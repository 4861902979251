<template>
<div>

    <PropertyItem padded v-if="input" :header="'Formatted text properties'" :icon="'strikethrough'" :initialCollapse="initialCollapse">

        <DesignerGroup c12>
            <TextEditorV2 designer v-model="input.desc[$root.locale.key]" advanced :raw="input.raw" @before="$emit('propinput', 'text', 'ftext', $event)" />
        </DesignerGroup>

        <DesignerGroup c4>
            <DesignerLabel :text="'Width'" />
            <DesignerDropdown v-model="input.width" :options="widthOptions" @before="$emit('propinput', 'drop', 'input_width', $event)" />
        </DesignerGroup>
<!--         <DesignerGroup c4>
            <DesignerLabel :text="''" />
            <DesignerCheckbox v-model="input.pdfOnly" :text="'PDF only'" />
        </DesignerGroup>
        <DesignerGroup c4>
            <DesignerLabel :text="''" />
            <DesignerCheckbox v-model="input.raw" :text="'Raw HTML'" />
        </DesignerGroup> -->

    </PropertyItem>

    <PropertyItem padded v-if="card" :header="'Formatted text properties'" :icon="'strikethrough'" :initialCollapse="initialCollapse">

        <DesignerGroup c12>
            <TextEditorV2 designer v-model="card.header[$root.locale.key]" advanced :raw="card.raw" @before="$emit('propinput', 'text', 'ftext', $event)" />
        </DesignerGroup>

<!--         <DesignerGroup c6>
            <DesignerCheckbox v-model="card.pdfOnly" :text="'PDF only'" />
        </DesignerGroup>
        <DesignerGroup c6>
            <DesignerCheckbox v-model="card.raw" :text="'Raw HTML'" />
        </DesignerGroup> -->

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';

import TextEditorV2 from '../../TextEditorV2.vue';

export default {
    name: 'FormattedTextProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
        TextEditorV2,
    },
    props: {
        input: { type: Object, default: null },
        card: { type: Object, default: null },
        initialCollapse: { type: Boolean, default: false },
    },
    data() {
        return {
            widthOptions: [
                { value: '12', display: '12/12' },
                { value: '9', display: '9/12' },
                { value: '8', display: '8/12' },
                { value: '6', display: '6/12' },
                { value: '5', display: '5/12' },
                { value: '4', display: '4/12' },
                { value: '3', display: '3/12' },
                { value: '2', display: '2/12' },
            ],
        }
    },
}
</script>