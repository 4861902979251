<template>
<div>
    <PageHeader compactable :offset="104" @scrolled="scrolled = $event">
        <template v-slot:content>
            <vap-row v-if="!loading">
                <vap-group auto noPadding>
                    <vap-label :text="'Monitor'" desc/>
                    <vap-label :text="monitor.name" value />
                </vap-group>
            </vap-row>
        </template>
        <template v-slot:tabs>
            <vap-tabs accent v-model="tabModel" :tabs="tabOptions" :compact="scrolled" />
        </template>
        <template v-slot:compact>
            <div v-if="!loading">
                <span>{{ 'Monitor' }}</span>
            </div>
        </template>
    </PageHeader>

    <PageContent :scrolled="scrolled" :offset="104" v-if="!loading">

        <MonitorDetails v-if="tabModel.details" :monitor="monitor" />

        <MonitorPersons v-if="tabModel.persons" :monitor="monitor" />

        <MonitorEvents v-if="tabModel.events" :monitor="monitor" />

    </PageContent>

    <PageFooter :scrolled="scrolled" />
</div>
</template>
<script>
import PageHeader from '../v1ui/PageHeader.vue';
import PageContent from '../v1ui/PageContent.vue';
import PageFooter from '../v1ui/PageFooter.vue';

import MonitorDetails from '../v1monitor/MonitorDetails.vue';
import MonitorPersons from '../v1monitor/MonitorPersons.vue';
import MonitorEvents from '../v1monitor/MonitorEvents.vue';

export default {
    name: 'MonitorV1',
    components: {
        PageHeader,
        PageContent,
        PageFooter,
        MonitorDetails,
        MonitorPersons,
        MonitorEvents,
    },
    data() {
        return {
            loading: true,
            scrolled: false,
            tabModel: {
                details: true,
                persons: false,
                events: false,
            },
            tabOptions: {
                details: { icon: 'info', label: 'Details' },
                persons: { icon: 'people', label: 'Persons' },
                /* events: { icon: 'reminder', label: 'Notifications' }, */
            },
            monitor: {},
        }
    },
    async created() {
        for (const i in this.$root.monitors.list) {
            if (this.$root.monitors.list[i].id == this.$route.query.id) {
                this.monitor = this.$root.monitors.list[i];
            }
        }
        this.loading = false;
    }
}
</script>