<template>
    <div>
        <PropertyItem :header="'Popup properties'" :icon="'locale'">
            <vap-row>

                <vap-group c12>
                    <vap-label :text="'Icon'" desc />
                    <vap-input v-model="card.icon" noValidation />
                </vap-group>

                <vap-group c12>
                    <vap-label :text="'Title'" desc />
                    <vap-input v-model="card.title[$root.locale.key]" noValidation />
                </vap-group>

                <vap-group c12>
                    <vap-label :text="'Link text'" desc />
                    <vap-input v-model="card.link[$root.locale.key]" noValidation />
                </vap-group>

                <vap-group c12>
                    <vap-label :text="'Popup content'" desc />
                    <vap-input :value="'Edit popup content'" noValidation actions disabled>
                        <template v-slot:actions>
                            <vap-icon action :icon="'open'" @clicked="editContent"><template v-slot:tooltip><vap-tooltip :text="__('fr_localized_text')" /></template></vap-icon>
                        </template>
                    </vap-input>
                </vap-group>

                <vap-group c4>
                    <vap-label :text="'Icon Color'" desc />
                    <vap-input v-model="card.iconColor" noValidation />
                </vap-group>
                <vap-group c4>
                    <vap-label :text="'Title Color'" desc />
                    <vap-input v-model="card.titleColor" noValidation />
                </vap-group>
                <vap-group c4>
                    <vap-label :text="'BG Color'" desc />
                    <vap-input v-model="card.bgColor" noValidation />
                </vap-group>

            </vap-row>
        </PropertyItem>
    </div>
</template>

<script>
import FormMenuButton from '../FormMenuButton.vue';

import PropertyItem from './PropertyItem.vue';

export default {
    name: 'PopupProperties',
    components: {
        FormMenuButton,
        PropertyItem
    },
    props: {
        card: { type: Object, default: null },
    },
    data() {
        return {
            
        }
    },
    methods: {
        editContent() {
            //console.log(this.$parent)
            this.$parent.$parent.$parent.$parent.$parent.showTextEditor(this.card.content)
        }
    }
}
</script>
<style scoped>

.frv2-opts-actions {
    display: flex;
    flex-direction: row-reverse;
    float: left;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
}

</style>
