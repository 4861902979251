const globalSteps = {

    //#region Steps -------------------------------------------------------------------------------------------------------
    
    getApprovalStep() {
        return {
            key: 'xx-approve',
            isDefault: true,
            resetMode: 'form',
            form: 'app00',
            name: 'Default Approval Step',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'app00',
                    triggers: [
                        { type: 'form', key: 'app00', trg: 'sent', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form',
                        nb_NO: 'Åpne skjema',
                        sv_SE: 'Öppna formuläret',
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'app00',
                    triggers: [
                        { type: 'form', key: 'app00', trg: 'sent', rev: false },
                        { type: 'form', key: 'app00', trg: 'signed', rev: true },
                        { type: 'form', key: 'app00', trg: 'analog', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder',
                    },
                    hint: {
                        en_EN: 'Send a reminder or change the recipient',
                        nb_NO: 'Send en påminnelse eller endre mottaker',
                        sv_SE: 'Skicka en påminnelse eller ändra mottagare',
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'form-reset',
                    key: 'app00',
                    triggers: [
                        { type: 'form', key: 'app00', trg: 'sent', rev: false },
                    ],
                    disabled: {
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ],
                        hint: {
                            en_EN: 'Customer is locked after the final report is created',
                            nb_NO: 'Kunden er låst etter sluttrapporten er laget',
                            sv_SE: 'Kunden är låst efter att slutrapporten skapats',
                        },
                    },
                    text: {
                        en_EN: 'Reset form',
                    },
                    hint: {
                        en_EN: 'The form will reset so that a new one can be sent',
                        nb_NO: 'Skjema tilbakestilles slik at ett nytt kan sendes',
                        sv_SE: 'Formuläret återställs så att ett nytt kan skickas',
                    }
                }
            ],
            title: {
                en_EN: 'Approval step',
                nb_NO: 'Approval step',
                sv_SE: 'Godkännande'
            },
            hint: {
                en_EN: 'Complete a checklist and send it to the responsible partner for approval.',
                nb_NO: 'Complete a checklist and send it to the responsible partner for approval.',
                sv_SE: 'Fyll i en checklista och skicka den till ansvarig partner för godkännande.'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Awaiting approval by {{app00-nextName}}',
                                nb_NO: 'Awaiting approval by {{app00-nextName}}',
                                sv_SE: 'Väntar på godkännande av {{app00-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'app00', trg: 'sent', rev: false },
                                { type: 'form', key: 'app00', trg: 'filled', rev: true },
                            ]
                        },
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Approved by {{app00-approvedNames}}',
                                nb_NO: 'Approved by {{app00-approvedNames}}',
                                sv_SE: 'Godkänd av {{app00-approvedNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'app00', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default globalSteps;