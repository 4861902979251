import verified from '../verified';
import v2lib from '../v2lib';
import log from '../log';
import v1api from './v1api';
const v1receipt = {

    //#region Flows -------------------------------------------------------------------------------------------------------

    async createClientEnvelope(root, client, companyData, flow, extraData) {

        const envelope = await v2lib.createEnvelope('aml-portal-v1-org', 'no-recipients-flow');

        if (!v2lib.wasCreated(envelope)) {
            log.e('AML: Envelope not created');
            return;
        }

        log.x('AML: Envelope successfully created', envelope);

        // Prepare metadata about the user who performed the search
        const metadata = {
            id: root.credentials.uid,
            userName: root.getEmployeeName(root.credentials.uid),
            date: new Date()
        };

        // Prepare translations and pdf config
        const translations = [
            'pep_pdf_user',
            'aml_pdf_title',
            'company_manual_header',
            'common_date',
            'common_name',
            'company_orgnr',
            'common_address',
            'common_zip',
            'common_city',
            'company_reg_date',
            'company_org_form',
            'common_country',
            'company_business',
            'company_business_codes',
            'company_registered',
            'company_status',
            'company_in_brreg',
            'company_in_business_reg',
            'company_in_mva_reg',
            'company_stat_bankrupt',
            'company_stat_liquidation',
            'company_stat_cliquidation',
            'common_yes',
            'common_no',
            'common_unknown'
        ];
        const pdfConfig = {
            logo: root.config.pdf.logoUrl,
            colors: {
                headerBack: root.getColor('header'),
                headerText: root.getColor('header-text')
            },
            language: root.locale.key || 'en_EN',
            translations: this.getPdfTranslations(root, translations)
        };

        // Prepare envelope userdata, name and tags
        let envelopeName = root.__t('search-name') + ' ' + root.companyTitleCase(client.name);
        let envelopeTags = [root.v1translations['search-tag'][root.config.params.defaultLanguage]];
        if (extraData && extraData.dataOnly) {
            envelopeName = root.__t('datapdf-name') + ' ' + root.companyTitleCase(client.name);
            envelopeTags = [root.v1translations['datapdf-tag'][root.config.params.defaultLanguage]];
        }
        let envelopeUserdata = {
            metadata: metadata,
            companyData: companyData,
            fileName: envelopeName,
            pdfConfig: pdfConfig
        };
        if (extraData) envelopeUserdata = { ...envelopeUserdata, ...extraData };

        // Complete the envelope
        await v2lib.setUserData(envelope, envelopeUserdata);
        await v2lib.setTags(envelope, envelopeTags);
        await v2lib.setName(envelope, envelopeName);
        await v2lib.publishEnvelope(envelope);

        log.x('AML: Envelope published');

        return envelope;
    },

    async getClientEnvelope(root, client) {
        return await root.getEnvelope(client.envelope.id);
    },

    async addBankIDReportToClientEnvelope(root, envelope, clientId, report) {

        const base64 = await verified.downloadBankIDReport(report.url);
        if (!base64) return;

        const attachment = {
            type: report.type,
            name: report.name,
            fileType: report.contentType
        };

        await this.addAttachmentToClientEnvelope(root, envelope, clientId, base64, attachment);
    },

    async addRawPdfToClientEnvelope(root, envelope, clientId, rawData, atc) {

        const attachment = {
            type: atc.type,
            name: atc.name,
            fileType: 'application/pdf'
        };

        await this.addAttachmentToClientEnvelope(root, envelope, clientId, rawData, attachment);
    },

    async addAttachmentToClientEnvelope(root, envelope, clientId, base64, attachment) {

        attachment.size = base64.length;

        await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, attachment.name);

        await v1api.addAttachmentToClient(clientId, attachment);

    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------

    getPdfTranslations(root, keys, language) {
        const translations = {};
        for (const i in keys) {
            if (root.translations[keys[i]]) {
                if (language) {
                    translations[keys[i]] = {
                        [language]: root.translations[keys[i]][language]
                    };
                }else {
                    translations[keys[i]] = root.translations[keys[i]];
                }
            }
        }
        return translations;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default v1receipt;