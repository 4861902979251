<template>
    <div class="pagec" :class="{'pagec-scrolled': scrolled }">
        
        <slot></slot>

    </div>
</template>
<script>


export default {
    name: 'PageContent',
    components: {
        
    },
    props: {
        offset: { type: Number, default: 0 },
        scrolled: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {
        

    }
}
</script>
<style scoped>

.pagec {
    float: left;
    width: 100%;
}

.pagec-scrolled {
    padding-top: 104px;
}

</style>