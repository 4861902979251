<template>
<div class="riskw-base">

    <div class="riskw-top">

        <div class="riskw-desc">
            {{ 'Weight:' }}
        </div>
        <div class="riskw-weight">
            {{ this.weight }}
        </div>

        <div class="riskw-desc">
            {{ 'Low risk multiplier:' }}
        </div>
        <div class="riskw-weight">
            <span v-if="multiplierL == 0">{{ 'OFF' }}</span>
            <span v-if="multiplierL == 100">{{ 'MAX' }}</span>
            <span v-if="multiplierL > 0 && multiplierL < 100">{{ multiplierL }}</span>
        </div>

        <div class="riskw-desc">
            {{ 'High risk multiplier:' }}
        </div>
        <div class="riskw-weight">
            <span v-if="multiplierH == 0">{{ 'OFF' }}</span>
            <span v-if="multiplierH == 100">{{ 'MAX' }}</span>
            <span v-if="multiplierH > 0 && multiplierH < 100">{{ multiplierH }}</span>
        </div>

        <div class="riskw-btn nohl" @click="adjustWeight(false)" style="margin-right: 5px;">
            {{ '-' }}
        </div>
        <div class="riskw-btn nohl" @click="adjustWeight(true)">
            {{ '+' }}
        </div>

    </div>

    <div class="riskw-bot">

        <div class="riskw-itm" v-for="(item, i) in params.items" :key="'wi' + i" :class="[
                {'riskw-itm-f': i == 0 },
                {'riskw-itm-l': i == params.items.length - 1 },
                {'riskw-itm-a': item.key == riskKey },
            ]"
            :style="'width: ' + item.percentage + '%;'"
        >
            
        </div>

    </div>

    <div class="riskw-mul">

        <div class="riskw-mul-l">

            <div class="riskw-btn nohl" @click="adjustMultiL(true)">
                {{ '+' }}
            </div>

            <div class="riskw-mul-c">
                <div class="riskw-itm" v-for="(item, i) in multiItems" :key="'mm' + i" :class="[
                        {'riskw-itm-f': i == 0 },
                        {'riskw-itm-l': i == multiItems.length - 1 },
                        {'riskw-itm-am': (80 - (i * 20)) < multiplierL },
                    ]"
                    :style="'width: ' + item + '%;'"
                />
            </div>

            <div class="riskw-btn nohl" @click="adjustMultiL(false)">
                {{ '-' }}
            </div>

        </div>

        <div class="riskw-mul-r">

            <div class="riskw-btn nohl" @click="adjustMultiH(false)">
                {{ '-' }}
            </div>
            
            <div class="riskw-mul-c">
                <div class="riskw-itm" v-for="(item, i) in multiItems" :key="'mp' + i" :class="[
                        {'riskw-itm-f': i == 0 },
                        {'riskw-itm-l': i == multiItems.length - 1 },
                        {'riskw-itm-ap': (i * 20) < multiplierH },
                    ]"
                    :style="'width: ' + item + '%;'"
                />
            </div>

            <div class="riskw-btn nohl" @click="adjustMultiH(true)">
                {{ '+' }}
            </div>

        </div>

    </div>

</div>
</template>
<script>

export default {
    name: 'RiskWeightControl',
    props: {
        riskKey: { type: String, default: '' },
        params: { type: Object, default: null },
    },
    data() {
        return {
            weight: 0,
            multiplierL: 0,
            multiplierH: 0,
            multiItems: [20, 20, 20, 20, 20]
        }
    },
    methods: {
        
        adjustWeight(plus) {
            if (plus) this.weight += 10;
            if (!plus) this.weight -= 10;

            this.$emit('adjust', this.riskKey, this.weight, this.multiplierL, this.multiplierH);
        },

        adjustMultiL(plus) {
            if (plus) this.multiplierL += 20;
            if (!plus) this.multiplierL -= 20;

            if (this.multiplierL < 0) this.multiplierL = 0;
            if (this.multiplierL > 100) this.multiplierL = 100;

            this.$emit('adjust', this.riskKey, this.weight, this.multiplierL, this.multiplierH);
        },

        adjustMultiH(plus) {
            if (plus) this.multiplierH += 20;
            if (!plus) this.multiplierH -= 20;

            if (this.multiplierH < 0) this.multiplierH = 0;
            if (this.multiplierH > 100) this.multiplierH = 100;

            this.$emit('adjust', this.riskKey, this.weight, this.multiplierL, this.multiplierH);
        },

    },
    async created() {

        for (const i in this.params.items) {
            if (this.params.items[i].key == this.riskKey) {
                this.weight = this.params.items[i].weight;
                this.multiplierL = this.params.items[i].multiL;
                this.multiplierH = this.params.items[i].multiH;
            }
        }

    }
}
</script>
<style scoped>

.riskw-base {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.riskw-top {
    float: left;
    width: 100%;
    height: 34px;
    display: flex;
    box-sizing: border-box;
}

.riskw-desc {
    font-size: 18px;
    padding-top: 5px;
    padding-left: 6px;
    color: var(--text-weak);
}
.riskw-weight {
    flex-grow: 100;
    margin-left: 8px;
    padding-top: 5px;
    font-size: 18px;
    font-weight: bold;
    color: var(--text-strong);
}

.riskw-btn {
    width: 34px;
    height: 30px;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 0px;
    color: gray;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--page-background);
    box-sizing: border-box;
    cursor: pointer;
}
.riskw-btn:hover {
    color: var(--accent-contrast);
    background-color: var(--accent);
}

.riskw-bot {
    float: left;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--page-background);
    box-sizing: border-box;
}

.riskw-itm {
    float: left;
    height: 28px;
    border-right: solid 1px var(--input-border);
    background-color: var(--page-background);
    box-sizing: border-box;
}
.riskw-itm-f {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.riskw-itm-l {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: none;
}

.riskw-itm-a {
    background-color: var(--accent);
}
.riskw-itm-am {
    background-color: var(--success-background);
}
.riskw-itm-ap {
    background-color: var(--val-error);
}


.riskw-mul {
    float: left;
    width: 100%;
    margin-top: 5px;
}

.riskw-mul-l {
    float: left;
    width: 50%;
    display: flex;
    padding-right: 3px;
    box-sizing: border-box;
}

.riskw-mul-r {
    float: left;
    width: 50%;
    display: flex;
    padding-left: 2px;
    box-sizing: border-box;
}

.riskw-mul-c {
    flex-grow: 100;
    height: 30px;
    margin: 0px 5px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--page-background);
    box-sizing: border-box;
}

</style>