<template>
<div>
    <PageHeader :actions="actions" compactable :offset="104" @scrolled="scrolled = $event" @action="onAction">
        <template v-slot:content>
            <vap-row v-if="!loading">
                <vap-group auto noPadding>
                    <vap-label :text="'Settings'" desc/>
                    <vap-label :text="'Settings for the portal'" value />
                </vap-group>
            </vap-row>
        </template>
        <template v-slot:tabs>
            <vap-tabs v-if="Object.keys(tabModel).length > 1" accent v-model="tabModel" :tabs="tabOptions" :compact="scrolled" @input="onTabChanged" />
        </template>
        <template v-slot:compact>
            <div v-if="!loading">
                <span>{{ 'Settings for the portal' }}</span>
            </div>
        </template>
    </PageHeader>

    <PageContent :scrolled="scrolled" :offset="104" v-if="!loading">

        <div v-if="tabModel.flows" class="mt20">
            <FlowSettings />
        </div>

        <div v-if="tabModel.risk" class="mt20">
            <RiskSettings />
        </div>

        <div v-if="tabModel.monitor" class="mt20">
            <MonitorSettings />
        </div>

        <div v-if="tabModel.branch" class="mt20">
            <BranchSettings />
        </div>

        <div v-if="tabModel.partner" class="mt20">
            <PartnerSettings />
        </div>

        <div v-if="tabModel.forms" class="mt20">
            <FormSettings />
        </div>

        <div v-if="tabModel.theme" class="mt20">
            <ThemeSettings />
        </div>

        <div v-if="tabModel.config" class="mt20">
            <ConfigSettings ref="configsettings" />
        </div>

    </PageContent>

    <PageFooter :scrolled="scrolled" />
</div>
</template>
<script>
import api from '../portalapi';
import configTools from '../config';
import uiHelper from '../helpers/uihelper';

import PageHeader from '../v1ui/PageHeader.vue';
import PageContent from '../v1ui/PageContent.vue';
import PageFooter from '../v1ui/PageFooter.vue';

import ThemeSettings from '../v1settings/ThemeSettings.vue';
import RiskSettings from '../v1settings/RiskSettings.vue';
import MonitorSettings from '../v1settings/MonitorSettings.vue';
import FlowSettings from '../v1settings/FlowSettings.vue';
import FormSettings from '../v1settings/FormSettings.vue';
import ConfigSettings from '../v1settings/ConfigSettings.vue';

import BranchSettings from '../v1settings/BranchSettings.vue';
import PartnerSettings from '../v1settings/PartnerSettings.vue';

export default {
    name: 'SettingsV1',
    components: {
        PageHeader,
        PageContent,
        PageFooter,
        ThemeSettings,
        RiskSettings,
        MonitorSettings,
        FlowSettings,
        FormSettings,
        ConfigSettings,
        BranchSettings,
        PartnerSettings,
    },
    data() {
        return {
            loading: true,
            scrolled: false,
            tabModel: {
                theme: true
            },
            tabOptions: {
                theme: { icon: 'colors', label: 'Appearance' }
            },
            actions: [
                { icon: 'save', action: 'save', working: false },
                { icon: 'help-o', action: 'help' }
            ]
        }
    },
    methods: {

        onTabChanged() {
            let newKey = '';
            for (const key in this.tabModel) {
                if (this.tabModel[key]) newKey = key;
            }
            const oldKey = this.$route.query.tab;
            if (newKey && oldKey != newKey) {
                this.$router.replace({query: { tab: newKey } });
            }
        },
        
        onAction(action) {
            if (action.action == 'save') {
                this.saveConfig();
            }
        },

        async saveConfig() {
            uiHelper.setCardActionWorking(this.actions, 'save', true);

            if (this.$refs.configsettings) {
                const newConfig = this.$refs.configsettings.getConfiguration();
                configTools.mergeConfig(this.$root.config, newConfig);
            }
            await api.setConfig(this.$root.config);

            if (this.$root.config.risk.autoEnabled) {
                // TODO save risk params
            }

            uiHelper.setCardActionWorking(this.actions, 'save', false);
        },

    },
    async created() {
        this.$parent.setTitle(this.__('settings'));

        if (this.$root.config.branches.enabled) {
            this.$set(this.tabModel, 'branch', false);
            this.$set(this.tabOptions, 'branch', { icon: 'orgchart', label: 'Branches' });
        }
        if (this.$root.config.partners.enabled) {
            this.$set(this.tabModel, 'partner', false);
            this.$set(this.tabOptions, 'partner', { icon: 'person', label: 'Partners' });
        }

        if (this.$root.config.risk.autoEnabled) {
            this.$set(this.tabModel, 'risk', false);
            this.$set(this.tabOptions, 'risk', { icon: 'search', label: 'Risk' });
        }

        if (this.$root.config.screening.personMonitor && this.$root.credentials.isAdmin) {
            this.$set(this.tabModel, 'monitor', false);
            this.$set(this.tabOptions, 'monitor', { icon: 'search', label: 'Monitors' });
        }

        if (this.$root.config.flow.editor.enabled || this.$root.credentials.isAdmin) {
            this.$set(this.tabModel, 'flows', false);
            this.$set(this.tabOptions, 'flows', { icon: 'flow', label: 'Flows' });
        }
        if (this.$root.config.forms.editor.enabled || this.$root.credentials.isAdmin || this.$root.credentials.isSuper) {
            this.$set(this.tabModel, 'forms', false);
            this.$set(this.tabOptions, 'forms', { icon: 'form', label: 'Forms' });
        }

        if (this.$root.credentials.isAdmin) {
            this.$set(this.tabModel, 'config', false);
            this.$set(this.tabOptions, 'config', { icon: 'settings', label: 'Configuration' });
        }

        if (this.$route.query.tab) {
            for (const key in this.tabModel) {
                this.tabModel[key] = false;
                if (key == this.$route.query.tab) this.tabModel[key] = true;
            }
        }

        this.loading = false;
    }
}
</script>
<style scoped>


</style>