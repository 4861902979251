var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vapli-base",staticStyle:{"position":"relative"}},[_c('router-link',{attrs:{"to":(_vm.noLink || _vm.locked) ? _vm.$route.path : _vm.link}},[_c('div',{staticClass:"vapcli-wrap",class:[
        {'vapcli-new' : _vm.newItem},
        {'vapcli-thick' : _vm.newItem},
        {'vapcli-locked' : _vm.locked},
        {'vapcli-disabled' : _vm.disabled || _vm.locked},
        {'vapcli-new-warning' : _vm.newItem && _vm.newWarning},
        {'vapcli-new-error' : _vm.newItem && _vm.newError} ],on:{"click":_vm.handleClick}},[_c('div',{staticClass:"vapcli"},[_c('div',{staticClass:"vapcli-icon"},[_c('vap-icon',{attrs:{"icon":_vm.icon,"color":'var(--disabled-background)'}})],1),_c('div',{staticClass:"vapcli-left"},[_c('div',{staticClass:"vapcli-name"},[_c('label',[_vm._v(_vm._s(_vm.name))])]),(!_vm.projects)?_c('div',{staticClass:"vapcli-info"},[(_vm.hasStatus)?_c('div',{staticClass:"vapcli-status",style:('border-color: ' + _vm.statusColor + '; background-color: ' + (_vm.completed ? _vm.statusColor : 'transparent') + ';')}):_vm._e(),_c('div',{staticClass:"vapcli-info1"},[_c('label',[_vm._v(_vm._s(_vm.info))])])]):_vm._e(),(_vm.projects)?_c('div',[_vm._t("default")],2):_vm._e()]),(_vm.locked)?_c('div',{staticClass:"vapcli-lock"},[_c('vap-icon',{attrs:{"icon":'locked',"color":'ext'}})],1):_vm._e(),(_vm.tags)?_c('div',{staticClass:"vapcli-tags"},[_vm._t("tags")],2):_vm._e(),_c('div',{staticClass:"vapcli-right"},[_c('div',{staticClass:"vapcli-date"},[_vm._v(" "+_vm._s(_vm.date)+" ")]),_c('div',{staticClass:"vapcli-user"},[_vm._v(" "+_vm._s(_vm.user)+" ")])])]),_c('div',{staticClass:"vapcli-bar"}),(_vm.newItem)?_c('div',{staticClass:"vapcli-new-overlay"},[_c('label',[_vm._v(_vm._s(_vm.__('common_new').toUpperCase()))])]):_vm._e()])]),(_vm.newItem)?_c('div',{staticClass:"vapli-new-button",class:[
    {'vapcli-newb-warning': _vm.newItem && _vm.newWarning },
    {'vapcli-newb-error': _vm.newItem && _vm.newError } ],on:{"click":function($event){$event.stopPropagation();return _vm.handleNewClick($event)}}},[_c('vap-icon',{attrs:{"icon":'check',"color":'white'}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }