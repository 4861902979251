<template>
<div class="vffp">

    <div v-if="!designer && files.length > 0" class="vffp-preview">
        <Preview :uid="dropZoneId + 'p' + files[0].id" :raw="files[0].data" />
    </div>

    <div v-if="designer" class="vffp-designer">
        {{ 'File will be previewed here!' }}
    </div>

</div>
</template>
<script>
import log from '@/log';
import tools from '@/tools';

import Preview from '../../documents/Preview.vue';

export default {
    name: 'vf-filepreview',
    components: {
        Preview
    },
    props: {
        value: { type: [Object, String], default: () => { return { files: [] } }},

        header: { type: String, default: '' },
        hintText: { type: String, default: '' },
        browseText: { type: String, default: '' },

        singleFile: { type: Boolean, default: false },
        autoPreview: { type: Boolean, default: false },

        validation: { type: Object, default: () => { return { done: false } }},
        disabled: { type: Boolean, default: false },
        dotted: { type: Boolean, default: false },

        required: { type: Boolean, default: false },
        noValidation: { type: Boolean, default: false },
        noHighlight: { type: Boolean, default: false },
        
        id: { type: String, default: 'id' },
        designer: { type: Boolean, default: false },
        activeLogic: { type: Boolean, default: false },
    },
    data() {
        return {
            dropZoneId: '',
            files: [],
        }
    },
    methods: {
        

    },
    mounted() {
        this.dropZoneId = 'dz' + (this.id ? this.id : tools.getUniqueId());
        this.files = this.value.files || [];
    }
}
</script>
<style scoped>

.vffp {

}

.vffp-preview {

}

.vffp-designer {

}

</style>
