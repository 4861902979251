<template>
<div class="own-pie-lbl">
    <div class="own-pie-lbl-color" :style="'background-color: ' + color + ';'">
<!--         <label style="color: var(--accent-contrast);">{{ '?' }}</label>
        <div class="own-pie-lbl-tt">
            <vap-tooltip :localized="'ki_desc_sumIdentifiedShareholders'" />
        </div> -->
    </div>
    <div class="own-pie-lbl-text">
        <label style="color: var(--text-title);">{{ text + ' ' }}</label>
        <label style="color: var(--text-title); font-weight: bold;">{{ percentage }}</label>
        <label style="color: var(--text-weak); font-weight: bold;">{{ '%' }}</label>
    </div>
</div>
</template>
<script>

export default {
    name: 'PieLegend',
    components: {
        
    },
    props: {
        text: { type: String, default: 'option' },
        color: { type: String, default: 'accent' },
        percentage: { type: [String, Number], default: '0' }
    },
    data() {
        return {
            scrolled: false
        }
    },
    methods: {
        
        

    },
    mounted() {
        
    }
}
</script>
<style scoped>

.own-pie-lbl {
    display: flex;
}
.own-pie-lbl-color {
    position: relative;
    width: 24px;
    height: 24px;
    padding-top: 3px;
    padding-left: 7px;
    font-weight: bold;
    margin-bottom: 20px;
    border-radius: 3px;
    box-sizing: border-box;
}
.own-pie-lbl-text {
    margin-top: 2px;
    margin-left: 10px;
    font-size: 17px;
}
.own-pie-lbl-tt {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
}

</style>