const monitorHelper = {

    //#region Monitor -----------------------------------------------------------------------------------------------------


    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------

    getAvailableTemplates(root) {
        const list = [];
        list.push(this.getSingleTemplate('bankid-eu-un'));
        return list;
    },

    getSingleTemplate(key) {
        const list = this.getAllTemplates();
        for (const template of list) {
            if (template.key == key) return template;
        }
        return {
            display: 'Unknown Template',
        };
    },

    getAllTemplates() {
        return [
            {
                key: 'bankid-eu-un',
                display: 'BankID Standard',
                provider: 'BankID',
                description: "Standard BankID screening using UN's sanction lists and Trapets' PEP Edge list",
            },
            {
                key: 'bankid-ofac',
                display: 'BankID OFAC',
                provider: 'BankID',
                description: "Sanction screening using the OFAC sanction list managed by USA",
            },
            {
                key: 'pliance-nordic',
                display: 'Pliance Nordic',
                provider: 'Pliance',
                description: "Standard Pliance PEP and Sanction screening with extra information using Bogard and Dow Jones",
            }
        ];
    },

    getTypeText(root, hit) {
        return hit.hit.type.toUpperCase();
    },

    getActiveText(root, hit) {
        if (hit.hit.active) return root.__t('monitor-hit-active');
        return root.__t('monitor-hit-inactive');
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    //#region Roles -------------------------------------------------------------------------------------------------------

    getRoleList() {
        return [
            'role_individual',
            'role_ceo',
            'role_chair',
            'role_proprietor',
            'role_customer_contact',
            'role_deputy_chair',
            'role_board_member',
            'role_deputy_bm',
            'role_rrh',
            'role_shareholder',
            'role_cfo',
            'role_commercial',
            'role_kyc_pep',
        ];
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default monitorHelper;