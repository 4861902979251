<template>
<div class="amlv1-action" @click="onClick" :class="[
    {'amlv1-action-disabled': disabled },
    {'amlv1-action-warning': warning },
]">
    <div class="amlv1-action-icon">
        <vap-icon :icon="icon" small :color="'var(--accent-contrast)'" />
    </div>
    <div class="amlv1-action-text">
        <div class="amlv1-action-head" :class="{'amlv1-action-nohint': !hint }">
            {{ text }}
        </div>
        <div class="amlv1-action-sub">
            {{ hint }}
        </div>
    </div>
</div>
</template>
<script>

export default {
    name: 'ActionV1',
    props: {
        icon: { type: String, default: 'bug' },
        text: { type: String, default: 'Perform action' },
        hint: { type: String, default: 'This is the default action button' },
        noMargin: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        warning: { type: Boolean, default: false },
    },
    methods: {

        onClick() {
            if (this.disabled) return;
            this.$emit('clicked');
        }

    }
}
</script>
<style scoped>

.amlv1-action {
    float: left;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    padding: 10px 14px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
    cursor: pointer;
}
.amlv1-action:hover {
    background-color: var(--item-hover);
}

.amlv1-action-icon {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    padding-top: 4px;
    padding-left: 4px;
    border-radius: 50%;
    background-color: var(--accent);
    box-sizing: border-box;
}

.amlv1-action-text {
    margin-left: 14px;
}

.amlv1-action-head {
    font-size: 18px;
    color: var(--text-title);
}

.amlv1-action-nohint {
    margin-top: 7px;
}

.amlv1-action-sub {
    margin-top: -2px;
    font-size: 14px;
    color: var(--text-weak);
}


.amlv1-action-disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.amlv1-action-disabled:hover {
    background-color: var(--card-background);
}
.amlv1-action-disabled .amlv1-action-icon {
    background-color: var(--disabled-background);
}

.amlv1-action-warning .amlv1-action-icon {
    background-color: var(--error-background);
}

</style>