<template>
<div v-if="flow">
    
   <FlowStep v-for="(step, i) in steps" :key="'sr' + i"
        :step="step"
        :designer="designer"
        :index="i"
        @action="onAction"
        @xaction="onActionX"
        :client="client"
        @signature="$emit('signature', $event)"
        @clicked="onStepClicked(step)"
        :selected="selectedKey == step.key"
    />

</div>
</template>
<script>
import defaultFlows from './defaultFlows';
import triggerHelper from './triggerHelper';

import FlowStep from './FlowStep.vue';

export default {
    name: 'StepRenderer',
    components: {
        FlowStep
    },
    props: {
        client: { type: Object, default: null },
        flow: { type: Object, default: null },
        designer: { type: Boolean, default: false },
        triggers: { type: Object, default: null },
        texts: { type: Object, default: null },
        selectedKey: { type: String, default: '' },
    },
    data() {
        return {
            steps: []
        }
    },
    watch: {
        "$root.locale.key": {
            deep: false,
            handler(val) {
                console.log('lang changed', val);
                this.reloadSteps();
            }
        }
    },
    methods: {

        onStepClicked(step) {
            if (!this.designer) return;
            this.$emit('clicked', step);
        },

        onAction(action, index) {
            //console.log('action', action);
            //console.log('index', index);

            if (action.action == 'form-start') {

                this.$emit('form', action.key, action);

            }else if (action.action == 'form-edit') {

                this.$emit('editform', action.key);

            }else if (action.action == 'preview') {

                this.$emit('preview', action.key);
                
            }else if (action.action == 'form-reminder') {

                this.$emit('reminder', action.key);
                
            }else if (action.action == 'tab') {

                this.$emit('tab', action.key);
                
            }else if (action.action == 'form-reset') {

                this.$emit('reset', action.key);

            }else if (action.action == 'form-view') {

                this.$emit('view', action.key);
                
            }else if (action.action == 'report-reset') {

                this.$emit('resetreport', action.key);

            }else if (action.action == 'report-create') {

                this.$emit('createreport', action.key);
                
            }else if (action.action.includes('custom')) {

                this.$emit('custom', action);
                
            }else if (action.action.includes('lookup')) {

                this.$emit('lookup', action);
                
            }else if (action.action.includes('form-new')) {

                this.$emit('newform', action);
                
            }else if (action.action.includes('ams-org')) {

                this.$emit('ams', action);
                
            }else {
                this.$emit('custom', action);
            }

        },

        onActionX(action, archiveIndex, index) {
            this.$emit('xaction', action, archiveIndex, index);
        },

        async reloadSteps() {
            this.steps = [];
            await new Promise(r => setTimeout(r, 10));
            this.loadSteps();
        },

        loadSteps() {
            for (const i in this.flow.steps) {
                let step = null;
                for (const j in this.$root.flowConfig.steps) {
                    if (this.flow.steps[i].key == this.$root.flowConfig.steps[j].key) {
                        step = this.$root.flowConfig.steps[j];
                    }
                }
                if (!step) {
                    step = defaultFlows.getStep(this.flow.steps[i].key);
                }

                let shouldAdd = true;
                if (step.triggers) {
                    shouldAdd = false;
                    const values = [];

                    for (const j in step.triggers) {

                        const valid = triggerHelper.validateTrigger(step.triggers[j], this.triggers);
                        //console.log('valid', valid);
                        values.push(valid);

                    }

                    if (step.triggerOr) {
                        for (const j in values) {
                            if (values[j]) shouldAdd = true;
                        }
                    }else {
                        shouldAdd = true;
                        for (const j in values) {
                            if (!values[j]) shouldAdd = false;
                        }
                    }
                }

                if (shouldAdd) this.steps.push(step);
            }
        },

    },
    created() {
        //console.log('FLOW', this.flow);

        this.loadSteps();

        //console.log('STEPS', this.steps);
    }
}
</script>
<style scoped>

</style>