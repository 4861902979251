<template>
    <div class="frv2-pdf">

        <div class="frv2-pdf-header">
            <div class="frv2-pdf-title">
                {{ 'PDF Designer' }}
            </div>
            <div class="frv2-pdf-close">
                <vap-icon :icon="'cancel'" :color="'var(--header-text)'" action @clicked="startClose" />
            </div>
        </div>

        <div class="frv2-pdf-designer">

            <div class="frv2-pdf-left">
                <div class="frv2-pdf-menu" :style="'width: ' + width + 'px;'">

                    <div class="frv2-pdf-menu-head">
                        {{ 'Selected item' }}
                    </div>
                    <div class="frv2-pdf-selected-empty" v-if="!selectedItem">
                        {{ 'No item selected' }}
                    </div>
                    <div class="frv2-pdf-selected-empty" v-if="selectedItem">
                        {{ selectedItem.key }}
                        <vap-icon :icon="'delete'" action @clicked="removeSelectedCard" />

                        <vap-icon :icon="'chev-u'" action @clicked="moveUp" v-if="selectedItem.isCustom" />
                        <vap-icon :icon="'chev-d'" action @clicked="moveDown" v-if="selectedItem.isCustom" />

                        <vap-input v-model="selectedOriginal.desc[$root.locale.key]" v-if="selectedItem.isCustom && selectedOriginal && selectedOriginal.sub == 'header'" />

                    </div>

<!--                     <div class="frv2-pdf-menu-head">
                        {{ 'Available items' }}
                    </div>
                    <div class="frv2-pdf-menu-item" @click="addItem('header')">
                        {{ 'Header' }}
                    </div>
                    <div class="frv2-pdf-menu-item" @click="addItem('ftext')">
                        {{ 'Formatted text' }}
                    </div> -->

                    <div class="frv2-pdf-menu-head" v-if="hasModule('idc')">
                        {{ 'ID Control Module' }}
                    </div>
                    <div class="frv2-pdf-menu-item" v-if="hasModule('idc')" @click="addItem('idc-result')">
                        {{ 'ID Control Result' }}
                    </div>

                    <div class="frv2-pdf-menu-head" v-if="hasModule('kar')">
                        {{ 'KAR Module' }}
                    </div>
                    <div class="frv2-pdf-menu-item" v-if="hasModule('kar')" @click="addItem('kar-result')">
                        {{ 'KAR Result' }}
                    </div>


                    <div class="frv2-pdf-menu-head">
                        {{ 'Removed items' }}
                    </div>

                </div>
            </div>

            <div class="frv2-pdf-right">
                <div class="frv2-pdf-scroll" @click="startClose">
                    <div class="frv2-pdf-content" :style="'opacity: ' + contentOpacity + ';'" @click.stop>
                        <PDFRenderer
                            :cards="cards"
                            :config="config"
                            :metadata="metadata"
                            @select="onSelectCard"
                        />
                    </div>
                </div>
            </div>

        </div>

        <div class="frv2-pdf-bg" :style="'opacity: ' + opacity + ';'" />

    </div>
</template>

<script>
import pdfHelper from '../pdfHelper';
import formHelper from '../formHelper';

import PDFRenderer from './PDFRenderer.vue';

export default {
    name: 'PDFDesignerV2',
    components: {
        PDFRenderer
    },
    props: {
        params: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            testing: false,

            width: 0,
            opacity: '0',
            contentOpacity: '0',

            cards: [],
            config: {},
            metadata: {},

            selectedItem: null,
            selectedOriginal: null,
            removedItems: []
        }
    },
    methods: {
        
        startShow() {
            const self = this;
            setTimeout(function() {
                self.width = 400;
                self.opacity = '0.8';
                self.contentOpacity = '1';
            }, 10);
        },

        startClose() {
            this.width = 0;
            this.opacity = '0';
            this.contentOpacity = '0';
            const self = this;
            setTimeout(function() {
                self.$emit('close')
                self.$root.enableScrolling(true);
            }, 500);
        },

        processPages() {
            this.cards = pdfHelper.processPages(this.$parent.pages, this.$root.locale.key, true, null, this.$parent);
            this.removedItems = pdfHelper.getRemovedCards(this.$parent.pages, this.$root.locale.key, true);
            for (const i in this.cards) {
                this.$set(this.cards[i], 'selected', false);
            }
            this.config = pdfHelper.getConfig(this.$root);
            this.metadata = pdfHelper.getMetadata(this.$parent.formData, this.$root.locale.key, true);
            //console.log('cards', this.cards);
        },

        hasModule(type) {
            for (const i in this.$parent.formData.modules) {
                if (this.$parent.formData.modules[i].type == type) return true;
            }
            return false;
        },

        handleClick() {
            this.deselectAll();
        },

        onSelectCard(card) {
            this.selectedItem = card;
            console.log('selected', this.selectedItem);

            if (this.selectedItem.isCustom) {
                this.selectedOriginal = formHelper.getItemFromKey(this.$parent.pages, this.selectedItem.key);
                console.log('orignal', this.selectedOriginal);
            }

        },

        removeSelectedCard() {

            const card = formHelper.getItemFromKey(this.$parent.pages, this.selectedItem.key);
            console.log('card', card);

            card.pdf = false;

            this.processPages();

        },

        moveUp() {
            const direction = 'up';

            const item = formHelper.getItemFromKey(this.$parent.pages, this.selectedItem.key);
            const container = formHelper.getContainer(this.$parent.pages, item);

            console.log('item', item);
            console.log('container', container);

            const location = formHelper.getItemLocation(this.$parent.pages, item);

            if (location.index == 0) {
                // Move to a different card
                console.log('moving page');

                let index = -1;
                for (const i in this.$parent.pages) {
                    if (this.$parent.pages[i].key == container.key) index = i;
                }
                if (index == -1) return;

                location.list.splice(location.index, 1);
                this.$parent.pages[index - 1].cards.push(item);
            }else {
                // Move within page
                location.list.splice(location.index, 1);
                if (direction == 'up') {
                    location.list.splice(location.index - 1, 0, item);
                }else {
                    location.list.splice(location.index + 1, 0, item);
                }
            }

            this.processPages();

        },

        moveDown() {

        },

        addItem(type) {
            let insertPageIndex = this.$parent.pages.length - 1;
            
            const card = {
                key: 'q' + (this.$parent.counter += 1),
                type: 'custom',
                pdf: true,
                pdfOnly: true
            };

            if (type == 'idc-result') {

                card.sub = 'idcr';

            }else if (type == 'kar-result') {

                card.sub = 'karr';

            }else if (type == 'header') {

                card.sub = 'header';
                card.desc = {
                    en_EN: 'New header',
                    nb_NO: 'Ny overskrift'
                };

            }else if (type == 'ftext') {

                card.sub = 'ftext';
                card.desc = {
                    en_EN: 'New formatted text',
                    nb_NO: 'Ny formattert tekst'
                };

            }

            console.log('card', card);


            this.$parent.pages[insertPageIndex].cards.push(card);
            this.processPages();
        },

        processTest() {
            const userdata = this.getTestData();
            console.log('userdata', userdata);

            const pages = JSON.parse(JSON.stringify(this.$parent.pages));

            const processedData = formHelper.processFormUserdata(userdata.formConfig, userdata.formPages);
            this.setModelFromUserdata(this, pages, userdata);
            console.log('pages', pages);

            this.config = pdfHelper.getConfig(this.$root);
            this.metadata = pdfHelper.getMetadata(userdata, this.$root.locale.key);
            this.cards = pdfHelper.processPages(pages, this.$root.locale.key, false, userdata, this.$parent);
            console.log('pdfConfig', this.config);
            console.log('pdfMeta', this.metadata);
            console.log('pdfPages', this.cards);

        },

        getTestData() {
            return {};
        },

        setModelFromUserdata(context, pages, userdata) {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    if (pages[i].cards[j].type == 'list' || pages[i].cards[j].type == 'chk-a') {

                        const card = this.getCardModelFromUserdata(pages, userdata, i, j);
                        context.$set(pages[i].cards[j], 'model', card.model);
                        context.$set(pages[i].cards[j], 'itemModel', card.itemModel);
                        context.$set(pages[i].cards[j], 'val', []);
                        for (const x in pages[i].cards[j].model) {
                            formHelper.addValidation(pages[i].cards[j]);
                        }
                        
                    }else {
                        for (const k in pages[i].cards[j].inputs) {
                            const input = pages[i].cards[j].inputs[k];
   
                            context.$set(input, 'model', this.getInputModelFromUserdata(pages, userdata, i, j, k));
                            context.$set(input, 'val', { valid: false, done: false, message: '' });

                        }
                    }
                }
            }
        },

        getCardModelFromUserdata(pages, userdata, pageIndex, cardIndex) {
            if (userdata.formPages[pageIndex].cards[cardIndex]) {
                console.log('card model', userdata.formPages[pageIndex].cards[cardIndex]);
                return userdata.formPages[pageIndex].cards[cardIndex];
            }else {
                console.log('no card model exists');
            }
        },

        getInputModelFromUserdata(pages, userdata, pageIndex, cardIndex, inputIndex) {
            try {
                if (userdata.formPages[pageIndex].cards[cardIndex].inputs[inputIndex]) {
                    return userdata.formPages[pageIndex].cards[cardIndex].inputs[inputIndex].model;
                }else {
                    console.log('no input model exists');
                }
            }catch (ex) {
                console.log('no input model exists');

            }
            return '';
        },

    },
    created() {
        this.$root.enableScrolling(false);
        this.startShow();

        if (this.testing) {
            this.processTest();
        }else {
            this.processPages();
        }
    }
}
</script>
<style scoped>

.frv2-pdf {
    position: absolute;
    top: 0px; left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 11;
}

.frv2-pdf-bg {
    position: fixed;
    top: 0px; left: 0px;
    width: 100%;
    height: 100vh;
    background-color: black;
    transition: opacity 0.5s ease-in-out;
    z-index: 12;
}


.frv2-pdf-header {
    position: absolute;
    display: flex;
    top: 0px; left: 0px;
    width: 100%;
    height: 60px;
    background-color: var(--header);
    z-index: 15;
}
.frv2-pdf-title {
    flex-grow: 100;
    font-size: 24px;
    padding-top: 15px;
    padding-left: 20px;
    color: var(--header-text);
}
.frv2-pdf-close {
    padding-top: 12px;
    padding-right: 16px;
}


.frv2-pdf-designer {
    display: flex;
    box-sizing: border-box;
}


.frv2-pdf-left {
    flex-shrink: 0;
    width: 400px;
    height: 100vh;
    z-index: 13;
}

.frv2-pdf-menu {
    min-height: 100vh;
    padding-top: 60px;
    background-color: var(--card-background);
    transition: width 0.5s ease-in-out;
}


.frv2-pdf-right {
    position: relative;
    flex-grow: 100;
    max-height: 100vh;
    z-index: 13;
    pointer-events: none;
    box-sizing: border-box;
}

.frv2-pdf-scroll {
    overflow: auto;
    max-height: calc(100% - 60px);
    margin-top: 60px;
    padding-top: 40px;
    padding-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px; 
    pointer-events: all;
    box-sizing: border-box;
}

.frv2-pdf-content {
    width: 100%;
    background-color: var(--card-background);
    transition: opacity 0.5s ease-in-out;
}



.frv2-pdf-menu-head {
    font-size: 18px;
    margin-top: 20px;
    padding-left: 10px;
    padding-bottom: 2px;
}

.frv2-pdf-menu-item {
    padding: 4px 15px;
    margin-top: -1px;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    cursor: pointer;
}
.frv2-pdf-menu-item:hover {
    background-color: var(--item-hover);
}

.frv2-pdf-selected-empty {
    padding: 40px 10px;
    font-size: 14px;
}


</style>