<template>
<div>

    <RiskItem v-for="(option, i) in options" :key="'rh' + i"
        :level="1" lastLevel
        :display="option.display"
    >
        <template v-slot:risk>
            <RiskScoreControl
                :params="riskParams"
                :source="'rh'"
                :rId="option.key"
            />
        </template>
    </RiskItem>

</div>
</template>
<script>
import RiskItem from './RiskItem.vue';
import RiskScoreControl from './RiskScoreControl.vue';

export default {
    name: 'RiskHitSettings',
    components: {
        RiskItem,
        RiskScoreControl,
    },
    props: {
        riskParams: { type: Object, default: null },
    },
    data() {
        return {
            options: []
        }
    },
    methods: {
        
        getOptions() {
            const options = [];

            options.push({
                key: 'hit00',
                display: 'No hits on PEP/Sanction'
            });
            options.push({
                key: 'hit01',
                display: 'PEP/Sanction hit'
            });

            return options;
        },

    },
    created() {
        this.options = this.getOptions();
    }
}
</script>
