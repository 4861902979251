<template>
<div class="vap-form-divider">
    <div class="vap-form-divider-left" :class="[
        {'w25' : left == 25},
        {'w35' : left == 35},
        {'w40' : left == 40},
        {'w50' : left == 50},
        {'w55' : left == 55},
        {'w65' : left == 65},
        {'w100' : left == 100},
    ]">
        <slot name="left"></slot>
    </div>
    <div ref="right" class="vap-form-divider-right" :class="[
        {'vap-sticky' : stickRight},
        {'w0' : right == 0},
        {'w35' : right == 35},
        {'w45' : right == 45},
        {'w50' : right == 50},
        {'w60' : right == 60},
        {'w65' : right == 65},
        {'w75' : right == 75},
    ]">
        <slot name="right"></slot>
    </div>
</div>
</template>

<script>

export default {
    name: 'vap-form-divider',
    components: {
        
    },
    props: {
        left: {
            type: Number,
            default: 35
        },
        right: {
            type: Number,
            default: 65
        },
        stickyRight: {
            type: Boolean,
            default: false
        },
        stickyOffset: {
            type: Number,
            default: 212
        },
        stickyTop: {
            type: Number,
            default: 10
        }
    },
    data() {
        return {
            stickRight: false
        }
    },
    methods: {
        handleScroll(event) {

            // Desyncs a little bit on low hz screens but keeps the proportions
            // and placement of the container compared to the old solution

            const top = document.documentElement.scrollTop;
            const el = this.$refs.right;

            if(top > this.stickyOffset) {
                el.style.marginTop = `${top - this.stickyOffset}px`;
            } else {
                el.style.marginTop = `0`;
            }
        }
    },
    async created() {
        if (this.stickyRight) window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        if (this.stickyRight) window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>

<style scoped>

.vap-form-divider {
    float: left;
    width: 100%;
}

.vap-form-divider-left {
    float: left;
    min-height: 100px;
    box-sizing: border-box;
}

.vap-form-divider-right {
    float: left;
    min-height: 100px;
    padding-left: 20px;
    box-sizing: border-box;
}
.vap-sticky {
    position: fixed;
    top: 10px;
    right: 20px;
    padding-left: 34px;
}

.vap-form-divider-left.w50 {
    padding-right: 10px;
}
.vap-form-divider-right.w50 {
    padding-left: 10px;
}

.w0 {
    display: none;
}
.w35 {
    width: 35%;
}
.w25 {
    width: 25%;
}
.w40 {
    width: 40%;
}
.w45 {
    width: 45%;
}
.w50 {
    width: 50%;
}
.w55 {
    width: 55%;
}
.w60 {
    width: 60%;
}
.w65 {
    width: 65%;
}
.w75 {
    width: 75%;
}
.w100 {
    width: 100%;
}


@media only screen and (max-width: 600px) {

    .vap-form-divider-left {
        width: 100%;
    }

    .vap-form-divider-right {
        width: 100%;
        padding-left: 0px;
        padding-top: 20px;
    }

}

</style>
