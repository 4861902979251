<template>
<div>
    <div class="frv2-opts-actions">
        <slot></slot>
    </div>
</div>
</template>

<script>
export default {
    name: 'FromPropertyActions',
}
</script>
<style scoped>

.frv2-opts-actions {
    display: flex;
    flex-direction: row-reverse;
    padding-right: 15px;
}

</style>
