<template>
    <div class="frv2-help-base">

        <div class="frv2-help-bg" @click="startClose" :style="'opacity: ' + opacity + ';'">

        </div>

        <div class="frv2-help-content" :style="'opacity: ' + contentOpacity + '; right: ' + (500) + 'px;'">
            <div class="frv2-help-content-head">
                <label>{{ selectedItem.title }}</label>
                <vap-icon :icon="'cancel'" action absolute :top="8" :right="15" :color="'var(--header-text)'" @clicked="startClose" />
            </div>
            <div class="frv2-help-content-content">
                <FormHelpContent :file="selectedItem.html" />
            </div>
            <div class="frv2-help-content-footer">
                <div class="frv2-help-footer-name">
                    <label>{{ 'form designer' }}</label>
                </div>
                <div class="frv2-help-footer-version">
                    <label>{{ 'version ' + version }}</label>
                </div>
                <div class="frv2-help-footer-logo">
                    <img :src="$root.images.vfblue" />
                </div>
            </div>
        </div>
        
        <div class="frv2-help-menu" :style="'width: ' + width + 'px;'">

            <div class="frv2-help-menu-logo">
                <div class="frv2-menu-title">
                    <div class="frv2-menu-title-text">
                        <label>{{ 'form designer' }}</label>
                    </div>
                    <div class="frv2-menu-title-logo">
                        <label>{{ 'POWERED BY' }}</label>
                        <img :src="$root.images.vfblue" />
                    </div>
                </div>
                <div class="frv2-menu-head-close" @click="startClose">
                    <vap-icon :icon="'chev-r'" tiny absolute :top="19" :right="60" />
                    <vap-icon :icon="'help-o'" absolute :top="12" :right="15" />
                </div>
            </div>

            <div class="frv2-help-menu-head">
                <label>{{ 'Help pages' }}</label>
            </div>

            <div class="frv2-help-menu-content">

                <div class="frv2-help-menu-item" v-for="(item, i) in items" :key="'hmi' + i">
                    <div class="frv2-help-menu-item-icon">
                        <vap-icon :icon="item.icon" />
                    </div>
                    <div class="frv2-help-menu-item-title">
                        <label>{{ item.title }}</label>
                    </div>
                </div>

            </div>

        </div>
        
    </div>
</template>

<script>
import FormHelpContent from './FormHelpContent';

export default {
    name: 'FormHelpMenuV2',
    components: {
        FormHelpContent
    },
    props: {
        version: { type: String, default: 'dev' }
    },
    data() {
        return {
            width: 0,
            opacity: '0',
            contentOpacity: '0',
            selectedItem: null,
            items: [
                { icon: 'help-o', title: 'Text editor', html: 'text_editor' },
                { icon: 'help-o', title: 'This is page 2' },
                { icon: 'help-o', title: 'This is page 3' },
                { icon: 'help-o', title: 'This is page 4' },
                { icon: 'help-o', title: 'This is page 5' },
                { icon: 'help-o', title: 'This is page 6' },
                { icon: 'help-o', title: 'This is page 7' },
                { icon: 'help-o', title: 'This is page 8' },
            ]
        }
    },
    methods: {
        
        startClose() {
            this.width = 0;
            this.opacity = '0';
            this.contentOpacity = '0';
            const self = this;
            setTimeout(function() {
                self.$emit('close')
            }, 500);
        }

    },
    created() {
        this.selectedItem = this.items[0];
        const self = this;
        setTimeout(function() {
            self.width = 400;
            self.opacity = '0.8';
            self.contentOpacity = '1';
        }, 10);
    }
}
</script>
<style scoped>

.frv2-help-base {
    position: absolute;
    top: 0px;
    height: 100vh;
    left: 0px; right: 0px;
    z-index: 100;
}

.frv2-help-bg {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
    background-color: black;
    transition: opacity 0.5s ease-in-out;
}

.frv2-help-content {
    position: absolute;
    top: 50px; bottom: 50px;
    left: 100px; right: 0px;
    border-radius: 8px;
    background-color: white;
    transition: opacity 0.5s ease-in-out;
    -webkit-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
}

.frv2-help-menu {
    position: absolute;
    top: 0px; bottom: 0px;
    right: 0px;
    width: 200px;
    background-color: white;
    transition: width 0.5s ease-in-out;
    -webkit-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
}


/* MENU - Header / Logo */

.frv2-help-menu-logo {
    display: flex;
    float: left;
    width: 100%;
    height: 62px;
    border-bottom: solid 2px var(--input-border);
    box-sizing: border-box;
}

.frv2-help-menu-head {
    float: left;
    width: 100%;
    height: 62px;
    display: flex;
    border-bottom: solid 2px var(--input-border);
    box-sizing: border-box;
}

.frv2-menu-title {
    flex-grow: 100;
    padding-top: 7px;
    padding-left: 26px;
}
.frv2-menu-title-text {
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    letter-spacing: 1.5px;
    color: var(--text-title);
}
.frv2-menu-title-logo {
    font-size: 13px;
    font-weight: bold;
    color: var(--text-title);
}
.frv2-menu-title-logo label {
    float: left;
    margin-top: 2px;
    opacity: 0.5;
}
.frv2-menu-title-logo img {
    float: left;
    width: 50px;
    margin-top: 1px;
    margin-left: 6px;
}

.frv2-menu-head-close {
    position: relative;
    width: 80px;
    border-left: 2px solid var(--input-border);
    cursor: pointer;
}
.frv2-menu-head-close:hover {
    background-color: var(--button);
    opacity: 0.5;
}

.frv2-help-menu-head {
    padding-top: 15px;
    padding-left: 24px;
    font-size: 24px;
    color: var(--header-text);
    background-color: var(--header);
}


/* MENU - Items */

.frv2-help-menu-content {
    float: left;
    width: 100%;
}

.frv2-help-menu-item {
    display: flex;
    border-bottom: 1px solid var(--input-border);
    cursor: pointer;
}
.frv2-help-menu-item:hover {
    background-color: lightgray;
}

.frv2-help-menu-item-icon {

}

.frv2-help-menu-item-title label {
    cursor: pointer;
}


/* CONTENT */

.frv2-help-content-head {
    position: absolute;
    top: 0px;
    height: 54px;
    left: 0px; right: 0px;
    padding-top: 14px;
    padding-left: 20px;
    font-size: 20px;
    color: var(--header-text);
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom: 2px solid var(--input-border);
    background-color: var(--header);
    box-sizing: border-box;
}

.frv2-help-content-content {
    position: absolute;
    top: 54px; bottom: 42px;
    left: 0px; right: 0px;
    box-sizing: border-box;
}

.frv2-help-content-footer {
    display: flex;
    position: absolute;
    bottom: 0px;
    height: 42px;
    left: 0px; right: 0px;
    border-top: 2px solid var(--input-border);
    box-sizing: border-box;
}

.frv2-help-footer-name {
    margin-top: 8px;
    margin-left: 15px;
    font-size: 16px;
    font-family: "Roboto Slab", serif;
    letter-spacing: 1.5px;
    color: var(--text-title);
}
.frv2-help-footer-version {
    flex-grow: 100;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 15px;
    color: var(--text-title);
}
.frv2-help-footer-logo {
    margin-top: 8px;
    margin-right: 15px;
}
.frv2-help-footer-logo img {
    width: 70px;
}

</style>
