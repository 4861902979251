<template>
<div>
    <FormViewer v-if="!loading" :isOwner="true" :formData="formData" />
    <div class="frv2-nosuchform" v-if="noSuchForm">
        {{ 'No form exists with key: ' + $route.params.key }}
    </div>
</div>
</template>
<script>
import FormViewer from '@/components/form/FormViewer';
import api from '@/portalapi';
export default {
    name: 'FormPublic',
    components: {
        FormViewer
    },
    data() {
        return {
            formData: null,
            loading: true,
            noSuchForm: false,
        }
    },
    methods: {

        setTitle(title) {
            this.$parent.headerTitle = title;
            this.$parent.setTitle(title, true);
        }

    },
    async created() {

        // Check if the page was reloaded and no longer has context
        const existingData = JSON.parse(this.$root.formData);
        if (!existingData) {
            history.back();
            return;
        }

        const response = await api.getForm(this.$route.params.key);
        if (response.formData) {
            this.formData = response.formData;
            this.loading = false;
        }else {
            this.noSuchForm = true;
        }
    }
}
</script>
<style scoped>

.frv2-nosuchform {
    width: fit-content;
    margin: 100px 100px;
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    color: var(--val-error);
    border-radius: 5px;
    background-color: var(--card-background);
}

</style>