<template>
    <div>
        <BatchView :items="items" :steps="steps" :skip="skip" :title="'Kunder'" />
    </div>
</template>
<script>
import api from '../../portalapi';
import log from '../../log';
import tools from '../../tools';
import clienthelper from '../../helpers/clienthelper';

import BatchView from '../../components/special/BatchView';

export default {
    components: {
        BatchView
    },
    name: 'BatchUpgrade',
    data() {
        return {
            items: [],
            steps: 2,
            STEP_LOAD_CLIENT: 0,
            STEP_SAVE_ROLES: 1,
            skip: 159
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            return item.name;
        },

        onGetInfo1(item) {
            return '';
        },

        onGetInfo2(item) {
            return '';
        },

        async onRemoveItem(item) {},

        async onStart() {
            return true;
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return 'Laster kundedata';
                case this.STEP_SAVE_ROLES: return 'Lagrer alle roller';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return await this.loadClient(item, data);
                case this.STEP_SAVE_ROLES: return await this.saveRoles(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async loadClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            log.suppress = true;
            result.data.client = await clienthelper.loadClient(this.$root, item);
            log.suppress = false;

            result.ok = true;
            return result;
        },

        async saveRoles(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            const client = data[this.STEP_LOAD_CLIENT].client;
            console.log('client', client);

            const roles = [];
            for (const i in client.persons) {
                const role = {
                    name: client.persons[i].info.firstName + ' ' + client.persons[i].info.lastName,
                    identity: tools.encodePerson(client.persons[i]),
                    customerId: client.customer._id,
                    customerName: client.customer.name,
                    customerType: client.customer.type,
                    roles: []
                };
                for (const j in client.persons[i].roles) {
                    if (!role.roles.includes(client.persons[i].roles[j].id)) {
                        role.roles.push(client.persons[i].roles[j].id);
                    }
                }
                console.log('role', role);
                roles.push(role);
            }
            console.log('roles', roles);
            await api.addCustomerRoles(client.customer._id, roles);

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        async loadCustomers() {
            this.$parent.setLoading(true, 'Laster...');
            
            const filters = [
                { field: 'status', op: 'is', value: 'r-rep-x' }
            ];
            const from = 0;
            const limit = 0;
            const sort = { dateUpdated: 1 };
            const include = [];
            const counts = [];

            const response = await api.queryCustomers(filters, from, limit, sort, include, counts);

            this.items = [];
            for (const i in response.customers) {
                this.items.push(response.customers[i]);
            }

            this.$parent.setLoading(false);
        },

    },
    async created() {
        await this.loadCustomers();
    }
}
</script>