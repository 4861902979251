<template>
<div>
    
    <div v-if="!searching && person.control.completed">

        <div class="vperni-row" :class="[
            { 'vperni-result-s': indicator == 1 || indicator == 2 },
            { 'vperni-result-w': indicator == 3 || indicator == 4 },
            { 'vperni-result-e': indicator == 0 || indicator == 5 || indicator == 6 || indicator == 7 },
            { 'vperni-result-n': person.control.method == 'manual' }
        ]">
            <div class="vperni-cell" :style="'width: 100%;'">
                <span v-if="person.control.method != 'manual'">{{ person.control.confirmedInfo.firstName + ' ' + person.control.confirmedInfo.lastName }}</span>
                <span v-if="person.control.method == 'manual'">{{ person.info.firstName + ' ' + person.info.lastName }}</span>
            </div>
        </div>

        <div class="vperni-row">

            <div class="vperni-cell" :style="'width: 50%;'">
                {{ getMethodName(person.control.method) }}
            </div>

            <div class="vperni-cell vperni-border" :style="'width: 50%;'">
                {{ person.control.confirmedInfo.ssn || person.control.confirmedInfo.dateOfBirth }}
            </div>

        </div>

        <div class="vperni-row">
            <div class="vperni-cell" :style="'width: 100%;'" v-if="person.control.method != 'manual'">
                {{ getMatchDescription(person) }}
            </div>
            <div class="vperni-cell vperni-double" :style="'width: 100%;'" v-if="person.control.method == 'manual'">
                {{ person.control.manualInfo.comment }}
            </div>
        </div>

        <div class="vperni-row" v-if="person.control.method != 'manual'">

            <div class="vperni-cell vperni-date" :style="'width: 38%;'">
                {{ getDateString(person.control.dateCompleted) }}
            </div>

            <div class="vperni-cell vperni-date vperni-border" :style="'width: 38%;'">
                {{ getTimePassedString(person.control.dateCompleted) }}
            </div>

            <div class="vperni-cell vperni-border vperni-button" :style="'width: 12%;'" @click="$emit('options')">
                <vap-icon small :icon="'menu-h'" absolute :top="3" :right="21" :color="'ext'" />
            </div>

            <div class="vperni-cell vperni-border vperni-button" :style="'width: 12%;'" @click="$emit('receipt')">
                <vap-icon small :icon="'file-sent'" absolute :top="3" :right="21" :color="'ext'" />
            </div>

        </div>

        <div class="vperni-row" v-if="person.control.method == 'manual'">

            <div class="vperni-cell vperni-date" :style="'width: 44%;'">
                {{ getDateString(person.control.dateCompleted) }}
            </div>

            <div class="vperni-cell vperni-date vperni-border" :style="'width: 44%;'">
                {{ getTimePassedString(person.control.dateCompleted) }}
            </div>

            <div class="vperni-cell vperni-border vperni-button" :style="'width: 12%;'" @click="$emit('options')">
                <vap-icon small :icon="'menu-h'" absolute :top="3" :right="21" :color="'ext'" />
            </div>

        </div>

    </div>



    <div v-if="!searching && !person.uimodel.doControl">

        <div class="vperni-empty-icon">
            <vap-icon :icon="'card'" massive :color="'var(--text-disabled)'" />
        </div>
        <div class="vperni-empty-text">
            {{ 'Ikke valgt' }}
        </div>

    </div>

</div>
</template>

<script>
import tools from '../../tools';
import personhelper from '../../helpers/personhelper';

export default {
    name: 'PersonStatusIDC',
    components: {
        
    },
    props: {
        person: { type: Object, default: () => { return {} } },
    },
    data() {
        return {
            searching: false,
            indicator: 0
        }
    },
    methods: {
        
        getDateString(date) {
            return tools.dateStringShort(date, this.$root.locale.key);
        },

        getTimePassedString(date) {
            return tools.timePassedString(date, this.$root);
        },

        getMatchDescription(person) {
            return personhelper.getIdcMatchDescription(person);
        },

        getMethodName(method) {
            switch (method) {
                case 'bankid-no': return 'BankID Norge';
                case 'manual': return 'Fullført manuelt';
            }
            return 'Ukjent';
        },

    },
    created() {
        //console.log('per', this.person);
        this.indicator = personhelper.getIdcMatchIndicator(this.person);
    }
}
</script>

<style scoped>

.vperni-row {
    float: left;
    width: 100%;
    height: 34.5px;
    display: flex;
    border-bottom: solid 1px var(--input-border);
}

.vperni-tall {
    height: 43px;
}
.vperni-thin {
    height: 30px;
}

.vperni-cell {
    text-align: center;
    padding-top: 9px;
}

.vperni-single {
    padding-top: 6px;
}
.vperni-double {
    padding-top: 6px;
    font-size: 13px;
}

.vperni-border {
    border-left: solid 1px var(--input-border);
}

.vperni-date {
    padding-top: 10px;
    font-size: 14px;
    color: var(--text-weak);
}

.vperni-highlight {
    color: var(--accent);
    background-color: lightgray;
}

.vperni-button {
    position: relative;
    cursor: pointer;
    fill: gray;
}
.vperni-button:hover {
    color: var(--button-text);
    background-color: var(--button);
    fill: var(--button-text);
}


.vperni-result-s {
    color: var(--success-text);
    background-color: var(--success-background);
}
.vperni-result-w {
    color: var(--warning-text);
    background-color: var(--warning-background);
}
.vperni-result-e {
    color: var(--error-text);
    background-color: var(--error-background);
}
.vperni-result-n {
    color: var(--text-strong);
    background-color: var(--page-background);
}


.vperni-empty-icon {
    padding-left: 125px;
}
.vperni-empty-text {
    text-align: center;
    color: var(--text-weak);
}

</style>
