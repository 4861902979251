<template>
    <div>
        <ve-popup :header="'Text editor'" :width="900" @close="$emit('close')" hideCloseButton>

            <div style="float: left; padding-top: 15px; padding-left: 20px; padding-right: 20px;">
                <vap-tabs bordered v-model="params.tabModel" :tabs="params.tabOptions" @input="onLocaleChanged" />
            </div>

            <div style="float: left; width: 100%; padding: 10px 20px; box-sizing: border-box;">

                <vap-input v-if="!params.value.formatted && !params.value.textarea" v-model="params.value[selectedLocale]" />
                <vap-textarea v-if="params.value.textarea" v-model="params.value[selectedLocale]" />

                <vap-text-editor v-if="params.value.formatted" ref="texteditor" :editable="true" :value="params.value[selectedLocale]" />

                <vap-button-row alignRight style="margin-top: 10px; margin-bottom: 20px;">
                    <!-- <vap-button :text="'Bytt til formattert tekst'" outline @clicked="switchTranslationTextType" /> -->
                    <vap-button :text="__('common_cancel')" outline @clicked="$emit('close')" />
                    <vap-button :text="__('common_save')" @clicked="onSave(true)" />
                </vap-button-row>
            </div>

        </ve-popup>
    </div>
</template>

<script>
export default {
    name: 'FormTextEditor',
    props: {
        params: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            selectedLocale: ''
        }
    },
    methods: {
        
        onLocaleChanged(val) {
            console.log('locale', val);
            for (const key in val) {
                if (val[key]) {
                    this.selectedLocale = key;
                    console.log('selected', this.selectedLocale);
                }
            }
        },

        onSave(close) {
            if (this.params.value.formatted) {
                this.params.value[this.selectedLocale] = this.$refs.texteditor.getText();
            }

            if (close) this.$emit('close');
        }

    },
    created() {
        this.onLocaleChanged(this.params.tabModel);
    }
}
</script>