<template>
<div>
    
    <PopupV1 :header="'Select signature'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ 'If the person has signed a document through the portal with an eID, it can be used as an ID-control.' }}
            </div>
            
            <div>


                <div class="amlv1-signd-item" v-for="(signature, i) in signatures" :key="'s' + i" :class="[
                    {'amlv1-signd-active': i == selectedIndex },
                    {'amlv1-signd-first': i == 0 },
                    {'amlv1-signd-last': i == signatures.length - 1 },
                ]" @click="onSelect(i)">
                    
                    <div class="amlv1-signd-name">
                        <span>{{ signature.name }}</span>
                        <span style="margin-left: 5px; color: var(--text-weak);">{{ '(' + signature.dateOfBirth + ')' }}</span>
                    </div>
                    <div class="amlv1-signd-match">
                        {{ signature.matchDescription }}
                    </div>
                </div>

            </div>

        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="'Use as ID-control'" :icon="'check'" @clicked="onUseAsSignature" :working="dialog.working" :disabled="!dialog.valid" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1personHelper from '../v1helpers/v1personHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';

import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFInput from '../components/form/inputs/VFInput.vue';
import VFTextArea from '../components/form/inputs/VFTextArea.vue';

export default {
    name: 'SelectSignatureDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFRow,
        VFGroup,
        VFInput,
        VFTextArea
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            signatures: [],
            selectedIndex: -1,
        }
    },
    methods: {

        onClose() {
            this.$emit('close');
        },

        onSelect(index) {
            if (index == this.selectedIndex) {
                this.selectedIndex = -1;
            }else {
                this.selectedIndex = index;
            }
            this.onValidate();
        },

        onValidate() {
            if (this.selectedIndex > -1) {
                this.dialog.valid = true;
            }else {
                this.dialog.valid = false;
            }
        },

        async onUseAsSignature() {
            this.dialog.working = true;
            this.dialog.status = 'Updating ID-control';
            try {

                const signature = this.signatures[this.selectedIndex].signature;
                const envelopeId = this.signatures[this.selectedIndex].envelopeId;

                await v1api.setIdcFromFormSignature(this.params.person.personId, signature, envelopeId);

                const response = await v1api.getClient(this.params.clientId);
                this.$emit('reload', response);

                this.dialog.working = false;
                this.onClose();

            }catch (ex) {
                this.dialog.error = ex.message;
                console.error(ex);
            }
        },

    },
    async created() {
        this.loading = true;
        this.dialog.working = true;
        this.dialog.status = 'Looking for signatures';
        

        console.log('parsm', this.params);

        const response = await v1api.getClient(this.params.clientId);

        console.log('res', response);

        for (const key in response.client) {

            if (key != 'kyc' && key != 'report' && response.client[key].envelopeId) {
                console.log(key + ' has envelopeId', response.client[key].envelopeId);

                const envelope = await this.getEnvelope(response.client[key].envelopeId);

                console.log('envelope', envelope);

                for (const j in envelope.content.documents) {

                    const document = envelope.content.documents[j];

                    for (const i in document.signatures) {

                        

                        const signature = document.signatures[i];

                        console.log('s', signature);

                        const sPerson = {
                            firstName: signature.givenName,
                            lastName: signature.familyName,
                            dateOfBirth: signature.birthDate ? signature.birthDate.split('T')[0] : ''
                        };

                        const indicator = v1personHelper.getMatchIndicator(this.params.person, sPerson);
                        console.log('IND', indicator);

                        if (indicator > 0) {

                            this.signatures.push({
                                name: this.companyTitleCase(sPerson.firstName + ' ' + sPerson.lastName),
                                dateOfBirth: sPerson.dateOfBirth,
                                matchIndicator: indicator,
                                matchDescription: v1personHelper.getMatchDescription(indicator),
                                signature: signature,
                                envelopeId: envelope.id,
                            });

                        }

                    }

                }

                

            }

        }

        this.dialog.working = false;
        this.loading = false;
        this.onValidate();
    }
}
</script>
<style scoped>

.amlv1-signd-item {
    margin-top: -1px;
    padding: 6px 14px;
    border: solid 1px var(--input-border);
    cursor: pointer;
}
.amlv1-signd-item:hover {
    background-color: var(--item-hover);
}

.amlv1-signd-first {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.amlv1-signd-last {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.amlv1-signd-active {
    color: var(--accent-contrast);
    background-color: var(--accent);
}
.amlv1-signd-active:hover {
    color: var(--accent-contrast);
    background-color: var(--accent);
}

.amlv1-signd-name {
    font-size: 17px;
    font-weight: 500;
    color: var(--text-title);
}

.amlv1-signd-match {
    color: var(--text-weak);
}

</style>