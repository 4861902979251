import formHelper from "./formHelper";
const formValidation = {

    // Validation Loop

    validatePages(reference, pages, steps) {
        for (const i in pages) {
            const result = this.validatePage(pages[i], pages);
        }
    },

    validatePage(reference, page, pages, roleKey, done) {
        const result = {
            done: done,
            valid: true,
            list: []
        };
        for (const i in page.cards) {
            this.validateCard(reference, page.cards[i], pages, page, result.list, roleKey, done);
        }
        for (const i in result.list) {
            if (!result.list[i].valid) result.valid = false;
        }
        return result;
    },

    validateCard(reference, card, pages, page, list, roleKey, done) {
        if (formHelper.isCardVisible(reference, card, false, pages, page, roleKey)) {
            if (card.type == 'card' || card.type == 'chk-c') {
                for (const i in card.inputs) {
                    list.push({
                        index: -1,
                        key: card.inputs[i].key,
                        valid: this.validateInput(reference, card.inputs[i], pages, page, roleKey, done)
                    });
                }
            }else if (card.type == 'list' || card.type == 'chk-a') {
                //console.log('VAL CARD', card);
                for (const i in card.model) {
                    for (const j in card.inputs) {
                        list.push({
                            index: i,
                            key: card.inputs[j].key,
                            valid: this.validateListInput(reference, card, i, card.inputs[j], pages, page, roleKey, done)
                        });
                    }
                }
            }
        }else {
            return false;
        }
    },

    validateInput(reference, input, pages, page, roleKey, done) {
        if (formHelper.isInputVisible(reference, input, false, pages, page)) {
            return this.validateSingleInput(reference, input, roleKey, done);
        }
        return true;
    },

    validateListInput(reference, card, index, input, pages, page, roleKey, done) {
        if (formHelper.isListInputVisible(reference, card, input, index, false, pages, page)) {
            return this.validateSingleListInput(reference, card, index, input, roleKey, done);
        }
        return true;
    },

    validateSingleListInput(reference, card, index, input, roleKey, done) {
        if (input.type == 'buttoni' || input.type == 'helpt') return true;

        //console.log('validating input ' + input.desc.en_EN, input);

        let modelKey = input.listModel;
        if (card.type == 'chk-a') modelKey = input.key;
        const model = card.model[index][modelKey];
        let validation = card.val[index][modelKey];
        delete validation.parts;
        let stopChecks = false;



        // Check if this is a special input
        if (input.type == 'chk-i') {
            this.validateChecklistArrayItem(reference, card, index, input, roleKey);
        }else {

            // First check if input is empty but required
            if (this.isListInputRequired(reference, card, index, input, roleKey)) {

                //console.log('VALRQ', model);
                const result = this.validateRequired(model, input);
                validation.valid = result.valid;
                validation.message = result.message;
                if (!result.valid) stopChecks = true;

            }else {

                validation.valid = true;
                validation.message = '';
                stopChecks = true;

            }
            
            // Validate email
            if (!stopChecks && input.inputType == 'email') {

                const result = this.validateEmail(model);
                validation.valid = result.valid;
                validation.message = result.message;
                if (!result.valid) stopChecks = true;

            }
            
            // Validate number
            if (!stopChecks && input.inputType == 'number') {

                const result = this.validateNumber(model);
                validation.valid = result.valid;
                validation.message = result.message;
                if (!result.valid) stopChecks = true;

            }

            // Validate minimum length
            if (!stopChecks && input.min) {

                const result = this.validateMinimumLength(model, input.min);
                validation.valid = result.valid;
                validation.parts = result.parts;
                if (!result.valid) stopChecks = true;

            }

        }


        validation.done = done;
        return validation.valid;
    },

    isInputRequired(reference, input, roleKey, designer) {

        if (roleKey !== 'owner' && input.logic && input.logic.required) {

            const required = formHelper.checkLogicTrigger(reference, input, designer || false, reference.pages, 'required');

            //console.log('validate logic', required);

            return required;

        }else if ((roleKey == 'owner' && input.ownerReq) || (roleKey !== 'owner' && input.required) || (roleKey == 'owner' && reference.ownerIsEditor && input.required)) {

            return true;

        }

        return false;
    },

    isListInputRequired(reference, card, index, input, roleKey, designer) {

        if (roleKey !== 'owner' && input.logic && input.logic.required) {

            const required = formHelper.checkLogicTriggerList(reference, card, input, index, designer || false, reference.pages, reference.pages[0], 'required');

            //console.log('validate logic', required);

            return required;

        }else if ((roleKey == 'owner' && input.ownerReq) || (roleKey !== 'owner' && input.required) || (roleKey == 'owner' && reference.ownerIsEditor && input.required)) {

            return true;

        }

        return false;
    },

    validateSingleInput(reference, input, roleKey, done) {

        if (input.type == 'buttoni' || input.type == 'helpt') return true;

        //console.log('validating input ' + input.desc.en_EN, input);

        let model = input.model;
        let validation = input.val;
        delete validation.parts;
        let stopChecks = false;

        // Check if this is a special input
        if (input.type == 'chk-i') {
            this.validateChecklistItem(reference, input, roleKey);
        }else {

            // First check if input is empty but required
            if (this.isInputRequired(reference, input, roleKey, false)) {

                const result = this.validateRequired(model, input);
                validation.valid = result.valid;
                validation.message = result.message;
                if (!result.valid) stopChecks = true;

            }else {

                validation.valid = true;
                validation.message = '';
                stopChecks = true;

            }
            
            // Validate email
            if (!stopChecks && input.inputType == 'email') {

                const result = this.validateEmail(model);
                validation.valid = result.valid;
                validation.message = result.message;
                if (!result.valid) stopChecks = true;

            }
            
            // Validate number
            if (!stopChecks && input.inputType == 'number') {

                const result = this.validateNumber(model);
                validation.valid = result.valid;
                validation.message = result.message;
                if (!result.valid) stopChecks = true;

            }

            // Validate bank account NO
            if (!stopChecks && input.inputType == 'bankno') {

                const result = this.validateAccountNumberNO(model);
                //console.log('result', result);
                validation.valid = result.valid;
                validation.message = result.message;
                if (!result.valid) stopChecks = true;

            }

            // Validate minimum length
            if (!stopChecks && input.min) {

                const result = this.validateMinimumLength(model, input.min);
                validation.valid = result.valid;
                validation.parts = result.parts;
                if (!result.valid) stopChecks = true;

            }

        }


        validation.done = done;
        return validation.valid;
    },

    validateChecklistItem(reference, input, roleKey) {
        //console.log('validating checkitem final = ' + reference.chkModel.finalValidation, input);
        if (input.required || true == true) {

            if (input.isQA) {

                if (this.chkQAvalid(reference, input.model, input)) {
                    input.val.valid = true;
                }else {
                    input.val.valid = false;
                }

            }else {
                if (roleKey == 'owner') {
                    //console.log('input.model', input.model);
                    if (this.chkIDvalid(input.model, input) && this.chkVSvalid(input.model, input)) {
                        input.val.valid = true;
                    }else {
                        input.val.valid = false;
                    }
                }else {
                    if (this.chkIDvalid(input.model, input) && this.chkVSvalid(input.model, input) && this.chkQCvalid(reference, input.model, input)) {
                        input.val.valid = true;
                    }else {
                        input.val.valid = false;
                    }
                }
            }
        }else {
            input.val.valid = true;
        }
    },

    validateChecklistArrayItem(reference, card, index, input, roleKey) {
        //console.log('validating checkitem', input);

        const validation = card.val[index][input.key];

        if (input.required || true == true) {
            const model = card.model[index][input.key];

            if (input.isQA) {

                if (this.chkQAvalid(reference, model, input)) {
                    validation.valid = true;
                }else {
                    validation.valid = false;
                }

            }else {
                if (roleKey == 'owner') {
                    if (this.chkIDvalid(model, input) && this.chkVSvalid(model, input)) {
                        validation.valid = true;
                    }else {
                        validation.valid = false;
                    }
                }else {
                    if (this.chkIDvalid(model, input) && this.chkVSvalid(model, input) && this.chkQCvalid(reference, model, input)) {
                        validation.valid = true;
                    }else {
                        validation.valid = false;
                    }
                }
            }
        }else {
            validation.valid = true;
        }
    },

    chkIDvalid(model, input) {
        if (model.ids || input.ids == 'chkNone') {
            return true;
        }
        return false;
    },

    chkVSvalid(model, input) {
        if ((model.vs || input.vs == 'chkNone') || model.vsD) {
            return true;
        }
        return false;
    },

    chkQCvalid(reference, model, input) {
        if (reference.chkModel && reference.chkModel.finalValidation && model.qcc == 'chkNo') return false;
        if (model.qcr == 'chkFail' && model.qcc != 'chkNo') return false;
        if (model.qcr == 'chkPass' && model.qcc != 'chkYes') return false;
        if (model.qcr == 'chkPassF' && model.qcc == 'chkNA') return false;
        if (model.qcr == 'chkNA' && model.qcc != 'chkNA') return false;
        if ((model.qcr && model.qcc) || !reference.chkModel.finalValidation) {
            return true;
        }
        return false;
    },

    chkQAvalid(reference, model, input) {
        if (reference.chkModel && model.qcc == 'chkNo') return false;
        /* if (model.qcr == 'chkFail' && model.qcc == 'chkYes') return false; */
        if (model.qcr && model.qcc) {
            return true;
        }
        return false;
    },


    // Input validation

    validateRequired(value, input) {
        const result = {
            valid: false,
            message: ''
        };

        if (input.type == 'check') {
            // Special validation for checkbox

            let valid = false;
            for (const key in value) {
                if (value[key] == true) valid = true;
            }
            if (valid) {
                result.valid = true;
            }else {
                result.message = 'validation_required';
            }
        }else if (input.type == 'phone') {

            if (value && value.number && value.number.length > 0) {
                result.valid = true;
            }else {
                result.message = 'validation_required';
            }

        }else if (input.type == 'upload') {

            if (value && value.files && value.files.length > 0) {
                result.valid = true;
            }else {
                result.message = 'validation_required';
            }

        }else {
            if (value != null && value.toString().length > 0) {
                result.valid = true;
            }else {
                result.message = 'validation_required';
            }
        }

        
        return result;
    },

    validateEmail(email) {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const status = regex.test(String(email).toLowerCase());
        return {
            valid: status,
            message: status ? '' : 'validation_email'
        };
    },

    validateNumber(value) {
        const result = {
            valid: true,
            message: ''
        };
        const array = '1234567890,.';

        for (let i=0; i<value.toString().length; i++) {
            if (!array.includes(value.toString()[i])) result.valid = false;
        }

        if (!result.valid) {
            result.message = 'validation_number';
        }
        
        return result;
    },

    validateMinimumLength(value, min) {
        const result = {
            valid: false,
            parts: []
        };

        if (value.toString().length >= min) {
            result.valid = true;
        }else {
            result.parts.push({ type: 'loc', val: 'validation_minimum' });
            result.parts.push({ type: 'val', val: min });
            result.parts.push({ type: 'loc', val: 'validation_digits' });
        }

        return result;
    },

    validateAccountNumberNO(kontonummer) {
        const result = {
            valid: false,
            message: ''
        };

        const weights = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        const kontonummerWithoutSpacesAndPeriods = kontonummer.replace(/[\s.]+/g, '');
        if (kontonummerWithoutSpacesAndPeriods.length !== 11) {
            result.message = 'validation_account_no';
        }else {
            const sjekksiffer = parseInt(kontonummerWithoutSpacesAndPeriods.charAt(10), 10);
            const kontonummerUtenSjekksiffer = kontonummerWithoutSpacesAndPeriods.substring(0, 10);
            let sum = 0;
            for (let index = 0; index < 10; index++) {
                sum += parseInt(kontonummerUtenSjekksiffer.charAt(index), 10) * weights[index];
            }
            const remainder = sum % 11;
            if (sjekksiffer === (remainder === 0 ? 0 : 11 - remainder)) {
                result.valid = true;
            }else {
                result.message = 'validation_account_no';
            }
        }

        return result;
    }

};
export default formValidation;