<template>
<div>

    <PopupV1 :header="__t('screening-fpositive-header')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
<!--             <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ 'The hit on PEP/Sanction will defined as a false positive' + ':' }}
            </div> -->
            <div class="amd-comments">
                <TextEditorV2 :content="comment" :placeholder="__t('common-comments')" @input="onCommentInput" />
            </div>
            <div class="amd-comments" style="margin-top: 10px;">
                <VFCheckbox inline radio v-model="tabModel" :options="tabOptions" />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('common-save')"
                :icon="'check'"
                @clicked="onClick"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

import TextEditorV2 from '../components/form/TextEditorV2.vue';

export default {
    name: 'FalsePositiveDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox,
        TextEditorV2,
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            comment: '',
            completedDate: '',
            completedUser: '',
            tabModel: {
                hit: false,
                inactive: false,
                fpositive: false
            },
            tabOptions: {
                hit: { label: 'Actual hit' },
                inactive: { label: 'Inactive' },
                fpositive: { label: 'False positive' }
            }
        }
    },
    methods: {

        onCommentInput(input) {
            this.comment = input;
        },

        async onClick() {
            this.dialog.working = true;

            let falsePositive = !this.tabModel.hit;
            const params = {
                reason: this.tabModel.inactive ? 'inactive' : 'fpositive',
                comment: this.comment,
                date: new Date(),
                userId: this.$root.credentials.uid
            };
            if (!falsePositive) params.reason = '';
            await v1api.setFalsePositive(this.params.person.personId, falsePositive, params);

            const response = await v1api.getClient(this.params.clientId);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        this.tabOptions.hit.label = this.__t('screening-real');
        this.tabOptions.inactive.label = this.__t('screening-inactive');
        this.tabOptions.fpositive.label = this.__t('screening-wrong-person');

        if (this.params.person.screening.fpParams && this.params.person.screening.fpParams.date) {
            if (this.params.person.screening.falsePositive) {
                if (this.params.person.screening.fpParams.reason == 'inactive') {
                    this.tabModel.inactive = true;
                }else {
                    this.tabModel.fpositive = true;
                }
            }else {
                this.tabModel.hit = true;
            }
            this.comment = this.params.person.screening.fpParams.comment;
            this.completedDate = this.formatDate('format', this.params.person.screening.fpParams.date);
            this.completedUser = this.getEmployeeName(this.params.person.screening.fpParams.userId);
        }else {
            this.tabModel.hit = true;
        }

        this.loading = false;
    }
}
</script>