<template>
<div>

    <PopupV1 :header="'Bekreft analog signatur'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title); font-style: italic;">
                {{ 'Last opp en kopi av det signerte dokumentet' }}
            </div>
            <div class="flw100">
                <VFFileuplaod
                    v-model="files"
                    :header="__t('files-upload-header')"
                    :hintText="__t('files-upload-hint')"
                    :browseText="__t('files-upload-browse')"
                    @input="validateInputs"
                />
            </div>
            <!-- <div class="flw100" style="margin-top: 20px;">
                <VFTextArea
                    v-model="comment"
                    :placeholder="__t('report-comment') + ' (' + __('common_optional').toLowerCase() + ')'"
                />
            </div> -->
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton
                :text="'Bekreft'"
                :icon="'check'"
                @clicked="onUpload"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1receipt from '../v1helpers/v1receipt';
import dataModelV1 from '../v1data/dataModelV1';
import v2lib from '../v2lib';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';

import VFFileuplaod from '../components/form/inputs/VFFileupload.vue';
import VFTextArea from '../components/form/inputs/VFTextArea.vue';

export default {
    name: 'ConfirmAnalogDialog',
    components: {
        PopupV1,
        VFButton,
        VFFileuplaod,
        VFTextArea,
    },
    props: {
        params: { type: Object, default: null },
        client: { type: Object, default: null },
        flow: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            files: {},
        }
    },
    methods: {

        validateInputs() {
            this.dialog.valid = false;
            console.log('this.fil', this.files);
            if (this.files.files && this.files.files.length > 0) {
                this.dialog.valid = true;
            }
        },

        async onUpload() {
            this.dialog.working = true;
            this.dialog.status = 'Fetching envelope';

            let envelope;
            if (this.client.envelope.id) {
                envelope = await this.getEnvelope(this.client.envelope.id);
                console.log('envelope', envelope);
            }else {
                this.dialog.status = 'Creating new envelope for attachments';
                const extraData = {
                    dataOnly: true,
                    dataOnlyParams: {
                        title: this.flow.title[this.$root.config.params.defaultLanguage] + ': ' + this.companyTitleCase(this.client.name),
                    },
                };
                envelope = await v1receipt.createClientEnvelope(this.$root, this.client, dataModelV1.getEmptyCompany(), this.flow, extraData);

                const updateData = {
                    envelopeId: envelope.id,
                    documentId: envelope.documentId,
                    name: this.__t('datapdf-name') + ' ' + this.companyTitleCase(this.client.name),
                    bearerToken: envelope.metadata.bearerToken
                };
                await v1api.updateClient(this.client._id, updateData);
            }

            if (!envelope) {
                this.dialog.error = 'No client envelope, can not add attachments!';
                this.dialog.working = false;
                return;
            }

            this.dialog.status = 'Uploading file';

            console.log('files', this.files);
            const name = this.files.files[0].name;
            const data = this.files.files[0].data;
            await v2lib.addAttachmentToExistingEnvelope(envelope, name, data, v2lib.personalToken);

            const fileData = {
                type: 'form-manual',
                envelopeId: envelope.id,
                formKey: this.params.formKey || '',
                name: name
            };
            await v1api.uploadFile(this.client._id, fileData);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        },

    },
    async created() {
        console.log('params', this.params);
        this.validateInputs();
        this.loading = false;
    }
}
</script>