<template>
<div class="aph" v-on-clickaway="hideMenus" :class="{'aph-message': $root.status.checked && $root.status.degraded && $route.path != '/kyc'}">
    <div class="aph-logo">
        <div class="aph-logo-logo">
            <img :src="$root.config.portal.logoUrl" :style="'width:' + $root.config.portal.logoWidth + 'px; margin-top:' + $root.config.portal.logoTop + 'px; margin-left:' + $root.config.portal.logoLeft + 'px;'" />
        </div>
        <div class="aph-logo-title">
            <label v-if="!$root.phoneMode" :style="'left:' + ($root.config.portal.logoWidth + 40) + 'px;'">{{ $root.config.portal.header }}</label>
        </div>
    </div>
    

    <div class="aph-nav-holder" v-if="!$root.phoneMode">
        <div id="aph-nav">
            <router-link v-if="!$root.config.portal.useV1" :to="basePath">{{ __('dashboard').toUpperCase() }}</router-link>
            <router-link v-if="$root.config.portal.useV1" :to="basePath + 'v1events'">
                {{ __('dashboard').toUpperCase() }}
            </router-link>
            <router-link :to="basePath + ($root.config.portal.useV1 ? 'v1clients' : 'clients')">{{ __('customers').toUpperCase() }}</router-link>
            <router-link v-if="$root.config.portal.useV1 && $root.config.roles.linkEnabled" :to="basePath + 'v1roles'">{{ __t('header-roles').toUpperCase() }}</router-link>
            <!-- <router-link v-if="$root.config.portal.useV1" :to="basePath + 'v1reports'">{{ __('reports').toUpperCase() }}</router-link> -->
            <router-link v-if="$root.config.modules.personSearch" :to="basePath + 'search'">{{ __('main_search').toUpperCase() }}</router-link>
            <a v-if="$root.isAWS" :href="$parent.user.dashboardLink" >{{ __('archive').toUpperCase() }}</a>
            <router-link v-if="$root.credentials.role == 'admin'" :to="basePath + ($root.config.portal.useV1 ? 'v1settings' : 'settinglist')">{{ __('settings').toUpperCase() }}</router-link>

            <router-link v-if="$root.config.portal.migrate" :to="basePath + 'clients'">{{ 'Gamle kunder'.toUpperCase() }}</router-link>
            <router-link v-if="$root.credentials.cid == 'r1MZtfWW7'" :to="basePath + 'backoffice'">{{ 'Backoffice'.toUpperCase() }}</router-link>
            <a v-if="$root.config.portal.migrateLink" :href="migrateLinkNetlify" target="_blank">{{ migrateLinkNetlifyName.toUpperCase() }}</a>
        </div>
    </div>

    <div class="aph-right">
        <HeaderMessages v-if="$root.config.events.messages" />
        <div class="aph-theme" @click="setDarkMode(!$root.darkMode)" v-if="$root.config.portal.enableDarkMode || $root.isDEV">
            <vap-icon :icon="$root.darkMode ? 'night' : 'day'" :color="'var(--header-text)'" />
        </div>
        <div class="aph-lang" @click="langMenuOpen = !langMenuOpen" :class="{'aph-lang-phone': $root.phoneMode }">
            <FlagIcon :locale="$root.locale.key" circle />
            <vap-dropdown-menu v-if="langMenuOpen" :top="'20'" :right="'15'">
                <div class="aph-user-menu">
                    <div class="aph-lang-menu-item" v-for="(locale, i) in $root.config.locales" :key="i" @click="langClicked(locale.key)" v-show="locale.enabled">
                        <img :src="$root.images.flags[locale.key]" />
                        <label>{{ locale.name }}</label>
                    </div>
                </div>
            </vap-dropdown-menu>
        </div>
        <div class="aph-user" :class="{'aph-user-aws': $root.isAWS}" @click="userClicked" v-if="!$root.phoneMode">
            <div class="aph-user-name">
                <label>{{ $parent.user.givenName + ' ' + $parent.user.familyName }}</label>
            </div>
            <div class="aph-user-company">
                <label>{{ $parent.user.company }}</label>
            </div>
            <vap-dropdown-menu v-if="userMenuOpen" :top="'22'" :right="'12'">
                <div class="aph-user-menu">
                    <div class="aph-user-menu-item" @click="goToDashboard">
                        <label>{{ __('verified_archive') }}</label>
                    </div>
                    <div class="aph-user-menu-item" @click="logout">
                        <label>{{ __('log_out') }}</label>
                    </div>
                </div>
            </vap-dropdown-menu>
        </div>
    </div>

<!--     <div class="aph-burger" v-if="$root.phoneMode">
        <svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" style="transform: scale(1.5);">
            <path d="M 3 5 A 1.0001 1.0001 0 1 0 3 7 L 21 7 A 1.0001 1.0001 0 1 0 21 5 L 3 5 z M 3 11 A 1.0001 1.0001 0 1 0 3 13 L 21 13 A 1.0001 1.0001 0 1 0 21 11 L 3 11 z M 3 17 A 1.0001 1.0001 0 1 0 3 19 L 21 19 A 1.0001 1.0001 0 1 0 21 17 L 3 17 z"/>
        </svg>
    </div> -->

<!--     <div class="aph-mmh" v-if="$root.phoneMode && mainMenuOpen">
        <div class="aph-mmb" />
        <div class="aph-mmc">

            <div class="aph-burger-nav">
                <router-link to="/">{{ __('dashboard').toUpperCase() }}</router-link>
                <router-link to="/customers">{{ __('customers').toUpperCase() }}</router-link>
                <router-link to="/settinglist">{{ __('settings').toUpperCase() }}</router-link>
            </div>

        </div>
    </div> -->

    <div class="aph-sorry" v-if="$root.status.checked && $root.status.degraded && $route.path != '/kyc'">
        <div class="aph-sorry-center">
            <div class="aph-sorry-info">
                <label>{{ '!' }}</label>
            </div>
            <div class="aph-sorry-content">
                <div class="aph-sorry-title">
                    <label>{{ __($root.status.headerKey) }}</label>
                </div>
                <div class="aph-sorry-text">
                    <label>{{ __($root.status.messageKey) + ' ' }}</label>
                    <a href="https://status.verified.eu/" target="_blank">{{ __('sorry_details') }}</a>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import locale from '../../locale'
import { mixin as clickaway } from "vue-clickaway"
import v2lib from '../../v2lib';

import FlagIcon from '../../v1ui/FlagIcon.vue';
import HeaderMessages from './HeaderMessages.vue';

export default {
    name: 'PortalHeader',
    mixins: [clickaway],
    components: {
        FlagIcon,
        HeaderMessages,
    },
    props: {
        editor: {
			type: Boolean,
			default: false
        },
        title: {
			type: String,
			default: ''
		},
        basePath: {
			type: String,
			default: '/'
		}
    },
    data() {
        return {
            mainMenuOpen: false,
            userMenuOpen: false,
            langMenuOpen: false,
            migrateLinkNetlify: '',
            migrateLinkNetlifyName: 'Gamle kunder',
        }
    },
    methods: {
        setDarkMode(value) {
            this.$root.darkMode = value;
            this.$parent.loadColors();
            this.saveSettings();
        },
        hideMenus() {
            this.userMenuOpen = false;
            this.langMenuOpen = false;
        },
        logout() {
            this.$emit('logout', true);
        },
        goToDashboard() {
            window.location.href = this.$parent.user.dashboardLink;
        },
        userClicked() {
            if (this.$root.isAWS) return;
            this.userMenuOpen = !this.userMenuOpen;
        },
        langClicked(lang) {
            this.$root.locale = locale.getLocale(this.$root.config.locales, lang);
            this.saveSettings();
        },
        saveSettings() {
            const settings = {
                locale: this.$root.locale.key,
                darkMode: this.$root.darkMode
            }
            localStorage.setItem('settings', JSON.stringify(settings));
        }
    },
    created() {
        try {
            this.migrateLinkNetlify = this.$root.config.portal.migrateLinkNetlify;
            if (this.migrateLinkNetlify.includes('deloitte')) {
                this.migrateLinkNetlify += '/open#?access_token=' + v2lib.personalToken;
                this.migrateLinkNetlify += '&companyId=' + this.$root.credentials.cid;
                this.migrateLinkNetlify += '&lang=' + this.$root.locale.key;
                this.migrateLinkNetlifyName = 'Verified 1.0';
            }
        }catch (ex) {
            
        }
        
    }
}
</script>

<style>

.aph {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: var(--header);
}
.aph-message {
    height: 120px;
}

.aph-logo {
    position: absolute;
    height: 60px;
    display: flex;
}

.aph-logo-logo {
    border-right: 1px solid var(--header-sep);
}
.aph-logo img {
    margin-right: 20px;
}

.aph-logo-title {
    padding-top: 18px;
    padding-left: 20px;
}
.aph-logo label {
    white-space: nowrap;
    top: 13px;
    /* font-family: "Roboto Slab", serif; */
    font-size: 22px;
    color: var(--header-text);
}



.aph-nav-holder {
    position: absolute;
    top: 0px;
    width: 100%;
    pointer-events: none;
}
#aph-nav {
    margin-top: 21px;
    text-align: center;
}
#aph-nav a {
    position: relative;
    display: inline;
    font-size: 18px;
    color: var(--header-link);
    text-decoration: none;
    margin-right: 14px;
    pointer-events: all;
}
#aph-nav a:hover {
    color: var(--header-link-active);
}



.aph-right {
    float: right;
    display: flex;
}

.aph-user {
    position: relative;
    margin-top: 8px;
    margin-right: 20px;
    padding: 3px 10px;
    color: var(--header-text);
    text-align: end;
    border-radius: 3px;
    cursor: pointer;
}
.aph-user label {
    cursor: pointer;
}
.aph-user:hover {
    background-color: var(--header-hover);
}
.aph-user-name {
    font-size: 15px;
}
.aph-user-company {
    font-size: 15px;
    opacity: 0.8;
}
.aph-user-menu {
    margin: 2px 2px;
    background-color: var(--header);
}
.aph-user-menu-item {
    text-align: left;
    color: var(--header-text);
    padding: 4px 8px;
    cursor: pointer;
}
.aph-user-menu-item:hover {
	background-color: var(--header-hover);
}
.aph-user-menu-item label {
    position: relative;
    top: 0px; left: 0px;
    font-family: "Roboto Slab", serif;;
    font-size: 18px;
    cursor: pointer;
}


.aph-lang {
    position: relative;
    height: 60px;
    margin-right: 20px;
    padding: 20px 20px;
    padding-bottom: 0px;
    border-radius: 3px;
    cursor: pointer;
    border-left: solid 1px var(--header-sep);
    border-right: solid 1px var(--header-sep);
    box-sizing: border-box;
}
.aph-lang-phone {
    margin-right: 0px;
    border-right: none;
}
.aph-lang:hover {
    background-color: var(--header-hover);
}
.aph-lang img {
    width: 24px;
    border-radius: 2px;
}
.aph-lang-menu-item {
    position: relative;
    height: 24px;
    color: var(--header-text);
    padding: 5px 5px;
    cursor: pointer;
}
.aph-lang-menu-item img {
    position: absolute;
    width: 24px;
    top: 9px; left: 10px;
    border-radius: 2px;
}
.aph-lang-menu-item label {
    position: absolute;
    top: 8px; left: 45px;
    cursor: pointer;
}
.aph-lang-menu-item:hover {
	background-color: var(--header-hover);
}



.aph-theme {
    padding-top: 12px;
    padding-left: 14px;
    padding-right: 14px;
    border-left: solid 1px var(--header-sep);
    cursor: pointer;
}
.aph-theme:hover {
    background-color: var(--header-hover);
}



.aph-burger {
    float: right;
    cursor: pointer;
    margin-top: 16px;
    margin-right: 20px;
    box-sizing: border-box;
}
.aph-burger path {
    fill: var(--header-text);
}
.aph-burger:hover path {
    fill: var(--header-hover);
}

.aph-mmh {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    padding-top: 60px;
    z-index: 10;
    box-sizing: border-box;
}
.aph-mmb {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.9;
}
.aph-mmc {
    position: absolute;
    right: 0px;
    width: 80%;
    min-height: 100vh;
    background-color: var(--card-background);
}

.aph-burger-nav {
    
}
.aph-burger-nav a {
    float: left;
    width: 100%;
    padding: 5px 0px;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    margin-right: 14px;
    border-bottom: 1px solid gray;
}
.aph-burger-nav a:hover {
    background-color: lightgray;
}

.aph-sorry {
    position: absolute;
    width: 100%;
    height: 60px;
    top: 60px;
    left: 0px; right: 0px;
    background-color: #F4E7C3;
    border-bottom: 1px solid #CFC4A5;
}

.aph-sorry-center {
    display: flex;
    width: fit-content;
    margin: 0 auto;
    padding-top: 12px;
}

.aph-sorry-content {
    margin-left: 15px;
}

.aph-sorry-title {
    font-weight: 500;
}
.aph-sorry-text {
    font-size: 15px;
    color: var(--text-title);
}
.aph-sorry-text a {
    font-weight: 500;
    text-decoration: none;
    color: #238CCB;
}

.aph-sorry-info {
    width: 26px; height: 26px;
    margin-top: 4px;
    padding-top: 4px;
    padding-left: 11px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #EDC250;
    box-sizing: border-box;
}



.aph-user-aws {
    cursor: default;
}
.aph-user-aws:hover {
    background-color: transparent;
}
.aph-user-aws label {
    cursor: default;
}

</style>
