<template>
    <div>
        <vap-expandcard v-for="(signer, i) in signers" :key="i" 
                    :expanded="signer.expanded" 
                    @expand-click="signer.expanded = !signer.expanded" >
            <template v-slot:top>
                <div v-if="!signer.saved" class="vep-add">
                    <div class="vep-add-icon">
                        <svg height="32" version="1.1" width="32" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.55);">
                            <path d="M14.5 1.5C14.5 0.7 15.2 0 16 0C16.8 0 17.5 0.7 17.5 1.5V30.5C17.5 31.3 16.8 32 16 32C15.2 32 14.5 31.3 14.5 30.5V1.5Z"/>
                            <path d="M30.5 14.5C31.3 14.5 32 15.2 32 16C32 16.8 31.3 17.5 30.5 17.5H1.5C0.7 17.5 0 16.8 0 16C0 15.2 0.7 14.5 1.5 14.5H30.5Z"/>
                        </svg>
                    </div>
                    <div class="vep-add-text">
                        <div class="vep-add-text1">
                            <label v-if="!selectEditor">{{ __('recipient_add') }}</label>
                            <label v-if="selectEditor">{{ __('recipient_select') }}</label>
                        </div>
                        <div class="vep-add-text2">
                            <!-- <label>Eller velg en person fra listen til høyre</label> -->
                        </div>
                    </div>
                </div>
                <div v-if="signer.saved" class="vep-add">
                    <div class="vep-add-icon">
                        <label>{{ signer.fname.substring(0,1).toUpperCase() + signer.lname.substring(0,1).toUpperCase() }}</label>
                    </div>
                    <div class="vep-add-text-n">
                        <div class="vep-add-text1">
                            <label>{{ signer.fname + ' ' + signer.lname }}</label>
                        </div>
                        <div class="vep-add-text2">
                            <label>{{ signer.email }}</label>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:content>

                <div class="flw100" style="padding: 10px 20px; padding-bottom: 20px;">

                    <div class="flw100" style="padding-top: 10px; padding-left: 10px; padding-right: 12px; padding-bottom: 5px;">
                        <!-- <label>Detaljer</label> -->
                        <label v-if="!internal" style="float: right; color: #3190D5; cursor: pointer;" @click="showPersonList(i)" >
                            {{ __('recipient_list') }}</label>
                        <label v-if="internal" style="float: right; color: #3190D5; cursor: pointer;" @click="showPersonList(i)" >
                            Velg person fra intern liste</label>
                    </div>

                    <vap-row style="padding: 0px 5px;">
                        <vap-group c6>
                            <vap-label :text="__('common_first_name')" desc required />
                            <vap-input v-model="signer.fname" :placeholder="__('common_required')" :rules="'required'" noHighlight></vap-input>
                        </vap-group>
                        <vap-group c6>
                            <vap-label :text="__('common_last_name')" desc required />
                            <vap-input v-model="signer.lname" :placeholder="__('common_required')" :rules="'required'" noHighlight></vap-input>
                        </vap-group>
                        <vap-group c6>
                            <vap-label :text="__('common_email')" desc required />
                            <vap-input v-model="signer.email" :placeholder="__('common_required')" :rules="'required|email'" noHighlight></vap-input>
                        </vap-group>
                        <vap-group c6>
                            <vap-label :text="__('common_language')" desc required />
                            <LanguageDropdown v-model="signer.language" :placeholder="__('common_required')" noHighlight />
                        </vap-group>
                    </vap-row>

                    <div v-if="!(selectEditor && hideEditorAction)" class="flw100" style="margin-top: 10px; margin-left: 10px; margin-bottom: 5px;">
                        <label>{{ __('recipient_action_question') }}</label>
                    </div>

                    <div v-if="!selectEditor" style="padding: 0px 10px; margin-top: 0px;">
                        <ve-actionswitch combineBottom
                            v-model="signer.sign"
                            :text="__('recipient_action_sign')" 
                            :showingHelp="false"
                            :disabled="false"
                            :selectOptions="selectOptions"
                            :selectValue="signer.method"
                            @select="setMethod(signer, $event)"
                             />
                        <ve-actionswitch combineTop
                            v-model="signer.approve"
                            :text="__('recipient_action_approve')" 
                            :showingHelp="false"
                            :disabled="false" />
                    </div>

                    <div v-if="selectEditor && !hideEditorAction" style="padding: 0px 10px; margin-top: 0px;">
                        <ve-actionswitch
                            v-model="editorValue"
                            :text="'Fylle i skjema'" 
                            :showingHelp="false"
                            :disabled="true" />
                    </div>

                    <div v-if="signer.method == 'TouchSign'" style="float: left; width: 100%; padding: 0px 20px; margin-top: 20px;">
    <!--                     <ve-fileupload v-model="signer.fileUpload" 
                            :singleFile="true" 
                            :autoPreview="true"
                            :header="'Legitimasjon'"
                            :helpText="'Dra og slipp fil her eller'"
                            :uploadText="'last opp'" /> -->
                    </div>

                    <div v-if="!hasInfo(signer)" class="flw100" style="text-align: right; padding-right: 12px; margin-top: 16px; color: #B62537;">
                        <label>{{ __('recipient_error_details') }}</label>
                    </div>
                    <div v-if="hasInfo(signer) && !hasAction(signer)" class="flw100" style="text-align: right; padding-right: 12px; margin-top: 16px; color: #B62537;">
                        <label>{{ 'Velg hva ' + signer.fname + ' skal gjøre' }}</label>
                    </div>
                    <div v-if="hasInfo(signer) && signer.sign && signer.approve" class="flw100" style="text-align: right; padding-right: 12px; margin-top: 16px; color: #B62537;">
                        <label>{{ 'Velg enten Signere eller Godkjenne' }}</label>
                    </div>

                    <vap-button-row alignRight class="flw100" style="margin-top: 20px; padding-right: 10px;">
                        <vap-button :text="__('common_cancel')" rounded outline uppercase @clicked="signer.expanded = !signer.expanded" />
                        <vap-button :text="__('common_save')" rounded uppercase @clicked="saveSigner(signer)" :disabled="!hasInfo(signer) || !hasAction(signer) || (signer.sign && signer.approve)" />
                    </vap-button-row>

                </div>
            </template>
        </vap-expandcard>

        <ve-popup v-if="showList" @close="showList = false" :header="'Velg person'">
            <div style="max-height: 630px; overflow: auto;">
                <vap-item v-for="(person, i) in persons" :key="i" padding20 @clicked="setSignerFromList(person)">
                    <label style="color: var(--text-accent); font-weight: bold;">
                        {{ person.firstName + ' ' + person.lastName }}
                    </label>
                    <label v-if="person.dateOfBirth" style="color: darkgray; font-weight: bold; margin-left: 7px;">
                        {{ '(' + person.dateOfBirth + ')' }}
                    </label>
                    <br>
                    <label>{{ getRole(person) }}</label>
                </vap-item>
            </div>
        </ve-popup>

    </div>
</template>

<script>
import validations from '../../validations'

import LanguageDropdown from '../special/LanguageDropdown'

export default {
    name: 'new-checkout',
    components: {
        LanguageDropdown
    },
    props: {
        value: {
            type: Array,
            default: undefined
        },
        persons: {
            type: Array,
            default: undefined
        },
        selectEditor: {
            type: Boolean,
            default: false
        },
        internal: {
            type: Boolean,
            default: false
        },
        initialData: {
            type: Array,
            default: undefined
        }
    },
    data() {
        return {
            signers: [],
            showList: false,
            listIndex: 0,
            selectOptions: [
                'E-post', 'BankID', 'TouchSign'
            ],
            editorValue: true,
            hideEditorAction: true
        }
    },
    computed: {
        
    },
    watch: {
        
    },
    methods: {
        updateVmodel() {
            let value = [];
            for (let i in this.signers) {
                value.push({
                    fname: this.signers[i].fname,
                    lname: this.signers[i].lname,
                    email: this.signers[i].email,
                    sign: this.signers[i].sign,
                    approve: this.signers[i].approve,
                    language: this.signers[i].language,
                    method: this.signers[i].method,
                    methodKey: this.getMethodKey(this.signers[i].method),
                });
            }
            this.$emit('input', value);
        },
        getMethodKey(method) {
            if (method == 'BankID') {
                return 'bankid-no';
            }else if (method == 'TouchSign') {
                return 'touch-sign';
            }
            return 'email';
        },
        hasInfo(signer) {
            let result = validations.validateEmail(signer.email);
            return signer.fname && signer.lname && result.valid && signer.language;
        },
        hasAction(signer) {
            return signer.sign || signer.approve;
        },
        setMethod(signer, method) {
            signer.method = method;
            signer.methodKey = this.getMethodKey(method);
            this.$forceUpdate();
        },
        showPersonList(index) {
            this.listIndex = index;
            this.showList = true;
        },
        setSignerFromList(person) {
            this.signers[this.listIndex].fname = person.firstName;
            this.signers[this.listIndex].lname = person.lastName;
            if (person.email) this.signers[this.listIndex].email = person.email;
            this.showList = false;
        },
        saveSigner(signer) {
            signer.saved = true;
            signer.expanded = false;
            if (!this.selectEditor && !this.hasEmptySigner()) this.addEmptySigner(false);
            this.updateVmodel();
        },
        hasEmptySigner() {
            for (let i in this.signers) {
                if (!this.signers[i].fname && !this.signers[i].lname && !this.signers[i].email) {
                    return true;
                }
            }
            return false;
        },
        addEmptySigner(expanded) {
            this.signers.push({
                expanded: expanded,
                saved: false,
                sign: true,
                approve: false,
                fname: '',
                lname: '',
                email: '',
                language: 'en_EN',
                method: 'BankID',
                methodKey: 'bankid-no',
                fileUpload: {}
            });
        },
        getRole(person) {
            console.log('person', person)
            let count = 0;
            let str = '';
            for (const i in person.roleIds) {
                if (count > 0) str += ', ';
                str += this.__(person.roleIds[i]);
                count += 1;
            }
            return str;
        }
    },
    async mounted() {
        if (this.initialData && this.initialData.length > 0) {
            for (let i in this.initialData) {
                this.signers.push(this.initialData[i]);
            }
            this.updateVmodel();
        }else {
            this.addEmptySigner(!this.internal);
        }
    }
}
</script>
<style scoped>

.vep-add {
    position: relative;
    float: left;
    width: 100%;
    padding: 14px;
}

.vep-add-icon {
    float: left;
    margin-top: 2px;
    margin-left: 10px;
    width: 38px;
    height: 38px;
    padding-top: 3px;
    padding-left: 0px;
    border-radius: 50%;
    background-color: var(--primary);
    text-align: center;
    box-sizing: border-box;
}
.vep-add-icon path {
    fill: white;
}
.vep-add-icon label {
    display: block;
    font-size: 17px;
    font-weight: normal;
    color: white;
    padding-top: 6px;
}

.vep-add-text {
    float: left;
    margin-left: 16px;
    margin-top: 10px;
}
.vep-add-text-n {
    float: left;
    margin-left: 16px;
    margin-top: 2px;
}

.vep-add-text1 label {
    margin-top: 9px;
    font-size: 17px;
    font-weight: bold;
    color: var(--primary);
    cursor: pointer;
}

.vep-add-text2 {
    margin-top: -1px;
}
.vep-add-text2 label {
    cursor: pointer;
}

.flw100 {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

</style>