<template>
<div>

    <PopupV1 :header="header" @close="onClose" :dialog="dialog" formContainer>
        <template v-slot:content>
            
            <FormStepper :steps="steps" :offset="60" :stepKey="stepKey" :bottomSlotHeight="stepperMessageHeight"
                :title="title ? title[$root.locale.key] : ''"
                :formValid="validation.formValid"
                :canSave="canSave"
                :hasChanges="hasChanges"
                :isSaving="isSaving"
                :viewOnly="viewOnly"
                @keychanged="onStepperKeyChanged"
                @submit="onStepperSubmit"
                @save="saveForm"
            >
                <template v-slot:bottom v-if="stepperMessageHeight > 0">
                    <FormStepperMessage />
                </template>
            </FormStepper>

            <div v-if="pages[pageIndex]" :class="[
                pages[pageIndex].mode || 'form-page',
                { 'no-stepper' : !pages[pageIndex].showStepper }
            ]">
                <FormRenderer ref="renderer" isPopup
                    :page="pages[pageIndex]"
                    :pages="pages"
                    :locales="locales"
                    :resources="resources"
                    :roleKey="roleKey"
                    @action="handleAction"
                    @link="handleLinkValue"
                    @validate="onValidateInput"
                    @personinput="onPersonInput"
                    @personlist="showPersonList"
                    @match="onDictionaryMatch"
                />
            </div>

        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="submitText" @clicked="onStepperSubmit" :working="dialog.working" :disabled="!validation.formValid || dialog.error != ''" />
        </template>
    </PopupV1>

    <PersonListDialog v-if="personListParams.show" :params="personListParams" @close="personListParams.show = false" @select="onSelectPerson" />
  
</div>
</template>

<script>
import api from '../../portalapi';
import v1api from '../../v1helpers/v1api';
import viewHelper from './viewHelper';

import FormStepper from './FormStepper.vue';
import FormStepperMessage from './FormStepperMessage.vue';
import FormRenderer from './Renderer.vue';

import PersonListDialog from './modules/kyc/PersonListDialog.vue';

import PopupV1 from '../../v1ui/PopupV1.vue';
import VFButton from './items/VFButton.vue';

export default {
    name: 'FormPopup',
    components: {
        FormStepper,
        FormStepperMessage,
        FormRenderer,
        PersonListDialog,

        PopupV1,
        VFButton,
    },
    props: {
        clientId: { type: String, default: '' },
        initialFormKey: { type: String, default: '' },
        isV0: { type: Boolean, default: false },
    },
    data() {
        return {
            testLocally: false,
            loading: true,
            formKey: '',
            title: null,
            steps: [],
            stepKey: '',
            pages: [],
            pageIndex: 0,
            roles: [],
            roleKey: '',
            resources: {},
            validation: {
                formValid: false,
                disabled: false,
                isValidating: false,
            },
            ownerIsEditor: false,
            attachments: [],
            autoSave: false,
            canSave: false,
            isSaving: false,
            hasChanges: false,
            isRedirected: false,
            viewOnly: false,

            personListParams: { show: false },

            existingFiles: [],
            existingValues: {},

            dialog: { show: false, valid: false, working: false, status: '', error: '' },
            isOwner: true,
            formData: null,

            header: 'Form',
            submitText: 'Submit',

            locales: [],

            logic: [],
            logicResults: {},

            flags: {},

            autoSaveLoaded: false,
            ignoreAutoSave: false,
            stepperMessageHeight: 0,

            clientTags: [],
        }
    },
    methods: {

        onClose() {
            this.$emit('close');
        },

        onClick() {

        },



        onDictionaryMatch(input, value, card, index) {
            viewHelper.onDictionaryMatch(this, input, value, card, index);
        },

        onPersonInput(card, input, index) {
            viewHelper.onPersonInput(this, card, input, index);
        },

        showPersonList(card, index) {
            viewHelper.showPersonList(this, card, index);
        },

        onSelectPerson() {
            this.validateEverything();
        },

        //#region Stepper Interface -------------------------------------------------------------------------------------------

        onStepperKeyChanged(event) {
            viewHelper.onStepperKeyChanged(this, event);
        },

        async onStepperSubmit() {
            viewHelper.onStepperSubmit(this);
        },

        preparePdfData(userdata) {
            viewHelper.preparePdfData(this, userdata);
        },

        redirectToLanding() {
            //console.log('redirect 2');
            this.isRedirected = true;
            const query = { reload: true };
            for (const key in this.$route.query) {
                query[key] = this.$route.query[key];
            }
            this.$parent.$parent.navigate({ path: `/lform/${this.$route.params.envelopeId}/${this.$route.params.recipientId}`, query: query });
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Flow --------------------------------------------------------------------------------------------------------

        handleAction(type, key) {
            viewHelper.handleAction(this, type, key);
        },

        handleLinkValue(link, value) {
            viewHelper.handleLinkValue(this, link, value);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Validation --------------------------------------------------------------------------------------------------

        onValidateInput(input, index) {
            this.validation.isValidating = true;
            viewHelper.onValidateInput(this, input, index);
            this.$nextTick(() => {
                this.validation.isValidating = false;
            });
        },

        updateValidation() {
            viewHelper.updateValidation(this);
        },

        validateEverything() {
            viewHelper.validateEverything(this);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Data processing ---------------------------------------------------------------------------------------------

        async saveForm() {
            viewHelper.saveForm(this);
        },

        processNewData(formData) {
            viewHelper.processNewData(this, formData);
        },

        processUserData2(formData, userdata, existingData) {
            viewHelper.processUserData2(this, formData, userdata, existingData);
        },
        
        processUserData(userdata) {
            viewHelper.processUserData(this, userdata);
        },

        processSteps() {
            viewHelper.processSteps(this);
        },

        goToNextRole() {
            viewHelper.goToNextRole(this);
        },

        getNextRole() {
            viewHelper.getNextRole(this);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        showMainSpinner(show, text) {
            /* if (show) {
                this.$parent.$parent.setLoading(true, text || this.__('loading') + '...');
            }else {
                this.$parent.$parent.setLoading(false);
            } */

            this.dialog.working = show || false;
            this.dialog.status = text || '';

        },

        showError(show, text) {
            this.dialog.error = text;
            this.dialog.working = false;
            if (!show) this.dialog.error = '';
        },

        async onFormCompleted() {
            this.dialog.working = true;
            this.dialog.status = 'Waiting for PDF generation';

            await new Promise(r => setTimeout(r, 4000));

            this.dialog.status = 'Updating customer';
            await new Promise(r => setTimeout(r, 2000));

            if (this.isV0) {

                // Support for V0, remove when ready

                const response = await api.getCustomer(this.clientId);
                this.$emit('reload', response.customer);

            }else {

                const response = await v1api.getClient(this.clientId);
                this.$emit('reload', response);

            }

            this.onClose();
        },

        setTitle(title) {
            this.header = title;
        },

        onAttachmentDownloaded(attachment, data) {
            viewHelper.onAttachmentDownloaded(this, attachment, data);
        },

        setAutoSaveLoaded() {
            this.autoSaveLoaded = true;
            this.stepperMessageHeight = 46;
        },

        hideAutoSaveMessage() {
            this.stepperMessageHeight = 0;
        },

        onIgnoreAutoSave() {
            this.ignoreAutoSave = true;
        },

    },
    async created() {

        const response = await api.getForm(this.initialFormKey);
        if (response.formData) {
            this.formData = response.formData;
        }else {
            this.showError(true, 'No form exists with key: ' + this.initialFormKey);
        }

        await viewHelper.onCreated(this);

        if (this.pages[this.pageIndex].submitText) {
            this.submitText = this.pages[this.pageIndex].submitText[this.$root.locale.key];
        }
    }
}
</script>