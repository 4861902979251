<template>
<div>

    <vap-row>
        <vap-group c12>
            <vap-label :text="__('econ_accountant')" desc />
            <vap-label :text="company.economy.accountant.name || __('common_unknown')" value />
        </vap-group>
        <vap-group c12>
            <vap-label :text="__('econ_auditor')" desc />
            <vap-label :text="company.economy.auditor.name || __('common_unknown')" value />
        </vap-group>
    </vap-row>

</div>
</template>

<script>
import log from '@/log';

export default {
    name: 'EconomyInfo',
    components: {
        
    },
    props: {
        company: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            loading: true,
        }
    },
    watch: {
        
    },
    methods: {
        


    },
    async created() {

        

        this.loading = false;
    }
}
</script>
<style scoped>



</style>
