<template>
<div>

    <PopupV1 :header="__t('form-trigger')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('form-trigger-hint') }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('form-trigger')"
                :icon="'update'"
                @clicked="triggerUpdate"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid || envelope == null"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1test from '../v1helpers/v1test';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';

export default {
    name: 'TriggerUpdateDialog',
    components: {
        PopupV1,
        VFButton,
    },
    props: {
        params: { type: Object, default: null },
        client: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            envelope: null,
        }
    },
    methods: {

        async triggerUpdate() {
            this.dialog.working = true;
            this.dialog.status = 'Triggering update';
            
            await v1test.testFormSubmit(this.client._id, this.envelope.content);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.dialog.show = false;
            this.onClose();
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        this.loading = true;
        this.dialog.working = true;
        this.dialog.status = 'Loading envelope';

        console.log('params', this.params);

        const formKey = this.params.params.key;

        if (this.client[formKey] && this.client[formKey].envelopeId) {
            this.envelope = await this.getEnvelope(this.client[formKey].envelopeId);
        }

        console.log('envelope', this.envelope);

        this.dialog.working = false;
        this.loading = false;
    }
}
</script>