import log from '../log';
import v1api from '../v1helpers/v1api';

const v1messages = {

    //#region Events ------------------------------------------------------------------------------------------------------

    async startUpdateLoop(root) {
        if (root.eventUpdateActive) {
            root.eventLastUpdate = new Date();
            log.x('Events: Loop already started');
            return;
        }
        this.getEvents(root);

        root.eventLastUpdate = new Date();

        const interval = Number(root.config.events.updateInterval || 150);

        root.eventUpdateActive = true;
        log.x('Events: Update loop started');
        while (root.eventUpdateActive) {

            await new Promise(r => setTimeout(r, 10000));

            const diffTime = Math.abs(new Date() - new Date(root.eventLastUpdate));
            const lastUpdate = Math.ceil(diffTime / 1000); // Seconds

            log.x('Events: Seconds since last fetch', lastUpdate);

            if (lastUpdate >= interval) {
                root.eventLastUpdate = new Date();
                log.x('Events: Fetching new events');
                this.getEvents(root);
            }

        }
    },

    async getEvents(root) {

        const fromDate = this.addDays(new Date(), Number(0 - Number(root.config.events.maxEventDays)));
        const toDate = this.addDays(new Date(), 1);

        const sort = { date: -1 };
        const filters = [
            { field: 'prio', op: 'x', values: ['med', 'high'] },
            { field: 'date', op: 'daterange', from: fromDate, to: toDate },
        ];
        const from = 0;
        const limit = 1000;
        const include = [];
        const counts = ['prio'];

        const response = await v1api.queryEvents(filters, from, limit, sort, include, counts);
        root.eventCache = response.events;
        
        this.setNewEventCount(root);
    },

    addDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    },

    async setNewEventCount(root) {
        const ackEvents = this.loadAcknowledgedEvents(root);

        root.events = [];
        root.eventPriority = 0;

        for (const i in root.eventCache) {
            let match = false;
            for (const j in ackEvents) {
                if (root.eventCache[i]._id == ackEvents[j].id) {
                    match = true;
                }
            }
            if (!match) {
                root.events.push({
                    id: root.eventCache[i]._id,
                });
                if (root.eventCache[i].color == 'e') root.eventPriority = 1;
            }
        }

        //console.log('root.events', root.events);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------

    loadAcknowledgedEvents(root) {
        let events = [];
        try {
            if (localStorage.getItem('ackEvents')) {
                events = JSON.parse(localStorage.getItem('ackEvents'));
            }
            if (events.length > 0) {
                const maxDays = Number(root.config.events.maxEventDays);
                for (let i = events.length - 1; i > -1; i--) {
                    const date = new Date(events[i].date);
                    const diff = new Date() - date;
                    const diffDays = diff / 1000 / 60 / 60 / 24;
                    if (diffDays > maxDays) {
                        events.splice(i, 1);
                    }
                }
                localStorage.setItem('ackEvents', JSON.stringify(events));
            }
        }catch (ex) {
            console.error(ex);
            log.w('Home: Failed to load acknowledged events');
        }
        //console.log('this.acknowledgedEvents', events);
        return events;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default v1messages;