<template>
<div>
    <div class="vfte" :class="{'vfte-designer': designer }">
        
        <div class="vfte-toolbar" v-if="!disabled && !raw">

            <button class="vfte-button vfte-button-first" type="button" @click="onCommand('justifyLeft')" v-if="advanced">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'align-left'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Align left'" /></div>
            </button>

            <button class="vfte-button" type="button" @click="onCommand('justifyCenter')" v-if="advanced">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'align-center'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Align center'" /></div>
            </button>

            <button class="vfte-button" type="button" @click="onCommand('justifyRight')" v-if="advanced">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'align-right'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Align right'" /></div>
            </button>

            <button class="vfte-button  vfte-button-first" type="button" @click="onCommand('bold')">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'bold'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Bold'" /></div>
            </button>

            <button class="vfte-button" type="button" @click="onCommand('italic')">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'italic'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Italic'" /></div>
            </button>

            <button class="vfte-button" type="button" @click="onCommand('underline')">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'underline'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Underline'" /></div>
            </button>

            <button class="vfte-button  vfte-button-last" type="button" @click="onCommand('strikeThrough')">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'strikethrough'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Strikethrough'" /></div>
            </button>

            <button class="vfte-button" type="button" @click="onChangeFontSize(false)" v-if="advanced">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'decrease-font'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Decrease font size'" /></div>
            </button>

            <button class="vfte-button" type="button" @click="onChangeFontSize(true)" v-if="advanced">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'increase-font'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Increase font size'" /></div>
            </button>

            <button class="vfte-button" type="button" @click="onCommand('insertUnorderedList')" v-if="advanced">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'list'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Unordered list'" /></div>
            </button>

            <button class="vfte-button" type="button" @click="onCommand('removeFormat')" v-if="advanced">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'delete'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Remove all formatting'" /></div>
            </button>

            <button class="vfte-button vfte-button-last" type="button" @click="showLinkDialog" v-if="advanced">
                <vap-icon absolute :top="4" :right="16" tiny :icon="'link'" />
                <div class="vfte-tooltip"><vap-tooltip :text="'Add link'" /></div>
            </button>
                
        </div>

        <div class="vfte-output vftex" :id="this.id" :contenteditable="!disabled" @mouseup="onMouseUp" @input="onInput" v-if="!raw" />

        <div class="vfte-raw" v-if="raw">
            <textarea :value="value" 
                :lines="8" 
                :placeholder="placeholder"
                :disabled="disabled"
                @input="handleInput"
            />
        </div>

        <div class="vfte-ph" v-if="isEmpty" :class="[{'vfte-ph-disabled': disabled}]">
            {{ placeholder }}
        </div>

    </div>

    <PopupV1 v-if="linkDialog.show" :designer="designer" :header="'Add link'" @close="linkDialog.show = false" :dialog="linkDialog">
        <template v-slot:content>
            <div style="padding-top: 20px;">
                <VFInput v-model="linkDialog.link" :placeholder="'Link'" />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="linkDialog.show = false" />
            <VFButton :text="'Add link'" :icon="'link'" :working="linkDialog.working" @clicked="addLink" />
        </template>
    </PopupV1>
</div>
</template>

<script>
import tools from '@/tools';
import xss from 'xss';
//foreColor

import PopupV1 from '../../v1ui/PopupV1.vue';
import VFButton from './items/VFButton.vue';
import VFInput from './inputs/VFInput.vue';

export default {
    name: 'TextEditorV2',
    components: {
        PopupV1,
        VFButton,
        VFInput,
    },
    props: {
        value: { type: String },
        content: { type: String },
        disabled: { type: Boolean, default: false },
        placeholder: { type: String, default: '' },
        advanced: { type: Boolean, default: false },
        designer: { type: Boolean, default: false },
        raw: { type: Boolean, default: false },
    },
    data() {
        return {
            id: 'vfte',
            isEmpty: true,
            selectedFontSize: -1,
            xssOptions: {
                whiteList: {
                    div: ['style'],
                    a: ['href', 'target'],
                    b: [], i: [], u: [],
                    br: [], p: [],
                    ul: [], li: [],
                    strike: [],
                    font: ['size'],
                    span: ['style'],
                    table: [], tr: [], th: [], td: []
                },
            },
            linkDialog: { show: false, link: '' },
            selection: null,
        }
    },
    watch: {
        raw: {
            deep: false,
            handler(val) {
                this.loadEditor();
            }
        },
    },
    methods: {
       
        onInput() {
            this.$nextTick(() => {
                const element = document.getElementById(this.id);
                if (element.innerHTML) {
                    this.isEmpty = false;
                }else {
                    this.isEmpty = true;
                }
                let value = element.innerHTML;

                //console.log('value', value);

                //console.log('xssOptions', this.xssOptions);

                value = xss(value, this.xssOptions);

                // Try to prevent users including screenshots in comments
                if (value.length > 5000) {
                    value = value.substring(0, 5000);
                    element.innerHTML = value;
                }
                /* element.innerHTML = value; */

                //console.log('value', value);
                this.$emit('before');
                this.$emit('input', value);
            });
        },

        handleInput(e) {
            this.$emit('before');
            this.$emit('input', e.srcElement.value);
        },

        onCommand(command, value) {
            console.log('exec', command);
            document.execCommand(command, false, value);
        },

        showLinkDialog() {
            this.selection = this.saveSelection();
            this.linkDialog.show = true;
        },

        addLink() {
            this.restoreSelection(this.selection);
            this.$nextTick(() => {

                const link = this.linkDialog.link;
                //console.log('createlink', link);

                document.execCommand('createlink', false, link);

                this.$nextTick(() => {

                    const element = document.getElementById(this.id);

                    //console.log('inner', element.innerHTML);
                    
                    element.innerHTML = element.innerHTML.replace('<a href="' + link, '<a target="_blank" href="' + link);

                    //console.log('inner', element.innerHTML);

                    //console.log('value', value);
                    //this.$emit('before');
                    this.$emit('input', element.innerHTML);
                });

                this.linkDialog.show = false;
            });
        },

        saveSelection() {
            if (window.getSelection) {
                var sel = window.getSelection();
                if (sel.getRangeAt && sel.rangeCount) {
                    var ranges = [];
                    for (var i = 0, len = sel.rangeCount; i < len; ++i) {
                        ranges.push(sel.getRangeAt(i));
                    }
                    return ranges;
                }
            } else if (document.selection && document.selection.createRange) {
                return document.selection.createRange();
            }
            return null;
        },

        restoreSelection(savedSel) {
            if (savedSel) {
                if (window.getSelection) {
                    var sel = window.getSelection();
                    sel.removeAllRanges();
                    for (var i = 0, len = savedSel.length; i < len; ++i) {
                        sel.addRange(savedSel[i]);
                    }
                } else if (document.selection && savedSel.select) {
                    savedSel.select();
                }
            }
        },

        onChangeFontSize(bigger) {
            let newValue = Number(this.selectedFontSize) - 1;
            if (bigger) newValue = Number(this.selectedFontSize) + 1;
            this.onCommand('fontSize', newValue);
            this.selectedFontSize = newValue;
        },

        onMouseUp() {
            //let colour = document.queryCommandValue("ForeColor");
            let fontSize = document.queryCommandValue("FontSize");
            //console.log('colour', colour);
            //console.log('fontSize', fontSize);
            this.selectedFontSize = fontSize;
        },

        loadEditor() {
            this.$nextTick(() => {
                const element = document.getElementById(this.id);
                const value = this.content || this.value || '';
                let xxx = xss(value);
                element.innerHTML = xxx;
                if (value) this.isEmpty = false;
                //console.log('elemtn', element);
            });
        },

    },
    created() {
        this.id = 'te' + tools.getUniqueId();
        this.loadEditor();
    }
}
</script>

<style>

.vfte {
    position: relative;
}

.vfte-designer {
    color: white;
    background-color: var(--fb-input-bg);
}

.vfte-designer .vfte-toolbar {
    display: flex;
    background-color: var(--fb-menu-b);
}

.vfte-button {
    position: relative;
    width: 32px;
    height: 32px;
    padding: none;
    border: solid 1px var(--input-border);
    border-left: none;
    background-color: var(--page-background);
}
.vfte-button:hover {
    background-color: lightgray;
}
.vfte-button-first {
    border-top-left-radius: 3px;
    border-left: solid 1px var(--input-border);
}
.vfte-button-last {
    border-top-right-radius: 3px;
}

.vfte-designer .vfte-button {
    border-color: var(--fb-input-bg);
    background-color: var(--fb-input-bg);
}

.vfte-output {
    outline: none;
    background: none;
    margin-top: -1px;
	min-height: 140px; 
	padding: 1rem;
    border-radius: 3px;
    border-top-left-radius: 0px;
    border: solid 1px var(--input-border);
}

.vfte-designer .vfte-output {
    border: none;
    border-radius: 0px;
    font-size: 14px;
}

.vfte-tooltip {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
}



.vfte-ph {
    position: absolute;
    top: 48px;
    left: 20px;
    color: var(--input-placeholder);
}

.vfte-ph-disabled {
    top: 10px;
}

.vfte-raw textarea {
    width: 100%;
    padding: 6px 12px;
    padding-top: 10px;
    font-size: 15px;
    box-sizing: border-box;
    background-color: var(--card-background) !important;
    border: none;
    background: none;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: var(--input-text);
    border-radius: 4px;
    border: solid 1px var(--input-border);
    resize: vertical;
    min-height: 201px;
    max-height: 400px;
}

.vfte-designer a {
    color: white;
}

</style>
