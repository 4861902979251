import log from '../../../../log';
import v2lib from '../../../../v2lib';
import axios from 'axios';
import v1api from '../../../../v1helpers/v1api';
import v1backoffice from '../../../../v1admin/v1backoffice';
const karModuleHelper = {

    getEmptyConfig() {
        return {
            type: 'kar',
            icon: 'bank'
        };
    },

    onFormCreated(root, reference, designer, module, pages) {
        if (designer) return;

        const existingData = JSON.parse(root.formData);
        log.x('KAR Module: On form created, existing data:', existingData);
        //if (!existingData || !existingData.companyData) return;

        let mode = 'company';
        if (existingData && existingData.customer && existingData.customer.type == 'person') mode = 'person'; // V0 support

        // Prepare result model
        reference.karResult = {
            mode: mode,
            orgnr: '',
            ssn: '',
            account: '',
            date: null,
            verified: false,
            statusCode: '',
            message: ''
        };

    },

    async onSetUserdata(root, reference, module, envelope, userdata) {

        const existingData = JSON.parse(root.formData);
        log.x('KAR Module: On set userdata, existing data:', existingData);
        if (!existingData || !existingData.customer) return;

        // TODO this should always be set in the main submit function as the direct flow depends on it?
        userdata.customer = {
            id: existingData.customer._id,
            type: existingData.customer.type
        };

        // Process values
        let identifier = reference.karResult.orgnr;
        if (reference.karResult.mode == 'person') identifier = reference.karResult.ssn;

        // Remove whitespace
        identifier = identifier.trim();
        let account = reference.karResult.account.trim();

        // Do KAR search
        const data = await this.performKAR(root, account, identifier);
        console.log('KAR DATA', data);

        // Update result model
        reference.karResult.verified = data.verified ? 'JA' : 'NEI';
        reference.karResult.statusCode = data.statusCode;
        reference.karResult.message = data.description;
        reference.karResult.date = new Date();

        userdata.karResult = reference.karResult;

        // Add custom fields
        userdata.fields = {
            karVerified: data.verified,
            karStatus: data.statusCode,
            karMessage: data.description,
            karIdentity: identifier,
            karAccount: account,
        };

        // Log to usage stats
        const statData = {
            type: 'kar',
            cc: root.config.params.baseCountry,
            src: '',
            eid: envelope.id,
            cid: root.credentials.cid,
            ckey: root.credentials.key,
            cname: root.user.companyName,
            stat: root.config.params.accountStatus
        };
        v1backoffice.addStat(statData);
    },

    async onFormSubmitted(root, reference, module, nextRoleKey, envelope) {

        const existingData = JSON.parse(root.formData);
        log.x('KAR Module: On form submitted, existing data:', existingData);
        
    },

    onLinkValue(root, reference, designer, module, link, value) {

        log.x('KAR Module: On link value (' + link.link + ')', value);

        if (link.link == 'ssn' || link.link == 'orgnr' || link.link == 'account') {
            
            reference.karResult[link.link] = value;

        }else if (link.link == 'person') {

            if (value[link.key]) reference.karResult.mode = 'person';

        }else if (link.link == 'company') {

            if (value[link.key]) reference.karResult.mode = 'company';

        }
        
        //console.log('kar', reference.karResult);
    },

    async performKAR(root, account, identifier) {
        const axiosConfig = {
            headers: {
                'Authorization': 'JWT ' + v2lib.personalToken,
                'x-namespace': v2lib.namespace
            }
        };

        let attempts = 0; let success = false; let data = null;
        const maxAttempts = 3;
        while (!success && attempts < maxAttempts) {

            let response;
            try {

                response = await axios.get(`/api/kar/v2/account-owner?accountNumber=${account}&customerNumber=${identifier}`, axiosConfig);
                console.log('response', response);

                data = response.data;
                success = true;



            }catch (ex) {
                console.log('respo', response);
                console.error(ex);
            }

            attempts += 1;
        }

        if (!data) {
            data = {
                verified: false,
                statusCode: 'NA',
                description: root.__t('kar-error')
            };
        }else {

            const existingData = JSON.parse(root.formData);
            log.x('KAR Module: performKAR, existing data:', existingData);

            const karData = {
                clientId: '',
                clientName: '',
                isV0: !root.config.portal.useV1,
                account: account,
                identifier: identifier,
                verified: data.verified,
                statusCode: data.statusCode,
                description: data.description,
            };

            if (existingData && existingData.customer) {

                karData.clientId = existingData.customer._id;
                karData.clientName = existingData.customer.name;

            }

            await v1api.logKAR('id', karData);

        }
        
        return data;
    },

};
export default karModuleHelper;