<template>
    <div class="frmod-cla card-shadow" v-if="cardData.header" :class="[
        {'frmod-cl-selected' : selected},
        {'frmod-cl-designer': designer },
    ]" v-on-clickaway="handleClickaway">

        <div class="frmod-cla-h" @click="handleClick">
            
            <div class="frmod-cla-h-1">
                <span v-if="itemModel && itemModel.name && !enteringName" @click="handleEnterNameClick">{{ itemModel.name }}</span>
                <span v-if="(!itemModel || !itemModel.name) && !enteringName" style="color: #FF7272;" @click="handleEnterNameClick">{{ '&lt;click to enter name&gt;' }}</span>
                <input v-if="enteringName" v-model="modelName" :id="randomNameId" @keydown="handleKeyDown" />
            </div>
            <div class="frmod-cla-h-d">
                <!-- {{ 'GBID:' }} -->
            </div>
            <div class="frmod-cla-h-2">
<!--                 <span v-if="itemModel && itemModel.id && !enteringId" @click="handleEnterIdClick">{{ itemModel.id }}</span>
                <span v-if="(!itemModel || !itemModel.id) && !enteringId" style="color: #FF7272;" @click="handleEnterIdClick">{{ '&lt;click to enter GBID&gt;' }}</span>
                <input v-if="enteringId" v-model="modelId" :id="randomIdId" @keydown="handleKeyDown" /> -->
            </div>

            <div class="frmod-cla-h-i">
                <vap-icon :icon="'delete'" :color="'var(--header-text)'" action @clicked="onDeleteItem" />
            </div>

        </div>


        <div class="frmod-cla-xc">
            <slot name="chk"></slot>
        </div>

        <div class="frmod-cla-xh" v-if="screeningCount > 0">
            <div class="frmod-cla-d">
                <div class="frmod-cla-d1">
                    {{ 'Screening Checks' }}
                </div>
                <div class="frmod-cla-d2">
                    {{ 'Status' }}
                </div>
                <div class="frmod-cla-d3">
                    {{ 'Escaleted' }}
                </div>
            </div>
        </div>
        <div class="frmod-cla-xc">
            <slot name="scr"></slot>
        </div>

    </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway"
import tools from '../../../../tools';

export default {
    name: "frmod-chk-array",
    mixins: [clickaway],
    props: {
        cardData: { type: Object, default: () => { return {} }},
        itemModel: { type: Object, default: () => { return null }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        roleKey: { type: String, default: 'owner' },
    },
    data() {
        return {
            visible: {
                owner: [true, true, true, false, false, false],
                editfil: [true, true, true, true, true, true]
            },
            widths: {
                owner: [80, 10, 10],
                editfil: [30, 10, 10, 30, 10, 10]
            },
            options: {
                ids1: ['App Form', 'AML Form', 'File Note', 'Other', 'N/A']
            },
            enteringName: false,
            enteringId: false,
            randomNameId: 'ridn',
            randomIdId: 'ridi',
            modelName: '',
            modelId: '',
            screeningCount: 0
        }
    },
    watch: {
        width: {
            deep: false,
            handler(val) {
                
            }
        }
    },
    methods: {
        
        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

        handleEnterNameClick() {
            if (this.designer) return;
            this.randomNameId = 'x' + tools.getUniqueId();
            this.enteringName = true;
            const self = this;
            setTimeout(function() {
                const element = document.getElementById(self.randomNameId);
                element.focus();
            }, 10);
        },
        handleEnterIdClick() {
            if (this.designer) return;
            this.randomIdId = 'x' + tools.getUniqueId();
            this.enteringId = true;
            const self = this;
            setTimeout(function() {
                const element = document.getElementById(self.randomIdId);
                element.focus();
            }, 10);
        },

        handleClickaway() {

            console.log('itemModel', this.itemModel);

            if (this.modelName) this.itemModel.name = this.modelName;
            if (this.modelId) this.itemModel.id = this.modelId;
            this.enteringName = false;
            this.enteringId = false;
        },

        handleKeyDown(event) {
            if (event.keyCode == 13) {
                this.handleClickaway();
            }
        },

        onDeleteItem() {
            this.$emit('delete');
        }

    },
    mounted() {
        //console.log('cardData', this.cardData);
        if (this.itemModel) this.modelName = this.itemModel.name;
        if (this.itemModel) this.modelId = this.itemModel.id;

        this.screeningCount = 0;
        for (const i in this.cardData.inputs) {
            if (this.cardData.inputs[i].mode == 'scr') this.screeningCount += 1;
        }
    }
}

</script>
<style>

.frmod-cla {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 4px;
    background-color: var(--card-background);
    box-sizing: border-box;
}

.frmod-cla-h {
    float: left;
    width: 100%;
    display: flex;
    color: var(--header-text);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: var(--header);
    box-sizing: border-box;
}

.frmod-cla-h-1 {
    min-width: 280px;
    padding-top: 6px;
    padding-left: 10px;
    font-size: 20px;
}
.frmod-cla-h-d {
    padding-top: 6px;
    font-size: 20px;
    opacity: 0.6;
}
.frmod-cla-h-2 {
    flex-grow: 100;
    padding-top: 6px;
    padding-left: 10px;
    font-size: 20px;
}
.frmod-cla-h-i {
    
}

.frmod-cla-xh {
    position: relative;
    float: left;
    width: 100%;
    height: 34px;
    display: table;
    color: var(--header-text);
    border: solid 1px lightgray;
    border-top: none;
    background-color: var(--header);
    box-sizing: border-box;
}

.frmod-cla-d {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
    opacity: 0.7;
}
.frmod-cla-d1 {
    float: left;
    width: 30%;
    padding-top: 7px;
    padding-left: 10px;
    box-sizing: border-box;
}
.frmod-cla-d2 {
    float: left;
    width: 10%;
    height: 24px;
    margin-top: 9px;
    padding-top: 1px;
    padding-left: 4px;
    border-top: solid 1px var(--header-text);
    border-left: solid 1px var(--header-text);
    border-right: solid 1px var(--header-text);
    box-sizing: border-box;
}
.frmod-cla-d3 {
    float: left;
    width: 10%;
    height: 24px;
    margin-top: 9px;
    padding-top: 1px;
    padding-left: 4px;
    border-top: solid 1px var(--header-text);
    border-right: solid 1px var(--header-text);
    box-sizing: border-box;
}

.frmod-cla-xc {
    float: left;
    width: 100%;
    display: table;
    border: solid 1px lightgray;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: var(--card-background);
    box-sizing: border-box;
}

.frmod-cl-selected {
    outline: solid 2px #238ccc;
}

</style>
