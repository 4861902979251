<template>
<div>
    
    <PropertyHeader :header="'Landing page'">
        <template v-slot:actions>
            
        </template>
    </PropertyHeader>

    <PropertyItem v-if="hasLanding()" padded :header="'Landing page'" :icon="'link'">

        <DesignerGroup c12>
            <DesignerLabel :text="'Title'" />
            <DesignerInput
                v-model="reference.landingParams[selectedModel].title[$root.locale.key]"
                :placeholder="'Not set'"
                @before="$emit('propinput', 'text', 'landing_text', $event)"
            />
        </DesignerGroup>

        <DesignerGroup c12>
            <TextEditorV2 designer advanced
                v-model="reference.landingParams[selectedModel].message[$root.locale.key]"
                @before="$emit('propinput', 'text', 'landing_text', $event)"
            />
        </DesignerGroup>
        
    </PropertyItem>

</div>
</template>
<script>
import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';

import TextEditorV2 from '../../TextEditorV2.vue';

export default {
    name: 'LandingPropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        TextEditorV2,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() { 
        return {
            showLanding: false,
            selectedModel: 'filled',
            aborted: false,
        }
    },
    methods: {

        hasLanding() {
            // TODO fix
            console.log('role', this.reference.roleOptions[this.reference.roleIndex]);

            if (this.reference.roleOptions[this.reference.roleIndex].key == 'editor') {
                this.selectedModel = 'filled';
                return true;

            }else if (this.reference.roleOptions[this.reference.roleIndex].key == 'signer') {
                this.selectedModel = 'signed';
                return true;
            }

            return false;
        },

    },
    created() {
        
    }
}
</script>