<template>
<div class="slim-page">
    <div v-if="!outdatedClient && !alreadyCompleted">



        <div style="width: 100%; text-align: center; margin-top: 100px; margin-bottom: 50px;">
            <img :src="$root.config.idcs[idcIndex].logo" style="width: 300px;" />
        </div>
        
        <vap-card v-if="bankIdNoOnly && !completed && loadingComplete && !showQuestions">
            <div style="width: 100%;">
                <vap-text-editor :value="__('idc_welcome')" />
            </div>
            <div style="width: 100%; text-align: center;">
                <vap-button :text="'START BANKID'" @clicked="startBankIdNoAuth" />
            </div>
        </vap-card>


        <div v-if="!bankIdNoOnly && !showMethod && !showQuestions && !completed && loadingComplete">

            <vap-card list>
                <div style="width: 100%;">
                    <vap-text-editor :value="__('idc_welcome_select')" />
                </div>
            </vap-card>

            <vap-label header :text="__('idc_met_select')" style="margin-bottom: 10px;" />

            <vap-card compactList noPadding>
                <vap-item @clicked="gotoBankIdNo">
                    <div class="idc-method">
                        <div class="idc-method-content">
                            <div class="idc-method-title">
                                <label>{{ __('idc_met_bankidno_header') }}</label>
                            </div>
                            <div class="idc-method-help">
                                <label>{{ __('idc_met_bankidno_hint') }}</label>
                            </div>
                        </div>
                        <div class="idc-method-arrow">
                            <vap-icon :icon="'chev-r'" />
                        </div>
                    </div>
                </vap-item>
            </vap-card>

            <vap-card compactList noPadding>
                <vap-item @clicked="gotoFileUpload">
                    <div class="idc-method">
                        <div class="idc-method-content">
                            <div class="idc-method-title">
                                <label>{{ __('idc_met_fileupload_header') }}</label>
                            </div>
                            <div class="idc-method-help">
                                <label>{{ __(passportOnly ? 'idc_met_fileupload_hint_po' : 'idc_met_fileupload_hint') }}</label>
                            </div>
                        </div>
                        <div class="idc-method-arrow">
                            <vap-icon :icon="'chev-r'" />
                        </div>
                    </div>
                </vap-item>
            </vap-card>

        </div>


        <div v-if="showMethod && !completed">

            <div style="margin-bottom: 10px;">
                <vap-button :text="__('idc_met_back')" @clicked="goBack" />
            </div>

            <vap-card v-if="showFileUpload">
                <div class="idc-fu-check-header">
                    <label>{{ __(passportOnly ? 'idc_fileupload_header_po' : 'idc_fileupload_header') }}</label>
                </div>
                <div class="idc-fu-check" v-if="!passportOnly">
                    <div class="idc-fu-item">
                        <div class="idc-fu-icon" :class="{'idc-fu-icon-ok': uploadedPassport}">
                            <vap-icon tiny absolute :top="1" :right="2" :icon="uploadedPassport ? 'check' : 'cancel'" :color="uploadedPassport ? 'var(--accent-contrast)' : 'var(--disabled-text)'" />
                        </div>
                        <div class="idc-fu-text">
                            <label>{{ __('idc_fileupload_passport') }}</label>
                        </div>
                    </div>
                    <div class="idc-fu-item">
                        <div class="idc-fu-icon" :class="{'idc-fu-icon-ok': uploadedUtility}">
                            <vap-icon tiny absolute :top="1" :right="2" :icon="uploadedUtility ? 'check' : 'cancel'" :color="uploadedUtility ? 'var(--accent-contrast)' : 'var(--disabled-text)'" />
                        </div>
                        <div class="idc-fu-text">
                            <label>{{ __('idc_fileupload_utility_bill') }}</label>
                        </div>
                    </div>
                </div>
                <vap-file-upload v-model="fileupload"
                    :allowedFiles="'pdf|img'"
                    :singleFile="passportOnly"
                    :autoPreview="passportOnly"
                    :contentOptions="passportOnly ? undefined : contentOptions"
                    class="flw100"
                    @input="onFileUploadInput"
                />
                <div class="idc-fu-send">
                    <vap-button :text="__(hasQuestions ? 'idc_continue' : 'idc_fileupload_send')" @clicked="submitFileUpload" :disabled="!uploadedPassport || !uploadedUtility" />
                </div>
            </vap-card>

            <vap-card v-if="showBankIdNo">
                <div style="width: 100%;">
                    <vap-text-editor :value="__('idc_welcome')" />
                </div>
                <div style="width: 100%; text-align: center;">
                    <vap-button :text="'START BANKID'" @clicked="startBankIdNoAuth" />
                </div>
            </vap-card>

        </div>


        <vap-form v-if="loadingComplete && showQuestions" ref="custom">
            <FormRenderer ref="form-renderer" :data="loadedCards" editor />
        </vap-form>
        <div class="idc-fu-send" v-if="loadingComplete && showQuestions">
            <vap-button :text="__('idc_fileupload_send')" @clicked="submitQuestions" />
        </div>


        <!-- Thank you page -->
        <vap-card v-if="completed && loadingComplete">
            <div style="width: 100%; text-align: center;">
                <vap-text-editor :value="__('idc_thank_you')" />
            </div>
        </vap-card>
        <div v-if="completed && loadingComplete" class="kyc-landing-footer">
            <span>{{ __('idc_landing_footer') }}</span><span><a :href="redirectUrl">{{ __('idc_landing_link') }}</a></span>
        </div>

    </div>
    <div v-if="outdatedClient">

        <div style="width: 100%; text-align: center; margin-top: 100px; margin-bottom: 50px;">
            <img :src="$root.config.idcs[idcIndex].logo" style="width: 300px;" />
        </div>

        <vap-card>
            <div style="width: 100%; text-align: center; margin-bottom: 40px;">
                <vap-text-editor :value="__('idc_short_link_1')" />
            </div>
        </vap-card>

    </div>
    <div v-if="alreadyCompleted">

        <div style="width: 100%; text-align: center; margin-top: 100px; margin-bottom: 50px;">
            <img :src="$root.config.idcs[idcIndex].logo" style="width: 300px;" />
        </div>

        <vap-card>
            <div style="width: 100%; text-align: center; margin-bottom: 40px;">
                <vap-text-editor :value="__('idc_already_completed')" />
            </div>
        </vap-card>
        <div class="kyc-landing-footer">
            <span>{{ __('idc_landing_footer') }}</span><span><a :href="redirectUrl">{{ __('idc_landing_link') }}</a></span>
        </div>

    </div>
</div>
</template>

<script>
import { auth, envelopes, oidc } from '@verified/libvf';
import jwt from 'jsonwebtoken';
import backend from '../backend';
import api from '../portalapi';
import locale from '../locale';
import tools from '../tools';
import v2lib from '../v2lib';

import FormRenderer from '../components/FormRenderer.vue';

export default {
    name: 'IDControl',
    components: {
        FormRenderer
    },
    data() {
        return {
            idcIndex: 0,
            bankIdNoOnly: false,
            methodBankIdNo: false,
            methodFileUpload: false,
            passportOnly: false,
            showMethod: false,
            showBankIdNo: false,
            showFileUpload: false,
            exceptions: [],
            completed: false,
            countdown: 10,
            loadingComplete: false,
            fileupload: {},
            uploadedPassport: false,
            uploadedUtility: false,
            redirectUrl: 'https://verified.eu',
            contentOptions: [
                { value: 'passport', nb_NO: 'Kopi av pass', en_EN: 'Passport' },
                { value: 'utility_bill', nb_NO: 'Strømregning/kommunale avgifter', en_EN: 'Utility bill' },
            ],
            userdata: {},
            hasQuestions: false,
            showQuestions: false,
            loadedCards: {},
            outdatedClient: false,
            alreadyCompleted: false
        }
    },
    methods: {

        // ----- BankID NO ----------------------------------------------------------------------

        gotoBankIdNo() {
            this.showBankIdNo = true;
            this.showMethod = true;
        },

        // Start BankID NO Auth
        async startBankIdNoAuth() {
            try {
                //console.log(this.$route.query);

                let documentId = this.$route.query.document_uid;
                let token = this.$route.query.a_t || this.$route.query.access_token;

                //console.log('token', this.$root.config.idcs[this.idcIndex].token);
                //console.log('namespace', this.$root.credentials.cid);
                //console.log('config', this.$root.config);

                auth.authenticate({
                    token: this.$root.config.idcs[this.idcIndex].token,
                    namespace: '/companies/' + this.$root.credentials.cid
                });

                let company = 'localhost';
                //console.log('host', window.location.host);
                if (!window.location.host.includes('localhost')) company = window.location.host.replace('.web.verified.eu', '');
                //console.log('company', company);

                const data = {
                    documentUid: documentId,
                    token: token
                };
                sessionStorage.setItem('vapoidc', JSON.stringify(data));

                let redirectUri = '';
                if (this.userdata && this.userdata.config && this.userdata.config.isAWS) {
                    redirectUri = await oidc.norway.getAuthUrl(`/web/aml-portal/`);
                    console.log('redirectUri', redirectUri);
                }else if (!this.$root.config.idcs[this.idcIndex].oidcUri || this.$root.config.idcs[this.idcIndex].oidcUri == 'wildcard') {
                    redirectUri = await oidc.norway.getAuthUrl(`/lib/iframes/redirect/aml-portal-id.html?` 
                        + encodeURIComponent(`sheetName=${documentId}&c=${company}&a_t=${token}`));
                    console.log('not AWS');
                }else {
                    let link = await oidc.norway.getAuthUrl(encodeURIComponent(this.$root.config.idcs[this.idcIndex].oidcUri));
                    redirectUri = link.replace('https%3A%2F%2Fapp.verified.eu', '');
                }

                window.location.href = redirectUri;

            }catch (ex) {
                console.log(ex);
                this.$root.exceptions.push(ex);
            }
        },

        getTokenData() {
            const decodedId = jwt.decode(this.$route.query.id_token);
            const decodedAccess = jwt.decode(this.$route.query.access_token);
            return {
                id_token: decodedId,
                access_token: decodedAccess
            };
        },

        // Complete ID control
        async onBankIdNoAuth() {
            this.$parent.setLoading(true, this.__('loading_id_verify') + '...');

            console.log('onBudAuth');

            // Get firstname and lastname from the bankid tokens
            try {
                let tokenData;
                if (this.$route.query.session_state) {
                    tokenData = this.getTokenData();
                    console.log('tokenData', tokenData);
                }else {
                    tokenData = oidc.norway.decodeUrlTokens();
                    let bat = this.$route.hash.split('access_token=')[1];
                    bat = bat.split('&token_type')[0];
                    //console.log('bat', bat);

                    let iat = this.$route.hash.split('id_token=')[1];
                    iat = iat.split('&access_token=')[0];
                    //console.log('iat', iat);
                }

                let fname = tokenData.id_token.given_name;
                let lname = tokenData.id_token.family_name;
                let dob = tokenData.id_token.birthdate;
                let ssn = tokenData.id_token.nnin_altsub || '';

                this.userdata.control = {
                    method: 'bankid-no',
                    firstName: fname,
                    lastName: lname,
                    dateOfBirth: dob,
                    ssn: ssn,
                    lookupDate: new Date()
                };

                if (this.hasQuestions) {
                    this.onShowQuestions();
                }else {
                    await this.submit();
                }

            }
            catch(ex) {
                console.log(ex);
                this.$root.exceptions.push(ex);
            }
            this.loadingComplete = true;
        },

        // --------------------------------------------------------------------------------------

        onShowQuestions() {
            this.showQuestions = true;
            this.showMethod = false;
            this.$parent.setLoading(false);
        },

        submitQuestions() {
            const valid = this.$refs['custom'].validate();
            if (valid) {

                this.userdata.questions = this.loadedCards;

                this.$parent.setLoading(true, this.__('loading_submitting_form') + '...');
                this.submit();
            }
        },

        async submit() {
            try {
                
                const envelope = this.$parent.loadedEnvelope;

                // Upload attachments and add to userdata
                this.userdata.attachments = [];
                for (const i in this.fileupload.files) {
                    this.userdata.attachments.push(v2lib.addFileUploadAttachmentToUserdataSafe(this.userdata.attachments.length, this.fileupload.files[i], this.fileupload.files[i].content));
                    await v2lib.addAttachment(envelope, this.fileupload.files[i].name, this.fileupload.files[i].data);
                }

                console.log(JSON.stringify(this.userdata));
                const template = await api.renderTemplate(this.$root, 'idc', this.userdata);
                this.userdata.css = template.css;
                this.userdata.html = template.html;

                await v2lib.setUserData(envelope, this.userdata);
                await api.submitIDC(tools.encodePerson(this.userdata.person), envelope.id, this.userdata.credentials.id);

                this.completed = true;
                this.showQuestions = false;

            }
            catch(ex) {
                console.log(ex);
                if (ex.response && ex.response.data && ex.response.data.code == 403) {
                    
                    console.log('response data', ex.response.data);

                    this.completed = true;
                    this.showQuestions = false;

                }else {
                    this.$root.exceptions.push(ex);
                }
            }
            this.$parent.setLoading(false);
        },

        // ----- File Upload --------------------------------------------------------------------

        gotoFileUpload() {
            this.showFileUpload = true;
            this.showMethod = true;
        },

        onFileUploadInput(data) {
            this.uploadedPassport = false;
            this.uploadedUtility = false;

            if (this.passportOnly) {

                if (data.files.length > 0) {
                    data.files[0].content = 'passport';
                    this.uploadedPassport = true;
                    this.uploadedUtility = true;
                }else {
                    this.uploadedPassport = false;
                    this.uploadedUtility = false;
                }

            }else {

                for (let i in data.files) {
                    if (data.files[i].content == 'passport') this.uploadedPassport = true;
                    if (data.files[i].content == 'utility_bill') this.uploadedUtility = true;
                }

            }
        },

        async submitFileUpload() {
            this.$parent.setLoading(true, this.__('loading_id_verify') + '...');
            try {
                
                this.userdata.control = {
                    method: 'fileupload',
                    firstName: '',
                    lastName: '',
                    dateOfBirth: '',
                    lookupDate: new Date()
                };

                if (this.hasQuestions) {
                    this.onShowQuestions();
                }else {
                    await this.submit();
                }

            }
            catch(ex) {
                console.log(ex);
                this.$root.exceptions.push(ex);
            }
        },

        // --------------------------------------------------------------------------------------

        async initiateCountdown(){
            this.countdown = 10;
            while (this.countdown > 0) {
                this.countdown -= 1;
                await new Promise(r => setTimeout(r, 1000));
            }
            window.location.href = this.$root.config.idcs[this.idcIndex].redirect;
        },

        goBack() {
            this.showBankIdNo = false;
            this.showFileUpload = false;
            this.showMethod = false;
        }

    },
    async created() {
        this.$parent.setLoading(true, this.__('loading_id') + '...');
        //console.log('ud', this.$parent.loadedEnvelope.userdata);
        //console.log('enveolpe', this.$parent.loadedEnvelope);

        if (this.$parent.loadedError.isError) {
            this.outdatedClient = true;
            this.loadingComplete = true;
            this.$parent.setLoading(false);
            return;
        }

        if (this.$parent.loadedEnvelope.content && this.$parent.loadedEnvelope.content.completed) {
            this.alreadyCompleted = true;
        }

        this.userdata = this.$parent.loadedEnvelope.userdata;

        // Find the correct IDC index
        for (const i in this.$root.config.idcs) {
            if (this.$root.config.idcs[i].key == this.$parent.loadedEnvelope.userdata.config.key) this.idcIndex = i;
        }
        this.passportOnly = this.$root.config.idcs[this.idcIndex].passportOnly || false;

        if (this.$root.config.idcs[this.idcIndex].designer) {
            const response = await api.getQuestions(this.$root.config.idcs[this.idcIndex].key);
            if (response.questions) {
                this.loadedCards = response.questions;
                if (this.loadedCards.cards && this.loadedCards.cards.length > 0) this.hasQuestions = true;
            }else {
                this.loadedCards = {};
            }
        }
        //console.log('cards', this.loadedCards);

        //console.log('config', this.$root.config.idcs[this.idcIndex]);
        this.redirectUrl = this.$root.config.idcs[this.idcIndex].redirect;

        // Check available auth methods
        if (this.$root.config.idcs[this.idcIndex].methods.includes('bankid-no')) this.methodBankIdNo = true;
        if (this.$root.config.idcs[this.idcIndex].methods.includes('fileupload')) this.methodFileUpload = true;
        this.bankIdNoOnly = (!this.methodFileUpload);

        // Load IDC translations and merge with main
        const defaults = locale.getDefaultIdcTranslations();
        const tres = await api.getTranslations(this.$root.config.idcs[this.idcIndex].key);
        const translations = { ...defaults, ...tres.texts };
        this.$root.translations = { ...this.$root.translations, ...translations };

        const emailData = {
            customerName: this.$parent.loadedEnvelope.userdata.company ? this.$parent.loadedEnvelope.userdata.company.name : ''
        };
        this.$parent.setTitle(this.replaceBrackets(this.__('idc_email_subject'), emailData));

        // Authenticate using info from the config/URL, if the url has no token it was probably redirected from static link and libvf should already be authenticated
        if (this.$route.query.a_t) {
            auth.authenticate({
                token: this.$route.query.a_t,
                namespace: '/companies/' + this.$root.credentials.cid
            });
        }

        // Check if this is a redirect from BankID NO
        console.log('sessionquery', this.$route.query.session_state);
        if (oidc.norway.urlHasNecessaryTokens() || this.$route.query.session_state) {
            console.log('ok');
            this.onBankIdNoAuth();
        }else {
            this.loadingComplete = true;
            this.$parent.setLoading(false);
        }
    }
}
</script>

<style scoped>

.idc-method {
    display: flex;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 10px;
    box-sizing: border-box;
}
.idc-method-content {
    flex-grow: 100;
}
.idc-method-arrow {
    flex-shrink: 0;
    padding-top: 4px;
}

.idc-method-title {
    font-size: 20px;
    color: var(--text-strong);
}
.idc-method-help {
    font-size: 16px;
    color: var(--text-weak);
}


.idc-fu-check-header {
    font-size: 19px;
    padding-bottom: 14px;
    border-bottom: solid 1px var(--input-border);
}
.idc-fu-check {
    float: left;
    width: 100%;
    margin-bottom: 15px;
    border-bottom: solid 1px var(--input-border);
}
.idc-fu-item {
    float: left;
    width: 50%;
    display: flex;
    padding: 5px 0px;
}
.idc-fu-icon {
    position: relative;
    width: 26px; height: 26px;
    border-radius: 50%;
    background-color: var(--disabled-background);
    box-sizing: border-box;
}
.idc-fu-icon-ok {
    background-color: var(--accent);
}
.idc-fu-text {
    padding-top: 3px;
    padding-left: 10px;
    font-size: 17px;
}
.idc-fu-send {
    float: left;
    width: 100%;
    padding-top: 20px;
    margin-bottom: 20px;
    text-align: center;
}


</style>