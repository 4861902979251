<template>
<div>
    <div class="slim-page">
        <vap-card list :header="'Export data'">
            <vap-row>
                <vap-group c2>
                    <vap-label :text="'Skip'" desc />
                    <vap-input v-model="skip" />
                </vap-group>
                <vap-group c2>
                    <vap-label :text="'Limit'" desc />
                    <vap-input v-model="limit" />
                </vap-group>
            </vap-row>
            <vap-button-row alignLeft>
                <vap-button :text="'Load metadata'" @clicked="loadData4" :disabled="working" :working="working" />
                <vap-button :text="'Load from archive'" @clicked="fillData4" :disabled="working" :working="working" />
                <vap-button :text="'Export to CSV'" @clicked="downloadFile" :disabled="working" :working="working" />
            </vap-button-row>
        </vap-card>
    </div>

    <div class="aml-page" v-if="loadedColumns.length > 0">
        <vap-card>
            <DataTable :columns="loadedColumns" :rows="loadedRows" ref="datatable" />
        </vap-card>
    </div>

</div>
</template>

<script>
import api from '../../portalapi';
import tools from '../../tools';
import cache from '../../cache';
import log from '../../log';
import clienthelper from '../../helpers/clienthelper';
import personhelper from '../../helpers/personhelper';

import downloadjs from 'downloadjs';
import DataTable from '../../components/special/DataTable.vue';

export default {
    name: 'ScriptView',
    components: {
        DataTable
    },
    data() {
        return {
            working: false,
            filename: 'exported_data',
            skip: 0,
            limit: 5,
            loadedCustomers: [],
            loadedColumns: [],
            loadedRows: [],
        }
    },
    methods: {

        async loadData() {

            const filters = [
                /* { field: 'status', op: 'is', value: 'f-kyc-b' } */
                { field: 'status1', op: 'is', value: 1 }
            ];
            const from = Number(this.skip);
            const limit = Number(this.limit);
            const sort = { name: 1 };
            const include = [];
            const counts = ['userId'];

            const response = await api.queryCustomers(filters, from, limit, sort, include, counts);
            this.loadedCustomers = response.customers;

            this.loadedRows = [];
            this.loadedColumnsTTTT = [
                /* { key: 'fullName', name: 'Navn', width: 10 },
                { key: 'dateOfBirth', name: 'Fødselsdato', width: 10 }, */
                { key: 'name', name: 'Selskap', width: 10 },
                { key: 'identity', name: 'Orgnr', width: 10 },
                { key: 'orgForm', name: 'Type', width: 10 },
                /* { key: 'businessCode', name: 'Næringskode', width: 10 },
                { key: 'businessDesc', name: 'Beskrivelse', width: 10 }, */

                

                /* { key: 'userId', name: 'UserID', width: 10 }, */
                { key: 'userName', name: 'User', width: 10 },
                /* { key: 'department', name: 'Department ID', width: 10 }, */
                { key: 'departmentName', name: 'Forretningsområde', width: 10 },
                { key: 'partner', name: 'Partner ID', width: 10 },
                { key: 'partnerName', name: 'Partnernavn', width: 10 },


/*                 { key: 'idcSent1', name: 'ID_Sendt', width: 10 },
                { key: 'idcStatus1', name: 'Status', width: 10 },
                { key: 'idcRole1', name: 'Rolle', width: 10 },
                { key: 'idcFirstName1', name: 'Fornavn', width: 10 },
                { key: 'idcLastName1', name: 'Etternavn', width: 10 },
                { key: 'idcEmail1', name: 'Epost', width: 10 },
                { key: 'idcDate1', name: 'Dato utsendt', width: 10 },
                { key: 'idcRecipientId1', name: 'RecipientID', width: 10 },
                { key: 'idcEnvelopeId1', name: 'EnvelopeID', width: 10 }, */

                { key: 'editorFirstName', name: 'Kontaktperson fornavn', width: 10 },
                { key: 'editorLastName', name: 'Kontaktperson etternavn', width: 10 },
                { key: 'editorEmail', name: 'Email', width: 10 },
                { key: 'editorPhone', name: 'Telefon', width: 10 },
                { key: 'status', name: 'Status', width: 10 },
                /* { key: 'flowStatus', name: 'Status', width: 10 },
                { key: 'country', name: 'Land', width: 10 },
                { key: 'rrhCountries', name: 'Land RRH', width: 10 },
                { key: 'roleCountries', name: 'Land Roller', width: 10 },
                { key: 'hasPep', name: 'PEP', width: 10 },
                { key: 'hasSanction', name: 'Sanksjon', width: 10 }, */
                /* { key: 'reminder', name: 'Siste påminnelse', width: 10 }, */
                { key: 'formDate', name: 'Dato utsendt', width: 10 },
                { key: 'customerId', name: 'KundeID', width: 10 },
                { key: 'envelopeId', name: 'Envelope ID', width: 10 },
                { key: 'recipientId', name: 'Recipient ID', width: 10 },
                
                /* { key: 'hasSearch', name: 'AML-søk', width: 10 }, */
                /* { key: 'hasPep', name: 'PEP', width: 10 }, */
                /* { key: 'hasSanction', name: 'Sanksjon', width: 10 },
                { key: 'readyForReport', name: 'Klar for sluttrapport', width: 10 }, */

/*                 { key: 'pepTitle', name: 'PEP', width: 10 },
                { key: 'pepMatchIndicator', name: 'Treff indikator', width: 10 },
                { key: 'pepMatchDescription', name: 'Treff beskrivelse', width: 10 },
                { key: 'pepName', name: 'Treff navn', width: 10 },
                { key: 'pepDateOfBirth', name: 'Treff fødselsdato', width: 10 }, */
            ];
            this.loadedColumnsID = [
                /* { key: 'fullName', name: 'Navn', width: 10 },
                { key: 'dateOfBirth', name: 'Fødselsdato', width: 10 }, */
                { key: 'name', name: 'Selskap', width: 10 },
                { key: 'identity', name: 'Orgnr', width: 10 },
                { key: 'orgForm', name: 'Type', width: 10 },
                /* { key: 'businessCode', name: 'Næringskode', width: 10 },
                { key: 'businessDesc', name: 'Beskrivelse', width: 10 }, */

                

                /* { key: 'userId', name: 'UserID', width: 10 }, */
                { key: 'userName', name: 'User', width: 10 },
                /* { key: 'department', name: 'Department ID', width: 10 }, */
                { key: 'departmentName', name: 'Forretningsområde', width: 10 },
                { key: 'partner', name: 'Partner ID', width: 10 },
                { key: 'partnerName', name: 'Partnernavn', width: 10 },


                { key: 'idcSent1', name: 'ID_Sendt', width: 10 },
                { key: 'idcStatus1', name: 'Status', width: 10 },
                { key: 'idcRole1', name: 'Rolle', width: 10 },
                { key: 'idcFirstName1', name: 'Fornavn', width: 10 },
                { key: 'idcLastName1', name: 'Etternavn', width: 10 },
                { key: 'idcEmail1', name: 'Epost', width: 10 },
                { key: 'idcDate1', name: 'Dato utsendt', width: 10 },
                { key: 'idcRecipientId1', name: 'RecipientID', width: 10 },
                { key: 'idcEnvelopeId1', name: 'EnvelopeID', width: 10 },

                /* { key: 'editorFirstName', name: 'Kontaktperson fornavn', width: 10 },
                { key: 'editorLastName', name: 'Kontaktperson etternavn', width: 10 },
                { key: 'editorEmail', name: 'Email', width: 10 },
                { key: 'editorPhone', name: 'Telefon', width: 10 },
                { key: 'status', name: 'Status', width: 10 }, */
                /* { key: 'flowStatus', name: 'Status', width: 10 },
                { key: 'country', name: 'Land', width: 10 },
                { key: 'rrhCountries', name: 'Land RRH', width: 10 },
                { key: 'roleCountries', name: 'Land Roller', width: 10 },
                { key: 'hasPep', name: 'PEP', width: 10 },
                { key: 'hasSanction', name: 'Sanksjon', width: 10 }, */
                /* { key: 'reminder', name: 'Siste påminnelse', width: 10 }, */
                /* { key: 'formDate', name: 'Dato utsendt', width: 10 }, */
                { key: 'customerId', name: 'KundeID', width: 10 },
                { key: 'envelopeId', name: 'Envelope ID', width: 10 },
                { key: 'recipientId', name: 'Recipient ID', width: 10 },
                
                /* { key: 'hasSearch', name: 'AML-søk', width: 10 }, */
                /* { key: 'hasPep', name: 'PEP', width: 10 }, */
                /* { key: 'hasSanction', name: 'Sanksjon', width: 10 },
                { key: 'readyForReport', name: 'Klar for sluttrapport', width: 10 }, */

/*                 { key: 'pepTitle', name: 'PEP', width: 10 },
                { key: 'pepMatchIndicator', name: 'Treff indikator', width: 10 },
                { key: 'pepMatchDescription', name: 'Treff beskrivelse', width: 10 },
                { key: 'pepName', name: 'Treff navn', width: 10 },
                { key: 'pepDateOfBirth', name: 'Treff fødselsdato', width: 10 }, */
            ];
            this.loadedColumns = [
                /* { key: 'fullName', name: 'Navn', width: 10 },
                { key: 'dateOfBirth', name: 'Fødselsdato', width: 10 }, */
                { key: 'name', name: 'Selskap', width: 10 },
                { key: 'identity', name: 'Orgnr', width: 10 },
                { key: 'orgForm', name: 'Type', width: 10 },
                { key: 'businessCode', name: 'Næringskode', width: 10 },
                { key: 'businessDesc', name: 'Beskrivelse', width: 10 },

/*                 { key: 'idcSent1', name: 'ID_Sendt', width: 10 },
                { key: 'idcStatus1', name: 'Status', width: 10 },
                { key: 'idcRole1', name: 'Rolle', width: 10 },
                { key: 'idcFirstName1', name: 'Fornavn', width: 10 },
                { key: 'idcLastName1', name: 'Etternavn', width: 10 },
                { key: 'idcEmail1', name: 'Epost', width: 10 },
                { key: 'idcRecipientId1', name: 'RecipientID', width: 10 },
                { key: 'idcEnvelopeId1', name: 'EnvelopeID', width: 10 }, */

                /* { key: 'userId', name: 'UserID', width: 10 }, */
                { key: 'userName', name: 'User', width: 10 },
                /* { key: 'department', name: 'Department ID', width: 10 }, */
                { key: 'departmentName', name: 'Forretningsområde', width: 10 },
                { key: 'partner', name: 'Partner ID', width: 10 },
                { key: 'partnerName', name: 'Partnernavn', width: 10 },
                /* { key: 'editorFirstName', name: 'Kontaktperson fornavn', width: 10 },
                { key: 'editorLastName', name: 'Kontaktperson etternavn', width: 10 },
                { key: 'editorEmail', name: 'Email', width: 10 },
                { key: 'editorPhone', name: 'Telefon', width: 10 }, */
                /* { key: 'status', name: 'Status', width: 10 }, */
                /* { key: 'flowStatus', name: 'Status', width: 10 }, */
                { key: 'country', name: 'Land', width: 10 },
                { key: 'rrhCountries', name: 'Land RRH', width: 10 },
                { key: 'roleCountries', name: 'Land Roller', width: 10 },
                
                { key: 'hasSanction', name: 'Sanksjon', width: 10 },

                { key: 'selfPep', name: 'Selverklært PEP', width: 10 },
                { key: 'selfPepTitle', name: 'PEP Tittel (Selverklært)', width: 10 },
                { key: 'selfPepRole', name: 'PEP Rolle (Selverklært)', width: 10 },
                { key: 'selfPepName', name: 'PEP Navn (Selverklært)', width: 10 },

                /* { key: 'reminder', name: 'Siste påminnelse', width: 10 },
                /* { key: 'formDate', name: 'Dato utsendt', width: 10 }, */
                /* { key: 'customerId', name: 'KundeID', width: 10 }, */
                /* { key: 'envelopeId', name: 'Envelope ID', width: 10 },
                { key: 'recipientId', name: 'Recipient ID', width: 10 }, */
                
                /* { key: 'hasSearch', name: 'AML-søk', width: 10 }, */
                /* { key: 'hasPep', name: 'PEP', width: 10 }, */
                /* { key: 'hasSanction', name: 'Sanksjon', width: 10 },
                { key: 'readyForReport', name: 'Klar for sluttrapport', width: 10 }, */

                { key: 'hasPep', name: 'PEP', width: 10 },
                { key: 'pepTitle', name: 'PEP Tittel', width: 10 },
                { key: 'pepRole', name: 'PEP Rolle', width: 10 },
                { key: 'pepName', name: 'PEP Navn', width: 10 },
                { key: 'pepMatchIndicator', name: 'Treff indikator', width: 10 },
                { key: 'pepMatchDescription', name: 'Treff beskrivelse', width: 10 },
                /* { key: 'pepName', name: 'Treff navn', width: 10 },
                { key: 'pepDateOfBirth', name: 'Treff fødselsdato', width: 10 }, */
            ];
            for (const i in this.loadedCustomers) {
                this.loadedRows.push({
                    name: this.loadedCustomers[i].name,
                    identity: this.loadedCustomers[i].identity,
                    userId: this.loadedCustomers[i].userId,
                    userName: this.$root.getEmployeeName(this.loadedCustomers[i].userId),
                    department: this.loadedCustomers[i].department,
                    departmentName: this.$root.getDepartmentName(this.loadedCustomers[i].department),
                    partner: this.loadedCustomers[i].partner,
                    partnerName: this.$root.getPartnerName(this.loadedCustomers[i].partner),
                    reminder: this.getDateFromReminder(this.loadedCustomers[i].reminder),
                    /* formDate: this.loadedCustomers[i].kyc ? tools.dateStringShort(this.loadedCustomers[i].kyc.sentDate) : 'Ikke sendt ut', */
                    customerId: this.loadedCustomers[i]._id,
                    hasSearch: this.loadedCustomers[i].aml ? 'ja' : 'nei',
                    flowStatus: this.getStatusText(this.loadedCustomers[i].status),
                    country: this.loadedCustomers[i].country,
                });
            }
        },

        async loadData2() {

            const filters = [
                { field: 'control', op: 'exists', value: true },
            ];
            const from = Number(this.skip);
            const limit = Number(this.limit);
            const sort = { dateUpdated: 1 };
            const include = [];
            const counts = ['userId'];

            const response = await api.queryPeople(filters, from, limit, sort, include, counts);
            this.loadedCustomers = [];

            for (const i in response.people) {
                this.loadedCustomers.push(response.people[i]);
            }

            this.loadedRows = [];
            this.loadedColumns = [
                { key: 'editorFirstName', name: 'Fornavn', width: 10 },
                { key: 'editorLastName', name: 'Etternavn', width: 10 },
                { key: 'name', name: 'Selskap', width: 10 },
                { key: 'dateOfBirth', name: 'Fødselsdato', width: 10 },
                { key: 'idControl', name: 'ID Kontroll Sendt', width: 10 },
                { key: 'editorEmail', name: 'Email', width: 10 },
                { key: 'status', name: 'Status', width: 10 },
                { key: 'formDate', name: 'Dato utsendt', width: 10 },
                /* { key: 'identity', name: 'Person ID', width: 10 }, */
                { key: 'envelopeId', name: 'Envelope ID', width: 10 },
                /* { key: 'recipientId', name: 'Recipient ID', width: 10 }, */
                { key: 'completed', name: 'Fullført', width: 10 },
                { key: 'method', name: 'EID', width: 10 },
                { key: 'confirmedName', name: 'Bekreftet navn', width: 10 },
                { key: 'confirmedDob', name: 'Bekreftet fødselsdato', width: 10 },
                { key: 'indicator', name: 'Indicator', width: 10 },
                { key: 'match', name: 'Match', width: 10 },
            ];
            for (const i in this.loadedCustomers) {
                const person = tools.decodePerson(this.loadedCustomers[i].identity);
                const fullName = tools.titleCase(person.firstName) + ' ' + tools.titleCase(person.lastName);
                const companyString = '';
                if (this.loadedCustomers[i].companies) {
                    for (const j in this.loadedCustomers[i].companies) {
                        if (j > 0) companyString += ', ';
                        companyString += this.loadedCustomers[i].companies[j].name;
                    }
                }

/* 
                this.loadedRows.push({
                    editorFirstName: person.firstName,
                    editorLastName: person.lastName,
                    dateOfBirth: person.dateOfBirth,
                    identity: this.loadedCustomers[i].identity,
                    name: companyString,
                    formDate: this.loadedCustomers[i].idc.sentDate ? tools.dateStringShort(this.loadedCustomers[i].idc.sentDate) : '',
                    envelopeId: this.loadedCustomers[i].idc.envelopeId,
                    status: this.loadedCustomers[i].idc.envelopeId,
                });
 */
                this.loadedRows.push({
                    editorFirstName: person.firstName,
                    editorLastName: person.lastName,
                    dateOfBirth: person.dateOfBirth,
                    identity: this.loadedCustomers[i].identity,
                    name: companyString,
                    formDate: this.loadedCustomers[i].control.sentDate ? tools.dateStringShort(this.loadedCustomers[i].control.sentDate) : '',
                    envelopeId: this.loadedCustomers[i].control.envelopeId,
                    status: '',
                });
            }
        },

        async fillData() {
            let hasReachedEnd = false;
            let i = 0;
            while (!hasReachedEnd) {
                if (this.loadedCustomers[i].skip) {
                    console.log('skipping ' + i, this.loadedCustomers[i].name);
                    i += 1;
                    continue;
                }
                console.log('filling ' + i, this.loadedCustomers[i].name);
                await new Promise(r => setTimeout(r, 500));

                    log.suppress = true;
                let client = null;
                try {
                    client = await clienthelper.loadClient(this.$root, this.loadedCustomers[i]);
                }catch (e) {
                    i += 1;
                    continue;
                }
                 
                log.suppress = false;

                this.loadedRows[i].editorFirstName = client.kycMetadata.editor.firstName;
                this.loadedRows[i].editorLastName = client.kycMetadata.editor.lastName;
                this.loadedRows[i].editorEmail = client.kycMetadata.editor.email;
                this.loadedRows[i].status = client.kycMetadata.editor.lastEvent;
                this.loadedRows[i].envelopeId = client.kycMetadata.envelopeId;
                this.loadedRows[i].recipientId = client.kycMetadata.editor.id;
                this.loadedRows[i].formDate = client.kycMetadata.envelopeDateCreated;

                if (client.brregData && client.brregData.name) {
                    this.loadedRows[i].orgForm = client.brregData.org_form;
                    this.loadedRows[i].businessCode = client.brregData.business_code;
                    this.loadedRows[i].businessDesc = client.brregData.business;
                }else if (client.manualData && client.manualData.name) {
                    this.loadedRows[i].orgForm = client.manualData.org_form;
                    this.loadedRows[i].businessCode = client.manualData.business_code;
                    this.loadedRows[i].businessDesc = client.manualData.business;
                }
                
                const idc1 = {
                    isSet: false,
                    sent: 'NEI',
                    sentDate: '',
                    status: '',
                    role: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    recipientId: '',
                    envelopeId: ''
                };
                let hasPep = false; let hasSanction = false;
                let pepTitle = '';
                let pepRole = '';
                let pepMatchIndicator = '';
                let pepMatchDescription = '';
                let rrhCountryList = [];
                let roleCountryList = [];
                let hasSelfPep = false;
                let selfPepTitle = '';
                let selfPepRole = '';
                let selfPepName = '';
                let peps = [];
                for (const j in client.persons) {
                    const pepItem = {
                        self: false,
                        hit: false,
                        selfData: {},
                        hitData: {}
                    };
                    if (client.persons[j].search.isPep) {
                        console.log('pep', client.persons[j]);
                        hasPep = true;
                        pepRole = this.getDisplayRoles(client.persons[j]);
                        pepTitle = client.persons[j].search.pepTitle;
                        pepMatchIndicator = client.persons[j].search.pepMatchIndicator;
                        pepMatchDescription = client.persons[j].search.pepMatchDescription;
                        pepItem.hit = true;
                        pepItem.hitData.role = this.getDisplayRoles(client.persons[j]);
                        pepItem.hitData.name = client.persons[j].search.pepName;
                        pepItem.hitData.title = client.persons[j].search.pepTitle;
                        pepItem.hitData.pepMatchIndicator = client.persons[j].search.pepMatchIndicator;
                        pepItem.hitData.pepMatchDescription = client.persons[j].search.pepMatchDescription;
                    }
                    if (client.persons[j].search.hasSanction) hasSanction = true;
                    if (personhelper.hasRole(client.persons[j], 'screening_role_rrh')) {
                        let country = '';
                        country = client.persons[j].countryName;
                        if (country != '' && !rrhCountryList.includes(country)) {
                            rrhCountryList.push(country);
                        }
                    }
                    let country = '';
                    country = client.persons[j].countryName;
                    if (country != '' && !roleCountryList.includes(country)) {
                        roleCountryList.push(country);
                    }

                    if (client.persons[j].flags.kycPep) {
                        hasSelfPep = true;
                        selfPepTitle = client.persons[j].flags.kycPepTitle;
                        selfPepRole = this.getDisplayRoles(client.persons[j]);
                        selfPepName = client.persons[j].displayName;
                        pepItem.self = true;
                        pepItem.selfData.title = client.persons[j].flags.kycPepTitle;
                        pepItem.selfData.role = this.getDisplayRoles(client.persons[j]);
                        pepItem.selfData.name = client.persons[j].displayName;
                    }

                    if (!idc1.isSet && (personhelper.hasRole(client.persons[j], 'ceo') || personhelper.hasRole(client.persons[j], 'chair') || personhelper.hasRole(client.persons[j], 'screening_role_rrh'))) {
                        if (client.persons[j].control.completed) {

                            if (client.persons[j].control.method == 'manual') {
                                idc1.sent  = '';
                                idc1.status = 'Fullført manuelt';
                            }else {
                                idc1.sent  = 'JA';
                                idc1.status  = 'Fullført';
                            }

                            idc1.role = this.getDisplayRoles(client.persons[j]);
                            idc1.firstName = client.persons[j].info.firstName;
                            idc1.lastName = client.persons[j].info.lastName;

                            idc1.isSet = true;

                        }else if (client.persons[j].control.inviteSent) {

                            idc1.sent  = 'JA';
                            console.log('idc', client.persons[j].control.sentDate);
                            idc1.sentDate = tools.dateStringShort(client.persons[j].control.sentDate);
                            console.log('idc', idc1.sentDate);

                            if (client.persons[j].control.notifications.length > 0) {
                                if (client.persons[j].control.notifications[0].opened) {
                                    idc1.status = 'Åpnet';
                                }else if (client.persons[j].control.notifications[0].delivered) {
                                    idc1.status = 'Levert';
                                }else if (client.persons[j].control.notifications[0].sent) {
                                    idc1.status = 'Sendt';
                                }else {
                                    idc1.status = 'Ikke levert';
                                }
                            }else {
                                idc1.status = 'Ukjent';
                            }

                            idc1.role = this.getDisplayRoles(client.persons[j]);
                            idc1.firstName = client.persons[j].info.firstName;
                            idc1.lastName = client.persons[j].info.lastName;

                            idc1.email = client.persons[j].control.recipientEmail;
                            idc1.recipientId = client.persons[j].control.recipientId;
                            idc1.envelopeId = client.persons[j].control.envelopeId;

                        }
                    }
                    if (pepItem.self || pepItem.hit) peps.push(pepItem);
                }
                this.loadedRows[i].hasSanction = hasSanction ? 'ja' : 'nei';

                this.loadedRows[i].rrhCountries = rrhCountryList.toString();
                this.loadedRows[i].roleCountries = roleCountryList.toString();

                this.loadedRows[i].idcSent1 = idc1.sent;
                this.loadedRows[i].idcDate1 = idc1.sentDate;
                this.loadedRows[i].idcStatus1 = idc1.status;
                this.loadedRows[i].idcRole1 = idc1.role;
                this.loadedRows[i].idcFirstName1 = idc1.firstName;
                this.loadedRows[i].idcLastName1 = idc1.lastName;
                this.loadedRows[i].idcEmail1 = idc1.email;
                this.loadedRows[i].idcRecipientId1 = idc1.recipientId;
                this.loadedRows[i].idcEnvelopeId1 = idc1.envelopeId;

                this.loadedRows[i].readyForReport = this.readyForReport(client);


                if (this.loadedCustomers[i].batch != '-') {
                    const batchCustomer = await cache.getBatchCustomer(this.loadedCustomers[i].batch, this.loadedCustomers[i]._id);
                    if (batchCustomer) this.loadedRows[i].editorPhone = batchCustomer.phone;
                }

                let updateId = this.loadedCustomers[i]._id;

                //console.log('peps', peps);
                if (peps.length > 0) {

                    this.loadedRows[i].selfPep = peps[0].self ? 'ja' : 'nei';
                    this.loadedRows[i].selfPepTitle = peps[0].selfData.title;
                    this.loadedRows[i].selfPepRole = peps[0].selfData.role;
                    this.loadedRows[i].selfPepName = peps[0].selfData.name;

                    this.loadedRows[i].hasPep = peps[0].hit ? 'ja' : 'nei';
                    this.loadedRows[i].pepTitle = peps[0].hitData.title;
                    this.loadedRows[i].pepRole = peps[0].hitData.role;
                    this.loadedRows[i].pepName = peps[0].hitData.name;
                    this.loadedRows[i].pepMatchIndicator = peps[0].hitData.pepMatchIndicator;
                    this.loadedRows[i].pepMatchDescription = peps[0].hitData.pepMatchDescription;

                }else {
                    this.loadedRows[i].selfPep = 'nei';
                    this.loadedRows[i].hasPep = 'nei';
                }
                if (peps.length > 1) {
                    for (const j in peps) {
                        if (j == 0) continue;
                        const copy = JSON.parse(JSON.stringify(this.loadedRows[i]));
                        copy.selfPep = peps[j].self ? 'ja' : 'nei';
                        copy.selfPepTitle = peps[j].selfData.title || '';
                        copy.selfPepRole = peps[j].selfData.role || '';
                        copy.selfPepName = peps[j].selfData.name || '';
                        copy.hasPep = peps[j].hit ? 'ja' : 'nei';
                        copy.pepTitle = peps[j].hitData.title || '';
                        copy.pepRole = peps[j].hitData.role || '';
                        copy.pepName = peps[j].hitData.name || '';
                        this.loadedCustomers[i].skip = true;
                        this.loadedRows.splice(i, 0, copy);
                        this.loadedCustomers.splice(i, 0, { skip: true, name: this.loadedRows[i].name });
                    }
                }

                this.$refs.datatable.updateRows();

                const udata = {
                    status1: 2
                };
                await api.updateCustomer(updateId, udata);

                i += 1;
                if (i >= this.loadedCustomers.length) hasReachedEnd = true;
            }
        },

        async fillData2() {
            for (const i in this.loadedCustomers) {
                await new Promise(r => setTimeout(r, 500));

                if (this.loadedCustomers[i].control && this.loadedCustomers[i].control.envelopeId) {

                    const envelope = await this.$root.getEnvelope(this.loadedCustomers[i].control.envelopeId);
                    console.log('envelope', envelope);

                    const recipient = envelope.content.recipients[0];
                    let status = 'unknown';
                    for (const j in recipient.notifications) {
                        status = recipient.notifications[j].status;
                    }

                    this.loadedRows[i].idControl = 'JA';

                    this.loadedRows[i].editorFirstName = recipient.givenName;
                    this.loadedRows[i].editorLastName = recipient.familyName;
                    this.loadedRows[i].editorEmail = recipient.email;
                    this.loadedRows[i].status = status;
                    this.loadedRows[i].recipientId = recipient.id;

                    if (envelope.userdata.control) {
                        this.loadedRows[i].completed = 'JA';
                        this.loadedRows[i].method = envelope.userdata.control.method;

                        const confirmedName = envelope.userdata.control.firstName + ' ' + envelope.userdata.control.lastName;

                        const person1 = {
                            firstName: this.loadedRows[i].editorFirstName,
                            lastName: this.loadedRows[i].editorLastName,
                            dateOfBirth: this.loadedRows[i].dateOfBirth,
                        };
                        const person2 = {
                            firstName: envelope.userdata.control.firstName,
                            lastName: envelope.userdata.control.lastName,
                            dateOfBirth: envelope.userdata.control.dateOfBirth,
                        };

                        this.loadedRows[i].confirmedName = person2.firstName + ' ' + person2.lastName;
                        this.loadedRows[i].confirmedDob = person2.dateOfBirth;

                        const indicator = this.getIdcMatchIndicator(person1, person2);

                        this.loadedRows[i].indicator = indicator.toString();
                        this.loadedRows[i].match = this.getIdcMatchDescription(indicator);

                    }

                    


                }

                

                this.$refs.datatable.updateRows();
            }
        },

        async loadData3() {

            const filters = [
                { field: 'status1', op: 'is', value: 1 },
                /* { field: 'status', op: 'x', values: ['c-aml', 'f-kyc-a', 'f-kyc-b', 'f-kyc-c', 'f-kyc-o', 'f-kyc-q', 'r-rep-a', 'r-rep-o', 'r-rep-k', 'r-rep-l', 'r-rep-m'] } */
                /* { field: 'userId', op: 'is', value: '/iss/eu-west-1_iuGGSHULf/b7b0df36-acea-4950-a076-d82b644f1e3e' } */
            ];
            const from = Number(this.skip);
            const limit = Number(this.limit);
            const sort = { name: 1 };
            const include = [];
            const counts = ['userId'];

            const response = await api.queryCustomers(filters, from, limit, sort, include, counts);
            this.loadedCustomers = response.customers;

            this.loadedRows = [];
            this.loadedColumns = [
                { key: 'name', name: 'Selskapsnavn', width: 10 },
                { key: 'partnerName', name: 'Partnernavn', width: 10 },
                { key: 'departmentName', name: 'Forretningsområde', width: 10 },
                { key: 'identity', name: 'Orgnr', width: 10 },
/*                 { key: 'orgSearch', name: 'BankID AML søk på foretaket utført', width: 10 },
                { key: 'personName', name: 'Navn, nøkkelperson', width: 10 },
                { key: 'personDob', name: 'Fødselsdato, nøkkelperson', width: 10 },
                { key: 'personRole', name: 'ID-type (nøkkelpersoner)', width: 10 },
                { key: 'personCountry', name: 'Land (nøkkelpersoner) (fra skjema)', width: 10 },
                { key: 'personEmail', name: 'Epost (nøkkelpersoner) (fra skjema)', width: 10 },
                { key: 'personSearch', name: 'PEP-søk utført', width: 10 },
                { key: 'personControl', name: 'ID-kontroll', width: 10 },
                { key: 'personMethod', name: 'ID-bekreftelse', width: 10 },
                { key: 'kycStatus', name: 'Signert e-skjema (selskapsoverordnet)', width: 10 },
                { key: 'personIdcName', name: 'Signert ID-kontroll person NAVN', width: 10 },
                { key: 'personIdcDob', name: 'Signert ID-kontroll person DATO', width: 10 },
                { key: 'personIdcIndicator', name: 'Signert ID-kontroll person INDICATOR', width: 10 },
                { key: 'personIdcMatch', name: 'Signert ID-kontroll person MATCH', width: 10 },
                { key: 'personKeywords', name: 'KYC-sjekk gjort', width: 10 },
                { key: 'finalStatus', name: 'Endelig status', width: 10 }, */
                { key: 'userName', name: 'Bruker (Kollegaer)', width: 10 },
                { key: 'lastEventDate', name: 'Siste Hendelse Dato', width: 10 },
                { key: 'lastEventText', name: 'Siste Hendelse Tekst', width: 10 },

/*                 { key: 'readyKyc', name: 'Status KYC', width: 10 },
                { key: 'readyID', name: 'Status ID', width: 10 },
                { key: 'readySearch', name: 'Status Søk', width: 10 },
                { key: 'readyAll', name: 'Status Alle', width: 10 }, */


/*                 { key: 'name', name: 'Selskapsnavn', width: 10 },
                { key: 'partnerName', name: 'Partnernavn', width: 10 }, */

                { key: 'xDateCreated', name: 'Dato opprettet', width: 10 },
                { key: 'xOrgSearch', name: 'AML-søk', width: 10 },
                { key: 'xOrgSearchDate', name: 'AML-søk TS', width: 10 },
                { key: 'xOrgStatus', name: 'Status', width: 10 },

                { key: 'xForm', name: 'E-skjema', width: 10 },
                { key: 'xFormReminders', name: 'E-skjema påminnelser', width: 10 },
                { key: 'xFormDate', name: 'E-skjema TS', width: 10 },

                { key: 'xFormFirstName', name: 'E-skjema fornavn', width: 10 },
                { key: 'xFormLastName', name: 'E-skjema etternavn', width: 10 },
                { key: 'xFormEmail', name: 'E-skjema epost', width: 10 },


                { key: 'xRoleName', name: 'Person navn', width: 10 },
                { key: 'xRoleDob', name: 'Person fødselsdato', width: 10 },
                { key: 'xRoleCountry', name: 'Person land', width: 10 },
                { key: 'xRoleRoles', name: 'Person roller', width: 10 },

                { key: 'xPep', name: 'PEP Kontroll', width: 10 },
                { key: 'xPepDate', name: 'PEP Kontroll TS', width: 10 },

                { key: 'xPepIndicator', name: 'PEP indikator', width: 10 },
                { key: 'xPepTitle', name: 'PEP tittel', width: 10 },
                { key: 'xPepSelf', name: 'PEP Selverklært', width: 10 },


                { key: 'xID', name: 'ID Kontroll', width: 10 },
                { key: 'xIDDate', name: 'ID Kontroll TS', width: 10 },

                { key: 'xIDComment', name: 'ID kommentar', width: 10 },
                { key: 'xIDEmail', name: 'ID epost', width: 10 },
                { key: 'xIDReminders', name: 'ID påminnelser', width: 10 },
                { key: 'xIDLastReminder', name: 'ID Siste påminnelse', width: 10 },
                { key: 'xIDName', name: 'ID Bekreftet navn', width: 10 },
                { key: 'xIDDOB', name: 'ID Bekreftet fødselsdato', width: 10 },
                { key: 'xIDIndicator', name: 'ID Match Indicator', width: 10 },
                { key: 'xIDDescription', name: 'ID Match Beskrivelse', width: 10 },

                { key: 'xGoogle', name: 'KYC Søk', width: 10 },
                { key: 'xGoogleDate', name: 'KYC Søk TS', width: 10 },

                { key: 'xReport', name: 'Sluttrapport', width: 10 },
                { key: 'xReportDate', name: 'Sluttrapport TS', width: 10 },

            ];
            for (const i in this.loadedCustomers) {
                this.loadedRows.push({
                    name: this.loadedCustomers[i].name,
                    identity: this.loadedCustomers[i].identity,
                    userId: this.loadedCustomers[i].userId,
                    userName: this.$root.getEmployeeName(this.loadedCustomers[i].userId),
                    department: this.loadedCustomers[i].department,
                    departmentName: this.$root.getDepartmentName(this.loadedCustomers[i].department),
                    partner: this.loadedCustomers[i].partner,
                    partnerName: this.$root.getPartnerName(this.loadedCustomers[i].partner),
                    reminder: this.getDateFromReminder(this.loadedCustomers[i].reminder),
                    /* formDate: this.loadedCustomers[i].kyc ? tools.dateStringShort(this.loadedCustomers[i].kyc.sentDate) : 'Ikke sendt ut', */
                    customerId: this.loadedCustomers[i]._id,
                    hasSearch: this.loadedCustomers[i].aml ? 'ja' : 'nei',
                    flowStatus: this.getStatusText(this.loadedCustomers[i].status),
                    country: this.loadedCustomers[i].country,
                });
            }
        },

        async fillData3() {
            let hasReachedEnd = false;
            let i = 0;
            while (!hasReachedEnd) {
                if (this.loadedCustomers[i].skip) {
                    console.log('skipping ' + i, this.loadedCustomers[i].name);
                    i += 1;
                    continue;
                }
                console.log('filling ' + i, this.loadedCustomers[i].name);
                await new Promise(r => setTimeout(r, 500));

                log.suppress = true;
                let client = null;
                try {
                    client = await clienthelper.loadClient(this.$root, this.loadedCustomers[i]);
                }catch (e) {
                    console.error('FAIL');
                    i += 1;
                    continue;
                }
                log.suppress = false;
                let updateId = this.loadedCustomers[i]._id;

                
                const events = client.events;
                events.sort((b, a) => (a.date > b.date) ? 1 : -1);
                console.log('events', events);

                const persons = [];
                for (const j in client.persons) {
                    if (client.persons[j].selected) persons.push(client.persons[j]);
                }

                const ready = this.readyForReport(client);

                console.log('client', client);


                let xOrgSearch = client.customer.aml ? 'OK' : 'Ikke utført (manuell)';
                if (client.amlData && client.amlData.responseWarnings) {
                    xOrgSearch = 'Ikke utført (mangler data)';
                }
                if (client.customer.source != 'brreg') {
                    xOrgSearch = 'Ikke utført (ikke brreg)';
                }
                let xOrgSearchDate = (client.customer.aml) ? this.formatDate('datetime', client.customer.aml.searchDate) : '-';

                let xOrgStatus = this.getStatusText(client.customer.status);

                console.log('kycMetadata', client.kycMetadata);

                let xResult = this.getKycStatus2(client);
                let xForm = xResult.status;
                let xFormDate = xResult.date;


                let xFormFirstName = client.kycMetadata.editor.firstName;
                let xFormLastName = client.kycMetadata.editor.lastName;
                let xFormEmail = client.kycMetadata.editor.email;


                let xReport = '';
                let xReportDate = '-';
                if (client.customer.report) {
                    xReport = 'OK (utført)';
                    xReportDate = this.formatDate('datetime', client.customer.report.reportDate);
                }else {
                    if (ready.hasKyc && ready.hasID && ready.hasSearch) {
                        xReport = 'Nei (Mangler KUN Sluttrapport)';
                    }else {
                        xReport = 'Nei';
                    }
                }
                

                let remCount = 0;
                if (client.kycMetadata.editor && client.kycMetadata.editor.events) {
                    for (const i in client.kycMetadata.editor.events) {
                        if (client.kycMetadata.editor.events[i] == 'sent') {
                            remCount += 1;
                        }
                    }
                }
                let xFormReminders = remCount.toString() || '-';


                if (persons.length > 0) {
                    this.loadedRows[i].orgSearch = client.customer.aml ? 'Ja' : 'Nei';
                    this.loadedRows[i].personName = persons[0].displayName;
                    this.loadedRows[i].personRole = this.getDisplayRoles(persons[0]);;
                    this.loadedRows[i].personSearch = persons[0].search.completed ? 'Ja' : 'Nei';

                    this.loadedRows[i].personControl = this.getIdcStatus(persons[0]);
                    this.loadedRows[i].personMethod = this.getIdcMethod(persons[0]);
                    this.loadedRows[i].kycStatus = this.getKycStatus(client);
                    this.loadedRows[i].personIdcName = this.getIdcName(persons[0]);
                    this.loadedRows[i].personDob = this.getPersonDob(persons[0]);
                    this.loadedRows[i].personCountry = this.getPersonCountry(persons[0]);
                    this.loadedRows[i].personEmail = this.getPersonEmail(persons[0]);
                    this.loadedRows[i].personIdcDob = this.getIdcDob(persons[0]);
                    this.loadedRows[i].personIdcIndicator = this.getIdcIndicator(persons[0]);
                    this.loadedRows[i].personIdcMatch = this.getIdcDescription(persons[0]);
                    this.loadedRows[i].personKeywords = this.getKeywordStatus(client, persons[0]);
                    this.loadedRows[i].finalStatus = client.customer.report ? 'Fullført sluttrapport' : 'Mangler sluttrapport';

                    this.loadedRows[i].lastEventDate = this.formatDate('datetime', events[0].date);
                    this.loadedRows[i].lastEventText = events[0].name;

                    this.loadedRows[i].readyKyc = ready.hasKyc ? 'Ja' : 'Nei';
                    this.loadedRows[i].readyID = ready.hasID ? 'Ja' : 'Nei';
                    this.loadedRows[i].readySearch = ready.hasSearch ? 'Ja' : 'Nei';
                    this.loadedRows[i].readyAll = (ready.hasKyc && ready.hasID && ready.hasSearch) ? 'Ja' : 'Nei';


                    const pepResult = this.getPepStatus(persons[0]);

                    let xRoleName = pepResult.name;
                    let xRoleDob = pepResult.dateOfBirth;
                    let xRoleCountry = pepResult.country;
                    let xRoleRoles = pepResult.roles;

                    let xPep = pepResult.status;
                    let xPepDate = pepResult.date;

                    let xPepIndicator = pepResult.matchIndicator;
                    let xPepTitle = pepResult.pepTitle;
                    let xPepSelf = pepResult.selfTitle;


                    const idcResult = this.getIdcStatus2(persons[0]);

                    let xID = idcResult.status;
                    let xIDDate = idcResult.date;

                    let xIDComment = idcResult.comment;
                    let xIDEmail = idcResult.email;
                    let xIDReminders = idcResult.reminders;
                    let xIDLastReminder = idcResult.lastReminder;
                    let xIDName = idcResult.name;
                    let xIDDOB = idcResult.dateOfBirth;
                    let xIDIndicator = idcResult.matchIndicator;
                    let xIDDescription = idcResult.matchDescription;


                    const googleResult = this.getGoogleStatus(persons[0], client);

                    let xGoogle = googleResult.status;
                    let xGoogleDate = googleResult.date;


                    this.loadedRows[i].xDateCreated = this.formatDate('datetime', client.customer.dateCreated);

                    this.loadedRows[i].xOrgSearch = xOrgSearch;
                    this.loadedRows[i].xOrgSearchDate = xOrgSearchDate;
                    this.loadedRows[i].xOrgStatus = xOrgStatus;
                    this.loadedRows[i].xForm = xForm;
                    this.loadedRows[i].xFormReminders = xFormReminders;
                    this.loadedRows[i].xFormDate = xFormDate;

                    this.loadedRows[i].xFormFirstName = xFormFirstName;
                    this.loadedRows[i].xFormLastName = xFormLastName;
                    this.loadedRows[i].xFormEmail = xFormEmail;

                    this.loadedRows[i].xRoleName = xRoleName;
                    this.loadedRows[i].xRoleDob = xRoleDob;
                    this.loadedRows[i].xRoleCountry = xRoleCountry;
                    this.loadedRows[i].xRoleRoles = xRoleRoles;
                    this.loadedRows[i].xPep = xPep;
                    this.loadedRows[i].xPepDate = xPepDate;
                    this.loadedRows[i].xPepIndicator = xPepIndicator;
                    this.loadedRows[i].xPepTitle = xPepTitle;
                    this.loadedRows[i].xPepSelf = xPepSelf;

                    this.loadedRows[i].xID = xID;
                    this.loadedRows[i].xIDDate = xIDDate;

                    this.loadedRows[i].xIDComment = xIDComment;
                    this.loadedRows[i].xIDEmail = xIDEmail;
                    this.loadedRows[i].xIDReminders = xIDReminders;
                    this.loadedRows[i].xIDLastReminder = xIDLastReminder;
                    this.loadedRows[i].xIDName = xIDName;
                    this.loadedRows[i].xIDDOB = xIDDOB;
                    this.loadedRows[i].xIDDescription = xIDDescription;

                    this.loadedRows[i].xGoogle = xGoogle;
                    this.loadedRows[i].xGoogleDate = xGoogleDate;

                    this.loadedRows[i].xReport = xReport;
                    this.loadedRows[i].xReportDate = xReportDate;

                }else {

                    this.loadedRows[i].personName = '-';
                    this.loadedRows[i].personRole = '-'
                    this.loadedRows[i].personSearch = '-';
                    this.loadedRows[i].personControl = '-';
                    this.loadedRows[i].personMethod = '-';
                    this.loadedRows[i].personIdcName = '-';
                    this.loadedRows[i].personKeywords = '-';
                    this.loadedRows[i].personDob = '-';
                    this.loadedRows[i].personCountry = '-';
                    this.loadedRows[i].personEmail = '-';
                    this.loadedRows[i].personIdcDob = '-';
                    this.loadedRows[i].personIdcIndicator = '-';
                    this.loadedRows[i].personIdcMatch = '-';

                    this.loadedRows[i].orgSearch = client.customer.aml ? 'Ja' : 'Nei';
                    this.loadedRows[i].finalStatus = client.customer.report ? 'Fullført sluttrapport' : 'Mangler sluttrapport';
                    this.loadedRows[i].kycStatus = this.getKycStatus(client);
                    this.loadedRows[i].lastEventDate = this.formatDate('datetime', events[0].date);
                    this.loadedRows[i].lastEventText = events[0].name;

                    this.loadedRows[i].readyKyc = '-';
                    this.loadedRows[i].readyID = '-';
                    this.loadedRows[i].readySearch = '-';
                    this.loadedRows[i].readyAll = '-';

                    this.loadedRows[i].xDateCreated = this.formatDate('datetime', client.customer.dateCreated);

                    this.loadedRows[i].xOrgSearch = xOrgSearch;
                    this.loadedRows[i].xOrgSearchDate = xOrgSearchDate;
                    this.loadedRows[i].xOrgStatus = xOrgStatus;
                    this.loadedRows[i].xForm = xForm;
                    this.loadedRows[i].xFormDate = xFormDate;

                    this.loadedRows[i].xFormFirstName = xFormFirstName;
                    this.loadedRows[i].xFormLastName = xFormLastName;
                    this.loadedRows[i].xFormEmail = xFormEmail;

                    this.loadedRows[i].xReport = xReport;
                    this.loadedRows[i].xReportDate = xReportDate;

                }
                if (persons.length > 1) {
                    for (const j in persons) {
                        if (j == 0) continue;
                        const copy = JSON.parse(JSON.stringify(this.loadedRows[i]));
                        console.log('person', persons[j]);

                        copy.personName = persons[j].displayName;
                        copy.personRole = this.getDisplayRoles(persons[j]);;
                        copy.personSearch = persons[j].search.completed ? 'Ja' : 'Nei';

                        copy.personControl = this.getIdcStatus(persons[j]);
                        copy.personMethod = this.getIdcMethod(persons[j]);
                        copy.personIdcName = this.getIdcName(persons[j]);
                        copy.personKeywords = this.getKeywordStatus(client, persons[j]);

                        copy.personDob = this.getPersonDob(persons[j]);
                        copy.personCountry = this.getPersonCountry(persons[j]);
                        copy.personEmail = this.getPersonEmail(persons[j]);
                        copy.personIdcDob = this.getIdcDob(persons[j]);
                        copy.personIdcIndicator = this.getIdcIndicator(persons[j]);
                        copy.personIdcMatch = this.getIdcDescription(persons[j]);



                        const pepResult = this.getPepStatus(persons[j]);
                        copy.xRoleName = pepResult.name;
                        copy.xRoleDob = pepResult.dateOfBirth;
                        copy.xRoleCountry = pepResult.country;
                        copy.xRoleRoles = pepResult.roles;
                        copy.xPep = pepResult.status;
                        copy.xPepDate = pepResult.date;
                        copy.xPepIndicator = pepResult.matchIndicator;
                        copy.xPepTitle = pepResult.pepTitle;
                        copy.xPepSelf = pepResult.selfTitle;


                        const idcResult = this.getIdcStatus2(persons[j]);
                        copy.xID = idcResult.status;
                        copy.xIDDate = idcResult.date;
                        copy.xIDComment = idcResult.comment;
                        copy.xIDEmail = idcResult.email;
                        copy.xIDReminders = idcResult.reminders;
                        copy.xIDLastReminder = idcResult.lastReminder;
                        copy.xIDName = idcResult.name;
                        copy.xIDDOB = idcResult.dateOfBirth;
                        copy.xIDIndicator = idcResult.matchIndicator;
                        copy.xIDDescription = idcResult.matchDescription;


                        const googleResult = this.getGoogleStatus(persons[j], client);

                        copy.xGoogle = googleResult.status;
                        copy.xGoogleDate = googleResult.date;


                        this.loadedCustomers[i].skip = true;
                        this.loadedRows.splice(i, 0, copy);
                        this.loadedCustomers.splice(i, 0, { skip: true, name: this.loadedRows[i].name });
                    }
                }


                const udata = {
                    status1: 2
                };
                await api.updateCustomer(updateId, udata);


                this.$refs.datatable.updateRows();
                i += 1;
                if (i >= this.loadedCustomers.length) hasReachedEnd = true;
            }
        },


        async loadData4() {

            const filters = [
                /* { field: 'status', op: 'is', value: 'f-kyc-b' } */
                { field: 'status1', op: 'is', value: 1 },
                { field: 'migrated', op: 'exists', values: false }
            ];
            const from = Number(this.skip);
            const limit = Number(this.limit);
            const sort = { name: 1 };
            const include = [];
            const counts = ['userId'];

            const response = await api.queryCustomers(filters, from, limit, sort, include, counts);
            this.loadedCustomers = response.customers;

            this.loadedRows = [];
            this.loadedColumns = [
                /* { key: 'fullName', name: 'Navn', width: 10 },
                { key: 'dateOfBirth', name: 'Fødselsdato', width: 10 }, */
                { key: 'name', name: 'Selskap', width: 10 },
                { key: 'identity', name: 'Organisasjonsnummer', width: 10 },
                { key: 'orgForm', name: 'Selskapstype', width: 10 },
                { key: 'businessCode', name: 'Næringskode', width: 10 },
                { key: 'businessDesc', name: 'Beskrivelse', width: 10 },

                
                { key: 'partnerName', name: 'Partnernavn', width: 10 },

                { key: 'departmentName', name: 'Forretningsområde', width: 10 },

                { key: 'flowStatus', name: 'Status for Kundekontrollen', width: 10 },

                { key: 'orgDuns', name: 'Duns Nummer', width: 10 },
                { key: 'orgAddress', name: 'Adresse (Selskap)', width: 10 },
                { key: 'orgCountry', name: 'Land (selskap)', width: 10 },
                { key: 'orgFounded', name: 'Stiftelsesdato', width: 10 },
                { key: 'orgRegistered', name: 'Dato registrert', width: 10 },
                { key: 'orgIndustry', name: 'Industrikode', width: 10 },
                { key: 'orgBusiness', name: 'Bransje', width: 10 },

                { key: 'orgEnhet', name: 'Registrert i enhetsregisteret', width: 10 },
                { key: 'orgForetak', name: 'Registrert i foretaksregisteret', width: 10 },
                { key: 'orgMva', name: 'Registrert i MVA-registeret', width: 10 },
                { key: 'orgKonkurs', name: 'Konkurs', width: 10 },
                { key: 'orgAvvikling', name: 'Under avvikling', width: 10 },
                { key: 'orgTvangs', name: 'Under tvangsavvikling/oppløsning', width: 10 },

                { key: 'personRole', name: 'Rolle (Person)', width: 10 },
                { key: 'personFirstName', name: 'Fornavn', width: 10 },
                { key: 'personLastName', name: 'Etternavn', width: 10 },
                { key: 'personDob', name: 'Fødselsdato', width: 10 },
                { key: 'personAddress', name: 'Adresse (Person)', width: 10 },
                { key: 'personCountry', name: 'Land (Person)', width: 10 },
                
                { key: 'hasSanction', name: 'Sanksjon', width: 10 },
                { key: 'hasPep', name: 'PEP', width: 10 },

                { key: 'pepMatchIndicator', name: 'Treff indikator', width: 10 },
                { key: 'pepMatchDescription', name: 'Treff beskrivelse', width: 10 },

                { key: 'pepTitle', name: 'PEP Tittel', width: 10 },
                { key: 'pepRole', name: 'PEP Rolle', width: 10 },
                { key: 'pepName', name: 'PEP Navn', width: 10 },
                { key: 'pepDateOfBirth', name: 'PEP Fødselsdato', width: 10 },

                { key: 'selfPep', name: 'Selverklært PEP', width: 10 },
                { key: 'selfPepTitle', name: 'PEP Tittel (Selverklært)', width: 10 },
                
            ];
            for (const i in this.loadedCustomers) {
                this.loadedRows.push({
                    name: this.loadedCustomers[i].name,
                    identity: this.loadedCustomers[i].identity,
                    userId: this.loadedCustomers[i].userId,
                    userName: this.$root.getEmployeeName(this.loadedCustomers[i].userId),
                    department: this.loadedCustomers[i].department,
                    departmentName: this.$root.getDepartmentName(this.loadedCustomers[i].department),
                    partner: this.loadedCustomers[i].partner,
                    partnerName: this.$root.getPartnerName(this.loadedCustomers[i].partner),
                    customerId: this.loadedCustomers[i]._id,
                    orgCountry: this.loadedCustomers[i].country,
                    flowStatus: this.getStatusText(this.loadedCustomers[i].status),
                    dateUpdated: this.formatDate('datetime', this.loadedCustomers[i].dateUpdated),
                    kycStatus: this.getKycStatus3(this.loadedCustomers[i]),
                    kycFilledDate: this.getKycDate3(this.loadedCustomers[i]),
                    reportDate: (this.loadedCustomers[i].report && this.loadedCustomers[i].report.reportDate) ? this.formatDate('datetime', this.loadedCustomers[i].report.reportDate) : '--',
                });
            }
        },

        getKycStatus3(customer) {
            if (customer.kyc) {

                if (customer.kyc.completedDate) {
                    return 'Fullført';
                }else {
                    return 'Ikke utfylt';
                }

            }else {
                return 'Ikke startet';
            }
        },

        getKycDate3(customer) {
            if (customer.kyc && customer.kyc.completedDate) {

                return this.formatDate('datetime', customer.kyc.completedDate);

            }else {
                return '--';
            }
        },

        async fillData4() {
            let hasReachedEnd = false;
            let i = 0;
            let count = 0;
            while (!hasReachedEnd) {
                if (this.loadedCustomers[i].skip) {
                    //console.log('skipping ' + i, this.loadedCustomers[i].name);
                    i += 1;
                    continue;
                }
                console.log('filling ' + count, this.loadedCustomers[i].name);
                count += 1;
                await new Promise(r => setTimeout(r, 500));

                log.suppress = true;
                let client = null;
                try {
                    client = await clienthelper.loadClient(this.$root, this.loadedCustomers[i]);
                }catch (e) {
                    i += 1;
                    continue;
                }
                log.suppress = false;

                let updateId = this.loadedCustomers[i]._id;

                console.log('client', client);


                if (client.brregData && client.brregData.name) {
                    this.loadedRows[i].orgForm = client.brregData.org_form;
                    this.loadedRows[i].businessCode = client.brregData.business_code;
                    this.loadedRows[i].businessDesc = client.brregData.business;

                    this.loadedRows[i].orgAddress = client.brregData.address + ', ' + client.brregData.zip + ' ' + client.brregData.town;
                    this.loadedRows[i].orgFounded = client.brregData.start_date;
                    this.loadedRows[i].orgRegistered = client.brregData.registration_date;
                    this.loadedRows[i].orgEnhet = client.brregData.inBrReg ? 'Ja' : 'Nei';
                    this.loadedRows[i].orgForetak = client.brregData.inBusinessReg ? 'Ja' : 'Nei';
                    this.loadedRows[i].orgMva = client.brregData.inMvaReg ? 'Ja' : 'Nei';
                    this.loadedRows[i].orgKonkurs = client.brregData.bankrupt ? 'Ja' : 'Nei';
                    this.loadedRows[i].orgAvvikling = client.brregData.liquidation ? 'Ja' : 'Nei';
                    this.loadedRows[i].orgTvangs = client.brregData.compulsoryLiquidation ? 'Ja' : 'Nei';

                }else if (client.manualData && client.manualData.name) {
                    console.log('manualData', client.manualData);
                    this.loadedRows[i].orgForm = client.manualData.org_form;
                    this.loadedRows[i].businessCode = client.manualData.business_code;
                    this.loadedRows[i].businessDesc = client.manualData.business;
                }

                if (client.amlData && client.amlData.keyInformation) {
                    if (client.amlData.keyInformation.institutionalSector) this.loadedRows[i].orgIndustry = client.amlData.keyInformation.institutionalSector.code + ' - ' + client.amlData.keyInformation.institutionalSector.description;
                    /* this.loadedRows[i].orgBusiness = client.amlData.keyInformation.purpose; */
                    this.loadedRows[i].orgDuns = client.amlData.keyInformation.dunsNumber;
                }
                
                const persons = [];
                for (const j in client.persons) {
                    //console.log('person', client.persons[j]);
                    const person = {

                        firstName: '-',
                        lastName: '-',
                        dateOfBirth: '-',
                        role: '-',
                        address: '-',
                        country: '-',

                        done: false,
                        sanction: false,
                        self: false,
                        hit: false,
                        selfData: {
                            title: '-'
                        },
                        hitData: {
                            name: '-',
                            title: '-',
                            pepMatchIndicator: '-',
                            pepMatchDescription: '-',
                        }
                    };

                    person.firstName = client.persons[j].info.firstName;
                    person.lastName = client.persons[j].info.lastName;
                    person.dateOfBirth = client.persons[j].info.dateOfBirth;

                    person.role = this.getDisplayRoles(client.persons[j]);
                    person.country = client.persons[j].countryName;

                    for (const v in client.persons[j].addresses) {
                        const adr = client.persons[j].addresses[v];
                        person.address = adr.street + ', ' + adr.zip + ' ' + adr.city + ' (' + adr.countryName + ')';
                    }

                    if (client.persons[j].search.completed) {
                        person.done = true;
                    }

                    if (client.persons[j].search.isPep) {
                        //console.log('pep', client.persons[j]);
                        person.hit = true;
                        person.hitData.name = client.persons[j].search.pepName;
                        person.hitData.title = client.persons[j].search.pepTitle.replace(',', ' - ').replace(',', ' - ');
                        person.hitData.pepMatchIndicator = client.persons[j].search.pepMatchIndicator;
                        person.hitData.pepMatchDescription = client.persons[j].search.pepMatchDescription;
                    }
                    if (client.persons[j].search.hasSanction) person.sanction = true;

                    
                    if (client.persons[j].flags.kycPep) {
                        person.self = true;
                        person.selfData.title = client.persons[j].flags.kycPepTitle;
                    }

                    persons.push(person);
                }


                //console.log('persons', persons);
                if (persons.length > 0) {

                    this.loadedRows[i].personFirstName = persons[0].firstName;
                    this.loadedRows[i].personLastName = persons[0].lastName;
                    this.loadedRows[i].personDob = persons[0].dateOfBirth;

                    this.loadedRows[i].personRole = persons[0].role;
                    this.loadedRows[i].personAddress = persons[0].address.replace('#', 'nr.');
                    this.loadedRows[i].personCountry = persons[0].country;

                    this.loadedRows[i].selfPep = persons[0].self ? 'JA' : 'NEI';
                    this.loadedRows[i].selfPepTitle = persons[0].selfData.title;

                    this.loadedRows[i].hasSanction = persons[0].sanction ? 'JA' : 'NEI';
                    this.loadedRows[i].hasPep = persons[0].hit ? 'JA' : 'NEI';
                    if (!persons[0].done) {
                        this.loadedRows[i].hasSanction = '-';
                        this.loadedRows[i].hasPep = '-';
                    }

                    this.loadedRows[i].pepTitle = persons[0].hitData.title;
                    this.loadedRows[i].pepName = persons[0].hitData.name;
                    this.loadedRows[i].pepMatchIndicator = persons[0].hitData.pepMatchIndicator;
                    this.loadedRows[i].pepMatchDescription = persons[0].hitData.pepMatchDescription;

                }else {
                    
                }
                if (persons.length > 1) {
                    for (const j in persons) {
                        if (j == 0) continue;
                        const copy = JSON.parse(JSON.stringify(this.loadedRows[i]));

                        copy.personFirstName = persons[j].firstName;
                        copy.personLastName = persons[j].lastName;
                        copy.personDob = persons[j].dateOfBirth;

                        copy.personRole = persons[j].role;
                        copy.personAddress = persons[j].address.replace('#', 'nr.');
                        copy.personCountry = persons[j].country;

                        copy.selfPep = persons[j].self ? 'JA' : 'NEI';
                        copy.selfPepTitle = persons[j].selfData.title || '-';

                        copy.hasSanction = persons[j].sanction ? 'JA' : 'NEI';
                        copy.hasPep = persons[j].hit ? 'JA' : 'NEI';
                        if (!persons[j].done) {
                            copy.hasSanction = '-';
                            copy.hasPep = '-';
                        }

                        copy.pepTitle = persons[j].hitData.title || '-';
                        copy.pepName = persons[j].hitData.name || '-';
                        copy.pepMatchIndicator = persons[j].hitData.pepMatchIndicator || '-';
                        copy.pepMatchDescription = persons[j].hitData.pepMatchDescription || '-';

                        this.loadedCustomers[i].skip = true;
                        this.loadedRows.splice(i, 0, copy);
                        this.loadedCustomers.splice(i, 0, { skip: true, name: this.loadedRows[i].name });
                    }
                }

                this.$refs.datatable.updateRows();

                const udata = {
                    status1: 2
                };
                await api.updateCustomer(updateId, udata);

                i += 1;
                if (i >= this.loadedCustomers.length) hasReachedEnd = true;
            }
        },





        getPepStatus(person) {
            const result = {
                name: '',
                dateOfBirth: '',
                roles: '',
                status: 'Ikke utført',
                matchIndicator: '-',
                pepTitle: '-',
                selfTitle: '-',
                country: '?',
                date: '-'
            };

            console.log('person', person);

            result.name = person.displayName;
            result.dateOfBirth = person.info.dateOfBirth;
            result.country = person.countryName;
            result.roles = this.getDisplayRoles(person);

            if (person.search.completed) {

                result.date = this.formatDate('datetime', person.search.dateCompleted);

                result.status = 'OK (Ingen treff)';

                if (person.search.isPep) {

                    result.matchIndicator = person.search.pepMatchIndicator;
                    result.pepTitle = person.search.pepTitle;

                    result.status = 'OK (' + person.search.pepMatchIndicator + ')';

                }

            }

            if (person.flags.kycPep) {

                result.selfTitle = person.flags.kycPepTitle;

                if (result.status == 'OK (Ingen treff)') {
                    result.status = 'OK (Selv.e.PEP)';
                }

            }

            

            return result;
        },

        getIdcStatus2(person) {
            const result = {
                status: 'Ikke Sendt',
                date: '-',
                comment: '-',
                reminders: '-',
                lastReminder: '-',
                matchIndicator: '-',
                matchDescription: '-',
                email: '-',
                name: '-',
                dateOfBirth: '-'
            };

            if (person.control) {

                if (person.control.completed) {
                    

                    if (person.control.method == 'bankid-no') {
                        result.status = 'OK (BankID)';

                        result.matchIndicator = this.getIdcIndicator(person);
                        result.matchDescription = this.getIdcDescription(person);

                        result.name = person.control.confirmedName;
                        result.dateOfBirth = person.control.confirmedInfo.dateOfBirth;

                    }else if (person.control.method == 'fileupload') {
                        result.status = 'OK (PassKopi)';
                    }else if (person.control.method == 'manual') {
                        result.status = 'OK (Bekreftet U/Portalen)';
                        result.comment = person.control.manualInfo.comment;
                    }

                    result.email = person.control.recipientEmail;

                    result.date = this.formatDate('datetime', person.control.dateCompleted);

                }else if (person.control.inviteSent) {

                    result.status = 'Ikke Gjort';

                    if (person.control.notifications) {
                        result.reminders = person.control.notifications.length;
                        if (person.control.notifications[person.control.notifications.length - 1]) {
                            if (person.control.notifications[person.control.notifications.length - 1].sentDate) {
                                result.lastReminder = this.formatDate('datetime', person.control.notifications[person.control.notifications.length - 1].sentDate);
                            }else if (person.control.notifications[person.control.notifications.length - 1].deliveredDate) {
                                result.lastReminder = this.formatDate('datetime', person.control.notifications[person.control.notifications.length - 1].deliveredDate);
                            }
                            
                        }
                    }

                    console.log('NOTI', person.control.notifications);

                    if (person.control.notifications.length == 0) {
                        result.status = 'Bounced (ingen meldinger)';
                    }

                    let bounced = false;
                    for (const i in person.control.events) {
                        if (person.control.events[i].type == 'bounced') {
                            bounced = true;
                        }
                    }

                    result.email = person.control.recipientEmail;

                    result.date = this.formatDate('datetime', person.control.sentDate);

                    if (bounced) {
                        result.status = 'Bounced';
                    }

                }

            }

            

            return result;
        },

        getGoogleStatus(person, client) {
            const result = {
                status: '',
                date: '-'
            };

            if (client.reportData && client.reportData[person.id]) {

                console.log('REPORT', client.reportData[person.id]);

                const data = client.reportData[person.id];

                if (data.searchComplete) {
                    result.status = 'OK (utført)';
                }else {

                    if (person.search.completed) {
                        result.status = 'Nei (Klar for KYC)';
                    }else {
                        result.status = 'Nei (PEP-ikke gjort)';
                    }

                }

            }

            return result;
        },

        getIdcStatus(person) {
            let idcStatus = 'Ikke sendt';
            if (person.control.completed) {
                idcStatus = 'OK';
            }else if (person.control.inviteSent) {
                idcStatus = 'Avventer';
            }
            return idcStatus;
        },
        getIdcMethod(person) {
            let idcMethod = 'Ingen';
            if (person.control.completed) {
                idcMethod = person.control.method;
            }else if (person.control.inviteSent) {
                
            }
            return idcMethod;
        },
        getIdcName(person) {
            if (person.control.completed) {
                if (person.control.method == 'manual' || person.control.method == 'fileupload') return '-';
                return person.control.confirmedName;
            }
            return '-';
        },
        getIdcDob(person) {
            if (person.control.completed) {
                if (person.control.method == 'manual' || person.control.method == 'fileupload') return '-';
                return person.control.confirmedInfo.dateOfBirth;
            }
            return '-';
        },
        getPersonDob(person) {
            return person.info.dateOfBirth || '-';
        },
        getPersonCountry(person) {
            return person.countryName || '-';
        },
        getPersonEmail(person) {
            return person.contact.email || '-';
        },

        getKeywordStatus(client, person) {
            if (client.reportData[person.id]) {
                if (client.reportData[person.id].searchComplete) return 'Ja';
            }
            return 'Nei';
        },

        getKycStatus(client) {
            if (client.customer.kyc) {
                if (client.kycMetadata.signed) return 'Ja';
                if (client.kycMetadata.filled) return 'Avventer signering';
                return 'Avventer utfylling';
            }
            return 'Ikke startet';
        },

        getKycStatus2(client) {
            const result = {
                status: '',
                date: ''
            };
            if (client.customer.kyc) {
                result.date = this.formatDate('datetime', client.kycMetadata.lastModifiedDate);

                result.status = 'Utsendt (Ikke startet)';

                if (client.kycMetadata.editor.lastEvent == 'bounced') {
                    result.status = 'Bounced';
                }
                if (client.kycMetadata.aborted) {
                    result.status = 'Avbrutt av Kontaktperson';
                }

                if (client.kycMetadata.completed) result.status = 'Utfylt (Ikke signert)';
                if (client.kycMetadata.signed) result.status = 'OK (Utfylt og signert)';
            }else {
                result.status = 'Ikke sendt';
            }
            return result;
        },

        getIdcIndicator(person) {
            if (person.control.completed) {
                if (person.control.method == 'manual' || person.control.method == 'fileupload') return '-';
                const person1 = {
                    firstName: person.info.firstName,
                    lastName: person.info.lastName,
                    dateOfBirth: person.info.dateOfBirth
                };
                const person2 = {
                    firstName: person.control.confirmedInfo.firstName,
                    lastName: person.control.confirmedInfo.lastName,
                    dateOfBirth: person.control.confirmedInfo.dateOfBirth
                };
                const indicator = this.getIdcMatchIndicator(person1, person2);
                return indicator;
            }
            return '-';
        },

        getIdcDescription(person) {
            const indicator = this.getIdcIndicator(person);
            if (indicator != '-') return this.getIdcMatchDescription(indicator);
            return '-';
        },

        getIdcMatchDescription(indicator) {
            switch (indicator) {
                case 7: return 'weak match on name only';
                case 6: return 'partial match on name only';
                case 5: return 'full match on name only';
                case 4: return 'match on date of birth only';
                case 3: return 'weak match on name and date of birth';
                case 2: return 'partial match on name and date of birth';
                case 1: return 'full match on name and date of birth';
            }
            return 'no match';
        },

        getIdcMatchIndicator(person1, person2) {

            //console.log('PERSON', person);

            const name1 = person1.firstName.toLowerCase() + ' ' + person1.lastName.toLowerCase();
            const name2 = person2.firstName.toLowerCase() + ' ' + person2.lastName.toLowerCase();
            const names1 = name1.split(' ');
            const names2 = name2.split(' ');
            let matchCount = 0;
            for (const i in names1) {
                for (const j in names2) {
                    //console.log('comparing ' + names1[i] + ' to ' + names2[j]);
                    if (names1[i] == names2[j]) {
                        matchCount += 1;
                    }
                }
            }
            //console.log('match on ' + matchCount + ' of ' + Math.max(names1.length, names2.length) + ' names');

            const matchDob = person1.dateOfBirth == person2.dateOfBirth;
            if (matchDob) {
                //console.log('match on DOB');

                if (matchCount >= Math.max(names1.length, names2.length)) {
                    return 1;
                }else if (matchCount > 1) {
                    return 2;
                }else if (matchCount == 1) {
                    return 3;
                }else {
                    return 4;
                }

            }else {
                //console.log('no match on DOB');

                if (matchCount >= Math.max(names1.length, names2.length)) {
                    return 5;
                }else if (matchCount > 1) {
                    return 6;
                }else if (matchCount == 1) {
                    return 7;
                }

            }

            return 0;
        },

        downloadFile() {
            let str = '';
            const comma = ';';
            for (const i in this.loadedColumns) {
                str += this.loadedColumns[i].name + comma;
            }
            str += '\n';
            for (const i in this.loadedRows) {
                for (const j in this.loadedColumns) {
                    str += this.getValue(this.loadedRows[i], this.loadedColumns[j]) + comma;
                }
                str += '\n';
            }
            downloadjs("data:text/csv;charset=utf-8,\uFEFF" + str, this.filename + ".csv", "text/csv");
        },

        getValue(row, column) {
            if (row[column.key]) {
                return row[column.key];
            }else {
                return '';
            }
        },

        getDateFromReminder(value) {
            if (!value) return 'Aldri';
            if (value == -1) return 'Deaktivert';
            const daysSince = Math.abs(Number(value) - Number(this.expireOffset));
            let expirationDate = new Date();
            expirationDate.setDate(new Date().getDate() - Number(daysSince));
            return tools.dateStringShort(expirationDate);
        },

        readyForReport(client) {

            // Check ID
            let hasID = false;
            let hasCeo = false, hasChair = false, hasRrh = false;
            let hasCeoID = false, hasChairID = false, hasRrhID = false;
            for (const i in client.persons) {
                if (personhelper.hasRole(client.persons[i], 'ceo')) {
                    hasCeo = true;
                    if (client.persons[i].control.completed) hasCeoID = true;
                }
                if (personhelper.hasRole(client.persons[i], 'chair')) {
                    hasChair = true;
                    if (client.persons[i].control.completed) hasChairID = true;
                }
                if (personhelper.hasRole(client.persons[i], 'screening_role_rrh')) {
                    hasRrh = true;
                    if (client.persons[i].control.completed) hasRrhID = true;
                }
            }
            if ((hasCeo && hasCeoID) || (hasChair && hasChairID) || (hasRrh && hasRrhID)) {
                hasID = true;
            }else {
                console.log('missing id');
            }

            // Check keywords
            let allCompleted = true;
            for (const i in client.reportData) {

                if (client.reportData[i].type == 'aml'
                && !client.reportData[i].searchComplete
                && !client.reportData[i].ok
                && (client.reportData[i].comment == '<p></p>' || client.reportData[i].comment == '')) {
                    allCompleted = false;
                    console.log('missing company search');
                }

                if (client.reportData[i].type == 'per'
                && !client.reportData[i].searchComplete
                && !client.reportData[i].ok
                && (client.reportData[i].comment == '<p></p>' || client.reportData[i].comment == '')) {
                    
                    for (const j in client.persons) {
                        if (client.persons[j].id == i) {
                            console.log('check role', client.persons[j]);
                            if (personhelper.hasRole(client.persons[j], 'ceo') || personhelper.hasRole(client.persons[j], 'chair') || personhelper.hasRole(client.persons[j], 'screening_role_rrh')) {
                                allCompleted = false;
                                console.log('missing person search');
                            }
                        }
                    }
                }
            }

            return {
                hasKyc: client.kycMetadata.signed,
                hasID: hasID,
                hasSearch: allCompleted
            };
        },

        getStatusText(status) {
            switch (status) {
                case 'r-rep-a': return 'Avventer kontroller';
                case 'r-rep-h': return 'Avventer ID-kontroll';
                case 'r-rep-k': return 'Avventer ID-kontroll og søk';
                case 'r-rep-l': return 'Avventer ID-kontroll';
                case 'r-rep-m': return 'Avventer søk';
                case 'r-rep-o': return 'Avventer sluttrapport';
                case 'r-rep-x': return 'Sluttrapport fullført';
                case 'r-rep-y': return 'Sluttrapport fullført automatisk';
                case 'c-aml': return 'Avventer selskapssøk';
                case 'c-aml-c': return 'Selskapssøk fullført';
                case 'f-kyc-a': return 'Avventer utsendelse egenerklæring';
                case 'f-kyc-b': return 'Avventer utfylling egenerklæring';
                case 'f-kyc-c': return 'Avventer signering egenerklæring';
                case 'f-kyc-o': return 'Avventer ny mottaker';
                case 'f-kyc-q': return 'Egenerklæringsskjema avbrutt';
                case 'b-per': return 'Avventer ID-kontroll';
            }
            return status;
        },

        getDisplayRoles(person) {
            return personhelper.getTextRoles(person, this.$root);
        },

    },
    async created() {
        const thisDate = new Date();
        const initialDate = new Date(2020, 9, 1);
        this.expireOffset = Math.ceil((thisDate - initialDate) / 8.64e7);
    }
}
</script>
<style scoped>


</style>