<template>
<div class="fbinp-pli" @click="handleClick" :class="[
    {'fbinp-pli-logic': logicActive },
    {'fbinp-pli-disabled': disabled },
]">

    <div class="fbinp-pli-icon">
        <vap-icon small :icon="icon" :color="'ext'" />
    </div>

    <div class="fbinp-pli-info">
        <div class="fbinp-pli-text" :class="{'fbinp-pli-text-only': !hint }">
            {{ text }}
        </div>
        <div class="fbinp-pli-hint" v-if="hint">
            {{ hint }}
        </div>
    </div>

    <div class="fbinp-pli-actions">
        <slot name="actions" />
    </div>

</div>
</template>
<script>

export default {
    name: 'PropertyListItem',
    props: {
        text: { type: String, default: '' },
        hint: { type: String, default: '' },
        icon: { type: String, default: 'bug' },
        disabled: { type: Boolean, default: false },
        logicActive: { type: Boolean, default: false },
    },
    methods: {

        handleClick(key) {
            if (this.disabled) return;
            this.$emit('clicked');
        },

    },
}
</script>
<style scoped>

.fbinp-pli {
    display: flex;
    padding: 4px 13px;
    color: var(--fb-text-1);
    fill: var(--fb-text-2);
    border-bottom: solid 1px var(--fb-menu-c);
    cursor: pointer;
}
.fbinp-pli:hover {
    background-color: var(--fb-menu-a);
}

.fbinp-pli-icon {
    margin-top: 3px;
}

.fbinp-pli-info {
    flex-grow: 100;
    padding: 5px 10px;
}

.fbinp-pli-text {
    opacity: 0.9;
}

.fbinp-pli-text-only {
    margin-top: 3px;
    margin-bottom: 3px;
}

.fbinp-pli-hint {
    margin-top: 2px;
    opacity: 0.6;
}


.fbinp-pli-actions {
    margin-top: -8px;
    margin-right: -12px;
    display: flex;
}

.fbinp-pli-disabled {
    color: var(--fb-text-4);
    fill: var(--fb-text-5);
    cursor: not-allowed;
}
.fbinp-pli-disabled:hover {
    background-color: var(--fb-menu-b);
}


.fbinp-pli-logic {
    padding: 8px 13px;
    fill: #238CCC;
    color: #238CCC;
    background-color: var(--fb-menu-b);
}
.fbinp-pli-logic:hover {
    fill: #13AA52;
    color: #13AA52;
    background-color: var(--fb-menu-b);
}

</style>