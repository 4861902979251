var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"riskw-base"},[_c('div',{staticClass:"riskw-top"},[_c('div',{staticClass:"riskw-desc"},[_vm._v(" "+_vm._s('Weight:')+" ")]),_c('div',{staticClass:"riskw-weight"},[_vm._v(" "+_vm._s(this.weight)+" ")]),_c('div',{staticClass:"riskw-desc"},[_vm._v(" "+_vm._s('Low risk multiplier:')+" ")]),_c('div',{staticClass:"riskw-weight"},[(_vm.multiplierL == 0)?_c('span',[_vm._v(_vm._s('OFF'))]):_vm._e(),(_vm.multiplierL == 100)?_c('span',[_vm._v(_vm._s('MAX'))]):_vm._e(),(_vm.multiplierL > 0 && _vm.multiplierL < 100)?_c('span',[_vm._v(_vm._s(_vm.multiplierL))]):_vm._e()]),_c('div',{staticClass:"riskw-desc"},[_vm._v(" "+_vm._s('High risk multiplier:')+" ")]),_c('div',{staticClass:"riskw-weight"},[(_vm.multiplierH == 0)?_c('span',[_vm._v(_vm._s('OFF'))]):_vm._e(),(_vm.multiplierH == 100)?_c('span',[_vm._v(_vm._s('MAX'))]):_vm._e(),(_vm.multiplierH > 0 && _vm.multiplierH < 100)?_c('span',[_vm._v(_vm._s(_vm.multiplierH))]):_vm._e()]),_c('div',{staticClass:"riskw-btn nohl",staticStyle:{"margin-right":"5px"},on:{"click":function($event){return _vm.adjustWeight(false)}}},[_vm._v(" "+_vm._s('-')+" ")]),_c('div',{staticClass:"riskw-btn nohl",on:{"click":function($event){return _vm.adjustWeight(true)}}},[_vm._v(" "+_vm._s('+')+" ")])]),_c('div',{staticClass:"riskw-bot"},_vm._l((_vm.params.items),function(item,i){return _c('div',{key:'wi' + i,staticClass:"riskw-itm",class:[
                {'riskw-itm-f': i == 0 },
                {'riskw-itm-l': i == _vm.params.items.length - 1 },
                {'riskw-itm-a': item.key == _vm.riskKey } ],style:('width: ' + item.percentage + '%;')})}),0),_c('div',{staticClass:"riskw-mul"},[_c('div',{staticClass:"riskw-mul-l"},[_c('div',{staticClass:"riskw-btn nohl",on:{"click":function($event){return _vm.adjustMultiL(true)}}},[_vm._v(" "+_vm._s('+')+" ")]),_c('div',{staticClass:"riskw-mul-c"},_vm._l((_vm.multiItems),function(item,i){return _c('div',{key:'mm' + i,staticClass:"riskw-itm",class:[
                        {'riskw-itm-f': i == 0 },
                        {'riskw-itm-l': i == _vm.multiItems.length - 1 },
                        {'riskw-itm-am': (80 - (i * 20)) < _vm.multiplierL } ],style:('width: ' + item + '%;')})}),0),_c('div',{staticClass:"riskw-btn nohl",on:{"click":function($event){return _vm.adjustMultiL(false)}}},[_vm._v(" "+_vm._s('-')+" ")])]),_c('div',{staticClass:"riskw-mul-r"},[_c('div',{staticClass:"riskw-btn nohl",on:{"click":function($event){return _vm.adjustMultiH(false)}}},[_vm._v(" "+_vm._s('-')+" ")]),_c('div',{staticClass:"riskw-mul-c"},_vm._l((_vm.multiItems),function(item,i){return _c('div',{key:'mp' + i,staticClass:"riskw-itm",class:[
                        {'riskw-itm-f': i == 0 },
                        {'riskw-itm-l': i == _vm.multiItems.length - 1 },
                        {'riskw-itm-ap': (i * 20) < _vm.multiplierH } ],style:('width: ' + item + '%;')})}),0),_c('div',{staticClass:"riskw-btn nohl",on:{"click":function($event){return _vm.adjustMultiH(true)}}},[_vm._v(" "+_vm._s('+')+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }