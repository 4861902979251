const textHelper = {

    getEmptyTextObject(reference) {
        const text = {};
        for (const i in reference.locales) {
            text[reference.locales[i].key] = '[EMPTY TEXT]';
        }
        return text;
    },

    getTranslation(reference, key) {
        return this.getTranslationFromRoot(reference.$root, reference, key)
    },

    getTranslationFromRoot(root, reference, key) {
        const text = {};
        for (const i in reference.locales) {
            if (root.v1translations[key] && root.v1translations[key][reference.locales[i].key]) {
                text[reference.locales[i].key] = root.v1translations[key][reference.locales[i].key];
            }else {
                text[reference.locales[i].key] = '[EMPTY TEXT]';
            }
        }
        return text;
    },

    addRemoveFormLocale(reference, add, localeKey) {

        this.onAddRemoveAction(reference, reference.name, null, add, localeKey);
        this.onAddRemoveAction(reference, reference.title, null, add, localeKey);

        for (const i in reference.roleOptions) {

            const role = reference.roleOptions[i];

            if (role.email) {

                // Set email values
                this.onAddRemoveAction(reference, role.email.button, null, add, localeKey);
                this.onAddRemoveAction(reference, role.email.footer, null, add, localeKey);
                this.onAddRemoveAction(reference, role.email.header, null, add, localeKey);
                this.onAddRemoveAction(reference, role.email.hint, null, add, localeKey);
                this.onAddRemoveAction(reference, role.email.message, null, add, localeKey);
                this.onAddRemoveAction(reference, role.email.subject, null, add, localeKey);

            }

        }

        if (reference.landingParams) {

            if (reference.landingParams.aborted) {
                this.onAddRemoveAction(reference, reference.landingParams.aborted.title, null, add, localeKey);
                this.onAddRemoveAction(reference, reference.landingParams.aborted.message, null, add, localeKey);
            }
            if (reference.landingParams.filled) {
                this.onAddRemoveAction(reference, reference.landingParams.filled.title, null, add, localeKey);
                this.onAddRemoveAction(reference, reference.landingParams.filled.message, null, add, localeKey);
            }
            if (reference.landingParams.signed) {
                this.onAddRemoveAction(reference, reference.landingParams.signed.title, null, add, localeKey);
                this.onAddRemoveAction(reference, reference.landingParams.signed.message, null, add, localeKey);
            }

        }


        for (const i in reference.pages) {

            const page = reference.pages[i];

            // Set title
            this.onAddRemoveAction(reference, page.title, null, add, localeKey);

            for (const rKey in page.info) {

                for (const j in page.info[rKey]) {

                    const roleInfo = page.info[rKey][j];

                    // Set header
                    this.onAddRemoveAction(reference, roleInfo.header, null, add, localeKey);

                    // Set text
                    this.onAddRemoveAction(reference, roleInfo.text, null, add, localeKey);

                }

            } 

            for (const j in page.cards) {

                const card = page.cards[j];

                // Set header
                this.onAddRemoveAction(reference, card.header, null, add, localeKey);

                if (card.type == 'list') {
                    // Set addtext
                    this.onAddRemoveAction(reference, card.addtext, null, add, localeKey);
                }

                for (const k in card.inputs) {

                    const input = card.inputs[k];

                    if (input.type == 'upload') {
                        // Set browse
                        this.onAddRemoveAction(reference, input.browse, null, add, localeKey);
                        // Set hint
                        this.onAddRemoveAction(reference, input.hint, null, add, localeKey);
                    }

                    // Set desc
                    this.onAddRemoveAction(reference, input.desc, null, add, localeKey);

                    if (input.type == 'check') {

                        for (const x in input.options) {

                            const option = input.options[x];

                            // Set locale
                            this.onAddRemoveAction(reference, option.locale, null, add, localeKey);

                        }

                    }else if (input.type == 'check2') {

                        for (const x in input.items) {

                            const item = input.items[x];

                            // Set text
                            this.onAddRemoveAction(reference, item.text, null, add, localeKey);

                        }

                    }else if (input.type == 'drop') {

                        for (const x in input.items) {

                            const item = input.items[x];

                            // Set _base
                            this.onAddRemoveAction(reference, item, null, add, localeKey);

                        }

                    }

                }

            }

        }

    },

    onAddRemoveAction(reference, textObject, transKey, add, localeKey) {
        if (!textObject) return;
        if (add) {
            if (transKey) {
                textObject[localeKey] = this.getTranslation(reference, transKey)[localeKey];
            }else {
                textObject[localeKey] = this.getEmptyTextObject(reference)[localeKey];
            }
        }else {
            delete textObject[localeKey];
        }
    },

    getAllTexts(reference) {
        const locales = [];

        for (const i in reference.pages) {

            const page = reference.pages[i];
            //console.log('page', page);

            // Page
            if (page.title) {
                const locale = {
                    pageKey: page.key,
                    key: page.key,
                    itemKey: 'NA',
                    role: 'NA',
                    type: 'page',
                    subtype: 'title',
                    index: 0,
                };
                for (const loc in page.title) {
                    if (this.isLocale(reference, loc)) locale[loc] = page.title[loc];
                }
                locales.push(locale);
            }

            // Help Texts
            for (const key in page.info) {
                for (const j in page.info[key]) {
                    if (page.info[key][j].header) {
                        const locale = {
                            pageKey: page.key,
                            key: page.info[key][j].key,
                            itemKey: 'NA',
                            role: key,
                            type: 'helptext',
                            subtype: 'header',
                            index: j,
                        };
                        for (const loc in page.info[key][j].header) {
                            if (this.isLocale(reference, loc)) locale[loc] = page.info[key][j].header[loc];
                        }
                        locales.push(locale);
                    }
                    if (page.info[key][j].text) {
                        const locale = {
                            pageKey: page.key,
                            key: page.info[key][j].key,
                            itemKey: 'NA',
                            role: key,
                            type: 'helptext',
                            subtype: 'text',
                            index: j,
                        };
                        for (const loc in page.info[key][j].header) {
                            if (this.isLocale(reference, loc)) locale[loc] = page.info[key][j].header[loc];
                        }
                        locales.push(locale);
                    }
                }
            }

            // Cards
            for (const j in page.cards) {
                const card = page.cards[j];

                if (card.header) {
                    const locale = {
                        pageKey: page.key,
                        key: card.key,
                        itemKey: 'NA',
                        role: 'NA',
                        type: 'card',
                        subtype: 'header',
                        index: 0,
                    };
                    for (const loc in card.header) {
                        if (this.isLocale(reference, loc)) locale[loc] = card.header[loc];
                    }
                    locales.push(locale);
                }

                // Inputs
                for (const k in card.inputs) {
                    const input = card.inputs[k];
                    //console.log('input', input);

                    if (input.desc) {
                        const locale = {
                            pageKey: page.key,
                            key: input.key,
                            itemKey: 'NA',
                            role: 'NA',
                            type: 'input',
                            subtype: 'desc',
                            index: 0,
                        };
                        for (const loc in input.desc) {
                            if (this.isLocale(reference, loc)) locale[loc] = input.desc[loc];
                        }
                        locales.push(locale);
                    }

                    // Options
                    if (input.options) {
                        for (const l in input.options) {
                            const locale = {
                                pageKey: page.key,
                                key: input.key,
                                itemKey: l,
                                role: 'NA',
                                type: 'checkbox',
                                subtype: 'option',
                                index: 0,
                            };
                            for (const loc in input.options[l]) {
                                if (this.isLocale(reference, loc)) locale[loc] = input.options[l][loc];
                            }
                            locales.push(locale);
                        }
                    }

                }

            }

        }

        //console.log('locales', locales);
        console.log(JSON.stringify(locales));

        return locales;
    },

    isLocale(reference, key) {
        for (const i in reference.locales) {
            if (reference.locales[i].key == key) return true;
        }
        return false;
    },

};
export default textHelper;