<template>
    <div class="veswitch" :class="disabled ? 'veswitch-disabled' : ''">
        <div class="veswitch-base" @click="handleClick" @click.stop :class="value ? 'veswitch-base-checked' : ''">
            <div class="veswitch-ball" :class="value ? 'veswitch-ball-checked' : ''" />
        </div>
        <label @click="handleClick">{{ text }}</label>
    </div>
</template>
<script>
export default {
    name: 'vap-switch',
    props: {
        value: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: '(switch)'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleClick() {
            if (this.disabled) return;
            this.$emit('input', !this.value);
            this.$emit('checked', !this.value);
        }
    }
}
</script>
<style scoped>

.veswitch {
    float: left;
}
.veswitch label {
    float: left;
    font-size: 16px;
    font-weight: bold;
    color: var(--text-title);
    margin-top: 5px;
    margin-left: 14px;
    cursor: pointer;
}
.veswitch-disabled label {
    cursor: not-allowed;
}

.veswitch-base {
    float: left;
    width: 50px;
    height: 30px;
    padding-top: 3px;
    padding-left: 3px;
    border-radius: 15px;
    background-color: var(--disabled-background);
    cursor: pointer;
    box-sizing: border-box;
}
.veswitch-base-checked {
    background-color: #3190D5;
    background-color: var(--input-accent);
}
.veswitch-disabled .veswitch-base {
    cursor: not-allowed;
    opacity: 0.5;
}

.veswitch-ball {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: var(--disabled-text);
    transition: all 0.5s;
}
.veswitch-ball-checked {
    margin-left: 20px;
    background-color: white;
}

</style>