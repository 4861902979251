<template>
<div style="display: flex;" :class="[{'vea-warning' : warning}, {'vea-error' : error}]">
    <div class="vea-base card-shadow">
        <div class="vea-icon">
            <vap-icon :icon="'info'" :color="'var(--alert-info-text)'" />
        </div>
        <div class="vea-content">
            <slot></slot>
        </div>
        <div class="vea-r">
            <slot name="right"></slot>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: 've-alert',
    props: {
        text: {
            type: String,
            default: ''
        },
        warning: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            
        }
    }
}
</script>
<style scoped>

.vea-base {
    position: relative;
    float: left;
    width: 100%;
    padding: 20px 20px;
    color: var(--alert-info-text);
    border-radius: 3px;
    background-color: var(--alert-info);
}
.vea-base path {
    fill: white;
    fill: var(--alert-info-text);
}

.vea-warning .vea-base {
    background-color: rgb(250, 221, 56);
    color: rgb(58, 58, 41);
}

.vea-error .vea-base {
    background-color: rgb(212, 81, 92);
    color: rgb(58, 58, 41);
}

.vea-icon {
    position: absolute;
}
.vea-icon path {
    fill: white;
    fill: var(--alert-info-text);
}
.vea-warning path {
    fill: rgb(58, 58, 41);
}

.vea-content {
    margin-left: 50px;
    font-size: 15px;
    line-height: 22px;
}

.vea-content b {
    font-weight: bold;
}
.vea-r {
    position: absolute;
    top: 0px;
    right: 0px;
}

</style>