var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.dialog.show)?_c('PopupV1',{attrs:{"header":_vm.__t('ams-name'),"width":800,"preventClosing":!_vm.disabled,"dialog":_vm.dialog},on:{"close":_vm.onClose},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"amd-s"},[_c('div',{staticClass:"amd-s-name"},[_vm._v(" "+_vm._s(_vm.params.name)+" ")]),(!_vm.disabled)?_c('div',{staticClass:"amd-s-button",on:{"click":_vm.doSearch}},[_c('vap-icon',{attrs:{"icon":'search'}})],1):_vm._e()]),_c('div',{staticClass:"amd-split"},[_c('div',{staticClass:"amd-left"},_vm._l((_vm.listLeft),function(keyword,i){return _c('div',{key:'amk' + i,staticClass:"amd-item"},[_c('div',{staticClass:"amd-item-key",class:[
                            {'amd-item-key-done': keyword.done },
                            {'amd-item-key-disabled': keyword.done } ]},[_vm._v(" "+_vm._s(keyword.display)+" ")]),_c('div',{staticClass:"amd-item-hits nohl",class:[
                            {'aml-item-hit-s': keyword.result == 'ok' },
                            {'aml-item-hit-w': keyword.result == 'some' },
                            {'aml-item-hit-e': keyword.result == 'hits' },
                            {'aml-item-hit-disabled': _vm.disabled } ],on:{"click":function($event){return _vm.onHitClick(keyword)}}},[_vm._v(" "+_vm._s(_vm.getHitText(keyword))+" ")]),(!_vm.disabled)?_c('div',{staticClass:"amd-item-icon",class:[
                            {'amd-item-icon-done': keyword.done }
                        ],on:{"click":function($event){return _vm.onSearchClicked(keyword)}}},[_c('vap-icon',{attrs:{"small":"","icon":'search',"absolute":"","color":'ext'}})],1):_vm._e()])}),0),_c('div',{staticClass:"amd-right"},_vm._l((_vm.listRight),function(keyword,i){return _c('div',{key:'amk' + i,staticClass:"amd-item"},[_c('div',{staticClass:"amd-item-key",class:[
                            {'amd-item-key-done': keyword.done },
                            {'amd-item-key-disabled': keyword.done } ]},[_vm._v(" "+_vm._s(keyword.display)+" ")]),_c('div',{staticClass:"amd-item-hits nohl",class:[
                            {'aml-item-hit-s': keyword.result == 'ok' },
                            {'aml-item-hit-w': keyword.result == 'some' },
                            {'aml-item-hit-e': keyword.result == 'hits' },
                            {'aml-item-hit-disabled': _vm.disabled } ],on:{"click":function($event){return _vm.onHitClick(keyword)}}},[_vm._v(" "+_vm._s(_vm.getHitText(keyword))+" ")]),(!_vm.disabled)?_c('div',{staticClass:"amd-item-icon",class:[
                            {'amd-item-icon-done': keyword.done }
                        ],on:{"click":function($event){return _vm.onSearchClicked(keyword)}}},[_c('vap-icon',{attrs:{"small":"","icon":'search',"absolute":"","color":'ext'}})],1):_vm._e()])}),0)]),(!_vm.disabled)?_c('div',{staticClass:"amd-comments-hint"},[_c('label',[_vm._v(_vm._s(_vm.__t('ams-comment-hint')))])]):_vm._e(),_c('div',{staticClass:"amd-comments"},[_c('TextEditorV2',{attrs:{"content":_vm.comment,"placeholder":_vm.__t('common-comments'),"disabled":_vm.disabled},on:{"input":_vm.onCommentInput}})],1),(_vm.disabled)?_c('div',{staticClass:"amd-user"},[_vm._v(" "+_vm._s(_vm.__t('pdf-performed-by') + ' ' + _vm.completedUser + _vm.__t('ams-on') + _vm.completedDate)+" ")]):_vm._e()]},proxy:true},{key:"buttons",fn:function(){return [(_vm.disabled)?_c('VFButton',{attrs:{"text":_vm.__t('common-close'),"outline":"","disabled":_vm.dialog.working},on:{"clicked":_vm.onClose}}):_vm._e(),(!_vm.disabled)?_c('VFButton',{attrs:{"text":_vm.__t('common-cancel'),"outline":"","disabled":_vm.dialog.working},on:{"clicked":_vm.onClose}}):_vm._e(),(!_vm.disabled)?_c('VFButton',{attrs:{"text":_vm.__t('common-save'),"icon":'save',"working":_vm.dialog.working},on:{"clicked":_vm.onSave}}):_vm._e()]},proxy:true}],null,false,1936378526)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }