<template>
<div class="flw100" style="padding: 20px 2px; padding-bottom: 0px;">
    
    <!-- <div class="flw100" style="padding: 10px 10px; padding-bottom: 20px;">
        {{ __('customer_new_no') }}
    </div> -->

    <div class="flw100" style="margin-bottom: 20px;" v-if="$root.config.search.org.no.underCreation">
        <VFCheckbox newDesign v-model="underCreation" :text="'Foretaket er under stiftelse'" @input="onUnderCreationChecked" />
    </div>

    <div v-if="!underCreation && !brregResult.name" class="flw100" style="padding: 0px 4px; padding-bottom: 20px;">
        <vap-label :text="__('customer_brreg_hint')" desc />
        <ve-brreg-search @company-selected="onBrregResult" />
    </div>

    <CompanyInputs v-if="!underCreation && brregResult.name" v-model="brregResult" simple :editMode="false" />

    <vap-row v-if="underCreation" style="margin-top: 0px;">
        <vap-group c8>
            <vap-label :text="__('common_name')" desc required />
            <vap-input v-model="forreignModel.name" @input="validateForreign" :placeholder="__('common_required')" noValidation />
        </vap-group>
        <vap-group c4>
            <vap-label :text="__('company_orgnr')" desc />
            <vap-input v-model="forreignModel.identity" @input="validateForreign" :placeholder="__('common_optional')" noValidation />
        </vap-group>
    </vap-row>

</div>
</template>
<script>
import aml from '../aml';

import CompanyInputs from '../components/kyc/CompanyInputs.vue';

import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'SearchNO2',
    components: {
        CompanyInputs,
        VFCheckbox,
    },
    data() {
        return {
            valid: false,
            searchValid: false,
            country: 'no',
            source: 'bankidaml',
            condition: 'none',
            brregResult: { name: '' },
            underCreation: false,
            forreignModel: {
                name: '',
                identity: '',
            },
        }
    },
    methods: {

        onUnderCreationChecked() {
            this.source = this.underCreation ? 'manual' : 'bankidaml';
            this.country = this.underCreation ? 'xx' : 'no';
            this.condition = this.underCreation ? 'underCreation' : 'none';
            if (this.underCreation) {
                this.validateForreign();
            }else {
                this.resetSearch();
            }
            this.$emit('creation');
        },

        validateForreign() {
            if (this.forreignModel.name) {
                this.valid = true;
            }else {
                this.valid = false;
            }
            this.searchValid = this.valid;
            this.$emit('validate', this.valid);
        },
        
        onBrregResult(company, rawData) {
            this.brregResult = aml.processBrreg(rawData);
            this.valid = true;
            this.searchValid = true;
            this.$emit('validate', this.valid);
        },

        resetSearch() {
            this.valid = false;
            this.searchValid = false;
            this.brregResult = { name: '' };
            this.forreignModel.name = '';
            this.forreignModel.identity = '';
            this.$emit('validate', this.valid);
        },

        getInitialData() {
            if (this.underCreation) {
                return {
                    type: 'org',
                    name: this.forreignModel.name,
                    identity: this.forreignModel.identity,
                    country: 'no',
                    source: this.source
                };
            }else {
                return {
                    type: 'org',
                    brreg: this.brregResult,
                    name: this.brregResult.name,
                    identity: this.brregResult.org_no,
                    country: 'no',
                    source: this.source
                };
            }
        },

    },
}
</script>