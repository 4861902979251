<template>
<div class="amlv1-efiles">

    <div>
        <div class="amlv1-efiles-item" v-for="(file, i) in client.files" :key="'ef' + i">
            <div class="amlv1-efiles-icon">
                <vap-icon :icon="'pdf'" />
            </div>
            <div class="amlv1-efiles-info">
                <div class="amlv1-efiles-name">
                    {{ file.name }}
                </div>
                <div class="amlv1-efiles-comment">
                    {{ file.comment || __t('common-no-comment') }}
                </div>
            </div>
            <CardActions :actions="actions" @action="onAction($event, i)" />
        </div>
    </div>

    <div v-if="!client.files || client.files.length == 0" style="font-size: 15px; color: var(--text-weak); margin-bottom: -24px; margin-top: 14px; margin-left: 20px;">
        {{ __t('files-external-empty') }}
    </div>

    <div class="vap-event-footer">
        <div class="vap-event-exptext">
            <!-- {{ 'Upload files to be included in the final report' }} -->
        </div>
        <div class="vap-event-button" @click="onAddFileClicked">
            {{ __t('files-external-add') }}
        </div>
    </div>
    
</div>
</template>
<script>
import CardActions from '../v1ui/CardActions.vue';

export default {
    name: 'ExternalFileList',
    components: {
        CardActions,
    },
    props: {
        client: { type: Object, default: null },
    },
    data() {
        return {
            actions: [
                { icon: 'pdf', action: 'preview' },
                { icon: 'edit', action: 'edit' },
                { icon: 'delete', action: 'delete' },
            ]
        }
    },
    methods: {

        //#region Actions -----------------------------------------------------------------------------------------------------

        onAction(action, index) {
            console.log('action', action);
            console.log('index', index);
            if (action.action == 'delete') {
                const file = {
                    formKey: this.client.files[index].formKey,
                    name: this.client.files[index].name,
                    envelopeId: this.client.files[index].envelopeId,
                };
                this.$emit('deletefile', file);
            }else if (action.action == 'edit') {
                this.$emit('file', { mode: 'edit', index: index });
            }else if (action.action == 'preview') {
                this.$emit('preview', { envelopeId: this.client.files[index].envelopeId, name: this.client.files[index].name });
            }
        },

        onAddFileClicked() {
            this.$emit('file', { mode: 'new' });
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Trigger validation ------------------------------------------------------------------------------------------

        

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    async created() {

        
    }
}
</script>
<style scoped>

.amlv1-efiles {
    float: left;
    width: 100%;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
}


.amlv1-efiles-item {
    position: relative;
    display: flex;
    padding: 7px 10px;
    border-bottom: solid 1px var(--input-border);
}

.amlv1-efiles-icon {
    width: 46px;
}

.amlv1-efiles-info {
    
}

.amlv1-efiles-name {
    
}

.amlv1-efiles-comment {
    color: var(--text-weak);
}

.amlv1-efiles-user {
    text-align: right;
}


.vap-event-footer {
    float: left;
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.vap-event-exptext {
    flex-grow: 100;
    padding-top: 4px;
    color: var(--link-text);
    font-weight: 500;
}
.vap-event-exptext label {
    cursor: pointer;
}
.vap-event-exptext label:hover {
    color: var(--link-active);
}

.vap-event-button {
    height: 20px;
    margin-left: 5px;
    margin-right: 10px;
    padding: 0px 14px;
    padding-top: 4px;
    padding-bottom: 2px;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-title);
    border-radius: 3px;
    border: 1px solid var(--input-border);
    background-color: var(--page-background);
    cursor: pointer;
}
.vap-event-button:hover {
    color: var(--button-text);
    background-color: var(--button);
}

</style>