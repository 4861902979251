<template>
<div>
    <PageHeader :offset="97" @scrolled="scrolled = $event" :actions="actions" @action="onAction">
        <template v-slot:content>
            <vap-row v-if="!loading">
                <vap-group auto noPadding>
                    <vap-label :text="__t('report-report')" desc/>
                    <vap-label :text="__t('report-customer')" value />
                </vap-group>
            </vap-row>
        </template>
        <template v-slot:compact>
            <div v-if="!loading">
                <span>{{ __t('report-report') }}</span>
                <span>{{ __t('report-customer') }}</span>
            </div>
        </template>
    </PageHeader>
    <PageContent :scrolled="scrolled" :offset="104">
        <div class="aml-page">

            <SplitterV1 :left="22" :middle="58" :right="20">
                <template v-slot:left>

                    <vap-label :text="__('filters')" header2 style="margin-top: 10px; margin-bottom: 10px;" />

                    <SearchBox ref="fltSear" @search="filtersOnSearch" :placeholder="__('clients_search')" style="margin-top: 2px; margin-bottom: 16px;" />

                    <RemoveFilterButton v-if="filtersHasActive()" @remove="filtersOnRemoveAll" />

                    <div>
                        <FilterControl empty :header="__('sort_last_updated')" />
                        <div style="float: left; width: 50%; margin-top: 10px;">
                            <SortDatePicker v-model="filterObject.sortDateFrom" noHighlight noValidation :hintKey="'datepicker_from'" :placeholder="__('datepicker_select_date')" outputISO @input="filtersOnDateChanged" />
                        </div>
                        <div style="float: left; width: 50%; margin-top: 10px;">
                            <SortDatePicker v-model="filterObject.sortDateTo" noHighlight noValidation :hintKey="'datepicker_to'" :placeholder="__('datepicker_select_date')" right outputISO @input="filtersOnDateChanged" />
                        </div>
                    </div>

                    <div v-if="filterObject.filters">
                        <div v-for="(filter, i) in filterObject.possibleFilters" :key="'cpf' + i">
                            <FilterControlV1
                                :params="filter"
                                :filters="filterObject.filters[filter.processedKey]"
                                :texts="filterObject.texts[filter.textKey]"
                                :activeValues="filterObject.activeFilters[filter.key]"
                                @filter="filtersOnChanged"
                            />
                        </div>
                    </div>

                </template>
                <template v-slot:middle>

                    <div style="display: flex;">
                        <vap-label :text="__t('report-report-preview')" header2 style="flex-grow: 100; margin-top: 10px; margin-bottom: 10px;" />
                        <SortSelector v-model="filterObject.sortModel" :options="filterObject.sortOptions" style="flex-shrink: 0; margin-top: 18px;" />
                    </div>

                    <vap-card noPadding v-if="!loading">
                        <DataTableV1 :columns="loadedColumns" :rows="loadedRows" ref="datatable" />
                    </vap-card>

                    <CenteredSpinner v-if="loading" />
                    <EmptyIcon v-if="!loading && clients.length == 0" :icon="'input-check'" :text="__t('events-no-events')" />

                    <PageControl v-if="!loading"
                        :total="filterObject.totalPages"
                        :current="filterObject.currentPage"
                        @page="filtersOnNavigate"
                        style="margin-top: 20px;"
                    />

                    <div v-if="!loading" class="flw100" style="margin-top: 40px; text-align: center;">
                        <div>
                            <span style="color: var(--text-weak);">{{ 'Total customers: ' }}</span>
                            <span style="font-weight: 500;">{{ filterObject.totalCount }}</span>
                        </div>
                        <div>
                            <span style="color: var(--text-weak);">{{ 'Estimated export time: ' }}</span>
                            <span style="font-weight: 500;">{{ 'less than 1 minute' }}</span>
                        </div>
                        <div style="margin-top: 10px;">
                            <VFButton :icon="'download'" :text="'Export selection'" @clicked="showExportDialog" />
                        </div>
                    </div>

                </template>
                <template v-slot:right>

                    <vap-label :text="'Fields'" header2 style="margin-top: 10px; margin-bottom: 10px;" />

                    <ExportFieldList :fields="fields" @add="showAddDialog" @delete="onDeleteField" @changed="onFieldsChanged" />

                </template>
            </SplitterV1>
        </div>
    </PageContent>
    <PageFooter :scrolled="scrolled" />

    <!-- Export dialog -->
    <PopupV1 v-if="exportDialog.show" :header="__t('report-export-CSV-2')" @close="exportDialog.show = false" :dialog="exportDialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ 'The following files will be exported' + ':' }}
            </div>
            <DialogContent>
                <DialogContentHeader :text="__t('files-document').toUpperCase() + ':'" />
                <DialogContentItem :text="exportDialog.filename + '.csv'" :icon="'pdf'" />
            </DialogContent>
            <DialogContent style="margin-top: 10px;">
                <div style="padding-top: 10px; margin-bottom: -10px;">
                    <VFGroup c6>
                        <VFLabel desc :text="'Skip'" />
                        <VFInput v-model="exportDialog.skip" @input="onSkipLimitChanged" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Limit'" />
                        <VFInput v-model="exportDialog.limit" @input="onSkipLimitChanged" />
                    </VFGroup>
                </div>
            </DialogContent>
            <DialogContent style="margin-top: 10px;">
                <DialogContentHeader :text="'Total customers'.toUpperCase() + ':'" />
                <DialogContentItem :text="exportDialog.totalCount + ' customers'" :icon="'company'" />
                <DialogContentHeader :text="'Estimated time'.toUpperCase() + ':'" />
                <DialogContentItem :text="'less than 1 minute'" :icon="'refresh'" />
            </DialogContent>
            <DialogContent style="margin-top: 10px;">
                <DialogContentHeader :text="'Progress'.toUpperCase() + ':'" />
                <DialogContentItem :text="exportDialog.progress + ' of ' + exportDialog.totalCount + ' customers'" :icon="'refresh'" />
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="exportDialog.show = false" :disabled="exportDialog.working" />
            <VFButton :text="__t('report-export-CSV-button')" :icon="'download'" @clicked="onStartExport" :working="exportDialog.working" />
        </template>
    </PopupV1>

    <!-- Load dialog -->
    <PopupV1 v-if="loadDialog.show" :header="'Load preset'" @close="loadDialog.show = false" :dialog="loadDialog">
        <template v-slot:content>
            <div class="amlv1-report-template" v-for="(preset, i) in presetList" :key="'rp' + i" @click="onLoadTemplate(i)">
                <div>
                    {{ preset.name }}
                </div>
                <div>
                    {{ preset.userId + ' - ' + preset.dateUpdated }}
                </div>
            </div>
            <div class="flw100" v-if="presetList.length == 0">
                {{ 'No presets available' }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="loadDialog.show = false" :disabled="loadDialog.working" />
        </template>
    </PopupV1>

    <!-- Save dialog -->
    <PopupV1 v-if="saveDialog.show" :header="'Save preset'" @close="saveDialog.show = false" :dialog="saveDialog">
        <template v-slot:content>
            <div>
                <VFCheckbox newDesign v-model="saveDialog.isNew" :text="'Save as new preset'" :disabled="!loadedPreset" />
            </div>
            <DialogContent v-if="loadedPreset && !saveDialog.isNew">
                <DialogContentHeader :text="'PRESET'.toUpperCase() + ':'" />
                <DialogContentItem :text="loadedPreset.name" :icon="'pdf'" />
            </DialogContent>
            <div v-if="saveDialog.isNew" class="flw100" style="margin-top: 10px;">
                <VFLabel desc :text="'Preset name'" />
                <VFInput v-model="saveDialog.name" />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="saveDialog.show = false" :disabled="saveDialog.working" />
            <VFButton :text="'Save preset'" :icon="'save'" @clicked="onSaveTemplate()" :working="saveDialog.working" />
        </template>
    </PopupV1>

    <!-- Add value dialog -->
    <PopupV1 v-if="addDialog.show" :header="'Add field'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <div class="amlv1-report-item" v-for="(item, i) in addDialog.list" :key="'ra' + i" @click="onAddField(item)">
                <div class="amlv1-report-item-key">
                    {{ item.name }}
                </div>
                <div class="amlv1-report-item-hint">
                    {{ item.hint }}
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="addDialog.show = false" :disabled="addDialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import tools from '../tools';
import v1api from '../v1helpers/v1api';
import v1filterHelper from '../v1helpers/v1filterHelper';
import v2exportHelper from '../v1export/v2exportHelper';
import downloadjs from 'downloadjs';

import PageHeader from '../v1ui/PageHeader.vue';
import PageContent from '../v1ui/PageContent.vue';
import PageFooter from '../v1ui/PageFooter.vue';
import SplitterV1 from '../v1ui/SplitterV1.vue';

import DataTableV1 from '../v1ui/DataTableV1.vue';
import ExportFieldList from '../v1export/ExportFieldList.vue';

import EmptyIcon from '../v1ui/EmptyIcon.vue';
import PageControl from '../components/special/PageControl';
import CenteredSpinner from '../v1ui/CenteredSpinner.vue';
import FilterControlV1 from '../v1ui/FilterControlV1.vue';
import FilterControl from '../components/special/FilterControl';
import RemoveFilterButton from '../v1ui/RemoveFilterButton.vue';
import SortDatePicker from '../components/special/SortDatePicker';
import SortSelector from '../components/special/SortSelector';
import SearchBox from '../components/special/SearchBox';

import PopupV1 from '../v1ui/PopupV1.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import VFInput from '../components/form/inputs/VFInput.vue';
import VFLabel from '../components/form/items/VFLabel.vue';

export default {
    name: 'ReportsV2',
    components: {
        PageHeader,
        PageContent,
        PageFooter,
        SplitterV1,
        DataTableV1,
        ExportFieldList,
        EmptyIcon,
        PageControl,
        CenteredSpinner,
        FilterControlV1,
        FilterControl,
        RemoveFilterButton,
        SortDatePicker,
        SortSelector,
        SearchBox,
        PopupV1,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFButton,
        VFGroup,
        VFCheckbox,
        VFInput,
        VFLabel,
    },
    data() {
        return {
            loading: true,
            scrolled: false,

            fields: [],
            clients: [],
            persons: [],
            counts: [],
            triggers: [],

            loadedColumns: [],
            loadedRows: [],

            exportDialog: { show: false, working: false, status: '', skip: 0, limit: 0, totalCount: 0, filename: 'Exported data', progress: 0 },
            actions: [
                { action: 'load', icon: 'download' },
                { action: 'save', icon: 'upload' },
            ],
            presetList: [],
            loadedPreset: null,
            loadDialog: { show: false, working: false, status: '' },
            saveDialog: { show: false, working: false, status: '', isNew: false, name: '' },
            addDialog: { show: false, list: [] },
            
            filterObject: {
                itemsPerPage: 20,
            },
        }
    },
    methods: {
        
        //#region Values ------------------------------------------------------------------------------------------------------

        onValuesChanged() {
            this.loadedColumns = v2exportHelper.loadColumns(this.$root, this.model);
            this.loadedRows = v2exportHelper.loadRows(this.$root, this.model, this.clients, this.persons, this.triggers, this.counts, this.filterObject);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Filter Interface --------------------------------------------------------------------------------------------

        filtersOnNavigate(page) { v1filterHelper.filtersOnNavigate(this, this.filterObject, page); },

        filtersOnLetterSelected(letter) { v1filterHelper.filtersOnLetterSelected(this, this.filterObject, letter); },

        filtersOnSearch(str) { v1filterHelper.filtersOnSearch(this, this.filterObject, str); },

        filtersOnDateChanged() { v1filterHelper.filtersOnDateChanged(this, this.filterObject); },

        filtersOnChanged(filter, value) { v1filterHelper.filtersOnChanged(this, this.filterObject, filter, value); },

        filtersHasActive() { return v1filterHelper.filtersHasActive(this.filterObject); },

        filtersOnRemoveAll() { v1filterHelper.filtersOnRemoveAll(this, this.filterObject); },

        filtersOnQuery() {
            this.queryClients();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Fields ------------------------------------------------------------------------------------------------------

        showAddDialog(type) {
            this.addDialog.list = v2exportHelper.getAvailableFields(this.$root, type);
            this.addDialog.show = true;
        },

        onAddField(item) {
            this.fields.push(item);
            this.addDialog.show = false;
            this.loadPreview();
        },

        onDeleteField(index) {
            this.fields.splice(index, 1);
            this.loadPreview();
        },

        onFieldsChanged() {
            this.loadPreview();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Templates ---------------------------------------------------------------------------------------------------

        onAction(action) {
            if (action.action == 'load') {
                this.showLoadDialog();
            }else if (action.action == 'save') {
                this.showSaveDialog();
            }
        },

        async showLoadDialog() {
            this.loadDialog.status = 'Loading presets';
            this.loadDialog.working = true;
            this.loadDialog.show = true;

            const response = await v1api.getReportTemplates();
            this.presetList = response.templates;

            this.loadDialog.working = false;
        },

        async onLoadTemplate(index) {
            this.loadDialog.working = true;

            this.loadedPreset = this.presetList[index];
            this.fields = this.presetList[index].preset;
            console.log('sds', this.presetList[index]);

            this.loadDialog.working = false;
            this.loadDialog.show = false;
            this.loadPreview();
        },

        showSaveDialog() {
            this.saveDialog.name = tools.getUniqueId().toUpperCase();
            this.saveDialog.isNew = (this.loadedPreset == null);
            this.saveDialog.show = true;
        },

        async onSaveTemplate() {
            this.saveDialog.working = true;

            const templateData = this.fields;

            if (this.saveDialog.isNew) {
                await v1api.addReportTemplate(templateData, this.saveDialog.name);
            }else {
                await v1api.updateReportTemplate(this.loadedPreset._id, templateData);
            }

            this.saveDialog.working = false;
            this.saveDialog.show = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Exporting ---------------------------------------------------------------------------------------------------

        showExportDialog() {
            this.exportDialog.show = true
            this.onSkipLimitChanged();
        },

        onSkipLimitChanged() {
            const skip = Number(this.exportDialog.skip);

            let totalRows = this.filterObject.totalCount;

            const maxLimit = Number(this.exportDialog.limit);

            if (maxLimit != 0) {
                totalRows = maxLimit;
                if (totalRows > (Number(this.filterObject.totalCount) - skip)) totalRows = Number(this.filterObject.totalCount) - skip;
            }else if (skip != 0) {
                totalRows = Number(this.filterObject.totalCount) - skip;
            }

            this.exportDialog.totalCount = totalRows;
        },

        async onStartExport() {
            this.exportDialog.working = true;
            this.exportDialog.progress = 0;
            this.exportDialog.status = 'Creating report';

            let totalRows = Number(this.filterObject.totalCount);
            let completedRows = 0;
            const limit = 500;

            const skip = Number(this.exportDialog.skip);
            completedRows = skip;

            const maxLimit = Number(this.exportDialog.limit);
            if (maxLimit != 0) totalRows = skip + maxLimit;

            console.log('maxLimit', maxLimit);
            console.log('totalRows', totalRows);
            console.log('completedRows', completedRows);

            const columns = v2exportHelper.loadColumns(this.$root, this.fields);
            const rows = [];

            while (completedRows < totalRows) {
                await new Promise(r => setTimeout(r, 1000));

                const query = v1filterHelper.getQuery(this.$root, this.filterObject);
                query.from = completedRows;
                query.limit = limit;
                query.sort = {};

                console.log('query.from', query.from);
                console.log('query.limit', query.limit);

                let requiresProcessing = false;

                let clients = [];
                let persons = [];
                let counts = [];
                let triggers = {};
                if (requiresProcessing) {
                    const response = await v1api.queryProcessedClients(query.filters, query.from, query.limit, query.sort, query.include, query.counts);
                    clients = response.clients;
                    persons = response.persons;
                    counts = response.counts;
                    triggers = response.triggers;
                }else {
                    const response = await v1api.queryClients(query.filters, query.from, query.limit, query.sort, query.include, query.counts);
                    clients = response.clients;
                }

                const currentRows = v2exportHelper.loadRows(this.$root, this.fields, clients, persons, triggers, counts, this.filterObject);
                rows.push(...currentRows);

                completedRows += limit;
                this.exportDialog.progress = completedRows;
            }

            console.log('columns', columns);
            console.log('rows', rows);

            this.downloadFile(columns, rows);
            this.exportDialog.working = false;
            this.exportDialog.show = false;
        },

        downloadFile(columns, rows) {
            let str = '';
            const comma = ';';
            for (const i in columns) str += columns[i].name + comma;
            str += '\n';
            for (const i in rows) {
                for (const j in columns) str += this.getValue(rows[i], columns[j]) + comma;
                str += '\n';
            }
            downloadjs("data:text/csv;charset=utf-8,\uFEFF" + str, this.exportDialog.filename + ".csv", "text/csv");
        },

        getValue(row, column) {
            if (row[column.key]) {
                return row[column.key];
            }else {
                return '';
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Database ----------------------------------------------------------------------------------------------------

        async queryClients() {
            this.loading = true;

            const query = v1filterHelper.getQuery(this.$root, this.filterObject);

            let requiresProcessing = false;

            let response = null;
            let clients = [];
            let persons = [];
            let counts = [];
            let triggers = {};
            if (requiresProcessing) {
                response = await v1api.queryProcessedClients(query.filters, query.from, query.limit, query.sort, query.include, query.counts);
                clients = response.clients;
                persons = response.persons;
                counts = response.counts;
                triggers = response.triggers;
            }else {
                response = await v1api.queryClients(query.filters, query.from, query.limit, query.sort, query.include, query.counts);
                clients = response.clients;
            }

            this.clients = clients;
            this.persons = persons;
            this.counts = counts;
            this.triggers = triggers;

            console.log('persons', this.persons);

            v1filterHelper.updateFilters(this.$root, this.filterObject, response);

            this.loadPreview();
            this.loading = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        loadPreview() {
            this.loadedColumns = v2exportHelper.loadColumns(this.$root, this.fields);
            this.loadedRows = v2exportHelper.loadRows(this.$root, this.fields, this.clients, this.persons, this.triggers, this.counts, this.filterObject);
        },

    },
    async created() {
        v1filterHelper.loadClientFilters(this.$root, this.filterObject);
        //v2exportHelper.loadPossibleFields(this.$root, this.fields);

        console.log('fields', this.fields);

        await this.queryClients();

        console.log('this.fil', this.filterObject);

        this.loading = false;
    }
}
</script>
<style scoped>

.amlv1-report-template {
    padding: 5px 10px;
    border-radius: 4px;
    border: solid 1px var(--input-border);
    cursor: pointer;
}

.amlv1-report-template:hover {
    background-color: var(--item-hover);
}

.amlv1-report-item {
    cursor: pointer;
}
.amlv1-report-item:hover {
    background-color: var(--item-hover);
}

.amlv1-report-item-key {
    
}

.amlv1-report-item-hint {
    
}

</style>