<template>
<div class="aph2" v-on-clickaway="hideMenus">
    <div class="aph2-logo">
        <img :src="$root.config.portal.logoUrl" :style="'width:' + $root.config.portal.logoWidth + 'px; top:' + $root.config.portal.logoTop + 'px; left:' + $root.config.portal.logoLeft + 'px;'" />
        <label v-if="!$root.phoneMode" :style="'left:' + ($root.config.portal.logoWidth + 40) + 'px;'">{{ title }}</label>
    </div>

    <div class="aph2-right">
        <div class="aph2-lang" @click="langMenuOpen = !langMenuOpen" v-if="!$root.editorLocales || $root.editorLocales.length == 0">
            <img v-if="$root.locale.key == 'nb_NO'" :src="$root.images.flags.nb_NO" />
            <img v-if="$root.locale.key == 'en_EN'" :src="$root.images.flags.en_EN" />
            <img v-if="$root.locale.key == 'sv_SE'" :src="$root.images.flags.sv_SE" />
            <img v-if="$root.locale.key == 'de_DE'" :src="$root.images.flags.de_DE" />
            <vap-dropdown-menu v-if="langMenuOpen" :top="'20'" :right="'15'">
                <div class="aph2-user-menu">
                    <div class="aph2-lang-menu-item" v-for="(locale, i) in $root.config.locales" :key="i" @click="langClicked(locale.key)" v-show="locale.enabled">
                        <img :src="$root.images.flags[locale.key]" />
                        <label>{{ locale.name }}</label>
                    </div>
                </div>
            </vap-dropdown-menu>
        </div>
        <HeaderLanguageSelector editor :locales="$root.editorLocales" v-if="$root.editorLocales && $root.editorLocales.length > 1" />
    </div>

</div>
</template>

<script>
import locale from '../../locale';
import { mixin as clickaway } from "vue-clickaway";

import HeaderLanguageSelector from '../../v1ui/HeaderLanguageSelector.vue';

export default {
    name: 'EditorHeader',
    mixins: [clickaway],
    components: {
        HeaderLanguageSelector,
    },
    props: {
        editor: {
			type: Boolean,
			default: false
        },
        title: {
			type: String,
			default: ''
		}
    },
    data() {
        return {
            mainMenuOpen: false,
            userMenuOpen: false,
            langMenuOpen: false
        }
    },
    methods: {
        setDarkMode(value) {
            this.$root.darkMode = value;
            this.$parent.loadColors();
        },
        hideMenus() {
            this.userMenuOpen = false;
            this.langMenuOpen = false;
        },
        langClicked(lang) {
            this.$root.locale = locale.getLocale(this.$root.config.locales, lang);
            //this.saveSettings();
        },
        saveSettings() {
            const settings = {
                locale: this.$root.locale.key,
                darkMode: this.$root.darkMode
            }
            localStorage.setItem('settings', JSON.stringify(settings));
        }
    },
    async created() {
        console.log('eded', this.$root.editorLocales);
    }
}
</script>

<style>

.aph2 {
    position: relative;
    width: 100%;
    height: 60px;
    background-color: var(--header);
}

.aph2-logo img {
    position: absolute;
}
.aph2-logo label {
    position: absolute;
    top: 13px;
    font-family: "Roboto Slab", serif;
    font-size: 24px;
    color: var(--header-text);
}


.aph2-right {
    float: right;
    display: flex;
}


.aph2-lang {
    position: relative;
    margin-top: 10px;
    margin-right: 20px;
    padding: 12px 15px;
    padding-bottom: 0px;
    border-radius: 3px;
    cursor: pointer;
}
.aph2-lang:hover {
    background-color: var(--header-hover);
}
.aph2-lang img {
    width: 26px;
    border-radius: 2px;
}
.aph2-lang-menu-item {
    position: relative;
    height: 24px;
    color: var(--header-text);
    padding: 5px 5px;
    cursor: pointer;
}
.aph2-lang-menu-item img {
    position: absolute;
    width: 24px;
    top: 9px; left: 10px;
    border-radius: 2px;
}
.aph2-lang-menu-item label {
    position: absolute;
    top: 8px; left: 45px;
    cursor: pointer;
}
.aph2-lang-menu-item:hover {
	background-color: var(--header-hover);
}

</style>
