<template>
<div>
    <PageHeader compactable :offset="40" @scrolled="scrolled = $event">
        <template v-slot:content>
            <vap-row v-if="!loading">
                <vap-group auto noPadding>
                    <vap-label :text="'Batch customer creation'" desc/>
                    <vap-label :text="'Create customers in batch by uploading CSV files'" value />
                </vap-group>
            </vap-row>
        </template>
    </PageHeader>

    <PageContent :scrolled="scrolled" :offset="40" v-if="!loading">
        <div class="form-page" style="padding-top: 40px;">

            <SplitterV1 :left="50" :right="50">
                <template v-slot:left>

                    <vap-label :text="'Batches'" header2 style="margin-top: 10px; margin-bottom: 10px;" />

                    <div class="amlv1-batch-item" v-for="(batch, i) in batches" :key="'b' + i" @click="onBatchClicked(batch)">
                        {{ batch.batchId }}
                    </div>

                    <div class="flw100" v-if="batches.length == 0">
                        {{ 'No batches' }}
                    </div>

                </template>

                <template v-slot:right>

                    <vap-label :text="'Actions'" header2 style="margin-top: 10px; margin-bottom: 10px;" />

                    <ActionV1
                        :icon="'add'"
                        :text="'New batch'"
                        :hint="'Create a new batch from a CSV file'"
                        @clicked="onNewBatchClicked()"
                    />

                </template>
            </SplitterV1>

        </div>
    </PageContent>

    <PageFooter :scrolled="scrolled" />

    <PopupV1 :maxHeight="500" v-if="dialog.show" :width="dialog.width" :header="__t('files-upload-header')" @close="dialog.show = false" :dialog="dialog">
        <template v-slot:content>
            <div class="flw100" v-if="!hasFile">
                <VFFileuplaod v-model="files" :allowedFiles="'all'" :singleFile="true"
                    :header="__t('files-upload-header')"
                    :hintText="__t('files-upload-hint')"
                    :browseText="__t('files-upload-browse')"
                    @input="onValidateDialog()"
                />
            </div>
            <div v-if="hasFile">
                <DataTableV1 :columns="loadedColumns" :rows="loadedRows" />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="dialog.show = false" :disabled="dialog.working" />
            <VFButton
                :text="'Create batch'"
                :icon="'upload'"
                @clicked="onCreateBatch"
                :working="dialog.working"
                :disabled="!hasFile"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import tools from '../tools';

import PageHeader from '../v1ui/PageHeader.vue';
import PageContent from '../v1ui/PageContent.vue';
import PageFooter from '../v1ui/PageFooter.vue';
import SplitterV1 from '../v1ui/SplitterV1.vue';
import ActionV1 from '../v1ui/ActionV1.vue';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFFileuplaod from '../components/form/inputs/VFFileupload.vue';

import DataTableV1 from '../v1ui/DataTableV1.vue';

export default {
    name: 'BatchListV1',
    components: {
        PageHeader,
        PageContent,
        PageFooter,
        SplitterV1,
        ActionV1,
        PopupV1,
        VFButton,
        VFFileuplaod,
        DataTableV1,
    },
    data() {
        return {
            loading: true,
            scrolled: false,
            batches: [],
            dialog: { show: false, working: false, status: '', error: '', width: 500 },
            files: {},
            hasFile: false,
            loadedColumns: [],
            loadedRows: [],
        }
    },
    methods: {

        onNewBatchClicked() {
            this.hasFile = false;
            this.files = {};
            this.dialog.width = 500;
            this.dialog.show = true;
            this.onValidateDialog();
        },

        onBatchClicked(batch) {
            this.$parent.navigate({ path: '/v1batch', query: { id: batch._id } });
        },

        onValidateDialog() {
            this.onFileDetected();
        },

        async onCreateBatch() {
            this.dialog.working = true;

            const batchData = {
                batchId: tools.getUniqueId(),
                clients: [],
                currentIndex: 0,
            };

            for (const i in this.loadedRows) {
                const client = {
                    status: {
                        searchDone: false,
                        kycSent: false,
                        smsSent: false,
                        clientId: '',
                        addResult: '',
                    },
                    companyName: this.loadedRows[i].company,
                    identity: this.loadedRows[i].orgnr,
                    firstName: this.loadedRows[i].firstname,
                    lastName: this.loadedRows[i].lastname,
                    email: this.loadedRows[i].email,
                };
                batchData.clients.push(client);
            }

            await v1api.addBatch(batchData);

            this.dialog.working = false;
            this.dialog.show = false;
            this.loadBatches();
        },

        onFileDetected() {
            console.log('onFileDetected');
            if (!this.files.files) return;

            const self = this;
            const reader = new FileReader();
            reader.onload = function(e) {
                const contents = e.target.result;
                self.readFile(contents);
            };
            reader.readAsText(this.files.files[0].data, 'ISO-8859-4');

        },

        readFile(data) {
            this.loadedColumns = [];
            this.loadedRows = [];

            console.log(data);
            const list = [];
            const lines = data.split(/\r\n|\n/);
            const def1 = lines[0].split(';');
            const def2 = lines[0].split(',');
            const comma = (def2.length > def1.length)
            const definition = comma ? def2 : def1;
            for (let i=1; i<lines.length; i++) {
                if (lines[i].length < 1) continue;
                const line = lines[i].split(comma ? ',' : ';');
                const item = {};
                for (const x in definition) {
                    item[definition[x]] = line[x];
                    if (i == 1) {
                        this.loadedColumns.push({
                            key: definition[x],
                            name: definition[x],
                            width: 10
                        });
                    }
                }
                /* console.log('item', item); */
                list.push(item);
            }
            for (const i in list) {
                console.log('list[i', list[i]);
                this.loadedRows.push({
                    company: list[i].company,
                    orgnr: list[i].orgnr.trim(),
                    firstname: list[i].firstname,
                    lastname: list[i].lastname,
                    email: list[i].email.trim(),
                });
            }

            console.log('columns', this.loadedColumns);
            console.log('rows', this.loadedRows);

            if (this.files.files) {
                this.dialog.width = 1200;
                this.hasFile = true;
            }
        },

        async loadBatches() {
            this.loading = true;

            const response = await v1api.getBatches();
            this.batches = response.batches;

            this.loading = false;
        },

    },
    async created() {
        this.loadBatches();
    }
}
</script>
<style scoped>

.amlv1-batch-item {
    float: left;
    width: 100%;
    border: solid 1px var(--input-border);
    cursor: pointer;
}
.amlv1-batch-item:hover {
    background-color: var(--item-hover);
}

</style>