<template>
<div>
    <PopupV1 :header="params.deleteMode ? 'Slett fil' : 'Last opp fil'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div class="flw100" v-if="!params.deleteMode">
                <VFFileuplaod v-model="files"
                    :header="__t('files-upload-header')"
                    :hintText="__t('files-upload-hint')"
                    :browseText="__t('files-upload-browse')"
                    @input="validateInputs"
                />
            </div>
            <div v-if="params.deleteMode" style="margin-bottom: 10px; color: var(--text-title); font-style: italic;">
                {{ 'Den opplastede filen vil bli slettet' }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton v-if="!params.deleteMode"
                :text="'Last opp'"
                :icon="'upload'"
                @clicked="onUpload"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
            <VFButton v-if="params.deleteMode"
                :text="'Slett fil'"
                :icon="'delete'" warning
                @clicked="onDelete"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>
</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1receipt from '../v1helpers/v1receipt';
import dataModelV1 from '../v1data/dataModelV1';
import v2lib from '../v2lib';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFFileuplaod from '../components/form/inputs/VFFileupload.vue';

export default {
    name: 'UploadFormDialog',
    components: {
        PopupV1,
        VFButton,
        VFFileuplaod,
    },
    props: {
        params: { type: Object, default: null },
        client: { type: Object, default: null },
        flow: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            files: {}
        }
    },
    methods: {

        validateInputs() {
            if (this.params.deleteMode) {
                this.dialog.valid = true;
            }else {
                if (this.files.files && this.files.files.length > 0) {
                    this.dialog.valid = true;
                }else {
                    this.dialog.valid = false;
                }
            }
        },

        async onUpload() {
            this.dialog.working = true;
            this.dialog.status = 'Fetching envelope';

            let envelope;
            if (this.client.envelope.id) {
                envelope = await this.getEnvelope(this.client.envelope.id);
                console.log('envelope', envelope);
            }else {
                this.dialog.status = 'Creating new envelope for attachments';
                const extraData = {
                    dataOnly: true,
                    dataOnlyParams: {
                        title: this.flow.title[this.$root.config.params.defaultLanguage] + ': ' + this.companyTitleCase(this.client.name),
                    },
                };
                envelope = await v1receipt.createClientEnvelope(this.$root, this.client, dataModelV1.getEmptyCompany(), this.flow, extraData);

                const updateData = {
                    envelopeId: envelope.id,
                    documentId: envelope.documentId,
                    name: this.__t('datapdf-name') + ' ' + this.companyTitleCase(this.client.name),
                    bearerToken: envelope.metadata.bearerToken
                };
                await v1api.updateClient(this.client._id, updateData);
            }

            if (!envelope) {
                this.dialog.error = 'No client envelope, can not add attachments!';
                this.dialog.working = false;
                return;
            }

            this.dialog.status = 'Uploading file';

            console.log('files', this.files);
            const name = this.files.files[0].name;
            const data = this.files.files[0].data;
            await v2lib.addAttachmentToExistingEnvelope(envelope, name, data, v2lib.personalToken);

            const fileData = {
                type: 'form-manual',
                envelopeId: envelope.id,
                formKey: this.params.formKey,
                name: name
            };
            await v1api.uploadFile(this.client._id, fileData);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        async onDelete() {
            this.dialog.working = true;
            this.dialog.status = 'Deleting file';

            await v1api.deleteFile(this.client._id, this.params.formKey);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        },

    },
    async created() {
        this.validateInputs();
        this.loading = false;
    }
}
</script>