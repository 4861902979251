<template>
<div>

    <PopupV1 :header="__('persons_select_contact')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div class="v1person-select">
                <div class="v1person-select-item" v-for="(person, i) in persons" :key="'psc' + i" :class="[
                    {'v1person-select-item-selected': selectedIndex == i }
                ]" @click="onSelected(i)">
                    <div>
                        <span style="color: var(--text-title);">{{ person.displayName }}</span>
                        <span style="color: var(--text-weak); margin-left: 6px;">{{ person.info.dateOfBirth }}</span>
                    </div>
                    <div v-html="getDisplayRoles(person)" />
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="'Angi kontaktperson'" @clicked="onClick" :disabled="!dialog.valid" :working="dialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1personHelper from '../v1helpers/v1personHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';

export default {
    name: 'SelectContactDialog',
    components: {
        PopupV1,
        VFButton,
    },
    props: {
        client: { type: Object, default: null },
        persons: { type: Array, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            selectedIndex: -1,
        }
    },
    methods: {

        getDisplayRoles(person) {
            return v1personHelper.getDisplayRoles(person, this.$root);
        },

        onSelected(index) {
            if (this.selectedIndex == index) {
                this.selectedIndex = -1;
            }else {
                this.selectedIndex = index;
            }
            this.dialog.valid = this.selectedIndex != -1;
        },

        async onClick() {
            this.dialog.working = true;
            this.dialog.status = 'Angir kontaktperson';

            const roleData = {
                firstName: this.persons[this.selectedIndex].info.firstName,
                lastName: this.persons[this.selectedIndex].info.lastName,
                dateOfBirth: this.persons[this.selectedIndex].info.dateOfBirth,
                roleId: 'role_customer_contact',
                share: ''
            };
            console.log('roleData', roleData);
            console.log('person', this.persons[this.selectedIndex]);
            await v1api.addManualRole(this.client._id, this.persons[this.selectedIndex].personId, roleData);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);
            this.$emit('close');

            this.dialog.working = false;
            this.dialog.show = false;
        },

        onClose() {
            this.$emit('close');
        }

    },
    created() {
        this.loading = false;
    }
}
</script>
<style scoped>

.v1person-select {
    max-height: 420px;
    overflow: auto;
    border-radius: 5px;
    border: solid 1px var(--input-border);
}   

.v1person-select-item {
    padding: 6px 15px;
    border-bottom: solid 1px var(--input-border);
    cursor: pointer;
}
.v1person-select-item:hover {
    background-color: var(--item-hover);
}
.v1person-select-item-selected {
    background-color: var(--item-hover);
}

</style>