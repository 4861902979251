<template>
<div class="vap-input" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" @mouseup="handleOptionMouseUp($event)"
    :class="[
        {'vap-input-combine': combine},
        {'vap-input-disabled': disabled},
        {'vap-input-dotted': dotted},
        {'vap-input-actions': actions},
        {'vap-input-numeric': numeric},
        {'val-ok': validation.valid && !noHighlight},
        {'val-error': !validation.valid},
        {'val-show': validation.show},
    ]">

    <input class="vip" :id="'inpid' + id"
        :value="value"
        :placeholder="placeholder"
        :type="password ? 'password' : numeric ? 'number' : ''"
        :inputmode="numeric ? 'numeric' : ''"
        :pattern="numeric ? '[0-9]*' : ''"
        @input="handleInput"
        @keydown="handleKeyDown"
        :disabled="disabled"
    />

    <div v-if="actions" style="display: flex; margin-right: -5px; margin-left: 5px;">
        <slot name="actions"></slot>
    </div>

    <!------- Validation ---------------------------------------------------------------------------->
    <svg v-if="validation.show && validation.valid && !noValidation" height="24" width="24" class="val-ic-ok">
        <path d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"/>
    </svg>
    <svg v-if="validation.show && !validation.valid" height="24" width="24" class="val-ic-error">
        <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"/>
    </svg>
    <div v-if="validation.show && !validation.valid" class="val-message vip-error">
        <label>{{ '* ' + __(validation.key) }}</label>
    </div>
    <!------- ----------- -------------------------------------------------------------------------->
</div>
</template>

<script>
import validations from '../../validations';

export default {
    name: 'vap-input',
    props: {
        value: [String, Number],
        placeholder: {
            type: String,
            default: ''
        },
        combine: {
            type: Boolean,
            default: false
        },
        rules: {
            type: String,
            default: ''
        },
        password: {
            type: Boolean,
            default: false
        },
        dictionary: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dotted: {
            type: Boolean,
            default: false
        },
        actions: {
            type: Boolean,
            default: false
        },
        noValidation: {
            type: Boolean,
            default: false
        },
        noHighlight: {
            type: Boolean,
            default: false
        },
        numeric: {
            type: Boolean,
            default: false
        },
        numberFormat: {
            type: Boolean,
            default: false
        },
        id: {
			type: String,
			default: 'id'
        },
        activeLogic: {
			type: Boolean,
			default: false
        },
    },
    data() {
        return {
            validation: {
                show: false,
                valid: true,
                key: ''
            }
        }
    },
    watch: {
        value: {
            deep: false,
            handler(val) {
                if (!this.validation.show && !this.noValidation) this.validation.show = true;
                this.validate(val);
            }
        }
    },
    methods: {

        validate(value) {
            if (this.noValidation) return;
            if (!value) value = this.value;
            this.validation = {...this.validation, ...validations.validateRules(value, this.rules)};
        },

        handleInput(e) {
            let val = e.srcElement.value;
            if (this.numberFormat) {
                const vvv = this.formatMoney(val);
                val = vvv == 'NaN' ? '' : vvv;
            }

            if (!this.validation.show && !this.noValidation) this.validation.show = true;
            this.$emit('input', val);
            this.$emit('changed', val);

            if (this.dictionary) {
                if (this.dictionary[val]) {
                    this.$emit('on-dictionary-match', this.dictionary[val]);
                }
            }
        },

        formatMoney(amount) {
            let value = parseFloat(amount.split(' ').join('')).toFixed(0)
            var str = "";
            if (value.toString().length > 9) {
                let index1 = value.toString().length - 9;
                let index2 = value.toString().length - 6;
                let index3 = value.toString().length - 3;
                str = value.toString().substring(0, index1) + " " + value.toString().substring(index1, index2) + " " + value.toString().substring(index2, index3)  + " " + value.toString().substring(index3);
            }else if (value.toString().length > 6) {
                let index1 = value.toString().length - 6;
                let index2 = value.toString().length - 3;
                str = value.toString().substring(0, index1) + " " + value.toString().substring(index1, index2) + " " + value.toString().substring(index2);
            }else if (value.toString().length > 3) {
                let index = value.toString().length - 3;
                str = value.toString().substring(0, index) + " " + value.toString().substring(index);
            }else {
                str = value.toString();
            }
            return str;
        },

        handleMouseEnter(e) {
            this.$emit('connect-enter', this.id);
        },

        handleMouseLeave(e) {
            this.$emit('connect-leave', this.id);
        },

        handleOptionMouseUp(e) {
            if (this.activeLogic) {
                this.$emit('connect', this.id);
            }
        },

        handleKeyDown(event) {
            if (this.disabled) return;
            if (event.keyCode == 13) {
                this.$emit('onreturn');
            }
        },

    }
}
</script>

<style scoped>

.vap-input {
    position: relative;
    float: left;
    width: 100%;
}

.vap-input input {
    width: 100%;
    padding: 6px 12px;
    padding-top: 7px;
    font-size: 15px;
    box-sizing: border-box;
    background-color: var(--card-background);
}

.vap-input input:focus {
    border-color: var(--input-focus);
}

.vap-input-disabled input {
    background-color: lightgray;
}

.vap-input-actions {
    display: flex;
}

.vap-input-actions .vap-action {
    margin-top: 1px;
    margin-left: 4px;
    margin-right: -4px;
    opacity: 0.6;
    cursor: pointer;
}
.vap-input-actions .vap-action:hover {
    opacity: 1;
}

.vap-input-numeric input {
    
}

/* --- Combine ----------------------- */

.vap-input-combine input {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    margin-left: -1px;
}

/* --- ----------- ------------------- */


/* --- Validation -------------------- */

.val-show.val-ok input {
    border-color: var(--val-ok);
}
.val-show.val-error input {
    border-color: var(--val-error);
}

.val-ic-ok {
    position: absolute;
    top: 8px;
    right: 8px;
    transform: scale(1.0);
}
.val-ic-ok path {
    fill: var(--val-ok);
}

.val-ic-error {
    position: absolute;
    top: 8px;
    right: 8px;
    transform: scale(0.8);
}
.val-ic-error path {
    fill: var(--val-error);
}

.val-message {
    
}

/* --- ---------- -------------------- */


/* --- Design Mode ------------------- */

.ac-designer input {
    cursor: default;
}

.vap-input-dotted input {
    border: dotted 2px var(--input-border);
}

.logic .vap-input:hover input {
    border: solid 3px #0DCC27;
}

/* --- ----------- ------------------- */

</style>
