<template>
    <div>
        <div class="slim-page">
            <vap-card list :header="'Export data'">
                <vap-row>
                    <vap-group c2>
                        <vap-label :text="'Skip'" desc />
                        <vap-input v-model="skip" />
                    </vap-group>
                    <vap-group c2>
                        <vap-label :text="'Limit'" desc />
                        <vap-input v-model="limit" />
                    </vap-group>
                    <vap-group c2>
                        <vap-button :text="'Load customers'" @clicked="loadCustomers" :disabled="working" :working="working" />
                    </vap-group>
                </vap-row>
            </vap-card>
        </div>
        <BatchView ref="batchview" :items="items" :steps="steps" :title="'Last ned rapport og oppdater iManage'" />
    </div>
</template>
<script>
import api from '../../portalapi';
import tools from '../../tools';
import cache from '../../cache';
import receipts from '../../receipts';
import clienthelper from '../../helpers/clienthelper';
import personhelper from '../../helpers/personhelper';
import reporthelper from '../../helpers/reporthelper';

import downloadjs from 'downloadjs';
import BatchView from '../../components/special/BatchView';

export default {
    components: {
        BatchView
    },
    name: 'BatchSaveReport',
    data() {
        return {
            items: [],
            steps: 3,
            STEP_LOAD_CLIENT: 0,
            STEP_PROCESS: 1,
            STEP_SAVE_DATA: 2,
            working: false,
            skip: 0,
            limit: 1,
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            return item.name;
        },

        onGetInfo1(item) {
            return item.status;
        },

        onGetInfo2(item) {
            return '';
        },

        async onRemoveItem(item) {
            
        },

        async onStart() {
            return true;
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return 'Laster kunde';
                case this.STEP_PROCESS: return 'Sjekker filter';
                case this.STEP_SAVE_DATA: return 'Lagrer sluttrapport';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return await this.loadClient(item, data);
                case this.STEP_PROCESS: return await this.processClient(item, data);
                case this.STEP_SAVE_DATA: return await this.saveData(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async loadClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { client: null, skip: false } };

            try {
                result.data.client = await clienthelper.loadClient(this.$root, item);
            }catch (e) {
                result.data.skip = true;
            }
            

            result.ok = true;
            return result;
        },

        async processClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { base64: '', skip: false } };

            await new Promise(r => setTimeout(r, 1000));

            const skip = data[this.STEP_LOAD_CLIENT].skip;
            if (skip) {
                console.log('SKIP');
                result.data.skip = true;
                result.ok = true;
                return result;
            }

            const client = data[this.STEP_LOAD_CLIENT].client;
            console.log('client', client);


            try {
                const config = reporthelper.getReportConfig(this.$root);
                config.ssn = false;
                config.appendAttachments = true;
                config.externalRisk = {
                    enabled: false
                };
                config.shareholderBook = {
                    enabled: false
                };
                config.externalKYC = {
                    enabled: false
                };
                const inputData = {
                    customer: client.customer,
                    brregData: client.brregData,
                    manualData: client.manualData,
                    aml: client.amlData,
                    persons: client.persons,
                    reportData: client.reportData,
                    kycMetadata: client.kycMetadata,
                    externalRisk: {
                        provided: false
                    },
                    shareholderBook: {
                        provided: false,
                        envelopeId: ''
                    },
                    externalKYC: {
                        provided: false,
                        envelopeId: ''
                    }
                };
                result.data.base64 = await reporthelper.downloadPDF(this.$root, inputData, config);
            }catch (e) {
                result.data.skip = true;
                console.log('ERROR');
            }
            

            result.ok = true;
            return result;
        },

        async saveData(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };


            const skip = data[this.STEP_PROCESS].skip;
            if (skip) {
                console.log('SKIP');
                result.ok = true;
                return result;
            }

            try {
                const base64 = data[this.STEP_PROCESS].base64;
                const client = data[this.STEP_LOAD_CLIENT].client;

                let dirMatch = 'n';
                if (item.flow == 'org-manual') dirMatch = 'u';
                if (item.flow == 'org-no-unreg') dirMatch = 's';
                if (item.flow == 'person') dirMatch = 'p';
                    

                const dstr = new Date(item.report.reportDate).toISOString().substring(0, 10);
                let name = dirMatch + '¤' + item.name + '¤' + item.identity + '¤' + item.partner + '¤' + this.$root.getPartnerName(item.partner) + '¤' + dstr;
                if (item.flow == 'person') {
                    name = dirMatch + '¤' + item.name + '¤' + (client.singlePerson.info.ssn || client.singlePerson.info.dateOfBirth) + '¤' + dstr;
                }

                downloadjs("data:application/pdf;base64," + base64, name + ".pdf", "application/pdf");

                await new Promise(r => setTimeout(r, 1000));

                const updateData = {
                    status: 'r-rep-z'
                };
                await api.updateCustomer(item._id, updateData);
            }catch (e) {
                console.log('ERROR');
            }

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        async loadCustomers() {
            this.$parent.setLoading(true, 'Laster kunder...');
            
            const filters = [
                { field: 'status', op: 'na', values: ['r-rep-x'] },
            ];
            const from = this.skip;
            const limit = this.limit;
            const sort = { dateUpdated: 1 };
            const include = [];
            const counts = [];
            const response = await api.queryCustomers(filters, from, limit, sort, include, counts);

            this.items = [];
            for (const i in response.customers) {
                this.items.push(response.customers[i]);
            }

            this.$parent.setLoading(false);
        },

    },
    async created() {
        
    }
}
</script>