<template>
<div>
    <HorizontalStepper :steps="steps" :stepKey="stepKey" @nav="onStepKeyChanged" @on-submit="onSubmit" :flowName="__('kyc_title')" />
    <div class="kyc">

    </div>
</div>
</template>
<script>
import log from '../log';
import api from '../portalapi';
import locale from '../locale';

import HorizontalStepper from '../components/kyc/HorizontalStepper';

export default {
    name: 'PublicKYC',
    components: {
        HorizontalStepper
    },
    data() {
        return {
            steps: [],
            stepKey: '',
        }
    },
    methods: {
        onStepKeyChanged(nav) {
            let result = this.$refs[this.stepKey].validate();
            if (nav.direction != 'prev' && !result) return;
            if (nav.skipping && !this.$root.debug.local) return;

            this.addContactInfoToPersons();
            this.stepKey = nav.key;
        },
        onSubmit() {

        }
    },
    async created() {
        this.$parent.setLoading(true, this.__('loading_form') + '...');
        log.v('PublicKYC: View created with key', this.$route.params.key);

        // Load KYC translations and merge with main
        const defaults = locale.getDefaultKycTranslations();
        const tres = await api.getTranslations(this.$route.params.key);
        this.kycTranslations = { ...defaults, ...tres.texts };
        this.$root.translations = { ...this.$root.translations, ...this.kycTranslations };

        // Set titles now that we have the translations
        this.$parent.setTitle(this.__('kyc_title'));
        this.$parent.headerTitle = this.__('kyc_title');
        
        // Show UI
        this.$parent.setLoading(false);
    }
}
</script>
<style scoped>



</style>