<template>
<div>

    <PopupV1 :header="'Screening result details'" @close="onClose" :width="700" :dialog="dialog">
        <template v-slot:content>
            <div style="max-height: 500px; overflow: auto; padding-right: 20px; max-width: 100%; overflow-x: hidden;">
                <DialogContent customContent :header="'Search parameters'" style="margin-bottom: 10px;">
                    <VFRow>
                        <VFGroup c12>
                            <VFLabel desc :text="'Name'" /><br>
                            <VFLabel input :text="params.screening.searchParams.firstName + ' ' + params.screening.searchParams.lastName" />
                        </VFGroup>
                        <VFGroup c6>
                            <VFLabel desc :text="'Date of Birth'" /><br>
                            <VFLabel input :text="params.screening.searchParams.dateOfBirth" />
                        </VFGroup>
                        <VFGroup c6>
                            <VFLabel desc :text="'Search mode'" /><br>
                            <VFLabel input :text="params.screening.searchParams.mode" />
                        </VFGroup>
                    </VFRow>
                </DialogContent>
                <DialogContent customContent :header="'PEP/Sanction summary'" style="margin-bottom: 10px;">
                    <VFRow>
                        <VFGroup c12>
                            <VFLabel desc :text="'Result'" /><br>
                            <VFLabel v-if="!params.screening.isPep && !params.screening.hasSanction" input :text="__('hit_none')" />
                            <VFLabel v-if="params.screening.isPep && !params.screening.hasSanction" input :text="__('hit_pep')" />
                            <VFLabel v-if="params.screening.hasSanction && !params.screening.isPep" input :text="__('hit_sanction')" />
                            <VFLabel v-if="params.screening.hasSanction && params.screening.isPep" input :text="'Treff på PEP og SANKSJON'" />
                        </VFGroup>
                        <VFGroup c12 v-if="params.screening.isPep">
                            <VFLabel desc :text="'Best match'" /><br>
                            <VFLabel input :text="params.screening.pepSummary.matchIndicator + ' - ' + params.screening.pepSummary.matchDescription" />
                        </VFGroup>
                        <VFGroup c12 v-if="params.screening.hasSanction">
                            <VFLabel desc :text="'Best match'" /><br>
                            <VFLabel input :text="params.screening.sanctionSummary.matchIndicator + ' - ' + params.screening.sanctionSummary.matchDescription" />
                        </VFGroup>
                    </VFRow>
                </DialogContent>
                <DialogContent customContent :header="'Data from FREG'" v-if="hasFreg" style="margin-bottom: 10px;">
                    <VFRow>
                        <VFGroup c12>
                            <VFLabel desc :text="'Name'" /><br>
                            <VFLabel input
                                :text="params.screening.name"
                            />
                        </VFGroup>
                        <VFGroup c6>
                            <VFLabel desc :text="'Citizenship'" /><br>
                            <VFLabel input v-for="(cit, i) in params.screening.citizenships" :key="'cz' + i"
                                :text="cit.country"
                            />
                        </VFGroup>
                        <VFGroup c6>
                            <VFLabel desc :text="'Gender'" /><br>
                            <VFLabel input
                                :text="params.screening.gender"
                            />
                        </VFGroup>
                        <VFGroup c12>
                            <VFLabel desc :text="'Birthplace'" /><br>
                            <VFLabel input
                                :text="formatBirthplace(params.screening.birthplace)"
                            />
                        </VFGroup>
                    </VFRow>
                </DialogContent>
                <DialogContent customContent :header="'Address'" style="margin-bottom: 10px;">
                    <VFRow>
                        <VFGroup c12>
                            <VFLabel desc :text="'Postal address'" /><br>
                            <VFLabel input
                                :text="isValidAddress(params.screening.addressPostal) ? formatAddress(params.screening.addressPostal) : 'Not available'"
                                :disabled="!isValidAddress(params.screening.addressPostal)"
                            />
                        </VFGroup>
                        <VFGroup c12>
                            <VFLabel desc :text="'National address'" /><br>
                            <VFLabel input
                                :text="isValidAddress(params.screening.addressNational) ? formatAddress(params.screening.addressNational) : 'Not available'"
                                :disabled="!isValidAddress(params.screening.addressNational)"
                            />
                        </VFGroup>
                        <VFGroup c12 v-if="params.screening.historicAddresses.length > 0">
                            <VFLabel desc :text="'Historical addresses'" /><br>
                            <VFLabel input v-for="(adr, i) in params.screening.historicAddresses" :key="'ha' + i" style="width: 100%;"
                                :text="formatAddress(adr)"
                            />
                        </VFGroup>
                    </VFRow>
                </DialogContent>
                <DialogContent customContent noPadding :header="'Company roles'" style="margin-bottom: 10px;">
                    <div>
                        <div class="aml-pi-item" v-for="(role, i) in params.screening.roles.companies" :key="'rc' + i">
                            <div class="">
                                <span class="aml-pi-item-vx">{{ role.name }}</span>
                                <span class="aml-pi-item-d">{{ ' (' + role.orgnr + ')' }}</span>
                            </div>
                            <div class="">
                                <span class="aml-pi-item-d">{{ __(role.roleId) + ' (' + role.from + ')' }}</span>
                            </div>
                        </div>
                        <div v-if="!params.screening.roles.available">
                            {{ 'Not available' }}
                        </div>
                    </div>
                </DialogContent>
                <DialogContent customContent noPadding :header="'Politically exposed person'" style="margin-bottom: 10px;">
                    <div>
                        <div class="aml-pi-item" v-for="(hit, i) in params.screening.pepHits" :key="'pis' + i">
                            <div class="aml-pi-warning" v-if="hit.matchIndicator < 175">
                                <div class="aml-pi-warning-icon">
                                    {{ '!' }}
                                </div>
                                <div class="aml-pi-warning-text">
                                    {{ 'No match on date of birth! This may indicate a false positive.' }}
                                </div>
                            </div>
                            <div class="">
                                <span class="aml-pi-item-d">{{ 'Match against:' }}</span>
                                <span class="aml-pi-item-v">{{ hit.source }}</span>
                            </div>
                            <div class="">
                                <span class="aml-pi-item-d">{{ 'Match indicator:' }}</span>
                                <span class="aml-pi-item-v">{{ hit.matchIndicator + ' - ' + hit.matchDescription }}</span>
                            </div>
                            <div class="">
                                <span class="aml-pi-item-d">{{ 'Name:' }}</span>
                                <span class="aml-pi-item-v">{{ hit.name }}</span>
                            </div>
                            <div class="" v-if="hit.title">
                                <span class="aml-pi-item-d">{{ 'Title:' }}</span>
                                <span class="aml-pi-item-v">{{ hit.title }}</span>
                            </div>
                            <div class="" v-if="hit.comment">
                                <span class="aml-pi-item-d">{{ 'Comment:' }}</span>
                            </div>
                            <div class="" v-html="hit.comment" />
                        </div>
                        <div class="aml-pi-empty" v-if="!params.screening.isPep">
                            {{ 'No hits in PEP lists' }}
                        </div>
                        <div class="aml-pi-lists">
                            <div class="aml-pi-list-head">
                                {{ 'Person was screened against these lists:' }}
                            </div>
                            <div class="aml-pi-list" v-for="(list, i) in pepSources" :key="'sl' + i">
                                {{ '• ' + list }}
                            </div>
                        </div>
                    </div>
                    <div class="aml-pi-hits" v-if="params.screening.isPep">
                        {{ params.screening.pepHits.length + ' hits' }}
                    </div>
                    <CardActions :actions="actionsPep" />
                </DialogContent>
                <DialogContent customContent noPadding :header="'Sanction lists'" style="margin-bottom: 10px;">
                    <div>
                        <div class="aml-pi-item" v-for="(hit, i) in params.screening.sanctionHits" :key="'pis' + i">
                            <div class="aml-pi-warning" v-if="hit.matchIndicator < 175">
                                <div class="aml-pi-warning-icon">
                                    {{ '!' }}
                                </div>
                                <div class="aml-pi-warning-text">
                                    {{ 'No match on date of birth! This may indicate a false positive.' }}
                                </div>
                            </div>
                            <div class="">
                                <span class="aml-pi-item-d">{{ 'Match against:' }}</span>
                                <span class="aml-pi-item-v">{{ hit.source }}</span>
                            </div>
                            <div class="">
                                <span class="aml-pi-item-d">{{ 'Match indicator:' }}</span>
                                <span class="aml-pi-item-v">{{ hit.matchIndicator + ' - ' + hit.matchDescription }}</span>
                            </div>
                            <div class="">
                                <span class="aml-pi-item-d">{{ 'Name:' }}</span>
                                <span class="aml-pi-item-v">{{ hit.name }}</span>
                            </div>
                            <div class="" v-if="hit.title">
                                <span class="aml-pi-item-d">{{ 'Title:' }}</span>
                                <span class="aml-pi-item-v">{{ hit.title }}</span>
                            </div>
                            <div class="" v-if="hit.comment">
                                <span class="aml-pi-item-d">{{ 'Comment:' }}</span>
                            </div>
                            <div class="" v-html="hit.comment" />
                        </div>
                        <div class="aml-pi-empty" v-if="!params.screening.hasSanction">
                            {{ 'No hits in sanction lists' }}
                        </div>
                        <div class="aml-pi-lists">
                            <div class="aml-pi-list-head">
                                {{ 'Person was screened against these lists:' }}
                            </div>
                            <div class="aml-pi-list" v-for="(list, i) in sanctionSources" :key="'sl' + i">
                                {{ '• ' + list }}
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-close')" outline @clicked="onClose" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import dbPersonHelper from '../v1helpers/dbPersonHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';

import CardActions from '../v1ui/CardActions.vue';

export default {
    name: 'ScreeningInfoDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        VFRow,
        VFGroup,
        VFLabel,
        CardActions,
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            dialog: { show: true },
            pepSources: [],
            sanctionSources: [],
            actionsPep: [
                /* { action: 'help', icon: 'help-o' }, */
            ],
            hasFreg: false,
        }
    },
    methods: {

        onClose() {
            this.$emit('close');
        },

        isValidAddress(address) {
            if (address.street) return true;
            return false;
        },

        formatAddress(address) {
            return dbPersonHelper.formatAddressString(this.$root, address);
        },

        formatBirthplace(birthplace) {
            return birthplace.city + ', ' + birthplace.country;
        },

        getPepSources(screening) {
            if (screening.pepLists) {

            }else {
                if (screening.source == 'bankidaml') {
                    return [
                        'KS-PEP Edge / Redaksjonell nordisk PEP-liste forvaltet av Trapets AB',
                    ];
                }
            }
        },

        getSanctionSources(screening) {
            if (screening.sanctionLists) {

            }else {
                if (screening.source == 'bankidaml') {
                    return [
                        'European Union Consolidated Financial Sanctions List',
                        'United Nations Security Council Consolidated List',
                    ];
                }
            }
        },

    },
    async created() {
        this.pepSources = this.getPepSources(this.params.screening);
        this.sanctionSources = this.getSanctionSources(this.params.screening);

        if (this.params.screening.isPep && this.$root.config.persons.allowFalsePositive) {
            this.actionsPep.splice(0, 0, { action: 'fp', icon: 'edit' });
        }

        this.loading = false;
    }
}
</script>
<style scoped>

.aml-pi-empty {
    margin-top: 10px;
    padding: 0px 25px;
    color: var(--text-disabled);
}

.aml-pi-item {
    float: left;
    width: 100%;
    padding: 10px 25px;
    border-bottom: solid 1px var(--input-border);
    box-sizing: border-box;
}

.aml-pi-item-d {
    color: var(--text-weak);
}
.aml-pi-item-v {
    margin-left: 6px;
    color: var(--text-title);
}
.aml-pi-item-vx {
    color: var(--text-title);
}

.aml-pi-warning {
    display: flex;
    margin-bottom: 6px;
}
.aml-pi-warning-icon {
    flex-shrink: 0;
    width: 18px; height: 18px;
    padding-top: 0px;
    padding-left: 7px;
    font-size: 15px;
    font-weight: bold;
    color: white;
    border-radius: 50%;
    background-color: var(--val-error);
    box-sizing: border-box;
}
.aml-pi-warning-text {
    flex-grow: 100;
    margin-left: 5px;
    padding-top: 1px;
    font-size: 14px;
    color: var(--val-error);
}



.aml-pi-hits {
    position: absolute;
    top: 5px; right: 160px;
    font-size: 15px;
    font-weight: 500;
    color: var(--val-error);
}

.aml-pi-lists {
    float: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px 25px;
}
.aml-pi-list-head {
    font-size: 14px;
    color: var(--text-weak);
}
.aml-pi-list {
    font-size: 14px;
    color: var(--text-title);
}

</style>