<template>
    <div class="pdfv-base">

        <div class="pdfv-bg" />

        

        <div class="pdfv-content">

            
            <div class="pdfv-pages" :style="'width: ' + pageWidth + 'px;'">

                <div class="px" v-for="(page, i) in pages" :key="'p' + i">
                    <div class="" :style="'width: ' + pageWidth + 'px; height: ' + pageHeight + 'px;'">
                        <canvas class="pp" :class="{'ppv': page.visible}" :id="'pc' + i" :width="pageWidth" :height="pageHeight" v-observe-visibility="visibilityChanged"></canvas>
                    </div>
                </div>

            </div>

            

        </div>

        <div class="pdfv-header" />

    </div>
</template>

<script>
import v2lib from '../../v2lib';
import verified from '../../verified';
import * as pdfjsLib from 'pdfjs-dist';

export default {
    name: 'PreviewPopupNew',
    components: {
        
    },
    props: {
        
    },
    data() {
        return {
            previewData: undefined,


            pages: [],

            pageWidth: 900,
            pageHeight: 1350,


            pdfDocument: undefined,

            
        }
    },
    methods: {
        
        async loadEnvelope(envelopeId) {

            const envelope = await v2lib.getEnvelope(envelopeId);
            console.log('envelope', envelope);

            const uid = envelope.content.documents[0].files.attachments[0].uid;
            //const uid = envelope.content.documents[0].files.document.uid;
            let url = await verified.getDownloadUrl(envelope.id, envelope.documentId, uid);
            let data = await verified.downloadFile(url);
            this.previewData = data;

            this.readRawData(this.previewData);

        },

        loadPage(index) {

        },

        visibilityChanged(isVisible, entry) {
            if (!this.pdfDocument) return;
            //console.log('visible = ' + isVisible, entry);
            const index = Number(entry.target.id.replace('pc', ''));
            //console.log('index', index);

            this.pages[index].visible = isVisible;

            const self = this;
            setTimeout(function() {
                self.onChanged(index);
            }, 500);

            
        },

        onChanged(index) {
            this.goToPage(this.pages[index]);
        },

        loadInitialPages() {
            const initalPageCount = 2;
            let count = 0;
            for (const i in this.pages) {
                count += 1;
                if (count > initalPageCount) break;
                this.pages[i].visible = true;
                this.goToPage(this.pages[i]);
            }
        },

        readRawData(data) {
            console.log(data);
            this.showPdf = true;
            this.loadPdf({data: Buffer.from(data, 'base64')});
        },

        loadPdf(data) {
            const self = this;

            pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';

            // Loading a document.
            var loadingTask = pdfjsLib.getDocument(data);
            loadingTask.promise.then(function(pdfDocument) {

                self.pdfDocument = pdfDocument;
                //self.pages = pdfDocument.numPages || 1;

                // Request a first page
                self.loadInitialPages();

            }).catch(function(reason) {
                console.error("Error: " + reason);
            });
        },

        goToPage(page) {
            console.log('going to page ', page);
            const width = 900;
            return this.pdfDocument.getPage(page.index).then(function(pdfPage) {

                // Display page on the existing canvas with 100% scale.
                var canvas = document.getElementById('pc' + (page.index - 1));
                canvas.width = width;

                var scale = canvas.width / pdfPage.getViewport({scale: 1.0}).width;
                var viewport = pdfPage.getViewport({scale: scale});
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                //console.log('w = ' + canvas.width + ' h= ' + canvas.height);
                var ctx = canvas.getContext("2d");
                var renderTask = pdfPage.render({
                    canvasContext: ctx,
                    viewport: viewport,
                });
                return renderTask.promise;

            });
        },

    },
    async created() {
        
        for (let i=0; i<20; i++) {

            this.pages.push({
                index: i + 1,
                visible: false
            });

        }

    }
}
</script>
<style scoped>

.pdfv-base {
    position: fixed;
    top: 0px; left: 0px;
    bottom: 0px; right: 0px;
}

.pdfv-bg {
    position: absolute;
    top: 0px; left: 0px;
    bottom: 0px; right: 0px;
    background-color: green;
    opacity: 0.7;
}

.pdfv-content {
    position: absolute;
    top: 60px; left: 0px;
    bottom: 0px; right: 0px;
    overflow: auto;
}

.pdfv-pages {
    margin: 0 auto;
    padding-top: 20px;
}

.pdfv-header {
    position: absolute;
    top: 0px; left: 0px; right: 0px;
    height: 60px;
    background-color: yellow;
}

.px {
    
}

.pp {
    border: solid 1px red;
}

.ppv {
    background-color: yellow;
}


</style>