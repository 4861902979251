<template>
<div style="color: var(--fb-text-4); padding: 5px 12px;">

    {{ 'No options for this module' }}

</div>
</template>
<script>
import DesignerGroup from '../../designer/ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../../designer/ui/inputs/DesignerLabel.vue';
import DesignerCheckbox from '../../designer/ui/inputs/DesignerCheckbox.vue';

export default {
    name: "KarModuleBox",
    components: {
        DesignerGroup,
        DesignerLabel,
        DesignerCheckbox,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() {
        return {
            
        }
    },
    methods: {

    },
    created() {
        
    }
}

</script>