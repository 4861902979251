<template>
<div>
    <!-- <FileReaderView :title="'Open'" :initalLimit="500000" /> -->

    <div style="width: 300px; margin: 0 auto; margin-top: 40px; display: flex;">
        <vap-input v-model="orgnr" />
        <vap-button :text="'Get map'" :working="working" @clicked="getCompany" style="width: 250px; margin-left: 10px;" />
    </div>

    <OwnershipMap :mapData="mapData" />
    
</div>
</template>
<script>
import mapNoApi from './mapNoApi';
import mapNoHelper from './mapNoHelper';

import FileReaderView from '../components/special/FileReaderView';

import OwnershipMap from './OwnershipMap.vue';

export default {
    name: 'MapNoView',
    components: {
        FileReaderView,
        OwnershipMap,
    },
    data() {
        return {
            orgnr: '989774697',
            working: false,
            items: [],
            mapData: null,
            demoCompanies: [
                { org: 915902170, display: 'Blinq AS' },
                { org: 998547148, display: 'Garanti Eiendomsmegling Norge AS' },
                { org: 989774697, display: 'Heimdal Forvaltning AS' },
                { org: 967948748, display: 'Itera Norge AS' },
                { org: 914852323, display: 'Aamodt AS' },
                { org: 995556200, display: 'Kløfta Bilservice AS' },
            ],
        }
    },
    methods: {

        async getCompany() {
            this.working = true;

            const result = await mapNoApi.getCompany(this.orgnr);
            console.log('result', result);

            mapNoHelper.processCompany(result, this);

            this.working = false;
        },
        
        //#region FileReaderView Interface ------------------------------------------------------------------------------------

            // Code for importing a new year into the database

        onGetColumns() {
            return [
                { key: 'Orgnr', name: 'Orgnr', width: 10 },
                { key: 'Selskap', name: 'Selskap', width: 10 },
                { key: 'Aksjeklasse', name: 'Aksjeklasse', width: 10 },
                { key: 'Navn aksjonær', name: 'Navn aksjonær', width: 10 },
                { key: 'Fødselsår/orgnr', name: 'Fødselsår/orgnr', width: 10 },
                { key: 'Postnr/sted', name: 'Postnr/sted', width: 10 },
                { key: 'Landkode', name: 'Landkode', width: 10 },
                { key: 'Antall aksjer', name: 'Antall aksjer', width: 10 },
                { key: 'Antall aksjer selskap', name: 'Antall aksjer selskap', width: 10 },
            ]
        },

        onFileDataLoaded(list) {
            for (const i in list) {
                this.items.push({
                    org: list[i]['Orgnr'],
                    name: list[i]['Selskap'],
                    cls: list[i]['Aksjeklasse'],
                    nm: list[i]['Navn aksjonær'],
                    id: list[i]['Fødselsår/orgnr'],
                    loc: list[i]['Postnr/sted'],
                    cc: list[i]['Landkode'],
                    sc: list[i]['Antall aksjer'],
                    st: list[i]['Antall aksjer selskap'],
                });
            }
            console.log('items', this.items);
            this.processItems();
        },

        async processItems() {
            const batchSize = 25000;

            let count = 0;
            while (count < this.items.length) {
                const batch = this.items.slice(count, count + batchSize);

                await mapNoApi.addData(batch);

                await new Promise(r => setTimeout(r, 1000));
                count += batchSize;
            }

        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    async created() {
        

    }
}
</script>