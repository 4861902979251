<template>
    <div class="frv2-fm">

        <div class="frv2-fm-actions">
            

            <FormMenuButton
                :icon="'save'"
                :working="saving"
                @clicked="$emit('save')"
                style="margin-top: 12px; margin-right: 340px;"
            >
                <template v-slot:tooltip>
                    <vap-tooltip :text="'Save'" />
                </template>
            </FormMenuButton>


            <FormMenuButton
                :icon="'bug'"
                :disabled="!replaceEnabled"
                @clicked="$emit('replace')"
                style="margin-top: 12px; margin-right: 10px;"
            >
                <template v-slot:tooltip>
                    <vap-tooltip :text="'Replace'" />
                </template>
            </FormMenuButton>


            <FormMenuButton
                :icon="'add'" 
                :disabled="(!$root.config.forms.editor.advanced && !$root.credentials.isAdmin) || addDisabled"
                @clicked="$emit('add')" 
                style="margin-top: 12px; margin-right: 4px; margin-right: 10px;"
            >
                <template v-slot:tooltip>
                    <vap-tooltip :text="'Add step'" />
                </template>
            </FormMenuButton>


        </div>

    </div>
</template>

<script>

import FormMenuButton from '../components/form/designer/FormMenuButton.vue';

export default {
    name: 'FlowMenu',
    components: {
        FormMenuButton
    },
    props: {
        designer: { type: Boolean, default: false },
        roleOptions: { type: Array, default: null },
        saving: { type: Boolean, default: false },
        phoneMode: { type: Boolean, default: false },
        canUndo: { type: Boolean, default: false },
        canRedo: { type: Boolean, default: false },
        addDisabled: { type: Boolean, default: false },
        replaceEnabled: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        

    },
    async created() {
        
    }
}
</script>
<style scoped>

.frv2-fm {
    float: left;
    display: flex;
    width: 100%;
    height: 62px;
    border-bottom: 2px solid var(--input-border);
    background-color: var(--stepper-bg);
    box-sizing: border-box;
}

.frv2-fm-actions {
    flex-grow: 100;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 50px;
}

</style>
