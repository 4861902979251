<template>
<div class="popv1" @keydown="handleKeyDown" :class="[
    {'popv1-adjust': maxHeight > 0 },
    {'popv1-nopadding': noPadding },
    {'popv1-designer': designer },
    {'popv1-full': fullscreen },
]">
    <div class="popv1-bg" @click="handleClose(true)" />
    <div class="popv1-center" :style="'width:' + width + 'px;'">


        <div class="popv1-container" :style="'margin-top: ' + topOffset + 'px;'">

            <div class="popv1-header">
                {{ header }}

                <div class="popv1-close" @click.stop="handleClose(false)">
                    <vap-icon :icon="'cancel'" tiny absolute :top="1" :right="2" :color="'white'" />
                </div>

            </div>

            <div class="popv1-content" :class="{'popv1-content-form': formContainer}">
                <slot name="content"></slot>
            </div>

            <div class="popv1-footer">
                <div class="popv1-status" v-if="dialog && dialog.working && dialog.status && !dialog.error">
                    {{ dialog.status + '...' }}
                </div>
                <div class="popv1-error" v-if="dialog && dialog.error">
                    {{ dialog.error }}
                </div>
                <VFButtonRow alignRight :flexMode="flexMode" :designerColor="designer">
                    <slot name="buttons"></slot>
                </VFButtonRow>
            </div>

        </div>


    </div>
</div>
</template>
<script>
import VFButtonRow from '../components/form/items/VFButtonRow.vue';

export default {
    name: 'PopupV1',
    components: {
        VFButtonRow
    },
    props: {
        header: { type: String, default: '' },
        width: { type: Number, default: 550 },
        maxHeight: { type: Number, default: 0 },
        dialog: { type: Object, default: null },
        noPadding: { type: Boolean, default: false },
        formContainer: { type: Boolean, default: false },
        flexMode: { type: Boolean, default: false },
        preventClosing: { type: Boolean, default: false },
        designer: { type: Boolean, default: false },
        fullscreen: { type: Boolean, default: false },
    },
    data() {
        return {
            topOffset: 0
        }
    },
    methods: {

        handleClose(outside) {
            if (this.preventClosing && outside) return;
            if (this.dialog && this.dialog.working) return;
            this.$emit("close", true);
        },

        handleScroll(event) {
            const doc = document.documentElement;
            this.topOffset = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        },

        handleKeyDown(event) {
            if (event.keyCode == 13) {

                // Enter was pressed
                this.$emit("enterkey", true);

            }else if (event.keyCode == 27) {

                // Escape was pressed
                this.handleClose(true);

            }
        },

    },
    mounted() {
        const doc = document.documentElement;
        this.topOffset = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>
<style scoped>

.popv1 {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    z-index: 100;
    box-sizing: border-box;
}

.popv1-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.6;
}

.popv1-center {
    position: relative;
    padding-top: 100px;
    min-width: 100px;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100px;
    box-sizing: border-box;
}

.popv1-container {
    position: relative;
    float: left;
    width: 100%;
    margin: 0 auto;
    min-height: 100px;
    overflow: visible;
    border-radius: 5px;
    box-sizing: border-box;
}

.popv1-header {
    float: left;
    width: 100%;
    padding: 20px;
    font-size: 20px;
    color: var(--text-title);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: var(--card-background);
    box-sizing: border-box;
}

.popv1-close {
    position: absolute;
    top: 20px; right: 20px;
    width: 26px; height: 26px;
    border-radius: 50%;
    background-color: lightgray;
    box-sizing: border-box;
    cursor: pointer;
}

.popv1-content {
    float: left;
    width: 100%;
    min-height: 20px;
    padding: 0px 20px;
    padding-bottom: 20px;
    background-color: var(--card-background);
    box-sizing: border-box;
}
.popv1-content-form {
    margin-bottom: -10px;
    padding-bottom: 0px;
}

.popv1-footer {
    float: left;
    width: 100%;
    padding: 20px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: var(--page-background);
    box-sizing: border-box;
}

.popv1-status {
    padding-bottom: 10px;
}

.popv1-error {
    padding-right: 2px;
    padding-bottom: 10px;
    text-align: right;
    font-size: 15px;
    font-weight: 500;
    color: var(--val-error);
}



.popv1-adjust .popv1-content {
    max-height: 500px;
    overflow: auto;
}

.popv1-nopadding .popv1-content {
    padding: 0px;
}

/* Fullscreen */

.popv1-full .popv1-center {
    padding-top: 0px;
}

.popv1-full .popv1-container {
    border-radius: 0px;
    max-height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    background-color: yellow;
}

.popv1-full .popv1-header {
    flex-shrink: 0;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.popv1-full .popv1-content {
    flex-grow: 100;
    max-height: initial;
}

.popv1-full .popv1-footer {
    flex-shrink: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

/* Designer Colors */

.popv1-designer .popv1-container {
    -webkit-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
}

.popv1-designer .popv1-header {
    color: white;
    background-color: var(--fb-header);
}

.popv1-designer .popv1-content {
    background-color: var(--fb-menu-b);
    scrollbar-color: var(--fb-scroll-f) var(--fb-scroll-b);
}
.popv1-designer .popv1-content::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
.popv1-designer .popv1-content::-webkit-scrollbar-thumb {
    background: var(--fb-scroll-f);
    border-radius: 5px;
}
.popv1-designer .popv1-content::-webkit-scrollbar-track {
    background: var(--fb-scroll-b);
}

.popv1-designer .popv1-close {
    background-color: var(--fb-menu-c);
}

.popv1-designer .popv1-footer {
    background-color: var(--fb-menu-c);
}

.popv1-designer .popv1-status {
    color: white;
}

</style>