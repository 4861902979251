<template>
<div>

    <PopupV1 designer :header="'Connect to system'" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <ConnectionList :items="options" :params="params" @item="onConnectItem" @itemkey="onConnectItemKey" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>
    
</div>
</template>
<script>
import logicHelper from '../logicHelper';

import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFButton from '../items/VFButton.vue';

import ConnectionList from './ConnectionList.vue';

export default {
    name: 'SystemDialog',
    components: {
        PopupV1,
        VFButton,
        ConnectionList,
    },
    props: {
        params: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: {},
            options: []
        }
    },
    methods: {
        
        onConnectItem(result) {
            console.log('result', result);

            if (result.conn == 'date-obj') {
                
                logicHelper.prepareItem(this.reference, this.params.fromItem, 'field');
                this.$set(this.params.fromItem.logic, 'field', {
                    type: 'sys',
                    key: 'date-obj'
                });

            }else if (result.conn == 'user-name') {
                
                logicHelper.prepareItem(this.reference, this.params.fromItem, 'field');
                this.$set(this.params.fromItem.logic, 'field', {
                    type: 'sys',
                    key: 'user-name'
                });

            }else if (result.conn == 'submit') {
                
                logicHelper.prepareItem(this.reference, this.params.fromItem, 'action');
                this.$set(this.params.fromItem.logic, 'action', {
                    type: 'sys',
                    key: 'submit'
                });

            }else if (result.conn == 'formvalid') {

                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'disabled');
                    this.params.fromItem.logic.disabled.keys.push({
                        type: 'val', key: 'form', rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'visible');
                    this.params.fromItem.logic.visible.keys.push({
                        type: 'val', key: 'form', rev: result.reversed
                    });

                }

            }else if (result.conn == 'analog') {

                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'disabled');
                    this.params.fromItem.logic.disabled.keys.push({
                        type: 'sys', key: 'analog', rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'visible');
                    this.params.fromItem.logic.visible.keys.push({
                        type: 'sys', key: 'analog', rev: result.reversed
                    });

                }

            }
            this.$emit('reselect');
            this.$emit('close');
        },

        onConnectItemKey(result) {
            console.log('result', result);

            if (result.conn == 'owner-editor') {

                logicHelper.prepareItem(this.reference, this.params.fromItem, 'link');
                this.params.fromItem.logic.link.push({
                    type: 'sys',
                    link: 'owner-editor',
                    key: result.key
                });

            }else if (result.conn == 'revalidate') {

                logicHelper.prepareItem(this.reference, this.params.fromItem, 'action');
                this.$set(this.params.fromItem.logic, 'action', {
                    type: 'sys',
                    key: 'valform'
                });

            }

            this.$emit('reselect');
            this.$emit('close');
        },
        
    },
    created() {
        console.log('params', this.params);
        if (this.params.type == 'connect' || true == true) {
            const type = this.params.fromItem.type;
            
            // Add possible connections
            if (type == 'date') {
                // System data
                this.options.push({ type: 'field', conn: 'date-obj', icon: 'input-date', text: 'Date',
                    hint: 'This input will be auto-filled with the current date'
                });
            }
            if (type == 'text') {
                // User - Full name
                this.options.push({ type: 'field', conn: 'user-name', icon: 'person', text: 'User - Full name',
                    hint: 'This input will be auto-filled with the full name of the logged in user'
                });
            }
            if (type == 'btn') {
                // Submit form
                this.options.push({ type: 'action', conn: 'submit', icon: 'open', text: 'Submit form',
                    hint: 'This action will trigger form submission'
                });
            }
            if (type == 'check') {
                // Owner is editor
                this.options.push({ type: 'link', conn: 'owner-editor', icon: 'input-check', text: 'Treat owner as editor',
                    hint: 'Owner will be treated as an editor, and inputs will be required'
                });
                // Action
                this.options.push({ type: 'action', conn: 'revalidate', icon: 'input-check', text: 'Trigger validation',
                    hint: 'Trigger a complete re-validation of the form'
                });
            }
            
            // Form valid
            this.options.push({ type: 'trigger', conn: 'formvalid', icon: 'check', text: 'Form valid',
                hint: 'Trigger input properties if the form is valid'
            });

            // Analog mode
            this.options.push({ type: 'trigger', conn: 'analog', icon: 'sign', text: 'Analog mode',
                hint: 'Trigger input properties if the form is in analog mode'
            });

        }
    }
}
</script>