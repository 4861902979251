<template>
<div class="rrpv2">

    <div class="rrpv2-head">

        <div class="rrpv2-icon">
            <vap-icon :icon="'person'" />
        </div>

        <div class="rrpv2-info">
            <div class="rrpv2-name">
                {{ item.person.displayName }}
            </div>
            <div class="rrpv2-roles" v-html="getDisplayRoles(item.person)" />
        </div>

    </div>

    <div class="rrpv2-content">
        <ReportResultItem
            :icon="'card'"
            :title="__t('identity-idc')"
            :status="getIdcStatus()"
            :text="getIdcText()"
            :hasReceipt="item.person.idc.done && item.person.idc.method != 'manual'"
            @receipt="showIDCReceipt"
        />
        <ReportResultItem
            :icon="'pep'"
            :title="__t('screening-person')"
            :status="getScrStatus()"
            :text="getScreeningResult()"
            :hasReceipt="item.person.screening.done"
            @receipt="showScreeningReceipt"
        />
        <ReportResultItem v-if="$root.config.adverseMedia.enabled"
            :icon="'search'"
            :title="__t('ams-name')"
            :status="getAmsStatus()"
            :text="hitText"
        />
    </div>
    
</div>
</template>
<script>
import v1personHelper from '../v1helpers/v1personHelper';
import previewHelper from '../v1files/previewHelper';

import ReportResultItem from './ReportResultItem.vue';

export default {
    name: 'ReportRole',
    components: {
        ReportResultItem
    },
    props: {
        item: { type: Object, default: null },
        disabled: { type: Boolean, default: false }
    },
    data() {
        return {
            indicator: 0,
            hitLevel: '',
            hitText: '',
        }
    },
    methods: {

        showScreeningReceipt() {
            const previewParams = previewHelper.getScreeningReceiptParams(this.$root, this.item.person);
            this.$emit('preview', previewParams);
        },

        showIDCReceipt() {
            const previewParams = previewHelper.getIDCReceiptParams(this.$root, this.item.person);
            this.$emit('preview', previewParams);
        },
        
        getScreeningResult() {
            return v1personHelper.getScreeningResult(this.$root, this.item.person);
        },

        //#region Roles -------------------------------------------------------------------------------------------------------

        getDisplayRoles(person) {
            return v1personHelper.getDisplayRoles(person, this.$root);
        },

        getRolePercentage(person, list) {
            return v1personHelper.getRolePercentage(person, list.id);
        },

        getMatchDescription(person) {
            if (person.idc.done && person.idc.method == 'manual') {
                return this.__t('method-manual') + ' - ' + (person.idc.comment || this.__t('common-no-comment'));
            }
            return v1personHelper.getIdcMatchDescription(person);
        },

        getAmsStatus() {
            if (this.hitLevel == 'ok') return 1;
            if (this.hitLevel == 'some') return 2;
            if (this.hitLevel == 'hits') return 3;
            return 0;
        },

        getScrStatus() {
            if (this.item.person.screening.done && ((!this.item.person.screening.isPep && !this.item.person.screening.hasSanction) || this.item.person.screening.falsePositive)) return 1;
            if (this.item.person.screening.done && (this.item.person.screening.isPep && !this.item.person.screening.hasSanction)) return 2;
            if (this.item.person.screening.done && (this.item.person.screening.hasSanction)) return 3;
            return 0;
        },

        getIdcStatus() {
            if (this.item.person.idc.done && (this.indicator == 1 || this.indicator == 2)) return 1;
            if (this.item.person.idc.done && (this.indicator == 3 || this.indicator == 4)) return 2;
            if (this.item.person.idc.done && (this.indicator == 0 || this.indicator == 5 || this.indicator == 6 || this.indicator == 7)) return 3;
            return 0;
        },

        getIdcText() {
            if (this.item.person.idc.done) {
                return this.getMatchDescription(this.item.person);
            }else {
                return this.__t('screening-not-completed');
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {
        this.indicator = v1personHelper.getIdcMatchIndicator(this.item.person);
        this.hitLevel = v1personHelper.getAmsHitLevel(this.item.person.web);
        this.hitText = v1personHelper.getAmsResultText(this.$root, this.item.person.web, this.$root.locale.key);
        if (this.hitText == 'error') this.hitText = this.__t('screening-not-completed');
    }
}
</script>
<style scoped>

.rrpv2 {
    float: left;
    width: 100%;
}

.rrpv2-head {
    float: left;
    width: 100%;
    display: flex;
    padding: 10px;
}

.rrpv2-info {
    padding-left: 7px;
}
.rrpv2-icon {
    padding-top: 3px;
    padding-left: 3px;
}
.rrpv2-name {
    font-size: 18px;
}

.rrpv2-content {
    float: left;
    width: 100%;
    padding: 0px 15px;
    padding-bottom: 12px;
    box-sizing: border-box;
}

</style>