<template>
<div class="flw100" style="padding: 20px 20px;">
    
    <div class="flw100" style="padding: 10px 10px; padding-bottom: 20px;">
        {{ __('customer_new_se') }}
    </div>

    <vap-row v-if="!hasResult && !hasSelected" style="margin-top: 10px;">
        <vap-group c12>
            <vap-label :text="__('customer_bolag_hint')" desc />
            <vap-input v-model="searchString" noValidation :placeholder="__('common_required')" :disabled="working" @onreturn="onSearch" />
        </vap-group>
    </vap-row>

    <div v-if="error" style="color: var(--val-error); text-align: center; margin-bottom: 20px;">
        {{ 'Trapets/Bisnode is currently unavailable!' }}
    </div>

    <vap-button-row v-if="!hasResult && !hasSelected" alignCenter>
        <vap-button :text="__('common_search')" action uppercase :working="working" @clicked="onSearch" />
    </vap-button-row>

</div>
</template>
<script>
import dataSwedenV1 from '../v1data/dataSwedenV1';

export default {
    name: 'SearchSE',
    data() {
        return {
            searchString: '',
            working: false,
            hasResult: false,
            resultList: [],
            hasSelected: false,
            name: '',
            orgnr: '',
            error: false
        }
    },
    methods: {
        
        async onSearch() {
            this.working = true;
            this.error = false;

            const result = await dataSwedenV1.getTrapetsData(this.searchString);
            console.log('result', result);

            if (!result.success) {
                this.error = true;
                this.working = false;
                return;
            }

            // Get address
            let address = '';
            try {
                for (const i in result.data.ResponseItems[0].Entities) {
                    const entity = result.data.ResponseItems[0].Entities[i];
                    for (const j in entity.Addresses) {
                        const adr = entity.Addresses[j];
                        if (adr.Street) {
                            address = adr.Street + ', ' + adr.ZipCode + ' ' + adr.City;
                        }
                    }
                }
            }catch (ex) {
                address = '';
            }

            const selectedCompany = {
                name: result.data.ResponseItems[0].Entities[0].Name,
                identity: result.data.ResponseItems[0].Entities[0].CorporateId,
                address: address,
                country: 'SE',
                source: 'trapets-bisnode',
                trapetsData: result.data
            };
            this.$emit('result', selectedCompany);

            this.hasSelected = true;
            this.working = false;
        },

    },
    async created() {
        
    }
}
</script>