const noOrgBaml = {

    getFlow() {
        return {
            key: 'no-org-baml',
            name: 'BankID AML Organization Flow',
            type: 'org',
            countries: 'no',
            tabs: {
                overview: { key: 'common_overview', icon: 'checklist' },
                company: { key: 'company_company', icon: 'company' },
                ownership: { key: 'company_owner_structure', icon: 'orgchart' },
                persons: { key: 'common_persons', icon: 'people' },
                report: { key: 'company_final_report', icon: 'verified' }
            },
            title: {
                en_EN: 'Company in the Brønnøysund registers',
                nb_NO: 'Foretak i Brønnøysundregistrene',
                sv_SE: 'Företag i Brønnøysundsregistren',
            },
            steps: [
                { key: 'no-baml-org' },
                { key: 'no-baml-kyc' },
                /* { key: 'no-book' }, */
                { key: 'no-baml-per' },
                { key: 'no-baml-rep' }
            ],
            statuses: [
                {
                    key: 'c-org-c',
                    text: {
                        en_EN: 'Awaiting company search',
                        nb_NO: 'Avventer selskapssøk',
                        sv_SE: 'Väntar på företagssökning'
                    },
                    triggers: []
                },
                {
                    key: 'f-kyc-a',
                    text: {
                        en_EN: 'Awaiting sending self-declaration',
                        nb_NO: 'Avventer utsendelse egenerklæring',
                        sv_SE: 'Väntar på att kundkännedom skickas'
                    },
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: false },
                    ]
                },
                {
                    key: 'f-kyc-b',
                    text: {
                        en_EN: 'Awaiting filling self-declaration',
                        nb_NO: 'Avventer utfylling egenerklæring',
                        sv_SE: 'Väntar på att kundkännedom ska slutföras'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                    ]
                },
                {
                    key: 'f-kyc-c',
                    text: {
                        en_EN: 'Awaiting signing self-declaration',
                        nb_NO: 'Avventer signering egenerklæring',
                        sv_SE: 'Väntar på undertecknande av kundkännedom'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'filled', rev: false },
                    ]
                },
                {
                    key: 'f-kyc-k',
                    text: {
                        en_EN: '! - Self-declaration rejected',
                        nb_NO: '! - Egenerklæring avvist',
                        sv_SE: '! - Kundkännedom avvisad'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'rejected', rev: false },
                    ]
                },
                {
                    key: 'f-kyc-l',
                    text: {
                        en_EN: '! - Self-declaration aborted',
                        nb_NO: '! - Egenerklæring avbrutt',
                        sv_SE: '! - Kundkännedom avbruten'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'aborted', rev: false },
                    ]
                },
                {
                    key: 'f-kyc-m',
                    text: {
                        en_EN: '! - Self-declaration bounced',
                        nb_NO: '! - Egenerklæring ikke levert (bounced)',
                        sv_SE: '! - Kundkännedom inte skickats (bounced)'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'bounced', rev: false },
                    ]
                },
                {
                    key: 'r-rep-a',
                    text: {
                        en_EN: 'Awaiting controls',
                        nb_NO: 'Avventer kontroller',
                        sv_SE: 'Väntar på kontroller'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'signed', rev: false },
                    ]
                },
                {
                    key: 'x-com-a',
                    completed: true,
                    text: {
                        en_EN: 'Completed',
                        nb_NO: 'Fullført',
                        sv_SE: 'Färdiga'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ]
                },
                {
                    key: 'z-ch-c',
                    completed: false,
                    text: {
                        en_EN: '! - Changed after completion',
                        nb_NO: '! - Endret etter ferdigstillelse',
                        sv_SE: '! - Ändrad efter färdigställande'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                        { type: 'report', trg: 'changed', rev: false },
                    ]
                }
            ],
            events: {
                items: [
                    {
                        key: 'ev-client-created',
                        text: {
                            en_EN: 'Customer created by {{createdUser}}',
                            nb_NO: 'Kunde opprettet av {{createdUser}}',
                            sv_SE: 'Customer created by {{createdUser}}'
                        },
                        date: { type: 'client', date: 'createdDate' },
                        triggers: [
                            { type: 'client', trg: 'created', rev: false },
                        ]
                    },
                    {
                        key: 'event01',
                        text: {
                            en_EN: 'Company search performed by {{createdUser}}',
                            nb_NO: 'Selskapssøk utført av {{createdUser}}',
                            sv_SE: 'Företagssökning utförd av {{createdUser}}'
                        },
                        date: { type: 'org', date: 'searchDate' },
                        triggers: [
                            { type: 'org', trg: 'has-data', rev: false },
                        ]
                    },
                    {
                        key: 'event02',
                        text: {
                            en_EN: 'Self declaration form sent by {{kyc00-senderName}}',
                            nb_NO: 'Egenerklæringsskjema sendt ut av {{kyc00-senderName}}',
                            sv_SE: 'Kundkännedom skickas ut av {{kyc00-senderName}}'
                        },
                        date: { type: 'form', key: 'kyc00', date: 'sentDate' },
                        triggers: [
                            { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                        ]
                    },
                    {
                        key: 'event03',
                        text: {
                            en_EN: 'Self declaration filled by {{kyc00-editorName}}',
                            nb_NO: 'Egenerklæringsskjema fylt ut av {{kyc00-editorName}}',
                            sv_SE: 'Kundkännedom ifylld av {{kyc00-editorName}}'
                        },
                        date: { type: 'form', key: 'kyc00', date: 'filledDate' },
                        triggers: [
                            { type: 'form', key: 'kyc00', trg: 'filled', rev: false },
                        ]
                    },
                    {
                        key: 'event04',
                        text: {
                            en_EN: 'Self declaration form signed by all signers',
                            nb_NO: 'Egenerklæringsskjema signert av alle signatarer',
                            sv_SE: 'Kundkännedom undertecknat av alla undertecknare'
                        },
                        date: { type: 'form', key: 'kyc00', date: 'signedDate' },
                        triggers: [
                            { type: 'form', key: 'kyc00', trg: 'signed', rev: false },
                        ]
                    },
                    {
                        key: 'event04b',
                        text: {
                            en_EN: '',
                            nb_NO: 'Egenerklæringsskjema signert analogt',
                            sv_SE: ''
                        },
                        date: { type: 'form', key: 'kyc00', date: 'signedDate' },
                        triggers: [
                            { type: 'form', key: 'kyc00', trg: 'analog-signed', rev: false },
                        ]
                    },
                    {
                        key: 'event05',
                        list: 'ams',
                        text: {
                            en_EN: 'Adverse media search completed for {{event-name}}',
                            nb_NO: 'KYC-søk utført for {{event-name}}',
                            sv_SE: 'AMS'
                        },
                        date: { type: 'ams', date: 'date' },
                        triggers: []
                    },
                    {
                        key: 'event06',
                        list: 'idc',
                        text: {
                            en_EN: 'ID-control completed by {{idc-name}}',
                            nb_NO: 'ID-kontroll fullført av {{idc-name}}',
                            sv_SE: 'ID-kontroll genomförd av {{idc-name}}',
                        },
                        date: { type: 'idc', date: 'date' },
                        triggers: [
                            { type: 'idc', trg: 'done', rev: false },
                        ]
                    },
                    {
                        key: 'event07',
                        list: 'idc-sent',
                        text: {
                            en_EN: 'ID-control sent to {{event-name}}',
                            nb_NO: 'ID-kontroll sendt til {{event-name}}',
                            sv_SE: 'ID-kontroll skickas till {{event-name}}',
                        },
                        date: { type: 'reminder', date: 'date' },
                        triggers: []
                    },
                    {
                        key: 'event08',
                        list: 'idc-rem',
                        text: {
                            en_EN: 'Reminder of ID-control sent to {{event-name}}',
                            nb_NO: 'Påminnelse om ID-kontroll sendt til {{event-name}}',
                            sv_SE: 'Påminnelse om ID-kontroll skickas till {{event-name}}',
                        },
                        date: { type: 'reminder', date: 'date' },
                        triggers: []
                    },
                    {
                        key: 'event09',
                        list: 'scr',
                        text: {
                            en_EN: 'Screening performed on {{scr-name}}',
                            nb_NO: 'PEP/Sanksjonsøk utført på {{scr-name}}',
                            sv_SE: 'Screening utförs på {{scr-name}}',
                        },
                        date: { type: 'scr', date: 'date' },
                        triggers: [
                            { type: 'scr', trg: 'done', rev: false },
                        ]
                    },
                    {
                        key: 'event10',
                        text: {
                            en_EN: 'Final report created by {{reportUser}}',
                            nb_NO: 'Sluttrapport fullført av {{reportUser}}',
                            sv_SE: 'Slutrapport skapad av {{reportUser}}',
                        },
                        date: { type: 'report', date: 'createdDate' },
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ]
                    },
                    {
                        key: 'event11',
                        list: 'form-rem-edit',
                        listForm: 'kyc00',
                        text: {
                            en_EN: 'Reminder of self-declaration for filling sent to {{event-name}}',
                            nb_NO: 'Påminnelse om egenerklæring for utfylling sent til {{event-name}}',
                            sv_SE: 'Påminnelse om kundkännedom för fyllning skickas till {{event-name}}',
                        },
                        date: { type: 'reminder', date: 'date' },
                        triggers: []
                    },
                    {
                        key: 'event12',
                        list: 'form-rem-sign',
                        listForm: 'kyc00',
                        text: {
                            en_EN: 'Reminder of self-declaration for signing sent to {{event-name}}',
                            nb_NO: 'Påminnelse om egenerklæring for signering sent til {{event-name}}',
                            sv_SE: 'Påminnelse om kundkännedom för signering skickas till {{event-name}}',
                        },
                        date: { type: 'reminder', date: 'date' },
                        triggers: []
                    },
                    {
                        key: 'event13',
                        text: {
                            en_EN: 'Adverse media search completed for the company',
                            nb_NO: 'KYC-søk fullført for foretaket',
                            sv_SE: 'Adverse media search completed for the company',
                        },
                        date: { type: 'org', date: 'amsDate' },
                        triggers: [
                            { type: 'org', trg: 'ams-completed', rev: false },
                        ]
                    }
                ]
            },
            report: [
                {
                    key: 'org',
                    icon: 'company',
                    comment: true,
                    separation: false,
                    title: {
                        en_EN: 'Company search',
                        nb_NO: 'Selskapssøk'
                    },
                    requirements: [
                        {
                            key: 'org-search',
                            message: {
                                en_EN: 'Company search not performed',
                                nb_NO: 'Selskapssøk ikke utført'
                            },
                            triggers: [
                                { type: 'org', trg: 'has-data', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Company not assessed',
                                nb_NO: 'Selskapsdata ikke vurdert'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                },
                {
                    key: 'kyc',
                    icon: 'form',
                    comment: true,
                    separation: true,
                    includeFile: {
                        enabled: true,
                        formKey: 'kyc00',
                        name: 'Egenerklæring'
                    },
                    title: {
                        en_EN: 'Self-declaration',
                        nb_NO: 'Egenerklæring'
                    },
                    requirements: [
                        {
                            key: 'org-search',
                            message: {
                                en_EN: 'Self-declaration not completed and signed',
                                nb_NO: 'Egenerklæringsskjema ikke fullført'
                            },
                            conditions: [
                                { type: 'form', key: 'kyc00', trg: 'manual', rev: true },
                            ],
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Self-declaration not assessed',
                                nb_NO: 'Egenerklæring ikke vurdert'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ],
                    actions: [
                        {
                            icon: 'pdf',
                            main: false,
                            action: 'preview',
                            key: 'kyc00',
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'filled', rev: false },
                            ],
                            text: {
                                en_EN: 'Show receipt'
                            },
                            hint: {
                                en_EN: 'Open the PDF version of the form'
                            }
                        },
                    ],
                    items: [
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Not ready',
                                nb_NO: 'Ikke klar'
                            },
                            value: {
                                en_EN: 'Self-declaration not filled in and signed',
                                nb_NO: 'Egenerklæring ikke fylt ut og signert'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'signed', rev: true },
                                { type: 'form', key: 'kyc00', trg: 'manual', rev: true },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Signed by',
                                nb_NO: 'Signert av'
                            },
                            value: {
                                en_EN: '{{kyc00-signerNames}}',
                                nb_NO: '{{kyc00-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: '',
                                nb_NO: 'Lasted opp'
                            },
                            value: {
                                en_EN: '',
                                nb_NO: 'Lasted opp'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'manual', rev: false },
                                { type: 'form', key: 'kyc00', trg: 'analog', rev: true },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: '',
                                nb_NO: 'Signert analogt og bekreftet'
                            },
                            value: {
                                en_EN: '',
                                nb_NO: 'Signert analogt og bekreftet'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'analog-signed', rev: false },
                            ],
                        },
/*                         {
                            type: 'kyc-comparison',
                            width: '12'
                        } */
                    ]
                },
                {
                    key: 'kar',
                    icon: 'bank',
                    comment: true,
                    separation: true,
                    stepCondition: 'no-kar',
                    includeFile: {
                        enabled: true,
                        formKey: 'kar99',
                        name: 'KAR-oppslag'
                    },
                    title: {
                        en_EN: 'KAR-lookup',
                        nb_NO: 'KAR-oppslag'
                    },
                    requirements: [
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'KAR-lookup not assessed',
                                nb_NO: 'KAR-oppslag ikke vurdert'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ],
                    actions: [
                        {
                            icon: 'pdf',
                            main: false,
                            action: 'preview',
                            key: 'kar99',
                            triggers: [
                                { type: 'form', key: 'kar99', trg: 'signed', rev: false },
                            ],
                            text: {
                                en_EN: 'Show receipt'
                            },
                            hint: {
                                en_EN: 'Open the PDF version of the form'
                            }
                        },
                    ],
                    items: [
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Not performed',
                                nb_NO: 'Ikke utført'
                            },
                            value: {
                                en_EN: 'KAR-loopup not performed',
                                nb_NO: 'Det er ikke utført KAR-oppslag'
                            },
                            triggers: [
                                { type: 'form', key: 'kar99', trg: 'signed', rev: true },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '6',
                            validTextOnly: true,
                            text: {
                                en_EN: 'Identity',
                                nb_NO: 'Identitet'
                            },
                            value: {
                                en_EN: '{{kar99-karIdentity}}',
                                nb_NO: '{{kar99-karIdentity}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kar99', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '6',
                            validTextOnly: true,
                            text: {
                                en_EN: 'Account Number',
                                nb_NO: 'Kontonummer'
                            },
                            value: {
                                en_EN: '{{kar99-karAccount}}',
                                nb_NO: '{{kar99-karAccount}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kar99', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Result',
                                nb_NO: 'Resultat'
                            },
                            value: {
                                en_EN: '{{kar99-karMessage}}',
                                nb_NO: '{{kar99-karMessage}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kar99', trg: 'signed', rev: false },
                            ],
                        },
                    ]
                },
                {
                    key: 'roles',
                    icon: 'person',
                    comment: true,
                    separation: true,
                    title: {
                        en_EN: 'Key roles',
                        nb_NO: 'Nøkkelroller'
                    },
                    requirements: [
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Key roles not assessed',
                                nb_NO: 'Nøkkelroller ikke vurdert'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                },
                {
                    key: 'form',
                    icon: 'input-check',
                    comment: false,
                    separation: true,
                    configCondition: 'reportChecklist',
                    formKey: 'checklist01',
                    title: {
                        en_EN: 'Checklist',
                        nb_NO: 'Sjekkliste'
                    },
                    requirements: [
                        {
                            key: 'report-form',
                            message: {
                                en_EN: 'Checklist not completed',
                                nb_NO: 'Sjekkliste ikke fullført'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'form-valid', rev: false },
                            ],
                        },
                    ]
                },
                {
                    key: 'assessment',
                    icon: 'verified',
                    comment: true,
                    separation: true,
                    title: {
                        en_EN: 'Final assessment / risk assessment',
                        nb_NO: 'Totalvurdering'
                    },
                    requirements: [
                        {
                            key: 'report-risk',
                            message: {
                                en_EN: 'Risk classification not set',
                                nb_NO: 'Risikoklassifisering ikke satt'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'risk-set', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Final assessment not completed',
                                nb_NO: 'Mangler totalvurdering'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                }
            ]
        };
    },

    //#region Steps -------------------------------------------------------------------------------------------------------
    
    getSearchStep() {
        return {
            key: 'no-baml-org',
            isDefault: true,
            resetMode: 'company',
            name: 'Default BankID AML Company Search',
            actions: [
                {
                    icon: 'search',
                    main: true,
                    action: 'lookup',
                    key: 'org',
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: true },
                    ],
                    text: {
                        en_EN: 'Get data',
                        nb_NO: 'Hent data',
                        sv_SE: 'Hämta data',
                    },
                    hint: {
                        en_EN: 'Retrieve data from public registers and screen for sanctions against the company',
                        nb_NO: 'Hent data fra offentlige registre og gjør et sanksjonsøk på selskapet',
                        sv_SE: 'Hämta uppgifter från offentliga register och screena för sanktioner mot företaget',
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'preview',
                    key: 'org',
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt',
                    },
                    hint: {
                        en_EN: 'Show the report from BankID AML',
                        nb_NO: 'Vis rapport fra BankID AML',
                        sv_SE: 'Visa rapporten från BankID AML',
                    }
                },
                {
                    icon: 'refresh',
                    main: false,
                    action: 'org-refresh',
                    key: 'org',
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: false },
                    ],
                    text: {
                        en_EN: 'Reset',
                    },
                    hint: {
                        en_EN: 'Reset the company lookup so a new one can be made',
                        nb_NO: 'Tilbakestill selskapssøket slik at det kan gjøres et nytt',
                        sv_SE: 'Återställ företagssökningen så att en ny kan göras',
                    },
                    disabled: {
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ],
                        hint: {
                            en_EN: 'Customer is locked after the final report is created',
                            nb_NO: 'Kunden er låst etter sluttrapporten er laget',
                            sv_SE: 'Kunden är låst efter att slutrapporten skapats',
                        },
                    }
                },
            ],
            title: {
                en_EN: 'Company search',
                nb_NO: 'Organisasjonssøk',
            },
            hint: {
                en_EN: 'Retrieve data from public registers and screen for sanctions against the company',
                nb_NO: 'Hent data fra offentlige registre og gjør et sanksjonsøk på selskapet',
                sv_SE: 'Hämta uppgifter från offentliga register och screena för sanktioner mot företaget',
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{sanctionMessage}}',
                                nb_NO: '{{sanctionMessage}}',
                                sv_SE: '{{sanctionMessage}}',
                            },
                            triggers: [
                                { type: 'org', trg: 'has-data', rev: false },
                                { type: 'org', trg: 'has-sanction', rev: true },
                            ]
                        }
                    ]
                },
                warning: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Some data was not fetched',
                                nb_NO: 'Some data was not fetched',
                                sv_SE: 'Some data was not fetched',
                            },
                            triggers: [
                                { type: 'org', trg: 'missing-data', rev: false },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{sanctionMessage}}',
                                nb_NO: '{{sanctionMessage}}',
                                sv_SE: '{{sanctionMessage}}',
                            },
                            triggers: [
                                { type: 'org', trg: 'has-sanction', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getKycStep() {
        return {
            key: 'no-baml-kyc',
            isDefault: true,
            resetMode: 'form',
            name: 'Default BankID AML Self-Declaration Form',
            /* formList: true, */
            form: 'kyc00',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: true },
                        { type: 'form', key: 'kyc00', trg: 'manual', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form',
                        nb_NO: 'Åpne skjema',
                    },
                    hint: {
                        en_EN: 'Open the form'
                    }
                },
                {
                    icon: 'upload',
                    main: false,
                    action: 'form-upload',
                    key: 'kyc00',
                    configCondition: 'uploadKyc',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: true },
                        { type: 'form', key: 'kyc00', trg: 'manual', rev: true },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: '',
                        nb_NO: 'Last opp en eksisterende egenerklæring',
                        sv_SE: '',
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'form-pdf-analog',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'analog-signed', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt',
                    },
                    hint: {
                        en_EN: 'Show the PDF receipt',
                        nb_NO: 'Vis PDF-kvittering',
                        sv_SE: 'Visa PDF-kvittot',
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'form-pdf-manual',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'manual', rev: false },
                        { type: 'form', key: 'kyc00', trg: 'analog', rev: true },
                    ],
                    text: {
                        en_EN: 'Show receipt',
                    },
                    hint: {
                        en_EN: 'Show the PDF receipt',
                        nb_NO: 'Vis PDF-kvittering',
                        sv_SE: 'Visa PDF-kvittot',
                    }
                },
                {
                    icon: 'refresh',
                    main: false,
                    action: 'form-start',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: true },
                        { type: 'form', key: 'kyc00', trg: 'saved', rev: false },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: 'Start over again',
                        nb_NO: 'Start utfylling på nytt',
                        sv_SE: 'Börja om igen',
                    }
                },
                {
                    icon: 'open',
                    main: true,
                    action: 'form-amend',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: true },
                        { type: 'form', key: 'kyc00', trg: 'saved', rev: false },
                    ],
                    text: {
                        en_EN: 'Continue process',
                        nb_NO: 'Fortsett utfylling',
                        sv_SE: 'Fortsätt processen',
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'preview',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'filled', rev: false },
                        { type: 'form', key: 'kyc00', trg: 'analog-signed', rev: true },
                    ],
                    text: {
                        en_EN: 'Show receipt',
                    },
                    hint: {
                        en_EN: 'Show the PDF receipt',
                        nb_NO: 'Vis PDF-kvittering',
                        sv_SE: 'Visa PDF-kvittot',
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                        { type: 'form', key: 'kyc00', trg: 'signed', rev: true },
                        { type: 'form', key: 'kyc00', trg: 'analog', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder',
                    },
                    hint: {
                        en_EN: 'Send a reminder or change the recipient',
                        nb_NO: 'Send en påminnelse eller endre mottaker',
                        sv_SE: 'Skicka en påminnelse eller ändra mottagare',
                    }
                },
                {
                    icon: 'refresh',
                    main: false,
                    action: 'form-resend',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                        { type: 'form', key: 'kyc00', trg: 'analog', rev: true },
                    ],
                    disabled: {
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ],
                        hint: {
                            en_EN: 'Customer is locked after the final report is created',
                            nb_NO: 'Kunden er låst etter sluttrapporten er laget',
                            sv_SE: 'Kunden är låst efter att slutrapporten skapats',
                        },
                    },
                    text: {
                        en_EN: 'Resend',
                    },
                    hint: {
                        en_EN: 'Resend the form with filled data',
                        nb_NO: 'Send skjema på nytt med utfylt informasjon',
                        sv_SE: 'Skicka formuläret igen med ifyllda uppgifter',
                    }
                },
                {
                    icon: 'download',
                    main: false,
                    action: 'form-download',
                    key: 'kyc00',
                    configCondition: 'analogKyc',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'signed', rev: true },
                        { type: 'form', key: 'kyc00', trg: 'manual', rev: true },
                        { type: 'form', key: 'kyc00', trg: 'analog', rev: true },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: '',
                        nb_NO: 'Last ned en utfylt egenerklæring for utskrift',
                        sv_SE: '',
                    }
                },
                {
                    icon: 'sign',
                    main: false,
                    action: 'form-analog-confirm',
                    key: 'kyc00',
                    configCondition: 'analogKyc',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'analog', rev: false },
                        { type: 'form', key: 'kyc00', trg: 'analog-signed', rev: true },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: '',
                        nb_NO: 'Last opp/bekreft analog signatur',
                        sv_SE: '',
                    }
                },
                {
                    icon: 'update',
                    main: false,
                    action: 'form-update',
                    key: 'kyc00',
                    configCondition: 'updateButton',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                        { type: 'form', key: 'kyc00', trg: 'signed', rev: true },
                        { type: 'form', key: 'kyc00', trg: 'analog', rev: true },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: 'Update status',
                        nb_NO: 'Oppdater status',
                        sv_SE: 'Update status',
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'form-reset',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                    ],
                    disabled: {
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ],
                        hint: {
                            en_EN: 'Customer is locked after the final report is created',
                            nb_NO: 'Kunden er låst etter sluttrapporten er laget',
                            sv_SE: 'Kunden är låst efter att slutrapporten skapats',
                        },
                    },
                    text: {
                        en_EN: 'Reset form',
                    },
                    hint: {
                        en_EN: 'The form will reset so that a new one can be sent',
                        nb_NO: 'Skjema tilbakestilles slik at ett nytt kan sendes',
                        sv_SE: 'Formuläret återställs så att ett nytt kan skickas',
                    }
                }
            ],
            title: {
                en_EN: 'Self-Declaration form',
                nb_NO: 'Egenerklæringsskjema',
                sv_SE: 'Kundkännedom'
            },
            hint: {
                en_EN: 'Completed by the customer contact person and makes sure to get updated information about the company and key roles.',
                nb_NO: 'Fylles ut av kundens kontaktperson og sørger for å hente inn oppdatert data om foretaket og nøkkelroller.',
                sv_SE: 'Fylls i av kundens kontaktperson och ser till att samla in uppdaterad information om företaget och nyckelroller'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Started',
                                nb_NO: 'Påbegynt',
                                sv_SE: 'Satte igång'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'sent', rev: true },
                                { type: 'form', key: 'kyc00', trg: 'saved', rev: false },
                                { type: 'form', key: 'kyc00', trg: 'rejected', rev: true },
                                { type: 'form', key: 'kyc00', trg: 'aborted', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting filling by {{kyc00-nextName}}',
                                nb_NO: 'Avventer utfylling fra {{kyc00-nextName}}',
                                sv_SE: 'Väntar på färdigställande från {{kyc00-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                                { type: 'form', key: 'kyc00', trg: 'filled', rev: true },
                                { type: 'form', key: 'kyc00', trg: 'aborted', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting signing by {{kyc00-nextName}}',
                                nb_NO: 'Avventer signering fra {{kyc00-nextName}}',
                                sv_SE: 'Väntar på signering från {{kyc00-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'filled', rev: false },
                                { type: 'form', key: 'kyc00', trg: 'signed', rev: true },
                                { type: 'form', key: 'kyc00', trg: 'rejected', rev: true },
                                { type: 'form', key: 'kyc00', trg: 'aborted', rev: true },
                                { type: 'form', key: 'kyc00', trg: 'analog', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting confirmation of analog signature',
                                nb_NO: 'Avventer bekreftelse på analog signatur',
                                sv_SE: 'Väntar på bekräftelse av analog signatur'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'analog', rev: false },
                                { type: 'form', key: 'kyc00', trg: 'analog-signed', rev: true },
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Signed by {{kyc00-signerNames}}',
                                nb_NO: 'Signert av {{kyc00-signerNames}}',
                                sv_SE: 'Undertecknad av {{kyc00-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'signed', rev: false },
                            ]
                        },
                        {
                            text: {
                                en_EN: '',
                                nb_NO: 'Lastet opp',
                                sv_SE: ''
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'manual', rev: false },
                                { type: 'form', key: 'kyc00', trg: 'analog', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: '',
                                nb_NO: 'Signert analogt og bekreftet',
                                sv_SE: ''
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'analog-signed', rev: false },
                            ]
                        }
                    ]
                },
                warning: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Aborted',
                                nb_NO: 'Avbrutt',
                                sv_SE: 'Aborted'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'aborted', rev: false },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Rejected by {{kyc00-rejecterName}} - {{kyc00-rejecterComment}}',
                                nb_NO: 'Avslått av {{kyc00-rejecterName}} - {{kyc00-rejecterComment}}',
                                sv_SE: 'Avvisade av {{kyc00-rejecterName}} - {{kyc00-rejecterComment}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'rejected', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getKycUploadStep() {
        return {
            key: 'xx-upload-kyc',
            isDefault: true,
            resetMode: 'form',
            name: 'Default Upload KYC Form',
            form: 'kyc00',
            actions: [
                {
                    icon: 'upload',
                    main: true,
                    action: 'form-upload',
                    key: 'kyc00',
                    /* configCondition: 'uploadKyc', */
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: true },
                        { type: 'form', key: 'kyc00', trg: 'manual', rev: true },
                    ],
                    text: {
                        en_EN: 'Upload',
                        nb_NO: 'Last opp',
                        sv_SE: 'Upload',
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'form-pdf-manual',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'manual', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt',
                    },
                    hint: {
                        en_EN: 'Show the PDF receipt',
                        nb_NO: 'Vis PDF-kvittering',
                        sv_SE: 'Visa PDF-kvittot',
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'form-upload-delete',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'manual', rev: false },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: 'Delete file',
                        nb_NO: 'Slett fil',
                        sv_SE: '',
                    }
                },
            ],
            title: {
                en_EN: 'Self-Declaration form',
                nb_NO: 'Egenerklæringsskjema',
                sv_SE: 'Kundkännedom'
            },
            hint: {
                en_EN: 'Upload a copy of a completed self-declaration',
                nb_NO: 'Last opp en kopi av en fullført egenerklæring',
                sv_SE: 'Upload a copy of a completed self-declaration'
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Uploaded by {{kyc00-uploadName}}',
                                nb_NO: 'Lastet opp av {{kyc00-uploadName}}',
                                sv_SE: 'Uploaded by {{kyc00-uploadName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'manual', rev: false },
                            ]
                        }
                    ]
                },
            }
        };
    },

    getPersonStep() {
        return {
            key: 'no-baml-per',
            isDefault: true,
            resetMode: 'roles',
            name: 'Default BankID AML Person Screening',
            actions: [
                {
                    icon: 'chev-r',
                    main: true,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        /* { type: 'person', trg: 'something', rev: false }, */
                        { type: 'report', trg: 'created', rev: true },
                    ],
                    text: {
                        en_EN: 'Show persons',
                        nb_NO: 'Vis personer',
                        sv_SE: 'Visa personer'
                    },
                    hint: {
                        en_EN: '',
                        nb_NO: ''
                    }
                },
                {
                    icon: 'chev-r',
                    main: false,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        { type: 'person', trg: 'something', rev: false },
                    ],
                    text: {
                        en_EN: ''
                    },
                    hint: {
                        en_EN: 'Show persons',
                        nb_NO: 'Vis personer',
                        sv_SE: 'Visa personer'
                    }
                }
            ],
            title: {
                en_EN: 'Screening and identification of roles',
                nb_NO: 'Kontroller nøkkelroller',
                sv_SE: 'Kontrollera nyckelroller'
            },
            hint: {
                en_EN: 'Define key roles in the company and perform PEP/Sanction-searches and ID-controls.',
                nb_NO: 'Angi nøkkelroller i foretaket og utfør PEP/Sanksjonssøk og ID-kontroller.',
                sv_SE: 'Definiera nyckelroller i företaget och utför PEP / Sanction-sökningar och ID-kontroller.',
            },
            status: {
                multiple: true,
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{screenedCount}} of {{selectedCount}} screened',
                                nb_NO: '{{screenedCount}} av {{selectedCount}} personsøk utført',
                                sv_SE: '{{screenedCount}} av {{selectedCount}} personsök utförda',
                            },
                            triggers: [
                                { type: 'person', trg: 'something', rev: false },
                                { type: 'person', trg: 'scr-all', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: '{{identifiedCount}} of {{selectedCount}} identified',
                                nb_NO: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utført',
                                sv_SE: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utförda',
                            },
                            triggers: [
                                { type: 'person', trg: 'something', rev: false },
                                { type: 'person', trg: 'idc-all', rev: true },
                            ]
                        },
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{screenedCount}} of {{selectedCount}} screened',
                                nb_NO: '{{screenedCount}} av {{selectedCount}} personsøk utført',
                                sv_SE: '{{screenedCount}} av {{selectedCount}} personsök utförda'
                            },
                            triggers: [
                                { type: 'person', trg: 'scr-all', rev: false },
                            ]
                        },
                        {
                            text: {
                                en_EN: '{{identifiedCount}} of {{selectedCount}} identified',
                                nb_NO: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utført',
                                sv_SE: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utförda'
                            },
                            triggers: [
                                { type: 'person', trg: 'idc-all', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getReportStep() {
        return {
            key: 'no-baml-rep',
            isDefault: true,
            resetMode: 'report',
            name: 'Default BankID AML Report',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'tab',
                    key: 'report',
                    triggers: [
                        { type: 'report', trg: 'created', rev: true },
                    ],
                    text: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport',
                        sv_SE: 'Visa rapport'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'preview',
                    key: 'report',
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Show the final report'
                    }
                },
/*                 {
                    icon: 'download',
                    main: false,
                    action: 'report-combined',
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ],
                    text: {
                        en_EN: ''
                    },
                    hint: {
                        en_EN: '',
                        nb_NO: 'Last ned samlet sluttrapport'
                    }
                }, */
                {
                    icon: 'delete',
                    main: false,
                    action: 'report-reset',
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ],
                    text: {
                        en_EN: ''
                    },
                    hint: {
                        en_EN: ''
                    }
                }
            ],
            title: {
                en_EN: 'Final report / Risk assessment',
                nb_NO: 'Sluttrapport',
                sv_SE: 'Slutrapport'
            },
            hint: {
                en_EN: 'Look through the collected information and set a risk assessment, then generate a final report for this customer.',
                nb_NO: 'Gå gjennom innsamlet informasjon og sett en risikovurdering, så genereres det en sluttrapport for kunden.',
                sv_SE: 'Gå igenom den insamlade informationen och sätt en riskbedömning och skapa sedan en slutrapport för denna kund.'
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Final report created',
                                nb_NO: 'Sluttrapport fullført',
                                sv_SE: 'Slutrapport skapad'
                            },
                            triggers: [
                                { type: 'report', trg: 'created', rev: false },
                                { type: 'report', trg: 'changed', rev: true },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Underlying data has been updated since the report was created',
                                nb_NO: 'Underliggende data har blitt oppdatert siden rapporten ble opprettet',
                                sv_SE: 'Underliggande data har uppdaterats sedan rapporten skapades'
                            },
                            triggers: [
                                { type: 'report', trg: 'created', rev: false },
                                { type: 'report', trg: 'changed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getKarStep() {
        return {
            "key": "no-kar",
            "name": "Default KAR Lookup",
            "isDefault": true,
            "resetMode": 'normal',
            "actions": [
                {
                    "icon": "open",
                    "main": true,
                    "action": "form-start",
                    "key": "kar99",
                    "triggers": [
                        {
                            "type": "form",
                            "key": "kar99",
                            "trg": "sent",
                            "rev": true
                        }
                    ],
                    "text": {
                        "en_EN": "Start",
                        "nb_NO": "Start",
                        "sv_SE": "Start"
                    },
                    "hint": {
                        "en_EN": "",
                        "nb_NO": "",
                        "sv_SE": ""
                    },
                    "inPopup": true
                },
                {
                    "icon": "pdf",
                    "main": false,
                    "action": "preview",
                    "key": "kar99",
                    "triggers": [
                        {
                            "type": "form",
                            "key": "kar99",
                            "trg": "filled",
                            "rev": false
                        }
                    ],
                    "text": {
                        "en_EN": "Show receipt",
                        "nb_NO": "Show receipt",
                        "sv_SE": "Show receipt"
                    },
                    "hint": {
                        "en_EN": "",
                        "nb_NO": "",
                        "sv_SE": ""
                    }
                },
                {
                    "icon": "delete",
                    "main": false,
                    "action": "form-reset",
                    "key": "kar99",
                    "triggers": [
                        {
                            "type": "form",
                            "key": "kar99",
                            "trg": "sent",
                            "rev": false
                        }
                    ],
                    "text": {
                        "en_EN": "Reset form",
                        "nb_NO": "Reset form",
                        "sv_SE": "Reset form"
                    },
                    "hint": {
                        "en_EN": "",
                        "nb_NO": "",
                        "sv_SE": ""
                    }
                }
            ],
            "title": {
                "en_EN": "KAR Lookup",
                "nb_NO": "KAR-oppslag",
                "sv_SE": "KAR Lookup"
            },
            "hint": {
                "en_EN": "Gjør et oppslag i KAR-registeret (Konto- og AdresseringsRegister). Sjekker at et kontonummer tilhører et foretak eller person.",
                "nb_NO": "Gjør et oppslag i KAR-registeret (Konto- og AdresseringsRegister). Sjekker at et kontonummer tilhører et foretak eller person.",
                "sv_SE": "Gjør et oppslag i KAR-registeret (Konto- og AdresseringsRegister). Sjekker at et kontonummer tilhører et foretak eller person."
            },
            "status": {
                "completed": {
                    "statuses": [
                        {
                            "text": {
                                "en_EN": "{{kar99-karMessage}}",
                                "nb_NO": "{{kar99-karMessage}}",
                                "sv_SE": "{{kar99-karMessage}}"
                            },
                            "triggers": [
                                {
                                    "type": "form",
                                    "key": "kar99",
                                    "trg": "sent",
                                    "rev": false
                                },
                                {
                                    "type": "form",
                                    "key": "kar99",
                                    "value": "karVerified",
                                    "op": 'is',
                                    "trg": true,
                                    "rev": false
                                }
                            ]
                        }
                    ]
                },
                "error": {
                    "statuses": [
                        {
                            "text": {
                                "en_EN": "{{kar99-karMessage}}",
                                "nb_NO": "{{kar99-karMessage}}",
                                "sv_SE": "{{kar99-karMessage}}"
                            },
                            "triggers": [
                                {
                                    "type": "form",
                                    "key": "kar99",
                                    "trg": "sent",
                                    "rev": false
                                },
                                {
                                    "type": "form",
                                    "key": "kar99",
                                    "value": "karVerified",
                                    "op": 'is',
                                    "trg": false,
                                    "rev": false
                                }
                            ]
                        }
                    ]
                }
            }
        };
    },

    getAmsStep() {
        return {
            "key": "xx-org-ams",
            "name": "Company Adverse Media",
            "isDefault": true,
            "actions": [
                {
                    "icon": "open",
                    "main": true,
                    "action": "ams-org",
                    "key": "start",
                    "triggers": [
                        {
                            "type": "org",
                            "key": "",
                            "trg": "ams-completed",
                            "rev": true
                        }
                    ],
                    "text": {
                        "en_EN": "Open search dialog",
                        "nb_NO": "Åpne søkeboks",
                        "sv_SE": "Öppna sökdialogrutan"
                    },
                    "hint": {
                        "en_EN": "",
                        "nb_NO": "",
                        "sv_SE": ""
                    }
                },
                {
                    "icon": "info",
                    "main": false,
                    "action": "ams-org",
                    "key": "start",
                    "triggers": [
                        {
                            "type": "org",
                            "key": "",
                            "trg": "ams-completed",
                            "rev": false
                        }
                    ],
                    "text": {
                        "en_EN": "",
                        "nb_NO": "",
                        "sv_SE": ""
                    },
                    "hint": {
                        "en_EN": "Show results from KYC-search",
                        "nb_NO": "Vis resultatet fra KYC-søket",
                        "sv_SE": "Visa resultat från KYC-sökning"
                    }
                },
                {
                    "icon": "delete",
                    "main": false,
                    "action": "ams-org",
                    "key": "reset",
                    "triggers": [
                        {
                            "type": "org",
                            "key": "",
                            "trg": "ams-completed",
                            "rev": false
                        }
                    ],
                    "text": {
                        "en_EN": "",
                        "nb_NO": "",
                        "sv_SE": ""
                    },
                    "hint": {
                        "en_EN": "Reset the KYC-search",
                        "nb_NO": "Tilbakestill KYC-søket",
                        "sv_SE": "Återställ KYC-sökningen"
                    },
                    "disabled": {
                        "triggers": [
                            {
                                "type": "report",
                                "key": "",
                                "trg": "created",
                                "rev": false
                            }
                        ],
                        "hint": {
                            "en_EN": "Customer is locked after the final report is created",
                            "nb_NO": "Kunden er låst etter sluttrapporten er laget",
                            "sv_SE": "Kunden är låst efter att slutrapporten skapats",
                        }
                    }
                }
            ],
            "title": {
                "en_EN": "Company KYC-search",
                "nb_NO": "KYC-søk på foretaket",
                "sv_SE": "KYC-sökning på företaget"
            },
            "hint": {
                "en_EN": "Perform a KYC-search on the company",
                "nb_NO": "Utfør et KYC-søk på foretaket",
                "sv_SE": "Utför en KYC-sökning på företaget"
            },
            "status": {
                "completed": {
                    "statuses": [
                        {
                            "text": {
                                "en_EN": "{{amsResult}}",
                                "nb_NO": "{{amsResult}}",
                                "sv_SE": "{{amsResult}}"
                            },
                            "triggers": [
                                {
                                    "type": "org",
                                    "key": "",
                                    "trg": "ams-completed",
                                    "rev": false
                                },
                                {
                                    "type": "org",
                                    "key": "",
                                    "trg": "ams-warning",
                                    "rev": true
                                },
                                {
                                    "type": "org",
                                    "key": "",
                                    "trg": "ams-hits",
                                    "rev": true
                                }
                            ]
                        }
                    ]
                },
                "progress": {
                    "statuses": [
                        {
                            "text": {
                                "en_EN": "Started by {{amsUser}}",
                                "nb_NO": "Påbegynt av {{amsUser}}",
                                "sv_SE": "Startad av {{amsUser}}"
                            },
                            "triggers": [
                                {
                                    "type": "org",
                                    "key": "",
                                    "trg": "ams-started",
                                    "rev": false
                                },
                                {
                                    "type": "org",
                                    "key": "",
                                    "trg": "ams-completed",
                                    "rev": true
                                }
                            ]
                        }
                    ]
                },
                "warning": {
                    "statuses": [
                        {
                            "text": {
                                "en_EN": "{{amsResult}}",
                                "nb_NO": "{{amsResult}}",
                                "sv_SE": "{{amsResult}}"
                            },
                            "triggers": [
                                {
                                    "type": "org",
                                    "key": "",
                                    "trg": "ams-warning",
                                    "rev": false
                                }
                            ]
                        }
                    ]
                },
                "error": {
                    "statuses": [
                        {
                            "text": {
                                "en_EN": "{{amsResult}}",
                                "nb_NO": "{{amsResult}}",
                                "sv_SE": "{{amsResult}}"
                            },
                            "triggers": [
                                {
                                    "type": "org",
                                    "key": "",
                                    "trg": "ams-hits",
                                    "rev": false
                                }
                            ]
                        }
                    ]
                }
            }
        };
    },

    getBookStep() {
        return {
            key: 'no-book',
            isDefault: true,
            name: 'Default Shareholder Book',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'book',
                    key: 'upload',
                    triggers: [
                        { type: 'book', trg: 'later', rev: false },
                        { type: 'book', trg: 'confirmed', rev: true },
                    ],
                    text: {
                        en_EN: 'Upload',
                        nb_NO: 'Last opp',
                        sv_SE: ''
                    },
                    hint: {
                        en_EN: ''
                    },
                    disabled: {
                        triggers: [
                            { type: 'form', key: 'kyc00', trg: 'signed', rev: true },
                        ],
                        hint: {
                            en_EN: 'Self-declaration not completed and signed',
                            nb_NO: 'Egenerklæring ikke fullført og signert',
                            sv_SE: '',
                        },
                    }
                },
                {
                    icon: 'check',
                    main: true,
                    action: 'book',
                    key: 'confirm',
                    triggers: [
                        { type: 'book', trg: 'provided', rev: false },
                        { type: 'book', trg: 'confirmed', rev: true },
                    ],
                    text: {
                        en_EN: 'Confirm',
                        nb_NO: 'Bekreft',
                        sv_SE: ''
                    },
                    hint: {
                        en_EN: '',
                        nb_NO: '',
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'book',
                    key: 'show',
                    triggers: [
                        { type: 'book', trg: 'exists', rev: false },
                    ],
                    text: {
                        en_EN: '',
                        nb_NO: '',
                        sv_SE: ''
                    },
                    hint: {
                        en_EN: 'Show the shareholder book',
                        nb_NO: 'Vis aksjeeierboken',
                    }
                },
            ],
            title: {
                en_EN: 'Shareholder book / other documentation',
                nb_NO: 'Aksjeeierbok / annen dokumentasjon',
                sv_SE: ''
            },
            hint: {
                en_EN: 'If changes have been made to the company structure that do not appear from public registers, documentation must be attached.',
                nb_NO: 'Hvis det er gjort endringer i foretaksstrukturen som ikke fremkommer fra offentlige registre, må det legges ved dokumentasjon.',
                sv_SE: ''
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'No changes were made to the corporate structure',
                                nb_NO: 'Det ble ikke gjort noen endringer i foretaksstrukturen',
                                sv_SE: ''
                            },
                            triggers: [
                                { type: 'book', trg: 'irrelevant', rev: false },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Confirmed by',
                                nb_NO: 'Bekreftet av {{bookUser}}',
                                sv_SE: ''
                            },
                            triggers: [
                                { type: 'book', trg: 'confirmed', rev: false },
                            ]
                        }
                    ]
                },
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Awaiting confirmation of the attached documentation',
                                nb_NO: 'Avventer bekreftelse av vedlagt dokumentasjon',
                                sv_SE: ''
                            },
                            triggers: [
                                { type: 'book', trg: 'provided', rev: false },
                                { type: 'book', trg: 'confirmed', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Documentation will be sent later and must be uploaded here',
                                nb_NO: 'Dokumentasjon ettersendes og må lastes opp her',
                                sv_SE: ''
                            },
                            triggers: [
                                { type: 'book', trg: 'later', rev: false },
                                { type: 'book', trg: 'confirmed', rev: true },
                            ]
                        },
                    ]
                }
            }
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default noOrgBaml;