<template>
<div class="aml-page" v-if="!loading">
    
    <vap-form-divider :left="50" :right="50">
        <template v-slot:left>
            <vap-label :text="'Registered Country'" header2 style="margin-bottom: 5px;" />
            <RiskCountrySettings :riskParams="riskParams" :source="'pc'" />
        </template>
        <template v-slot:right>
            <div class="arisk-right-padding">
                <vap-label :text="'Info/Weight'" header2 style="margin-bottom: 5px;" />
                <vap-card>
                    <div>
                        <RiskWeightControl :params="weightParams" :riskKey="'r01'" @adjust="onAdjustWeight" />
                    </div>
                </vap-card>
            </div>
        </template>
    </vap-form-divider>

    <div class="arisk-hr"><hr></div>

    <vap-form-divider :left="50" :right="50">
        <template v-slot:left>
            <vap-label :text="'Entity Types'" header2 style="margin-bottom: 5px;" />
            <RiskEntitySettings :riskParams="riskParams" />
        </template>
        <template v-slot:right>
            <div class="arisk-right-padding">
                <vap-label :text="'Info/Weight'" header2 style="margin-bottom: 5px;" />
                <vap-card>
                    <div>
                        <RiskWeightControl :params="weightParams" :riskKey="'r02'" @adjust="onAdjustWeight" />
                    </div>
                </vap-card>
            </div>
        </template>
    </vap-form-divider>

    <div class="arisk-hr"><hr></div>

    <vap-form-divider :left="50" :right="50">
        <template v-slot:left>
            <vap-label :text="'NACE Codes'" header2 style="margin-bottom: 5px;" />
            <RiskNaceSettings :riskParams="riskParams" />
        </template>
        <template v-slot:right>
            <div class="arisk-right-padding">
                <vap-label :text="'Info/Weight'" header2 style="margin-bottom: 5px;" />
                <vap-card>
                    <div>
                        <RiskWeightControl :params="weightParams" :riskKey="'r03'" @adjust="onAdjustWeight" />
                    </div>
                </vap-card>
            </div>
        </template>
    </vap-form-divider>

    <div class="arisk-hr"><hr></div>

    <vap-form-divider :left="50" :right="50">
        <template v-slot:left>
            <vap-label :text="'Startup Date'" header2 style="margin-bottom: 5px;" />
            <RiskAgeSettings :riskParams="riskParams" />
        </template>
        <template v-slot:right>
            <div class="arisk-right-padding">
                <vap-label :text="'Info/Weight'" header2 style="margin-bottom: 5px;" />
                <vap-card>
                    <div>
                        <RiskWeightControl :params="weightParams" :riskKey="'r04'" @adjust="onAdjustWeight" />
                    </div>
                </vap-card>
            </div>
        </template>
    </vap-form-divider>

    <div class="arisk-hr"><hr></div>

    <vap-form-divider :left="50" :right="50">
        <template v-slot:left>
            <vap-label :text="'Key Roles Address'" header2 style="margin-bottom: 5px;" />
            <RiskCountrySettings :riskParams="riskParams" :source="'kr'" />
        </template>
        <template v-slot:right>
            <div class="arisk-right-padding">
                <vap-label :text="'Info/Weight'" header2 style="margin-bottom: 5px;" />
                <vap-card>
                    <div>
                        <RiskWeightControl :params="weightParams" :riskKey="'r05'" @adjust="onAdjustWeight" />
                    </div>
                </vap-card>
            </div>
        </template>
    </vap-form-divider>

    <div class="arisk-hr"><hr></div>

    <vap-form-divider :left="50" :right="50">
        <template v-slot:left>
            <vap-label :text="'PEP/Sanctions'" header2 style="margin-bottom: 5px;" />
            <RiskHitSettings :riskParams="riskParams" :source="'rh'" />
        </template>
        <template v-slot:right>
            <div class="arisk-right-padding">
                <vap-label :text="'Info/Weight'" header2 style="margin-bottom: 5px;" />
                <vap-card>
                    <div>
                        <RiskWeightControl :params="weightParams" :riskKey="'r06'" @adjust="onAdjustWeight" />
                    </div>
                </vap-card>
            </div>
        </template>
    </vap-form-divider>

    <vap-button :text="'save'" @clicked="saveParams" />

    <RiskClientBar
        :clients="testClients"
    />

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import defaultRisk from '../v1risk/defaultRisk';

import RiskNaceSettings from '../v1risk/RiskNaceSettings.vue';
import RiskEntitySettings from '../v1risk/RiskEntitySettings.vue';
import RiskAgeSettings from '../v1risk/RiskAgeSettings.vue';
import RiskCountrySettings from '../v1risk/RiskCountrySettings.vue';
import RiskHitSettings from '../v1risk/RiskHitSettings.vue';

import RiskWeightControl from '../v1risk/RiskWeightControl.vue';
import RiskClientBar from '../v1risk/RiskClientBar.vue';

export default {
    name: 'RiskSettings',
    components: {
        RiskNaceSettings,
        RiskEntitySettings,
        RiskAgeSettings,
        RiskCountrySettings,
        RiskHitSettings,
        RiskWeightControl,
        RiskClientBar,
    },
    data() {
        return {
            loading: true,
            flowKey: 'no-org-baml',
            riskParams: null,
            items: [],
            weightParams: {
                items: [],
                total: 0
            },
            testClients: [
                { name: '', risk: '' },
                { name: '', risk: '' },
                { name: '', risk: '' },
                { name: '', risk: '' },
                { name: '', risk: '' },
            ],
        }
    },
    methods: {

        async saveParams() {
            await v1api.updateRiskParams(this.flowKey, this.riskParams, this.items);
        },

        onAdjustWeight(key, weight, multiL, multiH) {
            for (const i in this.items) {
                if (this.items[i].key == key) {
                    this.items[i].weight = Number(weight);
                    this.items[i].multiL = Number(multiL);
                    this.items[i].multiH = Number(multiH);
                }
            }
            this.calculateWeight();
        },

        calculateWeight() {
            this.loading = true;
            this.weightParams.items = [];
            this.weightParams.total = 0;

            for (const i in this.items) {

                this.weightParams.items.push({
                    key: this.items[i].key,
                    weight: Number(this.items[i].weight),
                    multiL: Number(this.items[i].multiL),
                    multiH: Number(this.items[i].multiH),
                });
                this.weightParams.total += Number(this.items[i].weight);

            } 

            const fac = 100 / Number(this.weightParams.total);

            for (const i in this.weightParams.items) {
                this.weightParams.items[i].percentage = fac * this.weightParams.items[i].weight;
            }

            this.loading = false;
        },

    },
    async created() {
        for (const i in this.$root.riskParams) {
            if (this.$root.riskParams[i].flow == this.flowKey) {
                this.riskParams = this.$root.riskParams[i].params;
                this.items = this.$root.riskParams[i].items;
                console.log('loaded risk params', this.$root.riskParams[i]);
            }
        }
        if (!this.riskParams) this.riskParams = defaultRisk.getDefaultScore('NO');
        if (!this.items) this.items = this.$root.config.risk.autoItems;
        
        this.calculateWeight();
        this.loading = false;
    }
}
</script>
<style scoped>

.arisk-hr {
    float: left;
    width: 100%;
    padding: 30px 280px;
    box-sizing: border-box;
}
.arisk-hr hr {
    border: solid 1px var(--input-border);
}

.arisk-right-padding {
    padding-right: 60px;
}

</style>