<template>
<div class="arisk-testbar">

    <div class="arisk-testbar-client" v-for="(client, i) in clients" :key="'tc' + i" :class="[
        {'arisk-testbar-client-border': i == 0 }
    ]">

    </div>

</div>
</template>
<script>
export default {
    name: 'RiskClientBar',
    components: {

    },
    props: {
        clients: { type: Array, default: null },
    },
    data() {
        return {
            
        }
    },
    methods: {



    },
    async created() {
        
    }
}
</script>
<style scoped>

.arisk-testbar {
    position: fixed;
    width: 70px; height: 400px;
    top: 300px; right: 0px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
}

.arisk-testbar-client {
    width: 70px; height: 80px;
    border-top: solid 1px var(--input-border);
}

.arisk-testbar-client-border {
    border-top: none;
}
 
</style>