<template>
<div class="frv2-stp-bot-msg">
    
    <div class="frv2-stp-bot-msg-center">
    
        <div class="frv2-stp-bot-msg-info">
            <label>{{ '!' }}</label>
        </div>
        <div class="frv2-stp-bot-msg-content">
            <div class="frv2-stp-bot-msg-title">
                {{ __t('form-autosave-header') }}
            </div>
            <div class="frv2-stp-bot-msg-text">
                {{ __t('form-autosave-message') }}
            </div>
        </div>
        <div class="frv2-stp-bot-msg-reset" @clicked="$emit('ignore')">
            <label>{{ __t('form-autosave-reset') }}</label>
        </div>
        <div class="frv2-stp-bot-msg-close">
            <vap-icon :icon="'cancel'" small action @clicked="$emit('hide')">
                <template v-slot:tooltip>
                    <vap-tooltip :text="__t('form-autosave-hide')" />
                </template>
            </vap-icon>
        </div>

    </div>

</div>
</template>

<script>

export default {
    name: 'FormStepperMessage',
    props: {
        content: { type: String, default: '' },
    },
}
</script>
<style scoped>

.frv2-stp-bot-msg {
    width: 100%;
    height: 46px;
    background-color: #F4E7C3;
    border-top: 1px solid #CFC4A5;
    border-bottom: 1px solid #CFC4A5;
}

.frv2-stp-bot-msg-center {
    display: flex;
    max-width: 770px;
    margin: 0 auto;
    box-sizing: border-box;
}


.frv2-stp-bot-msg-content {
    flex-grow: 100;
    margin-left: 15px;
    padding-top: 6px;
}

.frv2-stp-bot-msg-title {
    font-size: 15px;
    font-weight: 500;
}
.frv2-stp-bot-msg-text {
    margin-top: -2px;
    font-size: 15px;
    color: var(--text-title);
}

.frv2-stp-bot-msg-reset {
    width: 150px;
    margin-top: 14px;
}
.frv2-stp-bot-msg-reset label {
    font-weight: 500;
    text-decoration: none;
    color: #238CCB;
    cursor: pointer;
}
.frv2-stp-bot-msg-reset label:hover {
    opacity: 0.8;
}

.frv2-stp-bot-msg-info {
    width: 26px; height: 26px;
    margin-top: 10px;
    padding-top: 4px;
    padding-left: 11px;
    font-weight: bold;
    border-radius: 50%;
    background-color: #EDC250;
    box-sizing: border-box;
}

.frv2-stp-bot-msg-close {
    margin-top: 8px;
}

</style>
