<template>
<div class="v1dc">

    <CardActions v-if="hasInfo" :actions="actions" @action="$emit('action')" />

    <div class="v1dc-style" :class="[
        {'v1dc-style-1': status == 1 },
        {'v1dc-style-2': status == 2 },
        {'v1dc-style-3': status == 3 },
    ]">

        <div class="v1dc-line" />

        <div class="v1dc-icon">
            <vap-icon small :icon="icon" :color="'ext'" />
        </div>

    </div>

    <div class="v1dc-title">
        {{ title }}
    </div>

    <div class="v1dc-flag" v-if="hasFlag">
        <FlagIcon :alpha2="flag" />
    </div>

    <div class="v1dc-content">
        <slot name="content" v-if="!empty" />
        <div class="v1dc-empty" v-if="empty">
            {{ emptyText }}
        </div>
    </div>

    <div class="v1dc-list" v-if="hasList && expanded">
        <slot name="list" />
    </div>

    <div class="srv2-step-list-footer" v-if="hasList">

        <div class="srv2-step-list-bend" />
        <div class="srv2-step-list-top" />
        <div class="srv2-step-list-top1" />
        <div class="srv2-step-list-top2" />

        <div class="srv2-step-list-count">
            <div class="srv2-step-list-count1" v-if="!expanded && listCount > 0">
                {{ '+ ' + listCount + ' ' + listText }}
            </div>
            <div class="srv2-step-list-count2" :class="{'srv2-step-list-count2-exp': expanded || listCount == 0}">
                {{ listCount == 0 ? __t('details-count-empty') + ' ' + listText : __t('details-expand-hint') }}
            </div>
        </div>

        <div class="srv2-step-list-buttons nohl">
            <div class="srv2-step-list-button" @click="expanded = !expanded" v-if="listCount > 0">
                {{ __t(expanded ? 'details-hide' : 'details-show-all') }}
            </div>
<!--             <div class="srv2-step-list-button" @click="onNewForm" :class="{'srv2-step-list-disabled': disabled }">
                {{ __t('form-new') }}
            </div> -->
        </div>

    </div>

</div>
</template>
<script>
import FlagIcon from '../v1ui/FlagIcon.vue';
import CardActions from '../v1ui/CardActions.vue';

export default {
    name: 'DetailCard',
    components: {
        FlagIcon,
        CardActions,
    },
    props: {
        title: { type: String, default: 'Details' },
        icon: { type: String, default: 'bug' },
        flag: { type: String, default: 'xx' },
        hasFlag: { type: Boolean, default: false },
        hasList: { type: Boolean, default: false },
        hasInfo: { type: Boolean, default: false },
        empty: { type: Boolean, default: false },
        emptyText: { type: String, default: 'xx' },
        listCount: { type: Number, default: 0 },
        listText: { type: String, default: '' },
        status: { type: Number, default: 0 },
    },
    data() {
        return {
            actions: [
                { icon: 'help-o' },
                { icon: 'info' },
            ],
            items: [],
            expanded: false,
        }
    },
    methods: {
        
        

    }
}
</script>
<style scoped>

.v1dc {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
}

.v1dc-style {
    position: absolute;
    width: 60px;
    top: 0px; bottom: 0px;
}

.v1dc-line {
    position: absolute;
    top: 20px; bottom: 26px;
    left: 24px; width: 7px;
    border-radius: 4px;
    background-color: var(--disabled-background);
}
.v1dc-style-1 .v1dc-line {
    background-color: var(--success-background);
}
.v1dc-style-2 .v1dc-line {
    background-color: var(--warning-background);
}
.v1dc-style-3 .v1dc-line {
    background-color: var(--error-background);
}

.v1dc-icon {
    position: absolute;
    top: 8px; left: 10px;
    width: 35px; height: 35px;
    padding: 3px 4px;
    border-radius: 50%;
    fill: gray;
    background-color: var(--disabled-background);
    box-sizing: border-box;
}
.v1dc-style-1 .v1dc-icon {
    fill: var(--success-text);
    background-color: var(--success-background);
}
.v1dc-style-2 .v1dc-icon {
    fill: var(--warning-text);
    background-color: var(--warning-background);
}
.v1dc-style-3 .v1dc-icon {
    fill: var(--error-text);
    background-color: var(--error-background);
}

.v1dc-title {
    position: absolute;
    top: 14px; left: 58px;
    font-size: 18px;
}

.v1dc-flag {
    position: absolute;
    top: 5px; right: 10px;
}

.v1dc-content {
    float: left;
    width: 100%;
    padding-top: 50px;
    padding-left: 58px;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.v1dc-empty {
    color: var(--text-disabled);
}

.v1dc-list {
    float: left;
    width: 100%;
    min-height: 30px;
}




.srv2-step-list-footer {
    position: relative;
    float: left;
    width: 100%;
    min-height: 40px;
    /* background-color: red; */
    border-top: solid 1px var(--input-border);
}

.srv2-step-list-bend {
    position: absolute;
    top: 0px; left: 24px;
    width: 40px; height: 16px;
    border-left: solid 7px var(--disabled-background);
    border-bottom: solid 7px var(--disabled-background);
    border-radius: 10px;
}
.srv2-step-list-top {
    position: absolute;
    top: 0px; left: 24px;
    width: 7px; height: 14px;
    background-color: var(--disabled-background);
}
.srv2-step-list-top1 {
    position: absolute;
    top: 0px; left: 31px;
    width: 7px; height: 12px;
    background-color: var(--card-background);
}
.srv2-step-list-top2 {
    position: absolute;
    top: 12px; left: 63px;
    width: 8px; height: 12px;
    background-color: var(--card-background);
}



.srv2-step-list-count {
    position: absolute;
    top: 3px; left: 91px;
}

.srv2-step-list-count1 {
    font-size: 15px;
    font-weight: 500;
    color: var(--text-title);
}
.srv2-step-list-count2 {
    margin-top: -1px;
    font-size: 12px;
    color: var(--text-weak);
}
.srv2-step-list-count2-exp {
    margin-top: 9px;
}

.srv2-step-list-buttons {
    position: absolute;
    top: 6px; right: 6px;
    display: flex;
}

.srv2-step-list-button {
    height: 20px;
    margin-left: 5px;
    padding: 0px 14px;
    padding-top: 4px;
    padding-bottom: 2px;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-title);
    border-radius: 3px;
    border: 1px solid var(--input-border);
    background-color: var(--page-background);
    cursor: pointer;
}
.srv2-step-list-button:hover {
    color: var(--button-text);
    background-color: var(--button);
}



</style>