import v2lib from "../../../v2lib";
const signerFlow = {

    async onCreateEnvelope() {
        let descriptor = 'aml-portal-v1-sign';
        let flowName = 'aml-portal-v1-sign-flow'; //'simple-public-flow-custom-sender';

        // Create envelope
        const envelope = await v2lib.createEnvelope(descriptor, flowName, false);

        return envelope;
    },

    async onAddRecipient(envelope, person) {
        // Set editor recipient
        const recipient = {
            givenName: person.firstName,
            familyName: person.lastName,
            language: person.lang || 'en_EN',
            signingMethod: person.method || 'email',
            email: person.email,
            order: 1,
            role: person.role
        };
        //console.log('ENV', envelope);
        await v2lib.addRecipient(envelope, recipient);
    },

    async onSetUserData(envelope, userdata) {
        // Set template userdata and name
        /* const documentName = 'Egenerklæring ' + this.customer.name + '.pdf';
        this.ud.fileName = documentName; */
        
        await v2lib.setUserData(envelope, userdata);
        /* await v2lib.setNameAndTags(envelope, documentName, ['egenerklæring']);
        await v2lib.setName(envelope, documentName); */
    },

    async onSetNameAndTags(envelope, userdata, name, tags) {

        await v2lib.setDocumentName(envelope, userdata, name);

        //await v2lib.setTags(envelope, tags);

    },

    async onSubmit(envelope) {
        // Publish the envelope
        await v2lib.publishEnvelope(envelope);
    }

};
export default signerFlow;