<template>
<div>

    <PopupV1 :header="'Language'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ 'Form languages' + ':' }}
            </div>
            <DialogContent style="float: left; width: 100%; padding-top: 20px; padding-bottom: 10px;">
                <div>

                    <div class="frv2-ll-item" v-for="(item, i) in $parent.locales" :key="'ll' + i">

                        <div class="frv2-ll-item-con">
                            <div class="frv2-ll-item-flag">
                                <FlagIcon :alpha2="item.alpha2 || 'xx'" />
                            </div>
                            <div class="frv2-ll-item-check">
                                <VFCheckbox newDesign :text="''" v-model="item.enabled" borderOffset noValidation />
                            </div>
                            <div class="frv2-ll-item-text1">
                                {{ item.name }}
                            </div>
                            <div class="frv2-ll-item-text2">
                                {{ item.key }}
                            </div>
                            <div class="frv2-ll-item-text3">
                                {{ item.alpha2 || 'xx' }}
                            </div>
                        </div>
                        <div>
                            <FormMenuButton :icon="'delete'" dotted @clicked="onDeleteClicked">
                                <template v-slot:tooltip>
                                    <vap-tooltip :text="'Delete language'" />
                                </template>
                            </FormMenuButton>
                        </div>

                    </div>

                    <div class="frv2-opts-item-add">
                        <FormMenuButton :icon="'add'" dotted @clicked="addDialog.show = true">
                            <template v-slot:tooltip>
                                <vap-tooltip :text="'Add language'" />
                            </template>
                        </FormMenuButton>
                    </div>

                </div>
            </DialogContent>
            <div class="flw100" style="margin-top: 20px; margin-bottom: 10px; color: var(--text-title);">
                {{ 'Import/Export translations:' }}
            </div>
            <DialogContent style="float: left; width: 100%; padding-top: 20px; padding-bottom: 10px;">
                <div>

                    <VFButtonRow alignCenter>
                        <VFButton disabled
                            :icon="'download'"
                            :text="'Export translations'"
                            @clicked="onExport"
                        />
                        <VFButton disabled
                            :icon="'upload'"
                            :text="'Import translations'"
                            @clicked="onImport"
                        />
                    </VFButtonRow>

                </div>
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline
                @clicked="onClose"
            />
        </template>
    </PopupV1>

    <PopupV1 v-if="addDialog.show" :header="'Add language'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>

            <div>

                <div class="frv2-ll-item-con-add" v-for="(locale, i) in available" :key="'al' + i" @click="onAddLocale(locale)">
                    <div class="frv2-ll-item-flag">
                        <FlagIcon :alpha2="locale.alpha2 || 'xx'" />
                    </div>
                    <div class="frv2-ll-item-text1">
                        {{ locale.name }}
                    </div>
                </div>

            </div>

        </template>
        <template v-slot:buttons>
            <VFButton :text="'Cancel'" outline
                @clicked="addDialog.show = false"
            />
        </template>
    </PopupV1>
    
</div>
</template>
<script>
import FlagIcon from '../../../v1ui/FlagIcon.vue';
import FormMenuButton from './FormMenuButton.vue';

import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFButtonRow from '../items/VFButtonRow.vue';
import VFButton from '../items/VFButton.vue';
import DialogContent from '../../../v1ui/DialogContent.vue';
import DialogContentHeader from '../../../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../../../v1ui/DialogContentItem.vue';
import VFCheckbox from '../inputs/VFCheckbox.vue';

export default {
    name: 'FormLanguageDialog',
    components: {
        FlagIcon,
        FormMenuButton,
        PopupV1,
        VFButtonRow,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox
    },
    props: {
        params: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            addDialog: { show: false },
            available: [
                { key: 'en_EN', name: 'English', alpha2: 'gb' },
                { key: 'nb_NO', name: 'Norsk', alpha2: 'no' },
                { key: 'sv_SE', name: 'Svenska', alpha2: 'se' },
                { key: 'fi_FI', name: 'Suomi', alpha2: 'fi' },
                { key: 'da_DK', name: 'Dansk', alpha2: 'dk' },
                { key: 'de_DE', name: 'Deutsch', alpha2: 'de' },
            ]
        }
    },
    methods: {
        
        onDeleteClicked() {

        },

        onAddLocale(locale) {
            for (const i in this.$parent.locales) {
                if (this.$parent.locales[i].key == locale.key) return;
            }
            
            this.$parent.locales.push({
                key: locale.key,
                name: locale.name,
                enabled: true,
                alpha2: locale.alpha2,
            });

            this.addDialog.show = false;
        },

        onExport() {
            const locales = [];

            for (const i in this.$parent.pages) {

                const page = this.$parent.pages[i];
                //console.log('page', page);

                // Page
                if (page.title) {
                    const locale = {
                        pageKey: page.key,
                        key: page.key,
                        itemKey: 'NA',
                        role: 'NA',
                        type: 'page',
                        subtype: 'title',
                        index: 0,
                    };
                    for (const loc in page.title) {
                        if (this.isLocale(loc)) locale[loc] = page.title[loc];
                    }
                    locales.push(locale);
                }

                // Help Texts
                for (const key in page.info) {
                    for (const j in page.info[key]) {
                        if (page.info[key][j].header) {
                            const locale = {
                                pageKey: page.key,
                                key: page.info[key][j].key,
                                itemKey: 'NA',
                                role: key,
                                type: 'helptext',
                                subtype: 'header',
                                index: j,
                            };
                            for (const loc in page.info[key][j].header) {
                                if (this.isLocale(loc)) locale[loc] = page.info[key][j].header[loc];
                            }
                            locales.push(locale);
                        }
                        if (page.info[key][j].text) {
                            const locale = {
                                pageKey: page.key,
                                key: page.info[key][j].key,
                                itemKey: 'NA',
                                role: key,
                                type: 'helptext',
                                subtype: 'text',
                                index: j,
                            };
                            for (const loc in page.info[key][j].header) {
                                if (this.isLocale(loc)) locale[loc] = page.info[key][j].header[loc];
                            }
                            locales.push(locale);
                        }
                    }
                }

                // Cards
                for (const j in page.cards) {
                    const card = page.cards[j];

                    if (card.header) {
                        const locale = {
                            pageKey: page.key,
                            key: card.key,
                            itemKey: 'NA',
                            role: 'NA',
                            type: 'card',
                            subtype: 'header',
                            index: 0,
                        };
                        for (const loc in card.header) {
                            if (this.isLocale(loc)) locale[loc] = card.header[loc];
                        }
                        locales.push(locale);
                    }

                    // Inputs
                    for (const k in card.inputs) {
                        const input = card.inputs[k];
                        //console.log('input', input);

                        if (input.desc) {
                            const locale = {
                                pageKey: page.key,
                                key: input.key,
                                itemKey: 'NA',
                                role: 'NA',
                                type: 'input',
                                subtype: 'desc',
                                index: 0,
                            };
                            for (const loc in input.desc) {
                                if (this.isLocale(loc)) locale[loc] = input.desc[loc];
                            }
                            locales.push(locale);
                        }

                        // Options
                        if (input.options) {
                            for (const l in input.options) {
                                const locale = {
                                    pageKey: page.key,
                                    key: input.key,
                                    itemKey: l,
                                    role: 'NA',
                                    type: 'checkbox',
                                    subtype: 'option',
                                    index: 0,
                                };
                                for (const loc in input.options[l]) {
                                    if (this.isLocale(loc)) locale[loc] = input.options[l][loc];
                                }
                                locales.push(locale);
                            }
                        }

                    }

                }

            }

            //console.log('locales', locales);
            console.log(JSON.stringify(locales));
        },

        onImport() {

        },

        onClose() {
            this.$emit('close');
        },

        isLocale(key) {
            for (const i in this.$parent.locales) {
                if (this.$parent.locales[i].key == key) return true;
            }
            return false;
        },
        
    },
    created() {
        for (const i in this.$parent.locales) {
            if (!this.$parent.locales[i].alpha2) {
                if (this.$parent.locales[i].key == 'en_EN') this.$parent.locales[i].alpha2 = 'gb';
                if (this.$parent.locales[i].key == 'nb_NO') this.$parent.locales[i].alpha2 = 'no';
                if (this.$parent.locales[i].key == 'sv_SE') this.$parent.locales[i].alpha2 = 'se';
            }
        }
        console.log('pa', this.$parent.locales);
    }
}
</script>
<style scoped>

.frv2-ll-item {
    display: flex;
    margin-bottom: 5px;
}

.frv2-ll-item-con {
    flex-grow: 100;
    display: flex;
    margin-right: 5px;
    border-radius: 4px;
    border: solid 1px var(--input-border);
    box-sizing: border-box;
}

.frv2-ll-item-flag {
    margin-left: 10px;
    margin-top: 2px;
}

.frv2-ll-item-check {
    margin-top: -1px;
    margin-left: 10px;
}

.frv2-ll-item-text1 {
    margin-top: 9px;
    margin-left: 10px;
    font-weight: bold;
}

.frv2-ll-item-text2 {
    margin-top: 9px;
    margin-left: 10px;
}

.frv2-ll-item-text3 {
    margin-top: 9px;
    margin-left: 10px;
}

.frv2-opts-item-add {
    float: right;
}


.frv2-ll-item-con-add {
    flex-grow: 100;
    display: flex;
    margin-bottom: 5px;
    border-radius: 4px;
    border: solid 1px var(--input-border);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-ll-item-con-add:hover {
    background-color: var(--item-hover);
}

</style>