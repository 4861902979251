<template>
<div>

    <PropertyItem padded v-if="card" :header="'KYC Module'" :icon="'eye'" :initialCollapse="initialCollapse">

        <DesignerGroup c12>
            <DesignerLabel :text="'Connected to key'" />
            <DesignerSelector :text="getConnectedItemName(card.kycKey)" @clicked="$emit('kyc', card)" :tooltip="'Connect to KYC data'" />
        </DesignerGroup>

        <DesignerGroup c6>
            <DesignerCheckbox v-model="card.isPerson" :text="'Is KYC person'" />
        </DesignerGroup>

        <DesignerGroup c6>
            <DesignerCheckbox v-model="card.ppop" :text="'Person popup'" />
        </DesignerGroup>

        <DesignerGroup c6 v-if="card.kycKey == 'other' || card.kycKey == 'others'">
            <DesignerLabel :text="'Custom role name'" />
            <DesignerInput v-model="card.kycRoleName" :placeholder="'Not set'" />
        </DesignerGroup>

        <DesignerGroup c6 v-if="card.kycKey == 'other' || card.kycKey == 'others'">
            <DesignerLabel :text="'Key roles'" />
            <DesignerCheckbox v-model="card.kycKeyRole" :text="'Add to key roles'" />
        </DesignerGroup>

    </PropertyItem>

    <PropertyItem padded v-if="input" :header="'KYC Module'" :icon="'eye'" :initialCollapse="initialCollapse">

        <DesignerGroup c12>
            <DesignerLabel :text="'Connected to key'" />
            <DesignerSelector :text="getConnectedItemName(input.kycKey)" @clicked="$emit('kyc', input)" :tooltip="'Connect to KYC data'" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import kycModuleHelper from '../../modules/kyc/kycModuleHelper';

import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerSelector from '../ui/inputs/DesignerSelector.vue';

export default {
    name: 'KycModuleProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerSelector,
    },
    props: {
        input: { type: Object, default: null },
        card: { type: Object, default: null },
        initialCollapse: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

        getConnectedItemName(kycKey) {
            return kycModuleHelper.getConnectedItemName(kycKey);
        },

    }
}
</script>