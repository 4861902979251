<template>
<div class="piib" @click="onClick" :class="{'piib-disabled': disabled }">

    <vap-icon :icon="icon" small absolute :top="2" :right="3" :color="'ext'">
        <template v-slot:tooltip>
            <vap-tooltip :text="tooltip" v-if="tooltip" />
        </template>
    </vap-icon>
    
</div>
</template>
<script>
export default {
    name: 'PersonInfoButton',
    props: {
        icon: { type: String, default: 'bug' },
        tooltip: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
    },
    methods: {

        onClick() {
            if (this.disabled) return;
            this.$emit('clicked');
        }

    }
}
</script>
<style scoped>

.piib {
    position: relative;
    float: left;
    width: 35px;
    height: 32px;
    border-left: solid 1px var(--input-border);
    fill: gray;
    cursor: pointer;
}

.piib:hover {
    fill: var(--accent-contrast);
    background-color: var(--accent);
}

.piib-disabled {
    fill: var(--disabled-background);
    cursor: not-allowed;
}
.piib-disabled:hover {
    fill: var(--disabled-background);
    background-color: transparent;
}

</style>