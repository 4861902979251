<template>
<div>

    <PopupV1 :header="'Aksjeeierbok / dokumentasjon'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div v-if="params.mode == 'upload'" style="margin-bottom: 10px; color: var(--text-title); font-style: italic;">
                {{ 'Last opp aksjeeierbok eller annen dokumentasjon' }}
            </div>
            <div v-if="params.mode == 'confirm'" style="margin-bottom: 10px; color: var(--text-title); font-style: italic;">
                {{ 'Bekreft aksjeeierbok eller annen dokumentasjon' }}
            </div>
            <div class="flw100" v-if="params.mode == 'upload'">
                <VFFileuplaod
                    v-model="files"
                    :header="__t('files-upload-header')"
                    :hintText="__t('files-upload-hint')"
                    :browseText="__t('files-upload-browse')"
                    @input="validateInputs"
                />
            </div>
            <div class="flw100" style="margin-top: 20px;">
                <VFTextArea
                    v-model="comment"
                    :placeholder="__t('report-comment') + ' (' + __('common_optional').toLowerCase() + ')'"
                />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton v-if="params.mode == 'upload'"
                :text="'Last opp'"
                :icon="'upload'"
                @clicked="onUpload"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
            <VFButton v-if="params.mode == 'confirm'"
                :text="'Bekreft'"
                :icon="'check'"
                @clicked="onConfirm"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v2lib from '../v2lib';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';

import VFFileuplaod from '../components/form/inputs/VFFileupload.vue';
import VFTextArea from '../components/form/inputs/VFTextArea.vue';

export default {
    name: 'ShareholderBookDialog',
    components: {
        PopupV1,
        VFButton,
        VFFileuplaod,
        VFTextArea,
    },
    props: {
        params: { type: Object, default: null },
        client: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            files: {},
            comment: ''
        }
    },
    methods: {

        validateInputs() {
            this.dialog.valid = false;
            if (this.params.mode == 'confirm') {

                this.dialog.valid = true;

            }else if (this.params.mode == 'upload') {

                if (this.files.files && this.files.files.length > 0) {
                    this.dialog.valid = true;
                }

            }
        },

        async onUpload() {
            this.dialog.working = true;
            this.dialog.status = 'Laster opp';

            const envelope = await this.getEnvelope(this.client.envelope.id);
            console.log('envelope', envelope);

            console.log('files', this.files);
            const name = this.files.files[0].name;
            const data = this.files.files[0].data;
            await v2lib.addAttachmentToExistingEnvelope(envelope, name, data, v2lib.personalToken);

            const fileData = {
                type: 'form-manual',
                envelopeId: envelope.id,
                formKey: this.params.formKey || '',
                name: name
            };
            await v1api.uploadBook(this.client._id, [fileData], this.comment);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        async onConfirm() {
            this.dialog.working = true;
            this.dialog.status = 'Bekrefter';

            await v1api.confirmBook(this.client._id, this.comment);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        },

    },
    async created() {
        this.validateInputs();
        this.loading = false;
    }
}
</script>