<template>
<div class="ariski-plus">

    <div class="ariski-plus-dots">
        <vap-icon :icon="'menu-h'" :color="'var(--disabled-background)'" />
    </div>

    <div class="ariski-plus-click" @click="$emit('expand')">
        <div class="ariski-plus-chev">
            <vap-icon :icon="expanded ? 'chev-u' : 'chev-d'" />
        </div>
        <div class="ariski-plus-text">
            {{ expanded ? 'Collapse' : 'Expand' }}
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'RiskExpandButton',
    props: {
        expanded: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {
        


    },
    created() {

        
    }
}
</script>
<style scoped>

.ariski-plus {
    float: left;
    width: 100%;
    position: relative;
    display: flex;
}

.ariski-plus-dots {
    padding-left: 10px;
    width: 50px;
}

.ariski-plus-click {
    flex-grow: 100;
    display: flex;
    border-bottom: solid 1px var(--input-border);
    background-color: var(--card-background);
    cursor: pointer;
}
.ariski-plus-click:hover {
    background-color: var(--item-hover);
}

.ariski-plus-chev {
    padding-top: 1px;
    padding-left: 4px;
    width: 40px;
}

.ariski-plus-text {
    margin-top: 8px;
}

</style>