<template>
    <div>
        <FlowDesigner :initialFlow="flow" />
    </div>
</template>

<script>
import FlowDesigner from '../v1flows/designer/FlowDesigner.vue';

export default {
    name: 'FlowDesignerView',
    components: {
        FlowDesigner,
    },
    data() {
        return {
            flow: null,
        }
    },
    methods: {

        async onSave(data) {
            
        },

    },
    created() {
        
        const flowKey = this.$route.query.key;
        //console.log('flowKey', flowKey);

        for (const i in this.$root.flowConfig.flows) {
            if (this.$root.flowConfig.flows[i].key == flowKey) {
                this.flow = this.$root.flowConfig.flows[i];
            }
        }

        //console.log('flow', this.flow);

    }
}
</script>