<template>
<div>
    
    <div class="email-base" v-if="reference.roleOptions[reference.roleIndex].email && reference.roleOptions[reference.roleIndex].email.subject">

        <div class="email-subject">
            <div class="email-preview-subject">
                <span>{{ replaceBrackets(reference.roleOptions[reference.roleIndex].email.subject[$root.locale.key]) }}</span>
            </div>
            <div class="email-preview-sender">
                <span v-if="!reference.customSender.enabled">{{ replaceBrackets('\{\{senderName\}\}') }}</span>
                <span v-if="reference.customSender.enabled">{{ reference.customSender.firstName + ' ' + reference.customSender.lastName }}</span>
                <span class="email-preview-sender-email">{{ '&#60;' + 'noreply@verified.eu' + '&#62;' }}</span>
            </div>
        </div>

        <div class="email-preview" style="text-align: center;">
            <!-- <div class="email-preview-sender">
                <label>{{ __('settings_sender') + ':' }}</label><label style="margin-left: 8px; font-weight: bold;">{{ replaceBrackets('\{\{senderName\}\}') }}</label>
            </div> -->
            
            <br><br><br>
            <img :src="$root.config.branding.logoEmail" :style="'width: 200px;'" />
            <br><br>
            <h2>{{ replaceBrackets(reference.roleOptions[reference.roleIndex].email.header[$root.locale.key]) }}</h2>
            <br>
            <div v-html="replaceBrackets(reference.roleOptions[reference.roleIndex].email.message[$root.locale.key])" />
            <br><br>
            <span class="email-preview-button">{{ reference.roleOptions[reference.roleIndex].email.button[$root.locale.key] }}</span>
            <br><br><br>
            <div v-html="replaceBrackets(reference.roleOptions[reference.roleIndex].email.hint[$root.locale.key])" />
            <br>
            <p>{{ replaceBrackets(reference.roleOptions[reference.roleIndex].email.footer[$root.locale.key]) }}</p>
            <br><br>
        </div>

<!--         <div class="email-footer">

            <div>
                {{ 'Alt innhold i denne meldingen er personlig' }}
            </div>
                
            <div>
                {{ 'Denne e-posten inneholder konfidensiell informasjon og får ikke videresendes til urettmessige personer. Dersom du har mottatt denne e-posten ved en feil, vennligst kontakt avsenderen umiddelbart og slett deretter denne e-posten. Dokumentet kan lastes ned i 90 dager.' }}
            </div>
                
            <div>
                <span style="">{{ 'Konvolutt-ID:' }}</span>
                <span style="">{{ 'AbCDEFgHi' }}</span>
            </div>

            <div>
                <img :src="$root.config.kycs[0].email.logo" />
            </div>

        </div> -->

    </div>

    <div v-if="!reference.roleOptions[reference.roleIndex].email || !reference.roleOptions[reference.roleIndex].email.subject">
        {{ 'There is no email for this role. Select another role using the role selector.' }}
    </div>
    

</div>
</template>
<script>


export default {
    name: 'EmailEditor',
    components: {
        
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() { 
        return {
            index: 1
        }
    },
    methods: {

        

    },
    created() {
        
    }
}
</script>
<style scoped>

.email-base {
    max-width: 600px;
    margin: 0 auto;
}

.email-preview {
    margin-top: 5px;
    background-color: white;
}

.email-preview-button {
    padding: 12px 40px;
    border-radius: 24px;
    color: white;
    background-color: var(--button);
}

.email-preview-subject {
    font-size: 20px;
    color: var(--text-title);
}

.email-preview-sender {
    margin-top: 3px;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-title);
}

.email-preview-sender-email {
    margin-lefT: 5px;
    font-size: 16px;
    font-weight: initial;
    color: var(--text-weak);
}

</style>