<template>
<div class="frv2-clientbar">

    <vap-row>
        <vap-group auto noPadding>
            <vap-label :text="__('common_name')" desc/>
            <vap-label :text="companyTitleCase(client.name)" value />
        </vap-group>
        <vap-group auto noPadding>
            <vap-label :text="__('company_orgnr')" desc />
            <vap-label :text="client.identity" value />
        </vap-group>
    </vap-row>

</div>
</template>

<script>
export default {
    name: 'FormClientBar',
    components: {
        
    },
    props: {
        client: { type: Object, default: null },
    },
    data() {
        return {
            
        }
    },
    methods: {



    },
    created() {
        
    }
}
</script>
<style scoped>

.frv2-clientbar {
    float: left;
    display: flex;
    width: 100%;
    height: 62px;

    padding-top: 12px;
    padding-left: 26px;

    border-bottom: 2px solid var(--input-border);
    background-color: var(--stepper-bg);
    box-sizing: border-box;
}

</style>
