import log from '@/log';
import api from '@/portalapi';
import locale from '@/locale';
import v2lib from '@/v2lib';
import formHelper from './formHelper';
import flowHelper from './flowHelper';
import pdfHelper from './pdfHelper';
import moduleHelper from './modules/moduleHelper';
import signModuleHelper from './modules/signing/signModuleHelper';
import kycModuleHelper from './modules/kyc/kycModuleHelper';
import formLocalData from './formLocalData';
import formValidation from './formValidation';
import logicHelper from './logicHelper';
import verified from '@/verified';
import axios from 'axios';
import v1exceptions from '../../v1helpers/v1exceptions';

import pdfGenerator from './pdfGenerator';
import v1api from '../../v1helpers/v1api';
import v1tasks from '../../v1helpers/v1tasks';
const viewHelper = {

    useLocalStorage: false,
    logStuff: false,

    onDictionaryMatch(view, input, value, card, index) {
        if (input.logic && input.logic.dmatch) {
            for (const i in input.logic.dmatch.keys) {
                const item = formHelper.getItemFromKey(view.pages, input.logic.dmatch.keys[i]);
                if (item.isList) {
                    card.model[index][item.listModel] = value;
                    this.onValidateInput(view, item, index);
                }else {
                    if (typeof value == 'object') {
                        item.model = value[item.modelKey];
                    }else {
                        item.model = value;
                    }
                    this.onValidateInput(view, item, null);
                }
            }
        }
    },

    onStepperKeyChanged(view, event) {
        log.x('FRv2: Stepper key changed', event);

        if (!view.viewOnly) view.pages[view.pageIndex].validation = formValidation.validatePage(view, view.pages[view.pageIndex], view.pages, view.roleKey, true);
        if (this.logStuff) console.log('VAL', view.pages[view.pageIndex].validation);

        if (!view.viewOnly) view.updateValidation();

        view.stepKey = event.key;
        view.pageIndex = event.index;
        if (this.logStuff) console.log('key changed to ' + view.stepKey + '(' + view.pageIndex + ')', view.pages);
        if (this.logStuff) console.log('steps', view.steps);
        if (this.logStuff) console.log('pageIndex', view.pageIndex);

        if (view.viewOnly) return;

        // Validate all pages up to this one
        for (const i in view.pages) {
            if (i < view.pageIndex) {
                if (!view.pages[i].validation) {
                    view.pages[i].validation = formValidation.validatePage(view, view.pages[i], view.pages, view.roleKey, true);
                }
            }
        }

        if ((view.canSave || view.autoSave) && view.hasChanges) view.saveForm();

        // Validate this page
        if (!view.pages[view.pageIndex].validation) view.pages[view.pageIndex].validation = formValidation.validatePage(view, view.pages[view.pageIndex], view.pages, view.roleKey, false);
        view.updateValidation();
    },

    async onStepperSubmit(view) {
        log.x('FRv2: Stepper on submit');
        view.showMainSpinner(true, view.__('loading_submitting_form') + '...');
        if (view.testLocally) {
            flowHelper.useLocalStorage = true;
        }

        try {

            const existingData = JSON.parse(view.$root.formData);
            if (this.logStuff) console.log('existingData', existingData);
            let envelope = view.envelope;
            let userdata;
            let flowType = 'unknown';

            if (existingData && existingData.resetForm && existingData.client) {
                await v1api.resetForm(existingData.client._id, view.formData.key);
            }else if (existingData && existingData.resend && existingData.client) {
                await v1api.resetForm(existingData.client._id, view.formData.key);
            }
            
            if (view.isOwner) {
                flowType = view.formData.flowType;
                if (view.isAnalog) {
                    // Use direct flow for analog form
                    flowType = 'direct';
                }
                envelope = await flowHelper.createEnvelope(view.$root, flowType);
                log.x('FRv2: Envelope created', envelope);
            }else {
                flowType = envelope.userdata.formConfig.flowType;
                // Get the latest data, in case something went wrong and envelope has already been updated
                // The submit method will check for duplicate recipients
                if (flowType == 'sign') {
                    envelope = await flowHelper.refreshEditorEnvelope(view.$root, flowType, envelope);
                }
                log.x('FRv2: Submitting with existing envelope', envelope);
            }

            if (this.logStuff) console.log('flowType', flowType);
            let recipient = formHelper.getRecipient(view, view.pages, view.roles, view.roleKey);

            if (view.chkModel && view.chkModel.enabled && (!recipient || !recipient.firstName)) {

                if (view.roleKey == 'owner' && flowType != 'direct') {

                    if (this.logStuff) console.log('Checklist owner');

                    recipient = {
                        firstName: view.chkModel.cdd.firstName,
                        lastName: view.chkModel.cdd.lastName,
                        email: view.chkModel.cdd.email,
                        lang: 'en_EN',
                        method: 'email',
                        order: 2,
                        role: { action: 'edit', label: 'Approve', name: 'approver' }
                    };

                }else if (view.roleKey == 'approver' && !view.chkModel.finalValidation) {

                    if (this.logStuff) console.log('Checklist approver');

                    recipient = {
                        firstName: view.chkModel.branch.firstName || 'Magnus',
                        lastName: view.chkModel.branch.lastName || 'Branch',
                        email: view.chkModel.branch.email || 'magnus.ursin@verified.eu',
                        lang: 'en_EN',
                        method: 'email',
                        order: 2,
                        role: { action: 'edit', label: 'Provide info', name: 'provider' }
                    };

                }else if (view.roleKey == 'provider') {

                    if (this.logStuff) console.log('Checklist provider');

                    recipient = {
                        firstName: view.chkModel.cdd.firstName,
                        lastName: view.chkModel.cdd.lastName,
                        email: view.chkModel.cdd.email,
                        lang: 'en_EN',
                        method: 'email',
                        order: 2,
                        role: { action: 'edit', label: 'Approve', name: 'approver' }
                    };


                }else {
                    if (this.logStuff) console.log('Checklist no recipient');
                    recipient = null;
                }

            }

            if (this.logStuff) console.log('recipient', recipient);
            
            if (view.signModel && view.signModel.hasCheckout && !view.isOwner) {
                if (view.signModel.direct) {
                    recipient = {
                        firstName: view.recipient.givenName,
                        lastName: view.recipient.familyName,
                        email: view.recipient.email,
                        lang: view.recipient.language || view.$root.locale.key,
                        method: view.signModel.method,
                        order: 2,
                        role: { action: 'sign', label: 'Signer', name: 'signer' }
                    };
                }else {
                    recipient = view.signModel.signers[0];
                }
            }
            if (this.logStuff) console.log('signModel ', view.signModel);

        
            if (recipient && !view.isAnalog) {
                if (flowType == 'sign' && recipient.role.action == 'sign') {
                    if (this.logStuff) console.log('SIGNMODEL', view.signModel);
                    if (this.logStuff) console.log('REC', view.recipient);



                    if (view.signModel && view.signModel.hasCheckout) {
                        // Form has checkout component

                        if (view.signModel.direct) {

                            log.x('FRv2: Submitting DIRECT with recipient', recipient);
                            await flowHelper.addRecipient(envelope, recipient, flowType);

                        }else {

                            for (const i in view.signModel.signers) {
                                log.x('FRv2: Adding recipient from checkout', view.signModel.signers[i]);
                                await flowHelper.addRecipient(envelope, view.signModel.signers[i], flowType);
                            }

                        }
                        
                    }else {

                        if (view.signModel.direct) {

                            // Set the signer to the current recipient
                            recipient.firstName = view.recipient.givenName;
                            recipient.lastName = view.recipient.familyName;
                            recipient.email = view.recipient.email;
                            recipient.lang = view.recipient.language || view.$root.locale.key;
                            recipient.method = view.signModel.method;
                            recipient.role = { action: 'sign', label: 'Signer', name: 'signer' };

                            log.x('FRv2: Submitting DIRECT with recipient', recipient);
                            await flowHelper.addRecipient(envelope, recipient, flowType);

                        }else {

                            // Add all signers

                            //console.log('ADD SIGNERS');

                            const signers = formHelper.getSigners(view, view.pages, view.roles, view.roleKey);
                            //console.log('signers', signers);

                            for (const i in signers) {
                                view.signModel.signers.push({
                                    firstName: signers[i].firstName,
                                    lastName: signers[i].lastName,
                                    email: signers[i].email,
                                    lang: signers[i].lang || view.$root.locale.key,
                                    method: signers[i].method,
                                    order: Number(i) + 2,
                                    role: { action: 'sign', label: 'Signer', name: 'signer' }
                                });
                            }
                            for (const i in view.signModel.signers) {
                                log.x('FRv2: Adding recipient from form signatories', view.signModel.signers[i]);
                                await flowHelper.addRecipient(envelope, view.signModel.signers[i], flowType);
                            }

                        }
                    }

                }else {
                    if (!recipient.lang) recipient.lang = view.$root.locale.key;
                    log.x('FRv2: Submitting with recipient', recipient);
                    await flowHelper.addRecipient(envelope, recipient, flowType);
                }
            }else {
                if (flowType == 'direct') {
                    console.log('user', view.$root.user);
                    recipient = {
                        firstName: view.$root.user.givenName,
                        lastName: view.$root.user.familyName,
                        email: view.$root.user.email,
                        lang: view.$root.locale.key,
                        role: { action: 'sign', label: 'Creator', name: 'signer' }
                    }
                    log.x('FRv2: Submitting with recipient (direct)', recipient);
                    await flowHelper.addRecipient(envelope, recipient, flowType);
                }else {
                    log.w('FRv2: No recipient, final submit');
                }
            }


            // Create logic outputs
            const logicData = {
                outputs: []
            };
            for (const i in view.logic) {
                const output = logicHelper.performLogic(view, i, false);
                view.logicResults[view.logic[i].key] = output;
                log.x('Logic: ' + view.logic[i].name + ' processed', output);
                logicData.outputs.push({
                    key: view.logic[i].key,
                    indexKey: view.logic[i].indexKey,
                    indexed: view.logic[i].indexed,
                    output: output
                });
            }


            if (view.isOwner) {
                // Owner submit

                const mailConfig = flowHelper.getMailData(view.$root, view.formData, {}, view.$root.locale.key, flowType);
                if (existingData && existingData.customer) {
                    mailConfig.values.push({ key: 'customerName', type: 'value', value: existingData.customer.name })
                }

                const formUserdata = formHelper.getFormUserdata(view.formData, view.pages);
                userdata = {
                    formConfig: formUserdata.config,
                    formPages: formHelper.stripPages(formUserdata.pages),
                    mailConfig: mailConfig,
                    mailGreeting: '',
                    attachments: [],
                    logicData: logicData,
                    clientTags: [],
                };
                if (recipient && recipient.greeting) userdata.mailGreeting = recipient.greeting;

                userdata.flowParams = {
                    isAnalog: view.isAnalog || false,
                };

                // Always add customer ID to userdata if available
                if (existingData && existingData.customer) {
                    userdata.customer = {
                        id: existingData.customer._id
                    };
                    if (existingData.customer.tags) {
                        userdata.clientTags = existingData.customer.tags;
                        console.log('userdata', userdata);
                    }
                }

                // Modules onSetUserdata
                for (const i in view.modules) {
                    //console.log('module', view.modules[i]);
                    await moduleHelper.onSetUserdata(view.$root, view, view.modules[i], envelope, userdata, view.isOwner, recipient);
                }

                for (const i in view.attachments) {
                    //console.log('atc', view.attachments[i]);
                    let include = true;
                    if (view.attachments[i].logic) {
                        include = false;
                        const item = formHelper.getItemFromKey(view.pages, view.attachments[i].logic.key);
                        //console.log('ITEM AAA', item);
                        for (const key in item.model) {
                            if (key == view.attachments[i].logic.okey && item.model[key]) {
                                include = true;
                                //console.log('include set to true');
                            }
                        }
                    }
                    if (include && view.attachments[i].type == 'document-form') {
                        const response = await api.getForm(view.attachments[i].formKey);
                        //console.log('resposne', response.formData);
                        const base64 = await pdfGenerator.getBase64(view.$root, response.formData);
                        const bytes = v2lib.getUint8ArrayFromBase64(base64);
                        await v2lib.addDocument(envelope, this.replaceNameVariables(view, response.formData.name, existingData), bytes.buffer);
                    }
                    if (include && view.attachments[i].type == 'portal-filestore-attachment') {
                        try {
                            let filename = view.attachments[i].filename;
                            
                            const res = await v1api.get("filestore/" + view.attachments[i].fileId)

                            await verified.uploadBase64Attachment(envelope.id, envelope.documentId, res.file.base64.split(",")[1], filename);
                        } catch(err) {
                            alert("Failed to add attachment")
                            console.log(err)
                        }
                    }
                }

                const attachments = formHelper.getAttachments(view, view.pages, view.roleKey);
                for (const i in attachments) {
                    if (attachments[i].existing) {
                        log.x('FRv2: Skipping EXISTING attachment?', attachments[i]);
                        if (attachments[i].isResend) {
                            log.x('FRv2: Uploading RESEND attachment', attachments[i]);
                            userdata.attachments.push({
                                name: attachments[i].name,
                                type: attachments[i].type,
                                fileType: attachments[i].fileType,
                                fileSize: attachments[i].fileSize,
                            });
                            await verified.uploadBase64Attachment(envelope.id, envelope.documentId, attachments[i].binary, attachments[i].name);
                        }
                    }else {
                        log.x('FRv2: Uploading NEW attachment', attachments[i]);
                        userdata.attachments.push({
                            name: attachments[i].name,
                            type: attachments[i].type,
                            fileType: attachments[i].fileType,
                            fileSize: attachments[i].fileSize,
                        });
                        await v2lib.addAttachment(envelope, attachments[i].name, attachments[i].binary);
                    }
                    
                }

                // Remove base64 data from existing attachments
                userdata.formPages = formHelper.stripAttachmentData(userdata.formPages);

                const personId = (existingData && existingData.idcPerson) ? existingData.idcPerson.id : 'x';
                const customerId = (existingData && existingData.customer) ? existingData.customer._id : 'x';

                // Support for V0, remove eventually
                let apiRoute = 'v1api';
                let renderRoute = `clients/${customerId}/userdata`;
                let updateRoute = `clients/${customerId}/forms`;
                if (existingData.idcPerson || existingData.isV0) {
                    apiRoute = 'portal';
                    renderRoute = `forms/${customerId}/test`;
                    updateRoute = `forms/${customerId}/test`;
                }

                const tokenResponse = await api.getFormBearerToken(personId, customerId, envelope.id);
                userdata.credentials = {
                    id: customerId,
                    cid: view.$root.credentials.cid,
                    key: view.$root.credentials.key,
                    uid: view.$root.credentials.uid,
                    bearerToken: tokenResponse.token,
                    apiUrl: view.$root.isDEV ? 'https://dev-verified.eu/api/aml-v1/' + apiRoute : 'https://app.verified.eu/api/aml-v1/' + apiRoute,
                    /* configRoute: `forms/${formUserdata.config.key || 'na'}/userdata`, */
                    renderRoute: renderRoute,
                    updateRoute: updateRoute,
                    isV0: (existingData && existingData.isV0) ? true : false,
                    cc: view.$root.config.params.baseCountry,
                    stat: view.$root.config.params.accountStatus
                };
                if (userdata.credentials.id) {
                    const baseLink = view.$root.isDEV ? 'https://dev-verified.eu/web/aml-portal/#' + apiRoute : 'https://app.verified.eu/web/aml-portal/#';
                    mailConfig.clientLink = baseLink + '/v1client?id=' + userdata.credentials.id;
                }
                if (userdata.credentials.cid == 'rkeUXcLuv') {
                    userdata.credentials.key = 'default';
                }
                console.log('userdata.credentials', userdata.credentials);
                //console.log('formkey', formUserdata.config);

                if (flowType == 'direct' || (recipient && recipient.role && recipient.role.action == 'sign')) {
                    userdata._isForwarded = true; // if the sign flow skips editors, this must be true to send notifications to signers
                    view.preparePdfData(userdata);
                }


                // Set metadata
                const metadata = {
                    silentParties: [
                        /* {
                            givenName: 'Test',
                            familyName: 'Testerson',
                            email: 'magnus.ursin+silent1@verified.eu',
                            language: 'nb_NO'
                        } */
                    ],
                    language: 'en_EN',
                    skipNotifySender: false,
                    senderLanguage: view.$root.locale.key,
                };

                console.log('flowConfig', view.formData);
                if (view.formData.flowConfig.flags) {
                    if (!view.formData.flowConfig.flags.ownerCopy) {
                        metadata.skipNotifySender = true;
                    }
                    if (view.formData.flowConfig.flags.signedCopy && view.formData.flowConfig.flags.signedCopy.enabled) {
                        metadata.silentParties.push({
                            givenName: view.formData.flowConfig.flags.signedCopy.firstName,
                            familyName: view.formData.flowConfig.flags.signedCopy.lastName,
                            email: view.formData.flowConfig.flags.signedCopy.email,
                            language: view.formData.flowConfig.flags.signedCopy.language || view.$root.locale.key
                        });
                    }
                }

                if (this.logStuff) console.log('flowConfig', view.formData);
                // Set custom sender if enabled
                if (view.formData.flowConfig.customSender && view.formData.flowConfig.customSender.enabled) {
                    metadata.sender = {
                        givenName: view.formData.flowConfig.customSender.firstName,
                        familyName: view.formData.flowConfig.customSender.lastName,
                        email: view.formData.flowConfig.customSender.email,
                    };
                }

                // Modules onSetMetadata
                for (const i in view.modules) {
                    await moduleHelper.onSetMetadata(view.$root, view, view.modules[i], envelope, userdata, metadata, view.roleKey, recipient);
                }
                log.x('FRv2: Setting metadata', metadata);
                await v2lib.setMetadata(envelope, metadata);

                this.addIndexedValuesToEmail(view, userdata);

                const envelopeName = this.replaceNameVariables(view, view.formData.name, existingData) + '.pdf';
                const envelopeTags = ['form'];

                //console.log('sms recipient', recipient);
                if (recipient.useSms) {
                    let greeting = 'You have received an invitation to sign documents';
                    for (const i in view.formData.flowConfig.roles) {
                        if (view.formData.flowConfig.roles[i].key == recipient.role.name) {
                            greeting = view.formData.flowConfig.roles[i].email.smsMessage[recipient.lang];
                        }
                    }
                    greeting = flowHelper.replaceDynamicValues(view.$root, greeting, recipient.lang);
                    await v2lib.setSMSGreeting(envelope, greeting);
                }

                log.x('FRv2: Submitting with userdata', userdata);
                await flowHelper.setUserdata(envelope, userdata, flowType);
                await flowHelper.setNameAndTags(envelope, userdata, envelopeName, envelopeTags, flowType);
                await flowHelper.submitEnvelope(envelope, flowType);

                if (view.$root.config.features.autoActions) {
                    if (view.$root.config.features.autoUpdates.enabled) {
                        await v1tasks.addFormStatusTask(view.$root, envelope, userdata);
                    }
                    if (view.$root.config.features.autoReminders.enabled) {
                        await v1tasks.addFormReminderTask(view.$root, envelope, userdata);
                    }
                }

                log.x('FRv2: Waiting for envelope to be published');
                await v1api.waitForFormCreation(envelope, userdata);

            }else {
                // Editor submit

                userdata = envelope.userdata;
                userdata.formPages = formHelper.stripPages(view.pages);
                userdata.logicData = logicData;

                if (flowType == 'sign') {
                    userdata.finalSubmit = true;
                    if (view.signModel && view.signModel.direct) {
                        userdata._isForwarded = false;
                    }else {
                        userdata._isForwarded = true;
                    }
                    userdata.test = 'nei';
                }

                const attachments = formHelper.getAttachments(view, view.pages, view.roleKey);
                if (!userdata.attachments) userdata.attachments = [];
                for (const i in attachments) {
                    log.x('FRv2: Uploading attachment', attachments[i]);
                    userdata.attachments.push({
                        name: attachments[i].name,
                        type: attachments[i].type,
                        fileType: attachments[i].fileType,
                        fileSize: attachments[i].fileSize,
                    });
                    await v2lib.addAttachment(envelope, attachments[i].name, attachments[i].binary);
                }
                if (this.logStuff) console.log('recipient ', recipient);
                if (!recipient || (flowType == 'sign' && recipient.role.action == 'sign')) {
                    view.preparePdfData(userdata);
                }

                // Upload checkout attachments
                if (view.signModel && view.signModel.hasCheckout) {
                    for (const i in view.signModel.files) {
                        log.x('FRv2: Uploading checkout attachment', view.signModel.files[i]);
                        userdata.attachments.push({
                            name: view.signModel.files[i].name,
                            type: 'checkout-poa',
                            fileType: view.signModel.files[i].contentType,
                            fileSize: view.signModel.files[i].fileSize,
                        });
                        await v2lib.addAttachment(envelope, view.signModel.files[i].name, view.signModel.files[i].data);
                    }
                }

                // Remove base64 data from existing attachments
                userdata.formPages = formHelper.stripAttachmentData(userdata.formPages);

                this.addIndexedValuesToEmail(view, userdata);

                // Modules onSetUserdata
                for (const i in view.modules) {
                    if (this.logStuff) console.log('module', view.modules[i]);
                    await moduleHelper.onSetUserdata(view.$root, view, view.modules[i], envelope, userdata, view.isOwner, recipient);
                }

                if (userdata.formConfig.addModelToMetadata) {
                    const metadata = envelope.content.metadata;
                    const formModel = formHelper.getDataModel(view, false);
                    metadata.formKey = userdata.formConfig.key;
                    metadata.formModel = formModel;
                    console.log('adding METADATA', metadata);
                    await v2lib.setMetadata(envelope, metadata);
                }

                if (this.logStuff) console.log('RECIPIENT', recipient);
                if (this.logStuff) console.log('USERDATA', userdata);

                log.x('FRv2: Submitting with userdata', userdata);
                await flowHelper.setUserdata(envelope, userdata, flowType);
                await flowHelper.updateEnvelope(envelope, flowType);

            }

            log.x('FRv2: Envelope submitted', envelope);

            // Modules onFormSubmitted
            for (const i in view.modules) {
                if (this.logStuff) console.log('module', view.modules[i]);
                await moduleHelper.onFormSubmitted(view.$root, view, view.modules[i], recipient ? recipient.role.name : 'done', envelope);
            }

            if (view.testLocally) {

                view.showMainSpinner(true, 'TEST - Sending form to ' + recipient.firstName + ' ' + recipient.lastName + ' (' + recipient.email + ')...');
                await new Promise(r => setTimeout(r, 1000));
                window.location.href = `/landing/${envelope.id}/${recipient.id}`;
                
            }else if (flowType == 'sign' && view.signModel && view.signModel.direct && !view.isResend) {

                if (this.logStuff) console.log('REDIR: direct checkout');
                let signLinkResult = await v2lib.getSignLinkForm(view.$root, envelope, userdata.mailConfig);
                if (signLinkResult.ok) {
                    sessionStorage.setItem(envelope.id, v2lib.editorToken);
                    if (this.logStuff) console.log('LINK', signLinkResult.url);
                    window.location.href = signLinkResult.url;
                }else {
                    view.showError(true, 'Failed to get the sign link, the service might be temporary unavailable, try reloading the page in a few minutes');
                    const exceptionData = {
                        signLinkResult: signLinkResult
                    };
                    const fakeException = {
                        name: 'Sign link error',
                        message: 'Failed to get the sign link',
                        stack: 'Failed to get the sign link, the service might be temporary unavailable, try reloading the page in a few minutes'
                    };
                    v1exceptions.addException(view.$root, 'FormViewer', 'getSignLink', fakeException, exceptionData);
                }
                
            }else {
                let currentRole = {};
                for (const i in view.roles) {
                    if (view.roles[i].key == view.roleKey) currentRole = view.roles[i];
                }
                if (this.logStuff) console.log('roles', view.roles);
                if (this.logStuff) console.log('roleKey', view.roleKey);
                if (this.logStuff) console.log('currentRole', currentRole);
                if (currentRole.hasLanding) {
                    
                    if (this.logStuff) console.log('REDIR: landing');
                    view.redirectToLanding();

                }else {

                    if (this.logStuff) console.log('REDIR: link');
                    let redirect = currentRole.redirect;
                    if (!redirect) redirect = 'https://verified.eu';

                    if (redirect == 'popup') {

                        view.onFormCompleted();

                    }else {
                        if (redirect == 'clients' || redirect == 'client' || redirect == 'persons') {

                            // Wait a few seconds to allow the client to be updated from the flow
                            // await new Promise(r => setTimeout(r, 2500));
                            // No longer needed, the system will now wait for confirmation
                            view.showMainSpinner(false);
        
                            if (redirect == 'clients') {
        
                                if (view.$root.config.portal.useV1) {
                                    view.$parent.$parent.navigate({ path: '/v1clients' });
                                }else {
                                    view.$parent.$parent.navigate({ path: '/clients' });
                                }
                                
        
                            }else if (redirect == 'client') {
        
                                if (view.$root.config.portal.useV1) {
                                    view.$parent.$parent.navigate({ path: '/v1client', query: { id: existingData.customer._id } });
                                }else {
                                    view.$parent.$parent.navigate({ path: '/client', query: { id: existingData.customer._id } });
                                }
        
                            }else if (redirect == 'persons') {
        
                                if (view.$root.config.portal.useV1) {
                                    view.$parent.$parent.navigate({ path: '/v1client', query: { id: existingData.customer._id, tab: 'persons' } });
                                }else {
                                    view.$parent.$parent.navigate({ path: '/client', query: { id: existingData.customer._id } });
                                }
                            }
        
                        }else {
                            if (this.logStuff) console.log('redirect');
                            window.location.href = redirect;
                        }
                    }
                }
            }

        }catch (ex) {
            console.log('ex', ex);
            view.showError(true, 'Failed to submit form: ' + ex.message);
            const exceptionData = {

            };
            v1exceptions.addException(view.$root, 'FormViewer', 'onSubmit', ex, exceptionData);
        }

    },

    replaceNameVariables(view, name, existingData) {
        let output = (name && name[view.$root.locale.key]) ? name[view.$root.locale.key] : 'Custom form';
        if (existingData) {
            if (existingData.customer && existingData.customer.name) output = output.split('{{customerName}}').join(existingData.customer.name);
            if (existingData.person && existingData.person.displayName) output = output.split('{{personName}}').join(existingData.person.displayName);
        }
        const inputs = formHelper.getVisibleInputs(view, view.pages, view.roles, 'done');
        for (const i in inputs) {
            if (inputs[i].dbIndex) {
                output = output.split('{{' + inputs[i].modelKey + '}}').join(inputs[i].model);
            }
        }
        return output;
    },

    replaceDynamicVariables(view, input, existingData) {
        let output = input;
        if (existingData) {
            if (existingData.customer && existingData.customer.name) output = output.split('{{customerName}}').join(existingData.customer.name);
            if (existingData.person && existingData.person.displayName) output = output.split('{{personName}}').join(existingData.person.displayName);
        }
        for (const key in view.existingValues) {
            output = output.split('{{' + key + '}}').join(view.existingValues[key]);
        }
        return output;
    },

    addIndexedValuesToEmail(view, userdata) {
        const values = [];
        const inputs = formHelper.getVisibleInputs(view, view.pages, view.roles, 'done');
        for (const i in inputs) {
            if (inputs[i].dbIndex) {
                console.log('input db', inputs[i]);
                values.push({
                    key: inputs[i].modelKey,
                    type: 'value',
                    value: inputs[i].model
                });
            }
        }
        for (const i in values) {
            let match = false;
            for (const j in userdata.mailConfig.values) {
                if (userdata.mailConfig.values[j].key == values[i].key) {
                    userdata.mailConfig.values[j].value = values[i].value;
                    match = true;
                }
            }
            if (!match) userdata.mailConfig.values.push(values[i]);
        }
    },

    // TODO rename this more generally
    onPersonInput(view, card, input, index) {
        view.hasChanges = true;
        if (this.logStuff) console.log('hasChanges');
        if (card.isPerson) {
            kycModuleHelper.onPersonInput(card, input, index, view.pages, view);
        }else {
            kycModuleHelper.onAnyInput(card, input, index, view.pages, view);
        }

        // Process logic
        for (const i in view.logic) {
            const output = logicHelper.performLogic(view, i, false);
            view.logicResults[view.logic[i].key] = output;
            log.x('Logic: ' + view.logic[i].name + ' processed', output);
        }

        this.processSteps(view, true);
    },

    showPersonList(view, card, index) {
        view.personListParams.card = card;
        view.personListParams.index = index;
        view.personListParams.list = kycModuleHelper.getPersonList(view.pages);
        view.personListParams.show = true;
    },

    preparePdfData(view, userdata) {
        userdata.pdfConfig = pdfHelper.getConfig(view.$root, view.formData);
        userdata.pdfMeta = pdfHelper.getMetadata(userdata, view.$root.locale.key);
        userdata.pdfPages = pdfHelper.processPages(view.pages, view.$root.locale.key, false, userdata, view);
        userdata.tagList = [];

        console.log('pdf modules', view.modules);

        for (const i in view.modules) {

            console.log('pdf module', view.modules[i]);

            if (view.modules[i].type == 'client') {

                for (const j in view.modules[i].tags) {

                    const tag = view.modules[i].tags[j];
                    console.log('pdf tag', tag);

                    let valid = false;
                    if (tag.logic && tag.logic.visible) {
                        valid = formHelper.checkLogicTrigger(view, tag, false, view.pages, 'visible');
                        console.log('pdf checking tag logic', valid);
                    }else if (tag.enabled) {
                        valid = true;
                        console.log('pdf tag enabled');
                    }

                    if (valid) {
                        console.log('pdf adding tag', tag.tag);
                        userdata.tagList.push(tag.tag);
                    }

                }

            }

        }


        if (this.logStuff) console.log('pdfConfig', userdata.pdfConfig);
        if (this.logStuff) console.log('pdfMeta', userdata.pdfMeta);
        if (this.logStuff) console.log('pdfPages', userdata.pdfPages);
    },


    //#region Flow --------------------------------------------------------------------------------------------------------

    handleAction(view, type, key) {
        if (this.logStuff) console.log('action type', type);
        if (this.logStuff) console.log('action key', key);

        // Modules onAction
        for (const i in view.modules) {
            if (view.modules[i].type == type) {
                moduleHelper.onAction(view.$root, view, false, view.modules[i], key);
            }
        }
        moduleHelper.onSystemAction(view.$root, view, false, key);

    },

    handleLinkValue(view, link, value) {

        // Modules onLinkValue
        for (const i in view.modules) {
            if (view.modules[i].type == link.type) {
                moduleHelper.onLinkValue(view.$root, view, false, view.modules[i], link, value);
            }
        }
        moduleHelper.onSystemLinkValue(view.$root, view, false, link, value);

    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Validation --------------------------------------------------------------------------------------------------

    onValidateInput(view, input, index) {
        let done = true;
        let result = false;
        if (input.isList) {
            if (this.logStuff) console.log('index', index);
            const card = formHelper.getContainer(view.pages, input);
            if (input.type == 'chk-i') done = card.val[index][input.key].done;
            result = formValidation.validateSingleListInput(view, card, index, input, view.roleKey, done);
        }else {
            if (input.type == 'chk-i') done = input.val.done;
            result = formValidation.validateSingleInput(view, input, view.roleKey, done);
        }

/*             let pageValid = true;
        for (const i in view.pages[view.pageIndex].validation.list) {
            if (view.pages[view.pageIndex].validation.list[i].key == input.key) {
                view.pages[view.pageIndex].validation.list[i].valid = result;
            }
            if (!view.pages[view.pageIndex].validation.list[i].valid) pageValid = false;
        }
        view.pages[view.pageIndex].validation.valid = pageValid; */

        view.pages[view.pageIndex].validation = formValidation.validatePage(view, view.pages[view.pageIndex], view.pages, view.roleKey, true);

        view.updateValidation();
    },

    updateValidation(view) {
        let formValid = true;
        for (const i in view.steps) {
            view.steps[i].validation.done = view.pages[Number(view.steps[i].index)].validation ? view.pages[Number(view.steps[i].index)].validation.done : false;
            view.steps[i].validation.valid = view.pages[Number(view.steps[i].index)].validation ? view.pages[Number(view.steps[i].index)].validation.valid : false;
            if (!view.steps[i].validation.valid) formValid = false;
        }
        view.validation.formValid = formValid;
        if(view.signModel && view.signModel.hasCheckout && formValid && view.roleKey != 'owner'){
            view.validation.formValid = view.signModel.checkoutValid;
        }
        if (view.validation.disabled) view.validation.formValid = true;
    },

    validateEverything(view) {
        // Validate all pages
        for (const i in view.pages) {
            view.pages[i].validation = formValidation.validatePage(view, view.pages[i], view.pages, view.roleKey, true);
        }
        view.updateValidation();
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Data processing ---------------------------------------------------------------------------------------------

    async saveForm(view) {
        try {

            if (this.logStuff) console.log('ow', view.isOwner);

            view.isSaving = true;

            if (view.isOwner) {
                const formKey = view.formData.key;
                const formData = formHelper.stripPages(view.pages);

                const existingData = JSON.parse(view.$root.formData);
                await v1api.saveFormData(existingData.customer._id, formKey, formData);

                log.v('Form: Form data was saved for owner', formData);

            }else {
                const formKey = view.envelope.userdata.formConfig.key;
                const formData = formHelper.stripPages(view.pages);

                if (this.logStuff) console.log('userdata', view.envelope.userdata)

                await v1api.saveFormDataPublic(view.envelope.userdata.customer.id, formKey, formData);

                log.v('Form: Form data was saved for editor', formData);
            }

            view.isSaving = false;
            view.hasChanges = false;

            // TODO add separate flag for this
            if (view.canSave || view.flags.autoSaveMessage) {
                const messageKey = await view.$root.addMessage({
                    text: view.__t('form-autosave-saved'),
                    icon: 'check',
                    duration: 3000
                });
            }

        }catch (ex) {
            log.e('Form: Failed to save form data');
        }
    },

    processNewData(view, formData) {
        const processedData = formHelper.processData(formData);
        log.x('FRv2: Form data processed', processedData);
        view.formKey = formData.key;
        view.title = formData.title;
        view.pages = processedData.pages;
        view.modules = processedData.modules;
        view.attachments = processedData.attachments;
        view.roles = processedData.roles;
        if (view.roleKey != 'viewer') view.roleKey = 'owner';
        view.autoSave = processedData.autoSave;
        view.canSave = processedData.canSave;
        view.locales = processedData.locales;
        view.flags = processedData.flags;

        view.logic = processedData.logic || [];

        view.processSteps();

        view.title = formData.title;
    },

    processUserData2(view, formData, userdata, existingData) {
        const processedData = formHelper.processData(formData);
        log.x('FRv2: Form data processed', processedData);
        view.formKey = formData.key;
        view.title = formData.title;
        view.pages = processedData.pages;
        view.modules = processedData.modules;
        view.attachments = processedData.attachments;
        view.roles = processedData.roles;
        view.autoSave = processedData.autoSave;
        view.canSave = processedData.canSave;
        view.locales = processedData.locales;
        view.flags = processedData.flags;

        view.logic = processedData.logic || [];

        log.x('FRv2: Selecting role for recipient', view.recipient);
        const roleOptions = flowHelper.getRoleOptions();
        for (const i in roleOptions) {
            if (roleOptions[i].key == view.recipient.role.name) {
                view.roleKey = roleOptions[i].key;
            }
        }

        view.processSteps();
        this.setModelFromUserdata(view, view, view.pages, userdata, existingData, false);

        view.title = formData.title;
    },

    setModelFromUserdata(view, context, pages, userdata, existingData, isResend) {
        for (const i in pages) {
            for (const j in pages[i].cards) {
                if (pages[i].cards[j].type == 'list' || pages[i].cards[j].type == 'chk-a') {

                    const card = this.getCardModelFromUserdataFIX(view, pages, userdata, i, j, pages[i].cards[j]);
                    if(!card && pages[i].cards[j].type == 'list' || pages[i].cards[j].type == 'chk-a') {
                        context.$set(pages[i].cards[j], 'model', []);
                        context.$set(pages[i].cards[j], 'val', []);
                        if (pages[i].cards[j].type == 'chk-a') {
                            context.$set(pages[i].cards[j], 'itemModel', []);
                        }
                    } else {
                        context.$set(pages[i].cards[j], 'model', card.model || []);
                        context.$set(pages[i].cards[j], 'itemModel', card.itemModel || []);
                        context.$set(pages[i].cards[j], 'val', []);
                        for (const x in pages[i].cards[j].model) {
                            formHelper.addValidation(pages[i].cards[j]);
                        }
                    }
                    
                }else {
                    for (const k in pages[i].cards[j].inputs) {
                        const input = pages[i].cards[j].inputs[k];

                        if (input.desc) {
                            for (const loc in input.desc) {
                                // check if string because desc sometimes is `formatted: true`
                                // not sure why tho, mby the rich text formatter
                                if (typeof input.desc[loc] == 'string' && input.desc[loc].includes('{{')) {
                                    input.desc[loc] = this.replaceDynamicVariables(view, input.desc[loc], existingData);
                                }
                            }
                        }

                        if (input.type == 'preview') {

                            const model = { files: [] };
                            for (const z in view.existingFiles) {
                                if (view.existingFiles[z].info.type == input.fileKey) model.files.push(view.existingFiles[z]);
                            }
                            context.$set(input, 'model', model);
                            context.$set(input, 'val', { valid: false, done: false, message: '' });

                        }else if (input.type == 'upload') {

                            let model = this.getInputModelFromUserdataFIX(view, pages, userdata, input);

                            // Update the model with "existing = true" and add the downloaded data
                            console.log('files', model.files);
                            console.log('atcs', view.existingFiles);
                            if (model && model.files && model.files.length > 0) {
                                for (let n = model.files.length - 1; n > -1; n--) {
                                    model.files[n].existing = true;
                                    model.files[n].isResend = isResend || false;
                                    let match = false;
                                    for (const z in view.existingFiles) {
                                        if (view.existingFiles[z].info.name == model.files[n].name) {
                                            model.files[n].data = view.existingFiles[z].data;
                                            match = true;
                                        }
                                    }
                                    if (!match) model.files.splice(n, 1);
                                }
                            }

                            if (!model) model = { files: [] };

                            context.$set(input, 'model', model);
                            context.$set(input, 'val', { valid: false, done: false, message: '' });

                        }else {

                            

                            context.$set(input, 'model', this.getInputModelFromUserdataFIX(view, pages, userdata, input));
                            //console.log('model set to', this.getInputModelFromUserdataFIX(view, pages, userdata, input))
                            context.$set(input, 'val', { valid: false, done: false, message: '' });
                        }

                    }
                }
            }
        }
    },

    getCardModelFromUserdata(view, pages, userdata, pageIndex, cardIndex) {
        try {
            if (userdata.formPages[pageIndex].cards[cardIndex]) {
                if (this.logStuff) console.log('card model', userdata.formPages[pageIndex].cards[cardIndex]);
                return userdata.formPages[pageIndex].cards[cardIndex];
            }else {
                if (this.logStuff) console.log('no card model exists');
            }
        }catch (ex) {
            if (this.logStuff) console.log('no card model exists');

        }
        return '';
    },

    getCardModelFromUserdataFIX(view, pages, userdata, pageIndex, cardIndex, card) {
        try {

            for (const i in userdata.formPages) {
                for (const j in userdata.formPages[i].cards) {
                    if (userdata.formPages[i].cards[j].key == card.key) {
                        return userdata.formPages[i].cards[j];
                    }
                }
            }

            console.log('no card model exists');
        }catch (ex) {
            if (this.logStuff) console.log('no card model exists');

        }
        return '';
    },

    getInputModelFromUserdata(view, pages, userdata, pageIndex, cardIndex, inputIndex) {
        try {
            if (userdata.formPages[pageIndex].cards[cardIndex].inputs[inputIndex]) {
                return userdata.formPages[pageIndex].cards[cardIndex].inputs[inputIndex].model;
            }else {
                if (this.logStuff) console.log('no input model exists');
            }
        }catch (ex) {
            if (this.logStuff) console.log('no input model exists');

        }
        return '';
    },

    getInputModelFromUserdataFIX(view, pages, userdata, input) {
        try {
            for (const i in userdata.formPages) {
                for (const j in userdata.formPages[i].cards) {
                    for (const k in userdata.formPages[i].cards[j].inputs) {
                        const oInput = userdata.formPages[i].cards[j].inputs[k];
                        if (oInput.key == input.key) {
                            return oInput.model;
                        }
                    }
                }
            }
        }catch (ex) {
            if (this.logStuff) console.log('no input model exists');
        }
        return formHelper.getInputModel(view, view.$root, input);
    },
    
    processUserData(view, userdata) {
        const processedData = formHelper.processFormUserdata(userdata.formConfig, userdata.formPages);
        log.x('FRv2: Form userdata processed', processedData);

        view.pages = processedData.pages;
        view.modules = processedData.modules;
        view.attachments = processedData.attachments;
        view.roles = processedData.roles;

        log.x('FRv2: Selecting role for recipient', view.recipient);
        const roleOptions = flowHelper.getRoleOptions();
        for (const i in roleOptions) {
            if (roleOptions[i].key == view.recipient.role.name) {
                view.roleKey = roleOptions[i].key;
            }
        }

        view.processSteps();

        view.title = processedData.config.title;
    },

    processSteps(view, checkLogic) {
        view.steps = [];
        for (const i in view.pages) {
            if (view.pages[i].roles[view.roleKey] && this.isPageVisible(view, view.pages, view.pages[i], checkLogic)) {
                view.steps.push({
                    key: view.pages[i].key,
                    title: view.pages[i].title,
                    submitText: view.pages[i].submitText,
                    index: i,
                    showStepper: view.pages[i].showStepper,
                    validation: {
                        done: view.pages[i].validation ? view.pages[i].validation.done : false, 
                        valid: view.pages[i].validation ? view.pages[i].validation.valid : false 
                    }
                });
            }
        }
        //log.x('FRv2: Steps processed for role ' + view.roleKey, view.steps);
    },

    isPageVisible(view, pages, page, checkLogic) {
        if (!checkLogic) return true;
        if (page.logic && page.logic.visible) {
            return formHelper.checkVisible(view, page, false, pages, page);
        }
        return true;
    },

    goToNextRole(view) {
        if (this.logStuff) console.log('go to next');
        view.roleKey = view.getNextRole();
        view.processSteps();
    },

    getNextRole(view) {
        let currentRole = null;
        for (const i in view.roles) {
            if (view.roles[i].key == view.roleKey) currentRole = view.roles[i];
        }

        if (this.logStuff) console.log('roles', view.roles);
        if (this.logStuff) console.log('roleKey', view.roleKey);
        if (this.logStuff) console.log('currentRole', currentRole);

        const nextRole = currentRole.next;
        if (this.logStuff) console.log('nextRole', nextRole);

        return nextRole;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    onAttachmentDownloaded(view, attachment, data) {
        view.existingFiles.push({
            info: attachment,
            data: data
        });
    },

    setClientData(view, existingData) {
        const data = {
            name: '',
            identity: '',
        };
        if (existingData && existingData.client) {

            data.name = existingData.client.name;
            data.identity = existingData.client.identity;

        }
        view.clientData = data;
    },

    async onCreated(view) {
        const existingData = JSON.parse(view.$root.formData);
        this.setClientData(view, existingData);
        //console.log('existing', existingData);

        if (existingData && existingData.customer && existingData.customer.tags) {
            view.clientTags = existingData.customer.tags;
        }

        let isUsingExistingData = false;

        if (existingData && existingData.viewOnly) {

            view.roleKey = existingData.viewRole;
            view.processNewData(view.formData);
            const envelope = await view.getEnvelope(existingData.viewEnvelopeId);
            if (this.logStuff) console.log('envelope', envelope);
            const userdata = envelope.userdata;
            this.setModelFromUserdata(view, view, view.pages, userdata, null, false);
            view.canSave = false;
            view.viewOnly = true;
            

        }else if (existingData && existingData.savedPages && (view.formData.key == 'amlc01' || view.formData.key == 'qa01' || existingData.loadSavedData)) {

            // Load the form with saved data

            view.processNewData(view.formData);
            const userdata = {
                formPages: existingData.savedPages
            };
            this.setModelFromUserdata(view, view, view.pages, userdata, null, false);
            isUsingExistingData = true;

        }else if (existingData && existingData.resend) {

            // Resend the form with existing data

            view.processNewData(view.formData);
            const envelope = await view.getEnvelope(existingData.envelopeId);

            if (envelope.userdata.attachments) {
                for (const i in envelope.userdata.attachments) {
                    await flowHelper.downloadAttachment(view, envelope, envelope.userdata.attachments[i]);
                }
            }

            this.setModelFromUserdata(view, view, view.pages, envelope.userdata, null, true);
            isUsingExistingData = true;
            view.isResend = true;

        }else if (view.isOwner) {
            view.processNewData(view.formData);
            formHelper.createModel(view, view.$root, view.pages);
        }else {

            const response = await api.getForm(view.envelope.userdata.formConfig.key);
            let autoSaveWasLoaded = false;

            console.log('EDITOR LOADED', response);

            for (const i in response.formData.locales) {
                view.$root.editorLocales.push(response.formData.locales[i]);
            }

            if (view.envelope.userdata.customer && view.envelope.userdata.customer.id) {
                const response2 = await v1api.getFormDataPublic(view.envelope.userdata.customer.id, view.envelope.userdata.formConfig.key, view.envelope.id);
                if (response2.savedData && response2.savedData.length > 0) {
                    view.envelope.userdata.formPages = response2.savedData;
                    log.v('FRv2: Form loaded with saved data', view.envelope.userdata.formPages);
                    autoSaveWasLoaded = true;
                }
                view.existingValues = response2.values || {};
            }

            if (view.envelope.userdata.attachments) {
                for (const i in view.envelope.userdata.attachments) {
                    await flowHelper.downloadAttachment(view, view.envelope, view.envelope.userdata.attachments[i]);
                }
            }

            if (view.envelope.userdata.clientTags) {
                view.clientTags = view.envelope.userdata.clientTags;
            }

            view.processUserData2(response.formData, view.envelope.userdata, existingData);

            if (autoSaveWasLoaded && view.flags.autoSaveLoaded) view.setAutoSaveLoaded();
        }

        // Set analog mode
        if (existingData && existingData.isAnalog) {
            view.isAnalog = true;
            view.ownerIsEditor = true;
        }

        if (this.logStuff) console.log('roles', view.roles);

        if (this.logStuff) console.log('pages', view.pages);

        // Modules onFormCreated
        for (const i in view.modules) {
            if (this.logStuff) console.log('module', view.modules[i]);
            const userdata = (view.envelope && view.envelope.userdata) ? view.envelope.userdata : {};
            await moduleHelper.onFormCreated(view.$root, view, false, view.modules[i], view.pages, view.isOwner, userdata, isUsingExistingData);
        }
        moduleHelper.onFormCreatedSystem(view.$root, view, false, view.pages, view.isOwner, isUsingExistingData);

        if (this.logStuff) console.log('isRedirected', view.isRedirected);
        if (view.isRedirected) {
            if (this.logStuff) console.log('returning');
            return;
        }

        // Add list model to empty lists
        if (view.isOwner && !isUsingExistingData) {
            for (const i in view.pages) {
                for (const j in view.pages[i].cards) {
                    if (view.pages[i].cards[j].type == 'list' && view.pages[i].cards[j].model.length == 0) {
                        if (view.pages[i].cards[j].noEmpty) continue;
                        formHelper.addListModel(view.$root, view.pages[i].cards[j], {});
                    }
                }
            }
        }

        view.stepKey = view.steps[0].key;
        view.pageIndex = view.steps[0].index;

        view.pages[view.pageIndex].validation = formValidation.validatePage(view, view.pages[view.pageIndex], view.pages, view.roleKey, false);

        /* console.log('steps', view.steps);
        console.log('pages', view.pages);
        console.log('page', view.pages[view.pageIndex]); */

        // Trigger link input to link auto-filled inputs
        let valueWasLinked = false;
        for (const i in view.pages) {
            for (const j in view.pages[i].cards) {
                for (const k in view.pages[i].cards[j].inputs) {
                    if (view.pages[i].cards[j].inputs[k].logic && view.pages[i].cards[j].inputs[k].logic.link) {
                        for (const y in view.pages[i].cards[j].inputs[k].logic.link) {
                            const link = view.pages[i].cards[j].inputs[k].logic.link[y];
                            for (const x in view.modules) {
                                if (view.modules[x].type == link.type) {
                                    valueWasLinked = true;
                                    moduleHelper.onLinkValue(view.$root, view, false, view.modules[x], link, view.pages[i].cards[j].inputs[k].model);
                                }
                            }
                            moduleHelper.onSystemLinkValue(view.$root, view, false, link, view.pages[i].cards[j].inputs[k].model);
                        }
                    }
                }
            }
        }

        // Form should be validated if something was linked
        if (valueWasLinked) view.validateEverything();

        // TODO this should already be set by the main app?
        if (view.roleKey != 'owner') {
            let language = 'en_EN';
            if (view.recipient && view.recipient.language) language = view.recipient.language;
            if (view.$route.query.lang) language = view.$route.query.lang;
            view.$root.locale = locale.getLocale(view.$root.config.locales, language);
        }

        view.setTitle(view.title[view.$root.locale.key]);
        view.loading = false;
    }

};
export default viewHelper;