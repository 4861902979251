<template>
<div class="flw100" style="padding-top: 20px;">
    
    <vap-row>
        <vap-group c6>
            <vap-label :text="__('common_first_name')" desc required />
            <vap-input v-model="personModel.firstName" noValidation :placeholder="__('common_required')" @input="validatePerson" />
        </vap-group>
        <vap-group c6>
            <vap-label :text="__('common_last_name')" desc required />
            <vap-input v-model="personModel.lastName" noValidation :placeholder="__('common_required')" @input="validatePerson" />
        </vap-group>
        <vap-group c6>
            <vap-label :text="__('common_dob')" desc />
            <vap-datepicker v-model="personModel.dateOfBirth" outputISO noValidation :placeholder="__('common_optional')" @input="validatePerson" />
        </vap-group>
        <vap-group c6>
            <vap-label :text="__('common_citizenship')" desc />
            <vap-dropdown v-model="personModel.country" noValidation
                :options="$root.countriesJson"
                :commonOptions="$root.countriesCommon"
                :placeholder="__('common_optional')"
                :listKey="$root.locale.key"
                :displayKey="$root.locale.key"
                :outputKey="'key'"
                @input="validatePerson" />
        </vap-group>
        <vap-group c6 v-if="$root.config.search.person.visibleInputs.ssn">
            <vap-label :text="__('common_ssn')" desc />
            <vap-input v-model="personModel.ssn" noValidation :placeholder="__('common_required')" @input="validatePerson" />
        </vap-group>
        <vap-group c6>
            <vap-label :text="__('common_email')" desc />
            <vap-input v-model="personModel.email" noValidation
                :placeholder="__('common_optional')"
                @input="validatePerson" />
        </vap-group>
        <vap-group c6 v-if="!$root.config.search.person.visibleInputs.ssn">
            <vap-label :text="__('common_phone')" desc />
            <vap-phone v-model="personModel.phone" noValidation
                :placeholder="__('common_optional')"
                @input="validatePerson" />
        </vap-group>
    </vap-row>

</div>
</template>
<script>
export default {
    name: 'SearchPerson',
    components: {
        
    },
    data() {
        return {
            valid: false,
            searchValid: false,
            country: 'xx',
            source: 'manual',
            personModel: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                country: '',
                reference: '',
                phone: {
                    prefix: '+47',
                    number: ''
                },
                email: '',
                ssn: '',
            },
        }
    },
    methods: {
        
        validatePerson() {
            console.log('validate', this.personModel);
            if (this.personModel.firstName && this.personModel.lastName) {
                this.valid = true;
            }else {
                this.valid = false;
            }
            this.searchValid = this.valid;
            this.$emit('validate', this.valid);
        },

        resetSearch() {
            this.valid = false;
            this.searchValid = false;
            this.personModel = {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                country: '',
                reference: '',
                phone: {
                    prefix: '+47',
                    number: ''
                },
                email: '',
            };
            this.personModel.phone.prefix = this.$root.config.params.defaultPrefix;
            this.$emit('validate', this.valid);
        },

        getInitialData() {
            return {
                type: 'person',
                name: this.personModel.firstName + ' ' + this.personModel.lastName,
                identity: (this.personModel.dateOfBirth || '1800-01-01') + '-' + this.personModel.firstName.toLowerCase() + '-' + this.personModel.lastName.toLowerCase(),
                country: this.personModel.country,
                source: this.source,
                personData: {
                    name: this.personModel.firstName.toLowerCase() + ' ' + this.personModel.lastName.toLowerCase(),
                    firstName: this.personModel.firstName,
                    lastName: this.personModel.lastName,
                    dateOfBirth: this.personModel.dateOfBirth || '1800-01-01',
                    country: this.personModel.country,
                    email: this.personModel.email,
                    phone: this.personModel.phone,
                    roles: ['role_individual']
                }
            };
        },

    },
    async created() {
        this.personModel.phone.prefix = this.$root.config.params.defaultPrefix;
    }
}
</script>