<template>
<div class="vf-greeting-preview">

    <VFLabel desc :text="'Forhåndsvisning av melding'" />
    
    <div class="vf-greeting-preview-msg flw100">
        {{ renderedMessage }}
    </div>

</div>
</template>

<script>
import formHelper from '../formHelper';
import flowHelper from '../flowHelper';

import VFLabel from './VFLabel.vue';

export default {
    name: 'VFGreetingPreview',
    components: {
        VFLabel,
    },
    props: {
        cardData: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
    },
    data() {
        return {
            renderedMessage: '',
        }
    },
    watch: {
        'reference.validation.isValidating': {
            deep: false,
            handler(val) {
                this.renderMessage();
            }
        },
    },
    methods: {

        renderMessage() {
            const recipient = formHelper.getRecipient(this.reference, this.reference.pages, this.reference.roles, this.reference.roleKey);
            const message = this.getMessage(recipient);
            this.renderedMessage = this.replaceDynamic(message, recipient.lang);
        },

        getMessage(recipient) {
            if (this.designer) {

                return 'Message to the recipient';

            }else {
                for (const i in this.reference.formData.flowConfig.roles) {
                    if (this.reference.formData.flowConfig.roles[i].key == recipient.role.name) {
                        if (recipient.useSms) {
                            return this.reference.formData.flowConfig.roles[i].email.smsMessage[recipient.lang];
                        }else {
                            return this.reference.formData.flowConfig.roles[i].email.message[recipient.lang];
                        }
                    }
                }
            }
            return 'error';
        },

        replaceDynamic(input, language) {
            return flowHelper.replaceDynamicValues(this.$root, input, language);
        },

    },
    created() {
        this.renderMessage();
    }
}
</script>
<style scoped>

.vf-greeting-preview {
    float: left;
    width: 100%;
}

.vf-greeting-preview-msg {
    font-size: 15px;
    color: var(--input-text);
}

</style>