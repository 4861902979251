import defaultRisk from '../v1risk/defaultRisk';
const v1risk = {

    getDefaultParams() {
        return {
            key: 'default',
            params: [
                {
                    key: 'd01',
                    type: 'entity-type',
                    weight: 10
                },
                {
                    key: 'd02',
                    type: 'nace-code',
                    weight: 10
                }
            ]
        };
    },

    //#region Risk calculation --------------------------------------------------------------------------------------------

    determineRisk(root, params, client, persons) {
        const risk = {
            totalWeightML: 0,
            totalWeightTF: 0,
            weightNumber: 0,
            items: [],
            uncertainty: 0,
            missingString: '',
            riskValue: 0,
            riskText: ''
        };

        // Get default values
        let riskParams = null;
        let riskItems = [];
        for (const i in root.riskParams) {
            if (root.riskParams[i].flow == 'no-org-baml') {
                riskParams = JSON.parse(JSON.stringify(root.riskParams[i].params));
                riskItems = JSON.parse(JSON.stringify(root.riskParams[i].items));
            }
        }
        if (!riskParams) riskParams = defaultRisk.getDefaultScore('NO');
        if (!riskItems) riskItems = root.config.risk.autoItems;
        console.log('riskParams', riskParams);
        console.log('riskItems', riskItems);

        try {

            const personData = {
                keyRoleCount: 0,
                keyRoleScreened: 0,
                residences: [],
                nationalities: [],
                hasPep: false,
                hasSanction: false
            };

            for (const i in persons) {
                const person = persons[i];
                //console.log('risk person', person);

                if (person.screening.isPep) personData.hasPep = true;
                if (person.screening.hasSanction) personData.hasSanction = true;

                if (person.details.citizenship) {

                }

                if (person.details.residenceCountry || person.residenceCountry.available) {
                    const residenceCountry = person.details.residenceCountry || person.residenceCountry.value;
                    if (!personData.residences.includes(residenceCountry)) personData.residences.push(residenceCountry);
                }

                if (person.selected) {
                    personData.keyRoleCount += 1;
                    if (person.screening.done) personData.keyRoleScreened += 1;
                }
            }

            //console.log('risk person data', personData);


            let missingcount = 0;
            if (!client.company.lookup) {
                if (missingcount > 0) risk.missingString += ', ';
                risk.missingString += 'Company lookup not completed';
                risk.uncertainty += 25;
                missingcount += 1;
            }
            if (!client.kyc.done) {
                if (missingcount > 0) risk.missingString += ', ';
                risk.missingString += 'Self-declaration not completed';
                risk.uncertainty += 50;
                missingcount += 1;
            }
            if (personData.keyRoleCount == 0) {
                if (missingcount > 0) risk.missingString += ', ';
                risk.missingString += 'No key roles identified';
                risk.uncertainty += 50;
                missingcount += 1;
            }
            if (personData.keyRoleScreened < personData.keyRoleCount) {
                if (missingcount > 0) risk.missingString += ', ';
                risk.missingString += (personData.keyRoleCount - personData.keyRoleScreened) + ' key roles not screened';
                risk.uncertainty += (personData.keyRoleCount - personData.keyRoleScreened) * 10;
                missingcount += 1;
            }
            if (risk.uncertainty > 100) risk.uncertainty = 100;

            for (const i in riskItems) {
                const param = riskItems[i];
                let result = {
                    name: param.type,
                    value: '[UNKNOWN]',
                    riskML: 5,
                    riskTF: 5
                };
                if (param.type == 'entity-type') {

                    result = this.getEntityType(root, param, client, riskParams);
                    result.name = 'Entity type';

                }else if (param.type == 'nace-code') {

                    result = this.getNaceCodeX(root, param, client, riskParams);
                    result.name = 'Business code';

                }else if (param.type == 'reg-country') {

                    result = this.getRegCountry(root, param, client, riskParams);
                    result.name = 'Country of registration';

                }else if (param.type == 'startup-date') {

                    result = this.getStartupDate(root, param, client, riskParams);
                    result.name = 'Oppstartsdato';

                }else if (param.type == 'key-role-address') {

                    result = this.getPersonCountry(root, param, client, riskParams, personData);
                    result.name = 'Key roles geography';

                }else if (param.type == 'pep-sanction') {

                    result = this.getPepSanction(root, param, client, riskParams, personData);
                    result.name = 'PEP/Sanction';

                }
                risk.items.push({
                    name: result.name,
                    value: result.value,
                    riskML: result.riskML,
                    riskTF: result.riskTF,
                });
                if (result.match) {
                    risk.totalWeightML += this.getMultiplierValue(param, result.riskML);
                    risk.totalWeightTF += this.getMultiplierValue(param, result.riskTF);
                    risk.weightNumber += 1;
                }
            }



            console.log('totalWeightML', risk.totalWeightML);
            console.log('weightNumber', risk.weightNumber);

            risk.riskValue = Math.round(risk.totalWeightML / risk.weightNumber);
            if (risk.riskValue > 9) risk.riskValue = 9;

            if (risk.riskValue < 3) {
                risk.riskText = 'low';
            }else if (risk.riskValue < 6) {
                risk.riskText = 'medium';
            }else {
                risk.riskText = 'high';
            }


        }catch (ex) {
            console.log(ex);
            console.log('FAILED TO DETERMINE RISK');
        }
        return risk;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    getMultiplierValue(item, value) {

        if (item.multiH > 0) {
            if (value > 5) {
                return value * item.multiH;
            }
        }

        return value;
    },

    //#region Helpers -----------------------------------------------------------------------------------------------------

    getPepSanction(root, param, client, riskParams, personData) {
        const result = {
            riskML: 0,
            riskTF: 0,
            value: '',
            match: true
        };

        if (personData.keyRoleCount == 0) {
            result.riskML = 9;
            result.riskTF = 9;
            result.value = 'No key roles identified';
            return result;
        }

        if (!personData.hasPep && !personData.hasSanction) {
            result.riskML = 4;
            result.riskTF = 4;
            result.value = 'No PEP/Sanctions found';
            return result;
        }

        if (personData.hasPep || personData.hasSanction) {
            result.riskML = 9;
            result.riskTF = 9;
            result.value = 'PEP/Sanction found';
            return result;
        }

        return result;
    },

    getStartupDate(root, param, client, riskParams) {
        const result = {
            riskML: 0,
            riskTF: 0,
            value: '',
            match: true
        };

        //result.value = client.company.info.dateEstablished;

        if (!client.company.lookup) {
            result.riskML = 9;
            result.riskTF = 9;
            result.value = 'Unknown startup date';
            return result;
        }

        const now = new Date();
        const start = new Date(client.company.info.dateEstablished);
        const diff = this.monthDiff(start, now);

        let key = 'smu3';

        if (diff <= 3) {
            key = 'smu3';
            result.value = 'Less than 3 months ago';
        }else if (diff < 6) {
            key = 'smu6';
            result.value = 'Less than 6 months ago';
        }else if (diff < 12) {
            key = 'syu1';
            result.value = 'Less than a year ago';
        }else if (diff < 36) {
            key = 'syu3';
            result.value = 'Less than 3 years ago';
        }else if (diff < 60) {
            key = 'syo3';
            result.value = 'Less than 5 years ago';
        }else if (diff < 120) {
            key = 'syo5';
            result.value = 'Less than 10 years ago';
        }else {
            key = 'syo10';
            result.value = 'More than 10 years ago';
        }

        let riskML = riskParams['sd'][key];
        result.riskML = riskML;
        result.riskTF = riskML;
        
        return result;
    },

    monthDiff(d1, d2) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    },

    getPersonCountry(root, param, client, riskParams, personData) {
        const result = {
            riskML: 0,
            riskTF: 0,
            value: '',
            match: false
        };

        if (personData.keyRoleCount == 0) {
            result.riskML = 9;
            result.riskTF = 9;
            result.value = 'No key roles identified';
            return result;
        }

        let highestRisk = 0;

        for (const i in personData.residences) {

            const country = root.getCountry(personData.residences[i].toUpperCase());

            const keyRegion = 'c' + country.region.toString();
            const keySubregion = keyRegion + country.subregion.toString();
            const keyCountry = keySubregion + country.alpha2;

            let riskML = 0;
            if (riskParams['kr']) {
                if (riskParams['kr'][keyCountry]) {
                    riskML = riskParams['kr'][keyCountry];
                }else if (riskParams['kr'][keySubregion]) {
                    riskML = riskParams['kr'][keySubregion];
                }else if (riskParams['kr'][keyRegion]) {
                    riskML = riskParams['kr'][keyRegion];
                }
            }

            if (i > 0) result.value += ', ';
            result.value += country.name.en_EN;

            if (riskML > highestRisk) {
                result.riskML = riskML;
                result.riskTF = riskML;
                result.match = true;
                highestRisk = riskML;
            }

        }

        if (!result.match) {
            result.riskML = 8;
            result.riskTF = 8;
            result.value = 'No countries identified';
        }
        return result;
    },

    getRegCountry(root, param, client, riskParams) {
        const result = {
            riskML: 0,
            riskTF: 0,
            value: '',
            match: true
        };

        const country = root.getCountry(client.country.toUpperCase());
        //console.log('country', country);

        if (!country) {
            result.riskML = 9;
            result.riskTF = 9;
            result.value = 'Unknown';
            return result;
        }

        result.value = country.name.en_EN;

        const keyRegion = 'c' + country.region.toString();
        const keySubregion = keyRegion + country.subregion.toString();
        const keyCountry = keySubregion + country.alpha2;

        let riskML = 5;

        if (riskParams['pc']) {
            if (riskParams['pc'][keyCountry]) {
                riskML = riskParams['pc'][keyCountry];
            }else if (riskParams['pc'][keySubregion]) {
                riskML = riskParams['pc'][keySubregion];
            }else if (riskParams['pc'][keyRegion]) {
                riskML = riskParams['pc'][keyRegion];
            }
        }

        result.riskML = riskML;
        result.riskTF = riskML;
        
        return result;
    },

    getEntityType(root, param, client, riskParams) {
        const result = {
            riskML: 0,
            riskTF: 0,
            match: false
        };
        const types = this.getEntityTypes();
        for (const i in types.no) {
            if (client.company.info.country == 'NO' && types.no[i].key == client.company.form.formCode) {
                //console.log('match', types.no[i]);

                let riskML = riskParams['et']['e' + types.no[i].cat];
                
                result.riskML = riskML;
                result.riskTF = riskML;
                result.value = client.company.form.formDescription;
                result.match = true;
            }
        }
        if (!result.match) {
            result.riskML = 9;
            result.riskTF = 9;
            result.value = 'No match on entity type';
        }
        return result;
    },

    getNaceCodeX(root, param, client, riskParams) {
        const result = {
            riskML: 0,
            riskTF: 0,
            value: '',
            match: false
        };
        for (const i in client.company.naceCodes) {
            const code = this.getNaceCode(root, client.company.naceCodes[i].code, riskParams);
            //console.log('code', code);
            result.riskML = code.riskML;
            result.riskTF = code.riskTF;
            result.value = code.level5d || code.level4d || code.level3d;
            result.match = true;
        }
        if (!result.match) {
            result.riskML = 9;
            result.riskTF = 9;
            result.value = 'No match on business codes';
        }
        return result;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    getNaceCode(root, code, riskParams) {
        const result = {
            level: 0,
            code: code,
            category: '',
            level2: '',
            level2d: '',
            level3: '',
            level3d: '',
            level4: '',
            level4d: '',
            level5: '',
            level5d: '',
            riskML: 0,
            riskTF: 0,
        };
        if (code.length == 4 && !code.includes('.')) {
            console.log('swedish code detected', code);
            if (code.charAt(0) == '0') {
                code = code.substring(1, 3) + '.' + code.charAt(3) + code.charAt(0);
            }else {
                code = code.substring(0, 2) + '.' + code.substring(2);
            }
            console.log('changed to', code);
        }
        if (code.includes('.')) {
            const locale = 'nb_NO';
            const array = code.split('.');
            result.level2 = array[0];
            if (code == '00.000') {
                result.riskML = 9;
                result.riskTF = 9;
                return result;
            }
            result.level2d = root.naceCodes[result.level2][locale];
            result.category = root.naceCodes[result.level2].letter;
            if (array[1].length == 1) {
                result.level = 3;
                result.level3 = code.substring(0, 4);
                result.level3d = this.getNaceDescription(root, result.level3, locale);
                result.riskML = this.getNaceRisk(root, 'riskML', result.category, result.level2, result.level3, result.level4, result.level5);
                result.riskTF = this.getNaceRisk(root, 'riskTF', result.category, result.level2, result.level3, result.level4, result.level5);
            }else if (array[1].length == 2) {
                result.level = 4;
                result.level3 = code.substring(0, 4);
                result.level4 = code.substring(0, 5);
                result.level3d = this.getNaceDescription(root, result.level3, locale);
                result.level4d = this.getNaceDescription(root, result.level4, locale);
                result.riskML = this.getNaceRisk(root, 'riskML', result.category, result.level2, result.level3, result.level4, result.level5);
                result.riskTF = this.getNaceRisk(root, 'riskTF', result.category, result.level2, result.level3, result.level4, result.level5);
            }else {
                result.level = 5;
                result.level3 = code.substring(0, 4);
                result.level4 = code.substring(0, 5);
                result.level5 = code.substring(0, 6);
                result.level3d = this.getNaceDescription(root, result.level3, locale);
                result.level4d = this.getNaceDescription(root, result.level4, locale);
                result.level5d = this.getNaceDescription(root, result.level5, locale);
                result.riskML = this.getNaceRisk(root, 'riskML', result.category, result.level2, result.level3, result.level4, result.level5);
                result.riskTF = this.getNaceRisk(root, 'riskTF', result.category, result.level2, result.level3, result.level4, result.level5);
                let riskML = 5;
                if (riskParams['nc'] && riskParams['nc']['n' + result.category]) riskML = riskParams['nc']['n' + result.category];
                result.riskML = riskML;
                result.riskTF = riskML;
            }
        }else {
            result.level = 2;
        }
        return result;
    },

    getNaceDescription(root, code, locale) {
        return this.getNace(root, code)[locale];
    },

    getNaceRisk(root, risk, letter, level2, level3, level4, level5) {
        if (level5 && this.getNace(root, level5)[risk] != -1) {
            return this.getNace(root, level5)[risk];
        }else if (level4 && this.getNace(root, level4)[risk] != -1) {
            return this.getNace(root, level4)[risk];
        }else if (level3 && this.getNace(root, level3)[risk] != -1) {
            return this.getNace(root, level3)[risk];
        }else {
            return this.getNace(root, level2)[risk];
        }
    },

    getNace(root, code) {
        if (code.length == 2) {
            return root.naceCodes[code];
        }else {
            const parts = code.split('.');
            if (parts[1].length == 3) {
                return root.naceCodes[parts[0]][parts[1].charAt(0)][parts[1].charAt(1)][parts[1].charAt(2)];
            }else if (parts[1].length == 2) {
                return root.naceCodes[parts[0]][parts[1].charAt(0)][parts[1].charAt(1)];
            }else if (parts[1].length == 1) {
                return root.naceCodes[parts[0]][parts[1].charAt(0)];
            }
        }
    },

    getEntityCategories() {
        return {
            xas: {
                nb_NO: 'Aksjeselskap',
                en_EN: 'Limited company',
            },
            xasa: {
                nb_NO: 'Allmennaksjeselskap',
                en_EN: 'Public limited company',
            },
            xans: {
                nb_NO: 'Ansvarlig selskap',
                en_EN: 'Companies',
            },
            xoff: {
                nb_NO: 'Offentlig',
                en_EN: 'Government',
            },
            xfor: {
                nb_NO: 'Utenlandske foretak',
                en_EN: 'Forreign companies',
            },
            xper: {
                nb_NO: 'Enkeltperson',
                en_EN: 'Sole proprietor',
            },
            xsti: {
                nb_NO: 'Stiftelser / foreninger',
                en_EN: 'Foundations / associations',
            },
            xsam: {
                nb_NO: 'Borettslag / sameier',
                en_EN: 'Housing cooperatives',
            },
            xoth: {
                nb_NO: 'Annet',
                en_EN: 'Andre',
            }
        };
    },

    getEntityTypes() {
        return {
            no: [
                {
                    key: 'AAFY',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Ikke næringsdrivende virksomhet',
                    en_EN: 'No business activity',
                },
                {
                    key: 'ADOS',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoff',
                    nb_NO: 'Administrativ enhet -offentlig sektor',
                    en_EN: 'Administrative entity - Public sector',
                },
                {
                    key: 'ANNA',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Annen juridisk person',
                    en_EN: 'Other body corporate',
                },
                {
                    key: 'ANS',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xans',
                    nb_NO: 'Ansvarlig selskap med solidarisk ansvar',
                    en_EN: 'General Partnership',
                },
                {
                    key: 'AS',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xas',
                    nb_NO: 'Aksjeselskap',
                    en_EN: 'Limited company',
                },
                {
                    key: 'ASA',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xasa',
                    nb_NO: 'Allmennaksjeselskap',
                    en_EN: 'Public limited company',
                },
                {
                    key: 'BA',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xas',
                    nb_NO: 'Selskap med begrenset ansvar',
                    en_EN: 'Company with limited liability',
                },
                {
                    key: 'BBL',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xsam',
                    nb_NO: 'Boligbyggelag',
                    en_EN: 'House building cooperative',
                },
                {
                    key: 'BEDR',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Bedrift',
                    en_EN: 'Enterprise',
                },
                {
                    key: 'BO',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Andre bo',
                    en_EN: 'Other estates',
                },
                {
                    key: 'BRL',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xsam',
                    nb_NO: 'Borettslag',
                    en_EN: 'Housing cooperative',
                },
                {
                    key: 'DA',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xans',
                    nb_NO: 'Ansvarlig selskap med delt ansvar',
                    en_EN: 'General Partnership with shared liability',
                },
                {
                    key: 'ENK',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xper',
                    nb_NO: 'Enkeltpersonforetak',
                    en_EN: 'Sole proprietorship',
                },
                {
                    key: 'EØFG',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xfor',
                    nb_NO: 'Europeisk økonomisk foretaksgruppe',
                    en_EN: 'European Economic Enterprise Group',
                },
                {
                    key: 'ESEK',
                    riskML: 5,
                    riskTF: 5,
                    cat: 'xsam',
                    nb_NO: 'Eierseksjonssameie',
                    en_EN: 'Condominium flat owner',
                },
                {
                    key: 'FKF',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoff',
                    nb_NO: 'Fylkeskommunalt foretak',
                    en_EN: 'County municipal business enterprise',
                },
                {
                    key: 'FLI',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xsti',
                    nb_NO: 'Forening/lag/innretning',
                    en_EN: 'Association/club/organization',
                },
                {
                    key: 'FYLK',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoff',
                    nb_NO: 'Fylkeskommune',
                    en_EN: 'County municipality',
                },
                {
                    key: 'GFS',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Gjensidig forsikringsselskap',
                    en_EN: 'Mutual insurance company',
                },
                {
                    key: 'IKJP',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Andre ikke-juridiske personer',
                    en_EN: 'Other non-body corporate',
                },
                {
                    key: 'IKS',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoff',
                    nb_NO: 'Interkommunalt selskap',
                    en_EN: 'Inter-municipal company',
                },
                {
                    key: 'KBO',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Konkursbo',
                    en_EN: 'Bankrupt estate',
                },
                {
                    key: 'KF',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoff',
                    nb_NO: 'Kommunalt foretak',
                    en_EN: 'Municipal business enterprise',
                },
                {
                    key: 'KIRK',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoff',
                    nb_NO: 'Den norske kirke',
                    en_EN: 'The Church of Norway',
                },
                {
                    key: 'KOMM',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoff',
                    nb_NO: 'Kommune',
                    en_EN: 'Municipality',
                },
                {
                    key: 'KS',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xas',
                    nb_NO: 'Kommandittselskap',
                    en_EN: 'Limited partnership',
                },
                {
                    key: 'KTRF',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Kontorfellesskap',
                    en_EN: 'Joint office',
                },
                {
                    key: 'NUF',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xfor',
                    nb_NO: 'Norskregistrert utenlandsk foretak',
                    en_EN: 'Norwegian registered foreign business',
                },
                {
                    key: 'OPMV',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Særskilt oppdelt enhet, jf. mval. § 2-2',
                    en_EN: 'Specially sectioned unit',
                },
                {
                    key: 'ORGL',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Organisasjonsledd',
                    en_EN: 'Organizational section',
                },
                {
                    key: 'PERS',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xper',
                    nb_NO: 'Andre enkeltpersoner som registreres i tilknyttet register',
                    en_EN: 'Other individuals registered in affiliated register',
                },
                {
                    key: 'PK',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Pensjonskasse',
                    en_EN: 'Pension fund',
                },
                {
                    key: 'PRE',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Partrederi',
                    en_EN: 'Jointly owned shipping company',
                },
                {
                    key: 'SA',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xas',
                    nb_NO: 'Samvirkeforetak',
                    en_EN: 'Co-operative',
                },
                {
                    key: 'SAM',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xsam',
                    nb_NO: 'Tingsrettslig sameie',
                    en_EN: 'Joint ownership according to the law of property',
                },
                {
                    key: 'SE',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xfor',
                    nb_NO: 'Europeisk selskap',
                    en_EN: 'European company',
                },
                {
                    key: 'SF',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoff',
                    nb_NO: 'Statsforetak',
                    en_EN: 'Public corporation',
                },
                {
                    key: 'SPA',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Sparebank',
                    en_EN: 'Savings bank',
                },
                {
                    key: 'STI',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xsti',
                    nb_NO: 'Stiftelse',
                    en_EN: 'Foundation',
                },
                {
                    key: 'STAT',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoff',
                    nb_NO: 'Staten',
                    en_EN: 'State',
                },
                {
                    key: 'SÆR',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Annet foretak ifølge særskilt lov',
                    en_EN: 'Other business enterprise in accordance with special legislation',
                },
                {
                    key: 'TVAM',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Tvangsregistrert for MVA',
                    en_EN: 'Compulsory registration in the Value Added Tax Register',
                },
                {
                    key: 'VPFO',
                    riskML: 5,
                    riskTF: 5,
                    cc: 'NO',
                    cat: 'xoth',
                    nb_NO: 'Verdipapirfond',
                    en_EN: 'Unit trusts',
                }
            ]
        }
    }

};
export default v1risk;