<template>

<div :class="[{'vap-button-row-left' : alignLeft}, {'vap-button-row-center' : alignCenter}, {'vap-button-row-right' : alignRight}, {'vap-button-row-padding' : rowPadding}]">
    <slot></slot>
</div>

</template>
<script>

export default {
    name: 'vap-button-row',
    props: {
        alignLeft: {
            type: Boolean,
            default: false
        },
        alignCenter: {
            type: Boolean,
            default: false
        },
        alignRight: {
            type: Boolean,
            default: false
        },
        rowPadding: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style>

.vap-button-row-left, .vap-button-row-center, .vap-button-row-right {
    float: left;
    width: 100%;
}

.vap-button-row-left .vap-button {
    margin-right: 10px;
}

.vap-button-row-right {
    text-align: right;
}

.vap-button-row-center {
    text-align: center;
}

.vap-button-row-padding {
    padding: 0px 5px;
    box-sizing: border-box;
}

</style>