<template>
<div class="vst-prop">
    <slot></slot>
</div>
</template>

<script>
export default {
    name: 'StyleTransformer',
    props: {
        header: { type: String, default: 'Some properties' },
    },
    data() {
        return {
            expanded: true
        }
    },
}
</script>
<style>

.vst-prop .ac-group {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: -1px;
}

.vst-prop .vap-input input {
    padding: 3px 12px;
    padding-top: 4px;
    font-size: 15px;
    background-color: var(--fb-input-bg) !important;
    height: 34px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: white;
    border-radius: 0px;
    border: solid 1px var(--fb-input-bg);
}

.vst-prop .vap-input input::placeholder {
    color: var(--fb-input-ph);
    font-weight: 500;
    letter-spacing: 0.6px;
}


.vst-prop .vep-label-d {
    float: left;
    width: auto;
    min-height: 20px;
    margin-bottom: -5px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.6px;
    text-transform: capitalize;

    padding-top: 2px;
    padding-left: 6px;
    padding-right: 6px;

    border: solid 1px var(--fb-input-bg);
}

/* Checkbox */

.vst-prop .vap-cb {
    margin-top: 2px !important;
}

.vst-prop .vap-cb-text {
    color: var(--fb-input-txt) !important;
}

.vst-prop .vap-cb-bordered {
    height: 34px !important;
    border-radius: 0px !important;
    border-color: var(--fb-input-bg) !important;
    background-color: var(--fb-input-bg) !important;
}

/* Dropdown */

.vst-prop .vap-dd-value.vip {
    height: 34px;
    border-radius: 0px;
    padding-top: 7px;
    color: var(--fb-input-txt);
    border-color: var(--fb-input-bg);
    background-color: var(--fb-input-bg);
}
.vst-prop .vap-dd-value.vip:hover {
    /* border-color: var(--fb-input-bg); */
    background-color: var(--fb-input-bg);
}


</style>
