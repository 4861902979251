const xxPerson = {

    getFlow() {
        return {
            key: 'xx-person',
            name: 'Person Flow',
            type: 'person',
            countries: 'xx',
            isPerson: true,
            tabs: {
                overview: { key: 'common_overview', icon: 'checklist' },
                persons: { key: 'common_persons', icon: 'people' },
                report: { key: 'company_final_report', icon: 'verified' }
            },
            title: {
                en_EN: 'Person Flow',
                nb_NO: 'Personflyt',
            },
            steps: [
                { key: 'borea-kyc-person' },
                { key: 'no-baml-per' },
                { key: 'borea-suit-person' },
                { key: 'xx-person-rep' }
            ],
            statuses: [
                {
                    key: 'b-id-a',
                    text: {
                        en_EN: 'Awaiting ID control',
                        nb_NO: 'Avventer ID kontroll'
                    },
                    triggers: []
                },
                {
                    key: 'b-rep-a',
                    text: {
                        en_EN: 'Awaiting final report',
                        nb_NO: 'Avventer sluttrapport',
                        sv_SE: 'Väntar på slurapport'
                    },
                    triggers: [
                        { type: 'person', trg: 'scr-all', rev: false },
                        { type: 'person', trg: 'idc-all', rev: false },
                    ]
                },
                {
                    key: 'x-com-a',
                    text: {
                        en_EN: 'Completed',
                        nb_NO: 'Fullført',
                        sv_SE: 'Färdiga'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ]
                }
            ],
            events: {
                items: [
                    {
                        key: 'event01',
                        text: {
                            en_EN: 'Customer created by {{createdUser}}',
                            nb_NO: 'Kunde opprettet av {{createdUser}}'
                        },
                        date: { type: 'org', date: 'searchDate' },
                        triggers: [
                            { type: 'client', trg: 'created', rev: false },
                        ]
                    },
                    {
                        key: 'event02',
                        text: {
                            en_EN: 'Self declaration form sent by {{borea01-senderName}}',
                            nb_NO: 'Egenerklæringsskjema sendt ut av {{borea01-senderName}}'
                        },
                        date: { type: 'form', key: 'borea01', date: 'sentDate' },
                        triggers: [
                            { type: 'form', key: 'borea01', trg: 'sent', rev: false },
                        ]
                    },
                    {
                        key: 'event03',
                        text: {
                            en_EN: 'Self declaration filled by {{borea01-editorName}}',
                            nb_NO: 'Egenerklæringsskjema fylt ut av {{borea01-editorName}}'
                        },
                        date: { type: 'form', key: 'borea01', date: 'filledDate' },
                        triggers: [
                            { type: 'form', key: 'borea01', trg: 'filled', rev: false },
                        ]
                    },
                    {
                        key: 'event04',
                        text: {
                            en_EN: 'Self declaration form signed by all signers',
                            nb_NO: 'Egenerklæringsskjema signert av alle signatarer'
                        },
                        date: { type: 'form', key: 'borea01', date: 'signedDate' },
                        triggers: [
                            { type: 'form', key: 'borea01', trg: 'signed', rev: false },
                        ]
                    },
                    {
                        key: 'event05',
                        text: {
                            en_EN: 'Customer classification completed by {{suita02-senderName}}',
                            nb_NO: 'Kundeklassifisering fullført av {{suita02-senderName}}'
                        },
                        date: { type: 'form', key: 'suita02', date: 'signedDate' },
                        triggers: [
                            { type: 'form', key: 'suita02', trg: 'signed', rev: false },
                        ]
                    },
                    {
                        key: 'event06',
                        text: {
                            en_EN: 'Sluttrapport opprettet',
                            nb_NO: 'Sluttrapport opprettet'
                        },
                        date: { type: 'report', date: 'createdDate' },
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ]
                    }
                ]
            },
            report: [
                {
                    key: 'roles',
                    icon: 'person',
                    comment: true,
                    separation: false,
                    title: {
                        en_EN: 'Individual',
                        nb_NO: 'Privatperson'
                    },
                    requirements: [
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Individual not assessed',
                                nb_NO: 'Privatperson ikke vurdert',
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                },
                {
                    key: 'kyc',
                    icon: 'form',
                    comment: true,
                    separation: true,
                    includeFile: {
                        enabled: true,
                        formKey: 'borea01',
                        name: 'Egenerklæring'
                    },
                    title: {
                        en_EN: 'Self-declaration',
                        nb_NO: 'Egenerklæring'
                    },
                    requirements: [
                        {
                            key: 'org-search',
                            message: {
                                en_EN: 'Self-declaration not completed and signed',
                                nb_NO: 'Egenerklæringsskjema ikke fullført'
                            },
                            conditions: [
                                { type: 'form', key: 'borea01', trg: 'analog', rev: true },
                            ],
                            triggers: [
                                { type: 'form', key: 'borea01', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Self-declaration not assessed',
                                nb_NO: 'Egenerklæring ikke vurdert'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ],
                    actions: [
                        {
                            icon: 'file-sent',
                            main: false,
                            action: 'preview',
                            key: 'borea01',
                            triggers: [
                                { type: 'form', key: 'borea01', trg: 'filled', rev: false },
                            ],
                            text: {
                                en_EN: 'Show receipt'
                            },
                            hint: {
                                en_EN: 'Open the PDF version of the form'
                            }
                        },
                    ],
                    items: [
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Not ready',
                                nb_NO: 'Ikke klar'
                            },
                            value: {
                                en_EN: 'Self-declaration not filled in and signed',
                                nb_NO: 'Egenerklæring ikke fylt ut og signert'
                            },
                            triggers: [
                                { type: 'form', key: 'borea01', trg: 'signed', rev: true },
                                { type: 'form', key: 'borea01', trg: 'analog', rev: true },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Signed by',
                                nb_NO: 'Signert av'
                            },
                            value: {
                                en_EN: '{{borea01-signerNames}}',
                                nb_NO: '{{borea01-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'borea01', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: '',
                                nb_NO: 'Signert analogt og bekreftet'
                            },
                            value: {
                                en_EN: '',
                                nb_NO: 'Signert analogt og bekreftet'
                            },
                            triggers: [
                                { type: 'form', key: 'borea01', trg: 'analog-signed', rev: false },
                            ],
                        },
                    ]
                },
                {
                    key: 'suita02',
                    icon: 'form',
                    comment: true,
                    separation: true,
                    includeFile: {
                        enabled: true,
                        formKey: 'suita02',
                        name: 'Kundeklassifisering'
                    },
                    title: {
                        en_EN: 'Customer classification',
                        nb_NO: 'Kundeklassifisering'
                    },
                    requirements: [
                        {
                            key: 'org-search',
                            message: {
                                en_EN: 'Customer classification not completed',
                                nb_NO: 'Kundeklassifisering ikke fullført'
                            },
                            triggers: [
                                { type: 'form', key: 'suita02', trg: 'filled', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Customer classification not assessed',
                                nb_NO: 'Kundeklassifisering ikke vurdert'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ],
                    actions: [
                        {
                            icon: 'file-sent',
                            main: false,
                            action: 'preview',
                            key: 'suita02',
                            triggers: [
                                { type: 'form', key: 'suita02', trg: 'filled', rev: false },
                            ],
                            text: {
                                en_EN: 'Show receipt'
                            },
                            hint: {
                                en_EN: 'Open the PDF version of the form'
                            }
                        },
                    ],
                    items: [
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Not ready',
                                nb_NO: 'Ikke klar'
                            },
                            value: {
                                en_EN: 'Customer classification not completed',
                                nb_NO: 'Kundeklassifisering ikke fullført'
                            },
                            triggers: [
                                { type: 'form', key: 'suita02', trg: 'filled', rev: true },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Completed by',
                                nb_NO: 'Fullført av'
                            },
                            value: {
                                en_EN: '{{suita02-editorName}}',
                                nb_NO: '{{suita02-editorName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'suita02', trg: 'filled', rev: false },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '6',
                            text: {
                                en_EN: 'Customer classification',
                                nb_NO: 'Kundeklassifisering'
                            },
                            value: {
                                en_EN: '{{suita02-cuClass}}',
                                nb_NO: '{{suita02-cuClass}}'
                            },
                            triggers: [
                                { type: 'form', key: 'suita02', trg: 'filled', rev: false },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '6',
                            text: {
                                en_EN: 'Tax country',
                                nb_NO: 'Skatteland'
                            },
                            value: {
                                en_EN: '{{suita02-cuTaxCountry}}',
                                nb_NO: '{{suita02-cuTaxCountry}}'
                            },
                            triggers: [
                                { type: 'form', key: 'suita02', trg: 'filled', rev: false },
                            ],
                        }
                    ]
                },
                {
                    key: 'assessment',
                    icon: 'check',
                    comment: true,
                    separation: true,
                    title: {
                        en_EN: 'Final assessment / risk assessment',
                        nb_NO: 'Totalvurdering'
                    },
                    requirements: [
                        {
                            key: 'report-risk',
                            message: {
                                en_EN: 'Risk classification not set',
                                nb_NO: 'Risikoklassifisering ikke satt'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'risk-set', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Final assessment not completed',
                                nb_NO: 'Totalvurdering ikke fullført',
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                }
            ],
            fields: [
                {
                    key: 'cuClass',
                    rawValue: false,
                    values: [
                        { key: 'ama', en_EN: 'Non-professional', nb_NO: 'Ikke-profesjonell' },
                        { key: 'prof', en_EN: 'Professional', nb_NO: 'Profesjonell' },
                    ]
                },
                {
                    key: 'cuTaxCountry',
                    rawValue: true,
                    emptyText: { en_EN: 'Norway', nb_NO: 'Norge' },
                    values: []
                }
            ]
        };
    },

    //#region Steps -------------------------------------------------------------------------------------------------------
    
    getIdcStep() {
        return {
            key: 'xx-person-idc',
            name: 'ID Control',
            actions: [
                {
                    icon: 'card',
                    main: true,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        { type: 'person', trg: 'idc-all', rev: true },
                    ],
                    text: {
                        en_EN: 'Show person',
                        nb_NO: 'Vis person'
                    },
                    hint: {
                        en_EN: 'Show person'
                    }
                }
            ],
            title: {
                en_EN: 'ID Control',
                nb_NO: 'ID Kontroll',
            },
            hint: {
                en_EN: 'Send an email with an invitation to the ID-control. The recipient log in with BankID and is authenticated.',
                nb_NO: 'Send en epost med invitasjon til ID-kontroll. Mottaker følger linken i eposten og autentiserer seg med BankID.',
                sv_SE: 'Skicka ett mejl med en inbjudan till ID-kontrollen. Mottagaren loggar in med BankID och autentiseras.',
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'ID control completed',
                                nb_NO: 'ID kontroll fullført',
                            },
                            triggers: [
                                { type: 'person', trg: 'idc-all', rev: false },
                            ]
                        }
                    ]
                },
                warning: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Some data was not fetched'
                            },
                            triggers: [
                                { type: 'org', trg: 'missing-data', rev: false },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{sanctionMessage}}'
                            },
                            triggers: [
                                { type: 'org', trg: 'has-sanction', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getScreeningStep() {
        return {
            key: 'xx-person-scr',
            name: 'Screening',
            actions: [
                {
                    icon: 'search',
                    main: true,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        { type: 'person', trg: 'scr-all', rev: true },
                    ],
                    text: {
                        en_EN: 'Show person',
                        nb_NO: 'Vis person'
                    },
                    hint: {
                        en_EN: 'Show person'
                    }
                }
            ],
            title: {
                en_EN: 'Screening',
                nb_NO: 'PEP/Sanksjonssøk',
            },
            hint: {
                en_EN: 'Search with BankID AML against international sanction-lists and nordic PEP-lists.',
                nb_NO: 'Søker på personen med BankID AML mot internasjonale sankjsonslister og nordiske PEP-lister.',
                sv_SE: 'Sök med BankID AML mot internationella sanktionslistor och nordiska PEP-listor.',
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Søk utført',
                                nb_NO: 'Søk utført',
                            },
                            triggers: [
                                { type: 'person', trg: 'scr-all', rev: false },
                            ]
                        }
                    ]
                },
                warning: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Some data was not fetched'
                            },
                            triggers: [
                                { type: 'org', trg: 'missing-data', rev: false },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{sanctionMessage}}'
                            },
                            triggers: [
                                { type: 'org', trg: 'has-sanction', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getKycStep() {
        return {
            key: 'borea-kyc-person',
            name: 'Default BankID AML Self-Declaration Form',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'borea01',
                    triggers: [
                        { type: 'form', key: 'borea01', trg: 'sent', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form',
                        nb_NO: 'Åpne skjema',
                    },
                    hint: {
                        en_EN: 'Open the form'
                    }
                },
                {
                    icon: 'file-sent',
                    main: false,
                    action: 'preview',
                    key: 'borea01',
                    triggers: [
                        { type: 'form', key: 'borea01', trg: 'filled', rev: false },
                        { type: 'form', key: 'borea01', trg: 'analog-signed', rev: true },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'form-pdf-analog',
                    key: 'borea01',
                    triggers: [
                        { type: 'form', key: 'borea01', trg: 'analog-signed', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt',
                    },
                    hint: {
                        en_EN: 'Show the PDF receipt',
                        nb_NO: 'Vis PDF-kvittering',
                        sv_SE: 'Visa PDF-kvittot',
                    }
                },
                {
                    icon: 'download',
                    main: false,
                    action: 'form-download',
                    key: 'borea01',
                    configCondition: 'analogKyc',
                    triggers: [
                        { type: 'form', key: 'borea01', trg: 'signed', rev: true },
                        { type: 'form', key: 'borea01', trg: 'manual', rev: true },
                        { type: 'form', key: 'borea01', trg: 'analog', rev: true },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: '',
                        nb_NO: 'Last ned en utfylt egenerklæring for utskrift',
                        sv_SE: '',
                    }
                },
                {
                    icon: 'sign',
                    main: false,
                    action: 'form-analog-confirm',
                    key: 'borea01',
                    configCondition: 'analogKyc',
                    triggers: [
                        { type: 'form', key: 'borea01', trg: 'analog', rev: false },
                        { type: 'form', key: 'borea01', trg: 'analog-signed', rev: true },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: '',
                        nb_NO: 'Last opp/bekreft analog signatur',
                        sv_SE: '',
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'borea01',
                    triggers: [
                        { type: 'form', key: 'borea01', trg: 'sent', rev: false },
                        { type: 'form', key: 'borea01', trg: 'signed', rev: true },
                        { type: 'form', key: 'borea01', trg: 'analog', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'This action will send a reminder to the recipient'
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'form-reset',
                    key: 'borea01',
                    triggers: [
                        { type: 'form', key: 'borea01', trg: 'sent', rev: false },
                    ],
                    text: {
                        en_EN: 'Reset form'
                    },
                    hint: {
                        en_EN: 'This action will reset the form so that a new one can be sent'
                    }
                }
            ],
            title: {
                en_EN: 'Self-Declaration form',
                nb_NO: 'Egenerklæringsskjema',
            },
            hint: {
                en_EN: 'This step does not have a hint yet',
                nb_NO: 'Fylles ut av kundens kontaktperson og sørger for å hente inn oppdatert data om foretaket og nøkkelroller.'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Awaiting filling by {{borea01-nextName}}',
                                nb_NO: 'Avventer utfylling fra {{borea01-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'borea01', trg: 'sent', rev: false },
                                { type: 'form', key: 'borea01', trg: 'filled', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting confirmation of analog signature',
                                nb_NO: 'Avventer bekreftelse på analog signatur',
                                sv_SE: 'Väntar på bekräftelse av analog signatur'
                            },
                            triggers: [
                                { type: 'form', key: 'borea01', trg: 'analog', rev: false },
                                { type: 'form', key: 'borea01', trg: 'analog-signed', rev: true },
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Completed by {{borea01-editorName}}',
                                nb_NO: 'Fullført av {{borea01-editorName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'borea01', trg: 'filled', rev: false },
                            ]
                        },
                        {
                            text: {
                                en_EN: '',
                                nb_NO: 'Signert analogt og bekreftet',
                                sv_SE: ''
                            },
                            triggers: [
                                { type: 'form', key: 'borea01', trg: 'analog-signed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getSuitStep() {
        return {
            key: 'borea-suit-person',
            name: 'Default BankID AML Self-Declaration Form',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'suita02',
                    triggers: [
                        { type: 'form', key: 'suita02', trg: 'sent', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form',
                        nb_NO: 'Åpne skjema'
                    },
                    hint: {
                        en_EN: 'Open the form'
                    }
                },
                {
                    icon: 'file-sent',
                    main: false,
                    action: 'preview',
                    key: 'suita02',
                    triggers: [
                        { type: 'form', key: 'suita02', trg: 'filled', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'suita02',
                    triggers: [
                        { type: 'form', key: 'suita02', trg: 'sent', rev: false },
                        { type: 'form', key: 'suita02', trg: 'signed', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'This action will send a reminder to the recipient'
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'form-reset',
                    key: 'suita02',
                    triggers: [
                        { type: 'form', key: 'suita02', trg: 'sent', rev: false },
                        /* { type: 'form', key: 'suita02', trg: 'signed', rev: true }, */
                    ],
                    text: {
                        en_EN: 'Reset form'
                    },
                    hint: {
                        en_EN: 'This action will reset the form so that a new one can be sent'
                    }
                }
            ],
            title: {
                en_EN: 'Kundeklassifisering/investeringsanbefaling/egnethetserklæring',
                nb_NO: 'Kundeklassifisering/investeringsanbefaling/egnethetserklæring',
            },
            hint: {
                en_EN: 'Lag en kundeklassifisering/investeringsanbefaling basert på det som er hentet inn i egnethetserklæringen.',
                nb_NO: 'Lag en kundeklassifisering/investeringsanbefaling basert på det som er hentet inn i egnethetserklæringen.',
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Completed by {{suita02-senderName}}',
                                nb_NO: 'Fullført av {{suita02-senderName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'suita02', trg: 'filled', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getReportStep() {
        return {
            key: 'xx-person-rep',
            name: 'Default BankID AML Report',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'tab',
                    key: 'report',
                    triggers: [
                        { type: 'report', trg: 'created', rev: true },
                    ],
                    text: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport'
                    },
                    hint: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport'
                    }
                },
                {
                    icon: 'file-sent',
                    main: false,
                    action: 'preview',
                    key: 'report',
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Show the final report'
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'report-reset',
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ],
                    text: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport'
                    },
                    hint: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport'
                    }
                }
            ],
            title: {
                en_EN: 'Final report / Risk assessment',
                nb_NO: 'Sluttrapport',
            },
            hint: {
                en_EN: 'Look through the collected information and set a risk assessment, then generate a final report for this customer.',
                nb_NO: 'Gå gjennom innsamlet informasjon og sett en risikovurdering, så genereres det en sluttrapport for kunden.',
                sv_SE: 'Gå igenom den insamlade informationen och sätt en riskbedömning och skapa sedan en slutrapport för denna kund.',
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Final report created',
                                nb_NO: 'Sluttrapport opprettet'
                            },
                            triggers: [
                                { type: 'report', trg: 'created', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default xxPerson;