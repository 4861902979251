<template>
    <vap-card style="margin-top: 20px">
        <vap-row>
            <vap-group c12>
                <span class="addon-label">Test Addon</span>
            </vap-group>
        </vap-row>
        <vap-row>
            <span>Just a test addon to showcase required fields etc..</span>
        </vap-row>
    </vap-card>
</template>

<script>

const label = "testaddon"

const requiredFields = (customerType) => {
    return [
        "ssn",
    ]
}

const process = (customerId, customerType, data) => {

    if(customerType != "org-no") {
        return {}
    }

    // do work

    return {

    }
}

const TestAddon = {
    name: "TestAddon",
    data() {
        return {
            screeningRoles: {
                pep: false,
                boardMember: false,
                ceo: false,
            }
        }
    }
}

export { process, requiredFields, label }
export default TestAddon
</script>

<style scoped>
.addon-label {
    color: #238CCC;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 2px solid #238CCC;
    padding-bottom: 4px;
    display: block;
}
</style>