<template>
<div>
    <PopupV1 :header="'Last ned for utskrift'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title); font-style: italic;">
                {{ 'Velg et eksisterende skjema:' }}
            </div>
            <div>
                <div class="amlv1-dfd-item" v-for="(form, i) in forms" :key="'f' + i" :class="[
                    {'amlv1-dfd-item-first': i == 0 },
                    {'amlv1-dfd-item-last': i == forms.length - 1 },
                ]" @click="onStartExistingForm(form)">
                    {{ form.display }}
                </div>
            </div>
            <div style="margin-top: 10px; margin-bottom: 10px; color: var(--text-title); font-style: italic;">
                {{ 'eller' }}
            </div>
            <div class="amlv1-dfd-item amlv1-dfd-item-first amlv1-dfd-item-last" @click="onStartNewForm">
                {{ 'Opprett et nytt skjema og fyll det ut selv' }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
        </template>
    </PopupV1>
</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v2lib from '../v2lib';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFFileuplaod from '../components/form/inputs/VFFileupload.vue';

export default {
    name: 'DownloadFormDialog',
    components: {
        PopupV1,
        VFButton,
        VFFileuplaod,
    },
    props: {
        params: { type: Object, default: null },
        client: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            forms: [],
        }
    },
    methods: {

        onStartExistingForm(form) {
            const params = {
                formKey: this.params.formKey,
                useExisting: true,
                envelopeId: form.envelopeId,
                abortSource: true,
            };
            this.$emit('analog', params);
        },

        onStartNewForm() {
            const params = {
                formKey: this.params.formKey,
                useExisting: false,
                envelopeId: '',
                abortSource: true,
            };
            this.$emit('analog', params);
        },

        onClose() {
            this.$emit('close');
        },

    },
    async created() {
        const formKey = this.params.formKey;
        if (this.client[formKey] && this.client[formKey].archive) {
            for (const i in this.client[formKey].archive) {

                const form = {
                    envelopeId: this.client[formKey].archive[i].envelopeId,
                    display: this.formatDate('f', this.client[formKey].archive[i].dateCreated),
                };
                this.forms.push(form);

            }
        }
        this.loading = false;
    }
}
</script>
<style scoped>

.amlv1-dfd-item {
    padding: 10px 15px;
    border: solid 1px var(--input-border);
    cursor: pointer;
}
.amlv1-dfd-item:hover {
    background-color: var(--item-hover);
}

.amlv1-dfd-item-first {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.amlv1-dfd-item-last {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

</style>