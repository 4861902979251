<template>
    <div class="pep-switch">
        <div class="pep-switch-lbl">
            <label>{{ text }}</label>
        </div>
        <div class="pep-switch-sw">
            <VeSwitch v-model="checked" :text="''" :disabled="disabled" />
        </div>
    </div>
</template>

<script>
import VeSwitch from '../basic/VeSwitch'

export default {
    name: 'CardSwitch',
    components: {
        VeSwitch
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            checked: false,
            svalue: ''
        }
    },
    computed: {
        
    },
    watch: {
        checked: {
            deep: false,
            handler(val) {
                this.$emit('input', val);
                this.$emit('changed', val);
            }
        },
        value: {
            deep: false,
            handler(val) {
                this.checked = val;
            }
        },
    },
    methods: {
        
    },
    async mounted() {
        this.checked = this.value;
    }
}
</script>
<style scoped>

.pep-switch {
    position: relative;
    margin-top: 3px;
    margin-right: 0px;
    display: flex;
}

.pep-switch-lbl {
    font-size: 17px;
    font-weight: 500;
    margin-top: 4px;
    margin-right: 10px;
    color: var(--text-title);
}

.pep-switch-sw {
    
}

</style>