<template>
<div>
    <PopupV1 :header="__t('files-external-delete')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title); font-style: italic;">
                {{ __t('files-external-delete-hint') }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton
                :text="__t('files-external-delete')"
                :icon="'delete'" warning
                @clicked="onDelete"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>
</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v2lib from '../v2lib';
import cache from '../cache';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFFileuplaod from '../components/form/inputs/VFFileupload.vue';

export default {
    name: 'DeleteFileDialog',
    components: {
        PopupV1,
        VFButton,
        VFFileuplaod,
    },
    props: {
        params: { type: Object, default: null },
        client: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
        }
    },
    methods: {

        async onDelete() {
            this.dialog.working = true;
            this.dialog.status = 'Deleting file';

            if (this.client.envelope.bearerToken) {
                cache.invalidateCache(this.params.envelopeId);
                const envelope = await this.getEnvelope(this.params.envelopeId);
                await v2lib.removeAttachmentFromEnvelope(envelope, this.params.name, this.client.envelope.bearerToken);
            }

            await v1api.deleteFile(this.client._id, this.params.formKey);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        },

    },
    async created() {
        console.log('this', this.params);
        this.loading = false;
    }
}
</script>