<template>
<div>

    <ve-popup v-if="params.type == 'connect'" :header="'Connect to KAR module'" :width="500" @close="$emit('close')" >
        <div style="padding: 12px 10px;">
            
            <vap-item fancy padding20 v-for="(option, i) in options" :key="'mcc' + i"
                :fancyIcon="option.icon"
                :fancyText="option.text"
                :fancyHint="option.hint"
                @clicked="onConnect(option)"
            />

        </div>
    </ve-popup>

    <KeySelector v-if="selectKey" :params="keySelectParams" @connect="onConnectWithKey" @close="selectKey = false" />

</div>
</template>
<script>
import karModuleHelper from './karModuleHelper';

import KeySelector from '../../designer/KeySelector.vue';

export default {
    name: "frmod-kar",
    components: {
        KeySelector
    },
    props: {
        params: { type: Object, default: () => { return { type: '' } }}
    },
    data() {
        return {
            selectKey: false,
            keySelectParams: { type: '' },
            options: [
                { icon: 'person', text: 'Physical person', hint: 'Indicates that the account belongs to a person', type: 'person' },
                { icon: 'person', text: 'SSN', hint: 'The SSN of the person', type: 'ssn' },
                { icon: 'company', text: 'Company', hint: 'Indicates that the account belongs to a company', type: 'company' },
                { icon: 'company', text: 'Orgnr', hint: 'The orgnr of the company', type: 'orgnr' },
                { icon: 'card', text: 'Account number', hint: 'The bank account number to be checked', type: 'account' },
            ]
        }
    },
    methods: {
        
        onConnect(option) {
            console.log('params', this.params);

            if (!this.params.fromItem.logic) this.params.fromItem.logic = {};

            if (option.type == 'person' || option.type == 'company') {

                this.keySelectParams.type = option.type;
                this.keySelectParams.fromItem = this.params.fromItem;
                this.selectKey = true;
                return;

            }else if (option.type == 'ssn' || option.type == 'orgnr' || option.type == 'account') {

                if (!this.params.fromItem.logic.link) this.params.fromItem.logic.link = [];

                this.params.fromItem.logic.link.push({
                    type: 'kar',
                    link: option.type,
                    key: ''
                });

            }

            console.log('fromItem', this.params.fromItem);
            this.$emit('close');
        },

        onConnectWithKey(key) {
            this.selectKey = false;

            if (this.keySelectParams.type == 'person' || this.keySelectParams.type == 'company') {

                if (!this.params.fromItem.logic.link) this.params.fromItem.logic.link = [];

                this.params.fromItem.logic.link.push({
                    type: 'kar',
                    link: this.keySelectParams.type,
                    key: key
                });

            }

            this.$emit('close');
        },

    },
    mounted() {
        
    }
}

</script>
