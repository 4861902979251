<template>
<div class="srv2-step-list">

    <div v-if="expanded">
        <div v-for="(item, i) in items" :key="'sia' + i" class="flw100">
            <StepListItem
                :item="item"
                :step="step"
                :archiveIndex="i"
                @action="onAction"
            />
        </div>
    </div>

    <div class="srv2-step-list-footer">

        <div class="srv2-step-list-bend" />
        <div class="srv2-step-list-top" />
        <div class="srv2-step-list-top1" />
        <div class="srv2-step-list-top2" />

        <div class="srv2-step-list-count">
            <div class="srv2-step-list-count1" v-if="!expanded && items.length > 0">
                {{ '+ ' + items.length + ' ' + __t('form-count-1') }}
            </div>
            <div class="srv2-step-list-count2" :class="{'srv2-step-list-count2-exp': expanded || items.length == 0}">
                {{ __t('form-new-hint') }}
            </div>
        </div>

        <div class="srv2-step-list-buttons nohl">
            <div class="srv2-step-list-button" @click="expanded = !expanded" v-if="items.length > 0">
                {{ __t(expanded ? 'form-hide-all' : 'form-show-all') }}
            </div>
            <div class="srv2-step-list-button" @click="onNewForm" :class="{'srv2-step-list-disabled': disabled }">
                {{ __t('form-new') }}
            </div>
        </div>

    </div>

</div>
</template>

<script>
import StepListItem from './StepListItem.vue';

export default {
    name: 'StepFormList',
    components: {
        StepListItem
    },
    props: {
        step: { type: Object, default: null },
        form: { type: Object, default: null },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            status: 0,
            items: [],
            expanded: false
        }
    },
    methods: {

        onAction(action, archiveIndex) {
            this.$emit('xaction', action, archiveIndex);
        },

        onNewForm() {
            if (this.disabled) return;
            const action = {
                key: this.step.form,
                action: 'form-new'
            };
            this.$emit('newform', action);
        },

    },
    created() {
        
        this.items = [];

        //console.log('FORM', this.form);

        for (const i in this.form.archive) {

            if (this.form.archive[i].envelopeId == this.form.envelopeId) continue;

            if (i == 4) break;

            this.items.push(this.form.archive[i]);

        }

    }
}
</script>

<style scoped>

.srv2-step-list {
    position: relative;
    float: left;
    width: 100%;

    margin-top: -25px;
    margin-bottom: 20px;
    z-index: 1;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(161, 43, 43, 0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(161, 43, 43, 0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(161, 43, 43, 0.06);
    box-sizing: border-box;

}

.srv2-step-list-footer {
    position: relative;
    float: left;
    width: 100%;
    min-height: 40px;
    /* background-color: red; */
}

.srv2-step-list-bend {
    position: absolute;
    top: 0px; left: 41px;
    width: 40px; height: 16px;
    border-left: solid 7px var(--primary);
    border-bottom: solid 7px var(--primary);
    border-radius: 10px;
}
.srv2-step-list-top {
    position: absolute;
    top: 0px; left: 41px;
    width: 7px; height: 14px;
    background-color: var(--primary);
}
.srv2-step-list-top1 {
    position: absolute;
    top: 0px; left: 48px;
    width: 7px; height: 12px;
    background-color: var(--card-background);
}
.srv2-step-list-top2 {
    position: absolute;
    top: 12px; left: 80px;
    width: 8px; height: 12px;
    background-color: var(--card-background);
}



.srv2-step-list-count {
    position: absolute;
    top: 3px; left: 91px;
}

.srv2-step-list-count1 {
    font-size: 15px;
    font-weight: 500;
    color: var(--text-title);
}
.srv2-step-list-count2 {
    margin-top: -1px;
    font-size: 12px;
    color: var(--text-weak);
}
.srv2-step-list-count2-exp {
    margin-top: 9px;
}



.srv2-step-list-buttons {
    position: absolute;
    top: 6px; right: 6px;
    display: flex;
}

.srv2-step-list-button {
    height: 20px;
    margin-left: 5px;
    padding: 0px 14px;
    padding-top: 4px;
    padding-bottom: 2px;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-title);
    border-radius: 3px;
    border: 1px solid var(--input-border);
    background-color: var(--page-background);
    cursor: pointer;
}
.srv2-step-list-button:hover {
    color: var(--button-text);
    background-color: var(--button);
}


.srv2-step-list-disabled {
    color: var(--text-disabled);
    background-color: var(--page-background);
    cursor: not-allowed;
}
.srv2-step-list-disabled:hover {
    color: var(--text-disabled);
    background-color: var(--page-background);
}

</style>
