<template>
<div class="vap-label" :class="{'lbl-100': (desc || header || alignRight)}">
    
    <label class="vap-label-required" v-if="required">*</label>

    <label @click="handleLinkClick" :class="[
        {'vep-label-h1' : header},
        {'vep-label-h2' : header2},
        {'vep-label-d' : desc},
        {'vep-label-v' : value},
        {'vep-label-q' : q},
        {'vep-label-a' : a},
        {'vep-label-right' : alignRight},
        {'vep-label-error' : error},
        {'vep-label-link' : link},
    ]">{{ text }}</label>

    <div class="vap-label-actions"><slot name="actions"></slot></div>

</div>
</template>

<script>

export default {
    name: 'vap-label',
    components: {
        
    },
    props: {
        text: {
            type: [String, Number],
            default: '- - - - - - - - - - - - -'
        },
        required: {
            type: Boolean,
            default: false
        },
        header: {
            type: Boolean,
            default: false
        },
        header2: {
            type: Boolean,
            default: false
        },
        desc: {
            type: Boolean,
            default: false
        },
        value: {
            type: Boolean,
            default: false
        },
        q: {
            type: Boolean,
            default: false
        },
        a: {
            type: Boolean,
            default: false
        },
        alignRight: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        link: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            
        }
    },
    methods: {

        handleLinkClick() {
            if (!this.link) return;
            this.$emit('clicked', true);
        }

    },
    async created() {
        
    }
}
</script>

<style scoped>

.vap-label {
    float: left;
    display: flex;
}

.lbl-100 {
    width: 100%;
}

.vap-label-required {
    margin-right: 5px;
    font-size: 14px;
    color: #BF3744;
}

.vap-label-actions {
    display: flex;
    margin-top: -2px;
    margin-left: 10px;
}

.vep-label-h1 {
    font-family: "Roboto Slab", serif;
    font-size: 22px;
    color: var(--text-strong);
}
.vep-label-h2 {
    font-size: 22px;
    color: var(--text-title);
}

.vep-label-d {
    float: left;
    width: 100%;
    min-height: 20px;
    margin-bottom: -2px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: var(--input-label);
}
.vep-label-v {
    float: left;
    font-size: 19px;
    color: var(--text-title);
    padding-top: 0px;
    margin-top: -3px;
}

.vep-label-q {
    float: left;
    font-size: 16px;
    color: var(--text-strong);
}
.vep-label-a {
    float: left;
    font-size: 16px;
    font-weight: bold;
    margin-left: 0px;
    color: var(--text-strong);
}

.vep-label-right {
    float: left;
    width: 100%;
    text-align: right;
}

.vep-label-error {
    font-family: "Roboto Slab", serif;
    font-size: 15px;
    color: #B62537;
}

.vep-label-link {
    color: var(--link-text);
    cursor: pointer;
}
.vep-label-link:hover {
    color: var(--link-active);
}

.vap-combine-left .vap-label {
    flex: 0 0 100%;
}

</style>
