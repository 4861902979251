<template>
<div>

    <PopupV1 :header="'Connect to recipient'" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <ConnectionList :items="options" :params="params" @item="onConnectItem" @itemkey="onConnectItemKey" :isRecipient="true" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>
    
</div>
</template>
<script>
import logicHelper from '../logicHelper';

import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFButton from '../items/VFButton.vue';

import ConnectionList from './ConnectionList.vue';

export default {
    name: 'RecipientDialog',
    components: {
        PopupV1,
        VFButton,
        ConnectionList,
    },
    props: {
        params: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: {},
            options: []
        }
    },
    methods: {
        
        onConnectItem(result) {
            console.log('result', result);

            if (result.conn == 'isrole') {

                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'disabled');

                    this.params.fromItem.logic.disabled.keys.push({
                        type: 'role', key: result.role, rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'visible');

                    this.params.fromItem.logic.visible.keys.push({
                        type: 'role', key: result.role, rev: result.reversed
                    });

                }

            }else {

                logicHelper.prepareItem(this.reference, this.params.fromItem, 'flow');

                this.$set(this.params.fromItem, 'flow', {
                    field: { type: result.conn, rkey: result.role }
                });
            }
            this.$emit('reselect');
            this.$emit('close');
        },

        onConnectItemKey(result) {
            console.log('result', result);

            if (result.conn == 'override') {

                logicHelper.prepareItem(this.reference, this.params.fromItem, 'flow');

                const flow = {
                    override: {
                        key: result.key,
                        rkey: result.role
                    }
                };
                this.$set(this.params.fromItem, 'flow', flow);

            }else if (result.conn == 'sms') {

                logicHelper.prepareItem(this.reference, this.params.fromItem, 'flow');

                const flow = {
                    sms: {
                        key: result.key,
                        rkey: result.role
                    }
                };
                this.$set(this.params.fromItem, 'flow', flow);

            }
            this.$emit('reselect');
            this.$emit('close');
        },
        
    },
    created() {
        console.log('params', this.params);
        if (this.params.type == 'connect' || true == true) {
            const type = this.params.fromItem.type;
            
            // Add possible connections
            if (type == 'text') {

                // First name
                this.options.push({ type: 'flow', conn: 'firstName', icon: 'input-text', text: 'First name',
                    hint: 'This will be the first name of the recipient'
                });
                // Last name
                this.options.push({ type: 'flow', conn: 'lastName', icon: 'input-text', text: 'Last name',
                    hint: 'This will be the last name of the recipient'
                });
                // Email address
                this.options.push({ type: 'flow', conn: 'email', icon: 'alpha', text: 'Email address',
                    hint: 'This will be the email address of the recipient'
                });

            }
            if (type == 'text' || type == 'area') {

                // Message/Greeting
                this.options.push({ type: 'flow', conn: 'greeting', icon: 'edit', text: 'Message/Greeting',
                    hint: 'Ovverride the greeting'
                });

            }
            if (type == 'text' || type == 'drop') {
                // Signing method
                this.options.push({ type: 'flow', conn: 'method', icon: 'sign', text: 'Signing method',
                    hint: 'This will be the signing method of the recipient'
                });
            }
            if (type == 'language') {

                // Language
                this.options.push({ type: 'flow', conn: 'lang', icon: 'locale', text: 'Language',
                    hint: 'This will be the default display language of the recipient'
                });

            }
            if (type == 'phone') {

                // Phone
                this.options.push({ type: 'flow', conn: 'phone', icon: 'phone', text: 'Phone number',
                    hint: 'This will be the phone number of the recipient'
                });

            }
            if (type == 'check') {

                // Override flow
                this.options.push({ type: 'flow', conn: 'override', icon: 'person', text: 'Override flow',
                    hint: 'When checked, the recipient flow will be overridden to use the selected recipient'
                });

                // Use SMS
                this.options.push({ type: 'flow', conn: 'sms', icon: 'phone', text: 'Use SMS notification',
                    hint: 'When checked, the recipient will be notified with SMS instead of email'
                });
                
            }

            // Trigger
            this.options.push({ type: 'trigger', conn: 'isrole', icon: 'check', text: 'Is this role',
                hint: 'Trigger input properties if this role'
            });

        }
    }
}
</script>