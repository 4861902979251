<template>
<div class="amlv1-header-lang" @click="onClick" v-on-clickaway="hideDropdown" :class="{'amlv1-header-lang-designer': designer }">

    <FlagIcon circle :locale="$root.locale.key" />

    <div class="amlv1-header-lang-dd" v-if="open && !designer && !editor">

        <div class="amlv1-header-lang-item" v-for="(locale, i) in $root.config.locales" :key="'hli' + i" @click.stop="onSelect(locale)">

            <div class="amlv1-header-lang-name">
                {{ locale.name }}
            </div>
            <div class="amlv1-header-lang-icon">
                <FlagIcon circle :locale="locale.key" />
            </div>

        </div>

    </div>

    <div class="amlv1-header-lang-dd" v-if="open && (designer || editor)">

        <div class="amlv1-header-lang-item" v-for="(locale, i) in locales" :key="'hli' + i" @click.stop="onSelect(locale)" v-show="designer || locale.enabled">

            <div class="amlv1-header-lang-name">
                {{ locale.name }}
            </div>
            <div class="amlv1-header-lang-icon">
                <FlagIcon circle :locale="locale.key" />
            </div>
            
        </div>

    </div>

</div>
</template>
<script>
/*
    The language selector will show different options based on the properties:
    -default = Only enabled languages in the main portal configuration
    -editor = All enabled languages in the form 
    -designer = All languages added in the form, including disabled languages
    For editor and designer, the array of languages needs to be provided
*/
import FlagIcon from '../v1ui/FlagIcon.vue';

import { mixin as clickaway } from "vue-clickaway"

export default {
    mixins: [clickaway],
    name: 'HeaderLanguageSelector',
    components: {
        FlagIcon,
    },
    props: {
        editor: { type: Boolean, default: false },
        designer: { type: Boolean, default: false },
        locales: { type: Array, default: null },
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {

        onClick() {
           
            this.open = !this.open;
             //console.log('sdfsdf', this.open)
        },

        onSelect(locale) {
            this.hideDropdown();
            this.$root.locale = locale;
            this.$emit('select', locale);
        },

        hideDropdown() {
            this.open = false;
        },

    },
    created() {
        //console.log('sdfsfsdf', this.locales);
    }
}
</script>
<style scoped>

.amlv1-header-lang {
    position: relative;
    width: 71px;
    height: 62px;
    padding-top: 20px;
    padding-left: 24px;
    border-left: solid 1px var(--header-sep);
    border-right: solid 1px var(--header-sep);
    box-sizing: border-box;
    cursor: pointer;
}
.amlv1-header-lang:hover {
    background-color: var(--header-hover);
}

.amlv1-header-lang-dd {
    position: absolute;
    top: 60px; right: 0px;
    width: 200px;
    min-height: 20px;
    border-bottom: solid 1px var(--header-sep);
    border-bottom: none;
    background-color: var(--header);
    z-index: 20;
}

.amlv1-header-lang-item {
    width: 100%;
    padding: 4px 10px;
    padding-right: 24px;
    display: flex;
    color: var(--header-text);
    border-bottom: solid 1px var(--header-sep);
    box-sizing: border-box;
    cursor: pointer;
}
.amlv1-header-lang-item:hover {
    background-color: var(--header-hover);
}

.amlv1-header-lang-name {
    flex-grow: 100;
}

.amlv1-header-lang-icon {
    flex-shrink: 0;
}


.amlv1-header-lang-designer {
    color: white;
    border-color: var(--fb-menu-b);
}
.amlv1-header-lang-designer:hover {
    background-color: #238CCC;
}
.amlv1-header-lang-designer .amlv1-header-lang-dd {
    border-color: var(--fb-menu-b);
}
.amlv1-header-lang-designer .amlv1-header-lang-item {
    border-color: var(--fb-menu-b);
    background-color: var(--fb-menu-a);
}
.amlv1-header-lang-designer .amlv1-header-lang-item:hover {
    background-color: #238CCC;
}

</style>