<template>
<div>
    
    <PropertyHeader :header="'Flow designer'">
        <template v-slot:actions>
            <DesignerButton small
                :icon="'add'"
                :tooltip="'Add role'"
                @clicked="showAddDialog"
            />
        </template>
    </PropertyHeader>

<!--     <PropertyItem padded :header="'Flow type'" :icon="'settings'">
        
        <div>
            {{ 'flow type: ' }}
        </div>

    </PropertyItem> -->

    <PropertyItem padded :header="'Main flow'" :icon="'flow'">
        <div class="flw100" style="padding: 10px 0px;">
        
            <div class="frv2-flowd-role" v-for="(role, i) in mainFlow" :key="'fmr' + i">
                <div class="frv2-flowd-role-base" :class="{'frv2-flowd-role-logic': logicActive }" @click="onTopClicked(role)">
                    <div class="frv2-flowd-role-top">
                        {{ role.name }}
                    </div>
                    <div class="frv2-flowd-role-bot">
                        <div class="frv2-flowd-role-bot-con">
                            {{ role.hint }}
                        </div>
                        <div class="frv2-flowd-role-btn">
                            <vap-icon tiny :icon="'alpha'" />
                        </div>
                        <div class="frv2-flowd-role-btn">
                            <vap-icon tiny :icon="'link'" />
                        </div>
                        <div class="frv2-flowd-role-btn" @click="showNextDialog(role)">
                            <vap-icon tiny :icon="'chev-r'" />
                        </div>
                    </div>
                </div>
                <div class="frv2-flowd-role-arrow" v-if="i < mainFlow.length - 1">
                    <vap-icon :icon="'chev-d'" />
                </div>
            </div>

        </div>
    </PropertyItem>

    <PropertyItem padded :header="'Optional roles'" :icon="'flow'">
        <div class="flw100" style="padding: 10px 0px;">

            <div class="frv2-flowd-role" v-for="(role, i) in optionalRoles" :key="'fmr' + i">
                <div class="frv2-flowd-role-base" :class="{'frv2-flowd-role-logic': logicActive }" @click="onTopClicked(role)">
                    <div class="frv2-flowd-role-top">
                        {{ role.name }}
                    </div>
                    <div class="frv2-flowd-role-bot">
                        <div class="frv2-flowd-role-bot-con">
                            {{ role.hint }}
                        </div>
                        <div class="frv2-flowd-role-btn">
                            <vap-icon tiny :icon="'alpha'" />
                        </div>
                        <div class="frv2-flowd-role-btn">
                            <vap-icon tiny :icon="'link'" />
                        </div>
                        <div class="frv2-flowd-role-btn" @click="showNextDialog(role)">
                            <vap-icon tiny :icon="'chev-r'" />
                        </div>
                    </div>
                </div>
                <div style="margin-top: 20px;" v-if="i < optionalRoles.length - 1">
                    
                </div>
            </div>

            <div v-if="optionalRoles.length == 0" class="frv2-flowd-empty">
                {{ 'No optional roles' }}
            </div>
        
        </div>
    </PropertyItem>


    <PopupV1 designer v-if="dialog.show" :header="'Connect to recipient'" @close="dialog.show = false" :dialog="dialog">
        <template v-slot:content>
            <ConnectionList :items="options" :params="logicParams" @item="onConnectItem" @itemkey="onConnectItemKey" :isRecipient="true" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="dialog.show = false" />
        </template>
    </PopupV1>

    <PopupV1 designer noPadding v-if="addDialog.show" :header="'Add role'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <div>
                <PropertyListItem v-for="(role, i) in addRoleOptions" :key="'ar' + i"
                    :icon="role.icon"
                    :text="role.display"
                    :hint="role.hint"
                    @clicked="addRole(role)"
                />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="addDialog.show = false" />
        </template>
    </PopupV1>

    <PopupV1 designer noPadding v-if="nextDialog.show" :header="'Select next role'" @close="nextDialog.show = false" :dialog="nextDialog">
        <template v-slot:content>
            <div>
                <PropertyListItem v-for="(role, i) in nextOptions" :key="'nr' + i"
                    :icon="role.icon"
                    :text="role.display"
                    :hint="role.hint"
                    @clicked="onNextRoleSelected(role)"
                />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="nextDialog.show = false" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import flowHelper from '../../flowHelper';
import logicHelper from '../../logicHelper';

import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import PropertyListItem from '../ui/PropertyListItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';
import ConnectionList from '../ConnectionList.vue';

export default {
    name: 'FlowPropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        PropertyListItem,
        DesignerButton,
        PopupV1,
        VFButton,
        ConnectionList,
    },
    props: {
        reference: { type: Object, default: null },
        logicActive: { type: Boolean, default: false },
    },
    data() { 
        return {
            mainFlow: [],
            optionalRoles: [],
            nextOptions: [],
            nextDialog: { show: false, key: '' },

            dialog: { show: false },
            addDialog: { show: false },
            addRoleOptions: [],
            options: [],
            logicParams: null,
        }
    },
    methods: {

        onTopClicked(role) {
            if (!this.logicActive) return;

            for (const i in this.reference.roleOptions) {
                if (this.reference.roleOptions[i].key == role.key) this.logicParams.preSelectedRole = this.reference.roleOptions[i];
            }

            this.dialog.show = true;
            this.$emit('stoplogic');
        },

        //#region Add Dialog --------------------------------------------------------------------------------------------------

        showAddDialog() {
            this.addRoleOptions = flowHelper.getRoleOptions();
            this.addDialog.show = true;
        },

        addRole(role) {
            this.reference.onAddRole(role);
            this.addDialog.show = false;
            this.loadFlow();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Next Dialog -------------------------------------------------------------------------------------------------

        showNextDialog(role) {
            this.nextDialog.key = role.key;
            this.nextDialog.show = true;
        },

        onNextRoleSelected(role) {
            for (const i in this.reference.roleOptions) {
                if (this.reference.roleOptions[i].key == this.nextDialog.key) {
                    this.reference.roleOptions[i].next = role.key;
                }
            }
            this.nextDialog.show = false;
            this.loadFlow();
            console.log('this.reference.roleOptions[i]', this.reference.roleOptions)
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Logic Connection --------------------------------------------------------------------------------------------

        onConnectItem(result) {
            console.log('result', result);

            if (result.conn == 'isrole') {

                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.logicParams.fromItem, 'disabled');

                    this.logicParams.fromItem.logic.disabled.keys.push({
                        type: 'role', key: result.role, rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.logicParams.fromItem, 'visible');

                    this.logicParams.fromItem.logic.visible.keys.push({
                        type: 'role', key: result.role, rev: result.reversed
                    });

                }

            }else {

                logicHelper.prepareItem(this.reference, this.logicParams.fromItem, 'flow');

                this.$set(this.logicParams.fromItem, 'flow', {
                    field: { type: result.conn, rkey: result.role }
                });
            }
            this.$emit('reselect');
            this.dialog.show = false;
        },

        onConnectItemKey(result) {
            console.log('result', result);

            if (result.conn == 'override') {

                logicHelper.prepareItem(this.reference, this.logicParams.fromItem, 'flow');

                const flow = {
                    override: {
                        key: result.key,
                        rkey: result.role
                    }
                };
                this.$set(this.logicParams.fromItem, 'flow', flow);

            }else if (result.conn == 'sms') {

                logicHelper.prepareItem(this.reference, this.params.fromItem, 'flow');

                const flow = {
                    sms: {
                        key: result.key,
                        rkey: result.role
                    }
                };
                this.$set(this.params.fromItem, 'flow', flow);

            }
            this.$emit('reselect');
            this.dialog.show = false;
        },

        loadPossibleConnections() {
            console.log('PARAMS', this.reference.logicParamsFinalV2);
            this.logicParams = this.reference.logicParamsFinalV2;
            const type = this.logicParams.fromItem.type;
            
            // Add possible connections
            if (type == 'text') {

                // First name
                this.options.push({ type: 'flow', conn: 'firstName', icon: 'input-text', text: 'First name',
                    hint: 'This will be the first name of the recipient'
                });
                // Last name
                this.options.push({ type: 'flow', conn: 'lastName', icon: 'input-text', text: 'Last name',
                    hint: 'This will be the last name of the recipient'
                });
                // Email address
                this.options.push({ type: 'flow', conn: 'email', icon: 'alpha', text: 'Email address',
                    hint: 'This will be the email address of the recipient'
                });

            }
            if (type == 'text' || type == 'area') {

                // Message/Greeting
                this.options.push({ type: 'flow', conn: 'greeting', icon: 'edit', text: 'Message/Greeting',
                    hint: 'Ovverride the greeting'
                });

            }
            if (type == 'text' || type == 'drop') {
                // Signing method
                this.options.push({ type: 'flow', conn: 'method', icon: 'sign', text: 'Signing method',
                    hint: 'This will be the signing method of the recipient'
                });
            }
            if (type == 'language') {

                // Language
                this.options.push({ type: 'flow', conn: 'lang', icon: 'locale', text: 'Language',
                    hint: 'This will be the default display language of the recipient'
                });

            }
            if (type == 'phone') {

                // Phone
                this.options.push({ type: 'flow', conn: 'phone', icon: 'phone', text: 'Phone number',
                    hint: 'This will be the phone number of the recipient'
                });

            }
            if (type == 'check') {

                // Override flow
                this.options.push({ type: 'flow', conn: 'override', icon: 'person', text: 'Override flow',
                    hint: 'When checked, the recipient flow will be overridden to use the selected recipient'
                });

                // Use SMS
                this.options.push({ type: 'flow', conn: 'sms', icon: 'phone', text: 'Use SMS notification',
                    hint: 'When checked, the recipient will be notified with SMS instead of email'
                });
                
            }

            // Trigger
            this.options.push({ type: 'trigger', conn: 'isrole', icon: 'check', text: 'Is this role',
                hint: 'Trigger input properties if this role'
            });
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        loadFlow() {

            this.mainFlow = [];
            this.optionalRoles = [];
            this.nextOptions = [];

            // Load flow
            for (const i in this.reference.roleOptions) {

                const role = this.reference.roleOptions[i];

                console.log('role', role);

                const processedRole = {
                    key: role.key,
                    name: role.display,
                    next: role.next,
                    hint: ''
                };

                const roleOption = flowHelper.getRoleOption(role.key);
                if (roleOption) processedRole.hint = roleOption.hint;
                if (role.key == 'owner') {
                    processedRole.hint = 'The initiator of the form';
                    this.mainFlow.push(processedRole);
                }else {
                    this.optionalRoles.push(processedRole);
                }

                if (role.key != 'owner') {
                    this.nextOptions.push({
                        key: role.key,
                        display: role.display,
                    });
                }

            }

            this.nextOptions.push({
                key: 'done',
                display: 'Done',
            });

            let noMoreRoles = false; let abortCount = 0;
            while (!noMoreRoles && abortCount < 20) {
                abortCount += 1;
                noMoreRoles = this.moveToMainFlow(this.mainFlow[this.mainFlow.length - 1]);
            }

        },

        moveToMainFlow(role) {
            if (role.next && role.next != 'done') {
                for (const i in this.optionalRoles) {
                    if (this.optionalRoles[i].key == role.next) {
                        this.mainFlow.push(this.optionalRoles[i]);
                        this.optionalRoles.splice(i, 1);
                        break;
                    }
                }
                return false;
            }
            return true;
        },

    },
    created() {
        
        this.loadFlow();

        // Load possible logic connections
        if (this.logicActive) this.loadPossibleConnections();

    }
}
</script>
<style scoped>

.frv2-flowd-role {
    position: relative;
    float: left;
    width: 100%;
    /* margin-bottom: 20px; */
    /* padding-right: 24px; */
    box-sizing: border-box;
}

.frv2-flowd-role-base {
    background-color: var(--fb-input-bg);
}

.frv2-flowd-role-top {
    height: 24px;
    padding-top: 2px;
    padding-left: 5px;
    color: white;
    border-bottom: solid 1px var(--fb-menu-b);
    box-sizing: border-box;
}

.frv2-flowd-role-bot {
    height: 24px;
    display: flex;
}

.frv2-flowd-role-bot-con {
    flex-grow: 100;
    padding-top: 4px;
    padding-left: 5px;
    font-size: 14px;
    color: white;
    opacity: 0.6;
}

.frv2-flowd-role-btn {
    flex-shrink: 0;
    width: 28px; height: 24px;
    padding-top: 1px;
    padding-left: 3px;
    border-left: solid 1px var(--fb-menu-b);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-flowd-role-btn:hover {
    background-color: var(--fb-menu-a);
}

.frv2-flowd-role-btn-disabled {
    fill: var(--fb-menu-c);
}



.frv2-flowd-role-arrow {
    width: fit-content;
    margin: 0 auto;
}


.frv2-flowd-empty {
    padding-left: 10px;
    color: white;
    opacity: 0.4;
}



.frv2-flowd-role-logic {
    border: solid 2px #007FD4;
}
.frv2-flowd-role-logic:hover {
    color: #13AA52;
    border: solid 2px #13AA52;
}

</style>