<template>

<div class="vap-card" :id="id" @click.self="handleClick"
    :class="[
        {'vap-card-list' : list},
        {'vap-card-listc' : compactList},
        {'vap-card-selected' : selected},
        {'vap-card-designer': designer },
        {'vap-card-disabled': disabled },
        {'vap-card-dotted': dotted },
        {'vap-card-shadow': innerShadow },
        {'vap-card-shadowhigh': innerShadowHighlight },
        {'vap-card-valid': validated },
        {'card-shadow': !noShadow },
    ]">
    <div v-if="header" class="vap-card-header-c" @click.stop="handleClick">
        <div class="vap-card-icon" v-if="icon">
            <vap-icon :icon="icon" small />
        </div>
        <div class="vap-card-header">
            <label>{{ header }}</label>
        </div>
        <div class="vesc-actions">
            <slot name="actions"></slot>
        </div>
    </div>
    <div class="vap-card-content" :class="{'vap-card-np' : noPadding}" @click.stop="handleClick">
        <slot></slot>
    </div>
    <div class="ac-card-logic" :id="'l' + id" @click.stop="handleLogicClick" @mousedown="handleLogicClick">
        <svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.7);">
            <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 9 C 3 10.093063 3.9069372 11 5 11 L 9 11 C 10.093063 11 11 10.093063 11 9 L 11 5 C 11 3.9069372 10.093063 3 9 3 L 5 3 z M 5 5 L 9 5 L 9 9 L 5 9 L 5 5 z M 5 13 L 5 15 L 7 15 L 7 13 L 5 13 z M 15 13 C 13.906937 13 13 13.906937 13 15 L 13 19 C 13 20.093063 13.906937 21 15 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 15 C 21 13.906937 20.093063 13 19 13 L 15 13 z M 15 15 L 19 15 L 19 19 L 15 19 L 15 15 z M 5 17 L 5 19 L 7 19 L 7 17 L 5 17 z M 9 17 L 9 19 L 11 19 L 11 17 L 9 17 z"/>
        </svg>
    </div>
</div>

</template>
<script>

export default {
    name: 'vap-card',
    props: {
        header: {
            type: String,
            default: ''
        },
        noPadding: {
            type: Boolean,
            default: false
        },
        noShadow: {
            type: Boolean,
            default: false
        },
        selected: {
            type: Boolean,
            default: false
        },
        list: {
            type: Boolean,
            default: false
        },
        compactList: {
            type: Boolean,
            default: false
        },
        designer: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        id: {
            type: String,
            default: 'id'
        },
        innerShadow: {
            type: Boolean,
            default: false
        },
        innerShadowHighlight: {
            type: Boolean,
            default: false
        },
        validated: {
            type: Boolean,
            default: false
        },
        dotted: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        }
    },
    methods: {
        handleClick() {
            if (!this.designer) return;
            this.$emit('selected', true);
        },
        handleLogicClick() {
            this.$emit('logic', this.id);
        }
    }
}
</script>
<style>

.vap-card {
    position: relative;
    float: left;
    width: 100%;
    border-radius: 3px;
    background-color: var(--card-background);
    box-sizing: border-box;
}

.vap-card-list {
    margin-bottom: 20px;
}
.vap-card-listc {
    margin-bottom: 10px;
}

.vap-card-selected {
    outline: solid 2px #238ccc;
    box-sizing: border-box;
}



.vap-card-header-c {
    display: flex;
    width: 100%;
    border-bottom: solid 1px var(--input-border);
    box-sizing: border-box;
}
.vap-card-icon {
    width: 24px;
    padding-top: 15px;
    padding-left: 16px;
}
.vap-card-header {
    flex-grow: 100;
    padding: 0 20px;
    padding-top: 20px;
    padding-bottom: 16px;
}
.vap-card-header label {
    margin: 10px 0;
    font-size: 17px;
    font-weight: 500;
    color: var(--text-title);
}

.vesc-actions {
    display: flex;
    margin-top: 10px;
    margin-right: 14px;
}



.vap-card-content {
    float: left;
    width: 100%;
    padding: 15px 20px;
    padding-top: 25px;
    box-sizing: border-box;
}

.vap-card-np {
    padding: 0px;
}



.vap-card-shadow {
    border: 2px solid var(--val-error);
}
.vap-card-valid {
    border: 2px solid var(--card-background);
}
.vap-card-shadowhigh {
    border: 2px solid var(--accent);
    background-color: var(--item-hover);
}



/* logic icon */

.ac-card-logic {
    visibility: collapse;
    position: absolute;
    top: 10px; right: 20px;
    width: 26px; height: 26px;
    border-radius: 50%;
    background-color: #3190D5;
    cursor: pointer;
    z-index: 2;
    padding-top: 1px;
    padding-left: 1.5px;
    box-sizing: border-box;
}
.ac-card-logic path {
    fill: white;
}

/* design mode */

.vap-card-designer.vap-card:hover {
    background-color: var(--card-hover);
}

.vap-card-selected.vap-card-designer .ac-card-logic {
    visibility: visible;
}

.vap-card-disabled {
    background-color: var(--page-background);
    opacity: 0.8;
}

.vap-card-dotted {
    border: dotted 2px var(--input-border);
    background-color: var(--page-background);
    opacity: 1;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.vap-card-selected.vap-card-dotted {
    border: dotted 2px #3190D5;
}

</style>