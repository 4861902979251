<template>
<div>
    <canvas id="myChart" ></canvas>
</div>
</template>

<script>
import { Chart, ArcElement, PieController, LinearScale } from 'chart.js';
Chart.register(
    ArcElement,
    PieController,
    LinearScale
);

export default {
    name: 'vap-piechart',
    props: {
        size: {
            type: Number,
            default: 200
        },
        data: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            auth: {
                email: '',
                password: ''
            }
        }
    },
    methods: {
        
        render() {

            const labels = [];
            const vdata = [];
            const colors = [];

            for (const i in this.data) {
                labels.push(this.data[i].label);
                vdata.push(this.data[i].value);
                colors.push(this.data[i].color);
            }

            var ctx = document.getElementById('myChart');
            ctx.width = this.size;
            ctx.height = this.size;
            //console.log('ctx', ctx);
            
            var myChart = new Chart(ctx, {
                type: 'pie',
                data: {
                    labels: labels,
                    datasets: [{
                        label: 'My First Dataset',
                        data: vdata,
                        backgroundColor: colors,
                        hoverOffset: 4
                    }]
                    },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                }
            });
        }

    },
    async created() {
        const self = this;
        setTimeout(function() {
            self.render();
        }, 200);
    }
}
</script>

<style scoped>

#myChart {
    
}

</style>
