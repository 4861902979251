<template>
<div>
    <div>

        <DesignerLabel :text="'Tags'" style="margin-bottom: 4px;" />

        <PropertyLine v-for="(tag, i) in tags" :key="'cmt' + i"
            :input="tag"
            :display="tag.tag"
            :enabled="tag.enabled"
            :logicKey="'visible'"
            :reference="reference"
            @delete="onDeleteTag(tag)"
            @logic="$emit('logic', $event)"
        >

        </PropertyLine>

        <div v-if="tags.length == 0" style="padding: 5px 5px; font-size: 14px; color: var(--fb-text-4);">
            {{ 'No tags will be added to the client' }}
        </div>

        <div class="frv2-mod-list-add">
            <div class="frv2-mod-list-add-btn" @click="showAddDialog()">
                <vap-icon tiny :icon="'add'" />
            </div>
        </div>

    </div>


    <!-- Add dialog -->
    <PopupV1 designer noPadding v-if="addDialog.show" :header="'Add existing tag'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <div v-if="addDialog.existing">
                <PropertyListItem v-for="(tag, i) in existingTags" :key="'et' + i"
                    :icon="tag.icon"
                    :text="tag.display"
                    :hint="tag.key"
                    @clicked="onAddTag(tag)"
                />
            </div>
            <div v-if="!addDialog.existing" class="flw100" style="padding: 12px 16px;">
                <DesignerGroup c12>
                    <DesignerLabel :text="'Tag'" />
                    <DesignerInput v-model="addDialog.key" @input="validateAddDialog" />
                </DesignerGroup>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="addDialog.show = false" />
            <VFButton :text="'Add tag'" @clicked="onAddTag()" :disabled="!addDialog.valid" />
        </template>
    </PopupV1>


</div>
</template>
<script>
import editorHelper from '../../editorHelper';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';

import DesignerGroup from '../../designer/ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../../designer/ui/inputs/DesignerLabel.vue';
import DesignerInput from '../../designer/ui/inputs/DesignerInput.vue';

import PropertyListItem from '../../designer/ui/PropertyListItem.vue';
import PropertyLine from '../../designer/ui/PropertyLine.vue';

export default {
    name: "ClientModuleBox",
    components: {
        PopupV1,
        VFButton,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        PropertyListItem,
        PropertyLine,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() {
        return {
            tags: [],
            existingTags: [],
            addDialog: { show: false, valid: false, existing: true, tag: '' },
        }
    },
    methods: {

        //#region Add Dialog --------------------------------------------------------------------------------------------------

        showAddDialog() {
            this.addDialog.resourceType = null;
            this.addDialog.key = '';
            this.addDialog.name = '';
            this.addDialog.show = true;
            this.validateAddDialog();
        },

        validateAddDialog() {
            if (this.addDialog.key && this.addDialog.name) {
                this.addDialog.valid = true;
            }else {
                this.addDialog.valid = false;
            }
        },

        onAddTag(existing) {
            editorHelper.onChange(this.reference, 'client_tag_add', false);

            let tag = this.addDialog.tag || '';
            if (existing) tag = existing.tag;

            for (const i in this.reference.modules) {
                if (this.reference.modules[i].type == 'client') {
                    this.reference.modules[i].tags.push({
                        type: 'tag',
                        key: 't' + (this.reference.counter += 1),
                        tag: tag,
                        enabled: true,
                    });
                }
            }
            this.addDialog.show = false;
            this.loadTags();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        onDeleteTag(tag) {
            editorHelper.onChange(this.reference, 'client_tag_del', false);

            for (const i in this.reference.modules) {
                if (this.reference.modules[i].type == 'client') {
                    for (const j in this.reference.modules[i].tags) {
                        if (this.reference.modules[i].tags[j].tag == tag.tag) {
                            this.reference.modules[i].tags.splice(j, 1);
                            break;
                        }
                    }
                }
            }
            this.loadTags();
        },

        loadTags() {
            this.tags = [];
            this.existingTags = [];
            for (const i in this.reference.modules) {
                if (this.reference.modules[i].type == 'client') {
                    for (const j in this.reference.modules[i].tags) {
                        this.tags.push(this.reference.modules[i].tags[j]);
                    }
                }
            }
            for (const i in this.$root.config.tags.list) {
                this.existingTags.push({
                    tag: this.$root.config.tags.list[i].key,
                    display: this.$root.config.tags.list[i].text[this.$root.locale.key],
                });
            }
        },

    },
    created() {
        this.loadTags();
        console.log('tags', this.tags);
    }
}

</script>
<style scoped>

.frv2-mod-list-add {
    display: flex;
    flex-direction: row-reverse;
}
.frv2-mod-list-add-btn {
    width: 28px; height: 25px;
    padding: 2px 3px;
    background-color: var(--fb-menu-c);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-mod-list-add-btn:hover {
    background-color: var(--fb-menu-a);
}

</style>