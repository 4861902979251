<template>
<div class="frv2-conl-base">

    <!-- Breadcrumbs -->
    <div class="frv2-conl-bc" v-if="selectedRecipient || selectedItem">
        <div class="frv2-conl-bc-itm" v-if="selectedRecipient">
            <div class="frv2-conl-bc-desc">
                {{ 'Role' }}
            </div>
            <div class="frv2-conl-bc-value">
                {{ selectedRecipient.display }}
            </div>
        </div>
        <div class="frv2-conl-bc-itm" v-if="selectedItem">
            <div class="frv2-conl-bc-desc">
                {{ 'Value' }}
            </div>
            <div class="frv2-conl-bc-value">
                {{ selectedItem.text }}
            </div>
        </div>
        <div class="frv2-conl-bc-itm" v-if="selectedTag">
            <div class="frv2-conl-bc-desc">
                {{ 'Tag' }}
            </div>
            <div class="frv2-conl-bc-value">
                {{ selectedTag.display }}
            </div>
        </div>
        <div class="frv2-conl-bc-itm" v-if="selectedCountry">
            <div class="frv2-conl-bc-desc">
                {{ 'Country' }}
            </div>
            <div class="frv2-conl-bc-value">
                {{ getCountryName(selectedCountry) }}
            </div>
        </div>
    </div>

    <!-- List -->
    <div class="frv2-conl nohl" :class="[
        {'frv2-conl-noover': selectCountry }
    ]">

        <div v-if="!selectKey && !selectRecipient && !selectTrigger && !selectTag">

            <!-- Connection List -->
            <div class="frv2-conl-list" v-for="(list, i) in headers" :key="'cl' + i" v-show="lists[list.key].length > 0">
                <div class="frv2-conl-head" :class="{'frv2-conl-head-first': i == 0}">
                    <span>{{ list.name }}</span>
                </div>
                <div class="frv2-conl-item" v-for="(item, j) in lists[list.key]" :key="'cla' + j" @click="onItemClick(item)"
                    :class="{'frv2-conl-item-first': j == 0}"
                >
                    <div class="frv2-conl-icon">
                        <vap-icon :icon="item.icon" />
                    </div>
                    <div class="frv2-conl-cont">
                        <div class="frv2-conl-name">
                            {{ item.text }}
                        </div>
                        <div class="frv2-conl-hint">
                            {{ item.hint }}
                        </div>
                    </div>
                    <div class="frv2-conl-right">
                        {{ list.type }}
                    </div>
                </div>
            </div>

        </div>

        <div v-if="selectKey && !selectRecipient" class="frv2-conl-list">

            <!-- Checkbox/Dropdown Option List -->
            <div class="frv2-conl-head">
                <span>{{ 'Select key' }}</span>
            </div>
            <div class="frv2-conl-item" v-for="(option, j) in itemKeys" :key="'cla' + j" @click="onItemKeyClicked(option)">
                <div class="frv2-conl-icon">
                    <vap-icon :icon="'input-check'" />
                </div>
                <div class="frv2-conl-cont">
                    <div class="frv2-conl-name">
                        {{ option.display }}
                    </div>
                </div>
                <div class="frv2-conl-right">
                    {{ option.key }}
                </div>
            </div>

        </div>

        <div v-if="selectCountry" class="frv2-conl-list">

            <!-- Country list -->
            <div class="frv2-conl-head frv2-conl-head-first">
                <span>{{ 'Select country' }}</span>
            </div>
            <div>
                <VFCountrySelector v-model="selectedCountry" @input="onCountrySelected" />
            </div>

        </div>

        <div v-if="selectRecipient" class="frv2-conl-list">

            <!-- Recipient List -->
            <div class="frv2-conl-head frv2-conl-head-first">
                <span>{{ 'Select role' }}</span>
            </div>
            <div class="frv2-conl-item" v-for="(role, j) in params.roles" :key="'clr' + j" @click="onRoleClicked(role)"
                v-show="role.key != 'owner' && role.key != 'done'"
            >
                <div class="frv2-conl-icon">
                    <vap-icon :icon="role.key == 'signer' ? 'sign' : 'edit'" />
                </div>
                <div class="frv2-conl-cont">
                    <div class="frv2-conl-name">
                        {{ role.display }}
                    </div>
                    <div class="frv2-conl-hint">
                        {{ 'recipient' }}
                    </div>
                </div>
                <div class="frv2-conl-right">
                    {{ 'role' }}
                </div>
            </div>

        </div>

        <div v-if="selectTag && !selectedTag" class="frv2-conl-list">

            <!-- Recipient List -->
            <div class="frv2-conl-head frv2-conl-head-first">
                <span>{{ 'Select tag' }}</span>
            </div>
            <div class="frv2-conl-item" v-for="(tag, j) in tags" :key="'clr' + j" @click="onTagClicked(tag)">
                <div class="frv2-conl-icon">
                    <vap-icon :icon="'bug'" />
                </div>
                <div class="frv2-conl-cont">
                    <div class="frv2-conl-name">
                        {{ tag.display }}
                    </div>
                    <div class="frv2-conl-hint">
                        {{ 'tag' }}
                    </div>
                </div>
                <div class="frv2-conl-right">
                    {{ 'tag' }}
                </div>
            </div>

        </div>

        <div v-if="selectTrigger && !selectKey && !selectCountry" class="frv2-conl-list">

            <!-- Trigger List -->
            <div class="frv2-conl-list" v-for="(trigger, i) in triggers" :key="'xl' + i">
                <div class="frv2-conl-head" :class="{'frv2-conl-head-first': i == 0}">
                    <span>{{ trigger.name }}</span>
                </div>
                <div class="frv2-conl-item" v-for="(item, j) in trigger.subs" :key="'xla' + j" @click="onTriggerClicked(item)"
                    :class="{'frv2-conl-item-first': j == 0}"
                >
                    <div class="frv2-conl-icon">
                        <vap-icon :icon="trigger.icon" />
                    </div>
                    <div class="frv2-conl-cont">
                        <div class="frv2-conl-name">
                            {{ item.text }}
                        </div>
                        <div class="frv2-conl-hint">
                            {{ item.hint }}
                        </div>
                    </div>
                    <div class="frv2-conl-right">
                        {{ 'effect' }}
                    </div>
                </div>
            </div>

        </div>
        
    </div>
</div>
</template>
<script>
import VFCountrySelector from '../inputs/VFCountrySelector.vue';

export default {
    name: 'ConnectionList',
    components: {
        VFCountrySelector,
    },
    props: {
        items: { type: Array, default: () => { return [] }},
        tags: { type: Array, default: () => { return [] }},
        params: { type: Object, default: () => { return {} }},
        isRecipient: { type: Boolean, default: false },
    },
    data() {
        return {
            headers: [
                { key: 'trigger', name: 'Triggers', type: 'trigger' },
                { key: 'field', name: 'Autofill items', type: 'autofill' },
                { key: 'action', name: 'Actions', type: 'action' },
                { key: 'link', name: 'Linking', type: 'link' },
                { key: 'flow', name: 'Recipient', type: 'recipient' },
                { key: 'dictionary', name: 'Dictionary', type: 'dictionary' },
            ],
            lists: {
                action: [],
                field: [],
                link: [],
                trigger: [],
                flow: [],
                dictionary: [],
            },
            selectedItem: null,
            selectKey: false,
            selectCountry: false,
            selectedCountry: '',
            itemKeys: [],
            selectRecipient: false,
            selectedRecipient: null,
            selectTrigger: false,
            selectedTrigger: null,
            selectTag: false,
            selectedTag: '',
            triggers: [
                {
                    name: 'Visibility', icon: 'eye', subs: [
                        { text: 'Visible', key: 'visible', rev: false, hint: 'Visible if triggered' },
                        { text: 'Hidden', key: 'visible', rev: true, hint: 'Hidden if triggered' },
                    ]
                },
                {
                    name: 'Locking', icon: 'locked', subs: [
                        { text: 'Enabled', key: 'enabled', rev: false, hint: 'Enabled if triggered' },
                        { text: 'Disabled', key: 'enabled', rev: true, hint: 'Disabled if triggered' },
                    ]
                },
                {
                    name: 'Required for editor', icon: 'edit', subs: [
                        { text: 'Required', key: 'required', rev: false, hint: 'Required if triggered' },
                        { text: 'Optional', key: 'required', rev: true, hint: 'Optional if triggered' },
                    ]
                },
                {
                    name: 'Required for owner', icon: 'edit', subs: [
                        { text: 'Required', key: 'ownerReq', rev: false, hint: 'Required if triggered' },
                        { text: 'Optional', key: 'ownerReq', rev: true, hint: 'Optional if triggered' },
                    ]
                }
            ]
        }
    },
    methods: {

        onRoleClicked(role) {
            this.selectedRecipient = role;
            this.selectRecipient = false;
        },

        onItemClick(item) {
            console.log('params', this.params);
            console.log('item', item);

            if (item.selectKey) {
                this.loadItemKeys();
                this.selectKey = true;
            }

            if (item.selectCountry) {
                this.loadItemKeys();
                this.selectCountry = true;
            }

            if (item.selectTag && !this.selectedTag) {
                this.selectTag = true;
                this.selectedItem = item;
                return;
            }

            if (item.type == 'trigger') {

                this.selectedItem = item;
                this.selectTrigger = true;

            }else if (this.params.fromItem.type == 'check') {
                this.selectedItem = item;
                this.loadItemKeys();
                this.selectKey = true;
            }else if (this.params.fromItem.type == 'check2') {
                this.selectedItem = item;
                this.loadItemKeys2();
                this.selectKey = true;
            }else {
                this.$emit('item', { conn: item.conn, role: this.selectedRecipient ? this.selectedRecipient.key : '' });
            }
        },

        onCountrySelected() {
            console.log('country', this.selectedCountry);
            this.selectCountry = false;
        },

        onTagClicked(tag) {
            this.selectedTag = tag;
            this.onItemClick(this.selectedItem);
        },

        onTriggerClicked(trigger) {
            this.$emit('item', {
                conn: this.selectedItem.conn,
                trigger: trigger.key,
                reversed: trigger.rev,
                role: this.selectedRecipient ? this.selectedRecipient.key : '',
                country: this.selectedCountry,
                tag: this.selectedTag.tag,
            });
        },

        onItemKeyClicked(option) {
            this.$emit('itemkey', { conn: this.selectedItem.conn, key: option.key, role: this.selectedRecipient ? this.selectedRecipient.key : '' });
        },

        loadItemKeys() {
            for (const i in this.params.fromItem.options) {
                this.itemKeys.push({
                    key: this.params.fromItem.options[i].key,
                    display: this.params.fromItem.options[i].locale[this.$root.locale.key]
                });
            }
        },

        loadItemKeys2() {
            for (const i in this.params.fromItem.items) {
                this.itemKeys.push({
                    key: this.params.fromItem.items[i].key,
                    display: this.params.fromItem.items[i].text[this.$root.locale.key]
                });
            }
        },
        
    },
    created() {
        for (const i in this.items) {
            this.lists[this.items[i].type].push(this.items[i]);
        }

        this.selectRecipient = this.isRecipient;

        if (this.params.preSelectedRole) this.onRoleClicked(this.params.preSelectedRole);

        if (this.params.preSelectedItem) {
            for (const i in this.items) {
                if (this.items[i].conn == this.params.preSelectedItem) {
                    this.onItemClick(this.items[i]);
                    break;
                }
            }
        }

    }
}
</script>
<style scoped>


.frv2-conl-bc {
    display: flex;
    margin-top: 15px;
    margin-bottom: 10px;
    /* border-radius: 5px; */
    border: solid 1px var(--fb-menu-c);
}

.frv2-conl-bc-itm {
    padding: 3px 14px;
    border-right: solid 1px var(--fb-menu-c);
}

.frv2-conl-bc-desc {
    font-size: 14px;
    color: var(--text-weak);
}

.frv2-conl-bc-value {
    margin-top: -3px;
    font-weight: 500;
    color: var(--fb-text-1);
}



.frv2-conl {
    max-height: 400px;
    overflow: auto;
    /* border-radius: 5px; */
    border: solid 1px var(--fb-menu-c);
    scrollbar-color: var(--fb-scroll-f) var(--fb-scroll-b);
}
.frv2-conl-noover {
    overflow: initial;
}
.frv2-conl::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
.frv2-conl::-webkit-scrollbar-thumb { /* Foreground */
  background: var(--fb-scroll-f);
  border-radius: 5px;
}
.frv2-conl::-webkit-scrollbar-track { /* Background */
  background: var(--fb-scroll-b);
}


.frv2-conl-list {
    float: left;
    width: 100%;
}

.frv2-conl-head {
    float: left;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-weak);
    border-top: solid 1px var(--fb-menu-c);
    border-bottom: solid 1px var(--fb-menu-c);
}
.frv2-conl-head-first {
    border-top: none;
}
.frv2-conl-head span {
    float: left;
    padding: 5px 10px;
    border-right: solid 1px var(--fb-menu-c);
}

.frv2-conl-item {
    float: left;
    width: 100%;
    display: flex;
    border-top: solid 1px var(--fb-menu-c);
    cursor: pointer;
}
.frv2-conl-item-first {
    border-top: none;
}
.frv2-conl-item:hover {
    background-color: var(--fb-menu-a);
}

.frv2-conl-icon {
    width: 50px;
    flex-shrink: 0;
    padding-top: 5px;
    padding-left: 6px;
    box-sizing: border-box;
}

.frv2-conl-cont {
    flex-grow: 100;
    padding: 5px 0px;
}

.frv2-conl-name {
    font-weight: 500;
    color: var(--fb-text-1);
}

.frv2-conl-hint {
    font-size: 15px;
    color: var(--text-weak);
}

.frv2-conl-right {
    padding-top: 14px;
    padding-right: 20px;
    padding-left: 10px;
}

</style>