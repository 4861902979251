<template>
<div class="veper">

    <div class="veper-empty" v-if="!person.web.done">
        <div class="veper-empty-top">
            <VeSwitch v-model="checked" :disabled="disabled" :text="__t('ams-name')" />
        </div>
        <div class="veper-empty-icon" v-if="!checked">
            <vap-icon :icon="'search'" massive :color="'var(--text-disabled)'" />
        </div>
        <div class="veper-screen" v-if="checked">
            <div class="veper-screen-button" style="margin-top: 48px;">
                <vap-button :text="__t('ams-open')" @clicked="$emit('screen', person)" />
            </div>
        </div>
    </div>

    <div class="veper-empty" v-if="person.web.done && !person.web.completed">
        <div class="veper-empty-top">
            <VeSwitch :value="true" disabled :text="__t('ams-name')" />
        </div>
        <div class="veper-screen">
            <div style="margin-top: 15px;">
                {{ 'Started' }}
            </div>
            <div class="veper-screen-button" style="margin-top: 14px;">
                <vap-button :text="__t('ams-open')" @clicked="$emit('screen', person)" />
            </div>
        </div>
    </div>

    

    <div v-if="person.web.done && person.web.completed" class="veper-con">

        <PersonInfoContainer first>
            <template v-slot:info>
                <PersonInfoItem
                    :success="hitLevel == 'ok'"
                    :warning="hitLevel == 'some'"
                    :error="hitLevel == 'hits'"
                >
                    <span>{{ statusText }}</span>
                </PersonInfoItem>
            </template>
        </PersonInfoContainer>

        <PersonInfoContainer>
            <template v-slot:info>
                <PersonInfoItem>
                    <span>{{ statusText2 }}</span>
                </PersonInfoItem>
            </template>
        </PersonInfoContainer>

        <PersonInfoContainer>
            <template v-slot:info>
                <PersonInfoItem smallText weakText>
                    <span>{{ statusText3 }}</span>
                </PersonInfoItem>
            </template>
        </PersonInfoContainer>

        <PersonInfoContainer>
            <template v-slot:info>
                
                <PersonInfoItem :width="50" smallText weakText v-if="!$root.config.adverseMedia.validMinutes || ($root.config.adverseMedia.validMinutes && !isOutdated)">
                    {{ getDateString(person.web.date) }}
                </PersonInfoItem>

                <PersonInfoItem :width="50" border smallText weakText v-if="!$root.config.adverseMedia.validMinutes || ($root.config.adverseMedia.validMinutes && !isOutdated)">
                    {{ getTimePassedString(person.web.date) }}
                </PersonInfoItem>

                <PersonInfoItem :width="100" v-if="$root.config.adverseMedia.validMinutes && isOutdated"
                    textError smallText weakText 
                >
                    {{ getTimePassedString(person.web.date) }}
                    <template v-slot:tooltip v-if="isOutdated">
                        <vap-tooltip :text="getTimePassedString(person.web.date) + ' - ' + __t('screening-outdated')" />
                    </template>
                </PersonInfoItem>

            </template>
            <template v-slot:actions>
                <PersonInfoButton :icon="'update'" :disabled="disabled" @clicked="$emit('refresh', person)" v-if="$root.config.adverseMedia.validMinutes && isOutdated" />
                <PersonInfoButton :icon="'delete'" :disabled="disabled" @clicked="$emit('reset')" />
                <PersonInfoButton :icon="'info'" @clicked="$emit('info')" />
            </template>
        </PersonInfoContainer>

    </div>
    
</div>
</template>
<script>
import tools from '../tools';
import v1personHelper from '../v1helpers/v1personHelper';

import PersonInfoContainer from './PersonInfoContainer.vue';
import PersonInfoButton from './PersonInfoButton.vue';
import PersonInfoItem from './PersonInfoItem.vue';
import VeSwitch from '../components/basic/VeSwitch.vue';

export default {
    name: 'SlotWeb',
    components: {
        PersonInfoContainer,
        PersonInfoButton,
        PersonInfoItem,
        VeSwitch
    },
    props: {
        person: { type: Object, default: null },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            checked: false,
            hitLevel: 'ok',
            statusText: '',
            statusText2: '',
            statusText3: '',
            isOutdated: false,
        }
    },
    methods: {

        trimText(text, max) {
            if (!text) return '';
            if (text.length < max) return text;
            return text.substring(0, max) + '...';
        },

        //#region Helpers -----------------------------------------------------------------------------------------------------

        getDateString(date) {
            return tools.dateStringShort(date, this.$root.locale.key);
        },

        getTimePassedString(date) {
            return tools.timePassedString(date, this.$root);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {
        //console.log('PERSON', this.person);

        if (this.person.web.done) {
            this.hitLevel = v1personHelper.getAmsHitLevel(this.person.web);
            if (this.hitLevel == 'some') {
                this.statusText = this.__t('ams-unclear-text');
            }else if (this.hitLevel == 'hits') {
                this.statusText = this.__t('ams-hits-text');
            }else {
                this.statusText = this.__t('ams-ok');
            }
            this.statusText2 = v1personHelper.getAmsResultText(this.$root, this.person.web, this.$root.locale.key);
            this.statusText3 = this.__t('pdf-performed-by') + ' ' + this.getEmployeeName(this.person.web.userId);

            const date = new Date(this.person.web.date);
            const diff = new Date() - date;
            const diffMinutes = diff / 1000 / 60;
            this.isOutdated = (diffMinutes > (this.$root.config.adverseMedia.validMinutes) || 0);
        }
    }
}
</script>
<style scoped>

.veper {
    background-color: var(--page-background);
    border-bottom-left-radius: 6px;
}

.veper-con {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.veper-empty {
    max-height: 137px;
}
.veper-empty-top {
    display: flex;
    padding: 5px 10px;
    border-bottom: solid 1px var(--input-border);
}
.veper-empty-icon {
    margin-top: -5px;
    padding-left: 36%;
    padding-bottom: 20px;
}


.veper-screen {
    min-height: 85px;
    max-height: 105px;
    text-align: center;
    box-sizing: border-box;
}
.veper-screen-image {
    margin-top: 20px;
}
.veper-screen-image img {
    width: 150px;
}

.veper-screen-image-trap {
    margin-top: 10px;
}
.veper-screen-image-trap img {
    width: 94px;
}

.veper-screen-button {
    margin-top: 10px;
}

</style>