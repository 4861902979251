<template>
<div class="vpdf" @click="deselectAll">
    <div class="vpdf-header">
        <div class="vpdf-header-logo">
            <img :src="config.logo" />
        </div>
    </div>
    <div><h1>{{ metadata.title }}</h1></div>
    <div style="margin-top: 20px;" v-for="(card, i) in cards" :key="'pc' + i">
        <div class="vpdf-item" :class="{'vpdf-item-sel':card.selected}">
            <div v-if="(card.type == 'card' || card.type == 'checklist') && card.header" class="vpdf-head-1" :style="'background-color: ' + config.colors.headerBack + '; color: ' + config.colors.headerText + ';'">
                <span>{{ card.header }}</span>
            </div>
            <div v-if="card.type == 'chtml'" class="flw100" v-html="card.html" />
            <div v-if="card.type == 'cftext'" class="flw100" v-html="card.html" />
            <div v-if="card.type == 'header'" class="vpdf-head-h">
                {{ card.header }}
            </div>
            <div v-if="card.type == 'card-header'" class="vpdf-head-h">
                <h1 :style="'font-size: ' + ((card.size || 26).toString()) + 'px'">{{ card.value[$root.locale.key] }}</h1>
            </div>
            <table v-if="card.type == 'card'" class="vpdf-tab" :class="{'vpdf-tab-mt': true}">
                <tr class="vpdf-row" v-for="(row, j) in card.rows" :key="'pr' + j">
                    <td class="vpdf-col" v-for="(input, k) in row.inputs" :key="'pi' + k" :colspan="input.width">
                        <div v-if="input.type == 'text'">
                            <label class="vpdf-itm-h">{{ input.desc }}</label>
                            <label class="vpdf-itm-v">{{ input.value }}</label>
                        </div>
                        <div class="vpdf-check" :class="{'vpdf-inline': input.inline}" v-if="input.type == 'check'">
                            <label v-if="input.desc" class="vpdf-itm-h">{{ input.desc }}</label><br v-if="input.desc">
                            <div class="vpdf-options">
                                <div class="vpdf-option" v-for="(option, x) in input.options" :key="'o' + i + x">
                                    <div class="vpdf-circle">
                                        <div class="vpdf-ball" v-if="option.checked" :style="'background-color: ' + config.colors.headerBack + ';'" />
                                    </div>
                                    <div class="vpdf-label">
                                        <label class="vpdf-itm-h">{{ option.desc }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vpdf-html" v-if="input.type == 'html'" v-html="input.desc" />
                        <div v-if="input.type == 'chk-i'">
                            <label class="vpdf-itm-h">{{ input.desc }}</label>
                        </div>
                    </td>
                </tr>
            </table>
            <div v-if="card.type == 'checklist'" class="vpdf-chk">
                <div v-for="(input, k) in card.rows" :key="'pr' + k" class="vpdf-chk-i" :class="{'vpdf-chk-il': input.last}">
                    <div class="vpdf-chk-c" v-for="(column, x) in input.columns" :key="'co' + x"
                        :style="'width: ' + column.width + '%;'" :class="{'vpdf-chk-cl': column.last }"
                    >
                        <span v-if="column.value != 'com'">{{ column.value }}</span>
                        <div v-if="column.value == 'com'">
                            <div v-for="(comment, x) in column.comments" :key="'cc' + x">
                                <div>
                                    <div>
                                        {{ comment.role }}
                                    </div>
                                    <div>
                                        {{ comment.date }}
                                    </div>
                                </div>
                                <div>
                                    {{ comment.text }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="vpdf-item-hl" @click.stop="handleCardClick(card)" />
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'PDFRenderer',
    props: {
        config: { type: Object, default: null },
        metadata: { type: Object, default: null },
        cards: { type: Array, default: null },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        handleCardClick(card) {
            this.deselectAll();
            card.selected = true;
            this.$emit('select', card);
        },

        deselectAll() {
            for (const i in this.cards) {
                this.cards[i].selected = false;
            }
            this.selectedItem = null;
        },

    },
    async created() {

    }
}
</script>
<style scoped>

.vpdf-item {
    position: relative;
}
.vpdf-item-sel {
    border: solid 2px #0DCC27;
}

.vpdf-item-hl {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
    background-color: black;
    opacity: 0;
}
.vpdf-item-hl:hover {
    opacity: 0.2;
}


.vpdf {
    font-family: Arial, Helvetica, sans-serif;
    padding: 20px 60px;
}

.vpdf-header-logo img {
    width: 200px;
}

.vpdf h1 {
    font-size: 22px;
}
.vpdf h2 {
    font-size: 18px;
    margin: 0px;
}


.vpdf-tab {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0px;
    border-collapse: separate;
    border-top: 1px solid lightgray;
    border-right: 1px solid lightgray;
}
.vpdf-tab-mt {
    margin-top: -1px;
}

.vpdf-col {
    padding: 7px;
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}


.vpdf-head-1 {
    float: left;
    width: 100%;
    padding: 10px;
    font-size: 14px;
    box-sizing: border-box;
}
.vpdf-head-h {
/*     float: left;
    width: 100%; */
    padding: 0px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: -14px;
}


.vpdf-itm-h {
    float: left;
    width: 100%;
    font-size: 11px;
}
.vpdf-itm-v {
    float: left;
    width: 100%;
    margin-top: 2px;
    font-size: 12px;
    font-weight: bold;
}

.vpdf-check {
    float: left;
    margin-top: 2px;
}
.vpdf-inline .vpdf-options {
    display: flex;
}
.vpdf-option {
    display: flex;
    margin-top: 2px;
    margin-right: 10px;
}
.vpdf-circle {
    flex-shrink: 0;
    width: 16px; height: 16px;
    border-radius: 10px;
    border: solid 1px gray;
}
.vpdf-ball {
    margin-top: 2px;
    margin-left: 2px;
    width: 12px; height: 12px;
    border-radius: 6px;
    background-color: red;
}
.vpdf-label {
    margin-top: 3px;
    margin-left: 4px;
    font-size: 12px;
}


.vpdf-html {
    font-size: 11px;
}
.vpdf-html li p {
    margin: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
}


.vpdf-chk {
    float: left;
    border: solid 1px lightgray;
    box-sizing: border-box;
}
.vpdf-chk-i {
    float: left;
    width: 100%;
    border-bottom: solid 1px lightgray;
    box-sizing: border-box;
}
.vpdf-chk-il {
    border-bottom: none;
}
.vpdf-chk-c {
    float: left;
    border-right: solid 1px lightgray;
    padding: 4px 6px;
    box-sizing: border-box;
}
.vpdf-chk-cl {
    border-right: none;
}

</style>
