import triggerHelper from '../v1flows/triggerHelper';
import v1flows from '../v1helpers/v1flows';
import v1personHelper from '../v1helpers/v1personHelper';
const v2exportHelper = {

    NOT_AVAILABLE: '-',

    //#region Loading -----------------------------------------------------------------------------------------------------

    getAvailableFields(root, type) {
        const list = [];

        if (type == 'client') {

            list.push({
                key: 'clientName',
                name: {
                    en_EN: 'Customer name',
                    nb_NO: 'Kundenavn',
                }
            });

            list.push({
                key: 'clientIdentity',
                name: {
                    en_EN: 'Customer identity',
                    nb_NO: 'Identifikator',
                }
            });

            list.push({
                key: 'clientPartnerName',
                name: {
                    en_EN: 'Partner name',
                    nb_NO: 'Partnernavn',
                }
            });

            list.push({
                key: 'clientBranchName',
                name: {
                    en_EN: 'Branch navn',
                    nb_NO: 'Avdelingsnavn',
                }
            });

            list.push({
                key: 'flowStatus',
                name: {
                    en_EN: 'Status (flow)',
                    nb_NO: 'Status (flyt)',
                }
            });

            list.push({
                key: 'reportDate',
                name: {
                    en_EN: 'Final Report Date',
                    nb_NO: 'Dato sluttrapport',
                }
            });

            list.push({
                key: 'kycEditorFirstName',
                name: {
                    en_EN: 'KYC Recipient First Name',
                    nb_NO: 'KYC Mottaker Fornavn',
                }
            });
            list.push({
                key: 'kycEditorLastName',
                name: {
                    en_EN: 'KYC Recipient Last Name',
                    nb_NO: 'KYC Mottaker Etternavn',
                }
            });
            list.push({
                key: 'kycEditorEmail',
                name: {
                    en_EN: 'KYC Recipient Email',
                    nb_NO: 'KYC Mottaker Epost',
                }
            });

        }

        if (type == 'company') {

            list.push({
                key: 'companyForm',
                name: {
                    en_EN: 'Organisation form',
                    nb_NO: 'Organisasjonsform',
                }
            });

            list.push({
                key: 'companyNaceCodes',
                name: {
                    en_EN: 'Business codes',
                    nb_NO: 'Næringskoder',
                }
            });

            list.push({
                key: 'companyNaceDescriptions',
                name: {
                    en_EN: 'Business code descriptions',
                    nb_NO: 'Næringskodebeskrivelser',
                }
            });

            list.push({
                key: 'companyDunsNumber',
                name: {
                    en_EN: 'Duns number',
                    nb_NO: 'Duns nummer',
                }
            });

            list.push({
                key: 'companyAddress',
                name: {
                    en_EN: 'Address (company)',
                    nb_NO: 'Adresse (selskap)',
                }
            });

            list.push({
                key: 'companyRegisteredCountry',
                name: {
                    en_EN: 'Country (company)',
                    nb_NO: 'Land (selskap)',
                }
            });

            list.push({
                key: 'companyDateEstablished',
                name: {
                    en_EN: 'Date established',
                    nb_NO: 'Stiftelsesdato',
                }
            });

            list.push({
                key: 'companyDateRegistered',
                name: {
                    en_EN: 'Date registered',
                    nb_NO: 'Dato registrert',
                }
            });

            list.push({
                key: 'companyIndustryCode',
                name: {
                    en_EN: 'Industry code',
                    nb_NO: 'Industrikode',
                }
            });

            list.push({
                key: 'companyInVatRegistry',
                name: {
                    en_EN: 'inVatRegistry',
                    nb_NO: 'inVatRegistry',
                }
            });
            list.push({
                key: 'companyInBusinessRegistry',
                name: {
                    en_EN: 'inBusinessRegistry',
                    nb_NO: 'inBusinessRegistry',
                }
            });
            list.push({
                key: 'companyInBrreg',
                name: {
                    en_EN: 'inBrreg',
                    nb_NO: 'inBrreg',
                }
            });
            list.push({
                key: 'companyBankrupt',
                name: {
                    en_EN: 'bankrupt',
                    nb_NO: 'bankrupt',
                }
            });
            list.push({
                key: 'companyLiquidation',
                name: {
                    en_EN: 'liquidation',
                    nb_NO: 'liquidation',
                }
            });
            list.push({
                key: 'companyCompulsoryLiquidation',
                name: {
                    en_EN: 'compulsoryLiquidation',
                    nb_NO: 'compulsoryLiquidation',
                }
            });

        }


        if (type == 'person') {

            list.push({
                key: 'personRoles',
                name: {
                    en_EN: 'Person roles',
                    nb_NO: 'Roller (person)',
                }
            });

            list.push({
                key: 'personFirstName',
                name: {
                    en_EN: 'First name',
                    nb_NO: 'Fornavn',
                }
            });

            list.push({
                key: 'personLastName',
                name: {
                    en_EN: 'Last name',
                    nb_NO: 'Etternavn',
                }
            });

            list.push({
                key: 'personDateOfBirth',
                name: {
                    en_EN: 'Date of birth',
                    nb_NO: 'Fødselsdato',
                }
            });

            list.push({
                key: 'personAddress',
                name: {
                    en_EN: 'Address (person)',
                    nb_NO: 'Adresse (person)',
                }
            });

            list.push({
                key: 'personCountry',
                name: {
                    en_EN: 'Country (person)',
                    nb_NO: 'Country (person)',
                }
            });

            list.push({
                key: 'personSanction',
                name: {
                    en_EN: 'Sanction',
                    nb_NO: 'Sanksjon',
                }
            });

            list.push({
                key: 'personPep',
                name: {
                    en_EN: 'PEP',
                    nb_NO: 'PEP',
                }
            });

            list.push({
                key: 'personPepHitIndicator',
                name: {
                    en_EN: 'Hit indicator',
                    nb_NO: 'Treff indikator',
                }
            });

            list.push({
                key: 'personPepHitDescription',
                name: {
                    en_EN: 'Hit description',
                    nb_NO: 'Treff beskrivelse',
                }
            });

            list.push({
                key: 'personPepTitle',
                name: {
                    en_EN: 'PEP Title',
                    nb_NO: 'PEP Tittel',
                }
            });

            list.push({
                key: 'personPepRole',
                name: {
                    en_EN: 'PEP role',
                    nb_NO: 'PEP Rolle',
                }
            });

            list.push({
                key: 'personPepDateOfBirth',
                name: {
                    en_EN: 'PEP date of birth',
                    nb_NO: 'PEP Fødselsdato',
                }
            });

            list.push({
                key: 'personPepSelfReported',
                name: {
                    en_EN: 'PEP Self Reported',
                    nb_NO: 'Selverklært PEP',
                }
            });

            list.push({
                key: 'personPepSelfReportedTitle',
                name: {
                    en_EN: 'PEP Title (Self Reported)',
                    nb_NO: 'PEP Tittel (Selverklært)',
                }
            });

        }


        for (const i in list) {
            list[i].selected = true;
            if (type == 'client') {
                list[i].icon = 'locale';
            }else if (type == 'company') {
                list[i].icon = 'company';
            }else if (type == 'person') {
                list[i].icon = 'person';
            }
        }
        return list;
    },

    loadPossibleFields(root, fields) {

        fields.push({
            key: 'clientName', icon: 'locale', selected: true,
            locale: { en_EN: 'Customer name', nb_NO: 'Kundenavn' }
        });
        fields.push({
            key: 'clientPartnerName', icon: 'locale', selected: true,
            locale: { en_EN: 'Partner name', nb_NO: 'Partnernavn' }
        });
        fields.push({
            key: 'clientBranchName', icon: 'locale', selected: true,
            locale: { en_EN: 'Department name', nb_NO: 'Forretningsområde' }
        });
        fields.push({
            key: 'clientIdentity', icon: 'locale', selected: true,
            locale: { en_EN: 'Customer identity', nb_NO: 'Orgnr' }
        });
        fields.push({
            key: 'clientUserName', icon: 'locale', selected: true,
            locale: { en_EN: 'Username', nb_NO: 'Bruker (Kollegaer)' }
        });
        fields.push({
            key: 'lastEventDate', icon: 'locale', selected: true,
            locale: { en_EN: 'Last event date', nb_NO: 'Siste Hendelse Dato' }
        });
        fields.push({
            key: 'lastEventText', icon: 'locale', selected: true,
            locale: { en_EN: 'Last event text', nb_NO: 'Siste Hendelse Tekst' }
        });
        fields.push({
            key: 'clientCreatedDate', icon: 'locale', selected: true,
            locale: { en_EN: 'Date created', nb_NO: 'Dato opprettet' }
        });

        // Company lookup
        fields.push({
            key: 'companyLookup', icon: 'company', selected: true,
            locale: { en_EN: 'Company lookup', nb_NO: 'AML-søk' }
        });
        fields.push({
            key: 'companyLookupDate', icon: 'company', selected: true,
            locale: { en_EN: 'Company lookup date', nb_NO: 'AML-søk TS' }
        });

        // KYC
        fields.push({
            key: 'clientKycDone', icon: 'locale', selected: true,
            locale: { en_EN: 'Self-declaration', nb_NO: 'E-skjema' }
        });
        fields.push({
            key: 'clientKycDate', icon: 'locale', selected: true,
            locale: { en_EN: 'Self-declaration date', nb_NO: 'E-skjema TS' }
        });
        fields.push({
            key: 'clientKycReminders', icon: 'locale', selected: true,
            locale: { en_EN: 'Self-declaration reminders', nb_NO: 'E-skjema påminnelser' }
        });
        fields.push({
            key: 'clientKycFirstName', icon: 'locale', selected: true,
            locale: { en_EN: 'Self-declaration first name', nb_NO: 'E-skjema fornavn' }
        });
        fields.push({
            key: 'clientKycLastName', icon: 'locale', selected: true,
            locale: { en_EN: 'Self-declaration last name', nb_NO: 'E-skjema etternavn' }
        });
        fields.push({
            key: 'clientKycEmail', icon: 'locale', selected: true,
            locale: { en_EN: 'Self-declaration email', nb_NO: 'E-skjema epost' }
        });


        // Person
        fields.push({
            key: 'personName', icon: 'person', selected: true,
            locale: { en_EN: 'Person name', nb_NO: 'Person navn' }
        });
        fields.push({
            key: 'personDob', icon: 'person', selected: true,
            locale: { en_EN: 'Person date of birth', nb_NO: 'Person fødselsdato' }
        });
        fields.push({
            key: 'personCountry', icon: 'person', selected: true,
            locale: { en_EN: 'Person country', nb_NO: 'Person land' }
        });
        fields.push({
            key: 'personRoles', icon: 'person', selected: true,
            locale: { en_EN: 'Person roles', nb_NO: 'Person roller' }
        });

        // Person Screening
        fields.push({
            key: 'scrDone', icon: 'person', selected: true,
            locale: { en_EN: 'scrDone', nb_NO: 'PEP Kontroll' }
        });
        fields.push({
            key: 'scrDate', icon: 'person', selected: true,
            locale: { en_EN: 'scrDate', nb_NO: 'PEP Kontroll TS' }
        });
        fields.push({
            key: 'scrPepIndicator', icon: 'person', selected: true,
            locale: { en_EN: 'scrPepIndicator', nb_NO: 'PEP indikator' }
        });
        fields.push({
            key: 'scrPepTitle', icon: 'person', selected: true,
            locale: { en_EN: 'scrPepTitle', nb_NO: 'PEP tittel' }
        });
        fields.push({
            key: 'pepSelfReported', icon: 'person', selected: true,
            locale: { en_EN: 'pepSelfReported', nb_NO: 'PEP Selverklært' }
        });

        // Person ID-control
        fields.push({
            key: 'idcDone', icon: 'person', selected: true,
            locale: { en_EN: 'idcDone', nb_NO: 'ID Kontroll' }
        });
        fields.push({
            key: 'idcDate', icon: 'person', selected: true,
            locale: { en_EN: 'idcDate', nb_NO: 'ID Kontroll TS' }
        });
        fields.push({
            key: 'idcComment', icon: 'person', selected: true,
            locale: { en_EN: 'idcComment', nb_NO: 'ID kommentar' }
        });
        fields.push({
            key: 'idcEmail', icon: 'person', selected: true,
            locale: { en_EN: 'idcEmail', nb_NO: 'ID epost' }
        });
        fields.push({
            key: 'idcReminderCount', icon: 'person', selected: true,
            locale: { en_EN: 'idcReminderCount', nb_NO: 'ID påminnelser' }
        });
        fields.push({
            key: 'idcReminderLast', icon: 'person', selected: true,
            locale: { en_EN: 'idcReminderLast', nb_NO: 'ID Siste påminnelse' }
        });
        fields.push({
            key: 'idcConfirmedName', icon: 'person', selected: true,
            locale: { en_EN: 'idcConfirmedName', nb_NO: 'ID Bekreftet navn' }
        });
        fields.push({
            key: 'idcConfirmedDob', icon: 'person', selected: true,
            locale: { en_EN: 'idcConfirmedDob', nb_NO: 'ID Bekreftet fødselsdato' }
        });
        fields.push({
            key: 'idcMatchIndicator', icon: 'person', selected: true,
            locale: { en_EN: 'idcMatchIndicator', nb_NO: 'ID Match Indicator' }
        });
        fields.push({
            key: 'idcMatchDescription', icon: 'person', selected: true,
            locale: { en_EN: 'idcMatchDescription', nb_NO: 'ID Match Beskrivelse' }
        });

        // Company AMS
        fields.push({
            key: 'orgAmsDone', icon: 'company', selected: true,
            locale: { en_EN: 'orgAmsDone', nb_NO: 'KYC Søk' }
        });
        fields.push({
            key: 'orgAmsDate', icon: 'company', selected: true,
            locale: { en_EN: 'orgAmsDate', nb_NO: 'KYC Søk TS' }
        });

        // Final report
        fields.push({
            key: 'reportDone', icon: 'locale', selected: true,
            locale: { en_EN: 'reportDone', nb_NO: 'Sluttrapport' }
        });
        fields.push({
            key: 'reportDate', icon: 'locale', selected: true,
            locale: { en_EN: 'reportDate', nb_NO: 'Sluttrapport TS' }
        });
        
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    loadColumns(root, fields) {
        const columns = [];
        for (const i in fields) {
            if (fields[i].selected) {
                columns.push({
                    key: fields[i].key,
                    name: fields[i].name[root.locale.key],
                    width: 10
                });
            }
        }
        return columns;
    },

    loadRows(root, fields, clients, persons, triggers, counts, filterObject) {
        const rows = [];
        for (const i in clients) {
            const row = {};
            for (const j in fields) {
                let firstPerson = null;
                if (persons.length > 0 && persons[i].length > 0) firstPerson = persons[i][0];
                row[fields[j].key] = this.processValue(this.getValue(root, fields[j].key, clients[i], firstPerson, filterObject));
            }
            rows.push(row);

            for (const k in persons[i]) {
                if (k == 0) continue;
                const row = {};
                for (const j in fields) {
                    row[fields[j].key] = this.processValue(this.getValue(root, fields[j].key, clients[i], persons[i][k], filterObject));
                }
                rows.push(row);
            }

        }
        return rows;
    },

    //#region Helpers -----------------------------------------------------------------------------------------------------

    getValue(root, key, client, person, filterObject) {
        switch (key) {
            case 'clientName': return this.getClientName(root, client);
            case 'clientIdentity': return this.clientIdentity(root, client);
            case 'clientBranchName': return this.getClientBranchName(root, client);
            case 'clientPartnerName': return this.getClientPartnerName(root, client);
            case 'clientUserName': return this.getClientUserName(root, client);
            case 'flowStatus': return this.getFlowStatus(root, client, filterObject);
            case 'reportDate': return this.getReportDate(root, client, filterObject);
            case 'kycEditorFirstName': return this.getKycEditor(root, client, filterObject).firstName;
            case 'kycEditorLastName': return this.getKycEditor(root, client, filterObject).lastName;
            case 'kycEditorEmail': return this.getKycEditor(root, client, filterObject).email;


        }

        if (key.includes('company')) return this.getCompanyValue(root, client, key);
        if (key.includes('person')) return this.getPersonValue(root, client, person, key);

        return '--E--';
    },

    processValue(value) {
        if (!value) return '';
        value = value.toString();
        value = value.replaceAll('#', '');
        value = value.replaceAll(';', '');
        value = value.replaceAll('/', '');
        return value;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Values ------------------------------------------------------------------------------------------------------

    getClientName(root, client) {
        return root.companyTitleCase(client.name);
    },

    clientIdentity(root, client) {
        if (client.type == 'person') {
            if (client.person.ssn) return client.person.ssn;
        }else {
            return client.identity;
        }
        return '';
    },

    getClientUserName(root, client) {
        return root.getEmployeeName(client.userId);
    },

    getClientBranchName(root, client) {
        return root.getDepartmentName(client.branch);
    },

    getClientPartnerName(root, client) {
        return root.getPartnerName(client.partner);
    },

    getFlowStatus(root, client, filterObject) {
        if (filterObject.texts.status[client.status]) return filterObject.texts.status[client.status][root.locale.key];
        return client.status;
    },

    getReportDate(root, client, filterObject) {
        if (client.report.created) {
            return client.report.dateCreated.split('T')[0];
        }
        return '-';
    },

    getKycEditor(root, client, filterObject) {
        let form = null;
        if (client.kyc00) {
            console.log('form', client.kyc00);
            form = client.kyc00;
        }
        if (form) {
            if (form.editors && form.editors.length > 0) {
                const editor = form.editors[0];
                return {
                    firstName: editor.firstName,
                    lastName: editor.lastName,
                    email: editor.email,
                };
            }
        }
        return {
            firstName: '-',
            lastName: '-',
            email: '-',
        };
    },

    getCompanyValue(root, client, key) {

        if (!client.company || !client.company.lookup) return '-';

        if (key == 'companyForm') {

            console.log('company', client.company);

            return client.company.form.formDescription;

        }else if (key == 'companyNaceCodes') {

            if (client.company.naceCodes.length > 0) {
                return client.company.naceCodes[0].code;
            }
            return '';

        }else if (key == 'companyNaceDescriptions') {

            if (client.company.naceCodes.length > 0) {
                return client.company.naceCodes[0].description;
            }
            return '';

        }else if (key == 'companyDunsNumber') {

            return client.company.info.dunsNumber;

        }else if (key == 'companyAddress') {

            if (client.company.address.business.street) {
                return client.company.address.business.street + ', ' + client.company.address.business.zip + ' ' + client.company.address.business.city;
            }
            return '';

        }else if (key == 'companyRegisteredCountry') {

            return client.company.info.country;

        }else if (key == 'companyDateEstablished') {

            return client.company.info.dateEstablished;

        }else if (key == 'companyDateRegistered') {

            return client.company.info.dateRegistered;

        }else if (key == 'companyIndustryCode') {

            if (client.company.form.industryCode) return client.company.form.industryCode + ' - ' + client.company.form.industryDescription;
            return '-';

        }else if (key == 'companyBusiness') {

            return '';

        }else if (key == 'companyInVatRegistry') {

            return this.getYesNo(root, client.company.registration.inVatRegistry);

        }else if (key == 'companyInBusinessRegistry') {

            return this.getYesNo(root, client.company.registration.inBusinessRegistry);

        }else if (key == 'companyInBrreg') {

            return this.getYesNo(root, client.company.registration.inBrreg);

        }else if (key == 'companyBankrupt') {

            return this.getYesNo(root, client.company.registration.bankrupt);

        }else if (key == 'companyLiquidation') {

            return this.getYesNo(root, client.company.registration.liquidation);

        }else if (key == 'companyCompulsoryLiquidation') {

            return this.getYesNo(root, client.company.registration.compulsoryLiquidation);

        }


    },

    getPersonValue(root, client, person, key) {

        if (!person) return '-';

        if (key == 'personFirstName') {

            return root.companyTitleCase(person.info.firstName);

        }else if (key == 'personLastName') {

            return root.companyTitleCase(person.info.lastName);

        }else if (key == 'personDateOfBirth') {

            return person.info.dateOfBirth;

        }else if (key == 'personRoles') {

            return v1personHelper.getTextRoles(person, root);

        }else if (key == 'personAddress') {

            const bestSource = v1personHelper.getBestResidencySource(person);
            if (!bestSource) return '-';
            return v1personHelper.formatAddressString(root, bestSource);

        }else if (key == 'personCountry') {

            const bestSource = v1personHelper.getBestResidencySource(person);
            if (!bestSource) return '-';
            return root.getCountryName(bestSource.country)

        }else if (key == 'personSanction') {

            if (!person.screening.done) return '-';
            return this.getYesNoBool(root, person.screening.hasSanction);

        }else if (key == 'personPep') {

            if (!person.screening.done) return '-';
            return this.getYesNoBool(root, person.screening.isPep);

        }else if (key == 'personPepHitIndicator') {

            if (!person.screening.done) return '-';
            if (person.screening.isPep) {
                return person.screening.pepSummary.matchIndicator;
            }

            return '-';

        }else if (key == 'personPepHitDescription') {

            if (!person.screening.done) return '-';
            if (person.screening.isPep) {
                return person.screening.pepSummary.matchDescription;
            }

            return '-';

        }else if (key == 'personPepTitle') {

            if (!person.screening.done) return '-';
            if (person.screening.isPep) {
                return person.screening.pepSummary.title;
            }

            return '-';

        }else if (key == 'personPepRole') {

            return '';

        }else if (key == 'personPepDateOfBirth') {

            return '';

        }else if (key == 'personPepSelfReported') {

            return this.getYesNoBool(root, person.kycPep.reported);

        }else if (key == 'personPepSelfReportedTitle') {

            if (person.kycPep.reported) {
                return person.kycPep.title;
            }

            return '-';

        }

    },

    getYesNo(root, value) {
        if (value == 'Y') return root.__('common_yes').toUpperCase();
        if (value == 'N') return root.__('common_no').toUpperCase();
        return '-';
    },

    getYesNoBool(root, value) {
        if (value) return root.__('common_yes').toUpperCase();
        return root.__('common_no').toUpperCase();
    },


    //#endregion ----------------------------------------------------------------------------------------------------------

    getCustomText(root, flowKey, key, value) {
        const flow = v1flows.getFlow(root, flowKey);

        if (flow.fields) {
            for (const i in flow.fields) {
                if (flow.fields[i].key == key) {
                    for (const j in flow.fields[i].values) {
                        if (flow.fields[i].values[j].key == value) {
                            return flow.fields[i].values[j];
                        }
                    }
                }
            }
        }

        return { en_EN: 'CUSTOM VALUE NOT FOUND' };
    }

};
export default v2exportHelper;