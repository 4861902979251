<template>
<div class="aml-page">
    
    <vap-form-divider :left="50" :right="50">
        <template v-slot:left>
            <vap-label :text="'Logo'" header style="margin-bottom: 5px;" />
            <vap-card>
                <vap-row>
                    <vap-group c12>
                        <vap-label :text="'Header'" desc />
                        <vap-input v-model="$root.config.portal.header" noValidation />
                    </vap-group>
                    <vap-group c12>
                        <vap-label :text="'Logo URL'" desc />
                        <vap-input v-model="$root.config.portal.logoUrl" noValidation />
                    </vap-group>
                    <vap-group c4>
                        <vap-label :text="'Size adjustment'" desc />
                        <vap-slider v-model="$root.config.portal.logoWidth" :min="10" :max="500" />
                    </vap-group>
                    <vap-group c4>
                        <vap-label :text="'Top offset'" desc />
                        <vap-slider v-model="$root.config.portal.logoTop" :min="0" :max="50" />
                    </vap-group>
                    <vap-group c4>
                        <vap-label :text="'Left offset'" desc />
                        <vap-slider v-model="$root.config.portal.logoLeft" :min="0" :max="50" />
                    </vap-group>
                </vap-row>
            </vap-card>
        </template>
        <template v-slot:right>
            <vap-label :text="__('settings_preview')" header style="margin-bottom: 5px;" />
            <vap-card>
                <div class="logo-preview">
                    <div class="logo-preview-bar" >
                        <img :src="$root.config.portal.logoUrl" 
                            :style="'float: left; width:' + $root.config.portal.logoWidth + 'px; margin-top:' + $root.config.portal.logoTop + 'px; margin-left:' + $root.config.portal.logoLeft + 'px;'" />
                        <label style="color: var(--header-text);" class="logo-text">{{ $root.config.portal.header }}</label>
                    </div>
                </div>
            </vap-card>
        </template>
    </vap-form-divider>

    <vap-form-divider :left="50" :right="50" style="margin-top: 20px;">
        <template v-slot:left>
            <vap-label :text="'Emails (SVG not supported)'" header style="margin-bottom: 5px;" />
            <vap-card>
                <vap-row>
                    <vap-group c12>
                        <vap-label :text="'Logo URL'" desc />
                        <vap-input v-model="$root.config.branding.logoEmail" noValidation />
                    </vap-group>
                </vap-row>
            </vap-card>
        </template>
        <template v-slot:right>
            <vap-label :text="__('settings_preview')" header style="margin-bottom: 5px;" />
            <vap-card>
                <div class="pdf-preview">
                    <img :src="$root.config.branding.logoEmail" 
                        :style="'float: left; width:' + $root.config.pdf.logoWidth + 'px; margin-top:' + '0' + 'px; margin-left:' + '0' + 'px;'" />
                </div>
            </vap-card>
        </template>
    </vap-form-divider>

    <vap-form-divider :left="50" :right="50" style="margin-top: 20px;">
        <template v-slot:left>
            <vap-label :text="'Landing page / large logo'" header style="margin-bottom: 5px;" />
            <vap-card>
                <vap-row>
                    <vap-group c12>
                        <vap-label :text="'Logo URL'" desc />
                        <vap-input v-model="$root.config.branding.logoLanding" noValidation />
                    </vap-group>
                    <vap-group c12>
                        <vap-label :text="'Homepage'" desc />
                        <vap-input v-model="$root.config.branding.redirectUri" noValidation />
                    </vap-group>
                </vap-row>
            </vap-card>
        </template>
        <template v-slot:right>
            <vap-label :text="__('settings_preview')" header style="margin-bottom: 5px;" />
            <vap-card style="background-color: var(--page-background);">
                <div class="pdf-preview">
                    <img :src="$root.config.branding.logoLanding" 
                        :style="'float: left; width:' + '400' + 'px; margin-top:' + '0' + 'px; margin-left:' + '0' + 'px;'" />
                </div>
            </vap-card>
        </template>
    </vap-form-divider>

    <vap-form-divider :left="50" :right="50" style="margin-top: 20px;">
        <template v-slot:left>
            <vap-label :text="'PDF (SVG not supported)'" header style="margin-bottom: 5px;" />
            <vap-card>
                <vap-row>
                    <vap-group c12>
                        <vap-label :text="'Logo URL'" desc />
                        <vap-input v-model="$root.config.pdf.logoUrl" noValidation />
                    </vap-group>
                </vap-row>
            </vap-card>
        </template>
        <template v-slot:right>
            <vap-label :text="__('settings_preview')" header style="margin-bottom: 5px;" />
            <vap-card>
                <div class="pdf-preview">
                    <img :src="$root.config.pdf.logoUrl" 
                        :style="'float: left; width:' + $root.config.pdf.logoWidth + 'px; margin-top:' + '0' + 'px; margin-left:' + '0' + 'px;'" />
                    <label>{{ 'PDF Header preview' }}</label>
                </div>
            </vap-card>
        </template>
    </vap-form-divider>

    <vap-form-divider :left="50" :right="50" stickyRight :stickyOffset="1000" :stickyTop="90" style="margin-top: 20px;">
        <template v-slot:left>

            <div class="elements">
                <vap-label :text="'Elements'" header />
                <div @click="resetColors"><span>Reset colors</span></div>
            </div>

            

            <vap-card v-for="(element, i) in $root.config.colors" :key="i" noPadding compactList :selected="i == selectedColorIndex" v-show="element.show">
                <vap-item @clicked="handleColorClick(i)">
                    <div class="stng-color">
                        <div class="stng-color-circle" :style="'background-color: var(--' + element.key + ');'" />
                        <label>{{ element.name }}</label>
                    </div>
                    <div v-if="element.hint && i == selectedColorIndex" class="color-hint">
                        {{ element.hint }}
                    </div>
                </vap-item>
            </vap-card>

        </template>
        <template v-slot:right>
            <vap-label :text="'Color'" header style="margin-bottom: 5px;" />
            <vap-card>
                <div style="padding-top: 30px;">
                    <Chrome v-model="colors" @input="updateValue" style="margin: 0 auto;" />
                </div>
                
            </vap-card>
        </template>
    </vap-form-divider>

</div>
</template>
<script>
import { Chrome } from 'vue-color';
import config from '../config';

export default {
    name: 'ThemeSettings',
    components: {
        Chrome
    },
    data() {
        return {
            colors: '#194d33',
            selectedColorIndex: -1,
        }
    },
    methods: {
        
        updateValue(val) {
            console.log(val);
            let selected = this.$root.config.colors[this.selectedColorIndex];
            if (this.$root.darkMode) {
                selected.darkValue = val.hex;
                document.documentElement.style.setProperty('--' + selected.key, selected.darkValue);
            }else {
                selected.value = val.hex;
                document.documentElement.style.setProperty('--' + selected.key, selected.value);
            }
        },

        handleColorClick(index) {
            this.selectedColorIndex = index;
            if (this.$root.darkMode) {
                this.colors = this.$root.config.colors[index].darkValue;
            }else {
                this.colors = this.$root.config.colors[index].value;
            }
            console.log('si = ' + index)
        },

        resetColors() {
            this.$root.config.colors = config.getDefault().colors;

            for (let i in this.$root.config.colors) {
                if (!this.$root.darkMode) {
                    document.documentElement.style.setProperty('--' + this.$root.config.colors[i].key, this.$root.config.colors[i].value);
                }else {
                    document.documentElement.style.setProperty('--' + this.$root.config.colors[i].key, this.$root.config.colors[i].darkValue);
                }
            }
        }

    },
    async created() {
        
    }
}
</script>
<style scoped>
.color-hint {
    margin-left: 80px;
    margin-top: -10px;
    margin-bottom: 10px;
    color: #888;
}

.elements {
    display: flex;
}

.elements > div {
    margin-bottom: 5px;
    width: 50%;
}

.elements > div:last-of-type {
    text-align: right;
    align-self: flex-end;
    padding-bottom: 2px;
}

.elements > div:last-of-type > span {
    color: var(--link-text);
}

.elements > div:last-of-type > span:hover {
    color: var(--link-active);
    cursor: pointer;
}
</style>