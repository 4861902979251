<template>
<div class="vf-kyca" @click.stop="handleClick" :class="[
    { 'vf-selected' : selected }
]">
    
    <div v-if="designer">
        {{ 'Assessment information' }}
    </div>

    <div v-if="!designer">
        
        <div class="vf-kyca-page">
            <ReportItem list v-for="(item, i) in reportParams.items" :key="'c' + i" :item="item"
                :rdata="reportParams.data[item.dataKey]"
                @change="validateTriggers"
                :disabled="client.report.created"
                :isPartner="isPartner"
            >

                <ReportCompany v-if="item.key == 'org'"
                    :item="item"
                    :client="client"
                    :disabled="client.report.created"
                    @preview="previewFile"
                />
                <ReportRole v-if="item.key == 'roles'"
                    :item="item"
                    :disabled="client.report.created"
                    @preview="previewPersonFile"
                />
                <ReportRisk v-if="item.key == 'assessment'"
                    :item="item"
                    :client="client"
                    :riskOptions="reportParams.riskOptions"
                    :reportCreated="client.report.created"
                    :disabled="client.report.created"
                    :lockHighRisk="lockHighRisk"
                    :isPartner="isPartner"
                    @changed="validateTriggers"
                />
                <ReportCustom v-if="item.key != 'org' && item.key != 'roles' && item.key != 'assessment'"
                    @preview="previewFile"
                    :item="item"
                    :client="client"
                    :disabled="client.report.created"
                    @changed="validateTriggers"
                />

                <FormReportView ref="reportForm" v-if="item.key == 'form'"
                    :clientId="client._id"
                    :initialFormKey="item.formKey"
                    @validated="onFormValidated"
                />

            </ReportItem>
            <div class="flw100" style="margin-top: 120px;" />
        </div>

    </div>

</div>
</template>

<script>
import v1api from '../../../../v1helpers/v1api';
import v1flows from '../../../../v1helpers/v1flows';
import v1reportHelper from '../../../../v1helpers/v1reportHelper';
import triggerHelper from '../../../../v1flows/triggerHelper';
import previewHelper from '../../../../v1files/previewHelper';

import ReportItem from '../../../../v1report/ReportItem.vue';

import ReportCompany from '../../../../v1report/ReportCompany.vue';
import ReportRole from '../../../../v1report/ReportRole.vue';
import ReportRisk from '../../../../v1report/ReportRisk.vue';
import ReportCustom from '../../../../v1report/ReportCustom.vue';

export default {
    name: 'KycAssessmentInfo',
    components: {
        ReportItem,
        ReportCompany,
        ReportRole,
        ReportRisk,
        ReportCustom,
    },
    props: {
        cardData: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    data() {
        return {
            flow: null,
            client: null,
            persons: [],
            counts: {},
            triggers: {},
            texts: {},
            reportParams: {
                items: [],
                data: {},
                riskOptions: {}
            },
            statusText: null,
            allCompleted: false,
            isSaving: false,
            lockHighRisk: false,

            isDualRisk: true,
            isPartner: true,
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

        //#region Actions -----------------------------------------------------------------------------------------------------

        previewFile(key) {
            this.$emit('pdf', key);
        },

        previewPersonFile(previewParams) {
            this.$emit('preview', previewParams);
        },

        saveToMemory() {

            const items = [];
            for (const i in this.reportParams.data) {
                const item = this.reportParams.data[i];
                if (item.key == 'form') {
                    if ((this.$refs.reportForm && this.$refs.reportForm.length > 0)) {
                        const formData = this.$refs.reportForm[0].getFormData();
                        item.formData = JSON.stringify(formData);
                    }
                }
                items.push(item);
            }

            this.reference.assessmentData = {
                items: items,
                risk: this.client.report.risk || 0,
                riskUser: this.client.report.riskUser || 0,
            };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Trigger validation ------------------------------------------------------------------------------------------

        validateTriggers() {
            v1reportHelper.validateTriggers(this);
            this.saveToMemory();
            console.log('allCompleted', this.allCompleted);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {
        if (this.designer) {

            // Do nothing ?

        }else {

            if (this.reference.roleKey == 'owner') {

                const existingData = JSON.parse(this.$root.formData);
                console.log('existing', existingData);

                this.client = existingData.customer;
                this.persons = existingData.persons;
                this.counts = existingData.counts;

                this.isPartner = false;

            }else {
                
                this.client = this.reference.clientData.client;
                this.persons = this.reference.clientData.persons;
                this.counts = this.reference.clientData.counts;

                this.isPartner = true;

            }

            this.flow = v1flows.getFlow(this.$root, this.client.flow);


            // Load local saved data
            if (this.reference.assessmentData) {

                this.client.report.items = this.reference.assessmentData.items;
                this.client.report.risk = this.reference.assessmentData.risk || 0;
                this.client.report.riskUser = this.reference.assessmentData.riskUser || 0;

            }


            // Set triggers
            triggerHelper.setTriggers(this.$root, this.triggers, this.client, this.persons, this.counts);
            triggerHelper.setTexts(this.$root, this.texts, this.client, this.counts);


            // Load report data
            v1reportHelper.loadReportData(this.$root, this.reportParams, this.client, this.flow, this.persons, true);

            // Check if report contains a form
            for (const i in this.flow.report) {
                if (this.flow.report[i].key == 'form') {

                    // Give report form client context
                    let existingData = {
                        companyData: this.client.company,
                        customer: this.client,
                        persons: this.persons,
                        client: this.client
                    };
                    if (this.flow.isPerson) existingData.person = this.persons[0];

                    // Load saved form data
                    if (this.client.report.items) {
                        for (const j in this.client.report.items) {
                            if (this.client.report.items[j].key == 'form' && this.client.report.items[j].formData) {
                                existingData.savedPages = JSON.parse(this.client.report.items[j].formData);
                                existingData.loadSavedData = true;
                            }
                        }
                    }

                    //this.$root.formData = JSON.stringify(existingData);
                }
            }

            this.validateTriggers();

        }
    }
}
</script>
<style scoped>

.vf-kyca {
    float: left;
    width: 100%;
    /* padding: 20px; */
    box-sizing: border-box;
}

.vf-kyca-page {
    padding: 0px 2px;
}


/* Designer / Selection */

.vf-selected {
    outline: solid 2px #238ccc;
}

</style>