const kycp01 = {

  getForm() {
      return {
        "key": "kycp01",
        "designVersion": 1,
        "locales": [
          {
            "key": "en_EN",
            "name": "English",
            "enabled": true,
            "alpha2": "gb"
          },
          {
            "key": "nb_NO",
            "name": "Norsk",
            "enabled": true,
            "alpha2": "no"
          },
          {
            "key": "sv_SE",
            "name": "Svenska",
            "enabled": true,
            "alpha2": "se"
          }
        ],
        "designName": "Self-declaration form Physical Person",
        "title": {
          "en_EN": "Declaration form physical person",
          "nb_NO": "Egenerklæring fysisk person",
          "sv_SE": "Kundkännedom naturlig person"
        },
        "name": {
          "en_EN": "Self-declaration {{customerName}}",
          "nb_NO": "Egenerklæring {{customerName}}",
          "sv_SE": "Kundkännedom {{customerName}}"
        },
        "flowType": "sign",
        "flowConfig": {
          "autoSave": false,
          "canSave": false,
          "customSender": {
            "enabled": false
          },
          "landingParams": {
            "filled": {
              "title": {
                "en_EN": "Declaration form filled",
                "nb_NO": "Egenerklæringsskjema fylt ut",
                "sv_SE": "Kundkännedom är ifylld"
              },
              "message": {
                "en_EN": "<p>Thank you for filling out the declaration form.</p><p></p><p>The persons you selected will now receive an e-mail invitation to sign the decleration.</p>",
                "nb_NO": "<p>Takk for at du fylte ut egenerklæringsskjemaet.</p><p></p><p>De valgte personene vil nå få en e-post med invitasjon til å signere.</p>",
                "sv_SE": "<p>Tack för att du fyllt i formuläret för kundkännedom.</p><p></p><p>De valda personerna får nu ett e-postmeddelande med en inbjudan att underteckna.</p><p></p>"
              }
            },
            "signed": {
              "title": {
                "en_EN": "Declaration form signed",
                "nb_NO": "Egenerklæringsskjema signert",
                "sv_SE": "Kundkännedom signerad"
              },
              "message": {
                "en_EN": "<p>You have now signed the declaration form regarding your cooperation with us.</p><p></p><p>You will now receive an e-mail with a copy of the signed document.</p><p></p><p>Thank you for your cooperation.</p>",
                "nb_NO": "<p>Du har nå signert egenerklæringen i forbindelse med deres samarbeid med oss.</p><p></p><p>Du vil nå motta en e-post med en kopi av det signerte dokumentet.</p><p></p><p>Takk for ditt samarbeid.</p>",
                "sv_SE": "<p>Du har nu undertecknat kundkännedom angående ditt samarbete med oss.</p><p></p><p>Du kommer nu få ett e-postmeddelande med en kopia av det signerade dokumentet.</p><p></p><p>Tack för ditt samarbete.</p>"
              }
            },
            "aborted": {
              "title": {
                "en_EN": "Signing aborted",
                "nb_NO": "Signering avbrutt",
                "sv_SE": "Signering avbröts"
              },
              "message": {
                "en_EN": "<p>You have aborted the signing process, please contact your contact person in Verified AS to discuss further option.</p>",
                "nb_NO": "<p>Du har avbrutt signeringsprosessen, ta gjerne kontakt med din kontaktperson for oppdraget slik at vi kan bli enige om veien videre.</p><p></p>",
                "sv_SE": "<p>Du har avbrutit signeringsprocessen, kontakta gärna din kontaktperson för uppdraget så att vi kan komma överens om vägen framåt.</p><p></p>"
              }
            },
            "redirect": "",
            "logoUrl": ""
          },
          "roles": [
            {
              "key": "owner",
              "type": "owner",
              "display": "Owner",
              "next": "editor",
              "hasEmail": false,
              "email": {},
              "hasLanding": false,
              "landingParams": {},
              "redirect": "client"
            },
            {
              "key": "editor",
              "display": "Editor (Fill in form)",
              "next": "signer",
              "hasEmail": true,
              "email": {
                "subject": {
                  "en_EN": "You have new form to fill in",
                  "nb_NO": "Du har et nytt skjema å fylle ut",
                  "sv_SE": "Kundkännedom {{customerName}}"
                },
                "header": {
                  "en_EN": "Hi {{firstName}} {{lastName}}",
                  "nb_NO": "Hei {{firstName}} {{lastName}}",
                  "sv_SE": "Hej {{firstName}} {{lastName}}"
                },
                "message": {
                  "en_EN": "{{senderName}} have sent you a form ready to be filled in.",
                  "nb_NO": "{{senderName}} har sendt deg et nytt skjema som må fylles ut.",
                  "sv_SE": "I samband med ditt samarbete med {{companyName}} ber vi dig svara på ett kundkännedomsformulär."
                },
                "button": {
                  "en_EN": "Open form",
                  "nb_NO": "Åpne skjema",
                  "sv_SE": "GÅ VIDARE"
                },
                "hint": {
                  "en_EN": "Click the button to open the form.",
                  "nb_NO": "Trykk på knappen for å åpne skjema.",
                  "sv_SE": "Tryck på knappen för att öppna formuläret och börja fylla i det"
                },
                "footer": {
                  "en_EN": "If you have any questions please contact {{senderName}} on {{senderEmail}}",
                  "nb_NO": "Hvis du har noen spørsmål vennligst kontakt {{senderName}} på {{senderEmail}}",
                  "sv_SE": "Om du har några frågor, vänligen kontakta {{senderName}} på {{senderEmail}}"
                },
                "links": []
              },
              "hasLanding": true,
              "redirect": ""
            },
            {
              "key": "signer",
              "display": "Signer",
              "next": "done",
              "hasEmail": true,
              "email": {
                "subject": {
                  "en_EN": "Document for signing",
                  "nb_NO": "Dokument for signering",
                  "sv_SE": "Kundkännedom {{customerName}} klar för {{action}}"
                },
                "header": {
                  "en_EN": "Hi {{firstName}} {{lastName}}",
                  "nb_NO": "Hei {{firstName}} {{lastName}}",
                  "sv_SE": "Hej {{firstName}} {{lastName}}"
                },
                "message": {
                  "en_EN": "You have received a new document for signing",
                  "nb_NO": "Du har mottatt et nytt dokument for signering",
                  "sv_SE": "Du har blivit ibjuden av {{companyName}} till att {{actionVerb}} följande dokument:"
                },
                "button": {
                  "en_EN": "Go to signing",
                  "nb_NO": "Gå til signering",
                  "sv_SE": "GÅ VIDARE"
                },
                "hint": {
                  "en_EN": "Click the button to open the document.",
                  "nb_NO": "Trykk på knappen for å åpne dokumentet.",
                  "sv_SE": "Starta signeringsprocessen genom att trycka på knappen ovan"
                },
                "footer": {
                  "en_EN": "If you have any questions please contact {{senderName}} on {{senderEmail}}",
                  "nb_NO": "Hvis du har noen spørsmål vennligst kontakt {{senderName}} på {{senderEmail}}",
                  "sv_SE": "Om du har några frågor, vänligen kontakta {{senderName}} på {{senderEmail}}"
                },
                "links": []
              },
              "hasLanding": true,
              "landingParams": {},
              "redirect": ""
            },
            {
              "key": "done",
              "display": "Document signed",
              "hasEmail": true,
              "email": {
                "subject": {
                  "en_EN": "Signed document",
                  "nb_NO": "Signert dokument",
                  "sv_SE": "Signerat dokument"
                },
                "header": {
                  "en_EN": "Hi {{firstName}} {{lastName}}",
                  "nb_NO": "Hei {{firstName}} {{lastName}}",
                  "sv_SE": "Hej {{firstName}} {{lastName}}"
                },
                "message": {
                  "en_EN": "Here is your copy of the signed document",
                  "nb_NO": "Her er din kopi av det signerte dokumentet",
                  "sv_SE": "Här är din kopia av det signerade dokumentet"
                },
                "button": {
                  "en_EN": "Open form",
                  "nb_NO": "Åpne skjema",
                  "sv_SE": "Öppna formuläret"
                },
                "hint": {
                  "en_EN": "Click on the document name to download it for archiving",
                  "nb_NO": "Trykk på navnet for å laste det ned for arkivering",
                  "sv_SE": "Klicka på dokumentets namn för att ladda ner det för arkivering"
                },
                "footer": {
                  "en_EN": "If you have any questions please contact {{senderName}} on {{senderEmail}}",
                  "nb_NO": "Hvis du har noen spørsmål vennligst kontakt {{senderName}} på {{senderEmail}}",
                  "sv_SE": "Om du har några frågor vänligen kontakta {{senderName}} på {{senderEmail}}"
                },
                "links": []
              },
              "hasLanding": false,
              "landingParams": {
                "redirect": "default"
              }
            }
          ],
          "flags": {
            "autoSaveMessage": false,
            "autoSaveLoaded": false
          }
        },
        "modules": [
          {
            "type": "kyc",
            "icon": "eye",
            "updateKyc": true
          },
          {
            "type": "sign",
            "icon": "sign",
            "methods": {
              "bankidno": true,
              "bankidse": true,
              "touchsign": true
            }
          }
        ],
        "counter": 1302,
        "pages": [
          {
            "key": "p1005",
            "type": "page",
            "mode": "form-page",
            "title": {
              "en_EN": "Customer information",
              "nb_NO": "Opplysninger om kunden",
              "sv_SE": "Kundinformation"
            },
            "showStepper": true,
            "hasInfo": false,
            "info": {
              "owner": [
                {
                  "key": "h1004",
                  "icon": "company",
                  "header": {
                    "en_EN": "Company information",
                    "nb_NO": "Foretaket",
                    "sv_SE": "Företagsinformation"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "<p>There has been performed a lookup in public registries wich returned the information below. The customer will be asked to check if the information is correct.</p>",
                    "nb_NO": "<p>Det er gjennomført et søk i offentlige registre på foretaket som returnerte informasjonen under. Kunden får beskjed om å sjekke at informasjonen stemmer.</p>",
                    "sv_SE": "<p>En sökning har genomförts i offentliga register över företaget som returnerade informationen nedan. Kunden uppmanas att kontrollera att informationen är korrekt.</p>"
                  }
                }
              ],
              "editor": [
                {
                  "key": "h1021",
                  "icon": "company",
                  "header": {
                    "en_EN": "Company information",
                    "nb_NO": "Foretaksinformasjon",
                    "sv_SE": "Företagsinformation"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "<p>We have performed a lookup in public registries wich returned the information below. Please look over the results and correct any missing/outdated information.</p>",
                    "nb_NO": "<p>Det er gjennomført et søk i offentlige registre på foretaket som returnerte informasjonen under. Gå gjennom og sjekk at denne informasjonen stemmer overens med dagens virkelighet, og rett opp hvis noe er feil.</p>",
                    "sv_SE": "<p>En sökning har genomförts i offentliga register över företaget som returnerade informationen nedan. Gå igenom och kontrollera att denna information är i linje med dagens verklighet och korrigera om något är fel.</p>"
                  }
                }
              ],
              "signer": [
                {
                  "key": "h1030",
                  "icon": "help-o",
                  "header": {
                    "en_EN": "New help text",
                    "nb_NO": "Ny hjelpetekst"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "This is a new help text",
                    "nb_NO": "Dette er en ny hjelpetekst"
                  }
                }
              ],
              "done": [
                {
                  "key": "h1282",
                  "icon": "help-o",
                  "header": {
                    "en_EN": "New help text",
                    "nb_NO": "Ny hjelpetekst"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "This is a new help text",
                    "nb_NO": "Dette er en ny hjelpetekst"
                  }
                }
              ]
            },
            "cards": [
              {
                "key": "c1289",
                "type": "card",
                "header": {
                  "en_EN": "",
                  "nb_NO": "Opplysninger om kunden"
                },
                "inputs": [
                  {
                    "key": "i1290",
                    "type": "text",
                    "inputType": "text",
                    "width": "6",
                    "desc": {
                      "en_EN": "Firstname",
                      "nb_NO": "Fornavn",
                      "sv_SE": "Förnamn"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "kycKey": "",
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "modelKey": "firstName"
                  },
                  {
                    "key": "i1291",
                    "type": "text",
                    "inputType": "text",
                    "width": "6",
                    "desc": {
                      "en_EN": "Lastname",
                      "nb_NO": "Etternavn",
                      "sv_SE": "Efternamn"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "kycKey": "",
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "modelKey": "lastName"
                  },
                  {
                    "key": "i1302",
                    "type": "date",
                    "width": "6",
                    "desc": {
                      "en_EN": "Date of birth",
                      "nb_NO": "Fødselsdato",
                      "sv_SE": "Födelsedatum"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "kycKey": "",
                    "modelKey": "dob"
                  },
                  {
                    "key": "i1295",
                    "type": "phone",
                    "width": "6",
                    "desc": {
                      "en_EN": "Phone number",
                      "nb_NO": "Telefonnummer",
                      "sv_SE": "Telefonnummer"
                    },
                    "modelKey": "phone",
                    "initial": "system",
                    "required": false,
                    "noPadding": false,
                    "pdf": true,
                    "kycKey": "",
                    "model": {
                      "prefix": "+47",
                      "number": ""
                    },
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    }
                  },
                  {
                    "key": "i1299",
                    "type": "text",
                    "inputType": "text",
                    "width": "6",
                    "desc": {
                      "en_EN": "Address",
                      "nb_NO": "Adresse",
                      "sv_SE": "Adress"
                    },
                    "modelKey": "street",
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "kycKey": "",
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    }
                  },
                  {
                    "key": "i1300",
                    "type": "text",
                    "inputType": "number",
                    "width": "2",
                    "desc": {
                      "en_EN": "Zip code",
                      "nb_NO": "Postnummer",
                      "sv_SE": "Postnummer"
                    },
                    "modelKey": "zip",
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "kycKey": "",
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "external": true,
                    "logic": {
                      "rdict": {
                        "key": "zipCodes",
                        "global": true
                      },
                      "dmatch": {
                        "keys": [
                          "i1301"
                        ]
                      }
                    }
                  },
                  {
                    "key": "i1301",
                    "type": "text",
                    "inputType": "text",
                    "width": "4",
                    "desc": {
                      "en_EN": "City",
                      "nb_NO": "Poststed",
                      "sv_SE": "Stad"
                    },
                    "modelKey": "city",
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "kycKey": "",
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    }
                  },
                  {
                    "key": "c1293",
                    "type": "country",
                    "width": "6",
                    "desc": {
                      "en_EN": "Citizenship",
                      "nb_NO": "Statsborgerskap",
                      "sv_SE": "Medborgarskap"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "kycKey": "",
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    }
                  },
                  {
                    "key": "c1296",
                    "type": "country",
                    "width": "6",
                    "desc": {
                      "en_EN": "Tax country",
                      "nb_NO": "Dersom skattepliktig til andre land",
                      "sv_SE": "Om skattskyldig till ett annat land"
                    },
                    "required": false,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "kycKey": "",
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    }
                  }
                ],
                "noPadding": false,
                "pdf": true,
                "roles": {
                  "owner": true,
                  "editor": true,
                  "signer": true,
                  "done": true
                },
                "isPerson": true
              }
            ],
            "roles": {
              "owner": true,
              "editor": true,
              "signer": true,
              "done": true
            }
          },
          {
            "key": "p1013",
            "type": "page",
            "mode": "form-page",
            "title": {
              "en_EN": "PEP",
              "nb_NO": "PEP",
              "sv_SE": "PEP"
            },
            "showStepper": true,
            "hasInfo": false,
            "info": {
              "owner": [
                {
                  "key": "h1012",
                  "icon": "pep",
                  "header": {
                    "en_EN": "Politically exposed persons",
                    "nb_NO": "PEP",
                    "sv_SE": "Politiskt utsatta personer"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "This is a new help text",
                    "nb_NO": "<p>Kunden vil få beskjed om å fylle inn informasjon om PEP</p>",
                    "sv_SE": "<p>Kunden kommer att bli ombedd att fylla i nödvändig information om personer som har kryssats för PEP. Detta steg visas bara om nyckelroller har avmarkerats som PEP.</p>"
                  }
                }
              ],
              "editor": [
                {
                  "key": "h1025",
                  "icon": "pep",
                  "header": {
                    "en_EN": "Politically exposed persons",
                    "nb_NO": "Politisk Eksponerte Personer",
                    "sv_SE": "Politiskt utsatta personer"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "<p>Fill in required information about politically exposed persons.</p>",
                    "nb_NO": "<p>Fyll inn nødvendig informasjon om politisk eksponerte personer.</p>",
                    "sv_SE": "<p>Fyll i nödvändig information om politiskt utsatta personer.</p>"
                  }
                }
              ],
              "signer": [
                {
                  "key": "h1034",
                  "icon": "help-o",
                  "header": {
                    "en_EN": "New help text",
                    "nb_NO": "Ny hjelpetekst"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "This is a new help text",
                    "nb_NO": "Dette er en ny hjelpetekst"
                  }
                }
              ],
              "done": [
                {
                  "key": "h1286",
                  "icon": "help-o",
                  "header": {
                    "en_EN": "New help text",
                    "nb_NO": "Ny hjelpetekst"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "This is a new help text",
                    "nb_NO": "Dette er en ny hjelpetekst"
                  }
                }
              ]
            },
            "cards": [
              {
                "key": "p1297",
                "type": "pepinfo",
                "header": {
                  "en_EN": "PEP Info",
                  "nb_NO": "PEP Info",
                  "sv_SE": "PEP Info"
                },
                "pdf": false,
                "roles": {
                  "owner": true,
                  "editor": true,
                  "signer": true,
                  "done": true
                }
              },
              {
                "key": "c1086",
                "type": "card",
                "header": {
                  "en_EN": "Are you or a close relative defined as PEP?",
                  "nb_NO": "Er du eller et nært familiemedlem definert som PEP?",
                  "sv_SE": " Är du eller en nära släkting definierad som PEP?"
                },
                "inputs": [
                  {
                    "key": "i1087",
                    "type": "check",
                    "width": "12",
                    "desc": {
                      "en_EN": "",
                      "nb_NO": ""
                    },
                    "required": false,
                    "inline": false,
                    "radio": true,
                    "fillCard": true,
                    "options": {
                      "x1088": {
                        "type": "opt",
                        "key": "x1088",
                        "default": false,
                        "locale": {
                          "en_EN": "Yes",
                          "nb_NO": "Ja",
                          "sv_SE": "Ja"
                        }
                      },
                      "x1089": {
                        "type": "opt",
                        "key": "x1089",
                        "default": false,
                        "locale": {
                          "en_EN": "No",
                          "nb_NO": "Nei",
                          "sv_SE": "Nej"
                        }
                      }
                    },
                    "noPadding": true,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "model": {
                      "x1088": false,
                      "x1089": false
                    },
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    }
                  }
                ],
                "noPadding": true,
                "pdf": true,
                "roles": {
                  "owner": true,
                  "editor": true,
                  "signer": true
                }
              },
              {
                "key": "c1176",
                "type": "list",
                "header": {
                  "en_EN": "PEP",
                  "nb_NO": "PEP",
                  "sv_SE": "PEP"
                },
                "addtext": {
                  "en_EN": "Add PEP",
                  "nb_NO": "Legg til PEP",
                  "sv_SE": "Lägg till PEP"
                },
                "inputs": [
                  {
                    "key": "i1177",
                    "type": "text",
                    "inputType": "text",
                    "width": "4",
                    "desc": {
                      "en_EN": "First Name",
                      "nb_NO": "Fornavn",
                      "sv_SE": "Förnamn"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "firstName",
                    "kycKey": "",
                    "modelKey": ""
                  },
                  {
                    "key": "i1178",
                    "type": "text",
                    "inputType": "text",
                    "width": "4",
                    "desc": {
                      "en_EN": "Last Name",
                      "nb_NO": "Etternavn",
                      "sv_SE": "Efternamn"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "lastName",
                    "kycKey": "",
                    "modelKey": ""
                  },
                  {
                    "key": "i1179",
                    "type": "date",
                    "width": "4",
                    "desc": {
                      "en_EN": "Date of Birth",
                      "nb_NO": "Fødselsdato",
                      "sv_SE": "Födelsedatum"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "dateOfBirth",
                    "kycKey": "",
                    "modelKey": ""
                  },
                  {
                    "key": "i1183",
                    "type": "text",
                    "inputType": "text",
                    "width": "4",
                    "desc": {
                      "en_EN": "Address",
                      "nb_NO": "Adresse",
                      "sv_SE": "Adress"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "street",
                    "kycKey": ""
                  },
                  {
                    "key": "i1184",
                    "type": "text",
                    "inputType": "number",
                    "width": "2",
                    "desc": {
                      "en_EN": "Zip code",
                      "nb_NO": "Postnummer",
                      "sv_SE": "Postnummer"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "zip",
                    "kycKey": ""
                  },
                  {
                    "key": "i1185",
                    "type": "text",
                    "inputType": "text",
                    "width": "3",
                    "desc": {
                      "en_EN": "City",
                      "nb_NO": "Poststed",
                      "sv_SE": "Ort"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "city",
                    "kycKey": ""
                  },
                  {
                    "key": "c1277",
                    "type": "country",
                    "width": "3",
                    "desc": {
                      "en_EN": "Country",
                      "nb_NO": "Land",
                      "sv_SE": "Land"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "country",
                    "kycKey": ""
                  },
                  {
                    "key": "i1189",
                    "type": "text",
                    "inputType": "text",
                    "width": "12",
                    "desc": {
                      "en_EN": "Position / Title / Position (Why is this person defined as PEP?)",
                      "nb_NO": "Stilling / tittlel / verv (Hvorfor er denne personen definert som PEP?)",
                      "sv_SE": "Position / titel / position (Varför definieras denna person som PEP?)"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "title",
                    "kycKey": "",
                    "modelKey": ""
                  },
                  {
                    "key": "i1191",
                    "type": "text",
                    "inputType": "email",
                    "width": "6",
                    "desc": {
                      "en_EN": "Email address",
                      "nb_NO": "Epost",
                      "sv_SE": "Epost"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "email",
                    "kycKey": ""
                  },
                  {
                    "key": "i1239",
                    "type": "text",
                    "inputType": "text",
                    "width": "6",
                    "desc": {
                      "en_EN": "Phone number",
                      "nb_NO": "Telefon",
                      "sv_SE": "Telefon"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "phoneNumber",
                    "kycKey": ""
                  }
                ],
                "noPadding": false,
                "pdf": true,
                "roles": {
                  "owner": true,
                  "editor": true,
                  "signer": true
                },
                "kycKey": "peps",
                "model": [
                  {
                    "firstName": "",
                    "lastName": "",
                    "dateOfBirth": "",
                    "street": "",
                    "zip": "",
                    "city": "",
                    "country": "",
                    "title": "",
                    "email": "",
                    "phoneNumber": ""
                  }
                ],
                "val": [
                  {
                    "firstName": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "lastName": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "dateOfBirth": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "street": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "zip": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "city": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "country": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "title": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "email": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "phoneNumber": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    }
                  }
                ],
                "isPerson": true,
                "logic": {
                  "visible": {
                    "or": true,
                    "keys": [
                      {
                        "type": "check",
                        "pkey": "i1087",
                        "key": "x1088",
                        "rev": false
                      }
                    ]
                  }
                }
              }
            ],
            "roles": {
              "owner": true,
              "editor": true,
              "signer": true,
              "done": true
            }
          },
          {
            "key": "p1017",
            "type": "page",
            "mode": "form-page",
            "title": {
              "en_EN": "Recipient",
              "nb_NO": "Mottaker",
              "sv_SE": "Mottagare"
            },
            "showStepper": true,
            "hasInfo": false,
            "info": {
              "owner": [
                {
                  "key": "h1016",
                  "icon": "edit",
                  "header": {
                    "en_EN": "The recipient of the form",
                    "nb_NO": "Mottaker",
                    "sv_SE": "Mottagare"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "<p>Select the customer contact person that will fill out the form.</p>",
                    "nb_NO": "<p>Velg kontaktpersonen for oppdraget som skal fylle ut skjemaet.</p>",
                    "sv_SE": "<p>Välj kontaktperson för den som ska fylla i formuläret.</p>"
                  }
                }
              ],
              "editor": [
                {
                  "key": "h1027",
                  "icon": "help-o",
                  "header": {
                    "en_EN": "New help text",
                    "nb_NO": "Ny hjelpetekst"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "This is a new help text",
                    "nb_NO": "Dette er en ny hjelpetekst"
                  }
                }
              ],
              "signer": [
                {
                  "key": "h1036",
                  "icon": "help-o",
                  "header": {
                    "en_EN": "New help text",
                    "nb_NO": "Ny hjelpetekst"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "This is a new help text",
                    "nb_NO": "Dette er en ny hjelpetekst"
                  }
                }
              ],
              "done": [
                {
                  "key": "h1287",
                  "icon": "help-o",
                  "header": {
                    "en_EN": "New help text",
                    "nb_NO": "Ny hjelpetekst"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "This is a new help text",
                    "nb_NO": "Dette er en ny hjelpetekst"
                  }
                }
              ]
            },
            "cards": [
              {
                "key": "c1038",
                "type": "card",
                "header": {
                  "en_EN": "Recipient",
                  "nb_NO": "Mottaker",
                  "sv_SE": "Mottagare"
                },
                "inputs": [
                  {
                    "key": "i1114",
                    "type": "text",
                    "inputType": "text",
                    "width": "6",
                    "desc": {
                      "en_EN": "First Name",
                      "nb_NO": "Fornavn",
                      "sv_SE": "Förnamn"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "kycKey": "",
                    "flow": {
                      "field": {
                        "type": "firstName",
                        "rkey": "editor"
                      }
                    },
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "ownerReq": true
                  },
                  {
                    "key": "i1115",
                    "type": "text",
                    "inputType": "text",
                    "width": "6",
                    "desc": {
                      "en_EN": "Last Name",
                      "nb_NO": "Etternavn",
                      "sv_SE": "Efternamn"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "kycKey": "",
                    "flow": {
                      "field": {
                        "type": "lastName",
                        "rkey": "editor"
                      }
                    },
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "ownerReq": true
                  },
                  {
                    "key": "i1116",
                    "type": "text",
                    "inputType": "email",
                    "width": "12",
                    "desc": {
                      "en_EN": "Email address",
                      "nb_NO": "Epostadresse",
                      "sv_SE": "Epost"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "kycKey": "",
                    "flow": {
                      "field": {
                        "type": "email",
                        "rkey": "editor"
                      }
                    },
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "ownerReq": true
                  }
                ],
                "noPadding": false,
                "pdf": false,
                "pdfIndex": 0,
                "pdfLocked": false,
                "roles": {
                  "owner": true,
                  "editor": true,
                  "signer": true
                },
                "ppop": true
              }
            ],
            "roles": {
              "owner": true,
              "editor": false,
              "signer": true,
              "done": true
            }
          },
          {
            "key": "p1019",
            "type": "page",
            "mode": "form-page",
            "title": {
              "en_EN": "Signing",
              "nb_NO": "Signering",
              "sv_SE": "Undertecknare"
            },
            "showStepper": true,
            "hasInfo": false,
            "info": {
              "owner": [
                {
                  "key": "h1018",
                  "icon": "help-o",
                  "header": {
                    "en_EN": "New help text",
                    "nb_NO": "Ny hjelpetekst"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "This is a new help text",
                    "nb_NO": "Dette er en ny hjelpetekst"
                  }
                }
              ],
              "editor": [
                {
                  "key": "h1028",
                  "icon": "sign",
                  "header": {
                    "en_EN": "Signatories",
                    "nb_NO": "Signering",
                    "sv_SE": "Undertecknare"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "<p>Select the person or persons that will sign the declaration on behalf of the company.</p>",
                    "nb_NO": "<p>Velg hvem som skal signere egenerklæringen på vegne av foretaket.</p>",
                    "sv_SE": "<p>Välj vem som ska underteckna självdeklarationen på företagets vägnar.</p>"
                  }
                },
                {
                  "key": "h1244",
                  "icon": "people",
                  "header": {
                    "en_EN": "Who can sign?",
                    "nb_NO": "Hvem kan signere?",
                    "sv_SE": "Vem kan signera?"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "<p>There are no special requirements as to who can sign, as long as the person has a power of attorney from the company for this.</p>",
                    "nb_NO": "<p>Det er ingen spesielle krav til hvem som kan signere, så lenge personen har fullmakt fra foretaket til dette.</p>",
                    "sv_SE": "<p>Det finns inga speciella krav på vem som kan skriva under, så länge personen har en fullmakt från företaget för detta.</p>"
                  }
                }
              ],
              "signer": [
                {
                  "key": "h1037",
                  "icon": "help-o",
                  "header": {
                    "en_EN": "New help text",
                    "nb_NO": "Ny hjelpetekst"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "This is a new help text",
                    "nb_NO": "Dette er en ny hjelpetekst"
                  }
                }
              ],
              "done": [
                {
                  "key": "h1288",
                  "icon": "help-o",
                  "header": {
                    "en_EN": "New help text",
                    "nb_NO": "Ny hjelpetekst"
                  },
                  "text": {
                    "formatted": true,
                    "en_EN": "This is a new help text",
                    "nb_NO": "Dette er en ny hjelpetekst"
                  }
                }
              ]
            },
            "cards": [
              {
                "key": "c1118",
                "type": "card",
                "header": {
                  "en_EN": "",
                  "nb_NO": ""
                },
                "inputs": [
                  {
                    "key": "i1119",
                    "type": "check",
                    "width": "12",
                    "desc": {
                      "en_EN": "",
                      "nb_NO": ""
                    },
                    "required": true,
                    "inline": false,
                    "radio": true,
                    "fillCard": true,
                    "options": {
                      "x1120": {
                        "type": "opt",
                        "key": "x1120",
                        "default": false,
                        "locale": {
                          "en_EN": "Go straight to signing",
                          "nb_NO": "Gå rett til signering",
                          "sv_SE": "Jag har befogenhet att underteckna ensam och vill göra det nu"
                        }
                      },
                      "x1121": {
                        "type": "opt",
                        "key": "x1121",
                        "default": false,
                        "locale": {
                          "en_EN": "I want to forward the document for someone else to sign",
                          "nb_NO": "Jeg vil sende dokumentet videre til noen andre for signering",
                          "sv_SE": "Jag vill välja en eller flera personer som får en inbjudan att skriva under via e-post"
                        }
                      }
                    },
                    "noPadding": true,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "model": {
                      "x1120": false,
                      "x1121": false
                    },
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "logic": {
                      "link": [
                        {
                          "type": "sign",
                          "link": "direct",
                          "key": "x1120"
                        }
                      ]
                    }
                  }
                ],
                "noPadding": true,
                "pdf": false,
                "roles": {
                  "owner": true,
                  "editor": true,
                  "signer": true
                }
              },
              {
                "key": "c1117",
                "type": "card",
                "header": {
                  "en_EN": "",
                  "nb_NO": ""
                },
                "inputs": [
                  {
                    "key": "i1143",
                    "type": "helpt",
                    "width": "12",
                    "desc": {
                      "formatted": true,
                      "en_EN": "",
                      "nb_NO": "<p>Velg hvordan du ønsker å signere egenerklæringen</p>"
                    },
                    "required": false,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "kycKey": "",
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    }
                  },
                  {
                    "key": "i1123",
                    "type": "drop",
                    "width": "12",
                    "desc": {
                      "en_EN": "Signing method",
                      "nb_NO": "Signeringsmetode",
                      "sv_SE": "Signeringsmetod"
                    },
                    "required": true,
                    "external": true,
                    "items": [
                      {
                        "type": "itm",
                        "key": "x1124",
                        "value": "x1124",
                        "en_EN": "Dropdown item 1",
                        "nb_NO": "Nedtrekksvalg 1"
                      },
                      {
                        "type": "itm",
                        "key": "x1125",
                        "value": "x1125",
                        "en_EN": "Dropdown item 2",
                        "nb_NO": "Nedtrekksvalg 2"
                      }
                    ],
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "kycKey": "",
                    "model": "",
                    "val": {
                      "valid": false,
                      "done": false,
                      "message": ""
                    },
                    "logic": {
                      "rlist": {
                        "key": "signMethods",
                        "localized": true,
                        "display": "locale",
                        "list": "locale",
                        "output": "value"
                      },
                      "link": [
                        {
                          "type": "sign",
                          "link": "method",
                          "key": ""
                        }
                      ]
                    }
                  }
                ],
                "noPadding": false,
                "pdf": false,
                "roles": {
                  "owner": true,
                  "editor": true,
                  "signer": true
                },
                "logic": {
                  "visible": {
                    "or": true,
                    "keys": [
                      {
                        "type": "check",
                        "pkey": "i1119",
                        "key": "x1120",
                        "rev": false
                      }
                    ]
                  }
                }
              },
              {
                "key": "c1130",
                "type": "list",
                "header": {
                  "en_EN": "Signatory",
                  "nb_NO": "Signatar",
                  "sv_SE": "Mottagare"
                },
                "addtext": {
                  "en_EN": "Add signatory",
                  "nb_NO": "Legg til signatar",
                  "sv_SE": " LÄGG TILL MOTTAGARE "
                },
                "inputs": [
                  {
                    "key": "i1131",
                    "type": "text",
                    "inputType": "text",
                    "width": "6",
                    "desc": {
                      "en_EN": "First Name",
                      "nb_NO": "Fornavn",
                      "sv_SE": "Förnamn"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "m1134",
                    "kycKey": "",
                    "flow": {
                      "field": {
                        "type": "firstName",
                        "rkey": "signer"
                      }
                    }
                  },
                  {
                    "key": "i1132",
                    "type": "text",
                    "inputType": "text",
                    "width": "6",
                    "desc": {
                      "en_EN": "Last Name",
                      "nb_NO": "Etternavn",
                      "sv_SE": "Efternamn"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "m1135",
                    "kycKey": "",
                    "flow": {
                      "field": {
                        "type": "lastName",
                        "rkey": "signer"
                      }
                    }
                  },
                  {
                    "key": "i1133",
                    "type": "text",
                    "inputType": "email",
                    "width": "12",
                    "desc": {
                      "en_EN": "Email address",
                      "nb_NO": "Epostadresse",
                      "sv_SE": "Epost"
                    },
                    "required": true,
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "m1136",
                    "kycKey": "",
                    "flow": {
                      "field": {
                        "type": "email",
                        "rkey": "signer"
                      }
                    }
                  },
                  {
                    "key": "i1245",
                    "type": "drop",
                    "width": "6",
                    "desc": {
                      "en_EN": "Signing method",
                      "nb_NO": "Signeringsmetode"
                    },
                    "required": true,
                    "external": true,
                    "items": [
                      {
                        "type": "itm",
                        "key": "x1246",
                        "value": "x1246",
                        "en_EN": "Dropdown item 1",
                        "nb_NO": "Nedtrekksvalg 1"
                      },
                      {
                        "type": "itm",
                        "key": "x1247",
                        "value": "x1247",
                        "en_EN": "Dropdown item 2",
                        "nb_NO": "Nedtrekksvalg 2"
                      }
                    ],
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "m1248",
                    "kycKey": "",
                    "flow": {
                      "field": {
                        "type": "method",
                        "rkey": "signer"
                      }
                    },
                    "logic": {
                      "rlist": {
                        "key": "signMethods",
                        "localized": true,
                        "display": "locale",
                        "list": "locale",
                        "output": "value"
                      }
                    }
                  },
                  {
                    "key": "i1251",
                    "type": "drop",
                    "width": "6",
                    "desc": {
                      "en_EN": "Language",
                      "nb_NO": "Språk"
                    },
                    "required": true,
                    "external": false,
                    "items": [
                      {
                        "type": "itm",
                        "key": "x1252",
                        "value": "en_EN",
                        "en_EN": "English",
                        "nb_NO": "Engelsk"
                      },
                      {
                        "type": "itm",
                        "key": "x1253",
                        "value": "nb_NO",
                        "en_EN": "Norwegian",
                        "nb_NO": "Norsk"
                      }
                    ],
                    "noPadding": false,
                    "pdf": true,
                    "pdfIndex": 0,
                    "pdfLocked": false,
                    "isList": true,
                    "listModel": "m1254",
                    "kycKey": "",
                    "flow": {
                      "field": {
                        "type": "lang",
                        "rkey": "signer"
                      }
                    }
                  }
                ],
                "noPadding": false,
                "pdf": false,
                "roles": {
                  "owner": true,
                  "editor": true,
                  "signer": true
                },
                "kycKey": "",
                "logic": {
                  "visible": {
                    "or": true,
                    "keys": [
                      {
                        "type": "check",
                        "pkey": "i1119",
                        "key": "x1121",
                        "rev": false
                      }
                    ]
                  },
                  "link": [
                    {
                      "type": "sign",
                      "link": "slist",
                      "key": ""
                    }
                  ]
                },
                "model": [],
                "val": []
              },
              {
                "key": "l1129",
                "type": "methods",
                "mode": "sign",
                "pdf": false,
                "roles": {
                  "owner": true,
                  "editor": true,
                  "signer": true
                }
              }
            ],
            "roles": {
              "owner": false,
              "editor": true,
              "signer": true,
              "done": true
            }
          }
        ],
        "resources": {},
        "attachments": [],
        "logic": []
      };
  }

};
export default kycp01;