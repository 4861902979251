<template>
<div>

    <PropertyItem padded :header="'PDF properties'" :icon="'pdf'" :initialCollapse="initialCollapse">

        <DesignerGroup c6>
            <DesignerCheckbox v-model="card.pdf" :text="'Show in PDF'" />
        </DesignerGroup>

        <DesignerGroup c6>
            <DesignerCheckbox v-model="card.pdfOnly" :text="'PDF Only'" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';

export default {
    name: 'ModelProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
    },
    props: {
        card: { type: Object, default: null },
        initialCollapse: { type: Boolean, default: false },
    },
    data() {
        return {
            inputTypeOptions: [
                { value: 'text', display: 'Free text' },
                { value: 'number', display: 'Number' },
                { value: 'email', display: 'Email address' },
                { value: 'percentage', display: 'Percentage' },
                { value: 'bankno', display: 'Bank account (NO)' },
            ],
        }
    },
}
</script>