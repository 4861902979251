// -------------------------------
// Created by Magnus on 28.04.2020
// -------------------------------
// Updated by Magnus on 05.01.2021
// -- Update to getPersonList(), AML will sometimes return a person with name "Fratrådt" and no other fields, this needs to be checked for
// Updated by Magnus on 14.01.2021
// -- Update to getRRHList(), turns out some RRHs don't have the adress object

import axios from 'axios'
import { oidc } from '@verified/libvf'
import remote from '@verified/libvf/src/helpers/remote'

const aml = {

    // AML PEP/Sanction Search on person
    // Do AML search and return the response
    async doAmlPersonSearch(root, fname, lname, dob) {
        let expands = 'aml,'
            /* + 'keyInformation.birthplace,' */
            /* + 'keyInformation.citizenship,' */
            + 'address.postal,'
            /* + 'address.national,' */
            /* + 'address.historic,' */
            + 'address.numberOfResidents,'
            + 'roles,'
            + 'links.reports.aml';
        let basePath = '/bankid-oidc';
        if (root.config.aml.person.ofac) {
            console.log('using OFAC');
            basePath = '/bankid-oidc/ofac';
        }
        let response = await remote.call({
            path: basePath + `/person/noauth?expands=${ expands }&firstName=${ fname }&lastName=${ lname }&dateOfBirth=${ (dob || '').replace(/-/g, '') }`,
            method: 'GET'
        });
        return response.data;
    },

    // AML PEP/Sanction Search on person
    // Do AML search and return the response
    async doAmlPersonSearchSSN(root, fname, lname, ssn) {
        let expands = 'aml,'
            /* + 'keyInformation.birthplace,' */
            /* + 'keyInformation.citizenship,' */
            + 'address.postal,'
            /* + 'address.national,' */
            /* + 'address.historic,' */
            + 'address.numberOfResidents,'
            + 'roles,'
            + 'links.reports.aml';
        if (root.config.aml.person.dsfEnabled) {
            expands = 'aml,'
            + 'keyInformation.birthplace,'
            + 'keyInformation.citizenship,'
            + 'address.postal,'
            + 'address.national,'
            + 'address.historic,'
            + 'address.numberOfResidents,'
            + 'roles,'
            + 'links.reports.aml';
        }
        let response = await remote.call({
            path: `/bankid-oidc/person/noauth?expands=${ expands }&firstName=${ fname }&lastName=${ lname }&ssn=${ ssn }&countryOfSsn=NO`,
            method: 'GET'
        });
        return response.data;
    },

    // Person result
    // Get results from person AML as a result-object (pass in the entire aml-response)
    getPersonResults(data) {
        let result = { searchDate: new Date() };
        //console.log('aml', data);

        if (data.personData.aml.pepResults.length > 0) {
            result.pep = true;
            result.message = 'PEP - ' + data.personData.aml.pepResults[0].title;
            result.matchIndicator = data.personData.aml.pepResults[0].matchIndicator;
            result.matchDescription = data.personData.aml.pepResults[0].matchIndicatorDescription;
        }else {
            result.pep = false;
        }
        if (data.personData.aml.sanctionResults.length > 0) {
            result.sanction = true;
            result.message = 'SANCTION - ' + data.personData.aml.sanctionResults[0].title;
            result.matchIndicator = data.personData.aml.sanctionResults[0].matchIndicator;
            result.matchDescription = data.personData.aml.sanctionResults[0].matchIndicatorDescription;
        }else {
            result.sanction = false;
        }
        if (data.personData.aml.pepResults.length == 0 && data.personData.aml.sanctionResults.length == 0) {
            result.message = data.personData.aml.statusMessage;
        }

        return result;
    },

    // AML search
    // Do AML search and return the response
    async doAmlCompanySearch(root, orgnr) {
        //console.log(orgnr)
        if (orgnr.includes('900800700')) {
            await new Promise(r => setTimeout(r, 500));
            let url = '../../nhas/company.json';
            const response = await axios.get(url);
            console.log(response.data);
            return response.data.company;
        }else {
            if (Number(orgnr)) {
                const nationality = 'NO';
                const expands = [
                    "ownership.beneficial",
                    "ownership.shareholders",
                    /* "ownership.indicators", */
                    "authorizations",
                    "authorities",
                    "sanction",
                    "officialRoles",
                    "financials.key_figures",
                    "financials.auditor",
                    "financials.accounting.firm",
                    "financials.accounting",
                    /* "financials.credit" */,
                    /* "financials.credit.historic" */,
                    "links.reports.aml",
                    "links.reports.certificate_of_incorporation",
                    /* "links.reports.credit" */
                ];
                let basePath = '/bankid-oidc';
                if (root.config.aml.company.ofac) {
                    console.log('using OFAC');
                    basePath = '/bankid-oidc/ofac';
                }
                const path = basePath + `/organization/noauth?organizationNumber=${orgnr.toString().replace(/\D/g,'')}&nationality=${nationality}&expands=${expands.toString()}`;
                let response = await remote.call({
                    path: path,
                    method: 'GET'
                });
                console.log("aml search", JSON.parse(JSON.stringify(response.data)));
                return response.data;
            }
        }
    },

    // AML search
    // Do AML search and return the response
    async doAmlCompanySearch2(root, orgnr) {
        if (Number(orgnr)) {
            const nationality = 'NO';
            const expands = [
                "ownership.beneficial",
                "ownership.shareholders",
                "ownership.indicators",
                "authorizations",
                "authorities",
                "sanction",
                "officialRoles",
                "financials.key_figures",
                "financials.auditor",
                "financials.accounting.firm",
                "financials.accounting",
                /* "financials.credit" */,
                /* "financials.credit.historic" */,
                "links.reports.aml",
                "links.reports.certificate_of_incorporation",
                /* "links.reports.credit" */
            ];
            let basePath = '/bankid-oidc';
            if (root.config.aml.company.ofac) {
                console.log('using OFAC');
                basePath = '/bankid-oidc/ofac';
            }
            const path = basePath + `/organization/noauth?organizationNumber=${orgnr.toString().replace(/\D/g,'')}&nationality=${nationality}&expands=${expands.toString()}`;
            let response = await remote.call({
                path: path,
                method: 'GET'
            });
            //console.log("aml search", response);
            if (root.isDEV) {
                return this.replaceRolesInDev(response.data);
            }else {
                return response.data;
            }
        }
    },

    // AML search
    // Do AML search and return the response
    async doAmlCompanySearch3(root, orgnr) {
        if (Number(orgnr)) {
            const nationality = 'NO';
            const expands = [
                "ownership.beneficial",
                "ownership.shareholders",
                "ownership.indicators",
                "ownership.subsidiaries",
                "keyInformation.lei",
                "authorizations",
                "authorities",
                "sanction",
                "roles",
                "financials.key_figures",
                "financials.auditor",
                "financials.accounting.firm",
                "financials.accounting",
                /* "financials.credit" */,
                /* "financials.credit.historic" */,
                "links.reports.aml",
                "links.reports.certificate_of_incorporation",
                /* "links.reports.credit" */
            ];
            let basePath = '/bankid-oidc';
            if (root.config.aml.company.ofac) {
                console.log('using OFAC');
                basePath = '/bankid-oidc/ofac';
            }
            const path = basePath + `/organization/noauth?organizationNumber=${orgnr.toString().replace(/\D/g,'')}&nationality=${nationality}&expands=${expands.toString()}`;
            let response = await remote.call({
                path: path,
                method: 'GET'
            });
            //console.log("aml search", response);
            if (root.isDEV) {
                return this.replaceRolesInDev(response.data);
            }else {
                return response.data;
            }
        }
    },

    replaceRolesInDev(data) {
        console.log('data', data);
        /* if (data.keyInformation.organizationNumber == '613838465') {

            data.officialRoles.ceo.name = 'TOPSTAD TOMAS';
            data.officialRoles.ceo.firstName = 'TOMAS';
            data.officialRoles.ceo.lastName = 'TOPSTAD';
            data.officialRoles.ceo.dateOfBirth = '1985-01-01';

            data.officialRoles.chairman.name = 'KLAVEN KARI';
            data.officialRoles.chairman.firstName = 'KARI';
            data.officialRoles.chairman.lastName = 'KLAVEN';
            data.officialRoles.chairman.dateOfBirth = '2007-01-01';

            data.officialRoles.boardMembers[0].name = 'HOLM MORTEN';
            data.officialRoles.boardMembers[0].firstName = 'MORTEN';
            data.officialRoles.boardMembers[0].lastName = 'HOLM';
            data.officialRoles.boardMembers[0].dateOfBirth = '1963-02-01';

            data.officialRoles.boardMembers[1].name = 'HOLM MIA';
            data.officialRoles.boardMembers[1].firstName = 'MIA';
            data.officialRoles.boardMembers[1].lastName = 'HOLM';
            data.officialRoles.boardMembers[1].dateOfBirth = '1984-05-28';

            try {
                data.ownership.beneficial[0].name = 'TOPSTAD, TOMAS';
                data.ownership.beneficial[0].firstName = 'TOMAS';
                data.ownership.beneficial[0].lastName = 'TOPSTAD';
                data.ownership.beneficial[0].dateOfBirth = '1985-01-01';
            }catch (e) {}
            

            data.authorities.powerOfProcuration.options[0].required[0].name = 'TOPSTAD, TOMAS';
            data.authorities.powerOfProcuration.options[0].required[0].dateOfBirth = '1985-01-01';

            data.authorities.signatureRights.options[0].required[0].name = 'KLAVEN, KARI';
            data.authorities.signatureRights.options[0].required[0].dateOfBirth = '2007-01-01';

            data.authorities.signatureRights.options[1].required[0].name = 'TOPSTAD, TOMAS';
            data.authorities.signatureRights.options[1].required[0].dateOfBirth = '1985-01-01';

            data.authorities.signatureRights.options[2].required[0].name = 'KLAVEN, KARI';
            data.authorities.signatureRights.options[2].required[0].dateOfBirth = '2007-01-01';

            try {
                data.authorities.signatureRights.options[2].required[1].name = 'HOLM, MIA';
                data.authorities.signatureRights.options[2].required[1].dateOfBirth = '1984-05-28';
            }catch (e) {}

            try {
                data.authorities.signatureRights.options[2].required[2].name = 'HOLM, MORTEN';
                data.authorities.signatureRights.options[2].required[2].dateOfBirth = '1963-02-01';
            }catch (e) {}

        }else if (data.keyInformation.organizationNumber == '900433824') {

            data.officialRoles.ceo.name = 'HANSEN MARTIN';
            data.officialRoles.ceo.firstName = 'MARTIN';
            data.officialRoles.ceo.lastName = 'HANSEN';
            data.officialRoles.ceo.dateOfBirth = '1980-10-01';

            data.ownership.beneficial[0].name = 'HANSEN, MARTIN';
            data.ownership.beneficial[0].firstName = 'MARTIN';
            data.ownership.beneficial[0].lastName = 'HANSEN';
            data.ownership.beneficial[0].dateOfBirth = '1980-10-01';

        } */
        return data;
    },

    // Key roles
    // Get key roles as an array (pass in the entire aml-response)
    getKeyRoleList(data) {
        //console.log('aml data', data);
        let roles = [];
        let o = data.officialRoles;
        
        if (o.ceo && o.ceo.name) {
            const address = this.getAddressFromRRH(data, o.ceo.firstName, o.ceo.lastName, o.ceo.dateOfBirth);
            //console.log('ceo', o.ceo);
            roles.push({
                roleId: 'ceo',
                role: this.roleName('ceo'),
                firstName: o.ceo.firstName,
                lastName: o.ceo.lastName,
                dateOfBirth: o.ceo.dateOfBirth,
                address: address.address,
                zip: address.zip,
                city: address.city,
                country: address.country,
            });
        }
        
        if (o.chairman && o.chairman.name) {
            const address = this.getAddressFromRRH(data, o.chairman.firstName, o.chairman.lastName, o.chairman.dateOfBirth);
            //console.log('adr', address);
            roles.push({
                roleId: 'chair',
                role: this.roleName('chair'),
                firstName: o.chairman.firstName,
                lastName: o.chairman.lastName,
                dateOfBirth: o.chairman.dateOfBirth,
                address: address.address,
                zip: address.zip,
                city: address.city,
                country: address.country,
            });
        }

        if (o.proprietor && o.proprietor.name) {
            const address = this.getAddressFromRRH(data, o.proprietor.firstName, o.proprietor.lastName, o.proprietor.dateOfBirth);
            roles.push({
                roleId: 'proprietor',
                role: this.roleName('proprietor'),
                firstName: o.proprietor.firstName,
                lastName: o.proprietor.lastName,
                dateOfBirth: o.proprietor.dateOfBirth,
                address: address.address,
                zip: address.zip,
                city: address.city,
                country: address.country,
            });
        }

        return roles;
    },

    // Reelle Rettighetshavere
    // Get RRH's as an array (pass in the entire aml-response)
    getRRHList(data) {
        let rrhs = [];
        if (data.ownership) {
            let o = data.ownership;
            if (o.beneficial && o.beneficial.length) {
                o.beneficial.forEach((el) => {
                    rrhs.push({
                        name: el.name,
                        firstName: el.firstName,
                        lastName: el.lastName,
                        dateOfBirth: el.dateOfBirth,
                        percent: el.percentageControl,
                        address: el.address ? el.address.street : '',
                        zip: el.address ? el.address.postalCode : '',
                        city: el.address ? el.address.city : '',
                        country: el.address ? el.address.country : '',
                    });
                });
            }
        }
        return rrhs;
    },

    getAddressFromRRH(data, firstName, lastName, dateOfBirth) {
        if (data.ownership) {
            let o = data.ownership;
            if (o.beneficial && o.beneficial.length) {
                for (let i in o.beneficial) {
                    if (o.beneficial[i].firstName == firstName && o.beneficial[i].lastName == lastName && o.beneficial[i].dateOfBirth == dateOfBirth) {
                        return {
                            address: o.beneficial[i].address.street,
                            zip: o.beneficial[i].address.postalCode,
                            city: o.beneficial[i].address.city,
                            country: o.beneficial[i].address.country
                        }
                    }
                }
            }
        }
        return {
            address: '',
            zip: '',
            city: '',
            country: ''
        };
    },

    // Eierstruktur
    // Get entities as an array (pass in the entire aml-response)
    getEntityList(data) {
        let entities = [];
        if (data.ownership) {
            let o = data.ownership;
            if (o.shareholders && o.shareholders.length) {
                o.shareholders.forEach((el) => {
                    entities.push(el);
                });
            }
        }
        return entities;
    },

    // Person-list
    // Get a list of all persons with roles in the company (pass in the entire aml-response)
    getPersonList(data) {
        let output = [];
        if (data.authorities) {
            let a = data.authorities;
            // Add people with signature rights
            if (a.signatureRights && a.signatureRights.options && a.signatureRights.options[0] && a.signatureRights.options[0].required) {
                a.signatureRights.options[0].required.forEach((el) => {
                    this.mergeWithRoleList(el, 'screening_role_sign_right', output);
                });
            }
            // Add people with procura
            if (a.powerOfProcuration && a.powerOfProcuration.options && a.powerOfProcuration.options[0] && a.powerOfProcuration.options[0].required) {
                a.powerOfProcuration.options[0].required.forEach((el) => {
                    this.mergeWithRoleList(el, 'screening_role_procura', output);
                });
            }
        }
        if (data.officialRoles) {
            let o = data.officialRoles;
            // Add CEO without duplicates
            if (o.ceo && o.ceo.name) {
                this.mergeWithRoleList(o.ceo, 'ceo', output);
            }
            // Add chairman without duplicates
            if (o.chairman && o.chairman.name) {
                this.mergeWithRoleList(o.chairman, 'chair', output);
            }
            // Add deputy chairman without duplicates
            if (o.deputyChairman && o.deputyChairman.name) {
                this.mergeWithRoleList(o.deputyChairman, 'screening_role_deputy_chairman', output);
            }
            // Add owner without duplicates
            if (o.proprietor && o.proprietor.name) {
                this.mergeWithRoleList(o.proprietor, 'proprietor', output);
            }
            // Add board members without duplicates
            if (o.boardMembers && o.boardMembers.length) {
                o.boardMembers.forEach((el) => {
                    this.mergeWithRoleList(el, 'screening_role_board_member', output);
                });
            }
            // Add deputy board members without duplicates
            if (o.deputyBoardMembers && o.deputyBoardMembers.length) {
                o.deputyBoardMembers.forEach((el) => {
                    this.mergeWithRoleList(el, 'screening_role_deputy_board_member', output);
                });
            }
        }
        if (data.ownership) {
            let o = data.ownership;
            // Add RRHs without duplicates
            if (o.beneficial && o.beneficial.length) {
                o.beneficial.forEach((el) => {
                    this.mergeWithRoleList(el, 'screening_role_rrh', output);
                });
            }
            // Add shareholders without duplicates
            if (o.shareholders && o.shareholders.length) {
                o.shareholders.forEach((el) => {
                    if (el.type == 'PERSON') this.mergeWithRoleList(el, 'screening_role_shareholder', output);
                });
            }
        }
        return output;
    },

    // Person-list 2 (remove old one when this is working)
    // Get a list of all persons with roles in the company (pass in the entire aml-response)
    getPersonList2(data) {
        let output = [];
        if (data.authorities) {
            let a = data.authorities;
            // Add people with signature rights
            if (a.signatureRights && a.signatureRights.options && a.signatureRights.options[0] && a.signatureRights.options[0].required) {
                a.signatureRights.options[0].required.forEach((el) => {
                    const person = {
                        name: el.name,
                        dateOfBirth: el.dateOfBirth,
                    };
                    this.addToPersonList(person, 'screening_role_sign_right', output);
                });
            }
            // Add people with procura
            if (a.powerOfProcuration && a.powerOfProcuration.options && a.powerOfProcuration.options[0] && a.powerOfProcuration.options[0].required) {
                a.powerOfProcuration.options[0].required.forEach((el) => {
                    const person = {
                        name: el.name,
                        dateOfBirth: el.dateOfBirth,
                    };
                    this.addToPersonList(person, 'screening_role_procura', output);
                });
            }
        }
        if (data.officialRoles) {
            let o = data.officialRoles;
            // Add CEO without duplicates
            if (o.ceo && o.ceo.name) {
                const person = {
                    name: o.ceo.name,
                    firstName: o.ceo.firstName,
                    lastName: o.ceo.lastName,
                    dateOfBirth: o.ceo.dateOfBirth,
                };
                this.addToPersonList(person, 'ceo', output);
            }
            // Add chairman without duplicates
            if (o.chairman && o.chairman.name) {
                const person = {
                    name: o.chairman.name,
                    firstName: o.chairman.firstName,
                    lastName: o.chairman.lastName,
                    dateOfBirth: o.chairman.dateOfBirth,
                };
                this.addToPersonList(person, 'chair', output);
            }
            // Add deputy chairman without duplicates
            if (o.deputyChairman && o.deputyChairman.name) {
                const person = {
                    name: o.deputyChairman.name,
                    firstName: o.deputyChairman.firstName,
                    lastName: o.deputyChairman.lastName,
                    dateOfBirth: o.deputyChairman.dateOfBirth,
                };
                this.addToPersonList(person, 'screening_role_deputy_chairman', output);
            }
            // Add owner without duplicates
            if (o.proprietor && o.proprietor.name) {
                const person = {
                    name: o.proprietor.name,
                    firstName: o.proprietor.firstName,
                    lastName: o.proprietor.lastName,
                    dateOfBirth: o.proprietor.dateOfBirth,
                };
                this.addToPersonList(person, 'proprietor', output);
            }
            // Add board members without duplicates
            if (o.boardMembers && o.boardMembers.length) {
                o.boardMembers.forEach((el) => {
                    const person = {
                        name: el.name,
                        firstName: el.firstName,
                        lastName: el.lastName,
                        dateOfBirth: el.dateOfBirth,
                    };
                    this.addToPersonList(person, 'screening_role_board_member', output);
                });
            }
            // Add deputy board members without duplicates
            if (o.deputyBoardMembers && o.deputyBoardMembers.length) {
                o.deputyBoardMembers.forEach((el) => {
                    const person = {
                        name: el.name,
                        firstName: el.firstName,
                        lastName: el.lastName,
                        dateOfBirth: el.dateOfBirth,
                    };
                    this.addToPersonList(person, 'screening_role_deputy_board_member', output);
                });
            }
        }
        if (data.ownership) {
            let o = data.ownership;
            // Add RRHs without duplicates
            if (o.beneficial && o.beneficial.length) {
                o.beneficial.forEach((el) => {
                    let person;
                    if (el.dateOfBirth) {
                        person = {
                            firstName: el.firstName,
                            lastName: el.lastName,
                            dateOfBirth: el.dateOfBirth,
                            address: {
                                street: el.address && el.address.street ? el.address.street : '',
                                zip: el.address && el.address.postalCode ? el.address.postalCode : '',
                                city: el.address && el.address.city ? el.address.city : '',
                                country: el.address && el.address.country ? el.address.country : '',
                            },
                            percentage: el.percentageControl
                        };
                    }else {
                        person = {
                            firstName: el.name,
                            lastName: '(UNIDENTIFIED)',
                            dateOfBirth: '1800-01-01',
                            address: {
                                street: el.address && el.address.street ? el.address.street : '',
                                zip: el.address && el.address.postalCode ? el.address.postalCode : '',
                                city: el.address && el.address.city ? el.address.city : '',
                                country: el.address && el.address.country ? el.address.country : '',
                            },
                            percentage: el.percentageControl
                        };
                    }
                    this.addToPersonList(person, 'screening_role_rrh', output);
                });
            }
            // Add shareholders without duplicates
            if (o.shareholders && o.shareholders.length) {
                o.shareholders.forEach((el) => {
                    if (el.type == 'PERSON') {
                        const person = {
                            name: el.name,
                            dateOfBirth: el.dateOfBirth,
                            percentage: el.percentage
                        };
                        this.addToPersonList(person, 'screening_role_shareholder', output);
                    }
                });
            }
        }
        return output;
    },

    addToPersonList(person, role, output) {
        if (person.name == 'Fratrådt') return;
        if (!person.firstName || (person.firstName && person.firstName == person.name)) {
            if (person.name.includes(',')) {
                person.firstName = (person.name || '').split(', ').slice(1).join(' ');
                person.lastName = (person.name || '').split(', ')[0];
            }else {
                person.firstName = (person.name || '').split(' ').slice(1).join(' ');
                person.lastName = (person.name || '').split(' ')[0];
            }
        }
        const newPerson = JSON.parse(JSON.stringify(person));
        newPerson.roleId = role;
        delete newPerson.name;
        output.push(newPerson);
    },

    // Add a person to the output-list without duplicates
    // Merge role with the role-id-list if the person is already in the list
    mergeWithRoleList(person, role, output) {
        if (person.name == 'Fratrådt') return;
        if (!person.dateOfBirth) person.dateOfBirth = '1800-01-01';

        // Split name into first & last-name if not provided
        if (!person.firstName) person.firstName = (person.name || '').split(' ').slice(1).join(' ');
        if (!person.lastName) person.lastName = (person.name || '').split(' ')[0];

        // Get percentage from shareholder and RRH
        let percent = '';
        if (person.percentage) percent = ' (' + person.percentage + '%)';
        if (person.percentageControl) percent = ' (' + person.percentageControl + '%)';

        let roleName = this.roleName(role);
        if (role == 'screening_role_rrh' || role == 'screening_role_shareholder') roleName += percent;

        // Loop list and check if person already exists
        let match = false;
        for (let i in output) {
            if (output[i].name == person.name) {
                // Update person with role and percentage
                output[i].roleIds.push(role);
                output[i].role += ', ' + roleName;
                output[i].percentage = person.percentage || person.percentageControl || ''; // REMOVE WHEN OLD PEP IS GONE
                if (!output[i].share) output[i].share = person.percentage || 0;
                if (!output[i].rrhShare) output[i].rrhShare = person.percentageControl || 0;
                match = true;
            }
        }
        // Add the person to the list
        if (!match) {
            person.roleIds = [role];
            person.role = roleName;
            person.share = person.percentage || 0;
            person.rrhShare = person.percentageControl || 0;
            output.push(person);
        }
    },

    // Get role name
    roleName(role) {
        switch (role) {
            case 'ceo': return 'Daglig leder';
            case 'chair': return 'Styreleder';
            case 'proprietor': return 'Innehaver';
            case 'screening_role_sign_right': return 'Signaturberettiget';
            case 'screening_role_procura': return 'Prokura';
            case 'screening_role_deputy_chairman': return 'Nestleder';
            case 'screening_role_board_member': return 'Styremedlem';
            case 'screening_role_deputy_board_member': return 'Varamedlem';
            case 'screening_role_rrh': return 'Reell rettighetshaver';
            case 'screening_role_shareholder': return 'Aksjonær';
        }
        return role;
    },

    getAllPossibleRoles() {
        return [
            'ceo',
            'chair',
            'proprietor',
            'screening_role_sign_right',
            'screening_role_procura',
            'screening_role_deputy_chairman',
            'screening_role_board_member',
            'screening_role_deputy_board_member',
            'screening_role_rrh',
            'screening_role_shareholder'
        ];
    },

    // Get person name in title/proper case
    titleCase(str) {
        return str.split(' ')
        .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ')
    },

    // Get brreg-data from name
    // Makes a call to brreg-api and returns a company object
    async getBrregFromName(name) {
        try {
            let response = await axios.get("https://data.brreg.no/enhetsregisteret/api/enheter/" + encodeURIComponent(name));
            return this.processBrreg(response.data);
        }catch (ex) {
            return null;
        }
    },

    // Get brreg-data from orgnr
    // Makes a call to brreg-api and returns a company object
    async getBrregFromNumber(number) {
        try {
            if (number.includes('613838465')) {
                return this.processBrreg({
                    antallAnsatte: 5,
                    forretningsadresse: {
                        adresse: ['Dronningens gate'],
                        kommune: 'NARVIK',
                        kommunenummer: 0,
                        land: 'Norge',
                        landkode: 'NO',
                        postnummer: '8514',
                        poststed: 'NARVIK'
                    },
                    hjemmeside: 'www.verified.eu',
                    institusjonellSektorkode: {
                        beskrivelse: 'Private aksjeselskaper mv.',
                        kode: '2100'
                    },
                    konkurs: false,
                    maalform: 'Bokmål',
                    naeringskode1: {
                        beskrivelse: 'Hvitvasking av penger og annen økonomisk kriminalitet',
                        kode: '62.010'
                    },
                    navn: 'BETALINGER AS',
                    organisasjonsform: {
                        beskrivelse: 'Aksjeselskap',
                        kode: 'AS'
                    },
                    organisasjonsnummer: '613838465',
                    registreringsdatoEnhetsregisteret: '2014-11-03',
                    registrertIForetaksregisteret: true,
                    registrertIFrivillighetsregisteret: false,
                    registrertIMvaregisteret: false,
                    registrertIStiftelsesregisteret: false,
                    sisteInnsendteAarsregnskap: 'Aldri',
                    stiftelsesdato: '2014-10-08',
                    underAvvikling: false,
                    underTvangsavviklingEllerTvangsopplosning: true
                });
            }else if (number.includes('900433824')) {
                return this.processBrreg({
                    antallAnsatte: 5,
                    forretningsadresse: {
                        adresse: ['Dronningens gate'],
                        kommune: 'NARVIK',
                        kommunenummer: 0,
                        land: 'Norge',
                        landkode: 'NO',
                        postnummer: '8514',
                        poststed: 'NARVIK'
                    },
                    hjemmeside: 'www.verified.eu',
                    institusjonellSektorkode: {
                        beskrivelse: 'Private aksjeselskaper mv.',
                        kode: '2100'
                    },
                    konkurs: false,
                    maalform: 'Bokmål',
                    naeringskode1: {
                        beskrivelse: 'Hvitvasking av penger og annen økonomisk kriminalitet',
                        kode: '62.010'
                    },
                    navn: 'UTLEIE GRUPPEN AS',
                    organisasjonsform: {
                        beskrivelse: 'Aksjeselskap',
                        kode: 'AS'
                    },
                    organisasjonsnummer: '900433824',
                    registreringsdatoEnhetsregisteret: '2014-11-03',
                    registrertIForetaksregisteret: true,
                    registrertIFrivillighetsregisteret: false,
                    registrertIMvaregisteret: false,
                    registrertIStiftelsesregisteret: false,
                    sisteInnsendteAarsregnskap: 'Aldri',
                    stiftelsesdato: '2014-10-08',
                    underAvvikling: false,
                    underTvangsavviklingEllerTvangsopplosning: true
                });
            }else {
                let response = await axios.get("https://data.brreg.no/enhetsregisteret/api/enheter/" + encodeURIComponent(number));
                console.log('brreg response', response);
                return this.processBrreg(response.data);
            }
        }catch (ex) {
            return null;
        }
    },

    // Process brreg-response into company object
    processBrreg(data) {
        //console.log('called');
        const company = {};
        try {
            company.org_no = String(data.organisasjonsnummer) //orgno.substr(0,3) + " " + orgno.substr(3,3) + " " + orgno.substr(6,3)
            company.name = data.navn
            company.org_form = data.organisasjonsform.kode
            company.org_form_desc = data.organisasjonsform.beskrivelse
            company.registration_date = data.registreringsdatoEnhetsregisteret
            company.employees = data.antallAnsatte
            company.start_date = data.stiftelsesdato

            company.language = data.maalform

            if (data.institusjonellSektorkode) {
                company.industryCode = data.institusjonellSektorkode.kode
                company.industryDescription = data.institusjonellSektorkode.beskrivelse
            }

            if (data.forretningsadresse) {
                company.address = data.forretningsadresse.adresse.toString()
                company.zip = data.forretningsadresse.postnummer
                company.town = data.forretningsadresse.poststed
                company.country = data.forretningsadresse.land;
                company.countryCode = data.forretningsadresse.landkode;
                company.commune = data.forretningsadresse.kommune || '';
                company.communeNumber = data.forretningsadresse.kommunenummer || '';
            }
            company.businessCodes = [];
            if (data.naeringskode1) {
                company.business = data.naeringskode1.beskrivelse
                company.business_code = data.naeringskode1.kode
                company.businessCodes.push({
                    code: data.naeringskode1.kode,
                    description: data.naeringskode1.beskrivelse
                });
            }
            if (data.naeringskode2) {
                try {
                    company.businessCodes.push({
                        code: data.naeringskode2.kode,
                        description: data.naeringskode2.beskrivelse
                    });
                }catch (ex) {
                    console.log(ex);
                }
            }
            if (data.naeringskode3) {
                try {
                    company.businessCodes.push({
                        code: data.naeringskode3.kode,
                        description: data.naeringskode3.beskrivelse
                    });
                }catch (ex) {
                    console.log(ex);
                }
            }
            if (data.naeringskode4) {
                try {
                    company.businessCodes.push({
                        code: data.naeringskode4.kode,
                        description: data.naeringskode4.beskrivelse
                    });
                }catch (ex) {
                    console.log(ex);
                }
            }
            
            company.inBrReg = true
            company.inBusinessReg = data.registrertIForetaksregisteret
            company.inMvaReg = data.registrertIMvaregisteret
            company.inVoluntary = data.registrertIFrivillighetsregisteret
            company.bankrupt = data.konkurs
            company.liquidation = data.underAvvikling
            company.compulsoryLiquidation = data.underTvangsavviklingEllerTvangsopplosning
        }catch (ex) {
            console.error(ex);
        }
        //console.log('returned');
        return company;
    },

}

export default aml