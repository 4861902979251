import riskNO from "./defaults/riskNO";
const defaultRisk = {

    getDefaultScore(country) {
        switch (country) {
            case 'NO': return riskNO.getDefault();
        }
        return {};
    },

};
export default defaultRisk;