<template>
<div v-on-clickaway="deselectInput" class="fr-base" :class="[{'logic' : activeCanvas}, {'fr-designer' : designMode}]" @click.self="deselectInput" @contextmenu="onContextMenu($event)">

    <vap-form-divider :left="designMode ? 65 : 100" :right="designMode ? 35 : 0" :stickyRight="designMode">
        <!-- RENDERER -->
        <template v-slot:left>
            <vap-label v-if="designMode" :text="'Inputs'" header style="margin-bottom: 5px;" />
            
            
            <vap-card v-for="(card, i) in cards" :key="i" compactList :designer="designMode" :id="card.id" v-show="designMode || isCardRelevant(card)"
                @logic="handleLogic" :selected="isCardSelected(i)" @selected="selectCard(i)" :header="card.hloc ? __t(card.header) : card.header" :disabled="designMode && !isCardRelevant(card)">
                <vap-row>


                    <vap-group v-for="(input, j) in card.inputs" :key="j" v-show="designMode || isRelevant(input)"
                        :width="input.width" :designer="designMode" :disabled="designMode && !isRelevant(input)"
                        @selected="selectInput(i, j)" :selected="isSelected(i, j)" :id="input.id" @logic="handleLogic">

                        <vap-label v-if="input.type != 'helpt' && input.desc" :text="input.dloc ? __t(input.desc) : input.desc" desc :required="input.required" />
                        <vap-input v-if="input.type == 'input'" v-model="cards[i].inputs[j].model" :placeholder="input.placeholder" :rules="(input.required && isRelevant(input) && isCardRelevant(card)) ? 'required' : ''" :noValidation="noValidation || designMode" />
                        <vap-checkbox v-if="input.type == 'check'" :options="input.options" :id="input.id" :activeLogic="activeCanvas" :disabled="editor && input.disabled"
                            @connect="onConnectLogic" @connect-enter="onConnectEnter" @connect-leave="onConnectLeave" :designer="designMode"
                            :inline="input.inline" :radio="input.radio" v-model="cards[i].inputs[j].model" :noDesc="!input.desc" :rules="(input.required && isRelevant(input) && isCardRelevant(card)) ? 'required' : ''" :noValidation="noValidation || designMode" />

                        <vap-dropdown v-if="input.type == 'drop'" :listKey="'title'" :displayKey="'title'" />

                        <vap-helptext v-if="input.type == 'helpt'" :text="input.dloc ? __t(input.desc) : input.desc" />

                    </vap-group>


                </vap-row>
                <div v-if="designMode" style="float: left; margin-top: 20px;">
                    <vap-button :text="'Add input'" @clicked="showInputSelector(i)" />
                </div>
            </vap-card>


            <div v-if="designMode" style="float: left; margin-top: 20px;">
                <vap-button :text="'Add card'" @clicked="addCard" />
            </div>

        </template>
        <!-- DESIGNER -->
        <template v-slot:right>
            <vap-label :text="'Properties'" header style="margin-bottom: 5px;" />
            <vap-card v-if="selectedCardIndex == -1 && selectedInputIndex == -1">
                <div style="width: 100%; height: 160px; text-align: center; padding-top: 120px;">
                    <label>Select an input or card to show properties</label>
                </div>
            </vap-card>
            <vap-card v-if="selectedCardIndex != -1 || selectedInputIndex != -1">
                
                <!-- Basic properties -->
                <vap-row v-if="selectedInputIndex != -1">
                    <vap-icon action :icon="'delete'" absolute :top="10" :right="10" @clicked="showDeletePopup('input')"><template v-slot:tooltip><vap-tooltip :text="__('fr_delete_input')" /></template></vap-icon>
                    <vap-label :text="'Basic properties'" value style="margin-bottom: 6px;" />
                    <vap-group c12 action>
                        <vap-label :text="'Description'" desc />
                        <vap-input id="d1" v-if="!cards[selectedCardIndex].inputs[selectedInputIndex].dloc" actions v-model="cards[selectedCardIndex].inputs[selectedInputIndex].desc" :placeholder="'Description'" noValidation>
                            <template v-slot:actions>
                                <vap-icon action :icon="'open'" @clicked="openTranslations('input', 'desc', false)"><template v-slot:tooltip><vap-tooltip :text="__('fr_localized_text')" /></template></vap-icon>
                            </template>
                        </vap-input>
                        <vap-input id="d2" v-if="cards[selectedCardIndex].inputs[selectedInputIndex].dloc" actions :value="__t(cards[selectedCardIndex].inputs[selectedInputIndex].desc)" disabled :placeholder="'Description'" noValidation>
                            <template v-slot:actions>
                                <vap-icon action :icon="'open'" @clicked="openTranslations('input', 'desc', false)"><template v-slot:tooltip><vap-tooltip :text="__('fr_localized_text')" /></template></vap-icon>
                            </template>
                        </vap-input>
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="'Width'" desc />
                        <vap-dropdown v-model="cards[selectedCardIndex].inputs[selectedInputIndex].width" :options="widthOptions" :displayKey="'title'" :listKey="'title'" :outputKey="'value'" />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="'Required'" desc />
                        <vap-checkbox :text="'Required'" v-model="cards[selectedCardIndex].inputs[selectedInputIndex].required" noValidation />
                    </vap-group>
                    <vap-button-row alignRight style="margin-top: 10px;">
                        <vap-button :text="'⯅'" @clicked="moveInput(-1)" :disabled="selectedInputIndex == 0" />
                        <vap-button :text="'⯆'" @clicked="moveInput(1)" :disabled="selectedInputIndex >= cards[selectedCardIndex].inputs.length - 1"  />
                    </vap-button-row>
                </vap-row>

                <!-- Reference -->
                <vap-row v-if="selectedInputIndex != -1 && (cards[selectedCardIndex].inputs[selectedInputIndex].type == 'input' || cards[selectedCardIndex].inputs[selectedInputIndex].type == 'check')">
                    <vap-label :text="'Reference'" value style="margin-top: 6px; margin-bottom: 6px;" class="flw100" />
                    <vap-group c6>
                        <vap-label :text="'Reference'" desc />
                        <vap-input v-model="cards[selectedCardIndex].inputs[selectedInputIndex].ref" :placeholder="'No reference'" />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="'Source'" desc />
                        <vap-input v-model="cards[selectedCardIndex].inputs[selectedInputIndex].bsource" :placeholder="'No source'" />
                    </vap-group>
                </vap-row>

                <!-- Input properties -->
                <vap-row v-if="selectedInputIndex != -1 && cards[selectedCardIndex].inputs[selectedInputIndex].type == 'input'">
                    <vap-label :text="'Input properties'" value style="margin-top: 6px; margin-bottom: 6px;" />
                    <vap-group c12>
                        <vap-label :text="'Placeholder'" desc />
                        <vap-input v-model="cards[selectedCardIndex].inputs[selectedInputIndex].placeholder" :placeholder="'Placeholder'" />
                    </vap-group>
                </vap-row>

                <!-- Checkbox properties -->
                <vap-row v-if="selectedInputIndex != -1 && cards[selectedCardIndex].inputs[selectedInputIndex].type == 'check'">
                    <vap-label :text="'Checkbox properties'" value style="float: left; width: 100%; margin-top: 6px; margin-bottom: 6px;" />
                    <vap-group c6>
                        <vap-checkbox v-model="cards[selectedCardIndex].inputs[selectedInputIndex].inline" :text="'Inline'" />
                    </vap-group>
                    <vap-group c6>
                        <vap-checkbox v-model="cards[selectedCardIndex].inputs[selectedInputIndex].radio" :text="'Radio'" />
                    </vap-group>
                </vap-row>

                <!-- Options -->
                <div v-if="selectedInputIndex != -1 && (cards[selectedCardIndex].inputs[selectedInputIndex].type == 'check' || cards[selectedCardIndex].inputs[selectedInputIndex].type == 'drop')"
                    style="float: left; width: 100%;">
                    <vap-label :text="'Options'" value style="margin-top: 6px; margin-bottom: 6px;" /><br><br>
                    <div v-for="(option, key) in cards[selectedCardIndex].inputs[selectedInputIndex].options" :key="key" class="fr-option">
                        <vap-checkbox boxOnly v-model="cards[selectedCardIndex].inputs[selectedInputIndex].model[key]" :text="''" noValidation />
                        <vap-input v-if="!cards[selectedCardIndex].inputs[selectedInputIndex].options[key].key" actions v-model="cards[selectedCardIndex].inputs[selectedInputIndex].options[key].label" noValidation>
                            <template v-slot:actions>
                                <vap-icon action :icon="'open'" @clicked="openTranslations('input', 'option', false, key)"><template v-slot:tooltip><vap-tooltip :text="__('fr_localized_text')" /></template></vap-icon>
                                <vap-icon action :icon="'delete'" @clicked="deleteOption(key)"><template v-slot:tooltip><vap-tooltip :text="'Remove option'" /></template></vap-icon>
                            </template>
                        </vap-input>
                        <vap-input v-if="cards[selectedCardIndex].inputs[selectedInputIndex].options[key].key" actions :value="__t(cards[selectedCardIndex].inputs[selectedInputIndex].options[key].key)" disabled noValidation>
                            <template v-slot:actions>
                                <vap-icon action :icon="'open'" @clicked="openTranslations('input', 'option', false, key)"><template v-slot:tooltip><vap-tooltip :text="__('fr_localized_text')" /></template></vap-icon>
                                <vap-icon action :icon="'delete'" @clicked="deleteOption(key)"><template v-slot:tooltip><vap-tooltip :text="'Remove option'" /></template></vap-icon>
                            </template>
                        </vap-input>
                    </div>
                    <div class="fr-add" @click="addOption">
                        <label class="fr-add-icon">&#10010;</label>
                        <label>Add option</label>
                    </div>
                </div>

                <!-- Card properties -->
                <vap-row v-if="selectedInputIndex == -1">
                    <vap-icon action :icon="'delete'" absolute :top="10" :right="10" @clicked="showDeletePopup('card')"><template v-slot:tooltip><vap-tooltip :text="__('fr_delete_input')" /></template></vap-icon>
                    <vap-label :text="'Card properties'" value style="margin-bottom: 6px;" />
                    <vap-group c12>
                        <vap-label :text="'Header'" desc />
                        <vap-input id="c1" v-if="!cards[selectedCardIndex].hloc" v-model="cards[selectedCardIndex].header" actions noValidation :placeholder="'Header'">
                            <template v-slot:actions>
                                <vap-icon action :icon="'open'" @clicked="openTranslations('card', 'header', false)"><template v-slot:tooltip><vap-tooltip :text="__('fr_localized_text')" /></template></vap-icon>
                            </template>
                        </vap-input>
                        <vap-input id="c2" v-if="cards[selectedCardIndex].hloc" :value="__t(cards[selectedCardIndex].header)" actions noValidation disabled :placeholder="'Header'">
                            <template v-slot:actions>
                                <vap-icon action :icon="'open'" @clicked="openTranslations('card', 'header', false)"><template v-slot:tooltip><vap-tooltip :text="__('fr_localized_text')" /></template></vap-icon>
                            </template>
                        </vap-input>
                    </vap-group>
                    <vap-button-row alignRight style="margin-top: 10px;">
                        <vap-button :text="'⯅'" @clicked="moveCard(-1)" :disabled="selectedCardIndex == 0" />
                        <vap-button :text="'⯆'" @clicked="moveCard(1)" :disabled="selectedCardIndex >= cards.length - 1"  />
                    </vap-button-row>
                </vap-row>
                
            </vap-card>

            <!-- Logic -->
            <div v-if="selectedHasLogic()" style="float: left; width: 100%; margin-top: 10px;">
                <vap-label :text="'Logic'" header style="margin-bottom: 5px;" />
                <vap-card>
                    <vap-icon action :icon="'delete'" absolute :top="10" :right="10">
                        <template v-slot:tooltip><vap-tooltip :text="'Remove logic'" /></template>
                    </vap-icon>
                    <vap-row>
                        <vap-label :text="'Visible'" value style="margin-top: 6px; margin-bottom: 6px;" />
                        <vap-group c12>
                            <vap-label :text="'if'" desc />
                            <vap-label v-if="selectedInputIndex == -1" :text="getLabelFromKey(cards[selectedCardIndex].logicKey)" value />
                            <vap-label v-else :text="getLabelFromKey(cards[selectedCardIndex].inputs[selectedInputIndex].logicKey)" value />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="'is'" desc />
                            <vap-label :text="'checked'" value />
                        </vap-group>
                    </vap-row>
                </vap-card>
            </div>

        </template>
    </vap-form-divider>
    
    <ve-popup v-if="inputSelector" @close="inputSelector = false">
        <vap-item padding20 @clicked="addInput('input')"><label>Input</label></vap-item>
        <vap-item padding20 @clicked="addInput('check')"><label>Checkbox</label></vap-item>
        <!-- <vap-item padding20 @clicked="addInput('drop')"><label>Dropdown</label></vap-item> -->
        <vap-item padding20 @clicked="addInput('helpt')"><label>Infotext</label></vap-item>
    </ve-popup>

    <ve-popup v-if="localePopup" :header="'Lokalisert tekst'" @close="localePopup = false" hideCloseButton>
        <div style="float: left; padding-top: 25px; padding-left: 20px; padding-right: 20px;">
            <label>Vil du aktivere lokalisert tekst for dette feltet?</label>
        </div>
        <div style="float: left; width: 100%; padding: 10px 20px; box-sizing: border-box;">
            <vap-button-row alignRight style="margin-top: 15px; margin-bottom: 10px;">
                <vap-button :text="'Avbryt'" outline @clicked="localePopup = false" />
                <vap-button :text="'Ja'" @clicked="createNewLocalizedText" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="deletePopup" :header="__('fr_delete_input')" :width="300" @close="deletePopup = false" hideCloseButton>
        <div style="padding: 20px;">
            <label>{{ __('fr_delete_input_prompt') }}</label><br><br>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_no')" uppercase @clicked="deletePopup = false" />
                <vap-button :text="__('common_yes')" uppercase @clicked="deleteSomething()" />
            </vap-button-row>
        </div>
    </ve-popup>

    <canvas id="canvas" v-if="activeCanvas"></canvas>

</div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway"

export default {
    name: 'FormRenderer',
    mixins: [clickaway],
    props: {
        data: {
            type: Object,
            default: () => { return {} }
        },
        designMode: {
            type: Boolean,
            default: false
        },
        noValidation: {
            type: Boolean,
            default: false
        },
        editor: {
            type: Boolean,
            default: false
        },
        translations: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            designer: true,
            cards: [],
            cardIndexHolder: 0,
            inputSelector: false,
            localePopup: false,
            localeType: '',
            localeKey: '',
            localeAllow: false,
            deletePopup: false,
            deleteType: '',
            selectedCardIndex: -1,
            selectedInputIndex: -1,
            widthOptions: [
                { value: '12', title: '1/1' },
                { value: '6', title: '1/2' },
                { value: '4', title: '1/3' }
            ],
            activeCanvas: false,
            logicPossible: false,
            logicPoint: { x: 0, y: 0 },
            idCounter: 1001
        }
    },
    watch: {
        data: {
            deep: false,
            handler(val) {
                //console.log('FR: data changes', val);
                this.loadCards(val);
            }
        }
    },
    methods: {
        __t(key) {
            if (this.translations[key] && this.translations[key][this.$root.locale.key]) {
                return this.translations[key][this.$root.locale.key];
            }else {
                return key + '.' + this.$root.locale.key;
            }
        },
        onContextMenu: function(e) {
            e.preventDefault();
            this.deselectInput();
        },
        loadCards(val) {
            //console.log('FR: loading cards');
            this.cards = val.cards || [];
            this.idCounter = val.counter || 1001;
        },
        addCard() {
            this.idCounter += 1;
            this.cards.push({ key: 'x' + this.idCounter, inputs: [], id: this.getUniqueId(), header: '', hloc: false });
        },
        moveCard(direction) {
            let card = this.cards[this.selectedCardIndex];
            this.cards.splice(this.selectedCardIndex, 1);
            this.cards.splice(this.selectedCardIndex + direction, 0, card);
            this.selectedCardIndex = this.selectedCardIndex + direction;
        },
        moveInput(direction) {
            let input = this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex];
            this.cards[this.selectedCardIndex].inputs.splice(this.selectedInputIndex, 1);
            this.cards[this.selectedCardIndex].inputs.splice(this.selectedInputIndex + direction, 0, input);
            this.selectedInputIndex = this.selectedInputIndex + direction;
        },
        addInput(type) {
            this.idCounter += 1;
            let input = {
                type: type,
                width: '6',
                key: 'x' + this.idCounter,
                id: this.getUniqueId(),
                required: false,
                dloc: false
            };
            if (type == 'input') {
                input.desc = 'New input';
                input.model = '';
                input.placeholder = '';
            }
            if (type == 'helpt') {
                input.desc = 'New info text';
                input.width = '12';
            }
            if (type == 'check') {
                this.idCounter += 2;
                input.desc = 'New checkbox';
                input.inline = false;
                input.radio = false;
                input.model = {
                    ['x' + (this.idCounter - 1)]: false,
                    ['x' + this.idCounter]: false,
                };
                input.options = {
                    ['x' + (this.idCounter - 1)]: { label: 'Checkbox option 1'},
                    ['x' + (this.idCounter - 0)]: { label: 'Checkbox option 2'},
                };
            }
            if (type == 'drop') {
                this.idCounter += 2;
                input.desc = 'New dropdown';
                input.model = {
                    ['x' + (this.idCounter - 1)]: false,
                    ['x' + this.idCounter]: false,
                };
                input.options = {
                    ['x' + (this.idCounter - 1)]: { label: 'Dropdown option 1'},
                    ['x' + (this.idCounter - 0)]: { label: 'Dropdown option 2'},
                };
            }
            this.cards[this.cardIndexHolder].inputs.push(input);
            this.inputSelector = false;
        },
        selectInput(i, j) {
            if (this.activeCanvas) return;
            this.selectedCardIndex = i;
            this.selectedInputIndex = j;
        },
        selectCard(i) {
            if (this.activeCanvas) return;
            this.selectedCardIndex = i;
            this.selectedInputIndex = -1;
        },
        deselectInput() {
            this.selectedCardIndex = -1;
            this.selectedInputIndex = -1;
            //this.drawLineBetween(this.cards[0].inputs[0], this.cards[1].inputs[0]);
            this.stopCanvas();
        },
        isSelected(i, j) {
            return (this.selectedCardIndex == i && this.selectedInputIndex == j);
        },
        isCardSelected(i) {
            return (this.selectedCardIndex == i && this.selectedInputIndex == -1);
        },
        showInputSelector(index) {
            this.cardIndexHolder = index;
            this.inputSelector = true;
        },
        getUniqueId() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        getInputPoint(input) {
            let elem = document.getElementById(input.id);
            let x = elem.offsetLeft;
            let y = elem.offsetTop;
            if (input.type == 'input') {
                x += 20;
                y += 25;
            }
            return { x, y };
        },
        drawLineBetween(point1, point2) {
            let canvas = document.getElementById('canvas');
            //console.log('canvas', canvas);
            var body = document.body, html = document.documentElement;
            var height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
            canvas.height = height;
            canvas.width = window.innerWidth;
            let context = canvas.getContext('2d');
            context.strokeStyle = "#3190D5";
            if (this.logicPossible) context.strokeStyle = "#0DCC27";
            context.lineWidth = 3;
            context.beginPath();
            context.moveTo(point1.x + 10, point1.y);
            context.lineTo(point2.x + 10, point2.y);
            context.stroke();
            //console.log('x = ' + point1.x + ',  y = ' + point1.y + ',  x = ' + point2.x + ',  y = ' + point2.y);
        },
        handleLogic(id) {
            let elem = document.getElementById('l' + id);
            let left = elem.getBoundingClientRect().left + elem.ownerDocument.defaultView.pageXOffset;
            let top = elem.getBoundingClientRect().top + elem.ownerDocument.defaultView.pageYOffset;
            this.logicPoint = { x: left + 13, y: top + 13 };
            //console.log('x = ' + this.logicPoint.x + ',  y = ' + this.logicPoint.y);
            this.initCanvas();
        },
        onMouseMove(x, y) {
            if (!this.activeCanvas) return;
            //console.log('x = ' + x + ',  y = ' + y);
            this.drawLineBetween(this.logicPoint, { x, y });
        },
        initCanvas() {
            this.activeCanvas = true;
        },
        stopCanvas() {
            this.activeCanvas = false;
            let canvas = document.getElementById('canvas');
            if (canvas) {
                const context = canvas.getContext('2d');
                context.clearRect(0, 0, canvas.width, canvas.height);
            }
        },
        onConnectLogic(key) {
            console.log('connect key=' + key);
            this.stopCanvas();

            if (this.selectedInputIndex == -1) {
                let card = this.cards[this.selectedCardIndex];
                card.logic = true;
                card.logicKey = key;
            }else {
                let input = this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex];
                input.logic = true;
                input.logicKey = key;
            }

        },
        isRelevant(input) {
            if (input.logic) {

                return this.getValueFromKey(input.logicKey);

            }
            return true;
        },
        isCardRelevant(card) {
            if (card.logic) {

                return this.getValueFromKey(card.logicKey);

            }
            return true;
        },
        selectedHasLogic() {
            if (this.selectedCardIndex == -1) return false;
            if (this.selectedInputIndex == -1) {
                return this.cards[this.selectedCardIndex].logic;
            }else {
                return this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].logic;
            }
        },
        getValueFromKey(key) {
            // TODO improve performance here, maybe a separate list with key-value links
            for (let i in this.cards) {
                for (let j in this.cards[i].inputs) {
                    if (this.cards[i].inputs[j].type == 'check') {
                        for (let k in this.cards[i].inputs[j].options) {
                            if (k == key) {
                                return this.cards[i].inputs[j].model[k];
                            }
                        }
                    }
                }
            }
            console.error('getValueFromKey() - key dont exist');
            return false;
        },
        getLabelFromKey(key) {
            // TODO improve performance here, maybe a separate list with key-label links
            for (let i in this.cards) {
                for (let j in this.cards[i].inputs) {
                    if (this.cards[i].inputs[j].type == 'check') {
                        for (let k in this.cards[i].inputs[j].options) {
                            if (k == key) {
                                return this.cards[i].inputs[j].options[k].label;
                            }
                        }
                    }
                }
            }
            console.error('getValueFromKey() - key dont exist');
            return false;
        },
        onConnectEnter() {
            this.logicPossible = true;
        },
        onConnectLeave() {
            this.logicPossible = false;
        },
        getSaveData() {
            return {
                counter: this.idCounter,
                cards: this.cards
            }
        },
        addOption() {
            this.idCounter += 1;
            let input = this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex];
            this.$set(input.model, 'x' + this.idCounter, false);
            this.$set(input.options, 'x' + this.idCounter, { label: 'New option' });
        },
        deleteOption(key) {
            let input = this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex];
            this.$delete(input.model, key);
            this.$delete(input.options, key);
        },
        openTranslations(type, value, allowTipTap, key) {
            this.localeType = type;
            this.localeValue = value;
            this.localeKey = key;
            this.localeAllow = false;
            if (type == 'card') {
                if (this.cards[this.selectedCardIndex].hloc) {
                    this.$emit('locale', this.cards[this.selectedCardIndex].header);
                }else {
                    this.localePopup = true;
                }
            }else {

                if (value == 'option') {

                    if (this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].options[this.localeKey].key) {
                        this.$emit('locale', this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].options[this.localeKey].key);
                    }else {
                        this.localePopup = true;
                    }

                }else {
                    if (this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].dloc) {
                        this.$emit('locale', this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].desc);
                    }else {
                        this.localePopup = true;
                    }
                }
                
            }
        },
        createNewLocalizedText() {
            let key = 'error';
            if (this.localeType == 'card') {
                key = this.cards[this.selectedCardIndex].key;
            }else {
                key = this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].key;
            }
            // Find available translation key
            let proposedKey = 'error';
            let free = false; let count = 0;
            const alphabet = 'abcdefghijklmnopqrstuvwxyz';
            while (!free && count < 25) {
                let match = false;
                proposedKey = key + alphabet.charAt(count);
                for (let translationKey in this.$parent.translations) {
                    if (proposedKey == translationKey) match = true;
                }
                count += 1;
                if (!match) free = true;
            }
            console.log('available key: ' + proposedKey);
            // Add translation key for all enables languages
            console.log('type', this.localeType);
            console.log('value', this.localeValue);
            let formatted = false;
            if (this.localeType == 'input' && this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].type == 'helpt') {
                formatted = true;
            }

            let data = {
                tt: formatted
            };
            console.log()
            for (let i in this.$root.config.locales) {
                console.log(this.$root.config.locales[i])
                if (this.$root.config.locales[i].enabled) data[this.$root.config.locales[i].key] = this.$root.translations[formatted ? 'settings_new_text_tt' : 'settings_new_text'][this.$root.config.locales[i].key];
            }
            this.$set(this.$parent.translations, proposedKey, data);
            console.log('translations', this.$parent.translations);


            if (this.localeType == 'card') {
                console.log('localizing card');
                console.log('card', this.cards[this.selectedCardIndex]);

                this.cards[this.selectedCardIndex].hloc = true;
                this.cards[this.selectedCardIndex].header = proposedKey;
                
            }else {
                if (this.localeValue == 'desc') {
                    this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].dloc = true;
                    this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].desc = proposedKey;
                }else if (this.localeValue == 'option') {

                    console.log('localizing option');

                    console.log('option', this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].options[this.localeKey]);

                    this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].options[this.localeKey].key = proposedKey;
                    this.cards[this.selectedCardIndex].inputs[this.selectedInputIndex].options[this.localeKey].label = '';

                }
            }


            this.localePopup = false;
            this.$emit('locale', proposedKey);
        },
        showDeletePopup(type) {
            this.deleteType = type;
            this.deletePopup = true;
        },
        deleteSomething() {
            this.deletePopup = false;
            if (this.deleteType == 'card') {
                this.$delete(this.cards, this.selectedCardIndex);
                this.deselectInput();
            }else if (this.deleteType == 'input') {
                this.$delete(this.cards[this.selectedCardIndex].inputs, this.selectedInputIndex);
                this.deselectInput();
            }
        }
    },
    async created() {
        //console.log("formrender cretedet")
        if (this.data.cards) this.loadCards(this.data);
        
        document.body.addEventListener('mousemove', e => {
            this.onMouseMove(e.pageX, e.pageY);
        });

    }
}
</script>

<style>

#canvas {
    position: absolute;
    top: 0px; left: 0px;
    width: 100%;
    opacity: 0.8;
    pointer-events: none;
}

.fr-base.logic {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Opera and Firefox */
}

.fr-base {
    
}

.fr-designer #canvas {
    height: 100%;
    min-height: 100vh;
}

.fr-designer {
    min-height: 80vh;
}

.fr-option {
    display: flex;
    margin-bottom: 5px;
}
.fr-option .vap-cb {
    width: auto;
    margin-top: 2px;
}
.fr-option .vap-input {
    flex-grow: 100;
}

.fr-dsc {
    font-size: 13px;
    color: gray;
}

.fr-del {
    margin-top: -3px;
    margin-right: -8px;
    opacity: 0.6;
    cursor: pointer;
}
.fr-del:hover {
    opacity: 1;
}

.fr-add {
    padding: 2px 6px;
    border-radius: 4px;
    border: solid 1px lightgray;
    cursor: pointer;
}
.fr-add:hover {
    background-color: lightgray;
}
.fr-add label {
    cursor: pointer;
}

.fr-add-icon {
    margin-right: 10px;
}

</style>
