<template>
<div>
<div v-if="!loading && !error">
    <PageHeader :actions="actions" compactable :offset="97" @scrolled="scrolled = $event" @action="onAction">
        <template v-slot:content>
            <vap-row v-if="!loading">
                <vap-group auto noPadding>
                    <vap-label :text="__('common_name')" desc/>
                    <vap-label :text="companyTitleCase(person.name)" value />
                </vap-group>
                <vap-group auto noPadding>
                    <vap-label :text="__('common_dob')" desc />
                    <vap-label :text="formatDob(person.dateOfBirth)" value />
                </vap-group>
            </vap-row>
        </template>
        <template v-slot:compact>
            <div v-if="!loading">
                <span>{{ companyTitleCase(person.name) }}</span>
                <span>{{ person.dateOfBirth }}</span>
            </div>
        </template>
    </PageHeader>

    <div class="aml-page" v-if="!loading">

        <vap-form-divider :left="40" :right="60" style="margin-top: 20px;">
            <template v-slot:left>

                <DetailCard split
                    :icon="'person'"
                    :title="'Person'"
                >
                    <template v-slot:content>
                        <DetailItem c6
                            :title="__('common_first_name')"
                            :value="person.info.firstName"
                        />
                        <DetailItem c6
                            :title="__('common_dob')"
                            :value="formatDob(person.dateOfBirth)"
                        />
                        <DetailItem c6
                            :title="__('common_last_name')"
                            :value="person.info.lastName"
                        />
                        <DetailItem c6
                            :title="__('common_ssn')"
                            :value="person.info.ssn"
                            :emptyText="__t('common-not-available')"
                        />
                    </template>
                </DetailCard>


                <DetailCard split
                    :icon="'alpha'"
                    :title="__t('details-contact')"
                >
                    <template v-slot:content>
                        <DetailItem c6
                            :title="__('common_email')"
                            :value="person.email.value"
                            :emptyText="__t('common-not-available')"
                        />
                        <DetailItem c6
                            :title="__('common_phone')"
                            :value="person.phone.number ? person.phone.prefix + ' ' + person.phone.number : ''"
                            :emptyText="__t('common-not-available')"
                        />
                    </template>
                </DetailCard>


                <!-- Export button -->
                <ActionV1
                    :icon="'download'"
                    :text="__t('person-report-create')"
                    :hint="__t('person-report-create-hint')"
                    @clicked="onExportClicked"
                />


                <!-- Roles in companies -->
                <vap-label :text="__t('person-report-roles')" header2 style="margin-top: 10px; margin-bottom: 10px;" />
                <vap-card compactList noPadding v-for="(client, i) in person.clients" :key="'pc' + i">
                    <ClientItem
                        :icon="client.type == 'person' ? 'person' : 'company'"
                        :name="client.name"
                        :info="getRoles(client)"
                        :date="client.type == 'person' ? '' : client.identity"
                        :user="''"
                        :link="'/v1client?id=' + client.id + '&tab=persons'"
                    />
                </vap-card>

            </template>
            <template v-slot:right>

                <!-- Address -->

                <DetailCard hasFlag
                    :icon="'home'"
                    :title="__t('common-address')"
                    :flag="residencyFlag"
                    :empty="clientPerson.addresses.length == 0"
                    :emptyText="__t('common-not-available')"
                >
                    <template v-slot:content>
                        <DetailItem c6 v-for="(address, i) in clientPerson.addresses" :key="'ai' + i"
                            :value="formatAddress(address)"
                            :source="getSource(address)"
                        />
                    </template>
                </DetailCard>


                <!-- Citizenship -->

                <DetailCard v-if="$root.config.persons.geography"
                    hasFlag
                    :icon="'locale'"
                    :title="__t('details-nationality')"
                    :flag="citizenshipFlag"
                >
                    <template v-slot:content>
                        <DetailItem c6 v-for="(citizenship, i) in clientPerson.citizenships" :key="'ai' + i"
                            :value="formatCitizenship(citizenship)"
                            :source="getSource(citizenship)"
                        />
                    </template>
                </DetailCard>


                <!-- ID-controls -->

                <DetailCard :hasInfo="person.idc.done && $root.config.persons.identityInfo"
                    :icon="'card'"
                    :title="__t('person-source-idc')"
                    :hasList="true"
                    :listCount="controls.length"
                    :listText="__t('details-prev-idc')"
                    :empty="!person.idc.done"
                    :emptyText="__t('details-not-completed')"
                    :status="getIdcStatus(person.idc.indicator)"
                    @action="showIdentityInfoDialog(person.idc)"
                >
                    <template v-slot:content>
                        <DetailItem c6
                            :title="__t('details-auth-method')"
                            :value="person.idc.method"
                        />
                        <DetailItem c6
                            :title="__t('common-date')"
                            :value="formatDate('', person.idc.date)"
                        />
                        <DetailItem c12
                            :title="__t('common-result')"
                            :value="getIdcMatchDescription(person.idc.indicator)"
                        />
                    </template>
                    <template v-slot:list>
                        <DetailListItem v-for="(idc, i) in controls" :key="'as' + i"
                            :icon="'search'"
                            :status="getIdcStatus(idc.indicator)"
                        >
                            <template v-slot:row1>
                                <div style="font-size: 15px; color: var(--text-title);">
                                    {{ idc.method + ' (' + formatDate('', idc.date) + ')' }}
                                </div>
                            </template>
                            <template v-slot:row3>
                                <div style="font-size: 15px; color: var(--text-weak);">
                                    {{ getIdcMatchDescription(idc.indicator) }}
                                </div>
                            </template>
                        </DetailListItem>
                    </template>
                </DetailCard>


                <!-- Screenings -->

                <DetailCard :hasInfo="person.scr.done && $root.config.persons.screeningInfo"
                    :icon="'pep'"
                    :title="__t('person-source-scr')"
                    :hasList="true"
                    :listCount="screenings.length"
                    :listText="__t('details-prev-scr')"
                    :empty="!person.scr.done"
                    :emptyText="__t('details-not-completed')"
                    :status="getScreeningStatus(person.scr)"
                    @action="showScreeningInfoDialog"
                >
                    <template v-slot:content>
                        <DetailItem c6
                            :title="__t('common-result')"
                            :value="getScreeningResult(person.scr)"
                        />
                        <DetailItem c6
                            :title="__t('common-date')"
                            :value="formatDate('', person.scr.date)"
                        />
                        <DetailItem c12 v-if="person.scr.isPep || person.scr.hasSanction"
                            :title="'Title'"
                            :value="person.scr.pepSummary.title"
                        />
                    </template>
                    <template v-slot:list>
                        <DetailListItem v-for="(scr, i) in screenings" :key="'as' + i"
                            :middleRow="scr.isPep || scr.hasSanction"
                            :icon="'pep'"
                            :status="getScreeningStatus(scr, true)"
                        >
                            <template v-slot:row1>
                                <div style="font-size: 15px; color: var(--text-title);">
                                    {{ getScreeningResult(scr, true) }}
                                </div>
                            </template>
                            <template v-slot:row2>
                                <div>
                                    {{ scr.result }}
                                </div>
                            </template>
                            <template v-slot:row3>
                                <div style="font-size: 15px; color: var(--text-weak);">
                                    {{ formatDate('', scr.date) }}
                                </div>
                                <div style="font-size: 15px; color: var(--text-weak); margin-left: 6px;">
                                    {{ '(' + getEmployeeName(scr.userId) + ')' }}
                                </div>
                            </template>
                        </DetailListItem>
                    </template>
                </DetailCard>


                <!-- Adverse media -->

                <DetailCard v-if="$root.config.adverseMedia.enabled"
                    :hasInfo="person.web.done"
                    :icon="'search'"
                    :title="__t('ams-name')"
                    :hasList="true"
                    :listCount="searches.length"
                    :listText="__t('details-prev-ams')"
                    :empty="!person.web.done"
                    :emptyText="__t('details-not-completed')"
                    :status="getAmsStatus(person.web)"
                    @action="showAdverseMediaDialog(person.web)"
                >
                    <template v-slot:content>
                        <DetailItem c6
                            :title="__t('common-result')"
                            :value="getAmsText(person.web)"
                        />
                        <DetailItem c6
                            :title="__t('common-date')"
                            :value="formatDate('', person.web.date)"
                        />
                    </template>
                    <template v-slot:list>
                        <DetailListItem v-for="(ams, i) in searches" :key="'as' + i"
                            :icon="'search'"
                            :status="getAmsStatus(ams)"
                        >
                            <template v-slot:row1>
                                <div style="font-size: 15px; color: var(--text-title);">
                                    {{ getAmsText(ams, true) }}
                                </div>
                            </template>
                            <template v-slot:row3>
                                <div style="font-size: 15px; color: var(--text-weak);">
                                    {{ formatDate('', ams.date) }}
                                </div>
                                <div style="font-size: 15px; color: var(--text-weak); margin-left: 6px;">
                                    {{ '(' + getEmployeeName(ams.userId) + ')' }}
                                </div>
                            </template>
                        </DetailListItem>
                    </template>
                </DetailCard>


                <!-- Monitors -->

                <DetailCard v-if="$root.config.screening.personMonitor"
                    :hasInfo="true"
                    :icon="'cloud-search'"
                    :title="__t('monitor-hits-title')"
                    :hasList="true"
                    :listCount="monitorHits.length"
                    :listText="__t('monitor-hits-previous')"
                    :empty="!monitorHit"
                    :emptyText="__t('monitor-hits-empty')"
                    :status="getMonitorStatus(monitorHit)"
                    @action="showMonitorHitDialog(person.monitorHits.length - 1)"
                >
                    <template v-slot:content>
                        <DetailItem c6
                            :title="__t('monitor-hit-type')"
                            :value="getMonitorTypeText(monitorHit)"
                        />
                        <DetailItem c6
                            :title="__t('monitor-hit-new-status')"
                            :value="getMonitorActiveText(monitorHit)"
                        />
                        <DetailItem c12
                            :title="__t('monitor-hit-description')"
                            :value="monitorHit.hit.description"
                        />
                        <DetailItem c12
                            :title="__t('common-date')"
                            :value="formatDate('', monitorHit.created)"
                        />
                    </template>
                    <template v-slot:list>
                        <DetailListItem v-for="(hit, i) in monitorHits" :key="'as' + i"
                            :icon="'cloud-search'"
                            :status="getMonitorStatus(hit)"
                            :middleRow="true"
                        >
                            <template v-slot:row1>
                                <div style="font-size: 15px; color: var(--text-weak); margin-right: 6px;">
                                    {{ __t('monitor-hit-type') + ':' }}
                                </div>
                                <div style="font-size: 15px; color: var(--text-title); margin-right: 20px;">
                                    {{ getMonitorTypeText(hit) }}
                                </div>
                                <div style="font-size: 15px; color: var(--text-weak); margin-right: 6px;">
                                    {{ __t('monitor-hit-new-status') + ':' }}
                                </div>
                                <div style="font-size: 15px; color: var(--text-title); margin-right: 20px;">
                                    {{ getMonitorActiveText(hit) }}
                                </div>
                            </template>
                            <template v-slot:row2>
                                <div style="font-size: 15px; color: var(--text-title);">
                                    {{ hit.hit.description }}
                                </div>
                            </template>
                            <template v-slot:row3>
                                
                                <div style="font-size: 15px; color: var(--text-weak);">
                                    {{ formatDate('', hit.created) }}
                                </div>
                            </template>
                        </DetailListItem>
                    </template>
                </DetailCard>

            </template>
        </vap-form-divider>

    </div>

    <!-- Preview popup -->
    <PreviewPopupV1 v-if="previewVisible" :params="previewParams" @close="previewVisible = false" />

    <!-- ID info dialog -->
    <IdentityInfoDialog v-if="idInfoDialogVisible"
        :params="idInfoDialogParams"
        @close="idInfoDialogVisible = false"
    />

    <!-- Screening info dialog -->
    <ScreeningInfoDialog v-if="screeningInfoDialogVisible"
        :params="screeningInfoDialogParams"
        @close="screeningInfoDialogVisible = false"
    />

    <!-- Adverse Media Dialog -->
    <AdverseMediaDialog v-if="webVisible"
        :params="webParams"
        @close="webVisible = false"
    />

    <!-- Monitor Hit Dialog -->
    <MonitorHitDialog v-if="monitorHitDialog.show"
        :params="monitorHitDialog"
        @close="monitorHitDialog.show = false"
    />

    <!-- Monitor Test Dialog -->
    <MonitorTestDialog v-if="monitorTestDialog.show"
        :params="monitorTestDialog"
        @close="monitorTestDialog.show = false"
    />

</div>
<div>
    <PageErrorMessage v-if="error" :message="error.message" />
</div>
</div>
</template>
<script>
import log from '../log';
import v1api from '../v1helpers/v1api';
import previewHelper from '../v1files/previewHelper';
import uiHelper from '../helpers/uihelper';
import monitorHelper from '../v1monitor/monitorHelper';

import dbPersonHelper from '../v1helpers/dbPersonHelper';
import v1personHelper from '../v1helpers/v1personHelper';

import PageHeader from '../v1ui/PageHeader.vue';
import PreviewPopupV1 from '../v1files/PreviewPopupV1.vue';

import ClientItem from '../components/items/ClientItem.vue';

import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';

import DetailCard from '../v1ui/DetailCard.vue';
import DetailItem from '../v1ui/DetailItem.vue';
import DetailListItem from '../v1ui/DetailListItem.vue';

import ActionV1 from '../v1ui/ActionV1.vue';

import IdentityInfoDialog from '../v1persons/IdentityInfoDialog.vue';
import ScreeningInfoDialog from '../v1persons/ScreeningInfoDialog.vue';
import AdverseMediaDialog from '../v1persons/AdverseMediaDialog.vue';

import MonitorHitDialog from '../v1monitor/MonitorHitDialog.vue';
import MonitorTestDialog from '../v1monitor/MonitorTestDialog.vue';

import PageErrorMessage from '../v1ui/PageErrorMessage.vue';

export default {
    name: 'PersonV1',
    components: {
        PageHeader,
        PreviewPopupV1,
        ClientItem,
        VFRow,
        VFGroup,
        VFLabel,
        DetailCard,
        DetailItem,
        DetailListItem,
        ActionV1,
        IdentityInfoDialog,
        ScreeningInfoDialog,
        AdverseMediaDialog,
        MonitorHitDialog,
        MonitorTestDialog,
        PageErrorMessage,
    },
    data() {
        return {
            loading: true,
            error: null,
            scrolled: false,
            actions: [],
            
            person: {},
            clientPerson: null,

            previewVisible: false,
            previewParams: null,

            idInfoDialogVisible: false,
            idInfoDialogParams: {},

            screeningInfoDialogVisible: false,
            screeningInfoDialogParams: {},

            monitorHitDialog: { show: false },
            monitorTestDialog: { show: false },

            webVisible: false,
            webParams: null,

            screenings: [],
            controls: [],
            searches: [],

            monitorHit: null,
            monitorHits: [],

            residencyFlag: '',
            residencyTooltip: '',
            citizenshipFlag: '',
            citizenshipTooltip: '',
        }
    },
    methods: {

        onAction(action) {
            if (action.action == 'pdf') {
                this.onExportClicked();
            }else if (action.action == 'debug') {
                this.updateFlags();
            }else if (action.action == 'monitor') {
                this.showMonitorTestDialog();
            }
        },

        showMonitorTestDialog() {
            this.monitorTestDialog.person = this.person;
            this.monitorTestDialog.show = true;
        },

        showMonitorHitDialog(index) {
            this.monitorHitDialog.person = this.person;
            this.monitorHitDialog.hitIndex = index;
            this.monitorHitDialog.show = true;
        },

        async updateFlags() {
            uiHelper.setCardActionWorking(this.actions, 'debug', true);

            await v1api.updateFlags(this.person._id);

            uiHelper.setCardActionWorking(this.actions, 'debug', false);
        },
        
        getRoles(client) {
            let str = '';
            for (const i in client.roles) {
                if (i > 0) str += ', ';
                str += this.__(client.roles[i]);
            }
            return str;
        },

        onExportClicked() {
            const userdata = this.getReportUserData();
            console.log('USERDATA', userdata);

            this.previewParams = previewHelper.getPersonReportPreviewParams(this.$root, userdata);
            this.previewVisible = true;
        },

        getReportUserData() {
            const userdata = {
                metadata: {
                    userId: this.$root.credentials.uid,
                    userName: this.getEmployeeName(this.$root.credentials.uid),
                    date: new Date()
                },
                person: this.person,
                pdfLanguage: this.$root.locale.key,
                name: this.companyTitleCase(this.person.name),
                clients: []
            };

            for (const i in this.person.clients) {
                userdata.clients.push({
                    name: this.person.clients[i].name,
                    orgnr: this.person.clients[i].type == 'org' ? this.person.clients[i].identity : '',
                    roles: this.getRoles(this.person.clients[i])
                });
            }

            
            return userdata;
        },


        getIdcMatchDescription(indicator) {
            return dbPersonHelper.getIdcMatchDescription(indicator);
        },

        getIdcStatus(indicator) {
            if (indicator == 1 || indicator == 2) return 1;
            if (indicator == 3 || indicator == 4) return 2;
            if (indicator == 0 || indicator == 5 || indicator == 6 || indicator == 7) return 3;
            return 0;
        },

        showIdentityInfoDialog(idc) {
            this.idInfoDialogParams.person = this.person;
            this.idInfoDialogVisible = true;
        },


        getScreeningResult(screening, archive) {
            return dbPersonHelper.getScreeningResult(this.$root, screening, archive);
        },

        getScreeningStatus(screening, archive) {
            return dbPersonHelper.getScreeningStatus(screening, archive);
        },

        showScreeningInfoDialog() {
            this.screeningInfoDialogParams.screening = this.person.scr;
            this.screeningInfoDialogVisible = true;
        },


        getAmsStatus(web) {
            console.log('web', web);
            if (web.hitLevel == 'ok') return 1;
            if (web.hitLevel == 'some') return 2;
            if (web.hitLevel == 'hits') return 3;
            return 0;
        },

        getAmsText(web, archive) {
            return v1personHelper.getAmsResultText(this.$root, web, this.$root.locale.key, archive);
        },

        showAdverseMediaDialog(web) {
            this.webParams = { name: this.person.name, web: web, id: this.person.personId, clientId: '' };
            this.webVisible = true;
        },

        formatAddress(item) {
            return dbPersonHelper.formatAddressString(this.$root, item);
        },

        formatCitizenship(item) {
            return this.$root.getCountryName(item.value) + ' (' + item.value + ')';
        },

        getSource(item) {
            return v1personHelper.getSourceDisplay(this.$root, item.source);
        },

        getMonitorTypeText(hit) {
            return monitorHelper.getTypeText(this.$root, hit);
        },

        getMonitorActiveText(hit) {
            return monitorHelper.getActiveText(this.$root, hit);
        },

        getMonitorStatus(hit) {
            if (!hit || !hit.hit) return 0;
            if (hit.hit.active) {
                if (hit.hit.type == 'pep') return 2;
                return 3;
            }else {
                return 1;
            }
        }

    },
    async created() {
        this.$parent.setLoading(true, this.__t('person-loading') + '...');

        const response = await v1api.getPerson(this.$route.query.id);
        console.log('response', response);
        if (response.error) {
            this.error = {
                code: response.code,
                message: '',
            }

            if (response.code == 404) {
                this.error.message = this.__t('person-deleted');
            }

            // Hide loading spinner and show UI
            this.loading = false;
            this.$parent.setLoading(false);
            return;
        }

        this.person = response.person;

        if (response.clientPersons.length > 0) this.clientPerson = response.clientPersons[0].person;
        console.log('clientPerson', this.clientPerson);

        // Set the title
        this.$parent.setTitle(this.companyTitleCase(this.person.name));

        // Load archive
        for (const i in this.person.archive) {
            if (this.person.archive[i].type == 'idc') {
                this.controls.push(this.person.archive[i]);
            }else if (this.person.archive[i].type == 'scr') {
                this.screenings.push(this.person.archive[i]);
            }else if (this.person.archive[i].type == 'ams') {
                this.searches.push(this.person.archive[i]);
            }
        }

        // Load monitor hits
        if (this.person.monitorHits && this.person.monitorHits.length > 0) {
            for (const i in this.person.monitorHits) {
                if (i == this.person.monitorHits.length - 1) {
                    this.monitorHit = this.person.monitorHits[i];
                }else {
                    this.monitorHits.push(this.person.monitorHits[i]);
                }
            }
        }

        if (this.clientPerson) {
            const residency = v1personHelper.getBestResidencySource(this.clientPerson);
            if (residency) this.residencyFlag = residency.country;
            this.residencyTooltip = v1personHelper.getResidencyTooltip(this.$root, this.clientPerson);

            const citizenship = v1personHelper.getBestCitizenshipSource(this.clientPerson);
            if (citizenship) this.citizenshipFlag = citizenship.value;
            this.citizenshipTooltip = v1personHelper.getCitizenshipTooltip(this.$root, this.clientPerson);
        }

        this.actions = [
            { icon: 'pdf', action: 'pdf' },
        ];
        if (this.$root.debug.local) {
            this.actions.push({ icon: 'bug', action: 'debug', working: false });
        }
        if (this.$root.config.screening.personMonitorTest) {
            this.actions.push({ icon: 'search', action: 'monitor', working: false });
        }

        // Hide loading spinner and show UI
        this.loading = false;
        this.$parent.setLoading(false);

        console.log('PERSON', this.person);
    }
}
</script>
<style scoped>

.vclient-scrolled {
    padding-top: 90px;
}

</style>