<template>
<div>
    <div class="slim-page">
        <vap-card list :header="'Export data'">
            <vap-row>
                <vap-group c2>
                    <vap-label :text="'Skip'" desc />
                    <vap-input v-model="skip" />
                </vap-group>
                <vap-group c2>
                    <vap-label :text="'Limit'" desc />
                    <vap-input v-model="limit" />
                </vap-group>
            </vap-row>
            <vap-button-row alignLeft>
                <vap-button :text="'Load metadata'" @clicked="loadData" :disabled="working" :working="working" />
                <vap-button :text="'Export to CSV'" @clicked="downloadFile" :disabled="working" :working="working" />
            </vap-button-row>
        </vap-card>
    </div>

    <div class="aml-page" v-if="loadedColumns.length > 0">
        <vap-card>
            <DataTable :columns="loadedColumns" :rows="loadedRows" ref="datatable" />
        </vap-card>
    </div>

</div>
</template>

<script>
import api from '../../portalapi';
import tools from '../../tools';

import downloadjs from 'downloadjs';
import DataTable from '../../components/special/DataTable.vue';

export default {
    name: 'ScriptView',
    components: {
        DataTable
    },
    data() {
        return {
            working: false,
            filename: 'exported_data',
            skip: 0,
            limit: 3,
            loadedPeople: [],
            loadedColumns: [],
            loadedRows: [],
        }
    },
    methods: {

        async loadData() {

            const filters = [
                { field: 'pep', op: 'exists', value: true },
            ];
            const from = Number(this.skip);
            const limit = Number(this.limit);
            const sort = { created: 1 };
            const include = [];
            const counts = [];

            const response = await api.queryPeople(filters, from, limit, sort, include, counts);
            this.loadedPeople = response.people;

            this.loadedRows = [];
            this.loadedColumns = [
                { key: 'fullName', name: 'Navn', width: 10 },
                { key: 'dateOfBirth', name: 'Fødselsdato', width: 6 },
                { key: 'pepTitle', name: 'PEP tittel', width: 10 },
                { key: 'pepMatchIndicator', name: 'Treff indikator', width: 4 },
                { key: 'pepMatchDescription', name: 'Treff beskrivelse', width: 10 },
                { key: 'pepName', name: 'Treff navn', width: 10 },
                { key: 'pepDateOfBirth', name: 'Treff fødselsdato', width: 6 },
                { key: 'companies', name: 'Selskaper', width: 16 },
            ];
            for (const i in this.loadedPeople) {
                const person = tools.decodePerson(this.loadedPeople[i].identity);
                const fullName = tools.titleCase(person.firstName) + ' ' + tools.titleCase(person.lastName);
                const result = (this.loadedPeople[i].pep && this.loadedPeople[i].pep.results) ? this.loadedPeople[i].pep.results[0] : {};
                const companyString = '';
                if (this.loadedPeople[i].companies) {
                    for (const j in this.loadedPeople[i].companies) {
                        if (j > 0) companyString += ', ';
                        companyString += this.loadedPeople[i].companies[j].name;
                    }
                }
                this.loadedRows.push({
                    fullName: fullName,
                    dateOfBirth: person.dateOfBirth,
                    pepTitle: tools.replaceAll(result.title, 'FSEFSEFE', ' '),
                    pepMatchIndicator: result.indicator,
                    pepMatchDescription: tools.replaceAll(result.description, ',', ' '),
                    pepName: result.name,
                    pepDateOfBirth: result.dateOfBirth,
                    companies: tools.replaceAll(companyString, ',', ' ')
                });
            }
        },

        downloadFile() {
            let str = '';
            const comma = ';';
            for (const i in this.loadedColumns) {
                str += this.loadedColumns[i].name + comma;
            }
            str += '\n';
            for (const i in this.loadedRows) {
                for (const j in this.loadedColumns) {
                    str += this.getValue(this.loadedRows[i], this.loadedColumns[j]) + comma;
                }
                str += '\n';
            }
            downloadjs("data:text/csv;charset=utf-8,\uFEFF" + str, this.filename + ".csv", "text/csv");
        },

        getValue(row, column) {
            if (row[column.key]) {
                return row[column.key].toString().replace(/(\r\n|\n|\r)/gm, "");
            }else {
                return '';
            }
        },

    },
    async created() {
        
    }
}
</script>
<style scoped>


</style>