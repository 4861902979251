<template>
<div class="flw100" style="padding: 20px 20px; padding-bottom: 40px;">
    
    <div class="flw100" style="padding: 10px 10px; padding-bottom: 20px;">
        {{ __('customer_new_no') }}
    </div>

    <div v-if="!brregResult.name">
        <vap-label :text="__('customer_brreg_hint')" desc />
        <ve-brreg-search @company-selected="onBrregResult" />
    </div>

</div>
</template>
<script>
import aml from '../aml';

export default {
    name: 'SearchNO',
    components: {
        
    },
    data() {
        return {
            brregResult: { name: '' },
        }
    },
    methods: {
        
        onBrregResult(company, rawData) {
            this.brregResult = aml.processBrreg(rawData);
            const selectedCompany = {
                name: company.name,
                identity: company.org_no,
                country: 'NO',
                source: 'bankidaml',
                brreg: this.brregResult
            };
            //console.log('BRREG', this.brregResult);
            this.$emit('result', selectedCompany);
        },

        resetResult() {
            this.brregResult = { name: '' };
        },

    },
    async created() {
        
    }
}
</script>
<style scoped>


</style>