<template>
<div class="aml-page">

    <vap-form-divider>
        <template v-slot:left>

            <vap-label :text="'New account'" header2 style="margin-top: 20px; margin-bottom: 10px;" />

            <ActionV1
                :icon="'add'"
                :text="'New account'"
                :hint="'Show the wizard for enabling the AML portal for a new Verified account'"
                @clicked="onAddClicked"
            />
            <ActionV1
                :icon="'add'"
                :text="'New account V1.5'"
                :hint="'Show the wizard for enabling the AML portal for a new Verified account'"
                @clicked="onAddClickedV1"
            />

        </template>
        <template v-slot:right>

            <vap-label :text="'Accounts'" header2 style="margin-top: 20px; margin-bottom: 10px;" />

            <CenteredSpinner v-if="loading" />

            <div v-if="!loading">
                <vap-card compactList noPadding v-for="(company, i) in companies" :key="'hi' + i">
                    <ClientItem
                        :icon="'company'"
                        :name="company.name"
                        :info="company.companyId + ' - ' + company.key"
                        :date="formatDate('datetime', company.dateCreated)"
                        :user="getEmployeeName(company.userCreated)"
                        :link="'/v1admin?id=' + company._id"
                    />
                </vap-card>
            </div>

            <PageControl v-if="!loading" :total="totalPages" :current="currentPage" @page="onNavigate" />

        </template>
    </vap-form-divider>

    <ve-popup v-if="addDialog.show" :header="'Add account wizard'" :width="500" @close="addDialog.show = false" hideCloseButton>
        <div style="padding: 20px;" v-if="!addDialog.working && addDialog.page == 1">
            <vap-row>
                <vap-group c12>
                    <vap-label desc :text="'Company Id'" />
                    <vap-input v-model="addDialog.companyId" :rules="'required'" @input="validateAddDialog" />
                </vap-group>
            </vap-row>
        </div>
        <div style="padding: 20px;" v-if="!addDialog.working && addDialog.page == 2">
            <vap-row>
                <vap-group c6>
                    <vap-label desc :text="'Display Name 1'" />
                    <vap-input v-model="addDialog.name1" :rules="'required'" @input="validateAddDialog" />
                </vap-group>
                <vap-group c6>
                    <vap-label desc :text="'Display Name 2'" />
                    <vap-input v-model="addDialog.name2" :rules="'required'" @input="validateAddDialog" />
                </vap-group>
                <vap-group c12>
                    <vap-label desc :text="'Database key'" />
                    <vap-input v-model="addDialog.key" :rules="'required'" @input="validateAddDialog" />
                </vap-group>
            </vap-row>
        </div>
        <div style="padding: 20px;" v-if="!addDialog.working && addDialog.page == 3">
            <vap-row>
                <vap-group c12>
                    <vap-label desc :text="'Features'" />
                    <vap-checkbox v-model="addDialog.featureModel" :options="addDialog.featureOptions" @input="validateAddDialog" />
                </vap-group>
            </vap-row>
        </div>
        <div class="flw100" style="padding: 20px;" v-if="addDialog.working">
            <div class="flw100" style="position: relative; padding-top: 20px; padding-bottom: 0px;">
                <div style="position: absolute; left: 50%;">
                    <vap-spinner medium />
                </div>
                <div style="margin-top: 110px; text-align: center; margin-right: 20px; color: var(--accent); font-size: 18px;"><label>{{ addDialog.status + '...' }}</label></div>
            </div>
        </div>
        <div style="padding: 20px;">
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="addDialog.show = false" :disabled="addDialog.working" />
                <vap-button :text="addDialog.nextButton" uppercase @clicked="onAddNextPage" :disabled="isAddNextDisabled()" :working="addDialog.working" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="addDialogV1.show" :header="'Add account wizard'" :width="500" @close="addDialogV1.show = false" hideCloseButton>
        <div style="padding: 20px;" v-if="!addDialogV1.working && addDialogV1.page == 1">
            <vap-row>
                <vap-group c12>
                    <vap-label desc :text="'Company Id'" />
                    <vap-input v-model="addDialogV1.companyId" :rules="'required'" @input="validateAddDialogV1" />
                </vap-group>
            </vap-row>
        </div>
        <div style="padding: 20px;" v-if="!addDialogV1.working && addDialogV1.page == 2">
            <vap-row>
                <vap-group c6>
                    <vap-label desc :text="'Display Name 1'" />
                    <vap-input v-model="addDialogV1.name1" :rules="'required'" @input="validateAddDialogV1" />
                </vap-group>
                <vap-group c6>
                    <vap-label desc :text="'Display Name 2'" />
                    <vap-input v-model="addDialogV1.name2" :rules="'required'" @input="validateAddDialogV1" />
                </vap-group>
                <vap-group c12>
                    <vap-label desc :text="'Database key'" />
                    <vap-input v-model="addDialogV1.key" :rules="'required'" @input="validateAddDialogV1" />
                </vap-group>
            </vap-row>
        </div>
        <div style="padding: 20px;" v-if="!addDialogV1.working && addDialogV1.page == 3">
            <vap-row>
                <vap-group c12>
                    <vap-label desc :text="'Features'" />
                    <vap-checkbox v-model="addDialogV1.featureModel" :options="addDialogV1.featureOptions" @input="validateAddDialogV1" />
                </vap-group>
            </vap-row>
        </div>
        <div class="flw100" style="padding: 20px;" v-if="addDialogV1.working">
            <div class="flw100" style="position: relative; padding-top: 20px; padding-bottom: 0px;">
                <div style="position: absolute; left: 50%;">
                    <vap-spinner medium />
                </div>
                <div style="margin-top: 110px; text-align: center; margin-right: 20px; color: var(--accent); font-size: 18px;"><label>{{ addDialogV1.status + '...' }}</label></div>
            </div>
        </div>
        <div style="padding: 20px;">
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="addDialogV1.show = false" :disabled="addDialogV1.working" />
                <vap-button :text="addDialogV1.nextButton" uppercase @clicked="onAddNextPageV1" :disabled="isAddNextDisabledV1()" :working="addDialogV1.working" />
            </vap-button-row>
        </div>
    </ve-popup>

</div>
</template>

<script>
import axios from 'axios';
import backend from '../../backend';
import v2lib from '../../v2lib';
import api from '../../portalapi';
import log from '../../log';
import tools from '../../tools';
import configTools from '../../config';

import idc01 from '../../v1form/defaults/idc01';
import kyc00 from '../../v1form/defaults/kyc00';
import kyc02 from '../../v1form/defaults/kyc02';
import kycp01 from '../../v1form/defaults/kycp01';

import ClientItem from '../../components/items/ClientItem';
import CenteredSpinner from '../../v1ui/CenteredSpinner.vue';
import PageControl from '../../components/special/PageControl.vue';
import SearchBox from '../../components/special/SearchBox.vue';
import ActionV1 from '../../v1ui/ActionV1.vue';

export default {
    name: 'AdminView',
    components: {
        ClientItem,
        CenteredSpinner,
        PageControl,
        SearchBox,
        ActionV1,
    },
    data() {
        return {
            loading: true,
            companies: [],

            itemsPerPage: 10,
            currentPage: 0,
            totalPages: 0,
            totalCount: 0,

            addDialog: {
                show: false,
                working: false,
                valid: false,
                page: 1,
                status: '',
                nextButton: 'Next',
                companyId: '',
                name1: '',
                name2: '',
                key: '',
                featureModel: {
                    personFlow: false,
                    swedishCompany: false
                },
                featureOptions: {
                    personFlow: { label: 'Person Flow' },
                    swedishCompany: { label: 'Swedish company search' }
                }
            },
            addDialogV1: {
                show: false,
                working: false,
                valid: false,
                page: 1,
                status: '',
                nextButton: 'Next',
                companyId: '',
                name1: '',
                name2: '',
                key: '',
                featureModel: {
                    personFlow: false,
                    personFlowKyc: false,
                    flowNorway: false,
                    flowSweden: false
                },
                featureOptions: {
                    personFlow: { label: 'Person Flow' },
                    personFlowKyc: { label: 'Person Flow with KYC' },
                    flowNorway: { label: 'Norwegian setup' },
                    flowSweden: { label: 'Swedish setup' },
                }
            }
        }
    },
    methods: {

        //#region Add Account Dialog ------------------------------------------------------------------------------------------

        onAddClicked() {
            this.addDialog.show = true;
        },

        async onAddNextPage() {
            this.addDialog.working = true;

            if (this.addDialog.page == 1) {
                
                this.updateAddStatus('Fetching company info from Verified API');

                const res = await this.axiosGet(`/api/companies/${this.addDialog.companyId}`);
                console.log('res', res);

                const parts = res.data.name.split(' ');
                this.addDialog.name1 = parts[0];
                this.addDialog.key = parts[0].toLowerCase();
                for (const i in parts) {
                    if (i > 1) this.addDialog.name2 += ' ';
                    if (i > 0) this.addDialog.name2 += parts[i];
                }

                this.addDialog.page = 2;

            }else if (this.addDialog.page == 2) {

                this.addDialog.page = 3;

            }else if (this.addDialog.page == 3) {

                await this.setPermissions();
                await this.loadCompanies();
                this.addDialog.show = false;
            }

            this.addDialog.working = false;
        },

        validateAddDialog() {
            if (this.addDialog.page == 1) {
                if (this.addDialog.companyId.length > 7) {
                    this.addDialog.valid = true;
                }else {
                    this.addDialog.valid = false;
                }
                return;
            }

            this.addDialog.valid = true;
        },

        isAddNextDisabled() {
            return !this.addDialog.valid;
        },

        async setPermissions() {

            console.log('add', this.addDialog);

            let data = undefined, res = undefined;
            let companyId = this.addDialog.companyId;
            let idcToken = '';

            const roles = {
                admin: { type: 'admin', id: '' },
                privileged: { type: 'privileged', id: '' },
                regular: { type: 'regular', id: '' },
                template: { type: 'template', id: '' },
            };

            this.updateAddStatus('Fetching company roles from Verified API');
            await new Promise(r => setTimeout(r, 1000));

            // Get roles
            res = await this.axiosGet(`/api/auth/roles?filters=` + encodeURIComponent(`{"and":[{"op":"regex","value":"^/roles/.+/companies/${companyId}$","field":"name"}]}`) + `&from=0&limit=10&sort={"name":1}`);
            console.log('RRes', res);
            for (const i in res.data) {
                if (res.data[i].name.includes('admin')) {
                    roles.admin.id = res.data[i].id;
                }else if (res.data[i].name.includes('privileged')) {
                    roles.privileged.id = res.data[i].id;
                }else if (res.data[i].name.includes('regular')) {
                    roles.regular.id = res.data[i].id;
                }
            }

            this.updateAddStatus('Adding descriptors');
            await new Promise(r => setTimeout(r, 1000));

            // Add all descriptors for all roles
            const descriptors = [
                { name: 'aml-portal-org', public: false },
                { name: 'aml-portal-v0-kyc', public: false },
                { name: 'aml-portal-pep', public: true },
                { name: 'aml-portal-id', public: true },
                { name: 'aml-portal-report', public: true }
            ];
            for (const i in descriptors) {
                for (const j in roles) {
                    if (roles[j].type != 'template') {

                        console.log('adding', roles[j]);

                        this.updateAddStatus('Adding descriptor ' + descriptors[i].name);
                        await new Promise(r => setTimeout(r, 250));

                        data = { actions: [ 'GET' ], headers: [ { key: 'x-namespace', value: `/companies/${companyId}` } ], resource: `^/envelope-descriptors/${descriptors[i].name}$` };
                        await this.axiosPost(`/api/auth/roles/${roles[j].id}/permissions`, data);

                        data = { actions: [ 'POST' ], headers: [ { key: 'x-namespace', value: `/companies/${companyId}` } ], resource: `^/envelope-descriptors/${descriptors[i].name}/envelopes$` };
                        await this.axiosPost(`/api/auth/roles/${roles[j].id}/permissions`, data);

                        if (descriptors[i].public) {
                            data = { actions: [ 'POST' ], headers: [ { key: 'x-namespace', value: `/companies/${companyId}` } ], resource: `^/envelope-descriptors/${descriptors[i].name}/tokens$` };
                            await this.axiosPost(`/api/auth/roles/${roles[j].id}/permissions`, data);
                        }

                    }
                }
            }

            this.updateAddStatus('Creating public token for ID-control');
            await new Promise(r => setTimeout(r, 1500));

            // Get public token for ID-control
            data = { actor: { givenName: this.addDialog.name1, familyName: this.addDialog.name2 }, ttl: { years: 2 }, withCompanyAPI: false, withOidcAPI: false, withPersonAPI: false };
            res = await this.axiosPost('/api/envelope-descriptors/aml-portal-id/tokens', data, true);
            idcToken = res.data.token;
            console.log('idcToken', idcToken);

            this.updateAddStatus('Updating roles');
            await new Promise(r => setTimeout(r, 5000));

            // Get roles again since there will now be a public role
            res = await this.axiosGet(`/api/auth/roles?filters=` + encodeURIComponent(`{"and":[{"op":"regex","value":"^/roles/.+/companies/${companyId}$","field":"name"}]}`) + `&from=0&limit=10&sort={"name":1}`);
            for (const i in res.data) {
                if (res.data[i].name.includes('tmpl')) {
                    roles.template.id = res.data[i].id;
                }
            }

            // Set AML permissions for all roles
            const permissions = [
                { method: 'GET', resource: '^/bankid-oidc/organization/noauth', public: false },
                { method: 'GET', resource: '^/bankid-oidc/person/noauth$', public: false },
                { method: 'GET', resource: '^/bankid-oidc/person/pdf$', public: false },
                { method: 'GET', resource: '^/bankid-oidc/authentication', public: true },
                { method: 'GET', resource: '^/bankid-oidc/aml', public: true }
            ];
            for (const i in roles) {

                this.updateAddStatus('Adding BankID AML persmissions for ' + roles[i].type);
                await new Promise(r => setTimeout(r, 250));

                for (const j in permissions) {
                    if (!(!permissions[j].public && roles[i].type == 'template') && !(permissions[j].public && roles[i].type != 'template')) {
                        data = { actions: [permissions[j].method], resource: permissions[j].resource };
                        await this.axiosPost(`/api/auth/roles/${roles[i].id}/permissions`, data);
                    }
                }
            }

            this.updateAddStatus('Enabling link to AML-portal');
            await new Promise(r => setTimeout(r, 1500));

            // Get and set features
            data = { features: [] };
            res = await this.axiosGet(`/api/companies/${companyId}/settings`);
            for (const i in res.data.features) {
                data.features.push(res.data.features[i]);
            }
            data.features.push('aml-portal');
            await this.axiosPut(`/api/companies/${companyId}/settings`, data);

            // Set AML portal link
            data = { amlPortalLink: [ "web/aml-portal/", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "" ] };
            await this.axiosPut(`/api/companies/${companyId}/settings`, data);


            const company = {
                type: 'prod',
                name: this.addDialog.name1 + ' ' + this.addDialog.name2,
                key: this.addDialog.key,
                id: companyId
            };
            await backend.adminAddCompany(company);
            await this.loadCompanies();

        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Add Account Dialog V1 ---------------------------------------------------------------------------------------

        onAddClickedV1() {
            this.addDialogV1.show = true;
        },

        async onAddNextPageV1() {
            this.addDialogV1.working = true;

            if (this.addDialogV1.page == 1) {
                
                this.updateAddStatus('Fetching company info from Verified API');

                const res = await this.axiosGet(`/api/companies/${this.addDialogV1.companyId}`);
                console.log('res', res);

                const parts = res.data.name.split(' ');
                this.addDialogV1.name1 = parts[0];
                this.addDialogV1.key = parts[0].toLowerCase();
                for (const i in parts) {
                    if (i > 1) this.addDialogV1.name2 += ' ';
                    if (i > 0) this.addDialogV1.name2 += parts[i];
                }

                this.addDialogV1.page = 2;

            }else if (this.addDialogV1.page == 2) {

                this.addDialogV1.page = 3;

            }else if (this.addDialogV1.page == 3) {

                await this.setPermissionsV1();
                await this.loadCompanies();
                this.addDialogV1.show = false;
            }

            this.addDialogV1.working = false;
        },

        validateAddDialogV1() {
            if (this.addDialogV1.page == 1) {
                if (this.addDialogV1.companyId.length > 7) {
                    this.addDialogV1.valid = true;
                }else {
                    this.addDialogV1.valid = false;
                }
                return;
            }

            this.addDialogV1.valid = true;
        },

        isAddNextDisabledV1() {
            return !this.addDialogV1.valid;
        },

        async setPermissionsV1() {

            // TODO set screening provider
            // TODO type: config
            

            console.log('add', this.addDialogV1);

            let data = undefined, res = undefined;
            let companyId = this.addDialogV1.companyId;

            const roles = {
                admin: { type: 'admin', id: '' },
                privileged: { type: 'privileged', id: '' },
                regular: { type: 'regular', id: '' },
                template: { type: 'template', id: '' },
            };

            this.updateAddStatus('Fetching company roles from Verified API');
            await new Promise(r => setTimeout(r, 1000));

            // Get roles
            res = await this.axiosGet(`/api/auth/roles?filters=` + encodeURIComponent(`{"and":[{"op":"regex","value":"^/roles/.+/companies/${companyId}$","field":"name"}]}`) + `&from=0&limit=10&sort={"name":1}`);
            console.log('RRes', res);
            for (const i in res.data) {
                if (res.data[i].name.includes('admin')) {
                    roles.admin.id = res.data[i].id;
                }else if (res.data[i].name.includes('privileged')) {
                    roles.privileged.id = res.data[i].id;
                }else if (res.data[i].name.includes('regular')) {
                    roles.regular.id = res.data[i].id;
                }
            }

            this.updateAddStatus('Adding descriptors');
            await new Promise(r => setTimeout(r, 1000));

            // Add all descriptors for all roles
            const descriptors = [
                { name: 'aml-portal-v1-org', public: false },
                { name: 'aml-portal-v1-person', public: false },
                { name: 'aml-portal-v1-report', public: false },
                { name: 'aml-portal-v1-internal', public: false },
                { name: 'aml-portal-v1-sign', public: false },
                { name: 'aml-portal-v1-direct', public: false },
            ];
            for (const i in descriptors) {
                for (const j in roles) {
                    if (roles[j].type != 'template') {

                        console.log('adding', roles[j]);

                        this.updateAddStatus('Adding descriptor ' + descriptors[i].name);
                        await new Promise(r => setTimeout(r, 250));

                        data = { actions: [ 'GET' ], headers: [ { key: 'x-namespace', value: `/companies/${companyId}` } ], resource: `^/envelope-descriptors/${descriptors[i].name}$` };
                        await this.axiosPost(`/api/auth/roles/${roles[j].id}/permissions`, data);

                        data = { actions: [ 'POST' ], headers: [ { key: 'x-namespace', value: `/companies/${companyId}` } ], resource: `^/envelope-descriptors/${descriptors[i].name}/envelopes$` };
                        await this.axiosPost(`/api/auth/roles/${roles[j].id}/permissions`, data);

                        if (descriptors[i].public) {
                            data = { actions: [ 'POST' ], headers: [ { key: 'x-namespace', value: `/companies/${companyId}` } ], resource: `^/envelope-descriptors/${descriptors[i].name}/tokens$` };
                            await this.axiosPost(`/api/auth/roles/${roles[j].id}/permissions`, data);
                        }

                    }
                }
            }

            this.updateAddStatus('Updating roles');
            await new Promise(r => setTimeout(r, 5000));

            // Get roles again since there will now be a public role
            res = await this.axiosGet(`/api/auth/roles?filters=` + encodeURIComponent(`{"and":[{"op":"regex","value":"^/roles/.+/companies/${companyId}$","field":"name"}]}`) + `&from=0&limit=10&sort={"name":1}`);
            for (const i in res.data) {
                if (res.data[i].name.includes('tmpl')) {
                    roles.template.id = res.data[i].id;
                }
            }

            // Set BankID AML permissions for all roles
            if (this.addDialogV1.featureModel.flowNorway) {
                const permissions = [
                    { method: 'GET', resource: '^/bankid-oidc/organization/noauth', public: false },
                    { method: 'GET', resource: '^/bankid-oidc/person/noauth$', public: false },
                    { method: 'GET', resource: '^/bankid-oidc/person/pdf$', public: false },
/*                     { method: 'GET', resource: '^/bankid-oidc/authentication', public: true },
                    { method: 'GET', resource: '^/bankid-oidc/aml', public: true } */
                ];
                for (const i in roles) {

                    this.updateAddStatus('Adding BankID AML persmissions for ' + roles[i].type);
                    await new Promise(r => setTimeout(r, 250));

                    for (const j in permissions) {
                        if (!(!permissions[j].public && roles[i].type == 'template') && !(permissions[j].public && roles[i].type != 'template')) {
                            data = { actions: [permissions[j].method], resource: permissions[j].resource };
                            await this.axiosPost(`/api/auth/roles/${roles[i].id}/permissions`, data);
                        }
                    }
                }
            }

            if (this.addDialogV1.featureModel.flowSweden) {
                const permissions = [
                    { method: 'GET', resource: '^/bisnode/company', namespace: true, public: false },
                    { method: 'GET', resource: '^/bisnode/person', namespace: true, public: false },
                ];

                for (const i in roles) {

                    this.updateAddStatus('Adding Trapets/Bisnode persmissions for ' + roles[i].type);
                    await new Promise(r => setTimeout(r, 250));

                    for (const j in permissions) {
                        if (!(!permissions[j].public && roles[i].type == 'template') && !(permissions[j].public && roles[i].type != 'template')) {
                            data = { actions: [permissions[j].method], resource: permissions[j].resource };
                            if (permissions[j].namespace) data.headers = [ { key: 'x-namespace', value: `/companies/${companyId}` } ];
                            await this.axiosPost(`/api/auth/roles/${roles[i].id}/permissions`, data);
                        }
                    }
                }
            }



            this.updateAddStatus('Enabling link to AML-portal');
            await new Promise(r => setTimeout(r, 1500));

            // Get and set features
            data = { features: [] };
            res = await this.axiosGet(`/api/companies/${companyId}/settings`);
            for (const i in res.data.features) {
                data.features.push(res.data.features[i]);
            }
            data.features.push('aml-portal');
            if (this.addDialogV1.featureModel.flowSweden) data.features.push('trapets-credentials');
            await this.axiosPut(`/api/companies/${companyId}/settings`, data);

            // Set AML portal link
            data = { amlPortalLink: [ "web/aml-portal/", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "" ] };
            await this.axiosPut(`/api/companies/${companyId}/settings`, data);


            this.updateAddStatus('Adding company and configuration');

            const company = {
                type: 'prod',
                name: this.addDialogV1.name1 + ' ' + this.addDialogV1.name2,
                key: this.addDialogV1.key,
                id: companyId
            };
            await backend.adminAddCompany(company);

            const pid = company.key + '-' + company.id;
            const config = configTools.getDefault();

            config.portal.useV1 = true;

            if (this.addDialogV1.featureModel.flowNorway) {
                config.search.org.no.enabled = true;
                config.search.org.no.default = true;
                config.screening.nordicProvider = 'baml';
                config.screening.globalProvider = 'baml';
                config.params.defaultLanguage = 'nb_NO';
                config.params.defaultPrefix = '+47';
                this.enableDefaultFlow(config, 'no-org-baml', true);
            }else {
                config.search.org.no.enabled = false;
                config.search.org.no.default = false;
                this.enableDefaultFlow(config, 'no-org-baml', false);
            }
            if (this.addDialogV1.featureModel.flowSweden) {
                config.search.org.se.enabled = true;
                config.search.org.se.default = true;
                config.params.defaultLanguage = 'sv_SE';
                config.params.defaultPrefix = '+46';
                this.enableDefaultFlow(config, 'se-org-bistrap', true);
                this.enableLanguage(config, 'sv_SE', true);
                if (!this.addDialogV1.featureModel.flowNorway) this.enableLanguage(config, 'nb_NO', false);
            }
            if (this.addDialogV1.featureModel.personFlow) {
                config.search.person.enabled = true;
                this.enableDefaultFlow(config, 'zz-person', true);
            }
            if (this.addDialogV1.featureModel.personFlowKyc) {
                config.search.person.enabled = true;
                this.enableDefaultFlow(config, 'yy-person', true);
            }

            // Foreign company customers is default for all portals
            config.search.forreign.enabled = true;
            this.enableDefaultFlow(config, 'xx-org', true);

            await backend.adminSaveConfig(config, pid);

            this.updateAddStatus('Adding default forms');

            const forms = [];
            forms.push(idc01.getForm());
            forms.push(kyc00.getForm());
            forms.push(kyc02.getForm());

            if (this.addDialogV1.featureModel.personFlowKyc) {
                forms.push(kycp01.getForm())
            }

            await backend.adminAddForms(forms, pid);

            await this.loadCompanies();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        enableDefaultFlow(config, key, value) {
            for (const i in config.defaultFlows) {
                if (config.defaultFlows[i].key == key) {
                    config.defaultFlows[i].enabled = value;
                }
            }
        },

        enableLanguage(config, key, value) {
            for (const i in config.locales) {
                if (config.locales[i].key == key) {
                    config.locales[i].enabled = value;
                }
            }
        },

        //#region Helpers -----------------------------------------------------------------------------------------------------

        async axiosGet(route) {
            const response = await axios({
                url: route,
                method: 'GET',
                headers: this.getHeaders(false),
                withCredentials: "same-origin"
            });
            return response;
        },

        async axiosPost(route, data, xname) {
            const response = await axios({
                url: route,
                method: 'POST',
                headers: this.getHeaders(xname || false),
                data: data,
                withCredentials: "same-origin"
            });
            return response;
        },

        async axiosPut(route, data) {
            const response = await axios({
                url: route,
                method: 'PUT',
                headers: this.getHeaders(false),
                data: data,
                withCredentials: "same-origin"
            });
            return response;
        },

        getHeaders(xname) {
            const headers = {
                'content-type': 'application/json',
                'accept': 'application/json',
                'authorization': 'JWT ' + v2lib.personalToken,
            };
            if (xname) headers['x-namespace'] = '/companies/' + (this.addDialogV1.companyId || this.addDialog.companyId);
            return headers;
        },

        updateAddStatus(status) {
            this.addDialog.status = status;
            this.addDialogV1.status = status;
        },

        onNavigate(page) {
            this.currentPage = page;
            this.loadCompanies();
        },

        async loadCompanies() {
            this.loading = true;

            const from = (Number(this.currentPage) - 1) * Number(this.itemsPerPage);
            const limit = this.itemsPerPage;

            const response = await backend.adminGetCompanies(from, limit);
            this.companies = [];
            for (const i in response.data.companies) {
                if (response.data.companies[i].type != 'demo') {
                    this.companies.push(response.data.companies[i]);
                }
            }

            this.totalCount = response.data.count;
            this.totalPages = Math.ceil(Number(this.totalCount) / Number(this.itemsPerPage));

            console.log('companies', this.companies);
            this.loading = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    async created() {
        this.currentPage = 1;
        this.loadCompanies();
    }
}
</script>
<style scoped>

</style>