<template>
<div @mouseup="onResizeMouseUp">     
    <div>

        <slot name="menu" />

    </div>
    <div class="frv2-opts-base">

        <div class="frv2-split-l" :class="[
            { 'frv2-split-l-phone' : phoneMode },
        ]" @click="$emit('deselect')">

            <div>

                <slot name="stepper" />


                <div style="padding-top: 90px;" :style="getMaxWidthStyle()" :class="[
                    designer ? 'form-page' : pages[pageIndex].mode,
                    { 'no-stepper' : !pages[pageIndex].showStepper && !designer },
                    { 'frv2-designer-phone' : phoneMode },
                ]">

                    <slot name="renderer" />

                    <div class="frv2-phone-padding" v-if="phoneMode" />

                </div>

            </div>


            <div class="frv2-fakephone" v-if="phoneMode && (editorModel.view || editorModel.edit)" :style="'left: ' + getPhoneMargin() + 'px;'">
                <div class="frv2-fakephone-xt" />
                <div class="frv2-fakephone-top" />
                <div class="frv2-fakephone-con" />
                <div class="frv2-fakephone-bot" />
                <div class="frv2-fakephone-xb" />
            </div>


        </div>


        <div class="frv2-split-r" :style="'width: ' + rightWidth + 'px;'">

            

            <div class="frv2-sb-resize"
                @mouseenter="onResizeEnter"
                @mouseleave="onResizeLeave"
                @mousedown="onResizeMouseDown"
                @mouseup="onResizeMouseUp"
                :class="{'frv2-sb-high': resizeHighlight }"
            />

            <div style="flex-grow: 100; margin-left: -4px;">

                <div class="frv2-sb-top">

                    <div class="frv2-sb-top-tabs">
                        <div v-if="!customTab"
                            @click="$emit('menu', 'input')"
                            class="frv2-sb-top-tab"
                            :class="{'frv2-sb-top-tab-open': showPropsInput }"
                        >
                            {{ 'Input' }}
                        </div>
                        <div v-if="!customTab && !editorModel.pdf" 
                            @click="$emit('menu', 'page')"
                            class="frv2-sb-top-tab"
                            :class="{'frv2-sb-top-tab-open': showPropsPage }"
                        >
                            {{ 'Page ' + pageIndex }}
                        </div>
                        <div v-if="editorModel.pdf"
                            @click="$emit('menu', 'page')"
                            class="frv2-sb-top-tab"
                            :class="{'frv2-sb-top-tab-open': showPropsPage }"
                        >
                            {{ 'PDF' }}
                        </div>
                        <div v-if="customTab"
                            @click="$emit('tab', customTab)"
                            class="frv2-sb-top-tab"
                            :class="{'frv2-sb-top-tab-open': customTabSelected }"
                        >
                            {{ customTab }}
                        </div>
                    </div>

                    <div class="frv2-sb-top-lang" />

                </div>
                <div class="frv2-sb-con">

                    <div class="frv2-sb-props">
                        <slot name="props" />
                    </div>

                    <div class="frv2-sb-menu">

                        <div class="frv2-sb-btn" @click="onButtonClick('language', 3)" :class="[
                            {'frv2-sb-btn-active': menuModel['language'] },
                            {'frv2-sb-btn-disabled': $parent.accessLevel < 3 },
                        ]">
                            <vap-icon :icon="'locale'" :color="'ext'" />
                        </div>

                        <!-- Flow -->
                        <div class="frv2-sb-btn" @click="onButtonClick('flow', 3)" :class="[
                            {'frv2-sb-btn-active': menuModel['flow'] },
                            {'frv2-sb-btn-accept': logicActive },
                            {'frv2-sb-btn-disabled': $parent.accessLevel < 3 },
                        ]" @mouseenter="onButtonEnter('flow')">
                            <vap-icon :icon="'people'" :color="'ext'" />
                        </div>

                        <!-- Resources -->
                        <div class="frv2-sb-btn" @click="onButtonClick('resources', 3)" :class="[
                            {'frv2-sb-btn-active': menuModel['resources'] },
                            {'frv2-sb-btn-accept': logicActive },
                            {'frv2-sb-btn-disabled': $parent.accessLevel < 3 },
                        ]" @mouseenter="onButtonEnter('resources')">
                            <vap-icon :icon="'resource'" :color="'ext'" />
                        </div>

                        <!-- Logic -->
                        <div class="frv2-sb-btn" @click="onButtonClick('logic', 3)" :class="[
                            {'frv2-sb-btn-active': menuModel['logic'] },
                            {'frv2-sb-btn-accept': logicActive },
                            {'frv2-sb-btn-disabled': $parent.accessLevel < 3 },
                        ]" @mouseenter="onButtonEnter('logic')">
                            <vap-icon :icon="'logic'" :color="'ext'" />
                        </div>

                        <!-- Modules -->
                        <div class="frv2-sb-btn" @click="onButtonClick('modules', 3)" :class="[
                            {'frv2-sb-btn-active': menuModel['modules'] },
                            {'frv2-sb-btn-accept': logicActive },
                            {'frv2-sb-btn-disabled': $parent.accessLevel < 3 },
                        ]" @mouseenter="onButtonEnter('modules')">
                            <vap-icon :icon="'modules'" :color="'ext'" />
                        </div>

                        <div class="frv2-sb-btn" @click="onButtonClick('attachments', 3)" :class="[
                            {'frv2-sb-btn-active': menuModel['attachments'] },
                            {'frv2-sb-btn-accept': logicActive },
                            {'frv2-sb-btn-disabled': $parent.accessLevel < 3 },
                        ]">
                            <vap-icon :icon="'attachment'" :color="'ext'" />
                        </div>

                        <!-- Data Model -->
                        <div class="frv2-sb-btn" @click="onButtonClick('datamodel', 3)" :class="[
                            {'frv2-sb-btn-active': menuModel['datamodel'] },
                            {'frv2-sb-btn-accept': logicActive },
                            {'frv2-sb-btn-disabled': $parent.accessLevel < 3 },
                        ]" @mouseenter="onButtonEnter('datamodel')">
                            <vap-icon :icon="'orgchart'" :color="'ext'" />
                        </div>

                        <div class="frv2-sb-btn" @click="onButtonClick('settings', 2)" :class="[
                            {'frv2-sb-btn-active': menuModel['settings'] },
                            {'frv2-sb-btn-accept': logicActive },
                            {'frv2-sb-btn-disabled': $parent.accessLevel < 2 },
                        ]">
                            <vap-icon :icon="'settings'" :color="'ext'" />
                        </div>

                        <div style="flex-grow: 100" />

                        <div v-if="!$parent.temporaryDisable" class="frv2-sb-btn" @click="$emit('menu', 'help')" :class="[
                            {'frv2-sb-btn-active': menuModel['help'] },
                            {'frv2-sb-btn-accept': logicActive },
                        ]">
                            <vap-icon :icon="'help-o'" :color="'ext'" />
                        </div>
                        <div v-if="!$parent.temporaryDisable" class="frv2-sb-btn" @click="$emit('menu', 'info')" :class="[
                            {'frv2-sb-btn-active': menuModel['info'] },
                            {'frv2-sb-btn-accept': logicActive },
                        ]">
                            <vap-icon :icon="'info'" :color="'ext'" />
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'DesignerLayout',
    props: {
        designer: { type: Boolean, default: false },
        logicActive: { type: Boolean, default: false },
        phoneMode: { type: Boolean, default: false },
        pages: { type: Array, default: () => { return [] }},
        pageIndex: { type: [String, Number], default: 0 },
        showPropsInput: { type: Boolean, default: false },
        showPropsPage: { type: Boolean, default: false },
        menuModel: { type: Object, default: () => { return {} }},
        editorModel: { type: Object, default: null },
        customTab: { type: String, default: '' },
        customTabSelected: { type: Boolean, default: false },
    },
    data() {
        return {
            minRightWidth: 400,
            minLeftWidth: 500,
            rightWidth: 500,
            rightWidthBar: 510,
            resizeMouseOver: false,
            resizeMouseDown: false,
            resizeDownPoint: 0,
            resizeHighlight: false,
        }
    },
    methods: {

        onButtonClick(type, accessLevel) {
            if (this.$parent.accessLevel < accessLevel) return;
            if (this.logicActive) {
                this.$emit('menuconnect', type);
            }else {
                this.$emit('menu', type);
            }
        },

        getMaxWidthStyle() {
            if (this.editorModel.pdf) return 'max-width: 1000px;';
            if (this.editorModel.landing) return 'max-width: 700px;';
            return '';
        },

        onButtonEnter(type) {
            if (!this.logicActive) return;
            this.$emit('menu', type);
        },

        onResizeEnter(event) {
            const self = this;
            this.resizeMouseOver = true;
            setTimeout(function() {
                self.onResizeDelayed();
            }, 500);
        },

        onResizeDelayed() {
            if (this.resizeMouseOver) this.resizeHighlight = true;
        },

        onResizeLeave(event) {
            this.resizeMouseOver = false;
            if (!this.resizeMouseDown) this.resizeHighlight = false;
        },

        onResizeMouseDown(event) {
            event.preventDefault();
            this.resizeDownPoint = event.pageX;
            this.resizeHighlight = true;
            this.resizeMouseDown = true;
        },

        onResizeMouseUp(event) {
            this.resizeMouseDown = false;
            this.resizeHighlight = false;
        },

        handleResize(eventX) {
            const diff = eventX - this.resizeDownPoint;
            this.rightWidth -= diff;
            this.resizeDownPoint += diff;
            if (this.rightWidth < this.minRightWidth) this.rightWidth = this.minRightWidth;
            if ((window.innerWidth - this.rightWidth) < this.minLeftWidth) this.rightWidth = (window.innerWidth - this.minLeftWidth);
            this.rightWidthBar = this.rightWidth + 10;
            this.$emit('resize', this.rightWidthBar);

            if (window.innerWidth - this.rightWidth < 900) {
                if (!this.phoneMode) this.$emit('phone', true);
                this.$root.phoneMode = true; 
            }else {
                if (this.phoneMode) this.$emit('phone', false);
                this.$root.phoneMode = false;
            }
        },

        getPhoneMargin() {
            const totalMargin = window.innerWidth - this.rightWidth - 450;
            return (totalMargin / 2);
        },

        onMouseMove(x, y) {
            if (this.resizeMouseDown) this.handleResize(x);
        },

    },
    mounted() {
        document.body.addEventListener('mousemove', e => {
            this.onMouseMove(e.pageX, e.pageY);
        });
    },
    destroyed() {
        //this.enableAlwaysScroll(false);
        this.enableScrolling(true);
        window.removeEventListener('focus', this.updateClipboard);
        this.$parent.$parent.landing = false;
    }
}
</script>
<style scoped>

.frv2-opts-base {
    position: fixed;
    display: flex;
    top: 62px;
    bottom: 0px;
    left: 0px;
    width: 100%;
}

.frv2-split-l {
  /* Foreground, Background */
  scrollbar-color: var(--fb-scroll-f) var(--fb-scroll-b);
}
.frv2-split-l::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
.frv2-split-l::-webkit-scrollbar-thumb { /* Foreground */
  background: var(--fb-scroll-f);
  border-radius: 5px;
}
.frv2-split-l::-webkit-scrollbar-track { /* Background */
  background: var(--fb-scroll-b);
}


.frv2-split-l {
    flex-grow: 100;
    height: 100%;
    position: relative;
    overflow-y: scroll;
}
.frv2-split-l-phone {
    padding-top: 120px;
    box-sizing: border-box;
}
.frv2-phone-padding {
    float: left;
    width: 100%;
    height: 300px;
    /* background-color: red; */
}

.frv2-designer-phone {
    max-width: 400px;
}

.frv2-fakephone {
    position: fixed;
    width: 440px;
    height: 100%;
    max-height: 100%;
    top: 0px;
    pointer-events: none;
}
.frv2-fakephone-top {
    height: 50px;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    background-color: #222222;
}
.frv2-fakephone-con {
    height: 800px;
    border-left: solid 20px #222222;
    border-right: solid 20px #222222;
}
.frv2-fakephone-bot {
    height: 50px;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
    background-color: #222222;
}

.frv2-fakephone-xt {
    height: 130px;
    background-color: var(--page-background);
}
.frv2-fakephone-xb {
    height: 800px;
    background-color: var(--page-background);
    z-index: 101;
}


.frv2-split-r {
    flex-shrink: 0;
    height: 100%;
    display: flex;
    background-color: var(--fb-menu-a);
}


.frv2-sb-resize {
    flex-shrink: 0;
    width: 4px;
    height: 100%;
    cursor: col-resize;
    z-index: 99;
}
.frv2-sb-high {
    background-color: #007FD4;
}

.frv2-sb-top {
    display: flex;
    height: 50px;
    background-color: var(--fb-menu-c);
}

.frv2-sb-top-lang {
    flex-shrink: 0;
    width: 50px;
}

.frv2-sb-top-tabs {
    flex-grow: 100;
    display: inline-flex;
    padding-left: 10px;
    padding-right: 20px;
}
.frv2-sb-top-tab {
    display: inline-block;
    padding-top: 8px;
    height: 36px;
    margin-top: 14px;
    flex: 1 100%;
    text-align: center;
    color: var(--fb-text-2);
    border: solid 1px var(--fb-menu-a);
    border-bottom: none;
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-sb-top-tab-open {
    background-color: var(--fb-menu-a);
}

.frv2-sb-con {
    display: flex;
    height: calc(100% - 50px);
}


.frv2-sb-props {
    flex-grow: 100;
    width: 100%;
    height: 100%;

    background: var(--fb-menu-a);;
    overflow-y: scroll;
  scrollbar-color: var(--fb-scroll-f) var(--fb-scroll-b);
}
.frv2-sb-props::-webkit-scrollbar {
  width: 10px; /* Mostly for vertical scrollbars */
  height: 10px; /* Mostly for horizontal scrollbars */
}
.frv2-sb-props::-webkit-scrollbar-thumb { /* Foreground */
  background: var(--fb-scroll-f);
  border-radius: 5px;
}
.frv2-sb-props::-webkit-scrollbar-track { /* Background */
  background: var(--fb-scroll-b);
}

.frv2-sb-menu {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: 46px;
    height: 100%;
    background-color: var(--fb-header);
}

.frv2-sb-btn {
    width: 46px; height: 46px;
    padding-top: 5px;
    padding-left: 4px;
    fill: gray;
    cursor: pointer;
    box-sizing: border-box;
}
.frv2-sb-btn:hover {
    fill: white;
}

.frv2-sb-btn-active {
    fill: white;
    background-color: var(--fb-menu-c);
}

.frv2-sb-btn-accept {
    fill: #13AA52;
}

.frv2-sb-btn-disabled {
    fill: var(--fb-menu-b);
    cursor: not-allowed;
}
.frv2-sb-btn-disabled:hover {
    fill: var(--fb-menu-b);
}

</style>