<template>
<div>

    <PropertyItem padded :header="'Phone properties'" :icon="'phone'">

        <DesignerGroup c12>
            <DesignerLabel :text="'Default prefix'" />
            <DesignerDropdown v-model="input.initial" :options="defaultOptions" @before="$emit('propinput', 'drop', 'phone_prefix', $event)" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Placeholder'" />
            <DesignerInput v-model="input.placeholder" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'phone_ph', $event)" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';

export default {
    name: 'PhoneProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
    },
    props: {
        input: { type: Object, default: null },
    },
    data() {
        return {
            defaultOptions: [
                { value: 'system', display: 'System default' },
            ],
        }
    },
}
</script>