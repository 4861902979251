const defaultFlows = {

    getFlow() {
        return {
            key: 'no-org-baml-klp',
            name: 'KLP Flow',
            type: 'org',
            countries: ['no', 'gb'],
            tabs: {
                overview: { key: 'common_overview', icon: 'checklist' },
                company: { key: 'company_company', icon: 'company' },
                ownership: { key: 'company_owner_structure', icon: 'orgchart' },
                persons: { key: 'common_persons', icon: 'people' },
                /* report: { key: 'company_final_report', icon: 'verified' } */
            },
            title: {
                en_EN: 'KLP Flow',
                nb_NO: 'Flyt for endring av organisasjonsnummer',
            },
            steps: [
                { key: 'no-baml-org' },
                { key: 'no-klp-kyc' }, 
                { key: 'no-klp-pension' },
                { key: 'no-klp-connection' },
                //{ key: 'no-klp-kar' }, 
            ],
            statuses: [
                {
                    key: 'c-org-c',
                    text: { 
                        en_EN: 'Awaiting company search',
                        nb_NO: 'Avventer selskapssøk'
                    },
                    triggers: []
                },
                {
                    key: 'g-form-a',
                    text: {
                        en_EN: 'Awaiting sending form',
                        nb_NO: 'Avventer utsendelse skjema'
                    },
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: false },
                    ]
                },
                {
                    key: 'g-form-b',
                    text: {
                        en_EN: 'Awaiting filling form',
                        nb_NO: 'Avventer utfylling av skjema'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc01', trg: 'sent', rev: false },
                    ]
                },
                {
                    key: 'g-form-c',
                    text: {
                        en_EN: 'Awaiting signing form',
                        nb_NO: 'Avventer signering av skjema'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc01', trg: 'filled', rev: false },
                    ]
                },
                {
                    key: 'r-rep-x',
                    text: {
                        en_EN: 'Completed',
                        nb_NO: 'Fullført'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc01', trg: 'signed', rev: false },
                    ]
                }
            ],
            events: {
                items: [
                    {
                        key: 'event01',
                        text: {
                            en_EN: 'Company search performed by {{createdUser}}',
                            nb_NO: 'Selskapssøk utført av {{createdUser}}'
                        },
                        date: { type: 'org', date: 'searchDate' },
                        triggers: [
                            { type: 'org', trg: 'has-data', rev: false },
                        ]
                    },
                    {
                        key: 'event02',
                        list: 'idc',
                        text: {
                            en_EN: 'ID-control completed by {{idc-name}}'
                        },
                        date: { type: 'idc', date: 'date' },
                        triggers: [
                            { type: 'idc', trg: 'done', rev: false },
                        ]
                    }
                ]
            },
        };
    },

    //#region Default steps -----------------------------------------------------------------------------------------------
    getKlpKyc() {
        return {
            key: 'no-klp-kyc',
            name: 'Default BankID AML Self-Declaration Form',
            hasCheckout: true,
            form: 'kyc01',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'kyc01',
                    triggers: [
                        { type: 'form', key: 'kyc01', trg: 'sent', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form',
                        nb_NO: 'Start skjema'
                    },
                    hint: {
                        en_EN: 'Open the form'
                    }
                },
                {
                    icon: 'file-sent',
                    main: false,
                    action: 'preview',
                    key: 'kyc01',
                    triggers: [
                        { type: 'form', key: 'kyc01', trg: 'filled', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'kyc01',
                    triggers: [
                        { type: 'form', key: 'kyc01', trg: 'sent', rev: false },
                        { type: 'form', key: 'kyc01', trg: 'signed', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'This action will send a reminder to the recipient'
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'form-reset',
                    key: 'kyc01',
                    triggers: [
                        { type: 'form', key: 'kyc01', trg: 'sent', rev: false },
                    ],
                    text: {
                        en_EN: 'Reset form'
                    },
                    hint: {
                        en_EN: 'This action will reset the form so that a new one can be sent'
                    }
                }
            ],
            title: {
                en_EN: 'Overtakelse av pensjonsforpliktelser med egenerklæringsskjema',
                nb_NO: 'Overtakelse av pensjonsforpliktelser med egenerklæringsskjema',
            },
            hint: {
                en_EN: 'Sender ut egenerklæringsskjema inkludert skjema for overtakelse av pensjonsforpliktelser',
                nb_NO: 'Sender ut egenerklæringsskjema inkludert skjema for overtakelse av pensjonsforpliktelser'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Awaiting filling by {{kyc01-nextName}}',
                                nb_NO: 'Avventer utfylling av {{kyc01-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc01', trg: 'sent', rev: false },
                                { type: 'form', key: 'kyc01', trg: 'filled', rev: true }
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting signing by {{kyc01-nextName}}',
                                nb_NO: 'Avventer signering av {{kyc01-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc01', trg: 'filled', rev: false },
                                { type: 'form', key: 'kyc01', trg: 'signed', rev: true }
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Signed by {{kyc01-signerNames}}',
                                nb_NO: 'Signert av {{kyc01-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc01', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getKlpKar() {
        return {
            key: 'no-klp-kar',
            name: 'Default BankID AML Self-Declaration Form',
            actions: [
                {
                    icon: 'search',
                    main: true,
                    action: 'form-start',
                    key: 'kyc01',
                    triggers: [
                        { type: 'form', key: 'kyc01', trg: 'sent', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form',
                        nb_NO: 'Gjør oppslag'
                    },
                    hint: {
                        en_EN: 'Open the form'
                    }
                }
            ],
            title: {
                en_EN: 'KYC',
                nb_NO: 'Oppslag i KAR-registeret',
            },
            hint: {
                en_EN: 'Sender ut egenerklæringsskjema inkludert skjema for overtakelse av pensjonsforpliktelser',
                nb_NO: 'Sjekk om et kontunummer tilhører en person eller selskap.'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Awaiting filling by {{kyc01-nextName}}',
                                nb_NO: 'Avventer utfylling av {{kyc01-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc01', trg: 'sent', rev: false },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting signing by {{kyc01-nextName}}',
                                nb_NO: 'Avventer signering av {{kyc01-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc01', trg: 'filled', rev: false },
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Signed by {{kyc01-signerNames}}',
                                nb_NO: 'Signert av {{kyc01-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc01', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getKlpPension() {
        return {
            key: 'no-klp-pension',
            name: 'Default BankID AML Self-Declaration Form',
            hasCheckout: true,
            formList: false,
            form: 'klp01', 
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'klp01',
                    triggers: [
                        { type: 'form', key: 'klp01', trg: 'sent', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form',
                        nb_NO: 'Start skjema'
                    },
                    hint: {
                        en_EN: 'Open the form'
                    }
                },
                {
                    icon: 'file-sent',
                    main: false,
                    action: 'preview',
                    key: 'klp01',
                    triggers: [
                        { type: 'form', key: 'klp01', trg: 'filled', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'klp01',
                    triggers: [
                        { type: 'form', key: 'klp01', trg: 'sent', rev: false },
                        { type: 'form', key: 'klp01', trg: 'signed', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'This action will send a reminder to the recipient'
                    }
                },
/*                 {
                    icon: 'sign',
                    main: false,
                    action: 'form-signature',
                    key: 'klp01',
                    triggers: [
                        { type: 'form', key: 'klp01', trg: 'signed', rev: false },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'This action will send a reminder to the recipient'
                    }
                }, */
                {
                    icon: 'delete',
                    main: false,
                    action: 'form-reset',
                    key: 'klp01',
                    triggers: [
                        { type: 'form', key: 'klp01', trg: 'sent', rev: false },
                    ],
                    text: {
                        en_EN: 'Reset form'
                    },
                    hint: {
                        en_EN: 'This action will reset the form so that a new one can be sent'
                    }
                }
            ],
            title: {
                en_EN: 'Pension',
                nb_NO: 'Overtakelser for pensjonsforpliktelser',
            },
            hint: {
                en_EN: 'Sender ut kun skjema for pensjonsforpliktelser',
                nb_NO: 'Sender ut kun skjema for pensjonsforpliktelser'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Awaiting filling by {{klp01-nextName}}',
                                nb_NO: 'Venter på utfylling av {{klp01-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'klp01', trg: 'sent', rev: false },
                                { type: 'form', key: 'klp01', trg: 'filled', rev: true }
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting signing by {{klp01-nextName}}',
                                nb_NO: 'Venter på signering av {{klp01-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'klp01', trg: 'filled', rev: false },
                                { type: 'form', key: 'klp01', trg: 'signed', rev: true }
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Signed by {{klp01-signerNames}}',
                                nb_NO: 'Signert av {{klp01-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'klp01', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                },
/*                 warning: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Signed by {{klp01-signerNames}}',
                                nb_NO: 'Signert av {{klp01-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'klp01', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Signed by {{klp01-signerNames}}',
                                nb_NO: 'Signert av {{klp01-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'klp01', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                } */
            }
        };
    },

    getKlpConnection() {
        return {
            key: 'no-klp-connection',
            name: 'Default BankID AML Self-Declaration Form',
            hasCheckout: true,
            form: 'klp03',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'klp03',
                    triggers: [
                        { type: 'form', key: 'klp03', trg: 'sent', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form',
                        nb_NO: 'Start skjema'
                    },
                    hint: {
                        en_EN: 'Open the form'
                    }
                },
                {
                    icon: 'file-sent',
                    main: false,
                    action: 'preview',
                    key: 'klp03',
                    triggers: [
                        { type: 'form', key: 'klp03', trg: 'filled', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'klp03',
                    triggers: [
                        { type: 'form', key: 'klp03', trg: 'sent', rev: false },
                        { type: 'form', key: 'klp03', trg: 'signed', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'This action will send a reminder to the recipient'
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'form-reset',
                    key: 'klp03',
                    triggers: [
                        { type: 'form', key: 'klp03', trg: 'sent', rev: false },
                        { type: 'form', key: 'klp03', trg: 'signed', rev: true },
                    ],
                    text: {
                        en_EN: 'Reset form'
                    },
                    hint: {
                        en_EN: 'This action will reset the form so that a new one can be sent'
                    }
                }
            ],
            title: {
                en_EN: 'Connection',
                nb_NO: 'Tilslutningserklæring',
            },
            hint: {
                en_EN: 'Sender ut tilslutningserklæring',
                nb_NO: 'Sender ut tilslutningserklæring'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Awaiting filling by {{klp03-nextName}}',
                                nb_NO: 'Venter på utfylling av {{klp03-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'klp03', trg: 'sent', rev: false },
                                { type: 'form', key: 'klp03', trg: 'filled', rev: true }
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting signing by {{klp03-nextName}}',
                                nb_NO: 'Venter på signering av {{klp03-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'klp03', trg: 'filled', rev: false },
                                { type: 'form', key: 'klp03', trg: 'signed', rev: true }
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Signed by {{klp03-signerNames}}',
                                nb_NO: 'Signert av {{klp03-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'klp03', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },
    

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default defaultFlows;