<template>
<div>
    
    <div class="srv2-actions" :class="[
        {'srv2-actions-sharp': sharpCorner },
        {'srv2-actions-disabled': disabled }
    ]">
        <div class="srv2-act srv2-act-btn" v-for="(action, j) in actions" :key="'sa' + j"
        :class="[
            { 'srv2-act-btn-first': j == 0 },
            { 'srv2-act-end': j == actions.length - 1 },
            { 'srv2-act-high': action.highlight },
            { 'srv2-act-disabled': action.disabled },
        ]" @click.stop="onAction(action)"
        >
            <div class="srv2-act-end-1" v-if="j == actions.length - 1" />
            <div class="srv2-act-end-2" v-if="j == actions.length - 1" />
            <div class="srv2-act-icon" v-if="!action.working">
                <vap-icon :icon="action.icon" small absolute :top="4" :right="6" :color="'ext'" />
            </div>
            <div class="srv2-act-spinner" v-if="action.working">
                <vap-spinner small />
            </div>

            <div class="srv2-act-tt" v-if="action.hint && action.hint[$root.locale.key]">
                <vap-tooltip :text="action.hint[$root.locale.key]" />
            </div>

        </div>
    </div>

</div>
</template>
<script>


export default {
    name: 'CardActions',
    props: {
        actions: { type: Array, default: [] },
        sharpCorner: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

        onAction(action) {
            this.hideTooltipX();
            if (action.disabled) return;
            if (this.disabled) return;
            this.$emit('action', action);
        }

    },
    async created() {

    }
}
</script>
<style scoped>

.srv2-actions {
    position: absolute;
    top: 0px; right: 0px;
    display: flex;
    flex-direction: row-reverse;
    height: 40px;
    box-sizing: border-box;
}

.srv2-act {
    height: 36px;
    box-sizing: border-box;
}
.srv2-act:hover {
    background-color: var(--button);
}

.srv2-act-btn {
    position: relative;
    width: 40px;
    border: 1px solid var(--input-border);
    border-top: none;
    border-right: none;
    background-color: var(--page-background);
    z-index: 2;
    cursor: pointer;
}
.srv2-act-btn-first {
    border-top-right-radius: 4px;
}
.srv2-actions-sharp .srv2-act-btn-first {
    border-top-right-radius: 0px;
}

.srv2-act-end {
    position: relative;
    width: 30px;
    margin-right: 17px;
    border-top: none;
    border-bottom-left-radius: 6px;
    -webkit-transform: skew(45deg);
    transform: skew(45deg);
    z-index: 1;
}
.srv2-act-end-1 {
    position: absolute;
    top: 0px; right: -25px;
    width: 40px;
    height: 20px;
    -webkit-transform: skew(-45deg);
    transform: skew(-45deg);
    background-color: var(--page-background);
}
.srv2-act-end-2 {
    position: absolute;
    top: 15px; right: -10px;
    width: 20px;
    height: 20px;
    -webkit-transform: skew(-45deg);
    transform: skew(-45deg);
    background-color: var(--page-background);
}
.srv2-act-end:hover .srv2-act-end-1 {
    background-color: var(--button);
}
.srv2-act-end:hover .srv2-act-end-2 {
    background-color: var(--button);
}

.srv2-act-btn-first .srv2-act-end-1 {
    border-top-right-radius: 4px;
}

.srv2-act-icon {
    position: relative;
    width: 40px;
    fill: var(--text-weak);
}
.srv2-act-end .srv2-act-icon {
    -webkit-transform: skew(-45deg);
    transform: skew(-45deg);
    margin-left: 23px;
}
.srv2-act:hover .srv2-act-icon {
    fill: var(--button-text);
}


.srv2-act-spinner {
    position: absolute;
    top: 26px;
}
.srv2-act-end .srv2-act-spinner {
    -webkit-transform: skew(-45deg);
    transform: skew(-45deg);
}


.srv2-act-high {
    background-color: var(--button);
}
.srv2-act-high .srv2-act-icon {
    fill: var(--button-text);
}
.srv2-act-high .srv2-act-end-1 {
    background-color: var(--button);
}
.srv2-act-high .srv2-act-end-2 {
    background-color: var(--button);
}


.srv2-act-disabled {
    cursor: not-allowed;
}
.srv2-act-disabled .srv2-act-icon {
    fill: var(--text-disabled);
}
.srv2-act-disabled:hover {
    background-color: var(--page-background);
}
.srv2-act-disabled:hover .srv2-act-icon {
    fill: var(--text-disabled);
}
.srv2-act-disabled:hover .srv2-act-end-1 {
    background-color: var(--page-background);
}
.srv2-act-disabled:hover .srv2-act-end-2 {
    background-color: var(--page-background);
}


.srv2-actions-disabled .srv2-act-btn {
    cursor: not-allowed;
}

.srv2-act-tt {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
}
.srv2-act-end .srv2-act-tt {
    right: -20px;
    -webkit-transform: skew(-45deg);
    transform: skew(-45deg);
}

</style>