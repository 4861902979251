import triggerHelper from '../v1flows/triggerHelper';
import v1flows from '../v1helpers/v1flows';
const exportHelper = {

    //#region Loading -----------------------------------------------------------------------------------------------------

    setPossibleClientValues(root, model, options) {

        root.$set(model, 'clientName', true);
        root.$set(options, 'clientName', { locale: { en_EN: 'Client name', nb_NO: 'Kundenavn' } });

        root.$set(model, 'clientNumber', true);
        root.$set(options, 'clientNumber', { locale: { en_EN: 'Client identity', nb_NO: 'Kundeidentifikasjon' } });

        root.$set(model, 'clientStreet', true);
        root.$set(options, 'clientStreet', { locale: { en_EN: 'Address', nb_NO: 'Adresse' } });

        root.$set(model, 'clientZip', true);
        root.$set(options, 'clientZip', { locale: { en_EN: 'Zip code', nb_NO: 'Postnummer' } });

        root.$set(model, 'clientCity', true);
        root.$set(options, 'clientCity', { locale: { en_EN: 'City', nb_NO: 'Poststed' } });

    },

    setPossibleCompanyValues(root, model, options) {

/*         root.$set(model, 'companyName', true);
        root.$set(options, 'companyName', { locale: { en_EN: 'Company name' } });

        root.$set(model, 'companyNumber', true);
        root.$set(options, 'companyNumber', { locale: { en_EN: 'Company number' } });

        root.$set(model, 'clientStatus', true);
        root.$set(options, 'clientStatus', { locale: { en_EN: 'Client status' } }); */

    },

    setPossibleCustomValues(root, model, options) {

        root.$set(model, 'classification', true);
        root.$set(options, 'classification', { locale: { en_EN: 'Kundeklassifisering', nb_NO: 'Kundeklassifisering' } });

        root.$set(model, 'taxCountry', true);
        root.$set(options, 'taxCountry', { locale: { en_EN: 'Skatteland', nb_NO: 'Skatteland' } });

    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------

    getValue(root, key, client) {
        switch (key) {
            case 'clientName': return this.getClientName(client);
            case 'clientNumber': return this.getClientNumber(client);
            case 'clientStreet': return this.getClientStreet(client);
            case 'clientZip': return this.getClientZip(client);
            case 'clientCity': return this.getClientCity(client);

            case 'classification': return this.getClassification(root, client);
            case 'taxCountry': return this.getTaxCountry(root, client);
        }
        return 'UNKNOWN KEY';
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Values ------------------------------------------------------------------------------------------------------

    getClientName(client) {
        return client.name;
    },

    getClientNumber(client) {
        if (client.type == 'person') {
            if (client.person.ssn) return client.person.ssn;
        }else {
            return client.identity;
        }
        return '';
    },

    getClientStreet(client) {
        if (client.type == 'person') {
            if (client.person.street) return client.person.street;
        }else {
            if (client.company.address && client.company.address.business) {
                return client.company.address.business.street;
            }
        }
        return '';
    },

    getClientZip(client) {
        if (client.type == 'person') {
            if (client.person.zip) return client.person.zip;
        }else {
            if (client.company.address && client.company.address.business) {
                return client.company.address.business.zip;
            }
        }
        return '';
    },

    getClientCity(client) {
        if (client.type == 'person') {
            if (client.person.city) return client.person.city;
        }else {
            if (client.company.address && client.company.address.business) {
                return client.company.address.business.city;
            }
        }
        return '';
    },



    getClassification(root, client) {
        
        if (client.suita && client.suita.fields) {

            return this.getCustomText(root, client.flow, 'cuClass', client.suita.fields.cuClass)[root.locale.key];

            return client.suita.fields.cuClass;

        }

    },

    getTaxCountry(root, client) {
        if (client.suita && client.suita.fields) {

            if (client.suita.fields.cuTaxCountry) {
                return client.suita.fields.cuTaxCountry;
            }else {
                return 'Norge';
            }
            

        }
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    getCustomText(root, flowKey, key, value) {
        const flow = v1flows.getFlow(root, flowKey);

        if (flow.fields) {
            for (const i in flow.fields) {
                if (flow.fields[i].key == key) {
                    for (const j in flow.fields[i].values) {
                        if (flow.fields[i].values[j].key == value) {
                            return flow.fields[i].values[j];
                        }
                    }
                }
            }
        }

        return { en_EN: 'CUSTOM VALUE NOT FOUND' };
    }

};
export default exportHelper;