<template>
<div>

    <PopupV1 :header="'Trigger Hit'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div>

                <VFGroup c12>
                    <VFLabel :text="'Type'" />
                    <VFCheckbox inline radio v-model="typeModel" :options="typeOptions" />
                </VFGroup>

                <VFGroup c12>
                    <!-- <VFLabel :text="'Active'" /> -->
                    <VFCheckbox newDesign v-model="activeModel" :text="'Active'" />
                </VFGroup>

                <VFGroup c12>
                    <VFLabel :text="'Description'" />
                    <VFInput v-model="descriptionModel" />
                </VFGroup>

            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="'Trigger Hit'"
                :icon="'check'"
                @clicked="onClick"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import monitorHelper from './monitorHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import VFInput from '../components/form/inputs/VFInput.vue';

import MonitorSelector from './MonitorSelector.vue';

export default {
    name: 'MonitorTestDialog',
    components: {
        PopupV1,
        VFButton,
        VFGroup,
        VFLabel,
        VFCheckbox,
        VFInput,
        MonitorSelector,
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            typeModel: {
                pep: true,
                sanction: false,
            },
            typeOptions: {
                pep: { label: 'PEP' },
                sanction: { label: 'Sanction' },
            },
            activeModel: true,
            descriptionModel: 'Prime Minister of Sweden'
        }
    },
    methods: {

        async onClick() {
            this.dialog.working = true;

            const hitData = {
                referenceId: this.params.person._id,
                created: new Date(),
                searchParams: {
                    givenName: this.params.person.info.firstName,
                    familyName: this.params.person.info.lastName,
                    dateOfBirth: this.params.person.info.dateOfBirth,
                    nationality: this.params.person.info.country || 'NO',
                    ssn: this.params.person.info.ssn,
                },
                hit: {
                    type: this.typeModel.pep ? 'pep' : 'sanction',
                    active: this.activeModel,
                    description: this.descriptionModel,
                    notes: ['match on name only'],
                    matchIndicator: 0.25,
                },
            };
            console.log('hitData', hitData);

            await v1api.testMonitor(this.params.person._id, hitData);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        }

    },
    created() {
        this.loading = false;
    }
}
</script>
<style scoped>

.amlv1-cs-infobox {
    float: left;
    width: 100%;
    padding: 5px 20px;
    padding-top: 15px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    box-sizing: border-box;
}

.amlv1-cs-infoitem {
    float: left;
    width: 50%;
    margin-bottom: 8px;
    box-sizing: border-box;
}

.amlv1-cs-infoitem-d {
    font-size: 14px;
    color: var(--text-weak);
}

.amlv1-cs-infoitem-v {
    font-size: 17px;
    font-weight: 500;
    color: var(--text-title);
}

.amlv1-cs-infoitem-v-disabled {
    color: var(--text-disabled);
}

</style>