<template>
<div :key="'h' + pageId">
    
    <div v-if="expanded" class="vap-pinfocard">
        
        <div class="vap-infocard-line" v-for="(line, i) in data" :key="'hcl' + i">
            <div class="vap-infocard-left">
                <vap-icon :icon="line.icon" :color="'var(--help-text)'" />
            </div>
            <div class="vap-infocard-content">
                <div class="vap-infocard-head">
                    <label>{{ line.header[$root.locale.key] }}</label>
                </div>
                <div class="vap-infocard-text">
                    <vap-text-editor :value="line.text[$root.locale.key]" />
                </div>
            </div>
        </div>

    </div>

    <div v-if="!expanded" class="vap-pinfocard-icon">
        <vap-icon :icon="'help-o'" :color="'var(--help-text)'" action :top="2" :right="2" @clicked="onExpandClicked" :key="'id3'" >
            <!-- <template v-slot:tooltip><vap-tooltip :text="'Vis hjelpetekst'" /></template> -->
        </vap-icon>
    </div>

</div>
</template>

<script>

export default {
    name: 'FormInfoCardPhone',
    components: {
        
    },
    props: {
        data: {
            type: Array,
            default: null
        },
        designer: {
            type: Boolean,
            default: false
        },
        helpKey: {
            type: String,
            default: ''
        },
        pageId: {
            type: String,
            default: 'h'
        }
    },
    data() {
        return {
            expanded: false,
            hovering: false
        }
    },
    methods: {

        onExpandClicked() {
            //this.expanded = !this.expanded;
        },

    },
    async created() {
        
    }
}
</script>

<style scoped>

.vap-pinfocard {
    margin-bottom: 20px;
    padding: 5px 20px;
    padding-top: 20px;
    color: var(--help-text);
    border-radius: 3px;
    /* border: solid 1px var(--help-border); */
    background-color: var(--help-background);
}

.vap-infocard-line {
    display: flex;
}


.vap-infocard-left {
    width: 40px;
    flex-shrink: 0;
}

.vap-infocard-content {
    flex-grow: 100;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 15px;
    line-height: 22px;
}

.vap-infocard-head {
    min-height: 18px;
    font-weight: 500;;
}


.vap-pinfocard-icon {
    position: fixed;
    width: 40px; height: 40px;
    top: 0px; right: 0px;
    border-radius: 3px;
    background-color: var(--help-background);
}
.vap-pinfocard-icon:hover {
    background-color: red;
}

</style>
