<template>
<div>

    <FormDesigner ref="designer" v-if="formData" :formData="formData" @save="onSave" @changed="onChanged" @history="onChangeFormData" />

</div>
</template>

<script>
import api from '@/portalapi';

import formHelper from '@/components/form/formHelper';
import FormDesigner from '@/components/form/FormDesigner';

export default {
    name: 'FormRenderTest',
    components: {
        FormDesigner,
    },
    data() {
        return {
            formData: null,
        }
    },
    methods: {

        async onSave(data) {
            const response = await api.updateForm(this.$route.query.key, data);
            this.formData = response.formData;
        },

        onChangeFormData(data) {
            this.formData = { ...data, ...{ _id: this.formData._id, key: this.formData.key, } };
        },

        onBeforeUnload(e) {
            const message = 'There are unsaved changes. Are you sure you want to leave?';
            e.returnValue = message;
            return message;
        },

        onChanged(changed) {
            if (changed) {
                window.addEventListener("beforeunload", this.onBeforeUnload);
            }else {
                window.removeEventListener("beforeunload", this.onBeforeUnload);
            }
        },

    },
    async created() {
        if (this.$route.query.key) {
            const response = await api.getForm(this.$route.query.key);
            this.formData = response.formData;
        }else {
            this.formData = formHelper.getDemoConfig();
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.$refs.designer && this.$refs.designer.hasChanges) {
            const answer = window.confirm('There are unsaved changes. Are you sure you want to leave?');
            if (answer) {
                window.removeEventListener("beforeunload", this.onBeforeUnload);
                next();
            }else {
                next(false);
            }
        }else {
            next();
        }
    },
}
</script>