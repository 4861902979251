<template>
<div>

    <PopupV1 :header="'Add tag'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div class="amlv1-tag-aitems">
                <div v-for="(tag, i) in $root.config.tags.list" :key="'t' + i"
                    class="amlv1-tag-aitem" @click="addTag(tag)" :class="[{'amlv1-tag-aitem-d': alreadyHasTag(tag) }]">
                    {{ tag.text[$root.locale.key] }}
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-close')" outline @clicked="onClose" :disabled="dialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';

export default {
    name: 'TagDialog',
    components: {
        PopupV1,
        VFButton,
    },
    props: {
        params: { type: Object, default: null },
        client: { type: Object, default: null },
    },
    data() {
        return {
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
        }
    },
    methods: {

        alreadyHasTag(tag) {
            if (this.client.tags && this.client.tags.includes(tag.key)) return true;
            return false;
        },

        async addTag(tag) {
            if (this.alreadyHasTag(tag)) return;

            this.dialog.working = true;
            this.dialog.status = 'Adding tag';

            const tags = [tag.key];

            await v1api.addTags(this.client._id, tags);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        },

    },
    created() {
        
    }
}
</script>
<style scoped>

.amlv1-tag-aitems {
    border-top: solid 1px var(--input-border);
}

.amlv1-tag-aitem {
    padding: 4px 10px;
    border-bottom: solid 1px var(--input-border);
    cursor: pointer;
}
.amlv1-tag-aitem:hover {
    background-color: var(--item-hover);
}

.amlv1-tag-aitem-d {
    color: var(--text-disabled);
    cursor: not-allowed;
}
.amlv1-tag-aitem-d:hover {
    background-color: transparent;
}

</style>