<template>
<div class="aml-page">
    
    <SplitterV1 :left="60" :right="40">
        <template v-slot:left>

            <vap-label :text="'Monitor Details'" header2 style="margin-top: 10px;" />
            <div class="amlv1-m-subheader">
                {{ 'Monitor details' }}
            </div>

            <vap-card>
                <vap-row>
                    <vap-group c12>
                        <vap-label desc :text="'Monitor Name'" />
                        <vap-label value :text="monitor.name" />
                    </vap-group>
                    <vap-group c6>
                        <vap-label desc :text="'Type'" />
                        <vap-label value :text="monitor.template" />
                    </vap-group>
                    <vap-group c6>
                        <vap-label desc :text="'ID'" />
                        <vap-label value :text="monitor.id" />
                    </vap-group>
                    <!-- <vap-group c12>
                        <vap-label desc :text="'Webhook'" />
                        <vap-label value :text="monitor.webhook" />
                    </vap-group> -->
                </vap-row>
            </vap-card>

            <vap-label :text="'Automatic rules'" header2 style="margin-top: 20px;" />
            <div class="amlv1-m-subheader">
                {{ 'Add rules for automatically adding persons to this monitor (NOT IMPLEMENTED)' }}
            </div>

            <MonitorRule v-for="(rule, i) in rules" :key="'r' + i"
                :rule="rule"
            />

            <div class="amlv1-m-add" @click="showRuleDialog">
                {{ 'ADD RULE' }}
            </div>

        </template>
        <template v-slot:right>

            <vap-label :text="'Notifications'" header2 style="margin-top: 10px;" />
            <div class="amlv1-m-subheader">
                {{ 'Recent notifications from this monitor. Notifications are stored for 14 days.' }}
            </div>

            <div class="flw100">
                <NotificationItem v-for="(notification, i) in notifications" :key="'n' + i"
                    :notification="notification"
                />
            </div>

            <CenteredSpinner v-if="loadingNotifications" />
            <EmptyIcon v-if="!loadingNotifications && notifications.length == 0"
                :icon="'input-check'" :text="'No notifications from this monitor'"
            />

            <PageControl v-if="!loadingNotifications"
                :total="totalPages"
                :current="currentPage"
                @page="onNavigate"
                style="margin-top: 20px;"
            />

        </template>
    </SplitterV1>

    <MonitorRuleDialog v-if="addDialog.show"
        @close="addDialog.show = false"
    />

</div>
</template>
<script>
import axios from 'axios';
import v2lib from '../v2lib';
import monitorHelper from './monitorHelper';

import SplitterV1 from '../v1ui/SplitterV1.vue';
import EmptyIcon from '../v1ui/EmptyIcon.vue';
import PageControl from '../components/special/PageControl';
import CenteredSpinner from '../v1ui/CenteredSpinner.vue';

import NotificationItem from './NotificationItem.vue';
import MonitorRule from './MonitorRule.vue';
import MonitorRuleDialog from './MonitorRuleDialog.vue';

export default {
    components: {
        SplitterV1,
        EmptyIcon,
        PageControl,
        CenteredSpinner,
        NotificationItem,
        MonitorRule,
        MonitorRuleDialog,
    },
    name: 'MonitorDetails',
    props: {
        monitor: { type: Object, default: null },
    },
    data() {
        return {
            loadingNotifications: false,
            totalPages: 0,
            currentPage: 1,
            notifications: [],

            rules: [],
            addDialog: { show: false },
        }
    },
    methods: {

        //#region Rules / Triggers --------------------------------------------------------------------------------------------

        showRuleDialog() {
            this.addDialog.show = true;
        },

        onAddRule() {

        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Notifications -----------------------------------------------------------------------------------------------
        
        async getNotifications() {
            this.loadingNotifications = true;

            const config = {
                headers: {
                    'accept': 'application/json',
                    'x-namespace': v2lib.namespace,
                    'authorization': 'JWT ' + v2lib.personalToken,
                }
            };
            let response = await axios.get(this.$root.baseUrl + `/api/monitors/person/${this.monitor.id}/notifications`, config);
            console.log('response', response);

            this.notifications = response.data.notifications;

            this.loadingNotifications = false;
        },

        onNavigate(page) {
            currentPage = page;
            this.getNotifications();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        async pingMonitor(monitor) {
            const config = {
                headers: {
                    'accept': 'application/json',
                    'x-namespace': v2lib.namespace,
                    'authorization': 'JWT ' + v2lib.personalToken,
                }
            };
            let response = await axios.post(this.$root.baseUrl + `/api/monitors/person/${monitor.id}/webhook-ping`, '', config);
            console.log('response', response);
        },

    },
    created() {
        this.getNotifications();
    }
}
</script>
<style scoped>

.amlv1-m-subheader {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 1px;
    font-size: 14px;
    color: var(--text-weak);
}

.amlv1-m-add {
    float: left;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    color: var(--link-text);
    cursor: pointer;
}
.amlv1-m-add:hover {
    color: var(--link-active);
}

</style>