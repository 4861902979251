import log from '../../../../log';
import api from '../../../../portalapi';
import formHelper from '../../formHelper';
const clientModuleHelper = {

    getEmptyConfig() {
        return {
            type: 'client',
            icon: 'company',
            tags: [],
        };
    },

    onFormCreated(root, reference, designer, module, pages) {
        if (designer) return;

        const existingData = JSON.parse(root.formData);
        log.x('Client Module: On form created, existing data:', existingData);
        if (!existingData || !existingData.companyData) return;

    },

    onSetUserdata(root, reference, module, envelope, userdata) {

        const existingData = JSON.parse(root.formData);
        log.x('Client Module: On set userdata, existing data:', existingData);
        if (!existingData || !existingData.customer) return;

    },

    async onFormSubmitted(root, reference, module, nextRoleKey, envelope) {

        const existingData = JSON.parse(root.formData);
        log.x('Client Module: On form submitted, existing data:', existingData);
        
    },

};
export default clientModuleHelper;