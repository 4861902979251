<template>
<div class="vepv2">

    <div class="vepv2-top" @click="onTopClicked">
        
        <CardActions :actions="actions" @action="onAction" />

        <div class="vepv2-top-left">
            <div class="vepv2-info-icon">
                <vap-icon :icon="'person'" />
            </div>
            <div class="vepv2-info">
                <div class="vepv2-name">
                    {{ person.displayName }}
                </div>
                <div class="vepv2-roles" v-html="getDisplayRoles(person)" />
            </div>
        </div>

        <div class="vepv2-top-right" :style="'margin-right: ' + rightOffset + 'px;'">
            <div class="vepv2-flags" v-if="$root.config.persons.geography">
                <div class="vepv2-flag">
                    <FlagIcon :alpha2="residencyFlag" />
                    <vap-tooltip :maxWidth="500" :text="residencyTooltip" />
                </div>
                <div class="vepv2-flag">
                    <FlagIcon :alpha2="citizenshipFlag" />
                    <vap-tooltip :maxWidth="500" :text="citizenshipTooltip" />
                </div>
            </div>
            <div class="vepv2-dob-icon">
                <vap-icon :icon="'input-date'" small :color="'var(--text-weak)'" />
            </div>
            <div class="vepv2-dob">
                {{ formatDob(person.info.dateOfBirth) }}
            </div>
            <div class="vepv2-warnings" v-if="hasChangedWarning">
                <div class="vepv2-warning">
                    <vap-icon :icon="'warning'" :color="'var(--val-error)'">
                        <template v-slot:tooltip>
                            <vap-tooltip :maxWidth="500" :text="__t('monitor-person-changed')" />
                        </template>
                    </vap-icon>
                </div>
            </div>
        </div>

    </div>


    <div class="vepv2-expand" v-if="expanded">
        <div class="vepv2-ex-left">
            <PersonDetails :person="person" :client="client"
                showEmail
                showRoles
            />
        </div>
        <div class="vepv2-ex-right">
            <PersonDetails :person="person" :client="client"
                showAddress
                showCitizenship
            />
        </div>
    </div>


    <div class="vepv2-content" v-if="!$root.config.persons.checksDisabled">
        <div class="vepv2-left" :class="{'vepv2-left-x2': !bottomSlot }">
            <slot name="left"></slot>
        </div>
        <div class="vepv2-right" :class="{'vepv2-right-x2': !bottomSlot }">
            <slot name="right"></slot>
        </div>
        <div class="vepv2-bottom" v-if="bottomSlot">
            <slot name="bottom"></slot>
        </div>
    </div>
    
</div>
</template>
<script>
import v1personHelper from '../v1helpers/v1personHelper';

import CardActions from '../v1ui/CardActions.vue';
import FlagIcon from '../v1ui/FlagIcon.vue';

import PersonDetails from './PersonDetails.vue';

export default {
    name: 'PersonItem',
    components: {
        CardActions,
        FlagIcon,
        PersonDetails,
    },
    props: {
        client: { type: Object, default: null },
        person: { type: Object, default: null },
        singlePerson: { type: Boolean, default: false },
        bottomSlot: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            expanded: false,
            actions: [],
            rightOffset: 0,
            residencyFlag: '',
            residencyTooltip: '',
            citizenshipFlag: '',
            citizenshipTooltip: '',
            hasChangedWarning: false,
        }
    },
    methods: {

        //#region Actions -----------------------------------------------------------------------------------------------------

        onTopClicked() {
            if (this.$root.config.persons.expandDisabled) return;
            this.expanded = !this.expanded;
        },

        onAction(action) {
            this.$emit('action', action);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Roles -------------------------------------------------------------------------------------------------------

        getDisplayRoles(person) {
            return v1personHelper.getDisplayRoles(person, this.$root);
        },

        getRolePercentage(person, list) {
            return v1personHelper.getRolePercentage(person, list.id);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Geography Tooltip -------------------------------------------------------------------------------------------

        

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {
        //console.log('PERSON', this.person);
        if (this.singlePerson) {
            if (!this.$root.config.persons.infoDisabled) this.actions.push({ icon: 'info', action: 'info' });
        }else {
            this.actions = [
                { icon: 'delete', action: 'remove', disabled: this.disabled },
                { icon: 'add', action: 'add-role', disabled: this.disabled },
            ];
            if (!this.$root.config.persons.infoDisabled) this.actions.push({ icon: 'info', action: 'info' });
        }

        if (this.$root.config.screening.personMonitor) {
            const action = { icon: 'cloud-search', action: 'cscreen', highlight: this.person.monitors.length > 0, hint: {} };
            let tooltip = '';
            for (const i in this.person.monitors) {
                let monitor = { name: 'Unknown monitor' };
                for (const j in this.$root.monitors.list) {
                    if (this.$root.monitors.list[j].id == this.person.monitors[i]) {
                        monitor = this.$root.monitors.list[j];
                    }
                }
                if (i > 0) tooltip += ', ';
                tooltip += monitor.name;
            }
            for (const i in this.$root.config.locales) {
                action.hint[this.$root.config.locales[i].key] = tooltip;
            }
            this.actions.splice(this.singlePerson ? 0 : 2, 0, action);
        }

        const residency = v1personHelper.getBestResidencySource(this.person);
        if (residency) this.residencyFlag = residency.country;
        this.residencyTooltip = v1personHelper.getResidencyTooltip(this.$root, this.person);

        const citizenship = v1personHelper.getBestCitizenshipSource(this.person);
        if (citizenship) this.citizenshipFlag = citizenship.value;
        this.citizenshipTooltip = v1personHelper.getCitizenshipTooltip(this.$root, this.person);

        // Check if person has changed since the final report was created
        if (this.client.report.done && this.client.changedIds) {
            for (const i in this.client.changedIds) {
                if (this.client.changedIds[i].id == this.person.personId) {
                    this.hasChangedWarning = true;
                }
            }
        }

        this.rightOffset = (40 * this.actions.length) + 30;
    }
}
</script>
<style scoped>

.vepv2 {
    position: relative;
    float: left;
    width: 100%;
    margin-bottom: 20px;

    border-radius: 5px;
    border: solid 1px var(--input-border);
    box-sizing: border-box;
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
}

.vepv2-top {
    float: left;
    display: flex;
    width: 100%;
    border-bottom: solid 1px var(--input-border);
    box-sizing: border-box;
    cursor: pointer;
}
.vepv2-top:hover {
    background-color: var(--item-hover);
}

.vepv2-top-left {
    display: flex;
    flex-grow: 100;
    padding-top: 15px;
    padding-left: 10px;
}

/* .vepv2-info-icon {

} */

.vepv2-info {
    margin-left: 10px;
    margin-bottom: 10px;
}
.vepv2-name {
    position: relative;
    font-size: 18px;
    color: var(--text-accent);
}
.vepv2-roles {
    margin-top: 4px;
}








.vepv2-top-right {
    position: absolute;
    display: flex;
    top: 0px; right: 0px;
    margin-top: 12px;
}
.vepv2-dob-icon {
    margin-top: -4px;
}
.vepv2-dob {
    margin-top: -1px;
    margin-left: 6px;
    margin-right: 6px;
    font-size: 18px;
    color: var(--text-strong);
}

.vepv2-warnings {
    padding-left: 15px;
}

.vepv2-warning {
    margin-top: -7px;
}


.vepv2-expand {
    float: left;
    width: 100%;
}
.vepv2-ex-left {
    float: left;
    width: 50%;
    margin-top: -20px;
    padding: 20px;
    box-sizing: border-box;
}
.vepv2-ex-right {
    float: left;
    width: 50%;
    margin-top: -20px;
    padding: 20px;
    box-sizing: border-box;
}


.vepv2-content {
    float: left;
    width: 100%;
    box-sizing: border-box;
}





.vepv2-left {
    float: left;
    width: 33%;
    min-height: 100px;
    box-sizing: border-box;
}
.vepv2-right {
    float: left;
    width: 34%;
    min-height: 100px;
    border-left: solid 1px var(--input-border);
    box-sizing: border-box;
}
.vepv2-bottom {
    float: left;
    width: 33%;
    min-height: 100px;
    border-left: solid 1px var(--input-border);
    box-sizing: border-box;
}

.vepv2-left-x2 {
    width: 50%;
}
.vepv2-right-x2 {
    width: 50%;
}

@media screen and (max-width: 1400px) {

    .vepv2-left {
        width: 50%;
    }
    .vepv2-right {
        width: 50%;
    }
    .vepv2-bottom {
        width: 100%;
        border-top: solid 1px var(--input-border);
        border-left: none;
    }

}



.vepv2-flags {
    display: flex;
    margin-top: -6px;
    margin-right: 5px;
}

.vepv2-flag {
    position: relative;
    width: 32px;
    height: 32px;
    margin-left: 5px;
}

</style>