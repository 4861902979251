<template>
<div class="vf-btnrow" :class="[
    {'vf-btnrow-left' : alignLeft || alignment == 1},
    {'vf-btnrow-center' : alignCenter || alignment == 2},
    {'vf-btnrow-right' : alignRight || alignment == 3},
    {'vf-btnrow-designer' : designer},
    {'vf-btnrow-des' : designerColor},
    {'vf-btnrow-selected' : selected},
    {'vf-btnrow-flex' : flexMode},
    {'vf-btnrow-phone' : phoneMode},
]" @click.self="handleClick">
    <slot></slot>
</div>
</template>

<script>
export default {
    name: 'vf-button-row',
    props: {
        alignment: { type: Number, default: 0 },
        alignLeft: { type: Boolean, default: false },
        alignCenter: { type: Boolean, default: false },
        alignRight: { type: Boolean, default: false },
        designer: { type: Boolean, default: false },
        designerColor: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        flexMode: { type: Boolean, default: false },
        phoneMode: { type: Boolean, default: false },
    },
    data() { 
        return {
            
        }
    },
    methods: { 
        
        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

    },
    mounted() {

    }
}
</script>
<style scoped>

.vf-btnrow-left, .vf-btnrow-center, .vf-btnrow-right {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.vf-btnrow-left .vf-btn {
    margin-right: 10px;
}
.vf-btnrow-center .vf-btn {
    margin-left: 5px;
    margin-right: 5px;
}
.vf-btnrow-right .vf-btn {
    margin-left: 10px;
}

.vf-btnrow-right {
    text-align: right;
}

.vf-btnrow-center {
    text-align: center;
}

.vf-btnrow-flex.vf-btnrow-right {
    display: flex;
    flex-direction: row-reverse;
}

.vf-btnrow-flex.vf-btnrow-center {
    display: flex;
    align-items: center;
    justify-content: center;
}


.vf-btnrow-phone .vf-btn {
    width: 100%;
    margin: 3px 0px;
}


/* Designer */

.vf-btnrow-designer {
    border-radius: 4px;
    border: dotted 2px var(--input-border);
}
.vf-btnrow-designer:hover {
    background-color: rgb(250, 253, 255);
}

.vf-btnrow-selected {
    border: solid 2px #3190D5;
}


/* Designer Colors */

.vf-btnrow-des .vf-btn {
    color: white;
    fill: white;
    border-color: #238CCC;
    background-color: #238CCC;
}

.vf-btnrow-des .vf-btn-outline {
    fill: white;
    background-color: transparent;
}

</style>
