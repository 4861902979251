<template>
<div>

    <!-- Manage -->
    <PopupV1 :header="'Manage logic'" v-if="params.mode == 'manage' && !select && !ioSelect && !showDesigner" @close="$emit('close')" :dialog="addDialog">
        <template v-slot:content>
            <FormDialogSelector :items="items" showEmpty @add="showAddDialog" @edit="showEditDialog" @select="onSelected" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>


    <!-- Connect -->
    <PopupV1 :header="'Connect to logic'" v-if="params.mode == 'connect' && !select && !ioSelect && !showDesigner" @close="$emit('close')" :dialog="addDialog">
        <template v-slot:content>
            <FormDialogSelector :items="items" @add="showAddDialog" @select="onSelected" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>


    <!-- Select IO mode -->
    <PopupV1 :header="'Connect to:'" v-if="ioSelect" @close="$emit('close')" :dialog="addDialog">
        <template v-slot:content>
            <div v-if="!optionSelect">
                <vap-item v-for="(option, i) in ioOptions" :key="'am' + i" fancy padding20
                    :fancyIcon="option.icon" :fancyText="option.name" :fancyHint="option.hint"
                    @clicked="onIOSelected(option)"
                />
            </div>
            <OptionSelector v-if="optionSelect" :fromItem="params.fromItem" @select="onConnectInput" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>


    <!-- Edit dialog -->
    <PopupV1 :header="'Edit logic'" v-if="editDialog.show" @close="$emit('close')" :dialog="editDialog">
        <template v-slot:content>
            <VFRow>
                <VFGroup c4>
                    <VFLabel desc :text="'Indexed'" />
                    <VFCheckbox v-model="$parent.logic[editDialog.index].indexed" :text="'Indexed'" bordered borderOffset noValidation />
                </VFGroup>
                <VFGroup c4>
                    <VFLabel desc :text="'Index Key'" />
                    <VFInput v-model="$parent.logic[editDialog.index].indexKey" />
                </VFGroup>
                <VFGroup c4>
                    <VFLabel desc :text="'Key'" />
                    <VFInput v-model="$parent.logic[editDialog.index].key" />
                </VFGroup>
                <VFGroup c12>
                    <VFLabel desc :text="'Name'" />
                    <VFInput v-model="$parent.logic[editDialog.index].name" />
                </VFGroup>
            </VFRow>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Delete'" warning :icon="'delete'" @clicked="onDeleteLogic" />
            <VFButton :text="'OK'" :icon="'check'" @clicked="onEditLogic" />
        </template>
    </PopupV1>


    <!-- Add dialog -->
    <PopupV1 :header="'Add logic'" v-if="addDialog.show" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <VFRow>
                <VFGroup c12>
                    <VFInput v-model="addDialog.name" />
                </VFGroup>
            </VFRow>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="addDialog.show = false" />
            <VFButton :text="'Add'" :icon="'add'" @clicked="onAddLogic" />
        </template>
    </PopupV1>

    <LogicDesignView v-if="showDesigner" :reference="$parent" :index="selectedIndex" @close="$emit('close')" />
    
</div>
</template>
<script>
import FormDialogSelector from './FormDialogSelector.vue';
import OptionSelector from './OptionSelector.vue';
import LogicDesignView from './LogicDesignView.vue';

import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFInput from '../inputs/VFInput.vue';
import VFCheckbox from '../inputs/VFCheckbox.vue';
import VFDropdown from '../inputs/VFDropdown.vue';
import VFRow from '../items/VFRow.vue';
import VFGroup from '../items/VFGroup.vue';
import VFLabel from '../items/VFLabel.vue';
import VFButton from '../items/VFButton.vue';
import DialogContent from '../../../v1ui/DialogContent.vue';
import DialogContentHeader from '../../../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../../../v1ui/DialogContentItem.vue';

export default {
    name: 'LogicDesigner',
    components: {
        PopupV1,
        VFInput,
        VFCheckbox,
        VFDropdown,
        VFRow,
        VFGroup,
        VFLabel,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,

        FormDialogSelector,
        OptionSelector,
        LogicDesignView,
    },
    props: {
        params: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            items: [],
            addDialog: { show: false, name: '' },
            editDialog: { show: false, index: 0, name: '', key: '' },

            select: false,
            selectedIndex: 0,

            ioSelect: false,
            ioOptions: [
                { icon: 'logic', name: 'Input', hint: 'Input', type: 'input' },
                { icon: 'logic', name: 'Output', hint: 'Output', type: 'output' },
            ],
            ioSelection: null,

            optionSelect: false,

            showDesigner: false
        }
    },
    methods: {

        onSelected(index) {
            this.selectedIndex = index;

            if (this.params.mode == 'connect') {
                this.ioSelect = true;
            }else {
                this.showDesigner = true;
            }
        },

        onIOSelected(option) {
            if (option.type == 'input') {
                this.ioSelection = option;
                if (this.params.fromItem.type == 'check') {
                    this.optionSelect = true;
                }else {
                    this.onConnectInput(null);
                }
            }else {
                const params = {
                    logicIndex: this.selectedIndex,
                    fromItem: this.params.fromItem
                };
                this.$emit('output', params);
                this.$emit('close');
            }
        },

        onConnectInput(optionKey) {

            if (this.ioSelection.type == 'input') {

                const input = {
                    key: this.params.fromItem.key,
                    type: this.params.fromItem.type,
                    isOption: optionKey != null,
                    optionKey: optionKey || '',
                    format: 'S',
                    name: this.params.fromItem.desc.en_EN,
                    demoValue: '',
                    isList: this.params.fromItem.isList || false
                };

                if (optionKey) {
                    input.format = 'B';
                    input.demoValue = false;
                    input.name = this.params.fromItem.options[optionKey].locale.en_EN;
                }

                if (input.name.length > 30) {
                    input.name = input.name.substring(0, 27) + '...';
                }

                this.$parent.logic[this.selectedIndex].items[0].inputs.push(input);
                console.log('added input', input);

            }else {

            }

            this.showDesigner = true;
        },

        //#region Loading / Adding --------------------------------------------------------------------------------------------

        showAddDialog() {
            this.addDialog.show = true;
        },

        onAddLogic() {
            const count = this.$parent.counter += 1;
            this.$parent.logic.push({
                key: 'l' + count,
                name: this.addDialog.name,
                items: [
                    {
                        inputs: [],
                        ops: [],
                        demoOutput: null,
                        passFalse: true
                    },
                ],
                indexKey: 'l' + count,
                indexed: false,
                demoOutput: ''
            });
            console.log('logic', this.$parent.logic);
            this.addDialog.show = false;
            this.loadLogic();
        },

        showEditDialog(index) {
            this.editDialog.index = index;
            this.editDialog.show = true;
        },

        onEditLogic() {
            this.editDialog.show = false;
            this.loadLogic();
        },

        onDeleteLogic() {
            this.$parent.logic.splice(this.editDialog.index, 1);
            this.editDialog.show = false;
            this.loadLogic();
        },

        loadLogic() {
            this.items = [];
            for (const i in this.$parent.logic) {
                this.items.push({
                    type: 'logic',
                    icon: 'logic',
                    name: this.$parent.logic[i].name
                });
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------
        
    },
    created() {
        console.log('params', this.params);
        this.loadLogic();

        if (this.params.selectedIndex != null) {
            this.onSelected(this.params.selectedIndex);
        }
        if (this.params.addLogic) {
            this.showAddDialog();
        }

    }
}
</script>
<style scoped>

</style>