<template>
<div class="page-error">
    
    <div class="page-error-msg">
        {{ message }}
    </div>

</div>
</template>
<script>


export default {
    name: 'PageErrorMessage',
    props: {
        message: { type: String, default: 'An unknown error occured' },
    },
    data() {
        return {
            
        }
    },
    methods: {
        

    }
}
</script>
<style scoped>

.page-error-msg {
    float: left;
    width: fit-content;
    margin: 100px 100px;
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    color: var(--val-error);
    border-radius: 5px;
    background-color: var(--card-background);
}

</style>