<template>
    <div>

        <div v-if="!showDirect && !showOrder">
            <vap-label header :text="__('kyc_checkout_select')" style="margin-bottom: 10px;" />
            <vap-card compactList noPadding>
                <vap-item padding20 @clicked="goToDirect">
                    <div  class="checkout-option">
                        <label>{{ __('kyc_checkout_direct') }}</label>
                    </div>
                </vap-item>
            </vap-card>
            <vap-card compactList noPadding>
                <vap-item padding20 @clicked="goToOrder">
                    <div class="checkout-option">
                        <label>{{ __('kyc_checkout_recipients') }}</label>
                    </div>
                </vap-item>
            </vap-card>
        </div>


        <div v-if="showDirect">
            <vap-button rounded :text="__('kyc_checkout_back')" @clicked="goBack" style="margin-bottom: 10px;" />
            <vap-card compactList :header="__('kyc_checkout_direct')">
                <vap-row>
                    <vap-group c3>
                        <vap-label desc :text="__('kyc_checkout_method')" />
                        <vap-dropdown v-model="directMethodModel" noValidation
                            :options="signingMethodOptions"
                            :displayKey="'display'"
                            :listKey="'display'"
                            :outputKey="'value'"
                            @input="updateVmodel" />
                    </vap-group>
                    <vap-group>
                        <vap-label desc :text="''" />
                        <vap-button :text="__('kyc_checkout_start')" @clicked="submit" />
                    </vap-group>
                </vap-row>
            </vap-card>

        </div>


        <div v-if="showOrder">
            <vap-button rounded :text="__('kyc_checkout_back')" @clicked="goBack" style="margin-bottom: 10px;" />
            <vap-label header :text="__('kyc_checkout_order')" style="margin-bottom: 10px;" />
            <div class="checkout-signer-base" v-for="(signer, i) in signers" :key="'s' + i">
                <vap-card list noPadding>
                    <vap-item padding20 @clicked="editSigner(i)">
                        <div class="checkout-signer">
                            <div class="checkout-signer-left">
                                <div class="checkout-signer-name">
                                    <label>{{ signer.firstName + ' ' + signer.lastName }}</label>
                                </div>
                                <div class="checkout-signer-email">
                                    <label>{{ signer.email }}</label>
                                </div>
                            </div>
                            <div class="checkout-signer-right">

                                <div class="checkout-signer-sign">

                                    <vap-icon :icon="'sign'" />

                                </div>

                                

                                <div class="checkout-signer-action" v-if="signer.method != 'select'">
                                    <label>{{ __('kyc_checkout_with') }}</label>
                                </div>
                                <div class="checkout-signer-method" v-if="signer.method != 'select'">
                                    <label>{{ getMethodName(signer.method) }}</label>
                                </div>
                            </div>
                        </div>
                    </vap-item>
                </vap-card>
            </div>
            <vap-button-row alignCenter style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('kyc_checkout_add')" uppercase @clicked="addSigner" />
                <vap-button :text="__('kyc_checkout_send')" uppercase @clicked="submit" />
            </vap-button-row>
        </div>


        <ve-popup v-if="showPopup" :header="__('kyc_checkout_add')" :width="600" @close="abortPopup" hideCloseButton>
            <div style="padding: 20px;">
                
                <div class="checkout-add-top">
                    <div class="checkout-add-first">
                        <label v-if="signers.length == 0">{{ __('kyc_checkout_first') }}</label>
                    </div>
                    <div class="checkout-add-list">
                        <label @click="showPersonList">{{ __('kyc_checkout_list') }}</label>
                    </div>
                </div>

                <vap-row>
                    <vap-group c6>
                        <vap-label :text="__('common_first_name')" desc required />
                        <vap-input v-model="activeSigner.firstName" :placeholder="__('common_required')" :rules="'required'" noHighlight></vap-input>
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('common_last_name')" desc required />
                        <vap-input v-model="activeSigner.lastName" :placeholder="__('common_required')" :rules="'required'" noHighlight></vap-input>
                    </vap-group>
                    <vap-group :c12="!useRecipientPortal" :c8="useRecipientPortal">
                        <vap-label :text="__('common_email')" desc required />
                        <vap-input v-model="activeSigner.email" :placeholder="__('common_required')" :rules="'required|email'" noHighlight></vap-input>
                    </vap-group>
                    <vap-group :c6="!useRecipientPortal" :c4="useRecipientPortal">
                        <vap-label :text="__('common_language')" desc required />
                        <LanguageDropdown v-model="activeSigner.language" :placeholder="__('common_required')" noHighlight />
                    </vap-group>
                    <vap-group c6 v-if="!useRecipientPortal">
                        <vap-label desc :text="__('kyc_checkout_method')" />
                        <vap-dropdown v-model="activeSigner.method"
                            :options="signingMethodOptions"
                            :displayKey="'display'"
                            :listKey="'display'"
                            :outputKey="'value'" />
                    </vap-group>
                </vap-row>

                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="abortPopup" />
                    <vap-button :text="__(activeIndex == -1 ? 'common_add' : 'common_save')" uppercase @clicked="saveSigner" :disabled="!hasInfo(activeSigner)" />
                </vap-button-row>
            </div>
        </ve-popup>
        
        <ve-popup v-if="showList" @close="showList = false" :header="__('recipient_select_person')">
            <div style="max-height: 630px; overflow: auto;">
                <vap-item v-for="(person, i) in persons" :key="i" padding20 @clicked="setSignerFromList(person)">
                    <label style="color: var(--text-accent); font-weight: bold;">
                        {{ person.firstName + ' ' + person.lastName }}
                    </label>
                    <label v-if="person.dateOfBirth" style="color: darkgray; font-weight: bold; margin-left: 7px;">
                        {{ '(' + person.dateOfBirth + ')' }}
                    </label>
                    <br>
                    <label>{{ person.role }}</label>
                </vap-item>
            </div>
            <template v-slot:footer>
                <vap-item padding20 @clicked="showList = false">
                    <label>{{ __('recipient_list_missing') }}</label>
                </vap-item>
            </template>
        </ve-popup>

        <SigningMethodsInfo v-if="showDirect" :methods="signingMethodOptions" />

    </div>
</template>

<script>
import verified from '../../verified';
import validations from '../../validations';
import LanguageDropdown from '../special/LanguageDropdown';
import SigningMethodsInfo from '../../components/kyc/SigningMethodsInfo';

export default {
    name: 'Checkout',
    components: {
        LanguageDropdown,
        SigningMethodsInfo
    },
    props: {
        value: {
            type: Object,
            default: () => { return {} }
        },
        receiver: {
            type: Object,
            default: () => { return {} }
        },
        persons: {
            type: Array,
            default: () => { return [] }
        }
    },
    data() {
        return {
            showDirect: false,
            showOrder: false,
            showPopup: false,
            showList: false,
            directMethodModel: 'bankid-no',
            signingMethodOptions: [],
            activeIndex: -1,
            activeSigner: {
                firstName: '',
                lastName: '',
                email: '',
                language: 'nb_NO',
                action: 'sign',
                method: 'bankid-no'
            },
            signers: [],
            useRecipientPortal: false,
            addIsValid: false
        }
    },
    methods: {
        updateVmodel() {
            const value = {
                ready: this.showDirect || (this.showOrder && this.signers.length > 0),
                signDirect: this.showDirect,
                recipients: this.showDirect ? [] : this.signers
            };
            if (this.showDirect) value.recipients.push({
                firstName: this.receiver.fname,
                lastName: this.receiver.lname,
                email: this.receiver.email,
                language: this.$root.locale.key,
                method: this.directMethodModel
            });
            this.$emit('input', value);
        },
        goBack() {
            this.showDirect = false;
            this.showOrder = false;
            this.updateVmodel();
        },
        goToDirect() {
            this.showDirect = true;
            this.updateVmodel();
        },
        goToOrder() {
            this.showOrder = true;
            this.addSigner();
        },
        abortPopup() {
            this.showPopup = false;
            this.activeIndex = -1;
            if (this.signers.length == 0) this.goBack();
        },
        hasInfo(signer) {
            let result = validations.validateEmail(signer.email);
            return signer.firstName && signer.lastName && result.valid && signer.language;
        },
        addSigner() {
            this.activeIndex = -1;
            this.activeSigner = {
                firstName: '',
                lastName: '',
                email: '',
                language: 'nb_NO',
                action: 'sign',
                method: 'bankid-no'
            };
            this.showPopup = true;
        },
        editSigner(index) {
            this.activeIndex = index;
            this.activeSigner = this.signers[index];
            this.showPopup = true;
        },
        saveSigner() {
            if (this.useRecipientPortal) this.activeSigner.method = 'select';
            if (this.activeIndex == -1) {
                this.signers.push(JSON.parse(JSON.stringify(this.activeSigner)));
            }else {
                this.signers[this.activeIndex] = JSON.parse(JSON.stringify(this.activeSigner));
            }
            this.showPopup = false;
            this.activeIndex = -1;
            this.updateVmodel();
        },
        showPersonList() {
            this.showList = true;
        },
        setSignerFromList(person) {
            this.activeSigner.firstName = person.firstName;
            this.activeSigner.lastName = person.lastName;
            if (person.email) this.activeSigner.email = person.email;
            this.showList = false;
        },
        submit() {
            this.$emit('submit', true);
        },
        getMethodName(method) {
            for (const i in this.signingMethodOptions) {
                if (this.signingMethodOptions[i].value == method) return this.signingMethodOptions[i].display;
            }
            return method;
        }
    },
    async mounted() {
        
        this.useRecipientPortal = this.$root.config.kycs[0].settings.recipientPortal;

        this.signingMethodOptions = verified.getAvailableSigningMethods(this.$root.config.kycs[0].methods, this.$root.debug.local);

    }
}
</script>
<style scoped>


.checkout-option {
    font-size: 18px;
    padding: 15px 10px;
}



.checkout-signer {
    display: flex;
    padding: 5px 2px;
}
.checkout-signer-left {
    flex-grow: 100;
}
.checkout-signer-right {
    display: flex;
}

.checkout-signer-name {
    font-weight: 500;
}
.checkout-signer-email {

}


.checkout-signer-action {
    margin-top: 9px;
    margin-left: 6px;
    font-size: 16px;
}
.checkout-signer-method {
    margin-top: 9px;
    margin-left: 6px;
    font-size: 16px;
    font-weight: 500;
}


.checkout-add-top {
    display: flex;
    margin-bottom: 15px;
}
.checkout-add-first {
    flex-grow: 100;
}
.checkout-add-list label {
    color: blue;
    cursor: pointer;
}
.checkout-add-list label:hover {
    color: red;
}

</style>