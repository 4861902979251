<template>
<div>
    <PageHeader :actions="actions" compactable :offset="104" @scrolled="scrolled = $event" @action="onAction">
        <template v-slot:content>
            <vap-row v-if="!loading">
                <vap-group auto noPadding>
                    <vap-label :text="'Tasks'" desc/>
                    <vap-label :text="'Tasks'" value />
                </vap-group>
            </vap-row>
        </template>
        <template v-slot:tabs>
            <vap-tabs v-if="Object.keys(tabModel).length > 1" accent v-model="tabModel" :tabs="tabOptions" :compact="scrolled" @input="onTabChanged" />
        </template>
        <template v-slot:compact>
            <div v-if="!loading">
                <span>{{ 'Tasks' }}</span>
            </div>
        </template>
    </PageHeader>
    <PageContent :scrolled="scrolled" :offset="104" v-if="!loading">

        <div class="slim-page">


            <div class="amlv1-task" v-for="(task, i) in tasks" :key="'k' + i" @click="showPerformDialog(task)">
                {{ task.type }}
            </div>

        </div>

    </PageContent>
    <PageFooter :scrolled="scrolled" />

    <PopupV1 v-if="performDialog.show" :header="'Perform task'" @close="performDialog.show = false" :dialog="performDialog">
        <template v-slot:content>
            <div class="flw100" style="margin-top: 10px; color: var(--text-title);">
                {{ __t('verified-reminder-hint') }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="performDialog.show = false" :disabled="performDialog.working" />
            <VFButton :text="'Perform task'"
                :icon="'reminder'"
                @clicked="onPerformTask"
                :working="performDialog.working && !loading"
                :disabled="loading || !performDialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import uiHelper from '../helpers/uihelper';

import PageHeader from '../v1ui/PageHeader.vue';
import PageContent from '../v1ui/PageContent.vue';
import PageFooter from '../v1ui/PageFooter.vue';
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';

export default {
    name: 'TasksV1',
    components: {
        PageHeader,
        PageContent,
        PageFooter,
        PopupV1,
        VFButton,
    },
    data() {
        return {
            loading: true,
            scrolled: false,
            tabModel: {
                theme: true
            },
            tabOptions: {
                theme: { icon: 'colors', label: 'Appearance' }
            },
            actions: [
                { icon: 'save', action: 'save', working: false },
                { icon: 'help-o', action: 'help' }
            ],
            tasks: [],
            performDialog: { show: false, working: false, valid: true, task: null }
        }
    },
    methods: {

        onTabChanged() {
            let newKey = '';
            for (const key in this.tabModel) {
                if (this.tabModel[key]) newKey = key;
            }
            const oldKey = this.$route.query.tab;
            if (newKey && oldKey != newKey) {
                this.$router.replace({query: { tab: newKey } });
            }
        },
        
        onAction(action) {
            if (action.action == 'save') {
                this.saveConfig();
            }
        },

        showPerformDialog(task) {
            this.performDialog.task = task;
            this.performDialog.show = true;
        },

        async onPerformTask() {
            this.performDialog.working = true;

            await v1api.performTask(this.performDialog.task._id);

            this.performDialog.working = false;
            this.performDialog.show = false;
        },

        async getTasks() {
            
            const filters = [
                
            ];
            const sort = {};
            const from = 0;
            const limit = 100;
            const include = [];
            const counts = ['userId'];

            const result = await v1api.queryTasks(filters, from, limit, sort, include, counts);
            this.tasks = result.tasks;

            this.loading = false;
        },

    },
    async created() {
        this.getTasks();
    }
}
</script>
<style scoped>

.amlv1-task {
    padding: 20px;
    border: solid 1px var(--input-border);
    cursor: pointer;
}
.amlv1-task:hover {
    background-color: var(--item-hover);
}

</style>