<template>
<div>
    
    <div class="amlv1-mr">

        <div v-for="(trigger, i) in rule.triggers" :key="'t' + i">
            {{ trigger }}
        </div>

    </div>
    
    <PopupV1 v-if="addDialog.show" :width="700" :header="'Add trigger'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <VFRow>
                <VFGroup c12>
                    <VFLabel desc :text="'Trigger Type'" />
                    <VFDropdown v-model="addDialog.type" :options="triggerOptions" @input="validateTriggerDialog" />
                </VFGroup>
                <VFGroup c12>
                    <VFLabel desc :text="'Trigger Type'" />
                    <VFDropdown v-model="addDialog.type" :options="triggerOptions" @input="validateTriggerDialog" />
                </VFGroup>
            </VFRow>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="addDialog.show = false" :disabled="addDialog.working" />
            <VFButton :text="'Add'" @clicked="onAdd" :disabled="!addDialog.valid" :working="addDialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import monitorHelper from './monitorHelper';

export default {
    name: 'MonitorRule',
    props: {
        rule: { type: Object, default: null },
    },
    data() {
        return {
            addDialog: { show: false, valid: false, working: false, type: '' },
            triggerOptions: [
                { value: 'trigger', display: 'Trigger' },
            ],
        }
    },
    methods: {
        
        //#region Trigger Dialog ----------------------------------------------------------------------------------------------
        
        showTriggerDialog() {
            this.addDialog.show = true;
        },

        validateTriggerDialog() {
            this.addDialog.valid = true;
        },

        async onSaveTrigger() {
            this.addDialog.working = true;

            this.addDialog.working = false;
            this.addDialog.show = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {
        
    }
}
</script>
<style scoped>

.amlv1-mr {

}

</style>