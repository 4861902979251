<template>
<div>

    <vap-button-group v-model="economyTabs" style="margin-top: 0px;"
        :options="economyOptions">
    </vap-button-group>
    <vap-form-divider :left="50" :right="50" style="margin-top: 20px; margin-bottom: 10px;" v-if="!company.lookup">
        <template v-slot:left>
            <vap-label :text="__('econ_income_statement')" value style="float: left; width: 100%; margin-bottom: 5px;" />
            <div style="padding-left: 10px; padding-right: 50px;">
                <vap-number-item :text="__('econ_sales_revenues')" :number="company.economy[displayYear].salesRevenues.fullDisplay" v-if="company.economy[displayYear].salesRevenues.available" />
                <vap-number-item :text="__('econ_other_revenues')" :number="company.economy[displayYear].otherRevenues.fullDisplay" v-if="company.economy[displayYear].otherRevenues.available" />
                <vap-number-item :text="__('econ_total_revenues')" bold :number="company.economy[displayYear].totalRevenues.fullDisplay" v-if="company.economy[displayYear].totalRevenues.available" />
                <vap-number-item :text="__('econ_cost_of_goods_sold')" top :number="company.economy[displayYear].costOfGoodsSold.fullDisplay" v-if="company.economy[displayYear].costOfGoodsSold.available" />
                <vap-number-item :text="__('econ_inventory_changes')" :number="company.economy[displayYear].inventoryChanges.fullDisplay" v-if="company.economy[displayYear].inventoryChanges.available" />
                <vap-number-item :text="__('econ_salary_costs')" :number="company.economy[displayYear].salaryCosts.fullDisplay" v-if="company.economy[displayYear].salaryCosts.available" />
                <vap-number-item :text="__('econ_financial_costs')" :number="company.economy[displayYear].financialCosts.fullDisplay" v-if="company.economy[displayYear].financialCosts.available" />
                <vap-number-item :text="__('econ_depreciations')" :number="company.economy[displayYear].depreciations.fullDisplay" v-if="company.economy[displayYear].depreciations.available" />
                <vap-number-item :text="__('econ_amortizations')" :number="company.economy[displayYear].amortizations.fullDisplay" v-if="company.economy[displayYear].amortizations.available" />
                <vap-number-item :text="__('econ_other_operating_costs')" :number="company.economy[displayYear].otherOperatingCosts.fullDisplay" v-if="company.economy[displayYear].otherOperatingCosts.available" />
                <vap-number-item :text="__('econ_operating_profits')" bold :number="company.economy[displayYear].operatingProfits.fullDisplay" v-if="company.economy[displayYear].operatingProfits.available" />
                <vap-number-item :text="__('econ_ordinary_profits')" top :number="company.economy[displayYear].ordinaryProfits.fullDisplay" v-if="company.economy[displayYear].ordinaryProfits.available" />
                <vap-number-item :text="__('econ_extraordinary_revenues')" :number="company.economy[displayYear].extraordinaryRevenues.fullDisplay" v-if="company.economy[displayYear].extraordinaryRevenues.available" />
                <vap-number-item :text="__('econ_extraordinary_costs')" :number="company.economy[displayYear].extraordinaryCosts.fullDisplay" v-if="company.economy[displayYear].extraordinaryCosts.available" />
                <vap-number-item :text="__('econ_minority_interests')" :number="company.economy[displayYear].minorityInterests.fullDisplay" v-if="company.economy[displayYear].minorityInterests.available" />
                <vap-number-item :text="__('econ_taxes')" :number="company.economy[displayYear].taxes.fullDisplay" v-if="company.economy[displayYear].taxes.available" />
                <vap-number-item :text="__('econ_result_before_tax')" :number="company.economy[displayYear].resultBeforeTax.fullDisplay" v-if="company.economy[displayYear].resultBeforeTax.available" />
                <vap-number-item :text="__('econ_net_profits')" bold :number="company.economy[displayYear].netProfits.fullDisplay" v-if="company.economy[displayYear].netProfits.available" />
            </div>
        </template>
        <template v-slot:right>
            <vap-label :text="__('econ_balance_sheet')" value style="float: left; width: 100%; margin-bottom: 5px;" />
            <div style="padding-left: 10px; padding-right: 50px;">
                <vap-number-item :text="__('econ_current_assets')" :number="company.economy[displayYear].currentAssets.fullDisplay" v-if="company.economy[displayYear].currentAssets.available" />
                <vap-number-item :text="__('econ_fixed_assets')" :number="company.economy[displayYear].fixedAssets.fullDisplay" v-if="company.economy[displayYear].fixedAssets.available" />
                <vap-number-item :text="__('econ_sum_assets')" bold :number="company.economy[displayYear].sumAssets.fullDisplay" v-if="company.economy[displayYear].sumAssets.available" />
                <vap-number-item :text="__('econ_equity')" top :number="company.economy[displayYear].equity.fullDisplay" v-if="company.economy[displayYear].equity.available" />
                <vap-number-item :text="__('econ_debt')" :number="company.economy[displayYear].debt.fullDisplay" v-if="company.economy[displayYear].debt.available" />
                <vap-number-item :text="__('econ_sum_equity_and_debt')" bold :number="company.economy[displayYear].sumEquityAndDebt.fullDisplay" v-if="company.economy[displayYear].sumEquityAndDebt.available" />
            </div>
        </template>
    </vap-form-divider>

    <vap-form-divider :left="50" :right="50" style="margin-top: 20px; margin-bottom: 10px;" v-if="company.lookup">
        <template v-slot:left>
            <vap-label :text="__('econ_income_statement')" value style="float: left; width: 100%; margin-bottom: 5px;" />
            <div style="padding-left: 10px; padding-right: 50px;">
                <vap-number-item :text="__('econ_sales_revenues')" :number="getDisplayValue(displayYear, 'salesRevenues')" v-if="isAvailable(displayYear, 'salesRevenues')" />
                <vap-number-item :text="__('econ_other_revenues')" :number="getDisplayValue(displayYear, 'otherRevenues')" v-if="isAvailable(displayYear, 'otherRevenues')" />
                <vap-number-item :text="__('econ_total_revenues')" bold :number="getDisplayValue(displayYear, 'totalRevenues')" v-if="isAvailable(displayYear, 'totalRevenues')" />
                <vap-number-item :text="__('econ_cost_of_goods_sold')" top :number="getDisplayValue(displayYear, 'costOfGoodsSold')" v-if="isAvailable(displayYear, 'costOfGoodsSold')" />
                <vap-number-item :text="__('econ_inventory_changes')" :number="getDisplayValue(displayYear, 'inventoryChanges')" v-if="isAvailable(displayYear, 'inventoryChanges')" />
                <vap-number-item :text="__('econ_salary_costs')" :number="getDisplayValue(displayYear, 'salaryCosts')" v-if="isAvailable(displayYear, 'salaryCosts')" />
                <vap-number-item :text="__('econ_financial_costs')" :number="getDisplayValue(displayYear, 'financialCosts')" v-if="isAvailable(displayYear, 'financialCosts')" />
                <vap-number-item :text="__('econ_depreciations')" :number="getDisplayValue(displayYear, 'depreciations')" v-if="isAvailable(displayYear, 'depreciations')" />
                <vap-number-item :text="__('econ_amortizations')" :number="getDisplayValue(displayYear, 'amortizations')" v-if="isAvailable(displayYear, 'amortizations')" />
                <vap-number-item :text="__('econ_other_operating_costs')" :number="getDisplayValue(displayYear, 'otherOperatingCosts')" v-if="isAvailable(displayYear, 'otherOperatingCosts')" />
                <vap-number-item :text="__('econ_operating_profits')" bold :number="getDisplayValue(displayYear, 'operatingProfits')" v-if="isAvailable(displayYear, 'operatingProfits')" />
                <vap-number-item :text="__('econ_ordinary_profits')" top :number="getDisplayValue(displayYear, 'ordinaryProfits')" v-if="isAvailable(displayYear, 'ordinaryProfits')" />
                <vap-number-item :text="__('econ_extraordinary_revenues')" :number="getDisplayValue(displayYear, 'extraordinaryRevenues')" v-if="isAvailable(displayYear, 'extraordinaryRevenues')" />
                <vap-number-item :text="__('econ_extraordinary_costs')" :number="getDisplayValue(displayYear, 'extraordinaryCosts')" v-if="isAvailable(displayYear, 'extraordinaryCosts')" />

                <vap-number-item :text="__('econ_sum_other_financial_income')" :number="getDisplayValue(displayYear, 'sumOtherFinancialIncome')" v-if="isAvailable(displayYear, 'sumOtherFinancialIncome')" />
                <vap-number-item :text="__('econ_sum_financial_income')" :number="getDisplayValue(displayYear, 'sumFinancialIncome')" v-if="isAvailable(displayYear, 'sumFinancialIncome')" />

                <vap-number-item :text="__('econ_minority_interests')" :number="getDisplayValue(displayYear, 'minorityInterests')" v-if="isAvailable(displayYear, 'minorityInterests')" />
                <vap-number-item :text="__('econ_taxes')" :number="getDisplayValue(displayYear, 'taxes')" v-if="isAvailable(displayYear, 'taxes')" />
                <vap-number-item :text="__('econ_result_before_tax')" :number="getDisplayValue(displayYear, 'resultBeforeTax')" v-if="isAvailable(displayYear, 'resultBeforeTax')" />
                <vap-number-item :text="__('econ_net_profits')" bold :number="getDisplayValue(displayYear, 'netProfits')" v-if="isAvailable(displayYear, 'netProfits')" />
            </div>
        </template>
        <template v-slot:right>
            <vap-label :text="__('econ_balance_sheet')" value style="float: left; width: 100%; margin-bottom: 5px;" />
            <div style="padding-left: 10px; padding-right: 50px;">
                <vap-number-item :text="__('econ_current_assets')" :number="getDisplayValue(displayYear, 'currentAssets')" v-if="isAvailable(displayYear, 'currentAssets')" />
                <vap-number-item :text="__('econ_fixed_assets')" :number="getDisplayValue(displayYear, 'fixedAssets')" v-if="isAvailable(displayYear, 'fixedAssets')" />
                <vap-number-item :text="__('econ_sum_assets')" bold :number="getDisplayValue(displayYear, 'sumAssets')" v-if="isAvailable(displayYear, 'sumAssets')" />
                <vap-number-item :text="__('econ_equity')" top :number="getDisplayValue(displayYear, 'equity')" v-if="isAvailable(displayYear, 'equity')" />
                <vap-number-item :text="__('econ_debt')" :number="getDisplayValue(displayYear, 'debt')" v-if="isAvailable(displayYear, 'debt')" />
                <vap-number-item :text="__('econ_sum_equity_and_debt')" bold :number="getDisplayValue(displayYear, 'sumEquityAndDebt')" v-if="isAvailable(displayYear, 'sumEquityAndDebt')" />
            </div>
        </template>
    </vap-form-divider>

</div>
</template>

<script>
import log from '@/log';

export default {
    name: 'EconomyYears',
    components: {
        
    },
    props: {
        company: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            loading: true,
            displayYear: 'year0',
            economyTabs: {},
            economyOptions: {},
        }
    },
    watch: {
        economyTabs: {
            deep: true,
            handler(val) {
                for (const key in this.economyTabs) {
                    if (this.economyTabs[key]) {
                        this.displayYear = key;
                    }
                }
            }
        }
    },
    methods: {
        
        isAvailable(year, value) {
            return this.company.economy[year][value] != null && this.company.economy[year][value] != -1;
        },

        getDisplayValue(year, value) {
            return this.company.economy[year][value].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' ' + this.company.economy.currency;
        },

    },
    async created() {

        //console.log('ECON', this.company.economy)

        if (this.company.economy.year0.available) {
            this.$set(this.economyTabs, 'year0', true);
            this.$set(this.economyOptions, 'year0', { label: this.company.economy.year0.year });
        }
        if (this.company.economy.year1.available) {
            this.$set(this.economyTabs, 'year1', false);
            this.$set(this.economyOptions, 'year1', { label: this.company.economy.year1.year });
        }
        if (this.company.economy.year2.available) {
            this.$set(this.economyTabs, 'year2', false);
            this.$set(this.economyOptions, 'year2', { label: this.company.economy.year2.year });
        }

        this.loading = false;
    }
}
</script>
<style scoped>



</style>
