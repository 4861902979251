<template>
    <div class="slim-page slim-page-mt" v-if="loadingComplete">

<!--         <vap-card>

            <label>{{ 'Hei ' + recipient.displayName + ' role=' + recipient.action }}</label>

        </vap-card> -->

    </div>
</template>

<script>
import v2lib from '../v2lib';
import locale from '../locale';
import log from '../log';
import customFlowHelper from './customFlowHelper';

export default {
    name: 'FlowPortal',
    data() {
        return {
            lang: 'nb_NO',
            loadingComplete: false,
            recipient: { displayName: '' }
        }
    },
    methods: {
        setLang() {
            this.$root.locale = locale.getLocale(this.$root.config.locales, this.lang);
        },
        getCheckoutLink() {
            let signUrl = '';
            const useNewCheckout = true;
            if (useNewCheckout) {
                signUrl = 'https://checkout.verified.eu/checkout/?u=' + this.$parent.loadedRecipient.uid + '&';
            }else {
                signUrl = 'https://app.verified.eu/#/sign/envelopes/' + this.$parent.loadedEnvelope.id + '?';
            }
            signUrl += 'lang=' + this.lang;
            signUrl += '&redirect_to=' + this.$root.baseUrl + '/flow/' + this.$parent.loadedEnvelope.id + '/' + this.$parent.loadedRecipient.id;
            signUrl += '&access_token=' + v2lib.editorToken;
            return signUrl;
        },
        redirectToCheckout() {
            const signUrl = this.getCheckoutLink();
            sessionStorage.setItem(this.$parent.loadedEnvelope.id, v2lib.editorToken);
            window.location.href = signUrl;
        },
    },
    async created() {
        this.$parent.landing = true;
        this.$parent.setLoading(true, 'Checking envelope' + '...');

        log.x('FlowPortal: Envelope loaded', this.$parent.loadedEnvelope);
        log.x('FlowPortal: Recipient loaded', this.$parent.loadedRecipient);

        console.log(JSON.stringify(this.$parent.loadedEnvelope.userdata.mail));

        this.recipient = customFlowHelper.readRecipient(this.$parent.loadedRecipient);
        log.x('FlowPortal: Recipient processed', this.recipient);

        const metadata = customFlowHelper.getEmptyEnvelopeObject();
        customFlowHelper.readEnvelope(this.$parent.loadedEnvelope, metadata);

        log.x('FlowPortal: Metadata loaded', metadata);

        this.lang = this.$parent.loadedRecipient.language;
        const self = this;
        setTimeout(function() {
            self.setLang();
        }, 100);

        // Redirect to custom form
        if (this.recipient.action == 'edit') {
            if (this.$route.query.submit != 'yes') {
                this.$parent.landing = false;
                this.$parent.navigate({ path: '/shareapp', query: { editor: 'yes' }});
            }else {
                window.location.href = 'https://www.pangeapartners.no/pangea-project-finance/';
            }
        }

        // Redirect to checkout
        if (this.recipient.action == 'sign') {
            if (!this.recipient.completed) {
                this.redirectToCheckout();
                return;
            }else {
                window.location.href = 'https://www.pangeapartners.no/pangea-project-finance/';
            }
        }

        this.loadingComplete = true;
        this.$parent.setLoading(false);
    }
}
</script>