<template>
<div class="frmod-idc">

    <ve-popup v-if="params.type == 'connect'" :header="'Connect to ID control module'" :width="500" @close="$emit('close')" >
        <div style="padding: 12px 10px;">
            
            <vap-item fancy padding20
                :fancyIcon="'open'"
                :fancyText="'Start ID control'"
                :fancyHint="'This will trigger the ID control to start'"
                @clicked="onConnect('start')"
            />

            <vap-item fancy padding20
                :fancyIcon="'open'"
                :fancyText="'Selected method'"
                :fancyHint="'This will connect to the selected method'"
                @clicked="onConnect('method')"
            />

            <vap-item fancy padding20
                :fancyIcon="'cancel'"
                :fancyText="'Disable ID-control'"
                :fancyHint="'This will disable the ID-control if selected'"
                @clicked="onConnect('disable')"
            />

            <vap-item fancy padding20
                :fancyIcon="'open'"
                :fancyText="'BankID Norway SSN'"
                :fancyHint="'This will connect to BankID Norway SSN'"
                @clicked="onConnect('ssn-no')"
            />
            <vap-item fancy padding20
                :fancyIcon="'open'"
                :fancyText="'BankID Sweden SSN'"
                :fancyHint="'This will connect to BankID Norway SSN'"
                @clicked="onConnect('ssn-se')"
            />
            <vap-item fancy padding20
                :fancyIcon="'open'"
                :fancyText="'Passport Reader SSN'"
                :fancyHint="'This will connect to Passport Reader SSN'"
                @clicked="onConnect('ssn-pr')"
            />

            <vap-item fancy padding20
                :fancyIcon="'open'"
                :fancyText="'First Name'"
                :fancyHint="'This will connect to the first name of the selected person from the AML portal'"
                @clicked="onConnect('firstName')"
            />
            <vap-item fancy padding20
                :fancyIcon="'open'"
                :fancyText="'Last Name'"
                :fancyHint="'This will connect to the last name of the selected person from the AML portal'"
                @clicked="onConnect('lastName')"
            />
            <vap-item fancy padding20
                :fancyIcon="'open'"
                :fancyText="'Email'"
                :fancyHint="'This will connect to the email address of the selected person from the AML portal'"
                @clicked="onConnect('email')"
            />
            <vap-item fancy padding20
                :fancyIcon="'open'"
                :fancyText="'Phone'"
                :fancyHint="'This will connect to the phone number of the selected person from the AML portal'"
                @clicked="onConnect('phone')"
            />

        </div>
    </ve-popup>

    <ve-popup v-if="params.type == 'connect' && selectKey" :header="'Select key'" :width="500" @close="selectKey = false" >
        <div style="padding: 12px 10px;">
            
            <vap-item fancy padding20 v-for="(option, i) in keyOptions" :key="'ok' + i"
                :fancyIcon="option.icon"
                :fancyText="option.text"
                :fancyHint="option.hint"
                @clicked="onConnectKey(option)"
            />

        </div>
    </ve-popup>

    <PopupV1 :header="'ID Control Module Configuration'" v-if="params.type == 'manage'" :width="500" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            
            <VFCheckbox newDesign v-model="methodModel" :options="methodOptions" @input="onMethodsChanged" />

        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import idcModuleHelper from './idcModuleHelper';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';
import VFCheckbox from '../../inputs/VFCheckbox.vue';

export default {
    name: "frmod-idc",
    components: {
        PopupV1,
        VFButton,
        VFCheckbox,
    },
    props: {
        params: { type: Object, default: () => { return { type: '' } }}
    },
    data() {
        return {
            selectKey: false,
            keyOptions: [],
            dialog: {},
            methodOptions: {},
            methodModel: {
                bankidno: false,
                bankidse: false,
                touchsign: false,
            },
        }
    },
    methods: {

        onMethodsChanged() {

            for (const i in this.$parent.modules) {
                if (this.$parent.modules[i].type == 'idc') {
                    
                    const module = this.$parent.modules[i];

                    console.log('module', module);

                    for (const key in this.methodModel) {

                        module.methods[key] = this.methodModel[key];

                    }

                    console.log('methods', module.methods);
                }
            }

        },
        
        onConnect(type) {
            console.log('params', this.params);
            let close = true;

            if (!this.params.fromItem.logic) this.params.fromItem.logic = {};

            if (type == 'start') {

                this.$set(this.params.fromItem.logic, 'action', {
                    type: 'idc',
                    key: 'start'
                });

            }else if (type == 'method') {
                
                if (!this.params.fromItem.logic.link) this.params.fromItem.logic.link = [];

                this.params.fromItem.logic.link.push({
                    type: 'idc',
                    link: 'method',
                    key: ''
                });

            }else if (type == 'ssn-no' || type == 'ssn-se' || type == 'ssn-pr') {

                if (!this.params.fromItem.logic.link) this.params.fromItem.logic.link = [];

                this.params.fromItem.logic.link.push({
                    type: 'idc',
                    link: type,
                    key: ''
                });

            }else if (type == 'firstName' || type == 'lastName' || type == 'email' || type == 'phone') {
                
                this.$set(this.params.fromItem.logic, 'field', {
                    type: 'idc',
                    key: type
                });

            }else if (type == 'disable') {
                
                for (const i in this.params.fromItem.options) {
                    console.log('option', this.params.fromItem.options[i]);

                    this.keyOptions.push({
                        icon: 'input-check',
                        key: this.params.fromItem.options[i].key,
                        text: this.params.fromItem.options[i].key,
                        hint: this.params.fromItem.options[i].locale[this.$root.locale.key]
                    });

                }

                this.selectKey = true;
                this.close = false;

            }

            if (!close) this.$emit('close');
        },

        onConnectKey(option) {

            console.log('option', option);

            if (!this.params.fromItem.logic.link) this.params.fromItem.logic.link = [];

            this.params.fromItem.logic.link.push({
                type: 'idc',
                link: 'disable',
                key: option.key
            });

            this.$emit('close');

        },

    },
    created() {
        if (this.params.type == 'manage') {
            // Load available methods
            if (this.$root.config.params.authMethods.bankidno) this.methodOptions.bankidno = { label: 'BankID Norway' };
            if (this.$root.config.params.authMethods.bankidse) this.methodOptions.bankidse = { label: 'BankID Sweden' };
            if (this.$root.config.params.authMethods.passreader) this.methodOptions.passreader = { label: 'Passport Reader' };

            for (const i in this.$parent.modules) {
                if (this.$parent.modules[i].type == 'idc') {
                    if (this.$parent.modules[i].methods) {
                        if (this.$parent.modules[i].methods.bankidno) this.methodModel.bankidno = true;
                        if (this.$parent.modules[i].methods.bankidse) this.methodModel.bankidse = true;
                        if (this.$parent.modules[i].methods.passreader) this.methodModel.passreader = true;
                    }else {
                        this.methodModel.bankidno = true;
                        this.methodModel.bankidse = true;
                        this.methodModel.passreader = true;
                    }
                }
            }
        }
    }
}

</script>
<style>


</style>
