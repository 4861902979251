<template>
<div>

    <PropertyHeader :header="'Page properties'">
        <template v-slot:actions>
            <DesignerButton small
                :icon="'delete'"
                :tooltip="'Delete'"
                @clicked="$emit('delete', page)"
            />
            <DesignerButton small v-if="reference.accessLevel >= 5"
                :icon="'list'"
                :tooltip="'Log to console'"
                @clicked="logPage"
            />
            <DesignerButton small
                :icon="'chev-l'"
                :tooltip="'Move left'"
                @clicked="$emit('move', page, 'left')"
            />
            <DesignerButton small
                :icon="'chev-r'"
                :tooltip="'Move right'"
                @clicked="$emit('move', page, 'right')"
            />
            <DesignerButton small v-if="($root.config.forms.editor.advanced || $root.credentials.isAdmin)"
                :icon="'copy'"
                :tooltip="'Copy'"
                @clicked="$emit('copy', page)"
            />
        </template>
    </PropertyHeader>

    <PropertyItem padded :header="'Page'" :icon="'logic'">

        <DesignerGroup c12>
            <DesignerLabel :text="'Page title'" />
            <DesignerInput v-model="page.title[$root.locale.key]" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'page_title', $event)" />
        </DesignerGroup>
        <DesignerGroup c12 v-if="page.submitText">
            <DesignerLabel :text="'Submit text'" />
            <DesignerInput v-model="page.submitText[$root.locale.key]" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'page_submit_text', $event)" />
        </DesignerGroup>
        <DesignerGroup c12>
            <DesignerLabel :text="'Page width'" />
            <DesignerDropdown v-model="page.mode" :options="modeOptions" @before="$emit('propinput', 'check', 'page_width', $event)" />
        </DesignerGroup>

    </PropertyItem>


    <TriggerProperties v-if="!reference.temporaryDisable" :input="page" :reference="reference" hideEnabled />


    <PropertyItem padded :header="'Visibility'" :icon="'logic'">

        <DesignerGroup c6>
            <DesignerCheckbox v-model="page.showStepper" :text="'Show stepper'" @before="$emit('propinput', 'check', 'page_show_stepper', $event)" />
        </DesignerGroup>
        <DesignerGroup c6>
            <DesignerCheckbox v-model="page.hasInfo" :text="'Show help text'" @before="$emit('propinput', 'check', 'page_show_help', $event)" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Visible for roles'" />
            <DesignerCheckbox v-model="page.roles" :options="reference.roles" @before="$emit('propinput', 'check', 'page_role_visible', $event)" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>

<script>
import PropertyHeader from './ui/PropertyHeader.vue';
import PropertyItem from './properties/PropertyItem.vue';
import DesignerButton from './ui/DesignerButton.vue';

import DesignerGroup from './ui/inputs/DesignerGroup.vue';
import DesignerLabel from './ui/inputs/DesignerLabel.vue';
import DesignerInput from './ui/inputs/DesignerInput.vue';
import DesignerCheckbox from './ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from './ui/inputs/DesignerDropdown.vue';

import TriggerProperties from './properties/TriggerProperties.vue';

export default {
    name: 'PagePropertiesV2',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
        TriggerProperties,
    },
    props: {
        page: { type: Object, default: null },
        reference: { type: Object, default: null },
    },
    data() {
        return {
            modeOptions: [
                { value: 'form-page', display: 'Default' },
                { value: 'thin-page', display: 'Slim page' },
                { value: 'wide-page', display: 'Wide page' },
                { value: 'popup-page', display: 'Popup page' },
                { value: 'report-page', display: 'Report page' },
            ]
        }
    },
    methods: {

        logPage() {
            console.log('page', this.page);
        },

    }
}
</script>