import formHelper from './formHelper';
import flowHelper from './flowHelper';
import log from '@/log';

const testHelper = {

    defaultTexts: [
        '[EMPTY TEXT]',
    ],

    checkForProblems(reference) {
        const result = {
            ok: true,
            flowProblems: [],
            logicProblems: [],
            textProblems: [],
        };

        const keyList = {};

        const recipients = {

        };

        for (const i in reference.roleOptions) {
            recipients[reference.roleOptions[i].key] = {
                firstName: false,
                lastName: false,
                email: false,
            };
        }

        

        for (const i in reference.pages) {

            const page = reference.pages[i];
            if (keyList[page.key]) {
                result.logicProblems.push({
                    message: 'Duplicate key "' + page.key + '" detected'
                });
            }
            keyList[page.key] = true;



            // Page title
            if (page.title) {
                const localeResult = this.checkText(reference, page.title);
                for (const x in localeResult.problems) {
                    result.textProblems.push({
                        level: localeResult.problems[x].level,
                        message: localeResult.problems[x].text + ' found in page title (' + page.key + ')',
                        locale: localeResult.problems[x].key
                    });
                }
            }

            // Help texts
            if (page.hasInfo) {
                for (const rKey in page.info) {

                    for (const l in page.info[rKey]) {
                        const line = page.info[rKey][l];
                        if (line.header) {
                            const localeResult = this.checkText(reference, line.header);
                            for (const x in localeResult.problems) {
                                result.textProblems.push({
                                    level: localeResult.problems[x].level,
                                    message: localeResult.problems[x].text + ' found in helptext header (' + line.key + ')',
                                    locale: localeResult.problems[x].key
                                });
                            }
                        }
                        if (line.text) {
                            const localeResult = this.checkText(reference, line.text);
                            for (const x in localeResult.problems) {
                                result.textProblems.push({
                                    level: localeResult.problems[x].level,
                                    message: localeResult.problems[x].text + ' found in helptext (' + line.key + ')',
                                    locale: localeResult.problems[x].key
                                });
                            }
                        }
                    }

                }
            }


            for (const j in page.cards) {

                const card = page.cards[j];
                if (keyList[card.key]) {
                    result.logicProblems.push({
                        message: 'Duplicate key "' + card.key + '" detected'
                    });
                }
                keyList[card.key] = true;

                const personCard = {
                    isPerson: page.cards[j].isPerson || false,
                    isList: page.cards[j].type == 'list'
                };


                // Card header
                if (card.header) {
                    const localeResult = this.checkText(reference, card.header);
                    for (const x in localeResult.problems) {
                        result.textProblems.push({
                            level: localeResult.problems[x].level,
                            message: localeResult.problems[x].text + ' found in card header (' + card.key + ')',
                            locale: localeResult.problems[x].key
                        });
                    }
                }



                for (const k in card.inputs) {

                    const input = card.inputs[k];
                    if (keyList[input.key]) {
                        result.logicProblems.push({
                            message: 'Duplicate key "' + input.key + '" detected'
                        });
                    }
                    keyList[input.key] = true;


                    // Person card
                    if (personCard.isPerson) {
                        personCard[personCard.isList ? input.listModel : input.modelKey] = true;
                    }


                    // Check flow
                    if (input.flow) {
                        if (input.flow.field) {
                            if (recipients[input.flow.field.rkey] && recipients[input.flow.field.rkey][input.flow.field.type] != null) {
                                recipients[input.flow.field.rkey][input.flow.field.type] = true;
                            }
                        }
                    }

                    // Check description
                    if (input.desc) {
                        const localeResult = this.checkText(reference, input.desc);
                        for (const x in localeResult.problems) {
                            result.textProblems.push({
                                level: localeResult.problems[x].level,
                                message: localeResult.problems[x].text + ' found in input description (' + input.key + ')',
                                locale: localeResult.problems[x].key
                            });
                        }
                    }

                    // Check options
                    if (input.options) {
                        for (const l in input.options) {
                            const option = input.options[l];
                            if (keyList[l]) {
                                result.logicProblems.push({
                                    message: 'Duplicate key "' + l + '" detected',
                                });
                            }
                            keyList[l] = true;
                            if (option.locale) {
                                const localeResult = this.checkText(reference, option.locale);
                                for (const x in localeResult.problems) {
                                    result.textProblems.push({
                                        level: localeResult.problems[x].level,
                                        message: localeResult.problems[x].text + ' found in checkbox option (' + l + ')',
                                        locale: localeResult.problems[x].key
                                    });
                                }
                            }
                        }
                    }

                    // Check items
                    if (input.items) {
                        for (const l in input.items) {
                            const item = input.items[l];
                            if (keyList[item.key]) {
                                result.logicProblems.push({
                                    message: 'Duplicate key "' + item.key + '" detected'
                                });
                            }
                            keyList[item.key] = true;
                            const localeResult = this.checkText(reference, item);
                            for (const x in localeResult.problems) {
                                result.textProblems.push({
                                    level: localeResult.problems[x].level,
                                    message: localeResult.problems[x].text + ' found in dropdown item (' + item.key + ')',
                                    locale: localeResult.problems[x].key
                                });
                            }
                        }
                    }

                    // Check logic
                    if (input.logic) {

                        if (input.logic.visible) {

                            const keys = [];

                            for (const x in input.logic.visible.keys) {

                                if (keys.includes(input.logic.visible.keys[x].key)) {

                                    result.logicProblems.push({
                                        message: 'Duplicate key "' + input.logic.visible.keys[x].key + '" in visible logic'
                                    });

                                }

                                keys.push(input.logic.visible.keys[x].key);

                            }

                        }

                    }

                }

                // Person card
                //console.log('personCard', personCard);
                if (personCard.isPerson) {
                    if (!personCard.firstName) {
                        result.logicProblems.push({
                            key: card.key,
                            message: 'Card "' + card.key + '" is defined as a person, but missing input for "' + 'firstName' + '"'
                        });
                    }
                    if (!personCard.lastName) {
                        result.logicProblems.push({
                            key: card.key,
                            message: 'Card "' + card.key + '" is defined as a person, but missing input for "' + 'lastName' + '"'
                        });
                    }
                    if (!personCard.dateOfBirth) {
                        result.logicProblems.push({
                            key: card.key,
                            message: 'Card "' + card.key + '" is defined as a person, but missing input for "' + 'dateOfBirth' + '"'
                        });
                    }
                }

            }

        }

        console.log('recipients', recipients);

        for (const rKey in recipients) {

            if (rKey == 'owner' || rKey == 'done') continue;

            for (const key in recipients[rKey]) {

                if (!recipients[rKey][key]) {
                    result.flowProblems.push({
                        level: 7,
                        message: 'Missing field "' + key + '" for recipient "' + rKey + '"'
                    });
                }

            }

        }

        if (reference.formData.flowType == 'internal') {
            console.log('internal flow', reference.formData.flowConfig);

            let match = false;
            const roleOptions = flowHelper.getRoleOptions();

            for (const i in roleOptions) {
                if (roleOptions[i].action == 'edit' && recipients[roleOptions[i].key]) {
                    match = true;
                }
            }
            if (!match) {
                result.flowProblems.push({
                    level: 7,
                    message: 'Internal flow but no editors'
                });
            }

        }

        return result;
    },

    checkText(reference, texts) {
        const result = {
            problems: [],
        };
        for (const i in reference.locales) {

            if (texts[reference.locales[i].key]) {

                if (this.defaultTexts.includes(texts[reference.locales[i].key])) {

                    result.problems.push({
                        level: 3,
                        type: 'default',
                        text: texts[reference.locales[i].key],
                        key: reference.locales[i].key
                    });

                }

            }else {
                result.problems.push({
                    type: 'missing',
                    key: reference.locales[i].key
                });
            }

        }
        return result;
    },
    
};
export default testHelper;