<template>
<div @click="onDeselect">
    
    <FlowStepV2 v-for="(step, i) in flow.steps" :key="'ds' + i"
        :step="step"
        :selected="selectedIndex == i"
        @clicked="onSelectStep(i)"
    />

</div>
</template>

<script>
import FlowStepV2 from './FlowStepV2.vue';

export default {
    name: 'FlowRendererV2',
    components: {
        FlowStepV2,
    },
    props: {
        flow: { type: Object, default: null },
    },
    data() {
        return {
            selectedIndex: -1,
        }
    },
    methods: {

        async onSave(data) {
            
        },

        onSelectStep(index) {
            this.selectedIndex = index;
        },

        onDeselect() {
            this.selectedIndex = -1;
        },

    },
    async created() {
        console.log('flow', this.flow);
        

    }
}
</script>