<template>
<div>
    
    <PropertyHeader :header="'View mode'">
        <template v-slot:actions>
            
        </template>
    </PropertyHeader>

    <PropertyItem padded :header="'View mode'" :icon="'eye'">
        <!-- <div>

            <vap-button :text="'Save userdata'" @clicked="onSaveUserdata" />
            <vap-button :text="'Load userdata'" @clicked="onLoadUserdata" />

        </div> -->
    </PropertyItem>

</div>
</template>
<script>
import formHelper from '../../formHelper';

import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';

export default {
    name: 'ViewPropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() { 
        return {
            
        }
    },
    methods: {

        onSaveUserdata() {

            const pages = formHelper.stripPages(this.reference.pages);

            console.log('pages', pages);

            localStorage.setItem('frv2ud', JSON.stringify(pages));

        },

        onLoadUserdata() {

            const pages = JSON.parse(localStorage.getItem('frv2ud'));

            console.log('pages', pages);

            const userdata = {
                formPages: pages
            };

            this.$emit('userdata', userdata);

        },

    },
    created() {
        
    }
}
</script>