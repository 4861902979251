<template>
<div class="vf-methods" @click="handleClick" :class="[
    { 'vf-methods-selected' : selected },
    { 'vf-methods-phone' : $root.phoneMode },
]">
    
    <div class="method-help">

        <div class="method-help-item" v-if="isAvailable('bankid-no')">
            <div class="method-help-head">
                <label>{{ __('method_bankidno_head') }}</label>
            </div>
            <div class="method-help-help">
                <label>{{ __('method_bankidno_help') }}</label>
            </div>
        </div>

        <div class="method-help-item" v-if="isAvailable('bankid-se')">
            <div class="method-help-head">
                <label>{{ __('method_bankidse_head') }}</label>
            </div>
            <div class="method-help-help">
                <label>{{ __('method_bankidse_help') }}</label>
            </div>
        </div>

        <div class="method-help-item" v-if="isAvailable('nets-esign-dk')">
            <div class="method-help-head">
                <label>{{ __('method_nemidnets_head') }}</label>
            </div>
            <div class="method-help-help">
                <label>{{ __('method_nemidnets_help') }}</label>
            </div>
        </div>

        <div class="method-help-item" v-if="isAvailable('touch-sign')">
            <div class="method-help-head">
                <label>{{ __('method_touchsign_head') }}</label>
            </div>
            <div class="method-help-help">
                <label>{{ __('method_touchsign_help') }}</label>
            </div>
        </div>

        <div class="method-help-item" v-if="isAvailable('passport-reader')">
            <div class="method-help-head">
                <label>{{ __('method_passreader_head') }}</label>
            </div>
            <div class="method-help-help">
                <label>{{ __('method_passreader_help') }}</label>
            </div>
        </div>

    </div>

    <div class="flw100" style="text-align: center; margin-top: 30px;">
        <label style="color: var(--text-weak);">digital signing and authentication powered by</label><br>
        <img :src="$root.images.vfblue" style="width: 160px; margin-top: 8px;" /><br>
    </div>

</div>
</template>
<script>
export default {
    name: 'vf-methods',
    props: {
        cardData: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    data() {
        return {
            methods: []
        }
    },
    watch: {
        cardData: {
            deep: true,
            handler(val) {
                this.loadMethods();
            }
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

        isAvailable(key) {
            return this.methods.includes(key);
        },

        loadMethods() {
            this.methods = [];
            for (const i in this.reference.modules) {

                if (this.reference.modules[i].type == 'sign' && this.cardData.mode == 'sign') {
                    const config = this.$root.config.params.signMethods;

                    if (this.reference.modules[i].methods) {
                        if (this.reference.modules[i].methods.bankidno && config.bankidno) this.methods.push('bankid-no');
                        if (this.reference.modules[i].methods.bankidse && config.bankidse) this.methods.push('bankid-se');
                        if (this.reference.modules[i].methods.touchsign && config.touchsign) this.methods.push('touch-sign');
                    }

                }else if (this.reference.modules[i].type == 'idc' && this.cardData.mode == 'auth') {
                    const config = this.$root.config.params.authMethods;

                    if (this.reference.modules[i].methods) {
                        if (this.reference.modules[i].methods.bankidno && config.bankidno) this.methods.push('bankid-no');
                        if (this.reference.modules[i].methods.bankidse && config.bankidse) this.methods.push('bankid-se');
                        if (this.reference.modules[i].methods.passreader && config.passreader) this.methods.push('passport-reader');
                    }

                }
            }
        },

    },
    created() {
        this.loadMethods();
    }
}
</script>
<style scoped>

.vf-methods {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.vf-methods-phone {
    padding: 0px 16px;
}


/* Designer / Selection */

.vf-methods-selected {
    outline: solid 2px #238ccc;
}

</style>
