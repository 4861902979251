<template>
<div>

    <div v-if="showEmail">
        <div class="vepv2-header">
            {{ __t('common-email').toUpperCase() }}
        </div>
        <div class="vepv2-roles">
            <div class="vepv2-role" v-for="(item, i) in emails" :key="'pe' + i">
                <div class="vepv2-role-name">
                    {{ item.display }}
                </div>
                <div class="vepv2-role-source" v-html="getSourceDisplay(item.source)" />
            </div>
        </div>
        <div class="vepv2-empty" v-if="emails.length == 0">{{ __t('common-not-available') }}</div>
    </div>

    <div v-if="showRoles">
        <div class="vepv2-header">
            {{ __t('common-roles').toUpperCase() }}
        </div>
        <div class="vepv2-roles">
            <div class="vepv2-role" v-for="(role, i) in roles" :key="'pr' + i">
                <div class="vepv2-role-name">
                    {{ getSingleRoleText(role) + (role.newShare ? ' - ' + role.newShare + '%' : '') }}
                </div>
                <div class="vepv2-role-source" v-if="role.id == 'role_kyc_pep'">
                    {{ role.pepTitle }}
                </div>
                <div class="vepv2-role-source">
                    {{ getSourceText(role, person) }}
                </div>
            </div>
        </div>
        <div class="vepv2-empty" v-if="roles.length == 0">{{ __t('common-not-available') }}</div>
    </div>

    <div v-if="showAddress">
        <div class="vepv2-header">
            {{ __t('common-address').toUpperCase() }}
        </div>
        <div class="vepv2-roles">
            <div class="vepv2-role" v-for="(item, i) in addresses" :key="'pa' + i">
                <div class="vepv2-role-name">
                    {{ getAddressDisplay(item) }}
                </div>
                <div class="vepv2-role-source" v-html="getSourceDisplay(item.source)" />
            </div>
        </div>
        <div class="vepv2-empty" v-if="addresses.length == 0">{{ __t('common-not-available') }}</div>
    </div>

    <div v-if="showCitizenship">
        <div class="vepv2-header">
            {{ __t('person-citizenship').toUpperCase() }}
        </div>
        <div class="vepv2-roles">
            <div class="vepv2-role" v-for="(item, i) in citizenships" :key="'pa' + i">
                <div class="vepv2-role-name">
                    {{ getCitizenshipDisplay(item.value) }}
                </div>
                <div class="vepv2-role-source" v-html="getSourceDisplay(item.source)" />
            </div>
        </div>
        <div class="vepv2-empty" v-if="citizenships.length == 0">{{ __t('common-not-available') }}</div>
    </div>
    
</div>
</template>
<script>
import personhelper from '../helpers/personhelper';
import dbPersonHelper from '../v1helpers/dbPersonHelper';
import v1personHelper from '../v1helpers/v1personHelper';

export default {
    name: 'PersonDetails',
    props: {
        person: { type: Object, default: null },
        client: { type: Object, default: null },
        showEmail: { type: Boolean, default: false },
        showRoles: { type: Boolean, default: false },
        showAddress: { type: Boolean, default: false },
        showCitizenship: { type: Boolean, default: false },
    },
    data() {
        return {
            roles: [],
            emails: [],
            addresses: [],
            citizenships: [],
        }
    },
    methods: {

        //#region Roles -------------------------------------------------------------------------------------------------------

        getSingleRoleText(role) {

            let str;

            if (role.roleName) {
                str = role.roleName;
            } else {
                str = this.__(role.id);
            }
            
            if (role.share) {
                str += ' (' + role.share + '%)';
            }
            return str;
        },

        getSourceText(role, person) {
            let sources = [];
            for (const i in person.roles) {
                if (role.id == person.roles[i].id) {
                    sources.push(person.roles[i].source);
                }
            }
            if (sources.includes('org') && sources.includes('kyc')) {
                return this.__t('person-source') + ': ' + this.__t('person-source-kyc-confirm');
            }else if (sources.includes('kyc')) {
                return this.__t('person-source') + ': ' + this.__t('person-source-kyc-add');
            }else if (sources.includes('org')) {
                return this.__t('person-source') + ': ' + this.__t('person-source-org');
            }else if (sources.includes('man')) {
                return this.__t('person-source') + ': ' + this.__t('person-source-man-add') + this.getEmployeeName(role.userId);
            }
            return role.source;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        getAddressDisplay(address) {
            return dbPersonHelper.formatAddressString(this.$root, address);
        },

        getCitizenshipDisplay(citizenship) {
            return this.getCountryName(citizenship);
        },

        getSourceDisplay(source) {
            return v1personHelper.getSourceDisplay(this.$root, source);
        },

    },
    created() {
        //console.log('PERSON', this.person);

        const addresses = [];

        for (const i in this.person.roles) {
            let match = false;
            for (const j in this.roles) {
                if (this.roles[j].id == this.person.roles[i].id) {
                    match = true;
                    if (this.person.roles[i].source == 'kyc') {
                        if (this.roles[j].share != this.person.roles[i].share) this.roles[j].newShare = this.person.roles[i].share;
                    }
                }
            }
            if (!match) {
                const newRole = this.person.roles[i];
                if (this.person.roles[i].id == 'role_kyc_pep') {
                    if (this.person.kycPep && this.person.kycPep.reported) {
                        newRole.pepTitle = this.person.kycPep.title;
                    }
                }
                this.roles.push(newRole);
            }
        }

        for (const i in this.client.kyc.roles) {
            if (personhelper.isSamePerson(this.client.kyc.roles[i], this.person)) {
                if (this.client.kyc.roles[i].email) {
                    this.emails.push({
                        display: this.client.kyc.roles[i].email,
                        source: 'kyc'
                    });
                }
            }
        }
        for (const i in this.client.kyc.beneficials) {
            if (personhelper.isSamePerson(this.client.kyc.beneficials[i], this.person)) {
                if (this.client.kyc.beneficials[i].email) {
                    this.emails.push({
                        display: this.client.kyc.beneficials[i].email,
                        source: 'kyc'
                    });
                }
            }
        }
        for (const i in this.client.kyc.shareholders) {
            if (personhelper.isValidPerson(this.client.kyc.shareholders[i]) && personhelper.isSamePerson(this.client.kyc.shareholders[i], this.person)) {
                if (this.client.kyc.shareholders[i].email) {
                    this.emails.push({
                        display: this.client.kyc.shareholders[i].email,
                        source: 'kyc'
                    });
                }
            }
        }

        if (this.person.idc.done) {
            if (this.person.idc.email) {
                this.emails.push({
                    display: this.person.idc.email,
                    source: 'idc'
                });
            }
        }

        for (const i in this.person.emails) {
            this.emails.push({
                display: this.person.emails[i].value,
                source: this.person.emails[i].source,
            });
        }

        for (const i in this.person.addresses) {
            addresses.push(this.person.addresses[i]);
        }

        for (const i in addresses) {
            let match = false;
            for (const j in this.addresses) {
                if (this.addresses[j].street.toLowerCase() == addresses[i].street.toLowerCase() && this.addresses[j].source == addresses[i].source) {
                    match = true;
                }
            }
            if (!match) this.addresses.push(addresses[i]);
        }

        for (const i in this.person.citizenships) {
            this.citizenships.push(this.person.citizenships[i]);
        }

    }
}
</script>
<style>

.vepv2-header {
    margin-top: 15px;
    font-size: 13px;
    color: var(--text-weak);
}

.vepv2-roles {
    
}
.vepv2-role {
    margin-top: 2px;
    margin-bottom: 7px;
}
.vepv2-role-name {
    font-size: 16px;
    color: var(--text-strong);
}
.vepv2-role-source {
    font-size: 15px;
    color: var(--text-weak);
}

.vepv2-empty {
    margin-top: 2px;
    font-size: 14px;
    color: var(--text-disabled);
}


.vepv2-warning-icon {
    flex-shrink: 0;
	width: 16px; height: 16px;
    margin-right: 6px;
    padding-top: 1px;
    font-size: 12px;
    font-weight: 500;
    color: black;
    text-align: center;
    border-radius: 50%;
    background-color: #EDC250;
    box-sizing: border-box;
}

</style>