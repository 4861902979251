<template>
<div class="vf-uc" @click.stop="handleClick" :class="[
    { 'vf-selected' : selected }
]">
    
    <div v-if="designer">
        {{ 'Universal checkout' }}
    </div>

</div>
</template>

<script>
export default {
    name: 'VFCheckoutU',
    props: {
        cardData: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    data() {
        return {
            
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

    },
    mounted() {
        
    }
}
</script>
<style scoped>

.vf-uc {
    float: left;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}




/* Designer / Selection */

.vf-selected {
    outline: solid 2px #238ccc;
}

</style>
