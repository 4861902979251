<template>
    <div>

        <ve-popup :header="'Lagre i iManage'" :width="800" @close="abort">
            <div v-if="saving" class="flw100">
                <div class="flw100" style="padding-left: 410px; padding-top: 80px; padding-bottom: 30px;">
                    <vap-spinner medium />
                </div>
                <div class="flw100" style="text-align: center; font-size: 20px; padding-bottom: 110px;">
                    <label>Lagrer sluttrapport...</label>
                </div>
            </div>

            <div v-if="!saving" style="padding: 20px;">
                <div style="padding-bottom: 10px; color: red;" v-if="wrong">
                    <label >Du har valgt feil mappe, prøv på nytt</label>
                </div>
                <div class="imanage-step">
                    <div class="imanage-step-title">
                        <div class="imanage-number">
                            <label>1</label>
                        </div>
                        <div class="imanage-title">
                            <label>Trykk på "START" for å finne iManage-mappen på din PC</label>
                        </div>
                    </div>
                </div>
                <div class="imanage-step">
                    <div class="imanage-step-title">
                        <div class="imanage-number">
                            <label>2</label>
                        </div>
                        <div class="imanage-title">
                            <label>Naviger til "Denne PCen > (Z:) > SUPPORT > AML Reporting</label>
                        </div>
                    </div>
                    <img src="images/imanage1.png" />
                </div>
                <div class="imanage-step">
                    <div class="imanage-step-title">
                        <div class="imanage-number">
                            <label>3</label>
                        </div>
                        <div class="imanage-title">
                            <label>Trykk på "Velg mappe" ("Select Folder")</label>
                        </div>
                    </div>
                </div>
                <div class="imanage-step">
                    <div class="imanage-step-title">
                        <div class="imanage-number">
                            <label>4</label>
                        </div>
                        <div class="imanage-title">
                            <label>Trykk på "Vis filer" og så "Lagre endringer"</label>
                        </div>
                    </div>
                    <div class="imanage-imgsplit">
                        <img src="images/imanage2.png" />
                        <img src="images/imanage3.png" />
                    </div>
                </div>
                <div class="imanage-step">
                    <div class="imanage-step-title">
                        <div class="imanage-number">
                            <label>5</label>
                        </div>
                        <div class="imanage-title">
                            <label>Ferdig. Sluttrapporten blir nå lagret med korrekt navn i iManage</label>
                        </div>
                    </div>
                </div>
                <vap-button :text="'START'" @clicked="saveFile" />
            </div>
        </ve-popup>
        
    </div>
</template>

<script>
import backend from '../backend';
import api from '../portalapi';
import tools from '../tools';

export default {
    name: 'IManage',
    props: {
        customer: {
            type: Object,
            default: null
        },
        person: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            show: false,
            wrong: false,
            saving: false
        }
    },
    methods: {
        abort() {
            this.$emit('close', true);
        },

        async saveFile() {
            this.wrong = false;
            let name = this.customer.name + ' (' + this.customer.identity + ')';
            let dirMatch = 'Norske Selskaper';
            if (this.customer.flow == 'org-manual' || this.customer.flow == 'org-no-unreg') dirMatch = 'Utenlandske Selskaper';
            if (this.customer.flow == 'person') {
                dirMatch = 'Personer';
                name = this.customer.name + ' (' + (this.person.info.ssn || this.person.info.dateOfBirth) + ')';
            }
        
            let noHandle = undefined;

            const dirHandle = await window.showDirectoryPicker();
            for await (const entry of dirHandle.values()) {
                //console.log(entry.kind, entry.name);
                if (entry.kind == 'directory' && entry.name == dirMatch) noHandle = entry;
            }

            if (!noHandle) {
                this.wrong = true;
                return;
            }

            

            for await (const entry of noHandle.values()) {
                console.log(entry.kind, entry.name);
                
                if (entry.kind == 'directory' && entry.name.includes('(' + this.customer.identity + ')')) {
                    console.log('match');
                }

            }


            // In an existing directory, create a new directory named "My Documents".
            const newDirectoryHandle = await noHandle.getDirectoryHandle(name, {
                create: true,
            });

            this.saving = true;

            const dstr = new Date(this.customer.report.reportDate).toISOString().substring(0, 10);
            // In this new directory, create a file named "My Notes.txt".
            const newFileHandle = await newDirectoryHandle.getFileHandle(name + ' ' + dstr + '.pdf', { create: true });


            const fileData = await this.$parent.downloadCompleteReport();

            // Create a FileSystemWritableFileStream to write to.
            const writable = await newFileHandle.createWritable();
            // Write the contents of the file to the stream.
            await writable.write(this.getBlob(fileData));
            // Close the file and write the contents to disk.
            await writable.close();

            const metadata = {
                imanage: {
                    saveDate: new Date(),
                    userId: this.$root.credentials.uid
                }
            };
            const response = await api.setMetadata(this.customer._id, metadata);
            const customer = response.customer;
            this.$parent.onReloadRequested(customer);

            this.abort();
        },

        getBlob(base64Data, contentType) {
            contentType = contentType || '';
            var sliceSize = 1024;
            var byteCharacters = atob(base64Data);
            var bytesLength = byteCharacters.length;
            var slicesCount = Math.ceil(bytesLength / sliceSize);
            var byteArrays = new Array(slicesCount);

            for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
                var begin = sliceIndex * sliceSize;
                var end = Math.min(begin + sliceSize, bytesLength);

                var bytes = new Array(end - begin);
                for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                    bytes[i] = byteCharacters[offset].charCodeAt(0);
                }
                byteArrays[sliceIndex] = new Uint8Array(bytes);
            }
            return new Blob(byteArrays, { type: contentType });
        }

    },
    async mounted() {
        

    }
}
</script>
<style scoped>

.imanage-step-title {
    display: flex;
    margin-bottom: 15px;
}

.imanage-step img {
    width: 100%;
    margin-bottom: 10px;
}

.imanage-number {
    width: 30px; height: 30px;
    padding-top: 3px;
    text-align: center;
    border-radius: 15px;
    border: solid 2px black;
    box-sizing: border-box;
}

.imanage-title {
    margin-top: 5px;
    margin-left: 10px;
}

.imanage-imgsplit {

}
.imanage-imgsplit img {
    width: 50%;
    margin-bottom: 10px;
}

</style>