<template>
<div class="v2rep-fields">

    <div class="v2rep-fields-cats">
        <div class="v2rep-fields-cat" @click="$emit('add', 'client')">
            <vap-icon small :icon="'locale'" />
        </div>
        <div class="v2rep-fields-cat" @click="$emit('add', 'company')">
            <vap-icon small :icon="'company'" />
        </div>
        <div class="v2rep-fields-cat" @click="$emit('add', 'person')">
            <vap-icon small :icon="'person'" />
        </div>
    </div>

    <div class="v2rep-field" v-for="(field, i) in fields" :key="'rf' + i"
        :id="'i' + field.key" :class="{'v2rep-field-hide': sourceIndex == i}"
    >

        <div class="v2rep-field-handle" :id="'h' + field.key">
            <vap-icon small :icon="field.icon" style="pointer-events: none;" />
        </div>

        <div class="v2rep-field-default">
            <VFCheckbox :newDesign="true"
                v-model="field.selected"
                :text="''"
                @input="$emit('changed')"
            />
        </div>

        <div class="v2rep-field-text" @click="onTextClicked(field)">
            {{ field.name[$root.locale.key] }}
        </div>

        <div class="v2rep-field-delete" @click="$emit('delete', i)">
            <vap-icon small :icon="'delete'" />
        </div>


        <div class="v2rep-field-drop" v-if="isDragging"
            @dragenter="onDragEnter(i)"
            @dragleave="onDragLeave"
            @drop="handleDrop($event, i)"
            :class="{'v2rep-field-hover': hoveredIndex == i }"
        />
        <div class="v2rep-field-drop v2rep-field-drop2" v-if="isDragging && i == fields.length - 1"
            @dragenter="onDragEnter(i + 1)"
            @dragleave="onDragLeave"
            @drop="handleDrop($event, i + 1)"
            :class="{'v2rep-field-hover2': hoveredIndex == i + 1 }"
        />

    </div>

</div>
</template>
<script>
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'ReportsV2',
    components: {
        VFCheckbox,
    },
    props: {
        fields: { type: Array, default: () => { return [] } },
    },
    data() {
        return {
            isDragging: false,
            sourceIndex: -1,
            hoveredIndex: -1,
        }
    },
    methods: {

        onTextClicked(field) {
            field.selected = !field.selected;
            this.$emit('changed');
        },
        
        //#region Drag & Drop -------------------------------------------------------------------------------------------------

        onDragStart(index) {
            this.isDragging = true;
            
            this.$nextTick(() => {
                const stuff = document.getElementsByClassName('v2rep-field-drop');
                for (const item of stuff) {
                    item.addEventListener('dragover', event => {
                        event.preventDefault();
                    });
                }
            });

            const self = this;
            setTimeout(function() {
                self.sourceIndex = index;
            }, 10);
        },

        onDragEnd() {
            this.isDragging = false;
            this.sourceIndex = -1;
        },

        onDragEnter(index) {
            this.hoveredIndex = index;
        },

        onDragLeave() {
            this.hoveredIndex = -1;
        },

        getItemIndex(index) {
            if (index < this.sourceIndex) return index;
            return index - 1;
        },

        handleDrop(e, index) {
            //const data = e.dataTransfer.getData("text/aml");

            this.onDragLeave();
            this.isDragging = false;

            const targetIndex = this.getItemIndex(index);

            while (this.sourceIndex < 0) this.sourceIndex += this.fields.length;
            while (targetIndex < 0) targetIndex += this.fields.length;
            this.fields.splice(targetIndex, 0, this.fields.splice(this.sourceIndex, 1)[0]);

            this.$emit('changed');
        },

        enableDragging() {
            const self = this;
            this.$nextTick(() => {
                for (const i in this.fields) {
                    const draggable = document.getElementById('i' + this.fields[i].key);
                    const handle = document.getElementById('h' + this.fields[i].key);
                    handle.onmousedown = function(e) {
                        e.target.parentNode.setAttribute('draggable', 'true')
                    };
                    handle.onmouseup = function(e) {
                        e.target.parentNode.setAttribute('draggable', 'false')
                    };
                    draggable.ondragstart = function(e) {
                        e.dataTransfer.setData('text/aml', self.fields[i].key);
                        self.onDragStart(i);
                    };
                    draggable.ondragend = function(e) {
                        e.target.setAttribute('draggable', 'false');
                        self.onDragEnd();
                    };
                }
            });
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {
        this.enableDragging();
    }
}
</script>
<style scoped>

.v2rep-fields {
    position: relative;
    float: left;
    width: 100%;
    padding: 0px 5px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.v2rep-fields-cats {
    position: absolute;
    display: flex;
    top: -40px; right: 5px;
    width: 99px; height: 30px;
    border: solid 1px var(--input-border);
    border-right: none;
    box-sizing: border-box;
}
.v2rep-fields-cat {
    width: 33px; height: 30px;
    padding-top: 1px;
    padding-left: 2px;
    border-right: solid 1px var(--input-border);
    box-sizing: border-box;
    cursor: pointer;
}
.v2rep-fields-cat:hover {
    background-color: var(--item-hover);
}


.v2rep-field {
    position: relative;
    display: flex;
    height: 30px;
    margin-top: -1px;
    border: solid 1px var(--input-border);
}

.v2rep-field-hide {
    display: none;
}

.v2rep-field-handle {
    width: 31px;
    cursor: pointer;
}
.v2rep-field-handle:hover {
    background-color: var(--item-hover);
}

.v2rep-field-default {
    width: 30px;
    border-left: solid 1px var(--input-border);
}

.v2rep-field-key {
    width: 30px;
    border-left: solid 1px var(--input-border);
}

.v2rep-field-text {
    flex-grow: 100;
    padding-top: 7px;
    padding-left: 7px;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-title);
    border-left: solid 1px var(--input-border);
    cursor: pointer;
}

.v2rep-field-delete {
    display: none;
    position: absolute;
    height: 30px;
    top: 0px; right: 0px;
    border-left: solid 1px var(--input-border);
    background-color: var(--page-background);
    cursor: pointer;
}
.v2rep-field-delete:hover {
    background-color: var(--item-hover);
}
.v2rep-field:hover .v2rep-field-delete {
    display: initial;
}

.v2rep-field-drop {
    position: absolute;
    top: -6px; height: 11px;
    left: 60px; right: 60px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--text-weak);
    opacity: 0.3;
}
.v2rep-field-hover {
    opacity: 0.8;
    top: -8px; height: 17px;
}

.v2rep-field-drop2 {
    top: 25px;
}
.v2rep-field-hover2 {
    opacity: 0.8;
    top: 22px; height: 17px;
}

</style>