<template>
<div class="fbinp-select">
    
    <div class="fbinp-select-input" v-if="isInput" >
        <input
            :value="value"
            :placeholder="placeholder"
            @input="handleInput"
            :disabled="disabled"
        />
    </div>

    <div class="fbinp-select-text" v-if="!isInput && text" @click="$emit('clicked')">
        {{ text }}
    </div>
    <div class="fbinp-select-ph" v-if="!isInput && !text" @click="$emit('clicked')">
        {{ placeholder }}
    </div>

    <div class="fbinp-select-link" @click="$emit('clicked')">
        <vap-icon tiny :icon="'open'">
            <template v-slot:tooltip v-if="tooltip">
                <vap-tooltip :text="tooltip" />
            </template>
        </vap-icon>
    </div>

</div>
</template>
<script>
export default {
    name: 'DesignerSelector',
    props: {
        value: [String, Number],
        isInput: { type: Boolean, default: false },
        text: { type: String, default: '' },
        tooltip: { type: String, default: '' },
        placeholder: { type: String, default: 'Not set' },
        disabled: { type: Boolean, default: false },
    },
    methods: {

        handleInput(event) {
            this.$emit('before');
            this.$emit('input', event.srcElement.value);
        },

    }
}
</script>
<style scoped>

.fbinp-select {
    display: flex;
    width: 100%;
    height: 26px;
    background-color: var(--fb-input-bg);
    box-sizing: border-box;
}

.fbinp-select-text {
    flex-grow: 100;
    padding-top: 5px;
    padding-left: 5px;
    font-size: 14px;
    color: var(--fb-text-2);
}

.fbinp-select-ph {
    flex-grow: 100;
    padding-top: 5px;
    padding-left: 5px;
    font-size: 14px;
    color: var(--fb-text-4);
    font-weight: 500;
    letter-spacing: 0.6px;
}

.fbinp-select-link {
    flex-shrink: 0;
    width: 24px;
    padding-top: 2px;
    padding-left: 2px;
    border-left: solid 1px var(--fb-menu-b);
    cursor: pointer;
}
.fbinp-select-link:hover {
    background-color: var(--fb-menu-a);
}

.fbinp-select-input {
    flex-grow: 100;
}

.fbinp-select-input input {
    width: 100%;
    border: none;
    background: none;
    outline: none;
    height: 26px;
    padding: 0px 5px;
    padding-top: 1px;
    font-size: 14px;
    color: var(--fb-text-1);
    border: solid 1px var(--fb-input-bg);
    background-color: var(--fb-input-bg);
    box-sizing: border-box;
}

.fbinp-select-input input:focus {
    border-color: gray;
}

.fbinp-select-input input::placeholder {
    color: var(--fb-text-4);
    font-weight: 500;
    letter-spacing: 0.6px;
}

</style>