<template>
<div class="flw100" style="position: relative; padding-top: 100px;">
    <div style="position: absolute; left: 50%;"><vap-spinner medium /></div>
</div>
</template>
<script>

export default {
    name: 'CenteredSpinner',
    props: {
        text: { type: String, default: '' }
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    created() {
        
    }
}
</script>