<template>
<div>

    <PopupV1 noPadding designer :width="600" :maxHeight="500" :header="'Add card'" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <div class="frv2-dlist-container">
                <div class="frv2-dlist-column" style="width: 50%;">

                    <PropertyItem :header="'Common'"> 
                        <PropertyListItem v-for="(item, i) in listCommon" :key="'ic' + i"
                            v-show="!item.hidden"
                            :text="item.display"
                            :icon="item.icon"
                            @clicked="onAddInput(item.type)"
                        />
                    </PropertyItem>

                    <PropertyItem :header="'Special'"> 
                        <PropertyListItem v-for="(item, i) in listSpecial" :key="'is' + i"
                            v-show="!item.hidden"
                            :text="item.display"
                            :icon="item.icon"
                            @clicked="onAddInput(item.type)"
                        />
                    </PropertyItem>

                </div>
                <div class="frv2-dlist-column" style="width: 50%;">

                    <PropertyItem :header="'Info/Branding'"> 
                        <PropertyListItem v-for="(item, i) in listInfo" :key="'is' + i"
                            v-show="!item.hidden"
                            :text="item.display"
                            :icon="item.icon"
                            @clicked="onAddInput(item.type)"
                        />
                    </PropertyItem>

                    <PropertyItem :header="'PDF'"> 
                        <PropertyListItem v-for="(item, i) in listPdf" :key="'ic' + i"
                            v-show="!item.hidden"
                            :text="item.display"
                            :icon="item.icon"
                            @clicked="onAddInput(item.type)"
                        />
                    </PropertyItem>
                    
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import checklistModuleHelper from '../modules/checklist/checklistModuleHelper';

import textHelper from '../textHelper';

import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFButton from '../items/VFButton.vue';

import PropertyItem from './properties/PropertyItem.vue';
import PropertyListItem from './ui/PropertyListItem.vue';

export default {
    name: 'FormCardDialogV2',
    components: {
        PopupV1,
        VFButton,
        PropertyItem,
        PropertyListItem,
    },
    props: {
        params: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: {},
            listCommon: [
                { type: 'card-header', icon: 'bold', display: 'Header' },
                { type: 'card', icon: 'card', display: 'Card' },
                { type: 'list', icon: 'resource', display: 'Card list' },
                { type: 'radio', icon: 'input-check', display: 'Radiocard' },
                /* { type: 'buttons', icon: 'menu-h', display: 'Button group' }, */
                { type: 'checklist', icon: 'check-c', display: 'Checklist', hidden: !this.hasModule('chk') },
            ],
            listSpecial: [
                { type: 'checklist-a', icon: 'check-c', display: 'Checklist array', hidden: !this.hasModule('chk') },
                { type: 'checklist-h', icon: 'check-c', display: 'Checklist header', hidden: !this.hasModule('chk') },
                { type: 'checkout', icon: 'sign', display: 'Signing checkout', hidden: !(this.hasModule('sign') && (this.$root.config.forms.editor.advanced || this.$root.credentials.isAdmin)) },
                { type: 'flowv', icon: 'sign', display: 'Flow visualizer', hidden: !this.hasModule('sign') },
                { type: 'kycc', icon: 'merge', display: 'Comparison (KYC)', hidden: !this.hasModule('kyc') },
                { type: 'kyci', icon: 'merge', display: 'Customer info', hidden: !this.hasModule('kyc') },
                { type: 'kyca', icon: 'merge', display: 'Customer assessment', hidden: !this.hasModule('kyc') },
                { type: 'checklist-c', icon: 'sign', display: 'Checklist checkout', hidden: !this.hasModule('chk') },
                { type: 'checku', icon: 'sign', display: 'Universal checkout' },
                { type: 'anas', icon: 'sign', display: 'Analog Signature Field', hidden: !(this.$root.credentials.isAdmin) },
            ],
            listInfo: [
                { type: 'logo', icon: 'verified', display: 'Logo' },
                { type: 'methods', icon: 'list', display: 'Methods' },
                { type: 'pepinfo', icon: 'pep', display: 'PEP Info', hidden: !this.hasModule('kyc') },
                { type: 'popup', icon: 'info', display: 'Popup' },
                /* { type: 'cftext', icon: 'strikethrough', display: 'Formatted text' }, */
            ],
            listPdf: [
                /* { type: 'pdf-h', icon: 'pdf', display: 'PDF Header' },
                { type: 'pdf-i', icon: 'pdf', display: 'PDF Info Text' }, */
            ]
        }
    },
    methods: {

        hasModule(type) {
            for (const i in this.$parent.modules) {
                if (this.$parent.modules[i].type == type) return true;
            }
            return false;
        },
        
        onAddInput(type) {
            switch (type) {
                case 'card': return this.onAddCard();
                case 'list': return this.onAddList();
                case 'radio': return this.onAddRadioCard();
                case 'buttons': return this.onAddButtonGroup();
                case 'checklist': return this.onAddChecklist();
                case 'checklist-a': return this.onAddChecklistArray();
                case 'checklist-h': return this.onAddChecklistHeader();
                case 'checklist-c': return this.onAddChecklistCheckout();
                case 'logo': return this.onAddLogo();
                case 'methods': return this.onAddMethods();
                case 'checkout': return this.onAddCheckout();
                case 'card-header': return this.onAddHeader();
                case 'pepinfo': return this.onAddPepInfo();
                case 'popup': return this.onAddPopup();
                case 'cftext': return this.onAddFtext();
                case 'flowv': return this.onAddFlowV();
                case 'kycc': return this.onAddComparison();
                case 'pdf-h': return this.onAddPdfHeader();
                case 'pdf-i': return this.onAddPdfInfo();
                case 'checku': return this.onAddUniversalCheckout();
                case 'kyci': return this.onAddKycInfo();
                case 'kyca': return this.onAddKycAssessment();
                case 'anas': return this.onAddAnalogSignature();
            }
        },

        onAddAnalogSignature() {
            this.reference.counter += 1;
            const card = {
                key: 'a' + this.reference.counter,
                type: 'anas',
                pdf: true,
                roles: this.reference.roleModel,
            };
            this.$emit('add', [card]);
        },

        onAddKycInfo() {
            this.reference.counter += 1;
            const card = {
                key: 'k' + this.reference.counter,
                type: 'kyci',
                pdf: false,
                roles: this.reference.roleModel,
            };
            this.$emit('add', [card]);
        },

        onAddKycAssessment() {
            this.reference.counter += 1;
            const card = {
                key: 'k' + this.reference.counter,
                type: 'kyca',
                pdf: false,
                roles: this.reference.roleModel,
            };
            this.$emit('add', [card]);
        },

        onAddUniversalCheckout() {
            this.reference.counter += 1;
            const card = {
                key: 'u' + this.reference.counter,
                type: 'checku',
                mode: 'sign',
                pdf: false,
                roles: this.reference.roleModel,
            };
            this.$emit('add', [card]);
        },

        onAddPdfHeader() {
            this.reference.counter += 1;
            const card = {
                key: 'p' + this.reference.counter,
                type: 'pdfh',
                header: textHelper.getEmptyTextObject(this.reference),
                style: 'default',
                pdf: true,
                pdfOnly: true,
                roles: this.reference.roleModel
            };
            this.$emit('add', [card]);
        },

        onAddPdfInfo() {
            this.reference.counter += 1;
            const card = {
                key: 'p' + this.reference.counter,
                type: 'pdft',
                header: textHelper.getEmptyTextObject(this.reference),
                pdf: true,
                pdfOnly: true,
                raw: false,
                roles: this.reference.roleModel
            };
            this.$emit('add', [card]);
        },

        onAddFlowV() {
            this.$parent.counter += 1;
            const card = {
                key: 'v' + this.$parent.counter,
                type: 'flowv',
                hideName: true,
                pdf: false,
                roles: this.$parent.roleModel
            };
            this.$emit('add', [card]);
        },

        onAddComparison() {
            this.$parent.counter += 1;
            const card = {
                key: 'k' + this.$parent.counter,
                type: 'kycc',
                pdf: false,
                roles: this.$parent.roleModel
            };
            this.$emit('add', [card]);
        },

        onAddPepInfo() {
            this.$parent.counter += 1;
            const card = {
                key: 'p' + this.$parent.counter,
                type: 'pepinfo',
                header: {
                    en_EN: 'PEP Info',
                    nb_NO: 'PEP Info',
                    sv_SE: 'PEP Info',
                },
                pdf: false,
                roles: this.$parent.roleModel
            };
            this.$emit('add', [card]);
        },

        onAddPopup() {
            this.$parent.counter += 1;
            const card = {
                key: 'p' + this.$parent.counter,
                type: 'popup',
                icon: 'info',
                iconColor: "#444",
                titleColor: "#444",
                bgColor: "#D9EDF7",
                title: {
                    en_EN: 'New popup',
                    nb_NO: 'Ny popup',
                    sv_SE: 'Ny popup'
                },
                link: {
                    en_EN: 'Click here for more info',
                    nb_NO: 'Klikk her for mer info',
                    sv_SE: 'Click here for more info'
                },
                content: {
                    formatted: true,
                    en_EN: 'New popup content..',
                    nb_NO: 'Ny popup content..',
                    sv_SE: 'Ny popup content..'
                },
                pdf: false,
                inputs: [],
                noPadding: false,
                roles: this.$parent.roleModel
            };
            this.$emit('add', [card]);
        },

        onAddCard() {
            this.$parent.counter += 1;
            const card = {
                key: 'c' + this.$parent.counter,
                type: 'card',
                header: textHelper.getEmptyTextObject(this.reference),
                inputs: [],
                noPadding: false,
                pdf: true,
                roles: this.$parent.roleModel
            };
            this.$emit('add', [card]);
        },

        onAddRadioCard() {
            const count1 = this.$parent.counter += 1;
            const count2 = this.$parent.counter += 1;
            const optKey1 = 'x' + (this.$parent.counter += 1);
            const optKey2 = 'x' + (this.$parent.counter += 1);
            const optKey3 = 'x' + (this.$parent.counter += 1);
            const card = {
                key: 'c' + count1,
                type: 'card',
                header: {
                    en_EN: '',
                    nb_NO: ''
                },
                inputs: [
                    {
                        key: 'i' + count2,
                        type: 'check',
                        width: '12',
                        desc: {
                            en_EN: '',
                            nb_NO: ''
                        },
                        required: true,
                        inline: false,
                        radio: true,
                        fillCard: true,
                        options: {
                            [optKey1]: {
                                type: 'opt',
                                key: optKey1,
                                default: false,
                                locale: {
                                    en_EN: this.$root.locale.key != 'nb_NO' ? 'Checkbox option 1' : '',
                                    nb_NO: this.$root.locale.key == 'nb_NO' ? 'Huk av for valg 1' : ''
                                }
                            },
                            [optKey2]: {
                                type: 'opt',
                                key: optKey2,
                                default: false,
                                locale: {
                                    en_EN: this.$root.locale.key != 'nb_NO' ? 'Checkbox option 2' : '',
                                    nb_NO: this.$root.locale.key == 'nb_NO' ? 'Huk av for valg 2' : ''
                                }
                            },
                            [optKey3]: {
                                type: 'opt',
                                key: optKey3,
                                default: false,
                                locale: {
                                    en_EN: this.$root.locale.key != 'nb_NO' ? 'Checkbox option 3' : '',
                                    nb_NO: this.$root.locale.key == 'nb_NO' ? 'Huk av for valg 3' : ''
                                }
                            },
                        },
                        noPadding: true,
                        pdf: true,
                        pdfIndex: 0,
                        pdfLocked: false
                    }
                ],
                noPadding: true,
                pdf: true,
                roles: this.$parent.roleModel
            };
            this.$emit('add', [card]);
        },

        onAddList() {
            this.$parent.counter += 1;
            const card = {
                key: 'c' + this.$parent.counter,
                type: 'list',
                header: {
                    en_EN: this.$root.locale.key != 'nb_NO' ? 'New card' : '',
                    nb_NO: this.$root.locale.key == 'nb_NO' ? 'Nytt kort' : ''
                },
                addtext: {
                    en_EN: this.$root.locale.key != 'nb_NO' ? 'Add item' : '',
                    nb_NO: this.$root.locale.key == 'nb_NO' ? 'Legg til kort' : ''
                },
                inputs: [],
                noPadding: false,
                pdf: true,
                roles: this.$parent.roleModel
            };
            this.$emit('add', [card]);
        },

        onAddButtonGroup() {
            const count1 = this.$parent.counter += 1;
            const optKey1 = 'b' + (this.$parent.counter += 1);
            const optKey2 = 'b' + (this.$parent.counter += 1);
            const card = {
                key: 'b' + count1,
                type: 'buttons',
                buttons: [
                    {
                        key: optKey1,
                        color: 0,
                        outlined: false,
                        dotted: false,
                        width: 0,
                        text: {
                            en_EN: 'New button',
                            nb_NO: 'Ny knapp'
                        },
                        tooltip: {
                            en_EN: 'New button tooltip',
                            nb_NO: 'Ny hjelpetekst'
                        }
                    },
                    {
                        key: optKey2,
                        color: 0,
                        outlined: false,
                        dotted: false,
                        text: {
                            en_EN: 'New button',
                            nb_NO: 'Ny knapp'
                        },
                        tooltip: {
                            en_EN: 'New button tooltip',
                            nb_NO: 'Ny hjelpetekst'
                        }
                    }
                ],
                style: 0,
                size: 1,
                align: 2,
                pdf: false
            };
            this.$emit('add', [card]);
        },

        onAddChecklist() {
            const card = checklistModuleHelper.getEmptyCardConfig(this.$parent.counter += 1);
            card.inputs.push(checklistModuleHelper.getEmptyInputConfig(this.$parent.counter += 1, false));
            card.inputs.push(checklistModuleHelper.getEmptyInputConfig(this.$parent.counter += 1, false));
            this.$emit('add', [card]);
        },

        onAddChecklistArray() {
            const card = checklistModuleHelper.getEmptyArrayConfig(this.$parent.counter += 1);
            card.inputs.push(checklistModuleHelper.getEmptyInputConfig(this.$parent.counter += 1, true));
            card.inputs.push(checklistModuleHelper.getEmptyInputConfig(this.$parent.counter += 1, true));
            this.$emit('add', [card]);
        },

        onAddChecklistHeader() {
            const card = checklistModuleHelper.getEmptyHeaderConfig(this.$parent.counter += 1);
            this.$emit('add', [card]);
        },

        onAddLogo() {
            this.$parent.counter += 1;
            const card = {
                key: 'l' + this.$parent.counter,
                type: 'logo',
                url: '',
                pdf: false
            };
            this.$emit('add', [card]);
        },

        onAddMethods() {
            this.$parent.counter += 1;
            const card = {
                key: 'l' + this.$parent.counter,
                type: 'methods',
                mode: 'sign',
                pdf: false
            };
            this.$emit('add', [card]);
        },

        onAddCheckout() {
            this.$parent.counter += 1;
            const card = {
                key: 'c' + this.$parent.counter,
                type: 'checkout',
                pdf: false,
                directText: {
                    en_EN: 'Direct signing',
                    nb_NO: 'Direkte signering'
                },
                recipientText: {
                    en_EN: 'Select recipient',
                    nb_NO: 'Velg mottaker'
                }
            };
            this.$emit('add', [card]);
        },

        onAddChecklistCheckout() {
            this.$parent.counter += 1;
            const card = {
                key: 'c' + this.$parent.counter,
                type: 'chk-checkout',
                pdf: false
            };
            this.$emit('add', [card]);
        },

        onAddHeader() {
            this.$parent.counter += 1;
            const card = {
                key: 'h' + this.$parent.counter,
                type: 'card-header',
                value: {
                    en_EN: this.$root.locale.key != 'nb_NO' ? 'New header' : '',
                    nb_NO: this.$root.locale.key == 'nb_NO' ? 'Ny overskrift' : ''
                },
                pdf: true,
                inputs: [],
                noPadding: false,
                size: 26,
                margin: 0,
                roles: this.$parent.roleModel
            };
            this.$emit('add', [card]);
        },

        onAddFtext() {
            this.$parent.counter += 1;
            const card = {
                key: 'f' + this.$parent.counter,
                type: 'cftext',
                header: this.getTextLocale('New formatted text'),
                pdf: true,
                pdfOnly: false,
                raw: false,
                roles: this.$parent.roleModel
            };
            this.$emit('add', [card]);
        },

        getTextLocale(str) {
            const obj = {};
            for (const i in this.$parent.locales) {
                obj[this.$parent.locales[i].key] = str;
            }
            return obj;
        },

    }
}
</script>
<style scoped>

.frv2-dlist-container {
    float: left;
    width: 100%;
}

.frv2-dlist-column {
    float: left;
    width: 33.33%;
    min-height: 400px;
    box-sizing: border-box;
    border-right: solid 1px var(--fb-menu-c);
}

</style>