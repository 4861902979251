<template>
<div>
    <FormViewer v-if="!loading" :isOwner="false" :envelope="$parent.loadedEnvelope" :recipient="$parent.loadedRecipient" />
</div>
</template>
<script>
import FormViewer from '@/components/form/FormViewer';

export default {
    name: 'FormFillView',
    components: {
        FormViewer
    },
    data() {
        return {
            loading: true
        }
    },
    methods: {
        setTitle(title) {
            this.$parent.headerTitle = title;
            this.$parent.setTitle(title, true);
        }
    },
    async created() {
        //console.log('loadedEnvelope', this.$parent.loadedEnvelope);
        //console.log('loadedRecipient', this.$parent.loadedRecipient);

        // Check if recipient is completed
        let redirectToLanding = false;
        try {
            for (const i in this.$parent.loadedEnvelope.content.recipients) {
                const recipient = this.$parent.loadedEnvelope.content.recipients[i];
                if (recipient.id == this.$parent.loadedRecipient.id) {
                    if (recipient.completed) {
                        redirectToLanding = true;
                    }
                }
            }
        }catch (ex) {
            console.error('Unable to check recipients');
        }

        if (redirectToLanding && !this.$route.query.reload && this.$root.config.forms.newLanding) {
            // Recipient should not be on this link
            // Redirect to landing, to show options
            console.error('Trying to fill form, but recipient is already completed');
            const query = { reload: true };
            for (const key in this.$route.query) {
                query[key] = this.$route.query[key];
            }
            this.$parent.navigate({ path: `/lform/${this.$route.params.envelopeId}/${this.$route.params.recipientId}`, query: query });
        }

        this.loading = false;
        this.$parent.landing = false;
        this.$parent.setLoading(false);
    }
}
</script>