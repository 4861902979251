<template>
<div>

    <PopupV1 :header="'Watch Options'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div>
                {{ 'Current watchers:' }}
            </div>
            <div v-if="watchers.length == 0">
                {{ 'No one is watching this customer' }}
            </div>
            <div v-for="(user, i) in watchers" :key="'w' + i">
                {{ user }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-close')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton v-if="!isWatching"
                :text="'Start Watching'"
                :icon="'eye'"
                @clicked="onStartWatching"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
            <VFButton v-if="isWatching"
                warning
                :text="'Stop Watching'"
                :icon="'cancel'"
                @clicked="onStopWatching"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';

export default {
    name: 'WatcherDialog',
    components: {
        PopupV1,
        VFButton,
    },
    props: {
        params: { type: Object, default: null },
        client: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            isWatching: false,
            watchers: [],
        }
    },
    methods: {

        validateInputs() {
            this.dialog.valid = false;
            if (this.params.mode == 'confirm') {

                this.dialog.valid = true;

            }else if (this.params.mode == 'upload') {

                if (this.files.files && this.files.files.length > 0) {
                    this.dialog.valid = true;
                }

            }
        },

        async onStartWatching() {
            this.dialog.working = true;
            this.dialog.status = 'Laster opp';

            await v1api.addWatcher(this.client._id, this.$root.credentials.uid);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        async onStopWatching() {
            this.dialog.working = true;
            this.dialog.status = 'Bekrefter';

            await v1api.removeWatcher(this.client._id, this.$root.credentials.uid);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        },

    },
    async created() {
        
        if (this.client.watch) {

            for (const i in this.client.watch) {

                this.watchers.push({
                    name: this.getEmployeeName(this.client.watch[i]),
                });

                if (this.client.watch[i] == this.$root.credentials.uid) this.isWatching = true;

            }

        }

        this.loading = false;
    }
}
</script>