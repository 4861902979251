<template>
<div>
    
    <PopupV1 :header="__t('identity-manual')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('identity-manual-hint') }}
            </div>
            <DialogContent>
                <DialogContentHeader :text="__('common_ssn').toUpperCase() + ':'" />
                <VFRow style="margin-top: 5px; margin-left: -5px; margin-bottom: -20px;">
                    <VFGroup c12>
                        <VFInput v-model="ssn" :placeholder="__('common_optional')" />
                    </VFGroup>
                </VFRow>
                <DialogContentHeader :text="__t('report-comment').toUpperCase() + ':'" />
                <VFRow style="margin-top: 5px; margin-left: -5px; margin-bottom: -20px;">
                    <VFGroup c12>
                        <VFTextArea v-model="comment" :placeholder="__('common_optional')" />
                    </VFGroup>
                </VFRow>
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton v-if="$root.config.persons.selectSignature" :text="'From signature'" :icon="'sign'" @clicked="showSignatureDialog" :disabled="dialog.working" />
            <VFButton :text="__t('identity-manual')" :icon="'check'" @clicked="onCompleteManually" :working="dialog.working" />
        </template>
    </PopupV1>

    <SelectSignatureDialog v-if="signatureDialog.show" :params="params" @close="signatureDialog.show = false" @reload="onSignatureSelected" />

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';

import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFInput from '../components/form/inputs/VFInput.vue';
import VFTextArea from '../components/form/inputs/VFTextArea.vue';

import SelectSignatureDialog from './SelectSignatureDialog.vue';

export default {
    name: 'ManualIdcDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFRow,
        VFGroup,
        VFInput,
        VFTextArea,
        SelectSignatureDialog,
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            dialog: { working: false },
            signatureDialog: { show: false, working: false },
            ssn: '',
            comment: ''
        }
    },
    methods: {

        onClose() {
            this.$emit('close');
        },

        showSignatureDialog() {
            this.signatureDialog.show = true;
        },

        onSignatureSelected(response) {
            this.$emit('reload', response);
            this.onClose();
        },

        async onCompleteManually() {
            this.dialog.working = true;
            try {

                const manualData = {
                    ssn: this.ssn,
                    comment: this.comment
                };
                await v1api.completeIDCManually(this.params.person.personId, manualData);

                const response = await v1api.getClient(this.params.clientId);
                this.$emit('reload', response);

                this.dialog.working = false;
                this.onClose();

            }catch (ex) {
                this.dialog.error = ex.message;
                console.error(ex);
            }
        },

    },
    async created() {
        
    }
}
</script>
<style scoped>


</style>