<template>
<div class="vf-pepinfo card-shadow" @click="handleClick" :class="[
    { 'vf-pepinfo-selected' : selected }
]">

    <div class="vf-pepinfo-icon">
        <vap-icon :icon="'pep'" :color="'var(--text-title)'" />
    </div>

    <div class="vf-pepinfo-content">

        <div>
            {{ __t('pep-question') }}
        </div>

        <div class="vf-pepinfo-link">
            <label @click="showPopup">{{ __t('pep-click-here') }}</label>
        </div>

    </div>

</div>
</template>

<script>

export default {
    name: 'VFPepInfo',
    props: {
        cardData: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    methods: {
        
        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

        showPopup() {
            this.showHelpPopupX({ htmlKey: 'pep_explanation' });
        }

    }
}
</script>

<style scoped>

.vf-pepinfo {
    display: flex;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    padding: 5px 20px;
    padding-top: 14px;
    padding-bottom: 10px;
    border-radius: 3px;
    /* border: 1px solid #CFC4A5; */
    background-color: #F4E7C3;
    box-sizing: border-box;
}

.vf-pepinfo-icon {
    width: 48px;
    flex-shrink: 0;
    padding-top: 4px;
}

.vf-pepinfo-content {
    flex-grow: 100;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    color: var(--text-title);
}

.vf-pepinfo-link {
    color: var(--link-text);
}
.vf-pepinfo-link label {
    cursor: pointer;
}
.vf-pepinfo-link label:hover {
    color: var(--link-active);
}


/* Designer / Selection */

.vf-pepinfo-selected {
    outline: solid 2px #238ccc;
}


</style>
