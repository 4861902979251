<template>
<div class="flw100" style="padding: 0px 2px;">
    
    <vap-row style="margin-top: 40px;">
        <vap-group c12>
            <vap-label :text="__('common_name')" desc required />
            <vap-input v-model="forreignModel.name" @input="validateForreign" :placeholder="__('common_required')" noValidation />
        </vap-group>
        <vap-group c6>
            <vap-label :text="__('company_orgnr')" desc />
            <vap-input v-model="forreignModel.identity" @input="validateForreign" :placeholder="__('common_optional')" noValidation />
        </vap-group>
        <vap-group c6>
            <vap-label :text="__('common_country')" desc />
            <vap-dropdown v-model="forreignModel.country" noValidation
                :options="$root.countriesJson"
                :commonOptions="$root.countriesCommon"
                :placeholder="__('common_optional')"
                :listKey="$root.locale.key"
                :displayKey="$root.locale.key"
                :outputKey="'key'"
                @input="validateForreign" />
        </vap-group>
    </vap-row>

</div>
</template>
<script>
export default {
    name: 'SearchForreign',
    components: {
        
    },
    data() {
        return {
            valid: false,
            searchValid: false,
            country: 'xx',
            source: 'manual',
            forreignModel: {
                name: '',
                identity: '',
                country: '',
            },
        }
    },
    methods: {

        validateForreign() {
            if (this.forreignModel.name) {
                this.valid = true;
            }else {
                this.valid = false;
            }
            this.searchValid = this.valid;
            this.$emit('validate', this.valid);
        },

        resetSearch() {
            this.valid = false;
            this.searchValid = false;
            this.forreignModel.name = '';
            this.forreignModel.identity = '';
            this.forreignModel.country = '';
            this.$emit('validate', this.valid);
        },

        getInitialData() {
            return {
                type: 'org',
                name: this.forreignModel.name,
                identity: this.forreignModel.identity,
                country: this.forreignModel.country,
                source: this.source
            };
        },

    },
    created() {
        this.validateForreign();
    }
}
</script>