import log from '../../../log';
import formHelper from '../formHelper';
import textHelper from '../textHelper';
const inputHelper = {

    onAddValsum(reference) {
        this.$parent.counter += 1;

        const input = {
            key: 'v' + this.$parent.counter,
            type: 'valsum',
            width: '12',
            required: false,
            pdf: false
        };
        this.$emit('add', [input]);
    },

    onAddTooltip(reference) {
        reference.counter += 1;

        const input = {
            key: 't' + reference.counter,
            type: 'ttip',
            width: '12',
            desc: textHelper.getEmptyTextObject(reference),
            text: textHelper.getEmptyTextObject(reference),
            required: false,
            pdf: false
        };
        reference.onAddInput([input]);
    },

    onAddGreetingPreview(reference) {
        reference.counter += 1;

        const input = {
            key: 'g' + reference.counter,
            type: 'greet',
            width: '12',
            role: 'editor',
            required: false,
            pdf: false
        };
        reference.onAddInput([input]);
    },

    onAddApproveMultiple(reference) {
        reference.counter += 1;

        const input = {
            key: 'a' + reference.counter,
            type: 'appm',
            width: '12',
            required: false,
            pdf: false
        };
        reference.onAddInput([input]);
    },

    onAddCheckbox2(reference) {
        const input = {
            key: 'c' + (reference.counter += 1),
            type: 'check2',
            width: '12',
            desc: textHelper.getEmptyTextObject(reference),
            visible: true,
            enabled: true,
            required: true,
            inline: false,
            radio: false,
            yesno: false,
            min: 1,
            items: [
                {
                    key: 'o' + (reference.counter += 1),
                    modelKey: 'o' + reference.counter,
                    type: 'coo',
                    default: false,
                    text: textHelper.getEmptyTextObject(reference),
                },
                {
                    key: 'o' + (reference.counter += 1),
                    modelKey: 'o' + reference.counter,
                    type: 'coo',
                    default: false,
                    text: textHelper.getEmptyTextObject(reference),
                }
            ],
            pdf: true,
        };
        reference.onAddInput([input]);
    },

    onAddYesNo(reference) {
        const input = {
            key: 'c' + (reference.counter += 1),
            type: 'check2',
            width: '12',
            desc: textHelper.getEmptyTextObject(reference),
            visible: true,
            enabled: true,
            required: true,
            inline: true,
            radio: true,
            yesno: true,
            min: 1,
            items: [
                {
                    key: 'o' + (reference.counter += 1),
                    modelKey: 'yes',
                    type: 'coo',
                    default: false,
                    text: textHelper.getTranslation(reference, 'common-yes'),
                },
                {
                    key: 'o' + (reference.counter += 1),
                    modelKey: 'no',
                    type: 'coo',
                    default: false,
                    text: textHelper.getTranslation(reference, 'common-no'),
                }
            ],
            pdf: true,
        };
        reference.onAddInput([input]);
    },

};
export default inputHelper;