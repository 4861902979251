<template>
<div>

    <!-- Designer View -->
    <div class="frmod-checkout-designer" v-if="designer" @click.stop="onDesignerSelect" :class="[
        {'frmod-checkout-designer-selected': selected}
    ]">
        {{ '[Checkout]' }}
    </div>

    <!-- Owner View -->
    <div v-if="reference.roleKey == 'owner' && !designer">

        <div>

            <vap-card :header="__('signrights_owner_header')">

                <div v-if="reference.signRightsModel.available && !overrideSignatureCheck"
                    style="margin-top: 10px; margin-bottom: 10px; margin-left: 6px;"
                >
                    {{ __('signrights_owner_options_description') }}
                </div>

                <div v-if="!reference.signRightsModel.available"
                    style="margin-left: 6px;"
                >
                    {{ __('signrights_owner_no_options') }}
                </div>

                <div v-if="!overrideSignatureCheck" style="padding-left: 6px;">

                    <div v-for="(option, key, i) in options" :key="'sc' + i">
                        <div style="color: var(--text-weak);">
                            {{ __('signrights_owner_option') + " " + (i + 1) }}
                        </div>
                        <div v-for="(person, j) in option.persons" :key="'scp' + j" style="display: flex;">
                            <div>
                                {{ person.firstName + ' ' + person.lastName }}
                            </div>
                            <div style="margin-left: 6px; color: var(--text-weak);">
                                {{ '(' + person.dateOfBirth + ')' }}
                            </div>
                        </div>

                    </div>

                </div>

                <vap-row>
                    <!--Commented out because the functionality is not used later in the flow-->
                    <!--<vap-group c12 v-if="reference.signRightsModel.available">
                        <vap-checkbox v-model="overrideSignatureCheck"
                            :text="__('signrights_owner_override_checkbox')"
                            noValidation noHighlight
                            @input="validateOwner"
                        />
                    </vap-group>-->
                    <vap-group c12
                        style="margin-top: 25px; margin-bottom: 10px;"
                    >
                        <vap-label desc :text=" __('signrights_owner_override_message') " />
                        <VFTextArea v-model="recipientMessage"
                            :placeholder="__('signrights_owner_override_message_placeholder')"
                            @input="validateOwner"
                        />
                    </vap-group>
                </vap-row>

                <div v-if="reference.signRightsModel.available && overrideSignatureCheck" style="margin-top: 10px; margin-bottom: 20px; margin-left: 6px;">
                    {{ __('signrights_owner_override_text') }}
                </div>

            </vap-card>

        </div>

    </div>

    <!-- old -->
    <div v-if="false">
        <!-- Select mode -->
        <div @click="onDesignerSelect" v-if="!realSignature && !showDirect && !showOrder">
            <vap-card v-if="!reference.signRightsModel.available" noPadding :header="__('signrights_filler_no_options_header')" style="margin-bottom: 15px;">
                <div style="margin-top: 10px; margin-left: 20px;">
                    {{ signRightsDescriptionInfoText }}
                </div>
                <div v-if="reference.signRightsModel.description.length > 0" style="margin-top: 10px; margin-left: 15px; font-weight: bold;">
                    <div v-for="(description, i) in reference.signRightsModel.description" :key="'d' + i" style="padding: 2px 20px;">
                        {{ description }}
                    </div>
                </div>
                <div style="margin-top: 10px; margin-left: 20px; margin-bottom: 20px;">
                    {{ __('signrights_according_to_description') }}
                </div>
            </vap-card>
            <vap-card v-if="reference.signRightsModel.available" noPadding :header="__('signrights_signrights_text')" style="margin-bottom: 15px;">
                <div style="margin-top: 10px; margin-left: 20px;">
                    {{ __('signrights_according_to_sender') }}
                </div>
                <div style="margin-top: 10px; margin-left: 20px; margin-bottom: 20px;">
                    {{ __('signrights_option_below') }}
                </div>
            </vap-card>
            <div v-if="reference.signModel.message" :header="__('signrights_message_from_sender')" class="frmod-checkout-message card-shadow">
                <div style="font-weight: 500;">
                    {{ __('signrights_message_from_sender') }}
                </div>
                <div style="margin-top: 0px;">
                    {{ reference.signModel.message }}
                </div>
            </div>
            <vap-card compactList noPadding>
                <vap-item padding20 @clicked="goToDirect">
                    <div class="checkout-option">
                        <label>{{ __('signrights_direct_sign') }}</label>
                    </div>
                </vap-item>
            </vap-card>
            <vap-card compactList noPadding>
                <vap-item padding20 @clicked="goToOrder">
                    <div class="checkout-option">
                        <label>{{ __('signrights_nondirect_sign') }}</label>
                    </div>
                </vap-item>
            </vap-card>
        </div>

        <div v-if="!mode && realSignature">
            <vap-card noPadding :header="__('signrights_filler_header')">
                <SignOptionRadio
                    radio
                    fillCard
                    :signMethods="reference.resources.signMethods.items"
                    :options="options"
                    :locales="locales"
                    v-model="optionModel"
                    @input="onOptionSelected"
                    @validated="validateCheckout"
                />
            </vap-card>
        </div>

        <!-- Direct sign -->
        <div v-if="showDirect">
            <vap-button rounded :text="__('signrights_other_option')" @clicked="goBack" style="margin-bottom: 10px;" />
            <vap-card v-if="!reference.signRightsModel.available" noPadding :header="__('signrights_filler_no_options_header')" style="margin-bottom: 15px;">
                <div style="margin-top: 10px; margin-left: 20px;">
                    {{ signRightsDescriptionInfoText }}
                </div>
                <div v-if="reference.signRightsModel.description.length > 0" style="margin-top: 10px; margin-left: 15px; margin-bottom: 10px; font-weight: bold;">
                    <div v-for="(description, i) in reference.signRightsModel.description" :key="'d' + i" style="padding: 2px 20px;">
                        {{ description }}
                    </div>
                </div>
            </vap-card>
            <vap-card v-if="reference.signRightsModel.available" noPadding :header="__('signrights_signrights_text')" style="margin-bottom: 15px;">
                <div style="margin-top: 10px; margin-left: 20px;">
                    {{ __('signrights_according_to_sender') }}
                </div>
                <div style="margin-top: 10px; margin-left: 20px; margin-bottom: 20px;">
                    {{ 'Hvis du kan signere alene kan du gå direkte til signering nå.' }}
                </div>
            </vap-card>
            <div v-if="reference.signModel.message" :header="__('signrights_message_from_sender')" class="frmod-checkout-message card-shadow">
                <div style="font-weight: 500;">
                    {{ __('signrights_message_from_sender') }}
                </div>
                <div style="margin-top: 0px;">
                    {{ reference.signModel.message }}
                </div>
            </div>
            <vap-card compactList :header="__('signrights_direct_sign')">
                <vap-row>
                    <vap-group c3>
                        <vap-label desc :text="__('signrights_checkout_method')" />
                        <vap-dropdown v-model="directMethodModel" noValidation
                            :options="reference.resources.signMethods.items"
                            :displayKey="$root.locale.key"
                            :listKey="$root.locale.key"
                            :outputKey="'value'"
                            @input="updateVmodel"
                            />
                    </vap-group>
    <!--                 <vap-group>
                        <vap-label desc :text="''" />
                        <vap-button :text=" 'Start signering' " @clicked="submit" />
                    </vap-group> -->
                </vap-row>
            </vap-card>

        </div>

        <!-- Recipients -->
        <div v-if="showOrder">
            <vap-button rounded :text="__('signrights_other_option')" @clicked="goBack" style="margin-bottom: 10px;" />
            <vap-label header :text="__('signrights_signatories_text')" style="margin-bottom: 10px;" />

            <vap-card v-if="!reference.signRightsModel.available" noPadding :header="__('signrights_filler_no_options_header')" style="margin-bottom: 15px;">
                <div style="margin-top: 10px; margin-left: 20px;">
                    {{ signRightsDescriptionInfoText }}
                </div>
                <div v-if="reference.signRightsModel.description.length > 0" style="margin-top: 10px; margin-left: 15px; margin-bottom: 10px; font-weight: bold;">
                    <div v-for="(description, i) in reference.signRightsModel.description" :key="'d' + i" style="padding: 2px 20px;">
                        {{ description }}
                    </div>
                </div>
            </vap-card>
            <div style="display: flex; align-items: center; float: left; width: calc(100% - 30px); padding: 10px 15px; background: #FFF3CD; margin-bottom: 15px; border: 1px solid #F2C883; border-radius: 4px;">
                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24" style=" fill:#333333;">    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"></path></svg>    
                <span style="margin-left: 10px;">{{ __('signrights_add_self_notification') }}</span>
            </div>
            <vap-card v-if="reference.signRightsModel.available" noPadding :header="__('signrights_signrights_text')" style="margin-bottom: 15px;">
                <div style="margin-top: 10px; margin-left: 20px;">
                    {{ __('signrights_according_to_sender') }}
                </div>
                <div style="margin-top: 10px; margin-left: 20px; margin-bottom: 20px;">
                    {{ __('signrights_add_signatories') }}
                </div>
            </vap-card>
            <div v-if="reference.signModel.message" :header="__('signrights_message_from_sender')" class="frmod-checkout-message card-shadow">
                <div style="font-weight: 500;">
                    {{ __('signrights_message_from_sender') }}
                </div>
                <div style="margin-top: 0px;">
                    {{ reference.signModel.message }}
                </div>
            </div>
            <div class="checkout-signer-base" v-for="(signer, i) in signers" :key="'s' + i">
                <vap-card list noPadding>
                    <vap-item padding20 @clicked="editSigner(i)">
                        <div class="checkout-signer">
                            <div class="checkout-signer-left">
                                <div class="checkout-signer-name">
                                    <label>{{ signer.firstName + ' ' + signer.lastName }}</label>
                                </div>
                                <div class="checkout-signer-email">
                                    <label>{{ signer.email }}</label>
                                </div>
                            </div>
                            <div class="checkout-signer-right">

                                <div class="checkout-signer-sign">

                                    <vap-icon :icon="'sign'" />

                                </div>

                                

                                <div class="checkout-signer-action" v-if="signer.method != 'select'">
                                    <label>{{ 'Signere med' }}</label>
                                </div>
                                <div class="checkout-signer-method" v-if="signer.method != 'select'">
                                    <label>{{ getMethodName(signer.method) }}</label>
                                </div>
                            </div>
                        </div>
                    </vap-item>
                </vap-card>
            </div>
            <vap-button-row alignCenter style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('signrights_add_signatory')" uppercase @clicked="addSigner" />
                <!-- <vap-button :text="'Send til signering'" uppercase :disabled="!reference.signModel.checkoutValid" @clicked="submit" /> -->
            </vap-button-row>
        </div>



        <div v-if="!realSignature && !designer" class="flw100" style="margin-top: 10px;">
            <vap-card>
                <div>
                    {{ __('signrights_upload_poa') }}
                </div>
                <VFFileupload 
                    v-model="files"
                    :required="false"
                    :header="__('signrights_poa')"
                    :hintText="__('signrights_upload_poa_here')"
                    :browseText="__('signrights_upload_poa_select_file')"
                    @input="validateCheckout()"
                />
            </vap-card>

        </div>

    </div>

    <!-- new -->
    <div v-if="reference.roleKey != 'owner' && !designer">

        <template v-if="realSignature">
            <div v-if="reference.signModel.message && !optionModel.manual" :header="__('signrights_message_from_sender')" class="frmod-checkout-message card-shadow">
                <div style="font-weight: 500;">
                    {{ __('signrights_message_from_sender') }}
                </div>
                <div style="margin-top: 0px;">
                    {{ reference.signModel.message }}
                </div>
            </div>
            <div style="background: #fff; border-top-right-radius: 4px; border-top-left-radius: 4px; margin-bottom: 25px; float: left; width: 100%;">
                <div style="padding: 15px;">
                    <h2 style="margin: 5px 0; font-size: 17px; font-weight: 500; color: var(--text-title);">{{ __('signrights_filler_header') }}</h2>
                    <div style="margin-top: 10px; font-size: 14px; color: #666">{{ __('signrights_filler_subheader') }}</div>
                </div>
                <SignOptionRadio
                    radio
                    fillCard
                    :signMethods="reference.resources.signMethods.items"
                    :options="options"
                    :locales="locales"
                    :directAvailable="directAvailable"
                    v-model="optionModel"
                    @input="onOptionSelected"
                    @validated="validateCheckout"
                />
            </div>
        </template>

        <vap-card v-if="optionModel.manual || !realSignature" style="margin-bottom: 25px;" noPadding :header="__('signrights_add_signatory_header')">
            <template v-if="!signersIncludeSelf" v-slot:actions>
                    <div style="display: flex; align-items: center; margin-top: -7px; margin-right: 5px;" class="frv2-card-link">
                        <span @click="addSelf" class="checkout-add-self">{{ __('signrights_add_self') }}</span>
                    </div>
                </template>
            <div style="padding: 5px; margin: 15px;">

                <div v-if="signers && signers.length && !signersIncludeSelf" style="display: flex; align-items: center; width: calc(100% - 30px); padding: 10px 15px; background: #D9EDF7; margin-bottom: 15px; border-left: 4px solid #36708F; border-radius: 3px; color: #36708F">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24" style=" fill:#36708F;">    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"></path></svg>    
                    <span style="margin-left: 10px;">{{ __('signrights_add_self_notification') }}</span>
                </div>

                <div v-if="reference.signModel.message" :header="__('signrights_message_from_sender')" class="frmod-checkout-message card-shadow">
                    <div style="font-weight: 500;">
                        {{ __('signrights_message_from_sender') }}
                    </div>
                    <div style="margin-top: 0px;">
                        {{ reference.signModel.message }}
                    </div>
                </div>

                <div style="display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 15px;" v-for="(signer, i) in signers" :key="'s' + i">
                    <div style="background: #eee; padding: 15px; border-radius: 4px; flex-grow: 1" class="checkout-signer">
                        <div class="checkout-signer-left">
                            <div class="checkout-signer-name">
                                <label>{{ signer.firstName + ' ' + signer.lastName }}</label>
                            </div>
                            <div class="checkout-signer-email">
                                <label>{{ signer.email }}</label>
                            </div>
                        </div>
                        <div class="checkout-signer-right">

                            <div class="checkout-signer-sign">

                                <vap-icon :icon="'sign'" />

                            </div>

                            

                            <div class="checkout-signer-action" v-if="signer.method != 'select'">
                                <label>{{ 'Signere med' }}</label>
                            </div>
                            <div class="checkout-signer-method" v-if="signer.method != 'select'">
                                <label>{{ getMethodName(signer.method) }}</label>
                            </div>
                        </div>
                    </div>
                    <div style="margin-left: 10px">
                        <vap-icon icon="edit" color="#555" style="float: right; cursor: pointer" action @clicked="editSigner(i)" />
                    </div>
                    <div style="margin-left: 10px">
                        <vap-icon icon="delete" color="#555" style="float: right; cursor: pointer" action @clicked="removeSigner(i)" />
                    </div>

                    <div style="flex: 1 0 100%; margin-top: 16px;" v-if="directAvailable">
                    <div style="display: flex; align-items: center;">
                        <input style="width: 15px; height: 15px; margin-right: 7px;" type="checkbox" id="checkbox" @input="setSignerDirect(i, $event)" value="signer.direct" />
                        <label for="checkbox">{{ __('signrights_direct_option') }}</label>
                    </div>
                </div>
                </div>

                <!-- <div v-if="!signers || !signers.length" style="background: #fae8c0; padding: 15px; border-radius: 4px; margin-bottom: 15px;">
                    You have to add at least 1 person
                </div> -->

                <div v-if="!signers || !signers.length" style="display: flex; align-items: center; width: calc(100% - 30px); padding: 10px 15px; background: #ffe8bd; margin-bottom: 15px; border-left: 4px solid #fabc4b; border-radius: 3px; color: #a3752a">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 24 24" style=" fill:#a3752a;">    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z"></path></svg>    
                    <span style="margin-left: 10px;">{{ __('signrights_min_1') }}</span>
                </div>

                <vap-button-row style="margin-top: 5px; margin-bottom: 20px;">
                    <vap-button :text="__('signrights_add_signatory')" uppercase @clicked="addSigner" />
                    <!-- <vap-button :text="'Send til signering'" uppercase :disabled="!reference.signModel.checkoutValid" @clicked="submit" /> -->
                </vap-button-row>
            </div>
        </vap-card>

        <vap-card v-if="optionModel.manual || !realSignature" :header="__('signrights_poa_header')">
            <div>
                <VFFileupload 
                    v-model="files"
                    :header="__('signrights_poa')"
                    :hintText="__('signrights_upload_poa_here')"
                    :browseText="__('signrights_upload_poa_select_file')"
                    @input="validateCheckout()"
                />
            </div>
        </vap-card>

    </div>



    <!-- Add recipient popup -->
    <ve-popup v-if="showPopup" :header="__('signrights_add_signatory')" :width="600" @close="abortPopup" hideCloseButton>
        <div style="padding: 20px;">
            
            <div class="checkout-add-top">
                <div class="checkout-add-first">
                    <label>{{ __('signrights_signatory_popup_text') }}</label>
                </div>
            </div>

            <vap-row>
                <vap-group c6>
                    <vap-label :text="__('common_first_name')" desc required />
                    <vap-input v-model="activeSigner.firstName" :placeholder="__('signrights_required')" :rules="'required'" noHighlight></vap-input>
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_last_name')" desc required />
                    <vap-input v-model="activeSigner.lastName" :placeholder="__('signrights_required')" :rules="'required'" noHighlight></vap-input>
                </vap-group>
                <vap-group :c12="!useRecipientPortal" :c8="useRecipientPortal">
                    <vap-label :text="__('common_email')" desc required />
                    <vap-input v-model="activeSigner.email" :placeholder="__('signrights_required')" :rules="'required|email'" noHighlight></vap-input>
                </vap-group>
                <vap-group :c6="!useRecipientPortal" :c4="useRecipientPortal">
                    <vap-label :text="__('common_language')" desc required />
                    <LanguageDropdown v-model="activeSigner.language" :placeholder="__('signrights_required')" noHighlight />
                </vap-group>
                <vap-group c6 v-if="!useRecipientPortal">
                    <vap-label desc :text="__('signrights_checkout_method')" />
                    <vap-dropdown v-model="activeSigner.method"
                        :options="reference.resources.signMethods.items"
                        :displayKey="$root.locale.key"
                        :listKey="$root.locale.key"
                        :outputKey="'value'" />
                </vap-group>
            </vap-row>

            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="abortPopup" />
                <vap-button :text="__(activeIndex == -1 ? 'common_add' : 'common_save')" uppercase @clicked="saveSigner" :disabled="!hasInfo(activeSigner)" />
            </vap-button-row>
        </div>
    </ve-popup>

</div>
</template>
<script>
import signModuleHelper from './signModuleHelper';
import VFDropdown from '../../inputs/VFDropdown.vue';
import SignOptionRadio from './SignOptionRadio.vue';

import validations from '../../../../validations';
import LanguageDropdown from '../../../special/LanguageDropdown.vue';

import VFFileupload from '../../inputs/VFFileupload.vue';
import VFTextArea from '../../inputs/VFTextArea.vue';

export default {
    name: "SignCheckout",
    components: {
        VFDropdown,
        SignOptionRadio,
        LanguageDropdown,
        VFFileupload,
        VFTextArea
    },
    props: {
        cardData: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: () => { return {} }},
        receiver: {
            type: Object,
            default: () => { return {} }
        },
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        locales: { type: Array, default: () => { return [] }},
    },
    computed: {
        signersIncludeSelf: function() {
            return !!this.signers.find(x => x.email == this.reference.recipient.email);
        },
        directAvailable: function() {
            if(this.optionModel.manual || !this.realSignature) {
                return this.signers.length == 1 && this.signers[0].email == this.reference.recipient.email;
            }

            let key;
            for(const i in this.optionModel){
                if(this.optionModel[i]){
                    key = i;
                }
            }

            if(!key) {
                return false;
            }

            return this.options[key].persons.length == 1 && this.options[key].persons[0].email == this.reference.recipient.email;
        }
    },
    data() {
        return {
            mode: '',
            optionModel: {},
            options: {},
            selectedOption: {},

            realSignature: false,

            showDirect: false,
            showOrder: false,
            showPopup: false,
            showList: false,
            directMethodModel: 'bankid-no',
            signingMethodOptions: [],
            activeIndex: -1,
            activeSigner: {
                firstName: '',
                lastName: '',
                email: '',
                language: 'nb_NO',
                action: 'sign',
                method: 'bankid-no'
            },
            signers: [],
            useRecipientPortal: false,
            addIsValid: false,
            signRightsDescriptionInfoText: '',
            files: {},

            overrideSignatureCheck: false,
            recipientMessage: '',

        }
    },
    methods: {

        setSignerDirect(index, event) {
            let signer = this.signers[index];
            signer.direct = event.target.checked;
            this.$set(this.signers, index, signer);
            this.setSignModel();
        },

        recommendedLocale(locales) {
            // set to same locale as current user if enabled
            const currentLocaleValid = locales.find(x => x.key == this.$root.locale.key)
            if(currentLocaleValid) {
                return currentLocaleValid
            }

            // set to first enabled locale found
            const validLocales = locales.filter(x => x.enabled)

            if(validLocales.length) {
                return validLocales[0]
            }

            console.error("recommendedLocale: no valid locales found")

            return {}
        },

        //#region Navigation --------------------------------------------------------------------------------------------------

        selectMode(mode) {
            this.mode = mode;
        },

        goToDirect() {
            this.showDirect = true;
            this.updateVmodel();
        },
        goToOrder() {
            this.showOrder = true;
            
            //this.addSigner();
        },

        addSigner() {
            this.activeIndex = -1;
            this.activeSigner = {
                firstName: '',
                lastName: '',
                email: '',
                language: 'nb_NO',
                action: 'sign',
                method: 'bankid-no'
            };
            this.showPopup = true;
        },

        addSelf() {
            const editor = this.reference.recipient;
            this.signers.push({
                firstName: editor.givenName,
                lastName: editor.familyName,
                email: editor.email,
                language: editor.language || "nb_NO",
                action: 'sign',
                method: 'bankid-no'
            });
            this.updateVmodel();
        },

        showPersonList() {
            this.showList = true;
        },
        hasInfo(signer) {
            let result = validations.validateEmail(signer.email);
            return signer.firstName && signer.lastName && result.valid && signer.language;
        },
        editSigner(index) {
            this.activeIndex = index;
            this.activeSigner = this.signers[index];
            this.showPopup = true;
        },
        removeSigner(index) {
            this.signers.splice(index, 1);
        },
        saveSigner() {
            if (this.useRecipientPortal) this.activeSigner.method = 'select';
            if (this.activeIndex == -1) {
                this.signers.push(JSON.parse(JSON.stringify(this.activeSigner)));
            }else {
                this.signers[this.activeIndex] = JSON.parse(JSON.stringify(this.activeSigner));
            }
            this.showPopup = false;
            this.activeIndex = -1;
            this.updateVmodel();
        },
        abortPopup() {
            this.showPopup = false;
            this.activeIndex = -1;
            if (this.signers.length == 0) this.goBack();
        },
        goBack() {
            this.showDirect = false;
            this.showOrder = false;
            this.updateVmodel();
        },
        updateVmodel() {
            const value = {
                ready: this.showDirect || (this.showOrder && this.signers.length > 0),
                signDirect: this.showDirect,
                recipients: this.showDirect ? [] : this.signers
            };
            if (this.showDirect) value.recipients.push({
                firstName: this.receiver.fname,
                lastName: this.receiver.lname,
                email: this.receiver.email,
                language: this.$root.locale.key,
                method: this.directMethodModel
            });

            this.validateCheckout();
        },

        submit() {
            this.$emit('submit', true);
        },
        getMethodName(method) {
            for (const i in this.signingMethodOptions) {
                if (this.signingMethodOptions[i].value == method) return this.signingMethodOptions[i].display;
            }
            return method;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------
        
        validateCheckout(){
            /* if(this.realSignature){
                let key;
                for(const i in this.optionModel){
                    if(this.optionModel[i]){
                        key = i;
                    }
                }
                if(!key){
                    this.reference.signModel.checkoutValid = false;
                    return;
                };

                let allValid = true;
                for(const j in this.options[key].persons){
                    if(!this.options[key].persons[j].emailValidation.valid || !this.options[key].persons[j].methodValidation.valid){
                        allValid = false;
                    }
                }
                this.reference.signModel.checkoutValid = allValid;
            }else{
                if(this.showDirect){
                    this.reference.signModel.checkoutValid = this.directMethodModel ? true : false;
                }else if(this.showOrder){
                    this.reference.signModel.checkoutValid = this.signers.length > 0 ? true : false;
                }else{
                    this.reference.signModel.checkoutValid = false;
                }
            } */

            if(this.optionModel.manual || !this.realSignature) {
                this.reference.signModel.checkoutValid = this.signers.length > 0 ? true : false;
            } else {
                let key;
                for(const i in this.optionModel){
                    if(this.optionModel[i]){
                        key = i;
                    }
                }
                if(!key || key == "manual"){
                    this.reference.signModel.checkoutValid = false;
                    return;
                };

                let allValid = true;
                for(const j in this.options[key].persons){
                    if(!this.options[key].persons[j].emailValidation.valid || !this.options[key].persons[j].methodValidation.valid){
                        allValid = false;
                    }
                }
                this.reference.signModel.checkoutValid = allValid;
            }

            this.setSignModel();
            this.reference.updateValidation();
        },

        setSignModel(){

            if (!this.optionModel.manual && this.realSignature) {
                let key = '';
                for(const i in this.optionModel){
                    if(this.optionModel[i]){
                        key = i;
                    }
                }
                
                this.reference.signModel.direct = false;
                this.reference.signModel.signatureCheck = true;
                this.reference.signModel.selectedOption = [];
                for (const i in this.options[key].persons) {
                    this.reference.signModel.selectedOption.push({
                        name: this.options[key].persons[i].firstName + ' ' + this.options[key].persons[i].lastName,
                        firstName: this.options[key].persons[i].firstName,
                        lastName: this.options[key].persons[i].lastName,
                        dateOfBirth: this.options[key].persons[i].dateOfBirth,
                        method: this.options[key].persons[i].method,
                        email: this.options[key].persons[i].email
                    });
                }

                const signers = [];
                if(this.reference.signModel.checkoutValid){
                    for(const i in this.options[key].persons){
                        signers.push({
                            firstName: this.options[key].persons[i].firstName,
                            lastName: this.options[key].persons[i].lastName,
                            email: this.options[key].persons[i].email,
                            lang: this.options[key].persons[i].language,
                            method: this.options[key].persons[i].method,
                            dateOfBirth: this.options[key].persons[i].dateOfBirth,
                            role: { action: 'sign', label: 'Sign', name: 'signer' },
                            direct: this.options[key].persons[i].direct
                        })
                    }
                }

                // direct sign
                if(this.directAvailable && signers[0].direct) {
                    this.reference.signModel.direct = true;
                    this.reference.signModel.method = signers[0].method;
                }

                this.reference.signModel.signers = signers;

            }else {

                this.reference.signModel.signatureCheck = false;
                this.reference.signModel.selectedOption = [];
                this.reference.signModel.files = this.files.files;
                this.reference.signModel.direct = false;

                const signers = [];
                for(const i in this.signers){
                    signers.push({
                        firstName: this.signers[i].firstName,
                        lastName: this.signers[i].lastName,
                        email: this.signers[i].email,
                        lang: this.signers[i].language,
                        method: this.signers[i].method,
                        role: { action: 'sign', label: 'Sign', name: 'signer' },
                        direct: this.signers[i].direct
                    })
                }

                // direct sign
                if(this.directAvailable && signers[0].direct) {
                    this.reference.signModel.direct = true;
                    this.reference.signModel.method = signers[0].method;
                }

                this.reference.signModel.signers = signers;
            }
            
            console.log('SetSignModel: ' , this.reference.signModel);
        },

        validateOwner() {
            this.reference.signModel.message = this.recipientMessage;
            if (this.overrideSignatureCheck) {
                this.reference.signModel.signatureCheck = false;
            }else {
                this.reference.signModel.signatureCheck = true;
            }
            console.log('validateOwner: ' , this.reference.signModel);
        },

        
        //#region Designer / Logic --------------------------------------------------------------------------------------------

        onDesignerSelect() {
            if (!this.designer) return;
            this.$emit('selected');
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Real signature ----------------------------------------------------------------------------------------------

        onOptionSelected() {
            for(const i in this.optionModel){
                if(this.optionModel[i]){
                    this.selectedOption = this.options[i];
                }
            }
            this.validateCheckout();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        compareNames(name1, name2, threshold) {
            const names1 = name1.split(" ");
            const names2 = name2.split(" ");

            const numToMatch = names1.length > names2.length ? names1.length : names2.length;
            let matches = 0;

            for(let x of names2) {
                for(let y of names1) {
                    if(x == y) {
                        matches++;
                        break;
                    }
                }
            }

            return matches >= numToMatch - (numToMatch > 2 ? threshold : 0);
        }

    },
    created() {
        if (this.designer) return;

        console.log('signModel', this.reference.signModel);
        console.log('signRightsModel', this.reference.signRightsModel)
        if (this.reference.signRightsModel.available) {
            for (const i in this.reference.signRightsModel.options) {

                const option = this.reference.signRightsModel.options[i];
                const item = {persons:[]};
                

                for(const j in option.required){
                    const person = option.required[j];
                    person.email = '';
                    person.method = 'bankid-no';
                    person.direct = false;
                    person.language = this.recommendedLocale(this.locales).key;
                    person.emailValidation = {
                        done: false,
                        valid: false
                    };

                    person.languageValidation = {
                        done: false,
                        valid: false
                    };

                    person.methodValidation = {
                        done: false,
                        valid: false
                    };

                    if (this.reference.roleKey != 'owner') {
                        const pName = person.firstName + " " + person.lastName;
                        const rName = this.reference.recipient.givenName + " " + this.reference.recipient.familyName;

                        if(this.compareNames(pName, rName, 1)) {
                            person.email = this.reference.recipient.email;
                        }
                    }

                    item.persons.push(person);
                }

                if(item.persons.length < 1) {
                    continue;
                }

                this.$set(this.options, 'o' + i, item);
                this.$set(this.optionModel, 'o' + i, false);

                this.optionsFound = true;
            }
        }
        this.realSignature = this.optionsFound;

        if (this.reference.signRightsModel.description.length > 0) {

            this.signRightsDescriptionInfoText = this.__('signrights_description_info_text')
            console.log('Det ble funnet beskrivelse ', this.reference.signRightsModel.description);

        }else {

            this.signRightsDescriptionInfoText = this.__('signrights_description_info_text_no_brreg')
            console.log('Det ble ikke funnet beskrivelse');

        }

        console.log('signRightsModel', this.reference.signRightsModel);
        if (!this.reference.signRightsModel.available) this.overrideSignatureCheck = true;

        if (this.reference.roleKey == 'owner') this.validateOwner();
    }
}

</script>
<style>

.frv2-checkout-option {
    font-size: 18px;
}

.checkout-option {
    font-size: 18px;
    padding: 15px 10px;
}



.checkout-signer {
    display: flex;
    padding: 5px 2px;
}
.checkout-signer-left {
    flex-grow: 100;
}
.checkout-signer-right {
    display: flex;
}

.checkout-signer-name {
    font-weight: 500;
}
.checkout-signer-email {

}


.checkout-signer-action {
    margin-top: 9px;
    margin-left: 6px;
    font-size: 16px;
}
.checkout-signer-method {
    margin-top: 9px;
    margin-left: 6px;
    font-size: 16px;
    font-weight: 500;
}


.checkout-add-top {
    display: flex;
    margin-bottom: 15px;
}
.checkout-add-first {
    flex-grow: 100;
}
.checkout-add-list label {
    color: blue;
    cursor: pointer;
}
.checkout-add-list label:hover {
    color: red;
}

.checkout-add-self {
    color: var(--link-text);
}

.checkout-add-self:hover {
    color:  var(--link-active);
    cursor: pointer;
}




.frmod-checkout-designer {
    float: left;
    width: 100%;
    padding: 20px 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: solid 1px lightgray;
    background-color: var(--card-background);
    box-sizing: border-box;
}
.frmod-checkout-designer-selected {
    border: solid 2px var(--accent);
}




.frmod-checkout-message {
    float: left;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 10px;
    padding: 20px 35px;
    font-size: 15px;
    line-height: 22px;
    border-radius: 4px;
    background-color: var(--help-background);
    box-sizing: border-box;
}


</style>
