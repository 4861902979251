import axios from 'axios';
import log from './log';
const statusapi = {

    async getStatus() {
        const status = {
            checked: false,
            degraded: false,
            api: {
                operational: true,
                errorMessage: ''
            },
            baml: {
                operational: true,
                errorMessage: ''
            },
        };
        try {

            const response = await axios.get('https://api.sorryapp.com/v1/pages/c2ed623c/components');
            //console.log('response', response);

            for (const i in response.data.response) {

                switch (response.data.response[i].id) {
                    //case 5911: this.onCheckPlatform(status, response.data.response[i]); break; // Login
                    //case 5900: this.onCheckPlatform(status, response.data.response[i]); break; // Archive
                    case 5901: this.onCheckPlatform(status, response.data.response[i]); break; // Envelope builder
                    case 5910: this.onCheckPlatform(status, response.data.response[i]); break; // Authentication
                    case 5908: this.onCheckPlatform(status, response.data.response[i]); break; // Envelope actions
                    //case 5909: this.onCheckPlatform(status, response.data.response[i]); break; // Query

                    case 18559: this.onCheckAML(status, response.data.response[i]); break;
                }

            }

            status.checked = true;
        }catch (e) {
            log.e('Status: Failed to get API status', e);
        }
        return status;
    },

    onCheckPlatform(status, component) {
        if (component.state != 'operational') {
            status.api.operational = false;
            status.degraded = true;
            status.headerKey = 'sorry_api_head';
            status.messageKey = 'sorry_api_text';
        }
    },

    onCheckAML(status, component) {
        if (component.state != 'operational') {
            status.baml.operational = false;
            status.degraded = true;
            status.headerKey = 'sorry_baml_head';
            status.messageKey = 'sorry_baml_text';
        }
    },

    onCheckSigning(status, component) {

    }

};
export default statusapi;