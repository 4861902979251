<template>
<div>

    <PopupV1 :header="'Add rule'" @close="onClose" :dialog="dialog">
        <template v-slot:content>

            <VFRow>
                <VFGroup c12>
                    <VFLabel desc :text="'When'" />
                    <VFDropdown v-model="dialog.when" :options="whenOptions" />
                </VFGroup>
                <VFGroup c12>
                    <VFLabel desc :text="'Who'" />
                    <VFDropdown v-model="dialog.who" :options="whoOptions" />
                </VFGroup>
                <VFGroup c12>
                    <VFLabel desc :text="'Roles'" />
                    <VFCheckbox newDesign inline v-model="dialog.roleModel" :options="roleOptions" />
                </VFGroup>
            </VFRow>

        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="'Add rule'"
                :icon="'add'"
                @clicked="onAdd"
                :working="dialog.working"
                :disabled="!dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import monitorHelper from '../v1monitor/monitorHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFInput from '../components/form/inputs/VFInput.vue';
import VFDropdown from '../components/form/inputs/VFDropdown.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'MonitorRuleDialog',
    components: {
        PopupV1,
        VFButton,
        PopupV1,
        VFButton,
        VFRow,
        VFGroup,
        VFLabel,
        VFInput,
        VFDropdown,
        VFCheckbox,
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            dialog: { show: false, valid: false, working: false, when: '', who: '', roleModel: {} },
            whenOptions: [
                { value: 'when-org', display: 'Company search is completed' },
                { value: 'when-kyc', display: 'Self-declaration form is completed' },
                { value: 'when-rep', display: 'The final report is created' },
                { value: 'when-man', display: 'A person is added manually' },
            ],
            whoOptions: [
                { value: 'who-all', display: 'All persons' },
                { value: 'who-role', display: 'Persons with a specific role' },
            ],
            roleOptions: [],
        }
    },
    methods: {

        onClose() {
            this.$emit('close');
        },

        onAdd() {

        },

    },
    created() {
        const list = monitorHelper.getRoleList();

        this.dialog.roleModel = {};
        this.roleOptions = {};

        for (const i in list) {
            this.dialog.roleModel[list[i]] = false;
            this.roleOptions[list[i]] = { label: this.__(list[i]) };
        }
    }
}
</script>
<style scoped>

</style>