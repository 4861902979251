<template>
<vap-card :header="'Recipient'" style="margin-bottom: 20px;">

    <div v-if="roleKey == 'owner'">

        <vap-row>
            <vap-group c12>
                <vap-label :text="'CDD Team'" desc />
                <vap-dropdown v-model="model"
                    noValidation noHighlight
                    :options="options"
                    :displayKey="'display'"
                    :outputKey="'value'"
                    :listKey="'display'"
                    @input="onSelected"
                />
            </vap-group>
        </vap-row>

    </div>

    <div v-if="roleKey == 'provider'">

        <div>
            <div>
                {{ 'Checklist will be sent back to CDD' }}
            </div>
            <div style="font-size: 18px;">
                {{ reference.chkModel.cdd.firstName + ' ' + reference.chkModel.cdd.lastName }}
            </div>
        </div>

    </div>

    <div v-if="roleKey == 'approver' && reference.chkModel.key == 'amlc01'">

        <vap-row>
            <vap-group c12>
                <vap-label :text="'Approve or send back to branch?'" desc />
                <vap-checkbox v-model="approveModel"
                    radio
                    noValidation noHighlight
                    :options="approveOptions"
                    @input="onApproveModeChanged"
                />
            </vap-group>
        </vap-row>

        <div v-if="approveModel.sendBack">
            <div>
                {{ 'Checklist will be sent back to the branch' }}
            </div>
            <div style="font-size: 18px;">
                {{ reference.chkModel.branch.firstName }}
            </div>
        </div>

        <div v-if="approveModel.approve">
            <div>
                {{ 'Checklist will be approved and completed' }}
            </div>
        </div>

    </div>

    <div v-if="roleKey == 'approver' && reference.chkModel.key == 'amlc02'">

        <div>
            <div>
                {{ 'Checklist will be approved and completed' }}
            </div>
        </div>

    </div>

</vap-card>
</template>
<script>

export default {
    name: "frmod-checklist-checkout",
    props: {
        designer: { type: Boolean, default: false },
        reference: { type: Object, default: () => { return {} }},
        resources: { type: Object, default: () => { return {} }},
        roleKey: { type: String, default: 'owner' },
    },
    data() {
        return {
            model: 'cdd1',
            options: [
                { value: 'cdd1', display: 'CDD QC Assurance', /* email: 'CDD.Assurance@handelsbanken.com' */ email: '', firstName: 'CDD', lastName: 'QC Assurance' },
                { value: 'cdd2', display: 'High Risk Team', /* email: 'HRU_Branch_CDD_support@handelsbanken.com' */ email: '', firstName: 'High Risk', lastName: 'Team' },
                { value: 'cdd3', display: 'DEMO Mark CDD', email: 'mark.dodd@handelsbanken.co.uk', firstName: 'DEMO Mark', lastName: 'CDD' },
                { value: 'cdd4', display: 'DEMO Magnus CDD', email: 'magnus.ursin@verified.eu', firstName: 'DEMO Magnus', lastName: 'CDD' },
            ],
            approveModel: {
                approve: false,
                sendBack: false
            },
            approveOptions: {
                approve: { label: 'Approve checklist' },
                sendBack: { label: 'Send back to branch' },
            }
        }
    },
    methods: {
        
        onSelected() {
            
            for (const i in this.options) {

                if (this.options[i].value == this.model) {

                    this.reference.chkModel.cdd.key = this.options[i].value;
                    this.reference.chkModel.cdd.firstName = this.options[i].firstName;
                    this.reference.chkModel.cdd.lastName = this.options[i].lastName;
                    this.reference.chkModel.cdd.email = this.options[i].email;

                }

            }

            console.log('chkModel', this.reference.chkModel);
            this.reference.validateEverything();
        },

        onApproveModeChanged() {
            this.reference.chkModel.finalValidation = this.approveModel.approve;
            console.log('revalidate all');
            this.reference.validateEverything();
        },

    },
    created() {
        if (this.designer) return;

        if (this.reference.chkModel.key == 'amlc01') {
            this.options = [
                { value: 'cdd1', display: 'CDD QC Assurance', /* email: 'CDD.Assurance@handelsbanken.com' */ email: '', firstName: 'CDD', lastName: 'QC Assurance' },
                { value: 'cdd3', display: 'DEMO Mark CDD', email: 'mark.dodd@handelsbanken.co.uk', firstName: 'DEMO Mark', lastName: 'CDD' },
                { value: 'cdd4', display: 'DEMO Magnus CDD', email: 'magnus.ursin@verified.eu', firstName: 'DEMO Magnus', lastName: 'CDD' },

                { value: 'cdd5', display: 'DEMO Hannah Metcalfe CDD', email: 'hannah.metcalfe@handelsbanken.co.uk', firstName: 'DEMO Hannah', lastName: 'CDD' },
                { value: 'cdd6', display: 'DEMO Sofia Zaffer CDD', email: 'sofia.zaffer@handelsbanken.co.uk', firstName: 'DEMO Sofia', lastName: 'CDD' },
                { value: 'cdd7', display: 'DEMO Jenny Renhuldt CDD', email: 'jere06@handelsbanken.se', firstName: 'DEMO Jenny', lastName: 'CDD' },
            ];
            this.model = 'cdd1';
        }else if (this.reference.chkModel.key == 'amlc02') {
            this.options = [
                { value: 'cdd2', display: 'High Risk Team', /* email: 'HRU_Branch_CDD_support@handelsbanken.com' */ email: '', firstName: 'High Risk', lastName: 'Team' },
                { value: 'cdd3', display: 'DEMO Mark CDD HR', email: 'pugheaven@hotmail.com', firstName: 'DEMO Mark', lastName: 'CDD HR' },
                { value: 'cdd4', display: 'DEMO Magnus CDD HR', email: 'magnus.ursin@verified.eu', firstName: 'DEMO Magnus', lastName: 'CDD HR' },

                { value: 'cdd5', display: 'DEMO Hannah Metcalfe CDD HR', email: 'hannah.metcalfe@handelsbanken.co.uk', firstName: 'DEMO Hannah', lastName: 'CDD HR' },
                { value: 'cdd6', display: 'DEMO Sofia Zaffer CDD HR', email: 'sofia.zaffer@handelsbanken.co.uk', firstName: 'DEMO Sofia', lastName: 'CDD HR' },
                { value: 'cdd7', display: 'DEMO Jenny Renhuldt CDD HR', email: 'jere06@handelsbanken.se', firstName: 'DEMO Jenny', lastName: 'CDD HR' },
            ];
            this.model = 'cdd2';
        }

        if (this.reference.chkModel.finalValidation) {
            this.approveModel.approve = true;
            this.approveModel.sendBack = false;
        }else {
            this.approveModel.approve = false;
            this.approveModel.sendBack = true;
        }
        
        if (!this.reference.chkModel.cdd.key) {
            this.reference.chkModel.cdd.key = this.options[0].value;
            this.reference.chkModel.cdd.firstName = this.options[0].firstName;
            this.reference.chkModel.cdd.lastName = this.options[0].lastName;
            this.reference.chkModel.cdd.email = this.options[0].email;
        }

    }
}

</script>
<style>



</style>
