<template>
<div>

    <vap-form-divider :left="55" :right="45">
        <template v-slot:left>

            <vap-card list v-for="(sapp, i) in appList" :key="'sa' + i">

                <div class="shareapp-item">
                    <div class="shareapp-item-status">
                        <OverviewStatus v-if="sapp.loading"
                            :title="sapp.name"
                            :text="'LASTER INN'"
                            :name="''"
                        />
                        <OverviewStatus v-if="!sapp.loading && !sapp.local.signed"
                            :title="sapp.name"
                            :text="__('ov_kyc_awaiting_signing')"
                            :name="__('ov_kyc_status_by') + sapp.recipientName"
                        />
                        <OverviewStatus v-if="!sapp.loading && sapp.local.signed"
                            completed
                            :title="sapp.name"
                            :text="__('ov_kyc_status_complete')"
                            :name="__('ov_kyc_status_by') + sapp.local.signedNames"
                        />
                    </div>
                    <div class="shareapp-item-actions" v-if="!sapp.loading">
                        <vap-icon v-if="sapp.local.signed" :icon="'file-search'" action :color="'var(--accent)'" @clicked="showReceipt(sapp)">
                            <template v-slot:tooltip>
                                <vap-tooltip :localized="'show_receipt'" />
                            </template>
                        </vap-icon>
                        <vap-icon v-if="!sapp.local.signed" :icon="'reminder'" action :color="'var(--accent)'" @clicked="showReminderDialog(sapp)">
                            <template v-slot:tooltip>
                                <vap-tooltip :localized="'ov_send_reminder'" />
                            </template>
                        </vap-icon>
                    </div>
                </div>

            </vap-card>

            <div class="shareapp-empty flw100" v-if="appList.length == 0">
                <label>{{ __('shareapp_empty') }}</label>
            </div>

        </template>
        <template v-slot:right>

            <vap-card noPadding style="margin-bottom: 10px;">
                <vap-item fancy fancyCircle padding20 :fancyIcon="'add'"
                    :fancyText="__('shareapp_new')"
                    @clicked="onNewApplicationClicked">
                </vap-item>
            </vap-card>

        </template>
    </vap-form-divider>
    
</div>
</template>

<script>
import log from '@/log';
import api from '@/portalapi';
import shareapp from './shareapp';

import OverviewStatus from '@/components/customer/OverviewStatus';

export default {
    name: 'ShareApplications',
    components: {
        OverviewStatus
    },
    props: {
        customer: {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            appList: []
        }
    },
    watch: {
        
    },
    methods: {
        
        loadShareApplications() {
            this.appList = this.customer.shareApps || [];
            for (const i in this.appList) {
                //this.appList[i].local = shareapp.getEnvelopeObject();
                this.$set(this.appList[i], 'loading', true);
                this.$set(this.appList[i], 'local', shareapp.getEnvelopeObject());
            }
            this.loadMetadata();
        },

        async loadMetadata() {
            for (const i in this.appList) {
                const envelope = await this.$root.getEnvelope(this.appList[i].envelopeId);
                shareapp.getEnvelopeData(envelope, this.appList[i].local);
                console.log('meta', this.appList[i].local);
                this.appList[i].loading = false;
            }
        },

        onNewApplicationClicked() {
            this.$emit('shareapp', true);
        },

        showReceipt(sapp) {
            console.log('emit', sapp);
            this.$emit('receipt', sapp.envelopeId, 'Tegningsblankett ' + sapp.name, false);
        },

        async showReminderDialog(sapp) {

            const envelope = await this.$root.getEnvelope(sapp.envelopeId);
            const metadata = shareapp.getEnvelopeObject();
            shareapp.getEnvelopeData(envelope, metadata);

            console.log('metadata', metadata);

            const reminderData = {
                firstName: metadata.editor.firstName,
                lastName: metadata.editor.lastName,
                email: metadata.editor.email,
                envelopeId: sapp.envelopeId,
                recipientId: metadata.editor.id
            }
            this.$emit('reminder', reminderData);
        }

    },
    async created() {
        this.loadShareApplications();
    }
}
</script>
<style scoped>

.shareapp-item {
    display: flex;
    padding-left: 10px;
    padding-bottom: 5px;
}

.shareapp-item-status {
    flex-grow: 100;
}

.shareapp-item-actions {
    display: flex;
}

.shareapp-item-status {
    margin-top: -26px;
}

.shareapp-empty {
    padding-top: 30px;
    text-align: center;
}

</style>
