<template>
<div>
    <PageHeader>
        <template v-slot:content>
            <AlphabetSelector :letters="letters" @letter="onLetterSelected" v-if="!$root.phoneMode" />
        </template>
    </PageHeader>

    <div class="aml-page">
        
        <vap-form-divider :left="25" :right="75" style="margin-top: 10px;">
            <template v-slot:left>

                <!-- Filters -->
                <vap-label :text="__('filters')" header2 style="margin-top: 10px; margin-bottom: 10px;" />

                <!-- New client button -->
                <vap-card noPadding style="margin-bottom: 10px;">
                    <vap-item fancy fancyCircle padding20 :fancyIcon="'add'"
                        :fancyText="__t('clients-new')"
                        :fancyHint="__t('clients-new-hint')"
                        @clicked="onNewClientClicked">
                    </vap-item>
                </vap-card>

                <!-- Report button -->
                <vap-card noPadding style="margin-bottom: 10px;" v-if="$root.config.clients.reports || $root.config.export.enabled">
                    <vap-item fancy fancyCircle padding20 :fancyIcon="'checklist'"
                        :fancyText="__t('clients-report')"
                        :fancyHint="__t('clients-report-hint')"
                        @clicked="onReportClicked">
                    </vap-item>
                </vap-card>

                <!-- Search -->
                <SearchBox ref="fltSear" @search="onSearch" :placeholder="__('clients_search')" style="margin-top: 10px; margin-bottom: 16px;" />

                <RemoveFilterButton v-if="hasActiveFilters()" @remove="removeAllFilters" />

                <VFCheckbox newDesign v-if="$root.config.features.watchList" style="margin-top: 10px;"
                    v-model="watchOnly"
                    :text="'Show only customers on my watch-list'"
                    @input="onWatchOnlyChecked"
                />

                <div>
                    <FilterControl empty :header="__('sort_last_updated')" />
                    <div style="float: left; width: 50%; margin-top: 10px;">
                        <SortDatePicker v-model="sortDateFrom" noHighlight noValidation :hintKey="'datepicker_from'" :placeholder="__('datepicker_select_date')" outputISO @input="onDateChanged" />
                    </div>
                    <div style="float: left; width: 50%; margin-top: 10px;">
                        <SortDatePicker v-model="sortDateTo" noHighlight noValidation :hintKey="'datepicker_to'" :placeholder="__('datepicker_select_date')" right outputISO @input="onDateChanged" />
                    </div>
                </div>

                <div v-if="filters">

                    <div v-for="(filter, i) in possibleFilters" :key="'cpf' + i">
                        <FilterControlV1
                            :params="filter"
                            :filters="filters[filter.processedKey]"
                            :texts="texts[filter.textKey]"
                            :activeValues="activeFilters[filter.key]"
                            @filter="onFilterChanged"
                        />
                    </div>

                </div>

            </template>
            <template v-slot:right>

                <div style="display: flex;">
                    <vap-label :text="__('customers')" header2 style="flex-grow: 100; margin-top: 10px; margin-bottom: 10px;" />
                    <SortSelector v-model="sortModel" :options="sortOptions" style="flex-shrink: 0; margin-top: 18px;" />
                </div>

                <div v-if="!loading" class="flw100">

                    <vap-card compactList noPadding v-for="(client, i) in clients" :key="'c' + i">
                        <ClientItem
                            :icon="client.type == 'person' ? 'person' : 'company'"
                            :name="companyTitleCase(client.name)"
                            :info="getInfoText(client)"
                            :date="formatDate('datetime', sortModel == 'date_created_desc' ? client.dateCreated : client.dateUpdated)"
                            :user="texts.employees[client.userId][$root.locale.key]"
                            :hasStatus="true"
                            :statusColor="getStatusColor(client)"
                            :completed="isCompleted(client.status)"
                            :link="'/v1client?id=' + client._id"
                            :newItem="false"
                            :locked="$root.config.params.limitRegular && $root.credentials.role == 'regular'"
                            :projects="$root.config.projects.enabled && client.projects && client.projects.length > 0"
                            :tags="$root.config.tags.clientsShow"
                        >
                            <ProjectStatusList :client="client" />
                            <template v-slot:tags>
                                <TagList :client="client" />
                            </template>
                        </ClientItem>
                    </vap-card>

                </div>

                <CenteredSpinner v-if="loading" />
                <EmptyIcon v-if="!loading && clients.length == 0" :icon="'company'" :text="__('customer_no_hits')" />

                <PageControl v-if="!loading" :total="totalPages" :current="currentPage" @page="onNavigate" />

            </template>
        </vap-form-divider>

    </div>
</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import log from '../log';

import defaultFlows from '../v1flows/defaultFlows';

import PageHeader from '../v1ui/PageHeader.vue';
import AlphabetSelector from '../v1ui/AlphabetSelector.vue';
import ClientItem from '../components/items/ClientItem.vue';

import FilterControlV1 from '../v1ui/FilterControlV1.vue';
import RemoveFilterButton from '../v1ui/RemoveFilterButton.vue';
import PageControl from '../components/special/PageControl';
import SearchBox from '../components/special/SearchBox';
import SortSelector from '../components/special/SortSelector';
import SortDatePicker from '../components/special/SortDatePicker';

import EmptyIcon from '../v1ui/EmptyIcon.vue';
import CenteredSpinner from '../v1ui/CenteredSpinner.vue';

import FilterControl from '../components/special/FilterControl';

import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

import ProjectStatusList from '../v1projects/ProjectStatusList.vue';

import TagList from '../v1ui/TagList.vue';

export default {
    name: 'ClientsV1',
    components: {
        PageHeader,
        AlphabetSelector,
        ClientItem,
        FilterControlV1,
        RemoveFilterButton,
        PageControl,
        SearchBox,
        SortSelector,
        SortDatePicker,
        EmptyIcon,
        CenteredSpinner,
        FilterControl,
        VFCheckbox,
        ProjectStatusList,
        TagList,
    },
    data() {
        return {
            loading: true,
            itemsPerPage: 10,
            currentPage: 0,
            totalPages: 0,
            totalCount: 0,

            clients: [],

            letters: [],

            sortModel: 'date_updated_desc',
            sortOptions: [
                { key: 'sort_last_updated', value: 'date_updated_desc' },
                { key: 'sort_last_created', value: 'date_created_desc' },
                { key: 'sort_name', value: 'name_desc' },
            ],

            filters: null,
            possibleFilters: [],
            activeFilters: {},
            letterFilter: [],

            sortDateFrom: '',
            sortDateTo: '',
            filterDateFrom: null,
            filterDateTo: null,
            filterDateActive: false,

            watchOnly: false,

            texts: {
                status: {},
                flows: {},
                departments: {},
                employees: {},
                hits: {},
                risk: {},
                branches: {},
                partners: {},
                custom: {},
                tags: {},
            },
            completedKeys: []
        }
    },
    watch: {
        sortModel: {
            deep: false,
            handler(newVal, oldVal) {
                if (this.loading) return;
                this.queryClients();
            }
        }
    },
    methods: {
        
        onNewClientClicked() {
            this.$parent.navigate({ path: '/v1search' });
        },

        onReportClicked() {
            this.$root.reportData = {
                sort: { dateUpdated: -1 },
                filters: this.getFilters()
            };
            if (this.$root.config.export.enabled) {
                this.$parent.navigate({ path: '/v2reports' });
            }else {
                this.$parent.navigate({ path: '/v1reports' });
            }
        },

        onNavigate(page) {
            this.currentPage = page;
            this.queryClients();
        },

        onLetterSelected(letter) {
            this.letterFilter.push(letter);
            this.queryClients();
        },

        onWatchOnlyChecked() {
            this.currentPage = 1;
            this.queryClients(true);
        },

        //#region Client status -----------------------------------------------------------------------------------------------

        getInfoText(client) {
            let text = this.texts.status[client.status] ? this.texts.status[client.status][this.$root.locale.key] : 'Unknown status';
            if (this.$root.config.externalId.clientsEnabled && client.externalId) {
                text += ' | ' + this.$root.config.externalId.title[this.$root.locale.key] + ': ' + client.externalId;
            }
            return text;
        },

        getStatusColor(client) {
            for (const i in this.$root.config.risk.texts) {
                if (this.$root.config.risk.texts[i].value == client.risk) {
                    if (this.$root.config.risk.texts[i].color.includes('#')) {
                        return this.$root.config.risk.texts[i].color;
                    }else {
                        return 'var(--' + this.$root.config.risk.texts[i].color + ')';
                    }
                }
            }
            return null;
        },

        isCompleted(status) {
            return (this.completedKeys.includes(status));
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Filters -----------------------------------------------------------------------------------------------------

        onSearch(str) {
            this.searchString = str;
            this.currentPage = 1;
            this.queryClients();
        },

        onDateChanged() {
            if (this.sortDateFrom && this.sortDateTo) {
                this.filterDateFrom = new Date(this.sortDateFrom);
                this.filterDateTo = new Date(this.sortDateTo + 'T23:59:59.000Z');
                this.filterDateActive = true;
                this.currentPage = 1;
                this.queryClients();
            }
        },

        onFilterChanged(filter, value) {
            for (const i in this.activeFilters) {
                if (i == filter.key) {
                    let match = false;
                    for (const j in this.activeFilters[i]) {
                        if (this.activeFilters[i][j] == value) {
                            this.activeFilters[i].splice(j, 1);
                            match = true;
                        }
                    }
                    if (!match) this.activeFilters[i].push(value);
                }
            }
            this.currentPage = 1;
            this.queryClients();
        },

        hasActiveFilters() {
            if (this.searchString) return true;
            if (this.filterDateActive) return true;
            if (this.letterFilter.length > 0) return true;
            for (const i in this.activeFilters) {
                if (this.activeFilters[i].length > 0) return true;
            }
            return false;
        },

        removeAllFilters() {
            this.filterDateActive = false;
            this.sortDateFrom = '';
            this.sortDateTo = '';
            this.searchString = '';
            this.letterFilter = [];
            for (const i in this.activeFilters) {
                this.activeFilters[i] = [];
            }
            this.currentPage = 1;
            this.queryClients();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        getFilters() {
            const filters = [];

            if (this.watchOnly) {
                filters.push({ field: 'watch', op: 'in', values: [this.$root.credentials.uid] });
            }

            if (this.$root.config.params.hasDepartments && this.$root.credentials.branch && !this.$root.credentials.isMasterBranch) {
                filters.push({ field: 'department', op: 'in', values: [this.$root.credentials.branch.toString()] });
            }
            if (this.searchString) {
                filters.push({ field: 'name', op: 'search', value: this.searchString });
            }
            if (this.filterDateActive) {
                filters.push({ field: 'dateCreated', op: 'daterange', from: this.filterDateFrom, to: this.filterDateTo });
            }
            if (this.letterFilter.length > 0) {
                filters.push({ field: 'letter', op: 'in', values: this.letterFilter });
            }
            for (const key in this.activeFilters) {
                if (this.activeFilters[key].length > 0) {
                    for (const i in this.possibleFilters) {
                        if (this.possibleFilters[i].key == key) {
                            filters.push({ field: this.possibleFilters[i].processedKey, op: 'in', values: this.activeFilters[key] });
                        }
                    } 
                }
            }
            return filters;
        },

        async queryClients(initial) {
            this.loading = true;

            let sort = { dateUpdated: -1 };
            if (this.sortModel == 'date_created_desc') sort = { dateCreated: -1 };
            if (this.sortModel == 'name_desc') sort = { name: 1 };

            const filters = this.getFilters();
            const from = (Number(this.currentPage) - 1) * Number(this.itemsPerPage);
            const limit = this.itemsPerPage;
            const include = [];
            const counts = ['letter'];
            for (const i in this.possibleFilters) {
                if (this.possibleFilters[i].formKey) {
                    counts.push(this.possibleFilters[i].formKey + '.fields.' + this.possibleFilters[i].key);
                }else {
                    counts.push(this.possibleFilters[i].key);
                }
            }

            const response = await v1api.queryClients(filters, from, limit, sort, include, counts);

            this.totalCount = response.count;
            this.totalPages = Math.ceil(Number(this.totalCount) / Number(this.itemsPerPage));

            this.clients = response.clients;
            this.filters = response.filters;

            // Sort filters
            if (this.filters.status) this.filters.status.sort((a, b) => (a._id > b._id) ? 1 : -1);
            if (this.filters.flow) this.filters.flow.sort((a, b) => (a._id > b._id) ? 1 : -1);
            if (this.filters.userId) {
                this.filters.userId.sort((a, b) => (this.getSortValue('employees', a._id) > this.getSortValue('employees', b._id)) ? 1 : -1);
            }
            if (this.filters.partner) {
                this.filters.partner.sort((a, b) => (this.getSortValue('partners', a._id) > this.getSortValue('partners', b._id)) ? 1 : -1);
            }
            if (this.filters.branch) {
                this.filters.branch.sort((a, b) => (this.getSortValue('branches', a._id) > this.getSortValue('branches', b._id)) ? 1 : -1);
            }

/*             const all = [];
            const duplicates = [];
            for (const i in this.filters.partner) {
                let match = false;
                for (const j in all) {
                    if (this.filters.partner[i]._id && all[j]._id && this.filters.partner[i]._id.toString() == all[j]._id.toString()) {
                        match = true;
                    }
                }
                all.push(this.filters.partner[i]);
                if (match) {
                    duplicates.push(this.filters.partner[i]);
                }
            }
            console.log('duplicates', duplicates);

            console.log(JSON.stringify(duplicates));

            for (const i in duplicates) {
                console.log(duplicates[i]._id);
            } */

            this.letters = [];
            const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ#';
            for (let i=0; i < str.length; i++) {
                let match = false;
                for (let j=0; j < response.filters.letter.length; j++) {
                    if (response.filters.letter[j]._id == str.charAt(i)) { match = true; break; }
                }
                this.letters.push({ letter: str.charAt(i), active: match, deselected: false });
            }

            if (!initial) this.updateUrl();
            this.loading = false;
        },

        getSortValue(type, value) {
            if (!value) return 'AAA';
            if (this.texts[type][value]) return this.texts[type][value][this.$root.locale.key];
            return 'zzz';
        },

        updateUrl() {

            const page = this.currentPage;

            const filters = [];

            for (const key in this.activeFilters) {


                if (this.activeFilters[key].length > 0) {
                    filters.push({
                        key: key,
                        value: this.activeFilters[key][0]
                    });
                }

            }

            console.log('page', page);
            console.log('filters', filters);

            this.$router.replace({query: { page: page, search: this.searchString, filters: encodeURIComponent(JSON.stringify(filters)) } });
        },

        parseUrl() {
            const result = {
                page: 1,
                filters: []
            };
            try {

                if (this.$route.query.page) result.page = Number(this.$route.query.page);

                if (this.$route.query.filters) {

                    const filters = JSON.parse(decodeURIComponent(this.$route.query.filters));

                    result.filters = filters;

                }

                if (this.$route.query.search) {

                    this.searchString = this.$route.query.search;

                }

            }catch (ex) {
                console.error(ex);
            }
            return result;
        },

    },
    async created() {
        this.$parent.setTitle(this.__('customers'));

        this.sortModel = this.$root.config.clients.defaultSort || 'date_updated_desc';

        for (const i in this.$root.config.clients.filters) {
            if (this.$root.config.clients.filters[i].enabled) {
                const filter = {
                    enabled: this.$root.config.clients.filters[i].enabled,
                    search: this.$root.config.clients.filters[i].search,
                    limit: this.$root.config.clients.filters[i].limit,
                    key: this.$root.config.clients.filters[i].key,
                    processedKey: this.$root.config.clients.filters[i].key,
                    textKey: this.$root.config.clients.filters[i].textKey,
                    title: this.$root.config.clients.filters[i].title,
                    formKey: this.$root.config.clients.filters[i].formKey,
                    emptyText: this.$root.config.clients.filters[i].emptyText,
                };
                if (this.$root.config.clients.filters[i].formKey) {
                    filter.processedKey = this.$root.config.clients.filters[i].formKey + '.fields.' + this.$root.config.clients.filters[i].key;
                }
                this.possibleFilters.push(filter);
                this.$set(this.activeFilters, this.$root.config.clients.filters[i].key, []);
            }
        }

        // Load flows / statuses / custom fields
        const flows = [];
        const dflows = defaultFlows.getFlows();
        for (const i in dflows) flows.push(defaultFlows.getFlow(dflows[i]));
        for (const i in this.$root.flowConfig.flows) flows.push(this.$root.flowConfig.flows[i]);
        for (const i in flows) {
            const flow = flows[i];
            for (const j in flow.statuses) {
                if (!this.texts.status[flow.statuses[j].key]) {
                    this.texts.status[flow.statuses[j].key] = flow.statuses[j].text;
                }else {
                    for (const k in flow.statuses[j].text) {
                        this.texts.status[flow.statuses[j].key][k] = flow.statuses[j].text[k];
                    }
                }
                if (flow.statuses[j].completed) this.completedKeys.push(flow.statuses[j].key);
            }
            if (flow.fields) {
                for (const j in flow.fields) {
                    for (const k in flow.fields[j].values) {
                        const value = {};
                        for (const key in flow.fields[j].values[k]) {
                            if (key != 'key') value[key] = flow.fields[j].values[k][key];
                        }
                        this.texts.custom[flow.fields[j].values[k].key] = value;
                    }
                }
            }
            this.texts.flows[flow.key] = flow.title;
        }

        // Load departments
        if (this.$root.config.params.hasDepartments) {
            this.departments = [];
            for (const i in this.$root.departments) {
                const text = {};
                for (const j in this.$root.config.locales) {
                    if (this.$root.config.locales[j].enabled) {
                        text[this.$root.config.locales[j].key] = this.$root.departments[i].name;
                    }
                }
                this.texts.departments[this.$root.departments[i].key.toString()] = text;
            }
        }

        // Load branches
        if (this.$root.config.branches.enabled) {
            for (const i in this.$root.departments) {
                const text = {};
                for (const j in this.$root.config.locales) {
                    if (this.$root.config.locales[j].enabled) {
                        text[this.$root.config.locales[j].key] = this.$root.departments[i].name;
                    }
                }
                this.texts.branches[this.$root.departments[i].key.toString()] = text;
            }
        }
        // Load partners
        if (this.$root.config.partners.enabled) {
            for (const i in this.$root.partners) {
                const text = {};
                for (const j in this.$root.config.locales) {
                    if (this.$root.config.locales[j].enabled) {
                        text[this.$root.config.locales[j].key] = this.$root.partners[i].firstName + ' ' + this.$root.partners[i].lastName;
                    }
                }
                this.texts.partners[this.$root.partners[i].key.toString()] = text;
            }
        }

        // Load employees
        for (let i in this.$root.employees) {
            const text = {};
            for (const j in this.$root.config.locales) {
                if (this.$root.config.locales[j].enabled) {
                    text[this.$root.config.locales[j].key] = this.$root.employees[i].name;
                }
            }
            this.texts.employees[this.$root.employees[i].id] = text;
        }

        // Create risk options
        for (const i in this.$root.config.risk.values) {
            for (const j in this.$root.config.risk.texts) {
                if (this.$root.config.risk.texts[j].value == this.$root.config.risk.values[i]) {
                    const option = {};
                    for (const key in this.$root.config.risk.texts[j]) {
                        if (key != 'value' && key != 'color') option[key] = this.$root.config.risk.texts[j][key];
                    }
                    //this.$set(this.riskOptions, this.$root.config.risk.texts[j].value, option);
                    this.texts.risk[this.$root.config.risk.texts[j].value] = option;
                }
            }
        }

        // Tags
        if (this.$root.config.tags.clientsShow) {
            for (const i in this.$root.config.tags.list) {
                const text = {};
                for (const j in this.$root.config.locales) {
                    if (this.$root.config.locales[j].enabled) {
                        text[this.$root.config.locales[j].key] = this.$root.config.tags.list[i].text[this.$root.config.locales[j].key];
                    }
                }
                this.texts.tags[this.$root.config.tags.list[i].key] = text;
            }
        }

        // Create hit options
        this.texts.hits['0'] = this.$root.v1translations['clients-hits-none'];
        this.texts.hits['1'] = this.$root.v1translations['clients-hits-pep'];
        this.texts.hits['2'] = this.$root.v1translations['clients-hits-sanction'];
        this.texts.hits['3'] = this.$root.v1translations['clients-hits-both'];

        const result = this.parseUrl();
        this.currentPage = result.page || 1;

        for (const i in result.filters) {
            this.activeFilters[result.filters[i].key].push(result.filters[i].value);
        }

        this.queryClients(true);
    }
}
</script>