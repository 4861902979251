var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vap-form-divider"},[_c('div',{staticClass:"vap-form-divider-left",class:[
        {'w25' : _vm.left == 25},
        {'w35' : _vm.left == 35},
        {'w40' : _vm.left == 40},
        {'w50' : _vm.left == 50},
        {'w55' : _vm.left == 55},
        {'w65' : _vm.left == 65},
        {'w100' : _vm.left == 100} ]},[_vm._t("left")],2),_c('div',{ref:"right",staticClass:"vap-form-divider-right",class:[
        {'vap-sticky' : _vm.stickRight},
        {'w0' : _vm.right == 0},
        {'w35' : _vm.right == 35},
        {'w45' : _vm.right == 45},
        {'w50' : _vm.right == 50},
        {'w60' : _vm.right == 60},
        {'w65' : _vm.right == 65},
        {'w75' : _vm.right == 75} ]},[_vm._t("right")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }