<template>
    <div v-if="value">

        <vap-row v-if="!value.needsRole && config.name > 0">
            <vap-group :c4="config.percentage < 1" :c3="config.percentage == 2">
                <vap-label :text="__('common_first_name')" desc :required="config.name == 2" />
                <vap-input v-model="value.firstName"
                    :rules="config.name == 2 ? 'required' : ''"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput" />
            </vap-group>
            <vap-group :c5="config.percentage < 1" :c3="config.percentage == 2">
                <vap-label :text="__('common_last_name')" desc :required="config.name == 2" />
                <vap-input v-model="value.lastName"
                    :rules="config.name == 2 ? 'required' : ''"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput" />
            </vap-group>
            <vap-group c3>
                <vap-label :text="__('common_dob')" desc :required="config.name == 2" />
                <vap-datepicker v-model="value.dateOfBirth"
                    outputISO
                    :rules="config.name == 2 ? 'required' : ''"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput" />
            </vap-group>
            <vap-group c3 v-if="config.percentage > 0">
                <vap-label :text="__('common_share')" desc :required="config.percentage == 2" />
                <vap-input v-model="value.percent"
                    :rules="config.percentage == 2 ? 'required|percentage' : 'percentage'"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput" />
            </vap-group>
        </vap-row>



        <vap-row  v-if="!value.needsRole && config.address > 0">
            <vap-group c4>
                <vap-label :text="__('common_address')" desc :required="config.address == 2" />
                <vap-input v-model="value.address"
                    :rules="config.address == 2 ? 'required' : ''"
                    :placeholder="config.address == 2 ? __('common_required') : __('common_optional')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput" />
            </vap-group>
            <vap-group c2>
                <vap-label :text="__('common_zip')" desc :required="config.address == 2" />
                <vap-input v-model="value.zip" numeric
                    :dictionary="$root.zipNumbersNO"
                    :rules="config.address == 2 ? 'required' : ''"
                    :placeholder="config.address == 2 ? __('common_required') : __('common_optional')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput"
                    mask="####"
                    @on-dictionary-match="value.city = $event" />
            </vap-group>
            <vap-group c3>
                <vap-label :text="__('common_city')" desc :required="config.address == 2" />
                <vap-input v-model="value.city"
                    :rules="config.address == 2 ? 'required' : ''"
                    :placeholder="config.address == 2 ? __('common_required') : __('common_optional')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput" />
            </vap-group>
            <vap-group c3>
                <vap-label :text="__('common_country')" desc :required="config.address == 2" />
                <vap-dropdown v-model="value.country"
                    :options="$root.countriesJson"
                    :commonOptions="$root.countriesCommon"
                    :placeholder="config.address == 2 ? __('common_required') : __('common_optional')"
                    :listKey="$root.locale.key"
                    :displayKey="$root.locale.key"
                    :rules="config.address == 2 ? 'required' : ''"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput" />
            </vap-group>
        </vap-row>



        <vap-row v-if="!value.needsRole && config.title > 0">
            <vap-group c4>
                <vap-label :text="__('common_role')" desc :required="config.title == 2" />
                <vap-input v-model="value.role"
                    :rules="config.title == 2 ? 'required' : ''"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput" />
            </vap-group>
            <vap-group c8>
                <vap-label :text="__('pep_title')" desc :required="config.title == 2" />
                <vap-input v-model="value.title"
                    :rules="config.title == 2 ? 'required' : ''"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput" />
            </vap-group>
<!--             <vap-group c12>
                <vap-label :text="'Kommentar'" desc :required="config.title == 2" />
                <vap-input v-model="value.comment"
                    :rules="config.title == 2 ? 'required' : ''"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight" />
            </vap-group> -->
        </vap-row>



        <vap-row v-if="!value.needsRole">
            <vap-group c6>
                <vap-label :text="__('common_email')" desc :required="config.email == 2" />
                <vap-input v-model="value.email"
                    :rules="config.email == 2 ? 'required|email' : ''"
                    :placeholder="config.email == 2 ? __('common_required') : __('common_optional')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput" />
            </vap-group>
            <vap-group c6 v-if="config.phone > 0">
                <vap-label :text="__('common_phone')" desc :required="config.phone == 2" />
                <vap-phone v-model="value.phone"
                    :rules="config.phone == 2 ? 'required' : ''"
                    :placeholder="config.phone == 2 ? __('common_required') : __('common_optional')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="onInput" />
            </vap-group>
            <vap-group c6 v-if="config.pep > 0">
                <vap-label :text="__('pep_question')" desc :required="config.pep == 2" />
                <vap-checkbox inline radio bordered v-model="value.pepQuestion" :rules="config.pep == 2 ? 'required' : ''" help :helpHtml="'helpicon_pep_html'"
                    :options="{
                        yes: { label: __('common_yes') },
                        no: { label: __('common_no') }
                    }"
                    @input="onPepSwitch" :noHighlight="noHighlight"
                    :noValidation="noValidation" />
            </vap-group>
        </vap-row>





        <vap-row v-if="value.needsRole">
            <vap-group c12>
                <vap-label :text="__('common_select_role')" desc required />
                <vap-dropdown v-model="manualRole"
                    :options="roleOptions"
                    :outputKey="'value'"
                    :listKey="$root.locale.key"
                    :displayKey="$root.locale.key"
                    :placeholder="__('common_select_role')"
                    :rules="required ? 'required' : ''"
                    :noHighlight="noHighlight"
                    @input="selectRole" />
            </vap-group>
        </vap-row>

    </div>
</template>

<script>
import aml from '../../aml';

export default {
    name: 'RoleInputs',
    props: {
        value: {
            type: Object,
            default: null
        },
        config: {
            type: Object,
            default: () => {
                return {

                }
            }
        },
        roleOptions: {
            type: Array,
            default: () => { return [] }
        },
        required: {
            type: Boolean,
            default: false
        },
        noValidation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            noHighlight: true,
            manualRole: ''
        }
    },
    computed: {
        
    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            }
        },
    },
    methods: {
        selectRole() {
            this.value.roleId = this.manualRole;
            this.$set(this.value, 'pepQuestion', { yes: false, no: false });
            for (let i in this.roleOptions) {
                if (this.roleOptions[i].value == this.manualRole) this.value.role = this.roleOptions[i].display;
            }
            this.value.needsRole = false;
            this.$emit('role', this.value.role);
            this.$emit('changed', '');
        },
        onPepSwitch() {
            this.$emit('pep', this.value.pepQuestion);
            this.$emit('changed', '');
        },
        onInput() {
            this.$emit('changed', '');
        }
    },
    async mounted() {
        
    }
}
</script>
<style scoped>


</style>