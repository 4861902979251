<template>
<div>
    
    <PropertyHeader :header="'Logic Designer'">
        <template v-slot:actions>
            <DesignerButton small
                :icon="'add'"
                :tooltip="'Add logic'"
                @clicked="$emit('logic', { mode: 'manage', addLogic: true })"
            />
        </template>
    </PropertyHeader>

    <PropertyItem padded :header="item.name" :icon="'logic'" v-for="(item, i) in items" :key="'pm' + i">

        <DesignerGroup c4>
            <DesignerLabel :text="'Indexed'" />
            <DesignerCheckbox v-model="reference.logic[item.index].indexed" :text="'Indexed'" />
        </DesignerGroup>

        <DesignerGroup c4>
            <DesignerLabel :text="'Index key'" />
            <DesignerInput v-model="reference.logic[item.index].indexKey" :placeholder="'Not set'" />
        </DesignerGroup>

        <DesignerGroup c4>
            <DesignerLabel :text="'Key'" />
            <DesignerInput v-model="reference.logic[item.index].key" :placeholder="'Not set'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Name'" />
            <DesignerInput v-model="reference.logic[item.index].name" :placeholder="'Not set'" />
        </DesignerGroup>

        <DesignerButtonGroup alignRight>

            <DesignerButton small
                :icon="'edit'"
                :tooltip="'Edit'"
                @clicked="$emit('logic', { mode: 'manage', selectedIndex: i })"
            />

        </DesignerButtonGroup>

    </PropertyItem>

    <div v-if="items.length == 0" style="padding: 16px 20px; color: var(--fb-text-3);">
        {{ 'No logic items' }}
    </div>

</div>
</template>
<script>
import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';
import DesignerButtonGroup from '../ui/DesignerButtonGroup.vue';

import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';

export default {
    name: 'LogicPropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
        DesignerButtonGroup,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() { 
        return {
            items: [],
        }
    },
    methods: {

        loadLogic() {
            this.items = [];
            for (const i in this.reference.logic) {
                this.items.push({
                    index: i,
                    type: 'logic',
                    icon: 'logic',
                    name: this.reference.logic[i].name
                });
            }
        },

    },
    created() {
        this.loadLogic();
    }
}
</script>