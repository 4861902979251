<template>
    <div class="pageh" :class="{'pageh-scrolled': scrolled }">
        
        <div class="pageh-content" v-if="!scrolled">
            <slot name="content"></slot>
        </div>

        <div class="pageh-compact" v-if="scrolled">
            <div class="pageh-compact-c">
                <slot name="compact"></slot>
            </div>
            <div class="pageh-compact-actions">
                <div class="pageh-compact-a" v-for="(action, i) in actions" :key="'pha' + i" @click="onAction(action)">
                    <vap-icon tiny :icon="action.icon" absolute :top="3" :right="6" :color="'var(--header-text)'" />
                </div>
            </div>
        </div>

        <div class="pageh-tabs" :class="{'pageh-tabs-scrolled': scrolled }">
            <slot name="tabs"></slot>
        </div>

        <div style="position: absolute; right: 1px;" v-if="!scrolled">
            <CardActions :actions="actions" sharpCorner @action="onAction" />
        </div>

    </div>
</template>
<script>
import CardActions from '../v1ui/CardActions.vue';

export default {
    name: 'PageHeader',
    components: {
        CardActions
    },
    props: {
        offset: { type: Number, default: 0 },
        actions: { type: Array, default: null },
        compactable: { type: Boolean, default: false },
    },
    data() {
        return {
            scrolled: false,
            scale: 1,
        }
    },
    methods: {
        
        onAction(action) {
            this.$emit('action', action);
        },

        handleScroll(event) {
            if (!this.compactable) return;
            let doc = document.documentElement;
            let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
            top = top * this.scale;
            if (top > this.offset) {
                if (!this.scrolled) {
                    this.scrolled = true;
                    this.$emit('scrolled', true);
                }
            }else {
                if (this.scrolled) {
                    this.scrolled = false;
                    this.$emit('scrolled', false);
                }
            }
        },

        getScale() {
            var mediaQuery;
            var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if (window.devicePixelRatio !== undefined && !is_firefox) {
                return window.devicePixelRatio;
            } else if (window.matchMedia) {
                mediaQuery = "(-webkit-min-device-pixel-ratio: 1.5),\
                (min--moz-device-pixel-ratio: 1.5),\
                (-o-min-device-pixel-ratio: 3/2),\
                (min-resolution: 1.5dppx)";
                if (window.matchMedia(mediaQuery).matches) {
                    return 1.5;
                }
                mediaQuery = "(-webkit-min-device-pixel-ratio: 2),\
                (min--moz-device-pixel-ratio: 2),\
                (-o-min-device-pixel-ratio: 2/1),\
                (min-resolution: 2dppx)";
                if (window.matchMedia(mediaQuery).matches) {
                    return 2;
                }
                mediaQuery = "(-webkit-min-device-pixel-ratio: 0.75),\
                (min--moz-device-pixel-ratio: 0.75),\
                (-o-min-device-pixel-ratio: 3/4),\
                (min-resolution: 0.75dppx)";
                if (window.matchMedia(mediaQuery).matches) {
                    return 0.7;
                }
            } else {
                return 1;
            }
        }

    },
    mounted() {
        this.scale = this.getScale();
        //console.log('scale', this.scale);
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>
<style scoped>

.pageh {
    position: relative;
    float: left;
    width: 100%;
    border-bottom: solid 1px var(--input-border);
    box-sizing: border-box;
    z-index: 10;
}

.pageh-scrolled {
    position: fixed;
    top: 0px;
}

.pageh-content {
    float: left;
    width: 100%;
    height: 57px;
    padding-top: 10px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 10px;
    background-color: var(--stepper-bg);
    box-sizing: border-box;
}

.pageh-compact {
    display: flex;
    height: 28px;
    color: var(--header-text);
    background-color: var(--header);
}

.pageh-compact-c {
    flex-grow: 100;
    padding-top: 4px;
    padding-left: 10px;
}
.pageh-compact-c span {
    margin-left: 10px;
}

.pageh-compact-actions {
    flex-shrink: 0;
    display: flex;
    flex-direction: row-reverse;
}
.pageh-compact-a {
    position: relative;
    width: 34px;
    height: 28px;
    border-left: solid 1px var(--header-sep);
    cursor: pointer;
}
.pageh-compact-a:hover {
    background-color: var(--header-hover);
}


.pageh-actions {
    position: absolute;
    top: 0px; right: 0px;
}

.pageh-tabs {
    float: left;
    width: 100%;
    border-top: solid 1px var(--input-border);
}
.pageh-tabs-scrolled {
    border-top: solid 1px var(--header-sep);
}

</style>