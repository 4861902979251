<template>
    <div class="vesc-base card-shadow">
        
        <div class="vesc-top" :class="expanded ? 'vesc-expanded' : ''" @click="handleClick">
            <slot name="top"></slot>
        </div>

        <div class="vesc-content" v-if="expanded">
            <slot name="content"></slot>
        </div>

    </div>
</template>

<script>

export default {
    name: 'vap-expandcard',
    props: {
        expanded: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            
        }
    },
    computed: {
        
    },
    watch: {
        
    },
    methods: {
        handleClick() {
            this.$emit('expand-click', !this.expanded);
        }
    },
    async mounted() {

        

    }
}
</script>
<style scoped>

.vesc-base {
    float: left;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 10px;
    background-color: var(--card-background);
}

.vesc-top {
    display: flex;
    position: relative;
    width: 100%;
    min-height: 36px;
    height: auto;
    cursor: pointer;
}
.vesc-top:hover {
    min-height: 36px;
    background-color: var(--item-hover);
}

.vesc-expanded {
    border-bottom: solid 1px var(--input-border);
    transition: border-color 0s ease;  
}

.vesc-content {
    min-height: 10px;
}


</style>