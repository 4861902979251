import klpFlow from './defaults/klpFlow';
import boreaFlow from './defaults/boreaFlow';
import noOrgBaml from './defaults/noOrgBaml';
import seOrgBistrap from './defaults/seOrgBistrap';
import ukOrgChouse from './defaults/ukOrgChouse';
import xxPerson from './defaults/xxPerson';
import yyPerson from './defaults/yyPerson';
import zzPerson from './defaults/zzPerson';
import xxOrg from './defaults/xxOrg';
import xxOrgCreation from './defaults/xxOrgCreation';
import globalSteps from './defaults/globalSteps';
const defaultFlows = {

    getFlows() {
        return [
            'no-org-baml',
            'se-org-bistrap',
            'uk-org-chouse',
            /* 'xx-person', */
            'yy-person',
            'zz-person',
            'no-org-baml-klp',
            /* 'no-org-baml-borea', */
            'xx-org',
            'xx-org-creation',
        ];
    },

    getFlow(key) {
        switch (key) {
            case 'no-org-baml': return noOrgBaml.getFlow();
            case 'se-org-bistrap': return seOrgBistrap.getFlow();
            case 'uk-org-chouse': return ukOrgChouse.getFlow();
            /* case 'xx-person': return xxPerson.getFlow(); */
            case 'yy-person': return yyPerson.getFlow();
            case 'zz-person': return zzPerson.getFlow();
            case 'no-org-baml-klp': return klpFlow.getFlow();
            /* case 'no-org-baml-borea': return boreaFlow.getFlow(); */
            case 'xx-org': return xxOrg.getFlow();
            case 'xx-org-creation': return xxOrgCreation.getFlow();
        }
    },

    //#region Default steps -----------------------------------------------------------------------------------------------

    getStep(step) {
        switch (step) {

            case 'no-baml-org': return noOrgBaml.getSearchStep();
            case 'no-baml-kyc': return noOrgBaml.getKycStep();
            case 'no-baml-per': return noOrgBaml.getPersonStep();
            case 'no-baml-rep': return noOrgBaml.getReportStep();

            case 'se-bistrap-org': return seOrgBistrap.getSearchStep();
            case 'se-bistrap-kyc': return seOrgBistrap.getKycStep();
            case 'se-bistrap-per': return seOrgBistrap.getPersonStep();
            case 'se-bistrap-rep': return seOrgBistrap.getReportStep();

            case 'uk-chouse-org': return ukOrgChouse.getSearchStep();
            case 'hb-chk-risk': return ukOrgChouse.getRiskStep();
            case 'hb-chk-list-branch': return ukOrgChouse.getChecklistStepBranch();
            case 'hb-chk-qa-branch': return ukOrgChouse.getQAStepBranch();
            case 'hb-chk-list-cdd': return ukOrgChouse.getChecklistStepCDD();
            case 'hb-chk-qa-cdd': return ukOrgChouse.getQAStepCDD();

            case 'xx-person-idc': return xxPerson.getIdcStep();
            case 'xx-person-scr': return xxPerson.getScreeningStep();
            case 'xx-person-rep': return xxPerson.getReportStep();

            case 'yy-person-kyc': return yyPerson.getKycStep();
            
            case 'zz-person-idc': return zzPerson.getIdcStep();
            case 'zz-person-scr': return zzPerson.getScreeningStep();
            case 'zz-person-rep': return zzPerson.getReportStep();

            case 'no-klp-kyc': return klpFlow.getKlpKyc();
            case 'no-klp-kar': return klpFlow.getKlpKar();
            case 'no-klp-pension': return klpFlow.getKlpPension();
            case 'no-klp-connection': return klpFlow.getKlpConnection();

            /* case 'borea-kyc': return boreaFlow.getKycStep();
            case 'borea-suit': return boreaFlow.getSuitStep();
            case 'borea-kyc-person': return xxPerson.getKycStep();
            case 'borea-suit-person': return xxPerson.getSuitStep(); */

            case 'xx-org-kyc': return xxOrg.getKycStep();
            case 'xx-org-per': return xxOrg.getPersonStep();
            case 'xx-org-rep': return xxOrg.getReportStep();
            case 'xx-org-upgrade': return xxOrgCreation.getSearchStep();

            case 'no-kar': return noOrgBaml.getKarStep();
            case 'no-book': return noOrgBaml.getBookStep();
            case 'xx-org-ams': return noOrgBaml.getAmsStep();

            case 'xx-upload-kyc': return noOrgBaml.getKycUploadStep();

            case 'xx-approve': return globalSteps.getApprovalStep();

        }
    },

    getSteps() {
        return [
            'no-baml-org',
            'no-baml-kyc',
            'no-baml-per',
            'no-baml-rep',
            'no-kar',
            'xx-org-ams',
            'no-book',
            'xx-upload-kyc',
            'xx-approve',
        ];
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default defaultFlows;