import v1api from '../v1helpers/v1api';
import dataModelV1 from '../v1data/dataModelV1';
import dataNorwayV1 from '../v1data/dataNorwayV1';
import dataSwedenV1 from '../v1data/dataSwedenV1';
import log from '../log';
import tools from '../tools';
import aml from '../aml';
import v2lib from '../v2lib';
import verified from '../verified';
import axios from 'axios';
import v1backoffice from '../v1admin/v1backoffice';
import v1exceptions from '../v1helpers/v1exceptions';
const screeningHelper = {

    useTestResponse: false,

    //#region Loading -----------------------------------------------------------------------------------------------------

    async performScreening(root, client, person, useSSN, progress) {

        if (root.config.screening.nordicProvider == 'baml') {

            await this.performScreeningBankIDAML(root, client, person, useSSN, progress);

        }else {

            await this.performScreeningTrapets(root, client, person, useSSN, progress);

        }
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------

    async performScreeningBankIDAML(root, client, person, useSSN, progress) {
        progress.working = true;
        progress.status = root.__t('screening-prog-screening') + ' BankID AML';

        try {

            /* await new Promise(r => setTimeout(r, 2000));
            const test = test.test.test; */

            const searchParams = {
                firstName: person.info.firstName,
                lastName: person.info.lastName,
                dateOfBirth: person.info.dateOfBirth,
                ssn: person.info.ssn,
                countryOfSsn: '',
                mode: 'exact',
                parameters: []
            };

            let result = null;
            const reports = [];

            // First create an envelope to make sure Verified Platform is up and running
            const envelope = await v2lib.createEnvelope('aml-portal-v1-person', 'no-recipients-flow');

            if (!v2lib.wasCreated(envelope)) {
                // Error creating envelope, show error to the user
                return;
            }

            // Perform PEP/Sanction search with BankID AML
            if (useSSN && person.info.ssn) {
                log.w('AML: Searching with SSN');

                result = await aml.doAmlPersonSearchSSN(root, person.info.firstName, person.info.lastName, person.info.ssn);
                searchParams.parameters.push('firstName');
                searchParams.parameters.push('lastName');
                searchParams.parameters.push('ssn');
                searchParams.parameters.push('countryOfSsn');
                searchParams.countryOfSsn = 'NO';

            }else {
                log.v('AML: Searching with date of birth', person.info.dateOfBirth);

                const dateOfBirth = tools.fixDateOfBirth(person.info.dateOfBirth);
                result = await aml.doAmlPersonSearch(root, person.info.firstName, person.info.lastName, dateOfBirth);
                searchParams.parameters.push('firstName');
                searchParams.parameters.push('lastName');
                searchParams.parameters.push('dateOfBirth');

            }
            log.s('AML: Person data fetched from BankID', result);

            const personData = dataModelV1.getEmptyPerson();
            dataNorwayV1.processPerson(root, personData, result, searchParams);

            if (result.personData.links && result.personData.links.reports && result.personData.links.reports.length > 0) {

                for (const i in result.personData.links.reports) {
                    reports.push({
                        url: result.personData.links.reports[i].href,
                        contentType: result.personData.links.reports[i].contentType,
                        type: dataNorwayV1.getReportType(result.personData.links.reports[i].rel, 'person'),
                        name: dataNorwayV1.getReportName(result.personData.links.reports[i].rel, 'person', searchParams.firstName + ' ' + searchParams.lastName)
                    });
                }

            }

            personData.envelopeId = envelope.id;
            await v1api.performScreening(person.personId, personData);


            progress.status = root.__t('report-prog-receipt');
            const attachments = [];


            for (const i in reports) {

                const attachment = {
                    type: reports[i].type,
                    name: reports[i].name,
                    fileType: reports[i].contentType
                };
                attachments.push(attachment);

            }

            const metadata = {
                userId: root.credentials.uid,
                userName: root.getEmployeeName(root.credentials.uid),
                date: new Date()
            };

            // Get PDF configuration
            const pdfConfig = {
                logo: root.config.pdf.logoUrl,
                colors: {
                    headerBack: root.getColor('header'),
                    headerText: root.getColor('header-text')
                },
                language: root.locale.key || 'en_EN',
                translations: this.getPdfTranslations(root, root.locale.key || 'en_EN')
            };

            // Prepare envelope userdata, name and tags
            const envelopeName = root.__t('screening-name') + ` ${person.info.firstName} ${person.info.lastName} (${person.info.dateOfBirth})`;
            const envelopeTags = [root.v1translations['screening-tag'][root.config.params.defaultLanguage]];
            const envelopeUserdata = {
                metadata: metadata,
                pdfConfig: pdfConfig,
                personData: personData,
                attachments: attachments,
                fileName: envelopeName,
                person: {
                    firstName: searchParams.firstName,
                    lastName: searchParams.lastName,
                    dateOfBirth: searchParams.dateOfBirth
                }
            };

            // Complete the envelope
            await v2lib.setUserData(envelope, envelopeUserdata);
            await v2lib.setTags(envelope, envelopeTags);
            await v2lib.setName(envelope, envelopeName);
            await v2lib.publishEnvelope(envelope);
            log.s('AML: Envelope published');

            // Log usage data
            const statData = {
                type: 'scr',
                cc: root.config.params.baseCountry,
                src: personData.source,
                eid: envelope.id,
                cid: root.credentials.cid,
                ckey: root.credentials.key,
                cname: root.user.companyName,
                stat: root.config.params.accountStatus
            };
            v1backoffice.addStat(statData);

            // Download reports in background
            this.downloadReportBackground(root, envelope, reports);

        }catch (ex) {
            progress.error = ex.message;
            const exceptionData = {

            };
            v1exceptions.addException(root, 'ScreeningHelper', 'performScreeningBankIDAML', ex, exceptionData);
        }
    },

    async downloadReportBackground(root, envelope, reports) {
        if (reports.length > 0) {
            log.v('AML: Downloading BankID reports', reports);

           const messageKey = await root.addMessage({
                text: 'Laster ned rapport fra BankID i bakgrunnen',
                icon: 'cancel',
                duration: -1
            });

            for (const i in reports) {
                log.v('AML: Downloading', reports[i]);
    
                const base64 = await verified.downloadBankIDReport(reports[i].url);
                if (base64) {
    
                    const attachment = {
                        type: reports[i].type,
                        name: reports[i].name,
                        fileType: reports[i].contentType,
                        size: base64.length
                    };
                    await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, attachment.name);
    
                    log.s('AML: BankID report attached', reports[i]);
                }else {
                    log.e('AML: Error downloading BankID report');
                }
            }
    
            root.updateMessage(messageKey, {
                text: 'Rapporter ble lastet ned',
                duration: 5000
            });

        }else {
            log.v('AML: No reports to download');
        }
    },

    async performScreeningTrapets(root, client, person, useSSN, progress) {
        console.log(JSON.parse(JSON.stringify(progress)))
        progress.working = true;
        progress.status = root.__t('screening-prog-screening') + ' Trapets/Bisnode';

        // First create an envelope to make sure Verified Platform is up and running
        const envelope = await v2lib.createEnvelope('aml-portal-v1-person', 'no-recipients-flow');

        if (!v2lib.wasCreated(envelope)) {
            // Error creating envelope, show error to the user
            return;
        }

        const searchParams = {
            firstName: person.info.firstName,
            lastName: person.info.lastName,
            dateOfBirth: person.info.dateOfBirth,
            ssn: person.info.ssn,
            countryOfSsn: '',
            mode: 'exact',
            parameters: []
        };

        let trapetsResult = null;
        let bisnodeResult = null;

        if (person.info.ssn) {
            log.w('AML: Searching with SSN');

            trapetsResult = await this.getTrapetsDataSSN(person.info.ssn);

            bisnodeResult = await this.getBisnodeDataSSN(person.info.ssn);

            searchParams.parameters.push('firstName');
            searchParams.parameters.push('lastName');
            searchParams.parameters.push('ssn');
            searchParams.parameters.push('countryOfSsn');
            searchParams.countryOfSsn = 'NO';

        }else {
            log.v('AML: Searching with date of birth', person.info.dateOfBirth);

            trapetsResult = await this.getTrapetsDataName(person.info.firstName, person.info.lastName, person.info.dateOfBirth);

            searchParams.parameters.push('firstName');
            searchParams.parameters.push('lastName');
            searchParams.parameters.push('dateOfBirth');

        }
        log.s('AML: Person data fetched from Trapets', trapetsResult);
        log.s('AML: Person data fetched from Bisnode', bisnodeResult);

        const personData = dataModelV1.getEmptyPerson();
        dataSwedenV1.processPerson(root, personData, trapetsResult, bisnodeResult, searchParams);

        personData.envelopeId = envelope.id;
        await v1api.performScreening(person.personId, personData);


        progress.status = root.__t('report-prog-receipt');
        const attachments = [];


        const metadata = {
            userId: root.credentials.uid,
            userName: root.getEmployeeName(root.credentials.uid),
            date: new Date()
        };

        // Get PDF configuration
        const pdfConfig = {
            logo: root.config.pdf.logoUrl,
            colors: {
                headerBack: root.getColor('header'),
                headerText: root.getColor('header-text')
            },
            language: root.locale.key || 'en_EN',
            translations: this.getPdfTranslations(root, root.locale.key || 'en_EN')
        };

        // Prepare envelope userdata, name and tags
        const envelopeName = root.__t('screening-name') + ` ${person.info.firstName} ${person.info.lastName} (${person.info.dateOfBirth})`;
        const envelopeTags = [root.v1translations['screening-tag'][root.config.params.defaultLanguage]];
        const envelopeUserdata = {
            metadata: metadata,
            pdfConfig: pdfConfig,
            personData: personData,
            attachments: attachments,
            fileName: envelopeName,
            person: {
                firstName: searchParams.firstName,
                lastName: searchParams.lastName,
                dateOfBirth: searchParams.dateOfBirth
            }
        };
        console.log('userdata', envelopeUserdata);

        // Complete the envelope
        await v2lib.setUserData(envelope, envelopeUserdata);
        await v2lib.setTags(envelope, envelopeTags);
        await v2lib.setName(envelope, envelopeName);
        await v2lib.publishEnvelope(envelope);
        log.s('AML: Envelope published');

        // Log usage data
        const statData = {
            type: 'scr',
            cc: root.config.params.baseCountry,
            src: personData.source,
            eid: envelope.id,
            cid: root.credentials.cid,
            ckey: root.credentials.key,
            cname: root.user.companyName,
            stat: root.config.params.accountStatus
        };
        v1backoffice.addStat(statData);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    async getTrapetsDataSSN(ssn) {
        const result = {
            success: false,
            testdata: false,
            data: {}
        };

        if (this.useTestResponse) {
            try {
                const response = await axios.get(`test_data/trapets/${ssn}.json`);
                if (response.status == 200) {
                    await new Promise(r => setTimeout(r, 500));
                    result.data = response.data;
                    result.testdata = true;
                    result.success = true;
                    return result;
                }
            }catch (e) {
                console.log('TEST DATA UNAVAILABLE');
            }
        }

        const headers = {};
        headers["authorization"] = "JWT " + v2lib.personalToken;
        headers["x-namespace"] = v2lib.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        const companyId = v2lib.namespace.split('/')[2];
        try {
            const response = await axios({
                url: `/api/trapets/companies/${companyId}/query`,
                method: 'POST',
                headers: headers,
                data: {
                    "SubQueries": [
                        {
                            "Id": ssn,
                            /* "Countries": ["SWE"], */
                            "ItemNumber": ssn
                        }
                    ]
                }
            });
            console.log(JSON.stringify(response.data))
            result.data = response.data;
            result.success = true;
        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;
    },

    async getTrapetsDataName(firstName, lastName, dateOfBirth) {
        const result = {
            success: false,
            testdata: false,
            data: {}
        };

        if (this.useTestResponse) {
            try {
                const response = await axios.get(`test_data/trapets/${firstName.toLowerCase() + lastName.toLowerCase()}.json`);
                if (response.status == 200) {
                    await new Promise(r => setTimeout(r, 500));
                    result.data = response.data;
                    result.testdata = true;
                    result.success = true;
                    return result;
                }
            }catch (e) {
                console.log('TEST DATA UNAVAILABLE');
            }
        }

        const headers = {};
        headers["authorization"] = "JWT " + v2lib.personalToken;
        headers["x-namespace"] = v2lib.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        const companyId = v2lib.namespace.split('/')[2];
        try {
            const response = await axios({
                url: `/api/trapets/companies/${companyId}/query`,
                method: 'POST',
                headers: headers,
                data: {
                    "SubQueries": [
                        {
                            "Id": '123456789',
                            /* "Countries": ["SWE"], */
                            "Name": firstName + ' ' + lastName
                        }
                    ]
                }
            });
            console.log(JSON.stringify(response.data))
            result.data = response.data;
            result.success = true;
        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;
    },

    async getBisnodeDataSSN(ssn) {
        const result = {
            success: false,
            testdata: false,
            data: {}
        };

        if (this.useTestResponse) {
            try {
                const response = await axios.get(`test_data/bisnode/${ssn}.json`);
                if (response.status == 200) {
                    await new Promise(r => setTimeout(r, 500));
                    result.data = response.data;
                    result.testdata = true;
                    result.success = true;
                    return result;
                }
            }catch (e) {
                console.log('TEST DATA UNAVAILABLE');
            }
        }

        const headers = {};
        headers["authorization"] = "JWT " + v2lib.personalToken;
        headers["x-namespace"] = v2lib.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            const response = await axios({
                url: `/api/bisnode/person?personNumber=${ssn}`,
                method: 'GET',
                headers: headers
            });
            console.log(JSON.stringify(response.data))
            result.data = response.data;
            result.success = true;
        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;
    },

    getPdfTranslations(root, language) {
        const keys = [
            'pep_pdf_user',
            'pep_pdf_title',
            'pep_pdf_search_params',
            'pep_pdf_provider',
            'pep_pdf_pep_header',
            'pep_pdf_pep_empty',
            'pep_pdf_sanction_header',
            'pep_pdf_sanction_empty',
            'pep_pdf_lists',
            'pep_pdf_company_roles',

            'common_date',
            'common_name',
            'common_first_name',
            'common_last_name',
            'common_dob',
            'common_address',
            'common_zip',
            'common_city',
            'common_country',
            'common_unknown',
            'common_ssn',
            'common_ssn_country',
            'common_title',

            'common_result',

            'proprietor',
            'ceo',
            'chair',
            'screening_role_procura',
            'screening_role_deputy_chairman',
            'screening_role_sign_right',
            'screening_role_rrh',
            'screening_role_board_member',
            'screening_role_deputy_board_member',
            'screening_role_shareholder',
            'screening_role_accountant',
            'scr_secretary',
            'scr_director',
            'screening_role_other',
        ];

        const translations = {};
        for (const i in keys) {
            if (root.translations[keys[i]]) {
                if (language) {
                    translations[keys[i]] = {
                        [language]: root.translations[keys[i]][language]
                    };
                }else {
                    translations[keys[i]] = root.translations[keys[i]];
                }
            }
        }
        return translations;
    },

};
export default screeningHelper;