<template>
<div class="vfc">
    
    <div class="vfc-header" @click="onHeaderClick">
        <vap-icon tiny :icon="expanded ? 'chev-d' : 'chev-u'" :color="'lightgray'" />
        <div class="vfc-header-text">
            {{ params.title[$root.locale.key] }}
        </div>
    </div>

    <div class="vfc-filters" v-if="!empty && expanded">
        <div class="vfc-con" v-for="(filter, i) in filters" :key="'f' + i"
        @click="onFilterClick(i)"
        :class="{'vfc-selected': isSelected(i)}" v-show="!filter.hide">

            <div class="vfc-filter">
                <div class="vfc-filter-text">
                    <label>{{ displayText(filter) }}</label>
                </div>
                <div class="vfc-filter-number">
                    <label>{{ filter.count }}</label>
                </div>
            </div>

        </div>
    </div>

</div>
</template>
<script>


export default {
    name: 'FilterControlV1',
    props: {
        params: { type: Object, default: null },
        filters: { type: Array, default: null },
        texts: { type: Object, default: null },
        empty: { type: Boolean, default: false },
        activeValues: { type: Array, default: null },
    },
    data() {
        return {
            expanded: true,
            activeFilters: [],
        }
    },
    methods: {
        
        isSelected(index) {
            for (const i in this.activeValues) {
                if (this.activeValues[i] == this.filters[index]._id) return true;
            }
            return false;
        },

        displayText(filter) {
            if (!filter._id && this.params.emptyText) {
                return this.params.emptyText[this.$root.locale.key];
            }
            if (this.texts && this.texts[filter._id] && this.texts[filter._id][this.$root.locale.key]) {
                return this.texts[filter._id][this.$root.locale.key];
            }else if (this.texts && this.texts[filter._id]) {
                return this.texts[filter._id].en_EN;
            }else {
                return filter._id
            }
        },

        onHeaderClick() {
            this.expanded = !this.expanded;
        },

        onFilterClick(index) {
            this.$emit('filter', this.params, this.filters[index]._id);
        },

    },
    async created() {
        //console.log('params', this.params);
    }
}
</script>
<style scoped>

.vfc {
    float: left;
    width: 100%;
    margin-top: 20px;
}

.vfc-header {
    display: flex;
    float: left;
    width: 100%;
    color: var(--text-strong);
    padding-bottom: 4px;
    border-bottom: solid 1px var(--text-disabled);
    cursor: pointer;
}
.vfc-header-icon path {
    fill: var(--text-weak);
}
.vfc-header-icon svg {
    transform: scale(0.7);
}
.vfc-header-text {
    margin-top: 2px;
    margin-left: 3px;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-title);
}

</style>