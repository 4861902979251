const log = {

    editor: false,
    local: false,
    list: [],
    suppress: false,

    v(message, object) {
        this.log('v', message, object);
    },
    s(message, object) {
        this.log('s', message, object);
    },
    w(message, object) {
        this.log('w', message, object);
    },
    e(message, object) {
        this.log('e', message, object);
    },
    r(message, object) {
        this.log('r', message, object);
    },
    x(message, object) {
        this.log('x', message, object);
    },

    log(type, message, object) {
        if (this.suppress) return;
        this.addToList(type, message, object);
        if (this.editor && !this.local) return;
        if (type.length > 1) {
            if (message) {
                console.log(type, message);
            }else {
                console.log(type);
            }
            return;
        }
        if (object) {
            switch (type) {
                case 'v': console.log('%c' + message, 'color: #24A4F2', object); break;
                case 's': console.log('%c' + message, 'color: #8DEE57', object); break;
                case 'w': console.log('%c' + message, 'color: #EED757', object); break;
                case 'e': console.log('%c' + message, 'color: #E36B6B', object); break;
                case 'r': console.log('%c' + message, 'color: #7E42FF', object); break;
                case 'x': console.log('%c' + message, 'color: #A8A8A8', object); break;
                default: console.log(message, object);
            }
        }else {
            switch (type) {
                case 'v': console.log('%c' + message, 'color: #24A4F2'); break;
                case 's': console.log('%c' + message, 'color: #8DEE57'); break;
                case 'w': console.log('%c' + message, 'color: #EED757'); break;
                case 'e': console.log('%c' + message, 'color: #E36B6B'); break;
                case 'r': console.log('%c' + message, 'color: #7E42FF'); break;
                case 'x': console.log('%c' + message, 'color: #A8A8A8'); break;
                default: console.log(message);
            }
        }
    },

    addToList(type, message, object) {
        try {
            this.list.push({ type: type, message: message, data: JSON.stringify(object) || JSON.stringify({}) });
            if (this.list.length > 20) this.list.shift();
        }catch (ex) {

        }
    },

};
export default log;