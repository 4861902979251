<template>
<div class="slim-page">

    <vap-form-divider :left="55" :right="45" stickyRight v-if="!loading">
        <template v-slot:left>
            <div>
                <div class="amlv1-litm" v-for="(monitor, i) in monitors" :key="'m' + i" @click="onMonitorClicked(monitor)">
                    <div class="amlv1-litm-icon">
                        <vap-icon :icon="'person'" />
                    </div>
                    <div class="amlv1-litm-content">
                        <div class="amlv1-litm-name">
                            {{ monitor.name + ' (' + monitor.template + ')' }}
                        </div>
                        <div class="amlv1-litm-info">
                            {{ 'Number of persons: ' + monitor.persons }}
                        </div>
                    </div>
                    <div class="amlv1-litm-delete">
                        <vap-icon :icon="'delete'" action @clicked="showDeleteDialog(monitor)" />
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:right>
            <ActionV1 :icon="'add'" :text="'Add monitor'" :hint="'Add a new monitor'" @clicked="showAddDialog" />
        </template>
    </vap-form-divider>

    <PopupV1 v-if="addDialog.show" :width="700" :header="'Add monitor'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <VFRow>
                <VFGroup c12>
                    <VFLabel desc :text="'Name'" />
                    <VFInput v-model="addDialog.name" />
                </VFGroup>
                <VFGroup c6>
                    <VFLabel desc :text="'Template'" />
                    <VFDropdown v-model="addDialog.template" :options="templates" disabled />
                </VFGroup>
                <VFGroup c6>
                    <VFLabel desc :text="'Secret'" />
                    <VFInput v-model="addDialog.secret" />
                </VFGroup>
                <VFGroup c12>
                    <VFLabel desc :text="'Webhook'" />
                    <VFInput v-model="addDialog.webhook" />
                </VFGroup>
            </VFRow>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="addDialog.show = false" :disabled="addDialog.working" />
            <VFButton :text="'Add'" @clicked="onAdd" :disabled="!addDialog.valid" :working="addDialog.working" />
        </template>
    </PopupV1>

    <PopupV1 v-if="deleteDialog.show" :header="'Delete monitor'" @close="deleteDialog.show = false" :dialog="deleteDialog">
        <template v-slot:content>
            <div>
                {{ 'Are you sure you want to delete this monitor?' }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="deleteDialog.show = false" :disabled="deleteDialog.working" />
            <VFButton :text="'Delete'" warning @clicked="onDelete" :working="deleteDialog.working" />
        </template>
    </PopupV1>
    
</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import axios from 'axios';
import v2lib from '../v2lib';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFInput from '../components/form/inputs/VFInput.vue';
import VFDropdown from '../components/form/inputs/VFDropdown.vue';

import ActionV1 from '../v1ui/ActionV1.vue';

export default {
    name: 'MonitorSettings',
    components: {
        PopupV1,
        VFButton,
        VFRow,
        VFGroup,
        VFLabel,
        VFInput,
        VFDropdown,
        ActionV1,
    },
    props: {
        flow: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            addDialog: { show: false, valid: true, working: false, name: 'Default Person Monitor', template: 'bankid-eu-un', secret: '', webhook: '' },
            deleteDialog: { show: false, valid: true, working: false, id: '' },
            templates: [
                { value: 'bankid-eu-un', display: 'BankID Standard', hint: "Standard BankID screening using UN's sanction lists and Trapets' PEP Edge list" },
                { value: 'bankid-ofac', display: 'BankID OFAC', hint: "Sanction screening using the OFAC sanction list managed by USA" },
                { value: 'pliance-nordic', display: 'Plience Nordic', hint: "Standard Pliance PEP and Sanction screening with extra information using Bogard and Dow Jones" },
            ],
            monitors: [],
        }
    },
    methods: {

        onMonitorClicked(monitor) {
            this.$parent.$parent.$parent.navigate({ path: '/monitor', query: { id: monitor.id } });
        },
        
        showAddDialog() {
            this.addDialog.secret = this.getUniqueId();
            const base = this.getBaseUrl();
            this.addDialog.webhook = base + '/api/aml-v1/v1monitor?accountId=' + this.$root.credentials.key + '-' + this.$root.credentials.cid;
            this.addDialog.show = true;
        },

        getUniqueId() {
            return 'xxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },

        getBaseUrl() {
            let base = 'https://app.verified.eu';
            if (this.$root.isDEV) base = 'https://dev-verified.eu';
            return base;
        },

        async onAdd() {
            this.addDialog.working = true;

            const config = {
                headers: {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'x-namespace': v2lib.namespace,
                    'authorization': 'JWT ' + v2lib.personalToken,
                }
            };
            const body = {
                "name": this.addDialog.name,
                "template": this.addDialog.template,
                "webhook": this.addDialog.webhook,
                "secret": this.addDialog.secret,
            };
            const response = await axios.post(this.$root.baseUrl + '/api/monitors/person', JSON.stringify(body), config);
            console.log('response', response);

            const result = await v1api.addMonitor(response.data.id, this.addDialog.secret, this.addDialog.name, this.addDialog.template);
            console.log('result', result);

            await this.loadMonitors();

            this.addDialog.working = false;
            this.addDialog.show = false;
        },

        showDeleteDialog(monitor) {
            this.deleteDialog.id = monitor.id;
            this.deleteDialog.show = true;
        },

        async onDelete() {
            this.deleteDialog.working = true;

            const config = {
                headers: {
                    'accept': 'application/json',
                    'x-namespace': v2lib.namespace,
                    'authorization': 'JWT ' + v2lib.personalToken,
                }
            };
            let response = await axios.delete(this.$root.baseUrl + '/api/monitors/person/' + this.deleteDialog.id, config);
            console.log('response', response);

            const result = await v1api.deleteMonitor(this.deleteDialog.id);
            console.log('result', result);

            await this.loadMonitors();

            this.deleteDialog.working = false;
            this.deleteDialog.show = false;
        },

        async loadMonitors() {
            this.loading = true;

            const config = {
                headers: {
                    'accept': 'application/json',
                    'x-namespace': v2lib.namespace,
                    'authorization': 'JWT ' + v2lib.personalToken,
                }
            };
            let response = await axios.get(this.$root.baseUrl + '/api/monitors/person', config);
            console.log('response', response);

            this.monitors = response.data.monitors;

            for (const i in this.monitors) {
                response = await axios.get(`${this.$root.baseUrl}/api/monitors/person/${this.monitors[i].id}/stats`, config);
                console.log('response', response);
                this.monitors[i].persons = response.data.persons;
            }

            this.loading = false;
        },

    },
    created() {
        this.loadMonitors();
    }
}
</script>
<style scoped>

.amlv1-litm {
    float: left;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    padding: 10px 14px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
    cursor: pointer;
}
.amlv1-litm:hover {
    background-color: var(--item-hover);
}

.amlv1-litm-icon {
    width: 43px;
}

.amlv1-litm-content {
    flex-grow: 100;
}

.amlv1-litm-name {

}

.amlv1-litm-info {

}

.amlv1-litm-delete {

}

</style>