<template>
<div>
    <div class="frv2-prop-cat-head" @click="expanded = !expanded">
        <div>
            <vap-icon :icon="icon" small />
        </div>
        <div class="frv2-prop-cat-title">
            {{ header }}
        </div>
        <div class="frv2-prop-cat-actions">
            <div class="frv2-prop-cat-action" v-for="(action, i) in actions" :key="'pia' + i" @click.stop="$emit('action', action)">
                <vap-icon tiny :icon="action.icon" />
            </div>
        </div>
        <div class="frv2-prop-cat-exp">
            {{ expanded ? '-' : '+' }}
        </div>
    </div>
    <div class="frv2-prop-con" v-if="expanded" :class="{'frv2-prop-con-pad': padded }">
        <slot />
    </div>
</div>
</template>

<script>
export default {
    name: 'PropertyItem',
    props: {
        header: { type: String, default: 'Some properties' },
        icon: { type: String, default: 'help-o' },
        padded: { type: Boolean, default: false },
        initialCollapse: { type: Boolean, default: false },
        actions: { type: Array, default: () => { return [] } },
    },
    data() {
        return {
            expanded: true,
        }
    },
    created() {
        if (this.initialCollapse) this.expanded = false;
    }
}
</script>
<style scoped>

.frv2-prop-cat-head {
    display: flex;
    padding-left: 13px;
    border-bottom: solid 1px var(--fb-menu-c);
    background-color: var(--fb-menu-a);
    cursor: pointer;
}
.frv2-prop-cat-head:hover {
    background-color: var(--fb-menu-b);
}

.frv2-prop-cat-title {
    flex-grow: 100;
    font-size: 16px;
    padding-top: 4px;
    padding-left: 4px;
    color: var(--fb-text-2);
}

.frv2-prop-cat-actions {
    display: flex;
    border-right: 1px solid var(--fb-menu-b);
}

.frv2-prop-cat-action {
    padding-top: 4px;
    padding-left: 3px;
    width: 30px;
    height: 100%;
    border-left: 1px solid var(--fb-menu-b);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-prop-cat-action:hover {
    background-color: var(--fb-menu-a);
}

.frv2-prop-cat-exp {
    width: 40px;
    text-align: center;
    font-size: 24px;
    color: var(--fb-text-2);
}

.frv2-prop-con {
    float: left;
    width: 100%;
/*     padding: 20px 15px;
    padding-bottom: 0px; */
    box-sizing: border-box;
    /* border-bottom: solid 1px var(--input-border); */
    background-color: var(--fb-menu-b);
}

.frv2-prop-con-pad {
    padding: 15px 15px;
    padding-top: 10px;
}

</style>
