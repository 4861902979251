<template>
<div class="amlv1-mn">
    
    <div class="amlv1-mn-top">
        <div class="amlv1-mn-name">
            {{ notification.searchParams.givenName + ' ' + notification.searchParams.familyName }}
        </div>
        <div class="amlv1-mn-date">
            {{ formatDate('f', notification.created) }}
        </div>
    </div>

    <div class="amlv1-mn-type">
        <span class="amlv1-mn-type-d">{{ 'Type:' }}</span>
        <span class="amlv1-mn-type-v">{{ notification.hit.type }}</span>
    </div>

</div>
</template>
<script>

export default {
    name: 'NotificationItem',
    props: {
        notification: { type: Object, default: null },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        //#region Notifications -----------------------------------------------------------------------------------------------
        
        

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {
        
    }
}
</script>
<style scoped>

.amlv1-mn {
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 4px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
}

.amlv1-mn-top {
    display: flex;
}

.amlv1-mn-name {
    flex-grow: 100;
    font-size: 17px;
    font-weight: 500;
    color: var(--text-title);
}

.amlv1-mn-date {
    flex-shrink: 0;
    color: var(--text-weak);
}

.amlv1-mn-type {
    margin-top: 3px;
}

.amlv1-mn-type-d {
    color: var(--text-weak);
}

.amlv1-mn-type-v {
    margin-left: 6px;
    margin-right: 16px;
    font-weight: 500;
    color: var(--text-title);
}

</style>