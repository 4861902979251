<template>
    <div>
        <ve-popup :header="'Select icon'" :width="500" @close="$emit('close')">

            <div style="float: left; width: 100%; padding: 20px; box-sizing: border-box;">

                <div class="iconsel-item" v-for="(icon, i) in availableIcons" :key="'ai' + i">
                    <vap-icon :icon="icon" action @clicked="selectIcon(icon)">
                        <template v-slot:tooltip>
                            <vap-tooltip :text="icon" />
                        </template>
                    </vap-icon>
                </div>

            </div>

        </ve-popup>
    </div>
</template>

<script>
import uihelper from '../../../helpers/uihelper';

export default {
    name: 'FormIconSelector',
    props: {
        params: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            availableIcons: []
        }
    },
    methods: {
        
        selectIcon(icon) {
            this.params.reference.icon = icon;
            this.$emit('close');
        },

    },
    created() {
        this.availableIcons = uihelper.getAvailableIcons();
    }
}
</script>
<style scoped>

.iconsel-item {
    float: left;
    width: 12.5%;
    margin-bottom: 10px;
}

</style>