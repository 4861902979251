<template>
<div>
    
    <PopupV1 designer :maxHeight="500" :header="'Check for problems'" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>

            <div class="frv2-problems">
                <div class="frv2-problems-header">
                    {{ 'Flow' }}
                </div>
                <div class="frv2-problems-item" v-for="(problem, i) in result.flowProblems" :key="'fp' + i" :class="[
                    {'frv2-problems-item-7': problem.level == 7 },
                    {'frv2-problems-item-5': problem.level == 5 },
                    {'frv2-problems-item-3': problem.level == 3 },
                ]">
                    <div class="frv2-problems-item-bug">
                        <vap-icon small :icon="'bug'" :color="'ext'" />
                    </div>
                    <div class="frv2-problems-item-msg">
                        {{ problem.message }}
                    </div>
                    <div class="frv2-problems-item-link">
                        <vap-icon small :icon="'open'" />
                    </div>
                </div>
                <div class="frv2-problems-empty" v-if="result.flowProblems.length == 0">
                    {{ 'No problems identified' }}
                </div>
            </div>

            <div class="frv2-problems">
                <div class="frv2-problems-header">
                    {{ 'Logic' }}
                </div>
                <div class="frv2-problems-item" v-for="(problem, i) in result.logicProblems" :key="'fp' + i" :class="[
                    {'frv2-problems-item-7': problem.level == 7 },
                    {'frv2-problems-item-5': problem.level == 5 },
                    {'frv2-problems-item-3': problem.level == 3 },
                ]">
                    <div class="frv2-problems-item-bug">
                        <vap-icon small :icon="'bug'" :color="'ext'" />
                    </div>
                    <div class="frv2-problems-item-msg">
                        {{ problem.message }}
                    </div>
                    <div class="frv2-problems-item-link">
                        <vap-icon small :icon="'open'" />
                    </div>
                </div>
                <div class="frv2-problems-empty" v-if="result.logicProblems.length == 0">
                    {{ 'No problems identified' }}
                </div>
            </div>

            <div class="frv2-problems">
                <div class="frv2-problems-header">
                    {{ 'Text/Translations' }}
                </div>
                <div class="frv2-problems-item" v-for="(problem, i) in result.textProblems" :key="'fp' + i" :class="[
                    {'frv2-problems-item-7': problem.level == 7 },
                    {'frv2-problems-item-5': problem.level == 5 },
                    {'frv2-problems-item-3': problem.level == 3 },
                ]">
                    <div class="frv2-problems-item-flag">
                        <FlagIcon circle :locale="problem.locale" />
                    </div>
                    <div class="frv2-problems-item-msg">
                        {{ problem.message }}
                    </div>
                    <div class="frv2-problems-item-link">
                        <vap-icon small :icon="'open'" />
                    </div>
                </div>
                <div class="frv2-problems-empty" v-if="result.textProblems.length == 0">
                    {{ 'No problems identified' }}
                </div>
            </div>
            
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
            <!-- <VFButton :text="'Check for problems'" :icon="'search'" :working="dialog.working" @clicked="checkForProblems" /> -->
        </template>
    </PopupV1>

</div>
</template>

<script>
import testHelper from '../testHelper';

import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFButton from '../items/VFButton.vue';

import FlagIcon from '../../../v1ui/FlagIcon.vue';

export default {
    name: 'ProblemDialog',
    components: {
        PopupV1,
        VFButton,
        FlagIcon,
    },
    props: {
        reference: { type: Object, default: () => { return {} }}
    },
    data() { 
        return {
            dialog: { working: false },
            result: {
                flowProblems: [],
                logicProblems: [],
                textProblems: [],
            }
        }
    },
    methods: {

        async checkForProblems() {
            this.dialog.working = true;
            this.dialog.status = 'Checking for problems';

            /* await new Promise(r => setTimeout(r, 1000)); */

            this.result = testHelper.checkForProblems(this.$parent);
            console.log('result', this.result);

            this.dialog.working = false;
        },

    },
    created() {
        this.checkForProblems();
    }
}
</script>
<style scoped>

.frv2-problems {
    margin-top: 20px;
    padding-left: 10px;
    color: white;
}

.frv2-problems-header {
    margin-bottom: 5px;
    font-size: 17px;
    font-weight: 500;
    opacity: 0.9;
}

.frv2-problems-item {
    margin-top: -1px;
    display: flex;
    font-size: 15px;
    border: solid 1px var(--fb-menu-c);
    fill: gray;
}

.frv2-problems-item-bug {
    flex-shrink: 0;
    width: 30px;
    box-sizing: border-box;
}

.frv2-problems-item-flag {
    flex-shrink: 0;
    width: 30px;
    padding-top: 3px;
    padding-left: 5px;
    box-sizing: border-box;
}

.frv2-problems-item-msg {
    flex-grow: 100;
    padding-top: 5px;
    padding-left: 10px;
    border-left: solid 1px var(--fb-menu-c);
}

.frv2-problems-item-link {
    flex-shrink: 0;
    width: 30px;
    border-left: solid 1px var(--fb-menu-c);
    box-sizing: border-box;
}

.frv2-problems-item-7 {
    fill: #DA1919;
    color: #DA1919;
}
.frv2-problems-item-5 {
    fill: #DAA619;
    color: #DAA619;
}
.frv2-problems-item-3 {
    fill: #DAA619;
    color: #DAA619;
}

.frv2-problems-empty {
    font-size: 15px;
    opacity: 0.3;
}

</style>