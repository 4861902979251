<template>
<div class="vpc">
    <div class="vpc-c">

        <div class="vpc-prev" @click="onPrev" :class="{'vpc-disabled': current <= 1}">
            <label>&lt;</label>
        </div>

        <div class="vpc-page" v-for="(page, i) in pages" :key="'p' + i" :class="{'vpc-active': current == page.index + 1}" @click="onPage(page.index)">
            <label>{{ page.text }}</label>
        </div>

        <div class="vpc-next" @click="onNext" :class="{'vpc-disabled': current >= total}">
            <label>&gt;</label>
        </div>
            
    </div>
</div>
</template>
<script>

export default {
    name: 'vap-page-control',
    props: {
        total: {
            type: Number,
            default: 0
        },
        current: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            pages: []
        }
    },
    watch: {
        total: {
            deep: false,
            handler(val) {
                this.renderPages();
            }
        },
        current: {
            deep: false,
            handler(val) {
                this.renderPages();
            }
        }
    },
    methods: {
        renderPages() {
            this.pages = [];
            let count = 0;
            for (let i=0; i<this.total; i++) {

                if (count == 9) {
                    this.pages.push({
                        text: '...',
                        index: -1
                    });
                    this.pages.push({
                        text: this.total.toString(),
                        index: this.total - 1
                    });
                    break;
                }else {
                    if (i >= this.current - 5) {
                        count += 1;
                        this.pages.push({
                            text: (i+1).toString(),
                            index: i
                        });
                    }
                }

            }
        },
        onPrev() {
            if (this.current <= 1) return;
            this.$emit('page', this.current - 1);
        },
        onNext() {
            if (this.current >= this.total) return;
            this.$emit('page', this.current + 1);
        },
        onPage(index) {
            if (index == -1) return;
            this.$emit('page', index + 1);
        }
    },
    async created() {
        this.renderPages();
    }
}
</script>
<style>

.vpc {
    float: left;
    width: 100%;
}

.vpc-c {
    display: table;
    margin: 0 auto;
}

.vpc-page, .vpc-prev, .vpc-next {
    display: table-cell;
    min-height: 34px;
    text-align: center;
    color: var(--primary);
    border: solid 1px var(--text-disabled);
    border-right: none;
    background-color: var(--card-background);
    box-sizing: border-box;
    cursor: pointer;
}
.vpc-page:hover, .vpc-prev:hover, .vpc-next:hover {
    background-color: var(--item-hover);
}

.vpc-page {
    width: 46px;
    padding: 10px 0px;
}
.vpc-page label {
    cursor: pointer;
}
.vpc-active {
    color: var(--primary-contrast);
    background-color: var(--primary);
}

.vpc-prev {
    width: 46px;
    padding: 10px 0px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.vpc-next {
    width: 46px;
    padding: 10px 0px;
    border-right: solid 1px var(--text-disabled);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.vpc-next label, .vpc-prev label {
    cursor: pointer;
}

.vpc-disabled {
    color: var(--input-label);
    background-color: var(--item-hover);
    cursor: not-allowed;
    opacity: 0.5;
}
.vpc-disabled label {
    cursor: not-allowed;
}
.vpc-disabled:hover {
    background-color: var(--item-hover);
}

</style>
