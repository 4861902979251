<template>
    <div>
        <PropertyItem :header="'HTML properties'" :icon="'locale'">
            <vap-row>

                <vap-group c12>
                    <vap-label :text="'Edit HTML code'" desc />
                    <vap-input :value="'Edit HTML code'" noValidation actions disabled>
                        <template v-slot:actions>
                            <vap-icon action :icon="'open'" @clicked="editContent"><template v-slot:tooltip><vap-tooltip :text="__('fr_localized_text')" /></template></vap-icon>
                        </template>
                    </vap-input>
                </vap-group>

                <vap-group c12>
                    <vap-label :text="'Use preset'" desc />
                    <vap-dropdown v-model="input.kycKey" :options="presets" :displayKey="'title'" :listKey="'title'" :outputKey="'value'" noValidation />
                </vap-group>

            </vap-row>
        </PropertyItem>
    </div>
</template>

<script>
import FormMenuButton from '../FormMenuButton.vue';

import PropertyItem from './PropertyItem.vue';

export default {
    name: 'HTMLProperties',
    components: {
        FormMenuButton,
        PropertyItem
    },
    props: {
        input: { type: Object, default: null },
        reference: { type: Object, default: null },
    },
    data() {
        return {
            presets: [
                { value: '', title: "Don't use preset" },
                { value: 'htmlFinancialStatement', title: 'Financial Statement' },
            ],
        }
    },
    methods: {
        editContent() {
            this.reference.showTextEditor(this.input.desc)
        }
    }
}

</script>
<style scoped>

.frv2-opts-actions {
    display: flex;
    flex-direction: row-reverse;
    float: left;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
}

</style>
