const xxOrg = {

    getFlow() {
        return {
            key: 'xx-org',
            name: 'Organization Flow',
            type: 'org',
            countries: 'xx',
            conditions: 'forreign',
            isManual: true,
            tabs: {
                overview: { key: 'common_overview', icon: 'checklist' },
                persons: { key: 'common_persons', icon: 'people' },
                report: { key: 'company_final_report', icon: 'verified' }
            },
            title: {
                en_EN: 'Forreign company',
                nb_NO: 'Utenlandsk selskap',
                sv_SE: 'Utländskt företag',
            },
            steps: [
                { key: 'xx-org-kyc' },
                { key: 'xx-org-per' },
                { key: 'xx-org-rep' }
            ],
            statuses: [
                {
                    key: 'f-kyc-a',
                    text: {
                        en_EN: 'Awaiting sending self-declaration',
                        nb_NO: 'Avventer utsendelse egenerklæring',
                        sv_SE: 'Väntar på att kundkännedom skickas'
                    },
                    triggers: []
                },
                {
                    key: 'f-kyc-b',
                    text: {
                        en_EN: 'Awaiting filling self-declaration',
                        nb_NO: 'Avventer utfylling egenerklæring',
                        sv_SE: 'Väntar på att kundkännedom ska slutföras'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc02', trg: 'sent', rev: false },
                    ]
                },
                {
                    key: 'f-kyc-c',
                    text: {
                        en_EN: 'Awaiting signing self-declaration',
                        nb_NO: 'Avventer signering egenerklæring',
                        sv_SE: 'Väntar på undertecknande av kundkännedom'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc02', trg: 'filled', rev: false },
                    ]
                },
                {
                    key: 'r-rep-a',
                    text: {
                        en_EN: 'Awaiting controls',
                        nb_NO: 'Avventer kontroller',
                        sv_SE: 'Väntar på kontroller'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc02', trg: 'signed', rev: false },
                    ]
                },
                {
                    key: 'x-com-a',
                    text: {
                        en_EN: 'Completed',
                        nb_NO: 'Fullført',
                        sv_SE: 'Färdiga'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ]
                },
                {
                    key: 'z-ch-c',
                    completed: false,
                    text: {
                        en_EN: '! - Changed after completion',
                        nb_NO: '! - Endret etter ferdigstillelse',
                        sv_SE: '! - Ändrad efter färdigställande'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                        { type: 'report', trg: 'changed', rev: false },
                    ]
                }
            ],
            events: {
                items: [
                    {
                        key: 'event00',
                        text: {
                            en_EN: 'Customer created by {{createdUser}}',
                            nb_NO: 'Kunde opprettet av {{createdUser}}',
                            sv_SE: 'Kund skapad av {{createdUser}}'
                        },
                        date: { type: 'client', date: 'createdDate' },
                        triggers: [
                            { type: 'client', trg: 'created', rev: false },
                        ]
                    },
                    {
                        key: 'event01',
                        text: {
                            en_EN: 'Company search performed by {{createdUser}}',
                            nb_NO: 'Selskapssøk utført av {{createdUser}}',
                            sv_SE: 'Företagssökning utförd av {{createdUser}}'
                        },
                        date: { type: 'org', date: 'searchDate' },
                        triggers: [
                            { type: 'org', trg: 'has-data', rev: false },
                        ]
                    },
                    {
                        key: 'event02',
                        text: {
                            en_EN: 'Self declaration form sent by {{kyc02-senderName}}',
                            nb_NO: 'Egenerklæringsskjema sendt ut av {{kyc02-senderName}}',
                            sv_SE: 'Kundkännedom skickad av {{kyc02-senderName}}'
                        },
                        date: { type: 'form', key: 'kyc02', date: 'sentDate' },
                        triggers: [
                            { type: 'form', key: 'kyc02', trg: 'sent', rev: false },
                        ]
                    },
                    {
                        key: 'event03',
                        text: {
                            en_EN: 'Self declaration filled by {{kyc02-editorName}}',
                            nb_NO: 'Egenerklæringsskjema fylt ut av {{kyc02-editorName}}',
                            sv_SE: 'Kundkännedom ifylld av {{kyc02-editorName}}'
                        },
                        date: { type: 'form', key: 'kyc02', date: 'filledDate' },
                        triggers: [
                            { type: 'form', key: 'kyc02', trg: 'filled', rev: false },
                        ]
                    },
                    {
                        key: 'event04',
                        text: {
                            en_EN: 'Self declaration form signed by all signers',
                            nb_NO: 'Egenerklæringsskjema signert av alle signatarer',
                            sv_SE: 'Kundkännedom undertecknat av alla undertecknare'
                        },
                        date: { type: 'form', key: 'kyc02', date: 'signedDate' },
                        triggers: [
                            { type: 'form', key: 'kyc02', trg: 'signed', rev: false },
                        ]
                    }
                ]
            },
            report: [
                {
                    key: 'kyc',
                    icon: 'form',
                    comment: true,
                    separation: true,
                    includeFile: {
                        enabled: true,
                        formKey: 'kyc02',
                        name: 'Egenerklæring'
                    },
                    title: {
                        en_EN: 'Self-declaration',
                        nb_NO: 'Egenerklæring',
                        sv_SE: 'Kundkännedom'
                    },
                    requirements: [
                        {
                            key: 'org-search',
                            message: {
                                en_EN: 'Self-declaration not completed and signed',
                                nb_NO: 'Egenerklæringsskjema ikke fullført',
                                sv_SE: 'Kundkännedom ej ifylld och undertecknad'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc02', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Self-declaration not assessed',
                                nb_NO: 'Egenerklæring ikke vurdert',
                                sv_SE: 'Kundkännedom ej bedömd'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ],
                    actions: [
                        {
                            icon: 'pdf',
                            main: false,
                            action: 'preview',
                            key: 'kyc02',
                            triggers: [
                                { type: 'form', key: 'kyc02', trg: 'filled', rev: false },
                            ],
                            text: {
                                en_EN: 'Show receipt'
                            },
                            hint: {
                                en_EN: 'Open the PDF version of the form'
                            }
                        },
                    ],
                    items: [
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Not ready',
                                nb_NO: 'Ikke klar',
                                sv_SE: 'Inte redo'
                            },
                            value: {
                                en_EN: 'Self-declaration not filled in and signed',
                                nb_NO: 'Egenerklæring ikke fylt ut og signert',
                                sv_SE: 'Kundkännedom ej ifylld och undertecknad'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc02', trg: 'signed', rev: true },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Signed by',
                                nb_NO: 'Signert av',
                                sv_SE: 'Undertecknad av'
                            },
                            value: {
                                en_EN: '{{kyc02-signerNames}}',
                                nb_NO: '{{kyc02-signerNames}}',
                                sv_SE: '{{kyc02-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc02', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            type: 'comparison'
                        }
                    ]
                },
                {
                    key: 'roles',
                    icon: 'person',
                    comment: true,
                    separation: true,
                    title: {
                        en_EN: 'Key roles',
                        nb_NO: 'Nøkkelroller',
                        sv_SE: 'Nyckelroller'
                    },
                    requirements: [
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Key roles not assessed',
                                nb_NO: 'Nøkkelroller ikke vurdert',
                                sv_SE: 'Nyckelroller ej bedömda'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                },
                {
                    key: 'assessment',
                    icon: 'check',
                    comment: true,
                    separation: true,
                    title: {
                        en_EN: 'Final assessment / risk assessment',
                        nb_NO: 'Totalvurdering',
                        sv_SE: 'Slutbedömning / riskbedömning'
                    },
                    requirements: [
                        {
                            key: 'report-risk',
                            message: {
                                en_EN: 'Risk classification not set',
                                nb_NO: 'Risikoklassifisering ikke satt',
                                sv_SE: 'Riskklassificering ej fastställd'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'risk-set', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Final assessment not completed',
                                nb_NO: 'Mangler totalvurdering',
                                sv_SE: 'Helhetsbedömningg ej genomförd'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                }
            ]
        };
    },

    //#region Steps -------------------------------------------------------------------------------------------------------
    
    getKycStep() {
        return {
            key: 'no-baml-kyc',
            isDefault: true,
            name: 'Default BankID AML Self-Declaration Form',
            resetMode: 'form',
            form: 'kyc00',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'kyc02',
                    triggers: [
                        { type: 'form', key: 'kyc02', trg: 'sent', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form',
                        nb_NO: 'Åpne skjema',
                        sv_SE: 'Öppna formuläret'
                    },
                    hint: {
                        en_EN: 'Open the form'
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'preview',
                    key: 'kyc02',
                    triggers: [
                        { type: 'form', key: 'kyc02', trg: 'filled', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'kyc02',
                    triggers: [
                        { type: 'form', key: 'kyc02', trg: 'sent', rev: false },
                        { type: 'form', key: 'kyc02', trg: 'signed', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'This action will send a reminder to the recipient'
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'form-reset',
                    key: 'kyc02',
                    triggers: [
                        { type: 'form', key: 'kyc02', trg: 'sent', rev: false },
                        /* { type: 'form', key: 'kyc02', trg: 'signed', rev: true }, */
                    ],
                    text: {
                        en_EN: 'Reset form'
                    },
                    hint: {
                        en_EN: 'This action will reset the form so that a new one can be sent'
                    }
                }
            ],
            title: {
                en_EN: 'Self-Declaration form',
                nb_NO: 'Egenerklæringsskjema',
                sv_SE: 'Kundkännedom'
            },
            hint: {
                en_EN: 'This step does not have a hint yet',
                nb_NO: 'Fylles ut av kundens kontaktperson og sørger for å hente inn oppdatert data om foretaket og nøkkelroller.',
                sv_SE: 'Fylls i av kundens kontaktperson och ser till att samla in uppdaterad information om företaget och nyckelroller'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Awaiting filling by {{kyc02-nextName}}',
                                nb_NO: 'Avventer utfylling fra {{kyc02-nextName}}',
                                sv_SE: 'Väntar på färdigställande från {{kyc02-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc02', trg: 'sent', rev: false },
                                { type: 'form', key: 'kyc02', trg: 'filled', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting signing by {{kyc02-nextName}}',
                                nb_NO: 'Avventer signering fra {{kyc02-nextName}}',
                                sv_SE: 'Väntar på signering från {{kyc02-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc02', trg: 'filled', rev: false },
                                { type: 'form', key: 'kyc02', trg: 'signed', rev: true },
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Signed by {{kyc02-signerNames}}',
                                nb_NO: 'Signert av {{kyc02-signerNames}}',
                                sv_SE: 'Undertecknad av {{kyc02-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc02', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getPersonStep() {
        return {
            key: 'no-baml-per',
            isDefault: true,
            resetMode: 'roles',
            name: 'Default BankID AML Person Screening',
            actions: [
                {
                    icon: 'chev-r',
                    main: true,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        /* { type: 'person', trg: 'something', rev: false }, */
                        { type: 'report', trg: 'created', rev: true },
                    ],
                    text: {
                        en_EN: 'Show persons',
                        nb_NO: 'Vis personer',
                        sv_SE: 'Visa personer'
                    },
                    hint: {
                        en_EN: 'Show persons',
                        nb_NO: 'Vis personer',
                        sv_SE: 'Visa personer'
                    }
                },
                {
                    icon: 'chev-r',
                    main: false,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        { type: 'person', trg: 'something', rev: false },
                    ],
                    text: {
                        en_EN: 'Show persons',
                        nb_NO: 'Vis personer',
                        sv_SE: 'Visa personer'
                    },
                    hint: {
                        en_EN: 'Show persons',
                        nb_NO: 'Vis personer',
                        sv_SE: 'Visa personer'
                    }
                }
            ],
            title: {
                en_EN: 'Screening and identification of roles',
                nb_NO: 'Kontroller nøkkelroller',
                sv_SE: 'Kontrollera nyckelroller'
            },
            hint: {
                en_EN: 'Enter key roles in the company and perform PEP / Sanction Search with BankID AML and ID checks.',
                nb_NO: 'Angi nøkkelroller i foretaket og utfør PEP/Sanksjonssøk med BankID AML og ID-kontroller.',
                sv_SE: 'Gå in i nyckelroller i företaget och utför PEP/Sanction Search med BankID AML och ID-kontroller.'
            },
            status: {
                multiple: true,
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{screenedCount}} of {{selectedCount}} screened',
                                nb_NO: '{{screenedCount}} av {{selectedCount}} personsøk utført',
                                sv_SE: '{{screenedCount}} av {{selectedCount}} personsökningar utförda'
                            },
                            triggers: [
                                { type: 'person', trg: 'something', rev: false },
                                { type: 'person', trg: 'scr-all', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: '{{identifiedCount}} of {{selectedCount}} identified',
                                nb_NO: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utført',
                                sv_SE: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utförda'
                            },
                            triggers: [
                                { type: 'person', trg: 'something', rev: false },
                                { type: 'person', trg: 'idc-all', rev: true },
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{screenedCount}} of {{selectedCount}} screened',
                                nb_NO: '{{screenedCount}} av {{selectedCount}} personsøk utført',
                                sv_SE: '{{screenedCount}} av {{selectedCount}} personsökningar utförda' 
                            },
                            triggers: [
                                { type: 'person', trg: 'scr-all', rev: false },
                            ]
                        },
                        {
                            text: {
                                en_EN: '{{identifiedCount}} of {{selectedCount}} identified',
                                nb_NO: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utført',
                                sv_SE: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utförda'
                            },
                            triggers: [
                                { type: 'person', trg: 'idc-all', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getReportStep() {
        return {
            key: 'no-baml-rep',
            isDefault: true,
            resetMode: 'report',
            name: 'Default BankID AML Report',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'tab',
                    key: 'report',
                    triggers: [
                        { type: 'report', trg: 'created', rev: true },
                    ],
                    text: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport',
                        sv_SE: 'Visa rapport'
                    },
                    hint: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport',
                        sv_SE: 'Visa rapport'
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'preview',
                    key: 'report',
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Show the final report'
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'report-reset',
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ],
                    text: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport',
                        sv_SE: 'Visa rapport'
                    },
                    hint: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport',
                        sv_SE: 'Visa rapport'
                    }
                }
            ],
            title: {
                en_EN: 'Final report / Risk assessment',
                nb_NO: 'Sluttrapport',
                sv_SE: 'Slutrapport / Riskbedömning'
            },
            hint: {
                en_EN: 'Review the information collected and set a risk assessment, and a final report will be generated for the customer.',
                nb_NO: 'Gå gjennom innsamlet informasjon og sett en risikovurdering, så genereres det en sluttrapport for kunden.',
                sv_SE: 'Granska den insamlade informationen och gör en riskbedömning, så kommer en slutrapport att genereras för kunden.'
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Final report created',
                                nb_NO: 'Sluttrapport laget',
                                sv_SE: 'Slutrapport skapad'
                            },
                            triggers: [
                                { type: 'report', trg: 'created', rev: false },
                                { type: 'report', trg: 'changed', rev: true },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Underlying data has been updated since the report was created',
                                nb_NO: 'Underliggende data har blitt oppdatert siden rapporten ble opprettet',
                                sv_SE: 'Underliggande data har uppdaterats sedan rapporten skapades'
                            },
                            triggers: [
                                { type: 'report', trg: 'created', rev: false },
                                { type: 'report', trg: 'changed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default xxOrg;