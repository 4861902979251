<template>
<div class="fbinp-label">
    
    {{ text }}

</div>
</template>
<script>
export default {
    name: 'DesignerLabel',
    props: {
        text: { type: String, default: '' },
    }
}
</script>
<style scoped>

.fbinp-label {
    height: 15px;
    font-size: 13px;
    color: var(--fb-text-2);
    margin-bottom: 2px;
    padding-left: 2px;
}

</style>