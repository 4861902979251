<template>
    <div class="vapli" :class="[{'vapli-disabled' : disabled}, {'vapli-p10' : padding10}, {'vapli-p20' : padding20}, {'vapli-sep' : separator}, {'vapli-float' : float}, {'vapli-accent' : accented}]">
        <slot v-if="!fancy"></slot>
        <div v-if="fancy" class="vapli-fancy">
            <div class="vapli-fancy-icon" :class="{'vapli-fancy-icon-c': fancyCircle}">
                <vap-icon small :icon="fancyIcon" :color="getIconColor()" />
            </div>
            <div class="vapli-fancy-content">
                <div class="vapli-fancy-text" :class="{'vapli-fancy-text-h': fancyHint}">
                    <label>{{ fancyText }}</label>
                </div>
                <div class="vapli-fancy-hint" v-if="fancyHint">
                    <label>{{ fancyHint }}</label>
                </div>
            </div>
        </div>
        <div class="vapli-bar" v-if="accented" />
        <div class="vapli-cover" @click="handleClick" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" />
    </div>
</template>

<script>
export default {
    name: 'vap-item',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        padding10: {
            type: Boolean,
            default: false
        },
        padding20: {
            type: Boolean,
            default: false
        },
        separator: {
            type: Boolean,
            default: false
        },
        float: {
            type: Boolean,
            default: false
        },
        fancy: {
            type: Boolean,
            default: false
        },
        fancyText: {
            type: String,
            default: 'This is a text with an icon'
        },
        fancyHint: {
            type: String,
            default: ''
        },
        fancyCircle: {
            type: Boolean,
            default: false
        },
        fancyIcon: {
            type: String,
            default: 'help-o'
        },
        fancyError: {
            type: Boolean,
            default: false
        },
        accented: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            path1: '',
            path2: ''
        }
    },
    computed: {
        
    },
    watch: {
        
    },
    methods: {

        handleMouseEnter(event) {
            this.$emit("enter", event);
        },
        handleMouseLeave(event) {
            this.$emit("leave", event);
        },

        handleClick() {
            if (this.disabled) return;
            if (this.fancy) this.$emit("hide", true);
            this.$emit("clicked", true);
        },

        getIconColor() {
            if (this.disabled) {
                return 'var(--text-disabled)';
            }else {
                if (this.fancyCircle) {
                    return 'var(--accent-contrast)';
                }else {
                    return 'var(--accent)';
                }
            }
        }
    },
    async mounted() {}
}
</script>
<style scoped>

.vapli {
    position: relative;
    transition: none;
    padding: 0px 0px;
    color: var(--text-strong);
    box-sizing: border-box;
    border: solid 1px var(--card-background);
}
.vapli-sep {
    border-bottom: solid 1px lightgray;
}
.vapli-float {
    float: left;
    width: 100%;
}

.vapli-bar {
    position: absolute;
    width: 4px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: var(--accent);
    display: none;
}

@media screen and (min-width: 800px) {
    .vapli:hover {
        background-color: var(--card-hover);
    }
    .vapli-accent:hover {
        background-color: var(--card-hover);
        border: solid 1px var(--card-hover-border);
    }
    .vapli-accent:hover .vapli-bar {
        display: initial;
    }
    .vapli-disabled:hover {
        background-color: var(--card-background);
    }
}

.vapli:active {
    background-color: var(--primary);
}
.vapli-disabled:active {
    background-color: var(--card-background);
}

.vapli-cover {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    cursor: pointer;
}

.vapli-disabled .vapli-cover {
    cursor: not-allowed;
}

.vapli-p10 {
    padding: 10px 12px;
    box-sizing: border-box;
}
.vapli-p20 {
    padding: 10px 22px;
    box-sizing: border-box;
}



.vapli-fancy {
    display: flex;
}

.vapli-fancy-icon {
    width: 44px;
    flex-shrink: 0;
    padding-top: 4px;
}
.vapli-fancy-icon-c {
    width: 36px; height: 36px;
    margin-left: -7px;
    margin-right: 13px;
    padding-top: 4px;
    padding-left: 4px;
    border-radius: 50%;
    background-color: var(--accent);
    box-sizing: border-box;
}


.vapli-fancy-content {
    flex-grow: 100;
}

.vapli-fancy-text {
    padding-top: 6px;
    font-size: 20px;
    color: var(--text-accent);
}
.vapli-fancy-text {
    padding-top: 6px;
    font-size: 20px;
    color: var(--text-accent);
}
.vapli-fancy-hint {
    padding-top: 0px;
    font-size: 14px;
    color: var(--text-weak);
}

.vapli-fancy-text-h {
    padding-top: 0px;
    font-size: 18px;
}

.vapli-disabled .vapli-fancy-text {
    color: var(--text-disabled);
}
.vapli-disabled .vapli-fancy-icon-c {
    background-color: transparent;
}

</style>