<template>
<div>

    <PopupV1 :header="__t('monitor-recent-hits')" :maxHeight="500" @close="onClose" :dialog="dialog">
        <template v-slot:content>

            <div class="amlv1-cs-hit" v-for="(hit, i) in recentHits" :key="'h' + i">

                <div class="amlv1-cs-hit-c">
                    <div class="amlv1-cs-hit-d">
                        {{ __t('common-date') + ':' }}
                    </div>
                    <div class="amlv1-cs-hit-v">
                        {{ formatDate('d', hit.created) }}
                    </div>
                </div>

                <div class="amlv1-cs-hit-c">
                    <div class="amlv1-cs-hit-d">
                        {{ __t('monitor-hit-type') + ':' }}
                    </div>
                    <div class="amlv1-cs-hit-v">
                        {{ getMonitorTypeText(hit) }}
                    </div>
                </div>

                <div class="amlv1-cs-hit-c">
                    <div class="amlv1-cs-hit-d">
                        {{ __t('monitor-hit-new-status') + ':' }}
                    </div>
                    <div class="amlv1-cs-hit-v">
                        {{ getMonitorActiveText(hit) }}
                    </div>
                </div>

                <div class="amlv1-cs-hit-c">
                    <div class="amlv1-cs-hit-d">
                        {{ __t('monitor-hit-description') + ':' }}
                    </div>
                    <div class="amlv1-cs-hit-v">
                        {{ hit.hit.description }}
                    </div>
                </div>

                <div class="amlv1-cs-hit-c">
                    <div class="amlv1-cs-hit-dnl">
                        {{ __t('monitor-hit-notes') + ':' }}
                    </div>
                    <div class="amlv1-cs-hit-v" v-for="(note, j) in hit.hit.notes" :key="'n' + j">
                        {{ note }}
                    </div>
                </div>

            </div>

        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-close')" outline @clicked="onClose" :disabled="dialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import monitorHelper from '../v1monitor/monitorHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFInput from '../components/form/inputs/VFInput.vue';
import VFDropdown from '../components/form/inputs/VFDropdown.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'MonitorHitDialog',
    components: {
        PopupV1,
        VFButton,
        PopupV1,
        VFButton,
        VFRow,
        VFGroup,
        VFLabel,
        VFInput,
        VFDropdown,
        VFCheckbox,
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            dialog: { show: false, valid: false, working: false, when: '', who: '', roleModel: {} },
            recentHits: [],
        }
    },
    methods: {

        onClose() {
            this.$emit('close');
        },

        getMonitorTypeText(hit) {
            return monitorHelper.getTypeText(this.$root, hit);
        },

        getMonitorActiveText(hit) {
            return monitorHelper.getActiveText(this.$root, hit);
        },

    },
    created() {
        if (this.params.hitIndex) {
            this.recentHits.push(this.params.person.monitorHits[this.params.hitIndex]);
        }else {
            const screeningDate = new Date(this.params.person.screening.date);
            for (const i in this.params.person.monitorHits) {
                const hit = this.params.person.monitorHits[i];
                const hitDate = new Date(hit.created);
                const diff = hitDate - screeningDate;
                const diffSeconds = diff / 1000;
                if (diffSeconds > 10) {
                    this.recentHits.push(hit);
                }
            }
        }
    }
}
</script>
<style scoped>

.amlv1-cs-hit {
    margin-bottom: 10px;
    padding: 15px;
    border: solid 1px var(--input-border);
}

.amlv1-cs-hit-c {
    display: flex;
    flex-wrap: wrap;
}

.amlv1-cs-hit-d {
    margin-right: 6px;
    color: var(--text-weak);
}

.amlv1-cs-hit-dnl {
    width: 100%;
    color: var(--text-weak);
}

.amlv1-cs-hit-v {
    font-weight: 500;
    color: var(--text-title);
}

</style>