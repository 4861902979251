const v1locale = {

    //#region Portal ------------------------------------------------------------------------------------------------------

    getDefaultPortalTranslations() {
        return {

            "header-roles": {
                "en_EN": "Persons",
                "nb_NO": "Personer",
                "sv_SE": "Personer",
            },


            "common-yes": {
                "en_EN": "Yes",
                "nb_NO": "Ja",
                "de_DE": "Ja",
                "sv_SE": "Ja"
            },
            "common-no": {
                "en_EN": "No",
                "nb_NO": "Nei",
                "de_DE": "Nein",
                "sv_SE": "Nej"
            },
            "common-cancel": {
                "en_EN": "Cancel",
                "nb_NO": "Avbryt",
                "sv_SE": "Avbryt",
            },
            "common-close": {
                "en_EN": "Close",
                "nb_NO": "Lukk",
                "sv_SE": "Stänga",
            },
            "common-save": {
                "en_EN": "Save",
                "nb_NO": "Lagre",
                "sv_SE": "Spara",
            },
            "common-not-available": {
                "en_EN": "Not available",
                "nb_NO": "Ikke tilgjengelig",
                "sv_SE": "Not available",
            },
            "common-contact-info": {
                "en_EN": "Contact information",
                "nb_NO": "Kontaktinformasjon",
                "sv_SE": "Kontaktinformation",
            },
            "common-email": {
                "en_EN": "Email",
                "nb_NO": "E-post",
                "sv_SE": "E-post",
                "de_DE": "Email",
            },
            "common-phone": {
                "en_EN": "Phone",
                "nb_NO": "Telefon",
                "de_DE": "Telefonnummer",
                "sv_SE": "Telefon",
            },
            "common-address": {
                "en_EN": "Address",
                "nb_NO": "Adresse",
                "sv_SE": "Adress",
                "de_DE": "Strasse und Hausnummer",
            },
            "common-zip": {
                "en_EN": "Zip-code",
                "nb_NO": "Postnummer",
                "sv_SE": "Postnummer",
                "de_DE": "PLZ",
            },
            "common-city": {
                "en_EN": "City",
                "nb_NO": "Sted",
                "sv_SE": "Ort",
                "de_DE": "Ort",
            },
            "common-address-national": {
                "en_EN": "National address",
                "nb_NO": "Folkeregistrert adresse",
                "sv_SE": "National address",
            },
            "common-address-postal": {
                "en_EN": "Postal address",
                "nb_NO": "Postadresse",
                "sv_SE": "Postadress",
            },
            "common-customer": {
                "en_EN": "Customer",
                "nb_NO": "Kunde",
                "sv_SE": "Kund",
            },
            "common-person": {
                "en_EN": "Person",
                "nb_NO": "Person",
                "sv_SE": "Person",
            },
            "common-role": {
                "en_EN": "Role",
                "nb_NO": "Rolle",
                "sv_SE": "Roll",
            },
            "common-roles": {
                "en_EN": "Roles",
                "nb_NO": "Roller",
                "sv_SE": "Roller",
            },
            "common-comments": {
                "en_EN": "Comments",
                "nb_NO": "Kommentarer",
                "sv_SE": "Kommentarer",
            },
            "common-no-comment": {
                "en_EN": "No comment",
                "nb_NO": "Ingen kommentar",
                "sv_SE": "Ingen kommentar",
            },
            "common-date": {
                "en_EN": "Date",
                "nb_NO": "Dato",
                "de_DE": "Datum",
                "sv_SE": "Datum",
            },
            "common-result": {
                "en_EN": "Result",
                "nb_NO": "Resultat",
                "sv_SE": "Resultat",
            },
            "common-unknown": {
                "en_EN": "Unknown",
                "nb_NO": "Ukjent",
                "sv_SE": "Okänd",
            },
            "common-reset": {
                "en_EN": "Reset",
                "nb_NO": "Tilbakestill",
                "sv_SE": "Återställa",
            },


            "person-first-name": {
                "en_EN": "First Name",
                "nb_NO": "Fornavn",
                "sv_SE": "Förnamn",
                "de_DE": "Vorname",
            },
            "person-last-name": {
                "en_EN": "Last Name",
                "nb_NO": "Etternavn",
                "sv_SE": "Efternamn",
                "de_DE": "Nachname",
            },
            "person-dob": {
                "en_EN": "Date of birth",
                "nb_NO": "Fødselsdato",
                "sv_SE": "Födelsedatum",
                "de_DE": "Geburtsdatum",
            },
            "person-citizenship": {
                "en_EN": "Citizenship",
                "nb_NO": "Statsborgerskap",
                "sv_SE": "Medborgarskap",
                "de_DE": "Staatsbürgerschaft"
            },
            "person-residency": {
                "en_EN": "Residency",
                "nb_NO": "Bostedsadresse",
                "sv_SE": "Residens",
            },
            "person-geo-empty-hint": {
                "en_EN": "This information can be retrieved via self-declaration or person screening (if available).",
                "nb_NO": "Denne informasjonen kan hentes inn via egenerklæring eller personsøk (hvis tilgjengelig).",
                "sv_SE": "Denna information kan hämtas in via kundkännedom eller personsökning (hvis tillgänglig).",
            },


            "validation-all": {
                "en_EN": "Please enter all details",
                "nb_NO": "Vennligst fyll i alle felter",
                "sv_SE": "Vänligen ange alla detaljer",
            },

            "error": {
                "en_EN": "Error",
                "nb_NO": "Feil",
                "sv_SE": "Fel",
            },
            "error-title": {
                "en_EN": "An error occurred",
                "nb_NO": "Det oppstod en feil",
                "sv_SE": "Ett fel uppstod",
            },
            "error-status-1": {
                "en_EN": "You can check",
                "nb_NO": "Du kan sjekke",
                "sv_SE": "Du kan kolla",
            },
            "error-status-2": {
                "en_EN": "for any known issues.",
                "nb_NO": "om det er noen kjente utfordringer.",
                "sv_SE": "för alla kända problem.",
            },
            "error-submit-hint": {
                "en_EN": "If this issue has not already been reported, you can do so by clicking",
                "nb_NO": "Hvis dette problemet ikke allerede er rapportert, kan du gjøre det ved å klikke",
                "sv_SE": "Om det här problemet inte redan har rapporterats kan du göra det genom att klicka",
            },
            "error-submit-ok": {
                "en_EN": "Error report submitted",
                "nb_NO": "Feilrapport sendt inn",
                "sv_SE": "Felrapport skickad",
            },
            "error-id-hint": {
                "en_EN": "Please provide the ID in any support requests.",
                "nb_NO": "Vennligst oppgi ID i eventuelle støtteforespørsler.",
                "sv_SE": "Ange ID i alla supportförfrågningar.",
            },

            "date-tp-now": {
                "en_EN": "right now",
                "nb_NO": "nå",
                "sv_SE": "just nu",
            },
            "date-tp-min": {
                "en_EN": "minutes ago",
                "nb_NO": "minutter siden",
                "sv_SE": "minuter sedan",
            },
            "date-tp-hour": {
                "en_EN": "hours ago",
                "nb_NO": "timer siden",
                "sv_SE": "timmar sedan",
            },
            "date-tp-today": {
                "en_EN": "today",
                "nb_NO": "i dag",
                "sv_SE": "i dag",
            },
            "date-tp-week": {
                "en_EN": "this week",
                "nb_NO": "denne uken",
                "sv_SE": "Denna vecka",
            },
            "date-tp-month": {
                "en_EN": "this month",
                "nb_NO": "denne måneden",
                "sv_SE": "den här månaden",
            },
            "date-tp-month1": {
                "en_EN": "1 month ago",
                "nb_NO": "1 måned siden",
                "sv_SE": "1 månad sedan",
            },
            "date-tp-month2": {
                "en_EN": "2 months ago",
                "nb_NO": "2 måneder siden",
                "sv_SE": "2 månader sedan",
            },
            "date-tp-month3": {
                "en_EN": "more than 3 months",
                "nb_NO": "mer enn 3 måneder",
                "sv_SE": "mer än 3 månader",
            },
            "date-tp-year": {
                "en_EN": "more than a year",
                "nb_NO": "mer enn 1 år siden",
                "sv_SE": "mer än ett år",
            },


            "files-document": {
                "en_EN": "Document",
                "nb_NO": "Dokument",
                "sv_SE": "Dokument",
            },
            "files-attachments": {
                "en_EN": "Attachments",
                "nb_NO": "Vedlegg",
                "sv_SE": "Bilagor",
            },
            "files-attachments-empty": {
                "en_EN": "No attachments",
                "nb_NO": "Ingen vedlegg",
                "sv_SE": "Inga bilagor",
            },
            "files-attachments-preview": {
                "en_EN": "Attachments will be added when the report is created",
                "nb_NO": "Vedlegg vil bli lagt til når rapporten lages",
                "sv_SE": "Bilagor kommer att läggas till när rapporten skapas",
            },
            "files-main-document": {
                "en_EN": "Main document",
                "nb_NO": "Hoveddokument",
                "sv_SE": "Huvuddokument",
            },
            "files-secondary-document": {
                "en_EN": "Secondary document",
                "nb_NO": "Sekundærdokument",
                "sv_SE": "Sekundärt dokument",
            },
            "files-preview-error": {
                "en_EN": "Can't preview this file, download it to view",
                "nb_NO": "Kan ikke forhåndsvise denne filen. Last den ned for å åpne den.",
                "sv_SE": "Det går inte att förhandsgranska den här filen, ladda ner den för att visa den",
            },
            "files-download": {
                "en_EN": "Download",
                "nb_NO": "Last ned",
                "sv_SE": "Ladda ner",
            },
            "files-upload-header": {
                "en_EN": "Upload file",
                "nb_NO": "Last opp fil",
                "sv_SE": "Ladda upp fil",
            },
            "files-upload-hint": {
                "en_EN": "Drag & drop a file here or",
                "nb_NO": "Dra og slipp en fil her eller",
                "sv_SE": "Dra och släpp en fil här eller",
            },
            "files-upload-browse": {
                "en_EN": "select file",
                "nb_NO": "velg fil",
                "sv_SE": "välj fil",
            },
            "files-pdf-hint": {
                "en_EN": "Only PDF files are supported. If you have an Excel/Word file or similar you can export it to PDF before uploading.",
                "nb_NO": "Bare PDF-filer støttes. Hvis du har en Excel/Word-fil eller lignende kan du eksportere den til PDF før du laster opp.",
                "sv_SE": "Endast PDF-filer stöds. Om du har en Excel/Word-fil eller liknande kan du exportera den till PDF innan du laddar upp den.",
            },
            "files-upload-type": {
                "en_EN": "File Type",
                "nb_NO": "Filtype",
                "sv_SE": "Filtyp",
            },

            "files-external-header": {
                "en_EN": "External Files",
                "nb_NO": "Eksterne filer",
                "sv_SE": "Externa filer",
            },
            "files-external-empty": {
                "en_EN": "No external files for this customer",
                "nb_NO": "Ingen eksterne filer for denne kunden",
                "sv_SE": "Inga externa filer för denna kund",
            },
            "files-external-add": {
                "en_EN": "Add File",
                "nb_NO": "Legg til fil",
                "sv_SE": "Lägg till fil",
            },
            "files-external-delete": {
                "en_EN": "Delete File",
                "nb_NO": "Slett fil",
                "sv_SE": "Radera fil",
            },
            "files-external-delete-hint": {
                "en_EN": "The uploaded file will be deleted",
                "nb_NO": "Den opplastede filen vil bli slettet",
                "sv_SE": "Den uppladdade filen kommer att raderas",
            },

            "datapdf-name": {
                "en_EN": "Customer data",
                "nb_NO": "Kundedata",
                "sv_SE": "Kundedata",
            },
            "datapdf-tag": {
                "en_EN": "customer data",
                "nb_NO": "kundedata",
                "sv_SE": "kundedata",
            },


            "search-name": {
                "en_EN": "Company search",
                "nb_NO": "Selskapssøk",
                "sv_SE": "Företagssökning",
            },
            "search-tag": {
                "en_EN": "company search",
                "nb_NO": "selskapssøk",
                "sv_SE": "företagssökning",
            },
            "search-flow": {
                "en_EN": "Flow",
                "nb_NO": "Flyt",
                "sv_SE": "Flöde",
            },
            "search-result-count": {
                "en_EN": "results for",
                "nb_NO": "resultater for",
                "sv_SE": "resultat för",
            },
            "search-result-empty": {
                "en_EN": "No results for",
                "nb_NO": "Ingen resultater for",
                "sv_SE": "Inga resultat för",
            },
            "search-result-error-1": {
                "en_EN": "Could not search for",
                "nb_NO": "Kunne ikke søke etter",
                "sv_SE": "Kunde inte söka efter",
            },
            "search-result-error-2": {
                "en_EN": "Try searching for the entire name or use the organization number.",
                "nb_NO": "Prøv å søke på hele navnet eller bruk organisasjonsnummeret.",
                "sv_SE": "Försök att söka på hela namnet eller använd organisationsnumret.",
            },
            "search-select-company": {
                "en_EN": "Select a company",
                "nb_NO": "Velg et selskap",
                "sv_SE": "Välj ett företag",
            },
            "search-se-hint": {
                "en_EN": "Find a company registered in Bolagsverket. It is recommended to search using the organization number to find the correct company.",
                "nb_NO": "Finn en bedrift registrert i Bolagsverket. Det anbefales å søke ved hjelp av organisasjonsnummeret for å finne riktig bedrift.",
                "sv_SE": "Hitta ett företag registrerat i Bolagsverket. Det rekommenderas att söka med hjälp av organisationsnumret för att hitta rätt företag.",
            },
            "search-se-hint-number-only": {
                "en_EN": "Use the organization number to find a company registered in Bolagsverket.",
                "nb_NO": "Bruk organisasjonsnummeret for å finne en bedrift registrert i Bolagsverket.",
                "sv_SE": "Använd organisationsnumret för att hitta ett företag registrerat i Bolagsverket.",
            },
            "search-se-search-hint": {
                "en_EN": "Search for a company in Bolagsverket",
                "nb_NO": "Søk etter en bedrift i Bolagsverket",
                "sv_SE": "Sök efter ett företag i Bolagsverket",
            },
            "search-se-search-hint-number-only": {
                "en_EN": "Organization number",
                "nb_NO": "Organisasjonsnummer",
                "sv_SE": "Organisationsnummer",
            },
            "search-se-number-only-error": {
                "en_EN": "Enter a valid organization number",
                "nb_NO": "Skriv inn et gyldig organisasjonsnummer",
                "sv_SE": "Ange ett giltigt organisationsnummer",
            },



            "events-number-of-clients":{
                "en_EN": "Total number of clients",
                "nb_NO": "Totalt antall kunder",
                "sv_SE": "Totalt antal kunder",
            },
            "events-clients":{
                "en_EN": "Clients",
                "nb_NO": "Kunder",
                "sv_SE": "Kunder",
            },
            "events-events":{
                "en_EN": "Events",
                "nb_NO": "Hendelser",
                "sv_SE": "Händelser",
            },
            "events-last-event":{
                "en_EN": "Last event",
                "nb_NO": "Siste hendelser",
                "sv_SE": "Senaste händelserna",
            },
            "events-no-events":{
                "en_EN": "No events",
                "nb_NO": "Ingen hendelser",
                "sv_SE": "Inga händelser",
            },
            "events-risk-distribution":{
                "en_EN": "Risk distribution",
                "nb_NO": "Risikofordeling",
                "sv_SE": "Riskfördelning",
            },
            "events-date":{
                "en_EN": "Date",
                "nb_NO": "Dato",
                "sv_SE": "Datum",
            },
            "events-priority":{
                "en_EN": "Priority",
                "nb_NO": "Prioritet",
                "sv_SE": "Prioritet",
            },

            "events-client-created":{
                "en_EN": "Customer added",
                "nb_NO": "Ny kunde lagt til",
                "sv_SE": "Ny kund tillagd",
            },
            "events-screening-performed":{
                "en_EN": "Screening performed",
                "nb_NO": "Personsøk utført",
                "sv_SE": "Sökning utförd",
            },
            "events-idc-completed":{
                "en_EN": "ID-control completed by",
                "nb_NO": "ID-kontroll fullført av",
                "sv_SE": "ID-kontroll slutförd av",
            },
            "events-kyc-completed":{
                "en_EN": "Self-declaration completed",
                "nb_NO": "Egenerklæring fullført",
                "sv_SE": "Kundkännedom slutförd",
            },
            "events-form-completed":{
                "en_EN": "Form completed",
                "nb_NO": "Skjema fullført",
                "sv_SE": "Formulär slutförd",
            },
            "events-client-report":{
                "en_EN": "Final report created",
                "nb_NO": "Sluttrapport opprettet",
                "sv_SE": "Slutrapport skapad",
            },


            "lookup-header":{
                "en_EN": "Company lookup",
                "nb_NO": "Organisasjonssøk",
                "sv_SE": "Företagssökning",
            },
            "lookup-baml-report":{
                "en_EN": "BankID AML Company Report",
                "nb_NO": "BankID AML Rapport",
                "sv_SE": "BankID AML Company Report",
            },
            "lookup-baml-inc":{
                "en_EN": "BankID AML Certificate of Incorporation",
                "nb_NO": "BankID AML Firmaattest",
                "sv_SE": "BankID AML Certificate of Incorporation",
            },
            "lookup-source-hint":{
                "en_EN": "The lookup will fetch data from and screen against these sources",
                "nb_NO": "Organisasjonssøket vil hente data fra følgende kilder",
                "sv_SE": "Uppslagningen hämtar data från och skärmar mot dessa källor",
            },
            "lookup-action":{
                "en_EN": "Perform lookup",
                "nb_NO": "Søk",
                "sv_SE": "Utför uppslag",
            },
            "lookup-prog-creating":{
                "en_EN": "Creating new customer",
                "nb_NO": "Oppretter ny kunde",
                "sv_SE": "Skapar ny kund",
            },
            "lookup-prog-brreg":{
                "en_EN": "Fetching data from BRREG",
                "nb_NO": "Henter data fra BRREG",
                "sv_SE": "Hämtar data från BRREG",
            },
            "lookup-prog-baml":{
                "en_EN": "Fetching data from BankID AML",
                "nb_NO": "Henter data fra BankID AML",
                "sv_SE": "Hämtar data från BankID AML",
            },
            "lookup-prog-trapets":{
                "en_EN": "Fetching data from Trapets",
                "nb_NO": "Henter data fra Trapets",
                "sv_SE": "Hämtar data från Trapets",
            },
            "lookup-prog-bisnode":{
                "en_EN": "Fetching data from Bisnode",
                "nb_NO": "Henter data fra Bisnode",
                "sv_SE": "Hämtar data från Bisnode",
            },
            "lookup-prog-company":{
                "en_EN": "Updating company data",
                "nb_NO": "Oppdaterer selskapsdata",
                "sv_SE": "Uppdatering av företagsdata",
            },
            "lookup-prog-baml-report":{
                "en_EN": "Downloading report from BankID AML",
                "nb_NO": "Laster ned rapport fra BankID AML",
                "sv_SE": "Laddar ner rapport från BankID AML",
            },
            "lookup-empty":{
                "en_EN": "Perform a company lookup to see information here",
                "nb_NO": "Gjør et søk på foretaket for å se informasjon her",
                "sv_SE": "Gör en företagssökning för att se information här",
            },

            "client-import":{
                "en_EN": "Import customer",
                "nb_NO": "Importer kunde",
                "sv_SE": "Importera kund",
            },
            "client-import-hint":{
                "en_EN": "Create a new customer from a previously exported customer file",
                "nb_NO": "Opprett en ny kunde fra en tidligere eksportert kundefil",
                "sv_SE": "Skapa en ny kund från en tidigare exporterad kundfil",
            },
            "client-export":{
                "en_EN": "Export customer",
                "nb_NO": "Eksporter denne kunden",
                "sv_SE": "Exportera denna kund",
            },
            "client-export-hint":{
                "en_EN": "Client will be exported to your local device",
                "nb_NO": "Klienten vil bli eksportert til din lokale enhet",
                "sv_SE": "Klienten kommer att exporteras till din lokala enhet",
            },


            "verified-reminder-send": {
                "en_EN": "Send reminder",
                "nb_NO": "Send påminnelse",
                "sv_SE": "Skicka påminnelse",
            },
            "verified-reminder-hint": {
                "en_EN": "A reminder will be sent to this recipient",
                "nb_NO": "En påminnelse vil bli sendt til denne mottakeren",
                "sv_SE": "En påminnelse kommer att skickas till denna mottagare",
            },
            "verified-reminder-loading": {
                "en_EN": "Loading next recipient",
                "nb_NO": "Laster inn neste mottaker",
                "sv_SE": "Laddar nästa mottagare",
            },
            "verified-form": {
                "en_EN": "Form",
                "nb_NO": "Skjema",
                "sv_SE": "Formulär",
            },
            "verified-reset": {
                "en_EN": "Reset form",
                "nb_NO": "Tilbakestill skjema",
                "sv_SE": "Återställ formuläret",
            },
            "verified-reset-hint": {
                "en_EN": "The following will be reset",
                "nb_NO": "Følgende vil bli tilbakestilt",
                "sv_SE": "Följande kommer att återställas",
            },
            "verified-reset-abort": {
                "en_EN": "The ongoing process will be aborted",
                "nb_NO": "Den pågående prosessen vil bli avbrutt",
                "sv_SE": "Den pågående processen kommer att avbrytas",
            },
            "verified-reset-archive": {
                "en_EN": "The document will still be available from the Verified archive",
                "nb_NO": "Dokumentet vil fortsatt være tilgjengelig fra Verified-arkivet",
                "sv_SE": "Dokumentet kommer fortfarande att vara tillgängligt från Verified-arkivet",
            },
            "verified-resetting": {
                "en_EN": "Resetting form",
                "nb_NO": "Tilbakestiller skjema",
                "sv_SE": "Återställ formulär",
            },
            "verified-aborting": {
                "en_EN": "Aborting envelope",
                "nb_NO": "Avbryter konvolutt",
                "sv_SE": "Avbryter kuvert",
            },
            "verified-signing": {
                "en_EN": "Signing",
                "nb_NO": "Signering",
                "sv_SE": "Signering",
            },

            "method-bankid-no": {
                "en_EN": "BankID Norway",
                "nb_NO": "BankID Norge",
                "sv_SE": "BankID Norge",
            },
            "method-bankid-se": {
                "en_EN": "BankID Sweden",
                "nb_NO": "BankID Sverige",
                "sv_SE": "BankID Sverige",
            },
            "method-passport-reader": {
                "en_EN": "Passport Reader",
                "nb_NO": "Passport Reader",
                "sv_SE": "Passleser",
            },
            "method-manual": {
                "en_EN": "Completed manually",
                "nb_NO": "Fullført manuelt",
                "sv_SE": "Fullført manuellt",
            },

            "form-submit": {
                "en_EN": "Submit",
                "nb_NO": "Send inn",
                "sv_SE": "Skicka in",
            },
            "form-resend": {
                "en_EN": "Resend form",
                "nb_NO": "Send skjema på nytt",
                "sv_SE": "Skicka formuläret igen",
            },
            "form-resend-hint": {
                "en_EN": "This form will be loaded with already filled data, and can be sent again. You will be able to look over the information before sending.",
                "nb_NO": "Dette skjemaet vil bli lastet med allerede utfylte data, og kan sendes på nytt. Du vil kunne se over informasjonen før du sender.",
                "sv_SE": "Detta formulär kommer att laddas med redan ifyllda uppgifter och kan skickas igen. Du kommer att kunna se över informationen innan du skickar.",
            },
            "form-resend-warning": {
                "en_EN": "NB! This function is not intended to be used unless the previous form had technical issues, or the recipient filled wrong information and then submitted. If you just want to send a reminder for an existing form, you can use the reminder function.",
                "nb_NO": "NB! Denne funksjonen er ikke ment å brukes med mindre det forrige skjemaet hadde tekniske problemer, eller mottakeren fylte ut feil informasjon og deretter sendte inn. Ønsker du kun å sende en purring for et eksisterende skjema, kan du bruke påminnelsesfunksjonen.",
                "sv_SE": "OBS! Den här funktionen är inte avsedd att användas om inte det tidigare formuläret hade tekniska problem eller att mottagaren fyllde i felaktig information och sedan skickade in. Om du bara vill skicka en påminnelse för ett befintligt formulär kan du använda påminnelsefunktionen.",
            },
            "form-resend-abort": {
                "en_EN": "Abort the previous form",
                "nb_NO": "Avbryt forrige skjema",
                "sv_SE": "Avbryt det föregående formuläret",
            },

            "form-autosave-header": {
                "en_EN": "Data recovered",
                "nb_NO": "Data gjenopprettet",
                "sv_SE": "Data återställd",
            },
            "form-autosave-message": {
                "en_EN": "The form has been loaded with autosaved data",
                "nb_NO": "Skjema har blitt lastet inn med lagrede data",
                "sv_SE": "Formuläret har laddats med automatiskt sparad data",
            },
            "form-autosave-reset": {
                "en_EN": "Reset data",
                "nb_NO": "Tilbakestill",
                "sv_SE": "Återställ",
            },
            "form-autosave-hide": {
                "en_EN": "Hide this message",
                "nb_NO": "Gjem denne meldingen",
                "sv_SE": "Dölj det här meddelandet",
            },
            "form-autosave-button": {
                "en_EN": "Save",
                "nb_NO": "Lagre",
                "sv_SE": "Spara",
            },
            "form-autosave-hint": {
                "en_EN": "Save the form so you can continue later. Use the email-link to open the form again.",
                "nb_NO": "Lagre skjema slik at du kan fortsette senere. Bruk epost-linken for å åpne skjema igjen.",
                "sv_SE": "Spara formuläret så att du kan fortsätta senare. Använd e-postlänken för att öppna formuläret igen.",
            },
            "form-autosave-saved": {
                "en_EN": "Form saved",
                "nb_NO": "Skjemadata lagret",
                "sv_SE": "Formuläret har sparats",
            },

            "form-trigger": {
                "en_EN": "Update status",
                "nb_NO": "Oppdater status",
                "sv_SE": "Uppdatera status",
            },
            "form-trigger-hint": {
                "en_EN": "If the status of the form is not properly updated, you can try to update it manually here.",
                "nb_NO": "Hvis statusen til skjemaet ikke er riktig oppdatert, kan du prøve å oppdatere det manuelt her.",
                "sv_SE": "Om statusen för formuläret inte är korrekt uppdaterad kan du försöka uppdatera det manuellt här.",
            },

            "landing-document": {
                "en_EN": "Document",
                "nb_NO": "Dokument",
                "sv_SE": "Dokument",
            },
            "landing-sent-by": {
                "en_EN": "Sent by",
                "nb_NO": "Sendt av",
                "sv_SE": "Skickat av",
            },
            "landing-filled-by": {
                "en_EN": "Filled by",
                "nb_NO": "Fylt ut av",
                "sv_SE": "Fylld av",
            },
            "landing-filled-not": {
                "en_EN": "Awaiting filling",
                "nb_NO": "Avventer utfylling",
                "sv_SE": "Väntar på fyllning",
            },
            "landing-signed-by": {
                "en_EN": "Signed by",
                "nb_NO": "Signert av",
                "sv_SE": "Undertecknad av",
            },
            "landing-signed-not": {
                "en_EN": "Awaiting signing",
                "nb_NO": "Avventer signering",
                "sv_SE": "Väntar på signering",
            },
            "landing-signed-rejected": {
                "en_EN": "Rejected by",
                "nb_NO": "Avvist av",
                "sv_SE": "Rejected by",
            },
            "landing-signed-empty": {
                "en_EN": "Not selected",
                "nb_NO": "Ikke valgt",
                "sv_SE": "Ej valt",
            },
            "landing-completed-status": {
                "en_EN": "Status",
                "nb_NO": "Status",
                "sv_SE": "Status",
            },
            "landing-completed-not": {
                "en_EN": "Not completed",
                "nb_NO": "Ikke fullført",
                "sv_SE": "Inte färdigt",
            },
            "landing-completed": {
                "en_EN": "Completed",
                "nb_NO": "Fullført",
                "sv_SE": "Färdigt",
            },
            "landing-aborted": {
                "en_EN": "Aborted",
                "nb_NO": "Avbrutt",
                "sv_SE": "Avbruten",
            },
            "landing-aborted-head": {
                "en_EN": "Document signing was aborted",
                "nb_NO": "Dokumentsignering ble avbrutt",
                "sv_SE": "Dokumentsigneringen avbröts",
            },
            "landing-aborted-message": {
                "en_EN": "Document signing was aborted by the sender. This could be because it was sent to the wrong person, or because a new form has been sent for filling.",
                "nb_NO": "Dokumentsignering ble avbrutt av utsender. Dette kan være fordi det ble sendt til feil person, eller at det er blitt sendt et nytt skjema for utfylling.",
                "sv_SE": "Dokumentsigneringen avbröts av avsändaren. Det kan bero på att det skickats till fel person, eller för att ett nytt formulär har skickats för ifyllning.",
            },
            "landing-contact": {
                "en_EN": "If you have any questions, please contact ",
                "nb_NO": "Hvis du har noen spørsmål, kontakt ",
                "sv_SE": "Om du har några frågor, vänligen kontakta ",
            },
            "landing-contact-on": {
                "en_EN": " on ",
                "nb_NO": " på ",
                "sv_SE": " på ",
            },
            "landing-contact-please": {
                "en_EN": "In case of any inquiries, we appreciate that you include the document name and ID",
                "nb_NO": "Ved eventuelle henvendelser setter vi pris på at du inkluderer dokumentnavnet og ID",
                "sv_SE": "Vid eventuella förfrågningar uppskattar vi att du inkluderar dokumentets namn och ID",
            },
            "landing-contact-id": {
                "en_EN": "ID",
                "nb_NO": "ID",
                "sv_SE": "ID",
            },
            "landing-contact-name": {
                "en_EN": "Name",
                "nb_NO": "Navn",
                "sv_SE": "Namn",
            },
            "landing-filled": {
                "en_EN": "Form was filled out",
                "nb_NO": "Skjema er fylt ut",
                "sv_SE": "Formuläret fylldes i",
            },
            "landing-filled-hint": {
                "en_EN": "The form was filled out, but has not yet been signed.",
                "nb_NO": "Skjema er allerede fylt ut, men ikke signert enda. Hvis du har problemer med å signere kan du prøve å endre signeringsmetode.",
                "sv_SE": "Skjema är redan fylt ut, men inte signert enda. Om du har problem med att signera kan du prova att ändra signeringsmetoden.",
            },
            "landing-awaiting-signing": {
                "en_EN": "Awaiting signing by",
                "nb_NO": "Avventer signering av",
                "sv_SE": "Väntar på signering",
            },
            "landing-selected-method": {
                "en_EN": "Selected signing method",
                "nb_NO": "Valgt signeringsmetode",
                "sv_SE": "Vald signeringsmetod",
            },
            "landing-goto-signing": {
                "en_EN": "Go to signing",
                "nb_NO": "Gå til signering",
                "sv_SE": "Gå till signering",
            },
            "landing-change-method": {
                "en_EN": "Change signing method",
                "nb_NO": "Endre signeringsmetode",
                "sv_SE": "Ändra signeringsmetod",
            },
            "landing-welcome": {
                "en_EN": "Document signing",
                "nb_NO": "Dokumentsignering",
                "sv_SE": "Dokumentsignering",
            },
            "landing-welcome-hint": {
                "en_EN": "You have received a form for signing. If you don't have the selected signing method available you can change it here.",
                "nb_NO": "Du har mottatt et nytt dokument for signering. Hvis du ikke har den valgte signeringsmetoden tilgjengelig, kan du endre den her.",
                "sv_SE": "Du har fått ett formulär för underskrift. Om du inte har den valda signeringsmetoden tillgänglig kan du ändra den här.",
            },
            "landing-signer-hint": {
                "en_EN": "You will be able to review the document before signing.",
                "nb_NO": "Du vil få mulighet til å se over dokumentet før signering.",
                "sv_SE": "Du kommer att kunna granska dokumentet innan du skriver under.",
            },
            "landing-select-method": {
                "en_EN": "Select signing method",
                "nb_NO": "Velg signeringsmetode",
                "sv_SE": "Välj signeringsmetod",
            },


            "clients-last-event": {
                "en_EN": "Last event",
                "nb_NO": "Siste hendelse",
                "sv_SE": "Senaste händelsen",
            },
            "clients-more-event": {
                "en_EN": "more event",
                "nb_NO": "til hendelse",
                "sv_SE": "til händelse",
            },
            "clients-more-events": {
                "en_EN": "more events",
                "nb_NO": "til hendelser",
                "sv_SE": "til händelser",
            },
            "clients-events-show": {
                "en_EN": "Show all events",
                "nb_NO": "Vis alle hendelser",
                "sv_SE": "Visa alla händelser",
            },
            "clients-events-hide": {
                "en_EN": "Hide events",
                "nb_NO": "Skjul hendelser",
                "sv_SE": "Dölj händelser",
            },


            "archive-action": {
                "en_EN": "Archive customer",
                "nb_NO": "Arkiver kunde",
                "sv_SE": "Arkivera kunden",
            },
            "archive-hint": {
                "en_EN": "The customer will be archived with the current final report, and you will be able start a new process.",
                "nb_NO": "Kunden vil bli arkivert med gjeldende sluttrapport, og du vil kunne starte en ny prosess.",
                "sv_SE": "Kunden kommer att arkiveras med den aktuella slutrapporten och du kommer att kunna starta en ny process.",
            },
            "archive-steps": {
                "en_EN": "The following steps will be reset",
                "nb_NO": "Følgende steg vil tilbakestilles",
                "sv_SE": "Följande steg kommer att återställas",
            },
            "archive-progress": {
                "en_EN": "Archiving final report",
                "nb_NO": "Arkiverer sluttrapport",
                "sv_SE": "Arkiverar slutrapporten",
            },
            "archive-resetting": {
                "en_EN": "Resetting",
                "nb_NO": "Tilbakestiller",
                "sv_SE": "Återställer",
            },


            "clients-hits-none": {
                "en_EN": "No hits",
                "nb_NO": "Ingen treff",
                "sv_SE": "Inga träffar",
            },
            "clients-hits-pep": {
                "en_EN": "Hits on PEP",
                "nb_NO": "Treff på PEP",
                "sv_SE": "Träffar på PEP",
            },
            "clients-hits-sanction": {
                "en_EN": "Hits on sanction",
                "nb_NO": "Treff på sanksjon",
                "sv_SE": "Träffar på sanktion",
            },
            "clients-hits-both": {
                "en_EN": "Hits on PEP and sanction",
                "nb_NO": "Treff på PEP og sanksjon",
                "sv_SE": "Träffar på PEP och sanktion",
            },


            "clients-new": {
                "en_EN": "New customer",
                "nb_NO": "Ny kunde",
                "sv_SE": "Ny kund",
            },
            "clients-new-hint": {
                "en_EN": "Add a new customer to the portal",
                "nb_NO": "Legg til en ny kunde",
                "sv_SE": "Lägg till en ny kund",
            },
            "clients-report": {
                "en_EN": "Create report",
                "nb_NO": "Lag rapport",
                "sv_SE": "Skapa rapport",
            },
            "clients-report-hint": {
                "en_EN": "Create a report from this selection",
                "nb_NO": "Lag en rapport med dette utvalget",
                "sv_SE": "Skapa en rapport med det här urvalet",
            },


            "client-edit": {
                "en_EN": "Edit client",
                "nb_NO": "Rediger kunde",
                "sv_SE": "Redigera kund",
            },


            "company-information": {
                "en_EN": "Company information",
                "nb_NO": "Informasjon om foretaket",
                "sv_SE": "Företagsinformation",
            },
            "company-sanction-result": {
                "en_EN": "Sanction search result",
                "nb_NO": "Sanksjonssøk",
                "sv_SE": "Sanktionsansökan",
            },


            "persons-add-manual": {
                "en_EN": "Add manually",
                "nb_NO": "Legg til manuelt",
                "sv_SE": "Lägg till manuellt",
            },
            "persons-add-manual-hint": {
                "en_EN": "Add a person manually to this company",
                "nb_NO": "Legg til en person manuelt i dette selskapet",
                "sv_SE": "Lägg till en person manuellt till detta företag",
            },
            "persons-keyrole-add": {
                "en_EN": "Add person",
                "nb_NO": "Legg til person",
                "sv_SE": "Lägg till person",
            },
            "persons-keyrole-add-hint": {
                "en_EN": "This person will be added to key roles",
                "nb_NO": "Denne personen vil bli lagt til i nøkkelroller",
                "sv_SE": "Denna person kommer att läggas till i nyckelroller",
            },
            "persons-keyrole-remove": {
                "en_EN": "Remove person",
                "nb_NO": "Ta bort person",
                "sv_SE": "Ta bort personen",
            },
            "persons-keyrole-remove-hint": {
                "en_EN": "This person will be removed from key roles",
                "nb_NO": "Denne personen vil bli tatt bort fra nøkkelroller",
                "sv_SE": "Den här personen kommer att tas bort från nyckelroller",
            },
            "persons-role-remove": {
                "en_EN": "Remove role",
                "nb_NO": "Fjern rollen",
                "sv_SE": "Ta bort roll",
            },
            "persons-role-remove-hint": {
                "en_EN": "The following role will be removed from the person",
                "nb_NO": "Følgende rolle vil bli fjernet fra personen",
                "sv_SE": "Följande roll kommer att tas bort från personen",
            },
            "persons-duplicate-checking": {
                "en_EN": "Checking for duplicate persons",
                "nb_NO": "Ser etter duplikate personer",
                "sv_SE": "Letar efter dubbletter",
            },
            "persons-duplicate-none": {
                "en_EN": "No duplicate persons found",
                "nb_NO": "Fant ingen duplikater",
                "sv_SE": "Inga dubbletter hittades",
            },
            "persons-duplicate-found-1": {
                "en_EN": "duplicate found",
                "nb_NO": "duplikat funnet",
                "sv_SE": "dubblett hittades",
            },
            "persons-duplicate-found-2": {
                "en_EN": "duplicates found",
                "nb_NO": "duplikater funnet",
                "sv_SE": "dubbletter hittade",
            },
            "persons-merge": {
                "en_EN": "Merge persons",
                "nb_NO": "Slå sammen personer",
                "sv_SE": "Slå samman personer",
            },
            "persons-merge-companies": {
                "en_EN": "Companies (Customers)",
                "nb_NO": "Selskaper (Kunder)",
                "sv_SE": "Företag (kunder)",
            },
            "persons-merge-hint-1": {
                "en_EN": "The following person will be merged",
                "nb_NO": "Følgende personer vil slås sammen",
                "sv_SE": "Följande person kommer att slås samman",
            },
            "persons-merge-hint-2": {
                "en_EN": "The person will be merged with these duplicate persons",
                "nb_NO": "Person vil bli slått sammen med disse dupliserte personene",
                "sv_SE": "Personen kommer att slås samman med dessa dubbletter",
            },
            "persons-merge-pending-idc": {
                "en_EN": "One of the persons has a pending ID-control and can not be merged until it is completed or aborted!",
                "nb_NO": "En av personene har en ventende ID-kontroll og kan ikke slås sammen før den er fullført eller avbrutt!",
                "sv_SE": "En av personerna har en väntande ID-kontroll och kan inte slås samman förrän den är klar eller avbruten!",
            },
            "persons-list-select": {
                "en_EN": "Select from list",
                "nb_NO": "Velg fra listen",
                "sv_SE": "Välj från listan",
            },
            "persons-list-select-fill-hint": {
                "en_EN": "Select a person to fill information from",
                "nb_NO": "Velg en person å hente informasjon fra",
                "sv_SE": "Välj en person att fylla information från",
            },
            "persons-list-select-fill-empty": {
                "en_EN": "No additional information",
                "nb_NO": "Ingen tilleggsinformasjon",
                "sv_SE": "Ingen ytterligare information",
            },

            "person-loading": {
                "en_EN": "Loading person",
                "nb_NO": "Laster person",
                "sv_SE": "Laddar person",
            },
            "person-deleted": {
                "en_EN": "This person no longer exists",
                "nb_NO": "Denne personen eksisterer ikke lenger",
                "sv_SE": "Denna person finns inte längre",
            },


            "person-report": {
                "en_EN": "Person report",
                "nb_NO": "Personrapport",
                "sv_SE": "Personrapport",
            },
            "person-report-create": {
                "en_EN": "Generate report",
                "nb_NO": "Lag rapport",
                "sv_SE": "Generera rapport",
            },
            "person-report-create-hint": {
                "en_EN": "Generate a PDF-report that can be downloaded",
                "nb_NO": "Lag en PDF-rapport som kan lastes ned",
                "sv_SE": "Skapa en PDF-rapport som kan laddas ner",
            },
            "person-report-roles": {
                "en_EN": "Roles in clients",
                "nb_NO": "Roller i kunder",
                "sv_SE": "Roller hos kunder",
            },
            "person-select-list": {
                "en_EN": "Select from list",
                "nb_NO": "Velg fra listen",
                "sv_SE": "Välj från listan",
            },


            "person-source": {
                "en_EN": "Source",
                "nb_NO": "Kilde",
                "sv_SE": "Källa",
            },
            "person-source-kyc": {
                "en_EN": "Self-declaration",
                "nb_NO": "Egenerklæring",
                "sv_SE": "Kundkännedom",
            },
            "person-source-kyc-add": {
                "en_EN": "Added in self-declaration",
                "nb_NO": "Lagt til i egenerklæring",
                "sv_SE": "Tillagt i kundkännedom",
            },
            "person-source-kyc-confirm": {
                "en_EN": "Company search, confirmed in self-declaration",
                "nb_NO": "Selskapssøk, bekreftet i egenerklæring",
                "sv_SE": "Företagsrannsakan, bekräftad i kundkännedom",
            },
            "person-source-idc": {
                "en_EN": "ID-control",
                "nb_NO": "ID-kontroll",
                "sv_SE": "ID-kontroll",
            },
            "person-source-scr": {
                "en_EN": "Screening",
                "nb_NO": "Personsøk",
                "sv_SE": "Personsök",
            },
            "person-source-org": {
                "en_EN": "Company search",
                "nb_NO": "Selskapssøk",
                "sv_SE": "Företagssökning",
            },
            "person-source-man-add": {
                "en_EN": "Added manually by ",
                "nb_NO": "Lagt til manuelt av ",
                "sv_SE": "Läggs till manuellt av ",
            },
            "person-source-created": {
                "en_EN": "Added when created",
                "nb_NO": "Lagt til under opprettelse",
                "sv_SE": "Lades till när den skapades",
            },
            "person-source-postal": {
                "en_EN": "Postal address",
                "nb_NO": "Postadresse",
                "sv_SE": "Postadress",
            },
            "person-source-national": {
                "en_EN": "National address",
                "nb_NO": "Folkeregistrert adresse",
                "sv_SE": "Nationell adress",
            },

            "details-show-all": {
                "en_EN": "Show all",
                "nb_NO": "Vis alle",
                "sv_SE": "Show all",
            },
            "details-hide": {
                "en_EN": "Hide",
                "nb_NO": "Skjul",
                "sv_SE": "Dölj",
            },
            "details-expand-hint": {
                "en_EN": "Expand to show details",
                "nb_NO": "Utvid for å vise detaljer",
                "sv_SE": "Expandera för att visa detaljer",
            },
            "details-count-empty": {
                "en_EN": "No",
                "nb_NO": "Ingen",
                "sv_SE": "Inga",
            },
            "details-contact": {
                "en_EN": "Contact information",
                "nb_NO": "Kontaktinformasjon",
                "sv_SE": "Kontaktinformation",
            },
            "details-nationality": {
                "en_EN": "Nationality",
                "nb_NO": "Nasjonalitet",
                "sv_SE": "Nationalitet",
            },
            "details-prev-idc": {
                "en_EN": "previous ID-controls",
                "nb_NO": "tidligere ID-kontroller",
                "sv_SE": "tidigare ID-kontroller",
            },
            "details-prev-scr": {
                "en_EN": "previous screenings",
                "nb_NO": "tidligere personsøk",
                "sv_SE": "tidigare sök",
            },
            "details-prev-ams": {
                "en_EN": "previous KYC-searches",
                "nb_NO": "tidligere KYC-søk",
                "sv_SE": "tidigare KYC-sökningar",
            },
            "details-not-completed": {
                "en_EN": "Not completed",
                "nb_NO": "Ikke fullført",
                "sv_SE": "Inte färdigt",
            },
            "details-auth-method": {
                "en_EN": "Authentication method",
                "nb_NO": "Autentiseringsmetode",
                "sv_SE": "Autentiseringsmetod",
            },


            "screening-person": {
                "en_EN": "Person screening",
                "nb_NO": "PEP/Sanksjonssøk",
                "sv_SE": "PEP/Sanktionsansökan",
            },
            "screening-dialog": {
                "en_EN": "Open screening dialog",
                "nb_NO": "Åpne søkeboks",
                "sv_SE": "Öppna sökrutan",
            },
            "screening-parameters": {
                "en_EN": "Search parameters",
                "nb_NO": "Søkeparametere",
                "sv_SE": "Sökparametrar",
            },
            "screening-param-name": {
                "en_EN": "Name/Date of Birth",
                "nb_NO": "Navn/Fødselsdato",
                "sv_SE": "Namn/Födelsedatum",
            },
            "screening-param-ssn": {
                "en_EN": "Social Security Number",
                "nb_NO": "Fødselsnummer",
                "sv_SE": "Personnummer",
            },
            "screening-hint-name": {
                "en_EN": "The lists will be searched with name and date of birth. False positive hits are possible.",
                "nb_NO": "Det vil søkes med navn og fødselsdato. Det kan forekomme falske positive treff.",
                "sv_SE": "Det kommer att sökas med namn och födelsedatum. Falskt positiva träffar kan förekomma.",
            },
            "screening-hint-ssn": {
                "en_EN": "The lists will be searched with the full social security number.",
                "nb_NO": "Det vil søkes med fullt fødselsnummer. Data fra folkeregisteret for norske personer vil være tilgjengelig, hvis det er aktivert.",
                "sv_SE": "Listorna kommer att sökas med hela personnummer.",
            },
            "screening-no-ssn": {
                "en_EN": "Social Security Number not available, perform an ID control first",
                "nb_NO": "Fødselsnummer ikke tilgjengelig, utfør en ID kontroll først",
                "sv_SE": "Personnummer inte tillgängligt, gör en ID-kontroll först",
            },
            "screening-hint-documents": {
                "en_EN": "The following documents will be created",
                "nb_NO": "Følgende dokumenter vil opprettes",
                "sv_SE": "Följande dokument skapas",
            },
            "screening-hint-sources": {
                "en_EN": "The person will be screened against these lists",
                "nb_NO": "Det søkes mot følgende lister",
                "sv_SE": "Följande listor söks",
            },
            "screening-sources": {
                "en_EN": "The person has been screened against these lists",
                "nb_NO": "Det er søkt mot følgende lister",
                "sv_SE": "Följande listor har sökts igenom",
            },
            "screening-perform": {
                "en_EN": "Perform screening",
                "nb_NO": "Utfør personsøk",
                "sv_SE": "Utför personsökning",
            },
            "screening-reset": {
                "en_EN": "Reset screening",
                "nb_NO": "Tilbakestill personsøk",
                "sv_SE": "Återställ personsökning",
            },
            "screening-not-completed": {
                "en_EN": "Not completed",
                "nb_NO": "Ikke fullført",
                "sv_SE": "Ej genomförd",
            },
            "screening-fpositive-header": {
                "en_EN": "Set false positive",
                "nb_NO": "Vurdering av søketreff",
                "sv_SE": "Bedömning av sökträffar",
            },
            "screening-wrong-person": {
                "en_EN": "Wrong person",
                "nb_NO": "Feil person",
                "sv_SE": "Fel person",
            },
            "screening-inactive": {
                "en_EN": "Inactive",
                "nb_NO": "Inaktiv",
                "sv_SE": "Inaktiv",
            },
            "screening-real": {
                "en_EN": "Real hit",
                "nb_NO": "Reelt treff",
                "sv_SE": "Riktig hit",
            },
            "screening-name": {
                "en_EN": "PEP/Sanction-screening",
                "nb_NO": "PEP/Sanksjonsøk",
                "sv_SE": "PEP/Sanction-sökning",
            },
            "screening-tag": {
                "en_EN": "person screening",
                "nb_NO": "personsøk",
                "sv_SE": "personsökning",
            },
            "screening-prog-resetting": {
                "en_EN": "Resetting existing screening",
                "nb_NO": "Tilbakestille eksisterende søk",
                "sv_SE": "Återställ befintlig screening",
            },
            "screening-prog-screening": {
                "en_EN": "Screening person with",
                "nb_NO": "Utfører personsøk med",
                "sv_SE": "Utför personsökning med",
            },
            "screening-outdated": {
                "en_EN": "This search is outdated and must be done again",
                "nb_NO": "Søket er utdatert og må gjøres på nytt",
                "sv_SE": "Denna sökning är föråldrad och måste göras igen",
            },


            "pep-question": {
                "en_EN": "What is a Politically Exposed Person (PEP)?",
                "nb_NO": "Hva er en politisk eksponert person (PEP)?",
                "sv_SE": "Vad är en politiskt utsatt person (PEP)?",
            },
            "pep-click-here": {
                "en_EN": "Click here for explanation",
                "nb_NO": "Trykk her for forklaring",
                "sv_SE": "Klicka här för förklaring",
            },


            "identity-idc": {
                "en_EN": "ID control",
                "nb_NO": "ID kontroll",
                "sv_SE": "ID kontroll",
            },
            "identity-idc-start": {
                "en_EN": "Start ID control",
                "nb_NO": "Start ID kontroll",
                "sv_SE": "Starta ID-kontroll",
            },
            "identity-reset": {
                "en_EN": "Reset ID control",
                "nb_NO": "Tilbakestill ID kontroll",
                "sv_SE": "Återställ ID-kontroll",
            },
            "identity-manual": {
                "en_EN": "Completed manually",
                "nb_NO": "Fullført manuelt",
                "sv_SE": "Slutfört manuellt",
            },
            "identity-manual-hint": {
                "en_EN": "Complete the ID-control manually if this person is already identified outside of the portal.",
                "nb_NO": "Fullfør ID-kontrollen manuelt hvis denne personen er identifisert utenfor portalen.",
                "sv_SE": "Slutför ID-kontrollen manuellt om denna person redan är identifierad utanför portalen.",
            },
            "identity-preparing": {
                "en_EN": "Preparing ID control with Verified",
                "nb_NO": "Gjør klart for ID-kontroll med Verified",
                "sv_SE": "Förbereder ID-kontroll med Verified",
            },
            "identity-verifying": {
                "en_EN": "Verifying your ID",
                "nb_NO": "Verifiserer din ID",
                "sv_SE": "Verifierar ditt ID",
            },
            "identity-sent": {
                "en_EN": "Sent",
                "nb_NO": "Sendt",
                "sv_SE": "Skickat",
            },
            "identity-awaiting": {
                "en_EN": "Awaiting completion",
                "nb_NO": "Avventer fullførelse",
                "sv_SE": "Väntar på färdigställning",
            },


            "report-final": {
                "en_EN": "Final report",
                "nb_NO": "Sluttrapport",
                "sv_SE": "Slutrapport",
            },
            "report-create": {
                "en_EN": "Create final report",
                "nb_NO": "Lag sluttrapport",
                "sv_SE": "Skapa slutrapport",
            },
            "report-create-hint": {
                "en_EN": "Final report will be created",
                "nb_NO": "Sluttrapporten vil bli lagret",
                "sv_SE": "Slutrapporten sparas",
            },
            "report-create-confirm": {
                "en_EN": "I confirm the client is ready",
                "nb_NO": "Jeg bekrefter at kunden er klar",
                "sv_SE": "Jag bekräftar att kunden är redo",
            },
            "report-create-ready": {
                "en_EN": "Ready to create final report",
                "nb_NO": "Klar til å lage sluttrapport",
                "sv_SE": "Redo att göra slutrapport",
            },
            "report-already-created": {
                "en_EN": "Final report already created",
                "nb_NO": "Sluttrapporten er allerede laget",
                "sv_SE": "Slutrapporten har redan gjorts",
            },
            "report-reset": {
                "en_EN": "Reset final report",
                "nb_NO": "Tilbakestill sluttrapporten",
                "sv_SE": "Återställ slutrapporten",
            },
            "report-preview": {
                "en_EN": "Preview report",
                "nb_NO": "Forhåndsvis rapporten",
                "sv_SE": "Förhandsgranska rapporten",
            },
            "report-risk-assessment": {
                "en_EN": "Risk assessment",
                "nb_NO": "Risikovurdering",
                "sv_SE": "Riskbedömning",
            },
            "report-assessment": {
                "en_EN": "Assessment",
                "nb_NO": "Vurdering",
                "sv_SE": "Bedömning",
            },
            "report-comment": {
                "en_EN": "Comment",
                "nb_NO": "Kommentar",
                "sv_SE": "Kommentar",
            },
            "report-no-comment": {
                "en_EN": "OK, no comment",
                "nb_NO": "OK, ingen kommentar",
                "sv_SE": "OK, ingen kommentar",
            },
            "report-locked-tooltip": {
                "en_EN": "Editing is locked after the final report is created",
                "nb_NO": "Redigering er låst etter at sluttrapporten er opprettet",
                "sv_SE": "Redigering är låst efter att slutrapporten skapats",
            },

            "report-report": {
                "en_EN": "Report",
                "nb_NO": "Rapport",
                "sv_SE": "Rapport",
            },
            "report-customer": {
                "en_EN": "Customers",
                "nb_NO": "Kunde",
                "sv_SE": "Kund",
            },
            "report-exporting": {
                "en_EN": "Exporting",
                "nb_NO": "Eksporterer",
                "sv_SE": "Exportera",
            },
            "report-export-CSV-1": {
                "en_EN": "Export CSV",
                "nb_NO": "Eksporter CSV",
                "sv_SE": "Exportera CSV",
            },
            "report-export-CSV-2": {
                "en_EN": "Export CSV",
                "nb_NO": "Eksporter CSV",
                "sv_SE": "Exportera CSV",
            },
            "report-export-CSV-button": {
                "en_EN": "Export CSV",
                "nb_NO": "Eksporter CSV",
                "sv_SE": "Exportera CSV",
            },
            "report-export-CSV-hint": {
                "en_EN": "CSV files can be imported into Excel",
                "nb_NO": "CSV-filer kan importeres til Excel",
                "sv_SE": "CSV -filer kan importeras till Excel",
            },
            "report-data-points": {
                "en_EN": "Data points",
                "nb_NO": "Datapunkter",
                "sv_SE": "Datapunkter",
            },
            "report-report-preview": {
                "en_EN": "Report preview",
                "nb_NO": "Forhåndsvisning",
                "sv_SE": "Förhandsvisning",
            },
            "risk-classification": {
                "en_EN": "Risk classification",
                "nb_NO": "Risikoklassifisering",
                "sv_SE": "Riskklassificering",
            },
            "report-pdf-attachments": {
                "en_EN": "Final report attachments",
                "nb_NO": "Vedlegg til sluttrapporten",
                "sv_SE": "Bilagor till slutrapporten",
            },
            "report-pdf-files": {
                "en_EN": "External files",
                "nb_NO": "Eksterne filer",
                "sv_SE": "Externa filer",
            },
            "report-prog-creating": {
                "en_EN": "Creating final report",
                "nb_NO": "Oppretter sluttrapport",
                "sv_SE": "Skapar slutrapport",
            },
            "report-prog-attachment": {
                "en_EN": "Adding attachment",
                "nb_NO": "Legger til vedlegg",
                "sv_SE": "Lägger till bilaga",
            },
            "report-prog-receipt": {
                "en_EN": "Creating receipt",
                "nb_NO": "Lager kvittering",
                "sv_SE": "Skapar kvitto",
            },
            "report-prog-final": {
                "en_EN": "Finalizing report",
                "nb_NO": "Ferdiggjør rapport",
                "sv_SE": "Slutförar rapport",
            },
            "report-prog-update": {
                "en_EN": "Updating customer",
                "nb_NO": "Oppdaterer kunde",
                "sv_SE": "Uppdaterar kund",
            },
            "report-name": {
                "en_EN": "Final report",
                "nb_NO": "Sluttrapport",
                "sv_SE": "Slutrapport",
            },
            "report-tag": {
                "en_EN": "Final report",
                "nb_NO": "Sluttrapport",
                "sv_SE": "Slutrapport",
            },


            "form-landing-close": {
                "en_EN": "You can now close this tab or visit our",
                "nb_NO": "Du kan nå lukke denne fanen eller gå til vår",
                "sv_SE": "Du kan nu stänga den här fliken eller besöka vår",
            },
            "form-landing-homepage": {
                "en_EN": "home page",
                "nb_NO": "hjemmeside",
                "sv_SE": "hemsida",
            },


            "form-count-1": {
                "en_EN": "form",
                "nb_NO": "skjema",
                "sv_SE": "formulär",
            },
            "form-count-2": {
                "en_EN": "forms",
                "nb_NO": "skjemaer",
                "sv_SE": "formulär",
            },
            "form-new": {
                "en_EN": "New form",
                "nb_NO": "Nytt skjema",
                "sv_SE": "Nytt formulär",
            },
            "form-new-hint": {
                "en_EN": "Click \"New form\" to send out a new one",
                "nb_NO": "Velg \"Nytt skjema\" for å sende ut et nytt",
                "sv_SE": "Välj \"Nytt formulär\" för att skicka ut ett nytt",
            },
            "form-show-all": {
                "en_EN": "Show all forms",
                "nb_NO": "Vis alle skjemaer",
                "sv_SE": "Visa alla formulär",
            },
            "form-hide-all": {
                "en_EN": "Hide forms",
                "nb_NO": "Skjul skjemaer",
                "sv_SE": "Dölj formulär",
            },


            "monitor-cs-title": {
                "en_EN": "Continuous Screening",
                "nb_NO": "Kontinuerlig screening",
                "sv_SE": "Kontinuerlig screening",
            },
            "monitor-active": {
                "en_EN": "Continuous screening active",
                "nb_NO": "Kontinuerlig screening aktiv",
                "sv_SE": "Kontinuerlig screening aktiv",
            },
            "monitor-recent-hit": {
                "en_EN": "Recent monitor hit",
                "nb_NO": "Nylig monitortreff",
                "sv_SE": "Senaste monitorträff",
            },
            "monitor-recent-hits": {
                "en_EN": "Recent monitor hits",
                "nb_NO": "Nylige monitortreff",
                "sv_SE": "Senaste monitorträffar",
            },
            "monitor-recent-hit-hint": {
                "en_EN": "You should do a new screening",
                "nb_NO": "Du bør gjøre et nytt søk",
                "sv_SE": "Du borde göra en ny screening",
            },
            "monitor-person-changed": {
                "en_EN": "Person data has changed after creating final report!",
                "nb_NO": "Persondata er endret etter opprettelse av sluttrapport!",
                "sv_SE": "Persondata har ändrats efter att slutrapporten skapats!",
            },
            "monitor-event-hit": {
                "en_EN": "There was a hit from a monitor on this person",
                "nb_NO": "Det ble et treff fra en monitor på denne personen",
                "sv_SE": "Det blev en träff från en monitor på den här personen",
            },
            "monitor-hits-title": {
                "en_EN": "Hits from monitors",
                "nb_NO": "Treff fra monitorer",
                "sv_SE": "Träffar från monitorer",
            },
            "monitor-hits-empty": {
                "en_EN": "No monitor hits for this person",
                "nb_NO": "Ingen monitortreff for denne personen",
                "sv_SE": "Inga monitorträffar för den här personen",
            },
            "monitor-hits-previous": {
                "en_EN": "previous hits",
                "nb_NO": "tidligere treff",
                "sv_SE": "tidigare träffar",
            },
            "monitor-hit-type": {
                "en_EN": "Hit Type",
                "nb_NO": "Type treff",
                "sv_SE": "Träfftyp",
            },
            "monitor-hit-new-status": {
                "en_EN": "New Status",
                "nb_NO": "Ny status",
                "sv_SE": "Ny status",
            },
            "monitor-hit-active": {
                "en_EN": "Active",
                "nb_NO": "Aktiv",
                "sv_SE": "Aktiv",
            },
            "monitor-hit-inactive": {
                "en_EN": "Inactive",
                "nb_NO": "Inaktiv",
                "sv_SE": "Inaktiv",
            },
            "monitor-hit-description": {
                "en_EN": "Description",
                "nb_NO": "Beskrivelse",
                "sv_SE": "Beskrivning",
            },
            "monitor-hit-notes": {
                "en_EN": "Notes",
                "nb_NO": "Merknader",
                "sv_SE": "Anteckningar",
            },
            "monitor-select": {
                "en_EN": "Select one or more monitors to add this person to",
                "nb_NO": "Velg en eller flere monitore å legge denne person til i",
                "sv_SE": "Välj en eller flera monitorer att lägga denna person till i",
            },
            "monitor-action-add": {
                "en_EN": "This person will be added to",
                "nb_NO": "Denne personen vil bli lagt til i",
                "sv_SE": "Denna person kommer att läggas till i",
            },
            "monitor-action-remove": {
                "en_EN": "This person will be removed from",
                "nb_NO": "Denne personen vil bli fjernet fra",
                "sv_SE": "Denna person kommer att tas bort från",
            },
            "monitor-action-monitor": {
                "en_EN": "monitor",
                "nb_NO": "monitor",
                "sv_SE": "monitor",
            },
            "monitor-action-monitors": {
                "en_EN": "monitors",
                "nb_NO": "monitorer",
                "sv_SE": "monitorer",
            },
            "monitor-update": {
                "en_EN": "Update",
                "nb_NO": "Oppdater",
                "sv_SE": "Uppdatera",
            },
            "monitor-prog-adding": {
                "en_EN": "Adding person to monitors",
                "nb_NO": "Legger til person til i monitorer",
                "sv_SE": "Lägger till person till i monitorer",
            },


            "ams-name": {
                "en_EN": "Adverse media search",
                "nb_NO": "KYC-søk",
                "sv_SE": "KYC-sök",
            },
            "ams-open": {
                "en_EN": "Open search dialog",
                "nb_NO": "Åpne søkeboks",
                "sv_SE": "Öppna sökdialogrutan",
            },
            "ams-count": {
                "en_EN": "Searched against ",
                "nb_NO": "Søkt mot ",
                "sv_SE": "Sökte mot ",
            },
            "ams-keywords": {
                "en_EN": " keywords",
                "nb_NO": " nøkkelord",
                "sv_SE": " nyckelord",
            },
            "ams-not-completed": {
                "en_EN": "Not completed",
                "nb_NO": "Ikke sjekket",
                "sv_SE": "Inte färdigt",
            },
            "ams-ok": {
                "en_EN": "No hits for adverse media",
                "nb_NO": "Ingen treff på KYC-søk",
                "sv_SE": "Inga träffar för KYC-sök",
            },
            "ams-ok-short": {
                "en_EN": "No hits",
                "nb_NO": "Ingen treff",
                "sv_SE": "Inga träffar",
            },
            "ams-unclear-text": {
                "en_EN": "Unclear results for adverse media",
                "nb_NO": "Uklare treff på KYC-søk",
                "sv_SE": "Otydliga resultat för KYC-sök",
            },
            "ams-unclear-short": {
                "en_EN": "Unclear results",
                "nb_NO": "Uklare treff",
                "sv_SE": "Otydliga resultat",
            },
            "ams-hits-text": {
                "en_EN": "Hits for adverse media",
                "nb_NO": "Treff på KYC-søk",
                "sv_SE": "Träffar för KYC-sök",
            },
            "ams-hits-short": {
                "en_EN": "Hits",
                "nb_NO": "Treff",
                "sv_SE": "Träffar",
            },
            "ams-unclear": {
                "en_EN": "Unclear for ",
                "nb_NO": "Uklart for ",
                "sv_SE": "Oklart för ",
            },
            "ams-hit-on": {
                "en_EN": "Hit on ",
                "nb_NO": "Treff på ",
                "sv_SE": "Träffar för ",
            },
            "ams-and": {
                "en_EN": " and ",
                "nb_NO": " og ",
                "sv_SE": " och ",
            },
            "ams-more": {
                "en_EN": " more",
                "nb_NO": " til",
                "sv_SE": " til ",
            },
            "ams-on": {
                "en_EN": " on ",
                "nb_NO": " på ",
                "sv_SE": " på ",
            },
            "ams-comment-hint": {
                "en_EN": "Please comment any findings here",
                "nb_NO": "Kommenter eventuelle funn her",
                "sv_SE": "Vänligen kommentera eventuella fynd här",
            },
            "ams-comment-pdf": {
                "en_EN": "Please comment any findings here",
                "nb_NO": "Kommenter eventuelle funn her",
                "sv_SE": "Vänligen kommentera eventuella fynd här",
            },


            "kar-error": {
                "en_EN": "Failed to verify this account/identity combination",
                "nb_NO": "Kunne ikke validere denne kombinasjonen av eier/kontonummer",
                "sv_SE": "Failed to verify this account/identity combination",
            },


            "pdf-performed-by": {
                "en_EN": "Performed by",
                "nb_NO": "Utført av",
                "sv_SE": "Utförd av",
            }
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------

    

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default v1locale;