<template>
<div>

    <ve-popup :header="'Select option'" @close="$emit('close')">

        <div style="padding: 8px 0px;">
            <vap-item fancy padding20 :fancyIcon="'input-check'" v-for="(option, i) in options" :key="'ks' + i"
                :fancyText="option.key"
                :fancyHint="option.display"
                @clicked="onKeySelected(option.key)"
            />
        </div>

    </ve-popup>

</div>
</template>
<script>
import formHelper from '../formHelper';

export default {
    name: "frmod-key-select",
    props: {
        params: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            options: []
        }
    },
    methods: {
        
        onKeySelected(key) {


            this.$emit('connect', key);
        }

    },
    created() {
        
        console.log('params', this.params);

        for (const i in this.params.fromItem.options) {
            this.options.push({
                key: this.params.fromItem.options[i].key,
                display: this.params.fromItem.options[i].locale[this.$root.locale.key]
            });
        }

    }
}

</script>
<style>


</style>
