<template>
<div class="opr-base" v-on-clickaway="onClickaway">

    <div class="opr-op">
        <div class="opr-top">
            

            <div class="opr-name">
                {{ getOperatorName(operator.type) }}
            </div>

            <div class="opr-o2" v-if="operator.type == 'boolcomp' || operator.type == 'inputcomp'">
                <input v-model="operator.outputValueT" v-if="operator.outputFormat != 'B'" :class="[
                    {'opr-s': operator.outputFormat == 'S' },
                    {'opr-n': operator.outputFormat == 'N' },
                ]" @input="onOutputChanged" />
                <div class="opr-editor-bool opr-b nohl" v-if="operator.outputFormat == 'B'" @click="onChangeOutputBool(true)">
                    {{ operator.outputValueT }}
                </div>
            </div>

            <div class="opr-menu opr-button">
                <vap-icon tiny :icon="'burger'" />
            </div>

            <div class="opr-delete opr-button" @click="$emit('delete')">
                <vap-icon tiny :icon="'delete'" />
            </div>

        </div>

        <div class="opr-bot">

            <div class="opr-operator opr-button" @click="showOperatorList">
                {{ getOperatorDisplay() }}
            </div>

            <div class="opr-list" v-if="opListVisible">
                <div class="opr-list-item" v-for="(item, i) in opListOptions" :key="'opi' + i" @click="onChangeOperator(item)">
                    {{ item.display }}
                </div>
            </div>        

            <div class="opr-editor">
                <input v-model="operator.threshold" />
            </div>

            <div class="opr-output opr-button" v-if="operator.type == 'boolcomp' || operator.type == 'inputcomp'" @click="onChangeOutputFormat" :class="[
                {'opr-s': operator.outputFormat == 'S' },
                {'opr-b': operator.outputFormat == 'B' },
                {'opr-n': operator.outputFormat == 'N' },
            ]">
                {{ operator.outputFormat }}
            </div>

            <div class="opr-o2" v-if="operator.type == 'boolcomp' || operator.type == 'inputcomp'">
                <input v-model="operator.outputValueF" v-if="operator.outputFormat != 'B'" :class="[
                    {'opr-s': operator.outputFormat == 'S' },
                    {'opr-n': operator.outputFormat == 'N' },
                ]" @input="onOutputChanged" />
                <div class="opr-editor-bool opr-b nohl" v-if="operator.outputFormat == 'B'" @click="onChangeOutputBool(false)">
                    {{ operator.outputValueF }}
                </div>
            </div>

            <div class="opr-move-d opr-button">
                <vap-icon tiny :icon="'chev-d'" />
            </div>
            <div class="opr-move-u opr-button">
                <vap-icon tiny :icon="'chev-u'" />
            </div>

        </div>
    </div>
    
    <div class="opr-out" :class="[
        {'opr-s': typeof operator.demoOutput == 'string' },
        {'opr-b': typeof operator.demoOutput == 'boolean' },
        {'opr-n': typeof operator.demoOutput == 'number' },
    ]">

        {{ operator.demoOutput }}

    </div>

    

</div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
    name: 'LogicOperatorRenderer',
    mixins: [clickaway],
    props: {
        operator: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            dialog: {},
            opListVisible: false,
            opListOptions: []
        }
    },
    methods: {

        getOperatorName(type) {
            switch (type) {
                case 'inputcomp': return 'Input Comparison';
                case 'boolcomp': return 'Bool Count Comparison';
                case 'calc': return 'Calculator';
            }
            return 'Unknown operator';
        },

        //#region Output Format -----------------------------------------------------------------------------------------------

        onChangeOutputFormat() {
            if (this.operator.outputFormat == 'S') {
                this.operator.outputFormat = 'B';
            }else if (this.operator.outputFormat == 'B') {
                this.operator.outputFormat = 'N';
            }else {
                this.operator.outputFormat = 'S';
            }
            if (this.operator.outputFormat == 'S') {
                this.operator.outputValueT = 'true';
                this.operator.outputValueF = 'false';
            }else if (this.operator.outputFormat == 'B') {
                this.operator.outputValueT = true;
                this.operator.outputValueF = false;
            }else {
                this.operator.outputValueT = 1;
                this.operator.outputValueF = 0;
            }
            this.$emit('perform');
        },

        onChangeOutputBool(value) {
            if (value) {
                this.operator.outputValueT = !this.operator.outputValueT;
            }else {
                this.operator.outputValueF = !this.operator.outputValueF;
            }
            this.onOutputChanged();
        },

        onOutputChanged() {
            this.$emit('perform');
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Operator List -----------------------------------------------------------------------------------------------

        getOperatorDisplay() {
            switch (this.operator.operator) {
                case 'gt': return '>';
                case 'gte': return '>=';
                case 'is': return '=';
                case 'lt': return '<';
                case 'lte': return '<=';
                case 'add': return '+';
                case 'sub': return '-';
                case 'mul': return '*';
                case 'div': return '/';
            }
            return '?';
        },

        showOperatorList() {
            if (this.opListVisible) {
                this.hideOperatorList();
                return;
            }
            if (this.operator.type == 'calc') {
                this.opListOptions = [
                    { value: 'add', display: '+' },
                    { value: 'sub', display: '-' },
                    { value: 'mul', display: '*' },
                    { value: 'div', display: '/' },
                ];
            }else {
                this.opListOptions = [
                    { value: 'lt', display: '<' },
                    { value: 'lte', display: '<=' },
                    { value: 'is', display: '=' },
                    { value: 'gt', display: '>' },
                    { value: 'gte', display: '>=' },
                ];
            }
            this.opListVisible = true;
        },

        onChangeOperator(item) {
            this.operator.operator = item.value;
            this.hideOperatorList();
            this.$emit('perform');
        },

        hideOperatorList() {
            this.opListVisible = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        

        onClickaway() {
            this.hideOperatorList();
        },

    },
    created() {
        
    }
}
</script>
<style scoped>

.opr-base {
    float: left;
    width: 100%;
}

.opr-op {
    float: left;
    width: 46%;
    height: 44px;
    margin: 4px 0px;
    border-radius: 4px;
    border: solid 1px var(--text-disabled);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.1);
}

.opr-top {
    float: left;
    width: 100%;
    display: flex;
    border-bottom: solid 1px var(--text-disabled);
}

.opr-name {
    flex-grow: 100;
    padding: 2px 5px;
    padding-top: 3px;
    font-size: 14px;
    color: var(--text-title);
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}

.opr-move-d {
    width: 24px;
    flex-shrink: 0;
    border-left: solid 1px var(--text-disabled);
}
.opr-move-u {
    width: 24px;
    flex-shrink: 0;
    border-left: solid 1px var(--text-disabled);
}

.opr-menu {
    width: 24px;
    flex-shrink: 0;
    border-left: solid 1px var(--text-disabled);
}
.opr-delete {
    width: 24px;
    flex-shrink: 0;
    border-left: solid 1px var(--text-disabled);
}


.opr-bot {
    position: relative;
    float: left;
    width: 100%;
    display: flex;
}

.opr-operator {
    flex-shrink: 0;
    width: 32px;
    padding: 0px 5px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-right: solid 1px var(--text-disabled);
    box-sizing: border-box;
}
.opr-editor {
    flex-grow: 100;
    box-sizing: border-box;
}
.opr-editor input {
    border: none;
    width: 100%;
    padding: 2px 5px;
    padding-top: 3px;
    font-size: 14px;
    font-weight: bold;
    box-sizing: border-box;
}
.opr-editor input:focus {
    outline: solid 1px #007910;
}


.opr-output {
    width: 26px;
    flex-shrink: 0;
    padding: 2px 7px;
    padding-top: 3px;
    font-size: 14px;
    font-weight: bold;
    border-left: solid 1px var(--text-disabled);
    box-sizing: border-box;
}
.opr-o2 {
    width: 71px;
    flex-shrink: 0;
    padding-right: 1px;
    border-left: solid 1px var(--text-disabled);
    box-sizing: border-box;
}
.opr-o2 input {
    border: none;
    width: 100%;
    padding: 2px 5px;
    padding-top: 3px;
    font-size: 14px;
    font-weight: bold;
    box-sizing: border-box;
}

.opr-editor-bool {
    width: 100%;
    padding: 2px 5px;
    padding-top: 3px;
    font-size: 14px;
    font-weight: bold;
    box-sizing: border-box;
}

.opr-o2 .opr-s:focus {
    outline: solid 1px #b13707;
}
.opr-o2 .opr-n:focus {
    outline: solid 1px #007910;
}


.opr-button {
    cursor: pointer;
    box-sizing: border-box;
}
.opr-button:hover {
    background-color: var(--item-hover);
}



.opr-out {
    float: left;
    width: 46%;
    height: 26px;
    margin: 14px 0px;
    margin-left: 6%;
    padding: 3px 7px;
    padding-top: 4px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    border: solid 1px var(--text-disabled);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.1);
    box-sizing: border-box;
}


.opr-s {
    color: #b13707;
}
.opr-b {
    color: #6d07b1;
}
.opr-n {
    color: #007910;
}

.opr-list {
    position: absolute;
    top: 20px;
    left: -1px;
    width: 60px;
    min-height: 20px;
    background-color: var(--card-background);
    border: solid 1px var(--text-weak);
    border-bottom: none;
    z-index: 10;
}
.opr-list-item {
    padding: 4px 10px;
    font-weight: 500;
    border-bottom: solid 1px var(--text-weak);
    cursor: pointer;
}
.opr-list-item:hover {
    background-color: var(--item-hover);
}

</style>