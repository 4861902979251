<template>
<div class="vf-app-multi" @click.stop="handleClick" :class="[
    { 'vf-selected' : selected }
]">
    
    <div v-if="designer">
        {{ 'Approver list' }}
    </div>

    <div v-if="!designer">

        <div v-if="reference.approveModel.approvers.length > 0 && !aborted">

            <div style="margin-bottom: 4px; color: var(--text-weak);">
                {{ 'Registrering er allerede godkjent av:' }}
            </div>
            
            <div class="vf-app-multi-item" v-for="(approver, i) in reference.approveModel.approvers" :key="'ama' + i">
                <div>
                    {{ approver.name }}
                </div>
                <div style="margin-left: 6px;">
                    {{ '(' + formatDate('f', approver.date) + ')' }}
                </div>
            </div>

        </div>

        <div v-if="reference.approveModel.approvers.length == 0 && !aborted">

            <div>
                {{ 'Ingen har foreløpig godkjent kunderegistreringen' }}
            </div>
            
        </div>

        <div style="margin-top: 10px; font-style: italic; color: var(--text-weak);" v-if="!aborted">
            {{ 'Kunderegisteringen må godkjennes av 2 personer før den blir fullført' }}
        </div>

        <div v-if="aborted">

            <div>
                <span style="color: var(--val-error);">{{ 'Kunderegistrering ikke godkjent av: ' }}</span>
                <span>{{ aborterName }}</span>
            </div>

            <div style="margin-top: 6px; font-style: italic; color: var(--text-weak);">
                {{ aborterComment }}
            </div>
            
        </div>

    </div>

</div>
</template>

<script>

export default {
    name: 'ApproveMultipleInput',
    components: {
        
    },
    props: {
        cardData: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    data() {
        return {
            aborted: false,
            aborterName: '',
            aborterComment: '',
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

    },
    created() {
        if (this.reference.approveModel && this.reference.approveModel.approvers) {
            for (const i in this.reference.approveModel.approvers) {
                if (!this.reference.approveModel.approvers[i].approved) {
                    this.aborterName = this.reference.approveModel.approvers[i].name;
                    this.aborterComment = this.reference.approveModel.approvers[i].comment;
                    this.aborted = true;
                }
            }
        }
    }
}
</script>
<style scoped>

.vf-app-multi {
    float: left;
    width: 100%;
    box-sizing: border-box;
}


.vf-app-multi-item {
    display: flex;
    font-size: 17px;
    color: var(--text-title);
}



/* Designer / Selection */

.vf-selected {
    outline: solid 2px #238ccc;
}

</style>
