import dataModelV1 from "./dataModelV1";
import aml from '../aml';
const dataNorwayV1 = {

    //#region Searching ---------------------------------------------------------------------------------------------------

    async getBankIdAmlData(root, orgnr) {
        const result = {
            success: false,
            testdata: false,
            data: {},
            reports: []
        };

        if (root.config.company.bamlExpandsV2) {
            result.data = await aml.doAmlCompanySearch3(root, orgnr);
        }else {
            result.data = await aml.doAmlCompanySearch2(root, orgnr);
        }

        console.log('AML DAT', result.data);
        //console.log(JSON.stringify(result.data));

        for (const i in result.data.links.reports) {
            result.reports.push({
                url: result.data.links.reports[i].href,
                contentType: result.data.links.reports[i].contentType,
                type: this.getReportType(result.data.links.reports[i].rel, 'org'),
                name: this.getReportName(result.data.links.reports[i].rel, 'org')
            });
        }

        result.success = true;
        return result;
    },

    getReportType(rel, type) {
        if (rel == 'links.reports.aml') {

            if (type == 'person') {
                return 'baml-person';
            }else {
                return 'baml-org-full';
            }

        }else {
            return 'baml-org-inc';
        }
    },

    getReportName(rel, type, name) {
        if (rel == 'links.reports.aml') {

            if (type == 'person') {
                return 'BankID AML Personrapport ' + name +  '.pdf';
            }else {
                return 'BankID AML Rapport.pdf';
            }

        }else {
            return 'BankID AML Firmaattest.pdf';
        }
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Mapping -----------------------------------------------------------------------------------------------------

    processData(root, output, baml, brreg) {
        console.log('process baml', JSON.parse(JSON.stringify(baml)));
        //console.log('process brreg', JSON.parse(JSON.stringify(brreg)));

        output.source = 'bankidaml';
        output.version = 1;

        // Key information
        if (baml.keyInformation) {
            output.lookup = true;
            output.date = new Date();

            output.name = root.companyTitleCase(baml.keyInformation.name);
            output.orgnr = baml.keyInformation.organizationNumber;

            output.info.country = baml.keyInformation.country;
            output.info.number = baml.keyInformation.organizationNumber;
            output.info.dunsNumber = baml.keyInformation.dunsNumber;
            output.info.dateRegistered = baml.keyInformation.registeredDate;
            output.info.purpose = baml.keyInformation.purpose;
            output.info.employeesFrom = baml.keyInformation.employees.from;
            output.info.employeesTo = baml.keyInformation.employees.to;

            output.contact.phone = baml.keyInformation.phoneNumber;
            for (const i in baml.keyInformation.metadata.sources) {
                output.infoSources.push(baml.keyInformation.metadata.sources[i]);
            }
        }
        
        if (brreg) {
            output.info.language = brreg.language;
            output.info.dateEstablished = brreg.start_date;

            output.form.formCode = brreg.org_form;
            output.form.formDescription = brreg.org_form_desc;
            output.form.industryCode = brreg.industryCode;
            output.form.industryDescription = brreg.industryDescription;

            output.registration.inVatRegistry = brreg.inMvaReg ? 'Y' : 'N';
            output.registration.inBusinessRegistry = brreg.inBusinessReg ? 'Y' : 'N';
            output.registration.inBrreg = brreg.inBrReg ? 'Y' : 'N';
            output.registration.inVoluntaryRegistry = brreg.inVoluntary ? 'Y' : 'N';
            output.registration.bankrupt = brreg.bankrupt ? 'Y' : 'N';
            output.registration.liquidation = brreg.liquidation ? 'Y' : 'N';
            output.registration.compulsoryLiquidation = brreg.compulsoryLiquidation ? 'Y' : 'N';

            output.address.business.country = brreg.country == 'Norge' ? 'NO' : '';
            output.address.business.street = brreg.address;
            output.address.business.zip = brreg.zip;
            output.address.business.city = brreg.town;
            output.address.business.commune = brreg.commune;
            output.address.business.communeNumber = brreg.communeNumber;

            for (const i in brreg.businessCodes) {
                output.naceCodes.push({
                    code: brreg.businessCodes[i].code,
                    description: brreg.businessCodes[i].description,
                });
            }

            output.infoSources.push('BRREG');
            output.naceSources.push('BRREG');
        }
        

        // Signature rights
        if (baml.authorities && baml.authorities.signatureRights) {
            if (baml.authorities.signatureRights.description) {
                const parts = baml.authorities.signatureRights.description.split('. ');
                for (const i in parts) {
                    output.signature.description.push(parts[i]);
                }
            }
            for (const i in baml.authorities.signatureRights.options) {
                const option = { required: [] };
                for (const j in baml.authorities.signatureRights.options[i].required) {
                    const person = baml.authorities.signatureRights.options[i].required[j];
                    const names = this.getBamlNames(person.name);
                    option.required.push({
                        firstName: names.firstName,
                        lastName: names.lastName,
                        dateOfBirth: person.dateOfBirth,
                        roleId: this.getRoleId(person.role)
                    });
                }
                output.signature.options.push(option);
            }
            output.signature.conclusive = baml.authorities.signatureRights.status == 'CONCLUSIVE';
            for (const i in baml.authorities.signatureRights.remarks) {
                output.signature.remarks.push({
                    category: baml.authorities.signatureRights.remarks[i].category,
                    comment: baml.authorities.signatureRights.remarks[i].comment
                });
            }
            for (const i in baml.authorities.metadata.sources) {
                output.signature.sources.push(baml.authorities.metadata.sources[i]);
            }
            output.signature.available = true;
        }

        // Procura
        if (baml.authorities && baml.authorities.powerOfProcuration) {
            if (baml.authorities.powerOfProcuration.description) {
                const parts = baml.authorities.powerOfProcuration.description.split('. ');
                for (const i in parts) {
                    output.procura.description.push(parts[i]);
                }
            }
            for (const i in baml.authorities.powerOfProcuration.options) {
                const option = { required: [] };
                for (const j in baml.authorities.powerOfProcuration.options[i].required) {
                    const person = baml.authorities.powerOfProcuration.options[i].required[j];
                    const names = this.getBamlNames(person.name);
                    option.required.push({
                        firstName: names.firstName,
                        lastName: names.lastName,
                        dateOfBirth: person.dateOfBirth,
                        roleId: this.getRoleId(person.role)
                    });
                }
                output.procura.options.push(option);
            }
            output.procura.conclusive = baml.authorities.powerOfProcuration.status == 'CONCLUSIVE';
            for (const i in baml.authorities.powerOfProcuration.remarks) {
                output.procura.remarks.push({
                    category: baml.authorities.powerOfProcuration.remarks[i].category,
                    comment: baml.authorities.powerOfProcuration.remarks[i].comment
                });
            }
            for (const i in baml.authorities.metadata.sources) {
                output.procura.sources.push(baml.authorities.metadata.sources[i]);
            }
            output.procura.available = true;
        }
        

        // Financials key figures
        if (baml.financials) {
            if (baml.financials.accountant.name) {
                output.economy.accountant.name = baml.financials.accountant.name;
                output.economy.accountant.orgnr = baml.financials.accountant.organizationNumber;
            }
            if (baml.financials.auditor.name) {
                output.economy.auditor.name = baml.financials.auditor.name;
                output.economy.auditor.orgnr = baml.financials.auditor.organizationNumber;
            }
            output.economy.basicAvailable = true;
        }

        // Accounting years
        if (baml.financials && baml.financials.accounting && baml.financials.accounting.years) {
            output.economy.currency = 'NOK';
            const years = JSON.parse(JSON.stringify(baml.financials.accounting.years));
            years.sort((b, a) => (a.year > b.year) ? 1 : -1);
            if (years.length > 0) output.economy.yearAvailable = true;
            if (years.length > 2) output.economy.trendAvailable = true;
            let count = 0;
            for (const i in years) {
                const key = 'year' + count;
                output.economy[key].available = true;
                output.economy[key].year = years[i].year;
                output.economy[key].salesRevenues = this.fillEconomyValue(years[i].incomeStatement.salesRevenues);
                output.economy[key].otherRevenues = this.fillEconomyValue(years[i].incomeStatement.otherRevenues);
                output.economy[key].totalRevenues = this.fillEconomyValue(years[i].incomeStatement.totalRevenues);
                output.economy[key].costOfGoodsSold = this.fillEconomyValue(years[i].incomeStatement.costOfGoodsSold);
                output.economy[key].inventoryChanges = this.fillEconomyValue(years[i].incomeStatement.inventoryChanges);
                output.economy[key].salaryCosts = this.fillEconomyValue(years[i].incomeStatement.salaryCosts);
                output.economy[key].financialCosts = this.fillEconomyValue(years[i].incomeStatement.financialCosts);
                output.economy[key].depreciations = this.fillEconomyValue(years[i].incomeStatement.depreciations);
                output.economy[key].amortizations = this.fillEconomyValue(years[i].incomeStatement.amortizations);
                output.economy[key].otherOperatingCosts = this.fillEconomyValue(years[i].incomeStatement.otherOperatingCosts);
                output.economy[key].operatingProfits = this.fillEconomyValue(years[i].incomeStatement.operatingProfits);
                output.economy[key].ordinaryProfits = this.fillEconomyValue(years[i].incomeStatement.ordinaryProfits);
                output.economy[key].extraordinaryRevenues = this.fillEconomyValue(years[i].incomeStatement.extraordinaryRevenues);
                output.economy[key].extraordinaryCosts = this.fillEconomyValue(years[i].incomeStatement.extraordinaryCosts);
                output.economy[key].minorityInterests = this.fillEconomyValue(years[i].incomeStatement.minorityInterests);
                output.economy[key].taxes = this.fillEconomyValue(years[i].incomeStatement.taxes);
                output.economy[key].resultBeforeTax = this.fillEconomyValue(years[i].incomeStatement.resultBeforeTax);
                output.economy[key].netProfits = this.fillEconomyValue(years[i].incomeStatement.netProfits);

                output.economy[key].sumFinancialIncome = this.fillEconomyValue(years[i].incomeStatement.sumFinancialIncome);
                output.economy[key].sumOtherFinancialIncome = this.fillEconomyValue(years[i].incomeStatement.sumOtherFinancialIncome);

                if (years[i].balanceSheet) {
                    output.economy[key].currentAssets = this.fillEconomyValue(years[i].balanceSheet.currentAssets);
                    output.economy[key].fixedAssets = this.fillEconomyValue(years[i].balanceSheet.fixedAssets);
                    output.economy[key].sumAssets = this.fillEconomyValue(years[i].balanceSheet.sumAssets);
                    output.economy[key].equity = this.fillEconomyValue(years[i].balanceSheet.equity);
                    output.economy[key].debt = this.fillEconomyValue(years[i].balanceSheet.debt);
                    output.economy[key].sumEquityAndDebt = this.fillEconomyValue(years[i].balanceSheet.sumEquityAndDebt);
                }
                count += 1;
            }
        }

        if (baml.ownership) {
            for (const i in baml.ownership.shareholders) {

                const shareholder = dataModelV1.getEmptyShareholder();

                shareholder.type = baml.ownership.shareholders[i].type == 'PERSON' ? 'person' : 'org';
                shareholder.share = baml.ownership.shareholders[i].percentage;

                if (shareholder.type == 'person') {
                    const names = this.getBamlNames(baml.ownership.shareholders[i].name);
                    shareholder.firstName = names.firstName;
                    shareholder.lastName = names.lastName;
                    shareholder.name = names.firstName + ' ' + names.lastName;
                    shareholder.dateOfBirth = baml.ownership.shareholders[i].dateOfBirth;
                }else {
                    shareholder.name = baml.ownership.shareholders[i].name;
                    shareholder.orgnr = baml.ownership.shareholders[i].organizationNumber;
                }
                output.shareholders.push(shareholder);

            }
            for (const i in baml.ownership.beneficial) {

                const beneficial = dataModelV1.getEmptyBeneficial();

                if (baml.ownership.beneficial[i].firstName && baml.ownership.beneficial[i].dateOfBirth) {

                    if (!baml.ownership.beneficial[i].firstName || (baml.ownership.beneficial[i].firstName && baml.ownership.beneficial[i].firstName == baml.ownership.beneficial[i].name)) {
                        const names = this.getBamlNames(baml.ownership.beneficial[i].name);
                        beneficial.firstName = names.firstName;
                        beneficial.lastName = names.lastName;
                    }else {
                        beneficial.firstName = baml.ownership.beneficial[i].firstName;
                        beneficial.lastName = baml.ownership.beneficial[i].lastName;
                    }

                    beneficial.dateOfBirth = baml.ownership.beneficial[i].dateOfBirth;
                    beneficial.share = baml.ownership.beneficial[i].percentageControl;
                    if (baml.ownership.beneficial[i].address) {
                        beneficial.country = baml.ownership.beneficial[i].address.country;
                        beneficial.street = baml.ownership.beneficial[i].address.street;
                        beneficial.zip = baml.ownership.beneficial[i].address.postalCode;
                        beneficial.city = baml.ownership.beneficial[i].address.city;
                    }

                    beneficial.integratedShare = baml.ownership.beneficial[i].integratedOwnership;
                    beneficial.reason = baml.ownership.beneficial[i].reason;
                    beneficial.reasonPhrase = baml.ownership.beneficial[i].reasonPhrase;

                    beneficial.identified = true;
                }else {
                    beneficial.name = baml.ownership.beneficial[i].name;
                    beneficial.share = baml.ownership.beneficial[i].percentageControl;
                }

                output.beneficials.push(beneficial);

            }
        }

        if (baml.ownership && baml.ownership.indicators) {

            output.ownership.keyIndicators.maxShareHolderDistance = baml.ownership.indicators.keyIndicators.maxShareHolderDistance;
            output.ownership.keyIndicators.numberOfShareholdersDirect = baml.ownership.indicators.keyIndicators.numberOfShareholdersDirect;
            output.ownership.keyIndicators.numberOfShareholdersLegal = baml.ownership.indicators.keyIndicators.numberOfShareholdersLegal;
            output.ownership.keyIndicators.numberOfShareholdersTotal = baml.ownership.indicators.keyIndicators.numberOfShareholdersTotal;

            output.ownership.knowledge.numberOfUnidentifiedShareholders = baml.ownership.indicators.knowledge.numberOfUnidentifiedShareholders;
            output.ownership.knowledge.sumIdentifiedShareholders = baml.ownership.indicators.knowledge.sumIdentifiedShareholders;
            output.ownership.knowledge.sumUnidentifiedShareholders = baml.ownership.indicators.knowledge.sumUnidentifiedShareholders;
            output.ownership.knowledge.sumUnknownShareholders = baml.ownership.indicators.knowledge.sumUnknownShareholders;

            output.ownership.selfOwnership.companySelfOwnership = baml.ownership.indicators.selfOwnership.companySelfOwnership;
            output.ownership.selfOwnership.numberOfSelfOwnedShareholders = baml.ownership.indicators.selfOwnership.numberOfSelfOwnedShareholders;

            output.ownership.votingPower.numberOfHighVpLowIntegrated = baml.ownership.indicators.votingPower.numberOfHighVpLowIntegrated;
            output.ownership.votingPower.numberOfShareClasses = baml.ownership.indicators.votingPower.numberOfShareClasses;
            output.ownership.votingPower.numberOfShareholdersManyShareClasses = baml.ownership.indicators.votingPower.numberOfShareholdersManyShareClasses;
            output.ownership.votingPower.shareHoldersHaveMultipleShareClasses = baml.ownership.indicators.votingPower.shareHoldersHaveMultipleShareClasses ? 'Y' : 'N';

            for (const i in baml.ownership.indicators.keyIndicators.ownershipNaceList) {
                output.ownership.naceCodes.push({
                    code: baml.ownership.indicators.keyIndicators.ownershipNaceList[i].code,
                    description: baml.ownership.indicators.keyIndicators.ownershipNaceList[i].title,
                    share: Number(baml.ownership.indicators.keyIndicators.ownershipNaceList[i].sum),
                });
                output.ownership.naceCodes.sort((b, a) => (a.share > b.share) ? 1 : -1);
            }

            output.ownership.indicatorsAvailable = true;
        }


        if (baml.officialRoles) {

            if (baml.officialRoles.ceo && baml.officialRoles.ceo.name) {
                this.addToRoleList(baml.officialRoles.ceo, 'role_ceo', output);
            }
            if (baml.officialRoles.chairman && baml.officialRoles.chairman.name) {
                this.addToRoleList(baml.officialRoles.chairman, 'role_chair', output);
            }
            if (baml.officialRoles.deputyChairman && baml.officialRoles.deputyChairman.name) {
                this.addToRoleList(baml.officialRoles.deputyChairman, 'role_deputy_chair', output);
            }
            if (baml.officialRoles.proprietor && baml.officialRoles.proprietor.name) {
                this.addToRoleList(baml.officialRoles.proprietor, 'role_proprietor', output);
            }
            if (baml.officialRoles.boardMembers && baml.officialRoles.boardMembers.length) {
                baml.officialRoles.boardMembers.forEach((person) => {
                    this.addToRoleList(person, 'role_board_member', output);
                });
            }
            if (baml.officialRoles.deputyBoardMembers && baml.officialRoles.deputyBoardMembers.length) {
                baml.officialRoles.deputyBoardMembers.forEach((person) => {
                    this.addToRoleList(person, 'role_deputy_bm', output);
                });
            }

        }

        if (baml.roles) {

            for (const i in baml.roles) {

                if (baml.roles[i].firstName) {

                    const person = {
                        roleId: this.getRoleId(baml.roles[i].type),
                        firstName: baml.roles[i].firstName,
                        lastName: baml.roles[i].lastName,
                        dateOfBirth: baml.roles[i].dateOfBirth,
                    };

                    if (baml.roles[i].address) {
                        const adr = baml.roles[i].address;
                        person.street = adr.street;
                        person.zip = adr.postalCode;
                        person.city = adr.city;
                        person.country = adr.country;
                    }

                    output.roles.push(person);

                }

                

            }

        }


        if (baml.sanction) {

            output.sanction.available = true;

            output.sanction.message = baml.sanction.message;

            if (baml.sanction.list) {
                for (const i in baml.sanction.list) {
                    output.sanction.list.push(baml.sanction.list[i]);
                }
            }

            if (baml.sanction.sanctionResults && baml.sanction.sanctionResults.length > 0) {

                output.sanction.hit = true;

                output.sanction.summary.hits = baml.sanction.sanctionResults.length;

            }

        }

        

        //console.log('output', output);
    },

    processPerson(root, output, data, params) {
        //console.log('input', data);

        output.source = 'bankidaml';
        output.version = 1;
        output.done = true;

        const person = data.personData;

        output.searchParams.firstName = params.firstName;
        output.searchParams.lastName = params.lastName;
        output.searchParams.dateOfBirth = params.dateOfBirth;
        output.searchParams.ssn = params.ssn;
        output.searchParams.countryOfSsn = params.countryOfSsn;
        output.searchParams.mode = params.mode;
        output.searchParams.parameters = params.parameters;

        output.dateOfBirth = params.dateOfBirth;
        output.ssn = params.ssn;
        output.countryOfSsn = params.countryOfSsn;

        if (person.keyInformation) {

            output.name = person.keyInformation.name;
            output.firstName = person.keyInformation.firstName;
            output.lastName = person.keyInformation.lastName;
            output.gender = person.keyInformation.gender;

            if (person.keyInformation.birthPlace) {
                output.birthplace.country = person.keyInformation.birthPlace.countryCode;
                output.birthplace.city = person.keyInformation.birthPlace.location;
                output.birthplace.available = true;
            }
            
            if (person.keyInformation.citizenship) {
                for (const i in person.keyInformation.citizenship) {
                    const citizenship = dataModelV1.getEmptyCitizenship();
                    citizenship.country = person.keyInformation.citizenship[i].countryCode;
                    output.citizenships.push(citizenship);
                }
            }

        }

        if (person.address) {

            if (person.address.postal) {
                output.addressPostal.available = true;
                output.addressPostal.street = person.address.postal.address;
                output.addressPostal.zip = person.address.postal.postalcode;
                output.addressPostal.city = person.address.postal.city;
                output.addressPostal.country = person.address.postal.country;
                output.addressPostal.residents = person.address.postal.numberOfResidents ? person.address.postal.numberOfResidents.residents : 0;
                output.addressPostal.houseType = person.address.postal.numberOfResidents ? person.address.postal.numberOfResidents.houseType : '';
                output.addressPostal.registeredDate = new Date().toISOString().substring(0, 16).replace('T', ' ');
                let meta = person.address.postal.metaData;
                if (meta && meta.sources && meta.sources.length) {
                    output.addressPostal.source = meta.sources[0];
                }
            }

            if (person.address.national) {
                output.addressNational.available = true;
                output.addressNational.street = person.address.national.address;
                output.addressNational.zip = person.address.national.postalcode;
                output.addressNational.city = person.address.national.city;
                output.addressNational.country = person.address.national.country;
                output.addressNational.residents = person.address.national.numberOfResidents ? person.address.national.numberOfResidents.residents : 0;
                output.addressNational.houseType = person.address.national.numberOfResidents ? person.address.national.numberOfResidents.houseType : '';
                output.addressNational.registeredDate = new Date().toISOString().substring(0, 16).replace('T', ' ');
                let meta = person.address.national.metaData;
                if (meta && meta.sources && meta.sources.length) {
                    output.addressNational.source = meta.sources[0];
                }
            }
            
            if (person.address.historic) {
                for (const i in person.address.historic) {
                    const address = dataModelV1.getEmptyPersonAddress();
                    address.available = true;
                    address.street = person.address.historic[i].address;
                    address.zip = person.address.historic[i].postalcode;
                    address.city = person.address.historic[i].city;
                    address.country = person.address.historic[i].country;
                    address.registeredDate = person.address.historic[i].registeredDate;
                    let meta = person.address.historic[i].metaData;
                    if (meta && meta.sources && meta.sources.length) {
                        address.source = meta.sources[0];
                    }
                    output.historicAddresses.push(address);
                }
            }

        }

        if (person.roles) {

            output.roles.available = true;
            output.roles.sources = person.roles.metadata.sources;

            if (person.roles.organizationRoles) {
                for (const i in person.roles.organizationRoles) {
                    const role = dataModelV1.getEmptyPersonRole();
                    role.name = person.roles.organizationRoles[i].organizationName;
                    role.orgnr = person.roles.organizationRoles[i].organizationNumber;
                    role.roleId = this.getRoleId(person.roles.organizationRoles[i].type);
                    role.from = person.roles.organizationRoles[i].accession;
                    output.roles.companies.push(role);
                }
            }

        }

        if (person.aml) {

            output.sources = person.aml.metaData.sources;

            if (person.aml.pepResults && person.aml.pepResults.length > 0) {

                output.isPep = true;
                output.pepSummary.hits = person.aml.pepResults.length;

                for (const i in person.aml.pepResults) {
                    const pep = dataModelV1.getEmptyPepHit();

                    pep.source = person.aml.pepResults[i].source;
                    pep.provider = person.aml.pepResults[i].provider;

                    pep.matchIndicator = person.aml.pepResults[i].matchIndicator;
                    pep.matchDescription = person.aml.pepResults[i].matchIndicatorDescription;

                    pep.name = person.aml.pepResults[i].name;
                    pep.title = person.aml.pepResults[i].title;

                    output.pepHits.push(pep);

                    output.pepSummary.sources.push(person.aml.pepResults[i].source);
                    if (person.aml.pepResults[i].matchIndicator > output.pepSummary.matchIndicator) {
                        output.pepSummary.matchIndicator = person.aml.pepResults[i].matchIndicator;
                        output.pepSummary.matchDescription = person.aml.pepResults[i].matchIndicatorDescription;
                        output.pepSummary.title = person.aml.pepResults[i].title;
                    }
                }

            }

            if (person.aml.sanctionResults && person.aml.sanctionResults.length > 0) {

                output.hasSanction = true;
                output.sanctionSummary.hits = person.aml.sanctionResults.length;

                for (const i in person.aml.sanctionResults) {
                    const sanction = dataModelV1.getEmptySanctionHit();

                    sanction.source = person.aml.sanctionResults[i].source;
                    sanction.provider = person.aml.sanctionResults[i].provider;

                    sanction.matchIndicator = person.aml.sanctionResults[i].matchIndicator;
                    sanction.matchDescription = person.aml.sanctionResults[i].matchIndicatorDescription;

                    sanction.name = person.aml.sanctionResults[i].name;
                    sanction.title = person.aml.sanctionResults[i].title || '';
                    sanction.comment = person.aml.sanctionResults[i].comment;
                    if (!sanction.comment) sanction.comment = sanction.title;

                    output.sanctionHits.push(sanction);
                    
                    output.sanctionSummary.sources.push(person.aml.sanctionResults[i].source);
                    if (person.aml.sanctionResults[i].matchIndicator > output.sanctionSummary.matchIndicator) {
                        output.sanctionSummary.matchIndicator = person.aml.sanctionResults[i].matchIndicator;
                        output.sanctionSummary.matchDescription = person.aml.sanctionResults[i].matchIndicatorDescription;
                    }
                    if (person.aml.sanctionResults[i].source == 'UN_CONSOLIDATED') {
                        let str = person.aml.sanctionResults[i].comment ? person.aml.sanctionResults[i].comment : person.aml.sanctionResults[i].title;
                        if (str.length > 100) str = str.substring(0, 97) + '...';
                        output.sanctionSummary.title = str;
                    }else {
                        output.sanctionSummary.title = person.aml.sanctionResults[i].title;
                    }
                }

            }

        }
        
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------

    addToRoleList(person, roleId, output) {
        //console.log('merging ' + roleId, person);

        if (person.name == 'Fratrådt') return;

        const role = dataModelV1.getEmptyRole();
        role.roleId = roleId;
        if (!person.firstName || (person.firstName && person.firstName == person.name)) {
            const names = this.getBamlNames(person.name);
            role.firstName = names.firstName;
            role.lastName = names.lastName;
        }else {
            role.firstName = person.firstName;
            role.lastName = person.lastName;
        }
        role.dateOfBirth = person.dateOfBirth;

        output.roles.push(role);
    },

    fillEconomyValue(input) {
        return input;
    },

    getBamlNames(name) {
        let firstName = '';
        let lastName = '';
        if (name.includes(',')) {
            const parts = name.split(', ');
            firstName = parts[1];
            lastName = parts[0];
        }else {
            firstName = (name).split(' ').slice(1).join(' ');
            lastName = (name).split(' ')[0];
        }
        return {
            firstName: this.titleCase(firstName),
            lastName: this.titleCase(lastName)
        };
    },

    titleCase(str) {
        try {
            return str.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
        }catch (ex) {
            return str;
        }
    },

    getRoleId(role) {
        switch (role) {
            case 'CEO': return 'role_ceo';
            case 'CHAIRMAN_OF_THE_BOARD': return 'role_chair';
            case 'BOARD_MEMBER': return 'role_board_member';
            case 'DEPUTY_BOARD_MEMBER': return 'role_deputy_bm';
            case 'ACCOUNTANT': return 'role_accountant';
        }
        return 'role_other';
    }

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default dataNorwayV1;