<template>
<div class="slim-page">

    <vap-form-divider :left="55" :right="45" stickyRight>
        <template v-slot:left>
            <div>
                <div class="amlv1-litm" v-for="(branch, i) in $root.departments" :key="'b' + i">
                    <div class="amlv1-litm-icon">
                        <vap-icon :icon="'company'" />
                    </div>
                    <div class="amlv1-litm-content">
                        <div class="amlv1-litm-name">
                            {{ branch.name }}
                        </div>
                        <div class="amlv1-litm-info">
                            {{ branch.key }}
                        </div>
                    </div>
                    <div class="amlv1-litm-delete">
                        <vap-icon :icon="'delete'" action @clicked="showDeleteDialog(branch)" />
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:right>
            <ActionV1 :icon="'add'" :text="'Add branch'" @clicked="showAddDialog" />
        </template>
    </vap-form-divider>

    <PopupV1 v-if="addDialog.show" :header="'Add branch'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <VFRow>
                <VFGroup c6>
                    <VFLabel desc :text="'Name'" />
                    <VFInput v-model="addDialog.name" />
                </VFGroup>
                <VFGroup c6>
                    <VFLabel desc :text="'Key'" />
                    <VFInput v-model="addDialog.key" />
                </VFGroup>
            </VFRow>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="addDialog.show = false" :disabled="addDialog.working" />
            <VFButton :text="'Add'" @clicked="onAdd" :disabled="!addDialog.valid" :working="addDialog.working" />
        </template>
    </PopupV1>

    <PopupV1 v-if="deleteDialog.show" :header="'Delete partner'" @close="deleteDialog.show = false" :dialog="deleteDialog">
        <template v-slot:content>
            <div>
                {{ 'Are you sure you want to delete this partner?' }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="deleteDialog.show = false" :disabled="deleteDialog.working" />
            <VFButton :text="'Delete'" warning @clicked="onDelete" :working="deleteDialog.working" />
        </template>
    </PopupV1>
    
</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFInput from '../components/form/inputs/VFInput.vue';

import ActionV1 from '../v1ui/ActionV1.vue';

export default {
    name: 'BranchSettings',
    components: {
        PopupV1,
        VFButton,
        VFRow,
        VFGroup,
        VFLabel,
        VFInput,
        ActionV1,
    },
    props: {
        flow: { type: Object, default: null },
    },
    data() {
        return {
            addDialog: { show: false, valid: true, working: false, name: '', key: '' },
            deleteDialog: { show: false, valid: true, working: false, branch: null },
        }
    },
    methods: {
        
        showAddDialog() {
            this.addDialog.show = true;
        },  

        async onAdd() {
            this.addDialog.working = true;

            const result = await v1api.addBranch(this.addDialog);

            console.log('result', result);

            this.$root.departments = result.branches.list;

            this.addDialog.working = false;
            this.addDialog.show = false;
        },

        showDeleteDialog(branch) {
            this.deleteDialog.branch = branch;
            this.deleteDialog.show = true;
        },

        async onDelete() {
            this.deleteDialog.working = true;

            const result = await v1api.deleteBranch(this.deleteDialog.branch.key);

            console.log('result', result);

            this.$root.departments = result.branches.list;

            this.deleteDialog.working = false;
            this.deleteDialog.show = false;
        },

    },
    async created() {
        
    }
}
</script>
<style scoped>

.amlv1-litm {
    float: left;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    padding: 10px 14px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
}

.amlv1-litm-icon {

}

.amlv1-litm-content {
    flex-grow: 100;
}

.amlv1-litm-name {

}

.amlv1-litm-info {

}

.amlv1-litm-delete {

}

</style>