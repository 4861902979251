var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.step)?_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"srv2-step",class:[
        { 'srv2-step-completed srv2-step-progress' : _vm.status == 3 },
        { 'srv2-step-completed' : _vm.status == 5 },
        { 'srv2-step-completed srv2-step-warning' : _vm.status == 8 },
        { 'srv2-step-completed srv2-step-error' : _vm.status == 9 },
        { 'srv2-step-selected' : _vm.selected } ],on:{"click":function($event){$event.stopPropagation();return _vm.onStepClicked($event)}}},[(_vm.step && _vm.step.formList && _vm.form && _vm.status != 0)?_c('div',{staticClass:"srv2-line-bot"}):_vm._e(),_c('div',{staticClass:"srv2-icon"},[(_vm.step.icon)?_c('div',{staticClass:"srv2-icon-icon"},[_c('vap-icon',{attrs:{"icon":_vm.step.icon}})],1):_vm._e(),(!_vm.step.icon)?_c('div',{staticClass:"srv2-icon-number"},[_vm._v(" "+_vm._s((_vm.index + 1))+" ")]):_vm._e()]),_c('div',{staticClass:"srv2-content"},[_c('div',{staticClass:"srv2-header"},[(_vm.step && _vm.step.formList && _vm.form)?_c('span',[_vm._v(_vm._s(_vm.getFormName()))]):_vm._e(),(!(_vm.step && _vm.step.formList && _vm.form))?_c('EditableText',{attrs:{"editable":_vm.$root.credentials.isAdmin,"text":_vm.step.title[_vm.$root.locale.key]},on:{"save":_vm.updateHeader}}):_vm._e()],1),(_vm.status == 0)?_c('div',{staticClass:"srv2-hint"},[_c('EditableText',{attrs:{"editable":_vm.$root.credentials.isAdmin,"text":_vm.step.hint[_vm.$root.locale.key]},on:{"save":_vm.updateHint}})],1):_vm._e(),_vm._l((_vm.subs),function(sub,i){return _c('div',{key:'fss' + i,staticClass:"srv2-status",class:[
                {'srv2-status-progress': sub.status == 3 },
                {'srv2-status-completed': sub.status == 5 },
                {'srv2-status-warning': sub.status == 8 },
                {'srv2-status-error': sub.status == 9 }
            ]},[_c('div',{staticClass:"srv2-ball"}),_c('div',{staticClass:"srv2-status-text"},[_vm._v(" "+_vm._s(sub.text[_vm.$root.locale.key])+" ")])])})],2),(_vm.mainAction)?_c('div',{staticClass:"srv2-button",class:{'srv2-button-disabled': _vm.mainAction.disabled },on:{"click":_vm.onMainAction}},[_c('div',{staticClass:"srv2-button-c"},[_vm._v(" "+_vm._s(_vm.mainAction.text[_vm.$root.locale.key])+" ")]),_c('div',{staticClass:"srv2-button-t"}),(_vm.mainAction.hint && _vm.mainAction.hint[_vm.$root.locale.key])?_c('div',{staticClass:"srv2-button-tt"},[_c('vap-tooltip',{attrs:{"text":_vm.mainAction.hint[_vm.$root.locale.key]}})],1):_vm._e()]):_vm._e(),_c('CardActions',{attrs:{"actions":_vm.actions},on:{"action":_vm.onAction}})],1),(_vm.step && _vm.step.hasCheckout && _vm.form)?_c('SignCheckoutResult',{attrs:{"form":_vm.form},on:{"signature":function($event){return _vm.$emit('signature', { form: $event, formKey: _vm.step.form, step: _vm.step })}}}):_vm._e(),(_vm.step && _vm.step.formList && _vm.form)?_c('StepFormList',{attrs:{"form":_vm.form,"step":_vm.step,"disabled":_vm.client.report.created},on:{"newform":_vm.onAction,"xaction":_vm.onActionX}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }