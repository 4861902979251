<template>
<div class="slim-page" v-if="!loading">

    <ObjectEditor ref="objeditor"
        :mainObject="selectedObject"
        :level="0"
        :name="'OBJECT'"
        :type="'object'"
        isFirst
        initialCollapsed
        @change="hasChanges = true"
    />

</div>
</template>
<script>
import ObjectEditor from '../v1flows/ObjectEditor.vue';

export default {
    name: 'ConfigSettings',
    components: {
        ObjectEditor
    },
    props: {
        flow: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            selectedObject: {},
            hasChanges: false
        }
    },
    methods: {
        
        loadConfiguration() {
            this.selectedObject = JSON.parse(JSON.stringify(this.$root.config));

            delete this.selectedObject.portal.header;
            delete this.selectedObject.portal.logoUrl;
            delete this.selectedObject.portal.logoWidth;
            delete this.selectedObject.portal.logoTop;
            delete this.selectedObject.portal.logoLeft;
            delete this.selectedObject.portal.defaultType;
            delete this.selectedObject.portal.defaultPerson;
            delete this.selectedObject.portal.defaultCompany;
            delete this.selectedObject.portal.roundedButtons;
            delete this.selectedObject.portal.devmodeEnabled;
            delete this.selectedObject.portal.settingsEnabled;
            delete this.selectedObject.portal.confirmActions;
            /* delete this.selectedObject.portal.departments;
            delete this.selectedObject.portal.partners; */
            delete this.selectedObject.portal.reminders;
            delete this.selectedObject.portal.deletePermission;
            delete this.selectedObject.portal.smsNotificationPermission;
            delete this.selectedObject.portal.usev2receipts;
            delete this.selectedObject.portal.defaultSort;
            delete this.selectedObject.portal.filterDateCreated;
            delete this.selectedObject.portal.filterDateUpdated;
            delete this.selectedObject.portal.updateStatus;
            delete this.selectedObject.portal.referenceNumber;
            delete this.selectedObject.portal.useNewIdControl;
            delete this.selectedObject.portal.useNewKyc;
            delete this.selectedObject.portal.useNewSettings;
            delete this.selectedObject.portal.suitablityAssessment;
            delete this.selectedObject.portal.filters;

            delete this.selectedObject.kycs;
            delete this.selectedObject.idcs;
            delete this.selectedObject.pdf;
            delete this.selectedObject.modules;
            delete this.selectedObject.flows;
            delete this.selectedObject.report;
            delete this.selectedObject.integrations;
            delete this.selectedObject.internals;
            delete this.selectedObject.colors;

            /* delete this.selectedObject.branding; */

            delete this.selectedObject._id;
            delete this.selectedObject.type;

            this.loading = false;
            console.log('obj', this.selectedObject);
        },

        getConfiguration() {
            const obj = this.$refs.objeditor.getObject();
            console.log('obj', obj);
            return obj;
        },

    },
    async created() {
        this.loadConfiguration();
    }
}
</script>
<style scoped>



</style>