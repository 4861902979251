import api from '../portalapi';
import kychelper from './kychelper';
import personhelper from './personhelper';
import aml from '../aml';
import log from '../log';
import tools from '../tools';
import dataModel from '../dataModel';
import dataNorway from '../dataNorway';
import dataSweden from '../dataSweden';
import dataUK from '../dataUK';
import dataHelper from '../dataHelper';

const CHANGE_REMOVED = 1;
const CHANGE_ADDED = 2;
const CHANGE_PERCENTAGE = 3;

const clienthelper = {

    checkExistingId: '',
    checkExistingResponse: undefined,

    async loadClient(root, customer, flow, amlCache) {
        const result = {
            success: false,
            customer: customer,
            brregData: {},
            amlData: {},
            kycMetadata: kychelper.getReactiveObject(),
            persons: [],
            sources: { aml: [], kyc: [], pep: [], id: [], manual: [], roles: [], manualRoles: [], person: [], idc: [], search: [], formId: [] },
            counts: { vif: true, searchCount: 0, okCount: 0, pepCount: 0, sanctionCount: 0, sentCount: 0, completeCount: 0 },
            events: [],
            reportData: {},
            archivedReports: [],
            selectedPersonCount: 0
        };

        if (!flow) flow = { key: 'unknown' };

        result.associate = root.getEmployee(customer.userId);

        this.addReportData(root, result, 'total', 'total');
        this.addReportData(root, result, 'comparison', 'comparison');

        // Add created event
        result.events.push({ name: root.__('events_created') + result.associate.name, date: customer.dateCreated });


        if (customer.aml) {
            // Add AML event
            if (flow.key == 'org-uk-chouse') {
                result.events.push({ name: root.__('events_aml_chouse') + root.getEmployeeName(customer.aml.userId), date: customer.aml.searchDate });
            }else {
                result.events.push({ name: root.__('events_aml') + root.getEmployeeName(customer.aml.userId), date: customer.aml.searchDate });
            }
            this.addReportData(root, result, 'aml', customer.aml.envelopeId);
        }


        if (customer.kyc) {
            // Add the sent-event
            result.events.push({ name: root.__('events_kyc_sent') + root.getEmployeeName(customer.kyc.userId), date: customer.kyc.sentDate });

            // Load data from KYC-envelope userdata and get metadata
            const envelope = await root.getEnvelope(customer.kyc.envelopeId);
            kychelper.getMetadata(customer, envelope, result.kycMetadata);
            kychelper.processKYCPersons2(envelope.userdata, result.sources);
            log.v('Client: KYC metadata processed', result.kycMetadata);

            if (result.kycMetadata.completed) {
                // If this customer dont have AML, load the manual data from the KYC
                if (envelope.userdata.manualData.name) result.manualData = envelope.userdata.manualData;
                // Add the completed-event
                result.events.push({ name: root.__('events_kyc_completed') + result.kycMetadata.editor.displayName, date: customer.kyc.completedDate });
                for (const i in result.kycMetadata.signatories) {
                    if (result.kycMetadata.signatories[i].status == 'signed') result.events.push({ name: root.__('events_kyc_signed') + result.kycMetadata.signatories[i].confirmedName, date: result.kycMetadata.signatories[i].date });
                }
            }
            // Add an assessment-object for KYC
            this.addReportData(root, result, 'kyc', customer.kyc.envelopeId);

            // Load aml/brreg data from KYC userdata to save loading time
            if (envelope.userdata.brregData && envelope.userdata.brregData.name) result.brregData = envelope.userdata.brregData;
            if (envelope.userdata.amlData && envelope.userdata.amlData.keyInformation) {
                result.amlData = envelope.userdata.amlData;
                log.s('Client: AML data loaded from KYC-receipt', result.amlData);
            }

            if (result.kycMetadata.signed && !customer.kyc.updated && (customer.status == 'r-rep-a' || customer.status == 'f-kyc-c')) {
                customer = await this.addKYCpersons(root, customer, envelope, result.kycMetadata);
            }

            if (root.config.aml.person.acceptSignature) {
                for (const i in result.kycMetadata.signatories) {
                    if (result.kycMetadata.signatories[i].validId) {
                        const person = {
                            firstName: result.kycMetadata.signatories[i].idData.firstName,
                            lastName: result.kycMetadata.signatories[i].idData.lastName,
                            dateOfBirth: result.kycMetadata.signatories[i].idData.dateOfBirth,
                        };
                        result.sources.formId.push({ person: person, signature: result.kycMetadata.signatories[i] });
                    }
                }
            }
        }


        // Load selected persons
/*         if (customer.people) {
            for (const i in customer.people) {
                if (customer.people[i].search) {
                    // Load AML-data from receipt-envelope userdata
                    const envelope = await root.getEnvelope(customer.people[i].search.envelopeId);
                    // Add to search-list
                    this.onPepSearchCompleted(root, result, {
                        person: envelope.userdata.person,
                        aml: envelope.userdata.aml,
                        date: customer.people[i].search.searchDate,
                        userId: customer.people[i].search.userId
                    });
                }
                if (customer.people[i].invite) {
                    // Load data from receipt-envelope userdata
                    const envelope = await root.getEnvelope(customer.people[i].invite.envelopeId);
                    if (envelope.userdata) {
                        // Add to control-list
                        this.onControlSent(root, result, customer.people[i], envelope, false);
                    }
                }else if (customer.people[i].control && customer.people[i].control.manual) {
                    const data = {
                        identity: customer.people[i].id,
                        control: customer.people[i].control
                    };
                    this.onManualControlSent(root, result, data);
                }
            }
        } */

        if (customer.people) {
            for (const i in customer.people) {
                if (customer.people[i].manual) {
                    result.sources.manual.push(tools.decodePerson(customer.people[i].id));
                }
            }
        }

        if (customer.roles) {
            for (const i in customer.roles) {
                result.sources.roles.push(tools.decodePerson(customer.roles[i]));
            }
        }


        if (customer.report) {
            // Add report event
            result.events.push({ name: root.__('events_report_created') + root.getEmployeeName(customer.report.userId), date: customer.report.reportDate });
        }

        if (flow.key == 'person' || flow.key == 'personLookup') {
            result.sources.person.push({
                person: tools.decodePerson(customer.identity),
                identity: customer.identity,
                contact: customer.contact || undefined
            });
        }


        // Load archived reports
        if (customer.archive && customer.archive.reps) {
            for (const i in customer.archive.reps) {
                result.archivedReports.push({
                    date: customer.archive.reps[i].report.reportDate,
                    risk: customer.archive.reps[i].risk,
                    userId: customer.archive.reps[i].userId,
                    user: root.getEmployee(customer.archive.reps[i].userId),
                    envelopeId: customer.archive.reps[i].report.envelopeId,
                });
                result.events.push({ name: 'Ny kundekontroll påbegynt av ' + root.getEmployeeName(customer.archive.reps[i].archivedBy), date: customer.archive.reps[i].date });
            }
        }


        // Load AML/brreg data
        if (customer && customer.aml) {

            // Load AML/brreg from receipt-envelope userdata if not already loaded from KYC
            // TODO remove true == true when we can trust the KYC data to be unchanged
            if (!result.amlData.keyInformation || true == true) {
                if (amlCache && amlCache.useCache) {
                    result.amlData = amlCache.aml;
                    result.brregData = amlCache.brreg;
                    log.w('Client: AML data loaded from cache', amlCache);
                }else {
                    const envelope = await root.getEnvelope(customer.aml.envelopeId);
                    result.amlData = envelope.userdata.aml;
                    result.brregData = envelope.userdata.brreg;
                    result.trapetsData = envelope.userdata.trapetsData;
                    result.bisnodeData = envelope.userdata.bisnodeData;
                    result.chouseData = envelope.userdata.chouseData;
                    result.companyData = envelope.userdata.companyData;
                    log.w('Client: AML data loaded from AML-receipt', envelope.userdata.aml);
                }
            }

            // Process AML data
            if (customer.flow == 'org-se-trap') {
                /* const companyData = dataModel.getEmptyModel();
                dataSweden.processData(companyData, result.trapetsData, result.bisnodeData);
                result.sources.aml = dataHelper.getPersonList(companyData);
                result.companyData = companyData; */
                result.sources.aml = dataHelper.getPersonList(result.companyData);
            }else if (customer.flow == 'org-uk-chouse') {
                const companyData = dataModel.getEmptyModel();
                dataUK.processData(companyData, result.chouseData);
                result.sources.aml = dataHelper.getPersonList(companyData);
                result.companyData = companyData;
            }else {
                const companyData = dataModel.getEmptyModel();
                dataNorway.processData(root, companyData, result.amlData, result.brregData);
                result.sources.aml = dataHelper.getPersonList(companyData);
            }
            

        }else if (customer.source == 'brreg') {
            // no AML data, load data from brreg instead
            result.brregData = await aml.getBrregFromNumber(customer.identity);
            log.w('Client: data loaded from brreg');
        }

        if (!this.checkExistingResponse || this.checkExistingId != customer._id || true == true) {
            // Check BE for existing searches/controls on any roles from AML and KYC
            const personIds = [];
            for (const key in result.sources) {
                for (const i in result.sources[key]) {
                    let person = result.sources[key][i];
                    if (key == 'pep' || key == 'id' || key == 'person') person = result.sources[key][i].person;
                    if (tools.isValidPerson(person)) {
                        const id = tools.encodePerson(person);
                        if (!personIds.includes(id)) personIds.push(id);
                    }else {
                        log.e('Invalid person', result.sources[key][i]);
                    }
                }
            }
            this.checkExistingId = customer._id;
            this.checkExistingResponse = await api.checkExistingPeople(personIds);
        }
        for (const i in this.checkExistingResponse.people) {
            if (this.checkExistingResponse.people[i].aml) {
                // Load AML-data from receipt-envelope userdata
                const envelope = await root.getEnvelope(this.checkExistingResponse.people[i].aml.envelopeId);
                // Add to search-list
                if (envelope.content.descriptor.id == 'aml-portal-v1-person') {

                    this.onPepSearchCompletedNew(root, result, envelope);

                }else {
                    this.onPepSearchCompleted(root, result, {
                        person: envelope.userdata.person,
                        aml: envelope.userdata.aml,
                        date: this.checkExistingResponse.people[i].aml.searchDate,
                        userId: this.checkExistingResponse.people[i].aml.userId,
                        existing: true
                    });
                }
            }else if (this.checkExistingResponse.people[i].search) {
                // Load AML-data from receipt-envelope userdata
                const envelope = await root.getEnvelope(this.checkExistingResponse.people[i].search.envelopeId);
                // Add to search-list
                this.onPepSearchCompleted(root, result, {
                    person: envelope.userdata.person,
                    aml: envelope.userdata.aml,
                    date: this.checkExistingResponse.people[i].search.searchDate,
                    userId: this.checkExistingResponse.people[i].search.userId,
                    existing: true
                });
            }
            if (this.checkExistingResponse.people[i].idc) {
                if (this.checkExistingResponse.people[i].idc.envelopeId) {
                    const envelope = await root.getEnvelope(this.checkExistingResponse.people[i].idc.envelopeId);
                    this.onControlSentNew(root, result, this.checkExistingResponse.people[i], envelope);
                }else {
                    this.onControlSentNew(root, result, this.checkExistingResponse.people[i], undefined);
                }
            }else if (this.checkExistingResponse.people[i].control) {
                if (!this.checkExistingResponse.people[i].control.manual) {
                    // Load AML-data from receipt-envelope userdata
                    const envelope = await root.getEnvelope(this.checkExistingResponse.people[i].control.envelopeId);
                    // Add to control-list
                    this.onControlSent(root, result, this.checkExistingResponse.people[i], envelope, true);
                }else {
                    // ID control completed outside AML portal
                    this.onManualControlSent(root, result, this.checkExistingResponse.people[i]);
                }
            }else if (this.checkExistingResponse.people[i].invite) {
                // Load AML-data from receipt-envelope userdata
                const envelope = await root.getEnvelope(this.checkExistingResponse.people[i].invite.envelopeId);
                // Add to control-list
                if (envelope.userdata) {
                    this.onControlSent(root, result, this.checkExistingResponse.people[i], envelope, true);
                }else {
                    log.e('Client: Failed to load userdata from envelopeId ' + this.checkExistingResponse.people[i].invite.envelopeId + ' for personId ' + this.checkExistingResponse.people[i].identity);
                }
            }
            if (this.checkExistingResponse.people[i].roles) {
                for (const j in this.checkExistingResponse.people[i].roles) {
                    if (this.checkExistingResponse.people[i].roles[j].customerId == customer._id) {
                        result.sources.manualRoles.push({
                            person: tools.decodePerson(this.checkExistingResponse.people[i].identity),
                            manualRole: this.checkExistingResponse.people[i].roles[j]
                        });
                    }
                }
            }
        }

        result.persons = personhelper.getMergedList(root, result.sources, customer, result.kycMetadata);
        result.selectedPersonCount = 0;
        for (const i in result.persons) {
            if (result.persons[i].selected) result.selectedPersonCount += 1;

            if (result.persons[i].control.inviteSent) {
                result.events.push({ name: root.__('events_id_sent') + result.persons[i].displayName, date: result.persons[i].control.sentDate });
            }
            if (result.persons[i].control.completed) {
                result.events.push({ name: root.__('events_id_completed') + result.persons[i].displayName, date: result.persons[i].control.dateCompleted });
            }
            for (const j in result.persons[i].control.notifications) {
                if (result.persons[i].control.notifications[j].sent && result.persons[i].control.notifications[j].type == 'reminder') {
                    result.events.push({ name: root.__('events_id_reminder') + result.persons[i].displayName, date: result.persons[i].control.notifications[j].sentDate });
                }
            }
        }

        if (customer.metadata && customer.metadata.forwards) {
            for (const i in customer.metadata.forwards) {
                result.events.push({ name: 'Sluttrapport sendt til ' + customer.metadata.forwards[i].email, date: customer.metadata.forwards[i].date });
            }
        }
        if (customer.metadata && customer.metadata.imanage) {
            result.events.push({ name: 'Sluttrapport lagret i iManage av ' + root.getEmployeeName(customer.metadata.imanage.userId), date: customer.metadata.imanage.saveDate });
        }

        // Checklists
        if (customer.checklists && customer.checklists.length > 0) {
            const checklist = customer.checklists[customer.checklists.length - 1];
            let statusText = 'Awaiting screening checks';
            let actorName = 'Screening Team';
            let completed = false;
            if (customer.status == 'g-kyc-k') {
                statusText = 'Awaiting QC Review';
                actorName = 'CDD';
            }
            if (customer.status == 'g-kyc-h') {
                statusText = 'Awaiting info provided';
                actorName = 'Branch';
            }
            if (customer.status == 'g-kyc-o') {
                statusText = 'Checklist completed';
                actorName = '';
                completed = true;
            }

            let qaNeeded = checklist.qaNeeded || false;
            let qaPerformed = false;
            if (completed) {
                if (!checklist.qaNumber) {
                    let number = Math.floor(Math.random() * (100 - 1 + 1) + 1);
                    console.log('CHK number', number);
                    if (number < 50) {
                        qaNeeded = true;
                    }
                    checklist.qaNumber = number;
                    checklist.qaNeeded = qaNeeded;
                    checklist.qaPerformed = false;
                    const data = {
                        checklists: customer.checklists
                    };
                    await api.updateCustomer(customer._id, data);
                }
            }

            result.checklist = {
                sent: true,
                completed: completed,
                data: checklist,
                envelope: null,
                statusText: statusText,
                actorName: actorName,
                qaNeeded: qaNeeded
            };
        }

        this.loadReportPersons(root, result, this.checkExistingResponse);
        this.renderReportData(result, customer);

        result.success = true;
        return result;
    },

    async addKYCpersons(root, customer, envelope, kycMetadata) {
        if (!envelope.userdata.amlData) {
            return customer;
        }
        const personIds = [];
        if (customer.flow == 'org-no-baml') {
            const companyData = dataModel.getEmptyModel();
            dataNorway.processData(root, companyData, envelope.userdata.amlData, envelope.userdata.brregData);
            const amlPersons = dataHelper.getPersonList(companyData);
            log.v('AML: Auto-adding persons from the person list', amlPersons);
            for (const i in amlPersons) {
                //if (amlPersons[i].resigned) log.e('BankID: Resigned person detected');
                const personId = tools.encodePerson(amlPersons[i]);
                for (const j in amlPersons[i].roleIds) {
                    if (root.config.aml.autoRoles.includes(amlPersons[i].roleIds[j]) && !amlPersons[i].resigned) {
                        personIds.push(personId);
                        log.v('AML: Auto-added ' + amlPersons[i].firstName + ' ' + amlPersons[i].lastName);
                    }
                }
            }
        }
        const existingIds = [];
        if (customer.roles) {
            for (const i in customer.roles) {
                existingIds.push(customer.roles[i]);
            }
        }
        if (customer.people) {
            for (const i in customer.people) {
                existingIds.push(customer.people[i].id);
            }
        }
        const addPersons = [];
        const removePersons = [];
        if (customer.flow == 'org-no-baml' && kycMetadata && kycMetadata.changes) {
            for (const i in kycMetadata.changes.items) {
                const item = kycMetadata.changes.items[i];
                if (item.type == 'rrh') {
                    if (item.change == CHANGE_ADDED) {
                        personIds.push(item.data.identity);
                    }else if (item.change == CHANGE_REMOVED) {
                        this.removeOneRole(personIds, item.data.identity);
                        removePersons.push(item.data.identity);
                    }
                }else if (item.type == 'ceo' || item.type == 'chair') {
                    if (item.change == CHANGE_ADDED) {
                        personIds.push(item.data.identity);
                    }else if (item.change == CHANGE_REMOVED) {
                        this.removeOneRole(personIds, item.data.identity);
                        removePersons.push(item.data.identity);
                    }
                }else if (item.type == 'sh') {
                    console.log('shitem', item);
                    if (item.data.type == 'PERSON' && item.data.percentage > 25) {
                        if (item.change == CHANGE_ADDED) {
                            personIds.push(item.data.identity);
                        }else if (item.change == CHANGE_REMOVED) {
                            this.removeOneRole(personIds, item.data.identity);
                            removePersons.push(item.data.identity);
                        }
                    }
                }
            }
        }
        if (customer.flow != 'org-no-baml') {
            for (const i in envelope.userdata.roles) {
                //console.log('role', envelope.userdata.roles[i]);
                if (root.config.aml.autoRoles.includes(envelope.userdata.roles[i].roleId)) {
                    personIds.push(tools.encodePerson(envelope.userdata.roles[i]));
                    //log.v('AML: Auto-added ' + amlPersons[i].firstName + ' ' + amlPersons[i].lastName);
                }
            }
            if (envelope.userdata.ownerQuestion.hasOwners) {
                for (const i in envelope.userdata.rrhs) {
                    if (root.config.aml.autoRoles.includes('screening_role_rrh')) {
                        personIds.push(tools.encodePerson(envelope.userdata.rrhs[i]));
                        //log.v('AML: Auto-added ' + amlPersons[i].firstName + ' ' + amlPersons[i].lastName);
                    }
                }
            }
        }
        for (const i in removePersons) {
            for (const j in personIds) {
                if (personIds[j] == removePersons[i]) {
                    removePersons.splice(i, 1);
                    break;
                }
            }
        }
        for (const i in personIds) {
            let match = false;
            for (const j in existingIds) {
                if (existingIds[j] == personIds[i]) match = true;
            }
            if (!match) {
                addPersons.push(personIds[i]);
            }
        }
        const remove = [...new Set(removePersons)];
        const add = [...new Set(addPersons)];

        log.x('KYC: Updating selected persons after KYC completion');
        for (const i in remove) {
            const person = tools.decodePerson(remove[i]);
            log.x('KYC: Removing ' + person.firstName + ' ' + person.lastName);
        }
        for (const i in add) {
            const person = tools.decodePerson(add[i]);
            log.x('KYC: Adding ' + person.firstName + ' ' + person.lastName);
        }

        const result = await api.updateAfterKYC(customer._id, remove, add);
        console.log('resultcustomer', result.customer.roles);
        return result.customer;
        /* return customer; */
    },

    removeOneRole(personIds, identity) {
        for (const i in personIds) {
            if (personIds[i] == identity) {
                personIds.splice(i, 1);
                return;
            }
        }
    },

    addReportData(root, result, type, envelopeId, existingKeywords) {
        const reportObject = { type: type, ok: false, comment: '', falsePositive: false, searchComplete: false, keywords: {}, locked: false, searchDate: new Date(), userId: root.credentials.uid };
        if (root.config.report.keywordsEnabled) {
            for (const i in root.config.report.keywords) {
                if (existingKeywords) {
                    reportObject.keywords[root.config.report.keywords[i]] = existingKeywords.words[root.config.report.keywords[i]] || false;
                }else {
                    reportObject.keywords[root.config.report.keywords[i]] = false;
                }
            }
            if (existingKeywords) {
                reportObject.locked = true;
                reportObject.searchComplete = true;
                reportObject.ok = existingKeywords.ok;
                reportObject.comment = existingKeywords.comment;
                reportObject.userId = existingKeywords.userId;
            }
        }
        root.$set(result.reportData, envelopeId, reportObject);
    },

    loadReportPersons(root, result, existingPersons) {
        for (const i in result.persons) {
            let existingKeywords = undefined;
            for (const j in existingPersons.people) {
                if (existingPersons.people[j].identity == result.persons[i].id) {
                    existingKeywords = existingPersons.people[j].keywords || undefined;
                }
            }
            if (result.persons[i].selected) {
                if (!result.reportData[result.persons[i].id]) this.addReportData(root, result, 'per', result.persons[i].id, existingKeywords);
            }
        }
    },

    renderReportData(result, customer) {
        for (const eid1 in result.reportData) {
            for (const eid2 in customer.rdata) {
                if (eid1 == eid2) result.reportData[eid1] = customer.rdata[eid2];
            }
        }
    },

    onPepSearchCompleted(root, result, data) {
        // Add to search-list
        result.sources.pep.push({ aml: data.aml, person: data.person, date: data.date, fpositive: false, comment: '', envelopeId: data.person.envelopeId, date: data.date, userId: data.userId });
        // Update search-count
        if (data.aml.personData.aml && data.aml.personData.aml.pepResults.length > 0) result.counts.pepCount += 1;
        if (data.aml.personData.aml && data.aml.personData.aml.sanctionResults.length > 0) result.counts.sanctionCount += 1;
        if (data.aml.personData.aml && data.aml.personData.aml.pepResults.length == 0 && data.aml.personData.aml.sanctionResults.length == 0) result.counts.okCount += 1;
        if (!data.existing) result.counts.searchCount += 1;
        // Add to event-list
        if (!data.existing) result.events.push({ name: root.__('events_pep') + personhelper.getPersonDisplayName(data.person), date: data.date });
    },

    onPepSearchCompletedNew(root, result, envelope) {
        // Add to search-list
        result.sources.search.push({ person: envelope.userdata.person, envelope: envelope });
        // Update search-count
/*         if (data.aml.personData.aml && data.aml.personData.aml.pepResults.length > 0) result.counts.pepCount += 1;
        if (data.aml.personData.aml && data.aml.personData.aml.sanctionResults.length > 0) result.counts.sanctionCount += 1;
        if (data.aml.personData.aml && data.aml.personData.aml.pepResults.length == 0 && data.aml.personData.aml.sanctionResults.length == 0) result.counts.okCount += 1;
        if (!data.existing) result.counts.searchCount += 1; */
        // Add to event-list
        result.events.push({ name: root.__('events_pep') + personhelper.getPersonDisplayName(envelope.userdata.person), date: envelope.userdata.metadata.date });
    },

    onControlSent(root, result, person, envelope, existing) {
        // Add to event-list
        if (!existing) result.events.push({ name: root.__('events_id_sent') + personhelper.getPersonDisplayName(envelope.userdata.person), date: person.invite.sentDate });
        // Check if control is completed
        let completed = false;
        if (person.control && person.control.completedDate) {
            if (!existing) result.events.push({ name: root.__('events_id_completed') + personhelper.getPersonDisplayName(envelope.userdata.person), date: person.control.completedDate });
            completed = true;
            if (!existing) result.counts.completeCount += 1;

        }else if (envelope.content.recipients[0].completed) {
            if (!existing) result.events.push({ name: root.__('events_id_completed') + personhelper.getPersonDisplayName(envelope.userdata.person), date: person.control.completedDate });
            completed = true;
            if (!existing) result.counts.completeCount += 1;

        }else {
            if (!existing) result.counts.sentCount += 1;
        }
        // Add to id-control list
        envelope.userdata.person.control = envelope.userdata.control;
        result.sources.id.push({
            person: envelope.userdata.person,
            completed: completed,
            recipientId: envelope.content.recipients[0].id,
            recipientEmail: envelope.content.recipients[0].email,
            dbPerson: person,
            recipient: envelope.content.recipients[0]
        });
    },

    onManualControlSent(root, result, data) {
        const person = tools.decodePerson(data.identity);
        person.control = {
            method: 'manual',
            lookupDate: data.control.completedDate,
            userName: root.getEmployeeName(data.control.userId),
            userId: data.control.userId,
            ssn: data.control.ssn,
            comment: data.control.comment
        };
        result.sources.id.push({
            person: person,
            completed: true,
            recipientId: 'NA'
        });
    },

    onControlSentNew(root, result, dbPerson, envelope) {
        if (dbPerson.idc.isForm) {
            this.onControlSentForm(root, result, dbPerson, envelope);
            return;
        }
        if (envelope) {
            envelope.userdata.person.control = envelope.userdata.control;
            result.sources.id.push({
                person: envelope.userdata.person,
                completed: dbPerson.idc.completed || envelope.content.recipients[0].completed,
                recipientId: envelope.content.recipients[0].id,
                recipientEmail: envelope.content.recipients[0].email,
                dbPerson: dbPerson,
                recipient: envelope.content.recipients[0]
            });
        }else {
            const person = tools.decodePerson(dbPerson.identity);
            person.control = {
                method: 'manual',
                lookupDate: dbPerson.idc.completedDate,
                userName: root.getEmployeeName(dbPerson.idc.userId),
                userId: dbPerson.idc.userId,
                ssn: dbPerson.idc.ssn,
                comment: dbPerson.idc.comment
            };
            result.sources.id.push({
                person: person,
                completed: true,
                recipientId: 'NA'
            });
        }
    },

    onControlSentForm(root, result, dbPerson, envelope) {
        result.sources.idc.push({
            person: envelope.userdata.idcPerson,
            dbPerson: dbPerson,
            recipient: envelope.content.recipients[0],
            idcResult: envelope.userdata.idcResult
        });
    }

};
export default clienthelper;