<template>
    <div class="slim-page">
        
        <vap-label :text="__('settings_show_for')" header style="margin-top: 50px; margin-bottom: 10px;" />

        <!-- Portal -->
        <vap-card list noPadding>
            <vap-item float padding10 @clicked="navigate('portal')">
                <div class="setl-item">
                    <div class="setl-icon">
                        <vap-icon :icon="'settings'" />
                    </div>
                    <div class="setl-texts">
                        <div class="setl-head"><label>{{ __('settings_show_portal_head') }}</label></div>
                        <div class="setl-desc"><label>{{ __('settings_show_portal_desc') }}</label></div>
                    </div>
                    <div class="setl-arrow" style="opacity: 0.8;">
                        <vap-icon :icon="'chev-r'" />
                    </div>
                </div>
            </vap-item>
        </vap-card>

        <!-- KYC -->
        <vap-card v-if="$root.config.kycs.length > 1 || $root.config.modules.multipleKyc" list noPadding>
            <div style="padding: 10px 12px;">
                <div class="setl-item" style="margin-bottom: 7px;">
                    <div class="setl-icon">
                        <vap-icon :icon="'form'" />
                    </div>
                    <div class="setl-texts">
                        <div class="setl-head"><label>{{ __('settings_show_kyc_head') }}</label></div>
                        <div class="setl-desc"><label>{{ __('settings_show_kyc_desc') }}</label></div>
                    </div>
                </div>
                <vap-item v-for="(kyc, i) in $root.config.kycs" :key="'k' + i" @clicked="navigate('kyc', kyc.key)">
                    <div class="setl-item">
                        <div class="setl-texts">
                            <div class="setl-form"><label>{{ kyc.name }}</label></div>
                        </div>
                        <div class="setl-arrow" style="opacity: 0.8;">
                            <vap-icon :icon="'chev-r'" />
                        </div>
                    </div>
                </vap-item>
            </div>
        </vap-card>

        <!-- KYC -->
        <vap-card list noPadding>
            <vap-item v-if="$root.config.kycs.length <= 1 && !$root.config.modules.multipleKyc" float padding10 @clicked="navigate('kyc', $root.config.kycs[0].key)">
                <div class="setl-item">
                    <div class="setl-icon">
                        <vap-icon :icon="'form'" />
                    </div>
                    <div class="setl-texts">
                        <div class="setl-head"><label>{{ __('settings_show_kyc_head') }}</label></div>
                        <div class="setl-desc"><label>{{ __('settings_show_kyc_desc') }}</label></div>
                    </div>
                    <div class="setl-arrow" style="opacity: 0.8;">
                        <vap-icon :icon="'chev-r'" />
                    </div>
                </div>
            </vap-item>
        </vap-card>

        <!-- ID-Control -->
        <vap-card v-if="$root.config.idcs.length > 1 || $root.config.modules.multipleIdc" list noPadding>
            <div style="padding: 10px 12px;">
                <div class="setl-item" style="margin-bottom: 7px;">
                    <div class="setl-icon">
                        <vap-icon :icon="'card'" />
                    </div>
                    <div class="setl-texts">
                        <div class="setl-head"><label>{{ __('settings_show_idc_head') }}</label></div>
                        <div class="setl-desc"><label>{{ __('settings_show_idc_desc') }}</label></div>
                    </div>
                    <div class="setl-arrow" v-if="$root.config.modules.multipleIdc">
                        <vap-icon :icon="'add'" action @clicked="showCreateIdcDialog"><template v-slot:tooltip><vap-tooltip :text="'Opprett ny ID-kontroll'" /></template></vap-icon>
                    </div>
                </div>
                <vap-item v-for="(idc, i) in $root.config.idcs" :key="'i' + i" @clicked="navigate('idcontrol', idc.key)">
                    <div class="setl-item setl-item-sep">
                        <div class="setl-texts">
                            <div class="setl-form"><label>{{ idc.name }}</label></div>
                        </div>
                        <div class="setl-arrow" style="opacity: 0.8;">
                            <vap-icon :icon="'chev-r'" />
                        </div>
                    </div>
                </vap-item>
            </div>
        </vap-card>

        <!-- ID-Control -->
        <vap-card list noPadding v-if="$root.config.idcs.length <= 1 && !$root.config.modules.multipleIdc">
            <vap-item float padding10 @clicked="navigate('idcontrol', $root.config.idcs[0].key)">
                <div class="setl-item">
                    <div class="setl-icon">
                        <vap-icon :icon="'id'" />
                    </div>
                    <div class="setl-texts">
                        <div class="setl-head"><label>{{ __('settings_show_idc_head') }}</label></div>
                        <div class="setl-desc"><label>{{ __('settings_show_idc_desc') }}</label></div>
                    </div>
                    <div class="setl-arrow" style="opacity: 0.8;">
                        <vap-icon :icon="'chev-r'" />
                    </div>
                </div>
            </vap-item>
        </vap-card>

        <!-- Colors -->
        <vap-card list noPadding>
            <vap-item float padding10 @clicked="navigate('themes')">
                <div class="setl-item">
                    <div class="setl-icon">
                        <vap-icon :icon="'colors'" />
                    </div>
                    <div class="setl-texts">
                        <div class="setl-head"><label>{{ __('settings_show_colors_head') }}</label></div>
                        <div class="setl-desc"><label>{{ __('settings_show_colors_desc') }}</label></div>
                    </div>
                    <div class="setl-arrow" style="opacity: 0.8;">
                        <vap-icon :icon="'chev-r'" />
                    </div>
                </div>
            </vap-item>
        </vap-card>

        <!-- Departments -->
        <vap-card list noPadding v-if="$root.config.portal.departments">
            <vap-item float padding10 @clicked="navigate('users')">
                <div class="setl-item">
                    <div class="setl-icon">
                        <vap-icon :icon="'company'" />
                    </div>
                    <div class="setl-texts">
                        <div class="setl-head"><label>{{ 'Avdelinger og partnere' }}</label></div>
                        <div class="setl-desc"><label>{{ 'Legg til/ta bort avdelinger og partnere' }}</label></div>
                    </div>
                    <div class="setl-arrow" style="opacity: 0.8;">
                        <vap-icon :icon="'chev-r'" />
                    </div>
                </div>
            </vap-item>
        </vap-card>


        <ve-popup v-if="createIdc.show" :header="'Create new ID-control'" :width="600" @close="createIdc.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c3>
                            <vap-label desc :text="'Key'" required />
                            <vap-input v-model="createIdc.key" :rules="'required'" @input="validateCreateIdcDialog" noValidation :placeholder="__('common_required')" />
                        </vap-group>
                        <vap-group c9>
                            <vap-label desc :text="__('common_name')" required />
                            <vap-input v-model="createIdc.name" :rules="'required'" @input="validateCreateIdcDialog" noValidation :placeholder="__('common_required')" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="createIdc.show = false" />
                    <vap-button :text="__('common_save')" uppercase @clicked="createNewIdc" :disabled="!createIdc.valid" :working="createIdc.working" />
                </vap-button-row>
            </div>
        </ve-popup>

    </div>
</template>
<script>
import api from '../portalapi';

export default {
    name: 'Settings',
    data() {
        return {
            createIdc: { show: false, valid: false, working: false, key: '', name: '' }
        }
    },
    methods: {

        navigate(route, key) {
            if (key) {
                this.$parent.navigate({ path: '/' + 'settings', query: { for: route, key: key } });
            }else {
                this.$parent.navigate({ path: '/' + 'settings', query: { for: route } });
            }
        },

        showCreateIdcDialog() {
            this.createIdc.show = true;
        },

        validateCreateIdcDialog() {
            this.createIdc.valid = this.createIdc.name != '';
        },

        async createNewIdc() {
            this.createIdc.working = true;

            const oldKey = this.$root.config.idcs[0].key;
            const idc = JSON.parse(JSON.stringify(this.$root.config.idcs[0]));
            idc.key = this.createIdc.key;
            idc.name = this.createIdc.name;
            console.log('idc', idc);

            this.$root.config.idcs.push(idc);

            await api.setConfig(this.$root.config);

            const response = await api.getTranslations(oldKey);
            const texts = response.texts;
            console.log('texts', texts);

            await api.setTranslations(idc.key, texts);

            this.createIdc.working = false;
            this.createIdc.show = false;
        },

    },
    async created() {
        this.$parent.setTitle(this.__('settings'));
    }
}
</script>
<style scoped>

.setl-item {
    display: flex;
    width: 100%;
}
.setl-item-sep {
    padding-top: 5px;
    padding-bottom: 1px;
    border-top: 1px solid var(--input-border);
}

.setl-icon {
    width: 36px;
}

.setl-texts {
    flex-grow: 100;
    padding-left: 15px;
}
.setl-head {
    font-size: 18px;
    color: var(--text-accent);
}
.setl-desc {
    font-size: 16px;
    color: var(--text-strong);
}
.setl-form {
    padding-top: 7px;
    font-size: 18px;
    color: var(--text-accent);
}

.setl-arrow {
    float: right;
    width: 36px;
}

</style>