<template>
<div>

    <ve-popup :header="'Attachments and documents'" :width="500" @close="$emit('close')" hideCloseButton>
        <div style="padding: 16px 20px;">
            
            <div class="frv2-mmod" v-for="(slot, i) in slots" :key="'mm' + i">

                <div class="frv2-mmod-empty" v-if="slot.type == 'empty'" @click="showAddDialog">
                    <div class="frv2-mmod-icon">
                        <vap-icon :icon="'add'" :color="'var(--disabled-background)'" />
                    </div>
                </div>

                <div class="frv2-mmod-mod" v-if="slot.type != 'empty'" @click="onModuleClick(slot)">
                    <div class="frv2-mmod-mod-icon">
                        <vap-icon :icon="slot.icon" :color="'var(--accent)'" />
                    </div>
                    <div class="frv2-mmod-mod-text">
                        {{ 
                            slot.type == "portal-filestore-attachment"
                            ? ((filestoreNameCache.find(x => x.id == attachmentForSlot(slot.index).fileId) || { name: false }).name || slot.name)
                            : slot.name
                         }}
                    </div>
                    <div class="frv2-mmod-mod-icon" style="flex-grow: 1;">
                        <vap-icon icon="delete" color="#555" style="float: right; cursor: pointer" action @clicked="deleteItem(slot.index)" />
                    </div>
                </div>

            </div>

        </div>
    </ve-popup>

    <ve-popup v-if="addDialog.show" :header="'Add module'" :width="500" @close="addDialog.show = false" hideCloseButton>
        <div style="padding: 16px 0px;">
            
            <vap-item v-for="(mod, i) in allModules" :key="'am' + i" fancy padding20
                :fancyIcon="mod.icon"
                :fancyText="mod.name"
                :fancyHint="mod.hint"
                @clicked="onAddModule(mod.type)"
            />

        </div>
    </ve-popup>

    <ve-popup v-if="selectKey" :header="'Select key'" :width="500" @close="selectKey = false" hideCloseButton>
        <div style="padding: 16px 0px;">
            
            <vap-item fancy padding20 v-for="(option, i) in keyOptions" :key="'ok' + i"
                :fancyIcon="option.icon"
                :fancyText="option.text"
                :fancyHint="option.hint"
                @clicked="onConnectKey(option)"
            />

        </div>
    </ve-popup>

    <ve-popup v-if="attachment.visible" :header="'Select value'" :width="500" hideCloseButton>
        <div style="padding: 20px">

            <vap-row>
                <vap-group c12>
                    <label>Filename (with file extension)</label>
                    <vap-input v-model="attachment.filename" />
                </vap-group>
            </vap-row>

            <vap-row>
                <vap-group c12>
                    <vap-file-upload v-model="attachment.fileupload"
                        :header="'Upload file'"
                        :helpText="'Drag and drop here or'"
                        :uploadText="'select file'"
                        :singleFile="true"
                        :autoPreview="false"
                    />
                </vap-group>
            </vap-row>
            
            <vap-button-row alignLeft style="margin-bottom: 20px;">
                <vap-button :disabled="attachment.fileupload.files.length < 1" :text="'Save'" uppercase @clicked="onAddAttachment" />
            </vap-button-row>
        </div>
    </ve-popup>
    
</div>
</template>
<script>
import moduleHelper from '../modules/moduleHelper';
import v1api from '../../../v1helpers/v1api';

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export default {
    name: 'AttachmentModule',
    props: {
        params: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            slots: [],
            addDialog: { show: false },
            allModules: [],

            selectedIndex: 0,
            selectKey: false,
            keyOptions: [],

            filestoreNameCache: [],

            base64attachment: {
                visible: false,
                base64: "",
                filename: "",
            },

            attachment: {
                visible: false,
                filename: "",
                fileupload: {
                    files: []
                },
            },
        }
    },
    methods: {

        onConnectKey(option) {

            console.log('option', option);

            console.log('selin', this.selectedIndex);

            console.log('atc', this.$parent.attachments[this.selectedIndex]);

            const logic = {
                key: this.params.fromItem.key,
                okey: option.key
            };

            console.log('logig', logic);

            this.$parent.attachments[this.selectedIndex].logic = logic;

            this.$emit('close');
        },
        
        onModuleClick(slot) {
            if (this.params.mode == 'manage') {
                this.$emit('manage', slot.type);
            }else {

                this.selectedIndex = slot.index;

                console.log('fromItem', this.params);

                for (const i in this.params.fromItem.options) {
                    this.keyOptions.push({
                        icon: 'input-check',
                        key: this.params.fromItem.options[i].key,
                        text: this.params.fromItem.options[i].key,
                        hint: this.params.fromItem.options[i].locale[this.$root.locale.key]
                    });
                }

                this.selectKey = true;

                //this.$emit('connect', slot.type);
            }
        },

        showAddDialog() {
            this.allModules = [
                {
                    type: 'document-form',
                    icon: 'form',
                    name: 'Document from form',
                    hint: 'Generate a document from form that will be added as a second document to this form'
                },
                {
                    type: 'portal-filestore-attachment',
                    icon: 'pdf',
                    name: 'Static Attachment',
                    hint: 'Upload a file that will be added as an attachment to all envelopes created by this form'
                }
            ];
            this.addDialog.show = true;
        },

        onAddModule(type) {
            if(type == 'document-form'){
                this.$parent.attachments.push({
                    type: 'document-form',
                    formKey: 'klp03',
                    icon: 'form'
                });
            }

            if(type == 'portal-filestore-attachment'){
                this.attachment.filename = "";
                this.attachment.fileupload.files.splice(0);
                this.attachment.visible = true;
            }

            console.log('parent ', this.$parent)
            this.addDialog.show = false;
            //this.$parent.onCreated();
            this.updateList();
        },

        async onAddAttachment() {
            this.attachment.visible = false;

            const file = this.attachment.fileupload.files[0];
            const base64 = await toBase64(file.data);

            const res = await v1api.post("filestore", {
                filename: this.attachment.filename,
                base64,
            });

            this.$parent.attachments.push({
                type: 'portal-filestore-attachment',
                filename: this.attachment.filename,
                fileId: res._id,
                icon: 'pdf'
            });
            this.updateList();
        },

        attachmentForSlot(index) {
            return this.$parent.attachments[index];
        },

        async deleteItem(index) {
            const attachment = this.$parent.attachments[index];

            switch(attachment.type) {
                /* case "portal-filestore-attachment": {
                    await v1api.delete(`filestore/${attachment.fileId}`);
                    break;
                } */
            }

            this.$parent.attachments.splice(index, 1);
            this.attachment.visible = false;
            this.addDialog.show = false;
            this.updateList();
        },

        getAttachmentNameFromType(type) {
            
            switch (type) {
                case 'document-form': return 'Document from form';
                case 'portal-filestore-attachment': return 'Static attachment';
            }

        },

        updateList(){
            this.slots = [];
            for (const i in this.$parent.attachments) {
                this.slots.push({
                    type: this.$parent.attachments[i].type,
                    icon: this.$parent.attachments[i].icon,
                    name: this.getAttachmentNameFromType(this.$parent.attachments[i].type),
                    index: i
                });
            }
            if (this.params.mode == 'manage') {
                while (this.slots.length < 8) {
                    this.slots.push({ type: 'empty', icon: '', name: '' });
                }
            }

            this.updateFilestoreNameCache()
        },

        async updateFilestoreNameCache() {

            const results = await Promise.all( 
                this.slots
                    .filter(slot => slot.type == "portal-filestore-attachment")
                    .map(slot => this.attachmentForSlot(slot.index).fileId)
                    .map(fileId => v1api.get("filestore/" + fileId))
            )

            this.filestoreNameCache.splice(0);

            for(let res of results) {
                this.filestoreNameCache.push({
                    id: res.file._id,
                    name: res.file.filename
                })
            }

        }
        
    },
    created() {
        this.updateList();
    }
}
</script>
<style scoped>

.frv2-mmod {
    margin-bottom: 10px;
}

.frv2-mmod-empty {
    border: dotted 2px lightgray;
    border-radius: 6px;
    cursor: pointer;
}
.frv2-mmod-empty:hover {
    background-color: var(--item-hover);
}
.frv2-mmod-icon {
    width: 40px;
    margin: 0 auto;
}

.frv2-mmod-mod {
    display: flex;
    min-height: 40px;
    padding-top: 7px;
    padding-left: 10px;
    border: solid 2px var(--accent);
    border-radius: 6px;
    background-color: var(--card-background);
    box-sizing: border-box;
    align-items: center;
}
/* .frv2-mmod-mod:hover {
    background-color: var(--item-hover);
} */

.frv2-mmod-mod-icon {
    margin-top: -6px;
}

.frv2-mmod-mod-text {
    margin-left: 10px;
    font-size: 14px;
    margin-top: -6px;
    color: var(--accent);
}

</style>