<template>
<div :style="`background: ${cardData.bgColor}`" class="vf-popup card-shadow" @click="handleClick" :class="[
    { 'vf-popup-selected' : selected }
]">

    <div class="vf-popup-icon">
        <vap-icon :icon="cardData.icon || 'info'" :color="cardData.iconColor" />
    </div>

    <div class="vf-popup-content">

        <div :style="`color: ${cardData.titleColor}`">
            {{ cardData.title[$root.locale.key] }}
        </div>

        <div class="vf-popup-link">
            <label @click="showPopup">{{ cardData.link[$root.locale.key] }}</label>
        </div>

    </div>

</div>
</template>

<script>

export default {
    name: 'VFPopup',
    props: {
        cardData: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    methods: {
        
        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

        showPopup() {
            this.showHelpPopupX({ htmlRaw: this.cardData.content[this.$root.locale.key] });
        }

    }
}
</script>

<style scoped>

.vf-popup {
    display: flex;
    float: left;
    width: 100%;
    margin-bottom: 20px;
    padding: 5px 20px;
    padding-top: 14px;
    padding-bottom: 10px;
    border-radius: 3px;
    /* border: 1px solid #CFC4A5; */
    box-sizing: border-box;
}

.vf-popup-icon {
    width: 48px;
    flex-shrink: 0;
    padding-top: 4px;
}

.vf-popup-content {
    flex-grow: 100;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    color: var(--text-title);
}

.vf-popup-link {
    color: var(--link-text);
}
.vf-popup-link label {
    cursor: pointer;
}
.vf-popup-link label:hover {
    color: var(--link-active);
}


/* Designer / Selection */

.vf-popup-selected {
    outline: solid 2px #238ccc;
}


</style>
