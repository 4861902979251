const previewHelper = {

    //#region Loading -----------------------------------------------------------------------------------------------------

    getOrgReportParams(client) {
        const params = {
            isPreview: false,
            mainEnvelope: {
                id: client.envelope.id,
                name: client.envelope.name
            },
            attachments: client.envelope.attachments
        };
        return params;
    },

    getUploadedFileParams(client, name) {
        const params = {
            isPreview: false,
            loadAttachments: true,
            autoAttachmentName: name,
            mainEnvelope: {
                id: client.envelope.id,
                name: client.envelope.name
            },
            attachments: []
        };
        return params;
    },

    getExternalFileParams(envelopeId, name) {
        const params = {
            isPreview: false,
            loadAttachments: true,
            autoAttachmentName: name,
            mainEnvelope: {
                id: envelopeId,
                name: ''
            },
            attachments: []
        };
        return params;
    },

    getFinalReportParams(root, client) {
        const params = {
            isPreview: false,
            loadAttachments: true,
            mergedDownload: true,
            mainEnvelope: {
                id: client.report.envelopeId,
                name: root.__t('report-final') + ' ' + client.name
            },
            attachments: []
        };
        if (client.report.name) params.mainEnvelope.name = client.report.name;
        return params;
    },

    getArchivedReportParams(root, client, report) {
        const params = {
            isPreview: false,
            loadAttachments: true,
            mainEnvelope: {
                id: report.envelopeId,
                name: root.__t('report-final') + ' ' + client.name
            },
            attachments: []
        };
        return params;
    },

    getFormParams(client, key) {
        const params = {
            isPreview: false,
            loadAttachments: true,
            mainEnvelope: {
                id: client[key].envelopeId,
                name: client[key].name || 'Custom form'
            },
            attachments: []
        };
        return params;
    },

    getArchivedFormParams(client, key, index) {
        const params = {
            isPreview: false,
            loadAttachments: true,
            mainEnvelope: {
                id: client[key].archive[index].envelopeId,
                name: client[key].archive[index].name || 'Custom form'
            },
            attachments: []
        };
        return params;
    },

    getFormParams2(form, autoAttachmentName) {
        const params = {
            isPreview: false,
            loadAttachments: true,
            autoAttachmentName: autoAttachmentName || '',
            mainEnvelope: {
                id: form.envelopeId,
                name: form.name
            },
            attachments: []
        };
        return params;
    },

    getScreeningReceiptParams(root, person) {
        console.log('person', person);
        const params = {
            isPreview: false,
            loadAttachments: true,
            mainEnvelope: {
                id: person.screening.envelopeId,
                name: root.__t('screening-person') + ' ' + person.displayName
            },
            attachments: []
        };
        return params;
    },

    getIDCReceiptParams(root, person) {
        console.log('person', person);
        const params = {
            isPreview: false,
            loadAttachments: true,
            mainEnvelope: {
                id: person.idc.envelopeId,
                name: root.__t('identity-idc') + ' ' + person.displayName
            },
            attachments: []
        };
        return params;
    },

    getReportPreviewParams(root, userdata) {
        const params = {
            isPreview: true,
            templatePath: 'repv1',
            mainEnvelope: {
                name: root.__t('report-final') + ' ' + userdata.client.name
            },
            attachments: [],
            userdata: userdata
        };
        for (const i in userdata.attachments) {
            params.attachments.push(userdata.attachments[i]);
        }
        return params;
    },

    getPersonReportPreviewParams(root, userdata) {
        const params = {
            isPreview: true,
            templatePath: 'person',
            mainEnvelope: {
                name: root.__t('person-report') + ' ' + userdata.person.name
            },
            attachments: [],
            userdata: userdata
        };
        for (const i in userdata.attachments) {
            params.attachments.push(userdata.attachments[i]);
        }
        return params;
    },  

    getFormPreviewParams(userdata) {
        const params = {
            isPreview: true,
            templatePath: 'form',
            mainEnvelope: {
                name: 'Form '
            },
            attachments: [],
            userdata: userdata
        };
        return params;
    },

    getPartnerPreviewParams(envelope) {
        const params = {
            isPreview: false,
            isEditor: true,
            mainEnvelope: {
                id: envelope.id,
                name: envelope.id
            },
            attachmentIsMain: true,
            attachments: [],
            preventClose: true
        };
        return params;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------



    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default previewHelper;