<template>
<div>

    <PopupV1 :header="'Ingen tilgang'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ 'Du har ikke tilgang til å vise denne kunden' + ':' }}
            </div>
            <DialogContent>
                <DialogContentHeader :text="'Kunde'.toUpperCase() + ':'" />
                <DialogContentItem :text="params.clientName" :icon="'company'" />
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'OK'" outline @clicked="onClose" :disabled="dialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'UserAccessDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
        }
    },
    methods: {

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        
    }
}
</script>