<template>
<div class="frmod-kyc">

    <PopupV1 designer :header="'Connect to approve module'" v-if="params.type == 'xconnect'" :width="500" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <ConnectionList :items="options" :params="params" @item="onConnectItem" @itemkey="onConnectItemKey" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import logicHelper from '../../logicHelper';

import ConnectionList from '../../designer/ConnectionList.vue';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';
import VFCheckbox from '../../inputs/VFCheckbox.vue';

export default {
    name: "ApproveModuleView",
    components: {
        PopupV1,
        VFButton,
        VFCheckbox,
        ConnectionList,
    },
    props: {
        params: { type: Object, default: () => { return { type: '' } }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: {},
            options: [],
        }
    },
    watch: {
        width: {
            deep: false,
            handler(val) {
                
            }
        }
    },
    methods: {

        onConnectItem(result) {
            console.log('result', result);

            if (result.conn == 'approver-name') {
                
                logicHelper.prepareItem(this.reference, this.params.fromItem, 'link');
                this.params.fromItem.logic.link.push({
                    type: 'app',
                    link: 'approver-name',
                    key: ''
                });

                console.log('set', this.params.fromItem);

            }else if (result.conn == 'approver-comment') {
                
                logicHelper.prepareItem(this.reference, this.params.fromItem, 'link');
                this.params.fromItem.logic.link.push({
                    type: 'app',
                    link: 'approver-comment',
                    key: ''
                });

                console.log('set', this.params.fromItem);

            }

            
            this.$emit('reselect');
            this.$emit('close');
        },

        onConnectItemKey(result) {
            console.log('result', result);

            if (result.conn == 'approve-yes') {
                
                logicHelper.prepareItem(this.reference, this.params.fromItem, 'link');
                this.params.fromItem.logic.link.push({
                    type: 'app',
                    link: 'approve-yes',
                    key: result.key
                });

                console.log('set', this.params.fromItem);

            }

            this.$emit('reselect');
            this.$emit('close');
        },

    },
    created() {
        if (this.params.type == 'xconnect') {
            const type = this.params.fromItem.type;

            console.log('TYPE', type);

            // Add possible connections
            if (type == 'text') {
                // Approver - Name
                this.options.push({ type: 'link', conn: 'approver-name', icon: 'person', text: 'Approver - Name',
                    hint: 'Name of the current approver'
                });
            }
            if (type == 'text' || type == 'area') {
                // Approver - Name
                this.options.push({ type: 'link', conn: 'approver-comment', icon: 'person', text: 'Approver - Comment',
                    hint: 'Comment'
                });
            }
            if (type == 'check2') {
                // Form is approved
                this.options.push({ type: 'link', conn: 'approve-yes', icon: 'input-check', text: 'Form is approved',
                    hint: 'Form is approved'
                });
            }
            if (type == 'check') {
                // Form is approved
                this.options.push({ type: 'link', conn: 'approve-yes', icon: 'input-check', text: 'Form is approved',
                    hint: 'Form is approved'
                });
            }
            

        }
    }
}

</script>