<template>

<div class="ve-dm ve-dm-right card-shadow" :style="'margin-top: ' + top + 'px; margin-right: ' + right + 'px;'">
    <slot></slot>
</div>

</template>
<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
name: "vap-dropdown-menu",
mixins: [clickaway],
props: {
    top: {
        type: String,
        default: '0'
    },
    right: {
        type: String,
        default: '0'
    }
}
}

</script>
<style scoped>

.ve-dm {
    position: absolute;
    min-width: 200px;
    min-height: 20px;
    border-radius: 1px;
    background-color: white;
    z-index: 99999;
}

.ve-dm label {
    opacity: 1;
}

.ve-dm-right {
    top: 30px;
    right: -10px;
}

.ve-dm-right:after {
    content: '';
    position: absolute;
    border: 13px solid transparent;
    border-bottom: 16px solid white;
    top: -28px;
    right: 10px;
    pointer-events: none;
}

</style>