<template>
<div>
    
    <PropertyHeader :header="'Modules'">
        <template v-slot:actions>
            <DesignerButton small
                :icon="'add'"
                :tooltip="'Add module'"
                @clicked="showAddDialog"
            />
        </template>
    </PropertyHeader>

    <div v-if="!logicActive">
        <PropertyItem padded :header="mod.name" :icon="mod.icon" v-for="(mod, i) in modules" :key="'pm' + i"
            :actions="actions"
            @action="onAction($event, mod)"
        >
            
            <KycModuleBox :reference="reference" v-if="mod.type == 'kyc'" />

            <SigningModuleBox :reference="reference" v-if="mod.type == 'sign'" />

            <FormModuleBox :reference="reference" v-if="mod.type == 'form'" />

            <IdcModuleBox :reference="reference" v-if="mod.type == 'idc'" />

            <ApproveModuleBox :reference="reference" v-if="mod.type == 'app'" />

            <ClientModuleBox :reference="reference" v-if="mod.type == 'client'" @logic="$emit('logic', $event.key, $event.type)" />

            <KarModuleBox :reference="reference" v-if="mod.type == 'kar'" />

        </PropertyItem>
    </div>

<!--     <div>

        <div style="font-size: 40px; color: white; opacity: 0.88;">
            {{ 'TEST' }}
        </div>
        <div style="font-size: 40px; color: white; opacity: 0.7;">
            {{ 'TEST' }}
        </div>
        <div style="font-size: 40px; color: white; opacity: 0.4;">
            {{ 'TEST' }}
        </div>
        <div style="font-size: 40px; color: white; opacity: 0.2;">
            {{ 'TEST' }}
        </div>

    </div> -->

    <div v-if="logicActive">
        <PropertyItem :header="mod.name" :icon="mod.icon" v-for="(mod, i) in modules" :key="'pm' + i">
            <div class="frv2-modlogic-connect" @click="onConnectToModule(mod)">
                <div class="frv2-modlogic-connect-icon">
                    <vap-icon :icon="'logic'" :color="'ext'" />
                </div>
                <div class="frv2-modlogic-connect-text">
                    {{ 'Connect to ' + mod.name }}
                </div>
            </div>
        </PropertyItem>
    </div>

    <PopupV1 designer noPadding v-if="addDialog.show" :header="'Add module'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <div>
                <PropertListItem v-for="(mod, i) in allModules" :key="'am' + i"
                    :icon="mod.icon"
                    :text="mod.name"
                    :hint="mod.hint"
                    :disabled="hasModule(mod.type)"
                    @clicked="onAddModule(mod.type)"
                />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="addDialog.show = false" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import moduleHelper from '../../modules/moduleHelper';
import editorHelper from '../../editorHelper';

import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import PropertListItem from '../ui/PropertyListItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';

import KycModuleBox from '../../modules/kyc/KycModuleBox.vue';
import SigningModuleBox from '../../modules/signing/SigningModuleBox.vue';
import FormModuleBox from '../../modules/formdata/FormModuleBox.vue';
import IdcModuleBox from '../../modules/idcontrol/IdcModuleBox.vue';
import ApproveModuleBox from '../../modules/approve/ApproveModuleBox.vue';
import ClientModuleBox from '../../modules/customer/ClientModuleBox.vue';
import KarModuleBox from '../../modules/kar/KarModuleBox.vue';

export default {
    name: 'ModulesPropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        PropertListItem,
        DesignerButton,
        PopupV1,
        VFButton,
        KycModuleBox,
        SigningModuleBox,
        FormModuleBox,
        IdcModuleBox,
        ApproveModuleBox,
        ClientModuleBox,
        KarModuleBox,
    },
    props: {
        reference: { type: Object, default: null },
        logicActive: { type: Boolean, default: false },
    },
    data() { 
        return {
            addDialog: { show: false },
            allModules: [],
            modules: [],
            actions: [
                { icon: 'delete', action: 'del' },
            ],
        }
    },
    methods: {

        onAction(action, module) {
            switch (action.action) {
                case 'del': this.onDeleteModule(module); break;
            }
        },

        onConnectToModule(module) {
            this.$emit('connect', module.type);
            this.$emit('stoplogic');
        },

        showAddDialog() {
            this.allModules = moduleHelper.getAllModules();
            this.addDialog.show = true;
        },

        onAddModule(type) {
            editorHelper.onChange(this.reference, 'module_add', false);
            const config = moduleHelper.getEmptyConfig(type);
            this.reference.formData.modules.push(config);
            this.addDialog.show = false;
            this.reference.onCreated();
            this.loadModules();
        },

        onDeleteModule(module) {
            editorHelper.onChange(this.reference, 'module_del', false);
            for (const i in this.reference.modules) {
                if (this.reference.modules[i].type == module.type) {
                    this.reference.modules.splice(i, 1);
                    break;
                }
            }
            this.loadModules();
        },

        hasModule(type) {
            return moduleHelper.hasModule(this.reference, type);
        },

        loadModules() {
            this.modules = [];
            for (const i in this.reference.modules) {
                this.modules.push({
                    type: this.reference.modules[i].type,
                    icon: this.reference.modules[i].icon,
                    name: moduleHelper.getModuleName(this.reference.modules[i].type),
                });
            }
        },

    },
    created() {
        this.loadModules();
    }
}
</script>
<style scoped>

.frv2-modlogic-connect {
    padding: 10px 20px;
    fill: var(--fb-accent);
    color: var(--fb-accent);
    display: flex;
}
.frv2-modlogic-connect:hover {
    fill: var(--fb-logic);
    color: var(--fb-logic);
}

.frv2-modlogic-connect-text {
    margin-top: 8px;
    margin-left: 5px;
}

</style>