<template>
<div class="vrepv1-org">

    <VFRow>
        <VFGroup v-for="(itm, i) in items" :key="'ri' + i" :width="itm.width">

            <VFLabel v-if="itm.type == 'custom'" desc :text="itm.text[$root.locale.key]" /><br>
            <VFLabel v-if="itm.type == 'custom'" input :text="itm.value[$root.locale.key]" />

            <vap-switch v-if="itm.type == 'checklist'"
                v-model="itm.checked"
                disabled
                :text="itm.text[$root.locale.key]"
                style="margin-top: -15px; margin-bottom: -5px;"
            />

        </VFGroup>
    </VFRow>

    <div v-if="hasComparison">
        <KycComparisonRenderer :changes="comparisonData" />
    </div>

    <div v-if="upload.enabled" class="flw100" style="padding-bottom: 20px;">
        <div v-if="!disabled">
            <VFFileupload v-model="upload.files"
                :singleFile="true"
                :header="'Risikovurdering'"
                :hintText="'Dra og slipp filen her eller'"
                :browseText="'velg fil'"
                @input="onFileChanged"
            />
            <div>
                {{ 'Risikovurderingen må være i PDF-format. Velg «Fil - Eksporter» i Excel for å lage PDF.' }}
            </div>
        </div>
        <div v-if="disabled">
            {{ 'Risikovurdering' }}
        </div>
    </div>

    <CardActions :actions="actions" @action="onAction" />
    
</div>
</template>
<script>
import triggerHelper from '../v1flows/triggerHelper';

import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFFileupload from '../components/form/inputs/VFFileupload.vue';

import CardActions from '../v1ui/CardActions.vue';

import KycComparisonRenderer from '../components/form/modules/kyc/KycComparisonRenderer.vue';

export default {
    name: 'ReportCustom',
    components: {
        VFRow,
        VFGroup,
        VFLabel,
        CardActions,
        KycComparisonRenderer,
        VFFileupload,
    },
    props: {
        item: { type: Object, default: null },
        client: { type: Object, default: null },
        disabled: { type: Boolean, default: false }
    },
    data() {
        return {
            actions: [],
            items: [],
            hasComparison: false,
            comparisonData: null,
            upload: {
                enabled: false,
                files: {}
            }
        }
    },
    methods: {

        onFileChanged() {
            this.$root.loadedFiles = [];
            for (const i in this.upload.files.files) {
                const file = this.upload.files.files[i];
                file.reportKey = 'external-risk';
                console.log('file', file);
                this.$root.loadedFiles.push(file);
            }
            this.$emit('changed');
        },
        
        onAction(action) {
            if (action.action == 'preview') {

                this.$emit('preview', action.key);
                
            }
        },

        getText(text) {
            return triggerHelper.replaceTexts(text, this.$parent.texts)[this.$root.locale.key];
        },

    },
    created() {
        //console.log('ITEM', this.item);

        for (const i in this.item.actions) {
            let valid = true;
            for (const k in this.item.actions[i].triggers) {
                if (this.item.actions[i].triggers[k].type == 'rep-itm') {
                    const result = this.$parent.validateReportDataTrigger(this.item.actions[i].triggers[k], this.items[i]);
                    if (!result) valid = false;
                }else {
                    const result = triggerHelper.validateTrigger(this.item.actions[i].triggers[k], this.$parent.$parent.$parent.$parent.triggers);
                    if (!result) valid = false;
                }
            }
            if (valid) {
                this.actions.push({
                    action: this.item.actions[i].action,
                    icon: this.item.actions[i].icon,
                    key: this.item.actions[i].key,
                });
            }
        }

        for (const i in this.item.items) {
            if (this.item.items[i].type == 'custom' || this.item.items[i].type == 'checklist') {
                let valid = true;
                let disabled = false;
                for (const k in this.item.items[i].triggers) {
                    if (this.item.items[i].triggers[k].type == 'rep-itm') {
                        const result = this.$parent.validateReportDataTrigger(this.item.items[i].triggers[k], this.items[i]);
                        if (!result) valid = false;
                    }else {
                        const result = triggerHelper.validateTrigger(this.item.items[i].triggers[k], this.$parent.$parent.$parent.$parent.triggers);
                        if (!result) valid = false;
                    }
                }
                if (this.item.items[i].disabled) {
                    let dValid = true;
                    for (const k in this.item.items[i].disabled.triggers) {
                        if (this.item.items[i].disabled.triggers[k].type == 'rep-itm') {
                            const result = this.$parent.validateReportDataTrigger(this.item.items[i].disabled.triggers[k], this.items[i]);
                            if (!result) dValid = false;
                        }else {
                            const result = triggerHelper.validateTrigger(this.item.items[i].disabled.triggers[k], this.$parent.$parent.$parent.$parent.triggers);
                            if (!result) dValid = false;
                        }
                    }
                    disabled = dValid;
                }
                if (valid || (this.item.items[i].type == 'checklist' && !disabled)) {

                    let addCustomItem = true;

                    const customItem = {
                        type: this.item.items[i].type,
                        width: this.item.items[i].width,
                        text: this.item.items[i].text,
                        checked: valid,
                        value: triggerHelper.replaceTexts(this.item.items[i].value, this.$parent.$parent.$parent.$parent.texts),
                    };

                    if (this.item.items[i].validTextOnly) {
                        // Item should only be added if the text was replaced
                        if (customItem.value[this.$root.locale.key].includes('{{')) {
                            addCustomItem = false;
                        }
                    }

                    if (addCustomItem) this.items.push(customItem);


                }
            }else {
                if (this.item.items[i].type == 'kyc-comparison') {
                    if (this.client.kyc.done && this.client.kyc.changed != null) {
                        this.comparisonData = this.client.kyc.comparison;
                        this.hasComparison = true;
                    }
                }else if (this.item.items[i].type == 'upload') {
                    this.upload.enabled = true;
                }
            }
        }

    }
}
</script>
<style scoped>

.vrepv1-org {
    padding: 20px;
}

</style>