import v1flows from '../v1helpers/v1flows';
import v1personHelper from '../v1helpers/v1personHelper';
const triggerHelper = {

    setTriggers(root, triggers, client, persons, counts) {

        triggers.client = {
            created: true
        };

        triggers.org = {
            fetched: false,
            hasSanction: false,
            missingData: false,
            amsStarted: false,
            amsCompleted: false,
            amsWarning: false,
            amsHits: false,
        };

        triggers.book = {
            provided: false,
            uploaded: false,
            irrelevant: false,
            later: false,
            external: false,
            exists: false,
            confirmed: false,
        };

        if (client.kyc.book && client.kyc.book.provided) {
            triggers.book.provided = true;
        }
        if (client.kyc.done && !client.kyc.changed) {
            triggers.book.irrelevant = true;
        }
        if (client.kyc.done && client.kyc.changed && !triggers.book.provided) {
            triggers.book.later = true;
        }
        if (triggers.book.provided || triggers.book.uploaded) {
            triggers.book.exists = true;
        }
        if (client.book && client.book.confirmed) {
            triggers.book.confirmed = true;
        }
        if (client.book && client.book.uploaded) {
            triggers.book.uploaded = true;
        }

        if (client.company.name) {
            triggers.org.fetched = client.company.lookup;
            triggers.org.hasSanction = client.company.sanction.hit;
            triggers.org.missingData = !client.company.sanction.available;
        }
        if (client.company && client.company.source == 'chouse') triggers.org.missingData = false;

        if (client.web && client.web.done) {
            triggers.org.amsStarted = true;
            triggers.org.amsCompleted = client.web.completed;
            const hitLevel = v1personHelper.getAmsHitLevel(client.web);
            if (hitLevel == 'some') triggers.org.amsWarning = true;
            if (hitLevel == 'hits') triggers.org.amsHits = true;
        }

        triggers.tags = [];
        if (client.tags) {
            for (const i in client.tags) triggers.tags.push(client.tags[i]);
        }
        

        //console.log('counts', counts);

        triggers.person = {
            someScreened: counts.screened > 0,
            someIdentified: counts.identified > 0,
            allScreened: counts.screened >= counts.selected && counts.selected > 0,
            allIdentified: counts.identified >= counts.selected && counts.selected > 0,
            someAms: counts.ams > 0,
            allAms: counts.ams >= counts.selected && counts.selected > 0,
            keyIdentified: false,
            hasContact: false,
            hasPepSanction: false,
        };
        triggers.person.something = (triggers.person.someScreened || triggers.person.someIdentified);

        triggers.idControls = [];
        triggers.idControlReminders = [];
        triggers.idControlSents = [];
        triggers.screenings = [];
        triggers.amsList = [];

        const keyCheck = {
            hasCeo: false, hasChair: false, hasRrh: false, hasContact: false,
            idCeo: false, idChair: false, idRrh: false, idContact: false,
        };

        for (const i in persons) {
            //console.log('TRG person', persons[i]);

            if (persons[i].idc.done) {

                triggers.idControls.push({
                    name: persons[i].idc.name,
                    date: new Date(persons[i].idc.date),
                    done: true
                });

            }
            if (persons[i].idc.sent && persons[i].idc.reminders) {
                triggers.idControlSents.push({
                    name: persons[i].displayName,
                    date: new Date(persons[i].idc.sentDate),
                    done: false
                });
                if (persons[i].idc.reminders) {
                    for (const j in persons[i].idc.reminders) {
                        triggers.idControlReminders.push(persons[i].idc.reminders[j]);
                    }
                }
            }

            if (persons[i].screening.done) {

                triggers.screenings.push({
                    name: persons[i].displayName,
                    date: new Date(persons[i].screening.date),
                    done: true
                });

            }

            if (persons[i].web.done && persons[i].web.completed) {

                triggers.amsList.push({
                    name: persons[i].displayName,
                    date: new Date(persons[i].web.date),
                    done: true
                });

            }

            if (persons[i].screening.done && (persons[i].screening.isPep || persons[i].screening.hasSanction)) {
                triggers.person.hasPepSanction = true;
            }

            // Key role identified
            if (v1personHelper.hasRole(persons[i], 'role_customer_contact')) {
                keyCheck.hasContact = true;
                if (persons[i].idc.done) keyCheck.idContact = true;
            }
            if (v1personHelper.hasRole(persons[i], 'role_ceo')) {
                keyCheck.hasCeo = true;
                if (persons[i].idc.done) keyCheck.idCeo = true;
            }
            if (v1personHelper.hasRole(persons[i], 'role_chair')) {
                keyCheck.hasChair = true;
                if (persons[i].idc.done) keyCheck.idChair = true;
            }
            if (v1personHelper.hasRole(persons[i], 'role_rrh')) {
                keyCheck.hasRrh = true;
                if (persons[i].idc.done) keyCheck.idRrh = true;
            }

            // Contact person
            if (v1personHelper.hasRole(persons[i], 'role_customer_contact')) {
                triggers.person.hasContact = true;
            }
        }

        //console.log('key check', keyCheck);
        if (keyCheck.hasContact) {
            if (keyCheck.idContact) triggers.person.keyIdentified = true;
        }else if (keyCheck.hasCeo) {
            if (keyCheck.idCeo) triggers.person.keyIdentified = true;
        }else if (keyCheck.hasChair) {
            if (keyCheck.idChair) triggers.person.keyIdentified = true;
        }else if (keyCheck.hasRrh) {
            if (keyCheck.idRrh) triggers.person.keyIdentified = true;
        }

        //console.log('trigp', triggers.person);

        triggers.forms = {};

        for (const key in client) {
            if (!(key == 'report' || key == 'kyc') && client[key] && (client[key].envelopeId || client[key].saved)) {

                //console.log('form detected', key);
                //console.log('form detected', client[key]);

                triggers.forms[key] = {
                    sent: client[key].dateCreated ? true : false,
                    filled: client[key].filled || false,
                    signed: client[key].signed || false,
                    rejected: client[key].rejected || false,
                    aborted: client[key].aborted || false,
                    bounced: client[key].bounced || false,
                    saved: client[key].saved != null,
                    nextRole: client[key].next ? client[key].next.roleName || 'done' : 'done',
                    hasCheckout: client[key].hasCheckout || false,
                    reminders: client[key].reminders || [],
                    archive: [],
                    fields: client[key].fields,
                    analog: client[key].analog && client[key].analog.filled || false,
                    analogSigned: client[key].analog && client[key].analog.confirmed || false,
                    approval: client[key].approval,
                };
                for (const i in client[key].archive) {
                    triggers.forms[key].archive.push({
                        sent: client[key].archive[i].dateCreated ? true : false,
                        filled: client[key].archive[i].filled || false,
                        signed: client[key].archive[i].signed || false,
                        rejected: client[key].archive[i].rejected || false,
                        aborted: client[key].archive[i].aborted || false,
                        bounced: client[key].archive[i].bounced || false,
                        saved: client[key].archive[i].saved != null,
                        nextRole: client[key].archive[i].next ? client[key].archive[i].next.roleName || 'done' : 'done',
                        hasCheckout: client[key].archive[i].hasCheckout || false,
                        analog: client[key].archive[i].analog && client[key].archive[i].analog.filled || false,
                        analogSigned: client[key].archive[i].analog && client[key].archive[i].analog.confirmed || false,
                        approval: client[key].archive[i].approval,
                    });
                }

            }
        }


        triggers.values = {};
        for (const key in client) {
            if (client[key] && client[key].isValues) {

                //console.log('value detected', key);
                //console.log('value detected', client[key]);

                triggers.values[key] = {};
                for (const i in client[key].values) {
                    triggers.values[key][i] = client[key].values[i];
                }

            }
        }

        triggers.report = {
            created: client.report.created,
            stores: [],
            changed: false,
        };
        if (client.report.store) {
            for (const i in client.report.store) {
                triggers.report.stores.push({
                    name: root.getEmployeeName(client.report.store[i].userId),
                    date: new Date(client.report.store[i].date),
                });
            }
        }

        triggers.branch = {
            isMaster: root.credentials.isMasterBranch
        };

        if (client.files) {
            for (const i in client.files) {
                if (client.files[i].type == 'form-manual') {
                    if (!triggers.forms[client.files[i].formKey]) {
                        triggers.forms[client.files[i].formKey] = {
                            hasManual: true
                        };
                    }else {
                        triggers.forms[client.files[i].formKey].hasManual = true;
                        if (triggers.forms[client.files[i].formKey].analog) {
                            triggers.forms[client.files[i].formKey].analogSigned = true;
                        }
                    }
                }
            }
        }

        if (client.changed == 1) {
            triggers.report.changed = true;
        }

        //console.log('triggers', triggers);

    },

    //#region Default steps -----------------------------------------------------------------------------------------------

    getStepStatus(step, triggers, texts, index) {
        const result = {
            status: 0,
            text: {},
            subs: []
        };

        //console.log('get status', step);

        
        
        if (step.status.progress && step.status.progress.statuses) {

            for (const i in step.status.progress.statuses) {
                const status = step.status.progress.statuses[i];
                let valid = true;

                for (const j in status.triggers) {
                    if (!this.validateTrigger(status.triggers[j], triggers, index)) valid = false;
                }

                //console.log('progress', valid);

                if (valid) {
                    result.status = 3;
                    result.text = this.replaceTexts(status.text, texts, step, index);
                    result.subs.push({ status: result.status, text: result.text });
                }
            }

        }

        if (step.status.completed && step.status.completed.statuses) {

            for (const i in step.status.completed.statuses) {
                const status = step.status.completed.statuses[i];
                let valid = true;

                for (const j in status.triggers) {
                    if (!this.validateTrigger(status.triggers[j], triggers, index)) valid = false;
                }

                if (valid) {
                    result.status = 5;
                    result.text = this.replaceTexts(status.text, texts, step, index);
                    result.subs.push({ status: result.status, text: result.text });
                }
            }

        }

        if (step.status.warning && step.status.warning.statuses) {

            for (const i in step.status.warning.statuses) {
                const status = step.status.warning.statuses[i];
                let valid = true;

                for (const j in status.triggers) {
                    if (!this.validateTrigger(status.triggers[j], triggers, index)) valid = false;
                }

                if (valid) {
                    result.status = 8;
                    result.text = this.replaceTexts(status.text, texts, step, index);
                    result.subs.push({ status: result.status, text: result.text });
                }
            }

        }

        if (step.status.error && step.status.error.statuses) {

            for (const i in step.status.error.statuses) {
                const status = step.status.error.statuses[i];
                let valid = true;

                for (const j in status.triggers) {
                    if (!this.validateTrigger(status.triggers[j], triggers, index)) valid = false;
                }

                if (valid) {
                    result.status = 9;
                    result.text = this.replaceTexts(status.text, texts, step, index);
                    result.subs.push({ status: result.status, text: result.text });
                }
            }

        }

        //console.log('returning result with index ' + index, result);
        return result;
    },

    isActionRelevant(action, triggers, index) {
        let valid = true;

        //console.log('is relevant', action);

        for (const j in action.triggers) {
            if (!this.validateTrigger(action.triggers[j], triggers, index)) valid = false;
        }

        return valid;
    },

    isActionDisabled(action, triggers, index) {
        let valid = true;
        if (!action.disabled) return false;
        for (const j in action.disabled.triggers) {
            if (!this.validateTrigger(action.disabled.triggers[j], triggers, index)) valid = false;
        }
        return valid;
    },

    validateTrigger(trigger, triggers, index) {
        let value = false;

        //console.log('type = ' + trigger.type + ', trg = ' + trigger.trg);

        if (trigger.type == 'org') {

            if (trigger.trg == 'has-data') {

                value = triggers.org.fetched;

            }else if (trigger.trg == 'has-sanction') {

                value = triggers.org.hasSanction;

            }else if (trigger.trg == 'missing-data') {

                value = triggers.org.missingData;

            }else if (trigger.trg == 'ams-started') {

                value = triggers.org.amsStarted;

            }else if (trigger.trg == 'ams-completed') {

                value = triggers.org.amsCompleted;

            }else if (trigger.trg == 'ams-warning') {

                value = triggers.org.amsWarning;

            }else if (trigger.trg == 'ams-hits') {

                value = triggers.org.amsHits;

            }

        }else if (trigger.type == 'client') {

            if (trigger.trg == 'created') {

                value = triggers.client.created;

            }

        }else if (trigger.type == 'book') {

            if (trigger.trg == 'provided') {
                value = triggers.book.provided;
            }else if (trigger.trg == 'uploaded') {
                value = triggers.book.uploaded;
            }else if (trigger.trg == 'irrelevant') {
                value = triggers.book.irrelevant;
            }else if (trigger.trg == 'exists') {
                value = triggers.book.exists;
            }else if (trigger.trg == 'later') {
                value = triggers.book.later;
            }else if (trigger.trg == 'confirmed') {
                value = triggers.book.confirmed;
            }

        }else if (trigger.type == 'person') {
            

            if (trigger.trg == 'scr-some') {

                value = triggers.person.someScreened;
                //console.log('some', value);

            }else if (trigger.trg == 'idc-some') {

                value = triggers.person.someIdentified;
                //console.log('some', value);

            }else if (trigger.trg == 'idc-key') {

                value = triggers.person.keyIdentified;
                //console.log('some', value);

            }else if (trigger.trg == 'ams-some') {

                value = triggers.person.someAms;
                //console.log('some', value);

            }else if (trigger.trg == 'scr-all') {

                value = triggers.person.allScreened;
                //console.log('some', value);

            }else if (trigger.trg == 'idc-all') {

                value = triggers.person.allIdentified;
                //console.log('some', value);

            }else if (trigger.trg == 'ams-all') {

                value = triggers.person.allAms;
                //console.log('some', value);

            }else if (trigger.trg == 'something') {

                value = triggers.person.something;
                //console.log('some', value);

            }else if (trigger.trg == 'has-contact') {

                value = triggers.person.hasContact;
                //console.log('some', value);

            }else if (trigger.trg == 'has-hits') {

                value = triggers.person.hasPepSanction;
                //console.log('some', value);

            }

        }else if (trigger.type == 'idc') {
            

            if (trigger.trg == 'done') {

                value = triggers.idControls[index].done;
                //console.log('idc', index);

            }

        }else if (trigger.type == 'scr') {
            

            if (trigger.trg == 'done') {

                value = triggers.screenings[index].done;
                //console.log('scr', index);

            }

        }else if (trigger.type == 'form') {
            let formStatus = triggers.forms[trigger.key];
            //console.log('formStatus', formStatus);

            if (index != null) {
                formStatus = triggers.forms[trigger.key].archive[index];
                //console.log('formStatus', formStatus);
            }

            if (trigger.trg == 'signed') {
                value = formStatus && formStatus.signed;
            }else if (trigger.trg == 'filled') {
                value = formStatus && formStatus.filled;
            }else if (trigger.trg == 'sent') {
                value = formStatus && formStatus.sent;
            }else if (trigger.trg == 'sent') {
                value = formStatus && formStatus.sent;
            }else if (trigger.trg == 'rejected') {
                value = formStatus && formStatus.rejected;
            }else if (trigger.trg == 'aborted') {
                value = formStatus && formStatus.aborted;
            }else if (trigger.trg == 'bounced') {
                value = formStatus && formStatus.bounced;
            }else if (trigger.trg == 'saved') {
                value = formStatus && formStatus.saved;
            }else if (trigger.trg == 'manual') {
                value = formStatus && formStatus.hasManual;
            }else if (trigger.trg == 'analog') {
                value = formStatus && formStatus.analog;
            }else if (trigger.trg == 'analog-signed') {
                value = formStatus && formStatus.analogSigned;
            }else if (trigger.trg == 'approve-count') {

                let tValue = (formStatus && formStatus.approval && formStatus.approval.approvers) ? formStatus.approval.approvers.length : null;

                if (trigger.op == 'exists') {
                    value = (tValue != null);
                }else if (trigger.op == 'is') {
                    value = (tValue == trigger.value);
                }else if (trigger.op == 'lte') {
                    value = (tValue <= Number(trigger.value));
                }else if (trigger.op == 'gte') {
                    value = (tValue >= Number(trigger.value));
                }
                
            }else if (trigger.trg == 'approve-aborted') {

                value = false;

                if (formStatus && formStatus.approval && formStatus.approval.approvers) {
                    for (const i in formStatus.approval.approvers) {
                        if (formStatus.approval.approvers[i].acted && !formStatus.approval.approvers[i].approved) {
                            value = true;
                        }
                    }
                }

            }else if (trigger.trg == 'role') {

                if (trigger.op == 'is' && formStatus) {
                    value = (formStatus.nextRole == trigger.value);
                }else {
                    value = false;
                }

            }else if (trigger.value) {

                let tValue = null;
                try {
                    tValue = formStatus.fields[trigger.value];
                }catch (tex) {
                    // TODO fix this
                    //console.error(tex);
                }

                //console.log('T', tValue);

                if (trigger.op == 'exists') {
                    value = (tValue != null);
                }else if (trigger.op == 'is') {
                    value = (tValue == trigger.trg);
                }else if (trigger.op == 'lte') {
                    value = (tValue <= trigger.trg);
                }else if (trigger.op == 'gte') {
                    value = (tValue >= trigger.trg);
                }

            }

        }else if (trigger.type == 'report') {

            if (trigger.trg == 'created') {
                value = triggers.report.created;
            }else if (trigger.trg == 'stored') {
                value = triggers.report.stores.length > 0;
            }else if (trigger.trg == 'changed') {
                value = triggers.report.changed;
            }

        }else if (trigger.type == 'value') {

            const values = triggers.values[trigger.key];
            if (values && values[trigger.value]) {
                const tValue = values[trigger.value];

                //console.log('tValue', tValue);

                if (trigger.op == 'exists') {
                    value = (tValue != null);
                }else if (trigger.op == 'is') {
                    value = (tValue == trigger.trg);
                }else if (trigger.op == 'lte') {
                    value = (tValue <= trigger.trg);
                }else if (trigger.op == 'gte') {
                    value = (tValue >= trigger.trg);
                }
            }
            

        }else if (trigger.type == 'branch') {

            if (trigger.trg == 'master') {
                value = triggers.branch.isMaster;
            }
            

        }else if (trigger.type == 'tags') {

            if (trigger.trg == 'has-tag') {
                value = triggers.tags.includes(trigger.key) ? true : false;
            }
            

        }

        return trigger.rev ? !value : value;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    setTexts(root, texts, client, counts) {

        texts.createdUser = this.getTextForAllLanguages(root, root.getEmployeeName(client.userId));
        texts.reportUser = this.getTextForAllLanguages(root, root.getEmployeeName(client.report.userId));

        let bookUsername = 'Unknown user';
        if (client.book && client.book.userId) bookUsername = this.getTextForAllLanguages(root, root.getEmployeeName(client.book.userId))
        texts.bookUser = bookUsername;

        texts.sanctionMessage = {
            en_EN: 'Sanction search not performed'
        };
        if (client.company.name) {
            texts.sanctionMessage = this.getTextForAllLanguages(root, client.company.sanction.message);
        }

        if (client.web && client.web.done) {
            texts.amsUser = this.getTextForAllLanguages(root, root.getEmployeeName(client.web.userId));
            texts.amsResult = {
                en_EN: v1personHelper.getAmsResultText(root, client.web, 'en_EN'),
                nb_NO: v1personHelper.getAmsResultText(root, client.web, 'nb_NO'),
                sv_SE: v1personHelper.getAmsResultText(root, client.web, 'sv_SE'),
            };
        }

        if (client.report.store && client.report.store.length > 0) {
            texts.storeName = this.getTextForAllLanguages(root, root.getEmployeeName(client.report.store[client.report.store.length - 1].userId));
        }

        texts.screenedCount = this.getTextForAllLanguages(root, counts.screened.toString());
        texts.identifiedCount = this.getTextForAllLanguages(root, counts.identified.toString());
        texts.amsCount = this.getTextForAllLanguages(root, counts.ams.toString());
        texts.selectedCount = this.getTextForAllLanguages(root, counts.selected.toString());

        for (const key in client) {
            if (client[key] && client[key].envelopeId) {

                //console.log('form detected', client[key]);

                // Sender name
                texts[key + '-senderName'] = this.getTextForAllLanguages(root, root.getEmployeeName(client[key].userId));

                // Next actor name
                texts[key + '-nextName'] = this.getTextForAllLanguages(root, client[key].next ? client[key].next.name : '');

                // Rejector
                texts[key + '-rejecterName'] = this.getTextForAllLanguages(root, client[key].rejecter ? client[key].rejecter.name : '');
                texts[key + '-rejecterComment'] = this.getTextForAllLanguages(root, client[key].rejecter ? client[key].rejecter.comment : '');

                // Editor name
                let editorName = '';
                for (const i in client[key].editors) editorName = client[key].editors[i].name
                if (client[key].editors && client[key].editors.length == 0) {
                    editorName = root.getEmployeeName(client[key].userId);
                }
                texts[key + '-editorName'] = this.getTextForAllLanguages(root, editorName);

                // Signer names
                texts[key + '-signerNames'] = this.getTextForAllLanguages(root, this.getSignerNames(client[key].signers));

                // Custom fields
                if (client[key].fields) {
                    for (const i in client[key].fields) {
                        texts[key + '-' + i] = this.getCustomText(root, client.flow, i, client[key].fields[i]);
                    }
                }

                if (client[key].approval) {
                    let count = 0;
                    let names = '';
                    let aborted = false;
                    let aborterName = '';
                    let aborterComment = '';
                    for (const i in client[key].approval.approvers) {
                        if (client[key].approval.approvers[i].acted && client[key].approval.approvers[i].approved) {
                            if (count > 0) names += ', ';
                            names += client[key].approval.approvers[i].name;
                            count += 1;
                        }else if (client[key].approval.approvers[i].acted && !client[key].approval.approvers[i].approved) {
                            aborted = true;
                            aborterName = client[key].approval.approvers[i].name;
                            aborterComment = client[key].approval.approvers[i].comment;
                        }
                    }

                    texts[key + '-approvedCount'] = this.getTextForAllLanguages(root, count);
                    texts[key + '-approvedNames'] = this.getTextForAllLanguages(root, names);
                    texts[key + '-approvedAborterName'] = this.getTextForAllLanguages(root, aborterName);
                    texts[key + '-approvedAborterComment'] = this.getTextForAllLanguages(root, aborterComment);
                }

                if (client[key].archive) {
                    if (!texts.archive) texts.archive = {};
                    if (!texts.archive[key]) texts.archive[key] = [];
                    for (const i in client[key].archive) {
                        const obj = {};

                        //console.log('client[key].archive[i]', client[key].archive[i]);

                        // Next actor name
                        obj[key + '-nextName'] = this.getTextForAllLanguages(root, client[key].archive[i].next ? client[key].archive[i].next.name : '');
                        
                        // Rejector
                        obj[key + '-rejecterName'] = this.getTextForAllLanguages(root, client[key].archive[i].rejecter ? client[key].archive[i].rejecter.name : '');
                        obj[key + '-rejecterComment'] = this.getTextForAllLanguages(root, client[key].archive[i].rejecter ? client[key].archive[i].rejecter.comment : '');

                        // Signer names
                        obj[key + '-signerNames'] = this.getTextForAllLanguages(root, this.getSignerNames(client[key].archive[i].signers));

                        texts.archive[key].push(obj);
                    }
                }

            }
        }

        if (client.files) {
            for (const i in client.files) {
                if (client.files[i].type == 'form-manual') {
                    texts[client.files[i].formKey + '-uploadName'] = this.getTextForAllLanguages(root, root.getEmployeeName(client.files[i].userId));
                }
            }
        }

        texts.externalId = this.getTextForAllLanguages(root, client.externalId || '');

        //console.log('texts', texts);

    },

    getSignerNames(signers) {
        let signerString = ''; let count = 0;
        for (const i in signers) {
            if (signers[i].completed) {
                if (count > 0) signerString += ', ';
                if (signers[i].signature) {
                    signerString += signers[i].signature.name;
                }else {
                    signerString += signers[i].name;
                }
                count += 1;
            }
        }
        return signerString;
    },

    replaceTexts(text, texts, step, index) {
        if (!text) return '';
        const text1 = JSON.parse(JSON.stringify(text));
        const texts1 = JSON.parse(JSON.stringify(texts));
        if (step && step.form && index != null) {
            for (const i in text1) {
                for (const k in texts1.archive[step.form][index]) {
                    text1[i] = text1[i].split('{{' + k + '}}').join(texts1.archive[step.form][index][k][i]);
                }
            }
        }else {
            for (const i in text1) {
                for (const k in texts1) {
                    text1[i] = text1[i].split('{{' + k + '}}').join(texts1[k][i]);
                }
            }
        }
        return text1;
    },

    getTextForAllLanguages(root, text) {
        const value = {};
        for (const i in root.config.locales) {
            if (root.config.locales[i].enabled) {
                value[root.config.locales[i].key] = text;
            }
        }
        return value;
    },

    getCustomText(root, flowKey, key, value) {
        const flow = v1flows.getFlow(root, flowKey);

        if (flow.fields) {
            for (const i in flow.fields) {
                if (flow.fields[i].key == key) {

                    if (flow.fields[i].rawValue) {

                        return this.getTextForAllLanguages(root, value);

                    }else {
                        for (const j in flow.fields[i].values) {
                            if (flow.fields[i].values[j].key == value) {
                                return flow.fields[i].values[j];
                            }
                        }
                    }
                }
            }
        }

        return this.getTextForAllLanguages(root, value);

        return { en_EN: 'CUSTOM VALUE NOT FOUND', nb_NO: 'CUSTOM VALUE NOT FOUND' };
    }

};
export default triggerHelper;