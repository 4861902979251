<template>
<div>

    <PopupV1 :header="__t('common-reset')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <!-- <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('common-reset-hint') }}
            </div> -->
            <DialogContent>
                <DialogContentHeader :text="__t('files-document').toUpperCase() + ':'" />
                <DialogContentItem :text="__t('ams-name') + ' ' + params.name" :icon="'search'" />
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('common-reset')" :icon="'delete'" warning @clicked="onClick" :working="dialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';

export default {
    name: 'ResetAmsDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
    },
    props: {
        params: { type: Object, default: null },
        isCompany: { type: Boolean, default: false },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
        }
    },
    methods: {

        async onClick() {
            this.dialog.working = true;
            this.dialog.status = this.__t('common-resetting');

            if (this.isCompany) {
                await v1api.resetAdverseMediaOrg(this.params.id);
            }else {
                await v1api.resetAdverseMedia(this.params.id);
            }

            const response = await v1api.getClient(this.params.clientId);
            this.$emit('reload', response);
            this.$emit('close');

            this.dialog.working = false;
            this.dialog.show = false;
        },

        onClose() {
            this.$emit('close');
        }

    },
    created() {
        this.loading = false;
    }
}
</script>