<template>
    <div>
        <div class="cstmr-header">
            <div class="clh-letters">
                <div v-for="(letter, i) in letters" :key="i" class="clh-letter">
                    <div class="clh-color" :class="[{'clh-color-active' : letter.active}, {'clh-color-deselected' : letter.deselected}]" @click="addLetterFilter(letter)">
                        <label>{{ letter.letter }}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="aml-page" style="display: flex;">
            <vap-form-divider :left="25" :right="75">
                <template v-slot:left>
                    
                    <vap-label :text="__('customer_new')" header style="margin-top: 10px; margin-bottom: 10px;" />
                    <vap-card noPadding>
                        <vap-item float @clicked="onNewCustomerClicked">
                            <div class="common-add">
                                <div class="common-add-icon">
                                    <svg height="32" version="1.1" width="32" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.55);">
                                        <path d="M14.5 1.5C14.5 0.7 15.2 0 16 0C16.8 0 17.5 0.7 17.5 1.5V30.5C17.5 31.3 16.8 32 16 32C15.2 32 14.5 31.3 14.5 30.5V1.5Z"/>
                                        <path d="M30.5 14.5C31.3 14.5 32 15.2 32 16C32 16.8 31.3 17.5 30.5 17.5H1.5C0.7 17.5 0 16.8 0 16C0 15.2 0.7 14.5 1.5 14.5H30.5Z"/>
                                    </svg>
                                </div>
                                <div class="common-add-text">
                                    <label>{{ __('customer_new') }}</label>
                                </div>
                            </div>
                        </vap-item>
                    </vap-card>

                    <vap-label :text="__('filters')" header style="margin-top: 20px;" />

                    <SearchBox ref="fltSear" @search="onSearch" :placeholder="__('clients_search')" style="margin-top: 10px; margin-bottom: 16px;" />

                    <div class="cstmrs-filter-remove" v-if="anyFilters" @click="removeAllFilters">
                        <div class="cstmrs-filter-remove-icon">
                            <vap-icon tiny :icon="'cancel'" :color="'var(--header-text)'" />
                        </div>
                        <div class="cstmrs-filter-remove-text">
                            <label>{{ __('filters_remove') }}</label>
                        </div>
                    </div>

                    <div v-if="$root.config.portal.filterDateCreated">
                        <FilterControl empty :header="__(sortUpdated ? 'sort_last_updated' : 'sort_last_created')" />
                        <div style="float: left; width: 50%; margin-top: 10px;">
                            <SortDatePicker v-model="sortDateFrom" noHighlight noValidation :hintKey="'datepicker_from'" :placeholder="__('datepicker_select_date')" outputISO @input="onDateChanged" />
                        </div>
                        <div style="float: left; width: 50%; margin-top: 10px;">
                            <SortDatePicker v-model="sortDateTo" noHighlight noValidation :hintKey="'datepicker_to'" :placeholder="__('datepicker_select_date')" right outputISO @input="onDateChanged" />
                        </div>
                    </div>

                    <FilterControl ref="fltStat" :header="__($root.config.portal.filters.status.locale)" v-if="$root.config.portal.filters.status.enabled"
                        @filter="onFilterChanged" :filters="aggregates.statuses" :texts="statuses[$root.locale.key]"
                    />
                    <FilterControl ref="fltExpr" :header="__($root.config.portal.filters.expires.locale)" v-if="$root.config.portal.filters.expires.enabled"
                        @filter="onFilterChanged" :filters="aggregates.expires" :texts="expiresTexts"
                    />
                    <FilterControl ref="fltFlow" :header="__($root.config.portal.filters.flow.locale)" v-if="$root.config.portal.filters.flow.enabled"
                        @filter="onFilterChanged" :filters="aggregates.flows" :texts="flows[$root.locale.key]" :noMatch="__('common_unknown')" showRawFilter
                    />
                    <FilterControl ref="fltUser" :header="__($root.config.portal.filters.user.locale)" v-if="$root.config.portal.filters.user.enabled"
                        @filter="onFilterChanged" :filters="aggregates.users" :texts="usernames" :searchable="$root.config.portal.filters.user.search" :limit="$root.config.portal.filters.user.limit"
                    />
                    <FilterControl ref="fltRisk" :header="__($root.config.portal.filters.risk.locale)" v-if="$root.config.portal.filters.risk.enabled"
                        @filter="onFilterChanged" :filters="aggregates.risk" :texts="risks[$root.locale.key]"
                    />
                    <FilterControl ref="fltDept" :header="__($root.config.portal.filters.department.locale)" v-if="$root.config.portal.filters.department.enabled"
                        @filter="onFilterChanged" :filters="aggregates.departments" :texts="departments" :noMatch="__('common_unknown')"
                    />
                    <FilterControl ref="fltPart" :header="__($root.config.portal.filters.partner.locale)" v-if="$root.config.portal.filters.partner.enabled"
                        @filter="onFilterChanged" :filters="aggregates.partners" :texts="partners" :noMatch="__('common_unknown')" :searchable="$root.config.portal.filters.partner.search" :limit="$root.config.portal.filters.partner.limit"
                    />
                    <FilterControl ref="fltCust" :header="__($root.config.portal.filters.customstatus.locale)" v-if="$root.config.portal.filters.customstatus.enabled"
                        @filter="onFilterChanged" :filters="aggregates.customstatus" :texts="customstatuses[$root.locale.key]" :noMatch="__($root.config.portal.filters.customstatus.nomatch)" showRawFilter
                    />
                    <FilterControl ref="fltStat1" :header="__($root.config.portal.filters.status1.locale)" v-if="$root.config.portal.filters.status1.enabled"
                        @filter="onFilterChanged" :filters="aggregates.status1" :texts="customstatuses[$root.locale.key]" :searchable="$root.config.portal.filters.status1.search" 
                        :limit="$root.config.portal.filters.status1.limit" :noMatch="__($root.config.portal.filters.status1.nomatch)" showRawFilter
                    />
                    <FilterControl ref="fltStat2" :header="__($root.config.portal.filters.status2.locale)" v-if="$root.config.portal.filters.status2.enabled"
                        @filter="onFilterChanged" :filters="aggregates.status2" :texts="customstatuses[$root.locale.key]" :searchable="$root.config.portal.filters.status2.search" 
                        :limit="$root.config.portal.filters.status2.limit" :noMatch="__($root.config.portal.filters.status2.nomatch)" showRawFilter
                    />
                    <FilterControl ref="fltTags" :header="__($root.config.portal.filters.tags.locale)" v-if="$root.config.portal.filters.tags.enabled"
                        @filter="onFilterChanged" :filters="aggregates.tags" :texts="{}" :searchable="$root.config.portal.filters.partner.search" :limit="$root.config.portal.filters.tags.limit"
                    />
                    <FilterControl v-show="true == false" ref="fltLett" @filter="onFilterChanged"
                        :filters="aggregates.letters"
                    />

                </template>
                <template v-slot:right>
                    
                    <div style="display: flex;">
                        <vap-label :text="__('customers')" header style="margin-top: 10px; margin-bottom: 10px;" />
                        <SortSelector v-model="sortModel" :options="sortOptions" style="flex-shrink: 0; margin-top: 18px;" />
                    </div>

                    <div v-if="!loading" style="float: left; width: 100%;">
                        <vap-card noPadding v-for="(customer, i) in customers" :key="i" style="margin-bottom: 10px;">
                            <vap-item accented @clicked="onCustomerClicked(customer)">
                                <div class="cstmr">
                                    <div class="cstmr-icon" :class="[{'cstmr-high': customer.local.batchStatus == 1}, {'cstmr-med': customer.local.batchStatus == 3}, {'cstmr-low': customer.local.batchStatus == 5}]">
                                        <vap-icon absolute :top="5" :right="22" :icon="customer.type == 'person' ? 'person' : 'company'" :color="'var(--disabled-background)'" />
                                    </div>
                                    <div class="cstmr-main">
                                        <label class="cstmr-name">{{ companyTitleCase(customer.name) }}</label>
                                        <label class="cstmr-org">{{ '(' + (customer.local.identity == '1800-01-01' ? '' : customer.local.identity) + ')' }}</label>
                                    </div>
                                    <div class="cstmr-sub">
                                        <vap-status-label :completed="getStatusComplete(customer.status)" :name="''" :text="getStatusText(customer)" :aborted="customer.risk == 5" :warning="customer.risk == 3" />
                                        <!-- <label class="cstmr-exp">{{ getExpiresText(customer) }}</label>
                                        <label class="cstmr-rem">{{ getReminderText(customer) }}</label> -->
                                    </div>
                                    <div class="cstmr-right">
                                        <div class="cstmr-date">
                                            <label>{{ formatDate('datetime', sortUpdated ? customer.dateUpdated : customer.dateCreated) }}</label>
                                        </div>
                                        <div class="cstmr-user">
                                            <label>{{ customer.local.userName }}</label>
                                        </div>
                                    </div>
                                    <div class="cstmr-locked" v-if="$root.credentials.role == 'regular' && customer.userId != $root.credentials.uid">
                                        <vap-icon absolute :top="4" :right="22" :icon="'locked'" />
                                    </div>
                                </div>
                            </vap-item>
                        </vap-card>
                    </div>

                    <!-- Spinner -->
                    <div v-if="loading" class="flw100" style="position: relative; padding-top: 100px;">
                        <div style="position: absolute; left: 50%;"><vap-spinner medium /></div>
                    </div>

                    <div class="cstmr-no-hits" v-if="!loading && customers.length == 0">
                        <div class="cstmr-no-hits-icon">
                            <vap-icon :icon="'company'" massive :color="'var(--text-disabled)'" />
                        </div>
                        <div class="cstmr-no-hits-text">
                            <label>{{ __('customer_no_hits') }}</label>
                        </div>
                    </div>

                    <PageControl v-if="!loading" :total="totalPages" :current="currentPage" @page="onNavigate" />

                </template>
            </vap-form-divider>
        </div>

        <UserAccessDialog :params="userAccessDialogParams" v-if="userAccessDialogParams.show" @close="userAccessDialogParams.show = false" />

    </div>
</template>

<script>
import api from '../portalapi';
import log from '../log';

import PageControl from '../components/special/PageControl';
import FilterControl from '../components/special/FilterControl';
import SearchBox from '../components/special/SearchBox';
import SortSelector from '../components/special/SortSelector';
import SortDatePicker from '../components/special/SortDatePicker';
import tools from '../tools';

import UserAccessDialog from '../v1ui/UserAccessDialog.vue';

export default {
    name: 'Clients',
    components: {
        PageControl,
        FilterControl,
        SearchBox,
        SortSelector,
        SortDatePicker,
        UserAccessDialog
    },
    data() {
        return {
            loading: true,
            itemsPerPage: 10,
            currentPage: 0,
            totalPages: 0,
            totalCount: 0,
            searchString: '',
            filters: {
                letter: { active: false, values: [] },
                status: { active: false, values: [] },
                expires: { active: false, values: [] },
                flow: { active: false, values: [] },
                user: { active: false, values: [] },
                risk: { active: false, values: [] },
                department: { active: false, values: [] },
                partner: { active: false, values: [] },
                tags: { active: false, values: [] },
                customstatus: { active: false, values: [] },
                status1: { active: false, values: [] },
                status2: { active: false, values: [] },
            },
            anyFilters: false,
            filterObject: {},
            aggregates: {
                letters: [],
                statuses: [],
                expires: [],
                flows: [],
                users: [],
                risk: [],
                tags: [],
                customstatus: [],
                status1: [],
                status2: [],
            },
            customers: [],
            letters: [],
            usernames: {},
            statuses: {
                en_EN: {
                    'r-rep-c': 'Final report created',  // remove when safe
                    
                    'c-aml': 'Awaiting company search',
                    'c-aml-c': 'Company search completed',
                    'f-kyc-a': 'Awaiting sending declaration',
                    'f-kyc-b': 'Awaiting filling declaration',
                    'f-kyc-c': 'Awaiting signing declaration',
                    'f-kyc-o': 'Awating new recipient',
                    'f-kyc-q': '! - Declaration form aborted',

                    'b-per': 'Awaiting controls',
                    'b-per-a': 'Awaiting sending ID-control',
                    'b-per-c': 'Awaiting submit ID-control',
                    'b-per-f': 'Awaiting reference',
                    'b-per-g': 'Awaiting completion',
                    'b-per-x': 'Completed',

                    'r-rep-a': 'Awaiting controls',
                    'r-rep-h': 'Awaiting ID-control', 
                    'r-rep-o': 'Awaiting final report',
                    'r-rep-x': 'Final report created',
                    'r-rep-y': 'Final report created auto',

                    'r-rep-k': 'Awaiting ID-control and search',
                    'r-rep-l': 'Awaiting ID-control', 
                    'r-rep-m': 'Awaiting search', 

                    'x-ref-a': 'Completed',

                    // Checklists
                    'g-kyc-e': 'Awaiting screening',
                    'g-kyc-k': 'Awaiting QC Review',
                    'g-kyc-h': 'Awaiting info from branch',
                    'g-kyc-o': 'Checklist completed',
                },
                nb_NO: {
                    'r-rep-c': 'Sluttrapport fullført',  // remove when safe

                    'c-aml': 'Avventer selskapssøk',
                    'c-aml-c': 'Selskapssøk fullført',
                    'f-kyc-a': 'Avventer utsendelse egenerklæring',
                    'f-kyc-b': 'Avventer utfylling egenerklæring',
                    'f-kyc-c': 'Avventer signering egenerklæring',
                    'f-kyc-o': 'Avventer ny mottaker',
                    'f-kyc-q': '! - Egenerklæringsskjema avbrutt',

                    'b-per': 'Avventer kontroller',
                    'b-per-a': 'Avventer utsendelse ID-kontroll',
                    'b-per-c': 'Avventer svar ID-kontroll',
                    'b-per-f': 'Avventer referanse',
                    'b-per-g': 'Avventer fullførelse',
                    'b-per-x': 'Fullført',

                    'r-rep-a': 'Avventer kontroller',
                    'r-rep-h': 'Avventer svar ID-kontroll', 
                    'r-rep-o': 'Avventer sluttrapport', 
                    'r-rep-x': 'Sluttrapport fullført',
                    'r-rep-y': 'Sluttrapport fullført automatisk',
                    'r-rep-z': 'Sluttrapport lagret',

                    'r-rep-k': 'Avventer ID-kontroll og søk',
                    'r-rep-l': 'Avventer ID-kontroll', 
                    'r-rep-m': 'Avventer søk', 
                    'r-rep-zz': 'Fullført personflyt', 

                    'x-ref-a': 'Fullført',
                },
                sv_SE: {
                    'c-aml': 'Väntar på företagssökning',
                    'c-aml-c': 'Företagssökning slutförd',
                    'f-kyc-a': 'Väntar på leverans kundkännedom',
                    'f-kyc-b': 'Väntar färdigställning kundkännedom',
                    'f-kyc-c': 'Väntar på signering kundkännedom',
                    'f-kyc-o': 'Väntar på ny mottagare',
                    'f-kyc-q': '! - Kundkännedom avbröts',

                    'b-per': 'Väntar på kontroller',
                    'b-per-f': 'Väntar på referens',

                    'r-rep-a': 'Väntar på kontroller',
                    'r-rep-h': 'Väntar på svar ID-kontroll', 
                    'r-rep-o': 'Väntar på slutrapport', 
                    'r-rep-x': 'Kundkontroll slutförd',
                    'r-rep-y': 'Kundkontroll slutförd automatisk',

                    'r-rep-k': 'Avventer ID-kontroll og søk',
                    'r-rep-l': 'Avventer ID-kontroll', 
                    'r-rep-m': 'Avventer søk', 

                    'x-ref-a': 'Fullført',
                }
            },
            flows: {
                en_EN: {
                    'org-no-baml': 'Norwegian company',
                    'org-se-trap': 'Swedish company',
                    'org-uk-chouse': 'British company',
                    'companyBrregLookup': 'Norwegian company',
                    'org-no-unreg': 'Norwegian company under creation',
                    'org-manual': 'Forreign company',
                    'person': 'Person',
                    'personLookup': 'Person',
                    'snn-bank': 'SpareBank 1 Regnskap',
                    'snn-bank2': 'Opplastet egenerklæring',
                },
                nb_NO: {
                    'org-no-baml': 'Foretak i Brønnøysundregistrene',
                    'org-se-trap': 'Foretak i Bolagsverket',
                    'org-uk-chouse': 'Britisk foretak',
                    'companyBrregLookup': 'Foretak i Brønnøysundregistrene',
                    'org-no-unreg': 'Foretak under stiftelse',
                    'org-manual': 'Utenlandsk foretak',
                    'person': 'Person',
                    'personLookup': 'Person',
                    'snn-bank': 'SpareBank 1 Regnskap',
                    'snn-bank2': 'Opplastet egenerklæring',
                },
                sv_SE: {
                    'org-no-baml': 'Företag i Brønnøysundregistret',
                    'org-se-trap': 'Företag i Bolagsverket',
                    'companyBrregLookup': 'Företag i Brønnøysundregistret',
                    'org-no-unreg': 'Företag som grundas',
                    'org-manual': 'Utländskt företag',
                    'person': 'Person',
                    'personLookup': 'Person',
                    'snn-bank': 'SpareBank 1 Regnskap',
                    'snn-bank2': 'Opplastet egenerklæring',
                }
            },
            risks: {
                en_EN: {
                    0: 'Not assessed',
                    1: 'Low',
                    3: 'Medium',
                    5: 'High',
                },
                nb_NO: {
                    0: 'Ikke vurdert',
                    1: 'Lav',
                    3: 'Medium',
                    5: 'Høy',
                },
                sv_SE: {
                    0: 'Inte betygsatt',
                    1: 'Låg',
                    3: 'Medium',
                    5: 'Hög',
                }
            },
            customstatuses: {
                en_EN: {
                    'sa-c': 'Awaiting signature',
                    'sa-f': 'Signed',

                    'ser_prop': 'Business brokerage',
                    'ser_sec': 'Securities',
                    'ser_both': 'Both',
                    'cla_yes': 'Reclassified',
                    'cla_no': 'Not reclassified',
                    'cla_na': 'Not applicable',
                },
                nb_NO: {
                    'sa-c': 'Avventer signering',
                    'sa-f': 'Signert',

                    'r-ok': 'Lagt til',
                    'r-na': 'Avventer referensenummer',

                    'ser_prop': this.$root.credentials.key == 'pangea-da' ? 'Låne- og garantiformidling' : 'Næringsmegling',
                    'ser_sec': 'Verdipapirtjenester',
                    'ser_both': 'Begge',
                    'cla_yes': 'Omklassifisert',
                    'cla_no': 'Ikke omklassifisert',
                    'cla_na': 'Ikke tatt stilling',
                }
            },
            departments: {},
            partners: {},
            expiresTexts: {},
            expireOffset: 0,
            sortUpdated: false,
            sortModel: 'date_updated_desc',
            sortOptions: [
                { key: 'sort_last_updated', value: 'date_updated_desc' },
                { key: 'sort_last_created', value: 'date_created_desc' },
                { key: 'sort_name', value: 'name_desc' },
            ],
            sortDateFrom: '',
            sortDateTo: '',
            filterDateFrom: null,
            filterDateTo: null,
            filterDateActive: false,
            userAccessDialogParams: { show: false },
        }
    },
    watch: {
        sortModel: {
            deep: false,
            handler(newVal, oldVal) {
                if (this.loading) return;
                console.log('Sort model changed', this.sortModelTrigger);
                console.log('Sort model changed', newVal);
                this.sortUpdated = (newVal != 'date_created_desc');
                this.getCustomers(this.filterObject);
            }
        }
    },
    methods: {
        
        //----- Filters --------------------------------------------------------------------------

        onSearch(str) {
            this.searchString = str;
            this.onFilterChanged();
        },

        onDateChanged() {
            console.log('on date changed');
            if (this.sortDateFrom && this.sortDateTo) {
                this.filterDateFrom = new Date(this.sortDateFrom);
                this.filterDateTo = new Date(this.sortDateTo + 'T23:59:59.000Z');
                this.filterDateActive = true;
                this.onFilterChanged();
            }
        },

        onFilterChanged() {
            if (this.$refs.fltLett) this.filters.letter.active = this.$refs.fltLett.activeFilters.length > 0;
            if (this.$refs.fltLett) this.filters.letter.values = this.$refs.fltLett.activeFilters;
            if (this.$refs.fltUser) this.filters.user.active = this.$refs.fltUser.activeFilters.length > 0;
            if (this.$refs.fltUser) this.filters.user.values = this.$refs.fltUser.activeFilters;
            if (this.$refs.fltStat) this.filters.status.active = this.$refs.fltStat.activeFilters.length > 0;
            if (this.$refs.fltStat) this.filters.status.values = this.$refs.fltStat.activeFilters;
            if (this.$refs.fltDept) this.filters.department.active = this.$refs.fltDept.activeFilters.length > 0;
            if (this.$refs.fltDept) this.filters.department.values = this.$refs.fltDept.activeFilters;
            if (this.$refs.fltPart) this.filters.partner.active = this.$refs.fltPart.activeFilters.length > 0;
            if (this.$refs.fltPart) this.filters.partner.values = this.$refs.fltPart.activeFilters;
            if (this.$refs.fltExpr) this.filters.expires.active = this.$refs.fltExpr.activeFilters.length > 0;
            if (this.$refs.fltExpr) this.filters.expires.values = this.$refs.fltExpr.activeFilters;
            if (this.$refs.fltFlow) this.filters.flow.active = this.$refs.fltFlow.activeFilters.length > 0;
            if (this.$refs.fltFlow) this.filters.flow.values = this.$refs.fltFlow.activeFilters;
            if (this.$refs.fltRisk) this.filters.risk.active = this.$refs.fltRisk.activeFilters.length > 0;
            if (this.$refs.fltRisk) this.filters.risk.values = this.$refs.fltRisk.activeFilters;
            if (this.$refs.fltTags) this.filters.tags.active = this.$refs.fltTags.activeFilters.length > 0;
            if (this.$refs.fltTags) this.filters.tags.values = this.$refs.fltTags.activeFilters;
            if (this.$refs.fltCust) this.filters.customstatus.active = this.$refs.fltCust.activeFilters.length > 0;
            if (this.$refs.fltCust) this.filters.customstatus.values = this.$refs.fltCust.activeFilters;
            if (this.$refs.fltStat1) this.filters.status1.active = this.$refs.fltStat1.activeFilters.length > 0;
            if (this.$refs.fltStat1) this.filters.status1.values = this.$refs.fltStat1.activeFilters;
            if (this.$refs.fltStat2) this.filters.status2.active = this.$refs.fltStat2.activeFilters.length > 0;
            if (this.$refs.fltStat2) this.filters.status2.values = this.$refs.fltStat2.activeFilters;
            this.anyFilters = (
                this.filters.letter.active
                || this.filters.user.active
                || this.filters.status.active
                || this.filters.risk.active
                || this.filters.department.active
                || this.filters.partner.active
                || this.filters.expires.active
                || this.filters.flow.active
                || this.filters.tags.active
                || this.filters.customstatus.active
                || this.searchString
                || this.filterDateActive
            );
            this.applyFilters();
            this.currentPage = 1;
            this.getCustomers(this.filterObject);
        },

        applyFilters() {
            this.filterObject = { "$and": [] };
            if (this.getFilterCount() == 0 && !this.searchString) this.filterObject = {};

            if (this.searchString) {
                this.filterObject["$and"].push({ name: { $regex: this.searchString, $options: 'i' }});
            }

            if (this.filterDateActive) {
                this.filterObject["$and"].push({ dateCreated: { $lte: new Date(this.filterDateTo), $gte: new Date(this.filterDateFrom) }});
            }

            if (this.filters.status.active) {
                this.filterObject["$and"].push({ status: { $in: this.filters.status.values }});
            }
            if (this.filters.user.active) {
                this.filterObject["$and"].push({ userId: { $in: this.filters.user.values }});
            }
            if (this.filters.letter.active) {
                this.filterObject["$and"].push({ letter: { $in: this.filters.letter.values }});
            }
            if (this.filters.risk.active) {
                this.filterObject["$and"].push({ risk: { $in: this.filters.risk.values }});
            }
            if (this.filters.flow.active) {
                this.filterObject["$and"].push({ flow: { $in: this.filters.flow.values }});
            }
            if (this.filters.department.active) {
                this.filterObject["$and"].push({ department: { $in: this.filters.department.values }});
            }
            if (this.filters.partner.active) {
                this.filterObject["$and"].push({ partner: { $in: this.filters.partner.values }});
            }
            if (this.filters.tags.active) {
                this.filterObject["$and"].push({ tags: { $in: this.filters.tags.values }});
            }
            if (this.filters.customstatus.active) {
                this.filterObject["$and"].push({ custstat: { $in: this.filters.customstatus.values }});
            }
            if (this.filters.expires.active) {
                const filter = this.filters.expires.values[0];
                if (filter.match != undefined) {
                    this.filterObject["$and"].push({ expires: filter.match });
                }else if (filter.less != undefined && filter.more != undefined) {
                    this.filterObject["$and"].push({ expires: { $lt: filter.less, $gt: filter.more }});
                }else if (filter.less != undefined) {
                    this.filterObject["$and"].push({ expires: { $lt: filter.less }});
                }else if (filter.more != undefined) {
                    this.filterObject["$and"].push({ expires: { $gt: filter.more}});
                }
            }
            console.log('filters', this.filterObject);
        },

        getFilterCount() {
            let count = 0;
            if (this.filters.status.active) count += 1;
            if (this.filters.user.active) count += 1;
            if (this.filters.letter.active) count += 1;
            if (this.filters.risk.active) count += 1;
            if (this.filters.department.active) count += 1;
            if (this.filters.partner.active) count += 1;
            if (this.filters.expires.active) count += 1;
            if (this.filters.flow.active) count += 1;
            if (this.filters.tags.active) count += 1;
            if (this.filters.customstatus.active) count += 1;
            if (this.filters.status1.active) count += 1;
            if (this.filters.status2.active) count += 1;
            if (this.filterDateActive) count += 1;
            return count;
        },

        removeAllFilters() {
            if (this.$refs.fltLett) this.$refs.fltLett.removeFilters();
            if (this.$refs.fltUser) this.$refs.fltUser.removeFilters();
            if (this.$refs.fltStat) this.$refs.fltStat.removeFilters();
            if (this.$refs.fltDept) this.$refs.fltDept.removeFilters();
            if (this.$refs.fltPart) this.$refs.fltPart.removeFilters();
            if (this.$refs.fltExpr) this.$refs.fltExpr.removeFilters();
            if (this.$refs.fltRisk) this.$refs.fltRisk.removeFilters();
            if (this.$refs.fltFlow) this.$refs.fltFlow.removeFilters();
            if (this.$refs.fltTags) this.$refs.fltTags.removeFilters();
            if (this.$refs.fltCust) this.$refs.fltCust.removeFilters();
            if (this.$refs.fltStat1) this.$refs.fltStat1.removeFilters();
            if (this.$refs.fltStat2) this.$refs.fltStat2.removeFilters();
            if (this.$refs.fltSear) this.$refs.fltSear.reset();
            this.filterDateActive = false;
            this.sortDateFrom = '';
            this.sortDateTo = '';
            this.searchString = '';
            this.onFilterChanged();
        },

        highlightFilters() {
            if (this.$refs.fltUser) this.$refs.fltUser.highlightFilters(this.filters.user);
            if (this.$refs.fltStat) this.$refs.fltStat.highlightFilters(this.filters.status);
            if (this.$refs.fltDept) this.$refs.fltDept.highlightFilters(this.filters.department);
            if (this.$refs.fltPart) this.$refs.fltPart.highlightFilters(this.filters.partner);
            if (this.$refs.fltExpr) this.$refs.fltExpr.highlightFilters(this.filters.expires);
            if (this.$refs.fltRisk) this.$refs.fltRisk.highlightFilters(this.filters.risk);
            if (this.$refs.fltFlow) this.$refs.fltFlow.highlightFilters(this.filters.flow);
            if (this.$refs.fltTags) this.$refs.fltTags.highlightFilters(this.filters.tags);
            if (this.$refs.fltCust) this.$refs.fltCust.highlightFilters(this.filters.customstatus);
            if (this.$refs.fltStat1) this.$refs.fltStat1.highlightFilters(this.filters.status1);
            if (this.$refs.fltStat2) this.$refs.fltStat2.highlightFilters(this.filters.status2);
        },

        addLetterFilter(letter) {
            if (letter.active) {
                this.$refs.fltLett.addFilter(letter.letter);
            }
        },

        //-----------------------------------------------------------------------------------------

        onNavigate(page) {
            this.currentPage = page;
            this.getCustomers(this.filterObject);
        },

        async getCustomers(filters) {
            this.loading = true;

            let sort = { dateUpdated: -1 };
            if (this.sortModel == 'date_created_desc') sort = { dateCreated: -1 };
            if (this.sortModel == 'name_desc') sort = { name: 1 };

            //const skip = (Number(this.currentPage) - 1) * Number(this.itemsPerPage);
            //const response = await api.getFilteredCustomers(this.$root.config.portal.filters, filters, skip, this.itemsPerPage, sort);

            const xfilters = [];
            if (this.searchString) {
                xfilters.push({ field: 'name', op: 'search', value: this.searchString });
            }
            if (this.filterDateActive) {
                xfilters.push({ field: 'dateCreated', op: 'daterange', from: this.filterDateFrom, to: this.filterDateTo });
            }
            if (this.filters.status.active) xfilters.push({ field: 'status', op: 'in', values: this.filters.status.values });
            if (this.filters.user.active) xfilters.push({ field: 'userId', op: 'in', values: this.filters.user.values });
            if (this.filters.letter.active) xfilters.push({ field: 'letter', op: 'in', values: this.filters.letter.values });
            if (this.filters.risk.active) xfilters.push({ field: 'risk', op: 'in', values: this.filters.risk.values });
            if (this.filters.flow.active) xfilters.push({ field: 'flow', op: 'in', values: this.filters.flow.values });
            if (this.filters.department.active) xfilters.push({ field: 'department', op: 'in', values: this.filters.department.values });
            if (this.filters.partner.active) xfilters.push({ field: 'partner', op: 'in', values: this.filters.partner.values });
            if (this.filters.tags.active) xfilters.push({ field: 'tags', op: 'in', values: this.filters.tags.values });
            if (this.filters.customstatus.active) xfilters.push({ field: 'custstat', op: 'in', values: this.filters.customstatus.values });
            if (this.filters.status1.active) xfilters.push({ field: 'status1', op: 'in', values: this.filters.status1.values });
            if (this.filters.status2.active) xfilters.push({ field: 'status2', op: 'in', values: this.filters.status2.values });

            console.log('query', this.$root.config.portal.migrate);
            console.log('query', this.$root.config.portal.migrateLink);

            if (this.$root.config.portal.migrate || this.$root.config.portal.migrateLink) {
                xfilters.push({ field: 'migrated', op: 'exists', values: false });
            }

            const from = (Number(this.currentPage) - 1) * Number(this.itemsPerPage);
            const limit = this.itemsPerPage;
            const include = [];
            const counts = ['letter'];
            for (const i in this.$root.config.portal.filters) {
                if (this.$root.config.portal.filters[i].enabled) {
                    let key = i;
                    if (key == 'customstatus') key = 'custstat';
                    if (key == 'user') key = 'userId';
                    counts.push(key);
                }
            }

            const response = await api.queryCustomers(xfilters, from, limit, sort, include, counts);

            this.aggregates.letters = response.filters.letter;
            this.aggregates.statuses = response.filters.status;
            this.aggregates.users = response.filters.userId;
            this.aggregates.risk = response.filters.risk;
            this.aggregates.flows = response.filters.flow;
            this.aggregates.departments = response.filters.department;
            this.aggregates.partners = response.filters.partner;
            this.aggregates.expires = response.filters.expires;
            this.aggregates.tags = response.filters.tags;
            this.aggregates.customstatus = response.filters.custstat;
            this.aggregates.status1 = response.filters.status1;
            this.aggregates.status2 = response.filters.status2;

            if (this.aggregates.statuses) this.aggregates.statuses.sort((a, b) => (a._id > b._id) ? 1 : -1);
            if (this.aggregates.expires) this.aggregates.expires.sort((a, b) => (a._id > b._id) ? 1 : -1);

            if (this.aggregates.users) {
                for (const key in this.aggregates.users) {
                    this.aggregates.users[key].display = this.$root.getEmployeeName(this.aggregates.users[key]._id);
                }
                this.aggregates.users.sort((a, b) => (a.display > b.display) ? 1 : -1);
            }

            if (this.aggregates.partners) {
                for (const key in this.aggregates.partners) {
                    //this.aggregates.partners[key]._id = Number(this.aggregates.partners[key]._id);
                    this.aggregates.partners[key].display = this.$root.getPartnerName(this.aggregates.partners[key]._id);
                }
                //console.log('partners', this.aggregates.partners);
                this.aggregates.partners.sort((a, b) => (a.display > b.display) ? 1 : -1);
            }

            this.letters = [];
            const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ#';
            for (let i=0; i < str.length; i++) {
                let match = false;
                for (let j=0; j < this.aggregates.letters.length; j++) {
                    if (this.aggregates.letters[j]._id == str.charAt(i)) { match = true; break; }
                }
                this.letters.push({ letter: str.charAt(i), active: match, deselected: false });
            }

            this.totalCount = response.count;
            this.totalPages = Math.ceil(Number(this.totalCount) / Number(this.itemsPerPage));

            this.customers = response.customers;
            for (const i in this.customers) {
                let identity = this.customers[i].identity;
                if (this.customers[i].type == 'person') {
                    const person = tools.decodePerson(this.customers[i].identity);
                    identity = person.dateOfBirth;
                }
                this.customers[i].local = {
                    batchStatus: 0,
                    identity: identity,
                    userName: this.$root.getEmployeeName(this.customers[i].userId)
                };
            }

            this.saveFilters();

            log.v('Clients: getCustomers, skip=' + from + ' count=' + this.totalCount + ' pages=' + this.totalPages);
            this.loading = false;
        },

        onCustomerClicked(customer) {
            if (this.$root.credentials.role == 'regular' && customer.userId != this.$root.credentials.uid) {
                this.userAccessDialogParams.clientName = customer.name;
                this.userAccessDialogParams.ownerId = customer.userId;
                this.userAccessDialogParams.show = true;
            }else {
                this.$parent.navigate({ path: '/client', query: { id: customer._id } });
            }
        },

        onNewCustomerClicked() {
            this.$parent.navigate({ path: '/new' });
        },

        getIconColor(risk) {
            switch (risk) {
                case 1: return 'var(--success-text)';
                case 3: return 'var(--warning-text)';
                case 5: return 'var(--error-text)';
                default: return 'var(--disabled-text)';
            }
        },

        getExpiresText(customer) {
            //if (customer.flow == 'personLookup' || customer.flow == 'companyBrregLookup') return 'Dato opprettet: ' + tools.dateString(customer.dateCreated);

            if (!this.$root.config.portal.filters.expires.enabled) return '';
            if (customer.status.includes('r-rep')) return '';
            let str = this.__('clients_expires') + ' ';
            if (customer.expires != -1 && customer.expires != -2) {
                const days = Number(customer.expires) - Number(this.expireOffset);
                if (days == 1) {
                    str += this.__('clients_expires_tomorrow');
                }else if (days == 0) {
                    str += this.__('clients_expires_today');
/*                 }else if (days < 0) {
                    str = this.__('clients_expires_expired'); */
                }else {
                    str += days + ' ' + this.__('clients_expires_in') + ' ' + this.__('clients_expires_days');
                }
                return str;
            }else {
                return '';
            }
        },

        getReminderText(customer) {
            if (customer.flow == 'personLookup' || customer.flow == 'companyBrregLookup') return customer.ref ? 'Referanse: ' + customer.ref : '';

            if (!this.$root.config.portal.reminders) return '';
            if (customer.status.includes('r-rep')) return '';
            let str = this.__('clients_reminder') + ' ';
            //console.log(customer.expires)
            if (customer.expires != -1 && customer.expires != -2) {
                if (!customer.reminder) return str + this.__('clients_reminder_never');
                const days = Math.abs(Number(customer.reminder) - Number(this.expireOffset));
                if (days == 0) {
                    str += this.__('clients_reminder_today');
                }else if (days == 1) {
                    str += this.__('clients_reminder_yesterday');
                }else {
                    str += days + ' ' + this.__('clients_reminder_days_ago');
                }
                return str;
            }else {
                return '';
            }
        },

        getStatusText(customer) {
            let str = '';
            const exp = this.getExpiresText(customer);
            const rem = this.getReminderText(customer);
            if (exp) str += ' | ' + exp;
            if (rem) str += ' | ' + rem;

            if (this.statuses[this.$root.locale.key] && this.statuses[this.$root.locale.key][customer.status]) {
                return this.statuses[this.$root.locale.key][customer.status] + str;
            }else {
                return this.statuses['en_EN'][customer.status] + str;
            }
            return customer.status;
        },

        getStatusComplete(status) {
            if (status == 'r-rep-x' || status == 'r-rep-y' || status == 'b-per-x' || status == 'g-kyc-o') return true;
        },

        saveFilters() {
            this.$root.clientListCache = {
                anyFilters: this.anyFilters,
                aggregates: this.aggregates,
                customers: this.customers,
                filters: this.filters,
                totalCount: this.totalCount,
                totalPages: this.totalPages,
                currentPage: this.currentPage,
                letters: this.letters,
            };
        },

        loadFilters() {
            this.anyFilters = this.$root.clientListCache.anyFilters;
            this.aggregates = this.$root.clientListCache.aggregates;
            this.customers = this.$root.clientListCache.customers;
            this.filters = this.$root.clientListCache.filters;
            this.totalCount = this.$root.clientListCache.totalCount;
            this.totalPages = this.$root.clientListCache.totalPages;
            this.currentPage = this.$root.clientListCache.currentPage;
            this.letters = this.$root.clientListCache.letters;

            
            const self = this;
            setTimeout(function() {
                self.highlightFilters();
            }, 100);

            this.loading = false;
            log.w('Clients: Clients and filters loaded from cache');
        },

    },
    async created() {
        this.loading = true;
        this.$parent.setTitle(this.__('customers'));

        this.sortModel = this.$root.config.portal.defaultSort || 'date_updated_desc';

        // Load employees
        for (let i in this.$root.employees) {
            this.usernames[this.$root.employees[i].id] = this.$root.employees[i].name;
        }

        if (this.$root.config.portal.departments) {
            const departments = {};
            for (const i in this.$root.departments) {
                departments[this.$root.departments[i].key] = this.$root.departments[i].name;
            }
            this.departments = departments;

            const partners = {};
            for (const i in this.$root.partners) {
                partners[this.$root.partners[i].key] = this.$root.partners[i].firstName + ' ' + this.$root.partners[i].lastName;
            }
            this.partners = partners;
        }

        const thisDate = new Date();
        const initialDate = new Date(2020, 9, 1);
        this.expireOffset = Math.ceil((thisDate - initialDate) / 8.64e7);
        this.expiresTexts = {
            range: true,
            values: [
                {
                    less: -1,
                    text: 'pending',
                    nb_NO: 'Ikke sendt ut',
                    en_EN: 'Not sent out',
                },
                {
                    less: this.expireOffset + 15,
                    more: this.expireOffset + 7,
                    text: 'exp14',
                    nb_NO: 'Utløper om 8 - 14 dager',
                    en_EN: 'Expires in 8 - 14 days',
                },
                {
                    less: this.expireOffset + 8,
                    more: this.expireOffset + 4,
                    text: 'exp7',
                    nb_NO: 'Utløper om 5 - 7 dager',
                    en_EN: 'Expires in 5 - 7 days',
                },
                {
                    less: this.expireOffset + 5,
                    more: -1,
                    text: 'exp5',
                    nb_NO: 'Utløper om under 5 dager',
                    en_EN: 'Expires in under 5 days',
                },
                {
                    more: this.expireOffset + 14,
                    text: 'expired',
                    nb_NO: 'Utløpt',
                    en_EN: 'Expired',
                },
                {
                    less: 0,
                    more: -2,
                    text: 'completed',
                    nb_NO: 'Fullført',
                    en_EN: 'Completed',
                }
            ]
        };
        //console.log(this.expiresTexts);
        //console.log(this.expireOffset);

        if (this.$root.clientListCache.anyFilters) {
            this.loadFilters();
        }else {
            this.currentPage = 1;
            this.getCustomers({});
        }
    }
}
</script>
<style>

.cstmr-header {
    height: 72px;
    background-color: var(--stepper-bg);
    box-shadow: 1px 1px 2px rgba(166, 177, 198, 0.3);
}

.cstmr-no-hits {
    float: left;
    width: 100%;
}
.cstmr-no-hits-icon {
    width: 100px;
    margin: 0 auto;
    margin-top: 100px;
}
.cstmr-no-hits-text {
    text-align: center;
    color: var(--text-weak);
    margin-top: 20px;
    margin-bottom: 100px;
}




.clh-letters {
    width: 100%;
    margin: 0 auto;
    max-width: 1540px;
    display: table;
    padding: 20px 40px;
    box-sizing: border-box;
}
.clh-letter {
    display: table-cell;
    width: 45px;
    height: 36px;
    padding: 0px 2px;
    text-align: center;
    box-sizing: border-box;
}
.clh-color {
    padding-top: 2px;
    border-radius: 3px;
    background-color: var(--text-disabled);
    opacity: 0.5;
}
.clh-color-active {
    background-color: var(--primary);
    opacity: 1;
    cursor: pointer;
}
.clh-color-active:hover {
    opacity: 0.9;
}

.clh-color label {
    font-family: "Roboto Slab", serif;
    font-size: 24px;
    color: white;
}
.clh-color-active label {
    cursor: pointer;
}

.clh-color-deselected {
    opacity: 0.5;
}


.cstmrs-filter-remove {
    float: left;
    width: 100%;
    height: 30px;
    display: flex;
    color: var(--accent-contrast);
    border-radius: 4px;
    background-color: var(--accent);
    cursor: pointer;
}
.cstmrs-filter-remove-icon {
    margin-top: 3px;
    margin-left: 4px;
}
.cstmrs-filter-remove-text {
    padding-top: 5px;
    padding-left: 8px;
}
.cstmrs-filter-remove-text label {
    font-size: 15px;
    cursor: pointer;
}



.cstmr {
    position: relative;
    padding: 14px 14px;
}
.cstmr-icon {
    position: absolute;
    top: 13px;
    width: 46px; height: 46px;
    text-align: center;
    border-radius: 50%;
    /* background-color: var(--text-disabled); */
    box-sizing: border-box;
}
.cstmr-low {
    color: var(--success-text);
    background-color: var(--success-background);
}
.cstmr-med {
    color: var(--warning-text);
    background-color: var(--warning-background);
}
.cstmr-high {
    color: var(--error-text);
    background-color: var(--error-background);
}
.cstmr-icon label {
    font-size: 20px;
}
.cstmr-main {
    margin-top: 3px;
    margin-left: 64px;
    font-weight: 500;
    color: var(--text-strong);
}
.cstmr-name {
    font-size: 16px;
    color: var(--text-title);
}
.cstmr-org {
    margin-left: 6px;
    color: var(--text-weak);
}
.cstmr-sub {
    margin-top: 2px;
    margin-left: 64px;
    color: var(--text-weak);
}
.cstmr-exp {
    margin-left: 20px;
    color: var(--text-accent);
}
.cstmr-rem {
    margin-left: 20px;
    color: var(--text-weak);
}
.cstmr-locked {
    position: absolute;
    top: 15px;
    right: 250px;
}

.cstmr-right {
    position: absolute;
    top: 18px;
    right: 22px;
    text-align: right;
}
.cstmr-date {
    font-weight: 500;
    color: var(--text-title);
}
.cstmr-user {
    margin-top: 5px;
    font-weight: 500;
    color: var(--text-weak);
}



</style>

