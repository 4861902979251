<template>
<div class="frv2-d-btn-group">
    
    <div class="frv2-d-btn-group-right" v-if="alignRight" />

    <slot />

    <div class="frv2-d-btn-group-left" v-if="!alignRight" />

</div>
</template>
<script>
export default {
    name: 'DesignerButtonGroup',
    props: {
        alignRight: { type: Boolean, default: false },
    },
    methods: {

        

    },
}
</script>
<style scoped>

.frv2-d-btn-group {
    float: left;
    width: 100%;
    display: flex;
}

.frv2-d-btn-group-right {
    flex-grow: 100;
}

.frv2-d-btn-group-left {

}

</style>