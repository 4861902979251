<template>
    <div class="brreg-base" v-on-clickaway="hideDropdown">
        <input ref="field" id="brreginput"
           @input="handleInput"
           @keydown="handleKeyPress"
           @focus="onInputFocus"
           :placeholder="__('customer_brreg_placeholder')"
           spellcheck="false"
           autocomplete="off"
           class="form-control" :class="dropdown ? 'brreg-has-results' : ''">

        <div class="brreg-icon" v-if="promises.length == 0">
            <svg height="50" version="1.1" width="50" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.45);">
                <path d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"/>
            </svg>
        </div>

<!--         <div class="select-spinner spinner-border spinner-border-sm brreg-loading" v-if="promises.length > 0">
            <span class="sr-only">Loading...</span>
        </div> -->

        <div class="brreg-results" v-if="dropdown">
            <div class="brreg-result" v-for="(result, i) in results" :key="i" :class="result.selected ? 'brreg-selected' : ''" @click="handleClick(i)">
                <label>{{ result.name }}</label>
            </div>
        </div>

    </div>
</template>

<script>
import axios from "axios"
import { mixin as clickaway } from "vue-clickaway"

export default {
    name: 've-brreg-search',
    mixins: [clickaway],
    props: {
        icon: {
            type: String,
            default: 'search'
        }
    },
    data() {
        return {
            results: [],
            promises: [],
            companies: [],
            dropdown: false,
            selectedIndex: -1
        }
    },
    computed: {
        
    },
    watch: {
        companies: {
            deep: false,
            handler(val) {
                this.results = [];
                for (let i in val) {
                    this.results.push({
                        name: val[i].text,
                        selected: false
                    });
                }
                if (val.length > 0) {
                    this.showDropdown();
                }else {
                    this.hideDropdown();
                }
            }
        }
    },
    methods: {
        handleInput(e) {
            //let elem = document.getElementById('brreg-acl');
            let t = e.srcElement.value.toUpperCase();
            e.srcElement.value = t;
            this.handleSearch(e.srcElement.value);
        },
        handleKeyPress(e) {
            if (e.keyCode == 38) {
                e.preventDefault();
                this.moveSelected(-1);
            }else if (e.keyCode == 40) {
                e.preventDefault();
                this.moveSelected(1);
            }else if (e.keyCode == 13) {
                e.preventDefault();
                this.selectCompany(this.selectedIndex);
            }
        },
        moveSelected(by) {
            this.selectedIndex += by;
            if (this.selectedIndex > this.results.length - 1) this.selectedIndex = 0;
            if (this.selectedIndex < 0) this.selectedIndex = this.results.length - 1;
            for (let i in this.results) this.results[i].selected = false;
            this.results[this.selectedIndex].selected = true;
        },
        selectCompany(index) {
            let comp = this.companies[index];
            //console.log(comp);
            let elem = document.getElementById('brreginput');
            elem.value = comp.value.navn;
            this.hideDropdown();
            let company = {};
            this.setCompany(company, comp.value);
            this.$emit('company-selected', company, comp.value);
        },
        setCompany(company, data) {
            company.org_no = String(data.organisasjonsnummer) //orgno.substr(0,3) + " " + orgno.substr(3,3) + " " + orgno.substr(6,3)
            company.name = data.navn
            company.org_form = data.organisasjonsform.kode
            company.org_form_desc = data.organisasjonsform.beskrivelse
            company.registration_date = data.registreringsdatoEnhetsregisteret
            company.employees = data.antallAnsatte
            company.start_date = data.stiftelsesdato

            if (data.forretningsadresse) {
                company.address = data.forretningsadresse.adresse.toString()
                company.zip = data.forretningsadresse.postnummer
                company.town = data.forretningsadresse.poststed
                company.country = data.forretningsadresse.land;
            }
            if (data.naeringskode1) {
                company.business = data.naeringskode1.beskrivelse
                company.business_code = data.naeringskode1.kode
            }
            
            company.inBrReg = true
            company.inBusinessReg = data.registrertIForetaksregisteret
            company.inMvaReg = data.registrertIMvaregisteret
            company.bankrupt = data.konkurs
            company.liquidation = data.underAvvikling
            company.compulsoryLiquidation = data.underTvangsavviklingEllerTvangsopplosning
        },
        handleClick(index) {
            this.selectCompany(index);
        },
        showDropdown() {
            this.dropdown = true;
        },
        hideDropdown() {
            this.dropdown = false;
        },
        onInputFocus() {
            if (this.results.length > 0) this.showDropdown();
        },
        handleSearch(e) {
            if (e && !isNaN(e)) {
                var org = e.toString().replace(/[.\s]/g,"")
                if ( org.length != 9 ) {
                    this.companies = []
                    return
                }

                var data = []
                
                if (this.checkNr(org)) { 
                    this.companyOrgNoSearch(org)
                    .then(res => {
                        data.push(res.data)
                        this.companies = this.format(data);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.promises.shift()
                        }, 500) 
                    })
                }
            } else {
                if(e.length >= 2) {

                    this.promises.push({})

                    this.companyNameSearch(e)
                    .then(res => {
                        this.companies = this.format(res.data._embedded.enheter);
                        if (e.includes('BETAL')) {
                            this.companies.unshift({
                                text: '(DEV) BETALINGER AS',
                                value: {
                                    antallAnsatte: 5,
                                    forretningsadresse: {
                                        adresse: ['Dronningens gate'],
                                        kommune: 'NARVIK',
                                        kommunenummer: 0,
                                        land: 'Norge',
                                        landkode: 'NO',
                                        postnummer: '8514',
                                        poststed: 'NARVIK'
                                    },
                                    hjemmeside: 'www.verified.eu',
                                    institusjonellSektorkode: {
                                        beskrivelse: 'Private aksjeselskaper mv.',
                                        kode: '2100'
                                    },
                                    konkurs: false,
                                    maalform: 'Bokmål',
                                    naeringskode1: {
                                        beskrivelse: 'Hvitvasking av penger og annen økonomisk kriminalitet',
                                        kode: '62.010'
                                    },
                                    navn: 'BETALINGER AS',
                                    organisasjonsform: {
                                        beskrivelse: 'Aksjeselskap',
                                        kode: 'AS'
                                    },
                                    organisasjonsnummer: '613838465',
                                    registreringsdatoEnhetsregisteret: '2014-11-03',
                                    registrertIForetaksregisteret: true,
                                    registrertIFrivillighetsregisteret: false,
                                    registrertIMvaregisteret: false,
                                    registrertIStiftelsesregisteret: false,
                                    sisteInnsendteAarsregnskap: 'Aldri',
                                    stiftelsesdato: '2014-10-08',
                                    underAvvikling: false,
                                    underTvangsavviklingEllerTvangsopplosning: true
                                }
                            });
                        }else if (e.includes('UTLEIE')) {
                            this.companies.unshift({
                                text: '(DEV) UTLEIE GRUPPEN AS',
                                value: {
                                    antallAnsatte: 5,
                                    forretningsadresse: {
                                        adresse: ['Dronningens gate'],
                                        kommune: 'NARVIK',
                                        kommunenummer: 0,
                                        land: 'Norge',
                                        landkode: 'NO',
                                        postnummer: '8514',
                                        poststed: 'NARVIK'
                                    },
                                    hjemmeside: 'www.verified.eu',
                                    institusjonellSektorkode: {
                                        beskrivelse: 'Private aksjeselskaper mv.',
                                        kode: '2100'
                                    },
                                    konkurs: false,
                                    maalform: 'Bokmål',
                                    naeringskode1: {
                                        beskrivelse: 'Hvitvasking av penger og annen økonomisk kriminalitet',
                                        kode: '62.010'
                                    },
                                    navn: 'UTLEIE GRUPPEN AS',
                                    organisasjonsform: {
                                        beskrivelse: 'Aksjeselskap',
                                        kode: 'AS'
                                    },
                                    organisasjonsnummer: '900433824',
                                    registreringsdatoEnhetsregisteret: '2014-11-03',
                                    registrertIForetaksregisteret: true,
                                    registrertIFrivillighetsregisteret: false,
                                    registrertIMvaregisteret: false,
                                    registrertIStiftelsesregisteret: false,
                                    sisteInnsendteAarsregnskap: 'Aldri',
                                    stiftelsesdato: '2014-10-08',
                                    underAvvikling: false,
                                    underTvangsavviklingEllerTvangsopplosning: true
                                }
                            });
                        }
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.promises.shift()
                        }, 500) 
                    })
                } else {
                    this.companies = []
                }
            }
        },
        companyNameSearch(name) {
            return new Promise((resolve) => {
                axios.get("https://data.brreg.no/enhetsregisteret/api/enheter?page=0&size=10&navn=" + encodeURIComponent(name))
                .then(res => resolve(res))
                .catch(e => { console.log(e) })
            })
        },
        companyOrgNoSearch(nr) {

            return new Promise((resolve) => {
                axios.get("https://data.brreg.no/enhetsregisteret/api/enheter/" + encodeURIComponent(nr))
                .then(res => resolve(res))
                .catch(e => { console.log(e) })
            })
        },
        mod11(input) {
            var control = 2, sum = 0;
            for(var i = input.length - 2; i >= 0; --i) {
                sum += input.charAt(i) * control;
                if(++control > 7) control = 2;
            }
            var result = (11 - sum % 11);
            return result === 11 ? 0 : result;
        },

        checkNr(nr) {
            if (!nr) return false
            var check = parseInt(nr.charAt(nr.length - 1), 10)
            return check === this.mod11(nr)
        },
        format(data) {

            if(!data) {
                return
            }

            let companies = []

            for(let org of data) {
                companies.push({
                    text: org.navn,
                    value: org
                })
            }

            return companies
        }
    },
    async mounted() {

        

    }
}
</script>
<style scoped>

.form-control {
    width: 100%;
    padding: 11px 15px;
    padding-top: 12px;
    border-radius: 3px;
    border: solid 1px lightgray;
    box-sizing: border-box;
    background: none;
    outline: none;
}


.brreg-base {
    float: left;
    width: 100%;
    position: relative;
}
.brreg-base input {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: bold;
}

.brreg-has-results {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.brreg-results {
    position: absolute;
    width: 100%;
    background-color: white;
    border: solid 1px #218bcb;
    border-top: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-sizing: border-box;
    z-index: 9999999999;
}

.brreg-result {
    padding: 5px 10px;
    cursor: pointer;
}
.brreg-result label {
    cursor: pointer;
}
.brreg-result:hover {
    background-color: lightgray;
}
.brreg-selected {
    background-color: #3190D5;
}

.brreg-loading {
    top: 10px;
    right: 10px;
}

.brreg-icon {
    position: absolute;
    top: -4px;
    right: 1px;
}
.brreg-icon path {
    fill: gray;
}

::-webkit-input-placeholder { /* Edge */
    font-weight: normal;
}

:-ms-input-placeholder { /* Internet Explorer */
    font-weight: normal;
}

::placeholder {
    font-weight: normal;
}

</style>