<template>
    <div>

        <vap-button :text="'haha'" @clicked="haha" />

        <div v-for="(t, i) in test" :key="'t' + i">
            <label>{{ t.label }}</label>
        </div>

        <PreviewPopupNew v-if="showPreview" ref="preview" />

        

    </div>
</template>

<script>
import PreviewPopupNew from '../components/documents/PreviewPopupNew';

export default {
    name: 'ViewerTest',
    components: {
        PreviewPopupNew
    },
    data() {
        return {
            showPreview: false,
            test: [
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
                { label: 'Dette er bare en test'},
            ]
        }
    },
    methods: {
        
        haha() {
            this.$root.enableScrolling(!this.$root.scrollingEnabled);
        },

        showPreviewEnvelope() {
            this.showPreview = true;
            this.$nextTick(() => {
                this.$root.enableScrolling(false);
                if (this.$refs.preview) this.$refs.preview.loadEnvelope('HyxA9MHgt');
            });
            
        },

    },
    async created() {
        console.log(this.$refs);
        this.showPreviewEnvelope();
    }
}
</script>

<style scoped>


</style>