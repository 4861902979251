<template>
    <div v-if="resources['chkLevel']" class="frmod-cl-i" @click="handleClick" :id="'c' + item.key" :class="[
        { 'frmod-cl-i-selected': selected },
        { 'frmod-cl-i-invalid': validation.done && !validation.valid },
    ]">

        <div class="frmod-cl-x" v-if="visible[roleKey][0]" :style="'width: ' + widths[roleKey][0] + '%; border-left: none;'">
            <div class="frmod-tt" v-if="hasTooltip()">
                <vap-icon :icon="'help-o'" small>
                    <template v-slot:tooltip>
                        <vap-tooltip :text="getTooltip()" />
                    </template>
                </vap-icon>
            </div>
            <div class="frmod-cl-html" v-html="item.desc[$root.locale.key]" />
        </div>
        <div class="frmod-cl-x" v-if="visible[roleKey][1] && !item.isQA" :style="'width: ' + widths[roleKey][1] + '%;'" :class="{'frmod-cl-e': isEmptyID()}">
            <ChecklistDropdown v-if="!designer && !isEmptyID()" v-model="model.ids" :options="resources[item.ids].items" :height="height" @input="handleChange" :disabled="(roleKey == 'approver' && !resources.isCDD)  || roleKey == 'viewer'" />
            <ChecklistDropdown v-if="designer && !isEmptyID()" v-model="designerModelID" :options="resources[item.ids].items" :height="height" @input="onDropdownInput('ids')" />
        </div>
        <div class="frmod-cl-x" v-if="visible[roleKey][2] && !item.isQA" :style="'width: ' + widths[roleKey][2] + '%;'" :class="{'frmod-cl-e': isEmptyVS()}">
            <ChecklistDropdown v-if="!designer && !isEmptyVS()" v-model="model.vs" :options="resources[item.vs].items" :height="height" @input="handleChange" :disabled="(roleKey == 'approver' && !resources.isCDD)  || roleKey == 'viewer'" />
            <ChecklistDropdown v-if="designer && !isEmptyVS()" v-model="designerModelVS" :options="resources[item.vs].items" :height="height" @input="onDropdownInput('vs')" />
        </div>

        <div class="frmod-cl-x" v-if="item.isQA" :style="'width: ' + widths[roleKey][4] + '%;'">
            <ChecklistDropdown v-if="!designer" v-model="model.qcr" :options="resources[item.qcr].items" :height="height" @input="handleChange" colored :disabled="roleKey == 'viewer'" />
        </div>

        <div class="frmod-cl-x" v-if="visible[roleKey][3] || item.isQA" :style="'width: ' + widths[roleKey][3] + '%;'">
            <div class="frmod-cl-com">

                <vap-icon :icon="'add'" tiny action absolute :top="-6" :right="5" @clicked="onAddCommentClicked" v-if="roleKey != 'viewer'" />
                <label class="frmod-cl-com-empty" v-if="designer || model.comments.length == 0">{{ 'No comments' }}</label>

                <div class="frmod-cl-com-itm" v-for="(comment, i) in model.comments" :key="item.key + i">
                    <div class="frmod-cl-com-itm-h">
                        <div class="frmod-cl-com-itm-n">
                            {{ getRoleName(comment.role) }}
                        </div>
                        <div class="frmod-cl-com-itm-d">
                            {{ formatDate('format', comment.date) }}
                        </div>
                    </div>
                    <div class="frmod-cl-com-itm-t">
                        {{ comment.text }}
                    </div>
                </div>

            </div>
        </div>

        <div class="frmod-cl-x" v-if="item.isQA" :style="'width: ' + widths[roleKey][5] + '%;'">
            <ChecklistDropdown v-if="!designer" v-model="model.qcc" :options="resources[item.qcc].items" :height="height" @input="handleChange" colored :disabled="roleKey == 'viewer'" />
        </div>

        <div class="frmod-cl-x" v-if="visible[roleKey][4] && !item.isQA" :style="'width: ' + widths[roleKey][4] + '%;'">
            <ChecklistDropdown v-if="!designer && enabled[roleKey][4]" v-model="model.qcr" :options="resources[item.qcr].items" :height="height" @input="handleChange" colored :disabled="roleKey != 'approver'" />
        </div>
        <div class="frmod-cl-x" v-if="visible[roleKey][5] && !item.isQA" :style="'width: ' + widths[roleKey][5] + '%;'">
            <ChecklistDropdown v-if="!designer && enabled[roleKey][5]" v-model="model.qcc" :options="resources[item.qcc].items" :height="height" @input="handleChange" colored :disabled="roleKey != 'approver'" />
        </div>

        <div class="frmod-cl-logic" v-if="selected" :id="'l' + item.key" @click="handleLogicClick" @mousedown="handleLogicClick">
            <svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.7);">
                <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 9 C 3 10.093063 3.9069372 11 5 11 L 9 11 C 10.093063 11 11 10.093063 11 9 L 11 5 C 11 3.9069372 10.093063 3 9 3 L 5 3 z M 5 5 L 9 5 L 9 9 L 5 9 L 5 5 z M 5 13 L 5 15 L 7 15 L 7 13 L 5 13 z M 15 13 C 13.906937 13 13 13.906937 13 15 L 13 19 C 13 20.093063 13.906937 21 15 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 15 C 21 13.906937 20.093063 13 19 13 L 15 13 z M 15 15 L 19 15 L 19 19 L 15 19 L 15 15 z M 5 17 L 5 19 L 7 19 L 7 17 L 5 17 z M 9 17 L 9 19 L 11 19 L 11 17 L 9 17 z"/>
            </svg>
        </div>

        <ve-popup v-if="commentDialog.show" isStatic :header="'Add comment'" :width="600" @close="commentDialog.show = false" hideCloseButton style="position: fixed;">
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c12>
                            <vap-label desc :text="'Comment'" />
                            <vap-input v-model="commentDialog.comment" :rules="'required'" :id="'com'" @input="validateCommentDialog" @onreturn="onCommentInputReturn" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="commentDialog.show = false" />
                    <vap-button :text="__('common_save')" uppercase @clicked="onAddComment" :working="commentDialog.working" :disabled="!commentDialog.valid" />
                </vap-button-row>
            </div>
        </ve-popup>

    </div>
</template>
<script>
import ChecklistDropdown from './ChecklistDropdown.vue';

export default {
    name: "frmod-checklist-item",
    components: {
        ChecklistDropdown
    },
    props: {
        item: { type: Object, default: () => { return {} }},
        model: { type: Object, default: () => { return {} }},
        resources: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        roleKey: { type: String, default: 'owner' },
        validation: { type: Object, default: () => { return { done: false } }},
    },
    data() {
        return {
            height: 0,
            designerModelID: '',
            designerModelVS: '',
            visible: {
                owner: [true, true, true, true, true, true],
                checker: [true, true, true, true, true, true],
                editor: [true, true, true, true, true, true],
                approver: [true, true, true, true, true, true],
                provider: [true, true, true, true, true, true],
                viewer: [true, true, true, true, true, true],
            },
            widths: {
                owner: [30, 10, 10, 30, 10, 10],
                checker: [30, 10, 10, 30, 10, 10],
                editor: [30, 10, 10, 30, 10, 10],
                approver: [30, 10, 10, 30, 10, 10],
                provider: [30, 10, 10, 30, 10, 10],
                viewer: [30, 10, 10, 30, 10, 10],
            },
            enabled: {
                owner: [true, true, true, true, false, false],
                checker: [true, true, true, true, false, false],
                editor: [true, true, true, true, false, false],
                approver: [true, true, true, true, true, true],
                provider: [true, true, true, true, true, true],
                viewer: [true, true, true, true, true, true],
            },
            commentDialog: { show: false, valid: false, working: false, comment: '' },
        }
    },
    watch: {
        width: {
            deep: false,
            handler(val) {
                
            }
        }
    },
    methods: {

        onDropdownInput(type) {
            console.log('dd input', type);
            console.log('dd input', this.designerModelID);

            let value = '';

            if (type == 'ids') {
                value = this.designerModelID;
            }else if (type == 'vs') {
                value = this.designerModelVS;
            }
            this.designerModelID = '';
            this.designerModelVS = '';

            const params = {
                toKey: this.item.key,
                type: type,
                value: value,
            };

            this.$emit('chklogic', params);
        },

        handleLogicClick() {
            this.$emit('logic', this.item.key);
        },

        hasTooltip() {
            if (this.item.tooltip && this.item.tooltip.en_EN) {
                return true;
            }
            return false;
        },

        getTooltip() {
            return this.item.tooltip.en_EN;
        },
        
        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

        handleChange() {
            this.$emit('input');
            this.setHeight();
        },

        setHeight() {
            if (this.designer) return;
            this.height = 0;
            this.$nextTick(() => {
                this.height = (document.getElementById('c' + this.item.key).clientHeight - 6);
            });
        },

        isEmptyID() {
            if (this.item.ids == 'chkNone') return true;
            const level = this.resources['chkLevel'].value;
            if (level >= this.item.idsL) {
                this.model.idsD = false;
                return false;
            }else {
                this.model.idsD = true;
                return true;
            }
            return true;
        },

        isEmptyVS() {
            if (this.item.vs == 'chkNone') return true;
            const level = this.resources['chkLevel'].value;
            if (level >= this.item.vsL) {
                this.model.vsD = false;
                return false;
            }else {
                this.model.vsD = true;
                return true;
            }
            return true;
        },

        onAddCommentClicked() {
            this.commentDialog.show = true;

            this.$nextTick(() => {

                const elem = document.getElementById('inpidcom');
                console.log('elem', elem);
                elem.focus();

            });

        },

        validateCommentDialog() {
            this.commentDialog.valid = true;
        },

        onCommentInputReturn() {
            if (!this.commentDialog.valid) return;
            this.onAddComment();
        },

        onAddComment() {
            this.model.comments.push({
                role: this.roleKey,
                date: new Date(),
                text: this.commentDialog.comment
            });
            this.commentDialog.show = false;
            this.$emit('input');
            this.setHeight();
            this.commentDialog.comment = '';
        },

        getRoleName(key) {
            switch (key) {
                case 'owner': return 'Branch';
                case 'checker': return 'Screening Team';
                case 'editor': return 'Branch';
                case 'approver': return 'CDD';
                case 'provider': return 'Branch';
            }
            return key;
        }

    },
    mounted() {
        //console.log('item', this.item);
        this.setHeight();
    }
}

</script>
<style>

.frmod-cl-i {
    position: relative;
    display: table-row; 
    height: 100%; 
    box-sizing: border-box;
}

.frmod-cl-x {
    font-size: 14px;
    display: table-cell;
    border-top: solid 1px lightgray;
    border-left: solid 1px lightgray;
    box-sizing: border-box;
}

.frmod-cl-e {
    background-color: lightgray;
}

.frmod-cl-html {
    margin: 0px;
    padding: 5px 7px;
    padding-left: 30px;
}

.frmod-cl-html p {
    margin: 0px;
}

.frmod-cl-i-selected .frmod-cl-x {
    border: solid 1px #238ccc;
}



.frmod-cl-com {
    position: relative;
}

.frmod-cl-com-empty {
    margin-left: 6px;
    color: var(--text-disabled);
}

.frmod-cl-com-itm {
    padding: 0px 6px;
    padding-bottom: 5px;
}
.frmod-cl-com-itm-h {
    font-weight: 500;
    display: flex;
}
.frmod-cl-com-itm-n {
    margin-right: 8px;
}
.frmod-cl-com-itm-d {
    font-size: 14px;
    color: var(--text-weak);
}
.frmod-cl-com-itm-t {
    
}


.frmod-tt {
    position: absolute;
}



/* --- Validation -------------------- */

.frmod-cl-i-invalid .frmod-cl-x {
    color: var(--val-error);
    /* border: solid 1px var(--val-error); */
}

.frmod-cl-i-invalid .frmod-cl-e {
    color: var(--val-error);
}

/* ----------------------------------- */

.frmod-cl-logic {
    position: absolute;
    top: 1px; right: 2px;
    width: 26px; height: 26px;
    border-radius: 50%;
    background-color: #3190D5;
    cursor: pointer;
    padding-top: 1px;
    padding-left: 1.5px;
    box-sizing: border-box;
}
.frmod-cl-logic path {
    fill: white;
}

</style>
