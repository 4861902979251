<template>
    <div>
        <vap-row v-if="value">

            <vap-group c6>
                <vap-label :text="__('common_name')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.name" value />
                <vap-input v-model="value.name" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>
            <vap-group c6>
                <vap-label :text="__('company_orgnr')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.orgnr" value />
                <vap-input numeric v-model="value.orgnr" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>

            <vap-group c6 v-if="!simple">
                <vap-label :text="__('common_address')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.address.business.street" value />
                <vap-input v-model="value.address.business.street" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation"  :rules="required ? 'required' : ''"/>
            </vap-group>
            <vap-group c3 v-if="!simple">
                <vap-label :text="__('common_zip')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.address.business.zip" value />
                <vap-input numeric v-model="value.address.business.zip" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>
            <vap-group c3 v-if="!simple">
                <vap-label :text="__('common_city')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.address.business.city" value />
                <vap-input v-model="value.address.business.city" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>


            <vap-group c3>
                <vap-label :text="__('company_reg_date')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.info.dateRegistered" value />
                <vap-datepicker v-model="value.info.dateRegistered" v-if="editMode" outputISO :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>
            <vap-group c3>
                <vap-label :text="__('company_org_form')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.form.formDescription" value />
                <vap-input v-model="value.form.formDescription" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>

            <vap-group c3>
                <vap-label :text="__('company_employees')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.employees" value />
                <vap-input numeric v-model="value.employees" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>

            <vap-group c3>
                <vap-label :text="__('common_country')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.info.country" value />
                <vap-dropdown v-if="editMode"
                    v-model="value.info.country"
                    :options="$root.countriesJson"
                    :commonOptions="$root.countriesCommon"
                    :placeholder="required ? __('common_required') : __('common_optional')"
                    :listKey="$root.locale.key"
                    :displayKey="$root.locale.key"
                    :outputKey="'key'"
                    :rules="required ? 'required' : ''"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation" />
            </vap-group>

        </vap-row>

        <div class="flw100" style="margin-bottom: 25px;">
            <vap-row v-for="(code, i) in value.naceCodes" :key="'bc' + i" :actionWidth="editMode ? 34 : 0">
                <vap-group c3 compact>
                    <vap-label v-if="i == 0" :text="__('company_business_codes')" desc />
                    <vap-label v-if="!editMode" :text="code.code" value />
                    <vap-input v-model="code.code" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
                </vap-group>
                <vap-group c9 compact>
                    <vap-label v-if="i == 0" :text="__('common_description')" desc />
                    <vap-label v-if="!editMode" :text="code.description" value />
                    <vap-input v-model="code.description" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
                </vap-group>
                <template v-slot:actions>
                    <vap-icon :icon="'delete'" absolute action @clicked="removeCode(i)">
                        <template v-slot:tooltip><vap-tooltip :text="__('common_remove')" /></template>
                    </vap-icon>
                </template>
            </vap-row>
            <div v-if="editMode" class="flw100" style="margin-top: 5px; margin-left: 10px;">
                <vap-label :text="__('common_add') + ' ' + __('company_business_code')" desc link @clicked="addCode" />
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'CompanyInputs',
    props: {
        value: {
            type: Object,
            default: null
        },
        role: {
            type: String,
            default: 'owner'
        },
        editMode: {
            type: Boolean,
            default: false
        },
        simple: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        noValidation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            noHighlight: true
        }
    },
    computed: {
        
    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.$nextTick(() => {
                    console.log('changed')
                    this.$forceUpdate();
                });
            }
        },
    },
    methods: {
        
        removeCode(index) {
            this.value.naceCodes.splice(index, 1);
        },

        addCode() {
            this.value.naceCodes.push({
                code: '',
                description: ''
            });
        },

    },
    async mounted() {

        console.log('brreg', this.value)
        //if (this.value) this.value.businessCodes = [];

    }
}
</script>
<style scoped>


</style>