<template>
<div style="float: left; width: 100%;">
    
    <div style="float: left; width: 60%;">

        <slot name="editor" />

    </div>

    <div style="float: left; width: 40%;">

        

    </div>

</div>
</template>

<script>

export default {
    name: 'FlowDesignerLayout',
    components: {
        
    },
    props: {
        flow: { type: Object, default: null },
    },
    data() {
        return {
            
        }
    },
    methods: {

        async onSave(data) {
            
        },

    },
    async created() {
        console.log('flow', this.flow);
        

    }
}
</script>