<template>
<div class="cstmrs-filter-remove" @click="$emit('remove')">
    <div class="cstmrs-filter-remove-icon">
        <vap-icon tiny :icon="'cancel'" :color="'var(--header-text)'" />
    </div>
    <div class="cstmrs-filter-remove-text">
        <label>{{ __('filters_remove') }}</label>
    </div>
</div>
</template>
<script>


export default {
    name: 'RemoveFilterButton',
}
</script>
<style scoped>

.cstmrs-filter-remove {
    float: left;
    width: 100%;
    height: 30px;
    display: flex;
    color: var(--accent-contrast);
    border-radius: 4px;
    background-color: var(--accent);
    cursor: pointer;
}
.cstmrs-filter-remove-icon {
    margin-top: 3px;
    margin-left: 4px;
}
.cstmrs-filter-remove-text {
    padding-top: 5px;
    padding-left: 8px;
}
.cstmrs-filter-remove-text label {
    font-size: 15px;
    cursor: pointer;
}

</style>