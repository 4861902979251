<template>
<div>
    <PropertyItem :header="'Button group properties'" :icon="'button'">
        <vap-row>
            <vap-group c12>
                <vap-label :text="'Alignment'" desc />
                <vap-dropdown 
                    v-model="input.align"
                    :options="alignOptions"
                    :displayKey="'display'"
                    :listKey="'display'"
                    :outputKey="'value'"
                />
            </vap-group>
        </vap-row>
    </PropertyItem>
    <PropertyItem :header="'Buttons'" :icon="'button'">
        <div>

            <div v-for="button in input.buttons" :key="button.key">

                <div>
                    <label>{{ 'Button' }}</label>
                </div>
                <vap-row>
                    <vap-group c12>
                        <vap-label :text="'Text'" desc />
                        <vap-input 
                            v-model="button.text[$root.locale.key]"
                        />
                    </vap-group>
                </vap-row>
            </div>

        </div>
    </PropertyItem>
</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';

export default {
    name: 'ButtonProperties',
    components: {
        PropertyItem,
    },
    props: {
        input: { type: Object, default: null },
    },
    data() {
        return {
            alignOptions: [
                { value: 1, display: 'Left' },
                { value: 2, display: 'Center' },
                { value: 3, display: 'Right' }
            ]
        }
    },
    methods: {
        
        

    },
    mounted() {

    }
}
</script>
<style scoped>



</style>
