<template>
<div>
    <div v-if="!loading && !hasError">

        <FormStepper :steps="steps" :offset="60" :stepKey="stepKey" :bottomSlotHeight="stepperMessageHeight"
            :title="title ? title[$root.locale.key] : ''"
            :formValid="validation.formValid"
            :canSave="canSave"
            :hasChanges="hasChanges"
            :isSaving="isSaving"
            :viewOnly="viewOnly"
            :topSlotHeight="showClientBar ? 62 : 0"
            @keychanged="onStepperKeyChanged"
            @submit="onStepperSubmit"
            @save="saveForm"
        >
            <template v-slot:top v-if="showClientBar">
                <FormClientBar :client="clientData" />
            </template>
            <template v-slot:bottom v-if="stepperMessageHeight > 0">
                <FormStepperMessage @hide="hideAutoSaveMessage" @ignore="onIgnoreAutoSave" />
            </template>
        </FormStepper>

        <div :style="'padding-top: ' + stepperMessageHeight + 'px;'" />
        <div v-if="showClientBar" style="padding-top: 62px;" />

        <div v-if="pages[pageIndex]" :class="[
            pages[pageIndex].mode || 'form-page',
            { 'no-stepper' : !pages[pageIndex].showStepper }
        ]">
            <FormRenderer ref="renderer"
                :page="pages[pageIndex]"
                :pages="pages"
                :locales="locales"
                :resources="resources"
                :roleKey="roleKey"
                @action="handleAction"
                @link="handleLinkValue"
                @validate="onValidateInput"
                @testhb="onTestHB"
                @personinput="onPersonInput"
                @personlist="showPersonList"
                @match="onDictionaryMatch"
            />
        </div>

        <PersonListDialog v-if="personListParams.show" :params="personListParams" @close="personListParams.show = false" @select="onSelectPerson" />

    </div>
    <div class="frv2-nosuchform" v-if="hasError">
        {{ errorMessage }}
    </div>
</div>
</template>
<script>
import viewHelper from './viewHelper';

import FormStepper from './FormStepper.vue';
import FormStepperMessage from './FormStepperMessage.vue';
import FormRenderer from './Renderer.vue';

import FormClientBar from './FormClientBar.vue';

import PersonListDialog from './modules/kyc/PersonListDialog.vue';

export default {
    name: "FormViewer",
    components: {
        FormStepper,
        FormStepperMessage,
        FormRenderer,
        FormClientBar,
        PersonListDialog,
    },
    props: {
        formData: { type: Object, default: null },
        envelope: { type: Object, default: null },
        recipient: { type: Object, default: null },
        isOwner: { type: Boolean, default: false }
    },
    data() {
        return {
            testLocally: false,
            loading: true,
            hasError: false,
            errorMessage: '',
            formKey: '',
            title: null,
            steps: [],
            stepKey: '',
            pages: [],
            pageIndex: 0,
            roles: [],
            roleKey: '',
            resources: {},
            validation: {
                formValid: false,
                disabled: false
            },
            ownerIsEditor: false,
            attachments: [],
            autoSave: false,
            canSave: false,
            isSaving: false,
            hasChanges: false,
            isRedirected: false,
            viewOnly: false,

            personListParams: { show: false },

            existingFiles: [],
            existingValues: {},

            locales: [],

            logic: [],
            logicResults: {},

            flags: {},

            autoSaveLoaded: false,
            ignoreAutoSave: false,
            stepperMessageHeight: 0,

            showClientBar: false,
            clientData: null,

            clientTags: [],
        }
    },
    watch: {
        '$root.locale.key': {
            deep: false,
            handler(val) {
                if (this.title && this.title[val]) this.setTitle(this.title[val], false);
            }
        }
    },
    methods: {

        onTestHB(type) {
            console.log('test HB', type);

            if (type == 'cdd') {

                for (const i in this.pages) {
                    for (const j in this.pages[i].cards) {
                        for (const k in this.pages[i].cards[j].inputs) {
                            const input = this.pages[i].cards[j].inputs[k];
                            if (input.type == 'chk-i') {
                                if (input.model) {
                                    input.model.qcc = 'chkYes';
                                    input.model.qcr = 'chkPass';
                                }
                            }
                        }
                    }
                }

            }else {

                for (const i in this.pages) {
                    for (const j in this.pages[i].cards) {
                        for (const k in this.pages[i].cards[j].inputs) {
                            const input = this.pages[i].cards[j].inputs[k];
                            if (input.type == 'chk-i') {
                                const idsList = this.resources[input.ids].items;
                                let rnd = Math.floor(Math.random() * idsList.length) + 1;
                                if (input.model) input.model.ids = idsList[Number(rnd - 1)].value;
                                if (input.vs != 'chkNone') {
                                    const vsList = this.resources[input.vs].items;
                                    rnd = Math.floor(Math.random() * vsList.length) + 1;
                                    if (input.model) input.model.vs = vsList[Number(rnd - 1)].value;
                                }
                            }else if (input.type == 'check') {
                                if (input.fillCard) {
                                    for (const key in input.model) {
                                        input.model[key] = true;
                                    }
                                }
                            }
                            
                        }
                    }
                }

            }

        },
        
        onPersonInput(card, input, index) {
            viewHelper.onPersonInput(this, card, input, index);
        },

        showPersonList(card, index) {
            viewHelper.showPersonList(this, card, index);
        },

        onSelectPerson() {
            this.validateEverything();
        },

        onDictionaryMatch(input, value, card, index) {
            viewHelper.onDictionaryMatch(this, input, value, card, index);
        },

        //#region Stepper Interface -------------------------------------------------------------------------------------------

        onStepperKeyChanged(event) {
            viewHelper.onStepperKeyChanged(this, event);
        },

        async onStepperSubmit() {
            await viewHelper.onStepperSubmit(this);
        },

        preparePdfData(userdata) {
            viewHelper.preparePdfData(this, userdata);
        },

        redirectToLanding() {
            //console.log('redirect 2');
            this.isRedirected = true;
            const query = { reload: true };
            for (const key in this.$route.query) {
                query[key] = this.$route.query[key];
            }
            this.$parent.$parent.navigate({ path: `/lform/${this.$route.params.envelopeId}/${this.$route.params.recipientId}`, query: query });
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Flow --------------------------------------------------------------------------------------------------------

        handleAction(type, key) {
            viewHelper.handleAction(this, type, key);
        },

        handleLinkValue(link, value) {
            viewHelper.handleLinkValue(this, link, value);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Validation --------------------------------------------------------------------------------------------------

        onValidateInput(input, index) {
            viewHelper.onValidateInput(this, input, index);
        },

        updateValidation() {
            viewHelper.updateValidation(this);
        },

        validateEverything() {
            viewHelper.validateEverything(this);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Data processing ---------------------------------------------------------------------------------------------

        async saveForm() {
            viewHelper.saveForm(this);
        },

        processNewData(formData) {
            viewHelper.processNewData(this, formData);
        },

        processUserData2(formData, userdata, existingData) {
            viewHelper.processUserData2(this, formData, userdata, existingData);
        },
        
        processUserData(userdata) {
            viewHelper.processUserData(this, userdata);
        },

        processSteps() {
            viewHelper.processSteps(this);
        },

        goToNextRole() {
            viewHelper.goToNextRole(this);
        },

        getNextRole() {
            return viewHelper.getNextRole(this);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        showMainSpinner(show, text) {
            if (show) {
                this.$parent.$parent.setLoading(true, text || this.__('loading') + '...');
            }else {
                this.$parent.$parent.setLoading(false);
            }
        },

        showError(show, text) {
            if (show) {
                this.errorMessage = text;
                this.hasError = true;
                this.showMainSpinner(false);
            }else {
                this.errorMessage = '';
                this.hasError = false;
            }
        },

        setTitle(title) {
            //console.log('set title', title);
            this.$parent.setTitle(title);
        },

        onAttachmentDownloaded(attachment, data) {
            viewHelper.onAttachmentDownloaded(this, attachment, data);
        },

        setAutoSaveLoaded() {
            this.autoSaveLoaded = true;
            this.stepperMessageHeight = 46;
        },

        hideAutoSaveMessage() {
            this.stepperMessageHeight = 0;
        },

        onIgnoreAutoSave() {
            this.ignoreAutoSave = true;
            // TODO reload form
        },

    },
    async created() {
        await viewHelper.onCreated(this);
    }
}

</script>
<style>

.frv2-nosuchform {
    width: fit-content;
    margin: 100px 100px;
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    color: var(--val-error);
    border-radius: 5px;
    background-color: var(--card-background);
}

</style>
