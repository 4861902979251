<template>
<div class="amlv1-project-statuslist">
    
    <div v-for="(project, i) in projects" :key="'p' + i">
        <router-link :to="project.link">
            <div class="amlv1-project-statusitem">
                <div class="amlv1-project-statusitem-name">
                    <div class="vapcli-status" :style="'border-color: ' + project.statusColor + '; background-color: ' + (project.completed ? statusColor : 'transparent') + ';'" />
                </div>
                <div class="amlv1-project-statusitem-name">
                    {{ project.name }}
                </div>
                <div class="amlv1-project-statusitem-info">
                    {{ project.status }}
                </div>
            </div>
        </router-link>
    </div>

</div>
</template>
<script>
import tools from '../tools';
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFInput from '../components/form/inputs/VFInput.vue';

export default {
    name: 'ProjectStatusList',
    components: {
        PopupV1,
        VFButton,
        VFGroup,
        VFLabel,
        VFInput,
    },
    props: {
        client: { type: Object, default: null },
    },
    data() {
        return {
            newDialog: { show: false, valid: true, working: false, status: '', error: '', name: '' },
            projects: []
        }
    },
    methods: {

        showNewProjectDialog() {
            this.newDialog.show = true;
        },

        async onCreateNewProject() {
            this.newDialog.working = true;
            this.newDialog.status = 'Creating new project';

            const projectId = tools.getUniqueId().toUpperCase();

            const project = {
                projectId: projectId,
                name: this.newDialog.name || 'Project ' + projectId,
                flow: this.client.flow,
            };

            await v1api.addProject(this.client._id, project, tools.getUniqueId().toUpperCase());

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.newDialog.working = false;
            this.newDialog.show = false;
        },

    },
    created() {

        if (this.client.projects) {

            for (const i in this.client.projects) {
                const project = {
                    projectId: this.client.projects[i].projectId,
                    name: this.client.projects[i].name,
                    number: this.client.projects[i].number || '',
                    active: this.client.projects[i].projectId == this.projectId,
                    link: 'v1client?id=' + this.client._id + '&projectId=' + this.client.projects[i].projectId,
                    actions: [
                        { icon: 'edit', action: 'edit' },
                    ]
                };
                if (this.client.projects[i].isDefault) {
                    if (!this.client.projects[i].name) {
                        project.name = 'Default project';
                    }
                    if (!this.projectId) project.active = true;
                }
                this.projects.push(project);
            }

        }

    }
}
</script>
<style scoped>

.amlv1-project-statuslist {
    margin-top: 3px;
}

.amlv1-project-statuslist a {
    text-decoration: none;
}

.amlv1-project-statusitem {
    width: fit-content;
    min-width: 200px;
    display: flex;
    padding: 3px 6px;
    margin-top: -1px;
    color: var(--text-weak);
    border: solid 1px var(--input-border);
}
.amlv1-project-statusitem:hover {
    background-color: var(--item-hover);
}

.amlv1-project-statusitem-name {
    
}

.vapcli-status {
    flex-shrink: 0;
    width: 17px; height: 17px;
    margin-right: 8px;
    border-radius: 50%;
    border: solid 2px var(--disabled-background);
    box-sizing: border-box;
}

</style>