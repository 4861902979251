<template>
    <div>
        <vap-row v-if="value">

            <vap-group c6>
                <vap-label :text="__('common_name')" desc :required="required" />
                <vap-input v-model="value.name" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" :placeholder="__('common_required')" />
            </vap-group>
            <vap-group c6>
                <vap-label :text="__('company_orgnr')" desc />
                <vap-input numeric v-model="value.org_no" :noHighlight="noHighlight" :noValidation="noValidation" :placeholder="__('common_optional')" />
            </vap-group>

            <vap-group c6>
                <vap-label :text="__('common_address')" desc :required="required" />
                <vap-input v-model="value.address" :noHighlight="noHighlight" :noValidation="noValidation"  :rules="required ? 'required' : ''" :placeholder="__('common_required')" />
            </vap-group>
            <vap-group c3>
                <vap-label :text="__('common_zip')" desc :required="required" />
                <vap-input numeric v-model="value.zip" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''"  :placeholder="__('common_required')"/>
            </vap-group>
            <vap-group c3>
                <vap-label :text="__('common_city')" desc :required="required" />
                <vap-input v-model="value.town" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" :placeholder="__('common_required')" />
            </vap-group>

            <vap-group c3>
                <vap-label :text="__('company_est_date')" desc :required="required" />
                <vap-datepicker v-model="value.start_date" outputISO :noHighlight="noHighlight" :language="$root.locale.key" :noValidation="noValidation" :rules="required ? 'required' : ''" :placeholder="__('common_required')" />
            </vap-group>
            
            <vap-group c3>
                <vap-label :text="__('company_org_form')" desc :required="required" />
                <vap-input v-model="value.org_form" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" :placeholder="__('common_required')" />
            </vap-group>
            <vap-group c3>
                <vap-label :text="__('company_business_code')" desc />
                <vap-input v-model="value.business_code" :noHighlight="noHighlight" :noValidation="noValidation" :placeholder="__('common_optional')" />
            </vap-group>
            <vap-group c3>
                <vap-label :text="__('company_employees')" desc :required="required" />
                <vap-input numeric v-model="value.employees" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" :placeholder="__('common_required')" />
            </vap-group>

            <vap-group c12>
                <vap-label :text="__('company_business')" desc :required="required" />
                <vap-input v-model="value.business" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" :placeholder="__('common_required')" />
            </vap-group>

        </vap-row>
    </div>
</template>

<script>

export default {
    name: 'CompanyInputs',
    props: {
        value: {
            type: Object,
            default: null
        },
        role: {
            type: String,
            default: 'owner'
        },
        editMode: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        zipJson: {
            type: Object,
            default: null
        },
        noValidation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            noHighlight: true
        }
    },
    computed: {
        
    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            }
        },
    },
    methods: {
        
    },
    async mounted() {

        

    }
}
</script>
<style scoped>


</style>