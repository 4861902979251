<template>
<div class="v1dt">

    <div class="v1dt-items" :style="totalWidth">

        <div class="v1dt-item v1dt-head">
            <div class="v1dt-item-x" v-for="(column, i) in columns" :key="'vc' + i" :style="widths[i]">
                <label>{{ column.name }}</label>
            </div>
        </div>

        <div class="v1dt-item" v-for="(row, j) in rows" :key="'vr' + j">
            <div class="v1dt-item-x" v-for="(column, i) in columns" :key="'vrc' + i" :style="widths[i]">
                <label>{{ getValue(row, column) }}</label>
            </div>
        </div>

    </div>

</div>
</template>
<script>

export default {
    name: 'DataTableV1',
    props: {
        columns: { type: Array, default: () => { return [] } },
        rows: { type: Array, default: () => { return [] } },
    },
    data() {
        return {
            widths: [],
            totalWidth: 'width: 100%;'
        }
    },
    watch: {
        columns: {
            deep: true,
            handler(val) {
                this.calculateWidths(val);
            }
        }
    },
    methods: {

        getValue(row, column) {
            return row[column.key];
        },

        calculateWidths(val) {
            let total = 0;
            for (const i in val) total += (Number(val[i].width) * 15);
            const fac = 100 / total;
            this.widths = [];
            for (const i in val) {
                /* this.widths.push('width: 100px;'); */
                this.widths.push('width: ' + (Number(val[i].width) * 15) + 'px;');
            }
            this.totalWidth = 'width: ' + (total + 1) + 'px;';
        },

        updateRows() {
            this.$forceUpdate();
        },

    },
    created() {
        this.calculateWidths(this.columns);
    }
}
</script>
<style>

.v1dt {
    float: left;
    width: 100%;
    overflow: auto;
}

.v1dt-items {
    float: left;
    border-top: solid 1px var(--input-border);
    box-sizing: border-box;
}

.v1dt-item {
    float: left;
    border: solid 1px var(--input-border);
    border-top: none;
    border-right: none;
    box-sizing: border-box;
}

.v1dt-head label {
    font-weight: bold;
}

.v1dt-item-x  {
    float: left;
    padding: 3px 5px;
    font-family: monospace, monospace;
    letter-spacing: -0.8px;
    border-right: solid 1px var(--input-border);
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}

</style>
