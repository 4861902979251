import log from '../../log';
import verified from '../../verified';
import internalFlow from './flows/internalFlow';
import signerFlow from './flows/signerFlow';
import directFlow from './flows/directFlow';
import formLocalData from './formLocalData';
import v2lib from '../../v2lib';
const flowHelper = {

    useLocalStorage: false,

    async createEnvelope(root, flowType) {
        if (this.useLocalStorage) {
            const envelope = {
                id: formLocalData.getLocalId(),
                isLocal: true,
                content: {
                    recipients: [],
                },
                userdata: {}
            };
            return envelope;
        }else {
            if (flowType == 'sign') {

                const envelope = await signerFlow.onCreateEnvelope();
                return envelope;

            }else if (flowType == 'direct') {

                const envelope = await directFlow.onCreateEnvelope();
                return envelope;

            }else {

                const envelope = await internalFlow.onCreateEnvelope();
                return envelope;

            }
        }
    },

    async refreshEditorEnvelope(root, flowType, envelope) {
        if (this.useLocalStorage) {
            return envelope;
        }else {
            return await v2lib.getEditorEnvelope(envelope.id);
        }
    },

    async addRecipient(envelope, recipient, flowType) {
        console.log('add recipient', recipient);
        if (this.useLocalStorage) {
            recipient.id = formLocalData.getLocalId();
            recipient.language = recipient.lang;
            recipient.completed = false;
            envelope.content.recipients.push(recipient);
        }else {
            if (flowType == 'sign') {

                await signerFlow.onAddRecipient(envelope, recipient);

            }else if (flowType == 'direct') {

                await directFlow.onAddRecipient(envelope, recipient);

            }else {

                await internalFlow.onAddRecipient(envelope, recipient);

            }
        }
    },

    async setUserdata(envelope, userdata, flowType) {
        if (this.useLocalStorage) {
            envelope.userdata = userdata;
        }else {
            if (flowType == 'sign') {

                await signerFlow.onSetUserData(envelope, userdata);

            }else if (flowType == 'direct') {

                await directFlow.onSetUserData(envelope, userdata);

            }else {

                await internalFlow.onSetUserData(envelope, userdata);

            }
        }
    },

    async setNameAndTags(envelope, userdata, name, tags, flowType) {
        if (this.useLocalStorage) {
            envelope.userdata.fileName = name;
        }else {
            if (flowType == 'sign') {

                await signerFlow.onSetNameAndTags(envelope, userdata, name, tags);

            }else if (flowType == 'direct') {

                await directFlow.onSetNameAndTags(envelope, userdata, name, tags);

            }else {

                await internalFlow.onSetNameAndTags(envelope, userdata, name, tags);

            }
        }
    },

    async submitEnvelope(envelope, flowType) {
        if (this.useLocalStorage) {
            formLocalData.addEnvelope(envelope);
        }else {
            if (flowType == 'sign') {

                await signerFlow.onSubmit(envelope);

            }else if (flowType == 'direct') {

                await directFlow.onSubmit(envelope);

            }else {

                await internalFlow.onSubmit(envelope);

            }
        }
    },

    async updateEnvelope(envelope, flowType) {
        if (this.useLocalStorage) {
            formLocalData.addEnvelope(envelope);
        }else {
            
        }
    },

    getFormLogoUrl(root, formData) {
        if (formData && formData.flowConfig && formData.flowConfig.branding) {
            if (formData.flowConfig.branding.logoEmail) {
                if (formData.flowConfig.branding.logoEmail == 'none') {
                    return 'https://aml-portal.web.verified.eu/logo/empty.png';
                }else {
                    return formData.flowConfig.branding.logoEmail;
                }
            }
        }
        if (root.config.branding && root.config.branding.logoEmail && !root.config.branding.logoEmail.includes('verified-logo-blue')) {
            return root.config.branding.logoEmail;
        }else {
            return root.config.kycs[0].email.logo;
        }
    },

    replaceDynamicValues(root, input, language) {
        if (!input) return input;
        const existingData = JSON.parse(root.formData);
        let output = input.split('{{companyName}}').join(root.user.companyName);

        if (existingData && existingData.customer) {

            if (root.config.externalId.enabled) {
                if (existingData.customer.externalId) {
                    output = output.split('{{externalIdText}}').join(root.config.externalId.textValue[language] + existingData.customer.externalId);
                }else {
                    output = output.split('{{externalIdText}}').join('');
                }
                output = output.split('{{externalId}}').join(existingData.customer.externalId || '');
            }

        }
        return output;
    },

    getMailData(root, formData, emailData, language, flowType) {
        let base = '';
        if (root.isAWS) base = '/web/aml-portal/#';
        const mail = {
            logourl: this.getFormLogoUrl(root, formData),
            baseUrl: root.baseUrl,
            customRedirect: 'no',
            editRoute: base + '/lform/{envelopeId}/{recipientId}',
            signRoute: base + '/lform/{envelopeId}/{recipientId}',
            lang: language,
            includeEn: false,
            defaultLang: root.config.params.defaultLanguage,
            buttonColor: root.getColor('button'),
            linkColor: root.getColor('text-accent'),
            values: [],
            texts: {}
        };

        mail.values.push({ key: 'companyName', type: 'value', value: root.user.companyName });
        mail.values.push({ key: 'senderName', type: 'value', value: root.user.givenName + ' ' + root.user.familyName });
        mail.values.push({ key: 'senderEmail', type: 'value', value: root.user.email });

        if (root.config.params.hasDepartments) {
            let value = 'Unknown department';
            if (root.credentials.branch) {
                value = root.getDepartmentName(root.credentials.branch);
            }
            mail.values.push({ key: 'clientDepartment', type: 'value', value: value });
        }

        for (const i in formData.flowConfig.roles) {
            if (formData.flowConfig.roles[i].key != 'owner') {
                const role = this.getRecipientRole(formData.flowConfig.roles[i].key);
                const roleMail = formData.flowConfig.roles[i].email;
                const translations = {};
                for (let i in root.config.locales) {
                    if (!root.config.locales[i].enabled) continue;
                    translations[root.config.locales[i].key] = {
                        subject: this.replaceDynamicValues(root, roleMail.subject[root.config.locales[i].key], root.config.locales[i].key),
                        header: this.replaceDynamicValues(root, roleMail.header[root.config.locales[i].key], root.config.locales[i].key),
                        message: this.replaceDynamicValues(root, roleMail.message[root.config.locales[i].key], root.config.locales[i].key),
                        button: this.replaceDynamicValues(root, roleMail.button[root.config.locales[i].key], root.config.locales[i].key),
                        hint: this.replaceDynamicValues(root, roleMail.hint[root.config.locales[i].key], root.config.locales[i].key),
                        footer: this.replaceDynamicValues(root, roleMail.footer[root.config.locales[i].key], root.config.locales[i].key),
                    }
                    mail.texts[role.name] = translations;
                }
            }
        }
        return mail;
    },

    getMailDataForceProduction(root, formData, emailData, language, flowType) {
        const mailData = this.getMailData(root, formData, emailData, language, flowType);
        const base = '/web/aml-portal/#';
        mailData.baseUrl = 'https://' + 'app.verified.eu';
        mailData.editRoute = base + '/lform/{envelopeId}/{recipientId}';
        mailData.signRoute = base + '/lform/{envelopeId}/{recipientId}';
        mailData.includeEn = true;
        return mailData;
    },

    getRecipientRole(key) {
        const roles = this.getRoleOptions();
        for (const i in roles) {
            if (roles[i].key == key) {
                return { action: roles[i].action, label: roles[i].label, name: roles[i].key };
            }
        }
        return { action: "edit", label: "Fill in form", name: "editor" };
    },

    getRoleOption(key) {
        const options = this.getRoleOptions();
        for (const i in options) {
            if (options[i].key == key) return options[i];
        }
        return null;
    },

    getRoleOptions() {
        return [
            {
                key: 'editor',
                action: 'edit',
                label: 'Approve',
                display: 'Editor (Fill in form)',
                hint: 'This role will edit the form',
                icon: 'edit'
            },
            {
                key: 'approver',
                action: 'edit',
                label: 'Approve',
                display: 'Editor (Approve)',
                hint: 'This role will approve the form',
                icon: 'check'
            },
            {
                key: 'confirmer',
                action: 'edit',
                label: 'Confirm',
                display: 'Editor (Confirm)',
                hint: 'This role will confirm the form',
                icon: 'check'
            },
            {
                key: 'provider',
                action: 'edit',
                label: 'Provide info',
                display: 'Editor (Provide info)',
                hint: 'This role will provide additional information',
                icon: 'edit'
            },
            {
                key: 'checker',
                action: 'edit',
                label: 'Perform checks',
                display: 'Editor (Perform checks)',
                hint: 'This role will perform checks',
                icon: 'edit'
            },
            {
                key: 'idc',
                action: 'edit',
                label: 'ID-Control',
                display: 'Editor (ID-control)',
                hint: 'This role will perform an ID-control',
                icon: 'edit'
            },
            {
                key: 'viewer',
                action: 'edit',
                label: 'View',
                display: 'Editor (View)',
                hint: 'This role will view the form',
                icon: 'eye'
            },
            {
                key: 'signer',
                action: 'sign',
                label: 'Sign',
                display: 'Signer',
                hint: 'This role will sign the form',
                icon: 'sign'
            },
            {
                key: 'done',
                action: 'done',
                label: 'Done',
                display: 'Document signed',
                hint: 'Role for managing document signed',
                icon: 'check'
            }
        ];
    },

    getEmptyEmail(key) {
        const email = {
            subject: {
                en_EN: 'You have new form to fill in',
                nb_NO: 'Du har et nytt skjema å fylle ut',
                sv_SE: 'Du har ett nytt formulär att fylla i',
            },
            header: {
                en_EN: 'Hi {{firstName}} {{lastName}}',
                nb_NO: 'Hei {{firstName}} {{lastName}}',
                sv_SE: 'Hej {{firstName}} {{lastName}}',
            },
            message: {
                en_EN: '{{senderName}} have sent you a form ready to be filled in.',
                nb_NO: '{{senderName}} har sendt deg et nytt skjema som må fylles ut.',
                sv_SE: '{{senderName}} har skickat ett nytt formulär till dig som måste fyllas i.',
            },
            button: {
                en_EN: 'Open form',
                nb_NO: 'Åpne skjema',
                sv_SE: 'Öppna formuläret',
            },
            hint: {
                en_EN: 'Click the button to open the form.',
                nb_NO: 'Trykk på knappen for å åpne skjema.',
                sv_SE: 'Tryck på knappen för att öppna formuläret.',
            },
            footer: {
                en_EN: 'If you have any questions please contact {{senderName}} on {{senderEmail}}',
                nb_NO: 'Hvis du har noen spørsmål vennligst kontakt {{senderName}} på {{senderEmail}}',
                sv_SE: 'Om du har några frågor vänligen kontakta {{senderName}} på {{senderEmail}}',
            },
            links: []
        };
        if (key == 'signer') {
            email.subject.en_EN = 'Document for signing';
            email.subject.nb_NO = 'Dokument for signering';
            email.subject.sv_SE = 'Dokument för signering';

            email.message.en_EN = 'You have received a new document for signing';
            email.message.nb_NO = 'Du har mottatt et nytt dokument for signering';
            email.message.sv_SE = 'Du har fått ett nytt dokument för signering';

            email.button.en_EN = 'Go to signing';
            email.button.nb_NO = 'Gå til signering';
            email.button.sv_SE = 'Gå till signering';

            email.hint.en_EN = 'Click the button to open the document.';
            email.hint.nb_NO = 'Trykk på knappen for å åpne dokumentet.';
            email.hint.sv_SE = 'Tryck på knappen för att öppna dokumentet.';

        }else if (key == 'idc') {
            email.subject.en_EN = 'ID-control';
            email.subject.nb_NO = 'ID-kontroll';
            email.subject.sv_SE = 'ID-kontroll';

            email.message.en_EN = 'As part of your business with {{companyName}} we ask that you complete a digital ID-control.';
            email.message.nb_NO = 'Som en del av samarbeidet ditt med {{companyName}} ber vi deg om å fullføre en digital ID-kontroll.';
            email.message.sv_SE = 'Som en del av ditt partnerskap med {{companyName}} ber vi dig att slutföra en digital ID-kontroll.';

            email.button.en_EN = 'Start ID-control';
            email.button.nb_NO = 'Start ID-kontroll';
            email.button.sv_SE = 'Starta ID-kontroll';

            email.hint.en_EN = 'You are receiving this email because we are required by law to ID all of our clients.';
            email.hint.nb_NO = 'Du mottar denne eposten fordi vi er pålagt å hente inn ID på alle våre kunder.';
            email.hint.sv_SE = 'Du får det här e-postmeddelandet eftersom vi enligt lag är skyldiga att identifiera alla våra kunder.';

        }else if (key == 'done') {
            email.subject.en_EN = 'Signed document';
            email.subject.nb_NO = 'Signert dokument';
            email.subject.sv_SE = 'Signerat dokument';

            email.message.en_EN = 'Here is your copy of the signed document';
            email.message.nb_NO = 'Her er din kopi av det signerte dokumentet';
            email.message.sv_SE = 'Här är din kopia av det signerade dokumentet';

            email.hint.en_EN = 'Click on the document name to download it for archiving';
            email.hint.nb_NO = 'Trykk på navnet for å laste det ned for arkivering';
            email.hint.sv_SE = 'Klicka på dokumentets namn för att ladda ner det för arkivering';

        }
        return email;
    },

    async downloadAttachment(callback, envelope, attachment) {
        log.v('FlowHelper: Downloading attachment', attachment);

        let match = false;
        for (const j in envelope.content.documents[0].files) {
            for (const k in envelope.content.documents[0].files[j]) {
                if (envelope.content.documents[0].files[j][k].name == attachment.name) {
                    match = true;
                    try {
                        const url = await verified.getDownloadUrl(envelope.id, envelope.content.documents[0].id, envelope.content.documents[0].files[j][k].uid);
                        const data = await verified.downloadFile(url);
                        callback.onAttachmentDownloaded(attachment, data);
                        log.s('FlowHelper: Attachment (' + attachment.type + ') ' + attachment.name + ' downloaded');
                    }catch (ex) {
                        log.e('FlowHelper: Could not download attachment', ex);
                    }
                }
            }
        }
        if (!match) log.e('FlowHelper: Attachment does not exist in envelope', envelope);
    },

};
export default flowHelper;