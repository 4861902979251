<template>
<div>

    <RiskItem v-for="(category, i) in categories" :key="'nc' + i"
        :level="1"
        :code="category.letter"
        :display="category.nb_NO"
    >
        <template v-slot:risk>
            <RiskScoreControl
                :params="riskParams"
                :source="'nc'"
                :rId="'n' + category.letter"
            />
        </template>

        <RiskItem v-for="(level2, j) in category.items" :key="'n2' + j"
            :level="2"
            :code="level2.code"
            :display="level2.display"
        >
            <template v-slot:risk>
                <RiskScoreControl
                    :params="riskParams"
                    :source="'nc'"
                    :rId="'n' + category.letter + level2.key"
                    :rId1="'n' + category.letter"
                />
            </template>

            <RiskItem v-for="(level3, k) in level2.items" :key="'n3' + k"
                :level="3"
                :code="level3.code"
                :display="level3.display"
            >
                <template v-slot:risk>
                    <RiskScoreControl
                        :params="riskParams"
                        :source="'nc'"
                        :rId="'n' + category.letter + level3.key"
                        :rId1="'n' + category.letter + level2.key"
                        :rId2="'n' + category.letter"
                    />
                </template>

                <RiskItem v-for="(level4, l) in level3.items" :key="'n4' + l"
                    :level="4"
                    :code="level4.code"
                    :display="level4.display"
                >
                    <template v-slot:risk>
                        <RiskScoreControl
                            :params="riskParams"
                            :source="'nc'"
                            :rId="'n' + category.letter + level4.key"
                            :rId1="'n' + category.letter + level3.key"
                            :rId2="'n' + category.letter + level2.key"
                            :rId3="'n' + category.letter"
                        />
                    </template>

                    <RiskItem v-for="(level5, m) in level4.items" :key="'n5' + m"
                        :level="5" lastLevel
                        :code="level5.code"
                        :display="level5.display"
                    >
                        <template v-slot:risk>
                            <RiskScoreControl
                                :params="riskParams"
                                :source="'nc'"
                                :rId="'n' + category.letter + level5.key"
                                :rId1="'n' + category.letter + level4.key"
                                :rId2="'n' + category.letter + level3.key"
                                :rId3="'n' + category.letter + level2.key"
                                :rId4="'n' + category.letter"
                            />
                        </template>
                    </RiskItem>
                </RiskItem>
            </RiskItem>
        </RiskItem>
    </RiskItem>

    <RiskExpandButton :expanded="expanded" @expand="onExpandClicked" />

</div>
</template>
<script>
import RiskItem from './RiskItem.vue';
import RiskExpandButton from './RiskExpandButton.vue';
import RiskScoreControl from './RiskScoreControl.vue';

export default {
    name: 'RiskNaceSettings',
    components: {
        RiskItem,
        RiskExpandButton,
        RiskScoreControl,
    },
    props: {
        riskParams: { type: Object, default: null },
    },
    data() {
        return {
            expanded: false,
            categories: null
        }
    },
    methods: {

        onExpandClicked() {
            this.expanded = !this.expanded;
            this.loadCodes();
        },
        
        getNaceCategories() {
            const array = [
                { letter: 'A', items: [], expanded: false, nb_NO: 'Jordbruk, skogbruk og fiske' },
                { letter: 'B', items: [], expanded: false, nb_NO: 'Bergverksdrift og utvinning' },
                { letter: 'C', items: [], expanded: false, nb_NO: 'Industri' },
                { letter: 'D', items: [], expanded: false, nb_NO: 'Elektrisitets-, gass-, damp- og varmtvannsforsyning' },
                { letter: 'E', items: [], expanded: false, nb_NO: 'Vannforsyning, avløps- og renovasjonsvirksomhet' },
                { letter: 'F', items: [], expanded: false, nb_NO: 'Bygge- og anleggsvirksomhet' },
                { letter: 'G', items: [], expanded: false, nb_NO: 'Varehandel, reparasjon av motorvogner' },
                { letter: 'H', items: [], expanded: false, nb_NO: 'Transport og lagring' },
                { letter: 'I', items: [], expanded: false, nb_NO: 'Overnattings- og serveringsvirksomhet' },
                { letter: 'J', items: [], expanded: false, nb_NO: 'Informasjon og kommunikasjon' },
                { letter: 'K', items: [], expanded: false, nb_NO: 'Finansierings- og forsikringsvirksomhet' },
                { letter: 'L', items: [], expanded: false, nb_NO: 'Omsetning og drift av fast eiendom' },
                { letter: 'M', items: [], expanded: false, nb_NO: 'Faglig, vitenskapelig og teknisk tjenesteyting' },
                { letter: 'N', items: [], expanded: false, nb_NO: 'Forretningsmessig tjenesteyting' },
                { letter: 'O', items: [], expanded: false, nb_NO: 'Offentlig administrasjon og forsvar, og trygdeordninger underlagt offentlig forvaltning' },
                { letter: 'P', items: [], expanded: false, nb_NO: 'Undervisning' },
                { letter: 'Q', items: [], expanded: false, nb_NO: 'Helse- og sosialtjenester' },
                { letter: 'R', items: [], expanded: false, nb_NO: 'Kulturell virksomhet, underholdning og fritidsaktiviteter' },
                { letter: 'S', items: [], expanded: false, nb_NO: 'Annen tjenesteyting' },
                { letter: 'T', items: [], expanded: false, nb_NO: 'Lønnet arbeid i private husholdninger' },
                { letter: 'U', items: [], expanded: false, nb_NO: 'Internasjonale organisasjoner og organer' },
            ];
            if (!this.expanded) array.splice(5, array.length - 5);
            return array;
        },

        isSubCategory(obj) {
            if (obj.nb_NO) return true;
            return false;
        },

        loadCodes() {
            this.categories = this.getNaceCategories();
            for (const i in this.$root.naceCodes) {
                const level2 = {
                    items: [],
                    key: this.$root.naceCodes[i].code,
                    code: this.$root.naceCodes[i].code,
                    display: this.$root.naceCodes[i].nb_NO,
                    expanded: false
                };
                for (const j in this.$root.naceCodes[i]) {
                    if (this.isSubCategory(this.$root.naceCodes[i][j])) {
                        const level3 = {
                            items: [],
                            key: this.$root.naceCodes[i][j].code.replace('.', ''),
                            code: this.$root.naceCodes[i][j].code,
                            display: this.$root.naceCodes[i][j].nb_NO,
                            expanded: false
                        };
                        for (const k in this.$root.naceCodes[i][j]) {
                            if (this.isSubCategory(this.$root.naceCodes[i][j][k])) {
                                const level4 = {
                                    items: [],
                                    key: this.$root.naceCodes[i][j][k].code.replace('.', ''),
                                    code: this.$root.naceCodes[i][j][k].code,
                                    display: this.$root.naceCodes[i][j][k].nb_NO,
                                    expanded: false
                                }
                                for (const l in this.$root.naceCodes[i][j][k]) {
                                    if (this.isSubCategory(this.$root.naceCodes[i][j][k][l])) {
                                        level4.items.push({
                                            key: this.$root.naceCodes[i][j][k][l].code.replace('.', ''),
                                            code: this.$root.naceCodes[i][j][k][l].code,
                                            display: this.$root.naceCodes[i][j][k][l].nb_NO,
                                        });
                                    }
                                }
                                level3.items.push(level4);
                            }
                        }
                        level2.items.push(level3);
                    }
                }
                for (const j in this.categories) {
                    if (this.$root.naceCodes[i].letter == this.categories[j].letter) {
                        this.categories[j].items.push(level2);
                    }
                }
            }
        },

    },
    created() {
        this.loadCodes();
    }
}
</script>