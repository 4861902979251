<template>
<div class="clhv1-letters">

    <div v-for="(letter, i) in letters" :key="i" class="clhv1-letter">

        <div class="clhv1-color" :class="[{'clhv1-color-active' : letter.active}, {'clhv1-color-deselected' : letter.deselected}]" @click="onletterClicked(letter)">
            <label>{{ letter.letter }}</label>
        </div>

    </div>

</div>
</template>
<script>

export default {
    name: 'AlphabetSelector',
    props: {
        letters: { type: Array, default: [] }
    },
    data() {
        return {
            loading: { main: true, data: true },
            customer: {},
        }
    },
    methods: {
        
        onletterClicked(letter) {
            this.$emit('letter', letter.letter);
        }

    }
}
</script>
<style scoped>

.clhv1-letters {
    width: 100%;
    margin: 0 auto;
    max-width: 1540px;
    display: table;
    padding: 6px 40px;
    box-sizing: border-box;
}
.clhv1-letter {
    display: table-cell;
    width: 45px;
    height: 36px;
    padding: 0px 2px;
    text-align: center;
    box-sizing: border-box;
}
.clhv1-color {
    padding-top: 2px;
    border-radius: 3px;
    background-color: var(--text-disabled);
    opacity: 0.5;
}
.clhv1-color-active {
    background-color: var(--primary);
    opacity: 1;
    cursor: pointer;
}
.clhv1-color-active:hover {
    opacity: 0.9;
}

.clhv1-color label {
    font-family: "Roboto Slab", serif;
    font-size: 24px;
    color: white;
}
.clhv1-color-active label {
    cursor: pointer;
}

.clhv1-color-deselected {
    opacity: 0.5;
}

</style>