<template>
<div class="vap-textarea">
    <textarea ref="field" 
        @input="handleInput" 
        :value="value" 
        :rows="rows" 
        :placeholder="placeholder">
    </textarea>
</div>
</template>

<script>

export default {
    name: 'vap-textarea',
    props: {
        value: [String, Number],
        placeholder: {
            type: String,
            default: ''
        },
        rows: {
            type: String,
            default: "4"
        },
    },
    data() {
        return {
            
        }
    },
    methods: {
        handleInput(e) {
            this.$emit('input', e.srcElement.value);
        }
    }
}
</script>

<style>

textarea {
    resize: vertical;
    border: none;
    background: none;
    outline: none;
    width: 100%;
    padding: 5px 10px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: var(--text-strong);
    border-radius: 3px;
    border: solid 1px lightgray;
    box-sizing: border-box;
}

</style>
