<template>
<div>
    <PageHeader :actions="actions" compactable :offset="104" @scrolled="scrolled = $event">
        <template v-slot:content>
            <vap-row v-if="!loading">
                <vap-group auto noPadding>
                    <vap-label :text="'Admin'" desc/>
                    <vap-label :text="'Mass Onboarding'" value />
                </vap-group>
            </vap-row>
        </template>
        <template v-slot:tabs>
            <vap-tabs v-if="Object.keys(tabModel).length > 1" accent v-model="tabModel" :tabs="tabOptions" :compact="scrolled" />
        </template>
        <template v-slot:compact>
            <div v-if="!loading">
                <span>{{ 'Onboarding' }}</span>
            </div>
        </template>
    </PageHeader>

    <PageContent :scrolled="scrolled" :offset="104" v-if="!loading">

        <div :class="{'slim-page': true, 'locked': locked }">

            <!-- customer data -->
            <vap-card>
                 <vap-row>
                    <vap-group c12>
                        <vap-label :text="'Customer data'" desc />
                        <textarea v-model="csv" rows="10" placeholder="Paste csv data here.."></textarea>
                        <p class="error-text">{{ csvError }}</p>
                    </vap-group>
                    <vap-group c6 compact>
                        <vap-label :text="'Customer type'" desc />
                        <vap-dropdown v-model="customerType" noValidation
                            :options="customerTypes"
                        />
                    </vap-group>
                    <vap-group c6 compact>
                        <vap-label :text="'Customer flow'" desc />
                        <vap-dropdown v-model="customerFlow" noValidation
                            :options="customerFlows"
                        />
                    </vap-group>
                 </vap-row>
            </vap-card>

            <template v-if="!csvError">

                <!-- parsed csv -->
                <vap-card style="margin-top: 20px">
                    <vap-row>
                        <vap-group c12 compact>
                            <vap-label :text="'Required data fields'" desc />
                        </vap-group>
                        <vap-group c12>
                            <div :class="{'data-field-tag': true, 'dft-valid': dft.valid, 'dft-invalid': !dft.valid }" v-for="dft in requiredDataFields" :key="dft.tag">{{ dft.tag }}</div>
                        </vap-group>
                        <div class="data-fields">
                            <div :style="'width: ' + dataRowWidth" class="data-row">
                                <div class="data-col" v-for="(key, i) in parsedCsvModelKeys" :key="i">
                                    <input @input="onModelKeyChange(i, $event)" :value="parsedCsvModelKeys[i]" type="text">
                                </div>
                            </div>
                            
                            <div :style="'width: ' + dataRowWidth" class="data-row" v-for="(line, i) in parsedCsv" :key="i">
                                <div class="data-col" v-for="(val, j) in line" :key="j">
                                    <div class="data-value">{{ val }}</div>
                                </div>
                            </div>
                        </div>
                    </vap-row>
                </vap-card>

                <!-- addon selection -->
                <vap-card style="margin-top: 20px">
                    <vap-row>
                        <vap-group c12>
                            <vap-label :text="'Available addons'" desc />
                            <div @click="useAddon(addon)" v-for="addon in availableAddons" :key="addon.label" class="addon">{{ addon.label }} ▼</div>
                            <div v-if="!availableAddons.length" class="no-addon">No addons to show in this category</div>
                        </vap-group>

                        <vap-group c12 compact>
                            <vap-label :text="'Selected addons'" desc />
                            <div @click="takeAddon(addon)" v-for="addon in selectedAddons" :key="addon.label" class="addon">{{ addon.label }} ▲</div>
                            <div v-if="!selectedAddons.length" class="no-addon">Click the available addons above to select them</div>
                        </vap-group>
                    </vap-row>
                </vap-card>

                <!-- screening addon -->
                <ScreeningAddon v-if="addonEnabled('screening')"></ScreeningAddon>

                <!-- test addon -->
                <TestAddon v-if="addonEnabled('testaddon')"></TestAddon>

                <div>
                    <vap-button style="margin-top: 15px;" :text="'Save configuration'" @clicked="submit" />
                    <div v-if="submitError" style="margin-top: 10px;" class="error-text">{{ submitError }}</div>
                </div>

            </template>
    
        </div>

        <div v-if="locked" class="slim-page">
            <vap-card :header="'Progress ' + nextCustomer + '/' + customerCount" style="margin-top: 20px">
                <template v-slot:actions>
                     <div class="concurrency">
                         <label>Concurrecy </label>
                        <select v-model="numConcurrent">
                            <option :value="1">1</option>
                            <option :value="2">2</option>
                            <option :value="3">3</option>
                            <option :value="4">4</option>
                        </select>
                     </div>
                     <div @click="startJob" v-if="job.state == 0" class="play"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA/0lEQVRIid2USQoCMRREH0o3iBdx41kUh3bX53G6gCs3opfRjSC20ynatbpIAiIZfkdXFtTmU6lKfn4C/44UGAFroADumoWuZVoThT5wA54BXoFeFeM6MBcYf3IG1CQBMeaGk5B5/wtzw67LPMXf84Mw4ILj4keBhU1gKQwZ2gI2gUUGOWpUfdqVLeAsDABoAXuPtrAFlBUCABrAwqEtjUg0txF42Ionx25iWnQ0wvcT7IS7y4Et0PZotrZiFjhBlTEd2AJS1Mf1i4eWuI7WE5r42HGZG8y+MB+HzEFd/DTCfELFse+i+inpebAtLiSoj2uFmu1S86hrAzwX+h94AcCGAP/8i2hpAAAAAElFTkSuQmCC"/></div>
                     <div @click="pauseJob" v-if="job.state == 1" class="play"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA7UlEQVRIid2UTQ7CIBCFP38PZXoHjX+NN7LqMRrd6j10bWOtSy9Q1+oCMKYBipSVL3kJMxneCwwM/Dv6QAxsgQx4SGYyN5c1XpgABfCq4RUY/yLcATYOwlWugbaLgY+4YlInPmkgrjgyifcR99nU4Iah8bFl0wCILHGVM53BzrJBwRRXmeoMLgENMp1BGdCgVAVO79YDT53BPaDBR+vb4BTQ4KgW3a/kHlgYNkRAyxJXcdAle0BO849WYJmw4wAGQ8vJADEVfcWXdeIgGr/yEE/48dmPcOtJjsO1mNBDDK4UOCN+aCnXKTCVNX+MN25E4Win+3n4AAAAAElFTkSuQmCC"/></div>
                </template>
                <vap-row>
                    <vap-group c12 compact>
                        <div class="job">
                            <div style="flex: 0 0 20px; text-align: center" class="job-title"></div>
                            <div class="job-title">{{ job.customerType == 'org-no' ? 'Org nr' : job.customerType == 'person-no' ? 'Name' : 'ID' }}</div>
                            <div class="job-title">Status</div>
                        </div>
                        <div v-if="recentCustomersCount > 0" class="job">
                            <div style="text-align: center" class="job-col">... {{ recentCustomersCount }} more lines</div>
                        </div>

                        <div v-for="(customer, i) in recentCustomers" :key="'recent' + i" class="job">
                            <div style="flex: 0 0 20px; text-align: center; line-height: 13px;" class="job-col">✔️</div>
                            <div class="job-col">{{ customer.id }}</div>
                            <div class="job-col">{{ customer.status }}</div>
                        </div>

                        <div v-for="(customer, i) in inProgressCustomers" :key="'current' + i" class="job">
                            <div style="flex: 0 0 20px; text-align: center" class="job-col">
                                <div class="job-loading"></div>
                            </div>
                            <div class="job-col">{{ customer.id }}</div>
                            <div class="job-col">{{ customer.status }}</div>
                        </div>

                        <div v-for="(customer, i) in nextCustomers" :key="'next' + i" class="job">
                            <div style="flex: 0 0 20px; text-align: center" class="job-col">⌛</div>
                            <div class="job-col">{{ customer.id }}</div>
                            <div class="job-col">{{ customer.status }}</div>
                        </div>

                        <div v-if="customerCount - nextCustomer >= 1" class="job">
                            <div style="text-align: center" class="job-col">... {{ customerCount - nextCustomer }} more lines</div>
                        </div>

                        <template v-if="customersWithError.length">
                            <span style="font-size: 14px; font-weight: bold; color: #333; margin-top: 15px; display: block;">Problems</span>
                            
                            <div v-for="(customer, i) in customersWithError" :key="'problem' + i" class="job">
                                <div style="flex: 0 0 20px; text-align: center" class="job-col">❌</div>
                                <div class="job-col">{{ customer.id }}</div>
                                <div class="job-col">{{ customer.error }}</div>
                            </div>
                        </template>


                        <div v-if="job.done" style="margin-top: 20px; color: #077740; font-weight: bold">All done!</div>
                    </vap-group>
                </vap-row>
            </vap-card>
        </div>


    </PageContent>

    <PageFooter :scrolled="scrolled" />

</div>
</template>
<script>
import api from '../portalapi';
import configTools from '../config';
import { newOrgNo, newOrgSe, newPersonNo } from '../v1helpers/onboardingHelper'

import Vue from "vue";
import PageHeader from '../v1ui/PageHeader.vue';
import PageContent from '../v1ui/PageContent.vue';
import PageFooter from '../v1ui/PageFooter.vue';

import ScreeningAddon, * as screening from '../v1onboarding/ScreeningAddon.vue'
import TestAddon, * as testaddon from '../v1onboarding/TestAddon.vue'

const dft = (str) => {
    return {
        tag: str,
        valid: false
    }
}

export default {
    name: 'OnboardingV1',
    components: {
        PageHeader,
        PageContent,
        PageFooter,

        ScreeningAddon,
        TestAddon,
    },
    computed: {
        dataRowWidth() {
            if(this.parsedCsvModelKeys.length < 5) {
                return "100%";
            }

            return `calc(100% + ${(this.parsedCsvModelKeys.length - 5) * 150}px`
        },
        recentCustomers() {
            if(!this.job || !this.job.customers) {
                return []
            }
            const result = []
            const lastN = this.customersCompleted.slice(-5)

            for(let i of lastN) {
                if(this.job.customers[i].error) {
                    continue
                }
                result.push(this.job.customers[i])
            }
            return result
        },
        recentCustomersCount() {
            if(!this.job || !this.job.customers) {
                return 0
            }

            let c = this.customersCompleted.length - this.customersWithProblem.length - 5
            if(c < 0) {
                c = 0
            }
            return c
        },
        inProgressCustomers() {
            if(!this.job || !this.job.customers) {
                return []
            }
            const result = []
            for(let i of this.customersInProgress) {
                result.push(this.job.customers[i])
            }
            return result
        },
        customersWithError() {
            if(!this.job || !this.job.customers) {
                return []
            }
            const result = []
            for(let i of this.customersWithProblem) {
                result.push(this.job.customers[i])
            }
            return result
        },
        nextCustomers() {
            if(!this.job || !this.job.customers) {
                return []
            }
            const result = []
            let toShow = 5
            
            if(toShow > this.job.customers.length - this.nextCustomer) {
                toShow = this.job.customers.length - this.nextCustomer
            }

            for(let i = 0; i < toShow; i++) {
                result.push(this.job.customers[this.nextCustomer + i])
            }

            return result
        },
        customerCount() {
            if(!this.job || !this.job.customers) {
                return 0
            }

            return this.job.customers.length
        }
    },
    data() {
        return {
            loading: false,
            scrolled: false,
            tabModel: {},
            tabOptions: {},
            actions: [],

            csv: "",
            csvError: "",
            parsedCsv: [],
            parsedCsvModelKeys: [],
            customerTypes: [
                "org-no",
                "org-se",
                "person-no"
            ],
            customerType: "org-no",
            customerFlows: [],
            customerFlow: "",
            requiredDataFields: [],

            availableAddons: [
                screening,
                testaddon
            ],
            selectedAddons: [],

            submitError: "",
            locked: false,

            job: {},
            numConcurrent: 1,
            nextCustomer: 0,
            customersInProgress: [],
            customersCompleted: [],
            customersWithProblem: [],
            workerPool: []

        }
    },
    methods: {
        async workCustomer(index, nextFunc) {
            const customer = this.job.customers[index]

            const statusFunc = (status) => this.job.customers[index].status = status
            const errorFunc = (error) => {
                customer.error = error
                if(!this.customersWithProblem.includes(index)) {
                    this.customersWithProblem.push(index)
                }
            }

            let clientId

            try {
                switch(this.customerType) {
                    case "org-no":
                        clientId = await newOrgNo(this.$root, statusFunc, errorFunc, customer.data.orgnr, this.customerFlow)
                        break;
                    case "org-se":
                        clientId = await newOrgSe(this.$root, statusFunc, errorFunc, customer.data.orgnr, this.customerFlow)
                        break;
                    case "person-no":
                        const person = {
                            firstname: customer.data.firstname,
                            lastname: customer.data.lastname,
                            dob: customer.data.dob,
                            country: customer.data.country,
                            email: customer.data.email,
                            phone: customer.data.phone,
                            ssn: customer.data.ssn
                        }
                        clientId = await newPersonNo(this.$root, statusFunc, errorFunc, person, this.customerFlow)
                        break;
                    default:
                        errorFunc("Invalid customer type")
                        break;
                }
            } catch(err) {
                console.error(err)
            }

            if(!clientId) {
                nextFunc()
                return
            }

            // process addons
            for(let addon of this.job.addons) {
                try {
                    await addon.process(this.$root, clientId, statusFunc, errorFunc, this.customerType, customer, undefined)
                } catch(err) {
                    console.error(err)
                    errorFunc("Failed to process addon", addon.label)
                }
            }

            nextFunc()
        },

        async delegateWork() {

            if(!this.job || this.job.state != 1) {
                return
            }
            
            if(this.nextCustomer >= this.job.customers.length) {
                console.log("all done")
                this.job.state = 0
                this.job.done = true
                return
            }

            if(this.numConcurrent < this.workerPool.length) {
                this.workerPool.splice(this.numConcurrent)
            }

            for(let i = 0; i < this.numConcurrent; i++) {
                if(i > this.workerPool.length || !this.workerPool[i]) {
                    this.workerPool.push({
                        free: true
                    })
                }
            }

            for(let worker of this.workerPool) {
                if(worker.free) {
                    worker.free = false
                    let index = this.nextCustomer
                    this.nextCustomer++
                    this.customersInProgress.push(index)
                    
                    this.workCustomer(index, () => {
                        worker.free = true
                        this.job.customers[index].state = 1
                        this.job.customers[index].status = "Done"
                        this.customersInProgress = this.customersInProgress.filter(x => x != index)
                        this.customersCompleted.push(index)
                        this.delegateWork()
                    })
                }
            }            
        },

        startJob() {
            this.job.state = 1
            this.delegateWork()
        },

        pauseJob() {
            this.job.state = 0
        },

        submit() {
            // is all the required data fields specified?
            for(let dft of this.requiredDataFields) {
                if(!dft.valid) {
                    this.submitError = `Required data field "${dft.tag}" is not specified`
                    return
                }
            }

            const job = {
                state: 0,
                customerType: this.customerType,
                customers: [],
                customersCount: 0,
                customersProcessed: 0,
                addons: this.selectedAddons,
                done: false,
            }

            // for each customer
            for(let line of this.parsedCsv) {

                const data = {}

                // for each field in this line of customer data
                for(let i = 0; i < line.length; i++) {
                    // does this field have a key specified?
                    if(this.parsedCsvModelKeys.length <= i || !this.parsedCsvModelKeys[i]) {
                        continue
                    }

                    data[this.parsedCsvModelKeys[i]] = line[i]
                }

                let id
                switch(this.customerType) {
                    case "org-no":
                        id = data.orgnr
                        break;
                    case "org-se":
                        id = data.orgnr
                        break;
                    case "person-no":
                        id = `${data.firstname} ${data.lastname}`
                        break;
                    default:
                        id = JSON.stringify(data)
                }

                job.customers.push({
                    id,
                    state: 0,
                    status: "...",
                    data
                })

            }

            job.customersCount = job.customers.length
            this.job = job
            this.locked = true
        },

        addonEnabled(label) {
            return !!this.selectedAddons.find(addon => addon.label == label)
        },

        updateRequiredDataFields() {
            this.requiredDataFields.splice(0)

            switch(this.customerType) {
                case "org-no":
                    this.requiredDataFields.push(...[dft("orgnr")])
                    break;
                case "org-se":
                    this.requiredDataFields.push(...[dft("orgnr")])
                    break;
                case "person-no":
                    this.requiredDataFields.push(...[dft("firstname"), dft("lastname")])
            }

            for(let addon of this.selectedAddons) {
                for(let field of addon.requiredFields(this.customerType)) {
                    if(!this.requiredDataFields.find(x => x.tag == "field")) {
                        this.requiredDataFields.push(...[dft(field)])
                    }
                }
            }

            this.parseCsvModelKeys()
        },

        parseCsv() {

            const csvSeparator = ","

            this.parsedCsv.splice(0)
            this.parsedCsvModelKeys.splice(0)
            this.csvError = ""

            this.updateRequiredDataFields()

            const lines = this.csv.split("\n");
            
            if(!lines.length || !this.csv) {
                this.csvError = "Please paste csv data above"
                return;
            }

            const fieldsCount = lines[0].split(csvSeparator).length
            const parsed = []

            for(let [i, line] of lines.entries()) {
                const fields = line.split(csvSeparator)

                if(fields.length !== fieldsCount) {
                    this.csvError = `Number of fields on line ${i + 1} does not match the first line`
                    return
                }

                parsed.push(fields)
            }

            this.parsedCsv.push(...parsed)

             for(let i = 0; i < fieldsCount; i++) {
                this.parsedCsvModelKeys.push("")
            }

        },

        onModelKeyChange(index, e) {
            Vue.set(this.parsedCsvModelKeys, index, e.target.value)
        },

        useAddon(addon) {
            Vue.set(this, "availableAddons", this.availableAddons.filter(x => x != addon))
            this.selectedAddons.push(addon)
            this.updateRequiredDataFields()
        },

        takeAddon(addon) {
            Vue.set(this, "selectedAddons", this.selectedAddons.filter(x => x != addon))
            this.availableAddons.push(addon)
            this.updateRequiredDataFields()
        },

        parseCsvModelKeys() {
            for(let dft of this.requiredDataFields) {
                dft.valid = false
                for(let key of this.parsedCsvModelKeys) {
                    if(key == dft.tag) {
                        dft.valid = true
                    }
                }
            }
        },

        updateFlowOptions() {
            this.customerFlows.splice(0)
            switch(this.customerType) {
                case "org-no": {
                    this.customerFlows.push("no-org-baml")
                    this.customerFlows.push(...this.$root.flowConfig.flows.filter(x => x.type == "org").map(x => x.key))
                    break;
                }
                case "person-no": {
                    this.customerFlows.push("yy-person")
                    this.customerFlows.push(...this.$root.flowConfig.flows.filter(x => x.type == "person").map(x => x.key))
                    break;
                }
                case "org-se": {
                    this.customerFlows.push("se-org-bistrap")
                    this.customerFlows.push(...this.$root.flowConfig.flows.filter(x => x.type == "org").map(x => x.key))
                    break;
                }
            }

            this.customerFlow = this.customerFlows[0]
        }

    },
    watch: {
        csv() {
            this.parseCsv();
        },
        customerType() {
            this.updateFlowOptions()
            this.parseCsv();
        },
        parsedCsvModelKeys() {
            this.parseCsvModelKeys()
        },
        numConcurrent() {
            this.delegateWork()
        }
    },
    mounted() {
        this.updateFlowOptions()
        this.parseCsv()
        
        // test data
        /* this.csv = "Test1,Testerson,010196\nTest2,Testerson,010196\nTest3,Testerson,010196\nTest4,Testerson,010196\nTest5,Testerson,010196\nTest6,Testerson,010196\nTest7,Testerson,010196\nTest8,Testerson,010196\nTest9,Testerson,010196\nTest10,Testerson,010196\nTest11,Testerson,010196\nTest12,Testerson,010196\nTest13,Testerson,010196\nTest14,Testerson,010196\nTest15,Testerson,010196\nTest16,Testerson,010196\nTest17,Testerson,010196\nTest18,Testerson,010196\nTest19,Testerson,010196\nTest20,Testerson,010196"
        this.useAddon(this.availableAddons.find(x => x.label == "screening")) */
    }
}
</script>
<style scoped>

.job-loading {
    width: 20px;
    height: 20px;
    border: 2px 2px 2px 0 solid blue;
    border-radius: 50%;
}

.job {
    display: flex;
    font-size: 12px;
    gap: 3px;
    margin-bottom: 3px;
}

.job-title {
    flex: 1;
    font-weight: bold;
    padding: 7px 10px;
    background: #eee;
}

.job-col {
    flex: 1;
    padding: 7px 10px;
    background: #eee;
}

.concurrency {
    display: inline-block;
    margin-top: 4px;
    margin-right: 15px;
    border-radius: 4px;
    background: #eee;
    height: 20px;
    padding: 5px 10px;
}

.concurrency > label {
    font-size: 12px;
    margin-right: 5px;
}

.play {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-top: 7px;
    opacity: 0.7;
}

.play:hover {
    opacity: 0.8;
    cursor: pointer;
}

.locked {
    opacity: 0.5;
    pointer-events: none;
}

.addon-flare {
    margin-bottom: 10px;
}

.addon-flare > div {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 12px;
    margin: 0 5px;
    color: #238CCC;
}

.addon {
    display: inline-block;
    font-size: 11px;
    color: #fff;
    border-radius: 50px;
    padding: 3px 7px;
    margin-right: 5px;
    background: #238CCC;
    text-transform: capitalize;
}

.no-addon {
    font-size: 11px;
    color: #000;
    padding: 3px 0px;
    margin-bottom: 4px;
}

.addon:hover {
    cursor: pointer;
    background: #156191;
}

.data-value {
    font-size: 12px;
}

.data-row {
    display: flex;
}

.data-col {
    background: #e1e1e1;
    padding: 10px;
    margin-right: 2px;
    margin-bottom: 2px;
    flex: 1;
}

.data-col > input {
    width: calc(100% - 10px)
}

.error-text {
    color: red;
    margin: 0;
    padding: 5px 0;
    font-size: 12px;
}

textarea {
    font-size: 14px;
}

.data-field-tag {
    display: inline-block;
    font-size: 11px;
    color: #fff;
    border-radius: 50px;
    padding: 3px 7px;
    margin-right: 5px;
}

.dft-valid {
    background: rgb(36, 92, 45);
}

.dft-invalid {
    background: rgb(161, 44, 44);
}

.data-fields {
    margin: 0 5px;
    max-height: 300px;
    overflow-x: scroll;
    overflow-y: auto;
    float: left;
    width: 100%;
}

.job-loading {
  display: inline-block;
  width: 15px;
  height: 15px;
}
.job-loading:after {
  content: " ";
  display: block;
  width: 10px;
  height: 10px;
  margin: 2px;
  border-radius: 50%;
  border: 2px solid #238CCC;
  border-color: #238CCC transparent #238CCC transparent;
  animation: job-loading 1.2s linear infinite;
}
@keyframes job-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>