<template>

    <div class="vap-info-item" :class="[{'vap-info-item-tb': topBorder}]">

        <div class="vap-info-item-tt">
            <vap-icon :icon="'help-o'">
                <template v-slot:tooltip>
                    <vap-tooltip :localized="tooltipKey" />
                </template>
            </vap-icon>
        </div>
        <div class="vap-info-item-text">
            <label>{{ __(descriptionKey) }}</label>
        </div>
        <div class="vap-info-item-value">
            <label>{{ value }}</label>
            <label>{{ unit }}</label>
        </div>

    </div>

</template>

<script>
export default {
    name: 'InfoItem',
    props: {
        descriptionKey: {
            type: String,
            default: 'description'
        },
        tooltipKey: {
            type: String,
            default: 'tooltip'
        },
        value: {
            type: [String, Number],
            default: 'value'
        },
        unit: {
            type: String,
            default: 'unit'
        },
        topBorder: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    async mounted() {
        
    }
}
</script>
<style scoped>

.vap-info-item {
    display: flex;
    border-bottom: solid 1px var(--input-border);
}

.vap-info-item-tb {
    border-top: solid 1px var(--input-border);
}

.vap-info-item-tt {
    width: 40px;
}

.vap-info-item-text {
    flex-grow: 100;
    padding-top: 9px;
    padding-left: 5px;
}

.vap-info-item-value {
    padding-top: 9px;
    font-weight: 500;
}

</style>