<template>
<div>
    
    <PopupV1 :header="__t('persons-merge')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="max-height: 600px; overflow: auto;">
                <div style="margin-bottom: 10px; color: var(--text-title);">
                    {{ __t('persons-merge-hint-1') + ':' }}
                </div>
                <DialogContent>
                    <VFRow style="margin-top: 20px; margin-bottom: -20px;">
                        <VFGroup c6>
                            <div class="pmdv2-desc">{{ __('common_name') }}</div>
                            <div class="pmdv2-value">{{ companyTitleCase(params.person.originalPerson.name) }}</div>
                        </VFGroup>
                        <VFGroup c6>
                            <div class="pmdv2-desc">{{ __('common_dob') }}</div>
                            <div class="pmdv2-value">{{ params.person.originalPerson.dateOfBirth }}</div>
                        </VFGroup>
                        <VFGroup c12 style="margin-top: -12px;">
                            <div class="pmdv2-desc">{{ __('common_address') }}</div>
                            <div class="pmdv2-value">{{ getAddress(params.person.originalPerson) }}</div>
                        </VFGroup>
                        <VFGroup c6 style="margin-top: -12px;">
                            <div class="pmdv2-desc">{{ 'ID-kontrol' }}</div>
                            <div class="pmdv2-value">{{ getIdcStatus(params.person.originalPerson) }}</div>
                        </VFGroup>
                        <VFGroup c6 style="margin-top: -12px;">
                            <div class="pmdv2-desc">{{ 'PEP/Sanksjon' }}</div>
                            <div class="pmdv2-value">{{ getPepStatus(params.person.originalPerson) }}</div>
                        </VFGroup>
                        <VFGroup c12 style="margin-top: -12px;">
                            <div class="pmdv2-desc">{{ __t('persons-merge-companies') }}</div>
                            <div class="pmdv2-value">{{ getClientString(params.person.originalPerson) }}</div>
                        </VFGroup>
                    </VFRow>
                </DialogContent>
                <div style="margin-top: 20px; margin-bottom: 10px; color: var(--text-title);">
                    {{ __t('persons-merge-hint-2') + ':' }}
                </div>

                <div class="pmdv2-item" v-for="(person, i) in params.person.results" :key="'pd' + i">
                    <DialogContent>
                        <VFRow style="margin-top: 10px; margin-bottom: -20px;">
                            <VFGroup c6>
                                <div class="pmdv2-desc">{{ __('common_name') }}</div>
                                <div class="pmdv2-value">{{ companyTitleCase(person.name) }}</div>
                            </VFGroup>
                            <VFGroup c6>
                                <div class="pmdv2-desc">{{ __('common_dob') }}</div>
                                <div class="pmdv2-value">{{ person.dateOfBirth }}</div>
                            </VFGroup>
                            <VFGroup c12 style="margin-top: -12px;">
                                <div class="pmdv2-desc">{{ __('common_address') }}</div>
                                <div class="pmdv2-value">{{ getAddress(person) }}</div>
                            </VFGroup>
                            <VFGroup c6 style="margin-top: -12px;">
                                <div class="pmdv2-desc">{{ __t('person-source-idc') }}</div>
                                <div class="pmdv2-value">{{ getIdcStatus(person) }}</div>
                            </VFGroup>
                            <VFGroup c6 style="margin-top: -12px;">
                                <div class="pmdv2-desc">{{ __t('person-source-scr') }}</div>
                                <div class="pmdv2-value">{{ getPepStatus(person) }}</div>
                            </VFGroup>
                            <VFGroup c12 style="margin-top: -12px;">
                                <div class="pmdv2-desc">{{ __t('persons-merge-companies') }}</div>
                                <div class="pmdv2-value">{{ getClientString(person) }}</div>
                            </VFGroup>
                        </VFRow>
                    </DialogContent>
                </div>
            </div>
            <div v-if="hasPendingIdControl" style="color: var(--val-error);">
                {{ __t('persons-merge-pending-idc') }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('persons-merge')" :icon="'merge'" @clicked="onMatch" :working="dialog.working" :disabled="hasPendingIdControl" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import dbPersonHelper from '../v1helpers/dbPersonHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';

import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import methodHelper from '../components/form/modules/signing/methodHelper';

export default {
    name: 'MatchDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFRow,
        VFGroup
    },
    props: {
        params: { type: Object, default: null },
        clientId: { type: String, default: '' },
    },
    data() {
        return {
            dialog: { working: false, error: '' },
            hasPendingIdControl: false,
        }
    },
    methods: {

        onClose() {
            this.$emit('close');
        },

        async onMatch() {
            this.dialog.working = true;

            const originalId = this.params.person.originalPerson._id;
            const results = [];
            for (const i in this.params.person.results) {
                results.push({
                    id: this.params.person.results[i]._id
                });
            }

            await v1api.mergePersons(originalId, results);

            const response = await v1api.getClient(this.clientId);

            await this.$parent.getSimilarPersons(true);

            this.$emit('merge', response);

            this.dialog.working = false;
        },

        getAddress(person) {
            return dbPersonHelper.getAddressString(this.$root, person);
        },

        getClientString(person) {
            let str = '';
            for (const i in person.clients) {
                if (i > 0) str += ', ';
                str += this.companyTitleCase(person.clients[i].name);
                if (person.clients[i].type == 'person') str += ' (Private individual)';
            }
            return str;
        },

        getIdcStatus(person) {

            if (person.idc.done) {
                return methodHelper.getMethodName(this.$root, person.idc.method, this.$root.locale.key);
            }

            return '- - -';
        },

        getPepStatus(person) {

            if (person.scr.done) {
                console.log('SCR', person.scr);
                return dbPersonHelper.getScreeningResult(this.$root, person, true);
            }

            return '- - -';
        },

    },
    async created() {
        console.log('params', this.params);
        for (const i in this.params.person.results) {
            const person = this.params.person.results[i];
            if (person.idc.sent && !person.idc.done) {
                this.hasPendingIdControl = true;
            }
        }
        if (this.params.person.originalPerson.idc.sent && !this.params.person.originalPerson.idc.done) {
            this.hasPendingIdControl = true;
        }
    }
}
</script>
<style scoped>

.pmdv2-item {
    margin-bottom: 10px;
}

.pmdv2-desc {
    font-size: 15px;
    color: var(--text-weak);
}

.pmdv2-value {
    font-size: 17px;
    color: var(--text-strong);
}

</style>