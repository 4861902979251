import triggerHelper from '../v1flows/triggerHelper';
const v1events = {

    //#region Events ------------------------------------------------------------------------------------------------------

    getClientEvents(root, flow, triggers, texts, client, persons) {
        const events = [];
        if (!flow.events || !flow.events.items) return events;

        for (const i in flow.events.items) {

            if (flow.events.items[i].list) {
                // This is a list of events (ID-controls, PEP-searches, Forms etc.)
                if (flow.events.items[i].list == 'idc') {

                    for (const x in triggers.idControls) {
                        const event = { valid: true, texts: {} };
                        for (const k in flow.events.items[i].triggers) {
                            const result = triggerHelper.validateTrigger(flow.events.items[i].triggers[k], triggers, x);
                            if (!result) event.valid = false;
                        }
                        if (event.valid) {
                            event.texts = this.replaceTexts(JSON.parse(JSON.stringify(flow.events.items[i].text)), texts, triggers.idControls[x].name);
                            events.push({
                                name: event.texts[root.locale.key],
                                date: triggers.idControls[x].date
                            });
                        }
                    }

                }else if (flow.events.items[i].list == 'idc-sent') {

                    for (const x in triggers.idControlSents) {
                        const event = { valid: true, texts: {} };
                        for (const k in flow.events.items[i].triggers) {
                            const result = triggerHelper.validateTrigger(flow.events.items[i].triggers[k], triggers, x);
                            if (!result) event.valid = false;
                        }
                        if (event.valid) {
                            event.texts = this.replaceTexts(JSON.parse(JSON.stringify(flow.events.items[i].text)), texts, triggers.idControlSents[x].name);
                            events.push({
                                name: event.texts[root.locale.key],
                                date: triggers.idControlSents[x].date
                            });
                        }
                    }

                }else if (flow.events.items[i].list == 'idc-rem') {

                    for (const x in triggers.idControlReminders) {
                        const event = { valid: true, texts: {} };
                        for (const k in flow.events.items[i].triggers) {
                            const result = triggerHelper.validateTrigger(flow.events.items[i].triggers[k], triggers, x);
                            if (!result) event.valid = false;
                        }
                        if (event.valid) {
                            event.texts = this.replaceTexts(JSON.parse(JSON.stringify(flow.events.items[i].text)), texts, triggers.idControlReminders[x].name);
                            events.push({
                                name: event.texts[root.locale.key],
                                date: triggers.idControlReminders[x].date
                            });
                        }
                    }

                }else if (flow.events.items[i].list == 'scr') {

                    for (const x in triggers.screenings) {
                        const event = { valid: true, texts: {} };
                        for (const k in flow.events.items[i].triggers) {
                            const result = triggerHelper.validateTrigger(flow.events.items[i].triggers[k], triggers, x);
                            if (!result) event.valid = false;
                        }
                        if (event.valid) {
                            event.texts = this.replaceTexts(JSON.parse(JSON.stringify(flow.events.items[i].text)), texts, triggers.screenings[x].name);
                            events.push({
                                name: event.texts[root.locale.key],
                                date: triggers.screenings[x].date
                            });
                        }
                    }

                }else if (flow.events.items[i].list == 'ams') {

                    for (const x in triggers.amsList) {
                        const event = { valid: true, texts: {} };
                        for (const k in flow.events.items[i].triggers) {
                            const result = triggerHelper.validateTrigger(flow.events.items[i].triggers[k], triggers, x);
                            if (!result) event.valid = false;
                        }
                        if (event.valid) {
                            event.texts = this.replaceTexts(JSON.parse(JSON.stringify(flow.events.items[i].text)), texts, triggers.amsList[x].name);
                            events.push({
                                name: event.texts[root.locale.key],
                                date: triggers.amsList[x].date
                            });
                        }
                    }

                }else if (flow.events.items[i].list == 'form-rem-edit') {
                    // Form reminders (edit)

                    if (!triggers.forms[flow.events.items[i].listForm]) continue;
                    const reminders = triggers.forms[flow.events.items[i].listForm].reminders;
                    for (const x in reminders) {
                        if (reminders[x].action != 'edit') continue;
                        const event = { valid: true, texts: {} };
                        for (const k in flow.events.items[i].triggers) {
                            const result = triggerHelper.validateTrigger(flow.events.items[i].triggers[k], triggers, x);
                            if (!result) event.valid = false;
                        }
                        if (event.valid) {
                            event.texts = this.replaceTexts(JSON.parse(JSON.stringify(flow.events.items[i].text)), texts, reminders[x].name);
                            events.push({
                                name: event.texts[root.locale.key],
                                date: reminders[x].date
                            });
                        }
                    }

                }else if (flow.events.items[i].list == 'form-rem-sign') {
                    // Form reminders (sign)

                    if (!triggers.forms[flow.events.items[i].listForm]) continue;
                    const reminders = triggers.forms[flow.events.items[i].listForm].reminders;
                    for (const x in reminders) {
                        if (reminders[x].action != 'sign') continue;
                        const event = { valid: true, texts: {} };
                        for (const k in flow.events.items[i].triggers) {
                            const result = triggerHelper.validateTrigger(flow.events.items[i].triggers[k], triggers, x);
                            if (!result) event.valid = false;
                        }
                        if (event.valid) {
                            event.texts = this.replaceTexts(JSON.parse(JSON.stringify(flow.events.items[i].text)), texts, reminders[x].name);
                            events.push({
                                name: event.texts[root.locale.key],
                                date: reminders[x].date
                            });
                        }
                    }

                }
            }else {
                // This is a single event
                const event = {
                    valid: true,
                    texts: {}
                };
    
                for (const k in flow.events.items[i].triggers) {
                    const result = triggerHelper.validateTrigger(flow.events.items[i].triggers[k], triggers);
                    if (!result) event.valid = false;
                }
    
                if (event.valid) {
                    event.texts = triggerHelper.replaceTexts(flow.events.items[i].text, texts);

                    let dateValid = true;

                    const eventItem = {
                        name: event.texts[root.locale.key],
                        date: this.getEventDate(flow.events.items[i].date, client, persons)
                    };

                    if (!eventItem.date) dateValid = false;

                    if (dateValid) events.push(eventItem);
                }

            }

            //console.log('event', event);

        }

        return events;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------

    replaceTexts(text, texts, name) {
        for (const i in text) {
            text[i] = text[i].split('{{idc-name}}').join(name);
            text[i] = text[i].split('{{scr-name}}').join(name);
            text[i] = text[i].split('{{event-name}}').join(name);
        }
        return triggerHelper.replaceTexts(text, texts);
    },

    getEventDate(date, client, persons) {
        if (date.type == 'client') {

            if (date.date == 'createdDate') {
                return client.dateCreated;
            }

        }else if (date.type == 'org') {

            if (date.date == 'searchDate') {
                return client.dateCreated;
            }else if (date.date == 'amsDate') {
                return (client.web && client.web.completed) ? client.web.date : new Date();
            }

        }else if (date.type == 'form') {

            if (date.date == 'sentDate') {
                return client[date.key].dateCreated;
            }else if (date.date == 'filledDate') {
                return client[date.key].dateFilled;
            }else if (date.date == 'signedDate') {
                return client[date.key].dateSigned;
            }

        }else if (date.type == 'report') {

            if (date.date == 'createdDate') {

                return client.report.dateCreated;

            }

        }
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default v1events;