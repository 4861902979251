<template>
<div :class="{'frv2-opts-alogic': logicActive }">
    <div v-if="input || card">
        <StyleTransformer>

            <PropertyHeader :header="getHeaderDisplay(input || card)">
                <template v-slot:actions v-if="!isPdfEditor">
                    <DesignerButton small
                        :icon="'delete'"
                        :tooltip="'Delete'"
                        @clicked="$emit('delete', input || card)"
                    />
                    <DesignerButton small v-if="reference.accessLevel >= 5"
                        :icon="'list'"
                        :tooltip="'Log to console'"
                        @clicked="logInput(input, card)"
                    />
                    <DesignerButton small
                        :icon="'chev-l'"
                        :tooltip="'Move down/left'"
                        @clicked="$emit('move', input || card, 'up')"
                    />
                    <DesignerButton small
                        :icon="'chev-r'"
                        :tooltip="'Move up/right'"
                        @clicked="$emit('move', input || card, 'down')"
                    />
                    <DesignerButton small
                        :icon="'copy'"
                        :tooltip="'Copy input'"
                        @clicked="$emit('copy', input || card)"
                    />
                </template>
            </PropertyHeader>

            <div v-if="card && card.type != 'buttons'">

                <!-- Buttons (card) -->
                <!-- <ButtonProperties :input="card" v-if="card.type == 'buttons'" /> -->

                <!-- Formatted Text properties (card) -->
                <FormattedTextProperties :card="card" v-if="card.type == 'cftext'" />

                <!-- Header properties -->
                <HeaderProperties :card="card" v-if="card.type == 'card-header'" :initialCollapse="isPdfEditor" />

                <!-- Header properties -->
                <PopupProperties :card="card" v-if="card.type == 'popup'" />

                <!-- Card properties -->
                <CardProperties :card="card" :reference="reference" :initialCollapse="isPdfEditor" v-if="card.type != 'pdft'" />

                <!-- Trigger properties -->
                <TriggerProperties v-if="!reference.temporaryDisable"
                    :reference="reference"
                    :input="card"
                    :hideEnabled="true"
                    @logic="$emit('logic', $event.key, $event.type)"
                />

                <!-- Model -->
                <ModelProperties :card="card" :reference="reference"
                    v-if="card.type == 'card' || card.type == 'list'"
                    @propinput="onPropInput"
                />

                <!-- PDF Text/Header properties -->
                <PdfTextProperties v-if="card.type == 'pdfh' || card.type == 'pdft'"
                    :card="card"
                    @propinput="onPropInput"
                />

                <!-- Checkout properties -->
                <CheckoutProperties :card="card" v-if="card.type == 'checkout'" />

                <!-- Methods properties -->
                <MethodsProperties :card="card" v-if="card.type == 'methods'" />

                <!-- Checklist (card) -->
                <ChecklistViewProperties v-if="card.type == 'chk-c' || card.type == 'chk-a'" :card="card" />

                <!-- List properties (card) -->
                <ListProperties :card="card" v-if="card.type == 'list' || card.type == 'chk-a'" />

                <!-- PDF properties -->
                <PdfProperties :card="card" :initialCollapse="!isPdfEditor" />

                <!-- Logic (card) -->
                <LogicProperties :input="card" :reference="reference" @reselect="$emit('reselect')" :initialCollapse="isPdfEditor" />

                <!-- KYC module (card) -->
                <KycModuleProperties :card="card" :initialCollapse="isPdfEditor" v-if="reference.kycModule.enabled && (card.type == 'card' || card.type == 'list' || card.type == 'chk-a')" @kyc="$emit('kyc', $event)" />

            </div>

            <div v-if="input">

                <!-- Formatted Text properties (input) -->
                <FormattedTextProperties :input="input" v-if="input.type == 'ftext'" />

                <!-- HTML Properties (input) -->
                <HTMLProperties :input="input" :reference="reference" v-if="input.type == 'htmlraw'" />

                <!-- Basic options -->
                <BasicInputProperties :input="input" :reference="reference" v-if="input.type != 'buttoni' && input.type != 'ftext' && input.type != 'htmlraw' && input.type != 'valsum'" @propinput="onPropInput" @locale="$emit('locale', $event)" @trans="$emit('trans', $event)" />

                <!-- Trigger properties -->
                <TriggerProperties :reference="reference" :input="input" @logic="$emit('logic', $event.key, $event.type, true)" />

                <!-- Model -->
                <ModelProperties :input="input" :reference="reference"
                    v-if="input.type == 'text' || input.type == 'drop' || input.type == 'date' || input.type == 'country' || input.type == 'phone'
                    || input.type == 'check2'"
                    @propinput="onPropInput"
                />

                <!-- Tooltip -->
                <TooltipProperties :input="input"
                    v-if="input.type == 'ttip'"
                />

                <!-- Input properties -->
                <InputProperties v-if="input.type == 'text'"
                    :input="input"
                    @propinput="onPropInput"
                />

                <!-- List properties (input) -->
                <ListProperties :input="input" v-if="input.isList" />

                <!-- Checkbox -->
                <CheckboxProperties v-if="input.type == 'check'"
                    :reference="reference"
                    :input="input"
                    @propinput="onPropInput"
                    @trans="$emit('trans', $event)"
                    @kyc="$emit('kyc', $event)"
                    @reselect="$emit('reselect')"
                />

                <!-- Checkbox V2 -->
                <CheckboxPropertiesV2 v-if="input.type == 'check2'"
                    :reference="reference"
                    :input="input"
                    @propinput="onPropInput"
                    @trans="$emit('trans', $event)"
                    @kyc="$emit('kyc', $event)"
                    @reselect="$emit('reselect')"
                />

                <!-- Dropdown -->
                <DropdownProperties v-if="input.type == 'drop'"
                    :input="input"
                    :reference="reference"
                    @propinput="onPropInput"
                    @trans="$emit('trans', $event)"
                />

                <!-- Datepicker -->
                <DatepickerProperties v-if="input.type == 'date'"
                    :input="input"
                    :reference="reference"
                    @propinput="onPropInput"
                />

                <!-- Country Selector -->
                <!-- <CountrySelectorProperties :input="input" v-if="input.type == 'country'" /> -->

                <!-- Language Selector -->
                <LanguageSelectorProperties v-if="input.type == 'language'"
                    :input="input"
                    :reference="reference"
                    @propinput="onPropInput"
                    @trans="$emit('trans', $event)"
                />

                <!-- Phone -->
                <PhoneProperties v-if="input.type == 'phone'"
                    :input="input"
                    :reference="reference"
                    @propinput="onPropInput"
                />

                <!-- Fileupload -->
                <FileuploadProperties :input="input" v-if="input.type == 'upload'" />

                <!-- Filepreview -->
                <FilepreviewProperties :input="input" v-if="input.type == 'preview'" />

                <!-- Buttons (input) -->
                <ButtonProperties :input="input" v-if="input.type == 'buttoni'" />

                <!-- Checklist (input) -->
                <ChecklistItemProperties v-if="input.type == 'chk-i'" :input="input" @locale="$emit('locale', $event)" />

                <!-- Logic (input) -->
                <LogicProperties :input="input" :reference="reference"  @reselect="$emit('reselect')" />

                <!-- KYC module (input) -->
                <KycModuleProperties :input="input" v-if="reference.kycModule.enabled && !input.isList && input.type != 'check' && input.type != 'ftext'" @kyc="$emit('kyc', $event)" />

            </div>

        </StyleTransformer>
    </div>
    <div v-if="helpline">

        <PropertyHeader :header="'Helptext'">
            <template v-slot:actions>
                <DesignerButton small v-if="!reference.temporaryDisable"
                    :icon="'merge'"
                    :tooltip="'Other roles'"
                    @clicked="mergeHelplineVisible = true"
                />
                <DesignerButton small
                    :icon="'delete'"
                    :tooltip="'Delete'"
                    @clicked="$emit('linedelete', helpline)"
                />
            </template>
        </PropertyHeader>

        <HelpLineProperties :helpline="helpline" @icon="$emit('icon', $event)" @propinput="onPropInput" />

        <MergeHelplineDialog v-if="mergeHelplineVisible" :helpline="helpline" :reference="reference" @close="mergeHelplineVisible = false" />

    </div>
</div>
</template>
<script>
import FormMenuButton from './FormMenuButton';
import PropertyHeader from './ui/PropertyHeader.vue';
import PropertyItem from './properties/PropertyItem.vue';
import DesignerButton from './ui/DesignerButton.vue';

import CardProperties from './properties/CardProperties.vue';

import BasicInputProperties from './properties/BasicInputProperties.vue';

import InputProperties from './properties/InputProperties.vue';
import ButtonProperties from './properties/ButtonProperties.vue';
import CheckboxProperties from './properties/CheckboxProperties.vue';
import CheckboxPropertiesV2 from './properties/CheckboxPropertiesV2.vue';
import DropdownProperties from './properties/DropdownProperties.vue';
import DatepickerProperties from './properties/DatepickerProperties.vue';
import CountrySelectorProperties from './properties/CountrySelectorProperties.vue';
import FileuploadProperties from './properties/FileuploadProperties.vue';
import FilepreviewProperties from './properties/FilepreviewProperties.vue';
import HeaderProperties from './properties/HeaderProperties.vue';
import PopupProperties from './properties/PopupProperties.vue';
import PhoneProperties from './properties/PhoneProperties.vue';
import LanguageSelectorProperties from './properties/LanguageSelectorProperties.vue';
import FormattedTextProperties from './properties/FormattedTextProperties.vue';
import KycModuleProperties from './properties/KycModuleProperties.vue';
import ListProperties from './properties/ListProperties.vue';
import MethodsProperties from './properties/MethodsProperties.vue';
import HTMLProperties from './properties/HTMLProperties.vue';

import LogicProperties from './properties/LogicProperties.vue';

import ChecklistViewProperties from '../modules/checklist/ChecklistViewProperties.vue';
import ChecklistItemProperties from '../modules/checklist/ChecklistItemProperties.vue';

import CheckoutProperties from './properties/CheckoutProperties.vue';

import TriggerProperties from './properties/TriggerProperties.vue';
import ModelProperties from './properties/ModelProperties.vue';
import PdfProperties from './properties/PdfProperties.vue';
import PdfTextProperties from './properties/PdfTextProperties.vue';

import HelpLineProperties from './properties/HelpLineProperties.vue';
import MergeHelplineDialog from './dialogs/MergeHelplineDialog.vue';

import TooltipProperties from './properties/TooltipProperties.vue';

import StyleTransformer from './properties/StyleTransformer.vue';

export default {
    name: 'FormPropertiesV2',
    components: {
        FormMenuButton,
        PropertyHeader,
        PropertyItem,
        DesignerButton,
        CardProperties,
        BasicInputProperties,
        InputProperties,
        ButtonProperties,
        CheckboxProperties,
        CheckboxPropertiesV2,
        DropdownProperties,
        DatepickerProperties,
        CountrySelectorProperties,
        FileuploadProperties,
        FilepreviewProperties,
        LogicProperties,
        ChecklistViewProperties,
        ChecklistItemProperties,
        CheckoutProperties,
        HeaderProperties,
        PopupProperties,
        PhoneProperties,
        LanguageSelectorProperties,
        FormattedTextProperties,
        KycModuleProperties,
        ListProperties,
        MethodsProperties,
        TriggerProperties,
        ModelProperties,
        PdfProperties,
        PdfTextProperties,
        HelpLineProperties,
        MergeHelplineDialog,
        TooltipProperties,
        StyleTransformer,
        HTMLProperties,
    },
    props: {
        card: { type: Object, default: null },
        input: { type: Object, default: null },
        helpline: { type: Object, default: null },
        logicActive: { type: Boolean, default: false },
        reference: { type: Object, default: null },
        isPdfEditor: { type: Boolean, default: false },
    },
    data() {
        return {
            mergeHelplineVisible: false,
        }
    },
    methods: {
        
        logInput(input, card) {
            if (input) console.log('input', input);
            if (card) console.log('card', card);
        },

        onPropInput(type, key, value) {
            this.$emit('propinput', type, key, value);
        },

        getHeaderDisplay(item) {
            switch (item.type) {
                case 'card': return 'Card';
                case 'list': return 'Card list';
                case 'text': return 'Text input';
                case 'date': return 'Datepicker';
                case 'check2': return 'Checkbox';
                case 'checku': return 'Universal checkout';
                case 'kyci': return 'Customer information';
                case 'kyca': return 'Assessment information';
            }
            return item.type;
        },

    },
}
</script>
<style scoped>

.frv2-opts-alogic {
    pointer-events: none;
}

</style>
