import formHelper from "./formHelper";
/* import pdfRenderer from "../../../functions/v1api/logic/pdfRenderer"; */
const pdfHelper = {

    getConfig(root, formData) {
        console.log('PDF formdata', formData);
        const config = {
            logo: root.config.pdf.logoUrl,
            colors: {
                headerBack: root.getColor('pdf-header-bg'),
                headerText: root.getColor('pdf-header-text'),
                inputAccent: root.getColor('pdf-input-accent'),
            },
            language: root.locale.key
        };
        if (formData && formData.flowConfig && formData.flowConfig.branding) {
            if (formData.flowConfig.branding.logoPdf == 'none') {
                config.logo = 'https://aml-portal.web.verified.eu/logo/empty.png';
            }else if (formData.flowConfig.branding.logoPdf) {
                config.logo = formData.flowConfig.branding.logoPdf;
            }
        }
        console.log('PDF CONFIG', config);
        return config;
    },

    getMetadata(data, language, designer) {
        console.log('data', data);
        const metadata = {

        };

        if (designer) {


            metadata.title = data.title[language];
            metadata.name = data.name[language];

            // TODO support processed name


        }else {

            metadata.title = data.formConfig.title[language];

            if (data.formConfig.name) {
                metadata.name = data.formConfig.name[language];
            }else {
                metadata.name = data.formConfig.title[language];
            }
            // TODO fix
            


        }

        return metadata;
    },

    getRemovedCards(pages, language, designer) {
        const cards = [];
        for (const i in pages) {
            for (const j in pages[i].cards) {
                if (pages[i].cards[j].type == 'buttons' || pages[i].cards[j].type == 'logo' || pages[i].cards[j].type == 'methods' || pages[i].cards[j].type == 'custom' || pages[i].cards[j].type == 'checkout') continue;

                if (!pages[i].cards[j].pdf) {
                    cards.push({
                        key: pages[i].cards[j].key,
                        name: pages[i].cards[j].header ? pages[i].cards[j].header[language] : 'Unknonwn card'
                    });
                }

            }
        }
        return cards;
    },

    processPages(pages, language, designer, userdata, reference, empty) {
        /* const test = pdfRenderer.processPages(pages, language, designer, userdata, reference);
        console.log('test', test); */

        if (empty) {
            console.log('Processing EMPTY pages', pages);
        }

        const cards = [];
        for (const i in pages) {
            for (const j in pages[i].cards) {
                if (pages[i].cards[j].pdf) {
                    if (pages[i].cards[j].type == 'custom') {

                        if (pages[i].cards[j].sub == 'idcr') {

                            this.processIdcResult(cards, pages[i].cards[j], language, designer, userdata);

                        }else if (pages[i].cards[j].sub == 'karr') {

                            this.processKarResult(cards, pages[i].cards[j], language, designer, userdata);

                        }else if (pages[i].cards[j].sub == 'header') {

                            this.processHeader(cards, pages[i].cards[j], language, designer, userdata);

                        }

                    }else if (pages[i].cards[j].type == 'pdfh') {

                        this.processPdfHeader(cards, pages[i].cards[j], language, designer, userdata);

                    }else if (pages[i].cards[j].type == 'pdft') {

                        this.processPdfText(cards, pages[i].cards[j], language, designer, userdata);

                    }else {
                        if (formHelper.isCardVisible(reference, pages[i].cards[j], designer, pages, pages[i], 'done') || empty) {
                            if(pages[i].cards[j].type == 'card-header') {
                                this.processCardHeader(cards, pages[i].cards[j], language, designer, userdata);
                            }
                            else if (pages[i].cards[j].type == 'cftext') {

                                this.processFormattedText(cards, pages[i].cards[j], language, designer, userdata, reference);

                            }else if (pages[i].cards[j].type == 'chk-c') {

                                this.processChecklist(cards, pages[i].cards[j], language, designer, userdata, reference);

                            }else if (pages[i].cards[j].type == 'chk-a') {

                                this.processChecklistA(cards, pages[i].cards[j], language, designer, userdata, reference);

                            }else if (pages[i].cards[j].type == 'anas') {

                                this.processAnalogSignature(cards, pages[i].cards[j], language, designer, userdata, reference);

                            }else if (pages[i].cards[j].type == 'chk-h' || pages[i].cards[j].type == 'chk-hqa') {

                                // TODO do something here?

                            }else if (formHelper.isList(pages[i].cards[j])) {
                                for (const m in pages[i].cards[j].model) {

                                    const card = { key: pages[i].cards[j].key, rows: [], type: 'card', header: (pages[i].cards[j].header[language] + (pages[i].cards[j].hideListNum ? '' : (' ' + (Number(m) + 1)))) };
                                    let currentRow = { inputs: [] };
                                    let widthCount = 0;
                                    for (const k in pages[i].cards[j].inputs) {

                                        if (formHelper.isListInputVisible(reference, pages[i].cards[j], pages[i].cards[j].inputs[k], m, designer, pages, pages[i]) || empty) {
                                            const input = pages[i].cards[j].inputs[k];
                                            widthCount += Number(input.width);
                                            if (widthCount > 12) {
                                                widthCount = Number(input.width);
                                                card.rows.push(currentRow);
                                                currentRow = { inputs: [] };
                                                currentRow.inputs.push(this.processInput(reference, input, language, designer, pages[i].cards[j].model[m][input.listModel], empty));
                                            }else {
                                                currentRow.inputs.push(this.processInput(reference, input, language, designer, pages[i].cards[j].model[m][input.listModel], empty));
                                            }
                                        }

                                    }
                                    card.rows.push(currentRow);
                                    cards.push(card);

                                }
                            }else {

                                const card = { key: pages[i].cards[j].key, rows: [], type: 'card', header: pages[i].cards[j].header ? pages[i].cards[j].header[language] : '' };
                                let currentRow = { inputs: [] };
                                let widthCount = 0;
                                for (const k in pages[i].cards[j].inputs) {
                                    if (formHelper.isInputVisible(reference, pages[i].cards[j].inputs[k], designer, pages, pages[i]) || empty) {
                                        const input = pages[i].cards[j].inputs[k];
                                        widthCount += Number(input.width);
                                        if (widthCount > 12) {
                                            widthCount = Number(input.width);
                                            card.rows.push(currentRow);
                                            currentRow = { inputs: [] };
                                            currentRow.inputs.push(this.processInput(reference, input, language, designer, input.model, empty));
                                        }else {
                                            currentRow.inputs.push(this.processInput(reference, input, language, designer, input.model, empty));
                                        }
                                    }
                                }
                                card.rows.push(currentRow);
                                cards.push(card);

                            }
                        }
                    }
                }
            }
        }
        return cards;
    },

    processChecklist(cards, card, language, designer, userdata, reference) {
        //console.log('processing', card);
        language = 'en_EN';
        if (designer) return;

        const item = {
            isCustom: true,
            key: card.key,
            rows: [],
            type: 'checklist',
            header: card.header ? card.header[language] : ''
        };

        for (const i in card.inputs) {
            const input = {
                type: 'chk',
                width: '12',
                columns: []
            };
            if (i == card.inputs.length - 1) input.last = true;

            input.columns.push({
                width: '30',
                value: card.inputs[i].desc ? card.inputs[i].desc[language] : ''
            });
            input.columns.push({
                width: '10',
                value: this.getChecklistValue(reference, card.inputs[i].ids, card.inputs[i].model.ids)
            });
            input.columns.push({
                width: '10',
                value: this.getChecklistValue(reference, card.inputs[i].vs, card.inputs[i].model.vs)
            });
            const column = {
                width: '30',
                value: 'com',
                comments: []
            };
            for (const j in card.inputs[i].model.comments) {
                console.log('comm', card.inputs[i].model.comments[j]);
                column.comments.push({
                    role: card.inputs[i].model.comments[j].role,
                    date: reference.formatDate('format', card.inputs[i].model.comments[j].date),
                    text: card.inputs[i].model.comments[j].text
                });
            }
            input.columns.push(column);
            input.columns.push({
                width: '10',
                value: this.getChecklistValue(reference, card.inputs[i].qcr, card.inputs[i].model.qcr)
            });
            input.columns.push({
                width: '10',
                value: this.getChecklistValue(reference, card.inputs[i].qcc, card.inputs[i].model.qcc),
                last: true
            });

            item.rows.push(input);
        }

        cards.push(item);
    },

    processChecklistA(cards, card, language, designer, userdata, reference) {
        //console.log('processing', card);
        language = 'en_EN';
        if (designer) return;

        for (const j in card.model) {

            const item = {
                isCustom: true,
                key: card.key,
                rows: [],
                type: 'checklist',
                header: card.itemModel[j].name
            };

            
            for (const i in card.inputs) {
                const input = {
                    type: 'chk',
                    width: '12',
                    columns: []
                };
                if (i == card.inputs.length - 1) input.last = true;

                input.columns.push({
                    width: '30',
                    value: card.inputs[i].desc ? card.inputs[i].desc[language] : ''
                });
                input.columns.push({
                    width: '10',
                    value: this.getChecklistValue(reference, card.inputs[i].ids, card.model[j][card.inputs[i].key].ids)
                });
                input.columns.push({
                    width: '10',
                    value: this.getChecklistValue(reference, card.inputs[i].vs, card.model[j][card.inputs[i].key].vs)
                });
                const column = {
                    width: '30',
                    value: 'com',
                    comments: []
                };
                for (const x in card.model[j][card.inputs[i].key].comments) {
                    column.comments.push({
                        role: card.model[j][card.inputs[i].key].comments[x].role,
                        date: reference.formatDate('format', card.model[j][card.inputs[i].key].comments[x].date),
                        text: card.model[j][card.inputs[i].key].comments[x].text
                    });
                }
                input.columns.push(column);
                input.columns.push({
                    width: '10',
                    value: this.getChecklistValue(reference, card.inputs[i].qcr, card.model[j][card.inputs[i].key].qcr)
                });
                input.columns.push({
                    width: '10',
                    value: this.getChecklistValue(reference, card.inputs[i].qcc, card.model[j][card.inputs[i].key].qcc),
                    last: true
                });

                item.rows.push(input);
            }

            cards.push(item);
        }
    },

    getChecklistValue(reference, list, model) {
        if (!reference.resources[list]) {
            return 'N/A';
        }
        if (!model) return 'N/A';
        for (const i in reference.resources[list].items) {
            if (reference.resources[list].items[i].value == model) {
                return reference.resources[list].items[i].en_EN;
            }
        }
        return 'ERROR';
    },

    processIdcResult(cards, card, language, designer, userdata) {

        const item = {
            isCustom: true,
            key: card.key,
            rows: [],
            type: 'card',
            header: 'Result'
        };

        const row1 = {
            inputs: [
                {
                    type: 'text',
                    width: '6',
                    desc: 'Identification method',
                    value: designer ? 'BankID Norway' : userdata.idcResult.method
                },
                {
                    type: 'text',
                    width: '6',
                    desc: 'Date',
                    value: designer ? new Date().toString() : userdata.idcResult.date
                }
            ]
        };
        item.rows.push(row1);

        const row2 = {
            inputs: [
                {
                    type: 'text',
                    width: '6',
                    desc: 'First Name',
                    value: designer ? 'Test' : userdata.idcResult.firstName
                },
                {
                    type: 'text',
                    width: '6',
                    desc: 'Last Name',
                    value: designer ? 'Testerson' : userdata.idcResult.lastName
                }
            ]
        };
        item.rows.push(row2);

        const row3 = {
            inputs: [
                {
                    type: 'text',
                    width: '6',
                    desc: 'Date of Birth',
                    value: designer ? '1989-08-14' : userdata.idcResult.dateOfBirth
                },
                {
                    type: 'text',
                    width: '6',
                    desc: 'SSN',
                    value: designer ? '14088978213' : userdata.idcResult.ssn
                }
            ]
        };
        item.rows.push(row3);

        cards.push(item);
        console.log('adding', item);
    },

    processKarResult(cards, card, language, designer, userdata) {

        const item = {
            isCustom: true,
            key: card.key,
            rows: [],
            type: 'card',
            header: 'KAR Resultat'
        };

        const row1 = {
            inputs: [
                {
                    type: 'text',
                    width: '6',
                    desc: 'Date',
                    value: designer ? new Date().toString() : userdata.karResult.date
                },
                {
                    type: 'text',
                    width: '3',
                    desc: 'Verifisert',
                    value: designer ? 'JA' : userdata.karResult.verified
                },
                {
                    type: 'text',
                    width: '3',
                    desc: 'Status',
                    value: designer ? '01' : userdata.karResult.statusCode
                }
            ]
        };
        item.rows.push(row1);

/*         const row2 = {
            inputs: [
                {
                    type: 'text',
                    width: '6',
                    desc: 'First Name',
                    value: designer ? 'Test' : userdata.idcResult.firstName
                },
                {
                    type: 'text',
                    width: '6',
                    desc: 'Last Name',
                    value: designer ? 'Testerson' : userdata.idcResult.lastName
                }
            ]
        };
        item.rows.push(row2); */

        const row3 = {
            inputs: [
                {
                    type: 'text',
                    width: '12',
                    desc: 'Beskrivelse',
                    value: designer ? 'Ja. Konto finnes og eies av angitt kunde.' : userdata.karResult.message
                }
            ]
        };
        item.rows.push(row3);

        cards.push(item);
        console.log('adding', item);
    },

    processAnalogSignature(cards, card, language, designer, userdata) {
        cards.push({
            type: 'chtml',
            html: `<div style="width: 300px; margin: 0 auto; text-align: center; font-size: 14px;">

            <div style="height: 60px; border-bottom: solid 2px black;">
    
            </div>
    
            <div class="vf-anas-hint">
                Signatur
            </div>
    
        </div>`
        })
    },

    processCardHeader(cards, card, language, designer, userdata) {
        cards.push({
            type: 'chtml',
            html: `<div><h1 style="font-size: ${(card.size || 26).toString()}px">${card.value[language] ? card.value[language] : ""}</h1></div>`
        })
    },

    processFormattedText(cards, card, language, designer, userdata) {
        cards.push({
            type: 'cftext',
            html: card.header[language]
        });
    },

    processHeader(cards, card, language, designer, userdata) {

        const item = {
            isCustom: true,
            key: card.key,
            rows: [],
            type: 'header',
            header: card.desc ? card.desc[language] : ''
        };

        cards.push(item);
        console.log('adding', item);
    },

    processPdfHeader(cards, card, language, designer, userdata) {
        const style = 'font-size: 20px; margin-top: 40px; margin-bottom: 5px;';
        cards.push({
            type: 'chtml',
            html: `<div style="${style}">${card.header[language] ? card.header[language] : ""}</div>`
        });
    },

    processPdfText(cards, card, language, designer, userdata) {
        const style = 'font-size: 14px;';
        cards.push({
            type: 'chtml',
            html: `<div style="${style}">${card.header[language] ? card.header[language] : ""}</div>`
        });
    },

    processInput(reference, input, language, designer, model, empty) {
        const data = {
            type: this.getInputType(input),
            width: input.width,
            desc: this.getInputDesc(input, language)
        };
        if (empty) data.empty = true;
        if (data.type == 'text') data.value = this.getInputValue(reference, input, language, designer, model, empty);

        if (input.type == 'htmlraw' && input.model) {
            data.desc = input.model;
        }

        if (input.type == 'check') {
            data.options = [];
            for (const i in input.options) {
                data.options.push({
                    desc: input.options[i].locale[language],
                    checked: this.getCheckValue(input, input.options[i], designer, model)
                });
            }
            data.inline = input.inline;
        }
        if (input.type == 'check2') {
            data.options = [];
            for (const i in input.items) {
                data.options.push({
                    desc: input.items[i].text[language],
                    checked: this.getCheckValue(input, input.items[i], designer, model)
                });
            }
            data.inline = input.inline;
        }

        if (data.type == 'chk-i') {
            data.width = '12';
        }

        return data;
    },

    getInputType(input) {
        if (input.type == 'check') return 'check';
        if (input.type == 'check2') return 'check';
        if (input.type == 'drop') return 'text';
        if (input.type == 'helpt') return 'html';
        if (input.type == 'ftext') return 'html';
        if (input.type == 'htmlraw') return 'html';
        if (input.type == 'chk-i') return 'chk-i';
        return 'text';
    },

    getInputDesc(input, language) {
        if (input.desc) return input.desc[language];
    },

    getInputValue(reference, input, language, designer, model, empty) {
        if (empty) return '';
        if (designer) {

            return 'test';

        }else {
            if (input.type == 'drop') {

                for (const i in input.items) {
                    if (input.items[i].key == model || input.items[i].value == model) {
                        return input.items[i][language];
                    }
                }
                return model;

            }else if (input.type == 'phone') {

                return model.prefix + ' ' + model.number;

            }else if (input.type == 'country') {

                if (reference && reference.$root) {
                    const country = reference.$root.getCountry(model);
                    if (country && country.name && country.name[language]) return country.name[language];
                }
                return model;

            }else if(input.type == 'upload') {

                if(!model.files || model.files.length < 1) {
                    return "";
                }
                return model.files.map(x => x.name).join(", ");

            }else {

                return model;

            }
        }
    },

    getCheckValue(input, option, designer, model) {
        if (designer) {

            const randomNumber = Math.floor(Math.random() * (10 - 1 + 1) + 1);
            if (randomNumber < 5) return true;
            return false;

        }else {

            if (typeof model == 'boolean') {
                if (input.items && input.items.length == 1) {
                    return model;
                }else {
                    if (model === true && option.modelKey == 'yes') return true;
                    if (model === false && option.modelKey == 'no') return true;
                }
            }

            for (const key in model) {
                if (key == option.modelKey || key == option.key) return model[key];
            }
            return false;

        }
    }

};
export default pdfHelper;