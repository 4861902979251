import Vue from 'vue'
import App from './App.vue'
import router from './router'
import components from './components'

Vue.config.productionTip = false

Vue.use(require('vue-cookies'))
Vue.$cookies.config('7d')

components.install(Vue)
import "./app.css"

import tools from './tools'
import cache from './cache'

import vfblue from '@/assets/verified-logo-blue.svg'
import vfwhite from '@/assets/verified-logo-white.svg'
import bamltiny from '@/assets/bankid-aml-logo-tiny.png'
import trapetstiny from '@/assets/trapets-logo-tiny.png'
import flag_EN from '@/assets/flags/en_EN.png'
import flag_NO from '@/assets/flags/nb_NO.png'
import flag_SE from '@/assets/flags/sv_SE.png'
import flag_FI from '@/assets/flags/fi_FI.png'
import flag_DK from '@/assets/flags/da_DK.png'
import flag_DE from '@/assets/flags/de_DE.png'
import flag_UN from '@/assets/flags/un_UN.png'

import cflag_EN from '@/assets/flags/circle/en_EN.png'
import cflag_NO from '@/assets/flags/circle/nb_NO.png'
import cflag_SE from '@/assets/flags/circle/sv_SE.png'
import cflag_FI from '@/assets/flags/circle/fi_FI.png'
import cflag_DK from '@/assets/flags/circle/da_DK.png'
import cflag_DE from '@/assets/flags/circle/de_DE.png'

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)

Vue.mixin({
    methods: {
        __t(key) {
            if (this.$root.v1translations[key] && this.$root.v1translations[key][this.$root.locale.key]) {
                return this.$root.v1translations[key][this.$root.locale.key];
            }else {
                if (this.$root.v1translations[key] && this.$root.v1translations[key]['en_EN']) {
                    return this.$root.v1translations[key]['en_EN'];
                }else {
                    return key + '.' + this.$root.locale.key;
                }
            }
        },
        __(key) {
            if (this.$root.translations[key] && this.$root.translations[key][this.$root.locale.key]) {
                return this.$root.translations[key][this.$root.locale.key];
            }else {
                if (this.$root.translations[key] && this.$root.translations[key]['en_EN']) {
                    return this.$root.translations[key]['en_EN'];
                    //return key + '.' + this.$root.locale.key;
                }else {
                    return key + '.' + this.$root.locale.key;
                }
            }
        },
        ___(parent, key) {
            if (parent.translations && parent.translations[key] && parent.translations[key][this.$root.locale.key]) {
                return parent.translations[key][this.$root.locale.key];
            }
            if (this.$root.translations[key] && this.$root.translations[key][this.$root.locale.key]) {
                return this.$root.translations[key][this.$root.locale.key];
            }else {
                return key + '.' + this.$root.locale.key;
            }
        },
        formatDate(format, input) {
            if (format == 'datetime') {
                return tools.dateString(input, this.$root.locale);
            }
            return tools.dateStringLong(input, this.$root.locale);
        },
        formatDob(input) {

            //console.log('input', input);

            const date = new Date(input);
            //console.log('date', date);
            try {

                if (this.$root.config.params.dateFormat == 'iso') {
                    return date.toISOString().substring(0, 10);
                }else if (this.$root.config.params.dateFormat == 'no-long') {
                    let day = date.getDate().toString();
                    if (date.getDate() < 10) day = '0' + day;
                    let mon = tools.getMonthName(date.getMonth(), this.$root.locale.key);
                    if (mon.length > 3) mon = mon.substring(0, 3);
                    let year = date.getFullYear();
                    return `${day}. ${mon} ${year}`;
                }

            } catch(ex) {
                return input
            }
            
            return input;
        },
        replaceBrackets(input, data, blankRecipient) {
            if (!input) return '';
            if (!data) {
                data = {
                    firstName: 'Fornavn',
                    lastName: 'Etternavn',
                    customerName: 'Selskapsnavn AS',
                    senderName: this.$root.user.givenName + ' ' + this.$root.user.familyName,
                    senderEmail: this.$root.user.email,
                    action: 'signering',
                    actionVerb: 'signere',
                    reference: this.__('customer_reference_message') + ': 123456789'
                }
            }
            let output = input.split('{{companyName}}').join(this.$root.user.companyName);
            if (data.firstName && !blankRecipient) output = output.split('{{firstName}}').join(data.firstName);
            if (data.lastName && !blankRecipient) output = output.split('{{lastName}}').join(data.lastName);
            if (data.customerName) output = output.split('{{customerName}}').join(data.customerName);
            if (data.senderName) output = output.split('{{senderName}}').join(data.senderName);
            if (data.senderEmail) output = output.split('{{senderEmail}}').join(data.senderEmail);
            if (data.action && !blankRecipient) output = output.split('{{action}}').join(data.action);
            if (data.action && !blankRecipient) output = output.split('{{actionVerb}}').join(data.actionVerb);
            if (data.recipientName) output = output.split('{{recipientName}}').join(data.recipientName);
            if (data.editorName) output = output.split('{{editorName}}').join(data.editorName);
            if (data.projectName) output = output.split('{{projectName}}').join(data.projectName);
            if (data.reference) {
                output = output.split('{{reference}}').join(data.reference);
            }else {
                output = output.split('{{reference}}').join('');
            }
            return output;
        },
        replaceLink(input, text, link) {
            const html = '<a href="' + link + '" target="_blank">' + text + '</a>';
            let output = input.split('{{link}}').join(html);
            return output;
        },
        getColor(key, dark) {
            for (let i in this.$root.config.colors) {
                if (this.$root.config.colors[i].key == key) {
                    if (dark) {
                        return this.$root.config.colors[i].darkValue;
                    }else {
                        return this.$root.config.colors[i].value;
                    }
                }
            }
            return '#000000';
        },
        getCountry(alpha2) {
            for (let i in this.$root.countries) {
                if (this.$root.countries[i].alpha2 == alpha2) {
                    return this.$root.countries[i];
                }
            }
            return null;
        },
        getCountryName(key) {
            for (let i in this.$root.countriesJson) {
                if (this.$root.countriesJson[i].key == key) {
                    if (this.$root.countriesJson[i][this.$root.locale.key]) {
                        return this.$root.countriesJson[i][this.$root.locale.key];
                    }else {
                        return this.$root.countriesJson[i].en_EN;
                    }
                }
            }
            return 'Unknown country';
        },
        async getEnvelope(envelopeId) {
            return await cache.getEnvelope(envelopeId);
        },
        getEmployeeName(userId) {
            return this.getEmployee(userId).name;
        },
        getEmployee(userId) {
            if (userId == 'auto') return { id: userId, department: 'System', name: 'systemet (automatisk)' };
            if (userId == 'sys') return { id: userId, department: 'System', name: 'System' };
            for (let i in this.$root.employees) {
                if (this.$root.employees[i].id == userId) return this.$root.employees[i];
                if (this.$root.employees[i].uid == userId) return this.$root.employees[i];
            }
            return { id: userId, department: 'Unknown', name: 'Unknown User' };
        },
        getDepartment(key) {
            for (const i in this.$root.departments) {
                if (this.$root.departments[i].key == key) return this.$root.departments[i];
            }
            return undefined;
        },
        getDepartmentName(key) {
            for (const i in this.$root.departments) {
                if (this.$root.departments[i].key == key) return this.$root.departments[i].name;
            }
            return 'Unknown department';
        },
        getPartner(key) {
            for (const i in this.$root.partners) {
                if (this.$root.partners[i].key == key) return this.$root.partners[i];
            }
            return undefined;
        },
        getPartnerName(key) {
            for (const i in this.$root.partners) {
                if (this.$root.partners[i].key == key) return this.$root.partners[i].firstName + ' ' + this.$root.partners[i].lastName;
            }
            return 'Unknown partner';
        },
        showTooltipX(options) {
            this.$root.tooltipOptions = options;
            this.$root.showTooltip = true;
        },
        hideTooltipX(options) {
            this.$root.showTooltip = false;
        },
        showHelpPopupX(options) {
            this.$root.showHelpOptions = options;
            this.$root.showHelpPopup = true;
        },
        async addMessage(data) {
            const message = {
                key: tools.getUniqueId(),
                text: data.text,
                icon: data.icon,
                duration: data.duration || 5000
            };
            this.$root.messages.push(message);
            if (message.duration != -1) {
                await new Promise(r => setTimeout(r, message.duration));
                this.$root.removeMessage(message.key);
            }
            return message.key;
        },
        async updateMessage(key, data) {
            for (const i in this.$root.messages) {
                if (this.$root.messages[i].key == key) {
                    this.$root.messages[i].text = data.text;
                    this.$root.messages[i].icon = data.icon;
                    this.$root.messages[i].duration = data.duration;
                    if (data.duration && data.duration != -1) {
                        await new Promise(r => setTimeout(r, data.duration));
                        this.$root.removeMessage(key);
                    }
                }
            }
        },
        removeMessage(key) {
            for (const i in this.$root.messages) {
                if (this.$root.messages[i].key == key) {
                    this.$root.messages.splice(i, 1);
                    break;
                }
            }
        },
        enableScrolling(enable) {
            if (!enable) {
                document.body.className += ' noscroll';
            }else {
                document.body.className = document.body.className.replace("noscroll","");
            }
            this.$root.scrollingEnabled = enable;
            //console.log('setting scroll to ' + this.$root.scrollingEnabled);
            //console.log(document.body.className);
        },
        enableAlwaysScroll(enable) {
            if (enable) {
                document.body.className += ' alwaysscroll';
            }else {
                document.body.className = document.body.className.replace("alwaysscroll","");
            }
        },
        getVersionText() {
            if (this.$root.config.portal.useV1) {
                return '1.5.' + this.$root.version + (this.$root.isDEV ? ' (DEV)' : '');
            }else if (this.$root.isAWS) {
                return '1.0.' + this.$root.version + (this.$root.isDEV ? ' (AWS DEV)' : ' (AWS)');
            }else {
                return '0.9.' + this.$root.version + (this.$root.isDEV ? ' (DEV)' : '');
            }
        },
        companyTitleCase(input) {
            try {
                let str = '';
                const parts = input.split(' ');
                for (let i=0; i<parts.length; i++) {
                    if (i > 0) str += ' ';
                    if (parts[i] == 'AS' || parts[i] == 'ASA' || parts[i] == 'ANS' || parts[i] == 'AB'
                    || parts[i] == 'II' || parts[i] == 'III' || parts[i] == 'VI' || parts[i] == 'VII' || parts[i] == 'VIII' || parts[i] == 'XI' || parts[i] == 'XII' || parts[i] == 'XIII') {
                        str += parts[i];
                    }else {
                        if (parts[i].includes('-') && parts[i].length > 2) {
                            let ar = parts[i].split('-');
                            for (const j in ar) {
                                if (j > 0) str += '-';
                                str += ar[j][0].toUpperCase() + ar[j].substr(1).toLowerCase();
                            }
                        }else if (parts[i].length > 1) {
                            str += parts[i][0].toUpperCase() + parts[i].substr(1).toLowerCase();
                        }else {
                            str += parts[i].toUpperCase();
                        }
                    }
                }
                return str;
            }catch (ex) {
                return input;
            }
        },
    }
})

const data = {
    version: 65,
    versionText: '0.9.65',
    baseUrl: 'http://localhost:8888',
    isAWS: false,
    isDEV: false,
    forceDEV: false,
    debug: {
        local: false,
        defaults: false,
        skipValidation: false,
        deloitte: false,
        ssn: false,
        personPopup: false,
        testComparison: false,
        testForm: false,
        news: true
    },
    darkMode: false,
    user: {
        givenName: '',
        familyName: '',
        email: '',
        companyName: '',
    },
    allowedActions: {
        changeSettings: false,
        deleteCustomer: false
    },
    status: {
        checked: false,
        degraded: false,
        headerKey: '',
        messageKey: ''
    },
    editor: false,
    editorLocales: [],
    locale: {},
    config: {},
    flowConfig: {
        flows: [],
        steps: []
    },
    riskParams: [],
    v1translations: {},
    translations: {},
    countries: [],
    countriesJson: [],
    countriesCommon: [],
    zipNumbersNO: {},
    naceCodes: {},
    employees: {},
    departments: [],
    partners: [],
    credentials: {},
    showHelpPopup: false,
    showHelpOptions: {},
    showTooltip: false,
    tooltipOptions: {},
    workingInBackground: false,
    messages: [],
    eventUpdateActive: false,
    eventLastUpdate: null,
    events: [],
    eventCache: [],
    eventPriority: 0,
    phoneMode: false,
    windowHeight: 1000,
    exceptions: [],
    v1exceptions: [],
    internetExplorer: false,
    clientListCache: {},
    formData: null,
    reportData: null,
    loadedFiles: [],
    scrollingEnabled: true,
    formClipboard: [],
    images: {
        vfblue: vfblue,
        vfwhite: vfwhite,
        bamltiny: bamltiny,
        trapetstiny: trapetstiny,
        flags: {
            en_EN: flag_EN,
            nb_NO: flag_NO,
            sv_SE: flag_SE,
            fi_FI: flag_FI,
            da_DK: flag_DK,
            de_DE: flag_DE,
            un_UN: flag_UN,
        },
        circleFlags: {
            en_EN: cflag_EN,
            nb_NO: cflag_NO,
            sv_SE: cflag_SE,
            fi_FI: cflag_FI,
            da_DK: cflag_DK,
            de_DE: cflag_DE,
        }
    },
}

new Vue({
    data: data,
    router,
    render: h => h(App),
}).$mount('#app')
