<template>
<div class="vfrepv1-risk">

    <VFRow v-if="!$root.config.freport.dualComments.enabled">
        <VFGroup c12>

            <VFLabel desc :text="__t('risk-classification')" /><br>
            <VFCheckbox inline radio
                v-model="riskModel"
                :options="riskOptions"
                :disabled="reportCreated || lockHighRisk"
                @input="onRiskChanged"
            />

            <div v-if="lockHighRisk" class="flw100" style="margin-top: 10px;">
                {{ '*Risikovurdering er automatisk satt til høy fordi det er treff på PEP eller sanksjon.' }}
            </div>

        </VFGroup>
    </VFRow>

    <VFRow v-if="$root.config.freport.dualComments.enabled">
        <VFGroup c12>

            <VFLabel desc :text="__t('risk-classification') + ($root.config.freport.dualComments.enabled ? ' - ' + $root.config.responsible.clientText[$root.locale.key] : '')" /><br>
            <VFCheckbox inline radio
                v-model="riskUser"
                :options="riskOptions"
                :disabled="reportCreated || lockHighRisk || isPartner"
                @input="onRiskChangedUser"
            />

            <div v-if="lockHighRisk" class="flw100" style="margin-top: 10px;">
                {{ '*Risikovurdering er automatisk satt til høy fordi det er treff på PEP eller sanksjon.' }}
            </div>

        </VFGroup>
    </VFRow>

    <VFRow v-if="$root.config.freport.dualComments.enabled">
        <VFGroup c12>

            <VFLabel desc :text="__t('risk-classification') + ($root.config.freport.dualComments.enabled ? ' - ' + $root.config.partners.clientText[$root.locale.key] : '')" /><br>
            <VFCheckbox inline radio
                v-model="riskModel"
                :options="riskOptions"
                :disabled="reportCreated || lockHighRisk || !isPartner"
                @input="onRiskChanged"
            />

            <div v-if="lockHighRisk" class="flw100" style="margin-top: 10px;">
                {{ '*Risikovurdering er automatisk satt til høy fordi det er treff på PEP eller sanksjon.' }}
            </div>

        </VFGroup>
    </VFRow>
    
    
</div>
</template>
<script>
import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'ReportRisk',
    components: {
        VFRow,
        VFGroup,
        VFLabel,
        VFCheckbox
    },
    props: {
        item: { type: Object, default: null },
        client: { type: Object, default: null },
        riskOptions: { type: Object, default: null },
        reportCreated: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        lockHighRisk: { type: Boolean, default: false },
        isPartner: { type: Boolean, default: false },
    },
    data() {
        return {
            riskModel: {},
            riskUser: {},
        }
    },
    methods: {
        
        onRiskChanged() {
            for (const key in this.riskModel) {
                if (this.riskModel[key]) this.client.report.risk = key;
            }
            this.$emit('changed');
            this.$emit('risk-changed');
        },

        onRiskChangedUser() {
            for (const key in this.riskUser) {
                if (this.riskUser[key]) {
                    this.client.report.riskUser = key;
                }
            }
            this.$emit('changed');
            this.$emit('risk-changed');
        },

    },
    created() {
        for (const key in this.riskOptions) {
            this.$set(this.riskModel, key, key == this.client.report.risk);
        }
        for (const key in this.riskOptions) {
            this.$set(this.riskUser, key, key == this.client.report.riskUser);
        }
    }
}
</script>
<style scoped>

.vfrepv1-risk {
    float: left;
    padding: 20px;
}

</style>