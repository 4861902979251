<template>
<div class="portal-container">
    <PortalHeader v-if="!editor && user && configLoaded && !appLoading && !testingPdf && !landing" @logout="logout" :basePath="basePath" />
    <EditorHeader v-if="editor && configLoaded && !appLoading && !landing" :title="headerTitle" />

    <div v-if="(user || editor || testingPdf) && configLoaded && !appLoading" v-show="!navigating2" :style="{ opacity: (loading || navigating) ? 0 : 1 }" style="transition: opacity 0.5s">
        <router-view />
    </div>

    <div v-if="loading" style="position: absolute; top: 60px; bottom: 0px; left: 0px; right: 0px;">
        <div style="position: fixed; top: 30%; left: 50%;">
            <vap-spinner :text="loadMessage" />
        </div>
    </div>

    <div v-if="!user && !loading && !editor && !selectCompany && !testingPdf && !noValidCompanies && !loginFromLink" style="position: fixed; top: 20%; left: 50%;">
        <img :src="loginLogo" style="width: 300px; margin-left: -150px; margin-bottom: 60px;" />
        <ve-login :working="isLoggingIn" @login="startLogin" :error="loginError" />
    </div>

    <div v-if="selectCompany" class="slim-page" style="margin-top: 200px;">
        <vap-card :header="'Velg selskap'" v-if="validCompanies.length > 0 && !loginHolder.mainSelected">
            <vap-item float v-for="(company, i) in validCompanies" :key="i" padding20 @clicked="selectMainCompany(company)">
                <div>
                    <vap-label :text="company.name" value />
                </div>
            </vap-item>
        </vap-card>
        <vap-card :header="'Velg DEMO selskap'" v-if="loginHolder.testinc && (validCompanies.length < 2 || loginHolder.mainSelected)">
            <vap-item float v-for="(company, i) in demoCompanies" :key="i" padding20 @clicked="selectDemoCompany(company)">
                <div>
                    <vap-label :text="company.name" value />
                </div>
            </vap-item>
        </vap-card>
    </div>

    <div v-if="noValidCompanies" class="slim-page" style="margin-top: 300px;">
        <vap-card>
            <div class="no-valid-comp">
                <label>AML-portalen er ikke aktivert for ditt selskap.</label>
                <label>Kontakt oss på support@verified.eu for mer informasjon om hvordan aktivere AML-portalen.</label>
            </div>
        </vap-card>
    </div>

    <div v-if="!testingPdf && ($route.path == '/' || $route.path == '/customer' || $route.path == '/customers' || $route.path == '/new' || $route.path == '/web/aml-portal/' || $route.path == '/v1events')" class="verified-footer">
        <div style="">
            <div style="">
                <label style="margin-top: 6px;">Powered by</label>
                <img :src="$root.images.vfblue" style="width: 60px; margin-left: 6px;" /><br>
            </div>
            <div>
                <label class="verified-footer-link" @click="infoClicked(1)">Status</label>
                <label class="verified-footer-space">-</label>
                <label>{{ 'version ' + getVersionText() }}</label>
            </div>
        </div>
    </div>

    <div v-if="$root.showTooltip" class="app-tooltip" :style="'width: ' + $root.tooltipOptions.width + 'px; bottom: ' + $root.tooltipOptions.bottom + 'px; left: ' + $root.tooltipOptions.left + 'px;'">
        <div class="app-tooltip-html" v-html="$root.tooltipOptions.text" />
        <div :class="[{'app-tooltip-arrow-up':$root.tooltipOptions.direction == 'up'},{'app-tooltip-arrow-down':$root.tooltipOptions.direction == 'down'}]" :style="'left: ' + $root.tooltipOptions.point + 'px;'" />
    </div>
    <div v-if="$root.showHelpPopup" class="app-help">
        <div class="app-help-bg" @click="$root.showHelpPopup = false" />
        <div class="app-help-c">
            <vap-icon :icon="'cancel'" absolute :top="10" :right="20" action @clicked="$root.showHelpPopup = false" />
            <div v-if="$root.showHelpOptions.htmlRaw" class="app-help-html" v-html="$root.showHelpOptions.htmlRaw" />
            <div v-else class="app-help-html" v-html="__($root.showHelpOptions.htmlKey)" />
        </div>
    </div>

    <div v-if="$root.messages.length > 0" class="aml-app-msgs">
        <div v-for="message in $root.messages" :key="message.key" class="aml-app-msg">
            <div class="aml-app-msg-close">
                <vap-icon :icon="'cancel'" small :color="'var(--header-text)'" action />
            </div>
            <div class="aml-app-msg-text">
                <span>{{ message.text }}</span>
            </div>
            <div class="aml-app-msg-icon">
                <vap-icon :icon="message.icon" :color="'var(--header-text)'" v-if="message.icon" absolute :top="2" :right="2" />
                <vap-spinner iconMode noIcon v-if="message.duration == -1" />
            </div>
        </div>
    </div>

    <ve-popup v-if="$root.exceptions.length > 0" :header="__('error_header')" :width="600" @close="closeExceptions" hideCloseButton>
        <div style="padding: 20px;" v-if="!errorReported">
            <label>{{ __('error_message') }}</label><br><br>
            <div class="app-exception" v-for="(ex, i) in $root.exceptions" :key="'ex' + i">
                <label>{{ ex.message || 'Unknown error' }}</label>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('error_reload')" uppercase @clicked="reloadApp" />
                <vap-button :text="__('error_submit')" uppercase @clicked="reportExceptions" :working="isReporting" />
            </vap-button-row>
        </div>
        <div style="padding: 20px;" v-if="errorReported">
            <label>{{ __('error_thanks') }}</label><br><br>
            <label>{{ __('error_close') }}</label><br><br>
        </div>
    </ve-popup>

    <DepartmentDialog v-if="showDepartmentDialog" @xclose="onDepartmentSet" />

    <ExceptionDialog v-if="$root.v1exceptions.length > 0" />

</div>
</template>

<script>
import { auth, envelopes, oidc } from '@verified/libvf';
import axios from 'axios';
import jwt from 'jsonwebtoken';
import verified from './verified';
import statusapi from './statusapi';
import v2lib from './v2lib';
import backend from './backend';
import api from './portalapi';
import v1api from './v1helpers/v1api';
import configTools from './config';
import locale from './locale';
import log from './log';
import formLocalData from './components/form/formLocalData';
import v1locale from './v1helpers/v1locale';
import v1messages from './v1helpers/v1messages';

import PortalHeader from './components/header/PortalHeader';
import EditorHeader from './components/header/EditorHeader';

import DepartmentDialog from './v1ui/DepartmentDialog.vue';
import ExceptionDialog from './v1ui/ExceptionDialog.vue';

export default {
    name: 'App',
    components: {
        PortalHeader,
        EditorHeader,
        DepartmentDialog,
        ExceptionDialog,
    },
    data() {
        return {
            testingPdf: false,
            busy: false,
            landing: false,
            appLoading: true,
            loading: true,
            navigating: false,
            navigating2: false,
            isLoggingIn: false,
            loadMessage: '...',
            configLoaded: false,
            user: undefined,
            editor: false,
            headerTitle: 'error',
            loginHolder: { // some temporary vars for handling demo-company logic
                authHolder: '',
                testincHolder: {},
                testinc: false,
                subincName: '',
                mainSelected: false,
                isAWS: false
            },
            loginFromLink: false,
            loginError: false,
            selectCompany: false,
            validCompanies: [],
            noValidCompanies: false,
            regularUser: false,
            authorizedCompanies: [],
            demoCompanies: [
                {
                    key: 'verified',
                    name: 'Verified'
                },
                {
                    key: 'sverige',
                    name: 'Verified Sverige'
                },
                {
                    key: 'vipps',
                    name: 'Vipps BankID'
                },
                {
                    key: 'snn',
                    name: 'Sparebank 1 Regnskapshuset Nord Norge',
                },
                {
                    key: 'dnb',
                    name: 'DNB Næringsmegling',
                },
                {
                    key: 'kpmg',
                    name: 'KPMG Norge',
                },
                {
                    key: 'codan',
                    name: 'Codan Forsikring',
                }
            ],
            exceptions: [],
            errorReported: false,
            filters: {
                users: [],
                statuses: [],
                risk: [],
                letters: []
            },
            loadedEnvelope: {},
            loadedRecipient: {},
            loadedError: {
                isError: false,
                reason: 0,
                message: ''
            },
            headerTitle: '',
            loginLogo: '',
            loginOptions: [
                { host: 'aml-portal-demo', color: '#238CCC', logo: 'verified-logo-blue.svg', fav: 'favicon.png' },
                { host: 'aml-portal-uac', color: '#9023CC', logo: 'https://aml-portal.web.verified.eu/logo/appconsult-logo-color.png', fav: 'favicon.png' },
                { host: 'aml-portal-pangea', color: '#247278', logo: 'https://aml-portal.web.verified.eu/logo/pangea-blue.png', fav: 'https://www.pangeapartners.se/wp-content/themes/pangea/img/favicon.ico' },
                { host: 'aml-portal-sands', color: '#40444B', logo: 'https://sands.no/media/1zmlwkof/sands-logo.svg', fav: 'https://sands.no/dist/favicon/favicon.ico' },
                { host: 'aml-portal-deloitte', color: '#000000', logo: 'https://images.squarespace-cdn.com/content/v1/5645c708e4b03d93696173a7/1590752666963-VISAXTR00TRM46I19MO6/ke17ZwdGBToddI8pDm48kMexXLMgD0uU1g7OHBIBX1UUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYwL8IeDg6_3B-BRuF4nNrNcQkVuAT7tdErd0wQFEGFSnGNeP_n-x27sSAUmanwM_KQDOSCUOeX077yamCDLl73zX8Bcc8vf2Ds_W9b1WQU71Q/Deloitte_logo_black.png', fav: 'https://www2.deloitte.com/content/dam/resources/deloitte/icons/favicon.ico' },
                { host: 'aml-portal-kvale', color: '#B58F51', logo: 'https://aml-portal.web.verified.eu/images/kvale-black.png', fav: 'https://www.kvale.no/wp-content/uploads/2020/01/cropped-K-01-32x32.png' },
            ],
            isReporting: false,
            basePath: '/',
            showDepartmentDialog: false
        }
    },
    methods: {

        setLoading(loading, message) {
            this.loading = loading;
            this.loadMessage = message || '';
        },
        navigate(route) {
            // TODO clean up this stuff
            this.navigating = true;
            this.navigating2 = true;
            route.path = route.path.replace('/', this.basePath);
            this.$router.push(route);
            setTimeout(() => {
                this.navigating = false;
                this.navigating2 = false;
			}, 500);
        },
        setTitle(title, hidePortal) {
            let pageTitle = 'AML Portal';
            if (this.$root.config.branding && this.$root.config.branding.pageTitle) pageTitle = this.$root.config.branding.pageTitle;
            document.title = title + (hidePortal ? '' : ' - ' + pageTitle);
        },
        closeExceptions() {
            this.$root.exceptions = [];
        },
        async reportExceptions() {
            this.isReporting = true;
            const error = {
                date: new Date(),
                message: this.$root.exceptions[0].message,
                stack: this.$root.exceptions[0].stack,
                user: this.$root.getEmployee(this.$root.credentials.uid),
                url: this.$route.fullPath,
                info: this.$root.exceptions[0].info,
                log: log.list
            };

            await backend.reportError(error);
            
            this.errorReported = true;
            this.isReporting = false;
        },
        infoClicked(index) {
            if (index == 0) {
                this.navigate({ path: '/about' });
            }else {
                window.open('https://status.verified.eu/', '_blank');
            }
        },
        onDepartmentSet() {
            this.showDepartmentDialog = false;
            location.reload();
        },
        loadColors() {
            for (let i in this.$root.config.colors) {
                if (!this.$root.darkMode) {
                    document.documentElement.style.setProperty('--' + this.$root.config.colors[i].key, this.$root.config.colors[i].value);
                }else {
                    document.documentElement.style.setProperty('--' + this.$root.config.colors[i].key, this.$root.config.colors[i].darkValue);
                }
            }

            const useDarkBuilder = true;
            const fbColors = [
                { key: 'fb-header', light: '238CCC', dark: '114666' },
                { key: 'fb-accent', light: '238CCC', dark: '238CCC' },
                { key: 'fb-logic', light: '13AA52', dark: '13AA52' },

                { key: 'fb-menu-a', light: 'FFFFFF', dark: '114666' },
                { key: 'fb-menu-b', light: 'F4F4F4', dark: '0E3852' },
                { key: 'fb-menu-c', light: 'EBEBEB', dark: '0B2D42' },

                { key: 'fb-scroll-b', light: 'EBEBEB', dark: '0B2D42' },
                { key: 'fb-scroll-f', light: 'ADADAD', dark: '6E7B82' },

                { key: 'fb-text-1', light: '000000', dark: 'FFFFFF' },
                { key: 'fb-text-2', light: 'E2E9EC', dark: 'E2E9EC' },
                { key: 'fb-text-3', light: 'B7C7D1', dark: 'B7C7D1' },
                { key: 'fb-text-4', light: '7090A3', dark: '7090A3' },
                { key: 'fb-text-5', light: '416B85', dark: '416B85' },

                { key: 'fb-input-bg', light: 'FFFFFF', dark: '0B2D42' },
                { key: 'fb-input-ph', light: 'FFFFFF', dark: '424242' },
                { key: 'fb-input-txt', light: 'FFFFFF', dark: 'FFFFFF' },

                { key: 'fb-border', light: '', dark: 'FFFFFF' },
            ];
            for (const i in fbColors) {
                if (useDarkBuilder) {
                    document.documentElement.style.setProperty('--' + fbColors[i].key, '#' + fbColors[i].dark);
                }else {
                    document.documentElement.style.setProperty('--' + fbColors[i].key, '#' + fbColors[i].light);
                }
            }
        },
        startLogin(values) {
            this.isLoggingIn = true;
            this.loginHolder.authHolder = values.base64;
            //console.log('values', values);
            this.authenticate(values);
        },
        setAppUser() {
            this.$root.user.givenName = this.user.givenName;
            this.$root.user.familyName = this.user.familyName;
            this.$root.user.email = this.user.email;
            this.$root.user.companyName = this.user.company;
        },
        async authenticateAWS() {
            this.loginFromLink = true;
            this.busy = true;
            this.isLoggingIn = true;
            this.loginHolder.testinc = false;
            this.loginHolder.isAWS = true;
            this.$root.isAWS = true;
            this.loginError = false;
			try {
                let language = '';
                let companyId = '';
                if (window.location.host.includes('localhost')) {
                    const hash = this.$route.hash;
                    const part1 = hash.replace('#/?access_token=', '');
                    const part2 = part1.split('&companyId=');
                    this.token = part2[0];
                    const part3 = part2[1].split('&lang=');
                    companyId = part3[0];
                    language = part3[1];
                }else {
                    const hash = this.$route.fullPath;
                    if (hash.includes('&companyId=')) {
                        const part1 = hash.replace('/?access_token=', '');
                        const part2 = part1.split('&companyId=');
                        this.token = part2[0];
                        const part3 = part2[1].split('&lang=');
                        companyId = part3[0];
                        language = part3[1];
                    }else {
                        this.token = this.$route.path.replace('/access_token=', '');
                    }
                }

/*                 console.log('token', this.token);
                console.log('language', language);
                console.log('companyId', companyId); */

                if (language) {
                    this.$root.locale = locale.getLocale(this.$root.config.locales, language);
                }

                auth.token = this.token;
                // Get a list of all the user companies that are valid for the AML portal
                const companies = await auth.getCompanies();
                const list = [];
                for (const i in companies) list.push(companies[i].id);
                const ares = await backend.getValidCompanies(list);
                this.authorizedCompanies = ares.data.companies;
                // Merge with company-info from Verified and add to list
                for (const i in this.authorizedCompanies) {
                    for (const j in companies) {
                        if (this.authorizedCompanies[i].companyId == companies[j].id) {
                            this.authorizedCompanies[i].vinfo = companies[j];
                        }
                    }
                     // Test Inc. can have several demo-companies for presentation purposes
                    if (this.authorizedCompanies[i].companyId.includes('r1MZtfWW7')) {
                        // Temporary store Test Inc. company info
                        this.loginHolder.testincHolder = this.authorizedCompanies[i];
                        this.loginHolder.testinc = true;
                    }
                    this.validCompanies.push(this.authorizedCompanies[i]);
                }
                // Show a list of companies or auto-login if only one company
				if (this.validCompanies.length > 1 || this.loginHolder.testinc) {
                    // Select company || demo-company
                    if (companyId) {
                        let match = false;
                        for (const i in this.validCompanies) {
                            if (this.validCompanies[i].companyId == companyId) {
                                this.company = this.validCompanies[i];
                                match = true;
                            }
                        }
                        if (match) {
                            this.continueAWS(null, language);
                        }else {
                            this.noValidCompanies = true;
                        }
                    }else {
                        this.selectCompany = true;
                        this.busy = false;
                    }
                }else if (this.validCompanies.length == 0) {
                    this.noValidCompanies = true;
				}else {
                    this.company = this.validCompanies[0];
					this.continueAWS(null, language);
				}
			}catch(e) {
                console.log(e);
				this.exceptions.push(new Error("An error occured during call to authenticate"));
				this.busy = false;
                this.noMatch = true;
                this.loginError = true;
                this.isLoggingIn = false;
			}
        },
        async continueAWS(company, language) {
            this.loading = true;
            this.selectCompany = false;
			if (company) {
				this.company = company;
				this.busy = true;
			}
			try {
				await auth.setToken(this.token);
                if (window.location.host.includes('localhost')) {
                    log.e('AWS auth wont work on localhost');
                    console.log('provided company', company);
                    console.log('selected company', this.company);
                    return;
                }
                let response;
                response = await backend.authorizeAWS(this.token, this.company.companyId, this.company.key);
                //console.log('AWS response', response);
                //this.$root.credentials = response.data;
                this.$root.credentials.key = response.data.payload.key;
                this.$root.credentials.cid = response.data.payload.cid;
                this.$root.credentials.uid = response.data.payload.uid;
                this.$root.credentials.role = response.data.payload.role;
                this.$root.credentials.token = response.data.token;
                if (this.loginHolder.testinc) {
                    this.$root.credentials.testinc = this.loginHolder.subincName.key;
                    log.v('Using test inc: ' + this.$root.credentials.testinc);
                }
                backend.credentials = this.$root.credentials;
                api.credentials = this.$root.credentials;
                v1api.credentials = this.$root.credentials;
                //console.log('bc', backend.credentials);
                localStorage.setItem('backend', JSON.stringify(this.$root.credentials));
                log.s('backend user authorized: ' + this.$root.credentials.uid);

                const user = await auth.getUserinfo();
                //console.log('user', user);

                if (this.$root.config.portal.deletePermission == 'admin') {
                    if (backend.credentials.role == 'admin') this.$root.allowedActions.deleteCustomer = true;
                }
                
                let webAppUrl = 'https://app.verified.eu/#';
                if (this.$root.isDEV) webAppUrl = 'https://dev-verified.eu/#';
                webAppUrl = '#';
				this.user = {
                    isAWS: true,
                    version: this.$root.version,
					givenName: user.givenName,
					familyName: user.familyName,
					email: user.email,
					token: this.token,
					company: this.company.name,
					namespace: "/companies/" + this.company.companyId,
					dashboardLink: `/${webAppUrl}/archive?companyId=${this.company.companyId}`
                };
                //console.log('user', this.user);
                this.setAppUser();
				localStorage.setItem('user', JSON.stringify(this.user));
                localStorage.setItem('token', this.token);

                await api.addUser({
                    uid: this.$root.credentials.uid,
                    givenName: user.givenName,
                    familyName: user.familyName,
                });

				await auth.authenticate({
					token: auth.token,
					namespace: this.user.namespace
                });
                v2lib.namespace = this.user.namespace;
                v2lib.personalToken = this.token;
                v1api.vToken = this.token;
                
                // Load backend-config and finish up app loading
                await this.loadConfig();

                // Set the initial portal language after loading configuration
                if (language) {
                    this.$root.locale = locale.getLocale(this.$root.config.locales, language);
                }

                await this.finishLoading();
                this.loading = false;
                this.busy = false;
			}catch(e) {
				console.log(e);
				this.exceptions.push(new Error("An error occured during call to authenticate"));
				this.busy = false;
			}
        },
        async authenticateFromLink() {
            this.loginFromLink = true;
			this.busy = true;
            this.isLoggingIn = true;
            this.loginHolder.testinc = false;
            this.loginError = false;
			try {
                this.setLoading(true, 'Logger inn i portalen...');
                const companyId = 'rkeUXcLuv';
                this.token = this.$route.hash.replace('#access_token=', '');
                await auth.authenticate({
					token: this.token,
					namespace: '/companies/' + companyId
                });
                v2lib.personalToken = this.token;
                v1api.vToken = this.token;
                v2lib.namespace = '/companies/' + companyId;

                const envelopeId = 'S1y8MgWMu';
                const envelope = await this.$root.getEnvelope(envelopeId);
                //console.log('envelope', envelope);

                const decoded = jwt.decode(this.token);
                //console.log(decoded);

                const company = {
                    companyId: companyId,
                    namespace: '/companies/' + companyId,
                    key: 'deloitte',
                    name: 'Deloitte',
                    user: {
                        uid: decoded.sub.uid,
                        dep: 'sso',
                        givenName: decoded.sub.givenName,
                        familyName: decoded.sub.familyName,
                        email: decoded.sub.email,
                        companyName: 'Deloitte',
                        publicToken: envelope.userdata.publicToken
                    }
                };

                //console.log('company', company);
				this.continueAuthenticationFromLink(company);

			}catch(e) {
                console.log(e);
				this.exceptions.push(new Error("An error occured during call to authenticate"));
				this.busy = false;
                this.noMatch = true;
                this.loginError = true;
                this.isLoggingIn = false;
			}
		},
        async continueAuthenticationFromLink(company) {
            this.loading = true;
            this.selectCompany = false;
			if (company) {
				this.company = company;
				this.busy = true;
			}
			try {
				await auth.setToken(this.token);
                let response = await backend.authorizeFromLink(this.token, company);
                this.$root.credentials = response.data;
                this.$root.credentials.key = this.company.key;
                backend.credentials = this.$root.credentials;
                api.credentials = this.$root.credentials;
                v1api.credentials = this.$root.credentials;
                //console.log(backend.credentials);
                localStorage.setItem('backend', JSON.stringify(this.$root.credentials));
                log.s('backend user authorized: ' + this.$root.credentials.uid);

				this.user = {
                    version: this.$root.version,
					givenName: company.user.givenName,
					familyName: company.user.familyName,
					email: company.user.email,
					token: this.token,
					company: this.company.name,
					namespace: "/companies/" + this.company.companyId,
					dashboardLink: "https://app.verified.eu/#/dashboard?companyId=" + this.company.companyId
                };
                //console.log('user', this.user);
                this.setAppUser();
				localStorage.setItem('user', JSON.stringify(this.user));
                localStorage.setItem('token', this.token);

                await api.addUser({
                    uid: company.user.uid,
                    givenName: company.user.givenName,
                    familyName: company.user.familyName,
                });

				await auth.authenticate({
					token: auth.token,
					namespace: this.user.namespace
                });
                v2lib.namespace = this.user.namespace;
                v2lib.personalToken = this.token;
                v1api.vToken = this.token;
                
                // Load backend-config and finish up app loading
                await this.loadConfig();
                await this.finishLoading();
                this.loading = false;
                this.busy = false;
			}catch(e) {
				console.log(e);
				this.exceptions.push(new Error("An error occured during call to authenticate"));
				this.busy = false;
			}
        },
        async authenticate(values) {
			this.busy = true;
            this.isLoggingIn = true;
            this.loginHolder.testinc = false;
            this.loginError = false;
			try {
                const loginResult = await verified.login(values);
                //console.log('result', loginResult);
                this.token = loginResult.token;
                await auth.setToken(this.token);
                

                // Get a list of all the user companies that are valid for the AML portal
                const companies = await auth.getCompanies();
                const list = [];
                for (const i in companies) list.push(companies[i].id);
                const ares = await backend.getValidCompanies(list);
                this.authorizedCompanies = ares.data.companies;
                // Merge with company-info from Verified and add to list
                for (const i in this.authorizedCompanies) {
                    for (const j in companies) {
                        if (this.authorizedCompanies[i].companyId == companies[j].id) {
                            this.authorizedCompanies[i].vinfo = companies[j];
                        }
                    }
                     // Test Inc. can have several demo-companies for presentation purposes
                    if (this.authorizedCompanies[i].companyId.includes('r1MZtfWW7')) {
                        // Temporary store Test Inc. company info
                        this.loginHolder.testincHolder = this.authorizedCompanies[i];
                        this.loginHolder.testinc = true;
                    }
                    this.validCompanies.push(this.authorizedCompanies[i]);
                }
                // Show a list of companies or auto-login if only one company
				if (this.validCompanies.length > 1 || this.loginHolder.testinc) {
                    // Select company || demo-company
                    this.selectCompany = true;
                    this.busy = false;
                }else if (this.validCompanies.length == 0) {
                    this.noValidCompanies = true;
				}else {
                    this.company = this.validCompanies[0];
					this.continueAuthentication();
				}
			}catch(e) {
                console.log(e);
				this.exceptions.push(new Error("An error occured during call to authenticate"));
				this.busy = false;
                this.noMatch = true;
                this.loginError = true;
                this.isLoggingIn = false;
			}
		},
		async continueAuthentication(company) {
            this.loading = true;
            this.selectCompany = false;
			if (company) {
				this.company = company;
				this.busy = true;
			}
			try {
				await auth.setToken(this.token);
                let response;
                if (this.loginFromLink) {
                    response = await backend.authorizeFromLink(this.token, company);
                }else {
                    response = await backend.authorizeMongo(this.loginHolder.authHolder, this.company.companyId);
                }
                this.$root.credentials = response.data;
                this.$root.credentials.key = this.company.key;
                if (this.loginHolder.testinc) {
                    this.$root.credentials.testinc = this.loginHolder.subincName.key;
                    log.v('Using test inc: ' + this.$root.credentials.testinc);
                }
                backend.credentials = this.$root.credentials;
                api.credentials = this.$root.credentials;
                v1api.credentials = this.$root.credentials;
                //console.log(backend.credentials);
                localStorage.setItem('backend', JSON.stringify(this.$root.credentials));
                log.s('backend user authorized: ' + this.$root.credentials.uid);

                const user = await auth.getUserinfo();
                //console.log('user', user);

                if (this.$root.config.portal.deletePermission == 'admin') {
                    if (backend.credentials.role == 'admin') this.$root.allowedActions.deleteCustomer = true;
                }
                
				this.user = {
                    version: this.$root.version,
					givenName: user.givenName,
					familyName: user.familyName,
					email: user.email,
					token: this.token,
					company: this.company.name,
					namespace: "/companies/" + this.company.companyId,
					dashboardLink: "https://app.verified.eu/#/dashboard?companyId=" + this.company.companyId
                };
                //console.log('user', this.user);
                this.setAppUser();
                localStorage.setItem('user', JSON.stringify(this.user));
                localStorage.setItem('token', this.token);

                await api.addUser({
                    uid: this.$root.credentials.uid,
                    givenName: user.givenName,
                    familyName: user.familyName,
                });

				await auth.authenticate({
					token: auth.token,
					namespace: this.user.namespace
                });
                v2lib.namespace = this.user.namespace;
                v2lib.personalToken = this.token;
                v1api.vToken = this.token;
                
                // Load backend-config and finish up app loading
                await this.loadConfig();
                await this.finishLoading();
                this.loading = false;
                this.busy = false;
			}catch(e) {
				console.log(e);
				this.exceptions.push(new Error("An error occured during call to authenticate"));
				this.busy = false;
			}
        },
        selectMainCompany(company) {
            this.loginHolder.mainSelected = true;
            if (this.loginHolder.isAWS) {
                if (!company.companyId.includes('r1MZtfWW7')) this.continueAWS(company)
            }else {
                if (!company.companyId.includes('r1MZtfWW7')) this.continueAuthentication(company)
            }
        },
        selectDemoCompany(company) {
            // Temporary store the demo-company for use in continueAuthentication
            this.loginHolder.subincName = company;
            if (this.loginHolder.isAWS) {
                this.continueAWS(this.loginHolder.testincHolder);
            }else {
                this.continueAuthentication(this.loginHolder.testincHolder);
            }
        },
        logout() {
            log.w('Logging out');
            this.invalidateConfigCache();
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('backend');
            if (this.$route.path != '/') this.$router.push({path: '/' });
			location.reload();
        },
        reloadApp() {
            this.invalidateConfigCache();
            location.reload();
        },
        invalidateConfigCache() {
            localStorage.setItem('pcd', new Date('1970-01-01'));
            localStorage.setItem('emd', new Date('1970-01-01'));
        },
		onResize() {
            this.$root.windowHeight = window.innerHeight;
			if (window.innerWidth < 800 && !this.$root.phoneMode) {
				this.$root.phoneMode = true;
				log.v('Switched to phone mode');
			}else if (window.innerWidth >= 800 && this.$root.phoneMode) {
				this.$root.phoneMode = false;
				log.v('Switched to desktop mode');
			}
        },
        hasRecentLocalStorage(key, minuteLimit) {
            if (localStorage.getItem(key)) {
                const date = new Date(localStorage.getItem(key));
                return (new Date() - date) < (Number(minuteLimit) * 1000 * 60);
            }
            return false;
        },
        async loadConfig() {
            // Load config from backend
            if (this.user || this.editor) {
                let customConfig;
                if (this.hasRecentLocalStorage('pcd', 10) && !this.editor && true == false) {
                    log.w('Using cached config to save some time');
                    customConfig = JSON.parse(localStorage.getItem('pcx'));
                    try {
                        if (localStorage.getItem('dcx')) this.$root.departments = JSON.parse(localStorage.getItem('dcx'));
                        if (localStorage.getItem('dcp')) this.$root.partners = JSON.parse(localStorage.getItem('dcp'));
                    }catch (ex) {

                    }
                }else {
                    const response = await api.loadConfig();
                    if (!response.config) {
                        // No config has been created for this company/demo-company
                        log.e('Using default config');
                    }else {
                        log.s('Config loaded from backend');
                        customConfig = response.config;
                        this.$root.departments = response.departments ? response.departments.list : [];
                        this.$root.partners = response.partners ? response.partners.list : [];
                        this.$root.flowConfig = response.flows || { flows: [], steps: [] };
                        this.$root.riskParams = response.riskParams ? response.riskParams.flows || [] : [];
                        this.$root.monitors = response.monitors || {};
                        localStorage.setItem('pcd', new Date());
                        localStorage.setItem('pcx', JSON.stringify(customConfig));
                        localStorage.setItem('dcx', JSON.stringify(this.$root.departments));
                        localStorage.setItem('dcp', JSON.stringify(this.$root.partners));
                    }
                }
                // Merge backend-config with default config - backend-config may be missing newly implemented variables
                configTools.mergeConfig(this.$root.config, customConfig);
            }
            if (this.user && this.$root.config.params.dashboardLink && this.$root.config.params.dashboardLink != 'default') {
                this.user.dashboardLink = this.$root.config.params.dashboardLink;
            }

/*             const tres = await axios.get('test_data/csvjson.json');
            const dep = [];
            for (const i in tres.data) {
                dep.push({
                    key: tres.data[i]['ID'],
                    name: tres.data[i]['Branch'].substring(0, tres.data[i]['Branch'].length - 1),
                    email: tres.data[i]['Email Address'],
                });
                
            }
            console.log('dep', dep);

            await api.setDepartments(dep, null); */

            if (!this.editor && this.$root.config.risk.autoEnabled) {
                const res = await axios.get('risk/nace.json');
                this.$root.naceCodes = res.data;
                //console.log('nace', this.$root.naceCodes);
            }

/*             this.$root.config.portal.useV1 = true;
            this.$root.config.portal.migrate = true; */

            // Force Netlify to use 1.0
            if (this.$root.baseUrl.includes('web.verified.eu')) {
                this.$root.config.portal.useV1 = false;
            }

            this.configLoaded = true;
            this.loadColors();
        },
        async finishLoading() {
            this.loading = true;

            // Get employees for current company to show their names on actions etc.
            // Also used to make sure the user is authenticated when app loads
            if (this.hasRecentLocalStorage('emd', 10) && true == false) {
                log.w('Using cached employees to save some time');
                this.$root.employees = JSON.parse(localStorage.getItem('emx'));
            }else {
                try {
                    this.$root.employees = await verified.getEmployees(this.$root.credentials.cid);
                    const response = await api.getTeam();
                    for (const i in response.users) {
                        this.$root.employees.push({
                            id: response.users[i].uid,
                            uid: response.users[i].uid,
                            department: 'SSO',
                            name: response.users[i].givenName + ' ' + response.users[i].familyName
                        });
                        if (response.users[i].uid == this.$root.credentials.uid) {
                            if (response.users[i].isAdmin) this.$root.credentials.isAdmin = true;
                            if (response.users[i].isSuper) this.$root.credentials.isSuper = true;
                            if (response.users[i].branch) this.$root.credentials.branch = response.users[i].branch;
                        }
                    }

                    if (this.$root.config.params.hasDepartments) {
                        if (!this.$root.credentials.branch) {
                            this.showDepartmentDialog = true;
                        }else {
                            for (const i in this.$root.departments) {
                                if (this.$root.departments[i].key == this.$root.credentials.branch) {
                                    if (this.$root.departments[i].masterBranch) {
                                        this.$root.credentials.isMasterBranch = true;
                                    }
                                }
                            }
                        }
                    }

                    localStorage.setItem('emd', new Date());
                    localStorage.setItem('emx', JSON.stringify(this.$root.employees));
                    log.s('Employees loaded from API and token verified');
                }catch (ex) {
                    log.e('Failed to load employees from API');
                    this.logout();
                    return;
                }
            }

            this.loading = false;
            // When appLoading is set to false the router-view will load the current view
            this.appLoading = false;
            if (this.loginFromLink) this.navigate({ path: '/' });
        },
        async setEditorAndLoadEnvelope() {
            this.editor = true;
            log.editor = true;
            if (window.location.host.includes('dev-verified') || window.location.host.includes('app.verified')) {
                this.$root.isAWS = true;
            }

            // Load userdata from envelope
            let envelopeId = '';
            let recipientId = '';
            let savedToken = undefined;
            if (this.$route.query.document_uid) {
                envelopeId = this.$route.query.document_uid.split('/envelopes/')[1].split('/documents/')[0];

                if (this.$route.path == '/id-kontroll' && this.$route.query.a_t && this.$route.query.a_t == 'undefined') {
                    if (sessionStorage.getItem('vapoidc')) {
                        const data = JSON.parse(sessionStorage.getItem('vapoidc'));
                        //console.log('dd', data);
                        envelopeId = data.documentUid.split('/envelopes/')[1].split('/documents/')[0];
                        savedToken = data.token;
                        //console.log('vapoidc loaded', data);
                    }else {
                        //console.log('vapoidc NOT loaded');
                    }
                }



            }else if (this.$route.query.uid) {
                envelopeId = this.$route.query.uid.split('/envelopes/')[1].split('/recipients/')[0];
                recipientId = this.$route.query.uid.split('/recipients/')[1];
            }else if (this.$route.path == '/oidc') {
                
                if (sessionStorage.getItem('vapoidc')) {
                    const data = JSON.parse(sessionStorage.getItem('vapoidc'));
                    //console.log('dd', data);
                    envelopeId = data.documentUid.split('/envelopes/')[1].split('/documents/')[0];
                    savedToken = data.token;
                    //console.log('vapoidc loaded');
                }else {
                    //console.log('vapoidc NOT loaded');
                }

            }else {
                envelopeId = this.$route.params.envelopeId;
            }

            // Authenticate with bearer token
            if (this.$route.query.access_token || (this.$route.query.a_t && this.$route.query.a_t != 'undefined') || this.$route.query.act || savedToken) {
                // KYC will have access_token and id-control will have a_t, KYC landing will have act
                auth.setToken(this.$route.query.access_token || this.$route.query.a_t || this.$route.query.act || savedToken);
                v2lib.editorToken = this.$route.query.access_token || this.$route.query.a_t || this.$route.query.act || savedToken;
            }else if (sessionStorage.getItem(envelopeId)) {
                // Recipient portal - load token from sessionStorage
                //console.log('local');
                //auth.setToken(sessionStorage.getItem(envelopeId));
                let redirect = this.$root.baseUrl + '/kycLanding/' + recipientId + '/' + envelopeId + '?act=' + sessionStorage.getItem(envelopeId);
                //console.log('redirect', redirect);
                window.location.href = redirect;
                return;
            }
            
            try {
                //console.log('get e', envelopeId);
                this.loadedEnvelope = await v2lib.getEditorEnvelope(envelopeId);
            }catch (ex) {
                //console.log('error', ex);
                this.loadedError.isError = true;
                this.loadedError.reason = 1;
                this.loadedError.message = 'Outdated client';

                this.appLoading = false;
                return;
            }
            

            if (recipientId) {
                for (const i in this.loadedEnvelope.content.recipients) {
                    if (this.loadedEnvelope.content.recipients[i].id == recipientId) {
                        this.loadedRecipient = this.loadedEnvelope.content.recipients[i];
                    }
                }
            }

            // Authenticate backend with bearer token
            this.$root.credentials.id = this.loadedEnvelope.userdata.credentials.id;
            this.$root.credentials.cid = this.loadedEnvelope.userdata.credentials.cid;
            this.$root.credentials.key = this.loadedEnvelope.userdata.credentials.key || 'default';
            this.$root.credentials.bearerToken = this.loadedEnvelope.userdata.credentials.bearerToken;
            if (this.loadedEnvelope.userdata.credentials.testinc) {
                // Check if there is a demo company to load config from
                this.$root.credentials.testinc = this.loadedEnvelope.userdata.credentials.testinc;
                log.v('Using testinc: ' + this.$root.credentials.testinc );
            }
            backend.credentials = this.$root.credentials;
            api.credentials = this.$root.credentials;
            v1api.credentials = this.$root.credentials;
            v2lib.namespace = '/companies/' + this.$root.credentials.cid;
            log.s('Backend authorized with bearer token');

            // When appLoading is set to false the router-view will load the current view
            this.appLoading = false;
        },
        async setEditorAndLoadEnvelope2() {
            this.editor = true;
            log.editor = true;
            if (window.location.host.includes('dev-verified') || window.location.host.includes('app.verified')) {
                this.$root.isAWS = true;
            }
            if (window.location.host.includes('dev-verified')) {
                this.$root.isDEV = true;
                log.editor = false;
            }

            let envelopeId = this.$route.params.envelopeId;
            let recipientId = this.$route.params.recipientId;
            const isLocal = formLocalData.isLocalId(envelopeId);

            // Authenticate with bearer token
            if (!isLocal) {
                if (this.$route.query.access_token) {

                    auth.setToken(this.$route.query.access_token);
                    v2lib.editorToken = this.$route.query.access_token;

                }else if (sessionStorage.getItem(envelopeId)) {
                    
                    const sessionToken = sessionStorage.getItem(envelopeId);
                    auth.setToken(sessionToken);
                    v2lib.editorToken = sessionToken;

                }else if (sessionStorage.getItem('vapoidc')) {
                    const data = JSON.parse(sessionStorage.getItem('vapoidc'));
                    //console.log('dd', data);
                    envelopeId = data.envelopeId;
                    recipientId = data.recipientId;
                    const sessionToken = data.token;
                    auth.setToken(sessionToken);
                    v2lib.editorToken = sessionToken;
                    //console.log('vapoidc loaded');
                }
            }
            
            // Load envelope
            if (isLocal) {
                this.loadedEnvelope = formLocalData.getEnvelope(envelopeId);
                log.w('Loaded local envelope');
            }else {
                this.loadedEnvelope = await v2lib.getEditorEnvelope(envelopeId);
            }

            if (recipientId) {
                for (const i in this.loadedEnvelope.content.recipients) {
                    if (this.loadedEnvelope.content.recipients[i].id == recipientId) {
                        this.loadedRecipient = this.loadedEnvelope.content.recipients[i];
                    }
                }
            }

            // Authenticate backend with bearer token
            this.$root.credentials.id = this.loadedEnvelope.userdata.credentials.id;
            this.$root.credentials.cid = this.loadedEnvelope.userdata.credentials.cid;
            this.$root.credentials.key = this.loadedEnvelope.userdata.credentials.key || 'default';
            this.$root.credentials.bearerToken = this.loadedEnvelope.userdata.credentials.bearerToken;
            if (this.loadedEnvelope.userdata.credentials.testinc) {
                // Check if there is a demo company to load config from
                this.$root.credentials.testinc = this.loadedEnvelope.userdata.credentials.testinc;
                log.v('Using testinc: ' + this.$root.credentials.testinc);
            }
            backend.credentials = this.$root.credentials;
            api.credentials = this.$root.credentials;
            v1api.credentials = this.$root.credentials;
            v2lib.namespace = '/companies/' + this.$root.credentials.cid;
            log.s('Backend authorized with bearer token');

            // When appLoading is set to false the router-view will load the current view
            this.appLoading = false;
        },
        async setEditorAndLoadEnvelope3() {
            this.editor = true;
            //log.editor = true;
            if (window.location.host.includes('dev-verified') || window.location.host.includes('app.verified')) {
                this.$root.isAWS = true;
            }

            // Load userdata from envelope
            let envelopeId = '';

            if (sessionStorage.getItem('vapoidc')) {
                const data = JSON.parse(sessionStorage.getItem('vapoidc'));
                //console.log('dd', data);
                envelopeId = data.documentUid.split('/envelopes/')[1].split('/documents/')[0];
                const sessionToken = data.token;
                auth.setToken(sessionToken);
                v2lib.editorToken = sessionToken;
                //console.log('vapoidc loaded');
            }else {
                //console.log('vapoidc NOT loaded');
            }

            try {
                //console.log('get e', envelopeId);
                this.loadedEnvelope = await v2lib.getEditorEnvelope(envelopeId);
            }catch (ex) {
                console.log('error', ex);
                this.loadedError.isError = true;
                this.loadedError.reason = 1;
                this.loadedError.message = 'Outdated client';
                this.appLoading = false;
                return;
            }
            
            // Authenticate backend with bearer token
            this.$root.credentials.id = this.loadedEnvelope.userdata.credentials.id;
            this.$root.credentials.cid = this.loadedEnvelope.userdata.credentials.cid;
            this.$root.credentials.key = this.loadedEnvelope.userdata.credentials.key || 'default';
            this.$root.credentials.bearerToken = this.loadedEnvelope.userdata.credentials.bearerToken;
            if (this.loadedEnvelope.userdata.credentials.testinc) {
                // Check if there is a demo company to load config from
                this.$root.credentials.testinc = this.loadedEnvelope.userdata.credentials.testinc;
                log.v('Using testinc: ' + this.$root.credentials.testinc );
            }
            backend.credentials = this.$root.credentials;
            api.credentials = this.$root.credentials;
            v1api.credentials = this.$root.credentials;
            v2lib.namespace = '/companies/' + this.$root.credentials.cid;
            log.s('Backend authorized with bearer token');

            // When appLoading is set to false the router-view will load the current view
            this.appLoading = false;
        },
        async reloadEnvelope() {
            const recipientId = this.loadedRecipient.id;
            this.loadedEnvelope = await v2lib.getEditorEnvelope(this.loadedEnvelope.id);
            for (const i in this.loadedEnvelope.content.recipients) {
                if (this.loadedEnvelope.content.recipients[i].id == recipientId) {
                    this.loadedRecipient = this.loadedEnvelope.content.recipients[i];
                }
            }
        },
        async getStatus() {
            this.$root.status = await statusapi.getStatus();
        },
    },
    async created() {
        log.v('App version ' + this.$root.version + ' started in desktop mode');
        /* log.x('App route:', this.$route); */
        /* if (!window.location.host.includes('localhost')) this.getStatus(); */
        
        this.basePath = '/';
        if (this.$root.forceDEV) {
            backend.isDEV = true;
            v1api.isDEV = true;
            this.$root.isDEV = true;
        }

        // Paths
        backend.functionUrl = '/.netlify/functions/backend';
        backend.baseUrl = 'https://aml-portal.web.verified.eu/';
        api.functionUrl = '/.netlify/functions/portal';
        api.baseUrl = 'https://aml-portal.web.verified.eu/';
        if (process.env.VUE_APP_BASE_URL && process.env.VUE_APP_API_BASE_URL) {
            backend.functionUrl = process.env.VUE_APP_API_BASE_URL + '/backend';
            backend.baseUrl = process.env.VUE_APP_BASE_URL;
            api.functionUrl = process.env.VUE_APP_API_BASE_URL + '/portal';
            api.baseUrl = process.env.VUE_APP_BASE_URL;
        }

        if (window.document.documentMode) this.$root.internetExplorer = true;
        let showLogin = false;
        this.onResize();
        this.$nextTick(() => {
			window.addEventListener('resize', this.onResize);
        });

        if (this.$route.path == '/pdftest' || this.$route.path == '/view') {
            log.e('Testing PDF');
            this.testingPdf = true;
        }

        // Set some inital values based on the hostname
        // Set primary color immediately to avoid black loading spinner 
        if (!window.location.host.includes('localhost')) {
            this.$root.baseUrl = 'https://' + window.location.host;
            api.host = window.location.host;
            v1api.host = window.location.host;
            backend.baseUrl = this.$root.baseUrl + '/';
            api.baseUrl = this.$root.baseUrl + '/';
            v1api.baseUrl = this.$root.baseUrl + '/';
        }else {
            this.$root.debug.local = true;
            api.host = 'localhost';
            v1api.host = 'localhost';
            log.local = true;
            backend.baseUrl = 'http://localhost:8888/';
            api.baseUrl = 'http://localhost:8888/';
            v1api.baseUrl = 'http://localhost:8888/';
        }
        let hostMatch = false;
        for (let i in this.loginOptions) {
            if (window.location.host.includes(this.loginOptions[i].host)) {
                this.loginLogo = this.loginOptions[i].logo;
                document.documentElement.style.setProperty('--primary', this.loginOptions[i].color);
                document.documentElement.style.setProperty('--button', this.loginOptions[i].color);
                document.getElementById('favicon').href = this.loginOptions[i].fav;
                hostMatch = true;
            }
        }
        if (!hostMatch) {
            this.loginLogo = this.loginOptions[0].logo;
            document.documentElement.style.setProperty('--primary', this.loginOptions[0].color);
            document.documentElement.style.setProperty('--button', this.loginOptions[0].color);
            document.getElementById('favicon').href = this.loginOptions[0].fav;
        }

        // Load default config and translations
        this.$root.config = configTools.getDefault();
        this.$root.translations = locale.getBasicTranslations();
        this.$root.v1translations = v1locale.getDefaultPortalTranslations();

/*         const dt = locale.getBasicTranslations();
        const tres = await axios.get('basic.json');
        for (const key in dt) {

            if (tres.data[key] && tres.data[key].sv_SE) {
                dt[key].sv_SE = tres.data[key].sv_SE;
            }

        }
        console.log(JSON.stringify(dt)); */

        /// http://app.verified.eu/web/aml-portal

        let abortConfigFetch = false;

        // Authenticate
        if (this.$route.query.document_uid && this.$route.query.access_token) {
            // Editor - KYC
            log.w('Editor KYC');
            this.headerTitle = '';
            await this.setEditorAndLoadEnvelope();
            log.s('Authenticated from URL');

        }else if (this.$route.query.document_uid && this.$route.query.a_t) {
            // Editor ID-control - OR assessment
            
            if (this.$route.path == '/assessment') {
                this.headerTitle = 'Sluttrapport';
                log.w('Editor assessment');
            }else {
                this.headerTitle = 'ID-kontroll';
                log.w('Editor id-control');
            }
            await this.setEditorAndLoadEnvelope();
            log.s('Authenticated from URL');

        }else if (this.$route.name == 'FormFillView') {
            // Form editor
            
            log.w('Editor complete form');
            await this.setEditorAndLoadEnvelope2();
            log.s('Authenticated from URL');

        }else if (this.$route.name == 'FormPublic2') {
            // Form public

            abortConfigFetch = true;
            this.editor = true;
            this.configLoaded = true;
            this.appLoading = false;
            log.editor = true;
            log.w('Editor PUBLIC form');

        }else if (this.$route.query.uid) {
            // Recipient portal
            log.w('Recipient portal');
            this.setTitle('Dokumentsignering');
            this.headerTitle = '';
            await this.setEditorAndLoadEnvelope();
            log.s('Authenticated from URL');
        
        }else if (this.$route.path.includes('session_state')) {
            
            //console.log('bankid redirect');
            let url = this.$root.baseUrl;
            const isAWS = (window.location.host.includes('dev-verified') || window.location.host.includes('app.verified'));
            if (isAWS) url += '/web/aml-portal/#';
            url += '/id-kontroll?session_state=' + this.$route.path.replace('/session_state=', '');
            window.location.href = url;
            //console.log('url', url);
            //console.log('on redirected');
            //console.log('route', this.$route);
            log.w('Editor id-control');
            this.headerTitle = 'ID-kontroll';
            await this.setEditorAndLoadEnvelope3();

        }else if (this.$route.name == 'IDControl' && this.$route.query.session_state) {

            //console.log('on redirected');
            log.w('Editor id-control');
            this.headerTitle = 'ID-kontroll';
            await this.setEditorAndLoadEnvelope3();


        }else if (this.$route.name == 'FormRecipient') {

            //console.log('FormRecipient');
            log.w('Editor flow portal');
            this.landing = true;
            this.headerTitle = '';
            await this.setEditorAndLoadEnvelope2();
            log.s('Authenticated from URL');

        }else if (this.$route.name == 'FormLandingView') {

            log.w('Editor form landing page');
            this.landing = true;
            this.headerTitle = '';
            await this.setEditorAndLoadEnvelope2();
            log.s('Authenticated from URL');


        }else if (((window.location.host.includes('dev-verified') || window.location.host.includes('app.verified')) && this.$route.name == 'Home' && this.$route.fullPath.includes('access_token')) || window.location.href.includes('http://localhost:8888/#/')) {

            //console.log('auth AWS');
            //console.log('name', this.$route.name);

            if (window.location.host.includes('dev-verified')) {
                this.$root.isDEV = true;
                backend.isDEV = true;
            }

            this.authenticateAWS();

        }else if (this.$route.query.act) {
            // KYC Landing page
            log.w('Editor KYC landing');
            this.landing = true;
            this.headerTitle = '';
            await this.setEditorAndLoadEnvelope();
            log.s('Authenticated from URL');

        }else if (this.$route.path == '/open') {

            //console.log('route', this.$route);

            this.authenticateFromLink();

        }else if (this.$route.name == 'FlowPortal') {

            log.w('Editor flow portal');
            this.landing = true;
            this.headerTitle = '';
            await this.setEditorAndLoadEnvelope2();
            log.s('Authenticated from URL');

        }else if (this.$route.path == '/oidc') {
            
            if (this.$root.baseUrl.includes('norgesbank') && !sessionStorage.getItem('vapoidc')) {
                let nbUrl = 'https://aml-portal.web.verified.eu/oidc#' + this.$route.hash;
                window.location.href = nbUrl;
                return;
            }else {
                log.w('Editor id-control STATIC');
                this.headerTitle = 'ID-kontroll';
                await this.setEditorAndLoadEnvelope();
                log.s('Authenticated from URL');

                this.navigate({ path: '/id-kontroll', hash: this.$route.hash });
            }   

/*         }else if (this.$route.name == 'FormPublic') {
            
            try {
                log.w('Public form');
                this.headerTitle = 'Form';
                this.setTitle('Form', true);
                this.editor = true;
                log.editor = true;

                const response = await api.getPublicConfig(this.$route.params.company);

                this.$root.credentials.cid = response.publicConfig.cid;
                this.$root.credentials.key = response.publicConfig.key;

                backend.credentials = this.$root.credentials;
                api.credentials = this.$root.credentials;
                v1api.credentials = this.$root.credentials;
                v2lib.namespace = '/companies/' + this.$root.credentials.cid;
                v2lib.publicToken = response.publicConfig.token;

                this.appLoading = false;
            }catch (e) {

                this.logout();

            } */
            

        }else if (localStorage.getItem('user')) {
            try {
                // User and token from local storage
                this.user = JSON.parse(localStorage.getItem('user'));
                if (!(this.user.version && this.user.version >= this.$root.version)) {
                    log.e('User info exists but is from an older version');
                    this.logout();
                    return;
                }
                await auth.authenticate({
                    token: localStorage.getItem('token'),
                    namespace: this.user.namespace
                });
                v2lib.namespace = this.user.namespace;
                v2lib.personalToken = localStorage.getItem('token');
                v1api.vToken = localStorage.getItem('token');
                // Set backend credentials from local storage
                this.$root.credentials = JSON.parse(localStorage.getItem('backend'));
                backend.credentials = this.$root.credentials;
                api.credentials = this.$root.credentials;
                v1api.credentials = this.$root.credentials;
                if (this.$root.credentials.testinc) log.v('Using testinc: ' + this.$root.credentials.testinc );

                if (this.$root.config.portal.deletePermission == 'admin') {
                    if (backend.credentials.role == 'admin') this.$root.allowedActions.deleteCustomer = true;
                }
                if (this.user.isAWS) this.$root.isAWS = true;
                
                this.setAppUser();
                log.s('Authenticated from local storage');
            }catch (e) {
                log.e('User info exists but is corrupted');
                this.logout();
                return;
            }
		}else {
			// Show the login component
            if (window.location.host.includes('app.verified')) {
                window.location.href = 'https://app.verified.eu';
                return;
            }else if (window.location.host.includes('dev-verified')) {
                window.location.href = 'https://dev-verified.eu';
                return;
            }
            log.w('Not authenticated');
            this.loadColors();
            showLogin = true;
        }

        locale.loadCountries(this.$root);
        
        // TODO custom portal translations are not loaded because not needed for any clients yet
/*         const response = await api.getTranslations('portal');
        this.$root.translations = {...this.$root.translations, ...response.texts}; */

        if (!showLogin && !abortConfigFetch) {
            await this.loadConfig();
        }

        // Load user settings to set language and color theme
        if (localStorage.getItem('settings') && !abortConfigFetch) {
            const settings = JSON.parse(localStorage.getItem('settings'));
            this.$root.darkMode = settings.darkMode;
            if (this.$root.darkMode) this.loadColors();
            this.$root.locale = locale.getLocale(this.$root.config.locales, settings.locale);
            log.s('User settings loaded from local storage');
        }else {
            this.$root.locale = locale.getLocale(this.$root.config.locales, 'nb_NO');
            log.e('No user settings found, using defaults');
        }

        if (this.$root.config.branding && this.$root.config.branding.favIcon) {
            document.getElementById('favicon').href = this.$root.config.branding.favIcon;
        }

        // Set language if url contains it
        if (this.$route.query.lang) this.$root.locale = locale.getLocale(this.$root.config.locales, this.$route.query.lang);

        if (this.user && this.$root.config.events.update) {
            v1messages.startUpdateLoop(this.$root);
        }

        if (window.location.host.includes('dev-verified')) {
            this.$root.isDEV = true;
            backend.isDEV = true;
            v1api.isDEV = true;
        }

        // Finish app loading if the user is authenticated from local storage
        if (this.user) await this.finishLoading();
        // Set loading to false to show the login UI
        if (!this.user && !this.loginFromLink) this.loading = false;
    }
}
</script>

<style>

.app-tooltip {
    position: absolute;
    padding: 5px 10px;
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    color: white;
    /* text-align: center; */
    border-radius: 3px;
    background-color: rgb(73, 73, 73);
    box-sizing: border-box;
    -webkit-box-shadow: 0.5px 0.5px 3px 0px rgba(0,0,0,0.26);
    -moz-box-shadow: 0.5px 0.5px 3px 0px rgba(0,0,0,0.26);
    box-shadow: 0.5px 0.5px 3px 0px rgba(0,0,0,0.26);
    z-index: 9999999;
}
.app-tooltip-html {
    margin: 0px;
    padding: 0px;
}
.app-tooltip-html h6 {
    margin-top: 5px;
    margin-bottom: -14px;
    padding: 0px;
    font-size: 16px;
}

.app-tooltip-arrow-up {
    position: absolute;
    position: relative;
    margin: 0;
    text-align: left;
    box-sizing: border-box;
    background: red;
    box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
}
.app-tooltip-arrow-up:after {
    content: "";
    position: absolute;
    top: 5px;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 10px solid black;
    border-color: transparent transparent rgb(73, 73, 73) rgb(73, 73, 73);
    transform-origin: 0 0;
    transform: rotate(-45deg);
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.4);
}
.app-tooltip-arrow-down {
    position: absolute;
    position: relative;
    margin: 0;
    text-align: left;
    box-sizing: border-box;
    background: red;
    box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
}
.app-tooltip-arrow-down:after {
    content: "";
    position: absolute;
    top: -32px;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 10px solid black;
    border-color: transparent transparent rgb(73, 73, 73) rgb(73, 73, 73);
    transform-origin: 0 -20;
    transform: rotate(135deg);
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.4);
}


.app-help {
    position: fixed;
    top: 0px; left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 99999;
}
.app-help-bg {
    position: absolute;
    width: 100%; height: 100%;
    background-color: black;
    opacity: 0.7;
}
.app-help-c {
    position: relative;
    width: 100%;
    max-width: 1000px;
    height: 94vh;
    margin: 0 auto;
    margin-top: 3vh;
    border-radius: 5px;
    background-color: white;
    overflow: auto;
}
.app-help-html {
    font-size: 15px;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    text-align: left;
    padding: 30px 40px;
}



.aml-app-msgs {
    position: fixed;
    top: 144px; right: 20px;
    z-index: 9999;
}
.aml-app-msg {
    position: relative;
    display: flex;
    width: 340px;
    height: 50px;
    margin-bottom: 10px;
    color: var(--header-text);
    border-radius: 3px;
    background-color: var(--header);
    -webkit-box-shadow: 0.5px 3px 5px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0.5px 3px 5px 0px rgba(0,0,0,0.5);
    box-shadow: 0.5px 3px 5px 0px rgba(0,0,0,0.5);
    opacity: 0.9;
}
.aml-app-msg-close {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
}
.aml-app-msg-text {
    flex-grow: 100;
    height: 50px;
    line-height: 50px;
}
.aml-app-msg-text span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}
.aml-app-msg-icon {
    position: relative;
    margin-top: 4px;
    margin-right: 5px;
}



.no-valid-comp {
    float: left;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 40px;
}
.no-valid-comp label {
    float: left;
    width: 100%;
    margin-top: 30px;
}

</style>
