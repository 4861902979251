import v1api from '../v1helpers/v1api';
const v1backoffice = {

    functionUrl: process.env.VUE_APP_API_BASE_URL + '/v1backoffice',


    async checkBackofficeAccess()  {
        return await this.get('accounts');
    },

    //#region Queries -----------------------------------------------------------------------------------------------------

    async queryAccounts(filters, from, limit, sort, include, counts)  {
        const url = `query/accounts?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.get(url + query);
    },

    async queryErrors(filters, from, limit, sort, include, counts)  {
        const url = `query/errors?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.get(url + query);
    },

    async queryStats(filters, from, limit, sort, include, counts)  {
        const url = `query/stats?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.get(url + query);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------
    

    //#region Accounts ----------------------------------------------------------------------------------------------------

    async getAccount(id)  {
        return await this.get('accounts/' + id);
    },

    async checkAccount(pid)  {
        return await this.get(`accounts/${pid}/check`);
    },

    async createAccount(clientData, companyData, personData) {
        const data = {
            clientData: clientData,
            companyData: companyData,
            personData: personData
        };
        return await this.post('accounts', data);
    },

    async updateAccount(id, updateData) {
        const data = {
            updateData: updateData
        };
        return await this.put(`accounts/${id}`, data);
    },

    async deleteAccount(clientId)  {
        return await this.delete(`accounts/${clientId}`);
    },

    async updateUser(id, userId, userData) {
        const data = {
            userData: userData
        };
        return await this.put(`accounts/${id}/users/${userId}`, data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Errors ------------------------------------------------------------------------------------------------------

    async addError(errorData) {
        const data = {
            errorData: errorData,
        };
        return await this.post('errors', data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Stats -------------------------------------------------------------------------------------------------------

    async addStat(statData) {
        const data = {
            statData: statData,
        };
        return await this.post('stats', data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Request / Response ------------------------------------------------------------------------------------------

    async get(route) {
        return v1api.get(route, this.functionUrl);
    },

    async post(route, data) {
        return v1api.post(route, data, this.functionUrl);
    },

    async put(route, data) {
        return v1api.put(route, data, this.functionUrl);
    },

    async delete(route) {
        return v1api.delete(route, this.functionUrl);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default v1backoffice;