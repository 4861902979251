<template>
<div>

    <PopupV1 :maxHeight="$root.config.flow.reportChecklist ? 500 : 0" :width="900"
        :header="__t('report-create')" @close="onClose" :dialog="dialog" :fullscreen="fullscreen">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('report-create-hint') }}
            </div>
            <DialogContent>
                <DialogContentHeader :text="__t('files-document').toUpperCase() + ':'" />
                <DialogContentItem :text="filename" :icon="'verified'" />
                <DialogContentHeader :text="__t('files-attachments').toUpperCase() + ':'" />
                <DialogContentItem v-for="(attachment, i) in attachments" :key="'fra' + i" :text="attachment.name" :icon="'attachment'" />
            </DialogContent>

            <DialogContent v-if="notifications.length > 0" style="margin-top: 10px;">
                <DialogContentHeader :text="'En kopia av slutrapporten skickas till' + ':'" />
                <DialogContentItem v-for="(item, i) in notifications" :key="'frn' + i"
                    :text="item.display"
                    :icon="'verified'"
                />
            </DialogContent>

            <div class="flw100" v-if="formEnabled"
                style="margin-top: 20px; margin-left: -25px; margin-bottom: -40px;">

                <div class="flw100" style="padding-left: 25px;">
                    {{ formTitle[$root.locale.key] }}
                </div>

                <FormReportView ref="reportForm"
                    :clientId="client._id"
                    :initialFormKey="'checklist01'"
                    @loaded="onFormLoaded"
                    @validated="onFormValidated"
                />

            </div>

            <div class="flw100" style="margin-top: 10px;" v-if="$root.config.freport.popupComment">
                <TextEditorV2 :content="comment" :placeholder="__t('common-comments')" @input="onCommentInput" />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="'Lagre data'" :icon="'save'" @clicked="saveReportDataAction" :working="isSaving" v-if="formEnabled" />
            <VFButton :text="__t('report-create')" :icon="'verified'" @clicked="onCreate" :working="dialog.working" :disabled="!dialog.valid" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1reportHelper from '../v1helpers/v1reportHelper';
import triggerHelper from '../v1flows/triggerHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

import TextEditorV2 from '../components/form/TextEditorV2.vue';

import FormReportView from '../components/form/FormReportView.vue';

export default {
    name: 'CreateReportDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox,
        TextEditorV2,
        FormReportView,
    },
    props: {
        client: { type: Object, default: null },
        flow: { type: Object, default: null },
        persons: { type: Array, default: null },
        triggers: { type: Object, default: null },
        texts: { type: Object, default: null },
        params: { type: Object, default: null },
        fullscreen: { type: Boolean, default: false },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            attachments: [],
            comment: '',
            notifications: [],
            formEnabled: false,
            formTitle: { en_EN: '', nb_NO: '', sv_SE: '' },
            filename: 'finalreport.pdf',
            isSaving: false,
        }
    },
    methods: {

        onFormLoaded(formData) {
            //console.log('onFormLoaded', formData);
            this.formTitle = JSON.parse(JSON.stringify(formData.title));
        },

        onFormValidated() {
            this.validateDialog();
        },

        onCommentInput(input) {
            this.comment = input;
            this.validateDialog();
        },

        validateDialog() {
            this.dialog.error = '';
            this.dialog.valid = false;
            if (this.$root.config.freport.popupComment) {

                let valid = true;

                if (!(this.comment && this.comment.length > 0)) {
                    valid = false;
                    this.dialog.error = 'Legg inn en kommentar';
                }

                if (this.formEnabled) {
                    const formValid = this.$refs.reportForm ? this.$refs.reportForm.validation.formValid : false;
                    if (!formValid) {
                        valid = false;
                        this.dialog.error = 'Fullfør sjekklisten';
                    }
                }

                this.dialog.valid = valid;
            }else {
                this.dialog.valid = true;
            }
        },

        async saveReportDataAction() {
            this.isSaving = true;

            await this.saveReportData();

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.isSaving = false;
        },

        async saveReportData() {
            //console.log('refs', this.$refs);

            const items = [];
            for (const i in this.params.data) {
                if (this.$root.config.freport.popupComment && this.params.data[i].key == 'assessment') {
                    this.params.data[i].comment = this.comment;
                }
                const item = this.params.data[i];
                //console.log('item', item);
                if (item.key == 'form') {
                    console.log('FORM');
                    if ((this.$refs && this.$refs.reportForm)) {
                        const formData = this.$refs.reportForm.getFormData();
                        item.formData = JSON.stringify(formData);
                    }else if (this.$parent.$refs && this.$parent.$refs.reportForm && this.$parent.$refs.reportForm.length > 0) {
                        const formData = this.$parent.$refs.reportForm[0].getFormData();
                        item.formData = JSON.stringify(formData);
                    }
                }
                items.push(item);
            }
            await v1api.saveReportData(this.client._id, items, this.client.report.risk || 0);

            //console.log('items saved', items);
        },

        async onCreate() {
            this.dialog.working = true;
            this.dialog.status = this.__t('report-prog-creating');

            console.log('refs', this.$refs);

            await this.saveReportData();

            const extraData = {};
            if (this.$refs && this.$refs.reportForm) {
                const formData = this.$refs.reportForm.getHtml();
                console.log('formData', formData);
                extraData.pdfConfig = formData.pdfConfig;
                extraData.pdfMeta = formData.pdfMeta;
                extraData.pdfPages = formData.pdfPages;
            }else if (this.$parent.$refs && this.$parent.$refs.reportForm && this.$parent.$refs.reportForm.length > 0) {
                const formData = this.$parent.$refs.reportForm[0].getHtml();
                console.log('formData', formData);
                extraData.pdfConfig = formData.pdfConfig;
                extraData.pdfMeta = formData.pdfMeta;
                extraData.pdfPages = formData.pdfPages;
            }

            const userdata = v1reportHelper.getReportUserData(this.$root, this.params, this.client, this.flow, this.persons, this.triggers, this.texts, false, extraData);
            console.log('USERDATA', userdata);

            await v1reportHelper.createReport(this.$root, userdata, this.dialog, this.triggers, this.filename);

            const response = await v1api.getClient(this.client._id);
            this.$emit('created', response);

            this.dialog.working = false;
            this.dialog.show = false;
            this.onClose();
        },

        onClose() {
            this.$emit('close');
        },

    },
    created() {
        this.attachments = v1reportHelper.getAttachments(this.$root, this.flow, this.client, this.persons);

        this.filename = v1reportHelper.getReportName(this.$root, this.client) + '.pdf';

        for (const i in this.$root.config.freport.notifications) {
            const notification = this.$root.config.freport.notifications[i];
            const values = [];
            let shouldNotify = true;
            for (const j in notification.triggers) {
                const valid = triggerHelper.validateTrigger(notification.triggers[j], this.triggers);
                values.push(valid);
            }
            if (notification.triggerOr) {
                for (const j in values) {
                    if (values[j]) shouldNotify = true;
                }
            }else {
                shouldNotify = true;
                for (const j in values) {
                    if (!values[j]) shouldNotify = false;
                }
            }
            if (shouldNotify) {
                const item = {
                    display: notification.firstName + ' ' + notification.lastName + ' (' + notification.email + ')',
                };
                console.log('notifying', item);
                this.notifications.push(item);
            }
        }

        // Check if report contains a form
        for (const i in this.flow.report) {
            if (this.flow.report[i].key == 'form') {

                if (this.$root.config.freport.popupComment && this.$root.config.flow.reportChecklist) {
                    this.formEnabled = true;
                }

                //console.log('found form');

                // Give report form client context
                let existingData = {
                    companyData: this.client.company,
                    customer: this.client,
                    persons: this.persons,
                    client: this.client
                };
                if (this.flow.isPerson) existingData.person = this.persons[0];

                // Load saved form data
                if (this.client.report.items) {
                    for (const j in this.client.report.items) {
                        if (this.client.report.items[j].key == 'form' && this.client.report.items[j].formData) {
                            existingData.savedPages = JSON.parse(this.client.report.items[j].formData);
                            existingData.loadSavedData = true;
                        }
                    }
                }

                this.$root.formData = JSON.stringify(existingData);
            }
        }

        // Load existing comment
        for (const j in this.client.report.items) {
            if (this.client.report.items[j].key == 'assessment') {
                this.comment = this.client.report.items[j].comment;
            }
        }

        this.validateDialog();
        this.loading = false;
    }
}
</script>