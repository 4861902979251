<template>
    <div class="flw100" style="margin-top: 20px;">

        <!-- Departments -->
        <div v-if="tabs.tabDepartments">
            <vap-form-divider :left="65" :right="35" stickyRight>
                <template v-slot:left>
                    <vap-label :text="'Avdelinger'" header style="margin-bottom: 5px;" />
                    <vap-card compactList noPadding v-for="(department, i) in $root.departments" :key="'d' + i">
                        <vap-item padding20>
                            <label style="color: var(--text-strong);">{{ department.name }}</label>
                            <label style="margin-left: 10px; color: var(--text-weak);">{{ '(' + department.key + ')' }}</label>
                        </vap-item>
                    </vap-card>
                </template>
                <template v-slot:right>
                    <vap-label :text="'Valg'" header style="margin-bottom: 5px;" />
                    <vap-card>
                        
                        
                    </vap-card>
                </template>
            </vap-form-divider>
        </div>


        <!-- Partners -->
        <div v-if="tabs.tabPartners">
            <vap-form-divider :left="65" :right="35" stickyRight>
                <template v-slot:left>
                    <vap-label :text="'Partnere'" header style="margin-bottom: 5px;" />
                    <vap-card compactList noPadding v-for="(partner, i) in $root.partners" :key="'d' + i">
                        <vap-item padding20>
                            <label style="color: var(--text-strong);">{{ partner.firstName + ' ' + partner.lastName }}</label>
                            <label style="margin-left: 10px; color: var(--text-weak);">{{ '(' + partner.key + ')' }}</label>
                        </vap-item>
                    </vap-card>
                </template>
                <template v-slot:right>
                    <vap-label :text="'Valg'" header style="margin-bottom: 5px;" />
                    <vap-card>
                        
                        <vap-button :text="'Legg til partner'" @clicked="showAddPartnerPopup" />
                        
                    </vap-card>
                </template>
            </vap-form-divider>
        </div>


        <ve-popup v-if="addPartnerPopup.show" :header="'Legg til partner'" :width="500" @close="addPartnerPopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c6>
                            <vap-label desc :text="__('common_first_name')" />
                            <vap-input v-model="addPartnerPopup.firstName" :rules="'required'" @input="validateAddPartner" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="__('common_last_name')" />
                            <vap-input v-model="addPartnerPopup.lastName" :rules="'required'" @input="validateAddPartner" />
                        </vap-group>
                        <vap-group c12>
                            <vap-label desc :text="'Ansattnummer'" />
                            <vap-input v-model="addPartnerPopup.number" :rules="'required'" @input="validateAddPartner" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="addPartnerPopup.show = false" />
                    <vap-button :text="__('common_add')" uppercase @clicked="addPartner" :disabled="!addPartnerPopup.valid" :working="addPartnerPopup.working" />
                </vap-button-row>
            </div>
        </ve-popup>


    </div>
</template>

<script>
import backend from '../../backend';
import api from '../../portalapi';

export default {
    name: 'UserList',
    props: {
        tabs: {
            type: Object,
            default: undefined
        },
        tabKey: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            multiple: false,
            addPartnerPopup: { show: false, valid: false, working: false, firstName: '', lastName: '', number: '' },
        }
    },
    watch: {
        tabs: {
            deep: true,
            handler(val) {
                
            }
        }
    },
    methods: {
        
        showAddPartnerPopup() {
            this.addPartnerPopup.show = true;
        },

        validateAddPartner() {
            let valid = true;
            if (this.addPartnerPopup.firstName.length < 1) valid = false;
            if (this.addPartnerPopup.lastName.length < 1) valid = false;
            if (this.addPartnerPopup.number.length < 4) valid = false;
            this.addPartnerPopup.valid = valid;
        },

        async addPartner() {
            this.addPartnerPopup.working = true;
            const partner = {
                key: Number(this.addPartnerPopup.number),
                firstName: this.addPartnerPopup.firstName,
                lastName: this.addPartnerPopup.lastName,
            };
            const aresponse = await api.addPartner(partner);
            console.log('aresponse', aresponse);

            let response = await api.loadConfig();
            this.$root.partners = response.partners ? response.partners.list : [];
            localStorage.setItem('dcp', JSON.stringify(this.$root.partners));

            this.addPartnerPopup.working = false;
            this.addPartnerPopup.show = false;
        },

    },
    async mounted() {
        


    }
}
</script>
<style scoped>


</style>