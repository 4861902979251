<template>
<div class="vf-anas" @click.stop="handleClick" :class="[
    { 'vf-selected' : selected }
]">

    <div class="vf-card-logic" v-if="selected" :id="'l' + id" @click.stop="handleLogicClick" @mousedown="handleLogicClick">
        <vap-icon tiny :icon="'logic'" :color="'white'" absolute :top="1" :right="13" />
    </div>
    
    <div v-if="designer" class="vf-anas-base">

        <div class="vf-anas-line">

        </div>

        <div class="vf-anas-hint">
            {{ '(Signatur)' }}
        </div>

    </div>

    <div v-if="!designer" class="vf-anas-base">

        <div class="vf-anas-line">

        </div>

        <div class="vf-anas-hint">
            {{ '(Signatur)' }}
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'VFAnalogSignature',
    props: {
        cardData: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        id: { type: String, default: 'id' },
        selected: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

        handleLogicClick() {
            this.$emit('logic', this.id);
        },

    },
    mounted() {
        
    }
}
</script>
<style scoped>

.vf-anas {
    position: relative;
    float: left;
    width: 100%;
    text-align: center;
}

.vf-anas-base {
    width: 300px;
    margin: 0 auto;
}

.vf-anas-line {
    height: 40px;
    border-bottom: solid 3px black;
}

.vf-anas-text {
    
}


/* Designer / Selection */

.vf-selected {
    outline: solid 2px #238ccc;
}

.vf-card-logic {
    position: absolute;
    top: 10px; right: 20px;
    width: 26px; height: 26px;
    border-radius: 50%;
    background-color: #3190D5;
    cursor: pointer;
    z-index: 2;
}

</style>
