<template>
<div>

    <DesignerGroup c12>
        <DesignerLabel :text="'Number of approvers'" />
        <DesignerInput v-model="approveCount" @input="onConfigurationChanged" />
    </DesignerGroup>

</div>
</template>
<script>
import DesignerGroup from '../../designer/ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../../designer/ui/inputs/DesignerLabel.vue';
import DesignerInput from '../../designer/ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../../designer/ui/inputs/DesignerCheckbox.vue';

export default {
    name: "ApproveModuleBox",
    components: {
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() {
        return {
            approveCount: 0,
        }
    },
    methods: {

        onConfigurationChanged() {
            for (const i in this.reference.modules) {
                if (this.reference.modules[i].type == 'app') {
                    this.reference.modules[i].approveCount = this.approveCount;
                }
            }
        },

    },
    created() {
        for (const i in this.reference.modules) {
            if (this.reference.modules[i].type == 'app') {
                this.approveCount = this.reference.modules[i].approveCount || 1;
            }
        }
    }
}

</script>