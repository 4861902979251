<template>
<div class="slim-page">

    <vap-label :text="'Forms'" header style="margin-bottom: 5px;">
        <template v-slot:actions>
            <vap-icon v-if="$root.config.forms.editor.allowNew || $root.credentials.isAdmin" action :icon="'add'" @clicked="showCreateDialog">
                <template v-slot:tooltip><vap-tooltip :text="'Add form'"></vap-tooltip></template>
            </vap-icon>
            <vap-icon v-if="$root.credentials.isAdmin" action :icon="'upload'" @clicked="defaultDialog.show = true">
                <template v-slot:tooltip><vap-tooltip :text="'Add default form'"></vap-tooltip></template>
            </vap-icon>
        </template>
    </vap-label>

    <vap-card list noPadding v-for="form in forms" :key="form.key">
        <div class="frv2-list">
            <div class="frv2-list-title">
                <div>
                    <span>{{ form.designName || form.title[$root.locale.key] }}</span>
                </div>
                <div style="font-size: 16px;">
                    <span style="color: var(--text-weak);">{{ form.title[$root.locale.key] }}</span>
                    <span style="margin-left: 10px; color: var(--text-accent);">{{ '(' + form.key + ')' }}</span>
                </div>
            </div>
            <div class="frv2-list-actions">
                <vap-icon v-if="$root.credentials.isAdmin" :icon="'monitor'" action @clicked="onCreatePDF(form.key)" />
                <vap-icon v-if="$root.config.forms.editor.allowNew || $root.credentials.isAdmin" :icon="'delete'" action @clicked="onDeleteClicked(form)" />
                <vap-icon v-if="$root.config.forms.editor.simple || $root.config.forms.editor.advanced || $root.credentials.isAdmin || $root.credentials.isSuper" :icon="'edit'" action @clicked="onEditClicked(form.key)" />
                <vap-icon v-if="$root.credentials.isAdmin" :icon="'open'" action @clicked="onOpenClicked(form.key)" />
            </div>
        </div>
    </vap-card>

    <ve-popup v-if="createDialog.show" :header="'Create form'" :width="600" @close="createDialog.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <div>
                <vap-row>
                    <vap-group c12>
                        <vap-label desc :text="'Title'" required />
                        <vap-input v-model="createDialog.title" :rules="'required'" noValidation noHighlight @input="validateCreateDialog" />
                    </vap-group>
                    <vap-group c12>
                        <vap-label desc :text="'Key'" required />
                        <vap-input v-model="createDialog.key" :rules="'required'" noValidation noHighlight @input="validateCreateDialog" />
                    </vap-group>
                    <vap-group c12>
                        <vap-label desc :text="'Flow'" required />
                        <vap-dropdown noValidation noHighlight
                            v-model="createDialog.flow"
                            :options="flowOptions"
                            :displayKey="'display'"
                            :listKey="'display'"
                            :outputKey="'value'"
                            @input="validateCreateDialog"
                        />
                    </vap-group>
                    <vap-group c12>
                        <vap-label desc :text="'Languages'" required />
                        <vap-checkbox noValidation noHighlight
                            v-model="createDialog.locales"
                            :options="localeOptions"
                            @input="validateCreateDialog"
                        />
                    </vap-group>
                </vap-row>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="createDialog.show = false" />
                <vap-button :text="'Create form'" uppercase @clicked="createForm" :disabled="!createDialog.valid" :working="createDialog.working" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="defaultDialog.show" :header="'Add default form'" :width="600" @close="defaultDialog.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <div>
                <vap-row>
                    <vap-group c12>
                        <vap-label desc :text="'Default form'" required />
                        <vap-dropdown noValidation noHighlight
                            v-model="defaultDialog.option"
                            :options="defaultOptions"
                            :displayKey="'name'"
                            :listKey="'name'"
                            :outputKey="'key'"
                            @input="setDefaultKey"
                        />
                    </vap-group>
                    <vap-group c12>
                        <vap-label desc :text="'Key'" required />
                        <vap-input v-model="defaultDialog.key" :rules="'required'" noValidation noHighlight @input="validateDefaultDialog" />
                    </vap-group>
                </vap-row>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="defaultDialog.show = false" />
                <vap-button :text="'Create form'" uppercase @clicked="addDefaultForm" :disabled="!defaultDialog.valid" :working="defaultDialog.working" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="deleteDialog.show" :header="'Delete form?'" :width="400" @close="deleteDialog.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <label>{{ 'Are you sure you want to delete the form ' + deleteDialog.title + '?' }}</label><br><br>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_no')" uppercase @clicked="deleteDialog.show = false" />
                <vap-button :text="__('common_yes')" uppercase @clicked="deleteForm" :working="deleteDialog.working" />
            </vap-button-row>
        </div>
    </ve-popup>

    <!-- Preview popup -->
    <PreviewPopupV1 v-if="previewVisible" :params="previewParams" @close="previewVisible = false" />
    
</div>
</template>
<script>
import api from '@/portalapi';
import defaultForms from '../v1form/defaultForms';
import formHelper from '../components/form/formHelper';
import pdfGenerator from '../components/form/pdfGenerator';
import previewHelper from '../v1files/previewHelper';

import PreviewPopupV1 from '../v1files/PreviewPopupV1.vue';

const forms = defaultForms.getForms().map(x => ({ key: x, name: defaultForms.getForm(x).designName }));

export default {
    name: 'FormSettings',
    components: {
        PreviewPopupV1
    },
    props: {
        flow: { type: Object, default: null }
    },
    data() {
        return {
            forms: [],
            createDialog: { show: false, working: false, valid: false, key: '', title: '', flow: 'sign', module: 'none', locales: {} },
            defaultDialog: { show: false, working: false, key: '', option: '' },
            deleteDialog: { show: false, working: false, key: '', title: '' },
            flowOptions: [
                { value: 'sign', display: 'Signature flow' },
                { value: 'internal', display: 'Internal approve flow' },
                { value: 'direct', display: 'Direct to PDF flow' },
                { value: 'report', display: 'Final report flow' },
            ],
            localeOptions: {},
            defaultOptions: forms,
            previewVisible: false,
            previewParams: null
        }
    },
    methods: {
        
        onOpenClicked(key) {
            this.$parent.$parent.$parent.navigate({ path: '/form/dev/' + key });
        },

        onEditClicked(key) {
            this.$parent.$parent.$parent.navigate({ path: '/formdesigner', query: { key: key }});
        },

        onDeleteClicked(form) {
            this.deleteDialog.key = form.key;
            this.deleteDialog.title = form.title[this.$root.locale.key];
            this.deleteDialog.show = true;
        },

        async onCreatePDF(key){
            const response = await api.getForm(key);
            //pdfGenerator.getBase64(this.$root, response.formData);
            const userdata = await pdfGenerator.getPreviewData(this.$root, response.formData, true);

            console.log('USERDATA', userdata);
            const previewParams = previewHelper.getFormPreviewParams(userdata);
            this.$emit('preview', previewParams);
            
            this.previewParams = previewParams;
            this.previewVisible = true;
        },



        async loadForms() {
            const response = await api.getForms();
            this.forms = response.forms;
        },

        //#region Create Dialog -----------------------------------------------------------------------------------------------

        showCreateDialog() {
            for (const i in this.$root.config.locales) {
                if (this.$root.config.locales[i].enabled) {
                    this.$set(this.createDialog.locales, this.$root.config.locales[i].key, true);
                    this.$set(this.localeOptions, this.$root.config.locales[i].key, { label: this.$root.config.locales[i].name });
                }
            }
            this.createDialog.show = true;
        },

        validateCreateDialog() {
            let valid = false;
            if (this.createDialog.key && this.createDialog.title) valid = true;
            this.createDialog.valid = valid;
        },

        async createForm() {
            this.createDialog.working = true;

            const data = formHelper.getEmptyConfig(this.$root, this.createDialog);

            await api.createForm(data);

            await this.loadForms();

            this.createDialog.working = false;
            this.createDialog.show = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Default Dialog ----------------------------------------------------------------------------------------------

        validateDefaultDialog() {
            let valid = false;
            if (this.defaultDialog.key && this.defaultDialog.option) valid = true;
            this.defaultDialog.valid = valid;
        },

        setDefaultKey() {
            this.defaultDialog.key = defaultForms.getForm(this.defaultDialog.option).key;
            this.validateDefaultDialog();
        },

        async addDefaultForm() {
            this.defaultDialog.working = true;

            const data = defaultForms.getForm(this.defaultDialog.option);

            data.key = this.defaultDialog.key;

            await api.createForm(data);

            await this.loadForms();

            this.defaultDialog.working = false;
            this.defaultDialog.show = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        async deleteForm() {
            this.deleteDialog.working = true;

            await api.deleteForm(this.deleteDialog.key);

            await this.loadForms();

            this.deleteDialog.working = false;
            this.deleteDialog.show = false;
        }

    },
    async created() {
        await this.loadForms();
    }
}
</script>
<style scoped>

.frv2-list {
    padding: 10px 20px;
    display: flex;
}

.frv2-list-title {
    flex-grow: 100;
    font-size: 18px;
    color: var(--text-title);
}

.frv2-list-actions {
    display: flex;
}

</style>