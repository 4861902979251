<template>

<div class="vf-row">
    <slot></slot>
</div>

</template>
<script>

export default {
    name: "vf-row",
    props: {
        actionWidth: {
            type: Number,
            default: 0
        }
    },
}

</script>
<style>

.vf-row {
    /* float: left; */
    width: 100%;
}

</style>
