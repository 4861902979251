<template>
    <div class="vap-number-item" :class="[{'vap-number-bold': bold}, {'vap-number-top': top}]">
        <label class="vap-number-item-l">{{ text }}</label>
        <label class="vap-number-item-r">{{ number }}</label>
    </div>
</template>

<script>
import tools from '../../tools';

export default {
    name: 'vap-number-item',
    props: {
        text: {
            type: String,
            default: 'text'
        },
        number: {
            type: [String, Number],
            default: 'number'
        },
        bold: {
            type: Boolean,
            default: false
        },
        top: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        
    },
    async mounted() {
        
    }
}
</script>
<style scoped>

.vap-number-item {
    margin-top: 5px;
    color: var(--text-strong);
    border-bottom: solid 1px var(--input-border);
}

.vap-number-item-r {
    float: right;
    font-size: 17px;
    font-family: monospace;
}

.vap-number-bold {
    font-weight: bold;
}

.vap-number-top {
    margin-top: 20px;
    border-top: solid 1px var(--input-border);
}

</style>