<template>
<div>

    <PopupV1 :header="'Signing Module Configuration'" v-if="params.type == 'manage'" :width="500" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <VFCheckbox newDesign v-model="methodModel" :options="methodOptions" @input="onMethodsChanged" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

    <PopupV1 :header="'Connect to Signing Module'" v-if="params.type == 'connect'" :width="500" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <ConnectionList :items="options" :params="params" @item="onConnectItem" @itemkey="onConnectItemKey" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import logicHelper from '../../logicHelper';

import ConnectionList from '../../designer/ConnectionList.vue';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';
import VFCheckbox from '../../inputs/VFCheckbox.vue';

export default {
    name: "frmod-sign",
    components: {
        ConnectionList,
        PopupV1,
        VFButton,
        VFCheckbox,
    },
    props: {
        params: { type: Object, default: () => { return { type: '' } }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: {},
            options: [],
            methodOptions: {},
            methodModel: {
                bankidno: false,
                bankidse: false,
                touchsign: false,
            },
        }
    },
    methods: {

        onMethodsChanged() {

            for (const i in this.$parent.modules) {
                if (this.$parent.modules[i].type == 'sign') {
                    
                    const module = this.$parent.modules[i];

                    console.log('module', module);

                    for (const key in this.methodModel) {

                        module.methods[key] = this.methodModel[key];

                    }

                    console.log('methods', module.methods);
                }
            }

        },

        onConnectItem(result) {
            console.log('result', result);

            if (result.conn == 'submit') {

                logicHelper.prepareItem(this.reference, this.params.fromItem, 'action');
                this.$set(this.params.fromItem.logic, 'action', {
                    type: 'sign',
                    key: 'submit'
                });

            }else if (result.conn == 'method') {
                
                logicHelper.prepareItem(this.reference, this.params.fromItem, 'link');
                this.params.fromItem.logic.link.push({
                    type: 'sign',
                    link: 'method',
                    key: ''
                });

                console.log('set', this.params.fromItem);

            }else if (result.conn == 'signlist') {
                
                logicHelper.prepareItem(this.reference, this.params.fromItem, 'link');
                this.params.fromItem.logic.link.push({
                    type: 'sign',
                    link: 'slist',
                    key: ''
                });

            }
            
            this.$emit('reselect');
            this.$emit('close');
        },

        onConnectItemKey(result) {
            console.log('result', result);

            if (result.conn == 'direct') {

                logicHelper.prepareItem(this.reference, this.params.fromItem, 'link');
                this.params.fromItem.logic.link.push({
                    type: 'sign',
                    link: 'direct',
                    key: result.key
                });

            }

            this.$emit('reselect');
            this.$emit('close');
        },

    },
    created() {
        if (this.params.type == 'connect') {
            const type = this.params.fromItem.type;
            
            // Add possible connections
            if (type == 'drop') {
                // Selected method (direct signing)
                this.options.push({ type: 'link', conn: 'method', icon: 'sign', text: 'Selected method',
                    hint: 'This will connect to the selected method'
                });
            }
            if (type == 'btn') {
                // Submit form
                this.options.push({ type: 'action', conn: 'submit', icon: 'open', text: 'Submit form',
                    hint: 'This will submit the form and start the signing process'
                });
            }
            if (type == 'check' || type == 'drop') {
                // Activate direct signing
                this.options.push({ type: 'link', conn: 'direct', icon: 'file-sent', text: 'Select direct signing',
                    hint: 'Direct signing wil be selected when input is checked'
                });
            }
            if (type == 'list') {
                // Recipient list
                this.options.push({ type: 'link', conn: 'signlist', icon: 'sign', text: 'Signatories',
                    hint: 'This list will define the signatories of the form'
                });
            }
        }else {

            // Load available methods
            if (this.$root.config.params.signMethods.bankidno) this.methodOptions.bankidno = { label: 'BankID Norway' };
            if (this.$root.config.params.signMethods.bankidse) this.methodOptions.bankidse = { label: 'BankID Sweden' };
            if (this.$root.config.params.signMethods.touchsign) this.methodOptions.touchsign = { label: 'TouchSign' };
            if (this.$root.config.params.signMethods.passreader) this.methodOptions.passreader = { label: 'Passport Reader' };

            for (const i in this.$parent.modules) {
                if (this.$parent.modules[i].type == 'sign') {
                    if (this.$parent.modules[i].methods) {
                        if (this.$parent.modules[i].methods.bankidno) this.methodModel.bankidno = true;
                        if (this.$parent.modules[i].methods.bankidse) this.methodModel.bankidse = true;
                        if (this.$parent.modules[i].methods.touchsign) this.methodModel.touchsign = true;
                        if (this.$parent.modules[i].methods.passreader) this.methodModel.passreader = true;
                    }else {
                        this.methodModel.bankidno = true;
                        this.methodModel.bankidse = true;
                        this.methodModel.touchsign = true;
                    }
                }
            }

        }
    }
}

</script>