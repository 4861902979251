const mapNoHelper = {


    //#region Processing --------------------------------------------------------------------------------------------------

    processCompany(result, reference) {
        const mapData = {
            cards: [],
            lines: []
        };

        mapData.cards.push({
            display: result.owners[0].name,
            type: 'org',
            x: 0,
            y: 0,
        });

        this.processLevel(mapData, result, -1, 0);

        reference.mapData = mapData;
    },

    processLevel(mapData, base, yLevel, baseX) {
        if (!base.owners || base.owners.length == 0) return;

        console.log('processing', base);

        const totalCards = base.owners.length;

        console.log('totalCards', totalCards);

        let xOffset = 0;
        if (totalCards % 2) xOffset = -1;

        console.log('centerX', baseX);


        let startX = Math.round(baseX - totalCards - xOffset);
        if (totalCards == 1) startX = baseX;


        console.log('startX', startX);

        let currentX = startX;
        const totalShares = base.owners[0].st;
        const shareFactor = 100 / totalShares;

        for (const i in base.owners) {

            const share = Math.round(shareFactor * base.owners[i].sc).toFixed(2)

            const card = {
                display: this.getName(base.owners[i].nm),
                type: base.owners[i].id.length > 4 ? 'org' : 'per',
                x: currentX,
                y: yLevel,
            };
            mapData.cards.push(card);

            const line = {
                x1: currentX,
                y1: yLevel,
                x2: baseX,
                y2: yLevel + 1,
                x1p: 0,
                x2p: this.getTargetPosition(totalCards, i),
                share: share + ' %'
            };
            mapData.lines.push(line);

            this.processLevel(mapData, base.owners[i], yLevel - 1, currentX);

            currentX += 2;
        }
    },

    //#endregion ----------------------------------------------------------------------------------------------------------
    

    //#region Helpers -----------------------------------------------------------------------------------------------------

    getTargetPosition(totalCount, currentCount) {
        let xOffset = 0;
        let skipCenter = true;
        if (totalCount % 2) {
            xOffset = 0.5;
            skipCenter = false;
        }
        const firstPosition = 0 - (totalCount / 2) + xOffset;
        let position = firstPosition + Number(currentCount);
        if (position >= 0 && skipCenter) position = position + 1;
        return position;
    },

    getName(name) {
        if (name.length > 20) return name.substring(0, 17) + '...';
        return name;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default mapNoHelper;