import log from '../../log';
import formHelper from './formHelper';
const clipboardHelper = {

    async addToClipboard(root, reference, item) {

        root.formClipboard = [];
        root.formClipboard.push(item);

        if (navigator.clipboard) {
            const cbData = {
                frdata: true,
                item: item
            };
            await navigator.clipboard.writeText(JSON.stringify(cbData));
        }

        log.x('Clipboard: Item added to clipboard', item);
        this.updateClipboard(root, reference);
    },

    async pasteItem(root, reference, container) {

        log.x('Clipboard: Pasting item to container', container);

        let item = null;
        let hasClipboardData = false;

        try {
            if (navigator.clipboard) {
                const text = await navigator.clipboard.readText();
                const cbData = JSON.parse(text);
                if (cbData.frdata) {
                    item = cbData.item;
                    hasClipboardData = true;
                }
            }
        }catch (ex) {
            log.x('Clipboard: No valid data in clipboard or not focused');
        }

        if (!hasClipboardData && root.formClipboard.length > 0) {
            item = root.formClipboard[0];
        }

        let copy = null;
        if (formHelper.isCard(item)) {
            copy = this.getCardCopy(reference, item);
        }else if (item.type == 'page') {
            copy = this.getPageCopy(reference, item);
        }else {
            copy = this.getInputCopy(reference, item);
        }

        if (formHelper.isCard(copy)) {
            container.cards.push(copy);
        }else if (copy.type == 'page') {
            reference.pages.push(copy);
            reference.processSteps();
        }else {
            container.inputs.push(copy);
        }
        log.x('Clipboard: New item pasted', copy);
    },

    getInputCopy(reference, item) {
        const copy = JSON.parse(JSON.stringify(item));

        let keyLetter = copy.key.charAt(0);
        copy.key = keyLetter + (reference.counter += 1);

        // TODO find a way to copy logic
        if (copy.logic) delete copy.logic;

        // Checkbox options
        if (copy.options) {
            const tempList = [];
            for (const key in copy.options) {
                keyLetter = key.charAt(0); 
                copy.options[key].key = keyLetter + (reference.counter += 1);
                tempList.push(copy.options[key]);
                delete copy.options[key];
            }
            for (const i in tempList) {
                copy.options[tempList[i].key] = tempList[i];
            }
        }

        // Dropdown items
        if (copy.items) {
            for (const i in copy.items) {
                keyLetter = copy.items[i].key.charAt(0); 
                const newKey = keyLetter + (reference.counter += 1);
                if (copy.items[i].value == copy.items[i].key) copy.items[i].value = newKey;
                copy.items[i].key = newKey;
            }
        }

        return copy;
    },

    getCardCopy(reference, item) {
        const copy = JSON.parse(JSON.stringify(item));

        let keyLetter = copy.key.charAt(0);
        copy.key = keyLetter + (reference.counter += 1);

        // TODO find a way to copy logic
        if (copy.logic) delete copy.logic;

        if (copy.inputs) {
            for (const i in copy.inputs) {
                copy.inputs[i] = this.getInputCopy(reference, copy.inputs[i]);
            }
        }

        return copy;
    },

    getPageCopy(reference, item) {
        const copy = JSON.parse(JSON.stringify(item));

        let keyLetter = copy.key.charAt(0);
        copy.key = keyLetter + (reference.counter += 1);

        // TODO find a way to copy logic
        if (copy.logic) delete copy.logic;

        for (const i in copy.cards) {
            copy.cards[i] = this.getCardCopy(reference, copy.cards[i]);
        }

        return copy;
    },

    async updateClipboard(root, reference) {
        const clipboard = {
            hasInput: false,
            hasCard: false,
            hasPage: false
        };
        let item = null;
        let hasClipboardData = false;

        try {
            if (navigator.clipboard) {
                const text = await navigator.clipboard.readText();
                const cbData = JSON.parse(text);
                if (cbData.frdata) {
                    item = cbData.item;
                    hasClipboardData = true;
                }
            }
        }catch (ex) {
            log.x('Clipboard: No valid data in clipboard or not focused');
        }

        if (!hasClipboardData && root.formClipboard.length > 0) {
            item = root.formClipboard[0];
        }

        if (item) {
            if (formHelper.isCard(item)) {
                clipboard.hasCard = true;
            }else if (item.type == 'page') {
                clipboard.hasPage = true;
            }else {
                clipboard.hasInput = true;
            }
        }

        reference.clipboard = clipboard;
        log.x('Clipboard: Updated', clipboard);
    },

};
export default clipboardHelper;