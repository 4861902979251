<template>
    <div class="vepop" @keydown="handleKeyDown" :class="{'vepop-static': isStatic}">
        <div class="vepop-bg" @click="handleClose" />
        <div class="vepop-center" :style="'width:' + width + 'px;'">
            <div class="vepop-content" :style="!isStatic ? 'margin-top: ' + topOffset + 'px;' : 'margin-top: 100px;'">
                <div class="vepop-header" v-if="header">
                    <label>{{ header }}</label>
                </div>
                <div class="vepop-slot" :style="maxHeight > 0 ? 'max-height: ' + maxHeight + 'px;' : ''" :class="{'vepop-slot-mh': maxHeight > 0}">
                    <slot></slot>
                </div>
                <div class="vepop-footer" :class="{'vepop-footer-sep': footerSeparator }">
                    <slot name="footer"></slot>
                </div>
                <div v-if="!hideCloseButton" class="vepop-close">
                    <vap-item @clicked="handleClose">
                        <div style="text-align: center; padding: 15px 0px;">
                            <label>{{ __('common_close') }}</label>
                        </div>
                    </vap-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 've-popup',
    props: {
        header: {
            type: String,
            default: ''
        },
        width: {
            type: Number,
            default: 400
        },
        maxHeight: {
            type: Number,
            default: 0
        },
        sub: {
            type: String,
            default: ''
        },
        hideCloseButton: {
            type: Boolean,
            default: false
        },
        footerSeparator: {
            type: Boolean,
            default: false
        },
        isStatic: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            topOffset: 0
        }
    },
    methods: {
        handleClose() {
            this.$emit("close", true);
        },
        handleScroll(event) {
            let doc = document.documentElement;
            this.topOffset = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        },
        handleKeyDown(event) {
            if (event.keyCode == 13) {
                this.$emit("enterkey", true);
            }else if (event.keyCode == 27) {
                this.$emit("close", true);
            }
        },
    },
    mounted() {
        let doc = document.documentElement;
        this.topOffset = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>
<style scoped>

.vepop {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    z-index: 10;
    box-sizing: border-box;
}


.vepop-static .vepop-content {
    position: fixed;
    width: 600px;
}

.vepop-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
}

.vepop-center {
    position: relative;
    padding-top: 100px;
    min-width: 100px;
    max-width: 90%;
    margin: 0 auto;
    min-height: 100px;
    box-sizing: border-box;
}

.vepop-content {
    position: relative;
    float: left;
    width: 100%;
    margin: 0 auto;
    min-height: 100px;
    overflow: visible;
    border: solid 2px black;
    border-radius: 5px;
    color: var(--text-title);
    background-color: var(--card-background);
    -webkit-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
    box-sizing: border-box;
}

@media screen and (max-width: 800px) {
    .vepop-center {
        width: 95%;
    }
    .vepop-content {
        
    }
}

.vepop-header {
    width: 100%;
    border-bottom: solid 1px var(--input-border);
    padding-top: 16px;
    padding-bottom: 8px;
    padding-left: 20px;
    box-sizing: border-box;
}
.vepop-header label {
    font-size: 20px;
}

.vepop-slot {

}
.vepop-slot-mh {
    overflow: auto;
}

.vepop-close {
    float: left;
    width: 100%;
    border-top: solid 1px var(--input-border);
}

.vepop-footer {
    /* border-top: solid 1px lightgray; */
}

.vepop-footer-sep {
    border-top: solid 1px lightgray;
}

</style>