<template>
    <div>
<!--         <div class="frmod-clh-mode" :style="'margin-left: ' + 30 + '%; width: calc(20% - 1px); background-color: ' + getBackgroundColor() + '; color: ' + getTextColor() + ';'">
            {{ getLevel() }}
        </div> -->
        <div class="frmod-clh card-shadow" :class="[
            {'frmod-cl-selected' : selected},
            {'frmod-cl-designer': designer },
        ]" @click="handleClick">

            <div class="frmod-clh-i" v-for="(option, i) in options[roleKey]" :key="'clh' + i" :style="'width: ' + widths[roleKey][i] + '%;'" :class="[{'frmod-clh-f': i > 0}, {'frmod-clh-xx': !visible[roleKey][i] }]">
                {{ option }}
                <div class="frmod-clh-tt" v-if="tooltips[i]">
                    <vap-icon :icon="'help-o'" small>
                        <template v-slot:tooltip>
                            <vap-tooltip :text="tooltips[i]" />
                        </template>
                    </vap-icon>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import logicHelper from '../../logicHelper';

export default {
    name: "frmod-chk-header-qa",
    props: {
        reference: { type: Object, default: null },
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        roleKey: { type: String, default: 'owner' },
    },
    data() {
        return {
            options: {
                owner: ['Data points', 'Initial QA Result', 'QA Comments', 'QA Complete?'],
                checker: ['Data points', 'Initial QA Result', 'QA Comments', 'QA Complete?'],
                editor: ['Data points', 'Initial QA Result', 'QA Comments', 'QA Complete?'],
                approver: ['Data points', 'Initial QA Result', 'QA Comments', 'QA Complete?'],
                provider: ['Data points', 'Initial QA Result', 'QA Comments', 'QA Complete?'],
            },
            visible: {
                owner: [true, true, true, true, true, true],
                checker: [true, true, true, true, true, true],
                editor: [true, true, true, true, true, true],
                approver: [true, true, true, true, true, true],
                provider: [true, true, true, true, true, true],
            },
            widths: {
                owner: [30, 10, 30, 10, 10, 10],
                checker: [30, 10, 30, 10, 10, 10],
                editor: [30, 10, 30, 10, 10, 10],
                approver: [30, 10, 30, 10, 10, 10],
                provider: [30, 10, 30, 10, 10, 10],
            },
            tooltips: [
                '',
                '',
                '',
                '',
                '',
                '',
            ]
        }
    },
    watch: {
        width: {
            deep: false,
            handler(val) {
                
            }
        }
    },
    methods: {
        
        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

        getLevel() {
            const level = logicHelper.getResourceValue(this.reference, 'chkLevel');
            if (level == 1) return 'SDD';
            if (level == 2) return 'CDD';
            if (level == 3) return 'EDD';
            return '- - -';
        },

        getBackgroundColor() {
            const level = logicHelper.getResourceValue(this.reference, 'chkLevel');
            if (level == 1) return 'var(--success-background)';
            if (level == 2) return 'var(--warning-background)';
            if (level == 3) return 'var(--error-background)';
            return 'var(--card-background)';
        },

        getTextColor() {
            const level = logicHelper.getResourceValue(this.reference, 'chkLevel');
            if (level == 1) return 'var(--success-text)';
            if (level == 2) return 'var(--warning-text)';
            if (level == 3) return 'var(--error-text)';
            return 'var(--text-strong)';
        }

    },
    mounted() {
        
    }
}

</script>
<style>

.frmod-clh-mode {
    padding: 2px 0px;
    font-size: 15px;
    text-align: center;
    color: var(--success-text);
    border: solid 1px lightgray;
    border-bottom: none;
    background-color: var(--success-background);
}

.frmod-clh {
    width: 100%;
    display: table-row;
    height: 100%;
    margin-bottom: 20px;
    border-radius: 4px;
    border: solid 1px lightgray;
    background-color: var(--card-background);
    box-sizing: border-box;
}

.frmod-clh-i {
    position: relative;
    display: table-cell;
    padding-left: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 500;
    vertical-align: middle;
    box-sizing: border-box;
}

.frmod-clh-f {
    border-left: solid 1px lightgray;
}

.frmod-clh-xx {
    color: var(--card-background);
}

.frmod-cl-selected {
    outline: solid 2px #238ccc;
}

.frmod-clh-tt {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

</style>
