<template>
<div class="vap-helptext">
    <vap-text-editor :value="text" />
</div>
</template>

<script>

export default {
    name: 'vap-helptext',
    components: {
        
    },
    props: {
        text: {
            type: [String, Number],
            default: ''
        },
        localized: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            
        }
    },
    async created() {
        
    }
}
</script>

<style scoped>

.vap-helptext {
    float: left;
    width: 100%;
    margin-bottom: -5px;
}

</style>
