import log from '../log';
import tools from '../tools';
const v1exceptions = {

    addException(root, source, method, exception, extraData) {

        const item = this.getException(root, source, method, exception, extraData);

        console.log('added exception', item);
        root.v1exceptions.push(item);
    },

    getException(root, source, method, exception, extraData) {
        if (exception) console.error(exception);

        //console.log('credentials', root.credentials);

        const environment = this.getBrowserData();
        //console.log('environment', environment);

        const item = {
            errorId: tools.getUniqueId().toUpperCase(),
            str: '',
            source: source,
            method: method,
            name: exception ? exception.name : '',
            message: exception ? exception.message : '',
            stack: exception ? exception.stack : '',
            log: log.list,
            companyId: root.credentials.cid,
            companyName: root.user.companyName,
            userId: root.credentials.uid,
            userName: root.getEmployeeName(root.credentials.uid),
            url: root.$route.fullPath,
            osName: environment.osName,
            browserName: environment.browserName,
            browserVersion: environment.browserVersion,
            envelopeId: extraData.envelopeId || '',
            extraData: extraData || {},
        };
        item.str = item.errorId.toLowerCase();
        item.str += ' ' + item.userName.toLowerCase();
        item.str += ' ' + item.companyId.toLowerCase();
        item.str += ' ' + item.companyName.toLowerCase();
        item.str += ' ' + item.envelopeId.toLowerCase();

        return item;
    },

    //#region Helpers -----------------------------------------------------------------------------------------------------

    getBrowserData() {
        try {
            var nVer = navigator.appVersion;
            var nAgt = navigator.userAgent;
            var browserName = navigator.appName;
            var fullVersion = '' + parseFloat(navigator.appVersion);
            var majorVersion = parseInt(navigator.appVersion, 10);
            var nameOffset, verOffset, ix;

            // In Opera, the true version is after "Opera" or after "Version"
            if ((verOffset = nAgt.indexOf("Opera")) != -1) {
                browserName = "Opera";
                fullVersion = nAgt.substring(verOffset + 6);
                if ((verOffset = nAgt.indexOf("Version")) != -1)
                    fullVersion = nAgt.substring(verOffset + 8);
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
                browserName = "Microsoft Internet Explorer";
                fullVersion = nAgt.substring(verOffset + 5);
            }
            // In Chrome, the true version is after "Chrome" 
            else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
                browserName = "Chrome";
                fullVersion = nAgt.substring(verOffset + 7);
            }
            // In Safari, the true version is after "Safari" or after "Version" 
            else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
                browserName = "Safari";
                fullVersion = nAgt.substring(verOffset + 7);
                if ((verOffset = nAgt.indexOf("Version")) != -1)
                    fullVersion = nAgt.substring(verOffset + 8);
            }
            // In Firefox, the true version is after "Firefox" 
            else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
                browserName = "Firefox";
                fullVersion = nAgt.substring(verOffset + 8);
            }
            // In most other browsers, "name/version" is at the end of userAgent 
            else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
                (verOffset = nAgt.lastIndexOf('/'))) {
                browserName = nAgt.substring(nameOffset, verOffset);
                fullVersion = nAgt.substring(verOffset + 1);
                if (browserName.toLowerCase() == browserName.toUpperCase()) {
                    browserName = navigator.appName;
                }
            }
            // trim the fullVersion string at semicolon/space if present
            if ((ix = fullVersion.indexOf(";")) != -1)
                fullVersion = fullVersion.substring(0, ix);
            if ((ix = fullVersion.indexOf(" ")) != -1)
                fullVersion = fullVersion.substring(0, ix);

            majorVersion = parseInt('' + fullVersion, 10);
            if (isNaN(majorVersion)) {
                fullVersion = '' + parseFloat(navigator.appVersion);
                majorVersion = parseInt(navigator.appVersion, 10);
            }

            var userAgent = window.navigator.userAgent,
            platform = window.navigator?.userAgentData?.platform ?? window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'Mac OS';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'iOS';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'Windows';
            } else if (/Android/.test(userAgent)) {
                os = 'Android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'Linux';
            }

            return {
                browserName: browserName,
                browserVersion: majorVersion.toString(),
                osName: os || 'Unknown OS'
            };
        }catch (ex) {
            return {
                browserName: 'Unknown',
                browserVersion: '0',
                osName: 'Unknown OS'
            };
        }
    }

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default v1exceptions;