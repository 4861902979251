<template>
<div>
    

    <AdminCompany v-if="!loading && companyId" :id="companyId" />

</div>
</template>
<script>
import api from '../portalapi';



import AdminCompany from '../v1admin/AdminCompany.vue';

export default {
    name: 'AdminV1',
    components: {
        AdminCompany
    },
    data() {
        return {
            loading: true,
            
            companyId: ''
        }
    },
    methods: {
        
        

    },
    async created() {
        this.companyId = this.$route.query.id;
        console.log('id', this.companyId);

        this.loading = false;
    }
}
</script>
<style scoped>


</style>