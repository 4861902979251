<template>
    <div class="slim-page" v-if="loadingComplete">

        <div class="kyc-landing-logo" style="margin-top: 150px;">
            <img :src="$root.config.kycs[0].landing.logo" />
        </div>

        <div class="kyc-landing-header">
            <div class="kyc-landing-header-center">
                <div :class="aborted ? 'kyc-landing-icon-error' : 'kyc-landing-icon'">
                    <vap-icon :icon="aborted ? 'cancel' : 'check'" :color="aborted ? 'var(--error-text)' : 'var(--primary-contrast)'" />
                </div>
                <div class="kyc-landing-text">
                    <label>{{ ___(this, kycLandingKey + '_head') }}</label>
                </div>
            </div>
        </div>

        <vap-card style="margin-top: 40px;">
            <vap-text-editor :value="___(this, kycLandingKey)" />
        </vap-card>

        <div class="kyc-landing-footer">
            <span>{{ ___(this, 'kyc_landing_footer') }}</span><span><a :href="$root.config.kycs[0].landing.redirect">{{ ___(this, 'kyc_landing_link') }}</a></span>
        </div>

    </div>
</template>

<script>
import { auth, envelopes, oidc } from '@verified/libvf';
import backend from '../backend';
import api from '../portalapi';
import locale from '../locale';
import log from '../log';

export default {
    name: 'KYCLanding',
    components: {

    },
    data() {
        return {
            aborted: false,
            kycLandingKey: 'kyc_landing_signed',
            loadingComplete: false,
            translations: {},
            lang: 'nb_NO'
        }
    },
    methods: {
        setLang() {
            this.$root.locale = locale.getLocale(this.$root.config.locales, this.lang);
        }
    },
    async created() {
        this.$parent.setLoading(true, this.__('loading') + '...');
        let envelopeId = this.$route.params.envelopeId;
        let recipientId = this.$route.params.recipientId;
        log.v('KYC Landing created, eid=' + envelopeId + ', rid=' + recipientId);


        if (recipientId != 'editor') {

            // User selected direct to sign so we have no recipient id, but there should only be one signer recipient, so find that id
            if (recipientId == 'signer') {
                for (const i in this.$parent.loadedEnvelope.content.recipients) {
                    if (this.$parent.loadedEnvelope.content.recipients[i].role.action == 'sign') {
                        recipientId = this.$parent.loadedEnvelope.content.recipients[i].id;
                        console.log('recipient id set to', recipientId);
                    }
                }
            }

            let recipient = {};
            for (const i in this.$parent.loadedEnvelope.content.recipients) {
                if (this.$parent.loadedEnvelope.content.recipients[i].id == recipientId) {
                    recipient = this.$parent.loadedEnvelope.content.recipients[i];
                    console.log('recipient', recipient);
                }
            }


            this.lang = recipient.language;
            const self = this;
            setTimeout(function() {
                self.setLang();
            }, 500);
            

            for (const i in recipient.signatures) {
                if (recipient.signatures[i].status == 'rejected') {
                    this.aborted = true;
                    this.kycLandingKey = 'kyc_landing_aborted';
                    await api.updateCustomerStatus(this.$parent.loadedEnvelope.userdata.credentials.id, {
                        status: 'f-kyc-q'
                    });
                }
            }

            if (this.$parent.loadedEnvelope.content.completed) {
                console.log('everyone has signed');
                if (!this.aborted) {
                    await api.updateCustomerStatus(this.$parent.loadedEnvelope.userdata.credentials.id, {
                        status: this.$parent.loadedEnvelope.userdata.config.nextStat,
                        expires: -1
                    });
                    console.log('status set to ' + this.$parent.loadedEnvelope.userdata.config.nextStat);
                }
            }

        }else {
            this.kycLandingKey = 'kyc_landing_editor';
        }

        
        this.$parent.setLoading(true, this.__('loading') + '...');
        const response = await api.getTranslations('kyc');
        //console.log('res', response);
        if (response.texts) {
            this.translations = response.texts;
        }else {
            this.translations = {};
        }

        this.loadingComplete = true;
        this.$parent.setLoading(false);
    }
}
</script>
<style scoped>

.kyc-landing-logo {
    float: left;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}
.kyc-landing-logo img {
    width: 360px;
}

.kyc-landing-header {
    float: left;
    width: 100%;
    margin-top: 40px;
}
.kyc-landing-header-center {
    display: flex;
    width: fit-content;
    margin: 0 auto;
}
.kyc-landing-icon {
    width: 45px; height: 45px;
    padding-top: 5px;
    padding-left: 5px;
    border-radius: 50%;
    background-color: var(--primary);
    box-sizing: border-box;
}
.kyc-landing-icon-error {
    width: 45px; height: 45px;
    padding-top: 4px;
    padding-left: 4px;
    border-radius: 50%;
    background-color: var(--error-background);
    box-sizing: border-box;
}
.kyc-landing-text {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 20px;
}
.kyc-landing-footer {
    float: left;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}

</style>
