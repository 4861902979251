<template>

<div class="vap-form" ref="form" :class="[{'vap-form-np':noPadding}]">

    <slot></slot>
    
</div>

</template>
<script>

export default {
    name: 'vap-form',
    props: {
        header: {
            type: String,
            default: ''
        },
        noPadding: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        validate() {
            let valid = true;
            //console.log('validating');


            //console.log('ch', this.$children);

            for (let i in this.$children) {

                if (this.$children[i].$vnode.tag.includes('vap-card')) {
                    
                    let val = this.loopCard(this.$children[i]);
                    if (!val) valid = false;

                }else if (this.$children[i].$vnode.tag.includes('radio-card')) {
                    
                    if (!this.validateInput(this.$children[i])) valid = false;

                }else if (this.$children[i].$vnode.tag.includes('FormRenderer')) {
                    
                    for (let j in this.$children[i].$children[0].$children) {

                        if (this.$children[i].$children[0].$children[j].$vnode.tag.includes('vap-card')) {
                            
                            let val = this.loopCard(this.$children[i].$children[0].$children[j]);
                            if (!val) valid = false;

                        }

                    }

                }

            }

            return valid;
            /* this.$refs.form.classList.add("was-validated")
            return this.$refs.form.checkValidity() */
        },

        loopCard(card) {
            let valid = true;

            for (let i in card.$children) {

                if (card.$children[i].$vnode.tag.toLowerCase().includes('inputs')) {
                    for (let j in card.$children[i].$children) {
                        let val = this.validateRow(card.$children[i].$children[j]);
                        if (!val) valid = false;
                    }
                }else if (card.$children[i].$vnode.tag.toLowerCase().includes('vap-row')) {
                    let val = this.validateRow(card.$children[i]);
                    if (!val) valid = false;
                }

            }

            return valid;
        },


        validateRow(row) {
            let valid = true;
            for (let i in row.$children) {
                for (let j in row.$children[i].$children) {
                    let hasval = row.$children[i].$children[j].validation != undefined;
                    if (hasval) {
                        // -- Validate input --------------------------------------
                        if (!this.validateInput(row.$children[i].$children[j])) valid = false;
                        // --------------------------------------------------------
                    }else if (row.$children[i].$children[j].$vnode.tag.includes('phone')) {
                        let val = this.validatePhone(row.$children[i].$children[j]);
                        if (!val) valid = false;
                    }
                }
            }
            return valid;
        },

        validatePhone(combine) {
            let valid = true;
            for (let i in combine.$children) {
                let hasval = combine.$children[i].validation != undefined;
                if (hasval) {
                    // -- Validate input --------------------------------------
                    if (!this.validateInput(combine.$children[i])) valid = false;
                    // --------------------------------------------------------
                }
            }
            return valid;
        },

        validateInput(input) {
            if (input.$vnode.tag.includes('file-upload')) return true;
            input.validate();
            let val = input.validation.valid;
            input.validation.show = true;
            return val;
        }

    }
}
</script>
<style>

.vap-form {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.vap-form-np {
    padding: 0px;
}


@media only screen and (max-width: 600px) {

    .vap-form {
        padding: 0px;
    }

}

</style>