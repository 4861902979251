<template>
<div>

    <vap-infocard :data="indicatorHelpBaml" style="float: left; width: 100%;" v-if="company.source == 'bankidaml'" />
    <vap-infocard :data="indicatorHelpTrap" style="float: left; width: 100%;" v-if="company.source == 'trapets-bisnode'" />

    <EmptyIcon :icon="'orgchart'" :text="__t('lookup-empty')" v-if="!company.lookup && $root.config.portal.useV1" />

    <div v-if="company.lookup || !$root.config.portal.useV1">

        <vap-form-divider :left="55" :right="45">
            <template v-slot:left>

                <div v-if="company.source == 'bankidaml' && $root.config.company.mapNorway" class="flw100" style="margin-bottom: 5px;">
                    <vap-label :text="'Ownership map'" header2 style="width: 100%; margin-bottom: 10px;" />
                    <div class="flw100" style="margin-top: -60px; margin-bottom: 160px;" v-if="loadingMap">
                        <CenteredSpinner />
                    </div>
                    <div v-if="!loadingMap">
                        <OwnershipMap rounded preview :mapData="mapData" />
                    </div>
                    <div v-if="!loadingMap" style="">
                        <span style="font-size: 14px; font-style: italic; color: var(--text-weak);">
                            {{ 'The ownership map is currently in the testing phase. Please report any errors or feedback.' }}
                        </span>
                        <label style="color: var(--link-text); float: right; margin-top: 6px;">{{ 'Show map in fullscreen' }}</label>
                    </div>
                </div>

                <div v-if="company.source != 'chouse'">
                    <vap-label :text="__('ki_head_shareholders')" header2 style="margin-bottom: 10px;" />
                    <vap-card compactList v-for="(sh, i) in company.shareholders" :key="'sh' + i">
                        <div class="own-sh">
                            <div class="own-sh-icon">
                                <vap-icon big :icon="getShareholderIcon(sh)" :color="'var(--accent)'" />
                            </div>
                            <div class="own-sh-content">
                                <div class="own-sh-head">
                                    <label>{{ sh.name }}</label>
                                </div>
                                <div class="own-sh-sub">
                                    <label>{{ sh.orgnr || sh.dateOfBirth || __('ki_missing_shareholder_data') }}</label>
                                </div>
                            </div>
                            <div class="own-sh-right">
                                <label>{{ formatShare(sh) + ' %' }}</label>
                            </div>
                        </div>
                    </vap-card>
                </div>

                <div v-if="company.source == 'chouse'">
                    <vap-label :text="'Corporate entities with significant control'" header2 style="margin-bottom: 10px;" />
                    <vap-card compactList v-for="(sh, i) in company.controlEntities" :key="'ce' + i">
                        <div class="own-sh">
                            <div class="own-sh-icon">
                                <vap-icon big :icon="getShareholderIcon(sh)" :color="'var(--accent)'" />
                            </div>
                            <div class="own-sh-content">
                                <div class="own-sh-head">
                                    <label>{{ sh.name }}</label>
                                </div>
                                <div class="own-sh-sub">
                                    <label>{{ sh.orgnr || sh.dateOfBirth || __('ki_missing_shareholder_data') }}</label>
                                </div>
                            </div>
                            <div class="own-sh-right">
                                <label>{{ formatShare(sh) + ' %' }}</label>
                            </div>
                        </div>
                    </vap-card>
                </div>

                <div v-if="company.subsidiariesAvailable">
                    <vap-label :text="'Subsidiaries'" header2 style="margin-bottom: 10px;" />
                    <vap-card compactList v-for="(sub, i) in company.subsidiaries" :key="'ce' + i">
                        <div class="own-sh">
                            <div class="own-sh-icon">
                                <vap-icon big :icon="'company'" :color="'var(--accent)'" />
                            </div>
                            <div class="own-sh-content">
                                <div class="own-sh-head">
                                    <label>{{ sub.name }}</label>
                                </div>
                                <div class="own-sh-sub">
                                    <label>{{ sub.orgnr }}</label>
                                </div>
                            </div>
                        </div>
                    </vap-card>
                </div>


                <vap-label :text="__('ki_val_ownershipNace')" header2 style="margin-top: 20px; margin-bottom: 10px;" v-if="company.ownership.naceCodes.length > 0">
                    <template v-slot:actions>
                        <vap-icon :icon="'help-o'">
                            <template v-slot:tooltip><vap-tooltip :localized="'ki_desc_ownershipNace'"></vap-tooltip></template>
                        </vap-icon>
                    </template>
                </vap-label>

                <vap-card compactList v-for="(nace, i) in company.ownership.naceCodes" :key="'nc' + i">
                    <div class="own-nace">
                        <div class="own-nace-left">
                            <label>{{ nace.code }}</label>
                        </div>
                        <div class="own-nace-content">
                            <label>{{ nace.description }}</label>
                        </div>
                        <div class="own-nace-right">
                            <label>{{ formatShare(nace) + '%' }}</label>
                        </div>
                    </div>
                </vap-card>

            </template>
            <template v-slot:right>

                <vap-label :text="company.source == 'bankidaml' == __('ki_head_indicators') || __('screening_role_rrhs')" header2 style="margin-bottom: 10px;"  />

                <vap-card list :header="__('ki_head_identified_shares')" v-if="company.ownership.indicatorsAvailable">

                    <div class="own-pie" v-if="!loading">
                        <div class="own-pie-left">

                            <div class="own-pie-lbl">
                                <div class="own-pie-lbl-color" style="background-color: var(--accent);">
                                    <label style="color: var(--accent-contrast);">{{ '?' }}</label>
                                    <div class="own-pie-lbl-tt">
                                        <vap-tooltip :localized="'ki_desc_sumIdentifiedShareholders'" />
                                    </div>
                                </div>
                                <div class="own-pie-lbl-text">
                                    <label style="color: var(--text-title);">{{ __('ki_identified') + ' ' }}</label>
                                    <label style="color: var(--text-title); font-weight: bold;">{{ company.ownership.knowledge.sumIdentifiedShareholders }}</label>
                                    <label style="color: var(--text-weak); font-weight: bold;">{{ '%' }}</label>
                                </div>
                            </div>
                            <div class="own-pie-lbl">
                                <div class="own-pie-lbl-color" style="background-color: var(--val-error);">
                                    <label style="color: white;">{{ '?' }}</label>
                                    <div class="own-pie-lbl-tt">
                                        <vap-tooltip :localized="'ki_desc_sumUnidentifiedShareholders'" />
                                    </div>
                                </div>
                                <div class="own-pie-lbl-text">
                                    <label style="color: var(--text-title);">{{ __('ki_unidentified') + ' ' }}</label>
                                    <label style="color: var(--text-title); font-weight: bold;">{{ company.ownership.knowledge.sumUnidentifiedShareholders }}</label>
                                    <label style="color: var(--text-weak); font-weight: bold;">{{ '%' }}</label>
                                </div>
                            </div>
                            <div class="own-pie-lbl">
                                <div class="own-pie-lbl-color" style="background-color: var(--text-disabled);">
                                    <label style="color: white;">{{ '?' }}</label>
                                    <div class="own-pie-lbl-tt">
                                        <vap-tooltip :localized="'ki_desc_sumUnknownShareholders'" />
                                    </div>
                                </div>
                                <div class="own-pie-lbl-text">
                                    <label style="color: var(--text-title);">{{ __('ki_unknown') + ' ' }}</label>
                                    <label style="color: var(--text-title); font-weight: bold;">{{ company.ownership.knowledge.sumUnknownShareholders }}</label>
                                    <label style="color: var(--text-weak); font-weight: bold;">{{ '%' }}</label>
                                </div>
                            </div>

                        </div>
                        <div class="own-pie-right">
                            <PieChart :data="pieData" :size="150" />
                        </div>
                    </div>

                    <div style="margin-top: 20px; padding-left: 0px; padding-right: 20px;">
                        <InfoItem topBorder
                            :descriptionKey="'ki_val_numberOfUnidentifiedShareholders'"
                            :tooltipKey="'ki_desc_numberOfUnidentifiedShareholders'"
                            :value="company.ownership.knowledge.numberOfUnidentifiedShareholders"
                            :unit="''"
                        />
                    </div>
                    
                </vap-card>


                <vap-card list :header="__('ki_head_beneficial')">
                    <div style="margin-top: -6px; padding-left: 10px; padding-right: 20px;">
                        <div class="own-itm" v-for="(beneficial, i) in company.beneficials" :key="'b' + i">
                            <div class="own-itm-lbl"><label>{{ getRrhName(beneficial) }}</label><label>{{ getRrhIdentity(beneficial) }}</label></div>
                            <div class="own-itm-val"><label>{{ formatShare(beneficial) + '%' }}</label></div>
                        </div>
                        <div v-if="company.beneficials.length == 0" style="margin-bottom: 6px;">
                            <label>{{ __('ki_no_beneficial') }}</label>
                        </div>
                    </div>
                    <div style="margin-top: 20px; margin-bottom: 6px;" v-if="company.source == 'trapets-bisnode'">
                        <InfoItem topBorder
                            :descriptionKey="'ki_val_beneficials_available'"
                            :tooltipKey="'ki_desc_beneficials_available'"
                            :value="getYesNo(company.ownership.beneficialsAvailable)"
                            :unit="''"
                        />
                        <InfoItem
                            :descriptionKey="'ki_val_beneficials_suspiciuos'"
                            :tooltipKey="'ki_desc_beneficials_suspiciuos'"
                            :value="getYesNo(company.ownership.beneficialsSuspicious)"
                            :unit="''"
                        />
                    </div>
                </vap-card>


                <vap-card list :header="__('ki_head_key_indicators')" v-if="company.ownership.indicatorsAvailable">
                    <div style="margin-top: -6px; padding-left: 10px; padding-right: 20px;">
                        <InfoItem topBorder
                            :descriptionKey="'ki_val_numberOfShareholdersDirect'"
                            :tooltipKey="'ki_desc_numberOfShareholdersDirect'"
                            :value="company.ownership.keyIndicators.numberOfShareholdersDirect"
                            :unit="''"
                        />
                        <InfoItem
                            :descriptionKey="'ki_val_numberOfShareholdersLegal'"
                            :tooltipKey="'ki_desc_numberOfShareholdersLegal'"
                            :value="company.ownership.keyIndicators.numberOfShareholdersLegal"
                            :unit="''"
                        />
                        <InfoItem
                            :descriptionKey="'ki_val_numberOfShareholdersTotal'"
                            :tooltipKey="'ki_desc_numberOfShareholdersTotal'"
                            :value="company.ownership.keyIndicators.numberOfShareholdersTotal"
                            :unit="''"
                        />
                        <InfoItem
                            :descriptionKey="'ki_val_maxShareHolderDistance'"
                            :tooltipKey="'ki_desc_maxShareHolderDistance'"
                            :value="company.ownership.keyIndicators.maxShareHolderDistance"
                            :unit="''"
                        />
                    </div>
                </vap-card>


                <vap-card list :header="__('ki_head_self_ownership')" v-if="company.ownership.indicatorsAvailable">
                    <div style="margin-top: -6px; padding-left: 10px; padding-right: 20px;">
                        <InfoItem topBorder
                            :descriptionKey="'ki_val_companySelfOwnership'"
                            :tooltipKey="'ki_desc_companySelfOwnership'"
                            :value="company.ownership.selfOwnership.companySelfOwnership"
                            :unit="''"
                        />
                        <InfoItem
                            :descriptionKey="'ki_val_numberOfSelfOwnedShareholders'"
                            :tooltipKey="'ki_desc_numberOfSelfOwnedShareholders'"
                            :value="company.ownership.selfOwnership.numberOfSelfOwnedShareholders"
                            :unit="''"
                        />
                    </div>
                </vap-card>


                <vap-card list :header="__('ki_head_voting_power')" v-if="company.ownership.indicatorsAvailable">
                    <div style="margin-top: -6px; padding-left: 10px; padding-right: 20px;">
                        <InfoItem topBorder
                            :descriptionKey="'ki_val_numberOfHighVpLowIntegrated'"
                            :tooltipKey="'ki_desc_numberOfHighVpLowIntegrated'"
                            :value="company.ownership.votingPower.numberOfHighVpLowIntegrated"
                            :unit="''"
                        />
                        <InfoItem
                            :descriptionKey="'ki_val_numberOfShareClasses'"
                            :tooltipKey="'ki_desc_numberOfShareClasses'"
                            :value="company.ownership.votingPower.numberOfShareClasses"
                            :unit="''"
                        />
                        <InfoItem
                            :descriptionKey="'ki_val_numberOfShareholdersManyShareClasses'"
                            :tooltipKey="'ki_desc_numberOfShareholdersManyShareClasses'"
                            :value="company.ownership.votingPower.numberOfShareholdersManyShareClasses"
                            :unit="''"
                        />
                        <InfoItem
                            :descriptionKey="'ki_val_shareHoldersHaveMultipleShareClasses'"
                            :tooltipKey="'ki_desc_shareHoldersHaveMultipleShareClasses'"
                            :value="getYesNo(company.ownership.votingPower.shareHoldersHaveMultipleShareClasses)"
                            :unit="''"
                        />
                    </div>
                </vap-card>

                

            </template>
        </vap-form-divider>

        <div class="own-sources">

        </div>

    </div>
    
</div>
</template>

<script>
import dataModel from '@/dataModel';
import mapNoApi from '../../v1data/mapNoApi';
import mapNoHelper from '../../v1data/mapNoHelper';

import PieChart from '@/components/stats/PieChart';
import InfoItem from '@/components/items/InfoItem';

import EmptyIcon from '../../v1ui/EmptyIcon.vue';
import CenteredSpinner from '../../v1ui/CenteredSpinner.vue';

import OwnershipMap from '../../v1data/OwnershipMap.vue';

export default {
    name: 'TabOwnership',
    components: {
        PieChart,
        InfoItem,
        EmptyIcon,
        CenteredSpinner,
        OwnershipMap,
    },
    props: {
        company: { type: Object, default: () => { return {} } }
    },
    data() {
        return {
            loading: true,
            loadingMap: true,
            appList: [],
            pieData: null,
            indicatorHelpBaml: [{ icon: 'info', key: 'ki_info_baml' }],
            indicatorHelpTrap: [{ icon: 'info', key: 'ki_info_trap' }],
            mapResult: null,
            mapData: {},
        }
    },
    methods: {
        
        getShareholderIcon(shareholder) {
            if (shareholder.type == 'person') return 'person';
            if (shareholder.type == 'org') return 'company';
            return 'help-o';
        },

        getRrhName(rrh) {
            if (rrh.identified) {
                return rrh.firstName + ' ' + rrh.lastName;
            }
            return rrh.name;
        },

        getRrhIdentity(rrh) {
            if (rrh.identified) {
                return ' (' + rrh.dateOfBirth + ')';
            }
            return ' (' + this.__('common_unidentified') + ')';
        },

        getRrhShare(rrh) {
            return dataModel.getRrhShare(rrh);
        },

        formatShare(item) {
            if (item.shareRange) {
                return dataModel.getRrhShare(item);
            }else {
                try {
                    const share = item.share || item.integratedShare;
                    return Number(share).toFixed(2);
                }catch (e) {
                    return item.share;
                }
            }
        },

        getYesNo(value) {
            if (value == 'Y') return this.__('common_yes');
            if (value == 'N') return this.__('common_no');
            return this.__('common_unknown');
        },

        async getOwnershipMap() {
            this.loadingMap = true;

            if (!this.mapResult) this.mapResult = await mapNoApi.getCompany(this.company.orgnr);
            console.log('result', this.mapResult);

            mapNoHelper.processCompany(this.mapResult, this);

            console.log('mapDa', this.mapData);

            this.loadingMap = false;
        },

    },
    async created() {
        if (this.company.lookup) {

            this.pieData = [];

            this.pieData.push({
                label: 'lbl1',
                color: this.$root.getColor('accent', false),
                value: this.company.ownership.knowledge.sumIdentifiedShareholders
            });
            this.pieData.push({
                label: 'lbl2',
                color: this.$root.getColor('val-error', false),
                value: this.company.ownership.knowledge.sumUnidentifiedShareholders
            });
            this.pieData.push({
                label: 'lbl3',
                color: this.$root.getColor('text-disabled', false),
                value: this.company.ownership.knowledge.sumUnknownShareholders
            });

        }

        if (this.company.source == 'bankidaml' && this.$root.config.company.mapNorway) {
            this.getOwnershipMap();
        }
        this.loading = false;
    }
}
</script>
<style scoped>

.own-sh {
    display: flex;
    margin-top: -6px;
}

.own-sh-icon {
    
}

.own-sh-content {
    flex-grow: 100;
    margin-left: 10px;
}

.own-sh-right {
    flex-shrink: 0;
    margin-top: 8px;
    font-size: 17px;
    font-weight: bold;
    color: var(--text-title);
}

.own-sh-head {
    font-weight: bold;
    color: var(--text-accent);
}
.own-sh-sub {
    color: var(--text-weak);
}


.own-pie {
    display: flex;
    margin-top: -10px;
}
.own-pie-left {
    flex-grow: 100;
}
.own-pie-right {
    flex-shrink: 0;
}

.own-pie-lbl {
    display: flex;
}
.own-pie-lbl-color {
    position: relative;
    width: 24px;
    height: 24px;
    padding-top: 3px;
    padding-left: 7px;
    font-weight: bold;
    margin-bottom: 20px;
    border-radius: 3px;
    box-sizing: border-box;
}
.own-pie-lbl-text {
    margin-top: 2px;
    margin-left: 10px;
    font-size: 17px;
}
.own-pie-lbl-tt {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
}



.own-nace {
    display: flex;
    margin-top: -6px;
}

.own-nace-left {
    flex-shrink: 0;
    font-weight: bold;
    color: var(--text-weak);
}

.own-nace-content {
    flex-grow: 100;
    margin-left: 8px;
}

.own-nace-right {
    flex-shrink: 0;
    font-size: 17px;
    font-weight: bold;
    color: var(--text-title);
}




.own-itm {
    display: flex;
    margin-bottom: 6px;
}
.own-itm-lbl {
    flex-grow: 100;
}
.own-itm-val {
    font-weight: bold;
    color: var(--text-title);
}



.own-sources {

}

</style>
