import personHelper from '../helpers/personhelper';
const v1personHelper = {

    isSamePerson(person1, person2) {
        return personHelper.isSamePerson(person1, person2);
    },

    formatAddressString(root, address) {
        return root.companyTitleCase(address.street) + ', ' + address.zip + ' ' + root.companyTitleCase(address.city) + ' - ' + root.getCountryName(address.country);
    },

    //#region Roles -------------------------------------------------------------------------------------------------------

    hasRole(person, roleId) {
        for (const i in person.roles) {
            const role = person.roles[i];
            if (role.roleName) {
                if(role.roleName == roleId) {
                    return true;
                }
                return false;
            }
            
            if (role.id == roleId) {
                return true;
            }
        }
        return false;
    },

    getRolePercentage(person, roleId) {
        let kycMatch = false;
        let share = '';
        let amlP = undefined;
        let kycP = undefined;
        for (const i in person.roles) {
            if (person.roles[i].id == roleId) {
                if (!kycMatch || person.roles[i].source == 'kyc') {
                    if (person.roles[i].share) share = person.roles[i].share;
                }
                if (person.roles[i].source == 'kyc') kycP = person.roles[i].share;
                if (person.roles[i].source == 'org') amlP = person.roles[i].share;
            }
        }
        let op = '';
        if (kycP && amlP) {
            if (kycP > amlP) {
                op = '+';
            }else if (kycP < amlP) {
                op = '-';
            }
        }
        return share == '' ? '' : op + share + '%';
    },

    getDisplayRoles(person, root) {
        const list = [];
        for (const i in person.roles) {
            let match = false;
            for (const j in list) {
                if (list[j].id == person.roles[i].id) {
                    match = true;
                    if (!list[j].confirmed) {
                        list[j].confirmed = person.roles[i].source == 'kyc';
                        if (person.roles[i].source == 'kyc' && person.roles[i].share) list[j].share = person.roles[i].share;
                    }
                }
            }
            if (!match) {
                list.push({
                    id: person.roles[i].id,
                    share: person.roles[i].share,
                    confirmed: person.roles[i].source == 'kyc',
                    roleName: person.roles[i].roleName || "",
                    roleOther: person.roles[i].roleOther,
                    removed: person.roles[i].removed || false
                });
            }
        }
        let str = '';
        for (const i in list) {
            if (i > 0) str += '</span>, ';
            if (list[i].id == 'role_kyc_pep') {
                str += '<span class="drw">';
            }else if (list[i].confirmed) {
                str += '<span class="drc">';
            }else if (list[i].removed) {
                str += '<span class="drr">';
            }else {
                str += '<span class="dru">';
            }
            
            if (list[i].roleName) {
                str += list[i].roleName;
            }else if (list[i].id == 'common_other') {
                str += list[i].roleOther;
            }else {
                str += root.__(list[i].id);
                if (list[i].id == 'role_rrh' && list[i].share) {
                    str += ' (' + list[i].share + '%)';
                }
                if (list[i].id == 'role_shareholder') str += ' (' + list[i].share + '%)';
            }
        }
        if (person.roles.length == 0) {
            str += '<span class="drw">' + root.__('role_none');
        }
        str += '</span>';
        return str;
    },

    getTextRoles(person, root) {
        const list = [];
        for (const i in person.roles) {
            let match = false;
            for (const j in list) {
                if (list[j].id == person.roles[i].id) {
                    match = true;
                    if (!list[j].confirmed) {
                        list[j].confirmed = person.roles[i].source == 'kyc';
                        if (person.roles[i].source == 'kyc') list[j].percentage = person.roles[i].percentage;
                    }
                }
            }
            if (!match) {
                list.push({
                    id: person.roles[i].id,
                    percentage: person.roles[i].percentage,
                    confirmed: person.roles[i].source == 'kyc',
                    roleOther: person.roles[i].roleOther,
                    removed: person.roles[i].removed || false
                });
            }
        }
        let str = '';
        for (const i in list) {
            if (i > 0) str += ', ';
            if (list[i].id == 'common_other') {
                str += list[i].roleOther;
            }else {
                str += root.__(list[i].id);
                if (list[i].id == 'screening_role_rrh' && list[i].percentage) str += ' (' + list[i].percentage + '%)';
                if (list[i].id == 'screening_role_shareholder') str += ' (' + list[i].percentage + '%)';
            }
        }
        return str;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    getScreeningResult(root, person) {
        let str = 'UNKNOWN STATUS';

        if (!person.screening.done) str = root.__t('screening-not-completed');

        if (!person.screening.isPep && !person.screening.hasSanction) str = root.__('hit_none');
        if (person.screening.isPep && !person.screening.hasSanction) str = root.__('hit_pep');
        if (person.screening.hasSanction && !person.screening.isPep) str = root.__('hit_sanction');
        if (person.screening.hasSanction && person.screening.isPep) str = 'Hit on PEP and SANCTION';

        if (person.screening.falsePositive) {
            if (person.screening.fpParams.reason == 'fpositive') {
                str += ' - ' + root.__t('screening-wrong-person');
            }else if (person.screening.fpParams.reason == 'inactive') {
                str += ' - ' + root.__t('screening-inactive');
            }
        }

        return str;
    },


    //#region IDC Match Indicator -----------------------------------------------------------------------------------------

    getIdcMatchIndicator(person) {
        if (!person.idc.done) return 0;

        //console.log('PERSON', person);

        const person1 = {
            firstName: person.info.firstName,
            lastName: person.info.lastName,
            dateOfBirth: person.info.dateOfBirth,
        };
        const person2 = {
            firstName: person.idc.firstName,
            lastName: person.idc.lastName,
            dateOfBirth: person.idc.dateOfBirth,
        };

        return this.getMatchIndicator(person1, person2);
    },

    getMatchIndicator(person1, person2) {
        let name1 = '';
        let dob1 = '';
        let name2 = '';
        let dob2 = '';

        if (person1.firstName) {
            name1 = person1.firstName.toLowerCase() + ' ' + person1.lastName.toLowerCase();
            dob1 = person1.dateOfBirth;
        }else if (person1.info.firstName) {
            name1 = person1.info.firstName.toLowerCase() + ' ' + person1.info.lastName.toLowerCase();
            dob1 = person1.info.dateOfBirth;
        }
        if (person2.firstName) {
            name2 = person2.firstName.toLowerCase() + ' ' + person2.lastName.toLowerCase();
            dob2 = person2.dateOfBirth;
        }else if (person2.info && person2.info.firstName) {
            name1 = person2.info.firstName.toLowerCase() + ' ' + person2.info.lastName.toLowerCase();
            dob1 = person2.info.dateOfBirth;
        }

        const names1 = name1.split(' ');
        const names2 = name2.split(' ');
        let matchCount = 0;
        for (const i in names1) {
            for (const j in names2) {
                //console.log('comparing ' + names1[i] + ' to ' + names2[j]);
                if (names1[i] == names2[j]) {
                    matchCount += 1;
                }
            }
        }
        //console.log('match on ' + matchCount + ' of ' + Math.max(names1.length, names2.length) + ' names');

        const matchDob = dob1 == dob2;
        if (matchDob) {
            //console.log('match on DOB');

            if (matchCount >= Math.max(names1.length, names2.length)) {
                return 1;
            }else if (matchCount > 1) {
                return 2;
            }else if (matchCount == 1) {
                return 3;
            }else {
                return 4;
            }

        }else {
            //console.log('no match on DOB');

            if (matchCount >= Math.max(names1.length, names2.length)) {
                return 5;
            }else if (matchCount > 1) {
                return 6;
            }else if (matchCount == 1) {
                return 7;
            }

        }

        return 0;
    },

    getIdcMatchDescription(person) {
        const indicator = this.getIdcMatchIndicator(person);
        return this.getMatchDescription(indicator);
    },

    getMatchDescription(indicator) {
        switch (indicator) {
            case 7: return 'weak match on name only';
            case 6: return 'partial match on name only';
            case 5: return 'full match on name only';
            case 4: return 'match on date of birth only';
            case 3: return 'weak match on name and date of birth';
            case 2: return 'partial match on name and date of birth';
            case 1: return 'full match on name and date of birth';
        }
        return 'no match';
    },

    getAmsResultText(root, web, locale, archive) {
        if (web.done || archive) {
            let hitLevel = 'ok';
            let someCount = 0; const someTexts = [];
            let hitCount = 0; const hitTexts = [];
            for (const i in web.keywords) {
                let name = web.keywords[i].key;
                for (const j in root.config.adverseMedia.keywords) {
                    if (root.config.adverseMedia.keywords[j].key == web.keywords[i].key) name = root.config.adverseMedia.keywords[j].locale[locale];
                }
                if (web.keywords[i].result == 'some') {
                    if (hitLevel == 'ok') hitLevel = 'some';
                    someCount += 1;
                    someTexts.push(name);
                }
                if (web.keywords[i].result == 'hits') {
                    if (hitLevel == 'ok') hitLevel = 'hits';
                    hitCount += 1;
                    hitTexts.push(name);
                }
            }
            if (hitLevel == 'ok') {
                return root.v1translations['ams-count'][locale] + web.keywords.length + root.v1translations['ams-keywords'][locale];
            }else if (hitLevel == 'some') {
                if (someCount == 1) {
                    return root.v1translations['ams-unclear'][locale] + someTexts[0];
                }else if (someCount == 2) {
                    return root.v1translations['ams-unclear'][locale] + someTexts[0] + root.v1translations['ams-and'][locale] + someTexts[1];
                }else {
                    return root.v1translations['ams-unclear'][locale] + someTexts[0] + root.v1translations['ams-and'][locale] + (someCount - 1) + root.v1translations['ams-more'][locale];
                }
            }else if (hitLevel == 'hits') {
                if (hitCount == 1) {
                    return root.v1translations['ams-hit-on'][locale] + hitTexts[0];
                }else if (hitCount == 2) {
                    return root.v1translations['ams-hit-on'][locale] + hitTexts[0] + root.v1translations['ams-and'][locale] + hitTexts[1];
                }else {
                    return root.v1translations['ams-hit-on'][locale] + hitTexts[0] + root.v1translations['ams-and'][locale] + (hitCount - 1) + root.v1translations['ams-more'][locale];
                }
            }
        }
        return 'error';
    },

    getAmsHitLevel(web) {
        if (web.done) {
            let hitLevel = 'ok';
            let someCount = 0; const someTexts = [];
            let hitCount = 0; const hitTexts = [];
            for (const i in web.keywords) {
                if (web.keywords[i].result == 'some') {
                    if (hitLevel == 'ok') hitLevel = 'some';
                    someCount += 1;
                    someTexts.push(web.keywords[i].key);
                }
                if (web.keywords[i].result == 'hits') {
                    if (hitLevel == 'ok') hitLevel = 'hits';
                    hitCount += 1;
                    hitTexts.push(web.keywords[i].key);
                }
            }
            return hitLevel;
        }
        return 'error';
    },

    //#endregion ----------------------------------------------------------------------------------------------------------
    
    getSourceDisplay(root, source) {
        let str = '<div><span>';
        switch (source) {
            case 'kyc': str += root.__t('person-source') + ': ' + root.__t('person-source-kyc'); break;
            case 'scr-postal': str += root.__t('person-source-postal') + ', ' + root.__t('person-source') + ': ' + root.__t('person-source-scr') + ' (Bisnode)'; break;
            case 'scr-national': str += root.__t('person-source-national') + ', ' + root.__t('person-source') + ': ' + root.__t('person-source-scr') + ' (Folkeregisteret)'; break;
            case 'org-rrh': str += root.__t('person-source-national') + ', ' + root.__t('person-source') + ': ' + root.__t('person-source-org'); break;
            case 'org-role': str += root.__t('person-source') + ': ' + root.__t('person-source-org'); break;
            case 'idc': str += root.__t('person-source') + ': ' + root.__t('person-source-idc'); break;
            case 'created': str += root.__t('person-source-created'); break;
            case 'man': str += root.__t('person-source') + ': ' + root.__t('person-source-created'); break;
            case 'idc-bankid-no': str += root.__t('person-source') + ': ' + 'Fullført ID-kontroll med BankID'; break;
            case 'scr-citizenship': str += root.__t('person-source') + ': ' + root.__t('person-source-scr') + ' (Folkeregisteret)'; break;
        }
        str += '</span>';

        if (source == 'idc-bankid-no') {
            str += '<div style="display: flex;"><div class="vepv2-warning-icon">!</div><div>NB! Man kan ha norsk BankID uten å være norsk statsborger</div></div>'
        }

        str += '</div>';
        return str;
    },

    getResidencyTooltip(root, person) {
        const bestSource = this.getBestResidencySource(person);

        let str = '<div>';

        if (bestSource) {
            str += '<div><span>' + root.__t('person-residency') + ': ' + root.getCountryName(bestSource.country);
            str += ' (' + bestSource.country + ')</span></div>';
            str += this.getSourceDisplay(root, bestSource.source);
        }else {
            str += '<div><span>' + root.__t('person-residency') + ': ' + root.__t('common-not-available') + '</span></div>';
            str += '<div><span>' + root.__t('person-geo-empty-hint') + '</span></div>';
        }
        
        str += '</div>';
        return str;
    },

    getCitizenshipTooltip(root, person) {
        const bestSource = this.getBestCitizenshipSource(person);
        let str = '<div>';

        if (bestSource) {
            str += '<div><span>' + root.__t('person-citizenship') + ': ' + root.getCountryName(bestSource.value);
            str += ' (' + bestSource.value + ')</span></div>';
            str += this.getSourceDisplay(root, bestSource.source);
        }else {
            str += '<div><span>' + root.__t('person-citizenship') + ': ' + root.__t('common-not-available') + '</span></div>';
            str += '<div><span>' + root.__t('person-geo-empty-hint') + '</span></div>';
        }
        
        str += '</div>';
        return str;
    },

    getBestResidencySource(person) {
        let bestSource = null;
        let bestScore = -1;
        for (const i in person.addresses) {
            if (person.addresses[i].country && this.getResidencySourceScore(person.addresses[i].source) > bestScore) {
                bestSource = person.addresses[i];
                bestScore = this.getResidencySourceScore(person.addresses[i].source);
            }
        }
        return bestSource;
    },

    getBestCitizenshipSource(person) {
        let bestSource = null;
        let bestScore = -1;
        for (const i in person.citizenships) {
            if (this.getCitizenshipSourceScore(person.citizenships[i].source) > bestScore) {
                bestSource = person.citizenships[i];
                bestScore = this.getCitizenshipSourceScore(person.citizenships[i].source);
            }
        }
        return bestSource;
    },

    getResidencySourceScore(source) {
        switch (source) {
            case 'idc-bankid-no': return 1;
            case 'org-rrh': return 3;
            case 'kyc': return 5;
            case 'scr-postal': return 7;
            case 'scr-national': return 9;
        }
        return 0;
    },

    getCitizenshipSourceScore(source) {
        switch (source) {
            case 'idc-bankid-no': return 1;
            case 'kyc': return 5;
            case 'scr-citizenship': return 9;
        }
        return 0;
    },

    getClientString(root, person, max) {
        let str = '';
        for (const i in person.clients) {
            if (i >= max) {
                str += ' + ' + (person.clients.length - Number(i)) + ' more';
                break;
            }
            if (i > 0) str += ', ';
            str += root.companyTitleCase(person.clients[i].name);
        }
        return str;
    },

};
export default v1personHelper;