import axios from 'axios';
import log from './log';
const portalapi = {

    functionUrl: process.env.VUE_APP_API_BASE_URL+'/portal',
    credentials: {},
    logResponse: true,
    host: 'unknown',
    baseUrl: process.env.VUE_APP_BASE_URL,

    async queryCustomers(filters, from, limit, sort, include, counts)  {
        const url = `query/customers?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.post(url + query);
    },

    async queryRoles(filters, from, limit, sort, include, counts)  {
        const url = `query/roles?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.post(url + query);
    },

    async queryPeople(filters, from, limit, sort, include, counts)  {
        const url = `query/people?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.post(url + query);
    },

    async getBearerToken(customerId, type, envelopeId)  {
        const data = {
            type: type,
            envelopeId: envelopeId
        };
        return await this.post(`customers/${customerId}/token`, data);
    },

/*     async getFormBearerToken(customerId, type, envelopeId)  {
        const data = {
            type: type,
            envelopeId: envelopeId
        };
        return await this.post(`customers/${customerId}/ftoken`, data);
    }, */

    async doCustomStuff(data)  {
        return await this.post(`custom`, data);
    },

    async fixKyc(customerId, data)  {
        return await this.post(`customers/${customerId}/fixkyc`, data);
    },

    async resetKar(customerId)  {
        return await this.post(`customers/${customerId}/resetkar`, { reset: true });
    },

    //#region Customers ---------------------------------------------------------------------------------------------------

    async getCustomer(id)  {
        return await this.post('customers/' + id);
    },

    async getCustomerFromOrgnr(orgnr)  {
        return await this.get(`customers/${orgnr}/org`);
    },

    async getCustomerFromName(name)  {
        return await this.get(`customers/${name}/name`);
    },

    async getCustomers()  {
        return await this.get('customers');
    },

    async getFilteredCustomers(fconfig, filters, skip, limit, sort)  {
        const data = {
            config: fconfig,
            filters: filters,
            skip: skip,
            limit: limit,
            sort: sort
        };
        return await this.post('filteredcustomers', data);
    },

    async newCustomer(data) {
        return await this.post('customers', data);
    },

    async updateCustomer(customerId, customerObject) {
        const data = {
            customer: customerObject
        };
        return await this.put(`customers/${customerId}`, data);
    },

    async updateCustomerStatus(customerId, customerObject) {
        const data = {
            customer: customerObject
        };
        return await this.put(`customers/${customerId}/public`, data);
    },

    async setCustomerStatus(customerId) {
        const data = {
            setStatus: true
        };
        return await this.post(`customers/${customerId}/status`, data);
    },

    async deleteCustomer(customerId)  {
        return await this.delete(`customers/${customerId}`);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region AML / KYC / PEP / IDC ---------------------------------------------------------------------------------------

    async performAML(customerId, amlData, personIds, nextStat) {
        const data = {
            data: amlData,
            personIds: personIds,
            nextStat: nextStat
        };
        return await this.post(`customers/${customerId}/amlsearch`, data);
    },

    async sendOutKYC(customer, params) {
        const data = {
            customer: customer,
            params: params
        };
        return await this.post(`customers/${customer._id}/sendoutkyc`, data);
    },

    async sendInKYC(envelopeCredentials, submit) {
        const data = {
            id: envelopeCredentials.id,
            cid: envelopeCredentials.cid,
            submit: submit || {}
        };
        return await this.post(`customers/${envelopeCredentials.id}/sendinkyc`, data);
    },

    async updateAfterKYC(customerId, removeIds, addIds) {
        const data = {
            removeIds: removeIds,
            addIds: addIds
        };
        return await this.post(`customers/${customerId}/kycroles`, data);
    },

    async addPepSearch(customerId, personId, personData) {
        const data = {
            personId: personId,
            data: personData
        };
        return await this.post(`customers/${customerId}/pep`, data);
    },

    async addIdControl(customerId, personId, personData) {
        const data = {
            personId: personId,
            data: personData
        };
        return await this.post(`customers/${customerId}/idc`, data);
    },

    async archiveData(customerId, dataKey, reason) {
        const data = {
            dataKey: dataKey,
            reason: reason
        };
        return await this.post(`customers/${customerId}/archive`, data);
    },

    async setMetadata(customerId, metadata) {
        const data = {
            metadata: metadata
        };
        return await this.put(`customers/${customerId}/metadata`, data);
    },

    async editPerson(customerId, oldIdentity, newIdentity) {
        const data = {
            oldIdentity: oldIdentity,
            newIdentity: newIdentity
        };
        return await this.post(`customers/${customerId}/editperson`, data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Client ------------------------------------------------------------------------------------------------------

    async checkExisting(personIds) {
        const data = { personIds: personIds };
        return await this.post('checkpeople', data);
    },

    async setDepartment(customerId, department, partner) {
        const data = {
            department: department,
            partner: partner
        };
        return await this.put(`customers/${customerId}/department`, data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region People ------------------------------------------------------------------------------------------------------

    async addRole(customerId, personId) {
        const data = { personId: personId };
        return await this.post(`customers/${customerId}/roles`, data);
    },

    async deleteRole(customerId, personId) {
        const path = `customers/${customerId}/roles/` + encodeURIComponent(`${personId}`);
        return await this.delete(path);
    },

    async getPerson(personId) {
        return await this.get(`people/${personId}`);
    },

    async checkExistingPeople(personIds) {
        const data = { personIds: personIds };
        return await this.post(`people`, data);
    },

    async updatePerson(personId, personData) {
        const data = {
            personData: personData
        };
        const path = 'people/' + encodeURIComponent(`${personId}`);
        return await this.put(path, data);
    },

    async removePersonRole(personId, roleId, customerId) {
        const data = {
            roleId: roleId,
            customerId: customerId
        };
        const path = 'people/' + encodeURIComponent(`${personId}`) + '/removerole';
        return await this.put(path, data);
    },

    async submitIDC(personId, envelopeId, customerId) {
        const data = {
            envelopeId: envelopeId,
            customerId: customerId,
        };
        const path = 'people/' + encodeURIComponent(`${personId}`) + '/idcontrol';
        return await this.post(path, data);
    },

    async archivePersonData(personId, dataKey, reason) {
        const data = {
            dataKey: dataKey,
            reason: reason
        };
        const path = 'people/' + encodeURIComponent(`${personId}`) + '/archive';
        return await this.post(path, data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region All roles ---------------------------------------------------------------------------------------------------

    async addCustomerRoles(customerId, roles) {
        const data = {
            customerId: customerId,
            roles: roles
        };
        return await this.post(`roles`, data);
    },

    async getIndividual(personId) {
        const path = `roles/` + encodeURIComponent(`${personId}`);
        return await this.get(path);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Batches / Reminders -----------------------------------------------------------------------------------------

    async getBatches() {
        return await this.get('batches');
    },

    async createBatch(customers) {
        const data = { customers: customers };
        return await this.post('batches', data);
    },

    async getBatch(id)  {
        return await this.get(`batches/${id}`);
    },

    async updateBatch(batch, index) {
        const data = {
            index: index,
            completed: batch.completed,
            completeCount: batch.completeCount,
            customer: batch.customers[index]
        };
        return await this.put(`batches/${batch._id}`, data);
    },

    async deleteBatch(id)  {
        return await this.delete(`batches/${id}`);
    },

    async updateBatchCustomer(batch, index, customerData) {
        const data = { data: customerData };
        return await this.put(`batches/${batch._id}/index/${index}`, data);
    },

    async removeBatchCustomer(batch, index) {
        return await this.delete(`batches/${batch._id}/index/${index}`);
    },

    async getReminders() {
        return await this.get('reminders');
    },

    async setReminder(customerId, reminder) {
        const data = { reminder: reminder };
        return await this.put(`reminders/${customerId}`, data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Share Applications ------------------------------------------------------------------------------------------

    async getShareApplications(customerId) {
        return await this.get(`shareapps/${customerId}`);
    },

    async createShareApplication(customerId, appData) {
        const data = { appData: appData };
        return await this.post(`shareapps/${customerId}`, data);
    },

    async getShareApplication(customerId, id)  {
        return await this.get(`shareapps/${customerId}/${id}`);
    },

    async updateShareApplication(customerId, id, appData) {
        const data = { appData: appData };
        return await this.put(`shareapps/${customerId}/${id}`, data);
    },

    async deleteShareApplication(customerId, id)  {
        return await this.delete(`shareapps/${customerId}/${id}`);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Login / Config ----------------------------------------------------------------------------------------------

    async getTeam() {
        return await this.post(`team`);
    },

    async addUser(user) {
        const data = { user: user };
        return await this.put(`team`, data);
    },

    async updateUser(user) {
        const data = { user: user };
        return await this.put(`team/update`, data);
    },

    async loadConfig() {
        return await this.post(`config/${this.getPid()}`);
    },

    async getPublicConfig(publicKey) {
        return await this.post(`publicconfig/${publicKey}`);
    },

    async setConfig(config) {
        const data = { config: config };
        return await this.put(`config/${this.getPid()}`, data);
    },

    async getTranslations(name) {
        return await this.post(`config/${this.getPid()}/translations/${name}`);
    },

    async setTranslations(name, translations) {
        const data = { texts: translations };
        return await this.put(`config/${this.getPid()}/translations/${name}`, data);
    },

    async getQuestions(key) {
        return await this.post(`config/${this.getPid()}/questions/${key}`);
    },

    async setQuestions(key, questions) {
        const data = { counter: questions.counter, cards: questions.cards };
        return await this.put(`config/${this.getPid()}/questions/${key}`, data);
    },

    async setDepartments(departments, partners) {
        const data = { departments: departments, partners: partners };
        return await this.put(`config/${this.getPid()}/departments/all`, data);
    },

    async addPartner(partner) {
        const data = { partner: partner };
        return await this.put(`config/${this.getPid()}/partners`, data);
    },

    async getFlows(key) {
        return await this.post(`config/${this.getPid()}/flows/${key}`);
    },

    async setFlows(key, flowConfig) {
        const data = { flows: flowConfig.flows, steps: flowConfig.steps };
        return await this.put(`config/${this.getPid()}/flows/${key}`, data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    async renderTemplate(root, templateId, userdata, extraTranslations) {
        const translations = extraTranslations ? { ...root.translations, ...extraTranslations } : root.translations;
        const data = {
            path: root.isAWS ? this.baseUrl + 'web/aml-portal/templates/' : this.baseUrl + 'templates/',
            templateId: templateId,
            config: root.config,
            userdata: userdata,
            translations: translations
        };
        console.log('data', data);
        return await this.post('render', data);
    },

    //#region Form designer -----------------------------------------------------------------------------------------------

    async getForms() {
        return await this.get('forms');
    },

    async createForm(data) {
        return await this.post('forms', data);
    },

    async getForm(key)  {
        return await this.get(`forms/${key}`);
    },

    async updateForm(key, data) {
        return await this.put(`forms/${key}`, data);
    },

    async deleteForm(key)  {
        return await this.delete(`forms/${key}`);
    },

    async getFormBearerToken(personId, customerId, envelopeId)  {
        const data = {
            personId: personId,
            customerId: customerId,
            envelopeId: envelopeId,
        };
        return await this.post(`forms/na/token`, data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Request / Response ------------------------------------------------------------------------------------------

    async get(route) {
        const axiosConfig = this.getAxiosConfig(route);
        this.onRequest('GET', axiosConfig);
        const response = await axios.get(this.functionUrl, axiosConfig);
        this.onResponse(response, axiosConfig);
        response.data.result.status = response.data.result.code;
        delete response.data.result.code;
        return response.data.result;
    },

    async post(route, data) {
        const axiosConfig = this.getAxiosConfig(route);
        const body = JSON.stringify(data);
        this.onRequest('POST', axiosConfig, body);
        const response = await axios.post(this.functionUrl, body, axiosConfig);
        this.onResponse(response, axiosConfig, body);
        response.data.result.status = response.data.result.code;
        delete response.data.result.code;
        return response.data.result;
    },

    async put(route, data) {
        const axiosConfig = this.getAxiosConfig(route);
        const body = JSON.stringify(data);
        this.onRequest('PUT', axiosConfig, body);
        const response = await axios.put(this.functionUrl, body, axiosConfig);
        this.onResponse(response, axiosConfig, body);
        response.data.result.status = response.data.result.code;
        delete response.data.result.code;
        return response.data.result;
    },

    async delete(route) {
        const axiosConfig = this.getAxiosConfig(route);
        this.onRequest('DELETE', axiosConfig);
        const response = await axios.delete(this.functionUrl, axiosConfig);
        this.onResponse(response, axiosConfig);
        response.data.result.status = response.data.result.code;
        delete response.data.result.code;
        return response.data.result;
    },

    onRequest(method, config, body) {
        if (!this.logResponse) return;
        const data = {
            pid: config.headers.pid,
            body: body
        };
        if (method == 'POST' || method == 'PUT') {
            log.r('API ' + method + ' ' + config.headers['x-route'], data);
        }else {
            log.r('API ' + method + ' ' + config.headers['x-route']);
        }
    },

    onResponse(response, config, body) {
        if (!this.logResponse) return;
        log.s('API ' + response.config.method.toUpperCase() + ' ' + config.headers['x-route'], response);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Axios Headers -----------------------------------------------------------------------------------------------

    // TODO remove this when all clients are on the new naming convention
    getKey(existingKey, cid) {
        let key = existingKey;
        if (!existingKey || existingKey == 'default') {
            if (cid == 'H1xq6yFU8') key = 'kvale';
            if (cid == 'ry1K-ZPPD') key = 'pangea-pp';
            if (cid == 'B1LU_pJfm') key = 'sands';
            if (cid == 'rkeUXcLuv') key = 'deloitte';
        }
        if (key == 'deloitte') key = 'default';
        return key;
    },

    getAxiosConfig(method) {
        let pid = this.getKey(this.credentials.key, this.credentials.cid);
        if (this.credentials.testinc) pid = this.getKey(this.credentials.testinc, this.credentials.cid);
        pid += '-';
        pid += this.credentials.cid;
        let config = {
            headers: {
                'x-route': method,
                'token' : this.credentials.token || this.credentials.bearerToken || '',
                'pid': pid,
                'portalhost': this.host,
                'Pragma' : 'no-cache' // IE-fix - disable caching of network calls
            }
        }
        return config;
    },

    getPid() {
        let pid = this.getKey(this.credentials.key, this.credentials.cid);
        if (this.credentials.testinc) pid = this.getKey(this.credentials.testinc, this.credentials.cid);
        pid += '-';
        pid += this.credentials.cid;
        return pid;
    }

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default portalapi;