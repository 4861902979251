<template>
<div class="frv2-d-btn frv2-d-btn-menu" :class="[
    {'frv2-d-btn-small': small },
    {'frv2-d-btn-disabled': disabled },
    {'frv2-d-btn-warning': warning },
]" v-on-clickaway="hideDropdown">
    
    <div class="frv2-d-btn-l" v-if="!toggle && leftList" @click="showDropdown">
        <vap-icon tiny :icon="'chev-d'" :color="'ext'" />
    </div>

    <div class="frv2-d-btn-main" v-if="!toggle" @click="onMainClicked">
        <vap-icon :small="small" :icon="icon" :color="'ext'" v-if="!working" />
    </div>

    <div v-if="working" class="frv2-d-btn-spinner">
        <vap-spinner small style="margin-top: 27px; margin-right: 38px;" />
    </div>

    <div class="frv2-d-btn-r" v-if="!toggle && rightList" @click="showDropdown">
        <vap-icon tiny :icon="'chev-d'" :color="'ext'" />
    </div>

    <div class="frv2-d-btn-toggle" v-if="toggle">

        <div class="frv2-d-btn-itm" v-for="(toggle, key, i) in toggleOptions" :key="'fbt' + i" :class="[
            {'frv2-d-btn-itm-first': i == 0 },
            {'frv2-d-btn-itm-active': toggleModel[key] },
        ]" @click="$emit('toggle', key)">

            <vap-icon :icon="toggle.icon" :color="'ext'" />

        </div>

    </div>

    <div class="frv2-d-btn-list" v-if="open" @click="hideDropdown">
        <slot />
    </div>

</div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
    name: 'DesignerButton',
    mixins: [clickaway],
    props: {
        icon: { type: String, default: '' },
        tooltip: { type: String, default: '' },
        small: { type: Boolean, default: false },
        leftList: { type: Boolean, default: false },
        leftListDisabled: { type: Boolean, default: false },
        rightList: { type: Boolean, default: false },
        rightListDisabled: { type: Boolean, default: false },
        toggle: { type: Boolean, default: false },
        toggleOptions: { type: Object, default: () => { return {} }},
        toggleModel: { type: Object, default: () => { return {} }},
        disabled: { type: Boolean, default: false },
        warning: { type: Boolean, default: false },
        working: { type: Boolean, default: false },
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {

        onMainClicked() {
            if (this.disabled || this.working) return;
            this.$emit('clicked');
        },

        showDropdown() {
            this.open = !this.open;
        },

        hideDropdown() {
            this.open = false;
        },

    }
}
</script>
<style scoped>

.frv2-d-btn {
    position: relative;
    display: flex;
}

.frv2-d-btn-menu {
    margin-top: 11px;
    margin-right: 10px;
}

.frv2-d-btn-main {
    width: 40px;
    height: 39px;
    padding-top: 1px;
    padding-left: 1px;
    border: solid 1px var(--fb-menu-c);
    box-sizing: border-box;
    cursor: pointer;
    fill: lightgray;
    background-color: var(--fb-menu-b);
}

.frv2-d-btn-warning .frv2-d-btn-main {
    fill: white;
    background-color: #DAA619;
}

.frv2-d-btn-main:hover {
    fill: white;
    background-color: #238CCC;
}

.frv2-d-btn-spinner {
    position: relative;
}

.frv2-d-btn-l {
    width: 24px;
    height: 39px;
    padding-top: 9px;
    border: solid 1px var(--fb-menu-c);
    border-right: none;
    box-sizing: border-box;
    fill: gray;
    background-color: var(--fb-menu-b);
    cursor: pointer;
}
.frv2-d-btn-l:hover {
    fill: white;
    background-color: #238CCC;
}

.frv2-d-btn-r {
    width: 24px;
    height: 39px;
    padding-top: 9px;
    border: solid 1px var(--fb-menu-c);
    border-left: none;
    box-sizing: border-box;
    fill: gray;
    background-color: var(--fb-menu-b);
    cursor: pointer;
}
.frv2-d-btn-r:hover {
    fill: white;
    background-color: #238CCC;
}

.frv2-d-btn-toggle {
    display: flex;
}

.frv2-d-btn-itm {
    width: 46px;
    height: 39px;
    padding-left: 4px;
    border: solid 1px var(--fb-menu-c);
    border-left: none;
    box-sizing: border-box;
    fill: lightgray;
    cursor: pointer;
}
.frv2-d-btn-itm:hover {
    fill: white;
    background-color: #238CCC;
}
.frv2-d-btn-itm-first {
    border-left: solid 1px var(--fb-menu-c);
}

.frv2-d-btn-itm-active {
    fill: white;
    background-color: #238CCC;
}

.frv2-d-btn-small .frv2-d-btn-main {
    width: 30px;
    height: 30px;
}


.frv2-d-btn-list {
    position: absolute;
    top: 39px; left: 0px;
    min-width: 200px;
    min-height: 20px;
    background-color: var(--fb-menu-c);;
}


.frv2-d-btn-disabled .frv2-d-btn-main {
    fill: var(--fb-menu-b);
    background-color: var(--fb-menu-a);
    cursor: not-allowed;
}
.frv2-d-btn-disabled .frv2-d-btn-l {
    fill: var(--fb-menu-b);
    background-color: var(--fb-menu-a);
    cursor: not-allowed;
}
.frv2-d-btn-disabled .frv2-d-btn-r {
    fill: var(--fb-menu-b);
    background-color: var(--fb-menu-a);
    cursor: not-allowed;
}

</style>