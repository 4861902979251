const xxOrg = {

    getFlow() {
        return {
            key: 'xx-org-creation',
            name: 'Organization Flow',
            type: 'org',
            countries: 'xx',
            conditions: 'underCreation',
            isManual: true,
            tabs: {
                overview: { key: 'common_overview', icon: 'checklist' },
                persons: { key: 'common_persons', icon: 'people' },
                report: { key: 'company_final_report', icon: 'verified' }
            },
            title: {
                en_EN: '',
                nb_NO: 'Foretak under stiftelse',
                sv_SE: '',
            },
            steps: [
                /* { key: 'xx-org-upgrade' }, */
                { key: 'xx-org-kyc' },
                { key: 'xx-org-per' },
                { key: 'xx-org-rep' }
            ],
            statuses: [
                {
                    key: 'f-kyc-a',
                    text: {
                        en_EN: 'Awaiting sending self-declaration',
                        nb_NO: 'Avventer utsendelse egenerklæring',
                        sv_SE: 'Väntar på att kundkännedom skickas'
                    },
                    triggers: []
                },
                {
                    key: 'f-kyc-b',
                    text: {
                        en_EN: 'Awaiting filling self-declaration',
                        nb_NO: 'Avventer utfylling egenerklæring',
                        sv_SE: 'Väntar på att kundkännedom ska slutföras'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc02', trg: 'sent', rev: false },
                    ]
                },
                {
                    key: 'f-kyc-c',
                    text: {
                        en_EN: 'Awaiting signing self-declaration',
                        nb_NO: 'Avventer signering egenerklæring',
                        sv_SE: 'Väntar på undertecknande av kundkännedom'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc02', trg: 'filled', rev: false },
                    ]
                },
                {
                    key: 'r-rep-a',
                    text: {
                        en_EN: 'Awaiting controls',
                        nb_NO: 'Avventer kontroller',
                        sv_SE: 'Väntar på kontroller'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc02', trg: 'signed', rev: false },
                    ]
                },
                {
                    key: 'x-com-a',
                    text: {
                        en_EN: 'Completed',
                        nb_NO: 'Fullført',
                        sv_SE: 'Färdiga'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ]
                },
                {
                    key: 'z-ch-c',
                    completed: false,
                    text: {
                        en_EN: '! - Changed after completion',
                        nb_NO: '! - Endret etter ferdigstillelse',
                        sv_SE: '! - Ändrad efter färdigställande'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                        { type: 'report', trg: 'changed', rev: false },
                    ]
                }
            ],
            events: {
                items: [
                    {
                        key: 'event00',
                        text: {
                            en_EN: 'Customer created by {{createdUser}}',
                            nb_NO: 'Kunde opprettet av {{createdUser}}',
                            sv_SE: 'Customer created by {{createdUser}}'
                        },
                        date: { type: 'client', date: 'createdDate' },
                        triggers: [
                            { type: 'client', trg: 'created', rev: false },
                        ]
                    },
                    {
                        key: 'event01',
                        text: {
                            en_EN: 'Company search performed by {{createdUser}}',
                            nb_NO: 'Selskapssøk utført av {{createdUser}}',
                            sv_SE: 'Företagssökning utförd av {{createdUser}}'
                        },
                        date: { type: 'org', date: 'searchDate' },
                        triggers: [
                            { type: 'org', trg: 'has-data', rev: false },
                        ]
                    },
                    {
                        key: 'event02',
                        text: {
                            en_EN: 'Self declaration form sent by {{kyc02-senderName}}',
                            nb_NO: 'Egenerklæringsskjema sendt ut av {{kyc02-senderName}}',
                            sv_SE: ''
                        },
                        date: { type: 'form', key: 'kyc02', date: 'sentDate' },
                        triggers: [
                            { type: 'form', key: 'kyc02', trg: 'sent', rev: false },
                        ]
                    },
                    {
                        key: 'event03',
                        text: {
                            en_EN: 'Self declaration filled by {{kyc02-editorName}}',
                            nb_NO: 'Egenerklæringsskjema fylt ut av {{kyc02-editorName}}',
                            sv_SE: ''
                        },
                        date: { type: 'form', key: 'kyc02', date: 'filledDate' },
                        triggers: [
                            { type: 'form', key: 'kyc02', trg: 'filled', rev: false },
                        ]
                    },
                    {
                        key: 'event04',
                        text: {
                            en_EN: 'Self declaration form signed by all signers',
                            nb_NO: 'Egenerklæringsskjema signert av alle signatarer',
                            sv_SE: ''
                        },
                        date: { type: 'form', key: 'kyc02', date: 'signedDate' },
                        triggers: [
                            { type: 'form', key: 'kyc02', trg: 'signed', rev: false },
                        ]
                    }
                ]
            },
            report: [
                {
                    key: 'kyc',
                    icon: 'form',
                    comment: true,
                    separation: true,
                    includeFile: {
                        enabled: true,
                        formKey: 'kyc02',
                        name: 'Egenerklæring'
                    },
                    title: {
                        en_EN: 'Self-declaration',
                        nb_NO: 'Egenerklæring'
                    },
                    requirements: [
                        {
                            key: 'org-search',
                            message: {
                                en_EN: 'Self-declaration not completed and signed',
                                nb_NO: 'Egenerklæringsskjema ikke fullført'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc02', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Self-declaration not assessed',
                                nb_NO: 'Egenerklæring ikke vurdert'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ],
                    actions: [
                        {
                            icon: 'pdf',
                            main: false,
                            action: 'preview',
                            key: 'kyc02',
                            triggers: [
                                { type: 'form', key: 'kyc02', trg: 'filled', rev: false },
                            ],
                            text: {
                                en_EN: 'Show receipt'
                            },
                            hint: {
                                en_EN: 'Open the PDF version of the form'
                            }
                        },
                    ],
                    items: [
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Not ready',
                                nb_NO: 'Ikke klar'
                            },
                            value: {
                                en_EN: 'Self-declaration not filled in and signed',
                                nb_NO: 'Egenerklæring ikke fylt ut og signert'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc02', trg: 'signed', rev: true },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Signed by',
                                nb_NO: 'Signert av'
                            },
                            value: {
                                en_EN: '{{kyc02-signerNames}}',
                                nb_NO: '{{kyc02-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc02', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            type: 'comparison'
                        }
                    ]
                },
                {
                    key: 'roles',
                    icon: 'person',
                    comment: true,
                    separation: true,
                    title: {
                        en_EN: 'Key roles',
                        nb_NO: 'Nøkkelroller'
                    },
                    requirements: [
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Key roles not assessed',
                                nb_NO: 'Nøkkelroller ikke vurdert'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                },
                {
                    key: 'assessment',
                    icon: 'check',
                    comment: true,
                    separation: true,
                    title: {
                        en_EN: 'Final assessment / risk assessment',
                        nb_NO: 'Totalvurdering'
                    },
                    requirements: [
                        {
                            key: 'report-risk',
                            message: {
                                en_EN: 'Risk classification not set',
                                nb_NO: 'Risikoklassifisering ikke satt'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'risk-set', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Final assessment not completed',
                                nb_NO: 'Mangler totalvurdering'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                }
            ]
        };
    },

    //#region Steps -------------------------------------------------------------------------------------------------------

    getSearchStep() {
        return {
            key: 'xx-org-upgrade',
            isDefault: true,
            resetMode: 'company',
            name: 'Default BankID AML Company Search',
            actions: [
                {
                    icon: 'search',
                    main: true,
                    action: 'lookup',
                    key: 'org',
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: true },
                    ],
                    text: {
                        en_EN: 'Get data',
                        nb_NO: 'Hent data',
                        sv_SE: 'Hämta data',
                    },
                    hint: {
                        en_EN: 'Retrieve data from public registers and screen for sanctions against the company',
                        nb_NO: 'Hent data fra offentlige registre og gjør et sanksjonsøk på selskapet',
                        sv_SE: 'Hämta uppgifter från offentliga register och screena för sanktioner mot företaget',
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'preview',
                    key: 'org',
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt',
                    },
                    hint: {
                        en_EN: 'Show the report from BankID AML',
                        nb_NO: 'Vis rapport fra BankID AML',
                        sv_SE: 'Visa rapporten från BankID AML',
                    }
                },
                {
                    icon: 'refresh',
                    main: false,
                    action: 'org-refresh',
                    key: 'org',
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: false },
                    ],
                    text: {
                        en_EN: 'Reset',
                    },
                    hint: {
                        en_EN: 'Reset the company lookup so a new one can be made',
                        nb_NO: 'Tilbakestill selskapssøket slik at det kan gjøres et nytt',
                        sv_SE: 'Återställ företagssökningen så att en ny kan göras',
                    },
                    disabled: {
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ],
                        hint: {
                            en_EN: 'Customer is locked after the final report is created',
                            nb_NO: 'Kunden er låst etter sluttrapporten er laget',
                            sv_SE: 'Kunden är låst efter att slutrapporten skapats',
                        },
                    }
                },
            ],
            title: {
                en_EN: 'Company search',
                nb_NO: 'Organisasjonssøk',
            },
            hint: {
                en_EN: 'Retrieve data from public registers and screen for sanctions against the company',
                nb_NO: 'Hent data fra offentlige registre og gjør et sanksjonsøk på selskapet',
                sv_SE: 'Hämta uppgifter från offentliga register och screena för sanktioner mot företaget',
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{sanctionMessage}}',
                                nb_NO: '{{sanctionMessage}}',
                                sv_SE: '{{sanctionMessage}}',
                            },
                            triggers: [
                                { type: 'org', trg: 'has-data', rev: false },
                            ]
                        }
                    ]
                },
                warning: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Some data was not fetched',
                                nb_NO: 'Some data was not fetched',
                                sv_SE: 'Some data was not fetched',
                            },
                            triggers: [
                                { type: 'org', trg: 'missing-data', rev: false },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{sanctionMessage}}',
                                nb_NO: '{{sanctionMessage}}',
                                sv_SE: '{{sanctionMessage}}',
                            },
                            triggers: [
                                { type: 'org', trg: 'has-sanction', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default xxOrg;