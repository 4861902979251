const validations = {

    validateRules(value, rules) {
        let result = { valid: true };

        let ruleList = rules.split("|");
        for (let rule of ruleList) {
            if (!result.valid) break;

            switch (rule) {
                case 'required': result = this.validateRequired(value); break;
                case 'email': result = this.validateEmail(value); break;
                case 'percentage': result = this.validatePercentage(value); break;
                case 'number': result = this.validateNumber(value); break;
            }

        }

        return result;
    },

    validateRequired(value) {
        if (typeof value == 'number') {
            return {
                valid: (value != undefined && value.toString().length > 0),
                key: 'validation_required'
            }
        }else {
            return {
                valid: (value && value.length > 0),
                key: 'validation_required'
            }
        }
    },

    validatePercentage(value) {
        let valid = false;
        try {
            const nval = Number(value.toString().replace(',', '.'));
            valid = !isNaN(nval - parseFloat(nval));
            if (valid && (nval > 100 || nval < 0)) valid = false;
        }catch (ex) {
            valid = false;
        }
        return {
            valid: valid,
            key: 'validation_percentage'
        };
    },

    validateNumber(value) {
        let valid = false;
        try {
            const nval = Number(value.toString().split(' ').join('').replace(',', '.'));
            valid = !isNaN(nval - parseFloat(nval));
        }catch (ex) {
            valid = false;
        }
        return {
            valid: valid,
            key: 'validation_number'
        };
    },

    validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let status = re.test(String(email).toLowerCase());
        return {
            valid: status,
            key: 'validation_email'
        }
    },

    validateEmailName(email) {
        try {
            const arr = email.split('@');
            if (arr[0].includes('æ')) return false;
            if (arr[0].includes('ø')) return false;
            if (arr[0].includes('å')) return false;
            if (arr[0].includes('Æ')) return false;
            if (arr[0].includes('Ø')) return false;
            if (arr[0].includes('Å')) return false;
            return true;
        }catch (ex) {
            return false;
        }
    },

}
export default validations