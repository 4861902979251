<template>
    <div>

        <div class="method-help">
            <div class="method-help-title">
                <div class="method-help-title-icon">
                    <vap-icon :icon="'help-o'" />
                </div>
                <div class="method-help-title-text">
                    <label>{{ __('method_help_title') }}</label>
                </div>
            </div>
            <div class="method-help-item" v-if="isAvailable('bankid-no')">
                <div class="method-help-head">
                    <label>{{ __('method_bankidno_head') }}</label>
                </div>
                <div class="method-help-help">
                    <label>{{ __('method_bankidno_help') }}</label>
                </div>
            </div>
            <div class="method-help-item" v-if="isAvailable('bankid-se')">
                <div class="method-help-head">
                    <label>{{ __('method_bankidse_head') }}</label>
                </div>
                <div class="method-help-help">
                    <label>{{ __('method_bankidse_help') }}</label>
                </div>
            </div>
            <div class="method-help-item" v-if="isAvailable('nets-esign-dk')">
                <div class="method-help-head">
                    <label>{{ __('method_nemidnets_head') }}</label>
                </div>
                <div class="method-help-help">
                    <label>{{ __('method_nemidnets_help') }}</label>
                </div>
            </div>
            <div class="method-help-item" v-if="isAvailable('touch-sign')">
                <div class="method-help-head">
                    <label>{{ __('method_touchsign_head') }}</label>
                </div>
                <div class="method-help-help">
                    <label>{{ __('method_touchsign_help') }}</label>
                </div>
            </div>
        </div>

        <div class="flw100" style="text-align: center; margin-top: 30px;">
            <label style="color: var(--text-weak);">digital signing powered by</label><br>
            <img :src="$root.images.vfblue" style="width: 160px; margin-top: 8px;" /><br>
        </div>

    </div>
</template>

<script>
export default {
    name: 'SigningMethodsInfo',
    props: {
        methods: {
            type: Array,
            default: () => { return [] }
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        isAvailable(key) {
            for (const i in this.methods) {
                if (this.methods[i].value == key) return true;
            }
            return false;
        }

    },
    async mounted() {
        


    }
}
</script>
<style scoped>


</style>