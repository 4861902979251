<template>
<div class="pmv2" :class="{'pmv2-warning': result && result.count > 0 }">
    
    <div class="pmv2-loading" v-if="loading">
        <div class="pmv2-loading-hint">
            {{ __t('persons-duplicate-checking') + '...' }}
        </div>
        <div class="pmv2-loading-spinner">
            <vap-spinner small />
        </div>
    </div>

    <div class="pmv2-empty" v-if="!loading && result.count == 0">
        <div class="pmv2-empty-hint">
            {{ __t('persons-duplicate-none') }}
        </div>
        <div class="pmv2-empty-icon">
            <vap-icon :icon="'refresh'" action @clicked="onRefresh" />
        </div>
    </div>

    <div class="pmv2-results" v-if="!loading && result.count > 0">

        <div class="pmv2-header">
            <span v-if="result.count == 1">{{ result.count + ' ' + __t('persons-duplicate-found-1') + ':' }}</span>
            <span v-if="result.count > 1">{{ result.count + ' ' + __t('persons-duplicate-found-2') + ':' }}</span>
        </div>

        <div>

            <div class="pmv2-item" v-for="(person, i) in result.persons.filter(person => person.results.length > 0)" :key="'pmi' + i" @click="onShowDialog(person, i)">

                <div class="pmv2-item-left">
                    <div class="pmv2-item-name">
                        {{ person.original.displayName }}
                    </div>
                    <div class="pmv2-item-info">
                        {{ person.original.info.dateOfBirth }}
                    </div>
                </div>
                <div class="pmv2-item-count">
                    {{ person.results.length }}
                </div>
                <div class="pmv2-item-icon">
                    <vap-icon :icon="'merge'" :color="'ext'" />
                </div>
                
            </div>

        </div>

    </div>

</div>
</template>
<script>

export default {
    name: 'MatchStatus',
    components: {
        
    },
    props: {
        result: { type: Object, default: null },
        loading: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

        onShowDialog(person, index) {
            this.$emit('dialog', person, index);
        },

        onRefresh() {
            this.$emit('refresh');
        }

    },
    async created() {

    }
}
</script>
<style scoped>

.pmv2 {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
}
.pmv2-warning {
    border: solid 1px var(--input-border);
    background-color: #EDC250;
}


.pmv2-results {
    padding: 4px;
}
.pmv2-header {
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    color: var(--text-title);
}

.pmv2-item {
    display: flex;
    padding: 4px 10px;
    border-radius: 5px;
    fill: var(--text-title);
    cursor: pointer;
}
.pmv2-item:hover {
    background-color: var(--text-strong);
    fill: #EDC250;
}

.pmv2-item-left {
    flex-grow: 100;
}
.pmv2-item-name {
    color: var(--text-title);
}
.pmv2-item:hover .pmv2-item-name {
    color: #EDC250;
}
.pmv2-item-info {
    font-size: 14px;
    font-weight: bold;
    color: var(--text-weak);
}

.pmv2-item-count {
    width: 60px;
    flex-shrink: 0;
    padding-top: 6px;
    font-size: 20px;
}
.pmv2-item:hover .pmv2-item-count {
    color: #EDC250;
}
.pmv2-item-icon {
    width: 36px;
    flex-shrink: 0;
}


.pmv2-loading {
    position: relative;
}
.pmv2-loading-hint {
    padding: 14px;
    color: var(--text-weak);
}
.pmv2-loading-spinner {
    position: absolute;
    top: 32px; right: 44px;
}


.pmv2-empty {
    display: flex;
}
.pmv2-empty-hint {
    flex-grow: 100;
    padding: 14px;
    color: var(--text-weak);
}
.pmv2-empty-icon {
    width: 44px;
    flex-shrink: 0;
    padding-top: 6px;
}

</style>