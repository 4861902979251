<template>
<div class="vap-dd" :class="[
    {'vap-expanded' : expanded},
    {'vap-compact' : compact},
    {'vap-dd-combine' : combine},
    {'val-ok': validation.valid && !noHighlight},
    {'val-error': !validation.valid},
    {'val-show': validation.show},
    {'vap-disabled': disabled},
]" v-on-clickaway="hideDropdown" tabindex="0" @focus="onFocus" @blur="onBlur" @keydown="handleKeyDown">
    <div class="vap-dd-value vip" @click="handleValueClick">
        <label v-if="value">{{ objects ? objectValue : value }}</label>
        <label v-if="!value" class="vap-dd-placeholder">{{ placeholder }}</label>
    </div>
    <div v-if="expanded" class="vap-dd-list" id="ddScroll">

        <div v-show="!searching" class="vap-dd-item" v-for="(option, i) in commonOptions" :key="'c' + i" @click="handleCommonClick(i)" :class="{'vap-dd-selected': isSelected(i, true)}">
            <label v-if="!objects">{{ option }}</label>
            <label v-if="objects">{{ getListDisplayCommon(i) }}</label>
        </div>

        <div v-if="searching" class="vap-dd-search">
            <input v-model="searchString" />
        </div>

        <div class="vap-dd-sep" />

        <div v-show="!searching" class="vap-dd-item" v-for="(option, i) in options" :key="i" @click="handleItemClick(i)" :class="{'vap-dd-selected': isSelected(i, false)}">
            <label v-if="!objects">{{ option }}</label>
            <label v-if="objects">{{ getListDisplay(i) }}</label>
        </div>

        <div v-show="searching" class="vap-dd-item" v-for="(option, i) in searchOptions" :key="'s'+i" @click="handleSearchClick(i)" :class="{'vap-dd-selected': isSelected(i)}">
            <label v-if="!objects">{{ option }}</label>
            <label v-if="objects">{{ getSearchDisplay(i) }}</label>
        </div>

    </div>
    <svg v-if="!combine && !validation.show" height="26" width="26" class="vap-dd-arrow">
        <path v-if="expanded" d="M 13 10.59375 L 21.765625 19.179688 C 22.15625 19.5625 22.78125 19.558594 23.171875 19.171875 L 24.707031 17.636719 C 25.097656 17.242188 25.097656 16.609375 24.703125 16.21875 L 13.707031 5.292969 C 13.511719 5.097656 13.257813 5 13 5 C 12.742188 5 12.488281 5.097656 12.292969 5.292969 L 1.296875 16.21875 C 0.902344 16.609375 0.902344 17.242188 1.292969 17.636719 L 2.828125 19.171875 C 3.21875 19.558594 3.84375 19.5625 4.234375 19.179688 Z"/>
        <path v-if="!expanded" d="M 13 15.40625 L 21.765625 6.820313 C 22.15625 6.4375 22.78125 6.441406 23.171875 6.828125 L 24.707031 8.363281 C 25.097656 8.757813 25.097656 9.390625 24.703125 9.78125 L 13.707031 20.707031 C 13.511719 20.902344 13.257813 21 13 21 C 12.742188 21 12.488281 20.902344 12.292969 20.707031 L 1.296875 9.78125 C 0.902344 9.390625 0.902344 8.757813 1.292969 8.363281 L 2.828125 6.828125 C 3.21875 6.441406 3.84375 6.4375 4.234375 6.820313 Z"/>
    </svg>

    <!------- Validation ---------------------------------------------------------------------------->
    <svg v-if="validation.show && validation.valid && !combine && !noValidation" height="24" width="24" class="val-ic-ok">
        <path d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"/>
    </svg>
    <svg v-if="validation.show && !validation.valid && !combine" height="24" width="24" class="val-ic-error">
        <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"/>
    </svg>
    <div v-if="validation.show && !validation.valid" class="val-message vip-error">
        <label>{{ '* ' + __(validation.key) }}</label>
    </div>
    <!------- ----------- -------------------------------------------------------------------------->
</div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway"
import validations from '../../validations'

export default {
    name: 'vap-dropdown',
    mixins: [clickaway],
    props: {
        value: [String, Number],
        options: {
            type: Array,
            default: () => { return [] }
        },
        commonOptions: {
            type: Array,
            default: () => { return [] }
        },
        compact: {
            type: Boolean,
            default: false
        },
        combine: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        displayKey: {
            type: String,
            default: ''
        },
        outputKey: {
            type: String,
            default: ''
        },
        listKey: {
            type: String,
            default: ''
        },
        listKey2: {
            type: String,
            default: ''
        },
        rules: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Nothing selected'
        },
        noValidation: {
            type: Boolean,
            default: false
        },
        noHighlight: {
            type: Boolean,
            default: false
        },
        triggerValidation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expanded: false,
            searching: false,
            searchString: '',
            focusDelay: false,
            objects: false,
            validation: {
                show: false,
                valid: true,
                key: ''
            },
            searchOptions: [],
            selectedIndex: 0
        }
    },
    computed: {
        objectValue: function () {
            return this.getTitleFromValue(this.value);
        }
    },
    watch: {
        value: {
            deep: false,
            handler(val) {
                if (!this.validation.show && !this.noValidation) this.validation.show = true;
                this.validate(val);
            }
        },
        triggerValidation: {
            deep: false,
            handler(val) {
                if (!this.validation.show && !this.noValidation) this.validation.show = true;
                this.validate(this.value);
            }
        },
        searchString: {
            deep: false,
            handler(val) {
                this.selectedIndex = 0;
                if (val.length > 0) {
                    this.searchOptions = [];
                    let str = val.toLowerCase();
                    for (let i in this.options) {
                        for (let key in this.options[i]) {
                            let opt = this.options[i][key].toString().toLowerCase();
                            if (opt.includes(str)) {
                                this.searchOptions.push(this.options[i]);
                                break;
                            }
                        }
                    }
                    this.searching = true;
                }else {
                    this.searching = false;
                }
            }
        }
    },
    methods: {
        validate(value) {
            if (this.noValidation) return;
            if (!value) value = this.value;
            //console.log('validating', value);
            this.validation = {...this.validation, ...validations.validateRules(value, this.rules)};
        },
        onFocus() {
            if (this.disabled) return;
            this.searchString = '';
            this.selectedIndex = 0;
            this.expanded = true;
            const self = this;
            self.focusDelay = true;
            setTimeout(function() {
                self.focusDelay = false;
            }, 200);
        },
        onBlur() {
            if (this.disabled) return;
            this.expanded = false;
        },
        handleKeyDown(event) {
            if (this.disabled) return;
            if (event.keyCode == 38) {
                this.selectedIndex -= 1;
                this.addScroll();
            }else if (event.keyCode == 40) {
                this.selectedIndex += 1;
                this.addScroll();
            }else if (event.keyCode == 13) {
                this.selectItem();
            }else if (event.keyCode == 8) {
                if (this.searchString.length > 0) this.searchString = this.searchString.substring(0, this.searchString.length - 1);
            }else {
                if ('abcdefghijklmnopqrstuvwxyzæøåABCDEFGHIJKLMOPQRSTUVWXYZÆØÅ0123456789 '.includes(event.key)) {
                    this.searchString = this.searchString + event.key;
                }
            }
        },
        handleValueClick() {
            if (this.disabled) return;
            this.selectedIndex = 0;
            this.searchString = '';
            if (!this.focusDelay) this.expanded = !this.expanded;
        },
        handleItemClick(index) {
            this.expanded = false;
            if (this.objects) {
                if (this.outputKey) {
                    this.$emit('input', this.options[index][this.outputKey]);
                }else if (this.listKey) {
                    this.$emit('input', this.options[index][this.displayKey]);
                }else {
                    this.$emit('input', this.options[index].value);
                }
            }else {
                this.$emit('input', this.options[index]);
            }
        },
        handleSearchClick(index) {
            this.expanded = false;
            if (this.objects) {
                if (this.outputKey) {
                    this.$emit('input', this.searchOptions[index][this.outputKey]);
                }else if (this.listKey) {
                    this.$emit('input', this.searchOptions[index][this.displayKey]);
                }else {
                    this.$emit('input', this.searchOptions[index].value);
                }
            }else {
                this.$emit('input', this.searchOptions[index]);
            }
        },
        handleCommonClick(index) {
            this.expanded = false;
            if (this.objects) {
                if (this.outputKey) {
                    this.$emit('input', this.commonOptions[index][this.outputKey]);
                }else if (this.listKey) {
                    this.$emit('input', this.commonOptions[index][this.displayKey]);
                }else {
                    this.$emit('input', this.commonOptions[index].value);
                }
            }else {
                this.$emit('input', this.commonOptions[index]);
            }
        },
        hideDropdown() {
            this.expanded = false;
        },
        getTitleFromValue(value) {
            for (let i in this.options) {
                if (this.outputKey) {
                    if (this.options[i][this.outputKey] == value) {
                        return this.options[i][this.displayKey];
                    }
                }else if (this.listKey) {
                    if (this.options[i][this.displayKey] == value) {
                        return this.options[i][this.displayKey];
                    }
                }else {
                    if (this.options[i].value == value) {
                        return this.options[i].title;
                    }
                }
            }
            return '';
        },
        getListDisplay(index) {
            if (this.listKey) {
                if (this.listKey2) {
                    return this.options[index][this.listKey] + ' ' + this.options[index][this.listKey2];
                }else {
                    return this.options[index][this.listKey];
                }
            }else {
                return this.options[index].value;
            }
        },
        getSearchDisplay(index) {
            if (this.listKey) {
                if (this.listKey2) {
                    return this.searchOptions[index][this.listKey] + ' ' + this.searchOptions[index][this.listKey2];
                }else {
                    return this.searchOptions[index][this.listKey];
                }
            }else {
                return this.searchOptions[index].value;
            }
        },
        getListDisplayCommon(index) {
            if (this.listKey) {
                if (this.listKey2) {
                    return this.commonOptions[index][this.listKey] + ' ' + this.commonOptions[index][this.listKey2];
                }else {
                    return this.commonOptions[index][this.listKey];
                }
            }else {
                return this.commonOptions[index].value;
            }
        },
        isSelected(index, common) {
            if (common) {
                if (index == this.selectedIndex) {
                    return true;
                }
            }else if (this.searching) {
                if (index == this.selectedIndex) {
                    return true;
                }
            }else {
                if (index == this.selectedIndex - this.commonOptions.length) {
                    return true;
                }
            }
            return false;
        },
        selectItem() {
            if (this.selectedIndex < 0) return;
            if (this.searching) {
                this.handleSearchClick(this.selectedIndex);
                return;
            }
            if (this.selectedIndex < this.commonOptions.length) {
                this.handleCommonClick(this.selectedIndex);
                return;
            }
            if (this.selectedIndex >= this.commonOptions.length) {
                this.handleItemClick(this.selectedIndex - this.commonOptions.length);
            }
        },
        addScroll() {
            let elem1 = document.getElementById('ddScroll');
            let s = document.getElementsByClassName("vap-dd-selected");
            if (s.length == 0) return;
            elem1.scrollTop = s[0].offsetTop - 180;
        }
    },
    mounted() {
        if (this.options.length > 0) {
            if (typeof this.options[0] == 'object') {
                this.objects = true;
            }
        }
    }
}
</script>
<style scoped>

.vap-dd {
    float: left;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.vap-dd:focus {
    outline: none;
}
.vap-dd:focus .vap-dd-value {
    border: solid 1px var(--input-focus);
}

.vap-dd-value {
    width: 100%;
    padding: 8px 12px;
    padding-top: 10px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: var(--card-background);
}
.vap-dd-value label {
    cursor: pointer;
}
.vap-dd-placeholder {
    color: var(--text-disabled);
}
.vap-dd-value:hover {
    background-color: rgb(245, 245, 245);
    border-color: var(--input-focus);
}


.vap-compact .vap-dd-value  {
    height: 30px;
    font-size: 15px;
    padding-top: 6px;
}

.vap-dd-arrow {
    position: absolute;
    top: 7px;
    right: 8px;
    transform: scale(0.7);
    pointer-events: none;
}
.vap-dd-arrow path {
    fill: var(--input-label);
}
.vap-compact .vap-dd-arrow   {
    top: 3px;
}

.vap-expanded .vap-dd-value {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
}

.vap-dd-list {
    position: absolute;
    width: 100%;
    min-height: 20px;
    max-height: 500px;
    box-sizing: border-box;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: solid 1px lightgray;
    background-color: white;
    z-index: 100;
    overflow: auto;
}


.vap-dd-item {
    padding: 8px 14px;
    cursor: pointer;
}
.vap-dd-item label {
    cursor: pointer;
}
.vap-dd-item:hover {
    background-color: lightgray;
}
.vap-dd-selected {
    color: var(--accent-contrast);
    background-color: var(--accent);
}


.vap-dd-sep {
    height: 1px;
    background-color: lightgray;
}

.vap-dd-search {
    padding: 10px 6px;
    box-sizing: border-box;
}
.vap-dd-search input {
    width: 100%;
    font-size: 16px;
    padding: 4px 6px;
    box-sizing: border-box;
}


.vap-disabled .vap-dd-value {
    background-color: lightgray;
    cursor: not-allowed;
}
.vap-disabled label {
    cursor: not-allowed;
}
.vap-disabled .vap-dd-value:hover {
    border-color: var(--input-border);
}

/* --- Validation -------------------- */

.val-show.val-ok .vap-dd-value {
    border-color: var(--val-ok);
}
.val-show.val-error .vap-dd-value {
    border-color: var(--val-error);
}

.val-ic-ok {
    position: absolute;
    top: 8px;
    right: 8px;
    transform: scale(1.0);
}
.val-ic-ok path {
    fill: var(--val-ok);
}

.val-ic-error {
    position: absolute;
    top: 8px;
    right: 8px;
    transform: scale(0.8);
}
.val-ic-error path {
    fill: var(--val-error);
}

.val-message {
    
}

/* --- ---------- -------------------- */


/* --- Combine ----------------------- */

.vap-dd-combine .vap-dd-value {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.vap-dd-combine .vap-dd-list {
    min-width: 270px;
}

/* --- ----------- ------------------- */

</style>