<template>
<div>
    
    <!-- Add dialog -->
    <PopupV1 v-if="params.action == 'add'" :header="__t('persons-keyrole-add')" @close="onClose" :dialog="addDialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('persons-keyrole-add-hint') }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="progress.working" />
            <VFButton :text="__t('persons-keyrole-add')" :icon="'add'" @clicked="onAddRole" :working="progress.working" />
        </template>
    </PopupV1>

    <!-- Remove dialog -->
    <PopupV1 v-if="params.action == 'remove'" :header="__t('persons-keyrole-remove')" @close="onClose" :dialog="removeDialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('persons-keyrole-remove-hint') }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="progress.working" />
            <VFButton :text="__t('persons-keyrole-remove')" :icon="'delete'" @clicked="onRemoveRole" :working="progress.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';

export default {
    name: 'KeyRoleDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
    },
    props: {
        params: { type: Object, default: null },
        progress: { type: Object, default: null }
    },
    data() {
        return {
            addDialog: {},
            removeDialog: {}
        }
    },
    methods: {

        onAddRole() {
            this.$emit('add', this.params.person, true);
        },

        onRemoveRole() {
            this.$emit('remove', this.params.person, true);
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        
    }
}
</script>
<style scoped>


</style>