<template>
<div class="frmod-sc-result">

    <div class="frmod-sc-result-status">
        <div class="frmod-sc-result-ball" :class="[
            {'frmod-sc-result-success': status == 1 },
            {'frmod-sc-result-warning': status == 2 },
            {'frmod-sc-result-error': status == 3 }
        ]">
        
        </div>
    </div>
    
    <div class="frmod-sc-result-text">
        {{ getText() }}
    </div>

    <div class="frmod-sc-result-icon" :class="[
        {'frmod-sc-result-icon-disabled': status == 0 }
    ]" @click="handleClick" >
        <svg data-v-523d2a2c="" width="50" height="50" version="1.1" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.4); margin-top: -11px; margin-left: -11px;"><path data-v-523d2a2c="" d="M 33.875 3 C 33.617188 3.035156 33.378906 3.167969 33.21875 3.375 L 29.21875 8.375 L 28.65625 9.0625 L 29.28125 9.71875 L 29.71875 10.15625 C 25.609375 14.054688 21.082031 15 13 15 L 12.1875 15 L 12.03125 15.8125 C 10.070313 25.625 3.0625 45.65625 3.0625 45.65625 L 2.375 47.625 L 4.34375 46.9375 C 4.34375 46.9375 24.375 39.929688 34.1875 37.96875 L 35 37.8125 L 35 37 C 35 28.914063 35.945313 24.390625 39.84375 20.28125 L 40.28125 20.71875 L 40.875 21.28125 L 41.5625 20.84375 L 47.5625 16.84375 C 48.027344 16.53125 48.15625 15.902344 47.84375 15.4375 C 47.53125 14.972656 46.902344 14.84375 46.4375 15.15625 L 41.125 18.6875 L 31.34375 8.90625 L 34.78125 4.625 C 35.042969 4.308594 35.082031 3.863281 34.882813 3.503906 C 34.679688 3.144531 34.28125 2.945313 33.875 3 Z M 31.125 11.5625 L 38.4375 18.875 C 34.257813 23.265625 33.105469 28.410156 33.03125 36.21875 C 24.808594 37.960938 12.757813 41.976563 7.75 43.6875 L 22.1875 29.25 C 22.714844 29.53125 23.304688 29.71875 23.9375 29.71875 C 25.996094 29.71875 27.6875 28.027344 27.6875 25.96875 C 27.6875 23.910156 25.996094 22.25 23.9375 22.25 C 21.878906 22.25 20.1875 23.910156 20.1875 25.96875 C 20.1875 26.652344 20.394531 27.289063 20.71875 27.84375 L 6.3125 42.25 C 8.023438 37.242188 12.039063 25.191406 13.78125 16.96875 C 21.585938 16.894531 26.734375 15.742188 31.125 11.5625 Z M 23.9375 24.25 C 24.914063 24.25 25.6875 24.992188 25.6875 25.96875 C 25.6875 26.945313 24.914063 27.71875 23.9375 27.71875 C 23.507813 27.71875 23.113281 27.59375 22.8125 27.34375 C 22.773438 27.296875 22.734375 27.257813 22.6875 27.21875 C 22.371094 26.902344 22.1875 26.457031 22.1875 25.96875 C 22.1875 24.992188 22.960938 24.25 23.9375 24.25 Z"></path><!----><!----></svg>
        <!-- <vap-icon icon="sign" small color="#555" action /> -->
    </div>

</div>
</template>

<script>

export default {
    name: 'SignCheckoutResult',
    components: {
        
    },
    props: {
        form: { type: Object, default: null },
    },
    data() {
        return {
            status: 0
        }
    },
    methods: {

        handleClick() {
            this.$emit('signature', this.form);
        },
       
        getStatus() {
            if (!this.form.signed) return 0;

            if (this.form.checkout.realSignature) {
                
                let allMatch = true;
                for (const i in this.form.checkout.option) {
                    console.log('options', this.form.checkout.option[i]);
                    let match = false;
                    for (const j in this.form.signers) {
                        if (this.isSamePerson(this.form.checkout.option[i], this.form.signers[j].signature)) {
                            match = true;
                        }
                    }
                    if (!match) allMatch = false;
                }

                if (!allMatch) {
                    return 3;
                }else {
                    return 1;
                }

            }else {
                if (this.form.checkout.confirmedUser) {
                    return 1;
                }
                return 2;
            }
        },

        getText() {
            if (!this.form.signed) return this.__("signrights_result_pending");

            if (this.form.checkout.realSignature) {
                let allMatch = true;
                for (const i in this.form.checkout.option) {
                    console.log('options', this.form.checkout.option[i]);
                    let match = false;
                    for (const j in this.form.signers) {
                        if (this.isSamePerson(this.form.checkout.option[i], this.form.signers[j].signature)) {
                            match = true;
                        }
                    }
                    if (!match) allMatch = false;
                }

                if (!allMatch) {
                    return this.__("signrights_result_mismatch");
                }else {
                    return this.__("signrights_result_match");
                }

            }else {
                if (this.form.checkout.confirmedUser) {
                    return this.__('signrights_result_manual_confirmed') + ' ' + this.getEmployeeName(this.form.checkout.confirmedUser);
                }
                return this.__("signrights_result_manual");
            }
        },

        isSamePerson(option, signature) {
            if (signature.method == 'touch-sign') {
                return false; //this.compareNames(option.name, signature.name, 0);
            }else {
                return this.compareNames(option.name, signature.name, 1) && option.dateOfBirth == signature.dateOfBirth;
            }
        },

        compareNames(name1, name2, threshold) {

            const names1 = name1.split(" ");
            const names2 = name2.split(" ");

            const numToMatch = names1.length > names2.length ? names1.length : names2.length;
            let matches = 0;

            for(let x of names2) {
                for(let y of names1) {
                    if(x == y) {
                        matches++;
                        break;
                    }
                }
            }

            return matches >= numToMatch - (numToMatch > 2 ? threshold : 0);

        },

    },
    created() {
        //console.log('Checkout', this.form);
        this.status = this.getStatus();
    }
}
</script>

<style scoped>

.frmod-sc-result {
    position: relative;
    float: left;
    width: 100%;
    height: 40px;
    display: flex;

    margin-top: -25px;
    margin-bottom: 20px;
    z-index: 1;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;

}

.frmod-sc-result-status {
    padding-top: 9px;
    padding-left: 20px;
}

.frmod-sc-result-ball {
    width: 48px;
    height: 20px;
    border-radius: 5px;
    background-color: var(--disabled-background);
}

.frmod-sc-result-text {
    flex-grow: 100;
    padding-top: 10px;
    padding-left: 20px;
}

.frmod-sc-result-icon {
    width: 40px;
    padding-top: 5px;
    padding-left: 5px;
    border-left: solid 1px var(--input-border);
    background-color: var(--page-background);
    cursor: pointer;
    box-sizing: border-box;
    fill: #555;
}
.frmod-sc-result-icon:hover {
    background-color: var(--accent);
    fill: #fff;
}
/* .frmod-sc-result-icon-disabled {
    cursor: not-allowed;
}
.frmod-sc-result-icon-disabled:hover {
    background-color: var(--page-background);
    fill: #555;
} */


.frmod-sc-result-success {
    background-color: var(--success-background);
}
.frmod-sc-result-warning {
    background-color: var(--warning-background);
}
.frmod-sc-result-error {
    background-color: var(--error-background);
}

</style>
