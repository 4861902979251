<template>
<div class="preview-base" :id="'pvb' + uid" >

    <div v-if="showPdf && pages > 0 && !hideControls" class="preview-controls">

        <div class="pv-button pv-left" @click="goToPage(page - 1)" :class="page < 2 ? 'pv-disabled' : ''">
            <label :class="page < 2 ? 'pv-disabled' : ''">&#129136;</label>
        </div>
        <div class="pv-div">
            <label>{{ page + ' / ' + pages}}</label>
        </div>
        <div class="pv-button pv-right" @click="goToPage(page + 1)" :class="page >= pages ? 'pv-disabled' : ''">
            <label :class="page >= pages ? 'pv-disabled' : ''">&#129138;</label>
        </div>

    </div>

    <div v-if="showPdf && !hideControls && downloadButton" class="preview-controls-download" @click="downloadPDF">
        <div style="padding-top: 3px;">
            <vap-icon :icon="'copy'" small :color="'white'" />
        </div>
        <div style="margin-left: 10px; padding-top: 8px;">
            <label style="cursor: pointer;">{{ 'Last ned' }}</label>
        </div>
    </div>

    <canvas v-show="showPdf && !showError" :id="'p' + uid" width="600px"></canvas>

    <div v-show="showImage && !showError" class="pv-image-base">
        <img :id="'i' + uid" class="pv-image" />
    </div>

    <div v-if="showError" class="pv-error-base">
        <label>{{ errorMessage }}</label>
    </div>

</div>
</template>
<script>
/* import pdfjsLib from "pdfjs-dist/build/pdf"; */
import * as pdfjsLib from 'pdfjs-dist';
import downloadjs from 'downloadjs'

export default {
name: 've-preview',
components: {

},
props: {
    uid: {
        type: String,
        default: "uid"
    },
    file: {
        type: File,
        default: null
    },
    filename: {
        type: String,
        default: "filename"
    },
    path: {
        type: String,
        default: ""
    },
    raw: {
        type: String,
        default: ''
    },
    imageData: {
        type: String,
        default: ''
    },
    hideControls: {
        type: Boolean,
        default: false
    },
    downloadButton: {
        type: Boolean,
        default: false
    }
},
data() {
    return {
        debugging: false,
        pages: 0,
        page: 0,
        viewportWidth: 600,
        showPdf: false,
        showImage: false,
        showError: false,
        errorMessage: 'Kan ikke forhåndsvise denne filen',
        pdfDocument: undefined
    }
},
watch: {
    file: {
        deep: true,
        handler(val) {
            if (this.debugging) console.log("file detected")
            this.readFile(val);
        }
    },
    file: {
        deep: false,
        handler(val) {
            if (this.debugging) console.log("path detected")
            this.readPath(val);
        }
    },
    raw: {
        deep: false,
        handler(val) {
            if (this.debugging) console.log("raw data detected")
            this.readRawData(val);
        }
    },
    imageData: {
        deep: false,
        handler(val) {
            if (this.debugging) console.log("image data detected")
            this.readImageData(val);
        }
    }
},
methods: {
    emitPages() {
        let data = {
            page: this.page,
            count: this.pages,
            pDisabled: this.page <= 1,
            nDisabled: this.page >= this.pages
        };
        if (this.debugging) console.log('emit', data);
        this.$emit('pages', data);
    },
    readFile(file) {
        const self = this;
        const reader = new FileReader();
        if (file.type == 'application/pdf') {
            reader.onload = function (event) {
                try {
                    var typedarray = new Uint8Array(this.result);
                    self.showPdf = true;
                    self.loadPdf(typedarray);
                }catch (e) {
                    self.showError = true;
                }
            };
            reader.readAsArrayBuffer(file);
        }else {
            reader.onload = function (event) {
                try {
                    self.showImage = true;
                    self.loadImage(this.result);
                }catch (e) {
                    self.showError = true;
                }
            }
            reader.readAsDataURL(file);
        }
    },
    readPath(path) {
        this.loadPdf(path);
    },
    readRawData(data) {
        this.showPdf = true;
        this.loadPdf({data: Buffer.from(data, 'base64')});
    },
    readImageData(data) {
        this.loadImage(data);
    },
    loadPdf(data) {
        const self = this;

        pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';

        // Loading a document.
        var loadingTask = pdfjsLib.getDocument(data);
        loadingTask.promise.then(function(pdfDocument) {
            if (self.debugging) console.log(pdfDocument);

            self.pdfDocument = pdfDocument;
            self.pages = pdfDocument.numPages || 1;

            // Request a first page
            self.goToPage(1);

        }).catch(function(reason) {
            self.showError = true;
            console.error("Error: " + reason);
        });
    },
    goPrev() {
        this.goToPage(this.page - 1);
    },
    goNext() {
        this.goToPage(this.page + 1);
    },
    goToPage(page) {
        if (page == 0 || page > this.pages) return;
        this.page = page;
        this.emitPages();
        const uid = 'p' + this.uid;
        const width = this.viewportWidth;
        return this.pdfDocument.getPage(page).then(function(pdfPage) {

            // Display page on the existing canvas with 100% scale.
            var canvas = document.getElementById(uid);
            canvas.width = width;

            var scale = canvas.width / pdfPage.getViewport({scale: 1.0}).width;
            var viewport = pdfPage.getViewport({scale: scale});
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            //console.log('w = ' + canvas.width + ' h= ' + canvas.height);
            var ctx = canvas.getContext("2d");
            var renderTask = pdfPage.render({
                canvasContext: ctx,
                viewport: viewport,
            });
            return renderTask.promise;

        });
    },
    loadImage(data) {
        this.showPdf = false;
        this.showImage = true;
        document.getElementById('i' + this.uid).src = data;
    },
    downloadPDF() {
        downloadjs("data:application/pdf;base64," + this.raw, this.filename + ".pdf", "application/pdf");
    }
},
async created() {
    let self = this;
    setTimeout(function() {
        var base = document.getElementById("pvb" + self.uid);
        self.viewportWidth = base.offsetWidth;
        if (this.debugging) console.log(self.viewportWidth);
        if (self.file != null) self.readFile(self.file);
        if (self.path != '') self.readPath(self.path);
        if (self.raw != '') self.readRawData(self.raw);
    }, 200);
}
}

</script>
<style>

.preview-base {
    width: 100%;
    min-height: 500px;
    margin: 0 auto;
}

.preview-controls {
    width: 172px;
    display: flex;
    margin: 0 auto;
    padding: 6px;
}

.preview-controls-download {
    position: absolute;
    display: flex;
    top: 30px; right: 60px;
    height: 34px;
    padding: 0px 15px;
    color: white;
    border-radius: 5px;
    background-color: var(--button);
    box-sizing: border-box;
}
.preview-controls-download:hover {
    background-color: var(--button);
    cursor: pointer;
    opacity: 0.7;
}

.pv-button {
    width: 50px;
    height: 34px;
    padding-top: 6px;
    color: white;
    text-align: center;
    background-color: var(--button);
    box-sizing: border-box;
}
.pv-button label {
    cursor: pointer;
}
.pv-button:hover {
    background-color: var(--button);
    cursor: pointer;
    opacity: 0.7;
}

.pv-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.pv-right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.pv-disabled {
    background-color: var(--button) !important;
    cursor: auto !important;
    opacity: 0.5;
}
.pv-disabled:hover {
    background-color: var(--button) !important;
    cursor: auto !important;
    opacity: 0.5;
}

.pv-div {
    width: 60px;
    margin: 0 1px;
    padding-top: 8px;
    color: white;
    text-align: center;
    background-color: var(--button);
    box-sizing: border-box;
}

.pv-image-base {
    float: left;
    width: 100%;
    padding: 20px;
    text-align: center;
}

.pv-image {
    margin: 0 auto;
    max-width: 100%;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.47);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.47);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.47);
}

.pv-error-base {
    float: left;
    width: 100%;
    padding: 30px 0;
    text-align: center;
}

</style>