<template>
<div class="veper">

    <div class="veper-empty" v-if="!person.screening.done">
        <div class="veper-empty-top">
            <VeSwitch v-model="checked" :disabled="disabled" :text="__t('screening-person')" />
        </div>
        <div class="veper-empty-icon" v-if="!checked">
            <vap-icon :icon="'pep'" massive :color="'var(--text-disabled)'" />
        </div>
        <div class="veper-screen" v-if="checked">
            <div class="veper-screen-image" v-if="$root.config.screening.nordicProvider == 'baml'">
                <img :src="$root.images.bamltiny" />
            </div>
            <div class="veper-screen-image-trap" v-if="$root.config.screening.nordicProvider == 'trapets'">
                <img :src="$root.images.trapetstiny" />
            </div>
            <div class="veper-screen-button">
                <vap-button :text="__t('screening-dialog')" @clicked="showScreeningDialog" />
            </div>
        </div>
    </div>

    

    <div v-if="person.screening.done" class="veper-con">
        
        <PersonInfoContainer first>
            <template v-slot:info>
                <PersonInfoItem
                    :success="(!person.screening.isPep && !person.screening.hasSanction) || person.screening.falsePositive"
                    :warning="person.screening.isPep && !person.screening.hasSanction && !person.screening.falsePositive"
                    :error="person.screening.hasSanction && !person.screening.falsePositive"
                >
                    <span v-if="!person.screening.isPep && !person.screening.hasSanction">{{ __('hit_none') }}</span>
                    <span v-if="person.screening.isPep && !person.screening.hasSanction">{{ __('hit_pep') }}</span>
                    <span v-if="person.screening.hasSanction && !person.screening.isPep">{{ __('hit_sanction') }}</span>
                    <span v-if="person.screening.hasSanction && person.screening.isPep">{{ __('hit_pep_and_sanction') }}</span>
                </PersonInfoItem>
            </template>
        </PersonInfoContainer>

        <PersonInfoContainer>
            <template v-slot:info>
                <PersonInfoItem :weakText="!person.screening.isPep && !person.screening.hasSanction">
                    <span v-if="!person.screening.isPep && !person.screening.hasSanction">{{ __t('screening-sources') + ':' }}</span>
                    <span v-if="person.screening.isPep && !person.screening.hasSanction">{{ person.screening.pepSummary.title }}</span>
                    <span v-if="person.screening.hasSanction">{{ person.screening.sanctionSummary.title ? person.screening.sanctionSummary.title : person.screening.sanctionSummary.name }}</span>
                </PersonInfoItem>
            </template>
            <template v-slot:actions v-if="$root.config.persons.screeningInfo">
                <PersonInfoButton :icon="'info'" @clicked="$emit('info')" />
            </template>
        </PersonInfoContainer>

        <PersonInfoContainer>
            <template v-slot:info>
                <PersonInfoItem :smallText="!person.screening.isPep && !person.screening.hasSanction" :weakText="!person.screening.isPep && !person.screening.hasSanction">
                    <span v-if="!person.screening.isPep && !person.screening.hasSanction && !person.screening.falsePositive">{{ 'EU, UN, PEP_EDGE' }}</span>
                    <span v-if="person.screening.isPep && !person.screening.hasSanction && !person.screening.falsePositive">{{ person.screening.pepSummary.matchDescription }}</span>
                    <span v-if="person.screening.hasSanction && !person.screening.falsePositive">{{ person.screening.sanctionSummary.matchDescription }}</span>
                    <span v-if="person.screening.falsePositive">{{ getFPositiveText() }}</span>
                </PersonInfoItem>
            </template>
            <template v-slot:actions v-if="$root.config.persons.allowFalsePositive && (person.screening.isPep || person.screening.hasSanction)">
                <PersonInfoButton :icon="'edit'" @clicked="$emit('falsepositive')" v-if="!person.screening.falsePositive" />
                <PersonInfoButton :icon="'edit'" @clicked="$emit('falsepositive')" v-if="person.screening.falsePositive" />
            </template>
        </PersonInfoContainer>

        <PersonInfoContainer>
            <template v-slot:info>
                
                <PersonInfoItem :width="50" smallText weakText v-if="!isMonitored() && !isOutdated">
                    {{ getDateString(person.screening.date) }}
                </PersonInfoItem>

                <PersonInfoItem :width="50" border smallText weakText v-if="!isMonitored() && !isOutdated">
                    {{ getTimePassedString(person.screening.date) }}
                </PersonInfoItem>

                <PersonInfoItem :width="100" border textError smallText v-if="!isMonitored() && isOutdated">
                    {{ getTimePassedString(person.screening.date) }}
                    <template v-slot:tooltip v-if="isOutdated">
                        <vap-tooltip :text="getTimePassedString(person.screening.date) + ' - ' + __t('screening-outdated')" />
                    </template>
                </PersonInfoItem>

                <PersonInfoItem blueFill v-if="isMonitored() && !recentMonitorHit">
                    {{ __t('monitor-active') }}
                </PersonInfoItem>
                <PersonInfoItem redFill v-if="isMonitored() && recentMonitorHit">
                    {{ __t('monitor-recent-hit') }}
                    <template v-slot:tooltip>
                        <vap-tooltip :text="__t('monitor-recent-hit-hint')" />
                    </template>
                </PersonInfoItem>

            </template>
            <template v-slot:actions>
                <PersonInfoButton :icon="'update'" :disabled="disabled" @clicked="$emit('refresh', person)" v-if="!isMonitored() && isOutdated" />
                <PersonInfoButton v-if="isMonitored() && recentMonitorHit"
                    :icon="'update'" :disabled="disabled" @clicked="$emit('refresh', person)"
                    :tooltip="disabled ? __t('report-locked-tooltip') : ''"
                />
                <PersonInfoButton :icon="'info'" @clicked="$emit('hit-info', person)" v-if="isMonitored() && recentMonitorHit" />
                <PersonInfoButton :icon="'delete'" :disabled="disabled" @clicked="$emit('reset')" v-if="!isMonitored()" />
                <PersonInfoButton :icon="'file-sent'" @clicked="$emit('preview')" v-if="!isMonitored()" />
            </template>
        </PersonInfoContainer>

    </div>
    
</div>
</template>
<script>
import tools from '../tools';

import PersonInfoContainer from './PersonInfoContainer.vue';
import PersonInfoButton from './PersonInfoButton.vue';
import PersonInfoItem from './PersonInfoItem.vue';
import VeSwitch from '../components/basic/VeSwitch.vue';

export default {
    name: 'PersonScreening',
    components: {
        PersonInfoContainer,
        PersonInfoButton,
        PersonInfoItem,
        VeSwitch
    },
    props: {
        person: { type: Object, default: null },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            checked: false,
            isOutdated: false,
            recentMonitorHit: false,
        }
    },
    methods: {

        getFPositiveText() {
            if (this.person.screening.done && this.person.screening.falsePositive) {
                if (this.person.screening.fpParams.reason == 'inactive') {
                    return this.__t('screening-inactive');
                }else {
                    return this.__t('screening-wrong-person');
                }
            }
            return 'error';
        },

        isMonitored() {
            if (this.person.monitors && this.person.monitors.length > 0) return true;
            return false;
        },

        //#region Helpers -----------------------------------------------------------------------------------------------------
        
        showScreeningDialog() {
            this.$emit('screen', this.person);
        },

        getDateString(date) {
            return tools.dateStringShort(date, this.$root.locale.key);
        },

        getTimePassedString(date) {
            return tools.timePassedString(date, this.$root);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {
        console.log('PERSON', this.person);

        if (this.person.screening.done && this.$root.config.screening.validMinutes) {
            const date = new Date(this.person.screening.date);
            const diff = new Date() - date;
            const diffMinutes = diff / 1000 / 60;
            this.isOutdated = (diffMinutes > (this.$root.config.screening.validMinutes) || 0);
        }

        if (this.person.monitorHits && this.person.monitorHits.length > 0) {

            if (this.person.screening.done) {
                const screeningDate = new Date(this.person.screening.date);

                for (const i in this.person.monitorHits) {
                    const hit = this.person.monitorHits[i];
                    const hitDate = new Date(hit.created);
                    const diff = hitDate - screeningDate;
                    const diffSeconds = diff / 1000;
                    if (diffSeconds > 10) {
                        this.recentMonitorHit = true;
                    }
                }

            }

            
        }

    }
}
</script>
<style scoped>

.veper {
    background-color: var(--page-background);
    border-bottom-left-radius: 6px;
}

.veper-con {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.veper-empty {
    max-height: 137px;
}
.veper-empty-top {
    display: flex;
    padding: 5px 10px;
    border-bottom: solid 1px var(--input-border);
}
.veper-empty-icon {
    margin-top: -5px;
    padding-left: 36%;
    padding-bottom: 20px;
}


.veper-screen {
    min-height: 85px;
    max-height: 105px;
    text-align: center;
    box-sizing: border-box;
}
.veper-screen-image {
    margin-top: 20px;
}
.veper-screen-image img {
    width: 150px;
}

.veper-screen-image-trap {
    margin-top: 10px;
}
.veper-screen-image-trap img {
    width: 94px;
}

.veper-screen-button {
    margin-top: 10px;
}

</style>