<template>
    <div>
        <div class="slim-page">
            <vap-card list :header="'Export data'">
                <vap-row>
                    <vap-group c2>
                        <vap-label :text="'Skip'" desc />
                        <vap-input v-model="skip" />
                    </vap-group>
                    <vap-group c2>
                        <vap-label :text="'Limit'" desc />
                        <vap-input v-model="limit" />
                    </vap-group>
                    <vap-group c2>
                        <vap-button :text="'Load persons'" @clicked="loadPersons" :disabled="working" :working="working" />
                    </vap-group>
                </vap-row>
            </vap-card>
        </div>
        <BatchView ref="batchview" :items="items" :steps="steps" :title="'Lagre rapporter i iManage'" />
    </div>
</template>
<script>
import log from '../../log';
import api from '../../portalapi';
import tools from '../../tools';
import cache from '../../cache';
import receipts from '../../receipts';
import clienthelper from '../../helpers/clienthelper';
import personhelper from '../../helpers/personhelper';

import downloadjs from 'downloadjs';
import BatchView from '../../components/special/BatchView';

export default {
    components: {
        BatchView
    },
    name: 'ExportPersons',
    data() {
        return {
            items: [],
            steps: 3,
            STEP_LOAD_PERSON: 0,
            STEP_LOAD_COMPANIES: 1,
            STEP_UPDATE_PERSON: 2,
            working: false,
            skip: 0,
            limit: 4,
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            const person = tools.decodePerson(item.identity);
            return person.firstName + ' ' + person.lastName;
        },

        onGetInfo1(item) {
            return item.identity;
        },

        onGetInfo2(item) {
            return '';
        },

        async onRemoveItem(item) {
            
        },

        async onStart() {
            return true;
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_LOAD_PERSON: return 'Laster person';
                case this.STEP_LOAD_COMPANIES: return 'Laster selskaper';
                case this.STEP_UPDATE_PERSON: return 'Oppdaterer person';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_LOAD_PERSON: return await this.loadPerson(item, data);
                case this.STEP_LOAD_COMPANIES: return await this.loadCompanies(item, data);
                case this.STEP_UPDATE_PERSON: return await this.updatePerson(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async loadPerson(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { person: null, envelope: null, amlData: null } };

            if (item.aml) {
                result.data.envelope = await this.$root.getEnvelope(item.aml.envelopeId);
                result.data.amlData = result.data.envelope.userdata.aml;
            }else if (item.search) {
                result.data.envelope = await this.$root.getEnvelope(item.search.envelopeId);
                result.data.amlData = result.data.envelope.userdata.aml;
            }

            result.ok = true;
            return result;
        },

        async loadCompanies(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { companies: [] } };

            const person = data[this.STEP_LOAD_PERSON].person;

            log.suppress = true;
            let filters = [
                { field: 'people', sub: 'id', op: 'includes', value: item.identity },
            ];
            const from = 0;
            const limit = 0;
            const sort = { dateUpdated: 1 };
            const include = [];
            const counts = [];
            let response = await api.queryCustomers(filters, from, limit, sort, include, counts);
            for (const i in response.customers) {
                result.data.companies.push({
                    id: response.customers[i]._id,
                    name: response.customers[i].name,
                    type: response.customers[i].type
                });
            }

            filters = [
                { field: 'roles', op: 'includes', value: item.identity },
            ];
            response = await api.queryCustomers(filters, from, limit, sort, include, counts);
            for (const i in response.customers) {
                let match = false;
                for (const j in result.data.companies) {
                    if (result.data.companies[j].id == response.customers[i]._id) match = true;
                }
                if (!match) {
                    result.data.companies.push({
                        id: response.customers[i]._id,
                        name: response.customers[i].name,
                        type: response.customers[i].type
                    });
                }
            }
            log.suppress = false;

            result.ok = true;
            return result;
        },

        async updatePerson(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            const amlData = data[this.STEP_LOAD_PERSON].amlData;
            const companies = data[this.STEP_LOAD_COMPANIES].companies;
            console.log('amlData', amlData);
            console.log('companies', companies);

            const personData = {
                temp: true,
                companies: companies
            };

            if (amlData && amlData.personData.aml.pepResults.length > 0) {
                log.e('PEP found');
                personData.pep = {
                    hit: true,
                    results: []
                };
                for (const i in amlData.personData.aml.pepResults) {
                    const item = amlData.personData.aml.pepResults[i];
                    personData.pep.results.push({
                        indicator: item.matchIndicator,
                        description: item.matchIndicatorDescription,
                        title: item.title,
                        name: item.name,
                        dateOfBirth: item.birthDate,
                        source: item.provider + ' (' + item.source + ')'
                    });
                }
            }
            if (amlData && amlData.personData.aml.sanctionResults.length > 0) {
                personData.sanction = {
                    hit: true,
                    results: []
                };
                for (const i in amlData.personData.aml.sanctionResults) {
                    const item = amlData.personData.aml.sanctionResults[i];
                    personData.sanction.results.push({
                        indicator: item.matchIndicator,
                        description: item.matchIndicatorDescription,
                        title: item.title,
                        name: item.name,
                        dateOfBirth: item.birthDate || '',
                        source: item.provider + ' (' + item.source + ')'
                    });
                }
            }

            console.log('personData', personData);
            await api.updatePerson(item.identity, personData);

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        async loadPersons() {
            this.$parent.setLoading(true, 'Laster kunder...');
            
            const filters = [
                { field: 'temp', op: 'exists', value: false },
            ];
            const from = this.skip;
            const limit = this.limit;
            const sort = { created: 1 };
            const include = [];
            const counts = [];
            const response = await api.queryPeople(filters, from, limit, sort, include, counts);

            this.items = [];
            for (const i in response.people) {
                this.items.push(response.people[i]);
            }

            this.$parent.setLoading(false);
        },

    },
    async created() {
        
    }
}
</script>