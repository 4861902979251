<template>
<div>

    <PopupV1 :header="__t('form-resend')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('form-resend-hint') }}
            </div>
            <div style="margin-bottom: 10px; color: var(--text-weak); font-style: italic;">
                {{ __t('form-resend-warning') }}
            </div>
            <DialogContent>

                <DialogContentHeader :text="__t('verified-form').toUpperCase() + ':'" />
                <DialogContentItem :text="name || 'form'" :icon="'form'" />

            </DialogContent>
            <div style="margin-top: 20px; color: var(--text-title);" v-if="canAbort">
                <VFCheckbox newDesign v-model="shouldAbort" :text="__t('form-resend-abort')" />
            </div>
            <div class="flw100" style="margin-top: 10px; color: var(--text-title);" v-if="shouldAbort">
                {{ __t('verified-reset-abort') }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('form-resend')"
                :icon="'refresh'"
                @clicked="resetForm"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'ResendDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox
    },
    props: {
        params: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            name: '',
            canAbort: false,
            shouldAbort: true,
        }
    },
    methods: {

        validateDialog() {
            this.dialog.valid = true;
        },

        async resetForm() {
            let abort = false;
            if (this.canAbort && this.shouldAbort) abort = true;
            this.$emit('resend', abort);
            this.onClose();
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        this.loading = true;
        this.dialog.working = true;
        this.dialog.status = this.__t('verified-reminder-loading');

        if (this.params.envelopeId) {
            const envelope = await this.$root.getEnvelope(this.params.envelopeId);
            this.envelopeId = envelope.id;
            this.documentId = envelope.documentId;

            for (const i in envelope.content.recipients) {
                if (!envelope.content.recipients[i].completed) {
                    this.recipientId = envelope.content.recipients[i].id;
                    break;
                }
            }
            console.log('envelope', envelope);
            this.name = envelope.content.documents[0].name;
            this.canAbort = !envelope.content.completed;
            if (!this.canAbort) this.shouldAbort = false;
        }

        this.dialog.working = false;
        this.loading = false;
        this.validateDialog();
    }
}
</script>