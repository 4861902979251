<template>
    <div class="vehs" :class="[{'vehs-phone': $root.phoneMode}, {'vehs-scrolled': scrolled}, {'vehs-iefix': $root.internetExplorer}]" id="stepper" v-on:scroll.passive="handleScroll">

        <div class="vehs-container">

            <div class="vehs-left">

                <div class="vehs-header" v-if="steps.length > 0">
                    <div class="vehs-header-big">
                        <label>{{ __(steps[currentStep].title) }}</label>
                    </div>
                    <div class="vehs-header-small" v-if="!$root.phoneMode">
                        <label>{{ flowName }}</label>
                    </div>
                </div>

                <div class="vehs-base">
                    <div class="vehs-stepper">
                        <div class="vehs-steps" id="stepper-progress" />
                    </div>
                </div>

            </div>
            
            <div class="vehs-right" v-if="steps.length > 0">
                <div v-if="$root.phoneMode" class="vbutton-group">
                    <div class="vbutton vback" @click="handleBtnBackClick" :class="[{'vdisabled':steps[currentStep].backDisabled}]">
                        <label class="nohl">&#11104;</label>
                    </div>
                    <div class="vbutton vnext" @click="handleBtnNextClick" :class="[{'vdisabled':steps[currentStep].nextDisabled}]">
                        <label class="nohl">&#11106;</label>
                    </div>
                </div>
                <div v-if="!$root.phoneMode" class="vbutton-group" :class="{'vbutton-rounded': $root.config.portal.roundedButtons}">
                    <div class="vbuttonx vback" :class="[{'vdisabled':steps[currentStep].backDisabled}]" @click="handleBtnBackClick">
                        <label class="nohl">{{ __('common_prev') }}</label>
                    </div>
                    <div class="vbuttonx vnext" :class="[{'vdisabled':steps[currentStep].nextDisabled}]" @click="handleBtnNextClick">
                        <label class="nohl">{{ steps[currentStep].nextText ? __(steps[currentStep].nextText) : __('common_next') }}</label>
                        <div class="vap-button-tt" v-if="steps[currentStep].nextDisabled">
                            <vap-tooltip :localized="steps[currentStep].message" />
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="vehs-circles">

            <div class="vehs-circles-c">
                <div class="vehs-s-disabled" v-for="(step, i) in steps" :key="i" v-show="!step.hidden"
                    :class="[
                        {'vehs-s-passed' : i < currentStep},
                        {'vehs-s-current' : i == currentStep}
                    ]" >
                    <div class="vehs-circle" @click="handleStepClick(step)">
                        <label class="nohl">{{ getNumber(i) }}</label>
                        <div class="vap-icon-tt">
                            <vap-tooltip :localized="step.title" :key="'tt' + i" />
                        </div>
                    </div>
                </div>
            </div>

            

        </div>

    </div>
</template>

<script>

export default {
    name: 've-horizontal-stepper',
    props: {
        steps: {
            type: Array,
            default: () => { return [] }
        },
        phone: {
            type: Boolean,
            default: false
        },
        stepKey: {
            type: String,
            default: ''
        },
        flowName: {
            type: String,
            default: 'name-of-flow'
        }
    },
    data() {
        return {
            scrolled: false
        }
    },
    computed: {
        currentStep: function () {
            for (let i in this.steps) {
                if (this.steps[i].key == this.stepKey) return i;
            }
            return 0;
        }
    },
    watch: {
        steps: {
            deep: false,
            handler(val) {
                //console.log('HS: data changed', val);
                this.setProgress();
            }
        },
        stepKey: {
            deep: false,
            handler(val) {
                //console.log('HS: key changed', val);
                this.setProgress();
            }
        },
    },
    methods: {
        getNumber(index) {
            let count = 0;
            for (const i in this.steps) {
                if (!this.steps[i].hidden) count += 1;
                if (i >= index) break;
            }
            return count;
        },
        handleBtnBackClick() {
            if (this.steps[this.currentStep].backDisabled) return;
            let nextIndex = Number(this.currentStep);
            let hasNext = false;
            while (!hasNext) {
                nextIndex -= 1;
                if (!this.steps[nextIndex].hidden) hasNext = true;
            }
            const nav = {
                key: this.steps[nextIndex].key,
                direction: 'prev'
            }
            this.$emit('nav', nav);
        },
        handleBtnNextClick() {
            if (this.steps[this.currentStep].nextDisabled) return;
            if (this.currentStep >= this.steps.length - 1) {
                this.$emit('on-submit', true);
            }else {
                let nextIndex = Number(this.currentStep);
                let hasNext = false;
                while (!hasNext) {
                    nextIndex += 1;
                    if (!this.steps[nextIndex].hidden) hasNext = true;
                }
                const nav = {
                    key: this.steps[nextIndex].key,
                    direction: 'next'
                }
                this.$emit('nav', nav);
            }
        },
        handleStepClick(step) {
            let navIndex = -1;
            let curIndex = -1;
            for (const i in this.steps) {
                if (this.steps[i].key == step.key) navIndex = i;
                if (this.steps[i].key == this.stepKey) curIndex = i;
            }
            let direction = 'none';
            let skipping = false;
            if (Number(navIndex) > Number(curIndex)) direction = 'next';
            if (Number(navIndex) < Number(curIndex)) direction = 'prev';
            if (Number(navIndex) > (Number(curIndex) + 1)) skipping = true;
            const nav = {
                key: step.key,
                direction: direction,
                skipping: skipping
            }
            this.$emit('nav', nav);
        },
        setProgress() {
            const cs = Number(this.currentStep);
            const ms = this.steps.length;
            const fac = 100 / ms;
            const prog = Math.ceil(fac * cs);
            var elem = document.getElementById('stepper-progress');
            elem.style.width = prog + "%";
        },
        handleScroll(event) {
            let doc = document.documentElement;
            let left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
            let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
            if (top > 60 && !this.scrolled) this.scrolled = true;
            if (top <= 60 && this.scrolled) this.scrolled = false;
        }
    },
    async mounted() {
        window.addEventListener('scroll', this.handleScroll);
        this.setProgress();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>
<style scoped>

.vehs {
    position: absolute;
    top: 60px;
    left: 0px;
    right: 0px;
    height: 72px;
    padding-bottom: 8px;
    background-color: var(--stepper-bg);
    z-index: 9;
    box-shadow: 1px 1px 2px rgba(166, 177, 198, 0.3);
}

.vehs-phone {
    height: 55px;
}

.vap-icon-tt {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
}

.vehs-scrolled {
    position: fixed;
    top: 0px;
}

.vehs-container {
    display: flex;
}

.vehs-left {
    flex-grow: 100;
}

.vehs-header-big {
    font-family: "Roboto Slab", serif;
    color: var(--text-title);
    font-size: 22px;
    font-weight: normal;
    margin-top: 16px;
    margin-left: 24px;
}
.vehs-header-small {
    font-family: "Roboto Slab", serif;
    color: var(--text-weak);
    font-size: 14px;
    font-weight: normal;
    margin-top: 0px;
    margin-left: 24px;
}

.vehs-phone .vehs-header-big {
    font-size: 16px;
    margin-top: 10px;
    margin-left: 20px;
}

.vehs-right {
    float: right;
    padding-top: 20px;
    padding-right: 24px;
    z-index: 10;
}
.vehs-phone .vehs-right {
    padding-top: 11px;
    padding-right: 14px;
}

.vehs-base {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 6px;
    box-sizing: border-box;
}

.vehs-stepper {
    display: none;
    max-width: 400px;
    border-radius: 5px;
    border: solid 1px var(--primary);
    overflow: hidden;
}

.vehs-steps {
    width: 50%;
    height: 10px;
    background-color: var(--primary);
}



.vbutton-group {
    display: flex;
}
.vbutton {
    width: 50px;
    height: 40px;
    text-align: center;
    background-color: var(--button);
}
.vbutton label {
    margin-top: -1px;
    font-size: 28px;
    font-weight: bold;
    color: white;
}



.vbuttonx {
    position: relative;
    min-width: 110px;
    height: 40px;
    padding-top: 11px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    background-color: var(--button);
    box-sizing: border-box;
    cursor: pointer;
}
.vbuttonx label {
    font-size: 16px;
    color: white;
    cursor: pointer;
}
.vbuttonx:hover {
    opacity: 0.8;
}

.vback {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.vnext {
    margin-left: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.vbutton-rounded .vback {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.vbutton-rounded .vnext {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.vbutton-rounded .vbuttonx {
    min-width: 100px;
}

.vdisabled {
    background-color: var(--disabled-background);
    cursor: default;
}
.vdisabled label {
    color: var(--disabled-text);
    cursor: default;
}
.vdisabled:hover {
    opacity: 1;
}

.vehs-circles {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.vehs-circles-c {
    width: min-content;
    display: flex;
    margin: 0 auto;
    margin-top: 22px;
}

.vehs-iefix .vehs-circles {
    width: 900px;
    display: flex;
    margin: 0 auto;
}
.vehs-iefix .vehs-circles-c {
    display: flex;
    margin: 0 auto;
    margin-top: 22px;
}

.vehs-circle {
    position: relative;
    width: 36px;
    height: 36px;
    margin: 0px 4px;
    text-align: center;
    color: var(--disabled-text);
    border-radius: 18px;
    background: var(--disabled-background);
    transition: none;
    padding-top: 8px;
    box-sizing: border-box;
    cursor: pointer;
}
.vehs-s-passed .vehs-circle {
    color: var(--primary-contrast);
    background: var(--primary);
}
.vehs-s-current .vehs-circle {
    padding-top: 5px;
    color: var(--primary);
    border: solid 2px var(--primary);
    background: var(--stepper-background);
}
.vehs-circle label {
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
}

@media screen and (max-width: 1000px) {
    .vehs-circles {
        display: none;
    }
    .vehs-stepper {
        display: flex;
    }
    .vehs-left label {
        margin-top: 10px;
    }
    .vehs-left {
        float: left;
        width: 100%;
    }
}

.vehs-button-tt {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
}

</style>