<template>
<div>
    <vap-form-divider :left="65" :right="35">
        <!-- LEFT side -->
        <template v-slot:left>

            <vap-label header :text="__('key_roles')" style="margin-bottom: 10px;" />

            <div class="flw100 vep-left-empty" v-if="!hasSelected">
                <div class="vep-left-empty-icon">
                    <vap-icon massive :icon="'people'" />
                </div>
                <div class="vep-left-empty-text">
                    <label>{{ __('key_roles_description') }}</label>
                </div>
            </div>

            <vap-expandcard v-for="(person, i) in persons.filter(person => person.selected == true)" :key="i" :expanded="person.uimodel.expanded && !$root.config.aml.person.disableChecks" @expand-click="person.uimodel.expanded = !person.uimodel.expanded">
                <template v-slot:top>
                    <div class="vep-person">
                        <!-- name, role -->
                        <div class="vep-person-left">
                            <div class="vep-person-name">
                                <label>{{ person.displayName + ' (' + person.info.dateOfBirth + ')' }}</label>
                            </div>
                            <div class="vep-person-role" v-html="getDisplayRoles(person)" />
                        </div>
                        <vap-icon v-if="!disabled" :icon="'cancel'" action @clicked="showRemovePopup(person)"></vap-icon>
                    </div>
                </template>
                <template v-slot:content>
                    <div class="flw100 vep-actions" v-if="!$root.config.aml.person.disableChecks">

                        <div v-if="person.flags.kycPep" class="flw100 vep-kyc-pep">
                            <div class="vep-kyc-pep-desc">
                                <label>{{ __('persons_kyc_pep') }}</label>
                            </div>
                            <div class="vep-kyc-pep-title">
                                <label>Tittel: </label>
                                <label style="font-weight: 500;">{{ person.flags.kycPepTitle }}</label>
                            </div>
                        </div>

                        <div v-if="person.flags.kycAdded" class="flw100 vep-kyc-add">
                            <div class="vep-kyc-pep-desc">
                                <label>{{ __('persons_kyc_added') }}</label>
                            </div>
                        </div>

                        <div v-if="person.flags.manual" class="flw100 vep-kyc-add">
                            <div class="vep-kyc-pep-desc">
                                <label>{{ __('persons_manual_added') + $root.getEmployeeName(person.flags.manualUserId) }}</label>
                                <vap-icon v-if="!disabled" :icon="'edit'" small action absolute :top="-6" :right="-10" @clicked="showEditManualPopup(person)" :color="'var(--accent-contrast)'" />
                            </div>
                        </div>

                        <div v-if="person.flags.contact" class="flw100 vep-kyc-add">
                            <div class="vep-kyc-pep-desc">
                                <label>{{ 'Angitt som kundens kontaktperson av ' + $root.getEmployeeName(person.flags.contactId) }}</label>
                                <vap-icon v-if="!disabled" :icon="'cancel'" small action absolute :top="-5" :right="-10" @clicked="onRemoveContactClicked(person)" />
                            </div>
                        </div>

                        <ve-actionswitch content v-model="person.uimodel.doSearch" combineRight :text="__('person_pep')" :key="uid + person.dateOfBirth + 'p'"
                            :switchDisabled="disabled || person.uimodel.didSearch"
                            :tooltip="__('persons_pep_tooltip')">
                            <PersonStatusPEP v-if="$root.config.aml.person.ssn" :person="person" @search="showConfirmDialog(person, 'search', $event)" @receipt="handleReceiptClick(person)" />
                            <div v-if="!$root.config.aml.person.ssn && person.uimodel.doSearch" class="vep-action-base">
                                <div v-if="!person.uimodel.didSearch" class="vep-action-pending">
                                    <label>{{ __('persons_search_baml') + ($root.debug.ssn && person.info.ssn ? ' (' + person.info.ssn + ')' : '') }}</label>
                                </div>
                                <div v-if="person.search.completed && !person.search.isPep && !person.search.hasSanction" class="flw100 vep-action-complete">
                                    <label>{{ __('hit_none') }}</label>
                                </div>
                                <div v-if="person.search.completed && person.search.isPep" class="flw100 vep-action-complete-pep">
                                    <label>{{ __('hit_pep') }}</label>
                                </div>
                                <div v-if="person.search.completed && person.search.hasSanction" class="flw100 vep-action-complete-san">
                                    <label>{{ __('hit_sanction') }}</label>
                                </div>
                                <div v-if="person.search.completed" class="flw100 vep-action-date">
                                    <label>{{ __('search_performed') + ' ' + formatDate('format', person.search.dateCompleted) }}</label>
                                </div>
                                <div class="vep-action-button">
                                    <vap-button v-if="person.search.completed" :text="__('show_receipt')" @clicked="handleReceiptClick(person)" />
                                    <vap-button v-if="!person.search.completed" :text="__('search_perform')" :working="person.uimodel.workingSearch" @clicked="showConfirmDialog(person, 'search')" :disabled="isWorking" />
                                </div>
                            </div>
                            <div v-if="!$root.config.aml.person.ssn && !person.uimodel.doSearch" class="flw100 vep-action-empty">
                                <div class="vep-action-empty-icon">
                                    <vap-icon :icon="'id'" />
                                </div>
                                <label>{{ __('person_not_selected') }}</label>
                            </div>
                        </ve-actionswitch>

                        <ve-actionswitch content v-model="person.uimodel.doControl" combineLeft :text="__('person_id')" :key="uid + person.dateOfBirth + 'i'"
                            :switchDisabled="disabled || person.uimodel.didControl" @input="validateEmail(person)"
                            :tooltip="__('persons_idc_tooltip')">
                            <PersonStatusIDC v-if="$root.config.aml.person.ssn" :person="person" @receipt="handleControlReceiptClick(person)" @options="showIdcOptions(person)" />
                            <div v-if="(!$root.config.aml.person.ssn || !person.control.completed) && person.uimodel.doControl" class="vep-action-base">
                                <div class="vep-action-email">
                                    <!-- <vap-input v-model="person.contact.email" :placeholder="__('common_email')" :rules="'email'" :disabled="person.control.inviteSent" v-if="!person.control.completed" @input="validateEmail(person)" /> -->
                                    <div v-if="person.control.completed" class="flw100 vep-action-complete">
                                        <label v-if="person.control.method != 'fileupload' && person.control.method != 'manual'">{{ __('person_id_confirmed') + ': ' + person.control.confirmedName }}</label>
                                        <label v-if="person.control.method == 'fileupload' && person.control.method != 'manual'">{{ __('person_confirmed_id_fileupload') }}</label>
                                        <label v-if="person.control.method == 'manual' && person.control.method != 'fileupload'">{{ __('person_confirmed_id_manual') }}</label>
                                        <vap-icon v-if="person.control.method == 'manual' && person.control.method != 'fileupload'" :icon="'info'" :color="'var(--success-text)'" absolute :top="2" :right="20">
                                            <template v-slot:tooltip>
                                                <vap-tooltip :text="__('ov_kyc_status_by') + ' ' + $root.getEmployeeName(person.control.manualInfo.userId) + (person.control.manualInfo.comment ? ' (' + person.control.manualInfo.comment + ')' : '')" />
                                            </template>
                                        </vap-icon>
                                    </div>
                                </div>
                                <div v-if="(!person.control.completed && !person.control.inviteSent)" class="vep-action-pending">
                                    <label>{{ __('persons_id_send') }}</label>
                                </div>
                                <div v-if="person.uimodel.didControl && !person.control.completed" class="flw100 vep-action-status">
                                    <vap-status-label :completed="person.control.completed" :text="__('person_id_awaiting')" :completedText="__('ov_id_status_complete')" :name="''" />
                                </div>
                                <div v-if="person.control.completed" class="flw100 vep-action-date">
                                    <label>{{ __('common_confirmed') + ' ' + formatDate('format', person.control.dateCompleted) }}</label>
                                </div>
<!--                                 <div class="vep-action-midbutton">
                                    <vap-button v-if="!person.control.inviteSent && !person.control.completed" :text="__('persons_id_manual')" @clicked="onManualIdcClicked(person)" />
                                </div> -->
<!--                                 <div class="vep-action-midinfo" v-if="person.control.completed && person.control.method == 'manual'">
                                    <label>{{ __('ov_kyc_status_by') + ' ' + $root.getEmployeeName(person.control.manualInfo.userId) + (person.control.manualInfo.comment ? ' (' + person.control.manualInfo.comment + ')' : '') }}</label>
                                </div> -->
                                <div class="vep-action-button">
                                    <!-- <vap-button v-if="person.control.inviteSent && person.control.completed && person.control.method != 'manual'" :text="__('show_receipt')" @clicked="handleControlReceiptClick(person)" /> -->
                                    <vap-button :text="__('common_options')" @clicked="showIdcOptions(person)" />
                                    <!-- <vap-button v-if="person.control.inviteSent && !person.control.completed" :text="__('ov_send_reminder')" @clicked="handleControlReminderClick(person)" />
                                    <vap-button v-if="!person.control.inviteSent && !person.control.completed" :text="__('persons_id_send')" :working="person.uimodel.workingControl" :disabled="!person.uimodel.emailValid || isWorking" @clicked="showConfirmDialog(person, 'control')" /> -->
                                </div>
                            </div>
                            <div v-if="!$root.config.aml.person.ssn && !person.uimodel.doControl" class="flw100 vep-action-empty">
                                <div class="vep-action-empty-icon">
                                    <vap-icon :icon="'id'" />
                                </div>
                                <label>{{ __('person_not_selected') }}</label>
                            </div>
                        </ve-actionswitch>

                    </div>
                </template>
            </vap-expandcard>
            
        </template>
        <!-- RIGHT side -->
        <template v-slot:right>

            <vap-label header :text="__('all_roles')" style="margin-bottom: 10px;" />

            <vap-card noPadding style="margin-bottom: 10px;" v-if="$root.config.aml.contactPerson && !hasContact">
                <vap-item fancy fancyCircle padding20 :fancyIcon="'add'"
                    :fancyText="__('persons_contact')"
                    :fancyHint="__('persons_contact_hint')"
                    @clicked="showContactPopup">
                </vap-item>
            </vap-card>
            <vap-card noPadding style="margin-bottom: 10px;" v-if="$root.config.aml.contactPerson && hasContact">
                <vap-item fancy padding20 :fancyIcon="'check'"
                    :fancyText="'Kontaktperson angitt'"
                    :fancyHint="''"
                    :disabled="true"
                    @clicked="showContactPopup">
                </vap-item>
            </vap-card>

            <vap-card noPadding style="margin-bottom: 10px;">
                <vap-item fancy fancyCircle padding20 :fancyIcon="'add'"
                    :fancyText="__('person_add_manual')"
                    :disabled="disabled"
                    @clicked="showAddPopup(undefined, true)">
                </vap-item>
            </vap-card>

            <!-- Right side lists -->
            <vap-expandcard v-for="(list, i) in lists" :key="i" v-show="list.persons.length > 0"
                        :expanded="list.expanded" @expand-click="list.expanded = !list.expanded">
                <template v-slot:top>
                    <div class="vep-right-header">
                        <label class="vep-right-header-text">{{ __(list.key) + list.count }}</label>
                        <label class="vep-right-header-icon" v-if="!list.expanded">&#10010;</label>
                        <label class="vep-right-header-icon" v-if="list.expanded">&#9866;</label>
                    </div>
                </template>
                <template v-slot:content>
                    <vap-item v-for="(person, j) in list.persons" :key="'l' + i + j" @clicked="showAddPopup(person, false)" :disabled="disabled || person.selected || person.resigned" @enter="onListPersonEnter($event, person, 'l' + i + j)" @leave="onListPersonLeave('l' + i + j)">
                        <div class="vep-item" :class="[{'vep-item-disabled' : person.selected}, {'vep-item-resigned' : person.resigned}, {'vep-item-removed' : wasRemoved(person, list)}]">
                            <label class="vep-item-icon" v-if="person.selected && !person.resigned">&#10004;</label>
                            <label class="vep-item-icon" v-if="!person.selected && !person.resigned">&#10094;</label>
                            <label class="vep-item-icon" v-if="person.resigned">!</label>
                            <label class="vep-item-content">{{ person.displayName }}</label>
                            <label class="vep-item-info" v-if="person.control.inviteSent || person.control.completed" :class="[{'vep-item-pen': !person.control.completed}]">ID</label>
                            <label class="vep-item-info" v-if="person.search.completed" :class="[{'vep-item-pep': person.search.isPep}, {'vep-item-san': person.search.hasSanction}]">S</label>
                            <label class="vep-item-info vep-item-acc" v-if="person.flags.manual">M</label>
                            <label class="vep-item-info vep-item-acc" v-if="person.flags.contact">M</label>
                            <label class="vep-item-info vep-item-acc" v-if="person.flags.kycAdded || person.flags.kycChanged">K</label>
                            <label class="vep-item-info vep-item-pep" v-if="person.flags.kycPep">K</label>
                            <label class="vep-item-value">{{ getRolePercentage(person, list) }}</label>
                        </div>
                    </vap-item>
                </template>
            </vap-expandcard>

            

        </template>
    </vap-form-divider>

    <ve-popup v-if="addPopup" :header="__('common_add') + ' ' + __('common_person')" :width="500" @close="addPopup = false" hideCloseButton>
        <div style="padding: 20px;">
            <div v-if="!addManual">
                <label>{{ __('persons_role_add') }}</label>
            </div>
            <vap-row v-if="addManual">
                <vap-group c6>
                    <vap-label :text="__('common_first_name')" desc required />
                    <vap-input v-model="manualPerson.firstName" @changed="validateManual" :placeholder="__('common_required')" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_last_name')" desc required />
                    <vap-input v-model="manualPerson.lastName" @changed="validateManual" :placeholder="__('common_required')" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_dob')" desc required />
                    <vap-datepicker v-model="manualPerson.dateOfBirth" @changed="validateManual" outputISO />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_role')" desc required />
                    <vap-dropdown v-model="manualPerson.role" @input="validateManual" :options="manualRoleOptions" :displayKey="$root.locale.key" :listKey="$root.locale.key" :outputKey="'value'" :disabled="manualPerson.isContact" />
                </vap-group>
                <vap-group c12 v-if="manualPerson.role == 'common_other'">
                    <vap-label :text="__('common_role')" desc required />
                    <vap-input v-model="manualPerson.roleOther" @changed="validateManual" :placeholder="'Beskriv rolle'" />
                </vap-group>
                <vap-group c12 v-if="manualPerson.role == 'screening_role_rrh' || manualPerson.role == 'screening_role_shareholder'">
                    <vap-label :text="__('common_share')" desc required />
                    <vap-input v-model="manualPerson.share" @changed="validateManual" :placeholder="__('common_share')" />
                </vap-group>
            </vap-row>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="addPopup = false" />
                <vap-button :text="__('common_add')" uppercase @clicked="addPersonFromPopup" :working="addWorking" :disabled="addManual && !manualPerson.valid" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="editManualPopup.show" :header="'Endre person'" :width="500" @close="addPopup = false" hideCloseButton>
        <div style="padding: 20px;">
            <vap-row>
                <vap-group c6>
                    <vap-label :text="__('common_first_name')" desc required />
                    <vap-input v-model="editManualPopup.firstName" :placeholder="__('common_required')" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_last_name')" desc required />
                    <vap-input v-model="editManualPopup.lastName" :placeholder="__('common_required')" />
                </vap-group>
                <vap-group c12>
                    <vap-label :text="__('common_dob')" desc required />
                    <vap-datepicker v-model="editManualPopup.dateOfBirth" outputISO />
                </vap-group>
            </vap-row>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="editManualPopup.show = false" />
                <vap-button :text="'Oppdater'" uppercase @clicked="updateManualPerson" :working="editManualPopup.working" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="removePopup" :header="__('common_remove') + ' ' + __('common_person')" :width="500" @close="removePopup = false" hideCloseButton>
        <div style="padding: 20px;">
            <div>
                <label>{{ __('persons_role_remove') }}</label>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="removePopup = false" />
                <vap-button :text="__('common_remove')" uppercase @clicked="removePersonFromPopup" :working="removeWorking" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="confirmPopup" :header="confirmHeader" :width="400" @close="confirmPopup = false" hideCloseButton>
        <div style="padding: 20px;">
            <div>
                <label>{{ confirmText }}</label>
            </div>
            <div v-if="confirmExtra" style="margin-top: 20px;">
                <label>{{ confirmExtra }}</label>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="confirmPopup = false" />
                <vap-button :text="__('common_yes')" uppercase @clicked="onConfirmed" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="manualIdcPopup.show" :header="__('persons_id_manual')" :width="400" @close="manualIdcPopup.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <div>
                <label>{{ __('persons_id_manual_hint') }}</label>
            </div>
            <div style="margin-top: 20px;">
                <vap-row>
                    <vap-group c12>
                        <vap-label desc :text="__('report_comment')" />
                        <vap-input v-model="manualIdcPopup.comment" />
                    </vap-group>
                </vap-row>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="manualIdcPopup.show = false" />
                <vap-button :text="__('common_complete')" uppercase @clicked="onManualIdcConfirm" :working="manualIdcPopup.working" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="contactPopup.show" :header="__('persons_select_contact')" :width="400" @close="contactPopup.show = false" hideCloseButton footerSeparator>
        <div style="max-height: 630px; overflow: auto;">
            <vap-item v-for="(person, i) in persons" :key="i" padding20 @clicked="onContactExistingClicked(person)">
                <label style="color: var(--text-accent); font-weight: bold;">
                    {{ person.info.firstName + ' ' + person.info.lastName }}
                </label>
                <label v-if="person.info.dateOfBirth" style="color: darkgray; font-weight: bold; margin-left: 7px;">
                    {{ '(' + person.info.dateOfBirth + ')' }}
                </label>
                <br>
                <div v-html="getDisplayRoles(person)" />
            </vap-item>
        </div>
        <template v-slot:footer>
            <vap-item fancy fancyCircle padding20 :fancyIcon="'add'"
                :fancyText="__('recipient_list_missing')"
                :fancyHint="__('list_add_manually')"
                @clicked="onContactManualClicked">
            </vap-item>
        </template>
    </ve-popup>

    <ve-popup v-if="removeContactPopup.show" :header="'Ikke kontaktperson?'" :width="500" @close="removeContactPopup.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <div>
                <label>{{ 'Er du sikker på at denne personen ikke er kontaktperson?' }}</label>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="removeContactPopup.show = false" />
                <vap-button :text="__('common_yes')" uppercase @clicked="onRemoveContact" :working="removeContactPopup.working" />
            </vap-button-row>
        </div>
    </ve-popup>

    <div v-if="tooltipShow" class="ptt-base" :style="'bottom: ' + tooltipBottom + 'px; right: ' + tooltipRight + 'px;'">

        <div class="ptt-name">
            <label>{{ tooltipPerson.displayName }}</label>
            <label>{{ ' (' + tooltipPerson.info.dateOfBirth + ')' }}</label>
        </div>
        <div class="ptt-role" v-html="getDisplayRoles(tooltipPerson)" />

        <div class="ptt-info-line" v-if="tooltipPerson.search.completed">
            <label class="ptt-info-ball">S</label>
            <label class="ptt-info-text">{{ 'PEP/Sankjsonsøk utført' }}</label>
        </div>
        <div class="ptt-info-line" v-if="tooltipPerson.flags.manual">
            <label class="ptt-info-ball vep-item-acc">M</label>
            <label class="ptt-info-text">{{ __('persons_manual_added') + $root.getEmployeeName(tooltipPerson.flags.manualUserId) }}</label>
        </div>
        <div class="ptt-info-line" v-if="tooltipPerson.flags.kycAdded">
            <label class="ptt-info-ball vep-item-acc">K</label>
            <label class="ptt-info-text">{{ __('persons_kyc_added') }}</label>
        </div>
        <div class="ptt-info-line" v-if="tooltipPerson.flags.kycPep">
            <label class="ptt-info-ball vep-item-pep">K</label>
            <label class="ptt-info-text">{{ __('persons_kyc_pep') }}</label>
        </div>
        <div class="ptt-info-line" v-if="tooltipPerson.flags.kycCeoRemoved">
            <label class="ptt-info-ball vep-item-acc">K</label>
            <label class="ptt-info-text">{{ 'Fjernet som daglig leder i egenerklæringsskjema' }}</label>
        </div>
        <div class="ptt-info-line" v-if="tooltipPerson.flags.kycRrhRemoved">
            <label class="ptt-info-ball vep-item-acc">K</label>
            <label class="ptt-info-text">{{ 'Fjernet som reell rettighetshaver i egenerklæringsskjema' }}</label>
        </div>
        <div class="ptt-info-line" v-if="tooltipPerson.flags.kycRrhChanged">
            <label class="ptt-info-ball vep-item-acc">K</label>
            <label class="ptt-info-text">{{ 'Eierandel endret fra ' + tooltipPerson.flags.kycRrhChangedFrom + ' til ' + tooltipPerson.flags.kycRrhChangedTo + ' i egenerklæringsskjema' }}</label>
        </div>

    </div>

</div>
</template>

<script>
import log from '../../log';
import tools from '../../tools';
import validations from '../../validations';
import receipts from '../../receipts';
import api from '../../portalapi';
import personhelper from '../../helpers/personhelper';

import PersonStatusPEP from '../persons/PersonStatusPEP.vue';
import PersonStatusIDC from '../persons/PersonStatusIDC.vue';

export default {
    name: 'Persons',
    components: {
        PersonStatusPEP,
        PersonStatusIDC
    },
    props: {
        persons: {
			type: Array,
			default: () => { return [] }
        },
        customer: {
			type: Object,
			default: () => { return {} }
        },
        disabled: {
			type: Boolean,
			default: false
        },
    },
    data() {
        return {
            uid: 'initial',
            lists: [], // The lists on the right of the screen containing persons based on their role
            selectedList: [], // The list on the left displaying persons that have pending or already completed PEP-searches/ID-controls

            hasSelected: false,

            isWorking: false,
            addPopup: false,
            addIndex: 0,
            addManual: false,
            addWorking: false,
            removePopup: false,
            removeIndex: 0,
            removeWorking: false,
            manualExpanded: false,
            manualPerson: { firstName: '', lastName: '', dateOfBirth: '', role: '', roleOther: '', isContact: false },
            manualRoleOptions: [
                { value: 'proprietor' },
                { value: 'ceo' },
                { value: 'chair' },
                { value: 'screening_role_procura' },
                { value: 'screening_role_deputy_chairman' },
                { value: 'screening_role_sign_right' },
                { value: 'screening_role_rrh' },
                { value: 'screening_role_board_member' },
                { value: 'screening_role_deputy_board_member' },
                { value: 'screening_role_shareholder' },
                { value: 'role_customer_contact' },
                { value: 'common_other' }
            ],
            manualPersons: [],
            confirmPopup: false, confirmHeader: '', confirmText: '', confirmIndex: -1, confirmType: '', confirmExtra: '',
            manualIdcPopup: { show: false, comment: '', working: false, index: 0 },
            idcWrongRecipientPopup: { show: false, working: false },
            contactPopup: { show: false, working: false },
            removeContactPopup: { show: false, working: false, person: undefined },
            tooltipEvents: [],
            tooltipShow: false,
            tooltipBottom: 0,
            tooltipRight: 0,
            tooltipPerson: {},
            hasContact: false,
            editManualPopup: { show: false, working: false, oldIdentity: '', firstName: '', lastName: '', dateOfBirth: '' }
        }
    },
    watch: {

    },
    methods: {

        wasRemoved(person, list) {
            return (person.flags.kycRrhRemoved && list.id == 'screening_role_rrh')
                || (person.flags.kycShRemoved && list.id == 'screening_role_shareholder')
                || (person.flags.kycCeoRemoved && list.id == 'ceo')
                || (person.flags.kycChairRemoved && list.id == 'chair');
        },

        showEditManualPopup(person) {
            this.editManualPopup.oldIdentity = person.id;
            this.editManualPopup.firstName = person.info.firstName;
            this.editManualPopup.lastName = person.info.lastName;
            this.editManualPopup.dateOfBirth = person.info.dateOfBirth;
            this.editManualPopup.show = true;
        },

        async updateManualPerson() {
            this.editManualPopup.working = true;

            const oldIdentity = this.editManualPopup.oldIdentity;
            const newIdentity = tools.encodePerson(this.editManualPopup);

            const response = await api.editPerson(this.customer._id, oldIdentity, newIdentity);
            const updatedCustomer = response.customer;

            this.editManualPopup.working = false;
            this.editManualPopup.show = false;
            this.$emit('reload', updatedCustomer);
        },

        onListPersonEnter(event, person, key) {
            if (!this.$root.debug.personPopup) return;
            this.tooltipEvents.push({
                key: key,
                event: event,
                person: person
            });
            const self = this;
            setTimeout(function() {
                self.showPersonTooltip(key);
            }, 500);
        },
        onListPersonLeave(key) {
            if (!this.$root.debug.personPopup) return;
            for (const i in this.tooltipEvents) {
                if (this.tooltipEvents[i].key == key) {
                    //console.log('removed');
                    this.tooltipEvents.splice(i, 1);
                    break;
                }
            }
            this.hidePersonTooltip();
        },

        showPersonTooltip(key) {
            const event = this.tooltipEvents[this.tooltipEvents.length - 1];
            if (!event) return;
            if (event.key != key) return;
            //console.log('show', event);
            const body = document.body, html = document.documentElement;
            const pageHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            this.tooltipBottom = pageHeight - event.event.pageY + 40;
            this.tooltipRight = 40;
            //console.log('showing at ' + this.tooltipRight + ', ' + this.tooltipBottom);
            this.tooltipPerson = event.person;
            this.tooltipShow = true;
        },

        hidePersonTooltip() {
            this.tooltipShow = false;
        },

        showContactPopup() {
            this.contactPopup.show = true;
        },

        async onContactExistingClicked(person) {
            const personId = tools.encodePerson(person);
            const manual = {
                roleId: 'role_customer_contact', 
                roleOther: '',
                share: 0,
                userId: this.$root.credentials.uid,
                date: new Date(),
                customerId: this.customer._id
            };
            await api.updatePerson(personId, { manualRole: manual });
            const response = await api.addRole(this.customer._id, personId);
            const updatedCustomer = response.customer;
            this.$emit('reload', updatedCustomer);
            this.contactPopup.show = false;
        },

        onContactManualClicked() {
            this.contactPopup.show = false;
            this.showAddPopup(undefined, true, true);
        },

        setContact() {
            this.contactPopup.show = false;
            this.$emit('contactset', person);
        },

        onRemoveContactClicked(person) {
            this.removeContactPopup.person = person;
            this.removeContactPopup.show = true;
        },

        async onRemoveContact() {
            this.removeContactPopup.working = true;

            await api.removePersonRole(this.removeContactPopup.person.id, 'role_customer_contact', this.customer._id);

            this.removeContactPopup.working = false;
            this.removeContactPopup.show = false;
            this.$emit('reload', undefined);
        },

        // Method called to reset all lists to avoid duplicate data
        initializeLists() {
            // Initialize/reset the list on the right
            // All different roles returned by BankID AML should have it's own list
            // If there are any persons ending up in 'other', that list should probobly be added here
            this.lists = [
                { persons: [], expanded: true, id: 'proprietor', key: 'proprietor' },
                { persons: [], expanded: true, id: 'ceo', key: 'ceo' },
                { persons: [], expanded: true, id: 'chair', key: 'chair' },
                { persons: [], expanded: true, id: 'screening_role_procura', key: 'screening_role_procura' },
                { persons: [], expanded: true, id: 'screening_role_deputy_chairman', key: 'screening_role_deputy_chairman' },
                { persons: [], expanded: true, id: 'screening_role_sign_right', key: 'screening_role_sign_right' },
                { persons: [], expanded: true, id: 'screening_role_rrh', key: 'screening_role_rrhs' },
                { persons: [], expanded: true, id: 'screening_role_board_member', key: 'screening_role_board_members' },
                { persons: [], expanded: true, id: 'screening_role_deputy_board_member', key: 'screening_role_deputy_board_members' },
                { persons: [], expanded: true, id: 'screening_role_shareholder', key: 'screening_role_shareholders' },
                { persons: [], expanded: true, id: 'screening_role_accountant', key: 'screening_role_accountant' },
                { persons: [], expanded: true, id: 'scr_secretary', key: 'scr_secretary' },
                { persons: [], expanded: true, id: 'scr_director', key: 'scr_directors' },
                { persons: [], expanded: true, id: 'other', key: 'screening_role_others' },
                { persons: [], expanded: true, id: 'role_customer_contact', key: 'role_customer_contact' }
            ];
        },

        // Main method called when any relevant data is changed in parent view (Customer.vue) to update this views data
        processPersons() {
            log.v('Persons: processing persons with customer', this.customer);

            // Reset the lists on the right
            this.initializeLists();
            this.selectedList = [];
            this.uid = tools.getUniqueId();
            this.hasContact = false;

            // Create the right-side lists by looping the mainList
            for (const i in this.persons) {
                //console.log(this.persons[i].info.firstName + ' ', this.persons[i])
                let match = false;
                for (const j in this.lists) {
                    if (personhelper.hasRole(this.persons[i], this.lists[j].id)) {
                        this.lists[j].persons.push(this.persons[i]);
                        match = true;
                    }
                }
                // No match meens the role of this person is not defined as it's own list
                // Add the person to the 'other' list
                if (!match) {
                    for (const j in this.lists) {
                        if (this.lists[j].id == 'other') this.lists[j].persons.push(this.persons[i]);
                    }
                }

                for (const j in this.persons[i].roles) {
                    if (this.persons[i].roles[j].id == 'role_customer_contact') {
                        this.hasContact = true;
                    }
                }

                // Hide help message if a person is selected
                if (this.persons[i].selected) this.hasSelected = true;
            }
            // Update the right-side lists with the proper title (with count)
            for (const j in this.lists) {
                if (this.lists[j].persons.length > 1) {
                    this.lists[j].count = ' (' + this.lists[j].persons.length + ')';
                }else {
                    this.lists[j].count = '';
                }
            }

        },


        // Show the add person popup
        showAddPopup(person, manual, contact) {
            //console.log(person);
            if (person && (person.selected || person.resigned)) return;
            if (manual) {
                if (contact) {
                    this.manualPerson.isContact = true;
                    this.manualPerson.role = 'role_customer_contact';
                }
                this.addManual = true;
            }else {
                this.addManual = false;
                for (const i in this.persons) {
                    if (personhelper.isSamePerson(this.persons[i], person)) {
                        this.addIndex = i;
                    }
                }
            }
            this.addPopup = true;
        },
        async addPersonFromPopup() {
            //console.log('onadd', this.addManual);

            this.addWorking = true;
            let personId = '';

            if (this.addManual) {
                personId = tools.encodePerson(this.manualPerson);
                const manual = {
                    roleId: this.manualPerson.role,
                    roleOther: this.manualPerson.roleOther,
                    share: this.manualPerson.share,
                    userId: this.$root.credentials.uid,
                    date: new Date(),
                    customerId: this.customer._id
                };
                await api.updatePerson(personId, { manualRole: manual });
            }else {
                personId = tools.encodePerson(this.persons[this.addIndex]);
            }
            
            const response = await api.addRole(this.customer._id, personId);
            const updatedCustomer = response.customer;
            //console.log('uc', updatedCustomer);

            this.addPopup = false;
            this.addWorking = false;
            this.$emit('reload', updatedCustomer);
        },


        // Show the remove person popup
        showRemovePopup(person) {
            for (const i in this.persons) {
                if (personhelper.isSamePerson(this.persons[i], person)) {
                    this.removeIndex = i;
                }
            }
            this.removePopup = true;
        },
        async removePersonFromPopup() {
            this.removeWorking = true;
            const personId = tools.encodePerson(this.persons[this.removeIndex]);
            const response = await api.deleteRole(this.customer._id, personId);
            const updatedCustomer = response.customer;
            //console.log('uc', updatedCustomer);
            this.removePopup = false;
            this.removeWorking = false;
            this.$emit('reload', updatedCustomer);
        },

        // Get a deep copy of a person object
        copyPerson(person) {
            const newPerson = JSON.parse(JSON.stringify(person));
            return newPerson;
        },

        // Validate ID-control email
        validateEmail(person) {
            const result = validations.validateEmail(person.contact.email);
            person.uimodel.emailValid = result.valid;
        },

        validateManual() {
            if (this.manualPerson.firstName.length > 0 && this.manualPerson.lastName.length > 0
            && this.manualPerson.dateOfBirth.length > 0 && this.manualPerson.role.length > 0) {
                this.manualPerson.valid = true;
            }else {
                this.manualPerson.valid = false;
            }
        },

        addManualPerson() {
            const person = {
                firstName: this.manualPerson.firstName,
                lastName: this.manualPerson.lastName,
                dateOfBirth: this.manualPerson.dateOfBirth,
                roleIds: [this.manualPerson.role],
                displayName: tools.titleCase(this.manualPerson.firstName + ' ' + this.manualPerson.lastName),
                expanded: true,
                manual: true,
                userId: this.$root.credentials.uid,
                workingSearch: false,
                workingControl: false,
                manual: true,
                manualData: {
                    roleIds: [this.manualPerson.role],
                    roleOther: this.manualPerson.roleOther,
                    share: this.manualPerson.share,
                    userId: this.$root.user.uid
                },
            };
            this.manualPersons.push(person);
            this.processPersons();
            this.addPerson(person);
            this.resetManualPerson();
        },

        resetManualPerson() {
            this.manualPerson = { firstName: '', lastName: '', dateOfBirth: '', role: '', roleOther: '' };
            this.manualExpanded = false;
        },

        getDisplayRoles(person) {
            return personhelper.getDisplayRoles(person, this.$root);
        },

        getRolePercentage(person, list) {
            return personhelper.getRolePercentage(person, list.id);
        },

        setManualRoleOptions() {
            for (const i in this.manualRoleOptions) {
                for (const j in this.$root.config.locales) {
                    this.manualRoleOptions[i][this.$root.config.locales[j].key] = this.$root.translations[this.manualRoleOptions[i].value][this.$root.config.locales[j].key];
                }
            }
        },

        showConfirmDialog(person, type, ssn) {
            let index = -1;
            for (const i in this.persons) {
                if (personhelper.isSamePerson(this.persons[i], person)) index = i;
            }
            if (this.$root.config.portal.confirmActions) {
                this.confirmIndex = index;
                this.confirmType = type;
                if (type == 'control') {
                    this.confirmHeader = 'ID-kontroll';
                    this.confirmText = 'Er du sikker på at du vil utføre ID-kontroll på denne personen?';
                    this.confirmExtra = 'Det er vanligvis ikke nødvendig å utføre ID-kontroll på andre personer enn signaturberettigede og reelle rettighetshavere.';
                }else {
                    this.confirmHeader = 'PEP/Sanksjonssøk';
                    this.confirmText = 'Er du sikker på at du vil gjøre et PEP/Sanksjonssøk på denne personen?';
                    this.confirmExtra = 'Det er vanligvis ikke nødvendig å utføre PEP/Sanksjonssøk på andre personer enn signaturberettigede og reelle rettighetshavere.';
                }
                this.confirmPopup = true;
            }else {
                if (type == 'control') {
                    this.sendIdControl(index);
                }else {
                    this.performSearch(index, ssn || false);
                }
            }
        },

        onConfirmed() {
            this.confirmPopup = false;
            if (this.confirmType == 'control') {
                this.sendIdControl(this.confirmIndex);
            }else {
                this.performSearch(this.confirmIndex);
            }
        },

        // Handle receipts
        handleReceiptClick(person) {
            this.$emit('receipt', person.search.envelopeId, 'pep');
        },
        handleControlReceiptClick(person) {
            //console.log(person)
            this.$emit('receipt', person.control.envelopeId, 'id');
        },

/*         handleControlReminderClick(person) {
            this.idcOptionsPopup.show = false;
            //console.log(person);
            if (!person.control.recipientId) return;
            const reminderData = {
                firstName: person.info.firstName,
                lastName: person.info.lastName,
                email: person.contact.email,
                envelopeId: person.control.envelopeId,
                recipientId: person.control.recipientId
            }
            this.$emit('reminder', reminderData);
        }, */

        async performSearch(index, ssn) {
            if (!(this.persons[index].uimodel.doSearch && !this.persons[index].uimodel.didSearch)) return;

            this.isWorking = true;
            this.persons[index].uimodel.workingSearch = true;

            const existingData = {
                customerId: this.customer._id,
                customerName: this.customer.name,
                brreg: this.$parent.brregData,
                companyData: this.$parent.companyData,
                companyAml: this.$parent.amlData,
                useSSN: ssn
            };
            const result = await receipts.performPEPSearch(this.$root, this.persons[index], existingData, false);

            this.persons[index].uimodel.workingSearch = false;
            this.isWorking = false;
            this.$emit('reload', result.updatedCustomer);
        },

        async sendIdControl(index) {
            //console.log(this.persons[index]);
            if (!(this.persons[index].uimodel.doControl && !this.persons[index].uimodel.didControl)) return;
            this.isWorking = true;
            this.persons[index].uimodel.workingControl = true;

            const existingData = {
                customerId: this.customer._id,
                customerName: this.customer.name,
                brreg: this.$parent.brregData,
                companyAml: this.$parent.amlData
            };
            const result = await receipts.sendIdControl(this.$root, this.persons[index], existingData, false);

            this.persons[index].uimodel.workingControl = false;
            this.isWorking = false;
            this.$emit('reload', result.updatedCustomer);
        },

        onManualIdcClicked(person) {
            let index = -1;
            for (const i in this.persons) {
                if (personhelper.isSamePerson(this.persons[i], person)) index = i;
            }
            this.manualIdcPopup.index = index;
            this.manualIdcPopup.show = true;
        },

        async onManualIdcConfirm() {
            this.manualIdcPopup.working = true;

            const personId = tools.encodePerson(this.persons[this.manualIdcPopup.index]);
            await api.updatePerson(personId, {
                idc: {
                    userId: this.$root.credentials.uid,
                    completed: true,
                    completedDate: new Date(),
                    customerId: this.customer._id,
                    manual: true,
                    manualType: 'man',
                    comment: this.manualIdcPopup.comment
                }
            });

            this.manualIdcPopup.working = false;
            this.manualIdcPopup.show = false;

            this.$emit('reload', undefined);
        },

        showIdcOptions(person) {
            this.$parent.showDialog('idc-options', person);
        },

/*         showIdcWrongRecipient() {
            this.idcOptionsPopup.show = false;
            this.idcWrongRecipientPopup.show = true;
        },

        async onIdcWrongRecipient() {
            this.idcOptionsPopup.show = false;
            this.idcWrongRecipientPopup.working = true;

            await api.archiveData(this.customer._id, 'idc', 'resetIdc');
            const response = await api.updateCustomer(this.customer._id, { status: 'f-kyc-o', expires: -2 });

            this.idcWrongRecipientPopup.working = false;
            this.idcWrongRecipientPopup.show = false;
            this.$parent.loadAllData();
        }, */

    },
    async created() {
        log.v('Persons: created, persons =', this.persons);
        this.setManualRoleOptions();
        this.processPersons();
    }
}
</script>

<style scoped>

.ptt-base {
    position: absolute;
    width: 400px;
    min-height: 200px;
    padding: 20px;
    border: solid 2px var(--accent);
    background-color: var(--card-background);
    -webkit-box-shadow: 1.5px 0.5px 4px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 1.5px 0.5px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 1.5px 0.5px 4px 0px rgba(0,0,0,0.2);
}

.ptt-name {
    font-size: 20px;
    color: var(--text-accent);
}
.ptt-role {
    margin-bottom: 10px;
}

.ptt-info-line {
    display: flex;
    margin-bottom: 5px;
}
.ptt-info-ball {
    flex-shrink: 0;
    width: 24px; height: 24px;
    padding-top: 4px;
    border-radius: 12px;
    font-size: 14px;
    text-align: center;
    color: var(--success-text);
    background-color: var(--success-background);
    box-sizing: border-box;
}
.ptt-info-text {
    font-size: 16px;
    margin-left: 8px;
    padding-top: 3px;
}

/* Right-side lists */

.vep-right-header {
    display: flex;
    width: 100%;
    cursor: pointer;
}
.vep-right-header-text {
    font-size: 17px;
    font-weight: 500;
    color: var(--text-strong);
    margin-top: 7px;
    margin-left: 10px;
    flex-grow: 100;
    cursor: pointer;
}
.vep-right-header-icon {
    float: right;
    color: var(--text-strong);
    font-size: 18px;
    margin-top: 6px;
    margin-right: 10px;
    cursor: pointer;
}
.vep-item {
    display: flex;
    padding: 4px 12px;
}
.vep-item-icon {
    width: 25px;
    font-size: 18px;
}
.vep-item-content {
    flex-grow: 100;
    margin-top: 2px;
    font-family: "Roboto Slab", serif;
    color: var(--text-strong);
}
.vep-item-value {
    float: right;
    margin-top: 2px;
    margin-left: 8px;
}
.vep-item-disabled, .vep-item-resigned {
    background: var(--page-background);
}
.vep-item-disabled .vep-item-icon {
    color: var(--text-accent);
}
.vep-item-disabled .vep-item-content {
    color: var(--text-accent);
}
.vep-item-disabled .vep-item-value {
    color: var(--text-accent);
}


.vep-item-info {
    margin-top: 2px;
    margin-left: 5px;
    width: 20px; height: 20px;
    padding-top: 2px;
    border-radius: 10px;
    font-size: 13px;
    text-align: center;
    color: var(--success-text);
    background-color: var(--success-background);
    box-sizing: border-box;
}
.vep-item-pep {
    color: var(--warning-text);
    background-color: var(--warning-background);
}
.vep-item-san {
    color: var(--error-text);
    background-color: var(--error-background);
}
.vep-item-acc {
    color: var(--accent-contrast);
    background-color: var(--accent);
}
.vep-item-pen {
    color: var(--disabled-text);
    background-color: var(--disabled-background);
}

.vep-item-removed .vep-item-content {
    text-decoration: line-through;
}

.vep-item-removed .vep-item-value {
    text-decoration: line-through;
}


/* Left-side lists */

.vep-left-empty {
    padding-top: 100px;
}
.vep-left-empty-icon {
    width: 100px;
    margin: 0 auto;
}
.vep-left-empty-text {
    margin-top: 20px;
    padding: 0px 50px;
    text-align: center;
    font-size: 18px;
    color: var(--text-weak);
}

.vep-person {
    display: flex;
    width: 100%;
    padding: 15px 20px;
}
.vep-person-left {
    flex-grow: 100;
}
.vep-person-name {
    color: var(--text-accent);
    font-size: 17px;
    font-weight: bold;
}
.vep-person-role {
    margin-top: 6px;
    color: var(--text-strong);
    font-size: 15px;
    font-weight: 400;
}
.vep-person-name label, .vep-person-role label {
    cursor: pointer;
}



.vep-actions {
    padding: 10px 20px;
    padding-bottom: 30px;
    margin-top: 10px;
    margin-bottom: -10px;
    color: var(--text-strong);
}
.vep-action-base {
    margin-top: 10px;
}
.vep-action-empty {
    padding-top: 26px;
    padding-bottom: 10px;
    text-align: center;
}
.vep-action-empty-icon {
    width: 40px;
    margin: 0 auto;
    margin-bottom: 10px;
    padding-right: 30px;
}
.vep-action-midbutton {
    float: left;
    padding-top: 5px;
    width: 100%;
    text-align: center;
}
.vep-action-midinfo {
    float: left;
    padding-top: 5px;
    width: 100%;
    text-align: center;
}
.vep-action-button {
    position: absolute;
    left: 0px;
    bottom: 10px;
    width: 100%;
    text-align: center;
}
.vep-action-pending {
    padding-top: 25px;
    text-align: center;
}
.vep-action-date {
    padding-top: 12px;
    text-align: center;
}
.vep-action-status {
    margin-top: 9px;
    margin-bottom: 5px;
}
.vep-action-complete {
    position: relative;
    height: 40px;
    padding-top: 10px;
    text-align: center;
    color: white;
    border-radius: 4px;
    color: var(--success-text);
    background-color: var(--success-background);
}
.vep-action-complete-pep {
    height: 40px;
    padding-top: 10px;
    text-align: center;
    color: white;
    border-radius: 4px;
    color: var(--warning-text);
    background-color: var(--warning-background);
}
.vep-action-complete-san {
    height: 40px;
    padding-top: 10px;
    text-align: center;
    color: white;
    border-radius: 4px;
    color: var(--error-text);
    background-color: var(--error-background);
}

.vep-kyc-add {
    margin-bottom: 20px;
    padding: 10px 16px;
    border-radius: 4px;
    color: var(--help-text);
    background-color: var(--help-background);
}
.vep-kyc-pep {
    margin-bottom: 20px;
    padding: 10px 16px;
    border-radius: 4px;
    color: var(--warning-text);
    background-color: var(--warning-background);
}
.vep-kyc-pep-desc {
    position: relative;
    font-size: 14px;
}



</style>
