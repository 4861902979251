import tools from './tools';
import dataModel from './dataModel';
import dataHelper from './dataHelper';
import aml from './aml';
const dataNorway = {

    async getBankIdAmlData(root, orgnr) {
        const result = {
            success: false,
            testdata: false,
            data: {},
            reports: []
        };

        result.data = await aml.doAmlCompanySearch2(root, orgnr);
        console.log('AML DAT', result.data);

        for (const i in result.data.links.reports) {
            result.reports.push({
                url: result.data.links.reports[i].href,
                contentType: result.data.links.reports[i].contentType,
                type: this.getReportType(result.data.links.reports[i].rel),
                name: this.getReportName(result.data.links.reports[i].rel)
            });
        }

        result.success = true;
        return result;
    },

    getReportType(rel) {
        if (rel == 'links.reports.aml') {
            return 'baml-org-full';
        }else {
            return 'baml-org-inc';
        }
    },

    getReportName(rel) {
        if (rel == 'links.reports.aml') {
            return 'BankID AML Rapport.pdf';
        }else {
            return 'BankID AML Firmaattest.pdf';
        }
    },

    processData(root, output, baml, brreg) {
        //console.log('process baml', JSON.parse(JSON.stringify(baml)));
        //console.log('process brreg', JSON.parse(JSON.stringify(brreg)));

        //console.log(JSON.stringify(brreg));

        output.source = 'bankidaml';
        output.version = 1;

        // Key information
        if (baml.keyInformation) {
            output.name = root.companyTitleCase(baml.keyInformation.name);
            output.orgnr = baml.keyInformation.organizationNumber;

            output.info.country = baml.keyInformation.country;
            output.info.number = baml.keyInformation.organizationNumber;
            output.info.dunsNumber = baml.keyInformation.dunsNumber;
            output.info.dateRegistered = baml.keyInformation.registeredDate;
            output.info.purpose = baml.keyInformation.purpose;
            output.info.employeesFrom = baml.keyInformation.employees.from;
            output.info.employeesTo = baml.keyInformation.employees.to;

            output.contact.phone = baml.keyInformation.phoneNumber;
            for (const i in baml.keyInformation.metadata.sources) {
                output.info.sources.push(baml.keyInformation.metadata.sources[i]);
            }
        }
        
        if (brreg) {
            output.info.language = brreg.language;
            output.info.dateEstablished = brreg.start_date;

            output.form.formCode = brreg.org_form;
            output.form.formDescription = brreg.org_form_desc;
            output.form.industryCode = brreg.industryCode;
            output.form.industryDescription = brreg.industryDescription;

            output.registration.inVatRegistry = brreg.inMvaReg ? 'Y' : 'N';
            output.registration.inBusinessRegistry = brreg.inBusinessReg ? 'Y' : 'N';
            output.registration.inBrreg = brreg.inBrReg ? 'Y' : 'N';
            output.registration.inVoluntaryRegistry = brreg.inVoluntary ? 'Y' : 'N';
            output.registration.bankrupt = brreg.bankrupt ? 'Y' : 'N';
            output.registration.liquidation = brreg.liquidation ? 'Y' : 'N';
            output.registration.compulsoryLiquidation = brreg.compulsoryLiquidation ? 'Y' : 'N';

            output.address.business.country = brreg.country == 'Norge' ? 'NO' : '';
            output.address.business.street = brreg.address;
            output.address.business.zip = brreg.zip;
            output.address.business.city = brreg.town;
            output.address.business.commune = brreg.commune;
            output.address.business.communeNumber = brreg.communeNumber;

            for (const i in brreg.businessCodes) {
                output.naceCodes.push({
                    code: brreg.businessCodes[i].code,
                    description: brreg.businessCodes[i].description,
                });
            }

            output.registration.sources.push('BRREG');
            output.form.sources.push('BRREG');
            output.info.sources.push('BRREG');
            output.naceSources.push('BRREG');
        }
        

        // Signature rights
        if (baml.authorities && baml.authorities.signatureRights) {
            if (baml.authorities.signatureRights.description) {
                const parts = baml.authorities.signatureRights.description.split('. ');
                for (const i in parts) {
                    output.signature.description.push(parts[i]);
                }
            }
            for (const i in baml.authorities.signatureRights.options) {
                const option = { required: [] };
                for (const j in baml.authorities.signatureRights.options[i].required) {
                    const person = baml.authorities.signatureRights.options[i].required[j];
                    const names = this.getBamlNames(person.name);
                    option.required.push({
                        firstName: names.firstName,
                        lastName: names.lastName,
                        dateOfBirth: person.dateOfBirth,
                        roleId: this.getRoleId(person.role)
                    });
                }
                output.signature.options.push(option);
            }
            output.signature.conclusive = baml.authorities.signatureRights.status == 'CONCLUSIVE';
            for (const i in baml.authorities.signatureRights.remarks) {
                output.signature.remarks.push({
                    category: baml.authorities.signatureRights.remarks[i].category,
                    comment: baml.authorities.signatureRights.remarks[i].comment
                });
            }
            for (const i in baml.authorities.metadata.sources) {
                output.signature.sources.push(baml.authorities.metadata.sources[i]);
            }
            output.signature.available = true;
        }
        

        // Financials key figures
        if (baml.financials) {
            if (baml.financials.accountant.name) {
                output.economy.accountant.name = baml.financials.accountant.name;
                output.economy.accountant.orgnr = baml.financials.accountant.organizationNumber;
            }
            if (baml.financials.auditor.name) {
                output.economy.auditor.name = baml.financials.auditor.name;
                output.economy.auditor.orgnr = baml.financials.auditor.organizationNumber;
            }
            output.economy.basicAvailable = true;
        }

        // Accounting years
        if (baml.financials && baml.financials.accounting && baml.financials.accounting.years) {
            const years = JSON.parse(JSON.stringify(baml.financials.accounting.years));
            years.sort((b, a) => (a.year > b.year) ? 1 : -1);
            if (years.length > 0) output.economy.yearAvailable = true;
            if (years.length > 2) output.economy.trendAvailable = true;
            let count = 0;
            for (const i in years) {
                const key = 'year' + count;
                output.economy[key] = dataModel.getEmptyYear();
                output.economy[key].available = true;
                output.economy[key].year = years[i].year;
                output.economy[key].salesRevenues = this.fillEconomyValue(years[i].incomeStatement.salesRevenues);
                output.economy[key].otherRevenues = this.fillEconomyValue(years[i].incomeStatement.otherRevenues);
                output.economy[key].totalRevenues = this.fillEconomyValue(years[i].incomeStatement.totalRevenues);
                output.economy[key].costOfGoodsSold = this.fillEconomyValue(years[i].incomeStatement.costOfGoodsSold);
                output.economy[key].inventoryChanges = this.fillEconomyValue(years[i].incomeStatement.inventoryChanges);
                output.economy[key].salaryCosts = this.fillEconomyValue(years[i].incomeStatement.salaryCosts);
                output.economy[key].financialCosts = this.fillEconomyValue(years[i].incomeStatement.financialCosts);
                output.economy[key].depreciations = this.fillEconomyValue(years[i].incomeStatement.depreciations);
                output.economy[key].amortizations = this.fillEconomyValue(years[i].incomeStatement.amortizations);
                output.economy[key].otherOperatingCosts = this.fillEconomyValue(years[i].incomeStatement.otherOperatingCosts);
                output.economy[key].operatingProfits = this.fillEconomyValue(years[i].incomeStatement.operatingProfits);
                output.economy[key].ordinaryProfits = this.fillEconomyValue(years[i].incomeStatement.ordinaryProfits);
                output.economy[key].extraordinaryRevenues = this.fillEconomyValue(years[i].incomeStatement.extraordinaryRevenues);
                output.economy[key].extraordinaryCosts = this.fillEconomyValue(years[i].incomeStatement.extraordinaryCosts);
                output.economy[key].minorityInterests = this.fillEconomyValue(years[i].incomeStatement.minorityInterests);
                output.economy[key].taxes = this.fillEconomyValue(years[i].incomeStatement.taxes);
                output.economy[key].resultBeforeTax = this.fillEconomyValue(years[i].incomeStatement.resultBeforeTax);
                output.economy[key].netProfits = this.fillEconomyValue(years[i].incomeStatement.netProfits);
                if (years[i].balanceSheet) {
                    output.economy[key].currentAssets = this.fillEconomyValue(years[i].balanceSheet.currentAssets);
                    output.economy[key].fixedAssets = this.fillEconomyValue(years[i].balanceSheet.fixedAssets);
                    output.economy[key].sumAssets = this.fillEconomyValue(years[i].balanceSheet.sumAssets);
                    output.economy[key].equity = this.fillEconomyValue(years[i].balanceSheet.equity);
                    output.economy[key].debt = this.fillEconomyValue(years[i].balanceSheet.debt);
                    output.economy[key].sumEquityAndDebt = this.fillEconomyValue(years[i].balanceSheet.sumEquityAndDebt);
                }
                count += 1;
            }
        }

        if (baml.ownership) {
            for (const i in baml.ownership.shareholders) {

                const shareholder = dataModel.getEmptyShareholder();

                shareholder.type = baml.ownership.shareholders[i].type == 'PERSON' ? 'person' : 'org';
                shareholder.share = baml.ownership.shareholders[i].percentage;

                if (shareholder.type == 'person') {
                    const names = this.getBamlNames(baml.ownership.shareholders[i].name);
                    shareholder.firstName = names.firstName;
                    shareholder.lastName = names.lastName;
                    shareholder.name = names.firstName + ' ' + names.lastName;
                    shareholder.dateOfBirth = baml.ownership.shareholders[i].dateOfBirth;
                }else {
                    shareholder.name = baml.ownership.shareholders[i].name;
                    shareholder.orgnr = baml.ownership.shareholders[i].organizationNumber;
                }
                output.shareholders.push(shareholder);

            }
            for (const i in baml.ownership.beneficial) {

                const beneficial = dataModel.getEmptyBeneficial();

                if (baml.ownership.beneficial[i].firstName && baml.ownership.beneficial[i].dateOfBirth) {

                    if (!baml.ownership.beneficial[i].firstName || (baml.ownership.beneficial[i].firstName && baml.ownership.beneficial[i].firstName == baml.ownership.beneficial[i].name)) {
                        const names = this.getBamlNames(baml.ownership.beneficial[i].name);
                        beneficial.firstName = names.firstName;
                        beneficial.lastName = names.lastName;
                    }else {
                        beneficial.firstName = baml.ownership.beneficial[i].firstName;
                        beneficial.lastName = baml.ownership.beneficial[i].lastName;
                    }

                    beneficial.dateOfBirth = baml.ownership.beneficial[i].dateOfBirth;
                    beneficial.share = baml.ownership.beneficial[i].percentageControl;
                    beneficial.country = baml.ownership.beneficial[i].country;
                    beneficial.street = baml.ownership.beneficial[i].street;
                    beneficial.zip = baml.ownership.beneficial[i].postalCode;
                    beneficial.city = baml.ownership.beneficial[i].city;
                    beneficial.identified = true;
                }else {
                    beneficial.name = baml.ownership.beneficial[i].name;
                    beneficial.share = baml.ownership.beneficial[i].percentageControl;
                }

                output.beneficials.push(beneficial);

            }
        }

        if (baml.ownership && baml.ownership.indicators) {

            output.ownership.keyIndicators.maxShareHolderDistance = baml.ownership.indicators.keyIndicators.maxShareHolderDistance;
            output.ownership.keyIndicators.numberOfShareholdersDirect = baml.ownership.indicators.keyIndicators.numberOfShareholdersDirect;
            output.ownership.keyIndicators.numberOfShareholdersLegal = baml.ownership.indicators.keyIndicators.numberOfShareholdersLegal;
            output.ownership.keyIndicators.numberOfShareholdersTotal = baml.ownership.indicators.keyIndicators.numberOfShareholdersTotal;

            output.ownership.knowledge.numberOfUnidentifiedShareholders = baml.ownership.indicators.knowledge.numberOfUnidentifiedShareholders;
            output.ownership.knowledge.sumIdentifiedShareholders = baml.ownership.indicators.knowledge.sumIdentifiedShareholders;
            output.ownership.knowledge.sumUnidentifiedShareholders = baml.ownership.indicators.knowledge.sumUnidentifiedShareholders;
            output.ownership.knowledge.sumUnknownShareholders = baml.ownership.indicators.knowledge.sumUnknownShareholders;

            output.ownership.selfOwnership.companySelfOwnership = baml.ownership.indicators.selfOwnership.companySelfOwnership;
            output.ownership.selfOwnership.numberOfSelfOwnedShareholders = baml.ownership.indicators.selfOwnership.numberOfSelfOwnedShareholders;

            output.ownership.votingPower.numberOfHighVpLowIntegrated = baml.ownership.indicators.votingPower.numberOfHighVpLowIntegrated;
            output.ownership.votingPower.numberOfShareClasses = baml.ownership.indicators.votingPower.numberOfShareClasses;
            output.ownership.votingPower.numberOfShareholdersManyShareClasses = baml.ownership.indicators.votingPower.numberOfShareholdersManyShareClasses;
            output.ownership.votingPower.shareHoldersHaveMultipleShareClasses = baml.ownership.indicators.votingPower.shareHoldersHaveMultipleShareClasses ? 'Y' : 'N';

            for (const i in baml.ownership.indicators.keyIndicators.ownershipNaceList) {
                output.ownership.naceCodes.push({
                    code: baml.ownership.indicators.keyIndicators.ownershipNaceList[i].code,
                    description: baml.ownership.indicators.keyIndicators.ownershipNaceList[i].title,
                    share: Number(baml.ownership.indicators.keyIndicators.ownershipNaceList[i].sum),
                });
                output.ownership.naceCodes.sort((b, a) => (a.share > b.share) ? 1 : -1);
            }

            output.ownership.indicatorsAvailable = true;
        }


        if (baml.officialRoles) {

            if (baml.officialRoles.ceo && baml.officialRoles.ceo.name) {
                this.addToRoleList(baml.officialRoles.ceo, 'ceo', output);
            }
            if (baml.officialRoles.chairman && baml.officialRoles.chairman.name) {
                this.addToRoleList(baml.officialRoles.chairman, 'chair', output);
            }
            if (baml.officialRoles.deputyChairman && baml.officialRoles.deputyChairman.name) {
                this.addToRoleList(baml.officialRoles.deputyChairman, 'screening_role_deputy_chairman', output);
            }
            if (baml.officialRoles.proprietor && baml.officialRoles.proprietor.name) {
                this.addToRoleList(baml.officialRoles.proprietor, 'proprietor', output);
            }
            if (baml.officialRoles.boardMembers && baml.officialRoles.boardMembers.length) {
                baml.officialRoles.boardMembers.forEach((person) => {
                    this.addToRoleList(person, 'screening_role_board_member', output);
                });
            }
            if (baml.officialRoles.deputyBoardMembers && baml.officialRoles.deputyBoardMembers.length) {
                baml.officialRoles.deputyBoardMembers.forEach((person) => {
                    this.addToRoleList(person, 'screening_role_deputy_board_member', output);
                });
            }

        }

        

        //console.log('output', output);
    },

    addToRoleList(person, roleId, output) {
        //console.log('merging ' + roleId, person);

        if (person.name == 'Fratrådt') return;

        const role = dataModel.getEmptyRole();
        role.roleId = roleId;
        if (!person.firstName || (person.firstName && person.firstName == person.name)) {
            const names = this.getBamlNames(person.name);
            role.firstName = names.firstName;
            role.lastName = names.lastName;
        }else {
            role.firstName = person.firstName;
            role.lastName = person.lastName;
        }
        role.dateOfBirth = person.dateOfBirth;

        if (role.dateOfBirth && role.firstName && role.lastName) {
            output.roles.push(role);
        }
    },

    fillEconomyValue(input) {
        const value = dataModel.getEmptyEconValue();
        value.available = true;
        value.value = input;
        value.shortDisplay = this.formatMoneyShort(input);
        value.fullDisplay = this.formatMoney(input);
        return value;
    },

    formatMoney(amount) {
        const value = parseFloat(amount).toFixed(0);
        let str = '';
        if (value.toString().length > 9) {
            let index1 = value.toString().length - 9;
            let index2 = value.toString().length - 6;
            let index3 = value.toString().length - 3;
            str = value.toString().substring(0, index1) + ' ' + value.toString().substring(index1, index2) + ' ' + value.toString().substring(index2, index3) + ' ' + value.toString().substring(index3);
        }else if (value.toString().length > 6) {
            let index1 = value.toString().length - 6;
            let index2 = value.toString().length - 3;
            str = value.toString().substring(0, index1) + ' ' + value.toString().substring(index1, index2) + ' ' + value.toString().substring(index2);
        }else if (value.toString().length > 3) {
            let index = value.toString().length - 3;
            str = value.toString().substring(0, index) + ' ' + value.toString().substring(index);
        }else {
            str = value.toString();
        }
        return str;
    },

    formatMoneyShort(amount) {
        const value = parseFloat(amount).toFixed(0);
        let str = '';
        if (value.toString().length > 9) {
            let index1 = value.toString().length - 9;
            let index2 = value.toString().length - 6;
            let index3 = value.toString().length - 3;
            str = value.toString().substring(0, index1) + ' ' + value.toString().substring(index1, index2) + ' ' + value.toString().substring(index2, index3);
        }else if (value.toString().length > 6) {
            let index1 = value.toString().length - 6;
            let index2 = value.toString().length - 3;
            str = value.toString().substring(0, index1) + ' ' + value.toString().substring(index1, index2);
        }else if (value.toString().length > 3) {
            let index = value.toString().length - 3;
            str = value.toString().substring(0, index);
        }else {
            str = amount == 0 ? '0' : '1';
        }
        return str;
    },

    getBamlNames(name) {
        let firstName = '';
        let lastName = '';
        if (name.includes(',')) {
            const parts = name.split(', ');
            firstName = parts[1];
            lastName = parts[0];
        }else {
            firstName = (name).split(' ').slice(1).join(' ');
            lastName = (name).split(' ')[0];
        }
        return {
            firstName: this.titleCase(firstName),
            lastName: this.titleCase(lastName)
        };
    },

    titleCase(str) {
        try {
            return str.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
        }catch (ex) {
            return str;
        }
    },

    getRoleId(role) {
        switch (role) {
            case 'CEO': return 'ceo';
            case 'CHAIRMAN_OF_THE_BOARD': return 'chair';
            case 'BOARD_MEMBER': return 'screening_role_board_member';
            case 'DEPUTY_BOARD_MEMBER': return 'screening_role_deputy_board_members';
            case 'ACCOUNTANT': return 'screening_role_accountant';
        }
        return 'other';
    }

};
export default dataNorway;