import kycModuleHelper from "./kyc/kycModuleHelper";
import idcModuleHelper from "./idcontrol/idcModuleHelper";
import signModuleHelper from "./signing/signModuleHelper";
import formModuleHelper from "./formdata/formModuleHelper";
import clientModuleHelper from "./customer/clientModuleHelper";
import checklistModuleHelper from "./checklist/checklistModuleHelper";
import karModuleHelper from "./kar/karModuleHelper";
import approveModuleHelper from "./approve/approveModuleHelper";
import log from '../../../log';
const moduleHelper = {

    onFormCreatedSystem(root, reference, designer, pages, isOwner, isUsingExistingData) {

        root.$set(reference.resources, 'zipCodes', {
            type: 'list',
            key: 'zipCodes',
            name: 'Zip Codes',
            module: 'sys',
            definition: {
                value: 'string',
                en_EN: 'string',
                nb_NO: 'string',
                sv_SE: 'string',
            },
            items: []
        });

        // Prefill values
        if (!isUsingExistingData) {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    for (const k in pages[i].cards[j].inputs) {
                        if (pages[i].cards[j].inputs[k].logic && pages[i].cards[j].inputs[k].logic.field && pages[i].cards[j].inputs[k].logic.field.type == 'sys') {
                            pages[i].cards[j].inputs[k].model = this.getConnectedSystemValue(root, pages[i].cards[j].inputs[k].logic.field.key);
                        }
                    }
                }
            }
        }
        
    },

    getConnectedSystemValue(root, key) {

        console.log('key', key);

        switch (key) {
            case 'date-obj': return new Date().toISOString().substring(0, 10).replace('T', ' ');
            case 'user-name': return root.user.givenName + ' ' + root.user.familyName;
        }
        
        return '';
    },

    async onFormCreated(root, reference, designer, module, pages, isOwner, userdata, isUsingExistingData) {

        if (module.type == 'kyc') {
            kycModuleHelper.onFormCreated(root, reference, designer, module, pages, isOwner, userdata, isUsingExistingData);
        }else if (module.type == 'idc') {
            await idcModuleHelper.onFormCreated(root, reference, designer, module, pages, isOwner, userdata, isUsingExistingData);
        }else if (module.type == 'chk') {
            checklistModuleHelper.onFormCreated(root, reference, designer, module, pages, isOwner, userdata, isUsingExistingData);
        }else if (module.type == 'sign') {
            signModuleHelper.onFormCreated(root, reference, designer, module, pages, isOwner, userdata, isUsingExistingData);
        }else if (module.type == 'form') {
            await formModuleHelper.onFormCreated(root, reference, designer, module, pages, isOwner, userdata, isUsingExistingData);
        }else if (module.type == 'kar') {
            await karModuleHelper.onFormCreated(root, reference, designer, module, pages, isOwner, userdata, isUsingExistingData);
        }else if (module.type == 'app') {
            await approveModuleHelper.onFormCreated(root, reference, designer, module, pages, isOwner, userdata, isUsingExistingData);
        }
        
    },

    async onSetUserdata(root, reference, module, envelope, userdata, isOwner, recipient) {

        if (module.type == 'kyc') {
            kycModuleHelper.onSetUserdata(root, reference, module, envelope, userdata, isOwner, recipient);
        }else if (module.type == 'idc') {
            await idcModuleHelper.onSetUserdata(root, reference, module, envelope, userdata, isOwner, recipient);
        }else if (module.type == 'sign') {
            await signModuleHelper.onSetUserdata(root, reference, module, envelope, userdata, isOwner, recipient);
        }else if (module.type == 'form') {
            await formModuleHelper.onSetUserdata(root, reference, module, envelope, userdata, isOwner, recipient);
        }else if (module.type == 'chk') {
            await checklistModuleHelper.onSetUserdata(root, reference, module, envelope, userdata, isOwner, recipient);
        }else if (module.type == 'kar') {
            await karModuleHelper.onSetUserdata(root, reference, module, envelope, userdata, isOwner, recipient);
        }else if (module.type == 'app') {
            await approveModuleHelper.onSetUserdata(root, reference, module, envelope, userdata, isOwner, recipient);
        }

        
    },

    async onSetMetadata(root, reference, module, envelope, userdata, metadata, roleKey, recipient) {

        if (module.type == 'chk') {
            await checklistModuleHelper.onSetMetadata(root, reference, module, envelope, userdata, metadata, roleKey, recipient);
        }else if (module.type == 'idc') {
            await idcModuleHelper.onSetMetadata(root, reference, module, envelope, userdata, metadata, roleKey, recipient);
        }

        
    },

    async onFormSubmitted(root, reference, module, nextRoleKey, envelope) {

        if (module.type == 'kyc') {
            await kycModuleHelper.onFormSubmitted(root, reference, module, nextRoleKey, envelope);
        }else if (module.type == 'kar') {
            await karModuleHelper.onFormSubmitted(root, reference, module, nextRoleKey, envelope);
        }
        
    },

    onAction(root, reference, designer, module, actionKey) {
        if (module.type == 'idc') {
            idcModuleHelper.onAction(root, reference, designer, module, actionKey);
        }
    },

    onSystemAction(root, reference, designer, actionKey) {
        log.x('System Module: On action, key:', actionKey);

        if (actionKey == 'submit') {
            reference.onStepperSubmit();
        }

        if (actionKey == 'valform') {
            reference.validateEverything();
        }
    },

    onLinkValue(root, reference, designer, module, link, value) {
        if (module.type == 'idc') {
            idcModuleHelper.onLinkValue(root, reference, designer, module, link, value);
        }else if (module.type == 'sign') {
            signModuleHelper.onLinkValue(root, reference, designer, module, link, value);
        }else if (module.type == 'chk') {
            checklistModuleHelper.onLinkValue(root, reference, designer, module, link, value);
        }else if (module.type == 'kar') {
            karModuleHelper.onLinkValue(root, reference, designer, module, link, value);
        }else if (module.type == 'app') {
            approveModuleHelper.onLinkValue(root, reference, designer, module, link, value);
        }
    },

    onSystemLinkValue(root, reference, designer, link, value) {
        log.x('System Module: On link value (' + link.link + ')', value);

        if (link.link == 'owner-editor') {

            reference.ownerIsEditor = value[link.key];
            reference.validateEverything();

        }

    },

    getModuleName(type) {
        switch (type) {
            case 'kyc': return 'KYC Module';
            case 'idc': return 'ID Control Module';
            case 'sign': return 'Signing Module';
            case 'form': return 'Form Data Module';
            case 'client': return 'Customer Module';
            case 'chk': return 'Checklist Module';
            case 'kar': return 'KAR Module';
            case 'app': return 'Approve Module';
        }
        return 'Unknown Module';
    },

    getModuleHint(type) {
        switch (type) {
            case 'kyc': return 'This module makes it possible to connect form fields to customer data from the AML-portal';
            case 'idc': return 'This module will add an ID-control to the form';
            case 'sign': return 'This module will add signing support to the form';
            case 'form': return 'This module adds the option to import data from another form';
            case 'client': return 'Adds options to connect and update a customer in the AML-portal';
            case 'chk': return 'Checklist Module';
            case 'kar': return 'Add the option to validate a norwegian bank account';
            case 'app': return 'Add advanced approval options to the form';
        }
        return 'Unknown Module';
    },

    getAllModules() {
        const modules = [];

        modules.push({
            type: 'client',
            name: this.getModuleName('client'),
            hint: this.getModuleHint('client'),
            icon: clientModuleHelper.getEmptyConfig().icon
        });

        modules.push({
            type: 'kyc',
            name: this.getModuleName('kyc'),
            hint: this.getModuleHint('kyc'),
            icon: kycModuleHelper.getEmptyConfig().icon
        });

        modules.push({
            type: 'idc',
            name: this.getModuleName('idc'),
            hint: this.getModuleHint('idc'),
            icon: idcModuleHelper.getEmptyConfig().icon
        });

        modules.push({
            type: 'sign',
            name: this.getModuleName('sign'),
            hint: this.getModuleHint('sign'),
            icon: signModuleHelper.getEmptyConfig().icon
        });

        modules.push({
            type: 'app',
            name: this.getModuleName('app'),
            hint: this.getModuleHint('app'),
            icon: approveModuleHelper.getEmptyConfig().icon
        });

        modules.push({
            type: 'form',
            name: this.getModuleName('form'),
            hint: this.getModuleHint('form'),
            icon: formModuleHelper.getEmptyConfig().icon
        });

        modules.push({
            type: 'kar',
            name: this.getModuleName('kar'),
            hint: this.getModuleHint('kar'),
            icon: karModuleHelper.getEmptyConfig().icon
        });

        return modules;
    },

    getEmptyConfig(type) {
        if (type == 'kyc') return kycModuleHelper.getEmptyConfig();
        if (type == 'idc') return idcModuleHelper.getEmptyConfig();
        if (type == 'sign') return signModuleHelper.getEmptyConfig();
        if (type == 'form') return formModuleHelper.getEmptyConfig();
        if (type == 'client') return clientModuleHelper.getEmptyConfig();
        if (type == 'kar') return karModuleHelper.getEmptyConfig();
        if (type == 'app') return approveModuleHelper.getEmptyConfig();
    },

    hasModule(reference, type) {
        for (const i in reference.modules) {
            if (reference.modules[i].type == type) return true;
        }
        return false;
    },

};
export default moduleHelper;