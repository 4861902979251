<template>
<div class="vf-checkbox">

    <div @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" class="vf-checkbox-base" :class="[
        {'vf-checkbox-inline' : inline},
        {'vf-checkbox-disabled': disabled},
        {'vf-checkbox-invalid': validation.done && !validation.valid },
    ]">

        <div v-for="(item, i) in items" :key="id + item.key" class="vf-checkbox-item" @mouseup="handleOptionMouseUp(item.key, $event)">

            <div class="vf-checkbox-check" @click="handleClick(i)">
                <CheckboxUI :radio="radio" :checked="isChecked(i)" />
            </div>

            <div class="vf-checkbox-text" @click="handleClick(i)">
                {{ item.text[$root.locale.key] }}
            </div>

        </div>

    </div>

</div>
</template>

<script>
import CheckboxUI from '../designer/ui/CheckboxUI.vue';

export default {
    name: 'vf-checkbox-v2',
    components: {
        CheckboxUI,
    },
    props: {
        value: { type: [String, Boolean, Object], default: null },
        items: { type: Array, default: null },

        inline: { type: Boolean, default: false },
        radio: { type: Boolean, default: false },
        yesno: { type: Boolean, default: false },
        min: { type: [Number, String], default: 0 },

        id: { type: String, default: 'id' },
        designer: { type: Boolean, default: false },
        activeLogic: { type: Boolean, default: false  },
        
        validation: { type: Object, default: () => { return { done: false } }},
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

        isChecked(index) {
            if (this.value == null) return false;
            if (this.items.length == 1) {
                if (this.designer) {
                    return this.items[0].default;
                }else {
                    return this.value;
                }
            }else if (this.yesno) {
                if (this.designer) {
                    return this.items[index].default;
                }else {
                    if (this.items[index].modelKey == 'yes') {
                        return (this.value != null && (typeof this.value != 'string') && this.value);
                    }else if (this.items[index].modelKey == 'no') {
                        return (this.value != null && (typeof this.value != 'string') && !this.value);
                    }
                }
                return false;
            }else {
                if (this.designer) {
                    return this.items[index].default;
                }else {
                    return this.value[this.items[index].modelKey];
                }
            }
        },

        handleClick(index) {
            if (this.disabled) return;
            if (this.designer) return;
            let value = {};
            if (this.items.length == 1) {
                value = !this.value;
            }else if (this.yesno) {
                if (this.items[index].modelKey == 'yes') {
                    value = true;
                }else if (this.items[index].modelKey == 'no') {
                    value = false;
                }
            }else {
                if (this.radio) {
                    for (const k in this.items) value[this.items[k].modelKey] = false;
                    value[this.items[index].modelKey] = true;
                }else {
                    for (const k in this.items) value[this.items[k].modelKey] = this.value ? this.value[this.items[k].modelKey] || false : false;
                    value[this.items[index].modelKey] = this.value ? !this.value[this.items[index].modelKey] || false : true;
                }
            }
            console.log('value changed', value);
            this.$emit('input', value);
        },

        handleMouseEnter(e) {
            this.$emit('connect-enter', this.id);
        },

        handleMouseLeave(e) {
            this.$emit('connect-leave', this.id);
        },

        handleOptionMouseUp(key, e) {
            if (this.activeLogic) {
                this.$emit('connect', key);
            }
        },

    },
    created() {
        //console.log('checkbox created', this.value);
    }
}
</script>
<style scoped>

.vf-checkbox {
    float: left;
    width: 100%;
}

.vf-checkbox-base {
    float: left;
}

.vf-checkbox-item {
    display: flex;
    margin-top: 4px;
}

.vf-checkbox-text {
    font-size: 16px;
    font-weight: 400;
    margin-top: 3px;
    margin-left: 10px;
    color: var(--input-text);
}


.vf-checkbox-inline {
    display: flex;
}
.vf-checkbox-inline .vf-checkbox-item {
    margin-right: 20px;
}


/* --- Validation -------------------- */

.vf-cb-invalid .vap-cb-check {
    border-color: var(--val-error);
}

.vf-cb-invalid.vap-cb-card {
    border-color: var(--val-error);
}

/* --- ---------- -------------------- */



/* design mode */

.logic .vf-checkbox-item:hover .vf-checkbox-text {
    color: #0DCC27;
    font-weight: bold;
}

/* --- ---------- -------------------- */

</style>