const yyPerson = {

    getFlow() {
        return {
            key: 'yy-person',
            name: 'Person Flow',
            type: 'person',
            countries: 'xx',
            isPerson: true,
            tabs: {
                overview: { key: 'common_overview', icon: 'checklist' },
                persons: { key: 'common_persons', icon: 'people' },
                report: { key: 'company_final_report', icon: 'verified' }
            },
            title: {
                en_EN: 'Person Flow with Self-declaration',
                nb_NO: 'Personflyt med egenerklæring',
                sv_SE: 'Personflöde med kundkännedom'
            },
            steps: [
                { key: 'yy-person-kyc' },
                { key: 'zz-person-idc' },
                { key: 'zz-person-scr' },
                { key: 'zz-person-rep' }
            ],
            statuses: [
                {
                    key: 'b-id-a',
                    text: {
                        en_EN: 'Awaiting ID control',
                        nb_NO: 'Avventer ID kontroll',
                        sv_SE: 'Väntar på ID-kontroll',
                    },
                    triggers: []
                },
                {
                    key: 'b-rep-a',
                    text: {
                        en_EN: 'Awaiting final report',
                        nb_NO: 'Avventer sluttrapport',
                        sv_SE: 'Väntar på slurapport'
                    },
                    triggers: [
                        { type: 'person', trg: 'scr-all', rev: false },
                        { type: 'person', trg: 'idc-all', rev: false },
                    ]
                },
                {
                    key: 'x-com-a',
                    completed: true,
                    text: {
                        en_EN: 'Completed',
                        nb_NO: 'Fullført',
                        sv_SE: 'Färdiga'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ]
                },
                {
                    key: 'z-ch-c',
                    completed: false,
                    text: {
                        en_EN: '! - Changed after completion',
                        nb_NO: '! - Endret etter ferdigstillelse',
                        sv_SE: '! - Ändrad efter färdigställande'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                        { type: 'report', trg: 'changed', rev: false },
                    ]
                }
            ],
            events: {
                items: [
                    {
                        key: 'event01',
                        text: {
                            en_EN: 'Customer created by {{createdUser}}',
                            nb_NO: 'Kunde opprettet av {{createdUser}}',
                            sv_SE: 'Kund skapad av {{createdUser}}'
                        },
                        date: { type: 'org', date: 'searchDate' },
                        triggers: [
                            { type: 'client', trg: 'created', rev: false },
                        ]
                    },
                    {
                        key: 'event02',
                        text: {
                            en_EN: 'Self declaration form sent by {{kycp01-senderName}}',
                            nb_NO: 'Egenerklæringsskjema sendt ut av {{kycp01-senderName}}',
                            sv_SE: 'Kundkännedom skickat från {{kycp01-senderName}}'
                        },
                        date: { type: 'form', key: 'kycp01', date: 'sentDate' },
                        triggers: [
                            { type: 'form', key: 'kycp01', trg: 'sent', rev: false },
                        ]
                    },
                    {
                        key: 'event03',
                        text: {
                            en_EN: 'Self declaration filled by {{kycp01-editorName}}',
                            nb_NO: 'Egenerklæringsskjema fylt ut av {{kycp01-editorName}}',
                            sv_SE: 'Kundkännedom ifylld av {{kycp01-editorName}}'
                        },
                        date: { type: 'form', key: 'kycp01', date: 'filledDate' },
                        triggers: [
                            { type: 'form', key: 'kycp01', trg: 'filled', rev: false },
                        ]
                    },
                    {
                        key: 'event04',
                        text: {
                            en_EN: 'Self declaration form signed by all signers',
                            nb_NO: 'Egenerklæringsskjema signert av alle signatarer',
                            sv_SE: 'Kundkännedom undertecknat av alla undertecknare'
                        },
                        date: { type: 'form', key: 'kycp01', date: 'signedDate' },
                        triggers: [
                            { type: 'form', key: 'kycp01', trg: 'signed', rev: false },
                        ]
                    },
                    {
                        key: 'event06',
                        text: {
                            en_EN: 'Sluttrapport opprettet',
                            nb_NO: 'Sluttrapport opprettet',
                            sv_SE: 'Slutrapport skapad av {{reportUser}}',
                        },
                        date: { type: 'report', date: 'createdDate' },
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ]
                    }
                ]
            },
            report: [
                {
                    key: 'roles',
                    icon: 'person',
                    comment: true,
                    separation: false,
                    title: {
                        en_EN: 'Individual',
                        nb_NO: 'Privatperson',
                        sv_SE: 'Privatperson'

                    },
                    requirements: [
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Individual not assessed',
                                nb_NO: 'Privatperson ikke vurdert',
                                sv_SE: 'Privatperson ej bedömd'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                },
                {
                    key: 'kyc',
                    icon: 'form',
                    comment: true,
                    separation: true,
                    includeFile: {
                        enabled: true,
                        formKey: 'kycp01',
                        name: 'Egenerklæring'
                    },
                    title: {
                        en_EN: 'Self-declaration',
                        nb_NO: 'Egenerklæring',
                        sv_SE: 'Kundkännedom'
                    },
                    requirements: [
                        {
                            key: 'org-search',
                            message: {
                                en_EN: 'Self-declaration not completed and signed',
                                nb_NO: 'Egenerklæringsskjema ikke fullført',
                                sv_SE: 'Kundkännedom ej ifylld och undertecknad'
                            },
                            triggers: [
                                { type: 'form', key: 'kycp01', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Self-declaration not assessed',
                                nb_NO: 'Egenerklæring ikke vurdert',
                                sv_SE: 'Kundkännedom ej bedömd'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ],
                    actions: [
                        {
                            icon: 'pdf',
                            main: false,
                            action: 'preview',
                            key: 'kycp01',
                            triggers: [
                                { type: 'form', key: 'kycp01', trg: 'filled', rev: false },
                            ],
                            text: {
                                en_EN: 'Show receipt',
                                sv_SE: 'Visa kvitto'
                            },
                            hint: {
                                en_EN: 'Open the PDF version of the form',
                                sv_SE: 'Öppna PDF-versionen av formuläret'
                            }
                        },
                    ],
                    items: [
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Not ready',
                                nb_NO: 'Ikke klar',
                                sv_SE: 'Inte redo'
                            },
                            value: {
                                en_EN: 'Self-declaration not filled in and signed',
                                nb_NO: 'Egenerklæring ikke fylt ut og signert',
                                sv_SE: 'Kundkännedom ej ifylld och undertecknad'

                            },
                            triggers: [
                                { type: 'form', key: 'kycp01', trg: 'signed', rev: true },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Signed by',
                                nb_NO: 'Signert av',
                                sv_SE: 'Undertecknad av'
                            },
                            value: {
                                en_EN: '{{kycp01-signerNames}}',
                                nb_NO: '{{kycp01-signerNames}}',
                                sv_SE: '{{kycp01-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kycp01', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            type: 'comparison'
                        }
                    ]
                },
                {
                    key: 'kar',
                    icon: 'bank',
                    comment: true,
                    separation: true,
                    stepCondition: 'no-kar',
                    includeFile: {
                        enabled: true,
                        formKey: 'kar99',
                        name: 'KAR-oppslag'
                    },
                    title: {
                        en_EN: 'KAR-lookup',
                        nb_NO: 'KAR-oppslag'
                    },
                    requirements: [
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'KAR-lookup not assessed',
                                nb_NO: 'KAR-oppslag ikke vurdert'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ],
                    actions: [
                        {
                            icon: 'pdf',
                            main: false,
                            action: 'preview',
                            key: 'kar99',
                            triggers: [
                                { type: 'form', key: 'kar99', trg: 'signed', rev: false },
                            ],
                            text: {
                                en_EN: 'Show receipt'
                            },
                            hint: {
                                en_EN: 'Open the PDF version of the form'
                            }
                        },
                    ],
                    items: [
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Not performed',
                                nb_NO: 'Ikke utført'
                            },
                            value: {
                                en_EN: 'KAR-loopup not performed',
                                nb_NO: 'Det er ikke utført KAR-oppslag'
                            },
                            triggers: [
                                { type: 'form', key: 'kar99', trg: 'signed', rev: true },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '6',
                            validTextOnly: true,
                            text: {
                                en_EN: 'Identity',
                                nb_NO: 'Identitet'
                            },
                            value: {
                                en_EN: '{{kar99-karIdentity}}',
                                nb_NO: '{{kar99-karIdentity}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kar99', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '6',
                            validTextOnly: true,
                            text: {
                                en_EN: 'Account Number',
                                nb_NO: 'Kontonummer'
                            },
                            value: {
                                en_EN: '{{kar99-karAccount}}',
                                nb_NO: '{{kar99-karAccount}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kar99', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Result',
                                nb_NO: 'Resultat'
                            },
                            value: {
                                en_EN: '{{kar99-karMessage}}',
                                nb_NO: '{{kar99-karMessage}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kar99', trg: 'signed', rev: false },
                            ],
                        },
                    ]
                },
                {
                    key: 'form',
                    icon: 'input-check',
                    comment: false,
                    separation: true,
                    configCondition: 'reportChecklist',
                    formKey: 'checklist01',
                    title: {
                        en_EN: 'Checklist',
                        nb_NO: 'Sjekkliste',
                        sv_SE: 'Checklista'
                    },
                    requirements: [
                        {
                            key: 'report-form',
                            message: {
                                en_EN: 'Checklist not completed',
                                nb_NO: 'Sjekkliste ikke fullført',
                                sv_SE: 'Checklista ej ifylld'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'form-valid', rev: false },
                            ],
                        },
                    ]
                },
                {
                    key: 'assessment',
                    icon: 'check',
                    comment: true,
                    separation: true,
                    title: {
                        en_EN: 'Final assessment / risk assessment',
                        nb_NO: 'Totalvurdering',
                        sv_SE: 'Helhetsbedömning'
                    },
                    requirements: [
                        {
                            key: 'report-risk',
                            message: {
                                en_EN: 'Risk classification not set',
                                nb_NO: 'Risikoklassifisering ikke satt',
                                sv_SE: 'Riskklassificering ej fastställd'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'risk-set', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Final assessment not completed',
                                nb_NO: 'Totalvurdering ikke fullført',
                                sv_SE: 'Helhetsbedömning ej genomförd'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                }
            ]
        };
    },

    //#region Steps -------------------------------------------------------------------------------------------------------
    
    getKycStep() {
        return {
            key: 'yy-person-kyc',
            isDefault: true,
            resetMode: 'form',
            form: 'kycp01',
            name: 'Default Physical Person Self-Declaration Form',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'kycp01',
                    triggers: [
                        { type: 'form', key: 'kycp01', trg: 'sent', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form',
                        nb_NO: 'Åpne skjema',
                        sv_SE: 'Öppna formuläret',
                    },
                    hint: {
                        en_EN: 'Open the form'
                    }
                },
                {
                    icon: 'refresh',
                    main: false,
                    action: 'form-start',
                    key: 'kycp01',
                    triggers: [
                        { type: 'form', key: 'kycp01', trg: 'sent', rev: true },
                        { type: 'form', key: 'kycp01', trg: 'saved', rev: false },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: 'Start over again',
                        nb_NO: 'Start utfylling på nytt',
                        sv_SE: 'Börja om igen',
                    }
                },
                {
                    icon: 'open',
                    main: true,
                    action: 'form-amend',
                    key: 'kycp01',
                    triggers: [
                        { type: 'form', key: 'kycp01', trg: 'sent', rev: true },
                        { type: 'form', key: 'kycp01', trg: 'saved', rev: false },
                    ],
                    text: {
                        en_EN: 'Continue process',
                        nb_NO: 'Fortsett utfylling',
                        sv_SE: 'Fortsätt processen',
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'preview',
                    key: 'kycp01',
                    triggers: [
                        { type: 'form', key: 'kycp01', trg: 'filled', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'kycp01',
                    triggers: [
                        { type: 'form', key: 'kycp01', trg: 'sent', rev: false },
                        { type: 'form', key: 'kycp01', trg: 'signed', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'This action will send a reminder to the recipient'
                    }
                },
                {
                    icon: 'refresh',
                    main: false,
                    action: 'form-resend',
                    key: 'kycp01',
                    triggers: [
                        { type: 'form', key: 'kycp01', trg: 'sent', rev: false },
                    ],
                    text: {
                        en_EN: 'Resend',
                    },
                    hint: {
                        en_EN: 'Resend the form with filled data',
                        nb_NO: 'Send skjema på nytt med utfylt informasjon',
                        sv_SE: 'Skicka formuläret igen med ifyllda uppgifter',
                    }
                },
                {
                    icon: 'update',
                    main: false,
                    action: 'form-update',
                    key: 'kycp01',
                    configCondition: 'updateButton',
                    triggers: [
                        { type: 'form', key: 'kycp01', trg: 'sent', rev: false },
                        { type: 'form', key: 'kycp01', trg: 'signed', rev: true },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: 'Update status',
                        nb_NO: 'Oppdater status',
                        sv_SE: 'Update status',
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'form-reset',
                    key: 'kycp01',
                    triggers: [
                        { type: 'form', key: 'kycp01', trg: 'sent', rev: false },
                        /* { type: 'form', key: 'kycp01', trg: 'signed', rev: true }, */
                    ],
                    text: {
                        en_EN: 'Reset form'
                    },
                    hint: {
                        en_EN: 'This action will reset the form so that a new one can be sent'
                    }
                }
            ],
            title: {
                en_EN: 'Self-Declaration form',
                nb_NO: 'Egenerklæringsskjema',
                sv_SE: 'Kundkännedom'
            },
            hint: {
                en_EN: 'This step does not have a hint yet',
                nb_NO: 'Fylles ut av kundens kontaktperson og sørger for å hente inn oppdatert data om kunden og PEP.',
                sv_SE: 'Fylls i av kundens kontaktperson och ser till att samla in uppdaterad information om kunden och PEP'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Awaiting filling by {{kycp01-nextName}}',
                                nb_NO: 'Avventer utfylling fra {{kycp01-nextName}}',
                                sv_SE: 'Väntar på färdigställande från {{kycp01-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kycp01', trg: 'sent', rev: false },
                                { type: 'form', key: 'kycp01', trg: 'filled', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting signing by {{kycp01-nextName}}',
                                nb_NO: 'Avventer signering fra {{kycp01-nextName}}',
                                sv_SE: 'Väntar på signering från {{kycp01-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kycp01', trg: 'filled', rev: false },
                                { type: 'form', key: 'kycp01', trg: 'signed', rev: true },
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Signed by {{kycp01-signerNames}}',
                                nb_NO: 'Signert av {{kycp01-signerNames}}',
                                sv_SE: 'Undertecknad av {{kycp01-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kycp01', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default yyPerson;