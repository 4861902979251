import dataModelV1 from '../v1data/dataModelV1';
import dataNorwayV1 from '../v1data/dataNorwayV1';
import dataSwedenV1 from '../v1data/dataSwedenV1';
import dataUKV1 from '../v1data/dataUKV1';

import v1api from '../v1helpers/v1api';
import v1flows from '../v1helpers/v1flows';
import v1receipt from '../v1helpers/v1receipt';
import tools from '../tools';

const v1lookupHelper = {

    useTestResponse: false,

    //#region Loading -----------------------------------------------------------------------------------------------------

    async performScreening(root, client, person, useSSN, progress) {

        
        //await this.performScreeningTrapets(root, client, person, progress);

        if (root.config.screening.nordicProvider == 'baml') {

            await this.performScreeningBankIDAML(root, client, person, useSSN, progress);

        }else {

            await this.performScreeningTrapets(root, client, person, useSSN, progress);

        }

        

        //await new Promise(r => setTimeout(r, 5000));

    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    async createClient(root, params, companyData, flow) {

        console.log('params', params);
        console.log('companyData', companyData);
        console.log('flow', flow);

        const client = {};

        client.name = params.name;
        client.identity = params.identity;
        client.country = params.country;

        if (!client.identity) {
            client.identity = 'AUTO_' + tools.getUniqueId().toUpperCase();
        }

        client.type = params.type;
        client.source = params.source;
        client.flow = flow.key;
        client.letter = this.getLetter(client.name);

        if (root.config.params.hasDepartments) client.department = root.credentials.branch.toString();
        if (root.config.externalId.searchEnabled && params.externalId) client.externalId = params.externalId.toString();
        if (root.config.branches.searchEnabled && params.branch) client.branch = params.branch.toString();
        if (root.config.partners.searchEnabled && params.partner) client.partner = params.partner.toString();
        if (params.tags) client.tags = params.tags;

        if (client.type == 'org' && !companyData) {
            companyData = dataModelV1.getEmptyCompany();
        }

        console.log('CLIENT', client);

        const response = await v1api.createClient(client, companyData, params.personData);
        console.log('response', response);

        return response;
    },

    getLetter(name) {
        let letter = '#';
        const validLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (validLetters.includes(name.charAt(0).toUpperCase())) {
            letter = name.charAt(0).toUpperCase();
        }
        return letter;
    },


    //#region Helpers -----------------------------------------------------------------------------------------------------

    async getCompanyData(root, source, identity, initialData) {
        const result = {
            companyData: dataModelV1.getEmptyCompany(),
            reports: [],
            rawData: null
        };

        if (source == 'chouse') {
            // UK

            const chouseData = await dataUKV1.getCompanyData(identity);
            result.rawData = {
                chouseData: chouseData
            };
            dataUKV1.processData(result.companyData, chouseData.data);

        }else if (source == 'bankidaml') {
            // Norway

            const bamlData = await dataNorwayV1.getBankIdAmlData(root, identity);
            dataNorwayV1.processData(root, result.companyData, bamlData.data, initialData.brreg);
            result.reports = bamlData.reports;

        }else if (source == 'trapets-bisnode') {
            // Sweden

            const bisnodeData = await dataSwedenV1.getBisnodeData(identity);
            dataSwedenV1.processData(root, result.companyData, initialData.trapetsData, bisnodeData.data);

            console.log('company', result.companyData);

        }

        return result;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default v1lookupHelper;