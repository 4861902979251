<template>
<div>
<div class="frv2canvas-help" v-if="logicActive" />
<div class="frv2canvas-help-text" v-if="logicActive">
    <div class="frv2canvas-help-sub">
        <label style="font-size: 22px;">{{ 'Right-click to exit logic mode' }}</label><br>
        <label style="font-size: 17px; opacity: 0.6;">{{ 'Hover a page for 2 seconds to switch' }}</label>
    </div>
    <div class="frv2canvas-buttons">
        <FormMenuButton :icon="'people'" style="margin-top: 12px; margin-right: 548px;" :logicActive="logicActive" @connect="$parent.onShowRecipientLogic">
            <template v-slot:tooltip>
                <vap-tooltip :text="'Recipients / flow'" />
            </template>
        </FormMenuButton>
<!--             <FormMenuButton v-if="designer" :icon="'check'" style="margin-top: 12px; margin-right: 250px;">
            <template v-slot:tooltip>
                <vap-tooltip :text="'Validation'" />
            </template>
        </FormMenuButton> -->
        <FormMenuButton :icon="'resource'" style="margin-top: 12px; margin-right: 296px;" :logicActive="logicActive" @connect="$parent.onShowConnectResource">
            <template v-slot:tooltip>
                <vap-tooltip :text="'Resources'" />
            </template>
        </FormMenuButton>
        <FormMenuButton :icon="'logic'" style="margin-top: 12px; margin-right: 4px;" :logicActive="logicActive" @connect="$parent.onShowConnectLogicDesigner">
            <template v-slot:tooltip>
                <vap-tooltip :text="'Logic'" />
            </template>
        </FormMenuButton>
        <FormMenuButton :icon="'modules'" style="margin-top: 12px; margin-right: 4px;" :logicActive="logicActive" @connect="$parent.onShowConnectModule">
            <template v-slot:tooltip>
                <vap-tooltip :text="'Modules'" />
            </template>
        </FormMenuButton>
        <FormMenuButton :icon="'attachment'" style="margin-top: 12px; margin-right: 4px;" :logicActive="logicActive" @connect="$parent.onShowConnectAttachment">
            <template v-slot:tooltip>
                <vap-tooltip :text="'Attachments'" />
            </template>
        </FormMenuButton>
        <FormMenuButton :icon="'settings'" style="margin-top: 12px; margin-right: 4px;" :logicActive="logicActive" @connect="$parent.onShowConnectSystem">
            <template v-slot:tooltip>
                <vap-tooltip :text="'System'" />
            </template>
        </FormMenuButton>
    </div>
</div>
</div>
</template>
<script>
import FormMenuButton from './FormMenuButton.vue';

export default {
    name: 'FormMenuLogic',
    components: {
        FormMenuButton
    },
    props: {
        logicActive: { type: Boolean, default: false },
        scrolled: { type: Boolean, default: false },
    },
}
</script>
<style scoped>

.frv2canvas-help {
    position: absolute;
    top: 0px; left: 0px;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.2;
    pointer-events: none;
    z-index: 11;
}

.frv2canvas-help-text {
    position: fixed;
    top: 0px; left: 0px;
    width: 100%;
    height: 62px;
    color: white;
    background-color: black;
    box-sizing: border-box;
    opacity: 0.9;
    pointer-events: none;
    z-index: 10;
}
.frv2canvas-help-sub {
    padding-top: 38px;
    padding-left: 40px;
}

.frv2canvas-help-text .frv2canvas-help-sub {
    padding-top: 6px;
}

.frv2canvas-buttons {
    position: absolute;
    bottom: 0px; right: 0px;
    height: 60px;
    display: flex;
    flex-direction: row-reverse;
    pointer-events: all;
}

</style>
