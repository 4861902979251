<template>
<div v-if="!loading">

    <div class="frv2-nosuchform" v-if="noAccess">
        <span v-if="$root.credentials.cid != 'r1MZtfWW7'">{{ 'Backoffice can not be accessed from this account!' }}</span>
        <span v-if="$root.credentials.cid == 'r1MZtfWW7'">{{ 'You do not have AML backoffice access!' }}</span>
    </div>

    <div v-if="!noAccess">

        <div class="flw100 amlbo-line1" />
        <vap-tabs accent v-model="tabModel" :tabs="tabOptions" />
        <div class="flw100 amlbo-line2" />

        <div v-if="tabModel.accounts">
            <BoAccounts :admins="admins" />
        </div>
        <div v-if="tabModel.errors">
            <BoErrors />
        </div>
        <div v-if="tabModel.stats">
            <BoStats />
        </div>

    </div>

</div>
</template>
<script>
import v1backoffice from '../v1admin/v1backoffice';

import BoAccounts from '../v1admin/BoAccounts.vue';
import BoErrors from '../v1admin/BoErrors.vue';
import BoStats from '../v1admin/BoStats.vue';

export default {
    name: 'BackofficeV1',
    components: {
        BoAccounts,
        BoErrors,
        BoStats,
    },
    data() {
        return {
            loading: true,
            noAccess: true,
            tabModel: {
                stats: false,
                accounts: true,
                errors: false,
            },
            tabOptions: {
                stats: { label: 'Usage Stats' },
                accounts: { label: 'Accounts' },
                errors: { label: 'Errors' },
            },
            admins: []
        }
    },
    methods: {
        
        

    },
    async created() {
        try {
            const result = await v1backoffice.checkBackofficeAccess();
            this.admins = result.admins;
            this.noAccess = false;
        }catch (ex) {
            this.noAccess = true;
        }
        this.loading = false;
    }
}
</script>
<style scoped>

.amlbo-line1 {
    border-top: solid 1px var(--header-sep);
}

.amlbo-line2 {
    border-top: solid 1px var(--header);
}

.frv2-nosuchform {
    width: fit-content;
    margin: 100px 100px;
    padding: 10px 20px;
    font-size: 17px;
    font-weight: 500;
    color: var(--val-error);
    border-radius: 5px;
    background-color: var(--card-background);
}

</style>