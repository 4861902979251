import axios from 'axios';
const v2lib = {

    namespace: '',
    publicToken: '',
    personalToken: '',
    editorToken: '',

    async createEnvelope(descriptor, flow, publicTemplate) {
        const envelope = {
            id: '',
            documentId: '',
            templateId: '',
            pendingRecipients: [],
            pendingAttachments: [],
            pendingMetadata: [],
            content: {},
            metadata: {
                namespace: this.namespace,
                mainToken: publicTemplate ? this.publicToken : this.personalToken,
                bearerToken: ''
            },
            error: undefined,
            exception: undefined,
        };
        //console.log('env', envelope);
        try {
            const body = { "documents": { "1": { "data": {} } } };
            const response = await this.axiosPOST(`/envelope-descriptors/${descriptor}/envelopes`, envelope.metadata.mainToken, body);
            //console.log('response', response);

            envelope.id = response.data.uid.split('/envelopes/')[1];
            //console.log('envelopeId', envelope.id);

            let count = 0;
            while (!envelope.metadata.bearerToken && count < 90) {
                await new Promise(r => setTimeout(r, 1000));
                const bres = await this.axiosGET(`/flows/${flow}/jobs/${envelope.id}`, envelope.metadata.mainToken);
                //console.log('bres', bres);
                if (bres.data.token) envelope.metadata.bearerToken = bres.data.token;
                count += 1;
            }

            if (!envelope.metadata.bearerToken) {
                envelope.error = {
                    message: 'Unable to fetch bearer token, the Verified Platform might be temporarily unavailable. Please wait a few minutes and try again.'
                };
                console.error('Unable to fetch bearer token');
            }

            const eres = await this.axiosGET(`/envelopes/${envelope.id}`, envelope.metadata.bearerToken);
            envelope.documentId = eres.data.documents[0].id;
            envelope.templateId = eres.data.documents[0].template.id;
            envelope.content = eres.data;

        }catch (ex) {
            //console.log('exxxxxx');
            envelope.exception = ex;
        }
        return envelope;
    },

    async getEditorEnvelope(envelopeId) {
        const envelope = {
            id: envelopeId,
            documentId: '',
            templateId: '',
            content: {},
            userdata: {},
            metadata: {
                namespace: this.namespace,
                mainToken: '',
                bearerToken: this.editorToken
            },
            flowName: ''
        };
        const eres = await this.axiosGET(`/envelopes/${envelopeId}`, this.editorToken);
        envelope.flowName = eres.data.descriptor.flow.id.replace('/flows/', '');
        envelope.documentId = eres.data.documents[0].id;
        envelope.templateId = eres.data.documents[0].template.id;
        envelope.content = eres.data;
        envelope.userdata = eres.data.documents[0].template.userData;
        return envelope;
    },

    async getEnvelope(envelopeId) {
        const envelope = {
            id: envelopeId,
            documentId: '',
            templateId: '',
            content: {},
            userdata: {},
            flowName: ''
        };
        const eres = await this.axiosGET(`/envelopes/${envelopeId}`, this.personalToken);
        envelope.flowName = eres.data.descriptor.flow.id.replace('/flows/', '');
        envelope.documentId = eres.data.documents[0].id;
        envelope.templateId = eres.data.documents[0].template.id;
        envelope.content = eres.data;
        envelope.userdata = eres.data.documents[0].template.userData;
        return envelope;
    },

    async setUserData(envelope, userdata) {
        //console.log('envelope', envelope);

        let query = '';
        if (userdata.finalSubmit != null && !userdata.finalSubmit) {
            query = '?noCommit=true';
        }

        console.log('submit userdata query', query);

        const response = await this.axiosPOST(`/envelopes/${envelope.id}/documents/${envelope.documentId}/templates/${envelope.templateId}/user-data${query}`, envelope.metadata.bearerToken, userdata);
        //console.log('response', response);
    },

    async setMetadata(envelope, metadata) {
        const body = {
            metadata: metadata
        };
        const response = await this.axiosPUT(`/envelopes/${envelope.id}`, envelope.metadata.bearerToken, body);
    },

    async setNameAndTags(envelope, name, tags) { // REMOVE
        const body = {
/*             name: name, */
            tags: tags
        };
        const response = await this.axiosPUT(`/envelopes/${envelope.id}/documents/${envelope.documentId}`, envelope.metadata.bearerToken, body);
        //console.log('response', response);
    },

    async setTags(envelope, tags) {
        const body = {
            tags: tags
        };
        const response = await this.axiosPUT(`/envelopes/${envelope.id}/documents/${envelope.documentId}`, envelope.metadata.bearerToken, body);
        //console.log('response', response);
    },

    async setName(envelope, name) {
        const body = {
            name: name
        };
        const response = await this.axiosPUT(`/envelopes/${envelope.id}/documents/${envelope.documentId}`, envelope.metadata.bearerToken, body);
        //console.log('response', response);
    },

    async setDocumentName(envelope, userdata, name) {
        userdata.fileName = name;
        const body = {
            name: name
        };
        const response = await this.axiosPUT(`/envelopes/${envelope.id}/documents/${envelope.documentId}`, envelope.metadata.bearerToken, body);
        //console.log('response', response);
    },

    async publishEnvelope(envelope) {
        const body = {
            published: true
        };
        const response = await this.axiosPUT(`/envelopes/${envelope.id}/publish-status`, envelope.metadata.bearerToken, body);
        //console.log('response', response);
    },



    async startSubmitEditor(envelopeId) {
        const envelope = {
            id: envelopeId,
            documentId: '',
            templateId: '',
            nextSignerUid: '',
            nextSignerId: '',
            content: {},
            pendingRecipients: [],
            pendingAttachments: [],
            pendingMetadata: [],
            metadata: {
                namespace: this.namespace,
                mainToken: '',
                bearerToken: this.editorToken
            },
            error: undefined,
            exception: undefined,
            flowName: ''
        };
        try {
            const eres = await this.axiosGET(`/envelopes/${envelope.id}`, envelope.metadata.bearerToken);
            envelope.documentId = eres.data.documents[0].id;
            envelope.templateId = eres.data.documents[0].template.id;
            envelope.content = eres.data;
            envelope.flowName = eres.data.descriptor.flow.id.replace('/flows/', '');
        }catch (ex) {
            envelope.exception = ex;
        }
        return envelope;
    },

    async prepareAttachment(envelope, name, binary) {
        envelope.pendingAttachments.push({ name, binary });
    },

    async addAttachment(envelope, name, binary) {
        const data = { fileType: 'attachment', name: name };
        let response = await this.axiosPOST(`/envelopes/${envelope.id}/documents/${envelope.documentId}/files`, envelope.metadata.bearerToken, data);
        const uploadUrl = response.data.url;
        response = await axios({
            url: uploadUrl,
            method: 'PUT',
            headers: { 'Content-Type': 'application/pdf' },
            data: binary,
        });
        return response;
    },

    async addAttachmentToExistingEnvelope(envelope, name, binary, token) {
        const data = { fileType: 'attachment', name: name };
        let response = await this.axiosPOST(`/envelopes/${envelope.id}/documents/${envelope.documentId}/files`, token, data);
        const uploadUrl = response.data.url;
        response = await axios({
            url: uploadUrl,
            method: 'PUT',
            headers: { 'Content-Type': 'application/pdf' },
            data: binary,
        });
        return response;
    },

    async removeAttachmentFromEnvelope(envelope, name, token) {
        let uid = '';
        for (const i in envelope.content.documents[0].files.attachments) {
            const attachment = envelope.content.documents[0].files.attachments[i];
            console.log('atc', attachment);
            if (attachment.name == name) {
                uid = attachment.uid;
            }
        }
        const response = await this.axiosDELETE(uid, token);
        console.log('response', response);
        return response;
    },


    //https://dev-verified.eu/api/envelopes/B1Ab4FKVF/documents

    async addDocument(envelope, name, binary) {
        let data = {
            "name": name,
            "descriptor": {
                "attachments": [
                    {
                        "label": "Attachment",
                        "numMax": 20,
                        "numMin": 0
                    }
                ],
                "hash": 1,
                "numMax": 10,
                "numMin": 1,
                "roles": [],
                "type": "file",
                "instances": [envelope.content.documents[0]],
                "mandatory": false
            },
            "source": "file"
        };
        let response = await this.axiosPOST(`/envelopes/${envelope.id}/documents`, envelope.metadata.bearerToken, data);

        console.log('response ' , response);

        const location = response.headers.location;

        console.log('location ' , location);

        const newdocument = await this.axiosGET(location.replace('/api', ''), envelope.metadata.bearerToken);

        data = { fileType: "document", mimeType: "application/pdf", name: name };
        response = await this.axiosPOST(`/envelopes/${envelope.id}/documents/${newdocument.data.id}/files`, envelope.metadata.bearerToken, data);
        const uploadUrl = response.data.url;

        response = await axios({
            url: uploadUrl,
            method: 'PUT',
            headers: { 'Content-Type': 'application/pdf' },
            data: binary,

        });
        return response;
    },

    getUint8ArrayFromBase64(base64){
        // Convert base64 to arraybuffer
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes;
    },

    async prepareRecipient(envelope, recipient) {
        const copy = JSON.parse(JSON.stringify(recipient));
        delete copy.action;
        envelope.pendingRecipients.push(copy);
    },

    async addEditorRecipient(envelope, person, language) {
        const recipient = {
            givenName: person.firstName,
            familyName: person.lastName,
            email: person.email,
            language: language,
            signingMethod: 'email',
            role: {
                action: 'edit',
                label: 'Sluttrapport',
                name: 'editor'
            }
        };
        await this.addRecipient(envelope, recipient);
    },

    async addRecipientPerson(envelope, person, language, label) {
        const recipient = {
            givenName: person.firstName || person.fname,
            familyName: person.lastName || person.lname,
            email: person.email,
            language: language,
            signingMethod: 'email',
            role: {
                action: 'edit',
                label: label || 'Fylle i skjemaet',
                name: 'editor'
            }
        };
        await this.addRecipient(envelope, recipient);
    },

    async addSingleSigner(envelope, person, language, method) {
        const recipient = {
            givenName: person.firstName || person.fname,
            familyName: person.lastName || person.lname,
            email: person.email,
            language: language,
            signingMethod: method,
            order: 1,
            role: {
                action: 'sign',
                label: 'Signere',
                name: 'signer'
            }
        };
        await this.addRecipient(envelope, recipient);
    },

    async addRecipient(envelope, recipient) {

        //console.log('add recipient', recipient);
        //console.log('existing envelope', envelope);

        let skipRecipient = false;
        try {
            for (const i in envelope.content.recipients) {
                const rec = envelope.content.recipients[i];
                if (rec.email == recipient.email && rec.role.name == recipient.role.name) {
                    skipRecipient = true;
                    console.error('Skipped duplicate recipient');
                }
            }
        }catch (ex) {
            console.error('Error checking existing recipients');
        }
        if (!skipRecipient) {
            const response = await this.axiosPOST(`/envelopes/${envelope.id}/recipients`, envelope.metadata.bearerToken, recipient);
        }
    },

    async setSMSGreeting(envelope, greeting) {
        const data = {
            automaticReminders: 0,
            expiration: null,
            greeting: greeting
        };
        const response = await this.axiosPUT(`/envelopes/${envelope.id}`, envelope.metadata.bearerToken, data);
        console.log('set sms greeting response', response);
    },

    async prepareMetadata(envelope, metadata) {
        envelope.pendingMetadata = metadata;
    },

    async submitEditor(envelope, userdata) {

        for (const i in envelope.pendingAttachments) {
            await this.addAttachment(envelope, envelope.pendingAttachments[i].name, envelope.pendingAttachments[i].binary);
        }

        // Set metadata
        if (envelope.prepareMetadata) {
            await this.setMetadata(envelope, envelope.prepareMetadata);
        }

        for (const i in envelope.pendingRecipients) {
            await this.addRecipient(envelope, envelope.pendingRecipients[i]);
        }

        const response = await this.axiosPOST(`/envelopes/${envelope.id}/documents/${envelope.documentId}/templates/${envelope.templateId}/user-data`, envelope.metadata.bearerToken, userdata);
        //console.log('set userdata response', response);

    },

    async checkEnvelopeForErrors(envelope) {
        let hasDocument = false;
        let attemptCount = 0;
        const maxAttempts = 16;
        try {
            while (!hasDocument && attemptCount < maxAttempts) {
                await new Promise(r => setTimeout(r, 2000));
                attemptCount += 1;
                const response = await this.axiosGET(`/envelopes/${envelope.id}`, envelope.metadata.bearerToken);
                if (response.data && response.data.documents && response.data.documents.length > 0 && response.data.documents[0].files && response.data.documents[0].files.document) {
                    hasDocument = true;
                }
                if (response.data && !envelope.nextSignerUid) {
                    for (const i in response.data.recipients) {
                        if (response.data.recipients[i].role.action == 'sign') {
                            envelope.nextSignerUid = response.data.recipients[i].uid;
                            envelope.nextSignerId = response.data.recipients[i].id;
                        }
                    }
                }
            }
        }catch (ex) {
            console.log(ex);
        }
        return { ok: hasDocument };
    },

    async getSignLink(root, envelope, useOldCheckout, customRedirect) {
        const flowId = '/flows/' + (envelope.flowName || 'simple-public-flow-with-silent-parties');
        const result = {
            ok: false,
            token: '',
            url: ''
        };
        try {
            let attemptCount = 0;
            const maxAttempts = 10;
            while (!result.token && attemptCount < maxAttempts) {
                await new Promise(r => setTimeout(r, 2000));
                attemptCount += 1;
                const response = await this.axiosGET(`${flowId}/jobs/${envelope.id}`);
                if (response.data.signToken && response.data.signToken != 'NA') {
                    result.token = response.data.signToken;
                }else if (response.data.signLink) {
                    result.token = response.data.signLink.split('access_token=')[1];
                }
            }
            if (result.token) {
                let signUrl = '';
                if (!useOldCheckout) {
                    if (!envelope.nextSignerUid) {
                        const response = await this.axiosGET(`/envelopes/${envelope.id}`, envelope.metadata.bearerToken);
                        for (const i in response.data.recipients) {
                            if (response.data.recipients[i].role.action == 'sign') envelope.nextSignerUid = response.data.recipients[i].uid;
                        }
                    }
                    signUrl = 'https://checkout.verified.eu/checkout/?u=' + envelope.nextSignerUid + '&';
                }else {
                    signUrl = 'https://app.verified.eu/#/sign/envelopes/' + envelope.id + '?';
                }
                signUrl += 'lang=' + root.locale.key;
                if (!customRedirect) signUrl += '&redirect_to=' + root.baseUrl + '/kyclanding/signer/' + envelope.id + '?act=' + envelope.metadata.bearerToken;
                if (customRedirect) signUrl += '&redirect_to=' + customRedirect;
                signUrl += '&access_token=' + result.token;
                result.url = signUrl;
                result.ok = true;
            }
        }catch (ex) {
            console.log(ex);
        }
        return result;
    },

    async getSignLinkAWS(root, envelope, mail) {
        const flowId = '/flows/' + (envelope.flowName || 'simple-public-flow-custom-sender');
        const result = {
            ok: false,
            token: '',
            url: ''
        };
        try {
            let attemptCount = 0;
            const maxAttempts = 20;
            while (!result.token && attemptCount < maxAttempts) {
                await new Promise(r => setTimeout(r, 2000));
                attemptCount += 1;
                const response = await this.axiosGET(`${flowId}/jobs/${envelope.id}`);
                if (response.data.signToken && response.data.signToken != 'NA') {
                    result.token = response.data.signToken;
                }else if (response.data.signLink) {
                    result.token = response.data.signLink.split('access_token=')[1];
                }
            }
            if (result.token) {
                if (!envelope.nextSignerUid) {
                    const response = await this.axiosGET(`/envelopes/${envelope.id}`, envelope.metadata.bearerToken);
                    for (const i in response.data.recipients) {
                        if (response.data.recipients[i].role.action == 'sign') {
                            envelope.nextSignerUid = response.data.recipients[i].uid;
                            envelope.nextSignerId = response.data.recipients[i].id;
                        }
                    }
                }
                let signUrl = 'https://checkout.verified.eu/checkout/?u=' + envelope.nextSignerUid + '&';
                signUrl += 'lang=' + root.locale.key;
                if (mail.customRedirect == 'no') {
                    let signRoute = mail.signRoute;
                    signRoute = signRoute.split('{envelopeId}').join(envelope.id);
                    signRoute = signRoute.split('{recipientId}').join(envelope.nextSignerId);
                    signUrl += '&redirect_to=' + mail.baseUrl + signRoute;
                    console.log('REDIR' + mail.baseUrl + signRoute);
                }else {
                    signUrl += '&redirect_to=' + mail.customRedirect;
                }
                signUrl += '&access_token=' + result.token;
                result.url = signUrl;
                result.ok = true;
            }
        }catch (ex) {
            console.log(ex);
        }
        return result;
    },

    async getSignLinkForm(root, envelope, mailConfig) {
        console.log('mailConfig', mailConfig);
        const flowId = '/flows/' + (envelope.flowName || 'aml-portal-v1-sign-flow'); //'simple-public-flow-custom-sender');
        const result = {
            ok: false,
            token: '',
            url: ''
        };
        try {
            let attemptCount = 0;
            const maxAttempts = 60;
            while (!result.token && attemptCount < maxAttempts) {
                await new Promise(r => setTimeout(r, 2000));
                attemptCount += 1;
                const response = await this.axiosGET(`${flowId}/jobs/${envelope.id}`);
                if (response.data.signToken && response.data.signToken != 'NA') {
                    result.token = response.data.signToken;
                }else if (response.data.signLink) {
                    result.token = response.data.signLink.split('access_token=')[1];
                }
            }
            result.attempts = attemptCount;
            if (result.token) {
                if (!envelope.nextSignerUid) {
                    const response = await this.axiosGET(`/envelopes/${envelope.id}`, envelope.metadata.bearerToken);
                    for (const i in response.data.recipients) {
                        if (response.data.recipients[i].role.action == 'sign') {
                            envelope.nextSignerUid = response.data.recipients[i].uid;
                            envelope.nextSignerId = response.data.recipients[i].id;
                        }
                    }
                }
                let signUrl = 'https://checkout.verified.eu/checkout/?u=' + envelope.nextSignerUid + '&';
                if (root.isDEV) signUrl = 'https://checkout.dev-verified.eu/checkout/?u=' + envelope.nextSignerUid + '&';
                signUrl += 'lang=' + root.locale.key;
                if (mailConfig.customRedirect == 'no') {
                    let signRoute = mailConfig.signRoute;
                    signRoute = signRoute.split('{envelopeId}').join(envelope.id);
                    signRoute = signRoute.split('{recipientId}').join(envelope.nextSignerId);
                    signUrl += '&redirect_to=' + mailConfig.baseUrl + signRoute;
                    console.log('REDIR' + mailConfig.baseUrl + signRoute);
                }else {
                    signUrl += '&redirect_to=' + mailConfig.customRedirect;
                }
                signUrl += '&access_token=' + result.token;
                result.url = signUrl;
                result.ok = true;
            }
        }catch (ex) {
            console.log(ex);
        }
        return result;
    },

    async getSignLink2(root, envelope, useOldCheckout) {
        const flowId = '/flows/simple-public-flow-with-silent-parties';
        const result = {
            ok: false,
            token: '',
            url: ''
        };
        try {
            let attemptCount = 0;
            const maxAttempts = 10;
            while (!result.token && attemptCount < maxAttempts) {
                await new Promise(r => setTimeout(r, 2000));
                attemptCount += 1;
                const response = await this.axiosGET(`${flowId}/jobs/${envelope.id}`);
                if (response.data.signToken && response.data.signToken != 'NA') {
                    result.token = response.data.signToken;
                }else if (response.data.signLink) {
                    result.token = response.data.signLink.split('access_token=')[1];
                }
            }
            if (result.token) {
                let signUrl = '';
                let recipientId = '';
                const response = await this.axiosGET(`/envelopes/${envelope.id}`, envelope.metadata.bearerToken);
                for (const i in response.data.recipients) {
                    if (response.data.recipients[i].role.action == 'sign') {
                        envelope.nextSignerUid = response.data.recipients[i].uid;
                        recipientId = response.data.recipients[i].id;
                    } 
                }
                if (!useOldCheckout) {
                    signUrl = 'https://checkout.verified.eu/checkout/?u=' + envelope.nextSignerUid + '&';
                }else {
                    signUrl = 'https://app.verified.eu/#/sign/envelopes/' + envelope.id + '?';
                }
                signUrl += 'lang=' + root.locale.key;
                signUrl += '&redirect_to=' + root.baseUrl + '/flow/' + envelope.id + '/' + recipientId;
                signUrl += '&access_token=' + result.token;
                result.url = signUrl;
                result.ok = true;
            }
        }catch (ex) {
            console.log(ex);
        }
        return result;
    },

    async createNewEnvelopeFromExisting(oldEnvelope, userdata) {
        const descriptor = 'aml-portal-kyc';
        const flow = 'simple-public-flow-with-silent-parties';

        const newEnvelope = await this.createEnvelope(descriptor, flow, true);

        newEnvelope.pendingRecipients = oldEnvelope.pendingRecipients;
        newEnvelope.pendingAttachments = oldEnvelope.pendingAttachments;
        newEnvelope.pendingMetadata = oldEnvelope.pendingMetadata;

        for (const i in newEnvelope.pendingAttachments) {
            await this.addAttachment(newEnvelope, newEnvelope.pendingAttachments[i].name, newEnvelope.pendingAttachments[i].binary);
        }

        const name = oldEnvelope.content.documents[0].name;
        const tags = oldEnvelope.content.documents[0].tags;
        await this.setNameAndTags(newEnvelope, name, tags);
        await this.setName(newEnvelope, name);

        tags.push('replaced');
        await this.setNameAndTags(oldEnvelope, name, tags);

        for (const i in newEnvelope.pendingRecipients) {
            await this.addRecipient(newEnvelope, newEnvelope.pendingRecipients[i]);
        }

        userdata.fileName = name;
        
        let response = await this.axiosPOST(`/envelopes/${newEnvelope.id}/documents/${newEnvelope.documentId}/templates/${newEnvelope.templateId}/user-data`, newEnvelope.metadata.bearerToken, userdata);
        console.log('set userdata response', response);

        await this.publishEnvelope(newEnvelope);

        response = await this.axiosPOST(`/envelopes/${newEnvelope.id}/documents/${newEnvelope.documentId}/templates/${newEnvelope.templateId}/user-data`, newEnvelope.metadata.bearerToken, userdata);
        console.log('set userdata response', response);

        return newEnvelope;
    },


    wasCreated(envelope) {
        if (envelope.error || envelope.exception) return false;
        if (envelope.id) return true;
        return false;
    },
    getError(envelope) {
        if (envelope.error) return envelope.error;
        if (envelope.exception) return envelope.exception;
        return { message: 'Unknown error' };
    },

    addFileUploadAttachmentToUserdata(userdata, file, type) {
        if (!userdata.attachments) userdata.attachments = [];
        userdata.attachments.push({
            type: type,
            name: file.name,
            fileType: file.type,
            size: file.data.size,
            sizeText: file.size,
            number: userdata.attachments
        });
    },

    addFileUploadAttachmentToUserdataSafe(number, file, type) {
        return {
            type: type,
            name: file.name,
            fileType: file.type,
            size: file.data.size,
            sizeText: file.size,
            number: number
        };
    },


    async axiosGET(path, token) {
        const response = await axios({
            url: "/api" + path,
            method: 'GET',
            headers: this.getHeaders(token),
            withCredentials: "same-origin"
        });
        return response;
    },

    async axiosPOST(path, token, body) {
        //console.log('post header', this.getHeaders(token))
        const response = await axios({
            url: "/api" + path,
            method: 'POST',
            headers: this.getHeaders(token),
            data: body,
            withCredentials: "same-origin"
        });
        return response;
    },

    async axiosPUT(path, token, body) {
        //console.log('put header', this.getHeaders(token))
        const response = await axios({
            url: "/api" + path,
            method: 'PUT',
            headers: this.getHeaders(token),
            data: body,
            withCredentials: "same-origin"
        });
        return response;
    },

    async axiosDELETE(path, token) {
        const response = await axios({
            url: "/api" + path,
            method: 'DELETE',
            headers: this.getHeaders(token),
            withCredentials: "same-origin"
        });
        return response;
    },

    getHeaders(token) {
        const headers = {
            'content-type': 'application/json',
            'accept': 'application/json',
            'x-namespace': this.namespace,
            'authorization': 'JWT ' + token,
        };
        return headers;
    }

};
export default v2lib;