<template>
<div>

    <PopupV1 :header="'Form Module Configuration'" v-if="params.type == 'manage'" :width="500" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <div class="frmod-form-hint">
                {{ 'This form will try to load data from the following forms:' }}
            </div>
            <div class="frmod-form-item" v-for="(item, i) in items" :key="'fmi' + i">
                <div class="frmod-form-item-key">
                    {{ item }}
                </div>
                <div class="frmod-form-item-del" @click="onDeleteKey(i)">
                    <vap-icon small :icon="'delete'" />
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Add form'" :icon="'add'" @clicked="addDialog.show = true" />
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

    <PopupV1 :header="'Add form key'" v-if="addDialog.show" :width="400" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <VFInput v-model="addDialog.key" :placeholder="'F.ex. kyc00'" @onreturn="onAddFormKey" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Cancel'" outline @clicked="addDialog.show = false" />
            <VFButton :text="'Add form'" :icon="'add'" @clicked="onAddFormKey" :disabled="addDialog.key == ''" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';
import VFInput from '../../inputs/VFInput.vue';

export default {
    name: "frmod-form",
    components: {
        PopupV1,
        VFButton,
        VFInput,
    },
    props: {
        params: { type: Object, default: () => { return { type: '' } }}
    },
    data() {
        return {
            dialog: {},
            addDialog: { show: false, key: '' },
            items: []
        }
    },
    methods: {
        
        onAddFormKey() {
            if (!this.addDialog.key) return;
            this.items.push(this.addDialog.key);
            this.addDialog.show = false;
        },

        onDeleteKey(index) {
            this.items.splice(index, 1);
        },

    },
    created() {
        for (const i in this.$parent.modules) {
            if (this.$parent.modules[i].type == 'form') {
                this.items = this.$parent.modules[i].formKeys;
            }
        }
    }
}
</script>
<style>

/* .frmod-form-hint {
    color: var(--text-weak);
    font-style: italic;
}

.frmod-form-item {
    margin-top: 5px;
    display: flex;
    border-radius: 4px;
    border: solid 1px var(--input-border);
    box-sizing: border-box;
}

.frmod-form-item-key {
    flex-grow: 100;
    padding-top: 5px;
    padding-left: 10px;
    color: var(--text-title);
}

.frmod-form-item-del {
    width: 30px;
    border-left: solid 1px var(--input-border);
    box-sizing: border-box;
    cursor: pointer;
}
.frmod-form-item-del:hover {
    background-color: var(--item-hover);
} */

</style>