<template>
<div class="fbinp-group" :style="'width: ' + width + '%;'">
    
    <slot />

</div>
</template>
<script>
export default {
    name: 'DesignerGroup',
    props: {
        c12: { type: Boolean, default: false },
        c9: { type: Boolean, default: false },
        c8: { type: Boolean, default: false },
        c6: { type: Boolean, default: false },
        c5: { type: Boolean, default: false },
        c4: { type: Boolean, default: false },
        c3: { type: Boolean, default: false },
        c2: { type: Boolean, default: false },
    },
    data() {
        return {
            width: '100'
        }
    },
    created() {
        if (this.c12) {
            this.width = '100';
        }else if (this.c8) {
            this.width = '66.66';
        }else if (this.c6) {
            this.width = '50';
        }else if (this.c4) {
            this.width = '33.33';
        }
    }
}
</script>
<style scoped>

.fbinp-group {
    float: left;
    padding: 6px 5px;
    box-sizing: border-box;
}

</style>