import log from '../../log';
import formHelper from "./formHelper";
import flowHelper from "./flowHelper";
import logicHelper from "./logicHelper";
import pdfGenerator from './pdfGenerator';
import formValidation from './formValidation';
import moduleHelper from './modules/moduleHelper';
import v2lib from '@/v2lib';
import verified from '@/verified';
import api from '@/portalapi';
import v1api from '../../v1helpers/v1api';
const formSender = {

    logStuff: true,

    async sendForm(root, formKey, receiver) {
        
        const view = {};

        const formResponse = await api.getForm(formKey);
        view.formData = formResponse.formData;

        const existingData = JSON.parse(root.formData);

        const processedData = formHelper.processData(view.formData);
        log.x('FRv2: Form data processed', processedData);
        view.formKey = view.formData.key;
        view.title = view.formData.title;
        view.pages = processedData.pages;
        view.modules = processedData.modules;
        view.attachments = processedData.attachments;
        view.roles = processedData.roles;
        view.roleKey = 'owner';
        view.autoSave = processedData.autoSave;
        view.canSave = processedData.canSave;
        view.locales = processedData.locales;
        view.flags = processedData.flags;
        view.resources = {};
        view.isOwner = true;

        view.logic = processedData.logic || [];

        //view.processSteps();
        //view.title = formData.title;


        formHelper.createModel(root, root, view.pages);


        // Modules onFormCreated
        for (const i in view.modules) {
            if (this.logStuff) console.log('module', view.modules[i]);
            const userdata = (view.envelope && view.envelope.userdata) ? view.envelope.userdata : {};
            await moduleHelper.onFormCreated(root, view, false, view.modules[i], view.pages, view.isOwner, userdata, false);
        }
        moduleHelper.onFormCreatedSystem(root, view, false, view.pages, view.isOwner, false);

        // Add list model to empty lists
        for (const i in view.pages) {
            for (const j in view.pages[i].cards) {
                if (view.pages[i].cards[j].type == 'list' && view.pages[i].cards[j].model.length == 0) {
                    if (view.pages[i].cards[j].noEmpty) continue;
                    formHelper.addListModel(root, view.pages[i].cards[j], {});
                }
            }
        }

        //view.stepKey = view.steps[0].key;
        //view.pageIndex = view.steps[0].index;
        //view.pages[view.pageIndex].validation = formValidation.validatePage(view, view.pages[view.pageIndex], view.pages, view.roleKey, false);

        // Trigger link input to link auto-filled inputs
        let valueWasLinked = false;
        for (const i in view.pages) {
            for (const j in view.pages[i].cards) {
                for (const k in view.pages[i].cards[j].inputs) {
                    if (view.pages[i].cards[j].inputs[k].logic && view.pages[i].cards[j].inputs[k].logic.link) {
                        for (const y in view.pages[i].cards[j].inputs[k].logic.link) {
                            const link = view.pages[i].cards[j].inputs[k].logic.link[y];
                            for (const x in view.modules) {
                                if (view.modules[x].type == link.type) {
                                    valueWasLinked = true;
                                    moduleHelper.onLinkValue(root, view, false, view.modules[x], link, view.pages[i].cards[j].inputs[k].model);
                                }
                            }
                            moduleHelper.onSystemLinkValue(root, view, false, link, view.pages[i].cards[j].inputs[k].model);
                        }
                    }
                }
            }
        }





        let envelope = view.envelope;
        let userdata;
        let flowType = 'sign';
        envelope = await flowHelper.createEnvelope(root, flowType);
        log.x('FRv2: Envelope created', envelope);



        const recipient = {
            firstName: receiver.firstName,
            lastName: receiver.lastName,
            email: receiver.email,
            role: flowHelper.getRecipientRole('editor'),
        };



        if (!recipient.lang) recipient.lang = root.locale.key;
        log.x('FRv2: Submitting with recipient', recipient);
        await flowHelper.addRecipient(envelope, recipient, flowType);
    
        // Create logic outputs
        const logicData = {
            outputs: []
        };
        for (const i in view.logic) {
            const output = logicHelper.performLogic(view, i, false);
            view.logicResults[view.logic[i].key] = output;
            log.x('Logic: ' + view.logic[i].name + ' processed', output);
            logicData.outputs.push({
                key: view.logic[i].key,
                indexKey: view.logic[i].indexKey,
                indexed: view.logic[i].indexed,
                output: output
            });
        }


        if (view.isOwner) {
            // Owner submit

            const mailConfig = flowHelper.getMailDataForceProduction(root, view.formData, {}, root.locale.key, flowType);
            if (existingData && existingData.customer) {
                mailConfig.values.push({ key: 'customerName', type: 'value', value: existingData.customer.name })
            }

            const formUserdata = formHelper.getFormUserdata(view.formData, view.pages);
            userdata = {
                formConfig: formUserdata.config,
                formPages: formHelper.stripPages(formUserdata.pages),
                mailConfig: mailConfig,
                mailGreeting: '',
                attachments: [],
                logicData: logicData
            };
            if (recipient && recipient.greeting) userdata.mailGreeting = recipient.greeting;

            userdata.flowParams = {
                isAnalog: view.isAnalog || false,
            };

            // Always add customer ID to userdata if available
            if (existingData && existingData.customer) {
                userdata.customer = {
                    id: existingData.customer._id
                };
            }

            // Modules onSetUserdata
            for (const i in view.modules) {
                await moduleHelper.onSetUserdata(root, view, view.modules[i], envelope, userdata, view.isOwner, recipient);
            }

            const personId = (existingData && existingData.idcPerson) ? existingData.idcPerson.id : 'x';
            const customerId = (existingData && existingData.customer) ? existingData.customer._id : 'x';

            let apiRoute = 'v1api';
            let renderRoute = `clients/${customerId}/userdata`;
            let updateRoute = `clients/${customerId}/forms`;

            const tokenResponse = await api.getFormBearerToken(personId, customerId, envelope.id);
            userdata.credentials = {
                id: customerId,
                cid: root.credentials.cid,
                key: root.credentials.key,
                uid: root.credentials.uid,
                bearerToken: tokenResponse.token,
                apiUrl: root.isDEV ? 'https://dev-verified.eu/api/aml-v1/' + apiRoute : 'https://app.verified.eu/api/aml-v1/' + apiRoute,
                /* configRoute: `forms/${formUserdata.config.key || 'na'}/userdata`, */
                renderRoute: renderRoute,
                updateRoute: updateRoute,
                isV0: (existingData && existingData.isV0) ? true : false,
                cc: root.config.params.baseCountry,
                stat: root.config.params.accountStatus
            };
            if (userdata.credentials.id) {
                const baseLink = root.isDEV ? 'https://dev-verified.eu/web/aml-portal/#' + apiRoute : 'https://app.verified.eu/web/aml-portal/#';
                mailConfig.clientLink = baseLink + '/v1client?id=' + userdata.credentials.id;
            }
            if (userdata.credentials.cid == 'rkeUXcLuv') {
                userdata.credentials.key = 'default';
            }

            if (flowType == 'direct' || (recipient && recipient.role && recipient.role.action == 'sign')) {
                userdata._isForwarded = true; // if the sign flow skips editors, this must be true to send notifications to signers
                view.preparePdfData(userdata);
            }


            // Set metadata
            const metadata = {
                silentParties: [],
                language: 'en_EN',
                skipNotifySender: false,
                senderLanguage: root.locale.key,
            };
            if (view.formData.flowConfig.flags) {
                if (!view.formData.flowConfig.flags.ownerCopy) {
                    metadata.skipNotifySender = true;
                }
                if (view.formData.flowConfig.flags.signedCopy && view.formData.flowConfig.flags.signedCopy.enabled) {
                    metadata.silentParties.push({
                        givenName: view.formData.flowConfig.flags.signedCopy.firstName,
                        familyName: view.formData.flowConfig.flags.signedCopy.lastName,
                        email: view.formData.flowConfig.flags.signedCopy.email,
                        language: view.formData.flowConfig.flags.signedCopy.language || root.locale.key
                    });
                }
            }

            // Set custom sender if enabled
            if (view.formData.flowConfig.customSender && view.formData.flowConfig.customSender.enabled) {
                metadata.sender = {
                    givenName: view.formData.flowConfig.customSender.firstName,
                    familyName: view.formData.flowConfig.customSender.lastName,
                    email: view.formData.flowConfig.customSender.email,
                };
            }

            // Modules onSetMetadata
            for (const i in view.modules) {
                await moduleHelper.onSetMetadata(root, view, view.modules[i], envelope, userdata, metadata, view.roleKey, recipient);
            }
            log.x('FRv2: Setting metadata', metadata);
            await v2lib.setMetadata(envelope, metadata);

            this.addIndexedValuesToEmail(view, userdata);

            const envelopeName = this.replaceNameVariables(root, view, view.formData.name, existingData) + '.pdf';
            const envelopeTags = ['form'];

            //console.log('sms recipient', recipient);
            if (recipient.useSms) {
                let greeting = 'You have received an invitation to sign documents';
                for (const i in view.formData.flowConfig.roles) {
                    if (view.formData.flowConfig.roles[i].key == recipient.role.name) {
                        greeting = view.formData.flowConfig.roles[i].email.smsMessage[recipient.lang];
                    }
                }
                greeting = flowHelper.replaceDynamicValues(root, greeting, recipient.lang);
                await v2lib.setSMSGreeting(envelope, greeting);
            }

            log.x('FRv2: Submitting with userdata', userdata);
            await flowHelper.setUserdata(envelope, userdata, flowType);
            await flowHelper.setNameAndTags(envelope, userdata, envelopeName, envelopeTags, flowType);
            await flowHelper.submitEnvelope(envelope, flowType);

            log.x('FRv2: Waiting for envelope to be published');
            await v1api.waitForFormCreation(envelope, userdata);

        }

        log.x('FRv2: Envelope submitted', envelope);
    },

    replaceNameVariables(root, view, name, existingData) {
        let output = (name && name[root.locale.key]) ? name[root.locale.key] : 'Custom form';
        if (existingData) {
            if (existingData.customer && existingData.customer.name) output = output.split('{{customerName}}').join(existingData.customer.name);
            if (existingData.person && existingData.person.displayName) output = output.split('{{personName}}').join(existingData.person.displayName);
        }
        const inputs = formHelper.getVisibleInputs(view, view.pages, view.roles, 'done');
        for (const i in inputs) {
            if (inputs[i].dbIndex) {
                output = output.split('{{' + inputs[i].modelKey + '}}').join(inputs[i].model);
            }
        }
        return output;
    },

    replaceDynamicVariables(view, input, existingData) {
        let output = input;
        if (existingData) {
            if (existingData.customer && existingData.customer.name) output = output.split('{{customerName}}').join(existingData.customer.name);
            if (existingData.person && existingData.person.displayName) output = output.split('{{personName}}').join(existingData.person.displayName);
        }
        for (const key in view.existingValues) {
            output = output.split('{{' + key + '}}').join(view.existingValues[key]);
        }
        return output;
    },

    addIndexedValuesToEmail(view, userdata) {
        const values = [];
        const inputs = formHelper.getVisibleInputs(view, view.pages, view.roles, 'done');
        for (const i in inputs) {
            if (inputs[i].dbIndex) {
                console.log('input db', inputs[i]);
                values.push({
                    key: inputs[i].modelKey,
                    type: 'value',
                    value: inputs[i].model
                });
            }
        }
        for (const i in values) {
            let match = false;
            for (const j in userdata.mailConfig.values) {
                if (userdata.mailConfig.values[j].key == values[i].key) {
                    userdata.mailConfig.values[j].value = values[i].value;
                    match = true;
                }
            }
            if (!match) userdata.mailConfig.values.push(values[i]);
        }
    },

};
export default formSender;