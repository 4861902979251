<template>
    <div class="slim-page">
        
        <vap-label :text="'Forms'" header style="margin-bottom: 5px;">
            <template v-slot:actions>
                <vap-icon action :icon="'add'" @clicked="showCreateDialog">
                    <template v-slot:tooltip><vap-tooltip :text="'Add form'"></vap-tooltip></template>
                </vap-icon>
            </template>
        </vap-label>

        <vap-card list noPadding v-for="form in forms" :key="form.key">
            <div class="frv2-list">
                <div class="frv2-list-title">
                    <label>{{ form.title[$root.locale.key] }}</label>
                </div>
                <div class="frv2-list-actions">
                    <vap-icon :icon="'delete'" action @clicked="onDeleteClicked(form)" />
                    <vap-icon :icon="'edit'" action @clicked="onEditClicked(form.key)" />
                    <vap-icon :icon="'open'" action @clicked="onOpenClicked(form.key)" />
                </div>
            </div>
        </vap-card>

        <ve-popup v-if="createDialog.show" :header="'Create form'" :width="600" @close="createDialog.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c12>
                            <vap-label desc :text="'Title'" required />
                            <vap-input v-model="createDialog.title" :rules="'required'" noValidation noHighlight @input="validateCreateDialog" />
                        </vap-group>
                        <vap-group c12>
                            <vap-label desc :text="'Key'" required />
                            <vap-input v-model="createDialog.key" :rules="'required'" noValidation noHighlight @input="validateCreateDialog" />
                        </vap-group>
                        <vap-group c12>
                            <vap-label desc :text="'Flow'" required />
                            <vap-dropdown noValidation noHighlight
                                v-model="createDialog.flow"
                                :options="flowOptions"
                                :displayKey="'display'"
                                :listKey="'display'"
                                :outputKey="'value'"
                                :input="validateCreateDialog"
                            />
                        </vap-group>
                        <vap-group c12>
                            <vap-label desc :text="'Module'" />
                            <vap-dropdown noValidation noHighlight
                                v-model="createDialog.module"
                                :options="moduleOptions"
                                :displayKey="'display'"
                                :listKey="'display'"
                                :outputKey="'value'"
                                :input="validateCreateDialog"
                            />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="createDialog.show = false" />
                    <vap-button :text="'Create form'" uppercase @clicked="createForm" :disabled="!createDialog.valid" :working="createDialog.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="deleteDialog.show" :header="'Delete form?'" :width="400" @close="deleteDialog.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>{{ 'Are you sure you want to delete the form ' + deleteDialog.title + '?' }}</label><br><br>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_no')" uppercase @clicked="deleteDialog.show = false" />
                    <vap-button :text="__('common_yes')" uppercase @clicked="deleteForm" :working="deleteDialog.working" />
                </vap-button-row>
            </div>
        </ve-popup>

    </div>
</template>

<script>
import api from '@/portalapi';

import formHelper from '@/components/form/formHelper';

export default {
    name: 'FormList',
    components: {
        
    },
    data() {
        return {
            forms: [],
            createDialog: { show: false, working: false, valid: false, key: '', title: '', flow: 'sign', module: 'none' },
            deleteDialog: { show: false, working: false, key: '', title: '' },
            flowOptions: [
                { value: 'sign', display: 'Signature flow' },
                { value: 'internal', display: 'Internal approve flow' },
                { value: 'direct', display: 'Direct to PDF flow' },
            ],
            moduleOptions: [
                { value: 'none', display: 'No module' },
                { value: 'kyc', display: 'KYC Module' },
                { value: 'idc', display: 'ID Control Module' },
                { value: 'chk', display: 'Checklist Module' },
            ]
        }
    },
    methods: {

        onOpenClicked(key) {
            this.$parent.navigate({ path: '/form/dev/' + key });
        },

        onEditClicked(key) {
            this.$parent.navigate({ path: '/formdesigner', query: { key: key }});
        },

        onDeleteClicked(form) {
            this.deleteDialog.key = form.key;
            this.deleteDialog.title = form.title[this.$root.locale.key];
            this.deleteDialog.show = true;
        },

        async loadForms() {
            const response = await api.getForms();
            this.forms = response.forms;
        },

        showCreateDialog() {
            this.createDialog.show = true;
        },

        validateCreateDialog() {
            let valid = false;
            if (this.createDialog.key && this.createDialog.title) valid = true;
            this.createDialog.valid = valid;
        },

        async createForm() {
            this.createDialog.working = true;

            const data = formHelper.getEmptyConfig(this.$root, this.createDialog);

            await api.createForm(data);

            await this.loadForms();

            this.createDialog.working = false;
            this.createDialog.show = false;
        },

        async deleteForm() {
            this.deleteDialog.working = true;

            await api.deleteForm(this.deleteDialog.key);

            await this.loadForms();

            this.deleteDialog.working = false;
            this.deleteDialog.show = false;
        }

    },
    async created() {
        await this.loadForms();
    }
}
</script>
<style scoped>

.frv2-list {
    padding: 10px 20px;
    display: flex;
}

.frv2-list-title {
    flex-grow: 100;
    font-size: 20px;
    padding-top: 5px;
}

.frv2-list-actions {
    display: flex;
}

</style>