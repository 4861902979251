<template>
<div class="piii" :class="[
    {'piii-first': first },
]">

    <div class="piii-info">
        <slot name="info"></slot>
    </div>

    <div class="piii-actions">
        <slot name="actions"></slot>
    </div>
    
</div>
</template>
<script>
export default {
    name: 'PersonInfoContainer',
    props: {
        width: { type: Number, default: 100 },
        first: { type: Boolean, default: false },
    },
    data() {
        return {
            disabled: false
        }
    },
    methods: {

        

    },
    created() {
        
    }
}
</script>
<style scoped>

.piii {
    float: left;
    width: 100%;
    display: flex;
    border-top: solid 1px var(--input-border);
    box-sizing: border-box;
}

.piii-first {
    border-top: none;
}

.piii-info {
    flex-grow: 100;
    overflow: hidden;
}

.piii-actions {
    flex-shrink: 0;
}

</style>