import personhelper from './helpers/personhelper';
const dataHelper = {

    infoMatchPersons(data) {
        const masterList = [];
        for (const i in data.beneficials) {
            let match = false;
            for (const j in masterList) {
                if (personhelper.isSamePerson(data.beneficials[i], masterList[j])) {
                    this.mergeWithMasterList(data.beneficials[i], masterList[j]);
                    match = true;
                }
            }
            if (!match) this.addToMasterList(masterList, data.beneficials[i]);
        }
        for (const i in data.roles) {
            let match = false;
            for (const j in masterList) {
                if (personhelper.isSamePerson(data.roles[i], masterList[j])) {
                    this.mergeWithMasterList(data.roles[i], masterList[j]);
                    match = true;
                }
            }
            if (!match) this.addToMasterList(masterList, data.roles[i]);
        }


        for (const i in data.beneficials) {
            for (const j in masterList) {
                if (personhelper.isSamePerson(data.beneficials[i], masterList[j])) {
                    this.fillFromMasterList(data.beneficials[i], masterList[j]);
                }
            }
        }
        for (const i in data.roles) {
            for (const j in masterList) {
                if (personhelper.isSamePerson(data.roles[i], masterList[j])) {
                    this.fillFromMasterList(data.roles[i], masterList[j]);
                }
            }
        }
    },

    addToMasterList(list, person) {
        list.push(JSON.parse(JSON.stringify(person)));
    },

    mergeWithMasterList(person, masterPerson) {
        if (!masterPerson.street) {
            masterPerson.street = person.street;
            masterPerson.zip = person.zip;
            masterPerson.city = person.city;
            masterPerson.country = person.country;
        }
    },

    fillFromMasterList(person, masterPerson) {
        if (!person.street) {
            person.street = masterPerson.street;
            person.zip = masterPerson.zip;
            person.city = masterPerson.city;
            person.country = masterPerson.country;
        }
    },

    sortLists(data) {
        data.roles.sort((a, b) => (this.getRoleSortIndex(a.roleId) > this.getRoleSortIndex(b.roleId)) ? 1 : -1);
        data.shareholders.sort((b, a) => (a.share > b.share) ? 1 : -1);
    },

    getRoleSortIndex(roleId) {
        switch (roleId) {
            case 'ceo': return 1;
            case 'chair': return 3;
            case 'proprietor': return 2;
            case 'screening_role_sign_right': return 7;
            case 'screening_role_procura': return 9;
            case 'screening_role_deputy_chairman': return 4;
            case 'screening_role_board_member': return 11;
            case 'screening_role_deputy_board_member': return 13;
            case 'screening_role_rrh': return 15;
            case 'screening_role_shareholder': return 17;
        }
        return 99;
    },

    getPersonList(data) {
        //console.log('getPersonList', data);
        const list = [];
        for (const i in data.roles) {
            list.push(JSON.parse(JSON.stringify(data.roles[i])));
        }
        for (const i in data.beneficials) {
            if (data.beneficials[i].identified) {
                const person = JSON.parse(JSON.stringify(data.beneficials[i]));
                person.roleId = 'screening_role_rrh';
                person.percentage = person.share;
                list.push(person);
            }
        }
        return list;
    },

    getKeyRoleList(roleList, data) {
        console.log('GET', data);
        const list = [];
        for (const i in data.roles) {
            if (roleList.includes(data.roles[i].roleId)) {
                list.push(JSON.parse(JSON.stringify(data.roles[i])));
            }
        }
        return list;
    },

    getBeneficialList(data) {
        const list = [];
        for (const i in data.beneficials) {
            if (data.beneficials[i].identified) {
                const beneficial = JSON.parse(JSON.stringify(data.beneficials[i]));
                beneficial.percent = beneficial.share;
                list.push(beneficial);
            }
        }
        return list;
    },

    getShareholderList(data) {
        const list = [];
        for (const i in data.shareholders) {
            const shareholder = JSON.parse(JSON.stringify(data.shareholders[i]));
            shareholder.percentage = shareholder.share;
            shareholder.organizationNumber = shareholder.orgnr;
            shareholder.type = shareholder.type == 'person' ? 'PERSON' : 'ORGANIZATION';
            list.push(shareholder);
        }
        return list;
    }

};
export default dataHelper;