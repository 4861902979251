<template>
<div class="vap-cdd">
    
    <vap-dropdown v-model="countryCode"
        :options="$root.countriesJson"
        :commonOptions="$root.countriesCommon" 
        :listKey="$root.locale.key"
        :displayKey="$root.locale.key"
        :outputKey="'key'"
        :triggerValidation="dropdownValidated"
        :noHighlight="noHighlight"
        :noValidation="noValidation"
        @input="handleInput" />

</div>
</template>
<script>
export default {
name: 'vap-country',
props: {
    value: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        default: ''
    },
    rules: {
        type: String,
        default: ''
    },
    noHighlight: {
        type: Boolean,
        default: false
    },
    noValidation: {
        type: Boolean,
        default: false
    }
},
data() {
    return {
        countryCode: '',
        dropdownValidated: false
    }
},
methods: {
    handleInput(value) {
        if (!this.dropdownValidated) this.dropdownValidated = true;
        this.$emit('input', value);
    }
},
mounted() {
    this.countryCode = this.value;
}
}
</script>
<style scoped>

</style>