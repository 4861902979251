<template>
<div>

    <PopupV1 :header="'Send til teamet'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <vap-group c12>
                <!-- <vap-label desc :text="''" /> -->
                <vap-checkbox v-model="forward.send" :options="sendOptions" inline radio noHighlight @input="validateForward" noValidation />
            </vap-group>
            <vap-row>
                <vap-group c12 v-if="forward.send.partner">
                    <vap-label desc :text="'Velg partner'" />
                    <vap-dropdown v-model="partnerModel" noValidation
                        :options="partners"
                        :displayKey="'name'"
                        :outputKey="'key'"
                        :listKey="'name'"
                        @input="onPartnerSelected"
                    />
                </vap-group>
                <vap-group c6 v-if="forward.send.free">
                    <vap-label desc :text="__('common_first_name')" />
                    <vap-input v-model="forward.firstName" :rules="'required'" @input="validateForward" noValidation />
                </vap-group>
                <vap-group c6 v-if="forward.send.free">
                    <vap-label desc :text="__('common_last_name')" />
                    <vap-input v-model="forward.lastName" :rules="'required'" @input="validateForward" noValidation />
                </vap-group>
                <vap-group c6 v-if="forward.send.free">
                    <vap-label desc :text="__('common_email')" />
                    <vap-input v-model="forward.emailName" :rules="'required'" @input="validateForward" noValidation />
                </vap-group>
                <vap-group c6 v-if="forward.send.free">
                    <vap-label desc :text="' '" />
                    <vap-input :value="forward.domain" disabled noValidation />
                </vap-group>
                <vap-group c12>
                    <vap-label desc :text="'Er det noen spesielle funn?'" />
                    <vap-checkbox v-model="forward.findings" :options="yesNoOptions" inline radio noHighlight @input="validateForward" noValidation />
                </vap-group>
                <vap-group c12 v-if="forward.findings.yes">
                    <vap-label desc :text="'Funn/kommentar'" />
                    <vap-textarea v-model="forward.comment" @input="validateForward" />
                </vap-group>
            </vap-row>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <!-- <VFButton :text="'TEST'" :icon="'check'" @clicked="testStuff" :working="dialog.working" /> -->
            <VFButton :text="'Send'" :icon="'check'" @clicked="onCreate" :disabled="!forward.valid" :working="dialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1reportHelper from '../v1helpers/v1reportHelper';

import downloadjs from 'downloadjs';
import v2lib from '../v2lib';
import validations from '../validations';
import verified from '../verified';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

import TextEditorV2 from '../components/form/TextEditorV2.vue';

export default {
    name: 'ForwardDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox,
        TextEditorV2,
    },
    props: {
        client: { type: Object, default: null },
        params: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            attachments: [],
            comment: '',
            forward: { show: false, working: false, valid: false, firstName: '', lastName: '', email: '', emailName: '', domain: '@deloitte.no', findings: { yes: false, no: false }, send: { partner: true, free: false }, comment: '' },
            yesNoOptions: {
                yes: { key: 'common_yes' },
                no: { key: 'common_no' },
            },
            sendOptions: {
                partner: { label: 'Send til partner' },
                free: { label: 'Send til andre' },
            },
            partners: [],
            partnerModel: '',
        }
    },
    methods: {

        onPartnerSelected() {

            const partner = this.getPartner(this.partnerModel);

            console.log('partner', partner);

            this.forward.firstName = partner.firstName;
            this.forward.lastName = partner.lastName;
            this.forward.emailName = partner.email.split('@')[0];

        },

        onCommentInput(input) {
            this.comment = input;
        },

        validateForward() {
            this.forward.valid = true;
            
            if (!this.forward.firstName) this.forward.valid = false;
            if (!this.forward.lastName) this.forward.valid = false;

            if (this.$root.debug.local) this.forward.domain = '@verified.eu';
            this.forward.email = this.forward.emailName + this.forward.domain;

            if (!this.forward.findings.yes && !this.forward.findings.no) this.forward.valid = false;

            if (this.forward.findings.yes && !this.forward.comment) this.forward.valid = false;

            const result = validations.validateEmail(this.forward.email);
            if (!result.valid) this.forward.valid = false;
        },

        async testStuff() {
            this.dialog.working = true;
            this.dialog.status = this.__t('report-prog-creating');

            const downloadData = await v1reportHelper.getMergedReport(this.$root, this.params, true);
            downloadjs("data:application/pdf;base64," + downloadData, 'test' + ".pdf", "application/pdf");

            this.dialog.working = false;
            this.dialog.show = false;
        },

        async onCreate() {
            this.dialog.working = true;
            this.dialog.status = 'Oppretter forenklet rapport';

            const root = this.$root;

            const base64 = await v1reportHelper.getMergedReport(this.$root, this.params, true);


            this.dialog.status = 'Oppretter konvolutt';

            // Create envelope
            const envelope = await v2lib.createEnvelope('aml-portal-risk', 'only-editors-flow', false);

            this.dialog.status = 'Legger til rapporten';

            // Upload the PDF as an attachment
            const ures = await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, `Sluttrapport ${this.client.name}.pdf`);
            console.log('ures', ures);

            // Add the recipient
            await v2lib.addEditorRecipient(envelope, this.forward, 'nb_NO');

            // Prepare credentials
            const credentials = {
                id: this.client._id,
                cid: root.credentials.cid,
                key: root.credentials.key,
                bearerToken: ''
            };
            if (root.credentials.testinc) credentials.testinc = root.credentials.testinc;

            // Prepare mail config
            const mail = {
                redirect: root.config.branding.redirectUri,
                logourl: root.config.branding.logoEmail,
                buttonColor: root.getColor('button'),
                linkColor: root.getColor('text-accent'),

                url: root.baseUrl + '/assessment',
                lang: 'nb_NO',

                // Notify editor
                subject: 'Sluttrapport ' + this.client.name,
                header: 'Hei',
                message: '<p>I forbindelse med kundekontroll etter hvitvaskingsloven har vi innhentet informasjon gjennom oppslag i offentlig tilgjengelige registre og bedt kunden gjennomgå og bekrefte informasjon om foretaket, herunder informasjon med hensyn til eierskaps- og kontrollstruktur, reelle rettighetshavere og person som handler på vegne av kunde samt eventuell Politisk Eksponert Person blant disse basert på søk i Trapets. Trapets dekker PEPs i Norden. Dersom reelle rettighetshavere/person som handler på vegne av klient er hjemmehørende utenfor Norden må Partner vurdere behovet for å bekrefte informasjon fra kunde med hensyn til PEP-status ved hjelp av øvrige handlinger.</p><p>Det er videre foretatt søk i sanksjonsregistre fra EU og FN. Søk i øvrige sanksjonsregistre er per nå ikke implementert. &nbsp;Partner må vurdere behov for søk i eventuelle øvrige sanksjonsregistre, herunder OFAC.</p><p>Det er foretatt google-søk for foretaket samt personer identifisert som reelle rettighetshavere og person som handler på vegne av klient ut fra søkeord som fremkommer av klientakseptskjema.&nbsp; Teamet må selv vurdere behovet for google-søk av øvrige relevante personer etter DPM 1540.</p><p>&nbsp;</p><p>Vedlagt er en forenklet rapport av AML-kundekontroll som du må gjennomgå og sjekke at informasjonen stemmer med den kunnskapen du besitter. Compliance Desk i ditt FO har markert i rødt om det er noen spesielle funn. Du trenger ikke bekrefte at det stemmer, men hvis du finner noe feil må du sende en e-post til&nbsp;<a href="mailto:aml@deloitte.no" rel="noopener noreferrer nofollow">aml@deloitte.no</a>&nbsp;med hva som eventuelt må rettes opp i. Hører vi ikke fra deg, antar vi at informasjonen er riktig.</p><p>Fullstendig rapport vil arkiveres i felles arkiv. Det er derfor ikke krav om arkivering og oppbevaring av den forenklede rapporten. &nbsp;</p>',
                comment: this.forward.comment,
                hint: 'Trykk på knappen for å se sluttrapporten',
                footer: '-',
                buttonText: 'ÅPNE SLUTTRAPPORT',
            };

            if (root.isAWS) mail.url = root.baseUrl + '/web/aml-portal/#/assessment';

            const userdata = {
                mail: mail,
                credentials: credentials,
            };

            this.dialog.status = 'Videresender rapport';

            await v2lib.setUserData(envelope, userdata);
            await v2lib.setTags(envelope, ['partnerkopi']);
            await v2lib.setName(envelope, `Kopi til partner ${this.client.name}.pdf`);
            await v2lib.publishEnvelope(envelope);


            this.dialog.status = 'Oppdaterer kunde';

            const forwardData = {
                mode: 'view',
                type: 'simple',
                firstName: this.forward.firstName,
                lastName: this.forward.lastName,
                email: this.forward.email,
                userId: root.credentials.uid,
                envelopeId: envelope.id,
                date: new Date()
            };
            await v1api.forwardReport(this.client._id, forwardData);

            const response = await v1api.getClient(this.client._id);
            this.$emit('created', response);

            this.dialog.working = false;
            this.dialog.show = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {

        // Load partners
        if (this.$root.config.partners.enabled) {
            this.partners = [];
            for (const i in this.$root.partners) {
                this.partners.push({
                    key: this.$root.partners[i].key,
                    name: this.$root.partners[i].firstName + ' ' + this.$root.partners[i].lastName
                });
            }
        }

        this.loading = false;
    }
}
</script>