import log from '../../../../log';
import formHelper from '../../formHelper';
const checklistModuleHelper = {

    getEmptyConfig() {
        return {
            type: 'chk',
            icon: 'check'
        };
    },

    getEmptyCardConfig(count) {
        return {
            key: 'c' + count,
            type: 'chk-c',
            header: {
                en_EN: 'New checklist',
                nb_NO: 'Ny sjekkliste'
            },
            inputs: [],
            pdf: true,
            pdfIndex: 0,
            pdfLocked: false
        };
    },

    getEmptyInputConfig(count, isList) {
        return {
            key: 'c' + count,
            type: 'chk-i',
            width: '12',
            desc: {
                formatted: true,
                en_EN: 'New data point',
                nb_NO: 'Nytt datapunkt'
            },
            required: false,
            isList: isList || false,
            mode: 'chk',
            ids: 'chkI01',
            vs: 'chkV01',
            qcr: 'chkRes01',
            qcc: 'chkCom01',
            idsL: 1,
            vsL: 1,
        };
    },

    getEmptyArrayConfig(count) {
        return {
            key: 'c' + count,
            type: 'chk-a',
            header: {
                en_EN: 'New checklist',
                nb_NO: 'Ny sjekkliste'
            },
            addtext: {
                en_EN: 'Add item',
                nb_NO: 'Legg til sjekkliste'
            },
            inputs: [],
            pdf: true,
            pdfIndex: 0,
            pdfLocked: false
        };
    },

    getEmptyHeaderConfig(count) {
        return {
            key: 'c' + count,
            type: 'chk-h',
            header: {
                en_EN: 'New checklist',
                nb_NO: 'Ny sjekkliste'
            },
            pdf: true,
            pdfIndex: 0,
            pdfLocked: false
        };
    },

    onFormCreated(root, reference, designer, module, pages, isOwner, userdata) {

        const existingData = JSON.parse(root.formData);
        log.x('Checklist Module: On form created, existing data:', existingData);

        if (reference.formKey == 'amlc02') {
            root.$set(reference.resources, 'isCDD', {
                type: 'bool',
                key: 'isCDD',
                name: 'isCDD',
                value: true
            });
            console.log('isCDD');
        }

        root.$set(reference.resources, 'chkI01', {
            type: 'list',
            key: 'chkI01',
            name: 'ID Source 1',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkAppForm', en_EN: 'App Form' },
                { value: 'chkAmlForm', en_EN: 'AML Form' },
                { value: 'chkFileNote', en_EN: 'File Note' },
                { value: 'chkStrucChart', en_EN: 'Structure Chart' },
                { value: 'chkCH', en_EN: 'Companies House' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkV01', {
            type: 'list',
            key: 'chkV01',
            name: 'V Source 1',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkArtic', en_EN: 'Articles of Association' },
                { value: 'chkCertInc', en_EN: 'Certificate of Incorporation of equivalent' },
                { value: 'chkLlpAgree', en_EN: 'Copy of LLP Aggreement (if applicable)' },
                { value: 'chkCopyReg', en_EN: 'Copy of the File and the Office of the Registrar of Companies' },
                { value: 'chkCHSearc', en_EN: 'Companies House Search' },
                { value: 'chkChExtract', en_EN: 'Companies House Extract' },
                { value: 'chkFinStat', en_EN: 'Financial Statement' },
                { value: 'chkAnnRep', en_EN: 'Annual Report' },
                { value: 'chkOnSite', en_EN: 'On-Site Visit' },
                { value: 'chkWebsite', en_EN: 'Company Website' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkRes01', {
            type: 'list',
            key: 'chkRes01',
            name: 'QC Result',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkPass', en_EN: 'Pass' },
                { value: 'chkPassF', en_EN: 'Pass with Feedback' },
                { value: 'chkFail', en_EN: 'Fail' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkCom01', {
            type: 'list',
            key: 'chkCom01',
            name: 'QC Complete',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkYes', en_EN: 'Yes' },
                { value: 'chkNo', en_EN: 'No' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkScr01', {
            type: 'list',
            key: 'chkScr01',
            name: 'Screening Status',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkCom', en_EN: 'Complete' },
                { value: 'chkIncom', en_EN: 'Incomplete' },
                { value: 'chkRefBr', en_EN: 'Referred to Branch' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkScr02', {
            type: 'list',
            key: 'chkScr02',
            name: 'Screening Escalated',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkProg', en_EN: 'In Progress' },
                { value: 'chkCom', en_EN: 'Complete' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkYN', {
            type: 'list',
            key: 'chkYN',
            name: 'Yes No NA',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkYes', en_EN: 'Yes' },
                { value: 'chkNo', en_EN: 'No' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkYNx', {
            type: 'list',
            key: 'chkYNx',
            name: 'Yes No',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkYes', en_EN: 'Yes' },
                { value: 'chkNo', en_EN: 'No' }
            ]
        });


        root.$set(reference.resources, 'chkDDid01', {
            type: 'list',
            key: 'chkDDid01',
            name: 'ID Dropdown 01',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkAppForm', en_EN: 'Application Form' },
                { value: 'chkAmlForm', en_EN: 'AML Risk Assessment' },
                { value: 'chkFileNote', en_EN: 'File Note' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDid02', {
            type: 'list',
            key: 'chkDDid02',
            name: 'ID Dropdown 02',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkAmlForm', en_EN: 'AML Risk Assessment' },
                { value: 'chkFileNote', en_EN: 'File Note' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDid03', {
            type: 'list',
            key: 'chkDDid03',
            name: 'ID Dropdown 03',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkAppForm', en_EN: 'Application Form' },
                { value: 'chkAmlForm', en_EN: 'AML Risk Assessment' },
                { value: 'chkFileNote', en_EN: 'File Note' },
                { value: 'chkStrucChart', en_EN: 'Structure Chart' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDid04', {
            type: 'list',
            key: 'chkDDid04',
            name: 'ID Dropdown 04',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkAppForm', en_EN: 'Application Form' },
                { value: 'chkAmlForm', en_EN: 'AML Risk Assessment' },
                { value: 'chkEquifax', en_EN: 'Equifax' },
                { value: 'chkFileNote', en_EN: 'File Note' },
                { value: 'chkPassport', en_EN: 'Passport' },
                { value: 'chkUserForm', en_EN: 'User Form' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });




        root.$set(reference.resources, 'chkDDvs01', {
            type: 'list',
            key: 'chkDDvs01',
            name: 'VS Dropdown 01',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkCertInc', en_EN: 'Certificate of Incorporation of equivalent' },
                { value: 'chkCHSearc', en_EN: 'Companies House Search' },
                { value: 'chkCopyReg', en_EN: 'Copy of the File and the Office of the Registrar of Companies' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDvs02', {
            type: 'list',
            key: 'chkDDvs02',
            name: 'VS Dropdown 02',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkChExtract', en_EN: 'Companies House Extract' },
                { value: 'chkFinStat', en_EN: 'Financial Statement' },
                { value: 'chkAnnRep', en_EN: 'Annual Report' },
                { value: 'chkOnSite', en_EN: 'Documented Findings from an On-Site Visit' },
                { value: 'chkWebsite', en_EN: 'Company Website' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDvs03', {
            type: 'list',
            key: 'chkDDvs03',
            name: 'VS Dropdown 03',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkArtic', en_EN: 'Articles of Association' },
                { value: 'chkLlpAgree', en_EN: 'Copy of LLP Aggreement (if applicable)' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDvs04', {
            type: 'list',
            key: 'chkDDvs04',
            name: 'VS Dropdown 04',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkAmlForm', en_EN: 'AML Risk Assessment' },
                { value: 'chkOnSite1', en_EN: 'On-Site Visit' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDvs05', {
            type: 'list',
            key: 'chkDDvs05',
            name: 'VS Dropdown 05',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkFinStatAR', en_EN: 'Financial Statement / Annual Report' },
                { value: 'chkOnSite1', en_EN: 'On-Site Visit' },
                { value: 'chkConDoc', en_EN: 'Constitutional / Governing Document' },
                { value: 'chkWebsite', en_EN: 'Company Website' },
                { value: 'chkLandReg', en_EN: 'Land Registry (Property Landlords)' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDvs06', {
            type: 'list',
            key: 'chkDDvs06',
            name: 'VS Dropdown 06',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkEvidStruc', en_EN: 'Evidence of Structure' },
                { value: 'chkChExtract', en_EN: 'Companies House Extract' },
                { value: 'chkSignAnRep', en_EN: 'Latest Signed and Audited Annual Report and Accounts' },
                { value: 'chkParFinSt', en_EN: 'Parents latest signed & audited financial stmts' },
                { value: 'chkProspect', en_EN: 'Prospective / Offering Memorandum' },
                { value: 'chkShareCert', en_EN: 'Share Certif' },
                { value: 'chkShareReg', en_EN: 'Share Register' },
                { value: 'chkWritConf', en_EN: 'Written Conf from prof body' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDvs07', {
            type: 'list',
            key: 'chkDDvs07',
            name: 'VS Dropdown 07',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkBoardRes', en_EN: 'Board of Resolution or Other Authority' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDvs08', {
            type: 'list',
            key: 'chkDDvs08',
            name: 'VS Dropdown 08',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkPassportF', en_EN: 'Full Passport' },
                { value: 'chkDLphoto', en_EN: 'UK Photo-Card Driving Licence' },
                { value: 'chkEidV', en_EN: 'eID&V' },
                { value: 'chkResPerm', en_EN: 'Residence permit' },
                { value: 'chkNatIDC', en_EN: 'National Identity Card' },
                { value: 'chkFireLic', en_EN: 'Firearms or Shotgun Licence' },
                { value: 'chkTaxExCert', en_EN: 'Tax Exemption Certificate' },
                { value: 'chkBeneBook', en_EN: 'Benefit Book / Benefits Agency Notification Letter' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDvs09', {
            type: 'list',
            key: 'chkDDvs09',
            name: 'VS Dropdown 09',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkEquifax', en_EN: 'Equifax' },
                { value: 'chkBankCredit', en_EN: 'Bank / Credit Card Statement' },
                { value: 'chkUtilityBill', en_EN: 'Utility Bill (excl. Mobile and Printed Statements' },
                { value: 'chkTaxAuthBill', en_EN: 'Local Tax Authority Bill' },
                { value: 'chkDLphoto', en_EN: 'UK Photo-Card Driving Licence' },
                { value: 'chkDLFullPhot', en_EN: 'Full UK Paper Driving Licence' },
                { value: 'chkSolicLet', en_EN: 'Solicitors Letter' },
                { value: 'chkLandRegCon', en_EN: 'Land Registry Confirmation' },
                { value: 'chkOther', en_EN: 'Other' },
                { value: 'chkNA', en_EN: 'N/A' },
            ]
        });

        root.$set(reference.resources, 'chkDDvs10', {
            type: 'list',
            key: 'chkDDvs10',
            name: 'VS Dropdown 10',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkComment', en_EN: 'Add verification source in comments' },
            ]
        });



        root.$set(reference.resources, 'chkOpts', {
            type: 'list',
            key: 'chkOpts',
            name: 'Dropdown Options',
            definition: {
                value: 'string',
                en_EN: 'string'
            },
            items: [
                { value: 'chkNone', en_EN: 'None' },
                { value: 'chkI01', en_EN: 'ID Source 1' },
                { value: 'chkV01', en_EN: 'V Source 1' },
                { value: 'chkRes01', en_EN: 'QC Result' },
                { value: 'chkCom01', en_EN: 'QC Complete' },
                { value: 'chkScr01', en_EN: 'Screening Status' },
                { value: 'chkScr02', en_EN: 'Screening Escalated' },
                { value: 'chkYN', en_EN: 'Yes/No/NA' },
                { value: 'chkYNx', en_EN: 'Yes/No' },

                { value: 'chkDDid01', en_EN: 'ID Dropdown 01' },
                { value: 'chkDDid02', en_EN: 'ID Dropdown 02' },
                { value: 'chkDDid03', en_EN: 'ID Dropdown 03' },
                { value: 'chkDDid04', en_EN: 'ID Dropdown 04' },

                { value: 'chkDDvs01', en_EN: 'VS Dropdown 01' },
                { value: 'chkDDvs02', en_EN: 'VS Dropdown 02' },
                { value: 'chkDDvs03', en_EN: 'VS Dropdown 03' },
                { value: 'chkDDvs04', en_EN: 'VS Dropdown 04' },
                { value: 'chkDDvs05', en_EN: 'VS Dropdown 05' },
                { value: 'chkDDvs06', en_EN: 'VS Dropdown 06' },
                { value: 'chkDDvs07', en_EN: 'VS Dropdown 07' },
                { value: 'chkDDvs08', en_EN: 'VS Dropdown 08' },
                { value: 'chkDDvs09', en_EN: 'VS Dropdown 09' },
                { value: 'chkDDvs10', en_EN: 'VS Dropdown 10' },
            ]
        });

        root.$set(reference.resources, 'chkLevels', {
            type: 'list',
            key: 'chkLevels',
            name: 'Due Diligence Level Options',
            definition: {
                value: 'int',
                en_EN: 'string'
            },
            items: [
                { value: 1, en_EN: 'SDD' },
                { value: 2, en_EN: 'CDD' },
                { value: 3, en_EN: 'EDD' }
            ]
        });

        // TODO fix this
        const itm = formHelper.getItemFromKey(pages, 'i1046');
        //console.log('itm', itm);

        root.$set(reference.resources, 'chkLevel', {
            type: 'value',
            key: 'chkLevel',
            name: 'Due Diligence Level',
            value: itm && itm.model ? itm.model : 1
        });


        // Prepare checklist model
        reference.chkModel = {
            key: reference.formKey,
            enabled: true,
            finalValidation: true,
            branch: {
                key: '',
                firstName: '',
                lastName: '',
                email: ''
            },
            cdd: {
                key: '',
                firstName: '',
                lastName: '',
                email: ''
            }
        };
        if (userdata && userdata.chkModel) {
            reference.chkModel = userdata.chkModel;
        }
        console.log('chk usr', userdata);
        console.log('chk model', reference.chkModel);

        if (!designer) {

            if (existingData && existingData.customer) {
                const dep = root.getDepartment(existingData.customer.department);

                reference.chkModel.branch.key = existingData.customer.department;
                reference.chkModel.branch.firstName = dep.name;
                reference.chkModel.branch.lastName = 'Branch';
                reference.chkModel.branch.email = dep.email;
            }
            console.log('chkModel', reference.chkModel);

        }
        
        
    },

    onSetUserdata(root, reference, module, envelope, userdata) {

        const existingData = JSON.parse(root.formData);
        log.x('Checklist Module: On set userdata, existing data:', existingData);

        userdata.chkModel = reference.chkModel;
        
    },

    onSetMetadata(root, reference, module, envelope, userdata, metadata, roleKey, recipient) {

        log.x('Checklist Module: On set metadata');

        if (reference.chkModel.branch.firstName) {
            metadata.silentParties.push({
                givenName: reference.chkModel.branch.firstName,
                familyName: reference.chkModel.branch.lastName,
                email: reference.chkModel.branch.email
            });
        }
        
    },


    onLinkValue(root, reference, designer, module, link, value) {

        log.x('Checklist Module: On link value (' + link.link + ')', value);

        if (link.link == 'fval') {
            
            //reference.idcModel[link.link] = value;

            if (value.x1131) {
                reference.chkModel.finalValidation = true;
            }else {
                reference.chkModel.finalValidation = false;
            }
            console.log('final val set to', reference.chkModel.finalValidation);

            reference.validateEverything();

        }
        
    },

};
export default checklistModuleHelper;