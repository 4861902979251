<template>
<div class="frv2-designer"
    :class="[
        { 'logic': logicActive }
    ]"
    v-on-clickaway="deselect"
    @contextmenu="onContextMenu"
>
    <DesignerLayout
        :pages="pages"
        :pageIndex="pageIndex"
        :phoneMode="phoneMode"
        :showPropsInput="showPropsInput"
        :showPropsPage="showPropsPage"
        :menuModel="menuModel"
        :designer="designer"
        :logicActive="logicActive"
        :editorModel="editorModel"
        :customTab="customTab"
        :customTabSelected="customTabSelected"
        @resize="rightWidthBar = $event"
        @phone="phoneMode = $event"
        @menu="showPropBox"
        @menuconnect="onMenuConnect"
        @tab="onEditorToggle"
        @deselect="deselect"
    >
        <template v-slot:menu>
            <FormMenu v-if="!logicActive"
                @help="showHelpMenu(true)"
                :reference="reference"
                :designer="designer"
                :roleOptions="roleOptions"
                :phoneMode="phoneMode"
                :saving="isSaving"
                :hasChanges="hasChanges"
                :canUndo="changeLog.length > 0 && changeLogIndex < changeLog.length - 1"
                :canRedo="changeLogIndex >= 0"
                :sticky="scrolled"
                :toggleModel="editorModel"
                @toggle="onEditorToggle"
                @mode="setDesignerMode"
                @phone="setPhoneMode"
                @save="saveForm"
                @flow="flowDesignerVisible = true"
                @resources="showManageResources"
                @attachment="onShowAttachmentDialog"
                @undo="onUndo"
                @redo="onRedo"
                @rolechanged="onRoleChanged"
                @pdf="pdfPreviewVisible = true"
                @landing="showLandingDesigner"
                @settings="onShowFormProperties"
                @logic="showLogicDesigner"
                @locale="showLanguageDialog"
                @test="problems.showDialog = true"
            />
        </template>
        <template v-slot:stepper>
            <FormStepper :steps="steps" :stepKey="stepKey" :designer="designer" v-show="editorModel.edit || editorModel.view"
                :offset="62"
                :designerView="true"
                :title="formData.title[$root.locale.key]"
                :designerWidth="rightWidthBar"
                :designerPhoneMode="phoneMode"
                :hideActions="role == 'sign'"
                :logicActive="logicActive"
                :clipboard="clipboard"
                @keychanged="onStepperKeyChanged"
                @add="onAddPage"
                @paste="onPasteItem"
                @edit="onShowPageProperties"
                @scrolled="scrolled = $event"
                @logic-connect="onConnectLogic"
                @connect-enter="onStepEnter"
                @connect-leave="onStepLeave"
            />
        </template>
        <template v-slot:renderer>

            <FormRenderer ref="renderer" :designer="designer" :builder="true" :toggle="toggle" v-show="editorModel.edit || editorModel.view"
                :page="pages[pageIndex]"
                :pages="pages"
                :resources="resources"
                :roleKey="role"
                :logicActive="logicActive"
                :phoneMode="phoneMode"
                :clipboard="clipboard"
                :selectedLine="selectedLine"
                @add-card="showAddCardDialog"
                @add-input="showAddInputDialog"
                @paste-input="onPasteItem"
                @paste-card="onPasteItem"
                @sel-card="onSelectedCardChanged"
                @sel-input="onSelectedInputChanged"
                @logic="handleLogic"
                @chklogic="handleChecklistLogic"
                @logic-possible="logicPossible = $event"
                @logic-connect="onConnectLogic"
                @logic-connectx="onConnectLogicX"
                @line-new="onAddLineClicked"
                @line-edit="onLineClicked"
                @line-delete="onDeleteLineClicked"
                @editorinput="onFormInput"
                @personinput="onRendererInput"
                @match="onDictionaryMatch"
            />

            <PdfEditor v-if="editorModel.pdf" ref="pdfeditor"
                :reference="reference"
                @selectcard="onPdfSelectCard"
            />
            <EmailEditor v-if="editorModel.email"
                :reference="reference"
            />
            <LandingEditor v-if="editorModel.landing"
                :reference="reference"
            />

        </template>
        <template v-slot:props>

            <FormProperties v-if="showPropsInput && !customTab"
                :card="selectedCard"
                :input="selectedInput"
                :helpline="selectedLine"
                :logicActive="logicActive"
                :reference="reference"
                :isPdfEditor="editorModel.pdf"
                @locale="showTextEditor"
                @delete="onDeleteItem"
                @move="onMoveItem"
                @copy="onCopyItem"
                @duplicate="onDuplicateItem"
                @kyc="onConnectToKYC"
                @propinput="onPropInput"
                @reselect="reselectItem"
                @logic="handleLogic"
                @icon="showIconSelector"
                @trans="showTranslationsDialog"
                @linedelete="onDeleteLineClicked"
            />

            <PageProperties v-if="showPropsPage && !editorModel.pdf"
                :reference="reference"
                :page="pages[pageIndex]"
                @move="onMovePage"
                @delete="onDeletePage"
                @logic="onStepLogic"
                @copy="onCopyItem"
                @propinput="onPropInput"
            />

            <FormPropertiesX v-if="menuModel.settings" :reference="reference" @propinput="onPropInput" />

            <LanguagePropBox v-if="menuModel.language" :reference="reference" />
            <LogicPropBox v-if="menuModel.logic" :reference="reference" @logic="showLogicDesigner" />
            <ModulesPropBox v-if="menuModel.modules" :reference="reference" :logicActive="logicActive" @stoplogic="stopCanvas" @connect="onConnectModule" @logic="handleLogic" />
            <ResourcesPropBox v-if="menuModel.resources" :reference="reference" :logicActive="logicActive" @stoplogic="stopCanvas" @connect="onConnectLogic" />
            <FlowPropBox v-if="menuModel.flow" :reference="reference" :logicActive="logicActive" @stoplogic="stopCanvas" @reselect="reselectItem" />
            <AttachmentsPropBox v-if="menuModel.attachments" :reference="reference" />

            <HelpPropBox v-show="menuModel.help" :reference="reference" />
            <InfoPropBox v-if="menuModel.info" :reference="reference" />

            <PdfPropBox v-if="editorModel.pdf && !showPropsInput" :reference="reference" @userdata="loadUserdata" />
            <EmailPropBox v-if="editorModel.email && customTabSelected" :reference="reference" />
            <LandingPropBox v-if="editorModel.landing && customTabSelected" :reference="reference" />

            <ViewPropBox v-if="editorModel.view && customTabSelected" :reference="reference" @userdata="loadUserdata" />

            <DataModelPropBox v-if="menuModel.datamodel" :reference="reference" @userdata="loadUserdata" />

        </template>
    </DesignerLayout>


    <!-- <PageProperties v-if="showPageProperties" :page="pages[pageIndex]" @close="showPageProperties = false" @move="onMovePage" @delete="onDeletePage" @logic="onStepLogic" @copy="onCopyItem" /> -->
    <!-- <FormPropertiesX v-if="showFormProperties" @close="showFormProperties = false" /> -->
    <SignerProperties v-if="role == 'sign'" />

    <InputDialog v-if="addInputVisible" :params="addInputParams" :reference="reference" @add="onAddInput" @close="addInputVisible = false" />
    <CardDialog v-if="addCardVisible" :params="addCardParams" :reference="reference" @add="onAddCard" @close="addCardVisible = false" />
    <LogicDialog v-if="logicVisible" :params="logicParams" @add="onAddCard" @close="logicVisible = false" @reselect="reselectItem" />
    <LogicConnector v-if="logicVisible2" :reference="reference" :params="logicParams2" @close="logicVisible2 = false" @reselect="reselectItem" />

    <FormTextEditor v-if="textEditorVisible" :params="textEditorParams" @close="textEditorVisible = false" />

    <IconSelector v-if="selectIconVisible" :params="selectIconParams" @close="selectIconVisible = false" />

    <RecipientDialog v-if="recipientLogicVisible" :reference="reference" :params="recipientLogicParams" @close="recipientLogicVisible = false" @reselect="reselectItem" />
    <ResourceDialog v-if="resourceDialogVisible" :params="resourceDialogParams" @close="resourceDialogVisible = false" @connect="onConnectLogic" />
    <SystemDialog v-if="systemDialogVisible" :reference="reference" :params="systemDialogParams" @close="systemDialogVisible = false" @manage="onManageModule" @connect="onConnectModule" @reselect="reselectItem" />
    <AttachmentDialog v-if="attachmentDialogVisible" :params="attachmentDialogParams" @close="attachmentDialogVisible = false" />

    <LandingDialog v-if="landingDesignerVisible" :params="landingDesignerParams" :textParams="textEditorParams" @close="landingDesignerVisible = false" />
    <PDFDesigner v-if="pdfPreviewVisible" @close="pdfPreviewVisible = false" />
    <LandingDesigner v-if="landingVisible" @close="landingVisible = false" />

    <LogicDesigner v-if="logicDesignerVisible" :params="logicDesignerParams" @close="logicDesignerVisible = false" @output="onShowLogicOutputConnector" />
    <LogicOutputConnector v-if="logicOutputVisible" :reference="reference" :params="logicOutputParams" @close="logicOutputVisible = false" />

    <LanguageDialog v-if="languageDialogVisible" @close="languageDialogVisible = false" />

    <ProblemDialog v-if="problems.showDialog" @close="problems.showDialog = false" />

    <FormHelpMenu v-if="showHelp" @close="showHelpMenu(false)" />

    <canvas id="frv2canvas" v-if="logicActive" />
    <FormMenuLogic :logicActive="logicActive" :scrolled="scrolled" />

    <!-- Modules -->
    <KycModuleView v-if="kycModule.show" :reference="reference" :params="kycModule.uiParams" @close="kycModule.show = false" />
    <IdcModuleView v-if="idcModule.show" :params="idcModule.uiParams" @close="idcModule.show = false" />
    <SignModuleView v-if="signModule.show" :reference="reference" :params="signModule.uiParams" @close="signModule.show = false" @reselect="reselectItem" />
    <ChecklistModuleView v-if="checklistModule.show" :params="checklistModule.uiParams" @close="checklistModule.show = false" />
    <ClientModuleView v-if="clientModule.show" :reference="reference" :params="clientModule.uiParams" @close="clientModule.show = false" @reselect="reselectItem" />
    <KarModuleView v-if="karModule.show" :params="karModule.uiParams" @close="karModule.show = false" />
    <FormModuleView v-if="formModule.show" :params="formModule.uiParams" @close="formModule.show = false" />
    <ApproveModuleView v-if="approveModule.show" :reference="reference" :params="approveModule.uiParams" @close="approveModule.show = false" />

    <FlowDesigner v-if="flowDesignerVisible" :roles="roleOptions" @close="flowDesignerVisible = false" />

    <TranslationsDialog v-if="translationsDialog.show" :reference="reference" :params="translationsDialog" @close="translationsDialog.show = false" />

</div>
</template>

<script>
import log from '@/log';
import { mixin as clickaway } from "vue-clickaway"

import formHelper from './formHelper';
import viewHelper from './viewHelper';
import editorHelper from './editorHelper';
import clipboardHelper from './clipboardHelper';
import testHelper from './testHelper';
import logicHelper from './logicHelper';
import moduleHelper from './modules/moduleHelper';

import DesignerLayout from './designer/ui/DesignerLayout.vue';

import PdfEditor from './designer/editors/PdfEditor.vue';
import EmailEditor from './designer/editors/EmailEditor.vue';
import LandingEditor from './designer/editors/LandingEditor.vue';

import LanguagePropBox from './designer/propboxes/LanguagePropBox.vue';
import LogicPropBox from './designer/propboxes/LogicPropBox.vue';
import ModulesPropBox from './designer/propboxes/ModulesPropBox.vue';
import ResourcesPropBox from './designer/propboxes/ResourcesPropBox.vue';
import AttachmentsPropBox from './designer/propboxes/AttachmentsPropBox.vue';
import FlowPropBox from './designer/propboxes/FlowPropBox.vue';
import InfoPropBox from './designer/propboxes/InfoPropBox.vue';
import HelpPropBox from './designer/propboxes/HelpPropBox.vue';
import PdfPropBox from './designer/propboxes/PdfPropBox.vue';
import EmailPropBox from './designer/propboxes/EmailPropBox.vue';
import LandingPropBox from './designer/propboxes/LandingPropBox.vue';
import ViewPropBox from './designer/propboxes/ViewPropBox.vue';
import DataModelPropBox from './designer/propboxes/DataModelPropBox.vue';

import FormRenderer from './Renderer';
import FormProperties from './designer/Properties';
import FormStepper from './FormStepper';
import FormMenu from './designer/FormMenu';
import FormMenuLogic from './designer/FormMenuLogic.vue';
import InputDialog from './designer/InputDialog';
import CardDialog from './designer/CardDialog';
import LogicDialog from './designer/LogicDialog';
import FormHelpMenu from './guide/FormHelpMenu';
import PageProperties from './designer/PageProperties';
import FormPropertiesX from './designer/FormProperties';
import SignerProperties from './designer/SignerProperties';
import FormTextEditor from './designer/FormTextEditor';
import IconSelector from './designer/IconSelector';
import RecipientDialog from './designer/RecipientDialog';
import ResourceDialog from './designer/ResourceDialog';
import AttachmentDialog from './designer/AttachmentDialog';
import SystemDialog from './designer/SystemDialog';
import LanguageDialog from './designer/LanguageDialog';
import ProblemDialog from './designer/ProblemDialog.vue';
import LogicConnector from './designer/LogicConnector.vue';
import LogicOutputConnector from './designer/LogicOutputConnector.vue';

import LandingDialog from './designer/LandingDialog';
import PDFDesigner from './designer/PDFDesigner';
import LandingDesigner from './designer/LandingDesigner';
import FlowDesigner from './designer/FlowDesigner';
import LogicDesigner from './designer/LogicDesigner';

// Modules
import KycModuleView from './modules/kyc/KycModuleView.vue';
import IdcModuleView from './modules/idcontrol/IdcModuleView.vue';
import SignModuleView from './modules/signing/SignModuleView.vue';
import ChecklistModuleView from './modules/checklist/ChecklistModuleView.vue';
import ClientModuleView from './modules/customer/ClientModuleView.vue';
import KarModuleView from './modules/kar/KarModuleView.vue';
import FormModuleView from './modules/formdata/FormModuleView.vue';
import ApproveModuleView from './modules/approve/ApproveModuleView.vue';

import TranslationsDialog from './designer/dialogs/TranslationDialog.vue';

export default {
    name: 'FormDesignerV2',
    mixins: [clickaway],
    components: {
        DesignerLayout,
        PdfEditor,
        EmailEditor,
        LandingEditor,
        LanguagePropBox,
        LogicPropBox,
        ModulesPropBox,
        ResourcesPropBox,
        AttachmentsPropBox,
        FlowPropBox,
        InfoPropBox,
        HelpPropBox,
        PdfPropBox,
        EmailPropBox,
        LandingPropBox,
        ViewPropBox,
        DataModelPropBox,

        TranslationsDialog,

        FormRenderer,
        FormProperties,
        FormStepper,
        FormMenu,
        FormMenuLogic,
        InputDialog,
        CardDialog,
        LogicDialog,
        FormHelpMenu,
        PageProperties,
        FormPropertiesX,
        SignerProperties,
        FormTextEditor,
        IconSelector,
        RecipientDialog,
        ResourceDialog,
        SystemDialog,
        LanguageDialog,
        ProblemDialog,
        LogicConnector,
        LogicOutputConnector,
        AttachmentDialog,
        // Modules
        KycModuleView, IdcModuleView, SignModuleView, ChecklistModuleView, ClientModuleView, KarModuleView, FormModuleView, ApproveModuleView,
        // Designers
        LandingDialog, PDFDesigner, LandingDesigner, FlowDesigner, LogicDesigner,
    },
    props: {
        formData: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            reference: null,
            designer: true,
            phoneMode: false,
            scrolled: false,
            rightWidthBar: 510,
            toggle: { logic: true, pdf: false, add: true, locale: false, info: true, },
            clipboard: { hasInput: false, hasCard: false, hasPage: false },

            accessLevel: 2,
            allowTranslation: false,
            temporaryDisable: true,

            role: 'owner',
            previousRole: 'owner',
            roleOptions: [{ key: 'owner', type: 'owner', display: 'Owner' }],
            roles: { owner: { label: 'Owner' } },
            roleModel: {},
            roleIndex: 0,

            editorModel: {
                view: false,
                edit: true,
                pdf: false,
                email: false,
                landing: false,
            },
            menuModel: {
                language: false,
                flow: false,
                resources: false,
                logic: false,
                modules: false,
                attachments: false,
                settings: false,
                help: false,
                info: false,
                datamodel: false,
            },
            customTab: '',
            customTabSelected: false,
            showPropsInput: false,
            showPropsPage: false,

            counter: 0,
            steps: [],
            stepKey: '',
            pages: [],
            pageIndex: 0,
            showPageProperties: false,
            showFormProperties: false,

            kycModule: { enabled: false, show: false, uiParams: { type: '', input: null } },
            idcModule: { enabled: false, show: false, uiParams: { type: '', input: null } },
            signModule: { enabled: false, show: false, uiParams: { type: '', input: null } },
            checklistModule: { enabled: false, show: false, uiParams: { type: '', input: null } },
            clientModule: { enabled: false, show: false, uiParams: { type: '', input: null } },
            karModule: { enabled: false, show: false, uiParams: { type: '', input: null } },
            formModule: { enabled: false, show: false, uiParams: { type: '', input: null } },
            approveModule: { enabled: false, show: false, uiParams: { type: '', input: null } },

            addInputVisible: false,
            addInputParams: {},
            addCardVisible: false,
            addCardParams: {},

            textEditorVisible: false,
            textEditorParams: {
                tabModel: {},
                tabOptions: {},
                value: null
            },

            selectIconVisible: false,
            selectIconParams: {},

            showHelp: false,

            selectedCard: null,
            selectedInput: null,
            selectedLine: null,

            logicActive: false,
            logicPossible: false,
            logicPoint: { x: 0, y: 0 },
            logicFromKey: '',
            logicParams: {},
            logiclogicOptions: [],
            logicVisible: false,
            logicHoverStep: null,

            logicParams2: {},
            logicVisible2: false,

            logicParamsFinalV2: {},

            resources: {},
            resourceDialogVisible: false,
            resourceDialogParams: { mode: 'manage' },

            modules: [],
            modulesDialogVisible: false,
            modulesDialogParams: { mode: 'manage' },

            systemDialogVisible: false,
            systemDialogParams: { mode: 'manage' },

            recipientLogicVisible: false,
            recipientLogicParams: { mode: 'manage' },

            logicOutputVisible: false,
            logicOutputParams: {},

            translationsDialog: { show: false, text: {} },

            problems: {
                showDialog: false,
                hasProblems: false,
            },

            attachments: [],
            attachmentDialogVisible: false,
            attachmentDialogParams: { mode: 'manage' },

            pdfPreviewVisible: false,

            flowDesignerVisible: false,

            landingVisible: false,
            landingDesignerVisible: false,
            landingDesignerParams: {},
            landingParams: null,

            logicDesignerVisible: false,
            logicDesignerParams: {},

            languageDialogVisible: false,

            changeLog: [],
            changeLogCurrent: null,
            changeLogIndex: -1,
            hasChanges: false,
            changeKey: '',
            isSaving: false,

            autoSave: false,
            canSave: false,
            customSender: { enabled: false },

            flags: {},
            branding: {},

            title: null,
            name: null,

            locales: [],
            logic: [],
            logicResults: {},
        }
    },
    watch: {
        formData: {
            deep: false,
            handler(val) {
                this.processData(val);
            }
        }
    },
    methods: {

        onRendererInput(card, input, index) {
            // Process logic
            for (const i in this.logic) {
                const output = logicHelper.performLogic(this, i, false);
                this.logicResults[this.logic[i].key] = output;
                log.x('Logic: ' + this.logic[i].name + ' processed', output);
            }
        },

        onDictionaryMatch(input, value, card, index) {
            if (input.logic && input.logic.dmatch) {
                for (const i in input.logic.dmatch.keys) {
                    const item = formHelper.getItemFromKey(this.pages, input.logic.dmatch.keys[i]);
                    if (item.isList) {
                        card.model[index][item.listModel] = value;
                    }else {
                        if (typeof value == 'object') {
                            item.model = value[item.modelKey];
                        }else {
                            item.model = value;
                        }
                    }
                }
            }
        },

        showTranslationsDialog(text) {
            this.translationsDialog.text = text;
            this.translationsDialog.show = true;
        },

        onEditorToggle(toggled) {
            for (const key in this.editorModel) {
                this.editorModel[key] = false;
                if (key == toggled) this.editorModel[key] = true;
            }
            if (this.editorModel.view) {

                this.customTab = 'view';
                this.customTabSelected = true;
                this.setDesignerMode(false);
                this.deselect();

            }else if (this.editorModel.edit) {

                this.customTab = '';
                this.setDesignerMode(true);

            }else if (this.editorModel.email) {

                this.customTab = 'email';
                this.showPropBox('none');
                this.customTabSelected = true;
                this.deselect();

            }else if (this.editorModel.landing) {

                this.customTab = 'landing';
                this.customTabSelected = true;
                this.deselect();

            }else if (this.editorModel.pdf) {

                this.customTab = '';
                this.deselect();

            }
        },

        onMenuConnect(key) {


            if (key == 'flow') {
                this.onShowRecipientLogic();
                return;
            }
            if (key == 'resources') {
                this.onShowConnectResource();
                return;
            }
            if (key == 'logic') {
                this.onShowConnectLogicDesigner();
                return;
            }
            if (key == 'modules') {
                this.onShowConnectModule();
                return;
            }
            if (key == 'attachments') {
                this.onShowConnectAttachment();
                return;
            }
            if (key == 'settings') {
                this.onShowConnectSystem();
                return;
            }

        },

        showPropBox(key) {

            // TODO remove eventually
/*             if (key == 'logic') {
                this.showLogicDesigner();
                return;
            } */
            if (key == 'attachments') {
                this.onShowAttachmentDialog();
                return;
            }
/*             if (key == 'flow') {
                this.flowDesignerVisible = true;
                return;
            } */
/*             if (key == 'resources') {
                this.showManageResources();
                return;
            } */
/*             if (key == 'modules') {
                this.showManageModules();
                return;
            } */
/*             if (key == 'language') {
                this.showLanguageDialog();
                return;
            } */

            this.showPropsInput = false;
            this.showPropsPage = false;
            this.customTabSelected = false;
            for (const i in this.menuModel) {
                this.menuModel[i] = false;
                if (i == key) this.menuModel[i] = true;
            }
            if (key == 'input') this.showPropsInput = true;
            if (key == 'page') this.showPropsPage = true;
        },

        onPdfSelectCard(card) {
            console.log('card', card)
            this.$refs.renderer.selectCard(card);
        },

        showLandingDesigner() {
            this.landingDesignerVisible = true;
        },

        showLogicDesigner(params) {
            this.logicDesignerParams = {
                mode: 'manage'
            };
            if (params) this.logicDesignerParams = params;
            this.logicDesignerVisible = true;
        },

        onShowConnectLogicDesigner() {
            const fromItem = formHelper.getItemFromKey(this.pages, this.logicFromKey);
            this.logicDesignerParams = {
                mode: 'connect',
                fromItem: fromItem
            };
            this.logicDesignerVisible = true;
            this.stopCanvas();
            this.hideTooltipX();
        },

        onShowLogicOutputConnector(params) {
            this.logicOutputParams = params;
            this.logicOutputVisible = true;
        },

        showLanguageDialog() {
            this.languageDialogVisible = true;
        },

        //#region Stepper Interface -------------------------------------------------------------------------------------------

        onStepperKeyChanged(event) {
            log.x('FRv2: Stepper key changed', event);
            this.stepKey = event.key;
            this.pageIndex = event.index;
        },

        onRoleChanged(role) {
            log.x('FRv2: Role changed from ' + this.previousRole + ' to ' + role);
            if (role != this.previousRole) {
                this.previousRole = role;
                this.$nextTick(() => {
                    this.processSteps();
                    this.selectValidStep();
                });
            }
            for (const i in this.roleOptions) {
                if (this.roleOptions[i].key == role) {
                    console.log('selected role', this.roleOptions[i]);
                    this.roleIndex = i;
                }
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Editor ------------------------------------------------------------------------------------------------------

        setDesignerMode(value) {
            formHelper.createModel(this, this.$root, this.pages);
            this.designer = value;
            if (value) this.setPhoneMode(false);
        },

        loadUserdata(userdata) {
            formHelper.createModel(this, this.$root, this.pages);
            viewHelper.setModelFromUserdata(this, this, this.pages, userdata);
            if (this.$refs.pdfeditor) this.$refs.pdfeditor.processUserdata(userdata);
        },

        setPhoneMode(value) {
            this.phoneMode = value;
        },

        showAddInputDialog(card) {
            this.addInputParams = { cardKey: card.key, isList: card.type == 'list' };
            this.addInputVisible = true;
        },

        showAddCardDialog() {
            this.addCardParams = { pageKey: this.stepKey };
            this.addCardVisible = true;
        },

        onAddInput(inputs) { editorHelper.onAddInput(this, inputs); },
        onAddCard(cards) { editorHelper.onAddCard(this, cards); },
        onAddPage() { editorHelper.onAddPage(this); },
        onAddRole(role) { editorHelper.onAddRole(this, role); },

        onMoveItem(item, direction) { editorHelper.onMoveItem(this, item, direction); },
        onMovePage(page, direction) { editorHelper.onMovePage(this, page, direction); },

        onDeleteItem(item) { editorHelper.onDeleteItem(this, item); },
        onDeletePage(toRemove) { editorHelper.onDeletePage(this, toRemove); },

        onDuplicateItem(item) { editorHelper.onCopyItem(this, item); },
        onCopyItem(item) { clipboardHelper.addToClipboard(this.$root, this, item); },
        onPasteItem(container) { clipboardHelper.pasteItem(this.$root, this, container); },

        onFormInput(card, input) {
            console.log('onFormInput', input);
        },

        onPropInput(type, key, value) {
            editorHelper.onPropInput(this, type, key, value);
            this.hasChanges = true;
            this.$emit('changed', this.hasChanges);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Selection / Canvas ------------------------------------------------------------------------------------------

        onSelectedCardChanged(card) {
            this.selectedLine = null;
            this.selectedCard = card;
            this.showPropBox('none');
            if (this.selectedCard || this.selectedInput) {
                this.showPropsInput = true;
            }else {
                if (this.customTab) {
                    this.customTabSelected = true;
                }else {
                    this.showPropsPage = true;
                }
            }
        },

        onSelectedInputChanged(input) {
            this.selectedLine = null;
            this.selectedInput = input;
            this.showPropBox('none');
            if (this.selectedCard || this.selectedInput) {
                this.showPropsInput = true;
            }else {
                if (this.customTab) {
                    this.customTabSelected = true;
                }else {
                    this.showPropsPage = true;
                }
            }
        },

        selectInput(input) { this.$refs.renderer.selectInput(input); },
        selectCard(card) { this.$refs.renderer.selectCard(card); },

        async reselectItem() {
            const selectedInput = this.selectedInput;
            const selectedCard = this.selectedCard;
            this.selectedInput = null;
            this.selectedCard = null;
            await new Promise(r => setTimeout(r, 10));
            if (selectedInput) this.$refs.renderer.selectInput(selectedInput);
            if (selectedCard) this.$refs.renderer.selectCard(selectedCard);
        },

        deselect() {
            this.selectedLine = null;
            if (this.$refs.renderer) this.$refs.renderer.deselect();
            this.stopCanvas();
            this.showPageProperties = false;
            this.showFormProperties = false;
            this.hideTooltipX();
        },

        onContextMenu(event) {
            // HERE IT IS
            if (this.designer) event.preventDefault();
            this.deselect();
        },

        onShowPageProperties(key) {
            this.stepKey = key;
            this.showPageProperties = true;
        },

        onShowFormProperties(key) {
            this.stepKey = key;
            this.showFormProperties = true;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Logic / Canvas ----------------------------------------------------------------------------------------------

        handleLogic(key, type, newSystem) {

            console.log('key', key);
            console.log('type', type);

            let fromItem = formHelper.getItemFromKey(this.pages, key);
            if (!fromItem) fromItem = formHelper.getItemFromKeyModules(this, key);

            this.logicParamsFinalV2 = {
                fromKey: key,
                fromItem: fromItem,
                roles: this.roleOptions,
                newSystem: newSystem || false,
            };

            this.logicFromKey = key;
            this.switchLogicPoint('l' + key);
            this.initCanvas();
        },

        switchLogicPoint(elementId) {
            const elem = document.getElementById(elementId);
            const left = elem.getBoundingClientRect().left + elem.ownerDocument.defaultView.pageXOffset;
            const top = elem.getBoundingClientRect().top + elem.ownerDocument.defaultView.pageYOffset;
            this.logicPoint = { x: left + 2, y: top + 12 };
        },

        onStepLogic(page) {
            this.showPageProperties = false;
            this.handleLogic(page.key);
            this.switchLogicPoint('pl-' + page.key);
        },

        onStepEnter(step) {
            if (!this.logicActive) return;
            this.logicHoverStep = step;
            const self = this;
            setTimeout(function() {
                self.onStepLogicHovered(step.key);
            }, 2000);
        },

        onStepLeave(step) {
            this.logicHoverStep = null;
        },

        onStepLogicHovered(stepKey) {
            if (!this.logicActive) return;
            if (this.logicHoverStep && this.logicHoverStep.key == stepKey) {
                this.switchLogicPoint('pl-' + this.logicHoverStep.key);
                this.stepKey = this.logicHoverStep.key;
                this.pageIndex = this.logicHoverStep.index;
                this.hideTooltipX();
            }
        },

        handleChecklistLogic(params) {
            console.log('chk logic', params);

            const fromItem = formHelper.getItemFromKey(this.pages, this.logicFromKey);
            console.log('fromItem', fromItem);

            const chklogic = {
                key: params.toKey,
                type: params.type,
                value: params.value
            };
            
            this.$set(fromItem, 'chkshow', chklogic);

        },

        initCanvas() {
            this.logicActive = true;
        },

        stopCanvas() {
            this.logicActive = false;
            let canvas = document.getElementById('canvas');
            if (canvas) {
                const context = canvas.getContext('2d');
                context.clearRect(0, 0, canvas.width, canvas.height);
            }
        },

        onMouseMove(x, y) {
            if (!this.logicActive) return;
            this.drawLineBetween(this.logicPoint, { x, y });
        },

        drawLineBetween(point1, point2) {
            const canvas = document.getElementById('frv2canvas');
            const body = document.body, html = document.documentElement;
            const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            canvas.height = height;
            canvas.width = window.innerWidth;
            const context = canvas.getContext('2d');
            context.strokeStyle = "#3190D5";
            if (this.logicPossible) context.strokeStyle = "#0DCC27";
            context.lineWidth = 3;
            context.beginPath();
            context.moveTo(point1.x + 10, point1.y);
            context.lineTo(point2.x + 0, point2.y);
            context.stroke();
        },

        onConnectLogic(key, newStuff) {
            log.x('FRv2: onConnectLogic from ' + this.logicFromKey + ' to ' + key);
            let toItem; let isResource = false;
            if (this.resources[key]) {
                isResource = true;
                toItem = this.resources[key];
            }else {
                toItem = formHelper.getItemFromKey(this.pages, key);
                console.log('toItem', toItem);
            }
            let fromItem = formHelper.getItemFromKey(this.pages, this.logicFromKey);
            if (!fromItem) fromItem = formHelper.getItemFromKeyModules(this, this.logicFromKey);

            // Use new system for some inputs
            if (fromItem.type == 'brreg' || toItem.type == 'coo') newStuff = true;

            console.log('this.logicParamsFinalV2', this.logicParamsFinalV2);
            if (this.logicParamsFinalV2 && this.logicParamsFinalV2.newSystem) {
                newStuff = true;
            }

            this.logicParams = {
                key: key,
                fromItem: fromItem,
                toItem: toItem,
                isResource: isResource
            };
            if (!newStuff) this.logicVisible = true;

            this.logicParams2 = {
                key: key,
                fromItem: fromItem,
                toItem: toItem,
                isResource: isResource
            };
            if (newStuff) this.logicVisible2 = true;

            const self = this;
            setTimeout(function() {
                self.stopCanvas();
            }, 10);
        },
        onConnectLogicX(key) {
            this.onConnectLogic(key, true);
        },

        applyLogic(type) {

            const key = this.logicParams.key;
            const fromItem = this.logicParams.fromItem;
            const toItem = this.logicParams.toItem;

            if (type == 'visible-t') {
                fromItem.logic = {
                    visible: {
                        key: key,
                        reverse: false
                    }
                };
            }else if (type == 'visible-f') {
                fromItem.logic = {
                    visible: {
                        key: key,
                        reverse: true
                    }
                };
            }

            console.log('LOGIC connected', fromItem);
            
            this.logicVisible = false;
        },

        onShowRecipientLogic() {
            this.recipientLogicParams.fromItem = formHelper.getItemFromKey(this.pages, this.logicFromKey);
            this.recipientLogicParams.roles = this.roleOptions;
            this.recipientLogicVisible = true;
            this.logicActive = false;
            this.hideTooltipX();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Modules -----------------------------------------------------------------------------------------------------

        onManageModule(type) {
            this[type + 'Module'].uiParams.type = 'manage';
            this[type + 'Module'].show = true;
            this.modulesDialogVisible = false;
        },

        onShowConnectModule() {
            this.modulesDialogParams.mode = 'connect';
            this.modulesDialogVisible = true;
            this.stopCanvas();
            this.hideTooltipX();
        },

        onConnectModule(type) {
            if (type == 'idc') {
                const item = formHelper.getItemFromKey(this.pages, this.logicFromKey);
                this.idcModule.uiParams.fromItem = item;
                this.idcModule.uiParams.type = 'connect';
                this.idcModule.show = true;
            }else if (type == 'sign') {
                const item = formHelper.getItemFromKey(this.pages, this.logicFromKey);
                this.signModule.uiParams.fromItem = item;
                this.signModule.uiParams.type = 'connect';
                this.signModule.show = true;
            }else if (type == 'chk') {
                const item = formHelper.getItemFromKey(this.pages, this.logicFromKey);
                this.checklistModule.uiParams.fromItem = item;
                this.checklistModule.uiParams.type = 'connect';
                this.checklistModule.show = true;
            }else if (type == 'client') {
                const item = formHelper.getItemFromKey(this.pages, this.logicFromKey);
                this.clientModule.uiParams.fromItem = item;
                this.clientModule.uiParams.type = 'xconnect';
                this.clientModule.show = true;
            }else if (type == 'kar') {
                const item = formHelper.getItemFromKey(this.pages, this.logicFromKey);
                this.karModule.uiParams.fromItem = item;
                this.karModule.uiParams.type = 'connect';
                this.karModule.show = true;
            }else if (type == 'kyc') {
                const item = formHelper.getItemFromKey(this.pages, this.logicFromKey);
                this.kycModule.uiParams.fromItem = item;
                this.kycModule.uiParams.type = 'xconnect';
                this.kycModule.show = true;
            }else if (type == 'app') {
                const item = formHelper.getItemFromKey(this.pages, this.logicFromKey);
                this.approveModule.uiParams.fromItem = item;
                this.approveModule.uiParams.type = 'xconnect';
                this.approveModule.show = true;
            }
            this.modulesDialogVisible = false;
        },

        onShowConnectAttachment() {
            const fromItem = formHelper.getItemFromKey(this.pages, this.logicFromKey);
            this.attachmentDialogParams.mode = 'connect';
            this.attachmentDialogParams.fromItem = fromItem;
            this.attachmentDialogVisible = true;
            this.stopCanvas();
            this.hideTooltipX();
        },

        onShowConnectSystem() {
            this.systemDialogParams.mode = 'connect';

            const item = formHelper.getItemFromKey(this.pages, this.logicFromKey);
            this.systemDialogParams.fromItem = item;

            this.systemDialogVisible = true;
            this.stopCanvas();
            this.hideTooltipX();
        },
        onShowAttachmentDialog() {
            this.attachmentDialogParams.mode = 'manage';

            this.attachmentDialogVisible = true;
        },

        onConnectToKYC(input) {
            this.kycModule.uiParams.type = 'connect';
            this.kycModule.uiParams.input = input;
            this.kycModule.show = true;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Resources ---------------------------------------------------------------------------------------------------

        showManageResources() {
            this.resourceDialogParams.mode = 'manage';
            this.resourceDialogVisible = true;
        },

        onShowConnectResource() {
            this.resourceDialogParams.mode = 'connect';
            this.resourceDialogVisible = true;
            this.stopCanvas();
            this.hideTooltipX();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Data processing / Changelog Undo-Redo -----------------------------------------------------------------------

        processData(data) {
            const processedData = formHelper.processData(data);
            log.x('FRv2: Form data processed', processedData);
            //this.steps = processedData.steps;
            this.modules = processedData.modules;
            for (const i in processedData.resources) {
                this.$set(this.resources, processedData.resources[i].key, processedData.resources[i]);
            }
            this.attachments = processedData.attachments;
            this.pages = processedData.pages;
            this.processSteps();
            this.counter = processedData.counter;
            for (const i in processedData.modules) {
                if (processedData.modules[i].type == 'kyc') this.kycModule.enabled = true;
            }
            const rolesConfig = formHelper.processRoles(data);
            this.roleOptions = rolesConfig.roles;

            this.canSave = processedData.canSave;
            this.autoSave = processedData.autoSave;
            this.customSender = processedData.customSender;
            this.landingParams = processedData.landingParams;
            this.title = processedData.title;
            this.name = processedData.name;

            this.locales = processedData.locales;
            this.logic = processedData.logic;
            this.flags = processedData.flags;
            this.branding = processedData.branding;

            this.roleModel = {};
            this.roles = {};
            for (const i in this.roleOptions) {
                this.roleModel[this.roleOptions[i].key] = true;
                this.$set(this.roles, this.roleOptions[i].key, { label: this.roleOptions[i].display });
            }

            // TODO remove when ready
            for (const i in this.pages) {
                for (const j in this.pages[i].cards) {
                    if (!this.pages[i].cards[j].roles) {
                        this.$set(this.pages[i].cards[j], 'roles', this.roleModel);
                        log.e('FRv2: Roles added to card');
                    }
                }
            }

            // TODO remove when ready
            for (const i in this.pages) {
                for (const j in this.pages[i].cards) {
                    if (this.pages[i].cards[j].type == 'chk-c' || this.pages[i].cards[j].type == 'chk-a') {
                        for (const k in this.pages[i].cards[j].inputs) {
                            if (!this.pages[i].cards[j].inputs[k].roles) {
                                this.$set(this.pages[i].cards[j].inputs[k], 'roles', this.roleModel);
                                log.e('FRv2: Roles added to checklist item');
                            }
                        }
                    }
                }
            }

            // Add visible/enabled
            for (const i in this.pages) {
                if (this.pages[i].visible == null) this.pages[i].visible = true;
                for (const j in this.pages[i].cards) {
                    const card = this.pages[i].cards[j];
                    if (card.visible == null) card.visible = true;
                    for (const k in this.pages[i].cards[j].inputs) {
                        const input = this.pages[i].cards[j].inputs[k];
                        if (input.visible == null) input.visible = true;
                        if (input.enabled == null) input.enabled = true;
                    }
                }
            }

            this.selectValidStep();
            this.isSaving = false;
        },

        processSteps() {
            this.steps = [];
            for (const i in this.pages) {
                if (this.pages[i].roles[this.role]) {
                    this.steps.push({
                        key: this.pages[i].key,
                        title: this.pages[i].title,
                        submitText: this.pages[i].submitText,
                        index: i,
                        validation: { done: false, valid: false },
                        showStepper: this.pages[i].showStepper,
                    });
                }
            }
        },

        selectValidStep(stepKey) {
            if (stepKey) {
                for (const i in this.steps) {
                    if (this.steps[i].key == stepKey) {
                        this.stepKey = this.steps[i].key;
                        this.pageIndex = this.steps[i].index;
                    }
                }
                return;
            }
            let validKey = '', validIndex = 0;
            for (const i in this.steps) {
                if (this.steps[i].key == this.stepKey) {
                    validKey = this.steps[i].key;
                    validIndex = this.steps[i].index;
                }
            }
            if (validKey) {
                this.stepKey = validKey;
                this.pageIndex = validIndex;
            }else {
                this.stepKey = this.steps[0].key;
                this.pageIndex = this.steps[0].index;
            }
        },

        async saveForm() {
            this.isSaving = true;
            const data = this.getCurrentFormData();
            console.log('saving modules', this.modules);
            this.$emit('save', data);
            this.hasChanges = false;
            this.$emit('changed', this.hasChanges);
        },

        getCurrentFormData() {
            const flowConfig = {
                autoSave: this.autoSave,
                canSave: this.canSave,
                customSender: this.customSender,
                landingParams: this.landingParams,
                roles: [],
                flags: this.flags,
                branding: this.branding,
            };
            for (const i in this.roleOptions) {
                const role = {
                    key: this.roleOptions[i].key,
                    type: this.roleOptions[i].type,
                    display: this.roleOptions[i].display,
                    next: this.roleOptions[i].next,
                    hasEmail: this.roleOptions[i].hasEmail,
                    email: this.roleOptions[i].email,
                    hasLanding: this.roleOptions[i].hasLanding,
                    landingParams: this.roleOptions[i].landingParams,
                    redirect: this.roleOptions[i].redirect
                };
                flowConfig.roles.push(role);
            }
            const data = {
                counter: this.counter,
                pages: this.pages,
                flowConfig: flowConfig,
                modules: this.modules,
                attachments: this.attachments,
                resources: [],
                title: this.title,
                name: this.name,
                locales: this.locales,
                logic: this.logic,
            };
            for (const i in this.resources) {
                if (this.resources[i].module == 'x') data.resources.push(this.resources[i]);
            }
            return data;
        },

        onUndo(index) {
            if (this.changeLogIndex == -1) {
                const currentData = this.getCurrentFormData();
                this.changeLogCurrent = {
                    display: 'redo',
                    changeType: 'redo',
                    data: JSON.stringify(currentData),
                };
            }
            let newIndex = index;
            if (index == -2) newIndex = this.changeLogIndex + 1;
            this.loadSnapshot(newIndex);
        },

        onRedo(index) {
            let newIndex = index;
            if (index == -2) newIndex = this.changeLogIndex - 1;
            this.loadSnapshot(newIndex);
            this.hasChanges = true;
        },

        loadSnapshot(index) {
            let snapshot;
            if (index == -1) {
                snapshot = this.changeLogCurrent;
            }else {
                snapshot = this.changeLog[index];
            }
            log.x('FRv2: Loading snapshot', snapshot);
            this.changeLogIndex = index;

            let reselectKey = '';
            if (this.selectedInput) reselectKey = this.selectedInput.key;

            this.$emit('history', JSON.parse(snapshot.data));

            // Reference to the selected item will no longer be valid, it needs to be referenced again before reselecting
            this.$nextTick(() => {
                const item = formHelper.getItemFromKey(this.pages, reselectKey);
                this.$refs.renderer.selectInput(item);
            });
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Help --------------------------------------------------------------------------------------------------------

        showHelpMenu(value) {
            this.$root.enableScrolling(!value);
            this.showHelp = value;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        showTextEditor(reference) {
            this.textEditorParams.value = reference;
            this.textEditorVisible = true;
        },

        onLocaleUpdated() {
            for (const i in this.$root.config.locales) {
                if (this.$root.config.locales[i].enabled) {
                    this.$set(this.textEditorParams.tabModel, this.$root.config.locales[i].key, this.$root.config.locales[i].key == this.$root.locale.key);
                    this.$set(this.textEditorParams.tabOptions, this.$root.config.locales[i].key, { label: this.$root.config.locales[i].name, image: this.$root.images.flags[this.$root.config.locales[i].key] });
                }
            }
        },


        //#region Help texts --------------------------------------------------------------------------------------------------

        onAddLineClicked(reference) {
            this.counter += 1;
            reference.push(formHelper.getNewHelpTextLine(this));
        },

        onLineClicked(reference) {
            /* this.textEditorParams.value = reference;
            this.textEditorVisible = true; */

            this.deselect();

            this.selectedLine = reference;

            this.showPropBox('none');
            this.showPropsInput = true;

        },

        showIconSelector(reference) {
            this.selectIconParams.reference = reference;
            this.selectIconVisible = true;
        },

        onDeleteLineClicked(line) {
            editorHelper.onChange(this, 'hline_del', false);
            for (const i in this.pages) {
                for (const j in this.pages[i].info) {
                    for (const k in this.pages[i].info[j]) {
                        if (this.pages[i].info[j][k].key == line.key) {
                            this.pages[i].info[j].splice(k, 1);
                            break;
                        }
                    }
                }
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        onCreated() {
            this.processData(this.formData);

            if (this.$root.credentials.isSuper) this.accessLevel = 3;
            if (this.$root.credentials.isAdmin) this.accessLevel = 9;

            // Modules onFormCreated
            for (const i in this.modules) {
                //console.log('module', this.modules[i]);
                moduleHelper.onFormCreated(this.$root, this, true, this.modules[i], this.pages);
            }
            moduleHelper.onFormCreatedSystem(this.$root, this, false, this.pages, this.isOwner);

            const result = testHelper.checkForProblems(this);
            if (!result.ok) this.problems.hasProblems = true;
        },

        updateClipboard() {
            clipboardHelper.updateClipboard(this.$root, this);
        },

    },
    async created() {
        this.reference = this;

        document.body.addEventListener('mousemove', e => {
            this.onMouseMove(e.pageX, e.pageY);
        });

        this.onLocaleUpdated();

        this.onCreated();
        clipboardHelper.updateClipboard(this.$root, this);

        window.addEventListener('focus', this.updateClipboard);
        //this.enableAlwaysScroll(true);
        this.enableScrolling(false);
    },
    mounted() {
        this.$parent.$parent.landing = true;
    },
    destroyed() {
        //this.enableAlwaysScroll(false);
        this.enableScrolling(true);
        window.removeEventListener('focus', this.updateClipboard);
        this.$parent.$parent.landing = false;
    },
}
</script>
<style scoped>

.frv2-designer {
    /* min-height: 80vh; */
}

#frv2canvas {
    position: absolute;
    top: 0px; left: 0px;
    width: 100%;
    opacity: 0.8;
    pointer-events: none;
    z-index: 12;
}

.logic {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
    supported by Chrome, Opera and Firefox */
}

</style>