<template>
<div class="vap-spinner" :class="[{'vap-spinner-small' : small}, {'vap-spinner-medium' : medium}, {'vap-spinner-free' : free}, {'vap-spinner-im' : iconMode}]">
    <div id="loading-bar-spinner"><div class="spinner-icon">
        
        </div>
    </div>
    <svg width="100%" height="100%" viewBox="0 0 30 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" v-if="!noIcon" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g transform="matrix(1,0,0,1,0.931373,-0.0653595)">
            <path d="M17.5,22L3,22L3,7L7,7L7,3L17,3L17,11.3L20,6.5L20,0L5.9,0L0.3,5L0,5L0,25L16.2,25L17.5,22Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,0.931373,-0.0653595)">
            <path d="M24.8,0L16.8,16.1L14.3,9L10.6,9L14.4,21L18.3,21L28.4,0L24.8,0Z" style="fill-rule:nonzero;"/>
        </g>
    </svg>
    <label>{{ text }}</label>
</div>
</template>

<script>

export default {
    name: 'vap-spinner',
    props: {
        text: {
            type: String,
            default: ''
        },
        small: {
            type: Boolean,
            default: false
        },
        medium: {
            type: Boolean,
            default: false
        },
        iconMode: {
            type: Boolean,
            default: false
        },
        free: {
            type: Boolean,
            default: false
        },
        noIcon: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    async created() {
        
    }
}
</script>

<style>

.vap-spinner {
    position: relative;
    margin-left: -60px;
}

.vap-spinner-small {
    position: absolute;
    top: -16px;
    right: -26px;
}
.vap-spinner-im {
    position: absolute;
    top: 0px;
    right: 0px;
}

.vap-spinner-free {
    position: absolute;
    top: 0px;
    left: 0px;
}

.vap-spinner img {
    position: absolute;
    width: 60px;
    top: 40px;
    left: 40px;
}
.vap-spinner-small img {
    display: none;
}

.vap-spinner svg {
    position: absolute;
    width: 70px;
    top: 3px;
    left: 38px;
}
.vap-spinner-medium svg {
    width: 50px;
    top: 3px;
    left: 24px;
}
.vap-spinner-im svg {
    width: 20px;
    top: 1px;
    left: 11px;
}
.vap-spinner-small svg {
    display: none;
}
.vap-spinner path {
    fill: #238ccc;
    fill: var(--primary);
}

.vap-spinner label {
    position: absolute;
    width: 500px;
    font-family: 'Signika', sans-serif;
    font-size: 20px;
    color: #238ccc;
    color: var(--primary);
    text-align: center;
    top: 158px;
    left: -182px;
}

#loading-bar-spinner {
  pointer-events: none;
  -webkit-pointer-events: none;
  -webkit-transition: 350ms linear all;
  -moz-transition: 350ms linear all;
  -o-transition: 350ms linear all;
  transition: 350ms linear all;
}

#loading-bar-spinner .spinner-icon {
  width: 120px;
  height: 120px;

  border: solid 8px transparent;
  border-top-color: #238ccc;
  border-top-color: var(--primary);
  border-left-color: #ccc;
  border-left-color: var(--card-background) !important;
  border-radius: 50%;

  -webkit-animation: loading-bar-spinner 400ms linear infinite;
  -moz-animation:    loading-bar-spinner 400ms linear infinite;
  -ms-animation:     loading-bar-spinner 400ms linear infinite;
  -o-animation:      loading-bar-spinner 400ms linear infinite;
  animation:         loading-bar-spinner 400ms linear infinite;
}

.vap-spinner-small .spinner-icon {
  width: 10px !important;
  height: 10px !important;
  border: solid 2px transparent !important;
  border-top-color: #238ccc !important;
  border-top-color: var(--primary) !important;
  border-left-color: #ccc !important;
  border-left-color: var(--card-background) !important;
}

.vap-spinner-im .spinner-icon {
  width: 34px !important;
  height: 34px !important;
  border: solid 3px transparent !important;
  border-top-color: #238ccc !important;
  border-top-color: var(--primary) !important;
  border-left-color: #ccc !important;
  border-left-color: var(--card-background) !important;
}

.vap-spinner-medium .spinner-icon {
  width: 80px !important;
  height: 80px !important;
  border: solid 6px transparent !important;
  border-top-color: #238ccc !important;
  border-top-color: var(--primary) !important;
  border-left-color: #ccc !important;
  border-left-color: var(--card-background) !important;
}

@-webkit-keyframes loading-bar-spinner {
  0%   { -webkit-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
@-moz-keyframes loading-bar-spinner {
  0%   { -moz-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}
@-o-keyframes loading-bar-spinner {
  0%   { -o-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}
@-ms-keyframes loading-bar-spinner {
  0%   { -ms-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}
@keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}

</style>
