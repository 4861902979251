<template>
<div>

    <PropertyItem padded v-if="input" :header="'Tooltip properties'" :icon="'help-o'" :initialCollapse="initialCollapse">

        <DesignerGroup c12>
            <TextEditorV2 designer v-model="input.text[$root.locale.key]" advanced :raw="input.raw" @before="$emit('propinput', 'text', 'ttip', $event)" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';

import TextEditorV2 from '../../TextEditorV2.vue';

export default {
    name: 'TooltipProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
        TextEditorV2,
    },
    props: {
        input: { type: Object, default: null },
        initialCollapse: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
}
</script>