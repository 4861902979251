<template>
<div class="vrepv2-item" :class="[
    { 'vrepv2-item-sep': item.separation }
]" :id="item.dataKey">

    <div class="vrepv2-header" v-if="item.title">
        {{ item.title[$root.locale.key] }}
    </div>

    <vap-form-divider :left="item.comment ? 55 : 100" :right="item.comment ? 45 : 0">
        <template v-slot:left>
            <vap-card noPadding innerShadow :innerShadowHighlight="item.highlight" :validated="item.valid">

                <slot></slot>

            </vap-card>
        </template>
        <template v-slot:right>

            <vap-card noPadding :innerShadow="!isPartner" :innerShadowHighlight="item.highlight" :validated="item.commentValid" v-if="item.comment">
                <div :style="isPartner ? 'background-color: var(--page-background);' : ''">
                    <div class="vrepv2-ass">
                        <div class="vrepv2-ass-title">
                            {{ __t('report-assessment') + ($root.config.freport.dualComments.enabled ? ' - ' + $root.config.responsible.clientText[$root.locale.key] : '') }}
                        </div>
                        <div v-if="!isPartner && infoText">
                            {{ infoText }}
                        </div>
                        <TextEditorV2 :content="rdata.comment" @input="onCommentInput" :disabled="disabled || isPartner" v-if="!rdata.check" />
                        <div class="vrepv2-nc" v-if="rdata.check">
                            {{ __t('report-no-comment') }}
                        </div>
                    </div>
                </div>
                <CardActions :actions="actions" @action="onAction" :disabled="disabled" v-if="!isPartner" />
            </vap-card>

            <vap-card noPadding :innerShadow="isPartner" v-if="item.comment && $root.config.freport.dualComments.enabled"
                :innerShadowHighlight="item.highlight" :validated="item.commentValid" style="margin-top: 10px;"
            >
                <div :style="isPartner ? '' : 'background-color: var(--page-background);'">
                    <div class="vrepv2-ass">
                        <div class="vrepv2-ass-title">
                            {{ __t('report-assessment') + ($root.config.freport.dualComments.enabled ? ' - ' + $root.config.partners.clientText[$root.locale.key] : '') }}
                        </div>
                        <div v-if="isPartner && infoText">
                            {{ infoText }}
                        </div>
                        <TextEditorV2 :content="rdata.comment2" @input="onCommentInput2" :disabled="disabled || !isPartner" v-if="!rdata.check2" />
                        <div class="vrepv2-nc" v-if="rdata.check2">
                            {{ __t('report-no-comment') }}
                        </div>
                    </div>
                </div>
                <CardActions :actions="actions2" @action="onAction2" :disabled="disabled" v-if="isPartner" />
            </vap-card>

        </template>
    </vap-form-divider>
    
</div>
</template>
<script>
import v1reportHelper from '../v1helpers/v1reportHelper';

import TextEditorV2 from '../components/form/TextEditorV2.vue';
import CardActions from '../v1ui/CardActions.vue';

export default {
    name: 'ReportItem',
    components: {
        TextEditorV2,
        CardActions
    },
    props: {
        item: { type: Object, default: null },
        rdata: { type: Object, default: null },
        riskModel: { type: [String, Number], default: 0 },
        disabled: { type: Boolean, default: false },
        isPartner: { type: Boolean, default: false },
    },
    data() {
        return {
            actions: [
                { action: 'check', icon: 'check', highlight: false }
            ],
            actions2: [
                { action: 'check', icon: 'check', highlight: false }
            ],
            infoText: '',
        }
    },
    watch: {
        riskModel: {
            deep: false,
            handler(val) {
                console.log('changed', val);
                this.infoText = this.getInfoText();
            }
        }
    },
    methods: {

        getInfoText() {
            console.log('item', this.item);
            console.log('rdata', this.rdata);

            if (this.item.key == 'assessment') {

                if (this.riskModel == 7 && this.item.infoHighRisk) {
                    return this.item.infoHighRisk[this.$root.locale.key];
                }

                
            }

            if (this.item.key == 'roles') {
                if (this.item.person && this.item.person.screening.isPep && this.item.infoPep) {
                    return this.item.infoPep[this.$root.locale.key];
                }
            }

            return '';
        },
        
        onCommentInput(input) {
            this.rdata.comment = input;
            this.setHighlight();
            this.$emit('change');
        },
        onCommentInput2(input) {
            this.rdata.comment2 = input;
            this.setHighlight();
            this.$emit('change');
        },

        onAction(action) {
            if (action.action == 'check') {
                this.rdata.check = !this.rdata.check;
            }
            this.setHighlight();
            this.$emit('change');
        },
        onAction2(action) {
            if (action.action == 'check') {
                this.rdata.check2 = !this.rdata.check2;
            }
            this.setHighlight2();
            this.$emit('change');
        },

        setHighlight() {
            for (const i in this.actions) {
                if (this.actions[i].action == 'check') {
                    this.actions[i].highlight = this.rdata.check || v1reportHelper.isValidComment(this.rdata.comment);
                }
            }
        },
        setHighlight2() {
            for (const i in this.actions2) {
                if (this.actions2[i].action == 'check') {
                    this.actions2[i].highlight = this.rdata.check2 || v1reportHelper.isValidComment(this.rdata.comment2);
                }
            }
        },

    },
    async created() {
        this.infoText = this.getInfoText();
        this.setHighlight();
    }
}
</script>
<style scoped>

.vrepv2-item {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.vrepv2-item-sep {
    margin-top: 50px;
}

.vrepv2-header {
    font-size: 18px;
    margin-bottom: 5px;
}


.vrepv2-ass {
    padding: 20px;
    padding-top: 10px;
}
.vrepv2-ass-title {
    margin-bottom: 10px;
}


/* .vrepv2-nc {

} */

</style>