<template>
<div class="vf-erm">
    
    {{ '*' + text }}

</div>
</template>

<script>
export default {
    name: 'vf-error-message',
    props: {
        text: { type: [String, Number], default: '' }
    },
    data() {
        return {
            
        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>
<style scoped>

.vf-erm {
    float: left;
    width: 100%;
    min-height: 20px;
    margin-bottom: -2px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.6px;
    color: var(--val-error);
}

</style>
