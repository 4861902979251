<template>
<div>
    <div class="aml-page">

        <vap-form-divider :left="$root.config.events.riskDisabled ? 0 : 35" :right="$root.config.events.riskDisabled ? 100 : 65" style="margin-top: 10px;">
            <template v-slot:left>

                <!-- <vap-label :text="__t('events-clients')" header2 style="margin-top: 10px; margin-bottom: 10px;" /> -->

                <vap-card :header="__t('events-risk-distribution')" v-if="!$root.config.events.riskDisabled">
                    <div class="own-pie" v-if="!loadingClients">
                        <div class="own-pie-left">
                            <PieLegend v-for="(option, i) in pieData" :key="'ero' + i"
                                :text="option.label"
                                :color="option.colorString"
                                :percentage="option.percentage"
                            />
                        </div>
                        <div class="own-pie-right">
                            <PieChart :data="pieData" :size="150" />
                        </div>
                    </div>
                    <div style="margin-top: 0px; padding-left: 0px; padding-right: 20px; padding-top: 7px; padding-bottom: 7px; display: flex; border-top: solid 1px lightgray; border-bottom: solid 1px lightgray;">
                        <div style="flex-grow: 100;">
                            {{__t('events-number-of-clients')}}
                        </div>
                        <div style="font-weight: bold;">
                            {{ clientCount }}
                        </div>
                    </div>
                </vap-card>

                <!-- <vap-label :text="__('filters')" header2 style="margin-top: 10px; margin-bottom: 10px;" /> -->

                <!-- <SearchBox ref="fltSear" @search="filtersOnSearch" :placeholder="__('clients_search')"
                    style="margin-top: 2px; margin-bottom: 16px;"
                /> -->

                <!-- <vap-label :text="__t('events-events')" header2 style="margin-top: 20px; margin-bottom: 10px;" />

                <RemoveFilterButton v-if="filtersHasActive()" @remove="filtersOnRemoveAll" />

                <div>
                    <FilterControl empty :header="__t('events-date')" />
                    <div style="float: left; width: 50%; margin-top: 10px;">
                        <SortDatePicker v-model="filterObject.sortDateFrom" noHighlight noValidation :hintKey="'datepicker_from'" :placeholder="__('datepicker_select_date')" outputISO @input="filtersOnDateChanged" />
                    </div>
                    <div style="float: left; width: 50%; margin-top: 10px;">
                        <SortDatePicker v-model="filterObject.sortDateTo" noHighlight noValidation :hintKey="'datepicker_to'" :placeholder="__('datepicker_select_date')" right outputISO @input="filtersOnDateChanged" />
                    </div>
                </div>

                <div v-if="filterObject.filters">
                    <div v-for="(filter, i) in filterObject.possibleFilters" :key="'cpf' + i">
                        <FilterControlV1
                            :params="filter"
                            :filters="filterObject.filters[filter.processedKey]"
                            :texts="filterObject.texts[filter.textKey]"
                            :activeValues="filterObject.activeFilters[filter.key]"
                            @filter="filtersOnChanged"
                        />
                    </div>
                </div> -->

            </template>
            <template v-slot:right>

                <!-- <div style="display: flex;">
                    <vap-label :text="__t('events-last-event')" header2 style="flex-grow: 100; margin-top: 10px; margin-bottom: 10px;" />
                    <SortSelector v-model="filterObject.sortModel" :options="filterObject.sortOptions" style="flex-shrink: 0; margin-top: 18px;" />
                </div> -->

                <div v-if="!loading">
                    <vap-card compactList noPadding v-for="(event, i) in events" :key="'hi' + i">
                        <ClientItem
                            :icon="getEventIcon(event.type)"
                            :name="getEventName(event)"
                            :info="getEventText(event)"
                            :date="formatDate('datetime', event.date)"
                            :user="getEmployeeName(event.userId)"
                            :link="getEventLink(event)"
                            :newItem="isNewItem(event)"
                            :newError="event.color == 'e'"
                            @ack="acknowledgeEvent(event)"
                            @clicked="acknowledgeEvent(event)"
                        />
                    </vap-card>
                </div>

                <CenteredSpinner v-if="loading" />
                <EmptyIcon v-if="!loading && clients.length == 0" :icon="'input-check'" :text="__t('events-no-events')" />

                <PageControl v-if="!loading"
                    :total="filterObject.totalPages"
                    :current="filterObject.currentPage"
                    @page="filtersOnNavigate"
                    style="margin-top: 20px;"
                />
                
            </template>
        </vap-form-divider>

    </div>
</div>
</template>
<script>
import log from '@/log';
import v1api from '../v1helpers/v1api';
import v1filterHelper from '../v1helpers/v1filterHelper';
import v1messages from '../v1helpers/v1messages';

import PageHeader from '../v1ui/PageHeader.vue';
import ClientItem from '../components/items/ClientItem.vue';

import EmptyIcon from '../v1ui/EmptyIcon.vue';
import PageControl from '../components/special/PageControl';
import CenteredSpinner from '../v1ui/CenteredSpinner.vue';
import FilterControlV1 from '../v1ui/FilterControlV1.vue';
import FilterControl from '../components/special/FilterControl';
import RemoveFilterButton from '../v1ui/RemoveFilterButton.vue';
import SortDatePicker from '../components/special/SortDatePicker';
import SortSelector from '../components/special/SortSelector';
import SearchBox from '../components/special/SearchBox';

import InfoItem from '@/components/items/InfoItem';
import PieChart from '@/components/stats/PieChart';
import PieLegend from '../v1ui/PieLegend.vue';

export default {
    name: 'EventsV1',
    components: {
        PageHeader,
        ClientItem,
        InfoItem,
        PieChart,
        PieLegend,
        EmptyIcon,
        PageControl,
        CenteredSpinner,
        FilterControlV1,
        FilterControl,
        RemoveFilterButton,
        SortDatePicker,
        SortSelector,
        SearchBox,
    },
    data() {
        return {
            loading: true,
            loadingClients: true,

            acknowledgedEvents: [],

            events: [],
            clients: [],

            clientCount: 0,
            pieData: null,

            riskOptions: {},

            filterObject: {
                itemsPerPage: 8,
            },
        }
    },
    methods: {

        //#region Filter Interface --------------------------------------------------------------------------------------------

        filtersOnNavigate(page) { v1filterHelper.filtersOnNavigate(this, this.filterObject, page); },

        filtersOnLetterSelected(letter) { v1filterHelper.filtersOnLetterSelected(this, this.filterObject, letter); },

        filtersOnSearch(str) { v1filterHelper.filtersOnSearch(this, this.filterObject, str); },

        filtersOnDateChanged() { v1filterHelper.filtersOnDateChanged(this, this.filterObject); },

        filtersOnChanged(filter, value) { v1filterHelper.filtersOnChanged(this, this.filterObject, filter, value); },

        filtersHasActive() { return v1filterHelper.filtersHasActive(this.filterObject); },

        filtersOnRemoveAll() { v1filterHelper.filtersOnRemoveAll(this, this.filterObject); },

        filtersOnQuery() {
            this.queryEvents();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        getEventText(event) {
            switch (event.type) {
                case 'client-created': return this.__t('events-client-created');
                case 'kyc-completed': return this.__t('events-kyc-completed');
                case 'screening-performed': return this.__t('events-screening-performed');
                case 'idc-completed': return this.__t('events-idc-completed') + ' ' + event.personName;
                case 'form-completed': return this.__t('events-form-completed');
                case 'client-report': return this.__t('events-client-report');
                case 'monitor': return this.__t('monitor-event-hit');
                case 'monitor-active': return this.__t('monitor-event-hit');
                case 'monitor-inactive': return this.__t('monitor-event-hit');
            }
            return '[' + event.type + ']';
        },

        getEventIcon(type) {
            switch (type) {
                case 'client-created': return 'add';
                case 'kyc-completed': return 'file-check';
                case 'screening-performed': return 'search';
                case 'idc-completed': return 'card';
                case 'form-completed': return 'file-check';
                case 'client-report': return 'verified';
                case 'monitor': return 'cloud-search';
                case 'monitor-active': return 'cloud-search';
                case 'monitor-inactive': return 'cloud-search';
            }
        },

        getEventName(event) {
            if (event.clientName) return this.companyTitleCase(event.clientName);
            if (event.personName) return this.companyTitleCase(event.personName);
            return 'Unknown customer';
        },

        getEventLink(event) {
            if (event.clientId) return '/v1client?id=' + event.clientId;
            if (event.personId) return '/v1person?id=' + event.personId;
            return 'Unknown customer';
        },

        async queryEvents() {
            this.loading = true;

            const fromDate = this.addDays(new Date(), Number(0 - Number(this.$root.config.events.maxEventDays)));
            const toDate = this.addDays(new Date(), 1);

            const query = v1filterHelper.getQuery(this.$root, this.filterObject);
            query.sort = { date: -1 };
            query.filters = [
                { field: 'prio', op: 'x', values: ['med', 'high'] },
                { field: 'date', op: 'daterange', from: fromDate, to: toDate },
            ];

            const response = await v1api.queryEvents(query.filters, query.from, query.limit, query.sort, query.include, query.counts);
            this.events = response.events;

            v1filterHelper.updateFilters(this.$root, this.filterObject, response);

            this.loading = false;
        },

        addDays(date, days) {
            let result = new Date(date);
            result.setDate(result.getDate() + days);
            return result;
        },

        async queryClients() {
            this.loadingClients = true;

            const sort = { dateUpdated: -1 };
            const filters = [];
            const from = 0;
            const limit = 5;
            const include = [];
            const counts = ['risk', 'userId'];

            const response = await v1api.queryClients(filters, from, limit, sort, include, counts);
            this.clients = response.clients;
            this.clientCount = response.count;

            const fac = 100 / this.clientCount;

            this.pieData = [];
            for (const key in this.riskOptions) {
                
                const count = this.getRiskCount(response, key);
                let percentage = Math.round(count * fac);
                if (Number.isNaN(percentage)) percentage = 0;
                let color = this.riskOptions[key].color;
                if (this.riskOptions[key].color.includes('var(--')) {
                    const str = this.riskOptions[key].color.replace('var(--', '');
                    color = this.$root.getColor(str.substring(0, str.length - 1), false);
                }

                this.pieData.push({
                    label: this.riskOptions[key].locale[this.$root.locale.key],
                    colorString: this.riskOptions[key].color,
                    color: color,
                    value: count,
                    percentage: percentage
                });
            }

            this.loadingClients = false;
        },

        getRiskCount(response, key) {
            for (const i in response.filters.risk) {
                if (response.filters.risk[i]._id == key) return response.filters.risk[i].count;
            }
            return 0;
        },

        loadAcknowledgedEvents() {
            this.acknowledgedEvents = v1messages.loadAcknowledgedEvents(this.$root);
        },

        acknowledgeEvent(event) {
            this.acknowledgedEvents.push({
                id: event._id,
                date: new Date(),
            });
            localStorage.setItem('ackEvents', JSON.stringify(this.acknowledgedEvents));
            v1messages.setNewEventCount(this.$root);
        },

        isNewItem(event) {
            if (!this.$root.config.events.highlight) return;

            let diffTime = Math.abs(new Date() - new Date(event.date));
            const eventAge = Math.ceil(diffTime / (1000 * 60));
            //console.log('event is ' + eventAge + ' minutes old');

            for (const i in this.acknowledgedEvents) {
                if (event._id == this.acknowledgedEvents[i].id) {
                    return false;
                }
            }
            return true;
        },

    },
    async created() {
        this.$parent.setTitle(this.__('dashboard'));
        this.loadAcknowledgedEvents();

        v1filterHelper.loadEmptyFilters(this.$root, this.filterObject, 'type', 'date_desc');
        v1filterHelper.addFilter(this.$root, this.filterObject, 'prio', 'events-priority');

        // Create risk options
        for (const i in this.$root.config.risk.values) {
            for (const j in this.$root.config.risk.texts) {
                if (this.$root.config.risk.texts[j].value == this.$root.config.risk.values[i]) {
                    const option = { locale: {}, color: 'accent' };
                    for (const key in this.$root.config.risk.texts[j]) {
                        if (key != 'value' && key != 'color') option.locale[key] = this.$root.config.risk.texts[j][key];
                        if (key == 'color') {
                            if (this.$root.config.risk.texts[j].color.includes('#')) {
                                option.color = this.$root.config.risk.texts[j].color;
                            }else {
                                option.color = 'var(--' + this.$root.config.risk.texts[j].color + ')';
                            }
                        }
                    }
                    this.$set(this.riskOptions, this.$root.config.risk.texts[j].value, option);
                }
            }
        }

        this.queryClients();
        this.queryEvents();
    }
}
</script>
<style scoped>

.own-pie {
    display: flex;
    margin-top: -10px;
}
.own-pie-left {
    flex-grow: 100;
}
.own-pie-right {
    flex-shrink: 0;
}

</style>