import axios from 'axios';
import v2lib from './v2lib';
import tools from './tools';
import dataModel from './dataModel';
import dataHelper from './dataHelper';
const dataSweden = {

    useTestResponse: true,

    async getTrapetsData(orgno) {
        const result = {
            success: false,
            testdata: false,
            data: {}
        };

        if (this.useTestResponse) {
            try {
                const response = await axios.get(`test_data/trapets/${orgno}.json`);
                if (response.status == 200) {
                    await new Promise(r => setTimeout(r, 500));
                    result.data = response.data;
                    result.testdata = true;
                    result.success = true;
                    return result;
                }
            }catch (e) {

            }
        }

        const headers = {};
        headers["authorization"] = "JWT " + v2lib.personalToken;
        headers["x-namespace"] = v2lib.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        const companyId = v2lib.namespace.split('/')[2];
        try {
            const response = await axios({
                url: `/api/trapets/companies/${companyId}/query`,
                method: 'POST',
                headers: headers,
                data: {
                    "SubQueries": [
                        {
                            "Id": orgno,
                            "Countries": ["SWE"],
                            "ItemNumber": orgno
                        }
                    ]
                }
            });
            console.log(JSON.stringify(response.data))
            result.data = response.data;
            result.success = true;
        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;
    },

    async getBisnodeData(orgno) {
        const result = {
            success: false,
            testdata: false,
            data: {}
        };

        if (this.useTestResponse) {
            try {
                const response = await axios.get(`test_data/bisnode/${orgno}.json`);
                if (response.status == 200) {
                    await new Promise(r => setTimeout(r, 500));
                    result.data = response.data;
                    result.testdata = true;
                    result.success = true;
                    return result;
                }
            }catch (e) {

            }
        }

        const headers = {};
        headers["authorization"] = "JWT " + v2lib.personalToken;
        headers["x-namespace"] = v2lib.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            const response = await axios({
                url: `/api/bisnode/company?country=se&regNumber=${orgno}&rawRes=1`,
                method: 'GET',
                headers: headers
            });
            console.log(JSON.stringify(response.data))
            result.data = response.data;
            result.success = true;
        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;
    },

    processData(output, trapets, bisnode) {
        console.log('process trapets', trapets);
        console.log('process bisnode', bisnode);

        output.source = 'trapets-bisnode';
        output.version = 1;

        for (const i in trapets.ResponseItems[0].Entities) {
            const entity = trapets.ResponseItems[0].Entities[i];
            if (entity.SourceName == 'BVBeneficialOwners') {
                // Trapets - Beneficial owners

                const xml = this.parseXML(entity.Xml);

                for (const i in xml.owners) {
                    const dateOfBirth = xml.owners[i].ssn.substring(0, 4) + '-' + xml.owners[i].ssn.substring(4, 6) + '-' + xml.owners[i].ssn.substring(6, 8);
                    const beneficial = dataModel.getEmptyBeneficial();
                    beneficial.firstName = xml.owners[i].firstName;
                    beneficial.lastName = xml.owners[i].lastName;
                    beneficial.dateOfBirth = dateOfBirth;
                    beneficial.shareType = xml.owners[i].code;
                    beneficial.shareRange = xml.owners[i].share;
                    beneficial.country = xml.owners[i].country;
                    beneficial.citizenship = xml.owners[i].citizenship;
                    beneficial.ssn = xml.owners[i].ssn;
                    beneficial.identified = true;
                    output.beneficials.push(beneficial);
                }

                output.ownership.beneficialsAvailable = xml.dataAvailable == 'Available' ? 'Y' : 'N';
                output.ownership.beneficialsSuspicious = xml.suspicious == true ? 'Y' : 'N';

                console.log('xml', xml);

            }else if (entity.SourceName == 'OwnAndRep') {
                // Trapets - Owners and representatives
                console.log('OwnAndRep', entity);

                output.name = entity.Name;
                output.orgnr = entity.CorporateId;

                for (const i in entity.Owners) {
                    const shareholder = dataModel.getEmptyShareholder();
                    shareholder.name = entity.Owners[i].Name;
                    shareholder.share = 100 * Number(entity.Owners[i].OwnershipStake);
                    if (entity.Owners[i].OwnerType == 'Individual' && entity.Owners[i].ItemNumber) {
                        shareholder.type = 'person';
                        const names = tools.getNames(entity.Owners[i].Name);
                        shareholder.firstName = names.firstName;
                        shareholder.lastName = names.lastName;
                        shareholder.dateOfBirth = entity.Owners[i].ItemDate;
                    }else if (entity.Owners[i].OwnerType == 'Entity' && entity.Owners[i].ItemNumber) {
                        shareholder.type = 'org';
                        shareholder.orgnr = entity.Owners[i].ItemNumber;
                    }else {
                        shareholder.orgnr = entity.Owners[i].ItemNumber;
                    }
                    output.shareholders.push(shareholder);
                }

                for (const i in entity.Representatives) {
                    if (entity.Representatives[i].RepresentativeType == 'Entity') {

                        if (entity.Representatives[i].EngagementType == 'Accountant') {
                            output.economy.accountant.name = entity.Representatives[i].Name;
                            output.economy.accountant.orgnr = entity.Representatives[i].ItemNumber;
                        }

                    }else {
                        const role = dataModel.getEmptyRole();
                        role.roleId = this.getRoleIdTrapets(entity.Representatives[i].EngagementType);
                        const names = tools.getNames(entity.Representatives[i].Name);
                        role.firstName = names.firstName;
                        role.lastName = names.lastName;
                        role.dateOfBirth = entity.Representatives[i].ItemDate;
                        role.ssn = entity.Representatives[i].ItemNumber;
                        role.country = entity.Representatives[i].Country == 'SWE' ? 'SE' : entity.Representatives[i].Country;
                        role.address = entity.Representatives[i].Street;
                        const zip = this.processTrapetsZip(entity.Representatives[i].ZipCode);
                        role.zip = zip.zip;
                        role.city = zip.city;
                        output.roles.push(role);
                    }
                }

            }
        }

        for (let i=1; i<10; i++) {
            if (bisnode.generalCompanyDataBase['lineOfBusinessDesc' + i] && bisnode.generalCompanyDataBase['lineOflineOfBusinessC' + i]) {
                let cc = bisnode.generalCompanyDataBase['lineOflineOfBusinessC' + i];
                while (cc.length < 4) cc = '0' + cc;
                const code = {
                    code: cc,
                    description: bisnode.generalCompanyDataBase['lineOfBusinessDesc' + i]
                };
                output.naceCodes.push(code);
            }
        }

        output.info.country = 'SE';
        output.info.number = output.orgnr;
        output.info.dunsNumber = bisnode.generalCompanyDataBase.dunsNumber;
        output.info.dateRegistered = this.parseBisnodeRegDate(bisnode.generalCompanyDataBase.regDate);
        output.form.formCode = bisnode.generalCompanyDataBase.legalFormCode;
        output.form.formDescription = bisnode.generalCompanyDataBase.legalFormDesc;
        output.registration.inVatRegistry = bisnode.generalCompanyDataBase.registeredInTheVatList;
        output.registration.employer = bisnode.generalCompanyDataBase.registeredAsEmployer;
        output.registration.taxAssessment = bisnode.generalCompanyDataBase.noticeOfTaxAssessmentDesc == 'F-skattsedel finns' ? 'Y' : 'N';

        output.address.business.country = 'SE';
        output.address.business.street = bisnode.generalCompanyDataBase.postalAddress;
        output.address.business.zip = bisnode.generalCompanyDataBase.postalCode;
        output.address.business.city = bisnode.generalCompanyDataBase.town;
        output.address.business.commune = bisnode.generalCompanyDataBase.placeOfRegMunicipalityDesc;
        output.address.business.communeNumber = bisnode.generalCompanyDataBase.placeOfRegMunicipalityCode;

        output.contact.phone = bisnode.generalCompanyDataBase.telNumberInclAreaCode;

        // Accounting years
        if (bisnode.financialStatementSweden) {
            const sweden = bisnode.financialStatementSweden;

            // This object will be an array if there are more than one year, but an object if just one year
            if (Object.prototype.toString.call(sweden) === '[object Array]') {
                
                /* const years = JSON.parse(JSON.stringify(baml.financials.accounting.years));
                years.sort((b, a) => (a.year > b.year) ? 1 : -1); */
                if (sweden.length > 0) output.economy.yearAvailable = true;
                if (sweden.length > 2) output.economy.trendAvailable = true;

                // TODO remove this
                /* output.economy.yearAvailable = false;
                output.economy.trendAvailable = false; */

                let count = 0;
                for (const i in sweden) {
                    const key = 'year' + count;
                    output.economy[key] = dataModel.getEmptyYear();
                    output.economy[key].available = true;
                    output.economy[key].year = sweden[i].accountPeriod.substring(0, 4);
                    if (sweden[i].accountPeriod == '201912') {
                        for (const key in sweden[i]) {
                            console.log(this.getMoneyValue(sweden[i][key].$value) + ' - ' + key);
                        }
                    }
                    output.economy[key].salesRevenues = this.fillEconomyValue(sweden[i].netSales);
                    output.economy[key].otherRevenues = this.fillEconomyValue(sweden[i].otherFinancialIncome);

                    output.economy[key].totalRevenues = this.fillEconomyValue(sweden[i].totalRenewal);
                    output.economy[key].costOfGoodsSold = this.fillEconomyValue(null);
                    output.economy[key].inventoryChanges = this.fillEconomyValue(null);
                    output.economy[key].salaryCosts = this.fillEconomyValue(null);
                    output.economy[key].financialCosts = this.fillEconomyValue(null);
                    output.economy[key].depreciations = this.fillEconomyValue(null);
                    output.economy[key].amortizations = this.fillEconomyValue(null);
                    output.economy[key].otherOperatingCosts = this.fillEconomyValue(null);
                    output.economy[key].operatingProfits = this.fillEconomyValue(sweden[i].operatingProfitLoss);
                    output.economy[key].ordinaryProfits = this.fillEconomyValue(null);
                    output.economy[key].extraordinaryRevenues = this.fillEconomyValue(null);
                    output.economy[key].extraordinaryCosts = this.fillEconomyValue(null);
                    output.economy[key].minorityInterests = this.fillEconomyValue(null);
                    output.economy[key].taxes = this.fillEconomyValue(sweden[i].tax);
                    output.economy[key].resultBeforeTax = this.fillEconomyValue(sweden[i].profitLossAfter);
                    output.economy[key].netProfits = this.fillEconomyValue(sweden[i].profitLossAfter);
                    
                    output.economy[key].currentAssets = this.fillEconomyValue(null);
                    output.economy[key].fixedAssets = this.fillEconomyValue(null);
                    output.economy[key].sumAssets = this.fillEconomyValue(sweden[i].netWorth);
                    output.economy[key].equity = this.fillEconomyValue(null);
                    output.economy[key].debt = this.fillEconomyValue(null);
                    output.economy[key].sumEquityAndDebt = this.fillEconomyValue(sweden[i].totalEquityAndLiability2);

                    count += 1;
                }

            }else {

                output.economy.yearAvailable = true;
                output.economy.trendAvailable = false;

                const key = 'year0';
                output.economy[key] = dataModel.getEmptyYear();
                output.economy[key].available = true;
                output.economy[key].year = sweden.accountPeriod.substring(0, 4);

                output.economy[key].salesRevenues = this.fillEconomyValue(sweden.netSales);
                output.economy[key].otherRevenues = this.fillEconomyValue(sweden.otherFinancialIncome);

                output.economy[key].totalRevenues = this.fillEconomyValue(sweden.totalRenewal);
                output.economy[key].costOfGoodsSold = this.fillEconomyValue(null);
                output.economy[key].inventoryChanges = this.fillEconomyValue(null);
                output.economy[key].salaryCosts = this.fillEconomyValue(null);
                output.economy[key].financialCosts = this.fillEconomyValue(null);
                output.economy[key].depreciations = this.fillEconomyValue(null);
                output.economy[key].amortizations = this.fillEconomyValue(null);
                output.economy[key].otherOperatingCosts = this.fillEconomyValue(null);
                output.economy[key].operatingProfits = this.fillEconomyValue(sweden.operatingProfitLoss);
                output.economy[key].ordinaryProfits = this.fillEconomyValue(null);
                output.economy[key].extraordinaryRevenues = this.fillEconomyValue(null);
                output.economy[key].extraordinaryCosts = this.fillEconomyValue(null);
                output.economy[key].minorityInterests = this.fillEconomyValue(null);
                output.economy[key].taxes = this.fillEconomyValue(sweden.tax);
                output.economy[key].resultBeforeTax = this.fillEconomyValue(sweden.profitLossAfter);
                output.economy[key].netProfits = this.fillEconomyValue(sweden.profitLossAfter);
                
                output.economy[key].currentAssets = this.fillEconomyValue(null);
                output.economy[key].fixedAssets = this.fillEconomyValue(null);
                output.economy[key].sumAssets = this.fillEconomyValue(sweden.netWorth);
                output.economy[key].equity = this.fillEconomyValue(null);
                output.economy[key].debt = this.fillEconomyValue(null);
                output.economy[key].sumEquityAndDebt = this.fillEconomyValue(sweden.totalEquityAndLiability2);

            }

            
        }

        output.economy.basicAvailable = true;

        // Subsidiaries
        if (bisnode.subsidiaries) {
            for (const i in bisnode.subsidiaries) {
                const sub = dataModel.getEmptySubsidiary();
                sub.name = bisnode.subsidiaries[i].subsidName;
                sub.orgnr = bisnode.subsidiaries[i].subsidCountryRegNum;
                sub.dunsNumber = bisnode.subsidiaries[i].subsidDuns;
                sub.country = bisnode.subsidiaries[i].subsidCountryCode;
                output.subsidiaries.push(sub);
            }
            output.subsidiariesAvailable = true;
        }

        // companiesBusinessDesc
        if (bisnode.companiesBusinessDesc) {
            let str = '';
            for (const i in bisnode.companiesBusinessDesc) {
                str += bisnode.companiesBusinessDesc[i].businessDesc;
            }
            output.info.purpose = str;
        }

        // signature1
        if (bisnode.signature1) {
            for (const i in bisnode.signature1) {
                output.signature.description.push(bisnode.signature1[i].signature);
            }
        }
        output.signature.available = true;

        dataHelper.infoMatchPersons(output);
        dataHelper.sortLists(output);
        console.log('output', output);
    },

    getMoneyValue(value) {
        return this.formatMoney(value);
    },

    fillEconomyValue(input) {
        const value = dataModel.getEmptyEconValue();
        if (!input) return value;
        value.value = Number(input.$value) * 1000;
        value.shortDisplay = this.formatMoneyShort(value.value);
        value.fullDisplay = this.formatMoney(value.value);
        value.available = true;
        return value;
    },

    formatMoney(amount) {
        const value = parseFloat(amount).toFixed(0);
        let str = '';
        if (value.toString().length > 9) {
            let index1 = value.toString().length - 9;
            let index2 = value.toString().length - 6;
            let index3 = value.toString().length - 3;
            str = value.toString().substring(0, index1) + ' ' + value.toString().substring(index1, index2) + ' ' + value.toString().substring(index2, index3) + ' ' + value.toString().substring(index3);
        }else if (value.toString().length > 6) {
            let index1 = value.toString().length - 6;
            let index2 = value.toString().length - 3;
            str = value.toString().substring(0, index1) + ' ' + value.toString().substring(index1, index2) + ' ' + value.toString().substring(index2);
        }else if (value.toString().length > 3) {
            let index = value.toString().length - 3;
            str = value.toString().substring(0, index) + ' ' + value.toString().substring(index);
        }else {
            str = value.toString();
        }
        return str;
    },

    formatMoneyShort(amount) {
        const value = parseFloat(amount).toFixed(0);
        let str = '';
        if (value.toString().length > 9) {
            let index1 = value.toString().length - 9;
            let index2 = value.toString().length - 6;
            let index3 = value.toString().length - 3;
            str = value.toString().substring(0, index1) + ' ' + value.toString().substring(index1, index2) + ' ' + value.toString().substring(index2, index3);
        }else if (value.toString().length > 6) {
            let index1 = value.toString().length - 6;
            let index2 = value.toString().length - 3;
            str = value.toString().substring(0, index1) + ' ' + value.toString().substring(index1, index2);
        }else if (value.toString().length > 3) {
            let index = value.toString().length - 3;
            str = value.toString().substring(0, index);
        }else {
            str = amount == 0 ? '0' : '1';
        }
        return str;
    },

    parseBisnodeRegDate(input) {
        try {
            return input.substring(0,4) + '-' + input.substring(4,6) + '-' + input.substring(6,8);
        }catch (e) {
            return input;
        }
    },

    getRoleIdTrapets(input) {
        switch (input) {
            case 'CEO': return 'ceo';
            case 'Chairman': return 'chair';
            case 'BoardMember': return 'screening_role_board_member';
            case 'DeputyBoardMember': return 'screening_role_deputy_board_member';
            case 'Accountant': return 'screening_role_accountant';
        }
        return 'unknown';
    },

    processTrapetsZip(zip) {
        const array = zip.split('');
        let index = 0;
        const output = {
            zip: '',
            city: ''
        }
        for (const i in array) {
            if ('1234567890'.includes(array[i])) {
                output.zip += array[i];
            }else if (array[i] == ' ') {
                index = Number(i) + 1;
            }else {
                break;
            }
        }
        output.city = zip.substring(index);
        return output;
    },

    parseXML(text) {
        const output = {
            owners: []
        };
        const xml = this.getXMLDocument(text);
        const company = xml.getElementsByTagName("OriginalData")[0].childNodes[1];
        console.log('xml', company);
        for (const i in company.childNodes) {
            const tagName0 = company.childNodes[i].tagName;
            const value0 = company.childNodes[i].textContent;
            if (tagName0 == 'OrgNo') {
                output.orgnr = value0;
            }else if (tagName0 == 'CountryCode') {
                output.country = value0;
            }else if (tagName0 == 'CompanyName') {
                output.name = value0;
            }else if (tagName0 == 'LegalForm') {
                output.legalForm = value0;
            }else if (tagName0 == 'SuspiciousData') {
                output.suspicious = value0;
            }else if (tagName0 == 'BeneficialOwnerAvailability') {
                output.dataAvailable = value0;
            }
        }
        const owners = company.getElementsByTagName("BeneficialOwners")[0].childNodes;
        for (const i in owners) {
            if (owners[i].childNodes) {
                const owner = {};
                for (const j in owners[i].childNodes) {
                    const tagName = owners[i].childNodes[j].tagName;
                    const value = owners[i].childNodes[j].textContent;
                    if (tagName == 'PersonalNumber') {
                        owner.ssn = value;
                    }else if (tagName == 'FirstName') {
                        owner.firstName = value;
                    }else if (tagName == 'LastName') {
                        owner.lastName = value;
                    }else if (tagName == 'FullName') {
                        owner.fullName = value;
                    }else if (tagName == 'CountryOfResidence') {
                        owner.country = value;
                    }else if (tagName == 'CitizenShip') {
                        owner.citizenship = value;
                    }else if (tagName == 'ControlList') {
                        const control = owners[i].childNodes[j].childNodes[0];
                        for (const k in control.childNodes) {
                            const tagName2 = control.childNodes[k].tagName;
                            const value2 = control.childNodes[k].textContent;
                            if (tagName2 == 'ControlCode') {
                                owner.code = value2;
                            }else if (tagName2 == 'ControlInterval') {
                                owner.share = value2;
                            }
                        }
                    }
                }
                output.owners.push(owner);
            }
        }
        return output;
    },

    getXMLDocument(text) {
        let xmlDoc;
        if (window.DOMParser) {
            const parser = new DOMParser();
            xmlDoc = parser.parseFromString(text, "text/xml");
        }else {
            // Internet Explorer
            xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
            xmlDoc.async = false;
            xmlDoc.loadXML(text);
        }
        return xmlDoc;
    },

};
export default dataSweden;