<template>
<div>

    <FormInfoCard v-if="page.hasInfo && !phoneMode && toggle.info"
        :data="page.info[roleKey]"
        :designer="designer"
        :pageId="page.key"
        :selectedLine="selectedLine"
        @line-new="onAddLineClicked"
        @line-edit="onLineClicked"
        @line-icon="onLineIconClicked"
        @line-delete="onDeleteLineClicked"
    />
    <FormInfoCardPhone v-if="page.hasInfo && phoneMode"
        :data="page.info[roleKey]"
        :designer="designer"
        :pageId="page.key"
    />
    
    <div v-for="card in page.cards" :key="card.key">

        <vap-card v-if="card.type == 'card'"
            v-show="designer || isCardVisible(card)"
            list
            :id="card.key"
            :header="card.header[$root.locale.key]"
            :noPadding="card.noPadding"
            :noShadow="card.noShadow"
            :designer="designer"
            :dotted="designer && !isCardVisible(card)"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
            @logic="handleLogic"
        >
            <template v-slot:actions>
                <div class="frv2-card-link" v-if="card.ppop">
                    <span @click="onShowPersonList(card)">{{ __t('person-select-list') }}</span>
                </div>
            </template>

            <VFRow>
                <VFGroup v-for="input in card.inputs" :key="input.key"
                    v-show="(designer || isInputVisible(input)) && !(input.pdfOnly && !toggle.pdf)"
                    :id="input.key"
                    :width="input.width"
                    :noPadding="input.fillCard"
                    :validation="input.val"
                    :designer="designer"
                    :phoneMode="phoneMode"
                    :selected="isInputSelected(input)"
                    @selected="selectInput(input)"
                    @logic="handleLogic"
                >

                    <div v-if="input.type == 'htmlraw'" v-html="input.model ? input.model : input.desc[$root.locale.key]"></div>

                    <!-- Help text -->
                    <VFHelpText :style="{'margin-top': (input.marginTop || 0) + 'px', 'margin-bottom': (input.marginBottom || 0) + 'px'}" v-if="input.type == 'helpt'"
                        :content="input.desc[$root.locale.key]"
                    />

                    <!-- Formatted text -->
                    <VFTextViewer v-if="input.type == 'ftext'"
                        :content="input.desc[$root.locale.key]"
                    />

                    <!-- Description label -->
                    <VFLabel v-if="input.type != 'htmlraw' && input.type != 'helpt' && input.type != 'buttoni' && input.type != 'ftext' && input.type != 'error' && input.type != 'upload' && input.type != 'ttip' && input.desc && input.desc[$root.locale.key]"
                        desc
                        :text="input.desc[$root.locale.key]"
                        :required="isInputRequired(input)"
                        :tooltip="input.tooltip"
                    />

                    <!-- Error message -->
                    <VFErrorMessage v-if="input.type == 'error'"
                        :text="input.desc[$root.locale.key]"
                    />

                    <!-- Text input -->
                    <VFInput v-if="input.type == 'text'"
                        v-model="input.model"
                        :validation="input.val"
                        :disabled="isInputDisabled(input)"
                        :placeholder="getPlaceholder(input)"
                        :dotted="designer && !isInputVisible(input)"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        :numeric="input.inputType == 'number'"
                        :maxdigits="input.max"
                        :dictionary="getExternal(input)"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                        @match="onDictionaryMatch(input, $event)"
                    />

                    <!-- Tooltip -->
                    <VFTooltip v-if="input.type == 'ttip'"
                        :inputData="input"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                    />

                    <!-- Text area -->
                    <VFTextArea v-if="input.type == 'area'"
                        v-model="input.model"
                        :validation="input.val"
                        :disabled="isInputDisabled(input)"
                        :placeholder="getPlaceholder(input)"
                        :dotted="designer && !isInputVisible(input)"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        :numeric="input.inputType == 'number'"
                        :maxdigits="input.max"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                    />

                    <!-- Phone input -->
                    <VFPhone v-if="input.type == 'phone'"
                        v-model="input.model"
                        :disabled="isInputDisabled(input)"
                        :placeholder="getPlaceholder(input)"
                        :options="input.external ? resources[input.logic.rlist.key].items : input.items"
                        :listKey="$root.locale.key"
                        :displayKey="$root.locale.key"
                        :outputKey="'value'"
                        :dotted="designer && !isInputVisible(input)"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        :validation="input.val"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                    />

                    <!-- Language selector -->
                    <VFLanguageSelector v-if="input.type == 'language'"
                        :locales="locales"
                        v-model="input.model"
                        :disabled="isInputDisabled(input)"
                        :placeholder="getPlaceholder(input)"
                        :dotted="designer && !isInputVisible(input)"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        :validation="input.val"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                    />

                    <!-- Datepicker -->
                    <VFDatepicker v-if="input.type == 'date'"
                        v-model="input.model"
                        :disabled="isInputDisabled(input)"
                        :placeholder="getPlaceholder(input)"
                        :selectMode="!input.pickMode"
                        outputISO
                        :validation="input.val"
                        :designer="designer"
                        @input="onInput(card, input)"
                    />

                    <!-- Checkbox -->
                    <VFCheckbox v-if="input.type == 'check'"
                        :placeholder="getPlaceholder(input)"
                        :options="input.options"
                        v-model="input.model"
                        :inline="input.inline"
                        :radio="input.radio"
                        :bordered="input.border"
                        :fillCard="input.fillCard"
                        :activeLogic="logicActive"
                        noValidation
                        :designer="designer"
                        :validation="input.val"
                        :disabled="isInputDisabled(input)"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                    />

                    <!-- Checkbox V2 -->
                    <VFCheckboxV2 v-if="input.type == 'check2'"
                        :placeholder="getPlaceholder(input)"
                        :items="input.items"
                        v-model="input.model"
                        :inline="input.inline"
                        :radio="input.radio"
                        :yesno="input.yesno"
                        :min="input.min"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :validation="input.val"
                        :disabled="isInputDisabled(input)"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                    />

                    <!-- Dropdown -->
                    <VFDropdown v-if="input.type == 'drop'"
                        v-model="input.model"
                        :disabled="isInputDisabled(input)"
                        :placeholder="getPlaceholder(input)"
                        :options="input.external ? resources[input.logic.rlist.key].items : input.items"
                        :listKey="$root.locale.key"
                        :displayKey="$root.locale.key"
                        :outputKey="'value'"
                        :dotted="designer && !isInputVisible(input)"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        :validation="input.val"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                    />

                    <!-- Country dropdown -->
                    <VFDropdown v-if="input.type == 'country'"
                        v-model="input.model"
                        :placeholder="getPlaceholder(input)"
                        :options="$root.countriesJson"
                        :commonOptions="$root.countriesCommon"
                        :listKey="$root.locale.key"
                        :displayKey="$root.locale.key"
                        :outputKey="'key'"
                        :dotted="designer && !isInputVisible(input)"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        :disabled="isInputDisabled(input)"
                        :validation="input.val"
                        @connect="onConnectLogicX"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                    />

                    <!-- Fileupload -->
                    <VFFileupload v-if="input.type == 'upload'"
                        v-model="input.model"
                        :id="input.key"
                        :validation="input.val"
                        :header="input.desc[$root.locale.key]"
                        :hintText="input.hint[$root.locale.key]"
                        :browseText="input.browse[$root.locale.key]"
                        :allowedFiles="input.fileType"
                        :singleFile="input.singleFile"
                        :disabled="isInputDisabled(input)"
                        :autoPreview="input.autoPreview"
                        @input="onInput(card, input)"
                    />

                    <!-- File Preview -->
                    <VFFilePreview v-if="input.type == 'preview'"
                        v-model="input.model"
                        :id="input.key"
                        :designer="designer"
                    />

                    <!-- Country dropdown -->
                    <VFBrregSearch v-if="input.type == 'brreg'"
                        v-model="input.model"
                        :placeholder="getPlaceholder(input)"
                        :dotted="designer && !isInputVisible(input)"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        :validation="input.val"
                        :disabled="isInputDisabled(input)"
                        @connect="onConnectLogicX"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                        @match="onDictionaryMatch(input, $event)"
                    />

                    <VFValidationSummary v-if="input.type == 'valsum'"
                        :cardData="input"
                        :designer="designer"
                        :reference="reference"
                        :selected="isInputSelected(input)"
                        @selected="selectInput(input)"
                    />

                    <VFGreetingPreview v-if="input.type == 'greet'"
                        :cardData="input"
                        :designer="designer"
                        :reference="reference"
                        :selected="isInputSelected(input)"
                        @selected="selectInput(input)"
                    />

                    <ApproveMultipleInput v-if="input.type == 'appm'"
                        :cardData="input"
                        :designer="designer"
                        :reference="reference"
                        :selected="isInputSelected(input)"
                        @selected="selectInput(input)"
                    />

                    <VFButtonRow v-if="input.type == 'buttoni'"
                        :alignment="input.align"
                        :designer="designer"
                        :selected="isInputSelected(input)"
                        @selected="selectInput(input)"
                    >
                        <VFButton v-for="button in input.buttons" :key="button.key"
                            :id="button.key"
                            :text="button.text[$root.locale.key]"
                            :disabled="isInputOptionDisabled(button)"
                            @logic="handleLogic"
                            @clicked="onButtonClick(input, button)"
                        />
                    </VFButtonRow>

                </VFGroup>
            </VFRow>

            <div v-if="designer && toggle.add" class="frv2-add-actions" style="display: flex;">
                <FormMenuButton dotted :icon="'add'" style="margin-right: 10px;" @clicked="$emit('add-input', card)" />
                <FormMenuButton dotted :icon="'paste'" style="margin-right: 10px;" v-if="designer && toggle.add && clipboard.hasInput" @clicked="$emit('paste-input', card)" />
            </div>

        </vap-card>

        <div v-if="!designer && card.type == 'list'" v-show="designer || isCardVisible(card)">
            <vap-card v-for="(item, j) in card.model" :key="card.key + j"
                list
                :id="card.key"
                :header="card.header[$root.locale.key] + (card.hideListNum ? '' : (' ' + (j+1)))"
            >
                <template v-slot:actions>
                    <div class="frv2-card-link" v-if="card.ppop">
                        <span @click="onShowPersonList(card, j)">{{ __t('person-select-list') }}</span>
                    </div>
                    <vap-icon v-if="!card.minOneListEntry || card.model.length > 1" action :icon="'delete'" @clicked="onRemoveClicked(card, j)"><template v-slot:tooltip><vap-tooltip :localized="'common_remove'" /></template></vap-icon>
                </template>

                <vap-row>
                    <VFGroup v-for="input in card.inputs" :key="input.key"
                        v-show="designer || isListInputVisible(card, input, j)"
                        :id="input.key"
                        :width="input.width"
                        :validation="card.val[j][input.listModel]"
                    >

                        <!-- Help text -->
                        <VFHelpText v-if="input.type == 'helpt'"
                            :text="input.desc[$root.locale.key]"
                        />

                        <!-- Description label -->
                        <VFLabel v-if="input.desc[$root.locale.key] && input.type != 'helpt' && input.type != 'ttip'"
                            desc
                            :text="input.desc[$root.locale.key]"
                            :required="isListInputRequired(card, input, j)"
                            :tooltip="input.tooltip"
                        />

                        <!-- Text input -->
                        <VFInput v-if="input.type == 'text'"
                            v-model="item[input.listModel]"
                            :validation="card.val[j][input.listModel]"
                            :disabled="isInputDisabled(input)"
                            :placeholder="getListPlaceholder(card, input, j)"
                            :id="input.key"
                            :numeric="input.inputType == 'number'"
                            :maxdigits="input.max"
                            :dictionary="getExternal(input)"
                            @input="onInput(card, input, j)"
                            @match="onDictionaryMatch(input, $event, card, j)"
                        />

                        <!-- Tooltip -->
                        <VFTooltip v-if="input.type == 'ttip'"
                            :inputData="input"
                            :activeLogic="logicActive"
                            :designer="designer"
                            :id="input.key"
                            @connect="onConnectLogic"
                            @connect-enter="onConnectEnter"
                            @connect-leave="onConnectLeave"
                            @input="onInput(card, input, j)"
                        />

                        <!-- Phone input -->
                        <VFPhone v-if="input.type == 'phone'"
                            v-model="item[input.listModel]"
                            :disabled="isInputDisabled(input)"
                            :placeholder="getListPlaceholder(card, input, j)"
                            :dotted="designer && !isInputVisible(input)"
                            :activeLogic="logicActive"
                            :designer="designer"
                            :id="input.key"
                            :validation="card.val[j][input.listModel]"
                            @connect="onConnectLogic"
                            @connect-enter="onConnectEnter"
                            @connect-leave="onConnectLeave"
                            @input="onInput(card, input, j)"
                        />

                        <!-- Language selector -->
                        <VFLanguageSelector v-if="input.type == 'language'"
                            :locales="locales"
                            v-model="item[input.listModel]"
                            :disabled="isInputDisabled(input)"
                            :placeholder="getListPlaceholder(card, input, j)"
                            :dotted="designer && !isInputVisible(input)"
                            :activeLogic="logicActive"
                            :designer="designer"
                            :id="input.key"
                            :validation="card.val[j][input.listModel]"
                            @connect="onConnectLogic"
                            @connect-enter="onConnectEnter"
                            @connect-leave="onConnectLeave"
                            @input="onInput(card, input, j)"
                        />

                        <!-- Datepicker -->
                        <VFDatepicker v-if="input.type == 'date'"
                            v-model="item[input.listModel]"
                            :disabled="isInputDisabled(input)"
                            :validation="card.val[j][input.listModel]"
                            :placeholder="getListPlaceholder(card, input, j)"
                            outputISO
                            :designer="designer"
                            @input="onInput(card, input, j)"
                        />

                        <!-- Checkbox -->
                        <VFCheckbox v-if="input.type == 'check'"
                            :placeholder="getListPlaceholder(card, input, j)"
                            :options="input.options"
                            v-model="item[input.listModel]"
                            :inline="input.inline"
                            :radio="input.radio"
                            :disabled="isInputDisabled(input)"
                            :bordered="input.border"
                            :fillCard="input.fillCard"
                            :validation="card.val[j][input.listModel]"
                            @input="onInput(card, input, j)"
                        />

                        <!-- Checkbox V2 -->
                        <VFCheckboxV2 v-if="input.type == 'check2'"
                            :placeholder="getListPlaceholder(card, input, j)"
                            :items="input.items"
                            v-model="item[input.listModel]"
                            :inline="input.inline"
                            :radio="input.radio"
                            :yesno="input.yesno"
                            :min="input.min"
                            :validation="card.val[j][input.listModel]"
                            :disabled="isInputDisabled(input)"
                            @input="onInput(card, input, j)"
                        />

                        <!-- Dropdown -->
                        <VFDropdown v-if="input.type == 'drop'"
                            v-model="item[input.listModel]"
                            :placeholder="getListPlaceholder(card, input, j)"
                            :options="input.external ? resources[input.logic.rlist.key].items : input.items"
                            :listKey="$root.locale.key"
                            :displayKey="$root.locale.key"
                            :outputKey="'value'"
                            :dotted="designer && !isInputVisible(input)"
                            :activeLogic="logicActive"
                            :designer="designer"
                            :disabled="isInputDisabled(input)"
                            :id="input.key"
                            :validation="card.val[j][input.listModel]"
                            @connect="onConnectLogic"
                            @connect-enter="onConnectEnter"
                            @connect-leave="onConnectLeave"
                            @input="onInput(card, input, j)"
                        />

                        <!-- Country dropdown -->
                        <VFDropdown v-if="input.type == 'country'"
                            v-model="item[input.listModel]"
                            :placeholder="getListPlaceholder(card, input, j)"
                            :options="$root.countriesJson"
                            :commonOptions="$root.countriesCommon"
                            :listKey="$root.locale.key"
                            :displayKey="$root.locale.key"
                            :outputKey="'key'"
                            :dotted="designer && !isInputVisible(input)"
                            :activeLogic="logicActive"
                            :designer="designer"
                            :disabled="isInputDisabled(input)"
                            :id="input.key"
                            :validation="card.val[j][input.listModel]"
                            @input="onInput(card, input, j)"
                        />

                    </VFGroup>
                </vap-row>
            </vap-card>
        </div>

        <vap-card v-if="designer && card.type == 'list'"
            v-show="designer || isCardVisible(card)"
            list
            :id="card.key"
            :header="card.header[$root.locale.key] + (card.hideListNum ? '' : (' 1'))"
            :designer="designer"
            :dotted="designer && !isCardVisible(card)"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
            @logic="handleLogic"
        >
            <template v-slot:actions>
                <div class="frv2-card-link" v-if="card.ppop">
                    <span>{{ __t('person-select-list') }}</span>
                </div>
                <!-- 
                No need to display this icon in the editor. Will just be confused with the Properties delete icon that removes the entire card.
                <vap-icon action :icon="'delete'"><template v-slot:tooltip><vap-tooltip :localized="'common_remove'" /></template></vap-icon>
                -->
            </template>

            <vap-row>
                <VFGroup v-for="input in card.inputs" :key="input.key"
                    :id="input.key"
                    :width="input.width"
                    :designer="designer" 
                    :selected="isInputSelected(input)"
                    @selected="selectInput(input)"
                    @logic="handleLogic"
                >

                    <!-- Help text -->
                    <vap-helptext v-if="input.type == 'helpt'"
                        :text="input.desc[$root.locale.key]"
                    />

                    <!-- Description label -->
                    <VFLabel v-if="input.desc[$root.locale.key] && input.type != 'helpt' && input.type != 'ttip'"
                        desc
                        :text="input.desc[$root.locale.key]"
                        :required="isListInputRequired(card, input, 0)"
                        :tooltip="input.tooltip"
                    />

                    <!-- Text input -->
                    <VFInput v-if="input.type == 'text'"
                        :disabled="isInputDisabled(input)"
                        :placeholder="getListPlaceholder(card, input, 0)"
                        :dotted="designer && !isInputVisible(input)"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        :numeric="input.inputType == 'number'"
                        :maxdigits="input.max"
                        :dictionary="getExternal(input)"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                        @match="onDictionaryMatch(input, $event, card, j)"
                    />

                    <!-- Tooltip -->
                    <VFTooltip v-if="input.type == 'ttip'"
                        :inputData="input"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input, j)"
                    />

                    <!-- Phone input -->
                    <vap-phone v-if="input.type == 'phone'"
                        :placeholder="getListPlaceholder(card, input, 0)"
                    />

                    <!-- Language selector -->
                    <VFLanguageSelector v-if="input.type == 'language'"
                        :locales="locales"
                        :disabled="input.disabled"
                        :placeholder="getListPlaceholder(card, input, 0)"
                        :dotted="designer && !isInputVisible(input)"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input, j)"
                    />

                    <!-- Datepicker -->
                    <VFDatepicker v-if="input.type == 'date'"
                        :placeholder="getListPlaceholder(card, input, 0)"
                        :designer="designer"
                    />

                    <!-- Checkbox -->
                    <VFCheckbox v-if="input.type == 'check'"
                        :placeholder="getListPlaceholder(card, input, 0)"
                        :options="input.options"
                        :inline="input.inline"
                        :radio="input.radio"
                        :activeLogic="logicActive"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                    />

                    <!-- Checkbox V2 -->
                    <VFCheckboxV2 v-if="input.type == 'check2'"
                        :placeholder="getListPlaceholder(card, input, 0)"
                        :items="input.items"
                        :inline="input.inline"
                        :radio="input.radio"
                        :yesno="input.yesno"
                        :min="input.min"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :disabled="isInputDisabled(input)"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                    />

                    <!-- Dropdown -->
                    <VFDropdown v-if="input.type == 'drop'"
                        v-model="input.model"
                        :placeholder="getListPlaceholder(card, input, 0)"
                        :options="input.external ? resources[input.logic.rlist.key].items : input.items"
                        :listKey="$root.locale.key"
                        :displayKey="$root.locale.key"
                        :outputKey="'value'"
                        :dotted="designer && !isInputVisible(input)"
                        :activeLogic="logicActive"
                        :designer="designer"
                        :id="input.key"
                        :validation="input.val"
                        @connect="onConnectLogic"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                        @input="onInput(card, input)"
                    />

                    <!-- Country dropdown -->
                    <VFDropdown v-if="input.type == 'country'"
                        :placeholder="getListPlaceholder(card, input, 0)"
                        :options="$root.countriesJson"
                        :commonOptions="$root.countriesCommon"
                        :listKey="$root.locale.key"
                        :displayKey="$root.locale.key"
                        :designer="designer"
                        :activeLogic="logicActive"
                        :id="input.key"
                        @connect="onConnectLogicX"
                        @connect-enter="onConnectEnter"
                        @connect-leave="onConnectLeave"
                    />

                </VFGroup>
            </vap-row>

            <div v-if="designer && toggle.add" class="frv2-add-actions" style="display: flex;">
                <FormMenuButton dotted :icon="'add'" style="margin-right: 10px;" @clicked="$emit('add-input', card)" />
                <FormMenuButton dotted :icon="'paste'" style="margin-right: 10px;" v-if="designer && toggle.add && clipboard.hasInput" @clicked="$emit('paste-input', card)" />
            </div>

        </vap-card>

        <vap-button-row v-if="card.type == 'list'" alignCenter style="margin-top: 0px; margin-bottom: 20px;" v-show="designer || isCardVisible(card)">
            <vap-button :text="card.addtext[$root.locale.key]" @clicked="testAddListCard(card)" />
        </vap-button-row>

<!--         <div v-if="card.type == 'custom'" @click="selectCard(card)">
            {{ card }}
        </div> -->

        <div v-if="card.type == 'buttons'" class="flw100" style="margin-bottom: 20px;">
            <VFButtonRow
                :alignment="card.align"
                :designer="designer"
                :selected="isCardSelected(card)"
                @selected="selectCard(card)"
            >
                <VFButton v-for="button in card.buttons" :key="button.key"
                    :id="button.key"
                    :text="button.text[$root.locale.key]"
                    @logic="handleLogic"
                    @clicked="onButtonClick(input, button)"
                />
            </VFButtonRow>
        </div>

        <VFLogo v-if="card.type == 'logo'"
            :cardData="card"
            :designer="designer"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />
        <VFMethods v-if="card.type == 'methods'"
            :cardData="card"
            :designer="designer"
            :reference="reference"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />
        <VFPepInfo v-if="card.type == 'pepinfo'"
            :cardData="card"
            :designer="designer"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />
        <VFPopup v-if="card.type == 'popup'"
            :cardData="card"
            :designer="designer"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />

        <KycComparisonView v-if="card.type == 'kycc'"
            :cardData="card"
            :reference="reference"
            :designer="designer"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />

        <KycClientInfo v-if="card.type == 'kyci'"
            :cardData="card"
            :reference="reference"
            :designer="designer"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />

        <KycAssessmentInfo v-if="card.type == 'kyca'"
            :cardData="card"
            :reference="reference"
            :designer="designer"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />

        <VFTextViewer v-if="card.type == 'cftext'"
            :content="card.header[$root.locale.key]"
            :designer="designer"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />

        <SignCheckout v-if="card.type == 'checkout'"
            v-show="designer || isCardVisible(card)"
            :cardData="card"
            :designer="designer"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
            :reference="reference"
            :locales="locales"
        />

        <VFFlowVisualizer v-if="card.type == 'flowv'"
            :cardData="card"
            :envelope="reference.envelope"
            :recipient="reference.recipient"
            :designer="designer"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />

        <VFDocumentPreview v-if="card.type == 'pdfview'"
            :cardData="card"
            :designer="designer"
            :reference="reference"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />

        <VFPdfHeader v-if="card.type == 'pdfh'" v-show="!card.pdfOnly || toggle.pdf"
            :cardData="card"
            :designer="designer"
            :reference="reference"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />

        <VFPdfText v-if="card.type == 'pdft'" v-show="!card.pdfOnly || toggle.pdf"
            :cardData="card"
            :designer="designer"
            :reference="reference"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />

        <VFCheckoutU v-if="card.type == 'checku'"
            :cardData="card"
            :designer="designer"
            :reference="reference"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />

        <VFAnalogSignature v-if="card.type == 'anas'"
            v-show="isCardVisible(card)"
            :id="card.key"
            :cardData="card"
            :designer="designer"
            :reference="reference"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
            @logic="handleLogic"
        />

        <!-- Checklist system -->
        
        <ChecklistHeader v-if="card.type == 'chk-h'" style="float: left; margin-bottom: 20px;"
            :reference="reference"
            :roleKey="roleKey"
            :designer="designer"
            :selected="isCardSelected(card)"
        />
        <ChecklistHeaderQA v-if="card.type == 'chk-hqa'" style="float: left; margin-bottom: 20px;"
            :reference="reference"
            :roleKey="roleKey"
            :designer="designer"
            :selected="isCardSelected(card)"
        />
        <ChecklistCheckout v-if="card.type == 'chk-checkout'"
            :reference="reference"
            :resources="resources"
            :roleKey="roleKey"
            :designer="designer"
        />
        <div v-if="card.type == 'chk-c'">
            <ChecklistView
                :cardData="card"
                :designer="designer"
                :selected="isCardSelected(card)"
                @selected="selectCard(card)"
            >
                <ChecklistItem v-for="input in card.inputs" :key="input.key"
                    v-show="designer || (isInputVisible(input) && isChecklistItemVisible(input))"
                    :item="input"
                    :model="input.model"
                    :resources="resources"
                    :roleKey="roleKey"
                    :validation="input.val"
                    :designer="designer" 
                    :selected="isInputSelected(input)"
                    @selected="selectInput(input)"
                    @logic="handleLogic"
                    @chklogic="handleChecklistLogic"
                    @input="onInput(card, input)"
                />
            </ChecklistView>
        </div>
        <div v-if="card.type == 'chk-a' && !designer">
            <ChecklistArray v-for="(item, j) in card.model" :key="card.key + j"
                :cardData="card"
                :itemModel="card.itemModel[j]"
                :designer="designer"
                :selected="isCardSelected(card)"
                @selected="selectCard(card)"
                @delete="onCHARemoveClicked(card, j)"
            >
                <template v-slot:chk>
                    <ChecklistItem v-for="input in card.inputs.filter(input => input.mode == 'chk')" :key="input.key"
                        v-show="isInputVisible(input)"
                        :item="input"
                        :model="item[input.key]"
                        :resources="resources"
                        :roleKey="roleKey"
                        :validation="card.val[j][input.key]"
                        @input="onInput(card, input, j)"
                    />
                </template>
                <template v-slot:scr>
                    <ChecklistItem v-for="input in card.inputs.filter(input => input.mode == 'scr')" :key="input.key"
                        v-show="isInputVisible(input)"
                        :item="input"
                        :model="item[input.key]"
                        :resources="resources"
                        :roleKey="roleKey"
                        :validation="card.val[j][input.key]"
                        @input="onInput(card, input, j)"
                    />
                </template>
            </ChecklistArray>
            <vap-button-row alignCenter style="margin-top: 0px; margin-bottom: 20px;" v-show="designer || isCardVisible(card)">
                <vap-button :text="card.addtext[$root.locale.key]" @clicked="onAddChecklist(card)" :disabled="roleKey == 'approver' && !resources.isCDD" />
            </vap-button-row>
        </div>
        <div v-if="card.type == 'chk-a' && designer">
            <ChecklistArray
                :cardData="card"
                :designer="designer"
                :selected="isCardSelected(card)"
                @selected="selectCard(card)"
            >
                <template v-slot:chk>
                    <ChecklistItem v-for="input in card.inputs.filter(input => input.mode == 'chk')" :key="input.key"
                        v-show="designer || isInputVisible(input)"
                        :item="input"
                        :resources="resources"
                        :roleKey="roleKey"
                        :designer="designer" 
                        :selected="isInputSelected(input)"
                        @selected="selectInput(input)"
                        @logic="handleLogic"
                    />
                </template>
                <template v-slot:scr>
                    <ChecklistItem v-for="input in card.inputs.filter(input => input.mode == 'scr')" :key="input.key"
                        v-show="designer || isInputVisible(input)"
                        :item="input"
                        :resources="resources"
                        :roleKey="roleKey"
                        :designer="designer" 
                        :selected="isInputSelected(input)"
                        @selected="selectInput(input)"
                        @logic="handleLogic"
                    />
                </template>
            </ChecklistArray>
            <vap-button-row alignCenter style="margin-top: 0px; margin-bottom: 20px;" v-show="designer || isCardVisible(card)">
                <vap-button :text="card.addtext[$root.locale.key]" />
            </vap-button-row>
        </div>
        
        <VFHeader
            v-if="card.type == 'card-header'" 
            v-show="designer || isCardVisible(card)"
            :cardData="card"
            :designer="designer"
            :selected="isCardSelected(card)"
            @selected="selectCard(card)"
        />

    </div>

    <div v-if="testingHB && reference.chkModel && reference.chkModel.enabled" class="flw100">
        <vap-button-row alignCenter>
            <vap-button :text="'Autofill branch'" @clicked="$emit('testhb', 'branch')" />
            <vap-button :text="'Autofill cdd'" @clicked="$emit('testhb', 'cdd')" />
        </vap-button-row>
    </div>

    <div v-if="designer && toggle.add" class="frv2-add" @click="$emit('add-card')">
        <label>{{ 'ADD CARD' }}</label>
    </div>
    <div v-if="designer && toggle.add && clipboard.hasCard" class="frv2-add" @click="$emit('paste-card', page)">
        <label>{{ 'PASTE CARD' }}</label>
    </div>

</div>
</template>

<script>
import formHelper from './formHelper';
import formValidation from './formValidation';
import moduleHelper from './modules/moduleHelper';

import FormMenuButton from './designer/FormMenuButton';
import FormInfoCard from './FormInfoCard';
import FormInfoCardPhone from './FormInfoCardPhone';

import VFRow from './items/VFRow.vue';
import VFGroup from './items/VFGroup.vue';
import VFLabel from './items/VFLabel.vue';
import VFErrorMessage from './items/VFErrorMessage.vue';
import VFHelpText from './items/VFHelpText.vue';
import VFTextViewer from './TextViewerV2.vue';

import VFInput from './inputs/VFInput.vue';
import VFTextArea from './inputs/VFTextArea.vue';
import VFCheckbox from './inputs/VFCheckbox.vue';
import VFCheckboxV2 from './inputs/VFCheckboxV2.vue';
import VFDropdown from './inputs/VFDropdown.vue';
import VFDatepicker from './inputs/VFDatepicker.vue';
import VFFileupload from './inputs/VFFileupload.vue';
import VFFilePreview from './inputs/VFFilePreview.vue';
import VFCountrySelector from './inputs/VFCountrySelector.vue';
import VFPhone from './inputs/VFPhone.vue';
import VFLanguageSelector from './inputs/VFLanguageSelector.vue';
import VFBrregSearch from './inputs/VFBrregSearch.vue';
import VFTooltip from './items/VFTooltip.vue';

import VFButton from './items/VFButton.vue';
import VFButtonRow from './items/VFButtonRow.vue';

import VFLogo from './items/VFLogo.vue';
import VFMethods from './items/VFMethods.vue';
import VFPepInfo from './items/VFPepInfo.vue';
import VFPopup from './items/VFPopup.vue';
import VFHeader from './items/VFHeader.vue';

import VFValidationSummary from './items/VFValidationSummary.vue';
import VFDocumentPreview from './items/VFDocumentPreview.vue';
import VFGreetingPreview from './items/VFGreetingPreview.vue';
import ApproveMultipleInput from './modules/approve/ApproveMultipleInput.vue';

import VFPdfHeader from './items/VFPdfHeader.vue';
import VFPdfText from './items/VFPdfText.vue';

// Checklist system
import ChecklistView from './modules/checklist/ChecklistView.vue';
import ChecklistItem from './modules/checklist/ChecklistItem.vue';
import ChecklistArray from './modules/checklist/ChecklistArray.vue';
import ChecklistHeader from './modules/checklist/ChecklistHeader.vue';
import ChecklistHeaderQA from './modules/checklist/ChecklistHeaderQA.vue';
import ChecklistCheckout from './modules/checklist/ChecklistCheckout.vue';

import SignCheckout from './modules/signing/SignCheckout.vue';
import VFFlowVisualizer from './items/VFFlowVisualizer.vue';

import KycComparisonView from './modules/kyc/KycComparisonView.vue';
import KycClientInfo from './modules/kyc/KycClientInfo.vue';
import KycAssessmentInfo from './modules/kyc/KycAssessmentInfo.vue';

import VFCheckoutU from './checkout/VFCheckoutU.vue';
import VFAnalogSignature from './items/VFAnalogSignature.vue';

export default {
    name: 'FormRendererV2',
    components: {
        FormMenuButton,
        FormInfoCard,
        FormInfoCardPhone,
        VFRow,
        VFGroup,
        VFLabel,
        VFErrorMessage,
        VFHelpText,
        VFTextViewer,
        VFInput,
        VFTextArea,
        VFCheckbox,
        VFCheckboxV2,
        VFDropdown,
        VFDatepicker,
        VFFileupload,
        VFFilePreview,
        VFCountrySelector,
        VFPhone,
        VFLanguageSelector,
        VFBrregSearch,
        VFTooltip,
        VFButton,
        VFButtonRow,
        ChecklistView,
        ChecklistItem,
        ChecklistArray,
        ChecklistHeader,
        ChecklistHeaderQA,
        ChecklistCheckout,
        VFLogo,
        VFMethods,
        VFPepInfo,
        VFPopup,
        SignCheckout,
        VFHeader,
        VFValidationSummary,
        VFDocumentPreview,
        VFGreetingPreview,
        ApproveMultipleInput,
        VFFlowVisualizer,
        KycComparisonView,
        KycClientInfo,
        KycAssessmentInfo,
        VFPdfHeader,
        VFPdfText,
        VFCheckoutU,
        VFAnalogSignature,
    },
    props: {
        locales: { type: Array, default: () => { return [] }},
        page: { type: Object, default: () => { return {} }},
        pages: { type: Array, default: () => { return [] }},
        resources: { type: Object, default: () => { return {} }},
        toggle: { type: Object, default: () => { return { logic: true, pdf: false, add: true, locale: false, info: true,} }},
        clipboard: { type: Object, default: () => { return {} }},
        roleKey: { type: String, default: '' },
        designer: { type: Boolean, default: false },
        builder: { type: Boolean, default: false },
        phoneMode: { type: Boolean, default: false },
        logicActive: { type: Boolean, default: false },
        isPopup: { type: Boolean, default: false },
        selectedLine: { type: Object, default: () => { return {} }},
    },
    data() {
        return {
            reference: null,
            selectedCard: null,
            selectedInput: null,

            testingHB: true
        }
    },
    watch: {
        designer: {
            deep: false,
            handler(val) {
                if (this.builder) {
                    this.reference = val ? this.$parent.$parent : this.$parent.$parent;
                }else {
                    this.reference = this.isPopup ? this.$parent.$parent : this.$parent;
                }
                console.log('designer changed, ref =', this.reference);
            }
        }
    },
    methods: {

        getExternal(input) {
            if (input.logic && input.logic.rdict) {
                if (input.logic.rdict.key == 'zipCodes') {
                    return this.$root.zipNumbersNO;
                }
            }
            return null;
        },

        onDictionaryMatch(input, value, card, index) {
            this.$emit('match', input, value, card, index);
        },

        testAddListCard(card) {
            formHelper.addListModel(this.$root, card, {});
        },

        onAddChecklist(card) {
            formHelper.addListModel(this.$root, card, {});
        },

        onRemoveClicked(card, index) {
            card.model.splice(index, 1);
            if (!this.designer) this.$emit('personinput', card, null, index);
        },

        onCHARemoveClicked(card, index) {
            card.model.splice(index, 1);
        },

        onButtonClick(input, button) {
            if (button.logic && button.logic.action) this.$emit('action', button.logic.action.type, button.logic.action.key);
        },

        onInput(card, input, index) {
            if (input.logic) {
                if (input.logic.link) {
                    for (const i in input.logic.link) {
                        this.$emit('link', input.logic.link[i], input.model);
                    }
                }
                if (input.logic.rval) {
                    
                    // TODO fix for popup forms
                    this.reference.resources[input.logic.rval.key].value = input.model;

                }
                if (input.logic.action) {
                    
                    this.$emit('action', input.logic.action.type, input.logic.action.key);

                }
            }
            if (!this.designer) {
                this.$emit('validate', input, index);
            }else {
                this.$emit('editorinput', card, input);
            }

            /* if (!this.designer) this.reference.onPersonInput(card, input, index); */
            //console.log('onInput')
            this.$emit('personinput', card, input, index);
        },

        onShowPersonList(card, index) {
            /* this.reference.showPersonList(card, index); */
            this.$emit('personlist', card, index);
        },

        //#region Selection ---------------------------------------------------------------------------------------------------

        isCardSelected(card) {
            if (!this.selectedCard) return false;
            return (this.selectedCard.key == card.key);
        },

        isInputSelected(input) {
            if (!this.selectedInput) return false;
            return (this.selectedInput.key == input.key);
        },

        selectCard(card) {
            if (this.logicActive) return;
            this.selectedInput = null;
            this.selectedCard = card;
            this.$emit('sel-input', null);
            this.$emit('sel-card', card);
        },

        selectInput(input) {
            if (this.logicActive) return;
            this.selectedCard = null;
            this.selectedInput = input;
            this.$emit('sel-card', null);
            this.$emit('sel-input', input);
        },

        deselect() {
            this.selectedCard = null;
            this.selectedInput = null;
            this.$emit('sel-card', null);
            this.$emit('sel-input', null);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Rendering ---------------------------------------------------------------------------------------------------

        isInputRequired(input) {
            return formValidation.isInputRequired(this.reference, input, this.roleKey, this.designer);
        },

        isListInputRequired(card, input, index) {
            return formValidation.isListInputRequired(this.reference, card, index, input, this.roleKey, this.designer);
        },

        getPlaceholder(input) {
            if (input.placeholder) {
                return input.placeholder;
            }
            return this.__(this.isInputRequired(input) ? 'common_required' : 'common_optional');
        },

        getListPlaceholder(card, input, j) {
            if (input.placeholder) {
                return input.placeholder;
            }
            return this.__(this.isListInputRequired(card, input, j) ? 'common_required' : 'common_optional');
        },

        isChecklistItemVisible(input) {
            if (!input.chkshow) return true;
            const item = formHelper.getItemFromKey(this.pages, input.chkshow.key);
            if (item.model[input.chkshow.type] == input.chkshow.value) return true;
            return false;
        },

        isCardVisible(card) {
            return formHelper.isCardVisible(this.reference, card, this.designer, this.pages, this.page, this.roleKey);
        },

        isInputVisible(input) {
            return formHelper.isInputVisible(this.reference, input, this.designer, this.pages, this.page);
        },

        isListInputVisible(card, input, index) {
            return formHelper.isListInputVisible(this.reference, card, input, index, this.designer, this.pages, this.page);
        },

        isInputDisabled(input) {
            if (input.disabled) return true;
            if (input.logic && input.logic.disabled) {
                //console.log('input', input);
                //console.log('this.roleKey', this.roleKey);
                const values = [];
                for (const i in input.logic.disabled.keys) {

                    if (input.logic.disabled.keys[i].type == 'role') {
                        values.push(this.roleKey == input.logic.disabled.keys[i].key);
                    }
                }
                //console.log('values', values);
                if (values.length == 0) {
                    return false;
                }else if (input.logic.disabled.or) {
                    let value = false;
                    for (const i in values) {
                        if (values[i]) value = true;
                    }
                    return value;
                }else if (!input.logic.disabled.or) {
                    let value = false;
                    for (const i in values) {
                        if (!values[i]) value = true;
                    }
                    return value;
                }
            }
            return false;
        },

        isInputOptionDisabled(option) {
            // Should be "disabled", check if HB uses this and remove if not
            if (option.logic && option.logic.enabled) {
                const values = [];
                for (const i in option.logic.enabled.keys) {
                    const item = formHelper.getItemFromKey(this.pages, option.logic.enabled.keys[i].pkey);
                    if (option.logic.enabled.keys[i].key == 'pvalid') {
                        values.push(item.validation && item.validation.valid);
                        //console.log('val', item.validation);
                    }
                }
                //console.log('values', values);
                if (values.length == 0) {
                    return false;
                }else if (option.logic.enabled.or) {
                    let value = true;
                    for (const i in values) {
                        if (values[i]) value = false;
                    }
                    return value;
                }else if (!option.logic.enabled.or) {
                    let value = false;
                    for (const i in values) {
                        if (!values[i]) value = true;
                    }
                    return value;
                }
            }
            if (option.logic && option.logic.disabled) {
                
                const values = [];
                for (const i in option.logic.disabled.keys) {

                    if (option.logic.disabled.keys[i].type == 'val') {

                        if (option.logic.disabled.keys[i].key == 'form') {
                            if (this.designer) return false;
                            values.push(this.reference.validation.formValid);
                        }
                        
                    }

                }
                //console.log('values', values);
                if (values.length == 0) {
                    return false;
                }else if (option.logic.disabled.or) {
                    let value = true;
                    for (const i in values) {
                        if (values[i]) value = false;
                    }
                    return value;
                }else if (!option.logic.disabled.or) {
                    let value = false;
                    for (const i in values) {
                        if (!values[i]) value = true;
                    }
                    return value;
                }
            }
            return false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Logic -------------------------------------------------------------------------------------------------------

        handleLogic(key) {
            this.$emit('logic', key);
        },

        handleChecklistLogic(params) {
            this.$emit('chklogic', params);
        },

        onConnectEnter() {
            this.$emit('logic-possible', true);
        },

        onConnectLeave() {
            this.$emit('logic-possible', false);
        },

        onConnectLogic(key) {
            this.$emit('logic-connect', key);
        },

        onConnectLogicX(key) {
            this.$emit('logic-connectx', key);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Help texts --------------------------------------------------------------------------------------------------

        onAddLineClicked(data) {
            this.$emit('line-new', data);
        },

        onLineClicked(reference) {
            this.$emit('line-edit', reference);
        },

        onLineIconClicked(line) {
            this.$emit('line-icon', line);
        },

        onDeleteLineClicked(params) {
            this.$emit('line-delete', params);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------
        
    },
    async created() {
        //console.log('toogle', this.toggle);
        this.reference = this.builder ? this.$parent.$parent : (this.reference = this.isPopup ? this.$parent.$parent : this.$parent);
    }
}
</script>
<style scoped>

.frv2-add {
    float: left;
    padding-left: 10px;
    color: var(--link-text);
}
.frv2-add:hover {
    color: var(--link-active);
}
.frv2-add label {
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}

.frv2-add-actions {
    float: left;
    padding-top: 5px;
    padding-left: 5px;
}


.frv2-card-link {
    margin-top: 9px;
    padding-right: 10px;
}
.frv2-card-link span {
    color: var(--link-text);
    cursor: pointer;
}
.frv2-card-link span:hover {
    color: var(--link-active);
}

</style>