<template>
    <div>

        <vap-dropdown v-model="model"
                    :options="options"
                    :placeholder="placeholder"
                    :listKey="'display'"
                    :displayKey="'display'"
                    :outputKey="'value'"
                    :rules="rules"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation"
                    @input="handleChange" />

    </div>
</template>

<script>

export default {
    name: 'LanguageSelector',
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        rules: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        noHighlight: {
            type: Boolean,
            default: false
        },
        noValidation: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            model: '',
            options: [{ value: 'en_EN', display: 'English' }]
        }
    },
    methods: {
        handleChange() {
            this.$emit('input', this.model);
        }
    },
    async mounted() {
        this.model = this.value;
        this.options = [];
        for (let i in this.$root.config.locales) {
            if (this.$root.config.locales[i].enabled) {
                this.options.push({
                    value: this.$root.config.locales[i].key,
                    display: this.$root.config.locales[i].name
                });
            }
        }
    }
}
</script>
<style scoped>


</style>