<template>
<div class="flsv2" @click.stop="onStepClick" :class="[
    {'flsv2-selected': selected }
]">
    {{ step }}
</div>
</template>

<script>


export default {
    name: 'FlowRendererV2',
    components: {
        
    },
    props: {
        step: { type: Object, default: null },
        selected: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

        async onSave(data) {
            
        },

        onStepClick() {
            this.$emit('clicked');
        },

    },
    async created() {
        console.log('step', this.step);
        

    }
}
</script>
<style scoped>

.flsv2 {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    border: solid 2px gray;
    box-sizing: border-box;
}

.flsv2-selected {
    border: solid 2px blue;
}

</style>