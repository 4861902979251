import axios from 'axios';
import v2lib from '../v2lib';
import tools from '../tools';
import log from '../log';

import dataModelV1 from './dataModelV1';
import dataHelperV1 from './dataHelperV1';

/* import dataModel from '../dataModel';
import dataHelper from '../dataHelper'; */
const dataSwedenV1 = {

    useTestResponse: false,

    //#region Searching ---------------------------------------------------------------------------------------------------

    async getTrapetsData(orgno) {
        const result = {
            success: false,
            testdata: false,
            data: {}
        };

        if (this.useTestResponse) {
            try {
                const response = await axios.get(`test_data/trapets/${orgno}.json`);
                if (response.status == 200) {
                    await new Promise(r => setTimeout(r, 500));
                    result.data = response.data;
                    result.testdata = true;
                    result.success = true;
                    return result;
                }
            }catch (e) {
                console.log('TEST DATA UNAVAILABLE');
            }
        }

        const headers = {};
        headers["authorization"] = "JWT " + v2lib.personalToken;
        headers["x-namespace"] = v2lib.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        const companyId = v2lib.namespace.split('/')[2];

        const data = {
            "SubQueries": []
        };

        const array = '1234567890-';
        let isNumber = true;

        for (let i=0; i<orgno.toString().length; i++) {
            if (!array.includes(orgno.toString()[i])) isNumber = false;
        }

        if (isNumber) {
            data['SubQueries'].push({
                "Id": orgno,
                "Countries": ["SWE"],
                "ItemNumber": orgno.replace('-', '')
            });
            log.x('Trapets: Searching with orgnr', data);
        }else {
            data['SubQueries'].push({
                "Id": tools.getUniqueId(),
                "Countries": ["SWE"],
                "Name": orgno
            });
            log.x('Trapets: Searching with name', data);
        }

        try {
            const response = await axios({
                url: `/api/trapets/companies/${companyId}/query`,
                method: 'POST',
                headers: headers,
                data: data
            });
            console.log(JSON.stringify(response.data))
            result.data = response.data;
            result.success = true;
        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;
    },

    async getBisnodeData(orgno) {
        const result = {
            success: false,
            testdata: false,
            data: {}
        };

        if (this.useTestResponse) {
            try {
                const response = await axios.get(`test_data/bisnode/${orgno}.json`);
                if (response.status == 200) {
                    await new Promise(r => setTimeout(r, 500));
                    result.data = response.data;
                    result.testdata = true;
                    result.success = true;
                    return result;
                }
            }catch (e) {
                console.log('TEST DATA UNAVAILABLE');
            }
        }

        const headers = {};
        headers["authorization"] = "JWT " + v2lib.personalToken;
        headers["x-namespace"] = v2lib.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            const response = await axios({
                url: `/api/bisnode/company?country=se&regNumber=${orgno}&rawRes=1`,
                method: 'GET',
                headers: headers
            });
            console.log(JSON.stringify(response.data))
            result.data = response.data;
            result.success = true;
        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Mapping -----------------------------------------------------------------------------------------------------

    processData(root, output, trapets, bisnode) {
        console.log('process trapets', trapets);
        console.log('process bisnode', bisnode);

        output.source = 'trapets-bisnode';
        output.version = 1;

        output.lookup = true;
        output.date = new Date();

        const trapetsHits = [];

        for (const i in trapets.ResponseItems[0].Entities) {
            const entity = trapets.ResponseItems[0].Entities[i];
            if (entity.SourceName == 'BVBeneficialOwners') {
                // Trapets - Beneficial owners

                const xml = this.parseXML(entity.Xml);

                for (const i in xml.owners) {
                    const dateOfBirth = xml.owners[i].ssn.substring(0, 4) + '-' + xml.owners[i].ssn.substring(4, 6) + '-' + xml.owners[i].ssn.substring(6, 8);
                    const beneficial = dataModelV1.getEmptyBeneficial();
                    beneficial.firstName = xml.owners[i].firstName;
                    beneficial.lastName = xml.owners[i].lastName;
                    beneficial.dateOfBirth = dateOfBirth;
                    beneficial.shareType = xml.owners[i].code;
                    beneficial.shareRange = xml.owners[i].share;
                    beneficial.country = xml.owners[i].country;
                    beneficial.citizenship = xml.owners[i].citizenship;
                    beneficial.ssn = xml.owners[i].ssn;
                    beneficial.identified = true;
                    output.beneficials.push(beneficial);
                }

                output.ownership.beneficialsAvailable = xml.dataAvailable == 'Available' ? 'Y' : 'N';
                output.ownership.beneficialsSuspicious = xml.suspicious == true ? 'Y' : 'N';

                console.log('xml', xml);

            }else if (entity.SourceName == 'OwnAndRep') {
                // Trapets - Owners and representatives
                console.log('OwnAndRep', entity);

                output.name = entity.Name;
                output.orgnr = entity.CorporateId;

                for (const i in entity.Owners) {
                    const shareholder = dataModelV1.getEmptyShareholder();
                    shareholder.name = entity.Owners[i].Name;
                    shareholder.share = 100 * Number(entity.Owners[i].OwnershipStake);
                    if (entity.Owners[i].OwnerType == 'Individual' && entity.Owners[i].ItemNumber) {
                        shareholder.type = 'person';
                        const names = tools.getNames(entity.Owners[i].Name);
                        shareholder.firstName = names.firstName;
                        shareholder.lastName = names.lastName;
                        shareholder.dateOfBirth = entity.Owners[i].ItemDate;
                    }else if (entity.Owners[i].OwnerType == 'Entity' && entity.Owners[i].ItemNumber) {
                        shareholder.type = 'org';
                        shareholder.orgnr = entity.Owners[i].ItemNumber;
                    }else {
                        shareholder.orgnr = entity.Owners[i].ItemNumber;
                    }
                    output.shareholders.push(shareholder);
                }

                for (const i in entity.Representatives) {
                    if (entity.Representatives[i].RepresentativeType == 'Entity') {

                        if (entity.Representatives[i].EngagementType == 'Accountant') {
                            output.economy.accountant.name = entity.Representatives[i].Name;
                            output.economy.accountant.orgnr = entity.Representatives[i].ItemNumber;
                        }

                    }else {
                        const role = dataModelV1.getEmptyRole();
                        role.roleId = this.getRoleIdTrapets(entity.Representatives[i].EngagementType);
                        const names = tools.getNames(entity.Representatives[i].Name);
                        role.firstName = names.firstName;
                        role.lastName = names.lastName;
                        role.dateOfBirth = entity.Representatives[i].ItemDate;
                        role.ssn = entity.Representatives[i].ItemNumber;
                        role.country = entity.Representatives[i].Country == 'SWE' ? 'SE' : entity.Representatives[i].Country;
                        role.street = entity.Representatives[i].Street;
                        const zip = this.processTrapetsZip(entity.Representatives[i].ZipCode);
                        role.zip = zip.zip;
                        role.city = zip.city;
                        output.roles.push(role);
                    }
                }

            }else if (entity.ListType == 'SANCTION') {
                
                trapetsHits.push(entity);

            }
        }


        output.sanction.available = true;
        output.sanction.message = 'No result for SANCTION screening on company';
        if (trapetsHits.length > 0) {
            output.sanction.hit = true;
            output.sanction.summary.hits = trapetsHits.length;
            output.sanction.message = 'Hit on SANCTION';
        }


        for (let i=1; i<10; i++) {
            if (bisnode.generalCompanyDataBase['lineOfBusinessDesc' + i] && bisnode.generalCompanyDataBase['lineOflineOfBusinessC' + i]) {
                let cc = bisnode.generalCompanyDataBase['lineOflineOfBusinessC' + i];
                while (cc.length < 4) cc = '0' + cc;
                const code = {
                    code: cc,
                    description: bisnode.generalCompanyDataBase['lineOfBusinessDesc' + i]
                };
                output.naceCodes.push(code);
            }
        }

        output.info.country = 'SE';
        output.info.number = output.orgnr;
        output.info.dunsNumber = bisnode.generalCompanyDataBase.dunsNumber;
        output.info.dateRegistered = this.parseBisnodeRegDate(bisnode.generalCompanyDataBase.regDate);
        output.form.formCode = bisnode.generalCompanyDataBase.legalFormCode;
        output.form.formDescription = bisnode.generalCompanyDataBase.legalFormDesc;
        output.registration.inVatRegistry = bisnode.generalCompanyDataBase.registeredInTheVatList;
        output.registration.employer = bisnode.generalCompanyDataBase.registeredAsEmployer;
        output.registration.taxAssessment = bisnode.generalCompanyDataBase.noticeOfTaxAssessmentDesc == 'F-skattsedel finns' ? 'Y' : 'N';

        output.address.business.country = 'SE';
        output.address.business.street = bisnode.generalCompanyDataBase.postalAddress;
        output.address.business.zip = bisnode.generalCompanyDataBase.postalCode;
        output.address.business.city = bisnode.generalCompanyDataBase.town;
        output.address.business.commune = bisnode.generalCompanyDataBase.placeOfRegMunicipalityDesc;
        output.address.business.communeNumber = bisnode.generalCompanyDataBase.placeOfRegMunicipalityCode;

        output.contact.phone = bisnode.generalCompanyDataBase.telNumberInclAreaCode;

        // Accounting years
        if (bisnode.financialStatementSweden) {
            const sweden = bisnode.financialStatementSweden;

            // This object will be an array if there are more than one year, but an object if just one year
            if (Object.prototype.toString.call(sweden) === '[object Array]') {
                
                /* const years = JSON.parse(JSON.stringify(baml.financials.accounting.years));
                years.sort((b, a) => (a.year > b.year) ? 1 : -1); */
                if (sweden.length > 0) output.economy.yearAvailable = true;
                if (sweden.length > 2) output.economy.trendAvailable = true;

                // TODO remove this
                /* output.economy.yearAvailable = false;
                output.economy.trendAvailable = false; */

                let count = 0;
                for (const i in sweden) {
                    const key = 'year' + count;
                    if (!output.economy[key]) output.economy[key] = dataModelV1.getEmptyEconomyYear();
                    output.economy[key].available = true;
                    output.economy[key].year = sweden[i].accountPeriod.substring(0, 4);

                    output.economy[key].salesRevenues = this.fillEconomyValue(sweden[i].netSales);
                    output.economy[key].otherRevenues = this.fillEconomyValue(sweden[i].otherFinancialIncome);

                    output.economy[key].totalRevenues = this.fillEconomyValue(sweden[i].totalRenewal);
                    output.economy[key].costOfGoodsSold = this.fillEconomyValue(null);
                    output.economy[key].inventoryChanges = this.fillEconomyValue(null);
                    output.economy[key].salaryCosts = this.fillEconomyValue(null);
                    output.economy[key].financialCosts = this.fillEconomyValue(null);
                    output.economy[key].depreciations = this.fillEconomyValue(null);
                    output.economy[key].amortizations = this.fillEconomyValue(null);
                    output.economy[key].otherOperatingCosts = this.fillEconomyValue(null);
                    output.economy[key].operatingProfits = this.fillEconomyValue(sweden[i].operatingProfitLoss);
                    output.economy[key].ordinaryProfits = this.fillEconomyValue(null);
                    output.economy[key].extraordinaryRevenues = this.fillEconomyValue(null);
                    output.economy[key].extraordinaryCosts = this.fillEconomyValue(null);
                    output.economy[key].minorityInterests = this.fillEconomyValue(null);
                    output.economy[key].taxes = this.fillEconomyValue(sweden[i].tax);
                    output.economy[key].resultBeforeTax = this.fillEconomyValue(sweden[i].profitLossAfter);
                    output.economy[key].netProfits = this.fillEconomyValue(sweden[i].profitLossAfter);
                    
                    output.economy[key].currentAssets = this.fillEconomyValue(null);
                    output.economy[key].fixedAssets = this.fillEconomyValue(null);
                    output.economy[key].sumAssets = this.fillEconomyValue(sweden[i].netWorth);
                    output.economy[key].equity = this.fillEconomyValue(null);
                    output.economy[key].debt = this.fillEconomyValue(null);
                    output.economy[key].sumEquityAndDebt = this.fillEconomyValue(sweden[i].totalEquityAndLiability2);

                    count += 1;
                }

            }else {

                output.economy.yearAvailable = true;
                output.economy.trendAvailable = false;

                const key = 'year0';
                if (!output.economy[key]) output.economy[key] = dataModelV1.getEmptyEconomyYear();
                output.economy[key].available = true;
                output.economy[key].year = sweden.accountPeriod.substring(0, 4);

                output.economy[key].salesRevenues = this.fillEconomyValue(sweden.netSales);
                output.economy[key].otherRevenues = this.fillEconomyValue(sweden.otherFinancialIncome);

                output.economy[key].totalRevenues = this.fillEconomyValue(sweden.totalRenewal);
                output.economy[key].costOfGoodsSold = this.fillEconomyValue(null);
                output.economy[key].inventoryChanges = this.fillEconomyValue(null);
                output.economy[key].salaryCosts = this.fillEconomyValue(null);
                output.economy[key].financialCosts = this.fillEconomyValue(null);
                output.economy[key].depreciations = this.fillEconomyValue(null);
                output.economy[key].amortizations = this.fillEconomyValue(null);
                output.economy[key].otherOperatingCosts = this.fillEconomyValue(null);
                output.economy[key].operatingProfits = this.fillEconomyValue(sweden.operatingProfitLoss);
                output.economy[key].ordinaryProfits = this.fillEconomyValue(null);
                output.economy[key].extraordinaryRevenues = this.fillEconomyValue(null);
                output.economy[key].extraordinaryCosts = this.fillEconomyValue(null);
                output.economy[key].minorityInterests = this.fillEconomyValue(null);
                output.economy[key].taxes = this.fillEconomyValue(sweden.tax);
                output.economy[key].resultBeforeTax = this.fillEconomyValue(sweden.profitLossAfter);
                output.economy[key].netProfits = this.fillEconomyValue(sweden.profitLossAfter);
                
                output.economy[key].currentAssets = this.fillEconomyValue(null);
                output.economy[key].fixedAssets = this.fillEconomyValue(null);
                output.economy[key].sumAssets = this.fillEconomyValue(sweden.netWorth);
                output.economy[key].equity = this.fillEconomyValue(null);
                output.economy[key].debt = this.fillEconomyValue(null);
                output.economy[key].sumEquityAndDebt = this.fillEconomyValue(sweden.totalEquityAndLiability2);

            }
        }
        output.economy.basicAvailable = true;

        // Subsidiaries
        if (bisnode.subsidiaries) {
            if (Object.prototype.toString.call(bisnode.subsidiaries) === '[object Array]') {
                for (const i in bisnode.subsidiaries) {
                    const sub = dataModelV1.getEmptySubsidiary();
                    sub.name = bisnode.subsidiaries[i].subsidName;
                    sub.orgnr = bisnode.subsidiaries[i].subsidCountryRegNum;
                    sub.dunsNumber = bisnode.subsidiaries[i].subsidDuns;
                    sub.country = bisnode.subsidiaries[i].subsidCountryCode;
                    output.subsidiaries.push(sub);
                }
            }else {
                const sub = dataModelV1.getEmptySubsidiary();
                sub.name = bisnode.subsidiaries.subsidName;
                sub.orgnr = bisnode.subsidiaries.subsidCountryRegNum;
                sub.dunsNumber = bisnode.subsidiaries.subsidDuns;
                sub.country = bisnode.subsidiaries.subsidCountryCode;
                output.subsidiaries.push(sub);
            }
            output.subsidiariesAvailable = true;
        }

        // companiesBusinessDesc
        if (bisnode.companiesBusinessDesc) {
            let str = '';
            for (const i in bisnode.companiesBusinessDesc) {
                str += bisnode.companiesBusinessDesc[i].businessDesc;
            }
            output.info.purpose = str;
        }

        // signature1
        if (bisnode.signature1) {
            for (const i in bisnode.signature1) {
                output.signature.description.push(bisnode.signature1[i].signature);
            }
        }
        output.signature.available = true;

        dataHelperV1.infoMatchPersons(output);
        dataHelperV1.sortLists(output);
        console.log('output', output);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    processPerson(root, output, trapetsData, bisnodeData, params) {
        console.log('trapetsData', trapetsData);
        console.log('bisnodeData', bisnodeData);

        output.source = 'trapets-bisnode';
        output.version = 1;
        output.done = true;
        output.userId = root.credentials.uid;

        output.searchParams.firstName = params.firstName;
        output.searchParams.lastName = params.lastName;
        output.searchParams.dateOfBirth = params.dateOfBirth;
        output.searchParams.ssn = params.ssn;
        output.searchParams.countryOfSsn = params.countryOfSsn;
        output.searchParams.mode = params.mode;
        output.searchParams.parameters = params.parameters;

        if (trapetsData && trapetsData.data && trapetsData.data.ResponseItems && trapetsData.data.ResponseItems.length > 0) {
            for (const i in trapetsData.data.ResponseItems) {
                if (trapetsData.data.ResponseItems[i].Individuals) {
                    for (const j in trapetsData.data.ResponseItems[i].Individuals) {
                        const hit = trapetsData.data.ResponseItems[i].Individuals[j];

                        console.log('hit', hit);

                        if (hit.ListType == 'PEP') {
                            output.isPep = true;
                            output.pepSummary.hits += 1;

                            const pep = dataModelV1.getEmptyPepHit();

                            pep.source = hit.SourceName;
                            pep.provider = 'TRAPETS';

                            pep.matchIndicator = this.getMatchIndicator(params, hit);
                            pep.matchDescription = this.getMatchDescription(pep.matchIndicator);

                            pep.name = hit.Name;
                            pep.title = hit.Title;

                            output.pepHits.push(pep);

                            output.pepSummary.sources.push(hit.SourceName);
                            if (pep.matchIndicator > output.pepSummary.matchIndicator) {
                                output.pepSummary.matchIndicator = pep.matchIndicator;
                                output.pepSummary.matchDescription = pep.matchDescription;
                                output.pepSummary.title = pep.title;
                            }

                        }else if (hit.ListType == 'SANCTION') {
                            output.hasSanction = true;
                            output.sanctionSummary.hits += 1;

                            const sanction = dataModelV1.getEmptySanctionHit();

                            sanction.source = hit.SourceName;
                            sanction.provider = 'TRAPETS';

                            sanction.matchIndicator = this.getMatchIndicator(params, hit);
                            sanction.matchDescription = this.getMatchDescription(sanction.matchIndicator);

                            sanction.name = hit.Name;
                            sanction.title = hit.Title;

                            output.sanctionHits.push(sanction);

                            output.sanctionSummary.sources.push(hit.SourceName);
                            if (sanction.matchIndicator > output.sanctionSummary.matchIndicator) {
                                output.sanctionSummary.matchIndicator = sanction.matchIndicator;
                                output.sanctionSummary.matchDescription = sanction.matchDescription;
                                output.sanctionSummary.title = sanction.title;
                            }

                        }

                        
                    }
                }
            }
        }else {

        }

        if (bisnodeData && bisnodeData.data && bisnodeData.data.consumer) {
            const bisnode = bisnodeData.data.consumer;


            output.addressPostal.available = true;
            output.addressPostal.street = bisnode.postalAdressLine1;
            output.addressPostal.zip = bisnode.postCode;
            output.addressPostal.city = bisnode.town;
            output.addressPostal.country = "SE";
            output.addressPostal.source = "BISNODE";


            output.addressNational.available = true;
            output.addressNational.street = bisnode.nationalRegistrationAddressPostalAddressLine1;
            output.addressNational.zip = bisnode.nationalRegistrationAddressPostCode;
            output.addressNational.city = bisnode.nationalRegistrationAddressTown;
            output.addressNational.country = "SE";
            output.addressNational.source = "BISNODE";

        }
        
    },

    getMatchIndicator(params, hit) {

        let matchDOB = false;

        for (const i in params.parameters) {

            if (params.parameters[i] == 'dateOfBirth' && hit.BirthDate == params.dateOfBirth) {
                matchDOB = true;
            }

        }

        if (matchDOB) {
            return 175;
        }else {
            return 125;
        }
    },

    getMatchDescription(indicator) {
        switch (indicator) {
            case 125: return 'match on name only';
            case 175: return 'match on name and date of birth';
        }
        return 'unknown';
    },

    //#region Helpers -----------------------------------------------------------------------------------------------------

    fillEconomyValue(input) {
        return input ? input.$value * 1000 : -1;
    },

    parseBisnodeRegDate(input) {
        try {
            return input.substring(0,4) + '-' + input.substring(4,6) + '-' + input.substring(6,8);
        }catch (e) {
            return input;
        }
    },

    getRoleIdTrapets(input) {
        switch (input) {
            case 'CEO': return 'role_ceo';
            case 'ExternalCEO': return 'role_ceo';
            case 'Chairman': return 'role_chair';
            case 'BoardMember': return 'role_board_member';
            case 'DeputyBoardMember': return 'role_deputy_bm';
            case 'Accountant': return 'role_accountant';
        }
        return 'unknown';
    },

    processTrapetsZip(zip) {
        const array = zip.split('');
        let index = 0;
        const output = {
            zip: '',
            city: ''
        }
        for (const i in array) {
            if ('1234567890'.includes(array[i])) {
                output.zip += array[i];
            }else if (array[i] == ' ') {
                index = Number(i) + 1;
            }else {
                break;
            }
        }
        output.city = zip.substring(index);
        return output;
    },

    parseXML(text) {
        const output = {
            owners: []
        };
        const xml = this.getXMLDocument(text);
        const company = xml.getElementsByTagName("OriginalData")[0].childNodes[1];
        console.log('xml', company);
        for (const i in company.childNodes) {
            const tagName0 = company.childNodes[i].tagName;
            const value0 = company.childNodes[i].textContent;
            if (tagName0 == 'OrgNo') {
                output.orgnr = value0;
            }else if (tagName0 == 'CountryCode') {
                output.country = value0;
            }else if (tagName0 == 'CompanyName') {
                output.name = value0;
            }else if (tagName0 == 'LegalForm') {
                output.legalForm = value0;
            }else if (tagName0 == 'SuspiciousData') {
                output.suspicious = value0;
            }else if (tagName0 == 'BeneficialOwnerAvailability') {
                output.dataAvailable = value0;
            }
        }
        const owners = company.getElementsByTagName("BeneficialOwners")[0].childNodes;
        for (const i in owners) {
            if (owners[i].childNodes) {
                const owner = {};
                for (const j in owners[i].childNodes) {
                    const tagName = owners[i].childNodes[j].tagName;
                    const value = owners[i].childNodes[j].textContent;
                    if (tagName == 'PersonalNumber') {
                        owner.ssn = value;
                    }else if (tagName == 'FirstName') {
                        owner.firstName = value;
                    }else if (tagName == 'LastName') {
                        owner.lastName = value;
                    }else if (tagName == 'FullName') {
                        owner.fullName = value;
                    }else if (tagName == 'CountryOfResidence') {
                        owner.country = value;
                    }else if (tagName == 'CitizenShip') {
                        owner.citizenship = value;
                    }else if (tagName == 'ControlList') {
                        const control = owners[i].childNodes[j].childNodes[0];
                        for (const k in control.childNodes) {
                            const tagName2 = control.childNodes[k].tagName;
                            const value2 = control.childNodes[k].textContent;
                            if (tagName2 == 'ControlCode') {
                                owner.code = value2;
                            }else if (tagName2 == 'ControlInterval') {
                                owner.share = value2;
                            }
                        }
                    }
                }
                output.owners.push(owner);
            }
        }
        return output;
    },

    getXMLDocument(text) {
        let xmlDoc;
        if (window.DOMParser) {
            const parser = new DOMParser();
            xmlDoc = parser.parseFromString(text, "text/xml");
        }else {
            // Internet Explorer
            xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
            xmlDoc.async = false;
            xmlDoc.loadXML(text);
        }
        return xmlDoc;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default dataSwedenV1;