<template>
    <div class="ov-status">

        <div class="ov-status-title">

            <label>{{ title }}</label>

        </div>

        <div class="ov-status-sl">

            <vap-status-label :completed="completed" :aborted="aborted" :warning="warning"
                :text="text" :name="name" />

        </div>
        
    </div>
</template>

<script>

export default {
    name: 'OverviewPending',
    components: {
        
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        completed: {
            type: Boolean,
            default: false
        },
        aborted: {
            type: Boolean,
            default: false
        },
        warning: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    async created() {
        
    }
}
</script>
<style scoped>

.ov-status {

}

.ov-status-title {
    margin-top: 24px;
    font-size: 18px;
    color: var(--text-strong);
}

.ov-status-sl {
    margin-top: 4px;
}

</style>