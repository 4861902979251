<template>
<div>

    <PopupV1 :header="__t('client-import')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div v-if="!fileSelected">
                <VFFileupload
                    v-model="fileModel"
                    :id="'impid'"
                    :header="__t('client-import')"
                    :hintText="__t('files-upload-hint')"
                    :browseText="__t('files-upload-browse')"
                    :singleFile="true"
                    :autoPreview="false"
                    @input="onFileSelected"
                />
            </div>

            <DialogContent v-if="fileSelected">
                
                <DialogContentHeader :text="__t('common-customer').toUpperCase() + ':'" />
                <DialogContentItem :text="companyTitleCase(client.name)" :icon="'company'" />
                <DialogContentHeader :text="__t('files-attachments').toUpperCase() + ':'" />
                <DialogContentItem v-for="(attachment, i) in attachments" :key="'fra' + i" :text="attachment.name" :icon="'attachment'" />

            </DialogContent>

        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('client-import')"
                :icon="'upload'"
                @clicked="onImportClicked"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFFileupload from '../components/form/inputs/VFFileupload.vue';

export default {
    name: 'ImportClientDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFFileupload,
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            fileModel: {},
            fileSelected: false,
            wrongFileType: false,
            rawData: null,
            client: null,
            attachments: []
        }
    },
    methods: {

        //#region File reading ------------------------------------------------------------------------------------------------

        validateDialog() {
            this.dialog.valid = this.fileSelected;
        },

        onFileSelected() {
            for (const i in this.fileModel.files) {
                const parts = this.fileModel.files[0].name.split('.');
                if (parts.length == 0) return this.onWrongFileType();
                if (parts[parts.length - 1] != 'vaml') return this.onWrongFileType();
            }
            this.readFile(this.fileModel.files[0].data);
        },

        onWrongFileType() {
            this.wrongFileType = true;
        },

        readFile(file) {
            const self = this;
            const reader = new FileReader();
            reader.onload = async function (event) {
                try {
                    const content = event.target.result;
                    self.processFile(content);
                }catch (exception) {
                    console.log('EX', exception);
                }
            };
            reader.readAsText(file);
        },

        processFile(content) {
            let readError = false;

            const startIndex = content.indexOf('¤¤¤DATA_START¤¤¤', 0);
            const endIndex = content.indexOf('¤¤¤DATA_END¤¤¤', 0);

            const dataString = content.substring(startIndex + 16, endIndex);
            this.rawData = JSON.parse(dataString);

            const files = [];
            let currentIndex = 0, lastIndex = 0, loopCount = 0;
            let endOfFile = false;

            while (!endOfFile) {
                const sIndex = content.indexOf('¤¤¤ATTACHMENT_START¤¤¤', currentIndex);
                const eIndex = content.indexOf('¤¤¤ATTACHMENT_END¤¤¤', currentIndex + 3);

                if (eIndex != -1) {

                    const str = content.substring(sIndex + 22, eIndex);
                    files.push(str);

                }
                currentIndex = eIndex;
                if (currentIndex == lastIndex || eIndex == -1) endOfFile = true;
                lastIndex = currentIndex;

                loopCount += 1;
                if (loopCount > 50) {
                    readError = true;
                    endOfFile = true;
                }
            }

            for (const i in this.rawData.attachments) {
                const attachment = this.rawData.attachments[i];
                attachment.base64 = files[i];
                this.attachments.push(attachment);
            }
            this.client = this.rawData.client;
            console.log('client', this.client);

            this.fileSelected = true;
            this.validateDialog();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Client creation ---------------------------------------------------------------------------------------------

        async onImportClicked() {
            this.loading = true;
            this.dialog.working = true;
            this.dialog.status = 'Importing client';

            console.log('raw', this.rawData);

            const metadata = {

            };

            await v1api.importClient(this.rawData.client, this.rawData.persons, metadata)


            this.dialog.working = false;
            this.loading = false;
            this.onClose();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        this.loading = true;
        this.loading = false;
        this.validateDialog();
    }
}
</script>