<template>
<div>
    <vap-form-divider :left="55" :right="45">
        <template v-slot:left>
            <!-- Spinner -->
            <div v-if="loading" class="flw100" style="position: relative; padding-top: 120px;">
                <div style="position: absolute; left: 50%;"><vap-spinner medium /></div>
            </div>
            <div v-if="!loading">
                <div style="position: relative;">
                    <vap-label header :text="__('ov_steps')" style="margin-top: 20px; margin-bottom: 10px;" />
                    <div class="ov-bg-load" v-if="$root.workingInBackground">
                        <vap-spinner iconMode />
                        <div>
                            <label>{{ 'Laster ned firmarapport i bakgrunnen' }}</label>
                        </div>
                    </div>
                </div>

                <OverviewStep v-for="(step, i) in flow.steps" :key="'s' + i" :step="step" v-show="!step.hidden">
                    <template v-slot:pending>
                        <!-- Pedning steps -->

                        <OverviewPending v-if="step.key == 'company_aml'"
                            :title="__(step.key + '_pending')"
                            :info="__('ov_hint_aml_no')"
                            :tooltip="__('ov_tt_aml_no')"
                        />
                        <OverviewPending v-if="step.key == 'company_kyc'"
                            :title="__(step.key + '_pending')"
                            :info="__('ov_hint_kyc')"
                            :tooltip="__('ov_tt_kyc')"
                        />
                        <OverviewPending v-if="step.key == 'company_kyc_form'"
                            :title="__('company_kyc' + '_pending')"
                            :info="__('ov_hint_kyc')"
                            :tooltip="__('ov_tt_kyc')"
                        />
                        <OverviewPending v-if="step.key == 'company_per'"
                            :title="__(step.key + '_pending')"
                            :info="__('ov_hint_roles')"
                            :tooltip="__('ov_tt_roles')"
                        />
                        <OverviewPending v-if="step.key == 'company_rep'"
                            :title="__(step.key + '_pending')"
                            :info="__('ov_hint_report')"
                            :tooltip="__('ov_tt_report')"
                        />

                        <!-- UK -->
                        <OverviewPending v-if="step.key == 'company_uk_checklist'"
                            :title="'AML Checklist'"
                            :info="'Provide the necessary information about the company and send it to CDD for approval/review.'"
                            :tooltip="'Complete the AML checklist'"
                        />
                        <OverviewPending v-if="step.key == 'company_uk_audit'"
                            :title="'QA review (optional)'"
                            :info="'Perform an QA review of the AML process. This will be performed by CDD if necessary.'"
                            :tooltip="'Perform an QA review of the AML process'"
                        />
                        <OverviewPending v-if="step.key == 'company_uk_notify'"
                            :title="'Notify branch'"
                            :info="'Notify the branch that the checklist is completed.'"
                            :tooltip="'Notify the branch that the checklist is completed.'"
                        />

                        <!-- Deloitte -->
                        <OverviewPending v-if="step.key == 'custom_imanage'"
                            :title="__(step.key + '_pending')"
                            :info="'Lagre den fullstendige rapporten i iManage. Trykk på Lagre for å velge iManage-mappen i filsystemet.'"
                            :tooltip="'<p>Lagre den fullstendige rapporten i iManage. Trykk på Lagre for å velge iManage-mappen i filsystemet.</p>'"
                        />
                        <OverviewPending v-if="step.key == 'custom_forward'"
                            :title="__(step.key + '_pending')"
                            :info="'Sender en forenklet sluttrapport til valgt mottaker.'"
                            :tooltip="'<p>Sender en forenklet sluttrapport til valgt mottaker.</p>'"
                        />

                        <!-- Aksjeeierbok -->
                        <OverviewPending v-if="step.key == 'company_book'"
                            :title="__('company_shareholder_book')"
                            :info="'Det er gjort endringer i eierstruktur/nøkkelroller i egeneerklæringsskjema og aksjeeierbok må kontrolleres.'"
                            :tooltip="'<p>Det er gjort endringer i eierstruktur/nøkkelroller i egeneerklæringsskjema og aksjeeierbok må kontrolleres.</p>'"
                        />

                        <OverviewPending v-if="step.key == 'snn_bank_kyc'"
                            :title="'Egenerklæring'"
                            :info="'Bank + Regnskapsflyt. Last opp kopi av egenerklæringsskjema fra bank.'"
                            :tooltip="'<p>Bank + Regnskapsflyt. Last opp kopi av egenerklæringsskjema fra bank.</p>'"
                        />
                        <OverviewPending v-if="step.key == 'snn_bank_kyc2'"
                            :title="'Egenerklæring'"
                            :info="'Last opp kopi av eksisterende egenerklæringsskjema fra bank.'"
                            :tooltip="'<p>Last opp kopi av eksisterende egenerklæringsskjema fra bank.</p>'"
                        />

                        <!-- Person -->
                        <OverviewPending v-if="step.key == 'person_idp'"
                            :title="'Utfør ID-kontroll'"
                            :info="'Send invitasjon til å gjennomføre ID-kontroll. Det utføres et PEP/Sanksjonsøk i forbindelse med ID-kontrollen.'"
                            :tooltip="'<p>Send invitasjon til å gjennomføre ID-kontroll. Det utføres et PEP/Sanksjonsøk i forbindelse med ID-kontrollen.</p>'"
                        />
                        <OverviewPending v-if="step.key == 'person_rep'"
                            :title="__('o_person_rep_pending_head')"
                            :info="__('o_person_rep_pending_info')"
                            :tooltip="__('o_person_rep_pending_tt')"
                        />

                        <OverviewPending v-if="step.key == 'person_idc'"
                            :title="__('o_person_idc_pending_head')"
                            :info="__('o_person_idc_pending_info')"
                            :tooltip="__('o_person_idc_pending_tt')"
                        />
                        <OverviewPending v-if="step.key == 'person_pep'"
                            :title="__('o_person_pep_pending_head')"
                            :info="__('o_person_pep_pending_info')"
                            :tooltip="__('o_person_pep_pending_tt')"
                        />

                        <!-- Norges Bank -->
                        <OverviewPending v-if="step.key == 'nb_reference'"
                            :title="'Fullfør'"
                            :info="'Fullfør kunde'"
                            :tooltip="'<p>Fullfør kunde</p>'"
                        />

                        <!-- Borea -->
                        <OverviewPending v-if="step.key == 'suitability'"
                            :title="'Kundeklassifisering/investeringsanbefaling '"
                            :info="'Lag en kundeklassifisering/investeringsanbefaling basert på det som er hentet inn i egnethetserklæringen.'"
                            :tooltip="'<p>Lag en kundeklassifisering/investeringsanbefaling basert på det som er hentet inn i egnethetserklæringen.</p>'"
                        />

                        <!-- KAR -->
                        <OverviewPending v-if="step.key == 'company_kar'"
                            :title="'KAR-oppslag'"
                            :info="'Gjør et oppslag i KAR-registeret (Konto- og AdresseringsRegister). Sjekker at et kontonummer tilhører et foretak eller person.'"
                            :tooltip="'Gjør et oppslag i KAR-registeret'"
                        />

                    </template>
                    <template v-slot:progress>
                        <!-- In progress steps -->
                        
                        <div v-if="step.key == 'company_kyc'">
                            <OverviewStatus v-if="!$parent.kycMetadata.completed"
                                :title="__('kyc_form')"
                                :text="__('ov_kyc_awaiting_editor')"
                                :name="__('ov_kyc_status_by') + $parent.kycMetadata.editor.displayName"
                            />
                            <OverviewStatus v-if="$parent.kycMetadata.completed && !$parent.kycMetadata.signed"
                                :title="__('kyc_form')"
                                :text="__('ov_kyc_awaiting_signing')"
                                :name="__('ov_kyc_status_by') + $parent.kycMetadata.nextSigner.displayName"
                            />
                        </div>

                        <div v-if="step.key == 'company_uk_checklist'">
                            <OverviewStatus
                                :title="'AML Checklist'"
                                :text="$parent.checklist.statusText"
                                :name="__('ov_kyc_status_by') + $parent.checklist.actorName"
                            />
                        </div>

                        <div v-if="step.key == 'company_per'" style="padding-bottom: 16px;">
                            <OverviewStatus
                                :title="__(step.key + '_pending')"
                                :text="$parent.roles.searchCount + ' ' + __('common_of') + ' ' + $parent.selectedPersonCount"
                                :name="__('pep_searches') + ' ' + __('common_performed').toLowerCase()"
                                :completed="$parent.roles.searchCount == $parent.selectedPersonCount"
                            />
                            <vap-status-label :text="$parent.roles.idcCount + ' ' + __('common_of') + ' ' + $parent.selectedPersonCount" :name="__('id_controls') + ' ' + __('common_performed').toLowerCase()" 
                                :completed="$parent.roles.idcCount == $parent.selectedPersonCount" />
                            <vap-status-label :text="$parent.roles.keywordCount + ' ' + __('common_of') + ' ' + $parent.selectedPersonCount" :name="'stikkord-søk' + ' ' + __('common_performed').toLowerCase()"
                                :completed="$parent.roles.keywordCount == $parent.selectedPersonCount" v-if="$root.config.report.keywordsEnabled" />
                        </div>

                        <div v-if="step.key == 'company_book'">
                            <OverviewStatus v-if="!$parent.kycMetadata.shareholderBookProvided"
                                :title="__('company_shareholder_book')"
                                :text="__('ov_id_status_awaiting')"
                                :name="__('ov_book_control')"
                            />
                            <OverviewStatus v-if="$parent.kycMetadata.shareholderBookProvided"
                                :title="__('company_shareholder_book')"
                                :text="__('ov_book_attached').toUpperCase()"
                                :name="__('ov_book_control_awaiting_status')"
                            />
                        </div>

                        <div v-if="step.key == 'person_idc'">
                            <OverviewStatus
                                :title="'ID-kontroll'"
                                :text="'AVVENTER'"
                                :name="'fullførelse'"
                            />
                        </div>

                    </template>
                    <template v-slot:complete>
                        <!-- Completed steps -->

                        <div v-if="step.key == 'company_aml'">
                            <OverviewStatus completed
                                :title="__('company_aml_complete')"
                                :text="$parent.amlData && $parent.amlData.sanction ? $parent.amlData.sanction.message : ''"
                            />
                        </div>

                        <div v-if="step.key == 'company_se'">
                            <OverviewStatus completed
                                :title="__('company_se_complete')"
                                :text="__('company_se_complete_sub')"
                            />
                        </div>
                        <div v-if="step.key == 'company_uk'">
                            <OverviewStatus completed
                                :title="'Lookup company information'"
                                :text="'Company information fetched from Companies House'"
                            />
                        </div>
                        <div v-if="step.key == 'company_uk_checklist'">
                            <OverviewStatus completed
                                :title="'AML Checklist'"
                                :text="$parent.checklist.statusText"
                            />
                        </div>

                        <div v-if="step.key == 'company_kyc'">
                            <OverviewStatus completed
                                :title="__('company_kyc_complete')"
                                :text="__('ov_kyc_status_complete')"
                                :name="__('ov_kyc_status_by') + $parent.kycMetadata.signedNames"
                            />
                        </div>

                        <div v-if="step.key == 'company_per'">
                            <OverviewStatus completed
                                :title="__(step.key + '_pending')"
                                :text="__('ov_all_roles_complete')"
                            />
                        </div>

                        <div v-if="step.key == 'company_rep'">
                            <OverviewStatus completed
                                :title="__('company_final_report')"
                                :text="__('company_rep_complete')"
                            />
                        </div>



                        <!-- Deloitte -->
                        <div v-if="step.key == 'custom_imanage'">
                            <OverviewStatus completed
                                :title="'Lagre i iManage'"
                                :text="'Sluttrapport lagret i iManage'"
                            />
                        </div>
                        <div v-if="step.key == 'custom_forward'">
                            <OverviewStatus completed
                                :title="__(step.key + '_pending')"
                                :text="'Sendt'"
                            />
                        </div>

                        <div v-if="step.key == 'company_book'">
                            <OverviewStatus completed
                                :title="__('company_shareholder_book')"
                                :text="__('common_confirmed').toUpperCase()"
                                :name="__('ov_kyc_status_by') + $root.getEmployeeName($parent.customer.metadata.shBookData.userId)"
                            />
                            <label class="flw100" style="margin-top: 5px;">{{ $parent.customer.metadata.shBookData.comment }}</label>
                        </div>

                        <div v-if="step.key == 'snn_bank_kyc' || step.key == 'snn_bank_kyc2'">
                            <OverviewStatus completed
                                :title="'Egenerklæring'"
                                :text="__('common_confirmed').toUpperCase()"
                                :name="__('ov_kyc_status_by') + $root.getEmployeeName($parent.customer.metadata.uploadKycData.userId)"
                            />
                            <label class="flw100" style="margin-top: 5px;">{{ $parent.customer.metadata.uploadKycData.comment }}</label>
                        </div>

                        <div v-if="step.key == 'person_idc'">
                            <OverviewStatus completed
                                :title="'ID-kontroll'"
                                :text="'FULLFØRT'"
                                :name="'' + $parent.singlePerson.displayName + ($parent.singlePerson.info.ssn ? ' (' + $parent.singlePerson.info.ssn + ')' : '')"
                            />
                        </div>

                        <div v-if="step.key == 'person_pep'">
                            <OverviewStatus completed
                                :title="'PEP/Sanksjonssøk'"
                                :text="'UTFØRT'"
                            />
                            <div v-html="getStatusPEP($parent.singlePerson)" />
                        </div>

                        <div v-if="step.key == 'person_rep'">
                            <OverviewStatus completed
                                :title="__('company_final_report')"
                                :text="__('company_rep_complete')"
                            />
                        </div>

                        <div v-if="step.key == 'nb_reference'">
                            <OverviewStatus completed
                                :title="'Fullført'"
                                :text="'Fullført - ' + $parent.customer.ref"
                            />
                        </div>

                        <div v-if="step.key == 'suitability'">
                            <OverviewStatus completed
                                :title="'Kundeklassifisering/investeringsanbefaling'"
                                :text="'OPPRETTET'"
                                :name="'av ' + $parent.suit.userName"
                            />
                        </div>

                        <!-- KAR -->
                        <div v-if="step.key == 'company_kar'">
                            <OverviewStatus completed
                                :title="'KAR-oppslag'"
                                :text="$parent.karResult.message"
                                :name="''"
                            />
                        </div>

                    </template>
                    <template v-slot:aborted>
                        <!-- Aborted steps -->

                        <div v-if="step.key == 'company_aml'">
                            <OverviewStatus aborted
                                :title="__('company_aml_complete')"
                                :text="$parent.amlData && $parent.amlData.sanction ? $parent.amlData.sanction.message : ''"
                            />
                        </div>

                        <div v-if="step.key == 'company_kyc'">
                            <OverviewStatus aborted v-if="$parent.kycMetadata.aborted"
                                :title="__('kyc_form')"
                                :text="'AVBRUTT'"
                                :name="__('ov_kyc_status_by') + $parent.kycMetadata.aborter.displayName"
                            />
                            <OverviewStatus aborted v-if="$parent.kycMetadata.expired"
                                :title="__('kyc_form')"
                                :text="'FRIST UTLØPT'"
                                :name="formatDate('format', $parent.kycMetadata.expirationDate)"
                            />
                            <div style="margin-top: 5px; margin-bottom: 10px;" v-if="$parent.kycMetadata.aborted">
                                <label style="color: var(--text-weak);">{{ 'Kommentar: ' }}</label>
                                <label>{{ $parent.kycMetadata.aborter.comment }}</label>
                            </div>
                        </div>

                        <!-- KAR -->
                        <div v-if="step.key == 'company_kar'">
                            <OverviewStatus aborted
                                :title="'KAR-oppslag'"
                                :text="$parent.karResult.message"
                                :name="''"
                            />
                        </div>

                    </template>
                    <template v-slot:warning>
                        <!-- Warning steps -->

                        <div v-if="step.key == 'company_aml'">
                            <OverviewStatus warning
                                :title="__('company_aml_complete')"
                                :text="$parent.amlWarning"
                            />
                        </div>

                        <div v-if="step.key == 'company_uk_audit'">
                            <OverviewStatus warning
                                :title="'QA Review'"
                                :text="'This checklist has been randomly selected for QA review'"
                            />
                        </div>

                    </template>
                    <template v-slot:pending-action>
                        <!-- Pending actions -->

                        <div v-if="step.key == 'company_aml'">
                            <vap-button :fixedWidth="170" :text="__('search_perform')" @clicked="$emit('aml', true)" />
                        </div>

                        <div v-if="step.key == 'company_kyc'">
                            <vap-button :fixedWidth="170" :text="__('ov_kyc_goto')" @clicked="$emit('kyc', true)" :dotDisabled="($parent.customer.source == 'brreg' && !$parent.customer.aml) || $root.workingInBackground">
                                <template v-if="$parent.customer.source == 'brreg' && !$parent.customer.aml" v-slot:tooltip><vap-tooltip :text="__('ov_search_first')" /></template>
                            </vap-button>
                        </div>

                        <div v-if="step.key == 'company_kyc_form'">
                            <vap-button :fixedWidth="170" :text="__('ov_kyc_goto')" @clicked="$emit('form', 'kyc01')" :dotDisabled="($parent.customer.source == 'brreg' && !$parent.customer.aml) || $root.workingInBackground">
                                <template v-if="$parent.customer.source == 'brreg' && !$parent.customer.aml" v-slot:tooltip><vap-tooltip :text="__('ov_search_first')" /></template>
                            </vap-button>
                        </div>

                        <div v-if="step.key == 'company_per'">
                            <vap-button :fixedWidth="170" :text="__('ov_view_persons')" @clicked="goToTab('persons')" :dotDisabled="$parent.customer.source == 'brreg' && !$parent.customer.aml">
                                <template v-if="$parent.customer.source == 'brreg' && !$parent.customer.aml" v-slot:tooltip><vap-tooltip :text="__('ov_search_first')" /></template>
                            </vap-button>
                        </div>

                        <div v-if="step.key == 'company_rep'">
                            <vap-button :fixedWidth="170" :text="__('ov_create_report')" @clicked="goToTab('report')" :dotDisabled="($parent.customer.source == 'brreg' && !$parent.customer.aml) || (!$parent.shareholderBookConfirmed && $parent.kycMetadata.editorMadeChanges)">
                                <template v-slot:tooltip>
                                    <vap-tooltip :text="__('ov_search_first')" v-if="$parent.customer.source == 'brreg' && !$parent.customer.aml"  />
                                    <vap-tooltip :text="__('ov_book_control_awaiting')" v-if="!$parent.shareholderBookConfirmed && $parent.kycMetadata.editorMadeChanges"  />
                                </template>
                            </vap-button>
                        </div>

                        <!-- UK -->
                        <div v-if="step.key == 'company_uk_checklist'">
                            <vap-button :fixedWidth="170" :text="'Start'" @clicked="$emit('form', 'amlc01')" />
                        </div>
                        <div v-if="step.key == 'company_uk_audit'">
                            <vap-button :fixedWidth="170" :text="'Start'" />
                        </div>
                        <div v-if="step.key == 'company_uk_notify'">
                            <vap-button :fixedWidth="170" :text="'Notify'" />
                        </div>


                        <!-- Deloitte -->
                        <div v-if="step.key == 'custom_imanage'">
                            <vap-button :fixedWidth="170" :text="'Lagre'" @clicked="$emit('imanage')" :dotDisabled="!$parent.customer.report">
                                <template v-if="!$parent.customer.report" v-slot:tooltip><vap-tooltip :text="'Lag sluttrapport først'" /></template>
                            </vap-button>
                        </div>
                        <div v-if="step.key == 'custom_forward'">
                            <vap-button :fixedWidth="170" :text="'Velg mottaker'" @clicked="$emit('partner')" :dotDisabled="!$parent.customer.report">
                                <template v-if="!$parent.customer.report" v-slot:tooltip><vap-tooltip :text="'Lag sluttrapport først'" /></template>
                            </vap-button>
                        </div>

                        <!-- Bank + Regnskap -->
                        <div v-if="step.key == 'snn_bank_kyc' || step.key == 'snn_bank_kyc2'">
                            <vap-button :fixedWidth="170" :text="'Last opp'" @clicked="$parent.showDialog('upload-kyc')" />
                        </div>

                        <!-- Person -->
                        <div v-if="step.key == 'person_idp'">
                            <vap-button :fixedWidth="170" :text="'Send'" @clicked="showPersonIdpPopup" />
                        </div>

                        <div v-if="step.key == 'person_idc'">
                            <vap-button :fixedWidth="170" :text="__('common_options')" @clicked="$parent.showDialog('idc-options', $parent.singlePerson, $parent.singlePersonIdcIndex, true)" />
                        </div>
                        <div v-if="step.key == 'person_pep'">
                            <vap-button :fixedWidth="170" :text="__('search_perform')" @clicked="$parent.dialogs.pepSearch.show = true" />
                        </div>
                        <div v-if="step.key == 'person_rep'">
                            <vap-button :fixedWidth="170" :text="__('ov_create_report')" @clicked="goToTab('report')" />
                        </div>

                        <!-- Norges Bank -->
                        <div v-if="step.key == 'nb_reference'">
                            <vap-button :fixedWidth="170" :text="'Fullfør'" @clicked="$parent.showDialog('reference')" />
                        </div>

                        <!-- Borea -->
                        <div v-if="step.key == 'suitability'">
                            <vap-button :fixedWidth="170" :text="'Åpne'" @clicked="$emit('form', 'suita')" :dotDisabled="!$parent.kycMetadata.completed && !$parent.isPersonFlow">
                                <template v-if="!$parent.kycMetadata.completed && !$parent.isPersonFlow" v-slot:tooltip><vap-tooltip :text="'Egenerklæringskjema ikke fullført'" /></template>
                            </vap-button>
                        </div>

                        <!-- KAR -->
                        <div v-if="step.key == 'company_kar'">
                            <vap-button :fixedWidth="170" :text="'Start'" @clicked="$emit('kar')" />
                        </div>

                    </template>
                    <template v-slot:progress-action>
                        <!-- In progress actions -->

                        <div v-if="step.key == 'company_kyc'">
                            <vap-button :fixedWidth="170" :text="__('common_options')" @clicked="$emit('kycoptions')" />
                        </div>

                        <div v-if="step.key == 'company_per'">
                            <vap-button :fixedWidth="170" :text="__('ov_view_persons')" @clicked="goToTab('persons')" :dotDisabled="$parent.customer.source == 'brreg' && !$parent.customer.aml">
                                <template v-if="$parent.customer.source == 'brreg' && !$parent.customer.aml" v-slot:tooltip><vap-tooltip :text="'Hent selskapsdata fra BankID AML først'" /></template>
                            </vap-button>
                        </div>

                        <div v-if="step.key == 'company_book'">
                            <vap-button :fixedWidth="170" :text="__('common_options')" @clicked="$parent.showDialog('book-options')" />
                        </div>

                        <div v-if="step.key == 'person_idc'">
                            <vap-button :fixedWidth="170" :text="__('common_options')" @clicked="$parent.showDialog('idc-options', $parent.singlePerson)" />
                        </div>

                    </template>
                    <template v-slot:complete-action>
                        <!-- Completed actions -->

                        <div v-if="step.key == 'company_aml'">
                            <vap-button :fixedWidth="170" :text="__('show_receipt')" @clicked="handleReceiptClick($parent.customer.aml.envelopeId, 'aml')" :dotDisabled="$root.workingInBackground" />
                        </div>
                        <div v-if="step.key == 'company_se'">
                            <vap-button :fixedWidth="170" :text="__('show_receipt')" @clicked="handleReceiptClick($parent.customer.aml.envelopeId, 'aml')" />
                        </div>

                        <div v-if="step.key == 'company_uk'">
                            <vap-button :fixedWidth="170" :text="__('show_receipt')" @clicked="handleReceiptClick($parent.customer.aml.envelopeId, 'aml')" />
                        </div>
                        <div v-if="step.key == 'company_uk_checklist'">
                            <vap-button :fixedWidth="170" :text="__('show_receipt')" @clicked="handleReceiptClick($parent.checklist.data.envelopeId, 'check')" />
                        </div>

                        <div v-if="step.key == 'company_kyc'">
                            <vap-button :fixedWidth="170" :text="__('common_options')" @clicked="$emit('kycoptions')" />
                        </div>

                        <div v-if="step.key == 'company_per'">
                            <vap-button :fixedWidth="170" :text="__('ov_view_persons')" @clicked="goToTab('persons')" />
                        </div>

                        <div v-if="step.key == 'company_rep'">
                            <vap-button :fixedWidth="170" :text="__('common_options')" @clicked="$parent.showDialog('rep-options')" />
                        </div>

                        <div v-if="step.key == 'custom_imanage'">
                            <vap-button :fixedWidth="170" :text="'Lagre på nytt'" @clicked="$emit('imanage')" />
                        </div>
                        <div v-if="step.key == 'custom_forward'">
                            <vap-button :fixedWidth="170" :text="'Velg mottaker'" @clicked="$emit('partner')" />
                        </div>

                        <div v-if="step.key == 'company_book'">
                            <vap-button :fixedWidth="170" :text="__('common_options')" @clicked="$parent.showDialog('book-options')" />
                        </div>

                        <div v-if="step.key == 'snn_bank_kyc' || step.key == 'snn_bank_kyc2'">
                            <vap-button :fixedWidth="170" :text="__('show_receipt')" @clicked="$parent.showReceipt3($parent.customer.metadata.uploadKycData.envelopeId, 'Egenerklæring', true);" />
                        </div>

                        <div v-if="step.key == 'person_idc'">
                            <vap-button :fixedWidth="170" :text="__('common_options')" @clicked="$parent.showDialog('idc-options', $parent.singlePerson)" />
                        </div>
                        <div v-if="step.key == 'person_pep'">
                            <vap-button :fixedWidth="170" :text="__('show_receipt')" @clicked="handleReceiptClick($parent.singlePerson.search.envelopeId, 'pep')" />
                        </div>
                        <div v-if="step.key == 'person_rep'">
                            <vap-button :fixedWidth="170" :text="__('ov_show_report')" @clicked="handleReceiptClick($parent.customer.report.envelopeId, 'rep')" />
                        </div>

                        <!-- Norges Bank -->
                        <div v-if="step.key == 'nb_reference'">
                            <vap-button :fixedWidth="170" :text="'Endre'" @clicked="$parent.showDialog('reference')" />
                        </div>

                        <div v-if="step.key == 'suitability'">
                            <vap-button :fixedWidth="170" :text="'Åpne'" @clicked="handleReceiptClick($parent.suit.envelopeId, 'suit')" />
                        </div>

                        <!-- KAR -->
                        <div v-if="step.key == 'company_kar'">
                            <vap-button :fixedWidth="170" :text="__('common_options')" @clicked="$emit('karopt')" />
                        </div>

                    </template>
                    <template v-slot:aborted-action>
                        <!-- Aborted actions -->

                        <div v-if="step.key == 'company_aml'">
                            <vap-button :fixedWidth="170" :text="__('show_receipt')" @clicked="handleReceiptClick($parent.customer.aml.envelopeId, 'aml')" :dotDisabled="$root.workingInBackground" />
                        </div>

                        <div v-if="step.key == 'company_kyc'">
                            <vap-button :fixedWidth="170" :text="'Send på nytt'" @clicked="$emit('resendkyc', true)" />
                        </div>

                        <!-- KAR -->
                        <div v-if="step.key == 'company_kar'">
                            <vap-button :fixedWidth="170" :text="__('common_options')" @clicked="$emit('karopt')" />
                        </div>

                    </template>
                </OverviewStep>
            </div>
        </template>
        <template v-slot:right>

            <vap-label header :text="__('events_last')" style="margin-top: 20px; margin-bottom: 10px;" />

            <vap-card>
                <vap-eventlog :events="events" />
            </vap-card>

            <div v-if="$root.config.portal.migrate">
                <vap-label header :text="'Migrering'" style="margin-top: 30px; margin-bottom: 10px;" />

                <div v-if="!$parent.customer.report" style="float: left; color: var(--text-weak); margin-top: 12px; margin-left: 10px; margin-bottom: 16px;">
                    <label>{{ 'Sluttrapport må opprettes før kunden kan migreres!' }}</label>
                </div>

                <vap-card v-if="$parent.customer.report" noPadding style="margin-bottom: 10px;">
                    <vap-item fancy fancyCircle padding20 :fancyIcon="'upload'"
                        :fancyText="'Migrer kunde'"
                        :fancyHint="'Kunden vil oppdatert og flyttet til nye kunder'"
                        @clicked="$emit('migrate')">
                    </vap-item>
                </vap-card>
            </div>

            <vap-label header :text="__('previous_reports')" style="margin-top: 30px; margin-bottom: 10px;" />

            <div v-if="archivedReports.length == 0" style="float: left; color: var(--text-weak); margin-top: 12px; margin-left: 10px; margin-bottom: 16px;">
                <label>{{ __('ov_archive_empty') }}</label>
            </div>

            <div v-if="archivedReports.length > 0">
                <vap-card compactList noPadding v-for="(report, i) in archivedReports" :key="'ar' + i">
                    <vap-item @clicked="handleReceiptClick2(report.envelopeId, 'rep')">
                        <div class="ar-base">
                            <div class="ar-icon" :class="[{'ar-icon-3': report.risk == 3}, {'ar-icon-5': report.risk == 5}]">
                                <vap-icon tiny :icon="'report'" :color="getArchiveColor(report.risk)" />
                            </div>
                            <div class="ar-content">
                                <div class="ar-header">
                                    <label>{{ formatDate('format', report.date) }}</label>
                                </div>
                                <div class="ar-info">
                                    <div class="ar-name">
                                        <label>{{ report.user.name }}</label>
                                    </div>
                                    <div class="ar-risk" v-if="$root.config.report.riskAssessment">
                                        <label>{{ getArchiveRisk(report.risk) }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vap-item>
                </vap-card>
            </div>

            <vap-card noPadding v-if="$parent.customer.report">
                <vap-item float @clicked="resetPopup = true">
                    <div class="common-add">
                        <div class="common-add-icon">
                            <svg height="32" version="1.1" width="32" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.55);">
                                <path d="M14.5 1.5C14.5 0.7 15.2 0 16 0C16.8 0 17.5 0.7 17.5 1.5V30.5C17.5 31.3 16.8 32 16 32C15.2 32 14.5 31.3 14.5 30.5V1.5Z"/>
                                <path d="M30.5 14.5C31.3 14.5 32 15.2 32 16C32 16.8 31.3 17.5 30.5 17.5H1.5C0.7 17.5 0 16.8 0 16C0 15.2 0.7 14.5 1.5 14.5H30.5Z"/>
                            </svg>
                        </div>
                        <div class="common-add-text">
                            <label>{{ __('ov_archive_new') }}</label>
                        </div>
                    </div>
                </vap-item>
            </vap-card>

        </template>
    </vap-form-divider>

    <ve-popup v-if="resetPopup" :header="__('customer_archive')" :width="400" @close="resetPopup = false" hideCloseButton>
        <div style="padding: 20px;">
            <label>{{ __('customer_archive_prompt') }}</label><br><br>
            <label>{{ __('customer_archive_hint') }}</label>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_no')" uppercase @clicked="resetPopup = false" />
                <vap-button :text="__('common_yes')" uppercase @clicked="showArchiveDialog" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="personIdpPopup.show" :header="'Send invitasjon til ID-kontroll'" :width="450" @close="personIdpPopup.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <div>
                <vap-row>
                    <vap-group c12>
                        <vap-label desc :text="__('common_email')" />
                        <vap-input v-model="personIdpPopup.email" :rules="'required'" @input="validatePersonIdp" />
                    </vap-group>
                </vap-row>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="personIdpPopup.show = false" />
                <vap-button :text="'Send'" uppercase @clicked="sendPersonIdp" :disabled="!personIdpPopup.valid" :working="personIdpPopup.working" />
            </vap-button-row>
        </div>
    </ve-popup>

    

</div>
</template>

<script>
import OverviewStep from './OverviewStep';
import OverviewPending from './OverviewPending';
import OverviewStatus from './OverviewStatus';

import validations from '../../validations';
import personhelper from '../../helpers/personhelper';

export default {
    name: 'OverviewNew',
    components: {
        OverviewStep,
        OverviewPending,
        OverviewStatus
    },
    props: {
        loading: {
            type: Boolean,
            default: true
        },
        flow: {
            type: Object,
            default: () => { return {} }
        },
        events: {
            type: Array,
            default: () => { return [] }
        },
        archivedReports: {
            type: Array,
            default: () => { return [] }
        },
    },
    data() {
        return {
            resetPopup: false,
            personIdpPopup: { show: false, valid: false, working: false, email: '' },
            bardata: {}
            
        }
    },
    methods: {
        showPersonIdpPopup() {
            this.personIdpPopup.show = true;
        },
        validatePersonIdp() {
            const result = validations.validateEmail(this.personIdpPopup.email);
            this.personIdpPopup.valid = result.valid;
        },
        sendPersonIdp() {
            this.$emit('ipd', envelopeId, type);
        },
        handleReceiptClick(envelopeId, type) {
            this.$emit('receipt', envelopeId, type);
        },
        handleReceiptClick2(envelopeId, type) {
            this.$emit('receiptt', envelopeId, type);
        },
        goToTab(key) {
            this.$emit('tab', key);
        },
        getArchiveRisk(risk) {
            switch (risk) {
                case 1: return 'Lav risiko';
                case 3: return 'Medium risiko';
                case 5: return 'Høy risiko';
            }
            return risk;
        },
        getArchiveColor(risk) {
            if (!this.$root.config.report.riskAssessment) return 'var(--success-text)';
            switch (risk) {
                case 1: return 'var(--success-text)';
                case 3: return 'var(--warning-text)';
                case 5: return 'var(--error-text)';
            }
        },
        showArchiveDialog(risk) {
            this.$emit('reset', true);
            this.resetPopup = false;
        },
        getStatusPEP(person) {
            return personhelper.getStatusPEP(person, this.$root);
        },
    },
    async created() {
        
    }
}
</script>
<style scoped>

.ov-weak {
    color: var(--text-weak);
}

.ar-base {
    display: flex;
    padding: 8px 13px;
}
.ar-icon {
    width: 36px; height: 36px;
    padding-top: 2px;
    padding-left: 3px;
    border-radius: 50%;;
    background-color: var(--success-background);
    box-sizing: border-box;
}
.ar-icon-3 {
    background-color: var(--warning-background);
}
.ar-icon-5 {
    background-color: var(--error-background);
}
.ar-content {
    margin-left: 15px;
}
.ar-header {
    font-size: 17px;
}
.ar-info {
    display: flex;
}
.ar-name {
    font-size: 15px;
    color: var(--text-weak);
}
.ar-risk {
    margin-left: 10px;
    font-size: 15px;
    color: var(--text-weak);
}


.ov-bg-load {
    position: absolute;
    width: 800px;
    height: 40px;
    top: 10px;
    right: 0px;
    text-align: right;
    padding-top: 10px;
    padding-right: 50px;
    font-size: 17px;
    font-weight: bold;
    color: var(--text-accent);
}

</style>