<template>
<div class="fbinp-check">

    <div v-if="!options" class="fbinp-check-i" @click="handleClick">

        <div class="fbinp-check-v">
            <CheckboxUI :checked="value" />
        </div>
        <div class="fbinp-check-t">
            {{ text }}
        </div>

    </div>

    <div v-if="options">
        <div v-for="(option, key) in options" :key="key" class="fbinp-check-i" @click="handleClick(key)">

            <div class="fbinp-check-v">
                <CheckboxUI :checked="value[key]" />
            </div>
            <div class="fbinp-check-t">
                {{ option.label }}
            </div>

        </div>
    </div>

</div>
</template>
<script>
import CheckboxUI from '../CheckboxUI.vue';

export default {
    name: 'DesignerInput',
    components: {
        CheckboxUI,
    },
    props: {
        value: { type: [Boolean, Object], default: false },
        text: { type: String, default: '' },
        options: { type: Object, default: null },
        radio: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
    methods: {

        handleClick(key) {
            if (this.disabled) return;
            if (this.options) {
                let val = {};
                if (this.radio) {
                    for (let k in this.options) val[k] = false;
                    val[key] = true;
                }else {
                    for (let k in this.options) val[k] = this.value[k];
                    val[key] = !this.value[key];
                }
                this.$emit('input', val);
            }else {
                this.$emit('before');
                this.$emit('input', !this.value);
            }
        },

    },
}
</script>
<style scoped>

.fbinp-check {
    width: 100%;
    cursor: pointer;
}

.fbinp-check-i {
    display: flex;
    color: var(--fb-text-2);
    border: solid 1px var(--fb-input-bg);
    background-color: var(--fb-input-bg);
    box-sizing: border-box;
}

.fbinp-check-v {

}

.fbinp-check-t {
    padding-top: 3px;
    padding-left: 7px;
    font-size: 15px;
}

</style>