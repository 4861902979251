var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vap-input",class:[
        {'vap-input-combine': _vm.combine},
        {'vap-input-disabled': _vm.disabled},
        {'vap-input-dotted': _vm.dotted},
        {'vap-input-actions': _vm.actions},
        {'vap-input-numeric': _vm.numeric},
        {'val-ok': _vm.validation.valid && !_vm.noHighlight},
        {'val-error': !_vm.validation.valid},
        {'val-show': _vm.validation.show} ],on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave,"mouseup":function($event){return _vm.handleOptionMouseUp($event)}}},[_c('input',{staticClass:"vip",attrs:{"id":'inpid' + _vm.id,"placeholder":_vm.placeholder,"type":_vm.password ? 'password' : _vm.numeric ? 'number' : '',"inputmode":_vm.numeric ? 'numeric' : '',"pattern":_vm.numeric ? '[0-9]*' : '',"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":_vm.handleInput,"keydown":_vm.handleKeyDown}}),(_vm.actions)?_c('div',{staticStyle:{"display":"flex","margin-right":"-5px","margin-left":"5px"}},[_vm._t("actions")],2):_vm._e(),(_vm.validation.show && _vm.validation.valid && !_vm.noValidation)?_c('svg',{staticClass:"val-ic-ok",attrs:{"height":"24","width":"24"}},[_c('path',{attrs:{"d":"M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"}})]):_vm._e(),(_vm.validation.show && !_vm.validation.valid)?_c('svg',{staticClass:"val-ic-error",attrs:{"height":"24","width":"24"}},[_c('path',{attrs:{"d":"M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"}})]):_vm._e(),(_vm.validation.show && !_vm.validation.valid)?_c('div',{staticClass:"val-message vip-error"},[_c('label',[_vm._v(_vm._s('* ' + _vm.__(_vm.validation.key)))])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }