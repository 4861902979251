<template>
    <div class="editable">
        <template>
            <span @keydown="keydown" class="value" ref="value">{{ this.value }}</span>
            <span v-if="editable && !editing" @click="edit" class="edit"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 24 24" style="fill:#777; margin-bottom: -1px">    <path d="M 19.171875 2 C 18.448125 2 17.724375 2.275625 17.171875 2.828125 L 16 4 L 20 8 L 21.171875 6.828125 C 22.275875 5.724125 22.275875 3.933125 21.171875 2.828125 C 20.619375 2.275625 19.895625 2 19.171875 2 z M 14.5 5.5 L 3 17 L 3 21 L 7 21 L 18.5 9.5 L 14.5 5.5 z"></path></svg></span>
            <span v-if="editable && editing" @click="save" style="cursor: pointer;"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="15" height="15" viewBox="0 0 407.096 407.096" style="fill:#777; margin-bottom: -1px; margin-left: 10px;" xml:space="preserve"> <g> <g> <path d="M402.115,84.008L323.088,4.981C319.899,1.792,315.574,0,311.063,0H17.005C7.613,0,0,7.614,0,17.005v373.086 c0,9.392,7.613,17.005,17.005,17.005h373.086c9.392,0,17.005-7.613,17.005-17.005V96.032 C407.096,91.523,405.305,87.197,402.115,84.008z M300.664,163.567H67.129V38.862h233.535V163.567z"/> <path d="M214.051,148.16h43.08c3.131,0,5.668-2.538,5.668-5.669V59.584c0-3.13-2.537-5.668-5.668-5.668h-43.08 c-3.131,0-5.668,2.538-5.668,5.668v82.907C208.383,145.622,210.92,148.16,214.051,148.16z"/> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg> </span>
            <span v-if="editable && editing" @click="cancel" style="cursor: pointer"><svg width="25px" height="25px" viewBox="0 0 24 24" style="fill:#777; margin-bottom: -6px; margin-left: 5px;" xmlns="http://www.w3.org/2000/svg"><path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"/></svg></span>
        </template>
    </div>
</template>

<script>
export default {
    name: "EditableText",
    props: {
        text: {
            type: String,
        },
        editable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            value: "",

            editing: false,
        }
    },
    methods: {
        edit() {
            this.$refs["value"].contentEditable = true;
            this.$refs["value"].focus();
            this.editing = true;
        },
        save() {
            this.$refs["value"].contentEditable = false;
            this.editing = false;
            this.value = this.$refs["value"].innerHTML;
            this.$emit("save", this.value)
        },
        cancel() {
            this.$refs["value"].contentEditable = false;
            this.$refs["value"].innerHTML = this.value;
            this.editing = false;
        },
        keydown(e) {
            if(e.keyCode == 13) {
                this.save();
                e.preventDefault()
            } else if(e.keyCode == 27) {
                this.cancel();
                e.preventDefault();
            }
        }
    },
    created() {
        this.value = this.text;
    }
}
</script>

<style scoped>
.editable {
    display: inline-block;
}

.editable:hover > .edit {
    display: inline-block;
}

.edit {
    display: none;
    cursor: pointer;
}

.value {
    padding-right: 5px;
}
</style>