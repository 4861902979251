<template>
<div>

    <div class="flw100" style="margin-top: 20px; color: var(--text-title);">
        {{ __t('monitor-select') + ':' }}
    </div>

    <div class="amlv1-cs-monitors">
        <div class="amlv1-cs-monitor" v-for="(monitor, i) in monitors" :key="'m' + i" @click.stop="onMonitorClick(monitor)" :class="[
            {'amlv1-cs-monitor-a': monitor.enabled },
        ]">
            <div class="amlv1-cs-monitor-top">
                <div class="amlv1-cs-monitor-cb">
                    <CheckboxUI :checked="monitor.enabled" />
                </div>
                <div class="amlv1-cs-monitor-name">
                    {{ monitor.name }}
                </div>
                <div class="amlv1-cs-monitor-logo">
                    <img :src="getLogo(monitor)" />
                </div>
            </div>
            <div class="amlv1-cs-monitor-desc">
                {{ getDescription(monitor) }}
            </div>
        </div>
    </div>

    <div class="flw100" style="margin-top: 10px; color: var(--text-title);" v-if="removeCount > 0">
        {{ __t('monitor-action-remove') + getMonitorCountText(removeCount) }}
    </div>
    <div class="flw100" style="margin-top: 10px; color: var(--text-title);" v-if="addCount > 0 && !(this.$root.monitors.list && this.$root.monitors.list.length == 1)">
        {{ __t('monitor-action-add') + getMonitorCountText(addCount) }}
    </div>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import monitorHelper from '../v1monitor/monitorHelper';

import CheckboxUI from '../components/form/designer/ui/CheckboxUI.vue';

export default {
    name: 'MonitorSelector',
    components: {
        CheckboxUI,
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            monitors: [],
            oldMonitors: [],
            addCount: 0,
            removeCount: 0,
        }
    },
    methods: {

        getMonitorCountText(count) {
            let str = ' ' + count + ' ';
            if (count == 1) {
                str += this.__t('monitor-action-monitor');
            }else {
                str += this.__t('monitor-action-monitors');
            }
            return str;
        },

        getDescription(monitor) {
            return monitorHelper.getSingleTemplate(monitor.template).display;
        },

        getLogo(monitor) {
            switch (monitor.template) {
                case 'bankid-eu-un': return this.$root.images.bamltiny;
            }
            return monitor.enabled ? this.$root.images.vfwhite : this.$root.images.vfblue;
        },

        onMonitorClick(monitor) {
            monitor.enabled = !monitor.enabled;
            this.validateChanges();
        },

        validateChanges() {
            let hasChanges = false;
            let addCount = 0;
            let removeCount = 0;
            for (const newMonitor of this.monitors) {
                if (newMonitor.enabled) {
                    if (!this.oldMonitors.includes(newMonitor.id)) {
                        hasChanges = true;
                        addCount += 1;
                    }
                }
            }
            for (const oldMonitorId of this.oldMonitors) {
                let match = false;
                for (const newMonitor of this.monitors) {
                    if (newMonitor.enabled && newMonitor.id == oldMonitorId) {
                        match = true;
                    }
                }
                if (!match) {
                    hasChanges = true;
                    removeCount += 1;
                }
            }
            this.addCount = addCount;
            this.removeCount = removeCount;

            const monitorIds = [];
            for (const i in this.monitors) {
                if (this.monitors[i].enabled) {
                    monitorIds.push(this.monitors[i].id);
                }
            }

            this.$emit('changed', hasChanges, addCount, monitorIds);
        },

    },
    async created() {
        if (this.$root.monitors.list) {

            for (const i in this.$root.monitors.list) {
                
                const monitor = {
                    id: this.$root.monitors.list[i].id,
                    name: this.$root.monitors.list[i].name,
                    template: this.$root.monitors.list[i].template,
                    enabled: false,
                };

                if (this.params.person.monitors && this.params.person.monitors.includes(monitor.id)) {
                    monitor.enabled = true;
                    this.oldMonitors.push(monitor.id);
                }

                if (this.$root.monitors.list.length == 1 && !monitor.enabled) {
                    monitor.enabled = true;
                }

                this.monitors.push(monitor);
            }

        }

        this.validateChanges();
        this.loading = false;
    }
}
</script>
<style scoped>

.amlv1-cs-monitors {
    float: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: -10px;
}

.amlv1-cs-monitor {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--page-background);
    box-sizing: border-box;
    cursor: pointer;
}

.amlv1-cs-monitor-top {
    display: flex;
}

.amlv1-cs-monitor-cb {
    width: 40px;
}

.amlv1-cs-monitor-name {
    flex-grow: 100;
    padding-top: 1px;
    font-size: 18px;
    color: var(--text-title);
}

.amlv1-cs-monitor-logo {
    flex-shrink: 0;
}

.amlv1-cs-monitor-logo img {
    max-height: 30px;
    max-width: 120px;
}

.amlv1-cs-monitor-desc {
    margin-top: 0px;
    padding-left: 40px;
    color: var(--text-weak);
}

.amlv1-cs-monitor-a {
    background-color: var(--success-background);
}

.amlv1-cs-monitor-a .amlv1-cs-monitor-name {
    color: var(--success-text);
}

.amlv1-cs-monitor-a .amlv1-cs-monitor-desc {
    color: var(--success-text);
    opacity: 0.7;
}

</style>