<template>
<div>

    <!-- Add step dialog -->
    <PopupV1 :header="'Add step'" @close="onClose" :dialog="addStepDialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ 'Add from' + ':' }}
            </div>
            <DialogContent class="flw100" style="padding-top: 20px;">
                <VFCheckbox radio v-model="sourceModel" :options="sourceOptions" @input="validateDialog" />
            </DialogContent>
            <DialogContent class="flw100" style="margin-top: 20px; padding-top: 30px;" v-if="sourceModel.existing">
                <VFRow style="margin-bottom: -10px;">
                    <VFGroup c12>
                        <VFDropdown v-model="selectedDefault" :options="defaultSteps" :displayKey="'display'" :listKey="'display'" :outputKey="'value'" @input="validateDialog" />
                    </VFGroup>
                    <VFGroup c12>
                        <VFCheckbox newDesign v-model="copyModel" :options="copyOptions" @input="validateDialog" />
                    </VFGroup>
                </VFRow>
            </DialogContent>
            <DialogContent class="flw100" style="margin-top: 20px; padding-top: 30px;" v-if="sourceModel.none">
                <VFRow style="margin-bottom: -10px;">
                    <VFGroup c12>
                        <VFInput v-model="customKey" :placeholder="'Key'" @input="validateDialog" />
                    </VFGroup>
                    <VFGroup c12>
                        <VFInput v-model="customName" :placeholder="'Name'" @input="validateDialog" />
                    </VFGroup>
                </VFRow>
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="addStepDialog.working" />
            <VFButton :text="'Add step'" :icon="'add'" @clicked="onAddStep" :working="addStepDialog.working" :disabled="!addStepDialog.valid" />
        </template>
    </PopupV1>
  
</div>
</template>

<script>
import defaultFlows from './defaultFlows';
import flowEditHelper from './flowEditHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFInput from '../components/form/inputs/VFInput.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import VFDropdown from '../components/form/inputs/VFDropdown.vue';
import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';

export default {
    name: 'AddStepDialog',
    components: {
        PopupV1,
        VFInput,
        VFCheckbox,
        VFDropdown,
        VFRow,
        VFGroup,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
    },
    props: {
        flow: { type: Object, default: null }
    },
    data() {
        return {
            sourceModel: {
                none: true,
                custom: false,
                existing: false
            },
            sourceOptions: {
                none: { label: 'New step' },
                custom: { label: 'Existing custom step' },
                existing: { label: 'Default step' }
            },
            copyModel: {
                yes: false
            },
            copyOptions: {
                yes: { label: 'Make a customizable copy of the step' }
            },

            defaultSteps: [],
            customSteps: [],

            selectedDefault: '',
            customKey: '',
            customName: '',

            addStepDialog: { show: false, valid: false }
        }
    },
    methods: {

        validateDialog() {
            if (this.sourceModel.none) {

                if (this.customKey && this.customKey.length > 2 && this.customName && this.customName.length > 2) {
                    this.addStepDialog.valid = true;
                }else {
                    this.addStepDialog.valid = false;
                }

            }else if (this.sourceModel.existing) {

                this.addStepDialog.valid = this.selectedDefault != '';

            }else {
                this.addStepDialog.valid = false;
            }
        },

        onClose() {
            this.$emit('close');
        },

        onAddStep() {

            if (this.sourceModel.existing) {

                const step = JSON.parse(JSON.stringify(defaultFlows.getStep(this.selectedDefault)));

                if (this.copyModel.yes) {
                    step.key = step.key + '-custom';
                    step.isDefault = false;
                    this.$root.flowConfig.steps.push(step);
                }

                this.flow.steps.push({ key: step.key });

            }else if (this.sourceModel.custom) {

            }else {

                const step = flowEditHelper.getEmptyStep(this.customKey, this.customName);
                this.$root.flowConfig.steps.push(step);
                this.flow.steps.push({ key: step.key });

            }

            this.$emit('add');
            this.onClose();
        },


    },
    created() {
        
        const defaultSteps = defaultFlows.getSteps();
        for (const i in defaultSteps) {
            const step = defaultFlows.getStep(defaultSteps[i]);
            this.defaultSteps.push({
                value: step.key,
                display: step.name
            });
        }

    }
}
</script>