<template>
<div class="vf-dd" :class="[
    {'vf-expanded' : expanded},
    {'vf-disabled': disabled},
    {'vf-dd-dotted': dotted},
    {'vf-dd-designer': designer},
    {'vf-dd-invalid': validation.done && !validation.valid },
]" v-on-clickaway="hideDropdown" tabindex="0" @focus="onFocus" @blur="onBlur" @keydown="handleKeyDown" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" @mouseup="handleOptionMouseUp($event)">
    <div class="vf-dd-value" @click="handleValueClick">
        <label v-if="value">{{ objectValue }}</label>
        <label v-if="!value" class="vf-dd-placeholder">{{ placeholder }}</label>
    </div>
    <div v-if="expanded" class="vf-dd-list" id="ddScroll">

        <div v-show="!searching" class="vf-dd-item" v-for="(option, i) in commonOptions" :key="'c' + i" @click="handleCommonClick(i)" :class="{'vf-dd-selected': isSelected(i, true)}">
            <label>{{ getListDisplayCommon(i) }}</label>
        </div>

        <div v-if="searching" class="vf-dd-search">
            <input v-model="searchString" />
        </div>

        <div class="vf-dd-sep" />

        <div v-show="!searching" class="vf-dd-item" v-for="(option, i) in options" :key="i" @click="handleItemClick(i)" :class="{'vf-dd-selected': isSelected(i, false)}">
            <label>{{ getListDisplay(i) }}</label>
        </div>

        <div v-show="searching" class="vf-dd-item" v-for="(option, i) in searchOptions" :key="'s'+i" @click="handleSearchClick(i)" :class="{'vf-dd-selected': isSelected(i)}">
            <label>{{ getSearchDisplay(i) }}</label>
        </div>

    </div>
    <svg height="26" width="26" class="vf-dd-arrow">
        <path v-if="expanded" d="M 13 10.59375 L 21.765625 19.179688 C 22.15625 19.5625 22.78125 19.558594 23.171875 19.171875 L 24.707031 17.636719 C 25.097656 17.242188 25.097656 16.609375 24.703125 16.21875 L 13.707031 5.292969 C 13.511719 5.097656 13.257813 5 13 5 C 12.742188 5 12.488281 5.097656 12.292969 5.292969 L 1.296875 16.21875 C 0.902344 16.609375 0.902344 17.242188 1.292969 17.636719 L 2.828125 19.171875 C 3.21875 19.558594 3.84375 19.5625 4.234375 19.179688 Z"/>
        <path v-if="!expanded" d="M 13 15.40625 L 21.765625 6.820313 C 22.15625 6.4375 22.78125 6.441406 23.171875 6.828125 L 24.707031 8.363281 C 25.097656 8.757813 25.097656 9.390625 24.703125 9.78125 L 13.707031 20.707031 C 13.511719 20.902344 13.257813 21 13 21 C 12.742188 21 12.488281 20.902344 12.292969 20.707031 L 1.296875 9.78125 C 0.902344 9.390625 0.902344 8.757813 1.292969 8.363281 L 2.828125 6.828125 C 3.21875 6.441406 3.84375 6.4375 4.234375 6.820313 Z"/>
    </svg>

</div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
    name: 'vf-language',
    mixins: [clickaway],
    props: {
        value: [String, Number],

        locales: { type: Array, default: () => { return [] }},

        /* options: { type: Array, default: () => { return [] } },
        commonOptions: { type: Array, default: () => { return [] } }, */

        disabled: { type: Boolean, default: false },
        placeholder: { type: String, default: 'Nothing selected' },

        /* displayKey: { type: String, default: 'display' },
        outputKey: { type: String, default: 'value' },
        listKey: {  type: String, default: 'display' },
        listKey2: { type: String, default: '' }, */
        
        required: { type: Boolean, default: false },
        noValidation: { type: Boolean, default: false },
        noHighlight: { type: Boolean, default: false },

        validation: { type: Object, default: () => { return { done: false } }},
        dotted: { type: Boolean, default: false },

        id: { type: String, default: 'id' },
        designer: { type: Boolean, default: false },
        activeLogic: { type: Boolean, default: false },
    },
    data() {
        return {
            expanded: false,
            searching: false,
            searchString: '',
            focusDelay: false,
            searchOptions: [],
            selectedIndex: 0,
            options: [],
            commonOptions: [],
            displayKey: 'en_EN',
            outputKey: 'key',
            listKey: 'en_EN',
            listKey2: '',
        }
    },
    computed: {
        objectValue: function () {
            return this.getTitleFromValue(this.value);
        }
    },
    watch: {
        value: {
            deep: false,
            handler(val) {
                /* if (!this.validation.show && !this.noValidation) this.validation.show = true;
                this.validate(val); */
            }
        },
        searchString: {
            deep: false,
            handler(val) {
                this.selectedIndex = 0;
                if (val.length > 0) {
                    this.searchOptions = [];
                    let str = val.toLowerCase();
                    for (let i in this.options) {
                        for (let key in this.options[i]) {
                            let opt = this.options[i][key].toString().toLowerCase();
                            if (opt.includes(str)) {
                                this.searchOptions.push(this.options[i]);
                                break;
                            }
                        }
                    }
                    this.searching = true;
                }else {
                    this.searching = false;
                }
            }
        }
    },
    methods: {

        validate(value) {
            if (this.noValidation) return;
            if (!value) value = this.value;
            //console.log('validating', value);
            //this.validation = {...this.validation, ...validations.validateRules(value, this.rules)};
        },

        onFocus() {
            if (this.disabled || this.designer) return;
            this.searchString = '';
            this.selectedIndex = 0;
            this.expanded = true;
            const self = this;
            self.focusDelay = true;
            setTimeout(function() {
                self.focusDelay = false;
            }, 200);
        },

        onBlur() {
            if (this.disabled || this.designer) return;
            this.expanded = false;
        },

        handleKeyDown(event) {
            if (this.disabled || this.designer) return;
            if (event.keyCode == 38) {
                this.selectedIndex -= 1;
                this.addScroll();
            }else if (event.keyCode == 40) {
                this.selectedIndex += 1;
                this.addScroll();
            }else if (event.keyCode == 13) {
                this.selectItem();
            }else if (event.keyCode == 8) {
                if (this.searchString.length > 0) this.searchString = this.searchString.substring(0, this.searchString.length - 1);
            }else {
                if ('abcdefghijklmnopqrstuvwxyzæøåABCDEFGHIJKLMOPQRSTUVWXYZÆØÅ0123456789 '.includes(event.key)) {
                    this.searchString = this.searchString + event.key;
                }
            }
        },

        handleValueClick() {
            if (this.disabled || this.designer) return;
            this.selectedIndex = 0;
            this.searchString = '';
            if (!this.focusDelay) this.expanded = !this.expanded;
        },

        handleItemClick(index) {
            this.expanded = false;
            this.$emit('input', this.options[index][this.outputKey]);
        },

        handleSearchClick(index) {
            this.expanded = false;
            this.$emit('input', this.searchOptions[index][this.outputKey]);
        },

        handleCommonClick(index) {
            this.expanded = false;
            this.$emit('input', this.commonOptions[index][this.outputKey]);
        },

        hideDropdown() {
            this.expanded = false;
        },

        getTitleFromValue(value) {
            for (let i in this.options) {
                if (this.options[i][this.outputKey] == value) {
                    return this.options[i][this.displayKey];
                }
            }
            return '';
        },

        getListDisplay(index) {
            if (this.listKey) {
                if (this.listKey2) {
                    return this.options[index][this.listKey] + ' ' + this.options[index][this.listKey2];
                }else {
                    return this.options[index][this.listKey];
                }
            }else {
                return this.options[index].value;
            }
        },

        getSearchDisplay(index) {
            if (this.listKey) {
                if (this.listKey2) {
                    return this.searchOptions[index][this.listKey] + ' ' + this.searchOptions[index][this.listKey2];
                }else {
                    return this.searchOptions[index][this.listKey];
                }
            }else {
                return this.searchOptions[index].value;
            }
        },

        getListDisplayCommon(index) {
            if (this.listKey) {
                if (this.listKey2) {
                    return this.commonOptions[index][this.listKey] + ' ' + this.commonOptions[index][this.listKey2];
                }else {
                    return this.commonOptions[index][this.listKey];
                }
            }else {
                return this.commonOptions[index].value;
            }
        },

        isSelected(index, common) {
            if (common) {
                if (index == this.selectedIndex) {
                    return true;
                }
            }else if (this.searching) {
                if (index == this.selectedIndex) {
                    return true;
                }
            }else {
                if (index == this.selectedIndex - this.commonOptions.length) {
                    return true;
                }
            }
            return false;
        },
        
        selectItem() {
            if (this.selectedIndex < 0) return;
            if (this.searching) {
                this.handleSearchClick(this.selectedIndex);
                return;
            }
            if (this.selectedIndex < this.commonOptions.length) {
                this.handleCommonClick(this.selectedIndex);
                return;
            }
            if (this.selectedIndex >= this.commonOptions.length) {
                this.handleItemClick(this.selectedIndex - this.commonOptions.length);
            }
        },

        addScroll() {
            let elem1 = document.getElementById('ddScroll');
            let s = document.getElementsByClassName("vap-dd-selected");
            if (s.length == 0) return;
            elem1.scrollTop = s[0].offsetTop - 180;
        },


        //#region Designer / Logic --------------------------------------------------------------------------------------------

        handleMouseEnter(e) {
            this.$emit('connect-enter', this.id);
        },

        handleMouseLeave(e) {
            this.$emit('connect-leave', this.id);
        },

        handleOptionMouseUp(e) {
            if (this.activeLogic) {
                this.$emit('connect', this.id);
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        getOptions() {
            const list = [];

            if (this.locales.length == 0) {
                list.push({
                    key: 'en_EN',
                    alpha2: 'en',
                    name: 'English',
                });
            }

            for (const i in this.locales) {
                if (this.locales[i].enabled) {
                    list.push({
                        key: this.locales[i].key,
                        alpha2: this.locales[i].alpha2,
                        name: this.locales[i].name,
                    });
                }
            }
            
            return list;
        },

    },
    created() {

        this.options = this.getOptions();
        this.displayKey = 'name';
        this.listKey = 'name';

        if (this.options.length > 0) {
            if (typeof this.options[0] == 'object') {
                this.objects = true;
            }
        }

        for (const i in this.options) {
            if (this.options[i].key == this.$root.config.params.defaultLanguage) {
                this.handleItemClick(i);
            }
        }

    }
}
</script>
<style scoped>

.vf-dd {
    float: left;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.vf-dd:focus {
    outline: none;
}
.vf-dd:focus .vap-dd-value {
    border: solid 1px var(--input-focus);
}

.vf-dd-value {
    width: 100%;
    padding: 8px 12px;
    padding-top: 10px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: var(--card-background);

    height: 40px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: var(--input-text);
    border-radius: 4px;
    border: solid 1px var(--input-border);
}
.vf-dd-value label {
    cursor: pointer;
}
.vf-dd-placeholder {
    color: var(--text-disabled);
}
.vf-dd-value:hover {
    background-color: rgb(245, 245, 245);
    border-color: var(--input-focus);
}

.vf-dd-arrow {
    position: absolute;
    top: 7px;
    right: 8px;
    transform: scale(0.7);
    pointer-events: none;
}
.vf-dd-arrow path {
    fill: var(--input-label);
}

.vf-expanded .vf-dd-value {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
}

.vf-dd-list {
    position: absolute;
    width: 100%;
    min-height: 20px;
    max-height: 500px;
    box-sizing: border-box;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: solid 1px lightgray;
    background-color: white;
    z-index: 100;
    overflow: auto;
}


.vf-dd-item {
    padding: 8px 14px;
    cursor: pointer;
}
.vf-dd-item label {
    cursor: pointer;
}
.vf-dd-item:hover {
    background-color: lightgray;
}
.vf-dd-selected {
    color: var(--accent-contrast);
    background-color: var(--accent);
}


.vf-dd-sep {
    height: 1px;
    background-color: lightgray;
}

.vf-dd-search {
    padding: 10px 6px;
    box-sizing: border-box;
}
.vf-dd-search input {
    width: 100%;
    font-size: 16px;
    padding: 4px 6px;
    box-sizing: border-box;
}


.vf-disabled .vf-dd-value {
    background-color: lightgray;
    cursor: not-allowed;
}
.vf-disabled label {
    cursor: not-allowed;
}
.vf-disabled .vf-dd-value:hover {
    border-color: var(--input-border);
}



/* --- Validation -------------------- */

.vf-dd-invalid .vf-dd-value {
    border-color: var(--val-error);
}

/* ----------------------------------- */



/* --- Design Mode ------------------- */

.vf-dd-designer .vf-dd-value {
    cursor: default;
}
.vf-dd-designer .vf-dd-value label {
    cursor: default;
}

.vf-dd-dotted .vf-dd-value {
    border: dotted 2px var(--input-border);
}

.logic .vf-dd:hover .vf-dd-value {
    border: solid 3px #0DCC27;
}

/* ----------------------------------- */

</style>