import { render, staticRenderFns } from "./MassSendout.vue?vue&type=template&id=abc77240&scoped=true&"
import script from "./MassSendout.vue?vue&type=script&lang=js&"
export * from "./MassSendout.vue?vue&type=script&lang=js&"
import style0 from "./MassSendout.vue?vue&type=style&index=0&id=abc77240&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abc77240",
  null
  
)

export default component.exports