<template>
<div>

    <vap-row>

        <vap-group c6>
            <vap-label desc :text="'Egnethetstesten er utført av'" />
            <vap-label value :text="envelope.content.sender.givenName + ' ' + envelope.content.sender.familyName" />
        </vap-group>
        <vap-group c6>
            <vap-label desc :text="'Dato for egnethetstesten er'" />
            <vap-label value :text="formatDate('format', envelope.content.created)" />
        </vap-group>

    </vap-row>

</div>
</template>

<script>

export default {
    name: 'SuitabilityAssessment',
    components: {
        
    },
    props: {
        envelope: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            
        }
    },
    watch: {
        
    },
    methods: {
        


    },
    async created() {
        console.log('envelope', this.envelope);
    }
}
</script>
<style scoped>



</style>
