<template>
<div class="frmod-checkout">

    <ve-popup v-if="params.type == 'connect'" :header="'Connect to checklist module'" :width="500" @close="$emit('close')" >
        <div style="padding: 12px 10px;">
            
            <vap-item fancy padding20
                :fancyIcon="'open'"
                :fancyText="'Final validation'"
                :fancyHint="'This will trigger the final validation'"
                @clicked="onConnect('fval')"
            />

        </div>
    </ve-popup>

</div>
</template>
<script>

export default {
    name: "frmod-checkout",
    props: {
        params: { type: Object, default: () => { return { type: '' } }}
    },
    data() {
        return {
            
        }
    },
    watch: {
        width: {
            deep: false,
            handler(val) {
                
            }
        }
    },
    methods: {
        
        onConnect(type) {
            console.log('params', this.params);

            if (!this.params.fromItem.logic) this.params.fromItem.logic = {};

            if (type == 'fval') {

                if (!this.params.fromItem.logic.link) this.params.fromItem.logic.link = [];

                this.params.fromItem.logic.link.push({
                    type: 'chk',
                    link: type,
                    key: ''
                });

            }

            this.$emit('close');
        }

    },
    mounted() {
        
    }
}

</script>
<style>


</style>
