<template>
<div>

    <PopupV1 v-if="dialog.show" :header="__t('ams-name')" :width="800" :preventClosing="!disabled" @close="onClose" :dialog="dialog">
        <template v-slot:content>
<!--             <div style="margin-bottom: 10px; color: var(--text-title);" v-if="!disabled">
                {{ 'Please complete the following checklist' + ':' }}
            </div> -->

            <div class="amd-s">

                <div class="amd-s-name">
                    {{ params.name }}
                </div>
                <div class="amd-s-button" @click="doSearch" v-if="!disabled">
                    <vap-icon :icon="'search'" />
                </div>

            </div>

            <div class="amd-split">
                <div class="amd-left">
                    <div class="amd-item" v-for="(keyword, i) in listLeft" :key="'amk' + i">
                        <div class="amd-item-key" :class="[
                            {'amd-item-key-done': keyword.done },
                            {'amd-item-key-disabled': keyword.done },
                        ]">
                            {{ keyword.display }}
                        </div>
                        <div class="amd-item-hits nohl" @click="onHitClick(keyword)" :class="[
                            {'aml-item-hit-s': keyword.result == 'ok' },
                            {'aml-item-hit-w': keyword.result == 'some' },
                            {'aml-item-hit-e': keyword.result == 'hits' },
                            {'aml-item-hit-disabled': disabled },
                        ]">
                            {{ getHitText(keyword) }}
                        </div>
                        <div class="amd-item-icon" v-if="!disabled" @click="onSearchClicked(keyword)" :class="[
                            {'amd-item-icon-done': keyword.done }
                        ]">
                            <vap-icon small :icon="'search'" absolute :color="'ext'" />
                        </div>
                    </div>
                </div>
                <div class="amd-right">
                    <div class="amd-item" v-for="(keyword, i) in listRight" :key="'amk' + i">
                        <div class="amd-item-key" :class="[
                            {'amd-item-key-done': keyword.done },
                            {'amd-item-key-disabled': keyword.done },
                        ]">
                            {{ keyword.display }}
                        </div>
                        <div class="amd-item-hits nohl" @click="onHitClick(keyword)" :class="[
                            {'aml-item-hit-s': keyword.result == 'ok' },
                            {'aml-item-hit-w': keyword.result == 'some' },
                            {'aml-item-hit-e': keyword.result == 'hits' },
                            {'aml-item-hit-disabled': disabled },
                        ]">
                            {{ getHitText(keyword) }}
                        </div>
                        <div class="amd-item-icon" v-if="!disabled" @click="onSearchClicked(keyword)" :class="[
                            {'amd-item-icon-done': keyword.done }
                        ]">
                            <vap-icon small :icon="'search'" absolute :color="'ext'" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="amd-comments-hint" v-if="!disabled">
                <label>{{ __t('ams-comment-hint') }}</label>
            </div>

            <div class="amd-comments">
                <TextEditorV2 :content="comment" :placeholder="__t('common-comments')" @input="onCommentInput" :disabled="disabled" />
            </div>

            <div class="amd-user" v-if="disabled">
                {{ __t('pdf-performed-by') + ' ' + completedUser + __t('ams-on') + completedDate }}
            </div>
            
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-close')" outline @clicked="onClose" :disabled="dialog.working" v-if="disabled" />
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" v-if="!disabled" />
            <VFButton :text="__t('common-save')" :icon="'save'" @clicked="onSave" :working="dialog.working" v-if="!disabled" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import TextEditorV2 from '../components/form/TextEditorV2.vue';

export default {
    name: 'AdverseMediaDialog',
    components: {
        PopupV1,
        VFButton,
        TextEditorV2,
    },
    props: {
        params: { type: Object, default: null },
        isCompany: { type: Boolean, default: false },
    },
    data() {
        return {
            dialog: { show: true, working: false },
            listLeft: [],
            listRight: [],
            comment: '',
            disabled: false,
            completedDate: '',
            completedUser: ''
        }
    },
    methods: {

        onSearchClicked(keyword) {
            for (const i in this.listLeft) {
                if (this.listLeft[i].key == keyword.key) {
                    this.listLeft[i].done = true;
                }
            }
            for (const i in this.listRight) {
                if (this.listRight[i].key == keyword.key) {
                    this.listRight[i].done = true;
                }
            }
        },

        doSearch() {

            let baseUrl = 'https://www.google.no/search?q=';
            let locale = this.$root.config.params.defaultLanguage;

            if (locale == 'sv_SE') {
                baseUrl = 'https://www.google.se/search?q=';
            }

            let url = baseUrl;
            for (const i in this.$root.config.adverseMedia.keywords) {
                let keyword = this.$root.config.adverseMedia.keywords[i].locale[locale];
                keyword = keyword.replace('ø', '%C3%B8');
                keyword = keyword.replace('å', '%C3%A5');
                if (i > 0) url += '+OR+';
                url += '%22' + keyword + '%22';
            }
            url += '+%3A+';
            url += this.params.name.toLowerCase().split(' ').join('+');
            url += '&hl=no';

            console.log(url);

            for (const i in this.listLeft) {
                this.listLeft[i].done = true;
                this.listLeft[i].result = 'ok';
            }
            for (const i in this.listRight) {
                this.listRight[i].done = true;
                this.listRight[i].result = 'ok';
            }

            window.open(url, '_blank').focus();
        },

        onHitClick(keyword) {
            if (this.disabled) return;
            if (keyword.result == 'na') {
                keyword.result = 'ok';
            }else if (keyword.result == 'ok') {
                if (this.$root.config.adverseMedia.hitsOnly) {
                    keyword.result = 'hits';
                }else {
                    keyword.result = 'some';
                }
            }else if (keyword.result == 'some') {
                keyword.result = 'hits';
            }else {
                keyword.result = 'na';
            }
        },

        getHitText(keyword) {
            if (keyword.result == 'na') {
                return this.__t('ams-not-completed');
            }else if (keyword.result == 'ok') {
                return this.__t('ams-ok-short');
            }else if (keyword.result == 'some') {
                return this.__t('ams-unclear-short');
            }else {
                return this.__t('ams-hits-short');
            }
        },

        onCommentInput(input) {
            this.comment = input;
        },

        async onSave() {
            const model = {
                done: true,
                completed: true,
                keywords: [],
                hitLevel: '',
                comment: this.comment
            };
            let highestLevel = 'na';
            for (const i in this.listLeft) {
                model.keywords.push({
                    key: this.listLeft[i].key,
                    locale: 'en_EN',
                    done: this.listLeft[i].done,
                    result: this.listLeft[i].result,
                });
                if (!this.listLeft[i].done || this.listLeft[i].result == 'na') model.completed = false;
                if (this.listLeft[i].result == 'ok' && highestLevel == 'na') highestLevel = 'ok';
                if (this.listLeft[i].result == 'some' && (highestLevel == 'ok' || highestLevel == 'na')) highestLevel = 'some';
                if (this.listLeft[i].result == 'hits' && (highestLevel == 'some' || highestLevel == 'ok' || highestLevel == 'na')) highestLevel = 'hits';
            }
            for (const i in this.listRight) {
                model.keywords.push({
                    key: this.listRight[i].key,
                    locale: 'en_EN',
                    done: this.listRight[i].done,
                    result: this.listRight[i].result,
                });
                if (!this.listRight[i].done || this.listRight[i].result == 'na') model.completed = false;
                if (this.listRight[i].result == 'ok' && highestLevel == 'na') highestLevel = 'ok';
                if (this.listRight[i].result == 'some' && (highestLevel == 'ok' || highestLevel == 'na')) highestLevel = 'some';
                if (this.listRight[i].result == 'hits' && (highestLevel == 'some' || highestLevel == 'ok' || highestLevel == 'na')) highestLevel = 'hits';
            }
            model.hitLevel = highestLevel;

            console.log('model', model);

            this.dialog.working = true;

            if (this.isCompany) {
                if (this.params.shouldReset) {
                    await v1api.resetAdverseMediaOrg(this.params.id);
                }
                await v1api.saveAdverseMediaOrg(this.params.id, model);
            }else {
                if (this.params.shouldReset) {
                    await v1api.resetAdverseMedia(this.params.id);
                }
                await v1api.saveAdverseMedia(this.params.id, model);
            }

            const response = await v1api.getClient(this.params.clientId);

            this.dialog.working = false;

            this.$emit('saved', response);
            this.onClose();
        },

        onClose() {
            this.$emit('close');
        },

        getExistingValue(key) {
            if (!this.params.web || !this.params.web.done) return null;
            for (const i in this.params.web.keywords) {
                if (this.params.web.keywords[i].key == key) {
                    return this.params.web.keywords[i];
                }
            }
            return null;
        },

    },
    created() {
        let locale = this.$root.config.params.defaultLanguage;
        /* for (const i in this.$root.config.adverseMedia.languages) {
            locale = this.$root.config.adverseMedia.languages[i];
        } */

        for (const i in this.$root.config.adverseMedia.keywords) {
            let existing = null;
            if (!this.params.shouldReset) existing = this.getExistingValue(this.$root.config.adverseMedia.keywords[i].key)
            if (i % 2) {
                this.listRight.push({
                    key: this.$root.config.adverseMedia.keywords[i].key,
                    display: this.$root.config.adverseMedia.keywords[i].locale[locale],
                    done: existing ? existing.done : false,
                    result: existing ? existing.result : 'na',
                });
            }else {
                this.listLeft.push({
                    key: this.$root.config.adverseMedia.keywords[i].key,
                    display: this.$root.config.adverseMedia.keywords[i].locale[locale],
                    done: existing ? existing.done : false,
                    result: existing ? existing.result : 'na',
                });
            }
        }
        if (this.params.web && this.params.web.done && !this.params.shouldReset) {
            this.comment = this.params.web.comment;
        }
        if (this.params.web && this.params.web.completed && !this.params.shouldReset) {
            this.disabled = true;
            this.completedDate = this.formatDate('format', this.params.web.date);
            this.completedUser = this.getEmployeeName(this.params.web.userId);
        }

    }
}
</script>
<style scoped>

.amd-s {
    float: left;
    width: 100%;
    min-height: 34px;
    display: flex;
    border-radius: 5px;
    border: solid 1px var(--input-border);
}

.amd-s-name {
    flex-grow: 100;
    padding-top: 9px;
    padding-left: 10px;
    font-weight: 500;
    color: var(--text-title);
}

.amd-s-button {
    width: 80px;
    flex-shrink: 0;
    padding-left: 20px;
    border-left: solid 1px var(--input-border);
    background-color: var(--disabled-background);
    box-sizing: border-box;
    cursor: pointer;
}
.amd-s-button:hover {
    background-color: var(--item-hover);
}


.amd-split {
    margin-top: 10px;
    float: left;
    width: 100%;
}
.amd-left {
    float: left;
    width: 50%;
    padding-right: 5px;
    box-sizing: border-box;
}
.amd-right {
    float: left;
    width: 50%;
    padding-left: 5px;
    box-sizing: border-box;
}


.amd-item {
    height: 26px;
    display: flex;
    margin-bottom: 5px;
    border-radius: 4px;
    border: solid 1px var(--input-border);
}

.amd-item-key {
    flex-grow: 100;
    padding: 4px 5px;
    background-color: var(--disabled-background);
}
.amd-item-key-done {
    color: var(--success-text);
    background-color: var(--success-background);
}

.amd-item-hits {
    width: 140px;
    padding: 4px 5px;
    color: var(--text-weak);
    border-left: solid 1px var(--input-border);
    background-color: var(--disabled-background);
    cursor: pointer;
}

.aml-item-hit-s {
    color: var(--success-text);
    background-color: var(--success-background);
}
.aml-item-hit-w {
    color: var(--warning-text);
    background-color: var(--warning-background);
}
.aml-item-hit-e {
    color: var(--error-text);
    background-color: var(--error-background);
}

.aml-item-hit-disabled {
    cursor: initial;
}

.amd-item-icon {
    flex-shrink: 0;
    width: 40px;
    padding-left: 10px;
    fill: var(--text-title);
    border-left: solid 1px var(--input-border);
    background-color: var(--disabled-background);
    cursor: pointer;
}
.amd-item-icon-done {
    fill: var(--success-text);
    color: var(--success-text);
    background-color: var(--success-background);
}
.amd-item-icon:hover {
    background-color: var(--item-hover);
}
.amd-item-icon-done:hover {
    background-color: var(--success-background);
}


.amd-comments-hint {
    float: left;
    width: 100%;
    position: relative;
}
.amd-comments-hint label {
    position: absolute;
    top: 20px;
    right: 2px;
}
.amd-comments {
    float: left;
    width: 100%;
    margin-top: 10px;
}


.amd-user {
    float: left;
    width: 100%;
    margin-top: 2px;
    padding-left: 1px;
    font-size: 15px;
    color: var(--text-title);
}

</style>