<template>
    <div>

        <ve-popup v-if="reminder.show" :header="__('ov_send_reminder')" :width="600" @close="reminder.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c6>
                            <vap-label desc :text="__('common_first_name')" />
                            <vap-input v-model="reminder.firstName" :rules="'required'" @input="validateReminder" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="__('common_last_name')" />
                            <vap-input v-model="reminder.lastName" :rules="'required'" @input="validateReminder" />
                        </vap-group>
                        <vap-group c12>
                            <vap-label desc :text="__('common_email')" />
                            <vap-input v-model="reminder.email" :rules="'required|email'" @input="validateReminder" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="reminder.show = false" />
                    <vap-button :text="__('ov_send_reminder')" uppercase @clicked="sendReminder" :disabled="!reminder.valid" :working="reminder.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="idcOptions.show" :header="__('person_id')" :width="500" @close="idcOptions.show = false" >
            <div style="padding: 8px 0px;">
                <vap-item fancy padding20 :fancyIcon="'help-o'"
                    @hide="idcOptions.show = false"
                    :fancyText="__('ov_kyc_send_out')"
                    @clicked="onShowSendIDC"
                    :disabled="idcOptions.person.control.inviteSent || idcOptions.person.control.completed"
                />
                <vap-item fancy padding20 :fancyIcon="'help-o'"
                    @hide="idcOptions.show = false"
                    :fancyText="__('persons_id_manual')"
                    @clicked="onShowManualIDC"
                    :disabled="idcOptions.person.control.inviteSent || idcOptions.person.control.completed"
                />
                <vap-item fancy padding20 :fancyIcon="'help-o'"
                    @hide="idcOptions.show = false"
                    :fancyText="__('show_receipt')"
                    @clicked="handleReceiptClick(idcOptions.person.control.envelopeId, 'idc')"
                    :disabled="!idcOptions.person.control.completed || idcOptions.person.control.method == 'manual'"
                />
                <vap-item fancy padding20 :fancyIcon="'reminder'"
                    @hide="idcOptions.show = false"
                    :fancyText="__('ov_send_reminder')"
                    @clicked="showPersonIDCReminder"
                    :disabled="idcOptions.person.control.completed || !idcOptions.person.control.inviteSent"
                />
                <vap-item fancy padding20 :fancyIcon="'delete'"
                    @hide="idcOptions.show = false"
                    :fancyText="__('ov_kyc_reset')"
                    @clicked="idcReset.show = true"
                    :disabled="!idcOptions.person.control.completed && !idcOptions.person.control.inviteSent"
                />
            </div>
        </ve-popup>

        <ve-popup v-if="idcSend.show" :header="__('persons_id_send')" :width="600" @close="idcSend.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c6>
                            <vap-label desc :text="__('common_first_name')" required />
                            <vap-input v-model="idcSend.firstName" :rules="'required'" @input="validatePersonIDCPopup" noValidation />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="__('common_last_name')" required />
                            <vap-input v-model="idcSend.lastName" :rules="'required'" @input="validatePersonIDCPopup" noValidation />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="__('common_email')" required />
                            <vap-input v-model="idcSend.email" :rules="'required|email'" :placeholder="__('common_required')" @input="validatePersonIDCPopup" noHighlight />
                        </vap-group>
                        <vap-group c6>
                            <vap-label :text="__('common_language')" desc required />
                            <LanguageDropdown v-model="idcSend.language" :placeholder="__('common_required')" noHighlight noValidation @input="onGreetingChange" />
                        </vap-group>
                        <vap-group c12 v-if="idcOptions.select">
                            <vap-label desc :text="'Type ID-kontroll'" required />
                            <vap-dropdown v-model="idcSend.type" :rules="'required'" :options="idcTypeOptions" :displayKey="'display'" :listKey="'display'" :outputKey="'value'" @input="validatePersonIDCPopup" noHighlight />
                        </vap-group>
                        <vap-group c12 v-if="$root.config.idcs[idcIndex].smsEnabled">
                            <vap-label desc :text="__('common_phone')" :required="idcSend.phoneRequired" />
                            <vap-phone v-model="idcSend.phone" :rules="idcSend.phoneRequired ? 'required' : ''" @input="validatePersonIDCPopup" noValidation />
                        </vap-group>
                        <vap-group c12 v-if="$root.config.idcs[idcIndex].smsEnabled">
                            <vap-label desc :text="__('sms_notification')" />
                            <vap-checkbox v-model="idcSend.useSms" :text="__('sms_notification_use')" @input="validatePersonIDCPopup" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <label>{{ idcGreeting }}</label>
                        </vap-group>
                        <div v-if="$root.config.idcs[idcIndex].smsEnabled" class="flw100" style="text-align: right; padding-right: 12px; margin-top: 16px;">
                        <label>{{ __(idcSend.useSms ? 'sms_notification_info_sms' : 'sms_notification_info_email') }}</label>
                    </div>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="idcSend.show = false" />
                    <vap-button :text="__('persons_id_send')" uppercase @clicked="onSendIDC" :disabled="!idcSend.valid" :working="idcSend.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="idcManual.show" :header="__('persons_id_manual')"
            :width="400" hideCloseButton
            @enterkey="onPerformManualIDC"
            @close="idcManual.show = false">
            <div style="padding: 20px;">
                <div>
                    <label>{{ __('persons_id_manual_hint') }}</label>
                </div>
                <div style="margin-top: 20px;">
                    <vap-row>
                        <vap-group c12>
                            <vap-label desc :text="__('common_ssn')" />
                            <vap-input v-model="idcManual.ssn" :placeholder="__('common_optional')" />
                        </vap-group>
                        <vap-group c12>
                            <vap-label desc :text="__('report_comment')" />
                            <vap-input v-model="idcManual.comment" :placeholder="__('common_optional')" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="idcManual.show = false" />
                    <vap-button :text="__('common_complete')" uppercase @clicked="onPerformManualIDC" :working="idcManual.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="idcReset.show" :header="'Tilbakestille ID-kontroll?'" :width="400" @close="idcReset.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>Er du sikker på at du vil tilbakestille ID-kontrollen?</label><br><br>
                <!-- <label>Status/filter vil bli oppdatert og du vil kunne sende ut et nytt skjema til en ny mottaker på et senere tidspunkt.</label> -->
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="'Nei'" uppercase @clicked="idcReset.show = false" />
                    <vap-button :text="'Ja'" uppercase @clicked="onResetIDC()" :working="idcReset.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="dialogs.pepSearch.show" :header="'Utfør PEP/Sanksjonssøk'" :width="300" @close="dialogs.pepSearch.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>Det vil gjøres et PEP/Sanksjonssøk med BankID AML</label><br><br>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="'Avbryt'" uppercase @clicked="dialogs.pepSearch.show = false" />
                    <vap-button :text="'Søk'" uppercase @clicked="performPepSearch" :working="dialogs.pepSearch.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="upgradeOrg.show" :header="__('common_add') + ' ' + __('company_orgnr')" :width="600" @close="upgradeOrg.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <CompanyInputs v-if="upgradeOrg.brregData.name" v-model="upgradeOrg.brregData" simple :editMode="false" />
                    <div v-if="!upgradeOrg.brregData.name">
                        <vap-label :text="__('customer_brreg_hint')" desc />
                        <ve-brreg-search @company-selected="upgradeOrg.brregData = $event" />
                    </div>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="upgradeOrg.show = false" />
                    <vap-button :text="__('common_add')" uppercase @clicked="performUpgradeOrg" :disabled="!upgradeOrg.brregData.name" :working="upgradeOrg.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="bookOptions.show" :header="__('company_shareholder_book')" :width="500" @close="bookOptions.show = false" >
            <div style="padding: 8px 0px;">
                <vap-item v-if="!$parent.shareholderBookMultiple" fancy padding20 :fancyIcon="'report'"
                    :fancyText="__('ov_book_show')"
                    @clicked="onShowShareholderBook"
                    :disabled="!$parent.kycMetadata.shareholderBookProvided && !$parent.shareholderBookUploaded">
                </vap-item>
                <div v-if="$parent.shareholderBookMultiple">
                    <vap-item fancy padding20 :fancyIcon="'report'" v-for="(atc, i) in $parent.kycMetadata.shareholderBooks" :key="'atcb' + i"
                        :fancyText="__('ov_book_show') + ' ' + (i+1) + ' av ' + $parent.kycMetadata.shareholderBooks.length"
                        @clicked="onShowShareholderBook(atc.name)"
                        :disabled="!$parent.kycMetadata.shareholderBookProvided && !$parent.shareholderBookUploaded">
                    </vap-item>
                </div>
                <vap-item fancy padding20 :fancyIcon="'check'"
                    :fancyText="__('common_confirm') + ' ' + __('company_shareholder_book').toLowerCase()"
                    @clicked="showConfirmShareholderBookDialog"
                    :disabled="$parent.shareholderBookConfirmed">
                </vap-item>
            </div>
        </ve-popup>

        <ve-popup v-if="confirmShareholderBook.show" :header="__('common_confirm') + ' ' + __('company_shareholder_book').toLowerCase()" :width="500" @close="confirmShareholderBook.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c12>
                            <vap-label :text="__('report_comment')" desc />
                            <vap-textarea v-model="confirmShareholderBook.comment" @input="validateShareholderBookConfirmPopup" />
                        </vap-group>
                        <vap-group c12>
                            <vap-file-upload v-model="confirmShareholderBook.files"
                                :header="'Last opp aksjeeierbok (valgfritt)'"
                                :helpText="'Dra og slipp aksjeeierbok her eller'"
                                :uploadText="'velg fil'"
                                :singleFile="true"
                                :autoPreview="false"
                            />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="confirmShareholderBook.show = false" />
                    <vap-button :text="__('common_confirm')" uppercase @clicked="onConfirmShareholderBook" :disabled="!confirmShareholderBook.valid" :working="confirmShareholderBook.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="repOptions.show" :header="__('company_final_report')" :width="500" @close="repOptions.show = false" >
            <div style="padding: 8px 0px;">
                <vap-item fancy padding20 :fancyIcon="'help-o'"
                    @hide="repOptions.show = false"
                    :fancyText="__('show_receipt')"
                    @clicked="$parent.showReceipt($parent.customer.report.envelopeId, 'rep')"
                    :disabled="false"
                />
                <vap-item fancy padding20 :fancyIcon="'delete'"
                    @hide="repOptions.show = false"
                    :fancyText="__('common_reset')"
                    @clicked="repReset.show = true"
                    :disabled="false"
                />
            </div>
        </ve-popup>

        <ve-popup v-if="repReset.show" :header="'Tilbakestille sluttrapport?'" :width="400" @close="repReset.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>Er du sikker på at du vil tilbakestille sluttrapporten?</label><br><br>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="'Nei'" uppercase @clicked="repReset.show = false" />
                    <vap-button :text="'Ja'" uppercase @clicked="onResetReport" :working="repReset.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="uploadKyc.show" :header="'Last opp egenerklæring'" :width="500" @close="uploadKyc.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c12>
                            <vap-label :text="__('report_comment')" desc />
                            <vap-textarea v-model="uploadKyc.comment" @input="validateUploadKycDialog" />
                        </vap-group>
                        <vap-group c12>
                            <vap-file-upload v-model="uploadKyc.files"
                                :header="'Last opp egenerklæring'"
                                :helpText="'Dra og slipp egenerklæring her eller'"
                                :uploadText="'velg fil'"
                                :singleFile="true"
                                :autoPreview="false"
                                @input="validateUploadKycDialog"
                            />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="uploadKyc.show = false" />
                    <vap-button :text="__('common_confirm')" uppercase @clicked="onUploadKyc" :disabled="!uploadKyc.valid" :working="uploadKyc.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="setReference.show" :header="'Fullfør kunde'" :width="600" @close="setReference.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c12>
                            <vap-label desc :text="'Referansenummer'" />
                            <vap-input v-model="setReference.number" :rules="'required'" @input="validateReference" />
                        </vap-group>
                        <vap-group c12>
                            <vap-label desc :text="'Tag'" />
                            <vap-dropdown v-model="setReference.tag"
                                :options="referenceOptions"
                                :listKey="'display'"
                                :displayKey="'display'" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="setReference.show = false" />
                    <vap-button :text="'OK'" uppercase @clicked="onSetReference" :disabled="!setReference.valid" :working="setReference.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="changePerson.show" :header="'Endre person'" :width="600" @close="changePerson.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c6>
                            <vap-label desc :text="__('common_first_name')" required />
                            <vap-input v-model="changePerson.firstName" :rules="'required'" @input="validateChangePerson" noValidation :placeholder="__('common_required')" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="__('common_last_name')" required />
                            <vap-input v-model="changePerson.lastName" :rules="'required'" @input="validateChangePerson" noValidation :placeholder="__('common_required')" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label :text="__('common_dob')" desc />
                            <vap-datepicker v-model="changePerson.dateOfBirth" outputISO @input="validateChangePerson" noValidation :placeholder="__('common_optional')" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label :text="__('common_citizenship')" desc />
                            <vap-dropdown v-model="changePerson.country" noValidation
                                :options="$root.countriesJson"
                                :commonOptions="$root.countriesCommon"
                                :placeholder="__('common_optional')"
                                :listKey="$root.locale.key"
                                :displayKey="$root.locale.key"
                                :outputKey="'key'"
                                @input="validateChangePerson" />
                        </vap-group>
                        <vap-group c6 v-if="$root.config.portal.referenceNumber">
                            <vap-label desc :text="__('common_email')" />
                            <vap-input v-model="changePerson.email" :rules="'required'" @input="validateChangePerson" noValidation :placeholder="__('common_optional')" />
                        </vap-group>
                        <vap-group c6 v-if="$root.config.portal.referenceNumber">
                            <vap-label desc :text="__('common_phone')" />
                            <vap-phone v-model="changePerson.phone" :rules="'required'" @input="validateChangePerson" noValidation :placeholder="__('common_optional')" />
                        </vap-group>
                        <vap-group c12 v-if="$root.config.portal.referenceNumber">
                            <vap-label desc :text="__('customer_reference_short')" />
                            <vap-input v-model="changePerson.reference" :rules="'required'" @input="validateChangePerson" noValidation :placeholder="__('common_optional')" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="changePerson.show = false" />
                    <vap-button :text="'Endre'" uppercase @clicked="onChangePerson" :disabled="!changePerson.valid" :working="changePerson.working" />
                </vap-button-row>
            </div>
        </ve-popup>

    </div>
</template>

<script>
import api from '../../portalapi';
import tools from '../../tools';
import validations from '../../validations';
import verified from '../../verified';
import receipts from '../../receipts';
import locale from '../../locale';
import filestore from '../../filestore';
import personhelper from '../../helpers/personhelper';

import CompanyInputs from '../../components/kyc/CompanyInputs';
import LanguageDropdown from '../../components/special/LanguageDropdown';

export default {
    name: 'OverviewDialogs',
    components: {
        CompanyInputs,
        LanguageDropdown
    },
    props: {
        dialogs: {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            reminder: { show: false, working: false, firstName: '', lastName: '', email: '', envelopeId: '', recipientId: '', valid: false, changed: false },
            reminderOriginal: { firstName: '', lastName: '', email: '' },
            upgradeOrg: { show: false, working: false, brregData: {} },
            bookOptions: { show: false },
            confirmShareholderBook: { show: false, working: false, valid: false, comment: '', files: {} },

            idcIndex: 0,
            idcOptions: { show: false, person: undefined },
            idcSend: { show: false, working: false, valid: false, firstName: '', lastName: '', email: '', language: 'nb_NO', type: 'idc02', phone: { prefix: '+47', number: '' }, useSms: false, phoneRequired: false },
            idcManual: { show: false, ssn: '', comment: '', working: false, index: 0 },
            idcReset: { show: false, working: false },
            idcTranslations: null,
            idcTranslationsWorking: false,
            idcGreeting: '',
            idcGreetingAction: '',

            repOptions: { show: false },
            repReset: { show: false, working: false },

            uploadKyc: { show: false, working: false, valid: false, comment: '', files: {} },

            setReference: { show: false, working: false, valid: false, number: '', tag: 'Avventer utbetaling' },

            changePerson: { show: false, working: false, valid: false, firstName: '', lastName: '', dateOfBirth: '', country: '', reference: '', email: '', phone: { prefix: '+47', number: '' } },

            referenceOptions: [
                { value: 'Avventer utbetaling', display: 'Avventer utbetaling' },
                { value: 'Utbetalt', display: 'Utbetalt' },
                { value: 'Avbrutt', display: 'Avbrutt' },
            ],

            idcTypeOptions: [
                { value: 'idc02', display: 'ID-kontroll med egenerklæring' },
                { value: 'idc01', display: 'Kun ID-kontroll' },
            ]
        }
    },
    methods: {

        handleReceiptClick(envelopeId, type) {
            this.$emit('receipt', envelopeId, type);
        },


        onGreetingChange() {
            if (this.idcSend.useSms) {
                this.idcGreeting = this.getSMSgreeting();
            }else {
                this.idcGreeting = this.getEmailgreeting();
            }
        },

        getSMSgreeting() {
            if (this.idcTranslations) {
                const data = {
                    firstName: this.idcSend.firstName,
                    lastName: this.idcSend.lastName,
                    reference: this.$parent.customer.ref ? this.$root.translations['customer_reference_message'][this.idcSend.language] + ': ' + this.$parent.customer.ref : ''
                };
                return this.replaceBrackets(this.idcTranslations['idc_sms_invite'][this.idcSend.language], data);
            }else {
                this.idcGreeting = this.__('loading');
                this.idcGreetingAction = 'sms';
                this.loadIDCTranslations();
            }
        },

        getEmailgreeting() {
            if (this.idcTranslations) {
                const data = {
                    firstName: this.idcSend.firstName,
                    lastName: this.idcSend.lastName,
                    reference: this.$parent.customer.ref ? this.__('customer_reference_message') + ': ' + this.$parent.customer.ref : ''
                };
                return this.replaceBrackets(this.idcTranslations['idc_email_message'][this.idcSend.language], data);
            }else {
                this.idcGreeting = this.__('loading');
                this.idcGreetingAction = 'email';
                this.loadIDCTranslations();
            }
        },

        async loadIDCTranslations() {
            if (this.idcTranslationsWorking) return;
            this.idcTranslationsWorking = true;

            const response = await api.getTranslations(this.$root.config.idcs[this.idcIndex].key);
            this.idcTranslations = { ...locale.getDefaultIdcTranslations(), ...response.texts};

            if (this.idcGreetingAction == 'sms') {
                this.idcGreeting = this.getSMSgreeting();
            }else if (this.idcGreetingAction == 'email') {
                this.idcGreeting = this.getEmailgreeting();
            }
            this.idcGreetingAction = '';
            this.idcTranslationsWorking = false;
        },


        showChangePersonDialog() {
            //console.log('sperson', this.$parent.singlePerson);
            this.changePerson.firstName = this.$parent.singlePerson.info.firstName;
            this.changePerson.lastName = this.$parent.singlePerson.info.lastName;
            this.changePerson.dateOfBirth = this.$parent.singlePerson.info.dateOfBirth;
            this.changePerson.country = this.$parent.customer.country;
            this.changePerson.email = this.$parent.singlePerson.contact.email;
            this.changePerson.phone = this.$parent.singlePerson.contact.phone;
            this.changePerson.reference = this.$parent.customer.ref;
            this.changePerson.show = true;
            this.validateChangePerson();
        },

        validateChangePerson() {
            if (this.setReference.number != '') return this.setReference.valid = true;
            this.changePerson.valid = true;
        },

        async onChangePerson() {
            this.changePerson.working = true;
            
            const data = {
                name: this.changePerson.firstName.toUpperCase() + ' ' + this.changePerson.lastName.toUpperCase(),
                identity: tools.encodePerson(this.changePerson),
                country: this.changePerson.country
            };
            if (this.changePerson.email || this.changePerson.phone.number) {
                data.contact = {
                    email: this.changePerson.email,
                    phone: this.changePerson.phone
                }
            }
            if (this.changePerson.reference) {
                data.ref = this.changePerson.reference;
                data.status1 =  'r-ok';
            }else {
                data.ref = '';
                data.status1 = 'r-na';
            }
            const result = await api.updateCustomer(this.$parent.customer._id, data);

            this.changePerson.working = false;
            this.changePerson.show = false;
            this.$parent.onReloadRequested(result.customer);
        },


        //#region Reminders ---------------------------------------------------------------------------------------------------

        showReferenceDialog() {
            this.setReference.number = this.$parent.customer.ref || '';
            this.setReference.show = true;
            this.validateReference();
        },

        validateReference() {
            if (this.setReference.number != '') return this.setReference.valid = true;
            this.setReference.valid = false;
        },

        async onSetReference() {
            this.setReference.working = true;
            
            const data = {
                status: 'b-per-x',
                ref: this.setReference.number,
                status1: this.setReference.number ? 'r-ok' : 'r-na',
                resetTags: true,
                tags: [this.setReference.tag]
            }
            const result = await api.updateCustomer(this.$parent.customer._id, data);

            this.setReference.working = false;
            this.setReference.show = false;
            this.$parent.onReloadRequested(result.customer);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------



        showIDCOptionsDialog(person, idcIndex, select) {
            this.idcIndex = idcIndex || 0;
            this.idcOptions.person = person;
            this.idcOptions.select = select;
            this.idcOptions.show = true;
        },

        validatePersonIDCPopup() {
            this.idcSend.valid = true;

            this.idcSend.phoneRequired = (this.idcSend.useSms);

            const result = validations.validateEmail(this.idcSend.email);
            this.idcSend.valid = result.valid || this.idcSend.useSms;


            if (this.idcSend.phoneRequired && !this.idcSend.phone.number) this.idcSend.valid = false;

            this.onGreetingChange();
        },

        onShowSendIDC() {
            if (this.$root.config.portal.useNewIdControl) {

                if (this.$parent.personList.length == 0) return;
                let index = -1;
                for (const i in this.$parent.personList) {
                    if (personhelper.isSamePerson(this.idcOptions.person, this.$parent.personList[i])) {
                        index = i;
                        break;
                    }
                }
                if (index == -1) return;

                const data = {
                    idcPerson: this.$parent.personList[index]
                };

                this.$emit('form', 'idc01', data);

            }else {
                //console.log('using idc index ' + this.idcIndex);
                this.idcSend.firstName = this.idcOptions.person.info.firstName;
                this.idcSend.lastName = this.idcOptions.person.info.lastName;
                this.idcSend.email = this.idcOptions.person.contact.email;
                this.idcSend.phone = this.idcOptions.person.contact.phone.number ? this.idcOptions.person.contact.phone : { prefix: '+47', number: '' };
                this.validatePersonIDCPopup();
                this.idcSend.show = true;
            }
        },

        async onSendIDC() {
            this.idcSend.working = true;
            if (this.$parent.personList.length == 0) return;
            let index = -1;
            for (const i in this.$parent.personList) {
                if (personhelper.isSamePerson(this.idcOptions.person, this.$parent.personList[i])) {
                    index = i;
                    break;
                }
            }
            if (index == -1) return;

            this.$parent.personList[index].contact.email = this.idcSend.email;
            if (this.idcSend.useSms && this.idcSend.phone.number) {
                this.$parent.personList[index].contact.phone.prefix = this.idcSend.phone.prefix;
                this.$parent.personList[index].contact.phone.number = this.idcSend.phone.number;
            }

            console.log(this.$parent.personList[index]);

            if (this.$parent.personList[index].uimodel.didControl) return;

            let nextStatus = 'na';
            let currentStatus = 'na';
            if (this.$parent.customer.flow == 'personLookup') {
                currentStatus = 'b-per-c';
                nextStatus = 'b-per-g';
            }

            if (this.idcOptions.select) {
                if (this.idcSend.type == 'idc01') this.idcIndex = 0;
            }

            const existingData = {
                sms: {
                    notifyWithSms: this.idcSend.useSms,
                    phone: this.idcSend.phone
                },
                language: this.idcSend.language,
                customerId: this.$parent.customer._id,
                customerName: this.$parent.customer.name,
                brreg: this.$parent.brregData,
                companyAml: this.$parent.amlData,
                idcIndex: this.idcIndex,
                currentStatus: currentStatus,
                nextStatus: nextStatus,
                reference: this.$parent.customer.ref
            };
            const result = await receipts.sendIdControl(this.$root, this.$parent.personList[index], existingData, false);

            this.idcSend.working = false;
            this.idcSend.show = false;
            this.$parent.onReloadRequested(result.updatedCustomer);
        },

        onShowManualIDC() {
            this.idcManual.show = true;
        },

        async onPerformManualIDC() {
            this.idcManual.working = true;

            const personId = tools.encodePerson(this.idcOptions.person);
            await api.updatePerson(personId, {
                idc: {
                    userId: this.$root.credentials.uid,
                    completed: true,
                    completedDate: new Date(),
                    customerId: this.$parent.customer._id,
                    manual: true,
                    manualType: 'man',
                    ssn: this.idcManual.ssn,
                    comment: this.idcManual.comment
                }
            });

            this.idcManual.working = false;
            this.idcManual.show = false;
            this.$parent.onReloadRequested();
        },

        async onResetIDC() {
            this.idcOptions.show = false;
            this.idcReset.working = true;

            //console.log(this.idcOptions.person);
            await api.archivePersonData(this.idcOptions.person.id, 'idc', 'reset');

            this.idcReset.working = false;
            this.idcReset.show = false;
            this.$parent.loadAllData();
        },

        //#region Reminders ---------------------------------------------------------------------------------------------------

        showPersonIDCReminder() {
            //console.log('person', this.idcOptions.person);
            const reminderData = {
                envelopeId: this.idcOptions.person.control.envelopeId,
                firstName: this.idcOptions.person.info.firstName,
                lastName: this.idcOptions.person.info.lastName,
                email: this.idcOptions.person.contact.email,
                recipientId: this.idcOptions.person.control.recipientId
            }
            this.showReminder(reminderData);
            this.idcOptions.show = false;
        },
        
        showReminder(data) {
            this.reminderOriginal.firstName = data.firstName;
            this.reminderOriginal.lastName = data.lastName;
            this.reminderOriginal.email = data.email;
            this.reminder.firstName = data.firstName;
            this.reminder.lastName = data.lastName;
            this.reminder.email = data.email;
            this.reminder.envelopeId = data.envelopeId;
            this.reminder.recipientId = data.recipientId;
            this.validateReminder();
            this.reminder.show= true;
        },

        validateReminder() {
            const result = validations.validateEmail(this.reminder.email);
            this.reminder.valid = result.valid;
            if (this.reminder.firstName != this.reminderOriginal.firstName 
            || this.reminder.lastName != this.reminderOriginal.lastName 
            || this.reminder.email != this.reminderOriginal.email) {
                this.reminder.changed = true;
            }else {
                this.reminder.changed = false;
            }
        },

        async sendReminder() {
            this.reminder.working = true;
            if (this.reminder.changed) {
                await verified.editRecipient(this.reminder.envelopeId, this.reminder.recipientId, this.reminder.firstName, this.reminder.lastName, this.reminder.email);
            }
            await verified.sendReminder(this.reminder.envelopeId, this.reminder.recipientId, 'message');
            this.reminder.working = false;
            this.reminder.show = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Shareholder Book --------------------------------------------------------------------------------------------

        showShareholderBookOptionsDialog() {
            this.bookOptions.show = true;
        },

        onShowShareholderBook(name) {
            this.bookOptions.show = false;
            this.$parent.showShareholderBook(name);
        },

        showConfirmShareholderBookDialog() {
            this.bookOptions.show = false;
            this.confirmShareholderBook.show = true;
        },

        validateShareholderBookConfirmPopup() {
            let valid = true;
            if (!this.confirmShareholderBook.comment) valid = false;
            this.confirmShareholderBook.valid = valid;
        },

        async onConfirmShareholderBook() {
            this.confirmShareholderBook.working = true;

            let envelopeId = '';

            if (this.confirmShareholderBook.files.files && this.confirmShareholderBook.files.files.length > 0) {
                const name = 'Aksjeeierbok ' + this.$parent.customer.name;
                const tags = ['aksjeeierbok'];
                const result = await filestore.storeFiles(this.$root, this.confirmShareholderBook.files, name, tags);
                envelopeId = result.envelopeId;
            }

            const metadata = {
                shBookData: {
                    confirmed: true,
                    userId: this.$root.credentials.uid,
                    comment: this.confirmShareholderBook.comment,
                    envelopeId: envelopeId
                }
            };
            const response = await api.setMetadata(this.$parent.customer._id, metadata);

            this.confirmShareholderBook.working = false;
            this.confirmShareholderBook.show = false;
            this.$parent.onReloadRequested(response.customer);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Shareholder Book --------------------------------------------------------------------------------------------

        showUploadKycDialog() {
            this.uploadKyc.show = true;
        },

        validateUploadKycDialog() {
            let valid = true;
            if (!this.uploadKyc.files.files || this.uploadKyc.files.files.length == 0) valid = false;
            this.uploadKyc.valid = valid;
        },

        async onUploadKyc() {
            this.uploadKyc.working = true;

            let envelopeId = '';
            const name = 'Kopi av egenerklæring ' + this.$parent.customer.name;
            const tags = ['egenerklæring'];
            const result = await filestore.storeFiles(this.$root, this.uploadKyc.files, name, tags);
            envelopeId = result.envelopeId;

            const metadata = {
                uploadKycData: {
                    confirmed: true,
                    userId: this.$root.credentials.uid,
                    comment: this.uploadKyc.comment,
                    envelopeId: envelopeId
                }
            };
            const response = await api.setMetadata(this.$parent.customer._id, metadata);

            const response2 = await api.getCustomer(this.$parent.customer._id);

            this.$parent.onReloadRequested(response2.customer);

            this.uploadKyc.working = false;
            this.uploadKyc.show = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        async performPepSearch() {
            this.dialogs.pepSearch.working = true;

            const existingData = {
                customerId: this.$parent.customer._id,
                customerName: this.$parent.customer.name,
                brreg: this.$parent.brregData,
                companyData: this.$parent.companyData,
                companyAml: this.$parent.amlData
            };
            let result = await receipts.performPEPSearch(this.$root, this.$parent.singlePerson, existingData, false);

            if (this.$parent.customer.flow == 'person' || this.$parent.customer.flow == 'personLookup') {
                const data = {
                    status: 'b-per-f'
                }
                result = await api.updateCustomer(this.$parent.customer._id, data);
            }

            this.dialogs.pepSearch.working = false;
            this.dialogs.pepSearch.show = false;
            this.$parent.onReloadRequested(result.customer || result.updatedCustomer);
        },

        showUpgradeOrgDialog() {
            this.upgradeOrg.brregData = {};
            this.upgradeOrg.show = true;
        },

        async performUpgradeOrg() {
            this.upgradeOrg.working = true;

            const values = {
                identity: this.upgradeOrg.brregData.org_no,
                name: this.upgradeOrg.brregData.name,
                flow: 'org-no-baml',
                source: 'brreg'
            };
            const response = await api.updateCustomer(this.$parent.customer._id, values);

            if (this.$parent.customer.report) {
                await api.archiveData(this.$parent.customer._id, 'report', 'upgrade');
            }

            this.upgradeOrg.working = false;
            this.upgradeOrg.show = false;
            location.reload();
        },



        showReportOptionsDialog() {
            this.repOptions.show = true;
        },

        async onResetReport() {
            this.repReset.working = true;

            const response = await api.archiveData(this.$parent.customer._id, 'report', 'reset');

            this.repReset.working = false;
            this.repReset.show = false;
            location.reload();
        }

    },
    async created() {
        
    }
}
</script>
<style scoped>


</style>