<template>
<div>

    <RiskItem v-for="(cat, i) in cats" :key="'nc' + i"
        :level="1"
        :display="cat.nb_NO"
    >
        <template v-slot:risk>
            <RiskScoreControl
                :params="riskParams"
                :source="'et'"
                :rId="'e' + cat.key"
            />
        </template>

        <RiskItem v-for="(level2, j) in cat.items" :key="'n2' + j"
            :level="2" lastLevel
            :display="level2.display"
            :flag="level2.alpha2"
        >
            <template v-slot:risk>
                <RiskScoreControl
                    :params="riskParams"
                    :source="'pc'"
                    :rId="'c' + cat.key + level2.key"
                    :rId1="'c' + cat.key"
                />
            </template>
        </RiskItem>

    </RiskItem>

    <!-- <RiskExpandButton @expand="onExpandClicked" /> -->

</div>
</template>
<script>
import v1risk from './v1risk';

import RiskItem from './RiskItem.vue';
import RiskExpandButton from './RiskExpandButton.vue';
import RiskScoreControl from './RiskScoreControl.vue';

export default {
    name: 'RiskEntitySettings',
    components: {
        RiskItem,
        RiskExpandButton,
        RiskScoreControl,
    },
    props: {
        riskParams: { type: Object, default: null },
    },
    data() {
        return {
            expanded: false,
            cats: {}
        }
    },
    methods: {

        onExpandClicked() {
            this.expanded = !this.expanded;
            this.loadCategories();
        },

        loadCategories() {
            const categories = v1risk.getEntityCategories();

            for (const i in categories) {
                categories[i].key = i;
                categories[i].items = [];
                categories[i].expanded = false;
            }
            //console.log('cats', categories);

            const items = v1risk.getEntityTypes().no;

            for (const i in items) {
                //console.log('cat', items[i].cat);
                categories[items[i].cat].items.push({
                    key: items[i].key,
                    display: items[i].nb_NO,
                    alpha2: items[i].cc
                });

            }

            //console.log('entity', categories);

            this.cats = categories;
        },

    },
    created() {
        this.loadCategories();
    }
}
</script>