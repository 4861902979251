import api from './portalapi';
import v2lib from './v2lib';
import verified from './verified';
import log from './log';
const cache = {

    envelopeCache: [],
    batchCache: [],
    urlCache: [],
    fileCache: [],

    invalidateCache(envelopeId) {
        log.w('Cache: Invalidating cache with envelopeId', envelopeId);
        for (const i in this.envelopeCache) {
            if (this.envelopeCache[i].id == envelopeId) {
                this.envelopeCache.splice(i, 1);
                log.w('Cache: Envelope ' + envelopeId + ' removed from cache');
            }
        }
        for (const i in this.urlCache) {
            if (this.urlCache[i].envelopeId == envelopeId) {
                this.urlCache.splice(i, 1);
                log.w('Cache: URL ' + envelopeId + ' removed from cache');
            }
        }
        for (const i in this.fileCache) {
            if (this.fileCache[i].envelopeId == envelopeId) {
                this.fileCache.splice(i, 1);
                log.w('Cache: File ' + envelopeId + ' removed from cache');
            }
        }
    },

    async getEnvelope(envelopeId) {
        for (const i in this.envelopeCache) {
            if (this.envelopeCache[i].id == envelopeId) {
                log.s('Cache: Envelope ' + envelopeId + ' loaded from cache');
                return this.envelopeCache[i];
            }
        }

        const envelope = await v2lib.getEnvelope(envelopeId);
        this.envelopeCache.push(envelope);
        log.v('Cache: Envelope ' + envelopeId + ' fetched from API');
        return envelope;
    },

    async getBatch(batchId) {
        for (const i in this.batchCache) {
            if (this.batchCache[i]._id == batchId) {
                log.s('Cache: Batch ' + batchId + ' loaded from cache');
                return this.batchCache[i];
            }
        }
        const batch = await api.getBatch(batchId);
        this.batchCache.push(batch.batch);
        log.v('Cache: Batch ' + batchId + ' fetched from API');
        return batch.batch;
    },

    async getBatchCustomer(batchId, customerId) {
        const batch = await this.getBatch(batchId, customerId);
        for (const i in batch.customers) {
            if (batch.customers[i].customerId == customerId) {
                return batch.customers[i];
            }
        }
        return null;
    },

    async getUrl(envelopeId, documentId, fileUid) {
        for (const i in this.urlCache) {
            if (this.urlCache[i].envelopeId == envelopeId && this.urlCache[i].documentId == documentId && this.urlCache[i].fileUid == fileUid) {
                log.s('Cache: URL ' + fileUid + ' loaded from cache');
                return this.urlCache[i].url;
            }
        }
        const url = await verified.getDownloadUrl(envelopeId, documentId, fileUid);
        this.urlCache.push({
            envelopeId: envelopeId,
            documentId: documentId,
            fileUid: fileUid,
            url: url,
        });
        log.v('Cache: URL ' + fileUid + ' fetched');
        return url;
    },

    async getFile(envelopeId, name, url) {
        for (const i in this.fileCache) {
            if (this.fileCache[i].envelopeId == envelopeId && this.fileCache[i].name == name) {
                log.s('Cache: File ' + name + ' loaded from cache');
                return this.fileCache[i].data;
            } 
        }
        const data = await verified.downloadFile(url);
        this.fileCache.push({
            envelopeId: envelopeId,
            name: name,
            data: data
        });
        log.v('Cache: File ' + name + ' downloaded');
        return data;
    },

};
export default cache;