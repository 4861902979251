<template>
<div class="fbinp-input">
    
    <input
        :value="value"
        :placeholder="placeholder"
        @input="handleInput"
        :disabled="disabled"
    />

    <div class="fbinp-input-trans" v-if="transDialog" @click="$emit('trans')" :class="[
        {'fbinp-input-trans-warning': transWarning }
    ]">
        <vap-icon tiny :icon="'locale'" :color="'ext'" />
    </div>

</div>
</template>
<script>
export default {
    name: 'DesignerInput',
    props: {
        value: [String, Number],
        number: { type: Boolean, default: false },
        placeholder: { type: String, default: 'Not set' },
        disabled: { type: Boolean, default: false },
        transDialog: { type: Boolean, default: false },
        transWarning: { type: Boolean, default: false },
    },
    methods: {

        handleInput(event) {
            this.$emit('before');
            this.$emit('input', event.srcElement.value);
        },

    }
}
</script>
<style scoped>

.fbinp-input {
    display: flex;
}

.fbinp-input input {
    flex-grow: 100;
    border: none;
    background: none;
    outline: none;
    width: 100%;
    height: 26px;
    padding: 0px 5px;
    padding-top: 1px;
    font-size: 14px;
    color: var(--fb-text-1);
    border: solid 1px var(--fb-input-bg);
    background-color: var(--fb-input-bg);
    box-sizing: border-box;
}

.fbinp-input input:focus {
    border-color: gray;
}

.fbinp-input input::placeholder {
    color: var(--fb-text-4);
    font-weight: 500;
    letter-spacing: 0.6px;
}


.fbinp-input-trans {
    width: 28px;
    padding-top: 2px;
    padding-left: 2px;
    fill: gray;
    border-left: solid 1px var(--fb-menu-b);
    background-color: var(--fb-input-bg);
    box-sizing: border-box;
    cursor: pointer;
}

.fbinp-input-trans-warning {
    background-color: #DAA619;
}

.fbinp-input-trans:hover {
    background-color: var(--fb-menu-a);
}

</style>