var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.step)?_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"srv2x-step-x",class:[
        { 'srv2x-step-completed srv2x-step-progress' : _vm.status == 3 },
        { 'srv2x-step-completed' : _vm.status == 5 },
        { 'srv2x-step-completed srv2x-step-warning' : _vm.status == 8 },
        { 'srv2x-step-completed srv2x-step-error' : _vm.status == 9 } ],on:{"click":function($event){$event.stopPropagation();return _vm.onStepClicked($event)}}},[_c('div',{staticClass:"srv2x-line-top"}),_c('div',{staticClass:"srv2x-line-bot"}),_c('div',{staticClass:"srv2x-icon"},[(_vm.step.icon)?_c('div',{staticClass:"srv2x-icon-icon"},[_c('vap-icon',{attrs:{"icon":_vm.step.icon}})],1):_vm._e(),(!_vm.step.icon)?_c('div',{staticClass:"srv2x-icon-number"},[_vm._v(" "+_vm._s(_vm.getNumberChar(_vm.archiveIndex))+" ")]):_vm._e()]),_c('div',{staticClass:"srv2x-content"},[_c('div',{staticClass:"srv2x-header"},[_vm._v(" "+_vm._s(_vm.getFormName())+" ")]),(_vm.status == 0)?_c('div',{staticClass:"srv2x-hint"},[_vm._v(" "+_vm._s(_vm.step.hint[_vm.$root.locale.key])+" ")]):_vm._e(),_vm._l((_vm.subs),function(sub,i){return _c('div',{key:'fss' + i,staticClass:"srv2x-status",class:[
                {'srv2x-status-progress': sub.status == 3 },
                {'srv2x-status-completed': sub.status == 5 },
                {'srv2x-status-warning': sub.status == 8 },
                {'srv2x-status-error': sub.status == 9 }
            ]},[_c('div',{staticClass:"srv2x-ball"}),_c('div',{staticClass:"srv2x-status-text"},[_vm._v(" "+_vm._s(sub.text[_vm.$root.locale.key])+" ")])])})],2),(_vm.mainAction)?_c('div',{staticClass:"srv2x-button",on:{"click":function($event){return _vm.$emit('action', _vm.mainAction, _vm.index)}}},[_c('div',{staticClass:"srv2x-button-c"},[_vm._v(" "+_vm._s(_vm.mainAction.text[_vm.$root.locale.key])+" ")]),_c('div',{staticClass:"srv2x-button-t"})]):_vm._e(),_c('CardActions',{attrs:{"sharpCorner":"","actions":_vm.actions},on:{"action":_vm.onAction}})],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }