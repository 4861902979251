<template>
<div>
    <PopupV1 :header="'New account'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div v-if="page == 0">

                <VFLabel desc :text="'Company ID'" />
                <VFInput v-model="companyId" :placeholder="'Company ID'" @input="onCompanyIdChanged" />

            </div>
            <div v-if="page == 1">

                <VFLabel desc :text="'Display Name'" />
                <VFInput v-model="name" :placeholder="'Display Name'" @input="validateInputs" />

                <div class="flw100" style="margin-top: 5px; font-size: 15px; color: var(--text-title); font-style: italic;">
                    {{ 'This will be the account name displayed inside the portal. It will also be used as the company name in the default emails.' }}
                </div>

                <VFLabel desc :text="'Datebase Key'" style="margin-top: 20px;" />
                <div class="flw100">
                    <div style="float: left; width: 45%;">
                        <VFInput v-model="databaseKey" :placeholder="'Datebase Key'" @input="onDatabaseKeyChanged" />
                    </div>
                    <div style="float: left; width: 10%; font-size: 30px; text-align: center;">
                        {{ ' - ' }}
                    </div>
                    <div style="float: left; width: 45%;">
                        <VFInput disabled v-model="companyId" />
                    </div>
                </div>

                <div class="flw100" style="margin-top: 5px; font-size: 15px; color: var(--text-title); font-style: italic;">
                    {{ 'Database key has to be a one word, short identifier for the client.' }}
                </div>

                <div v-if="databaseExists" class="flw100" style="margin-top: 5px; color: var(--val-error);">
                    {{ 'Database already exists' }}
                </div>
                <VFCheckbox v-if="databaseExists" newDesign v-model="databaseOverride" :text="'Override existing configuration'" />

            </div>
            <div v-if="page == 2">

                <VFLabel desc :text="'Portal setup'" />
                <VFCheckbox inline radio v-model="baseModel" :options="baseOptions" />

                <VFLabel desc :text="'Features'" style="margin-top: 10px;" />
                <VFCheckbox newDesign v-model="featureModel" :options="featureOptions" />

            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="'Previous'" outline v-if="page > 0"
                :icon="'chev-l'"
                @clicked="onPrevPage"
                :disabled="loading || dialog.working"
            />
            <VFButton :text="page == 2 ? 'Create account' : 'Next'"
                :icon="page == 2 ? 'check' : 'chev-r'"
                @clicked="onNextPage"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>
</div>
</template>
<script>
import axios from 'axios';
import v2lib from '../v2lib';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFInput from '../components/form/inputs/VFInput.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import v1backoffice from './v1backoffice';

export default {
    name: 'BoAddAccountDialog',
    components: {
        PopupV1,
        VFButton,
        VFLabel,
        VFInput,
        VFCheckbox,
    },
    props: {
        params: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },

            page: 0,

            companyId: '',
            name: '',
            databaseKey: '',
            databaseExists: false,
            databaseOverride: false,

            baseModel: {
                norway: true,
                sweden: false,
            },
            baseOptions: {
                norway: { label: 'Norway' },
                sweden: { label: 'Sweden' },
            },
            featureModel: {
                personFlow: false,
                personFlowKyc: true,
                forreignFlow: false,
                seSpecial: false
            },
            featureOptions: {
                personFlow: { label: 'Person Flow' },
                personFlowKyc: { label: 'Person Flow with KYC' },
                forreignFlow: { label: 'Forreign Flow' },
                seSpecial: { label: 'Use custom form for Sweden' },
            }

        }
    },
    methods: {

        async onNextPage() {
            this.dialog.working = true;
            let canContinue = true;

            if (this.page == 0) {

                // Get company data from Verified
                this.dialog.status = 'Fetching company info from Verified API';

                try {
                    const response = await this.axiosGet(`/api/companies/${this.companyId}`);
                    console.log('response', response);

                    this.name = response.data.name;
                    this.databaseKey = response.data.name.split(' ')[0].toLowerCase();
                }catch (ex) {
                    this.dialog.error = ex.message;
                    canContinue = false;
                }

            }else if (this.page == 1) {

                if (!this.databaseOverride) {
                    // Check if database exists
                    this.dialog.status = 'Checking database';

                    const pid = this.databaseKey + '-' + this.companyId;

                    const response = await v1backoffice.checkAccount(pid);
                    console.log('response', response);

                    if (response.exists) {
                        this.databaseExists = true;
                        canContinue = false;
                    }
                }

            }else if (this.page == 2) {

                this.dialog.status = 'Creating account';

                await new Promise(r => setTimeout(r, 2500));

                this.$emit('reload');
                this.onClose();

            }

            this.dialog.working = false;
            if (canContinue) this.page += 1;
            this.validateInputs();
        },

        onCompanyIdChanged() {
            this.dialog.error = '';
            this.validateInputs();
        },

        onDatabaseKeyChanged() {
            this.databaseExists = false;
            this.databaseOverride = false;
            this.validateInputs();
        },

        onPrevPage() {
            this.page -= 1;
            this.validateInputs();
        },

        validateInputs() {
            if (this.page == 0) {

                this.dialog.valid = this.companyId.length > 7;

            }else if (this.page == 1) {
                let valid = true;

                if (!this.name) valid = false;
                if (!this.databaseKey) valid = false;

                this.dialog.valid = valid;
            }else {
                this.dialog.valid = true;
            }
        },

        onClose() {
            this.$emit('close');
        },

        async setPermissionsV1() {

            let data = undefined, res = undefined;
            let companyId = this.addDialogV1.companyId;

            const roles = {
                admin: { type: 'admin', id: '' },
                privileged: { type: 'privileged', id: '' },
                regular: { type: 'regular', id: '' },
                template: { type: 'template', id: '' },
            };

            this.updateAddStatus('Fetching company roles from Verified API');
            await new Promise(r => setTimeout(r, 1000));

            // Get roles
            res = await this.axiosGet(`/api/auth/roles?filters=` + encodeURIComponent(`{"and":[{"op":"regex","value":"^/roles/.+/companies/${companyId}$","field":"name"}]}`) + `&from=0&limit=10&sort={"name":1}`);
            console.log('RRes', res);
            for (const i in res.data) {
                if (res.data[i].name.includes('admin')) {
                    roles.admin.id = res.data[i].id;
                }else if (res.data[i].name.includes('privileged')) {
                    roles.privileged.id = res.data[i].id;
                }else if (res.data[i].name.includes('regular')) {
                    roles.regular.id = res.data[i].id;
                }
            }

            this.updateAddStatus('Adding descriptors');
            await new Promise(r => setTimeout(r, 1000));

            // Add all descriptors for all roles
            const descriptors = [
                { name: 'aml-portal-v1-org', public: false },
                { name: 'aml-portal-v1-person', public: false },
                { name: 'aml-portal-v1-report', public: false },
                { name: 'aml-portal-v1-internal', public: false },
                { name: 'aml-portal-v1-sign', public: false },
                { name: 'aml-portal-v1-direct', public: false },
            ];
            for (const i in descriptors) {
                for (const j in roles) {
                    if (roles[j].type != 'template') {

                        console.log('adding', roles[j]);

                        this.updateAddStatus('Adding descriptor ' + descriptors[i].name);
                        await new Promise(r => setTimeout(r, 250));

                        data = { actions: [ 'GET' ], headers: [ { key: 'x-namespace', value: `/companies/${companyId}` } ], resource: `^/envelope-descriptors/${descriptors[i].name}$` };
                        await this.axiosPost(`/api/auth/roles/${roles[j].id}/permissions`, data);

                        data = { actions: [ 'POST' ], headers: [ { key: 'x-namespace', value: `/companies/${companyId}` } ], resource: `^/envelope-descriptors/${descriptors[i].name}/envelopes$` };
                        await this.axiosPost(`/api/auth/roles/${roles[j].id}/permissions`, data);

                        if (descriptors[i].public) {
                            data = { actions: [ 'POST' ], headers: [ { key: 'x-namespace', value: `/companies/${companyId}` } ], resource: `^/envelope-descriptors/${descriptors[i].name}/tokens$` };
                            await this.axiosPost(`/api/auth/roles/${roles[j].id}/permissions`, data);
                        }

                    }
                }
            }

            this.updateAddStatus('Updating roles');
            await new Promise(r => setTimeout(r, 5000));

            // Get roles again since there will now be a public role
            res = await this.axiosGet(`/api/auth/roles?filters=` + encodeURIComponent(`{"and":[{"op":"regex","value":"^/roles/.+/companies/${companyId}$","field":"name"}]}`) + `&from=0&limit=10&sort={"name":1}`);
            for (const i in res.data) {
                if (res.data[i].name.includes('tmpl')) {
                    roles.template.id = res.data[i].id;
                }
            }

            // Set BankID AML permissions for all roles
            if (this.baseModel.norway) {
                const permissions = [
                    { method: 'GET', resource: '^/bankid-oidc/organization/noauth', public: false },
                    { method: 'GET', resource: '^/bankid-oidc/person/noauth$', public: false },
                    { method: 'GET', resource: '^/bankid-oidc/person/pdf$', public: false },
                ];
                for (const i in roles) {

                    this.updateAddStatus('Adding BankID AML persmissions for ' + roles[i].type);
                    await new Promise(r => setTimeout(r, 250));

                    for (const j in permissions) {
                        if (!(!permissions[j].public && roles[i].type == 'template') && !(permissions[j].public && roles[i].type != 'template')) {
                            data = { actions: [permissions[j].method], resource: permissions[j].resource };
                            await this.axiosPost(`/api/auth/roles/${roles[i].id}/permissions`, data);
                        }
                    }
                }
            }

            if (this.baseModel.sweden) {
                const permissions = [
                    { method: 'GET', resource: '^/bisnode/company', namespace: true, public: false },
                    { method: 'GET', resource: '^/bisnode/person', namespace: true, public: false },
                ];

                for (const i in roles) {

                    this.updateAddStatus('Adding Trapets/Bisnode persmissions for ' + roles[i].type);
                    await new Promise(r => setTimeout(r, 250));

                    for (const j in permissions) {
                        if (!(!permissions[j].public && roles[i].type == 'template') && !(permissions[j].public && roles[i].type != 'template')) {
                            data = { actions: [permissions[j].method], resource: permissions[j].resource };
                            if (permissions[j].namespace) data.headers = [ { key: 'x-namespace', value: `/companies/${companyId}` } ];
                            await this.axiosPost(`/api/auth/roles/${roles[i].id}/permissions`, data);
                        }
                    }
                }
            }



            this.updateAddStatus('Enabling link to AML-portal');
            await new Promise(r => setTimeout(r, 1500));

            // Get and set features
            data = { features: [] };
            res = await this.axiosGet(`/api/companies/${companyId}/settings`);
            for (const i in res.data.features) {
                data.features.push(res.data.features[i]);
            }
            data.features.push('aml-portal');
            if (this.addDialogV1.featureModel.flowSweden) data.features.push('trapets-credentials');
            await this.axiosPut(`/api/companies/${companyId}/settings`, data);

            // Set AML portal link
            data = { amlPortalLink: [ "web/aml-portal/", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "" ] };
            await this.axiosPut(`/api/companies/${companyId}/settings`, data);

        },

        async createAccountV1() {

            this.updateAddStatus('Adding company and configuration');

            const company = {
                type: 'prod',
                name: this.addDialogV1.name1 + ' ' + this.addDialogV1.name2,
                key: this.addDialogV1.key,
                id: companyId
            };
            await backend.adminAddCompany(company);

            const pid = company.key + '-' + company.id;
            const config = configTools.getDefault();

            config.portal.useV1 = true;

            if (this.addDialogV1.featureModel.flowNorway) {
                config.search.org.no.enabled = true;
                config.search.org.no.default = true;
                config.screening.nordicProvider = 'baml';
                config.screening.globalProvider = 'baml';
                config.params.defaultLanguage = 'nb_NO';
                config.params.defaultPrefix = '+47';
                this.enableDefaultFlow(config, 'no-org-baml', true);
            }else {
                config.search.org.no.enabled = false;
                config.search.org.no.default = false;
                this.enableDefaultFlow(config, 'no-org-baml', false);
            }
            if (this.addDialogV1.featureModel.flowSweden) {
                config.search.org.se.enabled = true;
                config.search.org.se.default = true;
                config.params.defaultLanguage = 'sv_SE';
                config.params.defaultPrefix = '+46';
                this.enableDefaultFlow(config, 'se-org-bistrap', true);
                this.enableLanguage(config, 'sv_SE', true);
                if (!this.addDialogV1.featureModel.flowNorway) this.enableLanguage(config, 'nb_NO', false);
            }
            if (this.addDialogV1.featureModel.personFlow) {
                config.search.person.enabled = true;
                this.enableDefaultFlow(config, 'zz-person', true);
            }
            if (this.addDialogV1.featureModel.personFlowKyc) {
                config.search.person.enabled = true;
                this.enableDefaultFlow(config, 'yy-person', true);
            }

            // Foreign company customers is default for all portals
            config.search.forreign.enabled = true;
            this.enableDefaultFlow(config, 'xx-org', true);

            await backend.adminSaveConfig(config, pid);

            this.updateAddStatus('Adding default forms');

            const forms = [];
            forms.push(idc01.getForm());
            forms.push(kyc00.getForm());
            forms.push(kyc02.getForm());

            if (this.addDialogV1.featureModel.personFlowKyc) {
                forms.push(kycp01.getForm())
            }

            await backend.adminAddForms(forms, pid);

            await this.loadCompanies();

        },

        enableDefaultFlow(config, key, value) {
            for (const i in config.defaultFlows) {
                if (config.defaultFlows[i].key == key) {
                    config.defaultFlows[i].enabled = value;
                }
            }
        },

        enableLanguage(config, key, value) {
            for (const i in config.locales) {
                if (config.locales[i].key == key) {
                    config.locales[i].enabled = value;
                }
            }
        },

        //#region Helpers -----------------------------------------------------------------------------------------------------

        async axiosGet(route) {
            const response = await axios({
                url: route,
                method: 'GET',
                headers: this.getHeaders(false),
                withCredentials: "same-origin"
            });
            return response;
        },

        async axiosPost(route, data, xname) {
            const response = await axios({
                url: route,
                method: 'POST',
                headers: this.getHeaders(xname || false),
                data: data,
                withCredentials: "same-origin"
            });
            return response;
        },

        async axiosPut(route, data) {
            const response = await axios({
                url: route,
                method: 'PUT',
                headers: this.getHeaders(false),
                data: data,
                withCredentials: "same-origin"
            });
            return response;
        },

        getHeaders(xname) {
            const headers = {
                'content-type': 'application/json',
                'accept': 'application/json',
                'authorization': 'JWT ' + v2lib.personalToken,
            };
            if (xname) headers['x-namespace'] = '/companies/' + this.companyId;
            return headers;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    async created() {
        this.validateInputs();
        this.loading = false;
    }
}
</script>