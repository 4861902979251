var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PopupV1',{attrs:{"designer":"","maxHeight":500,"header":'Check for problems',"dialog":_vm.dialog},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"frv2-problems"},[_c('div',{staticClass:"frv2-problems-header"},[_vm._v(" "+_vm._s('Flow')+" ")]),_vm._l((_vm.result.flowProblems),function(problem,i){return _c('div',{key:'fp' + i,staticClass:"frv2-problems-item",class:[
                    {'frv2-problems-item-7': problem.level == 7 },
                    {'frv2-problems-item-5': problem.level == 5 },
                    {'frv2-problems-item-3': problem.level == 3 } ]},[_c('div',{staticClass:"frv2-problems-item-bug"},[_c('vap-icon',{attrs:{"small":"","icon":'bug',"color":'ext'}})],1),_c('div',{staticClass:"frv2-problems-item-msg"},[_vm._v(" "+_vm._s(problem.message)+" ")]),_c('div',{staticClass:"frv2-problems-item-link"},[_c('vap-icon',{attrs:{"small":"","icon":'open'}})],1)])}),(_vm.result.flowProblems.length == 0)?_c('div',{staticClass:"frv2-problems-empty"},[_vm._v(" "+_vm._s('No problems identified')+" ")]):_vm._e()],2),_c('div',{staticClass:"frv2-problems"},[_c('div',{staticClass:"frv2-problems-header"},[_vm._v(" "+_vm._s('Logic')+" ")]),_vm._l((_vm.result.logicProblems),function(problem,i){return _c('div',{key:'fp' + i,staticClass:"frv2-problems-item",class:[
                    {'frv2-problems-item-7': problem.level == 7 },
                    {'frv2-problems-item-5': problem.level == 5 },
                    {'frv2-problems-item-3': problem.level == 3 } ]},[_c('div',{staticClass:"frv2-problems-item-bug"},[_c('vap-icon',{attrs:{"small":"","icon":'bug',"color":'ext'}})],1),_c('div',{staticClass:"frv2-problems-item-msg"},[_vm._v(" "+_vm._s(problem.message)+" ")]),_c('div',{staticClass:"frv2-problems-item-link"},[_c('vap-icon',{attrs:{"small":"","icon":'open'}})],1)])}),(_vm.result.logicProblems.length == 0)?_c('div',{staticClass:"frv2-problems-empty"},[_vm._v(" "+_vm._s('No problems identified')+" ")]):_vm._e()],2),_c('div',{staticClass:"frv2-problems"},[_c('div',{staticClass:"frv2-problems-header"},[_vm._v(" "+_vm._s('Text/Translations')+" ")]),_vm._l((_vm.result.textProblems),function(problem,i){return _c('div',{key:'fp' + i,staticClass:"frv2-problems-item",class:[
                    {'frv2-problems-item-7': problem.level == 7 },
                    {'frv2-problems-item-5': problem.level == 5 },
                    {'frv2-problems-item-3': problem.level == 3 } ]},[_c('div',{staticClass:"frv2-problems-item-flag"},[_c('FlagIcon',{attrs:{"circle":"","locale":problem.locale}})],1),_c('div',{staticClass:"frv2-problems-item-msg"},[_vm._v(" "+_vm._s(problem.message)+" ")]),_c('div',{staticClass:"frv2-problems-item-link"},[_c('vap-icon',{attrs:{"small":"","icon":'open'}})],1)])}),(_vm.result.textProblems.length == 0)?_c('div',{staticClass:"frv2-problems-empty"},[_vm._v(" "+_vm._s('No problems identified')+" ")]):_vm._e()],2)]},proxy:true},{key:"buttons",fn:function(){return [_c('VFButton',{attrs:{"text":'Close',"outline":""},on:{"clicked":function($event){return _vm.$emit('close')}}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }