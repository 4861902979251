import comparison from './comparison';
import receipts from '../receipts';
const kychelper = {

    getReactiveObject() {
        return {
            completed: false, aborted: false, signed: false, expired: false,
            editor: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
            aborter: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
            nextSigner: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
            signatories: [],
            signedNames: '',
            expirationDate: undefined,
            envelopeId: '', documentId: '', fileUid: '',
            editorMadeChanges: false,
            shareholderBookProvided: false,
            shareholderBookExisted: false,
            shareholderBooks: [],
            changes: null,
            envelopeDateCreated: undefined,
            lastModifiedDate: null
        };
    },

    getMetadata(customer, envelope, kycMetadata) {

        //console.log('kyc envelope', data);

        //console.log('envelope', envelope);
        let editorCompleted = false;

        kycMetadata.lastModifiedDate = envelope.content.modified;

        // Loop the recipients
        for (let i in envelope.content.recipients) {

            // Process notifications for this recipient
            const result = this.processNotifications(envelope.content.recipients[i].notifications);

            // Check if this is the editor or a signatory
            if (envelope.content.recipients[i].role.action == 'edit') {

                // Set the editor object
                kycMetadata.editor = {
                    id: envelope.content.recipients[i].id,
                    firstName: envelope.content.recipients[i].givenName,
                    lastName: envelope.content.recipients[i].familyName,
                    email: envelope.content.recipients[i].email,
                    displayName: envelope.content.recipients[i].givenName + ' ' + envelope.content.recipients[i].familyName,
                    completed: envelope.content.recipients[i].completed,
                    events: result.events,
                    lastEvent: result.lastEvent
                }
                editorCompleted = envelope.content.recipients[i].completed;

            }else if (envelope.content.recipients[i].role.action == 'sign') {

                const signatory = {
                    id: envelope.content.recipients[i].id,
                    firstName: envelope.content.recipients[i].givenName,
                    lastName: envelope.content.recipients[i].familyName,
                    confirmedFirstName: '',
                    confirmedLastName: '',
                    confirmedName: '',
                    displayName: envelope.content.recipients[i].givenName + ' ' + envelope.content.recipients[i].familyName,
                    status: 'unknown',
                    method: envelope.content.recipients[i].signingMethod,
                    date: new Date(),
                    email: envelope.content.recipients[i].email,
                    events: result.events,
                    validId: false,
                    idData: {}
                };

                //console.log('signatory', envelope.content.recipients[i]);

                if (envelope.content.recipients[i].signatures && envelope.content.recipients[i].signatures.length > 0) {
                    //console.log('signature', envelope.content.recipients[i].signatures[0]);
                    if (envelope.content.recipients[i].signatures[0].origin == 'bankid-no' && envelope.content.recipients[i].signatures[0].birthDate) {
                        signatory.validId = true;
                        signatory.idData = {
                            method: 'bankid-no',
                            ssn: envelope.content.recipients[i].signatures[0].personalNumber,
                            firstName: envelope.content.recipients[i].signatures[0].givenName,
                            lastName: envelope.content.recipients[i].signatures[0].familyName,
                            dateOfBirth: envelope.content.recipients[i].signatures[0].birthDate.split('T')[0],
                            envelopeId: envelope.id,
                            date: envelope.content.recipients[i].signatures[0].date
                        };
                    }
                }

                // Push to signatory list
                kycMetadata.signatories.push(signatory);
            }
        }

        if (customer.kyc.completedDate || editorCompleted) {
            kycMetadata.completed = true;
            
            for (let s in envelope.content.documents[0].signatures) {
                const rid = envelope.content.documents[0].signatures[s].recipient.uid.split('recipients/')[1];
                
                for (let x in kycMetadata.signatories) {
                    if (kycMetadata.signatories[x].id == rid) {
                        kycMetadata.signatories[x].status = envelope.content.documents[0].signatures[s].status;
                        kycMetadata.signatories[x].method = envelope.content.documents[0].signatures[s].origin;
                        kycMetadata.signatories[x].date = new Date(envelope.content.documents[0].signatures[s].date);
                        kycMetadata.signatories[x].confirmedFirstName = envelope.content.documents[0].signatures[s].givenName;
                        kycMetadata.signatories[x].confirmedLastName = envelope.content.documents[0].signatures[s].familyName;
                        kycMetadata.signatories[x].displayName = kycMetadata.signatories[x].confirmedFirstName + ' ' + kycMetadata.signatories[x].confirmedLastName;
                        kycMetadata.signatories[x].confirmedName = kycMetadata.signatories[x].confirmedFirstName + ' ' + kycMetadata.signatories[x].confirmedLastName;
                    }
                }
                if (envelope.content.documents[0].signatures[s].status == 'rejected') {
                    kycMetadata.aborted = true;
                    kycMetadata.aborter = {
                        id: rid,
                        firstName: envelope.content.documents[0].signatures[s].givenName,
                        lastName: envelope.content.documents[0].signatures[s].familyName,
                        email: envelope.content.documents[0].signatures[s].email,
                        displayName: envelope.content.documents[0].signatures[s].givenName + ' ' + envelope.content.documents[0].signatures[s].familyName,
                        comment: envelope.content.documents[0].signatures[s].comment
                    }
                }
            }

            if (envelope.content.completed) {
                if (!kycMetadata.aborted) kycMetadata.signed = true;
                kycMetadata.signedNames = '';
                for (let x in kycMetadata.signatories) {
                    if (kycMetadata.signatories[x].status == 'signed') {
                        if (x > 0) kycMetadata.signedNames = kycMetadata.signedNames + ', ';
                        kycMetadata.signedNames = kycMetadata.signedNames + kycMetadata.signatories[x].displayName;
                    }
                }
            }else {

                for (let x in kycMetadata.signatories) {
                    if (kycMetadata.signatories[x].status == 'unknown') {
                        kycMetadata.nextSigner = {
                            id: kycMetadata.signatories[x].id,
                            firstName: kycMetadata.signatories[x].firstName,
                            lastName: kycMetadata.signatories[x].lastName,
                            email: kycMetadata.signatories[x].email,
                            displayName: kycMetadata.signatories[x].displayName,
                            method: kycMetadata.signatories[x].method,
                        }
                        break;
                    }
                }

            }
        }

        // Check expiration date
        if (envelope.content.expired) {
            kycMetadata.expired = true;
        }
        kycMetadata.expirationDate = new Date(envelope.content.expiration);


        // Get PDF uid
        if (envelope.content.documents[0].files) {
            if (envelope.content.documents[0].files.document) {
                kycMetadata.fileUid = envelope.content.documents[0].files.document.uid;
            }
            if (envelope.content.documents[0].files.signedDocument) {
                kycMetadata.fileUid = envelope.content.documents[0].files.signedDocument.uid;
            }
        }
        kycMetadata.envelopeId = envelope.id;
        kycMetadata.documentId = envelope.documentId;
        kycMetadata.envelopeDateCreated = envelope.content.created;

        kycMetadata.editorIsNextSigner = kycMetadata.editor.email == kycMetadata.nextSigner.email;

        // Shareholder book logic

        if (envelope.userdata && envelope.userdata.changes) {
            //console.log('changes', envelope.userdata.changes);
            kycMetadata.changes = comparison.compareData(envelope.userdata.amlOriginalData, envelope.userdata, true);

            if (envelope.userdata.changes.changed) kycMetadata.editorMadeChanges = true;

            //console.log('env', envelope);
            if (envelope.userdata.attachments) {
                for (const i in envelope.userdata.attachments) {
                    if (envelope.userdata.attachments[i].type == 'shbook') {
                        kycMetadata.shareholderBookProvided = true;
                        kycMetadata.shareholderBooks.push({
                            source: 'kyc',
                            envelopeId: envelope.id,
                            name: envelope.userdata.attachments[i].name
                        });
                    }
                }
            }

        }

    },

    processNotifications(list) {
        const result = {
            events: [],
            lastEvent: 'unknown'
        };
        //console.log('processing notifications', list);
        for (const i in list) {
            result.events.push(list[i].status);
            result.lastEvent = list[i].status;
        }
        return result;
    },

    processKYCPersons(data, persons) {
        for (let i in data.roles) {
            const person = {
                name: data.roles[i].firstName + ' ' + data.roles[i].lastName,
                firstName: data.roles[i].firstName,
                lastName: data.roles[i].lastName,
                dateOfBirth: data.roles[i].dateOfBirth,
                percentage: data.roles[i].percent,
                roleIds: [data.roles[i].roleId],
                role: data.roles[i].role,
                email: data.roles[i].email
            };
            persons.kyc.push(person);
        }
        for (let i in data.rrhs) {
            const person = {
                name: data.rrhs[i].firstName + ' ' + data.rrhs[i].lastName,
                firstName: data.rrhs[i].firstName,
                lastName: data.rrhs[i].lastName,
                dateOfBirth: data.rrhs[i].dateOfBirth,
                percentage: data.rrhs[i].percent,
                roleIds: ['screening_role_rrh'],
                role: 'Reell rettighetshaver',
                email: data.rrhs[i].email
            };
            persons.kyc.push(person);
        }
        for (let i in data.peps) {
            console.log('peps', data.peps[i]);
            const person = {
                kycPep: true,
                kycPepTitle: data.peps[i].title,
                name: data.peps[i].firstName + ' ' + data.peps[i].lastName,
                firstName: data.peps[i].firstName,
                lastName: data.peps[i].lastName,
                dateOfBirth: data.peps[i].dateOfBirth,
                percentage: data.peps[i].percent,
                roleIds: [data.peps[i].roleId],
                role: data.peps[i].role,
                email: data.peps[i].email
            };
            persons.kyc.push(person);
        }
    },

    processKYCPersons2(data, persons) {
        //console.log('2', data);
        for (let i in data.roles) {
            //console.log('role', data.roles[i]);
            const person = {
                firstName: data.roles[i].firstName,
                lastName: data.roles[i].lastName,
                dateOfBirth: data.roles[i].dateOfBirth,
                roleId: data.roles[i].roleId,
                email: data.roles[i].email,
                address: this.processAddressFromKyc(data.roles[i])
            };
            persons.kyc.push(JSON.parse(JSON.stringify(person)));
        }
        if (data.ownerQuestion.hasOwners) {
            for (let i in data.rrhs) {
                const person = {
                    firstName: data.rrhs[i].firstName,
                    lastName: data.rrhs[i].lastName,
                    dateOfBirth: data.rrhs[i].dateOfBirth,
                    roleId: 'screening_role_rrh',
                    percentage: data.rrhs[i].percent,
                    email: data.rrhs[i].email,
                    address: this.processAddressFromKyc(data.rrhs[i])
                };
                persons.kyc.push(JSON.parse(JSON.stringify(person)));
            }
        }
        for (let i in data.peps) {
            const person = {
                firstName: data.peps[i].firstName,
                lastName: data.peps[i].lastName,
                dateOfBirth: data.peps[i].dateOfBirth,
                roleId: 'kycpep',
                email: data.peps[i].email,
                phone: data.peps[i].phone,
                title: data.peps[i].title,
            };
            persons.kyc.push(JSON.parse(JSON.stringify(person)));
        }
        for (let i in data.entities) {
            //console.log('ent', data.entities[i]);
            if (data.entities[i].type == 'PERSON') {
                let firstName = data.entities[i].firstName;
                let lastName = data.entities[i].lastName;
                if (!data.entities[i].firstName) {
                    firstName = (data.entities[i].name || '').split(' ').slice(1).join(' ');
                    lastName = (data.entities[i].name || '').split(' ')[0];
                }
                const person = {
                    firstName: firstName,
                    lastName: lastName,
                    dateOfBirth: data.entities[i].dateOfBirth,
                    roleId: 'screening_role_shareholder',
                    percentage: data.entities[i].percentage,
                };
                persons.kyc.push(JSON.parse(JSON.stringify(person)));
                if (person.percentage > 25) {
                    const newPerson = JSON.parse(JSON.stringify(person));
                    newPerson.roleId = 'screening_role_rrh';
                    persons.kyc.push(JSON.parse(JSON.stringify(newPerson)));
                }
            }
        }
    },

    processAddressFromKyc(person) {
        //console.log('prccaadaa', person);
        const address = {
            street: '',
            zip: '',
            city: '',
            country: '',
        };
        if (person.address) address.street = person.address;
        if (person.zip) address.zip = person.zip;
        if (person.city) address.city = person.city;
        if (person.country) {
            if (person.country == 'Norge') address.country = 'NO';
            address.countryName = person.country;
        }
        //console.log('returning', address);
        return address;
    },

    getSteps(kyc, stepConfig) {
        const steps = [];

        if (!kyc.editor) {
            const config = stepConfig.steps_owner;
            //console.log('oconfig', config);

            for (const i in config) {
                //console.log(config[i]);

                switch (config[i].key) {

                    case 'company':
                        if (kyc.customer.source == 'brreg' || kyc.customer.source == 'trapets') {
                            steps.push({ title: 'kyc_step_company', key: 'brreg', nextDisabled: false, backDisabled: true });
                        }else {
                            steps.push({ title: 'kyc_step_company', key: 'company', nextDisabled: false, backDisabled: true });
                        }
                        break;

                    case 'aagree':
                        steps.push({ title: 'kyc_step_aagree', key: 'aagree', nextDisabled: false, backDisabled: false });
                        break;

                    case 'roles':
                        if (kyc.customer.source == 'brreg') {
                            steps.push({ title: 'kyc_step_roles', key: 'roles', nextDisabled: false, backDisabled: false });
                        }else {
                            steps.push({ title: 'kyc_step_roles', key: 'mroles', nextDisabled: false, backDisabled: false });
                        }
                        break;

                    case 'rrh':
                        steps.push({ title: 'kyc_step_rrh', key: 'rrh', nextDisabled: false, backDisabled: false });
                        break;

                    case 'structure':
                        steps.push({ title: 'kyc_step_entity', key: 'entity', nextDisabled: false, backDisabled: false });
                        break;

                    case 'book':
                        steps.push({ title: 'kyc_step_book', key: 'book', nextDisabled: false, backDisabled: false, hidden: false });
                        break;

                    case 'pep':
                        steps.push({ title: 'kyc_step_pep', key: 'pep', nextDisabled: false, backDisabled: false, hidden: false });
                        break;

                    case 'purpose':
                        if (kyc.loadedCards.cards && kyc.loadedCards.cards.length > 0) {
                            steps.push({ title: 'kyc_step_purpose', key: 'custom', nextDisabled: false, backDisabled: false });
                        }
                        break;

                    case 'recipient':
                        steps.push({ title: 'kyc_step_recipient', key: 'checkout', nextDisabled: true, nextText: 'kyc_send_out', backDisabled: false });
                        break;

                }

            }

        }else {
            const config = stepConfig.steps_editor;
            //console.log('econfig', config);

            for (const i in config) {
                //console.log(config[i]);

                switch (config[i].key) {

                    case 'welcome':
                        steps.push({ title: 'kyc_step_welcome', key: 'start', nextDisabled: false, backDisabled: true });
                        break;

                    case 'company':
                        if (kyc.customer.source == 'brreg' || kyc.customer.source == 'trapets') {
                            steps.push({ title: 'kyc_step_company', key: 'brreg', nextDisabled: false, backDisabled: false });
                        }else {
                            steps.push({ title: 'kyc_step_company', key: 'company', nextDisabled: false, backDisabled: false });
                        }
                        break;

                    case 'aagree':
                        steps.push({ title: 'kyc_step_aagree', key: 'aagree', nextDisabled: false, backDisabled: false, hidden: !kyc.tasksPreviewData });
                        break;

                    case 'roles':
                        if (kyc.customer.source == 'brreg') {
                            steps.push({ title: 'kyc_step_roles', key: 'roles', nextDisabled: false, backDisabled: false });
                        }else {
                            steps.push({ title: 'kyc_step_roles', key: 'mroles', nextDisabled: false, backDisabled: false });
                        }
                        break;

                    case 'rrh':
                        steps.push({ title: 'kyc_step_rrh', key: 'rrh', nextDisabled: false, backDisabled: false });
                        break;

                    case 'structure':
                        steps.push({ title: 'kyc_step_entity', key: 'entity', nextDisabled: false, backDisabled: false });
                        break;

                    case 'book':
                        steps.push({ title: 'kyc_step_book', key: 'book', nextDisabled: false, backDisabled: false, hidden: true });
                        break;

                    case 'pep':
                        steps.push({ title: 'kyc_step_pep', key: 'pep', nextDisabled: false, backDisabled: false, hidden: false });
                        break;

                    case 'purpose':
                        if (kyc.loadedCards.cards && kyc.loadedCards.cards.length > 0) {
                            steps.push({ title: 'kyc_step_purpose', key: 'custom', nextDisabled: false, backDisabled: false });
                        }
                        break;

                    case 'checkout':
                        steps.push({ title: 'kyc_step_signers', key: 'checkout', nextDisabled: true, nextText: 'kyc_send_in', backDisabled: false });
                        break;

                }

            }

        }

        return steps;
    },

    getMailData(root, kycIndex, emailData, language, english) {
        let base = '';
        if (root.isAWS) base = '/web/aml-portal/#';
        const mail = {
            redirect: root.config.kycs[kycIndex].redirect,
            logourl: root.config.kycs[kycIndex].email.logo,
            buttonColor: root.getColor('button'),
            linkColor: root.getColor('text-accent'),

            url: root.baseUrl + base + '/kyc',
            rportal: root.baseUrl + base + '/recipient',
            select: false,
            lang: language,
            english: english,
            checkout: 'new',

            editorLinkUrl: root.config.kycs[kycIndex].email.link,

            // Notify editor - REMOVE when localized is tested
            subject: root.replaceBrackets(root.translations['kyc_email_editor_subject'][language], emailData),
            header: root.replaceBrackets(root.translations['kyc_email_editor_header'][language], emailData),
            message: root.replaceBrackets(root.translations['kyc_email_editor_message'][language], emailData),
            hint: root.replaceBrackets(root.translations['kyc_email_editor_hint'][language], emailData),
            footer: root.replaceBrackets(root.translations['kyc_email_editor_footer'][language], emailData),
            buttonText: root.replaceBrackets(root.translations['kyc_email_editor_button'][language], emailData),

        };
        // Add translations for notify signatory and document signed
        for (let i in root.config.locales) {
            mail[root.config.locales[i].key] = {
                signAction: root.translations['action_sign'][root.config.locales[i].key],
                signVerb: root.translations['action_sign_verb'][root.config.locales[i].key],
                approveAction: root.translations['action_approve'][root.config.locales[i].key],
                approveVerb: root.translations['action_approve_verb'][root.config.locales[i].key],

                
                editorLinkText: root.replaceBrackets(root.translations['kyc_email_editor_link'][root.config.locales[i].key], emailData, true),

                // Notify editor
                editorSubject: root.replaceBrackets(root.translations['kyc_email_editor_subject'][root.config.locales[i].key], emailData, true),
                editorHeader: root.replaceBrackets(root.translations['kyc_email_editor_header'][root.config.locales[i].key], emailData, true),
                editorMessage: root.replaceBrackets(root.translations['kyc_email_editor_message'][root.config.locales[i].key], emailData, true),
                editorButtonText: root.replaceBrackets(root.translations['kyc_email_editor_button'][root.config.locales[i].key], emailData, true),
                editorHint: root.replaceBrackets(root.translations['kyc_email_editor_hint'][root.config.locales[i].key], emailData, true),
                editorFooter: root.replaceBrackets(root.translations['kyc_email_editor_footer'][root.config.locales[i].key], emailData, true),
                // Notify signatory
                signSubject: root.replaceBrackets(root.translations['kyc_email_signer_subject'][root.config.locales[i].key], emailData, true),
                signHeader: root.replaceBrackets(root.translations['kyc_email_signer_header'][root.config.locales[i].key], emailData, true),
                signMessage: root.replaceBrackets(root.translations['kyc_email_signer_message'][root.config.locales[i].key], emailData, true),
                signButtonText: root.replaceBrackets(root.translations['kyc_email_signer_button'][root.config.locales[i].key], emailData, true),
                signHint: root.replaceBrackets(root.translations['kyc_email_signer_hint'][root.config.locales[i].key], emailData, true),
                signFooter: root.replaceBrackets(root.translations['kyc_email_signer_footer'][root.config.locales[i].key], emailData, true),
                // Document signed
                doneSubject: root.replaceBrackets(root.translations['kyc_email_done_subject'][root.config.locales[i].key], emailData, true),
                doneHeader: root.replaceBrackets(root.translations['kyc_email_done_header'][root.config.locales[i].key], emailData, true),
                doneMessage: root.replaceBrackets(root.translations['kyc_email_done_message'][root.config.locales[i].key], emailData, true),
                doneHint: root.replaceBrackets(root.translations['kyc_email_done_hint'][root.config.locales[i].key], emailData, true),
                doneFooter: root.replaceBrackets(root.translations['kyc_email_done_footer'][root.config.locales[i].key], emailData, true),
            }
        }
        return mail;
    },

    getMailData2(root, kycIndex, emailData, language) {
        let base = '';
        if (root.isAWS) base = '/web/aml-portal/#';
        const mail = {
            logourl: root.config.kycs[kycIndex].email.logo,
            baseUrl: root.baseUrl,
            customRedirect: 'no',
            editRoute: base + '/kyc?document_uid={documentUid}',
            signRoute: base + '/landing/{envelopeId}/{recipientId}',
            lang: language,
            buttonColor: root.getColor('button'),
            linkColor: root.getColor('text-accent'),
            values: []
        };
        for (let i in root.config.locales) {
            if (!root.config.locales[i].enabled) continue;
            mail[root.config.locales[i].key] = {
                // Actions
                signAction: root.translations['action_sign'][root.config.locales[i].key],
                signVerb: root.translations['action_sign_verb'][root.config.locales[i].key],
                approveAction: root.translations['action_approve'][root.config.locales[i].key],
                approveVerb: root.translations['action_approve_verb'][root.config.locales[i].key],
                // Notify editor
                editorSubject: root.replaceBrackets(root.translations['kyc_email_editor_subject'][root.config.locales[i].key], emailData, true),
                editorHeader: root.replaceBrackets(root.translations['kyc_email_editor_header'][root.config.locales[i].key], emailData, true),
                editorMessage: root.replaceBrackets(root.translations['kyc_email_editor_message'][root.config.locales[i].key], emailData, true),
                editorButtonText: root.replaceBrackets(root.translations['kyc_email_editor_button'][root.config.locales[i].key], emailData, true),
                editorHint: root.replaceBrackets(root.translations['kyc_email_editor_hint'][root.config.locales[i].key], emailData, true),
                editorFooter: root.replaceBrackets(root.translations['kyc_email_editor_footer'][root.config.locales[i].key], emailData, true),
                // Notify signatory
                signSubject: root.replaceBrackets(root.translations['kyc_email_signer_subject'][root.config.locales[i].key], emailData, true),
                signHeader: root.replaceBrackets(root.translations['kyc_email_signer_header'][root.config.locales[i].key], emailData, true),
                signMessage: root.replaceBrackets(root.translations['kyc_email_signer_message'][root.config.locales[i].key], emailData, true),
                signButtonText: root.replaceBrackets(root.translations['kyc_email_signer_button'][root.config.locales[i].key], emailData, true),
                signHint: root.replaceBrackets(root.translations['kyc_email_signer_hint'][root.config.locales[i].key], emailData, true),
                signFooter: root.replaceBrackets(root.translations['kyc_email_signer_footer'][root.config.locales[i].key], emailData, true),
                // Document signed
                doneSubject: root.replaceBrackets(root.translations['kyc_email_done_subject'][root.config.locales[i].key], emailData, true),
                doneHeader: root.replaceBrackets(root.translations['kyc_email_done_header'][root.config.locales[i].key], emailData, true),
                doneMessage: root.replaceBrackets(root.translations['kyc_email_done_message'][root.config.locales[i].key], emailData, true),
                doneHint: root.replaceBrackets(root.translations['kyc_email_done_hint'][root.config.locales[i].key], emailData, true),
                doneFooter: root.replaceBrackets(root.translations['kyc_email_done_footer'][root.config.locales[i].key], emailData, true),
            }
        }
        return mail;
    },

    getExpirationDay(expirationDays) {
        let expirationDate = new Date();
        expirationDate.setDate(new Date().getDate() + Number(expirationDays));
        const initialDate = new Date(2020, 9, 1);
        const currentDate = new Date(expirationDate);
        currentDate.setHours(0);
        currentDate.setMinutes(0);
        currentDate.setMilliseconds(0);
        const numberOfDays = Math.ceil((currentDate - initialDate) / 8.64e7);
        return numberOfDays;
    },

    getPdfTranslations(root) {
        const translations = [
            'kyc_title',
            'kyc_pdf_sent',
            'common_date',
            'kyc_pdf_filled',
            'kyc_pdf_signatories',
            'company_info_header',
            'kyc_step_roles',
            'common_first_name',
            'common_last_name',
            'common_dob',
            'common_email',
            'common_phone',
            'kyc_step_rrh',
            'kyc_rrh_yes',
            'kyc_rrh_no',
            'kyc_rrh_public',
            'screening_role_rrh',
            'ceo',
            'chair',
            'proprietor',
            'role_customer_contact',
            'screening_role_sign_right',
            'screening_role_procura',
            'screening_role_deputy_chairman',
            'screening_role_board_member',
            'screening_role_deputy_board_member',
            'screening_role_shareholder',
            'screening_role_accountant',
            'kyc_step_pep',
            'kyc_pdf_empty_pep',
            'common_role',
            'common_title',
            'kyc_step_entity',
            'kyc_entity_manual',
            'kyc_entity_file',
            'physical_person',
            'common_firm',
            'common_share',
            'kyc_step_purpose',
            'common_name',
            'company_orgnr',
            'common_address',
            'common_zip',
            'common_city',
            'company_reg_date',
            'company_org_form',
            'common_country',
            'company_business',
            'company_business_codes',
            'company_registered',
            'company_status',
            'company_in_brreg',
            'company_in_business_reg',
            'company_in_mva_reg',
            'company_stat_bankrupt',
            'company_stat_liquidation',
            'company_stat_cliquidation',
            'common_yes',
            'common_no',
            'common_unknown'
        ];
        return receipts.getPdfTranslations(root, translations);
    },

    prepareQuestions(questions, language, translations) {
        try {
            const processedCards = [];
            if (!questions) return [];
            for (const i in questions.cards) {
                const card = questions.cards[i];
                card.rows = [];
                let currentRow = [];
                let widthCount = 0;
                for (const j in questions.cards[i].inputs) {
                    if (!this.isRelevant(questions, questions.cards[i].inputs[j])) continue;
                    const input = questions.cards[i].inputs[j];
                    widthCount += Number(input.width);
                    if (widthCount > 12) {
                        widthCount = Number(input.width);
                        card.rows.push(currentRow);
                        currentRow = [];
                        currentRow.push(questions.cards[i].inputs[j]);
                    }else {
                        currentRow.push(questions.cards[i].inputs[j]);
                    }
                }
                card.rows.push(currentRow);
                if (this.isCardRelevant(questions, questions.cards[i])) processedCards.push(card);
            }

            console.log('CARDFS', processedCards);

            const finalCards = [];
            for (const i in processedCards) {
                const card = {
                    rows: [],
                    header: processedCards[i].hloc ? translations[processedCards[i].header][language] : processedCards[i].header
                };
                for (const m in processedCards[i].rows) {
                    const row = {
                        inputs: []
                    };
                    for (const j in processedCards[i].rows[m]) {
                        const input = processedCards[i].rows[m][j];
                        const field = {
                            width: input.width
                        };
                        if (input.type == 'helpt') {
                            field.type = 'html';
                            field.html = input.dloc ? translations[input.desc][language] : input.desc;
                        }
                        if (input.type == 'check') {
                            field.type = 'check';
                            field.inline = input.inline;
                            field.desc = input.dloc ? translations[input.desc][language] : input.desc;
                            field.options = [];
                            for (const k in input.options) {
                                field.options.push({
                                    desc: input.options[k].key ? translations[input.options[k].key][language] : input.options[k].label,
                                    checked: input.model[k]
                                });
                            }
                        }
                        if (input.type == 'input') {
                            field.type = 'text';
                            field.desc = input.dloc ? translations[input.desc][language] : input.desc;
                            field.value = input.model;
                        }
                        row.inputs.push(field);
                    }
                    card.rows.push(row);
                }
                finalCards.push(card);
            }
            return finalCards;
        }catch (ex) {
            console.error('failed to process questions');
            return [];
        }
    },

    isRelevant(questions, input) {
        if (input.logic) {

            return this.getValueFromKey(questions, input.logicKey);

        }
        return true;
    },

    isCardRelevant(questions, card) {
        if (card.logic) {

            return this.getValueFromKey(questions, card.logicKey);

        }
        return true;
    },

    getValueFromKey(questions, key) {
        // TODO improve performance here, maybe a separate list with key-value links
        for (let i in questions.cards) {
            for (let j in questions.cards[i].inputs) {
                if (questions.cards[i].inputs[j].type == 'check') {
                    for (let k in questions.cards[i].inputs[j].options) {
                        if (k == key) {
                            return questions.cards[i].inputs[j].model[k];
                        }
                    }
                }
            }
        }
        console.error('getValueFromKey() - key dont exist');
        return false;
    },

    getLabelFromKey(questions, key) {
        // TODO improve performance here, maybe a separate list with key-label links
        for (let i in questions.cards) {
            for (let j in questions.cards[i].inputs) {
                if (questions.cards[i].inputs[j].type == 'check') {
                    for (let k in questions.cards[i].inputs[j].options) {
                        if (k == key) {
                            if (questions.cards[i].inputs[j].options[k].key) {
                                return this.__(questions.cards[i].inputs[j].options[k].key);
                            }else {
                                return questions.cards[i].inputs[j].options[k].label;
                            }
                        }
                    }
                }
            }
        }
        console.error('getLabelFromKey() - key dont exist');
        return false;
    },

    autofillQuestions(questions, aml) {
        try {
            console.log('autofilling', questions);
            console.log('autofilling', aml);

            let year = null;
            if (aml.financials && aml.financials.accounting && aml.financials.accounting.years && aml.financials.accounting.years.length > 1) {
                year = aml.financials.accounting.years[0];
            }

            if (!year) return;
            console.log('year', year);

            for (const i in questions.cards) {
                for (const j in questions.cards[i].inputs) {
                    if (questions.cards[i].inputs[j].bsource) {
                        const input = questions.cards[i].inputs[j];

                        console.log('bsource', input.bsource);
                        

                        if (input.bsource == 'egenkap') {

                            const value = year.balanceSheet.equity;
                            if (value > 20000000) {
                                this.setModelIndex(input, 0, true);
                            }else {
                                this.setModelIndex(input, 1, true);
                            }

                        }else if (input.bsource == 'eiendel') {

                            const value = year.balanceSheet.sumAssets;
                            if (value > 200000000) {
                                this.setModelIndex(input, 0, true);
                            }else {
                                this.setModelIndex(input, 1, true);
                            }

                        }else if (input.bsource == 'omset') {

                            const value = year.incomeStatement.econ_total_revenues;
                            if (value > 400000000) {
                                this.setModelIndex(input, 0, true);
                            }else {
                                this.setModelIndex(input, 1, true);
                            }

                        }


                    }
                }
            }

        }catch (e) {
            console.log('autofilling failed');
        }
    },

    setModelIndex(input, index, value) {
        let count = 0;
        for (const k in input.model) {
            if (count == index) {
                input.model[k] = value;
            }
            count += 1;
        }
    }

};
export default kychelper;