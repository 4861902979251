<template>
    <div class="aml-page">
        <vap-form-divider>
            <template v-slot:left>
                <vap-label :text="__('customer_new')" header2 style="margin-top: 20px; margin-bottom: 10px;" />
                <vap-card noPadding>
                    <vap-item float @clicked="onNewCustomerClicked">
                        <div class="common-add">
                            <div class="common-add-icon2">
                                <vap-icon small :icon="'add'" :color="'var(--primary-contrast)'" />
                            </div>
                            <div class="common-add-text">
                                <label>{{ __('customer_new') }}</label>
                            </div>
                        </div>
                    </vap-item>
                </vap-card>

                <vap-label :text="__('home_reminders')" header2 style=" margin-top: 30px; margin-bottom: 10px;" v-if="$root.config.portal.reminders" />
                <vap-card noPadding v-if="$root.config.portal.reminders">
                    <vap-item float @clicked="onReminderClicked">
                        <div class="common-add">
                            <div class="common-add-icon2">
                                <vap-icon small :icon="'reminder'" :color="'var(--primary-contrast)'" />
                            </div>
                            <div class="common-add-text">
                                <label>{{ __('home_reminders') }}</label>
                            </div>
                        </div>
                    </vap-item>
                </vap-card>

                <div v-if="!loading" style="float: left; width: 100%; margin-top: 30px;">
                    <vap-label :text="__('customer_last')" header2 style="margin-bottom: 10px;" />
                    <vap-card noPadding v-for="(customer, i) in customers" :key="i" style="margin-bottom: 10px;">
                        <vap-item padding20 @clicked="onCustomerClicked(customer)">
                            <label style="font-size: 16px; font-weight: bold; color: var(--text-accent);">{{ companyTitleCase(customer.name) }}</label>
                            <label style="font-size: 15px; font-weight: bold; color: var(--text-weak);">{{ ' (' + customer.local.identity + ')' }}</label>
                        </vap-item>
                    </vap-card>
                </div>

                <div style="float: left; width: 100%; margin-top: 30px;" v-if="batches.length > 0">
                    <vap-label :text="__('home_batches')" header2 style="margin-bottom: 10px;" />
                    <vap-card noPadding v-for="(batch, i) in batches" :key="i" style="margin-bottom: 10px;">
                        <vap-item padding20 @clicked="onBatchClicked(batch)">
                            <div class="hbat">
                                <div class="hbat-header">
                                    <label>{{ formatDate('format', batch.dateCreated) }}</label>
                                </div>
                                <div class="hbat-status">
                                    <vap-status-label v-if="batch.completeCount >= batch.customers.length" :text="batch.customers.length + ' ' + __('home_batch_num_notified')" :name="''" completed />
                                    <vap-status-label v-if="batch.completeCount < batch.customers.length" :text="batch.completeCount + ' ' + __('common_of') + ' ' + batch.customers.length + ' ' + __('home_batch_num_notified')" :name="''" />
                                </div>
                            </div>
                        </vap-item>
                        <div class="hbat-delete" v-if="batch.completeCount == 0">
                            <vap-icon :icon="'delete'" action @clicked="onDeleteBatchClicked(i)" />
                        </div>
                    </vap-card>
                </div>

            </template>
            <template v-slot:right>
                <vap-label :text="__('events_last')" header2 style="margin-top: 20px; margin-bottom: 10px;" />

                <div v-if="loading" class="flw100" style="position: relative; padding-top: 100px;">
                    <div style="position: absolute; left: 50%;">
                        <vap-spinner medium />
                    </div>
                </div>

                <vap-card compactList noPadding v-for="(event, i) in events" :key="'hi' + i">
                    <ClientItem
                        :icon="event.icon"
                        :name="companyTitleCase(event.company)"
                        :info="__(event.text)"
                        :date="formatDate('datetime', event.date)"
                        :user="event.user.name"
                        :link="'/client?id=' + event.customerId"
                        :newItem="isNewItem(event.date)"
                    />
                </vap-card>

            </template>
        </vap-form-divider>


        <ve-popup v-if="batchDeletePopup.show" :header="'Slette masseutsendelse?'" :width="300" @close="batchDeletePopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>Er du sikker på at du vil slette denne messeutsendelsen?</label><br><br>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="'Nei'" uppercase @clicked="batchDeletePopup.show = false" />
                    <vap-button :text="'Ja'" uppercase @clicked="deleteBatch" :working="batchDeletePopup.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <!-- <OverlayIcon /> -->

    </div>
</template>

<script>
import log from '../log';
import api from '../portalapi';
import tools from '../tools';
import dataPerson from '../dataPerson';

import ClientItem from '../components/items/ClientItem';

import OverlayIcon from '../components/basic/OverlayIcon.vue';

export default {
    name: 'Home',
    components: {
        ClientItem,
        OverlayIcon
    },
    data() {
        return {
            loading: true,
            customers: [],
            people: [],
            events: [],
            batches: [],
            batchDeletePopup: {
                show: false,
                index: 0,
                working: false
            },
            lastVisitDate: null,
            lastVisitValid: false
        }
    },
    methods: {

        onEventClicked(event) {
            this.$parent.navigate({ path: '/client', query: { id: event.customerId } });
        },

        onCustomerClicked(customer) {
            this.$parent.navigate({ path: '/client', query: { id: customer._id } });
        },

        onNewCustomerClicked() {
            this.$parent.navigate({ path: '/new' });
/*             const data = {

            };
            api.doCustomStuff(data); */

            //api.setCustomerStatus('6132373e0bd1cb3d4c94700d');
        },

        onBatchClicked(batch) {
            this.$parent.navigate({ path: '/batch', query: { id: batch._id } });
        },

        onReminderClicked() {
            this.$parent.navigate({ path: '/reminders' });
        },

        onDeleteBatchClicked(index) {
            this.batchDeletePopup.index = index;
            this.batchDeletePopup.show = true;
        },

        async deleteBatch() {
            this.batchDeletePopup.working = true;

            const id = this.batches[this.batchDeletePopup.index]._id;
            console.log('delete id', id);

            let response = await api.deleteBatch(id);
            console.log('response', response);

            response = await api.getBatches();
            this.batches = response.batches;

            this.batchDeletePopup.working = false;
            this.batchDeletePopup.show = false;
        },

        loadLastVisit() {
            try {
                if (localStorage.getItem('lastVisit')) {
                    this.lastVisitDate = new Date(localStorage.getItem('lastVisit'));
                    this.lastVisitValid = (this.lastVisitDate instanceof Date && !isNaN(this.lastVisitDate));
                }
                localStorage.setItem('lastVisit', new Date());
                if (this.lastVisitValid) {
                    log.v('Home: Last visit date', this.lastVisitDate);
                }else {
                    log.w('Home: Last visit date is invalid');
                }
            }catch (ex) {
                log.w('Home: Failed to load last visit date');
            }
        },

        isNewItem(date) {
            try {
                const now = new Date();
                let diffTime = Math.abs(now - date);
                const eventAge = Math.ceil(diffTime / (1000 * 60));
                let timeSinceLastVisit = 0;
                if (this.lastVisitValid) {
                    diffTime = Math.abs(now - this.lastVisitDate);
                    timeSinceLastVisit = Math.ceil(diffTime / (1000 * 60)); 
                }

                //console.log('Event is ' + eventAge + ' minutes old');
                //console.log('Last visit was ' + timeSinceLastVisit + ' minutes ago');

                if (timeSinceLastVisit != 0 && eventAge < timeSinceLastVisit) {
                    return true;
                }else if (eventAge < 5) {
                    return true;
                }else {
                    return false;
                }
            }catch (ex) {
                return false;
            }
        },

        async getCustomers() {
            const filters = [
                { field: 'userId', op: 'is', value: this.$root.credentials.uid }
            ];
            const from = 0;
            const limit = 12;
            const sort = { dateUpdated: -1 };
            const include = [];
            const counts = [];
            const response = await api.queryCustomers(filters, from, limit, sort, include, counts);
            return response.customers;
        },

        async getPeople() {
            const filters = [
                { field: 'userId', op: 'is', value: this.$root.credentials.uid }
            ];
            const from = 0;
            const limit = 20;
            const sort = { dateUpdated: -1 };
            const include = [];
            const counts = [];
            const response = await api.queryPeople(filters, from, limit, sort, include, counts);
            return response.people;
        }

    },
    async created() {
        if (this.$root.config.portal.useV1) {
            this.$parent.navigate({ path: '/v1clients' });
            return;
        }

        this.loading = true;
        this.$parent.setTitle(this.__('dashboard'));

        this.loadLastVisit();

        if (this.$root.config.modules.massCompany) {
            const response = await api.getBatches();
            this.batches = response.batches;
        }

        // Get latest customers from backend
        this.customers = await this.getCustomers();
        this.people = await this.getPeople();


        for (const i in this.customers) {
            let identity = this.customers[i].identity;
            if (this.customers[i].type == 'person') {
                const person = tools.decodePerson(this.customers[i].identity);
                identity = person.dateOfBirth;
            }
            this.customers[i].local = {
                batchStatus: 0,
                identity: identity,
                userName: this.$root.getEmployeeName(this.customers[i].userId)
            };
        }

        // Add events
        for (const i in this.customers) {
            if (this.customers[i].aml) {
                this.events.push({
                    text: 'home_event_aml',
                    icon: 'file-search',
                    date: new Date(this.customers[i].aml.searchDate),
                    company: this.customers[i].name,
                    customerId: this.customers[i]._id,
                    user: this.$root.getEmployee(this.customers[i].aml.userId)
                });
            }
            if (this.customers[i].kyc) {
                this.events.push({
                    text: 'home_event_kyc_sent',
                    icon: 'file-sent',
                    date: new Date(this.customers[i].kyc.sentDate),
                    company: this.customers[i].name,
                    customerId: this.customers[i]._id,
                    user: this.$root.getEmployee(this.customers[i].kyc.userId)
                });
                if (this.customers[i].kyc.completedDate) {
                    this.events.push({
                        text: 'home_event_kyc_filled',
                        icon: 'file-check',
                        date: new Date(this.customers[i].kyc.completedDate),
                        company: this.customers[i].name,
                        customerId: this.customers[i]._id,
                        user: {}
                    });
                }
            }
            if (this.customers[i].report) {
                this.events.push({
                    text: 'home_event_report',
                    icon: 'check-c',
                    date: new Date(this.customers[i].report.reportDate),
                    company: this.customers[i].name,
                    customerId: this.customers[i]._id,
                    user: this.$root.getEmployee(this.customers[i].report.userId)
                });
            }
        }

        for (const i in this.people) {
            //console.log('people', this.people[i]);
            const person = tools.decodePerson(this.people[i].identity);
            if (this.people[i].aml) {
                this.events.push({
                    text: 'home_event_pep',
                    icon: 'client-search',
                    date: new Date(this.people[i].aml.searchDate),
                    company: person.firstName + ' ' + person.lastName,
                    customerId: this.people[i].aml.customerId,
                    user: this.$root.getEmployee(this.people[i].aml.userId)
                });
            }
            if (this.people[i].idc) {
                this.events.push({
                    text: 'home_event_idc_sent',
                    icon: 'file-sent',
                    date: new Date(this.people[i].idc.sentDate),
                    company: person.firstName + ' ' + person.lastName,
                    customerId: this.people[i].idc.customerId,
                    user: this.$root.getEmployee(this.people[i].idc.userId)
                });
                if (this.people[i].idc.completed) {
                    this.events.push({
                        text: 'home_event_idc_completed',
                        icon: 'card',
                        date: new Date(this.people[i].idc.completedDate),
                        company: person.firstName + ' ' + person.lastName,
                        customerId: this.people[i].idc.customerId,
                        user: this.$root.getEmployee(this.people[i].idc.userId)
                    });
                }
            }
        }

        // Sort event list by date
        this.events.sort((b, a) => (a.date > b.date) ? 1 : -1);
        // Don't show more than 10 events
        if (this.events.length > 10) this.events.splice(10);


        this.loading = false;
    }
}
</script>
<style scoped>

.hbat {
    position: relative;
}
.hbat-header {
    font-size: 17px;
    font-weight: bold;
    color: var(--text-accent);
}
.hbat-delete {
    position: absolute;
    top: 12px;
    right: 16px;
}

</style>