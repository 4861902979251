<template>
<div class="amlv1-tag-list">
    
    <div class="amlv1-tag-list-itm" v-for="(tag, i) in client.tags" :key="'t' + i">
        <div class="amlv1-tag-list-itm-tag">
            {{ getTagDisplay(tag) }}
        </div>
        <div class="amlv1-tag-list-itm-del" v-if="editable">
            <vap-icon tiny :icon="'cancel'" :color="'ext'" absolute :top="0" :right="0" action @clicked="onRemoveClicked(tag)" />
        </div>
    </div>

    <div class="amlv1-tag-list-add" v-if="editable" @click.stop="onAddClicked">
        <vap-icon tiny :icon="'add'" :color="'ext'" absolute :top="1" :right="3" />
    </div>

    <TagDialog v-if="addDialog.show" :client="client" @close="addDialog.show = false" @reload="$emit('reload', $event)" />

    <TagRemoveDialog v-if="removeDialog.show" :params="removeDialog" :client="client" @close="removeDialog.show = false" @reload="$emit('reload', $event)" />

</div>
</template>
<script>
import TagDialog from '../v1client/TagDialog.vue';
import TagRemoveDialog from '../v1client/TagRemoveDialog.vue';

export default {
    name: 'TagList',
    components: {
        TagDialog,
        TagRemoveDialog,
    },
    props: {
        client: { type: Object, default: null },
        editable: { type: Boolean, default: false },
    },
    data() {
        return {
            addDialog: { show: false },
            removeDialog: { show: false, tag: '' },
        }
    },
    methods: {
        
        onRemoveClicked(tag) {
            this.removeDialog.tag = tag;
            this.removeDialog.show = true;
        },

        onAddClicked() {
            this.addDialog.show = true;
        },

        getTagDisplay(tag) {
            for (const i in this.$root.config.tags.list) {
                if (this.$root.config.tags.list[i].key == tag) return this.$root.config.tags.list[i].text[this.$root.locale.key];
            }
            return tag;
        },

    },
}
</script>
<style scoped>

.amlv1-tag-list {
    display: flex;
}

.amlv1-tag-list-itm {
    position: relative;
    display: flex;
    height: 24px;
    margin-right: 5px;
    border-radius: 3px;
    color: var(--accent-contrast);
    fill: var(--accent-contrast);
    background-color: var(--accent);
    box-sizing: border-box;
}

.amlv1-tag-list-itm-tag {
    padding: 3px 8px;
    font-size: 15px;
}

.amlv1-tag-list-itm-del {
    width: 14px;
}

.amlv1-tag-list-add {
    position: relative;
    width: 29px; height: 24px;
    border-radius: 3px;
    fill: var(--accent-contrast);
    background-color: var(--accent);
    box-sizing: border-box;
    cursor: pointer;
}
.amlv1-tag-list-add:hover {
    opacity: 0.7;
}

</style>