<template>
<div>

    <PopupV1 :header="__t('error-title')" @close="onClose" :width="800" :dialog="dialog" :preventClosing="true">
        <template v-slot:content>
            <DialogContent>
                <div style="margin-top: 16px; font-size: 17px; color: var(--val-error);">
                    {{ __t('error') + ': ' + $root.v1exceptions[0].name }}
                </div>
                <div style="margin-top: 10px; font-size: 15px; color: var(--text-weak);">
                    {{ $root.v1exceptions[0].stack }}
                </div>
            </DialogContent>
            <div style="margin-top: 16px; color: var(--text-title); font-style: italic;" v-if="!errorReported">
                <span>{{ __t('error-status-1') + ' ' }}</span>
                <a href="https://status.verified.eu" target="_blank">{{ 'status.verified.eu' }}</a>
                <span>{{ ' ' + __t('error-status-2') }}</span>
            </div>
            <div style="margin-top: 6px; color: var(--text-title); font-style: italic;" v-if="!errorReported">
                {{ __t('error-submit-hint') + ' "' + __('error_submit') + '".' }}
            </div>
            <div v-if="errorReported" style="padding-left: 14px;">
                <div style="margin-top: 6px; font-weight: bold; color: var(--success-background);" >
                    {{ __t('error-submit-ok') }}
                </div>
                <div style="margin-top: 6px; color: var(--text-title);">
                    <span style="color: var(--text-weak);">{{ 'ID: ' }}</span>
                    <span style="font-weight: bold;">{{ $root.v1exceptions[0].errorId }}</span>
                </div>
                <div style="margin-top: 6px; color: var(--text-weak); font-style: italic;">
                    {{ __t('error-id-hint') }}
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-close')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__('error_reload')" :icon="'refresh'" outline @clicked="onReload" />
            <VFButton :text="__('error_submit')"
                :icon="'file-sent'"
                @clicked="reportError"
                :working="dialog.working"
                :disabled="loading || errorReported"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1exceptions from '../v1helpers/v1exceptions';
import v1backoffice from '../v1admin/v1backoffice';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';

export default {
    name: 'ExceptionDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            errorReported: false,
        }
    },
    methods: {

        async reportError() {
            this.dialog.working = true;
            this.dialog.status = 'Submitting error report';

            const result = await v1backoffice.addError(this.$root.v1exceptions[0]);
            if (result.id) this.errorReported = true;

            this.dialog.working = false;
        },

        onReload() {
            this.onClose();
            location.reload();
        },

        onClose() {
            this.$root.v1exceptions = [];
        }

    },
    created() {
        this.loading = false;
    }
}
</script>