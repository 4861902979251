<template>
<div>

    <PopupV1 :header="'ID control details'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <DialogContent customContent v-if="params.person.idc.method != 'manual'">
                <VFRow>
                    <VFGroup c6>
                        <VFLabel desc :text="'Confirmed First Name'" /><br>
                        <VFLabel input :text="params.person.idc.firstName" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Confirmed Last Name'" /><br>
                        <VFLabel input :text="params.person.idc.lastName" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Confirmed Date of Birth'" /><br>
                        <VFLabel input :text="params.person.idc.dateOfBirth" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Confirmed SSN'" /><br>
                        <VFLabel input :text="params.person.idc.ssn" />
                    </VFGroup>
                </VFRow>
            </DialogContent>
            <DialogContent customContent style="margin-top: 20px;" v-if="params.person.idc.method == 'bankid-no'">
                <VFRow>
                    <VFGroup c6>
                        <VFLabel desc :text="'Identification Method'" /><br>
                        <VFLabel input :text="getMethod(params.person.idc)" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Date'" /><br>
                        <VFLabel input :text="formatDate('format', params.person.idc.date)" />
                    </VFGroup>
                    <VFGroup c12>
                        <VFLabel desc :text="'Issuing Bank'" /><br>
                        <VFLabel input :text="getIssuingBank(params.person.idc)" />
                    </VFGroup>
                </VFRow>
            </DialogContent>
            <DialogContent customContent style="margin-top: 20px;" v-if="params.person.idc.method == 'bankid-se'">
                <VFRow>
                    <VFGroup c6>
                        <VFLabel desc :text="'Identification Method'" /><br>
                        <VFLabel input :text="getMethod(params.person.idc)" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Date'" /><br>
                        <VFLabel input :text="formatDate('format', params.person.idc.date)" />
                    </VFGroup>
                </VFRow>
            </DialogContent>
            <DialogContent customContent style="margin-top: 20px;" v-if="params.person.idc.method == 'passport-reader'">
                <VFRow>
                    <VFGroup c6>
                        <VFLabel desc :text="'Identification Method'" /><br>
                        <VFLabel input :text="getMethod(params.person.idc)" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Date'" /><br>
                        <VFLabel input :text="formatDate('format', params.person.idc.date)" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Document Type'" /><br>
                        <VFLabel input :text="getDocumentType(params.person.idc)" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Document Number'" /><br>
                        <VFLabel input :text="params.person.idc.documentNumber" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Issuing Country'" /><br>
                        <VFLabel input :text="params.person.idc.issuingCountry" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Gender'" /><br>
                        <VFLabel input :text="params.person.idc.gender" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Nationality'" /><br>
                        <VFLabel input :text="params.person.idc.nationality" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Face Match'" /><br>
                        <VFLabel input :text="params.person.idc.faceMatch" />
                    </VFGroup>
                </VFRow>
            </DialogContent>
            <DialogContent customContent v-if="params.person.idc.method == 'manual'">
                <VFRow>
                    <VFGroup c6>
                        <VFLabel desc :text="'Identification Method'" /><br>
                        <VFLabel input :text="getMethod(params.person.idc)" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Date'" /><br>
                        <VFLabel input :text="formatDate('format', params.person.idc.date)" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Confirmed by'" /><br>
                        <VFLabel input :text="getEmployeeName(params.person.idc.userId)" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'SSN'" /><br>
                        <VFLabel input :text="params.person.idc.ssn" />
                    </VFGroup>
                    <VFGroup c12>
                        <VFLabel desc :text="'Comment'" /><br>
                        <VFLabel input :text="params.person.idc.comment" />
                    </VFGroup>
                </VFRow>
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-close')" outline @clicked="onClose" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';

export default {
    name: 'IdentityInfoDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        VFRow,
        VFGroup,
        VFLabel
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            dialog: { show: true },
        }
    },
    methods: {

        onClose() {
            this.$emit('close');
        },

        getMethod(idc) {
            let str = '';
            switch (idc.method) {
                case 'bankid-no': str = this.__t('method-bankid-no'); break;
                case 'bankid-se': str = this.__t('method-bankid-se'); break;
                case 'passport-reader': str = this.__t('method-passport-reader'); break;
                case 'manual': str = this.__t('method-manual'); break;
            }
            if (idc.source == 'signature') {
                str += ' (' + this.__t('verified-signing') + ')';
            }
            return str;
        },

        getIssuingBank(idc) {
            //console.log('orig', idc.originator);
            if (!idc.originator) return 'Unknown Bank';

            const parts = idc.originator.split(';');
            //console.log('parts', parts);

            for (const i in parts) {
                const item = parts[i].split('=');
                if (item.length > 1) {
                    if (item[0] == 'OriginatorName') {
                        return item[1];
                    }
                }
            }

            return 'Unknown Bank';
        },

        getDocumentType(idc) {
            if (idc.documentType == 'P') {
                return 'Passport (P)';
            }else if (idc.documentType == 'C') {
                return 'Residence Card (C)';
            }
            return 'Unknown';
        },

    },
    async created() {
        this.loading = false;
        //console.log('par', this.params.person.idc);
    }
}
</script>