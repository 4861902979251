<template>
<div>

    <RiskItem v-for="(region, i) in regions" :key="'nc' + i"
        :level="1"
        :display="region.nb_NO"
    >
        <template v-slot:risk>
            <RiskScoreControl
                :params="riskParams"
                :source="source"
                :rId="'c' + region.key"
            />
        </template>

        <RiskItem v-for="(level2, j) in region.subregions" :key="'n2' + j"
            :level="2"
            :display="level2.nb_NO"
        >
            <template v-slot:risk>
                <RiskScoreControl
                    :params="riskParams"
                    :source="source"
                    :rId="'c' + region.key + level2.key"
                    :rId1="'c' + region.key"
                />
            </template>

            <RiskItem v-for="(level3, k) in level2.countries" :key="'n3' + k"
                :level="3" lastLevel
                :code="level3.alpha2"
                :flag="level3.alpha2"
                :display="level3.name.nb_NO"
            >
                <template v-slot:risk>
                    <RiskScoreControl
                        :params="riskParams"
                        :source="source"
                        :rId="'c' + region.key + level2.key + level3.alpha2"
                        :rId1="'c' + region.key + level2.key"
                        :rId2="'c' + region.key"
                    />
                </template>

            </RiskItem>
        </RiskItem>
    </RiskItem>

</div>
</template>
<script>
import RiskItem from './RiskItem.vue';
import RiskScoreControl from './RiskScoreControl.vue';

export default {
    name: 'RiskCountrySettings',
    components: {
        RiskItem,
        RiskScoreControl,
    },
    props: {
        source: { type: String, default: 'xx' },
        riskParams: { type: Object, default: null },
    },
    data() {
        return {
            regions: []
        }
    },
    methods: {
        
        getRegions() {
            const array = [
                { key: 0, nb_NO: 'Europa' },
                { key: 1, nb_NO: 'Amerika' },
                { key: 2, nb_NO: 'Asia' },
                { key: 3, nb_NO: 'Afrika' },
                { key: 4, nb_NO: 'Annet' },
            ];
            return array;
        },

        getSubRegions() {
            const array = [
                { key: 0, mainKey: 0, en_EN: 'Nordics', nb_NO: 'Norden' },
                { key: 1, mainKey: 0, en_EN: 'EU/EFTA', nb_NO: 'EU/EFTA' },
                { key: 2, mainKey: 0, en_EN: 'Outside EU', nb_NO: 'Utenfor EU' },

                { key: 0, mainKey: 1, en_EN: 'North America', nb_NO: 'Nord-Amerika' },
                { key: 1, mainKey: 1, en_EN: 'Central America', nb_NO: 'Mellom-Amerika' },
                { key: 2, mainKey: 1, en_EN: 'South America', nb_NO: 'Sør-Amerika' },
                { key: 3, mainKey: 1, en_EN: 'Caribbean', nb_NO: 'Karibia' },

                { key: 0, mainKey: 2, en_EN: 'Middle East', nb_NO: 'Midtøsten' },
                { key: 1, mainKey: 2, en_EN: 'West Asia', nb_NO: 'Vest-Asia' },
                { key: 2, mainKey: 2, en_EN: 'East Asia', nb_NO: 'Øst-Asia' },
                { key: 3, mainKey: 2, en_EN: 'South Asia', nb_NO: 'Sør-Asia' },
                { key: 4, mainKey: 2, en_EN: 'Southeast Asia', nb_NO: 'Sørøst-Asia' },
                { key: 5, mainKey: 2, en_EN: 'Central Asia', nb_NO: 'Sentral-Asia' },

                { key: 0, mainKey: 3, en_EN: 'Northern Africa', nb_NO: 'Nord-Afrika' },
                { key: 1, mainKey: 3, en_EN: 'Southern Africa', nb_NO: 'Sørlige Afrika' },
                { key: 2, mainKey: 3, en_EN: 'Western Africa', nb_NO: 'Vest-Afrika' },
                { key: 3, mainKey: 3, en_EN: 'Eastern Africa', nb_NO: 'Øst-Afrika' },
                { key: 4, mainKey: 3, en_EN: 'Central Africa', nb_NO: 'Sentral-Afrika' },

                { key: 0, mainKey: 4, en_EN: 'Oceania', nb_NO: 'Oseania' },
                { key: 1, mainKey: 4, en_EN: 'Pacific Islands', nb_NO: 'Stillehavsøyer' },
                { key: 2, mainKey: 4, en_EN: 'Other', nb_NO: 'Annet' },
            ];
            return array;
        },

    },
    async created() {

        this.regions = this.getRegions();
        for (const i in this.regions) {
            this.$set(this.regions[i], 'expanded', false);
            this.$set(this.regions[i], 'subregions', []);
        }
        const subregions = this.getSubRegions();
        for (const i in subregions) {
            const subregion = subregions[i];
            subregion.expanded = false;
            subregion.countries = [];
            for (const j in this.regions) {
                if (subregions[i].mainKey == this.regions[j].key) {
                    this.regions[j].subregions.push(subregion);
                }
            }
        }

        for (const i in this.$root.countries) {
            const country = this.$root.countries[i];
            for (const j in this.regions) {
                if (country.region == this.regions[j].key) {
                    for (const k in this.regions[j].subregions) {
                        if (this.regions[j].subregions[k].key == country.subregion) {
                            this.regions[j].subregions[k].countries.push(country);
                        }
                    }
                }
            }
        }

    }
}
</script>