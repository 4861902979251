import v1api from "./v1api";
const v1tasks = {

    async addFormStatusTask(root, envelope, userdata) {
        const task = {
            type: 'form-status',
            clientId: userdata.credentials.id,
            delay: 5,
            info: {
                formKey: userdata.formConfig.key,
                clientToken: userdata.credentials.bearerToken,
                envelopeId: envelope.id,
                accountId: userdata.credentials.cid,
                accountKey: userdata.credentials.key,
            }
        };
        await v1api.addTask(task);
    },

    async addFormReminderTask(root, envelope, userdata) {
        const task = {
            type: 'form-reminder',
            clientId: userdata.credentials.id,
            delay: root.config.features.autoReminders.defaultDelayMinutes,
            info: {
                formKey: userdata.formConfig.key,
                clientToken: userdata.credentials.bearerToken,
                envelopeId: envelope.id,
                accountId: userdata.credentials.cid,
                accountKey: userdata.credentials.key,
            }
        };
        await v1api.addTask(task);
    },

};
export default v1tasks;