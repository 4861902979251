<template>
    <div>
        
        <vap-card noPadding>

            <vap-item float padding20 @clicked="showList = true">

                <div>
                    <div class="vep-add-icon">
                        <svg height="32" version="1.1" width="32" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.55);">
                            <path d="M14.5 1.5C14.5 0.7 15.2 0 16 0C16.8 0 17.5 0.7 17.5 1.5V30.5C17.5 31.3 16.8 32 16 32C15.2 32 14.5 31.3 14.5 30.5V1.5Z"/>
                            <path d="M30.5 14.5C31.3 14.5 32 15.2 32 16C32 16.8 31.3 17.5 30.5 17.5H1.5C0.7 17.5 0 16.8 0 16C0 15.2 0.7 14.5 1.5 14.5H30.5Z"/>
                        </svg>
                    </div>
                    <div class="vep-add-text">
                        <div class="vep-add-text1">
                            <label>{{ __('recipient_select') }}</label>
                        </div>
                        <div class="vep-add-text2">
                            <!-- <label>Eller velg en person fra listen til høyre</label> -->
                        </div>
                    </div>
                </div>

            </vap-item>

        </vap-card>

        <ve-popup v-if="showList" @close="showList = false" :header="__('recipient_select_person')">
            <div style="max-height: 630px; overflow: auto;">
                <vap-item v-for="(person, i) in persons" :key="i" padding20 @clicked="setSignerFromList(person)">
                    <label style="color: var(--text-accent); font-weight: bold;">
                        {{ person.firstName + ' ' + person.lastName }}
                    </label>
                    <label v-if="person.dateOfBirth" style="color: darkgray; font-weight: bold; margin-left: 7px;">
                        {{ '(' + person.dateOfBirth + ')' }}
                    </label>
                    <br>
                    <label>{{ getRole(person) }}</label>
                </vap-item>
            </div>
            <template v-slot:footer>
                <vap-item fancy fancyCircle padding20 :fancyIcon="'add'"
                    :fancyText="__('recipient_list_missing')"
                    :fancyHint="__('list_add_manually')"
                    @clicked="setManualSigner">
                </vap-item>
            </template>
        </ve-popup>

    </div>
</template>

<script>

export default {
    name: 've-recipient-select',
    components: {
        
    },
    props: {
        persons: {
            type: Array,
            default: undefined
        },
    },
    data() {
        return {
            signers: [],
            showList: false,
            listIndex: 0,
        }
    },
    computed: {
        
    },
    watch: {
        
    },
    methods: {
        setSignerFromList(person) {
            this.signers[this.listIndex].fname = person.firstName;
            this.signers[this.listIndex].lname = person.lastName;
            if (person.email) this.signers[this.listIndex].email = person.email;
            this.showList = false;
            this.$emit('selected', this.signers[this.listIndex]);
        },
        setManualSigner() {
            this.signers[this.listIndex].fname = '';
            this.signers[this.listIndex].lname = '';
            this.signers[this.listIndex].email = '';
            this.showList = false;
            this.$emit('selected', this.signers[this.listIndex]);
        },
        addEmptySigner() {
            this.signers.push({
                expanded: true,
                saved: false,
                sign: true,
                approve: false,
                fname: '',
                lname: '',
                email: '',
                method: 'BankID',
                fileUpload: {}
            });
        },
        getRole(person) {
            console.log('person', person)
            let count = 0;
            let str = '';
            for (const i in person.roleIds) {
                if (count > 0) str += ', ';
                str += this.__(person.roleIds[i]);
                count += 1;
            }
            return str;
        }
    },
    async mounted() {
        this.addEmptySigner();
    }
}
</script>
<style scoped>

.vep-add {
    position: relative;
    float: left;
    width: 100%;
    padding: 14px;
}

.vep-add-icon {
    float: left;
    margin-top: 2px;
    margin-left: 10px;
    width: 38px;
    height: 38px;
    padding-top: 3px;
    padding-left: 0px;
    border-radius: 50%;
    background-color: var(--primary);
    text-align: center;
    box-sizing: border-box;
}
.vep-add-icon path {
    fill: white;
}
.vep-add-icon label {
    display: block;
    font-size: 17px;
    font-weight: normal;
    color: white;
    padding-top: 6px;
}

.vep-add-text {
    float: left;
    margin-left: 16px;
    margin-top: 10px;
}
.vep-add-text-n {
    float: left;
    margin-left: 16px;
    margin-top: 2px;
}

.vep-add-text1 label {
    margin-top: 9px;
    font-size: 17px;
    font-weight: bold;
    color: var(--primary);
    cursor: pointer;
}

.vep-add-text2 {
    margin-top: -1px;
}
.vep-add-text2 label {
    cursor: pointer;
}

.flw100 {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

</style>