<template>
<div class="vedpsort nohl" v-on-clickaway="hidePicker" tabindex="0" @focus="onFocus" @blur="onBlur" @keydown="handleKeyDown"
    :class="[
        {'val-ok': validation.valid && !noHighlight},
        {'val-error': !validation.valid},
        {'val-show': validation.show},
        {'vedpsort-right': right},
        {'vedpsort-left': !right},
    ]">
    
    <div class="vedpsort-input" @click="handleClick">
        <label class="vedpsort-placeholder">{{ __(hintKey) }}</label><br>
        <label>{{ displayValue || placeholder }}</label>
    </div>

    <div v-if="pick && !searchString" class="vedp-picker">

        <div class="vedp-hint" v-if="selectMode">
            <label v-if="showDecades">{{ __('datepicker_select_decade') }}</label>
            <label v-if="showYears">{{ __('datepicker_select_year') }}</label>
            <label v-if="showMonths">{{ __('datepicker_select_month') }}</label>
            <label v-if="!showDecades && !showYears && !showMonths">{{ __('datepicker_select_date') }}</label>
        </div>

        <div class="vedp-nav">
            <div class="vedp-left" @click="navigatePicker(true)" v-if="!showDecades">
                <label>&#9668;</label>
            </div>
            <div class="vedp-middle" @click="onMiddleClick">
                <label>{{ pickerHeader }}</label>
            </div>
            <div class="vedp-right" @click="navigatePicker(false)" v-if="!showDecades">
                <label>&#9658;</label>
            </div>
        </div>

        <div class="vedp-days" v-if="!showMonths && !showYears && !showDecades">
            <div class="vedp-day" v-for="(day, i) in days" :key="i">
                <label>{{ day }}</label>
            </div>
        </div>
        <div class="vedp-dates" v-if="!showMonths && !showYears && !showDecades">
            <div class="vedp-date" v-for="(date, i) in dates" :key="i" :class="[{'vedp-date-disabled' : date.disabled}]" 
                @click="handleDateClick(date.date)">
                <label>{{ date.text }}</label>
            </div>
        </div>
        <div class="vedp-months" v-if="showMonths">
            <div class="vedp-month" v-for="(month, i) in months" :key="i" @click="onMonthClicked(i)">
                <label>{{ month }}</label>
            </div>
        </div>
        <div class="vedp-years" v-if="showYears">
            <div class="vedp-year" v-for="(year, i) in years" :key="i" @click="onYearClicked(i)">
                <label>{{ year }}</label>
            </div>
        </div>
        <div class="vedp-decades" v-if="showDecades">
            <div class="vedp-decade" v-for="(decade, i) in decades" :key="i" @click="onDecadeClicked(i)">
                <label>{{ decade }}</label>
            </div>
        </div>

    </div>

    <div class="vedp-search" v-if="searchString">

        <div class="vedp-search-string">
            <label>{{ searchString + searchRemaining }}</label>
        </div>
        <div class="vedp-search-result" :class="[{'vedp-search-completed': searchCompleted}, {'vedp-search-failed': searchFailed}]">
            <label>{{ resultString }}</label>
        </div>
        <div class="vedp-search-hint">
            <label>{{ hintString }}</label>
        </div>

    </div>

<!--     <svg v-if="!validation.show" height="50" width="50" class="vedp-icon">
        <path d="M 12 0 C 10.90625 0 10 0.90625 10 2 L 10 4 L 4 4 C 3.476563 4 2.945313 4.191406 2.570313 4.570313 C 2.191406 4.945313 2 5.476563 2 6 L 2 46 C 2 46.523438 2.191406 47.054688 2.570313 47.433594 C 2.945313 47.808594 3.476563 48 4 48 L 46 48 C 46.523438 48 47.054688 47.808594 47.433594 47.433594 C 47.808594 47.054688 48 46.523438 48 46 L 48 6 C 48 5.476563 47.808594 4.945313 47.433594 4.570313 C 47.054688 4.191406 46.523438 4 46 4 L 40 4 L 40 2 C 40 0.90625 39.09375 0 38 0 L 36 0 C 34.90625 0 34 0.90625 34 2 L 34 4 L 16 4 L 16 2 C 16 0.90625 15.09375 0 14 0 Z M 12 2 L 14 2 L 14 8 L 12 8 Z M 36 2 L 38 2 L 38 8 L 36 8 Z M 4 6 L 10 6 L 10 8 C 10 9.09375 10.90625 10 12 10 L 14 10 C 15.09375 10 16 9.09375 16 8 L 16 6 L 34 6 L 34 8 C 34 9.09375 34.90625 10 36 10 L 38 10 C 39.09375 10 40 9.09375 40 8 L 40 6 L 46 6 L 46 13 L 4 13 Z M 4 15 L 46 15 L 46 46 L 4 46 Z M 10 19 L 10 42 L 40 42 L 40 19 Z M 12 21 L 17 21 L 17 26 L 12 26 Z M 19 21 L 24 21 L 24 26 L 19 26 Z M 26 21 L 31 21 L 31 26 L 26 26 Z M 33 21 L 38 21 L 38 26 L 33 26 Z M 12 28 L 17 28 L 17 33 L 12 33 Z M 19 28 L 24 28 L 24 33 L 19 33 Z M 26 28 L 31 28 L 31 33 L 26 33 Z M 33 28 L 38 28 L 38 33 L 33 33 Z M 12 35 L 17 35 L 17 40 L 12 40 Z M 19 35 L 24 35 L 24 40 L 19 40 Z M 26 35 L 31 35 L 31 40 L 26 40 Z M 33 35 L 38 35 L 38 40 L 33 40 Z"/>
    </svg> -->

    <!------- Validation ---------------------------------------------------------------------------->
    <svg v-if="validation.show && validation.valid && !noValidation" height="24" width="24" class="val-ic-ok">
        <path d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"/>
    </svg>
    <svg v-if="validation.show && !validation.valid" height="24" width="24" class="val-ic-error">
        <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"/>
    </svg>
    <div v-if="validation.show && !validation.valid" class="val-message vip-error">
        <label>{{ '* ' + __(validation.key) }}</label>
    </div>
    <!------- ----------- -------------------------------------------------------------------------->
</div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway"
import validations from '../../validations'

export default {
    name: 'SortDatePicker',
    mixins: [clickaway],
    props: {
        value: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Velg dato'
        },
        rules: {
            type: String,
            default: ''
        },
        debug: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: "numeric"
        },
        outputISO: {
            type: Boolean,
            default: false
        },
        noValidation: {
            type: Boolean,
            default: false
        },
        noHighlight: {
            type: Boolean,
            default: false
        },
        selectMode: {
            type: Boolean,
            default: false
        },
        right: {
            type: Boolean,
            default: false
        },
        hintKey: {
            type: String,
            default: "common_unknown"
        },
    },
    data() {
        return {
            pick: false,
            date: null,
            pickerDate: new Date(),
            pickerHeader: '',
            displayValue: '',
            days: ['man', 'tir', 'ons', 'tor', 'fre', 'lør', 'søn'],
            dates: [],
            months: [],
            years: [],
            decades: [],
            validation: {
                show: false,
                valid: true,
                key: ''
            },
            showMonths: false,
            showYears: false,
            showDecades: false,
            yearStart: 0,
            searchString: '',
            searchRemaining: '',
            searchCompleted: false,
            searchFailed: false,
            resultString: 'Enter all 6 digits',
            resultDate: undefined,
            hintString: 'Use format DDMMYY',
            language: 'en'
        }
    },
    watch: {
        date: {
            deep: true,
            handler(val) {
                if (this.debug) console.log("date changed to " + this.date);
                if (!val || val == '') this.$emit('');
                this.displayValue = this.formatDate(this.date, this.language, this.format);
                if (this.outputISO) {
                    this.$emit('input', this.formatDate(this.date, 'se', 'numeric'));
                    this.$emit('changed', this.formatDate(this.date, 'se', 'numeric'));
                }else {
                    this.$emit('input', this.formatDate(this.date, this.language, this.format));
                    this.$emit('changed', this.formatDate(this.date, this.language, this.format));
                }
            }
        },
        value: {
            deep: true,
            handler(val) {
                this.loopCount += 1;
                if (this.loopCount > 10) {
                    console.warn("something is wrong with datepicker")
                    this.loopCount = 0;
                    return;
                }
                if (this.debug) console.log("value changed to " + this.value)
                this.date = this.parseDate(this.value);
                this.validate();
            }
        },
        '$root.locale.key': {
            deep: false,
            handler(val) {
                this.setLanguage(val);
            }
        },
        searchString: {
            deep: true,
            handler(val) {
                const count = 6 - val.length;
                this.searchRemaining = '';
                for (let i=0; i<count; i++) this.searchRemaining += '-';
                this.searchCompleted = false;
                this.searchFailed = false;
                this.resultString = 'Enter all 6 digits';
                if (val.length == 6) {
                    if (Number(val.substring(0, 2) < 31) && Number(val.substring(2, 4)) < 12) {
                        const parsedDate = this.parseDate(val.substring(4, 6) + '-' + val.substring(2, 4) + '-' + val.substring(0, 2));
                        this.resultDate = parsedDate;
                        this.resultString = this.formatDate(parsedDate, this.language, this.format);
                        this.searchCompleted = true;
                    }else {
                        this.resultString = 'Invalid format';
                        this.searchFailed = true;
                    }
                    
                }
            }
        }
    },
    methods: {

        setLanguage(val) {
            if (val == 'nb_NO') {
                this.language = 'no';
            }else if (val == 'sv_SE') {
                this.language = 'se';
            }else {
                this.language = 'en';
            }
            this.setDays(this.language);
        },

        validate(value) {
            if (this.noValidation) return;
            if (!value) value = this.value;
            this.validation = {...this.validation, ...validations.validateRules(value, this.rules)};
        },
        handleClick() {
            if (this.selectMode) {
                this.pickerDate = new Date('01.01.1970 12:00');
                this.renderYears();
            }else {
                this.pickerDate = this.date || new Date();
                this.renderPicker();
            }
            this.pick = true;
        },
        handleKeyDown(event) {
            if (event.keyCode == 38) {
                // Up
            }else if (event.keyCode == 40) {
                // Down
            }else if (event.keyCode == 37) {
                // Left
            }else if (event.keyCode == 39) {
                // Right
            }else if (event.keyCode == 33) {
                // Page up
            }else if (event.keyCode == 34) {
                // Page down
            }else if (event.keyCode == 13) {
                // Enter
                if (this.searchCompleted) {
                    this.date = this.resultDate;
                }
                this.hidePicker();
            }else if (event.keyCode == 9) {
                // Tab
                if (this.searchCompleted) {
                    this.date = this.resultDate;
                }
            }else if (event.keyCode == 8) {
                // Backspace
                if (this.searchString.length > 0) this.searchString = this.searchString.substring(0, this.searchString.length - 1);
            }else if (event.keyCode == 46) {
                // Delete
                this.searchString = '';
            }else if (event.keyCode == 27) {
                // Escape
                this.hidePicker();
            }else {
                if (this.searchString.length < 6 && '0123456789'.includes(event.key)) {
                    this.searchString += event.key;
                }
            }
        },
        onFocus() {
            this.handleClick();
        },
        onBlur() {
            //console.log('onblur')
            this.hidePicker();
        },
        hidePicker() {
            this.searchString = '';
            this.pick = false;
        },
        handleDateClick(date) {
            this.date = date;
            this.pick = false;
            if (!this.validation.show && !this.noValidation) this.validation.show = true;
        },
        setDays(val) {
            if (val.toLowerCase().includes('en')) {
                this.days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
            }else if (val.toLowerCase().includes('se')) {
                this.days = ['mån', 'tis', 'ons', 'tor', 'fre', 'lör', 'sön'];
            }else {
                this.days = ['man', 'tir', 'ons', 'tor', 'fre', 'lør', 'søn'];
            }
        },
        navigatePicker(left) {
            if (this.showYears) {
                if (left) {
                    this.yearStart -= 10;
                }else {
                    this.yearStart += 10;
                }
                this.renderYears();
            }else if (this.showMonths) {
                if (left) {
                    this.pickerDate = this.pickerDate.addMonths(-12);
                }else {
                    this.pickerDate = this.pickerDate.addMonths(12);
                }
                this.renderMonths();
            }else {
                if (left) {
                    this.pickerDate = this.pickerDate.addMonths(-1);
                }else {
                    this.pickerDate = this.pickerDate.addMonths(1);
                }
                this.renderPicker();
            }
        },
        onMiddleClick() {
            if (this.showYears) {
                //this.renderDecades();
            }else if (this.showMonths) {
                this.renderYears();
            }else {
                this.renderMonths();
            }
        },
        renderMonths() {
            this.showYears = false; this.showDecades = false;
            this.months = [];
            for (let i=0; i<12; i++) {
                this.months.push(this.getMonthName(i));
            }
            this.showMonths = true;
            this.pickerHeader = this.pickerDate.getFullYear();
        },
        renderYears() {
            this.showMonths = false; this.showDecades = false;
            this.years = [];
            if (this.yearStart == 0) this.yearStart = 1970;
            const endYear = this.yearStart + 9;
            for (let i=0; i<10; i++) {
                this.years.push((this.yearStart + i).toString());
            }
            this.showYears = true;
            this.pickerHeader = this.yearStart.toString() + ' - ' + endYear.toString();
        },
        renderDecades() {
            this.showMonths = false; this.showYears = false;
            this.decades = [];
            const startDecade = 1900;
            const endDecade = 2030;
            for (let i=0; i<10; i++) {
                let decadeS = endDecade - (i * 10);
                let decadeE = decadeS - 10;
                this.decades.unshift(decadeE.toString() + ' - ' + decadeS.toString());
            }
            this.showDecades = true;
            this.pickerHeader = 'Velg tiår';
        },
        onMonthClicked(month) {
            let year = this.pickerDate.getFullYear();
            //console.log(year);
            let mm = (Number(month) + 1).toString();
            if (Number(mm) < 10) mm = '0' + mm;
            let parseDate = year.toString() + '-' + mm + '-10';
            this.pickerDate = new Date(parseDate);
            //console.log(this.pickerDate);
            this.showMonths = false;
            this.renderPicker();
        },
        onYearClicked(year) {
            this.pickerDate.setYear(this.yearStart + year);
            //console.log('year set to ' + year);
            this.renderMonths();
        },
        onDecadeClicked(decade) {
            
        },
        renderPicker() {
            //console.log('render picker');
            //console.log(this.pickerDate);
            this.dates = [];
            let list = this.getDisplayDays(this.pickerDate);
            for (let i in list) {
                this.dates.push({
                    date: list[i],
                    text: list[i].getDate().toString(),
                    disabled: list[i].getMonth() != this.pickerDate.getMonth()
                });
            }
            this.pickerHeader = this.getMonthName(this.pickerDate.getMonth()) + ' ' + this.pickerDate.getFullYear();
        },
        getDisplayDays(date) {
            let list = [];
            let sd = this.testAddDays(date, -32);
            for (let i=0; i<68; i++) {

                //const dd = this.testAddDays(sd, i);
                //console.log(dd);

                list.push(sd.addDays(i));
            }
            //console.log(list);
            let splice = false;
            for (let i=list.length-31; i>=0; i--) {
                if (splice) { list.splice(i, 1); continue; }
                let val = list[i].getMonth() != date.getMonth();
                if (val && (list[i].getDay() == 1 || list[i].getDay() == 0)) splice = true;
                if (splice && list[i].getDay() == 0) list.splice(i, 1);
            }
            let first = true; let spliceIndex = 0;
            for (let i in list) {
                if (i < 8) continue;
                let val = list[i].getMonth() != date.getMonth();
                let wd = list[i].getDay();
                if (val && (wd == 0 || (first && wd == 1))) {
                    spliceIndex = i;
                    if (!first) spliceIndex = Number(i)+1;
                    if (first && wd == 0) spliceIndex = Number(i)+1;
                    break;
                }
                if (val) first = false;
            }
            if (spliceIndex > 0) list.splice(spliceIndex, list.length - spliceIndex);
            //console.log(list);
            return list;
        },
        getMonthName(month) {
            const m_no = ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'];
            const m_se = ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'];
            const m_en = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            let array = m_no;
            if (this.language.toLowerCase().includes('en')) array = m_en;
            if (this.language.toLowerCase().includes('se')) array = m_se;
            return array[month];
        },
        getMonthNumber(name) {
            if (name.includes('januar') || name.includes('januari') || name.includes('January')) {
                return 1;
            }else if (name.includes('februar') || name.includes('februari') || name.includes('February')) {
                return 2;
            }else if (name.includes('mars') || name.includes('mars') || name.includes('March')) {
                return 3;
            }else if (name.includes('april') || name.includes('april') || name.includes('April')) {
                return 4;
            }else if (name.includes('mai') || name.includes('maj') || name.includes('May')) {
                return 5;
            }else if (name.includes('juni') || name.includes('juni') || name.includes('June')) {
                return 6;
            }else if (name.includes('juli') || name.includes('juli') || name.includes('July')) {
                return 7;
            }else if (name.includes('august') || name.includes('augusti') || name.includes('August')) {
                return 8;
            }else if (name.includes('september') || name.includes('september') || name.includes('September')) {
                return 9;
            }else if (name.includes('oktober') || name.includes('oktober') || name.includes('October')) {
                return 10;
            }else if (name.includes('november') || name.includes('november') || name.includes('November')) {
                return 11;
            }else if (name.includes('desember') || name.includes('december') || name.includes('December')) {
                return 12;
            }
        },
        leadingZero(value) {
            if (Number(value) < 10) {
                return '0' + value.toString();
            }else {
                return value;
            }
        },
        parseDate(value) {
            if (this.debug) console.log('pd: ' + value);
            if (!value || value == '') return null;
            var year, month, day;
            if (value.includes('-')) {
                // Swedish / ISO / numeric
                if (this.debug) console.log('pd: SE/ISO numeric');
                const itm = value.split('-');
                year = itm[0]; month = itm[1]; day = itm[2];
            }else if (value.includes('.')) {
                // Norwegian
                if (value.includes(' ')) {
                    // long
                    if (this.debug) console.log('pd: NO long');
                    const itm = value.split('.');
                    const itx = itm[1].replace(' ', '').split(' ');
                    year = itx[1]; month = this.getMonthNumber(itx[0]); day = itm[0];
                }else {
                    // numeric
                    if (this.debug) console.log('pd: NO numeric');
                    const itm = value.split('.');
                    year = itm[2]; month = itm[1]; day = itm[0];
                }
            }else if (value.includes('/')) {
                // English numeric
                if (this.debug) console.log('pd: EN numeric');
                const itm = value.split('/');
                year = itm[2]; month = itm[1]; day = itm[0];
            }else if (value.includes(' ')) {
                // English / Swedish long
                if (this.debug) console.log('pd: EN/SE long');
                const itm = value.split(' ');
                year = itm[2]; month = this.getMonthNumber(itm[1]); day = itm[0];
            }
            //month = month.replace('0', '');
            if (this.debug) console.log('pd: y=' + year + ' m=' + month + ' d=' + day);
            var pd = new Date(Date.UTC(year, month - 1, day));
            if (this.debug) console.log('pd: ', pd);
            return pd;
        },
        formatDate(date, lang, format) {
            if (!date) return '';
            if (this.debug) console.log('fd: l=' + lang + ' f=' + format + ' d=' + date.toISOString().slice(0,10));
            if (this.debug) console.log('fd: y=' + date.getFullYear() + ' m=' + date.getMonth() + ' d=' + date.getDate());
            const m_no = ['januar', 'februar', 'mars', 'april', 'mai', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'desember'];
            const m_se = ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti', 'september', 'oktober', 'november', 'december'];
            const m_en = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            if (lang.toLowerCase().includes('no')) {
                if (format == 'numeric') {
                    return this.leadingZero(date.getDate()) + '.' + this.leadingZero(date.getMonth() + 1) + '.' + date.getFullYear();
                }else {
                    return this.leadingZero(date.getDate()) + '. ' + m_no[date.getMonth()] + ' ' + date.getFullYear();
                }
            }else if (lang.toLowerCase().includes('en')) {
                if (format == 'numeric') {
                    return date.getDate() + '/' + Number(date.getMonth() + 1) + '/' + date.getFullYear();
                }else {
                    return date.getDate() + ' ' + m_en[date.getMonth()] + ' ' + date.getFullYear();
                }
            }else {
                if (format == 'numeric') {
                    return date.getFullYear() + '-' + this.leadingZero(date.getMonth() + 1) + '-' + this.leadingZero(date.getDate());
                }else {
                    return date.getDate() + ' ' + m_se[date.getMonth()] + ' ' + date.getFullYear();
                }
            }
        },

        testAddDays(source, days) {
            let ms = days * 1000 * 60 * 60 * 24;
            let date = new Date(source.getTime() + ms);
            return date;
        },

        initDatePrototype() {
            Date.prototype.addDays = function(days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + Number(days));
                return date;
            }
            Date.isLeapYear = function (year) { 
                return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
            };

            Date.getDaysInMonth = function (year, month) {
                return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
            };

            Date.prototype.isLeapYear = function () { 
                return Date.isLeapYear(this.getFullYear()); 
            };

            Date.prototype.getDaysInMonth = function () { 
                return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
            };

            Date.prototype.addMonths = function (value) {
                var n = this.getDate();
                this.setDate(1);
                this.setMonth(this.getMonth() + value);
                this.setDate(Math.min(n, this.getDaysInMonth()));
                return this;
            };
        }
    },
    async created() {
        this.initDatePrototype();

        this.setLanguage(this.$root.locale.key);

        if (this.value) {
            this.date = this.parseDate(this.value);
        }

    }
}
</script>

<style scoped>

.vedpsort {
    position: relative;
    float: left;
    width: 100%;
}

.vedpsort-left .vedpsort-input {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.vedpsort-right .vedpsort-input {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: 1px;
}

.vedp:focus {
    outline: none;
}
.vedp:focus .vedp-input {
    border: solid 1px var(--input-focus);
}

.vedpsort-placeholder {
    font-size: 15px;
    color: var(--disabled-background);
    font-weight: 500;
    letter-spacing: 0.6px;
}

.vedpsort-input {
    width: 100%;
    padding-top: 11px;
    padding-left: 14px;
    height: 60px;
    box-sizing: border-box;
    cursor: pointer;
    font-weight: 500;
    color: var(--accent);
    background-color: var(--card-background);
}
.vedpsort-input label {
    cursor: pointer;
}
.vedpsort-input:hover {
    background-color: rgb(245, 245, 245);
    border-color: var(--input-focus);
}

.vedp-picker {
    position: absolute;
    top: 60px;
    width: 100%;
    min-width: 300px;
    max-width: 400px;
    min-height: 100px;
    border: solid 1px gray;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: var(--card-background);
    z-index: 2;
    box-sizing: border-box;
}


.vedp-icon {
    position: absolute;
    top: -5px;
    right: -4px;
    transform: scale(0.4);
    pointer-events: none;
}
.vedp-icon path {
    fill: var(--input-label);
}



.vedp-nav {
    display: flex;
    width: 100%;
    border-bottom: solid 1px gray;
}
.vedp-nav label {
    cursor: pointer;
}
.vedp-left, .vedp-right {
    width: 44px;
    height: 36px;
    padding-top: 4px;
    font-size: 20px;
    color: var(--text-weak);
}
.vedp-left, .vedp-right, .vedp-middle {
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
}
.vedp-right {
    float: right;
}
.vedp-middle {
    flex-grow: 100;
    padding-top: 7px;
    font-size: 18px;
    color: var(--text-strong);
}
.vedp-left:hover, .vedp-right:hover, .vedp-middle:hover {
    background-color: lightgray;
}


.vedp-hint {
    padding: 10px 0px;
    text-align: center;
    border-bottom: solid 1px gray;
}


.vedp-days {
    display: flex;
    margin-top: 12px;
    margin-bottom: 14px;
}

.vedp-day {
    width: 14.29%;
    text-align: center;
    color: var(--text-weak);
}




.vedp-dates {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.vedp-date {
    float: left;
    width: 14.28%;
    padding: 12px 0px;
    color: var(--text-strong);
    text-align: center;
    cursor: pointer;
}
.vedp-date label {
    cursor: pointer;
}
.vedp-date:hover {
    -webkit-box-shadow:inset 0px 0px 0px 2px var(--accent);
    -moz-box-shadow:inset 0px 0px 0px 2px var(--accent);
    box-shadow:inset 0px 0px 0px 2px var(--accent);
}

.vedp-date-disabled {
    color: var(--text-disabled);
}



.vedp-months {
    float: left;
    width: 100%;
    box-sizing: border-box;
}
.vedp-month {
    float: left;
    width: 33.3333334%;
    padding: 12px 0px;
    color: var(--text-strong);
    text-align: center;
    cursor: pointer;
}
.vedp-month label {
    cursor: pointer;
}
.vedp-month:hover {
    -webkit-box-shadow:inset 0px 0px 0px 2px var(--accent);
    -moz-box-shadow:inset 0px 0px 0px 2px var(--accent);
    box-shadow:inset 0px 0px 0px 2px var(--accent);
}


.vedp-years {
    float: left;
    width: 100%;
    box-sizing: border-box;
}
.vedp-year {
    float: left;
    width: 33.3333334%;
    padding: 12px 0px;
    color: var(--text-strong);
    text-align: center;
    cursor: pointer;
}
.vedp-year label {
    cursor: pointer;
}
.vedp-year:hover {
    -webkit-box-shadow:inset 0px 0px 0px 2px var(--accent);
    -moz-box-shadow:inset 0px 0px 0px 2px var(--accent);
    box-shadow:inset 0px 0px 0px 2px var(--accent);
}


.vedp-search {
    position: absolute;
    top: 37px;
    width: 100%;
    max-width: 400px;
    min-height: 100px;
    border: solid 1px gray;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: var(--card-background);
    z-index: 2;
    box-sizing: border-box;
}
.vedp-search-string {
    margin-top: 10px;
    font-size: 28px;
    text-align: center;
    font-family: monospace, monospace;
    color: var(--text-strong);
}
.vedp-search-result {
    margin-top: 10px;
    padding: 8px 0px;
    font-size: 18px;
    text-align: center;
    color: var(--text-strong);
    border-top: solid 1px gray;
    border-bottom: solid 1px gray;
}
.vedp-search-completed {
    color: var(--accent-contrast);
    background-color: var(--accent);
}
.vedp-search-failed {
    color: var(--error-text);
    background-color: var(--error-background);
}
.vedp-search-hint {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    text-align: center;
    color: var(--text-weak);
}



/* --- Validation -------------------- */

.val-show.val-ok .vedp-input {
    border-color: var(--val-ok);
}
.val-show.val-error .vedp-input {
    border-color: var(--val-error);
}

.val-ic-ok {
    position: absolute;
    top: 8px;
    right: 8px;
    transform: scale(1.0);
}
.val-ic-ok path {
    fill: var(--val-ok);
}

.val-ic-error {
    position: absolute;
    top: 8px;
    right: 8px;
    transform: scale(0.8);
}
.val-ic-error path {
    fill: var(--val-error);
}

.val-message {
    
}

/* --- ---------- -------------------- */

</style>
