<template>
<div v-if="!loading">
    <PageHeader :actions="actions" compactable :offset="97" @scrolled="scrolled = $event" @action="onAction">
        <template v-slot:content>
            <BarItemContainer>
                <BarItem :desc="'Name'" :value="account.name" />
                <BarItem :desc="'Account Status'" :value="getAccountStatus()" :accented="true" :isEmpty="!isActive()" />
                <BarItem :desc="'Database'" :value="account.key + '-' + account.companyId" />
            </BarItemContainer>
        </template>
        <template v-slot:compact>
            <div v-if="!loading">
                <span>{{ account.name }}</span>
            </div>
        </template>
    </PageHeader>
    <PageContent :scrolled="scrolled" :offset="104" v-if="!loading">
        <div class="aml-page">
            <SplitterV1>
                <template v-slot:left>

                    <!-- Usage Stats -->
                    <vap-label header2 :text="'Usage Stats'" style="margin-bottom: 10px;" />
                    <BoStatCompany :stats="stats" />

                    <!-- Account config -->
                    <vap-card style="margin-top: 20px;">
                        <vap-row>
                            <vap-group c12>
                                <vap-label desc :text="'Display Name'" />
                                <vap-input v-model="account.name" noValidation />
                            </vap-group>
                            <vap-group c6>
                                <vap-label desc :text="'Account status'" />
                                <vap-dropdown v-model="statusModel"
                                    :options="statusOptions"
                                    :displayKey="'display'"
                                    :listKey="'display'"
                                    :outputKey="'value'"
                                    noValidation
                                />
                            </vap-group>
                            <vap-group c6>
                                <vap-label desc :text="'Portal version'" />
                                <vap-dropdown v-model="versionModel"
                                    :options="versionOptions"
                                    :displayKey="'display'"
                                    :listKey="'display'"
                                    :outputKey="'value'"
                                    noValidation
                                />
                            </vap-group>
                        </vap-row>
                        <div class="amladmin-link">
                            <a :href="`https://app.verified.eu/#/admin/companies/${account.companyId}/settings?s=0`" target="_blank">{{ 'Verified Admin Page' }}</a>
                        </div>
                    </vap-card>

                    <!-- Configuration -->
                    <vap-label header2 :text="'Configuration'" style="margin-top: 20px;" />
                    <div class="flw100" style="font-style: italic; color: var(--text-weak); margin-bottom: 6px;">
                        {{ 'Just for reference. Configuration has to be updated while logged in to the account.' }}
                    </div>
                    <ObjectEditor ref="objeditor"
                        :mainObject="selectedObject"
                        :level="0"
                        :name="'OBJECT'"
                        :type="'object'"
                        isFirst
                        initialCollapsed
                        @change="hasChanges = true"
                    />
                    
                </template>
                <template v-slot:right>

                    <!-- User List -->
                    <vap-label header2 :text="'Users'" style="margin-bottom: 10px;" />
                    <div class="amladmin-users">
                        <div class="amladmin-user" v-for="(user, i) in users" :key="'au' + i">
                            <div class="amladmin-ucon">
                                <div class="amladmin-name">
                                    {{ user.givenName + ' ' + user.familyName }}
                                </div>
                                <div class="amladmin-stat">
                                    <div class="amladmin-uadm" v-if="user.isAdmin">
                                        {{ 'Admin'}}
                                    </div>
                                    <div class="amladmin-uadm" v-if="user.isSuper">
                                        {{ 'Superuser'}}
                                    </div>
                                    <div class="amladmin-uid">
                                        {{ user.uid }}
                                    </div>
                                </div>
                            </div>
                            <div class="amladmin-actions">
                                <vap-icon :icon="'locked'" action @clicked="showUserAccessDialog(i)" />
                            </div>
                        </div>
                    </div>
                    
                </template>
            </SplitterV1>
        </div>
    </PageContent>
    <PageFooter :scrolled="scrolled" />

    <PopupV1 v-if="userDialog.show" :header="'Update user'" @close="userDialog.show = false" :dialog="userDialog">
        <template v-slot:content>
            <div style="margin-bottom: 5px; color: var(--text-title);">
                {{ 'User access level' }}
            </div>
            <VFCheckbox inline radio v-model="userDialog.model" :options="userAccessOptions" />
            <div class="flw100" style="margin-top: 20px; color: var(--text-title); font-style: italic;">
                {{ 'NB! Admin access is only for Verified employees, client users that need extra access can be made a Superuser.' }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="userDialog.show = false" :disabled="userDialog.working" />
            <VFButton :text="'Update user'"
                :icon="'check'"
                @clicked="updateUser"
                :working="userDialog.working && !loading"
                :disabled="loading || !userDialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1backoffice from '../v1admin/v1backoffice';
import uiHelper from '../helpers/uihelper';

import PageHeader from '../v1ui/PageHeader.vue';
import PageContent from '../v1ui/PageContent.vue';
import PageFooter from '../v1ui/PageFooter.vue';
import BarItem from '../v1ui/BarItem.vue';
import BarItemContainer from '../v1ui/BarItemContainer.vue';
import SplitterV1 from '../v1ui/SplitterV1.vue';

import BoStatCompany from '../v1admin/BoStatCompany.vue';
import ObjectEditor from '../v1flows/ObjectEditor.vue';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'BackofficeAccount',
    components: {
        PageHeader,
        PageContent,
        PageFooter,
        BarItem,
        BarItemContainer,
        SplitterV1,
        BoStatCompany,
        ObjectEditor,
        PopupV1,
        VFButton,
        VFCheckbox,
    },
    data() {
        return {
            loading: true,
            scrolled: false,

            account: null,
            stats: [],
            users: [],
            config: null,

            actions: [
                { icon: 'delete', action: 'delete' },
                { icon: 'save', action: 'save', working: false }
            ],

            statusModel: '',
            statusOptions: [
                { value: 'demo', display: 'Demo' },
                { value: 'dev', display: 'Development' },
                { value: 'live', display: 'Active' },
                { value: 'gone', display: 'Deactivated' },
            ],

            versionModel: '',
            versionOptions: [
                { value: 'v0', display: 'Version 1.0' },
                { value: 'v1', display: 'Version 1.5' },
            ],

            userDialog: {
                show: false, valid: true, working: false, status: '', error: '',
                index: -1,
                model: { regular: false, super: false, admin: false }
            },
            userAccessOptions: {
                regular: { label: 'Regular' },
                super: { label: 'Superuser' },
                admin: { label: 'Admin' },
            },

            selectedObject: {},
            hasChanges: false
        }
    },
    methods: {

        showUserAccessDialog(index) {
            this.userDialog.index = index;
            this.userDialog.model.admin = false;
            this.userDialog.model.super = false;
            this.userDialog.model.regular = false;
            if (this.users[index].isAdmin) {
                this.userDialog.model.admin = true;
            }else if (this.users[index].isSuper) {
                this.userDialog.model.super = true;
            }else {
                this.userDialog.model.regular = true;
            }
            this.userDialog.show = true;
        },

        getAccountStatus() {
            switch (this.account.status) {
                case 'demo': return 'Demo';
                case 'dev': return 'Development';
                case 'live': return 'Active';
                case 'gone': return 'Deactivated';
            }
            return 'Demo';
        },

        isActive() {
            if (this.account.status == 'live') return true;
            return false;
        },
        
        onAction(action) {
            if (action.action == 'save') {
                this.saveAccount();
            }
        },

        async updateUser() {
            this.userDialog.working = true;
            const user = this.users[this.userDialog.index];

            const userData = {
                isAdmin: this.userDialog.model.admin,
                isSuper: this.userDialog.model.super
            };
            await v1backoffice.updateUser(this.account._id, user.uid, userData);

            await this.reloadCompany();

            this.userDialog.working = false;
            this.userDialog.show = false;
        },

        async saveAccount() {
            uiHelper.setCardActionWorking(this.actions, 'save', true);

            const updateData = {
                name: this.account.name,
                status: this.statusModel,
                version: this.versionModel,
            };
            await v1backoffice.updateAccount(this.account._id, updateData);

            await this.reloadCompany();

            uiHelper.setCardActionWorking(this.actions, 'save', false);
        },

        loadConfiguration() {
            this.selectedObject = JSON.parse(JSON.stringify(this.config));

            delete this.selectedObject.portal.header;
            delete this.selectedObject.portal.logoUrl;
            delete this.selectedObject.portal.logoWidth;
            delete this.selectedObject.portal.logoTop;
            delete this.selectedObject.portal.logoLeft;
            delete this.selectedObject.portal.defaultType;
            delete this.selectedObject.portal.defaultPerson;
            delete this.selectedObject.portal.defaultCompany;
            delete this.selectedObject.portal.roundedButtons;
            delete this.selectedObject.portal.devmodeEnabled;
            delete this.selectedObject.portal.settingsEnabled;
            delete this.selectedObject.portal.confirmActions;
            /* delete this.selectedObject.portal.departments;
            delete this.selectedObject.portal.partners; */
            delete this.selectedObject.portal.reminders;
            delete this.selectedObject.portal.deletePermission;
            delete this.selectedObject.portal.smsNotificationPermission;
            delete this.selectedObject.portal.usev2receipts;
            delete this.selectedObject.portal.defaultSort;
            delete this.selectedObject.portal.filterDateCreated;
            delete this.selectedObject.portal.filterDateUpdated;
            delete this.selectedObject.portal.updateStatus;
            delete this.selectedObject.portal.referenceNumber;
            delete this.selectedObject.portal.useNewIdControl;
            delete this.selectedObject.portal.useNewKyc;
            delete this.selectedObject.portal.useNewSettings;
            delete this.selectedObject.portal.suitablityAssessment;
            delete this.selectedObject.portal.filters;

            delete this.selectedObject.kycs;
            delete this.selectedObject.idcs;
            delete this.selectedObject.pdf;
            delete this.selectedObject.modules;
            delete this.selectedObject.flows;
            delete this.selectedObject.report;
            delete this.selectedObject.integrations;
            delete this.selectedObject.internals;
            delete this.selectedObject.colors;

            delete this.selectedObject.branding;

            delete this.selectedObject._id;
            delete this.selectedObject.type;

            this.loading = false;
            console.log('obj', this.selectedObject);
        },

        async reloadCompany() {
            this.loading = true;

            const companyId = this.$route.query.id;
            const response = await v1backoffice.getAccount(companyId);

            console.log('response', response);

            this.account = response.account;
            this.stats = response.stats;
            this.users = response.users;
            this.config = response.config;

            this.statusModel = this.account.status || '';
            this.versionModel = this.account.version || '';

            this.loadConfiguration();
            this.loading = false;
        },

    },
    async created() {
        await this.reloadCompany();
    }
}
</script>
<style scoped>

.amladmin-users {
    float: left;
    width: 100%;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
}

.amladmin-user {
    display: flex;
    padding: 6px 14px;
    border-bottom: solid 1px var(--input-border);
}

.amladmin-ucon {
    flex-grow: 100;
}

.amladmin-name {
    font-size: 17px;
    font-weight: 500;
    color: var(--text-title);
}

.amladmin-stat {
    display: flex;
    font-weight: 500;
    color: var(--text-accent);
}

.amladmin-uadm {
    margin-right: 8px;
}

.amladmin-uid {
    color: var(--text-weak);
}

.amladmin-actions {
    flex-shrink: 0;
}

.amladmin-link a {
    margin-left: 10px;
    text-decoration: none;
    font-weight: 500;
    color: var(--link-text);
}
.amladmin-link a:hover {
    color: var(--link-active);
}

</style>