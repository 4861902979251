import { render, staticRenderFns } from "./TabOwnership.vue?vue&type=template&id=015d3427&scoped=true&"
import script from "./TabOwnership.vue?vue&type=script&lang=js&"
export * from "./TabOwnership.vue?vue&type=script&lang=js&"
import style0 from "./TabOwnership.vue?vue&type=style&index=0&id=015d3427&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "015d3427",
  null
  
)

export default component.exports