<template>
<div class="frmod-kyc-cview card-shadow" @click="handleClick" :class="[
    {'frmod-kyc-cview-selected': selected }
]">

    <div class="frmod-kyc-cview-designer" v-if="designer">
        {{ '[COMPARISON]' }}
    </div>

    <div class="frmod-kyc-cview-base" v-if="!designer && reference.comparison">
        <KycComparisonRenderer :changes="reference.comparison.changes" />
    </div>

</div>
</template>
<script>
import KycComparisonRenderer from './KycComparisonRenderer.vue';

export default {
    name: "KycComparisonView",
    components: {
        KycComparisonRenderer
    },
    props: {
        cardData: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    data() {
        return {
            
        }
    },

    methods: {
        
        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

    },
    created() {
        
    }
}

</script>
<style>

.frmod-kyc-cview {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 3px;
    background-color: var(--card-background);
    box-sizing: border-box;
}

.frmod-kyc-cview-base {
    padding: 16px 16px;
}

/* Designer / Selection */

.frmod-kyc-cview-designer {
    padding: 20px 40px;
}

.frmod-kyc-cview-selected {
    outline: solid 2px #238ccc;
}

</style>