import personhelper from "../helpers/personhelper";
const dataHelperV1 = {

    //#region Info Match --------------------------------------------------------------------------------------------------

    infoMatchPersons(data) {
        const masterList = [];
        for (const i in data.beneficials) {
            let match = false;
            for (const j in masterList) {
                if (personhelper.isSamePerson(data.beneficials[i], masterList[j])) {
                    this.mergeWithMasterList(data.beneficials[i], masterList[j]);
                    match = true;
                }
            }
            if (!match) this.addToMasterList(masterList, data.beneficials[i]);
        }
        for (const i in data.roles) {
            let match = false;
            for (const j in masterList) {
                if (personhelper.isSamePerson(data.roles[i], masterList[j])) {
                    this.mergeWithMasterList(data.roles[i], masterList[j]);
                    match = true;
                }
            }
            if (!match) this.addToMasterList(masterList, data.roles[i]);
        }


        for (const i in data.beneficials) {
            for (const j in masterList) {
                if (personhelper.isSamePerson(data.beneficials[i], masterList[j])) {
                    this.fillFromMasterList(data.beneficials[i], masterList[j]);
                }
            }
        }
        for (const i in data.roles) {
            for (const j in masterList) {
                if (personhelper.isSamePerson(data.roles[i], masterList[j])) {
                    this.fillFromMasterList(data.roles[i], masterList[j]);
                }
            }
        }
    },

    addToMasterList(list, person) {
        list.push(JSON.parse(JSON.stringify(person)));
    },

    mergeWithMasterList(person, masterPerson) {
        if (!masterPerson.street) {
            masterPerson.street = person.street;
            masterPerson.zip = person.zip;
            masterPerson.city = person.city;
            masterPerson.country = person.country;
        }
    },

    fillFromMasterList(person, masterPerson) {
        if (!person.street) {
            person.street = masterPerson.street;
            person.zip = masterPerson.zip;
            person.city = masterPerson.city;
            person.country = masterPerson.country;
        }
    },

    sortLists(data) {
        data.roles.sort((a, b) => (this.getRoleSortIndex(a.roleId) > this.getRoleSortIndex(b.roleId)) ? 1 : -1);
        data.shareholders.sort((b, a) => (a.share > b.share) ? 1 : -1);
    },

    getRoleSortIndex(roleId) {
        switch (roleId) {
            case 'role_ceo': return 1;
            case 'role_chair': return 3;
            case 'role_proprietor': return 2;
            case 'role_signatory': return 7;
            case 'role_procura': return 9;
            case 'role_deputy_chair': return 4;
            case 'role_board_member': return 11;
            case 'role_deputy_bm': return 13;
            case 'role_rrh': return 15;
            case 'role_shareholder': return 17;
        }
        return 99;
    },


    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Mapping -----------------------------------------------------------------------------------------------------


    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------


    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default dataHelperV1;