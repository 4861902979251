<template>
<div>
    
    <PropertyHeader :header="'Attachments'">
        <template v-slot:actions>
            <DesignerButton small
                :icon="'add'"
                :tooltip="'Add attachment'"
            />
        </template>
    </PropertyHeader>

    <!-- <PropertyItem padded :header="'Help'" :icon="'bug'">
        
    </PropertyItem> -->

</div>
</template>
<script>
import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';

export default {
    name: 'AttachmentsPropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() { 
        return {
            
        }
    },
    methods: {

    },
    created() {
        
    }
}
</script>