<template>
<div>

    <PropertyItem padded :header="'Filepreview properties'" :icon="'download'">

        <DesignerGroup c12>
            <DesignerLabel :text="'File key'" />
            <DesignerInput v-model="input.fileKey" :placeholder="'Not set'" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';

export default {
    name: 'FilepreviewProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
    },
    props: {
        input: { type: Object, default: null },
    }
}
</script>