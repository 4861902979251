<template>
<div class="sortsel" v-on-clickaway="hideDropdown">
    
    <div class="sortsel-l">
        <label>{{ __('sort').toUpperCase() }}</label>
    </div>
    <div class="sortsel-r" @click="handleClick">
        <label>{{ __(getDisplayValue()).toUpperCase() }}</label>
    </div>
    <div class="sortsel-i" @click="handleClick">
        <vap-icon small :icon="'chev-d'" :color="'var(--text-disabled)'" absolute :top="-4" :right="0" />
    </div>

    <div v-if="dropdownVisible" class="sortsel-dd">
        <div class="sortsel-item" v-for="(item, i) in options" :key="'ssi' + i" @click="handleItemClick(item)">
            <label>{{ __(item.key).toUpperCase() }}</label>
        </div>
    </div>

</div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
    name: 'SortSelector',
    mixins: [clickaway],
    props: {
        value: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => { return [] }
        }
    },
    data() {
        return {
            dropdownVisible: false,
        }
    },
    methods: {

        getDisplayValue() {
            for (const i in this.options) {
                if (this.options[i].value == this.value) return this.options[i].key;
            }
            return 'unknown';
        },

        handleClick() {
            if (!this.dropdownVisible) {
                this.showDropdown();
            }else {
                this.hideDropdown();
            }
        },

        handleItemClick(item) {
            this.hideDropdown();
            this.$emit('input', item.value);
        },

        showDropdown() {
            this.dropdownVisible = true;
        },

        hideDropdown() {
            this.dropdownVisible = false;
        },

    },
    async created() {
        
    }
}
</script>
<style scoped>

.sortsel {
    display: flex;
    position: relative;
}

.sortsel-l {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-weak);
}
.sortsel-r {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    color: var(--link-text);
    cursor: pointer;
}
.sortsel-r label {
    cursor: pointer;
}
.sortsel-r:hover {
    color: var(--link-active);
}

.sortsel-i {
    width: 29px;
    position: relative;
    cursor: pointer;
}

.sortsel-dd {
    position: absolute;
    top: 20px;
    right: 0px;
    width: 200px;
    min-height: 20px;
    padding: 10px 0px;
    border-radius: 3px;
    background-color: var(--card-background);
    z-index: 100;
    -webkit-box-shadow: 0.5px 0.5px 6px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0.5px 0.5px 6px 0px rgba(0,0,0,0.2);
    box-shadow: 0.5px 0.5px 6px 0px rgba(0,0,0,0.2);
}

.sortsel-dd:after {
    content: "";
    position: absolute;
    top: -8px;
    right: 15px;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 8px solid var(--card-background);
    border-color: transparent transparent var(--card-background) var(--card-background);
    transform-origin: 0 -20;
    transform: rotate(135deg);
    box-shadow: -1px 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.sortsel-item {
    font-size: 16px;
    font-weight: 500;
    padding: 4px 10px;
    color: var(--text-title);
    cursor: pointer;
}
.sortsel-item label {
    cursor: pointer;
}

.sortsel-item:hover {
    background-color: lightgray;
}

</style>
