<template>
<div>

    <!-- Manage -->
    <PopupV1 :header="'Manage resources'" :width="700" @close="$emit('close')" :dialog="addDialog">
        <template v-slot:content>
            <div class="frv2-selector-container">

                <div class="frv2-selector-left">
                    <div class="frv2-selector-header">
                        {{ 'Module resources' }}
                    </div>
                    <FormDialogSelector :items="listModule" @select="onSelectModule" />

                    <div class="frv2-selector-header" style="margin-top: 21px;">
                        {{ 'Global resources' }}
                    </div>
                    <FormDialogSelector :items="listGlobal" @select="onSelectGlobal" />
                </div>
                <div class="frv2-selector-right">
                    <div class="frv2-selector-header">
                        {{ 'Custom resources' }}
                    </div>
                    <FormDialogSelector :items="listCustom" :showEmpty="params.mode == 'manage'" />
                </div>

            </div> 
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

    <!-- Add resource -->
    <ve-popup v-if="newResource.show" :header="'Add resource'" :width="400" @close="newResource.show = false" hideCloseButton>
        <div style="padding: 8px 0px;" v-if="!newResource.typeSelected">
            <vap-item v-for="(option, i) in resourceTypes" :key="'ro' + i" fancy padding20
                :fancyIcon="option.icon"
                :fancyText="option.display"
                :fancyHint="option.hint"
                @clicked="showAddResourceName(option.type)"
            />
        </div>
        <div style="padding: 8px 0px;" v-if="newResource.typeSelected">
            <vap-row>
                <vap-group>
                    <vap-label desc :text="'Name of resource'" />
                    <vap-input v-model="newResource.name" />
                </vap-group>
            </vap-row>
            <vap-button :text="'Add'" @clicked="onAddResource" />
        </div>
    </ve-popup>
    
</div>
</template>

<script>
import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFButton from '../items/VFButton.vue';

import FormDialogSelector from './FormDialogSelector.vue';
import OptionSelector from './OptionSelector.vue';

export default {
    name: 'FormResourcesV2',
    components: {
        PopupV1,
        VFButton,

        FormDialogSelector,
        OptionSelector,
    },
    props: {
        params: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            addDialog: { show: false, name: '' },
            listCustom: [],
            listModule: [],
            listGlobal: [],


            resourceTypes: [
                { type: 'bool', icon: 'input-check', display: 'Boolean', hint: 'Boolean' },
                { type: 'value', icon: 'input-text', display: 'Value', hint: 'Value' },
                { type: 'list', icon: 'resource', display: 'List', hint: 'List' },
                { type: 'obj', icon: 'modules', display: 'Object', hint: 'Object' },
            ],
            newResource: { show: false, type: '', name: '', typeSelected: false }
        }
    },
    methods: {

        onSelectModule(index, slot) {
            if (this.params.mode == 'connect') {
                this.$emit('connect', slot.key);
                this.$emit('close');
            }
        },

        onSelectGlobal(index, slot) {
            if (this.params.mode == 'connect') {
                this.$emit('connect', slot.key);
                this.$emit('close');
            }
        },

        showAddResource() {
            this.newResource.type = '';
            this.newResource.name = '';
            this.newResource.typeSelected = false;
            this.newResource.nameSelected = false;
            this.newResource.show = true;
        },

        showAddResourceName(type) {
            this.newResource.type = type;
            this.newResource.typeSelected = true;
        },

        onAddResource() {
            const name = this.newResource.name;
            if (this.$parent.resources[name]) return;

            const resource = {
                key: 'r' + (this.$parent.counter += 1),
                type: this.newResource.type,
                name: this.newResource.name,
                value: false
            };

            if (this.newResource.type == 'list') resource.value = [];
            if (this.newResource.type == 'obj') resource.value = {};

            this.$set(this.$parent.resources, name, resource);

            this.newResource.show = false;
        },

        getDecoder() {
            return {
                inputs: [
                    { key: '' }
                ],
                
            };
        },

        getIconFromType(type) {
            for (const i in this.resourceTypes) {
                if (this.resourceTypes[i].type == type) return this.resourceTypes[i].icon;
            }
            return 'help-o';
        },
        
    },
    created() {
        console.log('res', this.$parent.resources);

        for (const key in this.$parent.resources) {

            if (this.$parent.resources[key].module) {

                if (this.$parent.resources[key].module == 'sys') {

                    this.listGlobal.push({
                        icon: 'home',
                        name: this.$parent.resources[key].name,
                        key: this.$parent.resources[key].key,
                    });

                }else {

                    this.listModule.push({
                        icon: this.$parent.resources[key].module,
                        name: this.$parent.resources[key].name,
                        key: this.$parent.resources[key].key,
                    });

                }

            }

        }

    }
}
</script>
<style scoped>

.frv2-selector-container {
    float: left;
    width: 100%;
}
.frv2-selector-left {
    float: left;
    width: 50%;
    padding-right: 5px;
    box-sizing: border-box;
}
.frv2-selector-right {
    float: left;
    width: 50%;
    padding-left: 5px;
    box-sizing: border-box;
}
.frv2-selector-header {
    float: left;
    width: 100%;
    margin-bottom: 6px;
    font-size: 18px;
    color: var(--text-title);
}


.fres-i {
    display: flex;
    margin-bottom: 10px;
    border: solid 2px gray;
    border-radius: 6px;
    cursor: pointer;
}
.fres-i:hover {
    background-color: var(--item-hover);
}

.fres-i-icon {
    width: 45px;
    padding-top: 4px;
    padding-left: 8px;
}

.fres-i-text {
    flex-grow: 100;
}
.fres-i-name {
    margin-top: 2px;
    font-size: 18px;
    color: var(--text-strong);
}
.fres-i-info {
    margin-top: -2px;
    margin-bottom: 2px;
    color: var(--text-weak);
}

.fres-i-action {
    padding-top: 4px;
    padding-right: 4px;
}

.frv2-mmod-empty {
    padding: 2px;
    border: dotted 2px lightgray;
    border-radius: 6px;
    cursor: pointer;
}
.frv2-mmod-empty:hover {
    background-color: var(--item-hover);
}
.frv2-mmod-icon {
    width: 40px;
    margin: 0 auto;
}

</style>