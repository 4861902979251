<template>
<div style="float: left; width: 100%; margin-bottom: 20px;">
    <div class="vesr card-shadow" >
        <div class="vesr-item" v-for="(item, id, i) in options" :key="id"
            @click="handleClick(id)"
            :class="[
                (i == 0) ? 'vesr-first' : '', 
                (i + 1 >= Object.keys(options).length) ? 'vesr-last' : '',
                (value[id]) ? 'vesr-checked' : '',
                {'val-ok': validation.valid && !noHighlight},
                {'val-error': !validation.valid},
                {'val-show': validation.show},
            ]">
            <div class="vesr-radio">
                <div class="vesr-ball" />
            </div>
            <div class="vesr-option">
                <span>{{ item.label }}</span>
            </div>

<!--             <vap-icon v-if="!$root.phoneMode" action absolute :top="10" :right="10" :icon="'help-o'">
                <template v-slot:tooltip>
                    <vap-tooltip :locale="'customer_delete'" />
                </template>
            </vap-icon> -->

        </div>
        <div v-if="validation.show && !validation.valid" class="val-message vip-error">
            <label>{{ '* ' + validation.message }}</label>
        </div>
    </div>
</div>
</template>

<script>
import validations from '../../validations'

export default {
    name: 'vap-radio-card',
    props: {
        value: {
			type: Object,
			default: () => { return {} }
		},
        options: {
			type: Object,
			default: {}
        },
        rules: {
            type: String,
            default: ""
        },
        noValidation: {
            type: Boolean,
            default: false
        },
        noHighlight: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            validation: {
                show: false,
                valid: true,
                message: ''
            }
        }
    },
    methods: {
        handleClick(id) {
            for (let key in this.value) {
                this.value[key] = false;
                if (key == id) this.value[key] = true;
            }
            if (!this.validation.show && !this.noValidation) this.validation.show = true;
            this.validate();
            this.$emit('changed', this.value);
        },
        validate() {
            if (this.noValidation) return;
            //console.log('validate rc');
            let check = '';
            for (let key in this.options) {
                if (this.value[key]) check = 'check';
            }
            this.validation = {...this.validation, ...validations.validateRules(check, this.rules)};
            //console.log(this.validation);
        }
    },
    mounted() {
        this.validate();
    }
}
</script>
<style>

.vesr {
    float: left;
    width: 100%;
    min-height: 100px;
    border-radius: 3px;
    background-color: var(--page-background);
    margin-bottom: 4px;
}

.vesr-item {
    position: relative;
    min-height: 50px;
    border-bottom: solid 1px var(--input-border);
    border-left: solid 1px var(--input-border);
    border-right: solid 1px var(--input-border);
    padding: 5px 0px;
    cursor: pointer;
    padding-right: 20px;
    color: var(--input-text);
}
.vesr-first {
    border-top: solid 1px var(--input-border);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.vesr-last {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}
.vesr-item:hover {
    background-color: var(--item-hover);
}
.vesr-checked {
    background-color: var(--card-background);;
    
    box-sizing: border-box;
}

.vesr-item span {
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
}

.vesr-radio {
    position: absolute;
    top: 18px;
    left: 10px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: solid 1px var(--input-label);
    box-sizing: border-box;
}
.vesr-ball {
    visibility: hidden;
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-left: 3px;
    border-radius: 50%;
    background-color: var(--accent);
    transition: visibility 0s !important;
}

.vesr-checked .vesr-ball {
    visibility: visible;
}

.vesr-option {
    margin-left: 46px;
    line-height: 50px;
}
.vesr-option span {
    cursor: pointer;
}


/* --- Validation -------------------- */

.val-show.val-ok {
    /* border-color: var(--val-ok); */
}
.val-show.val-error {
    border-color: var(--val-error);
}

.val-message {
    
}

/* --- ---------- -------------------- */

</style>