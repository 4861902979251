<template>
<div style="position: relative;" :class="{'vap-infocard-designer': designer}" :key="vueHack" @mouseenter="hovering = true" @mouseleave="hovering = false">
    <div v-if="expanded" class="vap-infocard card-shadow">
        
        <div class="vap-infocard-line" v-for="(line, i) in data" :key="'hcl' + i">
            <div class="vap-infocard-left" @click="onIconClicked(i)">
                <vap-icon :icon="line.icon" :color="'var(--help-text)'" />
            </div>
            <div class="vap-infocard-content">
                <div class="vap-infocard-head" @click="onLineClicked(line.key + '_header')">
                    <label>{{ line.key ? ___($parent, line.key + '_header') : line.header }}</label>
                </div>
                <div class="vap-infocard-text" @click="onLineClicked(line.key + '_text')">
                    <vap-text-editor :value="line.key ? ___($parent, line.key + '_text') : line.text" />
                </div>
            </div>
            <div v-if="designer" class="vap-infocard-delete">
                <vap-icon :icon="'delete'" :color="'var(--help-text)'" action @clicked="onDeleteClicked(line, i)" />
            </div>
        </div>

        <vap-icon v-if="hovering && !designer" :icon="'cancel'" :color="'var(--help-text)'" tiny action absolute :top="0" :right="0" @clicked="onExpandClicked" :key="'id1'" />

        <div v-if="designer" class="vap-infocard-add">
            <vap-icon :icon="'add'" :color="'var(--help-text)'" action absolute :top="0" :right="3" @clicked="onAddClicked" :key="'id2'">
                <template v-slot:tooltip>
                    <vap-tooltip :key="'settings_help_new'" />
                </template>
            </vap-icon>
        </div>

    </div>

    <div v-if="!expanded" class="vap-infocard-icon card-shadow">
        <vap-icon :icon="'help-o'" :color="'var(--help-text)'" action absolute :top="2" :right="2" @clicked="onExpandClicked" :key="'id3'" >
            <!-- <template v-slot:tooltip><vap-tooltip :text="'Vis hjelpetekst'" /></template> -->
        </vap-icon>
    </div>
</div>
</template>

<script>

export default {
    name: 'vap-infocard',
    components: {
        
    },
    props: {
        data: {
            type: Array,
            default: null
        },
        designer: {
            type: Boolean,
            default: false
        },
        helpKey: {
            type: String,
            default: ''
        },
        vueHack: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            expanded: true,
            hovering: false
        }
    },
    methods: {
        onExpandClicked() {
            this.expanded = !this.expanded;
        },
        onAddClicked() {
            this.$emit('new', this.helpKey);
        },
        onLineClicked(key) {
            this.$emit('line', key);
        },
        onIconClicked(index) {
            this.$emit('icon', { key: this.helpKey, index: index });
        },
        onDeleteClicked(line, index) {
            this.$emit('delete', { key: this.helpKey, line: line, index: index });
        },
    },
    async created() {
        
    }
}
</script>

<style scoped>

.vap-infocard {
    position: relative;
    margin-bottom: 20px;
    padding: 5px 20px;
    padding-top: 20px;
    color: var(--help-text);
    border-radius: 3px;
    /* border: solid 1px var(--help-border); */
    background-color: var(--help-background);
}

.vap-infocard-designer {
    float: left;
    width: 100%;
}

.vap-infocard-line {
    display: flex;
}


.vap-infocard-left {
    width: 40px;
    flex-shrink: 0;
}
.vap-infocard-designer .vap-infocard-left:hover {
    border-radius: 4px;
    background-color: black;
    opacity: 0.5;
    cursor: pointer;
}

.vap-infocard-content {
    flex-grow: 100;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 15px;
    line-height: 22px;
}

.vap-infocard-head {
    min-height: 18px;
    font-weight: 500;;
}
.vap-infocard-designer .vap-infocard-head:hover {
    border-radius: 4px;
    background-color: black;
    opacity: 0.5;
    cursor: pointer;
}
.vap-infocard-designer .vap-infocard-head label {
    cursor: pointer;
}

.vap-infocard-text {
    
}
.vap-infocard-designer .vap-infocard-text:hover {
    border-radius: 4px;
    background-color: black;
    opacity: 0.5;
    cursor: pointer;
}

.vap-infocard-delete {
    float: right;
    width: 40px;
}

.vap-infocard-add {
    position: relative;
    width: 100%;
    height: 40px;
}

.vap-infocard-icon {
    position: absolute;
    width: 40px; height: 40px;
    top: 0px; right: -50px;
    border-radius: 3px;
    background-color: var(--help-background);
}

</style>
