<template>
    <div>

        <vap-label v-if="!multiple" :text="__('settings_welcome')" header style="margin-bottom: 5px;">
            <template v-slot:actions>
                <vap-icon action :icon="'edit'" @clicked="$emit('locale', 'idc_welcome')">
                    <template v-slot:tooltip><vap-tooltip :text="'Endre velkomstmelding'"></vap-tooltip></template>
                </vap-icon>
            </template>
        </vap-label>
        <vap-card v-if="!multiple">
            <vap-text-editor :value="___($parent, 'idc_welcome')" />
        </vap-card>

        <vap-label v-if="multiple" :text="__('settings_welcome')" header style="margin-bottom: 5px;">
            <template v-slot:actions>
                <vap-icon action :icon="'edit'" @clicked="$emit('locale', 'idc_welcome_select')">
                    <template v-slot:tooltip><vap-tooltip :text="'Endre velkomstmelding'"></vap-tooltip></template>
                </vap-icon>
            </template>
        </vap-label>
        <vap-card v-if="multiple">
            <vap-text-editor :value="___($parent, 'idc_welcome_select')" />
        </vap-card>

        <vap-label :text="__('settings_thank_you')" header style="margin-top: 30px; margin-bottom: 5px;">
            <template v-slot:actions>
                <vap-icon action :icon="'edit'" @clicked="$emit('locale', 'idc_thank_you')">
                    <template v-slot:tooltip><vap-tooltip :text="'Endre takkemelding'"></vap-tooltip></template>
                </vap-icon>
            </template>
        </vap-label>
        <vap-card>
            <vap-text-editor :value="___($parent, 'idc_thank_you')" />
        </vap-card>

        <vap-label :text="__('settings')" header style="margin-top: 30px; margin-bottom: 5px;" />
        <vap-card>
            <vap-row>
                <vap-group c12>
                    <vap-label :text="__('settings_landing_page')" desc />
                    <vap-input v-model="$root.config.idcs[idcIndex].redirect" noValidation />
                </vap-group>
                <vap-group c12>
                    <vap-label :text="__('settings_help_page')" desc />
                    <vap-input v-model="$root.config.idcs[idcIndex].email.link" noValidation />
                </vap-group>
                <vap-group c12 v-if="$root.config.idcs[idcIndex].allowCustomSender">
                    <vap-label :text="__('settings_custom_sender')" desc />
                    <vap-checkbox v-model="$root.config.idcs[idcIndex].useCustomSender" :text="__('settings_custom_sender_use')" noValidation />
                </vap-group>
                <vap-group c12 v-if="$root.config.idcs[idcIndex].useCustomSender">
                    <vap-label :text="__('settings_custom_sender') + ' ' + __('common_first_name')" desc />
                    <vap-input v-model="$root.config.idcs[idcIndex].customSender.firstName" noValidation />
                </vap-group>
                <vap-group c12 v-if="$root.config.idcs[idcIndex].useCustomSender">
                    <vap-label :text="__('settings_custom_sender') + ' ' + __('common_last_name')" desc />
                    <vap-input v-model="$root.config.idcs[idcIndex].customSender.lastName" noValidation />
                </vap-group>
                <vap-group c12 v-if="$root.config.idcs[idcIndex].useCustomSender">
                    <vap-label :text="__('settings_custom_sender') + ' ' + __('common_email')" desc />
                    <vap-input v-model="$root.config.idcs[idcIndex].customSender.email" noValidation />
                </vap-group>
            </vap-row>
        </vap-card>

        <vap-form-divider :left="50" :right="50" style="margin-top: 20px;">
            <template v-slot:left>
                <vap-label :text="__('settings_email')" header style="margin-bottom: 5px;">
                    <template v-slot:actions>
                        <vap-icon :icon="'help-o'"><template v-slot:tooltip><vap-tooltip :localized="'settings_dynamic_info'"></vap-tooltip></template></vap-icon>
                    </template>
                </vap-label>
                <vap-card>
                    <vap-row v-if="$root.config.idcs[idcIndex].email && $parent.translations.idc_email_subject">
                        <vap-group c12>
                            <vap-label :text="'Logo URL'" desc />
                            <vap-input v-model="$root.config.idcs[idcIndex].email.logo" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_subject')" desc />
                            <vap-input v-model="$parent.translations.idc_email_subject[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_greeting')" desc />
                            <vap-input v-model="$parent.translations.idc_email_header[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_message')" desc />
                            <vap-input v-model="$parent.translations.idc_email_message[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_help_text')" desc />
                            <vap-input v-model="$parent.translations.idc_email_hint[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_contact')" desc />
                            <vap-input v-model="$parent.translations.idc_email_footer[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_help_link')" desc />
                            <vap-input v-model="$parent.translations.idc_email_link[$root.locale.key]" noValidation />
                        </vap-group>
                    </vap-row>
                </vap-card>
            </template>
            <template v-slot:right>
                <vap-label :text="__('settings_preview')" header style="margin-bottom: 5px;" />
                <vap-card v-if="$root.config.idcs[idcIndex].email && $parent.translations.idc_email_subject">
                    <div class="email-preview" style="text-align: center;">
                        <div class="email-preview-sender">
                            <label>{{ __('settings_sender') + ':' }}</label><label style="margin-left: 8px; font-weight: bold;">{{ replaceBracketsCustom('\{\{senderName\}\}') }}</label>
                        </div>
                        <div class="email-preview-subject">
                            <label>{{ __('settings_subject') + ':' }}</label><label style="margin-left: 8px; font-weight: bold;">{{ replaceBrackets($parent.translations.idc_email_subject[$root.locale.key]) }}</label>
                        </div>
                        <br><br><br>
                        <img :src="$root.config.idcs[idcIndex].email.logo" :style="'width: 200px;'" />
                        <br><br>
                        <h2>{{ replaceBrackets($parent.translations.idc_email_header[$root.locale.key]) }}</h2>
                        <br>
                        <p>{{ replaceBrackets($parent.translations.idc_email_message[$root.locale.key]) }}</p>
                        <br><br>
                        <span class="email-preview-button">{{ $parent.translations.idc_email_button[$root.locale.key] }}</span>
                        <br><br><br>
                        <div v-html="replaceLink(replaceBrackets($parent.translations.idc_email_hint[$root.locale.key]), $parent.translations.idc_email_link[$root.locale.key], $root.config.idcs[idcIndex].email.link)" />
                        <br>
                        <p>{{ replaceBracketsCustom($parent.translations.idc_email_footer[$root.locale.key]) }}</p>
                        <br><br>
                    </div>
                </vap-card>
            </template>
        </vap-form-divider>

        <vap-form-divider v-if="$root.config.idcs[idcIndex].smsEnabled" :left="50" :right="50" style="margin-top: 20px;">
            <template v-slot:left>
                <vap-label :text="'SMS'" header style="margin-bottom: 5px;">
                    <template v-slot:actions>
                        <vap-icon :icon="'help-o'"><template v-slot:tooltip><vap-tooltip :localized="'settings_dynamic_info'"></vap-tooltip></template></vap-icon>
                    </template>
                </vap-label>
                <vap-card>
                    <vap-row>
                        <vap-group c12>
                            <vap-label :text="'Message'" desc />
                            <vap-textarea v-model="$parent.translations.idc_sms_invite[$root.locale.key]" noValidation />
                        </vap-group>
                    </vap-row>
                </vap-card>
            </template>
            <template v-slot:right>
                <vap-label :text="__('settings_preview')" header style="margin-bottom: 5px;" />
                <vap-card>
                    <div class="email-preview" style="text-align: left;">
                        <div class="email-preview-sender">
                            <label>{{ __('settings_sender') + ':' }}</label><label style="margin-left: 8px; font-weight: bold;">{{ 'Verified' }}</label>
                        </div>
                        <p>{{ replaceBrackets($parent.translations.idc_sms_invite[$root.locale.key])+ ' https://app.verified.eu/s/8BkaB0LEx' }}</p>
                        <br><br>
                    </div>
                </vap-card>
            </template>
        </vap-form-divider>

    </div>
</template>

<script>

export default {
    name: 'IDCSettings',
    props: {
        number: {
            type: [String, Number],
            default: 'number'
        },
        idcIndex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            multiple: false
        }
    },
    watch: {
        number: {
            deep: false,
            handler(val) {
                
            }
        }
    },
    methods: {
        
        replaceBracketsCustom(input) {
            const emailData = {
                firstName: 'Fornavn',
                lastName: 'Etternavn',
                customerName: 'Selskapsnavn AS',
                senderName: this.$root.user.givenName + ' ' + this.$root.user.familyName,
                senderEmail: this.$root.user.email,
                action: 'signering',
                actionVerb: 'signere',
                reference: this.__('customer_reference_message') + ': 123456789'
            };
            if (this.$root.config.idcs[this.idcIndex].useCustomSender) {
                emailData.senderName = this.$root.config.idcs[this.idcIndex].customSender.firstName + ' ' + this.$root.config.idcs[this.idcIndex].customSender.lastName;
                emailData.senderEmail = this.$root.config.idcs[this.idcIndex].customSender.email;
            }
            return this.replaceBrackets(input, emailData);
        }

    },
    async mounted() {
        
        console.log(this.$root.config.idcs[this.idcIndex].methods)

        if (this.$root.config.idcs[this.idcIndex].methods.length > 1) this.multiple = true;

    }
}
</script>
<style scoped>

.email-preview-button {
    padding: 12px 40px;
    border-radius: 24px;
    color: white;
    background-color: var(--button);
}
.email-preview-sender {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 13px;
}
.email-preview-subject {
    position: absolute;
    left: 5px;
    top: 20px;
    font-size: 13px;
}

</style>