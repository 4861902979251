<template>
<div :class="[
    {'ariski-l1': level == 1 },
    {'ariski-l2': level == 2 },
    {'ariski-l3': level == 3 },
]" @click.stop="expanded = !expanded">

    <div class="ariski-info">
        <div class="ariski-exp" v-if="!lastLevel">
            {{ expanded ? '-' : '+' }}
        </div>
        <div class="ariski-flag" v-if="flag">
            <FlagIcon :alpha2="flag" />
        </div>
        <div class="ariski-grow">
            <span class="ariski-code">{{ '' }}</span>
            <span class="ariski-name">{{ display }}</span>
        </div>
        <slot name="risk"></slot>
    </div>

    <div v-if="expanded">
        <slot></slot>
    </div>

</div>
</template>
<script>
import FlagIcon from '../v1ui/FlagIcon.vue';

export default {
    name: 'RiskItem',
    components: {
        FlagIcon,
    },
    props: {
        level: { type: Number, default: 0 },
        flag: { type: String, default: '' },
        display: { type: String, default: '' },
        lastLevel: { type: Boolean, default: false },
    },
    data() {
        return {
            expanded: false
        }
    },
    methods: {
        


    },
    created() {

        
    }
}
</script>
<style scoped>

.ariski-l1 {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    background-color: var(--card-background);
    cursor: pointer;
}

.ariski-info {
    display: flex;
    padding: 8px 10px;
    border-bottom: solid 1px var(--input-border);
}
.ariski-info:hover {
    background-color: var(--item-hover);
}

.ariski-exp {
    margin-top: -2px;
    margin-right: 10px;
    font-size: 20px;
    font-weight: bold;
}

.ariski-flag {
    margin-top: -5px;
    margin-bottom: -10px;
    margin-right: 10px;
}

.ariski-grow {
    padding-top: 1px;
    flex-grow: 100;
}

.ariski {
    float: left;
    width: 100%;
    padding-left: 40px;
    box-sizing: border-box;
}

.ariski-l2 {
    padding-left: 30px;
}
.ariski-l3 {
    padding-left: 30px;
}
.ariski-l4 {
    padding-left: 30px;
}
.ariski-l5 {
    padding-left: 30px;
}

.ariski-code {
    font-weight: 500;
    color: var(--text-strong);
}
.ariski-name {
    color: var(--text-strong);
}

</style>