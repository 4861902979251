<template>
<div>

    <div v-if="!(singleFile && files.length > 0)" class="vffu-drop" :id="dropZoneId">

        <!-- Dotted border -->
        <svg width="100%" height="100%" style="overflow: visible;">
            <rect width="100%" height="100%" x="0" y="0" rx="5" ry="5"
                :fill="fillColor" 
                :stroke="validation.done && !validation.valid ? strokeColorI : strokeColorV"
                stroke-width="1.5"
                stroke-dasharray="10 3"
            />
        </svg>

        <!-- Inside dropzone -->
        <div class="vffu-content">
            <label v-if="header" style="font-size: 16px; font-weight: bold;">{{ header }}</label><br>
            <label>{{ hintText }}</label>

            <label :for="dropZoneId + 'i'" class="vffu-browse-label">
                {{ browseText }}
                <input type="file" :id="dropZoneId + 'i'" class="vffu-browse" @change="onFileInputChanged">
            </label>

        </div>

        <!-- Top icon -->
        <div class="vffu-drop-icon">
            <vap-icon :icon="'upload'" absolute :top="-6" :right="-4" />
        </div>

        <div class="vffu-drop-msg" v-if="allowedFiles == 'pdf'" :class="{'vffu-drop-msg-wf': wrongFormat }">
            {{ __t('files-pdf-hint') }}
        </div>

    </div>


    <!-- File list -->
    <div class="vffu-files">
        <div class="vffu-file" v-for="(file, i) in files" :key="'fuf' + i">

            <div v-if="!file.selectContent" class="vffu-file-top" @click="onFileClicked(i)" :class="{'vffu-file-top-expanded': file.expanded}">

                <div class="vffu-file-left">
                    <label>{{ file.name }}</label>
                </div>

                <div class="vffu-file-right">
                    <vap-icon v-if="!file.existing || file.isResend" action :icon="'delete'" @clicked="deleteFile(i, file)">
                        <template v-slot:tooltip><vap-tooltip :text="'Ta bort fil'" /></template>
                    </vap-icon>
                </div>

            </div>

            <div class="vffu-file-preview" v-if="file.expanded">
                <Preview v-if="!file.existing" :uid="dropZoneId + 'p' + file.id" :file="file.data" />
                <Preview v-if="file.existing" :uid="dropZoneId + 'p2' + file.id" :raw="file.data" />
            </div>

        </div>
    </div>

</div>
</template>
<script>
import log from '@/log';
import tools from '@/tools';

import Preview from '../../documents/Preview.vue';

export default {
    name: 'vf-fileupload',
    components: {
        Preview
    },
    props: {
        value: { type: Object, default: () => { return { files: [] } }},

        header: { type: String, default: '' },
        hintText: { type: String, default: '' },
        browseText: { type: String, default: '' },

        allowedFiles: { type: String, default: '' },
        singleFile: { type: Boolean, default: false },
        autoPreview: { type: Boolean, default: false },

        validation: { type: Object, default: () => { return { done: false } }},
        disabled: { type: Boolean, default: false },
        dotted: { type: Boolean, default: false },

        required: { type: Boolean, default: false },
        noValidation: { type: Boolean, default: false },
        noHighlight: { type: Boolean, default: false },
        
        id: { type: String, default: 'id' },
        designer: { type: Boolean, default: false },
        activeLogic: { type: Boolean, default: false },
    },
    data() {
        return {
            dropZoneId: '',
            fillColor: '#ffffff',
            strokeColorI: '#ffffff',
            strokeColorV: '#ffffff',
            files: [],
            wrongFormat: false,
        }
    },
    methods: {
        
        updateVmodel() {
            const data = {
                files: this.files
            };
            log.x('FileUpload: Model updated', data);
            this.$emit('input', data);
        },

        //#region File management ---------------------------------------------------------------------------------------------

        onFileClicked(index) {

            this.files[index].expanded = !this.files[index].expanded;
            //console.log(this.files[index].expanded)

            this.$forceUpdate();
        },

        deleteFile(index, file) {
            log.x('FileUpload: Deleting file', index);
            this.files.splice(index, 1);
            this.updateVmodel();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region File Loading ------------------------------------------------------------------------------------------------

        // Files were added through the File Dialog
        onFileInputChanged(event) {
            //console.log(event.target.files);
            this.addFile(event.target.files[0]);
        },

        // Files were added by drag & drop
        readDroppedFiles(files) {
            for (var i = 0; i < files.length; i++) {
                this.addFile(files[i]);
            }
        },

        addFile(file) {

            log.x('FileUpload: Adding file', file);


            if (!this.validateFileFormat(file)) {
                this.wrongFormat = true;
            }

            if (!this.wrongFormat) {
                const newFile = {
                    data: file,
                    name: file.name,
                    contentType: file.type,
                    fileSize: file.size,
                    sizeString: Number(file.size / 1000) + ' kB',
                    expanded: this.autoPreview,
                };
                this.files.push(newFile);
                
                log.x('FileUpload: File added', newFile);
            }else {
                log.e('FileUpload: File has wrong format', file);
                const self = this;
                setTimeout(function() {
                    self.wrongFormat = false;
                }, 5000);
            }
            this.updateVmodel();
        },

        validateFileFormat(file) {
            const allowed = [];
            let match = false;

            console.log('this.allowedFiles', this.allowedFiles);

            if (this.allowedFiles == 'pdf') {
                allowed.push('pdf');
            }else {
                match = true;
            }
            
            if (file.type == 'application/pdf' && allowed.includes('pdf')) match = true;
            if (file.type == 'image/png' && allowed.includes('img')) match = true;
            if (file.type == 'image/jpeg' && allowed.includes('img')) match = true;

            return match;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Drop Zone Initialization ------------------------------------------------------------------------------------

        async initDropZone() {
            await new Promise(r => setTimeout(r, 200));
            const self = this;
            const holder = document.getElementById(self.dropZoneId);
            holder.ondragover = function () {
                self.highlightDropzone(true);
                return false;
            };
            holder.ondragend = function () {
                self.highlightDropzone(false);
                return false;
            };
            holder.ondragleave = function () {
                self.highlightDropzone(false);
                return false;
            };
            holder.ondrop = function (e) {
                self.highlightDropzone(false);
                e.preventDefault();
                self.readDroppedFiles(e.dataTransfer.files);
            };
            self.highlightDropzone(false);
        },

        highlightDropzone(value) {
            if (value) {
                this.fillColor = this.$root.getColor('item-hover', false);
                this.strokeColorI = this.$root.getColor('val-error', false);
                this.strokeColorV = this.$root.getColor('accent', false);
            }else {
                this.fillColor = 'transparent';
                this.strokeColorI = this.$root.getColor('val-error', false);
                this.strokeColorV = this.$root.getColor('input-border', false);
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Designer / Logic --------------------------------------------------------------------------------------------

        handleMouseEnter(e) {
            this.$emit('connect-enter', this.id);
        },

        handleMouseLeave(e) {
            this.$emit('connect-leave', this.id);
        },

        handleOptionMouseUp(e) {
            if (this.activeLogic) {
                this.$emit('connect', this.id);
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    mounted() {
        this.dropZoneId = 'dz' + (this.id ? this.id : tools.getUniqueId());
        this.files = this.value.files || [];
        this.initDropZone();
    }
}
</script>
<style scoped>

.vffu-drop {
    position: relative;
    height: 80px;
    margin: 0 1px;
    margin-top: 10px;
}

.vffu-content {
    position: absolute;
    width: 100%;
    top: 0px;
    text-align: center;
    font-size: 16px;
    padding-top: 22px;
}

.vffu-browse {
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
}
.vffu-browse-label {
    margin-left: 5px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    color: var(--link-text);
}
.vffu-browse-label:hover {
    color: var(--link-active);
}

.vffu-drop-icon {
    position: absolute;
    top: -12px; left: calc(50% - 15px);
    width: 28px; height: 28px;
}


.vffu-drop-msg {
    float: left;
    width: 100%;
    font-size: 15px;
    font-style: italic;
    color: var(--text-weak);
}
.vffu-drop-msg-wf {
    color: var(--val-error);
}


/* Files */

.vffu-files {
    float: left;
    width: 100%;
    margin-top: 10px;
}
.vffu-file {
    margin-top: 10px;
}
.vffu-file-top {
    display: flex;
    border-radius: 4px;
    border: solid 1px var(--input-border);
    cursor: pointer;
}
.vffu-file-top-expanded {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.vffu-file-top label {
    cursor: pointer;
}
.vffu-file-top:hover {
    background-color: var(--item-hover);
}
.vffu-file-left {
    flex-grow: 100;
    padding: 8px 10px;
    font-size: 17px;
    color: var(--text-strong);
}
.vffu-file-right {
    float: right;
}
.vffu-file-preview {
    min-height: 400px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px var(--input-border);
    border-top: none;
}


</style>
