<template>
    <div>
        <vap-row v-if="value">

            <vap-group c6>
                <vap-label :text="__('common_name')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.name" value />
                <vap-input v-model="value.name" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>
            <vap-group c6>
                <vap-label :text="__('company_orgnr')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.org_no" value />
                <vap-input numeric v-model="value.org_no" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>

            <vap-group c6>
                <vap-label :text="__('common_address')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.address" value />
                <vap-input v-model="value.address" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation"  :rules="required ? 'required' : ''"/>
            </vap-group>
            <vap-group c3>
                <vap-label :text="__('common_zip')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.zip" value />
                <vap-input numeric v-model="value.zip" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>
            <vap-group c3>
                <vap-label :text="__('common_city')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.town" value />
                <vap-input v-model="value.town" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>

            <vap-group c3 v-if="!simple">
                <vap-label :text="__('company_est_date')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.start_date" value />
                <vap-datepicker v-model="value.start_date" v-if="editMode" outputISO :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>
            

<!--             <vap-group c6>
                <vap-label :text="__('company_reg_date')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.registration_date" value />
                <vap-datepicker v-model="value.registration_date" v-if="editMode" outputISO :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group> -->
            <vap-group c3 v-if="!simple">
                <vap-label :text="__('company_org_form')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.org_form" value />
                <vap-input v-model="value.org_form" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>
            <vap-group c3 v-if="!simple && !(value && value.businessCodes && value.businessCodes.length > 0)">
                <vap-label :text="__('company_business_code')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.business_code" value />
                <vap-input v-model="value.business_code" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>
            <vap-group c3 v-if="!simple && !(value && value.businessCodes && value.businessCodes.length > 0)">
                <vap-label :text="__('company_employees')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.employees" value />
                <vap-input numeric v-model="value.employees" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>
            <vap-group c6 v-if="!simple && (value && value.businessCodes && value.businessCodes.length > 0)">
                <vap-label :text="__('company_employees')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.employees" value />
                <vap-input numeric v-model="value.employees" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>

            <vap-group c12 v-if="!simple && !(value && value.businessCodes && value.businessCodes.length > 0)">
                <vap-label :text="__('company_business')" desc :required="required" />
                <vap-label v-if="!editMode" :text="value.business" value />
                <vap-input v-model="value.business" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
            </vap-group>

        </vap-row>

        <div v-if="!simple && (value && value.businessCodes && value.businessCodes.length > 0)" class="flw100" style="margin-bottom: 25px;">
            <vap-row v-for="(code, i) in value.businessCodes" :key="'bc' + i" :actionWidth="editMode ? 34 : 0">
                <vap-group c3 compact>
                    <vap-label v-if="i == 0" :text="__('company_business_codes')" desc />
                    <vap-label v-if="!editMode" :text="code.code" value />
                    <vap-input v-model="code.code" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
                </vap-group>
                <vap-group c9 compact>
                    <vap-label v-if="i == 0" :text="__('common_description')" desc />
                    <vap-label v-if="!editMode" :text="code.description" value />
                    <vap-input v-model="code.description" v-if="editMode" :noHighlight="noHighlight" :noValidation="noValidation" :rules="required ? 'required' : ''" />
                </vap-group>
                <template v-slot:actions>
                    <vap-icon :icon="'delete'" absolute action @clicked="removeCode(i)">
                        <template v-slot:tooltip><vap-tooltip :text="__('common_remove')" /></template>
                    </vap-icon>
                </template>
            </vap-row>
            <div v-if="editMode" class="flw100" style="margin-top: 5px; margin-left: 10px;">
                <vap-label :text="__('common_add') + ' ' + __('company_business_code')" desc link @clicked="addCode" />
            </div>
        </div>


        <vap-row v-if="value">

            <vap-group c6>
                <vap-label :text="__('company_registered')" desc />
                <vap-label :text="__('company_in_brreg')" q />
                <vap-label v-if="value.inBrReg" :text="__('common_yes')" a /><br>
                <vap-label v-if="!value.inBrReg" :text="__('common_no')" a /><br>
                <vap-label :text="__('company_in_business_reg')" q />
                <vap-label v-if="value.inBusinessReg" :text="__('common_yes')" a />
                <vap-label v-if="!value.inBusinessReg" :text="__('common_no')" a /><br>
                <vap-label :text="__('company_in_mva_reg')" q />
                <vap-label v-if="value.inMvaReg" :text="__('common_yes')" a />
                <vap-label v-if="!value.inMvaReg" :text="__('common_no')" a /><br>
            </vap-group>
            <vap-group c6>
                <vap-label :text="__('company_status')" desc />
                <vap-label :text="__('company_stat_bankrupt')" q />
                <vap-label v-if="value.bankrupt" :text="__('common_yes')" a /><br>
                <vap-label v-if="!value.bankrupt" :text="__('common_no')" a /><br>
                <vap-label :text="__('company_stat_liquidation')" q />
                <vap-label v-if="value.liquidation" :text="__('common_yes')" a />
                <vap-label v-if="!value.liquidation" :text="__('common_no')" a /><br>
                <vap-label :text="__('company_stat_cliquidation')" q />
                <vap-label v-if="value.compulsoryLiquidation" :text="__('common_yes')" a />
                <vap-label v-if="!value.compulsoryLiquidation" :text="__('common_no')" a /><br>
            </vap-group>

        </vap-row>
    </div>
</template>

<script>

export default {
    name: 'CompanyInputs',
    props: {
        value: {
            type: Object,
            default: null
        },
        role: {
            type: String,
            default: 'owner'
        },
        editMode: {
            type: Boolean,
            default: false
        },
        simple: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        zipJson: {
            type: Object,
            default: null
        },
        noValidation: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            noHighlight: true
        }
    },
    computed: {
        
    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            }
        },
    },
    methods: {
        
        removeCode(index) {
            this.value.businessCodes.splice(index, 1);
        },

        addCode() {
            this.value.businessCodes.push({
                code: '',
                description: ''
            });
        },

    },
    async mounted() {

        //console.log('brreg', this.value)
        //if (this.value) this.value.businessCodes = [];

    }
}
</script>
<style scoped>


</style>