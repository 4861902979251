<template>
<div>

    <PopupV1 :header="'Connect to logic output'" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <ConnectionList :items="options" :fromItem="params.fromItem" @item="onConnectItem" @itemkey="onConnectItemKey" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>
    
</div>
</template>
<script>
import logicHelper from '../logicHelper';

import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFButton from '../items/VFButton.vue';

import ConnectionList from './ConnectionList.vue';

export default {
    name: 'LogicOutputConnector',
    components: {
        PopupV1,
        VFButton,
        ConnectionList,
    },
    props: {
        params: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: {},
            options: []
        }
    },
    methods: {
        
        onConnectItem(result) {
            console.log('result', result);

            const logicKey = this.$parent.logic[this.params.logicIndex].key;
            console.log('logicKey', logicKey);

            if (result.conn == 'trigger') {

                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'disabled');
                    this.params.fromItem.logic.disabled.keys.push({
                        type: 'logic', key: logicKey, rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'visible');
                    this.params.fromItem.logic.visible.keys.push({
                        type: 'logic', key: logicKey, rev: result.reversed
                    });

                }

            }
            this.$emit('close');
        },

        onConnectItemKey(result) {
            this.$emit('close');
        },
        
    },
    created() {
        const type = this.params.fromItem.type;
            
        // Trigger
        this.options.push({ type: 'trigger', conn: 'trigger', icon: 'check', text: 'Trigger',
            hint: 'Trigger input properties based on the output value'
        });
    }
}
</script>