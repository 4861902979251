<template>
<div>

    <PopupV1 :header="'Set risk'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ 'Preliminary risk rating' }}
            </div>
            <DialogContent style="float: left; width: 100%; padding-top: 20px;">

                <VFCheckbox
                    radio
                    v-model="riskModel"
                    :options="riskOptions"
                    @input="validateDialog"
                />

            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="'Set risk'"
                :icon="'check'"
                @clicked="setRisk"
                :working="dialog.working"
                :disabled="!dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import verified from '../verified';
import formValidation from '../components/form/formValidation';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import v1api from '../v1helpers/v1api';

export default {
    name: 'HBRiskPopup',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox
    },
    props: {
        client: { type: Object, default: null },
    },
    data() {
        return {
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            riskModel: {
                low: false,
                medium: false,
                medhigh: false,
                high: false,
            },
            riskOptions: {
                low: { label: 'Standard Risk' },
                medium: { label: 'Medium Risk' },
                medhigh: { label: 'Medium (high risk factors/additional due diligence)' },
                high: { label: 'High Risk' },
            }
        }
    },
    methods: {

        validateDialog() {
            for (const i in this.riskModel) {
                if (this.riskModel[i]) return this.dialog.valid = true;
            }
            this.dialog.valid = false;
        },

        async setRisk() {
            this.dialog.working = true;

            const data = {
                isValues: true,
                values: {
                    risk: 0
                }
            };
            for (const key in this.riskModel) {
                if (this.riskModel[key] && key == 'low') data.values.risk = 2;
                if (this.riskModel[key] && key == 'medium') data.values.risk = 4;
                if (this.riskModel[key] && key == 'medhigh') data.values.risk = 7;
                if (this.riskModel[key] && key == 'high') data.values.risk = 9;
            }

            await v1api.addCustomData(this.client._id, 'hbrisk', data);
            await v1api.saveReportData(this.client._id, [], data.values.risk);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        if (this.client.hbrisk) {
            if (this.client.hbrisk.values.risk == 2) this.riskModel.low = true;
            if (this.client.hbrisk.values.risk == 4) this.riskModel.medium = true;
            if (this.client.hbrisk.values.risk == 7) this.riskModel.medhigh = true;
            if (this.client.hbrisk.values.risk == 9) this.riskModel.high = true;
        }
    }
}
</script>