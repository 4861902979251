<template>
<div style="position: relative;" :class="{'vap-infocard-designer': designer}" :key="'h' + pageId" @mouseenter="hovering = true" @mouseleave="hovering = false">
    <div v-if="expanded" class="vap-infocard card-shadow">
        
        <div class="vap-infocard-line" v-for="(line, i) in data" :key="'hcl' + i" :class="[
            {'vap-infocard-line-selected': selectedLine && selectedLine.key == line.key }
        ]" @click.stop="onLineClicked(line)">
            <div class="vap-infocard-left" @click.stop="onLineClicked(line)">
                <vap-icon :icon="line.icon" :color="'var(--help-text)'" />
            </div>
            <div class="vap-infocard-content">
                <div class="vap-infocard-head" @click.stop="onLineClicked(line)">
                    <label>{{ line.header[$root.locale.key] }}</label>
                </div>
                <div class="vap-infocard-text" @click.stop="onLineClicked(line)">
                    <vap-text-editor :value="line.text[$root.locale.key]" />
                </div>
            </div>
            <!-- <div v-if="designer" class="vap-infocard-delete">
                <vap-icon small :icon="'delete'" :color="'var(--help-text)'" action @clicked="onDeleteClicked(i)" >
                    <template v-slot:tooltip>
                        <vap-tooltip :text="'Delete line'" />
                    </template>
                </vap-icon>
            </div> -->
        </div>

        <vap-icon v-if="hovering && !designer" :icon="'cancel'" :color="'var(--help-text)'" tiny action absolute :top="0" :right="0" @clicked="onExpandClicked" :key="'id1'" />

        <div v-if="designer" class="vap-infocard-add">
            <vap-icon small :icon="'add'" :color="'var(--help-text)'" action absolute :top="-10" :right="12" @clicked="onAddClicked" :key="'id2'">
                <template v-slot:tooltip>
                    <vap-tooltip :text="'New line'" />
                </template>
            </vap-icon>
<!--             <vap-icon small :icon="'merge'" :color="'var(--help-text)'" action absolute :top="-10" :right="48" @clicked="$emit('line-merge')" :key="'id3'">
                <template v-slot:tooltip>
                    <vap-tooltip :text="'Merge infotext for all roles'" />
                </template>
            </vap-icon> -->
        </div>

    </div>

    <div v-if="!expanded" class="vap-infocard-icon card-shadow">
        <vap-icon :icon="'help-o'" :color="'var(--help-text)'" action absolute :top="2" :right="2" @clicked="onExpandClicked" :key="'id3'" >
            <!-- <template v-slot:tooltip><vap-tooltip :text="'Vis hjelpetekst'" /></template> -->
        </vap-icon>
    </div>
</div>
</template>

<script>

export default {
    name: 'FormInfoCard',
    components: {
        
    },
    props: {
        data: {
            type: Array,
            default: null
        },
        designer: {
            type: Boolean,
            default: false
        },
        helpKey: {
            type: String,
            default: ''
        },
        pageId: {
            type: String,
            default: 'h'
        },
        selectedLine: { type: Object, default: () => { return {} }},
    },
    data() {
        return {
            expanded: true,
            hovering: false
        }
    },
    methods: {
        onExpandClicked() {
            this.expanded = !this.expanded;
        },
        onAddClicked() {
            this.$emit('line-new', this.data);
            //this.$emit('line-edit', 'x');
        },
        onLineClicked(line) {
            //this.$emit('line-edit', reference);
            this.$emit('line-edit', line);
        },
        onIconClicked(index) {
            //this.$emit('line-icon', this.data[index]);
            this.$emit('line-edit', 'x');
        },
        onDeleteClicked(index) {
            this.$emit('line-delete', { data: this.data, index: index });
            //this.$emit('line-edit', 'x');
        },
    },
    async created() {
        
    }
}
</script>

<style scoped>

.vap-infocard {
    position: relative;
    margin-bottom: 20px;
    padding: 5px 20px;
    padding-top: 20px;
    color: var(--help-text);
    border-radius: 3px;
    /* border: solid 1px var(--help-border); */
    background-color: var(--help-background);
}

.vap-infocard-designer {
    float: left;
    width: 100%;
}

.vap-infocard-line {
    display: flex;
}

.vap-infocard-designer .vap-infocard-line:hover {
    border-radius: 4px;
    background-color: white;
    opacity: 0.5;
    cursor: pointer;
}

.vap-infocard-line-selected {
    border-radius: 5px;
    border: solid 1px var(--help-text);
    box-sizing: border-box;
}


.vap-infocard-left {
    width: 40px;
    flex-shrink: 0;
}
.vap-infocard-designer .vap-infocard-left:hover {
    /* border-radius: 4px;
    background-color: black;
    opacity: 0.5;
    cursor: pointer; */
}

.vap-infocard-content {
    flex-grow: 100;
    margin-bottom: 10px;
    padding-left: 10px;
    font-size: 15px;
    line-height: 22px;
}

.vap-infocard-head {
    min-height: 18px;
    font-weight: 500;;
}
.vap-infocard-designer .vap-infocard-head:hover {
    /* border-radius: 4px;
    background-color: black;
    opacity: 0.5;
    cursor: pointer; */
}
.vap-infocard-designer .vap-infocard-head label {
    cursor: pointer;
}

.vap-infocard-text {
    
}
.vap-infocard-designer .vap-infocard-text:hover {
    /* border-radius: 4px;
    background-color: black;
    opacity: 0.5;
    cursor: pointer; */
}

.vap-infocard-delete {
    flex-shrink: 0;
    float: right;
    width: 40px;
}

.vap-infocard-add {
    position: relative;
    width: 100%;
    height: 30px;
}

.vap-infocard-icon {
    position: absolute;
    width: 40px; height: 40px;
    top: 0px; right: -50px;
    border-radius: 3px;
    background-color: var(--help-background);
}

</style>
