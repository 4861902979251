<template>
<div>
    <div class="slim-page" v-if="!$root.config.portal.migrateLink">

        <vap-label header :text="__('customer_new')" style="margin-top: 100px; margin-bottom: 20px;" />

        <vap-card list noPadding v-if="typeVisible" style="margin-bottom: 40px;">
            <vap-tabs v-model="typeModel" :tabs="typeOptions" />
        </vap-card>

        <vap-card list noPadding v-if="typeModel.person">
            <vap-tabs v-model="personModel" :tabs="personOptions" v-show="Object.keys(personOptions).length > 1" />
            <div v-if="personModel.other" class="new-company-container" style="margin-top: 10px;">
                <vap-row>
                    <vap-group c6>
                        <vap-label :text="__('common_first_name')" desc required />
                        <vap-input v-model="person.firstName" noValidation :placeholder="__('common_required')" />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('common_last_name')" desc required />
                        <vap-input v-model="person.lastName" noValidation :placeholder="__('common_required')" />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('common_dob')" desc />
                        <vap-datepicker v-model="person.dateOfBirth" outputISO noValidation :placeholder="__('common_optional')" />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('common_citizenship')" desc />
                        <vap-dropdown v-model="person.country" noValidation
                            :options="$root.countriesJson"
                            :commonOptions="$root.countriesCommon"
                            :placeholder="__('common_optional')"
                            :listKey="$root.locale.key"
                            :displayKey="$root.locale.key"
                            :outputKey="'key'" />
                    </vap-group>
                    <vap-group c6 v-if="$root.config.flows.personLookup.enabled">
                        <vap-label :text="__('common_email')" desc />
                        <vap-input v-model="person.email" noValidation
                            :placeholder="__('common_optional')" />
                    </vap-group>
                    <vap-group c6 v-if="$root.config.flows.personLookup.enabled">
                        <vap-label :text="__('common_phone')" desc />
                        <vap-phone v-model="person.phone" noValidation
                            :placeholder="__('common_optional')" />
                    </vap-group>
                    <vap-group c12 v-if="$root.config.portal.referenceNumber">
                        <vap-label :text="__('customer_reference')" desc />
                        <vap-input v-model="person.reference" noValidation
                            :placeholder="__('common_optional')" />
                    </vap-group>
                    <vap-group c6 v-if="$root.config.portal.departments">
                        <vap-label :text="'Avdeling'" desc />
                        <vap-dropdown v-model="selectedDepartment" noValidation
                            :options="departments"
                            :placeholder="__('common_optional')"
                            :listKey="'display'"
                            :displayKey="'display'"
                            :outputKey="'value'" />
                    </vap-group>
                    <vap-group c6 v-if="$root.config.portal.departments">
                        <vap-label :text="'LCSP'" desc />
                        <vap-dropdown v-model="selectedPartner" noValidation
                            :options="partners"
                            :placeholder="__('common_optional')"
                            :listKey="'display'"
                            :displayKey="'display'"
                            :outputKey="'value'" />
                    </vap-group>
                </vap-row>
            </div>
            <div class="new-company-buttons">
                <vap-label v-if="alreadyCustomer" :text="__('customer_new_exists')" error alignRight />
                <vap-button-row alignRight style="margin-top: 10px;">
                    <vap-button :text="__('common_cancel')" action uppercase outline @clicked="resetSearch" />
                    <vap-button :text="__('common_open')" action uppercase v-if="alreadyCustomer" @clicked="openExisting" :working="creatingNewCustomer" />
                    <vap-button :text="__('common_save')" action uppercase :dotDisabled="alreadyCustomer || !validPerson()" @clicked="createCustomer" :working="creatingNewCustomer" />
                </vap-button-row>
            </div>
        </vap-card>

        <vap-card list noPadding v-if="typeModel.company">
            <vap-tabs v-model="companyModel" :tabs="companyOptions"/>

            <div class="new-info">
                <label v-if="companyModel.norway">{{ __('customer_new_no') }}</label>
                <label v-if="companyModel.sweden">{{ __('customer_new_se') }}</label>
                <label v-if="companyModel.uk">{{ __('customer_new_uk') }}</label>
                <label v-if="companyModel.other">{{ __('customer_new_other') }}</label>
            </div>

            <div v-if="companyModel.norway" class="new-company-container">

                <CompanyInputs v-if="brregResult.name" v-model="brregResult" simple :editMode="false" />

                <div v-if="!brregResult.name">
                    <vap-label :text="__('customer_brreg_hint')" desc />
                    <ve-brreg-search @company-selected="onBrregResult" />
                </div>
                <vap-row v-if="brregResult.name" style="margin-top: 10px;">
                    <vap-group c6 v-if="$root.config.portal.departments">
                        <vap-label :text="'Avdeling'" desc />
                        <vap-dropdown v-model="selectedDepartment" noValidation
                            :options="departments"
                            :placeholder="__('common_optional')"
                            :listKey="'display'"
                            :displayKey="'display'"
                            :outputKey="'value'" />
                    </vap-group>
                    <vap-group c6 v-if="$root.config.portal.departments">
                        <vap-label :text="'LCSP'" desc />
                        <vap-dropdown v-model="selectedPartner" noValidation
                            :options="partners"
                            :placeholder="__('common_optional')"
                            :listKey="'display'"
                            :displayKey="'display'"
                            :outputKey="'value'" />
                    </vap-group>
                    <vap-group c12 v-if="customFlows">
                        <vap-label :text="'Flyt'" desc />
                        <vap-dropdown v-model="customFlowsModel" noValidation
                            :options="customFlowsOptions"
                            :placeholder="__('common_optional')"
                            :listKey="'display'"
                            :displayKey="'display'"
                            :outputKey="'value'" />
                        <label v-if="$root.config.portal.sb1rhName != 'ok'" class="flw100" style="margin-top: 8px; margin-left: 10px; font-size: 14px; color: var(--text-weak);">{{ '* Velg mellom vanlig flyt eller SpareBank 1 Regnskap' }}</label>
                        <label v-if="$root.config.portal.sb1rhName == 'ok'" class="flw100" style="margin-top: 8px; margin-left: 10px; font-size: 14px; color: var(--text-weak);">{{ '* Velg mellom vanlig flyt eller opplasting av egenerklæringsskjema utført utenfor portal.' }}</label>
                    </vap-group>
                </vap-row>
            </div>
            <div v-if="companyModel.sweden" class="new-company-container">

                <vap-row v-if="!bolagModel.hasResult" style="margin-top: 10px;">
                    <vap-group c12>
                        <vap-label :text="__('customer_bolag_hint')" desc />
                        <vap-input v-model="bolagModel.orgSearch" noValidation :placeholder="__('common_required')" :disabled="bolagModel.working" />
                    </vap-group>
                </vap-row>
                <vap-button-row v-if="!bolagModel.hasResult" alignCenter>
                    <vap-button :text="__('common_search')" action uppercase :working="bolagModel.working" @clicked="onTrapetsSearch" />
                </vap-button-row>

                <vap-row v-if="bolagModel.hasResult" style="margin-top: 10px;">
                    <vap-group c6>
                        <vap-label :text="__('common_name')" desc />
                        <vap-label :text="bolagModel.name" value />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('company_orgnr')" desc />
                        <vap-label :text="bolagModel.orgnr" value />
                    </vap-group>
                </vap-row>

            </div>
            <div v-if="companyModel.uk" class="new-company-container">

                <vap-row v-if="!companiesHouseModel.hasResult && !companiesHouseModel.hasSelected" style="margin-top: 10px;">
                    <vap-group c12>
                        <vap-label :text="__('customer_chouse_hint')" desc />
                        <vap-input v-model="companiesHouseModel.searchString" noValidation :placeholder="__('common_required')" :disabled="companiesHouseModel.working" @onreturn="onCompaniesHouseSearch" />
                    </vap-group>
                </vap-row>
                <vap-button-row v-if="!companiesHouseModel.hasResult && !companiesHouseModel.hasSelected" alignCenter>
                    <vap-button :text="__('common_search')" action uppercase :working="companiesHouseModel.working" @clicked="onCompaniesHouseSearch" />
                </vap-button-row>

                <vap-label header :text="__('common_search_results') + ':'" v-if="companiesHouseModel.hasResult" style="margin-bottom: -30px;" />

                <vap-row v-if="companiesHouseModel.hasSelected" style="margin-top: 10px;">
                    <vap-group c6>
                        <vap-label :text="__('common_name')" desc />
                        <vap-label :text="companiesHouseModel.name" value />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('company_orgnr')" desc />
                        <vap-label :text="companiesHouseModel.orgnr" value />
                    </vap-group>
                </vap-row>

            </div>
            <div v-if="companyModel.other" class="new-company-container">
                <vap-row>
                    <vap-group c12>
                        <vap-label :text="__('common_name')" desc required />
                        <vap-input v-model="other.name" noValidation />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('common_country')" desc required />
                        <vap-dropdown v-model="other.country" noValidation
                            :options="$root.countriesJson"
                            :commonOptions="$root.countriesCommon"
                            :placeholder="__('common_required')"
                            :listKey="$root.locale.key"
                            :displayKey="$root.locale.key"
                            :outputKey="'key'" />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('company_orgnr')" desc />
                        <vap-input v-model="other.number" noValidation />
                    </vap-group>
                    <vap-group c6 v-if="$root.config.portal.departments">
                        <vap-label :text="'Avdeling'" desc />
                        <vap-dropdown v-model="selectedDepartment" noValidation
                            :options="departments"
                            :placeholder="__('common_optional')"
                            :listKey="'display'"
                            :displayKey="'display'"
                            :outputKey="'value'" />
                    </vap-group>
                    <vap-group c6 v-if="$root.config.portal.departments">
                        <vap-label :text="'LCSP'" desc />
                        <vap-dropdown v-model="selectedPartner" noValidation
                            :options="partners"
                            :placeholder="__('common_optional')"
                            :listKey="'display'"
                            :displayKey="'display'"
                            :outputKey="'value'" />
                    </vap-group>
                </vap-row>
            </div>
            <div class="new-company-buttons">
                <vap-label v-if="alreadyCustomer" :text="__('customer_new_exists')" error alignRight />
                <vap-button-row v-if="brregResult.name || companyModel.other || bolagModel.hasResult || companiesHouseModel.hasSelected" alignRight style="margin-top: 10px;">
                    <vap-button :text="__('common_cancel')" action uppercase outline @clicked="resetSearch" />
                    <!-- <vap-button :text="'test'" action uppercase outline @clicked="testCH" /> -->
                    <vap-button :text="__('common_open')" action uppercase v-if="alreadyCustomer" @clicked="openExisting" :working="creatingNewCustomer" />
                    <vap-button :text="__('common_save')" action uppercase :dotDisabled="alreadyCustomer || (companyModel.other && !validManual())" @clicked="createCustomer" :working="creatingNewCustomer" />
                </vap-button-row>
            </div>
        </vap-card>

        <div v-if="companyModel.uk && companiesHouseModel.hasResult">
            <vap-card compactList noPadding v-for="(company, i) in companiesHouseModel.resultList" :key="'chr' + i">
                <ClientItem
                    :icon="'company'"
                    :name="company.name"
                    :info="company.orgnr"
                    :date="company.date"
                    :user="company.status"
                    :link="'/new'"
                    @clicked="onCompaniesHouseClicked(company)"
                />
            </vap-card>
        </div>


        <vap-card compactList noPadding v-if="$root.config.modules.massCompany">
            <vap-item padding20 @clicked="goToMass">
                <div class="new-mass">
                    <div class="new-mass-l">
                        <vap-icon :icon="'batch'" />
                    </div>
                    <div class="new-mass-c">
                        <label>Masseutsendelse (Juridisk Person)</label>
                    </div>
                    <div class="new-mass-r">
                        <vap-icon :icon="'chev-r'" />
                    </div>
                </div>
            </vap-item>
        </vap-card>

        <UserAccessDialog :params="userAccessDialogParams" v-if="userAccessDialogParams.show" @close="userAccessDialogParams.show = false" />

    </div>
    <div class="slim-page" v-if="$root.config.portal.migrateLink">

        <vap-card :header="'Portalen er oppgradert'">

            <div>
                {{ 'AML-portalen er oppgradert til siste versjon.' }}<br>
                {{ 'I en overgangsfase vil derfor nye kunder måtte opprettes i det nye systemet, og gamle kunder fullføres i det nåværende systemet.' }}<br>
                {{ 'Når alle eksisterende kunder er fullførte, vil det kun være det nye systemet å forholde seg til.' }}<br><br>
            </div>

            <div>
                {{ 'For å komme inn i det nye systemet:' }}<br>
                {{ 'Logg inn på ' }}
                <a :href="$root.config.portal.migrateLinkAws" target="_blank">{{ $root.config.portal.migrateLinkAws }}</a>
                {{ 'med samme brukernavn og passord som her.' }}
            </div>

            <div>
                {{ 'Velg "AML PORTAL i menyen øverst på siden:' }}
                <img :src="'https://aml-portal.web.verified.eu/logo/aml-dashboard.png'" style="margin-top: 10px;" />
            </div>

        </vap-card>

    </div>
</div>
</template>

<script>
import api from '../portalapi';
import log from '../log';
import tools from '../tools';
import dataModel from '../dataModel';
import dataSweden from '../dataSweden';
import dataUK from '../dataUK';
import receipts from '../receipts';

import CompanyInputs from '../components/kyc/CompanyInputs';
import ClientItem from '../components/items/ClientItem.vue';

import UserAccessDialog from '../v1ui/UserAccessDialog.vue';

export default {
    name: 'NewCustomer',
    components: {
        CompanyInputs,
        ClientItem,
        UserAccessDialog
    },
    data() {
        return {
            typeVisible: false,
            typeModel: {},
            typeOptions: {},
            companyModel: {},
            companyOptions: {},
            personModel: {},
            personOptions: {},

            customFlows: false,
            customFlowsModel: '',
            customFlowsOptions: {},

            departments: [],
            partners: [],
            selectedDepartment: '',
            selectedPartner: '',

            creatingNewCustomer: false,
            alreadyCustomer: false,
            existingId: '',
            existingUserId: '',
            existingName: '',
            userAccessDialogParams: { show: false },
            brregResult: { name: '' },

            trapetsData: {},
            bisnodeData: {},
            companyData: {},

            bolagModel: {
                orgSearch: '',
                working: false,
                hasResult: false,
                resultList: [],
                hasSelected: false,
                name: '',
                orgnr: '5563829810'
            },
            companiesHouseModel: {
                searchString: '',
                working: false,
                hasResult: false,
                name: '',
                orgnr: ''
            },
            other: {
                name: '',
                number: '',
                country: ''
            },
            person: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                country: '',
                reference: '',
                phone: {
                    prefix: '+47',
                    number: ''
                },
                email: ''
            }
        }
    },
    methods: {

        async onTrapetsSearch() {
            this.bolagModel.working = true;

            let result = await dataSweden.getTrapetsData(this.bolagModel.orgSearch);
            if (result.success) {
                this.trapetsData = result.data;
                this.bolagModel.name = result.data.ResponseItems[0].Entities[0].Name;
                this.bolagModel.orgnr = result.data.ResponseItems[0].Entities[0].CorporateId;
                this.bolagModel.hasResult = true;
            }
            result = await dataSweden.getBisnodeData(this.bolagModel.orgSearch);
            console.log('bisres', result);
            if (result.success) {
                this.bisnodeData = result.data;
            }

            const data = dataModel.getEmptyModel();
            dataSweden.processData(data, this.trapetsData, this.bisnodeData);
            this.companyData = data;

            this.bolagModel.working = false;
        },

        async onCompaniesHouseSearch() {
            this.companiesHouseModel.working = true;

            const result = await dataUK.searchCompaniesHouse(this.companiesHouseModel.searchString);
            console.log('result', result);

            this.companiesHouseModel.resultList = result.items;
            this.companiesHouseModel.hasResult = true;

            this.companiesHouseModel.working = false;
        },

        async onCompaniesHouseClicked(company) {
            this.companiesHouseModel.working = true;
            this.companiesHouseModel.hasResult = false;
            this.companiesHouseModel.name = company.name;
            this.companiesHouseModel.orgnr = company.orgnr;
            this.companiesHouseModel.hasSelected = true;
            this.companiesHouseModel.working = false;
        },

        async testCH() {
            const data = dataModel.getEmptyModel();
            const chData = await dataUK.getCompanyData(this.companiesHouseModel.orgnr);
            dataUK.processData(data, chData);
            //this.companyData = data;
        },

        resetSearch() {
            this.brregResult = { name: '' };
            this.other.name = '';
            this.other.number = '';
            this.other.country = '';
            this.person.firstName = '';
            this.person.lastName = '';
            this.person.dateOfBirth = '';
            this.alreadyCustomer = false;
            this.existingId = '';
        },

        onBrregResult(company) {
            this.brregResult = company;
            for (let i in this.$parent.customerCache) {
                if (this.$parent.customerCache[i].org_no == company.org_no) {
                    this.alreadyCustomer = true;
                }
            }
        },

        validManual() {
            return this.other.name && this.other.country;
        },
        validPerson() {
            return this.person.firstName && this.person.lastName;
        },

        goToMass() {
            this.$parent.navigate({ path: '/batch' });
        },
        openExisting() {
            if (this.$root.credentials.role == 'regular' && this.existingUserId != this.$root.credentials.uid) {
                this.userAccessDialogParams.clientName = this.existingName;
                this.userAccessDialogParams.ownerId = this.existingUserId;
                this.userAccessDialogParams.show = true;
            }else {
                this.$parent.navigate({ path: '/client', query: { id: this.existingId } });
            }
        },
        async createCustomer() {
            try {
                this.creatingNewCustomer = true;
                let customer = this.getValues();
                customer.risk = 0;
                customer.batch = '-';
                customer.expires = -2;
                customer.department = 0;
                customer.partner = 0;
                if (this.selectedDepartment) customer.department = Number(this.selectedDepartment);
                if (this.selectedPartner) customer.partner = Number(this.selectedPartner);
                const validLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                if (validLetters.includes(customer.name.charAt(0).toUpperCase())) {
                    customer.letter = customer.name.charAt(0).toUpperCase();
                }else {
                    customer.letter = '#';
                }

                if (customer.type == 'person') {
                    customer.status = 'b-per';
                    if (this.$root.config.portal.referenceNumber && this.person.reference) {
                        customer.ref = this.person.reference;
                        customer.status1 = 'r-ok';
                    }
                    if (customer.flow == 'personLookup') {
                        customer.status = 'b-per-a';
                        customer.contact = {
                            phone: this.person.phone,
                            email: this.person.email
                        };
                    }
                }else {
                    if (customer.source == 'brreg') {
                        customer.status = 'c-aml';
                    }else {
                        customer.status = 'f-kyc-a';
                    }
                }

                const response = await api.newCustomer(customer);
                if (response.status == 200) {
                    this.alreadyCustomer = true;
                    this.existingId = response.id;
                    this.existingUserId = response.userId;
                    this.existingName = customer.name;
                }else if (response.status == 201) {

                    const resultCustomer = response.customer;
                    if (customer.flow == 'org-se-trap') {
                        const existingData = {
                            customer: resultCustomer,
                            trapetsData: this.trapetsData,
                            bisnodeData: this.bisnodeData,
                            companyData: this.companyData,
                            nextStatus: 'f-kyc-a'
                        };
                        const result = await receipts.doAmlSweden(this.$root, this.$parent, existingData, true);
                        console.log('result', result);

                    }else if (customer.flow == 'org-uk-chouse') {

                        const companyData = dataModel.getEmptyModel();
                        const chouseData = await dataUK.getCompanyData(this.companiesHouseModel.orgnr);
                        console.log('chchhc', chouseData);
                        dataUK.processData(companyData, chouseData.data);

                        const existingData = {
                            customer: resultCustomer,
                            chouseData: chouseData.data,
                            companyData: companyData,
                            nextStatus: 'f-kyc-a'
                        };
                        const result = await receipts.doAmlUK(this.$root, this.$parent, existingData, true);
                        console.log('result', result);

                    }

                    this.$parent.navigate({ path: '/client', query: { id: response.id }});
                }
            }catch (ex) {
                this.$root.exceptions.push(ex);
            }
            this.creatingNewCustomer = false;
        },

        getValues() {
            let type = 'org';
            let name = '--';
            let identity = '';
            let country = '--';
            let source = '--';
            let flow = 'org-no-baml';

            if (this.typeModel.person) {

                if (!this.person.dateOfBirth) {
                    this.person.dateOfBirth = '1800-01-01';
                }

                type = 'person';
                name = this.person.firstName.toUpperCase() + ' ' + this.person.lastName.toUpperCase();
                identity = tools.encodePerson(this.person);
                source = 'manual';
                country = this.person.country || 'XX';
                flow = 'person';
                if (this.$root.config.flows.personLookup.enabled) {
                    flow = 'personLookup';
                }
                
            }else {

                if (this.companyModel.norway) {
                    name = this.brregResult.name;
                    identity = this.brregResult.org_no;
                    country = 'NO';
                    source = 'brreg';
                    if (this.$root.config.flows.companyBrregLookup.enabled) {
                        flow = 'companyBrregLookup';
                    }else if (this.customFlows) {
                        if (this.customFlowsModel == 'snnBank') {
                            flow = 'snn-bank';
                        }else if (this.customFlowsModel == 'snnBank2') {
                            flow = 'snn-bank2';
                        }
                    }
                }else if (this.companyModel.sweden) {
                    name = this.bolagModel.name.toUpperCase();
                    identity = this.bolagModel.orgnr;
                    country = 'SE';
                    source = 'trapets';
                    flow = 'org-se-trap';
                }else if (this.companyModel.uk) {
                    name = this.companiesHouseModel.name.toUpperCase();
                    identity = this.companiesHouseModel.orgnr;
                    country = 'GB';
                    source = 'chouse';
                    flow = 'org-uk-chouse';
                }else {
                    name = this.other.name.toUpperCase();
                    identity = this.other.number;
                    source = 'manual';
                    country = this.other.country;
                    if (country == 'NO') {
                        flow = 'org-no-unreg';
                    }else {
                        flow = 'org-manual';
                    }
                }

            }

            
            return { type, name, identity, country, source, flow };
        },

        async onCreated() {
            this.$parent.setLoading(true, 'Henter innstillinger...');

            // Check modules to see what options will be available
            let hasPersonModule = false, hasCompanyModule = false;
            let defaultPersonSet = false, defaultCompanySet = false;

            // Person - Norway
            if (this.$root.config.modules.personNorway) {
                const value = this.$root.config.portal.defaultPerson == 'norway';
                this.$set(this.personOptions, 'norway', { label: this.getCountryName('NO'), image: this.$root.images.flags.nb_NO });
                this.$set(this.personModel, 'norway', value);
                hasPersonModule = true;
                if (value) defaultPersonSet = true;
            }
            // Person - Sweden
            if (this.$root.config.modules.personSweden) {
                const value = this.$root.config.portal.defaultPerson == 'sweden';
                this.$set(this.personOptions, 'sweden', { label: this.getCountryName('SE'), image: this.$root.images.flags.sv_SE });
                this.$set(this.personModel, 'sweden', value);
                hasPersonModule = true;
                if (value) defaultPersonSet = true;
            }
            // Person - Other
            if (this.$root.config.modules.personOther) {
                const value = this.$root.config.portal.defaultPerson == 'other';
                this.$set(this.personOptions, 'other', { label: this.__('common_other'), image: this.$root.images.flags.un_UN });
                this.$set(this.personModel, 'other', value);
                hasPersonModule = true;
                if (value) defaultPersonSet = true;
            }

            // Company - Norway
            if (this.$root.config.modules.companyNorway) {
                const value = this.$root.config.portal.defaultCompany == 'norway';
                this.$set(this.companyOptions, 'norway', { label: this.getCountryName('NO'), image: this.$root.images.flags.nb_NO });
                this.$set(this.companyModel, 'norway', value);
                hasCompanyModule = true;
                if (value) defaultCompanySet = true;
            }
            // Company - Sweden
            if (this.$root.config.modules.companySweden) {
                const value = this.$root.config.portal.defaultCompany == 'sweden';
                this.$set(this.companyOptions, 'sweden', { label: this.getCountryName('SE'), image: this.$root.images.flags.sv_SE });
                this.$set(this.companyModel, 'sweden', value);
                hasCompanyModule = true;
                if (value) defaultCompanySet = true;
            }
            // Company - United Kingdom
            if (this.$root.config.modules.companyUK) {
                const value = this.$root.config.portal.defaultCompany == 'uk';
                this.$set(this.companyOptions, 'uk', { label: this.getCountryName('GB'), image: this.$root.images.flags.en_EN });
                this.$set(this.companyModel, 'uk', value);
                hasCompanyModule = true;
                if (value) defaultCompanySet = true;
            }
            // Company - Other
            if (this.$root.config.modules.companyOther) {
                const value = this.$root.config.portal.defaultCompany == 'other';
                this.$set(this.companyOptions, 'other', { label: this.__('common_other'), image: this.$root.images.flags.un_UN });
                this.$set(this.companyModel, 'other', value);
                hasCompanyModule = true;
                if (value) defaultCompanySet = true;
            }

            if (hasCompanyModule) {
                this.$set(this.typeOptions, 'company', { key: 'customer_new_org', icon: 'company' });
                this.$set(this.typeModel, 'company', this.$root.config.portal.defaultType == 'company');
            }
            if (hasPersonModule) {
                this.$set(this.typeOptions, 'person', { key: 'customer_new_person', icon: 'people' });
                this.$set(this.typeModel, 'person', this.$root.config.portal.defaultType == 'person');
            }

            if (!defaultPersonSet) {
                for (const key in this.personModel) {
                    this.personModel[key] = true;
                    break;
                }
            }

            if (hasPersonModule && hasCompanyModule) {
                this.typeVisible = true;
            }

            if (this.$root.config.portal.departments) {
                this.departments = [];
                this.partners = [];
                for (const i in this.$root.departments) {
                    this.departments.push({
                        value: this.$root.departments[i].key,
                        display: this.$root.departments[i].name
                    });
                }
                for (const i in this.$root.partners) {
                    this.partners.push({
                        value: this.$root.partners[i].key,
                        display: this.$root.partners[i].firstName + ' ' + this.$root.partners[i].lastName
                    });
                }
            }

            if (this.$root.config.flows.custom.enabled) {
                this.customFlowsOptions = [];
                this.customFlowsModel = 'companyBrreg';
                this.customFlows = true;
                for (const i in this.$root.config.flows.custom.flows) {
                    let name = 'Vanlig selskapsflyt';
                    if (this.$root.config.portal.sb1rhName == 'ok') name = 'Vanlig selskapsflyt (Økonomihuset)';
                    if (this.$root.config.flows.custom.flows[i] == 'snnBank') name = 'SpareBank 1 Regnskap';
                    if (this.$root.config.flows.custom.flows[i] == 'snnBank2') name = 'Last opp egenerklæringsskjema utført utenfor portal.';
                    this.customFlowsOptions.push({
                        value: this.$root.config.flows.custom.flows[i],
                        display: name
                    });
                }
            }

            this.$parent.setLoading(false);
        }
    },
    async created() {
        if (this.$root.config.portal.migrate) {
            this.$parent.navigate({ path: '/v1search' });
        }

        this.onCreated();
        //dataUK.test();
    }
}
</script>
<style>

.new-company-container {
    float: left;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.new-company-buttons {
    float: left;
    width: 100%;
    padding: 20px;
    padding-top: 0px;
    box-sizing: border-box;
}

.new-info {
    float: left;
    width: 100%;
    margin-top: 26px;
    margin-bottom: 10px;
    padding: 0px 30px;
    box-sizing: border-box;
}


.new-mass {
    display: flex;
}
.new-mass-l {
    
}
.new-mass-c {
    flex-grow: 100;
    margin-top: 8px;
    margin-left: 15px;
    font-size: 17px;
}
.new-mass-r {
    
}


</style>

