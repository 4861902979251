<template>
<div class="vf-pdf-header" @click.stop="handleClick" :class="[
    { 'vf-pdf-selected' : selected },
    { 'vf-pdf-style-default' : cardData.style == 'default' },
]">
    
    {{ cardData.header[$root.locale.key] }}

</div>
</template>

<script>
export default {
    name: 'vf-pdf-header',
    props: {
        cardData: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    data() {
        return {
            logoUrl: ''
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

    },
}
</script>
<style scoped>

.vf-pdf-header {
    float: left;
    width: 100%;
}

.vf-pdf-style-default {
    font-size: 20px;
}

/* Designer / Selection */

.vf-pdf-selected {
    outline: solid 2px #238ccc;
}

</style>
