<template>
    <div>

        <div class="slim-page">

            <vap-card list noPadding>
                <vap-tabs v-model="filterModel" :tabs="filterOptions"/>
                <div v-if="filterModel.expiresIn" class="flw100" style="padding-top: 20px; padding-left: 20px;">
                    <vap-row>
                        <vap-group c3>
                            <vap-label :text="'Dager fra'" desc />
                            <vap-input v-model="expiresFrom" />
                        </vap-group>
                        <vap-group c3>
                            <vap-label :text="'Dager til'" desc />
                            <vap-input v-model="expiresTo" />
                        </vap-group>
                        <vap-group>
                            <vap-button :text="'Last inn'" style="margin-top: 19px;" @clicked="loadReminders" />
                        </vap-group>
                    </vap-row>
                </div>
            </vap-card>

        </div>

        <BatchView :items="items" :steps="steps" :showDelete="!filterModel.disabled" :title="'Påminnelser klare til å sendes'" />
    </div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import BatchView from '../components/special/BatchView';

export default {
    components: {
        BatchView
    },
    name: 'RemindersV1',
    data() {
        return {
            items: [],
            steps: 3,
            STEP_CHECK_STATUS: 0,
            STEP_SEND_REMINDER: 1,
            STEP_UPDATE_STATUS: 2,
            filterModel: {
                noReminders: false,
                expiresIn: false,
                everything: false,
                disabled: false
            },
            filterOptions: {
                noReminders: { label: 'Ingen påminnelser' },
                expiresIn: { label: 'Utløper om' },
                everything: { label: 'Alle' },
                disabled: { label: 'Deaktiverte' },
            },
            expiresFrom: -14,
            expiresTo: 0
        }
    },
    watch: {
        filterModel: {
            deep: true,
            handler(val) {
                if (!val.expiresIn) this.loadReminders();
            }
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            return item.name;
        },

        onGetInfo1(item) {
            if (item.expires != -1 && item.expires != -2) {
                return 'Utløper om ' + (Number(item.expires) - Number(this.expireOffset)) + ' dager';
            }
            return '';
        },

        onGetInfo2(item) {
            if (item.expires != -1 && item.expires != -2) {
                if (!item.reminder) return 'Siste påminnelse: Aldri';
                return 'Siste påminnelse: ' + Math.abs(Number(item.reminder) - Number(this.expireOffset)) + ' dager siden';
            }
            return '';
        },

        async onRemoveItem(item) {
            console.log('item', item);
            const response = await api.setReminder(item._id, -1);
            this.loadReminders();
        },

        async onStart() {
            return true;
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_CHECK_STATUS: return 'Sjekker status';
                case this.STEP_SEND_REMINDER: return 'Sender påminnelse';
                case this.STEP_UPDATE_STATUS: return 'Oppdaterer status';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_CHECK_STATUS: return await this.checkStatus(item, data);
                case this.STEP_SEND_REMINDER: return await this.sendReminder(item, data);
                case this.STEP_UPDATE_STATUS: return await this.updateStatus(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async checkStatus(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { kycMetadata: kychelper.getReactiveObject() } };

            console.log(item);

            

            result.ok = true;
            return result;
        },

        async sendReminder(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            

            result.ok = true;
            return result;
        },

        async updateStatus(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        needsReminder(customer) {
            //console.log(customer);
            if (customer.reminder && customer.reminder == -1 && !this.filterModel.disabled) {
                return false;
            }

            const expireDay = Number(customer.expires) - Number(this.expireOffset);
            const reminderDay = customer.reminder ? Math.abs(Number(customer.reminder) - Number(this.expireOffset)) : 99;
            //console.log(reminderDay);
            if (expireDay < 8) {
                if (reminderDay > 3) {
                    //console.log('TRUE');
                    return true;
                }
            }
            //console.log('false');
            return false;
        },

        async loadReminders() {
            this.$parent.setLoading(true, 'Laster påminnelser...');
            
            const sort = { dateUpdated: -1 };
            const filters = [
                { field: 'status', op: 'in', values: ['f-kyc-b'] },
            ];
            const from = 0;
            const limit = 10;
            const include = [];
            const counts = ['letter'];

            const response = await v1api.queryClients(filters, from, limit, sort, include, counts);

            for (const i in response.clients) {
                this.items.push(response.clients[i]);
            }

            this.$parent.setLoading(false);
        },

    },
    async created() {
        await this.loadReminders();
    }
}
</script>