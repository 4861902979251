<template>
<div class="amlv1-project-list">
    
    <div class="amlv1-project-item" v-for="(project, i) in projects" :key="'p' + i" @click="onChangeProject(project)">
        <div style="width: 54px;">
            <div class="amlv1-project-item-circle" :class="[
                {'amlv1-project-item-circle-a': project.active }
            ]">
                <vap-icon :icon="'check'" :color="'ext'" v-if="project.active" />
            </div>
        </div>
        <div style="flex-grow: 100;">
            <div>
                {{ project.name }}
            </div>
            <div style="display: flex;">
                <div>
                    {{ 'Magnus Ursin' }}
                </div>
                <div>
                    {{ 'Partner' }}
                </div>
            </div>
        </div>
        <CardActions :actions="project.actions" @action="onAction($event, project)" />
    </div>

    <div class="amlv1-project-buttons">

        <div class="amlv1-project-button" @click="showAddDialog">
            {{ 'New project' }}
        </div>

    </div>


    <!-- Add dialog -->
    <PopupV1 v-if="addDialog.show" :header="'New project'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <div style="font-style: italic;">
                {{ 'This will create a new project for this customer. Each project has a separate report and risk assessment.' }}
            </div>
            <div class="flw100" style="margin-top: 20px;">
                <VFGroup c12>
                    <VFLabel desc :text="'Project Name'" />
                    <VFInput v-model="addDialog.name" :placeholder="'Project Name'" />
                </VFGroup>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="addDialog.show = false" :disabled="addDialog.working" />
            <VFButton
                :text="'Create project'"
                :icon="'add'"
                @clicked="onCreateNewProject"
                :working="addDialog.working"
                :disabled="!addDialog.valid"
            />
        </template>
    </PopupV1>

    <!-- Edit dialog -->
    <PopupV1 v-if="editDialog.show" :header="'Edit project'" @close="editDialog.show = false" :dialog="editDialog">
        <template v-slot:content>
            <div style="font-style: italic;">
                {{ 'This will create a new project for this customer. Each project has a separate report and risk assessment.' }}
            </div>
            <div class="flw100" style="margin-top: 20px;">
                <VFGroup c12>
                    <VFLabel desc :text="'Project Name'" />
                    <VFInput v-model="editDialog.name" :placeholder="'Project Name'" />
                </VFGroup>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="editDialog.show = false" :disabled="editDialog.working" />
            <VFButton
                :text="__t('common-save')"
                :icon="'add'"
                @clicked="onCreateNewProject"
                :working="editDialog.working"
                :disabled="!editDialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import tools from '../tools';
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFInput from '../components/form/inputs/VFInput.vue';

import CardActions from '../v1ui/CardActions.vue';

export default {
    name: 'ProjectList',
    components: {
        PopupV1,
        VFButton,
        VFGroup,
        VFLabel,
        VFInput,
        CardActions,
    },
    props: {
        client: { type: Object, default: null },
        projectId: { type: String, default: '' },
    },
    data() {
        return {
            addDialog: { show: false, valid: true, working: false, status: '', error: '', name: '' },
            editDialog: { show: false, valid: true, working: false, status: '', error: '', name: '' },
            projects: [],
        }
    },
    methods: {

        onAction(action, project) {
            if (action.action == 'edit') {
                this.showEditDialog(project);
            }
        },

        onChangeProject(project) {
            if (project.projectId == this.projectId) return;
            this.$emit('project', project.projectId);
        },

        //#region Add Dialog --------------------------------------------------------------------------------------------------

        showAddDialog() {
            this.addDialog.show = true;
        },

        async onCreateNewProject() {
            this.addDialog.working = true;
            this.addDialog.status = 'Creating new project';

            const projectId = tools.getUniqueId().toUpperCase();

            const project = {
                projectId: projectId,
                name: this.addDialog.name || 'Project ' + projectId,
                flow: this.client.flow,
            };

            await v1api.addProject(this.client._id, project, tools.getUniqueId().toUpperCase());

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.addDialog.working = false;
            this.addDialog.show = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Edit Dialog -------------------------------------------------------------------------------------------------
        
        showEditDialog(project) {
            this.editDialog.name = project.name;
            this.editDialog.show = true;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {

        console.log('crrate', this.projectId);

        if (this.client.projects && this.client.projects.length > 0) {

            for (const i in this.client.projects) {
                const project = {
                    projectId: this.client.projects[i].projectId,
                    name: this.client.projects[i].name,
                    number: this.client.projects[i].number || '',
                    active: this.client.projects[i].projectId == this.projectId,
                    actions: [
                        { icon: 'edit', action: 'edit' },
                    ]
                };
                if (this.client.projects[i].isDefault) {
                    if (!this.client.projects[i].name) {
                        project.name = 'Default project';
                    }
                    if (!this.projectId) project.active = true;
                }
                this.projects.push(project);
            }

        }else {

            this.projects.push({
                name: 'Default project',
                number: '',
                active: true,
                actions: [
                    { icon: 'edit', action: 'edit' },
                ]
            });

        }

    }
}
</script>
<style scoped>

.amlv1-project-list {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
}

.amlv1-project-item {
    position: relative;
    float: left;
    width: 100%;
    display: flex;
    padding: 10px 14px;
    border-bottom: 1px solid var(--input-border);
    box-sizing: border-box;
    cursor: pointer;
}
.amlv1-project-item:hover {
    background-color: var(--item-hover);
}

.amlv1-project-item-circle {
    width: 40px; height: 40px;
    padding-top: 3px;
    padding-left: 2px;
    border-radius: 50%;
    fill: var(--disabled-text);
    background-color: var(--disabled-background);
    box-sizing: border-box;
}
.amlv1-project-item-circle-a {
    fill: var(--accent-contrast);
    background-color: var(--accent);
}




.amlv1-project-buttons {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 14px;
    box-sizing: border-box;
}

.amlv1-project-button {
    height: 20px;
    margin-left: 5px;
    padding: 0px 14px;
    padding-top: 4px;
    padding-bottom: 2px;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-title);
    border-radius: 3px;
    border: 1px solid var(--input-border);
    background-color: var(--page-background);
    cursor: pointer;
}
.amlv1-project-button:hover {
    color: var(--button-text);
    background-color: var(--button);
}

</style>