<template>
<div class="frv2-sign-prop">
    
    <vap-card noPadding>
        <div class="frv2-sign-prop-head">
            <div class="frv2-sign-prop-title">
                <label>{{ 'Signer properties' }}</label>
            </div>
            <!-- <vap-icon :icon="'cancel'" action absolute :top="5" :right="8" @clicked="closePageProperties" /> -->
        </div>
        <div class="frv2-sign-prop-content">

            <div class="frv2-sign-prop-actions">

            </div>


        </div>
    </vap-card>

</div>
</template>

<script>
import FormMenuButton from './FormMenuButton';
export default {
    name: 'SignerPropertiesV2',
    components: {
        FormMenuButton
    },
    props: {
        page: { type: Object, default: null }
    },
    data() {
        return {
            
        }
    },
    methods: {
        

    },
    async created() {

    }
}
</script>
<style scoped>

.frv2-sign-prop {
    max-width: 700px;
    margin: 0 auto;
}

.frv2-sign-prop-head {
    border-bottom: 1px solid var(--input-border);
}
.frv2-sign-prop-title {
    padding: 10px 20px;
    font-size: 20px;
}

.frv2-sign-prop-content {
    padding: 20px 20px;
}

.frv2-sign-prop-actions {
    display: flex;
    float: left;
    width: 100%;
    margin-bottom: 15px;
    box-sizing: border-box;
}

</style>
