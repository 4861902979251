<template>
<div class="flw100" style="padding: 20px 20px;">
    
    <div class="flw100" style="padding: 10px 10px; padding-bottom: 20px;">
        {{ __('customer_new_uk') }}
    </div>

    <vap-row v-if="!hasResult && !hasSelected" style="margin-top: 10px;">
        <vap-group c12>
            <vap-label :text="__('customer_chouse_hint')" desc />
            <vap-input v-model="searchString" noValidation :placeholder="__('common_required')" :disabled="working" @onreturn="onSearch" />
        </vap-group>
    </vap-row>
    <vap-button-row v-if="!hasResult && !hasSelected" alignCenter>
        <vap-button :text="__('common_search')" action uppercase :working="working" @clicked="onSearch" />
    </vap-button-row>

    <vap-label header :text="__('common_search_results') + ':'" v-if="hasResult" style="margin-left: 10px; margin-bottom: 10px;" />

    <div class="flw100" v-if="hasResult">
        <ClientItem v-for="(company, i) in resultList" :key="'chr' + i"
            :icon="'company'"
            :name="company.name"
            :info="company.orgnr"
            :date="company.date"
            :user="company.status"
            noLink
            @clicked="onResultClicked(company)"
        />
        <PageControl v-if="!loading" :total="totalPages" :current="currentPage" @page="onNavigate" />
    </div>

</div>
</template>
<script>
import dataUKV1 from '../v1data/dataUKV1';

import ClientItem from '../components/items/ClientItem.vue';

import PageControl from '../components/special/PageControl';

export default {
    name: 'SearchUK2',
    components: {
        ClientItem,
        PageControl
    },
    data() {
        return {
            loading: false,
            itemsPerPage: 10,
            currentPage: 0,
            totalPages: 0,
            totalCount: 0,

            searchString: '',
            working: false,
            hasResult: false,
            resultList: [],
            hasSelected: false,
            name: '',
            orgnr: ''
        }
    },
    methods: {
        
        async onSearch() {
            this.working = true;

            const result = await dataUKV1.searchCompaniesHouse(this.searchString, (Number(this.currentPage) - 1) * Number(this.itemsPerPage), 10);
            console.log('result', result);

            this.totalCount = result.totalCount;
            this.totalPages = Math.ceil(Number(this.totalCount) / Number(this.itemsPerPage));

            this.resultList = result.items;
            this.hasResult = true;

            this.working = false;
        },

        onNavigate(page) {
            this.currentPage = page;
            this.onSearch();
        },

        async onResultClicked(company) {
            this.working = true;
            this.hasResult = false;
            this.name = company.name;
            this.orgnr = company.orgnr;
            this.hasSelected = true;
            this.working = false;

            const selectedCompany = {
                name: this.name,
                identity: this.orgnr,
                country: 'GB',
                source: 'chouse'
            };
            this.$emit('result', selectedCompany);
        },

    },
    async created() {
        this.currentPage = 1;
    }
}
</script>