<template>
<div>
    
    <div class="frv2-pdfe-page">
        <PDFRenderer
            :cards="cards"
            :config="config"
            :metadata="metadata"
            @select="onSelectCard"
        />
    </div>

<!--     <div class="frv2-pdfe-page">
        <PdfRendererV2
            :data="data"
            @select="onSelectCard"
        />
    </div> -->

</div>
</template>
<script>
import pdfHelper from '../../pdfHelper';
import formHelper from '../../formHelper';
import viewHelper from '../../viewHelper';

import PDFRenderer from '../PDFRenderer.vue';

import PdfRendererV2 from '../pdf/PdfRendererV2.vue';

export default {
    name: 'PdfEditor',
    components: {
        PDFRenderer,
        PdfRendererV2,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() { 
        return {
            cards: [],
            config: {},
            metadata: {},
            data: {},
        }
    },
    methods: {

        processPages() {
            this.cards = pdfHelper.processPages(this.reference.pages, this.$root.locale.key, true, null, this.reference);
            this.removedItems = pdfHelper.getRemovedCards(this.reference.pages, this.$root.locale.key, true);
            for (const i in this.cards) {
                this.$set(this.cards[i], 'selected', false);
            }
            this.config = pdfHelper.getConfig(this.$root);
            this.metadata = pdfHelper.getMetadata(this.reference.formData, this.$root.locale.key, true);

            this.data = {
                pdfConfig: this.config,
                metadata: this.metadata,
                pdfPages: this.cards,
            };
        },

        processUserdata(userdata) {
            const pages = JSON.parse(JSON.stringify(this.reference.pages));
            viewHelper.setModelFromUserdata(this.reference, this.reference, pages, userdata);

            this.config = pdfHelper.getConfig(this.$root);
            this.metadata = pdfHelper.getMetadata(this.reference.formData, this.$root.locale.key, true);
            this.cards = pdfHelper.processPages(pages, this.$root.locale.key, false, userdata, this.reference);

            this.data = {
                pdfConfig: this.config,
                metadata: this.metadata,
                pdfPages: this.cards,
            };
        },

        onSelectCard(card) {

            console.log('card', card);

            const editorCard = formHelper.getItemFromKey(this.reference.pages, card.key);

            console.log('ecard', editorCard);

            this.$emit('selectcard', editorCard);

/*             this.selectedItem = card;
            console.log('selected', this.selectedItem);

            if (this.selectedItem.isCustom) {
                this.selectedOriginal = formHelper.getItemFromKey(this.$parent.pages, this.selectedItem.key);
                console.log('orignal', this.selectedOriginal);
            } */

        },

    },
    created() {
        this.processPages();
    }
}
</script>
<style scoped>

.frv2-pdfe-page {
    margin-top: -60px;
    border: solid 1px lightgray;
    background-color: white;
}

</style>