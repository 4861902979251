<template>
<div>

    <PopupV1 :header="__('ov_archive_new')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title); font-style: italic;">
                {{ __t('archive-hint') }}
            </div>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('archive-steps') + ':' }}
            </div>
            <div v-if="steps.length == 0" style="margin-bottom: 10px; color: var(--text-weak); font-style: italic;">
                {{ 'No steps can be archived!' }}
            </div>

            <div v-for="(step, i) in steps" :key="'rds' + i" class="aml-ad-item">
                <VFCheckbox newDesign v-model="step.checked" :text="step.name" :disabled="step.disabled" />
            </div>

            <div v-if="$root.config.externalId.clientEnabled">
                <div style="margin-top: 5px; margin-bottom: 5px; color: var(--text-weak);">
                    {{ $root.config.externalId.title[$root.locale.key] }}
                </div>
                <div>
                    <VFInput v-model="externalId" :placeholder="$root.config.externalId.title[$root.locale.key]" />
                </div>
            </div>

        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('archive-action')"
                :icon="'refresh'"
                @clicked="onResetClient"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid || steps.length == 0"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1flows from '../v1helpers/v1flows';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import VFInput from '../components/form/inputs/VFInput.vue';

export default {
    name: 'ArchiveDialog',
    components: {
        PopupV1,
        VFButton,
        VFCheckbox,
        VFInput,
    },
    props: {
        flow: { type: Object, default: null },
        client: { type: Object, default: null },
        persons: { type: Array, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            steps: [],
            externalId: '',
        }
    },
    methods: {

        async onResetClient() {
            this.dialog.working = true;
            this.dialog.status = this.__t('archive-progress');

            await v1api.resetReport(this.client._id);

            for (const i in this.steps) {
                if (this.steps[i].resetMode == 'report') continue;

                //console.log('resetting', this.steps[i]);

                this.dialog.status = this.__t('archive-resetting') + ' ' + this.steps[i].name;

                if (this.steps[i].resetMode == 'form') {
                    if (!this.steps[i].checked) continue;

                    await v1api.resetForm(this.client._id, this.steps[i].formKey);

                }else if (this.steps[i].resetMode == 'company') {
                    if (!this.steps[i].checked) continue;

                    await v1api.resetCompany(this.client._id);

                }else if (this.steps[i].resetMode == 'scr-individual') {
                    if (!this.steps[i].checked) continue;

                    await v1api.resetScreening(this.steps[i].personId);

                }else if (this.steps[i].resetMode == 'idc-individual') {
                    if (!this.steps[i].checked) continue;

                    await v1api.resetIDC(this.steps[i].personId);

                }else if (this.steps[i].resetMode == 'ams-org') {
                    if (!this.steps[i].checked) continue;

                    await v1api.resetAdverseMediaOrg(this.client._id);

                }

            }

            this.dialog.status = this.__t('report-prog-update');

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        },

        getIndividualPersonId() {
            let match = false;
            for (const j in this.persons) {
                for (const k in this.persons[j].roles) {
                    if (this.persons[j].roles[k].id == 'role_individual') {
                        return this.persons[j].personId;
                    }
                }
            }
            if (!match) return null;
        },

    },
    async created() {
        this.loading = true;
        this.dialog.working = true;
        this.dialog.status = this.__t('verified-reminder-loading');

        for (const i in this.flow.steps) {
            const stepSource = v1flows.getStep(this.$root, this.flow.steps[i].key);
            console.log('stepSource', stepSource);
            if (stepSource.resetMode) {

                const step = {
                    name: stepSource.title[this.$root.locale.key],
                    checked: true,
                    disabled: false,
                    resetMode: stepSource.resetMode
                };

                if (stepSource.resetMode == 'company') {
                    if (this.$root.config.clients.archiveUnchecked) step.checked = false;
                }

                if (stepSource.resetMode == 'form') {
                    step.formKey = stepSource.form;
                    if (this.$root.config.clients.archiveUnchecked) step.checked = false;
                }

                if (stepSource.resetMode == 'report') {
                    if (!this.client.report.created) step.checked = false;
                    step.disabled = true;
                }

                if (stepSource.resetMode == 'idc-individual') {
                    step.checked = false;
                    step.personId = this.getIndividualPersonId();
                    if (!step.personId) continue;
                }
                if (stepSource.resetMode == 'scr-individual') {
                    step.personId = this.getIndividualPersonId();
                    if (!step.personId) continue;
                }

                if (stepSource.resetMode == 'roles') continue;

                this.steps.push(step);
            }
        }

        this.dialog.working = false;
        this.loading = false;
    }
}
</script>
<style scoped>

.aml-ad-item {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    padding: 6px 10px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    box-sizing: border-box;
}

</style>