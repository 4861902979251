<template>
<div>

    <PopupV1 :header="'Select branch'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ 'Please select your branch' + ':' }}
            </div>
            <DialogContent style="float: left; width: 100%; padding-top: 20px; padding-bottom: 10px;">
                <vap-row>
                    <vap-group c12>
                        <vap-label desc required :text="'Branch'" />
                        <vap-dropdown
                            v-model="departmentModel"
                            :options="departments"
                            :displayKey="'name'"
                            :outputKey="'key'"
                            :listKey="'name'"
                            @input="validateDialog"
                        />
                    </vap-group>
                </vap-row>
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <!-- <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" /> -->
            <VFButton :text="'Save'"
                :icon="'check'"
                @clicked="onSaveBranch"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import api from '../portalapi';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'DepartmentDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            departmentModel: '',
            departments: []
        }
    },
    methods: {

        validateDialog() {
            this.dialog.valid = this.departmentModel != '';
        },

        async onSaveBranch() {
            this.dialog.working = true;

            const user = {
                uid: this.$root.credentials.uid,
                branch: this.departmentModel
            };
            await api.updateUser(user);

            this.dialog.working = false;
            this.$emit('xclose');
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        this.loading = true;
        this.dialog.working = true;
        this.dialog.status = this.__t('verified-reminder-loading');

        this.departments = [];
        for (const i in this.$root.departments) {
            this.departments.push({
                key: this.$root.departments[i].key,
                name: this.$root.departments[i].name
            });
        }

        this.dialog.working = false;
        this.loading = false;
        this.validateDialog();
    }
}
</script>