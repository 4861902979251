const config = {
    getDefault() {
        let configuration = {
            locales: [
                {
                    key: 'en_EN',
                    short: 'EN',
                    name: 'English',
                    enabled: true
                },
                {
                    key: 'nb_NO',
                    short: 'NO',
                    name: 'Norsk',
                    enabled: true
                },
                {
                    key: 'sv_SE',
                    short: 'SE',
                    name: 'Svenska',
                    enabled: false
                },
                {
                    key: 'fi_FI',
                    short: 'FI',
                    name: 'Suomi',
                    enabled: false
                },
                {
                    key: 'da_DK',
                    short: 'DK',
                    name: 'Dansk',
                    enabled: false
                },
                {
                    key: 'de_DE',
                    short: 'DE',
                    name: 'Deutsch',
                    enabled: false
                }
            ],
            portal: {
                header: 'AML Portal',
                logoUrl: process.env.VUE_APP_BASE_URL+'verified-logo-white.svg',
                logoWidth: 100,
                logoTop: 15,
                logoLeft: 20,
                defaultType: 'company',
                defaultPerson: 'norway',
                defaultCompany: 'norway',
                roundedButtons: true,
                darkModeEnabled: false,
                devmodeEnabled: false,
                settingsEnabled: true,
                confirmActions: false,
                departments: false,
                partners: false,
                reminders: false,
                deletePermission: 'admin',
                smsNotificationPermission: 'regular',
                usev2receipts: false,
                defaultSort: 'date_updated_desc',
                filterDateCreated: false,
                filterDateUpdated: false,
                updateStatus: false,
                referenceNumber: false,
                useNewIdControl: false,
                useNewKyc: false,
                useNewSettings: false,
                suitablityAssessment: false,
                useV1: false,
                migrate: false,
                migrateLink: false,
                migrateLinkNetlify: '',
                migrateLinkAws: '',
                sb1rhName: '',
                entityOrgOptional: false,
                filters: {
                    status: {
                        enabled: true,
                        locale: 'filters_status'
                    },
                    expires: {
                        enabled: false,
                        locale: 'filters_expires'
                    },
                    flow: {
                        enabled: false,
                        locale: 'filters_flow'
                    },
                    user: {
                        enabled: true,
                        search: false,
                        limit: 0,
                        locale: 'filters_team_members'
                    },
                    risk: {
                        enabled: true,
                        locale: 'filters_risk'
                    },
                    department: {
                        enabled: false,
                        search: false,
                        limit: 0,
                        locale: 'filters_department'
                    },
                    partner: {
                        enabled: false,
                        search: false,
                        limit: 0,
                        locale: 'filters_partner'
                    },
                    customstatus: {
                        enabled: false,
                        locale: 'filters_custom_status',
                        nomatch: 'shareapp_none'
                    },
                    tags: {
                        enabled: false,
                        search: true,
                        limit: 10,
                        locale: 'filters_tags'
                    },
                    status1: {
                        enabled: false,
                        locale: 'filters_custom_status',
                        nomatch: 'shareapp_none'
                    },
                    status2: {
                        enabled: false,
                        locale: 'filters_custom_status',
                        nomatch: 'shareapp_none'
                    }
                }
            },
            kycs: [
                {
                    key: 'kyc1',
                    name: 'Standard egenerklæringsskjema',
                    title: 'Standard egenerklæringsskjema',
                    logoUrl: process.env.VUE_APP_BASE_URL + 'verified-logo-white.png',
                    logoWidth: 100,
                    logoTop: 15,
                    logoLeft: 20,
                    redirect: 'https://verified.eu',
                    email: {
                        logo: process.env.VUE_APP_BASE_URL + 'verified-logo-blue.png',
                        redirect: 'https://verified.eu',
                        link: 'https://verified.eu'
                    },
                    publicToken: 'PUT_TOKEN_HERE',
                    settings: {
                        usev2receipts: false,
                        assignmentUpload: false,
                        landingPage: true,
                        silentParty: '',
                        expirationDays: '14',
                        reminderDays: '7',
                        recipientPortal: false
                    },
                    customSender: {
                        firstName: '',
                        lastName: '',
                        email: ''
                    },
                    methods: [
                        'bankid-no', 'bankid-se', 'touch-sign'
                    ],
                    defaultLanguage: 'nb_NO',
                    landing: {
                        logo: process.env.VUE_APP_BASE_URL + 'verified-logo-blue.svg',
                        redirect: 'https://verified.eu'
                    },
                    roles: ['ceo', 'chair', 'proprietor'],
                    inputs: {
                        officials: {
                            name: 2,
                            percentage: 0,
                            address: 2,
                            email: 2,
                            phone: 0,
                            pep: 2,
                            title: 0,
                        },
                        rrh: {
                            name: 2,
                            percentage: 2,
                            address: 2,
                            email: 2,
                            phone: 0,
                            pep: 2,
                            title: 0,
                        },
                        pep: {
                            name: 2,
                            percentage: 0,
                            address: 0,
                            email: 2,
                            phone: 2,
                            pep: 0,
                            title: 2,
                        }
                    },
                    steps: {
                        steps_owner: [
                            {
                                key: 'company',
                                type: 'company',
                                header: 'kyc_step_company'
                            },
                            {
                                key: 'roles',
                                type: 'roles',
                                header: 'kyc_step_roles'
                            },
                            {
                                key: 'rrh',
                                type: 'rrh',
                                header: 'kyc_step_rrh'
                            },
                            {
                                key: 'structure',
                                type: 'structure',
                                header: 'kyc_step_entity'
                            },
                            {
                                key: 'pep',
                                type: 'pep',
                                header: 'kyc_step_pep'
                            },
                            {
                                key: 'purpose',
                                type: 'purpose',
                                header: 'kyc_step_purpose'
                            },
                            {
                                key: 'recipient',
                                type: 'recipient',
                                header: 'kyc_step_recipient'
                            }
                        ],
                        steps_editor: [
                            {
                                key: 'welcome',
                                type: 'welcome',
                                header: 'kyc_step_welcome'
                            },
                            {
                                key: 'company',
                                type: 'company',
                                header: 'kyc_step_company'
                            },
                            {
                                key: 'roles',
                                type: 'roles',
                                header: 'kyc_step_roles'
                            },
                            {
                                key: 'rrh',
                                type: 'rrh',
                                header: 'kyc_step_rrh',
                                confirmOnNoOwners: true
                            },
                            {
                                key: 'structure',
                                type: 'structure',
                                header: 'kyc_step_entity'
                            },
                            {
                                key: 'pep',
                                type: 'pep',
                                header: 'kyc_step_pep'
                            },
                            {
                                key: 'purpose',
                                type: 'purpose',
                                header: 'kyc_step_purpose'
                            },
                            {
                                key: 'checkout',
                                type: 'checkout',
                                header: 'kyc_step_signers'
                            }
                        ]
                    },
                    help: {
                        owner: {
                            company_aml: {
                                head: 'help_company_aml_head', desc: 'help_company_aml_desc',
                                items: [
                                    { icon: 'company', key: 'help_o_company_aml_1' }
                                ]
                            },
                            company_man: {
                                head: 'help_company_man_head', desc: 'help_company_man_desc',
                                items: [
                                    { icon: 'company', key: 'help_o_company_man_1' }
                                ]
                            },
                            aagree: {
                                head: 'help_aagree_head', desc: 'help_aagree_desc',
                                items: [
                                    { icon: 'checklist', key: 'help_o_aagree_1' }
                                ]
                            },
                            roles_aml: {
                                head: 'help_roles_aml_head', desc: 'help_roles_aml_desc',
                                items: [
                                    { icon: 'people', key: 'help_o_roles_aml_1' }
                                ]
                            },
                            roles_man: {
                                head: 'help_roles_man_head', desc: 'help_roles_man_desc',
                                items: [
                                    { icon: 'people', key: 'help_o_roles_man_1' }
                                ]
                            },
                            rrh_aml: {
                                head: 'help_rrh_aml_head', desc: 'help_rrh_aml_desc',
                                items: [
                                    { icon: 'help-o', key: 'help_o_rrh_aml_1' },
                                    { icon: 'orgchart', key: 'help_o_rrh_aml_2' }
                                ]
                            },
                            rrh_man: {
                                head: 'help_rrh_man_head', desc: 'help_rrh_man_desc',
                                items: [
                                    { icon: 'help-o', key: 'help_o_rrh_man_1' },
                                    { icon: 'orgchart', key: 'help_o_rrh_aml_2' }
                                ]
                            },
                            entity_aml: {
                                head: 'help_entity_aml_head', desc: 'help_entity_aml_desc',
                                items: [
                                    { icon: 'orgchart', key: 'help_o_entity_aml_1' }
                                ]
                            },
                            entity_man: {
                                head: 'help_entity_man_head', desc: 'help_entity_man_desc',
                                items: [
                                    { icon: 'orgchart', key: 'help_o_entity_man_1' }
                                ]
                            },
                            pep: {
                                head: 'help_pep_head', desc: 'help_pep_desc',
                                items: [
                                    { icon: 'people', key: 'help_o_pep_1' }
                                ]
                            },
                            purpose: {
                                head: 'help_purpose_head', desc: 'help_purpose_desc',
                                items: [
                                    { icon: 'help-o', key: 'help_o_purpose_1' }
                                ]
                            },
                            book: {
                                head: 'help_book_head', desc: 'help_book_desc',
                                items: [
                                    { icon: 'orgchart', key: 'help_o_book_1' }
                                ]
                            },
                            checkout: {
                                head: 'help_o_checkout_head', desc: 'help_o_checkout_desc',
                                items: [
                                    { icon: 'edit', key: 'help_o_checkout_1' }
                                ]
                            }
                        },
                        editor: {
                            company_aml: {
                                head: 'help_company_aml_head', desc: 'help_company_aml_desc',
                                items: [
                                    { icon: 'company', key: 'help_e_company_aml_1' }
                                ]
                            },
                            company_man: {
                                head: 'help_company_man_head', desc: 'help_company_man_desc',
                                items: [
                                    { icon: 'company', key: 'help_e_company_man_1' }
                                ]
                            },
                            aagree: {
                                head: 'help_aagree_head', desc: 'help_aagree_desc',
                                items: [
                                    { icon: 'checklist', key: 'help_e_aagree_1' }
                                ]
                            },
                            roles_aml: {
                                head: 'help_roles_aml_head', desc: 'help_roles_aml_desc',
                                items: [
                                    { icon: 'people', key: 'help_e_roles_aml_1' }
                                ]
                            },
                            roles_man: {
                                head: 'help_roles_man_head', desc: 'help_roles_man_desc',
                                items: [
                                    { icon: 'people', key: 'help_e_roles_man_1' }
                                ]
                            },
                            rrh_aml: {
                                head: 'help_rrh_aml_head', desc: 'help_rrh_aml_desc',
                                items: [
                                    { icon: 'help-o', key: 'help_e_rrh_aml_1' },
                                    { icon: 'orgchart', key: 'help_o_rrh_aml_2' }
                                ]
                            },
                            rrh_man: {
                                head: 'help_rrh_man_head', desc: 'help_rrh_man_desc',
                                items: [
                                    { icon: 'help-o', key: 'help_e_rrh_man_1' },
                                    { icon: 'orgchart', key: 'help_o_rrh_aml_2' }
                                ]
                            },
                            entity_aml: {
                                head: 'help_entity_aml_head', desc: 'help_entity_aml_desc',
                                items: [
                                    { icon: 'orgchart', key: 'help_e_entity_aml_1' },
                                    { icon: 'help-o', key: 'help_e_entity_man_2' }
                                ]
                            },
                            entity_man: {
                                head: 'help_entity_man_head', desc: 'help_entity_man_desc',
                                items: [
                                    { icon: 'orgchart', key: 'help_e_entity_man_1' },
                                    { icon: 'help-o', key: 'help_e_entity_man_2' }
                                ]
                            },
                            pep: {
                                head: 'help_pep_head', desc: 'help_pep_desc',
                                items: [
                                    { icon: 'people', key: 'help_e_pep_1' },
                                    { icon: 'help-o', key: 'help_e_pep_2' }
                                ]
                            },
                            purpose: {
                                head: 'help_purpose_head', desc: 'help_purpose_desc',
                                items: [
                                    { icon: 'help-o', key: 'help_e_purpose_1' }
                                ]
                            },
                            book: {
                                head: 'help_book_head', desc: 'help_book_desc',
                                items: [
                                    { icon: 'orgchart', key: 'help_e_book_1' }
                                ]
                            },
                            checkout: {
                                head: 'help_e_checkout_head', desc: 'help_e_checkout_desc',
                                items: [
                                    { icon: 'sign', key: 'help_e_checkout_1' },
                                    { icon: 'people', key: 'help_e_checkout_2' },
                                ]
                            }
                        },
                    }
                },
            ],
            idcs: [
                {
                    key: 'idc1',
                    name: 'Standard ID-kontroll',
                    title: 'ID-kontroll',
                    logo: process.env.VUE_APP_BASE_URL + 'verified-logo-blue.svg',
                    redirect: 'https://verified.eu',
                    email: {
                        logo: process.env.VUE_APP_BASE_URL + 'verified-logo-blue.png',
                        link: 'https://verified.eu'
                    },
                    token: 'PUT_TOKEN_HERE',
                    oidcUri: 'wildcard',
                    methods: [
                        'bankid-no'
                    ],
                    allowCustomSender: false,
                    useCustomSender: false,
                    customSender: {
                        firstName: '',
                        lastName: '',
                        email: ''
                    },
                    passportOnly: false,
                    designer: false,
                    smsEnabled: false,
                    smsGreeting: 'I henhold til lov om hvitvasking trenger vi å bekrefte din ID i forbindelse med vårt samarbeid. Trykk på linken for å åpne ID-kontrollen. Mvh {{companyName}}'
                },
            ],
            pdf: {
                logoUrl: process.env.VUE_APP_BASE_URL + 'verified-logo-blue.png',
                logoWidth: 160
            },
            modules: {
                multipleKyc: false,
                multipleIdc: false,
                personNorway: false,
                personSweden: false,
                personOther: false,
                companyNorway: true,
                companySweden: false,
                companyUK: false,
                companyOther: true,
                massPerson: false,
                massCompany: false,
                personSearch: false
            },
            flows: {
                custom: {
                    enabled: false,
                    flows: [
                        'companyBrreg'
                    ]
                },
                companyBrreg: {
                    enabled: true,
                    shareapp: false,
                    ownership: false,
                    customStatus: false,
                    steps: [
                        { key: 'company_aml' },
                        { key: 'company_kyc' },
                        { key: 'company_per' },
                        { key: 'company_rep' },
                    ],
                    statuses: [
                        {
                            key: 'c-org-c',
                            text: {
                                en_EN: 'Awaiting company search',
                                nb_NO: 'Avventer selskapssøk'
                            },
                            triggers: [
                                { type: 'cst', trg: 'created' }
                            ]
                        },
                        {
                            key: 'f-kyc-c',
                            text: {
                                en_EN: 'Awaiting sending self-declaration',
                                nb_NO: 'Avventer utsendelse egenerklæring'
                            },
                            triggers: [
                                { type: 'cst', trg: 'created' },
                                { type: 'cst', trg: 'screened' }
                            ]
                        },
                        {
                            key: 'f-kyc-e',
                            text: {
                                en_EN: 'Awaiting filling self-declaration',
                                nb_NO: 'Avventer utfylling egenerklæring'
                            },
                            triggers: [
                                { type: 'frm', trg: 'created', key: 'kyc01' },
                            ]
                        },
                        {
                            key: 'f-kyc-h',
                            text: {
                                en_EN: 'Awaiting signing self-declaration',
                                nb_NO: 'Avventer signering egenerklæring'
                            },
                            triggers: [
                                { type: 'frm', trg: 'completed', key: 'kyc01' },
                            ]
                        }
                    ]
                },
                companySweden: {
                    enabled: true,
                    shareapp: false,
                    steps: [
                        { key: 'company_se' },
                        { key: 'company_kyc' },
                        { key: 'company_per' },
                        { key: 'company_rep' },
                    ]
                },
                companyUK: {
                    enabled: true,
                    shareapp: false,
                    steps: [
                        { key: 'company_uk' },
                        { key: 'company_kyc' },
                        { key: 'company_per' },
                    ],
                    statuses: [
                        {
                            key: 'c-org-c',
                            text: {
                                en_EN: 'Awaiting company search',
                                nb_NO: 'Avventer selskapssøk'
                            },
                            triggers: ['created']
                        },
                        {
                            key: 'f-chk-c',
                            text: {
                                en_EN: 'Awaiting checklist completion',
                                nb_NO: 'Avventer fullførelse av sjekkliste'
                            },
                            triggers: [
                                { type: 'cst', trg: 'created' },
                                { type: 'cst', trg: 'screened' }
                            ]
                        }
                    ]
                },
                companyManual: {
                    enabled: true,
                    steps: [
                        { key: 'company_kyc' },
                        { key: 'company_per' },
                        { key: 'company_rep' },
                    ]
                },
                personNorway: {
                    enabled: false,
                    idcIndex: 0,
                    steps: [
                        { key: 'person_idp' },
                        { key: 'person_rep' },
                    ]
                },
                companyBrregLookup: {
                    enabled: false,
                    steps: [
                        { key: 'company_aml' },
                        { key: 'company_per' },
                    ]
                },
                personLookup: {
                    enabled: false,
                    allowEdit: true,
                    contactInfo: true,
                    steps: [
                        { key: 'person_idc' },
                        { key: 'person_pep' },
                    ]
                }
            },
            aml: {
                autoRoles: [
                    'ceo',
                    'chair',
                    'screening_role_rrh',
                ],
                person: {
                    ofac: false,
                    ssn: false,
                    report: false,
                    autoCheck: false,
                    searchAll: false,
                    idcSms: false,
                    acceptSignature: false,
                    disableChecks: false,
                    provider: 'bankid-aml',
                    dsfEnabled: false
                },
                company: {
                    ofac: false,
                    ownership: false
                },
                compareData: false,
                compareRoles: false,
                contactPerson: false
            },
            report: {
                flow: 'none',
                pdf: {
                    aml: 'attachment',
                    kyc: 'attachment'
                },
                kycAutoComment: false,
                ssn: false,
                merged: false,
                mergedDownload: false,
                riskAssessment: true,
                autoRisk: false,
                autoRiskType: 'default',
                uploadExternalRisk: false,
                hitPepAction: 'none',
                hitSanctionAction: 'none',
                keywordsEnabled: false,
                keywords: [ 'terrorism', 'fraud', 'sentance', 'insideTrading', 'illegal', 'fine', 'corruption', 'trial', 'moneyLaundering', 'lawsuit' ]
            },
            integrations: {
                maconomy: {
                    enabled: false
                }
            },
            internals: [
                {
                    firstName: 'Test',
                    lastName: 'Testerson',
                    email: 'magnus.ursin@verified.eu',
                    role: 'Head of Testing'
                }
            ],
            colors: [
                { name: 'Header', key: 'header', value: '#238CCC', darkValue: '#238CCC', show: true, hint: "Changes the background of the header at the very top of the page." },
                { name: 'Page background', key: 'page-background', value: '#F4F4F4', darkValue: '#1B1D1E', show: true, hint: "The background color of all pages." },
                { name: 'Card background', key: 'card-background', value: '#FFFFFF', darkValue: '#121415', show: false },
                { name: 'Card Hover', key: 'card-hover', value: '#FAFDFF', darkValue: '#111213', show: false },
                { name: 'Card Hover Border', key: 'card-hover-border', value: '#D1D7E3', darkValue: '#212121', show: false },
                { name: 'Item Hover', key: 'item-hover', value: '#E1E1E1', darkValue: '#212121', show: false },
                { name: 'Text Strong', key: 'text-strong', value: '#000000', darkValue: '#CDCDCD', show: false },
                { name: 'Text Title', key: 'text-title', value: '#444444', darkValue: '#D1D1D1', show: false },
                { name: 'Text Weak', key: 'text-weak', value: '#878787', darkValue: '#9C9C9C', show: false },
                { name: 'Text Disabled', key: 'text-disabled', value: '#D3D3D3', darkValue: '#404040', show: false },
                { name: 'Disabled Background', key: 'disabled-background', value: '#D3D3D3', darkValue: '#404040', show: false },
                { name: 'Disabled Text', key: 'disabled-text', value: '#FFFFFF', darkValue: '#777777', show: false },
                { name: 'Text Accent', key: 'text-accent', value: '#238CCC', darkValue: '#238CCC', show: true, hint: "Changes the color of important texts and links throughout the portal, such as the names of customers and clickable items." },
                { name: 'Primary', key: 'primary', value: '#238CCC', darkValue: '#238CCC', show: true, hint: "Changes the color of key icons and backgrounds throughout the portal, such as the different steps in a form." },
                { name: 'Primary Contrast', key: 'primary-contrast', value: '#FFFFFF', darkValue: '#FFFFFF', show: true, hint: "Changes the color of icons/texts that are put on top of the primary background, such as the number of each step in a form." },
                { name: 'Primary Weak', key: 'primary-weak', value: '#DCF0FA', darkValue: '#DCF0FA', show: false },
                { name: 'Accent', key: 'accent', value: '#238CCC', darkValue: '#238CCC', show: true },
                { name: 'Accent Contrast', key: 'accent-contrast', value: '#FFFFFF', darkValue: '#FFFFFF', show: true },
                { name: 'Button', key: 'button', value: '#238CCC', darkValue: '#238CCC', show: true },
                { name: 'Button Text', key: 'button-text', value: '#FFFFFF', darkValue: '#FFFFFF', show: true },
                { name: 'Tab', key: 'tab', value: '#238CCC', darkValue: '#238CCC', show: true },
                { name: 'Header Hover', key: 'header-hover', value: '#3995D6', darkValue: '#3995D6', show: true },
                { name: 'Header Separator', key: 'header-sep', value: '#1478B4', darkValue: '#1478B4', show: true },
                { name: 'Header Text', key: 'header-text', value: '#ffffff', darkValue: '#ffffff', show: true },
                { name: 'Header Link', key: 'header-link', value: '#C7EAFF', darkValue: '#C7EAFF', show: true },
                { name: 'Header Link Active', key: 'header-link-active', value: '#ffffff', darkValue: '#ffffff', show: true },
                { name: 'Link Text', key: 'link-text', value: '#218BCB', darkValue: '#218BCB', show: true },
                { name: 'Link Active', key: 'link-active', value: '#6AB2DE', darkValue: '#6AB2DE', show: true },
                { name: 'Sucess Background', key: 'success-background', value: '#218BCB', darkValue: '#218BCB', show: true },
                { name: 'Sucess Text', key: 'success-text', value: '#FFFFFF', darkValue: '#FFFFFF', show: true },
                { name: 'Warning Background', key: 'warning-background', value: '#FADD38', darkValue: '#FADD38', show: false },
                { name: 'Warning Text', key: 'warning-text', value: '#2E2702', darkValue: '#2E2702', show: false },
                { name: 'Error Background', key: 'error-background', value: '#D4515C', darkValue: '#D4515C', show: false },
                { name: 'Error Text', key: 'error-text', value: '#270306', darkValue: '#270306', show: false },
                { name: 'Validation OK', key: 'val-ok', value: '#218BCB', darkValue: '#218BCB', show: false },
                { name: 'Validation Error', key: 'val-error', value: '#B62537', darkValue: '#B62537', show: false },
                { name: 'Alert Info', key: 'alert-info', value: '#238CCC', darkValue: '#238CCC', show: true },
                { name: 'Alert Info Text', key: 'alert-info-text', value: '#ffffff', darkValue: '#ffffff', show: true },
                { name: 'Help Background', key: 'help-background', value: '#D9EDF7', darkValue: '#2B3A42', show: true },
                { name: 'Help Text', key: 'help-text', value: '#36708F', darkValue: '#90BDD3', show: true },
                { name: 'Input Border', key: 'input-border', value: '#E1E1E1', darkValue: '#272727', show: false },
                { name: 'Input Focus', key: 'input-focus', value: '#686868', darkValue: '#686868', show: false },
                { name: 'Input Text', key: 'input-text', value: '#151515', darkValue: '#DDDDDD', show: false },
                { name: 'Input Placeholder', key: 'input-placeholder', value: '#D5D5D8', darkValue: '#434343', show: false },
                { name: 'Input Label', key: 'input-label', value: '#686868', darkValue: '#686868', show: false },
                { name: 'Input Accent', key: 'input-accent', value: '#238CCC', darkValue: '#238CCC', show: true },
                { name: 'Stepper Background', key: 'stepper-bg', value: '#FFFFFF', darkValue: '#141617', show: true },
                { name: 'Action Background', key: 'action-background', value: '#F6F6F6', darkValue: '#1E1E1E', show: false },
                { name: 'Action Border', key: 'action-border', value: '#E1E1E1', darkValue: '#252525', show: false },
                { name: 'PDF Header Background', key: 'pdf-header-bg', value: '#238CCC', darkValue: '#238CCC', show: true },
                { name: 'PDF Header Text', key: 'pdf-header-text', value: '#FFFFFF', darkValue: '#FFFFFF', show: true },
                { name: 'PDF Input Accent', key: 'pdf-input-accent', value: '#238CCC', darkValue: '#238CCC', show: true },
            ],
            search: {
                org: {
                    uk: { enabled: false, default: false, provider: 'chouse' },
                    no: { enabled: true, default: true, provider: 'bankid-aml', underCreation: false, skipLookup: false },
                    se: { enabled: false, default: false, provider: 'trapets-bisnode', underCreation: false, numberOnly: true },
                    fi: { enabled: false, default: false, provider: 'bisnode' },
                    dk: { enabled: false, default: false, provider: 'bisnode' }
                },
                person: {
                    enabled: false,
                    default: false,
                    visibleInputs: {
                        email: true,
                        phone: true,
                        citizenship: true,
                        ssn: false,
                    }
                },
                forreign: {
                    enabled: false
                },
                v2search: false,
                forceDepartments: false
            },
            features: {
                continuousScreening: false,
                exportClient: false,
                importClient: false,
                watchList: false,
                externalFiles: {
                    enabled: false,
                    types: [
                        { type: 'other', text: { en_EN: 'Other', nb_NO: 'Annet', sv_SE: 'Annat' } },
                    ]
                },
                autoActions: false,
                autoUpdates: {
                    enabled: false,
                },
                autoReminders: {
                    enabled: false,
                    defaultDelayMinutes: 10080, // 7 days
                    defaultRecurringDelay: 10080, // 7 days
                    defaultAttempts: 3, // 3 reminder attempts = 35 days total before giving up
                },
            },
            params: {
                accountStatus: 'demo',
                baseCountry: 'XX',
                defaultLanguage: 'en_EN',
                defaultPrefix: '+47',
                dashboardLink: 'default',
                dateFormat: 'iso',
                hasDepartments: false,
                signMethods: {
                    bankidno: true,
                    bankidse: true,
                    touchsign: true
                },
                authMethods: {
                    bankidno: true,
                    bankidse: true,
                    passreader: false
                },
                limitRegular: false,
            },
            events: {
                riskDisabled: false,
                messages: false,
                update: false,
                updateInterval: 150,
                highlight: false,
                maxEventDays: 30,
            },
            clients: {
                reports: false,
                archive: true,
                archiveUnchecked: false,
                maxEvents: 10,
                newSearch: false,
                allowFlowChange: false,
                defaultSort: 'date_updated_desc',
                lastNameSort: false,
                personItems: {
                    dateOfBirth: false,
                    citizenship: false
                },
                filters: {
                    status: {
                        enabled: true, search: false, limit: 0, key: 'status', textKey: 'status',
                        title: {
                            en_EN: 'Status',
                            nb_NO: 'Status',
                            sv_SE: 'Status'
                        }
                    },
                    flow: {
                        enabled: true, search: false, limit: 0, key: 'flow', textKey: 'flows',
                        title: {
                            en_EN: 'Flow',
                            nb_NO: 'Flyt',
                            sv_SE: 'Flöde'
                        }
                    },
                    userId: {
                        enabled: true, search: false, limit: 0, key: 'userId', textKey: 'employees',
                        title: {
                            en_EN: 'Team members',
                            nb_NO: 'Medarbeidere',
                            sv_SE: 'Anställda'
                        }
                    },
                    tags: {
                        enabled: false, search: false, limit: 0, key: 'tags', textKey: 'tags',
                        title: {
                            en_EN: 'Tags',
                            nb_NO: 'Tagger',
                            sv_SE: 'Tags'
                        }
                    },
                    hits: {
                        enabled: false, search: false, limit: 0, key: 'flags.hits', textKey: 'hits',
                        title: {
                            en_EN: 'Screening hits',
                            nb_NO: 'Søketreff',
                            sv_SE: 'Sökresultat'
                        }
                    },
                    risk: {
                        enabled: true, search: false, limit: 0, key: 'risk', textKey: 'risk',
                        title: {
                            en_EN: 'Risk',
                            nb_NO: 'Risiko',
                            sv_SE: 'Risk'
                        }
                    }
                }
            },
            roles: {
                linkEnabled: false,
                filters: {
                    userId: {
                        enabled: true, search: false, limit: 0, key: 'userId', textKey: 'employees',
                        title: {
                            en_EN: 'Team members',
                            nb_NO: 'Medarbeidere',
                            sv_SE: 'Anställda'
                        }
                    },
                }
            },
            company: {
                mapNorway: false,
                bamlExpandsV2: false,
            },
            persons: {
                checksDisabled: false,
                mergingDisabled: false,
                expandDisabled: false,
                infoDisabled: false,
                autoKeyRoles: ['role_proprietor', 'role_ceo', 'role_chair', 'role_rrh'],
                idControlPopup: false,
                acceptSignature: true,
                selectSignature: false,
                identityInfo: false,
                screeningInfo: false,
                allowFalsePositive: false,
                allowIdentityUpdate: false,
                geography: false,
                listPopup: false,
                selectContact: false
            },
            adverseMedia: {
                enabled: false,
                orgEnabled: false,
                mode: 'manual',
                hitsOnly: false,
                keywords: [
                    { enabled: true, key: 'terrorism', locale: { en_EN: 'Terrorism', nb_NO: 'Terrorisme', sv_SE: 'Terrorism' } },
                    { enabled: true, key: 'fraud', locale: { en_EN: 'Fraud', nb_NO: 'Svindel', sv_SE: 'Bedrägeri' } },
                    { enabled: true, key: 'sentance', locale: { en_EN: 'Sentance', nb_NO: 'Dom', sv_SE: 'Döma' } },
                    { enabled: true, key: 'insideTrading', locale: { en_EN: 'Inside Trading', nb_NO: 'Innsidehandel', sv_SE: 'Insiderhandel' } },
                    { enabled: true, key: 'illegal', locale: { en_EN: 'Illegal', nb_NO: 'Ulovlig', sv_SE: 'Illegal' } },
                    { enabled: true, key: 'fine', locale: { en_EN: 'Fine', nb_NO: 'Bot', sv_SE: 'Böter' } },
                    { enabled: true, key: 'corruption', locale: { en_EN: 'Corruption', nb_NO: 'Korrupsjon', sv_SE: 'Korruption' } },
                    { enabled: true, key: 'trial', locale: { en_EN: 'Trial', nb_NO: 'Rettssak', sv_SE: 'Rättegång' } },
                    { enabled: true, key: 'moneyLaundering', locale: { en_EN: 'Money Laundering', nb_NO: 'Hvitvasking', sv_SE: 'Pengatvätt' } },
                    { enabled: true, key: 'lawsuit', locale: { en_EN: 'Lawsuit', nb_NO: 'Søksmål', sv_SE: 'Rättegång' } },
                ],
                languages: ['nb_NO'],
                validMinutes: 0,
            },
            screening: {
                nordicProvider: 'trapets',
                globalProvider: 'trapets',
                personMonitor: false,
                companyMonitor: false,
                validMinutes: 0,
                personMonitorTest: false,
            },
            forms: {
                editor: { allowNew: false, advanced: false, simple: true, enabled: true },
                clientBar: false,
                newLanding: false
            },
            flow: {
                editor: { allowNew: false, enabled: false },
                uploadKyc: false,
                analogKyc: false,
                reportChecklist: false,
                updateButton: false,
            },
            risk: {
                enabled: true,
                values: [0, 2, 4, 7],
                texts: [
                    { value: 0, color: 'disabled-background', en_EN: 'Not assessed', nb_NO: 'Ikke satt', sv_SE: 'Inte bedömts' },
                    { value: 2, color: 'success-background', en_EN: 'Low', nb_NO: 'Lav', sv_SE: 'Låg' },
                    { value: 4, color: 'warning-background', en_EN: 'Medium', nb_NO: 'Medium', sv_SE: 'Medium' },
                    { value: 7, color: 'error-background', en_EN: 'High', nb_NO: 'Høy', sv_SE: 'Hög' },
                ],
                lockHighIfPep: false,
                autoEnabled: false,
                autoItems: [
                    {
                        key: 'r01',
                        type: 'reg-country',
                        weight: 100,
                        multiL: 0,
                        multiH: 0
                    },
                    {
                        key: 'r02',
                        type: 'entity-type',
                        weight: 100,
                        multiL: 0,
                        multiH: 0
                    },
                    {
                        key: 'r03',
                        type: 'nace-code',
                        weight: 100,
                        multiL: 0,
                        multiH: 0
                    },
                    {
                        key: 'r04',
                        type: 'startup-date',
                        weight: 100,
                        multiL: 0,
                        multiH: 0
                    },
                    {
                        key: 'r05',
                        type: 'key-role-address',
                        weight: 100,
                        multiL: 0,
                        multiH: 0
                    },
                    {
                        key: 'r06',
                        type: 'pep-sanction',
                        weight: 100,
                        multiL: 0,
                        multiH: 100
                    }
                ]
            },
            freport: {
                popupComment: false,
                popupFullscreen: false,
                simpleEnabled: false,
                dualComments: {
                    enabled: false,
                },
                notifications: [],
                notificationsOptional: false,
                nameFormat: 'default'
            },
            defaultFlows: [
                { key: 'no-org-baml', enabled: false },
                { key: 'se-org-bistrap', enabled: false },
                { key: 'uk-org-chouse', enabled: false },
                { key: 'xx-person', enabled: false },
                { key: 'yy-person', enabled: false },
                { key: 'zz-person', enabled: false },
                { key: 'no-org-baml-klp', enabled: false },
                { key: 'no-org-baml-borea', enabled: false },
                { key: 'xx-org', enabled: false },
                { key: 'xx-org-creation', enabled: false },
            ],
            branding: {
                favIcon: '',
                pageTitle: '',
                logoEmail: process.env.VUE_APP_BASE_URL + 'verified-logo-blue.png',
                logoLanding: process.env.VUE_APP_BASE_URL + 'verified-logo-blue.svg',
                redirectUri: 'https://verified.eu'
            },
            branches: {
                enabled: false,
                filtered: false,
                initialSelect: false,
                searchEnabled: false,
                searchRequired: false,
                clientEnabled: false,
                clientText: {
                    en_EN: 'Department',
                    nb_NO: 'Avdeling',
                    sv_SE: ''
                }
            },
            partners: {
                enabled: false,
                searchEnabled: false,
                searchRequired: false,
                clientEnabled: false,
                clientText: {
                    en_EN: 'Partner',
                    nb_NO: 'Partner',
                    sv_SE: 'Partner'
                }
            },
            responsible: {
                enabled: false,
                searchEnabled: false,
                searchRequired: false,
                clientEnabled: false,
                clientText: {
                    en_EN: 'Associate',
                    nb_NO: 'Kundeansvarlig',
                    sv_SE: 'Kundansvarig'
                }
            },
            projects: {
                enabled: false,
            },
            externalId: {
                enabled: false,
                searchEnabled: false,
                searchRequired: false,
                clientEnabled: false,
                clientsEnabled: false,
                title: {
                    en_EN: 'Reference Number',
                    nb_NO: 'Referansenummer',
                    sv_SE: 'Referensnummer'
                },
                textValue: {
                    en_EN: "Your reference number: ",
                    nb_NO: "Ditt referansenummer: ",
                    sv_SE: "Your reference number: "
                }
            },
            tags: {
                clientsShow: false,
                clientsAdd: false,
                clientEnabled: false,
                editable: true,
                searchShow: false,
                list: [
                    /* { key: 'low', color: 1, text: { en_EN: 'Low Risk', nb_NO: 'Lav risiko', sv_SE: 'Låg' } },
                    { key: 'medium', color: 2, text: { en_EN: 'Medium Risk', nb_NO: 'Medium risiko', sv_SE: 'Medium' } },
                    { key: 'high', color: 3, text: { en_EN: 'High Risk', nb_NO: 'Høy risiko', sv_SE: 'Hög' } }, */
                ]
            },
            export: {
                enabled: false,
                columns: [
                    { cat: 1, key: 'clientName', enabled: true, default: false },
                ]
            }
        }
        return configuration;
    },

    // Deep-merge the default config with the custom config
    mergeConfig(standard, custom) {
        this.mergeObject(standard, custom);
    },
    mergeObject(standard, custom) {
        for (const key in custom) {
            if (key == 'colors') {
                this.mergeColors(standard[key], custom[key]);
            }else if (key == 'methods') {
                standard[key] = custom[key];
            }else if (this.isObject(custom[key])) {
                if (!standard[key]) {
                    standard[key] = custom[key];
                }else {
                    this.mergeObject(standard[key], custom[key]);
                }
            }else {
                standard[key] = custom[key];
            }
        }
    },
    mergeColors(standard, custom) {
        for (let i in standard) {
            for (let j in custom) {
                if (custom[j].key == standard[i].key) {
                    standard[i].value = custom[j].value;
                    standard[i].darkValue = custom[j].darkValue;
                }
            }
        }
    },
    isObject(item) {
        return (item && typeof item == 'object');
    }

}
export default config;