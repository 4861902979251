<template>
<div>

    <PropertyItem :header="'Checklist item'" :icon="'check-c'">
        <vap-row>
            <vap-group c6 compact>
                <vap-label :text="'ID Source'" desc />
                <vap-dropdown
                    v-model="input.ids"
                    :options="$parent.$parent.$parent.$parent.resources['chkOpts'].items"
                    :displayKey="'en_EN'"
                    :listKey="'en_EN'"
                    :outputKey="'value'"
                    noValidation
                    noHighlight
                />
            </vap-group>
            <vap-group c6 compact>
                <vap-label :text="'V Source'" desc />
                <vap-dropdown
                    v-model="input.vs"
                    :options="$parent.$parent.$parent.$parent.resources['chkOpts'].items"
                    :displayKey="'en_EN'"
                    :listKey="'en_EN'"
                    :outputKey="'value'"
                    noValidation
                    noHighlight
                />
            </vap-group>

            <vap-group c6>
                <vap-dropdown
                    v-model="input.idsL"
                    :options="ddOptions"
                    :displayKey="'en_EN'"
                    :listKey="'en_EN'"
                    :outputKey="'value'"
                    noValidation
                    noHighlight
                />
            </vap-group>
            <vap-group c6>
                <vap-dropdown
                    v-model="input.vsL"
                    :options="ddOptions"
                    :displayKey="'en_EN'"
                    :listKey="'en_EN'"
                    :outputKey="'value'"
                    noValidation
                    noHighlight
                />
            </vap-group>

            <vap-group c6>
                <vap-label :text="'QC Result'" desc />
                <vap-dropdown
                    v-model="input.qcr"
                    :options="$parent.$parent.$parent.$parent.resources['chkOpts'].items"
                    :displayKey="'en_EN'"
                    :listKey="'en_EN'"
                    :outputKey="'value'"
                    noValidation
                    noHighlight
                />
            </vap-group>
            <vap-group c6>
                <vap-label :text="'QC Complete'" desc />
                <vap-dropdown
                    v-model="input.qcc"
                    :options="$parent.$parent.$parent.$parent.resources['chkOpts'].items"
                    :displayKey="'en_EN'"
                    :listKey="'en_EN'"
                    :outputKey="'value'"
                    noValidation
                    noHighlight
                />
            </vap-group>
            <vap-group c12 v-if="input.type == 'helpt' || input.type == 'chk-i'">
                <vap-label :text="'Tooltip'" desc />
                <vap-input :value="getTooltip(input)" noValidation actions disabled>
                    <template v-slot:actions>
                        <vap-icon action :icon="'open'" @clicked="$emit('locale', input.tooltip)">
                            <template v-slot:tooltip><vap-tooltip :text="__('fr_localized_text')" /></template>
                        </vap-icon>
                    </template>
                </vap-input>
            </vap-group>
            <vap-group c12>
                <vap-label :text="'QA'" desc />
                <vap-checkbox :text="'QA'" v-model="input.isQA" bordered borderOffset noValidation />
            </vap-group>
        </vap-row>
    </PropertyItem>

    <PropertyItem v-if="input.isList" :header="'Checklist array properties'" :icon="'check-c'">
        <vap-row>
            <vap-group c12>
                <vap-label :text="'Item type'" desc />
                <vap-dropdown
                    v-model="input.mode"
                    :options="typeOptions"
                    :displayKey="'en_EN'"
                    :listKey="'en_EN'"
                    :outputKey="'value'"
                    noValidation
                    noHighlight
                />
            </vap-group>
            <vap-group c12>
                <vap-label :text="'Required for roles'" desc />
                <vap-checkbox v-model="input.roles" :options="$parent.$parent.$parent.$parent.roles" noValidation />
            </vap-group>
        </vap-row>
    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from '../../designer/properties/PropertyItem.vue';

export default {
    name: "frmod-checklist-item-prop",
    components: {
        PropertyItem
    },
    props: {
        input: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            ddOptions: [
                { value: 1, en_EN: 'SDD' },
                { value: 2, en_EN: 'CDD' },
                { value: 3, en_EN: 'EDD' },
            ],
            typeOptions: [
                { value: 'chk', en_EN: 'Data point' },
                { value: 'scr', en_EN: 'Screening point' }
            ]
        }
    },
    methods: {
        
        getTooltip(item) {
            if (!item.tooltip) item.tooltip = {
                formatted: true,
                en_EN: ''
            };
            let desc = item.tooltip[this.$root.locale.key];
            desc = desc.split('<p>').join('');
            desc = desc.split('</p>').join(' ');
            desc = desc.split('<strong>').join('');
            desc = desc.split('</strong>').join('');
            return desc;
        },

    },
    mounted() {
        
    }
}

</script>
