<template>
<div class="v1cb-container">

    <slot></slot>

</div>
</template>
<script>

export default {
    name: 'BarItemContainer',
    props: {
        desc: { type: String, default: '' },
        value: { type: String, default: '' },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        

    }
}
</script>
<style scoped>

.v1cb-container {
    display: flex;
}

</style>