<template>
<div class="risks nohl" :class="{'risks-disabled': disabled }">

    <div class="risks-s" @click.stop="onSub">
        {{ '-' }}
    </div>

    <div class="risks-v" @click.stop>
        {{ rValue }}
    </div>

    <div class="risks-s" @click.stop="onAdd">
        {{ '+' }}
    </div>

</div>
</template>
<script>

export default {
    name: 'RiskScoreControl',
    props: {
        params: { type: Object, default: null },
        source: { type: String, default: '' },
        rId: { type: String, default: '' },
        riskML: { type: Number, default: 0 },
        rId1: { type: String, default: '' },
        rId2: { type: String, default: '' },
        rId3: { type: String, default: '' },
    },
    data() {
        return {
            rValue: -1,
            disabled: true
        }
    },
    methods: {
        
        onAdd() {
            if (!this.params[this.source]) this.$set(this.params, this.source, {});
            if (!this.params[this.source][this.rId]) this.$set(this.params[this.source], this.rId, 5);
            this.params[this.source][this.rId] += 1;
            this.updateValue();
        },

        onSub() {
            if (!this.params[this.source]) this.$set(this.params, this.source, {});
            if (!this.params[this.source][this.rId]) this.$set(this.params[this.source], this.rId, 5);
            this.params[this.source][this.rId] -= 1;
            this.updateValue();
        },

        updateValue() {
            /* console.log('params', this.params);
            console.log('get', this.source);
            console.log('get', this.rId); */

            if (this.params && this.params[this.source]) {

                if (this.params[this.source][this.rId]) {
                    this.disabled = false;
                    this.rValue = this.params[this.source][this.rId];
                }else if (this.rId1 && this.params[this.source][this.rId1]) {
                    this.disabled = true;
                    this.rValue = this.params[this.source][this.rId1];
                }else if (this.rId2 && this.params[this.source][this.rId2]) {
                    this.disabled = true;
                    this.rValue = this.params[this.source][this.rId2];
                }else if (this.rId3 && this.params[this.source][this.rId3]) {
                    this.disabled = true;
                    this.rValue = this.params[this.source][this.rId3];
                }

            }else {
                this.disabled = true;
                this.rValue = -1;
            }

            
        },

    },
    created() {

        this.updateValue();

    }
}
</script>
<style scoped>

.risks {
    display: flex;
    width: 80px;
    height: 20px;
    color: gray;
    border-radius: 3px;
    border: solid 1px var(--input-border);
    background-color: var(--page-background);
}

.risks-v {
    width: 32px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    border-left: solid 1px var(--input-border);
    border-right: solid 1px var(--input-border);
}

.risks-s {
    width: 24px;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}
.risks-s:hover {
    color: var(--accent-contrast);
    background-color: var(--accent);
}

.risks-disabled {
    color: var(--text-disabled);
}


</style>