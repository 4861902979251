<template>
<div>
    
    <svg viewBox="0 0 50 50" width="40px">
  
  <defs>
    <clipPath id="shape">
      <path fill="none" d="M 40 4 L 10 4 C 8.347656 4 7 5.347656 7 7 L 7 45 C 7 45.554688 7.449219 46 8 46 L 42 46 C 42.554688 46 43 45.554688 43 45 L 43 7 C 43 5.347656 41.652344 4 40 4 Z M 18 41 L 12 41 L 12 36 L 18 36 Z M 18 32 L 12 32 L 12 27 L 18 27 Z M 18 23 L 12 23 L 12 18 L 18 18 Z M 18 14 L 12 14 L 12 9 L 18 9 Z M 28 44 L 22 44 L 22 36 L 28 36 Z M 28 32 L 22 32 L 22 27 L 28 27 Z M 28 23 L 22 23 L 22 18 L 28 18 Z M 28 14 L 22 14 L 22 9 L 28 9 Z M 38 41 L 32 41 L 32 36 L 38 36 Z M 38 32 L 32 32 L 32 27 L 38 27 Z M 38 23 L 32 23 L 32 18 L 38 18 Z M 38 14 L 32 14 L 32 9 L 38 9 Z"/>
    </clipPath>
    
  </defs>
<image width="70" height="52" clip-path="url(#shape)"  :href="$root.images.flags.nb_NO" >
</image>

</svg>

</div>
</template>

<script>

export default {
    name: 'vap-overlay-icon',
    components: {
        
    },
    props: {

    },
    data() {
        return {
            
        }
    },
    methods: {

  

    },
    async created() {
        
    }
}
</script>

<style scoped>


</style>
