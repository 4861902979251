import log from '../../../../log';
import api from '../../../../portalapi';
import formHelper from '../../formHelper';
import personHelper from '../../../../helpers/personhelper';
import kycComparison from './kycComparison';
import { htmlFinancialStatement } from "./kycHtmlViews";
import v1personHelper from '../../../../v1helpers/v1personHelper';
const kycModuleHelper = {

    getEmptyConfig() {
        return {
            type: 'kyc',
            icon: 'eye',
            updateKyc: true,
            comparison: false
        };
    },

    getMergedAddress(existingData, person) {
        const result = {
            street: person.street || '',
            zip: person.zip || '',
            city: person.city || '',
            country: person.country || ''
        };

        for (const i in existingData.persons) {
            if (personHelper.isSamePerson(person, existingData.persons[i])) {

                for (const j in existingData.persons[i].addresses) {

                    result.street = existingData.persons[i].addresses[j].street;
                    result.zip = existingData.persons[i].addresses[j].zip;
                    result.city = existingData.persons[i].addresses[j].city;
                    result.country = existingData.persons[i].addresses[j].country;
                    
                }

            }
        }

        return result;
    },

    onFormCreated(root, reference, designer, module, pages, isOwner, userdata, isUsingExistingData) {
        if (designer) return;

        const existingData = JSON.parse(root.formData);
        log.x('KYC Module: On form created, existing data:', existingData);
        
        if (module.comparison) {
            if (isOwner && existingData && existingData.client) {
                const original = {
                    persons: []
                };
                for (const i in existingData.client.company.roles) {
                    if (kycComparison.isValidPerson(existingData.client.company.roles[i])) {
                        original.persons.push({
                            firstName: existingData.client.company.roles[i].firstName.toLowerCase(),
                            lastName: existingData.client.company.roles[i].lastName.toLowerCase(),
                            dateOfBirth: existingData.client.company.roles[i].dateOfBirth,
                            roleId: existingData.client.company.roles[i].roleId,
                        });
                    }
                }
                for (const i in existingData.client.company.beneficials) {
                    if (kycComparison.isValidPerson(existingData.client.company.beneficials[i])) {
                        original.persons.push({
                            firstName: existingData.client.company.beneficials[i].firstName.toLowerCase(),
                            lastName: existingData.client.company.beneficials[i].lastName.toLowerCase(),
                            dateOfBirth: existingData.client.company.beneficials[i].dateOfBirth,
                            roleId: 'role_rrh',
                            share: existingData.client.company.beneficials[i].share
                        });
                    }
                }
                for (const i in existingData.client.company.shareholders) {
                    if (kycComparison.isValidPerson(existingData.client.company.shareholders[i])) {
                        original.persons.push({
                            firstName: existingData.client.company.shareholders[i].firstName.toLowerCase(),
                            lastName: existingData.client.company.shareholders[i].lastName.toLowerCase(),
                            dateOfBirth: existingData.client.company.shareholders[i].dateOfBirth,
                            roleId: 'role_shareholder',
                            share: existingData.client.company.shareholders[i].share
                        });
                    }else {
                        original.persons.push({
                            firstName: existingData.client.company.shareholders[i].name.toLowerCase(),
                            lastName: 'org',
                            dateOfBirth: 'org',
                            roleId: 'role_shareholder',
                            share: existingData.client.company.shareholders[i].share
                        });
                    }
                }
                reference.originalCompany = original;
                log.x('KYC Module: Original comparison data processed', original);
            }else {
                reference.originalCompany = userdata ? userdata.originalCompany : {};
            }
        }

        //console.log('PAGES', pages);

        // Prepare sign rights model
        //console.log('userdata' , userdata);
        if(isOwner) {
            // signature
            if (existingData && existingData.companyData && (existingData.companyData.signature || existingData.companyData.procura)) {
                const signature = existingData.companyData.signature;
                const procura = existingData.companyData.procura;
                
                reference.signRightsModel = {
                    available: signature.available || procura.available,
                    options: [],
                    description: []
                };

                if(signature && signature.available) {
                    reference.signRightsModel.options.push(...JSON.parse(JSON.stringify(signature.options)))
                    reference.signRightsModel.description.push(...JSON.parse(JSON.stringify(signature.description)))
                }

                if(procura && procura.available) {
                    reference.signRightsModel.options.push(...JSON.parse(JSON.stringify(procura.options)))
                    reference.signRightsModel.description.push(...JSON.parse(JSON.stringify(procura.description)))
                }

                if (!reference.signRightsModel.options || reference.signRightsModel.options.length == 0) reference.signRightsModel.available = false;
            }
        }else{
            reference.signRightsModel = userdata.signRightsModel;
        }
        
        if (!existingData || !existingData.companyData) return;
        
        if (!isUsingExistingData) {
            this.autoFillKycStuff(root, module, pages, existingData);
        }
        
    },

    autoFillKycStuff(root, module, pages, existingData) {
        for (const i in pages) {
            for (const j in pages[i].cards) {
                if (pages[i].cards[j].type == 'list') {

                    if (pages[i].cards[j].kycKey == 'beneficials') {
                        for (const x in existingData.companyData.beneficials) {
                            const beneficial = existingData.companyData.beneficials[x];

                            let share = beneficial.share;
                            if (!beneficial.share) share = beneficial.shareRange ? beneficial.shareRange * 100 : '';

                            const customModel = {
                                share: share,
                                firstName: beneficial.firstName,
                                lastName: beneficial.lastName,
                                dateOfBirth: beneficial.dateOfBirth,
                                street: beneficial.street,
                                zip: beneficial.zip,
                                city: beneficial.city,
                                country: beneficial.country,
                            };

                            console.log('BEN', beneficial);

                            if (beneficial.reason == 'VOTING_RIGHTS_AND_OWNERSHIP' || beneficial.reason == 'VOTING_RIGHTS') {
                                customModel.directIndirect = { direct: true, indirect: false };
                                customModel.shareDirect = beneficial.share;
                                customModel.ownType = { own: true };
                            }else if (beneficial.reason == 'CONTROL_INDIRECT') {
                                customModel.directIndirect = { direct: false, indirect: true };
                                customModel.shareIndirect = beneficial.integratedShare;
                                customModel.ownType = { own: true };
                            }

                            console.log('customModel', customModel);
                            formHelper.addListModel(root, pages[i].cards[j], customModel);

                        }

                        // Add board as UBOs if foundation etc. and enabled
                        let addBoard = (existingData.companyData && existingData.companyData.form && (existingData.companyData.form.formCode == 'FLI' || existingData.companyData.form.formCode == 'STI'));
                        if (!module.boardUbo) addBoard = false;
                        if (addBoard) {
                            for (const x in existingData.companyData.roles) {
                                const role = existingData.companyData.roles[x];
                                console.log('role', role);
                                if (role.roleId == 'role_ceo' || role.roleId == 'role_chair' || role.roleId == 'role_board_member') {

                                    const customModel = {
                                        firstName: role.firstName,
                                        lastName: role.lastName,
                                        dateOfBirth: role.dateOfBirth,
                                    };

                                    console.log('customModel', customModel);
                                    formHelper.addListModel(root, pages[i].cards[j], customModel);

                                }
                            }
                        }

                    }else if (pages[i].cards[j].kycKey == 'shareholders') {
                        for (const x in existingData.companyData.shareholders) {

                            const customModel = {
                                type: existingData.companyData.shareholders[x].type == 'person' ? 'person' : 'org',
                                share: existingData.companyData.shareholders[x].share,
                                name: existingData.companyData.shareholders[x].name,
                                orgnr: existingData.companyData.shareholders[x].orgnr,
                                firstName: existingData.companyData.shareholders[x].firstName,
                                lastName: existingData.companyData.shareholders[x].lastName,
                                dateOfBirth: existingData.companyData.shareholders[x].dateOfBirth,
                            };
                            formHelper.addListModel(root, pages[i].cards[j], customModel);

                        }
                    }else if (pages[i].cards[j].kycKey == 'shareholderPersons') {
                        for (const x in existingData.companyData.beneficials) {
                            const beneficial = existingData.companyData.beneficials[x];

                            let share = beneficial.share;
                            if (!beneficial.share) share = beneficial.shareRange ? beneficial.shareRange * 100 : '';

                            const customModel = {
                                type: 'person',
                                share: share,
                                firstName: beneficial.firstName,
                                lastName: beneficial.lastName,
                                dateOfBirth: beneficial.dateOfBirth,
                                isBeneficial: true,
                                street: beneficial.street,
                                zip: beneficial.zip,
                                city: beneficial.city,
                                country: beneficial.country,
                            };

                            console.log('BEN', beneficial);

                            if (beneficial.reason == 'VOTING_RIGHTS_AND_OWNERSHIP' || beneficial.reason == 'VOTING_RIGHTS') {
                                customModel.directIndirect = { direct: true, indirect: false };
                                customModel.shareDirect = beneficial.share;
                                customModel.ownType = { own: true };
                            }else if (beneficial.reason == 'CONTROL_INDIRECT') {
                                customModel.directIndirect = { direct: false, indirect: true };
                                customModel.shareIndirect = beneficial.integratedShare;
                                customModel.ownType = { own: true };
                            }

                            console.log('customModel', customModel);
                            formHelper.addListModel(root, pages[i].cards[j], customModel);

                        }
                        for (const x in existingData.companyData.shareholders) {

                            if (existingData.companyData.shareholders[x].type != 'person') continue;
                            if (existingData.companyData.shareholders[x].share < 10) continue;

                            // Dont add shareholders that were already added as beneficials
                            let match = false;
                            for (const y in existingData.companyData.beneficials) {
                                if (this.isSamePersonDOB(existingData.companyData.beneficials[y], existingData.companyData.shareholders[x])) match = true;
                            }
                            if (!match) {
                                const customModel = {
                                    type: existingData.companyData.shareholders[x].type == 'person' ? 'person' : 'org',
                                    share: existingData.companyData.shareholders[x].share,
                                    name: existingData.companyData.shareholders[x].name,
                                    orgnr: existingData.companyData.shareholders[x].orgnr,
                                    firstName: existingData.companyData.shareholders[x].firstName,
                                    lastName: existingData.companyData.shareholders[x].lastName,
                                    dateOfBirth: existingData.companyData.shareholders[x].dateOfBirth,
                                    isBeneficial: false,
                                };

                                customModel.directIndirect = { direct: true, indirect: false };
                                customModel.shareDirect = existingData.companyData.shareholders[x].share;
                                customModel.ownType = { own: true };

                                formHelper.addListModel(root, pages[i].cards[j], customModel);
                            }

                        }
                    }else if (pages[i].cards[j].kycKey == 'shareholderCompanies') {
                        for (const x in existingData.companyData.shareholders) {

                            if (existingData.companyData.shareholders[x].type == 'person') continue;

                            const customModel = {
                                type: existingData.companyData.shareholders[x].type == 'person' ? 'person' : 'org',
                                share: existingData.companyData.shareholders[x].share,
                                name: existingData.companyData.shareholders[x].name,
                                orgnr: existingData.companyData.shareholders[x].orgnr,
                                firstName: existingData.companyData.shareholders[x].firstName,
                                lastName: existingData.companyData.shareholders[x].lastName,
                                dateOfBirth: existingData.companyData.shareholders[x].dateOfBirth,
                            };
                            formHelper.addListModel(root, pages[i].cards[j], customModel);

                        }
                    }else if (pages[i].cards[j].kycKey == 'roles') {
                        for (const x in existingData.companyData.roles) {

                            if (existingData.companyData.roles[x].roleId == 'role_deputy_chair') {

                                const customModel = {
                                    roleId: existingData.companyData.roles[x].roleId,
                                    firstName: existingData.companyData.roles[x].firstName,
                                    lastName: existingData.companyData.roles[x].lastName,
                                    dateOfBirth: existingData.companyData.roles[x].dateOfBirth,
                                    street: existingData.companyData.roles[x].street || '',
                                    zip: existingData.companyData.roles[x].zip || '',
                                    city: existingData.companyData.roles[x].city || '',
                                    country: existingData.companyData.roles[x].country || '',
                                };
                                formHelper.addListModel(root, pages[i].cards[j], customModel);

                            }

                        }
                    
                    }else if (pages[i].cards[j].kycKey == 'boardMembers') {
                        for (const x in existingData.companyData.roles) {
                            if (existingData.companyData.roles[x].roleId == 'role_board_member') {

                                const customModel = {
                                    firstName: existingData.companyData.roles[x].firstName,
                                    lastName: existingData.companyData.roles[x].lastName,
                                    dateOfBirth: existingData.companyData.roles[x].dateOfBirth,
                                    street: existingData.companyData.roles[x].street || '',
                                    zip: existingData.companyData.roles[x].zip || '',
                                    city: existingData.companyData.roles[x].city || '',
                                    country: existingData.companyData.roles[x].country || '',
                                };
                                formHelper.addListModel(root, pages[i].cards[j], customModel);

                            }
                        }
                    }else if (pages[i].cards[j].kycKey == 'deputyBoardMembers') {
                        for (const x in existingData.companyData.roles) {
                            if (existingData.companyData.roles[x].roleId == 'role_deputy_bm') {

                                const customModel = {
                                    firstName: existingData.companyData.roles[x].firstName,
                                    lastName: existingData.companyData.roles[x].lastName,
                                    dateOfBirth: existingData.companyData.roles[x].dateOfBirth,
                                    street: existingData.companyData.roles[x].street || '',
                                    zip: existingData.companyData.roles[x].zip || '',
                                    city: existingData.companyData.roles[x].city || '',
                                    country: existingData.companyData.roles[x].country || '',
                                };
                                formHelper.addListModel(root, pages[i].cards[j], customModel);

                            }
                        }
                    }else if (pages[i].cards[j].kycKey == 'peps') {
                        for (const x in existingData.persons) {
                            if (existingData.persons[x].screening.done && existingData.persons[x].screening.isPep) {

                                const person = existingData.persons[x];
                                const customModel = {
                                    firstName: person.info.firstName,
                                    lastName: person.info.lastName,
                                    dateOfBirth: person.info.dateOfBirth,
                                    title: person.screening.pepSummary.title
                                };
                                formHelper.addListModel(root, pages[i].cards[j], customModel);

                            }
                        }
                    }else if (pages[i].cards[j].kycKey == 'naceCodes') {
                        //console.log('ncae', existingData.companyData)
                        for (const x in existingData.companyData.naceCodes) {

                            const customModel = {
                                code: existingData.companyData.naceCodes[x].code,
                                description: existingData.companyData.naceCodes[x].description
                            };
                            //console.log('model', customModel);
                            formHelper.addListModel(root, pages[i].cards[j], customModel);

                        }
                    }
                }else if (pages[i].cards[j].type == 'card' && pages[i].cards[j].kycKey) {

                    if (pages[i].cards[j].kycKey == 'individual') {
                        //console.log("Individual:", existingData)
                        let person = existingData.customer.person;

                        let individual = null;
                        for (const i in existingData.persons) {
                            for (const j in existingData.persons[i].roles) {
                                if (existingData.persons[i].roles[j].id == 'role_individual') {
                                    individual = existingData.persons[i];
                                }
                            }
                        }
                        //console.log('individual', individual);

                        if (person) {
                            const mResult = this.getMergedAddress(existingData, person);
                            for (const k in pages[i].cards[j].inputs) {
                                const input = pages[i].cards[j].inputs[k];
                                if (input.modelKey == 'firstName') input.model = person.firstName;
                                if (input.modelKey == 'lastName') input.model = person.lastName;
                                if (input.modelKey == 'dateOfBirth' && person.dateOfBirth != '1800-01-01') input.model = person.dateOfBirth;
                                if (input.modelKey == 'phone') input.model = person.phone;
                                if (input.modelKey == 'email') input.model = person.email;
                                if (input.modelKey == 'ssn') input.model = person.ssn;
                                if (input.modelKey == 'street') input.model = mResult.street;
                                if (input.modelKey == 'zip') input.model = mResult.zip;
                                if (input.modelKey == 'city') input.model = mResult.city;
                                if (input.modelKey == 'country') input.model = mResult.country;
                            }
                        }
                        if (individual) {

                            for (const k in pages[i].cards[j].inputs) {
                                const input = pages[i].cards[j].inputs[k];
                                if (input.modelKey == 'citizenship') input.model = individual.details.citizenship;
                                if (input.modelKey == 'birthCity') input.model = individual.details.birthCity;
                                if (input.modelKey == 'birthCountry') input.model = individual.details.birthCountry;
                            }

                            const bestAddress = v1personHelper.getBestResidencySource(individual);
                            if (bestAddress) {
                                for (const k in pages[i].cards[j].inputs) {
                                    const input = pages[i].cards[j].inputs[k];
                                    if (input.modelKey == 'street') input.model = bestAddress.street;
                                    if (input.modelKey == 'zip') input.model = bestAddress.zip;
                                    if (input.modelKey == 'city') input.model = bestAddress.city;
                                    if (input.modelKey == 'country') input.model = bestAddress.country;
                                }
                            }

                            if (individual.email.available) {
                                for (const k in pages[i].cards[j].inputs) {
                                    const input = pages[i].cards[j].inputs[k];
                                    if (input.modelKey == 'email') input.model = individual.email.value;
                                }
                            }

                        }

                    }else if (pages[i].cards[j].kycKey == 'ceo') {
                        let person = null;
                        for (const x in existingData.companyData.roles) {
                            if (existingData.companyData.roles[x].roleId == 'role_ceo') person = existingData.companyData.roles[x];
                        }
                        if (person) {
                            const mResult = this.getMergedAddress(existingData, person);
                            for (const k in pages[i].cards[j].inputs) {
                                const input = pages[i].cards[j].inputs[k];
                                if (input.modelKey == 'firstName') input.model = person.firstName;
                                if (input.modelKey == 'lastName') input.model = person.lastName;
                                if (input.modelKey == 'dateOfBirth') input.model = person.dateOfBirth;
                                if (input.modelKey == 'ssn') input.model = person.ssn;
                                if (input.modelKey == 'street') input.model = mResult.street;
                                if (input.modelKey == 'zip') input.model = mResult.zip;
                                if (input.modelKey == 'city') input.model = mResult.city;
                                if (input.modelKey == 'country' || input.type == 'country') input.model = mResult.country;
                            }
                        }
                    }else if (pages[i].cards[j].kycKey == 'chair') {
                        let person = null;
                        for (const x in existingData.companyData.roles) {
                            if (existingData.companyData.roles[x].roleId == 'role_chair') person = existingData.companyData.roles[x];
                        }
                        if (person) {
                            const mResult = this.getMergedAddress(existingData, person);
                            for (const k in pages[i].cards[j].inputs) {
                                const input = pages[i].cards[j].inputs[k];
                                if (input.modelKey == 'firstName') input.model = person.firstName;
                                if (input.modelKey == 'lastName') input.model = person.lastName;
                                if (input.modelKey == 'dateOfBirth') input.model = person.dateOfBirth;
                                if (input.modelKey == 'ssn') input.model = person.ssn;
                                if (input.modelKey == 'street') input.model = mResult.street;
                                if (input.modelKey == 'zip') input.model = mResult.zip;
                                if (input.modelKey == 'city') input.model = mResult.city;
                                if (input.modelKey == 'country' || input.type == 'country') input.model = mResult.country;
                            }
                        }
                    }

                }else if (pages[i].cards[j].type == 'chk-a') {
                    if (pages[i].cards[j].kycKey == 'beneficials') {

                        for (const x in existingData.companyData.beneficials) {
                            pages[i].cards[j].model.push({
                                firstName: existingData.companyData.beneficials[x].firstName,
                                lastName: existingData.companyData.beneficials[x].lastName,
                                dateOfBirth: existingData.companyData.beneficials[x].dateOfBirth,
                            });
                        }

                    }else if (pages[i].cards[j].kycKey == 'roles') {
                        for (const x in existingData.companyData.roles) {

                            const customModel = {
                                
                            };
                            formHelper.addListModel(root, pages[i].cards[j], customModel);

                            console.log('card', pages[i].cards[j]);

                            pages[i].cards[j].itemModel.push({
                                name: existingData.companyData.roles[x].firstName + ' ' + existingData.companyData.roles[x].lastName,
                                id: ''
                            });

                        }
                    }else if (pages[i].cards[j].kycKey == 'controlEntities') {
                        for (const x in existingData.companyData.controlEntities) {

                            const customModel = {
                                
                            };
                            formHelper.addListModel(root, pages[i].cards[j], customModel);

                            console.log('card', pages[i].cards[j]);

                            pages[i].cards[j].itemModel.push({
                                name: existingData.companyData.controlEntities[x].name,
                                id: ''
                            });

                        }
                    }
                }else {
                    for (const k in pages[i].cards[j].inputs) {
                        if (pages[i].cards[j].inputs[k].kycKey) {
                            if (!pages[i].cards[j].inputs[k].model) pages[i].cards[j].inputs[k].model = this.getConnectedValue(root, pages[i].cards[j].inputs[k].kycKey, existingData.companyData, existingData.customer);
                        }
                        if (pages[i].cards[j].inputs[k].type == 'check') {
                            for (const x in pages[i].cards[j].inputs[k].options) {
                                if (pages[i].cards[j].inputs[k].options[x].kycKey) {
                                    pages[i].cards[j].inputs[k].model[pages[i].cards[j].inputs[k].options[x].key] = this.getConnectedValue(root, pages[i].cards[j].inputs[k].options[x].kycKey, existingData.companyData, existingData.customer);
                                }
                            }
                        }
                    }
                }
            }
        }
    },

    onSetUserdata(root, reference, module, envelope, userdata) {

        const existingData = JSON.parse(root.formData);
        log.x('KYC Module: On set userdata, existing data:', existingData);
        if (!existingData || !existingData.customer) return;

        userdata.customer = {
            id: existingData.customer._id
        };

        userdata.signRightsModel = reference.signRightsModel;

        if (module.comparison) {
            userdata.originalCompany = reference.originalCompany;
        }
        
    },

    async onFormSubmitted(root, reference, module, nextRoleKey, envelope) {

        const existingData = JSON.parse(root.formData);
        log.x('KYC Module: On form submitted, existing data:', existingData);

        //console.log('neztroleKey', nextRoleKey);
        //console.log('module', module);
        //console.log('envelope', envelope);

        if (reference.roleKey == 'owner') {

            // This is now handled in the backend

        }else {

            // This is now handled in the backend

        }
        
    },

    getConnectedValue(root, kycKey, companyData, client) {
        try {
            switch (kycKey) {
                case 'clientIsPerson': return this.getClientValue(root, client, kycKey);
                case 'clientIsCompany': return this.getClientValue(root, client, kycKey);
                case 'clientName': return this.getClientValue(root, client, kycKey);
                case 'clientNumber': return this.getClientValue(root, client, kycKey);
                case 'clientDepartment': return this.getClientValue(root, client, kycKey);
                case 'clientContactName': return this.getClientValue(root, client, kycKey);
                case 'clientSSN': return this.getClientValue(root, client, kycKey);
    
                case 'orgName': return companyData.name;
                case 'orgNumber': return companyData.orgnr;
                case 'businessAddress.street': return companyData.address.business.street;
                case 'businessAddress.zip': return companyData.address.business.zip;
                case 'businessAddress.city': return companyData.address.business.city;
                case 'businessAddress.country': return companyData.address.business.country;
                case 'dateEstablished': return companyData.info.dateEstablished;
                case 'dateRegistered': return companyData.info.dateRegistered;
                case 'formCode': return companyData.form.formCode == 'ltd' ? 'Limited Company' : companyData.form.formCode;
                case 'regCountry': return companyData.info.country;
                case 'employees': return companyData.info.employeesTo;
                case 'hasBeneficials': return companyData.beneficials.length > 0;
                case 'hasShareholders': return companyData.shareholders.length > 0;
    
                case 'equityOver20mill': return (companyData.economy.year0.available && companyData.economy.year0.equity != -1) ? companyData.economy.year0.equity > 20000000 : false;
                case 'assetsOver200mill': return (companyData.economy.year0.available && companyData.economy.year0.sumAssets != -1) ? companyData.economy.year0.sumAssets > 200000000 : false;
                case 'incomeOver400mill': return (companyData.economy.year0.available && companyData.economy.year0.totalRevenues != -1) ? companyData.economy.year0.totalRevenues > 400000000 : false;
    
                case 'equityUnder20mill': return (companyData.economy.year0.available && companyData.economy.year0.equity != -1) ? companyData.economy.year0.equity <= 20000000 : false;
                case 'assetsUnder200mill': return (companyData.economy.year0.available && companyData.economy.year0.sumAssets != -1) ? companyData.economy.year0.sumAssets <= 200000000 : false;
                case 'incomeUnder400mill': return (companyData.economy.year0.available && companyData.economy.year0.totalRevenues != -1) ? companyData.economy.year0.totalRevenues <= 400000000 : false;

                case 'listed': return companyData.form.formCode == 'ASA';
                case 'unlisted': return companyData.form.formCode != 'ASA';
                case 'confirmBeneficials': return companyData.beneficials.length > 0;
                case 'confirmShareholders': return true;

                case 'htmlFinancialStatement': return htmlFinancialStatement(root, kycKey, companyData, client);
            }
        } catch(err) {
            console.log("Error loading connected data", err);
        }
        
        // return empty string if an error was caught or no match found
        return '';
    },

    getConnectedItemName(kycKey) {
        switch (kycKey) {
            case 'orgName': return 'Company Name';
            case 'orgNumber': return 'Company Number';
            case 'beneficials': return 'Beneficial Owners';
            case 'roles': return 'Company Roles';
            case 'shareholders': return 'Shareholders';
            case 'controlEntities': return 'Control Entities';
            case 'individual': return 'Physical Person';
        }
        return kycKey;
    },

    getClientValue(root, client, key) {
        if (!client) return '';

        if (key == 'clientName') {

            return client.name;

        }else if (key == 'clientNumber') {

            console.log('PERSON', client);

            if (client.type == 'person') {
                return client.person.ssn || '';
            }else {
                return client.identity;
            }

        }else if (key == 'clientDepartment') {

            return root.getDepartmentName(client.department);

        }else if (key == 'clientContactName') {

            if (client.type == 'person') {
                return client.name;
            }else {
                return '';
            }

        }else if (key == 'clientIsPerson') {

            return client.type == 'person';

        }else if (key == 'clientIsCompany') {

            return client.type != 'person';

        }else if (key == 'clientSSN') {

            if (client.type == 'person') {
                return client.person.ssn || '';
            }else {
                return '';
            }

        }
        return '';
    },

    onPersonInput(card, input, index, pages, reference) {
        try {

            const person = this.getEmptyPerson();

            if (card.type == 'list') {

                for (const i in card.inputs) {
                    person[card.inputs[i].listModel] = card.model[index][card.inputs[i].listModel];
                    if (card.inputs[i].options) {
                        for (const j in card.inputs[i].options) {
                            if (card.inputs[i].options[j].kycKey) {
                                if (card.inputs[i].options[j].kycKey == 'isPep') person.isPep = card.model[index][card.inputs[i].listModel][j];
                                if (card.inputs[i].options[j].kycKey == 'isBeneficial') person.isBeneficial = card.model[index][card.inputs[i].listModel][j];
                            }
                        }
                    }
                }
    
            }else {
                for (const i in card.inputs) {
                    if (card.inputs[i].modelKey) {
                        person[card.inputs[i].modelKey] = card.inputs[i].model;
                    }
                }
            }
    
            //console.log('persson', person);
    
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    if (pages[i].cards[j].isPerson) {
                        if (pages[i].cards[j].type == 'list') {
    
                            let match = false;
    
                            for (const k in pages[i].cards[j].model) {
    
                                const targetPerson = this.getEmptyPerson();
                                for (const key in pages[i].cards[j].model[k]) {
                                    targetPerson[key] = pages[i].cards[j].model[k][key];
                                }
                                if (this.isSamePerson(person, targetPerson)) {
                                    if (!(pages[i].cards[j].key == card.key && index == k)) {
                                        this.updateTargetList(person, pages[i].cards[j], k);
                                        match = true;
                                    }
                                }
    
                            }
    
                            if (!match && person.isBeneficial && pages[i].cards[j].kycKey == 'beneficials') {
                                console.log('copy to beneficials', person);
                            }
    
                        }else {
                            const targetPerson = this.getEmptyPerson();
                            for (const k in pages[i].cards[j].inputs) {
                                if (pages[i].cards[j].inputs[k].modelKey) {
                                    targetPerson[pages[i].cards[j].inputs[k].modelKey] = pages[i].cards[j].inputs[k].model;
                                }
                            }
                            if (this.isSamePerson(person, targetPerson)) {
                                if (!(pages[i].cards[j].key == card.key)) {
                                    this.updateTargetCard(person, pages[i].cards[j].key);
                                }
                            }
                        }
                    }
                }
            }
    
    
            if (reference.originalCompany) {
                reference.comparison = kycComparison.compareStuff(reference, reference.originalCompany, pages);
            }

        }catch (ex) {
            console.log(ex);
        }
    },

    onAnyInput(card, input, index, pages, reference) {
        if (reference.originalCompany) {
            reference.comparison = kycComparison.compareStuff(reference, reference.originalCompany, pages);
        }
    },

    getPersonList(pages) {
        const list = [];
        for (const i in pages) {
            for (const j in pages[i].cards) {
                if (pages[i].cards[j].isPerson) {
                    if (pages[i].cards[j].type == 'list') {
                        for (const k in pages[i].cards[j].model) {
                            const targetPerson = this.getEmptyPerson();
                            for (const key in pages[i].cards[j].model[k]) {
                                targetPerson[key] = pages[i].cards[j].model[k][key];
                            }
                            if (this.isValidPerson(targetPerson)) {
                                list.push(targetPerson);
                            }
                        }
                    }else {
                        const targetPerson = this.getEmptyPerson();
                        for (const k in pages[i].cards[j].inputs) {
                            let key = pages[i].cards[j].inputs[k].key;
                            if (pages[i].cards[j].inputs[k].modelKey) key = pages[i].cards[j].inputs[k].modelKey;
                            targetPerson[key] = pages[i].cards[j].inputs[k].model;
                        }
                        if (this.isValidPerson(targetPerson)) list.push(targetPerson);
                    }
                }
            }
        }

        const refinedList = [];

        for (const i in list) {
            let match = false;
            for (const j in refinedList) {
                if (this.isSamePersonDOB(list[i], refinedList[j])) {
                    match = true;

                    if (!refinedList[j].email && list[i].email) refinedList[j].email = list[i].email;
                    if (!refinedList[j].phone && list[i].phone) refinedList[j].phone = list[i].phone;
                    if (!refinedList[j].phoneNumber && list[i].phoneNumber) refinedList[j].phoneNumber = list[i].phoneNumber;
                    if (!refinedList[j].citizenship && list[i].citizenship) refinedList[j].citizenship = list[i].citizenship;
                    if (!refinedList[j].street && list[i].street) refinedList[j].street = list[i].street;
                    if (!refinedList[j].zip && list[i].zip) refinedList[j].zip = list[i].zip;
                    if (!refinedList[j].city && list[i].city) refinedList[j].city = list[i].city;
                    if (!refinedList[j].country && list[i].country) refinedList[j].country = list[i].country;

                }
            }
            if (!match) {
                refinedList.push(list[i]);
            }
        }

        return refinedList;
    },

    isSamePerson(person1, person2) {
        if (!person1.firstName || !person1.lastName) return false;
        if (!person2.firstName || !person2.lastName) return false;
        if (person1.firstName.toLowerCase() == person2.firstName.toLowerCase() && person1.lastName.toLowerCase() == person2.lastName.toLowerCase()) return true;
        return false;
    },

    isSamePersonDOB(person1, person2) {
        if (!person1.firstName || !person1.lastName || !person1.dateOfBirth) return false;
        if (!person2.firstName || !person2.lastName || !person2.dateOfBirth) return false;
        if (person1.firstName.toLowerCase() == person2.firstName.toLowerCase() && person1.lastName.toLowerCase() == person2.lastName.toLowerCase() && person1.dateOfBirth == person2.dateOfBirth) return true;
        return false;
    },

    isValidPerson(person) {
        if (person.firstName && person.lastName) return true;
        return false;
    },

    getEmptyPerson() {
        return {
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            street: '',
            zip: '',
            city: '',
            email: '',
            phone: { prefix: '+47', number: '' },
            phoneNumber: ''
        };
    },

    updateTargetCard(sourcePerson, targetCard) {
        // TODO
    },

    updateTargetList(sourcePerson, targetCard, targetIndex) {
        for (const key in targetCard.model[targetIndex]) {
            if (key == 'street' && sourcePerson.street) {
                targetCard.model[targetIndex][key] = sourcePerson.street;
            }else if (key == 'zip' && sourcePerson.zip) {
                targetCard.model[targetIndex][key] = sourcePerson.zip;
            }else if (key == 'city' && sourcePerson.city) {
                targetCard.model[targetIndex][key] = sourcePerson.city;
            }else if (key == 'email' && sourcePerson.email) {
                targetCard.model[targetIndex][key] = sourcePerson.email;
            }else if (key == 'phone' && sourcePerson.phone && sourcePerson.phone.number) {
                targetCard.model[targetIndex][key] = sourcePerson.phone;
            }else if (key == 'phoneNumber' && sourcePerson.phoneNumber) {
                targetCard.model[targetIndex][key] = sourcePerson.phoneNumber;
            }else if (key == 'citizenship' && sourcePerson.citizenship) {
                targetCard.model[targetIndex][key] = sourcePerson.citizenship;
            }
        }
    },

};
export default kycModuleHelper;