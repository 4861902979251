import defaultFlows from "../v1flows/defaultFlows";
import log from '../log';
const v1flows = {

    //#region Flows -------------------------------------------------------------------------------------------------------

    getAvailableFlows(root, type, country, source) {
        const flows = [];

        // First check if there are any custom flows
        for (const i in root.flowConfig.flows) {
            
            const flow = root.flowConfig.flows[i];

            // Check if flow is valid for this client type and country
            if ((flow.countries.includes(country.toLowerCase()) || flow.countries.includes('xx')) && flow.type == type) {
                if (!(flow.key == 'xx-org' && (country.toLowerCase() == 'no' || country.toLowerCase() == 'se'))) {
                    flows.push(flow);
                }
            }

        }

        // Load all enabled default flows
        const df = defaultFlows.getFlows();
        for (const i in df) {

            const flow = defaultFlows.getFlow(df[i]);

            // Check if flow is enabled
            let enabled = false;
            root.config.defaultFlows.forEach((item) => {
                if (item.key == flow.key && item.enabled) enabled = true;
            });
            if (!enabled) continue;

            // Check if flow is valid for this client type and country
            if ((flow.countries.includes(country.toLowerCase()) || flow.countries.includes('xx')) && flow.type == type) {
                if (!(flow.key == 'xx-org' && (country.toLowerCase() == 'no' || country.toLowerCase() == 'se'))) {
                    flows.push(flow);
                }
            }
        }

        flows.sort((b, a) => (a.sortIndex > b.sortIndex) ? 1 : -1);

        return flows;
    },

    getAvailableFlows2(root, type, country, source, condition) {
        const flows = [];

        // First check if there are any custom flows
        for (const i in root.flowConfig.flows) {
            
            const flow = root.flowConfig.flows[i];

            // Check if flow is valid for this client type and country
            if (flow.countries.includes(country.toLowerCase()) && flow.type == type) {
                if (condition && condition != 'none') {
                    if (flow.conditions && flow.conditions.includes(condition)) flows.push(flow);
                }else {
                    flows.push(flow);
                }
            }

        }

        // Load all enabled default flows
        const df = defaultFlows.getFlows();
        for (const i in df) {

            const flow = defaultFlows.getFlow(df[i]);

            // Check if flow is enabled
            let enabled = false;
            root.config.defaultFlows.forEach((item) => {
                if (item.key == flow.key && item.enabled) enabled = true;
            });
            if (!enabled) continue;

            // Check if flow is valid for this client type and country
            if (flow.countries.includes(country.toLowerCase()) && flow.type == type) {
                if (condition && condition != 'none') {
                    if (flow.conditions && flow.conditions.includes(condition)) flows.push(flow);
                }else {
                    flows.push(flow);
                }
            }
        }

        log.x('Flows: Get available flows: type=' + type + ', country=' + country + ', source=' + source + ', condition=' + condition, flows);
        return flows;
    },

    getFlow(root, key) {
        for (const i in root.flowConfig.flows) {
            if (root.flowConfig.flows[i].key == key) return this.mergeWithDefault(root, root.flowConfig.flows[i]);
        }
        const flows = defaultFlows.getFlows();
        for (const i in flows) {
            if (flows[i] == key) return defaultFlows.getFlow(key);
        }
    },

    getStep(root, key) {
        for (const j in root.flowConfig.steps) {
            if (root.flowConfig.steps[j].key == key) {
                return root.flowConfig.steps[j];
            }
        }
        return defaultFlows.getStep(key);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------

    setTabModel(reference, flow, output, defaultKey) {
        if (!flow) return;
        for (const key in flow.tabs) {
            reference.$set(output, key, key == defaultKey);
        }
    },

    mergeWithDefault(root, customFlowRef) {
        const customFlow = JSON.parse(JSON.stringify(customFlowRef));
        if (customFlow.defaultEvents) {
            const defaultFlow = this.getFlow(root, customFlow.defaultEvents);
            for (const i in defaultFlow.events.items) {
                let match = false;
                for (const j in customFlow.events.items) {
                    if (defaultFlow.events.items[i].key == customFlow.events.items[j].key) {
                        this.mergeEvent(customFlow.events.items, defaultFlow.events.items[i], customFlow.events.items[j]);
                        match = true;
                    }
                }
                if (!match) customFlow.events.items.push(defaultFlow.events.items[i]);
            }
        }
        if (customFlow.defaultReport) {
            const mergedList = [];
            const defaultFlow = this.getFlow(root, customFlow.defaultReport);
            for (const i in defaultFlow.report) {
                let match = false;
                for (const j in customFlow.report) {
                    if (defaultFlow.report[i].key == customFlow.report[j].key) {
                        this.mergeReport(mergedList, defaultFlow.report[i], customFlow.report[j]);
                        match = true;
                    }
                }
                if (!match) mergedList.push(defaultFlow.report[i]);
            }
            for (const j in customFlow.report) {
                let match = false;
                for (const i in mergedList) {
                    if (mergedList[i].key == customFlow.report[j].key) match = true;
                }
                if (!match) {
                    const inheritIndex = customFlow.report[j].inheritIndex || 'last';
                    const index = this.getInheritIndex(mergedList, inheritIndex);
                    if (customFlow.report[j].inheritMode != 'remove') mergedList.splice(index, 0, customFlow.report[j]);
                }

            }
            customFlow.report = mergedList;
        }
        if (customFlow.defaultStatuses) {
            const mergedList = [];
            const defaultFlow = this.getFlow(root, customFlow.defaultStatuses);
            for (const i in defaultFlow.statuses) {
                let match = false;
                for (const j in customFlow.statuses) {
                    if (defaultFlow.statuses[i].key == customFlow.statuses[j].key) {
                        this.mergeStatus(mergedList, defaultFlow.statuses[i], customFlow.statuses[j]);
                        match = true;
                    }
                }
                if (!match) mergedList.push(defaultFlow.statuses[i]);
            }
            for (const j in customFlow.statuses) {
                let match = false;
                for (const i in mergedList) {
                    if (mergedList[i].key == customFlow.statuses[j].key) match = true;
                }
                if (!match) mergedList.push(customFlow.statuses[j]);
            }
            customFlow.statuses = mergedList;
        }
        return customFlow;
    },

    mergeEvent(list, defaultEvent, customEvent) {

    },

    mergeReport(mergedList, defaultReport, customReport) {
        const mode = customReport.inheritMode || 'replace';
        //console.log('inheritMode', mode);
        if (mode == 'replace') {
            mergedList.push(defaultReport);
        }else if (mode == 'merge') {
            const items = defaultReport.items;
            for (const i in customReport.items) {
                items.push(customReport.items[i]);
            }
            defaultReport.items = items;
            mergedList.push(defaultReport);
        }else if (mode == 'remove') {
            return false;
        }
        return true;
    },

    mergeStatus(mergedList, defaultStatuses, customStatuses) {
        const mode = customStatuses.inheritMode || 'replace';
        if (mode == 'replace') {
            mergedList.push(defaultStatuses);
        }
    },

    getInheritIndex(mergedList, inheritIndex) {
        if (inheritIndex == 'last') return mergedList.length;
        for (const i in mergedList) {
            if (mergedList[i].key == inheritIndex) return Number(i);
        }
        return mergedList.length;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default v1flows;