var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.hideList),expression:"hideList"}],staticClass:"frv2-menu-button",class:[
            { 'frv2-menu-button-dot': _vm.dotted },
            { 'frv2-menu-button-working': _vm.working },
            { 'frv2-menu-button-warning': _vm.warning },
            { 'frv2-menu-button-disabled': _vm.disabled },
            { 'frv2-menu-button-hlist-l': _vm.hasListLeft },
            { 'frv2-menu-button-hlist-r': _vm.hasListRight } ],on:{"click":_vm.handleClick,"mouseup":_vm.handleOptionMouseUp}},[(!_vm.working)?_c('vap-icon',{attrs:{"icon":_vm.icon,"absolute":"","top":0,"right":_vm.hasListRight ? 4 : 1,"color":'ext'}}):_vm._e(),_c('div',{staticClass:"frv2-menu-button-tt"},[_vm._t("tooltip")],2),(_vm.working)?_c('div',{staticClass:"frv2-menu-button-spinner"},[_c('vap-spinner',{staticStyle:{"margin-top":"27px","margin-right":"38px"},attrs:{"small":""}})],1):_vm._e()],1),(_vm.hasListLeft)?_c('div',{staticClass:"frv2-menu-button-list-l",class:[
            { 'frv2-menu-button-disabled-l': _vm.disabled } ],on:{"click":function($event){$event.stopPropagation();return _vm.handleListClick($event)}}},[_c('vap-icon',{attrs:{"tiny":"","icon":'chev-d',"color":'ext'}})],1):_vm._e(),(_vm.hasListRight)?_c('div',{staticClass:"frv2-menu-button-list-r",class:[
            { 'frv2-menu-button-disabled-r': _vm.disabled } ],on:{"click":function($event){$event.stopPropagation();return _vm.handleListClick($event)}}},[_c('vap-icon',{attrs:{"tiny":"","icon":'chev-d',"color":'ext'}})],1):_vm._e(),(_vm.listExpanded)?_c('div',{staticClass:"frv2-menu-button-list"},[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }