<template>
<div v-if="step" style="position: relative;">
        
    <div class="srv2-step" :class="[
        { 'srv2-step-completed srv2-step-progress' : status == 3 },
        { 'srv2-step-completed' : status == 5 },
        { 'srv2-step-completed srv2-step-warning' : status == 8 },
        { 'srv2-step-completed srv2-step-error' : status == 9 },
        { 'srv2-step-selected' : selected },
    ]" @click.stop="onStepClicked">

        <div class="srv2-line-bot" v-if="step && step.formList && form && status != 0" />

        <div class="srv2-icon">
            <div class="srv2-icon-icon" v-if="step.icon">
                <vap-icon :icon="step.icon" />
            </div>
            <div class="srv2-icon-number" v-if="!step.icon">
                {{ (index + 1) }}
            </div>
        </div>

        <div class="srv2-content">

            <div class="srv2-header">
                <span v-if="step && step.formList && form">{{ getFormName() }}</span>
                <EditableText v-if="!(step && step.formList && form)" @save="updateHeader" :editable="$root.credentials.isAdmin" :text="step.title[$root.locale.key]" />
            </div>

            <div class="srv2-hint" v-if="status == 0">
                <EditableText @save="updateHint" :editable="$root.credentials.isAdmin" :text="step.hint[$root.locale.key]" />
            </div>

            <div class="srv2-status" v-for="(sub, i) in subs" :key="'fss' + i" :class="[
                {'srv2-status-progress': sub.status == 3 },
                {'srv2-status-completed': sub.status == 5 },
                {'srv2-status-warning': sub.status == 8 },
                {'srv2-status-error': sub.status == 9 }
            ]">
                <div class="srv2-ball" />
                <div class="srv2-status-text">
                    {{ sub.text[$root.locale.key] }}
                </div>
            </div>

        </div>

        <div class="srv2-button" v-if="mainAction" @click="onMainAction" :class="{'srv2-button-disabled': mainAction.disabled }">
            
            <div class="srv2-button-c">
                {{ mainAction.text[$root.locale.key] }}
            </div>
            <div class="srv2-button-t" />
            <div class="srv2-button-tt" v-if="mainAction.hint && mainAction.hint[$root.locale.key]">
                <vap-tooltip :text="mainAction.hint[$root.locale.key]" />
            </div>
        </div>

       <CardActions :actions="actions" @action="onAction" />

   </div>

   <SignCheckoutResult v-if="step && step.hasCheckout && form" 
        :form="form"
        @signature="$emit('signature', { form: $event, formKey: step.form, step: step })"
    />

    <StepFormList v-if="step && step.formList && form" 
        :form="form"
        :step="step"
        :disabled="client.report.created"
        @newform="onAction"
        @xaction="onActionX"
    />

</div>
</template>
<script>
import api from '../portalapi';
import tools from '../tools';

import triggerHelper from './triggerHelper';

import CardActions from '../v1ui/CardActions.vue';

import SignCheckoutResult from '../components/form/modules/signing/SignCheckoutResult.vue'

import StepFormList from './StepFormList.vue';

import EditableText from '../components/basic/EditableText.vue';

export default {
    name: 'FlowStep',
    components: {
        CardActions,
        SignCheckoutResult,
        StepFormList,
        EditableText,
    },
    props: {
        client: { type: Object, default: null },
        step: { type: Object, default: null },
        index: { type: Number, default: 0 },
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
    },
    data() {
        return {
            actions: [],
            mainAction: null,
            status: 0,
            text: {},
            subs: [],
            form: null,
        }
    },
    methods: {

        async saveStep() {

            // Update flowConfig before overwriting it in case changes has been made
            this.$root.flowConfig = (await api.loadConfig()).flows || { flows: [], steps: [] };
            
            console.log("Before step save:", this.$root.flowConfig);

            if(!this.$root.flowConfig.steps) {
                this.$root.flowConfig.steps = [];
            }
            this.$root.flowConfig.steps = this.$root.flowConfig.steps.filter(x => x.key != this.step.key);
            this.$root.flowConfig.steps.push(this.step);

            console.log("After step save:", this.$root.flowConfig);

            await api.setFlows('na', this.$root.flowConfig);

        },

        updateHeader(val) {
            console.log("update header", val);
            this.step.title[this.$root.locale.key] = val;
            this.saveStep();
        },

        updateHint(val) {
            console.log("update hint", val);
            this.step.hint[this.$root.locale.key] = val;
            this.saveStep();
        },

        onStepClicked() {
            this.$emit('clicked');
        },

        onAction(action) {
            this.$emit('action', action, this.index);
        },

        onActionX(action, archiveIndex) {
            this.$emit('xaction', action, archiveIndex, this.index);
        },

        onMainAction() {
            this.hideTooltipX();
            if (this.mainAction.disabled) return;
            this.$emit('action', this.mainAction, this.index);
        },

        getFormName() {
            let name = this.form.name;
            name = name.replace('.pdf', '');
            /* name += ' (' + tools.dateString(this.form.dateCreated) + ')'; */
            return name;
        },

    },
    async created() {

        //console.log('STEP', this.step);
        //console.log('FORM', this.form);

        for (const i in this.step.actions) {
            if (this.step.actions[i].configCondition) {
                if (this.step.actions[i].configCondition == 'localhost') {
                    if (!this.$root.debug.local) continue;
                }else {
                    if (!this.$root.config.flow[this.step.actions[i].configCondition]) continue;
                }
            }
            if (triggerHelper.isActionRelevant(this.step.actions[i], this.$parent.triggers)) {

                const action = JSON.parse(JSON.stringify(this.step.actions[i]));
                delete action.triggers;
                delete action.disabled;

                if (triggerHelper.isActionDisabled(this.step.actions[i], this.$parent.triggers)) {
                    action.disabled = true;
                    action.hint = JSON.parse(JSON.stringify(this.step.actions[i].disabled.hint));
                }

                if (this.step.actions[i].main) {
                    this.mainAction = action;
                }else {
                    this.actions.push(action);
                }
            }
        }

        const result = triggerHelper.getStepStatus(this.step, this.$parent.triggers, this.$parent.texts);
        this.status = result.status;
        this.text = result.text;
        this.subs = result.subs;

        // TODO remove this
        if (this.step.hasCheckout && this.client[this.step.form] && this.client[this.step.form].hasCheckout) {
            this.form = this.client[this.step.form];
        }

        if (this.step.formList && this.client[this.step.form]) {
            this.form = this.client[this.step.form];
        }


        //console.log('SUBS', this.subs);

    }
}
</script>
<style scoped>

.srv2-header-edit {
    display: none;
}

.srv2-header-text:hover > .srv2-header-edit {
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
}

.srv2-step {
    float: left;
    position: relative;
    width: 100%;
    min-height: 90px;
    display: flex;
    margin-bottom: 20px;
    border-radius: 5px;
    border: dotted 2px gray;
    box-sizing: border-box;
}

.srv2-step-progress {

}
.srv2-step-completed {
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
}

.srv2-line-bot {
    position: absolute;
    bottom: 0px; left: 41px;
    width: 7px; height: 34px;
    background-color: red;
}
.srv2-step-completed .srv2-line-bot {
    background-color: var(--primary);
}
.srv2-step-progress .srv2-line-bot {
    background-color: var(--primary-weak);
}
.srv2-step-warning .srv2-line-bot {
    background-color: var(--warning-background);
}
.srv2-step-error .srv2-line-bot {
    background-color: var(--error-background);
}

.srv2-icon {
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    margin-top: 20px;
    margin-left: 20px;
    color: gray;
    border-radius: 5px;
    border: dotted 2px gray;
    z-index: 1;
}

.srv2-icon-icon {
    padding-top: 4px;
    padding-left: 4px;
}
.srv2-icon-number {
    padding-top: 5px;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
}

.srv2-step-completed .srv2-icon {
    color: var(--primary-contrast);
    border: solid 2px var(--primary);
    background-color: var(--primary);
}
.srv2-step-progress .srv2-icon {
    color: var(--primary);
    border: solid 2px var(--primary-weak);
    background-color: var(--primary-weak);
}
.srv2-step-warning .srv2-icon {
    color: var(--warning-text);
    border: solid 2px var(--warning-background);
    background-color: var(--warning-background);
}
.srv2-step-error .srv2-icon {
    color: var(--error-text);
    border: solid 2px var(--error-background);
    background-color: var(--error-background);
}




.srv2-content {
    flex-grow: 100;
    margin-left: 20px;
    padding-right: 60px;
    padding-top: 7px;
    padding-bottom: 15px;
    color: gray;
}
.srv2-step-completed .srv2-content {
    color: var(--text-strong);
}

.srv2-header {
    margin-top: 10px;
    font-size: 20px;
}
.srv2-hint {
    margin-top: 3px;
    padding-right: 100px;
}

.srv2-status {
    display: flex;
    margin-top: 6px;
}

.srv2-ball {
    flex-shrink: 0;
    width: 16px; height: 16px;
    margin-top: 1px;
    border-radius: 8px;
    border: solid 2px transparent;
    box-sizing: border-box;
}
.srv2-status-progress .srv2-ball {
    border: solid 2px var(--success-background);
}
.srv2-status-completed .srv2-ball {
    border: solid 2px var(--success-background);
    background-color: var(--success-background);
}
.srv2-status-warning .srv2-ball {
    border: solid 2px var(--warning-background);
    background-color: var(--warning-background);
}
.srv2-status-error .srv2-ball {
    border: solid 2px var(--error-background);
    background-color: var(--error-background);
}

.srv2-status-text {
    flex-grow: 100;
    margin-left: 7px;
}




/* Main Button */

.srv2-button {
    position: absolute;
    min-width: 120px;
    max-width: 300px;
    height: 30px;
    bottom: 12px;
    right: 12px;
    background-color: var(--button);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border: solid 2px var(--button);
    box-sizing: border-box;
    cursor: pointer;
}
.srv2-button-c {
    position: relative;
    margin-left: -22px;
    padding-top: 4px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 24;
    color: var(--button-text);
    text-align: center;
}
.srv2-button-t {
    position: absolute;
    top: -2px;
    left: -30px;
    width: 50px;
    height: 30px;
    border: solid 2px var(--button);
    border-right: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 5px;
    -webkit-transform: skew(-45deg);
    transform: skew(-45deg);
    background-color: var(--button);
    box-sizing: border-box;
}
.srv2-button:hover {
    opacity: 0.8;
}

.srv2-button-disabled {
    color: var(--disabled-text);
    border-color: var(--disabled-background);
    background-color: var(--disabled-background);
    cursor: not-allowed;
}
.srv2-button-disabled:hover {
    opacity: 1;
}
.srv2-button-disabled .srv2-button-t {
    border-color: var(--disabled-background);
    background-color: var(--disabled-background);
}

.srv2-button-tt {
    position: absolute;
    top: 0px; bottom: 0px;
    left: -30px; right: 0px;
    z-index: 25;
}


/* Designer */

.srv2-step-selected {
    border: solid 2px var(--accent);
}



</style>