import log from '../log';
import tools from '../tools';

const CHANGE_REMOVED = 1;
const CHANGE_ADDED = 2;
const CHANGE_PERCENTAGE = 3;

const personhelper = {

    debugging: false,

    // Get a list of persons with merged information from multiple sources like AML search, PEP search, KYC form, ID-control and manually added persons
    getMergedList(root, sources, customer, kycMetadata) {
        const list = []; let index = -1;
        if (this.debugging) log.x('PH: creating list', sources);

        for (const i in sources.aml) {
            index = this.getListIndex(list, sources.aml[i]);
            if (index == -1) index = this.createPerson(root, list, sources.aml[i]);
            this.mergeFromAMLSearch(list[index], sources.aml[i]);
        }
        //console.log('sources.kyc', sources.kyc);
        for (const i in sources.kyc) {
            //console.log(sources.kyc[i]);
            try {
                if (this.isValidPerson(sources.kyc[i])) {
                    index = this.getListIndex(list, sources.kyc[i]);
                    if (index == -1) index = this.createPerson(root, list, sources.kyc[i]);
                    this.mergeFromKYC(list[index], sources.kyc[i]);
                }
            }catch (ex) {

            }
        }
        //console.log('pep', sources.pep);
        for (const i in sources.pep) {
            index = this.getListIndex(list, sources.pep[i].person);
            if (index == -1) index = this.createPerson(root, list, sources.pep[i].person);
            this.mergeFromPEPSearch(list[index], sources.pep[i]);
        }
        for (const i in sources.search) {
            index = this.getListIndex(list, sources.search[i].person);
            if (index == -1) index = this.createPerson(root, list, sources.search[i].person);
            this.mergeFromPEPSearchNew(list[index], sources.search[i]);
        }

        for (const i in sources.id) {
            index = this.getListIndex(list, sources.id[i].person);
            if (index == -1) index = this.createPerson(root, list, sources.id[i].person);
            this.mergeFromIDControl(list[index], sources.id[i]);
        }
        for (const i in sources.idc) {
            index = this.getListIndex(list, sources.idc[i].person);
            if (index == -1) index = this.createPerson(root, list, sources.idc[i].person);
            this.mergeFromIDControlForm(list[index], sources.idc[i]);
        }
        for (const i in sources.formId) {
            index = this.getListIndex(list, sources.formId[i].person);
            if (index == -1) index = this.createPerson(root, list, sources.formId[i].person);
            this.mergeFromIDSignature(list[index], sources.formId[i]);
        }

        for (const i in sources.manualRoles) {
            index = this.getListIndex(list, sources.manualRoles[i].person);
            if (index == -1) index = this.createPerson(root, list, sources.manualRoles[i].person);
            this.mergeFromManual(list[index], sources.manualRoles[i]);
        }

        for (const i in sources.person) {
            index = this.getListIndex(list, sources.person[i].person);
            if (index == -1) index = this.createPerson(root, list, sources.person[i].person);
            this.mergeFromPerson(list[index], sources.person[i]);
        }

        if (customer.people) {
            for (const i in customer.people) {
                const person = tools.decodePerson(customer.people[i].id);
                index = this.getListIndex(list, person);
                if (index == -1) index = this.createPerson(root, list, person);
                this.mergeFromSelected(list[index], customer.people[i], person);
            }
        }
        if (customer.roles) {
            for (const i in customer.roles) {
                const person = tools.decodePerson(customer.roles[i]);
                index = this.getListIndex(list, person);
                if (index == -1) index = this.createPerson(root, list, person);
                this.mergeFromSelectedNew(list[index], customer.roles[i], person);
            }
        }

        if (kycMetadata && kycMetadata.changes) {
            //console.log('changes', kycMetadata.changes);
            for (const i in kycMetadata.changes.items) {
                const item = kycMetadata.changes.items[i];
                if (item.type == 'rrh') {
                    if (item.change == CHANGE_REMOVED) {
                        this.removeRRH(list, item.data.identity);
                    }else if (item.change == CHANGE_PERCENTAGE) {
                        this.changeRRHPercentage(list, item.data);
                    }
                }else if (item.type == 'sh') {
                    if (item.change == CHANGE_REMOVED) {
                        //console.log('remove shareholder', item);
                        this.removeShareholder(list, item.data.identity);
                    }
                }else if (item.type == 'ceo') {
                    if (item.change == CHANGE_REMOVED) {
                        this.removeCEO(list, item.data.identity);
                    }
                }else if (item.type == 'chair') {
                    if (item.change == CHANGE_REMOVED) {
                        this.removeChair(list, item.data.identity);
                    }
                }
            }
        }

        for (const i in list) {
            let fromAml = false;
            let fromKyc = false;
            for (const j in list[i].roles) {
                if (list[i].roles[j].source == 'aml') fromAml = true;
                if (list[i].roles[j].source == 'kyc') fromKyc = true;
            }
            if (fromKyc && !fromAml) list[i].flags.kycAdded = true;
        }

        return list;
    },

    removeRRH(list, identity) {
        const person = tools.decodePerson(identity);
        for (const i in list) {
            if (this.isSamePerson(person, list[i])) {
                //console.log(list[i].displayName + ' was removed');
                list[i].flags.kycRrhRemoved = true;
                for (const j in list[i].roles) {
                    if (list[i].roles[j].id == 'screening_role_rrh' && list[i].roles[j].source == 'aml') {
                        list[i].roles[j].removed = true;
                    }
                }
            }
        }
    },

    removeShareholder(list, identity) {
        try {
            //console.log('rsh', list);
            //console.log('rsh', identity);
            const person = tools.decodePerson(identity);
            for (const i in list) {
                if (this.isSamePerson(person, list[i])) {
                    //console.log(list[i].displayName + ' was removed');
                    list[i].flags.kycShRemoved = true;
                    for (const j in list[i].roles) {
                        if (list[i].roles[j].id == 'screening_role_shareholder' && list[i].roles[j].source == 'aml') {
                            list[i].roles[j].removed = true;
                        }
                    }
                }
            }
        }catch (ex) {
            log.e('PH: failed to remove shareholder', identity);
        }
    },

    changeRRHPercentage(list, data) {
        const person = tools.decodePerson(data.identity);
        //console.log('data', data);
        for (const i in list) {
            if (this.isSamePerson(person, list[i])) {
                //console.log(list[i].displayName + ' was removed');
                list[i].flags.kycRrhChanged = true;
                list[i].flags.kycRrhChangedFrom = Number(data.percentageFrom);
                list[i].flags.kycRrhChangedTo = Number(data.percentageTo);
            }
        }
    },

    removeCEO(list, identity) {
        const person = tools.decodePerson(identity);
        for (const i in list) {
            if (this.isSamePerson(person, list[i])) {
                //console.log(list[i].displayName + ' was removed');
                list[i].flags.kycCeoRemoved = true;
                for (const j in list[i].roles) {
                    if (list[i].roles[j].id == 'ceo' && list[i].roles[j].source == 'aml') {
                        list[i].roles[j].removed = true;
                    }
                }
            }
        }
    },

    removeChair(list, identity) {
        const person = tools.decodePerson(identity);
        for (const i in list) {
            if (this.isSamePerson(person, list[i])) {
                //console.log(list[i].displayName + ' was removed');
                list[i].flags.kycChairRemoved = true;
                for (const j in list[i].roles) {
                    if (list[i].roles[j].id == 'chair' && list[i].roles[j].source == 'aml') {
                        list[i].roles[j].removed = true;
                    }
                }
            }
        }
    },

    stripSensitiveData(list) {
        for (const i in list) {
            list[i].info.ssn = 'REDACTED';
            if (list[i].control.confirmedInfo && list[i].control.confirmedInfo.ssn) list[i].control.confirmedInfo.ssn = 'REDACTED';
        }
    },

    mergeFromAMLSearch(target, source) {
        if (this.debugging) log.x('PH: merging person ' + source.firstName + ' ' + source.lastName + ' from AML', source);

        this.mergeRole(target, source.roleId, 'aml', source.percentage);

        
    },

    mergeFromKYC(target, source) {
        if (this.debugging) log.x('PH: merging person ' + source.firstName + ' ' + source.lastName + ' from KYC', source);

        if (source.roleId == 'kycpep') {
            target.flags.kycPep = true;
            target.flags.kycPepTitle = source.title;
        }else {
            this.mergeRole(target, source.roleId, 'kyc', source.percentage);
        }
        
        let match = false;
        for (const i in target.addresses) {
            if (target.addresses[i].source == 'kyc') match = true;
        }
        if (!match && source.address) {
            target.addresses.push({
                source: 'kyc',
                type: 'postal',
                street: source.address.street,
                zip: source.address.zip,
                city: source.address.city,
                country: source.address.country,
            });
            if (source.address.countryName) target.countryName = source.address.countryName;
        }

        if (source.phone && source.phone.number) {
            target.contact.phone = source.phone;
        }
        if (source.email) target.contact.email = source.email;
        
    },

    mergeFromPEPSearch(target, source) {
        //if (this.debugging) log.x('PH: merging person ' + source.firstName + ' ' + source.lastName + ' from PEP', source);

        target.uimodel.doSearch = true;
        target.uimodel.didSearch = true;

/*         target.result = {
            searchDate: new Date(source.date),
            isPep: source.aml.personData.aml.pepResults.length > 0,
            hasSanction: source.aml.personData.aml.sanctionResults.length > 0,
            envelopeId: source.envelopeId
        }; */
        target.search.completed = true;
        target.search.dateCompleted = new Date(source.date);
        target.search.isPep = source.aml.personData.aml && source.aml.personData.aml.pepResults.length > 0;
        target.search.hasSanction = source.aml.personData.aml && source.aml.personData.aml.sanctionResults.length > 0;
        target.search.envelopeId = source.envelopeId;

        target.search.list = [];
        target.search.listString = '';

        if (source.aml && source.aml.personData && source.aml.personData.aml && source.aml.personData.aml.list) {
            for (const i in source.aml.personData.aml.list) {
                target.search.list.push(source.aml.personData.aml.list[i]);
                if (i > 0) target.search.listString += ', ';
                target.search.listString += source.aml.personData.aml.list[i];
            }
        }

        if (target.search.isPep) {
            target.search.pepTitle = source.aml.personData.aml.pepResults[0].title;
            target.search.pepName = source.aml.personData.aml.pepResults[0].name;
            target.search.pepMatchIndicator = source.aml.personData.aml.pepResults[0].matchIndicator;
            target.search.pepMatchDescription = source.aml.personData.aml.pepResults[0].matchIndicatorDescription;
        }

        
    },

    mergeFromPEPSearchNew(target, source) {
        //log.x('PH: merging person ' + source.person.firstName + ' ' + source.person.lastName + ' from PEP', source);

        //console.log('source', source);

        const result = source.envelope.userdata.personData;

        target.uimodel.doSearch = true;
        target.uimodel.didSearch = true;

        target.search.completed = true;
        target.search.dateCompleted = new Date(source.envelope.userdata.metadata.date);
        target.search.isPep = result.isPep;
        target.search.hasSanction = result.hasSanction;
        target.search.envelopeId = source.envelope.id;

        target.search.list = [];
        target.search.listString = '';

        if (source.envelope.userdata.personData) {
            for (const i in source.envelope.userdata.personData.sources) {
                target.search.list.push(source.envelope.userdata.personData.sources[i]);
                if (i > 0) target.search.listString += ', ';
                target.search.listString += source.envelope.userdata.personData.sources[i];
            }
        }

        if (result.isPep) {
            target.search.pepTitle = result.pepSummary.title;
            target.search.pepName = result.pepSummary.name;
            target.search.pepMatchIndicator = result.pepSummary.matchIndicator;
            target.search.pepMatchDescription = result.pepSummary.matchDescription;
        }
        
    },

    mergeFromIDControl(target, source) {
        log.x('PH: merging person ' + source.person.firstName + ' ' + source.person.lastName + ' from IDC', source);

        target.uimodel.doControl = true;
        target.uimodel.didControl = true;

        target.control.inviteSent = source.person.envelopeIdControl != undefined && source.person.envelopeIdControl != '';
        target.control.envelopeId = source.person.envelopeIdControl;
        target.control.recipientId = source.recipientId;
        target.control.recipientEmail = source.recipientEmail;
        target.control.completed = source.completed;
        if (source.completed) {
            target.control.dateCompleted = new Date(source.person.control.lookupDate);
            target.control.method = source.person.control.method;
            target.control.confirmedInfo = {
                firstName: this.titleCase(source.person.control.firstName),
                lastName: this.titleCase(source.person.control.lastName),
                dateOfBirth: source.person.control.dateOfBirth,
                ssn: source.person.control.ssn
            };
            target.control.confirmedName = this.titleCase(source.person.control.firstName + ' ' + source.person.control.lastName);
            if (source.person.control.ssn) target.info.ssn = source.person.control.ssn;
            if (source.person.control.method == 'manual') {
                target.control.manualInfo = {
                    comment: source.person.control.comment,
                    userId: source.person.control.userId,
                };
            }
        }

        if (source.dbPerson && source.dbPerson.idc && source.dbPerson.idc.sentDate) {
            target.control.sentDate = source.dbPerson.idc.sentDate;
        }else if (source.dbPerson && source.dbPerson.invite) {
            target.control.sentDate = source.dbPerson.invite.sentDate;
        }else {
            target.control.sentDate = null;
        }
        target.control.notifications = [];
        if (source.recipient) {
            for (const i in source.recipient.notifications) {
                let id = source.recipient.notifications[i].id;
                if (source.recipient.notifications[i].id.length == 11 || source.recipient.notifications[i].id.length == 12) {
                    id = source.recipient.notifications[i].id.substring(0, source.recipient.notifications[i].id.length - 2);
                }
                //console.log('noti', source.recipient.notifications[i]);
                let match = false;
                for (const j in target.control.notifications) {
                    if (target.control.notifications[j].id == id) {
                        if (source.recipient.notifications[i].status == 'sent') {
                            target.control.notifications[j].sent = true;
                            target.control.notifications[j].sentDate = new Date(source.recipient.notifications[i].details.submittedAt);
                        }else if (source.recipient.notifications[i].status == 'delivered') {
                            target.control.notifications[j].delivered = true;
                            target.control.notifications[j].deliveredDate = new Date(source.recipient.notifications[i].details.DeliveredAt);
                        }else if (source.recipient.notifications[i].status == 'opened') {
                            target.control.notifications[j].opened = true;
                            target.control.notifications[j].openedDate = new Date(source.recipient.notifications[i].details.ReceivedAt);
                        }
                        match = true;
                    }
                }
                if (!match) {
                    if (source.recipient.notifications[i].status == 'sent') {
                        target.control.notifications.push({
                            id: id,
                            type: target.control.notifications.length > 0 ? 'reminder' : 'initial',
                            sent: true,
                            sentDate: new Date(source.recipient.notifications[i].details.submittedAt)
                        });
                    }else if (source.recipient.notifications[i].status == 'delivered') {
                        target.control.notifications.push({
                            id: id,
                            type: target.control.notifications.length > 0 ? 'reminder' : 'initial',
                            delivered: true,
                            deliveredDate: new Date(source.recipient.notifications[i].details.DeliveredAt)
                        });
                    }else if (source.recipient.notifications[i].status == 'opened') {
                        target.control.notifications.push({
                            id: id,
                            type: target.control.notifications.length > 0 ? 'reminder' : 'initial',
                            opened: true,
                            openedDate: new Date(source.recipient.notifications[i].details.ReceivedAt)
                        });
                    }
                }
            }
            //console.log('notifications', target.control.notifications);
        }
        
        if (source.person.version == 2) {
            if (source.person.contact.email) target.contact.email = source.person.contact.email;
        }else {
            if (source.person.email) target.contact.email = source.person.email;
        }

    },

    mergeFromIDControlForm(target, source) {
        //log.x('PH: merging person ' + source.person.firstName + ' ' + source.person.lastName + ' from IDC', source);

        target.uimodel.doControl = true;
        target.uimodel.didControl = true;

        target.control.inviteSent = source.recipient != null;
        target.control.envelopeId = source.dbPerson.idc.envelopeId;
        target.control.recipientId = source.recipient.id;
        target.control.recipientEmail = source.recipient.email;
        target.control.completed = source.recipient.completed;
        if (source.recipient.completed) {
            target.control.dateCompleted = new Date(source.dbPerson.idc.completedDate || source.dbPerson.idc.sentDate);
            target.control.method = source.idcResult.method;
            target.control.confirmedInfo = {
                firstName: this.titleCase(source.idcResult.firstName),
                lastName: this.titleCase(source.idcResult.lastName),
                dateOfBirth: source.idcResult.dateOfBirth,
                ssn: source.idcResult.ssn
            };
            target.control.confirmedName = this.titleCase(source.idcResult.firstName + ' ' + source.idcResult.lastName);
            if (source.idcResult.ssn) target.info.ssn = source.idcResult.ssn;
        }

        if (source.dbPerson && source.dbPerson.idc && source.dbPerson.idc.sentDate) {
            target.control.sentDate = source.dbPerson.idc.sentDate;
        }else if (source.dbPerson && source.dbPerson.invite) {
            target.control.sentDate = source.dbPerson.invite.sentDate;
        }else {
            target.control.sentDate = null;
        }
        target.control.notifications = [];
        target.control.events = [];
        if (source.recipient) {
            for (const i in source.recipient.notifications) {
                target.control.events.push({
                    type: source.recipient.notifications[i].status
                });

                let id = source.recipient.notifications[i].id;
                if (source.recipient.notifications[i].id.length == 11 || source.recipient.notifications[i].id.length == 12) {
                    id = source.recipient.notifications[i].id.substring(0, source.recipient.notifications[i].id.length - 2);
                }
                //console.log('noti', source.recipient.notifications[i]);
                let match = false;
                for (const j in target.control.notifications) {
                    if (target.control.notifications[j].id == id) {
                        if (source.recipient.notifications[i].status == 'sent') {
                            target.control.notifications[j].sent = true;
                            target.control.notifications[j].sentDate = new Date(source.recipient.notifications[i].details.submittedAt);
                        }else if (source.recipient.notifications[i].status == 'delivered') {
                            target.control.notifications[j].delivered = true;
                            target.control.notifications[j].deliveredDate = new Date(source.recipient.notifications[i].details.DeliveredAt);
                        }else if (source.recipient.notifications[i].status == 'opened') {
                            target.control.notifications[j].opened = true;
                            target.control.notifications[j].openedDate = new Date(source.recipient.notifications[i].details.ReceivedAt);
                        }
                        match = true;
                    }
                }
                if (!match) {
                    if (source.recipient.notifications[i].status == 'sent') {
                        target.control.notifications.push({
                            id: id,
                            type: target.control.notifications.length > 0 ? 'reminder' : 'initial',
                            sent: true,
                            sentDate: new Date(source.recipient.notifications[i].details.submittedAt)
                        });
                    }else if (source.recipient.notifications[i].status == 'delivered') {
                        target.control.notifications.push({
                            id: id,
                            type: target.control.notifications.length > 0 ? 'reminder' : 'initial',
                            delivered: true,
                            deliveredDate: new Date(source.recipient.notifications[i].details.DeliveredAt)
                        });
                    }else if (source.recipient.notifications[i].status == 'opened') {
                        target.control.notifications.push({
                            id: id,
                            type: target.control.notifications.length > 0 ? 'reminder' : 'initial',
                            opened: true,
                            openedDate: new Date(source.recipient.notifications[i].details.ReceivedAt)
                        });
                    }
                }
            }
            //console.log('notifications', target.control.notifications);
        }
        
        if (source.person.version == 2) {
            if (source.person.contact.email) target.contact.email = source.person.contact.email;
        }else {
            if (source.person.email) target.contact.email = source.person.email;
        }

    },

    mergeFromIDSignature(target, source) {
        log.x('PH: merging person ' + source.person.firstName + ' ' + source.person.lastName + ' from ID Signature', source);

        target.uimodel.doControl = true;
        target.uimodel.didControl = true;

        target.control.envelopeId = source.signature.idData.envelopeId;

        target.control.completed = true;

        target.control.dateCompleted = new Date(source.signature.idData.date);
        target.control.method = 'sign-bid-no';
        target.control.confirmedInfo = {
            firstName: this.titleCase(source.signature.idData.firstName),
            lastName: this.titleCase(source.signature.idData.lastName),
            dateOfBirth: source.signature.idData.dateOfBirth,
            ssn: source.signature.idData.ssn
        };
        target.control.confirmedName = this.titleCase(source.signature.idData.firstName + ' ' + source.signature.idData.lastName);
        if (source.signature.idData.ssn) target.info.ssn = source.signature.idData.ssn;

    },

    mergeFromManual(target, source) {
        //log.x('PH: merging person ' + source.person.firstName + ' ' + source.person.lastName + ' from manual roles', source);

        target.flags.manual = true;
        target.flags.manualUserId = source.manualRole.userId;
        console.log('source', source);
        if (source.manualRole.roleId == 'role_customer_contact') {
            target.flags.manual = false;
            target.flags.contact = true;
            target.flags.contactId = source.manualRole.userId;
        }
        const role = {
            id: source.manualRole.roleId,
            source: 'man',
            percentage: source.manualRole.share || 0
        };
        if (source.manualRole.roleId == 'common_other') role.roleOther = source.manualRole.roleOther;
        target.roles.push(role);
        
    },

    mergeFromSelected(target, source, person) {
        //log.x('PH: merging person ' + person.firstName + ' ' + person.lastName + ' from selected', source);

        target.selected = true;
        target.id = source.id;
        target.uimodel.expanded = true;

        if (source.manual) {
            target.flags.manual = true;
            target.flags.manualUserId = source.manual.userId;
            const role = {
                id: source.manual.roleId,
                source: 'man',
                percentage: 0
            };
            if (source.manual.roleId == 'common_other') role.roleOther = source.manual.roleOther;
            target.roles.push(role);
        }
        
    },

    mergeFromSelectedNew(target, source, person) {
        //log.x('PH: merging person ' + person.firstName + ' ' + person.lastName + ' from selected', source);

        target.selected = true;
        target.id = source;
        target.uimodel.expanded = true;
        
    },

    mergeFromPerson(target, source, person) {
        //log.x('PH: merging person ' + source.person.firstName + ' ' + source.person.lastName + ' from person', source);
        log.x('PH: merging person ' + source.person.firstName + ' ' + source.person.lastName + ' from person', source);

        if (source.contact) {
            console.log('has contact');
            if (source.contact.email) target.contact.email = source.contact.email;
            if (source.contact.phone) target.contact.phone = source.contact.phone;
        }

        target.selected = true;
        target.id = source.identity;
        target.uimodel.expanded = true;
        
    },

    mergeRole(target, roleId, source, percentage) {
        if (percentage) percentage = percentage.toString().replace('%', '').replace(',', '.');
        if (percentage && percentage.includes('1/3')) percentage = '33.33';
        target.roles.push({
            id: roleId,
            source: source,
            percentage: Number(percentage) || 0
        });
    },

    createPerson(root, list, person) {
        //log.x('PH: creating person', person);
        const sourceVersion = person.version == 2 ? 2 : 1;
        //console.log(sourceVersion)
        list.push({
            version: 2,
            info: {
                firstName: this.titleCase(sourceVersion == 2 ? person.info.firstName : person.firstName),
                lastName: this.titleCase(sourceVersion == 2 ? person.info.lastName : person.lastName),
                dateOfBirth: sourceVersion == 2 ? person.info.dateOfBirth : person.dateOfBirth,
                ssn: ''
            },
            displayName: this.titleCase(sourceVersion == 2 ? person.info.firstName + ' ' + person.info.lastName : person.firstName + ' ' + person.lastName),
            contact: {
                email: '',
                phone: {
                    prefix: '',
                    number: ''
                }
            },
            addresses: [],
            countryName: '',
            roles: [],
            selected: false,
            search: {
                completed: false
            },
            control: {
                inviteSent: false,
                completed: false
            },
            flags: {
                manual: false,
                contact: false,
                kycChanged: false,
                kycAdded: false,
                kycPep: false,
                kycRrhRemoved: false,
                kycShRemoved: false,
                kycCeoRemoved: false,
                kycChairRemoved: false
            },
            uimodel: {
                expanded: false,
                doSearch: root.config.aml.person.autoCheck,
                doControl: false,
                didSearch: false,
                didControl: false,
                workingSearch: false,
                workingControl: false,
                emailValid: false
            }
        });
        return list.length - 1;
    },

    getListIndex(list, person) {
        for (const i in list) {
            if (this.isSamePerson(list[i], person)) return i;
        }
        return -1;
    },

     // Get person name in title/proper case
     titleCase(str) {
        try {
            str = str.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
            return str.split('-').map(w => w[0].toUpperCase() + w.substr(1)).join('-');
        }catch(ex) {
            return str;
        }
    },

    // Compare two persons
    isSamePerson(person1, person2) {
        if (!person1 || !person2) return false;
        // Compare persons with firstName, lastName and dateOfBirth to be sure (some companies will have i.e. father and son with the same name)
        if (person1.info && person2.info) {
            if (person1.info.firstName.toLowerCase() == person2.info.firstName.toLowerCase() 
            && person1.info.lastName.toLowerCase() == person2.info.lastName.toLowerCase() 
            && person1.info.dateOfBirth == person2.info.dateOfBirth) {
                return true;
            }
        }else if (person1.info) {
            if (person1.info.firstName.toLowerCase() == person2.firstName.toLowerCase() 
            && person1.info.lastName.toLowerCase() == person2.lastName.toLowerCase() 
            && person1.info.dateOfBirth == person2.dateOfBirth) {
                return true;
            }
        }else if (person2.info) {
            if (!person1.firstName || !person2.info.firstName) return false;
            if (person1.firstName.toLowerCase() == person2.info.firstName.toLowerCase() 
            && person1.lastName.toLowerCase() == person2.info.lastName.toLowerCase() 
            && person1.dateOfBirth == person2.info.dateOfBirth) {
                return true;
            }
        }else {
            if (person1.firstName.toLowerCase() == person2.firstName.toLowerCase() 
            && person1.lastName.toLowerCase() == person2.lastName.toLowerCase() 
            && person1.dateOfBirth == person2.dateOfBirth) {
                return true;
            }
        }
        return false;
    },

    // Check if an object is a valid person
    isValidPerson(person1) {
        if (person1.info) {
            if (person1.info.firstName && person1.info.lastName && person1.info.dateOfBirth) {
                return true;
            }
        }else {
            if (person1.firstName && person1.lastName && person1.dateOfBirth) {
                return true;
            }
        }
        return false;
    },

    hasRole(person, roleId) {
        for (const i in person.roles) {
            if (person.roles[i].id == roleId) return true;
        }
        return false;
    },

    getRolePercentage(person, roleId) {
        let kycMatch = false;
        let percentage = 0;
        let amlP = undefined;
        let kycP = undefined;
        for (const i in person.roles) {
            if (person.roles[i].id == roleId) {
                if (!kycMatch || person.roles[i].source == 'kyc') {
                    percentage = person.roles[i].percentage;
                }
                if (person.roles[i].source == 'kyc') kycP = person.roles[i].percentage;
                if (person.roles[i].source == 'aml') amlP = person.roles[i].percentage;
            }
        }
        let op = '';
        if (kycP != undefined && amlP != undefined) {
            if (kycP > amlP) {
                op = '+';
            }else if (kycP < amlP) {
                op = '-';
            }
        }
        return percentage == 0 ? '' : op + percentage + '%';
    },

    getDisplayRoles(person, root) {
        const list = [];
        for (const i in person.roles) {
            let match = false;
            for (const j in list) {
                if (list[j].id == person.roles[i].id) {
                    match = true;
                    if (!list[j].confirmed) {
                        list[j].confirmed = person.roles[i].source == 'kyc';
                        if (person.roles[i].source == 'kyc') list[j].percentage = person.roles[i].percentage;
                    }
                }
            }
            if (!match) {
                list.push({
                    id: person.roles[i].id,
                    percentage: person.roles[i].percentage,
                    confirmed: person.roles[i].source == 'kyc',
                    roleOther: person.roles[i].roleOther,
                    removed: person.roles[i].removed || false
                });
            }
        }
        let str = '';
        for (const i in list) {
            if (i > 0) str += '</span>, ';
            if (list[i].confirmed) {
                str += '<span class="drc">';
            }else if (list[i].removed) {
                str += '<span class="drr">';
            }else {
                str += '<span class="dru">';
            }
            if (list[i].id == 'common_other') {
                str += list[i].roleOther;
            }else {
                str += root.__(list[i].id);
                if (list[i].id == 'screening_role_rrh' && list[i].percentage) str += ' (' + list[i].percentage + '%)';
                if (list[i].id == 'screening_role_shareholder') str += ' (' + list[i].percentage + '%)';
            }
        }
        str += '</span>';
        return str;
    },

    getTextRoles(person, root) {
        const list = [];
        for (const i in person.roles) {
            let match = false;
            for (const j in list) {
                if (list[j].id == person.roles[i].id) {
                    match = true;
                    if (!list[j].confirmed) {
                        list[j].confirmed = person.roles[i].source == 'kyc';
                        if (person.roles[i].source == 'kyc') list[j].percentage = person.roles[i].percentage;
                    }
                }
            }
            if (!match) {
                list.push({
                    id: person.roles[i].id,
                    percentage: person.roles[i].percentage,
                    confirmed: person.roles[i].source == 'kyc',
                    roleOther: person.roles[i].roleOther,
                    removed: person.roles[i].removed || false
                });
            }
        }
        let str = '';
        for (const i in list) {
            if (i > 0) str += ', ';
            if (list[i].id == 'common_other') {
                str += list[i].roleOther;
            }else {
                str += root.__(list[i].id);
                if (list[i].id == 'screening_role_rrh' && list[i].percentage) str += ' (' + list[i].percentage + '%)';
                if (list[i].id == 'screening_role_shareholder') str += ' (' + list[i].percentage + '%)';
            }
        }
        return str;
    },

    getStatusPEP(person, root) {
        if (person.search.completed) {
            if (person.search.isPep) {
                return '<span class="dre">' + root.__('hit_pep') + '</span>';
            }else if (person.search.hasSanction) {
                return '<span class="dre">' + root.__('hit_sanction') + '</span>';
            }else {
                return '<span class="drc">' + root.__('hit_none') + '</span>';
            }
        }else {
            return '<span class="dru">*' + root.__('hit_empty_search') + '</span>';
        }
    },

    getStatusIDC(person, root) {
        if (person.control.completed) {
            if (person.control.method == 'manual') {
                return '<span class="drc">' + root.__('person_confirmed_id_manual') + '</span>';
            }else if (person.control.method == 'fileupload') {
                return '<span class="drc">' + root.__('person_confirmed_id_fileupload') + '</span>';
            }else {
                return '<span class="drc">' + person.control.confirmedName + '</span>';
            }
        }else if (person.control.inviteSent) {
            return '<span class="dre">*' + root.__('person_confirmed_id_pending') + '</span>';
        }else {
            return '<span class="dru">*' + root.__('person_confirmed_id_empty') + '</span>';
        }
    },

    getPersonDisplayName(person) {
        if (person.version == 2) {
            return person.displayName;
        }else {
            return person.firstName + ' ' + person.lastName;
        }
    },

    getIdcMatchIndicator(person) {
        if (!person.control.completed) return 0;

        //console.log('PERSON', person);

        const name1 = person.info.firstName + ' ' + person.info.lastName;
        const name2 = person.control.confirmedInfo.firstName + ' ' + person.control.confirmedInfo.lastName;
        const names1 = name1.split(' ');
        const names2 = name2.split(' ');
        let matchCount = 0;
        for (const i in names1) {
            for (const j in names2) {
                //console.log('comparing ' + names1[i] + ' to ' + names2[j]);
                if (names1[i] == names2[j]) {
                    matchCount += 1;
                }
            }
        }
        //console.log('match on ' + matchCount + ' of ' + Math.max(names1.length, names2.length) + ' names');

        const matchDob = person.info.dateOfBirth == person.control.confirmedInfo.dateOfBirth;
        if (matchDob) {
            //console.log('match on DOB');

            if (matchCount >= Math.max(names1.length, names2.length)) {
                return 1;
            }else if (matchCount > 1) {
                return 2;
            }else if (matchCount == 1) {
                return 3;
            }else {
                return 4;
            }

        }else {
            //console.log('no match on DOB');

            if (matchCount >= Math.max(names1.length, names2.length)) {
                return 5;
            }else if (matchCount > 1) {
                return 6;
            }else if (matchCount == 1) {
                return 7;
            }

        }

        return 0;
    },

    getIdcMatchDescription(person) {
        const indicator = this.getIdcMatchIndicator(person);
        switch (indicator) {
            case 7: return 'weak match on name only';
            case 6: return 'partial match on name only';
            case 5: return 'full match on name only';
            case 4: return 'match on date of birth only';
            case 3: return 'weak match on name and date of birth';
            case 2: return 'partial match on name and date of birth';
            case 1: return 'full match on name and date of birth';
        }
        return 'no match';
    }

};
export default personhelper;