<template>
    <div class="econ-line">
        <div class="econ-line-base">
            <div class="econ-line-top">
                <div class="econ-line-name econ-head">
                    <label>{{ '' }}</label>
                </div>
                <div class="econ-line-year0 econ-head">
                    <label>{{ company.economy.year0.year }}</label>
                </div>
                <div class="econ-line-year1 econ-head">
                    <label>{{ company.economy.year1.year }}</label>
                </div>
                <div class="econ-line-year2 econ-head">
                    <label>{{ company.economy.year2.year }}</label>
                </div>
            </div>
            <div class="econ-line-item" v-for="(line, i) in econLines" :key="'el' + i" :class="[{'econ-line-item-a': i == econLineIndex}]" @click="onLineClick(i)">
                <div class="econ-line-name">
                    <label>{{ __(line.localeKey) }}</label>
                </div>
                <div class="econ-line-year0">
                    <label>{{ line['year0'].display }}</label>
                </div>
                <div class="econ-line-year1">
                    <label>{{ line['year1'].display }}</label>
                </div>
                <div class="econ-line-year2">
                    <label>{{ line['year2'].display }}</label>
                </div>
            </div>
        </div>
        <div class="econ-lines" v-if="lines.labels">
            <LineChart :data="lines" />
        </div>
    </div>
</template>

<script>
import log from '@/log';

import LineChart from '../../components/stats/LineChart';

export default {
    name: 'EconomyTrend',
    components: {
        LineChart
    },
    props: {
        company: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            loading: true,
            lines: {},
            econLines: [],
            econLineIndex: 0
        }
    },
    watch: {
        
    },
    methods: {
        
        onLineClick(index) {
            this.econLineIndex = index;
            this.updateChart(index);
        },

        createLines() {
            if (this.company.lookup) {

                for (let i=0; i<5; i++) {
                    let econKey = 'totalRevenues', localeKey = 'econ_total_revenues';
                    switch (i) {
                        case 1: econKey = 'operatingProfits'; localeKey = 'econ_operating_profits'; break;
                        case 2: econKey = 'resultBeforeTax'; localeKey = 'econ_result_before_tax'; break;
                        case 3: econKey = 'netProfits'; localeKey = 'econ_net_profits'; break;
                        case 4: econKey = 'sumAssets'; localeKey = 'econ_sum_assets'; break;
                    }
                    const item = { localeKey: localeKey };
                    for (let j=0; j<3; j++) {
                        if (this.company.economy['year' + j][econKey]) {
                            item['year' + j] = {
                                value: this.company.economy['year' + j][econKey],
                                display: this.getShortDisplay(this.company.economy['year' + j][econKey])
                            };
                        }else {
                            item['year' + j] = {
                                value: 0,
                                display: ''
                            };
                        }
                        
                    }
                    this.econLines.push(item);
                }

                
            }else {
                // Support for V0 - remove when ready

                for (let i=0; i<5; i++) {
                    let econKey = 'totalRevenues', localeKey = 'econ_total_revenues';
                    switch (i) {
                        case 1: econKey = 'operatingProfits'; localeKey = 'econ_operating_profits'; break;
                        case 2: econKey = 'resultBeforeTax'; localeKey = 'econ_result_before_tax'; break;
                        case 3: econKey = 'netProfits'; localeKey = 'econ_net_profits'; break;
                        case 4: econKey = 'sumAssets'; localeKey = 'econ_sum_assets'; break;
                    }
                    const item = { localeKey: localeKey };
                    for (let j=0; j<3; j++) {
                        if (this.company.economy['year' + j][econKey]) {
                            item['year' + j] = {
                                value: this.company.economy['year' + j][econKey],
                                display: this.company.economy['year' + j][econKey].value
                            };
                        }else {
                            item['year' + j] = {
                                value: 0,
                                display: ''
                            };
                        }
                        
                    }
                    this.econLines.push(item);
                }

            }
            //console.log('econLines', this.econLines);
        },

        updateChart(index) {
            this.lines = {
                labels: [this.company.economy.year2.year, this.company.economy.year1.year, this.company.economy.year0.year],
                numbers: [this.econLines[index]['year2'].value, this.econLines[index]['year1'].value, this.econLines[index]['year0'].value],
                color: this.$root.getColor('accent', false)
            };
            //console.log('lines', this.lines);
        },

        getShortDisplay(value) {
            const short = value / 1000;
            return short.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },

    },
    async created() {
        this.createLines();
        this.updateChart(0);
        this.loading = false;
    }
}
</script>
<style scoped>

.econ-line {
    display: flex;
    padding: 10px 10px;
}

.econ-line-base {
    width: 400px;
    flex-shrink: 0;
}

.econ-line-top {
    display: flex;
    padding: 2px 6px;
    font-weight: bold;
}

.econ-line-item {
    display: flex;
    padding: 4px 6px;
    border: solid 1px var(--card-background);
    border-bottom: 1px solid var(--input-border);
    cursor: pointer;
}
.econ-line-item-a {
    border: solid 1px var(--accent);
}
.econ-line-item:hover {
    border: solid 1px var(--accent);
}
.econ-line-item label {
    cursor: pointer;
}

.econ-line-name {
    flex-grow: 100;
    font-size: 14px;
}

.econ-line-year0, .econ-line-year1, .econ-line-year2 {
    width: 90px;
    flex-shrink: 0;
    text-align: right;
    font-size: 14px;
}

.econ-line-year0 {
    
}
.econ-line-year1 {
    
}
.econ-line-year2 {
    
}

.econ-lines {
    width: 100%;
    padding-left: 20px;
}

</style>
