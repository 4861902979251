<template>
    <div class="slim-page slim-page-mt" v-if="loadingComplete">

        <vap-card>

            <div class="flw100">
                <vap-text-editor :value="replaceBrackets(__('kyc_signer_message'), textData)" />
            </div>

            <div class="flw100" style="margin-top: 40px;">
                <div style="width: 300px; margin: 0 auto;">
                    <vap-row>
                        <vap-group c12>
                            <vap-label desc :text="__('kyc_checkout_method')" />
                            <vap-dropdown v-model="methodModel" noValidation
                                :options="methodOptions"
                                :displayKey="$root.locale.key"
                                :listKey="$root.locale.key"
                                :outputKey="'value'" />
                        </vap-group>
                    </vap-row>
                </div>
            </div>

            <div style="width: 100%; text-align: center; margin-bottom: 40px;">
                <vap-button :text="__('kyc_signer_go')" @clicked="goToSigning" :working="isWorking" />
            </div>

        </vap-card>

        <div class="method-help">
            <div class="method-help-title">
                <div class="method-help-title-icon">
                    <vap-icon :icon="'help-o'" />
                </div>
                <div class="method-help-title-text">
                    <label>{{ __('method_help_title') }}</label>
                </div>
            </div>
            <div class="method-help-item">
                <div class="method-help-head">
                    <label>{{ __('method_bankidno_head') }}</label>
                </div>
                <div class="method-help-help">
                    <label>{{ __('method_bankidno_help') }}</label>
                </div>
            </div>
            <div class="method-help-item">
                <div class="method-help-head">
                    <label>{{ __('method_bankidse_head') }}</label>
                </div>
                <div class="method-help-help">
                    <label>{{ __('method_bankidse_help') }}</label>
                </div>
            </div>
            <div class="method-help-item">
                <div class="method-help-head">
                    <label>{{ __('method_nemidnets_head') }}</label>
                </div>
                <div class="method-help-help">
                    <label>{{ __('method_nemidnets_help') }}</label>
                </div>
            </div>
            <div class="method-help-item">
                <div class="method-help-head">
                    <label>{{ __('method_touchsign_head') }}</label>
                </div>
                <div class="method-help-help">
                    <label>{{ __('method_touchsign_help') }}</label>
                </div>
            </div>
        </div>

        <div class="flw100" style="text-align: center; margin-top: 30px;">
            <label style="color: var(--text-weak);">digital signing powered by</label><br>
            <img :src="$root.images.vfblue" style="width: 160px; margin-top: 8px;" /><br>
        </div>

    </div>
</template>

<script>
import v2lib from '../v2lib';
import api from '../portalapi';
import verified from '../verified';
import kychelper from '../helpers/kychelper';
import locale from '../locale';
import log from '../log';

export default {
    name: 'RecipientPortal',
    data() {
        return {
            lang: 'nb_NO',
            loadingComplete: false,
            methodModel: 'bankid-no',
            methodOptions: [],
            isWorking: false,
            textData: {},
            customer: {},
            kycMetadata: {
                completed: false, aborted: false, signed: false, expired: false,
                editor: { id: '', firstName: '', lastName: '', email: '', displayName: '', completed: false },
                aborter: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
                nextSigner: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
                signatories: [],
                signedNames: '',
                expirationDate: undefined,
                envelopeId: '', documentId: '', fileUid: ''
            },
        }
    },
    methods: {
        setLang() {
            this.$root.locale = locale.getLocale(this.$root.config.locales, this.lang);
        },
        getCheckoutLink() {
            let signUrl = '';
            const useNewCheckout = true;
            if (useNewCheckout) {
                signUrl = 'https://checkout.verified.eu/checkout/?u=' + this.$parent.loadedRecipient.uid + '&';
            }else {
                signUrl = 'https://app.verified.eu/#/sign/envelopes/' + this.$parent.loadedEnvelope.id + '?';
            }
            signUrl += 'lang=' + this.lang;
            signUrl += '&redirect_to=' + this.$root.baseUrl + '/recipient?uid=' + this.$parent.loadedRecipient.uid;
            signUrl += '&access_token=' + v2lib.editorToken;
            return signUrl;
        },
        redirectToCheckout() {
            let signUrl = this.getCheckoutLink();
            sessionStorage.setItem(this.$parent.loadedEnvelope.id, v2lib.editorToken);
            window.location.href = signUrl;
        },
        async goToSigning() {
            this.isWorking = true;
            const result = await verified.setSigningMethod(this.$parent.loadedEnvelope.id, this.$parent.loadedRecipient.id, this.methodModel);
            if (result.success) this.redirectToCheckout();
        }
    },
    async created() {
        this.$parent.setLoading(true, 'Gjør klart til signering' + '...');

        //console.log('recipient', this.$parent.loadedRecipient);
        this.lang = this.$parent.loadedRecipient.language;
        const self = this;
        setTimeout(function() {
            self.setLang();
        }, 500);
        
        
        if (this.$parent.loadedEnvelope.userdata.actions && this.$parent.loadedEnvelope.userdata.actions.portal) {
            // Recipient will be able to select signing method
            
            this.customer = this.$parent.loadedEnvelope.userdata.customer;
            kychelper.getMetadata(this.customer, this.$parent.loadedEnvelope, this.kycMetadata);
            log.v('RP: Metadata loaded', this.kycMetadata);


            this.textData = {
                customerName: this.customer.name,
                editorName: this.kycMetadata.editor.displayName,
                recipientName: this.$parent.loadedRecipient.givenName + ' ' + this.$parent.loadedRecipient.familyName
            };

            
            // Load KYC translations and merge with main
            const defaults = locale.getDefaultKycTranslations();
            const tres = await api.getTranslations('kyc');
            const kycTranslations = { ...defaults, ...tres.data };
            this.$root.translations = { ...this.$root.translations, ...kycTranslations };


            this.methodOptions = [];
            if (this.$root.config.kycs[0].methods.includes('bankid-no')) {
                this.methodOptions.push({
                    value: 'bankid-no',
                    nb_NO: 'BankID Norge',
                    en_EN: 'BankID Norway'
                });
            }
            if (this.$root.config.kycs[0].methods.includes('bankid-se')) {
                this.methodOptions.push({
                    value: 'bankid-se',
                    nb_NO: 'BankID Sverige',
                    en_EN: 'BankID Sweden'
                });
            }
            if (this.$root.config.kycs[0].methods.includes('nets-esign-dk')) {
                this.methodOptions.push({
                    value: 'nets-esign-dk',
                    nb_NO: 'NemID (Danmark)',
                    en_EN: 'NemID (Denmark)'
                });
            }
            if (this.$root.config.kycs[0].methods.includes('touch-sign')) {
                this.methodOptions.push({
                    value: 'touch-sign',
                    nb_NO: 'TouchSign',
                    en_EN: 'TouchSign'
                });
            }
            if (this.$root.config.kycs[0].methods.includes('email') || this.$root.debug.local) {
                this.methodOptions.push({
                    value: 'email',
                    nb_NO: 'E-post',
                    en_EN: 'E-mail'
                });
            }


        }else {
            // Use the old method of redirecting directly to checkout
            this.redirectToCheckout();
        }

        

        this.loadingComplete = true;
        this.$parent.setLoading(false);
    }
}
</script>