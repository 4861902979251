<template>
<div class="frmod-kyc">

    <PopupV1 designer :header="'Connect to client module'" v-if="params.type == 'xconnect'" :width="500" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <ConnectionList :items="options" :params="params" @item="onConnectItem" @itemkey="onConnectItemKey" :tags="tags" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import logicHelper from '../../logicHelper';

import ConnectionList from '../../designer/ConnectionList.vue';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';
import VFCheckbox from '../../inputs/VFCheckbox.vue';

export default {
    name: "ClientModuleView",
    components: {
        PopupV1,
        VFButton,
        VFCheckbox,
        ConnectionList,
    },
    props: {
        params: { type: Object, default: () => { return { type: '' } }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: {},
            options: [],
            tags: [],
        }
    },
    methods: {

        onConnectItem(result) {
            console.log('result', result);

            if (result.conn == 'approver-name') {
                
                logicHelper.prepareItem(this.reference, this.params.fromItem, 'link');
                this.params.fromItem.logic.link.push({
                    type: 'app',
                    link: 'approver-name',
                    key: ''
                });

                console.log('set', this.params.fromItem);

            }else if (result.conn == 'approver-comment') {
                
                logicHelper.prepareItem(this.reference, this.params.fromItem, 'link');
                this.params.fromItem.logic.link.push({
                    type: 'app',
                    link: 'approver-comment',
                    key: ''
                });

                console.log('set', this.params.fromItem);

            }else if (result.conn == 'hastag') {
                
                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'disabled');
                    this.params.fromItem.logic.disabled.keys.push({
                        type: 'tag', key: result.tag, rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'visible');
                    this.params.fromItem.logic.visible.keys.push({
                        type: 'tag', key: result.tag, rev: result.reversed
                    });

                }else if (result.trigger == 'required') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'required');
                    this.params.fromItem.logic.required.keys.push({
                        type: 'tag', key: result.tag, rev: result.reversed
                    });

                }else if (result.trigger == 'ownerReq') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'ownerReq');
                    this.params.fromItem.logic.ownerReq.keys.push({
                        type: 'tag', key: result.tag, rev: result.reversed
                    });

                }

            }

            
            this.$emit('reselect');
            this.$emit('close');
        },

        onConnectItemKey(result) {
            console.log('result', result);

            if (result.conn == 'approve-yes') {
                
                logicHelper.prepareItem(this.reference, this.params.fromItem, 'link');
                this.params.fromItem.logic.link.push({
                    type: 'app',
                    link: 'approver-yes',
                    key: ''
                });

                console.log('set', this.params.fromItem);

            }

            this.$emit('reselect');
            this.$emit('close');
        },

        loadTags() {
            this.tags = [];
            for (const i in this.$root.config.tags.list) {
                this.tags.push({
                    tag: this.$root.config.tags.list[i].key,
                    display: this.$root.config.tags.list[i].text[this.$root.locale.key],
                });
            }
        },

    },
    created() {
        this.loadTags();
        if (this.params.type == 'xconnect') {
            const type = this.params.fromItem.type;

            console.log('TYPE', type);

            // Add possible connections
            if (type == 'check2') {
                // Form is approved
                this.options.push({ type: 'link', conn: 'tag', icon: 'input-check', text: 'Form is approved',
                    hint: 'Form is approved'
                });
            }

            this.options.push({ type: 'trigger', conn: 'hastag', icon: 'check', text: 'Has tag trigger',
                hint: 'Trigger input properties if customer has a specific tag', selectTag: true,
            });
            

        }
    }
}

</script>