<template>
<div class="slim-page">
    
    <vap-label header2 :text="__('customer_new')" style="margin-top: 100px; margin-bottom: 20px;" />

    <!-- Client type selector -->
    <vap-card list noPadding v-if="typeVisible" style="margin-bottom: 40px;">
        <vap-tabs v-model="typeModel" :tabs="typeOptions" @input="onTypeChanged" />
    </vap-card>


    <!-- Company country selector -->
    <vap-card list noPadding>
        <vap-tabs v-model="companyModel" :tabs="companyOptions" @input="onCompanyChanged" v-show="typeModel.company" />

        <div style="padding: 20px 30px; padding-top: 0px;" class="flw100">

            <SearchNO2 ref="searchNO" v-show="typeModel.company && companyModel.searchNO" @validate="validateSearch" @creation="onUnderCreationChecked" />
            <SearchSE2 ref="searchSE" v-show="typeModel.company && companyModel.searchSE" @validate="validateSearch" @creation="onUnderCreationChecked" />
            <SearchUK2 ref="searchUK" v-show="typeModel.company && companyModel.searchUK" @validate="validateSearch" @creation="onUnderCreationChecked" />
            <SearchForreign ref="searchF" v-show="typeModel.company && companyModel.searchF" @validate="validateSearch" />
            <SearchPerson ref="searchP" v-show="typeModel.person" @validate="validateSearch" />

            <!-- Flow selector -->
            <vap-row v-if="searchValid">
                <vap-group c12 v-if="flowOptions.length > 0">
                    <vap-label :text="__t('search-flow')" desc />
                    <!-- <vap-dropdown v-model="flowModel"
                        :disabled="progress.working"
                        :options="flowOptions"
                        :displayKey="$root.locale.key"
                        :outputKey="'key'"
                        :listKey="$root.locale.key"
                        @input="onFlowSelected"
                    /> -->
                    <VFCheckbox radio
                        v-model="flowModelCheck"
                        :options="flowOptionsCheck"
                        @input="onFlowCheckChanged"
                    />
                </vap-group>
            </vap-row>

            <!-- External ID -->
            <vap-row v-if="$root.config.externalId.searchEnabled">
                <vap-group c12>
                    <vap-label :text="$root.config.externalId.title[$root.locale.key]" desc :required="$root.config.externalId.searchRequired" />
                    <vap-input v-model="externalId" noValidation
                        :disabled="progress.working"
                        :placeholder="__($root.config.externalId.searchRequired ? 'common_required' : 'common_optional')"
                        @input="validateSearch"
                    />
                </vap-group>
            </vap-row>

            <!-- Branches / Partners -->
            <vap-row v-if="searchValid && $root.config.branches.searchEnabled && $root.config.partners.searchEnabled">
                <vap-group c6>
                    <vap-label :text="$root.config.branches.clientText[$root.locale.key]" desc :required="$root.config.branches.searchRequired" />
                    <vap-dropdown v-model="branchModel" noValidation
                        :disabled="progress.working"
                        :options="branches"
                        :displayKey="'name'"
                        :outputKey="'key'"
                        :listKey="'name'"
                        @input="validateSearch"
                    />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="$root.config.partners.clientText[$root.locale.key]" desc :required="$root.config.partners.searchRequired"  />
                    <vap-dropdown v-model="partnerModel" noValidation
                        :disabled="progress.working"
                        :options="partners"
                        :displayKey="'name'"
                        :outputKey="'key'"
                        :listKey="'name'"
                        @input="validateSearch"
                    />
                </vap-group>
            </vap-row>

            <!-- Tags -->
            <vap-row v-if="searchValid && $root.config.tags && $root.config.tags.searchShow">
                <vap-group c12>
                    <vap-label :text="'Tags'" desc />
                    <VFCheckbox newDesign
                        v-model="tagModel"
                        :options="tagOptions"
                    />
                </vap-group>
            </vap-row>

            <div class="flw100">
                <vap-label v-if="alreadyExists" :text="__('customer_new_exists')" error alignRight />
                <vap-label v-if="noValidFlows" :text="'No valid flow for this client type'" error alignRight />
                <vap-label v-if="progress.error" :text="progress.error" error alignRight />
                <vap-label v-if="progress.status && !progress.error" :text="progress.status + '...'" alignRight />
                <vap-button-row alignRight style="margin-top: 10px;">
                    <vap-button :text="__('common_cancel')" action uppercase outline @clicked="resetSearch" :disabled="progress.working" />
                    <vap-button :text="__('common_open')" action uppercase v-if="alreadyExists" @clicked="openExistingClient" :working="progress.working" />
                    <vap-button :text="__('common_save')" action uppercase :disabled="!progress.valid || alreadyExists || noValidFlows" @clicked="saveClient" :working="progress.working" />
                </vap-button-row>
            </div>

        </div>
    </vap-card>

    <!-- Import button -->
    <ActionV1 v-if="$root.config.features.importClient"
        :icon="'upload'"
        :text="__t('client-import')"
        :hint="__t('client-import-hint')"
        @clicked="importDialog.show = true"
    />

    <ImportClientDialog v-if="importDialog.show" @close="importDialog.show = false" />

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1flows from '../v1helpers/v1flows';
import v1receipt from '../v1helpers/v1receipt';
import v1lookupHelper from '../v1search/v1lookupHelper';
import v1exceptions from '../v1helpers/v1exceptions';
import v1backoffice from '../v1admin/v1backoffice';

import SearchNO2 from '../v1search/SearchNO2.vue';
import SearchSE2 from '../v1search/SearchSE2.vue';
import SearchUK2 from '../v1search/SearchUK2.vue';
import SearchForreign from '../v1search/SearchForreign.vue';
import SearchPerson from '../v1search/SearchPerson.vue';

import ActionV1 from '../v1ui/ActionV1.vue';
import ImportClientDialog from '../v1ui/ImportClientDialog.vue';

import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'SearchV2',
    components: {
        SearchNO2,
        SearchSE2,
        SearchUK2,
        SearchForreign,
        SearchPerson,
        ActionV1,
        ImportClientDialog,
        VFCheckbox,
    },
    data() {
        return {
            typeVisible: false,
            typeModel: {},
            typeOptions: {},
            companyModel: {},
            companyOptions: {},

            flowModel: '',
            flowOptions: [],
            flowModelCheck: {},
            flowOptionsCheck: {},
            selectedFlow: null,
            noValidFlows: false,
            flowAlwaysVisible: true,

            alreadyExists: false,
            existingId: '',
            progress: { valid: false, working: false, status: '', error: '' },
            searchValid: false,
            
            externalId: '',
            branches: [],
            branchModel: '',
            partners: [],
            partnerModel: '',

            tagOptions: {},
            tagModel: {},
            
            importDialog: { show: false },
        }
    },
    methods: {

        validateSearch() {
            let valid = true;
            if (this.$refs[this.getCurrentRef()]) {
                if (!this.$refs[this.getCurrentRef()].valid) valid = false;
                this.searchValid = this.$refs[this.getCurrentRef()].searchValid;
            }else {
                valid = false;
                this.searchValid = false;
            }
            this.progress.valid = valid;
        },

        openExistingClient() {
            this.$parent.navigate({ path: '/v1client', query: { id: this.existingId }});
        },

        async saveClient() {
            this.progress.working = true;
            try {
                const initialData = this.$refs[this.getCurrentRef()].getInitialData();

                if (initialData.source == 'bankidaml') {

                    this.progress.status = this.__t('lookup-prog-baml');

                }else if (initialData.source == 'trapets-bisnode') {

                    this.progress.status = this.__t('lookup-prog-bisnode');

                }else {
                    
                    this.progress.status = this.__t('lookup-prog-creating');
                    
                }

                // Check if client exists
                const eData = {
                    name: initialData.name,
                    identity: initialData.identity
                };
                const eResult = await v1api.clientExists(eData);
                //console.log('eResult', eResult);
                if (eResult.exists && eData.identity != '') {
                    this.alreadyExists = true;
                    this.existingId = eResult.id;
                    this.progress.status = '';
                    this.progress.working = false;
                    return;
                }

                initialData.externalId = this.externalId;
                initialData.branch = this.branchModel;
                initialData.partner = this.partnerModel;
                let tags = [];
                for (const key in this.tagModel) {
                    if (this.tagModel[key]) tags.push(key);
                }
                if (tags.length > 0) initialData.tags = tags;

                let result = { companyData: null };
                if (initialData.source != 'manual') {
                    result = await v1lookupHelper.getCompanyData(this.$root, initialData.source, initialData.identity, initialData);
                }
                console.log('result', result);

                this.progress.status = this.__t('lookup-prog-creating');
                const response = await v1lookupHelper.createClient(this.$root, initialData, result.companyData, this.selectedFlow);

                if (response.status == 200) {

                    // 200 response means the client already exists
                    this.alreadyExists = true;
                    this.existingId = response.id;

                }else if (response.status == 201) {
                    if (initialData.source != 'manual') {

                        this.progress.status = this.__t('report-prog-receipt');
                        const envelope = await v1receipt.createClientEnvelope(this.$root, response.client, result.companyData, this.selectedFlow, result.rawData);

                        this.progress.status = this.__t('lookup-prog-company');
                        const updateData = {
                            envelopeId: envelope.id,
                            documentId: envelope.documentId,
                            name: this.__t('search-name') + ' ' + this.companyTitleCase(response.client.name),
                            bearerToken: envelope.metadata.bearerToken
                        };
                        //console.log('updateData', updateData);
                        await v1api.updateClient(response.id, updateData);

                        try {
                            const statData = {
                                type: 'org',
                                cc: this.$root.config.params.baseCountry,
                                src: initialData.source,
                                eid: envelope.id,
                                cid: this.$root.credentials.cid,
                                ckey: this.$root.credentials.key,
                                cname: this.$root.user.companyName,
                                stat: this.$root.config.params.accountStatus
                            };
                            v1backoffice.addStat(statData);
                        }catch (exs) {
                            console.error('Error adding stat');
                        }

                        for (const i in result.reports) {
                            this.progress.status = this.__t('lookup-prog-baml-report') + ' (' + Number(Number(i) + 1) + '/' + result.reports.length + ')';
                            await v1receipt.addBankIDReportToClientEnvelope(this.$root, envelope, response.id, result.reports[i]);
                        }

                    }
                    // Client was created, navigate to client view
                    this.$parent.navigate({ path: '/v1client', query: { id: response.id }});
                }

            }catch (ex) {
                this.progress.error = ex.message;
                const exceptionData = {

                };
                v1exceptions.addException(this.$root, 'SearchV1', 'saveClient', ex, exceptionData);

            }
            this.progress.working = false;
        },

        //#region Selection ---------------------------------------------------------------------------------------------------

        onTypeChanged() {
            this.loadAvailableFlows();
            this.validateSearch();
        },

        onCompanyChanged() {
            this.loadAvailableFlows();
            this.validateSearch();
        },

        onUnderCreationChecked() {
            this.loadAvailableFlows();
            this.validateSearch();
        },

        getCurrentRef() {
            if (this.typeModel.company) {
                for (const key in this.companyModel) {
                    if (this.companyModel[key]) return key;
                }
            }else if (this.typeModel.person) {
                return 'searchP';
            }
        },

        resetSearch() {
            this.progress.status = '';
            this.progress.error = '';
            this.alreadyExists = false;
            if (this.$refs[this.getCurrentRef()]) this.$refs[this.getCurrentRef()].resetSearch();
        },

        loadAvailableFlows() {
            let flows = [];
            if (this.typeModel.person) {
                flows = v1flows.getAvailableFlows2(this.$root, 'person', 'xx', 'manual', 'none');
            }else if (this.typeModel.company && this.companyModel.searchF) {
                flows = v1flows.getAvailableFlows2(this.$root, 'org', 'xx', 'manual', 'forreign');
            }else {
                const ref = this.$refs[this.getCurrentRef()];
                flows = v1flows.getAvailableFlows2(this.$root, 'org', ref.country, ref.source, ref.condition);
            }
            //console.log('flows', flows);

            this.flowOptions = [];
            this.flowOptionsCheck = {};
            if (flows.length > 1) {
                // There are multiple available flows for this client type
                // Show the flow dropdown

                for (const i in flows) {
                    const flow = {
                        key: flows[i].key
                    };
                    for (const key in flows[i].title) {
                        flow[key] = flows[i].title[key];
                    }
                    this.flowOptions.push(flow);
                    this.$set(this.flowModelCheck, flows[i].key, (i == 0));
                    this.$set(this.flowOptionsCheck, flows[i].key, { label: flows[i].title[this.$root.locale.key] });
                }
                this.flowModel = this.flowOptions[0].key;
                this.selectedFlow = v1flows.getFlow(this.$root, this.flowOptions[0].key);
                this.noValidFlows = false;
                
            }else if (flows.length == 0) {
                // No available flow for this client type, show error message
                this.flowModel = '';
                this.noValidFlows = true;
            }else {
                // Just one flow available
                this.flowModel = flows[0].key;
                this.selectedFlow = flows[0];
                this.noValidFlows = false;
            }
        },

        onFlowCheckChanged() {
            for (const key in this.flowModelCheck) {
                if (this.flowModelCheck[key]) this.onFlowSelected(key);
            }
        },

        onFlowSelected(flowKey) {
            this.selectedFlow = v1flows.getFlow(this.$root, flowKey);
            //console.log('selected flow set to ' + flowKey, this.selectedFlow);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Loading -----------------------------------------------------------------------------------------------------

        loadAvailableOptions() {
            // Check modules to see what options will be available

            this.addCompanyOption(this.$root.config.search.org.no, 'searchNO', 'NO', 'nb_NO');
            this.addCompanyOption(this.$root.config.search.org.se, 'searchSE', 'SE', 'sv_SE');
            this.addCompanyOption(this.$root.config.search.org.uk, 'searchUK', 'GB', 'en_EN');

            if (this.$root.config.search.forreign.enabled) {
                this.$set(this.companyOptions, 'searchF', { key: 'customer_new_other_title', image: this.$root.images.flags.un_UN });
                this.$set(this.companyModel, 'searchF', false);
            }

            if (Object.keys(this.companyOptions).length > 0) {
                this.$set(this.typeOptions, 'company', { key: 'customer_new_org', icon: 'company' });
                this.$set(this.typeModel, 'company', !this.$root.config.search.person.default);
            }
            if (this.$root.config.search.person.enabled) {
                this.$set(this.typeOptions, 'person', { key: 'customer_new_person', icon: 'person' });
                this.$set(this.typeModel, 'person', this.$root.config.search.person.default);
            }

            if (Object.keys(this.companyOptions).length > 0 && this.$root.config.search.person.enabled) {
                this.typeVisible = true;
            }

            let hasDefault = false;
            let firstKey = '';
            let count = 0;
            for (const key in this.companyModel) {
                if (count == 0) firstKey = key;
                if (this.companyModel[key]) hasDefault = true;
                count += 1;
            }
            if (!hasDefault) {
                this.companyModel[firstKey] = true;
            }

        },

        addCompanyOption(config, countryName, countryCode, flag) {
            if (config.enabled) {
                this.$set(this.companyOptions, countryName, { label: this.getCountryName(countryCode), image: this.$root.images.flags[flag] });
                this.$set(this.companyModel, countryName, config.default);
            }
        },

        loadBranches() {
            // Load branches
            if (this.$root.config.branches.enabled) {
                this.branches = [];
                for (const i in this.$root.departments) {
                    this.branches.push({
                        key: this.$root.departments[i].key,
                        name: this.$root.departments[i].name
                    });
                }
            }
            // Load partners
            if (this.$root.config.partners.enabled) {
                this.partners = [];
                for (const i in this.$root.partners) {
                    this.partners.push({
                        key: this.$root.partners[i].key,
                        name: this.$root.partners[i].firstName + ' ' + this.$root.partners[i].lastName
                    });
                }
            }
        },

        loadTags() {
            for (const i in this.$root.config.tags.list) {
                this.tagOptions[this.$root.config.tags.list[i].key] = {
                    label: this.$root.config.tags.list[i].text[this.$root.locale.key],
                };
                this.tagModel[this.$root.config.tags.list[i].key] = false;
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {
        this.$parent.setTitle(this.__('customer_new'));

        this.loadTags();
        this.loadBranches();
        this.loadAvailableOptions();
        this.$nextTick(() => {
            this.onTypeChanged();
        });
    }
}
</script>