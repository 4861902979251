<template>
<div class="fbinp-chk" :class="[
    {'fbinp-chk-checked': checked },
    {'fbinp-chk-radio': radio },
    {'fbinp-chk-designer': designer },
]">
    <div class="fbinp-chk-con" v-if="checked">
        <div class="fbinp-chk-stem" />
        <div class="fbinp-chk-kick" />
    </div>
</div>
</template>
<script>
export default {
    name: 'CheckboxUI',
    props: {
        radio: { type: Boolean, default: false },
        checked: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        designer: { type: Boolean, default: false },
    },
}
</script>
<style scoped>

.fbinp-chk {
    width: 22px;
    height: 22px;
    border: solid 1px var(--input-border);
}

.fbinp-chk-con {
    display: inline-block;
    width: 26px;
    height: 26px;
    transform: rotate(45deg);
}

.fbinp-chk-checked {
    border-color: #238CCC;
    background-color: #238CCC;
}

.fbinp-chk-radio {
    border-radius: 50%;
    border-color: var(--input-border);
    background-color: transparent;
}

.fbinp-chk-stem {
    position: absolute;
    width: 3px;
    height: 14px;
    left: 11px;
    top: 5px;
    background-color: var(--accent-contrast);
}
.fbinp-chk-kick {
    position: absolute;
    width: 8px;
    height: 3px;
    left: 6px;
    top: 16px;
    background-color: var(--accent-contrast);
}

.fbinp-chk-radio .fbinp-chk-stem {
    top: 5px; left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #238CCC;
}

.fbinp-chk-radio .fbinp-chk-kick {
    display: none;
}




.fbinp-chk-designer {
    border: solid 1px gray;
}
.fbinp-chk-designer .fbinp-chk-radio {
    border-color: gray;
}

.fbinp-chk-designer .fbinp-chk-checked {
    border-color: #238CCC;
    background-color: #238CCC;
}

</style>