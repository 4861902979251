<template>
<div>
    
    <PropertyHeader :header="'PDF'">
        <template v-slot:actions>
            
        </template>
    </PropertyHeader>

    <PropertyItem padded :header="'PDF'" :icon="'pdf'">
        <!-- <div>

            <vap-button :text="'Load userdata'" @clicked="onLoadUserdata" />

        </div> -->

        <DesignerGroup c12>
            <DesignerLabel :text="'Override logo url'" />
            <DesignerInput
                v-model="reference.branding.logoPdf"
                :placeholder="'Not set'"
                @before="$emit('propinput', 'text', 'pdf_logo', $event)"
            />
        </DesignerGroup>

    </PropertyItem>


</div>
</template>
<script>
import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';

export default {
    name: 'PdfPropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() { 
        return {
            
        }
    },
    methods: {

        onLoadUserdata() {
            const pages = JSON.parse(localStorage.getItem('frv2ud'));

            console.log('pages', pages);

            const userdata = {
                formPages: pages
            };

            this.$emit('userdata', userdata);
        },

    },
    created() {
        
    }
}
</script>