import dataModel from "./dataModel";
import dataNorway from "./dataNorway";
const dataPerson = {

    processBamlData(root, output, data, params) {
        //console.log('input', data);

        output.source = 'bankidaml';
        output.version = 1;

        const person = data.personData;

        for (const i in person.aml.list) {
            output.sources.push(person.aml.list[i]);
        }


        output.searchParams.firstName = params.firstName;
        output.searchParams.lastName = params.lastName;
        output.searchParams.dateOfBirth = params.dateOfBirth;
        output.searchParams.ssn = params.ssn;
        output.searchParams.countryOfSsn = params.countryOfSsn;
        output.searchParams.mode = params.mode;
        output.searchParams.parameters = params.parameters;

        output.dateOfBirth = params.dateOfBirth;
        output.ssn = params.ssn;
        output.countryOfSsn = params.countryOfSsn;

        if (person.keyInformation) {

            output.name = person.keyInformation.name;
            output.firstName = person.keyInformation.firstName;
            output.lastName = person.keyInformation.lastName;
            output.gender = person.keyInformation.gender;

            if (person.keyInformation.birthPlace) {
                output.birthplace.country = person.keyInformation.birthPlace.countryCode;
                output.birthplace.city = person.keyInformation.birthPlace.location;
                output.birthplace.available = true;
            }
            
            if (person.keyInformation.citizenship) {
                for (const i in person.keyInformation.citizenship) {
                    const citizenship = dataModel.getEmptyCitizenship();
                    citizenship.country = person.keyInformation.citizenship[i].countryCode;
                    output.citizenships.push(citizenship);
                }
            }

        }

        if (person.address) {

            if (person.address.postal) {
                output.addressPostal.available = true;
                output.addressPostal.street = person.address.postal.address;
                output.addressPostal.zip = person.address.postal.postalcode;
                output.addressPostal.city = person.address.postal.city;
                output.addressPostal.country = person.address.postal.country;
                output.addressPostal.residents = person.address.postal.numberOfResidents ? person.address.postal.numberOfResidents.residents : 0;
                output.addressPostal.houseType = person.address.postal.numberOfResidents ? person.address.postal.numberOfResidents.houseType : '';
                output.addressPostal.registeredDate = new Date().toISOString().substring(0, 16).replace('T', ' ');
                output.addressPostal.source = person.address.postal.metaData.sources[0];
            }

            if (person.address.national) {
                output.addressNational.available = true;
                output.addressNational.street = person.address.national.address;
                output.addressNational.zip = person.address.national.postalcode;
                output.addressNational.city = person.address.national.city;
                output.addressNational.country = person.address.national.country;
                output.addressNational.residents = person.address.national.numberOfResidents ? person.address.national.numberOfResidents.residents : 0;
                output.addressNational.houseType = person.address.national.numberOfResidents ? person.address.national.numberOfResidents.houseType : '';
                output.addressNational.registeredDate = new Date().toISOString().substring(0, 16).replace('T', ' ');
                output.addressNational.source = person.address.national.metaData.sources[0];
            }
            
            if (person.address.historic) {
                for (const i in person.address.historic) {
                    const address = dataModel.getEmptyPersonAddress();
                    address.available = true;
                    address.street = person.address.historic[i].address;
                    address.zip = person.address.historic[i].postalcode;
                    address.city = person.address.historic[i].city;
                    address.country = person.address.historic[i].country;
                    address.registeredDate = person.address.historic[i].registeredDate;
                    address.source = person.address.historic[i].metaData.sources[0];
                    output.historicAddresses.push(address);
                }
            }

        }

        if (person.roles) {

            output.roles.available = true;
            output.roles.sources = person.roles.metadata.sources;

            if (person.roles.organizationRoles) {
                for (const i in person.roles.organizationRoles) {
                    const role = dataModel.getEmptyPersonRole();
                    role.name = person.roles.organizationRoles[i].organizationName;
                    role.orgnr = person.roles.organizationRoles[i].organizationNumber;
                    role.roleId = dataNorway.getRoleId(person.roles.organizationRoles[i].type);
                    role.from = person.roles.organizationRoles[i].accession;
                    output.roles.companies.push(role);
                }
            }

        }

        if (person.aml) {

            if (person.aml.pepResults && person.aml.pepResults.length > 0) {

                output.isPep = true;
                output.pepSummary.hits = person.aml.pepResults.length;

                for (const i in person.aml.pepResults) {
                    const pep = dataModel.getEmptyPepHit();

                    pep.source = person.aml.pepResults[i].source;
                    pep.provider = person.aml.pepResults[i].provider;

                    pep.matchIndicator = person.aml.pepResults[i].matchIndicator;
                    pep.matchDescription = person.aml.pepResults[i].matchIndicatorDescription;

                    pep.name = person.aml.pepResults[i].name;
                    pep.title = person.aml.pepResults[i].title;

                    output.pepHits.push(pep);

                    output.pepSummary.sources.push(person.aml.pepResults[i].source);
                    if (person.aml.pepResults[i].matchIndicator > output.pepSummary.matchIndicator) {
                        output.pepSummary.matchIndicator = person.aml.pepResults[i].matchIndicator;
                        output.pepSummary.matchDescription = person.aml.pepResults[i].matchIndicatorDescription;
                        output.pepSummary.title = person.aml.pepResults[i].title;
                    }
                }

            }

            if (person.aml.sanctionResults && person.aml.sanctionResults.length > 0) {

                output.hasSanction = true;
                output.sanctionSummary.hits = person.aml.sanctionResults.length;

                for (const i in person.aml.sanctionResults) {
                    const sanction = dataModel.getEmptySanctionHit();

                    sanction.source = person.aml.sanctionResults[i].source;
                    sanction.provider = person.aml.sanctionResults[i].provider;

                    sanction.matchIndicator = person.aml.sanctionResults[i].matchIndicator;
                    sanction.matchDescription = person.aml.sanctionResults[i].matchIndicatorDescription;

                    sanction.name = person.aml.sanctionResults[i].name;
                    sanction.title = person.aml.sanctionResults[i].title || '';
                    sanction.comment = person.aml.sanctionResults[i].comment;

                    output.sanctionHits.push(sanction);
                    
                    output.sanctionSummary.sources.push(person.aml.sanctionResults[i].source);
                    if (person.aml.sanctionResults[i].matchIndicator > output.sanctionSummary.matchIndicator) {
                        output.sanctionSummary.matchIndicator = person.aml.sanctionResults[i].matchIndicator;
                        output.sanctionSummary.matchDescription = person.aml.sanctionResults[i].matchIndicatorDescription;
                    }
                    if (person.aml.sanctionResults[i].source == 'UN_CONSOLIDATED') {
                        let str = person.aml.sanctionResults[i].comment;
                        if (str.length > 50) str = str.substring(0, 47) + '...';
                        output.sanctionSummary.title = str;
                    }
                }

            }

        }
        
    },

};
export default dataPerson;