var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"srv2-actions",class:[
        {'srv2-actions-sharp': _vm.sharpCorner },
        {'srv2-actions-disabled': _vm.disabled }
    ]},_vm._l((_vm.actions),function(action,j){return _c('div',{key:'sa' + j,staticClass:"srv2-act srv2-act-btn",class:[
            { 'srv2-act-btn-first': j == 0 },
            { 'srv2-act-end': j == _vm.actions.length - 1 },
            { 'srv2-act-high': action.highlight },
            { 'srv2-act-disabled': action.disabled } ],on:{"click":function($event){$event.stopPropagation();return _vm.onAction(action)}}},[(j == _vm.actions.length - 1)?_c('div',{staticClass:"srv2-act-end-1"}):_vm._e(),(j == _vm.actions.length - 1)?_c('div',{staticClass:"srv2-act-end-2"}):_vm._e(),(!action.working)?_c('div',{staticClass:"srv2-act-icon"},[_c('vap-icon',{attrs:{"icon":action.icon,"small":"","absolute":"","top":4,"right":6,"color":'ext'}})],1):_vm._e(),(action.working)?_c('div',{staticClass:"srv2-act-spinner"},[_c('vap-spinner',{attrs:{"small":""}})],1):_vm._e(),(action.hint && action.hint[_vm.$root.locale.key])?_c('div',{staticClass:"srv2-act-tt"},[_c('vap-tooltip',{attrs:{"text":action.hint[_vm.$root.locale.key]}})],1):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }