<template>
<div class="vf-ttip" @click="handleClick">
    
    <div class="vf-ttip-desc" @click="handleClick">
        <div>
            <vap-icon small :icon="'help-o'" :color="'ext'" />
        </div>
        <div class="vf-ttip-desc-txt">
            {{ inputData.desc[$root.locale.key] }}
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'VFTooltip',
    props: {
        inputData: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        reference: { type: Object, default: null },
    },
    data() {
        return {
            items: [],
        }
    },
    methods: {

        handleClick() {
            this.showHelpPopupX({ htmlRaw: this.inputData.text[this.$root.locale.key] });
        },

    },
    created() {



    }
}
</script>
<style scoped>

.vf-ttip {
    margin-top: -10px;
}

.vf-ttip-desc {
    width: fit-content;
    display: flex;
    padding-left: 4px;
    fill: var(--link-text);
    color: var(--link-text);
    cursor: pointer;
}
.vf-ttip-desc:hover {
    fill: var(--link-active);
    color: var(--link-active);
}

.vf-ttip-desc-txt {
    margin-top: 5px;
    margin-left: 5px;
}

</style>