<template>
    <div>
        <vap-row v-if="value">

            <vap-group c6 v-if="value.type != 'PERSON'">
                <vap-label :text="__('common_name')" desc required />
                <vap-input
                    v-model="value.name"
                    :rules="'required'"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation" />
            </vap-group>
            <vap-group c3 v-if="value.type != 'PERSON'">
                <vap-label :text="__('company_orgnr')" desc :required="!$root.config.portal.entityOrgOptional" />
                <vap-input
                    v-model="value.organizationNumber"
                    :rules="$root.config.portal.entityOrgOptional ? '' : 'required'"
                    :placeholder="$root.config.portal.entityOrgOptional ? __('common_optional') : __('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation" />
            </vap-group>


            <vap-group c3 v-if="value.type == 'PERSON'">
                <vap-label :text="__('common_first_name')" desc required />
                <vap-input
                    v-model="value.firstName"
                    :rules="'required'"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation" />
            </vap-group>
            <vap-group c3 v-if="value.type == 'PERSON'">
                <vap-label :text="__('common_last_name')" desc required />
                <vap-input
                    v-model="value.lastName"
                    :rules="'required'"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation" />
            </vap-group>
            <vap-group c3 v-if="value.type == 'PERSON'">
                <vap-label :text="__('common_dob')" desc required />
                <vap-datepicker
                    v-model="value.dateOfBirth"
                    outputISO
                    :rules="'required'"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation" />
            </vap-group>




            <vap-group c3>
                <vap-label :text="__('common_share')" desc required />
                <vap-input
                    v-model="value.percentage"
                    :rules="'required|percentage'"
                    :placeholder="__('common_required')"
                    :noHighlight="noHighlight"
                    :noValidation="noValidation" />
            </vap-group>

        </vap-row>
    </div>
</template>

<script>

export default {
    name: 'EntityInputs',
    props: {
        value: {
            type: Object,
            default: null
        },
        noValidation: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            noHighlight: true,
        }
    },
    computed: {
        
    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            }
        },
    },
    methods: {
        
    },
    async mounted() {

        

    }
}
</script>
<style scoped>


</style>