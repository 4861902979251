<template>
<div class="vf-valsum" @click="handleClick" :class="[
    { 'vf-valsum-selected' : selected }
]">
    
    <div v-for="(item, i) in items" :key="'ve' + i">
        <div v-html="item.display" />
    </div>

</div>
</template>

<script>
import formHelper from '../formHelper';

export default {
    name: 'VFValidationSummary',
    props: {
        cardData: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        reference: { type: Object, default: null },
    },
    data() {
        return {
            items: [],
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

        getInputDisplay(input, pageNumber) {
            let type = 'Felt';
            let name = '';
            let action = ' ikke huket av!';

            if (input.desc && input.desc[this.$root.locale.key]) name = input.desc[this.$root.locale.key];

            if (input.type == 'check') {

                type = 'Avmerkingsboks' ;
                action = ' ikke huket av!';
            }

            let str = type;

            if (name) str += ' <b>' + name + '</b>';

            str += ' på <b>side ' + pageNumber + '</b>' + action;

            return str;
        },

    },
    created() {

        const steps = {};
        for (const i in this.reference.steps) {
            steps[this.reference.steps[i].key] = {
                number: Number(i) + 1,
                display: this.reference.steps[i].title[this.$root.locale.key]
            };
        }

        for (const i in this.reference.pages) {
            const page = this.reference.pages[i];
            if (steps[page.key]) {
                for (const j in page.cards) {
                    const card = page.cards[j];
                    if (formHelper.isCardVisible(this.reference, card, false, this.reference.pages, page, this.reference.roleKey)) {
                        if (card.type == 'list') {
                            for (const x in card.model) {
                                for (const k in card.inputs) {
                                    const input = card.inputs[k];
                                    if (formHelper.isListInputVisible(this.reference, card, input, x, false, this.reference.pages, page)) {
                                        if (input.required) {
                                            if (!card.val[x][input.listModel].valid) {
                                                this.items.push({
                                                    display: this.getInputDisplay(input, steps[page.key].number),
                                                });
                                            }
                                        }
                                    }
                                }
                            }
                        }else {
                            for (const k in card.inputs) {
                                if (formHelper.isInputVisible(this.reference, card.inputs[k], false, this.reference.pages, page)) {
                                    const input = card.inputs[k];
                                    if (input.required) {
                                        if (!input.val || !input.val.valid) {
                                            this.items.push({
                                                display: this.getInputDisplay(input, steps[page.key].number),
                                                page: 'Side ' + steps[page.key].number,

                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}
</script>
<style scoped>

.vf-valsum {
    float: left;
    width: 100%;
    box-sizing: border-box;
}


/* Designer / Selection */

.vf-valsum-selected {
    outline: solid 2px #238ccc;
}

</style>
