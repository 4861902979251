/*
HTMLProperties.vue includes a dropdown with presets.

The below functions are used to generate the HTML for these presets
and can include kyc data.
*/

export const htmlFinancialStatement = (root, kycKey, companyData, client) => {
    
    if(!companyData.economy.yearAvailable || !companyData.economy.year0.available) {
        return "Could not automatically find income statement";
    }

    const eco = companyData.economy.year0 || {};

    const row = (leftVal, rightVal, bold) => {
        return `
            <div style="display: flex; border-bottom: 1px solid #ccc; ${bold ? "font-weight: bold;" : ""}">
                <div>${leftVal}</div>
                <div style="word-spacing: 5px; flex-grow: 1; text-align: right;">${rightVal} NOK</div>
            </div>
            <div style="margin: 5px 0;"></div>       
        `;
    }

    const safeLocale = (unsafeNum) => {
        try {
            return unsafeNum.toLocaleString();
        } catch(err) {
            console.error(err);
            return "";
        }
    }

    let html = `
    <div style="display: flex;">
        <div style="flex-grow: 1; margin-right: 20px;">
            <h3 style="margin-top: 0;">Income statement ${eco.year}</h3>
            ${row("Sales revenues", safeLocale(eco.salesRevenues))}
            ${row("Other revenues", safeLocale(eco.otherRevenues))}
            ${row("<b>Total revenues</b>", safeLocale(eco.totalRevenues), true)}
            <br>
            ${row("Cost of goods sold", safeLocale(eco.costOfGoodsSold))}
            ${row("Inventory changes", safeLocale(eco.inventoryChanges))}
            ${row("Salary costs", safeLocale(eco.salaryCosts))}
            ${row("Financial costs", safeLocale(eco.financialCosts))}
            ${row("Depreciations", safeLocale(eco.depreciations))}
            ${row("Amortizations", safeLocale(eco.amortizations))}
            ${row("Other operating costs", safeLocale(eco.otherOperatingCosts))}
            ${row("<b>Operating profits</b>", safeLocale(eco.operatingProfits), true)}
            <br>
            ${row("Ordinary profits", safeLocale(eco.ordinaryProfits))}
            ${row("Extraordinary revenues", safeLocale(eco.extraordinaryRevenues))}
            ${row("Extraordinary costs", safeLocale(eco.extraordinaryCosts))}
            ${row("Minority interests", safeLocale(eco.minorityInterests))}
            ${row("Taxes", eco.taxes)}
            ${row("Result before tax", safeLocale(eco.resultBeforeTax))}
            ${row("<b>Net profits</b>", safeLocale(eco.netProfits), true)}
        </div>

        <div style="flex-grow: 1; margin-left: 20px;">
            <h3 style="margin-top: 0;">Balance sheet ${eco.year}</h3>
            ${row("Current assets", safeLocale(eco.currentAssets))}
            ${row("Fixed assets", safeLocale(eco.fixedAssets))}
            ${row("Sum assets", safeLocale(eco.sumAssets), true)}
            <br>
            ${row("Equity", safeLocale(eco.equity))}
            ${row("Debt", safeLocale(eco.debt))}
            ${row("Sum equity and debt", safeLocale(eco.sumEquityAndDebt), true)}
        </div>
    </div>
        
    `;
    
    return html;
}