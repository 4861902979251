<template>
<div class="aml-page">
    
    <vap-infocard :data="notificationsHelp" style="float: left; width: 100%;" />

</div>
</template>
<script>
import axios from 'axios';
import v2lib from '../v2lib';

export default {
    components: {
        
    },
    name: 'MonitorEvents',
    props: {
        monitor: { type: Object, default: null },
    },
    data() {
        return {
            loadingNotifications: false,
            notificationsHelp: [{ icon: 'info', key: 'company_info' }],
        }
    },
    methods: {

        //#region Stuff -------------------------------------------------------------------------------------------------------


        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Notifications -----------------------------------------------------------------------------------------------
        
        async getNotifications() {
            this.loadingNotifications = true;

            const config = {
                headers: {
                    'accept': 'application/json',
                    'x-namespace': v2lib.namespace,
                    'authorization': 'JWT ' + v2lib.personalToken,
                }
            };
            let response = await axios.get(this.$root.baseUrl + `/api/monitors/person/${this.monitor.id}/notifications`, config);
            console.log('response', response);

            this.loadingNotifications = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


    },
    created() {
        this.getNotifications();
    }
}
</script>