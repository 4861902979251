<template>
<div class="v1dc-li">

    <div class="v1dc-li-line" :class="[
        {'v1dc-style-1': status == 1 },
        {'v1dc-style-2': status == 2 },
        {'v1dc-style-3': status == 3 },
    ]" />

    <div class="v1dc-li-style" :class="[
        {'v1dc-style-1': status == 1 },
        {'v1dc-style-2': status == 2 },
        {'v1dc-style-3': status == 3 },
    ]">

        <div class="v1dc-li-icon">
            <vap-icon small :icon="icon" :color="'ext'" />
        </div>

    </div>

    <div class="v1dc-li-content">

        <div class="v1dc-li-row">

            <slot name="row1" />

        </div>
        <div class="v1dc-li-row" v-if="middleRow">

            <slot name="row2" />

        </div>
        <div class="v1dc-li-row">

            <slot name="row3" />

        </div>

    </div>

</div>
</template>
<script>

export default {
    name: 'DetailListItem',
    props: {
        icon: { type: String, default: 'bug' },
        middleRow: { type: Boolean, default: false },
        status: { type: Number, default: 0 },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        

    }
}
</script>
<style scoped>

.v1dc-li {
    position: relative;
    float: left;
    width: 100%;
    display: flex;
    border-top: solid 1px var(--input-border);
    padding: 4px 0px;
}

.v1dc-li-style {
    position: absolute;
    width: 60px;
    top: 0px; bottom: 0px;

/*     background-color: pink;
    opacity: 0.5; */
}

.v1dc-li-icon {
    position: absolute;
    top: 3px; left: 10px;
    width: 35px; height: 35px;
    padding: 3px 3px;
    border-radius: 50%;
    fill: gray;
    background-color: var(--disabled-background);
    box-sizing: border-box;
}
.v1dc-style-1 .v1dc-li-icon {
    fill: var(--success-text);
    background-color: var(--success-background);
}
.v1dc-style-2 .v1dc-li-icon {
    fill: var(--warning-text);
    background-color: var(--warning-background);
}
.v1dc-style-3 .v1dc-li-icon {
    fill: var(--error-text);
    background-color: var(--error-background);
}

.v1dc-li-content {
    margin-left: 60px;
}

.v1dc-li-row {
    display: flex;
}

.v1dc-li-line {
    position: absolute;
    top: 0px; left: 24px;
    bottom: 0px;
    width: 7px;
    background-color: red;
}

.v1dc-li-line.v1dc-style-1 {
    background-color: var(--success-background);
}
.v1dc-li-line.v1dc-style-2 {
    background-color: var(--warning-background);
}
.v1dc-li-line.v1dc-style-3 {
    background-color: var(--error-background);
}

</style>