<template>
<div>

    <PopupV1 designer :header="'Translations'" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <div>

                <div class="fwl100" style="color: white; margin-top: 20px; margin-bottom: 6px;">
                    {{ 'Source:' }}
                </div>

                <div class="frv2-trans-selector">
                    <div class="frv2-trans-selector-flag" v-for="(locale, i) in reference.locales" :key="'tdl' + i" :class="[
                        {'frv2-trans-selector-source': locale.key == sourceLanguage }
                    ]" @click="setSourceLanguage(locale.key)">
                        <FlagIcon circle :locale="locale.key" />
                    </div>
                </div>

                <div class="flw100" style="margin-top: 10px;">
                    <DesignerTextArea v-model="params.text[sourceLanguage]" />
                </div>


                <hr class="flw100" style="width: 60%; margin-left: 20%; margin-top: 20px; border: solid 1px var(--fb-menu-c);">


                <div class="frv2-trans-item" v-for="(locale, i) in reference.locales" :key="'txl' + i" v-show="locale.key != sourceLanguage">
                    <div class="frv2-trans-header">
                        <div class="frv2-trans-header-icon">
                            <FlagIcon circle :locale="locale.key" />
                        </div>
                        <div class="frv2-trans-header-button" @click="translate(locale.key)">
                            {{ 'TRANSLATE' }}
                        </div>
                    </div>
                    <div>
                        <DesignerTextArea v-model="params.text[locale.key]" />
                    </div>
                </div>

            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>
    
</div>
</template>
<script>
import axios from 'axios';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';

import FlagIcon from '../../../../v1ui/FlagIcon.vue';
import DesignerTextArea from '../ui/inputs/DesignerTextArea.vue';

export default {
    name: 'TranslationsDialog',
    components: {
        PopupV1,
        VFButton,
        FlagIcon,
        DesignerTextArea,
    },
    props: {
        params: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: {},

            sourceLanguage: '',

            noSource: '',

            enTarget: '',
            seTarget: '',

        }
    },
    methods: {

        getIso6391(locale) {
            switch (locale) {
                case 'en_EN': return 'en';
                case 'nb_NO': return 'no';
                case 'sv_SE': return 'sv';
            }
            return 'en';
        },

        setSourceLanguage(sourceLanguage) {
            this.sourceLanguage = sourceLanguage;
            sessionStorage.setItem('frv2-src-lang', this.sourceLanguage);
        },
        
        async translate(targetLanguage) {

            // Authentication needs to be fixed before activating this in production
            const apiKey = '';
            const config = {
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

            const data = {
                q: this.params.text[this.sourceLanguage],
                source: this.getIso6391(this.sourceLanguage),
                target: this.getIso6391(targetLanguage),
                format: 'text',
            };

            const result = await axios.post(`https://translation.googleapis.com/language/translate/v2?key=${apiKey}`, data, config);
            console.log('result', result);

            this.params.text[targetLanguage] = result.data.data.translations[0].translatedText;
        },
        
    },
    created() {
        if (sessionStorage.getItem('frv2-src-lang')) {
            this.sourceLanguage = sessionStorage.getItem('frv2-src-lang');
        }else {
            this.sourceLanguage = this.$root.locale.key;
        }
    }
}
</script>
<style scoped>

.frv2-trans-selector {
    float: left;
    width: 100%;
    display: flex;
}

.frv2-trans-selector-flag {
    width: 80px; height: 30px;
    padding-top: 5px;
    padding-left: 28px;
    background-color: var(--fb-menu-c);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-trans-selector-flag:hover {
    background-color: var(--fb-menu-a);
}
.frv2-trans-selector-source {
    background-color: var(--fb-menu-a);
}


.frv2-trans-item {
    float: left;
    width: 100%;
    margin-top: 10px;
}

.frv2-trans-header {
    display: flex;
    background-color: var(--fb-menu-a);
}

.frv2-trans-header-icon {
    flex-grow: 100;
    padding: 3px 5px;
}

.frv2-trans-header-button {
    padding: 6px 10px;
    font-size: 13px;
    color: white;
    border-left: solid 1px var(--fb-menu-b);
    box-sizing: border-box;
    cursor: pointer;
    opacity: 0.6;
}
.frv2-trans-header-button:hover {
    background-color: var(--fb-menu-c);
}

</style>