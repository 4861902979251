<template>
    <div>
        <BatchView :items="items" :steps="steps" :skip="skip" :title="'Kunder'" />
    </div>
</template>
<script>
import api from '../../portalapi';
import log from '../../log';
import tools from '../../tools';
import clienthelper from '../../helpers/clienthelper';
import personhelper from '../../helpers/personhelper';

import BatchView from '../../components/special/BatchView';

export default {
    components: {
        BatchView
    },
    name: 'BatchUpgrade',
    data() {
        return {
            items: [],
            steps: 3,
            STEP_LOAD_CLIENT: 0,
            STEP_CHECK_STATUS: 1,
            STEP_UPDATE_STATUS: 2,
            skip: 0
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            return item.name;
        },

        onGetInfo1(item) {
            return '';
        },

        onGetInfo2(item) {
            return '';
        },

        async onRemoveItem(item) {},

        async onStart() {
            return true;
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return 'Laster kundedata';
                case this.STEP_CHECK_STATUS: return 'Sjekker status';
                case this.STEP_UPDATE_STATUS: return 'Oppdaterer status';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return await this.loadClient(item, data);
                case this.STEP_CHECK_STATUS: return await this.checkStatus(item, data);
                case this.STEP_UPDATE_STATUS: return await this.updateStatus(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async loadClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            log.suppress = true;
            result.data.client = await clienthelper.loadClient(this.$root, item);
            log.suppress = false;

            result.ok = true;
            return result;
        },

        async checkStatus(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            const client = data[this.STEP_LOAD_CLIENT].client;

            const check = this.readyForReport(client);
            result.data.check = check;

            result.ok = true;
            return result;
        },

        async updateStatus(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            const check = data[this.STEP_CHECK_STATUS].check;
            console.log('check', check);

            let status = 'r-rep-o';

            if (!check.hasID && !check.hasSearch) {
                status = 'r-rep-k';
            }else if (!check.hasID) {
                status = 'r-rep-l';
            }else if (!check.hasSearch) {
                status = 'r-rep-m';
            }

            if (!check.hasKyc) {
                console.log('missing kyc');
                const client = data[this.STEP_LOAD_CLIENT].client;
                status = 'f-kyc-b';
                if (client.kycMetadata.completed) {
                    status = 'f-kyc-c';
                }
            }

            console.log('status', status);

            const response = await api.updateCustomer(item._id, {
                status: status
            });
            log.s('Sync: Updated status', response);

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        readyForReport(client) {

            // Check ID
            let hasID = false;
            let hasCeo = false, hasChair = false, hasRrh = false;
            let hasCeoID = false, hasChairID = false, hasRrhID = false;
            for (const i in client.persons) {
                if (personhelper.hasRole(client.persons[i], 'ceo')) {
                    hasCeo = true;
                    if (client.persons[i].control.completed) hasCeoID = true;
                }
                if (personhelper.hasRole(client.persons[i], 'chair')) {
                    hasChair = true;
                    if (client.persons[i].control.completed) hasChairID = true;
                }
                if (personhelper.hasRole(client.persons[i], 'screening_role_rrh')) {
                    hasRrh = true;
                    if (client.persons[i].control.completed) hasRrhID = true;
                }
            }
            if ((hasCeo && hasCeoID) || (hasChair && hasChairID) || (hasRrh && hasRrhID)) {
                hasID = true;
            }else {
                console.log('missing id');
            }

            // Check keywords
            let allCompleted = true;
            for (const i in client.reportData) {

                if (client.reportData[i].type == 'aml'
                && !client.reportData[i].searchComplete
                && !client.reportData[i].ok
                && (client.reportData[i].comment == '<p></p>' || client.reportData[i].comment == '')) {
                    allCompleted = false;
                    console.log('missing company search');
                }

                if (client.reportData[i].type == 'per'
                && !client.reportData[i].searchComplete
                && !client.reportData[i].ok
                && (client.reportData[i].comment == '<p></p>' || client.reportData[i].comment == '')) {
                    
                    for (const j in client.persons) {
                        if (client.persons[j].id == i) {
                            console.log('check role', client.persons[j]);
                            if (personhelper.hasRole(client.persons[j], 'ceo') || personhelper.hasRole(client.persons[j], 'chair') || personhelper.hasRole(client.persons[j], 'screening_role_rrh')) {
                                allCompleted = false;
                                console.log('missing person search');
                            }
                        }
                    }
                }
            }

            return {
                hasKyc: client.kycMetadata.signed,
                hasID: hasID,
                hasSearch: allCompleted
            };
        },

        async loadCustomers() {
            this.$parent.setLoading(true, 'Laster...');
            
            const filters = [
                { field: 'status', op: 'is', value: 'r-rep-a' },
                /* { field: 'report', op: 'exists', value: false } */
            ];
            const from = 0;
            const limit = 1000;
            const sort = { dateUpdated: -1 };
            const include = [];
            const counts = [];

            const response = await api.queryCustomers(filters, from, limit, sort, include, counts);

            this.items = [];
            for (const i in response.customers) {
                this.items.push(response.customers[i]);
            }

            this.$parent.setLoading(false);
        },

    },
    async created() {
        await this.loadCustomers();
    }
}
</script>