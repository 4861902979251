<template>
    <div>
        <HorizontalStepper v-if="!completed && !kycMetadata.editor.completed" :steps="steps" :stepKey="stepKey" @nav="onStepKeyChanged" @on-submit="onSubmit" :flowName="__('kyc_title')" />
        <div v-if="!loadingKYC && !completed && !kycMetadata.editor.completed" class="kyc">

            <!-- Velkommen (Editor) -->
            <vap-form v-show="stepKey == 'start'" ref="start" >
                <vap-card>
                    <vap-text-editor :value="__('kyc_welcome')" />
                </vap-card>
            </vap-form>


            <!-- Selskapet -->
            <vap-form v-show="stepKey == 'brreg'" ref="brreg">
                
                <vap-infocard v-if="!editor && $root.config.kycs[kycIndex].help.owner.company_aml.items" :data="$root.config.kycs[kycIndex].help.owner.company_aml.items" />
                <vap-infocard v-if="editor && $root.config.kycs[kycIndex].help.editor.company_aml.items" :data="$root.config.kycs[kycIndex].help.editor.company_aml.items" />

                <ve-alert :warning="!ud.amlData.sanction" v-if="ud.amlData && !editor" :icon="'info'" style="float: left; width: 100%; margin-bottom: 20px;" >
                    <div v-if="ud.amlData.sanction">
                        <span>{{ __('kyc_aml_info') }}</span><span><b>{{ ud.amlData.keyInformation.name }}</b></span>
                        <span>{{ __('kyc_aml_on') }}</span><span><b>{{ ud.amlTimestamp }}</b></span><br>
                        <span>{{ __('kyc_aml_result') }}</span><span ><b>{{ ud.amlData.sanction.message }}</b></span>
                    </div>
                    <div v-if="!ud.amlData.sanction">
                        <span>{{ 'Kunne ikke hente data fra sanksjonsregisteret. Dette kan hentes ved et senere tidspunkt.' }}</span>
                    </div>
                </ve-alert>

                <vap-card :header="__(customer.source == 'trapets' ? 'company_bolag_header' : 'company_brreg_header')">
                    <template v-slot:actions>
                        <!-- <vap-action :icon="'edit'" @clicked="state.editBrreg = !state.editBrreg" :tooltip="'Rediger'" /> -->
                    </template>
                    <!-- <CompanyInputs v-model="ud.brregData" :editMode="state.editBrreg" /> -->
                    <CompanyInputs v-if="customer.source == 'brreg'" v-model="ud.brregData" :editMode="true" required :noValidation="!editor || $root.debug.skipValidation" />
                    <CompanyInputsT v-if="customer.source == 'trapets'" v-model="ud.companyData" :editMode="true" required :noValidation="!editor || $root.debug.skipValidation" />
                </vap-card>

            </vap-form>


            <!-- Oppdragsavtale -->
            <vap-form v-show="stepKey == 'aagree' && !editor" ref="aagree">
                <vap-infocard v-if="!editor && $root.config.kycs[kycIndex].help.owner.aagree.items" :data="$root.config.kycs[kycIndex].help.owner.aagree.items" />
                
                <vap-card>
                    <div class="flw100" style="margin-top: 10px; margin-left: 5px; margin-bottom: 30px;">
                        <vap-switch v-model="ud.includeAA" :text="__('kyc_aa_include')" />
                    </div>
                    <vap-row v-if="ud.includeAA">
                        <vap-group c12>
                            <vap-file-upload singleFile autoPreview v-model="tasksFiles" :header="__('kyc_aa_upload')" :helpText="__('kyc_aa_drop')" :uploadText="__('kyc_aa_browse')" />
                        </vap-group>
                    </vap-row>    
                </vap-card>
            </vap-form>
            <!-- Oppdragsavtale Visning -->
            <vap-form v-show="stepKey == 'aagree' && editor" ref="aagree">
                <vap-infocard v-if="editor && $root.config.kycs[kycIndex].help.editor.aagree.items" :data="$root.config.kycs[kycIndex].help.editor.aagree.items" />
                <vap-card v-if="stepKey == 'aagree' && editor">
                    <Preview :raw="tasksPreviewData" :filename="tasksPreviewName" downloadButton />
                </vap-card>
            </vap-form>


            <!-- Selskapet Utenlandsk Selskap -->
            <vap-form v-show="stepKey == 'company'" ref="company">
                
                <vap-infocard v-if="!editor && $root.config.kycs[kycIndex].help.owner.company_man.items" :data="$root.config.kycs[kycIndex].help.owner.company_man.items" />
                <vap-infocard v-if="editor && $root.config.kycs[kycIndex].help.editor.company_man.items" :data="$root.config.kycs[kycIndex].help.editor.company_man.items" />

                <vap-card :header="__('company_manual_header')">
                    <CompanyInputsF v-model="ud.manualData" required :noValidation="!editor" />
                </vap-card>

            </vap-form>


            <!-- Nøkkelroller -->
            <vap-form v-show="stepKey == 'roles'" ref="roles">
                
                <vap-infocard v-if="!editor && $root.config.kycs[kycIndex].help.owner.roles_aml.items" :data="$root.config.kycs[kycIndex].help.owner.roles_aml.items" />
                <vap-infocard v-if="editor && $root.config.kycs[kycIndex].help.editor.roles_aml.items" :data="$root.config.kycs[kycIndex].help.editor.roles_aml.items" />

                <vap-card v-for="(person, i) in ud.roles" :key="i" list :header="person.roleId ? __(person.roleId) : __('common_select_role')">
                    <template v-slot:actions>
                        <vap-icon action :icon="'delete'" @clicked="ud.roles.splice(i, 1)"><template v-slot:tooltip><vap-tooltip :text="__('common_remove')" /></template></vap-icon>
                    </template>
                    <RoleInputs v-model="ud.roles[i]"
                        :editMode="true"
                        :required="editor"
                        :roleOptions="roleOptions"
                        :config="$root.config.kycs[kycIndex].inputs.officials"
                        :noValidation="!editor || $root.debug.skipValidation"
                        @role="setRole"
                        @pep="processPeps" />
                </vap-card>
                <vap-button-row alignCenter style="margin-top: 10px;">
                    <vap-button :text="__('common_add') + ' ' + __('common_person')" @clicked="addRole" />
                </vap-button-row>
            </vap-form>


            <!-- Nøkkelroller Utenlandsk Selskap -->
            <vap-form v-show="stepKey == 'mroles'" ref="mroles">
                
                <vap-infocard v-if="!editor && $root.config.kycs[kycIndex].help.owner.roles_man.items" :data="$root.config.kycs[kycIndex].help.owner.roles_man.items" />
                <vap-infocard v-if="editor && $root.config.kycs[kycIndex].help.editor.roles_man.items" :data="$root.config.kycs[kycIndex].help.editor.roles_man.items" />

                <vap-card v-for="(role, i) in ud.roles" :key="i" list :header="role.roleId ? __(role.roleId) : __('common_select_role')">
                    <template v-slot:actions>
                        <vap-icon action :icon="'delete'" @clicked="ud.roles.splice(i, 1)"><template v-slot:tooltip><vap-tooltip :text="__('common_remove')" /></template></vap-icon>
                    </template>
                    <RoleInputs v-model="ud.roles[i]"
                        :editMode="true"
                        :required="editor"
                        :roleOptions="roleOptions"
                        :config="$root.config.kycs[kycIndex].inputs.officials"
                        :noValidation="!editor || $root.debug.skipValidation"
                        @role="setRole"
                        @pep="processPeps" />
                </vap-card>
                <vap-button-row alignCenter style="margin-top: 10px;">
                    <vap-button :text="__('common_add') + ' ' + __('common_person')" @clicked="showSelectRolePopup" />
                </vap-button-row>
            </vap-form>


            <!-- Reelle Rettighetshavere -->
            <vap-form v-show="stepKey == 'rrh'" ref="rrh">
                
                <vap-infocard v-if="!editor && customer.source == 'brreg' && $root.config.kycs[kycIndex].help.owner.rrh_aml.items" :data="$root.config.kycs[kycIndex].help.owner.rrh_aml.items" />
                <vap-infocard v-if="editor && customer.source == 'brreg' && $root.config.kycs[kycIndex].help.editor.rrh_aml.items" :data="$root.config.kycs[kycIndex].help.editor.rrh_aml.items" />

                <vap-infocard v-if="!editor && customer.source != 'brreg' && $root.config.kycs[kycIndex].help.owner.rrh_man.items" :data="$root.config.kycs[kycIndex].help.owner.rrh_man.items" />
                <vap-infocard v-if="editor && customer.source != 'brreg' && $root.config.kycs[kycIndex].help.editor.rrh_man.items" :data="$root.config.kycs[kycIndex].help.editor.rrh_man.items" />

                <vap-radio-card
                    v-model="ud.ownerQuestion"
                    :rules="editor ? 'required' : ''"
                    :noValidation="!editor || $root.debug.skipValidation"
                    @changed="onRRHRadioChanged"
                    :options="{
                        hasOwners: { label: __('kyc_rrh_yes') },
                        noOwners: { label: __('kyc_rrh_no') },
                        public: { label: __('kyc_rrh_public') }
                }"></vap-radio-card>
                <div v-if="ud.ownerQuestion.hasOwners" >
                    <vap-card v-for="(rrh, i) in ud.rrhs" :key="i" list :header="__('screening_role_rrh') + ' ' + (i+1)">
                        <template v-slot:actions>
                            <vap-icon action :icon="'delete'" @clicked="removeRRH(i)"><template v-slot:tooltip><vap-tooltip :localized="'common_remove'" /></template></vap-icon>
                        </template>
                        <RoleInputs v-model="ud.rrhs[i]"
                            :editMode="true"
                            :required="editor"
                            :roleOptions="roleOptions"
                            :noValidation="!editor || $root.debug.skipValidation"
                            :config="$root.config.kycs[kycIndex].inputs.rrh"
                            @pep="processPeps"
                            @changed="validateStep" />
                    </vap-card>
                    <vap-button-row alignCenter style="margin-top: 10px;">
                        <vap-button :text="__('common_add') + ' ' + __('screening_role_rrh')" @clicked="addRRH" />
                    </vap-button-row>
                </div>
            </vap-form>


            <!-- Eierstruktur -->
            <vap-form v-show="stepKey == 'entity'" ref="entity">
                
                <vap-infocard v-if="!editor && customer.source == 'brreg' && $root.config.kycs[kycIndex].help.owner.entity_aml.items" :data="$root.config.kycs[kycIndex].help.owner.entity_aml.items" />
                <vap-infocard v-if="editor && customer.source == 'brreg' && $root.config.kycs[kycIndex].help.editor.entity_aml.items" :data="$root.config.kycs[kycIndex].help.editor.entity_aml.items" />

                <vap-infocard v-if="!editor && customer.source != 'brreg' && $root.config.kycs[kycIndex].help.owner.entity_man.items" :data="$root.config.kycs[kycIndex].help.owner.entity_man.items" />
                <vap-infocard v-if="editor && customer.source != 'brreg' && $root.config.kycs[kycIndex].help.editor.entity_man.items" :data="$root.config.kycs[kycIndex].help.editor.entity_man.items" />

                <vap-radio-card
                    v-model="ud.entityQuestion"
                    :rules="editor ? 'required' : ''"
                    :options="{
                        manual: { label: __(customer.source == 'brreg' ? 'kyc_entity_manual' : 'kyc_entity_manual_no_lookup') },
                        file: { label: __('kyc_entity_file') }
                }"></vap-radio-card>
                <div v-if="ud.entityQuestion.manual" >
                    <div v-if="ud.entities.length == 0"  class="flw100" style="text-align: center; padding-top: 30px; padding-bottom: 40px;">
                        <label>{{ __('kyc_entity_empty') }}</label>
                    </div>
                    <vap-card v-for="(entity, i) in ud.entities" :key="i" list 
                            :header="(i+1) + ' - ' + __(entity.type == 'PERSON' ? 'physical_person' : 'judicial_person')">
                        <template v-slot:actions>
                            <vap-icon action :icon="'delete'" @clicked="ud.entities.splice(i, 1)"><template v-slot:tooltip><vap-tooltip :localized="'common_remove'" /></template></vap-icon>
                        </template>
                        <EntityInputs v-model="ud.entities[i]" :noValidation="!editor" />
                    </vap-card>
                    <vap-button-row alignCenter style="margin-top: 10px;">
                        <vap-button :text="__('common_add') + ' ' + __('common_firm')" @clicked="addEntity('ORGANIZATION')" />
                        <vap-button :text="__('common_add') + ' ' + __('physical_person')" @clicked="addEntity('PERSON')" />
                    </vap-button-row>
                </div>
                <vap-card v-if="ud.entityQuestion.file && stepKey == 'entity'">
                    <vap-file-upload v-model="entityFiles" :existingFiles="entityExisting" :header="__('kyc_entity_upload')" :helpText="__('kyc_entity_drop')" :uploadText="__('kyc_entity_browse')" />
                </vap-card>
            </vap-form>


            <!-- Aksjeeierbok -->
            <vap-form v-show="stepKey == 'book'" ref="book">
                
                <vap-infocard v-if="!editor && $root.config.kycs[kycIndex].help.owner.book.items" :data="$root.config.kycs[kycIndex].help.owner.book.items" />
                <vap-infocard v-if="editor && $root.config.kycs[kycIndex].help.editor.book.items" :data="$root.config.kycs[kycIndex].help.editor.book.items" />

                <vap-radio-card
                    v-model="ud.bookQuestion"
                    :rules="editor ? 'required' : ''"
                    :options="{
                        upload: { label: __('kyc_book_option_upload') },
                        later: { label: __('kyc_book_option_later') },
                        internal: { label: __(editor ? 'kyc_book_option_exists_e' : 'kyc_book_option_exists_o') }
                }"></vap-radio-card>
                <vap-card v-if="ud.bookQuestion.upload && stepKey == 'book'">
                    <vap-file-upload v-model="bookFiles" :existingFiles="bookExisting" :header="__('kyc_entity_upload')" :helpText="__('kyc_entity_drop')" :uploadText="__('kyc_entity_browse')" />
                </vap-card>
                <vap-card v-if="!editor && ud.bookQuestion.internal && stepKey == 'book'">
                    <vap-file-upload v-model="bookFilesEx" :existingFiles="bookExistingEx" :header="__('kyc_entity_upload')" :helpText="__('kyc_entity_drop')" :uploadText="__('kyc_entity_browse')" />
                </vap-card>
                <vap-card v-if="editor && ud.bookQuestion.internal && stepKey == 'book'">
                    <Preview :raw="bookPreviewData" :filename="bookPreviewName" downloadButton />
                </vap-card>
            </vap-form>


            <!-- PEP -->
            <vap-form v-show="stepKey == 'pep'" ref="pep">
                
                <vap-infocard v-if="!editor && $root.config.kycs[kycIndex].help.owner.pep.items" :data="$root.config.kycs[kycIndex].help.owner.pep.items" />
                <vap-infocard v-if="editor && $root.config.kycs[kycIndex].help.editor.pep.items" :data="$root.config.kycs[kycIndex].help.editor.pep.items" />

                <vap-card v-for="(pep, i) in ud.peps" :key="i" list :header="'PEP ' + (i+1)">
                    <template v-slot:actions>
                        <vap-icon action :icon="'delete'" @clicked="ud.peps.splice(i, 1)"><template v-slot:tooltip><vap-tooltip :localized="'common_remove'" /></template></vap-icon>
                    </template>
                    <RoleInputs v-model="ud.peps[i]"
                        :editMode="true"
                        :roleOptions="roleOptions"
                        :noValidation="!editor"
                        :required="editor"
                        :config="$root.config.kycs[kycIndex].inputs.pep" />
                </vap-card>
            </vap-form>


            <!-- Formål -->
            <vap-form v-show="stepKey == 'custom'" ref="custom">
                <vap-infocard v-if="!editor && $root.config.kycs[kycIndex].help.owner.purpose.items" :data="$root.config.kycs[kycIndex].help.owner.purpose.items" />
                <vap-infocard v-if="editor && $root.config.kycs[kycIndex].help.editor.purpose.items" :data="$root.config.kycs[kycIndex].help.editor.purpose.items" />
                <FormRenderer ref="form-renderer" :data="loadedCards" :translations="kycTranslations" :noValidation="!editor" :editor="editor" />
            </vap-form>


            <!-- Checkout -->
            <vap-form v-show="stepKey == 'checkout'" ref="checkout">
                <vap-infocard v-if="!editor && $root.config.kycs[kycIndex].help.owner.checkout.items" :data="$root.config.kycs[kycIndex].help.owner.checkout.items" />
                <vap-infocard v-if="editor && $root.config.kycs[kycIndex].help.editor.checkout.items" :data="$root.config.kycs[kycIndex].help.editor.checkout.items" />
                <vap-form-divider v-if="!editor" :left="55" :right="45">
                    <template v-slot:left>
                        <vap-label :text="__('recipient')" header style="float: left; width: 100%; margin-bottom: 5px;" />
                        <RecipientSelect v-if="!checkout.initalSelect && !editor" :persons="persons" @selected="setInitialReceiver" />
                        <!-- <Recipient v-if="editor" v-model="checkout.signers" :persons="checkout.persons" /> -->
                        <Recipient v-if="!editor && checkout.initalSelect" :initialData="checkout.initalReceivers" v-model="checkout.receivers" :persons="persons" selectEditor />
                    </template>
                    <template v-slot:right>
                        <vap-label :text="__('recipient_order')" header style="float: left; width: 100%;  margin-bottom: 5px;" />
                        <RecipientList v-if="!editor" :editors="checkout.receivers" :signers="checkout.internals" :controls="checkout.controls" tempEditor />
                        <!-- <RecipientList v-if="editor" :signers="checkout.signers" /> -->
                    </template>
                </vap-form-divider>
                <Checkout v-if="editor" v-model="checkoutData" :receiver="ud.receiver" :persons="checkout.persons" @submit="onSubmit" />
            </vap-form>

        </div>

        <div class="slim-page" v-if="kycMetadata.editor.completed" style="margin-top: 100px;">
            <vap-card :header="'Dette skjema er allerede fylt ut'">
                <vap-row v-if="!kycMetadata.signed">
                    <vap-group c6>
                        <vap-label desc :text="'Avventer signering av'" />
                        <vap-label value :text="kycMetadata.nextSigner.displayName" />
                    </vap-group>
                    <vap-group c6 v-if="kycMetadata.editorIsNextSigner">
                        <vap-label desc :text="'Signeringsmetode'" />
                        <vap-label value :text="getSigningMethodName(kycMetadata.nextSigner.method)" />
                    </vap-group>
                </vap-row>
                <div v-if="kycMetadata.editorIsNextSigner && !kycMetadata.signed && !kycSubmitError">
                    <label><b>Har du problemer med å signere med den valgte signeringsmetoden?</b></label><br>
                    <label>Velg en annen signeringsmetode eller kontakt oss for alternativer.</label>
                </div>
                <div v-if="kycMetadata.editorIsNextSigner && !kycMetadata.signed && kycSubmitError">
                    <label><b>Har du problemer med å signere med den valgte signeringsmetoden?</b></label><br>
                    <label>Kontakt oss for alternativer.</label>
                </div>
                <div v-if="!kycMetadata.editorIsNextSigner && !kycMetadata.signed">
                    <label><b>Har mottakeren problemer med å signere?</b></label><br>
                    <label>Mottaker kan åpne e-posten på nytt og velge en annen signeringsmetode.</label><br><br>
                    <label>Eventuelt kontakt oss for alternativer.</label><br>
                </div>
                <div v-if="kycMetadata.signed">
                    <label>Egenerklæringen er ferdig signert. Takk for ditt samarbeid.</label><br>
                </div>
                <vap-button-row alignCenter style="margin-top: 30px;" v-if="kycMetadata.editorIsNextSigner && !kycSubmitError">
                    <vap-button :text="'Endre signeringsmetode'" @clicked="selectMethod.show = true" :disabled="isFetchingSignLink" />
                    <vap-button :text="'Gå til signering'" @clicked="goToSigning" :working="isFetchingSignLink" />
                </vap-button-row>
            </vap-card>
        </div>
        <ve-popup v-if="selectMethod.show" :header="'Velg signeringsmetode'" :width="400" @close="selectMethod.show = false">
            <div v-if="!selectMethod.working" class="flw100">
                <vap-item padding20 v-for="(method, i) in methodOptions" :key="'m' + i" @clicked="changeSigningMethod(method)">
                    <label>{{ method.display }}</label>
                </vap-item>
            </div>
            <div v-if="selectMethod.working" class="flw100" style="padding-left: 210px; padding-top: 30px; padding-bottom: 30px;">
                <vap-spinner medium />
            </div>
        </ve-popup>

        <ve-popup v-if="rrhPopup.show" :header="__('kyc_rrh_popup_header')" :width="300" @close="rrhPopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>{{ __('kyc_rrh_popup_message') }}</label><br><br>
                <label>{{ __('kyc_rrh_popup_hint') }}</label>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('kyc_rrh_popup_yes')" uppercase @clicked="rrhPopup.show = false" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="selectRolePopup.show" :header="'Velg rolle'" :width="400" @close="selectRolePopup.show = false" hideCloseButton footerSeparator>
            <div style="max-height: 630px; overflow: auto;">
                <vap-item v-for="(role, i) in roleOptions" :key="i" padding20 @clicked="showSelectPersonPopup(role, 'role')">
                    <label>{{ role[$root.locale.key] }}</label>
                </vap-item>
            </div>
        </ve-popup>

        <ve-popup v-if="selectPersonPopup.show" :header="'Velg person'" :width="400" @close="selectPersonPopup.show = false" hideCloseButton footerSeparator>
            <div style="max-height: 630px; overflow: auto;">
                <vap-item v-for="(person, i) in persons" :key="i" padding20 @clicked="onPersonSelected(person)">
                    <label style="color: var(--text-accent); font-weight: bold;">
                        {{ person.firstName + ' ' + person.lastName }}
                    </label>
                    <label v-if="person.dateOfBirth" style="color: darkgray; font-weight: bold; margin-left: 7px;">
                        {{ '(' + person.dateOfBirth + ')' }}
                    </label>
                    <br>
                    <!-- <div v-html="getDisplayRoles(person)" /> -->
                </vap-item>
            </div>
            <template v-slot:footer>
                <vap-item fancy fancyCircle padding20 :fancyIcon="'add'"
                    :fancyText="__('recipient_list_missing')"
                    :fancyHint="__('list_add_manually')"
                    @clicked="onPersonSelected(undefined)">
                </vap-item>
            </template>
        </ve-popup>

    </div>
</template>

<script>
import locale from '../locale';
import backend from '../backend';
import api from '../portalapi';
import verified from '../verified';
import v2lib from '../v2lib';
import aml from '../aml';
import tools from '../tools';
import log from '../log';
import kychelper from '../helpers/kychelper';
import comparison from '../helpers/comparison';
import dataModel from '../dataModel';
import dataHelper from '../dataHelper';
import dataNorway from '../dataNorway';

import CompanyInputs from '../components/kyc/CompanyInputs';
import CompanyInputsF from '../components/kyc/CompanyInputsF';
import CompanyInputsT from '../components/kyc/CompanyInputsT';
import RoleInputs from '../components/kyc/RoleInputs';
import EntityInputs from '../components/kyc/EntityInputs';
import CardSwitch from '../components/kyc/CardSwitch';

import HorizontalStepper from '../components/kyc/HorizontalStepper';
import Recipient from '../components/kyc/Recipient';
import RecipientSelect from '../components/kyc/RecipientSelect';
import RecipientList from '../components/kyc/RecipientList';
import Checkout from '../components/kyc/Checkout';
import FormRenderer from '../components/FormRenderer';
import Preview from '../components/documents/Preview';
import personhelper from '../helpers/personhelper';

export default {
    name: 'KYC',
    components: {
        HorizontalStepper,
        Recipient,
        RecipientList,
        RecipientSelect,
        Checkout,
        CompanyInputs,
        CompanyInputsF,
        CompanyInputsT,
        RoleInputs,
        EntityInputs,
        CardSwitch,
        FormRenderer,
        Preview
    },
    data() {
        return {
            loadingKYC: true,
            kycKey: 'kyc1',
            kycIndex: 0,
            customer: undefined,
            steps: [],
            stepKey: '',
            editor: false,
            kycTranslations: {},
            kycMetadata: {
                completed: false, aborted: false, signed: false, expired: false,
                editor: { id: '', firstName: '', lastName: '', email: '', displayName: '', completed: false },
                aborter: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
                nextSigner: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
                signatories: [],
                signedNames: '',
                expirationDate: undefined,
                envelopeId: '', documentId: '', fileUid: '',
                editorIsNextSigner: false
            },
            kycSubmitError: false,
            checkout: {
                initalSelect: false,
                initalReceivers: [],
                receivers: [], // Holds the external receiver (editor)
                internals: [], // Holds internal receivers
                signers: [],
                persons: [],
                contacts: [],
                controls: []
            },
            checkoutData: {
                ready: false,
                signDirect: false,
                recipients: []
            },
            entityFiles: {},
            entityExisting: [],
            bookFiles: {},
            bookExisting: [],
            bookFilesEx: {},
            bookExistingEx: [],
            tasksFiles: {},
            tasksPreviewName: 'Oppdragsavtale',
            tasksPreviewData: undefined,
            bookPreviewName: 'Aksjeeierbok',
            bookPreviewData: undefined,
            loadedCards: {},
            originalPeps: [],
            originalAttachments: [],
            rrhPopup: { show: false, alreadyShown: false },
            persons: [],
            ud: {
                amlData: null,
                amlOriginalData: null,
                amlTimestamp: '',
                brregData: {},
                bisnodeData: {},
                trapetsData: {},
                companyData: {},
                manualData: {},
                ownerQuestion: {
                    noOwners: false,
                    hasOwners: false,
                    public: false
                },
                entityQuestion: {
                    manual: false,
                    file: false
                },
                bookQuestion: {
                    upload: false,
                    later: false,
                    internal: false
                },
                roles: [],
                rrhs: [],
                entities: [],
                peps: [],
                includeAA: true,
                attachments: [],
                changes: null
            },
            envelope: undefined,
            completed: false,
            isFetchingSignLink: false,
            selectMethod: { show: false, value: '', working: false },
            methodOptions: [],
            roleOptions: [],
            selectRolePopup: { show: false },
            selectPersonPopup: { show: false, type: '', role: '' },
        }
    },
    watch: {
        checkout: {
            deep: true,
            handler(val) {
                if (!this.editor) {
                    // Check if there is a receiver
                    let e = val.receivers.length > 0 && val.receivers[0].fname && val.receivers[0].lname && val.receivers[0].email;
                    this.enableStep('checkout', e);
                    let message = e ? '' : 'Legg til en mottaker';
                    this.setStepMessage('checkout', message);
                }
            }
        },
        checkoutData: {
            deep: true,
            handler(val) {
                if (this.editor) {
                    if (val.ready) {
                        this.enableStep('checkout', true);
                    }else {
                        this.enableStep('checkout', false);
                    }
                }
            }
        }
    },
    methods: {
        onStepKeyChanged(nav) {
            //console.log('key changed');
            let result = this.$refs[this.stepKey].validate();
            if (nav.direction != 'prev' && !result) return;
            if (nav.skipping && !this.$root.debug.local) return;

            //this.addContactInfoToPersons();
            this.processInputData();
            this.updatePersonsFromList();

            log.suppress = true;
            this.ud.changes = comparison.compareData(this.ud.amlOriginalData, this.ud);
            log.x('KYC: Data compared with result:', this.ud.changes);
            log.suppress = false;
            this.showBook(this.ud.changes.changed);

            this.stepKey = nav.key;

            if (this.stepKey == 'rrh') {
                this.validateStep();
            }
        },
        enableStep(key, value) {
            for (let i in this.steps) {
                if (this.steps[i].key == key) {
                    this.steps[i].nextDisabled = !value;
                }
            }
        },
        setStepMessage(key, message) {
            for (let i in this.steps) {
                if (this.steps[i].key == key) {
                    this.steps[i].message = message;
                }
            }
        },
        validateStep() {
            this.$nextTick(() => {
                if (this.editor && !this.$root.debug.skipValidation) this.delayedValidate();
            });
        },
        delayedValidate() {
            if (this.stepKey == 'rrh') {
                //console.log('validating rrh');
                let passed = false;
                let message = '';

                if (this.ud.ownerQuestion.noOwners) {
                    passed = true;
                }else if (this.ud.ownerQuestion.public) {
                    passed = true;
                }else {

                    if (this.ud.rrhs.length > 0) {
                        let result = this.$refs[this.stepKey].validate();
                        if (result) {
                            passed = true;
                        }else {
                            message = 'Fyll inn påkrevd informasjon';
                        }
                    }else {
                        message = 'Legg til reelle rettighetshavere';
                    }

                }
                this.enableStep(this.stepKey, passed);
                if (!passed) this.setStepMessage(this.stepKey, message);
            }
        },
        showSelectRolePopup() {
            this.selectRolePopup.show = true;
        },
        showSelectPersonPopup(role, type) {
            this.processInputData();
            this.selectRolePopup.show = false;

            this.selectPersonPopup.type = type;
            this.selectPersonPopup.role = role;
            this.selectPersonPopup.show = true;
        },
        onPersonSelected(person) {
            if (this.selectPersonPopup.type == 'rrh') {
                if (!person) {
                    this.ud.rrhs.push({ added: true, edit: true, needsRole: false, roleId: 'screening_role_rrh', phone: { prefix: '+47', number: '' } });
                }else {
                    this.ud.rrhs.push({
                        added: true, edit: true, needsRole: false,
                        roleId: this.selectPersonPopup.role.value,
                        firstName: person.firstName,
                        lastName: person.lastName,
                        dateOfBirth: person.dateOfBirth,
                        address: person.address,
                        zip: person.zip,
                        city: person.city,
                        country: person.country,
                        email: person.email,
                        pepQuestion: person.pepQuestion,
                        phone: person.phone
                    });
                }
            }else {
                if (!person) {
                    this.ud.roles.push({ added: true, edit: true, needsRole: false, roleId: this.selectPersonPopup.role.value, phone: { prefix: '+47', number: '' } });
                }else {
                    this.ud.roles.push({
                        added: true, edit: true, needsRole: false,
                        roleId: this.selectPersonPopup.role.value,
                        firstName: person.firstName,
                        lastName: person.lastName,
                        dateOfBirth: person.dateOfBirth,
                        address: person.address,
                        zip: person.zip,
                        city: person.city,
                        country: person.country,
                        email: person.email,
                        pepQuestion: person.pepQuestion,
                        phone: person.phone
                    });
                }
            }
            
            this.selectPersonPopup.show = false;
        },
        processInputData() {
            this.persons = [];
            for (const i in this.ud.roles) {
                if (personhelper.isValidPerson(this.ud.roles[i])) {
                    this.addPersonToList(this.ud.roles[i], this.ud.roles[i].roleId);
                }
            }
            for (const i in this.ud.rrhs) {
                if (personhelper.isValidPerson(this.ud.rrhs[i])) {
                    this.addPersonToList(this.ud.rrhs[i], 'screening_role_rrh');
                }
            }
            log.x('KYC: Person list updated', this.persons);
            if (this.editor) {
                const inputData = {
                    brregData: this.ud.brregData,
                    manualData: this.ud.manualData,
                    ownerQuestion: this.ud.ownerQuestion,
                    entityQuestion: this.ud.entityQuestion,
                    bookQuestion: this.ud.bookQuestion,
                    roles: this.ud.roles,
                    rrhs: this.ud.rrhs,
                    entities: this.ud.entities,
                    peps: this.ud.peps
                };
                localStorage.setItem('kyc' + this.$parent.loadedEnvelope.id, JSON.stringify(inputData));
                log.x('KYC: Inputdata saved to localStorage (kyc' + this.$parent.loadedEnvelope.id + ')' , inputData);
            }
        },
        addPersonToList(person, roleId) {
            let match = false;
            for (const i in this.persons) {
                if (personhelper.isSamePerson(this.persons[i], person)) {
                    match = true;
                    this.persons[i].roleIds.push(roleId);
                }
            }
            if (!match) {
                const newperson = {
                    firstName: tools.titleCase(person.firstName),
                    lastName: tools.titleCase(person.lastName),
                    dateOfBirth: person.dateOfBirth,
                    address: person.address,
                    zip: person.zip,
                    city: person.city,
                    country: person.country,
                    email: person.email,
                    pepQuestion: person.pepQuestion,
                    phone: person.phone,
                    roleIds: [roleId]
                };
                this.persons.push(newperson);
            }
        },
        showBook(show) {
            if (this.customer.flow == 'org-manual') {
                show = true;
            }
            for (const i in this.steps) {
                if (this.steps[i].key == 'book') {
                    this.steps[i].hidden = !show;
                }
            }
        },
        setInitialReceiver(value) {
            this.checkout.initalReceivers.push(value);
            this.checkout.initalSelect = true;
        },
        addRole() {
            this.processInputData();
            this.ud.roles.push({ added: true, edit: true, needsRole: true, phone: { prefix: '+47', number: '' } });
        },
        setRole() {
            this.processInputData();
            this.$forceUpdate();
        },
        addRRH() {
            this.processInputData();
            if (this.customer.flow != 'org-no-baml') {
                this.showSelectPersonPopup('screening_role_rrh', 'rrh');
                return;
            }
            this.ud.rrhs.push({ added: true, edit: true, phone: { prefix: '+47', number: '' } });
            this.validateStep();
        },
        removeRRH(index) {
            this.ud.rrhs.splice(index, 1);
            this.processInputData();
            this.validateStep();
        },
        onRRHRadioChanged() {
            if (this.ud.ownerQuestion.noOwners && !this.rrhPopup.alreadyShown) {
                this.rrhPopup.show = true;
                this.rrhPopup.alreadyShown = true;
            }
            this.validateStep();
        },
        addEntity(type) {
            this.ud.entities.push({ added: true, edit: true, type: type });
        },
        getSigningMethodName(method) {
            for (const i in this.methodOptions) {
                if (this.methodOptions[i].value == method) return this.methodOptions[i].display;
            }
            return method;
        },
        async changeSigningMethod(method) {
            this.selectMethod.working = true;
            await verified.setSigningMethod(this.kycMetadata.envelopeId, this.kycMetadata.nextSigner.id, method.value);
            this.selectMethod.working = false;
            this.selectMethod.show = false;
            location.reload();
        },
        async goToSigning() {
            this.isFetchingSignLink = true;
            // Get the sign token
            const envelope = { id: this.envelope.id, metadata: { bearerToken: v2lib.editorToken }, flowName: this.envelope.flowName };
            console.log('en', this.envelope)
            const result = await v2lib.getSignLink(this.$root, envelope, false);
            if (result.ok) {
                window.location.href = result.url;
            }else {
                const contactMail = '';
                if (this.$root.host == 'https://aml-portal-deloitte.web.verified.eu') contactMail = 'aml@deloitte.no';
                this.$root.exceptions.push({
                    message: contactMail ? 'Det oppstod en feil med generering av dokument for signering. Vennligst kontakt oss på ' + contactMail + ' for å løse problemet.' : 'Det oppstod en feil med generering av dokument for signering. Vennligst kontakt oss for å løse problemet.',
                    info: {
                        source: 'KYC goToSigning',
                        url: this.$route.fullPath,
                        envelopeId: this.$parent.loadedEnvelope.id
                    }
                });
            }
            this.isFetchingSignLink = false;
        },
        processPeps() {
            this.ud.peps = [];
            for (let i in this.ud.roles) {
                if (this.ud.roles[i].pepQuestion && this.ud.roles[i].pepQuestion.yes) {
                    if (!this.pepExists(this.ud.roles[i].firstName, this.ud.roles[i].lastName, this.ud.roles[i].dateOfBirth)) {
                        this.ud.peps.push({
                            added: true,
                            edit: true,
                            firstName: this.ud.roles[i].firstName,
                            lastName: this.ud.roles[i].lastName,
                            dateOfBirth: this.ud.roles[i].dateOfBirth,
                            role: this.ud.roles[i].role,
                            roleId: this.ud.roles[i].roleId,
                            email: this.ud.roles[i].email,
                            phone: this.ud.roles[i].phone,
                            address: this.ud.roles[i].address,
                            zip: this.ud.roles[i].zip,
                            city: this.ud.roles[i].city,
                            country: this.ud.roles[i].country,
                            title: this.getTitleFromExistingPep(this.ud.roles[i].firstName, this.ud.roles[i].lastName, this.ud.roles[i].dateOfBirth)
                        });
                    }else {
                        //console.log('pep already exists');
                    }
                }
            }

            for (let i in this.ud.rrhs) {
                if (this.ud.rrhs[i].pepQuestion && this.ud.rrhs[i].pepQuestion.yes) {
                    if (!this.pepExists(this.ud.rrhs[i].firstName, this.ud.rrhs[i].lastName, this.ud.rrhs[i].dateOfBirth)) {
                        this.ud.peps.push({
                            added: true,
                            edit: true,
                            firstName: this.ud.rrhs[i].firstName,
                            lastName: this.ud.rrhs[i].lastName,
                            dateOfBirth: this.ud.rrhs[i].dateOfBirth,
                            role: 'Reell rettighetshaver',
                            roleId: 'screening_role_rrh',
                            email: this.ud.rrhs[i].email,
                            phone: this.ud.rrhs[i].phone,
                            address: this.ud.rrhs[i].address,
                            zip: this.ud.rrhs[i].zip,
                            city: this.ud.rrhs[i].city,
                            country: this.ud.rrhs[i].country,
                            title: this.getTitleFromExistingPep(this.ud.rrhs[i].firstName, this.ud.rrhs[i].lastName, this.ud.rrhs[i].dateOfBirth)
                        });
                    }else {
                        //console.log('pep already exists');
                    }
                }
            }

            // Add/remove the PEP step to stepper
            for (const i in this.steps) {
                if (this.steps[i].key == 'pep') {
                    this.steps[i].hidden = (this.ud.peps.length == 0);
                }
            }

        },
        pepExists(firstName, lastName, dateOfBirth) {
            for (let i in this.ud.peps) {
                if (this.ud.peps[i].firstName == firstName && this.ud.peps[i].lastName == lastName && this.ud.peps[i].dateOfBirth == dateOfBirth) return true;
            }
            return false;
        },
        getTitleFromExistingPep(firstName, lastName, dateOfBirth) {
            try {
                for (let i in this.originalPeps) {
                    if (this.originalPeps[i].firstName == firstName && this.originalPeps[i].lastName == lastName && this.originalPeps[i].dateOfBirth == dateOfBirth) {
                        return this.originalPeps[i].title;
                    }
                }
                return '';
            }catch (ex) {
                return '';
            }
        },
        updatePersonsFromList() {
            try {
                for (const i in this.ud.rrhs) {
                    for (const j in this.persons) {
                        if (this.isSamePersonCheck(this.ud.rrhs[i], this.persons[j])) {
                            
                            if (this.ud.rrhs[i].firstName == 'Frank') {
                                console.log('match',this.ud.rrhs[i]);
                                console.log('match',this.persons[j]);
                            }

                            if (!this.ud.rrhs[i].email && this.persons[j].email) this.$set(this.ud.rrhs[i], 'email', this.persons[j].email);
                            if ((!this.ud.rrhs[i].pepQuestion || (!this.ud.rrhs[i].pepQuestion.yes) && !this.ud.rrhs[i].pepQuestion.no) && this.persons[j].pepQuestion) this.$set(this.ud.rrhs[i], 'pepQuestion', this.persons[j].pepQuestion);

                        }
                    }
                }
            }catch (ex) {
                log.e('KYC: ERROR updatePersonsFromList:', ex.message);
            }
        },
        isSamePersonCheck(person1, person2) {
            if (!person1 || !person1.firstName || !person1.lastName || !person1.dateOfBirth) return false;
            if (!person2 || !person2.firstName || !person2.lastName || !person2.dateOfBirth) return false;
            if (person1.firstName.toLowerCase() == person2.firstName.toLowerCase() 
            && person1.lastName.toLowerCase() == person2.lastName.toLowerCase() 
            && person1.dateOfBirth.toLowerCase() == person2.dateOfBirth.toLowerCase()) {
                return true;
            }
            return false;
        },
        addContactInfoToPersons() {
            console.log('yes');
            try {
                for (let i in this.ud.roles) {
                    if (this.ud.roles[i].email && this.ud.roles[i].email.length > 3) {
                        this.addEmailToPerson(this.ud.roles[i].firstName, this.ud.roles[i].lastName, this.ud.roles[i].dateOfBirth, this.ud.roles[i].email);
                    }
                    if (this.ud.roles[i].pepQuestion && this.ud.roles[i].pepQuestion.yes) {
                        this.updateRRHPEP(this.ud.roles[i].firstName, this.ud.roles[i].lastName, this.ud.roles[i].dateOfBirth, this.ud.roles[i].pepQuestion);
                    }
                }
            }catch (ex) {
                log.e('KYC: ERROR addContactInfoToPersons:', ex.message);
            }
        },
        addEmailToPerson(fname, lname, dob, email) {
            try {
                for (let i in this.checkout.persons) {
                    if (!this.checkout.persons[i].firstName || !this.checkout.persons[i].lastName || this.checkout.persons[i].dateOfBirth) continue;
                    if (this.checkout.persons[i].firstName.toLowerCase() == fname.toLowerCase() 
                    && this.checkout.persons[i].lastName.toLowerCase() == lname.toLowerCase() 
                    && this.checkout.persons[i].dateOfBirth.toLowerCase() == dob.toLowerCase()) {
                        if (!this.checkout.persons[i].email) {
                            this.$set(this.checkout.persons[i], 'email', email);
                        }
                    }
                }
                for (let i in this.ud.rrhs) {
                    if (!this.ud.rrhs[i].firstName || !this.ud.rrhs[i].lastName || this.ud.rrhs[i].dateOfBirth) continue;
                    if (this.ud.rrhs[i].firstName.toLowerCase() == fname.toLowerCase() 
                    && this.ud.rrhs[i].lastName.toLowerCase() == lname.toLowerCase() 
                    && this.ud.rrhs[i].dateOfBirth.toLowerCase() == dob.toLowerCase()) {
                        if (!this.ud.rrhs[i].email) {
                            this.$set(this.ud.rrhs[i], 'email', email);
                        }
                    }
                }
            }catch(ex) {
                log.e('KYC: ERROR addEmailToPerson:', ex.message);
            }
        },
        updateRRHPEP(fname, lname, dob, pep) {
            try {
                for (let i in this.ud.rrhs) {
                    if (!this.ud.rrhs[i].firstName || !this.ud.rrhs[i].lastName || this.ud.rrhs[i].dateOfBirth) continue;
                    if (this.ud.rrhs[i].firstName.toLowerCase() == fname.toLowerCase() 
                    && this.ud.rrhs[i].lastName.toLowerCase() == lname.toLowerCase() 
                    && this.ud.rrhs[i].dateOfBirth.toLowerCase() == dob.toLowerCase()) {
                        this.$set(this.ud.rrhs[i], 'pepQuestion', pep);
                        //console.log('pep set to ' + pep);
                    }
                }
                this.$forceUpdate();
            }catch(ex) {
                log.e('KYC: ERROR updateRRHPEP:', ex.message);
            }
            
        },
        processAmlData(data) {
            if (this.ud.roles.length > 0) {
                for (let i in this.ud.roles) {
                    if (this.ud.roles[i].zip && this.ud.roles[i].zip.length == 3) this.ud.roles[i].zip = '0' + this.ud.roles[i].zip;
                    this.$set(this.ud.roles[i], 'phone', { prefix: '+47', number: '' });
                    this.$set(this.ud.roles[i], 'edit', false);
                    this.$set(this.ud.roles[i], 'pepQuestion', { yes: false, no: false });
                    for (let cc in this.$root.countriesJson) {
                        if (this.$root.countriesJson[cc].key == this.ud.roles[i].country) {
                            this.ud.roles[i].country = this.$root.countriesJson[cc].nb_NO;
                        }
                    }
                }
            }
            if (this.ud.rrhs.length > 0) {
                this.ud.ownerQuestion.hasOwners = true;
                for (let i in this.ud.rrhs) {
                    if (this.ud.rrhs[i].zip && this.ud.rrhs[i].zip.length == 3) this.ud.rrhs[i].zip = '0' + this.ud.rrhs[i].zip;
                    this.$set(this.ud.rrhs[i], 'phone', { prefix: '+47', number: '' });
                    this.$set(this.ud.rrhs[i], 'edit', false);
                    this.$set(this.ud.rrhs[i], 'pepQuestion', { yes: false, no: false });
                    for (let cc in this.$root.countriesJson) {
                        if (this.$root.countriesJson[cc].key == this.ud.rrhs[i].country) {
                            this.ud.rrhs[i].country = this.$root.countriesJson[cc].nb_NO;
                        }
                    }
                }
            }
            if (this.ud.entities.length > 0) {
                this.ud.entityQuestion.manual = true;
                for (let i in this.ud.entities) this.$set(this.ud.entities[i], 'edit', false);
            }
        },
        processEditorData() {
            for (const i in this.ud.peps) {
                //console.log('pep', this.ud.peps[i]);
                for (const j in this.ud.roles) {
                    if (tools.isSamePerson(this.ud.peps[i], this.ud.roles[j])) {
                        this.$set(this.ud.roles[j], 'title', this.ud.peps[i].title || '');
                        this.$set(this.ud.roles[j], 'pepQuestion', { yes: true, no: false });
                        //console.log('pep set for ' + this.ud.roles[j].firstName, this.ud.roles[j]);
                    }
                }
                for (const j in this.ud.rrhs) {
                    if (tools.isValidPerson(this.ud.rrhs[j]) && tools.isSamePerson(this.ud.peps[i], this.ud.rrhs[j])) {
                        this.$set(this.ud.rrhs[j], 'title', this.ud.peps[i].title || '');
                        this.$set(this.ud.rrhs[j], 'pepQuestion', { yes: true, no: false });
                        //console.log('pep set for ' + this.ud.rrhs[j].firstName, this.ud.rrhs[j]);
                    }
                }
            }
        },
        async downloadAttachments(envelope, attachments, needsUpload) {
            if (!attachments) return;
            log.v('KYC: Downloading ' + attachments.length + ' attachments', attachments);
            for (let i in attachments) {
                let match = false;
                for (let j in envelope.content.documents[0].files) {
                    for (let k in envelope.content.documents[0].files[j]) {
                        if (envelope.content.documents[0].files[j][k].name == attachments[i].name) {
                            match = true;
                            try {
                                const url = await verified.getDownloadUrl(envelope.id, envelope.content.documents[0].id, envelope.content.documents[0].files[j][k].uid);
                                const data = await verified.downloadFile(url);
                                this.onAttachmentDownloaded(attachments[i], data, needsUpload);
                                log.s('KYC: Attachment (' + attachments[i].type + ') ' + attachments[i].name + ' downloaded');
                            }catch (ex) {
                                log.e('KYC: Could not download attachment', ex);
                            }
                        }
                    }
                }
                if (!match) log.e('KYC: Attachment does not exist in envelope', attachments[i]);
            }
        },
        onAttachmentDownloaded(attachment, data, needsUpload) {
            if (attachment.type == 'ostructure') {
                this.entityExisting.push({
                    name: attachment.name,
                    size: attachment.size,
                    fileType: attachment.fileType,
                    uploaded: true,
                    autoPreview: true,
                    data: data
                });
            }else if (attachment.type == 'exshbook') {
                this.bookPreviewName = attachment.name;
                this.bookPreviewData = data;
            }else {
                this.tasksPreviewName = attachment.name;
                this.tasksPreviewData = data;
                for (const i in this.steps) {
                    if (this.steps[i].key == 'aagree') {
                        this.steps[i].hidden = false;
                    }
                }
            }
            if (needsUpload) {
                this.originalAttachments.push({
                    attachment: attachment,
                    data: data
                });
            }
        },
        async onSubmit() {
            this.$parent.setLoading(true, this.__('loading_creating_form') + '...');

            this.ud.questions = this.loadedCards;

            try {
                // If this is a re-send, reset the KYC object on the customer
                if (this.$route.query.eid) await api.archiveData(this.$route.query.id, 'kyc', 'resend');

                if (!this.editor) {
                    
                    // Add receiver to userdata
                    this.ud.receiver = this.checkout.receivers[0];

                    // Set mail-script parameters
                    const emailData = {
                        firstName: this.checkout.receivers[0].fname,
                        lastName: this.checkout.receivers[0].lname,
                        senderName: this.$root.user.givenName + ' ' + this.$root.user.familyName,
                        senderEmail: this.$root.user.email,
                        customerName: this.customer.name
                    };
                    const lang = this.checkout.receivers[0].language;
                    this.ud.mail = kychelper.getMailData(this.$root, this.kycIndex, emailData, lang, false);

                    const useNewFlow = this.$root.config.kycs[this.kycIndex].settings.usev2receipts || false;
                    let descriptor = useNewFlow ? 'aml-portal-v1-kyc' : 'aml-portal-kyc';
                    let flowName = useNewFlow ? 'simple-public-flow-custom-sender' : 'simple-public-flow-with-silent-parties';

                    const useAWSflow = this.$root.isAWS;
                    if (useAWSflow) {
                        descriptor = 'aml-portal-v0-kyc';
                        flowName = 'simple-public-flow-custom-sender';
                        this.ud.mail = kychelper.getMailData2(this.$root, this.kycIndex, emailData, lang);
                    }

                    // Create envelope
                    const envelope = await v2lib.createEnvelope(descriptor, flowName, false);

                    // Now that we have the envelopeId, check if this kyc should use an in-portal landing page
                    if (this.$root.config.kycs[this.kycIndex].settings.landingPage && !useAWSflow) {
                        this.ud.mail.redirect = this.$root.baseUrl + '/kyclanding/' + envelope.id + '?act=' + envelope.bearerToken;
                    }

                    // Set loading
                    this.$parent.setLoading(true, this.__('loading_saving_customer') + '...');
                    // Add KYC data to customer
                    this.customer.kyc = {
                        status: 1,
                        userId: this.$root.credentials.uid,
                        envelopeId: envelope.id,
                        documentId: envelope.documentId,
                        templateId: envelope.templateId,
                        sentDate: new Date()
                    }

                    // Get expiration day from config
                    const expirationDay = kychelper.getExpirationDay(this.$root.config.kycs[this.kycIndex].settings.expirationDays);

                    // Update customer in database and get bearer token
                    const params = { expires: expirationDay };
                    const response = await api.sendOutKYC(this.customer, params);
                    this.ud.credentials = {
                        id: this.customer._id,
                        cid: this.$root.credentials.cid,
                        key: this.$root.credentials.key,
                        bearerToken: response.token
                    }
                    if (this.$root.credentials.testinc) this.ud.credentials.testinc = this.$root.credentials.testinc;
                    
                    this.ud.customer = this.customer;

                    // Set KYC config
                    this.ud.config = { key: this.kycKey, nextStat: 'r-rep-a' };

                    if (!this.ud.attachments) this.ud.attachments = [];

                    // Upload assignment agreement if added
                    for (let i in this.tasksFiles.files) {
                        if (!this.tasksFiles.files[i].uploaded) {
                            this.ud.attachments.push(v2lib.addFileUploadAttachmentToUserdataSafe(this.ud.attachments.length, this.tasksFiles.files[i], 'tasks'));
                            await v2lib.addAttachment(envelope, this.tasksFiles.files[i].name, this.tasksFiles.files[i].data);
                        }
                    }

                    // Upload owner structure if added
                    for (let i in this.entityFiles.files) {
                        if (!this.entityFiles.files[i].uploaded) {
                            this.ud.attachments.push(v2lib.addFileUploadAttachmentToUserdataSafe(this.ud.attachments.length, this.entityFiles.files[i], 'ostructure'));
                            if (this.entityFiles.files[i].existing) {
                                await verified.uploadBase64Attachment(envelope.id, envelope.documentId, this.entityFiles.files[i].data, this.entityFiles.files[i].name);
                            }else {
                                await v2lib.addAttachment(envelope, this.entityFiles.files[i].name, this.entityFiles.files[i].data);
                            }
                        }
                    }

                    // Upload shareholder book if added
                    for (let i in this.bookFilesEx.files) {
                        if (!this.bookFilesEx.files[i].uploaded) {
                            this.ud.attachments.push(v2lib.addFileUploadAttachmentToUserdataSafe(this.ud.attachments.length, this.bookFilesEx.files[i], 'exshbook'));
                            if (this.bookFilesEx.files[i].existing) {
                                await verified.uploadBase64Attachment(envelope.id, envelope.documentId, this.bookFilesEx.files[i].data, this.bookFilesEx.files[i].name);
                            }else {
                                await v2lib.addAttachment(envelope, this.bookFilesEx.files[i].name, this.bookFilesEx.files[i].data);
                            }
                        }
                    }

                    for (const i in this.originalAttachments) {
                        this.ud.attachments.push({
                            type: this.originalAttachments[i].attachment.type,
                            name: this.originalAttachments[i].attachment.name,
                            fileType: this.originalAttachments[i].attachment.fileType,
                            size: this.originalAttachments[i].attachment.size,
                            sizeText: this.originalAttachments[i].attachment.sizeText,
                            number: this.ud.attachments.length
                        });
                        await verified.uploadBase64Attachment(envelope.id, envelope.documentId, this.originalAttachments[i].data, this.originalAttachments[i].attachment.name);
                    }

                    this.ud.actions = {
                        dateSent: new Date(),
                        sender: {
                            firstName: this.$root.user.givenName,
                            lastName: this.$root.user.familyName,
                            email: this.$root.user.email,
                            company: this.$root.user.companyName
                        },
                        receiver: {
                            firstName: this.checkout.receivers[0].fname,
                            lastName: this.checkout.receivers[0].lname,
                            language: this.checkout.receivers[0].language,
                            signingMethod: 'email',
                            email: this.checkout.receivers[0].email,
                        }
                    };

                    // Set loading
                    this.$parent.setLoading(true, this.__('loading_forwarding_form') + '...');

                    // Set metadata
                    const metadata = {
                        silentParties: [],
                        language: 'en_EN'
                    };
                    if (useNewFlow && this.$root.config.kycs[this.kycIndex].customSender && this.$root.config.kycs[this.kycIndex].customSender.firstName) {
                        metadata.sender = {
                            givenName: this.$root.config.kycs[this.kycIndex].customSender.firstName,
                            familyName: this.$root.config.kycs[this.kycIndex].customSender.lastName,
                            email: this.$root.config.kycs[this.kycIndex].customSender.email,
                        };
                        this.ud.actions.sender.firstName = this.$root.config.kycs[this.kycIndex].customSender.firstName;
                        this.ud.actions.sender.lastName = this.$root.config.kycs[this.kycIndex].customSender.lastName;
                        this.ud.actions.sender.email = this.$root.config.kycs[this.kycIndex].customSender.email;
                    }
                    await v2lib.setMetadata(envelope, metadata);

                    if (useAWSflow) {
                        // Strip uneccessary userdata
                        //delete this.ud.amlData;
                        //delete this.ud.amlOriginalData;
                        //delete this.ud.amlTimestamp;
                        //delete this.ud.brregData;
                        //delete this.ud.bisnodeData;
                        //delete this.ud.trapetsData;
                        delete this.ud.companyData.economy;
                    }

                    // Set template userdata and name
                    const documentName = 'Egenerklæring ' + this.customer.name + '.pdf';
                    this.ud.fileName = documentName;
                    console.log('submit ud', this.ud);
                    await v2lib.setUserData(envelope, this.ud);
                    await v2lib.setNameAndTags(envelope, documentName, ['egenerklæring']);
                    await v2lib.setName(envelope, documentName);

                    // Set editor recipient
                    const recipient = {
                        givenName: this.checkout.receivers[0].fname,
                        familyName: this.checkout.receivers[0].lname,
                        language: this.checkout.receivers[0].language,
                        signingMethod: 'email',
                        email: this.checkout.receivers[0].email,
                        order: 1,
                        role: { action: "edit", label: "Fylle i skjemaet", name: "editor" }
                    };
                    await v2lib.addRecipient(envelope, recipient);

                    // Publish the envelope
                    await v2lib.publishEnvelope(envelope);


                }else {
                    // Editor

                    this.$parent.setLoading(true, this.__('kyc_submitting_form') + '...');

                    let envelope = await v2lib.startSubmitEditor(this.envelope.id);
                    log.v('KYC: Submit editor: Envelope initialized', envelope);

                    this.ud._isForwarded = true;
                    this.ud.finalSubmit = true;

                    const descriptorId = envelope.content.descriptor.id;

                    // Upload owner structure if added
                    if (!this.ud.attachments) this.ud.attachments = [];
                    for (let i in this.entityFiles.files) {
                        if (!this.entityFiles.files[i].uploaded) {
                            this.ud.attachments.push({
                                type: 'ostructure',
                                name: this.entityFiles.files[i].name,
                                fileType: this.entityFiles.files[i].type,
                                size: this.entityFiles.files[i].data.size,
                                sizeText: this.entityFiles.files[i].size,
                                number: this.ud.attachments.length
                            });
                            await v2lib.prepareAttachment(envelope, this.entityFiles.files[i].name, this.entityFiles.files[i].data);
                        }
                    }

                    // Upload shareholder book if added
                    if (!this.ud.attachments) this.ud.attachments = [];
                    for (let i in this.bookFiles.files) {
                        if (!this.bookFiles.files[i].uploaded) {
                            this.ud.attachments.push({
                                type: 'shbook',
                                name: this.bookFiles.files[i].name,
                                fileType: this.bookFiles.files[i].type,
                                size: this.bookFiles.files[i].data.size,
                                sizeText: this.bookFiles.files[i].size,
                                number: this.ud.attachments.length
                            });
                            await v2lib.prepareAttachment(envelope, this.bookFiles.files[i].name, this.bookFiles.files[i].data);
                        }
                    }

                    if (!this.ud.actions) {
                        this.ud.actions = { dateFilled: new Date(), signers: [], signerString: '', direct: this.checkoutData.signDirect, portal: false };
                    }else {
                        this.ud.actions.dateFilled = new Date();
                        this.ud.actions.signers = [];
                        this.ud.actions.signerString = '';
                    }

                    // Set recipients
                    let useNemId = false;
                    let usePortal = false;
                    if (this.checkoutData.signDirect) this.ud._isForwarded = false;
                    for (let i in this.checkoutData.recipients) {
                        let role = { action: "sign", label: "Fylle i skjemaet", name: "signer" };
                        let method = this.checkoutData.recipients[i].method;
                        if (this.checkoutData.recipients[i].action == 'approve') role = { action: "review", label: "Godkjenne", name: "reviewer" };
                        // Check if we should use the recipient portal
                        if (method == 'select') {
                            usePortal = true;
                            // If we need to use the recipient portal, we also need to trick the API to use NemID renderer, in case someone selects NemID
                            method = 'nets-esign-dk';
                        }
                        // Check if we need to use NemID renderer
                        if (method == 'nets-esign-dk') useNemId = true;

                        const recipient = {
                            givenName: this.checkoutData.recipients[i].firstName,
                            familyName: this.checkoutData.recipients[i].lastName,
                            language: this.checkoutData.recipients[i].language,
                            signingMethod: method,
                            email: this.checkoutData.recipients[i].email,
                            order: Number(i) + 1,
                            role: role,
                            action: role.action
                        };
                        
                        await v2lib.prepareRecipient(envelope, recipient);
                        this.ud.actions.signers.push(recipient);

                        if (i > 0) this.ud.actions.signerString = this.ud.actions.signerString + ', ';
                        this.ud.actions.signerString = this.ud.actions.signerString + this.checkoutData.recipients[i].firstName + ' ' + this.checkoutData.recipients[i].lastName;
                    }
                    this.ud.actions.portal = usePortal;
                    if (descriptorId != 'aml-portal-v0-kyc') this.ud.mail.select = usePortal;

                    // Add silent parties
/*                     const metadata = {
                        silentParties: [],
                        language: this.language
                    };
                    if (this.$root.config.kycs[this.kycIndex].settings.silentParty) {
                        metadata.silentParties.push({
                            givenName: 'Verified',
                            familyName: 'Egenerklæringsskjema',
                            email: this.$root.config.kycs[this.kycIndex].settings.silentParty
                        });
                    } */
                    
                    //await v2lib.prepareMetadata(envelope, metadata);

                    this.ud.pdfLanguage = this.$root.locale.key;
                    this.ud.processedQuestions = kychelper.prepareQuestions(this.ud.questions, this.ud.pdfLanguage, this.$root.translations);

                    this.ud.pdfConfig = {
                        logo: this.$root.config.pdf.logoUrl,
                        colors: {
                            headerBack: this.$root.getColor('header'),
                            headerText: this.$root.getColor('header-text')
                        },
                        translations: kychelper.getPdfTranslations(this.$root)
                    };

                    if (descriptorId == 'aml-portal-v0-kyc') {

                        console.log(JSON.stringify(this.ud));

                    }else {
                        // Get KYC template
                        const template = await api.renderTemplate(this.$root, 'kyc', this.ud);
                        this.ud.html = template.html;
                        this.ud.css = template.css;
                    }

                    await v2lib.submitEditor(envelope, this.ud);
                    log.v('KYC: Submit editor: Envelope submitted', this.ud);

                    const submitData = {
                        success: false,
                        pdfError: false,
                        attempts: 0
                    };

                    let result = await v2lib.checkEnvelopeForErrors(envelope);
                    //result.ok = false; // TODO remove please
                    if (!result.ok) {
                        this.$parent.setLoading(true, 'Dette ser ut til å ta litt lengre tid enn vanlig, vennligst vent litt til' + '...');
                        log.e('KYC: Submit editor: PDF not generated', envelope);

                        // PDF was probably not generated - try again with a new envelope
                        v2lib.publicToken = this.$root.config.kycs[this.kycIndex].publicToken;

                        submitData.pdfError = true;

                        let attemptCount = 0;
                        const maxAttempts = 1;
                        let newEnvelope = undefined;
                        while (!result.ok && attemptCount < maxAttempts) {
                            attemptCount += 1;
                            submitData.attempts = attemptCount;
                            newEnvelope = await v2lib.createNewEnvelopeFromExisting(envelope, this.ud);
                            result = await v2lib.checkEnvelopeForErrors(newEnvelope);
                        }
                        if (!result.ok) {
                            log.e('KYC: Submit editor: PDF not generated SECOND attempt', newEnvelope);
                            this.$root.exceptions.push({
                                message: 'Det skjedde en feil under innsending av skjema',
                                info: {
                                    source: 'KYC submit, PDF not generated',
                                    envelopeId: envelope.id,
                                    attempts: attemptCount
                                }
                            });
                            return;
                        }else {
                            submitData.newEnvelope = {
                                envelopeId: newEnvelope.id,
                                documentId: newEnvelope.documentId,
                                templateId: newEnvelope.templateId,
                            };
                            submitData.oldEnvelope = {
                                envelopeId: envelope.id,
                                documentId: envelope.documentId,
                                templateId: envelope.templateId,
                            };
                            envelope = newEnvelope;
                        }
                    }
                    submitData.success = result.ok;

                    // Update customer in database
                    await api.sendInKYC(this.ud.credentials, submitData);

                    // Stop here if there was an error with the envelope
                    if (!result.ok) return;


                    // Filter based on question logic
                    let uploadFilter = false;
                    let filterValue1 = 'ser_none';
                    let filterValue2 = 'na';
                    for (const i in this.ud.questions.cards) {
                        for (const j in this.ud.questions.cards[i].inputs) {
                            if (this.ud.questions.cards[i].inputs[j].filter) {
                                if (this.ud.questions.cards[i].inputs[j].filterKey == 'services') {
                                    uploadFilter = true;
                                    const model = this.ud.questions.cards[i].inputs[j].model;
                                    if (model['x1179'] && !model['x1180']) {
                                        filterValue1 = 'ser_prop';
                                    }else if (model['x1180'] && !model['x1179']) {
                                        filterValue1 = 'ser_sec';
                                    }else if (model['x1180'] && model['x1179']) {
                                        filterValue1 = 'ser_both';
                                    }
                                }else if (this.ud.questions.cards[i].inputs[j].filterKey == 'reclassed') {
                                    uploadFilter = true;
                                    const model = this.ud.questions.cards[i].inputs[j].model;
                                    if (model['x1040']) {
                                        filterValue2 = 'cla_yes';
                                    }else if (model['x1041']) {
                                        filterValue2 = 'cla_no';
                                    }else {
                                        filterValue2 = 'cla_na';
                                    }
                                }
                            }
                        }
                    }
                    if (uploadFilter) {
                        const filterData = {
                            status1: filterValue1,
                            status2: filterValue2
                        }
                        await api.updateCustomerStatus(this.ud.credentials.id, filterData);
                    }


                    if (this.ud._isForwarded) {
                        // Redirect to thank you page
                        window.location.href = this.$root.baseUrl + '/kyclanding/editor/' + envelope.id + '?act=' + envelope.metadata.bearerToken;
                    }else {
                        // Get the sign token
                        let result = null;
                        if (descriptorId == 'aml-portal-v0-kyc') {
                            result = await v2lib.getSignLinkAWS(this.$root, envelope, this.ud.mail);
                            sessionStorage.setItem(envelope.id, v2lib.editorToken);
                        }else {
                            result = await v2lib.getSignLink(this.$root, envelope, false);
                        }
                        if (result.ok) {
                            window.location.href = result.url;
                        }else {
                            this.$root.exceptions.push({
                                message: 'Det skjedde en feil under innsending av skjema',
                                info: {
                                    source: 'KYC submit, error getting sign link',
                                    envelopeId: envelope.id
                                }
                            });
                            return;
                        }
                    }
                    return;
                }
            }catch (ex) {
                // Error while creating/updating envelope
                this.$root.exceptions.push(ex);
                console.log(ex);
                return;
            }

            this.$parent.navigate({ path: '/client', query: { id: this.customer._id } });
            this.$parent.setLoading(false);
        }
    },
    async created() {
        this.$parent.setLoading(true, this.__('loading_form') + '...');
        log.v('KYC: View created');
        this.$parent.setTitle('Egenerklæringsskjema');

        // Set KYC index
        this.kycKey = this.$route.query.key;
        this.kycIndex = 0; // TODO <-

        // Load KYC translations and merge with main
        this.kycTranslations = locale.getDefaultKycTranslations();
        const tres = await api.getTranslations('kyc');
        locale.mergeTranslations(this.$root, this.kycTranslations, tres.texts);
        this.$root.translations = { ...this.$root.translations, ...this.kycTranslations };

        this.$parent.headerTitle = this.__('kyc_title');

        // Get available signing methods - used incase editor follows the editor-link after filling in the form, but before signing
        this.methodOptions = verified.getAvailableSigningMethods(this.$root.config.kycs[0].methods, this.$root.debug.local);

        // Check if this is owner or editor
        if (this.$route.query.document_uid && this.$route.query.access_token) {
            log.w('KYC: Loading editor');
            this.editor = true;

            this.envelope = this.$parent.loadedEnvelope;
            this.ud = { ...this.ud, ...this.$parent.loadedEnvelope.userdata };
            this.customer = this.ud.customer;
/*             const cres = await api.getCustomer(this.ud.customer._id);
            this.customer = cres.customer; */
            this.originalPeps = this.$parent.loadedEnvelope.userdata.peps;

            console.log('envelope', this.envelope);

            if (this.customer.kyc.submit && this.customer.kyc.submit.pdfError) {
                this.kycSubmitError = true;
            }

            kychelper.getMetadata(this.customer, this.$parent.loadedEnvelope, this.kycMetadata);
            log.v('KYC: Metadata loaded', this.kycMetadata);

            if (this.ud.amlData) this.checkout.persons = aml.getPersonList(this.ud.amlData);
            for (let i in this.checkout.persons) {
                this.checkout.persons[i].firstName = aml.titleCase(this.checkout.persons[i].firstName);
                this.checkout.persons[i].lastName = aml.titleCase(this.checkout.persons[i].lastName);
            }

            this.loadedCards = this.ud.questions;

            if (localStorage.getItem('kyc' + this.$parent.loadedEnvelope.id)) {
                const inputData = JSON.parse(localStorage.getItem('kyc' + this.$parent.loadedEnvelope.id));
                log.w('KYC: Inputdata loaded from localStorage', inputData);
            }

            this.downloadAttachments(this.envelope, this.ud.attachments, false);
            this.processEditorData();

        }else {
            log.w('KYC: loading owner');

            // Load customer
            const customerId = this.$route.query.id;
            const cres = await api.getCustomer(customerId);
            this.customer = cres.customer;

            // Load AML/brreg data
            if (this.customer && this.customer.aml) {
                this.$parent.setLoading(true, this.__('loading_aml') + '...');

                // Load AML/brreg from receipt-envelope userdata
                const envelope = await this.$root.getEnvelope(this.customer.aml.envelopeId);
                this.ud.amlData = envelope.userdata.aml;
                this.ud.brregData = envelope.userdata.brreg;
                this.ud.trapetsData = envelope.userdata.trapetsData;
                this.ud.companyData = envelope.userdata.companyData;
                if (envelope.userdata.aml) this.ud.amlOriginalData = JSON.parse(JSON.stringify(envelope.userdata.aml));
                if (envelope.userdata.aml) delete this.ud.amlOriginalData.token;

                // Process AML data
                this.ud.amlTimestamp = tools.dateString(this.customer.aml.searchDate);
                if (this.ud.amlData) {
                    const companyData = dataModel.getEmptyModel();
                    dataNorway.processData(this.$root, companyData, this.ud.amlData, this.ud.brregData);

                    this.ud.roles = dataHelper.getKeyRoleList(this.$root.config.kycs[this.kycIndex].roles, companyData);
                    this.ud.rrhs = dataHelper.getBeneficialList(companyData);
                    this.ud.entities = dataHelper.getShareholderList(companyData);

                }else if (this.ud.companyData) {
                    this.ud.roles = dataHelper.getKeyRoleList(this.$root.config.kycs[this.kycIndex].roles, this.ud.companyData);
                    this.ud.rrhs = dataHelper.getBeneficialList(this.ud.companyData);
                    this.ud.entities = dataHelper.getShareholderList(this.ud.companyData);
                }
                
                this.processAmlData(this.ud.amlData);

                log.s('KYC: Data loaded from aml-receipt', this.ud.amlData);
            }else {
                // No AML data, load data from brreg instead
                this.ud.brregData = await aml.getBrregFromNumber(this.customer.org_no);
                log.w('KYC: Data loaded from brreg');
            }

            const response = await api.getQuestions('kyc1');
            if (response.questions) {
                this.loadedCards = response.questions;
            }else {
                this.loadedCards = {};
            }

            kychelper.autofillQuestions(this.loadedCards, this.ud.amlData);

            // Check if this is a resend-attempt and load the existing data
            if (this.$route.query.eid) {
                log.w('KYC: Loading existing userdata');
                const envelope = await this.$root.getEnvelope(this.$route.query.eid);
                log.w('KYC: Existing data:', envelope);

                this.ud.brregData = envelope.userdata.brregData;
                this.ud.roles = envelope.userdata.roles;
                this.ud.rrhs = envelope.userdata.rrhs;
                this.ud.ownerQuestion = envelope.userdata.ownerQuestion;
                this.ud.entities = envelope.userdata.entities;
                this.ud.entityQuestion = envelope.userdata.entityQuestion;
                this.originalPeps = envelope.userdata.peps;
                this.loadedCards = envelope.userdata.questions;

                this.downloadAttachments(envelope, envelope.userdata.attachments, true);
            }
        }

        // Load steps
        this.steps = kychelper.getSteps(this, this.$root.config.kycs[this.kycIndex].steps);
        this.stepKey = this.steps[0].key;

        this.roleOptions = [];
        try {
            const allRoles = aml.getAllPossibleRoles();
            for (const i in allRoles) {
                this.roleOptions.push({
                    value: allRoles[i],
                    nb_NO: this.$root.translations[allRoles[i]]['nb_NO'],
                    en_EN: this.$root.translations[allRoles[i]]['en_EN'],
                    sv_SE: this.$root.translations[allRoles[i]]['sv_SE'],
                    de_DE: this.$root.translations[allRoles[i]]['de_DE'],
                });
            }
            //console.log('options', this.roleOptions);
        }catch (ex) {

        }

        this.processPeps();

        this.checkout.contacts = this.$root.config.internals;

        this.$parent.setTitle(this.__('kyc_form') + ' - ' + this.customer.name, true);
        this.loadingKYC = false;
        this.$parent.setLoading(false);
    }
}
</script>
<style scoped>

.kyc {
    margin-top: 80px;
}

</style>