var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-btnrow",class:[
    {'vf-btnrow-left' : _vm.alignLeft || _vm.alignment == 1},
    {'vf-btnrow-center' : _vm.alignCenter || _vm.alignment == 2},
    {'vf-btnrow-right' : _vm.alignRight || _vm.alignment == 3},
    {'vf-btnrow-designer' : _vm.designer},
    {'vf-btnrow-des' : _vm.designerColor},
    {'vf-btnrow-selected' : _vm.selected},
    {'vf-btnrow-flex' : _vm.flexMode},
    {'vf-btnrow-phone' : _vm.phoneMode} ],on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.handleClick($event)}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }