import log from '../log';
import aml from '../aml';
import tools from '../tools';
import personhelper from './personhelper';
import dataNorway from '../dataNorway';

const CHANGE_REMOVED = 1;
const CHANGE_ADDED = 2;
const CHANGE_PERCENTAGE = 3;

const comparison = {

    compareData(amlData, kycData, includeData) {
        const result = { changed: false, items: [] };
        try {
            log.x('Comparison: Comparing AML:', amlData);
            log.x('Comparison: Comparing KYC:', kycData);

            // Compare RRH
            if (amlData.ownership && amlData.ownership.beneficial) {
                for (const i in amlData.ownership.beneficial) {
                    if (amlData.ownership.beneficial[i].type != 'UNIDENTIFIED') {
                        this.compareRRHfromAML(result, kycData, amlData.ownership.beneficial[i], includeData);
                    }
                }
            }
            for (const i in kycData.rrhs) {
                this.compareRRHfromKYC(result, amlData, kycData.rrhs[i], includeData);
            }

            const keyRoles = aml.getKeyRoleList(amlData);
            // Compare key roles
            for (const i in keyRoles) {
                this.compareRoleFromAML(result, kycData, keyRoles[i], includeData);
            }
            for (const i in kycData.roles) {
                this.compareRoleFromKYC(result, keyRoles, kycData.roles[i], includeData);
            }

            const shareholders = aml.getEntityList(amlData);
            // Compare shareholders
            for (const i in shareholders) {
                this.compareSHfromAML(result, kycData, shareholders[i], includeData);
            }
            for (const i in kycData.entities) {
                this.compareSHfromKYC(result, shareholders, kycData.entities[i], includeData);
            }

        }catch (ex) {
            console.log(ex);
            log.x('Comparison failed');
        }
        return result;
    },

    compareSHfromAML(result, kycData, amlSH, includeData) {
        if (kycData.entityQuestion.file) return;
        let match = false;
        for (const i in kycData.entities) {
            if (this.isSameShareholder(amlSH, kycData.entities[i])) {
                match = true;
                if (amlSH.percentage != kycData.entities[i].percentage) {
                    const data = {
                        displayName: amlSH.name,
                        percentageFrom: amlSH.percentage,
                        percentageTo: kycData.entities[i].percentage,
                        /* identity: tools.encodePerson(amlRRH) */
                    };
                    this.addChange(result, CHANGE_PERCENTAGE, 'sh', includeData ? data : undefined);
                }
            }
        }
        if (!match) {
            let type = 'org';
            let identity = '';
            if (amlSH.type == 'PERSON') {
                type = 'person';
                const names = dataNorway.getBamlNames(amlSH.name);
                const cp = {
                    firstName: names.firstName,
                    lastName: names.lastName,
                    dateOfBirth: amlSH.dateOfBirth,
                };
                identity = tools.encodePerson(cp);
            }
            const data = {
                type: amlSH.type,
                displayName: amlSH.name,
                type: type,
                identity: identity
            };
            this.addChange(result, CHANGE_REMOVED, 'sh', includeData ? data : undefined);
            //console.log('SH REMOVED');
        }
    },

    compareSHfromKYC(result, amlShareholders, kycSH, includeData) {
        let match = false;
        for (const i in amlShareholders) {
            if (this.isSameShareholder(kycSH, amlShareholders[i])) {
                match = true;
            }
        }
        if (!match) {
            let name = kycSH.name;
            if (kycSH.firstName && kycSH.lastName) name = kycSH.firstName + ' ' + kycSH.lastName;
            const data = {
                type: kycSH.type,
                displayName: name,
                /* identity: tools.encodePerson(kycSH) */
            };
            if (kycSH.type == 'PERSON' && kycSH.firstName && kycSH.lastName) {
                data.percentage = kycSH.percentage;
                data.identity = tools.encodePerson(kycSH);
            }
            this.addChange(result, CHANGE_ADDED, 'sh', includeData ? data : undefined);
        }
    },

    compareRRHfromAML(result, kycData, amlRRH, includeData) {
        if (!kycData.ownerQuestion.hasOwners) {
            console.log('amlRRH', amlRRH);
            const data = {
                displayName: amlRRH.firstName + ' ' + amlRRH.lastName,
                identity: tools.encodePerson(amlRRH)
            };
            this.addChange(result, CHANGE_REMOVED, 'rrh', includeData ? data : undefined);
            return;
        }
        let match = false;
        for (const i in kycData.rrhs) {
            if (personhelper.isSamePerson(amlRRH, kycData.rrhs[i])) {
                match = true;
                if (amlRRH.percentageControl != kycData.rrhs[i].percent) {
                    const data = {
                        displayName: amlRRH.firstName + ' ' + amlRRH.lastName,
                        percentageFrom: amlRRH.percentageControl,
                        percentageTo: kycData.rrhs[i].percent,
                        identity: tools.encodePerson(amlRRH)
                    };
                    this.addChange(result, CHANGE_PERCENTAGE, 'rrh', includeData ? data : undefined);
                }
            }
        }
        if (!match) {
            const data = {
                displayName: amlRRH.firstName + ' ' + amlRRH.lastName,
                identity: tools.encodePerson(amlRRH)
            };
            this.addChange(result, CHANGE_REMOVED, 'rrh', includeData ? data : undefined);
        }
    },

    compareRRHfromKYC(result, amlData, kycRRH, includeData) {
        if (!amlData.ownership || !amlData.ownership.beneficial) return;
        let match = false;
        for (const i in amlData.ownership.beneficial) {
            if (amlData.ownership.beneficial[i].type != 'UNIDENTIFIED' && personhelper.isSamePerson(kycRRH, amlData.ownership.beneficial[i])) {
                match = true;
            }
        }
        if (!match) {
            const data = {
                displayName: kycRRH.firstName + ' ' + kycRRH.lastName,
                identity: tools.encodePerson(kycRRH)
            };
            this.addChange(result, CHANGE_ADDED, 'rrh', includeData ? data : undefined);
        }
    },

    compareRoleFromAML(result, kycData, amlRole, includeData) {
        let match = false;
        for (const i in kycData.roles) {
            if (personhelper.isSamePerson(amlRole, kycData.roles[i]) && amlRole.roleId == kycData.roles[i].roleId) {
                match = true;
            }
        }
        if (!match) {
            const data = {
                displayName: amlRole.firstName + ' ' + amlRole.lastName,
                identity: tools.encodePerson(amlRole)
            };
            this.addChange(result, CHANGE_REMOVED, amlRole.roleId, includeData ? data : undefined);
        }
    },

    compareRoleFromKYC(result, amlRoles, kycRole, includeData) {
        let match = false;
        for (const i in amlRoles) {
            if (personhelper.isSamePerson(kycRole, amlRoles[i]) && kycRole.roleId == amlRoles[i].roleId) {
                match = true;
            }
        }
        if (!match) {
            const data = {
                displayName: kycRole.firstName + ' ' + kycRole.lastName,
                identity: tools.encodePerson(kycRole)
            };
            this.addChange(result, CHANGE_ADDED, kycRole.roleId, includeData ? data : undefined);
        }
    },

    addChange(result, change, type, data) {
        result.changed = true;
        result.items.push({
            change: change,
            type: type,
            data: data
        });
    },

    isSameShareholder(shareholder1, shareholder2) {
        if (shareholder1.type == shareholder2.type && shareholder1.type == 'PERSON') {
            if (shareholder2.firstName) {
                const names = this.getBamlNames(shareholder1.name);
                if (names.firstName.toLowerCase() == shareholder2.firstName.toLowerCase()
                && names.lastName.toLowerCase() == shareholder2.lastName.toLowerCase()
                && shareholder1.dateOfBirth == shareholder2.dateOfBirth) {
                    return true;
                }
            }else {
                if (shareholder1.name == shareholder2.name && shareholder1.dateOfBirth == shareholder2.dateOfBirth) return true;
            }
        }else if (shareholder1.type == shareholder2.type && shareholder1.type != 'PERSON') {
            if ((shareholder1.organizationNumber && shareholder1.organizationNumber == shareholder2.organizationNumber) || (shareholder1.name == shareholder2.name)) return true;
        }
        return false;
    },

    getBamlNames(name) {
        let firstName = '';
        let lastName = '';
        if (name.includes(',')) {
            const parts = name.split(', ');
            firstName = parts[1];
            lastName = parts[0];
        }else {
            firstName = (name).split(' ').slice(1).join(' ');
            lastName = (name).split(' ')[0];
        }
        return {
            firstName: this.titleCase(firstName),
            lastName: this.titleCase(lastName)
        };
    },

};
export default comparison;