<template>
<div class="frv2-d-propheader">
    
    <div class="frv2-d-propheader-title">
        {{ header }}
    </div>

    <div class="frv2-d-propheader-actions">
        <slot name="actions" />
    </div>

</div>
</template>
<script>
export default {
    name: 'PropertyHeader',
    props: {
        header: { type: String, default: '[EMPTY HEADER]' },
    },
}
</script>
<style scoped>

.frv2-d-propheader {
    display: flex;
    padding-bottom: 10px;
    border-bottom: solid 1px var(--fb-menu-c);
}

.frv2-d-propheader-title {
    flex-grow: 100;
    margin-top: 15px;
    margin-left: 18px;
    color: var(--fb-text-1);
}

.frv2-d-propheader-actions {
    flex-shrink: 0;
    display: flex;
}

</style>