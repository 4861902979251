import log from '@/log';
const customFlowHelper = {

    getInitalFlowMetadata(root, receiver, customer) {
        return {
            sender: {
                firstName: root.user.givenName,
                lastName: root.user.familyName,
                email: root.user.email,
                company: root.user.companyName
            },
            editor: {
                firstName: receiver.fname,
                lastName: receiver.lname,
                email: receiver.email,
                customerType: customer.type,
                customerName: customer.name
            },
            dateSent: new Date()
        };
    },

    onAddRecipient(customFlowData, recipient, index) {
        if (!customFlowData.signing) {
            customFlowData.signing = {
                recipients: [],
                signerString: ''
            };
            customFlowData.dateFilled = new Date();
        } 
        customFlowData.signing.recipients.push({
            displayName: recipient.givenName + ' ' + recipient.familyName,
            order: recipient.order,
            language: recipient.language,
            signingMethod: recipient.signingMethod,
            email: recipient.email,
        });
        if (index > 0) customFlowData.signing.signerString += ', ';
        customFlowData.signing.signerString += recipient.givenName + ' ' + recipient.familyName
    },

    getEmptyEnvelopeObject() {

    },

    readEnvelope(envelope, output) {

    },

    readRecipient(recipient) {
        console.log('REC', recipient);
        return {
            displayName: recipient.givenName + ' ' + recipient.familyName,
            action: recipient.role.action,
            completed: recipient.completed
        };
    },

    getDefaultMailScriptConfig() {
        return {
            formKey: 'custom',
            translationKey: 'custom',
            customRedirect: false,
            customRedirectUrl: 'https://verified.eu',
            useNewCheckout: true
        };
    },

    getMailScriptData(root, config, emailData, language) {
        const mail = {
            redirect: config.customRedirectUrl,
            logourl: root.config.kycs[0].email.logo,
            buttonColor: root.getColor('button'),
            linkColor: root.getColor('text-accent'),

            baseUrl: root.baseUrl,
            flowPortal: true,
            lang: language,
            checkout: config.useNewCheckout ? 'new' : 'old',

        };
        // Add translations for notify signatory and document signed
        for (let i in root.config.locales) {
            mail[root.config.locales[i].key] = {
                signAction: root.translations['action_sign'][root.config.locales[i].key],
                signVerb: root.translations['action_sign_verb'][root.config.locales[i].key],
                approveAction: root.translations['action_approve'][root.config.locales[i].key],
                approveVerb: root.translations['action_approve_verb'][root.config.locales[i].key],
                // Notify editor
                editorSubject: root.replaceBrackets(root.translations[config.translationKey + '_email_editor_subject'][root.config.locales[i].key], emailData, true),
                editorHeader: root.replaceBrackets(root.translations[config.translationKey + '_email_editor_header'][root.config.locales[i].key], emailData, true),
                editorMessage: root.replaceBrackets(root.translations[config.translationKey + '_email_editor_message'][root.config.locales[i].key], emailData, true),
                editorButtonText: root.replaceBrackets(root.translations[config.translationKey + '_email_editor_button'][root.config.locales[i].key], emailData, true),
                editorHint: root.replaceBrackets(root.translations[config.translationKey + '_email_editor_hint'][root.config.locales[i].key], emailData, true),
                editorFooter: root.replaceBrackets(root.translations[config.translationKey + '_email_editor_footer'][root.config.locales[i].key], emailData, true),
                // Notify signatory
                signSubject: root.replaceBrackets(root.translations[config.translationKey + '_email_signer_subject'][root.config.locales[i].key], emailData, true),
                signHeader: root.replaceBrackets(root.translations[config.translationKey + '_email_signer_header'][root.config.locales[i].key], emailData, true),
                signMessage: root.replaceBrackets(root.translations[config.translationKey + '_email_signer_message'][root.config.locales[i].key], emailData, true),
                signButtonText: root.replaceBrackets(root.translations[config.translationKey + '_email_signer_button'][root.config.locales[i].key], emailData, true),
                signHint: root.replaceBrackets(root.translations[config.translationKey + '_email_signer_hint'][root.config.locales[i].key], emailData, true),
                signFooter: root.replaceBrackets(root.translations[config.translationKey + '_email_signer_footer'][root.config.locales[i].key], emailData, true),
                // Document signed
                doneSubject: root.replaceBrackets(root.translations[config.translationKey + '_email_done_subject'][root.config.locales[i].key], emailData, true),
                doneHeader: root.replaceBrackets(root.translations[config.translationKey + '_email_done_header'][root.config.locales[i].key], emailData, true),
                doneMessage: root.replaceBrackets(root.translations[config.translationKey + '_email_done_message'][root.config.locales[i].key], emailData, true),
                doneHint: root.replaceBrackets(root.translations[config.translationKey + '_email_done_hint'][root.config.locales[i].key], emailData, true),
                doneFooter: root.replaceBrackets(root.translations[config.translationKey + '_email_done_footer'][root.config.locales[i].key], emailData, true),
            }
        }
        return mail;
    },

};
export default customFlowHelper;