<template>
<div>
    <PageHeader compactable :offset="104" @scrolled="scrolled = $event" :actions="actions" @action="onAction">
        <template v-slot:content>
            <vap-row v-if="!loading">
                <vap-group auto noPadding>
                    <vap-label :text="'Migration'" desc/>
                    <vap-label :text="'A collection of scripts for migrating customers'" value />
                </vap-group>
            </vap-row>
        </template>
        <template v-slot:tabs>
            <vap-tabs accent v-model="tabModel" :tabs="tabOptions" :compact="scrolled" />
        </template>
        <template v-slot:compact>
            <div v-if="!loading">
                <span>{{ 'Migration' }}</span>
            </div>
        </template>
    </PageHeader>

    <PageContent :scrolled="scrolled" :offset="104" v-if="!loading">

        <ScriptMigration v-if="tabModel.migration" />

        <ScriptChangeFlow2 v-if="tabModel.flow" />

        <ScriptPersonFlags v-if="tabModel.flags" />

    </PageContent>

    <PageFooter :scrolled="scrolled" />
</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import uiHelper from '../helpers/uihelper';

import PageHeader from '../v1ui/PageHeader.vue';
import PageContent from '../v1ui/PageContent.vue';
import PageFooter from '../v1ui/PageFooter.vue';

import ScriptMigration from '../v1scripts/ScriptMigration.vue';
import ScriptChangeFlow2 from '../v1scripts/ScriptChangeFlow2.vue';
import ScriptPersonFlags from '../v1scripts/ScriptPersonFlags.vue';

export default {
    name: 'MigrationV1',
    components: {
        PageHeader,
        PageContent,
        PageFooter,
        ScriptMigration,
        ScriptChangeFlow2,
        ScriptPersonFlags,
    },
    data() {
        return {
            loading: true,
            scrolled: false,
            tabModel: {
                migration: false,
                flow: true,
                flags: false,
            },
            tabOptions: {
                migration: { icon: 'upload', label: 'Migration' },
                flow: { icon: 'flow', label: 'Flow' },
                flags: { icon: 'pep', label: 'Screening flags' },
            },
            actions: [
                { icon: 'help-o', action: 'help', working: false },
                { icon: 'bug', action: 'reset', working: false },
            ],
        }
    },
    methods: {

        onAction(action) {
            if (action.action == 'reset') {
                this.onResetScriptStatus();
            }
        },

        async onResetScriptStatus() {
            uiHelper.setCardActionWorking(this.actions, 'reset', true);
            await v1api.resetScriptStatus();
            uiHelper.setCardActionWorking(this.actions, 'reset', false);
        },

    },
    async created() {
        this.loading = false;
    }
}
</script>