<template>
<div v-if="reportData.total">
    
    <vap-label v-if="amlData.sanction" :text="__('company_company')" header style="margin-bottom: 10px;" />
    <vap-form-divider :left="55" :right="45" v-if="amlData.sanction" style="float: left; width: 100%; margin-bottom: 20px;">
        <template v-slot:left>
            <vap-card>
                <vap-row>
                    <vap-group>
                        <vap-label :text="__('report_aml_result')" desc />
                        <vap-label :text="amlData.sanction.message" value />
                    </vap-group>
                </vap-row>
                <vap-button-row style="margin-top: 20px; margin-bottom: 0px;">
                    <vap-button :text="__('ov_view_aml')" uppercase @clicked="showReceipt(customer.aml.envelopeId, 'aml')"  />
                </vap-button-row>
            </vap-card>
        </template>
        <template v-slot:right>
            <vap-card innerShadow :validated="checklist.amlc.complete" :header="__('report_assessment')" v-if="reportData[customer.aml.envelopeId]">
                <template v-slot:actions>
                    <CardSwitch v-model="reportData[customer.aml.envelopeId].ok" :text="__('report_ok')" @changed="onReportInput" :disabled="customer.report != undefined" />
                </template>
                <vap-row>
                    <vap-group c12 v-if="$root.config.report.keywordsEnabled">
                        <vap-checkbox v-model="reportData[customer.aml.envelopeId].searchComplete" :text="'Det er gjort søk på stikkordene under'" noValidation inline  @input="onReportInput" />
                    </vap-group>
                    <vap-group c12 v-if="$root.config.report.keywordsEnabled">
                        <vap-label :text="'Huk av for eventuelle funn'" desc />
                        <vap-checkbox v-model="reportData[customer.aml.envelopeId].keywords" :options="keywordOptions" noValidation inline  @input="onReportInput" />
                    </vap-group>
                    <vap-group c12 v-if="amlData.sanction.status != 'NONE'">
                        <vap-checkbox :text="'Falsk positiv'" />
                    </vap-group>
                    <vap-group c12 v-if="reportData[customer.aml.envelopeId].ok">
                        <label style="color: var(--text-strong);">{{ __('report_no_comment') }}</label>
                    </vap-group>
                    <vap-group c12 v-if="!reportData[customer.aml.envelopeId].ok">
                        <vap-text-editor grouped compact :editable="!customer.report" hideOnBlur :ref="'rte' + customer.aml.envelopeId" 
                            v-model="reportData[customer.aml.envelopeId].comment" :placeholder="__('report_comment')"
                            @updated="onReportInput" />
                    </vap-group>
                </vap-row>
            </vap-card>
        </template>
    </vap-form-divider>

    <div v-if="customer.type != 'person'">
        <vap-label :text="__('kyc_form')" header style="margin-bottom: 10px;" />
        <vap-label v-if="!customer.kyc || !customer.kyc.completedDate" :text="__('report_empty_kyc')" value style="margin-bottom: 50px;" />

        <vap-form-divider v-if="customer.kyc && customer.kyc.completedDate" :left="55" :right="45" style="float: left; width: 100%; margin-bottom: 20px;">
            <template v-slot:left>
                <!-- <FormRenderer ref="form-renderer" :data="loadedCards" /> -->
                <vap-card>
                    <vap-row v-if="kycMetadata.signed">
                        <vap-group c6>
                            <vap-label :text="'Signert av'" desc />
                            <vap-label :text="getSignerNames()" value />
                        </vap-group>
                        <vap-group c6>
                            <vap-label :text="'Dato'" desc />
                            <vap-label :text="formatDate(kycMetadata.signatories[0].date)" value />
                        </vap-group>
                    </vap-row>
                    <vap-row v-if="kycMetadata.aborted">
                        <vap-group c6>
                            <vap-label :text="'Avbrutt av'" desc />
                            <vap-label :text="kycMetadata.aborter.displayName" value />
                        </vap-group>
                    </vap-row>
                    <label v-if="!kycMetadata.signatories.length">*Egenerklæringsskjema ikke signert</label>
                    <label style="color: var(--val-error);" v-if="kycMetadata.aborted">*Signering av egenerklæringsskjema avbrutt</label>
                    <vap-button-row style="margin-top: 20px; margin-bottom: 0px;">
                        <vap-button :text="__('ov_view_kyc')" uppercase @clicked="showReceipt(customer.kyc.envelopeId, 'kyc')"  />
                    </vap-button-row>
                </vap-card>
            </template>
            <template v-slot:right>
                <vap-card innerShadow :validated="checklist.kycc.complete" :header="__('report_assessment')" v-if="reportData[customer.kyc.envelopeId]">
                    <template v-slot:actions>
                        <CardSwitch v-model="reportData[customer.kyc.envelopeId].ok" :text="__('report_ok')" @changed="onReportInput" :disabled="customer.report != undefined" />
                    </template>
                    <vap-row>
                        <vap-group c12 v-if="reportData[customer.kyc.envelopeId].ok">
                            <label style="color: var(--text-strong);">{{ __('report_no_comment') }}</label>
                        </vap-group>
                        <vap-group c12 v-if="!reportData[customer.kyc.envelopeId].ok">
                            <vap-text-editor grouped compact :editable="!customer.report" hideOnBlur :ref="'rte' + customer.kyc.envelopeId" 
                                v-model="reportData[customer.kyc.envelopeId].comment" :placeholder="__('report_comment')"
                                @updated="onReportInput" />
                        </vap-group>
                    </vap-row>
                </vap-card>
            </template>
        </vap-form-divider>
    </div>


    <div v-if="($root.config.aml.compareData || $root.debug.testComparison) && (customer.flow == 'org-no-baml' || customer.flow == 'org-se-trap') && customer.type != 'person' && !(!customer.kyc || !customer.kyc.completedDate)" class="flw100" style="margin-bottom: 20px;">
        <vap-label :text="__('report_comparison')" header style="margin-bottom: 10px;" />
        <vap-form-divider :left="55" :right="45">
            <template v-slot:left>
                <ComparisonView :changes="$parent.kycMetadata.changes" />
            </template>
            <template v-slot:right>
                <vap-card innerShadow :validated="getCH2()" :header="__('report_assessment')" v-if="reportData.comparison">
                    <template v-slot:actions>
                        <CardSwitch v-model="reportData.comparison.ok" :text="__('report_ok')" @changed="onReportInput" :disabled="customer.report != undefined" />
                    </template>
                    <vap-row>
                        <vap-group c12 v-if="reportData.comparison.ok">
                            <label style="color: var(--text-strong);">{{ __('report_no_findings') }}</label>
                        </vap-group>
                        <vap-group c12 v-if="!reportData.comparison.ok">
                            <vap-text-editor grouped :editable="!customer.report" hideOnBlur :ref="'rte' + 'comparison'" 
                                v-model="reportData.comparison.comment" :placeholder="__('report_comment_findings')"
                                @updated="onReportInput" />
                        </vap-group>
                    </vap-row>
                </vap-card>
            </template>
        </vap-form-divider>
    </div>


    <div>
        <vap-label :text="__('key_roles')" header style="margin-bottom: 10px;" v-if="customer.type != 'person'" />
        <vap-label v-if="personList.length == 0" :text="__('key_roles_empty') + '...'" value style="margin-bottom: 50px;" />

        <div v-for="(person, i) in personList.filter(person => person.selected == true)" :key="'sp'+i">
            <vap-form-divider :left="55" :right="45" style="float: left; width: 100%; margin-bottom: 20px;">
                <template v-slot:left>
                    <vap-card :header="person.displayName">
                        <vap-row>
                            <vap-group c12 v-if="customer.type != 'person'">
                                <vap-label :text="__('common_role')" desc />
                                <div v-html="getDisplayRoles(person)" />
                            </vap-group>
                            <vap-group c12>
                                <vap-label :text="__('person_pep')" desc />
                                <div v-html="getStatusPEP(person)" />
                            </vap-group>
                            <vap-group c4 v-if="person.result && person.result.pep">
                                <vap-label :text="'Match Indicator'" desc />
                                <vap-label :text="person.result.matchIndicator" value />
                            </vap-group>
                            <vap-group c8 v-if="person.result && person.result.pep">
                                <vap-label :text="'Match Description'" desc />
                                <vap-label :text="person.result.matchDescription" value />
                            </vap-group>
                            <vap-group c12>
                                <vap-label :text="__('person_confirmed_id')" desc />
                                <div v-html="getStatusIDC(person)" />
                            </vap-group>
                        </vap-row>
                        <vap-button-row alignLeft>
                            <vap-button :text="'Vis PEP/Sanksjonssøk'" uppercase @clicked="showReceipt(person.envelopeId, 'pep')" v-if="person.result" />
                            <vap-button :text="'Vis ID-kontroll'" uppercase @clicked="showReceipt(person.envelopeIdControl, 'id')" v-if="person.controlCompleted" />
                        </vap-button-row>
                    </vap-card>
                </template>
                <template v-slot:right>
                    <vap-card innerShadow :validated="isPersonValid(person)" :header="__('report_assessment')" v-if="reportData[person.id]">
                        <template v-slot:actions>
                            <CardSwitch v-model="reportData[person.id].ok" :text="__('report_ok')" @changed="onReportInput" :disabled="customer.report != undefined || reportData[person.id].locked" />
                        </template>
                        <vap-row>
                            <vap-group c12 v-if="$root.config.report.keywordsEnabled">
                                <vap-checkbox v-model="reportData[person.id].searchComplete" :text="'Det er gjort søk på stikkordene under'" noValidation inline  @input="onReportInput(true)" :disabled="reportData[person.id].locked" />
                                <div v-if="reportData[person.id].locked" class="flw100" style="margin-top: 7px; margin-left: 5px;">
                                    <label>{{ 'Søk utført av ' + $root.getEmployeeName(reportData[person.id].userId) + ' ' + formatDate(reportData[person.id].searchDate) }}</label>
                                    <vap-label :text="'Åpne for endring'" desc link @clicked="archiveKeywords(person)" style="margin-top: 4px;" />
                                </div>
                            </vap-group>
                            <vap-group c12 v-if="$root.config.report.keywordsEnabled">
                                <vap-label :text="'Huk av for eventuelle funn'" desc />
                                <vap-checkbox v-model="reportData[person.id].keywords" :options="keywordOptions" noValidation inline  @input="onReportInput" :disabled="reportData[person.id].locked" />
                            </vap-group>
                            <vap-group c12 v-if="reportData[person.id].ok">
                                <label style="color: var(--text-strong);">{{ __('report_no_findings') }}</label>
                            </vap-group>
                            <vap-group c12 v-if="!reportData[person.id].ok">
                                <vap-text-editor grouped display :editable="!customer.report && !reportData[person.id].locked" hideOnBlur :ref="'rte' + person.id" 
                                    v-model="reportData[person.id].comment" :placeholder="__('report_comment_findings')"
                                    @updated="onReportInput" />
                            </vap-group>
                        </vap-row>
                    </vap-card>
                </template>
            </vap-form-divider>
        </div>
    </div>

    <div v-if="$root.config.report.autoRisk">
        <vap-label :text="__('report_checklist')" header style="margin-top: 50px; margin-bottom: 10px;" />
        <vap-card innerShadow :validated="getCH()">
            <div class="flw100">
                <vap-switch v-model="contactSet" disabled :text="'Har du angitt kontaktperson?'" />
            </div>
            <div class="flw100" style="margin-top: 10px;" v-if="$parent.kycMetadata.editorMadeChanges || $parent.customer.flow != 'org-no-baml'">
                <vap-switch v-model="bookConfirmed" disabled :text="'Har du kontrollert aksjeboken?'" />
            </div>
            <div class="flw100" style="margin-top: 10px;">
                <vap-switch v-model="allPepsDone" disabled :text="'Har du gjort PEP/Sanksjonssøk på alle nøkkelroller?'" />
            </div>
        </vap-card>
    </div>

    <div v-if="$parent.suitEnvelope">
        <vap-label :text="'Egnethetserklæring'" header style="margin-top: 50px; margin-bottom: 10px;" />
        <vap-form-divider :left="55" :right="45">
            <template v-slot:left>
                <vap-card innerShadow :validated="true">
                    <SuitabilityAssessment :envelope="$parent.suitEnvelope" />
                    <vap-button-row style="margin-top: 20px; margin-bottom: 0px;">
                        <vap-button :text="'Vis egnethetserklæring'" uppercase @clicked="showReceipt($parent.suitEnvelope.id, 'suit')"  />
                    </vap-button-row>
                </vap-card>
            </template>
            <template v-slot:right>
                <vap-card innerShadow :validated="getCH2()" :header="__('report_assessment')" v-if="reportData.comparison">
                    <template v-slot:actions>
                        <CardSwitch v-model="reportData.comparison.ok" :text="__('report_ok')" @changed="onReportInput" :disabled="customer.report != undefined" />
                    </template>
                    <vap-row>
                        <vap-group c12 v-if="reportData.comparison.ok">
                            <label style="color: var(--text-strong);">{{ __('report_no_findings') }}</label>
                        </vap-group>
                        <vap-group c12 v-if="!reportData.comparison.ok">
                            <vap-text-editor grouped :editable="!customer.report" hideOnBlur :ref="'rte' + 'comparison'" 
                                v-model="reportData.comparison.comment" :placeholder="__('report_comment_findings')"
                                @updated="onReportInput" />
                        </vap-group>
                    </vap-row>
                </vap-card>
            </template>
        </vap-form-divider>
    </div>

    <vap-label :text="__('report_final_assessment')" header style="margin-top: 50px; margin-bottom: 10px;" />
    <vap-card style="margin-bottom: 200px;" innerShadow :validated="checklist.risk.complete && checklist.com.complete">
        <vap-row v-if="reportData.total">
            <vap-group c12 v-if="$root.config.report.riskAssessment">
                <vap-label :text="__('report_risk_assessment')" desc />
                <vap-checkbox inline radio noValidation v-model="riskModel" :disabled="customer.report != undefined || riskDisabled" @input="onReportInput" :options="{
                    low: { label: __('report_risk_low') },
                    med: { label: __('report_risk_med') },
                    high: { label: __('report_risk_high') }
                }"/>
                <label v-if="riskDisabled" class="flw100" style="margin-top: 10px;">{{ '*' + __('pep_risk_auto_high') }}</label>
            </vap-group>
            <vap-group c12 v-if="$root.config.report.uploadExternalRisk && !customer.report">
                <vap-file-upload v-model="externalRiskFiles"
                    :header="__('report_external_header')"
                    :helpText="__('report_external_hint')"
                    :uploadText="__('report_external_select')"
                    :singleFile="true"
                    :autoPreview="false"
                    :initialInvalid="true"
                    @input="onReportInput"
                />
                <label style="padding-left: 10px; font-size: 15px; color: var(--text-weak);">{{ __('report_external_help') }}</label>
            </vap-group>
            <vap-group c12>
                <!-- <vap-label :text="__('report_comment')" desc /> -->
                <vap-text-editor grouped :editable="!customer.report" :ref="'rte' + 'total'" 
                    v-model="reportData.total.comment" :placeholder="__('report_comment')"
                    @updated="onReportInput" />
            </vap-group>
        </vap-row>
    </vap-card>

    <div class="report-fixed">
        <div class="report-fixed-left">
            <div v-if="allChecksComplete || customer.report" class="report-fixed-one">
                <label v-if="customer.report">{{ __('report_completed') }}</label>
                <label v-if="allChecksComplete && !customer.report">{{ __('report_ready') }}</label>
            </div>
            <div v-if="!allChecksComplete && !customer.report" class="report-fixed-two">
                <div style="font-size: 20px;">
                    <label>{{ __('report_not_ready') }}</label>
                </div>
                <div style="font-size: 18px;">
                    <label>{{ __(blockingCheckKey) }}</label>
                </div>
            </div>
        </div>
        <div class="report-fixed-right">
            <vap-button-row alignRight style="margin-top: 20px;">
                <vap-button :text="__('report_generate')" uppercase @clicked="createReport" :disabled="!allChecksComplete || customer.report != undefined" />
                <vap-button :text="__('save_changes')" uppercase @clicked="saveReport" :disabled="!reportChanged || customer.report != undefined" :working="reportSaving" />
            </vap-button-row>
        </div>
    </div>

</div>
</template>

<script>
import backend from '../../backend';
import api from '../../portalapi';
import receipts from '../../receipts';
import tools from '../../tools';
import personhelper from '../../helpers/personhelper';
import CardSwitch from '../kyc/CardSwitch';
import Label from '../basic/Label.vue';
import VeSwitch from '../basic/VeSwitch.vue';

import ComparisonView from './ComparisonView';
import SuitabilityAssessment from './SuitabilityAssessment.vue';

export default {
    name: 'Report',
    components: {
        CardSwitch,
        ComparisonView,
        SuitabilityAssessment
    },
    props: {
        customer: {
            type: Object,
            default: () => { return {} }
        },
        amlData: {
            type: Object,
            default: () => { return {} }
        },
        persons: {
            type: Object,
            default: () => { return {} }
        },
        personList: {
            type: Array,
            default: () => { return [] }
        },
        reportData: {
            type: Object,
            default: () => { return {} }
        },
        kycMetadata: {
            type: Object,
            default: () => { return {} }
        },
        kycData: {
            type: Object,
            default: () => { return {} }
        },
        brregData: {
            type: Object,
            default: () => { return {} }
        },
        manualData: {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            reportTracked: true,
            reportChanged: false,
            reportSaving: false,
            riskModel: { low: false, med: false, high: false },
            checklist: {
                aml: { key: 'report_check_aml', complete: false },
                amlc: { key: 'report_check_aml_c', complete: false },
                kyc: { key: 'report_check_kyc', complete: false },
                kycc: { key: 'report_check_kyc_c', complete: false },
                comp: { key: 'report_check_comp', complete: false },
                perc: { key: 'report_check_per_c', complete: false },
                chlist: { key: 'report_check_chlist', complete: false },
                risk: { key: 'report_check_risk', complete: false },
                file: { key: 'report_check_file', complete: false },
                com: { key: 'report_check_comment', complete: false },
            },
            blockingCheckKey: '',
            allChecksComplete: false,
            keywordOptions: {
                terrorism: { label: 'Terrorisme' },
                fraud: { label: 'Svindel' },
                sentance: { label: 'Dom' },
                insideTrading: { label: 'Innsidehandel' },
                illegal: { label: 'Ulovlig' },
                fine: { label: 'Bot' },
                corruption: { label: 'Korrupsjon' },
                trial: { label: 'Rettssak' },
                moneyLaundering: { label: 'Hvitvasking' },
                lawsuit: { label: 'Søksmål' }
            },
            riskModel: {
                model1: {
                    yes: false,
                    no: false
                },
                source1: '',
                comment1: ''
            },
            externalRiskFiles: {},
            riskDisabled: false,
            contactSet: false,
            bookConfirmed: false,
            allPepsDone: false
        }
    },
    watch: {
        reportData: {
            deep: true,
            handler(val) {
                if (val.metadata && val.metadata.risk) {
                    if (val.metadata.risk == 1) this.riskModel.low = true;
                    if (val.metadata.risk == 3) this.riskModel.med = true;
                    if (val.metadata.risk == 5) this.riskModel.high = true;
                }
            }
        },
    },
    methods: {

        formatDate(input) {
            return tools.dateString(input);
        },

        getDisplayRoles(person) {
            return personhelper.getDisplayRoles(person, this.$root);
        },

        getStatusPEP(person) {
            return personhelper.getStatusPEP(person, this.$root);
        },

        getStatusIDC(person) {
            return personhelper.getStatusIDC(person, this.$root);
        },

        onReportInput(checkStatus) {
            if (this.reportTracked) {
                this.reportChanged = true;
            }
            this.doAllChecks();
            if (checkStatus) this.$emit('check', true);
        },
        doAllChecks() {


            if (this.$root.config.report.hitPepAction != 'none') {
                for (const i in this.personList) {
                    if (this.personList[i].search.completed && this.personList[i].search.isPep) {
                        this.riskDisabled = true;
                        this.riskModel.low = false;
                        this.riskModel.med = false;
                        this.riskModel.high = true;
                    }
                }
            }
            if (this.$root.config.report.hitSanctionAction != 'none') {
                for (const i in this.personList) {
                    if (this.personList[i].search.completed && this.personList[i].search.hasSanction) {
                        this.riskDisabled = true;
                        this.riskModel.low = false;
                        this.riskModel.med = false;
                        this.riskModel.high = true;
                    }
                }
            }



            // Check if AML and KYC has been completed
            if (this.customer.aml) this.checklist.aml.complete = true;
            if ((this.customer.kyc && this.kycMetadata.signed) || this.$parent.flow.key == 'person' || this.$parent.kycDisabled) this.checklist.kyc.complete = true;

            // Check AML Comment
            if (this.customer.source == 'brreg') {
                if (this.checklist.aml.complete) {
                    if (this.reportData[this.customer.aml.envelopeId].ok) {
                        this.checklist.amlc.complete = true;
                    }else {
                        this.checklist.amlc.complete = (this.hasRef(this.customer.aml.envelopeId) && this.getTextFromRef(this.customer.aml.envelopeId).length > 7);
                    }
                }
            }else {
                this.checklist.aml.complete = true;
                this.checklist.amlc.complete = true;
            }
            

            // Check KYC Comment
            if (this.$parent.flow.key == 'person' || this.$parent.kycDisabled) {
                this.checklist.kycc.complete = true;
            }else {
                if (this.checklist.kyc.complete) {
                    if (this.reportData[this.customer.kyc.envelopeId].ok) {
                        this.checklist.kycc.complete = true;
                    }else {
                        this.checklist.kycc.complete = (this.hasRef(this.customer.kyc.envelopeId) && this.getTextFromRef(this.customer.kyc.envelopeId).length > 7);
                    }
                }
            }

            if (!this.$root.config.aml.compareData || this.$parent.flow.key != 'brreg'  || this.$parent.kycDisabled) {
                this.checklist.comp.complete = true;
            }else {
                if (this.reportData.comparison.ok) {
                    this.checklist.comp.complete = true;
                }else {
                    this.checklist.comp.complete = (this.hasRef('comparison') && this.getTextFromRef('comparison').length > 7);
                }
            }


            let perc = true;
/*             for (let i in this.personList) {
                if (!this.personList.selected) continue;
                if (!this.reportData[this.personList[i].id].ok 
                && !(this.hasRef(this.personList[i].id) && this.getTextFromRef(this.personList[i].id).length > 7)) {
                    perc = false;
                }
            } */
            for (const i in this.personList) {
                if (!this.isPersonValid(this.personList[i])) perc = false;
            }
            this.checklist.perc.complete = perc;


            if (this.$root.config.report.autoRisk) {
                this.checklist.chlist.complete = true;
                let match = false;
                this.allPepsDone = true;
                for (const i in this.personList) {
                    for (const j in this.personList[i].roles) {
                        if (this.personList[i].roles[j].id == 'role_customer_contact') {
                            this.contactSet = true;
                            match = true;
                        }
                        if (this.personList[i].selected && !this.personList[i].search.completed) {
                            this.allPepsDone = false;
                        }
                    }
                }
                if (!match) this.checklist.chlist.complete = false;
                if (this.$parent.shareholderBookConfirmed || (!this.$parent.kycMetadata.editorMadeChanges && this.$parent.customer.flow == 'org-no-baml')) {
                    this.bookConfirmed = true;
                }else {
                    this.checklist.chlist.complete = false;
                }
                if (!this.allPepsDone) this.checklist.chlist.complete = false;
            }else {
                this.checklist.chlist.complete = true;
            }
            


            if (this.riskModel.low || this.riskModel.med || this.riskModel.high) {
                this.checklist.risk.complete = true;
            }else {
                this.checklist.risk.complete = false;
            }

            if (this.$root.config.report.uploadExternalRisk) {
                if (this.externalRiskFiles.files && this.externalRiskFiles.files.length > 0) {
                    this.checklist.file.complete = true;
                }else {
                    this.checklist.file.complete = false;
                }
            }else {
                this.checklist.file.complete = true;
            }


            if (!this.$root.config.report.riskAssessment) this.checklist.risk.complete = true;

            this.checklist.com.complete = (this.hasRef('total') && this.getTextFromRef('total').length > 7);

            //console.log(this.getTextFromRef('total'));

            // Update allChecksComplete
            let allComplete = true;
            let blockingKey = '';
            for (let key in this.checklist) {
                if (!this.checklist[key].complete) {
                    if (!blockingKey) blockingKey = this.checklist[key].key;
                    allComplete = false;
                }
            }
            this.allChecksComplete = allComplete;
            this.blockingCheckKey = blockingKey;
            //console.log('checks', this.checklist);
        },

        getCH() {
            //console.log(this.checklist.chlist.complete)
            return this.checklist.chlist.complete;
        },

        getCH2() {
            //console.log(this.checklist.comp.complete)
            return this.checklist.comp.complete;
        },

        cutoffText(text, cutoff) {
            if (text.length > cutoff) return text.substring(0, cutoff) + '...';
            return text;
        },

        async saveReport() {
            this.reportSaving = true;
            for (let eid in this.reportData) {
                if (this.hasRef(eid)) this.reportData[eid].comment = this.getTextFromRef(eid);
            }

            let risk = 0;
            if (this.riskModel.low) risk = 1;
            if (this.riskModel.med) risk = 3;
            if (this.riskModel.high) risk = 5;
            this.customer.risk = risk;

            await api.updateCustomer(this.customer._id, {
                rdata: this.reportData,
                risk: risk
            });

            for (const i in this.personList) {
                if (this.personList[i].selected) {
                    const reportData = this.reportData[this.personList[i].id];
                    if (reportData && reportData.searchComplete) {
                        const keywords = {
                            completed: true,
                            date: new Date(),
                            userId: this.$root.credentials.uid,
                            ok: reportData.ok,
                            comment: reportData.comment,
                            words: reportData.keywords
                        };
                        console.log('keywords', keywords);
                        await api.updatePerson(this.personList[i].id, { keywords });
                    }
                }
            }

            if (this.$root.config.portal.updateStatus) {
                console.log('update status');

                const check = this.readyForReport();
                console.log('check', check);

                let status = 'r-rep-o';

                if (!check.hasID && !check.hasSearch) {
                    status = 'r-rep-k';
                }else if (!check.hasID) {
                    status = 'r-rep-l';
                }else if (!check.hasSearch) {
                    status = 'r-rep-m';
                }

                console.log('status', status);

                const response = await api.updateCustomer(this.$parent.customer._id, {
                    status: status
                });

            }

            this.reportChanged = false;
            this.reportSaving = false;
        },

        readyForReport() {

            // Check ID
            let hasID = false;
            let hasCeo = false, hasChair = false, hasRrh = false;
            let hasCeoID = false, hasChairID = false, hasRrhID = false;
            for (const i in this.$parent.personList) {
                if (personhelper.hasRole(this.$parent.personList[i], 'ceo')) {
                    hasCeo = true;
                    if (this.$parent.personList[i].control.completed) hasCeoID = true;
                }
                if (personhelper.hasRole(this.$parent.personList[i], 'chair')) {
                    hasChair = true;
                    if (this.$parent.personList[i].control.completed) hasChairID = true;
                }
                if (personhelper.hasRole(this.$parent.personList[i], 'screening_role_rrh')) {
                    hasRrh = true;
                    if (this.$parent.personList[i].control.completed) hasRrhID = true;
                }
            }
            if ((hasCeo && hasCeoID) || (hasChair && hasChairID) || (hasRrh && hasRrhID)) {
                hasID = true;
            }else {
                console.log('missing id');
            }

            // Check keywords
            let allCompleted = true;
            for (const i in this.$parent.reportData) {
                //console.log('rd', this.$parent.reportData[i]);

                if (this.$parent.reportData[i].type == 'aml'
                && !this.$parent.reportData[i].searchComplete
                && !this.$parent.reportData[i].ok
                && (this.$parent.reportData[i].comment == '<p></p>' || this.$parent.reportData[i].comment == '')) {
                    allCompleted = false;
                    console.log('missing company search');
                }

                if (this.$parent.reportData[i].type == 'per'
                && !this.$parent.reportData[i].searchComplete
                && !this.$parent.reportData[i].ok
                && (this.$parent.reportData[i].comment == '<p></p>' || this.$parent.reportData[i].comment == '')) {
                    
                    //console.log('checking ', this.$parent.reportData[i]);
                    //console.log('checking ', this.$parent.personList);

                    for (const j in this.$parent.personList) {
                        //console.log('check role', this.$parent.personList[j]);
                        if (this.$parent.personList[j].id == i) {
                            //console.log('check role', this.$parent.personList[j]);
                            if (personhelper.hasRole(this.$parent.personList[j], 'ceo') || personhelper.hasRole(this.$parent.personList[j], 'chair') || personhelper.hasRole(this.$parent.personList[j], 'screening_role_rrh')) {
                                allCompleted = false;
                                console.log('missing person search');
                            }
                        }
                    }

                }
            }

            return {
                hasID: hasID,
                hasSearch: allCompleted
            };
        },

        async archiveKeywords(person) {
            const reportData = this.reportData[person.id];
            if (reportData) reportData.locked = false;
            //console.log('data', reportData);
        },

        isPersonValid(person) {
            //console.log('checking person', person);
            //console.log('checking ref', this.getTextFromRef(person.id));
            if (!person.selected) return true;
            if (!this.reportData[person.id].ok 
            && !(this.hasRef(person.id) && this.getTextFromRef(person.id).length > 7)) {
                return false;
            }
            return true;
        },
        hasRef(envelopeId) {
            return this.$refs['rte' + envelopeId] != undefined;
        },
        getTextFromRef(envelopeId) {
            try {
                return this.$refs['rte' + envelopeId].getText();
            }catch (e) {
                try {
                    return this.$refs['rte' + envelopeId][0].getText();
                }catch (ee) {
                    return '';
                }
            }
        },

        async createReport() {
            this.$parent.$parent.setLoading(true, this.__('loading_saving_customer') + '...');
            await this.saveReport();
            const reportMetadata = {
                hasPep: false,
                hasSanction: false
            };
            for (const i in this.personList) {
                if (this.personList[i].search.isPep) reportMetadata.hasPep = true;
                if (this.personList[i].search.hasSanction) reportMetadata.hasSanction = true;
            }
            const data = {
                customer: this.customer,
                brregData: this.brregData,
                manualData: this.manualData,
                amlData: this.amlData,
                persons: this.personList,
                reportData: this.reportData,
                kycMetadata: this.kycMetadata,
                kycDisabled: this.$parent.kycDisabled,
                kycExternalId: this.$parent.externalKYCID,
                reportMetadata: reportMetadata,
                suitability: {
                    envelope: this.$parent.suitEnvelope
                }
            };
            this.$parent.$parent.setLoading(true, this.__('loading_creating_receipt') + '...');
            let result;
            if (this.$parent.flow.key == 'person') {
                result = await receipts.createFinalReportPerson(this.$root, data, false, this.externalRiskFiles);
            }else {
                result = await receipts.createFinalReport(this.$root, data, false, this.externalRiskFiles);
            }
            this.$emit('report', true);
            this.$emit('reload', result.updatedCustomer);
            this.$parent.$parent.setLoading(false);
        },
        
        getSignerNames() {
            let sns = '';
            if (this.kycMetadata.signatories.length) {
                for (let i in this.kycMetadata.signatories) {
                    if (i > 0) sns += ', ';
                    sns += this.kycMetadata.signatories[i].displayName;
                }
            }
            return sns;
        },

        // Handle receipts
        handleReceiptClick(envelopeId, type) {
            this.$emit('show-pdf', envelopeId, type);
        },

        showReceipt(envelopeId, type) {
            this.$emit('receipt', envelopeId, type);
        },

    },
    async created() {
        this.doAllChecks();
        if (this.customer.risk == 1) this.riskModel.low = true;
        if (this.customer.risk == 3) this.riskModel.med = true;
        if (this.customer.risk == 5) this.riskModel.high = true;
    }
}
</script>

<style>

.report-fixed {
    position: fixed;
    display: flex;
    left: 0px;
    bottom: 0px;
    height: 80px;
    width: 100%;
    padding-right: 20px;
    color: var(--text-strong);
    background-color: var(--card-background);
    z-index: 99;
    box-shadow: -1px -1px 2px rgba(166, 177, 198, 0.3);
    box-sizing: border-box;
}
.report-fixed-left {
    flex-grow: 100;
}
.report-fixed-right {
    float: right;
}
.report-fixed-one {
    margin-top: 22px;
    margin-left: 20px;
    font-size: 22px;
}
.report-fixed-two {
    margin-top: 12px;
    margin-left: 20px;
}



.risk-rr {
    float: left;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 10px;
    display: flex;
}

.risk-rrr {
    font-size: 20px;
    margin-top: 3px;
}

.risk-rrrr {
    margin-left: 15px;
    font-size: 20px;
    padding: 3px 10px;
    border-radius: 4px;
}



</style>
