<template>
<div>

    <DesignerGroup c12>
        <DesignerLabel :text="'Available authentication methods'" />
        <DesignerCheckbox v-model="methodModel" :options="methodOptions" @input="onMethodsChanged" />
    </DesignerGroup>

</div>
</template>
<script>
import DesignerGroup from '../../designer/ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../../designer/ui/inputs/DesignerLabel.vue';
import DesignerCheckbox from '../../designer/ui/inputs/DesignerCheckbox.vue';

export default {
    name: "IdcModuleBox",
    components: {
        DesignerGroup,
        DesignerLabel,
        DesignerCheckbox,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() {
        return {
            methodOptions: {},
            methodModel: {
                bankidno: false,
                bankidse: false,
                touchsign: false,
            },
        }
    },
    methods: {

        onMethodsChanged() {

            for (const i in this.reference.modules) {
                if (this.reference.modules[i].type == 'idc') {
                    
                    const module = this.reference.modules[i];

                    console.log('module', module);

                    for (const key in this.methodModel) {

                        module.methods[key] = this.methodModel[key];

                    }

                    console.log('methods', module.methods);
                }
            }

        },

    },
    created() {
        // Load available methods
        if (this.$root.config.params.authMethods.bankidno) this.methodOptions.bankidno = { label: 'BankID Norway' };
        if (this.$root.config.params.authMethods.bankidse) this.methodOptions.bankidse = { label: 'BankID Sweden' };
        if (this.$root.config.params.authMethods.passreader) this.methodOptions.passreader = { label: 'Passport Reader' };

        for (const i in this.reference.modules) {
            if (this.reference.modules[i].type == 'idc') {
                if (this.reference.modules[i].methods) {
                    if (this.reference.modules[i].methods.bankidno) this.methodModel.bankidno = true;
                    if (this.reference.modules[i].methods.bankidse) this.methodModel.bankidse = true;
                    if (this.reference.modules[i].methods.passreader) this.methodModel.passreader = true;
                }else {
                    this.methodModel.bankidno = true;
                    this.methodModel.bankidse = true;
                    this.methodModel.passreader = true;
                }
            }
        }
    }
}

</script>