<template>
<div class="frmod-kyc">

    <PopupV1 :header="'Connect to KYC data'" v-if="params.type == 'connect'" :width="900" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <div class="from-kyc-con">
                <div class="from-kyc-left">

                    <div class="from-kyc-item-head">
                        <label>{{ 'Basic Company Information' }}</label>
                    </div>
                    <div v-for="key in connectKeys.company" :key="'c' + key" class="from-kyc-item" @click="onOldConnectItem(key)">
                        <label>{{ getConnectedItemName(key) }}</label>
                    </div>

                </div>
                <div class="from-kyc-middle">

                    <div class="from-kyc-item-head">
                        <label>{{ 'Economy' }}</label>
                    </div>
                    <div v-for="key in connectKeys.economy" :key="'c' + key" class="from-kyc-item" @click="onOldConnectItem(key)">
                        <label>{{ getConnectedItemName(key) }}</label>
                    </div>

                    <div class="from-kyc-item-head" style="margin-top: 10px;">
                        <label>{{ 'Objects' }}</label>
                    </div>
                    <div v-for="key in connectKeys.objects" :key="'c' + key" class="from-kyc-item" @click="onOldConnectItem(key)">
                        <label>{{ getConnectedItemName(key) }}</label>
                    </div>

                    <div class="from-kyc-item-head" style="margin-top: 10px;">
                        <label>{{ 'Lists' }}</label>
                    </div>
                    <div v-for="key in connectKeys.lists" :key="'c' + key" class="from-kyc-item" @click="onOldConnectItem(key)">
                        <label>{{ getConnectedItemName(key) }}</label>
                    </div>

                </div>
                <div class="from-kyc-right">

                    <div class="from-kyc-item-head">
                        <label>{{ 'Flags' }}</label>
                    </div>
                    <div v-for="key in connectKeys.flags" :key="'c' + key" class="from-kyc-item" @click="onOldConnectItem(key)">
                        <label>{{ getConnectedItemName(key) }}</label>
                    </div>

                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

    <PopupV1 :header="'KYC Module Configuration'" v-if="params.type == 'manage'" :width="600" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            
            <VFCheckbox newDesign v-model="updateKyc" :text="'Update client KYC data from this form'" @input="onConfigurationChanged" />
            <VFCheckbox newDesign v-model="comparison" :text="'Do a comparison of the inital company and inputted data'" @input="onConfigurationChanged" />

        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

    <PopupV1 :header="'Connect to KYC data'" v-if="params.type == 'xconnect'" :width="500" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <ConnectionList :items="options" :params="params" @item="onConnectItem" @itemkey="onConnectItemKey" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import kycModuleHelper from './kycModuleHelper';
import logicHelper from '../../logicHelper';

import ConnectionList from '../../designer/ConnectionList.vue';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';
import VFCheckbox from '../../inputs/VFCheckbox.vue';

export default {
    name: "frmod-kyc",
    components: {
        PopupV1,
        VFButton,
        VFCheckbox,
        ConnectionList,
    },
    props: {
        params: { type: Object, default: () => { return { type: '' } }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: {},
            connectKeys: {
                company: [
                    'clientIsPerson',
                    'clientIsCompany',
                    'clientName',
                    'clientNumber',
                    'clientDepartment',
                    'clientContactName',
                    'clientSSN',
                    'orgName',
                    'orgNumber',
                    'businessAddress.street',
                    'businessAddress.zip',
                    'businessAddress.city',
                    'businessAddress.country',
                    'dateEstablished',
                    'dateRegistered',
                    'formCode',
                    'regCountry',
                    'employees',
                    'hasBeneficials',
                    'hasShareholders',
                ],
                economy: [
                    'equity',
                    'assets',
                    'income',
                ],
                lists: [
                    'beneficials',
                    'roles',
                    'peps',
                    'boardMembers',
                    'deputyBoardMembers',
                    'shareholders',
                    'shareholderPersons',
                    'shareholderCompanies',
                    'controlEntities',
                    'naceCodes',
                    'others',
                    'signatories',
                    'poas',
                ],
                objects: [
                    'individual',
                    'ceo',
                    'chair',
                    'cfo',
                    'other'
                ],
                flags: [
                    'listed',
                    'unlisted',
                    'confirmBeneficials',
                    'noBeneficials',
                    'confirmShareholders',
                    'uploadShareholders',
                    'bookUpload',
                    'bookForward',
                    'bookOwner',
                    'bookComment',
                    'isPep',
                    'isBeneficial',
                ]
            },
            updateKyc: false,
            comparison: false,
            options: [],
        }
    },
    watch: {
        width: {
            deep: false,
            handler(val) {
                
            }
        }
    },
    methods: {

        onConfigurationChanged() {
            for (const i in this.$parent.modules) {
                if (this.$parent.modules[i].type == 'kyc') {
                    this.$parent.modules[i].updateKyc = this.updateKyc;
                    this.$parent.modules[i].comparison = this.comparison;
                }
            }
        },
        
        getConnectedItemName(kycKey) {
            return kycModuleHelper.getConnectedItemName(kycKey);
        },

        onOldConnectItem(kycKey) {
            if (this.params.output == 'logic') {
                this.$emit('connect', kycKey);
            }else {
                console.log('input', this.params.input)
                //this.params.input.kycKey = kycKey;
                this.$set(this.params.input, 'kycKey', kycKey);
            }
            this.$emit('close');
        },

        onConnectItem(result) {
            console.log('result', result);

            if (result.conn == 'changed') {

                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'disabled');
                    this.params.fromItem.logic.disabled.keys.push({
                        type: 'kyc', key: 'changed', rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'visible');
                    this.params.fromItem.logic.visible.keys.push({
                        type: 'kyc', key: 'changed', rev: result.reversed
                    });

                    console.log('this.params.fromItem', this.params.fromItem);

                }

            }
            
            this.$emit('reselect');
            this.$emit('close');
        },

        onConnectItemKey(result) {
            console.log('result', result);



            this.$emit('reselect');
            this.$emit('close');
        },

    },
    created() {
        for (const i in this.$parent.modules) {
            if (this.$parent.modules[i].type == 'kyc') {
                if (this.$parent.modules[i].updateKyc) this.updateKyc = true;
                if (this.$parent.modules[i].comparison) this.comparison = true;
            }
        }
        if (this.params.type == 'xconnect') {
            const type = this.params.fromItem.type;
            
            // Data changed (comparison)
            this.options.push({ type: 'trigger', conn: 'changed', icon: 'check', text: 'Data changed (comparison)',
                hint: 'Trigger input properties if data changed in the comparison'
            });

        }
    }
}

</script>
<style>

.from-kyc-con {
    float: left;
    width: 100%;
}
.from-kyc-left {
    float: left;
    width: 33%;
}
.from-kyc-middle {
    float: left;
    width: 34%;
}
.from-kyc-right {
    float: left;
    width: 33%;
}

.from-kyc-item-head {
    padding-left: 10px;
    font-size: 18px;
}

.from-kyc-item {
    padding: 5px 15px;
    cursor: pointer;
}
.from-kyc-item label {
    font-size: 16px;
    cursor: pointer;
}
.from-kyc-item:hover {
    background: var(--item-hover);
}

</style>
