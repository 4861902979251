<template>
<div class="om-base" :class="[
    {'om-rounded': rounded }
]">
    
    <canvas :id="'canvasx'" class="myCanvas2" />
    
</div>
</template>
<script>

export default {
    name: 'OwnershipMapCanvas',
    props: {
        admins: { type: Array, default: () => { return [] } },
        rounded: { type: Boolean, default: false },
        preview: { type: Boolean, default: false },
    },
    data() {
        return {
            canvas: null,
            context: null,

            cameraOffset: { x: 0, y: 0 },
            cameraZoom: 1,
            MAX_ZOOM: 5,
            MIN_ZOOM: 0.1,
            SCROLL_SENSITIVITY: 0.0005,

            initialPinchDistance: null,
            lastZoom: 1,

            isDragging: false,
            dragStart: { x: 0, y: 0 },
        }
    },
    methods: {

        initializeCanvas() {
            
            this.canvas = document.getElementById("canvasx")
            this.context = this.canvas.getContext('2d')

            /* console.log('canvas', this.canvas); */

            var rect = this.canvas.getBoundingClientRect();
            /* console.log(rect.top, rect.right, rect.bottom, rect.left); */

            const parentWidth = this.canvas.parentNode.parentNode.offsetWidth;
            console.log('parentWidth', parentWidth);

            //this.cameraOffset = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
            this.cameraOffset = { x: (parentWidth / 2) - 60, y: (parentWidth / 2) - 60 };
            this.cameraZoom = this.preview ? 1.0 : 1.5;
            this.MAX_ZOOM = 5
            this.MIN_ZOOM = 0.1
            this.SCROLL_SENSITIVITY = 0.001

            this.initialPinchDistance = null
            this.lastZoom = this.cameraZoom

            this.isDragging = false
            this.dragStart = { x: 0, y: 0 }

            const self = this;

            this.canvas.addEventListener('mousedown', this.onPointerDown);
            this.canvas.addEventListener('touchstart', (e) => this.handleTouch(e, this.onPointerDown));
            this.canvas.addEventListener('mouseup', this.onPointerUp);
            this.canvas.addEventListener('touchend',  (e) => this.handleTouch(e, this.onPointerUp));
            this.canvas.addEventListener('mousemove', this.onPointerMove);
            this.canvas.addEventListener('touchmove', (e) => this.handleTouch(e, this.onPointerMove));
            this.canvas.addEventListener('wheel', (e) => this.adjustZoom(e.deltaY * this.SCROLL_SENSITIVITY));

        },

        

        draw() {

            const parentWidth = this.canvas.parentNode.parentNode.offsetWidth;

            //this.canvas.width = window.innerWidth;
            //this.canvas.height = window.innerHeight;

            this.canvas.width = parentWidth;
            this.canvas.height = parentWidth * 0.5;
            
            // Translate to the canvas centre before zooming - so you'll always zoom on what you're looking directly at
            this.context.translate( window.innerWidth / 2, window.innerHeight / 2 )
            this.context.scale(this.cameraZoom, this.cameraZoom)
            this.context.translate( -window.innerWidth / 2 + this.cameraOffset.x, -window.innerHeight / 2 + this.cameraOffset.y )
            this.context.clearRect(0,0, window.innerWidth, window.innerHeight)

            this.$parent.onDrawFrame(this.context);

/*             this.context.fillStyle = "#991111"
            this.drawRect(-50,-50,100,100)
            
            this.context.fillStyle = "#eecc77"
            this.drawRect(-35,-35,20,20)
            this.drawRect(15,-35,20,20)
            this.drawRect(-35,15,70,20)
            
            this.context.fillStyle = "#fff"
            this.drawText("Simple Pan and Zoom Canvas", -255, -100, 32, "courier")
            
            this.context.rotate(-31*Math.PI / 180)
            this.context.fillStyle = `#${(Math.round(Date.now()/40)%4096).toString(16)}`
            this.drawText("Now with touch!", -110, 100, 32, "courier")
            
            this.context.fillStyle = "#fff"
            this.context.rotate(31*Math.PI / 180)
            
            this.drawText("Wow, you found me!", -260, -2000, 48, "courier") */

            /* this.drawRect(0, 0, 100, 50); */
            
            requestAnimationFrame( this.draw )
        },

        // Gets the relevant location from a mouse or single touch event
        getEventLocation(e) {
            if (e.touches && e.touches.length == 1)
            {
                return { x:e.touches[0].clientX, y: e.touches[0].clientY }
            }
            else if (e.clientX && e.clientY)
            {
                return { x: e.clientX, y: e.clientY }        
            }
        },

        drawRect(x, y, width, height) {
            this.context.fillRect( x, y, width, height )
        },

        drawText(text, x, y, size, font) {
            this.context.font = `${size}px ${font}`
            this.context.fillText(text, x, y)
        },

        

        onPointerDown(e) {
            this.isDragging = true
            this.dragStart.x = this.getEventLocation(e).x/this.cameraZoom - this.cameraOffset.x
            this.dragStart.y = this.getEventLocation(e).y/this.cameraZoom - this.cameraOffset.y
        },

        onPointerUp(e) {
            this.isDragging = false
            this.initialPinchDistance = null
            this.lastZoom = this.cameraZoom
        },

        onPointerMove(e) {
            if (this.isDragging) {
                this.cameraOffset.x = this.getEventLocation(e).x / this.cameraZoom - this.dragStart.x
                this.cameraOffset.y = this.getEventLocation(e).y / this.cameraZoom - this.dragStart.y
            }else {

                const x = e.x - this.cameraOffset.x;
                const y = e.y - this.cameraOffset.y;

                /* console.log('x: ' + x + ', y: ' + y); */
            }
        },

        handleTouch(e, singleTouchHandler) {
            if ( e.touches.length == 1 )
            {
                singleTouchHandler(e)
            }
            else if (e.type == "touchmove" && e.touches.length == 2)
            {
                this.isDragging = false
                handlePinch(e)
            }
        },

        

        handlePinch(e) {
            e.preventDefault()
            
            let touch1 = { x: e.touches[0].clientX, y: e.touches[0].clientY }
            let touch2 = { x: e.touches[1].clientX, y: e.touches[1].clientY }
            
            // This is distance squared, but no need for an expensive sqrt as it's only used in ratio
            let currentDistance = (touch1.x - touch2.x)**2 + (touch1.y - touch2.y)**2
            
            if (this.initialPinchDistance == null)
            {
                this.initialPinchDistance = currentDistance
            }
            else
            {
                adjustZoom( null, currentDistance/this.initialPinchDistance )
            }
        },

        adjustZoom(zoomAmount, zoomFactor) {
            if (!this.isDragging) {
                if (zoomAmount) {
                    this.cameraZoom -= zoomAmount;
                }else if (zoomFactor) {
                    this.cameraZoom = zoomFactor * lastZoom;
                }
                this.cameraZoom = Math.min(this.cameraZoom, this.MAX_ZOOM);
                this.cameraZoom = Math.max(this.cameraZoom, this. MIN_ZOOM);
            }
        },

        
    },
    created() {
        
        this.$nextTick(() => {
            this.initializeCanvas();
            this.draw();
        });

        
    }
}
</script>
<style scoped>

.om-base {
    overflow: hidden;
    border: solid 1px black;
}

.om-rounded {
    border-radius: 5px;
    background-color: white;
    border: solid 1px var(--input-border);
}

.myCanvas2 {
    background-color: white;
}

</style>