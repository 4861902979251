<template>

<div :class="['ac-group', widthString,
        { 'ac-group-12': c12 },
        { 'ac-group-9': c9 },
        { 'ac-group-8': c8 },
        { 'ac-group-6': c6 },
        { 'ac-group-5': c5 },
        { 'ac-group-4': c4 },
        { 'ac-group-3': c3 },
        { 'ac-group-2': c2 },
        { 'ac-group-auto': auto },
        { 'ac-group-np': noPadding },
        { 'ac-designer': designer },
        { 'ac-selected': selected },
        { 'ac-disabled': disabled },
        { 'ac-compact': compact },
    ]" @click="handleClick" :id="id" @mouseover.stop>
    <slot></slot>
    <div class="ac-group-logic" :id="'l' + id" @click="handleLogicClick" @mousedown="handleLogicClick">
        <svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.7);">
            <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 9 C 3 10.093063 3.9069372 11 5 11 L 9 11 C 10.093063 11 11 10.093063 11 9 L 11 5 C 11 3.9069372 10.093063 3 9 3 L 5 3 z M 5 5 L 9 5 L 9 9 L 5 9 L 5 5 z M 5 13 L 5 15 L 7 15 L 7 13 L 5 13 z M 15 13 C 13.906937 13 13 13.906937 13 15 L 13 19 C 13 20.093063 13.906937 21 15 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 15 C 21 13.906937 20.093063 13 19 13 L 15 13 z M 15 15 L 19 15 L 19 19 L 15 19 L 15 15 z M 5 17 L 5 19 L 7 19 L 7 17 L 5 17 z M 9 17 L 9 19 L 11 19 L 11 17 L 9 17 z"/>
        </svg>
    </div>
</div>

</template>
<script>

export default {
    name: "vap-group",
    props: {
        width: { type: String, default: ''},
        c12: { type: Boolean, default: false },
        c9: { type: Boolean, default: false },
        c8: { type: Boolean, default: false },
        c6: { type: Boolean, default: false },
        c5: { type: Boolean, default: false },
        c4: { type: Boolean, default: false },
        c3: { type: Boolean, default: false },
        c2: { type: Boolean, default: false },
        auto: { type: Boolean, default: false },
        noPadding: { type: Boolean, default: false },
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        compact: { type: Boolean, default: false },
        id: { type: String, default: 'id' },
    },
    data() {
        return {
            widthString: ''
        }
    },
    watch: {
        width: {
            deep: false,
            handler(val) {
                this.setWidth(val);
            }
        }
    },
    methods: {
        handleClick() {
            if (!this.designer) return;
            this.$emit('selected', true);
        },
        setWidth(val) {
            if (val == '12') {
                this.widthString = 'ac-group-12';
            }else if (val == '9') {
                this.widthString = 'ac-group-9';
            }else if (val == '8') {
                this.widthString = 'ac-group-8';
            }else if (val == '6') {
                this.widthString = 'ac-group-6';
            }else if (val == '5') {
                this.widthString = 'ac-group-5';
            }else if (val == '4') {
                this.widthString = 'ac-group-4';
            }else if (val == '2') {
                this.widthString = 'ac-group-2';
            }
        },
        handleLogicClick() {
            this.$emit('logic', this.id);
        }
    },
    mounted() {
        this.setWidth(this.width);
    }
}

</script>
<style>

.ac-group {
    position: relative;
    float: left;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
    align-items: center;
    box-sizing: border-box;
}

.ac-group-np {
    padding: 0px;
}

.ac-group-12 {
    width: 100%;
}
.ac-group-9 {
    width: 75%;
}
.ac-group-8 {
    width: 66.6664%;
}
.ac-group-6 {
    width: 50%;
}
.ac-group-5 {
    width: 41.666%;
}
.ac-group-4 {
    width: 33.3334%;
}
.ac-group-3 {
    width: 25%;
}
.ac-group-2 {
    width: 16.666%;
}

.ac-group-auto {
    float: left;
    width: auto;
    margin-right: 40px;
}

@media only screen and (max-width: 600px) {

    .ac-group-8, .ac-group-6, .ac-group-5, .ac-group-4, .ac-group-3, .ac-group-2 {
        width: 100%;
    }

}


/* logic icon */

.ac-group-logic {
    visibility: collapse;
    position: absolute;
    top: 10px; right: 20px;
    width: 26px; height: 26px;
    border-radius: 50%;
    background-color: #3190D5;
    cursor: pointer;
    padding-top: 1px;
    padding-left: 1.5px;
    box-sizing: border-box;
}
.ac-group-logic path {
    fill: white;
}

/* design mode */

.ac-designer {
    /* padding-top: 4px;
    padding-bottom: 6px; */
}
.ac-designer:hover {
    background-color: rgb(231, 246, 255);
}
.logic .ac-designer:hover {
    background-color: transparent;
}

.ac-selected {
    border-radius: 3px;
    outline: solid 2px #238ccc;
}

.ac-selected .ac-group-logic {
    visibility: visible;
}

.ac-disabled {
    opacity: 0.5;
}

.ac-compact {
    padding-bottom: 5px;
}

</style>
