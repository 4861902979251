<template>
    <div>
        
        <div class="aml-page" >

            <vap-card list :header="'Load customers'">
                <vap-button-row alignLeft>
                    <vap-button :text="'f-kyc-c'" @clicked="loadCustomers('f-kyc-c')" />
                    <vap-button :text="'r-rep-a'" @clicked="loadCustomers('r-rep-a')" />
                </vap-button-row>
            </vap-card>

            <vap-form-divider :left="55" :right="45" stickyRight :stickyOffset="80">
                <template v-slot:left>
                    <vap-label :text="'Customers'" header style="margin-bottom: 5px;" />

                    <vap-card>
                        <div class="flw100 bat-items">
                            <div class="flw100 bat-item" v-for="(customer, i) in customers" :key="'c' + i">
                               <div class="bat-icon-c" v-if="i < currentIndex || isComplete">
                                    <vap-icon :icon="'check'" :color="'var(--success-text)'" />
                                </div>
                                <div class="bat-icon-p" v-if="i > currentIndex || (i == currentIndex && !isRunning && !isComplete)">
                                    <vap-icon :icon="'cancel'" :color="'var(--disabled-text)'" />
                                </div>
<!--                                 <div class="rem-icon">
                                    <vap-checkbox v-model="customer.checked" :text="''" noValidation />
                                </div> -->
                                <div class="bat-prog" v-if="i == currentIndex && isRunning">
                                    <vap-spinner iconMode />
                                </div>
                                <div class="bat-content">
                                    <div class="bat-top">
                                        <label class="bat-strong">{{ customer.name }}</label>
                                    </div>
                                    <div class="bat-bot">
                                        <label class="bat-accent">{{ customer.status }}</label>
                                        <label class="bat-weak">{{ customer.expires }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </vap-card>


                </template>
                <template v-slot:right v-if="customers.length > 0">
                    <vap-label :text="'Status'" header style="margin-bottom: 5px;" />
                    <vap-card>
                        
                        <div class="bat-current">
                            <label>{{ customers[currentIndex].name }}</label>
                        </div>
                        <div class="bat-current-stat">
                            <label>{{ currentStatus }}</label>
                        </div>

                        <div class="bat-prog-text">
                            <label>{{ currentIndex + 1 }}</label>
                            <label>/</label>
                            <label>{{ customers.length }}</label>
                        </div>

                        <div class="bat-prog-base">
                            <div class="bat-prog-prog" :style="'right: ' + progressRight + '%;'" />
                        </div>

                        <div class="bat-loop-stat" :class="{'bat-loop-stat-error': isError}">
                            <label>{{ loopStatus }}</label>
                        </div>
                        
                        <vap-button-row alignCenter style="margin-top: 20px;">
                            <vap-button :text="'Start'" @clicked="startBatch" :disabled="isRunning || isComplete || isStopping || isError" />
                            <vap-button :text="'Pause'" @clicked="pauseBatch" :disabled="!isRunning || isComplete || isStopping || isError" />
                        </vap-button-row>

                        <div class="flw100 bat-loop-error" v-if="isError">
                            <label>{{ errorMessage }}</label>
                        </div>

                    </vap-card>
                </template>
            </vap-form-divider>

            
        </div>

    </div>
</template>
<script>
import { auth, envelopes, oidc } from '@verified/libvf';
import backend from '../../backend';
import api from '../../portalapi';
import verified from '../../verified';
import kychelper from '../../helpers/kychelper';
import log from '../../log';

export default {
    name: 'StatusSync',
    data() {
        return {
            customers: [],
            progressRight: 50,
            isRunning: false,
            isStopping: false,
            isComplete: false,
            isError: false,
            currentIndex: 0,
            currentStatus: 'Venter...',
            loopStatus: 'Klar til start...',
            errorMessage: 'unknown error',
        }
    },
    methods: {
        
        // ----- Batch Loop ---------------------------------------------------------------

        startBatch() {
            if (!this.isRunning && !this.isStopping && !this.isComplete) this.startBatchLoop(); 
        },

        pauseBatch() {
            this.loopStatus = 'Stopper...';
            this.isStopping = true;
        },

        setProgress() {
            const fac = 100 / this.customers.length;
            const per = fac * (this.currentIndex);
            this.progressRight = 100 - per;
            if (this.isComplete) this.progressRight = 0;
        },

        async startBatchLoop() {
            this.isRunning = true;
            this.loopStatus = 'Kjører...';
            while (!this.isStopping && !this.isComplete) {
                console.log('processing (' + this.currentIndex + ') ' + this.customers[this.currentIndex].name);
                let result;
                // Process customer


                // Step 1: Check status
                this.currentStatus = 'Henter status...';
                result = await this.checkStatus();
                if (!result.ok) {
                    this.setError(result);
                    break;
                }
                
                // Step 2: Validate status
                this.currentStatus = 'Validerer status...';
                result = await this.validateStatus(result.kycMetadata);
                if (!result.ok) {
                    this.setError(result);
                    break;
                }

                // Step 3: Update customer
                this.currentStatus = 'Oppdaterer kunde...';
                result = await this.updateStatus(result.mismatch);
                if (!result.ok) {
                    this.setError(result);
                    break;
                }

                
                

                // Next customer
                this.currentStatus = 'Venter...';
                if (this.currentIndex >= this.customers.length - 1) {
                    this.isComplete = true;
                }else {
                    this.currentIndex += 1;
                }
                this.setProgress();
            }
            this.isStopping = false;
            this.isRunning = false;
            if (this.isComplete) {
                this.loopStatus = 'Ferdig!';
            }else if (this.isError) {
                this.loopStatus = 'Stoppet på grunn av feil!';
            }else {
                this.loopStatus = 'Klar til start...';
            }
        },

        // --------------------------------------------------------------------------------


        // ----- Actions ------------------------------------------------------------------

        async checkStatus() {
            const result = { ok: false, errorMessage: 'unknown error', kycMetadata: {
                completed: false, aborted: false, signed: false, expired: false,
                editor: { id: '', firstName: '', lastName: '', email: '', displayName: '', completed: false },
                aborter: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
                nextSigner: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
                signatories: [],
                signedNames: '',
                expirationDate: undefined,
                envelopeId: '', documentId: '', fileUid: ''
            }};
            try {
                await new Promise(r => setTimeout(r, 1000));

                if (!this.customers[this.currentIndex].status.includes('f-kyc')) {
                    log.v('Sync: Skipped KYC metadata');
                    result.ok = true;
                    return result;
                }

                const envelope = await this.$root.getEnvelope(this.customers[this.currentIndex].kyc.envelopeId);
                kychelper.getMetadata(this.customers[this.currentIndex], envelope, result.kycMetadata);
                log.v('Sync: Loaded KYC metadata', result.kycMetadata);

                result.ok = true;
            }catch (ex) {
                result.errorMessage = ex.message;
            }
            return result;
        },

        async validateStatus(kycMetadata) {
            const result = { ok: false, errorMessage: 'unknown error', mismatch: {} };
            try {
                await new Promise(r => setTimeout(r, 1000));
                
                if (this.customers[this.currentIndex].status == 'f-kyc-c') {
                    if (kycMetadata.signed) {
                        result.mismatch.signed = true;
                        log.e('Sync: Mismatch: Status is f-kyc-c, but envelope is signed');
                    }
                }
                
                if (this.customers[this.currentIndex].status == 'r-rep-a') {
                    const customer = this.customers[this.currentIndex];
                    let pendingControl = false;
                    let completedControl = false;
                    if (customer.people) {
                        for (const i in customer.people) {
                            if (customer.people[i].invite) {
                                if (customer.people[i].control) {
                                    completedControl = true;
                                }else {
                                    pendingControl = true;
                                }
                            }
                        }
                    }
                    if (pendingControl) {
                        result.mismatch.invite = true;
                        log.e('Sync: Mismatch: Status is r-rep-a, but there are pending ID-controls');
                    }else if (completedControl) {
                        result.mismatch.control = true;
                        log.e('Sync: Mismatch: Status is r-rep-a, but ID-controls are completed');
                    }
                }

                result.ok = true;
            }catch (ex) {
                result.errorMessage = ex.message;
            }
            return result;
        },

        async updateStatus(mismatch) {
            const result = { ok: false, errorMessage: 'unknown error' };
            try {
                await new Promise(r => setTimeout(r, 1000));

                if (mismatch.signed) {
                    const response = await api.updateCustomer(this.customers[this.currentIndex]._id, {
                        status: 'r-rep-a',
                        expires: -1
                    });
                    log.s('Sync: Updated status', response);
                }

                result.ok = true;
            }catch (ex) {
                result.errorMessage = ex.message;
            }
            return result;
        },

        // --------------------------------------------------------------------------------

        setError(result) {
            this.errorMessage = result.errorMessage;
            this.isError = true;
        },

        async loadCustomers(status) {
            this.$parent.setLoading(true, 'Loading customers...');

            const response = await backend.getCustomersParams({ status: status });
            this.customers = response.data.customers;

            this.setProgress();
            this.$parent.setLoading(false);
        }

    },
    async created() {
        
    }
}
</script>
<style scoped>

.bat-current {
    text-align: center;
    font-size: 18px;
    color: var(--text-strong);
}
.bat-current-stat {
    margin-top: 5px;
    text-align: center;
    font-size: 16px;
    color: var(--primary);
}


.bat-prog-text {
    margin-top: 20px;
    text-align: center;
    font-size: 24px;
    color: var(--text-strong);
}
.bat-prog-base {
    position: relative;
    width: 100%;
    height: 40px;
    margin: 0 auto;
    margin-top: 5px;
    text-align: center;
    border-radius: 20px;
    border: solid 2px var(--accent);
    background-color: var(--text-disabled);
    overflow: hidden;
    box-sizing: border-box;
}
.bat-prog-prog {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0%;
    background-color: var(--accent);
    opacity: 0.4;
}

.bat-loop-stat {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: var(--text-strong);
}
.bat-loop-stat-error {
    color: var(--val-error);
}
.bat-loop-error {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    color: var(--val-error);
}




.bat-items {

}
.bat-item {
    display: flex;
    margin-bottom: 15px;
}
.bat-icon-p {
    flex-shrink: 0;
    width: 40px; height: 40px;
    padding-top: 3px;
    padding-left: 2px;
    border-radius: 50%;
    background-color: var(--disabled-background);
    box-sizing: border-box;
}
.bat-icon-c {
    flex-shrink: 0;
    width: 40px; height: 40px;
    padding-top: 3px;
    padding-left: 3px;
    border-radius: 50%;
    background-color: var(--success-background);
    box-sizing: border-box;
}
.bat-prog {
    position: relative;
    flex-shrink: 0;
    width: 40px; height: 40px;
}
.bat-content {
    flex-grow: 100;
    margin-left: 15px;
}
.bat-top {
    
}
.bat-bot {
    
}
.bat-strong {
    margin-right: 10px;
    color: var(--text-strong);
}
.bat-accent {
    margin-right: 10px;
    color: var(--text-accent);
}
.bat-weak {
    margin-right: 10px;
    color: var(--text-weak);
}


</style>