<template>
<div class="vap-ai">
    <div v-if="!disabled && !error" class="vap-ai-item vap-ai-active card-shadow">
        <div class="vap-ai-circle">
            <label>{{ number }}</label>
        </div>
        <div class="vap-ai-content">
            <slot name="active-content"></slot>
        </div>
        <div class="vap-ai-right">
            <slot name="active-action"></slot>
        </div>
    </div>
    <div v-if="disabled && !error" class="vap-ai-item vap-ai-disabled">
        <div class="vap-ai-circle">
            <label>{{ number }}</label>
        </div>
        <div class="vap-ai-content">
            <slot name="disabled-content"></slot>
        </div>
        <div class="vap-ai-right">
            <slot name="disabled-action"></slot>
        </div>
    </div>
    <div v-if="error" class="vap-ai-item vap-ai-active vap-ai-error card-shadow">
        <div class="vap-ai-circle">
            <label>{{ number }}</label>
        </div>
        <div class="vap-ai-content">
            <slot name="error-content"></slot>
        </div>
        <div class="vap-ai-right">
            <slot name="error-action"></slot>
        </div>
    </div>
</div>
</template>

<script>

export default {
    name: 'vap-action-item',
    components: {
        
    },
    props: {
        number: {
            type: Number,
            default: 0
        },
        disabled: {
            type: Boolean,
            default: true
        },
        error: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            
        }
    },
    async created() {
        
    }
}
</script>

<style scoped>

.vap-ai {
    float: left;
    width: 100%;
    margin-bottom: 20px;
}

.vap-ai-circle {
    flex-shrink: 0;
    width: 56px;
    height: 56px;
    margin-top: 12px;
    margin-left: 15px;
    border-radius: 50%;
    font-family: "Roboto Slab", serif;;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 11px;
    box-sizing: border-box;
}

.vap-ai-content {
    margin-left: 20px;
    flex-grow: 100;
}

.vap-ai-right {
    flex-shrink: 0;
    float: right;
    padding-right: 20px;
}

.vap-ai-item {
    display: flex;
    width: 100%;
    min-height: 80px;
    border-radius: 3px;
}

.vap-ai-disabled {
    border: dotted 2px var(--text-disabled);
}
.vap-ai-disabled .vap-ai-circle {
    color: gray;
    border: dotted 2px var(--text-disabled);
}

.vap-ai-active {
    border: solid 2px var(--card-background);
    background-color: var(--card-background);
}
.vap-ai-active .vap-ai-circle {
    color: white;
    background-color: var(--primary);
}
.vap-ai-error .vap-ai-circle {
    color: var(--error-text);
    background-color: var(--error-background);
}


.l1 {
    font-family: "Roboto Slab", serif;
    font-size: 20px;
}
.l2 {
    font-family: 'Signika', sans-serif;
    font-size: 20px;
}

</style>
