<template>
    <div style="background-color: white; min-height: 100vh; padding: 30px;">
        <div v-html="html" />
        <div class="pdf-button" @click="showPDF">
            <label>PDF</label>
        </div>
        <PopupViewer v-if="previewVisible" :blob="previewRawData" :filename="'haha'" @close="previewVisible = false" />
    </div>
</template>
<script>
import axios from 'axios'
import pdfTools from '../../pdfTools'
import locale from '../../locale'
import api from '../../portalapi'
import PopupViewer from '../../components/documents/PopupViewer'
export default {
    name: 'PDFTest',
    components: {
        PopupViewer
    },
    data() {
        return {
            html: '',
            userdata: {},
            type: '',
            previewVisible: false,
            previewRawData: '',
        }
    },
    methods: {
        async showPDF() {
            // Render template locally for testing
            const response = await pdfTools.getPDF(this.$root, this.$root.config, this.type, this.userdata, this.$root.translations, true);
            this.previewRawData = response.data;
            this.previewVisible = true;
        },
        copyToClipboard(text) {
            if (!navigator.clipboard) {
                this.fallbackCopyTextToClipboard(text);
                return;
            }
            navigator.clipboard.writeText(text).then(function() {
                console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
                console.error('Async: Could not copy text: ', err);
            });
        },
        fallbackCopyTextToClipboard(text) {
            var textArea = document.createElement("textarea");
            textArea.value = text;
            
            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                console.log('Fallback: Copying text command was ' + msg);
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
            }

            document.body.removeChild(textArea);
        }
    },
    async created() {
        this.type = this.$route.query.pdf || 'pep';
        let data = this.$route.query.data || '1';

        if (this.type == 'kyc') {
            // Load KYC translations and merge with main
            const defaults = locale.getDefaultKycTranslations();
            const tres = await api.getTranslations('kyc');
            this.kycTranslations = { ...defaults, ...tres.texts };
            this.$root.translations = { ...this.$root.translations, ...this.kycTranslations };
        }

        const response = await axios.get(`templates/${this.type}/testdata${data}.json`);
        if (response.data.path) {
            this.userdata = response.data.userdata;
        }else {
            this.userdata = response.data;
        }
        if (!this.userdata.pdfLanguage) this.userdata.pdfLanguage = 'en_EN';
        console.log('userdata', this.userdata);
        const template = await api.renderTemplate(this.$root, this.type, this.userdata);
        console.log('template', template);
        const htmlPage = `
            <!DOCTYPE html>
            <html lang="en">
            <head><title>Verified PDF Template</title><style>${template.css}</style></head>
            <body>${template.html}</body>
            </html>
        `;
        this.html = htmlPage;
        this.copyToClipboard(htmlPage.toString());
    }
}
</script>
<style scoped>

.pdf-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 30px;
    padding: 6px 10px;
    border-radius: 5px;
    background-color: gray;
    cursor: pointer;
    opacity: 0.6;
    box-sizing: border-box;
}
.pdf-button label {
    cursor: pointer;
}
.pdf-button:hover {
    opacity: 1;
}

</style>