<template>
<div>

    <PopupV1 :header="__t('client-edit')" :width="700" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <DialogContent style="padding-top: 20px;">

                <VFRow v-if="$root.config.externalId.enabled">
                    <VFGroup c12>
                        <VFLabel :text="$root.config.externalId.title[$root.locale.key]" desc required />
                        <VFInput v-model="externalId" :placeholder="__('common_optional')" />
                    </VFGroup>
                </VFRow>

                <VFRow v-if="$root.config.responsible.enabled">
                    <VFGroup c12>
                        <VFLabel :text="$root.config.responsible.clientText[$root.locale.key]" desc required />
                        <vap-dropdown v-model="employeeModel" :options="employees" :displayKey="'name'" :outputKey="'key'" :listKey="'name'" noValidation />
                    </VFGroup>
                </VFRow>

                <VFRow v-if="$root.config.branches.enabled">
                    <VFGroup c12>
                        <VFLabel :text="$root.config.branches.clientText[$root.locale.key]" desc required />
                        <vap-dropdown v-model="branchModel" :options="branches" :displayKey="'name'" :outputKey="'key'" :listKey="'name'" noValidation />
                    </VFGroup>
                </VFRow>
                <VFRow v-if="$root.config.partners.enabled">
                    <VFGroup c12>
                        <VFLabel :text="$root.config.partners.clientText[$root.locale.key]" desc required />
                        <vap-dropdown v-model="partnerModel" :options="partners" :displayKey="'name'" :outputKey="'key'" :listKey="'name'" noValidation />
                    </VFGroup>
                </VFRow>

                <VFRow v-if="($root.debug.local || $root.config.clients.allowFlowChange) && (flowOptions.length > 1 || hasInvalidFlow)">
                    <VFGroup c12>
                        <VFLabel :text="$root.config.clients.filters.flow.title[$root.locale.key]" desc required />
                        <vap-dropdown v-model="flowModel" :options="flowOptions" :displayKey="$root.locale.key" :outputKey="'key'" :listKey="$root.locale.key" noValidation />
                    </VFGroup>
                </VFRow>

            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__('common_save')"
                :icon="'check'"
                @clicked="editClient"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1flows from '../v1helpers/v1flows';

import PopupV1 from '../v1ui/PopupV1.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFInput from '../components/form/inputs/VFInput.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import VFDropdown from '../components/form/inputs/VFDropdown.vue';
import VFDatepicker from '../components/form/inputs/VFDatepicker.vue';

export default {
    name: 'EditResponsibleDialog',
    components: {
        PopupV1,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFRow,
        VFGroup,
        VFLabel,
        VFButton,
        VFInput,
        VFCheckbox,
        VFDropdown,
        VFDatepicker
    },
    props: {
        client: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            flowOptions: [],
            flowModel: '',
            hasInvalidFlow: false,
            branches: [],
            branchModel: '',
            partners: [],
            partnerModel: '',
            employees: [],
            employeeModel: '',
            externalId: '',
        }
    },
    methods: {

        validateDialog() {
            this.dialog.valid = true;
        },

        async editClient() {
            this.dialog.working = true;
            try {

                const clientData = {};

                if (this.externalId) clientData.externalId = this.externalId;
                if (this.employeeModel) clientData.responsible = this.employeeModel;
                if (this.branchModel) clientData.branch = this.branchModel;
                if (this.partnerModel) clientData.partner = this.partnerModel;

                if ((this.$root.debug.local || this.$root.config.clients.allowFlowChange) && (this.flowOptions.length > 1 || this.hasInvalidFlow)) {
                    if (this.flowModel != this.client.flow) clientData.flow = this.flowModel;
                }

                await v1api.editClient(this.client._id, clientData);

                const response = await v1api.getClient(this.client._id);
                this.$emit('reload', response);

                this.dialog.working = false;
                this.dialog.show = false;
                this.onClose();

            }catch (ex) {
                this.dialog.error = ex.message;
                console.error(ex);
            }
        },

        onClose() {
            this.$emit('close');
        },

        loadValidFlows() {
            let flows = [];
            if (this.client.type == 'person') {
                flows = v1flows.getAvailableFlows2(this.$root, 'person', 'xx', 'manual', 'none');
            }else {
                flows = v1flows.getAvailableFlows2(this.$root, 'org', this.client.country, this.client.source, 'none');
            }
            for (const i in flows) {
                const flow = flows[i];
                const flowOption = {
                    key: flows[i].key,
                };
                for (const key in flow.title) {
                    flowOption[key] = flow.title[key];
                }
                this.flowOptions.push(flowOption);
            }
            for (const i in this.flowOptions) {
                if (this.flowOptions[i].key == this.client.flow) {
                    this.flowModel = this.flowOptions[i].key;
                }
            }
        },

    },
    async created() {
        this.loading = true;

        // Load branches
        if (this.$root.config.branches.enabled) {
            for (const i in this.$root.departments) {
                this.branches.push({
                    key: this.$root.departments[i].key,
                    name: this.$root.departments[i].name,
                });
            }
            this.branchModel = this.client.branch || '';
        }
        // Load partners
        if (this.$root.config.partners.enabled) {
            for (const i in this.$root.partners) {
                this.partners.push({
                    key: this.$root.partners[i].key,
                    name: this.$root.partners[i].firstName + ' ' + this.$root.partners[i].lastName,
                });
            }
            this.partnerModel = this.client.partner || '';
        }

        /* console.log('partners', this.partners);
        console.log('branches', this.branches); */

        // Load employees
        for (const i in this.$root.employees) {
            this.employees.push({
                name: this.$root.employees[i].name,
                key: this.$root.employees[i].id,
            });
        }
        this.employeeModel = this.client.responsible || '';


        this.externalId = this.client.externalId;

        const flow = v1flows.getFlow(this.$root, this.client.flow);
        if (!flow) this.hasInvalidFlow = true;
        this.loadValidFlows();

        this.loading = false;
        this.validateDialog();
    }
}
</script>