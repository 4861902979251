<template>
<div class="aml-page">

    <vap-card :header="'Change Flow'">

        <VFGroup c6>
            <VFLabel :text="'Source Flow'" />
            <VFInput v-model="source" />
        </VFGroup>

        <VFGroup c6>
            <VFLabel :text="'Target Flow'" />
            <VFInput v-model="target" />
        </VFGroup>

        <VFButton :text="'Load clients'" @clicked="onLoadClientsClicked" />

    </vap-card>

    <BatchView :items="items" :steps="steps" :skip="skip" :title="'Clients'" />

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import BatchView from '../components/special/BatchView';

import VFButton from '../components/form/items/VFButton.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFInput from '../components/form/inputs/VFInput.vue';

export default {
    components: {
        BatchView,
        VFButton,
        VFGroup,
        VFLabel,
        VFInput,
    },
    name: 'ScriptChangeFlow2',
    data() {
        return {
            items: [],
            steps: 2,
            STEP_LOAD_CLIENT: 0,
            STEP_UPDATE_STATUS: 1,
            skip: 0,
            source: 'no-org-baml',
            target: 'no-org-baml',
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            return item.name;
        },

        onGetInfo1(item) {
            return item.flow;
        },

        onGetInfo2(item) {
            return '';
        },

        async onRemoveItem(item) {},

        async onStart() {
            return true;
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return 'Updating flow';
                case this.STEP_UPDATE_STATUS: return 'Updating status';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return await this.loadClient(item, data);
                case this.STEP_UPDATE_STATUS: return await this.updateStatus(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async loadClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            const clientData = {
                flow: this.target,
                skipDateUpdate: true,
            };
            await v1api.updateClient(item._id, clientData);

            result.ok = true;
            return result;
        },

        async updateStatus(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            await v1api.getClientStatusTEST(item._id);

            await new Promise(r => setTimeout(r, 250));

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        async loadClients() {
            this.loading = true;

            const filters = [
                { field: 'flow', op: 'is', value: this.source },
            ];
            const from = 0;
            const limit = 250;
            const sort = {};
            const include = [];
            const counts = ['letter'];

            const response = await v1api.queryClients(filters, from, limit, sort, include, counts);

            this.items = [];
            for (const i in response.clients) {
                this.items.push(response.clients[i]);
            }

            this.loading = false;
        },

        onLoadClientsClicked() {
            this.loadClients();
        }

    },
    async created() {
        
    }
}
</script>