<template>
<div>

    <PopupV1 :header="'Update person from ID control'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ 'This person will be updated to use confirmed details from the ID control' + ':' }}
            </div>
            <DialogContent customContent>
                <VFRow>
                    <VFGroup c6>
                        <VFLabel desc :text="'First Name'" /><br>
                        <VFLabel input :text="params.person.info.firstName" :error="params.person.info.firstName != params.person.idc.firstName" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Last Name'" /><br>
                        <VFLabel input :text="params.person.info.lastName" :error="params.person.info.lastName != params.person.idc.lastName" />
                    </VFGroup>
                    <VFGroup c12>
                        <VFLabel desc :text="'Date of Birth'" /><br>
                        <VFLabel input :text="params.person.info.dateOfBirth" :error="params.person.info.dateOfBirth != params.person.idc.dateOfBirth" />
                    </VFGroup>
                </VFRow>
            </DialogContent>
            <div class="mid-merge-icon">
                <vap-icon :icon="'merge'" />
            </div>
            <DialogContent customContent>
                <VFRow>
                    <VFGroup c6>
                        <VFLabel desc :text="'First Name'" /><br>
                        <VFLabel input :text="params.person.idc.firstName" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Last Name'" /><br>
                        <VFLabel input :text="params.person.idc.lastName" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'Date of Birth'" /><br>
                        <VFLabel input :text="params.person.idc.dateOfBirth" />
                    </VFGroup>
                    <VFGroup c6>
                        <VFLabel desc :text="'SSN'" /><br>
                        <VFLabel input :text="params.person.idc.ssn" />
                    </VFGroup>
                </VFRow>
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="'Update'" v-if="!params.active"
                :icon="'merge'"
                @clicked="onClick"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';

export default {
    name: 'MergeIdentityDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        VFRow,
        VFGroup,
        VFLabel
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            includePersons: true
        }
    },
    methods: {

        async onClick() {
            this.dialog.working = true;

            await v1api.updateFromID(this.params.person.personId, {});

            const response = await v1api.getClient(this.params.clientId);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        this.loading = false;
    }
}
</script>
<style scoped>

.mid-merge-icon {
    float: left;
    width: 100%;
    padding-left: 220px;
}

</style>