<template>
<div>
    
    <PopupV1 :header="'Select operator'" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <vap-item v-for="(option, i) in options" :key="'op' + i" fancy padding20
                :fancyIcon="option.icon"
                :fancyText="option.name"
                :fancyHint="option.hint"
                @clicked="onSelected(option)"
            />
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

</div>
</template>

<script>
import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFInput from '../inputs/VFInput.vue';
import VFCheckbox from '../inputs/VFCheckbox.vue';
import VFDropdown from '../inputs/VFDropdown.vue';
import VFRow from '../items/VFRow.vue';
import VFGroup from '../items/VFGroup.vue';
import VFButton from '../items/VFButton.vue';
import DialogContent from '../../../v1ui/DialogContent.vue';
import DialogContentHeader from '../../../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../../../v1ui/DialogContentItem.vue';

export default {
    name: 'LogicOperatorSelector',
    components: {
        PopupV1,
        VFInput,
        VFCheckbox,
        VFDropdown,
        VFRow,
        VFGroup,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
    },
    props: {
        items: { type: Array, default: () => { return [] }}
    },
    data() {
        return {
            dialog: {},
            options: [
                { icon: 'logic', name: 'Input Comparison', hint: 'Compare one input to another', type: 'inputcomp' },
                { icon: 'logic', name: 'Bool Count Comparison', hint: 'Output something based on a number of boolean inputs', type: 'boolcomp' },
                { icon: 'logic', name: 'Calculator', hint: 'Perform simple math on inputs', type: 'calc' },
            ]
        }
    },
    methods: {

        onSelected(option) {

            const operator = {
                type: option.type,
                demoOutput: null
            };

            if (option.type == 'inputcomp') {

                operator.operator = 'gt';
                operator.outputFormat = 'B';
                operator.outputValueT = true;
                operator.outputValueF = false;

            }else if (option.type == 'boolcomp') {

                operator.reversed = false;
                operator.operator = 'gt';
                operator.threshold = 2;
                operator.outputFormat = 'B';
                operator.outputValueT = true;
                operator.outputValueF = false;

            }else if (option.type == 'calc') {

                operator.operator = 'add';

            }

            this.$emit('select', operator);
        },

    },
    created() {
        
    }
}
</script>
<style scoped>

</style>