<template>
    <div class="slim-page">

        <vap-card>
            <vap-row>
                <vap-group c12>
                    <vap-label desc :text="'Phone'" />
                    <vap-phone v-model="phone" />
                </vap-group>
            </vap-row>
            <vap-button-row alignCenter style="margin-top: 10px; margin-bottom: 10px;">
                <vap-button :text="'Start'" uppercase @clicked="startIDControl" />
            </vap-button-row>
        </vap-card>
        
    </div>
</template>

<script>
import axios from 'axios';
import { auth, envelopes, oidc } from '@verified/libvf';
import backend from '../backend';
import locale from '../locale';
import tools from '../tools';
import v2lib from '../v2lib';

export default {
    name: 'IDC',
    components: {

    },
    data() {
        return {
            phone: {
                prefix: '+47',
                number: '95558014'
            }
        }
    },
    methods: {

        async startIDControl() {

            const body = {
                "authFlow": "implicit",
                "eid": "bankid-no",
                "redirectUrl": "http://localhost:8888/idc",
                "duration": "3d",
                "sessionDuration": "30m",
                "ssn": "18084110848",
                /* "telephone": this.phone.prefix + this.phone.number, */
            };

            const url = await this.post(body);
            window.location.href = url;
        },

        async post(body) {
            const axiosConfig = {
                headers: {
                    'Authorization': 'JWT ' + v2lib.personalToken,
                }
            };
            const response = await axios.post('/api/secure/oidc/initiate', body, axiosConfig);
            console.log('response', response);
            return response.data.url;
        }

    },
    async created() {

    }
}
</script>

<style scoped>



</style>