<template>
<div class="pii" :class="[
    {'pii-border': border },
    {'pii-icon': icon },
    {'pii-small': smallText },
    {'pii-weak nohl': weakText },
    {'pii-success': success },
    {'pii-warning': warning },
    {'pii-error': error },
    {'pii-manual': manual },
    {'pii-blue nohl': blue },
    {'pii-bluefill': blueFill },
    {'pii-redfill': redFill },
    {'pii-text-error': textError },
]" :style="'width: ' + width + '%;'">

    <slot></slot>

    <div class="vap-button-tt">
        <slot name="tooltip"></slot>
    </div>
    
</div>
</template>
<script>
export default {
    name: 'PersonInfoItem',
    props: {
        width: { type: Number, default: 100 },
        border: { type: Boolean, default: false },
        icon: { type: Boolean, default: false },
        smallText: { type: Boolean, default: false },
        weakText: { type: Boolean, default: false },
        success: { type: Boolean, default: false },
        warning: { type: Boolean, default: false },
        error: { type: Boolean, default: false },
        manual: { type: Boolean, default: false },
        blue: { type: Boolean, default: false },
        blueFill: { type: Boolean, default: false },
        redFill: { type: Boolean, default: false },
        textError: { type: Boolean, default: false },
    }
}
</script>
<style scoped>

.pii {
    position: relative;
    float: left;
    height: 32px;
    padding: 0px 5px;
    padding-top: 7px;
    text-align: center;
    color: var(--text-strong);
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}

.pii-border {
    border-left: solid 1px var(--input-border);
}

.pii-icon {
    position: relative;
    fill: gray
}


.pii-small {
    padding-top: 8px;
    font-size: 14px;
}
.pii-weak {
    color: var(--text-weak);
}

.pii-blue {
    font-weight: 500;
    color: var(--success-background);
}

.pii-bluefill {
    color: var(--success-text);
    background-color: var(--success-background);
}
.pii-redfill {
    color: var(--error-text);
    background-color: var(--error-background);
}


.pii-success {
    height: 41px;
    padding-top: 12px;
    color: var(--success-text);
    background-color: var(--success-background);
}
.pii-warning {
    height: 41px;
    padding-top: 12px;
    color: var(--warning-text);
    background-color: var(--warning-background);
}
.pii-error {
    height: 41px;
    padding-top: 12px;
    color: var(--error-text);
    background-color: var(--error-background);
}
.pii-manual {
    height: 41px;
    padding-top: 12px;
    color: var(--disabled-text);
    background-color: var(--disabled-background);
}

.pii-text-error {
    font-weight: 500;
    color: var(--error-text);
    background-color: var(--error-background);
}

.vap-button-tt {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
}

</style>