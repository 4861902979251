<template>
<div class="vftex" v-html="getText()" @click="handleClick" :class="[
    { 'vfte-v-selected' : selected }
]">
</div>
</template>

<script>
import xss from 'xss';

export default {
    name: 'TextViewerV2',
    props: {
        content: { type: String, default: '' },
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
    },
    data() {
        return {
            xssOptions: {
                whiteList: {
                    div: ['style'],
                    a: ['href', 'target'],
                    b: [], i: [], u: [],
                    br: [], p: [],
                    ul: [], li: [],
                    strike: [],
                    font: ['size'],
                    span: ['style'],
                    table: [], tr: [], th: [], td: []
                },
            },
        }
    },
    methods: {

        getText() {
            //console.log('content', this.content);
            //console.log('xssOptions', this.xssOptions);
            const value = xss(this.content, this.xssOptions);
            //console.log('value', value);
            return value;
        },
       
        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

    },
}
</script>
<style>

.vfte-v-selected {
    float: left;
    width: 100%;
    margin-bottom: 4px;
    outline: solid 2px #238ccc;
    border-radius: 3px;
}

.vftex ul {
    margin-block-start: 2px;
    margin-block-end: 0px;
    padding-inline-start: 28px;
}
.vftex li {
    margin: none;
    padding: none;
}

</style>