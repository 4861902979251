<template>
<div>

    <vap-form-divider :left="50" :right="50">
        <template v-slot:left>

            <div>
                <div>
                    <label class="sr-title">{{ __('sr_alternatives') }}</label>
                </div>
                <div>
                    <label>{{ __('sr_conclusive') + ':' }}</label>
                    <label class="sr-bold" style="margin-left: 6px;">{{ __(company.signature.conclusive ? 'common_yes' : 'common_no').toUpperCase() }}</label>
                </div>
                <div v-for="(option, i) in company.signature.options" :key="'so' + i">
                    <div class="sr-title sr-margin">
                        <label>{{ __('sr_alternative') + ' ' + (i+1) }}</label>
                    </div>
                    <div v-for="(person, j) in option.required" :key="'sp' + i + j" style="margin-top: 2px;">
                        <div>
                            <label class="sr-name">{{ person.firstName + ' ' + person.lastName }}</label>
                            <label class="sr-weak">{{ ' (' + person.dateOfBirth + ')' }}</label>
                        </div>
                    </div>
                </div>
                <div v-if="company.signature.options.length == 0" class="sr-margin">
                    <label>{{ __('sr_not_conclusive') }}</label>
                </div>
            </div>

        </template>
        <template v-slot:right>

            <div>
                <div>
                    <label class="sr-title">{{ __('sr_description') }}</label>
                </div>
                <div v-for="(description, i) in company.signature.description" :key="'sd' + i">
                    <label>{{ description }}</label>
                </div>
                <div class="sr-title sr-margin">
                    <label>{{ __('sr_remarks') }}</label>
                </div>
                <div v-for="(remark, i) in company.signature.remarks" :key="'sr' + i">
                    <label>{{ remark.comment }}</label>
                </div>
                <div v-if="company.signature.options.length == 0">
                    <label>{{ __('sr_remarks_none') }}</label>
                </div>
            </div>

        </template>
    </vap-form-divider>

</div>
</template>

<script>
import log from '@/log';

export default {
    name: 'CompanySignRight',
    props: {
        company: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            loading: true,
        }
    },
    async created() {
        this.loading = false;
    }
}
</script>
<style scoped>

.sr-title {
    font-weight: 500;
    color: var(--text-title);
}

.sr-name {
    color: var(--text-strong);
}

.sr-weak {
    color: var(--text-weak);
}

.sr-bold {
    font-weight: 500;
}

.sr-margin {
    margin-top: 10px;
}

</style>
