<template>
<div>

    <PopupV1 :maxHeight="500" :header="__t('persons-list-select')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('persons-list-select-fill-hint') + ':' }}
            </div>
            <DialogContent v-for="(person, i) in persons" :key="'pl' + i"
                class="flw100" style="margin-bottom: 10px;" clickable @clicked="onPersonClicked(person)">

                <div class="frv2-pl flw100">

                    <div class="frv2-pl-name">
                        {{ person.firstName + ' ' + person.lastName + ' (' + person.dateOfBirth + ')' }}
                    </div>
                    <div class="frv2-pl-roles">
                        {{ person.displayAddress }}
                    </div>
                    <div class="frv2-pl-roles">
                        {{ person.displayContact }}
                    </div>
                    <div class="frv2-pl-empty" v-if="!person.displayAddress && !person.displayContact">
                        {{ __t('persons-list-select-fill-empty') }}
                    </div>

                </div>

            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../../form/items/VFButton.vue';
import DialogContent from '../../../../v1ui/DialogContent.vue';
import DialogContentHeader from '../../../../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../../../../v1ui/DialogContentItem.vue';
import VFCheckbox from '../../../form/inputs/VFCheckbox.vue';

export default {
    name: 'PersonListDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox
    },
    props: {
        params: { type: Object, default: null },
    },
    data() {
        return {
            persons: [],
            dialog: { show: true, valid: false, working: false, status: '', error: '' }
        }
    },
    methods: {

        onPersonClicked(person) {
            
            if (this.params.card.type == 'list') {
                const model = this.params.card.model[this.params.index];

                if (person.firstName && model.firstName != null) model.firstName = person.firstName;
                if (person.lastName && model.lastName != null) model.lastName = person.lastName;
                if (person.dateOfBirth && model.dateOfBirth != null) model.dateOfBirth = person.dateOfBirth;
                if (person.street && model.street != null) model.street = person.street;
                if (person.zip && model.zip != null) model.zip = person.zip;
                if (person.city && model.city != null) model.city = person.city;
                if (person.country && model.country != null) model.country = person.country;
                if (person.email && model.email != null) model.email = person.email;
                if (person.phoneNumber && model.phoneNumber != null) model.phoneNumber = person.phoneNumber;
                if (person.title && model.title != null) model.title = person.title;
                if (person.phone && model.phone != null) model.phone = person.phone;
                if (person.citizenship && model.citizenship != null) model.citizenship = person.citizenship;


            }else {

                for (const i in this.params.card.inputs) {
                    const input = this.params.card.inputs[i];

                    if (person.firstName && input.modelKey == 'firstName') input.model = person.firstName;
                    if (person.lastName && input.modelKey == 'lastName') input.model = person.lastName;
                    if (person.dateOfBirth && input.modelKey == 'dateOfBirth') input.model = person.dateOfBirth;
                    if (person.street && input.modelKey == 'street') input.model = person.street;
                    if (person.zip && input.modelKey == 'zip') input.model = person.zip;
                    if (person.city && input.modelKey == 'city') input.model = person.city;
                    if (person.country && input.modelKey == 'country') input.model = person.country;
                    if (person.email && input.modelKey == 'email') input.model = person.email;
                    if (person.phoneNumber && input.modelKey == 'phoneNumber') input.model = person.phoneNumber;
                    if (person.title && input.modelKey == 'title') input.model = person.title;
                    if (person.phone && input.modelKey == 'phone') input.model = person.phone;
                    if (person.citizenship && input.modelKey == 'citizenship') input.model = person.citizenship;

                }

            }

            this.$emit('select');
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        }

    },
    created() {
        for (const person of this.params.list) {
            console.log('person', person);

            const names = {
                firstName: this.companyTitleCase(person.firstName),
                lastName: this.companyTitleCase(person.lastName),
                displayAddress: '',
                displayContact: '',
            };

            if (person.street) {
                names.displayAddress = `${person.street}, ${person.zip} ${person.city}`;
            }
            if (person.email) {
                names.displayContact += person.email;
            }

            this.persons.push({ ...person, ...names });

        }
    }
}
</script>
<style scoped>

.frv2-pl {
    margin-top: 6px;
    margin-bottom: -5px;
}

.frv2-pl-name {
    font-weight: 500;
    color: var(--text-title);
}

.frv2-pl-roles {
    font-size: 15px;
    color: var(--text-weak);
}

.frv2-pl-empty {
    color: var(--text-disabled);
}

</style>