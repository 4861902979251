<template>
<div class="vfdv2-header">

    {{ text }}
    
</div>
</template>
<script>

export default {
    name: 'DialogContentHeader',
    props: {
        text: { type: String, default: 'HEADER' }
    }
}
</script>
<style scoped>

.vfdv2-header {
    margin-top: 20px;
    font-size: 13px;
    color: var(--text-weak);
}

</style>