<template>
<div>

    <PopupV1 :header="__t('client-export')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('client-export-hint') + ':' }}
            </div>
<!--             <DialogContent style="float: left; width: 100%; padding-top: 20px; padding-bottom: 10px;">
                <vap-row>
                    <vap-group c12>
                        <vap-label desc required :text="'Export format'" />
                        <vap-dropdown
                            v-model="formatModel"
                            :options="formatOptions"
                            :displayKey="'display'"
                            :outputKey="'value'"
                            :listKey="'display'"
                            @input="validateDialog"
                        />
                    </vap-group>
                </vap-row>
            </DialogContent>
            <div style="float: left; margin-top: 10px; margin-bottom: 10px; color: var(--text-title); font-style: italic;">
                {{ 'NB! A number of PDFs will be exported along with the main file. All these files must be kept in the same folder when importing.' }}
            </div> -->
            <DialogContent style="float: left; width: 100%; padding-bottom: 10px;">
                <DialogContentHeader :text="__t('files-document').toUpperCase() + ':'" />
                <DialogContentItem :text="filename + '.vaml'" :icon="'company'" />
                <DialogContentHeader :text="__t('files-attachments').toUpperCase() + ':'" />
                <DialogContentItem v-for="(attachment, i) in pdfs" :key="'fra' + i" :text="attachment.name" :icon="'attachment'" />
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('client-export')"
                :icon="'download'"
                @clicked="onExportClient"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import verified from '../verified';
import clientImportHelper from '../v1helpers/clientImportHelper';
import v1api from '../v1helpers/v1api';

import downloadjs from 'downloadjs';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'ExportClientDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            formatModel: 'json',
            formatOptions: [
                { value: 'json', display: 'JSON' }
            ],
            filename: 'test',
            pdfs: [],
            rawData: null
        }
    },
    methods: {

        validateDialog() {
            this.dialog.valid = this.formatModel != '';
        },

        async onExportClient() {
            this.dialog.working = true;

            const data = {
                metadata: {
                    version: 1,
                    content: 'client'
                },
                client: this.rawData.client,
                persons: this.rawData.persons,
                attachments: []
            };

            let atcString = '¤¤¤ATTACHMENTS_START¤¤¤';
            for (const i in this.pdfs) {
                atcString += '¤¤¤ATTACHMENT_START¤¤¤';
                atcString += this.pdfs[i].base64;
                data.attachments.push({
                    name: this.pdfs[i].name
                });
                atcString += '¤¤¤ATTACHMENT_END¤¤¤';
            }
            atcString += '¤¤¤ATTACHMENTS_END¤¤¤';

            let mainString = '¤¤¤DATA_START¤¤¤';
            mainString += JSON.stringify(data);
            mainString += '¤¤¤DATA_END¤¤¤';

            downloadjs("data:text/plain;charset=utf-8,\uFEFF" + mainString + atcString, this.filename + ".vaml", "text/plain");

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        this.loading = true;
        this.dialog.working = true;
        this.dialog.status = 'Loading PDFs';

        const attachments = await clientImportHelper.getPDFs(this.$root, this.$parent.flow, this.$parent.client, this.$parent.persons);
        this.pdfs = [];

        let base64; let ures;

        for (const i in attachments) {
            this.dialog.status = 'Adding attachment ' + attachments[i].name;

            base64 = await verified.downloadVerifiedFile(this.$root, attachments[i].envelopeId, attachments[i].isAttachment, attachments[i].name);

            this.pdfs.push({
                name: attachments[i].name,
                base64: base64
            });
        }

        this.filename = this.$parent.client.name;
        if (this.filename.length > 30) this.filename = this.$parent.client.name.substring(0, 29);
        
        const result = await v1api.getRawClient(this.$parent.client._id);
        this.rawData = {
            client: result.client,
            persons: result.persons
        };

        this.dialog.working = false;
        this.loading = false;
        this.validateDialog();
    }
}
</script>