var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vap-card",class:[
        {'vap-card-list' : _vm.list},
        {'vap-card-listc' : _vm.compactList},
        {'vap-card-selected' : _vm.selected},
        {'vap-card-designer': _vm.designer },
        {'vap-card-disabled': _vm.disabled },
        {'vap-card-dotted': _vm.dotted },
        {'vap-card-shadow': _vm.innerShadow },
        {'vap-card-shadowhigh': _vm.innerShadowHighlight },
        {'vap-card-valid': _vm.validated },
        {'card-shadow': !_vm.noShadow } ],attrs:{"id":_vm.id},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.handleClick($event)}}},[(_vm.header)?_c('div',{staticClass:"vap-card-header-c",on:{"click":function($event){$event.stopPropagation();return _vm.handleClick($event)}}},[(_vm.icon)?_c('div',{staticClass:"vap-card-icon"},[_c('vap-icon',{attrs:{"icon":_vm.icon,"small":""}})],1):_vm._e(),_c('div',{staticClass:"vap-card-header"},[_c('label',[_vm._v(_vm._s(_vm.header))])]),_c('div',{staticClass:"vesc-actions"},[_vm._t("actions")],2)]):_vm._e(),_c('div',{staticClass:"vap-card-content",class:{'vap-card-np' : _vm.noPadding},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick($event)}}},[_vm._t("default")],2),_c('div',{staticClass:"ac-card-logic",attrs:{"id":'l' + _vm.id},on:{"click":function($event){$event.stopPropagation();return _vm.handleLogicClick($event)},"mousedown":_vm.handleLogicClick}},[_c('svg',{staticStyle:{"transform":"scale(0.7)"},attrs:{"height":"24","version":"1.1","width":"24","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 9 C 3 10.093063 3.9069372 11 5 11 L 9 11 C 10.093063 11 11 10.093063 11 9 L 11 5 C 11 3.9069372 10.093063 3 9 3 L 5 3 z M 5 5 L 9 5 L 9 9 L 5 9 L 5 5 z M 5 13 L 5 15 L 7 15 L 7 13 L 5 13 z M 15 13 C 13.906937 13 13 13.906937 13 15 L 13 19 C 13 20.093063 13.906937 21 15 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 15 C 21 13.906937 20.093063 13 19 13 L 15 13 z M 15 15 L 19 15 L 19 19 L 15 19 L 15 15 z M 5 17 L 5 19 L 7 19 L 7 17 L 5 17 z M 9 17 L 9 19 L 11 19 L 11 17 L 9 17 z"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }