<template>
<div>
    <PageHeader v-if="!loading" :actions="actions" compactable :offset="122" @scrolled="scrolled = $event" @action="onAction">
        <template v-slot:content>
            <vap-row v-if="!loading && company">
                <vap-group auto noPadding>
                    <vap-label :text="'Admin'" desc/>
                    <vap-label :text="company.name" value />
                </vap-group>
            </vap-row>
        </template>
        <template v-slot:compact>
            <div v-if="!loading && company">
                <span>{{ company.name }}</span>
            </div>
        </template>
    </PageHeader>

    <div class="aml-page" v-if="!loading">

        <vap-form-divider :left="55" :right="45">
            <template v-slot:left>

                <vap-label header2 :text="'Company'" style="margin-bottom: 10px;" />

                <vap-card>
                    <vap-row>
                        <vap-group c12>
                            <vap-label desc :text="'Display Name'" />
                            <vap-input v-model="company.name" />
                        </vap-group>
                    </vap-row>
                </vap-card>

            </template>
            <template v-slot:right>

                <vap-label header2 :text="'Users'" style="margin-bottom: 10px;" />

                <div class="amladmin-users">
                    <div class="amladmin-user" v-for="(user, i) in users" :key="'au' + i">
                        <div class="amladmin-ucon">
                            <div class="amladmin-name">
                                {{ user.givenName + ' ' + user.familyName }}
                            </div>
                            <div class="amladmin-stat">
                                <div class="amladmin-uadm" v-if="user.isAdmin">
                                    {{ 'Admin'}}
                                </div>
                                <div class="amladmin-uid">
                                    {{ user.uid }}
                                </div>
                            </div>
                        </div>
                        <div class="amladmin-actions">
                            <vap-icon :icon="'locked'" action @clicked="onMakeAdminClicked(user)" />
                        </div>
                    </div>
                </div>

            </template>
        </vap-form-divider>

    </div>

    <ve-popup v-if="makeAdminDialog.show" :header="'Make admin'" :width="500" @close="makeAdminDialog.show = false" hideCloseButton>
        <div style="padding: 20px;">
            {{ 'User will be made admin' }}
        </div>
        <div style="padding: 20px;">
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="makeAdminDialog.show = false" :disabled="makeAdminDialog.working" />
                <vap-button :text="'OK'" uppercase @clicked="onMakeAdmin" :working="makeAdminDialog.working" />
            </vap-button-row>
        </div>
    </ve-popup>

</div>
</template>
<script>
import backend from '../backend';
import uiHelper from '../helpers/uihelper';

import PageHeader from '../v1ui/PageHeader.vue';

export default {
    name: 'AdminCompany',
    components: {
        PageHeader
    },
    props: {
        id: { type: String, default: '' }
    },
    data() {
        return {
            loading: true,
            scrolled: false,
            actions: [
                { icon: 'delete', action: 'delete' },
                { icon: 'save', action: 'save' }
            ],

            company: null,
            users: [],

            makeAdminDialog: { show: false, working: false, user: null }
        }
    },
    methods: {
        
        onAction(action) {
            if (action.action == 'save') {
                this.onSave();
            }
        },

        onMakeAdminClicked(user) {
            this.makeAdminDialog.user = user;
            this.makeAdminDialog.show = true;
        },

        async onMakeAdmin() {
            this.makeAdminDialog.working = true;

            const data = {
                isAdmin: true
            };
            await backend.adminUpdateUser(this.company._id, this.company.companyId, this.company.key, this.makeAdminDialog.user._id, data);

            this.makeAdminDialog.working = false;
            this.makeAdminDialog.show = false;

            this.reloadCompany();
        },

        async onSave() {
            uiHelper.setCardActionWorking(this.actions, 'save', true);

            console.log('compay', this.company);

            const updateData = {
                name: this.company.name
            };
            //await backend.adminUpdateCompany(this.company._id, updateData);

            uiHelper.setCardActionWorking(this.actions, 'save', false);
        },

        async reloadCompany() {
            this.loading = true;

            const response = await backend.adminGetCompany(this.id);

            this.company = response.data.company;
            this.users = response.data.users;

            console.log('company', this.company);

            this.loading = false;
        }

    },
    async created() {
        await this.reloadCompany();
    }
}
</script>
<style scoped>

.amladmin-users {
    float: left;
    width: 100%;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
}

.amladmin-user {
    display: flex;
    padding: 6px 14px;
    border-bottom: solid 1px var(--input-border);
}

.amladmin-ucon {
    flex-grow: 100;
}

.amladmin-name {
    font-size: 17px;
    font-weight: 500;
    color: var(--text-title);
}

.amladmin-stat {
    display: flex;
    font-weight: 500;
    color: var(--text-accent);
}

.amladmin-uadm {
    margin-right: 8px;
}

.amladmin-uid {
    color: var(--text-weak);
}

.amladmin-actions {
    flex-shrink: 0;
}

</style>