<template>
<div class="splitv1">
    
    <div class="splitv1-l" :style="'width: ' + left + '%;'">
        <slot name="left" />
    </div>

    <div class="splitv1-m" :style="'width: ' + middle + '%;'" v-if="middle > 0">
        <slot name="middle" />
    </div>

    <div class="splitv1-r" :style="'width: ' + right + '%;'">
        <slot name="right" />
    </div>

</div>
</template>
<script>
export default {
    name: 'SplitterV1',
    props: {
        left: { type: Number, default: 50 },
        middle: { type: Number, default: 0 },
        right: { type: Number, default: 50 },
    },
    data() {
        return {
            
        }
    },
    methods: {
        


    },
    created() {
        
    }
}
</script>
<style scoped>

.splitv1 {
    float: left;
    width: 100%;
}

.splitv1-l {
    float: left;
    min-height: 100px;

    padding-right: 10px;
    box-sizing: border-box;

    /* background-color: antiquewhite; */
}

.splitv1-m {
    float: left;
    min-height: 100px;

    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;

    /* background-color: aquamarine; */
}

.splitv1-r {
    float: left;
    min-height: 100px;

    padding-left: 10px;
    box-sizing: border-box;

    /* background-color: bisque; */
}

</style>