<template>
<div class="flw100" style="padding: 20px 0px;">
    
    <div class="flw100" style="padding: 0px 5px; padding-bottom: 20px; font-size: 15px; font-style: italic;" v-if="!hasResult && !hasSelected">
        {{ __t($root.config.search.org.se.numberOnly ? 'search-se-hint-number-only' : 'search-se-hint') }}
    </div>

    <vap-row v-if="!hasResult && !hasSelected && !$root.config.search.org.se.numberOnly" style="margin-top: 10px;">
        <VFLabel :text="__t('search-se-search-hint')" desc />
        <div class="flw100" style="display: flex;">
            <div style="flex-grow: 100; margin-right: 10px;">
                <vap-input v-model="searchString" noValidation :placeholder="__('common_required')" :disabled="working" @onreturn="onSearch" />
            </div>
            <div>
                <vap-button :text="__('common_search')" action uppercase :working="working" @clicked="onSearch" />
            </div>
        </div>
    </vap-row>

    <vap-row v-if="!hasResult && !hasSelected && $root.config.search.org.se.numberOnly" style="margin-top: 10px;">
        <VFLabel :text="__t('search-se-search-hint-number-only')" desc />
        <div class="flw100" style="display: flex;">
            <div style="flex-grow: 100; margin-right: 10px;">
                <vap-input v-model="searchString" noValidation :placeholder="__('common_required')" :disabled="working" @onreturn="onSearch" @input="onValidateNumber" />
            </div>
            <div>
                <vap-button :text="__('common_search')" action uppercase :disabled="!numberValid" :working="working" @clicked="onSearch" />
            </div>
        </div>
    </vap-row>

    <div v-if="hasResult && !hasSelected">

        <div style="padding-bottom: 5px; padding-left: 5px; font-size: 18px; font-weight: 500; color: var(--text-title);">
            {{ resultList.length + ' ' + __t('search-result-count') + ' "' + searchString + '"' }}
        </div>

        <div class="v2search-result-item" v-for="(company, i) in resultList" :key="'r' + i" @click="onResultSelected(company)" :class="[
            {'v2search-result-item-first': i == 0 },
            {'v2search-result-item-last': i == resultList.length - 1 },
        ]">
            <div>
                <span class="v2search-result-name">{{ company.name }}</span>
                <span class="v2search-result-org">{{ company.identity }}</span>
            </div>
            <div class="v2search-result-adr">
                {{ company.address }}
            </div>
        </div>

        <div style="padding-top: 3px; padding-left: 5px; font-size: 15px; font-style: italic; color: var(--text-weak);">
            {{ __t('search-select-company') }}
        </div>

    </div>

    <vap-row v-if="hasSelected">
        <vap-group c6>
            <vap-label :text="__('common_name')" desc />
            <vap-label :text="name" value />
        </vap-group>
        <vap-group c6>
            <vap-label :text="__('company_orgnr')" desc />
            <vap-label :text="identity" value />
        </vap-group>
        <vap-group c12>
            <vap-label :text="__('common_address')" desc />
            <vap-label :text="address" value />
        </vap-group>
    </vap-row>

    <div v-if="error" class="flw100" style="color: var(--val-error); margin-top: 5px;">
        {{ errorMessage }}
    </div>

    <div v-if="!numberValid" class="flw100" style="color: var(--val-error); margin-top: 5px;">
        {{ numberError }}
    </div>

</div>
</template>
<script>
import dataSwedenV1 from '../v1data/dataSwedenV1';

import VFLabel from '../components/form/items/VFLabel.vue';

export default {
    name: 'SearchSE2',
    components: {
        VFLabel,
    },
    data() {
        return {
            searchString: '',
            numberValid: false,
            numberError: '',
            working: false,
            hasResult: false,
            resultList: [],
            hasSelected: false,
            error: false,
            errorMessage: '',

            valid: false,
            searchValid: false,
            country: 'se',
            source: 'trapets-bisnode',
            name: '',
            identity: '',
            address: '',
            trapetsData: null,
            underCreation: false,
        }
    },
    methods: {

        onValidateNumber() {
            this.numberValid = true;

            const array = '1234567890-';

            for (let i=0; i<this.searchString.toString().length; i++) {
                if (!array.includes(this.searchString.toString()[i])) this.numberValid = false;
            }

            if (this.searchString.includes('-')) {
                if (this.searchString.length < 11 || this.searchString.length > 11) this.numberValid = false;
            }else {
                if (this.searchString.length < 10 || this.searchString.length > 10) this.numberValid = false;
            }

            if (!this.numberValid ) {
                this.numberError = this.__t('search-se-number-only-error');
            }else {
                this.numberError = '';
            }
        },
        
        async onSearch() {
            this.working = true;
            this.error = false;

            const searchString = this.searchString;

            try {
                const result = await dataSwedenV1.getTrapetsData(this.searchString);
                console.log('result', result);

                if (!result.success) {
                    this.errorMessage = this.__t('search-result-error-1') + ' "' + searchString + '". ' + this.__t('search-result-error-2');
                    this.error = true;
                    this.working = false;
                    return;
                }


                // Compile list of different companies
                for (const i in result.data.ResponseItems[0].Entities) {
                    const entity = result.data.ResponseItems[0].Entities[i];
                    console.log('entity', entity);
                    if (entity.CorporateId) {
                        let match = false;
                        for (const j in this.resultList) {
                            if (this.resultList[j].CorporateId == entity.CorporateId) {
                                match = true;
                            }
                        }
                        if (!match) {
                            this.resultList.push({
                                CorporateId: entity.CorporateId,
                                data: JSON.parse(JSON.stringify(result.data)),
                            });
                        }
                    }
                }

                // Remove companies from each list
                for (const i in this.resultList) {
                    for (let j=this.resultList[i].data.ResponseItems[0].Entities.length - 1; j>-1; j--) {
                        if (this.resultList[i].data.ResponseItems[0].Entities[j].CorporateId != this.resultList[i].CorporateId) {
                            this.resultList[i].data.ResponseItems[0].Entities.splice(j, 1);
                        }
                    }
                }

                // Set name and address
                for (const i in this.resultList) {

                    this.resultList[i].name = this.resultList[i].data.ResponseItems[0].Entities[0].Name;
                    this.resultList[i].identity = this.resultList[i].data.ResponseItems[0].Entities[0].CorporateId;

                    let address = '';
                    try {
                        for (const k in this.resultList[i].data.ResponseItems[0].Entities) {
                            const entity = this.resultList[i].data.ResponseItems[0].Entities[k];
                            for (const j in entity.Addresses) {
                                const adr = entity.Addresses[j];
                                if (adr.Street) {
                                    address = adr.Street + ', ' + adr.ZipCode + ' ' + adr.City;
                                }
                            }
                        }
                    }catch (ex2) {
                        address = '';
                    }
                    this.resultList[i].address = address;

                }

                console.log('LIST', this.resultList);


                if (this.resultList.length == 1) {

                    this.name = this.resultList[0].name;
                    this.identity = this.resultList[0].identity;
                    this.address = this.resultList[0].address;
                    this.trapetsData = this.resultList[0].data;

                    this.valid = true;
                    this.searchValid = true;
                    this.$emit('validate', this.valid);

                    this.hasSelected = true;

                }else if (this.resultList.length > 1) {

                    this.hasResult = true;

                }else {
                    this.errorMessage = this.__t('search-result-empty') + ' "' + searchString + '"';
                    this.error = true;
                }

            }catch (ex) {
                this.errorMessage = this.__t('search-result-empty') + ' "' + searchString + '"';
                this.error = true;
            }

            this.working = false;
        },

        onResultSelected(company) {

            this.name = company.name;
            this.identity = company.identity;
            this.address = company.address;
            this.trapetsData = company.data;

            this.valid = true;
            this.searchValid = true;
            this.$emit('validate', this.valid);

            this.hasSelected = true;
        },

        resetSearch() {
            this.valid = false;
            this.searchValid = false;
            this.hasSelected = false;
            this.hasResult = false;
            this.resultList = [];
            this.searchString = '';
            this.errorMessage = '';
            this.numberValid = false;
            this.numberError = '';
            this.error = false;
            this.name = '';
            this.identity = '';
            this.address = '';
            this.trapetsData = null;
            this.$emit('validate', this.valid);
        },

        getInitialData() {
            return {
                type: 'org',
                trapetsData: this.trapetsData,
                name: this.name,
                identity: this.identity,
                country: this.country,
                source: this.source
            };
        },

    },
}
</script>
<style scoped>

.v2search-result-item {
    margin-top: -1px;
    padding: 8px 16px;
    border: solid 1px var(--input-border);
    cursor: pointer;
}
.v2search-result-item:hover {
    background-color: var(--item-hover);
}

.v2search-result-item-first {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.v2search-result-item-last {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.v2search-result-name {
    font-weight: 500;
    color: var(--text-title);
}

.v2search-result-org {
    margin-left: 6px;
    font-weight: 500;
    color: var(--text-weak);
}

.v2search-result-adr {
    color: var(--text-weak);
}

</style>