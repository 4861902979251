<template>
<div class="cardheader" @click.stop="handleClick">
    
    <h1 :class="[{ 'selected' : selected }]"
        :style="style">{{ cardData.value[$root.locale.key] }}</h1>

</div>
</template>

<script>
export default {
    name: 'vf-header',
    props: {
        cardData: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    computed: {
        style() {
            return [
                `font-size: ${((this.cardData.size || 26).toString())}px`,
                `margin: ${((this.cardData.margin || 0).toString())}px 0`
            ].join("; ");
        }
    },
    data() {
        return {
            
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

    },
    mounted() {

    }
}
</script>
<style scoped>

.cardheader {
    position: relative;
    float: left;
    width: 100%;
}

.selected {
    outline: solid 2px #238ccc;
}

</style>
