<template>
<div class="prl">

    <div class="prl-list" v-for="(list, i) in lists" :key="i"
    v-show="list.persons.length > 0">

        <div class="prl-top">

            <div class="prl-top-name">
                {{ list.custom ? list.key : __(list.key) }}
            </div>

            <div class="prl-top-count">
                {{ list.count }}
            </div>

        </div>

        <div class="prl-item" v-for="(person, j) in list.persons" :key="'l' + i + j" :class="[
            {'prl-item-last': j == list.persons.length - 1 },
            {'prl-item-selected': person.selected }
        ]"
        @click="showAddPopup(person, false)" @mouseenter="onListPersonEnter($event, person, 'l' + i + j)" @mouseleave="onListPersonLeave('l' + i + j)">

            <div class="prl-content" :class="[
                {'prl-removed': wasRoleRemoved(person, list) },
            ]">

                <div class="prl-icon">
                    <vap-icon :icon="person.selected ? 'check' : 'chev-l'" small :color="'ext'" />
                </div>

                <div class="prl-name">
                    {{ person.displayName }}
                </div>

                <div class="prl-flags">

                    <div class="prl-flag" v-if="person.screening.done" :class="[
                        {'prl-flag-success': !person.screening.isPep && !person.screening.hasSanction },
                        {'prl-flag-warning': person.screening.isPep },
                        {'prl-flag-error': person.screening.hasSanction },
                    ]">
                        <vap-icon tiny :icon="'pep'" :color="'ext'" absolute :top="-1" :right="-1" />
                    </div>

                    <div class="prl-flag" v-if="person.idc.done" :class="[
                        {'prl-flag-success': true == true },
                        {'prl-flag-warning': true == false },
                        {'prl-flag-error': true == false },
                    ]">
                        <vap-icon tiny :icon="'card'" :color="'ext'" absolute :top="-1" :right="-1" />
                    </div>

                    <div class="prl-flag" v-if="person.web.done" :class="[
                        {'prl-flag-success': person.web.hitLevel == 'ok' },
                        {'prl-flag-warning': person.web.hitLevel == 'some' },
                        {'prl-flag-error': person.web.hitLevel == 'hits' },
                    ]">
                        <vap-icon tiny :icon="'search'" :color="'ext'" absolute :top="-1" :right="-1" />
                    </div>

                </div>

                <div class="prl-share" :class="{'prl-share-short': isManualOnly(person, list) }">
                    {{ getRolePercentage(person, list) }}
                </div>

            </div>

            <div class="prl-actions">
                <div class="prl-action" v-if="isManualOnly(person, list)" @click.stop="onRemoveManualRole(person, list)">
                    <vap-icon :icon="'delete'" small />
                </div>
                <div class="prl-action" v-if="!$root.config.persons.infoDisabled" @click.stop="onInfoClicked(person)">
                    <vap-icon :icon="'info'" small />
                </div>
            </div>

        </div>

    </div>

    <div v-if="tooltipShow" class="ptt-base" :style="'bottom: ' + tooltipBottom + 'px; right: ' + tooltipRight + 'px;'">



    </div>
    
</div>
</template>
<script>
import log from '../log';
import v1personHelper from '../v1helpers/v1personHelper';

export default {
    name: 'PersonRoleList',
    components: {
        
    },
    props: {
        persons: { type: Array, default: null },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            tooltipEvents: [],
            tooltipShow: false,
            tooltipBottom: 0,
            tooltipRight: 0,
            tooltipPerson: {},
        }
    },
    watch: {
        persons: {
            deep: false,
            handler(val) {
                this.processPersons();
                
                // TODO fix this
                this.$nextTick(() => {
                    this.$forceUpdate();
                });
            }
        }
    },
    methods: {

        test(e) {
            console.log('asdasdasdasd')
        },

        //#region Loading -----------------------------------------------------------------------------------------------------
        
        initializeLists() {
            // Initialize/reset the list on the right
            // All different roles returned by BankID AML should have it's own list
            // If there are any persons ending up in 'other', that list should probobly be added here
            return [
                { persons: [], expanded: true, id: 'role_proprietor', key: 'proprietor' },
                { persons: [], expanded: true, id: 'role_ceo', key: 'ceo' },
                { persons: [], expanded: true, id: 'role_chair', key: 'chair' },
                { persons: [], expanded: true, id: 'role_procura', key: 'screening_role_procura' },
                { persons: [], expanded: true, id: 'role_deputy_chair', key: 'screening_role_deputy_chairman' },
                { persons: [], expanded: true, id: 'role_signatory', key: 'screening_role_sign_right' },
                { persons: [], expanded: true, id: 'role_rrh', key: 'screening_role_rrhs' },
                { persons: [], expanded: true, id: 'role_board_member', key: 'screening_role_board_members' },
                { persons: [], expanded: true, id: 'role_deputy_bm', key: 'screening_role_deputy_board_members' },
                { persons: [], expanded: true, id: 'role_shareholder', key: 'screening_role_shareholders' },
                { persons: [], expanded: true, id: 'role_accountant', key: 'screening_role_accountant' },
                { persons: [], expanded: true, id: 'role_secretary', key: 'scr_secretary' },
                { persons: [], expanded: true, id: 'role_director', key: 'scr_directors' },
                { persons: [], expanded: true, id: 'role_other', key: 'screening_role_others' },
                { persons: [], expanded: true, id: 'role_customer_contact', key: 'role_customer_contact' },
                { persons: [], expanded: true, id: 'role_individual', key: 'role_individual' },
                { persons: [], expanded: true, id: 'role_owner_10', key: 'role_owner_10' },
                { persons: [], expanded: true, id: 'role_cfo', key: 'role_cfo' },
                { persons: [], expanded: true, id: 'role_commercial', key: 'role_commercial' },
                { persons: [], expanded: true, id: 'role_none', key: 'role_nones' },
            ];
        },

        processPersons() {
            //log.v('Person Role List: Processing persons', this.persons);
            if (!this.persons) return;

            // Reset the lists on the right
            const lists = this.initializeLists();

            // Update the list with any custom role names
            for(const i in this.persons) {
                const person = this.persons[i];

                for(let role of person.roles) {
                    if(!role.roleName || lists.find(x => x.id == role.roleName)) {
                        continue;
                    }

                    lists.push(
                        { persons: [], expanded: true, id: role.roleName, key: role.roleName, custom: true },
                    )
                }         
            }

            this.lists = lists;

            // Create the right-side lists by looping the main list
            for (const i in this.persons) {
                //console.log(this.persons[i].info.firstName + ' ', this.persons[i])
                let match = false;
                for (const j in this.lists) {
                    if (v1personHelper.hasRole(this.persons[i], this.lists[j].id)) {
                        this.lists[j].persons.push(this.persons[i]);
                        match = true;
                    }
                }
                // No match meens the role of this person is not defined as it's own list
                // Add the person to the 'other' list
                if (!match) {
                    let otherList = 'role_other';
                    // If the person has no roles, he was removed from both the org-search and kyc
                    if (this.persons[i].roles.length == 0) {
                        otherList = 'role_none';
                    }
                    for (const j in this.lists) {
                        if (this.lists[j].id == otherList) this.lists[j].persons.push(this.persons[i]);
                    }
                }

                // Hide help message if a person is selected
                if (this.persons[i].selected) this.hasSelected = true;
            }
            // Update the right-side lists with the proper title (with count)
            for (const j in this.lists) {
                if (this.lists[j].persons.length > 1) {
                    this.lists[j].count = this.lists[j].persons.length;
                }else {
                    this.lists[j].count = '';
                }
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Actions -----------------------------------------------------------------------------------------------------

        showAddPopup(person, manual, contact) {
            if (this.disabled) return;
            if (person.selected) return;
            this.$emit('addkeyrole', person);
        },

        wasRemoved(person, list) {
            return false;
        },

        isManualOnly(person, list) {
            for (const i in person.roles) {
                if (list.id == 'role_individual') return false;
                if (person.roles[i].id == list.id && person.roles[i].source != 'man') return false;
            }
            return true;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        onRemoveManualRole(person, list) {
            if (this.disabled) return;
            this.$emit('removerole', person, list.id);
        },

        //#region Info popup --------------------------------------------------------------------------------------------------

        onListPersonEnter(event, person, key) {
            if (!this.$root.config.persons.listPopup) return;
            this.tooltipEvents.push({
                key: key,
                event: event,
                person: person
            });
            const self = this;
            setTimeout(function() {
                self.showPersonTooltip(key);
            }, 500);
        },
        onListPersonLeave(key) {
            if (!this.$root.config.persons.listPopup) return;
            for (const i in this.tooltipEvents) {
                if (this.tooltipEvents[i].key == key) {
                    //console.log('removed');
                    this.tooltipEvents.splice(i, 1);
                    break;
                }
            }
            this.hidePersonTooltip();
        },

        showPersonTooltip(key) {
            const event = this.tooltipEvents[this.tooltipEvents.length - 1];
            if (!event) return;
            if (event.key != key) return;
            //console.log('show', event);
            const body = document.body, html = document.documentElement;
            const pageHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            this.tooltipBottom = pageHeight - event.event.pageY + 40;
            this.tooltipRight = 40;
            //console.log('showing at ' + this.tooltipRight + ', ' + this.tooltipBottom);
            this.tooltipPerson = event.person;
            this.tooltipShow = true;
        },

        hidePersonTooltip() {
            this.tooltipShow = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        onInfoClicked(person) {
            this.$emit('info', person);
        },

        //#region Roles -------------------------------------------------------------------------------------------------------

        getDisplayRoles(person) {
            return v1personHelper.getDisplayRoles(person, this.$root);
        },

        getRolePercentage(person, list) {
            return v1personHelper.getRolePercentage(person, list.id);
        },

        wasRoleRemoved(person, list) {
            return false;
            for (const i in person.mergedRoles) {
                if (person.mergedRoles[i].id == list.id) {
                    return person.mergedRoles[i].kycRemoved;
                }
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    async created() {
        this.processPersons();
    }
}
</script>
<style scoped>

.prl-list {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 4px;
    border: solid 1px var(--input-border);

    box-sizing: border-box;
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
}


.prl-top {
    display: flex;
    color: var(--text-strong);
    border-bottom: solid 1px var(--input-border);
    font-size: 15px;
    font-weight: bold;
    color: var(--text-weak);
}
.prl-top-name {
    flex-grow: 100;
    padding-top: 5px;
    padding-left: 8px;
    padding-bottom: 4px;
}
.prl-top-count {
    padding-top: 5px;
    padding-right: 8px;
}


.prl-item {
    display: flex;
    color: var(--text-strong);
    fill: var(--text-strong);
    border-bottom: solid 1px var(--input-border);
}
.prl-item-last {
    border-bottom: none;
}

.prl-content {
    flex-grow: 100;
    position: relative;
    display: flex;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}
.prl-content:hover {
    background-color: var(--item-hover);
}
.prl-item-selected .prl-content {
    color: var(--text-accent);
    background-color: var(--page-background);
    cursor: not-allowed;
    fill: var(--text-accent);
}
.prl-item-selected .prl-content:hover {
    background-color: var(--page-background);
}

.prl-icon {
    margin-left: 5px;
}
.prl-name {
    flex-grow: 100;
    padding-top: 5px;
    padding-left: 6px;
    padding-bottom: 6px;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}
.prl-removed .prl-name {
    text-decoration: line-through;
}

.prl-flags {
    display: flex;
}
.prl-share {
    padding-top: 6px;
    padding-right: 5px;
    text-align: right;
    min-width: 50px;
}
.prl-share-short {
    min-width: 16px;
}
.prl-removed .prl-share {
    text-decoration: line-through;
}

.prl-flag {
    position: relative;
    width: 21px; height: 21px;
    margin-top: 5px;
    margin-right: 5px;
    fill: var(--disabled-text);
    border-radius: 50%;
    background-color: var(--disabled-background);
    box-sizing: border-box;
}
.prl-flag-success {
    fill: var(--success-text);
    background-color: var(--success-background);
}
.prl-flag-warning {
    fill: var(--warning-text);
    background-color: var(--warning-background);
}
.prl-flag-error {
    fill: var(--error-text);
    background-color: var(--error-background);
}


.prl-actions {
    display: flex;
}

.prl-action {
    width: 34px;
    height: 30px;
    padding-top: 1px;
    padding-left: 2px;
    border-left: solid 1px var(--input-border);
    background-color: var(--page-background);
    box-sizing: border-box;
    cursor: pointer;
}
.prl-action:hover {
    background-color: var(--item-hover);
}



.ptt-base {
    position: absolute;
    width: 400px;
    min-height: 200px;
    padding: 20px;
    border: solid 2px var(--accent);
    background-color: var(--card-background);
    -webkit-box-shadow: 1.5px 0.5px 4px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 1.5px 0.5px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 1.5px 0.5px 4px 0px rgba(0,0,0,0.2);
    z-index: 9999;
}

</style>