// Main
import Login from './Login'

// Basic
import Label from './basic/Label'
import Button from './basic/Button'
import ButtonRow from './basic/ButtonRow'
import ButtonGroup from './basic/ButtonGroup'
import Spinner from './basic/Spinner'
import VeAlert from './basic/VeAlert'
import Slider from './basic/Slider'
import Tooltip from './basic/Tooltip'
import TextEditor from './basic/TextEditor'
import HelpText from './basic/HelpText'
import Icon from './basic/Icon'
import Tabs from './basic/Tabs'
import InfoCard from './basic/InfoCard'
import VeSwitch from './basic/VeSwitch'


// Inputs
import Input from './inputs/Input'
import Checkbox from './inputs/Checkbox'
import Dropdown from './inputs/Dropdown'
import Datepicker from './inputs/Datepicker'
import PhoneInput from './inputs/PhoneInput'
import TextArea from './inputs/TextArea'
import CountryDropdown from './inputs/CountryDropdown'

// Containers
import FormDivider from './containers/FormDivider'
import Card from './containers/Card'
import Group from './containers/Group'
import Row from './containers/Row'
import Form from './containers/Form'
import ExpandCard from './containers/ExpandCard'
import Popup from './containers/Popup'
import DropdownMenu from './containers/DropdownMenu'

// Items
import ActionItem from './items/ActionItem'
import ActionSwitch from './items/ActionSwitch'
import ActionSwitchHelp from './items/ActionSwitchHelp'
import RadioCard from './items/RadioCard'
import Item from './items/Item'
import StatusLabel from './items/StatusLabel'
import NumberItem from './items/NumberItem'

// Special
import EventLog from './special/EventLog'
import BrregSearch from './special/BrregSearch'
import FileUpload from './documents/FileUpload'

export default {
    install(Vue) {

        // Main
        Vue.component(Login.name, Login)

        // Basic
        Vue.component(Label.name, Label)
        Vue.component(Button.name, Button)
        Vue.component(ButtonRow.name, ButtonRow)
        Vue.component(ButtonGroup.name, ButtonGroup)
        Vue.component(Spinner.name, Spinner)
        Vue.component(VeAlert.name, VeAlert)
        Vue.component(Slider.name, Slider)
        Vue.component(Tooltip.name, Tooltip)
        Vue.component(TextEditor.name, TextEditor)
        Vue.component(HelpText.name, HelpText)
        Vue.component(Icon.name, Icon)
        Vue.component(Tabs.name, Tabs)
        Vue.component(InfoCard.name, InfoCard)
        Vue.component(VeSwitch.name, VeSwitch)

        // Inputs
        Vue.component(Input.name, Input)
        Vue.component(Checkbox.name, Checkbox)
        Vue.component(Dropdown.name, Dropdown)
        Vue.component(Datepicker.name, Datepicker)
        Vue.component(PhoneInput.name, PhoneInput)
        Vue.component(TextArea.name, TextArea)
        Vue.component(CountryDropdown.name, CountryDropdown)
        
        // Containers
        Vue.component(FormDivider.name, FormDivider)
        Vue.component(Card.name, Card)
        Vue.component(Group.name, Group)
        Vue.component(Row.name, Row)
        Vue.component(Form.name, Form)
        Vue.component(ExpandCard.name, ExpandCard)
        Vue.component(Popup.name, Popup)
        Vue.component(DropdownMenu.name, DropdownMenu)

        // Items
        Vue.component(ActionItem.name, ActionItem)
        Vue.component(ActionSwitch.name, ActionSwitch)
        Vue.component(ActionSwitchHelp.name, ActionSwitchHelp)
        Vue.component(RadioCard.name, RadioCard)
        Vue.component(Item.name, Item)
        Vue.component(StatusLabel.name, StatusLabel)
        Vue.component(NumberItem.name, NumberItem)

        // Special
        Vue.component(EventLog.name, EventLog)
        Vue.component(BrregSearch.name, BrregSearch)
        Vue.component(FileUpload.name, FileUpload)

    }
}