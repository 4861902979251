<template>
    <div class="frmod-cl-dd" v-on-clickaway="hideDropdown" tabindex="0" @focus="onFocus" @blur="onBlur" @keydown="handleKeyDown"
    :class="[
        {'frmod-cl-dd-disabled': disabled},
        {'frmod-cl-dd-success': colored && (value == 'chkPass' || value == 'chkYes')},
        {'frmod-cl-dd-warning': colored && (value == 'chkPassF')},
        {'frmod-cl-dd-error': colored && (value == 'chkFail' || value == 'chkNo')},
        {'frmod-cl-dd-gray': colored && (value == 'chkNA')},
    ]">

        <div class="frmod-cl-dd-val" @click="handleValueClick" :style="'min-height: ' + height + 'px;'">
            <label style="color: var(--card-background);" v-if="!value">{{ 'not selected' }}</label>
            <label v-if="value">{{ getDisplayValue(value) }}</label>
            <vap-icon :icon="'chev-d'" tiny absolute :top="0" :right="0" v-if="!disabled" />
        </div>

        <div class="frmod-cl-dd-lst" v-if="expanded">
            <div class="frmod-cl-dd-i" v-for="(option, i) in options" :key="'cld' + i" @click="handleOptionClick(option)">
                {{ option.en_EN }}
            </div>
        </div>

    </div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway"

export default {
    name: "frmod-checklist-dropdown",
    mixins: [clickaway],
    props: {
        value: { type: String, default: '' },
        options: { type: Array, default: () => { return [] }},
        height: { type: Number, default: 18 },
        colored: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false }
    },
    data() {
        return {
            expanded: false,
            focusDelay: false,
        }
    },
    watch: {
        width: {
            deep: false,
            handler(val) {
                
            }
        }
    },
    methods: {
        
        onFocus() {
            if (this.disabled) return;
            this.expanded = true;
            const self = this;
            self.focusDelay = true;
            setTimeout(function() {
                self.focusDelay = false;
            }, 200);
        },

        onBlur() {
            if (this.disabled) return;
            this.expanded = false;
        },

        handleKeyDown(event) {
            
        },

        handleValueClick() {
            if (this.disabled) return;
            if (!this.focusDelay) this.expanded = !this.expanded;
        },

        hideDropdown() {
            this.expanded = false;
        },

        handleOptionClick(option) {
            if (this.disabled) return;
            this.expanded = false;
            this.$emit('input', option.value);
        },

        getDisplayValue(value) {
            for (const i in this.options) {
                if (this.options[i].value == value) return this.options[i].en_EN;
            }
            return 'ERROR';
        }

    },
    mounted() {
        
    }
}

</script>
<style>

.frmod-cl-dd {
    position: relative;
}

.frmod-cl-dd-val {
    position: relative;
    padding: 2px 5px;
    cursor: pointer;
}
.frmod-cl-dd-val:hover {
    position: relative;
    background-color: lightgray;
}
.frmod-cl-dd-val label {
    cursor: pointer;
}

.frmod-cl-dd-lst {
    color: black;
    position: absolute;
    min-width: 160px;
    border: solid 1px var(--header);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: var(--card-background);
    z-index: 19;
}

.frmod-cl-dd-i {
    padding: 2px 5px;
    cursor: pointer;
}
.frmod-cl-dd-i:hover {
    background-color: lightgray;
}


.frmod-cl-dd-disabled {
    
}
.frmod-cl-dd-disabled .frmod-cl-dd-val {
    cursor: not-allowed;
}
.frmod-cl-dd-disabled .frmod-cl-dd-val label {
    cursor: not-allowed;
}
.frmod-cl-dd-disabled .frmod-cl-dd-val:hover {
    background-color: transparent;
}
.frmod-cl-dd-disabled .frmod-cl-dd-i:hover {
    background-color: transparent;
}


.frmod-cl-dd-success {
    color: black;
    background-color: #70AD47;
}
.frmod-cl-dd-warning {
    color: black;
    background-color: #FFCC99;
}
.frmod-cl-dd-error {
    color: white;
    background-color: #C00000;
}
.frmod-cl-dd-gray {
    color: black;
    background-color: #BFBFBF;
}


</style>
