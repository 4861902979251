<template>
<div>
    
    <PropertyHeader :header="'Help'">
        <template v-slot:actions>
            
        </template>
    </PropertyHeader>

    <PropertyItem padded :header="page.header" :icon="page.icon" v-for="(page, i) in pages" :key="'hp' + i" :initialCollapse="!page.open">
        <div class="frv2-help-content" v-html="page.html" />
    </PropertyItem>

</div>
</template>
<script>
import axios from 'axios';

import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';

export default {
    name: 'HelpPropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() { 
        return {
            pages: [
                { icon: 'help-o', header: 'Getting started', file: 'view_toggle', html: '', open: true }
            ],
        }
    },
    methods: {

        async readFile(page) {
            const response = await axios.get('guide/pages/' + page.file + '.html');
            page.html = response.data;
        },

    },
    created() {
        this.readFile(this.pages[0]);
    }
}
</script>
<style>

.frv2-help-content {
    padding-bottom: 200px;
    color: white;
}

.frv2-help-content img {
    width: 100%;
}

.frv2-help-content h1 {
    font-size: 20px;
}
.frv2-help-content h2 {
    font-size: 16px;
}

.frv2-help-content p {
    font-size: 15px;
}

.frv2-help-content hr {
    margin: 30px 0px;
    border: solid 1px var(--fb-menu-a);
}

</style>