<template>
<div>

    <PopupV1 :header="params.title[$root.locale.key]" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div>
                
                <VFRow>

                    <VFGroup c12 v-if="params.externalId">
                        <VFLabel desc :text="$root.config.externalId.title[$root.locale.key]" />
                        <VFInput v-model="externalId" :placeholder="$root.config.externalId.title[$root.locale.key]" />
                    </VFGroup>

                    <VFGroup c12 v-if="params.tag">
                        <VFLabel desc :text="'Tag'" />
                        <VFDropdown v-model="tagModel" :options="tagOptions" :displayKey="'display'" :listKey="'display'" :outputKey="'value'" />
                    </VFGroup>

                </VFRow>

            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton
                :text="params.submitText[$root.locale.key]"
                :icon="'check'"
                @clicked="onSave"
                :working="dialog.working"
                :disabled="!dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';

import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';

import VFInput from '../components/form/inputs/VFInput.vue';
import VFDropdown from '../components/form/inputs/VFDropdown.vue';

export default {
    name: 'ValuePopup',
    components: {
        PopupV1,
        VFButton,
        VFRow,
        VFGroup,
        VFLabel,
        VFInput,
        VFDropdown,
    },
    props: {
        params: { type: Object, default: null },
        client: { type: Object, default: null },
    },
    data() {
        return {
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            externalId: '',
            tagModel: '',
            tagOptions: [],
        }
    },
    methods: {

        async onSave() {
            this.dialog.working = true;
            this.dialog.status = this.params.progressText[this.$root.locale.key];

            if (this.params.externalId && this.externalId) {
                const clientData = {
                    externalId: this.externalId,
                };
                await v1api.updateClient(this.client._id, clientData);
            }

            if (this.params.tag && this.tagModel) {
                if (this.client.tags && this.client.tags.length > 0) {
                    await v1api.removeTag(this.client._id, this.client.tags[0]);
                }
                await v1api.addTags(this.client._id, [this.tagModel]);
            }

            const data = {
                isValues: true,
                values: {},
            };
            for (const i in this.params.values) {
                if (this.params.values[i].type == 'static') {
                    data.values[this.params.values[i].key] = this.params.values[i].staticValue;
                }
            }
            await v1api.addCustomData(this.client._id, 'custom', data);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        },

    },
    created() {
        
        if (this.params.tag) {
            for (const i in this.$root.config.tags.list) {
                this.tagOptions.push({
                    value: this.$root.config.tags.list[i].key,
                    display: this.$root.config.tags.list[i].text[this.$root.locale.key],
                });
            }
            this.tagModel = this.$root.config.tags.list[0].key;
            if (this.client.tags && this.client.tags.length > 0) this.tagModel = this.client.tags[0];
        }

        if (this.params.externalId) {
            if (this.client.externalId) this.externalId = this.client.externalId;
        }

    }
}
</script>
<style scoped>

.amlv1-tag-aitems {
    border-top: solid 1px var(--input-border);
}

.amlv1-tag-aitem {
    padding: 4px 10px;
    border-bottom: solid 1px var(--input-border);
    cursor: pointer;
}
.amlv1-tag-aitem:hover {
    background-color: var(--item-hover);
}

</style>