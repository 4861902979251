<template>
    <div class="slim-page">

        <vap-card v-if="person" :header="person.displayName" style="margin-top: 40px;">
            <vap-row>
                <vap-group c6>
                    <vap-label desc :text="__('common_first_name')" />
                    <vap-label :text="person.firstName" value />
                </vap-group>
                <vap-group c6>
                    <vap-label desc :text="__('common_last_name')" />
                    <vap-label :text="person.lastName" value />
                </vap-group>
                <vap-group c12>
                    <vap-label desc :text="__('common_dob')" />
                    <vap-label :text="person.dateOfBirth" value />
                </vap-group>
            </vap-row>
        </vap-card>

        <vap-label :text="'Roller'" header style="margin-top: 30px; margin-bottom: 6px;" />

        <div v-if="roles.length > 0">
            <vap-card noPadding v-for="(role, i) in roles" :key="i" style="margin-bottom: 10px;">
                <vap-item @clicked="showCustomer(role)">
                    <div class="comitm-base">
                        <div class="comitm-icon">
                            <vap-icon :icon="role.customerType == 'person' ? 'person' : 'company'" />
                        </div>
                        <div class="comitm-content">
                            <div class="comitm-header">
                                <label>{{ role.customerName }}</label>
                            </div>
                            <div class="comitm-info">
                                <label>{{ getRoleString(role) }}</label>
                            </div>
                        </div>
                    </div>
                </vap-item>
            </vap-card>
        </div>

    </div>
</template>

<script>
import api from '../portalapi';
import tools from '../tools';
import personhelper from '../helpers/personhelper';

export default {
    name: 'Individual',
    components: {
        
    },
    data() {
        return {
            identity: 'id',
            person: undefined,
            roles: []
        }
    },
    methods: {

        showCustomer(role) {
            this.$parent.navigate({ path: '/client', query: { id: role.customerId } });
        },

        getRoleString(role) {
            if (role.customerType == 'person') return 'Privat';
            let str = '';
            for (const i in role.roles) {
                if (i > 0) str += ', ';
                str += this.__(role.roles[i]);
            }
            return str;
        },

        async getIndividual() {

            const response = await api.getIndividual(this.identity);

            this.roles = response.roles;

            const person = tools.decodePerson(response.roles[0].identity);
            this.person = {
                identity: response.roles[0].identity,
                firstName: personhelper.titleCase(person.firstName),
                lastName: personhelper.titleCase(person.lastName),
                dateOfBirth: person.dateOfBirth,
                displayName: personhelper.titleCase(person.firstName) + ' ' + personhelper.titleCase(person.lastName),
            };

            console.log('person', this.person);
            console.log('roles', this.roles);

        }

    },
    async created() {

        this.identity = decodeURIComponent(this.$route.query.id);

        console.log('identity', this.identity);

        await this.getIndividual();
    }
}
</script>

<style scoped>

.comitm-base {
    display: flex;
    padding: 10px 10px;
}

.comitm-icon {
    flex-shrink: 0;
}
.comitm-content {
    flex-grow: 100;
    margin-left: 14px;
}

.comitm-header {
    font-size: 18px;
    color: var(--text-accent);
}
.comitm-info {
    font-size: 16px;
    color: var(--text-weak);
}

</style>