import pdfHelper from './pdfHelper';
import formHelper from './formHelper';
import log from '@/log';
import moduleHelper from './modules/moduleHelper'
import pdfTools from '@/pdfTools';

const pdfGenerator = {

    async getBase64(root, formData) {

        const result = this.processNewData(root, formData, false);

        const userdata = {
            formConfig: formData,
            pdfLanguage: root.locale.key
        };

        // Modules onFormCreated
        for (const i in result.modules) {
            await moduleHelper.onFormCreated(root, result, false, result.modules[i], result.pages, result.isOwner);
        }
        moduleHelper.onFormCreatedSystem(root, result, false, result.pages, result.isOwner);

        
        this.preparePdfData(root, userdata, result.pages, result, formData, false);

        
        const response = await pdfTools.getPDF(root, root.config, 'form', userdata, {}, true, []);

        return response.data;


    },

    async getPreviewData(root, formData, empty) {

        const result = this.processNewData(root, formData);

        const userdata = {
            formConfig: formData
        };

        // Modules onFormCreated
        for (const i in result.modules) {
            await moduleHelper.onFormCreated(root, result, false, result.modules[i], result.pages, result.isOwner);
        }
        moduleHelper.onFormCreatedSystem(root, result, false, result.pages, result.isOwner);

        if (empty) {
            for (const i in result.pages) {
                const page = result.pages[i];
                for (const j in page.cards) {
                    const card = page.cards[j];
                    if (card.type == 'list') {
                        formHelper.addListModel(root, card, {});
                    }
                }
            }
        }

        this.preparePdfData(root, userdata, result.pages, result, formData, empty);

        return userdata;
    },

    preparePdfData(root, userdata, pages, result, formData, empty) {
        userdata.pdfConfig = pdfHelper.getConfig(root, formData);
        userdata.pdfMeta = pdfHelper.getMetadata(userdata, root.locale.key);
        userdata.pdfPages = pdfHelper.processPages(pages, root.locale.key, false, userdata, result, empty);
        console.log('pdfConfig', userdata.pdfConfig);
        console.log('pdfMeta', userdata.pdfMeta);
        console.log('pdfPages', userdata.pdfPages);
    },

    processNewData(root, formData, empty) {
        const result = {};
        const processedData = formHelper.processData(formData);
        log.x('FRv2: Form data processed', processedData);
        result.title = formData.title;
        result.pages = processedData.pages;
        result.modules = processedData.modules;
        result.roles = processedData.roles;
        result.roleKey = 'owner';
        result.isOwner = true;
        result.resources = {};
        result.logicResults = {};

        formHelper.createModel(root, root, result.pages, empty);

        return result;
    },
};
export default pdfGenerator;