<template>
<div>

    <PopupV1 :header="__t('monitor-cs-title')" @close="onClose" :dialog="dialog">
        <template v-slot:content>

            <div class="amlv1-cs-infobox">
                <div class="amlv1-cs-infoitem">
                    <div class="amlv1-cs-infoitem-d">
                        {{ __t('person-first-name') }}
                    </div>
                    <div class="amlv1-cs-infoitem-v">
                        {{ companyTitleCase(params.person.info.firstName) }}
                    </div>
                </div>
                <div class="amlv1-cs-infoitem">
                    <div class="amlv1-cs-infoitem-d">
                        {{ __t('person-last-name') }}
                    </div>
                    <div class="amlv1-cs-infoitem-v">
                        {{ companyTitleCase(params.person.info.lastName) }}
                    </div>
                </div>
                <div class="amlv1-cs-infoitem">
                    <div class="amlv1-cs-infoitem-d">
                        {{ __t('person-dob') }}
                    </div>
                    <div class="amlv1-cs-infoitem-v">
                        {{ params.person.info.dateOfBirth }}
                    </div>
                </div>
                <div class="amlv1-cs-infoitem">
                    <div class="amlv1-cs-infoitem-d">
                        {{ __t('screening-param-ssn') }}
                    </div>
                    <div class="amlv1-cs-infoitem-v" :class="[
                        {'amlv1-cs-infoitem-v-disabled': !params.person.info.ssn },
                    ]">
                        {{ params.person.info.ssn || __t('common-not-available') }}
                    </div>
                </div>
            </div>

            <MonitorSelector
                :params="params"
                @changed="onMonitorValidation"
            />

        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('monitor-update')"
                :icon="'check'"
                @clicked="onClick"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import monitorHelper from '../v1monitor/monitorHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

import MonitorSelector from './MonitorSelector.vue';

export default {
    name: 'MonitorDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox,
        MonitorSelector,
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            shouldScreen: false,
            monitorIds: [],
        }
    },
    methods: {

        onMonitorValidation(valid, addCount, monitorIds) {
            console.log('valid', valid);
            this.shouldScreen = false;
            if (addCount > 0 && !this.params.person.screening.done) {
                this.shouldScreen = true;
            }
            this.monitorIds = monitorIds;
            this.dialog.valid = valid;
            console.log('this.dialog.valid', this.dialog.valid);
        },

        async onClick() {
            this.dialog.working = true;

            await v1api.enableContinuousScreeningPerson(this.params.person.personId, this.monitorIds);

            const response = await v1api.getClient(this.params.clientId);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        }

    },
    created() {
        this.loading = false;
    }
}
</script>
<style scoped>

.amlv1-cs-infobox {
    float: left;
    width: 100%;
    padding: 5px 20px;
    padding-top: 15px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    box-sizing: border-box;
}

.amlv1-cs-infoitem {
    float: left;
    width: 50%;
    margin-bottom: 8px;
    box-sizing: border-box;
}

.amlv1-cs-infoitem-d {
    font-size: 14px;
    color: var(--text-weak);
}

.amlv1-cs-infoitem-v {
    font-size: 17px;
    font-weight: 500;
    color: var(--text-title);
}

.amlv1-cs-infoitem-v-disabled {
    color: var(--text-disabled);
}

</style>