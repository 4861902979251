<template>
    <div>
        <BatchView :items="items" :steps="steps" :skip="skip" :title="'Clients (1.0)'" />
    </div>
</template>
<script>
import api from '../portalapi';
import flows from '../flows';

import v1api from '../v1helpers/v1api';
import v1migration from '../v1helpers/v1migration';

import BatchView from '../components/special/BatchView';

export default {
    components: {
        BatchView
    },
    name: 'ScriptMigration',
    data() {
        return {
            items: [],
            steps: 2,
            STEP_LOAD_CLIENT: 0,
            STEP_UPDATE_STATUS: 1,
            skip: 0
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            return item.name;
        },

        onGetInfo1(item) {
            return '';
        },

        onGetInfo2(item) {
            return '';
        },

        async onRemoveItem(item) {},

        async onStart() {
            return true;
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return 'Laster kundedata';
                case this.STEP_UPDATE_STATUS: return 'Oppdaterer status';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return await this.loadClient(item, data);
                case this.STEP_UPDATE_STATUS: return await this.updateStatus(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async loadClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            const customer = item;
            let flow = null;

            // Load customer specific flow
            if (customer.type == 'person') {
                if (customer.flow && customer.flow == 'personLookup') {
                    this.isLookup = true;
                    flow = flows.getFlow('personLookup', this.$root.config.flows.personLookup);
                }else {
                    flow = flows.getFlow('personNO', this.$root.config.flows.personNorway);
                }
            }else {
                if (customer.source == 'brreg') {
                    if (customer.flow && customer.flow == 'companyBrregLookup') {
                        flow = flows.getFlow('brregLookup', this.$root.config.flows.companyBrregLookup);
                    }else if (customer.flow && customer.flow == 'snn-bank') {
                        flow = flows.getFlow('snn-bank', this.$root.config.flows.snnBank);
                    }else if (customer.flow && customer.flow == 'snn-bank2') {
                        flow = flows.getFlow('snn-bank2', this.$root.config.flows.snnBank2);
                    }else {
                        flow = flows.getFlow('brreg', this.$root.config.flows.companyBrreg);
                    }
                }else if (customer.flow == 'org-se-trap') {
                    flow = flows.getFlow('org-se-trap', this.$root.config.flows.companySweden);
                }else if (customer.flow == 'org-uk-chouse') {
                    flow = flows.getFlow('org-uk-chouse', this.$root.config.flows.companyUK);
                }else {
                    flow = flows.getFlow('manual', this.$root.config.flows.companyManual);
                }
            }

            const clientData = {
                customer: customer,
                flow: flow
            };

            const loadResult = await v1migration.loadV0Client(this.$root, clientData);
            const migrateResult = await v1migration.migrateClient(this.$root, loadResult);

            console.log('migrateResult', migrateResult);

            if (!migrateResult.hasActiveControls) {
                const newId = await v1migration.addToDatabase(loadResult, migrateResult);
            }else {
                console.error('SKIPPED');
            }

            result.ok = true;
            return result;
        },

        async updateStatus(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            await new Promise(r => setTimeout(r, 500));

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        async loadCustomers() {
            const filters = [
                { field: 'status', op: 'is', value: 'r-rep-a' },
                { field: 'migrated', op: 'exists', values: false },
                /* { field: 'flow', op: 'is', value: 'org-no-baml' }, */
            ];
            const from = 50;
            const limit = 500;
            const sort = { dateUpdated: 1 };
            const include = [];
            const counts = [];

            const response = await api.queryCustomers(filters, from, limit, sort, include, counts);

            this.items = [];
            for (const i in response.customers) {
                this.items.push(response.customers[i]);
            }
        },

    },
    async created() {
        await this.loadCustomers();
    }
}
</script>