<template>
<div>
    
    <PropertyHeader :header="'Data Model'">
        <template v-slot:actions>
            <!-- <DesignerButton small
                :icon="'add'"
                :tooltip="'Add module'"
                @clicked="showAddDialog"
            /> -->
        </template>
    </PropertyHeader>

    <PropertyItem padded :header="'Settings'" :icon="'settings'">

        <DesignerGroup c12>
            <DesignerCheckbox v-model="reference.flags.addModelToMetadata"
                :text="'Add data model to envelope metadata'"
            />
        </DesignerGroup>
        
    </PropertyItem>

    <PropertyItem :header="'Data Model'" :icon="'orgchart'">
        <pre class="frv2-dm-output" style="margin-top: -15px;">
{{ dataModel }}
        </pre>
    </PropertyItem>

</div>
</template>
<script>
import formHelper from '../../formHelper';

import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';

import DesignerGroup from '../../designer/ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../../designer/ui/inputs/DesignerLabel.vue';
import DesignerCheckbox from '../../designer/ui/inputs/DesignerCheckbox.vue';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';

export default {
    name: 'DataModelPropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
        DesignerGroup,
        DesignerLabel,
        DesignerCheckbox,
        PopupV1,
        VFButton,
    },
    props: {
        reference: { type: Object, default: null },
        logicActive: { type: Boolean, default: false },
    },
    data() { 
        return {
            dataModel: null,
        }
    },
    methods: {

    },
    created() {
        this.dataModel = formHelper.getDataModel(this.reference, true);
    }
}
</script>
<style scoped>

.frv2-dm-output {
    padding: 0px 20px;
    font-size: 18px;
    color: var(--fb-text-2);
}

</style>