<template>
<div>

    <ve-popup :header="'Flow designer'" :width="804" @close="closeDesigner" hideCloseButton>

        <div class="frv2-fd" v-if="loaded" @click="hideDropdowns">

            <div class="frv2-fd-itm nohl" v-for="(item, i) in items" :key="'fdi' + i"
                :style="'top: ' + item.bounds.y + 'px; left: ' + item.bounds.x + 'px; width: ' + item.bounds.w + 'px;'">
                <div class="frv2-fd-name" v-if="item.key != 'done'">
                    {{ item.name }}
                </div>
                <div class="frv2-fd-act" v-if="item.key != 'done'">
                    <div class="frv2-fd-info">
                        
                    </div>
                    <div class="frv2-fd-btn" :class="{'frv2-fd-btn-s' : item.hasEmail }">
                        <vap-icon :icon="'alpha'" small absolute :top="3" :right="5" />
                    </div>
                    <div class="frv2-fd-btn" :class="{'frv2-fd-btn-s' : item.hasLanding }">
                        <vap-icon :icon="'check-c'" small absolute :top="3" :right="5" />
                    </div>
                    <div class="frv2-fd-btn" @click.stop="showNextList(item)">
                        <vap-icon :icon="'chev-r'" small absolute :top="3" :right="5" />
                    </div>
                </div>
                <div class="frv2-fd-icon" v-if="item.key == 'done'">
                    <vap-icon :icon="'check'" absolute :top="18" :right="30" />
                </div>
            </div>

            <canvas id="frv2-fd-canvas" />

        </div>

        <div class="frv2-fd-guides">
            <div class="frv2-fd-guides-l">
                <div class="frv2-fd-guides-h">{{ 'Main Flow' }}</div>
            </div>
            <div class="frv2-fd-guides-r">
                <div class="frv2-fd-guides-h">{{ 'Optional Roles' }}</div>
            </div>
        </div>

        <div class="frv2-fd-nextlist" v-if="nextListVisible" :style="'top: ' + nextListPosition.y + 'px; left: ' + nextListPosition.x + 'px;'">
            <div class="frv2-fd-nextlist-i" v-for="(item, i) in items" :key="'ne' + i" @click.stop="onChangeNextRole(item.key)">
                {{ item.name }}
            </div>
        </div>

        <div>
            <vap-button :text="'Add'" @clicked="addRoleShow = true" />
        </div>

    </ve-popup>

    <!-- Select role -->
    <ve-popup v-if="addRoleShow" :header="'Add role'" :width="400" @close="addRoleShow = false">
        <div style="padding: 8px 0px;">
            <vap-item v-for="(option, i) in addRoleOptions" :key="'foo' + i" fancy padding20
                :fancyIcon="option.icon"
                :fancyText="option.display"
                :fancyHint="option.hint"
                @clicked="addRole(option)"
            />
        </div>
    </ve-popup>

</div>
</template>
<script>
import flowHelper from '../flowHelper';

export default {
    name: 'FlowDesigner',
    components: {
        
    },
    props: {
        roles: { type: Array, default: null },
    },
    data() {
        return {
            loaded: false,
            items: [
                { key: 'x', col: 1, row: 0, name: 'Owner' },
                { key: 'x', col: 1, row: 2, name: 'Editor (Perform checks)' },
                { key: 'x', col: 1, row: 4, name: 'Editor (Approve)' },
                { key: 'x', col: 2, row: 4, name: 'Editor (Provide info)' },
            ],
            arrows: [],
            nextListKey: '',
            nextListVisible: false,
            nextListPosition: { x: 0, y: 0 },
            addRoleOptions: [],
            addRoleShow: false
        }
    },
    methods: {
        
        closeDesigner() {
            this.$root.enableScrolling(true);
            this.$emit('close');
        },

        addRole(option) {
            this.$parent.onAddRole(option);
            this.addRoleShow = false;
            this.loadDesigner();
        },

        calculatePositions() {
            const width = 300;
            const height = 70;

            const cols = [50, 450, 850];
            const rows = [100, 0, 240, 0, 380, 0, 520];

            for (const i in this.items) {
                let x = cols[this.items[i].col];
                const y = rows[this.items[i].row];
                let adjustedWidth = width;
                if (this.items[i].key == 'done') {
                    x = x + 100;
                    adjustedWidth = 100;
                }
                const bounds = {
                    x: x,
                    y: y,
                    w: adjustedWidth,
                    aT: { x: x + (adjustedWidth / 2), y: y - 12 },
                    aB: { x: x + (adjustedWidth / 2), y: y + height + 16 },
                };
                //console.log('x = ' + x + ', y = ' + y + ', aB =', { x: x + (adjustedWidth / 2), y: y + height + 10 });
                this.$set(this.items[i], 'bounds', bounds);
            }

        },

        addArrow(point1, point2) {
            const aPointL1 = { x: 0, y: 0 };
            const aPointR1 = { x: 0, y: 0 };
            const aPointL2 = { x: 0, y: 0 };
            const aPointR2 = { x: 0, y: 0 };
            let direction = 'left';
            if (point1.x < point2.x) direction = 'right';
            if (point1.y < point2.y) direction = 'down';
            if (point1.y > point2.y) direction = 'up';

            if (direction == 'down') {
                aPointL1.x = point2.x + 1;
                aPointL1.y = point2.y;
                aPointL2.x = point2.x - 14;
                aPointL2.y = point2.y - 14;
                aPointR1.x = point2.x - 1;
                aPointR1.y = point2.y;
                aPointR2.x = point2.x + 14;
                aPointR2.y = point2.y - 14;
            }else if (direction == 'up') {
                aPointL1.x = point2.x + 1;
                aPointL1.y = point2.y;
                aPointL2.x = point2.x - 14;
                aPointL2.y = point2.y + 14;
                aPointR1.x = point2.x - 1;
                aPointR1.y = point2.y;
                aPointR2.x = point2.x + 14;
                aPointR2.y = point2.y + 14;
            }else if (direction == 'left') {
                aPointL1.x = point2.x;
                aPointL1.y = point2.y + 1;
                aPointL2.x = point2.x + 14;
                aPointL2.y = point2.y - 14;
                aPointR1.x = point2.x;
                aPointR1.y = point2.y - 1;
                aPointR2.x = point2.x + 14;
                aPointR2.y = point2.y + 14;
            }else if (direction == 'right') {
                aPointL1.x = point2.x;
                aPointL1.y = point2.y + 1;
                aPointL2.x = point2.x - 14;
                aPointL2.y = point2.y - 14;
                aPointR1.x = point2.x;
                aPointR1.y = point2.y - 1;
                aPointR2.x = point2.x - 14;
                aPointR2.y = point2.y + 14;
            }

            this.arrows.push({ point1, point2, aPointL1, aPointR1, aPointL2, aPointR2 });
        },

        drawArrows() {
            const canvas = document.getElementById('frv2-fd-canvas');
            const body = document.body, html = document.documentElement;
            const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            canvas.height = height;
            canvas.width = window.innerWidth;
            const context = canvas.getContext('2d');
            context.strokeStyle = "#000000";
            context.lineWidth = 3;

            for (const i in this.arrows) {
                context.beginPath();
                context.moveTo(this.arrows[i].point1.x, this.arrows[i].point1.y);
                context.lineTo(this.arrows[i].point2.x, this.arrows[i].point2.y);
                context.stroke();

                context.beginPath();
                context.moveTo(this.arrows[i].aPointL1.x, this.arrows[i].aPointL1.y);
                context.lineTo(this.arrows[i].aPointL2.x, this.arrows[i].aPointL2.y);
                context.stroke();

                context.beginPath();
                context.moveTo(this.arrows[i].aPointR1.x, this.arrows[i].aPointR1.y);
                context.lineTo(this.arrows[i].aPointR2.x, this.arrows[i].aPointR2.y);
                context.stroke();
            }
            
        },

        showNextList(item) {
            this.nextListKey = item.key;
            this.nextListPosition.x = item.bounds.x + 256;
            this.nextListPosition.y = item.bounds.y + 121;
            this.nextListVisible = true;
        },

        hideDropdowns() {
            this.nextListVisible = false;
        },

        onChangeNextRole(nextKey) {
            this.hideDropdowns();
            
            const parentRole = this.getParentRole(this.nextListKey);

            parentRole.next = nextKey;

            this.loadDesigner();
        },

        getParentRole(key) {
            console.log('roles', this.$parent.roleOptions);
            for (const i in this.$parent.roleOptions) {
                if (this.$parent.roleOptions[i].key == key) {
                    return this.$parent.roleOptions[i];
                }
            }
            return null;
        },

        loadDesigner() {
            this.loaded = false;

            let rowCount = 0;
            this.items = [];
            const nextList = [];

            for (const i in this.roles) {
                const role = this.roles[i];
                if (role.key == 'done') continue;
                const item = { key: role.key, next: role.next, name: role.display, col: 0, row: 0, hasEmail: role.hasEmail, hasLanding: role.hasLanding };
                if (role.key == 'owner') {
                    item.col = 0;
                    item.row = 0;
                }else {
                    let next = null;
                    for (const j in nextList) {
                        if (nextList[j].key == item.next) next = nextList[j];
                    }
                    if (next) {

                        item.col = 1;
                        item.row = next.row;

                    }else {
                        rowCount += 2;
                        item.col = 0;
                        item.row = rowCount;
                        nextList.push({ key: item.key, row: item.row });
                    }
                }

                this.items.push(item);
            }

            rowCount += 2;
            this.items.push({
                key: 'done', next: 'ndone', name: 'done', col: 0, row: rowCount
            });

            this.calculatePositions();

    /*         this.addArrow({ x: 450, y: 130}, { x: 450, y: 330});
            this.addArrow({ x: 150, y: 200}, { x: 150, y: 50});
            this.addArrow({ x: 100, y: 250}, { x: 250, y: 250});
            this.addArrow({ x: 250, y: 300}, { x: 100, y: 300}); */

            this.arrows = [];
            for (const i in this.items) {
                const point1 = this.items[i].bounds.aB;
                let point2 = null;
                if (this.items[i].next != 'ndone') {
                    for (const j in this.items) {
                        if (this.items[i].next == this.items[j].key) {
                            point2 = this.items[j].bounds.aT;
                        }
                    }
                }
                if (point2) {
                    this.addArrow(point1, point2);
                }
            }

            const self = this;
            setTimeout(function() {
                self.drawArrows();
            }, 200);

            this.loaded = true;
        },

    },
    created() {
        this.$root.enableScrolling(false);
        console.log('roles', this.roles);
        this.addRoleOptions = flowHelper.getRoleOptions();
        this.loadDesigner();
    }
}
</script>
<style scoped>

.frv2-fd {
    position: absolute;
    width: 800px;
    height: 800px;
    background-color: lightgray;
}

#frv2-fd-canvas {
    position: absolute;
    top: 0px; left: 0px;
    pointer-events: none;
    z-index: 22;
}



.frv2-fd-nextlist {
    position: absolute;
    width: 200px;
    border: solid 2px black;
    background-color: var(--card-background);
}
.frv2-fd-nextlist-i {
    position: relative;
    padding: 4px 10px;
    cursor: pointer;
}
.frv2-fd-nextlist-i:hover {
    background-color: lightskyblue;
}


.frv2-fd-guides {
    position: absolute;
    display: flex;
    width: 800px;
    height: 800px;
    color: black;
    pointer-events: none;
}

.frv2-fd-guides-l {
    width: 50%;
    height: 100%;
    padding: 0px 20px;
    border-right: solid 1px black;
    box-sizing: border-box;
}
.frv2-fd-guides-r {
    width: 50%;
    height: 100%;
    padding: 0px 20px;
    border-left: solid 1px black;
    box-sizing: border-box;
}

.frv2-fd-guides-h {
    padding-top: 20px;
    padding-bottom: 10px;
    text-align: center;
    border-bottom: solid 2px black;
}


.frv2-fd-itm {
    position: absolute;
    width: 300px;
    height: 70px;
    border: solid 2px black;
    border-radius: 8px;
    background-color: var(--card-background);
}

.frv2-fd-name {
    position: absolute;
    width: 100%;
    height: 36px;
    padding-top: 8px;
    padding-left: 12px;
    border-bottom: solid 2px black;
    box-sizing: border-box;
}

.frv2-fd-act {
    position: absolute;
    top: 36px;
    width: 100%;
    display: flex;
    height: 36px;
    cursor: pointer;
}

.frv2-fd-info {
    flex-grow: 100;
    height: 100%;
}

.frv2-fd-btn {
    position: relative;
    flex-shrink: 0;
    width: 38px;
    height: 34px;
    border-left: solid 2px black;
}
.frv2-fd-btn-s {
    background-color: lightgreen;
}
.frv2-fd-btn:hover {
    background-color: lightskyblue;
}

</style>
