<template>
<div v-if="!loading">

    <ReportSideBar
        :items="reportParams.items"
        @save="onSave"
        @create="createDialog.show = true"
        @preview="onPreviewClicked"
        :statusText="statusText"
        :allCompleted="allCompleted"
        :reportCreated="client.report.created"
        :isSaving="isSaving"
        @goto="gotoItem"
    />

    <div class="report-page">
        <ReportItem list v-for="(item, i) in reportParams.items" :key="'c' + i" :item="item"
            :rdata="reportParams.data[item.dataKey]"
            @change="validateTriggers"
            :disabled="client.report.created"
            :isPartner="isPartner"
            :riskModel="riskModel"
        >

            <ReportCompany v-if="item.key == 'org'"
                :item="item"
                :client="client"
                :disabled="client.report.created"
                @preview="previewFile"
            />
            <ReportRole v-if="item.key == 'roles'"
                :item="item"
                :disabled="client.report.created"
                @preview="previewPersonFile"
            />
            <ReportRisk v-if="item.key == 'assessment'"
                :item="item"
                :client="client"
                :riskOptions="reportParams.riskOptions"
                :reportCreated="client.report.created"
                :disabled="client.report.created"
                :lockHighRisk="lockHighRisk"
                :isPartner="isPartner"
                @changed="validateTriggers"
                @risk-changed="onRiskChanged"
            />
            <ReportCustom v-if="item.key != 'org' && item.key != 'roles' && item.key != 'assessment'"
                @preview="previewFile"
                :item="item"
                :client="client"
                :disabled="client.report.created"
                @changed="validateTriggers"
            />

            <FormReportView ref="reportForm" v-if="item.key == 'form'"
                :clientId="client._id"
                :initialFormKey="item.formKey"
                @validated="onFormValidated"
            />

        </ReportItem>
        <div class="flw100" style="margin-top: 120px;" />
    </div>

    <CreateReportDialog v-if="createDialog.show"
        :client="client"
        :flow="flow"
        :persons="persons"
        :triggers="triggers"
        :texts="texts"
        :params="reportParams"
        @created="onReportCreated"
        @close="createDialog.show = false"
    />
    
</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1reportHelper from '../v1helpers/v1reportHelper';
import triggerHelper from '../v1flows/triggerHelper';
import previewHelper from '../v1files/previewHelper';

import ReportSideBar from '../v1report/ReportSideBar.vue';
import ReportItem from '../v1report/ReportItem.vue';

import ReportCompany from '../v1report/ReportCompany.vue';
import ReportRole from '../v1report/ReportRole.vue';
import ReportRisk from '../v1report/ReportRisk.vue';
import ReportCustom from '../v1report/ReportCustom.vue';

import FormReportView from '../components/form/FormReportView.vue';

import CreateReportDialog from '../v1report/CreateReportDialog.vue';

export default {
    name: 'ReportV1',
    components: {
        ReportSideBar,
        ReportItem,
        ReportCompany,
        ReportRole,
        ReportRisk,
        ReportCustom,
        FormReportView,
        CreateReportDialog,
    },
    props: {
        client: { type: Object, default: null },
        flow: { type: Object, default: null },
        triggers: { type: Object, default: null },
        texts: { type: Object, default: null },
        persons: { type: Array, default: null },
        isPartner: { type: Boolean, default: false },
    },
    data() {
        return {
            loading: true,
            reportParams: {
                items: [],
                data: {},
                riskOptions: {}
            },
            statusText: null,
            createDialog: { show: false },
            allCompleted: false,
            isSaving: false,
            lockHighRisk: false,
            riskModel: 0,
        }
    },
    methods: {

        onRiskChanged(risk) {
            this.riskModel = this.client.report.risk || this.client.report.riskUser || 0;
            console.log('changed', this.client);
            console.log('changed', this.riskModel);
        },

        onFormValidated() {
            this.validateTriggers();
        },

        gotoItem(item) {
            const element = document.getElementById(item.dataKey);
            if (element) {
                element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
                item.highlight = true;
                setTimeout(function() {
                    item.highlight = false;
                }, 1000);
            }
        },
        
        //#region Actions -----------------------------------------------------------------------------------------------------

        previewFile(key) {
            this.$emit('pdf', key);
        },

        previewPersonFile(previewParams) {
            this.$emit('preview', previewParams);
        },

        async onSave() {
            if (this.client.report.created) return;
            this.isSaving = true;

            const items = [];
            for (const i in this.reportParams.data) {
                const item = this.reportParams.data[i];
                if (item.key == 'form') {
                    if ((this.$refs.reportForm && this.$refs.reportForm.length > 0)) {
                        const formData = this.$refs.reportForm[0].getFormData();
                        item.formData = JSON.stringify(formData);
                    }
                }
                items.push(item);
            }
            await v1api.saveReportData(this.client._id, items, this.client.report.risk || 0);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.isSaving = false;
        },

        onReportCreated(response) {
            this.$emit('tab', 'overview');
            this.$emit('reload', response);
        },

        onPreviewClicked() {

            const extraData = {};

            if (this.$refs.reportForm && this.$refs.reportForm.length > 0) {
                const formData = this.$refs.reportForm[0].getHtml();
                console.log('formData', formData);
                extraData.pdfConfig = formData.pdfConfig;
                extraData.pdfMeta = formData.pdfMeta;
                extraData.pdfPages = formData.pdfPages;
            }

            const userdata = v1reportHelper.getReportUserData(this.$root, this.reportParams, this.client, this.flow, this.persons, this.triggers, this.texts, false, extraData);
            console.log('USERDATA', userdata);
            const previewParams = previewHelper.getReportPreviewParams(this.$root, userdata);
            this.$emit('preview', previewParams);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Trigger validation ------------------------------------------------------------------------------------------

        validateTriggers() {
            let allValid = true;
            this.statusText = null;
            for (const i in this.reportParams.items) {
                let itemValid = true;
                let commentValid = true;
                for (const j in this.reportParams.items[i].requirements) {
                    let valid = true;
                    let validComment = true;
                    let checkRequirements = true;
                    if (this.reportParams.items[i].requirements[j].conditions) {
                        for (const k in this.reportParams.items[i].requirements[j].conditions) {
                            const result = triggerHelper.validateTrigger(this.reportParams.items[i].requirements[j].conditions[k], this.triggers);
                            if (!result) checkRequirements = false;
                        }
                    }
                    if (checkRequirements) {
                        for (const k in this.reportParams.items[i].requirements[j].triggers) {
                            if (this.reportParams.items[i].requirements[j].triggers[k].type == 'rep-itm') {
                                const result = this.validateReportDataTrigger(this.reportParams.items[i].requirements[j].triggers[k], this.reportParams.items[i]);
                                if (!result) {
                                    if (this.reportParams.items[i].requirements[j].triggers[k].trg == 'has-comment') {
                                        commentValid = false;
                                        validComment = false;
                                    }else {
                                        valid = false;
                                    }
                                }
                            }else {
                                const result = triggerHelper.validateTrigger(this.reportParams.items[i].requirements[j].triggers[k], this.triggers);
                                if (!result) valid = false;
                            }
                        }
                    }
                    if (!valid) {
                        itemValid = false;
                        if (!this.statusText) this.statusText = triggerHelper.replaceTexts(this.reportParams.items[i].requirements[j].message, this.texts);
                    }else if (!validComment) {
                        if (!this.statusText) this.statusText = triggerHelper.replaceTexts(this.reportParams.items[i].requirements[j].message, this.texts);
                    }
                }
                this.reportParams.items[i].valid = itemValid;
                this.reportParams.items[i].commentValid = commentValid;
                if (!itemValid || !commentValid) allValid = false;
            }
            if (allValid) this.statusText = this.$root.v1translations['report-create-ready'];
            if (this.client.report.created) this.statusText = this.$root.v1translations['report-already-created'];
            this.allCompleted = allValid;
        },

        validateReportDataTrigger(trigger, item) {
            let valid = false;
            if (trigger.trg == 'has-comment') {
                const rdata = this.reportParams.data[item.dataKey];
                if (this.isPartner) {
                    valid = (rdata.check2 || this.isValidComment(rdata.comment2));
                }else {
                    valid = (rdata.check || this.isValidComment(rdata.comment));
                }
            }else if (trigger.trg == 'has-file') {
                console.log('VAL FILE', item)
                if (item.includeFile && item.includeFile.reportKey) {
                    for (const i in this.$root.loadedFiles) {
                        if (this.$root.loadedFiles[i].reportKey == item.includeFile.reportKey) return true;
                    }
                }
                valid = false;
            }else if (trigger.trg == 'risk-set') {
                valid = (this.client.report.risk && this.client.report.risk != 0);
            }else if (trigger.trg == 'form-valid') {
                valid = (this.$refs.reportForm && this.$refs.reportForm.length > 0) ? this.$refs.reportForm[0].validation.formValid : false;
            }
            return valid;
        },

        isValidComment(input) {
            if (!input) return false;
            if (input == '<br>') return false;
            if (input.length > 0) return true;
            return false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    async created() {

        // Set risk to high if enabled
        if (this.$root.config.risk.lockHighIfPep) {
            for (const i in this.persons) {
                if (this.persons[i].screening.done && this.persons[i].screening.isPep) {
                    this.client.risk = 7;
                    this.client.report.risk = 7;
                    this.riskModel = 7;
                    this.lockHighRisk = true;
                }
            }
        }

        // Load report data
        v1reportHelper.loadReportData(this.$root, this.reportParams, this.client, this.flow, this.persons, false);

        // Check if report contains a form
        for (const i in this.flow.report) {
            if (this.flow.report[i].key == 'form') {

                // Give report form client context
                let existingData = {
                    companyData: this.client.company,
                    customer: this.client,
                    persons: this.persons,
                    client: this.client
                };
                if (this.flow.isPerson) existingData.person = this.persons[0];

                // Load saved form data
                if (this.client.report.items) {
                    for (const j in this.client.report.items) {
                        if (this.client.report.items[j].key == 'form' && this.client.report.items[j].formData) {
                            existingData.savedPages = JSON.parse(this.client.report.items[j].formData);
                            existingData.loadSavedData = true;
                        }
                    }
                }

                this.$root.formData = JSON.stringify(existingData);
            }
        }

        this.riskModel = this.client.report.risk || this.client.report.riskUser || 0;

        this.validateTriggers();
        this.loading = false;
    }
}
</script>
<style scoped>

.report-page {
    width: 100%;
    max-width: 1500px;
    margin: 0 auto;
    padding: 20px;
    padding-right: 100px;
    box-sizing: border-box;
}

</style>