<template>

<div class="ac-row">
    <div class="ac-row-content">
        <slot></slot>
    </div>
    <div class="ac-row-actions" v-if="actionWidth">
        <div class="ac-row-actions-cc">
            <slot name="actions"></slot>
        </div>
    </div>
</div>

</template>
<script>

export default {
    name: "vap-row",
    props: {
        actionWidth: {
            type: Number,
            default: 0
        }
    },
}

</script>
<style>

.ac-row {
    float: left;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    box-sizing: border-box;
}

.ac-row-content {
    flex-grow: 100;
}

.ac-row-actions {
    position: relative;
    flex-shrink: 0;
    width: 34px;
}
.ac-row-actions-cc {
    position: absolute;
    bottom: 44px;
}

</style>
