<template>
<div>
<PropertyItem :header="'Visibility'" :icon="'logic'">
    <div style="padding: 20px 20px; padding-bottom: 10px;">
    
        <div class="frv2-opt-trg-base">
            <div class="frv2-opt-trg">
                <div class="frv2-opt-trg-check" v-if="!input.logic || !input.logic.visible" @click="onTriggerClicked('visible')">
                    <CheckboxUI :checked="input.visible" />
                </div>
                <div class="frv2-opt-trg-lock" v-if="input.logic && input.logic.visible">
                    <vap-icon small :icon="'locked'" />
                </div>
                <div class="frv2-opt-trg-title" @click="onTriggerClicked('visible')">
                    {{ 'Visible' }}
                </div>
                <div class="frv2-opt-trg-logic" @click="$emit('logic', {key: input.key, type: 'visible' })"
                    :class="{'frv2-opt-trg-activelogic': input.logic && input.logic.visible }"
                >
                    <vap-icon small :icon="'logic'" :color="'ext'" absolute :top="-2" :right="0" />
                </div>
                <div class="frv2-opt-trg-op" @click="onModeClicked('visible')" :class="{'frv2-opt-trg-op-click': input.logic && input.logic.visible }">
                    <span v-if="input.logic && input.logic.visible">{{ input.logic.visible.or ? 'OR' : 'AND' }}</span>
                </div>
            </div>
            <LogicLines :reference="reference" :input="input" :logicKey="'visible'" />
        </div>


<!--         <div class="frv2-opt-trg-base" v-if="!hideEnabled">
            <div class="frv2-opt-trg">
                <div class="frv2-opt-trg-check" v-if="!input.logic || !input.logic.enabled" @click="onTriggerClicked('enabled')">
                    <CheckboxUI :checked="input.enabled" />
                </div>
                <div class="frv2-opt-trg-lock" v-if="input.logic && input.logic.enabled">
                    <vap-icon small :icon="'locked'" />
                </div>
                <div class="frv2-opt-trg-title" @click="onTriggerClicked('enabled')">
                    {{ 'Enabled' }}
                </div>
                <div class="frv2-opt-trg-logic" :class="{'frv2-opt-trg-activelogic': input.logic && input.logic.enabled }">
                    <vap-icon small :icon="'logic'" :color="'ext'" absolute :top="-2" :right="0" />
                </div>
                <div class="frv2-opt-trg-op" @click="onModeClicked('enabled')" :class="{'frv2-opt-trg-op-click': input.logic && input.logic.enabled }">
                    <span v-if="input.logic && input.logic.enabled">{{ input.logic.enabled.or ? 'OR' : 'AND' }}</span>
                </div>
            </div>
            <LogicLines :reference="reference" :input="input" :logicKey="'enabled'" />
        </div> -->


        <div class="frv2-opt-trg-base" v-if="!hideEnabled">
            <div class="frv2-opt-trg">
                <div class="frv2-opt-trg-check" v-if="!input.logic || !input.logic.required" @click="onTriggerClicked('required')">
                    <CheckboxUI :checked="input.required" />
                </div>
                <div class="frv2-opt-trg-lock" v-if="input.logic && input.logic.required">
                    <vap-icon small :icon="'locked'" />
                </div>
                <div class="frv2-opt-trg-title" @click="onTriggerClicked('required')">
                    {{ 'Required for editor' }}
                </div>
                <div class="frv2-opt-trg-logic" @click="$emit('logic', {key: input.key, type: 'required' })"
                    :class="{'frv2-opt-trg-activelogic': input.logic && input.logic.required }"
                >
                    <vap-icon small :icon="'logic'" :color="'ext'" absolute :top="-2" :right="0" />
                </div>
                <div class="frv2-opt-trg-op" @click="onModeClicked('required')" :class="{'frv2-opt-trg-op-click': input.logic && input.logic.required }">
                    <span v-if="input.logic && input.logic.required">{{ input.logic.required.or ? 'OR' : 'AND' }}</span>
                </div>
            </div>
            <LogicLines :reference="reference" :input="input" :logicKey="'required'" />
        </div>


        <div class="frv2-opt-trg-base" v-if="!hideEnabled">
            <div class="frv2-opt-trg">
                <div class="frv2-opt-trg-check" v-if="!input.logic || !input.logic.ownerReq" @click="onTriggerClicked('ownerReq')">
                    <CheckboxUI :checked="input.ownerReq" />
                </div>
                <div class="frv2-opt-trg-lock" v-if="input.logic && input.logic.ownerReq">
                    <vap-icon small :icon="'locked'" />
                </div>
                <div class="frv2-opt-trg-title" @click="onTriggerClicked('ownerReq')">
                    {{ 'Required for owner' }}
                </div>
                <div class="frv2-opt-trg-logic" @click="$emit('logic', {key: input.key, type: 'ownerReq' })"
                    :class="{'frv2-opt-trg-activelogic': input.logic && input.logic.ownerReq }"
                >
                    <vap-icon small :icon="'logic'" :color="'ext'" absolute :top="-2" :right="0" />
                </div>
                <div class="frv2-opt-trg-op" @click="onModeClicked('ownerReq')" :class="{'frv2-opt-trg-op-click': input.logic && input.logic.ownerReq }">
                    <span v-if="input.logic && input.logic.ownerReq">{{ input.logic.ownerReq.or ? 'OR' : 'AND' }}</span>
                </div>
            </div>
            <LogicLines :reference="reference" :input="input" :logicKey="'ownerReq'" />
        </div>

    </div>
</PropertyItem>
</div>
</template>
<script>
import editorHelper from '../../editorHelper';

import PropertyItem from './PropertyItem.vue';
import VFCheckbox from '../../inputs/VFCheckbox.vue';
import LogicLines from '../ui/LogicLines.vue';

import CheckboxUI from '../ui/CheckboxUI.vue';

export default {
    name: 'TriggerProperties',
    components: {
        PropertyItem,
        VFCheckbox,
        LogicLines,
        CheckboxUI,
    },
    props: {
        input: { type: Object, default: null },
        reference: { type: Object, default: null },
        hideEnabled: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

        onTriggerClicked(type) {
            if (this.input[type] == null) {
                this.$set(this.input, type, false);
            }
            this.input[type] = !this.input[type];
            editorHelper.onChange(this.reference, 'item_' + type + '_changed', false);
        },

        onModeClicked(type) {
            if (!this.input.logic || !this.input.logic[type]) return;
            this.input.logic[type].or = !this.input.logic[type].or;
            editorHelper.onChange(this.reference, 'logic_' + type + '_op', false);
        },

    }
}
</script>
<style scoped>

.frv2-opt-trg-base {
    margin-bottom: 10px;
}

.frv2-opt-trg {
    display: flex;
    height: 24px;
    border-bottom: solid 1px var(--fb-input-bg);
    background-color: var(--fb-input-bg);
}

.frv2-opt-trg-check {
    width: 30px;
}

.frv2-opt-trg-lock {
    margin-top: -2px;
}

.frv2-opt-trg-title {
    flex-grow: 100;
    padding-left: 3px;
    padding-top: 4px;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: white;
    opacity: 0.8;
}

.frv2-opt-trg-logic {
    position: relative;
    width: 28px;
    fill: white;
    background-color: var(--fb-menu-a);
    cursor: pointer;
}
.frv2-opt-trg-activelogic {
    background-color: #13AA52;
}

.frv2-opt-trg-op {
    width: 50px;
    padding-top: 3px;
    font-weight: 500;
    color: #af81ce;
    text-align: center;
}
.frv2-opt-trg-op-click {
    cursor: pointer;
}
.frv2-opt-trg-op-click:hover {
    background-color: var(--fb-menu-a);
}

</style>