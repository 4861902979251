<template>
    <div class="verl">
        
        <div v-if="controls && controls.length > 0">

            <vap-card v-for="(rec, i) in controls" :key="i" style="margin-bottom: 15px;">
                <div class="verl-c">
                    <span><b>{{ rec.name }}</b></span>
                    <span> mottar invitasjon til å gjennomføre</span>
                    <span><b> ID-kontroll</b></span>
                </div>
            </vap-card>

            <div class="verl-l">
                <label>samtidig</label>
            </div>

        </div>

        <div v-if="(editors && editors.length > 0) || tempEditor">
            
            <vap-card style="margin-bottom: 15px;">
                <div class="verl-c">
                    <span><b>{{ editor.name }}</b></span>
                    <span>{{ __('recipient_order_invitation') }}</span>
                    <span><b>{{ __('recipient_order_editor') }}</b></span>
                </div>
            </vap-card>

            <vap-card style="margin-bottom: 15px;" v-if="!editorIsSigner">
                <div class="verl-c">
                    <span><b>{{ editor.name }}</b></span>
                    <span>{{ __('recipient_order_select_1') }}</span>
                    <span><b>{{ __('recipient_order_signer') }}</b></span>
                    <span>{{ __('recipient_order_select_2') }}</span>
                </div>
            </vap-card>

            <vap-card style="margin-bottom: 15px;" v-if="editorIsSigner">
                <div class="verl-c">
                    <span><b>{{ editor.name }}</b></span>
                    <span>{{ __('recipient_order_fills_and') }}</span>
                    <span><b>{{ __('recipient_order_signs') }}</b></span>
                    <span>{{ __('recipient_order_completed_form') }}</span>
                </div>
            </vap-card>

            <div class="verl-l" v-if="!editorIsSigner">
                <label>{{ __('recipient_order_therafter') }}</label>
            </div>

            <vap-card style="margin-bottom: 15px;" v-if="!editorIsSigner">
                <div class="verl-c">
                    <span><b>{{ __('recipient_order_selected') }}</b></span>
                    <span>{{ __('recipient_order_invitation') }}</span>
                    <span><b>{{ __('recipient_order_signer') }}</b></span>
                </div>
            </vap-card>

        </div>

        <div v-if="signers && signers.length > 0">

            <div v-if="(editors && editors.length > 0) || tempEditor" class="verl-l">
                <label>{{ __('recipient_order_therafter') }}</label>
            </div>
            
            <vap-card v-for="(rec, i) in signers" :key="i" v-show="rec.email" style="margin-bottom: 15px;">
                <div class="verl-c">
                    <span><b>{{ rec.fname + ' ' + rec.lname }}</b></span>
                    <span> mottar invitasjon til å</span>
                    <span v-if="rec.sign"><b> Signere</b></span>
                    <span v-if="rec.approve"><b> Godkjenne</b></span>
                    <span v-if="rec.sign"> med signeringsmetode </span>
                    <span v-if="rec.sign"><b>{{ rec.method }}</b></span>
                </div>
            </vap-card>

        </div>

        <div class="verl-l">
            <label>{{ __('recipient_order_finally') }}</label>
        </div>


        <vap-card style="margin-bottom: 15px;">
            <div class="verl-c">
                <span><b>{{ __('recipient_order_all') }}</b></span>
                <span>{{ __('recipient_order_email') }}</span>
            </div>
        </vap-card>
        

    </div>
</template>

<script>

export default {
    name: 've-recipient-list',
    components: {
        
    },
    props: {
        editors: {
            type: Array,
            default: null
        },
        signers: {
            type: Array,
            default: null
        },
        controls: {
            type: Array,
            default: null
        },
        tempEditor: {
            type: Boolean,
            default: false
        },
        editorIsSigner: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editor: {
                name: 'Mottaker'
            }
        }
    },
    computed: {
        
    },
    watch: {
        editors: {
            deep: false,
            handler(val) {
                console.log('RL: data 1 changed', val);
                if (val && val.length > 0) {
                    this.editor = {
                        name: val[0].fname + ' ' + val[0].lname
                    }
                }
                
            }
        },
        signers: {
            deep: false,
            handler(val) {
                console.log('RL: data 2 changed', val);
            }
        }
    },
    methods: {
        
    },
    async mounted() {

        

    }
}
</script>
<style scoped>

.verl-c {
    padding: 10px 20px;
}

.verl-l {
    margin-top: -5px;
    margin-bottom: 10px;
}

b {
    font-weight: bold;
}

</style>