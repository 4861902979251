<template>
<div>

    <PropertyItem :header="'Methods properties'" :icon="'sign'">
        <vap-row>

            <vap-group c12>
                <vap-label :text="'Source'" desc />
                <vap-dropdown
                    v-model="card.mode"
                    :options="modeOptions"
                    :displayKey="'title'"
                    :listKey="'title'"
                    :outputKey="'value'"
                    noValidation
                />
            </vap-group>

        </vap-row>
    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';

export default {
    name: 'MethodsProperties',
    components: {
        PropertyItem
    },
    props: {
        card: { type: Object, default: null },
    },
    data() {
        return {
            modeOptions: [
                { value: 'sign', title: 'Signing methods' },
                { value: 'auth', title: 'Authentication methods' },
            ],
        }
    },
}
</script>