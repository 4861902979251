<template>
<div>
    <vap-row v-if="company.source == 'bankidaml'">
        <!-- BankID AML -->
        <vap-group c4>
            <vap-label :text="__('common_name')" desc />
            <vap-label :text="company.name" value />
        </vap-group>
        <vap-group c4>
            <vap-label :text="__('company_orgnr')" desc />
            <vap-label :text="company.orgnr" value />
        </vap-group>
        <vap-group c4>
            <vap-label :text="'Duns number'" desc />
            <vap-label :text="company.info.dunsNumber || '- - - -'" value />
        </vap-group>

        <vap-group c8>
            <vap-label :text="__('common_address')" desc />
            <vap-label :text="company.address.business.street + ', ' + company.address.business.zip + ' ' + company.address.business.city" value />
        </vap-group>
        <vap-group c2>
            <vap-label :text="__('common_country')" desc />
            <vap-label :text="company.address.business.country" value />
        </vap-group>
        <vap-group c2>
            <vap-label :text="__('common_commune')" desc />
            <vap-label :text="company.address.business.commune ? company.address.business.communeNumber + ' ' + company.address.business.commune : '- - - -'" value />
        </vap-group>

        <vap-group c4>
            <vap-label :text="__('company_org_form')" desc />
            <vap-label :text="company.form.formCode ? company.form.formCode + ' - ' + company.form.formDescription : company.form.formDescription" value />
        </vap-group>
        <vap-group c4>
            <vap-label :text="__('company_est_date')" desc />
            <vap-label :text="company.info.dateEstablished" value />
        </vap-group>
        <vap-group c4>
            <vap-label :text="__('company_reg_date')" desc />
            <vap-label :text="company.info.dateRegistered" value />
        </vap-group>

        <vap-group c4>
            <vap-label :text="__('company_industry_code')" desc />
            <vap-label :text="company.form.industryDescription ? company.form.industryCode + ' - ' + company.form.industryDescription : '- - - -'" value />
        </vap-group>
        <vap-group c8>
            <vap-label :text="__('company_business')" desc />
            <vap-label :text="company.info.purpose" value />
        </vap-group>

    </vap-row>

    <vap-row v-if="company.source == 'trapets-bisnode'">
        <!-- Trapets/Bisnode -->
        <vap-group c4>
            <vap-label :text="__('common_name')" desc />
            <vap-label :text="company.name" value />
        </vap-group>
        <vap-group c4>
            <vap-label :text="__('company_orgnr')" desc />
            <vap-label :text="company.orgnr" value />
        </vap-group>
        <vap-group c4>
            <vap-label :text="'Duns number'" desc />
            <vap-label :text="company.info.dunsNumber || '- - - -'" value />
        </vap-group>

        <vap-group c8>
            <vap-label :text="__('common_address')" desc />
            <vap-label :text="company.address.business.street + ', ' + company.address.business.zip + ' ' + company.address.business.city" value />
        </vap-group>
        <vap-group c4>
            <vap-label :text="__('common_commune')" desc />
            <vap-label :text="company.address.business.commune ? company.address.business.communeNumber + ' ' + company.address.business.commune : '- - - -'" value />
        </vap-group>

        <vap-group c4>
            <vap-label :text="__('company_org_form')" desc />
            <vap-label :text="company.form.formCode ? company.form.formCode + ' - ' + company.form.formDescription : company.form.formDescription" value />
        </vap-group>
        <vap-group c4>
            <vap-label :text="__('company_reg_date')" desc />
            <vap-label :text="company.info.dateRegistered" value />
        </vap-group>
        <vap-group c4>
            <vap-label :text="__('common_country')" desc />
            <vap-label :text="company.address.business.country" value />
        </vap-group>

        <vap-group c12>
            <vap-label :text="__('company_business')" desc />
            <vap-label :text="company.info.purpose" value />
        </vap-group>

    </vap-row>

    <vap-row v-if="company.source == 'chouse'">
        <!-- Companies House -->
        <vap-group c4>
            <vap-label :text="__('common_name')" desc />
            <vap-label :text="company.name" value />
        </vap-group>
        <vap-group c8>
            <vap-label :text="'Company number'" desc />
            <vap-label :text="company.orgnr" value />
        </vap-group>

        <vap-group c8>
            <vap-label :text="__('common_address')" desc />
            <vap-label :text="company.address.business.street + ', ' + company.address.business.zip + ' ' + company.address.business.city" value />
        </vap-group>
        <vap-group c4>
            <vap-label :text="'Jurisdiction'" desc />
            <vap-label :text="company.info.jurisdiction" value />
        </vap-group>

        <vap-group c4>
            <vap-label :text="__('company_org_form')" desc />
            <vap-label :text="company.form.formDescription + ' (' + company.form.formCode + ')'" value />
        </vap-group>
        <vap-group c4>
            <vap-label :text="__('company_reg_date')" desc />
            <vap-label :text="company.info.dateRegistered" value />
        </vap-group>
<!--         <vap-group c4>
            <vap-label :text="__('common_country')" desc />
            <vap-label :text="company.address.business.country" value />
        </vap-group> -->

    </vap-row>

    <div class="flw100" style="margin-bottom: 25px;">
        <vap-row v-for="(code, i) in company.naceCodes" :key="'bc' + i">
            <vap-group c2 compact>
                <vap-label v-if="i == 0" :text="__('company_business_codes')" desc />
                <vap-label :text="code.code" value />
            </vap-group>
            <vap-group c10 compact>
                <vap-label v-if="i == 0" :text="__('common_description')" desc />
                <vap-label :text="code.description" value />
            </vap-group>
            <template v-slot:actions>
                <vap-icon :icon="'delete'" absolute action @clicked="removeCode(i)">
                    <template v-slot:tooltip><vap-tooltip :text="__('common_remove')" /></template>
                </vap-icon>
            </template>
        </vap-row>
    </div>

    <vap-row v-if="company.source == 'bankidaml'">
        <vap-group c6>
            <vap-label :text="__('company_registered')" desc />
            <vap-label :text="__('company_in_brreg')" q />
            <vap-label :text="getYesNo(company.registration.inBrreg)" a /><br><br>
            <vap-label :text="__('company_in_business_reg')" q />
            <vap-label :text="getYesNo(company.registration.inBusinessRegistry)" a /><br>
            <vap-label :text="__('company_in_mva_reg')" q />
            <vap-label :text="getYesNo(company.registration.inVatRegistry)" a />
        </vap-group>
        <vap-group c6>
            <vap-label :text="__('company_status')" desc />
            <vap-label :text="__('company_stat_bankrupt')" q />
            <vap-label :text="getYesNo(company.registration.bankrupt)" a /><br><br>
            <vap-label :text="__('company_stat_liquidation')" q />
            <vap-label :text="getYesNo(company.registration.liquidation)" a /><br>
            <vap-label :text="__('company_stat_cliquidation')" q />
            <vap-label :text="getYesNo(company.registration.compulsoryLiquidation)" a />
        </vap-group>
    </vap-row>

    <vap-row v-if="company.source == 'trapets-bisnode'">
        <vap-group c12>
            <vap-label :text="__('company_registered')" desc />
            <vap-label :text="__('company_in_mva_reg')" q />
            <vap-label :text="getYesNo(company.registration.inVatRegistry)" a /><br><br>
            <vap-label :text="'F-skattsedel finns'" q />
            <vap-label :text="getYesNo(company.registration.taxAssessment)" a />
        </vap-group>
    </vap-row>

    <vap-row v-if="company.source == 'chouse'">
        <vap-group c6>
            <vap-label :text="' '" desc />
            <vap-label :text="'Status'" q />
            <vap-label :text="company.registration.status" a /><br><br>
            <vap-label :text="'Can file'" q />
            <vap-label :text="getYesNo(company.registration.canFile)" a /><br>
            <vap-label :text="'Has been liquidated'" q />
            <vap-label :text="getYesNo(company.registration.liquidation)" a />
        </vap-group>
        <vap-group c6>
            <vap-label :text="' '" desc />
            <vap-label :text="'Has insolvency history'" q />
            <vap-label :text="getYesNo(company.registration.insolvency)" a /><br><br>
            <vap-label :text="'Registered office is in dispute'" q />
            <vap-label :text="getYesNo(company.registration.officeInDispute)" a /><br>
            <vap-label :text="'Undeliverable registered office address'" q />
            <vap-label :text="getYesNo(company.registration.undeliverableAddress)" a />
        </vap-group>
    </vap-row>

    <vap-card :header="__('company_manual_header')" v-if="manualData.name">
        <CompanyInputsF v-model="manualData" noValidation />
    </vap-card>

</div>
</template>

<script>
import log from '@/log';
import CompanyInputsF from '../kyc/CompanyInputsF';
export default {
    name: 'CompanyDetails',
    components: {
        CompanyInputsF
    },
    props: {
        company: {
            type: Object,
            default: () => { return {} }
        },
        manualData: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            loading: true,
        }
    },
    watch: {
        
    },
    methods: {
        
        getYesNo(value) {
            if (value == 'Y') return this.__('common_yes');
            if (value == 'N') return this.__('common_no');
            return this.__('common_unknown');
        }

    },
    async created() {

        //console.log('company', this.company);

        this.loading = false;
    }
}
</script>
<style scoped>



</style>
