<template>
<div>
    <ve-popup v-if="ready" :header="'Landing designer'" :width="1200" @close="$emit('close')" >
        <vap-tabs accent v-model="textParams.tabModel" :tabs="textParams.tabOptions" @input="onLocaleChanged" />
        <vap-tabs v-model="model" :tabs="options" accent style="margin-top: 1px;" />

        <div>

        </div>

        <div>

            <div class="lform-landing-logo" style="margin-top: 150px;">
                <img :src="$root.config.kycs[0].landing.logo" />
            </div>

            <div class="lform-landing-header">
                <div class="lform-landing-header-center">
                    <div :class="aborted ? 'lform-landing-icon-error' : 'lform-landing-icon'">
                        <vap-icon :icon="aborted ? 'cancel' : 'check'" :color="aborted ? 'var(--error-text)' : 'var(--primary-contrast)'" />
                    </div>
                    <div class="lform-landing-text">
                        <vap-input v-model="$parent.landingParams[selectedModel].title[selectedLocale]" />
                    </div>
                </div>
            </div>

            <vap-card style="margin-top: 40px;">
                <vap-text-editor :value="$parent.landingParams[selectedModel].message[selectedLocale]" ref="lfte" :editable="true" />
            </vap-card>

            <div class="lform-landing-footer">
                <span>{{ __t('form-landing-close') + ' ' }}</span><span><a :href="$root.config.kycs[0].landing.redirect">{{ __t('form-landing-homepage') }}</a></span>
            </div>

        </div>
        
    </ve-popup>
</div>
</template>

<script>
export default {
    name: 'LandingDialog',
    props: {
        params: { type: Object, default: () => { return {} }},
        textParams: { type: Object, default: () => { return {} }}
    },
    data() { 
        return {
            selectedLocale: '',
            model: {
                filled: true,
                signed: false,
                aborted: false,
            },
            options: {
                filled: { label: 'Filled' },
                signed: { label: 'Signed' },
                aborted: { label: 'Aborted' },
            },
            selectedModel: 'filled',
            ready: false,
            aborted: false
        }
    },
    watch: {
        model: {
            deep: false,
            handler(val) {
                this.saveCurrent();
                for (const key in this.model) {
                    if (this.model[key]) {
                        this.selectedModel = key;
                        console.log('selected model', this.selectedModel);
                    }
                }

            }
        }
    },
    methods: {
        
        onLocaleChanged(val) {
            this.saveCurrent();
            console.log('locale', val);
            for (const key in val) {
                if (val[key]) {
                    this.selectedLocale = key;
                    console.log('selected', this.selectedLocale);
                }
            }
        },

        saveCurrent() {
            if (!this.$refs.lfte) return;
            console.log('saving');
            this.$parent.landingParams[this.selectedModel].message[this.selectedLocale] = this.$refs.lfte.getText();
        }

    },
    created() {

        console.log('lParams', this.$parent.landingParams);

/*         let firstOne = true;
        for (const i in this.$parent.roleOptions) {
            console.log('add role', this.$parent.roleOptions[i]);
            if (this.$parent.roleOptions[i].type != 'owner') {
                this.$set(this.model, this.$parent.roleOptions[i].key, firstOne);
                this.$set(this.options, this.$parent.roleOptions[i].key, { label: this.$parent.roleOptions[i].display });
                firstOne = false;
            }
        } */

        this.ready = true;

        this.onLocaleChanged(this.textParams.tabModel);
    }
}
</script>
<style scoped>

.lform-landing-logo {
    float: left;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}
.lform-landing-logo img {
    width: 360px;
}

.lform-landing-header {
    float: left;
    width: 100%;
    margin-top: 40px;
}
.lform-landing-header-center {
    display: flex;
    width: fit-content;
    margin: 0 auto;
}
.lform-landing-icon {
    width: 45px; height: 45px;
    padding-top: 5px;
    padding-left: 5px;
    border-radius: 50%;
    background-color: var(--primary);
    box-sizing: border-box;
}
.lform-landing-icon-error {
    width: 45px; height: 45px;
    padding-top: 4px;
    padding-left: 4px;
    border-radius: 50%;
    background-color: var(--error-background);
    box-sizing: border-box;
}
.lform-landing-text {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 20px;
}
.lform-landing-footer {
    float: left;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}

</style>