<template>
    <div class="vepop">

        <div class="vepop-top">

            <div class="vepop-top-left">
                <div class="vepop-back vepop-icon" @click="handleClose"> 
                    <svg height="50" version="1.1" width="50" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.8);">
                        <path d="M 19.9375 12.96875 C 19.414063 12.988281 18.921875 13.214844 18.5625 13.59375 L 8.6875 23.59375 L 7.3125 25 L 8.6875 26.40625 L 18.5625 36.40625 C 19.339844 37.199219 20.613281 37.214844 21.40625 36.4375 C 22.199219 35.660156 22.214844 34.386719 21.4375 33.59375 L 14.90625 27 L 40 27 C 40.722656 27.011719 41.390625 26.632813 41.753906 26.007813 C 42.121094 25.386719 42.121094 24.613281 41.753906 23.992188 C 41.390625 23.367188 40.722656 22.988281 40 23 L 14.90625 23 L 21.4375 16.40625 C 22.039063 15.824219 22.21875 14.933594 21.882813 14.164063 C 21.546875 13.398438 20.773438 12.921875 19.9375 12.96875 Z"/>
                    </svg>
                </div>
                <div class="vepop-name"> 
                    <label>{{ filename }}</label>
                </div>
            </div>

            <div class="vepop-nav"> 

                <div class="vepop-nav-left vepop-icon" :class="{'vepop-icon-disabled':pDisabled}" @click="navigatePrev">
                    <svg height="50" version="1.1" width="50" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.8);">
                        <path d="M 19.9375 12.96875 C 19.414063 12.988281 18.921875 13.214844 18.5625 13.59375 L 8.6875 23.59375 L 7.3125 25 L 8.6875 26.40625 L 18.5625 36.40625 C 19.339844 37.199219 20.613281 37.214844 21.40625 36.4375 C 22.199219 35.660156 22.214844 34.386719 21.4375 33.59375 L 14.90625 27 L 40 27 C 40.722656 27.011719 41.390625 26.632813 41.753906 26.007813 C 42.121094 25.386719 42.121094 24.613281 41.753906 23.992188 C 41.390625 23.367188 40.722656 22.988281 40 23 L 14.90625 23 L 21.4375 16.40625 C 22.039063 15.824219 22.21875 14.933594 21.882813 14.164063 C 21.546875 13.398438 20.773438 12.921875 19.9375 12.96875 Z"/>
                    </svg>
                </div>

                <div class="vepop-nav-mid">
                    <label>{{ page }}</label>
                    <label class="vepop-gray">{{ ' / ' }}</label>
                    <label>{{ count }}</label>
                </div>

                <div class="vepop-nav-right vepop-icon" :class="{'vepop-icon-disabled':nDisabled}" @click="navigateNext">
                    <svg height="50" version="1.1" width="50" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.8);">
                        <path d="M 29.84375 13.09375 C 29.066406 13.167969 28.402344 13.691406 28.148438 14.429688 C 27.890625 15.171875 28.089844 15.992188 28.65625 16.53125 L 35.125 23 L 10 23 C 9.9375 22.996094 9.875 22.996094 9.8125 23 C 8.707031 23.050781 7.855469 23.988281 7.90625 25.09375 C 7.957031 26.199219 8.894531 27.050781 10 27 L 35.125 27 L 28.625 33.46875 C 27.839844 34.253906 27.839844 35.527344 28.625 36.3125 C 29.410156 37.097656 30.683594 37.097656 31.46875 36.3125 L 41.34375 26.40625 L 42.78125 25 L 41.34375 23.59375 L 31.46875 13.6875 C 31.046875 13.253906 30.449219 13.035156 29.84375 13.09375 Z"/>
                    </svg>
                </div>

            </div>

            <div class="vepop-top-right">

                <div class="vepop-icon" @click="downloadFile" style="margin-right: 20px;" v-if="!loading">
                    <svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" style="transform: scale(1); margin-top: 13px;">
                        <path d="M 11 2 C 10.448 2 10 2.448 10 3 L 10 11 L 6.5 11 A 0.5 0.5 0 0 0 6 11.5 A 0.5 0.5 0 0 0 6.1464844 11.853516 A 0.5 0.5 0 0 0 6.1777344 11.882812 L 11.283203 16.697266 L 11.316406 16.728516 A 1 1 0 0 0 12 17 A 1 1 0 0 0 12.683594 16.728516 L 12.697266 16.716797 A 1 1 0 0 0 12.707031 16.705078 L 17.810547 11.892578 A 0.5 0.5 0 0 0 17.839844 11.865234 L 17.847656 11.859375 A 0.5 0.5 0 0 0 17.853516 11.853516 A 0.5 0.5 0 0 0 18 11.5 A 0.5 0.5 0 0 0 17.5 11 L 14 11 L 14 3 C 14 2.448 13.552 2 13 2 L 12 2 L 11 2 z M 3 20 A 1.0001 1.0001 0 1 0 3 22 L 21 22 A 1.0001 1.0001 0 1 0 21 20 L 3 20 z"/>
                    </svg>
                </div>

                <div class="vepop-icon" @click="handleClose">
                    <svg height="50" version="1.1" width="50" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.8);">
                        <path d="M 14.40625 13 L 13 14.40625 L 23.625 25 L 13 35.59375 L 14.40625 37 L 25.0625 26.40625 L 35.6875 37 L 37.09375 35.59375 L 26.46875 25 L 37.09375 14.40625 L 35.6875 13 L 25.0625 23.59375 Z"/>
                    </svg>
                </div>

            </div>

        </div>

        <div class="vepop-bg" @click="handleClose" />

        <div class="vepop-center" :style="'width:' + '900' + 'px;'">
            <div class="vepop-content">
<!--                 <div class="vepop-header" v-if="header">
                    <label>{{ header }}</label>
                </div> -->

                <div v-if="loading" class="vepop-loader">
                    <div class="vepop-loader-p">
                        <vap-spinner free :text="'Laster PDF...'" />
                    </div>
                </div>

                <Preview  ref="pv" :raw="previewData" :hideControls="true" @pages="updatePages" />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { auth, envelopes } from '@verified/libvf'
import verified from '../../verified'
import backend from '../../backend'

import Preview from './Preview'
import downloadjs from 'downloadjs'

export default {
    name: 've-popup',
    components: {
        Preview
    },
    props: {
        envelopeId: {
            type: String,
            default: ''
        },
        blob: {
            type: String,
            default: ''
        },
        filename: {
            type: String,
            default: 'filename'
        },
        showAttachment: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            debugging: false,
            header: 'Forhåndsvisning',
            previewData: '',
            loading: true,
            page: 0,
            count: 0,
            pDisabled: true,
            nDisabled: true
        }
    },
    methods: {
        handleClose() {
            this.$emit("close", true);
        },
        updatePages(data) {
            this.page = data.page;
            this.count = data.count;
            this.pDisabled = data.pDisabled;
            this.nDisabled = data.nDisabled;
        },
        navigatePrev() {
            if (this.pDisabled) return;
            this.$refs.pv.goPrev();
        },
        navigateNext() {
            if (this.nDisabled) return;
            this.$refs.pv.goNext();
        },
        downloadFile() {
            downloadjs("data:application/pdf;base64," + this.previewData, this.filename + ".pdf", "application/pdf");
        },
        async downloadPDF() {
            let envelope = await envelopes.get(this.envelopeId);

            if (this.debugging) console.log('env', envelope);

            let documentId = envelope.firstDocument().id;
            let uid = 'error';
            if (envelope.firstDocument().data.files.document) {
                uid = envelope.firstDocument().data.files.document.uid;
            }
            if (envelope.firstDocument().data.files.signedDocument) {
                uid = envelope.firstDocument().data.files.signedDocument.uid;
            }
            if (this.showAttachment && envelope.firstDocument().data.files.attachments && envelope.firstDocument().data.files.attachments.length > 0) {
                uid = envelope.firstDocument().data.files.attachments[0].uid;
            }

            let url = await verified.getDownloadUrl(envelope.id, documentId, uid);
            if (this.debugging) console.log('url = ' + url);

            let data = await verified.downloadFile(url);

            this.previewData = data;

            let self = this;
            setTimeout(function() {
                self.loading = false;
            }, 1000);
            
        }
    },
    async created() {
        if (this.blob) {
            await new Promise(r => setTimeout(r, 1000));
            this.previewData = this.blob;
            this.loading = false;
            return;
        }
        if (this.debugging) console.log('envelopeId', this.envelopeId);
        if (this.debugging) console.log('cred', this.$root.credentials);

        this.downloadPDF();
    }
}
</script>
<style scoped>

.vepop {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height:100vh;
    height: 100%;
    padding-bottom: 100px;
    z-index: 100;
}

.vepop-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
}

.vepop-center {
    position: relative;
    padding-top: 70px;
    width: 60%;
    margin: 0 auto;
    min-height: 500px;
}

.vepop-content {
    position: relative;
    width: 100%;
    margin: 0 auto;
    min-height: 900px;
    overflow: hidden;
    border: solid 2px black;
    border-radius: 5px;
    background-color: white;
    -webkit-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 10px 0px rgba(0,0,0,0.75);
}

@media screen and (max-width: 800px) {
    .vepop-center {
        width: 95%;
    }
}

.vepop-header {
    width: 100%;
    border-bottom: solid 1px lightgray;
    padding-top: 20px;
    padding-bottom: 6px;
    padding-left: 20px;
}
.vepop-header label {
    font-size: 20px;
}

.vepop-close {
    width: 100%;
    padding: 10px;
    text-align: center;
}

.vepop-loader {
    position: absolute;
    width: 900px;
    height: 600px;
    
}
.vepop-loader-p {
    position: absolute;
    top: 300px;
    left: 50%;
}


.vepop-top {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 50px;
    background-color: var(--header);
    opacity: 0.9;
    z-index: 12;
}

.vepop-top-left {
    display: flex;
}

.vepop-back {
    margin-left: 10px;
}


.vepop-name {
    margin-top: 13px;
    margin-left: 10px;
    opacity: 0.8;
}
.vepop-name label {
    font-size: 20px;
    color: var(--header-text);
}


.vepop-top-right {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
}


.vepop-nav {
    position: absolute;
    top: 0px; left: 50%;
    display: flex;
    width: 200px;
    margin-left: -100px;
}

.vepop-nav-mid {
    width: 100px;
    text-align: center;
    padding-top: 12px;
    opacity: 0.8;
}
.vepop-nav-mid label {
    font-size: 22px;
    color: var(--header-text);
}
.vepop-gray {
    margin: 0px 5px;
    opacity: 0.5;
}


.vepop-icon {
    opacity: 0.7;
    cursor: pointer;
}
.vepop-icon:hover {
    opacity: 1;
}
.vepop-icon path {
    fill: var(--header-text);
}
.vepop-icon-disabled {
    opacity: 0.2;
    cursor: default;
}
.vepop-icon-disabled:hover {
    opacity: 0.2;
}

</style>