<template>
<div style="padding: 50px 50px;">

     <!-- PDF Title/Logo -->
    <!-- <vap-logo if(data.pdfConfig.showLogo) /> -->

    <div class="npdf-logo">
        <img :src="xval('pdfConfig.logo')" />
    </div>
    
    <div v-for="(card, i) in data.pdfPages" :key="'c' + i">


        <div v-if="(card.header)" class="npdf-card-header" :style="'background-color: ' + xval('pdfConfig.colors.headerBack') + '; color: ' + xval('pdfConfig.colors.headerText') + ';'">
            {{card.header}}
        </div>

        <div v-if="(card.type == 'chtml')" v-html="card.html">
            
        </div>

        <table class="npdf-table" style="margin-bottom: 20px;" v-if="(card.type == 'card')">
            <tr class="npdf-row" v-for="(row, j) in card.rows" :key="'r' + j">


                <td class="npdf-col" v-for="(input, k) in row.inputs" :key="'i' + k" :colspan="input.width">

                    <div v-if="(input.type == 'text')">
                        <div class="npdf-desc">
                            {{input.desc}}
                        </div>
                        <div class="npdf-value">
                            {{input.value}}
                        </div>
                    </div>


                    <div class="npdf-check" v-if="(input.type == 'check')">

                        <div class="npdf-desc" style="margin-bottom: 2px;" v-if="(input.desc)">
                            {{input.desc}}
                        </div>

                        <div :class="input.inline ? 'npdf-inline' : ''" v-if="(!input.radiolist)">

                            <div class="npdf-option" v-for="(option, l) in input.options" :key="'o' + l">
                                <div class="npdf-circle">
                                    <div class="npdf-ball" v-if="(option.checked == true)" :style="'background-color: ' + xval('pdfConfig.colors.inputAccent') + ';'" />
                                </div>
                                <div class="npdf-label">
                                    {{option.desc}}
                                </div>
                            </div>

                        </div>

                        <div class="npdf-radiolist" v-if="(input.radiolist)">

                            <div class="npdf-radiolist-option" v-for="(option, l) in input.options" :key="'o' + l">
                                <div class="npdf-circle">
                                    <div class="npdf-ball" v-if="(option.checked == true)" :style="'background-color: ' + xval('pdfConfig.colors.inputAccent') + ';'" />
                                </div>
                                <div class="npdf-label">
                                    {{option.desc}}
                                </div>
                            </div>

                        </div>
                        
                    </div>

                    
                    <div v-if="(input.type == 'html')" class="npdf-html" v-html="input.desc">
                        
                    </div>

                </td>


            </tr>
        </table>

    </div>

</div>
</template>
<script>


export default {
    name: 'PdfRendererV2',
    components: {
        
    },
    props: {
        data: { type: Object, default: null },
    },
    data() {
        return {
            
        }
    },
    methods: {

        xval(path) {
            const parts = path.split('.');
            if (parts.length == 2) {
                return this.data[parts[0]][parts[1]];
            }else if (parts.length == 3) {
                return this.data[parts[0]][parts[1]][parts[2]];
            }
            return '';
        },

    },
    created() {
        console.log('PDF Renderer data', this.data);
    }
}
</script>
<style scoped>

.npdf-logo img {
    width: 200px;
}


.npdf-card-header {
    padding: 10px;
    font-size: 14px;
    box-sizing: border-box;
}

.npdf-table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0px;
    border-collapse: separate;
    border-top: 1px solid lightgray;
    border-right: 1px solid lightgray;
}
.npdf-col {
    padding: 7px;
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.npdf-desc {
    font-size: 11px;
}
.npdf-value {
    margin-top: 2px;
    font-size: 12px;
    font-weight: bold;
}

.npdf-check {
    margin-top: 2px;
}
.npdf-inline {
    display: flex;
}
.npdf-radiolist {
    margin-top: -10px;
    margin-bottom: -7px;
}
.npdf-option {
    display: flex;
    margin-top: 2px;
    margin-right: 10px;
}
.npdf-radiolist-option {
    display: flex;
    padding: 8px 7px;
    margin-top: 2px;
    margin-left: -7px;
    margin-right: -7px;
    border-top: 1px solid lightgray;
}
.npdf-circle {
    flex-shrink: 0;
    width: 16px; height: 16px;
    border-radius: 10px;
    border: solid 1px gray;
}
.npdf-ball {
    margin-top: 2px;
    margin-left: 2px;
    width: 12px; height: 12px;
    border-radius: 6px;
    background-color: #1F8DCD;
}
.npdf-label {
    margin-top: 3px;
    margin-left: 4px;
    font-size: 12px;
}

.npdf-html {
    font-size: 11px;
}
.npdf-html li p {
    margin: none;
    margin-block-start: 0px;
    margin-block-end: 0px;
}

</style>