<template>
    <vap-card style="margin-top: 20px">
        <vap-row>
            <vap-group c12>
                <span class="addon-label">Screening Addon</span>
            </vap-group>
        </vap-row>
        <vap-row>
            <vap-group c12>
                <vap-label :text="'Duplicate screening'" desc />
                <vap-checkbox v-model="store.skipIfAlreadyScreened" text="Skip if person has been screened before" noValidation></vap-checkbox>
            </vap-group>
        </vap-row>
        <vap-row>
            <vap-group c12>
                <vap-label :text="'Select the roles to perform screening on'" desc />
                <vap-checkbox v-model="store.screeningRoles.role_individual" text="Individuals (Physical person customer)" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_ceo" text="CEO" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_chair" text="Chairman" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_rrh" text="Beneficial owner" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_board_member" text="Board member" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_deputy_bm" text="Deputy board member" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_deputy_chair" text="Deputy chairman" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_procura" text="Procura" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_signatory" text="Signatory" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_shareholder" text="Shareholder" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_accountant" text="Accountant" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_secretary" text="Secretary" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_director" text="Director" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_other" text="Others" noValidation></vap-checkbox>
                <vap-checkbox v-model="store.screeningRoles.role_procura" text="Procura" noValidation></vap-checkbox>
            </vap-group>
        </vap-row>
    </vap-card>
</template>

<script>
import v1api from "../v1helpers/v1api"
import { screening } from "../v1helpers/onboardingHelper"

let store = {
    skipIfAlreadyScreened: false,
    screeningRoles: {
        role_individual: true,
        role_ceo: true,
        role_chair: true,
        role_rrh: true,
        role_board_member: true,
        role_deputy_bm: false,
        role_shareholder: false,
        role_procura: false,
        role_deputy_chair: false,
        role_signatory: false,
        role_accountant: false,
        role_secretary: false,
        role_director: false,
        role_other: false,
        role_customer_contact: false,
    }
}

const ScreeningAddon = {
    name: "ScreeningAddon",
    data() {
        return {
            store,
        }
    }
}

const label = "screening"

const requiredFields = (customerType) => {
    return []
}

const process = async (root, clientId, statusFunc, errorFunc, customerType, customer, flow) => {

    console.log("STORE", store.screeningRoles)

    const whitelistedRoles = []

    for(let key in store.screeningRoles) {
        if(store.screeningRoles[key]) {
            whitelistedRoles.push(key)
        }
    }

    console.log("Whitelisted roles:", whitelistedRoles)

    statusFunc("Fetching client data")

    let client;

    try {
        client = await v1api.getClient(clientId)
        console.log("CLIENT", client)
    } catch(err) {
        console.error(err)
        errorFunc("Failed to fetch client data")
        return
    }

    if(!client) {
        errorFunc("Couldn't find client")
        return
    }

    for(let person of client.persons) {

        console.log("person", person)

        
        let performScreening = false
        for(let role of person.roles) {
            if(whitelistedRoles.includes(role.id)) {
                performScreening = true
                break
            }
        }

        if(person.screening && person.screening.done && store.skipIfAlreadyScreened) {
            performScreening = false
        }

        if(!performScreening) {
            console.log("Skipped screening on", person.info.firstName, person.info.lastName)
            continue
        }

        statusFunc(`Screening ${person.info.firstName} ${person.info.lastName}..`)

        // TODO: check if whitelisted role
        try {
            await screening(root, client, person, person.info.ssn);
        } catch(err) {
            console.error(err)
            errorFunc("Failed to perform screening on person " + person.personId)
            continue
        }

    }

    return

    switch(customerType) {
        case "org-no": {

            break;
        }

        case "person-no": {

            break;
        }
    }

    if(customerType != "org-no") {
        return
    }

    // do work

    return
}


export { process, requiredFields, label }
export default ScreeningAddon
</script>

<style scoped>
.addon-label {
    color: #238CCC;
    font-weight: bold;
    font-size: 14px;
    border-bottom: 2px solid #238CCC;
    padding-bottom: 4px;
    display: block;
}
</style>