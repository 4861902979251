<template>
<PropertyItem padded :header="'Logic'" :icon="'logic'" :initialCollapse="initialCollapse">

    <div class="frv2-logic-empty" v-if="!input.logic && !input.flow">
        <label>{{ 'No logic on this input' }}</label>
    </div>

    <div v-if="input.logic">

        <div class="frv2-logx-itm" v-if="input.logic.visible">
            <div class="frv2-logx-itm-con">
                <div class="frv2-logx-itm-top">
                    <div class="frv2-logx-itm-lval" style="padding-left: 0px; padding-right: 10px; font-weight: initial;">
                        {{ 'Visible if' }}
                    </div>
                    <div class="frv2-logx-itm-lval frv2-logx-itm-btn" style="padding: 1px 10px; color: #6d07b1;" @click="changeVisibleMode">
                        <span v-if="input.logic.visible.or">{{ 'OR' }}</span>
                        <span v-if="!input.logic.visible.or">{{ 'AND' }}</span>
                    </div>
                </div>
                <div class="frv2-logx-itm-bot">
                    <div class="frv2-logx-itm-lval" v-for="(itemKey, i) in input.logic.visible.keys" :key="'lvi' + i">
                        {{ getKeyDisplay(itemKey) }}
                    </div>
                </div>
            </div>
            <div class="frv2-logx-itm-del" @click="deleteLogic('visible')">
                <vap-icon :icon="'delete'" small />
            </div>
        </div>

        <div class="frv2-logx-itm" v-if="input.logic.disabled">
            <div class="frv2-logx-itm-con">
                <div class="frv2-logx-itm-top">
                    <div class="frv2-logx-itm-lval" style="padding-left: 0px; padding-right: 10px; font-weight: initial;">
                        {{ 'Disabled if' }}
                    </div>
                    <div class="frv2-logx-itm-lval frv2-logx-itm-btn" style="padding: 1px 10px; color: #6d07b1;" @click="changeDisabledMode">
                        <span v-if="input.logic.disabled.or">{{ 'OR' }}</span>
                        <span v-if="!input.logic.disabled.or">{{ 'AND' }}</span>
                    </div>
                </div>
                <div class="frv2-logx-itm-bot">
                    <div class="frv2-logx-itm-lval" v-for="(itemKey, i) in input.logic.disabled.keys" :key="'lvi' + i">
                        {{ getKeyDisplay(itemKey) }}
                    </div>
                </div>
            </div>
            <div class="frv2-logx-itm-del" @click="deleteLogic('disabled')">
                <vap-icon :icon="'delete'" small />
            </div>
        </div>

        <div class="frv2-logx-itm" v-if="input.logic.rdict">
            <div class="frv2-logx-itm-con">
                <div class="frv2-logx-itm-top nohl">
                    {{ 'Dictionary connected to' }}
                </div>
                <div class="frv2-logx-itm-bot nohl">
                    <div class="frv2-logx-itm-lval">
                        {{ 'System' }}
                    </div>
                    <div class="frv2-logx-itm-lval">
                        {{ 'Zip Codes' }}
                    </div>
                </div>
            </div>
            <div class="frv2-logx-itm-del" @click="deleteLogic('rdict')">
                <vap-icon :icon="'delete'" small />
            </div>
        </div>

        <div class="frv2-logx-itm" v-if="input.logic.dmatch">
            <div class="frv2-logx-itm-con">
                <div class="frv2-logx-itm-top nohl">
                    {{ 'Dictionary match will update' }}
                </div>
                <div class="frv2-logx-itm-bot nohl">
                    <div class="frv2-logx-itm-lval" v-for="(itemKey, i) in input.logic.dmatch.keys" :key="'ldi' + i">
                        {{ getKeyDisplay(itemKey) }}
                    </div>
                </div>
            </div>
            <div class="frv2-logx-itm-del" @click="deleteLogic('dmatch')">
                <vap-icon :icon="'delete'" small />
            </div>
        </div>

        <div class="frv2-logx-itm" v-if="input.logic.rlist">
            <div class="frv2-logx-itm-con">
                <div class="frv2-logx-itm-top nohl">
                    {{ 'Dropdown items autofilled from' }}
                </div>
                <div class="frv2-logx-itm-bot nohl">
                    <div class="frv2-logx-itm-lval">
                        {{ this.reference.resources[input.logic.rlist.key].name }}
                    </div>
                </div>
            </div>
            <div class="frv2-logx-itm-del" @click="deleteLogic('rlist')">
                <vap-icon :icon="'delete'" small />
            </div>
        </div>

        <div class="frv2-logx-itm" v-if="input.logic.link">
            <div class="frv2-logx-itm-con" v-for="(item, i) in input.logic.link" :key="'ldi' + i">
                <div class="frv2-logx-itm-top nohl">
                    {{ getLinkDisplay(item.type, item.link) }}
                </div>
                <div class="frv2-logx-itm-bot nohl">
                    <div class="frv2-logx-itm-lval">
                        {{ getKeyDisplay(item.key) }}
                    </div>
                </div>
            </div>
            <div class="frv2-logx-itm-del" @click="deleteLogic('link')">
                <vap-icon :icon="'delete'" small />
            </div>
        </div>

    </div>

    <div v-if="input.flow">

        <div class="frv2-logx-itm" v-if="input.flow.field">
            <div class="frv2-logx-itm-con">
                <div class="frv2-logx-itm-top nohl">
                    {{ input.flow.field.rkey }}
                </div>
                <div class="frv2-logx-itm-bot nohl">
                    <div class="frv2-logx-itm-lval">
                        {{ input.flow.field.type }}
                    </div>
                </div>
            </div>
            <div class="frv2-logx-itm-del" @click="deleteFlow">
                <vap-icon :icon="'delete'" small />
            </div>
        </div>

        <div class="frv2-logx-itm" v-if="input.flow.override">
            <div class="frv2-logx-itm-con">
                <div class="frv2-logx-itm-top nohl">
                    {{ input.flow.override.rkey }}
                </div>
                <div class="frv2-logx-itm-bot nohl">
                    <div class="frv2-logx-itm-lval">
                        {{ getKeyDisplay(input.flow.override.key) }}
                    </div>
                </div>
            </div>
            <div class="frv2-logx-itm-del" @click="deleteFlow">
                <vap-icon :icon="'delete'" small />
            </div>
        </div>

    </div>

    

<!--     <div class="frv2-logic-itm" v-if="input.logic && input.logic.rlist">
        <label>{{ 'Items connected to ' + this.reference.resources[input.logic.rlist.key].name }}</label>
    </div> -->

    <div class="frv2-logic-itm" v-if="input.logic && input.logic.rval">
        <label>{{ 'Value connected to ' + this.reference.resources[input.logic.rval.key].name }}</label>
    </div>

<!--     <div v-if="input.logic && input.logic.link">
        <div class="frv2-logic-itm" v-for="(link, i) in input.logic.link" :key="'lpl' + i">
            <label>{{ 'Value linked to ' + link.link + ' (' + link.type + ') (' + link.key + ')' }}</label>
        </div>
    </div> -->

    <div class="frv2-logic-itm" v-if="input.logic && input.logic.field">
        <label>{{ 'Value wil be autofilled with ' + input.logic.field.key + ' (' + input.logic.field.type + ')' }}</label>
    </div>

    <!-- <div class="frv2-logic-itm" v-if="input.logic && input.logic.visible">
        <label>{{ input.logic.visible }}</label>
        <vap-icon :icon="'delete'" action @clicked="deleteLogic('visible')" />
    </div> -->


</PropertyItem>
</template>
<script>
import PropertyItem from './PropertyItem.vue';

import formHelper from '../../formHelper';

export default {
    name: 'LogicProperties',
    components: {
        PropertyItem
    },
    props: {
        input: { type: Object, default: null },
        reference: { type: Object, default: null },
        initialCollapse: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

        changeVisibleMode() {
            console.log('visible', this.input.logic.visible);
            this.input.logic.visible.or = !this.input.logic.visible.or;
        },

        changeDisabledMode() {
            console.log('disabled', this.input.logic.disabled);
            this.input.logic.disabled.or = !this.input.logic.disabled.or;
        },
        
        deleteLogic(key) {
            delete this.input.logic[key];
            if (Object.keys(this.input.logic).length == 0) {
                delete this.input.logic;
            }
            this.$emit('reselect');
        },

        deleteFlow() {
            delete this.input.flow;
        },

        getKeyDisplay(key) {
            const maxLength = 20;
            if (typeof key == 'string') {

                const item = formHelper.getItemFromKey(this.reference.pages, key);
                if (!item) return '[INPUT]';

                let desc = '[' + item.type.toUpperCase() + '_' + 'INPUT]';
                if (item.desc) {
                    if (item.desc[this.$root.locale.key]) {
                        desc = item.desc[this.$root.locale.key];
                    }else if (desc = item.desc.en_EN) {
                        desc = item.desc.en_EN;
                    }
                }else if (item.locale) {
                    if (item.locale[this.$root.locale.key]) {
                        desc = item.locale[this.$root.locale.key];
                    }else if (desc = item.locale.en_EN) {
                        desc = item.locale.en_EN;
                    }
                }
                if (desc.length > maxLength) desc = desc.substring(0, maxLength - 3) + '...';
                desc += ' (' + item.key + ')';
                return desc;

            }else {
                if (key.type == 'logic') {

                    for (const i in this.reference.logic) {
                        if (this.reference.logic[i].key == key.key) return 'L:' + this.reference.logic[i].name;
                    }

                    return 'logic';

                }else if (key.type == 'role') {

                    return 'Role = ' + key.key;

                }else if (key.type == 'sys') {

                    let desc = 'System value: ' + key.key;
                    if (key.rev) desc = 'NOT ' + desc;
                    return desc;

                }else if (key.type == 'kyc') {

                    return 'KYC (' + key.key + ')';

                }else if (key.type == 'loc') {

                    if (key.key == 'nordic') return 'Outside nordics';
                    if (key.key == 'euro') return 'Outside EU/EFTA';

                    return 'Country: ' + this.$root.getCountryName(key.key);

                }else {
                    const item = formHelper.getItemFromKey(this.reference.pages, key.key);
                    if (!item) return 'ERROR';
                    let desc = '[' + item.type.toUpperCase() + '_' + 'OPTION]';
                    if (item.locale) {
                        if (item.locale[this.$root.locale.key]) {
                            desc = item.locale[this.$root.locale.key];
                        }else if (desc = item.locale.en_EN) {
                            desc = item.locale.en_EN;
                        }
                    }else if (item[this.$root.locale.key]) {
                        desc = item[this.$root.locale.key];
                    }
                    if (desc.length > maxLength) desc = desc.substring(0, maxLength - 3) + '...';
                    desc += ' (' + item.key + ')';
                    if (key.rev) desc = 'NOT ' + desc;
                    return desc;
                }
            }
        },

        getFlowLabel(input) {
            console.log('input', input);
            if (input.flow.override) {
                return 'Override recipient to ' + input.flow.override.rkey;
            }else if (input.flow.field) {
                return 'Connected to ' + input.flow.field.rkey + ' ' + input.flow.field.type;
            }
        },

        getLinkDisplay(type, key) {

            if (type == 'sign') {

                switch (key) {
                    case 'direct': return 'Will set direct signing (Signing Module)';
                    case 'method': return 'Will set signing method (Signing Module)';
                }

            }else if (type == 'sys') {

                switch (key) {
                    case 'owner-editor': return 'Owner will be treated as an editor (Validation)';
                }

            }

            return 'unknown value';
        },

    },
}
</script>
<style scoped>

.frv2-logx-itm {
    display: flex;
    height: 41px;
    margin: 0px 7px;
    margin-bottom: 8px;
    border-radius: 4px;
    border: solid 1px var(--text-weak);
    background-color: var(--item-hover);
    box-sizing: border-box;
}

.frv2-logx-itm-con {
    flex-grow: 100;
}

.frv2-logx-itm-top {
    display: flex;
    width: 100%;
    height: 21px;
    padding: 1px 4px;
    font-size: 14px;
    color: var(--text-title);
    border-bottom: solid 1px var(--text-weak);
    box-sizing: border-box;
}

.frv2-logx-itm-bot {
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.frv2-logx-itm-del {
    flex-shrink: 0;
    width: 40px;
    padding-top: 5px;
    padding-left: 5px;
    border-left: solid 1px var(--text-weak);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-logx-itm-del:hover {
    background-color: var(--accent);
}

.frv2-logx-itm-lval {
    padding: 1px 4px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-title);
    border-right: solid 1px var(--text-weak);
    box-sizing: border-box;
}

.frv2-logx-itm-btn {
    cursor: pointer;
}
.frv2-logx-itm-btn:hover {
    background-color: var(--text-disabled);
}


.frv2-logic-itm {
    margin-bottom: 5px;
    padding: 4px 10px;
    font-size: 14px;
    color: var(--text-weak);
    border: solid 1px var(--input-border);
}

.frv2-logic-empty {
    padding-top: 5px;
    padding-left: 7px;
    font-size: 14px;
    color: var(--text-weak);
}

</style>
