<template>
<div>
    
    <vap-item v-for="(option, i) in options" :key="'os' + i" fancy padding20
        :fancyIcon="option.icon"
        :fancyText="option.name"
        :fancyHint="option.hint"
        @clicked="onSelected(option)"
    />

</div>
</template>

<script>

export default {
    name: 'OptionSelector',
    props: {
        fromItem: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            options: []
        }
    },
    methods: {

        onSelected(option) {
            this.$emit('select', option.key);
        },
        
        loadOptions() {
            this.options = [];

            for (const i in this.fromItem.options) {
                const option = {
                    icon: 'input-check',
                    name: this.fromItem.options[i].key,
                    hint: this.fromItem.options[i].key,
                    key: this.fromItem.options[i].key
                };

                if (this.fromItem.options[i].locale.en_EN) {
                    option.name = this.fromItem.options[i].locale.en_EN;
                }

                this.options.push(option);
            }
        },

    },
    created() {
        this.loadOptions();
    }
}
</script>
<style scoped>


</style>