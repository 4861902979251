<template>
<div>

    <PropertyItem :header="'Checklist properties'" :icon="'check-c'">
        
    </PropertyItem>

    <PropertyActions>
        <FormMenuButton :icon="'add'" dotted @clicked="addItem">
            <template v-slot:tooltip>
                <vap-tooltip :text="'Add item'" />
            </template>
        </FormMenuButton>
    </PropertyActions>

</div>
</template>
<script>
import checklistModuleHelper from './checklistModuleHelper';

import PropertyItem from '../../designer/properties/PropertyItem.vue';
import PropertyActions from '../../designer/properties/PropertyActions.vue';
import FormMenuButton from '../../designer/FormMenuButton.vue';

export default {
    name: "frmod-checklist-view-prop",
    components: {
        PropertyItem,
        PropertyActions,
        FormMenuButton
    },
    props: {
        card: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        addItem() {
            const count = this.$parent.$parent.$parent.$parent.counter += 1;
            const isList = this.card.type == 'chk-a';
            this.card.inputs.push(checklistModuleHelper.getEmptyInputConfig(count, isList));
        },

    },
    mounted() {
        
    }
}

</script>
