import axios from 'axios';
import log from './log';
import backend from './backend';
const pdfTools = {

    functionUrl: process.env.VUE_APP_API_BASE_URL,
    logResponse: true,

    // --------------------------------------------------------------------------------

    async renderPDF(root, companyConfig, template, userdata, translations, nemid, appendFiles) {
        let config = this.getConfig('getPDF');
        const cc = { config: companyConfig };
        if (!translations && !userdata.translations) translations = {};
        let body;
        if (userdata.translations) {
            body = JSON.stringify({
                path: root.isAWS ? backend.baseUrl + 'web/aml-portal/templates/' : backend.baseUrl + 'templates/',
                nemid: nemid,
                template: template,
                userdata: { ...userdata, ...cc }
            });
        }else {
            body = JSON.stringify({
                path: root.isAWS ? backend.baseUrl + 'web/aml-portal/templates/' : backend.baseUrl + 'templates/',
                nemid: nemid,
                template: template,
                userdata: { ...userdata, ...cc, ...{ translations } }
            });
        }
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl + '/renderer', body, config);
        return response;
    },

    async getPDF(root, companyConfig, template, userdata, translations, nemid, appendFiles) {
        let config = this.getConfig('getPDF');

        const result = await this.renderPDF(root, companyConfig, template, userdata, translations, nemid);
        
        const body = JSON.stringify({
            appendFiles: appendFiles || [],
            base64: result.data
        });

        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl + '/pdfmerge', body, config);
        //this.onResponse(response, config, body);
        return response;
    },

    async mergePDF(mainFile, mergeFiles) {
        let config = this.getConfig('getPDF');

        const body = JSON.stringify({
            appendFiles: mergeFiles || [],
            base64: mainFile
        });

        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl + '/pdfmerge', body, config);
        //this.onResponse(response, config, body);
        return response;
    },

    async getSinglePDF(root, companyConfig, template, userdata, translations, nemid, appendFiles) {
        let config = this.getConfig('getPDF');

        const body = JSON.stringify({
            appendFiles: [],
            base64: base64
        });

        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl + '/pdfmerge', body, config);
        //this.onResponse(response, config, body);
        return response;
    },

    async getHTMLandCSS(companyConfig, template, userdata, translations, nemid) {
        let config = this.getConfig('getHTMLandCSS');
        const cc = { config: companyConfig };
        if (!translations && !userdata.translations) translations = {};
        let body;
        if (userdata.translations) {
            body = JSON.stringify({
                path: root.isAWS ? backend.baseUrl + 'web/aml-portal/templates/' : backend.baseUrl + 'templates/',
                nemid: nemid,
                template: template,
                userdata: { ...userdata, ...cc }
            });
        }else {
            body = JSON.stringify({
                path: root.isAWS ? backend.baseUrl + 'web/aml-portal/templates/' : backend.baseUrl + 'templates/',
                nemid: nemid,
                template: template,
                userdata: { ...userdata, ...cc, ...{ translations } }
            });
        }
/*         console.log(body);
        await new Promise(r => setTimeout(r, 10000)); */
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    async renderTemplate(root, templateId, userdata, extraTranslations) {
        let config = this.getConfig('getHTMLandCSS');
        const translations = extraTranslations ? { ...root.translations, ...extraTranslations } : root.translations;
        const data = {
            path: root.isAWS ? backend.baseUrl + 'web/aml-portal/templates/' : backend.baseUrl + 'templates/',
            templateId: templateId,
            config: root.config,
            userdata: userdata,
            translations: translations
        };
        console.log('render data', data);
        this.onRequest('POST', config, data);
        const response = await axios.post(this.functionUrl+ '/pdfmerge', data, config);
        this.onResponse(response, config, data);
        return response;
    },


    onRequest(method, config, body) {
        if (!this.logResponse) return;
        const data = {
            pid: config.headers.pid,
            body: body
        };
        log.r('pdfTools ' + method + ' ' + config.headers.method, data);

    },

    onResponse(response, config, body) {
        if (!this.logResponse) return;

        log.s('pdfTools ' + response.config.method.toUpperCase() + ' ' + config.headers.method, response);

    },

    // TODO remove this when all clients are on the new naming convention
    getKey(existingKey, cid) {
        let key = existingKey;
        if (!existingKey || existingKey == 'default') {
            if (cid == 'H1xq6yFU8') key = 'kvale';
            if (cid == 'ry1K-ZPPD') key = 'pangea-pp';
            if (cid == 'B1LU_pJfm') key = 'sands';
            if (cid == 'rkeUXcLuv') key = 'deloitte';
        }
        if (key == 'deloitte') key = 'default';
        return key;
    },

    getConfig(method) {
        let config;
        if (backend.credentials) {
            let pid = this.getKey(backend.credentials.key, backend.credentials.cid);
            if (backend.credentials.testinc) pid = this.getKey(backend.credentials.testinc, backend.credentials.cid);
            pid += '-';
            pid += backend.credentials.cid;
            if (backend.credentials.token) {
                config = {
                    headers: {
                        'method': method,
                        'token' : backend.credentials.token || '',
                        'pid': pid,
                        'Pragma' : 'no-cache' // IE-fix - disable caching of network calls
                    }
                }
            }else {
                config = {
                    headers: {
                        'method': method,
                        'btoken' : backend.credentials.bearerToken || '',
                        'pid': pid,
                        'Pragma' : 'no-cache' // IE-fix - disable caching of network calls
                    }
                }
            }
            if (backend.credentials.testinc) config.headers['testinc'] = backend.credentials.testinc;
        }else {
            config = {
                headers: {
                    'method': method,
                    'Pragma' : 'no-cache' // IE-fix - disable caching of network calls
                }
            }
        }
        return config;
    }

};
export default pdfTools;