<template>
    <div class="ov-pending">

        <div class="ov-content-title">

            <label>{{ title }}</label>

        </div>

        <div class="ov-help">

            <div class="ov-help-icon">
                <vap-icon :icon="'help-o'" >
                    <template v-slot:tooltip>
                        <vap-tooltip :text="tooltip" />
                    </template>
                </vap-icon>
            </div>

            <div class="ov-help-text">
                <label class="ov-weak">{{ info }}</label>
            </div>

        </div>
        
    </div>
</template>

<script>

export default {
    name: 'OverviewPending',
    components: {
        
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        info: {
            type: String,
            default: ''
        },
        tooltip: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    async created() {
        
    }
}
</script>
<style scoped>

.ov-weak {
    color: var(--text-weak);
}

.ov-content-title {
    margin-top: 22px;
    font-size: 18px;
    color: var(--text-strong);
}
.ov-help {
    display: flex;
    padding-right: 10px;
    box-sizing: border-box;
}

.ov-help-icon {
    margin-left: -5px;
}
.ov-help-text {
    margin-top: 4px;
    margin-left: 4px;
    font-size: 14px;
}

</style>