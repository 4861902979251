<template>
<div style="padding: 0px 15px;">
    <div class="vap-slider" :id="id" @mousemove="handleMouseMove" @mouseup="handleMouseUp" @mouseleave="handleMouseUp">
        
        <div class="vap-slider-line1" :style="'width:' + sliderX + '%;'" />
        <div class="vap-slider-line2" :style="'width:' + (100 - sliderX) + '%;'"  />

        <div class="vap-slider-ball-c" :style="'margin-left:' + sliderX + '%;'"
            @mousedown="handleMouseDown" >
            <div class="vap-slider-ball" />
        </div>

    </div>
</div>
</template>

<script>

export default {
    name: 'vap-slider',
    props: {
        value: {
            type: Number,
            default: 50
        },
        min: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 100
        }
    },
    data() {
        return {
            id: 'slider',
            sliderX: 75,
            mousedown: false,
            width: 0,
            pageX: 0,
            pageY: 0
        }
    },
    methods: {
        handleMouseDown(e) {
            this.mousedown = true;
            //console.log('down');
        },
        handleMouseUp(e) {
            this.mousedown = false;
            //console.log('up');
        },
        handleMouseMove(e) {
            if (this.mousedown) {
                let x = e.pageX - this.pageX;
                //console.log('x', x);
                let fac = 100 / this.width;
                //console.log('fac', fac);
                this.sliderX = x * fac;
                if (this.sliderX < 0) this.sliderX = 0;
                if (this.sliderX > 100) this.sliderX = 100;

                let vfac = this.max / 100;
                let cval = this.sliderX * vfac;

                //console.log(cval);
                this.$emit('input', cval);
            }
            
        },
        getUniqueId() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
    },
    async created() {
        this.id = this.getUniqueId();

        await new Promise(r => setTimeout(r, 500));
        let elem = document.getElementById(this.id);
        //console.log('elem', elem);

        var rect = elem.getBoundingClientRect();
        //console.log(rect.top, rect.right, rect.bottom, rect.left);
        this.pageX = rect.left;

        this.width = elem.clientWidth;
        //console.log('width', this.width);

        let fac = 100 / this.max;
        this.sliderX = this.value * fac;

    }
}
</script>

<style>

.vap-slider {
    position: relative;
    float: left;
    width: 100%;
    height: 30px;
    margin: 0px 0px;
    background-color: var(--card-background);
    box-sizing: border-box;
}

.vap-slider-line1 {
    position: absolute;
    top: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #238ccc;
    background-color: var(--accent);
}
.vap-slider-line2 {
    position: absolute;
    top: 10px;
    right: 0px;
    height: 10px;
    border-radius: 5px;
    background-color: lightgray;
}

.vap-slider-ball-c {
    position: absolute;
    float: left;
}
.vap-slider-ball {
    position: absolute;
    width: 30px;
    height: 30px;
    left: -15px;
    border-radius: 50%;
    background-color: #238ccc;
    background-color: var(--accent);
    cursor: pointer;
}

</style>
