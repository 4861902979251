import v1reportHelper from "./v1reportHelper";
const clientImportHelper = {

    async getPDFs(root, flow, client, persons) {
        const pdfs = [];

        for (const i in client.envelope.attachments) {
            pdfs.push({
                name: client.envelope.attachments[i].name,
                envelopeId: client.envelope.id,
                isAttachment: true
            });
        }
        
        console.log('PDFs', pdfs);
        return pdfs;
    },


};
export default clientImportHelper;