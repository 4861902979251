var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prl"},[_vm._l((_vm.lists),function(list,i){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(list.persons.length > 0),expression:"list.persons.length > 0"}],key:i,staticClass:"prl-list"},[_c('div',{staticClass:"prl-top"},[_c('div',{staticClass:"prl-top-name"},[_vm._v(" "+_vm._s(list.custom ? list.key : _vm.__(list.key))+" ")]),_c('div',{staticClass:"prl-top-count"},[_vm._v(" "+_vm._s(list.count)+" ")])]),_vm._l((list.persons),function(person,j){return _c('div',{key:'l' + i + j,staticClass:"prl-item",class:[
            {'prl-item-last': j == list.persons.length - 1 },
            {'prl-item-selected': person.selected }
        ],on:{"click":function($event){return _vm.showAddPopup(person, false)},"mouseenter":function($event){return _vm.onListPersonEnter($event, person, 'l' + i + j)},"mouseleave":function($event){return _vm.onListPersonLeave('l' + i + j)}}},[_c('div',{staticClass:"prl-content",class:[
                {'prl-removed': _vm.wasRoleRemoved(person, list) } ]},[_c('div',{staticClass:"prl-icon"},[_c('vap-icon',{attrs:{"icon":person.selected ? 'check' : 'chev-l',"small":"","color":'ext'}})],1),_c('div',{staticClass:"prl-name"},[_vm._v(" "+_vm._s(person.displayName)+" ")]),_c('div',{staticClass:"prl-flags"},[(person.screening.done)?_c('div',{staticClass:"prl-flag",class:[
                        {'prl-flag-success': !person.screening.isPep && !person.screening.hasSanction },
                        {'prl-flag-warning': person.screening.isPep },
                        {'prl-flag-error': person.screening.hasSanction } ]},[_c('vap-icon',{attrs:{"tiny":"","icon":'pep',"color":'ext',"absolute":"","top":-1,"right":-1}})],1):_vm._e(),(person.idc.done)?_c('div',{staticClass:"prl-flag",class:[
                        {'prl-flag-success': true == true },
                        {'prl-flag-warning': true == false },
                        {'prl-flag-error': true == false } ]},[_c('vap-icon',{attrs:{"tiny":"","icon":'card',"color":'ext',"absolute":"","top":-1,"right":-1}})],1):_vm._e(),(person.web.done)?_c('div',{staticClass:"prl-flag",class:[
                        {'prl-flag-success': person.web.hitLevel == 'ok' },
                        {'prl-flag-warning': person.web.hitLevel == 'some' },
                        {'prl-flag-error': person.web.hitLevel == 'hits' } ]},[_c('vap-icon',{attrs:{"tiny":"","icon":'search',"color":'ext',"absolute":"","top":-1,"right":-1}})],1):_vm._e()]),_c('div',{staticClass:"prl-share",class:{'prl-share-short': _vm.isManualOnly(person, list) }},[_vm._v(" "+_vm._s(_vm.getRolePercentage(person, list))+" ")])]),_c('div',{staticClass:"prl-actions"},[(_vm.isManualOnly(person, list))?_c('div',{staticClass:"prl-action",on:{"click":function($event){$event.stopPropagation();return _vm.onRemoveManualRole(person, list)}}},[_c('vap-icon',{attrs:{"icon":'delete',"small":""}})],1):_vm._e(),(!_vm.$root.config.persons.infoDisabled)?_c('div',{staticClass:"prl-action",on:{"click":function($event){$event.stopPropagation();return _vm.onInfoClicked(person)}}},[_c('vap-icon',{attrs:{"icon":'info',"small":""}})],1):_vm._e()])])})],2)}),(_vm.tooltipShow)?_c('div',{staticClass:"ptt-base",style:('bottom: ' + _vm.tooltipBottom + 'px; right: ' + _vm.tooltipRight + 'px;')}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }