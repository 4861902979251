<template>
<div>

    <PropertyItem padded v-if="card" :header="'List properties'" :icon="'resource'">

        <DesignerGroup c12>
            <DesignerCheckbox v-model="card.hideListNum" :text="'Hide list number'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerCheckbox v-model="card.minOneListEntry" :text="'Require at least one list entry'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerCheckbox v-model="card.noEmpty" :text="'Dont add empty card on form start'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Add button text'" />
            <DesignerInput v-model="card.addtext[$root.locale.key]" :placeholder="'Not set'" />
        </DesignerGroup>

    </PropertyItem>

<!--     <PropertyItem v-if="input" :header="'List model properties'" :icon="'resource'">
        <vap-row>
            <vap-group c12>
                <vap-label :text="'Model key'" desc />
                <vap-input v-model="input.listModel" noValidation />
            </vap-group>
        </vap-row>
    </PropertyItem> -->

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';

export default {
    name: 'ListProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
    },
    props: {
        card: { type: Object, default: null },
        input: { type: Object, default: null },
    },
}
</script>