<template>
<div class="vf-lbl">
    
    <label class="vf-lbl-required" v-if="required">*</label>

    <label :class="[
        {'vf-lbl-d' : desc},
        {'vf-lbl-i' : input},
        {'vf-lbl-e' : error},
        {'vf-lbl-disabled' : disabled},
    ]">{{ text }}</label>

    <div v-if="tooltip" class="vf-lbl-tooltip" :data-tooltip="tooltip">
        <div class="vf-lbl-tooltip-icon"></div>
    </div>

</div>
</template>

<script>
export default {
    name: 'vf-label',
    props: {
        text: { type: [String, Number], default: '' },
        required: { type: Boolean, default: false },
        desc: { type: Boolean, default: false },
        input: { type: Boolean, default: false },
        tooltip: { type: String, default: '' },
        error: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

    },
    mounted() {

    }
}
</script>
<style scoped>

.vf-lbl-tooltip {
    position: relative;
}

.vf-lbl-tooltip:hover:before {
  display: block;
}

.vf-lbl-tooltip:hover:after {
  display: block;
}

.vf-lbl-tooltip:before {
    content: attr(data-tooltip);
    position: absolute;
    transform: translateX(-50%);
    bottom: 25px;
    font-size: 12px;
    margin-left: 12px;
    background: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    display: none;
    max-width: 250px;
    width: 200px;
    text-align: center;
}

.vf-lbl-tooltip:after {
    content: "";
    position:absolute;
    margin-left: 12px;
    bottom: 16px;
    transform: translateX(-50%);
    border:5px solid #000;
    border-color: black transparent transparent transparent;
    display: none;
}

.vf-lbl-tooltip-icon {
    background: #1A63B8;
    border-radius: 100%;
    padding: 2px;
    color: #fff;
    font-size: 11px;
    width: 10px;
    height: 10px;
    text-align: center;
    position: relative;
    margin-left: 5px;
}

.vf-lbl-tooltip-icon::after {
    content: "?";
    position: absolute;
    margin: -3px;
    top: 4px;
    color: #fff;
}

.vf-lbl {
    float: left;
    display: flex;
}

.vf-lbl-required {
    margin-right: 5px;
    font-size: 14px;
    color: #BF3744;
}

.vf-lbl-d {
    float: left;
    width: 100%;
    min-height: 10px;
    margin-bottom: 2px;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.6px;
    color: var(--input-label);
}

.vf-lbl-i {
    float: left;
    width: 100%;
    min-height: 20px;
    margin-top: -2px;
    margin-bottom: -2px;
    font-size: 15px;
    letter-spacing: 0.6px;
    color: var(--text-title);
}

.vf-lbl-disabled {
    color: var(--text-disabled);
}


.vf-lbl-e {
    color: var(--val-error);
}

</style>
