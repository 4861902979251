<template>
<div class="frv2-opt-trg-base">
    <div class="frv2-opt-trg">
        <div class="frv2-opt-trg-check" v-if="!input.logic || !input.logic[logicKey]" @click="onTriggerClicked(logicKey)">
            <CheckboxUI :checked="enabled" />
        </div>
        <div class="frv2-opt-trg-lock" v-if="input.logic && input.logic[logicKey]">
            <vap-icon tiny :icon="'locked'" />
        </div>
        <div class="frv2-opt-trg-title" @click="onTriggerClicked(logicKey)">
            {{ display }}
        </div>
        <div class="frv2-opt-trg-logic" :id="'l' + input.key" @click="$emit('logic', {key: input.key, type: logicKey })"
            :class="{'frv2-opt-trg-activelogic': input.logic && input.logic[logicKey] }"
        >
            <vap-icon small :icon="'logic'" :color="'ext'" absolute :top="-2" :right="0" />
        </div>
        <div class="frv2-opt-trg-op" @click="onModeClicked(logicKey)" :class="{'frv2-opt-trg-op-click': input.logic && input.logic[logicKey] }">
            <span v-if="input.logic && input.logic[logicKey]">{{ input.logic[logicKey].or ? 'OR' : 'AND' }}</span>
        </div>
        <div class="frv2-opt-trg-del" @click="$emit('delete')">
            <vap-icon tiny :icon="'delete'" />
        </div>
    </div>
    <LogicLines :reference="reference" :input="input" :logicKey="logicKey" />
</div>
</template>
<script>
import logicHelper from '../../logicHelper';
import editorHelper from '../../editorHelper';

import CheckboxUI from './CheckboxUI.vue';
import LogicLines from './LogicLines.vue';

export default {
    name: 'PropertyLine',
    components: {
        CheckboxUI,
        LogicLines,
    },
    props: {
        logicKey: { type: String, default: '' },
        input: { type: Object, default: () => { return {} }},
        display: { type: String, default: '' },
        enabled: { type: Boolean, default: false },
        reference: { type: Object, default: null },
    },
    methods: {

        onTriggerClicked(type) {
            if (this.input[type] == null) {
                this.$set(this.input, type, false);
            }
            this.input[type] = !this.input[type];
            editorHelper.onChange(this.reference, 'item_' + type + '_changed', false);
        },

        onModeClicked(type) {
            if (!this.input.logic || !this.input.logic[type]) return;
            this.input.logic[type].or = !this.input.logic[type].or;
            editorHelper.onChange(this.reference, 'logic_' + type + '_op', false);
        },

    },
}
</script>
<style scoped>

.frv2-opt-trg-base {
    margin-bottom: 10px;
}

.frv2-opt-trg {
    display: flex;
    height: 24px;
    border-bottom: solid 1px var(--fb-input-bg);
    background-color: var(--fb-input-bg);
}

.frv2-opt-trg-check {
    width: 30px;
}

.frv2-opt-trg-lock {
    margin-top: -2px;
}

.frv2-opt-trg-title {
    flex-grow: 100;
    padding-left: 3px;
    padding-top: 4px;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: white;
    opacity: 0.8;
}

.frv2-opt-trg-logic {
    position: relative;
    width: 28px;
    fill: white;
    background-color: var(--fb-menu-a);
    cursor: pointer;
}
.frv2-opt-trg-activelogic {
    background-color: #13AA52;
}

.frv2-opt-trg-op {
    width: 50px;
    padding-top: 3px;
    font-weight: 500;
    color: #af81ce;
    text-align: center;
}
.frv2-opt-trg-op-click {
    cursor: pointer;
}
.frv2-opt-trg-op-click:hover {
    background-color: var(--fb-menu-a);
}

.frv2-opt-trg-del {
    width: 28px;
    padding-top: 1px;
    padding-left: 2px;
    border-left: 1px solid var(--fb-menu-b);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-opt-trg-del:hover {
    background-color: var(--fb-menu-a);
}

</style>