import v1api from "./v1api";
const v1test = {

    async testFormSubmit(clientId, envelopeData) {
        
        if (!envelopeData) envelopeData = this.getEnvelope();

        await v1api.updateClientFromFormTEST(clientId, envelopeData);

    },

    getEnvelope() {
        return {};
    }

};
export default v1test;