const seOrgBistrap = {

    getFlow() {
        return {
            key: 'se-org-bistrap',
            name: 'Trapets/Bisnode Organization Flow',
            type: 'org',
            countries: 'se',
            tabs: {
                overview: { key: 'common_overview', icon: 'checklist' },
                company: { key: 'company_company', icon: 'company' },
                ownership: { key: 'company_owner_structure', icon: 'orgchart' },
                persons: { key: 'common_persons', icon: 'people' },
                report: { key: 'company_final_report', icon: 'verified' }
            },
            title: {
                en_EN: 'Company registered in Bolagsverket',
                nb_NO: 'Selskap registrert i Bolagsverket',
                sv_SE: 'Företag registrerat i Bolagsverket'
            },
            steps: [
                { key: 'se-bistrap-org' },
                { key: 'se-bistrap-kyc' },
                { key: 'se-bistrap-per' },
                { key: 'se-bistrap-rep' }
            ],
            statuses: [
                {
                    key: 'c-org-c',
                    text: {
                        en_EN: 'Awaiting company search',
                        nb_NO: 'Avventer selskapssøk',
                        sv_SE: 'Väntar på företagssökning'
                    },
                    triggers: []
                },
                {
                    key: 'f-kyc-a',
                    text: {
                        en_EN: 'Awaiting sending self-declaration',
                        nb_NO: 'Avventer utsendelse egenerklæring',
                        sv_SE: 'Väntar på att kundkännedom skickas'
                    },
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: false },
                    ]
                },
                {
                    key: 'f-kyc-b',
                    text: {
                        en_EN: 'Awaiting filling self-declaration',
                        nb_NO: 'Avventer utfylling egenerklæring',
                        sv_SE: 'Väntar på att kundkännedom ska slutföras'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                    ]
                },
                {
                    key: 'f-kyc-c',
                    text: {
                        en_EN: 'Awaiting signing self-declaration',
                        nb_NO: 'Avventer signering egenerklæring',
                        sv_SE: 'Väntar på undertecknande av kundkännedom'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'filled', rev: false },
                    ]
                },
                {
                    key: 'f-kyc-k',
                    text: {
                        en_EN: '! - Self-declaration rejected',
                        nb_NO: '! - Egenerklæring avvist',
                        sv_SE: '! - Kundkännedom avvisad'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'rejected', rev: false },
                    ]
                },
                {
                    key: 'f-kyc-l',
                    text: {
                        en_EN: '! - Self-declaration aborted',
                        nb_NO: '! - Egenerklæring avbrutt',
                        sv_SE: '! - Kundkännedom avbruten'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'aborted', rev: false },
                    ]
                },
                {
                    key: 'r-rep-a',
                    text: {
                        en_EN: 'Awaiting controls',
                        nb_NO: 'Avventer kontroller',
                        sv_SE: 'Väntar på kontroller'
                    },
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'signed', rev: false },
                    ]
                },
                {
                    key: 'x-com-a',
                    completed: true,
                    text: {
                        en_EN: 'Completed',
                        nb_NO: 'Fullført',
                        sv_SE: 'Färdiga'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ]
                },
                {
                    key: 'z-ch-c',
                    completed: false,
                    text: {
                        en_EN: '! - Changed after completion',
                        nb_NO: '! - Endret etter ferdigstillelse',
                        sv_SE: '! - Ändrad efter färdigställande'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                        { type: 'report', trg: 'changed', rev: false },
                    ]
                }
            ],
            events: {
                items: [
                    {
                        key: 'event00',
                        text: {
                            en_EN: 'Customer created by {{createdUser}}',
                            nb_NO: 'Kunde opprettet av {{createdUser}}',
                            sv_SE: 'Kund skapad av {{createdUser}}'
                        },
                        date: { type: 'client', date: 'createdDate' },
                        triggers: [
                            { type: 'client', trg: 'created', rev: false },
                        ]
                    },
                    {
                        key: 'event01',
                        text: {
                            en_EN: 'Company search performed by {{createdUser}}',
                            nb_NO: 'Selskapssøk utført av {{createdUser}}',
                            sv_SE: 'Företagssökning utförd av {{createdUser}}'
                        },
                        date: { type: 'org', date: 'searchDate' },
                        triggers: [
                            { type: 'org', trg: 'has-data', rev: false },
                        ]
                    },
                    {
                        key: 'event02',
                        text: {
                            en_EN: 'Self declaration form sent by {{kyc00-senderName}}',
                            nb_NO: 'Egenerklæringsskjema sendt ut av {{kyc00-senderName}}',
                            sv_SE: 'Kundkännedom skickas ut av {{kyc00-senderName}}'
                        },
                        date: { type: 'form', key: 'kyc00', date: 'sentDate' },
                        triggers: [
                            { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                        ]
                    },
                    {
                        key: 'event03',
                        text: {
                            en_EN: 'Self declaration filled by {{kyc00-editorName}}',
                            nb_NO: 'Egenerklæringsskjema fylt ut av {{kyc00-editorName}}',
                            sv_SE: 'Kundkännedom ifylld av {{kyc00-editorName}}'
                        },
                        date: { type: 'form', key: 'kyc00', date: 'filledDate' },
                        triggers: [
                            { type: 'form', key: 'kyc00', trg: 'filled', rev: false },
                        ]
                    },
                    {
                        key: 'event04',
                        text: {
                            en_EN: 'Self declaration form signed by all signers',
                            nb_NO: 'Egenerklæringsskjema signert av alle signatarer',
                            sv_SE: 'Kundkännedom undertecknat av alla undertecknare'
                        },
                        date: { type: 'form', key: 'kyc00', date: 'signedDate' },
                        triggers: [
                            { type: 'form', key: 'kyc00', trg: 'signed', rev: false },
                        ]
                    },
                    {
                        key: 'event05',
                        list: 'ams',
                        text: {
                            en_EN: 'Adverse media search completed for {{event-name}}',
                            nb_NO: 'KYC-søk utført for {{event-name}}',
                            sv_SE: 'KYC-sökning gjord för {{event-name}}'
                        },
                        date: { type: 'ams', date: 'date' },
                        triggers: []
                    },
                    {
                        key: 'event06',
                        list: 'idc',
                        text: {
                            en_EN: 'ID-control completed by {{idc-name}}',
                            nb_NO: 'ID-kontroll fullført av {{idc-name}}',
                            sv_SE: 'ID-kontroll genomförd av {{idc-name}}',
                        },
                        date: { type: 'idc', date: 'date' },
                        triggers: [
                            { type: 'idc', trg: 'done', rev: false },
                        ]
                    },
                    {
                        key: 'event07',
                        list: 'idc-sent',
                        text: {
                            en_EN: 'ID-control sent to {{event-name}}',
                            nb_NO: 'ID-kontroll sendt til {{event-name}}',
                            sv_SE: 'ID-kontroll skickas till {{event-name}}',
                        },
                        date: { type: 'reminder', date: 'date' },
                        triggers: []
                    },
                    {
                        key: 'event08',
                        list: 'idc-rem',
                        text: {
                            en_EN: 'Reminder of ID-control sent to {{event-name}}',
                            nb_NO: 'Påminnelse om ID-kontroll sendt til {{event-name}}',
                            sv_SE: 'Påminnelse om ID-kontroll skickas till {{event-name}}',
                        },
                        date: { type: 'reminder', date: 'date' },
                        triggers: []
                    },
                    {
                        key: 'event09',
                        list: 'scr',
                        text: {
                            en_EN: 'Screening performed on {{scr-name}}',
                            nb_NO: 'PEP/Sanksjonsøk utført på {{scr-name}}',
                            sv_SE: 'Screening utförs på {{scr-name}}',
                        },
                        date: { type: 'scr', date: 'date' },
                        triggers: [
                            { type: 'scr', trg: 'done', rev: false },
                        ]
                    },
                    {
                        key: 'event10',
                        text: {
                            en_EN: 'Final report created by {{reportUser}}',
                            nb_NO: 'Sluttrapport fullført av {{reportUser}}',
                            sv_SE: 'Slutrapport skapad av {{reportUser}}',
                        },
                        date: { type: 'report', date: 'createdDate' },
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ]
                    },
                    {
                        key: 'event11',
                        list: 'form-rem-edit',
                        listForm: 'kyc00',
                        text: {
                            en_EN: 'Reminder of self-declaration for filling sent to {{event-name}}',
                            nb_NO: 'Påminnelse om egenerklæring for utfylling sent til {{event-name}}',
                            sv_SE: 'Påminnelse om kundkännedom för fyllning skickas till {{event-name}}',
                        },
                        date: { type: 'reminder', date: 'date' },
                        triggers: []
                    },
                    {
                        key: 'event12',
                        list: 'form-rem-sign',
                        listForm: 'kyc00',
                        text: {
                            en_EN: 'Reminder of self-declaration for signing sent to {{event-name}}',
                            nb_NO: 'Påminnelse om egenerklæring for signering sent til {{event-name}}',
                            sv_SE: 'Påminnelse om kundkännedom för signering skickas till {{event-name}}',
                        },
                        date: { type: 'reminder', date: 'date' },
                        triggers: []
                    },
                    {
                        key: 'event13',
                        text: {
                            en_EN: 'Adverse media search completed for the company',
                            nb_NO: 'KYC-søk fullført for foretaket',
                            sv_SE: 'KYC-sökning slutförd för företaget',
                        },
                        date: { type: 'org', date: 'amsDate' },
                        triggers: [
                            { type: 'org', trg: 'ams-completed', rev: false },
                        ]
                    },
                    {
                        key: 'event-internal',
                        text: {
                            en_EN: 'Internal documentation completed',
                            nb_NO: 'Egenerklæringsskjema signert av alle signatarer',
                            sv_SE: 'Intern dokumentation färdig'
                        },
                        date: { type: 'form', key: 'internal00', date: 'signedDate' },
                        triggers: [
                            { type: 'form', key: 'internal00', trg: 'signed', rev: false },
                        ]
                    }
                ]
            },
            report: [
                {
                    key: 'org',
                    icon: 'company',
                    comment: true,
                    separation: false,
                    title: {
                        en_EN: 'Company search',
                        nb_NO: 'Selskapssøk',
                        sv_SE: 'Företagssökning'
                    },
                    requirements: [
                        {
                            key: 'org-search',
                            message: {
                                en_EN: 'Company search not performed',
                                nb_NO: 'Selskapssøk ikke utført',
                                sv_SE: 'Företagssökning har inte utförts'
                            },
                            triggers: [
                                { type: 'org', trg: 'has-data', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Company not assessed',
                                nb_NO: 'Selskapsdata ikke vurdert',
                                sv_SE: 'Företaget bedöms inte'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                },
                {
                    key: 'kyc',
                    icon: 'form',
                    comment: true,
                    separation: true,
                    includeFile: {
                        enabled: true,
                        formKey: 'kyc00',
                        name: 'Kundkännedom'
                    },
                    title: {
                        en_EN: 'Self-declaration',
                        nb_NO: 'Egenerklæring',
                        sv_SE: 'Kundkännedom'
                    },
                    requirements: [
                        {
                            key: 'org-search',
                            message: {
                                en_EN: 'Self-declaration not completed and signed',
                                nb_NO: 'Egenerklæringsskjema ikke fullført',
                                sv_SE: 'Kundkännedom inte ifylld'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Self-declaration not assessed',
                                nb_NO: 'Egenerklæring ikke vurdert',
                                sv_SE: 'Kundkännedom beaktas inte'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ],
                    actions: [
                        {
                            icon: 'pdf',
                            main: false,
                            action: 'preview',
                            key: 'kyc00',
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'filled', rev: false },
                            ],
                            text: {
                                en_EN: 'Show receipt'
                            },
                            hint: {
                                en_EN: 'Open the PDF version of the form'
                            }
                        },
                    ],
                    items: [
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Not ready',
                                nb_NO: 'Ikke klar',
                                sv_SE: 'Inte redo'
                            },
                            value: {
                                en_EN: 'Self-declaration not filled in and signed',
                                nb_NO: 'Egenerklæring ikke fylt ut og signert',
                                sv_SE: 'Kundkännedom har inte slutförts och undertecknats'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'signed', rev: true },
                            ],
                        },
                        {
                            type: 'custom',
                            width: '12',
                            text: {
                                en_EN: 'Signed by',
                                nb_NO: 'Signert av',
                                sv_SE: 'Undertecknad av'
                            },
                            value: {
                                en_EN: '{{kyc00-signerNames}}',
                                nb_NO: '{{kyc00-signerNames}}',
                                sv_SE: '{{kyc00-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'signed', rev: false },
                            ],
                        },
                        {
                            type: 'comparison'
                        }
                    ]
                },
                {
                    key: 'roles',
                    icon: 'person',
                    comment: true,
                    separation: true,
                    title: {
                        en_EN: 'Key roles',
                        nb_NO: 'Nøkkelroller',
                        sv_SE: 'Nyckelroller'
                    },
                    requirements: [
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Key roles not assessed',
                                nb_NO: 'Nøkkelroller ikke vurdert',
                                sv_SE: 'Nyckelroller beaktas inte'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                },
                {
                    key: 'assessment',
                    icon: 'check',
                    comment: true,
                    separation: true,
                    title: {
                        en_EN: 'Final assessment / risk assessment',
                        nb_NO: 'Totalvurdering',
                        sv_SE: 'Helhetsbedömning'
                    },
                    requirements: [
                        {
                            key: 'report-risk',
                            message: {
                                en_EN: 'Risk classification not set',
                                nb_NO: 'Risikoklassifisering ikke satt',
                                sv_SE: 'Riskklassificering inte fastställd'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'risk-set', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Final assessment not completed',
                                nb_NO: 'Mangler totalvurdering',
                                sv_SE: 'Saknar helhetsbedömning'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                }
            ]
        };
    },

    //#region Steps -------------------------------------------------------------------------------------------------------
    
    getSearchStep() {
        return {
            key: 'se-bistrap-org',
            isDefault: true,
            resetMode: 'company',
            name: 'Trapets/Bisnode Company Search',
            actions: [
                {
                    icon: 'search',
                    main: true,
                    action: 'lookup',
                    key: 'org',
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: true },
                    ],
                    text: {
                        en_EN: 'Get data',
                        nb_NO: 'Hent data',
                        sv_SE: 'Hämta data',
                    },
                    hint: {
                        en_EN: 'Retrieve data from public registers and screen for sanctions against the company',
                        nb_NO: 'Hent data fra offentlige registre og gjør et sanksjonsøk på selskapet',
                        sv_SE: 'Hämta uppgifter från offentliga register och screena för sanktioner mot företaget',
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'preview',
                    key: 'org',
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: false },
                    ],
                    text: {
                        en_EN: ''
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'refresh',
                    main: false,
                    action: 'org-refresh',
                    key: 'org',
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: false },
                    ],
                    text: {
                        en_EN: 'Reset',
                    },
                    hint: {
                        en_EN: 'Reset the company lookup so a new one can be made',
                        nb_NO: 'Tilbakestill selskapssøket slik at det kan gjøres et nytt',
                        sv_SE: 'Återställ företagssökningen så att en ny kan göras',
                    },
                    disabled: {
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ],
                        hint: {
                            en_EN: 'Customer is locked after the final report is created',
                            nb_NO: 'Kunden er låst etter sluttrapporten er laget',
                            sv_SE: 'Kunden är låst efter att slutrapporten skapats',
                        },
                    }
                }
            ],
            title: {
                en_EN: 'Company search',
                nb_NO: 'Organisasjonssøk',
                sv_SE: 'Företagssökning'
            },
            hint: {
                en_EN: 'Retrieve data from public registers and screen for sanctions against the company',
                nb_NO: 'Hent data fra offentlige registre og gjør et sanksjonsøk på selskapet',
                sv_SE: 'Hämta uppgifter från offentliga register och screena för sanktioner mot företaget',
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{sanctionMessage}}',
                                nb_NO: '{{sanctionMessage}}',
                                sv_SE: '{{sanctionMessage}}',
                            },
                            triggers: [
                                { type: 'org', trg: 'has-data', rev: false },
                            ]
                        }
                    ]
                },
                warning: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Some data was not fetched',
                                nb_NO: 'Some data was not fetched',
                                sv_SE: 'Some data was not fetched',
                            },
                            triggers: [
                                { type: 'org', trg: 'missing-data', rev: false },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{sanctionMessage}}',
                                nb_NO: '{{sanctionMessage}}',
                                sv_SE: '{{sanctionMessage}}',
                            },
                            triggers: [
                                { type: 'org', trg: 'has-sanction', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },


    getKycStep() {
        return {
            key: 'se-bistrap-kyc',
            isDefault: true,
            resetMode: 'form',
            name: 'Default BankID AML Self-Declaration Form',
            /* formList: true, */
            form: 'kyc00',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form',
                        nb_NO: 'Åpne skjema',
                        sv_SE: 'Öppna formuläret'
                    },
                    hint: {
                        en_EN: 'Open the form'
                    }
                },
                {
                    icon: 'refresh',
                    main: false,
                    action: 'form-start',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: true },
                        { type: 'form', key: 'kyc00', trg: 'saved', rev: false },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: 'Start over again',
                        nb_NO: 'Start utfylling på nytt',
                        sv_SE: 'Börja om igen',
                    }
                },
                {
                    icon: 'open',
                    main: true,
                    action: 'form-amend',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: true },
                        { type: 'form', key: 'kyc00', trg: 'saved', rev: false },
                    ],
                    text: {
                        en_EN: 'Continue process',
                        nb_NO: 'Fortsett utfylling',
                        sv_SE: 'Fortsätt processen',
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'preview',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'filled', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                        { type: 'form', key: 'kyc00', trg: 'signed', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'This action will send a reminder to the recipient'
                    }
                },
                {
                    icon: 'refresh',
                    main: false,
                    action: 'form-resend',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                    ],
                    disabled: {
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ],
                        hint: {
                            en_EN: 'Customer is locked after the final report is created',
                            nb_NO: 'Kunden er låst etter sluttrapporten er laget',
                            sv_SE: 'Kunden är låst efter att slutrapporten skapats',
                        },
                    },
                    text: {
                        en_EN: 'Resend',
                    },
                    hint: {
                        en_EN: 'Resend the form with filled data',
                        nb_NO: 'Send skjema på nytt med utfylt informasjon',
                        sv_SE: 'Skicka formuläret igen med ifyllda uppgifter',
                    }
                },
                {
                    icon: 'delete',
                    main: false,
                    action: 'form-reset',
                    key: 'kyc00',
                    triggers: [
                        { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                    ],
                    disabled: {
                        triggers: [
                            { type: 'report', trg: 'created', rev: false },
                        ],
                        hint: {
                            en_EN: 'Customer is locked after the final report is created',
                            nb_NO: 'Kunden er låst etter sluttrapporten er laget',
                            sv_SE: 'Kunden är låst efter att slutrapporten skapats',
                        },
                    },
                    text: {
                        en_EN: 'Reset form',
                    },
                    hint: {
                        en_EN: 'The form will reset so that a new one can be sent',
                        nb_NO: 'Skjema tilbakestilles slik at ett nytt kan sendes',
                        sv_SE: 'Formuläret återställs så att ett nytt kan skickas',
                    }
                }
            ],
            title: {
                en_EN: 'Self-Declaration form',
                nb_NO: 'Egenerklæringsskjema',
                sv_SE: 'Kundkännedom'
            },
            hint: {
                en_EN: 'Completed by the customer contact person and makes sure to get updated information about the company and key roles.',
                nb_NO: 'Fylles ut av kundens kontaktperson og sørger for å hente inn oppdatert data om foretaket og nøkkelroller.',
                sv_SE: 'Fylls i av kundens kontaktperson och ser till att samla in uppdaterad information om företaget och nyckelroller'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Awaiting filling by {{kyc00-nextName}}',
                                nb_NO: 'Avventer utfylling fra {{kyc00-nextName}}',
                                sv_SE: 'Väntar på färdigställande från {{kyc00-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'sent', rev: false },
                                { type: 'form', key: 'kyc00', trg: 'filled', rev: true },
                                { type: 'form', key: 'kyc00', trg: 'aborted', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting signing by {{kyc00-nextName}}',
                                nb_NO: 'Avventer signering fra {{kyc00-nextName}}',
                                sv_SE: 'Väntar på signering från {{kyc00-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'filled', rev: false },
                                { type: 'form', key: 'kyc00', trg: 'signed', rev: true },
                                { type: 'form', key: 'kyc00', trg: 'rejected', rev: true },
                                { type: 'form', key: 'kyc00', trg: 'aborted', rev: true },
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Signed by {{kyc00-signerNames}}',
                                nb_NO: 'Signert av {{kyc00-signerNames}}',
                                sv_SE: 'Undertecknad av {{kyc00-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                },
                warning: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Signed by {{kyc00-signerNames}}',
                                nb_NO: 'Avbrutt',
                                sv_SE: 'Undertecknad av {{kyc00-signerNames}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'aborted', rev: false },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Rejected by {{kyc00-rejecterName}} - {{kyc00-rejecterComment}}',
                                nb_NO: 'Avslått av {{kyc00-rejecterName}} - {{kyc00-rejecterComment}}',
                                sv_SE: 'Avvisade av {{kyc00-rejecterName}} - {{kyc00-rejecterComment}}'
                            },
                            triggers: [
                                { type: 'form', key: 'kyc00', trg: 'rejected', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getPersonStep() {
        return {
            key: 'se-bistrap-per',
            isDefault: true,
            resetMode: 'roles',
            name: 'Default BankID AML Person Screening',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        /* { type: 'person', trg: 'something', rev: false }, */
                        { type: 'report', trg: 'created', rev: true },
                    ],
                    text: {
                        en_EN: 'Show persons',
                        nb_NO: 'Vis personer',
                        sv_SE: 'Visa personer'
                    },
                    hint: {
                        en_EN: '',
                        nb_NO: '',
                        sv_SE: ''
                    }
                },
                {
                    icon: 'chev-r',
                    main: false,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        { type: 'person', trg: 'something', rev: false },
                    ],
                    text: {
                        en_EN: '',
                    },
                    hint: {
                        en_EN: 'Show persons',
                        nb_NO: 'Vis personer',
                        sv_SE: 'Visa personer'
                    }
                }
            ],
            title: {
                en_EN: 'Screening and identification of roles',
                nb_NO: 'Kontroller nøkkelroller',
                sv_SE: 'Kontrollera nyckelroller'
            },
            hint: {
                en_EN: 'Define key roles in the company and perform PEP/Sanction-searches and ID-controls.',
                nb_NO: 'Angi nøkkelroller i foretaket og utfør PEP/Sanksjonssøk og ID-kontroller.',
                sv_SE: 'Definiera nyckelroller i företaget och utför PEP / Sanction-sökningar och ID-kontroller.',
            },
            status: {
                multiple: true,
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{screenedCount}} of {{selectedCount}} screened',
                                nb_NO: '{{screenedCount}} av {{selectedCount}} personsøk utført',
                                sv_SE: '{{screenedCount}} av {{selectedCount}} personsök utförda',
                            },
                            triggers: [
                                { type: 'person', trg: 'something', rev: false },
                                { type: 'person', trg: 'scr-all', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: '{{identifiedCount}} of {{selectedCount}} identified',
                                nb_NO: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utført',
                                sv_SE: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utförda',
                            },
                            triggers: [
                                { type: 'person', trg: 'something', rev: false },
                                { type: 'person', trg: 'idc-all', rev: true },
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{screenedCount}} of {{selectedCount}} screened',
                                nb_NO: '{{screenedCount}} av {{selectedCount}} personsøk utført',
                                sv_SE: '{{screenedCount}} av {{selectedCount}} personsök utförda'
                            },
                            triggers: [
                                { type: 'person', trg: 'scr-all', rev: false },
                            ]
                        },
                        {
                            text: {
                                en_EN: '{{identifiedCount}} of {{selectedCount}} identified',
                                nb_NO: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utført',
                                sv_SE: '{{identifiedCount}} av {{selectedCount}} ID-kontroller utförda'
                            },
                            triggers: [
                                { type: 'person', trg: 'idc-all', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getReportStep() {
        return {
            key: 'se-bistrap-rep',
            isDefault: true,
            resetMode: 'report',
            name: 'Default BankID AML Report',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'tab',
                    key: 'report',
                    triggers: [
                        { type: 'report', trg: 'created', rev: true },
                    ],
                    text: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport',
                        sv_SE: 'Visa rapport'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'pdf',
                    main: false,
                    action: 'preview',
                    key: 'report',
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Show the final report'
                    }
                },
/*                 {
                    icon: 'delete',
                    main: false,
                    action: 'report-reset',
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ],
                    text: {
                        en_EN: '',
                        nb_NO: ''
                    },
                    hint: {
                        en_EN: '',
                        nb_NO: ''
                    }
                } */
            ],
            title: {
                en_EN: 'Final report / Risk assessment',
                nb_NO: 'Sluttrapport',
                sv_SE: 'Slutrapport'
            },
            hint: {
                en_EN: 'Look through the collected information and set a risk assessment, then generate a final report for this customer.',
                nb_NO: 'Gå gjennom innsamlet informasjon og sett en risikovurdering, så genereres det en sluttrapport for kunden.',
                sv_SE: 'Gå igenom den insamlade informationen och sätt en riskbedömning och skapa sedan en slutrapport för denna kund.'
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Final report created',
                                nb_NO: 'Sluttrapport fullført',
                                sv_SE: 'Slutrapport skapad'
                            },
                            triggers: [
                                { type: 'report', trg: 'created', rev: false },
                                { type: 'report', trg: 'changed', rev: true },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Underlying data has been updated since the report was created',
                                nb_NO: 'Underliggende data har blitt oppdatert siden rapporten ble opprettet',
                                sv_SE: 'Underliggande data har uppdaterats sedan rapporten skapades'
                            },
                            triggers: [
                                { type: 'report', trg: 'created', rev: false },
                                { type: 'report', trg: 'changed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default seOrgBistrap;