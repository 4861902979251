<template>
<div>

    <PopupV1 designer :header="'Logic connection'" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <ConnectionList :items="options" :params="params" @item="onConnectItem" @itemkey="onConnectItemKey" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>
    
</div>
</template>
<script>
import logicHelper from '../logicHelper';

import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFButton from '../items/VFButton.vue';

import ConnectionList from './ConnectionList.vue';
import formHelper from '../formHelper';

export default {
    name: 'LogicConnector',
    components: {
        PopupV1,
        VFButton,
        ConnectionList,
    },
    props: {
        params: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: {},
            options: [],
            innerType: '',
            innerParentKey: ''
        }
    },
    methods: {
        
        onConnectItem(result) {
            console.log('result', result);

            if (result.conn == 'opttrigger') {

                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'disabled');
                    this.params.fromItem.logic.disabled.keys.push({
                        type: 'logic', key: this.params.key, pkey: this.innerParentKey, rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'visible');
                    this.params.fromItem.logic.visible.keys.push({
                        type: this.innerType, key: this.params.key, pkey: this.innerParentKey, rev: result.reversed
                    });

                }else if (result.trigger == 'required') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'required');
                    this.params.fromItem.logic.required.keys.push({
                        type: this.innerType, key: this.params.key, pkey: this.innerParentKey, rev: result.reversed
                    });

                }else if (result.trigger == 'ownerReq') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'ownerReq');
                    this.params.fromItem.logic.ownerReq.keys.push({
                        type: this.innerType, key: this.params.key, pkey: this.innerParentKey, rev: result.reversed
                    });

                }

            }else if (result.conn == 'opttrigger2') {

                console.log('innerParentKey', this.innerParentKey);
                console.log('key', this.params.key);

                let innerParentKey = this.innerParentKey.key;
                let key = this.params.key;

                console.log('this.params.toItem', this.params.toItem);

                /* const parentItem = formHelper.getContainer(this.reference.pages, this.params.toItem); */

                if (this.innerParentKey.isList) {
                    console.log('isList');
                    //innerParentKey = this.innerParentKey.listModel;
                    for (const i in this.innerParentKey.items) {
                        if (this.innerParentKey.items[i].key == this.params.key) {
                            key = this.innerParentKey.items[i].modelKey;
                        }
                    }
                    console.log('innerParentKey', innerParentKey);
                    console.log('key', key);
                }

                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'disabled');
                    this.params.fromItem.logic.disabled.keys.push({
                        type: this.innerType, key: key, pkey: innerParentKey, rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'visible');
                    this.params.fromItem.logic.visible.keys.push({
                        type: this.innerType, key: key, pkey: innerParentKey, rev: result.reversed
                    });

                }else if (result.trigger == 'required') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'required');
                    this.params.fromItem.logic.required.keys.push({
                        type: this.innerType, key: key, pkey: innerParentKey, rev: result.reversed
                    });

                }else if (result.trigger == 'ownerReq') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'ownerReq');
                    this.params.fromItem.logic.ownerReq.keys.push({
                        type: this.innerType, key: key, pkey: innerParentKey, rev: result.reversed
                    });

                }

            }else if (result.conn == 'country') {

                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'disabled');
                    this.params.fromItem.logic.disabled.keys.push({
                        type: 'loc', key: result.country, rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'visible');
                    this.params.fromItem.logic.visible.keys.push({
                        type: 'loc', key: result.country, pkey: this.params.key, rev: result.reversed
                    });

                }

            }else if (result.conn == 'loc-nordic') {

                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'disabled');
                    this.params.fromItem.logic.disabled.keys.push({
                        type: 'loc', key: 'nordic', rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'visible');
                    this.params.fromItem.logic.visible.keys.push({
                        type: 'loc', key: 'nordic', pkey: this.params.key, rev: result.reversed
                    });

                }

            }else if (result.conn == 'loc-euro') {

                if (result.trigger == 'enabled') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'disabled');
                    this.params.fromItem.logic.disabled.keys.push({
                        type: 'loc', key: 'euro', rev: result.reversed
                    });

                }else if (result.trigger == 'visible') {

                    logicHelper.prepareItem(this.reference, this.params.fromItem, 'visible');
                    this.params.fromItem.logic.visible.keys.push({
                        type: 'loc', key: 'euro', pkey: this.params.key, rev: result.reversed
                    });

                }

            }else if (result.conn == 'dmatch') {

                logicHelper.prepareItem(this.reference, this.params.fromItem, 'dmatch');
                this.params.fromItem.logic.dmatch.keys.push(this.params.toItem.key);

            }else if (result.conn == 'dmatch-brreg') {

                logicHelper.prepareItem(this.reference, this.params.fromItem, 'dmatch');
                this.params.fromItem.logic.dmatch.keys.push(this.params.toItem.key);

            }
            this.$emit('reselect');
            this.$emit('close');
        },

        onConnectItemKey(result) {
            this.$emit('close');
        },

        getContainerCheckbox(pages, key) {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    for (const k in pages[i].cards[j].inputs) {
                        for (const l in pages[i].cards[j].inputs[k].options) {
                            if (key == pages[i].cards[j].inputs[k].options[l].key) return pages[i].cards[j].inputs[k];
                        }
                    }
                }
            }
            return null;
        },

        getContainerCheckbox2(pages, key) {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    for (const k in pages[i].cards[j].inputs) {
                        for (const l in pages[i].cards[j].inputs[k].items) {
                            if (key == pages[i].cards[j].inputs[k].items[l].key) return pages[i].cards[j].inputs[k];
                        }
                    }
                }
            }
            return null;
        },
        
    },
    created() {
        const fromItem = this.params.fromItem;
        const toItem = this.params.toItem;

        if (toItem.type == 'opt') {

            this.innerType = 'check';
            this.innerParentKey = this.getContainerCheckbox(this.$parent.pages, this.params.key).key;

            // Option trigger
            this.options.push({ type: 'trigger', conn: 'opttrigger', icon: 'check', text: 'Checked',
                hint: 'Trigger input properties when this option is checked'
            });

        }else if (toItem.type == 'coo') {

            this.innerType = 'check';
            this.innerParentKey = this.getContainerCheckbox2(this.$parent.pages, this.params.key);

            // Option trigger
            this.options.push({ type: 'trigger', conn: 'opttrigger2', icon: 'check', text: 'Checked',
                hint: 'Trigger input properties when this option is checked'
            });

            this.params.preSelectedItem = 'opttrigger2';

        }else if (toItem.type == 'text' && fromItem.type == 'text') {

            // Dictionary value
            this.options.push({ type: 'dictionary', conn: 'dmatch', icon: 'logic', text: 'Dictionary value',
                hint: 'Value will be set to the dictionary match'
            });

        }else if (toItem.type == 'text' && fromItem.type == 'brreg') {

            // Dictionary value
            this.options.push({ type: 'dictionary', conn: 'dmatch-brreg', icon: 'logic', text: 'Dictionary value',
                hint: 'Value will be set to the dictionary match'
            });
            
        }else if (toItem.type == 'country') {

            this.options.push({ type: 'trigger', conn: 'country', icon: 'check', text: 'Country',
                hint: 'Trigger input properties when a country is selected', selectCountry: true
            });
            this.options.push({ type: 'trigger', conn: 'loc-nordic', icon: 'check', text: 'Outside the nordics',
                hint: 'Trigger input properties when a country is selected'
            });
            this.options.push({ type: 'trigger', conn: 'loc-euro', icon: 'check', text: 'Outside EU/EFTA',
                hint: 'Trigger input properties when a country is selected'
            });
            
        }
            
        
    }
}
</script>