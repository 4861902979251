import axios from 'axios';
import { auth, envelopes, oidc } from '@verified/libvf';
import api from './portalapi';
import locale from './locale';
import verified from './verified';
import v2lib from './v2lib';
import amlTools from './aml';
import tools from './tools';
import log from './log';
import reporthelper from './helpers/reporthelper';
import dataModel from './dataModel';
import dataHelper from './dataHelper';
import dataNorway from './dataNorway';
import dataPerson from './dataPerson';

const receipts = {

    async doAmlSearch(root, mainApp, existingData, inBackground) {
        // Prepare the result object
        const result = { success: false, exceptions: [] };
        log.v('AML: Performing company search on ' + existingData.customer.name + ' (' + existingData.customer.identity + ')');
        try {
            // Set the loading message
            if (!inBackground) mainApp.setLoading(true, root.__('loading_aml_fetch') + '...');

            // First create an envelope to make sure Verified Platform is up and running
            const envelope = await v2lib.createEnvelope('aml-portal-company', 'no-recipients-flow');
            //const envelope = await v2lib.createEnvelope('aml-portal-v1-org', 'aml-portal-no-recipients-flow');
            if (v2lib.wasCreated(envelope)) {
                // Envelope successfully created, proceed with AML
                log.s('AML: Envelope successfully created', envelope);
                const amlData = await amlTools.doAmlCompanySearch(root, existingData.customer.identity);
                log.s('AML: Company data fetched from BankID', amlData);

                // Download BankID reports if they exist
                const attachments = [];
                if (!amlData.keyInformation.name.includes('NARVIK HVITVASKING')) {
                    // This is not the test-company, proceed with report download

                    // Loop reports and download them
                    for (const i in amlData.links.reports) {

                        const url = amlData.links.reports[i].href;
                        // The first report will be the full report, while the second will be the simple report
                        const name = i == 1 ? 'BankID AML Firmaattest.pdf' : 'BankID AML Firmarapport.pdf';
                        const type = i == 1 ? 'inc' : 'aml';

                        try {
                            const base64 = await verified.downloadBankIDReport(url);
                            await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, name);
                            attachments.push({
                                type: type,
                                name: name,
                                fileType: 'application/pdf',
                                size: 0,
                                sizeText: 'unknown',
                                number: 0
                            });

                        }catch (ex) {
                            log.e('BankID: Exception while downloading report');
                            console.log(ex);
                        }
                    }
                }

                // Update the loading message
                if (!inBackground) mainApp.setLoading(true, root.__('loading_creating_receipt') + '...');

                // Prepare metadata about the user who performed the search
                const metadata = {
                    id: root.credentials.uid,
                    userName: root.getEmployeeName(root.credentials.uid),
                    date: new Date()
                };

                // Prepare userdata for the PDF generation and get HTML and CSS from BE
                const userdata = { aml: amlData, brreg: existingData.brregData, searchDate: new Date(), metadata: metadata, attachments: attachments };
                //console.log(JSON.stringify(userdata));
                const template = await api.renderTemplate(root, 'aml', userdata);
                log.v('AML: HTML for PDF generated', template);

                // Now we update the customer in the BE, update the loading message
                if (!inBackground) mainApp.setLoading(true, root.__('loading_saving_customer') + '...');

                // Prepare envelope userdata, name and tags
                const envelopeName = `Firmarapport ${amlData.keyInformation.name}`;
                const envelopeTags = ['firmarapport'];
                const envelopeUserdata = {
                    metadata: metadata,
                    templateId: 'aml',
                    templatePath: process.env.VUE_APP_BASE_URL + 'templates/',
                    brreg: existingData.brregData,
                    aml: amlData,
                    searchDate: new Date(),
                    attachments: attachments,
                    css: template.css,
                    html: template.html,
                    fileName: envelopeName
                };
                //console.log(JSON.stringify(envelopeUserdata));

                // Complete the envelope
                await v2lib.setUserData(envelope, envelopeUserdata);
                await v2lib.setTags(envelope, envelopeTags);
                await v2lib.setName(envelope, envelopeName);
                await v2lib.publishEnvelope(envelope);
                log.s('AML: Envelope published');

                // Prepare BE data
                const aml = {
                    userId: root.credentials.uid,
                    envelopeId: envelope.id,
                    searchDate: new Date()
                };

                const companyData = dataModel.getEmptyModel();
                dataNorway.processData(root, companyData, amlData, existingData.brregData);

                // Create list of persons to auto-add
                const personIds = this.getPersonIds(root, companyData);

                // Update customer in BE
                const response = await api.performAML(existingData.customer._id, aml, personIds, existingData.nextStatus);
                result.customer = response.customer;
                result.amlData = amlData;
                result.success = true;

                log.s('AML: Customer updated in BE, process complete', response);
            }else {
                // Error creating envelope, show error to the user
                result.exceptions.push(v2lib.getError(envelope));
                if (!inBackground) root.exceptions.push(v2lib.getError(envelope));
            }
        }catch (ex) {
            console.log(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'AML Company search'
                };
            }catch (exx) {}
            result.exceptions.push(ex);
            if (!inBackground) root.exceptions.push(ex);
        }
        // Hide loading message and return the result
        if (!inBackground) mainApp.setLoading(false);
        return result;
    },

    async doAmlSearch2(root, mainApp, existingData, inBackground) {
        // Prepare the result object
        const result = { success: false, exceptions: [] };
        log.v('AMLv2: Performing company search on ' + existingData.customer.name + ' (' + existingData.customer.identity + ')');
        try {
            // Set the loading message
            if (!inBackground) mainApp.setLoading(true, root.__('loading_aml_fetch') + '...');

            // First create an envelope to make sure Verified Platform is up and running
            const envelope = await v2lib.createEnvelope('aml-portal-org', 'no-recipients-flow');

            if (v2lib.wasCreated(envelope)) {
                // Envelope successfully created, proceed with AML
                log.s('AML: Envelope successfully created', envelope);
                const amlData = await amlTools.doAmlCompanySearch2(root, existingData.customer.identity);
                log.s('AML: Company data fetched from BankID', amlData);

                // Make sure the format is compatible with mongodb
                if (amlData.ownership && amlData.ownership.indicators && amlData.ownership.indicators.keyIndicators && amlData.ownership.indicators.keyIndicators.ownershipNace) {
                    amlData.ownership.indicators.keyIndicators.ownershipNaceList = [];
                    for (const key in amlData.ownership.indicators.keyIndicators.ownershipNace) {
                        amlData.ownership.indicators.keyIndicators.ownershipNaceList.push({
                            code: key,
                            title: amlData.ownership.indicators.keyIndicators.ownershipNace[key].title,
                            sum: amlData.ownership.indicators.keyIndicators.ownershipNace[key].sum,
                        });
                    }
                    delete amlData.ownership.indicators.keyIndicators.ownershipNace;
                }
                log.x('AML: Company data fetched from BankID', amlData);

                const companyData = dataModel.getEmptyModel();
                dataNorway.processData(root, companyData, amlData, existingData.brregData);

                this.completeAmlEnvelope(root, existingData, JSON.parse(JSON.stringify(amlData)), companyData, envelope);
                
                // Prepare BE data
                const aml = {
                    userId: root.credentials.uid,
                    envelopeId: envelope.id,
                    searchDate: new Date()
                }

                // Create list of persons to auto-add
                const personIds = this.getPersonIds(root, companyData);

                // Update customer in BE
                const response = await api.performAML(existingData.customer._id, aml, personIds, existingData.nextStatus);
                result.customer = response.customer;
                result.amlData = amlData;
                result.success = true;

                //await api.setCustomerStatus(existingData.customer._id);

                log.s('AML: Customer updated in BE, process complete', response);
            }else {
                // Error creating envelope, show error to the user
                result.exceptions.push(v2lib.getError(envelope));
                if (!inBackground) root.exceptions.push(v2lib.getError(envelope));
            }
        }catch (ex) {
            console.log(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'AML Company search'
                };
            }catch (exx) {}
            result.exceptions.push(ex);
            if (!inBackground) root.exceptions.push(ex);
        }
        // Hide loading message and return the result
        if (!inBackground) mainApp.setLoading(false);
        return result;
    },

    async completeAmlEnvelope(root, existingData, amlData, companyData, envelope) {
        root.workingInBackground = true;
        try {
            console.log('AML', amlData);

            // Download BankID reports if they exist
            const attachments = [];
            if (!amlData.keyInformation.name.includes('NARVIK HVITVASKING')) {
                // This is not the test-company, proceed with report download

                // Loop reports and download them
                for (const i in amlData.links.reports) {

                    const url = amlData.links.reports[i].href;
                    // The first report will be the full report, while the second will be the simple report
                    const name = i == 1 ? 'BankID AML Firmaattest.pdf' : 'BankID AML Firmarapport.pdf';
                    const type = i == 1 ? 'inc' : 'aml';

                    try {
                        const base64 = await verified.downloadBankIDReport(url);
                        await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, name);
                        attachments.push({
                            type: type,
                            name: name,
                            fileType: 'application/pdf',
                            size: 0,
                            sizeText: 'unknown',
                            number: 0
                        });

                    }catch (ex) {
                        log.e('BankID: Exception while downloading report');
                        console.log(ex);
                    }
                }
            }

            // Prepare metadata about the user who performed the search
            const metadata = {
                id: root.credentials.uid,
                userName: root.getEmployeeName(root.credentials.uid),
                date: new Date()
            };

            const translations = [
                'pep_pdf_user',
                'aml_pdf_title',
                'company_manual_header',
                'common_date',
                'common_name',
                'company_orgnr',
                'common_address',
                'common_zip',
                'common_city',
                'company_reg_date',
                'company_org_form',
                'common_country',
                'company_business',
                'company_business_codes',
                'company_registered',
                'company_status',
                'company_in_brreg',
                'company_in_business_reg',
                'company_in_mva_reg',
                'company_stat_bankrupt',
                'company_stat_liquidation',
                'company_stat_cliquidation',
                'common_yes',
                'common_no',
                'common_unknown'
            ];
            const pdfConfig = {
                logo: root.config.pdf.logoUrl,
                colors: {
                    headerBack: root.getColor('header'),
                    headerText: root.getColor('header-text')
                },
                language: root.locale.key || 'en_EN',
                translations: this.getPdfTranslations(root, translations)
            };

            // Prepare envelope userdata, name and tags
            const envelopeName = `Firmarapport ${amlData.keyInformation.name}`;
            const envelopeTags = ['firmarapport'];
            const envelopeUserdata = {
                metadata: metadata,
                templateId: 'aml',
                templatePath: process.env.VUE_APP_BASE_URL + 'templates/',
                brreg: existingData.brregData,
                aml: amlData,
                companyData: companyData,
                searchDate: new Date(),
                attachments: attachments,
                fileName: envelopeName,
                pdfConfig: pdfConfig
            };
            //console.log(JSON.stringify(envelopeUserdata));

            // Complete the envelope
            await v2lib.setUserData(envelope, envelopeUserdata);
            await v2lib.setTags(envelope, envelopeTags);
            await v2lib.setName(envelope, envelopeName);
            await v2lib.publishEnvelope(envelope);
            log.s('AML: Envelope published');

            // Wait for PDF to be generated
            await new Promise(r => setTimeout(r, 5000));

        }catch (ex) {
            console.log(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'AML Company search publish'
                };
            }catch (exx) {}
            result.exceptions.push(ex);
        }
        root.workingInBackground = false;
    },

    getPersonIds(root, companyData) {
        // Create list of persons to auto-add
        const personIds = [];
        log.v('AML: Auto-adding persons from the person list', companyData);
        for (const i in companyData.roles) {
            let roleMatch = false;
            for (const j in root.config.aml.autoRoles) {
                if (companyData.roles[i].roleId == root.config.aml.autoRoles[j]) roleMatch = true;
            }
            console.log('role', companyData.roles[i]);
            const personId = tools.encodePerson(companyData.roles[i]);
            if (roleMatch && !personIds.includes(personId)) {
                personIds.push(personId);
                log.v('AML: Auto-added ' + companyData.roles[i].firstName + ' ' + companyData.roles[i].lastName);
            }
        }
        if (root.config.aml.autoRoles.includes('screening_role_rrh')) {
            for (const i in companyData.beneficials) {
                if (companyData.beneficials[i].identified) {
                    const personId = tools.encodePerson(companyData.beneficials[i]);
                    if (!personIds.includes(personId)) {
                        personIds.push(personId);
                        log.v('AML: Auto-added ' + companyData.beneficials[i].firstName + ' ' + companyData.beneficials[i].lastName);
                    }
                }
            }
        }
        return personIds;
    },

    getPdfTranslations(root, keys, language) {
        const translations = {};
        for (const i in keys) {
            if (root.translations[keys[i]]) {
                if (language) {
                    translations[keys[i]] = {
                        [language]: root.translations[keys[i]][language]
                    };
                }else {
                    translations[keys[i]] = root.translations[keys[i]];
                }
            }
        }
        return translations;
    },

    async doAmlSweden(root, mainApp, existingData, inBackground) {
        // Prepare the result object
        const result = { success: false, exceptions: [] };
        log.v('AML: Performing company search on ' + existingData.customer.name + ' (' + existingData.customer.identity + ')');
        try {
            // Set the loading message
            if (!inBackground) mainApp.setLoading(true, root.__('loading_aml_fetch') + '...');

            // First create an envelope to make sure Verified Platform is up and running
            const envelope = await v2lib.createEnvelope('aml-portal-org', 'no-recipients-flow');
            //const envelope = await v2lib.createEnvelope('aml-portal-v1-org', 'aml-portal-no-recipients-flow');
            if (v2lib.wasCreated(envelope)) {
                // Envelope successfully created, proceed with AML


                // Update the loading message
                if (!inBackground) mainApp.setLoading(true, root.__('loading_creating_receipt') + '...');

                // Prepare metadata about the user who performed the search
                const metadata = {
                    id: root.credentials.uid,
                    userName: root.getEmployeeName(root.credentials.uid),
                    date: new Date()
                };

                // Prepare userdata for the PDF generation and get HTML and CSS from BE
/*                 const userdata = { trapetsData: existingData.trapetsData, searchDate: new Date(), metadata: metadata, attachments: [] };
                //console.log(JSON.stringify(userdata));
                const template = await api.renderTemplate(root, 'aml', userdata);
                log.v('AML: HTML for PDF generated', template); */


                const translations = [
                    'pep_pdf_user',
                    'aml_pdf_title',
                    'company_manual_header',
                    'common_date',
                    'common_name',
                    'company_orgnr',
                    'common_address',
                    'common_zip',
                    'common_city',
                    'company_reg_date',
                    'company_org_form',
                    'common_country',
                    'company_business',
                    'company_business_codes',
                    'company_registered',
                    'company_status',
                    'company_in_brreg',
                    'company_in_business_reg',
                    'company_in_mva_reg',
                    'company_stat_bankrupt',
                    'company_stat_liquidation',
                    'company_stat_cliquidation',
                    'common_yes',
                    'common_no',
                    'common_unknown'
                ];
                const pdfConfig = {
                    logo: root.config.pdf.logoUrl,
                    colors: {
                        headerBack: root.getColor('header'),
                        headerText: root.getColor('header-text')
                    },
                    language: root.locale.key || 'en_EN',
                    translations: this.getPdfTranslations(root, translations)
                };


                // Now we update the customer in the BE, update the loading message
                if (!inBackground) mainApp.setLoading(true, root.__('loading_saving_customer') + '...');

                // Prepare envelope userdata, name and tags
                const envelopeName = `Firmarapport ${existingData.companyData.name}`;
                const envelopeTags = ['firmarapport'];
                const envelopeUserdata = {
                    metadata: metadata,
                    templateId: 'aml',
                    templatePath: process.env.VUE_APP_BASE_URL + 'templates/',
                    /* trapetsData: existingData.trapetsData,
                    bisnodeData: existingData.bisnodeData, */
                    companyData: existingData.companyData,
                    searchDate: new Date(),
                    attachments: [],
                    /* css: template.css,
                    html: template.html, */
                    fileName: envelopeName,
                    pdfConfig: pdfConfig
                };
                console.log(JSON.stringify(envelopeUserdata));

                // Complete the envelope
                await v2lib.setUserData(envelope, envelopeUserdata);
                await v2lib.setTags(envelope, envelopeTags);
                await v2lib.setName(envelope, envelopeName);
                await v2lib.publishEnvelope(envelope);
                log.s('AML: Envelope published');

                // Prepare BE data
                const aml = {
                    userId: root.credentials.uid,
                    envelopeId: envelope.id,
                    searchDate: new Date()
                }

                // Create list of persons to auto-add
                const personIds = this.getPersonIds(root, existingData.companyData);

                // Update customer in BE
                const response = await api.performAML(existingData.customer._id, aml, personIds, existingData.nextStatus);
                result.customer = response.customer;
                result.success = true;

                log.s('AML: Customer updated in BE, process complete', response);
            }else {
                // Error creating envelope, show error to the user
                result.exceptions.push(v2lib.getError(envelope));
                if (!inBackground) root.exceptions.push(v2lib.getError(envelope));
            }
        }catch (ex) {
            console.log(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'AML Company search (trapets)'
                };
            }catch (exx) {}
            result.exceptions.push(ex);
            if (!inBackground) root.exceptions.push(ex);
        }
        // Hide loading message and return the result
        if (!inBackground) mainApp.setLoading(false);
        return result;
    },

    async doAmlUK(root, mainApp, existingData, inBackground) {
        // Prepare the result object
        const result = { success: false, exceptions: [] };
        log.v('AML: Performing company search on ' + existingData.customer.name + ' (' + existingData.customer.identity + ')');
        try {
            // Set the loading message
            if (!inBackground) mainApp.setLoading(true, root.__('loading_aml_fetch') + '...');

            // First create an envelope to make sure Verified Platform is up and running
            const envelope = await v2lib.createEnvelope('aml-portal-org', 'no-recipients-flow');
            //const envelope = await v2lib.createEnvelope('aml-portal-v1-org', 'aml-portal-no-recipients-flow');
            if (v2lib.wasCreated(envelope)) {
                // Envelope successfully created, proceed with AML

                // Update the loading message
                if (!inBackground) mainApp.setLoading(true, root.__('loading_creating_receipt') + '...');

                // Prepare metadata about the user who performed the search
                const metadata = {
                    id: root.credentials.uid,
                    userName: root.getEmployeeName(root.credentials.uid),
                    date: new Date()
                };

                const translations = [
                    'pep_pdf_user',
                    'aml_pdf_title',
                    'company_manual_header',
                    'common_date',
                    'common_name',
                    'company_orgnr',
                    'common_address',
                    'common_zip',
                    'common_city',
                    'company_reg_date',
                    'company_org_form',
                    'common_country',
                    'company_business',
                    'company_business_codes',
                    'company_registered',
                    'company_status',
                    'company_in_brreg',
                    'company_in_business_reg',
                    'company_in_mva_reg',
                    'company_stat_bankrupt',
                    'company_stat_liquidation',
                    'company_stat_cliquidation',
                    'common_yes',
                    'common_no',
                    'common_unknown'
                ];
                const pdfConfig = {
                    logo: root.config.pdf.logoUrl,
                    colors: {
                        headerBack: root.getColor('header'),
                        headerText: root.getColor('header-text')
                    },
                    language: root.locale.key || 'en_EN',
                    translations: this.getPdfTranslations(root, translations)
                };


                // Now we update the customer in the BE, update the loading message
                if (!inBackground) mainApp.setLoading(true, root.__('loading_saving_customer') + '...');

                // Prepare envelope userdata, name and tags
                const envelopeName = `Company report ${existingData.companyData.name}`;
                const envelopeTags = ['firmarapport'];
                const envelopeUserdata = {
                    metadata: metadata,
                    templateId: 'aml',
                    templatePath: process.env.VUE_APP_BASE_URL + 'templates/',
                    chouseData: existingData.chouseData,
                    companyData: existingData.companyData,
                    searchDate: new Date(),
                    attachments: [],
                    /* css: template.css,
                    html: template.html, */
                    fileName: envelopeName,
                    pdfConfig: pdfConfig
                };
                console.log(JSON.stringify(envelopeUserdata));

                // Complete the envelope
                await v2lib.setUserData(envelope, envelopeUserdata);
                await v2lib.setTags(envelope, envelopeTags);
                await v2lib.setName(envelope, envelopeName);
                await v2lib.publishEnvelope(envelope);
                log.s('AML: Envelope published');

                // Prepare BE data
                const aml = {
                    userId: root.credentials.uid,
                    envelopeId: envelope.id,
                    searchDate: new Date()
                }

                // Create list of persons to auto-add
                const personIds = this.getPersonIds(root, existingData.companyData);

                // Update customer in BE
                const response = await api.performAML(existingData.customer._id, aml, personIds, existingData.nextStatus);
                result.customer = response.customer;
                result.success = true;

                log.s('AML: Customer updated in BE, process complete', response);
            }else {
                // Error creating envelope, show error to the user
                result.exceptions.push(v2lib.getError(envelope));
                if (!inBackground) root.exceptions.push(v2lib.getError(envelope));
            }
        }catch (ex) {
            console.log(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'AML Company search (uk)'
                };
            }catch (exx) {}
            result.exceptions.push(ex);
            if (!inBackground) root.exceptions.push(ex);
        }
        // Hide loading message and return the result
        if (!inBackground) mainApp.setLoading(false);
        return result;
    },

    async performPEPSearch(root, person, existingData, inBackground) {
        // Prepare the result object
        const result = { success: false, exceptions: [] };
        log.v('AML: Performing person search on ' + person.info.firstName + ' ' + person.info.lastName + ' (' + person.info.dateOfBirth + ')');
        try {
            // First create an envelope to make sure Verified Platform is up and running
            const usev2receipts = root.config.aml.person.report;
            const descriptor = usev2receipts ? 'aml-portal-v1-person' : 'aml-portal-pep';
            const flowName = usev2receipts ? 'no-recipients-flow' : 'no-recipients-flow';

            const envelope = await v2lib.createEnvelope(descriptor, flowName);
            if (v2lib.wasCreated(envelope)) {
                // Envelope successfully created, proceed with AML
                log.s('AML: Envelope successfully created', envelope);
                // Update the person object with envelopeId and bearer token?
                person.envelopeId = envelope.id;

                

                const searchParams = {
                    firstName: person.info.firstName,
                    lastName: person.info.lastName,
                    dateOfBirth: person.info.dateOfBirth,
                    ssn: person.info.ssn,
                    countryOfSsn: '',
                    mode: 'exact',
                    parameters: []
                };

                // Perform PEP/Sanction search
                if (root.config.aml.person.ssn && person.info.ssn) {
                    log.w('AML: Searching with SSN');
                    result.amlData = await amlTools.doAmlPersonSearchSSN(root, person.info.firstName, person.info.lastName, person.info.ssn);
                    searchParams.parameters.push('firstName');
                    searchParams.parameters.push('lastName');
                    searchParams.parameters.push('ssn');
                    searchParams.parameters.push('countryOfSsn');
                    searchParams.countryOfSsn = 'NO';
                }else {
                    log.v('AML: Searching with date of birth', person.info.dateOfBirth);
                    const dateOfBirth = tools.fixDateOfBirth(person.info.dateOfBirth);
                    result.amlData = await amlTools.doAmlPersonSearch(root, person.info.firstName, person.info.lastName, dateOfBirth);
                    searchParams.parameters.push('firstName');
                    searchParams.parameters.push('lastName');
                    searchParams.parameters.push('dateOfBirth');
                    console.log(JSON.stringify(result.amlData));
                }
                log.s('AML: Person data fetched from BankID', result.amlData);

                // Try to download the BankID report
                let base64 = undefined;
                if (root.config.aml.person.report) {
                    if (result.amlData.personData.links && result.amlData.personData.links.reports && result.amlData.personData.links.reports.length > 0) {
                        const link = result.amlData.personData.links.reports[0].href;
                        base64 = await verified.downloadBankIDReport(link);
                    }
                }

                // Upload attachment if available
                const attachments = [];
                if (base64) {
                    console.log('uploading');
                    await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, 'BankID AML Personrapport.pdf');
                    attachments.push({
                        type: 'peprep',
                        name: 'BankID AML Personrapport.pdf',
                        fileType: 'application/pdf',
                        size: 0,
                        sizeText: 'unknown',
                        number: attachments.length
                    });
                }

                const metadata = {
                    userId: root.credentials.uid,
                    userName: root.getEmployeeName(root.credentials.uid),
                    date: new Date()
                };

                let envelopeName;
                let envelopeTags;
                let envelopeUserdata;
                if (usev2receipts) {

                    const personData = dataModel.getEmptyPersonModel();
                    dataPerson.processBamlData(root, personData, result.amlData, searchParams);

                    const translations = [
                        'pep_pdf_user',
                        'pep_pdf_title',
                        'pep_pdf_search_params',
                        'pep_pdf_provider',
                        'pep_pdf_pep_header',
                        'pep_pdf_pep_empty',
                        'pep_pdf_sanction_header',
                        'pep_pdf_sanction_empty',
                        'pep_pdf_lists',
                        'pep_pdf_company_roles',

                        'common_date',
                        'common_name',
                        'common_first_name',
                        'common_last_name',
                        'common_dob',
                        'common_address',
                        'common_zip',
                        'common_city',
                        'common_country',
                        'common_unknown',
                        'common_ssn',
                        'common_ssn_country',

                        'common_result',

                        'proprietor',
                        'ceo',
                        'chair',
                        'screening_role_procura',
                        'screening_role_deputy_chairman',
                        'screening_role_sign_right',
                        'screening_role_rrh',
                        'screening_role_board_member',
                        'screening_role_deputy_board_member',
                        'screening_role_shareholder',
                        'screening_role_accountant',
                        'scr_secretary',
                        'scr_director',
                        'screening_role_other',
                    ];
                    const pdfConfig = {
                        logo: root.config.pdf.logoUrl,
                        colors: {
                            headerBack: root.getColor('header'),
                            headerText: root.getColor('header-text')
                        },
                        language: root.locale.key || 'en_EN',
                        translations: this.getPdfTranslations(root, translations, root.locale.key || 'en_EN')
                    };

                    // Prepare envelope userdata, name and tags
                    envelopeName = `PEP/Sanksjonsøk ${person.info.firstName} ${person.info.lastName} (${person.info.dateOfBirth})`;
                    envelopeTags = ['personsøk'];
                    envelopeUserdata = {
                        metadata: metadata,
                        pdfConfig: pdfConfig,
                        personData: personData,
                        companyData: dataModel.getSimpleCompanyData(existingData.companyData),
                        attachments: attachments,
                        fileName: envelopeName,
                        person: {
                            firstName: searchParams.firstName,
                            lastName: searchParams.lastName,
                            dateOfBirth: searchParams.dateOfBirth
                        }
                    };

                }else {

                    const userdata = { aml: result.amlData, person: person, brreg: existingData.brreg, company: existingData.companyAml, metadata: metadata, attachments: attachments };
                    const template = await api.renderTemplate(root, 'pep', userdata);
    
                    // Prepare envelope userdata, name and tags
                    envelopeName = `PEP/Sanksjonsøk ${person.info.firstName} ${person.info.lastName} (${person.info.dateOfBirth})`;
                    envelopeTags = ['personsøk'];
                    envelopeUserdata = {
                        aml: result.amlData,
                        person: person,
                        brreg: existingData.brreg,
                        company: existingData.companyAml,
                        attachments: attachments,
                        css: template.css,
                        html: template.html,
                        metadata: metadata
                    };

                }
                console.log(JSON.stringify(envelopeUserdata));

                // Complete the envelope
                await v2lib.setUserData(envelope, envelopeUserdata);
                await v2lib.setTags(envelope, envelopeTags);
                await v2lib.setName(envelope, envelopeName);
                await v2lib.publishEnvelope(envelope);
                log.s('AML: Envelope published');

                // Prepare backend update
                const backendData = {
                    aml: {
                        userId: root.credentials.uid,
                        searchDate: new Date(),
                        envelopeId: envelope.id,
                        customerId: existingData.customerId
                    }
                };

                console.log('pep data', result.amlData);
                if (result.amlData.personData.aml && result.amlData.personData.aml.pepResults.length > 0) {
                    backendData.pep = {
                        hit: true,
                        results: []
                    };
                    for (const i in result.amlData.personData.aml.pepResults) {
                        const item = result.amlData.personData.aml.pepResults[i];
                        backendData.pep.results.push({
                            indicator: item.matchIndicator,
                            description: item.matchIndicatorDescription,
                            title: item.title,
                            name: item.name,
                            dateOfBirth: item.birthDate,
                            source: item.provider + ' (' + item.source + ')'
                        });
                    }
                }
                if (result.amlData.personData.aml && result.amlData.personData.aml.sanctionResults.length > 0) {
                    backendData.sanction = {
                        hit: true,
                        results: []
                    };
                    for (const i in result.amlData.personData.aml.sanctionResults) {
                        const item = result.amlData.personData.aml.sanctionResults[i];
                        backendData.sanction.results.push({
                            indicator: item.matchIndicator,
                            description: item.matchIndicatorDescription,
                            title: item.title,
                            name: item.name,
                            dateOfBirth: item.birthDate || '',
                            source: item.provider + ' (' + item.source + ')'
                        });
                    }
                }

                const personId = tools.encodePerson(person);
                await api.updatePerson(personId, backendData);

                // Fetch the updated customer
                const cres = await api.getCustomer(existingData.customerId);
                result.updatedCustomer = cres.customer;

            }else {
                // Error creating envelope, show error to the user
                result.exceptions.push(v2lib.getError(envelope));
                if (!inBackground) root.exceptions.push(v2lib.getError(envelope));
            }
        }catch (ex) {
            console.log(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'AML Person search'
                };
            }catch (exx) {}
            result.exceptions.push(ex);
            if (!inBackground) root.exceptions.push(ex);
        }
        return result;
    },

    async sendIdControl(root, person, existingData, inBackground) {

        // Prepare the result object
        const result = { success: false, exceptions: [] };
        log.v('IDC: Sending ID-control to ' + person.info.firstName + ' ' + person.info.lastName + ' (' + person.info.dateOfBirth + ')');
        try {

            const idcIndex = existingData.idcIndex || 0;
            const idcConfig = root.config.idcs[idcIndex];

            const usev2receipts = idcConfig.useCustomSender;
            const descriptor = usev2receipts ? 'aml-portal-v1-idc-invitation' : 'aml-portal-id';
            const flowName = usev2receipts ? 'aml-portal-id-flow' : 'only-editors-flow';

            // First create an envelope to make sure Verified Platform is up and running
            const envelope = await v2lib.createEnvelope(descriptor, flowName);
            if (v2lib.wasCreated(envelope)) {
                // Envelope successfully created, proceed with AML
                log.s('IDC: Envelope successfully created', envelope);

                person.envelopeIdControl = envelope.id;
                result.envelopeId = envelope.id;

                const tres = await api.getTranslations(idcConfig.key);
                const translations = { ...locale.getDefaultIdcTranslations(), ...tres.texts };

                let language = 'nb_NO';
                if (existingData.language) language = existingData.language;


                // Set mail-script parameters
                const emailData = {
                    firstName: person.info.firstName,
                    lastName: person.info.lastName,
                    senderName: root.user.givenName + ' ' + root.user.familyName,
                    senderEmail: root.user.email,
                    customerName: existingData.customerName,
                    reference: existingData.reference ? root.translations['customer_reference_message'][language] + ': ' + existingData.reference : ''
                }
                if (usev2receipts) {
                    emailData.senderName = idcConfig.customSender.firstName + ' ' + idcConfig.customSender.lastName;
                    emailData.senderEmail = idcConfig.customSender.email;
                }


                if (existingData.sms && existingData.sms.notifyWithSms) {
                    console.log('using SMS', existingData.sms);
                    await v2lib.addRecipient(envelope, {
                        givenName: person.info.firstName || ' ',
                        familyName: person.info.lastName || ' ',
                        email: person.contact.email,
                        language: language,
                        signingMethod: 'email',
                        role: { action: 'edit', label: 'Utføre ID-kontroll', name: 'editor' },
                        notificationMethod: 'sms',
                        secure: true,
                        sms: true,
                        telephone: existingData.sms.phone.prefix + existingData.sms.phone.number
                    });
                    const greeting = root.replaceBrackets(translations['idc_sms_invite'][language], emailData)
                    await v2lib.setSMSGreeting(envelope, greeting);
                }else {
                    await v2lib.addRecipient(envelope, {
                        givenName: person.info.firstName || ' ',
                        familyName: person.info.lastName || ' ',
                        email: person.contact.email,
                        language: language,
                        signingMethod: 'email',
                        role: { action: 'edit', label: 'Utføre ID-kontroll', name: 'editor' }
                    });
                }

                // Add to BE
                const personId = tools.encodePerson(person);
                const response = await api.updatePerson(personId, {
                    idc: {
                        userId: root.credentials.uid,
                        sentDate: new Date(),
                        completed: false,
                        envelopeId: envelope.id,
                        customerId: existingData.customerId,
                        nextStatus: existingData.nextStatus || 'na'
                    }
                });
                if (existingData.currentStatus && existingData.currentStatus != 'na') {
                    const cuData = {
                        status: existingData.currentStatus
                    };
                    await api.updateCustomer(existingData.customerId, cuData);
                }

                const credentials = {
                    id: existingData.customerId,
                    cid: root.credentials.cid,
                    key: root.credentials.key,
                    bearerToken: response.token
                }
                if (root.credentials.testinc) credentials.testinc = root.credentials.testinc;

                result.updatedCustomer = response.customer;

                let base = '';
                if (root.isAWS) base = '/web/aml-portal/#';

                const mail = {
                    redirect: root.config.idcs[idcIndex].redirect,
                    logourl: root.config.idcs[idcIndex].email.logo,
                    buttonColor: root.getColor('button'),
                    linkColor: root.getColor('text-accent'),

                    url: root.baseUrl + base + '/id-kontroll',
                    lang: language,
                    linkUrl: root.config.idcs[idcIndex].email.link,

                    // Notify editor
                    subject: root.replaceBrackets(translations['idc_email_subject'][language], emailData),
                    header: root.replaceBrackets(translations['idc_email_header'][language], emailData),
                    message: root.replaceBrackets(translations['idc_email_message'][language], emailData),
                    hint: root.replaceBrackets(translations['idc_email_hint'][language], emailData),
                    footer: root.replaceBrackets(translations['idc_email_footer'][language], emailData),
                    buttonText: root.replaceBrackets(translations['idc_email_button'][language], emailData),
                }
                // Add translations for notify signatory and document signed
                for (let i in root.config.locales) {
                    mail[root.config.locales[i].key] = {
                        // Notify editor
                        subject: root.replaceBrackets(translations['idc_email_subject'][root.config.locales[i].key], emailData, true),
                        header: root.replaceBrackets(translations['idc_email_header'][root.config.locales[i].key], emailData, true),
                        message: root.replaceBrackets(translations['idc_email_message'][root.config.locales[i].key], emailData, true),
                        buttonText: root.replaceBrackets(translations['idc_email_button'][root.config.locales[i].key], emailData, true),
                        hint: root.replaceBrackets(translations['idc_email_hint'][root.config.locales[i].key], emailData, true),
                        footer: root.replaceBrackets(translations['idc_email_footer'][root.config.locales[i].key], emailData, true),
                        linkText: root.replaceBrackets(translations['idc_email_link'][root.config.locales[i].key], emailData, true),
                    }
                }

                // Prepare envelope userdata, name and tags
                const envelopeName = `ID-kontroll ${person.info.firstName} ${person.info.lastName}`;
                const envelopeTags = ['id-kontroll'];
                const envelopeUserdata = {
                    mail: mail,
                    credentials: credentials,
                    config: {
                        key: idcConfig.key,
                        isAWS: root.isAWS
                    },
                    person: person,
                    company: {
                        name: existingData.customerName
                    },
                    metadata: {
                        userId: root.credentials.uid,
                        userName: root.getEmployeeName(root.credentials.uid),
                        sentDate: new Date()
                    },
                    fileName: envelopeName,
                    _isForwarded: true
                };


                // Set metadata
                const metadata = {
                    silentParties: [],
                    language: 'en_EN'
                };
                if (usev2receipts) {
                    metadata.sender = {
                        givenName: idcConfig.customSender.firstName,
                        familyName: idcConfig.customSender.lastName,
                        email: idcConfig.customSender.email
                    };
                }
                await v2lib.setMetadata(envelope, metadata);


                // Complete the envelope
                await v2lib.setUserData(envelope, envelopeUserdata);
                await v2lib.setTags(envelope, envelopeTags);
                await v2lib.setName(envelope, envelopeName);
                await v2lib.publishEnvelope(envelope);
                log.s('IDC: Envelope published');

                result.userdata = envelopeUserdata;

            }else {
                // Error creating envelope, show error to the user
                result.exceptions.push(v2lib.getError(envelope));
                if (!inBackground) root.exceptions.push(v2lib.getError(envelope));
            }
        }catch (ex) {
            console.log(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'Send ID-control'
                };
            }catch (exx) {}
            result.exceptions.push(ex);
            if (!inBackground) root.exceptions.push(ex);
        }
        return result;
    },








    async createFinalReport(root, data, inBackground, files) {
        // Prepare the result object
        const result = { success: false, exceptions: [] };
        log.v('Report: Creating final report for', data.customer);
        try {
            // First create an envelope to make sure Verified Platform is up and running
            const envelope = await v2lib.createEnvelope('aml-portal-report', 'no-recipients-flow');
            if (v2lib.wasCreated(envelope)) {
                // Envelope successfully created, proceed with report
                log.s('Report: Envelope successfully created', envelope);



                const config = reporthelper.getReportConfig(root);

                // Collect all the data
                const userdata = {
                    metadata: {
                        date: tools.dateString(new Date()),
                        userId: root.credentials.uid,
                        userName: root.getEmployeeName(root.credentials.uid)
                    },
                    customer: data.customer,
                    brregData: data.brregData,
                    manualData: data.manualData,
                    aml: data.amlData,
                    persons: data.persons,
                    report: data.reportData,
                    kycMetadata: data.kycMetadata,
                    reportMetadata: data.reportMetadata,
                    keywordOptions: {
                        terrorism: { label: 'Terrorisme' },
                        fraud: { label: 'Svindel' },
                        sentance: { label: 'Dom' },
                        insideTrading: { label: 'Innsidehandel' },
                        illegal: { label: 'Ulovlig' },
                        fine: { label: 'Bot' },
                        corruption: { label: 'Korrupsjon' },
                        trial: { label: 'Rettssak' },
                        moneyLaundering: { label: 'Hvitvasking' },
                        lawsuit: { label: 'Søksmål' }
                    },
                    reportConfig: config,
                    pdfLanguage: root.locale.key
                }

                let identityTag = data.customer.identity;
                if (data.customer.type != 'org') identityTag = '';

                const attachments = [];
                if (data.customer.source == 'brreg') {
                    attachments.push({
                        type: 'aml',
                        name: 'BankID AML Firmarapport.pdf',
                        fileType: 'application/pdf',
                        size: 0,
                        sizeText: 'unknown',
                        number: 0
                    });
                }
                attachments.push({
                    type: 'kyc',
                    name: 'Egenerklæring.pdf',
                    fileType: 'application/pdf',
                    size: 0,
                    sizeText: 'unknown',
                    number: attachments.length
                });

                if (data.suitability.envelope) {
                    attachments.push({
                        type: 'suit',
                        name: 'Egnethetserklæring.pdf',
                        fileType: 'application/pdf',
                        size: 0,
                        sizeText: 'unknown',
                        number: attachments.length
                    });
                }

                // Set metadata
                const metadata = {
                    userId: root.credentials.uid,
                    date: new Date(),
                };
                // Maconomy integration
                if (root.config.integrations.maconomy.enabled) {
                    metadata.maconomy = {
                        risk: 0,
                        date: new Date(),
                    };
                }
                await v2lib.setMetadata(envelope, metadata);

                console.log('1');

                let base64; let ures;
                // Attach BankID AML Report
                if (data.customer.source == 'brreg') {
                    base64 = await this.downloadVerifiedFile(data.customer.aml.envelopeId, true);
                    ures = await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, 'BankID AML Firmarapport.pdf');
                    //console.log('ures', ures);
                }
                // Attach declaration form
                if (!data.kycDisabled) {
                    base64 = await this.downloadVerifiedFile(data.customer.kyc.envelopeId);
                    ures = await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, 'Egenerklæring.pdf');
                }else if (data.kycExternalId) {
                    base64 = await this.downloadVerifiedFile(data.kycExternalId, true);
                    ures = await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, 'Egenerklæring.pdf');
                }
                //console.log('ures', ures);

                if (data.suitability.envelope) {
                    base64 = await this.downloadVerifiedFile(data.suitability.envelope.id);
                    ures = await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, 'Egnethetserklæring.pdf');
                }

                console.log('2');

                // Upload assignment agreement if added
                for (let i in files.files) {
                    if (!files.files[i].uploaded) {
                        attachments.push(v2lib.addFileUploadAttachmentToUserdataSafe(attachments.length, files.files[i], 'risk'));
                        await v2lib.addAttachment(envelope, files.files[i].name, files.files[i].data);
                    }
                }

                //console.log('atc', attachments);

                console.log(JSON.stringify(userdata));
                const template = await api.renderTemplate(root, 'rep', userdata);

                const envelopeName = `Sluttrapport ${data.customer.name}`;
                const envelopeTags = ['sluttrapport'];
                if (identityTag && identityTag.length > 0) envelopeTags.push(identityTag);
                const envelopeUserdata = {
                    css: template.css,
                    html: template.html,
                    attachments: attachments
                };


                // Complete the envelope
                await v2lib.setUserData(envelope, envelopeUserdata);
                await v2lib.setTags(envelope, envelopeTags);
                await v2lib.setName(envelope, envelopeName);
                await v2lib.publishEnvelope(envelope);
                log.s('Report: Envelope published');



                const customerObject = {
                    report: {
                        userId: root.credentials.uid,
                        envelopeId: envelope.id,
                        reportDate: new Date()
                    },
                    status: 'r-rep-x'
                };

                const beres = await api.updateCustomer(data.customer._id, customerObject);
                result.updatedCustomer = beres.customer;

                const roles = [];
                for (const i in data.persons) {
                    const role = {
                        name: data.persons[i].info.firstName + ' ' + data.persons[i].info.lastName,
                        identity: tools.encodePerson(data.persons[i]),
                        customerId: data.customer._id,
                        customerName: data.customer.name,
                        customerType: data.customer.type,
                        roles: []
                    };
                    for (const j in data.persons[i].roles) {
                        if (!role.roles.includes(data.persons[i].roles[j].id)) {
                            role.roles.push(data.persons[i].roles[j].id);
                        }
                    }
                    console.log('role', role);
                    roles.push(role);
                }
                await api.addCustomerRoles(data.customer._id, roles);


                result.success = true;




            }else {
                // Error creating envelope, show error to the user
                result.exceptions.push(v2lib.getError(envelope));
                if (!inBackground) root.exceptions.push(v2lib.getError(envelope));
            }
        }catch (ex) {
            console.log(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'Final report'
                };
            }catch (exx) {}
            result.exceptions.push(ex);
            if (!inBackground) root.exceptions.push(ex);
        }
        return result;
    },


    async createFinalReportAuto(root, data, inBackground, files) {
        // Prepare the result object
        const result = { success: false, exceptions: [] };
        log.v('Report: Creating final report for', data.customer);
        try {
            // First create an envelope to make sure Verified Platform is up and running
            const envelope = await v2lib.createEnvelope('aml-portal-report', 'no-recipients-flow');
            if (v2lib.wasCreated(envelope)) {
                // Envelope successfully created, proceed with report
                log.s('Report: Envelope successfully created', envelope);



                const config = reporthelper.getReportConfig(root);

                // Collect all the data
                const userdata = {
                    metadata: {
                        date: tools.dateString(new Date()),
                        userId: 'auto',
                        userName: 'laget automatisk av systemet'
                    },
                    customer: data.customer,
                    brregData: data.brregData,
                    manualData: data.manualData,
                    aml: data.amlData,
                    persons: data.persons,
                    report: data.reportData,
                    kycMetadata: data.kycMetadata,
                    reportMetadata: data.reportMetadata,
                    keywordOptions: {
                        terrorism: { label: 'Terrorisme' },
                        fraud: { label: 'Svindel' },
                        sentance: { label: 'Dom' },
                        insideTrading: { label: 'Innsidehandel' },
                        illegal: { label: 'Ulovlig' },
                        fine: { label: 'Bot' },
                        corruption: { label: 'Korrupsjon' },
                        trial: { label: 'Rettssak' },
                        moneyLaundering: { label: 'Hvitvasking' },
                        lawsuit: { label: 'Søksmål' }
                    },
                    reportConfig: config
                }

                let identityTag = data.customer.identity;
                if (data.customer.type != 'org') identityTag = '';

                const attachments = [];
                if (data.customer.source == 'brreg') {
                    attachments.push({
                        type: 'aml',
                        name: 'BankID AML Firmarapport.pdf',
                        fileType: 'application/pdf',
                        size: 0,
                        sizeText: 'unknown',
                        number: 0
                    });
                }
                attachments.push({
                    type: 'kyc',
                    name: 'Egenerklæring.pdf',
                    fileType: 'application/pdf',
                    size: 0,
                    sizeText: 'unknown',
                    number: attachments.length
                });

                // Set metadata
                const metadata = {
                    userId: 'auto',
                    date: new Date(),
                };
                // Maconomy integration
                if (root.config.integrations.maconomy.enabled) {
                    metadata.maconomy = {
                        risk: 0,
                        date: new Date(),
                    };
                }
                await v2lib.setMetadata(envelope, metadata);


                let base64; let ures;
                // Attach BankID AML Report
                if (data.customer.source == 'brreg') {
                    base64 = await this.downloadVerifiedFile(data.customer.aml.envelopeId, true);
                    ures = await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, 'BankID AML Firmarapport.pdf');
                    //console.log('ures', ures);
                }
                // Attach declaration form
                if (!data.kycDisabled) {
                    base64 = await this.downloadVerifiedFile(data.customer.kyc.envelopeId);
                    ures = await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, 'Egenerklæring.pdf');
                }
                
                //console.log('ures', ures);

                // Upload assignment agreement if added
                for (let i in files.files) {
                    if (!files.files[i].uploaded) {
                        attachments.push(v2lib.addFileUploadAttachmentToUserdataSafe(attachments.length, files.files[i], 'risk'));
                        await v2lib.addAttachment(envelope, files.files[i].name, files.files[i].data);
                    }
                }

                //console.log('atc', attachments);

                console.log(JSON.stringify(userdata));
                const template = await api.renderTemplate(root, 'rep', userdata);

                const envelopeName = `Sluttrapport ${data.customer.name}`;
                const envelopeTags = ['sluttrapport'];
                if (identityTag && identityTag.length > 0) envelopeTags.push(identityTag);
                const envelopeUserdata = {
                    css: template.css,
                    html: template.html,
                    attachments: attachments
                };


                // Complete the envelope
                await v2lib.setUserData(envelope, envelopeUserdata);
                await v2lib.setTags(envelope, envelopeTags);
                await v2lib.setName(envelope, envelopeName);
                await v2lib.publishEnvelope(envelope);
                log.s('Report: Envelope published');



                const customerObject = {
                    report: {
                        userId: 'auto',
                        envelopeId: envelope.id,
                        reportDate: new Date()
                    },
                    status: 'r-rep-y'
                };

                const beres = await api.updateCustomer(data.customer._id, customerObject);
                result.updatedCustomer = beres.customer;

                const roles = [];
                for (const i in data.persons) {
                    const role = {
                        name: data.persons[i].info.firstName + ' ' + data.persons[i].info.lastName,
                        identity: tools.encodePerson(data.persons[i]),
                        customerId: data.customer._id,
                        customerName: data.customer.name,
                        customerType: data.customer.type,
                        roles: []
                    };
                    for (const j in data.persons[i].roles) {
                        if (!role.roles.includes(data.persons[i].roles[j].id)) {
                            role.roles.push(data.persons[i].roles[j].id);
                        }
                    }
                    console.log('role', role);
                    roles.push(role);
                }
                await api.addCustomerRoles(data.customer._id, roles);


                result.success = true;




            }else {
                // Error creating envelope, show error to the user
                result.exceptions.push(v2lib.getError(envelope));
                if (!inBackground) root.exceptions.push(v2lib.getError(envelope));
            }
        }catch (ex) {
            console.log(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'Final report'
                };
            }catch (exx) {}
            result.exceptions.push(ex);
            if (!inBackground) root.exceptions.push(ex);
        }
        return result;
    },


    async createFinalReportPerson(root, data, inBackground, files) {
        // Prepare the result object
        const result = { success: false, exceptions: [] };
        log.v('Report: Creating final report for', data.customer);
        try {
            // First create an envelope to make sure Verified Platform is up and running
            const envelope = await v2lib.createEnvelope('aml-portal-report', 'no-recipients-flow');
            if (v2lib.wasCreated(envelope)) {
                // Envelope successfully created, proceed with report
                log.s('Report: Envelope successfully created', envelope);

                const config = reporthelper.getReportConfig(root);

                let identityTag = data.customer.identity;
                if (data.customer.type != 'org') identityTag = '';

                // Set metadata
                const metadata = {
                    userId: root.credentials.uid,
                    userName: root.getEmployeeName(root.credentials.uid),
                    date: new Date(),
                    risk: data.customer.risk
                };
                //await envelope.put({ metadata: metadata });

                const attachments = [];
                for (let i in files.files) {
                    if (!files.files[i].uploaded) {
                        attachments.push(v2lib.addFileUploadAttachmentToUserdataSafe(attachments.length, files.files[i], 'risk'));
                        await v2lib.addAttachment(envelope, files.files[i].name, files.files[i].data);
                    }
                }

                console.log('data', data);

                if (data.persons.length > 0) {

                    const person = data.persons[0];

                    if (person.control.completed && person.control.envelopeId) {

                        attachments.push({
                            type: 'kyc',
                            name: 'Egenerklæring.pdf',
                            fileType: 'application/pdf',
                            size: 0,
                            sizeText: 'unknown',
                            number: attachments.length
                        });
                        let base64 = await this.downloadVerifiedFile(person.control.envelopeId);
                        let ures = await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, 'Egenerklæring.pdf');

                    }

                }

                if (data.suitability.envelope) {
                    attachments.push({
                        type: 'suit',
                        name: 'Egnethetserklæring.pdf',
                        fileType: 'application/pdf',
                        size: 0,
                        sizeText: 'unknown',
                        number: attachments.length
                    });
                }
                if (data.suitability.envelope) {
                    let base64 = await this.downloadVerifiedFile(data.suitability.envelope.id);
                    let ures = await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, 'Egnethetserklæring.pdf');
                }

                const envelopeName = `Sluttrapport ${data.customer.name}`;
                const envelopeTags = ['sluttrapport'];
                if (identityTag && identityTag.length > 0) envelopeTags.push(identityTag);

                // Collect all the data
                const userdata = {
                    metadata: {
                        date: tools.dateString(new Date()),
                        userId: root.credentials.uid,
                        userName: root.getEmployeeName(root.credentials.uid)
                    },
                    reportMetadata: data.reportMetadata,
                    flow: data.customer.flow || 'org-no-baml',
                    templateId: 'aml',
                    templatePath: process.env.VUE_APP_BASE_URL + 'templates/',
                    customer: data.customer,
                    brregData: data.brregData,
                    manualData: data.manualData,
                    aml: data.amlData,
                    persons: data.persons,
                    singlePerson: data.singlePerson,
                    report: data.reportData,
                    kycMetadata: data.kycMetadata,
                    keywordOptions: {
                        terrorism: { label: 'Terrorisme' },
                        fraud: { label: 'Svindel' },
                        sentance: { label: 'Dom' },
                        insideTrading: { label: 'Innsidehandel' },
                        illegal: { label: 'Ulovlig' },
                        fine: { label: 'Bot' },
                        corruption: { label: 'Korrupsjon' },
                        trial: { label: 'Rettssak' },
                        moneyLaundering: { label: 'Hvitvasking' },
                        lawsuit: { label: 'Søksmål' }
                    },
                    reportConfig: config,
                    attachments: attachments,
                    css: 'template.css',
                    html: 'template.html',
                    fileName: envelopeName
                };

                const template = await api.renderTemplate(root, 'rep', userdata);

                const envelopeUserdata = {
                    css: template.css,
                    html: template.html,
                    attachments: attachments
                };

                // Complete the envelope
                await v2lib.setUserData(envelope, envelopeUserdata);
                await v2lib.setTags(envelope, envelopeTags);
                await v2lib.setName(envelope, envelopeName);
                await v2lib.publishEnvelope(envelope);
                log.s('Report: Envelope published');

                const customerObject = {
                    report: {
                        userId: root.credentials.uid,
                        envelopeId: envelope.id,
                        reportDate: new Date()
                    },
                    status: 'r-rep-x'
                };

                const beres = await api.updateCustomer(data.customer._id, customerObject);
                result.updatedCustomer = beres.customer;

                const roles = [];
                for (const i in data.persons) {
                    const role = {
                        name: data.persons[i].info.firstName + ' ' + data.persons[i].info.lastName,
                        identity: tools.encodePerson(data.persons[i]),
                        customerId: data.customer._id,
                        customerName: data.customer.name,
                        customerType: data.customer.type,
                        roles: []
                    };
                    for (const j in data.persons[i].roles) {
                        role.roles.push(data.persons[i].roles[j].id);
                    }
                    console.log('role', role);
                    roles.push(role);
                }
                await api.addCustomerRoles(data.customer._id, roles);

                result.success = true;
            }else {
                // Error creating envelope, show error to the user
                result.exceptions.push(v2lib.getError(envelope));
                if (!inBackground) root.exceptions.push(v2lib.getError(envelope));
            }
        }catch (ex) {
            console.log(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'Final report person'
                };
            }catch (exx) {}
            result.exceptions.push(ex);
            if (!inBackground) root.exceptions.push(ex);
        }
        return result;
    },


    async downloadVerifiedFile(envelopeId, attachment) {
        //console.log('attachVfile: fetching envelope', envelopeId);
        const envelope = await envelopes.get(envelopeId);

        const documentId = envelope.firstDocument().id;

        let uid = 'error';
        if (envelope.firstDocument().data.files.document) {
            uid = envelope.firstDocument().data.files.document.uid;
        }
        if (envelope.firstDocument().data.files.signedDocument) {
            uid = envelope.firstDocument().data.files.signedDocument.uid;
        }
        if (attachment && envelope.firstDocument().data.files.attachments && envelope.firstDocument().data.files.attachments.length > 0) {
            uid = envelope.firstDocument().data.files.attachments[0].uid;
        }

        //console.log('attachVfile: fetching download url', uid);
        let url = await verified.getDownloadUrl(envelope.id, documentId, uid);

        //console.log('attachVfile: downloading file', url);
        let data = await verified.downloadFile(url);

        return data;
    },



};
export default receipts;