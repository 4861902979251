import v2lib from "../../../v2lib";
const internalFlow = {

    async onCreateEnvelope() {
        let descriptor = 'aml-portal-v1-internal';
        let flowName = 'aml-portal-v1-internal-flow';

        // Create envelope
        const envelope = await v2lib.createEnvelope(descriptor, flowName, false);

        return envelope;
    },

    async onAddRecipient(envelope, person) {
        // Set editor recipient
        let recipient = null;
        if (person.useSms) {

            recipient = {
                givenName: person.firstName,
                familyName: person.lastName,
                language: person.lang || 'en_EN',
                signingMethod: 'email',
                email: person.email,
                order: 1,
                role: person.role,

                notificationMethod: 'sms',
                secure: false,
                sms: true,
                telephone: person.phone.prefix + person.phone.number
            };

        }else {

            recipient = {
                givenName: person.firstName,
                familyName: person.lastName,
                language: person.lang || 'en_EN',
                signingMethod: 'email',
                email: person.email,
                order: 1,
                role: person.role
            };

        }
        await v2lib.addRecipient(envelope, recipient);
    },

    async onSetUserData(envelope, userdata) {
        // Set template userdata and name
        /* const documentName = 'Egenerklæring ' + this.customer.name + '.pdf';
        this.ud.fileName = documentName; */
        
        await v2lib.setUserData(envelope, userdata);
        /* await v2lib.setNameAndTags(envelope, documentName, ['egenerklæring']);
        await v2lib.setName(envelope, documentName); */
    },

    async onSetNameAndTags(envelope, userdata, name, tags) {

        await v2lib.setDocumentName(envelope, userdata, name);

        //await v2lib.setTags(envelope, tags);

    },

    async onSubmit(envelope) {
        // Publish the envelope
        await v2lib.publishEnvelope(envelope);
    }

};
export default internalFlow;