<template>
<div>
    <PageHeader :actions="actions" compactable :offset="97" @scrolled="scrolled = $event" @action="onAction">
        <template v-slot:content>
            <BarItemContainer>
                <BarItem
                    :desc="__('common_name')"
                    :value="companyTitleCase(client.name)"
                />
                <BarItem v-if="client.type == 'org'"
                    :desc="__('company_orgnr')"
                    :value="client.identity"
                    :isEmpty="client.identity && client.identity.includes('AUTO_')"
                />
                <BarItem v-if="client.type == 'person' && $root.config.clients.personItems.dateOfBirth"
                    :desc="'Date of Birth'"
                    :value="getDateOfBirth()"
                    :isEmpty="!hasDateOfBirth()"
                />
                <BarItem v-if="client.type == 'person' && $root.config.clients.personItems.citizenship"
                    :desc="'Citizenship'"
                    :value="getCitizenship()"
                    :isEmpty="!hasCitizenship()"
                />
                <BarItem v-if="$root.config.responsible.clientEnabled"
                    isButton @clicked="editResponsibleVisible = true"
                    :desc="$root.config.responsible.clientText[$root.locale.key]"
                    :value="getEmployeeName(client.responsible)"
                    :isEmpty="!client.responsible"
                />
                <BarItem v-if="$root.config.params.hasDepartments"
                    :desc="'Branch'"
                    :value="getDepartmentName(client.department)"
                />
                <BarItem v-if="$root.config.branches.clientEnabled"
                    isButton @clicked="editResponsibleVisible = true"
                    :desc="$root.config.branches.clientText[$root.locale.key]"
                    :value="getDepartmentName(client.branch)"
                    :isEmpty="!client.branch"
                    :emptyValue="__t('common-unknown') + ' ' + $root.config.branches.clientText[$root.locale.key].toLowerCase()"
                />
                <BarItem v-if="$root.config.partners.clientEnabled"
                    isButton @clicked="editResponsibleVisible = true"
                    :desc="$root.config.partners.clientText[$root.locale.key]"
                    :value="getPartnerName(client.partner)"
                    :isEmpty="!client.partner"
                    :emptyValue="__t('common-unknown') + ' ' + $root.config.partners.clientText[$root.locale.key].toLowerCase()"
                />
                <BarItem v-if="$root.config.externalId.clientEnabled"
                    isButton @clicked="editResponsibleVisible = true"
                    :desc="$root.config.externalId.title[$root.locale.key]"
                    :value="client.externalId"
                    :isEmpty="!client.externalId"
                />
            </BarItemContainer>
        </template>
        <template v-slot:tabs>
            <vap-tabs v-if="Object.keys(tabModel).length > 1" accent v-model="tabModel" :tabs="flow.tabs" :compact="scrolled" />
        </template>
        <template v-slot:compact>
            <div v-if="!loading">
                <span>{{ companyTitleCase(client.name) }}</span>
                <span>{{ client.identity }}</span>
            </div>
        </template>
    </PageHeader>

    <!-- Tabs -->
    <div v-if="!loading" :class="[{'vclient-scrolled': scrolled}]">

        <div v-if="tabModel.overview" class="flw100" style="margin-top: 5px;">
            <OverviewV1
                :client="client"
                :persons="persons"
                :flow="flow"
                :triggers="triggers"
                :texts="texts"
                :events="events"
                :risk="risk"
                :projectId="projectId"
                @lookup="showLookupDialog"
                @form="startForm"
                @editform="editForm"
                @newform="newForm"
                @view="viewForm"
                @preview="previewFile"
                @previewex="previewExternalFile"
                @reminder="showFormReminderDialog"
                @tab="goToTab"
                @reset="showResetDialog"
                @resetreport="showResetReportDialog"
                @createreport="showCreateReportDialog"
                @custom="onCustomAction"
                @signature="onSignatureClicked"
                @archive="previewArchivedReport"
                @ams="onAmsAction"
                @xaction="onActionX"
                @reload="reloadClient"
                @project="changeProject"
                @file="showAddFileDialog"
                @deletefile="showDeleteFileDialog"
            />
        </div>

        <div v-if="tabModel.company" class="aml-page">
            <div class="flw100" style="margin-top: 20px;">
                <TabCompany v-if="client.company" :company="client.company" />
            </div>
        </div>

        <div v-if="tabModel.ownership" class="aml-page">
            <div class="flw100" style="margin-top: 20px;">
                <TabOwnership v-if="client.company" :company="client.company" />
            </div>
        </div>

        <div class="aml-page" v-if="(tabModel.persons || tabModel.report) && client.report.created">
            <vap-infocard :data="reportCreatedHelp" style="float: left; width: 100%; margin-top: 20px; margin-bottom: -20px;" />
        </div>

        <div v-if="tabModel.persons" class="mt20">
            <PersonsV1
                :persons="persons"
                :client="client"
                :flow="flow"
                @form="startForm"
                @reload="reloadClient"
                @preview="customPreviewFile"
            />
        </div>

        <div v-if="tabModel.report" class="mt20">
            <ReportV1
                :client="client"
                :flow="flow"
                :persons="persons"
                :triggers="triggers"
                :texts="texts"
                @reload="reloadClient"
                @pdf="previewFile"
                @preview="customPreviewFile"
                @tab="goToTab"
            />
        </div>

    </div>

    <!-- Delete dialog -->
    <PopupV1 v-if="deleteDialog.show" :header="__('client_delete')" @close="deleteDialog.show = false" :dialog="deleteDialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __('client_delete_prompt') }}
            </div>
            <DialogContent>
                <!-- <DialogContentHeader :text="__t('files-document').toUpperCase() + ':'" /> -->
                <DialogContentItem :text="__('client_delete_hint')" :icon="'verified'" />
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="deleteDialog.show = false" :disabled="deleteDialog.working" />
            <VFButton :text="__('client_delete')" :icon="'delete'" warning @clicked="deleteCustomer" :working="deleteDialog.working" />
        </template>
    </PopupV1>

    <!-- Preview popup -->
    <PreviewPopupV1 v-if="previewVisible" :params="previewParams" @close="previewVisible = false" />

    <!-- Reminder dialog -->
    <ReminderDialog v-if="reminderDialogVisible"
        :params="reminderDialogParams"
        :client="client"
        @close="reminderDialogVisible = false"
        @reload="reloadClient"
    />

    <!-- Reset dialog -->
    <ResetDialog v-if="resetDialogVisible"
        :params="resetDialogParams"
        @close="resetDialogVisible = false"
        @reload="reloadClient"
    />

    <!-- Resend dialog -->
    <ResendDialog v-if="resendDialogVisible"
        :params="resendDialogParams"
        @close="resendDialogVisible = false"
        @resend="onResendForm"
    />

    <!-- Lookup dialog -->
    <LookupDialog v-if="lookupDialogVisible"
        :params="lookupDialogParams"
        @close="lookupDialogVisible = false"
        @reload="reloadClient"
    />

    <!-- Continuous Screening dialog -->
    <ContinuousScreeningDialog v-if="cscreenDialogVisible"
        :params="cscreenDialogParams"
        @close="cscreenDialogVisible = false"
        @reload="reloadClient"
    />

    <!-- Reset report dialog -->
    <PopupV1 v-if="resetReportDialog.show" :header="__t('report-reset')" @close="resetReportDialog.show = false" :dialog="resetReportDialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('verified-reset-hint') }}
            </div>
            <DialogContent>
                <DialogContentHeader :text="__t('files-document').toUpperCase() + ':'" />
                <DialogContentItem :text="__t('report-final') + ' ' + client.name" :icon="'verified'" />
            </DialogContent>
            <div style="margin-top: 20px; color: var(--text-title);">
                {{ __t('verified-reset-archive') }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="resetReportDialog.show = false" :disabled="resetReportDialog.working" />
            <VFButton :text="__t('report-reset')" :icon="'delete'" warning @clicked="onResetReport" :working="resetReportDialog.working" />
        </template>
    </PopupV1>

    <HBRiskPopup v-if="hbRiskPopupVisible" :client="client" @close="hbRiskPopupVisible = false" @reload="reloadClient" />



    <!-- Signature result -->
    <PopupV1 v-if="signatureDialog.show" :header="__('signrights_result_popup_header')" @close="signatureDialog.show = false" :dialog="signatureDialog">
        <template v-slot:content>

            <!-- chosen signing group -->
            <fieldset style="border: 1px solid #ccc; border-radius: 4px;">
                <legend>{{ __('signrights_result_popup_signoptions') }}</legend>
                <div :style="'display: flex; align-items: center; ' + (i ? 'margin-top: 10px' : '')" v-for="(signer, i) in signatureDialog.form.checkout.option" :key="'s' + i">
                    <div style="background: #eee; padding: 15px; border-radius: 4px; flex-grow: 1" class="checkout-signer">
                        <div class="checkout-signer-left">
                            <div class="checkout-signer-name">
                                <label>{{ signer.firstName + ' ' + signer.lastName }}</label>
                            </div>
                            <div class="checkout-signer-email">
                                <label>{{ signer.dateOfBirth || __('signrights_result_popup_unknown_dob') }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!signatureDialog.form.filled" style="padding: 10px; background: #fae8c0; border-radius: 4px;">
                    {{ __('signrights_result_popup_notfilled') }}
                </div>
                <div v-if="signatureDialog.form.filled && (!signatureDialog.form.checkout.option || !signatureDialog.form.checkout.option.length)" style="padding: 10px; background: #fae8c0; border-radius: 4px;">
                    {{ __('signrights_result_popup_manualoption') }}
                </div>
            </fieldset>

            <!-- actual signers -->
            <fieldset style="border: 1px solid #ccc; border-radius: 4px; margin-top: 25px;">
                <legend>{{ __('signrights_result_popup_signers') }}</legend>
                    <div :style="'display: flex; align-items: center; ' + (i && signer.signature ? 'margin-top: 10px' : '')" v-for="(signer, i) in signatureDialog.form.signers" :key="'s' + i">
                        <div v-if="signer.signature" style="background: #eee; padding: 15px; border-radius: 4px; flex-grow: 1" class="checkout-signer">
                            <div class="checkout-signer-left">
                                <div class="checkout-signer-name">
                                    <label>{{ signer.signature.name }}</label>
                                </div>
                                <div class="checkout-signer-email">
                                    <label>{{ signer.signature.dateOfBirth || __('signrights_result_popup_unknown_dob') }}</label>
                                </div>
                            </div>
                            <div class="checkout-signer-right">
                                <div class="checkout-signer-sign">
                                    <vap-icon :icon="'sign'" />
                                </div>
                                <div class="checkout-signer-method" >
                                    <label>{{ signer.signature.method }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                <div v-if="!signatureDialog.form.signers || !signatureDialog.form.signers.length || !signatureDialog.form.signers[0].signature" style="padding: 10px; background: #fae8c0; border-radius: 4px;">
                    {{ __('signrights_result_popup_no_signers') }}
                </div>
            </fieldset>


            <fieldset v-if="signatureDialog.form.checkout.files.length > 0" style="border: 1px solid #ccc; border-radius: 4px; margin-top: 25px;">
                <legend>{{ __('signrights_result_popup_procura') }}</legend>
                <div v-for="(file, i) in signatureDialog.form.checkout.files" :key="'fd'+i" style="display: flex">
                    <div style="background: #eee; padding: 15px; border-radius: 4px; flex-grow: 1; align-items: center;" class="checkout-signer">
                        <div class="checkout-signer-left">
                            <div class="checkout-signer-name">
                                <label>{{ file.name }}</label>
                            </div>
                        </div>
                        <div class="checkout-signer-right">
                            <div class="checkout-signer-email">
                                <vap-icon :icon="'file-sent'" action @clicked="onSignatureAttachementClicked(file, signatureDialog.form)" />
                            </div>
                        </div>
                    </div>
                </div>
            </fieldset>

            <div v-if="signatureDialog.shouldConfirm && !signatureDialog.form.checkout.confirmedUser" style="margin-top: 15px;">
                <VFCheckbox newDesign v-model="signatureDialog.confirmModel"
                    :text="__('signrights_result_manual_confirm_check')"
                    :disabled="signatureDialog.working"
                />
            </div>
            <div v-if="signatureDialog.form.checkout.confirmedUser" style="margin-top: 15px;">
                {{ __('signrights_result_manual_confirmed') + ' ' + getEmployeeName(signatureDialog.form.checkout.confirmedUser) }}
            </div>
            
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="signatureDialog.show = false" :disabled="signatureDialog.working" />
            <VFButton v-if="signatureDialog.shouldConfirm && !signatureDialog.form.checkout.confirmedUser"
                :icon="'check'"
                :text="__('signrights_result_manual_confirm')"
                :disabled="!signatureDialog.confirmModel"
                :working="signatureDialog.working"
                @clicked="onConfirmSignature"
            />
        </template>
    </PopupV1>

    <EditClientDialog v-if="editClientDialogVisible" :client="client" @close="editClientDialogVisible = false" @reload="reloadClient" />
    <EditResponsibleDialog v-if="editResponsibleVisible" :client="client" @close="editResponsibleVisible = false" @reload="reloadClient" />

    <ExportClientDialog v-if="exportDialogVisible" @close="exportDialogVisible = false" />

    <FormPopup v-if="formPopup.show" :clientId="client._id" :initialFormKey="formPopup.formKey" @close="formPopup.show = false" @reload="reloadClient" />

    <!-- Debug dialog -->
    <PopupV1 v-if="debugDialog.show" :header="'Debug'" @close="debugDialog.show = false" :dialog="debugDialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ 'Trigger client update from envelope data' }}
            </div>
            <vap-group>
                <vap-label desc :text="'Envelope ID'" />
                <vap-input v-model="debugDialog.envelopeId" />
            </vap-group>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="debugDialog.show = false" :disabled="debugDialog.working" />
            <VFButton :text="'Update status'" :icon="'refresh'" @clicked="onDebugUpdateStatus" :working="debugDialog.working" />
            <VFButton :text="'Go'" :icon="'bug'" @clicked="onDebugFormEnvelopeUpdate" :working="debugDialog.working" :disabled="debugDialog.envelopeId.length < 6" />
        </template>
    </PopupV1>

    <AdverseMediaDialog isCompany v-if="amsVisible" :params="amsParams" @saved="reloadClient" @close="amsVisible = false" />
    <ResetAmsDialog isCompany v-if="resetAmsVisible" :params="amsParams" @reload="reloadClient" @close="resetAmsVisible = false" />

    <CreateReportDialog v-if="createReportDialog.show"
        :client="client"
        :flow="flow"
        :persons="persons"
        :triggers="triggers"
        :texts="texts"
        :params="reportParams"
        :fullscreen="$root.config.freport.popupFullscreen"
        @created="reloadClient"
        @reload="reloadClient"
        @close="createReportDialog.show = false"
    />

    <StoreDialog v-if="storeDialog.show" :client="client" @reload="reloadClient" @close="storeDialog.show = false" />

    <ForwardDialog v-if="forwardDialog.show"
        :client="client"
        :params="previewParams"
        @reload="reloadClient"
        @close="forwardDialog.show = false"
    />

    <ShareholderBookDialog v-if="bookDialogVisible"
        :params="bookDialogParams"
        :client="client"
        @reload="reloadClient"
        @close="bookDialogVisible = false"
    />

    <UploadFormDialog v-if="uploadFormDialogVisible"
        :client="client"
        :flow="flow"
        :params="uploadFormDialogParams"
        @reload="reloadClient"
        @close="uploadFormDialogVisible = false"
    />

    <DownloadAnalogDialog v-if="downloadAnalogDialog.show"
        :client="client"
        :params="downloadAnalogDialog"
        @reload="reloadClient"
        @close="downloadAnalogDialog.show = false"
        @analog="onAnalogForm"
    />

    <ConfirmAnalogDialog v-if="confirmAnalogDialog.show"
        :client="client"
        :flow="flow"
        :params="confirmAnalogDialog"
        @reload="reloadClient"
        @close="confirmAnalogDialog.show = false"
        @analog="onAnalogForm"
    />

    <WatcherDialog v-if="watcherDialog.show"
        :client="client"
        :params="watcherDialog"
        @reload="reloadClient"
        @close="watcherDialog.show = false"
    />

    <ValuePopup v-if="valuePopup.show"
        :client="client"
        :params="valuePopup.params"
        @reload="reloadClient"
        @close="valuePopup.show = false"
    />

    <TriggerUpdateDialog v-if="triggerUpdateDialog.show"
        :client="client"
        :params="triggerUpdateDialog"
        @reload="reloadClient"
        @close="triggerUpdateDialog.show = false"
    />

    <UploadFileDialog v-if="uploadFileDialog.show"
        :client="client"
        :flow="flow"
        :params="uploadFileDialog.params"
        @reload="reloadClient"
        @close="uploadFileDialog.show = false"
    />
    <DeleteFileDialog v-if="deleteFileDialog.show"
        :client="client"
        :params="deleteFileDialog.params"
        @reload="reloadClient"
        @close="deleteFileDialog.show = false"
    />

    <PageErrorMessage v-if="flowError" :message="'Failed to load flow: ' + client.flow" />

</div>
</template>
<script>
import log from '../log';
import v1api from '../v1helpers/v1api';
import v1flows from '../v1helpers/v1flows';
import v1risk from '../v1risk/v1risk';
import v1test from '../v1helpers/v1test';
import v1events from '../v1helpers/v1events';
import triggerHelper from '../v1flows/triggerHelper';
import previewHelper from '../v1files/previewHelper';
import v1reportHelper from '../v1helpers/v1reportHelper';

import PageHeader from '../v1ui/PageHeader.vue';

import OverviewV1 from '../v1client/OverviewV1.vue';
import PersonsV1 from '../v1client/PersonsV1.vue';
import ReportV1 from '../v1client/ReportV1.vue';

import TabCompany from '../components/customer/TabCompany.vue';
import TabOwnership from '../components/customer/TabOwnership.vue';

import PreviewPopupV1 from '../v1files/PreviewPopupV1.vue';
import ReminderDialog from '../v1ui/ReminderDialog.vue';
import ResetDialog from '../v1ui/ResetDialog.vue';
import ResendDialog from '../v1ui/ResendDialog.vue';
import EditClientDialog from '../v1client/EditClientDialog.vue';
import EditResponsibleDialog from '../v1client/EditResponsibleDialog.vue';
import LookupDialog from '../v1client/LookupDialog.vue';
import ContinuousScreeningDialog from '../v1monitor/MonitorDialog.vue';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';

import HBRiskPopup from '../v1custom/HBRiskPopup.vue';
import v2lib from '../v2lib';

import ExportClientDialog from '../v1ui/ExportClientDialog.vue';

import FormPopup from '../components/form/FormPopup.vue';

import AdverseMediaDialog from '../v1persons/AdverseMediaDialog.vue';
import ResetAmsDialog from '../v1persons/ResetAmsDialog.vue';
import CreateReportDialog from '../v1report/CreateReportDialog.vue';

import StoreDialog from '../v1report/StoreDialog.vue';
import ForwardDialog from '../v1report/ForwardDialog.vue';

import BarItem from '../v1ui/BarItem.vue';
import BarItemContainer from '../v1ui/BarItemContainer.vue';

import ShareholderBookDialog from '../v1client/ShareholderBookDialog.vue';
import UploadFormDialog from '../v1client/UploadFormDialog.vue';
import DownloadAnalogDialog from '../v1client/DownloadAnalogDialog.vue';
import ConfirmAnalogDialog from '../v1client/ConfirmAnalogDialog.vue';
import WatcherDialog from '../v1client/WatcherDialog.vue';

import ValuePopup from '../v1client/ValuePopup.vue';

import TriggerUpdateDialog from '../v1client/TriggerUpdateDialog.vue';

import UploadFileDialog from '../v1client/UploadFileDialog.vue';
import DeleteFileDialog from '../v1client/DeleteFileDialog.vue';

import PageErrorMessage from '../v1ui/PageErrorMessage.vue';

export default {
    name: 'ClientV1',
    components: {
        PageHeader,
        OverviewV1,
        PersonsV1,
        ReportV1,
        TabCompany,
        TabOwnership,
        PreviewPopupV1,
        ReminderDialog,
        ResetDialog,
        ResendDialog,
        EditClientDialog,
        EditResponsibleDialog,
        LookupDialog,
        ContinuousScreeningDialog,
        PopupV1,
        VFButton,
        VFCheckbox,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        HBRiskPopup,
        ExportClientDialog,
        FormPopup,
        AdverseMediaDialog,
        ResetAmsDialog,
        CreateReportDialog,
        StoreDialog,
        ForwardDialog,
        BarItem,
        BarItemContainer,
        ShareholderBookDialog,
        UploadFormDialog,
        DownloadAnalogDialog,
        ConfirmAnalogDialog,
        WatcherDialog,
        ValuePopup,
        TriggerUpdateDialog,
        UploadFileDialog,
        DeleteFileDialog,
        PageErrorMessage,
    },
    data() {
        return {
            loading: true,
            flowError: false,
            scrolled: false,
            client: {},
            flow: {},
            tabModel: {},

            projectId: '',

            persons: [],
            counts: {},
            risk: {},
            events: [],

            triggers: {},
            texts: {},

            previewVisible: false,
            previewParams: null,

            reminderDialogVisible: false,
            reminderDialogParams: {},

            resetDialogVisible: false,
            resetDialogParams: {},

            resendDialogVisible: false,
            resendDialogParams: {},

            lookupDialogVisible: false,
            lookupDialogParams: {},

            cscreenDialogVisible: false,
            cscreenDialogParams: {},

            bookDialogVisible: false,
            bookDialogParams: null,

            uploadFormDialogVisible: false,
            uploadFormDialogParams: {},

            downloadAnalogDialog: { show: false },
            confirmAnalogDialog: { show: false },

            triggerUpdateDialog: { show: false },

            uploadFileDialog: { show: false },
            deleteFileDialog: { show: false },

            valuePopup: { show: false, params: null },

            editClientDialogVisible: false,
            editResponsibleVisible: false,

            resetReportDialog: { show: false, working: false, status: '', attachments: [], confirmed: false },
            createReportDialog: { show: false },
            reportParams: { items: [], data: {}, riskOptions: {} },

            signatureDialog: { show: false, working: false, status: '', confirmedUser: '', shouldConfirm: false, confirmModel: false },

            deleteDialog: { show: false, working: false },

            watcherDialog: { show: false },

            storeDialog: { show: false, working: false },
            forwardDialog: { show: false, working: false },

            actions: [
                { icon: 'delete', action: 'delete' },
                { icon: 'help-o', action: 'help' }
            ],

            similarResponse: null,

            reportCreatedHelp: [{ icon: 'locked', key: 'rep_created_info' }],

            hbRiskPopupVisible: false,

            exportDialogVisible: false,

            formPopup: { show: false, formKey: '' },

            debugDialog: { show: false, working: false, envelopeId: '' },

            amsVisible: false,
            amsParams: null,
            resetAmsVisible: false,
        }
    },
    watch: {
        '$root.locale.key': {
            deep: false,
            handler(val) {
                // Update events
                this.events = v1events.getClientEvents(this.$root, this.flow, this.triggers, this.texts, this.client, this.persons);
            }
        },
        tabModel: {
            deep: false,
            handler(val) {
/*                 let tabKey = 'overview';
                for (const key in val) {
                    if (val[key]) tabKey = key;
                }
                let url = this.$route.path + '?id=' + this.$route.query.id;
                url += '&tab=' + tabKey;
                window.history.replaceState({}, null, url); */
            }
        }
    },
    methods: {

        async onConfirmSignature() {
            this.signatureDialog.working = true;

            await v1api.confirmFormSignature(this.client._id, this.signatureDialog.formKey, this.signatureDialog.form.envelopeId);

            const response = await v1api.getClient(this.client._id);
            this.reloadClient(response);

            this.signatureDialog.working = false;
            this.signatureDialog.show = false;
        },

        showAddFileDialog(params) {
            this.uploadFileDialog.params = params;
            this.uploadFileDialog.show = true;
        },

        showDeleteFileDialog(params) {
            this.deleteFileDialog.params = params;
            this.deleteFileDialog.show = true;
        },

        hasDateOfBirth() {
            if (this.client.person && this.client.person.dateOfBirth) return true;
            return false;
        },

        getDateOfBirth() {
            if (this.client.person && this.client.person.dateOfBirth) return this.client.person.dateOfBirth;
            return 'unknown';
        },

        hasCitizenship() {
            if (this.client.country) return true;
            return false;
        },

        getCitizenship() {
            if (this.client.country) return this.getCountryName(this.client.country);
            return 'unknown';
        },

        onActionX(action, archiveIndex, index) {
            console.log('action', action);
            console.log('archiveIndex', archiveIndex);
            console.log('index', index);
            
            if (action.action == 'preview') {

                this.previewParams = previewHelper.getArchivedFormParams(this.client, action.key, archiveIndex);
                console.log('preview params', this.previewParams);
                this.previewVisible = true;

            }

        },

        onAmsAction(action) {
            this.amsParams = { name: this.client.name, web: this.client.web, id: this.client._id, clientId: this.client._id };
            if (action.key == 'start') {
                this.amsVisible = true;
            }else if (action.key == 'reset') {
                this.resetAmsVisible = true;
            }
        },

        async onDebugFormEnvelopeUpdate() {
            this.debugDialog.working = true;
            const envelope = await this.getEnvelope(this.debugDialog.envelopeId);
            console.log('envelope', envelope.content);
            await v1test.testFormSubmit(this.client._id, envelope.content);
            this.debugDialog.working = false;
            this.debugDialog.show = false;
        },

        async onDebugUpdateStatus() {
            this.debugDialog.working = true;

            await v1api.getClientStatusTEST(this.client._id);

            const response = await v1api.getClient(this.client._id);
            this.reloadClient(response);

            this.debugDialog.working = false;
            this.debugDialog.show = false;
        },

        showLookupDialog() {
            this.lookupDialogParams.clientId = this.client._id;
            this.lookupDialogParams.name = this.client.name;
            this.lookupDialogParams.source = this.client.source || 'unknown';
            this.lookupDialogParams.client = this.client;
            this.lookupDialogVisible = true;
        },

        showCScreenDialog() {
            this.cscreenDialogParams.mode = 'client';
            this.cscreenDialogParams.clientId = this.client._id;
            this.cscreenDialogParams.name = this.client.name;
            this.cscreenDialogParams.active = this.client.cscreen;
            this.cscreenDialogVisible = true;
        },

        onSignatureClicked(params) {
            this.signatureDialog.shouldConfirm = params.step.checkoutConfirm || false;
            this.signatureDialog.confirmModel = false;
            this.signatureDialog.form = params.form;
            this.signatureDialog.formKey = params.formKey;
            this.signatureDialog.show = true;
        },

        onSignatureAttachementClicked(file, form){
            this.previewParams = previewHelper.getFormParams2(form, file.name);

            //console.log('preview params', this.previewParams);

            this.previewVisible = true;
        },
        
        onCustomAction(action) {
            console.log('custom', action);

            if (action.action == 'custom-risk-popup') {

                this.hbRiskPopupVisible = true;

            }else if (action.action == 'org-refresh') {

                this.showLookupDialog();

            }else if (action.action == 'report-store') {

                this.storeDialog.show = true;

            }else if (action.action == 'forward') {

                this.showForwardDialog();

            }else if (action.action == 'book') {

                if (action.key == 'upload') {
                    this.bookDialogParams = { mode: 'upload' };
                    this.bookDialogVisible = true;
                }else if (action.key == 'confirm') {
                    this.bookDialogParams = { mode: 'confirm' };
                    this.bookDialogVisible = true;
                }else {
                    this.showShareholderBook();
                }

            }else if (action.action == 'form-resend') {

                this.showResendDialog(action);

            }else if (action.action == 'form-amend') {

                const extraData = {
                    loadSavedData: true
                };
                this.startForm(action.key, action, extraData);

            }else if (action.action == 'form-upload') {

                this.showUploadFormDialog(action, false);

            }else if (action.action == 'form-upload-delete') {

                this.showUploadFormDialog(action, true);

            }else if (action.action == 'form-download') {

                this.downloadAnalogDialog.formKey = action.key;
                this.downloadAnalogDialog.show = true;

            }else if (action.action == 'form-analog-confirm') {

                this.confirmAnalogDialog.formKey = action.key;
                this.confirmAnalogDialog.show = true;

            }else if (action.action == 'form-pdf-manual') {

                this.previewUploadedFile(action);

            }else if (action.action == 'form-pdf-analog') {

                this.previewUploadedFile(action);

            }else if (action.action == 'value-popup') {

                this.valuePopup.params = action.params;
                this.valuePopup.show = true;

            }else if (action.action == 'form-update') {

                this.triggerUpdateDialog.params = action;
                this.triggerUpdateDialog.show = true;

            }

        },

        previewUploadedFile(action) {
            let name = '';
            for (const i in this.client.files) {
                if (this.client.files[i].formKey == action.key) {
                    name = this.client.files[i].name;
                }
            }

            this.previewParams = previewHelper.getUploadedFileParams(this.client, name);
            this.previewVisible = true;
        },

        showUploadFormDialog(action, deleteMode) {
            console.log('show upload', action);
            this.uploadFormDialogParams = {
                deleteMode: deleteMode,
                formKey: action.key
            };
            this.uploadFormDialogVisible = true;
        },

        showShareholderBook() {
            if (this.client.kyc.book && this.client.kyc.book.provided) {
                this.previewParams = previewHelper.getFormParams2(this.client[this.client.kyc.formKey], this.client.kyc.book.names[0]);
            }
            this.previewVisible = true;
        },

        showResendDialog(action) {
            this.resendDialogParams = {
                action: action,
                envelopeId: this.client[action.key].envelopeId
            };
            this.resendDialogVisible = true;
        },

        onResendForm(shouldAbort) {
            const extraData = {
                resend: true,
                resetAfterResend: shouldAbort,
                envelopeId: this.resendDialogParams.envelopeId
            };
            this.startForm(this.resendDialogParams.action.key, this.resendDialogParams.action, extraData);
        },

        onAnalogForm(params) {
            const extraData = {
                resend: params.useExisting,
                resetAfterResend: params.abortSource,
                envelopeId: params.envelopeId,
                isAnalog: true,
            };
            this.startForm(params.formKey, null, extraData);
        },

        onAction(action) {
            if (action.action == 'help') {

            }else if (action.action == 'delete') {
                this.deleteDialog.show = true;
            }else if (action.action == 'edit') {
                this.editClientDialogVisible = true;
            }else if (action.action == 'export') {
                this.exportDialogVisible = true;
            }else if (action.action == 'debug') {
                this.debugDialog.show = true;
            }else if (action.action == 'cscreen') {
                this.showCScreenDialog();
            }else if (action.action == 'watch') {
                this.watcherDialog.show = true;
            }
        },

        goToTab(tab) {
            for (const key in this.tabModel) {
                this.tabModel[key] = false;
                if (key == tab) this.tabModel[key] = true;
            }
        },

        startForm(key, action, extraData) {

            //console.log('key', key);
            //console.log('action', action);
            //console.log('extraData', extraData);

            

            let existingData = {
                companyData: this.client.company,
                customer: this.client,
                persons: this.persons,
                client: this.client,
                counts: this.counts,
            };
            if (this.flow.isPerson) {
                existingData.person = this.persons[0];
            }
            if (this.client[key] && this.client[key].saved) {
                existingData.savedPages = this.client[key].saved;
            }

            if (extraData) existingData = { ...existingData, ...extraData };

            this.$root.formData = JSON.stringify(existingData);


            if (action && action.inPopup) {

                this.formPopup.formKey = key;
                this.formPopup.show = true;

            }else {
                this.$parent.navigate({ path: '/form/dev/' + key });
            }
        },

        editForm(key) {
            console.log('edit form', key);

            const envelopeId = this.client[key].envelopeId;
            const next = this.client[key].next;

            let url = this.$root.baseUrl;
            if (this.$root.baseUrl.includes('app.verified')) url += '/web/aml-portal/#';
            url += `/lform/${envelopeId}/${next.id}?`;
            url += 'lang=' + this.$root.locale.key;
            url += '&access_token=' + next.token;

            console.log('url', url);

            window.open(url, '_blank');
        },

        newForm(action) {
            const extraData = {
                resetForm: true
            };
            this.startForm(action.key, action, extraData);
        },

        viewForm(key) {
            const data = {
                viewOnly: true,
                viewEnvelopeId: this.client[key].envelopeId,
                viewRole: 'viewer'
            };
            this.startForm(key, {}, data);
        },

        previewFile(type) {
            if (type == 'org') {
                this.previewParams = previewHelper.getOrgReportParams(this.client);
            }else if (type == 'report') {
                this.previewParams = previewHelper.getFinalReportParams(this.$root, this.client);
                this.previewParams.recreateData = { params: { items: [], data: {}, riskOptions: {} }, client: this.client, flow: this.flow, persons: this.persons, triggers: this.triggers, texts: this.texts };
                v1reportHelper.loadReportData(this.$root, this.previewParams.recreateData.params, this.client, this.flow, this.persons, false);
            }else {
                this.previewParams = previewHelper.getFormParams(this.client, type);
            }
            console.log('preview params', this.previewParams);
            this.previewVisible = true;
        },

        previewExternalFile(params) {
            
            console.log('params', params);

            this.previewParams = previewHelper.getExternalFileParams(params.envelopeId, params.name);

            console.log('preview params', this.previewParams);
            this.previewVisible = true;
        },

        showForwardDialog() {
            this.previewParams = previewHelper.getFinalReportParams(this.$root, this.client);
            this.previewParams.recreateData = { params: { items: [], data: {}, riskOptions: {} }, client: this.client, flow: this.flow, persons: this.persons, triggers: this.triggers, texts: this.texts };
            v1reportHelper.loadReportData(this.$root, this.previewParams.recreateData.params, this.client, this.flow, this.persons, false);
            this.forwardDialog.show = true;
        },

        previewArchivedReport(report) {
            this.previewParams = previewHelper.getArchivedReportParams(this.$root, this.client, report);
            console.log('preview params', this.previewParams);
            this.previewVisible = true;
        },

        customPreviewFile(params) {
            this.previewParams = params;
            this.previewVisible = true;
        },

        showFormReminderDialog(key) {
            this.reminderDialogParams = {
                envelopeId: this.client[key].envelopeId,
                formKey: key
            };
            this.reminderDialogVisible = true;
        },

        showResetDialog(key) {
            this.resetDialogParams = {
                clientId: this.client._id,
                formKey: key,
                envelopeId: this.client[key].envelopeId
            };
            this.resetDialogVisible = true;
        },

        showResetReportDialog() {
            this.resetReportDialog.show = true;
        },

        async onResetReport() {
            this.resetReportDialog.working = true;

            await v1api.resetReport(this.client._id);

            const response = await v1api.getClient(this.client._id);

            this.reloadClient(response);
            this.resetReportDialog.working = false;
            this.resetReportDialog.show = false;
        },

        showCreateReportDialog() {
            v1reportHelper.loadReportData(this.$root, this.reportParams, this.client, this.flow, this.persons, false);
            this.createReportDialog.show = true;
        },

        async deleteCustomer() {
            this.deleteDialog.working = true;
            
            await v1api.deleteClient(this.client._id);

            this.deleteDialog.show = false;
            this.deleteDialog.working = false;

            this.$parent.navigate({ path: '/v1clients' });
        },

        changeProject(projectId) {
            this.$router.replace({query: { id: this.$route.query.id,  projectId: projectId } });
            this.reloadClient(null);
        },

        reloadClient(response) {
            //console.log('reloadClient', response);

            if (response) {
                this.client = response.client;
                this.persons = response.persons;
                this.counts = response.counts;
            }

            if (this.$route.query.projectId) {
                this.projectId = this.$route.query.projectId;
            }

            let isWatching = false;
            if (this.client.watch) {
                for (const i in this.client.watch) {
                    if (this.client.watch[i] == this.$root.credentials.uid) isWatching = true;
                }
            }

            //console.log('PERSONS', this.persons);

            this.flow = v1flows.getFlow(this.$root, this.client.flow);

            if (!this.flow) {
                this.actions = [
                    { icon: 'delete', action: 'delete' },
                    { icon: 'edit', action: 'edit' },
                ];
                return;
            }
 
            // Set triggers
            triggerHelper.setTriggers(this.$root, this.triggers, this.client, this.persons, this.counts);
            triggerHelper.setTexts(this.$root, this.texts, this.client, this.counts);

            //console.log('triggers', this.triggers);

            // Get events
            this.events = v1events.getClientEvents(this.$root, this.flow, this.triggers, this.texts, this.client, this.persons);
            //console.log('EVENTS', this.events);

            if (this.$root.config.risk.autoEnabled) {
                const riskParams = v1risk.getDefaultParams();
                this.risk = v1risk.determineRisk(this.$root, riskParams, this.client, this.persons);
                console.log('risk', this.risk);
            }

            if (this.flow.isPerson) {
                this.actions = [
                    { icon: 'delete', action: 'delete' },
                    { icon: 'edit', action: 'edit' },
                    /* { icon: 'help-o', action: 'help' } */
                ];
            }else {
                this.actions = [
                    { icon: 'delete', action: 'delete' },
                    { icon: 'edit', action: 'edit' },
                    /* { icon: 'help-o', action: 'help' } */
                ];
            }
            if (this.$root.config.screening.companyMonitor) {
                this.actions.splice(1, 0, { icon: 'refresh', action: 'cscreen', highlight: this.client.cscreen });
            }
            if (this.$root.config.features.watchList) {
                this.actions.splice(1, 0, { icon: 'eye', action: 'watch', highlight: isWatching });
            }
            if (this.$root.debug.local) {
                this.actions.splice(1, 0, { icon: 'bug', action: 'debug' });
            }
            if (this.$root.config.features.exportClient) {
                this.actions.splice(1, 0, { icon: 'download', action: 'export' });
            }

            this.tabHack();
        },

        async tabHack() {
            let selectedKey = 'overview';
            for (const key in this.tabModel) {
                if (this.tabModel[key]) selectedKey = key;
                this.tabModel[key] = false;
            }
            await new Promise(r => setTimeout(r, 100));
            for (const key in this.tabModel) {
                if (key == selectedKey) this.tabModel[key] = true;
            }
        },

    },
    async created() {
        this.$parent.setLoading(true, this.__('loading_customer') + '...');

        // Load customer from database
        const response = await v1api.getClient(this.$route.query.id);
        this.client = response.client;
        log.v('Client: ID ' + this.client._id, this.client);

        //console.log('persons', response.persons);

        // Set the title
        this.$parent.setTitle(this.companyTitleCase(this.client.name));

        // Get flow information
        try {
            this.flow = v1flows.getFlow(this.$root, this.client.flow);
            v1flows.setTabModel(this, this.flow, this.tabModel, this.$route.query.tab || 'overview');
        }catch (ex) {
            console.error(ex);
            this.flowError = true;
            this.loading = false;
            this.$parent.setLoading(false);
            return;
        }

        this.reloadClient(response);

        // Hide loading spinner and show UI
        this.loading = false;
        this.$parent.setLoading(false);
    }
}
</script>
<style scoped>

.vclient-scrolled {
    padding-top: 90px;
} 

</style>