<template>
<div>

    <PropertyItem padded :header="'Basic properties'" :icon="'settings'">

        <DesignerGroup c12 v-if="input.type != 'helpt' && input.type != 'chk-i' && input.type != 'preview' && input.type != 'greet' && input.type != 'appm'">
            <DesignerLabel :text="'Description'" />
            <DesignerInput :transDialog="reference.allowTranslation" v-model="input.desc[$root.locale.key]" :placeholder="'Not set'" @before="onPropInput('text', 'input_desc', $event)" @trans="$emit('trans', input.desc)" />
        </DesignerGroup>

        <!-- Support for old help tekst -->
        <DesignerGroup c12 v-if="input.type == 'helpt' || input.type == 'chk-i'">
            <DesignerLabel :text="'Description'" />
            <DesignerSelector :text="getDescription(input)" :placeholder="'Not set'" :tooltip="__('fr_localized_text')" @clicked="$emit('locale', input.desc)" />
        </DesignerGroup>
        <!-- -------------------------- -->

        <DesignerGroup c4>
            <DesignerLabel :text="'Width'" />
            <DesignerDropdown v-model="input.width" :options="widthOptions" @before="onPropInput('drop', 'input_width', $event)" />
        </DesignerGroup>

        <DesignerGroup c8>
            <DesignerLabel :text="'Tooltip'" />
            <DesignerInput v-model="input.tooltip" :placeholder="'Not set'" @before="onPropInput('text', 'input_tooltip', $event)" />
        </DesignerGroup>

        <!-- Support for old help tekst -->
        <DesignerGroup c4 v-if="input.type == 'helpt'">
            <DesignerLabel :text="'Margin top'" />
            <DesignerInput number v-model="input.marginTop" :placeholder="'0'" />
        </DesignerGroup>
        <DesignerGroup c4 v-if="input.type == 'helpt'">
            <DesignerLabel :text="'Margin bottom'" />
            <DesignerInput number v-model="input.marginBottom" :placeholder="'0'" />
        </DesignerGroup>
        <!-- -------------------------- -->

<!--         <DesignerGroup c4 v-if="input.type != 'helpt' && input.type != 'chk-i' && input.type != 'preview'">
            <DesignerLabel :text="'Required for'" />
            <DesignerCheckbox v-model="input.required" :text="'Editor'" @before="onPropInput('check', 'required_editor', $event)" />
        </DesignerGroup>
        <DesignerGroup c4 v-if="input.type != 'helpt' && input.type != 'chk-i' && input.type != 'preview'">
            <DesignerLabel :text="'Required for'" />
            <DesignerCheckbox v-model="input.ownerReq" :text="'Owner'" @before="onPropInput('check', 'required_owner', $event)" />
        </DesignerGroup> -->

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';
import DesignerSelector from '../ui/inputs/DesignerSelector.vue';

export default {
    name: 'BasicInputProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
        DesignerSelector,
    },
    props: {
        input: { type: Object, default: null },
        reference: { type: Object, default: null },
    },
    data() {
        return {
            widthOptions: [
                { value: '12', display: '12/12' },
                { value: '9', display: '9/12' },
                { value: '8', display: '8/12' },
                { value: '6', display: '6/12' },
                { value: '5', display: '5/12' },
                { value: '4', display: '4/12' },
                { value: '3', display: '3/12' },
                { value: '2', display: '2/12' },
            ],
        }
    },
    methods: {

        getDescription(item) {
            let desc = item.desc[this.$root.locale.key];
            desc = desc.split('<p>').join('');
            desc = desc.split('</p>').join(' ');
            desc = desc.split('<strong>').join('');
            desc = desc.split('</strong>').join('');
            if (desc.length > 40) desc = desc.substring(0, 37) + '...';
            return desc;
        },

        onPropInput(type, key, value) {
            this.$emit('propinput', type, key, value);
        },

    }
}
</script>