<template>
<div class="aml-page">

    <vap-form-divider :left="55" :right="45">
        <template v-slot:left>

            <vap-label header2 :text="flow.title[$root.locale.key]" style="margin-bottom: 10px;" />

            <div class="v2o-subheader">
                <div class="v2o-status-icon" :class="[
                    {'v2o-status-completed': isFlowCompleted() }
                ]" />
                <div class="v2o-status-text">
                    {{ getFlowStatus() }}
                </div>
                <div style="margin-top: -8px;" v-if="$root.config.tags.clientEnabled">
                    <TagList :editable="$root.config.tags.editable" :client="client" @reload="$emit('reload', $event)" />
                </div>
            </div>

            <StepRenderer
                :client="client"
                :flow="flow"
                :triggers="triggers"
                :texts="texts"
                @lookup="$emit('lookup', $event)"
                @form="startForm"
                @editform="editForm"
                @newform="newForm"
                @preview="previewFile"
                @reminder="showReminder"
                @tab="goToTab"
                @reset="resetForm"
                @view="viewForm"
                @resetreport="resetReport"
                @createreport="createReport"
                @custom="customAction"
                @ams="onAmsAction"
                @signature="$emit('signature', $event)"
                @xaction="onActionX"
            />

            <div v-if="archiveOnLeft">
                <!-- Archived reports -->
                <vap-label header2 :text="__('previous_reports')" style="margin-top: 20px; margin-bottom: 10px;" />
                <ArchivedReportItem v-for="(report, i) in client.archive" :key="'ar' + i"
                    :report="report"
                    @clicked="onArchiveClicked(report)"
                />

                <div v-if="client.archive.length == 0" style="float: left; width: 100%; color: var(--text-weak); margin-top: 12px; margin-left: 10px; margin-bottom: 16px;">
                    <label>{{ __('ov_archive_empty') }}</label>
                </div>

                <ActionV1 v-if="$root.config.clients.archive && client.report.created"
                    :icon="'refresh'"
                    :text="__('ov_archive_new')"
                    :hint="''"
                    @clicked="archiveDialog.show = true"
                />
            </div>

        </template>
        <template v-slot:right>

            <!-- Risk -->
            <div class="flw100" style="margin-bottom: 20px;" v-if="$root.config.risk.autoEnabled">
                <vap-label header2 :text="'Risk classification'" style="margin-bottom: 10px;" />
                <div class="v2o-subheader">
                    <div style="margin-left: 1px; color: var(--text-weak); flex-grow: 100;">
                        {{ 'Automatic classification (1-9)' }}
                    </div>
                    <div style="margin-left: 5px; color: var(--text-weak); font-weight: 500;">
                        <span style="margin-right: 15px;">{{ 'risk' }}</span>
                        <span style="margin-right: 25px;">{{ 'ML' }}</span>
                        <span style="margin-right: 15px;">{{ 'TF' }}</span>
                    </div>
                </div>
                <RiskCard :risk="risk" />
            </div>

            <!-- Risk -->
            <div class="flw100" style="margin-bottom: 20px;" v-if="$root.config.projects.enabled">
                <vap-label header2 :text="'Projects'" style="margin-bottom: 10px;" />
                <div class="v2o-subheader">
                    <div style="margin-left: 1px; color: var(--text-weak); flex-grow: 100;">
                        {{ 'List of projects for this customer' }}
                    </div>
                </div>
                <ProjectList
                    :client="client"
                    :projectId="projectId"
                    @reload="$emit('reload', $event)"
                    @project="$emit('project', $event)"
                />
            </div>


            <!-- Events -->
            <vap-label header2 :text="__('events_last')" style="margin-top: 0px; margin-bottom: 10px;" />
            <div class="v2o-subheader" v-if="!$root.config.risk.autoEnabled">
                <div style="margin-left: 1px; color: var(--text-weak);">
                    {{ __t('clients-last-event') + ': ' }}
                </div>
                <div style="margin-left: 5px; color: var(--text-weak); font-weight: 500;">
                    {{ getLatestEvent() }}
                </div>
            </div>
            <div class="v2o-event-container">
                <vap-eventlog :events="events" :maxEvents="$root.config.clients.maxEvents" />
            </div>


            <!-- External files -->
            <div v-if="$root.config.features.externalFiles.enabled">
                <vap-label header2 :text="__t('files-external-header')" style="margin-top: 20px; margin-bottom: 10px;" />
                <div>
                    <ExternalFileList :client="client" @file="$emit('file', $event)" @deletefile="$emit('deletefile', $event)" @preview="$emit('previewex', $event)" />
                </div>
            </div>

            <div v-if="!archiveOnLeft">
                <!-- Archived reports -->
                <vap-label header2 :text="__('previous_reports')" style="margin-top: 20px; margin-bottom: 10px;" />
                <ArchivedReportItem v-for="(report, i) in client.archive" :key="'ar' + i"
                    :report="report"
                    @clicked="onArchiveClicked(report)"
                />

                <div v-if="client.archive.length == 0" style="float: left; width: 100%; color: var(--text-weak); margin-top: 12px; margin-left: 10px; margin-bottom: 16px;">
                    <label>{{ __('ov_archive_empty') }}</label>
                </div>

                <ActionV1 v-if="$root.config.clients.archive && client.report.created"
                    :icon="'refresh'"
                    :text="__('ov_archive_new')"
                    :hint="''"
                    @clicked="archiveDialog.show = true"
                />
            </div>

        </template>
    </vap-form-divider>

    <ArchiveDialog v-if="archiveDialog.show" :client="client" :persons="persons" :flow="flow" @close="archiveDialog.show = false" @reload="$emit('reload', $event)" />
    
</div>
</template>
<script>
import tools from '../tools';

import StepRenderer from '../v1flows/StepRenderer.vue';
import ArchivedReportItem from './ArchivedReportItem.vue';
import RiskCard from '../v1risk/RiskCard.vue';
import ActionV1 from '../v1ui/ActionV1.vue';
import ArchiveDialog from './ArchiveDialog.vue';

import ProjectList from '../v1projects/ProjectList.vue';

import TagList from '../v1ui/TagList.vue';

import ExternalFileList from './ExternalFileList.vue';

export default {
    name: 'OverviewV1',
    components: {
        StepRenderer,
        ArchivedReportItem,
        RiskCard,
        ActionV1,
        ArchiveDialog,
        ProjectList,
        TagList,
        ExternalFileList,
    },
    props: {
        client: { type: Object, default: null },
        persons: { type: Array, default: null },
        flow: { type: Object, default: null },
        triggers: { type: Object, default: null },
        texts: { type: Object, default: null },
        events: { type: Array, default: null },
        risk: { type: Object, default: null },
        projectId: { type: String, default: '' },
    },
    data() {
        return {
            archiveDialog: { show: false },
            archiveOnLeft: false,
        }
    },
    methods: {
        
        getFlowStatus() {
            for (const i in this.flow.statuses) {
                if (this.flow.statuses[i].key == this.client.status) return this.flow.statuses[i].text[this.$root.locale.key];
            }
            return 'Unknown status';
        },

        isFlowCompleted() {
            for (const i in this.flow.statuses) {
                if (this.flow.statuses[i].key == this.client.status) return this.flow.statuses[i].completed;
            }
            return false;
        },

        getLatestEvent() {
            if (this.events.length > 0) {
                return tools.timePassedString(this.events[this.events.length - 1].date, this.$root);
            }
            return 'No events for this client';
        },

        startForm(key, action) {
            this.$emit('form', key, action);
        },

        editForm(key) {
            this.$emit('editform', key);
        },

        newForm(action) {
            this.$emit('newform', action);
        },

        previewFile(key) {
            this.$emit('preview', key);
        },

        showReminder(key) {
            this.$emit('reminder', key);
        },

        goToTab(key) {
            this.$emit('tab', key);
        },

        resetForm(key) {
            this.$emit('reset', key);
        },

        viewForm(key) {
            this.$emit('view', key);
        },

        resetReport() {
            this.$emit('resetreport');
        },

        createReport() {
            this.$emit('createreport');
        },

        customAction(action) {
            this.$emit('custom', action);
        },

        onArchiveClicked(report) {
            this.$emit('archive', report);
        },

        onAmsAction(action) {
            this.$emit('ams', action);
        },

        onActionX(action, archiveIndex, index) {
            this.$emit('xaction', action, archiveIndex, index);
        },

    },
    created() {
        if (this.$root.config.risk.autoEnabled) this.archiveOnLeft = true;
    }
}
</script>
<style scoped>

.v2o-subheader {
    float: left;
    width: 100%;
    display: flex;
    margin-top: -8px;
    margin-bottom: 10px;
}

.v2o-status-icon {
    flex-shrink: 0;
    width: 16px; height: 16px;
    margin-top: 1px;
    margin-left: 1px;
    border-radius: 8px;
    border: solid 2px var(--success-background);
    box-sizing: border-box;
}

.v2o-status-completed {
    border: solid 2px var(--success-background);
    background-color: var(--success-background);
}

.v2o-status-text {
    flex-grow: 100;
    margin-left: 6px;
    font-weight: 500;
    color: var(--text-weak);
}

.v2o-event-container {
    float: left;
    width: 100%;
    padding: 0px 20px;
    padding-top: 20px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
}

</style>