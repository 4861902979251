<template>
<div class="vdt">
    <div class="flw100 vdt-items">
        <div class="flw100 vdt-item vdt-head">
            <div class="vdt-item-x" v-for="(column, i) in columns" :key="'vc' + i" :style="widths[i]">
                <label>{{ column.name }}</label>
            </div>
        </div>
        <div class="flw100 vdt-item" v-for="(row, j) in rows" :key="'vr' + j">
            <div class="vdt-item-x" v-for="(column, i) in columns" :key="'vrc' + i" :style="widths[i]">
                <label>{{ getValue(row, column) }}</label>
            </div>
        </div>  
    </div>
</div>
</template>
<script>

export default {
    name: 'DataTable',
    props: {
        columns: {
			type: Array,
			default: () => { return [] }
        },
        rows: {
			type: Array,
			default: () => { return [] }
        },
    },
    data() {
        return {
            widths: []
        }
    },
    watch: {
        columns: {
            deep: true,
            handler(val) {
                this.calculateWidths(val);
            }
        }
    },
    methods: {
        getValue(row, column) {
            return row[column.key];
        },
        calculateWidths(val) {
            let total = 0;
            for (const i in val) total += Number(val[i].width);
            const fac = 100 / total;
            this.widths = [];
            for (const i in val) {
                this.widths.push('width: ' + (Number(val[i].width * fac)) + '%;');
            }
        },
        updateRows() {
            this.$forceUpdate();
        }
    },
    async created() {
        this.calculateWidths(this.columns);
    }
}
</script>
<style>

.vdt-items {
    border-top: solid 1px var(--input-border);
    box-sizing: border-box;
}

.vdt-item {
    border: solid 1px var(--input-border);
    border-top: none;
    border-right: none;
    box-sizing: border-box;
}
.vdt-head label {
    font-weight: bold;
}
.vdt-item-x  {
    float: left;
    padding: 3px 5px;
    font-family: monospace, monospace;
    letter-spacing: -0.8px;
    border-right: solid 1px var(--input-border);
    box-sizing: border-box;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}

</style>
