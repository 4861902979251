<template>
    <div class="pdfv-base">

        <div class="pdfv-bg" :style="'opacity: ' + opacity + ';'" @click="startClose"/>

        <!-- Content -->
        <div class="pdfv-page">

            <!-- Side Menu -->
            <div class="pdfv-left" @click="startClose" v-if="!loading && !notReady">
                <div class="pdfv-menu" :style="'width: ' + width + 'px;'" @click.stop>

                    <div v-if="!fading">

                        <div class="pdfv-menu-head">
                            {{ __t('files-main-document') }}
                        </div>
                        <div class="pdfv-menu-doc" style="margin-bottom: 20px;" :class="{'pdfv-menu-doc-selected': selectedFile.name == mainFile.name }" @click="selectFile(mainFile)">
                            {{ mainFile.name }}
                        </div>

                        <div v-if="secondFile" class="pdfv-menu-head">
                            {{ __t('files-secondary-document') }}
                        </div>
                        <div v-if="secondFile" class="pdfv-menu-doc" style="margin-bottom: 20px;" :class="{'pdfv-menu-doc-selected': selectedFile.name == secondFile.name }" @click="selectFile(secondFile)">
                            {{ secondFile.name }}
                        </div>

                        <div class="pdfv-menu-head">
                            {{ __t('files-attachments') }}
                        </div>
                        <div v-if="!params.isPreview">
                            <div class="pdfv-menu-doc" v-for="(attachment, i) in attachments" :key="'pra' + i"
                            :class="{'pdfv-menu-doc-selected': selectedFile.name == attachment.name }" @click="selectFile(attachment)">
                                {{ attachment.name }}
                            </div>

                            <div class="pdfv-menu-noatc" v-if="attachments.length == 0">
                                {{ __t('files-attachments-empty') }}
                            </div>
                        </div>
                        <div v-if="params.isPreview" style="margin-left: 15px; margin-top: 5px; font-size: 14px; color: var(--text-weak);">
                            {{ __t('files-attachments-preview') }}
                        </div>

                        <div class="pdfv-menu-dlm" v-if="params.mergedDownload" @click="onDownloadMerged(false)">
                            <div class="pdfv-menu-dlm-icon">
                                <vap-icon :icon="'download'" :color="'var(--header-text)'" />
                            </div>
                            <div class="pdfv-menu-dlm-lbl">
                                {{ __('preview_download_merged') }}
                            </div>
                        </div>

                        <div class="pdfv-menu-dlm" v-if="params.mergedDownload && $root.config.freport.simpleEnabled" @click="onDownloadMerged(true)">
                            <div class="pdfv-menu-dlm-icon">
                                <vap-icon :icon="'download'" :color="'var(--header-text)'" />
                            </div>
                            <div class="pdfv-menu-dlm-lbl">
                                {{ 'Last ned forenklet rapport' }}
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <!-- Pages -->
            <div class="pdfv-right" :style="'opacity: ' + contentOpacity + ';'" @click="startClose">
                <div class="pdfv-pages" :style="'width: ' + pageWidth + 'px;'" @click.stop v-if="!isDownloadingMerged && !invalidFile && !loading">

                    <div class="px" v-for="(page, i) in pages" :key="'p' + i">
                        <div class="" :style="'width: ' + pageWidth + 'px; height: ' + pageHeight + 'px;'">
                            <canvas class="pp" :class="{'ppv': page.visible}" :id="'pc' + i" :width="pageWidth" :height="pageHeight" v-observe-visibility="visibilityChanged"></canvas>
                        </div>
                    </div>

                </div>
                <div class="pdfv-progress" v-if="isDownloadingMerged || loading">
                    <div class="pdfv-progress-content">
                        <div class="pdfv-progress-spinner">
                            <vap-spinner free :text="__('loading_document')" />
                        </div>
                    </div>
                </div>
                <div class="pdfv-progress" v-if="invalidFile && !notReady">
                    <div class="pdfv-invalid" @click.stop>
                        {{ __t('files-preview-error') }}<br>
                        <VFButton :icon="'download'" :text="__t('files-download')" style="margin-top: 30px;" @clicked="onDownloadClicked" />
                    </div>
                </div>
            </div>

        </div>
        
        <!-- Header -->
        <div class="pdfv-header">
            <div class="pdfv-title" v-if="selectedFile">
                {{ selectedFile.name }}
            </div>
<!--             <div class="pdfv-indicator">
                {{ currentPage + '/' + pageCount }}
            </div> -->
            <div class="pdfv-icon pdfv-download" @click="onDownloadClicked">
                <vap-icon :icon="'download'" :color="'var(--header-text)'" />
            </div>
            <div class="pdfv-icon pdfv-close" @click="onCloseClicked" v-if="!params.preventClose">
                <vap-icon :icon="'cancel'" :color="'var(--header-text)'" />
            </div>
        </div>

    </div>
</template>

<script>
import log from '../log';
import v2lib from '../v2lib';
import cache from '../cache';
import pdfTools from '../pdfTools';
import verified from '../verified';
import * as pdfjsLib from 'pdfjs-dist';
import downloadjs from 'downloadjs'
import v1reportHelper from '../v1helpers/v1reportHelper';

import VFButton from '../components/form/items/VFButton.vue';

export default {
    name: 'PreviewPopupV1',
    components: {
        VFButton,
    },
    props: {
        params: { type: Object, default: null }
    },
    data() {
        return {
            width: 0,
            opacity: '0',
            contentOpacity: '0',
            fading: true,

            previewData: null,
            pdfDocument: null,

            pages: [],
            pageWidth: 900,
            pageHeight: 1300,

            pageCount: 0,
            currentPage: 0,

            mainFile: null,
            secondFile: null,
            attachments: [],

            selectedFile: null,
            
            loading: true,
            notReady: false,

            isDownloadingMerged: false,

            invalidFile: false,
        }
    },
    methods: {

        //#region Open / Close ------------------------------------------------------------------------------------------------

        startShow() {
            //console.log('start show');
            this.fading = true;
            const self = this;
            setTimeout(function() {
                self.width = 400;
                self.opacity = '0.8';
                self.contentOpacity = '1';
            }, 10);
            setTimeout(function() {
                self.fading = false;
            }, 400);
        },

        startClose() {
            if (this.params.preventClose) return;
            //console.log('start close');
            this.width = 0;
            this.opacity = '0';
            this.contentOpacity = '0';
            this.fading = true;
            const self = this;
            setTimeout(function() {
                self.$emit('close')
                self.$root.enableScrolling(true);
            }, 500);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Loading -----------------------------------------------------------------------------------------------------

        async loadEnvelope(envelopeId) {

            const envelope = await v2lib.getEnvelope(envelopeId);
            console.log('envelope', envelope);

            const uid = envelope.content.documents[0].files.attachments[0].uid;
            //const uid = envelope.content.documents[0].files.document.uid;
            let url = await verified.getDownloadUrl(envelope.id, envelope.documentId, uid);
            let data = await verified.downloadFile(url);
            this.previewData = data;

            this.readRawData(this.previewData);

        },

        async processParams() {
            this.loading = true;

            console.log('PARAMS', this.params);

            if (this.params.isPreview) {

                this.params.userdata.pdfLanguage = this.$root.locale.key;
                const translations = { ...this.$root.translations, ...this.$root.v1translations };
                const response = await pdfTools.getPDF(this.$root, this.$root.config, this.params.templatePath, this.params.userdata, translations, true, []);

                this.mainFile = {
                    isPreview: true,
                    name: this.params.mainEnvelope.name,
                    previewData: response.data
                };

                for (const i in this.params.userdata.attachments) {
                    const attachment = {
                        url: '',
                        envelopeId: this.params.userdata.attachments[i].envelopeId,
                        name: this.params.userdata.attachments[i].name
                    };
                    this.attachments.push(attachment);
                }

            }else {

                let result = await v1reportHelper.loadPreviewEnvelope(this.$root, this.params);
                this.notReady = result.notReady;

                if (this.notReady) {

                    log.e('PDF viewer: Document not ready', result);

                    let count = 0; const maxAttampts = 5;
                    while (this.notReady && count < maxAttampts) {

                        cache.invalidateCache(this.params.mainEnvelope.id);

                        await new Promise(r => setTimeout(r, 2500));

                        log.e('PDF viewer: Trying again', result);
                        result = await v1reportHelper.loadPreviewEnvelope(this.$root, this.params);

                        this.notReady = result.notReady;

                        count += 1;
                    }

                }

                if (!this.notReady) {
                    log.s('PDF viewer: Envelope loaded', result);
                    this.mainFile = result.mainFile;
                    this.secondFile = result.secondFile;
                    this.attachments = result.attachments;
                }

            }

            
            console.log('mainFile', this.mainFile);
            console.log('attachments', this.attachments);
            if (this.params.autoAttachmentName) {
                let index = 0;
                for (const i in this.attachments) {
                    if (this.attachments[i].name == this.params.autoAttachmentName) index = i;
                }
                await this.selectFile(this.attachments[index]);
            }else{
                await this.selectFile(this.mainFile);
            }
            this.loading = false;
        },


        async selectFile(file) {
            this.selectedFile = file;
            if (file.isPreview) {
                this.previewData = file.previewData;
            }else if (this.params.isEditor) {
                console.log('yes', file.url);
                this.previewData = await verified.downloadFile(file.url);
            }else {
                this.previewData = await cache.getFile(file.envelopeId, file.name, file.url);
            }
            this.readRawData(this.previewData);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region PDF / Logic -------------------------------------------------------------------------------------------------

        visibilityChanged(isVisible, entry) {
            if (!this.pdfDocument) return;
            //console.log('visible = ' + isVisible, entry);
            const index = Number(entry.target.id.replace('pc', ''));
            //console.log('index', index);

            this.pages[index].visible = isVisible;

            const self = this;
            setTimeout(function() {
                self.onChanged(index);
            }, 500);

            
        },

        loadPage(index) {

        },

        onChanged(index) {
            this.goToPage(this.pages[index]);
        },

        loadInitialPages() {
            const initalPageCount = 2;
            let count = 0;
            for (const i in this.pages) {
                count += 1;
                if (count > initalPageCount) break;
                this.pages[i].visible = true;
                this.goToPage(this.pages[i]);
            }
        },

        readRawData(data) {
            //console.log(data);
            this.showPdf = true;
            this.loadPdf({data: Buffer.from(data, 'base64')});
        },

        loadPdf(data) {
            this.invalidFile = false;
            const self = this;

            pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';

            // Loading a document.
            var loadingTask = pdfjsLib.getDocument(data);
            loadingTask.promise.then(function(pdfDocument) {

                self.pdfDocument = pdfDocument;
                self.pageCount = pdfDocument.numPages || 1;

                while (self.pages.length > 1) {
                    self.pages.pop();
                }
                for (let i=1; i<self.pageCount; i++) {
                    self.pages.push({
                        index: i + 1,
                        visible: false
                    });
                }

                // Request a first page
                self.loadInitialPages();

            }).catch(function(reason) {
                console.error("Error: " + reason);
                self.invalidFile = true;
            });
        },

        goToPage(page) {
            //console.log('going to page ', page);
            const width = 900;
            return this.pdfDocument.getPage(page.index).then(function(pdfPage) {

                // Display page on the existing canvas with 100% scale.
                var canvas = document.getElementById('pc' + (page.index - 1));
                canvas.width = width;

                var scale = canvas.width / pdfPage.getViewport({scale: 1.0}).width;
                var viewport = pdfPage.getViewport({scale: scale});
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                //console.log('w = ' + canvas.width + ' h= ' + canvas.height);
                var ctx = canvas.getContext("2d");
                var renderTask = pdfPage.render({
                    canvasContext: ctx,
                    viewport: viewport,
                });
                return renderTask.promise;

            });
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Actions -----------------------------------------------------------------------------------------------------

        onCloseClicked() {
            this.startClose();
        },

        onDownloadClicked() {
            if (!this.invalidFile) {
                downloadjs("data:application/pdf;base64," + this.previewData, this.selectedFile.name + ".pdf", "application/pdf");
            }else {
                // Open download url
                window.open(this.selectedFile.url, '_blank').focus();
            }
        },

        async onDownloadMerged(simplified) {
            this.isDownloadingMerged = true;
            const downloadData = await v1reportHelper.getMergedReport(this.$root, this.params, simplified);
            downloadjs("data:application/pdf;base64," + downloadData, this.mainFile.name + ".pdf", "application/pdf");
            this.isDownloadingMerged = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        loadEmptyPages() {
            this.pages = [];
            for (let i=0; i<1; i++) {
                this.pages.push({
                    index: i + 1,
                    visible: false
                });
            }
        },

    },
    created() {
        this.$root.enableScrolling(false);
        this.startShow();

        this.loadEmptyPages();

        this.processParams();
    }
}
</script>
<style scoped>

.pdfv-base {
    position: fixed;
    top: 0px; left: 0px;
    bottom: 0px; right: 0px;
    z-index: 130;
}

.pdfv-bg {
    position: absolute;
    top: 0px; left: 0px;
    bottom: 0px; right: 0px;
    background-color: black;
    transition: opacity 0.5s ease-in-out;
}

.pdfv-page {
    display: flex;
    box-sizing: border-box;
}

.pdfv-left {
    flex-shrink: 0;
    width: 400px;
    height: 100vh;
    z-index: 13;
}

.pdfv-right {
    position: relative;
    flex-grow: 100;
    max-height: calc(100vh - 60px);
    margin-top: 60px;
    z-index: 13;
/*     pointer-events: none; */
    box-sizing: border-box;
    transition: opacity 0.5s ease-in-out;
    overflow: auto;
}

.pdfv-content {
    position: absolute;
    top: 60px; left: 0px;
    bottom: 0px; right: 0px;
    
}

.pdfv-pages {
    margin: 0 auto;
    padding-top: 20px;
}

.pdfv-header {
    position: absolute;
    top: 0px; left: 0px; right: 0px;
    height: 60px;
    background-color: var(--header);
    z-index: 99;
}

.pdfv-title {
    padding-top: 17px;
    padding-left: 20px;
    font-size: 20px;
    color: var(--header-text);
}

.pdfv-indicator {
    position: absolute;
    top: 0px;
    left: 400px; right: 0px;
    padding-top: 10px;
    text-align: center;
    font-size: 30px;
    color: var(--header-text);
}

.pdfv-icon {
    position: absolute;
    border-radius: 8px;
    border: solid 2px var(--header-text);
    opacity: 0.7;
    cursor: pointer;
}
.pdfv-icon:hover {
    opacity: 1;
}
.pdfv-close {
    top: 10px; right: 12px;
}
.pdfv-download {
    top: 10px; right: 62px;
}


.pdfv-menu {
    width: 100%;
    min-height: 300px;
    margin-top: 300px;
    padding-top: 10px;
    padding-bottom: 20px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: var(--header);
    transition: width 0.5s ease-in-out;
}

.pdfv-menu-head {
    padding-left: 10px;
    font-size: 16px;
    color: var(--header-text);
    opacity: 0.6;
}
.pdfv-menu-doc {
    padding: 5px 10px;
    font-size: 17px;
    color: var(--header-text);
    cursor: pointer;
}
.pdfv-menu-doc:hover {
    color: var(--header);
    background-color: var(--header-text);
}
.pdfv-menu-doc-selected {
    color: var(--header);
    background-color: var(--header-text);
    cursor: default;
}

.pdfv-menu-noatc {
    padding-top: 8px;
    padding-left: 16px;
    font-size: 14px;
    color: var(--header-text);
    opacity: 0.4;
}

.pdfv-menu-dlm {
    display: flex;
    height: 34px;
    margin: 0px 10px;
    margin-top: 20px;
    color: var(--header-text);
    border: solid 2px var(--header-text);
    fill: var(--header-text);
    cursor: pointer;
}
.pdfv-menu-dlm:hover {
    background-color: var(--header-hover);
}

.pdfv-menu-dlm-icon {
    margin-top: -1px;
    margin-left: 10px;
}

.pdfv-menu-dlm-lbl {
    padding-top: 6px;
    padding-left: 12px;
    font-size: 18px;
}

.pdfv-progress {
    padding-top: 200px;
}
.pdfv-progress-content {
    position: relative;
    width: 700px;
    height: 400px;
    margin: 0 auto;
    border-radius: 8px;
    background-color: var(--card-background);
}
.pdfv-progress-spinner {
    position: absolute;
    top: 100px;
    left: 50%;
}


.pdfv-invalid {
    position: relative;
    width: 700px;
    height: 400px;
    margin: 0 auto;
    padding-top: 120px;
    font-size: 17px;
    text-align: center;
    border-radius: 8px;
    background-color: var(--card-background);
    box-sizing: border-box;
}



.px {
    
}

.pp {
    border-radius: 8px;
    /* background-color: pink; */
    border: solid 1px gray;
}

.ppv {
    /* background-color: yellow; */
}


</style>