const flows = {

    getFlow(name, config) {
        switch (name) {
            case 'brreg': return this.getBrregFlow(config);
            case 'brregLookup': return this.getBrregLookupFlow(config);
            case 'manual': return this.getManualFlow(config);
            case 'personNO': return this.getPersonNOFlow(config);
            case 'personLookup': return this.getPersonLookupFlow(config);
            case 'snn-bank': return this.getSNNBankFlow(config);
            case 'snn-bank2': return this.getSNNBankFlow(config);
            case 'org-se-trap': return this.getSwedenFlow(config);
            case 'org-uk-chouse': return this.getUKFlow(config);
        }
    },

    getSNNBankFlow(config) {
        const flow = {
            key: 'brreg',
            tabOptions: {},
            tabModel: {},
            steps: []
        };

        flow.tabOptions['overview'] = { key: 'common_overview', icon: 'checklist' };
        flow.tabModel['overview'] = true;

        if (config.shareapp) {
            flow.tabOptions['shareapp'] = { key: 'shareapp_tab_title', icon: 'checklist' };
            flow.tabModel['shareapp'] = false;
        }
        
        flow.tabOptions['company'] = { key: 'company_company', icon: 'company' };
        flow.tabModel['company'] = false;

        if (config.ownership) {
            flow.tabOptions['ownership'] = { key: 'company_owner_structure', icon: 'orgchart' };
            flow.tabModel['ownership'] = false;
        }

        flow.tabOptions['persons'] = { key: 'common_persons', icon: 'people' };
        flow.tabModel['persons'] = false;

        flow.tabOptions['report'] = { key: 'company_final_report', icon: 'verified' };
        flow.tabModel['report'] = false;

        for (let i in config.steps) flow.steps.push(this.getStep(config.steps[i].key, i));
        return flow;
    },

    getBrregFlow(config) {
        const flow = {
            key: 'brreg',
            tabOptions: {},
            tabModel: {},
            steps: []
        };

        //console.log('config', config);

        flow.tabOptions['overview'] = { key: 'common_overview', icon: 'checklist' };
        flow.tabModel['overview'] = true;

        if (config.shareapp) {
            flow.tabOptions['shareapp'] = { key: 'shareapp_tab_title', icon: 'checklist' };
            flow.tabModel['shareapp'] = false;
        }
        
        flow.tabOptions['company'] = { key: 'company_company', icon: 'company' };
        flow.tabModel['company'] = false;

        if (config.ownership) {
            flow.tabOptions['ownership'] = { key: 'company_owner_structure', icon: 'orgchart' };
            flow.tabModel['ownership'] = false;
        }

        flow.tabOptions['persons'] = { key: 'common_persons', icon: 'people' };
        flow.tabModel['persons'] = false;

        flow.tabOptions['report'] = { key: 'company_final_report', icon: 'verified' };
        flow.tabModel['report'] = false;

        for (let i in config.steps) flow.steps.push(this.getStep(config.steps[i].key, i));
        return flow;
    },

    getSwedenFlow(config) {
        const flow = {
            key: 'trapets-se',
            tabOptions: {},
            tabModel: {},
            steps: []
        };

        console.log('config', config);

        flow.tabOptions['overview'] = { key: 'common_overview', icon: 'checklist' };
        flow.tabModel['overview'] = true;

        if (config.shareapp) {
            flow.tabOptions['shareapp'] = { key: 'shareapp_tab_title', icon: 'checklist' };
            flow.tabModel['shareapp'] = false;
        }
        
        flow.tabOptions['company'] = { key: 'company_company', icon: 'company' };
        flow.tabModel['company'] = false;

        flow.tabOptions['ownership'] = { key: 'company_owner_structure', icon: 'orgchart' };
        flow.tabModel['ownership'] = false;

        flow.tabOptions['persons'] = { key: 'common_persons', icon: 'people' };
        flow.tabModel['persons'] = false;

        flow.tabOptions['report'] = { key: 'company_final_report', icon: 'verified' };
        flow.tabModel['report'] = false;

        for (let i in config.steps) flow.steps.push(this.getStep(config.steps[i].key, i));
        return flow;
    },

    getUKFlow(config) {
        const flow = {
            key: 'org-uk-chouse',
            tabOptions: {},
            tabModel: {},
            steps: []
        };

        console.log('config', config);

        flow.tabOptions['overview'] = { key: 'common_overview', icon: 'checklist' };
        flow.tabModel['overview'] = true;

        if (config.shareapp) {
            flow.tabOptions['shareapp'] = { key: 'shareapp_tab_title', icon: 'checklist' };
            flow.tabModel['shareapp'] = false;
        }
        
        flow.tabOptions['company'] = { key: 'company_company', icon: 'company' };
        flow.tabModel['company'] = false;

        flow.tabOptions['ownership'] = { key: 'company_owner_structure', icon: 'orgchart' };
        flow.tabModel['ownership'] = false;

        flow.tabOptions['persons'] = { key: 'common_persons', icon: 'people' };
        flow.tabModel['persons'] = false;

/*         flow.tabOptions['report'] = { key: 'company_final_report', icon: 'verified' };
        flow.tabModel['report'] = false; */

        for (let i in config.steps) flow.steps.push(this.getStep(config.steps[i].key, i));
        return flow;
    },

    getBrregLookupFlow(config) {
        const flow = {
            key: 'brregLookup',
            tabOptions: {
                overview: { key: 'common_overview', icon: 'checklist' },
                company: { key: 'company_company', icon: 'company' },
                /* ownership: { key: 'company_ownership', icon: 'company' }, */
                persons: { key: 'common_persons', icon: 'people' },
                /* report: { key: 'company_final_report', icon: 'report' }, */
            },
            tabModel: {
                overview: true,
                company: false,
                /* ownership: false, */
                persons: false,
                report: false,
            },
            steps: []
        };
        for (let i in config.steps) flow.steps.push(this.getStep(config.steps[i].key, i));
        return flow;
    },

    getManualFlow(config) {
        const flow = {
            key: 'manual',
            tabOptions: {
                overview: { key: 'common_overview', icon: 'checklist' },
                company: { key: 'company_company', icon: 'company' },
                persons: { key: 'common_persons', icon: 'people' },
                report: { key: 'company_final_report', icon: 'report' },
            },
            tabModel: {
                overview: true,
                company: false,
                persons: false,
                report: false,
            },
            steps: []
        };
        for (let i in config.steps) flow.steps.push(this.getStep(config.steps[i].key, i));
        return flow;
    },

    getPersonNOFlow(config) {
        const flow = {
            key: 'person',
            tabOptions: {
                overview: { key: 'common_overview', icon: 'checklist' },
                report: { key: 'company_final_report', icon: 'report' },
            },
            tabModel: {
                overview: true,
                report: false,
            },
            steps: [],
            idcIndex: config.idcIndex || 0,
            allowEdit: config.allowEdit,
            selectIdc: config.selectIdc
        };
        for (let i in config.steps) flow.steps.push(this.getStep(config.steps[i].key, i));
        return flow;
    },

    getPersonLookupFlow(config) {
        const flow = {
            key: 'personLookup',
            tabOptions: {
                overview: { key: 'common_overview', icon: 'checklist' },
            },
            tabModel: {
                overview: true,
            },
            steps: [],
            allowEdit: config.allowEdit
        };
        for (let i in config.steps) flow.steps.push(this.getStep(config.steps[i].key, i));
        return flow;
    },

    getStep(key, index) {
        const step = {
            key: key,
            status: 0,
            number: (Number(index) + 1).toString()
        };

        return step;
    },

    getNextStat(flow, stat) {
        let match = false;
        for (let i in flow.steps) {
            if (match) {
                return flow.steps[i].statusPending;
            }
            if (flow.steps[i].statusPending == stat) {
                match = true;
            }
        }
        return 'unknown';
    },

    showStep(flow, stepKey, show) {
        for (let i in flow.steps) {
            if (flow.steps[i].key == stepKey) {
                flow.steps[i].hidden = !show;
            }
        }
    }

};
export default flows;