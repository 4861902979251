<template>
    <div class="slim-page">

        <vap-label :text="'Søk i alle personer'" header style="margin-bottom: 10px;" />
        <SearchBox ref="fltSear" @search="onSearch" :placeholder="__('clients_search')" style="margin-top: 10px; margin-bottom: 16px;" />

        <div v-if="didSearch" style="padding: 0px 10px;">
            <vap-label :text="'Søkeresultater: ' + roles.length" desc style="margin-top: 10px; margin-bottom: 6px;" />
        </div>

        <div v-if="roles.length > 0" style="padding: 0px 10px;">
            <vap-card noPadding v-for="(person, i) in roles" :key="i" style="margin-bottom: 10px;">
                <vap-item @clicked="showIndividual(person)">
                    <div class="comitm-base">
                        <div class="comitm-icon">
                            <vap-icon :icon="'person'" />
                        </div>
                        <div class="comitm-content">
                            <div class="comitm-header">
                                <label>{{ person.displayName }}</label>
                            </div>
                            <div class="comitm-info">
                                <label>{{ person.roleCount + ' roller' }}</label>
                            </div>
                        </div>
                    </div>
                </vap-item>
            </vap-card>
        </div>

    </div>
</template>

<script>
import api from '../portalapi';
import tools from '../tools';
import personhelper from '../helpers/personhelper';

import SearchBox from '../components/special/SearchBox';

export default {
    name: 'Search',
    components: {
        SearchBox
    },
    data() {
        return {
            searchString: '',
            didSearch: false,
            roles: []
        }
    },
    methods: {

        showIndividual(person) {
            this.$parent.navigate({ path: '/person', query: { id: encodeURIComponent(person.identity) } });
        },

        onSearch(str) {
            this.searchString = str;
            this.performSearch();
        },

        async performSearch() {
            const filters = [
                { field: 'name', op: 'search', value: this.searchString }
            ];
            const from = 0;
            const limit = 0;
            const sort = { date: 1 };
            const include = [];
            const counts = [];

            const response = await api.queryRoles(filters, from, limit, sort, include, counts);
            this.roles = [];

            for (const i in response.roles) {
                let match = false;
                const person = tools.decodePerson(response.roles[i].identity);
                for (const j in this.roles) {
                    if (personhelper.isSamePerson(person, this.roles[j])) {
                        match = true;
                        this.roles[j].roleCount += 1;
                    }
                }
                if (!match) {
                    this.roles.push({
                        identity: response.roles[i].identity,
                        firstName: personhelper.titleCase(person.firstName),
                        lastName: personhelper.titleCase(person.lastName),
                        dateOfBirth: person.dateOfBirth,
                        displayName: personhelper.titleCase(person.firstName) + ' ' + personhelper.titleCase(person.lastName),
                        roleCount: 1
                    });
                }
            }

            this.didSearch = true;
        }

    },
    async created() {

    }
}
</script>

<style scoped>

.comitm-base {
    display: flex;
    padding: 10px 10px;
}

.comitm-icon {
    flex-shrink: 0;
}
.comitm-content {
    flex-grow: 100;
    margin-left: 14px;
}

.comitm-header {
    font-size: 18px;
    color: var(--text-accent);
}
.comitm-info {
    font-size: 16px;
    color: var(--text-weak);
}

</style>