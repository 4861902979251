<template>
<div>
    <div class="aml-page" >

        <vap-form-divider :left="55" :right="45" stickyRight :stickyOffset="80">
            <template v-slot:left>
                <vap-label :text="title" header style="margin-bottom: 5px;" />

                <vap-card>
                    <div class="flw100 bat-items">
                        <div class="flw100 bat-item" v-for="(item, i) in items" :key="'c' + i">
                            <div class="bat-icon-c" v-if="i < currentIndex || isComplete">
                                <vap-icon :icon="'check'" :color="'var(--success-text)'" />
                            </div>
                            <div class="bat-icon-p" v-if="i > currentIndex || (i == currentIndex && !isRunning && !isComplete)">
                                <vap-icon :icon="'cancel'" :color="'var(--disabled-text)'" />
                            </div>
<!--                                 <div class="rem-icon">
                                <vap-checkbox v-model="customer.checked" :text="''" noValidation />
                            </div> -->
                            <div class="bat-prog" v-if="i == currentIndex && isRunning">
                                <vap-spinner iconMode />
                            </div>
                            <div class="bat-content">
                                <div class="bat-top">
                                    <label class="bat-strong">{{ $parent.onGetName(item) }}</label>
                                </div>
                                <div class="bat-bot">
                                    <label class="bat-accent">{{ $parent.onGetInfo1(item) }}</label>
                                    <label class="bat-weak">{{ $parent.onGetInfo2(item) }}</label>
                                </div>
                            </div>
                            <div class="bat-actions">
                                <vap-icon v-if="showDelete" action :icon="'cancel'" @clicked="showRemovePopup(item)" />
                            </div>
                        </div>
                    </div>
                </vap-card>


            </template>
            <template v-slot:right v-if="items.length > 0">
                <vap-label :text="'Status'" header style="margin-bottom: 5px;" />
                <vap-card>
                    
                    <div class="bat-current">
                        <label>{{ $parent.onGetName(items[currentIndex]) }}</label>
                    </div>
                    <div class="bat-current-stat">
                        <label>{{ currentStatus }}</label>
                    </div>

                    <div class="bat-prog-text">
                        <label>{{ currentIndex + 1 }}</label>
                        <label>/</label>
                        <label>{{ items.length }}</label>
                    </div>

                    <div class="bat-prog-base">
                        <div class="bat-prog-prog" :style="'right: ' + progressRight + '%;'" />
                    </div>

                    <div class="bat-loop-stat" :class="{'bat-loop-stat-error': isError}">
                        <label>{{ loopStatus }}</label>
                    </div>
                    
                    <vap-button-row alignCenter style="margin-top: 20px;">
                        <vap-button :text="'Start'" @clicked="startBatch" :disabled="isRunning || isComplete || isStopping || isError" />
                        <vap-button :text="'Pause'" @clicked="pauseBatch" :disabled="!isRunning || isComplete || isStopping || isError" />
                    </vap-button-row>

                    <div class="flw100 bat-loop-error" v-if="isError">
                        <label>{{ errorMessage }}</label>
                    </div>

                </vap-card>
            </template>
        </vap-form-divider>

        
    </div>

    <ve-popup v-if="removePopup.show" :header="'Fjerne?'" :width="300" @close="removePopup.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <label>{{ 'Er du sikker på at du vil fjerne ' + $parent.onGetName(removePopup.item) + ' fra listen?' }}</label><br><br>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_no')" uppercase @clicked="removePopup.show = false" />
                <vap-button :text="__('common_yes')" uppercase @clicked="removeItem" :working="removePopup.working" />
            </vap-button-row>
        </div>
    </ve-popup>

</div>
</template>
<script>
import log from '../../log';

export default {
    name: 'BatchView',
    props: {
        items: {
            type: Array,
            default: () => { return [] }
        },
        steps: {
            type: Number,
            default: 0
        },
        skip: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            default: 'Batch'
        },
        showDelete: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            progressRight: 50,
            isRunning: false,
            isStopping: false,
            isComplete: false,
            isError: false,
            currentIndex: 0,
            currentStatus: 'Venter...',
            loopStatus: 'Klar til start...',
            errorMessage: 'unknown error',
            removePopup: { show: false, working: false, item: {} }
        }
    },
    watch: {
        items: {
            deep: false,
            handler(val) {
                this.setProgress();
            }
        }
    },
    methods: {

        showRemovePopup(item) {
            this.removePopup.item = item;
            this.removePopup.show = true;
        },

        async removeItem() {
            this.removePopup.working = true;
            await this.$parent.onRemoveItem(this.removePopup.item);
            this.removePopup.working = false;
            this.removePopup.show = false;
        },
        
        startBatch() {
            if (!this.isRunning && !this.isStopping && !this.isComplete) this.startBatchLoop(); 
        },

        pauseBatch() {
            this.loopStatus = 'Stopper...';
            this.isStopping = true;
        },

        setProgress() {
            const fac = 100 / this.items.length;
            const per = fac * (this.currentIndex);
            this.progressRight = 100 - per;
            if (this.isComplete) this.progressRight = 0;
        },

        async startBatchLoop() {
            this.isRunning = true;
            this.loopStatus = 'Kjører...';
            while (!this.isStopping && !this.isComplete && !this.isError) {

                if (!await this.$parent.onStart()) {
                    this.isRunning = false;
                    this.loopStatus = 'Venter på startsignal...';
                    return;
                }

                console.log('processing (' + this.currentIndex + ') ' + this.$parent.onGetName(this.items[this.currentIndex]));

                const data = [];

                for (let i=0; i<this.steps; i++) {
                    this.currentStatus = this.$parent.onGetStatus(i);
                    let result = {
                        ok: false,
                        errorMessage: 'unknown error'
                    };
                    try {
                        result = await this.$parent.onExecuteStep(i, this.items[this.currentIndex], data);
                    }catch (ex) {
                        result = {
                            ok: false,
                            errorMessage: ex.message
                        };
                        console.error(ex);
                    }
                    if (!result.ok) {
                        this.setError(result);
                        break;
                    }else {
                        data.push(result.data);
                    }
                }

                // Next item
                this.currentStatus = 'Venter...';
                if (this.currentIndex >= this.items.length - 1) {
                    this.isComplete = true;
                }else {
                    this.currentIndex += 1;
                }
                this.setProgress();
            }
            this.isStopping = false;
            this.isRunning = false;
            if (this.isComplete) {
                this.loopStatus = 'Ferdig!';
            }else if (this.isError) {
                this.loopStatus = 'Stoppet på grunn av feil!';
            }else {
                this.loopStatus = 'Klar til start...';
            }
        },

        setError(result) {
            this.errorMessage = result.errorMessage;
            this.isError = true;
        },

    },
    async created() {
        this.currentIndex = this.skip;
        this.setProgress();
    }
}
</script>
<style scoped>

.bat-current {
    text-align: center;
    font-size: 18px;
    color: var(--text-strong);
}
.bat-current-stat {
    margin-top: 5px;
    text-align: center;
    font-size: 16px;
    color: var(--primary);
}


.bat-prog-text {
    margin-top: 20px;
    text-align: center;
    font-size: 24px;
    color: var(--text-strong);
}
.bat-prog-base {
    position: relative;
    width: 100%;
    height: 40px;
    margin: 0 auto;
    margin-top: 5px;
    text-align: center;
    border-radius: 20px;
    border: solid 2px var(--accent);
    background-color: var(--text-disabled);
    overflow: hidden;
    box-sizing: border-box;
}
.bat-prog-prog {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0%;
    background-color: var(--accent);
    opacity: 0.4;
}

.bat-loop-stat {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: var(--text-strong);
}
.bat-loop-stat-error {
    color: var(--val-error);
}
.bat-loop-error {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    color: var(--val-error);
}




.bat-items {

}
.bat-item {
    display: flex;
    margin-bottom: 15px;
}
.bat-icon-p {
    flex-shrink: 0;
    width: 40px; height: 40px;
    padding-top: 3px;
    padding-left: 2px;
    border-radius: 50%;
    background-color: var(--disabled-background);
    box-sizing: border-box;
}
.bat-icon-c {
    flex-shrink: 0;
    width: 40px; height: 40px;
    padding-top: 3px;
    padding-left: 3px;
    border-radius: 50%;
    background-color: var(--success-background);
    box-sizing: border-box;
}
.bat-prog {
    position: relative;
    flex-shrink: 0;
    width: 40px; height: 40px;
}
.bat-content {
    flex-grow: 100;
    margin-left: 15px;
}
.bat-top {
    
}
.bat-bot {
    
}
.bat-strong {
    margin-right: 10px;
    color: var(--text-strong);
}
.bat-accent {
    margin-right: 10px;
    color: var(--text-accent);
}
.bat-weak {
    margin-right: 10px;
    color: var(--text-weak);
}


</style>