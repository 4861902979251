import log from '../../../../log';
import api from '../../../../portalapi';
import formHelper from '../../formHelper';
const formModuleHelper = {

    getEmptyConfig() {
        return {
            type: 'form',
            icon: 'form',
            formKeys: ['kyc00']
        };
    },

    async onFormCreated(root, reference, designer, module, pages, isOwner) {
        if (designer) return;

        const existingData = JSON.parse(root.formData);
        log.x('Form Module: On form created, existing data:', existingData);
        
        if (!existingData.client) {
            await this.oldVersion(root, reference, designer, module, pages, isOwner, existingData);
            return;
        }

        log.x('Form Module: Checking client', existingData.client);
        for (const i in module.formKeys) {
            log.x('Form Module: Checking for form:', module.formKeys[i]);

            if (existingData.client[module.formKeys[i]] && existingData.client[module.formKeys[i]].envelopeId) {

                const envelope = await root.getEnvelope(existingData.client[module.formKeys[i]].envelopeId);
                //console.log('envelope', envelope);

                // Prefill values
                for (const i in pages) {
                    for (const j in pages[i].cards) {
                        for (const k in pages[i].cards[j].inputs) {
                            if (pages[i].cards[j].inputs[k].oldFormKey) {
                                const value = this.getConnectedValue(pages[i].cards[j].inputs[k], envelope);
                                //console.log('value', value);
                                if (value) {
                                    pages[i].cards[j].inputs[k].model = value;
                                    //console.log('value set');
                                }
                            }
                        }
                    }
                }

            }

        }

    },

    getConnectedValue(input, envelope) {
        //console.log('get key', input.oldFormKey);
        const pages = envelope.userdata.formPages;

        for (const i in pages) {

            for (const j in pages[i].cards) {

                for (const k in pages[i].cards[j].inputs) {
                    const inp = pages[i].cards[j].inputs[k];
                    if (inp.modelKey == input.oldFormKey) {

                        if (inp.type == 'check' && input.type == 'check') {
                            let count = 0;
                            for (const x in inp.model) {
                                if (inp.model[x]) {
                                    let countY = 0;
                                    for (const y in input.model) {
                                        if (count == countY) {
                                            input.model[y] = true;
                                        }
                                        countY += 1;
                                    }
                                }
                                count += 1;
                            }
                            return input.model;
                        }else {
                            return inp.model;
                        }
                    }
                }

            }

        }


    },

    async oldVersion(root, reference, designer, module, pages, isOwner, existingData) {
        let kycEnvelope;
        let isPerson = false;
        

        if (existingData.oldSinglePerson && existingData.oldSinglePerson.displayName) {
            console.log('SINGLE', existingData.oldSinglePerson);

            if (existingData.oldSinglePerson.control.completed) {
                kycEnvelope = await root.getEnvelope(existingData.oldSinglePerson.control.envelopeId);
            }
            isPerson = true;

        }else if ( existingData.oldKycMetadata) {
            kycEnvelope = await root.getEnvelope(existingData.oldKycMetadata.envelopeId);
        }

        console.log('kycEnvelope', kycEnvelope);


        // Prefill values
        if (isOwner && kycEnvelope && !isPerson) {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    for (const k in pages[i].cards[j].inputs) {
                        if (pages[i].cards[j].inputs[k].oldFormKey) {
                            pages[i].cards[j].inputs[k].model = this.getOldKycConnectedValue(pages[i].cards[j].inputs[k], kycEnvelope);
                        }
                    }
                }
            }
        }

        // Prefill values
        if (isOwner && isPerson && existingData.oldSinglePerson && existingData.oldSinglePerson.displayName) {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    for (const k in pages[i].cards[j].inputs) {
                        if (pages[i].cards[j].inputs[k].oldFormKey) {
                            let value = this.getOldKycConnectedValue2(pages[i].cards[j].inputs[k], existingData.oldSinglePerson, kycEnvelope);
                            if (value) pages[i].cards[j].inputs[k].model = value;
                        }
                    }
                }
            }
        }
    },

    getOldKycConnectedValue2(input, singlePerson, kycEnvelope) {
        console.log('get key', input.oldFormKey);

        if (input.oldFormKey == 'personName') return singlePerson.displayName;
        if (input.oldFormKey == 'personNumber') return singlePerson.info.ssn;

        if (kycEnvelope && kycEnvelope.userdata && kycEnvelope.userdata.questions && kycEnvelope.userdata.questions.cards) {

            for (const i in kycEnvelope.userdata.questions.cards) {
                const card = kycEnvelope.userdata.questions.cards[i];
                //console.log('card', card);
                for (const j in card.inputs) {
                    const inp = card.inputs[j];
                    //console.log('input.oldFormKey', input.oldFormKey);
                    if (inp.ref == input.oldFormKey) {
                        if (inp.type == 'check' && input.type == 'check') {
                            let count = 0;
                            for (const x in inp.model) {
                                if (inp.model[x]) {
                                    let countY = 0;
                                    for (const y in input.model) {
                                        if (count == countY) {
                                            input.model[y] = true;
                                        }
                                        countY += 1;
                                    }
                                }
                                count += 1;
                            }
                            return input.model;
                        }else {
                            return inp.model;
                        }
                    }
                }
            }

        }

    },

    getOldKycConnectedValue(input, kycEnvelope) {
        console.log('get key', input.oldFormKey);

        for (const i in kycEnvelope.userdata.questions.cards) {
            const card = kycEnvelope.userdata.questions.cards[i];
            //console.log('card', card);

            for (const j in card.inputs) {
                const inp = card.inputs[j];
                //console.log('input.oldFormKey', input.oldFormKey);

                if (inp.ref == input.oldFormKey) {

                    if (inp.type == 'check' && input.type == 'check') {
                        let count = 0;
                        for (const x in inp.model) {
                            if (inp.model[x]) {
                                let countY = 0;
                                for (const y in input.model) {
                                    if (count == countY) {
                                        input.model[y] = true;
                                    }
                                    countY += 1;
                                }
                            }
                            count += 1;
                        }
                        return input.model;
                    }else {
                        return inp.model;
                    }

                }

            }

        }

    },

    async onSetUserdata(root, reference, module, envelope, userdata, isOwner, recipient) {

        const existingData = JSON.parse(root.formData);
        log.x('Form Module: On set userdata, existing data:', existingData);
        if (!existingData || !existingData.customer) return;

        console.log('config', root.config);

        // Support for V0 (Borea) - please remove
        if (!root.config.portal.useV1) {
            const updateData = {
                suit: {
                    envelopeId: envelope.id,
                    userId: root.credentials.uid
                }
            };
            await api.updateCustomer(existingData.customer._id, updateData);
        }
        

    },

    async onFormSubmitted(root, reference, module, nextRoleKey, envelope) {

        const existingData = JSON.parse(root.formData);
        log.x('Form Module: On form submitted, existing data:', existingData);
        
    },

};
export default formModuleHelper;