import log from '../../../../log';
import api from '../../../../portalapi';
import v1api from '../../../../v1helpers/v1api';
import formHelper from '../../formHelper';
const approveModuleHelper = {

    getEmptyConfig() {
        return {
            type: 'app',
            icon: 'check',
            approveCount: 1,
        };
    },

    async onFormCreated(root, reference, designer, module, pages, isOwner, userdata) {

        const existingData = JSON.parse(root.formData);
        log.x('Approve Module: On form created:', userdata);

        if (designer) return;

        if (isOwner) {

            // Prepare model
            reference.approveModel = {
                currentName: '',
                currentComment: '',
                currentYes: false,
                approvers: [],
            };

        }else {

            const response = await v1api.getClientFromForm(userdata.credentials.id);
            reference.clientData = {
                client: response.client,
                persons: response.persons,
                counts: response.counts,
            };

            // Load model from userdata
            const approveModel = userdata.approveModel;
            reference.approveModel = {
                currentName: '',
                currentComment: '',
                currentYes: false,
                approvers: approveModel ? approveModel.approvers || [] : [],
            };

            for (const i in pages) {
                for (const j in pages[i].cards) {
                    for (const k in pages[i].cards[j].inputs) {
                        const input = pages[i].cards[j].inputs[k];
                        if (input.logic && input.logic.link) {
                            console.log('input', input.logic);

                            for (const x in input.logic.link) {
                                if (input.logic.link[x].link == 'approver-name') {
                                    input.model = '';
                                }
                                if (input.logic.link[x].link == 'approver-comment') {
                                    input.model = '';
                                }
                                if (input.logic.link[x].link == 'approve-yes') {
                                    if (input.type == 'check2') {
                                        input.model = null;
                                    }
                                }
                            }

                        }
                    }
                }
            }

        }

    },

    async onSetUserdata(root, reference, module, envelope, userdata, isOwner, recipient) {

        const existingData = JSON.parse(root.formData);
        log.x('Approve Module: On set userdata, existing data:', existingData);

        const approveModel = {
            approvers: reference.approveModel.approvers || [],
        };
        
        if (reference.approveModel.currentYes) {
            
        }

        if (reference.assessmentData) {
            const id = isOwner ? existingData.customer._id : userdata.credentials.id;
            const clientData = {
                reportData: reference.assessmentData,
            };
            log.x('Approve Module: Updating client:', clientData);
            await v1api.updateClientPublic(id, clientData);
        }
        

        if (!isOwner) {

            if (!reference.approveModel.currentName) {
                console.log('recipient?', reference.recipient);
                reference.approveModel.currentName = reference.recipient.givenName + ' ' + reference.recipient.familyName;
            }

            approveModel.approvers.push({
                acted: true,
                approved: reference.approveModel.currentYes,
                name: reference.approveModel.currentName,
                comment: reference.approveModel.currentComment,
                date: new Date(),
            });

            userdata.approveModel = approveModel;

            let count = 0;
            let aborted = false;
            for (const i in userdata.approveModel.approvers) {
                if (userdata.approveModel.approvers[i].acted && userdata.approveModel.approvers[i].approved) {
                    count += 1;
                }else if (userdata.approveModel.approvers[i].acted && !userdata.approveModel.approvers[i].approved) {
                    aborted = true;
                }
            }
            if (count >= (Number(module.approveCount) || 1)) { // TODO submit anyway if aborted
                userdata.finalSubmit = true;
            }else {
                userdata.finalSubmit = false;
                const uEnvelope = JSON.parse(JSON.stringify(envelope.content));
                uEnvelope.userdata = userdata;
                await v1api.updateClientFromFormTEST(userdata.credentials.id, uEnvelope);
            }
        }

    },

    onLinkValue(root, reference, designer, module, link, value) {

        log.x('Approve Module: On link value (' + link.link + ')', value);

        if (link.link == 'approver-name') {
            
            reference.approveModel.currentName = value || '';

        }else if (link.link == 'approver-comment') {
            
            reference.approveModel.currentComment = value || '';

        }else if (link.link == 'approve-yes') {

            console.log('typeof value', typeof value);
            
            if (typeof value == 'object' && value != null) {

                console.log('link', link)

                reference.approveModel.currentYes = value[link.key] || false;
            }else {
                reference.approveModel.currentYes = value || false;
            }

        }

        log.x('Approve Module: After link value:', reference.approveModel);

    },

};
export default approveModuleHelper;