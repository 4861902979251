<template>
    <div>
        <FileReaderView :title="'Importer fil'" />
        <BatchView ref="batchview" :items="items" :steps="steps" :title="'Send påminnelser'" />
    </div>
</template>
<script>
import log from '../../log';
import api from '../../portalapi';
import tools from '../../tools';
import cache from '../../cache';
import locale from '../../locale';
import clienthelper from '../../helpers/clienthelper';
import kychelper from '../../helpers/kychelper';
import v2lib from '../../v2lib';
import verified from '../../verified';
import amlTools from '../../aml';

import downloadjs from 'downloadjs';

import FileReaderView from '../../components/special/FileReaderView';
import BatchView from '../../components/special/BatchView';

export default {
    components: {
        FileReaderView,
        BatchView
    },
    name: 'BatchKycReminder',
    data() {
        return {
            items: [],
            steps: 3,
            STEP_LOAD_CLIENT: 0,
            STEP_CHECK_STATUS: 1,
            STEP_SEND_REMINDER: 2,
            working: false,
            skip: 0,
            limit: 1,
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetColumns() {
/*             return [
                { key: 'Selskap', name: 'Selskap', width: 10 },
                { key: 'Orgnr', name: 'Orgnr', width: 10 },
                { key: 'Kontaktpersonfornavn', name: 'Navn', width: 10 },
                { key: 'Kontaktpersonetternavn', name: 'Etternavn', width: 10 },
                { key: 'Email', name: 'Email', width: 10 },
                { key: 'KundeID', name: 'KundeID', width: 10 },
            ] */
            return [
                { key: 'Selskapsnavn', name: 'Selskapsnavn', width: 10 },
            ]
/*             return [
                { key: 'Selskap', name: 'Selskap', width: 10 },
                { key: 'Orgnr', name: 'Orgnr', width: 10 },
                { key: 'Navn', name: 'Navn', width: 10 },
                { key: 'Fødselsdato', name: 'Fødselsdato', width: 10 },
                { key: 'Epost', name: 'Epost', width: 10 },
            ] */
        },

        onFileDataLoaded(list) {
            for (const i in list) {
                this.items.push({
                    companyName: list[i]['Selskapsnavn'],
                });
            }
            /* for (const i in list) {
                this.items.push({
                    companyName: list[i]['Selskap'].replace('"', ''),
                    orgnr: list[i]['Orgnr'],
                    name: list[i]['Navn'],
                    dateOfBirth: list[i]['Fødselsdato'],
                    oldEmail: list[i]['Epost'],
                });
            } */
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            return item.companyName;
        },

        onGetInfo1(item) {
            return item.orgnr;
        },

        onGetInfo2(item) {
            return '';
        },

        async onRemoveItem(item) {},

        async onStart() {
            return true;
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return 'Laster kunde';
                case this.STEP_CHECK_STATUS: return 'Sjekker status';
                case this.STEP_SEND_REMINDER: return 'Sender påminnelse';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return await this.loadClient2(item, data);
                case this.STEP_CHECK_STATUS: return await this.checkStatus2(item, data);
                case this.STEP_SEND_REMINDER: return await this.sendReminder2(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async loadClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { envelope: null } };

            const envelope = await this.$root.getEnvelope(item.envelopeId);
            console.log('envelope', envelope);

            result.data.envelope = envelope;
            result.ok = true;
            return result;
        },

        async checkStatus(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { doReminder: false, recipientId: '' } };

            const envelope = data[this.STEP_LOAD_CLIENT].envelope;

            let doReminder = true;
            if (envelope.content.completed) {
                doReminder = false;
            }

            for (const i in envelope.content.recipients) {

                console.log('recipient', envelope.content.recipients[i]);

                result.data.recipientId = envelope.content.recipients[i].id;

            }

            if (!result.data.recipientId) {
                console.log('NO RECIPIENT ID');
                doReminder = false;
            }

            result.data.doReminder = doReminder;
            result.ok = true;
            return result;
        },

        async sendReminder(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };
            
            const doReminder = data[this.STEP_CHECK_STATUS].doReminder;
            const recipientId = data[this.STEP_CHECK_STATUS].recipientId;
            console.log('doReminder', doReminder);
            console.log('recipientId', recipientId);

            if (doReminder) {

                const envelope = data[this.STEP_LOAD_CLIENT].envelope;
                console.log('envelope', envelope);

                const response = await verified.sendReminder(envelope.id, recipientId);

            }

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async loadClient2(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            const response = await api.getCustomerFromName(item.companyName);
            console.log('res', response);

            log.suppress = true;
            result.data.client = await clienthelper.loadClient(this.$root, response.customer);
            log.suppress = false;

            result.ok = true;
            return result;
        },

        async checkStatus2(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { doReminder: false } };

            const client = data[this.STEP_LOAD_CLIENT].client;
            console.log('client', client);

            let doReminder = false;

            if (client.kycMetadata.signed) {
                console.log('SKIP');
            }else if (client.kycMetadata.completed) {
                console.log('COMPLETED BUT NOT SIGNED');
            }else {
                doReminder = true;
            }

            result.data.doReminder = doReminder;
            result.ok = true;
            return result;
        },

        async sendReminder2(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };
            
            const client = data[this.STEP_LOAD_CLIENT].client;
            const doReminder = data[this.STEP_CHECK_STATUS].doReminder;

            if (doReminder) {
                console.log('sending reminder', client.kycMetadata.envelopeId);
                console.log('sending reminder', client.kycMetadata.editor.id);

                const response = await verified.sendReminder(client.kycMetadata.envelopeId, client.kycMetadata.editor.id);
                console.log('response', response);
            }else {
                console.log('SKIPPED');
            }

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async loadClient3(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            const response = await api.getCustomerFromOrgnr(item.orgnr);
            console.log('res', response);

            log.suppress = true;
            result.data.client = await clienthelper.loadClient(this.$root, response.customer);
            log.suppress = false;

            console.log('item', item);

            result.ok = true;
            return result;
        },

        async checkStatus3(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { doReminder: false, envelopeId: '', recipientId: '' } };

            const client = data[this.STEP_LOAD_CLIENT].client;
            console.log('client', client);

            let envelopeId = '';

            for (const i in client.persons) {
                if (client.persons[i].displayName == item.name) {
                    console.log('MATCH', client.persons[i]);
                    if (client.persons[i].control && client.persons[i].control.inviteSent) {
                        envelopeId = client.persons[i].control.envelopeId;
                        console.log('envelopeId', client.persons[i].control.envelopeId);
                        result.data.envelopeId = envelopeId;
                        result.data.recipientId = client.persons[i].control.recipientId;
                    }
                }
            }

            let doReminder = false;
            if (envelopeId) {
                const envelope = await this.$root.getEnvelope(envelopeId);
                console.log('envelope', envelope);
                if (envelope.content.completed === false) {
                    doReminder = true;
                }
            }


            result.data.doReminder = doReminder;
            result.ok = true;
            return result;
        },

        async sendReminder3(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };
            
            const doReminder = data[this.STEP_CHECK_STATUS].doReminder;
            const envelopeId = data[this.STEP_CHECK_STATUS].envelopeId;
            const recipientId = data[this.STEP_CHECK_STATUS].recipientId;

            if (doReminder) {

                console.log('envelopeId', envelopeId);
                console.log('recipientId', recipientId);

                const response = await verified.sendReminder(envelopeId, recipientId);
                console.log('response', response);

            }

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        async sendOutKYC(client, item) {
            const result = { ok: false, errorMessage: 'unknown error' };
            try {

                const kycIndex = 0;

                const userdata = {
                    amlData: client.amlData,
                    amlTimestamp: '',
                    brregData: client.brregData,
                    manualData: {},
                    ownerQuestion: {
                        noOwners: false,
                        hasOwners: false,
                        public: false
                    },
                    entityQuestion: {
                        manual: false,
                        file: false
                    },
                    roles: [],
                    rrhs: [],
                    entities: [],
                    peps: [],
                    includeAA: true
                };

                // Process AML data
                userdata.amlTimestamp = tools.dateString(new Date());
                userdata.roles = amlTools.getKeyRoleList(userdata.amlData);
                userdata.rrhs = amlTools.getRRHList(userdata.amlData);
                userdata.entities = amlTools.getEntityList(userdata.amlData);
                this.processAmlData(userdata);

                userdata.questions = {};

                 // Add receiver to userdata
                userdata.receiver = {
                    fname: item.firstName,
                    lname: item.lastName,
                    email: item.oldEmail,
                    language: 'nb_NO'
                };
                console.log('item', item);
                console.log('receiver', userdata.receiver);

                // Set mail-script parameters
                const emailData = {
                    firstName: userdata.receiver.fname,
                    lastName: userdata.receiver.lname,
                    senderName: this.$root.user.givenName + ' ' + this.$root.user.familyName,
                    senderEmail: this.$root.user.email,
                    customerName: userdata.brregData.name
                };
                const lang = userdata.receiver.language;
                userdata.mail = kychelper.getMailData(this.$root, kycIndex, emailData, lang, true);

                userdata.actions = {
                    dateSent: new Date(),
                    sender: {
                        firstName: this.$root.user.givenName,
                        lastName: this.$root.user.familyName,
                        email: this.$root.user.email,
                        company: this.$root.user.companyName
                    },
                    receiver: {
                        firstName: userdata.receiver.fname,
                        lastName: userdata.receiver.lname,
                        language: this.$root.locale.key,
                        signingMethod: 'email',
                        email: userdata.receiver.email,
                    }
                };

                const useNewFlow = this.$root.config.kycs[kycIndex].settings.usev2receipts || false;
                const descriptor = useNewFlow ? 'aml-portal-v1-kyc' : 'aml-portal-kyc';
                const flowName = useNewFlow ? 'simple-public-flow-custom-sender' : 'simple-public-flow-with-silent-parties';

                // Create envelope
                const envelope = await v2lib.createEnvelope(descriptor, flowName, false);

                // Now that we have the envelopeId, check if this kyc should use an in-portal landing page
                if (this.$root.config.kycs[kycIndex].settings.landingPage) {
                    userdata.mail.redirect = this.$root.baseUrl + '/kyclanding/' + envelope.id + '?act=' + envelope.bearerToken;
                }

                // Add KYC data to customer
                const customerData = client.customer;
                customerData.kyc = {
                    status: 1,
                    userId: this.$root.credentials.uid,
                    envelopeId: envelope.id,
                    sentDate: new Date()
                }

                // Get expiration day from config
                const expirationDay = kychelper.getExpirationDay(this.$root.config.kycs[kycIndex].settings.expirationDays);

                // Update customer in backend and get bearer token
                const params = { expires: expirationDay };
                const response = await api.sendOutKYC(customerData, params);
                userdata.credentials = {
                    id: customerData._id,
                    cid: this.$root.credentials.cid,
                    key: this.$root.credentials.key,
                    bearerToken: response.token
                }
                if (this.$root.credentials.testinc) userdata.credentials.testinc = this.$root.credentials.testinc;
                
                userdata.customer = customerData;

                // Set KYC config
                userdata.config = { key: this.$root.config.kycs[kycIndex].key, nextStat: 'r-rep-a' };

                // Set metadata
                const metadata = {
                    silentParties: [],
                    language: 'en_EN'
                };
                if (useNewFlow && this.$root.config.kycs[kycIndex].customSender && this.$root.config.kycs[kycIndex].customSender.firstName) {
                    metadata.sender = {
                        givenName: this.$root.config.kycs[kycIndex].customSender.firstName,
                        familyName: this.$root.config.kycs[kycIndex].customSender.lastName,
                        email: this.$root.config.kycs[kycIndex].customSender.email,
                    };
                    userdata.actions.sender.firstName = this.$root.config.kycs[kycIndex].customSender.firstName;
                    userdata.actions.sender.lastName = this.$root.config.kycs[kycIndex].customSender.lastName;
                    userdata.actions.sender.email = this.$root.config.kycs[kycIndex].customSender.email;
                }
                await v2lib.setMetadata(envelope, metadata);

                // Set template userdata and name
                const documentName = 'Egenerklæring ' + userdata.brregData.name + '.pdf';
                userdata.fileName = documentName;
                await v2lib.setUserData(envelope, userdata);
                await v2lib.setNameAndTags(envelope, documentName, ['egenerklæring']);
                await v2lib.setName(envelope, documentName);

                // Set editor recipient
                const recipient = {
                    givenName: userdata.receiver.fname,
                    familyName: userdata.receiver.lname,
                    language: this.$root.locale.key,
                    signingMethod: 'email',
                    email: userdata.receiver.email,
                    order: 1,
                    role: { action: "edit", label: "Fylle i skjemaet", name: "editor" }
                };
                await v2lib.addRecipient(envelope, recipient);

                // Publish the envelope
                await v2lib.publishEnvelope(envelope);

                result.ok = true;

            }catch (ex) {
                console.log(ex);
                result.errorMessage = ex.message;
            }
            return result;
        },

        processAmlData(userdata) {
            if (userdata.roles.length > 0) {
                for (let i in userdata.roles) {
                    if (userdata.roles[i].zip && userdata.roles[i].zip.length == 3) userdata.roles[i].zip = '0' + userdata.roles[i].zip;
                    this.$set(userdata.roles[i], 'phone', { prefix: '+47', number: '' });
                    this.$set(userdata.roles[i], 'edit', false);
                    this.$set(userdata.roles[i], 'pepQuestion', { yes: false, no: false });
                    for (let cc in this.$root.countriesJson) {
                        if (this.$root.countriesJson[cc].key == userdata.roles[i].country) {
                            userdata.roles[i].country = this.$root.countriesJson[cc].nb_NO;
                        }
                    }
                }
            }
            if (userdata.rrhs.length > 0) {
                userdata.ownerQuestion.hasOwners = true;
                for (let i in userdata.rrhs) {
                    if (userdata.rrhs[i].zip && userdata.rrhs[i].zip.length == 3) userdata.rrhs[i].zip = '0' + userdata.rrhs[i].zip;
                    this.$set(userdata.rrhs[i], 'phone', { prefix: '+47', number: '' });
                    this.$set(userdata.rrhs[i], 'edit', false);
                    this.$set(userdata.rrhs[i], 'pepQuestion', { yes: false, no: false });
                    for (let cc in this.$root.countriesJson) {
                        if (this.$root.countriesJson[cc].key == userdata.rrhs[i].country) {
                            userdata.rrhs[i].country = this.$root.countriesJson[cc].nb_NO;
                        }
                    }
                }
            }
            if (userdata.entities.length > 0) {
                userdata.entityQuestion.manual = true;
                for (let i in userdata.entities) {
                    this.$set(userdata.entities[i], 'edit', false);
                    if (userdata.entities[i].type == 'PERSON') {
                        console.log('ent', userdata.entities[i]);
                        if (userdata.entities[i].name.includes(',')) {
                            userdata.entities[i].firstName = userdata.entities[i].name.split(', ').slice(1).join(' ');
                            userdata.entities[i].lastName = userdata.entities[i].name.split(', ')[0];
                        }else {
                            userdata.entities[i].firstName = userdata.entities[i].name.split(' ').slice(1).join(' ');
                            userdata.entities[i].lastName = userdata.entities[i].name.split(' ')[0];
                        }
                    }
                }
            }
        },

    },
    async created() {
        // Load KYC translations and merge with main
        const defaults = locale.getDefaultKycTranslations();
        const tres = await api.getTranslations('kyc');
        this.kycTranslations = { ...defaults, ...tres.texts };
        this.$root.translations = { ...this.$root.translations, ...this.kycTranslations };
    }
}
</script>