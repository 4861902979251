<template>
    <div class="veas-h-base">

        

    </div>
</template>

<script>

export default {
    name: 've-actionswitch-help',
    props: {
        text: {
            type: String,
            default: '(action switch)'
        },
        show: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            helpClicked: false
        }
    },
    computed: {
        
    },
    watch: {
        
    },
    methods: {
        
    },
    async mounted() {

        

    }
}
</script>
<style scoped>

.veas-h-base {
    position: relative;
    float: left;
    width: 100%;
    min-height: 40px;
    background-color: #F6F6F6;
    border: solid 1px #E1E1E1;
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.veas {
    width: 100%;
}

.veas-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.veas-disabled .veas-help {
    cursor: not-allowed;
}

.veas-base {
    position: relative;
    float: left;
    height: 60px;
    background-color: #F6F6F6;
    border: solid 1px #E1E1E1;
    border-radius: 5px;
}

.veas-left {
    width: 50%;
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.veas-right {
    width: 50%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.veas-sh {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
}

.veas-help {
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer;
}

path {
    fill: #cccccc;
}

</style>