<template>
<div>
    <PageHeader>
        <template v-slot:content>
            <AlphabetSelector :letters="filterObject.letters" @letter="filtersOnLetterSelected" />
        </template>
    </PageHeader>
    <PageContent :scrolled="scrolled" :offset="104">
        <div class="aml-page">

            <SplitterV1 :left="28" :right="72">
                <template v-slot:left>

                    <vap-label :text="__('filters')" header2 style="margin-top: 10px; margin-bottom: 10px;" />

                    <SearchBox ref="fltSear" @search="filtersOnSearch" :placeholder="__('clients_search')" style="margin-top: 2px; margin-bottom: 16px;" />

                    <RemoveFilterButton v-if="filtersHasActive()" @remove="filtersOnRemoveAll" />

<!--                     <div>
                        <FilterControl empty :header="__('sort_last_updated')" />
                        <div style="float: left; width: 50%; margin-top: 10px;">
                            <SortDatePicker v-model="filterObject.sortDateFrom" noHighlight noValidation :hintKey="'datepicker_from'" :placeholder="__('datepicker_select_date')" outputISO @input="filtersOnDateChanged" />
                        </div>
                        <div style="float: left; width: 50%; margin-top: 10px;">
                            <SortDatePicker v-model="filterObject.sortDateTo" noHighlight noValidation :hintKey="'datepicker_to'" :placeholder="__('datepicker_select_date')" right outputISO @input="filtersOnDateChanged" />
                        </div>
                    </div> -->

                    <div v-if="filterObject.filters">
                        <div v-for="(filter, i) in filterObject.possibleFilters" :key="'cpf' + i">
                            <FilterControlV1
                                :params="filter"
                                :filters="filterObject.filters[filter.processedKey]"
                                :texts="filterObject.texts[filter.textKey]"
                                :activeValues="filterObject.activeFilters[filter.key]"
                                @filter="filtersOnChanged"
                            />
                        </div>
                    </div>

                </template>
                <template v-slot:right>

                    <div style="display: flex;">
                        <vap-label :text="__('common_persons')" header2 style="flex-grow: 100; margin-top: 10px; margin-bottom: 10px;" />
                        <SortSelector v-model="filterObject.sortModel" :options="filterObject.sortOptions" style="flex-shrink: 0; margin-top: 18px;" />
                    </div>

                    <div v-if="!loading" class="flw100">

                        <vap-card compactList noPadding v-for="(client, i) in persons" :key="'c' + i">
                            <ClientItem
                                :icon="'person'"
                                :name="companyTitleCase(client.name)"
                                :info="getClientString(client)"
                                :date="formatDate('datetime', client.dateCreated)"
                                :link="'/v1person?id=' + client._id"
                                :newItem="false"
                            />
                        </vap-card>

                    </div>

                    <CenteredSpinner v-if="loading" />
                    <EmptyIcon v-if="!loading && persons.length == 0" :icon="'input-check'" :text="__t('events-no-events')" />

                    <PageControl v-if="!loading"
                        :total="filterObject.totalPages"
                        :current="filterObject.currentPage"
                        @page="filtersOnNavigate"
                        style="margin-top: 20px;"
                    />

                </template>
            </SplitterV1>
        </div>
    </PageContent>
    <PageFooter :scrolled="scrolled" />
</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1filterHelper from '../v1helpers/v1filterHelper';
import v1personHelper from '../v1helpers/v1personHelper';

import PageHeader from '../v1ui/PageHeader.vue';
import PageContent from '../v1ui/PageContent.vue';
import PageFooter from '../v1ui/PageFooter.vue';
import SplitterV1 from '../v1ui/SplitterV1.vue';

import AlphabetSelector from '../v1ui/AlphabetSelector.vue';
import ClientItem from '../components/items/ClientItem.vue';

import FilterControlV1 from '../v1ui/FilterControlV1.vue';
import FilterControl from '../components/special/FilterControl';
import RemoveFilterButton from '../v1ui/RemoveFilterButton.vue';
import PageControl from '../components/special/PageControl';
import SearchBox from '../components/special/SearchBox';
import SortSelector from '../components/special/SortSelector';
import SortDatePicker from '../components/special/SortDatePicker';

import EmptyIcon from '../v1ui/EmptyIcon.vue';
import CenteredSpinner from '../v1ui/CenteredSpinner.vue';

import ActionV1 from '../v1ui/ActionV1.vue';

export default {
    name: 'RolesV1',
    components: {
        PageHeader,
        PageContent,
        PageFooter,
        SplitterV1,
        AlphabetSelector,
        ClientItem,
        FilterControlV1,
        FilterControl,
        RemoveFilterButton,
        PageControl,
        SearchBox,
        SortSelector,
        SortDatePicker,
        EmptyIcon,
        CenteredSpinner,
        ActionV1,
    },
    data() {
        return {
            loading: true,
            scrolled: false,

            persons: [],

            filterObject: {},
        }
    },
    methods: {

        //#region Filter Interface --------------------------------------------------------------------------------------------

        filtersOnNavigate(page) { v1filterHelper.filtersOnNavigate(this, this.filterObject, page); },

        filtersOnLetterSelected(letter) { v1filterHelper.filtersOnLetterSelected(this, this.filterObject, letter); },

        filtersOnSearch(str) { v1filterHelper.filtersOnSearch(this, this.filterObject, str); },

        filtersOnDateChanged() { v1filterHelper.filtersOnDateChanged(this, this.filterObject); },

        filtersOnChanged(filter, value) { v1filterHelper.filtersOnChanged(this, this.filterObject, filter, value); },

        filtersHasActive() { return v1filterHelper.filtersHasActive(this.filterObject); },

        filtersOnRemoveAll() { v1filterHelper.filtersOnRemoveAll(this, this.filterObject); },

        filtersOnQuery() {
            this.queryPersons();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        getClientString(person) {
            return v1personHelper.getClientString(this.$root, person, 5);
        },

        async queryPersons() {
            this.loading = true;

            const query = v1filterHelper.getQuery(this.$root, this.filterObject);
            query.counts.push('monitors');

            const response = await v1api.queryPersons(query.filters, query.from, query.limit, query.sort, query.include, query.counts);
            this.persons = response.persons;

            console.log('persons', this.persons);

            v1filterHelper.updateFilters(this.$root, this.filterObject, response);

            this.loading = false;
        }

    },
    async created() {
        v1filterHelper.loadPersonFilters(this.$root, this.filterObject);
        console.log('filterObject', this.filterObject);
        await this.queryPersons();
        this.loading = false;
    }
}
</script>
<style scoped>


</style>