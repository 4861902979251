import tools from '../../tools';
const formLocalData = {

    getLocalId() {
        return 'ø' + tools.getUniqueId();
    },

    isLocalId(id) {
        if (id.charAt(0) == 'ø') return true;
        return false;
    },

    hasEnvelope(envelopeId) {
        if (localStorage.getItem(envelopeId)) return true;
    },

    getEnvelope(envelopeId) {
        return JSON.parse(localStorage.getItem(envelopeId));
    },

    addEnvelope(envelope) {
        localStorage.setItem(envelope.id, JSON.stringify(envelope));
    }

};
export default formLocalData;