<template>
<div class="amlv1-header-msg" @click="onClick" :class="[
    {'amlv1-header-msg-w': $root.events.length > 0 },
    {'amlv1-header-msg-e': $root.eventPriority == 1 },
]">
    
    <div class="amlv1-header-msg-icon">
        <vap-icon :icon="'message'" :color="'ext'" />
    </div>

    <div class="amlv1-header-msg-text" v-if="$root.events.length > 0">
        {{ $root.events.length }}
    </div>

</div>
</template>

<script>
export default {
    name: 'HeaderMessages',
    methods: {

        onClick() {
            this.$parent.$parent.navigate({ path: '/v1events' });
        },
        
    },
}
</script>
<style scoped>

.amlv1-header-msg {
    display: flex;
    padding: 12px 14px;
    border-left: solid 1px var(--header-sep);
    cursor: pointer;
}
.amlv1-header-msg:hover {
    background-color: var(--header-hover);
}

.amlv1-header-msg-icon {
    fill: white;
}

.amlv1-header-msg-text {
    padding-top: 3px;
    padding-left: 5px;
    font-size: 24px;
    font-weight: 500;
    color: white;
}

.amlv1-header-msg-w {
    background-color: #EDC250;
}

.amlv1-header-msg-e {
    background-color: var(--error-background);
}

</style>