<template>
<div class="fev2-obj-x" :class="{'fev2-obj-first card-shadow': isFirst }">

    <div class="fev2-obj-main" v-if="!isFirst">
        <div class="fev2-obj-exp">
            <vap-icon :icon="expanded ? 'chev-d' : 'chev-r'" tiny action @clicked="onObjectClicked" />
        </div>
        <span class="fev2-obj-name" @click="onKeyClicked(null, true)">{{ name + ': ' }}</span>
        <span class="fev2-obj-weak" @click="onObjectClicked">{{ type }}</span>
        <div class="fev2-obj-exp2">
            <vap-icon :icon="'add'" tiny action @clicked="onAddClicked" />
            <vap-icon v-if="isArray" :icon="'chev-u'" tiny action @clicked="$emit('move', 'up', name)" style="margin-top: 4px;"  />
            <vap-icon v-if="isArray" :icon="'chev-d'" tiny action @clicked="$emit('move', 'down', name)" style="margin-top: 3px;" />
        </div>
    </div>

    <div class="fev2-obj" v-for="(obj, key, i) in subObjects" :key="'o' + key + i">
        <div v-if="expanded">
            <object-editor v-if="obj.type == 'object' || obj.type == 'array'"
                :mainObject="obj.subs"
                :name="obj.key"
                :type="obj.type"
                :level="level + 1"
                :isParentArray="obj.type == 'array'"
                :isArray="isParentArray"
                :initialCollapsed="initialCollapsed"
                @move="onMove"
                @update="onUpdate"
            />
            <div v-if="obj.type == 'string'">
                <span class="fev2-obj-name" @click="onKeyClicked(obj, false)">{{ obj.key + ': ' }}</span>
                <span class="fev2-obj-str" :class="{'fev2-obj-tra': isTranslation(obj.key) }" @click="onValueClicked(obj)">{{ '"' + obj.value + '"' }}</span>
            </div>
            <div v-if="obj.type == 'boolean'">
                <span class="fev2-obj-name" @click="onKeyClicked(obj, false)">{{ obj.key + ': ' }}</span>
                <span class="fev2-obj-boo" @click="onBooleanClicked(obj)">{{ obj.value }}</span>
            </div>
            <div v-if="obj.type == 'number'">
                <span class="fev2-obj-name" @click="onKeyClicked(obj, false)">{{ obj.key + ': ' }}</span>
                <span class="fev2-obj-num" @click="onValueClicked(obj)">{{ obj.value }}</span>
            </div>
        </div>
    </div>

    <div v-if="isFirst" class="flw100">
        <vap-icon :icon="'add'" tiny action @clicked="onAddClicked" />
    </div>

    <!-- Add Dialog -->
    <PopupV1 v-if="addDialog.show" :header="'Add value'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <VFRow style="margin-bottom: -10px;">
                <VFGroup c12>
                    <VFDropdown v-model="addDialog.model"
                        :options="addOptions"
                        :displayKey="'display'"
                        :listKey="'display'"
                        :outputKey="'type'"
                    />
                </VFGroup>
                <VFGroup c12 v-if="addDialog.model == 'object'">
                    <VFDropdown v-model="addDialog.objectModel"
                        :options="objectOptions"
                        :displayKey="'display'"
                        :listKey="'display'"
                        :outputKey="'type'"
                    />
                </VFGroup>
                <VFGroup c12 v-if="!addDialog.isArray">
                    <VFInput v-model="addDialog.key" :placeholder="'Key'" :id="'add'" @onreturn="onAddValue" />
                </VFGroup>
            </VFRow>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'OK'" :icon="'check'" @clicked="onAddValue" />
        </template>
    </PopupV1>

    <!-- Key Dialog -->
    <PopupV1 v-if="editKeyDialog.show" :header="'Edit key'" :width="700" @close="editKeyDialog.show = false" :dialog="editKeyDialog">
        <template v-slot:content>
            <VFRow style="margin-bottom: -10px;">
                <VFGroup c12 v-if="!editKeyDialog.isMain">
                    <VFInput v-model="editKeyDialog.obj.key" :placeholder="'Key'" :id="'key'" @onreturn="onEditKey" />
                </VFGroup>
                <VFGroup c12 v-if="editKeyDialog.isMain">
                    <VFInput v-model="editKeyDialog.editKey" :placeholder="'Key'" :id="'key'" @onreturn="onEditKey" />
                </VFGroup>
            </VFRow>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Cancel'" outline @clicked="editKeyDialog.show = false" />
            <VFButton :text="'Delete'" warning :icon="'delete'" @clicked="onDeleteKey" />
            <VFButton :text="'Copy'" outline :icon="'copy'" @clicked="onCopy" />
            <VFButton :text="'Paste'" outline :icon="'paste'" @clicked="onPaste" />
            <VFButton :text="'OK'" :icon="'check'" @clicked="onEditKey" />
        </template>
    </PopupV1>

    <!-- String Dialog -->
    <PopupV1 v-if="editStringDialog.show" :header="'Edit string'" @close="editStringDialog.show = false" :dialog="editStringDialog">
        <template v-slot:content>
            <VFRow style="margin-bottom: -10px;">
                <VFGroup c12>
                    <VFInput v-model="editStringDialog.obj.value" :placeholder="'Value'" :id="'edits'" @onreturn="onEditString" />
                </VFGroup>
            </VFRow>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'OK'" :icon="'check'" @clicked="onEditString" />
        </template>
    </PopupV1>

    <!-- Number Dialog -->
    <PopupV1 v-if="editNumberDialog.show" :header="'Edit number'" @close="editNumberDialog.show = false" :dialog="editNumberDialog">
        <template v-slot:content>
            <VFRow style="margin-bottom: -10px;">
                <VFGroup c12>
                    <VFInput v-model="editNumberDialog.obj.value" :placeholder="'Value'" :id="'edits'" @onreturn="onEditNumber" numeric />
                </VFGroup>
            </VFRow>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'OK'" :icon="'check'" @clicked="onEditNumber" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import log from '../log';
import flowEditHelper from './flowEditHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFInput from '../components/form/inputs/VFInput.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import VFDropdown from '../components/form/inputs/VFDropdown.vue';
import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';

export default {
    name: 'ObjectEditor',
    components: {
        PopupV1,
        VFInput,
        VFCheckbox,
        VFDropdown,
        VFRow,
        VFGroup,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
    },
    props: {
        name: { type: String, default: 'null' },
        type: { type: String, default: 'null' },
        level: { type: Number, default: 0 },
        isFirst: { type: Boolean, default: false },
        isArray: { type: Boolean, default: false },
        isParentArray: { type: Boolean, default: false },
        initialCollapsed: { type: Boolean, default: false },
        mainObject: { type: [Object, Array], default: null },
    },
    data() {
        return {
            expanded: true,
            subObjects: [],
            addDialog: { show: false, model: 'object', key: '', isArray: false, arrayIndex: 0, objectModel: 'empty' },
            editStringDialog: { show: false, obj: null },
            editNumberDialog: { show: false, obj: null },
            editKeyDialog: { show: false, obj: null, isMain: false, key: '', editKey: '' },
            addOptions: [
                { type: 'object', display: 'Object' },
                { type: 'array', display: 'Array' },
                { type: 'string', display: 'String' },
                { type: 'boolean', display: 'Boolean' },
                { type: 'number', display: 'Number' },
            ],
            objectOptions: [
                { type: 'empty', display: 'Empty object' },
                { type: 'status', display: 'Flow status' },
                { type: 'trigger', display: 'Trigger' },
                { type: 'event', display: 'Event' },
                { type: 'action', display: 'Action' },
                { type: 'statusf', display: 'Step status' },
                { type: 'locale', display: 'Translations' },
            ]
        }
    },
    methods: {

        async onCopy() {
            if (navigator.clipboard) {
                const cbData = {
                    fedata: true,
                    item: this.mainObject
                };
                await navigator.clipboard.writeText(JSON.stringify(cbData));
                log.x('Clipboard: Item added to clipboard', cbData);
            }
        },

        async onPaste() {
            try {
                if (navigator.clipboard) {
                    const text = await navigator.clipboard.readText();
                    const cbData = JSON.parse(text);
                    if (cbData.fedata) {
                        this.mainObject = cbData.item;
                    }
                }
                this.loadObject(true);
            }catch (ex) {
                log.x('Clipboard: No valid data in clipboard or not focused');
            }
        },

        isTranslation(key) {
            if (key == 'en_EN' || key == 'nb_NO' || key == 'sv_SE') return true;
            return false;
        },

        onObjectClicked() {
            this.expanded = !this.expanded;
        },

        onBooleanClicked(obj) {
            obj.value = !obj.value;
        },

        onAddClicked() {
            this.addDialog.key = '';

            this.addDialog.isArray = this.isParentArray;
            if (this.addDialog.isArray) {
                this.addDialog.arrayIndex = this.mainObject.length;
            }

            this.addDialog.show = true;

            if (!this.addDialog.isArray) {
                this.$nextTick(() => {
                    const element = document.getElementById('inpidadd');
                    element.focus();
                });
            }
        },

        async onAddValue() {
            let value = null;

            if (this.addDialog.model == 'object') {
                value = {};

                if (this.addDialog.objectModel != 'empty') {
                    value = flowEditHelper.getEmptyObject(this.addDialog.objectModel);
                }

            }else if (this.addDialog.model == 'array') {
                value = [];
            }else if (this.addDialog.model == 'string') {
                value = '';
            }else if (this.addDialog.model == 'boolean') {
                value = false;
            }else if (this.addDialog.model == 'number') {
                value = 0;
            }

            if (this.type == 'array') {

                //this.$emit('update', this.getObject(), value);

                console.log('mainObject', this.mainObject);

                this.mainObject.push(value);
                this.loadObject(true);


/*                 this.subObjects.push({
                    type: this.addDialog.model,
                    key: this.subObjects.length,
                    value: value
                }); */
            }else if (this.type == 'object' && this.addDialog.objectModel == 'locale') {

                console.log('mainObject', this.mainObject);
                console.log('value', value);

                this.mainObject[this.addDialog.key] = value;
                this.loadObject(true);

            }else {
                this.subObjects.push({
                    type: this.addDialog.model,
                    key: this.addDialog.key,
                    value: value
                });
            }
            
            this.addDialog.show = false;
        },

        onValueClicked(obj) {
            if (obj.type == 'string') {
                this.editStringDialog.obj = obj;
                this.editStringDialog.show = true;
            }else if (obj.type == 'number') {
                this.editNumberDialog.obj = obj;
                this.editNumberDialog.show = true;
            }
            this.$nextTick(() => {
                const element = document.getElementById('inpidedits');
                element.focus();
                element.select();
            });
        },

        onEditString() {
            this.editStringDialog.show = false;
            this.$emit('change');
        },

        onEditNumber() {
            this.editNumberDialog.obj.value = Number(this.editNumberDialog.obj.value);
            this.editNumberDialog.show = false;
            this.$emit('change');
        },

        onKeyClicked(obj, isMain) {
            this.editKeyDialog.obj = obj;
            this.editKeyDialog.isMain = isMain;
            if (isMain) {
                this.editKeyDialog.key = this.name;
                this.editKeyDialog.editKey = this.name;
            }
            this.editKeyDialog.show = true;

            this.$nextTick(() => {
                const element = document.getElementById('inpidkey');
                element.focus();
                element.select();
            });
        },

        async onEditKey() {
            if (this.editKeyDialog.isMain) {

                const parentObject = this.$parent.mainObject;

                parentObject[this.editKeyDialog.editKey] = this.mainObject;
                delete parentObject[this.editKeyDialog.key];

                await new Promise(r => setTimeout(r, 10));
                this.$parent.loadObject(true);

                this.editKeyDialog.show = false;
            }else {
                this.editKeyDialog.show = false;
            }
        },

        async onDeleteKey() {
            if (this.editKeyDialog.isMain) {
                const parentObject = this.$parent.mainObject;
                const deleteKey = this.editKeyDialog.key;

                if (Object.prototype.toString.call(parentObject) === '[object Array]') {

                    for (const key in parentObject) {
                        if (key == deleteKey) parentObject.splice(Number(key), 1);
                    }

                }else {
                    delete parentObject[deleteKey];
                }

                await new Promise(r => setTimeout(r, 10));
                this.$parent.loadObject();
                await new Promise(r => setTimeout(r, 10));
                this.loadObject();
            }else {
                delete this.mainObject[this.editKeyDialog.obj.key];

                await new Promise(r => setTimeout(r, 10));
                this.loadObject();
            }
            
            this.editKeyDialog.show = false;
        },

        async onMove(direction, name) {
            const index = Number(name);
            if (direction == 'up') {
                if (index <= 0) return;
                const item = this.mainObject.splice(index, 1)[0];
                this.mainObject.splice(index - 1, 0, item);
            }else {
                if (index >= this.mainObject.length - 1) return;
                const item = this.mainObject.splice(index, 1)[0];
                this.mainObject.splice(index + 1, 0, item);
            }
            this.subObjects = [];
            await new Promise(r => setTimeout(r, 10));
            this.loadObject();
        },

        onUpdate(obj) {
            console.log('onUpdate', obj);

            
        },

        getObject() {
            if (this.type == 'object') {

                const obj = {};

                for (const i in this.subObjects) {
                    if (this.subObjects[i].type == 'object') {
                        obj[this.subObjects[i].key] = {};
                    }else if (this.subObjects[i].type == 'array') {
                        obj[this.subObjects[i].key] = [];
                    }else {
                        obj[this.subObjects[i].key] = this.subObjects[i].value;
                    }
                }
                for (const i in this.$children) {
                    if (this.$children[i].$vnode.tag.includes('ObjectEditor')) {
                        for (const key in obj) {
                            if (key == this.$children[i].name) {
                                obj[key] = this.$children[i].getObject();
                            }
                        }
                    }
                }
                return obj;

            }else if (this.type == 'array') {

                const array = [];

                for (const i in this.subObjects) {
                    if (this.subObjects[i].type == 'object') {
                        array.push({});
                    }else if (this.subObjects[i].type == 'array') {
                        array.push([]);
                    }else {
                        array.push(this.subObjects[i].value);
                    }
                }
                for (const i in this.$children) {
                    if (this.$children[i].$vnode.tag.includes('ObjectEditor')) {
                        for (const index in array) {
                            if (index == this.$children[i].name) {
                                array[index] = this.$children[i].getObject();
                            }
                        }
                    }
                }
                return array;

            }
        },

        async loadObject(loadSubs) {
            this.subObjects = [];
            for (const key in this.mainObject) {
                if (typeof this.mainObject[key] == 'object') {
                    let type = 'object';
                    if (Object.prototype.toString.call(this.mainObject[key]) === '[object Array]') {
                        type = 'array';
                    }
                    this.subObjects.push({
                        type: type,
                        key: key,
                        subs: this.mainObject[key]
                    });
                }else if (typeof this.mainObject[key] == 'string') {
                    this.subObjects.push({
                        type: 'string',
                        key: key,
                        value: this.mainObject[key]
                    });
                }else if (typeof this.mainObject[key] == 'boolean') {
                    this.subObjects.push({
                        type: 'boolean',
                        key: key,
                        value: this.mainObject[key]
                    });
                }else if (typeof this.mainObject[key] == 'number') {
                    this.subObjects.push({
                        type: 'number',
                        key: key,
                        value: this.mainObject[key]
                    });
                }
            }

            if (loadSubs) {
                for (const i in this.$children) {
                    if (this.$children[i].$vnode.tag.includes('ObjectEditor')) {
                        await new Promise(r => setTimeout(r, 10));
                        this.$children[i].loadObject();
                    }
                }
            }

        },

    },
    created() {
        if (this.initialCollapsed && !this.isFirst) this.expanded = false;
        this.loadObject();
    }
}
</script>
<style scoped>

.fev2-obj-x {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.fev2-obj-first {
    padding: 30px 10px;
    background-color: var(--card-background);
}

.fev2-obj {
    float: left;
    width: 100%;
    font-size: 18px;
    padding-left: 40px;
    box-sizing: border-box;
}


.fev2-obj-main {
    position: relative;
    display: flex;
}

.fev2-obj-exp {
    position: absolute;
    top: 0px; left: -24px;
}
.fev2-obj-exp2 {
    display: flex;
    margin-top: -4px;
}



.fev2-obj-name {
    font-weight: bold;
    cursor: pointer;
}
.fev2-obj-weak {
    color: var(--text-weak);
    margin-left: 5px;
}
.fev2-obj-str {
    color: #0764b1;
}
.fev2-obj-tra {
    color: #b13707;
}
.fev2-obj-boo {
    color: #6d07b1;
    font-weight: bold;
}
.fev2-obj-num {
    color: #007910;
    font-weight: bold;
}

</style>