<template>
<div style="position: relative;">
    <div class="frv2-menu-button" v-on-clickaway="hideList"
        :class="[
            { 'frv2-menu-button-dot': dotted },
            { 'frv2-menu-button-working': working },
            { 'frv2-menu-button-warning': warning },
            { 'frv2-menu-button-disabled': disabled },
            { 'frv2-menu-button-hlist-l': hasListLeft },
            { 'frv2-menu-button-hlist-r': hasListRight },
        ]"
        @click="handleClick" @mouseup="handleOptionMouseUp"
    >

        <vap-icon v-if="!working" :icon="icon" absolute :top="0" :right="hasListRight ? 4 : 1" :color="'ext'" />

        <div class="frv2-menu-button-tt">
            <slot name="tooltip"></slot>
        </div>

        <div v-if="working" class="frv2-menu-button-spinner">
            <vap-spinner small style="margin-top: 27px; margin-right: 38px;" />
        </div>

    </div>

    <div class="frv2-menu-button-list-l" v-if="hasListLeft" @click.stop="handleListClick" :class="[
            { 'frv2-menu-button-disabled-l': disabled },
        ]">
        <vap-icon tiny :icon="'chev-d'" :color="'ext'" />
    </div>
    <div class="frv2-menu-button-list-r" v-if="hasListRight" @click.stop="handleListClick" :class="[
            { 'frv2-menu-button-disabled-r': disabled },
        ]">
        <vap-icon tiny :icon="'chev-d'" :color="'ext'" />
    </div>

    <div class="frv2-menu-button-list" v-if="listExpanded">
        <slot></slot>
    </div>

</div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
    name: 'FormMenuButtonV2',
    mixins: [clickaway],
    props: {
        icon: { type: String, default: 'help-o' },
        dotted: { type: Boolean, default: false },
        working: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        logicActive: { type: Boolean, default: false },
        logicId: { type: String, default: '' },
        warning: { type: Boolean, default: false },
        hasListLeft: { type: Boolean, default: false },
        hasListRight: { type: Boolean, default: false },
        list: { type: Array, default: () => { return [] }}
    },
    data() {
        return {
            listExpanded: false
        }
    },
    methods: {
        
        handleOptionMouseUp() {
            if (this.logicActive) {
                this.$emit('connect', this.logicId);
            }
        },

        handleClick() {
            if (!this.disabled) {
                this.$emit('clicked');
            }
        },

        handleListClick() {
            if (!this.disabled) {
                this.listExpanded = !this.listExpanded;
            }
        },

        hideList() {
            this.listExpanded = false;
        },

    },
    async created() {

        

    }
}
</script>
<style scoped>

.frv2-menu-button {
    position: relative;
    width: 42px; height: 38px;
    border-radius: 5px;
    border: 2px solid var(--button);
    background-color: var(--button);
    box-sizing: border-box;
    fill: var(--button-text);
    cursor: pointer;
}
.frv2-menu-button:hover {
    opacity: 0.8;
}

.frv2-menu-button-dot {
    border: 2px dotted var(--input-border);
    background-color: transparent;
}

.frv2-menu-button-dot, path {
    fill: var(--input-border);
}
.frv2-menu-button-dot:hover, path {
    border: 2px solid var(--button);
    background-color: var(--button);
    fill: var(--button-text);
}

.frv2-menu-button-tt {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
}

.frv2-menu-button-hlist-l {
    width: 67px;
}
.frv2-menu-button-list-l {
    position: absolute;
    top: 0px;
    width: 25px;
    height: 38px;
    padding-top: 9px;
    padding-left: 2px;
    margin-left: -2px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-right: solid 1px var(--card-background);
    background-color: var(--button);
    box-sizing: border-box;
    fill: var(--button-text);
    cursor: pointer;
}
.frv2-menu-button-list-l:hover {
    opacity: 0.8;
}

.frv2-menu-button-hlist-r {
    width: 45px;
}
.frv2-menu-button-list-r {
    position: absolute;
    top: 0px;
    width: 25px;
    height: 38px;
    left: 42px;
    padding-top: 9px;
    padding-left: 1px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: solid 1px var(--card-background);
    background-color: var(--button);
    box-sizing: border-box;
    fill: var(--button-text);
    cursor: pointer;
}
.frv2-menu-button-list-r:hover {
    opacity: 0.8;
}


.frv2-menu-button-warning {
    border: 2px solid var(--val-error);
    background-color: var(--val-error);
}


.frv2-menu-button-disabled {
    border: 2px solid var(--disabled-background);
    background-color: var(--disabled-background);
    fill: gray;
    cursor: not-allowed;
}
.frv2-menu-button-disabled-l {
    border: 2px solid var(--disabled-background);
    background-color: var(--disabled-background);
    border-right: solid 1px var(--card-background);
    padding-top: 7px;
    padding-left: 0px;
    fill: gray;
    cursor: not-allowed;
}
.frv2-menu-button-disabled-r {
    border: 2px solid var(--disabled-background);
    background-color: var(--disabled-background);
    border-left: solid 1px var(--card-background);
    padding-top: 7px;
    fill: gray;
    cursor: not-allowed;
}



.frv2-menu-button-working {
    border: 2px solid var(--button);
    background-color: transparent;
}

.frv2-menu-button-spinner {
    position: relative;
}



.frv2-menu-button-list {
    position: absolute;
    top: 42px;
    left: 0px;
    width: 300px;
    min-height: 40px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    z-index: 99;
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
}

</style>
