<template>
<div class="aml-page">
    
    <vap-form-divider :left="25" :right="75" style="margin-top: 10px;">
        <template v-slot:left>

            <!-- Filters -->
            <vap-label :text="__('filters')" header2 style="margin-top: 10px; margin-bottom: 10px;" />

            <!-- Search -->
            <SearchBox ref="fltSear" @search="onSearch" :placeholder="__('clients_search')" style="margin-top: 10px; margin-bottom: 16px;" />

            <RemoveFilterButton v-if="hasActiveFilters()" @remove="removeAllFilters" />

            <div>
                <FilterControl empty :header="__('sort_last_updated')" />
                <div style="float: left; width: 50%; margin-top: 10px;">
                    <SortDatePicker v-model="sortDateFrom" noHighlight noValidation :hintKey="'datepicker_from'" :placeholder="__('datepicker_select_date')" outputISO @input="onDateChanged" />
                </div>
                <div style="float: left; width: 50%; margin-top: 10px;">
                    <SortDatePicker v-model="sortDateTo" noHighlight noValidation :hintKey="'datepicker_to'" :placeholder="__('datepicker_select_date')" right outputISO @input="onDateChanged" />
                </div>
            </div>

            <div v-if="filters">

                <div v-for="(filter, i) in possibleFilters" :key="'cpf' + i">
                    <FilterControlV1
                        :params="filter"
                        :filters="filters[filter.processedKey]"
                        :texts="texts[filter.textKey]"
                        :activeValues="activeFilters[filter.key]"
                        @filter="onFilterChanged"
                    />
                </div>

            </div>

        </template>
        <template v-slot:right>

            <div style="display: flex;">
                <vap-label :text="'Usage Stats'" header2 style="flex-grow: 100; margin-top: 10px; margin-bottom: 10px;" />
                <!-- <SortSelector v-model="sortModel" :options="sortOptions" style="flex-shrink: 0; margin-top: 18px;" /> -->
            </div>

            <div v-if="!loading" class="flw100">

                <vap-card compactList noPadding v-for="(client, i) in clients" :key="'c' + i">
                    <ClientItem
                        :icon="'bug'"
                        :name="client.type"
                        :info="client.companyName"
                        :date="formatDate('datetime', client.date)"
                        :user="client.companyId"
                        :noLink="true"
                        :newItem="false"
                    />
                </vap-card>

            </div>

            <CenteredSpinner v-if="loading" />
            <EmptyIcon v-if="!loading && clients.length == 0" :icon="'company'" :text="__('customer_no_hits')" />

            <PageControl v-if="!loading" :total="totalPages" :current="currentPage" @page="onNavigate" />

        </template>
    </vap-form-divider>

</div>
</template>
<script>
import v1backoffice from '../v1admin/v1backoffice';

import ClientItem from '../components/items/ClientItem.vue';
import FilterControlV1 from '../v1ui/FilterControlV1.vue';
import RemoveFilterButton from '../v1ui/RemoveFilterButton.vue';
import PageControl from '../components/special/PageControl';
import SearchBox from '../components/special/SearchBox';
import SortSelector from '../components/special/SortSelector';
import SortDatePicker from '../components/special/SortDatePicker';
import EmptyIcon from '../v1ui/EmptyIcon.vue';
import CenteredSpinner from '../v1ui/CenteredSpinner.vue';
import FilterControl from '../components/special/FilterControl';

export default {
    name: 'BoStats',
    components: {
        ClientItem,
        FilterControlV1,
        RemoveFilterButton,
        PageControl,
        SearchBox,
        SortSelector,
        SortDatePicker,
        EmptyIcon,
        CenteredSpinner,
        FilterControl,
    },
    props: {
        admins: { type: Array, default: () => { return [] } },
    },
    data() {
        return {
            loading: true,

            itemsPerPage: 10,
            currentPage: 0,
            totalPages: 0,
            totalCount: 0,

            clients: [],

            letters: [],

            filters: null,
            possibleFilters: [
                {
                    enabled: true,
                    search: false,
                    limit: 0,
                    key: 'type',
                    processedKey: 'type',
                    textKey: 'type',
                    title: { en_EN: 'Type', nb_NO: 'Type' },
                    formKey: '',
                },
                {
                    enabled: true,
                    search: false,
                    limit: 0,
                    key: 'src',
                    processedKey: 'src',
                    textKey: 'src',
                    title: { en_EN: 'Source', nb_NO: 'Source' },
                    formKey: '',
                },
                {
                    enabled: true,
                    search: false,
                    limit: 0,
                    key: 'month',
                    processedKey: 'month',
                    textKey: 'month',
                    title: { en_EN: 'Month', nb_NO: 'Month' },
                    formKey: '',
                },
                {
                    enabled: true,
                    search: false,
                    limit: 0,
                    key: 'year',
                    processedKey: 'year',
                    textKey: 'year',
                    title: { en_EN: 'Year', nb_NO: 'Year' },
                    formKey: '',
                },
                {
                    enabled: true,
                    search: false,
                    limit: 0,
                    key: 'stat',
                    processedKey: 'stat',
                    textKey: 'stat',
                    title: { en_EN: 'stat', nb_NO: 'stat' },
                    formKey: '',
                },
                {
                    enabled: true,
                    search: false,
                    limit: 0,
                    key: 'cname',
                    processedKey: 'cname',
                    textKey: 'cname',
                    title: { en_EN: 'cname', nb_NO: 'cname' },
                    formKey: '',
                }
            ],
            activeFilters: {
                type: [],
                src: [],
                month: [],
                year: [],
                stat: [],
                cname: [],
            },
            letterFilter: [],

            sortDateFrom: '',
            sortDateTo: '',
            filterDateFrom: null,
            filterDateTo: null,
            filterDateActive: false,

            texts: {
                status: {},
                userCreated: {},
            },
        }
    },
    methods: {
        
        onNavigate(page) {
            this.currentPage = page;
            this.queryClients();
        },

        //#region Filters -----------------------------------------------------------------------------------------------------

        onSearch(str) {
            this.searchString = str;
            this.currentPage = 1;
            this.queryClients();
        },

        onDateChanged() {
            if (this.sortDateFrom && this.sortDateTo) {
                this.filterDateFrom = new Date(this.sortDateFrom);
                this.filterDateTo = new Date(this.sortDateTo + 'T23:59:59.000Z');
                this.filterDateActive = true;
                this.currentPage = 1;
                this.queryClients();
            }
        },

        onFilterChanged(filter, value) {
            for (const i in this.activeFilters) {
                if (i == filter.key) {
                    let match = false;
                    for (const j in this.activeFilters[i]) {
                        if (this.activeFilters[i][j] == value) {
                            this.activeFilters[i].splice(j, 1);
                            match = true;
                        }
                    }
                    if (!match) this.activeFilters[i].push(value);
                }
            }
            this.currentPage = 1;
            this.queryClients();
        },

        hasActiveFilters() {
            if (this.searchString) return true;
            if (this.filterDateActive) return true;
            if (this.letterFilter.length > 0) return true;
            for (const i in this.activeFilters) {
                if (this.activeFilters[i].length > 0) return true;
            }
            return false;
        },

        removeAllFilters() {
            this.filterDateActive = false;
            this.sortDateFrom = '';
            this.sortDateTo = '';
            this.searchString = '';
            this.letterFilter = [];
            for (const i in this.activeFilters) {
                this.activeFilters[i] = [];
            }
            this.currentPage = 1;
            this.queryClients();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        getFilters() {
            const filters = [];

            if (this.searchString) {
                filters.push({ field: 'name', op: 'search', value: this.searchString });
            }
            if (this.filterDateActive) {
                filters.push({ field: 'dateCreated', op: 'daterange', from: this.filterDateFrom, to: this.filterDateTo });
            }
            for (const key in this.activeFilters) {
                if (this.activeFilters[key].length > 0) {
                    for (const i in this.possibleFilters) {
                        if (this.possibleFilters[i].key == key) {
                            filters.push({ field: this.possibleFilters[i].processedKey, op: 'in', values: this.activeFilters[key] });
                        }
                    } 
                }
            }
            return filters;
        },

        async queryClients() {
            this.loading = true;

            const sort = { date: -1 };
            const filters = this.getFilters();
            const from = (Number(this.currentPage) - 1) * Number(this.itemsPerPage);
            const limit = this.itemsPerPage;
            const include = [];
            const counts = ['type', 'src', 'month', 'year', 'cname', 'stat'];

            const response = await v1backoffice.queryStats(filters, from, limit, sort, include, counts);

            this.totalCount = response.count;
            this.totalPages = Math.ceil(Number(this.totalCount) / Number(this.itemsPerPage));

            this.clients = response.clients;
            this.filters = response.filters;

            // Sort filters
            /* if (this.filters.status) this.filters.status.sort((a, b) => (a._id > b._id) ? 1 : -1); */
            /* if (this.filters.userCreated) this.filters.userCreated.sort((a, b) => (a._id > b._id) ? 1 : -1); */

            console.log('clients', this.clients);

            this.loading = false;
        }

    },
    created() {


        console.log('texts', this.texts);

        this.currentPage = 1;
        this.queryClients();
    }
}
</script>
<style scoped>


</style>