<template>
<div class="vf-helptext">
    <vap-text-editor :editable="false" :value="content" />
</div>
</template>

<script>
export default {
    name: 'vf-helptext',
    props: {
        content: { type: [String, Number], default: '' },
    }
}
</script>

<style>

.vf-helptext {
    float: left;
    width: 100%;
    margin-bottom: -5px;
}

.vf-helptext p {
    /* margin-block-start: 0px;
    margin-block-end: 0px; */
}

.vf-helptext h3 {
    /* margin-block-start: 0px;
    margin-block-end: 0px; */
}

</style>
