<template>
<div class="vfc">
    
    <div class="vfc-header">
        <vap-icon tiny :icon="'chev-d'" :color="'lightgray'" />
        <div class="vfc-header-text">
            <label>{{ header }}</label>
        </div>
    </div>

    <div class="vfc-search" v-if="searchable && activeFilters.length == 0">
        <input v-model="searchString" :placeholder="'Søk'" />
    </div>

    <div class="vfc-filters" v-if="!empty && texts && !texts.range">
        <div class="vfc-con" v-for="(filter, i) in filters" :key="'f' + i" @click="onFilterClick(i)" :class="{'vfc-selected': isSelected(i)}" v-show="!filter.hide && (searchString != '' || (limit == 0 || i < limit))">
            <div class="vfc-filter">
                <div class="vfc-filter-text">
                    <label>{{ displayText(filter) }}</label>
                </div>
                <div class="vfc-filter-number">
                    <label>{{ filter.count }}</label>
                </div>
            </div>
        </div>
    </div>

    <div class="vfc-filters" v-if="!empty && texts && texts.range">
        <div class="vfc-con" v-for="(filter, i) in refinedList" :key="'f' + i" @click="onFilterClick(i)" :class="{'vfc-selected': isSelected(i)}" v-show="filter.count > 0">
            <div class="vfc-filter">
                <div class="vfc-filter-text">
                    <label>{{ filter[$root.locale.key] }}</label>
                </div>
                <div class="vfc-filter-number">
                    <label>{{ filter.count }}</label>
                </div>
            </div>
        </div>
    </div>

</div>
</template>
<script>

export default {
    name: 'vap-filter-controla',
    props: {
        filters: {
            type: Array,
            default: () => { return [] }
        },
        header: {
            type: String,
            default: 'Filter by'
        },
        texts: {
            type: Object,
            default: null
        },
        noMatch: {
            type: String,
            default: ''
        },
        showRawFilter: {
            type: Boolean,
            default: false
        },
        searchable: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number,
            default: 0
        },
        empty: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            expanded: true,
            activeFilters: [],
            range: false,
            refinedList: [],
            searchString: ''
        }
    },
    watch: {
        filters: {
            deep: true,
            handler(val) {
                if (this.texts && this.texts.range) this.refineList(val);
            }
        },
        searchString: {
            deep: false,
            handler(val) {
                this.onSearchStringChanged(val);
            }
        },
    },
    methods: {

        onSearchStringChanged(str) {
            str = str.toLowerCase();

            for (const i in this.filters) {

                if (this.displayText(this.filters[i]).toLowerCase().includes(str)) {
                    this.filters[i].hide = false;
                }else {
                    this.filters[i].hide = true;
                }

            }

        },

        onFilterClick(index, skipEmit) {
            this.searchString = '';
            if (this.texts && this.texts.range) return this.onFilterClickRange(index, skipEmit);
            let match = false;
            for (let i in this.activeFilters) {
                if (this.activeFilters[i] == this.filters[index]._id) {
                    this.activeFilters.splice(i, 1);
                    match = true;
                    break;
                }
            }
            if (!match) this.activeFilters.push(this.filters[index]._id);
            if (!skipEmit) this.$emit('filter', true);
        },
        onFilterClickRange(index, skipEmit) {
            let match = false;
            for (let i in this.activeFilters) {
                if (this.activeFilters[i].text == this.refinedList[index].text) {
                    this.activeFilters.splice(i, 1);
                    match = true;
                    break;
                }
            }
            if (!match) this.activeFilters.push(this.refinedList[index]);
            if (!skipEmit) this.$emit('filter', true);
        },
        isSelected(index) {
            if (this.texts && this.texts.range) return this.isSelectedRange(index);
            for (let i in this.activeFilters) {
                if (this.activeFilters[i] == this.filters[index]._id) return true;
            }
            return false;
        },
        isSelectedRange(index) {
            for (let i in this.activeFilters) {
                if (this.activeFilters[i].text == this.refinedList[index].text) return true;
            }
            return false;
        },
        addFilter(value) {
            for (let i in this.filters) {
                if (this.filters[i]._id == value) this.onFilterClick(i);
            }
        },
        highlightFilters(filters) {
            let isRange = false;
            if (this.texts && this.texts.range) {
                isRange = true;
                this.refineList(this.filters);
            }
            const highlightList = [];
            if (filters.active) {
                if (isRange) {
                    for (const i in this.filters) {
                        for (const j in this.refinedList) {
                            const filter = this.refinedList[j];
                            const value = this.filters[i];
                            if (filter.match != undefined) {
                                if (value._id == filter.match) {
                                    if (!highlightList.includes(j)) highlightList.push(j);
                                }
                            }else if (filter.less != undefined && filter.more != undefined) {
                                if (value._id < filter.less && value._id > filter.more) {
                                    if (!highlightList.includes(j)) highlightList.push(j);
                                }
                            }else if (filter.less != undefined) {
                                if (value._id < filter.less) {
                                    if (!highlightList.includes(j)) highlightList.push(j);
                                }
                            }else if (filter.more != undefined) {
                                if (value._id > filter.more) {
                                    if (!highlightList.includes(j)) highlightList.push(j);
                                }
                            }
                        }
                    }
                }else {
                    for (const i in filters.values) {
                        for (const j in this.filters) {
                            if (this.filters[j]._id == filters.values[i]) this.onFilterClick(j, true);
                        }
                    }
                }
            }
            for (const i in highlightList) this.onFilterClick(Number(highlightList[i]), true);
        },
        removeFilters() {
            this.activeFilters = [];
        },
        displayText(filter) {
            if (this.texts && this.texts[filter._id]) {
                return this.texts[filter._id];
            }else {
                if (this.showRawFilter && filter._id) {
                    return filter._id;
                }else if (this.noMatch) {
                    return this.noMatch;
                }else {
                    return filter._id
                }
            }
        },
        refineList(val) {
            this.refinedList = [];

            for (const i in this.texts.values) {
                this.refinedList.push({
                    text: this.texts.values[i].text,
                    nb_NO: this.texts.values[i].nb_NO,
                    en_EN: this.texts.values[i].en_EN,
                    match: this.texts.values[i].match,
                    less: this.texts.values[i].less,
                    more: this.texts.values[i].more,
                    count: 0
                });
            }

            for (const i in val) {

                for (const j in this.refinedList) {
                    const filter = this.refinedList[j];

                    if (filter.match != undefined) {
                        if (val[i]._id == filter.match) {
                            this.refinedList[j].count = Number(this.refinedList[j].count) + Number(val[i].count);
                            break;
                        }
                    }else if (filter.less != undefined && filter.more != undefined) {
                        if (val[i]._id > filter.more && val[i]._id < filter.less) {
                            this.refinedList[j].count = Number(this.refinedList[j].count) + Number(val[i].count);
                            break;
                        }
                    }else if (filter.more != undefined) {
                        if (val[i]._id > filter.more) {
                            this.refinedList[j].count = Number(this.refinedList[j].count) + Number(val[i].count);
                            break;
                        }
                    }else if (filter.less != undefined) {
                        if (val[i]._id < filter.less) {
                            this.refinedList[j].count = Number(this.refinedList[j].count) + Number(val[i].count);
                            break;
                        }
                    }

                }


            }

        }
    }
}
</script>
<style>

.vfc {
    float: left;
    width: 100%;
    margin-top: 20px;
}

.vfc-header {
    display: flex;
    float: left;
    width: 100%;
    color: var(--text-strong);
    padding-bottom: 4px;
    border-bottom: solid 1px var(--text-disabled);
}
.vfc-header-icon path {
    fill: var(--text-weak);
}
.vfc-header-icon svg {
    transform: scale(0.7);
}
.vfc-header-text {
    margin-top: 2px;
    margin-left: 3px;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-title);
}

.vfc-filters {
    float: left;
    width: 100%;
    padding-top: 10px;
}
.vfc-con {
    padding: 2px 0px;
}
.vfc-filter {
    display: flex;
    padding: 4px 0px;
    font-size: 15px;
    color: var(--input-label);
    cursor: pointer;
}
.vfc-filter:hover {
    color: var(--accent);
}
.vfc-filter-text {
    flex-grow: 100;
    padding-left: 15px;
}
.vfc-filter-number {
    float: right;
    padding-right: 10px;
}
.vfc-filter-text label, .vfc-filter-number label {
    cursor: pointer;
}


.vfc-selected .vfc-filter {
    color: var(--accent-contrast);
    background-color: var(--accent);
    border-radius: 4px;
}



.vfc-search {
    float: left;
    width: 100%;
    margin-top: 10px;
    margin-bottom: -5px;
    padding-left: 15px;
    padding-right: 9px;
    box-sizing: border-box;
}

.vfc-search input {
    outline: none;
    width: 100%;
    padding: 4px 10px;
    border-radius: 4px;
    border: solid 1px var(--text-disabled);
    background-color: var(--page-background);
    box-sizing: border-box;
}

.vfc-search input:focus {
    border: solid 1px var(--text-strong);
    background-color: var(--card-background);
}

</style>
