<template>
<div class="fbinp-textarea">
    
    <textarea
        :value="value"
        :placeholder="placeholder"
        @input="handleInput"
        :disabled="disabled"
    />

</div>
</template>
<script>
export default {
    name: 'DesignerTextArea',
    props: {
        value: [String, Number],
        placeholder: { type: String, default: '' },
        disabled: { type: Boolean, default: false },
    },
    methods: {

        handleInput(event) {
            this.$emit('input', event.srcElement.value);
        },

    }
}
</script>
<style scoped>

.fbinp-textarea {
    
}

.fbinp-textarea textarea {
    border: none;
    background: none;
    outline: none;
    width: 100%;
    padding: 5px 5px;
    font-size: 14px;
    color: white;
    border: solid 1px var(--fb-input-bg);
    background-color: var(--fb-input-bg);
    box-sizing: border-box;
    resize: vertical;
    min-height: 40px;
    max-height: 400px;
}

.fbinp-textarea textarea:focus {
    border-color: gray;
}

.fbinp-textarea textarea::placeholder {
    color: white;
    font-weight: 500;
    letter-spacing: 0.6px;
    opacity: 0.2;
}

</style>