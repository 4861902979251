const idc01 = {

    getForm() {
        return {
            "key": "idc01",
            "designVersion": 1,
            "locales": [
                {
                    "key": "en_EN",
                    "name": "English",
                    "enabled": true,
                    "alpha2": "gb"
                },
                {
                    "key": "nb_NO",
                    "name": "Norsk",
                    "enabled": true,
                    "alpha2": "no"
                },
                {
                    "key": "sv_SE",
                    "name": "Svenska",
                    "enabled": true,
                    "alpha2": "se"
                }
            ],
            "designName": "Standard ID-control",
            "title": {
                "en_EN": "ID Control",
                "nb_NO": "ID Kontroll",
                "sv_SE": "ID Kontroll"
            },
            "name": {
                "en_EN": "ID Control {{personName}}",
                "nb_NO": "ID Kontroll {{personName}}",
                "sv_SE": "ID Kontroll {{personName}}"
            },
            "flowType": "internal",
            "flowConfig": {
                "autoSave": false,
                "canSave": false,
                "customSender": {
                    "enabled": false
                },
                "landingParams": {
                    "filled": {
                        "title": {
                            "en_EN": "ID confirmed",
                            "nb_NO": "ID bekreftet",
                            "sv_SE": "ID bekräftat"
                        },
                        "message": {
                            "en_EN": "<p>Thank you for your cooperation.</p>",
                            "nb_NO": "<p>Takk for ditt samarbeid.</p><p></p><p></p><p>Du trenger ikke gjøre noe mer.</p>",
                            "sv_SE": "<p>Tack&nbsp;för&nbsp;ditt&nbsp;samarbete</p>"
                        }
                    },
                    "signed": {
                        "title": {
                            "en_EN": "en",
                            "nb_NO": "no",
                            "sv_SE": "se"
                        },
                        "message": {
                            "en_EN": "en",
                            "nb_NO": "no",
                            "sv_SE": "se"
                        }
                    },
                    "aborted": {
                        "title": {
                            "en_EN": "en",
                            "nb_NO": "no",
                            "sv_SE": "se"
                        },
                        "message": {
                            "en_EN": "en",
                            "nb_NO": "no",
                            "sv_SE": "se"
                        }
                    },
                    "redirect": "",
                    "logoUrl": ""
                },
                "roles": [
                    {
                        "key": "owner",
                        "type": "owner",
                        "display": "Owner",
                        "next": "idc",
                        "redirect": "persons"
                    },
                    {
                        "key": "idc",
                        "display": "Editor (ID-control)",
                        "next": "done",
                        "email": {
                            "subject": {
                                "en_EN": "ID-control",
                                "nb_NO": "ID-kontroll",
                                "sv_SE": "ID-kontroll"
                            },
                            "header": {
                                "en_EN": "Hi {{firstName}} {{lastName}}",
                                "nb_NO": "Hei {{firstName}} {{lastName}}",
                                "sv_SE": "Hej {{firstName}} {{lastName}}"
                            },
                            "message": {
                                "en_EN": "As part of your business with {{companyName}} we ask that you complete a digital ID-control.",
                                "nb_NO": "Som en del av samarbeidet ditt med {{companyName}} ber vi deg om å fullføre en digital ID-kontroll.",
                                "sv_SE": "Som en del av din verksamhet med {{companyName}} vi ber dig att genomföra en digital ID-kontroll."
                            },
                            "button": {
                                "en_EN": "Start ID-control",
                                "nb_NO": "Start ID-kontroll",
                                "sv_SE": "Start ID-kontroll"
                            },
                            "hint": {
                                "en_EN": "You are receiving this email because we are required by law to ID all of our clients.",
                                "nb_NO": "Du mottar denne eposten fordi vi er pålagt å hente inn ID på alle våre kunder.",
                                "sv_SE": "Du får det här e-postmeddelandet eftersom vi enligt lag är skyldiga att identifiera alla våra kunder."
                            },
                            "footer": {
                                "en_EN": "If you have any questions please contact {{senderName}} on {{senderEmail}}",
                                "nb_NO": "Hvis du har noen spørsmål vennligst kontakt {{senderName}} på {{senderEmail}}",
                                "sv_SE": "Om du har några frågor vänligen kontakta {{senderName}} på {{senderEmail}}"
                            },
                            "links": []
                        }
                    },
                    {
                        "key": "editor",
                        "display": "Editor (Fill in form)",
                        "next": "done",
                        "email": {
                            "subject": {
                                "en_EN": "You have new form to fill in",
                                "nb_NO": "Du har et nytt skjema å fylle ut"
                            },
                            "header": {
                                "en_EN": "Hi {{firstName}} {{lastName}}",
                                "nb_NO": "Hei {{firstName}} {{lastName}}"
                            },
                            "message": {
                                "en_EN": "{{senderName}} have sent you a form ready to be filled in.",
                                "nb_NO": "{{senderName}} har sendt deg et nytt skjema som må fylles ut."
                            },
                            "button": {
                                "en_EN": "Open form",
                                "nb_NO": "Åpne skjema"
                            },
                            "hint": {
                                "en_EN": "Click the button to open the form.",
                                "nb_NO": "Trykk på knappen for å åpne skjema."
                            },
                            "footer": {
                                "en_EN": "If you have any questions please contact {{senderName}} on {{senderEmail}}",
                                "nb_NO": "Hvis du har noen spørsmål vennligst kontakt {{senderName}} på {{senderEmail}}"
                            },
                            "links": []
                        }
                    }
                ]
            },
            "modules": [
                {
                    "type": "idc",
                    "icon": "card",
                    "methods": {
                        "bankidno": true,
                        "bankidse": true,
                        "nemid": false,
                        "passreader": false
                    }
                }
            ],
            "counter": 1042,
            "pages": [
                {
                    "key": "p1001",
                    "type": "page",
                    "title": {
                        "en_EN": "Recipient",
                        "nb_NO": "ID Control",
                        "sv_SE": "Mottagare"
                    },
                    "hasInfo": true,
                    "info": {
                        "owner": [
                            {
                                "key": "h1002",
                                "icon": "person",
                                "header": {
                                    "en_EN": "Recipient",
                                    "nb_NO": "Velg mottaker",
                                    "sv_SE": "Mottagare"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>The recipient of the ID-control</p>",
                                    "nb_NO": "<p>Velg mottakeren av ID kontrollen</p>",
                                    "sv_SE": "<p>Mottagaren av ID-kontrollen</p>"
                                }
                            }
                        ],
                        "idc": [
                            {
                                "key": "h1012",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1015",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ]
                    },
                    "roles": {
                        "owner": true,
                        "idc": false,
                        "editor": false
                    },
                    "cards": [
                        {
                            "key": "c1003",
                            "type": "card",
                            "header": {
                                "en_EN": "Recipient",
                                "nb_NO": "Mottaker",
                                "sv_SE": "Mottagare"
                            },
                            "inputs": [
                                {
                                    "key": "i1004",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "First Name",
                                        "nb_NO": "Fornavn",
                                        "sv_SE": "Förnamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "flow": {
                                        "field": {
                                            "type": "firstName",
                                            "rkey": "idc"
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "logic": {
                                        "field": {
                                            "type": "idc",
                                            "key": "firstName"
                                        }
                                    },
                                    "ownerReq": true
                                },
                                {
                                    "key": "i1005",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Last Name",
                                        "nb_NO": "Etternavn",
                                        "sv_SE": "Efternamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "flow": {
                                        "field": {
                                            "type": "lastName",
                                            "rkey": "idc"
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "logic": {
                                        "field": {
                                            "type": "idc",
                                            "key": "lastName"
                                        }
                                    },
                                    "ownerReq": true
                                },
                                {
                                    "key": "i1006",
                                    "type": "text",
                                    "inputType": "email",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Email address",
                                        "nb_NO": "Epostadresse",
                                        "sv_SE": "Epost"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "flow": {
                                        "field": {
                                            "type": "email",
                                            "rkey": "idc"
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "logic": {
                                        "field": {
                                            "type": "idc",
                                            "key": "email"
                                        }
                                    },
                                    "ownerReq": true
                                },
                                {
                                    "key": "l1042",
                                    "type": "language",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Language",
                                        "nb_NO": "Språk",
                                        "sv_SE": "Språk"
                                    },
                                    "source": "form",
                                    "initial": "system",
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "flow": {
                                        "field": {
                                            "type": "lang",
                                            "rkey": "idc"
                                        }
                                    },
                                    "ownerReq": true,
                                    "model": "en_EN"
                                }
                            ],
                            "noPadding": false,
                            "roles": {
                                "owner": true,
                                "idc": true,
                                "editor": true
                            },
                            "pdf": true
                        }
                    ],
                    "showStepper": true,
                    "mode": "thin-page"
                },
                {
                    "key": "p1008",
                    "type": "page",
                    "title": {
                        "en_EN": "Welcome",
                        "nb_NO": "Ny side",
                        "sv_SE": "ID-kontroll"
                    },
                    "hideStepper": true,
                    "hasInfo": false,
                    "info": {
                        "owner": [
                            {
                                "key": "h1007",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "idc": [
                            {
                                "key": "h1013",
                                "icon": "card",
                                "header": {
                                    "en_EN": "Authentication methods",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>Please select your preferred authentication method</p>",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1016",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ]
                    },
                    "cards": [
                        {
                            "key": "l1031",
                            "type": "logo",
                            "url": "",
                            "pdf": false,
                            "roles": {
                                "owner": true,
                                "idc": true,
                                "editor": true
                            }
                        },
                        {
                            "key": "c1018",
                            "type": "card",
                            "header": {
                                "en_EN": "",
                                "nb_NO": ""
                            },
                            "inputs": [
                                {
                                    "key": "i1019",
                                    "type": "helpt",
                                    "width": "12",
                                    "desc": {
                                        "formatted": true,
                                        "en_EN": "<h3>Welcome to our ID-control</h3><p>Please select your preferred authentication method:</p>",
                                        "nb_NO": "<h3>Velkommen til vår ID-kontroll</h3><p>Velg hvordan du ønsker å identifisere deg:</p>",
                                        "sv_SE": "<h3>Välkommen&nbsp;till&nbsp;vår ID-kontroll</h3><p>Välj autentiseringsmetod:</p>"
                                    },
                                    "required": false,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1020",
                                    "type": "drop",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Authentication method",
                                        "nb_NO": "Identifiseringsmetode",
                                        "sv_SE": "Autentiseringsmetod"
                                    },
                                    "required": true,
                                    "external": true,
                                    "items": [
                                        {
                                            "type": "itm",
                                            "key": "x1021",
                                            "value": "x1021",
                                            "en_EN": "Dropdown item 1",
                                            "nb_NO": "Nedtrekksvalg 1"
                                        },
                                        {
                                            "type": "itm",
                                            "key": "x1022",
                                            "value": "x1022",
                                            "en_EN": "Dropdown item 2",
                                            "nb_NO": "Nedtrekksvalg 2"
                                        }
                                    ],
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "logic": {
                                        "rlist": {
                                            "key": "idcMethods",
                                            "localized": true,
                                            "display": "locale",
                                            "list": "locale",
                                            "output": "value"
                                        },
                                        "link": [
                                            {
                                                "type": "idc",
                                                "link": "method",
                                                "key": ""
                                            }
                                        ]
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1035",
                                    "type": "text",
                                    "inputType": "number",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "SSN (12 digits)",
                                        "nb_NO": "Fødselsnummer (12 siffer)",
                                        "sv_SE": "Personnummer (12 siffror)"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "min": "12",
                                    "max": "12",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "drop",
                                                    "pkey": "i1020",
                                                    "key": "bankid-se",
                                                    "rev": false
                                                }
                                            ]
                                        },
                                        "link": [
                                            {
                                                "type": "idc",
                                                "link": "ssn-se",
                                                "key": ""
                                            }
                                        ]
                                    }
                                },
                                {
                                    "key": "b1024",
                                    "type": "buttoni",
                                    "width": "12",
                                    "buttons": [
                                        {
                                            "key": "b1025",
                                            "type": "btn",
                                            "color": 0,
                                            "outlined": false,
                                            "dotted": false,
                                            "width": 0,
                                            "text": {
                                                "en_EN": "Start ID-control",
                                                "nb_NO": "Start ID-kontroll",
                                                "sv_SE": "Start ID-control"
                                            },
                                            "tooltip": {
                                                "en_EN": "New button tooltip",
                                                "nb_NO": "Ny hjelpetekst"
                                            },
                                            "logic": {
                                                "action": {
                                                    "type": "idc",
                                                    "key": "start"
                                                },
                                                "enabled": {
                                                    "or": true,
                                                    "keys": [
                                                        {
                                                            "type": "page",
                                                            "pkey": "p1008",
                                                            "key": "pvalid",
                                                            "rev": false
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    ],
                                    "style": 0,
                                    "size": 1,
                                    "align": 1,
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                }
                            ],
                            "noPadding": false,
                            "pdf": false,
                            "pdfIndex": 0,
                            "pdfLocked": false,
                            "roles": {
                                "owner": true,
                                "idc": true,
                                "editor": true
                            }
                        },
                        {
                            "key": "l1030",
                            "type": "methods",
                            "mode": "auth",
                            "pdf": false,
                            "roles": {
                                "owner": true,
                                "idc": true,
                                "editor": true
                            }
                        }
                    ],
                    "roles": {
                        "owner": false,
                        "idc": true,
                        "editor": false
                    },
                    "showStepper": false,
                    "mode": "thin-page"
                },
                {
                    "key": "p1011",
                    "type": "page",
                    "title": {
                        "en_EN": "ID confirmed",
                        "nb_NO": "Ny side"
                    },
                    "hideStepper": false,
                    "hasInfo": false,
                    "info": {
                        "owner": [
                            {
                                "key": "h1010",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "idc": [
                            {
                                "key": "h1014",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1017",
                                "icon": "check-c",
                                "header": {
                                    "en_EN": "ID confirmation",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>Your ID is beeing confirmed</p>",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ]
                    },
                    "cards": [
                        {
                            "key": "l1033",
                            "type": "logo",
                            "url": "",
                            "pdf": false,
                            "roles": {
                                "owner": true,
                                "idc": true,
                                "editor": true
                            }
                        },
                        {
                            "key": "c1027",
                            "type": "card",
                            "header": {
                                "en_EN": "",
                                "nb_NO": ""
                            },
                            "inputs": [
                                {
                                    "key": "i1034",
                                    "type": "helpt",
                                    "width": "12",
                                    "desc": {
                                        "formatted": true,
                                        "en_EN": "<h3>ID Confirmed</h3><p></p><p>Thank you for your cooperation</p><p></p><p></p><p>You can now close this tab</p>",
                                        "nb_NO": "<h3>ID bekreftet</h3><p></p><p>Takk for ditt samarbeid</p><p></p><p></p><p>Du kan nå lukke denne fanen</p>",
                                        "sv_SE": "<h3>ID bekräftat</h3><p></p><p>Tack&nbsp;för&nbsp;ditt&nbsp;samarbete</p><p></p><p></p><p>Du&nbsp;kan&nbsp;nu&nbsp;stänga&nbsp;den&nbsp;här&nbsp;fliken</p>"
                                    },
                                    "required": false,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false
                                }
                            ],
                            "noPadding": false,
                            "pdf": false,
                            "pdfIndex": 0,
                            "pdfLocked": false,
                            "roles": {
                                "owner": true,
                                "idc": true,
                                "editor": true
                            }
                        },
                        {
                            "key": "q1041",
                            "type": "custom",
                            "pdf": true,
                            "pdfOnly": true,
                            "sub": "idcr",
                            "roles": {
                                "owner": true,
                                "idc": true,
                                "editor": true
                            }
                        }
                    ],
                    "roles": {
                        "owner": false,
                        "idc": false,
                        "editor": true
                    },
                    "showStepper": false,
                    "mode": "thin-page"
                }
            ],
            "resources": {},
            "attachments": [],
            "logic": []
        };
    }

};
export default idc01;