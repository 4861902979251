import formHelper from './formHelper';
import flowHelper from './flowHelper';
import log from '@/log';

const editorHelper = {

    //#region Add stuff ---------------------------------------------------------------------------------------------------

    onAddPage(reference) {
        // Take a snapshot of the form before adding
        this.onChange(reference, 'page_added');

        const result = formHelper.getNewPage(reference, reference.roleOptions);

        result.page.visible = true;

        reference.pages.push(result.page);
        reference.processSteps();
        reference.selectValidStep(result.page.key);

        log.x('FRv2: Page added', result.page);
    },

    onAddInput(reference, inputs) {
        // Take a snapshot of the form before adding
        this.onChange(reference, 'input_added');

        const card = formHelper.getItemFromKey(reference.pages, reference.addInputParams.cardKey);
        const isInList = card.type == 'list';

        for (const i in inputs) {
            if (isInList) {
                reference.counter += 1;
                inputs[i].isList = true;
                inputs[i].listModel = 'm' + reference.counter;
            }
            if (reference.kycModule.enabled) inputs[i].kycKey = '';
            inputs[i].visible = true;
            inputs[i].enabled = true;
            card.inputs.push(inputs[i]);
            log.x('FRv2: Input added to card ' + card.key, inputs[i]);

            reference.selectInput(inputs[i]);
        }

        reference.addInputVisible = false;
    },

    onAddCard(reference, cards) {
        // Take a snapshot of the form before adding
        this.onChange(reference, 'card_added');

        //console.log('index', this.pageIndex);
        //console.log('page', this.pages[this.pageIndex]);

        for (const i in cards) {
            if (reference.kycModule.enabled && cards[i].type == 'list') cards[i].kycKey = '';
            reference.pages[reference.pageIndex].cards.push(cards[i]);
            log.x('FRv2: Card added to page ' + reference.pages[reference.pageIndex].key, cards[i]);

            cards[i].visible = true;

            reference.selectCard(cards[i]);
        }

        reference.addCardVisible = false;
    },

    onDeleteItem(reference, item) {
        log.x('FRv2: On delete item', item);

        // Remove any logic depending on this item
        for (const i in reference.pages) {
            const page = reference.pages[i];
            this.removeLogic(page, item.key);
            for (const j in page.cards) {
                const card = page.cards[j];
                this.removeLogic(card, item.key);
                for (const k in card.inputs) {
                    this.removeLogic(card.inputs[k], item.key);
                }
            }
        }

        // Take a snapshot of the form before deleting
        this.onChange(reference, 'item_deleted');
        if (formHelper.isCard(item)) {
            const page = formHelper.getContainer(reference.pages, item);
            for (const i in page.cards) {
                if (page.cards[i].key == item.key) {
                    page.cards.splice(i, 1);
                }
            }
        }else {
            const card = formHelper.getContainer(reference.pages, item);
            console.log('card', card);
            for (const i in card.inputs) {
                if (card.inputs[i].key == item.key) {
                    card.inputs.splice(i, 1);
                }
            }
        }

        reference.deselect();
    },

    removeLogic(item, deletedKey) {
        // Remove any logic that depends on the deleted key
        if (item.logic) {

            // Check visible logic
            if (item.logic.visible) {
                for (const i in item.logic.visible.keys) {
                    if (item.logic.visible.keys[i].key == deletedKey || item.logic.visible.keys[i].pkey == deletedKey) {
                        item.logic.visible.keys.splice(i, 1);
                    }
                }
                if (item.logic.visible.keys.length == 0) delete item.logic.visible;
            }

            // Check disabled logic
            if (item.logic.disabled) {
                for (const i in item.logic.disabled.keys) {
                    if (item.logic.disabled.keys[i].key == deletedKey || item.logic.disabled.keys[i].pkey == deletedKey) {
                        item.logic.disabled.keys.splice(i, 1);
                    }
                }
                if (item.logic.disabled.keys.length == 0) delete item.logic.disabled;
            }

            // Delete the entire logic object if empty
            let count = 0;
            for (const key in item.logic) count += 1;
            if (count == 0) delete item.logic;
        }
    },

    onMoveItem(reference, item, direction) {
        // Move an item (input, card etc.) up or down in it's containing array
        log.x('FRv2: On move item ' + direction, item);
        // Take a snapshot of the form before moving
        this.onChange(reference, 'item_moved');
        const location = formHelper.getItemLocation(reference.pages, item);
        location.list.splice(location.index, 1);
        if (direction == 'up') {
            location.list.splice(location.index - 1, 0, item);
        }else {
            location.list.splice(location.index + 1, 0, item);
        }
    },

    onMovePage(reference, page, direction) {

        console.log('page', page);
        console.log('direction', direction);
        
        let index = -1;
        for (const [i, p] of reference.pages.entries()) {
            if (p.key == page.key) index = i;
        }
        console.log('index', index);
        if (index == -1) return;

        if (
          (direction == "left" && index == 0) ||
          (direction == "right" && index >= reference.pages.length - 1)
        ) {
          return;
        }

        reference.pages.splice(index, 1);
        if (direction == 'left') {
            reference.pages.splice(index - 1, 0, page);
            reference.pageIndex = index - 1;
        }else {
            reference.pages.splice(index + 1, 0, page);
            reference.pageIndex = index + 1;
        }
        console.log('pages', reference.pages);
        reference.stepKey = page.key;
        console.log('pageIndex', reference.pageIndex)
        console.log('stepKey', reference.stepKey)
        reference.processSteps();
    },

    onDeletePage(reference, toRemove) {

        // change current step
        for(let [i, step] of reference.steps.entries()) {
            if(step.key != toRemove.key) {
                continue;
            }

            // move to closest page to the one we're deleting
            if(i > 0) {
                reference.onStepperKeyChanged({ key: reference.steps[i - 1].key, index: i - 1 });
            } else if(i <= reference.steps.length) {
                reference.onStepperKeyChanged({ key: reference.steps[i + 1].key, index: i + 1 });
            } else {
                // Don't delete if this is the only page in the stepper
                console.error("Can't delete the only page in the stepper");
                return;
            }
        }

        reference.pages = reference.pages.filter(x => x.key != toRemove.key);
        reference.steps = reference.steps.filter(x => x.key != toRemove.key);

    },

    onCopyItem(reference, item) {
        log.x('FRv2: On copy item', item);
        const copy = JSON.parse(JSON.stringify(item));

        // TODO support for dropdown

        let keyLetter = copy.key.charAt(0);
        copy.key = keyLetter + (reference.counter += 1);

        if (copy.options) {
            const tempList = [];
            for (const key in copy.options) {
                keyLetter = key.charAt(0); 
                copy.options[key].key = keyLetter + (reference.counter += 1);
                tempList.push(copy.options[key]);
                delete copy.options[key];
            }
            for (const i in tempList) {
                copy.options[tempList[i].key] = tempList[i];
            }
        }
        log.x('FRv2: Item copied', copy);

        const container = formHelper.getContainer(reference.pages, item);
        container.inputs.push(copy);
    },

    onAddRole(reference, role) {
        log.x('FRv2: On add role', role);
        reference.roleOptions.push({
            key: role.key,
            type: role.type,
            display: role.display,
            label: role.label,
            hasEmail: true,
            email: flowHelper.getEmptyEmail(role.key),
            hasLanding: false,
            landingParams: {
                redirect: 'default'
            }
        });
        // Add logic support for this role if not a signer or approver
        if (!formHelper.isSignerRole(role.key)) {
            reference.roles = {};
            const model = {};
            for (const i in reference.roleOptions) {
                reference.$set(reference.roles, reference.roleOptions[i].key, { label: reference.roleOptions[i].display });
                model[reference.roleOptions[i].key] = true;
            }
            for (const i in reference.pages) {
                reference.$set(reference.pages[i], 'roles', model);
                reference.counter += 1;
                reference.$set(reference.pages[i].info, role.key, [formHelper.getNewHelpTextLine(reference)]);
            }
        }
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    onPropInput(reference, type, key, value) {
        console.log('onPropInput type', type);
        console.log('onPropInput key', key);
        console.log('onPropInput value', value);

        const newKey = type + '-' + key;


        if (type == 'text' && newKey == reference.changeKey) {

            console.log('input is the same, skip change log');
            
        }else {

            

            this.onChange(reference, newKey, true);

            reference.changeKey = newKey;

        }


    },


    //#region Undo / Redo -------------------------------------------------------------------------------------------------

    onChange(reference, changeType, skipReset) {
        // Add a new snapshot of the form settings to be used for undo/redo

        const currentData = reference.getCurrentFormData();

        reference.hasChanges = true;
        reference.changeLogIndex = -1;

        const snapshot = {
            display: changeType,
            changeType: changeType,
            data: JSON.stringify(currentData),
        };
        if (!skipReset) reference.changeKey = '';
        reference.changeLog.unshift(snapshot);
        if (reference.changeLog.length > 5) reference.changeLog.pop();
        log.x('FRv2: Form snapshot added before "' + changeType + '"', snapshot);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------
    
};
export default editorHelper;