import axios from 'axios';
import log from './log';
const backend = {
    // -----------------------------------------------------------------------------------------
    // ---- "API reference" for the Netlify backend --------------------------------------------
    // -----------------------------------------------------------------------------------------

    functionUrl: process.env.VUE_APP_API_BASE_URL+'/backend',
    credentials: {},
    logResponse: true,
    isDEV: false,
    baseUrl: process.env.VUE_APP_BASE_URL,

    async authorizeMongo(authString, companyId)  {
        let config = { headers: { 'method': 'authorizeMongo' } };
        const body = JSON.stringify({
            authString: authString,
            companyId: companyId,
            isDev: this.isDEV
        });
        const response = await axios.post(this.functionUrl, body, config);
        return response;
    },

    async authorizeFromLink(authString, company)  {
        let config = { headers: { 'method': 'authorizeFromLink' } };
        const body = JSON.stringify({
            authString: authString,
            company: company
        });
        const response = await axios.post(this.functionUrl, body, config);
        return response;
    },

    async authorizeAWS(authString, companyId, key)  {
        let config = { headers: { 'method': 'authorizeAWS' } };
        const body = JSON.stringify({
            authString: authString,
            companyId: companyId,
            key: key,
            isDev: this.isDEV
        });
        const response = await axios.post(this.functionUrl, body, config);
        return response;
    },



    async searchCompaniesHouse(searchString, skip, limit) {
        let config = this.getConfig('searchCompaniesHouse');
        const body = JSON.stringify({
            searchString: searchString,
            skip: skip,
            limit: limit,
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    async getCompaniesHouse(companyNumber) {
        let config = this.getConfig('getCompaniesHouse');
        const body = JSON.stringify({
            companyNumber: companyNumber
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },


    // --- Customers --------------------------------------------------------------------------

    async getCustomersParams(params) {
        let config = this.getConfig('getCustomersParams');
        const body = JSON.stringify({
            params: params
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },


    async exportData(params, skip, limit) {
        const config = this.getConfig('exportData');
        const body = JSON.stringify({
            params: params,
            skip: skip,
            limit: limit
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    // ----------------------------------------------------------------------------------------

    // --- AML/PEP/KYC ------------------------------------------------------------------------



    async manualCompleteIdc(customerId, personId, type, comment) {
        let config = this.getConfig('manualCompleteIdc');
        config.headers['id'] = customerId;
        const body = JSON.stringify({
            personId: personId,
            manualType: type,
            comment: comment
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },



    async sendAssessment(customerId, data) {
        let config = this.getConfig('sendAssessment');
        config.headers['id'] = customerId;
        const body = JSON.stringify({
            data: data
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },






    // ----------------------------------------------------------------------------------------


    async reportError(error) {
        const config = this.getConfig('reportError');
        const body = JSON.stringify({
            error: error
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },


    // --- Admin ------------------------------------------------------------------------------

    async getValidCompanies(list) {
        const config = this.getConfig('getValidCompanies');
        const body = JSON.stringify({
            list: list
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    async adminGetCompanies(from, limit) {
        const config = this.getConfig('adminGetCompanies');
        const body = JSON.stringify({
            from: from,
            limit: limit
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    async adminGetCompany(id) {
        const config = this.getConfig('adminGetCompany');
        const body = JSON.stringify({
            id: id
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    async adminUpdateUser(id, companyId, key, userId, userData) {
        const config = this.getConfig('adminUpdateUser');
        const body = JSON.stringify({
            id: id,
            companyId: companyId,
            key: key,
            userId: userId,
            userData: userData,
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    async adminAddCompany(company) {
        const config = this.getConfig('adminAddCompany');
        const body = JSON.stringify({
            company: company
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    async adminCopyConfig(fromCompany, toCompany) {
        const config = this.getConfig('adminCopyConfig');
        const body = JSON.stringify({
            fromCompany: fromCompany,
            toCompany: toCompany
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    async adminSaveConfig(configToSave, pid) {
        const config = this.getConfig('adminSaveConfig');
        const body = JSON.stringify({
            config: configToSave,
            pid: pid
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    async adminAddForms(forms, pid) {
        const config = this.getConfig('adminAddForms');
        const body = JSON.stringify({
            forms: forms,
            pid: pid
        });
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    // ----------------------------------------------------------------------------------------

    // --------------------------------------------------------------------------------


    async getPDF(root, companyConfig, template, userdata, translations, nemid, appendFiles) {
        let config = this.getConfig('getPDF');
        const cc = { config: companyConfig };
        if (!translations && !userdata.translations) translations = {};
        let body;
        if (userdata.translations) {
            body = JSON.stringify({
                path: root.isAWS ? this.baseUrl + 'web/aml-portal/templates/' : this.baseUrl + 'templates/',
                appendFiles: appendFiles || [],
                nemid: nemid,
                template: template,
                userdata: { ...userdata, ...cc }
            });
        }else {
            body = JSON.stringify({
                path: root.isAWS ? this.baseUrl + 'web/aml-portal/templates/' : this.baseUrl + 'templates/',
                appendFiles: appendFiles || [],
                nemid: nemid,
                template: template,
                userdata: { ...userdata, ...cc, ...{ translations } }
            });
        }
/*         console.log(body);
        await new Promise(r => setTimeout(r, 10000)); */
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        //this.onResponse(response, config, body);
        return response;
    },

    async getHTMLandCSS(companyConfig, template, userdata, translations, nemid) {
        let config = this.getConfig('getHTMLandCSS');
        const cc = { config: companyConfig };
        if (!translations && !userdata.translations) translations = {};
        let body;
        if (userdata.translations) {
            body = JSON.stringify({
                path: root.isAWS ? this.baseUrl + 'web/aml-portal/templates/' : this.baseUrl + 'templates/',
                nemid: nemid,
                template: template,
                userdata: { ...userdata, ...cc }
            });
        }else {
            body = JSON.stringify({
                path: root.isAWS ? this.baseUrl + 'web/aml-portal/templates/' : this.baseUrl + 'templates/',
                nemid: nemid,
                template: template,
                userdata: { ...userdata, ...cc, ...{ translations } }
            });
        }
/*         console.log(body);
        await new Promise(r => setTimeout(r, 10000)); */
        this.onRequest('POST', config, body);
        const response = await axios.post(this.functionUrl, body, config);
        this.onResponse(response, config, body);
        return response;
    },

    async renderTemplate(root, templateId, userdata, extraTranslations) {
        let config = this.getConfig('getHTMLandCSS');
        const translations = extraTranslations ? { ...root.translations, ...extraTranslations } : root.translations;
        const data = {
            path: root.isAWS ? this.baseUrl + 'web/aml-portal/templates/' : this.baseUrl + 'templates/',
            templateId: templateId,
            config: root.config,
            userdata: userdata,
            translations: translations
        };
        console.log('render data', data);
        this.onRequest('POST', config, data);
        const response = await axios.post(this.functionUrl, data, config);
        this.onResponse(response, config, data);
        return response;
    },


    onRequest(method, config, body) {
        if (!this.logResponse) return;
        const data = {
            pid: config.headers.pid,
            body: body
        };
        log.r('Backend ' + method + ' ' + config.headers.method, data);

    },

    onResponse(response, config, body) {
        if (!this.logResponse) return;

        log.s('Backend ' + response.config.method.toUpperCase() + ' ' + config.headers.method, response);

    },

    // TODO remove this when all clients are on the new naming convention
    getKey(existingKey, cid) {
        let key = existingKey;
        if (!existingKey || existingKey == 'default') {
            if (cid == 'H1xq6yFU8') key = 'kvale';
            if (cid == 'ry1K-ZPPD') key = 'pangea-pp';
            if (cid == 'B1LU_pJfm') key = 'sands';
            if (cid == 'rkeUXcLuv') key = 'deloitte';
        }
        if (key == 'deloitte') key = 'default';
        return key;
    },

    getConfig(method) {
        let config;
        if (this.credentials) {
            let pid = this.getKey(this.credentials.key, this.credentials.cid);
            if (this.credentials.testinc) pid = this.getKey(this.credentials.testinc, this.credentials.cid);
            pid += '-';
            pid += this.credentials.cid;
            if (this.credentials.token) {
                config = {
                    headers: {
                        'method': method,
                        'token' : this.credentials.token || '',
                        'pid': pid,
                        'Pragma' : 'no-cache' // IE-fix - disable caching of network calls
                    }
                }
            }else {
                config = {
                    headers: {
                        'method': method,
                        'btoken' : this.credentials.bearerToken || '',
                        'pid': pid,
                        'Pragma' : 'no-cache' // IE-fix - disable caching of network calls
                    }
                }
            }
            if (this.credentials.testinc) config.headers['testinc'] = this.credentials.testinc;
        }else {
            config = {
                headers: {
                    'method': method,
                    'Pragma' : 'no-cache' // IE-fix - disable caching of network calls
                }
            }
        }
        return config;
    }

};
export default backend;