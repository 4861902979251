<template>
<div>

    <vap-infocard :data="companyHelp" style="float: left; width: 100%;" />

    <EmptyIcon :icon="'company'" :text="__t('lookup-empty')" v-if="!company.lookup && $root.config.portal.useV1" />

    <div v-if="company.lookup || !$root.config.portal.useV1">

        <vap-card list :header="__('company_info_header')" :icon="'company'">
            <CompanyDetails :company="company" :manualData="manualData" />
        </vap-card>

        <vap-card list :header="'Previous company names'" :icon="'company'" v-if="company.previousNames.length > 0">
            
            <div class="pcm-item" v-for="(company, i) in company.previousNames" :key="'pn' + i">
                <div class="pcm-item-val">
                    <span style="font-weight: bold;">{{ company.name }}</span>
                </div>
                <div class="pcm-item-val">
                    <span style="color: var(--text-weak);">{{ 'Effective from: ' }}</span>
                    <span style="font-weight: bold;">{{ company.from }}</span>
                </div>
                <div class="pcm-item-val">
                    <span style="color: var(--text-weak);">{{ 'Ceased on: ' }}</span>
                    <span style="font-weight: bold;">{{ company.to }}</span>
                </div>
            </div>

        </vap-card>

        <vap-card list :header="__('company_head_sign_rights')" :icon="'sign'" v-if="company.signature.available">
            <CompanySignRight :company="company" />
        </vap-card>

        <vap-form-divider :left="35" :right="65">
            <template v-slot:left>
                <vap-card list :header="__('company_head_economy')" :icon="'chart'" v-if="company.economy.basicAvailable">
                    <EconomyInfo :company="company" />
                </vap-card>
            </template>
            <template v-slot:right>
                <vap-card list noPadding v-if="company.economy.trendAvailable">
                    <EconomyTrend :company="company" />
                </vap-card>
            </template>
        </vap-form-divider>

        <vap-card list v-if="company.economy.yearAvailable">
            <EconomyYears :company="company" />
        </vap-card>

    </div>

</div>
</template>

<script>
import CompanyDetails from '@/components/company/CompanyDetails';
import CompanySignRight from '@/components/company/CompanySignRight';
import EconomyInfo from '@/components/company/EconomyInfo';
import EconomyTrend from '@/components/company/EconomyTrend';
import EconomyYears from '@/components/company/EconomyYears';

import EmptyIcon from '../../v1ui/EmptyIcon.vue';

export default {
    name: 'TabCompany',
    components: {
        CompanyDetails,
        CompanySignRight,
        EconomyInfo,
        EconomyTrend,
        EconomyYears,
        EmptyIcon
    },
    props: {
        company: { type: Object, default: () => { return {} } },
        manualData: { type: Object, default: () => { return {} } }
    },
    data() {
        return {
            loading: true,
            companyHelp: [{ icon: 'info', key: 'company_info' }],
        }
    },
    async created() {
        this.loading = false;
    }
}
</script>
<style scoped>

.pcm-item {
    display: flex;
    margin-bottom: 10px;
}
.pcm-item-val {
    margin-right: 20px;
}

</style>
