<template>
<div class="vap-btng">
    
    <div v-for="(item, id, i) in options" :key="id" class="vap-btng-cell"
        :class="[
            {'vap-btng-first' : i == 0},
            {'vap-btng-last' : i == Object.keys(options).length - 1},
            {'vap-btng-middle' : i != 0 && i != Object.keys(options).length - 1},
            {'vap-btng-selected' : value[id]},
        ]">

        <div class="vap-btng-item" @click="handleClick(id)">
            <label>{{ item.label }}</label>
        </div>

    </div>

</div>
</template>

<script>

export default {
    name: 'vap-button-group',
    props: {
		value: {
			type: Object,
			default: () => { return {} }
		},
		options: {
			type: Object,
			default: () => { return {} }
		}
	},
    data() {
        return {
            
        }
    },
    methods: {
        handleClick(id) {
            let val = {};
            for (let key in this.value) {
                val[key] = false;
                if (key == id) val[key] = true;
            }
            this.$emit('input', val);
        }
    },
    async created() {
        
    }
}
</script>

<style>

.vap-btng {
    display: table;
    table-layout: fixed;
    float: left;
    width: 100%;
}

.vap-btng-cell {
    display: table-cell;
    width: 100px;
}

.vap-btng-item {
    min-height: 40px;
    padding-top: 10px;
    text-align: center;
    border-top: solid 1px var(--tab);
    border-bottom: solid 1px var(--tab);
    box-sizing: border-box;
    cursor: pointer;

    font-family: 'Signika', sans-serif;
    font-size: 16px;
}
.vap-btng-first {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-right: solid 1px var(--tab);
    border-left: solid 1px var(--tab);
}
.vap-btng-middle {
    border-right: solid 1px var(--tab);
}
.vap-btng-last {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: solid 1px var(--tab);
}

.vap-btng-item label {
    color: var(--tab);
    cursor: pointer;
}

.vap-btng-item:hover {
    background-color: var(--tab);
}
.vap-btng-item:hover label {
    color: white;
}

.vap-btng-selected {
    background-color: var(--tab);
}
.vap-btng-selected label {
    color: white;
}


</style>
