<template>
<div>
    
    <PropertyHeader :header="'Resources'">
        <template v-slot:actions>
            <!-- <DesignerButton small
                :icon="'add'"
                :tooltip="'Add resource'"
                @clicked="showAddDialog()"
            /> -->
        </template>
    </PropertyHeader>

    <PropertyItem :header="'Module resources'" :icon="'modules'">
        <PropertyListItem v-for="(item, i) in listModule" :key="'rg' + i"
            :icon="item.icon"
            :text="item.name"
            :logicActive="logicActive"
        />
        <div class="frv2mod-res-empty" v-if="listModule.length == 0">
            {{ 'No resources' }}
        </div>
    </PropertyItem>

    <PropertyItem :header="'Global resources'" :icon="'locale'">
        <PropertyListItem v-for="(item, i) in listGlobal" :key="'rg' + i"
            :icon="item.icon"
            :text="item.name"
            :logicActive="logicActive"
        />
        <div class="frv2mod-res-empty" v-if="listGlobal.length == 0">
            {{ 'No resources' }}
        </div>
    </PropertyItem>

    <PropertyItem :header="'Custom resources'" :icon="'verified'">
        <PropertyListItem v-for="(item, i) in listCustom" :key="'rg' + i"
            :icon="item.icon"
            :text="item.name"
            :logicActive="logicActive"
        >
            <template v-slot:actions>
                <DesignerButton small
                    :icon="'edit'"
                    :tooltip="'Edit resource'"
                    @clicked="showEditDialog(item)"
                />
                <DesignerButton small
                    :icon="'settings'"
                    :tooltip="'Edit definition'"
                    @clicked="showConfigDialog(item)"
                />
            </template>
        </PropertyListItem>
        <div class="frv2mod-res-empty" v-if="listCustom.length == 0">
            {{ 'No resources' }}
        </div>
    </PropertyItem>

    <!-- Add dialog -->
    <PopupV1 designer noPadding v-if="addDialog.show" :header="'Add resource'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <div v-if="!addDialog.resourceType">
                <PropertyListItem v-for="(resource, i) in resourceTypes" :key="'am' + i"
                    :icon="resource.icon"
                    :text="resource.name"
                    :hint="resource.hint"
                    @clicked="onSelectResourceType(resource)"
                />
            </div>
            <div v-if="addDialog.resourceType" class="flw100" style="padding: 12px 16px;">
                <DesignerGroup c6>
                    <DesignerLabel :text="'Key'" />
                    <DesignerInput v-model="addDialog.key" @input="validateAddDialog" />
                </DesignerGroup>
                <DesignerGroup c6>
                    <DesignerLabel :text="'Name'" />
                    <DesignerInput v-model="addDialog.name" @input="validateAddDialog" />
                </DesignerGroup>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="addDialog.show = false" />
            <VFButton :text="'Add resource'" @clicked="onAddResource()" :disabled="!addDialog.valid" />
        </template>
    </PopupV1>

    <!-- Edit dialog -->
    <PopupV1 designer noPadding v-if="editDialog.show" :header="'Edit resource'" @close="editDialog.show = false" :dialog="editDialog">
        <template v-slot:content>
            <div class="flw100" style="padding: 12px 16px;">

                <div v-for="(item, i) in reference.resources[editDialog.key].items" :key="'ri' + i">
                    {{ item }}
                </div>

            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-close')" outline @clicked="editDialog.show = false" />
        </template>
    </PopupV1>

    <!-- Config dialog -->
    <PopupV1 designer noPadding v-if="configDialog.show" :header="'Resource definition'" @close="configDialog.show = false" :dialog="configDialog">
        <template v-slot:content>
            <div class="flw100" style="padding: 12px 16px;">
<!--                 <DesignerGroup c6>
                    <DesignerLabel :text="'Key'" />
                    <DesignerInput v-model="reference.resources[configDialog.key].key"/>
                </DesignerGroup> -->
                <DesignerGroup c12>
                    <DesignerLabel :text="'Name'" />
                    <DesignerInput v-model="reference.resources[configDialog.key].name" />
                </DesignerGroup>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-close')" outline @clicked="configDialog.show = false" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import moduleHelper from '../../modules/moduleHelper';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';

import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import PropertyListItem from '../ui/PropertyListItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';

import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';

export default {
    name: 'ResourcesPropBox',
    components: {
        PopupV1,
        VFButton,
        PropertyHeader,
        PropertyItem,
        PropertyListItem,
        DesignerButton,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
    },
    props: {
        reference: { type: Object, default: null },
        logicActive: { type: Boolean, default: false },
    },
    data() { 
        return {
            listCustom: [],
            listModule: [],
            listGlobal: [],
            addDialog: { show: false, valid: false, resourceType: null, key: '', name: '' },
            resourceTypes: [
                /* { type: 'bool', icon: 'input-check', display: 'Boolean', hint: 'Boolean' }, */
                /* { type: 'value', icon: 'input-text', display: 'Value', hint: 'Value' }, */
                { type: 'list', icon: 'resource', display: 'List', hint: 'List' },
                /* { type: 'obj', icon: 'modules', display: 'Object', hint: 'Object' }, */
            ],
            editDialog: { show: false, key: '' },
            configDialog: { show: false, key: '' },
        }
    },
    methods: {

        onClick() {
            this.$emit('connect', slot.key);
        },

        //#region Add Dialog --------------------------------------------------------------------------------------------------

        showAddDialog() {
            this.addDialog.resourceType = null;
            this.addDialog.key = '';
            this.addDialog.name = '';
            this.addDialog.show = true;
            this.validateAddDialog();
        },

        validateAddDialog() {
            if (this.addDialog.key && this.addDialog.name) {
                this.addDialog.valid = true;
            }else {
                this.addDialog.valid = false;
            }
        },

        onSelectResourceType(resourceType) {
            this.addDialog.resourceType = resourceType;
            this.validateAddDialog();
        },

        onAddResource() {

            if (this.addDialog.resourceType.type == 'list') {

                const resource = {
                    type: 'list',
                    key: this.addDialog.key,
                    name: this.addDialog.name,
                    module: 'x',
                    definition: {
                        key: 'string',
                        message: 'text',
                    },
                    items: [],
                };
                this.$set(this.reference.resources, this.addDialog.key, resource);

            }

        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Edit Dialog -------------------------------------------------------------------------------------------------

        showEditDialog(item) {
            this.editDialog.key = item.key;
            this.editDialog.show = true;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Config Dialog -----------------------------------------------------------------------------------------------

        showConfigDialog(item) {
            this.configDialog.key = item.key;
            this.configDialog.show = true;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {
        console.log('res', this.reference.resources);

        for (const key in this.reference.resources) {
            if (this.reference.resources[key].module) {
                if (this.reference.resources[key].module == 'sys') {
                    this.listGlobal.push({
                        icon: 'home',
                        name: this.reference.resources[key].name,
                        key: this.reference.resources[key].key,
                    });
                }else if (this.reference.resources[key].module == 'x') {
                    this.listCustom.push({
                        icon: 'bug',
                        name: this.reference.resources[key].name,
                        key: this.reference.resources[key].key,
                    });
                }else {
                    const module = moduleHelper.getEmptyConfig(this.reference.resources[key].module);
                    this.listModule.push({
                        icon: module.icon || 'bug',
                        name: this.reference.resources[key].name,
                        key: this.reference.resources[key].key,
                    });
                }
            }
        }
    }
}
</script>
<style scoped>

.frv2mod-res-empty {
    padding: 10px 20px;
    font-size: 14px;
    color: var(--fb-text-3);
}

</style>