<template>
    <div>

        <vap-label :text="__('settings_welcome')" header style="margin-bottom: 5px;">
            <template v-slot:actions>
                <vap-icon action :icon="'edit'" @clicked="$emit('locale', 'kyc_welcome')">
                    <template v-slot:tooltip><vap-tooltip :text="'Endre velkomstmelding'"></vap-tooltip></template>
                </vap-icon>
            </template>
        </vap-label>
        <vap-card>
            <vap-text-editor :value="___($parent, 'kyc_welcome')" />
        </vap-card>

        <vap-label :text="__('settings')" header style="margin-top: 30px; margin-bottom: 5px;" />
        <vap-card>
            <vap-row>
                <vap-group c12>
                    <vap-label :text="__('settings_landing_page')" desc />
                    <vap-input v-model="$root.config.kycs[kycIndex].redirect" noValidation />
                </vap-group>
                <vap-group c12>
                    <vap-label :text="__('settings_help_page')" desc />
                    <vap-input v-model="$root.config.kycs[kycIndex].email.link" noValidation />
                </vap-group>
                <vap-group c12 v-if="$root.config.kycs[kycIndex].settings">
                    <vap-label :text="__('settings_copy_to')" desc />
                    <vap-input v-model="$root.config.kycs[kycIndex].settings.silentParty" :rules="'email'" noHighlight />
                </vap-group>
            </vap-row>
        </vap-card>

        <vap-form-divider :left="50" :right="50" style="margin-top: 30px;">
            <template v-slot:left>
                <vap-label :text="__('settings_email_editor')" header style="margin-bottom: 5px;">
                    <template v-slot:actions>
                        <vap-icon :icon="'help-o'"><template v-slot:tooltip><vap-tooltip :localized="'settings_dynamic_info'"></vap-tooltip></template></vap-icon>
                    </template>
                </vap-label>
                <vap-card v-if="$parent.translations.kyc_email_editor_header">
                    <vap-row v-if="$root.config.kycs[kycIndex].email">
                        <vap-group c12>
                            <vap-label :text="'Logo URL'" desc />
                            <vap-input v-model="$root.config.kycs[kycIndex].email.logo" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_subject')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_editor_subject[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_greeting')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_editor_header[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_message')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_editor_message[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_help_text')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_editor_hint[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_contact')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_editor_footer[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_help_link')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_editor_link[$root.locale.key]" noValidation />
                        </vap-group>
                    </vap-row>
                </vap-card>
            </template>
            <template v-slot:right>
                <vap-label :text="__('settings_preview')" header style="margin-bottom: 5px;" />
                <vap-card v-if="$parent.translations.kyc_email_editor_header">
                    <div class="email-preview" style="text-align: center;">
                        <div class="email-preview-sender">
                            <label>{{ __('settings_sender') + ':' }}</label><label style="margin-left: 8px; font-weight: bold;">{{ replaceBrackets('\{\{senderName\}\}') }}</label>
                        </div>
                        <div class="email-preview-subject">
                            <label>{{ __('settings_subject') + ':' }}</label><label style="margin-left: 8px; font-weight: bold;">{{ replaceBrackets($parent.translations.kyc_email_editor_subject[$root.locale.key]) }}</label>
                        </div>
                        <br><br><br>
                        <img :src="$root.config.kycs[kycIndex].email.logo" :style="'width: 200px;'" />
                        <br><br>
                        <h2>{{ replaceBrackets($parent.translations.kyc_email_editor_header[$root.locale.key]) }}</h2>
                        <br>
                        <div v-html="replaceLink(replaceBrackets($parent.translations.kyc_email_editor_message[$root.locale.key]), $parent.translations.kyc_email_editor_link[$root.locale.key], $root.config.kycs[kycIndex].email.link)" />
                        <br><br>
                        <span class="email-preview-button">{{ $parent.translations.kyc_email_editor_button[$root.locale.key] }}</span>
                        <br><br><br>
                        <div v-html="replaceLink(replaceBrackets($parent.translations.kyc_email_editor_hint[$root.locale.key]), $parent.translations.kyc_email_editor_link[$root.locale.key], $root.config.kycs[kycIndex].email.link)" />
                        <br>
                        <p>{{ replaceBrackets($parent.translations.kyc_email_editor_footer[$root.locale.key]) }}</p>
                        <br><br>
                    </div>
                </vap-card>
            </template>
        </vap-form-divider>

        <vap-form-divider :left="50" :right="50" style="margin-top: 30px;">
            <template v-slot:left>
                <vap-label :text="__('settings_email_sign')" header style="margin-bottom: 5px;">
                    <template v-slot:actions>
                        <vap-icon :icon="'help-o'"><template v-slot:tooltip><vap-tooltip :localized="'settings_dynamic_info'"></vap-tooltip></template></vap-icon>
                    </template>
                </vap-label>
                <vap-card v-if="$parent.translations.kyc_email_editor_header">
                    <vap-row v-if="$root.config.kycs[kycIndex].email">
                        <vap-group c12>
                            <vap-label :text="'Logo URL'" desc />
                            <vap-input v-model="$root.config.kycs[kycIndex].email.logo" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_subject')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_signer_subject[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_greeting')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_signer_header[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_message')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_signer_message[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_help_text')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_signer_hint[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_contact')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_signer_footer[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_help_link')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_signer_link[$root.locale.key]" noValidation />
                        </vap-group>
                    </vap-row>
                </vap-card>
            </template>
            <template v-slot:right>
                <vap-label :text="__('settings_preview')" header style="margin-bottom: 5px;" />
                <vap-card v-if="$parent.translations.kyc_email_signer_header">
                    <div class="email-preview" style="text-align: center;">
                        <div class="email-preview-sender">
                            <label>{{ __('settings_sender') + ':' }}</label><label style="margin-left: 8px; font-weight: bold;">{{ replaceBrackets('\{\{senderName\}\}') }}</label>
                        </div>
                        <div class="email-preview-subject">
                            <label>{{ __('settings_subject') + ':' }}</label><label style="margin-left: 8px; font-weight: bold;">{{ replaceBrackets($parent.translations.kyc_email_signer_subject[$root.locale.key]) }}</label>
                        </div>
                        <br><br><br>
                        <img :src="$root.config.kycs[kycIndex].email.logo" :style="'width: 200px;'" />
                        <br><br>
                        <h2>{{ replaceBrackets($parent.translations.kyc_email_signer_header[$root.locale.key]) }}</h2>
                        <br>
                        <p>{{ replaceBrackets($parent.translations.kyc_email_signer_message[$root.locale.key]) }}</p>
                        <br><br>
                        <span class="email-preview-button">{{ $parent.translations.kyc_email_signer_button[$root.locale.key] }}</span>
                        <br><br><br>
                        <div v-html="replaceLink(replaceBrackets($parent.translations.kyc_email_signer_hint[$root.locale.key]), $parent.translations.kyc_email_signer_link[$root.locale.key], $root.config.kycs[kycIndex].email.link)" />
                        <br>
                        <p>{{ replaceBrackets($parent.translations.kyc_email_signer_footer[$root.locale.key]) }}</p>
                        <br><br>
                    </div>
                </vap-card>
            </template>
        </vap-form-divider>

        <vap-form-divider :left="50" :right="50" style="margin-top: 30px;">
            <template v-slot:left>
                <vap-label :text="__('settings_email_done')" header style="margin-bottom: 5px;">
                    <template v-slot:actions>
                        <vap-icon :icon="'help-o'"><template v-slot:tooltip><vap-tooltip :localized="'settings_dynamic_info'"></vap-tooltip></template></vap-icon>
                    </template>
                </vap-label>
                <vap-card v-if="$parent.translations.kyc_email_editor_header">
                    <vap-row v-if="$root.config.kycs[kycIndex].email">
                        <vap-group c12>
                            <vap-label :text="'Logo URL'" desc />
                            <vap-input v-model="$root.config.kycs[kycIndex].email.logo" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_subject')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_done_subject[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_greeting')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_done_header[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_message')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_done_message[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_help_text')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_done_hint[$root.locale.key]" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="__('settings_contact')" desc />
                            <vap-input v-model="$parent.translations.kyc_email_done_footer[$root.locale.key]" noValidation />
                        </vap-group>
                    </vap-row>
                </vap-card>
            </template>
            <template v-slot:right>
                <vap-label :text="__('settings_preview')" header style="margin-bottom: 5px;" />
                <vap-card v-if="$parent.translations.kyc_email_done_header">
                    <div class="email-preview" style="text-align: center;">
                        <div class="email-preview-sender">
                            <label>{{ __('settings_sender') + ':' }}</label><label style="margin-left: 8px; font-weight: bold;">{{ replaceBrackets('\{\{senderName\}\}') }}</label>
                        </div>
                        <div class="email-preview-subject">
                            <label>{{ __('settings_subject') + ':' }}</label><label style="margin-left: 8px; font-weight: bold;">{{ replaceBrackets($parent.translations.kyc_email_done_subject[$root.locale.key]) }}</label>
                        </div>
                        <br><br><br>
                        <img :src="$root.config.kycs[kycIndex].email.logo" :style="'width: 200px;'" />
                        <br><br>
                        <h2>{{ replaceBrackets($parent.translations.kyc_email_done_header[$root.locale.key]) }}</h2>
                        <br>
                        <p>{{ replaceBrackets($parent.translations.kyc_email_done_message[$root.locale.key]) }}</p>
                        <br><br><br>
                        <div v-html="replaceBrackets($parent.translations.kyc_email_done_hint[$root.locale.key])" />
                        <br>
                        <p>{{ replaceBrackets($parent.translations.kyc_email_done_footer[$root.locale.key]) }}</p>
                        <br><br>
                    </div>
                </vap-card>
            </template>
        </vap-form-divider>

        <vap-label :text="'Velkomstmelding (signere)'" header style="margin-bottom: 5px;" v-if="true == false">
            <template v-slot:actions>
                <vap-icon action :icon="'edit'" @clicked="$emit('locale', 'kyc_signer_message')">
                    <template v-slot:tooltip><vap-tooltip :text="'Endre velkomstmelding'"></vap-tooltip></template>
                </vap-icon>
            </template>
        </vap-label>
        <vap-card v-if="true == false">
            <vap-text-editor :value="___($parent, 'kyc_signer_message')" />
        </vap-card>

    </div>
</template>

<script>

export default {
    name: 'KYCSettings',
    props: {
        number: {
            type: [String, Number],
            default: 'number'
        },
        kycIndex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            
        }
    },
    watch: {
        number: {
            deep: false,
            handler(val) {
                
            }
        }
    },
    methods: {
        

    },
    async mounted() {
        
    }
}
</script>
<style scoped>

.email-preview-button {
    padding: 12px 40px;
    border-radius: 24px;
    color: white;
    background-color: var(--button);
}
.email-preview-sender {
    position: absolute;
    left: 5px;
    top: 5px;
    font-size: 13px;
}
.email-preview-subject {
    position: absolute;
    left: 5px;
    top: 20px;
    font-size: 13px;
}

</style>