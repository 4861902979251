<template>
<div class="vap-tt" @mouseenter="handleEnter" @mouseleave="handleLeave" />
</template>

<script>

export default {
    name: 'vap-tooltip',
    props: {
        localized: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        html: {
            type: String,
            default: ''
        },
        maxWidth: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            show: false,
            wdith: 10,
            bottom: 0,
            left: 0
        }
    },
    methods: {
        handleEnter() {
            const parentWidth = event.target.parentElement.offsetWidth;
            const topPos = event.target.parentElement.getBoundingClientRect().top + window.scrollY;
            const bottomPos = event.target.parentElement.getBoundingClientRect().bottom + window.scrollY;
            const leftPos = event.target.parentElement.getBoundingClientRect().left;
            const rightPos = Number(event.target.parentElement.getBoundingClientRect().right);

            //console.log('win w', window.innerWidth)

            //console.log('right', rightPos);

            const body = document.body, html = document.documentElement;
            const pageHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            let direction = 'up';
            let directionDownOffset = 0;

            const possibleScrollY = pageHeight - window.innerHeight;
            const scrollbarOffsetX = possibleScrollY > 0 ? 20 : 0;
            //console.log('possY', possibleScrollY);

            const scrolledY = window.scrollY;
            //console.log('scrolledY', scrolledY);

            const width = rightPos - leftPos;
            const height = bottomPos - topPos;
            const center = Number(leftPos) + Number(width / 2);
            //console.log('width = ' + width + ', height = ' + height + ', center = ' + center);

            const distanceBottom = window.innerHeight - bottomPos;
            //console.log('distanceFromBottom = ', distanceBottom);

            //console.log('topPos = ' + topPos + ' bottomPos = ' + bottomPos);

            const distanceFromTop = window.innerHeight - distanceBottom - scrolledY;
            //console.log('distanceFromTop = ', distanceFromTop);

            if (distanceFromTop < 80) {
                direction = 'down';
                directionDownOffset = 90;
            }

            let text = '';
            if (this.localized) {
                text = this.__(this.localized);
            }else if (this.html) {
                text = this.__(this.html);
            }else {
                text = this.text;
            }
            //console.log(text);

            let mw = this.getTextWidth(text, '15px arial');

            mw += 20; // padding

            let ttWidth = (mw > 260 && !html) ? 260 : mw;
            if (ttWidth > 1200) ttWidth = 1200;

            if (this.maxWidth > 0 && ttWidth > this.maxWidth) ttWidth = this.maxWidth;

            const halfWidth = ttWidth / 2;

            //console.log('half', halfWidth);

            let rightOverflow = (window.innerWidth - rightPos - scrollbarOffsetX) - halfWidth;
            rightOverflow = rightOverflow < 0 ? Math.abs(rightOverflow) : 0;
            //console.log('right overflow', rightOverflow);


            //console.log('leftpos', leftPos);
            let leftOverflow = (leftPos - scrollbarOffsetX) - halfWidth;
            leftOverflow = leftOverflow < 0 ? Math.abs(leftOverflow) : 0;
            //console.log('left overflow', leftOverflow);
            
            

            const left = center - (Number(ttWidth) / 2) - rightOverflow + leftOverflow;
            const bottom = distanceBottom + height + 10 + possibleScrollY - directionDownOffset;

            //console.log('bottom', bottom);

            const point = (Number(ttWidth) / 2) - 24 + rightOverflow - leftOverflow;
            //console.log('point', point);

            //console.log('direction = ' + direction + ', offset = ' + directionDownOffset);

            const options = {
                text: text,
                width: ttWidth,
                bottom: bottom,
                point: point,
                left: left,
                direction: direction
            }
            this.showTooltipX(options);
        },
        handleLeave() {
            this.hideTooltipX({});
        },
        getTextWidth(text, font) {
            var canvas = document.createElement("canvas");
            var context = canvas.getContext("2d");
            context.font = font;
            var metrics = context.measureText(text);
            return metrics.width;
        }
    }
}
</script>

<style>

.vap-tt {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
}

</style>
