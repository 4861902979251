<template>
<button class="vf-btn" :class="[
        {'vf-btn-outline': outline || working },
        {'vf-btn-disabled': disabled },
        {'vf-btn-warning': warning },
        {'vf-btn-working': working },
        {'vf-btn-spacing': icon || working },
    ]"
    @click="handleClick"
    :style="fixedWidth > 0 ? 'width: ' + fixedWidth + 'px;' : ''" :id="'l' + id">

    {{ text }}

    <div class="vf-btn-icon" v-if="icon && !working">
        <vap-icon :icon="icon" small :color="'ext'" />
    </div>

    <div class="vf-btn-spinner" v-if="working">
        <vap-spinner small />
    </div>

    <div class="vap-button-tt">
        <slot name="tooltip"></slot>
    </div>

</button>
</template>

<script>
export default {
    name: 'vf-button',
    props: {
        text: { type: String, default: 'Button' },
        outline: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        warning: { type: Boolean, default: false },
        working: { type: Boolean, default: false },
        fixedWidth: { type: Number, default: 0 },
        id: { type: String, default: 'id' },
        icon: { type: String, default: '' },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        handleClick() {
            if (this.$parent.designer) {
                this.$emit('logic', this.id);
            }
            if (this.disabled || this.working) return;
            this.$emit('clicked');
        }

    },
    mounted() {

    }
}
</script>
<style scoped>

.vf-btn {
    position: relative;
    border: none;
    outline: none;
    background: none;
    min-height: 40px;
    font-family: 'Signika', sans-serif;
    font-size: 15px;
    font-weight: normal;
    color: var(--button-text);
    padding: 0px 25px;
    border-radius: 3px;
    border: solid 1px var(--button);
    background-color: var(--button);
    cursor: pointer;
    fill: var(--button-text);
}
.vf-btn:hover {
    opacity: 0.8;
}

.vf-btn-spacing {
    padding-left: 50px;
}

.vf-btn-warning {
    border-color: var(--val-error);
    background-color: var(--val-error);
}

.vf-btn-outline {
    color: var(--button);
    background-color: transparent;
    fill: var(--button);
}

.vf-btn-disabled {
    color: var(--disabled-text);
    background-color: var(--disabled-background);
    cursor: default;
    border-color: var(--disabled-background);
    fill: var(--disabled-background);
}
.vf-btn-disabled:hover {
    opacity: 1;
}

.vf-btn-icon {
    position: absolute;
    top: 5px; left: 0px;
}
.vf-btn-disabled .vf-btn-icon {
    fill: var(--disabled-text);
}

.vf-btn-spinner {
    position: absolute;
    top: 26px; left: 10px;
}

.vf-btn-working {
    cursor: not-allowed;
}

.vap-button-tt {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
}

</style>
