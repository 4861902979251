<template>
<div style="min-height: 80vh;">

    <FlowMenu
        @add="onAddStepClicked"
        @save="onSave"
        :addDisabled="selectedIndex != -1"
        :replaceEnabled="customStepSelected"
        @replace="showReplaceDialog"
    />

    <div class="aml-page" style="padding-top: 80px;">
        <vap-form-divider :left="65" :right="35">
            <template v-slot:left>

                <vap-label header2 :text="flow.title[$root.locale.key]" style="margin-bottom: 10px;" />
                <div class="flw100" style="margin-top: -10px; margin-bottom: 10px;" v-if="selectedIndex > -1">
                    {{ 'Step: ' + selectedObject.key }}
                </div>

                <div v-if="!loading && !selectedIsDefault">
                    <ObjectEditor :mainObject="selectedObject" :level="0" :name="'OBJECT'" :type="'object'" isFirst @change="hasChanges = true" ref="objeditor" />
                </div>

                <div class="flw100" style="margin-top: 30px;" v-if="selectedIsDefault">
                    {{ 'This is a default step' }}
                </div>
                <div class="flw100" style="margin-top: 10px;" v-if="selectedIsDefault">
                    <vap-button :text="'Make a customizable copy'" @clicked="showCopyDialog" />
                </div>

            </template>
            <template v-slot:right>

                <vap-label header2 :text="'Steps'" style="margin-bottom: 10px;" />

                <div class="flw100 fev2-itm card-shadow" @click="onSelectObject(-1)" :class="[
                    {'fev2-itm-selected': selectedIndex == -1 }
                ]">
                    {{ 'Flow configuration' }}
                </div>

                <div class="flw100 fev2-itm card-shadow" v-for="(step, i) in flow.steps" :key="'fs' + i" @click="onSelectObject(i)" :class="[
                    {'fev2-itm-selected': selectedIndex == i }
                ]">
                    {{ step.key }}
                </div>

            </template>
        </vap-form-divider>
    </div>

    <AddStepDialog v-if="addStepDialogVisible" :flow="flow" @close="addStepDialogVisible = false" @add="reloadFlow" />

    <ve-popup v-if="copyDialog.show" :header="'Copy step'" :width="600" @close="copyDialog.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <div>
                <vap-row>
                    <vap-group c12>
                        <vap-label desc :text="'Key'" required />
                        <vap-input v-model="copyDialog.key" :rules="'required'" noValidation noHighlight @input="validateCopyDialog" />
                    </vap-group>
                </vap-row>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="copyDialog.show = false" />
                <vap-button :text="'Copy step'" uppercase @clicked="onCopyStep" :disabled="!copyDialog.valid" :working="copyDialog.working" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="replaceDialog.show" :header="'Replace key'" :width="600" @close="replaceDialog.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <div>
                <vap-row>
                    <vap-group c6>
                        <vap-label desc :text="'Old key'" required />
                        <vap-input v-model="replaceDialog.oldKey" :rules="'required'" noValidation noHighlight @input="validateReplaceDialog" />
                    </vap-group>
                    <vap-group c6>
                        <vap-label desc :text="'New key'" required />
                        <vap-input v-model="replaceDialog.newKey" :rules="'required'" noValidation noHighlight @input="validateReplaceDialog" />
                    </vap-group>
                </vap-row>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="replaceDialog.show = false" />
                <vap-button :text="'Replace'" uppercase @clicked="onReplace" :disabled="!replaceDialog.valid" :working="replaceDialog.working" />
            </vap-button-row>
        </div>
    </ve-popup>

</div>
</template>

<script>
import api from '../portalapi';
import v1flows from '../v1helpers/v1flows';

import FlowMenu from './FlowMenu.vue';
import AddStepDialog from './AddStepDialog.vue';

import ObjectEditor from './ObjectEditor.vue';

export default {
    name: 'FlowEditor',
    components: {
        FlowMenu,
        AddStepDialog,
        ObjectEditor
    },
    props: {
        flow: { type: Object, default: null }
    },
    data() {
        return {
            loading: true,

            addStepDialogVisible: false,

            copyDialog: { show: false, working: false, valid: false, key: '', existingKey: '' },
            replaceDialog: { show: false, working: false, valid: false, oldKey: '', newKey: '' },

            objectTest: {},

            selectedIndex: -2,
            selectedObject: {},
            selectedIsDefault: false,
            hasChanges: false,

            customStepSelected: false,
        }
    },
    methods: {

        showReplaceDialog() {
            if (this.selectedObject.form) this.replaceDialog.oldKey = this.selectedObject.form;
            this.replaceDialog.show = true;
            this.validateReplaceDialog();
        },

        validateReplaceDialog() {
            if (this.replaceDialog.oldKey && this.replaceDialog.newKey) {
                this.replaceDialog.valid = true;
            }else {
                this.replaceDialog.valid = false;
            }
        },

        async onReplace() {
            this.replaceDialog.working = true;
            let obj = null;
            for (const i in this.$root.flowConfig.steps) {
                if (this.$root.flowConfig.steps[i].key == this.selectedObject.key) {
                    obj = this.$root.flowConfig.steps[i];
                }
            }
            this.replaceRecursion(obj, this.replaceDialog.oldKey, this.replaceDialog.newKey);
            this.onSelectObject(this.selectedIndex);
            this.replaceDialog.show = false;
            this.replaceDialog.working = false;
        },

        replaceRecursion(obj, oldKey, newKey) {
            for (const key in obj) {
                console.log(typeof obj[key]);
                if (typeof obj[key] == 'object') {
                    this.replaceRecursion(obj[key], oldKey, newKey);
                }else if (typeof obj[key] == 'string') {
                    obj[key] = obj[key].replace(oldKey, newKey).replace(oldKey, newKey);
                }
            }
        },

        showCopyDialog() {
            console.log('selected', this.selectedObject);

            this.copyDialog.key = this.selectedObject.key;
            this.copyDialog.existingKey = this.selectedObject.key;

            this.copyDialog.show = true;
            this.validateCopyDialog();
        },

        validateCopyDialog() {
            let valid = false;
            valid = true;
            this.copyDialog.valid = valid;
        },

        async onCopyStep() {
            this.copyDialog.working = true;

            const newStep = JSON.parse(JSON.stringify(this.selectedObject));

            newStep.key = this.copyDialog.key;
            newStep.isDefault = false;

            for (const i in this.flow.steps) {
                if (this.flow.steps[i].key == this.copyDialog.existingKey) {
                    this.flow.steps[i].key = newStep.key;
                }
            }

            this.$root.flowConfig.steps.push(newStep);

            await api.setFlows('na', this.$root.flowConfig);
            this.reloadFlow();

            this.copyDialog.working = false;
            this.copyDialog.show = false;
        },

        async onSelectObject(index) {
            this.loading = true;
            this.selectedIndex = index;
            console.log('selecting index', index);

            if (index == -1) {
                this.selectedObject = JSON.parse(JSON.stringify(this.flow));
                this.selectedIsDefault = false;
                console.log('selected object: ', this.selectedObject);
            }else {
                this.selectedObject = JSON.parse(JSON.stringify(v1flows.getStep(this.$root, this.flow.steps[index].key)));
                this.selectedIsDefault = this.selectedObject.isDefault;
            }

            this.customStepSelected = (index != -1 && !this.selectedIsDefault);

            await new Promise(r => setTimeout(r, 10));
            this.loading = false;
        },

        onAddStepClicked() {
            this.addStepDialogVisible = true;
        },

        async onSave() {
            console.log('saving');

            const obj = this.$refs.objeditor.getObject();
            console.log('obj', obj);

            if (this.selectedIndex == -1) {
                for (const i in this.$root.flowConfig.flows) {
                    if (this.$root.flowConfig.flows[i].key == this.selectedObject.key) {
                        this.$root.flowConfig.flows[i] = obj;
                    }
                }
            }else if (this.selectedIndex > -1) {
                for (const i in this.$root.flowConfig.steps) {
                    if (this.$root.flowConfig.steps[i].key == this.selectedObject.key) {
                        this.$root.flowConfig.steps[i] = obj;
                    }
                }
            }

            await api.setFlows('na', this.$root.flowConfig);
        },

        reloadFlow() {
            this.onSelectObject(this.selectedIndex);
        }

    },
    async created() {
        
    }
}
</script>
<style scoped>

.fev2-itm {
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 18px;
    border-radius: 5px;
    border: solid 2px var(--card-background);
    background-color: var(--card-background);
    cursor: pointer;
}

.fev2-itm-selected {
    border: solid 2px var(--accent);
}

</style>