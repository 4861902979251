<template>
<div class="slim-page">
    
    <vap-label header :text="__('customer_new')" style="margin-top: 100px; margin-bottom: 20px;" />

    <!-- Client type selector -->
    <vap-card list noPadding v-if="typeVisible" style="margin-bottom: 40px;">
        <vap-tabs v-model="typeModel" :tabs="typeOptions" @input="onTypeChanged" />
    </vap-card>

    <!-- Person selector -->
    <vap-card list noPadding v-if="typeModel.person">
        <div v-if="typeModel.person" class="new-company-container" style="margin-top: 10px;">
            <vap-row>
                <vap-group c6>
                    <vap-label :text="__('common_first_name')" desc required />
                    <vap-input v-model="personModel.firstName" noValidation :placeholder="__('common_required')" @input="validatePerson" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_last_name')" desc required />
                    <vap-input v-model="personModel.lastName" noValidation :placeholder="__('common_required')" @input="validatePerson" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_dob')" desc />
                    <vap-datepicker v-model="personModel.dateOfBirth" outputISO noValidation :placeholder="__('common_optional')" @input="validatePerson" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_citizenship')" desc />
                    <vap-dropdown v-model="personModel.country" noValidation
                        :options="$root.countriesJson"
                        :commonOptions="$root.countriesCommon"
                        :placeholder="__('common_optional')"
                        :listKey="$root.locale.key"
                        :displayKey="$root.locale.key"
                        :outputKey="'key'"
                        @input="validatePerson" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_email')" desc />
                    <vap-input v-model="personModel.email" noValidation
                        :placeholder="__('common_optional')"
                        @input="validatePerson" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_phone')" desc />
                    <vap-phone v-model="personModel.phone" noValidation
                        :placeholder="__('common_optional')"
                        @input="validatePerson" />
                </vap-group>
                <vap-group c12 v-if="flowOptions.length > 0">
                    <vap-label :text="__t('search-flow')" desc />
                    <vap-dropdown v-model="flowModel" :options="flowOptions" :displayKey="'name'" :outputKey="'key'" :listKey="'name'" @input="onFlowSelected" />
                </vap-group>
            </vap-row>
        </div>
        <div class="flw100" style="padding-bottom: 20px; padding-right: 25px;">
            <vap-button-row alignRight>
                <vap-button :text="__('common_cancel')" action uppercase outline @clicked="resetSearch" />
                <vap-button :text="__('common_save')" action uppercase :dotDisabled="!personModel.valid" @clicked="createPerson" :working="working" />
            </vap-button-row>
        </div>
    </vap-card>

    <!-- Company country selector -->
    <vap-card list noPadding v-if="typeModel.company && !companyModel.other">
        <vap-tabs v-model="companyModel" :tabs="companyOptions" @input="onTypeChanged" />

        <SearchNO ref="searchNO" v-if="companyModel.norway" @result="onCompanySelected" />
        <SearchUK ref="searchUK" v-if="companyModel.uk" @result="onCompanySelected" />
        <SearchSE ref="searchSE" v-if="companyModel.sweden" @result="onCompanySelected" />

        <div style="padding: 20px 30px; padding-top: 0px;" class="flw100">

            <vap-row v-if="selectedCompany && !companyModel.norway">
                <vap-group c6>
                    <vap-label :text="__('common_name')" desc />
                    <vap-label :text="selectedCompany.name" value />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('company_orgnr')" desc />
                    <vap-label :text="selectedCompany.identity" value />
                </vap-group>
            </vap-row>

            <vap-row v-if="selectedCompany && companyModel.sweden && selectedCompany.address">
                <vap-group c12>
                    <vap-label :text="__('common_address')" desc />
                    <vap-label :text="selectedCompany.address" value />
                </vap-group>
            </vap-row>

            <CompanyInputs v-if="selectedCompany && companyModel.norway" v-model="selectedCompany.brreg" simple :editMode="false" style="margin-top: -20px;" />

            <vap-row v-if="selectedCompany">
                <vap-group c12 v-if="flowOptions.length > 0">
                    <vap-label :text="__t('search-flow')" desc />
                    <vap-dropdown v-model="flowModel" :options="flowOptions" :displayKey="$root.locale.key" :outputKey="'key'" :listKey="$root.locale.key" @input="onFlowSelected" />
                </vap-group>
            </vap-row>

            <div v-if="selectedCompany" class="flw100">
                <vap-label v-if="alreadyCustomer" :text="__('customer_new_exists')" error alignRight />
                <vap-label v-if="noValidFlows" :text="'No valid flow for this client type'" error alignRight />
                <vap-button-row alignRight style="margin-top: 10px;">
                    <vap-button :text="__('common_cancel')" action uppercase outline @clicked="resetSearch" />
                    <vap-button :text="__('common_open')" action uppercase v-if="alreadyCustomer" @clicked="openExisting" :working="working" />
                    <vap-button :text="__('common_save')" action uppercase :dotDisabled="alreadyCustomer || noValidFlows" @clicked="createCompany" :working="working" />
                </vap-button-row>
            </div>

        </div>
    </vap-card>


    <!-- Manual company -->
    <vap-card list noPadding v-if="typeModel.company && companyModel.other">
        <vap-tabs v-model="companyModel" :tabs="companyOptions" @input="onTypeChanged" />

        <div style="padding: 20px 30px; padding-top: 0px;" class="flw100">

            <vap-row style="margin-top: 40px;">
                <vap-group c12>
                    <vap-label :text="__('common_name')" desc required />
                    <vap-input v-model="forreignModel.name" @input="validateForreign" :placeholder="__('common_required')" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('company_orgnr')" desc />
                    <vap-input v-model="forreignModel.orgnr" @input="validateForreign" :placeholder="__('common_optional')" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_country')" desc />
                    <vap-dropdown v-model="forreignModel.country" noValidation
                        :options="$root.countriesJson"
                        :commonOptions="$root.countriesCommon"
                        :placeholder="__('common_optional')"
                        :listKey="$root.locale.key"
                        :displayKey="$root.locale.key"
                        :outputKey="'key'"
                        @input="validateForreign" />
                </vap-group>
            </vap-row>

            <vap-row>
                <vap-group c12 v-if="flowOptions.length > 0">
                    <vap-label :text="'flow'" desc />
                    <vap-dropdown v-model="flowModel" :options="flowOptions" :displayKey="$root.locale.key" :outputKey="'key'" :listKey="$root.locale.key" @input="onFlowSelected" />
                </vap-group>
            </vap-row>

            <div class="flw100">
                <vap-label v-if="alreadyCustomer" :text="__('customer_new_exists')" error alignRight />
                <vap-label v-if="noValidFlows" :text="'No valid flow for this client type'" error alignRight />
                <vap-button-row alignRight style="margin-top: 10px;">
                    <vap-button :text="__('common_cancel')" action uppercase outline @clicked="resetSearch" />
                    <vap-button :text="__('common_open')" action uppercase v-if="alreadyCustomer" @clicked="openExisting" :working="working" />
                    <vap-button :text="__('common_save')" action uppercase :dotDisabled="alreadyCustomer || noValidFlows" @clicked="createManualCompany" :working="working" />
                </vap-button-row>
            </div>

        </div>
    </vap-card>

    <!-- Import button -->
    <vap-card v-if="$root.config.features.importClient" noPadding style="margin-bottom: 10px;">
        <vap-item fancy fancyCircle padding20 :fancyIcon="'upload'"
            :fancyText="'Import customer'"
            :fancyHint="'Create a new customer from a previously exported customer file'"
            @clicked="importDialog.show = true">
        </vap-item>
    </vap-card>

    <ImportClientDialog v-if="importDialog.show" @close="importDialog.show = false" />

</div>
</template>
<script>
import dataModelV1 from '../v1data/dataModelV1';
import dataNorwayV1 from '../v1data/dataNorwayV1';
import dataSwedenV1 from '../v1data/dataSwedenV1';
import dataUKV1 from '../v1data/dataUKV1';

import v1api from '../v1helpers/v1api';
import v1flows from '../v1helpers/v1flows';
import v1receipt from '../v1helpers/v1receipt';
import v1lookupHelper from '../v1search/v1lookupHelper';

import v1backoffice from '../v1admin/v1backoffice';

import tools from '../tools';

import SearchUK from '../v1search/SearchUK.vue';
import SearchNO from '../v1search/SearchNO.vue';
import SearchSE from '../v1search/SearchSE.vue';

import ImportClientDialog from '../v1ui/ImportClientDialog.vue';
import CompanyInputs from '../components/kyc/CompanyInputs.vue';

export default {
    name: 'SearchV1',
    components: {
        SearchUK,
        SearchNO,
        SearchSE,
        ImportClientDialog,
        CompanyInputs
    },
    data() {
        return {
            typeVisible: false,

            typeModel: {},
            typeOptions: {},

            companyModel: {},
            companyOptions: {},

            selectedCompany: null,
            selectedFlow: null,
            noValidFlows: false,

            working: false,
            alreadyCustomer: false,
            existingId: '',

            flowModel: '',
            flowOptions: [],

            forreignModel: {
                name: '',
                orgnr: '',
                country: '',
            },

            personModel: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                country: '',
                reference: '',
                phone: {
                    prefix: '+47',
                    number: ''
                },
                email: '',
                valid: false
            },

            departmentModel: '',
            departments: [],

            importDialog: { show: false }
        }
    },
    methods: {

        validatePerson() {
            this.personModel.valid = true;
        },

        validateForreign() {
            this.personModel.valid = true;
        },

        onFlowSelected(flowKey) {
            this.selectedFlow = v1flows.getFlow(this.$root, flowKey);
        },

        onDepartmentSelected() {

        },

        onCompanySelected(selectedCompany) {
            this.selectedCompany = selectedCompany;

            const flows = v1flows.getAvailableFlows(this.$root, 'org', selectedCompany.country, selectedCompany.source);
            //console.log('flows', flows);

            this.loadAvailableFlows(flows);
        },

        onTypeChanged() {
            if (this.typeModel.person) {

                const flows = v1flows.getAvailableFlows(this.$root, 'person', 'un', 'manual');
                //console.log('flows', flows);
                this.loadAvailableFlows(flows);

            }else if (this.typeModel.company && this.companyModel.other) {

                const flows = v1flows.getAvailableFlows(this.$root, 'org', 'un', 'manual');
                //console.log('flows', flows);
                this.loadAvailableFlows(flows);

            }
        },

        loadAvailableFlows(flows) {
            this.flowOptions = [];
            if (flows.length > 1) {
                // There are multiple available flows for this client type
                // Show the flow dropdown

                for (const i in flows) {
                    const flow = flows[i];
                    for (const key in flow.title) {
                        flow[key] = flow.title[key];
                    }
                    this.flowOptions.push(flows[i]);
                }
                this.flowModel = this.flowOptions[0].key;
                this.selectedFlow = v1flows.getFlow(this.$root, this.flowOptions[0].key);
                
            }else if (flows.length == 0) {
                // No available flow for this client type, show error message
                this.noValidFlows = true;
            }else {
                // Just one flow available
                this.selectedFlow = flows[0];
            }
        },

        async createPerson() {
            this.working = true;
            const client = {};

            client.name = this.personModel.firstName + ' ' + this.personModel.lastName;
            client.identity = (this.personModel.dateOfBirth || '1800-01-01') + '-' + this.personModel.firstName.toLowerCase() + '-' + this.personModel.lastName.toLowerCase();
            client.country = this.personModel.country;

            client.type = 'person';
            client.source = 'manual';
            client.flow = this.selectedFlow.key;
            client.letter = this.getLetter(client.name);

            const personData = {
                name: this.personModel.firstName.toLowerCase() + ' ' + this.personModel.lastName.toLowerCase(),
                firstName: this.personModel.firstName,
                lastName: this.personModel.lastName,
                dateOfBirth: this.personModel.dateOfBirth || '1800-01-01',
                country: this.personModel.country,
                email: this.personModel.email,
                phone: this.personModel.phone,
                roles: ['role_individual']
            };

            const response = await v1api.createClient(client, null, personData);
            if (response.status == 200) {

                // 200 response means the client already exists
                this.alreadyCustomer = true;
                this.existingId = response.id;

            }else if (response.status == 201) {

/*                 const envelope = await v1receipt.createClientEnvelope(this.$root, client, null);

                const updateData = {
                    envelopeId: envelope.id,
                    documentId: envelope.documentId
                };
                await v1api.updateClient(response.id, updateData); */

                // Client was created, navigate to client view
                this.$parent.navigate({ path: '/v1client', query: { id: response.id }});

            }
            this.working = false;
        },

        async createManualCompany() {
            this.working = true;
            const client = {};

            client.name = this.forreignModel.name;
            client.identity = this.forreignModel.orgnr;
            client.country = this.forreignModel.country;

            if (!client.identity) {
                client.identity = 'AUTO_' + tools.getUniqueId().toUpperCase();
            }

            client.type = 'org';
            client.source = 'manual';
            client.flow = this.selectedFlow.key;
            client.letter = this.getLetter(client.name);

            if (this.$root.config.params.hasDepartments) client.department = this.$root.credentials.branch.toString();

            const companyData = dataModelV1.getEmptyCompany();

            const response = await v1api.createClient(client, companyData, null);
            if (response.status == 200) {

                // 200 response means the client already exists
                this.alreadyCustomer = true;
                this.existingId = response.id;

            }else if (response.status == 201) {

                // Client was created, navigate to client view
                this.$parent.navigate({ path: '/v1client', query: { id: response.id }});

            }
            this.working = false;
        },

        async createCompany() {
            this.working = true;
            const client = {};

            client.name = this.selectedCompany.name;
            client.identity = this.selectedCompany.identity;
            client.country = this.selectedCompany.country;

            client.type = 'org';
            client.source = this.selectedCompany.source;
            client.flow = this.selectedFlow.key;
            client.letter = this.getLetter(client.name);

            if (this.$root.config.params.hasDepartments) client.department = this.$root.credentials.branch.toString();

            const result = await v1lookupHelper.getCompanyData(this.$root, client.source, client.identity, this.selectedCompany);

            const response = await v1api.createClient(client, result.companyData, null);
            if (response.status == 200) {

                // 200 response means the client already exists
                this.alreadyCustomer = true;
                this.existingId = response.id;

            }else if (response.status == 201) {

                const envelope = await v1receipt.createClientEnvelope(this.$root, client, result.companyData, this.selectedFlow, result.rawData);

                const updateData = {
                    envelopeId: envelope.id,
                    documentId: envelope.documentId,
                    name: this.__t('search-name') + ' ' + this.companyTitleCase(client.name)
                };
                await v1api.updateClient(response.id, updateData);

                try {
                    const statData = {
                        type: 'org',
                        cc: this.$root.config.params.baseCountry,
                        src: client.source,
                        eid: envelope.id,
                        cid: this.$root.credentials.cid,
                        ckey: this.$root.credentials.key,
                        cname: this.$root.user.companyName,
                        stat: this.$root.config.params.accountStatus
                    };
                    v1backoffice.addStat(statData);
                }catch (exs) {
                    console.error('Error adding stat', exs);
                }

                for (const i in result.reports) {
                    await v1receipt.addBankIDReportToClientEnvelope(this.$root, envelope, response.id, result.reports[i]);
                }

                // Client was created, navigate to client view
                this.$parent.navigate({ path: '/v1client', query: { id: response.id }});

            }
            this.working = false;
        },

/*         async getCompanyData() {
            const result = {
                companyData: dataModelV1.getEmptyCompany(),
                reports: [],
                rawData: null
            };

            if (this.selectedCompany.source == 'chouse') {
                // UK

                const chouseData = await dataUKV1.getCompanyData(this.selectedCompany.identity);
                result.rawData = {
                    chouseData: chouseData
                };
                dataUKV1.processData(result.companyData, chouseData.data);

            }else if (this.selectedCompany.source == 'bankidaml') {
                // Norway

                const bamlData = await dataNorwayV1.getBankIdAmlData(this.$root, this.selectedCompany.identity);
                dataNorwayV1.processData(this.$root, result.companyData, bamlData.data, this.selectedCompany.brreg);
                result.reports = bamlData.reports;

            }else if (this.selectedCompany.source == 'trapets-bisnode') {
                // Sweden

                const bisnodeData = await dataSwedenV1.getBisnodeData(this.selectedCompany.identity);
                dataSwedenV1.processData(this.$root, result.companyData, this.selectedCompany.trapetsData, bisnodeData.data);

                console.log('company', result.companyData);

            }

            return result;
        }, */

        resetSearch() {
            let defaultPrefix = '+47';
            if (this.$root.config.params.defaultLanguage == 'sv_SE') defaultPrefix = '+46';

            this.noValidFlows = false;
            this.selectedCompany = null;
            if (this.$refs.searchNO) this.$refs.searchNO.resetResult();
            this.personModel = {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                country: '',
                reference: '',
                phone: {
                    prefix: defaultPrefix,
                    number: ''
                },
                email: '',
                valid: false
            };
        },

        openExisting() {
            this.$parent.navigate({ path: '/v1client', query: { id: this.existingId }});
        },

        getLetter(name) {
            let letter = '#';
            const validLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            if (validLetters.includes(name.charAt(0).toUpperCase())) {
                letter = name.charAt(0).toUpperCase();
            }
            return letter;
        },

        loadAvailableOptions() {
            // Check modules to see what options will be available

            this.addCompanyOption(this.$root.config.search.org.no, 'norway', 'NO', 'nb_NO');
            this.addCompanyOption(this.$root.config.search.org.se, 'sweden', 'SE', 'sv_SE');
            this.addCompanyOption(this.$root.config.search.org.uk, 'uk', 'GB', 'en_EN');

            if (this.$root.config.search.forreign.enabled) {
                this.$set(this.companyOptions, 'other', { key: 'common_other', image: this.$root.images.flags.un_UN });
                this.$set(this.companyModel, 'other', false);
            }


            if (Object.keys(this.companyOptions).length > 0) {
                this.$set(this.typeOptions, 'company', { key: 'customer_new_org', icon: 'company' });
                this.$set(this.typeModel, 'company', this.$root.config.portal.defaultType == 'company');
            }
            if (this.$root.config.search.person.enabled) {
                this.$set(this.typeOptions, 'person', { key: 'customer_new_person', icon: 'people' });
                this.$set(this.typeModel, 'person', this.$root.config.portal.defaultType == 'person');
            }

            if (Object.keys(this.companyOptions).length > 0 && this.$root.config.search.person.enabled) {
                this.typeVisible = true;
            }
        },

        addCompanyOption(config, countryName, countryCode, flag) {
            if (config.enabled) {
                this.$set(this.companyOptions, countryName, { label: this.getCountryName(countryCode), image: this.$root.images.flags[flag] });
                this.$set(this.companyModel, countryName, config.default);
            }
        }

    },
    created() {
        if (this.$root.config.search.v2search) {
            this.$parent.navigate({ path: '/v2search' });
            return;
        }
        
        if (this.$root.config.params.hasDepartments) {
            this.departments = [];
            for (const i in this.$root.departments) {
                this.departments.push({
                    key: this.$root.departments[i].key,
                    name: this.$root.departments[i].name
                });
            }
        }

        this.personModel.phone.prefix = this.$root.config.params.defaultPrefix;

        this.loadAvailableOptions();
    }
}
</script>