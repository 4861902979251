<template>
<div>

    <PopupV1 noPadding designer :width="900" :maxHeight="500" :header="'Add input'" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <div class="frv2-dlist-container">
                <div class="frv2-dlist-column">

                    <PropertyItem :header="'Common inputs'"> 
                        <PropertyListItem v-for="(item, i) in listCommon" :key="'ic' + i"
                            v-show="!item.hidden"
                            :text="item.display"
                            :icon="item.icon"
                            @clicked="onAddInput(item.type)"
                        />
                    </PropertyItem>

                    <PropertyItem :header="'Special inputs'"> 
                        <PropertyListItem v-for="(item, i) in listSpecial" :key="'is' + i"
                            v-show="!item.hidden"
                            :text="item.display"
                            :icon="item.icon"
                            @clicked="onAddInput(item.type)"
                        />
                    </PropertyItem>

                </div>
                <div class="frv2-dlist-column">

                   <PropertyItem :header="'Input groups'"> 
                        <PropertyListItem v-for="(item, i) in listGroups" :key="'ic' + i"
                            v-show="!item.hidden"
                            :text="item.display"
                            :icon="item.icon"
                            @clicked="onAddInput(item.type)"
                        />
                    </PropertyItem>

                    <PropertyItem :header="'Info'"> 
                        <PropertyListItem v-for="(item, i) in listInfo" :key="'is' + i"
                            v-show="!item.hidden"
                            :text="item.display"
                            :icon="item.icon"
                            @clicked="onAddInput(item.type)"
                        />
                    </PropertyItem>
                    
                </div>
                <div class="frv2-dlist-column">
                    
                    <PropertyItem :header="'Other'"> 
                        <PropertyListItem v-for="(item, i) in listOther" :key="'is' + i"
                            v-show="!item.hidden"
                            :text="item.display"
                            :icon="item.icon"
                            @clicked="onAddInput(item.type)"
                        />
                    </PropertyItem>
                    
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="$emit('close')" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import textHelper from '../textHelper';
import inputHelper from '../inputs/inputHelper';

import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFButton from '../items/VFButton.vue';

import PropertyItem from './properties/PropertyItem.vue';
import PropertyListItem from './ui/PropertyListItem.vue';

export default {
    name: 'FormInputDialogV2',
    components: {
        PopupV1,
        VFButton,
        PropertyItem,
        PropertyListItem,
    },
    props: {
        params: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: {},
            listCommon: [
                { type: 'text', icon: 'input-text', display: 'Text input' },
                /* { type: 'number', icon: 'input-number', display: 'Number input' }, */
                { type: 'check', icon: 'input-check', display: 'Checkbox' },
                { type: 'check2', icon: 'input-check', display: 'Checkbox V2', hidden: !(this.$root.credentials.isAdmin) },
                { type: 'yesno', icon: 'input-check', display: 'Checkbox V2 (Yes/no)', hidden: !(this.$root.credentials.isAdmin) },
                { type: 'drop', icon: 'input-drop', display: 'Dropdown' },
                { type: 'textarea', icon: 'input-text', display: 'Text Area' },
            ],
            listSpecial: [
                { type: 'date', icon: 'input-date', display: 'Datepicker' },
                { type: 'country', icon: 'locale', display: 'Country selector' },
                { type: 'language', icon: 'locale', display: 'Language selector' },
                { type: 'email', icon: 'alpha', display: 'Email input' },
                { type: 'phone', icon: 'phone', display: 'Phone input', hidden: !(this.$root.config.forms.editor.advanced || this.$root.credentials.isAdmin) },
            ],
            listGroups: [
                { type: 'address', icon: 'home', display: 'Address group' },
                { type: 'person', icon: 'person', display: 'Person group' },
                { type: 'recipient', icon: 'person', display: 'Recipient group' },
                { type: 'buttons', icon: 'button', display: 'Button group', hidden: !(this.$root.credentials.isAdmin && !this.params.isList) },
            ],
            listInfo: [
                { type: 'error', icon: 'cancel', display: 'Error message' },
                /* { type: 'helpt', icon: 'help-o', display: 'Info text' }, */
                { type: 'ftext', icon: 'strikethrough', display: 'Formatted text' },
                { type: 'ttip', icon: 'help-o', display: 'Tooltip' },
            ],
            listOther: [
                { type: 'upload', icon: 'upload', display: 'File upload', hidden: !((this.$root.config.forms.editor.advanced || this.$root.credentials.isAdmin) && !this.params.isList) },
                { type: 'preview', icon: 'download', display: 'File preview', hidden: !((this.$root.config.forms.editor.advanced || this.$root.credentials.isAdmin) && !this.params.isList) },
                { type: 'brreg', icon: 'search', display: 'Brreg search', hidden: !(this.$root.credentials.isAdmin && !this.params.isList) },
                { type: 'valsum', icon: 'check', display: 'Validation summary', hidden: !(this.$root.credentials.isAdmin && !this.params.isList) },
                { type: 'greet', icon: 'alpha', display: 'Greeting preview', hidden: !(this.$root.credentials.isAdmin) },
                { type: 'htmlraw', icon: 'align-left', display: 'HTML', hidden: !((this.$root.config.forms.editor.advanced || this.$root.credentials.isAdmin) && !this.params.isList) },
                { type: 'appm', icon: 'check', display: 'Multiple approval', hidden: !((this.$root.config.forms.editor.advanced || this.$root.credentials.isAdmin) && !this.params.isList) },
            ]
        }
    },
    methods: {
        
        onAddInput(type) {
            switch (type) {
                case 'text': return this.onAddTextInput();
                case 'textarea': return this.onAddTextArea();
                case 'number': return this.onAddNumberInput();
                case 'check': return this.onAddCheckbox();
                case 'check2': return inputHelper.onAddCheckbox2(this.reference);
                case 'yesno': return inputHelper.onAddYesNo(this.reference);
                case 'drop': return this.onAddDropdown();
                case 'date': return this.onAddDatepicker();
                case 'email': return this.onAddEmailInput();
                case 'phone': return this.onAddPhoneInput();
                case 'address': return this.onAddAddressGroup();
                case 'helpt': return this.onAddHelpText();
                case 'ftext': return this.onAddFormattedText();
                case 'person': return this.onAddPersonGroup();
                case 'recipient': return this.onAddRecipientGroup();
                case 'buttons': return this.onAddButtonGroup();
                case 'upload': return this.onAddFileupload();
                case 'preview': return this.onAddFilepreview();
                case 'country': return this.onAddCountrySelector();
                case 'language': return this.onAddLanguageSelector();
                case 'error': return this.onAddError();
                case 'htmlraw': return this.onAddHTMLRaw();
                case 'brreg': return this.onAddBrreg();
                case 'valsum': return inputHelper.onAddValsum(this.reference);
                case 'ttip': return inputHelper.onAddTooltip(this.reference);
                case 'greet': return inputHelper.onAddGreetingPreview(this.reference);
                case 'appm': return inputHelper.onAddApproveMultiple(this.reference);
            }
        },

        onAddBrreg() {
            this.$parent.counter += 1;

            const input = {
                key: 'b' + this.$parent.counter,
                type: 'brreg',
                width: '12',
                desc: textHelper.getEmptyTextObject(this.reference),
                required: true,
                pdf: false
            };
            this.$emit('add', [input]);
        },

        onAddError() {
            this.$parent.counter += 1;

            const input = {
                key: 'e' + this.$parent.counter,
                type: 'error',
                width: '12',
                desc: textHelper.getEmptyTextObject(this.reference),
                required: true,
                pdf: false
            };
            this.$emit('add', [input]);
        },

        onAddHTMLRaw() {
            this.$parent.counter += 1;

            const input = {
                key: 'h' + this.$parent.counter,
                type: 'htmlraw',
                width: '12',
                desc: {
                    textarea: true,
                    en_EN: '<h4>html en_EN</h4>',
                    nb_NO: '<h4>html nb_NO</h4>',
                    sv_SE: '<h4>html sv_SE</h4>'
                },
                required: false,
                pdf: true
            };
            this.$emit('add', [input]);
        },

        onAddLanguageSelector() {
            this.$parent.counter += 1;

            const input = {
                key: 'l' + this.$parent.counter,
                type: 'language',
                width: '6',
                desc: {
                    en_EN: 'Language',
                    nb_NO: 'Språk',
                    sv_SE: 'Språk'
                },
                source: 'form',
                initial: 'system',
                required: true,
                noPadding: false,
                pdf: true
            };
            this.$emit('add', [input]);
        },

        onAddFileupload() {
            this.$parent.counter += 1;

            const input = {
                key: 'f' + this.$parent.counter,
                type: 'upload',
                width: '12',
                desc: {
                    en_EN: 'Upload file',
                    nb_NO: 'Last opp fil',
                    sv_SE: 'Ladda upp fil'
                },
                hint: {
                    en_EN: 'Drag and drop a file here or',
                    nb_NO: 'Dra og slipp en fil her eller',
                    sv_SE: 'Dra och släpp en fil här eller'
                },
                browse: {
                    en_EN: 'select file',
                    nb_NO: 'velg fil',
                    sv_SE: 'välj en fil'
                },
                fileType: 'pdf',
                singleFile: false,
                autoPreview: false,
                required: true,
                noPadding: false,
                pdf: true
            };
            this.$emit('add', [input]);
        },

        onAddFilepreview() {
            this.$parent.counter += 1;

            const input = {
                key: 'f' + this.$parent.counter,
                type: 'preview',
                width: '12',
                required: false,
                noPadding: false,
                pdf: false
            };
            this.$emit('add', [input]);
        },

        onAddHelpText() {
            this.$parent.counter += 1;

            const input = {
                key: 'i' + this.$parent.counter,
                type: 'helpt',
                width: '12',
                desc: textHelper.getEmptyTextObject(this.reference),
                required: false,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.$emit('add', [input]);
        },

        onAddFormattedText() {
            this.$parent.counter += 1;

            const input = {
                key: 'f' + this.$parent.counter,
                type: 'ftext',
                width: '12',
                desc: textHelper.getEmptyTextObject(this.reference),
                required: false,
                noPadding: false,
                pdf: true,
                pdfOnly: false,
                raw: false
            };
            this.$emit('add', [input]);
        },

        onAddCountrySelector() {
            this.$parent.counter += 1;

            const input = {
                key: 'c' + this.$parent.counter,
                type: 'country',
                width: '6',
                desc: textHelper.getEmptyTextObject(this.reference),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.$emit('add', [input]);
        },

        onAddTextInput() {
            this.$parent.counter += 1;

            const input = {
                key: 'i' + this.$parent.counter,
                type: 'text',
                inputType: 'text',
                width: '6',
                desc: textHelper.getEmptyTextObject(this.reference),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.$emit('add', [input]);
        },

        onAddTextArea() {
            this.$parent.counter += 1;

            const input = {
                key: 'a' + this.$parent.counter,
                type: 'area',
                inputType: 'text',
                width: '12',
                desc: textHelper.getEmptyTextObject(this.reference),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.$emit('add', [input]);
        },

        onAddNumberInput() {
            this.$parent.counter += 1;

            const input = {
                key: 'i' + this.$parent.counter,
                type: 'text',
                inputType: 'number',
                width: '6',
                desc: textHelper.getEmptyTextObject(this.reference),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.$emit('add', [input]);
        },

        onAddCheckbox() {

            this.$parent.counter += 1;
            const inputKey = 'i' + this.$parent.counter;

            this.$parent.counter += 1;
            const optKey1 = 'x' + this.$parent.counter;

            this.$parent.counter += 1;
            const optKey2 = 'x' + this.$parent.counter;

            const input = {
                key: inputKey,
                type: 'check',
                width: '6',
                desc: textHelper.getEmptyTextObject(this.reference),
                required: true,
                inline: false,
                radio: false,
                fillCard: false,
                border: false,
                options: {
                    [optKey1]: {
                        type: 'opt',
                        key: optKey1,
                        default: false,
                        locale: textHelper.getEmptyTextObject(this.reference),
                    },
                    [optKey2]: {
                        type: 'opt',
                        key: optKey2,
                        default: false,
                        locale: textHelper.getEmptyTextObject(this.reference),
                    },
                },
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.$emit('add', [input]);
        },

        onAddDropdown() {
            
            this.$parent.counter += 1;
            const inputKey = 'i' + this.$parent.counter;

            this.$parent.counter += 1;
            const optKey1 = 'x' + this.$parent.counter;

            this.$parent.counter += 1;
            const optKey2 = 'x' + this.$parent.counter;

            const input = {
                key: inputKey,
                type: 'drop',
                width: '6',
                desc: textHelper.getEmptyTextObject(this.reference),
                required: true,
                external: false,
                items: [
                    {
                        type: 'itm',
                        key: optKey1,
                        value: optKey1,
                    },
                    {
                        type: 'itm',
                        key: optKey2,
                        value: optKey2,
                    },
                ],
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            for (const i in input.items) {
                input.items[i] = { ...input.items[i], ...textHelper.getEmptyTextObject(this.reference) };
            }

            this.$emit('add', [input]);
        },

        onAddDatepicker() {
            this.$parent.counter += 1;

            const input = {
                key: 'i' + this.$parent.counter,
                type: 'date',
                width: '6',
                desc: textHelper.getTranslation(this.reference, 'common-date'),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.$emit('add', [input]);
        },

        onAddEmailInput() {
            this.$parent.counter += 1;

            const input = {
                key: 'i' + this.$parent.counter,
                type: 'text',
                inputType: 'email',
                width: '6',
                desc: textHelper.getTranslation(this.reference, 'common-email'),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.setModelKey(input, 'email');
            this.$emit('add', [input]);
        },

        onAddPhoneInput() {
            this.$parent.counter += 1;

            const input = {
                key: 'i' + this.$parent.counter,
                type: 'phone',
                width: '6',
                desc: textHelper.getTranslation(this.reference, 'common-phone'),
                initial: 'system',
                required: true,
                noPadding: false,
                pdf: true,
            };
            this.setModelKey(input, 'phone');
            this.$emit('add', [input]);
        },

        onAddAddressGroup() {
            const inputs = [];

            const count1 = this.$parent.counter += 1;
            const count2 = this.$parent.counter += 1;
            const count3 = this.$parent.counter += 1;

            const input1 = {
                key: 'i' + count1,
                type: 'text',
                inputType: 'text',
                width: '6',
                desc: textHelper.getTranslation(this.reference, 'common-address'),
                required: true,
                noPadding: false,
                pdf: true,
            };
            this.setModelKey(input1, 'street');
            inputs.push(input1);

            const input2 = {
                key: 'i' + count2,
                type: 'text',
                inputType: 'text',
                width: '2',
                desc: textHelper.getTranslation(this.reference, 'common-zip'),
                logic: {
                    dmatch: {
                        keys: ['i' + count3]
                    },
                    rdict: {
                        global: true,
                        key: 'zipCodes'
                    }
                },
                required: true,
                noPadding: false,
                pdf: true,
            };
            this.setModelKey(input2, 'zip');
            inputs.push(input2);

            const input3 = {
                key: 'i' + count3,
                type: 'text',
                inputType: 'text',
                width: '4',
                desc: textHelper.getTranslation(this.reference, 'common-city'),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.setModelKey(input3, 'city');
            inputs.push(input3);

            this.$emit('add', inputs);
        },

        onAddPersonGroup() {
            const inputs = [];

            this.$parent.counter += 1;
            const input1 = {
                key: 'i' + this.$parent.counter,
                type: 'text',
                inputType: 'text',
                width: '4',
                desc: textHelper.getTranslation(this.reference, 'person-first-name'),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.setModelKey(input1, 'firstName');
            inputs.push(input1);

            this.$parent.counter += 1;
            const input2 = {
                key: 'i' + this.$parent.counter,
                type: 'text',
                inputType: 'text',
                width: '4',
                desc: textHelper.getTranslation(this.reference, 'person-last-name'),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.setModelKey(input2, 'lastName');
            inputs.push(input2);

            this.$parent.counter += 1;
            const input3 = {
                key: 'i' + this.$parent.counter,
                type: 'date',
                width: '4',
                desc: textHelper.getTranslation(this.reference, 'person-dob'),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.setModelKey(input3, 'dateOfBirth');
            inputs.push(input3);

            this.$emit('add', inputs);
        },

        onAddRecipientGroup() {
            const inputs = [];

            this.$parent.counter += 1;
            const input1 = {
                key: 'i' + this.$parent.counter,
                type: 'text',
                inputType: 'text',
                width: '6',
                desc: textHelper.getTranslation(this.reference, 'person-first-name'),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.setModelKey(input1, 'firstName');
            inputs.push(input1);

            this.$parent.counter += 1;
            const input2 = {
                key: 'i' + this.$parent.counter,
                type: 'text',
                inputType: 'text',
                width: '6',
                desc: textHelper.getTranslation(this.reference, 'person-last-name'),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.setModelKey(input2, 'lastName');
            inputs.push(input2);

            this.$parent.counter += 1;
            const input3 = {
                key: 'i' + this.$parent.counter,
                type: 'text',
                inputType: 'email',
                width: '12',
                desc: textHelper.getTranslation(this.reference, 'common-email'),
                required: true,
                noPadding: false,
                pdf: true,
                pdfIndex: 0,
                pdfLocked: false
            };
            this.setModelKey(input3, 'email');
            inputs.push(input3);

            this.$emit('add', inputs);
        },

        onAddButtonGroup() {
            const count1 = this.$parent.counter += 1;
            const optKey1 = 'b' + (this.$parent.counter += 1);
            const input = {
                key: 'b' + count1,
                type: 'buttoni',
                width: '12',
                buttons: [
                    {
                        key: optKey1,
                        type: 'btn',
                        color: 0,
                        outlined: false,
                        dotted: false,
                        width: 0,
                        text: textHelper.getEmptyTextObject(this.reference),
                        tooltip: textHelper.getEmptyTextObject(this.reference),
                    },
                ],
                style: 0,
                size: 1,
                align: 2
            };
            this.$emit('add', [input]);
        },

        setModelKey(input, key) {
            if (this.params.isList) {
                input.listModel = key;
            }else {
                input.modelKey = key;
            }
        },

    }
}
</script>
<style scoped>

.frv2-dlist-container {
    float: left;
    width: 100%;
}

.frv2-dlist-column {
    float: left;
    width: 33.33%;
    min-height: 400px;
    box-sizing: border-box;
    border-right: solid 1px var(--fb-menu-c);
}

</style>