<template>
<div class="vap-inputc" :class="{'vap-cb-boxonly': boxOnly}">
    <div @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" class="vap-cb-base" :class="[
        {'vap-cb-nodesc': noDesc},
        {'vap-cb-bordered': bordered},
        {'vap-cb-bordero': borderOffset},
        {'vap-cb-card': fillCard},
        {'vap-cb-disabled': disabled},
        {'vf-cb-invalid': validation.done && !validation.valid },
    ]">
        
        <div v-for="(option, key, i) in options" :key="key" class="vap-cb" @mouseup="handleOptionMouseUp(key, $event)"
            :class="[
                {'vap-cb-inline' : inline},
                {'vap-cb-radio' : radio},
                {'vap-cb-border' : i != 0},
                {'vap-cb-selected' : value[key]},
            ]">
            <div class="vap-cb-check" @click="handleClick(key)">
                <div v-if="value[key] || (designer && option.default)" class="checkmark">
                    <div class="checkmark_stem"></div>
                    <div class="checkmark_kick"></div>
                </div>
            </div>
            <div v-if="option.persons" class="vap-cb-text" @click="handleClick(key)">
                <div v-for="(person, i) in option.persons" :key="'p' +i" >
                    <div style="display: flex;">
                        <div style="color: var(--text-strong); padding-left: 4px; padding-bottom: 4px;">
                            {{person.firstName + ' ' + person.lastName}}
                        </div>
                        <div style="margin-left: 5px; color: var(--text-weak);">
                            {{' (' + person.dateOfBirth + ')'}}
                        </div>
                    </div>
                    <div v-if="value[key]">
                        <VFRow>
                            <VFGroup c6 :validation="person.emailValidation">
                                <VFInput 
                                v-model="person.email" 
                                :placeholder="__('common_email')" 
                                :validation="person.emailValidation"
                                @input="validatePerson(person)"
                                />
                            </VFGroup>
                            <VFGroup c3 :validation="person.methodValidation">
                                <VFDropdown 
                                    v-model="person.method"
                                    :placeholder="__('signrights_filler_signmethod')"
                                    :options="signMethods"
                                    :listKey="$root.locale.key"
                                    :displayKey="$root.locale.key"
                                    :outputKey="'value'"
                                    :validation="person.methodValidation"
                                    @input="validatePerson(person)"
                                />
                            </VFGroup>
                            <VFGroup c3 :validation="person.languageValidation">
                                <VFDropdown 
                                    v-model="person.language"
                                    :placeholder="'Velg språk'"
                                    :options="languageOptions"
                                    :listKey="$root.locale.key"
                                    :displayKey="$root.locale.key"
                                    :outputKey="'value'"
                                    :validation="person.languageValidation"
                                    @input="validatePerson(person)"
                                />
                            </VFGroup>
                            <div @click.stop="() => {}" v-if="directAvailable">
                                <div style="display: flex; align-items: center;">
                                    <input @input="directChanged(person, $event)" :id="i + 'pcheckbox'" style="width: 15px; height: 15px; margin-right: 7px;" type="checkbox" :value="person.direct" />
                                    <label :for="i + 'pcheckbox'">{{ __('signrights_direct_option') }}</label>
                                </div>
                            </div>
                        </VFRow>
                    </div>
                </div>
            </div>
        </div>

        <div class="vap-cb" style="border-top: solid 1px var(--input-border);" @mouseup="handleOptionMouseUp('manual', $event)" :class="[
                {'vap-cb-inline' : inline},
                {'vap-cb-radio' : radio},
                {'vap-cb-selected' : value.manual},
        ]">
            <div class="vap-cb-check" @click="handleClick('manual')">
                <div v-if="value['manual'] || (designer && option.default)" class="checkmark">
                    <div class="checkmark_stem"></div>
                    <div class="checkmark_kick"></div>
                </div>
            </div>
            <div class="vap-cb-text" @click="handleClick('manual')">
                <div style="color: var(--text-strong); padding-left: 4px; padding-bottom: 4px;">Alternativene over er feil eller manglende</div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
import VFRow from '../../items/VFRow.vue';
import VFGroup from '../../items/VFGroup.vue';
import VFInput from '../../inputs/VFInput.vue';
import VFDropdown from '../../inputs/VFDropdown.vue';
import formValidation from '../../formValidation';

export default {
    name: 'SignOptionRadio',
    components: { 
        VFRow,
        VFDropdown,
        VFInput,
        VFGroup
    },
    props: {
        value: { type: [Boolean, Object], default: false },
        text: { type: String, default: 'This is a checkbox' },
        id: { type: String, default: 'id' },
        options: { type: Object, default: null },

        directAvailable: { type: Boolean, default: false },

        designer: { type: Boolean, default: false },
        activeLogic: { type: Boolean, default: false  },
        
        inline: { type: Boolean, default: false },
        radio: { type: Boolean, default: false },
        fillCard: { type: Boolean, default: false },

        noDesc: { type: Boolean, default: false },
        bordered: { type: Boolean, default: false },
        borderOffset: { type: Boolean, default: false },
        boxOnly: { type: Boolean, default: false },

        validation: { type: Object, default: () => { return { done: false } }},
        noValidation: { type: Boolean, default: false },
        noHighlight: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },

        signMethods: { type: Array, default: null },
        locales: { type: Array, default: () => { return [] }},
    },
    computed: {
        languageOptions: function() {
            return this.locales.filter(x => x.enabled).map(x => { return { value: x.key, nb_NO: x.name, en_EN: x.name } })
        }
    },
    data() {
        return {
            objects: false,
        }
    },
    methods: {

        directChanged(person, event) {
            person.direct = event.target.checked;
            this.validatePerson(person);
        },

        getOption(option) {
            if (option.locale) {
                if (option.locale[this.$root.locale.key]) {
                    this.missingLocale = false;
                    return option.locale[this.$root.locale.key];
                }else if (option.locale.en_EN) {
                    if (this.designer) this.missingLocale = true;
                    return option.locale.en_EN;
                }else if (option.locale.nb_NO) {
                    if (this.designer) this.missingLocale = true;
                    return option.locale.nb_NO;
                }
                return '';
            }else if (option.key) {
                // TODO ------------------------------ ¯\_(ツ)_/¯ -------------------------------
                if (this.designer) return this.$parent.$parent.$parent.$parent.$parent.__t(option.key);
                return this.__(option.key);
            }
            return option.label;
        },

        handleClick(key) {
            if (this.disabled) return;
            if (this.designer) return;
            if (this.objects) {
                let val = {};
                if (this.radio) {
                    for (let k in this.options) val[k] = false;
                    val[key] = true;
                }else {
                    for (let k in this.options) val[k] = this.value[k];
                    val[key] = !this.value[key];
                }
                this.$emit('input', val);
            }else {
                this.$emit('input', !this.value);
            }
        },

        handleMouseEnter(e) {
            this.$emit('connect-enter', this.id);
        },

        handleMouseLeave(e) {
            this.$emit('connect-leave', this.id);
        },

        handleOptionMouseUp(key, e) {
            if (this.activeLogic) {
                this.$emit('connect', key);
            }
        },

        showHelpPopup() {
            this.showHelpPopupX({ htmlKey: this.helpHtml });
        },

        validatePerson(person){
            person.emailValidation.done = true;
            let result = formValidation.validateEmail(person.email);
            person.emailValidation.valid = result.valid; 
            person.emailValidation.message = result.message;

            result = formValidation.validateRequired(person.method, {type: 'text'});
            person.methodValidation.done = true;
            person.methodValidation.valid = result.valid; 
            person.methodValidation.message = result.message;

            this.$emit('validated');
        },

    },
    mounted() {
        if (this.options) {
            this.objects = true;
        }
    }
}
</script>
<style scoped>

.vap-inputc {
    float: left;
    width: 100%;
    display: flex;
}
.vap-input-help {
    float: right;
    width: 35px;
    padding-top: 1px;
    padding-left: 5px;
}

.vap-cb-base {
    position: relative;
    float: left;
    flex-grow: 100;
}

.vap-cb {
    float: left;
    width: 100%;
    display: flex;
    height: auto;
}

.vap-cb-bordered {
    float: left;
    width: 100%;
    height: 40px;
    display: flex;
    border-radius: 4px;
    border: solid 1px var(--input-border);
    box-sizing: border-box;
}

.vap-cb-bordero .vap-cb {
    margin-top: 4px;
    margin-left: 4px;
}

.vap-cb-boxonly {
    display: unset;
    flex-grow: 0;
    width: 45px;
}

.vap-cb-nodesc {
    margin-top: 0px;
}

.vap-cb-inline {
    width: auto;
    padding-top: 4px;
    padding-left: 4px;
    box-sizing: border-box;
}

.vap-cb-check {
    flex-basis: 22px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 3px; margin-left: 3px;
    width: 22px; height: 22px;
    border: solid 1px #238ccc;
    border-color: var(--input-border);
}
.vap-cb-radio .vap-cb-check {
    border-radius: 50%;
}

.vap-cb-text {
    flex-grow: 100;
    font-weight: 400;
    margin-top: 4px;
    margin-left: 10px;
    color: var(--input-text);
}
.vap-cb-inline .vap-cb-text {
    margin-right: 15px;
}
.vap-cb-text label  {
    width: 100%;
}

.checkmark {
    display:inline-block;
    width: 26px;
    height: 26px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.checkmark_stem {
    position: absolute;
    width: 6px;
    height: 22px;
    background-color: var(--input-accent);
    left: 11px;
    top: -3px;
}
.checkmark_kick {
    position: absolute;
    width: 12px;
    height: 6px;
    background-color: var(--input-accent);
    left: 2px;
    top: 13px;
}
.vap-cb-radio .checkmark_stem {
    top: 5px; left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.vap-cb-radio .checkmark_kick {
    display: none;
}

.vap-cb-disabled .vap-cb-text {
    color: var(--text-disabled);
}




.vap-cb-card {
    background-color: var(--page-background);
    border-radius: 3px;
    border: solid 1px var(--input-border);
}

.vap-cb-card .vap-cb {
    padding: 0px 15px;
    box-sizing: border-box;
    cursor: pointer;
}
/* .vap-cb-card .vap-cb:hover {
    background-color: #f7f7f7;
} */

.vap-cb-card .vap-cb-text {
    padding: 15px 5px;
}
.vap-cb-card .vap-cb-text label {
    cursor: pointer;
}

.vap-cb-card .vap-cb-check {
    margin-top: 18px;
    border-color: var(--input-label);
}

.vap-cb-card .vap-cb-border {
    border-top: solid 1px var(--input-border);
}

.vap-cb-card .vap-cb-selected {
    background-color: var(--card-background);
}



/* --- Validation -------------------- */

.vf-cb-invalid .vap-cb-check {
    border-color: var(--val-error);
}

.vf-cb-invalid.vap-cb-card {
    border-color: var(--val-error);
}

/* --- ---------- -------------------- */



/* design mode */

.logic .vap-cb:hover .vap-cb-text {
    color: #0DCC27;
    font-weight: bold;
}
.logic .vap-cb:hover .vap-cb-check {
    border: solid 1.5px #0DCC27;
}

/* --- ---------- -------------------- */

</style>