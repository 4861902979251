<template>
    <div class="pagef" :class="{'pagef-scrolled': scrolled }">
        


    </div>
</template>
<script>


export default {
    name: 'PageFooter',
    components: {
        
    },
    props: {
        scrolled: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {
        

    }
}
</script>
<style scoped>

.pagef {
    float: left;
    width: 100%;
}

.pagef-scrolled {
    height: 60px;
}

</style>