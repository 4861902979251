<template>
<div class="vap-phone">
    
    <vap-dropdown combine v-model="value.prefix"
        :options="$root.countriesJson"
        :commonOptions="$root.countriesCommon" 
        :listKey="'prefix'"
        :listKey2="'nb_NO'"
        :displayKey="'prefix'"
        :triggerValidation="dropdownValidated" :noHighlight="noHighlight" :noValidation="noValidation" />

    <vap-input combine numeric v-model="value.number" @input="handleInput" :placeholder="placeholder" :rules="rules" :noHighlight="noHighlight" :noValidation="noValidation" />

</div>
</template>
<script>
export default {
name: 'vap-phone',
props: {
    value: {
        type: Object,
        default: () => { return { prefix: '+47', number: '' } }
    },
    placeholder: {
        type: String,
        default: ''
    },
    rules: {
        type: String,
        default: ''
    },
    noHighlight: {
        type: Boolean,
        default: false
    },
    noValidation: {
        type: Boolean,
        default: false
    }
},
data() {
    return {
        dropdownValidated: false
    }
},
methods: {
    handleInput() {
        if (!this.dropdownValidated) this.dropdownValidated = true;
        this.$emit('input', this.value);
    }
}
}
</script>
<style scoped>

.vap-phone {
    float: left;
    width: 100%;
    display: flex;
}

.vap-phone .vap-dd {
    max-width: 80px;
}

.vap-phone .vap-input {
    flex-grow: 100;
}

</style>