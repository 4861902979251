<template>
<div class="aml-login card-shadow">
    <div class="aml-login-bar" />

    <div class="aml-login-input">
        <vap-label :text="__('common_email')" desc />
        <vap-input v-model="auth.email" :placeholder="'E-post'" @keyup.enter.native="handleClick" />
    </div>

    <div class="aml-login-input">
        <vap-label :text="__('common_password')" desc />
        <vap-input v-model="auth.password" :placeholder="'Passord'" password @keyup.enter.native="handleClick" />
    </div>

    <div class="aml-login-error" v-if="error">
        <vap-label error :text="__('common_wrong_pass')" />
    </div>

    <div class="aml-login-button">
        <vap-button-row alignRight style="margin-top: 20px;">
            <vap-button :text="__('common_log_in')" uppercase :working="working" @clicked="handleClick" />
        </vap-button-row>
    </div>

</div>
</template>

<script>

export default {
    name: 've-login',
    props: {
        working: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            auth: {
                email: '',
                password: '',
                base64: ''
            }
        }
    },
    methods: {
        handleClick() {
            this.auth.base64 = this.b64EncodeUnicode(this.auth.email + ':' + this.auth.password);
            this.$emit('login', this.auth);
        },

        b64EncodeUnicode(str) {
            return btoa(
                encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
                String.fromCharCode("0x" + p1)
                )
            );
        },

        b64DecodeUnicode(str) {
            return decodeURIComponent(
                atob(str)
                .split("")
                .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
                .join("")
            );
        }

    },
    async created() {
        
    }
}
</script>

<style>

.aml-login {
    width: 400px;
    margin-left: -200px;
    border-radius: 5px;
    background: white;
    overflow: hidden;
}

.aml-login-bar {
    float: left;
    width: 100%;
    height: 20px;
    background: var(--primary);
}

.aml-login-input {
    float: left;
    width: 100%;
    margin-top: 15px;
    padding: 0px 20px;
    box-sizing: border-box;
}

.aml-login-button {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    padding: 0px 20px;
    box-sizing: border-box;
}

.aml-login-error {
    float: left;
    width: 100%;
    margin-top: 10px;
    padding: 0px 20px;
    box-sizing: border-box;
}

</style>
