<template>
    <div>
        <div class="slim-page">
            <vap-card list :header="'Export data'">
                <vap-row>
                    <vap-group c2>
                        <vap-label :text="'Skip'" desc />
                        <vap-input v-model="skip" />
                    </vap-group>
                    <vap-group c2>
                        <vap-label :text="'Limit'" desc />
                        <vap-input v-model="limit" />
                    </vap-group>
                    <vap-group c2>
                        <vap-button :text="'Load customers'" @clicked="loadCustomers" :disabled="working" :working="working" />
                    </vap-group>
                </vap-row>
            </vap-card>
        </div>
        <BatchView ref="batchview" :items="items" :steps="steps" :title="'Lagre rapporter i iManage'" />
    </div>
</template>
<script>
import api from '../../portalapi';
import tools from '../../tools';
import cache from '../../cache';
import log from '../../log';
import receipts from '../../receipts';
import clienthelper from '../../helpers/clienthelper';
import personhelper from '../../helpers/personhelper';

import downloadjs from 'downloadjs';
import BatchView from '../../components/special/BatchView';

export default {
    components: {
        BatchView
    },
    name: 'BatchSaveReport',
    data() {
        return {
            items: [],
            steps: 3,
            STEP_LOAD_CLIENT: 0,
            STEP_PROCESS: 1,
            STEP_SAVE_DATA: 2,
            working: false,
            skip: 0,
            limit: 1,
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            return item.name;
        },

        onGetInfo1(item) {
            return item.status;
        },

        onGetInfo2(item) {
            return '';
        },

        async onRemoveItem(item) {
            
        },

        async onStart() {
            return true;
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return 'Laster kunde';
                case this.STEP_PROCESS: return 'Sjekker filter';
                case this.STEP_SAVE_DATA: return 'Lagrer sluttrapport';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return await this.loadClient(item, data);
                case this.STEP_PROCESS: return await this.processClient(item, data);
                case this.STEP_SAVE_DATA: return await this.saveData(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async loadClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { client: null, skip: false } };

            try {
                result.data.client = await clienthelper.loadClient(this.$root, item);
            }catch (e) {
                log.e('Failed to load client');
                result.data.skip = true;
            }
            

            result.ok = true;
            return result;
        },

        async processClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { ready: false } };

            await new Promise(r => setTimeout(r, 1000));

            const skip = data[this.STEP_LOAD_CLIENT].skip;
            if (skip) {
                console.log('skipping');
                result.ok = true;
                return result;
            }

            const client = data[this.STEP_LOAD_CLIENT].client;
            console.log('client', client);

            const readyResult = this.readyForReport(client);
            console.log('ready', readyResult);
            if (readyResult == 'ja') result.data.ready = true;

            if (!client.kycMetadata.signed) {
                result.data.ready = false;
                log.e('Wrong status');
                await api.updateCustomer(client.customer._id, {
                    status: 'f-kyc-x'
                });
            }else if (readyResult == 'laget') {
                result.data.ready = false;
                log.e('Wrong status already complete');
                await api.updateCustomer(client.customer._id, {
                    status: 'r-rep-y'
                });
            }else if (readyResult == 'nei' && item.status == 'r-rep-o') {
                result.data.ready = false;
                log.e('Wrong status missing');
                await api.updateCustomer(client.customer._id, {
                    status: 'r-rep-a'
                });
            }

            result.ok = true;
            return result;
        },

        async saveData(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            const skip = data[this.STEP_LOAD_CLIENT].skip;
            if (skip) {
                console.log('skipping');
                result.ok = true;
                return result;
            }

            const client = data[this.STEP_LOAD_CLIENT].client;
            const ready = data[this.STEP_PROCESS].ready;

            if (ready) {


                for (const i in client.reportData) {
                    if (client.reportData[i].type == 'kyc') {
                        if (client.reportData[i].comment == '<p></p>') {
                            client.reportData[i].comment = '<p>Automatisk kommentar: Sluttrapport laget av systemet</p>';
                        }
                    }
                    if (client.reportData[i].type == 'total') {
                        if (client.reportData[i].comment == '<p></p>') {
                            client.reportData[i].comment = '<p>Sluttrapport laget automatisk av systemet</p>';
                        }
                    }
                }
                await api.updateCustomer(client.customer._id, {
                    rdata: client.reportData,
                    risk: 0
                });



                const reportMetadata = {
                    hasPep: false,
                    hasSanction: false
                };
                for (const i in client.persons) {
                    if (client.persons[i].search.isPep) reportMetadata.hasPep = true;
                    if (client.persons[i].search.hasSanction) reportMetadata.hasSanction = true;
                }
                const reportData = {
                    customer: client.customer,
                    brregData: client.brregData,
                    manualData: client.manualData,
                    amlData: client.amlData,
                    persons: client.persons,
                    reportData: client.reportData,
                    kycMetadata: client.kycMetadata,
                    reportMetadata: reportMetadata,
                };
                
                let reportResult;
                if (client.customer.flow == 'person') {
                    reportResult = await receipts.createFinalReportPerson(this.$root, reportData, true, {});
                }else {
                    reportResult = await receipts.createFinalReportAuto(this.$root, reportData, true, {});
                }


                const metadata = {
                    autoreport: {
                        scanned: true,
                        created: true,
                        saved: false
                    }
                };
                const response = await api.setMetadata(client.customer._id, metadata);
                
            }else {



                const metadata = {
                    autoreport: {
                        scanned: true,
                        created: false,
                        saved: false
                    }
                };
                const response = await api.setMetadata(client.customer._id, metadata);
            }

            


/*             const client = data[this.STEP_LOAD_CLIENT].client;

            const saveData = JSON.stringify(client);

            console.log('saving ' + client.customer.name);
            downloadjs("data:text/json;charset=utf-8,\uFEFF" + saveData, client.customer.name + ".json", "text/json"); */

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        readyForReport(client) {
            if (client.customer.report) return 'laget';

            // Check ID
            let hasID = false;
            let hasCeo = false, hasChair = false, hasRrh = false;
            let hasCeoID = false, hasChairID = false, hasRrhID = false;
            for (const i in client.persons) {
                if (personhelper.hasRole(client.persons[i], 'ceo')) {
                    hasCeo = true;
                    if (client.persons[i].control.completed) hasCeoID = true;
                }
                if (personhelper.hasRole(client.persons[i], 'chair')) {
                    hasChair = true;
                    if (client.persons[i].control.completed) hasChairID = true;
                }
                if (personhelper.hasRole(client.persons[i], 'screening_role_rrh')) {
                    hasRrh = true;
                    if (client.persons[i].control.completed) hasRrhID = true;
                }
            }
            if ((hasCeo && hasCeoID) || (hasChair && hasChairID) || (hasRrh && hasRrhID)) {
                hasID = true;
            }else {
                console.log('missing id');
            }

            // Check keywords
            let allCompleted = true;
            for (const i in client.reportData) {

                if (client.reportData[i].type == 'aml'
                && !client.reportData[i].searchComplete
                && !client.reportData[i].ok
                && (client.reportData[i].comment == '<p></p>' || client.reportData[i].comment == '')) {
                    allCompleted = false;
                    console.log('missing company search');
                }

                if (client.reportData[i].type == 'per'
                && !client.reportData[i].searchComplete
                && !client.reportData[i].ok
                && (client.reportData[i].comment == '<p></p>' || client.reportData[i].comment == '')) {
                    
                    for (const j in client.persons) {
                        if (client.persons[j].id == i) {
                            console.log('check role', client.persons[j]);
                            if (personhelper.hasRole(client.persons[j], 'ceo') || personhelper.hasRole(client.persons[j], 'chair') || personhelper.hasRole(client.persons[j], 'screening_role_rrh')) {
                                allCompleted = false;
                                console.log('missing person search');
                            }
                        }
                    }
                }
            }

            if (hasID && allCompleted && client.customer.kyc) {
                return 'ja';
            }

            return 'nei';
        },

        async loadCustomers() {
            this.$parent.setLoading(true, 'Laster kunder...');
            
            const filters = [
                /* { field: 'status', op: 'na', values: ['r-rep-a', 'r-rep-h', 'r-rep-o'] }, */
                { field: 'status', op: 'na', values: ['r-rep-o'] },
                /* { field: 'metadata.autoreport', op: 'exists', value: false } */
            ];
            const from = this.skip;
            const limit = this.limit;
            const sort = { dateUpdated: -1 };
            const include = [];
            const counts = [];
            const response = await api.queryCustomers(filters, from, limit, sort, include, counts);

            this.items = [];
            for (const i in response.customers) {
                this.items.push(response.customers[i]);
            }

            this.$parent.setLoading(false);
        },

    },
    async created() {
        
    }
}
</script>