<template>
<div>
    <div v-for="(day, i) in processedEvents" :key="i" class="vap-event-day">
        <div class="vap-event-day-header">
            <label>{{ formatDate('longDate', day.date) }}</label>
        </div>
        <div v-for="(event, j) in day.events" :key="j" class="vap-event">
            <div class="vap-event-time">
                <label>{{ event.timeString }}</label>
            </div>
            <div class="vap-event-name">
                <label>{{ event.name }}</label>
            </div>
        </div>
    </div>
    <div class="vap-event-footer" v-if="overflow">
        <div class="vap-event-exptext">
            <label v-if="!expanded" @click="onExpandClicked">
                {{ '+ ' + overflowCount + ' ' + __t(overflowCount == 1 ? 'clients-more-event' : 'clients-more-events') }}
            </label>
        </div>
        <div class="vap-event-button" @click="onExpandClicked">
            {{ expanded ? __t('clients-events-hide') : __t('clients-events-show') }}
        </div>
    </div>
</div>
</template>
<script>

export default {
    name: 'vap-eventlog',
    props: {
        events: { type: Array, default: () => { return [] } },
        maxEvents: { type: [String, Number], default: 3 },
    },
    data() {
        return {
            processedEvents: [],
            overflow: false,
            expanded: false,
            overflowCount: 0
        }
    },
    watch: {
        events: {
            deep: false,
            handler(val) {
                this.processEvents(val);
            }
        }
    },
    methods: {

        onExpandClicked() {
            this.expanded = !this.expanded;
            this.processEvents(this.events);
        },

        processEvents(events) {
            const sortedEvents = JSON.parse(JSON.stringify(events));
            sortedEvents.sort((b, a) => (a.date > b.date) ? 1 : -1);

            this.processedEvents = [];
            let count = 0;
            for (const i in sortedEvents) {
                let match = false;
                for (let j in this.processedEvents) {
                    if (this.isSameDay(this.processedEvents[j].date, sortedEvents[i].date)) {
                        match = true;
                        this.processedEvents[j].events.push({
                            name: sortedEvents[i].name,
                            date: new Date(sortedEvents[i].date),
                            timeString: this.timeString(sortedEvents[i].date)
                        });
                    }
                }
                if (!match) {
                    this.processedEvents.push({
                        date: new Date(sortedEvents[i].date),
                        events: [{
                            name: sortedEvents[i].name,
                            date: new Date(sortedEvents[i].date),
                            timeString: this.timeString(sortedEvents[i].date)
                        }]
                    });
                }
                count += 1;
                if (this.overflow && !this.expanded && count >= this.maxEvents) break;
            }
            for (const j in this.processedEvents) {
                this.processedEvents[j].events.sort((b, a) => (a.date > b.date) ? 1 : -1);
            }
            this.processedEvents.sort((b, a) => (a.date > b.date) ? 1 : -1);
            this.overflowCount = sortedEvents.length - this.maxEvents;
        },

        timeString(input) {
            let date = new Date(input);
            let h = date.getHours();
            if (date.getHours() < 10) h = '0' + h;
            let m = date.getMinutes();
            if (date.getMinutes() < 10) m = '0' + m;
            return `${h}:${m}`;
        },

        isSameDay(input1, input2) {
            let date1 = new Date(input1);
            let date2 = new Date(input2);
            return (date1.getDate() == date2.getDate() && date1.getMonth() == date2.getMonth() && date1.getFullYear() == date2.getFullYear());
        },

    },
    created() {
        if (this.maxEvents != 0 && this.events.length > this.maxEvents) {
            this.overflow = true;
        }
        this.processEvents(this.events);
    }
}
</script>

<style>

.vap-event-day {
    margin-bottom: 20px;
}
.vap-event-day-header {
    margin-bottom: 6px;
}
.vap-event-day-header label {
    font-family: "Roboto Slab", serif;
    font-size: 18px;
    color: var(--text-weak);
}

.vap-event {
    display: flex;
    margin-top: 3px;
    padding-top: 4px;
    padding-bottom: 2px;
    border-top: 1px solid var(--input-border);
}

.vap-event-time {
    padding-top: 2px;
}
.vap-event-time label {
    font-family: "Lucida Console", Monaco, monospace;
    font-weight: bold;
    font-size: 16px;
    color: var(--text-title);
}

.vap-event-name {
    margin-left: 14px;
}
.vap-event-name label {
    font-family: 'Signika', sans-serif;
    font-size: 17px;
    color: var(--text-title);
}

.vap-event-footer {
    display: flex;
    margin-top: -10px;
    margin-bottom: 10px;
}

.vap-event-exptext {
    flex-grow: 100;
    padding-top: 4px;
    color: var(--link-text);
    font-weight: 500;
}
.vap-event-exptext label {
    cursor: pointer;
}
.vap-event-exptext label:hover {
    color: var(--link-active);
}

.vap-event-button {
    height: 20px;
    margin-left: 5px;
    padding: 0px 14px;
    padding-top: 4px;
    padding-bottom: 2px;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-title);
    border-radius: 3px;
    border: 1px solid var(--input-border);
    background-color: var(--page-background);
    cursor: pointer;
}
.vap-event-button:hover {
    color: var(--button-text);
    background-color: var(--button);
}

</style>
