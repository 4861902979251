<template>
    <div class="frmod-cl card-shadow" v-if="cardData.header" :class="[
        {'frmod-cl-selected' : selected},
        {'frmod-cl-designer': designer },
    ]">

        <div class="frmod-cl-h" @click="handleClick">
            <label>{{ cardData.header[$root.locale.key] }}</label>
        </div>

        <div class="frmod-clc">

            <slot></slot>

        </div>

    </div>
</template>
<script>
import verified from '../../../../verified';

export default {
    name: "frmod-checklist",
    props: {
        cardData: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        roleKey: { type: String, default: 'owner' },
    },
    data() {
        return {
            visible: {
                owner: [true, true, true, false, false, false],
                editfil: [true, true, true, true, true, true]
            },
            widths: {
                owner: [80, 10, 10],
                editfil: [30, 10, 10, 30, 10, 10]
            },
            options: {
                ids1: ['App Form', 'AML Form', 'File Note', 'Other', 'N/A']
            }
        }
    },
    watch: {
        width: {
            deep: false,
            handler(val) {
                
            }
        }
    },
    methods: {
        
        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

    },
    mounted() {
        //console.log('cardData', this.cardData);
    }
}

</script>
<style>

.frmod-cl {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    border-radius: 4px;
    box-sizing: border-box;
}

.frmod-cl-h {
    float: left;
    width: 100%;
    padding-top: 8px;
    padding-left: 10px;
    padding-bottom: 4px;
    color: var(--header-text);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: var(--header);
    box-sizing: border-box;
}

.frmod-clc {
    float: left;
    width: 100%;
    display: table;
    border: solid 1px lightgray;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: var(--card-background);
    box-sizing: border-box;
}

.frmod-cl-selected {
    outline: solid 2px #238ccc;
}

</style>
