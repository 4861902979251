<template>
<div>

    <!-- <VFCheckbox newDesign v-model="updateKyc" :text="'Update client KYC data from this form'" @input="onConfigurationChanged" />
    <VFCheckbox newDesign v-model="comparison" :text="'Do a comparison of the inital company and inputted data'" @input="onConfigurationChanged" /> -->

    <DesignerGroup c12>
        <DesignerCheckbox v-model="updateKyc" :text="'Update client KYC data from this form'" @input="onConfigurationChanged" />
    </DesignerGroup>

    <DesignerGroup c12>
        <DesignerCheckbox v-model="comparison" :text="'Do a comparison of the inital company and inputted data'" @input="onConfigurationChanged" />
    </DesignerGroup>

    <DesignerGroup c12>
        <DesignerCheckbox v-model="boardUbo" :text="'Add board as UBOs if the company is a foundation or similar'" @input="onConfigurationChanged" />
    </DesignerGroup>

    <DesignerGroup c12>
        <DesignerCheckbox v-model="addOwnerIfChecked" :text="'Add owners as UBOs if isBeneficial is true'" @input="onConfigurationChanged" />
    </DesignerGroup>

</div>
</template>
<script>
import DesignerGroup from '../../designer/ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../../designer/ui/inputs/DesignerLabel.vue';
import DesignerCheckbox from '../../designer/ui/inputs/DesignerCheckbox.vue';

export default {
    name: "KycModuleBox",
    components: {
        DesignerGroup,
        DesignerLabel,
        DesignerCheckbox,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() {
        return {
            updateKyc: false,
            comparison: false,
            boardUbo: false,
            addOwnerIfChecked: false,
        }
    },
    methods: {

        onConfigurationChanged() {
            for (const i in this.reference.modules) {
                if (this.reference.modules[i].type == 'kyc') {
                    this.reference.modules[i].updateKyc = this.updateKyc;
                    this.reference.modules[i].comparison = this.comparison;
                    this.reference.modules[i].boardUbo = this.boardUbo;
                    this.reference.modules[i].addOwnerIfChecked = this.addOwnerIfChecked;
                }
            }
        },

    },
    created() {
        for (const i in this.reference.modules) {
            if (this.reference.modules[i].type == 'kyc') {
                if (this.reference.modules[i].updateKyc) this.updateKyc = true;
                if (this.reference.modules[i].comparison) this.comparison = true;
                if (this.reference.modules[i].boardUbo) this.boardUbo = true;
                if (this.reference.modules[i].addOwnerIfChecked) this.addOwnerIfChecked = true;
            }
        }
    }
}

</script>