<template>
<div>
    
    <PropertyHeader :header="reference.roleOptions[reference.roleIndex].display">
        <template v-slot:actions>
            
        </template>
    </PropertyHeader>

    <PropertyItem padded :header="'Email text'" :icon="'alpha'" v-if="reference.roleOptions[reference.roleIndex].email && reference.roleOptions[reference.roleIndex].email.subject">
        
        <DesignerGroup c12>
            <DesignerLabel :text="'Subject'" />
            <DesignerTextArea v-model="reference.roleOptions[reference.roleIndex].email.subject[$root.locale.key]" :placeholder="'Not set'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Greeting'" />
            <DesignerInput v-model="reference.roleOptions[reference.roleIndex].email.header[$root.locale.key]" :placeholder="'Not set'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Message'" />
            <DesignerTextArea v-model="reference.roleOptions[reference.roleIndex].email.message[$root.locale.key]" :placeholder="'Not set'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Button text'" />
            <DesignerInput v-model="reference.roleOptions[reference.roleIndex].email.button[$root.locale.key]" :placeholder="'Not set'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Hint'" />
            <DesignerTextArea v-model="reference.roleOptions[reference.roleIndex].email.hint[$root.locale.key]" :placeholder="'Not set'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Contact'" />
            <DesignerTextArea v-model="reference.roleOptions[reference.roleIndex].email.footer[$root.locale.key]" :placeholder="'Not set'" />
        </DesignerGroup>

    </PropertyItem>

    <PropertyItem padded :header="'SMS text'" :icon="'phone'" v-if="reference.roleOptions[reference.roleIndex].email && reference.roleOptions[reference.roleIndex].email.smsMessage">
        
        <DesignerGroup c12>
            <DesignerLabel :text="'Message'" />
            <DesignerTextArea v-model="reference.roleOptions[reference.roleIndex].email.smsMessage[$root.locale.key]" :placeholder="'Not set'" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';
import DesignerTextArea from '../ui/inputs/DesignerTextArea.vue';

export default {
    name: 'EmailPropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
        DesignerTextArea,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() { 
        return {
            
        }
    },
    methods: {

    },
    created() {
        
    }
}
</script>