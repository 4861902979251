<template>
<div>

    <PropertyItem padded :header="'Datepicker properties'" :icon="'input-date'">

        <DesignerGroup c12>
            <DesignerCheckbox v-model="input.pickMode" :text="'Disable select-mode'" @before="$emit('propinput', 'check', 'date_mode', $event)" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';

export default {
    name: 'DatepickerProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerCheckbox,
    },
    props: {
        input: { type: Object, default: null },
    }
}
</script>