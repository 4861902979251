var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vap-label",class:{'lbl-100': (_vm.desc || _vm.header || _vm.alignRight)}},[(_vm.required)?_c('label',{staticClass:"vap-label-required"},[_vm._v("*")]):_vm._e(),_c('label',{class:[
        {'vep-label-h1' : _vm.header},
        {'vep-label-h2' : _vm.header2},
        {'vep-label-d' : _vm.desc},
        {'vep-label-v' : _vm.value},
        {'vep-label-q' : _vm.q},
        {'vep-label-a' : _vm.a},
        {'vep-label-right' : _vm.alignRight},
        {'vep-label-error' : _vm.error},
        {'vep-label-link' : _vm.link} ],on:{"click":_vm.handleLinkClick}},[_vm._v(_vm._s(_vm.text))]),_c('div',{staticClass:"vap-label-actions"},[_vm._t("actions")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }