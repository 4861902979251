<template>
<div class="vf-inp" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" @mouseup="handleOptionMouseUp($event)"
    :class="[
        {'vf-inp-disabled': disabled},
        {'vf-inp-dotted': dotted},
        {'vf-inp-numeric': numeric},
        {'vf-inp-designer': designer},
        {'vf-inp-invalid': validation.done && !validation.valid },
    ]">
    
    <input
        :id="'inpid' + id"
        :value="value"
        :placeholder="placeholder"
        :type="getType()"
        :inputmode="numeric ? 'numeric' : ''"
        :pattern="numeric ? '[0-9]*' : ''"
        @input="handleInput"
        @keydown="handleKeyDown"
        :disabled="disabled"
    />

</div>
</template>

<script>
export default {
    name: 'vf-input',
    props: {
        value: [String, Number],
        placeholder: { type: String, default: '' },

        password: { type: Boolean, default: false },
        numeric: { type: Boolean, default: false },
        maxdigits: { type: String, default: '' },

        validation: { type: Object, default: () => { return { done: false } }},
        dictionary: { type: Object, default: null },
        disabled: { type: Boolean, default: false },
        dotted: { type: Boolean, default: false },

        required: { type: Boolean, default: false },
        noValidation: { type: Boolean, default: false },
        noHighlight: { type: Boolean, default: false },
        
        id: { type: String, default: 'id' },
        designer: { type: Boolean, default: false },
        activeLogic: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        getType() {
            if (this.password) return 'password';
            if (this.numeric) return 'numeric'
            return '';
        },

        handleInput(e) {
            let val = e.srcElement.value;

            if (this.maxdigits && val.length >= Number(this.maxdigits)) {
                val = val.substring(0, Number(this.maxdigits));
                e.srcElement.value = val;
            }

            // Emit input
            this.$emit('input', val);

            // Check if the value matches something in the dictionary
            if (this.dictionary) {
                if (this.dictionary[val]) {
                    this.$emit('match', this.dictionary[val]);
                }
            }
        },

        handleKeyDown(event) {
            if (this.disabled) return;
            if (event.keyCode == 13) {
                this.$emit('onreturn');
            }
        },

        //#region Designer / Logic --------------------------------------------------------------------------------------------

        handleMouseEnter(e) {
            this.$emit('connect-enter', this.id);
        },

        handleMouseLeave(e) {
            this.$emit('connect-leave', this.id);
        },

        handleOptionMouseUp(e) {
            if (this.activeLogic) {
                this.$emit('connect', this.id);
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    mounted() {

    }
}
</script>
<style scoped>

.vf-inp {
    position: relative;
    float: left;
    width: 100%;
}

.vf-inp input {
    width: 100%;
    padding: 6px 12px;
    padding-top: 7px;
    font-size: 15px;
    box-sizing: border-box;
    background-color: var(--card-background) !important;
    border: none;
    background: none;
    outline: none;
    height: 40px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: var(--input-text);
    border-radius: 4px;
    border: solid 1px var(--input-border);
}

.vf-inp input:focus {
    border-color: var(--input-focus);
}

.vf-inp-disabled input {
    background-color: lightgray !important;
}



/* --- Validation -------------------- */

.vf-inp-invalid input {
    border-color: var(--val-error) !important;
}

/* ----------------------------------- */



/* --- Design Mode ------------------- */

.vf-inp-designer input {
    cursor: default;
}

.vf-inp-dotted input {
    border: dotted 2px var(--input-border);
}

.logic .vf-inp:hover input {
    border: solid 3px #0DCC27;
}

/* ----------------------------------- */

</style>
