<template>
<div class="vap-inputc" :class="{'vap-cb-boxonly': boxOnly}">
    <div @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" class="vap-cb-base" :class="[
        {'vap-cb-nodesc': noDesc},
        {'vap-cb-bordered': bordered},
        {'vap-cb-bordero': borderOffset},
        {'vap-cb-card': fillCard},
        {'vap-cb-disabled': disabled},
        {'vap-cb-missing': missingLocale},
        {'val-ok': validation.valid && !noHighlight},
        {'val-error': !validation.valid},
        {'val-show': validation.show}
    ]">
        <div v-if="!options" class="vap-cb" :class="[
            {'vap-cb-radio' : radio},
        ]">
            <div class="vap-cb-check" @click="handleClick">
                <div v-if="value" class="checkmark">
                    <div class="checkmark_stem"></div>
                    <div class="checkmark_kick"></div>
                </div>
            </div>
            <div class="vap-cb-text" @click="handleClick">
                <label>{{ text }}</label>
            </div>
        </div>
        <div v-for="(option, key, i) in options" :key="key" class="vap-cb" @mouseup="handleOptionMouseUp(key, $event)"
            :class="[
                {'vap-cb-inline' : inline},
                {'vap-cb-radio' : radio},
                {'vap-cb-border' : i != 0},
                {'vap-cb-selected' : value[key]},
            ]">
            <div class="vap-cb-check" @click="handleClick(key)">
                <div v-if="value[key] || (designer && option.default)" class="checkmark">
                    <div class="checkmark_stem"></div>
                    <div class="checkmark_kick"></div>
                </div>
            </div>
            <div class="vap-cb-text" @click="handleClick(key)">
                <label>{{ getOption(option) }}</label>
            </div>
        </div>

        <!------- Validation ---------------------------------------------------------------------------->
    <svg v-if="validation.show && validation.valid && !noValidation" height="24" width="24" class="val-ic-ok">
        <path d="M 20.292969 5.2929688 L 9 16.585938 L 4.7070312 12.292969 L 3.2929688 13.707031 L 9 19.414062 L 21.707031 6.7070312 L 20.292969 5.2929688 z"/>
    </svg>
    <svg v-if="validation.show && !validation.valid && !noValidation" height="24" width="24" class="val-ic-error">
        <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z"/>
    </svg>
    <div v-if="validation.show && !validation.valid" class="val-message vip-error">
        <label>{{ '* ' + __(validation.key) }}</label>
    </div>
    <!------- ----------- -------------------------------------------------------------------------->

    </div>
    <div class="vap-input-help" v-if="help">
        <vap-icon v-if="!helpHtml" :icon="'help-o'" >
            <template v-slot:tooltip>
                <vap-tooltip :localized="'show_help'" />
            </template>
        </vap-icon>
        <vap-icon action v-if="helpHtml" :icon="'help-o'" @clicked="showHelpPopup">
            <template v-slot:tooltip>
                <vap-tooltip :localized="'show_help'" />
            </template>
        </vap-icon>
    </div>
</div>
</template>

<script>
import validations from '../../validations'

export default {
    name: 'vap-checkbox',
    props: {
        value: {
			type: [Boolean, Object],
			default: false
		},
        text: {
            type: String,
            default: 'This is a checkbox'
        },
        id: {
			type: String,
			default: 'id'
        },
        activeLogic: {
			type: Boolean,
			default: false
        },
        options: {
            type: Object,
            default: null
        },
        inline: {
            type: Boolean,
            default: false
        },
        radio: {
            type: Boolean,
            default: false
        },
        noDesc: {
            type: Boolean,
            default: false
        },
        bordered: {
            type: Boolean,
            default: false
        },
        help: {
            type: Boolean,
            default: false
        },
        helpHtml: {
			type: String,
			default: ''
        },
        rules: {
            type: String,
            default: ''
        },
        noValidation: {
            type: Boolean,
            default: false
        },
        noHighlight: {
            type: Boolean,
            default: false
        },
        boxOnly: {
            type: Boolean,
            default: false
        },
        designer: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        borderOffset: {
            type: Boolean,
            default: false
        },
        fillCard: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            objects: false,
            validation: {
                show: false,
                valid: true,
                key: ''
            },
            missingLocale: false
        }
    },
    watch: {
        value: {
            deep: true,
            handler(val) {
                if (!this.validation.show && !this.noValidation) this.validation.show = true;
                this.validate(val);
            }
        }
    },
    methods: {
        getOption(option) {
            if (option.locale) {
                if (option.locale[this.$root.locale.key]) {
                    this.missingLocale = false;
                    return option.locale[this.$root.locale.key];
                }else if (option.locale.en_EN) {
                    if (this.designer) this.missingLocale = true;
                    return option.locale.en_EN;
                }else if (option.locale.nb_NO) {
                    if (this.designer) this.missingLocale = true;
                    return option.locale.nb_NO;
                }
                return '';
            }else if (option.key) {
                // TODO ------------------------------ ¯\_(ツ)_/¯ -------------------------------
                if (this.designer) return this.$parent.$parent.$parent.$parent.$parent.__t(option.key);
                return this.__(option.key);
            }
            return option.label;
        },
        validate(value) {
            if (this.noValidation) return;
            if (!value) value = this.value;
            let text = '';
            for (let key in value) {
                if (value[key]) text = 'ok';
            }
            this.validation = {...this.validation, ...validations.validateRules(text, this.rules)};
        },
        handleClick(key) {
            if (this.disabled) return;
            if (this.designer) return;
            if (this.objects) {
                let val = {};
                if (this.radio) {
                    for (let k in this.options) val[k] = false;
                    val[key] = true;
                }else {
                    for (let k in this.options) val[k] = this.value[k];
                    val[key] = !this.value[key];
                }
                this.$emit('input', val);
            }else {
                this.$emit('input', !this.value);
            }
        },
        handleMouseEnter(e) {
            this.$emit('connect-enter', this.id);
        },
        handleMouseLeave(e) {
            this.$emit('connect-leave', this.id);
        },
        handleOptionMouseUp(key, e) {
            if (this.activeLogic) {
                this.$emit('connect', key);
            }
        },
        showHelpPopup() {
            this.showHelpPopupX({ htmlKey: this.helpHtml });
        },
    },
    mounted() {
        if (this.options) {
            this.objects = true;
        }
    }
}
</script>
<style scoped>

.vap-inputc {
    float: left;
    width: 100%;
    display: flex;
}
.vap-input-help {
    float: right;
    width: 35px;
    padding-top: 1px;
    padding-left: 5px;
}

.vap-cb-base {
    position: relative;
    float: left;
    flex-grow: 100;
}

.vap-cb {
    float: left;
    width: 100%;
    display: flex;
    height: auto;
}

.vap-cb-bordered {
    float: left;
    width: 100%;
    height: 40px;
    display: flex;
    border-radius: 4px;
    background-color: var(--card-background);
    border: solid 1px var(--input-border);
    box-sizing: border-box;
}

.vap-cb-bordero .vap-cb {
    margin-top: 4px;
    margin-left: 4px;
}

.vap-cb-boxonly {
    display: unset;
    flex-grow: 0;
    width: 45px;
}

.vap-cb-nodesc {
    margin-top: 0px;
}

.vap-cb-inline {
    width: auto;
    padding-top: 4px;
    padding-left: 4px;
    box-sizing: border-box;
}

.vap-cb-check {
    flex-basis: 22px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-top: 3px; margin-left: 3px;
    width: 22px; height: 22px;
    border: solid 1px #238ccc;
    border-color: var(--input-border);
}
.vap-cb-radio .vap-cb-check {
    border-radius: 50%;
}

.vap-cb-text {
    flex-grow: 100;
    font-size: 18px;
    font-weight: 400;
    margin-top: 4px;
    margin-left: 10px;
    color: var(--input-text);
}
.vap-cb-inline .vap-cb-text {
    margin-right: 15px;
}
.vap-cb-text label  {
    width: 100%;
}

.checkmark {
    display:inline-block;
    width: 26px;
    height: 26px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
}

.checkmark_stem {
    position: absolute;
    width: 6px;
    height: 22px;
    background-color: var(--input-accent);
    left: 11px;
    top: -3px;
}
.checkmark_kick {
    position: absolute;
    width: 12px;
    height: 6px;
    background-color: var(--input-accent);
    left: 2px;
    top: 13px;
}
.vap-cb-radio .checkmark_stem {
    top: 5px; left: 2px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.vap-cb-radio .checkmark_kick {
    display: none;
}

.vap-cb-disabled .vap-cb-text {
    color: var(--text-disabled);
}


/* --- Validation -------------------- */

.val-show.val-ok input {
    border-color: var(--val-ok);
}
.val-show.val-error input {
    border-color: var(--val-error);
}

.val-ic-ok {
    position: absolute;
    top: 8px;
    right: 8px;
    transform: scale(1.0);
}
.val-ic-ok path {
    fill: var(--val-ok);
}

.val-ic-error {
    position: absolute;
    top: 8px;
    right: 8px;
    transform: scale(0.8);
}
.val-ic-error path {
    fill: var(--val-error);
}

.val-message {
    float: left;
    margin-top: 5px;
}

.vap-cb-bordered .val-message {
    position: absolute;
    top: 34px;
}

/* --- ---------- -------------------- */



/* design mode */

.logic .vap-cb:hover .vap-cb-text {
    color: #0DCC27;
    font-weight: bold;
}
.logic .vap-cb:hover .vap-cb-check {
    border: solid 1.5px #0DCC27;
}

.vap-cb-missing {

}




.vap-cb-card {
    background-color: var(--page-background);
    border-radius: 3px;
    border: solid 1px var(--input-border);
}

.vap-cb-card .vap-cb {
    padding: 0px 15px;
    box-sizing: border-box;
    cursor: pointer;
}
.vap-cb-card .vap-cb:hover {
    background-color: var(--item-hover);
}

.vap-cb-card .vap-cb-text {
    padding: 15px 5px;
}
.vap-cb-card .vap-cb-text label {
    cursor: pointer;
}

.vap-cb-card .vap-cb-check {
    margin-top: 18px;
    border-color: var(--input-label);
}

.vap-cb-card .vap-cb-border {
    border-top: solid 1px var(--input-border);
}

.vap-cb-card .vap-cb-selected {
    background-color: var(--card-background);
}

</style>