<template>
<div class="rrpv2-stat" :class="[
    {'rrpv2-stat-1': status == 1 },
    {'rrpv2-stat-2': status == 2 },
    {'rrpv2-stat-3': status == 3 },
]">

    <div class="rrpv2-stat-icon">
        <vap-icon :icon="icon" :color="'ext'" />
    </div>

    <div class="rrpv2-stat-content">

        <div class="rrpv2-stat-type">
            {{ title }}
        </div>

        <div class="rrpv2-stat-text">
            {{ text }}
        </div>

    </div>

    <div class="rrpv2-stat-pdf" v-if="hasReceipt">
        <vap-icon :icon="'pdf'" small action @clicked="$emit('receipt')" :color="'ext'" />
    </div>

</div>
</template>
<script>


export default {
    name: 'ReportResultItem',
    props: {
        icon: { type: String, default: 'bug' },
        title: { type: String, default: 'title' },
        status: { type: Number, default: 0 },
        text: { type: String, default: 'result' },
        hasReceipt: { type: Boolean, default: false }
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        

    },
    created() {
        
    }
}
</script>
<style scoped>

.rrpv2-stat {
    float: left;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    fill: var(--disabled-background);
}

.rrpv2-stat-icon {
    width: 45px;
    padding-top: 6px;
    padding-left: 10px;
}

.rrpv2-stat-content {
    flex-grow: 100;
    padding: 6px;
}
.rrpv2-stat-type {
    color: var(--text-disabled);
}
.rrpv2-stat-text {
    color: var(--text-disabled);
}

.rrpv2-stat-pdf {
    width: 38px;
    padding-top: 10px;
}

.rrpv2-stat-1 {
    background-color: var(--success-background);
    color: var(--success-text);
    fill: var(--success-text);
}
.rrpv2-stat-1 .rrpv2-stat-type {
    color: var(--success-text);
    opacity: 0.7;
}
.rrpv2-stat-1 .rrpv2-stat-text {
    color: var(--tex-strong);
}
.rrpv2-stat-2 {
    background-color: var(--warning-background);
    fill: var(--warning-text);
}
.rrpv2-stat-2 .rrpv2-stat-type {
    color: var(--warning-text);
    opacity: 0.8;
}
.rrpv2-stat-2 .rrpv2-stat-text {
    color: var(--tex-strong);
}
.rrpv2-stat-3 {
    fill: var(--error-background);
}
.rrpv2-stat-3 .rrpv2-stat-type {
    color: var(--text-weak);
}
.rrpv2-stat-3 .rrpv2-stat-text {
    color: var(--tex-strong);
}

</style>