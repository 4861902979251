<template>
<div class="frmod-kyc-comp">

    <div class="frmod-kyc-comp-header">
        {{ 'Data endret i egenerklæring' }}
    </div>

    <div class="frmod-kyc-comp-item" v-for="(change, i) in changes" :key="'kc' + i">

        <div class="frmod-kyc-comp-icon">
            <vap-icon small :icon="getIcon(change)" />
        </div>
        <div class="frmod-kyc-comp-content">
            <div class="frmod-kyc-comp-role">
                {{ getDisplayRole(change) }}
            </div>
            <div class="frmod-kyc-comp-change" v-html="getDisplayChange(change)" />
        </div>
        <!-- {{ change }} -->
    </div>

    <div class="frmod-kyc-comp-empty" v-if="changes.length == 0">
        {{ 'Ingen data endret i egenerklæringen' }}
    </div>

</div>
</template>
<script>
export default {
    name: "KycComparisonRenderer",
    props: {
        changes: { type: Array, default: () => { return [] }}
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        getIcon(change) {
            switch (change.type) {
                case 'add': return 'add';
                case 'shr': return 'edit';
                case 'del': return 'cancel';
            }
            return 'bug';
        },

        getDisplayRole(change) {
            switch (change.roleId) {
                case 'role_rrh': return 'Reell rettighetshaver';
                case 'role_shareholder': return 'Aksjonær';
                case 'role_ceo': return 'Daglig leder';
                case 'role_chair': return 'Styreleder';
                case 'role_board_member': return 'Styremedlem';
            }
            return 'Unknown change';
        },

        getDisplayChange(change) {
            let str = '<span>';
            if (change.type == 'shr') {
                str += '<b>' + this.companyTitleCase(change.name) + '</b>';
                str += ' - eierandel endret fra <b>';
                str += change.oShare + '%';
                str += '</b> til <b>';
                str += change.nShare + '%';
                str += '</b>';
            }else if (change.type == 'add') {
                str += '<b>' + this.companyTitleCase(change.name) + '</b>';
                str += ' - lagt til som ';
                switch (change.roleId) {
                    case 'role_rrh': str += 'reell rettighetshaver'; break;
                    case 'role_shareholder': str += 'aksjonær'; break;
                    case 'role_ceo': str += 'daglig leder'; break;
                    case 'role_chair': str += 'styreleder'; break;
                    case 'role_board_member': str += 'styremedlem'; break;
                }
            }else if (change.type == 'del') {
                str += '<b>' + this.companyTitleCase(change.name) + '</b>';
                str += ' - tatt bort som ';
                switch (change.roleId) {
                    case 'role_rrh': str += 'reell rettighetshaver'; break;
                    case 'role_shareholder': str += 'aksjonær'; break;
                    case 'role_ceo': str += 'daglig leder'; break;
                    case 'role_chair': str += 'styreleder'; break;
                    case 'role_board_member': str += 'styremedlem'; break;
                }
            }
            str += '</span>';
            return str;
        },

    },
    created() {
        console.log('changes', this.changes);
    }
}
</script>
<style>

.frmod-kyc-comp {

}

.frmod-kyc-comp-header {
    padding-left: 5px;
    padding-bottom: 5px;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.6px;
    color: var(--input-label);
}

.frmod-kyc-comp-item {
    display: flex;
    border-top: solid 1px var(--input-border);
}

.frmod-kyc-comp-icon {
    width: 40px;
    padding-top: 5px;
}

.frmod-kyc-comp-content {
    padding: 3px 0px;
}

.frmod-kyc-comp-role {
    font-size: 15px;
    color: var(--text-weak);
}

.frmod-kyc-comp-change {
    font-size: 15px;
    color: var(--text-title);
}


.frmod-kyc-comp-empty {
    padding-left: 5px;
    font-size: 16px;
    color: var(--text-title);
}

</style>