<template>
<div class="vf-kyci" @click.stop="handleClick" :class="[
    { 'vf-selected' : selected }
]">
    
    <div v-if="designer">
        {{ 'Customer information' }}
    </div>

    <div v-if="!designer">
        
        <div class="flw100">
            <TabCompany v-if="client.company" :company="client.company" />
        </div>

        <div class="flw100" style="margin-top: 20px;">
            <TabOwnership v-if="client.company" :company="client.company" />
        </div>

    </div>

</div>
</template>

<script>
import TabCompany from '../../../customer/TabCompany.vue';
import TabOwnership from '../../../customer/TabOwnership.vue';

export default {
    name: 'KycClientInfo',
    components: {
        TabCompany,
        TabOwnership,
    },
    props: {
        cardData: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    data() {
        return {
            client: null,
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

    },
    created() {
        if (this.designer) {

            // Do nothing ?

        }else {

            if (this.reference.roleKey == 'owner') {

                const existingData = JSON.parse(this.$root.formData);
                this.client = existingData.customer;

            }else {
                
                this.client = this.reference.clientData.client;

            }

        }
    }
}
</script>
<style scoped>

.vf-kyci {
    float: left;
    width: 100%;
    box-sizing: border-box;
}




/* Designer / Selection */

.vf-selected {
    outline: solid 2px #238ccc;
}

</style>
