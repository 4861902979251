<template>
<div class="fbinp-drop" v-on-clickaway="hideDropdown">
    
    <div class="fbinp-drop-value" @click="onValueClicked">
        <span v-if="value">{{ objectValue }}</span>
        <span v-if="!value" style="color: var(--fb-text-4);">{{ placeholder }}</span>
    </div>

    <div class="fbinp-drop-dd" v-if="open">

        <div class="fbinp-drop-item" v-for="(item, i) in options" :key="'dd' + i" @click="handleItemClick(i)">
            {{ item[displayKey] }}
        </div>

    </div>

    <svg height="26" width="26" class="fbinp-drop-arrow">
        <path v-if="open" d="M 13 10.59375 L 21.765625 19.179688 C 22.15625 19.5625 22.78125 19.558594 23.171875 19.171875 L 24.707031 17.636719 C 25.097656 17.242188 25.097656 16.609375 24.703125 16.21875 L 13.707031 5.292969 C 13.511719 5.097656 13.257813 5 13 5 C 12.742188 5 12.488281 5.097656 12.292969 5.292969 L 1.296875 16.21875 C 0.902344 16.609375 0.902344 17.242188 1.292969 17.636719 L 2.828125 19.171875 C 3.21875 19.558594 3.84375 19.5625 4.234375 19.179688 Z"/>
        <path v-if="!open" d="M 13 15.40625 L 21.765625 6.820313 C 22.15625 6.4375 22.78125 6.441406 23.171875 6.828125 L 24.707031 8.363281 C 25.097656 8.757813 25.097656 9.390625 24.703125 9.78125 L 13.707031 20.707031 C 13.511719 20.902344 13.257813 21 13 21 C 12.742188 21 12.488281 20.902344 12.292969 20.707031 L 1.296875 9.78125 C 0.902344 9.390625 0.902344 8.757813 1.292969 8.363281 L 2.828125 6.828125 C 3.21875 6.441406 3.84375 6.4375 4.234375 6.820313 Z"/>
    </svg>

</div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
    name: 'DesignerDropdown',
    mixins: [clickaway],
    props: {
        value: [String, Number],
        options: { type: Array, default: () => { return [] } },
        displayKey: { type: String, default: 'display' },
        outputKey: { type: String, default: 'value' },
        placeholder: { type: String, default: 'Not set' },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            open: false,
        }
    },
    computed: {
        objectValue: function () {
            return this.getTitleFromValue(this.value);
        }
    },
    methods: {

        onValueClicked() {
            this.open = !this.open;
        },

        hideDropdown() {
            this.open = false;
        },

        getTitleFromValue(value) {
            for (let i in this.options) {
                if (this.options[i][this.outputKey] == value) return this.options[i][this.displayKey];
            }
            return '';
        },

        handleItemClick(index) {
            this.open = false;
            this.$emit('input', this.options[index][this.outputKey]);
        },

    }
}
</script>
<style scoped>

.fbinp-drop {
    position: relative;
    width: 100%;
    height: 26px;
    color: var(--fb-text-1);
    border: solid 1px var(--fb-input-bg);
    background-color: var(--fb-input-bg);
    box-sizing: border-box;
}

.fbinp-drop-value {
    padding: 0px 5px;
    padding-top: 4px;
    font-size: 14px;
    cursor: pointer;
}

.fbinp-drop-dd {
    position: absolute;
    top: 25px;
    left: 0px;
    min-width: 100%;
    border-bottom: solid 1px var(--fb-menu-b);
    background-color: var(--fb-input-bg);
    z-index: 2;
}

.fbinp-drop-item {
    padding: 3px 5px;
    font-size: 14px;
    border-top: solid 1px var(--fb-menu-b);
    cursor: pointer;
}
.fbinp-drop-item:hover {
    background-color: var(--fb-menu-a);
}

.fbinp-drop-arrow {
    position: absolute;
    top: 0px;
    right: 1px;
    transform: scale(0.5);
    pointer-events: none;
    fill: white;
    opacity: 0.6;
}

</style>