import log from '../../../../log';
import api from '../../../../portalapi';
import formHelper from '../../formHelper';
const signModuleHelper = {

    getEmptyConfig() {
        return {
            type: 'sign',
            icon: 'sign',
            methods: {
                bankidno: true,
                bankidse: true,
                touchsign: true
            }
        };
    },

    onFormCreated(root, reference, designer, module, pages, isOwner, userdata) {

        let signConfig = {};
        for (const i in reference.modules) {
            if (reference.modules[i].type == 'sign') signConfig = reference.modules[i];
        }

        const existingData = JSON.parse(root.formData);
        log.x('Signing Module: On form created, config:', signConfig);

        // Add available signing methods
        log.x('Signing Module: Checking available methods:', module);
        if (reference.resources) {
            root.$set(reference.resources, 'signMethods', {
                type: 'list',
                key: 'signMethods',
                name: 'Signing Methods',
                module: 'sign',
                definition: {
                    value: 'string',
                    en_EN: 'string',
                    nb_NO: 'string',
                    sv_SE: 'string',
                },
                items: []
            });
        }
        if (module.methods) {
            if (module.methods.bankidno) {
                reference.resources.signMethods.items.push({ value: 'bankid-no', en_EN: 'BankID Norway', nb_NO: 'BankID Norge', sv_SE: 'BankID Norge' });
            }
            if (module.methods.bankidse) {
                reference.resources.signMethods.items.push({ value: 'bankid-se', en_EN: 'BankID Sweden', nb_NO: 'BankID Sverige', sv_SE: 'BankID Sverige' });
            }
            if (module.methods.touchsign) {
                reference.resources.signMethods.items.push({ value: 'touch-sign', en_EN: 'Touch Sign', nb_NO: 'Touch Sign', sv_SE: 'Touch Sign' });
            }
        }else {
            reference.resources.signMethods.items.push({ value: 'bankid-no', en_EN: 'BankID Norway', nb_NO: 'BankID Norge', sv_SE: 'BankID Norge' });
            reference.resources.signMethods.items.push({ value: 'bankid-se', en_EN: 'BankID Sweden', nb_NO: 'BankID Sverige', sv_SE: 'BankID Sverige' });
            reference.resources.signMethods.items.push({ value: 'touch-sign', en_EN: 'Touch Sign', nb_NO: 'Touch Sign', sv_SE: 'Touch Sign' });
        }
        log.x('Signing Module: Available methods:', reference.resources.signMethods.items);

        let hasCheckout = false;
        for( const i in pages){
            for(const j in pages[i].cards){
                if(pages[i].cards[j].type == 'checkout'){
                    hasCheckout = true;
                }
            }
        }

        // Prepare signing model
        reference.signModel = {
            direct: false,
            method: '',
            signers: [],
            hasCheckout: hasCheckout,
            checkoutValid: false,
            signatureCheck: false,
            selectedOption: [],
            files: [],
            message: ''
        };

        if (!isOwner && userdata) {
            reference.signModel = userdata.signModel;
        }
        
    },

    onSetUserdata(root, reference, module, envelope, userdata, recipient) {

        const existingData = JSON.parse(root.formData);
        log.x('Signing Module: On set userdata, existing data:', existingData);
        
        userdata.signModel = reference.signModel;

    },

    async onFormSubmitted(root, reference, module, nextRoleKey, envelope) {

        const existingData = JSON.parse(root.formData);
        log.x('Signing Module: On form submitted, existing data:', existingData);

    },

    onLinkValue(root, reference, designer, module, link, value) {

        log.x('Signing Module: On link value (' + link.link + ')', value);

        if (link.link == 'direct') {
            
            reference.signModel.direct = value[link.key];

        }else if (link.link == 'method') {
            
            reference.signModel.method = value;

        }
        
        //console.log('SIGN MODEL', reference.signModel);

    },

};
export default signModuleHelper;