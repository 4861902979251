const flowEditHelper = {

    getTestClient(flow) {
        return {
            name: 'Test Client',
            company: {},
            report: {
                created: false
            }
        };
    },

    getTestCounts(flow) {
        return {
            selected: 1,
            screened: 0,
            identified: 0
        };
    },

    //#region Default steps -----------------------------------------------------------------------------------------------

    getEmptyStep(key, name) {
        return {
            key: key,
            name: name,
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'formkey',
                    triggers: [
                        { type: 'form', key: 'formkey', trg: 'sent', rev: true },
                    ],
                    text: {
                        en_EN: 'Start',
                        nb_NO: 'Start',
                        sv_SE: 'Start'
                    },
                    hint: {
                        en_EN: 'This action does not have a hint yet',
                        nb_NO: 'This action does not have a hint yet',
                        sv_SE: 'This action does not have a hint yet',
                    }
                },
            ],
            title: {
                en_EN: name,
                nb_NO: name,
                sv_SE: name,
            },
            hint: {
                en_EN: 'This step does not have a hint yet',
                nb_NO: 'This step does not have a hint yet',
                sv_SE: 'This step does not have a hint yet',
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Step completed',
                                nb_NO: 'Step completed',
                                sv_SE: 'Step completed'
                            },
                            triggers: [
                                { type: 'form', key: 'formkey', trg: 'sent', rev: false },
                            ]
                        }
                    ]
                },
            }
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Empty objects -----------------------------------------------------------------------------------------------

    getEmptyObject(type) {
        switch (type) {
            case 'status': return {
                key: '',
                text: {
                    en_EN: 'New status',
                    nb_NO: 'New status',
                    sv_SE: 'New status',
                },
                triggers: [
                    {
                        type: 'form',
                        key: 'form00',
                        trg: 'sent',
                        rev: false
                    }
                ]
            };
            case 'trigger': return {
                type: 'form',
                key: 'form00',
                trg: 'sent',
                rev: false
            };
            case 'event': return {
                key: 'event00',
                text: {
                    en_EN: 'New event',
                    nb_NO: 'New event',
                    sv_SE: 'New event',
                },
                date: {
                    type: 'org',
                    date: 'searchDate'
                },
                triggers: [
                    {
                        type: 'form',
                        key: 'form00',
                        trg: 'sent',
                        rev: false
                    }
                ]
            };
            case 'statusf': return {
                text: {
                    en_EN: 'New status',
                    nb_NO: 'New status',
                    sv_SE: 'New status',
                },
                triggers: [
                    {
                        type: 'form',
                        key: 'form00',
                        trg: 'sent',
                        rev: false
                    }
                ]
            };
            case 'action': return {
                icon: 'open',
                main: false,
                action: 'form-start',
                key: 'form00',
                triggers: [
                    {
                        type: 'form',
                        key: 'form00',
                        trg: 'sent',
                        rev: false
                    }
                ],
                text: {
                    en_EN: 'New action',
                    nb_NO: 'New action',
                    sv_SE: 'New action',
                },
                hint: {
                    en_EN: 'This step does not have a hint yet',
                    nb_NO: 'This step does not have a hint yet',
                    sv_SE: 'This step does not have a hint yet',
                },
            };
            case 'locale': return {
                en_EN: '',
                nb_NO: '',
                sv_SE: '',
            };
        }
        return {};
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default flowEditHelper;