import Vue from 'vue';
import VueRouter from 'vue-router';

// Portal
import Home from '../views/Home.vue';
import NewCustomer from '../views/NewCustomer.vue';
import Clients from '../views/Clients.vue';
import Client from '../views/Client.vue';
import Search from '../views/Search.vue';
import Individual from '../views/Individual.vue';

// Settings
import Settings from '../views/Settings.vue';
import Template from '../views/Template.vue';

// KYC / IDC
import KYC from '../views/KYC.vue';
import IDControl from '../views/IDControl.vue';
import KYCLanding from '../views/KYCLanding.vue';
import RecipientPortal from '../views/RecipientPortal.vue';
import FlowPortal from '../custom/FlowPortal.vue';
import FormRecipient from '../views/FormRecipient.vue';

import PublicKYC from '../views/PublicKYC.vue';
import PublicIDC from '../views/PublicIDC.vue';

import IDC from '../views/IDC.vue';

// Custom views
import Assessment from '../views/Assessment.vue';
import ShareApplication from '../custom/shareapp/ShareApplication.vue';

// Forms
import FormDesignView from '../views/forms/FormDesignView.vue';
import FormList from '../views/forms/FormList.vue';
import FormPublic from '../views/forms/FormPublic.vue';
import FormRedirect from '../views/forms/FormRedirect.vue';
import FormFillView from '../views/forms/FormFillView.vue';
import FormLandingView from '../views/forms/FormLandingView.vue';

// Mass sendout / reminders
import MassSendout from '../views/MassSendout.vue';
import Reminders from '../views/Reminders.vue';

// Management
import AdminView from '../views/management/AdminView.vue';
import PDFTest from '../views/management/PDFTest.vue';
import ConfigTools from '../views/management/ConfigTools.vue';
import ScriptView from '../views/management/ScriptView.vue';
import ScriptViewPeople from '../views/management/ScriptViewPeople.vue';
import StatusSync from '../views/management/StatusSync.vue';
import DBQuery from '../views/management/DBQuery.vue'
import BatchSaveReport from '../views/management/BatchSaveReport.vue';
import BatchUpgrade from '../views/management/BatchUpgrade.vue';
import ExportPersons from '../views/management/ExportPersons.vue';
import StatusUpdateView from '../views/management/StatusUpdateView.vue';
import BatchKycReminder from '../views/management/BatchKycReminder.vue';
import BatchDownloadReport from '../views/management/BatchDownloadReport.vue';

// Misc
import ViewerTest from '../views/ViewerTest.vue';

// V1
import EventsV1 from '../v1views/EventsV1.vue';
import SearchV1 from '../v1views/SearchV1.vue';
import SearchV2 from '../v1views/SearchV2.vue';
import ClientV1 from '../v1views/ClientV1.vue';
import ClientsV1 from '../v1views/ClientsV1.vue';
import RolesV1 from '../v1views/RolesV1.vue';
import PersonV1 from '../v1views/PersonV1.vue';
import ReportsV1 from '../v1views/ReportsV1.vue';
import ReportsV2 from '../v1views/ReportsV2.vue';
import SettingsV1 from '../v1views/SettingsV1.vue';
import AdminV1 from '../v1views/AdminV1.vue';
import OnboardingV1 from '../v1views/OnboardingV1.vue';
import MigrationV1 from '../v1views/MigrationV1.vue';
import RemindersV1 from '../v1views/RemindersV1.vue';
import BatchV1 from '../v1views/BatchV1.vue';
import BatchListV1 from '../v1views/BatchListV1.vue';
import TasksV1 from '../v1views/TasksV1.vue';
import MonitorV1 from '../v1views/MonitorV1.vue';

// V1 backoffice
import BackofficeV1 from '../v1views/BackofficeV1.vue';
import BackofficeAccount from '../v1views/BackofficeAccount.vue';
import BackofficeError from '../v1views/BackofficeError.vue';

// Flows
import FlowEditView from '../v1views/FlowEditView.vue';
import FlowDesignerView from '../v1views/FlowDesignerView.vue';

import MapNoView from '../v1data/MapNoView.vue';

Vue.use(VueRouter);

const routes = [
    // Portal
    { path: '/', name: 'Home', component: Home },
    { path: '/new', name: 'NewCustomer', component: NewCustomer },
    { path: '/clients', name: 'Clients', component: Clients },
    { path: '/client', name: 'Client', component: Client },
    { path: '/search', name: 'Search', component: Search },
    { path: '/person', name: 'Individual', component: Individual },

    // Settings
    { path: '/settinglist', name: 'Settings', component: Settings },
    { path: '/settings', name: 'Template', component: Template },

    // KYC / IDC
    { path: '/kyc', name: 'KYC', component: KYC },
    { path: '/id-kontroll', name: 'IDControl', component: IDControl },
    { path: '/kyclanding/:recipientId/:envelopeId', name: 'KYCLanding', component: KYCLanding },
    { path: '/recipient', name: 'RecipientPortal', component: RecipientPortal },
    { path: '/flow/:envelopeId/:recipientId', name: 'FlowPortal', component: FlowPortal },
    { path: '/landing/:envelopeId/:recipientId', name: 'FormRecipient', component: FormRecipient },

    { path: '/kycp/:company/:key', name: 'PublicKYC', component: PublicKYC },
    { path: '/id/:company/:key', name: 'PublicIDC', component: PublicIDC },

    { path: '/idc', name: 'IDC', component: IDC },

    // Custom views
    { path: '/assessment', name: 'Assessment', component: Assessment },
    { path: '/shareapp', name: 'ShareApplication', component: ShareApplication },
    
    // Forms
    { path: '/formlist', name: 'FormList', component: FormList },
    { path: '/formdesigner', name: 'FormDesignView', component: FormDesignView },
    { path: '/form/:company/:key', name: 'FormPublic', component: FormPublic },
    { path: '/fform', name: 'FormRedirect', component: FormRedirect },
    { path: '/fform/:envelopeId/:recipientId', name: 'FormFillView', component: FormFillView },
    { path: '/lform/:envelopeId/:recipientId', name: 'FormLandingView', component: FormLandingView },

    // Mass sendout / reminders
    { path: '/batch', name: 'MassSendout', component: MassSendout },
    { path: '/reminders', name: 'Reminders', component: Reminders },

    // Management
    { path: '/admin', name: 'AdminView', component: AdminView },
    { path: '/pdftest', name: 'PDFTest', component: PDFTest },
    { path: '/tools', name: 'ConfigTools', component: ConfigTools },
    { path: '/sync', name: 'StatusSync', component: StatusSync },
    { path: '/db', name: 'DBQuery', component: DBQuery },
    { path: '/batchsave', name: 'BatchSaveReport', component: BatchSaveReport },
    { path: '/upgrade', name: 'BatchUpgrade', component: BatchUpgrade },
    { path: '/exportpersons', name: 'ExportPersons', component: ExportPersons },
    { path: '/export', name: 'ScriptView', component: ScriptView },
    { path: '/exportpeople', name: 'ScriptViewPeople', component: ScriptViewPeople },
    { path: '/batchstatus', name: 'StatusUpdateView', component: StatusUpdateView },
    { path: '/batchreminder', name: 'BatchKycReminder', component: BatchKycReminder },
    { path: '/batchdownload', name: 'BatchDownloadReport', component: BatchDownloadReport },
    
    // Misc
    { path: '/viewtest', name: 'ViewerTest', component: ViewerTest },

    // V1
    { path: '/v1events', name: 'EventsV1', component: EventsV1 },
    { path: '/v1search', name: 'SearchV1', component: SearchV1 },
    { path: '/v2search', name: 'SearchV2', component: SearchV2 },
    { path: '/v1client', name: 'ClientV1', component: ClientV1 },
    { path: '/v1clients', name: 'ClientsV1', component: ClientsV1 },
    { path: '/v1roles', name: 'RolesV1', component: RolesV1 },
    { path: '/v1person', name: 'PersonV1', component: PersonV1 },
    { path: '/v1reports', name: 'ReportsV1', component: ReportsV1 },
    { path: '/v2reports', name: 'ReportsV2', component: ReportsV2 },
    { path: '/v1settings', name: 'SettingsV1', component: SettingsV1 },
    { path: '/v1admin', name: 'AdminV1', component: AdminV1 },
    { path: '/v1onboarding', name: 'OnboardingV1', component: OnboardingV1 },
    { path: '/v1migration', name: 'MigrationV1', component: MigrationV1 },
    { path: '/v1reminders', name: 'RemindersV1', component: RemindersV1 },
    { path: '/v1batch', name: 'BatchV1', component: BatchV1 },
    { path: '/v1batchlist', name: 'BatchListV1', component: BatchListV1 },
    { path: '/v1tasks', name: 'TasksV1', component: TasksV1 },
    { path: '/monitor', name: 'MonitorV1', component: MonitorV1 },

    // V1 backoffice
    { path: '/backoffice', name: 'BackofficeV1', component: BackofficeV1 },
    { path: '/backoffice-account', name: 'BackofficeAccount', component: BackofficeAccount },
    { path: '/backoffice-error', name: 'BackofficeError', component: BackofficeError },

    // Flows
    { path: '/v1flow', name: 'FlowEditView', component: FlowEditView },
    { path: '/v1flowdesigner', name: 'FlowDesignerView', component: FlowDesignerView },

    { path: '/map', name: 'MapNoView', component: MapNoView },
];

const router = new VueRouter({
    mode: process.env.VUE_APP_ROUTER_MODE || 'history',
    routes
});

export default router;