<template>
<div>

    <PopupV1 :header="__t('lookup-header')" @close="onClose" :dialog="dialog">
        <template v-slot:content>

            <div v-if="!needsBrreg">

                <div style="margin-bottom: 10px; color: var(--text-title);">
                    {{ __t('screening-hint-documents') + ':' }}
                </div>

                <DialogContent>
                    <DialogContentHeader :text="__t('files-document').toUpperCase() + ':'" />
                    <DialogContentItem :text="__t('search-name') + ' ' + params.name" :icon="'file-search'" />
                    <DialogContentHeader :text="__t('files-attachments').toUpperCase() + ':'" v-if="params.source == 'bankidaml'" />
                    <DialogContentItem :text="__t('lookup-baml-report')" :icon="'attachment'" v-if="params.source == 'bankidaml'" />
                    <DialogContentItem :text="__t('lookup-baml-inc')" :icon="'attachment'" v-if="params.source == 'bankidaml'" />
                </DialogContent>
                <div style="margin-top: 20px; margin-bottom: 10px; color: var(--text-title);">
                    {{ __t('lookup-source-hint') + ':' }}
                </div>
                <DialogContent style="position: relative; padding-top: 10px;">
                    <DialogContentItem v-for="(source, i) in sources" :key="'sds' + i" :text="source.name" :subtext="source.subtext" :icon="'search'"
                        :style="i == 0 ? 'padding-right: 110px;': ''"
                    />
                    <div class="scrdv1-screen-image">
                        <div class="scrdv1-screen-image-baml" v-if="$root.config.screening.nordicProvider == 'baml'">
                            <img :src="$root.images.bamltiny" />
                        </div>
                        <div class="scrdv1-screen-image-trap" v-if="$root.config.screening.nordicProvider == 'trapets'">
                            <img :src="$root.images.trapetstiny" />
                        </div>
                    </div>
                </DialogContent>

            </div>

            <div v-if="needsBrreg">

                <div style="margin-bottom: 10px; color: var(--text-title);">
                    {{ 'Could not find company organization number, please find the company in Brreg first' }}
                </div>

                <div class="flw100" style="padding: 0px 1px; padding-bottom: 20px;">
                    <vap-label :text="__('customer_brreg_hint')" desc />
                    <ve-brreg-search @company-selected="onBrregResult" />
                </div>

            </div>

        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('lookup-action')"
                :icon="'search'"
                @clicked="onPerformLookup"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import amlTools from '../aml';
import dataSwedenV1 from '../v1data/dataSwedenV1';

import v1api from '../v1helpers/v1api';
import v1flows from '../v1helpers/v1flows';
import v1receipt from '../v1helpers/v1receipt';
import v1lookupHelper from '../v1search/v1lookupHelper';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

export default {
    name: 'LookupDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox
    },
    props: {
        client: { type: Object, default: null },
        params: { type: Object, default: null },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            sources: [],
            needsBrreg: false,
        }
    },
    methods: {

        async onPerformLookup() {
            this.dialog.working = true;

            const client = this.params.client;
            let flow = null;
            let identity = '';
            let source = '';

            if (this.customData) {

                flow = v1flows.getFlow(this.$root, this.customData.flow);
                identity = this.customData.identity;
                source = this.customData.source;

            }else {

                flow = v1flows.getFlow(this.$root, client.flow);
                identity = client.identity;
                source = client.source;

            }

            const initialData = {
                brreg: null,
                trapetsData: null
            };

            if (this.params.source == 'bankidaml') {

                // Get initial data from Brreg
                this.dialog.status = this.__t('lookup-prog-brreg');
                initialData.brreg = await amlTools.getBrregFromNumber(identity);
                this.dialog.status = this.__t('lookup-prog-baml');

            }else if (this.params.source == 'trapets-bisnode') {

                // Get initial data from Trapets
                this.dialog.status = this.__t('lookup-prog-trapets');
                const trapetsResult =  await dataSwedenV1.getTrapetsData(identity);
                initialData.trapetsData = trapetsResult.data;
                this.dialog.status = this.__t('lookup-prog-bisnode');

            }

            

            const result = await v1lookupHelper.getCompanyData(this.$root, source, identity, initialData);
            await v1api.refreshCompany(client._id, result.companyData);

            this.dialog.status = this.__t('report-prog-receipt');

            const envelope = await v1receipt.createClientEnvelope(this.$root, client, result.companyData, flow, result.rawData);
            const updateData = {
                envelopeId: envelope.id,
                documentId: envelope.documentId,
                name: this.__t('search-name') + ' ' + this.companyTitleCase(result.companyData.name)
            };
            if (this.customData) {
                updateData.identity = this.customData.identity;
                updateData.source = this.customData.source;
                updateData.flow = this.customData.flow;
                updateData.changeFormKeys = [{ from: 'kyc02', to: 'kyc00' }];
            }

            this.dialog.status = this.__t('lookup-prog-company');

            await v1api.updateClient(client._id, updateData);

            for (const i in result.reports) {
                this.dialog.status = this.__t('lookup-prog-baml-report') + ' (' + Number(Number(i) + 1) + '/' + result.reports.length + ')';
                await v1receipt.addBankIDReportToClientEnvelope(this.$root, envelope, client._id, result.reports[i]);
            }
            
            this.dialog.status = this.__t('report-prog-update');

            const response = await v1api.getClient(client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        },

        validateDialog() {
            this.dialog.valid = false;
            if (!this.needsBrreg) this.dialog.valid = true;
        },

        onBrregResult(company, rawData) {
            this.brregResult = amlTools.processBrreg(rawData);
            //console.log('this.brreg', this.brregResult);
            this.params.name = this.brregResult.name;
            this.customData = {
                identity: this.brregResult.org_no,
                source: 'bankidaml',
                flow: this.getNoFlowKey(),
            };
            this.needsBrreg = false;
            this.validateDialog();
        },

        getNoFlowKey() {
            const flows = v1flows.getAvailableFlows2(this.$root, 'org', 'NO', 'bankidaml', null);
            console.log('flows', flows);
            if (flows.length == 0) return 'no-org-baml';
            return flows[0].key;
        },

    },
    async created() {
        this.loading = true;
        this.dialog.working = true;
        this.dialog.status = this.__t('verified-reminder-loading');

        console.log('this', this.params);

        if (this.params.source == 'unknown' || this.params.source == 'manual'
        || !this.params.client.identity || this.params.client.identity.includes('AUTO_')) {
            this.needsBrreg = true;
            this.params.source = 'bankidaml';
        }

        if (this.params.source == 'bankidaml') {
            this.sources.push({ name: 'Brønnøysundregistrene' });
            this.sources.push({ name: 'Skatteetaten', subtext: 'Aksjonærregisteret' });
            this.sources.push({ name: 'T-Rank', subtext: 'ownership, beneficial owners, and company roles' });
            this.sources.push({ name: 'Bisnode', subtext: 'general company information, financial information, and sanction search results' });
        }else if (this.params.source == 'trapets-bisnode') {
            this.sources.push({ name: 'Trapets', subtext: 'ownership, beneficial owners, company roles and sanction search results' });
            this.sources.push({ name: 'Bisnode', subtext: 'general company information, financial information' });
        }

        this.validateDialog();
        this.dialog.working = false;
        this.loading = false;
    }
}
</script>
<style scoped>

.scrdv1-screen-image {
    position: absolute;
    top: 15px; right: 20px;
}

.scrdv1-screen-image-baml img {
    width: 140px;
}

.scrdv1-screen-image-trap img {
    width: 100px;
}

</style>