<template>
<div>
    <PageHeader compactable :offset="104" @scrolled="scrolled = $event">
        <template v-slot:content>
            <vap-row v-if="!loading">
                <vap-group auto noPadding>
                    <vap-label :text="'Batch customer creation'" desc/>
                    <vap-label :text="'Create customers in batch by uploading CSV files'" value />
                </vap-group>
            </vap-row>
        </template>
    </PageHeader>

    <div class="form-page" style="padding-top: 80px;">

        <BatchView :items="items" :steps="steps" :skip="skip" :title="'Clients'" />

    </div>

    <PageFooter :scrolled="scrolled" />
</div>
</template>
<script>
import PageHeader from '../v1ui/PageHeader.vue';
import PageContent from '../v1ui/PageContent.vue';
import PageFooter from '../v1ui/PageFooter.vue';

import BatchView from '../components/special/BatchView';

import amlTools from '../aml';
import dataSwedenV1 from '../v1data/dataSwedenV1';
import v1api from '../v1helpers/v1api';
import v1flows from '../v1helpers/v1flows';
import v1receipt from '../v1helpers/v1receipt';
import v1lookupHelper from '../v1search/v1lookupHelper';

import formSender from '../components/form/formSender';

export default {
    name: 'BatchV1',
    components: {
        PageHeader,
        PageContent,
        PageFooter,
        BatchView,
    },
    data() {
        return {
            loading: true,
            scrolled: false,
            batch: null,
            items: [],
            steps: 5,
            STEP_PERFORM_CHECKS: 0,
            STEP_CREATE_CLIENT: 1,
            STEP_COMPANY_SEARCH: 2,
            STEP_SEND_KYC: 3,
            STEP_FINALIZE: 4,
            skip: 0
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            return item.companyName + ' (' + item.identity + ')';
        },

        onGetInfo1(item) {
            return 'Not sent'
        },

        onGetInfo2(item) {
            return item.firstName + ' ' + item.lastName + ' (' + item.email + ')';
        },

        async onRemoveItem(item) {},

        async onStart() {
            return true;
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_PERFORM_CHECKS: return 'Checking customer';
                case this.STEP_CREATE_CLIENT: return 'Creating new customer';
                case this.STEP_COMPANY_SEARCH: return 'Performing company lookup';
                case this.STEP_SEND_KYC: return 'Sending out self-declaration';
                case this.STEP_FINALIZE: return 'Finalizing';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_PERFORM_CHECKS: return await this.performChecks(item, data);
                case this.STEP_CREATE_CLIENT: return await this.createClient(item, data);
                case this.STEP_COMPANY_SEARCH: return await this.performSearch(item, data);
                case this.STEP_SEND_KYC: return await this.sendKyc(item, data);
                case this.STEP_FINALIZE: return await this.finalizeClient(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async performChecks(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { createAction: 'new', existingClient: null, existingPersons: [] } };

            let existingClient = null;
            try {
                const response = await v1api.getClientFromIdentity(item.identity);
                existingClient = response.client;
                result.data.existingPersons = response.persons;
                result.data.existingClient = existingClient;
            }catch (ex) {

            }
            console.log('existingClient', existingClient);

            if (existingClient) {
                if (existingClient.report.created) {
                    result.data.createAction = 'archive';
                }else {
                    result.data.createAction = 'reset';
                }
            }else {
                result.data.createAction = 'new';
            }

            result.ok = true;
            return result;
        },

        async createClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: { clientId: '' } };

            const createAction = data[this.STEP_PERFORM_CHECKS].createAction;
            console.log('createAction', createAction);

            const existingClient = data[this.STEP_PERFORM_CHECKS].existingClient;
            console.log('existingClient', existingClient);

            const existingPersons = data[this.STEP_PERFORM_CHECKS].existingPersons;
            console.log('existingPersons', existingPersons);

            if (createAction == 'new') {

                const initialData = {
                    type: 'org',
                    brreg: null,
                    name: item.companyName,
                    identity: item.identity,
                    country: 'no',
                    source: 'bankidaml'
                };
                const flow = {
                    key: 'no-org-baml',
                };
                const response = await v1lookupHelper.createClient(this.$root, initialData, null, flow);

                result.data.clientId = response.id;

            }

            if (createAction == 'archive') {

                await v1api.resetReport(existingClient._id);
                await v1api.resetForm(existingClient._id, 'kyc00');
                await v1api.resetCompany(existingClient._id);
                await v1api.resetAdverseMediaOrg(existingClient._id);

                result.data.clientId = existingClient._id;

            }

            if (createAction == 'reset') {

                await v1api.resetForm(existingClient._id, 'kyc00');
                await v1api.resetCompany(existingClient._id);
                await v1api.resetAdverseMediaOrg(existingClient._id);

                result.data.clientId = existingClient._id;
                
            }

            result.ok = true;
            return result;
        },

        async performSearch(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            const clientId = data[this.STEP_CREATE_CLIENT].clientId;
            console.log('clientId', clientId);

            const clientResponse = await v1api.getClient(clientId);
            const client = clientResponse.client;
            
            const source = 'bankidaml';
            const flow = v1flows.getFlow(this.$root, client.flow);

            const initialData = {
                brreg: null,
                trapetsData: null
            };

            if (source == 'bankidaml') {
                initialData.brreg = await amlTools.getBrregFromNumber(client.identity);
            }else if (source == 'trapets-bisnode') {
                const trapetsResult =  await dataSwedenV1.getTrapetsData(client.identity);
                initialData.trapetsData = trapetsResult.data;
            }

            const lookupResult = await v1lookupHelper.getCompanyData(this.$root, client.source, client.identity, initialData);
            await v1api.refreshCompany(client._id, lookupResult.companyData);

            const envelope = await v1receipt.createClientEnvelope(this.$root, client, lookupResult.companyData, flow, lookupResult.rawData);
            const updateData = {
                envelopeId: envelope.id,
                documentId: envelope.documentId,
                name: this.__t('search-name') + ' ' + this.companyTitleCase(lookupResult.companyData.name)
            };

            await v1api.updateClient(client._id, updateData);

            for (const i in lookupResult.reports) {
                await v1receipt.addBankIDReportToClientEnvelope(this.$root, envelope, client._id, lookupResult.reports[i]);
            }

            result.ok = true;
            return result;
        },

        async sendKyc(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            const clientId = data[this.STEP_CREATE_CLIENT].clientId;
            //const clientId = '6307a15a1480943b9c502046';
            console.log('clientId', clientId);

            const clientResponse = await v1api.getClient(clientId);

            const existingData = {
                companyData: clientResponse.client.company,
                customer: clientResponse.client,
                persons: clientResponse.persons,
                client: clientResponse.client,
                counts: clientResponse.counts,
            };
            this.$root.formData = JSON.stringify(existingData);

            const receiver = {
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
            };

            await formSender.sendForm(this.$root, 'kyc00', receiver);

            result.ok = true;
            return result;
        },

        async finalizeClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            

            await new Promise(r => setTimeout(r, 1000));

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        async loadBatch() {
            this.loading = true;
            this.items = [];

            const response = await v1api.getBatch(this.$route.query.id);
            this.batch = response.batch;

            for (const i in this.batch.clients) {
                this.items.push(this.batch.clients[i]);
            }

            this.loading = false;
        },

    },
    async created() {
        this.loadBatch();
    }
}
</script>