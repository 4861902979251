<template>
    <div class="frv2-pdf">

        <div class="frv2-pdf-header">
            <div class="frv2-pdf-title">
                {{ 'Landing Page Designer' }}
            </div>
            <div class="frv2-pdf-close">
                <vap-icon :icon="'cancel'" :color="'var(--header-text)'" action @clicked="startClose" />
            </div>
        </div>

        <div class="frv2-pdf-designer">
            <div class="frv2-pdf-left">
                <div class="frv2-pdf-menu" :style="'width: ' + width + 'px;'">



                </div>
            </div>

            <div class="frv2-pdf-right">
                <div class="frv2-pdf-scroll" @click="startClose">
                    <div class="frv2-pdf-content" :style="'opacity: ' + contentOpacity + ';'" @click.stop>


                        <div class="vpdf">
                            
                        </div>


                    </div>
                </div>
            </div>
        </div>

        <div class="frv2-pdf-bg" :style="'opacity: ' + opacity + ';'" />

    </div>
</template>

<script>
import pdfHelper from '../pdfHelper';

export default {
    name: 'LandingDesignerV2',
    props: {
        params: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            width: 0,
            opacity: '0',
            contentOpacity: '0',

            cards: [],
            config: {},
            metadata: {}
        }
    },
    methods: {
        
        startShow() {
            const self = this;
            setTimeout(function() {
                self.width = 400;
                self.opacity = '0.8';
                self.contentOpacity = '1';
            }, 10);
        },

        startClose() {
            console.log('close');
            this.width = 0;
            this.opacity = '0';
            this.contentOpacity = '0';
            const self = this;
            setTimeout(function() {
                self.$emit('close')
                self.$root.enableScrolling(true);
            }, 500);
        },

        processPages() {
            
        }

    },
    created() {
        this.$root.enableScrolling(false);
        this.startShow();
        this.processPages();
    }
}
</script>
<style scoped>

.frv2-pdf {
    position: absolute;
    top: 0px; left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 11;
}

.frv2-pdf-bg {
    position: fixed;
    top: 0px; left: 0px;
    width: 100%;
    height: 100vh;
    background-color: black;
    transition: opacity 0.5s ease-in-out;
    z-index: 12;
}


.frv2-pdf-header {
    position: absolute;
    display: flex;
    top: 0px; left: 0px;
    width: 100%;
    height: 60px;
    background-color: var(--header);
    z-index: 15;
}
.frv2-pdf-title {
    flex-grow: 100;
    font-size: 24px;
    padding-top: 15px;
    padding-left: 20px;
    color: var(--header-text);
}
.frv2-pdf-close {
    padding-top: 12px;
    padding-right: 16px;
}


.frv2-pdf-designer {
    display: flex;
    box-sizing: border-box;
}


.frv2-pdf-left {
    flex-shrink: 0;
    width: 400px;
    height: 100vh;
    z-index: 13;
}

.frv2-pdf-menu {
    min-height: 100vh;
    background-color: var(--card-background);
    transition: width 0.5s ease-in-out;
}


.frv2-pdf-right {
    position: relative;
    flex-grow: 100;
    max-height: 100vh;
    z-index: 13;
    pointer-events: none;
    box-sizing: border-box;
}

.frv2-pdf-scroll {
    overflow: auto;
    max-height: calc(100% - 60px);
    margin-top: 60px;
    padding-top: 40px;
    padding-bottom: 60px;
    padding-left: 40px;
    padding-right: 40px; 
    pointer-events: all;
    box-sizing: border-box;
}

.frv2-pdf-content {
    width: 100%;
    background-color: var(--card-background);
    transition: opacity 0.5s ease-in-out;
}




</style>