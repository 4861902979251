<template>
<div>
    <ve-popup :header="'Connect logic'" :width="500" @close="$emit('close')" >
        <div style="padding: 8px 0px;" v-if="!selectKey || keySelected">
            <div style="padding: 8px 30px;">
                {{ 'Connect to ' + params.key + ' (' + params.keyDisplay + ')' }}
            </div>
            <vap-item fancy padding20 :fancyIcon="option.icon" v-for="(option, i) in logicOptions" :key="'cl' + i"
                :fancyText="option.text"
                :fancyHint="option.hint"
                @clicked="applyLogic(option)"
            />
        </div>
        <div style="padding: 8px 0px;" v-if="selectKey && !keySelected">
            <div style="padding: 8px 30px;">
                {{ 'Select key' }}
            </div>
            <vap-item fancy padding20 :fancyIcon="option.icon" v-for="(option, i) in keyOptions" :key="'ck' + i"
                :fancyText="option.text"
                :fancyHint="option.hint"
                @clicked="selectItem(i)"
            />
        </div>
        <div v-if="logicOptions.length == 0" style="padding-bottom: 10px; padding-left: 20px;">
            {{ 'No connections possible' }}
        </div>
    </ve-popup>
</div>
</template>

<script>
import formHelper from '../formHelper';
export default {
    name: 'FormLogicDialogV2',
    props: {
        params: { type: Object, default: () => { return {} }}
    },
    data() { 
        return {
            logicOptions: [],
            keyOptions: [],
            selectKey: false,
            keySelected: false
        }
    },
    methods: {
        
        getLogicOptions() {
            const fromItem = this.params.fromItem;
            const toItem = this.params.toItem;
            this.logicOptions = [];

            console.log('fromItem', this.params.fromItem);
            console.log('toItem', this.params.toItem);

            if (toItem.type == 'opt') {
                this.params.type = 'check';
                const container = this.getContainerCheckbox(this.$parent.pages, this.params.key);
                this.params.pkey = container.key;
                this.params.keyDisplay = container.options[this.params.key].locale[this.$root.locale.key];

                this.logicOptions.push({
                    text: 'Visible if checked',
                    hint: 'Input will be visible when this option is checked',
                    type: 'visible-t',
                    icon: 'logic'
                });
                this.logicOptions.push({
                    text: 'Visible if NOT checked',
                    hint: 'Input will be hidden when this option is checked',
                    type: 'visible-f',
                    icon: 'logic'
                });

                this.selectKey = false;

            }else if (toItem.type == 'drop') {
                this.params.type = 'drop';
                this.params.pkey = toItem.key;

                if (toItem.external) {
                    for (const i in this.$parent.resources[toItem.logic.rlist.key].items) {
                        this.keyOptions.push({
                            text: this.$parent.resources[toItem.logic.rlist.key].items[i][this.$root.locale.key],
                            hint: this.$parent.resources[toItem.logic.rlist.key].items[i].value,
                            icon: 'check',
                            key: this.$parent.resources[toItem.logic.rlist.key].items[i].value
                        });
                    }
                }else {
                    console.log('sdf', toItem.items);
                    for (const i in toItem.items) {
                        this.keyOptions.push({
                            text: toItem.items[i][this.$root.locale.key],
                            hint: toItem.items[i].value,
                            icon: 'check',
                            key: toItem.items[i].value
                        });
                    }
                }

                this.logicOptions.push({
                    text: 'Visible if checked',
                    hint: 'Input will be visible when this option is checked',
                    type: 'visible-t',
                    icon: 'logic'
                });
                this.logicOptions.push({
                    text: 'Visible if NOT checked',
                    hint: 'Input will be hidden when this option is checked',
                    type: 'visible-f',
                    icon: 'logic'
                });

                this.selectKey = true;

            }else if (toItem.type == 'text' && fromItem.type == 'text') {

                this.logicOptions.push({
                    text: 'Dictionary value',
                    hint: 'Value will be set to the dictionary match',
                    type: 'dmatch',
                    icon: 'logic'
                });
                
            }else if (this.params.isResource && toItem.type == 'list') {
                this.params.type = 'rlist';
                this.params.pkey = toItem.key;
                this.params.keyDisplay = toItem.name;

                if (fromItem.type == 'drop') {
                    this.logicOptions.push({
                        text: 'Dropdown items',
                        hint: 'The dropdown will use this list for its items',
                        type: 'drop-connect',
                        icon: 'resource'
                    });
                }else if (fromItem.type == 'text') {
                    this.logicOptions.push({
                        text: 'Dictionary',
                        hint: 'The resource will be used as the dictionary for this input',
                        type: 'dictionary',
                        icon: 'resource'
                    });
                }

                this.selectKey = false;
                
            }else if (this.params.isResource && toItem.type == 'value') {
                this.params.type = 'rvalue';
                this.params.pkey = toItem.key;
                this.params.keyDisplay = toItem.name;

                if (fromItem.type == 'drop') {
                    this.logicOptions.push({
                        text: 'Input value',
                        hint: 'This value will be updated when the dropdown value changes',
                        type: 'value-connect',
                        icon: 'input-text'
                    });
                }

                this.selectKey = false;
                
            }else if (toItem.type == 'page') {
                this.params.type = 'page';
                this.params.pkey = toItem.key;
                this.params.keyDisplay = 'page';

                this.keyOptions.push({
                    text: 'Page valid',
                    hint: 'Perform logic based on if this page is validated',
                    icon: 'check',
                    key: 'pvalid'
                });

                this.logicOptions.push({
                    text: 'Visible if valid',
                    hint: 'Input will be visible when the page is validated',
                    type: 'visible-t',
                    icon: 'logic'
                });
                this.logicOptions.push({
                    text: 'Visible if NOT valid',
                    hint: 'Input will be hidden until the page is validated',
                    type: 'visible-f',
                    icon: 'logic'
                });

                this.logicOptions.push({
                    text: 'Disabled if valid',
                    hint: 'Input will be enabled when the page is validated',
                    type: 'disabled-t',
                    icon: 'logic'
                });
                this.logicOptions.push({
                    text: 'Disabled if NOT valid',
                    hint: 'Input will be disabled until the page is validated',
                    type: 'disabled-f',
                    icon: 'logic'
                });

                this.selectKey = true;
                
            }

            this.keySelected = false;
        },

        selectItem(index) {
            this.params.key = this.keyOptions[index].key;
            this.params.keyDisplay = this.keyOptions[index].text;
            this.keySelected = true;
        },

        applyLogic(option) {

            const fromItem = this.params.fromItem;
            const toItem = this.params.toItem;

            if (!fromItem.logic) fromItem.logic = {};

            if (option.type == 'visible-t' || option.type == 'visible-f') {

                if (!fromItem.logic.visible) fromItem.logic.visible = {
                    or: true, keys: []
                };
                let rev = false;
                if (option.type == 'visible-f') rev = true;

                fromItem.logic.visible.keys.push({
                    type: this.params.type, pkey: this.params.pkey, key: this.params.key, rev: rev
                });

            }else if (option.type == 'disabled-t' || option.type == 'disabled-f') {

                if (!fromItem.logic.enabled) fromItem.logic.enabled = {
                    or: true, keys: []
                };
                let rev = false;
                if (option.type == 'disabled-t') rev = true;

                fromItem.logic.enabled.keys.push({
                    type: this.params.type, pkey: this.params.pkey, key: this.params.key, rev: rev
                });

                console.log('fromItem', fromItem);

            }else if (option.type == 'drop-connect') {

                fromItem.external = true;
                this.$set(fromItem.logic, 'rlist', {
                    key: toItem.key,
                    localized: true,
                    display: 'locale',
                    list: 'locale',
                    output: 'value'
                });

            }else if (option.type == 'dictionary') {

                fromItem.external = true;
                this.$set(fromItem.logic, 'rdict', {
                    key: toItem.key,
                    global: toItem.module == 'sys'
                });

            }else if (option.type == 'dmatch') {

                if (!fromItem.logic.dmatch) fromItem.logic.dmatch = {
                    keys: []
                };
                fromItem.logic.dmatch.keys.push(toItem.key);


            }else if (option.type == 'value-connect') {

                this.$set(fromItem.logic, 'rval', {
                    key: toItem.key
                });

            }

            console.log('fromItem', fromItem);
            this.$emit('reselect');
            this.$emit('close');
        },

        getContainerCheckbox(pages, key) {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    for (const k in pages[i].cards[j].inputs) {
                        for (const l in pages[i].cards[j].inputs[k].options) {
                            if (key == pages[i].cards[j].inputs[k].options[l].key) return pages[i].cards[j].inputs[k];
                        }
                    }
                }
            }
            return null;
        },

    },
    created() {
        this.getLogicOptions();
    }
}
</script>
