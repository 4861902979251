<template>
<div>

    <RiskItem v-for="(option, i) in options" :key="'nc' + i"
        :level="1" lastLevel
        :display="option.display"
    >
        <template v-slot:risk>
            <RiskScoreControl
                :params="riskParams"
                :source="'sd'"
                :rId="option.key"
            />
        </template>
    </RiskItem>

</div>
</template>
<script>
import RiskItem from './RiskItem.vue';
import RiskScoreControl from './RiskScoreControl.vue';

export default {
    name: 'RiskAgeSettings',
    components: {
        RiskItem,
        RiskScoreControl,
    },
    props: {
        riskParams: { type: Object, default: null },
    },
    data() {
        return {
            options: []
        }
    },
    methods: {
        
        getOptions() {
            const options = [];

            options.push({
                key: 'syo10',
                display: 'Over 10 år siden'
            });
            options.push({
                key: 'syo5',
                display: 'Over 5 år siden'
            });
            options.push({
                key: 'syo3',
                display: 'Over 3 år siden'
            });
            options.push({
                key: 'syu3',
                display: 'Under 3 år siden'
            });
            options.push({
                key: 'syu1',
                display: 'Under 1 år siden'
            });
            options.push({
                key: 'smu6',
                display: 'Under 6 måneder siden'
            });
            options.push({
                key: 'smu3',
                display: 'Under 3 måneder siden'
            });

            return options;
        },

    },
    created() {
        this.options = this.getOptions();
    }
}
</script>
<style scoped>



</style>