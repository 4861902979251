<template>
<div class="vapli-base" style="position: relative;">
<router-link :to="(noLink || locked) ? $route.path : link">
    <div class="vapcli-wrap" @click="handleClick" :class="[
        {'vapcli-new' : newItem},
        {'vapcli-thick' : newItem},
        {'vapcli-locked' : locked},
        {'vapcli-disabled' : disabled || locked},
        {'vapcli-new-warning' : newItem && newWarning},
        {'vapcli-new-error' : newItem && newError},
    ]">
        <div class="vapcli">

            <div class="vapcli-icon">
                <vap-icon :icon="icon" :color="'var(--disabled-background)'" />
            </div>

            <div class="vapcli-left">
                <div class="vapcli-name">
                    <label>{{ name }}</label>
                </div>
                <div class="vapcli-info" v-if="!projects">
                    <div class="vapcli-status" v-if="hasStatus" :style="'border-color: ' + statusColor + '; background-color: ' + (completed ? statusColor : 'transparent') + ';'" />
                    <div class="vapcli-info1">
                        <label>{{ info }}</label>
                    </div>
                </div>
                <div v-if="projects">
                    <slot />
                </div>
            </div>

            <div class="vapcli-lock" v-if="locked">
                <vap-icon :icon="'locked'" :color="'ext'" />
            </div>

            <div class="vapcli-tags" v-if="tags">
                <slot name="tags" />
            </div>

            <div class="vapcli-right">
                <div class="vapcli-date">
                    {{ date }}
                </div>
                <div class="vapcli-user">
                    {{ user }}
                </div>
            </div>

        </div>

        <div class="vapcli-bar" />
        <div class="vapcli-new-overlay" v-if="newItem">
            <label>{{ __('common_new').toUpperCase() }}</label>
        </div>

    </div>
</router-link>
<div class="vapli-new-button" @click.stop="handleNewClick" v-if="newItem" :class="[
    {'vapcli-newb-warning': newItem && newWarning },
    {'vapcli-newb-error': newItem && newError },
]">
    <vap-icon :icon="'check'" :color="'white'" />
</div>
</div>
</template>

<script>
export default {
    name: 'ClientItem',
    props: {
        icon: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        info: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        user: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: '/'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        newItem: {
            type: Boolean,
            default: false
        },
        noLink: {
            type: Boolean,
            default: false
        },
        hasStatus: {
            type: Boolean,
            default: false
        },
        statusColor: {
            type: String,
            default: 'var(--disabled-background)'
        },
        completed: {
            type: Boolean,
            default: false
        },
        locked: {
            type: Boolean,
            default: false
        },
        projects: {
            type: Boolean,
            default: false
        },
        tags: {
            type: Boolean,
            default: false
        },
        newWarning: {
            type: Boolean,
            default: false
        },
        newError: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            
        }
    },
    computed: {
        
    },
    watch: {
        
    },
    methods: {

        handleClick() {
            if (this.disabled || this.locked) return;
            this.$emit("clicked");
        },

        handleNewClick() {
            this.$emit('ack');
        },
        
    },
    async mounted() {}
}
</script>
<style scoped>

.vapcli-wrap {
    position: relative;
    padding: 1px 0px;
    padding-right: 1px;
    background: var(--card-background);
    border-radius: 3px;
}
.vapcli-new {
    background: linear-gradient(to right, var(--success-background), var(--card-background));
}
.vapcli-new-error {
    background: linear-gradient(to right, var(--error-background), var(--card-background));
}
.vapcli-thick {
    padding: 3px 0px;
    padding-right: 3px;
}

.vapcli {
    position: relative;
    display: flex;
    border-radius: 3px;
    background: var(--card-background);
    box-sizing: border-box;
}

.vapcli-wrap:hover {
    background: var(--card-hover-border);
}
.vapcli-new:hover {
    background: var(--success-background);
}
.vapcli-new-error:hover {
    background: var(--error-background);
}
.vapcli-wrap:hover .vapcli {
    background-color: var(--card-hover);
}

.vapcli-disabled.vapcli-wrap:hover {
    background: var(--card-background);
}
.vapcli-disabled.vapcli-wrap:hover .vapcli {
    background-color: var(--card-background);
}

.vapcli label {
    cursor: pointer;
}
a:link {
    text-decoration: none;
}

.vapcli-disabled {
    cursor: initial;
}
.vapcli-disabled label {
    cursor: initial;
}

.vapcli-bar {
    position: absolute;
    width: 4px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: var(--card-background);
}
.vapcli-thick .vapcli-bar {
    width: 6px;
}
.vapcli-wrap:hover .vapcli-bar {
    background-color: var(--success-background);
}
.vapcli-new .vapcli-bar {
    background-color: var(--success-background);
}
.vapcli-new-error .vapcli-bar {
    background-color: var(--error-background);
}
.vapcli-new-error:hover .vapcli-bar {
    background-color: var(--error-background);
}
.vapcli-disabled .vapcli-bar {
    width: 0px;
}

.vapcli-new-overlay {
    position: absolute;
    top: 1px; left: 6px;
    width: 28px;
    padding-left: 10px;
    padding-right: 15px;
    padding-bottom: 2px;
    text-align: center;
    border-bottom-right-radius: 6px;
    background-color: var(--success-background);
}
.vapcli-new-overlay label {
    font-size: 11px;
    font-weight: 500;
    color: var(--accent-contrast);
    cursor: pointer;
}

.vapcli-new-error .vapcli-new-overlay {
    background-color: var(--error-background);
}

.vapli-new-button {
    display: none;
    position: absolute;
    top: 0px; right: 0px;
    bottom: 0px; width: 100px;
    padding-top: 20px;
    padding-left: 30px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: var(--success-background);
    box-sizing: border-box;
    cursor: pointer;
    opacity: 0.9;
}
.vapli-new-button:hover {
    opacity: 1;
}
.vapli-base:hover .vapli-new-button {
    display: initial;
}
.vapli-base:hover .vapcli-new-error {
    background: var(--error-background);
}

.vapcli-newb-error {
    background-color: var(--error-background);
}




.vapcli-icon {
    width: 55px;
    padding-left: 18px;
    padding-top: 18px;
}
.vapcli-new .vapcli-icon {
    padding-top: 20px;
}
.vapcli-left {
    flex-grow: 100;
    padding: 15px 0px;
}

.vapcli-lock {
    position: absolute;
    top: 20px; right: 300px;
    fill: var(--val-error);
}

.vapcli-tags {
    position: absolute;
    top: 30px; right: 200px;
}

.vapcli-right {
    padding: 15px 0px;
    padding-right: 15px;
    text-align: right;
}

.vapcli-name {
    color: var(--text-title);
    font-weight: 500;
}
.vapcli-info {
    display: flex;
    margin-top: 5px;
    color: var(--text-weak);
    box-sizing: border-box;
}

.vapcli-status {
    flex-shrink: 0;
    width: 17px; height: 17px;
    margin-right: 8px;
    border-radius: 50%;
    border: solid 2px var(--disabled-background);
    box-sizing: border-box;
}

.vapcli-date {
    color: var(--text-title);
    font-weight: 500;
}
.vapcli-user {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 3px;
    color: var(--text-weak);
}

.vapcli-locked .vapcli-name {
    color: var(--text-disabled);
}
.vapcli-locked .vapcli-date {
    color: var(--text-weak);
}
.vapcli-locked .vapcli-info {
    color: var(--text-disabled);
}

</style>