<template>
<div class="vfdv2-item">

    <div class="vfdv2-item-icon">
        <vap-icon small :icon="icon" absolute :top="0" :right="0" :color="'var(--text-disabled)'" />
    </div>

    <div class="vfdv2-item-text">
        <span>{{ text }}</span>
        <span v-if="subtext" style="margin-left: 5px; color: var(--text-weak);">{{ '(' + subtext + ')' }}</span>
    </div>
    
</div>
</template>
<script>

export default {
    name: 'DialogContentItem',
    props: {
        icon: { type: String, default: 'help-o' },
        text: { type: String, default: 'ITEM' },
        subtext: { type: String, default: '' },
    }
}
</script>
<style scoped>

.vfdv2-item {
    display: flex;
    margin-bottom: 2px;
}

.vfdv2-item-icon {
    flex-shrink: 0;
    position: relative;
    width: 20px;
}

.vfdv2-item-text {
    margin-top: 4px;
    margin-left: 4px;
    color: var(--text-title);
}

</style>