import kychelper from '@/helpers/kychelper';
import verified from '@/verified';
import log from '@/log';
const shareapp = {

    getEnvelopeObject() {
        return {
            completed: false, aborted: false, signed: false, expired: false,
            editor: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
            aborter: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
            nextSigner: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
            signatories: [],
            signedNames: '',
            expirationDate: undefined,
            envelopeId: '', documentId: '', fileUid: '',
            editorMadeChanges: false,
        };
    },

    getEnvelopeData(envelope, output) {

        console.log('envelope', envelope);

        let editorCompleted = false;

        // Loop the recipients
        for (let i in envelope.content.recipients) {

            // Process notifications for this recipient
            const result = kychelper.processNotifications(envelope.content.recipients[i].notifications);

            // Check if this is the editor or a signatory
            if (envelope.content.recipients[i].role.action == 'edit') {

                // Set the editor object
                output.editor = {
                    id: envelope.content.recipients[i].id,
                    firstName: envelope.content.recipients[i].givenName,
                    lastName: envelope.content.recipients[i].familyName,
                    email: envelope.content.recipients[i].email,
                    displayName: envelope.content.recipients[i].givenName + ' ' + envelope.content.recipients[i].familyName,
                    completed: envelope.content.recipients[i].completed,
                    events: result.events,
                    lastEvent: result.lastEvent
                }
                editorCompleted = envelope.content.recipients[i].completed;

            }else if (envelope.content.recipients[i].role.action == 'sign') {

                // Push to signatory list
                output.signatories.push({
                    id: envelope.content.recipients[i].id,
                    firstName: envelope.content.recipients[i].givenName,
                    lastName: envelope.content.recipients[i].familyName,
                    confirmedFirstName: '',
                    confirmedLastName: '',
                    confirmedName: '',
                    displayName: envelope.content.recipients[i].givenName + ' ' + envelope.content.recipients[i].familyName,
                    status: 'unknown',
                    method: envelope.content.recipients[i].signingMethod,
                    date: new Date(),
                    email: envelope.content.recipients[i].email,
                    events: result.events
                });
            }
        }

        if (editorCompleted) {
            output.completed = true;
            
            for (const s in envelope.content.documents[0].signatures) {
                const rid = envelope.content.documents[0].signatures[s].recipient.uid.split('recipients/')[1];
                
                for (const x in output.signatories) {
                    if (output.signatories[x].id == rid) {
                        output.signatories[x].status = envelope.content.documents[0].signatures[s].status;
                        output.signatories[x].method = envelope.content.documents[0].signatures[s].origin;
                        output.signatories[x].date = new Date(envelope.content.documents[0].signatures[s].date);
                        output.signatories[x].confirmedFirstName = envelope.content.documents[0].signatures[s].givenName;
                        output.signatories[x].confirmedLastName = envelope.content.documents[0].signatures[s].familyName;
                        output.signatories[x].displayName = output.signatories[x].confirmedFirstName + ' ' + output.signatories[x].confirmedLastName;
                        output.signatories[x].confirmedName = output.signatories[x].confirmedFirstName + ' ' + output.signatories[x].confirmedLastName;
                    }
                }
                if (envelope.content.documents[0].signatures[s].status == 'rejected') {
                    output.aborted = true;
                    output.aborter = {
                        id: rid,
                        firstName: envelope.content.documents[0].signatures[s].givenName,
                        lastName: envelope.content.documents[0].signatures[s].familyName,
                        email: envelope.content.documents[0].signatures[s].email,
                        displayName: envelope.content.documents[0].signatures[s].givenName + ' ' + envelope.content.documents[0].signatures[s].familyName,
                        comment: envelope.content.documents[0].signatures[s].comment
                    }
                }
            }

            if (envelope.content.completed) {
                if (!output.aborted) output.signed = true;
                output.signedNames = '';
                for (let x in output.signatories) {
                    if (output.signatories[x].status == 'signed') {
                        if (x > 0) output.signedNames = output.signedNames + ', ';
                        output.signedNames = output.signedNames + output.signatories[x].displayName;
                    }
                }
            }else {

                for (let x in output.signatories) {
                    if (output.signatories[x].status == 'unknown') {
                        output.nextSigner = {
                            id: output.signatories[x].id,
                            firstName: output.signatories[x].firstName,
                            lastName: output.signatories[x].lastName,
                            email: output.signatories[x].email,
                            displayName: output.signatories[x].displayName,
                            method: output.signatories[x].method,
                        }
                        break;
                    }
                }

            }
        }

        // Check expiration date
        if (envelope.content.expired) {
            output.expired = true;
        }
        output.expirationDate = new Date(envelope.content.expiration);


        // Get PDF uid
        if (envelope.content.documents[0].files) {
            if (envelope.content.documents[0].files.document) {
                output.fileUid = envelope.content.documents[0].files.document.uid;
            }
            if (envelope.content.documents[0].files.signedDocument) {
                output.fileUid = envelope.content.documents[0].files.signedDocument.uid;
            }
        }
        output.envelopeId = envelope.id;
        output.documentId = envelope.documentId;

        output.editorIsNextSigner = output.editor.email == output.nextSigner.email;

    },

    getSteps(editor) {
        const steps = [];

        if (!editor) {
            steps.push({ title: 'shareapp_step_upload', key: 'upload', nextDisabled: true, backDisabled: true, message: 'shareapp_error_upload' });
            steps.push({ title: 'shareapp_step_recipient', key: 'recipient', nextDisabled: true, nextText: 'shareapp_send_out', backDisabled: false, message: 'shareapp_error_recipient' });
        }else {
            /* steps.push({ title: 'shareapp_step_welcome', key: 'welcome', nextDisabled: true, backDisabled: true, message: 'shareapp_error_downloading' }); */
            steps.push({ title: 'shareapp_step_preview', key: 'preview', nextDisabled: false, backDisabled: true });
            steps.push({ title: 'shareapp_step_form', key: 'appform', nextDisabled: true, backDisabled: false, message: 'shareapp_error_fill_out' });
            steps.push({ title: 'shareapp_step_checkout', key: 'checkout', nextDisabled: true, nextText: 'shareapp_sign', backDisabled: false, message: 'shareapp_error_signer' });
        }

        return steps;
    },

    getHelpTexts() {
        return {
            owner_upload: {
                head: 'shareapp_help_o_upload_head', desc: 'shareapp_help_o_upload_desc',
                items: [
                    { icon: 'file-sent', key: 'shareapp_help_o_upload_1' }
                ]
            },
            owner_recipient: {
                head: 'shareapp_help_o_recipient_head', desc: 'shareapp_help_o_recipient_desc',
                items: [
                    { icon: 'edit', key: 'shareapp_help_o_recipient_1' }
                ]
            },
            editor_preview: {
                head: 'shareapp_help_e_preview_head', desc: 'shareapp_help_e_preview_desc',
                items: [
                    { icon: 'file-sent', key: 'shareapp_help_e_preview_1' }
                ]
            },
            editor_form: {
                head: 'shareapp_help_e_form_head', desc: 'shareapp_help_e_form_desc',
                items: [
                    { icon: 'edit', key: 'shareapp_help_e_form_1' },
                ]
            },
            editor_checkout: {
                head: 'shareapp_help_e_checkout_head', desc: 'shareapp_help_e_checkout_desc',
                items: [
                    { icon: 'sign', key: 'shareapp_help_e_form_2' }
                ]
            },
        };
    },

    async downloadAttachments(callback, envelope) {
        if (!envelope.userdata.attachments) return;
        log.v('ShareApp: Downloading ' + envelope.userdata.attachments.length + ' attachments', envelope.userdata.attachments);
        for (const i in envelope.userdata.attachments) {
            let match = false;
            for (const j in envelope.content.documents[0].files) {
                for (const k in envelope.content.documents[0].files[j]) {
                    if (envelope.content.documents[0].files[j][k].name == envelope.userdata.attachments[i].name) {
                        match = true;
                        try {
                            const url = await verified.getDownloadUrl(envelope.id, envelope.content.documents[0].id, envelope.content.documents[0].files[j][k].uid);
                            const data = await verified.downloadFile(url);
                            callback.onAttachmentDownloaded(envelope.userdata.attachments[i], data);
                            log.s('ShareApp: Attachment (' + envelope.userdata.attachments[i].type + ') ' + envelope.userdata.attachments[i].name + ' downloaded');
                        }catch (ex) {
                            log.e('ShareApp: Could not download attachment', ex);
                        }
                    }
                }
            }
            if (!match) log.e('ShareApp: Attachment does not exist in envelope', envelope.userdata.attachments[i]);
        }
    },

};
export default shareapp;