import v1api from '../v1helpers/v1api';
const mapNoApi = {

    functionUrl: process.env.VUE_APP_API_BASE_URL + '/v1backoffice',

    //#region Queries -----------------------------------------------------------------------------------------------------

    async queryStats(filters, from, limit, sort, include, counts)  {
        const url = `query/stats?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.get(url + query);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------
    
    async getCompany(orgnr) {
        return await this.get('mapno/' + orgnr);
    },

    //#region Add Data ----------------------------------------------------------------------------------------------------

    async addData(list) {
        const data = {
            list: list,
        };
        return await this.post('mapno', data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Request / Response ------------------------------------------------------------------------------------------

    async get(route) {
        return v1api.get(route, this.functionUrl);
    },

    async post(route, data) {
        return v1api.post(route, data, this.functionUrl);
    },

    async put(route, data) {
        return v1api.put(route, data, this.functionUrl);
    },

    async delete(route) {
        return v1api.delete(route, this.functionUrl);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default mapNoApi;