<template>
    <div class="veas" :class="disabled ? 'veas-disabled' : ''">

        <div class="veas-base" 
            :class="[
                combineLeft ? 'veas-left' : '',
                combineRight ? 'veas-right' : '',
                combineTop ? 'veas-top' : '',
                combineBottom ? 'veas-bottom' : '',
                showingHelp ? 'veas-sh' : ''
            ]">

            <VeSwitch v-model="checked" :text="text" style="margin-top: 14px; margin-left: 16px;" :disabled="disabled || switchDisabled" />

            <vap-dropdown compact noValidation v-if="selectOptions" style="position: absolute; top: 14px; left: 180px; width: 120px;"
                v-model="svalue"
                :options="selectOptions"
                @input="handleSelect"
            ></vap-dropdown>

            <div class="veas-help">
                <vap-icon :icon="'help-o'">
                    <template v-slot:tooltip><vap-tooltip v-if="tooltip" :text="tooltip" /></template>
                </vap-icon>
            </div>

            <div v-if="content" style="position: relative; float: left; width: 100%; height: 155px; padding-top: 13px; padding-left: 10px; padding-right: 10px; box-sizing: border-box;">

                <div style="float: left; width: 100%; border-top: solid 1px var(--input-border);">
                    <slot></slot>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import VeSwitch from '../basic/VeSwitch'

export default {
    name: 've-actionswitch',
    components: {
        VeSwitch
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: '(action switch)'
        },
        combineLeft: {
            type: Boolean,
            default: false
        },
        combineRight: {
            type: Boolean,
            default: false
        },
        combineTop: {
            type: Boolean,
            default: false
        },
        combineBottom: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        switchDisabled: {
            type: Boolean,
            default: false
        },
        showingHelp: {
            type: Boolean,
            default: false
        },
        selectOptions: {
            type: Array,
            default: undefined
        },
        selectValue: {
            type: String,
            default: ''
        },
        tooltip: {
            type: String,
            default: ''
        },
        content: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            checked: false,
            svalue: '',
            helpClicked: false
        }
    },
    computed: {
        
    },
    watch: {
        checked: {
            deep: true,
            handler(val) {
                this.$emit('input', val);
                this.$emit('changed', val);
            }
        },
    },
    methods: {
        handleSelect(value) {
            this.svalue = value;
            this.$emit('select', this.svalue);
        }
    },
    async mounted() {
        this.checked = this.value;
        this.svalue = this.selectValue;
    }
}
</script>
<style scoped>

.veas {
    width: 100%;
}

.veas-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.veas-disabled .veas-help {
    cursor: not-allowed;
}

.veas-base {
    width: 100%;
    position: relative;
    float: left;
    min-height: 60px;
    background-color: var(--action-background);
    border: solid 1px var(--action-border);
    border-radius: 5px;
    box-sizing: border-box;
}

.veas-left {
    width: 50%;
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.veas-right {
    width: 50%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.veas-top {
    width: 100%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.veas-bottom {
    width: 100%;
    border-bottom: none;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.veas-sh {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.veas-help {
    position: absolute;
    top: 12px;
    right: 10px;
}

path {
    fill: #cccccc;
}

</style>