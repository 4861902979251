<template>
<div class="bostat-acc">

    <div class="bostat-heads">
        <div class="bostat-head-desc">
            {{ 'Month' }}
        </div>
        <div class="bostat-head" v-for="(column, i) in columns" :key="'sc' + i" :style="widths[i]">
            {{ column.display }}
        </div>
    </div>

    <div class="bostat-items">
        <div class="bostat-item" v-for="(month, j) in months" :key="'sm' + j">
            <div class="bostat-item-desc">
                {{ month.key }}
            </div>
            <div class="bostat-item-col" v-for="(column, i) in columns" :key="'sc' + i + j" :style="widths[i]">
                {{ month[column.key] }}
            </div>
        </div>
    </div>

</div>
</template>
<script>

export default {
    name: 'BoStatCompany',
    components: {
        
    },
    props: {
        stats: { type: Array, default: () => { return [] } },
    },
    data() {
        return {
            columns: [
                { key: 'org', display: 'Company lookups', width: 10 },
                { key: 'scr', display: 'Person screenings', width: 10 },
                { key: 'idc', display: 'ID-controls', width: 10 },
                { key: 'kyc', display: 'KYC-forms', width: 10 },
                { key: 'kar', display: 'KAR lookups', width: 10 },
                { key: 'frm', display: 'Custom forms', width: 10 },
            ],
            widths: [],
            months: []
        }
    },
    methods: {
        
        calculateWidths(columns) {
            let total = 0;
            for (const i in columns) total += Number(columns[i].width);
            const fac = 80 / total;
            this.widths = [];
            for (const i in columns) {
                this.widths.push('width: ' + (Number(columns[i].width * fac)) + '%;');
            }
        },

    },
    created() {
        for (const i in this.stats) {
            const mKey = this.stats[i].month + '-' + this.stats[i].year;
            let match = false;
            for (const j in this.months) {
                if (this.months[j].key == mKey) {
                    this.months[j][this.stats[i].type] += 1;
                    match = true;
                }
            }
            if (!match) {
                const month = { key: mKey, org: 0, scr: 0, idc: 0, kyc: 0, kar: 0, frm: 0 };
                month[this.stats[i].type] += 1;
                this.months.push(month);
            }
        }

        this.calculateWidths(this.columns);
    }
}
</script>
<style scoped>

.bostat-acc {
    float: left;
    width: 100%;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
}

.bostat-heads {
    display: table;
    width: 100%;
    border-bottom: solid 1px var(--input-border);
}

.bostat-head-desc {
    width: 20%;
    display: table-cell;
    padding: 0px 8px;
    vertical-align: middle;
    box-sizing: border-box;
}

.bostat-head {
    height: 46px;
    display: table-cell;
    padding: 0px 8px;
    font-size: 14px;
    vertical-align: middle;
    border-left: solid 1px var(--input-border);
    box-sizing: border-box;
}

.bostat-items {
    width: 100%;
}

.bostat-item {
    display: table;
    width: 100%;
}

.bostat-item-desc {
    display: table-cell;
    width: 20%;
    padding: 4px 8px;
    box-sizing: border-box;
}

.bostat-item-col {
    display: table-cell;
    padding: 4px 8px;
    font-size: 17px;
    font-weight: 500;
    text-align: right;
    border-left: solid 1px var(--input-border);
    box-sizing: border-box;
}

</style>