import api from './portalapi';
import v2lib from './v2lib';
import log from './log';
const filestore = {

    envelopeCache: [],
    batchCache: [],

    async storeFiles(root, files, envelopeName, envelopeTags) {
        // Prepare the result object
        const result = { success: false, exceptions: [] };
        log.v('Filestore: Storing files in archive', files);
        try {
            // First create an envelope to make sure Verified Platform is up and running
            const envelope = await v2lib.createEnvelope('aml-portal-file', 'no-recipients-flow');
            if (v2lib.wasCreated(envelope)) {
                // Envelope successfully created, proceed with report
                log.s('Filestore: Envelope successfully created', envelope);

                const attachments = [];
                for (const i in files.files) {
                    if (!files.files[i].uploaded) {
                        attachments.push(v2lib.addFileUploadAttachmentToUserdataSafe(attachments.length, files.files[i], 'filestore'));
                        await v2lib.addAttachment(envelope, files.files[i].name, files.files[i].data);
                    }
                }

                const userdata = {
                    metadata: {
                        date: new Date(),
                        userId: root.credentials.uid,
                        userName: root.getEmployeeName(root.credentials.uid)
                    },
                    templateId: 'file',
                    templatePath: process.env.VUE_APP_BASE_URL + 'templates/',
                    attachments: attachments,
                    css: 'template.css',
                    html: 'template.html',
                    fileName: envelopeName
                };

                console.log(JSON.stringify(userdata));
                const template = await api.renderTemplate(root, 'file', userdata);
                userdata.css = template.css;
                userdata.html = template.html;

                // Complete the envelope
                await v2lib.setUserData(envelope, userdata);
                await v2lib.setTags(envelope, envelopeTags);
                await v2lib.setName(envelope, envelopeName);
                await v2lib.publishEnvelope(envelope);
                log.s('Filestore: Envelope published');

                result.envelopeId = envelope.id;
                result.success = true;
            }else {
                // Error creating envelope, show error to the user
                result.exceptions.push(v2lib.getError(envelope));
                /* if (!inBackground) root.exceptions.push(v2lib.getError(envelope)); */
            }
        }catch (ex) {
            console.log(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'Filestore'
                };
            }catch (exx) {}
            result.exceptions.push(ex);
            /* if (!inBackground) root.exceptions.push(ex); */
        }
        return result;
    },



};
export default filestore;