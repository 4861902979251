<template>
    <div class="aml-page">

        <vap-form-divider :left="50" :right="50">
            <template v-slot:left>
                <vap-label :text="'Authorized companies'" header style="margin-bottom: 5px;" />
                <vap-card compactList noPadding v-for="(company, i) in prodCompanies" :key="'p' + i">
                    <vap-item padding20>
                        <div class="admin-com">
                            <div class="admin-com-logo">
                                <img :src="company.config.logo" />
                            </div>
                            <div class="admin-com-left">
                                <div class="admin-com-name">
                                    <label>{{ company.name }}</label>
                                </div>
                                <div class="admin-com-info">
                                    <label>{{ company.companyId }}</label>
                                    <label>{{ company.key }}</label>
                                    <label>{{ company.config.host }}</label>
                                </div>
                            </div>
                            <div class="admin-com-icon">
                                <img :src="company.config.favicon" />
                            </div>
                            <div class="admin-com-color" :style="'background-color: ' + company.colors.primary + ';'" />
                        </div>
                    </vap-item>
                    <div class="admin-com-actions">
                        <vap-icon :icon="'delete'" action @clicked="deleteCompany(company)"><template v-slot:tooltip><vap-tooltip :text="'Delete company'" /></template></vap-icon>
                        <vap-icon :icon="'copy'" action @clicked="copyConfig(company)"><template v-slot:tooltip><vap-tooltip :text="'Copy config'" /></template></vap-icon>
                        <vap-icon :icon="'paste'" action @clicked="pasteConfig(company)"><template v-slot:tooltip><vap-tooltip :text="'Paste config'" /></template></vap-icon>
                    </div>
                </vap-card>
                <vap-button-row alignLeft style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="'Add company'" uppercase @clicked="showAddProdPopup" />
                </vap-button-row>
            </template>
            <template v-slot:right>
                <vap-label :text="'Demo companies'" header style="margin-bottom: 5px;" />
                <vap-card v-for="(company, i) in demoCompanies" :key="'d' + i">
                    
                </vap-card>
                <vap-button-row alignLeft style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="'Add demo company'" uppercase @clicked="showAddDemoPopup" />
                </vap-button-row>
            </template>
        </vap-form-divider>

        <ve-popup v-if="addProdPopup.show" :header="'Add company'" :width="500" @close="addProdPopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c12>
                            <vap-label desc :text="__('common_name')" />
                            <vap-input v-model="addProdPopup.name" :rules="'required'" @input="validateProdPopup" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="'Database key'" />
                            <vap-input v-model="addProdPopup.key" :rules="'required'" @input="validateProdPopup" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="'CompanyId'" />
                            <vap-input v-model="addProdPopup.id" :rules="'required'" @input="validateProdPopup" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="addProdPopup.show = false" :disabled="addProdPopup.working" />
                    <vap-button :text="__('common_add')" uppercase @clicked="onAddProd" :disabled="!addProdPopup.valid" :working="addProdPopup.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="pasteConfigPopup.show" :header="'Change configuration?'" :width="400" @close="pasteConfigPopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>{{ 'Config will be copied from ' + pasteConfigPopup.fromCompany.name + ' to ' + pasteConfigPopup.toCompany.name }}</label><br><br>
                <label>Are you sure you want to do this?</label>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_no')" uppercase @clicked="pasteConfigPopup.show = false" />
                    <vap-button :text="__('common_yes')" uppercase @clicked="performConfigPaste" :working="pasteConfigPopup.working" :disabled="pasteConfigPopup.fromCompany.name == 'ERROR' || pasteConfigPopup.toCompany.name == 'ERROR'" />
                </vap-button-row>
            </div>
        </ve-popup>

    </div>
</template>

<script>
import backend from '../../backend';
import api from '../../portalapi';

export default {
    name: 'ConfigTools',
    data() {
        return {
            prodCompanies: [],
            demoCompanies: [],
            addProdPopup: { show: false, valid: false, working: false, name: '', key: '', id: '' },
            addDemoPopup: { show: false, valid: false, working: false, name: '', key: '', id: '' },
            pasteConfigPopup: { show: false, valid: false, working: false, fromCompany: { name: 'ERROR' }, toCompany: { name: 'ERROR' } },
        }
    },
    methods: {

        // ----- Add Popups ----------------------------------------------------------------------------

        showAddProdPopup() {
            this.addProdPopup.show = true;
        },
        validateProdPopup() {
            let valid = true;
            if (this.addProdPopup.name.length < 3) valid = false;
            if (this.addProdPopup.key.length < 3) valid = false;
            if (this.addProdPopup.id.length < 8) valid = false;
            this.addProdPopup.valid = valid;
        },
        async onAddProd() {
            this.addProdPopup.working = true;
            const company = {
                type: 'prod',
                name: this.addProdPopup.name,
                key: this.addProdPopup.key,
                id: this.addProdPopup.id,
            };
            await backend.adminAddCompany(company);
            await this.loadCompanies();
            this.addProdPopup.working = false;
            this.addProdPopup.show = false;
        },
        showAddDemoPopup() {
            this.addDemoPopup.show = true;
        },
        validateDemoPopup() {

        },
        async onAddDemo() {

        },

        // ---------------------------------------------------------------------------------------------

        // ----- Copy/Paste Popups ---------------------------------------------------------------------

        copyConfig(company) {
            console.log('config for ' + company.name + ' copied', company);
            this.pasteConfigPopup.fromCompany = company;
        },

        pasteConfig(company) {
            this.pasteConfigPopup.toCompany = company;
            this.pasteConfigPopup.show = true;
        },

        async performConfigPaste() {
            this.pasteConfigPopup.working = true;
            console.log('pasting to ' + this.pasteConfigPopup.toCompany.name, this.pasteConfigPopup.toCompany);

            await backend.adminCopyConfig(this.pasteConfigPopup.fromCompany, this.pasteConfigPopup.toCompany);

            this.pasteConfigPopup.working = false;
            this.pasteConfigPopup.show = false;
        },

        deleteCompany() {

        },

        // ---------------------------------------------------------------------------------------------

        async loadCompanies() {
            const response = await backend.adminGetCompanies();
            this.prodCompanies = [];
            this.demoCompanies = [];
            for (const i in response.data.companies) {
                if (response.data.companies[i].type == 'demo') {
                    this.demoCompanies.push(response.data.companies[i]);
                }else {
                    this.prodCompanies.push(response.data.companies[i]);
                }
            }
        },

    },
    async created() {
        this.loadCompanies();
    }
}
</script>
<style scoped>

.admin-com {
    display: flex;
    padding-right: 140px;
}
.admin-com-logo {
    flex-shrink: 0;
}
.admin-com-logo img {
    width: 50px;
}
.admin-com-left {
    flex-grow: 100;
    padding-top: 3px;
    padding-left: 15px;
}
.admin-com-icon {
    flex-shrink: 0;
    width: 20px;
}
.admin-com-icon img {
    flex-shrink: 0;
    margin-top: 12px;
    width: 20px;
}
.admin-com-color {
    flex-shrink: 0;
    width: 20px; height: 20px;
    margin-top: 12px;
    margin-left: 20px;
}
.admin-com-name {
    font-size: 18px;
    color: var(--text-strong);
}
.admin-com-info {
    margin-top: 2px;
    font-size: 15px;
    color: var(--text-weak);
}
.admin-com-info label {
    margin-right: 20px;
}
.admin-com-actions {
    position: absolute;
    display: flex;
    top: 14px; right: 10px;
}

</style>