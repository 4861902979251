<template>
<div>
    
    <vap-card v-if="customer.source == 'brreg'" list :header="__('company_brreg_header')">
        <CompanyInputs v-model="brregData" :editMode="false" />
    </vap-card>

    <vap-card list :header="__('econ_key_info')">

        <vap-row>
            <vap-group c6 v-if="economy.accountant">
                <vap-label :text="__('econ_accountant')" desc />
                <vap-label :text="economy.accountant.name" value />
            </vap-group>
            <vap-group c6 v-if="economy.auditor">
                <vap-label :text="__('econ_auditor')" desc />
                <vap-label :text="economy.auditor.name" value />
            </vap-group>
        </vap-row>

    </vap-card>

    <vap-card v-if="economyYears.length > 0">
        <vap-label :text="__('econ_accounting_years')" value />
        <vap-button-group v-model="economyTabs" style="margin-top: 10px;"
            :options="economyOptions">
        </vap-button-group>
        <vap-form-divider :left="50" :right="50" v-if="economyYears.length > 0" style="margin-top: 10px;">
            <template v-slot:left>
                <vap-label :text="__('econ_income_statement')" value style="float: left; width: 100%; margin-bottom: 5px;" />
                <div style="padding-left: 10px; padding-right: 50px;">
                    <vap-number-item :text="__('econ_sales_revenues')" :number="economyYears[economyIndex].incomeStatement.salesRevenues" />
                    <vap-number-item :text="__('econ_other_revenues')" :number="economyYears[economyIndex].incomeStatement.otherRevenues" />
                    <vap-number-item :text="__('econ_total_revenues')" bold :number="economyYears[economyIndex].incomeStatement.totalRevenues" />
                    <vap-number-item :text="__('econ_cost_of_goods_sold')" :number="economyYears[economyIndex].incomeStatement.costOfGoodsSold" />
                    <vap-number-item :text="__('econ_inventory_changes')" :number="economyYears[economyIndex].incomeStatement.inventoryChanges" />
                    <vap-number-item :text="__('econ_salary_costs')" :number="economyYears[economyIndex].incomeStatement.salaryCosts" />
                    <vap-number-item :text="__('econ_financial_costs')" :number="economyYears[economyIndex].incomeStatement.financialCosts" />
                    <vap-number-item :text="__('econ_depreciations')" :number="economyYears[economyIndex].incomeStatement.depreciations" />
                    <vap-number-item :text="__('econ_amortizations')" :number="economyYears[economyIndex].incomeStatement.amortizations" />
                    <vap-number-item :text="__('econ_other_operating_costs')" :number="economyYears[economyIndex].incomeStatement.otherOperatingCosts" />
                    <vap-number-item :text="__('econ_operating_profits')" bold :number="economyYears[economyIndex].incomeStatement.operatingProfits" />
                    <vap-number-item :text="__('econ_ordinary_profits')" :number="economyYears[economyIndex].incomeStatement.ordinaryProfits" />
                    <vap-number-item :text="__('econ_extraordinary_revenues')" :number="economyYears[economyIndex].incomeStatement.extraordinaryRevenues" />
                    <vap-number-item :text="__('econ_extraordinary_costs')" :number="economyYears[economyIndex].incomeStatement.extraordinaryCosts" />
                    <vap-number-item :text="__('econ_minority_interests')" :number="economyYears[economyIndex].incomeStatement.minorityInterests" />
                    <vap-number-item :text="__('econ_taxes')" :number="economyYears[economyIndex].incomeStatement.taxes" />
                    <vap-number-item :text="__('econ_result_before_tax')" :number="economyYears[economyIndex].incomeStatement.resultBeforeTax" />
                    <vap-number-item :text="__('econ_net_profits')" bold :number="economyYears[economyIndex].incomeStatement.netProfits" />
                </div>
            </template>
            <template v-slot:right>
                <vap-label :text="__('econ_balance_sheet')" value style="float: left; width: 100%; margin-bottom: 5px;" />
                <div style="padding-left: 10px; padding-right: 50px;" v-if="economyYears[economyIndex].balanceSheet">
                    <vap-number-item :text="__('econ_current_assets')" :number="economyYears[economyIndex].balanceSheet.currentAssets" />
                    <vap-number-item :text="__('econ_fixed_assets')" :number="economyYears[economyIndex].balanceSheet.fixedAssets" />
                    <vap-number-item :text="__('econ_sum_assets')" bold :number="economyYears[economyIndex].balanceSheet.sumAssets" />
                    <vap-number-item :text="__('econ_equity')" :number="economyYears[economyIndex].balanceSheet.equity" />
                    <vap-number-item :text="__('econ_debt')" :number="economyYears[economyIndex].balanceSheet.debt" />
                    <vap-number-item :text="__('econ_sum_equity_and_debt')" bold :number="economyYears[economyIndex].balanceSheet.sumEquityAndDebt" />
                </div>
            </template>
        </vap-form-divider>
    </vap-card>

    <vap-card :header="__('company_manual_header')" v-if="manualData.name && customer.source != 'brreg'">
        <CompanyInputsF v-model="manualData" noValidation />
    </vap-card>

</div>
</template>

<script>
import log from '../../log';

import CompanyInputs from '../kyc/CompanyInputs';
import CompanyInputsF from '../kyc/CompanyInputsF';

import LineChart from '../../components/stats/LineChart';

export default {
    name: 'CompanyInfo',
    components: {
        CompanyInputs,
        CompanyInputsF,
        LineChart
    },
    props: {
        customer: {
            type: Object,
            default: () => { return {} }
        },
        amlData: {
            type: Object,
            default: () => { return {} }
        },
        brregData: {
            type: Object,
            default: () => { return {} }
        },
        manualData: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            economy: {},
            economyYears: [],
            economyTabs: {},
            economyOptions: {},
            economyIndex: 0,
            lines: {},
            econLines: [],
            econLineIndex: 0
        }
    },
    watch: {
        amlData: {
            deep: false,
            handler(val) {
                log.v('Company: amlData watch triggered', val);
                this.processEconomy(val);
            }
        },
        economyTabs: {
            deep: true,
            handler(val) {
                let selectedYear = '';
                for (let key in this.economyTabs) {
                    if (this.economyTabs[key]) {
                        selectedYear = key.replace('y', '');
                    }
                }
                for (let i in this.economyYears) {
                    if (this.economyYears[i].year == selectedYear) {
                        this.economyIndex = i;
                    }
                }
            }
        }
    },
    methods: {
        processEconomy(data) {
            //console.log('processing', data);
            if (data.financials) {
                // Accountant
                if (data.financials.accountant && data.financials.accountant.name) {
                    this.economy.accountant = {
                        name: data.financials.accountant.name,
                        orgnr: data.financials.accountant.organizationNumber
                    }
                    if (data.financials.accountant.metadata.sources && data.financials.accountant.metadata.sources.length > 0) {
                        this.economy.accountant.source = data.financials.accountant.metadata.sources[0];
                    }
                }
                // Auditor
                if (data.financials.auditor && data.financials.auditor.name) {
                    this.economy.auditor = {
                        name: data.financials.auditor.name,
                        orgnr: data.financials.auditor.organizationNumber
                    }
                    if (data.financials.auditor.metadata.sources && data.financials.auditor.metadata.sources.length > 0) {
                        this.economy.auditor.source = data.financials.auditor.metadata.sources[0];
                    }
                }
                // Key figures
                if (data.financials.keyFigures) {

                }
                // Accounting years
                if (data.financials.accounting && data.financials.accounting.years) {
                    for (let i in data.financials.accounting.years) {
                        this.economyYears.push(data.financials.accounting.years[i]);
                    }
                    this.economyYears.sort((b, a) => (a.year > b.year) ? 1 : -1);
                    for (let i in this.economyYears) {
                        this.$set(this.economyTabs, 'y' + this.economyYears[i].year, i == 0);
                        this.$set(this.economyOptions, 'y' + this.economyYears[i].year, { label: this.economyYears[i].year.toString() });

                        //console.log('year', this.economyYears[i]);
                        

                    }
                }

                for (let i=0; i<5; i++) {
                    let mainkey = 'incomeStatement', subKey = 'totalRevenues', localeKey = 'econ_total_revenues';
                    switch (i) {
                        case 1: mainkey = 'incomeStatement'; subKey = 'operatingProfits'; localeKey = 'econ_operating_profits'; break;
                        case 2: mainkey = 'incomeStatement'; subKey = 'resultBeforeTax'; localeKey = 'econ_result_before_tax'; break;
                        case 3: mainkey = 'incomeStatement'; subKey = 'netProfits'; localeKey = 'econ_net_profits'; break;
                        case 4: mainkey = 'balanceSheet'; subKey = 'sumAssets'; localeKey = 'econ_sum_assets'; break;
                    }
                    const item = { localeKey: localeKey };
                    for (const j in this.economyYears) {
                        item[this.economyYears[j].year.toString()] = this.economyYears[j][mainkey][subKey].toString();
                    }
                    this.econLines.push(item);
                }
                this.econLines.year0 = '2019';
                this.econLines.year1 = '2018';
                this.econLines.year2 = '2017';
                //console.log('lines', this.econLines);

                this.lines = {
                    labels: [this.econLines.year2, this.econLines.year1, this.econLines.year0],
                    numbers: [this.econLines[0][this.econLines.year2], this.econLines[0][this.econLines.year1], this.econLines[0][this.econLines.year0]],
                    color: this.$root.getColor('accent', false)
                };

            }
        },
    },
    async created() {
        //console.log(this.manualData);
        this.processEconomy(this.amlData);
    }
}
</script>

<style scoped>

.econ-line {
    display: flex;
}

.econ-line-base {
    width: 400px;
    flex-shrink: 0;
}

.econ-line-top {
    display: flex;
}

.econ-line-item {
    display: flex;
}

.econ-line-name {
    flex-grow: 100;
}

.econ-line-year0, .econ-line-year1, .econ-line-year2 {
    width: 100px;
    flex-shrink: 0;
    text-align: right;
}

.econ-line-year0 {
    
}
.econ-line-year1 {
    
}
.econ-line-year2 {
    
}

.econ-lines {
    width: 100%;
}

</style>
