<template>
<div>

    <PropertyItem padded :header="'Options'" :icon="'input-check'" v-if="!input.external">

        <div class="frv2-opts-ditems">

            <div class="frv2-opts-ditem" v-for="(item, i) in input.items" :key="'ii' + i" :id="'i' + item.key" :class="{'frv2-opts-ditem-hide': sourceIndex == i}">

                <div class="frv2-opts-ditem-handle" :id="'h' + item.key">
                    <vap-icon small :icon="'burger'" :color="'ext'" style="pointer-events: none;" />
                </div>

                <div class="frv2-opts-ditem-default" @click="onRadioInput(item.key)">
                    <CheckboxUI radio :checked="item.default" />
                </div>

                <div class="frv2-opts-ditem-key">
                    <DesignerInput v-model="item.value" @before="$emit('propinput', 'text', 'drop_item_key', $event)" />
                </div>

                <div class="frv2-opts-ditem-text">
                    <DesignerInput v-model="item[$root.locale.key]" @before="$emit('propinput', 'text', 'drop_item_text', $event)" />
                </div>

                <div class="frv2-opts-ditem-delete" @click="onTranslationsClicked(i)" v-if="reference.allowTranslation">
                    <vap-icon tiny :icon="'locale'" :color="'ext'" />
                </div>

                <div class="frv2-opts-ditem-delete" @click="deleteDropdownItem(i)">
                    <vap-icon tiny :icon="'delete'" :color="'ext'" />
                </div>

                <div class="frv2-opts-ditem-drop" v-if="isDragging"
                    @dragenter="onDragEnter(i)"
                    @dragleave="onDragLeave"
                    @drop="handleDrop($event, i)"
                    :class="{'frv2-opts-ditem-hover': hoveredIndex == i }"
                />
                <div class="frv2-opts-ditem-drop frv2-opts-ditem-drop2" v-if="isDragging && i == input.items.length - 1"
                    @dragenter="onDragEnter(i + 1)"
                    @dragleave="onDragLeave"
                    @drop="handleDrop($event, i + 1)"
                    :class="{'frv2-opts-ditem-hover2': hoveredIndex == i + 1 }"
                />

            </div>

            <div class="frv2-opts-ditems-add">
                <div class="frv2-opts-ditems-add-text">
                    {{ input.items.length + ' options' }}
                </div>
                <div class="frv2-opts-ditems-add-button" @click="showPasteDialog" v-if="$root.credentials.isAdmin">
                    <vap-icon small :icon="'paste'" />
                </div>
                <div class="frv2-opts-ditems-add-button" @click="addDropdownItem">
                    <vap-icon small :icon="'add'" />
                </div>
            </div>

        </div>

    </PropertyItem>

    <PopupV1 designer noPadding :maxHeight="500" v-if="pasteDialog.show" :header="'Paste data'" @close="pasteDialog.show = false" :dialog="pasteDialog">
        <template v-slot:content>

            <div>
                <div v-for="(item, i) in pasteItems" :key="'pi' + i">
                    {{ item }}
                </div>
            </div>

        </template>
        <template v-slot:buttons>
            <VFButton :text="'Cancel'" outline
                @clicked="pasteDialog.show = false"
            />
            <VFButton :text="'Paste'" :icon="'paste'"
                @clicked="onPasteItems" :disabled="!pasteDialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import textHelper from '../../textHelper';
import editorHelper from '../../editorHelper';

import PropertyItem from './PropertyItem.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import CheckboxUI from '../ui/CheckboxUI.vue';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';

export default {
    name: 'DropdownProperties',
    components: {
        PropertyItem,
        DesignerInput,
        CheckboxUI,
        PopupV1,
        VFButton,
    },
    props: {
        reference: { type: Object, default: null },
        input: { type: Object, default: null },
    },
    data() {
        return {
            isDragging: false,
            sourceIndex: -1,
            hoveredIndex: -1,
            pasteDialog: { show: false, valid: false },
            pasteItems: [],
        }
    },
    methods: {

        async showPasteDialog() {
            this.pasteItems = [];

            try {
                if (navigator.clipboard) {
                    const text = await navigator.clipboard.readText();
                    
                    console.log('text', text);

                    const definition = [];

                    let lines = text.split('\r\n');
                    /* let lines = text.split('&#9;'); */

                    for (const i in lines) {

                        const columns = lines[i].split('\t');

                        console.log('columns', columns);

                        if (i == 0) continue;

                        this.pasteItems.push({
                            text: columns[1],
                        });

                    }


                    console.log('lines', lines);

                }
            }catch (ex) {
                log.x('Clipboard: No valid data in clipboard or not focused');
            }

            this.validatePasteDialog();
            this.pasteDialog.show = true;
        },

        validatePasteDialog() {
            this.pasteDialog.valid = true;
        },

        onPasteItems() {
            editorHelper.onChange(this.reference, 'drop_item_paste', false);
            for (const i in this.pasteItems) {
                this.pasteDropdownItem(this.pasteItems[i].text);
            }
            this.pasteDialog.show = false;
            this.enableDragging();
        },

        onTranslationsClicked(index) {

        },
        
        deleteDropdownItem(index) {
            editorHelper.onChange(this.reference, 'drop_item_del', false);
            this.input.items.splice(index, 1);
        },

        addDropdownItem() {
            editorHelper.onChange(this.reference, 'drop_item_add', false);
            this.reference.counter += 1;
            const key = 'x' + this.reference.counter;
            const item = {
                type: 'itm',
                key: key,
                value: key,
            };
            this.input.items.push({ ...item, ...textHelper.getEmptyTextObject(this.reference) });
    	    this.enableDragging();
        },

        pasteDropdownItem(pasteText) {
            this.reference.counter += 1;
            const key = 'x' + this.reference.counter;
            const item = {
                type: 'itm',
                key: key,
                value: key,
            };
            const newItem = { ...item, ...textHelper.getEmptyTextObject(this.reference) };

            for (const i in this.reference.locales) {
                newItem[this.reference.locales[i].key] = pasteText;
            }

            this.input.items.push(newItem);
        },

        onRadioInput(key) {
            editorHelper.onChange(this.reference, 'drop_item_default', false);
            for (const i in this.input.items) {
                this.input.items[i].default = false;
                if (this.input.items[i].key == key) this.input.items[i].default = true;
            }
            this.$forceUpdate();
        },

        onDragStart(index) {
            this.isDragging = true;
            
            this.$nextTick(() => {
                const stuff = document.getElementsByClassName('frv2-opts-ditem-drop');
                stuff.forEach(el => el.addEventListener('dragover', event => {
                    event.preventDefault();
                }));
            });

            const self = this;
            setTimeout(function() {
                self.sourceIndex = index;
            }, 10);
        },

        onDragEnd() {
            this.isDragging = false;
            this.sourceIndex = -1;
        },

        onDragEnter(index) {
            this.hoveredIndex = index;
        },

        onDragLeave() {
            this.hoveredIndex = -1;
        },

        getItemIndex(index) {
            if (index < this.sourceIndex) return index;
            return index - 1;
        },

        handleDrop(e, index) {
            //const data = e.dataTransfer.getData("text/aml");
            editorHelper.onChange(this.reference, 'drop_item_move', false);

            this.onDragLeave();
            this.isDragging = false;

            const targetIndex = this.getItemIndex(index);

            while (this.sourceIndex < 0) this.sourceIndex += this.input.items.length;
            while (targetIndex < 0) targetIndex += this.input.items.length;
            this.input.items.splice(targetIndex, 0, this.input.items.splice(this.sourceIndex, 1)[0]);
        },

        enableDragging() {
            const self = this;
            this.$nextTick(() => {
                for (const i in this.input.items) {
                    const draggable = document.getElementById('i' + this.input.items[i].key);
                    const handle = document.getElementById('h' + this.input.items[i].key);
                    handle.onmousedown = function(e) {
                        e.target.parentNode.setAttribute('draggable', 'true')
                    };
                    handle.onmouseup = function(e) {
                        e.target.parentNode.setAttribute('draggable', 'false')
                    };
                    draggable.ondragstart = function(e) {
                        e.dataTransfer.setData('text/aml', self.input.items[i].key);
                        self.onDragStart(i);
                    };
                    draggable.ondragend = function(e) {
                        e.target.setAttribute('draggable', 'false');
                        self.onDragEnd();
                    };
                }
            });
        },

    },
    created() {
        if (!this.input.external) this.enableDragging();
    }
}
</script>
<style scoped>

.frv2-opts-ditems {
    float: left;
    width: 100%;
    padding: 0px 5px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 5px;
}

.frv2-opts-ditem {
    position: relative;
    display: flex;
    height: 26px;
    margin-top: -1px;
    border: solid 1px var(--fb-menu-b);
    background-color: var(--fb-input-bg);
}

.frv2-opts-ditem-hide {
    display: none;
}

.frv2-opts-ditem-handle {
    width: 31px;
    padding-top: 0px;
    padding-left: 2px;
    fill: var(--fb-menu-a);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-opts-ditem-handle:hover {
    fill: var(--fb-menu-b);
    background-color: var(--fb-menu-a);
}

.frv2-opts-ditem-default {
    padding-top: 1px;
    padding-left: 0px;
    width: 26px;
    border-left: solid 1px var(--fb-menu-b);
    box-sizing: border-box;
    cursor: pointer;
}

.frv2-opts-ditem-key {
    width: 70px;
    border-left: solid 1px var(--fb-menu-b);
}
.frv2-opts-ditem-key input {
    border: none; outline: none; background: none;
    width: 100%; height: 100%;
    box-sizing: border-box;
    padding-left: 6px;
    padding-top: 2px;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: white;
    opacity: 0.6;
}

.frv2-opts-ditem-text {
    flex-grow: 100;
    border-left: solid 1px var(--fb-menu-b);
}

.frv2-opts-ditem-text input {
    border: none; outline: none; background: none;
    width: 100%; height: 100%;
    box-sizing: border-box;
    padding-left: 6px;
    padding-top: 2px;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: white;
    opacity: 0.8;
}

.frv2-opts-ditem-delete {
    width: 28px;
    padding-top: 2px;
    padding-left: 2px;
    fill: gray;
    border-left: solid 1px var(--fb-menu-b);
    cursor: pointer;
    box-sizing: border-box;
}
.frv2-opts-ditem-delete:hover {
    background-color: var(--fb-menu-a);
}

.frv2-opts-ditem-drop {
    position: absolute;
    top: -6px; height: 11px;
    left: 60px; right: 60px;
    border-radius: 5px;
    border: solid 1px var(--fb-menu-b);
    background-color: var(--text-weak);
    opacity: 0.3;
}
.frv2-opts-ditem-hover {
    opacity: 0.8;
    top: -8px; height: 17px;
}

.frv2-opts-ditem-drop2 {
    top: 25px;
}
.frv2-opts-ditem-hover2 {
    opacity: 0.8;
    top: 22px; height: 17px;
}

.frv2-opts-ditems-add {
    display: flex;
    margin-top: -1px;
}

.frv2-opts-ditems-add-text {
    flex-grow: 100;
    font-size: 14px;
    color: var(--text-weak);
    padding-top: 8px;
    padding-left: 12px;
}

.frv2-opts-ditems-add-button {
    width: 28px;
    border: solid 1px var(--fb-menu-b);
    cursor: pointer;
}
.frv2-opts-ditems-add-button:hover {
    background-color: var(--item-hover);
}

</style>
