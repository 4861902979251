<template>
<div>

    <PopupV1 :header="__t('verified-reset')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('verified-reset-hint') + ':' }}
            </div>
            <DialogContent>

                <DialogContentHeader :text="__t('verified-form').toUpperCase() + ':'" />
                <DialogContentItem :text="name || 'form'" :icon="'form'" />

            </DialogContent>
            <div style="margin-top: 20px; color: var(--text-title);" v-if="abort">
                {{ __t('verified-reset-abort') }}
            </div>
            <div style="margin-top: 20px; color: var(--text-title);" v-if="!abort">
                {{ __t('verified-reset-archive') }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('verified-reset')"
                :icon="'delete'" warning
                @clicked="resetForm"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import verified from '../verified';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import v1api from '../v1helpers/v1api';

export default {
    name: 'ResetDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox
    },
    props: {
        params: { type: Object, default: null },
        isID: { type: Boolean, default: false },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            envelopeId: '',
            documentId: '',
            recipientId: '',
            name: '',
            abort: false
        }
    },
    methods: {

        validateDialog() {
            this.dialog.valid = true;
        },

        async resetForm() {
            this.dialog.working = true;

            if (this.abort) {
                this.dialog.status = this.__t('verified-aborting');

                // TODO abort multiple documents
                await verified.abortEnvelope(this.envelopeId, this.documentId);
            }

            this.dialog.status = this.__t('verified-resetting');

            if (this.isID) {
                await v1api.resetIDC(this.params.personId);
            }else {
                await v1api.resetForm(this.params.clientId, this.params.formKey);
            }

            const response = await v1api.getClient(this.params.clientId);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.dialog.show = false;
            this.onClose();
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        this.loading = true;
        this.dialog.working = true;
        this.dialog.status = this.__t('verified-reminder-loading');

        if (this.params.envelopeId) {
            const envelope = await this.$root.getEnvelope(this.params.envelopeId);
            this.envelopeId = envelope.id;
            this.documentId = envelope.documentId;

            for (const i in envelope.content.recipients) {
                if (!envelope.content.recipients[i].completed) {
                    this.recipientId = envelope.content.recipients[i].id;
                    break;
                }
            }
            console.log('envelope', envelope);
            this.name = envelope.content.documents[0].name;
            this.abort = !envelope.content.completed;
        }

        this.dialog.working = false;
        this.loading = false;
        this.validateDialog();
    }
}
</script>