<template>
<div class="vf-logo" @click.stop="handleClick" :class="[
    { 'vf-logo-selected' : selected }
]">
    
    <img :src="logoUrl" />

</div>
</template>

<script>
export default {
    name: 'vf-logo',
    props: {
        cardData: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    data() {
        return {
            logoUrl: ''
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

    },
    mounted() {
        let logoUrl = this.cardData.url;
        if (!logoUrl && this.$root.config.branding && this.$root.config.branding.logoLanding) {
            logoUrl = this.$root.config.branding.logoLanding;
        }
        if (!logoUrl || logoUrl.includes('verified-logo-blue')) {
            logoUrl = this.$root.config.pdf.logoUrl;
        }
        this.logoUrl = logoUrl;
    }
}
</script>
<style scoped>

.vf-logo {
    float: left;
    width: 100%;
    padding-top: 60px;
    padding-bottom: 40px;
    text-align: center;
    box-sizing: border-box;
}

.vf-logo img {
    max-width: 400px;
}


/* Designer / Selection */

.vf-logo-selected {
    outline: solid 2px #238ccc;
}

</style>
