<template>
    <div>
        <FlowEditor :flow="flow" />
    </div>
</template>

<script>
import FlowEditor from '../v1flows/FlowEditor.vue';

export default {
    name: 'FlowEditView',
    components: {
        FlowEditor
    },
    data() {
        return {
            flow: null
        }
    },
    methods: {

        async onSave(data) {
            
        },

    },
    async created() {
        
        const flowKey = this.$route.query.key;
        console.log('flowKey', flowKey);

        for (const i in this.$root.flowConfig.flows) {
            if (this.$root.flowConfig.flows[i].key == flowKey) {
                this.flow = this.$root.flowConfig.flows[i];
            }
        }

        console.log('flow', this.flow);

    }
}
</script>