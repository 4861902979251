<template>
<div>
    <vap-card v-if="changes">
        <div class="aml-comp-item" v-for="(change, i) in changes.items" :key="'cc' + i">
            <div class="aml-comp-header">
                <div class="aml-comp-icon">
                    <vap-icon small absolute :top="-4" :right="0" :icon="getIcon(change)" :color="'var(--text-accent)'" />
                </div>
                <div class="aml-comp-type">
                    <label>{{ getTypeText(change) }}</label>
                </div>
            </div>
            <div class="aml-comp-change">
                <div class="aml-comp-from">
                    <label>{{ getFromText(change) }}</label>
                </div>
                <div class="aml-comp-arrow">
                    <vap-icon v-if="getToText(change)" tiny absolute :top="-2" :right="0" :icon="'chev-r'" />
                </div>
                <div class="aml-comp-to">
                    <label>{{ getToText(change) }}</label>
                </div>
            </div>
        </div>
        <div v-if="changes.items && changes.items.length == 0" style="margin-top: 10px; margin-bottom: 20px;">
            <label>{{ 'Ingen data endret i egenerklæringen' }}</label>
        </div>
    </vap-card>
</div>
</template>

<script>
import personhelper from '../../helpers/personhelper';

const CHANGE_REMOVED = 1;
const CHANGE_ADDED = 2;
const CHANGE_PERCENTAGE = 3;

export default {
    name: 'ComparisonView',
    props: {
        changes: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        getIcon(change) {
            switch (change.change) {
                case CHANGE_REMOVED: return 'cancel';
                case CHANGE_ADDED: return 'add';
                case CHANGE_PERCENTAGE: return 'edit';
            }
        },

        getTypeText(change) {
            let str = '';
            if (change.type == 'rrh') {
                str += this.$root.__('screening_role_rrh');
            }else if (change.type == 'sh') {
                str += this.$root.__('screening_role_shareholder');
            }else {
                str += this.$root.__(change.type);
            }
            str += ' ' + this.getChangeType(change);
            return str;
        },

        getChangeType(change) {
            switch (change.change) {
                case CHANGE_REMOVED: return 'fjernet';
                case CHANGE_ADDED: return 'lagt til';
                case CHANGE_PERCENTAGE: return '- eierandel endret';
            }
        },

        getFromText(change) {
            switch (change.change) {
                case CHANGE_REMOVED: return personhelper.titleCase(change.data.displayName);
                case CHANGE_ADDED: return personhelper.titleCase(change.data.displayName);
                case CHANGE_PERCENTAGE: return personhelper.titleCase(change.data.displayName) + ' - ' + change.data.percentageFrom + ' %';
            }
        },

        getToText(change) {
            switch (change.change) {
                case CHANGE_REMOVED: return '';
                case CHANGE_ADDED: return '';
                case CHANGE_PERCENTAGE: return change.data.percentageTo + ' %';
            }
        },

    },
    async created() {
        //console.log('changes', this.changes);
    }
}
</script>

<style scoped>

.aml-comp-item {
    margin-bottom: 15px;
}

.aml-comp-header {
    display: flex;
}
.aml-comp-icon {
    position: relative;
    flex-shrink: 0;
    margin-right: 10px;
    width: 26px; height: 26px;
}
.aml-comp-type {
    flex-grow: 100;
    font-size: 18px;
    color: var(--text-accent);
}

.aml-comp-change {
    display: flex;
    margin-top: 3px;
}
.aml-comp-from {
    font-size: 16px;
}
.aml-comp-arrow {
    position: relative;
    flex-shrink: 0;
    margin-left: 2px;
    margin-right: 4px;
    width: 26px; height: 26px;
}
.aml-comp-to {
    font-size: 16px;
}

</style>
