<template>
<div>
    <PageHeader :offset="97" @scrolled="scrolled = $event">
        <template v-slot:content>
            <vap-row v-if="!loading">
                <vap-group auto noPadding>
                    <vap-label :text="__t('report-report')" desc/>
                    <vap-label :text="__t('report-customer')" value />
                </vap-group>
            </vap-row>
        </template>
        <template v-slot:compact>
            <div v-if="!loading">
                <span>{{ __t('report-report') }}</span>
                <span>{{ __t('report-customer') }}</span>
            </div>
        </template>
    </PageHeader>
    
    <div class="aml-page">
        <vap-form-divider :left="25" :right="75" style="margin-top: 10px;">
            <template v-slot:left>

                <!-- Export button -->
                <vap-label :text="__t('report-exporting')" header style="margin-top: 10px; margin-bottom: 10px;" />

                <vap-card noPadding style="margin-bottom: 10px;">
                    <vap-item fancy fancyCircle padding20 :fancyIcon="'download'"
                        :fancyText="__t('report-export-CSV-1')"
                        :fancyHint="__t('report-export-CSV-hint')"
                        @clicked="showExportCSVDialog">
                    </vap-item>
                </vap-card>

                <!-- Client -->
                <vap-label :text="'Kunde'" header style="margin-top: 10px; margin-bottom: 10px;" />

                <VFCheckbox
                    v-model="pointClientModel"
                    :options="pointClientOptions"
                    disabled
                />

                <!-- Company -->
                <vap-label :text="'Selskap'" header style="margin-top: 10px; margin-bottom: 10px;" />

                <VFCheckbox
                    v-model="pointCompanyModel"
                    :options="pointCompanyOptions"
                    disabled
                />

                <!-- Custom -->
                <vap-label :text="'Egendefinert'" header style="margin-top: 10px; margin-bottom: 10px;" />

                <VFCheckbox
                    v-model="pointCustomModel"
                    :options="pointCustomOptions"
                    disabled
                />

            </template>
            <template v-slot:right>

                <!-- Report -->
                <vap-label :text="__t('report-report-preview')" header style="margin-top: 10px; margin-bottom: 10px;" />

                <vap-card noPadding>
                    <DataTable :columns="loadedColumns" :rows="loadedRows" ref="datatable" />
                </vap-card>
                
            </template>
        </vap-form-divider>
    </div>

    <!-- Export dialog -->
    <PopupV1 v-if="exportCSVDialog.show" :header="__t('report-export-CSV-2')" @close="exportCSVDialog.show = false" :dialog="exportCSVDialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ 'The following files will be exported' + ':' }}
            </div>
            <DialogContent>
                <DialogContentHeader :text="__t('files-document').toUpperCase() + ':'" />
                <DialogContentItem :text="exportCSVDialog.filename" :icon="'checklist'" />
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="exportCSVDialog.show = false" :disabled="exportCSVDialog.working" />
            <VFButton :text="__t('report-export-CSV-button')" :icon="'download'" @clicked="exportCSV" :working="exportCSVDialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import log from '../log';
import v1api from '../v1helpers/v1api';
import exportHelper from '../v1export/exportHelper';
import downloadjs from 'downloadjs';

import PageHeader from '../v1ui/PageHeader.vue';

import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import DataTable from '../components/special/DataTable.vue';

import PopupV1 from '../v1ui/PopupV1.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFButton from '../components/form/items/VFButton.vue';

export default {
    name: 'ReportsV1',
    components: {
        PageHeader,
        VFCheckbox,
        DataTable,
        PopupV1,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFButton
    },
    data() {
        return {
            loading: true,
            scrolled: false,

            pointClientModel: {},
            pointClientOptions: {},

            pointCompanyModel: {},
            pointCompanyOptions: {},

            pointCustomModel: {},
            pointCustomOptions: {},

            clients: [],

            loadedColumns: [],
            loadedRows: [],

            exportCSVDialog: { show: false, working: false, status: '', filename: 'Exported data' },
        }
    },
    methods: {
        
        async getClients() {

            const sort = this.$root.reportData.sort;
            const filters = this.$root.reportData.filters;
            const from = 0;
            const limit = 10;
            const include = [];
            const counts = ['letter'];

            const response = await v1api.queryClients(filters, from, limit, sort, include, counts);
            this.clients = response.clients;
        },

        async getClients2(from, limit) {

            const sort = this.$root.reportData.sort;
            const filters = this.$root.reportData.filters;
            const include = [];
            const counts = ['letter'];

            const response = await v1api.queryClients(filters, from, limit, sort, include, counts);
            console.log('response', response);
            return response.clients;
        },

        loadColumns() {
            for (const key in this.pointClientModel) {
                if (this.pointClientModel[key]) {
                    this.loadedColumns.push({
                        key: key,
                        name: this.pointClientOptions[key].locale[this.$root.locale.key],
                        width: 10
                    });
                }
            }
            for (const key in this.pointCompanyModel) {
                if (this.pointCompanyModel[key]) {
                    this.loadedColumns.push({
                        key: key,
                        name: this.pointCompanyOptions[key].locale[this.$root.locale.key],
                        width: 10
                    });
                }
            }
            for (const key in this.pointCustomModel) {
                if (this.pointCustomModel[key]) {
                    this.loadedColumns.push({
                        key: key,
                        name: this.pointCustomOptions[key].locale[this.$root.locale.key],
                        width: 10
                    });
                }
            }
        },

        loadRows() {
            for (const i in this.clients) {
                const row = {};
                for (const key in this.pointClientModel) {
                    row[key] = exportHelper.getValue(this.$root, key, this.clients[i]);
                }
                for (const key in this.pointCompanyModel) {
                    row[key] = exportHelper.getValue(this.$root, key, this.clients[i]);
                }
                for (const key in this.pointCustomModel) {
                    row[key] = exportHelper.getValue(this.$root, key, this.clients[i]);
                }
                this.loadedRows.push(row);
            }
        },

        loadRows2(clients, outputRows) {
            for (const i in clients) {
                const row = {};
                for (const key in this.pointClientModel) {
                    row[key] = exportHelper.getValue(this.$root, key, clients[i]);
                }
                for (const key in this.pointCompanyModel) {
                    row[key] = exportHelper.getValue(this.$root, key, clients[i]);
                }
                for (const key in this.pointCustomModel) {
                    row[key] = exportHelper.getValue(this.$root, key, clients[i]);
                }
                outputRows.push(row);
            }
        },

        showExportCSVDialog() {
            this.exportCSVDialog.show = true;
        },

        async exportCSV() {
            this.exportCSVDialog.working = true;

            const limit = 100;
            const rows = [];

            let end = false;
            let from = 0;
            while (!end) {
                const clients = await this.getClients2(Number(from), Number(limit));
                from += Number(limit);

                console.log('clients', clients);

                this.loadRows2(clients, rows);

                await new Promise(r => setTimeout(r, 200));

                if (clients.length < limit) end = true;
            }

            
            this.downloadFile(rows);

            this.exportCSVDialog.working = false;
            this.exportCSVDialog.show = false;
        },

        downloadFile(rows) {
            let str = '';
            const comma = ';';
            for (const i in this.loadedColumns) {
                str += this.loadedColumns[i].name + comma;
            }
            str += '\n';
            for (const i in rows) {
                for (const j in this.loadedColumns) {
                    str += this.getDownloadValue(rows[i], this.loadedColumns[j]) + comma;
                }
                str += '\n';
            }
            downloadjs("data:text/csv;charset=utf-8,\uFEFF" + str, this.exportCSVDialog.filename + ".csv", "text/csv");
        },

        getDownloadValue(row, column) {
            if (row[column.key]) {
                return row[column.key];
            }else {
                return '';
            }
        },

    },
    async created() {
        this.$parent.setLoading(true, this.__('loading_customer') + '...');

        console.log('RDATA', this.$root.reportData);

        exportHelper.setPossibleClientValues(this.$root, this.pointClientModel, this.pointClientOptions);
        exportHelper.setPossibleCompanyValues(this.$root, this.pointCompanyModel, this.pointCompanyOptions);
        exportHelper.setPossibleCustomValues(this.$root, this.pointCustomModel, this.pointCustomOptions);

        await this.getClients();

        this.loadColumns();
        this.loadRows();

        // Hide loading spinner and show UI
        this.loading = false;
        this.$parent.setLoading(false);
    }
}
</script>