const kyc00se = {

    getForm() {
        return {
            "key": "kyc00",
            "designVersion": 1,
            "locales": [
                {
                    "key": "en_EN",
                    "name": "English",
                    "enabled": false,
                    "alpha2": "gb"
                },
                {
                    "key": "nb_NO",
                    "name": "Norsk",
                    "enabled": false,
                    "alpha2": "no"
                },
                {
                    "key": "sv_SE",
                    "name": "Svenska",
                    "enabled": true,
                    "alpha2": "se"
                }
            ],
            "designName": "Self-declaration form Judicial Person (with lookup, SE version)",
            "title": {
                "en_EN": "Declaration form Judicial Person",
                "nb_NO": "Egenerklæring Juridisk Person",
                "sv_SE": "Kundkännedom Juridisk person"
            },
            "name": {
                "en_EN": "Self-declaration {{customerName}}",
                "nb_NO": "Egenerklæring {{customerName}}",
                "sv_SE": "Kundkännedom {{customerName}}"
            },
            "flowType": "sign",
            "flowConfig": {
                "autoSave": false,
                "canSave": false,
                "customSender": {
                    "enabled": false
                },
                "landingParams": {
                    "filled": {
                        "title": {
                            "en_EN": "Declaration form filled",
                            "nb_NO": "Egenerklæringsskjema fylt ut",
                            "sv_SE": "Kundkännedom är ifylld"
                        },
                        "message": {
                            "en_EN": "<p>Thank you for filling out the declaration form.</p><p></p><p>The persons you selected will now receive an e-mail invitation to sign the decleration.</p>",
                            "nb_NO": "<p>Takk for at du fylte ut egenerklæringsskjemaet.</p><p></p><p>De valgte personene vil nå få en e-post med invitasjon til å signere.</p>",
                            "sv_SE": "<p>Tack för att du fyllt i formuläret för kundkännedom.</p><p></p><p>De valda personerna får nu ett e-postmeddelande med en inbjudan att underteckna.</p><p></p>"
                        }
                    },
                    "signed": {
                        "title": {
                            "en_EN": "Declaration form signed",
                            "nb_NO": "Egenerklæringsskjema signert",
                            "sv_SE": "Kundkännedom signerad"
                        },
                        "message": {
                            "en_EN": "<p>You have now signed the declaration form regarding your cooperation with us.</p><p></p><p>You will now receive an e-mail with a copy of the signed document.</p><p></p><p>Thank you for your cooperation.</p>",
                            "nb_NO": "<p>Du har nå signert egenerklæringen i forbindelse med deres samarbeid med oss.</p><p></p><p>Du vil nå motta en e-post med en kopi av det signerte dokumentet.</p><p></p><p>Takk for ditt samarbeid.</p>",
                            "sv_SE": "<p>Du har nu undertecknat kundkännedom angående ditt samarbete med oss.</p><p></p><p>Du kommer nu få ett e-postmeddelande med en kopia av det signerade dokumentet.</p><p></p><p>Tack för ditt samarbete.</p>"
                        }
                    },
                    "aborted": {
                        "title": {
                            "en_EN": "Signing aborted",
                            "nb_NO": "Signering avbrutt",
                            "sv_SE": "Signering avbröts"
                        },
                        "message": {
                            "en_EN": "<p>You have aborted the signing process, please contact your contact person in Verified AS to discuss further option.</p>",
                            "nb_NO": "<p>Du har avbrutt signeringsprosessen, ta gjerne kontakt med din kontaktperson for oppdraget slik at vi kan bli enige om veien videre.</p><p></p>",
                            "sv_SE": "<p>Du har avbrutit signeringsprocessen, kontakta gärna din kontaktperson för uppdraget så att vi kan komma överens om vägen framåt.</p><p></p>"
                        }
                    },
                    "redirect": "",
                    "logoUrl": ""
                },
                "roles": [
                    {
                        "key": "owner",
                        "type": "owner",
                        "display": "Owner",
                        "next": "editor",
                        "hasEmail": false,
                        "email": {},
                        "hasLanding": false,
                        "landingParams": {},
                        "redirect": "client"
                    },
                    {
                        "key": "editor",
                        "display": "Editor (Fill in form)",
                        "next": "signer",
                        "hasEmail": true,
                        "email": {
                            "subject": {
                                "en_EN": "You have new form to fill in",
                                "nb_NO": "Du har et nytt skjema å fylle ut",
                                "sv_SE": "Kundkännedom {{customerName}}"
                            },
                            "header": {
                                "en_EN": "Hi {{firstName}} {{lastName}}",
                                "nb_NO": "Hei {{firstName}} {{lastName}}",
                                "sv_SE": "Hej {{firstName}} {{lastName}}"
                            },
                            "message": {
                                "en_EN": "{{senderName}} have sent you a form ready to be filled in.",
                                "nb_NO": "{{senderName}} har sendt deg et nytt skjema som må fylles ut.",
                                "sv_SE": "I samband med ditt samarbete med {{companyName}} ber vi dig svara på ett kundkännedomsformulär."
                            },
                            "button": {
                                "en_EN": "Open form",
                                "nb_NO": "Åpne skjema",
                                "sv_SE": "GÅ VIDARE"
                            },
                            "hint": {
                                "en_EN": "Click the button to open the form.",
                                "nb_NO": "Trykk på knappen for å åpne skjema.",
                                "sv_SE": "Tryck på knappen för att öppna formuläret och börja fylla i det"
                            },
                            "footer": {
                                "en_EN": "If you have any questions please contact {{senderName}} on {{senderEmail}}",
                                "nb_NO": "Hvis du har noen spørsmål vennligst kontakt {{senderName}} på {{senderEmail}}",
                                "sv_SE": "Om du har några frågor, vänligen kontakta {{senderName}} på {{senderEmail}}"
                            },
                            "links": []
                        },
                        "hasLanding": true,
                        "redirect": ""
                    },
                    {
                        "key": "signer",
                        "display": "Signer",
                        "next": "done",
                        "hasEmail": true,
                        "email": {
                            "subject": {
                                "en_EN": "Document for signing",
                                "nb_NO": "Dokument for signering",
                                "sv_SE": "Kundkännedom {{customerName}} klar för {{action}}"
                            },
                            "header": {
                                "en_EN": "Hi {{firstName}} {{lastName}}",
                                "nb_NO": "Hei {{firstName}} {{lastName}}",
                                "sv_SE": "Hej {{firstName}} {{lastName}}"
                            },
                            "message": {
                                "en_EN": "You have received a new document for signing",
                                "nb_NO": "Du har mottatt et nytt dokument for signering",
                                "sv_SE": "Du har blivit ibjuden av {{companyName}} till att {{actionVerb}} följande dokument:"
                            },
                            "button": {
                                "en_EN": "Go to signing",
                                "nb_NO": "Gå til signering",
                                "sv_SE": "GÅ VIDARE"
                            },
                            "hint": {
                                "en_EN": "Click the button to open the document.",
                                "nb_NO": "Trykk på knappen for å åpne dokumentet.",
                                "sv_SE": "Starta signeringsprocessen genom att trycka på knappen ovan"
                            },
                            "footer": {
                                "en_EN": "If you have any questions please contact {{senderName}} on {{senderEmail}}",
                                "nb_NO": "Hvis du har noen spørsmål vennligst kontakt {{senderName}} på {{senderEmail}}",
                                "sv_SE": "Om du har några frågor, vänligen kontakta {{senderName}} på {{senderEmail}}"
                            },
                            "links": []
                        },
                        "hasLanding": true,
                        "landingParams": {},
                        "redirect": ""
                    },
                    {
                        "key": "done",
                        "display": "Document signed",
                        "hasEmail": true,
                        "email": {
                            "subject": {
                                "en_EN": "Signed document",
                                "nb_NO": "Signert dokument",
                                "sv_SE": "Signerat dokument"
                            },
                            "header": {
                                "en_EN": "Hi {{firstName}} {{lastName}}",
                                "nb_NO": "Hei {{firstName}} {{lastName}}",
                                "sv_SE": "Hej {{firstName}} {{lastName}}"
                            },
                            "message": {
                                "en_EN": "Here is your copy of the signed document",
                                "nb_NO": "Her er din kopi av det signerte dokumentet",
                                "sv_SE": "Här är din kopia av det signerade dokumentet"
                            },
                            "button": {
                                "en_EN": "Open form",
                                "nb_NO": "Åpne skjema",
                                "sv_SE": "Öppna formuläret"
                            },
                            "hint": {
                                "en_EN": "Click on the document name to download it for archiving",
                                "nb_NO": "Trykk på navnet for å laste det ned for arkivering",
                                "sv_SE": "Klicka på dokumentets namn för att ladda ner det för arkivering"
                            },
                            "footer": {
                                "en_EN": "If you have any questions please contact {{senderName}} on {{senderEmail}}",
                                "nb_NO": "Hvis du har noen spørsmål vennligst kontakt {{senderName}} på {{senderEmail}}",
                                "sv_SE": "Om du har några frågor vänligen kontakta {{senderName}} på {{senderEmail}}"
                            },
                            "links": []
                        },
                        "hasLanding": false,
                        "landingParams": {
                            "redirect": "default"
                        }
                    }
                ]
            },
            "modules": [
                {
                    "type": "kyc",
                    "icon": "eye",
                    "updateKyc": true
                },
                {
                    "type": "sign",
                    "icon": "sign",
                    "methods": {
                        "bankidno": true,
                        "bankidse": true,
                        "touchsign": true
                    }
                }
            ],
            "counter": 1347,
            "pages": [
                {
                    "key": "p1001",
                    "type": "page",
                    "mode": "thin-page",
                    "title": {
                        "en_EN": "Welcome",
                        "nb_NO": "Standard KYC",
                        "sv_SE": "Välkommen"
                    },
                    "showStepper": true,
                    "hasInfo": false,
                    "info": {
                        "owner": [
                            {
                                "key": "h1002",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1020",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "signer": [
                            {
                                "key": "h1029",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "done": [
                            {
                                "key": "h1281",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ]
                    },
                    "roles": {
                        "owner": false,
                        "editor": true,
                        "signer": true,
                        "done": true
                    },
                    "cards": [
                        {
                            "key": "c1137",
                            "type": "card",
                            "header": {
                                "en_EN": "",
                                "nb_NO": ""
                            },
                            "inputs": [
                                {
                                    "key": "i1139",
                                    "type": "helpt",
                                    "width": "12",
                                    "desc": {
                                        "formatted": true,
                                        "en_EN": "<h3>Welcome to our KYC-form</h3><p>This form is already partially filled with data from public registries. What you need to do is:</p><ul><li><p>Go over the informastion and make sure everything is correct and up to date.</p></li><li><p>If some of the information is wrong/outdated use the edit tools to correct it.</p></li><li><p>Enter necessary contact information for key roles in the company.</p></li></ul><p>Finally you choose who will sign the form on behalf of the company, yourself or you can forward it to someone else.</p><h3>Good luck!</h3>",
                                        "nb_NO": "<h3>Velkommen til vårt Egenerklæringsskjema</h3><p>Skjemaet er delvis utfylt med informasjon hentet fra offentlige registre. Det du nå skal gjøre er:</p><ul><li><p>Gå over informasjonen og sjekk at alt stemmer med dagens virkelighet.</p></li><li><p>Hvis noe ikke stemmer endrer du informasjonen med redigeringsverktøyene.</p></li><li><p>Fyll inn nødvendig kontaktinformasjon til relevante nøkkelpersoner.</p></li></ul><p>Til slutt velger du hvem som skal signere skjemaet, enten du selv, eller noen andre i foretaket.</p><h3>Lykke til!</h3>",
                                        "sv_SE": "<h2>Välkommen till vår kundkännedom</h2><p>Blanketten fylls delvis i med information från offentliga register. Vi vill nu att du som kund:</p><ul><li><p>Gå igenom informationen och kontrollera att den överensstämmer med aktuella uppgifter.</p></li><li><p>Om något är fel i dokumentet redigerar du informationen med redigeringsverktygen.</p></li><li><p>Fyll i önskad kontaktinformation för relevanta nyckelpersoner.</p></li></ul><p>Välj slutligen den som ska skriva under formuläret, antingen du själv eller någon annan i företaget.</p><h3>Lycka till!</h3>"
                                    },
                                    "required": false,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                }
                            ],
                            "noPadding": false,
                            "pdf": false,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            }
                        }
                    ]
                },
                {
                    "key": "p1005",
                    "type": "page",
                    "mode": "form-page",
                    "title": {
                        "en_EN": "Company",
                        "nb_NO": "Foretaket",
                        "sv_SE": "Företagsinformation"
                    },
                    "showStepper": true,
                    "hasInfo": true,
                    "info": {
                        "owner": [
                            {
                                "key": "h1004",
                                "icon": "company",
                                "header": {
                                    "en_EN": "Company information",
                                    "nb_NO": "Foretaket",
                                    "sv_SE": "Företagsinformation"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>There has been performed a lookup in public registries wich returned the information below. The customer will be asked to check if the information is correct.</p>",
                                    "nb_NO": "<p>Det er gjennomført et søk i offentlige registre på foretaket som returnerte informasjonen under. Kunden får beskjed om å sjekke at informasjonen stemmer.</p>",
                                    "sv_SE": "<p>En sökning har genomförts i offentliga register över företaget som returnerade informationen nedan. Kunden uppmanas att kontrollera att informationen är korrekt.</p>"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1021",
                                "icon": "company",
                                "header": {
                                    "en_EN": "Company information",
                                    "nb_NO": "Foretaksinformasjon",
                                    "sv_SE": "Företagsinformation"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>We have performed a lookup in public registries wich returned the information below. Please look over the results and correct any missing/outdated information.</p>",
                                    "nb_NO": "<p>Det er gjennomført et søk i offentlige registre på foretaket som returnerte informasjonen under. Gå gjennom og sjekk at denne informasjonen stemmer overens med dagens virkelighet, og rett opp hvis noe er feil.</p>",
                                    "sv_SE": "<p>En sökning har genomförts i offentliga register över företaget som returnerade informationen nedan. Gå igenom och kontrollera att denna information är i linje med dagens verklighet och korrigera om något är fel.</p>"
                                }
                            }
                        ],
                        "signer": [
                            {
                                "key": "h1030",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "done": [
                            {
                                "key": "h1282",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ]
                    },
                    "cards": [
                        {
                            "key": "c1053",
                            "type": "card",
                            "header": {
                                "en_EN": "Company Information",
                                "nb_NO": "Selskapsinformasjon",
                                "sv_SE": "Företagsinformation"
                            },
                            "inputs": [
                                {
                                    "key": "i1054",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Company Name",
                                        "nb_NO": "Navn på foretaket",
                                        "sv_SE": "Namn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "orgName",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1055",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Company Number",
                                        "nb_NO": "Organisasjonsnummer",
                                        "sv_SE": "Företagsnr"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "orgNumber",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1144",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Address",
                                        "nb_NO": "Adresse",
                                        "sv_SE": "Adress"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "businessAddress.street",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1145",
                                    "type": "text",
                                    "inputType": "number",
                                    "width": "2",
                                    "desc": {
                                        "en_EN": "Zip Code",
                                        "nb_NO": "Postnummer",
                                        "sv_SE": "Postnummer"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "businessAddress.zip",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1146",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "City",
                                        "nb_NO": "Poststed",
                                        "sv_SE": "Ort"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "businessAddress.city",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1147",
                                    "type": "date",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Date Established",
                                        "nb_NO": "Stiftelsesdato",
                                        "sv_SE": "Datum registrerat"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "dateRegistered",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1148",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Entity Type",
                                        "nb_NO": "Selskapstype",
                                        "sv_SE": "Företagstyp"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "formCode",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            }
                        },
                        {
                            "key": "c1150",
                            "type": "list",
                            "header": {
                                "en_EN": "Business Code",
                                "nb_NO": "Næringskode",
                                "sv_SE": "Kundkod/SNIkod"
                            },
                            "addtext": {
                                "en_EN": "Add business code",
                                "nb_NO": "Legg til næringskode",
                                "sv_SE": "Lägg till företagskod"
                            },
                            "inputs": [
                                {
                                    "key": "i1151",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "Business Code",
                                        "nb_NO": "Kode",
                                        "sv_SE": "Företagskod"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "code",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1153",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "9",
                                    "desc": {
                                        "en_EN": "Description",
                                        "nb_NO": "Beskrivelse",
                                        "sv_SE": "Beskrivning"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "description",
                                    "kycKey": ""
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            },
                            "kycKey": "naceCodes",
                            "model": [],
                            "val": []
                        }
                    ],
                    "roles": {
                        "owner": true,
                        "editor": true,
                        "signer": true,
                        "done": true
                    }
                },
                {
                    "key": "p1304",
                    "type": "page",
                    "mode": "form-page",
                    "title": {
                        "en_EN": "New page",
                        "nb_NO": "Ny side",
                        "sv_SE": "Företagets verksamhet"
                    },
                    "submitText": {
                        "en_EN": "Submit",
                        "nb_NO": "Send inn",
                        "sv_SE": "Nästa"
                    },
                    "showStepper": true,
                    "hasInfo": true,
                    "info": {
                        "owner": [
                            {
                                "key": "h1300",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst",
                                    "sv_SE": "Företagets verksamhet"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst",
                                    "sv_SE": "<p>Nedan fyller ni i information som rör er verksamhet.</p>"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1301",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst",
                                    "sv_SE": "New help text"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst",
                                    "sv_SE": "This is a new help text"
                                }
                            }
                        ],
                        "signer": [
                            {
                                "key": "h1302",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst",
                                    "sv_SE": "New help text"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst",
                                    "sv_SE": "This is a new help text"
                                }
                            }
                        ],
                        "done": [
                            {
                                "key": "h1303",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst",
                                    "sv_SE": "New help text"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst",
                                    "sv_SE": "This is a new help text"
                                }
                            }
                        ]
                    },
                    "cards": [
                        {
                            "key": "c1305",
                            "type": "card",
                            "header": {
                                "en_EN": "The company's operations",
                                "nb_NO": "",
                                "sv_SE": "Företagets verksamhet"
                            },
                            "inputs": [
                                {
                                    "key": "i1306",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "Annual turnover",
                                        "nb_NO": "New text input",
                                        "sv_SE": "Årlig omsättning"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1307",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "Number of employees",
                                        "nb_NO": "New text input",
                                        "sv_SE": "Antal anställda"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1308",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Industry",
                                        "nb_NO": "New text input",
                                        "sv_SE": "Bransch"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": ""
                                },
                                {
                                    "key": "a1309",
                                    "type": "area",
                                    "inputType": "text",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Business concept and goals",
                                        "nb_NO": "New text area",
                                        "sv_SE": "Affärsidé och mål"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": ""
                                },
                                {
                                    "key": "a1310",
                                    "type": "area",
                                    "inputType": "text",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Business conducted",
                                        "nb_NO": "New text area",
                                        "sv_SE": "Verksamhet som bedrivs"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": ""
                                },
                                {
                                    "key": "a1311",
                                    "type": "area",
                                    "inputType": "text",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Market, customers and suppliers",
                                        "nb_NO": "New text area",
                                        "sv_SE": "Marknad kunder och leverantörer"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": ""
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true,
                                "done": true
                            }
                        },
                        {
                            "key": "c1318",
                            "type": "card",
                            "header": {
                                "en_EN": "The company's cash flow",
                                "nb_NO": "",
                                "sv_SE": "Företagets betalningsflöde"
                            },
                            "inputs": [
                                {
                                    "key": "i1322",
                                    "type": "check",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Cash handling",
                                        "nb_NO": "New checkbox",
                                        "sv_SE": "Kontanthantering"
                                    },
                                    "required": true,
                                    "inline": true,
                                    "radio": true,
                                    "fillCard": false,
                                    "border": false,
                                    "options": {
                                        "x1323": {
                                            "type": "opt",
                                            "key": "x1323",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "Yes",
                                                "nb_NO": "Checkbox option 1",
                                                "sv_SE": "Ja"
                                            }
                                        },
                                        "x1324": {
                                            "type": "opt",
                                            "key": "x1324",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "No",
                                                "nb_NO": "Checkbox option 2",
                                                "sv_SE": "Nej"
                                            }
                                        }
                                    },
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "modelKey": "cash",
                                    "oldFormKey": "cashflow"
                                },
                                {
                                    "key": "i1325",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Expected annual cash handling volume",
                                        "nb_NO": "New text input",
                                        "sv_SE": "Förväntad årsvolym kontanthantering"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1322",
                                                    "key": "x1323",
                                                    "rev": false
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1326",
                                    "type": "check",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Foreign trade",
                                        "nb_NO": "New checkbox",
                                        "sv_SE": "Utlandsaffärer"
                                    },
                                    "required": true,
                                    "inline": true,
                                    "radio": true,
                                    "fillCard": false,
                                    "border": false,
                                    "options": {
                                        "x1327": {
                                            "type": "opt",
                                            "key": "x1327",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "Yes",
                                                "nb_NO": "Checkbox option 1",
                                                "sv_SE": "Ja"
                                            }
                                        },
                                        "x1328": {
                                            "type": "opt",
                                            "key": "x1328",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "No",
                                                "nb_NO": "Checkbox option 2",
                                                "sv_SE": "Nej"
                                            }
                                        }
                                    },
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1329",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Incoming foreign payments, annual volume",
                                        "nb_NO": "New text input",
                                        "sv_SE": "Ankommande utlandsbetalningar, årsvolym"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1326",
                                                    "key": "x1327",
                                                    "rev": false
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1330",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "From which countries?",
                                        "nb_NO": "New text input",
                                        "sv_SE": "Från vilka länder"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1326",
                                                    "key": "x1327",
                                                    "rev": false
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1331",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Outgoing payments abroad, annual volume",
                                        "nb_NO": "New text input",
                                        "sv_SE": "Angående utlandsbetalningar, årsvolym"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1326",
                                                    "key": "x1327",
                                                    "rev": false
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1332",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "To which countries?",
                                        "nb_NO": "New text input",
                                        "sv_SE": "Till vilka länder"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1326",
                                                    "key": "x1327",
                                                    "rev": false
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1333",
                                    "type": "check",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Does the business include currency exchange, payment intermediation or payment platform?",
                                        "nb_NO": "New checkbox",
                                        "sv_SE": "Innefattar verksamhetens valutaväxling, betalningsförmedling eller betalplattform?"
                                    },
                                    "required": true,
                                    "inline": true,
                                    "radio": true,
                                    "fillCard": false,
                                    "border": false,
                                    "options": {
                                        "x1334": {
                                            "type": "opt",
                                            "key": "x1334",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "Yes",
                                                "nb_NO": "Checkbox option 1",
                                                "sv_SE": "Ja"
                                            }
                                        },
                                        "x1335": {
                                            "type": "opt",
                                            "key": "x1335",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "No",
                                                "nb_NO": "Checkbox option 2",
                                                "sv_SE": "Nej"
                                            }
                                        }
                                    },
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": ""
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true,
                                "done": true
                            }
                        },
                        {
                            "key": "c1336",
                            "type": "card",
                            "header": {
                                "en_EN": "Consortium",
                                "nb_NO": "",
                                "sv_SE": "Koncern"
                            },
                            "inputs": [
                                {
                                    "key": "i1341",
                                    "type": "check",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Is the company part of a consortium?",
                                        "nb_NO": "New checkbox",
                                        "sv_SE": "Ingår bolaget i en koncern"
                                    },
                                    "required": true,
                                    "inline": true,
                                    "radio": true,
                                    "fillCard": false,
                                    "border": false,
                                    "options": {
                                        "x1342": {
                                            "type": "opt",
                                            "key": "x1342",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "Yes",
                                                "nb_NO": "Checkbox option 1",
                                                "sv_SE": "Ja"
                                            }
                                        },
                                        "x1343": {
                                            "type": "opt",
                                            "key": "x1343",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "No",
                                                "nb_NO": "Checkbox option 2",
                                                "sv_SE": "Nej"
                                            }
                                        }
                                    },
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1344",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Organisation number",
                                        "nb_NO": "New text input",
                                        "sv_SE": "Organisationsnummer"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1341",
                                                    "key": "x1342",
                                                    "rev": false
                                                }
                                            ]
                                        }
                                    }
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true,
                                "done": true
                            }
                        }
                    ],
                    "roles": {
                        "owner": true,
                        "editor": true,
                        "signer": true,
                        "done": true
                    }
                },
                {
                    "key": "p1007",
                    "type": "page",
                    "mode": "form-page",
                    "title": {
                        "en_EN": "Key roles",
                        "nb_NO": "Nøkkelroller",
                        "sv_SE": "Nyckelroller"
                    },
                    "showStepper": true,
                    "hasInfo": true,
                    "info": {
                        "owner": [
                            {
                                "key": "h1229",
                                "icon": "people",
                                "header": {
                                    "en_EN": "Key roles",
                                    "nb_NO": "Nøkkelroller",
                                    "sv_SE": "Nyckelroller"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>The customer will be asked add and fill in information about relevant key roles in the company. You can also add key roles at this stage if you have that information.</p>",
                                    "nb_NO": "<p>Kunden vil få beskjed om å legge til og fylle inn informasjon om relevante nøkkelroller i foretaket. Du kan også legge til nøkkelroller på dette steget hvis du har denne informasjonen.</p>",
                                    "sv_SE": "<p>Kunden uppmanas att lägga till och fylla i information om relevanta nyckelroller i företaget. Du kan också lägga till nyckelroller i det här steget om du har den här informationen.</p>"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1022",
                                "icon": "people",
                                "header": {
                                    "en_EN": "Key roles",
                                    "nb_NO": "Ny hjelpetekst",
                                    "sv_SE": " Nyckelroller"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst",
                                    "sv_SE": "<p>En sökning har gjorts i offentliga register efter nyckelroller i företaget. Gå igenom och kontrollera att denna information är i linje med dagens verklighet och korrigera om något är fel.</p>"
                                }
                            }
                        ],
                        "signer": [
                            {
                                "key": "h1031",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "done": [
                            {
                                "key": "h1283",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ]
                    },
                    "cards": [
                        {
                            "key": "c1040",
                            "type": "card",
                            "header": {
                                "en_EN": "CEO",
                                "nb_NO": "Daglig leder",
                                "sv_SE": "VD"
                            },
                            "inputs": [
                                {
                                    "key": "i1049",
                                    "type": "check",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "",
                                        "nb_NO": "",
                                        "sv_SE": ""
                                    },
                                    "required": false,
                                    "inline": false,
                                    "radio": false,
                                    "fillCard": false,
                                    "border": false,
                                    "options": {
                                        "x1050": {
                                            "type": "opt",
                                            "key": "x1050",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "The company does not have a CEO",
                                                "nb_NO": "Selskapet har ikke daglig leder",
                                                "sv_SE": "Företaget har ingen VD"
                                            }
                                        }
                                    },
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": {
                                        "x1050": false
                                    },
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1041",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "First Name",
                                        "nb_NO": "Fornavn",
                                        "sv_SE": "Förnamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "modelKey": "firstName",
                                    "oldFormKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1049",
                                                    "key": "x1050",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1042",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Last Name",
                                        "nb_NO": "Etternavn",
                                        "sv_SE": "Efternamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "modelKey": "lastName",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1049",
                                                    "key": "x1050",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1043",
                                    "type": "date",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Date of Birth",
                                        "nb_NO": "Fødselsdato",
                                        "sv_SE": "Födelsedatum"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "modelKey": "dateOfBirth",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1049",
                                                    "key": "x1050",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1044",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Address",
                                        "nb_NO": "Adresse",
                                        "sv_SE": "Adress"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "modelKey": "street",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1049",
                                                    "key": "x1050",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1045",
                                    "type": "text",
                                    "inputType": "number",
                                    "width": "2",
                                    "desc": {
                                        "en_EN": "Zip code",
                                        "nb_NO": "Postnummer",
                                        "sv_SE": "Postnummer"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "modelKey": "zip",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1049",
                                                    "key": "x1050",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1046",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "City",
                                        "nb_NO": "Poststed",
                                        "sv_SE": "Ort"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "modelKey": "city",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1049",
                                                    "key": "x1050",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "c1279",
                                    "type": "country",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "Country",
                                        "nb_NO": "Land",
                                        "sv_SE": "Land"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1049",
                                                    "key": "x1050",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "modelKey": "country",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1047",
                                    "type": "text",
                                    "inputType": "email",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Email address",
                                        "nb_NO": "Epost",
                                        "sv_SE": "Epost"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1049",
                                                    "key": "x1050",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "modelKey": "email"
                                },
                                {
                                    "key": "i1228",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Phone number",
                                        "nb_NO": "Telefon",
                                        "sv_SE": "Telefon"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1049",
                                                    "key": "x1050",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "modelKey": "phoneNumber"
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            },
                            "kycKey": "ceo",
                            "isPerson": true
                        },
                        {
                            "key": "c1155",
                            "type": "card",
                            "header": {
                                "en_EN": "Chairman",
                                "nb_NO": "Styreleder",
                                "sv_SE": "Ordförande"
                            },
                            "inputs": [
                                {
                                    "key": "i1156",
                                    "type": "check",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "",
                                        "nb_NO": ""
                                    },
                                    "required": false,
                                    "inline": false,
                                    "radio": false,
                                    "fillCard": false,
                                    "border": false,
                                    "options": {
                                        "x1157": {
                                            "type": "opt",
                                            "key": "x1157",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "The company does not have a Chairman",
                                                "nb_NO": "Selskapet har ikke styreleder",
                                                "sv_SE": "Företaget har ingen ordförande"
                                            }
                                        }
                                    },
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": {
                                        "x1157": false
                                    },
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1159",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "First Name",
                                        "nb_NO": "Fornavn",
                                        "sv_SE": "Förnamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "modelKey": "firstName",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1156",
                                                    "key": "x1157",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1160",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Last Name",
                                        "nb_NO": "Etternavn",
                                        "sv_SE": "Efternamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "modelKey": "lastName",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1156",
                                                    "key": "x1157",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1161",
                                    "type": "date",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Date of Birth",
                                        "nb_NO": "Fødselsdato",
                                        "sv_SE": "Födelsedatum"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "modelKey": "dateOfBirth",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1156",
                                                    "key": "x1157",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1162",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Address",
                                        "nb_NO": "Adresse",
                                        "sv_SE": "Adress"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "modelKey": "street",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1156",
                                                    "key": "x1157",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1163",
                                    "type": "text",
                                    "inputType": "number",
                                    "width": "2",
                                    "desc": {
                                        "en_EN": "Zip code",
                                        "nb_NO": "Postnummer",
                                        "sv_SE": "Postnummer"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "modelKey": "zip",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1156",
                                                    "key": "x1157",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1164",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "City",
                                        "nb_NO": "Poststed",
                                        "sv_SE": "Ort"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "modelKey": "city",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1156",
                                                    "key": "x1157",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "c1280",
                                    "type": "country",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "Country",
                                        "nb_NO": "Land",
                                        "sv_SE": "Land"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1156",
                                                    "key": "x1157",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "modelKey": "country",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1165",
                                    "type": "text",
                                    "inputType": "email",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Email address",
                                        "nb_NO": "Epost",
                                        "sv_SE": "Epost"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1156",
                                                    "key": "x1157",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "modelKey": "email"
                                },
                                {
                                    "key": "i1230",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Phone number",
                                        "nb_NO": "Telefon",
                                        "sv_SE": "Telefon"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1156",
                                                    "key": "x1157",
                                                    "rev": true
                                                }
                                            ]
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "modelKey": "phoneNumber"
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            },
                            "kycKey": "chair",
                            "isPerson": true
                        },
                        {
                            "key": "c1167",
                            "type": "card",
                            "header": {
                                "en_EN": "Board Members",
                                "nb_NO": "Styremedlemmer",
                                "sv_SE": "Ledamöter"
                            },
                            "inputs": [
                                {
                                    "key": "i1168",
                                    "type": "helpt",
                                    "width": "12",
                                    "desc": {
                                        "formatted": true,
                                        "en_EN": "<p>Please fill in information about the board members</p>",
                                        "nb_NO": "<p>Vennligst legg til alle styremedlemmer</p>",
                                        "sv_SE": "<p>Lägg till alla ledamöter</p>"
                                    },
                                    "required": false,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            }
                        },
                        {
                            "key": "c1169",
                            "type": "list",
                            "header": {
                                "en_EN": "Board Member",
                                "nb_NO": "Styremedlem",
                                "sv_SE": "Ledamot"
                            },
                            "addtext": {
                                "en_EN": "Add Board Member",
                                "nb_NO": "Legg til styremedlem",
                                "sv_SE": "Lägg till ledamot"
                            },
                            "inputs": [
                                {
                                    "key": "i1170",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "First Name",
                                        "nb_NO": "Fornavn",
                                        "sv_SE": "Förnamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "firstName",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1171",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Last Name",
                                        "nb_NO": "Etternavn",
                                        "sv_SE": "Efternamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "lastName",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1172",
                                    "type": "date",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Date of Birth",
                                        "nb_NO": "Fødselsdato",
                                        "sv_SE": "Födelsedatum"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "dateOfBirth",
                                    "kycKey": ""
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            },
                            "kycKey": "boardMembers",
                            "isPerson": true,
                            "model": [],
                            "val": []
                        },
                        {
                            "key": "c1291",
                            "type": "card",
                            "header": {
                                "en_EN": "Deputy board members",
                                "nb_NO": "",
                                "sv_SE": "Suppleanter"
                            },
                            "inputs": [
                                {
                                    "key": "i1293",
                                    "type": "helpt",
                                    "width": "12",
                                    "desc": {
                                        "formatted": true,
                                        "en_EN": "<p>Please fill in information about deputy board members</p>",
                                        "nb_NO": "Ny infotekst",
                                        "sv_SE": "<p>Lägg till alla suppleanter</p>"
                                    },
                                    "required": false,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": ""
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true,
                                "done": true
                            }
                        },
                        {
                            "key": "c1292",
                            "type": "list",
                            "header": {
                                "en_EN": "Deputy board member",
                                "nb_NO": "",
                                "sv_SE": "Suppleant"
                            },
                            "addtext": {
                                "en_EN": "Add deputy board member",
                                "nb_NO": "",
                                "sv_SE": "Lägg till suppleant"
                            },
                            "inputs": [
                                {
                                    "key": "i1294",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "First Name",
                                        "nb_NO": "Fornavn",
                                        "sv_SE": "Förnamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "firstName",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1295",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Last Name",
                                        "nb_NO": "Etternavn",
                                        "sv_SE": "Efternamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "lastName",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1296",
                                    "type": "date",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Date of Birth",
                                        "nb_NO": "Fødselsdato",
                                        "sv_SE": "Födelsedatum"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "dateOfBirth",
                                    "kycKey": ""
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true,
                                "done": true
                            },
                            "kycKey": "deputyBoardMembers"
                        }
                    ],
                    "roles": {
                        "owner": true,
                        "editor": true,
                        "signer": true,
                        "done": true
                    }
                },
                {
                    "key": "p1015",
                    "type": "page",
                    "mode": "form-page",
                    "title": {
                        "en_EN": "Owner structure",
                        "nb_NO": "Eierstruktur",
                        "sv_SE": "Ägarstruktur"
                    },
                    "showStepper": true,
                    "hasInfo": true,
                    "info": {
                        "owner": [
                            {
                                "key": "h1014",
                                "icon": "orgchart",
                                "header": {
                                    "en_EN": "Owner structure",
                                    "nb_NO": "Eierstruktur",
                                    "sv_SE": "Ägarstruktur"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>The customer will be asked to set up the owner structure in the form or upload a file that describes the owner structure.</p>",
                                    "nb_NO": "<p>Kunden vil bli bedt om å sette opp eierstrukturen i skjemaet eller laste opp en fil som beskriver eierstrukturen.</p>",
                                    "sv_SE": "<p>Kunden uppmanas att ange ägarstrukturen i formuläret eller ladda upp en fil som beskriver ägarstrukturen.</p>"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1026",
                                "icon": "orgchart",
                                "header": {
                                    "en_EN": "Owner structure",
                                    "nb_NO": " Eierstruktur",
                                    "sv_SE": "Ägarstruktur"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>We have performed a lookup in public registries on the company owner structure. Please look over the results and correct any missing/outdated information.</p>",
                                    "nb_NO": "<p>Det er gjennomført et søk i offentlige registre på foretakets eierstruktur. Gå gjennom og sjekk at denne informasjonen stemmer overens med dagens virkelighet, og rett opp hvis noe er feil.</p>",
                                    "sv_SE": "<p>En sökning har genomförts i offentliga register över företagets ägarstruktur. Kunden kommer att bli ombedd att granska informationen och bekräfta att den är korrekt.</p>"
                                }
                            },
                            {
                                "key": "h1242",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "Complex structure",
                                    "nb_NO": "Kompleks struktur",
                                    "sv_SE": "Komplex struktur"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>If the company has a complex owner structure, you can choose to upload a file that describes it.</p>",
                                    "nb_NO": "<p>Hvis foretaket har en kompleks eierstruktur, kan du velge laste opp en fil som beskriver den.</p>",
                                    "sv_SE": "<p>Om företaget har en komplex ägarstruktur kan du välja att ladda upp en fil som beskriver den.</p>"
                                }
                            }
                        ],
                        "signer": [
                            {
                                "key": "h1035",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "done": [
                            {
                                "key": "h1284",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ]
                    },
                    "cards": [
                        {
                            "key": "c1234",
                            "type": "card",
                            "header": {
                                "en_EN": "",
                                "nb_NO": ""
                            },
                            "inputs": [
                                {
                                    "key": "i1235",
                                    "type": "check",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "",
                                        "nb_NO": ""
                                    },
                                    "required": true,
                                    "inline": false,
                                    "radio": true,
                                    "fillCard": true,
                                    "options": {
                                        "x1236": {
                                            "type": "opt",
                                            "key": "x1236",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "I want to fill in information about the owner structure",
                                                "nb_NO": "Jeg bekrefter at informasjonen fra offentlige registre vist under er korrekt (Du kan endre navn/eierandel, og legge til/ta bort eiere hvis informasjonen er utdatert).",
                                                "sv_SE": "Jag vill ställa in ägarstruktur manuellt"
                                            },
                                            "kycKey": "hasShareholders"
                                        },
                                        "x1237": {
                                            "type": "opt",
                                            "key": "x1237",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "I want to upload a file that describes the owner structure",
                                                "nb_NO": "Jeg vil laste opp en fil som beskriver eierstruktur",
                                                "sv_SE": "Jag vill ladda upp en fil som beskriver ägarstrukturen"
                                            }
                                        }
                                    },
                                    "noPadding": true,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "model": {
                                        "x1236": false,
                                        "x1237": false
                                    },
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                }
                            ],
                            "noPadding": true,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            }
                        },
                        {
                            "key": "c1256",
                            "type": "list",
                            "header": {
                                "en_EN": "Shareholder",
                                "nb_NO": "Aksjonær",
                                "sv_SE": "Aktieägare"
                            },
                            "addtext": {
                                "en_EN": "Add shareholder",
                                "nb_NO": "Legg til aksjonær",
                                "sv_SE": "Lägg till aktieägare"
                            },
                            "inputs": [
                                {
                                    "key": "i1257",
                                    "type": "drop",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Shareholder type",
                                        "nb_NO": "Type aksjonær",
                                        "sv_SE": "Type aktieägare"
                                    },
                                    "required": true,
                                    "external": false,
                                    "items": [
                                        {
                                            "type": "itm",
                                            "key": "x1258",
                                            "value": "org",
                                            "en_EN": "Entity",
                                            "nb_NO": "Selskap",
                                            "sv_SE": "Företag"
                                        },
                                        {
                                            "type": "itm",
                                            "key": "x1259",
                                            "value": "person",
                                            "en_EN": "Physical person",
                                            "nb_NO": "Fysisk person",
                                            "sv_SE": "Fysisk person"
                                        }
                                    ],
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "type",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1261",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Owner share",
                                        "nb_NO": "Eierandel",
                                        "sv_SE": "Ägarandel"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "share",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1263",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Name",
                                        "nb_NO": "Navn",
                                        "sv_SE": "Namn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "name",
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "drop",
                                                    "pkey": "i1257",
                                                    "key": "org",
                                                    "rev": false
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1265",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Company number",
                                        "nb_NO": "Organisasjonsnummer",
                                        "sv_SE": "Företagsnr"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "orgnr",
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "drop",
                                                    "pkey": "i1257",
                                                    "key": "org",
                                                    "rev": false
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1267",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "First name",
                                        "nb_NO": "Fornavn",
                                        "sv_SE": "Förnamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "firstName",
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "drop",
                                                    "pkey": "i1257",
                                                    "key": "person",
                                                    "rev": false
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1269",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Last name",
                                        "nb_NO": "Etternavn",
                                        "sv_SE": "Efternamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "lastName",
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "drop",
                                                    "pkey": "i1257",
                                                    "key": "person",
                                                    "rev": false
                                                }
                                            ]
                                        }
                                    }
                                },
                                {
                                    "key": "i1271",
                                    "type": "date",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Date of Birth",
                                        "nb_NO": "Fødselsdato",
                                        "sv_SE": "Födelsedatum"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "dateOfBirth",
                                    "kycKey": "",
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "drop",
                                                    "pkey": "i1257",
                                                    "key": "person",
                                                    "rev": false
                                                }
                                            ]
                                        }
                                    }
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            },
                            "kycKey": "shareholders",
                            "isPerson": true,
                            "logic": {
                                "visible": {
                                    "or": true,
                                    "keys": [
                                        {
                                            "type": "check",
                                            "pkey": "i1235",
                                            "key": "x1236",
                                            "rev": false
                                        }
                                    ]
                                }
                            },
                            "model": [],
                            "val": []
                        },
                        {
                            "key": "c1273",
                            "type": "card",
                            "header": {
                                "en_EN": "",
                                "nb_NO": ""
                            },
                            "inputs": [
                                {
                                    "key": "f1274",
                                    "type": "upload",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Upload file",
                                        "nb_NO": "Last opp fil",
                                        "sv_SE": "Ladda upp fil"
                                    },
                                    "hint": {
                                        "en_EN": "Drag and drop a file here or",
                                        "nb_NO": "Dra og slipp en fil her eller",
                                        "sv_SE": "Dra och släpp en fil här eller"
                                    },
                                    "browse": {
                                        "en_EN": "select file",
                                        "nb_NO": "velg fil",
                                        "sv_SE": "välj en fil"
                                    },
                                    "singleFile": false,
                                    "autoPreview": false,
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "kycKey": "",
                                    "model": {
                                        "files": []
                                    },
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            },
                            "logic": {
                                "visible": {
                                    "or": true,
                                    "keys": [
                                        {
                                            "type": "check",
                                            "pkey": "i1235",
                                            "key": "x1237",
                                            "rev": false
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "roles": {
                        "owner": true,
                        "editor": true,
                        "signer": true,
                        "done": true
                    }
                },
                {
                    "key": "p1011",
                    "type": "page",
                    "mode": "form-page",
                    "title": {
                        "en_EN": "Beneficial owners",
                        "nb_NO": "Reelle rettighetshavere",
                        "sv_SE": "Verklig huvudman"
                    },
                    "showStepper": true,
                    "hasInfo": true,
                    "info": {
                        "owner": [
                            {
                                "key": "h1010",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "Beneficial owners",
                                    "nb_NO": "Reelle rettighetshavere",
                                    "sv_SE": "Verklig huvudman"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>The customer will be asked to add and fill in information about any beneficial owners.</p>",
                                    "nb_NO": "<p>Kunden vil bli bedt om å legge til og fylle inn informasjon om eventuelle reelle rettighetshavere.</p>",
                                    "sv_SE": "<p>Kunden kommer att bli ombedd att lägga till och fylla i information om alla verkliga huvudman.</p>"
                                }
                            },
                            {
                                "key": "h1233",
                                "icon": "orgchart",
                                "header": {
                                    "en_EN": "What is a beneficial owner?",
                                    "nb_NO": "Hva er en reell rettighetshaver?",
                                    "sv_SE": "Vad är en verklig huvudman?"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>A beneficial owner is a person that in the end controls more than 25 % of a company, either directly or indirectly through a parent company.</p>",
                                    "nb_NO": "<p>En reell rettighetshaver er en person som i praksis kontrollerer mer enn 25 % av foretaket, enten direkte eller inderekte gjennom et morselskap.</p>",
                                    "sv_SE": "<p>En verklig huvudman är en person som i praktiken kontrollerar mer än 25% av företaget, antingen direkt eller indirekt genom ett moderbolag.</p>"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1024",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "Information from public registries",
                                    "nb_NO": "Informasjon fra offentlige registre",
                                    "sv_SE": "Information från offentliga register"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>We have performed a lookup in public registries and hits on beneficial owners is shown below. Please look over the results and correct any missing/outdated information.</p>",
                                    "nb_NO": "<p>Det er gjennomført et søk i offentlige registre og treff på reelle rettighetshavere vises under. Gå gjennom og sjekk at denne informasjonen stemmer overens med dagens virkelighet, og rett opp hvis noe er feil.</p>",
                                    "sv_SE": "<p>En sökning har genomförts i offentliga register och träffar på verklig huvudman visas nedan. Gå igenom och kontrollera att denna information är i linje med dagens verklighet och korrigera om något är fel.</p>"
                                }
                            },
                            {
                                "key": "h1241",
                                "icon": "orgchart",
                                "header": {
                                    "en_EN": "What is a beneficial owner?",
                                    "nb_NO": "Hva er en reell rettighetshaver?",
                                    "sv_SE": "Vad är en verklig huvudman?"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>A beneficial owner is a person that in the end controls more than 25 % of a company, either directly or indirectly through a parent company.</p>",
                                    "nb_NO": "<p>En reell rettighetshaver er en person som i praksis kontrollerer mer enn 25 % av foretaket, enten direkte eller inderekte gjennom et morselskap.</p>",
                                    "sv_SE": "<p>En verklig huvudman är en person som i praktiken kontrollerar mer än 25% av företaget, antingen direkt eller indirekt genom ett moderbolag.</p>"
                                }
                            }
                        ],
                        "signer": [
                            {
                                "key": "h1033",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "done": [
                            {
                                "key": "h1285",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ]
                    },
                    "cards": [
                        {
                            "key": "c1062",
                            "type": "card",
                            "header": {
                                "en_EN": "",
                                "nb_NO": ""
                            },
                            "inputs": [
                                {
                                    "key": "i1063",
                                    "type": "check",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "",
                                        "nb_NO": ""
                                    },
                                    "required": true,
                                    "inline": false,
                                    "radio": true,
                                    "fillCard": true,
                                    "options": {
                                        "x1064": {
                                            "type": "opt",
                                            "key": "x1064",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "The company has one or more beneficial owner(s)",
                                                "nb_NO": "Foretaket har én eller flere rettighetshaver(e)",
                                                "sv_SE": "Företaget har en eller flera verkliga huvudmän"
                                            },
                                            "kycKey": "hasBeneficials"
                                        },
                                        "x1065": {
                                            "type": "opt",
                                            "key": "x1065",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "The company does not have any beneficial owners (No single person, through family members, shares or other means, controls more than 25 percent of the votes).",
                                                "nb_NO": "Foretaket har ingen reelle rettighetshavere",
                                                "sv_SE": "Företaget har inga verkliga huvudmän"
                                            }
                                        },
                                        "x1066": {
                                            "type": "opt",
                                            "key": "x1066",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "The company, or it's parent company is listed, and therefore do not need to report beneficial owners",
                                                "nb_NO": "Foretaket er et børsnotert foretak eller et datterforetak av et børsnotert foretak, og trenger derfor ikke å rapportere reell rettighetshaver",
                                                "sv_SE": "Företaget är ett börsnoterat företag eller ett dotterbolag till ett börsnoterat företag och behöver därför inte rapportera en huvudman"
                                            }
                                        }
                                    },
                                    "noPadding": true,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "model": {
                                        "x1064": false,
                                        "x1065": false,
                                        "x1066": false
                                    },
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                }
                            ],
                            "noPadding": true,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            }
                        },
                        {
                            "key": "c1067",
                            "type": "list",
                            "header": {
                                "en_EN": "Beneficial owner",
                                "nb_NO": "Reell rettighetshaver",
                                "sv_SE": "Verklig huvudman"
                            },
                            "addtext": {
                                "en_EN": "Add item",
                                "nb_NO": "Legg til reell rettighetshaver",
                                "sv_SE": "Lägg till verklig huvudman"
                            },
                            "inputs": [
                                {
                                    "key": "i1068",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "First Name",
                                        "nb_NO": "Fornavn",
                                        "sv_SE": "Förnamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "firstName",
                                    "kycKey": "",
                                    "modelKey": ""
                                },
                                {
                                    "key": "i1069",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "Last Name",
                                        "nb_NO": "Etternavn",
                                        "sv_SE": "Efternamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "lastName",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1070",
                                    "type": "date",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "Date of Birth",
                                        "nb_NO": "Fødselsdato",
                                        "sv_SE": "Födelsedatum"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "dateOfBirth",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1084",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "Percentage",
                                        "nb_NO": "Eierandel",
                                        "sv_SE": "Ägarandel"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "share",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1074",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Address",
                                        "nb_NO": "Adresse",
                                        "sv_SE": "Adress"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "street",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1075",
                                    "type": "text",
                                    "inputType": "number",
                                    "width": "2",
                                    "desc": {
                                        "en_EN": "Zip code",
                                        "nb_NO": "Postnummer",
                                        "sv_SE": "Postnummer"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "zip",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1076",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "City",
                                        "nb_NO": "Poststed",
                                        "sv_SE": "Ort"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "city",
                                    "kycKey": ""
                                },
                                {
                                    "key": "c1275",
                                    "type": "country",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "Country",
                                        "nb_NO": "Land",
                                        "sv_SE": "Land"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "country",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1080",
                                    "type": "text",
                                    "inputType": "email",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Email address",
                                        "nb_NO": "Epost",
                                        "sv_SE": "Epost"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "email",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1231",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Phone number",
                                        "nb_NO": "Telefon",
                                        "sv_SE": "Telefon"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "phoneNumber",
                                    "kycKey": ""
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            },
                            "kycKey": "beneficials",
                            "logic": {
                                "visible": {
                                    "or": true,
                                    "keys": [
                                        {
                                            "type": "check",
                                            "pkey": "i1063",
                                            "key": "x1064",
                                            "rev": false
                                        }
                                    ]
                                }
                            },
                            "model": [],
                            "val": [],
                            "isPerson": true
                        }
                    ],
                    "roles": {
                        "owner": true,
                        "editor": true,
                        "signer": true,
                        "done": true
                    }
                },
                {
                    "key": "p1013",
                    "type": "page",
                    "mode": "form-page",
                    "title": {
                        "en_EN": "PEP",
                        "nb_NO": "PEP",
                        "sv_SE": "PEP"
                    },
                    "showStepper": true,
                    "hasInfo": true,
                    "info": {
                        "owner": [
                            {
                                "key": "h1012",
                                "icon": "pep",
                                "header": {
                                    "en_EN": "Politically exposed persons",
                                    "nb_NO": "PEP",
                                    "sv_SE": "Politiskt utsatta personer"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>The customer will be asked to fill in the necessary information about people who have been checked for PEP. This step only appears if key roles have been deselected as PEP.</p>",
                                    "nb_NO": "<p>Kunden vil få beskjed om å fylle inn informasjon om PEP</p>",
                                    "sv_SE": "<p>Kunden kommer att bli ombedd att fylla i nödvändig information om personer som har kryssats för PEP. Detta steg visas bara om nyckelroller har avmarkerats som PEP.</p>"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1025",
                                "icon": "pep",
                                "header": {
                                    "en_EN": "Politically exposed persons",
                                    "nb_NO": "Politisk Eksponerte Personer",
                                    "sv_SE": "Politiskt utsatta personer"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>According to the requirements of the Money Laundering Act, we must ask questions if you are a person in a politically exposed position (or PEP). A person in a politically exposed position, family members and known employees of this person,\n\nis considered by its position and influence to hold a position which in itself constitutes a risk of being exploited for, among other things, bribery.\n\nA person in a politically exposed position is a person who has or has had important public functions in a state or in an international organization.</p>",
                                    "nb_NO": "<p>Fyll inn nødvendig informasjon om politisk eksponerte personer.</p>",
                                    "sv_SE": "<p>Enligt krav i penningtvättslagen måste vi ställa frågor om du är en person i politiskt utsatt ställning (eller PEP). En person i politiskt utsatt ställning, familjemedlemmar och kända medarbetare till denna person,</p><p>anses genom sin position och sitt inflytande inneha en ställning som i sig utgör en risk för att utnyttjas för bland annat mutbrott.</p><p>En person i politiskt utsatt ställning är en person som har eller har haft viktiga offentliga funktioner i en stat eller i en internationell organisation.</p>"
                                }
                            }
                        ],
                        "signer": [
                            {
                                "key": "h1034",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "done": [
                            {
                                "key": "h1286",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ]
                    },
                    "cards": [
                        {
                            "key": "c1086",
                            "type": "card",
                            "header": {
                                "en_EN": "",
                                "nb_NO": ""
                            },
                            "inputs": [
                                {
                                    "key": "i1087",
                                    "type": "check",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "",
                                        "nb_NO": ""
                                    },
                                    "required": false,
                                    "inline": false,
                                    "radio": true,
                                    "fillCard": true,
                                    "options": {
                                        "x1088": {
                                            "type": "opt",
                                            "key": "x1088",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "The company has one or more PEPs",
                                                "nb_NO": "En eller flere roller i selskapet er definert som PEP",
                                                "sv_SE": "Företaget har en eller flera PEP"
                                            }
                                        },
                                        "x1089": {
                                            "type": "opt",
                                            "key": "x1089",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "The company has no PEPs",
                                                "nb_NO": "Ingen roller i selskapet er definert som PEP",
                                                "sv_SE": "Företaget har inga PEP"
                                            }
                                        }
                                    },
                                    "noPadding": true,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "model": {
                                        "x1088": false,
                                        "x1089": false
                                    },
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                }
                            ],
                            "noPadding": true,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            }
                        },
                        {
                            "key": "c1176",
                            "type": "list",
                            "header": {
                                "en_EN": "PEP",
                                "nb_NO": "PEP",
                                "sv_SE": "PEP"
                            },
                            "addtext": {
                                "en_EN": "Add item",
                                "nb_NO": "Legg til PEP",
                                "sv_SE": "Lägg till PEP"
                            },
                            "inputs": [
                                {
                                    "key": "i1177",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "First Name",
                                        "nb_NO": "Fornavn",
                                        "sv_SE": "Förnamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "firstName",
                                    "kycKey": "",
                                    "modelKey": ""
                                },
                                {
                                    "key": "i1178",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Last Name",
                                        "nb_NO": "Etternavn",
                                        "sv_SE": "Efternamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "lastName",
                                    "kycKey": "",
                                    "modelKey": ""
                                },
                                {
                                    "key": "i1179",
                                    "type": "date",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Date of Birth",
                                        "nb_NO": "Fødselsdato",
                                        "sv_SE": "Födelsedatum"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "dateOfBirth",
                                    "kycKey": "",
                                    "modelKey": ""
                                },
                                {
                                    "key": "i1183",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "4",
                                    "desc": {
                                        "en_EN": "Address",
                                        "nb_NO": "Adresse",
                                        "sv_SE": "Adress"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "street",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1184",
                                    "type": "text",
                                    "inputType": "number",
                                    "width": "2",
                                    "desc": {
                                        "en_EN": "Zip code",
                                        "nb_NO": "Postnummer",
                                        "sv_SE": "Postnummer"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "zip",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1185",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "City",
                                        "nb_NO": "Poststed",
                                        "sv_SE": "Ort"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "city",
                                    "kycKey": ""
                                },
                                {
                                    "key": "c1277",
                                    "type": "country",
                                    "width": "3",
                                    "desc": {
                                        "en_EN": "Country",
                                        "nb_NO": "Land",
                                        "sv_SE": "Land"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "country",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1189",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Position / Title / Position (Why is this person defined as PEP?)",
                                        "nb_NO": "Stilling / tittlel / verv (Hvorfor er denne personen definert som PEP?)",
                                        "sv_SE": "Position / titel / position (Varför definieras denna person som PEP?)"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "title",
                                    "kycKey": "",
                                    "modelKey": ""
                                },
                                {
                                    "key": "i1191",
                                    "type": "text",
                                    "inputType": "email",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Email address",
                                        "nb_NO": "Epost",
                                        "sv_SE": "Epost"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "email",
                                    "kycKey": ""
                                },
                                {
                                    "key": "i1239",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Phone number",
                                        "nb_NO": "Telefon",
                                        "sv_SE": "Telefon"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "phoneNumber",
                                    "kycKey": ""
                                }
                            ],
                            "noPadding": false,
                            "pdf": true,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            },
                            "kycKey": "peps",
                            "model": [],
                            "val": [],
                            "isPerson": true,
                            "logic": {
                                "visible": {
                                    "or": true,
                                    "keys": [
                                        {
                                            "type": "check",
                                            "pkey": "i1087",
                                            "key": "x1088",
                                            "rev": false
                                        }
                                    ]
                                }
                            }
                        }
                    ],
                    "roles": {
                        "owner": true,
                        "editor": true,
                        "signer": true,
                        "done": true
                    }
                },
                {
                    "key": "p1017",
                    "type": "page",
                    "mode": "form-page",
                    "title": {
                        "en_EN": "Recipient",
                        "nb_NO": "Mottaker",
                        "sv_SE": "Mottagare"
                    },
                    "showStepper": true,
                    "hasInfo": true,
                    "info": {
                        "owner": [
                            {
                                "key": "h1016",
                                "icon": "edit",
                                "header": {
                                    "en_EN": "The recipient of the form",
                                    "nb_NO": "Mottaker",
                                    "sv_SE": "Mottagare"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>Select the customer contact person that will fill out the form.</p>",
                                    "nb_NO": "<p>Velg kontaktpersonen for oppdraget som skal fylle ut skjemaet.</p>",
                                    "sv_SE": "<p>Välj kontaktperson för den som ska fylla i formuläret.</p>"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1027",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "signer": [
                            {
                                "key": "h1036",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "done": [
                            {
                                "key": "h1287",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ]
                    },
                    "cards": [
                        {
                            "key": "c1038",
                            "type": "card",
                            "header": {
                                "en_EN": "Recipient",
                                "nb_NO": "Mottaker",
                                "sv_SE": "Mottagare"
                            },
                            "inputs": [
                                {
                                    "key": "i1114",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "First Name",
                                        "nb_NO": "Fornavn",
                                        "sv_SE": "Förnamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "flow": {
                                        "field": {
                                            "type": "firstName",
                                            "rkey": "editor"
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "ownerReq": true
                                },
                                {
                                    "key": "i1115",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Last Name",
                                        "nb_NO": "Etternavn",
                                        "sv_SE": "Efternamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "flow": {
                                        "field": {
                                            "type": "lastName",
                                            "rkey": "editor"
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "ownerReq": true
                                },
                                {
                                    "key": "i1116",
                                    "type": "text",
                                    "inputType": "email",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Email address",
                                        "nb_NO": "Epostadresse",
                                        "sv_SE": "Epost"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "flow": {
                                        "field": {
                                            "type": "email",
                                            "rkey": "editor"
                                        }
                                    },
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "ownerReq": true
                                }
                            ],
                            "noPadding": false,
                            "pdf": false,
                            "pdfIndex": 0,
                            "pdfLocked": false,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            }
                        }
                    ],
                    "roles": {
                        "owner": true,
                        "editor": false,
                        "signer": true,
                        "done": true
                    }
                },
                {
                    "key": "p1019",
                    "type": "page",
                    "mode": "form-page",
                    "title": {
                        "en_EN": "Signing",
                        "nb_NO": "Signering",
                        "sv_SE": "Undertecknare"
                    },
                    "showStepper": true,
                    "hasInfo": true,
                    "info": {
                        "owner": [
                            {
                                "key": "h1018",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "editor": [
                            {
                                "key": "h1028",
                                "icon": "sign",
                                "header": {
                                    "en_EN": "Signatories",
                                    "nb_NO": "Signering",
                                    "sv_SE": "Undertecknare"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>Select the person or persons that will sign the declaration on behalf of the company.</p>",
                                    "nb_NO": "<p>Velg hvem som skal signere egenerklæringen på vegne av foretaket.</p>",
                                    "sv_SE": "<p>Välj vem som ska underteckna självdeklarationen på företagets vägnar.</p>"
                                }
                            },
                            {
                                "key": "h1244",
                                "icon": "people",
                                "header": {
                                    "en_EN": "Who can sign?",
                                    "nb_NO": "Hvem kan signere?",
                                    "sv_SE": "Vem kan signera?"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "<p>There are no special requirements as to who can sign, as long as the person has a power of attorney from the company for this.</p>",
                                    "nb_NO": "<p>Det er ingen spesielle krav til hvem som kan signere, så lenge personen har fullmakt fra foretaket til dette.</p>",
                                    "sv_SE": "<p>Det finns inga speciella krav på vem som kan skriva under, så länge personen har en fullmakt från företaget för detta.</p>"
                                }
                            }
                        ],
                        "signer": [
                            {
                                "key": "h1037",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ],
                        "done": [
                            {
                                "key": "h1288",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "New help text",
                                    "nb_NO": "Ny hjelpetekst"
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "This is a new help text",
                                    "nb_NO": "Dette er en ny hjelpetekst"
                                }
                            }
                        ]
                    },
                    "cards": [
                        {
                            "key": "c1118",
                            "type": "card",
                            "header": {
                                "en_EN": "",
                                "nb_NO": ""
                            },
                            "inputs": [
                                {
                                    "key": "i1119",
                                    "type": "check",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "",
                                        "nb_NO": ""
                                    },
                                    "required": true,
                                    "inline": false,
                                    "radio": true,
                                    "fillCard": true,
                                    "options": {
                                        "x1120": {
                                            "type": "opt",
                                            "key": "x1120",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "I want to sign the self-declaration now",
                                                "nb_NO": "Jeg har fullmakt til å signere alene og ønsker å gjøre det nå",
                                                "sv_SE": "Jag har befogenhet att underteckna ensam och vill göra det nu"
                                            }
                                        },
                                        "x1121": {
                                            "type": "opt",
                                            "key": "x1121",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "I want to forward the self-declaration to be signed by one or more persons",
                                                "nb_NO": "Jeg vil sende den ferdig utfylte egenerklæringen til en eller flere personer for signering",
                                                "sv_SE": "Jag vill välja en eller flera personer som får en inbjudan att skriva under via e-post"
                                            }
                                        }
                                    },
                                    "noPadding": true,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "model": {
                                        "x1120": false,
                                        "x1121": false
                                    },
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "logic": {
                                        "link": [
                                            {
                                                "type": "sign",
                                                "link": "direct",
                                                "key": "x1120"
                                            }
                                        ]
                                    }
                                }
                            ],
                            "noPadding": true,
                            "pdf": false,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            }
                        },
                        {
                            "key": "c1117",
                            "type": "card",
                            "header": {
                                "en_EN": "",
                                "nb_NO": ""
                            },
                            "inputs": [
                                {
                                    "key": "i1143",
                                    "type": "helpt",
                                    "width": "12",
                                    "desc": {
                                        "formatted": true,
                                        "en_EN": "",
                                        "nb_NO": "<p>Velg hvordan du ønsker å signere egenerklæringen</p>"
                                    },
                                    "required": false,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    }
                                },
                                {
                                    "key": "i1123",
                                    "type": "drop",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Signing method",
                                        "nb_NO": "Signeringsmetode",
                                        "sv_SE": "Signeringsmetod"
                                    },
                                    "required": true,
                                    "external": true,
                                    "items": [
                                        {
                                            "type": "itm",
                                            "key": "x1124",
                                            "value": "x1124",
                                            "en_EN": "Dropdown item 1",
                                            "nb_NO": "Nedtrekksvalg 1"
                                        },
                                        {
                                            "type": "itm",
                                            "key": "x1125",
                                            "value": "x1125",
                                            "en_EN": "Dropdown item 2",
                                            "nb_NO": "Nedtrekksvalg 2"
                                        }
                                    ],
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "kycKey": "",
                                    "model": "",
                                    "val": {
                                        "valid": false,
                                        "done": false,
                                        "message": ""
                                    },
                                    "logic": {
                                        "rlist": {
                                            "key": "signMethods",
                                            "localized": true,
                                            "display": "locale",
                                            "list": "locale",
                                            "output": "value"
                                        },
                                        "link": [
                                            {
                                                "type": "sign",
                                                "link": "method",
                                                "key": ""
                                            }
                                        ]
                                    }
                                },
                                {
                                    "key": "b1345",
                                    "type": "buttoni",
                                    "width": "12",
                                    "buttons": [
                                        {
                                            "key": "b1346",
                                            "type": "btn",
                                            "color": 0,
                                            "outlined": false,
                                            "dotted": false,
                                            "width": 0,
                                            "text": {
                                                "en_EN": "Start the signing process",
                                                "nb_NO": "Ny knapp",
                                                "sv_SE": "Gå till signering"
                                            },
                                            "tooltip": {
                                                "en_EN": "New button tooltip",
                                                "nb_NO": "Ny hjelpetekst"
                                            },
                                            "logic": {
                                                "action": {
                                                    "type": "sys",
                                                    "key": "submit"
                                                },
                                                "disabled": {
                                                    "or": true,
                                                    "keys": [
                                                        {
                                                            "type": "val",
                                                            "key": "form",
                                                            "rev": false
                                                        }
                                                    ]
                                                }
                                            }
                                        }
                                    ],
                                    "style": 0,
                                    "size": 1,
                                    "align": 1,
                                    "kycKey": ""
                                }
                            ],
                            "noPadding": false,
                            "pdf": false,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            },
                            "logic": {
                                "visible": {
                                    "or": true,
                                    "keys": [
                                        {
                                            "type": "check",
                                            "pkey": "i1119",
                                            "key": "x1120",
                                            "rev": false
                                        }
                                    ]
                                }
                            }
                        },
                        {
                            "key": "c1130",
                            "type": "list",
                            "header": {
                                "en_EN": "Signatory",
                                "nb_NO": "Signatar",
                                "sv_SE": "Mottagare"
                            },
                            "addtext": {
                                "en_EN": "Add signatory",
                                "nb_NO": "Legg til signatar",
                                "sv_SE": " LÄGG TILL MOTTAGARE "
                            },
                            "inputs": [
                                {
                                    "key": "i1131",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "First Name",
                                        "nb_NO": "Fornavn",
                                        "sv_SE": "Förnamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "m1134",
                                    "kycKey": "",
                                    "flow": {
                                        "field": {
                                            "type": "firstName",
                                            "rkey": "signer"
                                        }
                                    }
                                },
                                {
                                    "key": "i1132",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Last Name",
                                        "nb_NO": "Etternavn",
                                        "sv_SE": "Efternamn"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "m1135",
                                    "kycKey": "",
                                    "flow": {
                                        "field": {
                                            "type": "lastName",
                                            "rkey": "signer"
                                        }
                                    }
                                },
                                {
                                    "key": "i1133",
                                    "type": "text",
                                    "inputType": "email",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "Email address",
                                        "nb_NO": "Epostadresse",
                                        "sv_SE": "Epost"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "m1136",
                                    "kycKey": "",
                                    "flow": {
                                        "field": {
                                            "type": "email",
                                            "rkey": "signer"
                                        }
                                    }
                                },
                                {
                                    "key": "i1245",
                                    "type": "drop",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Signing method",
                                        "nb_NO": "Signeringsmetode",
                                        "sv_SE": "Signeringsmetod"
                                    },
                                    "required": true,
                                    "external": true,
                                    "items": [
                                        {
                                            "type": "itm",
                                            "key": "x1246",
                                            "value": "x1246",
                                            "en_EN": "Dropdown item 1",
                                            "nb_NO": "Nedtrekksvalg 1"
                                        },
                                        {
                                            "type": "itm",
                                            "key": "x1247",
                                            "value": "x1247",
                                            "en_EN": "Dropdown item 2",
                                            "nb_NO": "Nedtrekksvalg 2"
                                        }
                                    ],
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "m1248",
                                    "kycKey": "",
                                    "flow": {
                                        "field": {
                                            "type": "method",
                                            "rkey": "signer"
                                        }
                                    },
                                    "logic": {
                                        "rlist": {
                                            "key": "signMethods",
                                            "localized": true,
                                            "display": "locale",
                                            "list": "locale",
                                            "output": "value"
                                        }
                                    }
                                },
                                {
                                    "key": "i1251",
                                    "type": "drop",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Language",
                                        "nb_NO": "Språk",
                                        "sv_SE": "Språk"
                                    },
                                    "required": true,
                                    "external": false,
                                    "items": [
                                        {
                                            "type": "itm",
                                            "key": "x1252",
                                            "value": "en_EN",
                                            "en_EN": "English",
                                            "nb_NO": "Engelsk",
                                            "sv_SE": "Norsk"
                                        },
                                        {
                                            "type": "itm",
                                            "key": "x1253",
                                            "value": "nb_NO",
                                            "en_EN": "Norwegian",
                                            "nb_NO": "Norsk",
                                            "sv_SE": "Engelsk"
                                        },
                                        {
                                            "type": "itm",
                                            "key": "x1289",
                                            "value": "sv_SE",
                                            "en_EN": "Swedish",
                                            "nb_NO": "Nytt nedtrekksvalg",
                                            "sv_SE": "Svenska"
                                        }
                                    ],
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "isList": true,
                                    "listModel": "m1254",
                                    "kycKey": "",
                                    "flow": {
                                        "field": {
                                            "type": "lang",
                                            "rkey": "signer"
                                        }
                                    }
                                }
                            ],
                            "noPadding": false,
                            "pdf": false,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            },
                            "kycKey": "",
                            "logic": {
                                "visible": {
                                    "or": true,
                                    "keys": [
                                        {
                                            "type": "check",
                                            "pkey": "i1119",
                                            "key": "x1121",
                                            "rev": false
                                        }
                                    ]
                                },
                                "link": [
                                    {
                                        "type": "sign",
                                        "link": "slist",
                                        "key": ""
                                    }
                                ]
                            },
                            "model": [],
                            "val": []
                        },
                        {
                            "key": "l1129",
                            "type": "methods",
                            "mode": "sign",
                            "pdf": false,
                            "roles": {
                                "owner": true,
                                "editor": true,
                                "signer": true
                            }
                        }
                    ],
                    "roles": {
                        "owner": false,
                        "editor": true,
                        "signer": true,
                        "done": true
                    }
                }
            ],
            "resources": {},
            "attachments": [],
            "logic": {}
        };
    }

};
export default kyc00se;