<template>
<div>
    {{ 'Redirect to: ' + redirectUrl }}
</div>
</template>
<script>
// Redirect to form viewer from a VE Secure URL

import FormViewer from '@/components/form/FormViewer';

export default {
    name: 'FormRedirect',
    components: {
        FormViewer
    },
    data() {
        return {
            redirectUrl: 'unknown'
        }
    },
    methods: {
        
    },
    async created() {
        let recipientId = '';
        for (const i in this.$parent.loadedEnvelope.content.recipients) {
            const recipient = this.$parent.loadedEnvelope.content.recipients[i];
            if (!recipient.completed) {
                recipientId = recipient.id;
                this.$parent.loadedRecipient = recipient;
            }
        }
        const lang = this.$route.query.lang;
        const access_token = this.$route.query.access_token;

        this.$parent.navigate({ path: `/fform/${this.$parent.loadedEnvelope.id}/${recipientId}`, query: { lang: lang, access_token: access_token } });

        this.loading = false;
        this.$parent.landing = false;
        this.$parent.setLoading(false);
    }
}
</script>