<template>
<div v-if="input.logic && input.logic[logicKey]">
    
    <div class="frv2-opt-logic" v-for="(itemKey, i) in input.logic[logicKey].keys" :key="'ll' + i">

        <div class="frv2-opt-logic-rev" @click="onRevClicked(itemKey)">
            {{ itemKey.rev ? 'NOT' : 'IF' }}
        </div>
        <div class="frv2-opt-logic-trg">
            {{ getKeyDisplay(itemKey) }}
        </div>
        <div class="frv2-opt-logic-delete" @click="onDeleteClicked(itemKey)">
            <vap-icon tiny :icon="'delete'" :color="'white'" />
        </div>

    </div>

</div>
</template>
<script>
import logicHelper from '../../logicHelper';

export default {
    name: 'LogicLines',
    props: {
        logicKey: { type: String, default: '' },
        input: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
    },
    methods: {

        getKeyDisplay(key) { return logicHelper.getKeyDisplay(this.$root, this.reference, key); },

        onRevClicked(itemKey) {
            itemKey.rev = !itemKey.rev;
        },

        onDeleteClicked(index) {
            console.log('onDelete', index);

            console.log('input', this.input);

            this.input.logic[this.logicKey].keys.splice(index, 1);

            if (this.input.logic[this.logicKey].keys.length == 0) {
                delete this.input.logic[this.logicKey];
            }

            if (Object.keys(this.input.logic).length == 0) {
                delete this.input.logic;
            }

        },

    },
}
</script>
<style scoped>

.frv2-opt-logic {
    display: flex;
    height: 24px;
    border-bottom: solid 1px var(--fb-input-bg);
    background-color: #13AA52;
    opacity: 0.8;
}

.frv2-opt-logic-rev {
    width: 40px;
    padding: 4px 0px;
    text-align: center;
    font-size: 14px;
    color: white;
    border-right: solid 1px var(--fb-input-bg);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-opt-logic-rev:hover {
    background-color: #26cc6b;
}

.frv2-opt-logic-trg {
    flex-grow: 100;
    padding-top: 4px;
    padding-left: 8px;
    font-size: 14px;
    color: white;
}

.frv2-opt-logic-delete {
    width: 28px;
    padding: 1px 2px;
    border-left: solid 1px var(--fb-input-bg);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-opt-logic-delete:hover {
    background-color: #26cc6b;
}

</style>