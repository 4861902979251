<template>
<div class="v1cb" :class="[
    {'v1cb-empty': isEmpty },
    {'v1cb-button': isButton },
    {'v1cb-accent': accented },
]" @click="handleClick">

    <div class="v1cb-desc">
        {{ desc }}
    </div>

    <div class="v1cb-value">
        {{ isEmpty ? emptyValue || value : value }}
    </div>

</div>
</template>
<script>

export default {
    name: 'BarItem',
    props: {
        desc: { type: String, default: '' },
        value: { type: String, default: '' },
        emptyValue: { type: String, default: '' },
        isEmpty: { type: Boolean, default: false },
        isButton: { type: Boolean, default: false },
        accented: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        handleClick() {
            if (!this.isButton) return;
            this.$emit('clicked');
        },

    }
}
</script>
<style scoped>

.v1cb {
    margin-right: 30px;
    padding: 4px 8px;
    padding-top: 0px;
}

.v1cb-desc {
    font-size: 13px;
    color: var(--text-weak);
}

.v1cb-value {
    font-size: 18px;
    color: var(--text-title);
}

.v1cb-accent .v1cb-value {
    font-weight: 500;
    color: var(--accent);
}

.v1cb-empty .v1cb-value {
    font-weight: normal;
    color: var(--text-disabled);
}

.v1cb-button {
    border-radius: 3px;
    cursor: pointer;
}
.v1cb-button:hover {
    background-color: var(--item-hover);
}

</style>