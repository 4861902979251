<template>
    <div class="ovs" :class="[{'ovs-pending': step.status == 0}, {'ovs-progress': step.status > 0 && step.status < 5}, {'ovs-complete card-shadow': step.status > 0}, {'ovs-aborted card-shadow': step.status == 9}, {'ovs-warning card-shadow': step.status == 8}]">

        <div class="ovs-icon">
            <label>{{ step.number }}</label>
        </div>

        <div class="ovs-content">

            <div v-if="step.status == 0" class="ovs-progress-content">
                <slot name="pending" />
            </div>

            <div v-if="step.status > 0 && step.status < 5" class="ovs-progress-content">
                <slot name="progress" />
            </div>

            <div v-if="step.status >= 5 && step.status < 8" class="ovs-complete-content">
                <slot name="complete" />
            </div>

            <div v-if="step.status == 9" class="ovs-complete-content">
                <slot name="aborted" />
            </div>

            <div v-if="step.status == 8" class="ovs-complete-content">
                <slot name="warning" />
            </div>

        </div>

        <div class="ovs-right">
            
            <div v-if="step.status == 0" class="ovs-action">
                <slot name="pending-action" />
            </div>

            <div v-if="step.status > 0 && step.status < 5" class="ovs-action">
                <slot name="progress-action" />
            </div>

            <div v-if="step.status >= 5 && step.status != 9" class="ovs-action">
                <slot name="complete-action" />
            </div>

            <div v-if="step.status == 9" class="ovs-action">
                <slot name="aborted-action" />
            </div>

        </div>
        
    </div>
</template>

<script>

export default {
    name: 'OverviewStep',
    components: {
        
    },
    props: {
        step: {
            type: Object,
            default: () => { return {} }
        },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
    },
    async created() {
        
    }
}
</script>
<style scoped>

.ovs {
    display: flex;
    float: left;
    width: 100%;
    min-height: 100px;
    margin-bottom: 20px;
    box-sizing: border-box;
}
.ovs-pending {
    border-radius: 6px;
    border: dotted 2px var(--text-disabled);
}
.ovs-complete {
    border-radius: 3px;
    background: var(--card-background);
}

.ovs-icon {
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    margin-top: 25px;
    margin-left: 25px;
    border-radius: 6px;
    font-family: "Roboto Slab", serif;;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    padding-top: 8px;
    padding-left: 2px;
    box-sizing: border-box;
}
.ovs-pending .ovs-icon {
    color: gray;
    border: dotted 2px var(--text-disabled);
}
.ovs-complete .ovs-icon {
    color: var(--primary-contrast);
    background: var(--primary);
}
.ovs-progress .ovs-icon {
    color: var(--primary);
    background: #DCF0FA;
    padding-top: 9px;
}
.ovs-aborted .ovs-icon {
    color: var(--error-text);
    background: var(--error-background);
}
.ovs-warning .ovs-icon {
    color: var(--warning-text);
    background: var(--warning-background);
}

.ovs-content {
    flex-grow: 100;
    padding-left: 24px;
}
.ovs-right {
    flex-shrink: 0;
}

.ovs-pending-name {
    margin-top: 28px;
    font-size: 18px;
    color: gray;
}
.ovs-progress-name, .ovs-complete-name {
    margin-top: 15px;
    font-size: 18px;
    color: var(--text-title);
}


.ovs-action {
    margin-top: 30px;
    margin-right: 20px;
}

</style>