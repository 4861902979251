import backend from '../backend';
import pdfTools from '../pdfTools';
import api from '../portalapi';
import verified from '../verified';
import tools from '../tools';
import log from '../log';
import v2lib from '../v2lib';
import personhelper from './personhelper';
const reporthelper = {

    async downloadPDF(root, data, config) {

        const appendFiles = [];
        if (config.appendFiles) {
            for (const i in config.appendFiles) {
                appendFiles.push(config.appendFiles[i]);
            }
        }

        if (config.appendAttachments) {
            let amlEnvelope;
            if (data.customer.aml) {
                amlEnvelope = await root.getEnvelope(data.customer.aml.envelopeId);
                //console.log('amle', amlEnvelope);
                for (const i in amlEnvelope.userdata.attachments) {
                    if (amlEnvelope.userdata.attachments[i].type == 'inc') {
                        const url = await verified.getAttachmentDownloadUrl(amlEnvelope, amlEnvelope.userdata.attachments[i].name);
                        //console.log('url', url);
                        if (url != 'NA') {
                            appendFiles.push({
                                type: 'inc',
                                url: url,
                                fileType: amlEnvelope.userdata.attachments[i].fileType,
                                description: 'aml',
                                info: 'aml'
                            });
                        }
                    }
                }
            }
            if (data.customer.kyc) {
                const url = await verified.getDownloadUrl(data.kycMetadata.envelopeId, data.kycMetadata.documentId, data.kycMetadata.fileUid);
                appendFiles.push({
                    type: 'kyc',
                    url: url,
                    fileType: 'application/pdf',
                    description: 'kyc',
                    info: 'kyc'
                });
            }else if (data.externalKYC && data.externalKYC.envelopeId) {
                const externalKycEnvelope = await root.getEnvelope(data.externalKYC.envelopeId);
                const url = await verified.getFirstAttachmentDownloadUrl(externalKycEnvelope);
                appendFiles.push({
                    type: 'kyc',
                    url: url,
                    fileType: 'application/pdf',
                    description: 'kyc',
                    info: 'kyc'
                });
            }


            if (data.customer.aml) {
                for (const i in amlEnvelope.userdata.attachments) {
                    if (amlEnvelope.userdata.attachments[i].type == 'aml') {
                        const url = await verified.getAttachmentDownloadUrl(amlEnvelope, amlEnvelope.userdata.attachments[i].name);
                        //console.log('url', url);
                        if (url != 'NA') {
                            appendFiles.push({
                                type: 'aml',
                                url: url,
                                fileType: amlEnvelope.userdata.attachments[i].fileType,
                                description: 'aml',
                                info: 'aml'
                            });
                        }
                    }
                }
            }

            //console.log('enabled', config.shareholderBook.enabled);
            //console.log('data', data.shareholderBook);
            if (config.shareholderBook.enabled && data.shareholderBook.provided) {

                let shEnvelope = undefined;
                let shName = '';
                shEnvelope = await root.getEnvelope(data.shareholderBook.envelopeId);
                //console.log('shenvelope', shEnvelope);
                for (const i in shEnvelope.userdata.attachments) {
                    if (shEnvelope.userdata.attachments[i].type == 'shbook' || shEnvelope.userdata.attachments[i].type == 'filestore') {
                        shName = shEnvelope.userdata.attachments[i].name;
                        const url = await verified.getAttachmentDownloadUrl(shEnvelope, shName);
                        appendFiles.push({
                            type: 'shbook',
                            url: url,
                            fileType: 'application/pdf',
                            description: 'shbook',
                            info: 'shbook'
                        });
                    }
                }
            }

            if (config.suitability && data.suitability.provided) {

                let suitEnvelope = undefined;
                let suitName = '';
                suitEnvelope = await root.getEnvelope(data.shareholderBook.envelopeId);
                //console.log('suitEnvelope', suitEnvelope);
                for (const i in suitEnvelope.userdata.attachments) {
                    if (suitEnvelope.userdata.attachments[i].type == 'shbook' || suitEnvelope.userdata.attachments[i].type == 'filestore') {
                        suitName = suitEnvelope.userdata.attachments[i].name;
                        const url = await verified.getAttachmentDownloadUrl(suitEnvelope, suitName);
                        appendFiles.push({
                            type: 'suit',
                            url: url,
                            fileType: 'application/pdf',
                            description: 'suit',
                            info: 'suit'
                        });
                    }
                }
            }

            for (const i in data.persons) {
                if (data.persons[i].control.completed && data.persons[i].control.method == 'fileupload') {
                    const idcEnvelope = await root.getEnvelope(data.persons[i].control.envelopeId);
                    //console.log('idcEnvelope', idcEnvelope);
                    for (const i in idcEnvelope.userdata.attachments) {
                        const url = await verified.getAttachmentDownloadUrl(idcEnvelope, idcEnvelope.userdata.attachments[i].name);
                        //console.log('url', url);
                        if (url != 'NA') {
                            appendFiles.push({
                                type: 'idcfile',
                                url: url,
                                name: idcEnvelope.userdata.attachments[i].name,
                                fileType: idcEnvelope.userdata.attachments[i].fileType,
                                description: idcEnvelope.userdata.attachments[i].type == 'passport' ? 'Kopi av pass' : 'Kopi av strømregning/kommunale avgifter',
                                info: 'Vedlegg i forbindelse med ID-kontroll av ' + idcEnvelope.userdata.person.displayName
                            });
                        }
                    }
                }
            }


            if (data.customer.type == 'person' && root.credentials.cid == 'By5w3t6Jd') {
                if (data.persons && data.persons.length > 0) {
                    if (data.persons[0].control.completed) {
                        const idcEnvelopeH = await root.getEnvelope(data.persons[0].control.envelopeId);
                        const url = await verified.getDownloadUrl(idcEnvelopeH.id, idcEnvelopeH.documentId, idcEnvelopeH.content.documents[0].files.signedDocument.uid);
                        appendFiles.push({
                            type: 'kyc',
                            url: url,
                            fileType: 'application/pdf',
                            description: 'kyc',
                            info: 'kyc'
                        });
                    }
                }
            }


        }

        //console.log('appendFiles', appendFiles);

        //console.log('data', data);

        let dateCreated = tools.dateString(new Date());
        let userId = root.credentials.uid;
        let userName = root.getEmployeeName(root.credentials.uid);

        if (!root.editor && data.customer.report && data.customer.report.envelopeId) {
            const repEnvelope = await root.getEnvelope(data.customer.report.envelopeId);
            //console.log('enve', repEnvelope);
            try {
                dateCreated = tools.dateString(repEnvelope.content.metadata.date);
                userId = repEnvelope.content.metadata.userId;
                userName = root.getEmployeeName(userId);
            }catch (exx) {
                console.log(exx);
            }
            if (config.externalRisk.enabled) {
                let name = '';
                for (const i in repEnvelope.userdata.attachments) {
                    if (repEnvelope.userdata.attachments[i].type == 'risk') {
                        name = repEnvelope.userdata.attachments[i].name;
                    }
                }
                if (name) {
                    const url = await verified.getAttachmentDownloadUrl(repEnvelope, name);
                    appendFiles.push({
                        type: 'risk',
                        url: url,
                        fileType: 'application/pdf',
                        description: 'risk',
                        info: 'risk'
                    });
                }
            }
        }else {
            dateCreated = data.metadata.date;
            userId = data.metadata.userId;
            userName = data.metadata.userName || 'Auto';
        }
        
        
        const userdata = {
            metadata: {
                date: dateCreated,
                userId: userId,
                userName: userName
            },
            flow: data.customer.flow || 'org-no-baml',
            customer: data.customer,
            brregData: data.brregData,
            manualData: data.manualData,
            aml: data.amlData,
            persons: data.persons,
            report: data.reportData,
            kycMetadata: data.kycMetadata,
            reportMetadata: data.reportMetadata,
            appendFiles: appendFiles,
            keywordOptions: {
                terrorism: { label: 'Terrorisme' },
                fraud: { label: 'Svindel' },
                sentance: { label: 'Dom' },
                insideTrading: { label: 'Innsidehandel' },
                illegal: { label: 'Ulovlig' },
                fine: { label: 'Bot' },
                corruption: { label: 'Korrupsjon' },
                trial: { label: 'Rettssak' },
                moneyLaundering: { label: 'Hvitvasking' },
                lawsuit: { label: 'Søksmål' }
            },
            reportConfig: config
        }

        //console.log(JSON.stringify(userdata));

        const response = await pdfTools.getPDF(root, root.config, 'rep', userdata, root.translations, true, appendFiles);
        return response.data;

    },

    getReportConfig(root) {
        const config = {
            ssn: root.config.report.ssn,
            appendAttachments: false,
            appendFiles: [],
            hintAttachments: true
        };
        return config;
    },

    async forwardReport(root, data, forwardData) {
        const result = {
            success: false
        };
        try {
            log.v('Forward: Forwarding report to', forwardData);
            log.v('Forward: Forwarding with data', data);

            const envelope = await v2lib.createEnvelope('aml-portal-risk', 'only-editors-flow', false);
            await v2lib.addEditorRecipient(envelope, forwardData, 'nb_NO');

            log.v('Forward: Envelope created', envelope);

            // Prepare for adding to backend
            const backendData = {
                userId: root.credentials.uid,
                envelopeId: envelope.id,
                sentDate: new Date()
            };
            // Add to backend
            const response = await backend.sendAssessment(data.customer._id, backendData);
            log.v('Forward: Updated in database', response);

            const credentials = {
                id: data.customer._id,
                cid: root.credentials.cid,
                key: root.credentials.key,
                bearerToken: response.data.token
            };
            if (root.credentials.testinc) credentials.testinc = root.credentials.testinc;

            const mail = {
                redirect: root.config.idcs[0].redirect,
                logourl: root.config.idcs[0].email.logo,
                buttonColor: root.getColor('button'),
                linkColor: root.getColor('text-accent'),

                url: root.baseUrl + '/assessment',
                lang: 'nb_NO',

                // Notify editor
                subject: 'Sluttrapport ' + data.customer.name,
                header: 'Hei',
                message: '<p>I forbindelse med kundekontroll etter hvitvaskingsloven har vi innhentet informasjon gjennom oppslag i offentlig tilgjengelige registre og bedt kunden gjennomgå og bekrefte informasjon om foretaket, herunder informasjon med hensyn til eierskaps- og kontrollstruktur, reelle rettighetshavere og person som handler på vegne av kunde samt eventuell Politisk Eksponert Person blant disse basert på søk i Trapets. Trapets dekker PEPs i Norden. Dersom reelle rettighetshavere/person som handler på vegne av klient er hjemmehørende utenfor Norden må Partner vurdere behovet for å bekrefte informasjon fra kunde med hensyn til PEP-status ved hjelp av øvrige handlinger.</p><p>Det er videre foretatt søk i sanksjonsregistre fra EU og FN. Søk i øvrige sanksjonsregistre er per nå ikke implementert. &nbsp;Partner må vurdere behov for søk i eventuelle øvrige sanksjonsregistre, herunder OFAC.</p><p>Det er foretatt google-søk for foretaket samt personer identifisert som reelle rettighetshavere og person som handler på vegne av klient ut fra søkeord som fremkommer av klientakseptskjema.&nbsp; Teamet må selv vurdere behovet for google-søk av øvrige relevante personer etter DPM 1540.</p><p>&nbsp;</p><p>Vedlagt er en forenklet rapport av AML-kundekontroll som du må gjennomgå og sjekke at informasjonen stemmer med den kunnskapen du besitter. Compliance Desk i ditt FO har markert i rødt om det er noen spesielle funn. Du trenger ikke bekrefte at det stemmer, men hvis du finner noe feil må du sende en e-post til&nbsp;<a href="mailto:aml@deloitte.no" rel="noopener noreferrer nofollow">aml@deloitte.no</a>&nbsp;med hva som eventuelt må rettes opp i. Hører vi ikke fra deg, antar vi at informasjonen er riktig.</p><p>Fullstendig rapport vil arkiveres i felles arkiv. Det er derfor ikke krav om arkivering og oppbevaring av den forenklede rapporten. &nbsp;</p>',
                comment: forwardData.comment,
                hint: 'Trykk på knappen for å se sluttrapporten',
                footer: '-',
                buttonText: 'ÅPNE SLUTTRAPPORT',
            };

            console.log('ok');

            const persons = JSON.parse(JSON.stringify(data.persons));
            personhelper.stripSensitiveData(persons);

            let dateCreated = tools.dateString(new Date());
            let userId = root.credentials.uid;
            let userName = root.getEmployeeName(root.credentials.uid);

            if (data.customer.report && data.customer.report.envelopeId) {
                const repEnvelope = await root.getEnvelope(data.customer.report.envelopeId);
                console.log('enve', repEnvelope);

                for (const i in repEnvelope.content.documents[0].files.attachments) {
                    const attachment = repEnvelope.content.documents[0].files.attachments[i];
                    console.log('attachment', attachment);
                    const url = await verified.getAttachmentDownloadUrl(repEnvelope, attachment.name);
                    console.log('url', url);
        
                    const base64 = await verified.downloadFile(url);
        
                    const ures = await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, attachment.name);
                    console.log('ures', ures);
                }

                try {
                    dateCreated = tools.dateString(repEnvelope.content.metadata.date);
                    userId = repEnvelope.content.metadata.userId;
                    userName = root.getEmployeeName(userId);
                }catch (exx) {
                    console.log(exx);
                }
            }

            const userdata = {
                mail: mail,
                credentials: credentials,
                metadata: {
                    date: dateCreated,
                    userId: userId,
                    userName: userName,
                    type: 'forward'
                },
                forward: {
                    email: forwardData.email,
                    completed: false
                },
                customer: data.customer,
                brregData: data.brregData,
                manualData: data.manualData,
                aml: data.amlData,
                persons: persons,
                report: data.reportData,
                kycMetadata: data.kycMetadata
            };

            log.v('Forward: Submitting with userdata', userdata);

            await v2lib.setUserData(envelope, userdata);
            await v2lib.setTags(envelope, ['egenerklæring']);
            await v2lib.setName(envelope, `Kopi til partner ${data.customer.name}.pdf`);
            await v2lib.publishEnvelope(envelope);

            const metadata = {
                forward: {
                    date: new Date(),
                    userId: root.credentials.uid,
                    email: forwardData.email,
                    completed: false
                }
            };
            const mres = await api.setMetadata(data.customer._id, metadata);
            log.v('Forward: Updated in database', mres);

            result.updatedCustomer = mres.customer;
            result.success = true;
            log.s('Forward: Forwarded successfully');
        }
        catch(ex) {
            console.error(ex);
            // Show error message to the user
            try {
                ex.info = {
                    source: 'forward report'
                };
            }catch (exx) {}
            root.exceptions.push(ex);
        }
        return result;
    },

};
export default reporthelper;