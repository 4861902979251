<template>
<div class="vrepv1-org">

    <VFRow>
        <VFGroup c6 >
            <VFLabel desc :text="__('common_name')" /><br>
            <VFLabel input :text="client.name" />
        </VFGroup>
        <VFGroup c6 >
            <VFLabel desc :text="__('company_orgnr')" /><br>
            <VFLabel input :text="client.identity" />
        </VFGroup>
        <VFGroup c12 >
            <VFLabel desc :text="__t('company-sanction-result')" /><br>
            <VFLabel input :text="getSanctionText()" />
        </VFGroup>
    </VFRow>

    <div v-if="showAms" class="flw100" style="margin-top: -5px; margin-bottom: 10px;">
        <ReportResultItem
            :icon="'search'"
            :title="__t('ams-name')"
            :status="getAmsStatus()"
            :text="hitText"
        />
    </div>

    <CardActions :actions="actions" @action="onAction" />
    
</div>
</template>
<script>
import v1personHelper from '../v1helpers/v1personHelper';

import VFRow from '../components/form/items/VFRow.vue';
import VFGroup from '../components/form/items/VFGroup.vue';
import VFLabel from '../components/form/items/VFLabel.vue';

import CardActions from '../v1ui/CardActions.vue';
import ReportResultItem from './ReportResultItem.vue';

export default {
    name: 'ReportCompany',
    components: {
        VFRow,
        VFGroup,
        VFLabel,
        CardActions,
        ReportResultItem,
    },
    props: {
        item: { type: Object, default: null },
        client: { type: Object, default: null },
        disabled: { type: Boolean, default: false }
    },
    data() {
        return {
            actions: [
                { action: 'preview', key: 'org', icon: 'file-sent' }
            ],
            showAms: false,
            hitLevel: '',
            hitText: '',
        }
    },
    methods: {
        
        onAction(action) {
            if (action.action == 'preview') {
                this.$emit('preview', action.key);
            }
        },

        getSanctionText() {
            return this.$parent.$parent.$parent.$parent.texts.sanctionMessage[this.$root.locale.key];
        },

        getAmsStatus() {
            if (this.hitLevel == 'ok') return 1;
            if (this.hitLevel == 'some') return 2;
            if (this.hitLevel == 'hits') return 3;
            return 0;
        },

    },
    created() {
        if (this.$root.config.adverseMedia.orgEnabled) {
            this.hitLevel = v1personHelper.getAmsHitLevel(this.client.web);
            this.hitText = v1personHelper.getAmsResultText(this.$root, this.client.web, this.$root.locale.key);
            if (this.hitText == 'error') this.hitText = this.__t('screening-not-completed');
            this.showAms = true;
        }
    }
}
</script>
<style scoped>

.vrepv1-org {
    padding: 20px;
}

</style>