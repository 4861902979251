const ukOrgChouse = {

    getFlow() {
        return {
            key: 'uk-org-chouse',
            name: 'Companies House Organization Flow',
            type: 'org',
            countries: ['gb'],
            tabs: {
                overview: { /* key: 'common_overview', */ icon: 'checklist', label: 'Customer Overview and Checklist' },
                company: { /* key: 'company_company', */ icon: 'company', label: 'Company Overview' },
                ownership: { /* key: 'company_owner_structure', */ icon: 'orgchart', label: 'Persons with Significant Control' },
                persons: { /* key: 'common_persons', */ icon: 'people', label: 'Officer Information' },
            },
            title: {
                en_EN: 'AML Checklist Flow',
                nb_NO: 'Companies House Organisasjonsflyt',
            },
            steps: [
                { key: 'uk-chouse-org' },
                { key: 'hb-chk-risk' },
                { key: 'hb-chk-list-branch' },
                { key: 'hb-chk-qa-branch' },
                { key: 'hb-chk-list-cdd' },
                { key: 'hb-chk-qa-cdd' },
                
            ],
            statuses: [
                {
                    key: 'c-org-c',
                    text: {
                        en_EN: 'Awaiting company search',
                        nb_NO: 'Avventer selskapssøk'
                    },
                    triggers: []
                },
                {
                    key: 'd-risk-a',
                    text: {
                        en_EN: 'Awaiting Risk Rating',
                        nb_NO: 'Awaiting Risk Rating'
                    },
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: false },
                    ]
                },
                {
                    key: 'f-chk-a',
                    text: {
                        en_EN: 'AML Checklist Not Started',
                        nb_NO: 'AML Checklist Not Started'
                    },
                    triggers: [
                        { type: 'value', key: 'hbrisk', value: 'risk', op: 'exists', trg: '', rev: false },
                    ]
                },
                {
                    key: 'f-chk-b',
                    text: {
                        en_EN: 'Awaiting AML Checklist to be sent to CDD HR',
                        nb_NO: 'Awaiting AML Checklist to be sent to CDD HR'
                    },
                    triggers: [
                        { type: 'value', key: 'hbrisk', value: 'risk', op: 'gte', trg: 7, rev: false },
                    ]
                },
                {
                    key: 'f-chk-d',
                    text: {
                        en_EN: 'AML Checklist In Draft',
                        nb_NO: 'AML Checklist In Draft'
                    },
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'sent', rev: true },
                        { type: 'form', key: 'amlc01', trg: 'saved', rev: false },
                    ]
                },
                {
                    key: 'f-chk-e',
                    text: {
                        en_EN: 'Awaiting CDD Quality Control',
                        nb_NO: 'Awaiting CDD Quality Control'
                    },
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'role', op: 'is', value: 'approver', rev: false },
                    ]
                },
                {
                    key: 'f-chk-f',
                    text: {
                        en_EN: 'Awaiting filling by CDD High Risk',
                        nb_NO: 'Awaiting filling by CDD High Risk'
                    },
                    triggers: [
                        { type: 'form', key: 'amlc02', trg: 'role', op: 'is', value: 'approver', rev: false },
                    ]
                },
                {
                    key: 'f-chk-g',
                    text: {
                        en_EN: 'Failed by CDD Quality Control',
                        nb_NO: 'Failed by CDD Quality Control'
                    },
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'role', op: 'is', value: 'provider', rev: false },
                    ]
                },
                {
                    key: 'f-chk-k',
                    text: {
                        en_EN: 'Quality Control Completed by CDD QC',
                        nb_NO: 'Quality Control Completed by CDD QC'
                    },
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'signed', rev: false },
                    ]
                },
                {
                    key: 'f-chk-l',
                    text: {
                        en_EN: 'Completed by CDD High Risk',
                        nb_NO: 'Completed by CDD High Risk'
                    },
                    triggers: [
                        { type: 'form', key: 'amlc02', trg: 'signed', rev: false },
                    ]
                },
                {
                    key: 'h-qa-h',
                    text: {
                        en_EN: 'Quality Assurance Completed by CDD QA',
                        nb_NO: 'Quality Assurance Completed by CDD QA'
                    },
                    triggers: [
                        { type: 'form', key: 'qa01', trg: 'signed', rev: false },
                    ]
                },
            ],
            report: {
                items: []
            }
        };
    },

    //#region Steps -------------------------------------------------------------------------------------------------------
    
    getSearchStep() {
        return {
            key: 'uk-chouse-org',
            name: 'Default BankID AML Company Search',
            actions: [
                {
                    icon: 'search',
                    main: false,
                    action: 'preview',
                    key: 'org',
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: true },
                    ],
                    text: {
                        en_EN: 'Get company data'
                    },
                    hint: {
                        en_EN: 'Get company data and perform a sanction screening on the company'
                    }
                },
                {
                    icon: 'file-sent',
                    main: true,
                    action: 'preview',
                    key: 'org',
                    triggers: [
                        { type: 'org', trg: 'has-data', rev: false },
                    ],
                    text: {
                        en_EN: 'View Company Snapshot'
                    },
                    hint: {
                        en_EN: ''
                    }
                }
            ],
            title: {
                en_EN: 'Company search',
                nb_NO: 'Organisasjonssøk',
            },
            hint: {
                en_EN: ''
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Data fetched from Companies House'
                            },
                            triggers: [
                                { type: 'org', trg: 'has-data', rev: false },
                            ]
                        }
                    ]
                },
                warning: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Some data was not fetched'
                            },
                            triggers: [
                                { type: 'org', trg: 'missing-data', rev: false },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: '{{sanctionMessage}}'
                            },
                            triggers: [
                                { type: 'org', trg: 'has-sanction', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getRiskStep() {
        return {
            key: 'hb-chk-risk',
            name: 'AML Checklist',
            custom: 'risk-popup',
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'custom-risk-popup',
                    key: 'hb',
                    triggers: [
                        /* { type: 'value', key: 'hbrisk', value: 'risk', op: 'exists', trg: '', rev: false }, */
                        { type: 'form', key: 'amlc02', trg: 'sent', rev: true },
                        { type: 'form', key: 'amlc01', trg: 'saved', rev: true },
                        { type: 'form', key: 'amlc01', trg: 'sent', rev: true },
                        { type: 'branch', trg: 'master', rev: true },
                    ],
                    text: {
                        en_EN: 'Set risk'
                    },
                    hint: {
                        en_EN: 'Set risk'
                    }
                },
            ],
            title: {
                en_EN: 'Determine risk',
                nb_NO: 'AML Checklist',
            },
            hint: {
                en_EN: 'This step would need to be completed before starting the checklist.'
            },
            status: {
                progress: {
                    statuses: [ ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Preliminary risk rating: Standard Risk'
                            },
                            triggers: [
                                { type: 'value', key: 'hbrisk', value: 'risk', op: 'is', trg: 2, rev: false },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Preliminary risk rating: Medium Risk'
                            },
                            triggers: [
                                { type: 'value', key: 'hbrisk', value: 'risk', op: 'is', trg: 4, rev: false },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Preliminary risk rating: Medium (high risk factors/additional due diligence)'
                            },
                            triggers: [
                                { type: 'value', key: 'hbrisk', value: 'risk', op: 'is', trg: 7, rev: false },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Preliminary risk rating: High Risk'
                            },
                            triggers: [
                                { type: 'value', key: 'hbrisk', value: 'risk', op: 'is', trg: 9, rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getChecklistStepBranch() {
        return {
            key: 'hb-chk-list-branch',
            name: 'AML Checklist',
            triggerOr: true,
            triggers: [
                { type: 'value', key: 'hbrisk', value: 'risk', op: 'exists', trg: '', rev: true },
                { type: 'value', key: 'hbrisk', value: 'risk', op: 'lte', trg: 4, rev: false },
            ],
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'amlc01',
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'sent', rev: true },
                        { type: 'form', key: 'amlc01', trg: 'saved', rev: true },
                        { type: 'value', key: 'hbrisk', value: 'risk', op: 'exists', trg: '', rev: false },
                        { type: 'branch', trg: 'master', rev: true },
                    ],
                    text: {
                        en_EN: 'Start form'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'amlc01',
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'sent', rev: true },
                        { type: 'form', key: 'amlc01', trg: 'saved', rev: false },
                    ],
                    text: {
                        en_EN: 'Amend Form'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'amlc01',
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'sent', rev: false },
                        { type: 'form', key: 'amlc01', trg: 'signed', rev: true },
                        { type: 'form', key: 'amlc01', trg: 'role', op: 'is', value: 'provider', rev: false },
                        { type: 'branch', trg: 'master', rev: false },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'The recipient will receive a new email with a link to the checklist.'
                    }
                },
                {
                    icon: 'open',
                    main: true,
                    action: 'form-edit',
                    key: 'amlc01',
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'sent', rev: false },
                        { type: 'form', key: 'amlc01', trg: 'signed', rev: true },
                        { type: 'form', key: 'amlc01', trg: 'role', op: 'is', value: 'approver', rev: false },
                        { type: 'branch', trg: 'master', rev: false },
                    ],
                    text: {
                        en_EN: 'Amend Form'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'amlc01',
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'sent', rev: false },
                        { type: 'form', key: 'amlc01', trg: 'signed', rev: true },
                        { type: 'form', key: 'amlc01', trg: 'role', op: 'is', value: 'approver', rev: false },
                        { type: 'branch', trg: 'master', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'The recipient will receive a new email with a link to the checklist.'
                    }
                },
                {
                    icon: 'open',
                    main: true,
                    action: 'form-edit',
                    key: 'amlc01',
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'sent', rev: false },
                        { type: 'form', key: 'amlc01', trg: 'signed', rev: true },
                        { type: 'form', key: 'amlc01', trg: 'role', op: 'is', value: 'provider', rev: false },
                        { type: 'branch', trg: 'master', rev: true },
                    ],
                    text: {
                        en_EN: 'Amend Form'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'file-sent',
                    main: true,
                    action: 'form-view',
                    key: 'amlc01',
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'filled', rev: false },
                    ],
                    text: {
                        en_EN: 'View'
                    },
                    hint: {
                        en_EN: 'Open the checklist in view mode'
                    }
                },
                {
                    icon: 'file-sent',
                    main: false,
                    action: 'preview',
                    key: 'amlc01',
                    triggers: [
                        { type: 'form', key: 'amlc01', trg: 'filled', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                }
            ],
            title: {
                en_EN: 'AML Checklist',
            },
            hint: {
                en_EN: 'Provide the necessary information about the company and send it to CDD for approval/review.'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'DRAFT'
                            },
                            triggers: [
                                { type: 'form', key: 'amlc01', trg: 'saved', rev: false },
                                { type: 'form', key: 'amlc01', trg: 'sent', rev: true },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting filling by {{amlc01-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'amlc01', trg: 'sent', rev: false },
                                { type: 'form', key: 'amlc01', trg: 'filled', rev: true },
                                { type: 'form', key: 'amlc01', trg: 'role', op: 'is', value: 'approver', rev: false },
                            ]
                        },
                        {
                            text: {
                                en_EN: 'Awaiting signing by {{signerName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'amlc01', trg: 'filled', rev: false },
                                { type: 'form', key: 'amlc01', trg: 'signed', rev: true },
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Completed'
                            },
                            triggers: [
                                { type: 'form', key: 'amlc01', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Checklist failed by CDD Quality Control'
                            },
                            triggers: [
                                { type: 'form', key: 'amlc01', trg: 'role', op: 'is', value: 'provider', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getQAStepBranch() {
        return {
            key: 'hb-chk-qa-branch',
            name: 'QA',
            triggerOr: true,
            triggers: [
                { type: 'value', key: 'hbrisk', value: 'risk', op: 'exists', trg: '', rev: true },
                { type: 'value', key: 'hbrisk', value: 'risk', op: 'lte', trg: 4, rev: false },
            ],
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'qa01',
                    triggers: [
                        { type: 'form', key: 'qa01', trg: 'sent', rev: true },
                        { type: 'form', key: 'amlc01', trg: 'filled', rev: false },
                        { type: 'form', key: 'qa01', trg: 'saved', rev: true },
                        { type: 'branch', trg: 'master', rev: false },
                    ],
                    text: {
                        en_EN: 'Start form'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'qa01',
                    triggers: [
                        { type: 'form', key: 'qa01', trg: 'sent', rev: true },
                        { type: 'form', key: 'qa01', trg: 'saved', rev: false },
                        { type: 'branch', trg: 'master', rev: false },
                    ],
                    text: {
                        en_EN: 'Amend Form'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'file-sent',
                    main: false,
                    action: 'preview',
                    key: 'qa01',
                    triggers: [
                        { type: 'form', key: 'qa01', trg: 'filled', rev: false },
                        { type: 'branch', trg: 'master', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                },
                {
                    icon: 'file-sent',
                    main: true,
                    action: 'form-view',
                    key: 'qa01',
                    triggers: [
                        { type: 'form', key: 'qa01', trg: 'filled', rev: false },
                        { type: 'branch', trg: 'master', rev: false },
                    ],
                    text: {
                        en_EN: 'View'
                    },
                    hint: {
                        en_EN: 'Open the checklist in view mode'
                    }
                },
            ],
            title: {
                en_EN: 'QA review (optional)',
            },
            hint: {
                en_EN: 'Perform a QA review of the AML process. This will be performed if the client is randomly selected.'
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Completed by QA'
                            },
                            triggers: [
                                { type: 'form', key: 'qa01', trg: 'filled', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getChecklistStepCDD() {
        return {
            key: 'hb-chk-list-cdd',
            name: 'Checklist CDD',
            triggers: [
                { type: 'value', key: 'hbrisk', value: 'risk', op: 'gte', trg: 7, rev: false },
            ],
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'amlc02',
                    triggers: [
                        { type: 'form', key: 'amlc02', trg: 'sent', rev: true },
                        { type: 'form', key: 'amlc02', trg: 'saved', rev: true },
                        { type: 'branch', trg: 'master', rev: true },
                    ],
                    text: {
                        en_EN: 'Send to CDD High Risk Team'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'file-sent',
                    main: true,
                    action: 'form-view',
                    key: 'amlc02',
                    triggers: [
                        { type: 'form', key: 'amlc02', trg: 'filled', rev: false },
                    ],
                    text: {
                        en_EN: 'View'
                    },
                    hint: {
                        en_EN: 'Open the checklist in view mode'
                    }
                },
                {
                    icon: 'reminder',
                    main: false,
                    action: 'form-reminder',
                    key: 'amlc02',
                    triggers: [
                        { type: 'form', key: 'amlc02', trg: 'sent', rev: false },
                        { type: 'form', key: 'amlc02', trg: 'signed', rev: true },
                    ],
                    text: {
                        en_EN: 'Send reminder'
                    },
                    hint: {
                        en_EN: 'The recipient will receive a new email with a link to the checklist.'
                    }
                },
                {
                    icon: 'open',
                    main: true,
                    action: 'form-edit',
                    key: 'amlc02',
                    triggers: [
                        { type: 'form', key: 'amlc02', trg: 'sent', rev: false },
                        { type: 'form', key: 'amlc02', trg: 'signed', rev: true },
                        { type: 'form', key: 'amlc02', trg: 'role', op: 'is', value: 'approver', rev: false },
                        { type: 'branch', trg: 'master', rev: false },
                    ],
                    text: {
                        en_EN: 'Amend Form'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'file-sent',
                    main: false,
                    action: 'preview',
                    key: 'amlc02',
                    triggers: [
                        { type: 'form', key: 'amlc02', trg: 'filled', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                }
            ],
            title: {
                en_EN: 'AML Checklist',
            },
            hint: {
                en_EN: 'Complete the checklist (CDD).'
            },
            status: {
                progress: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Awaiting filling by {{amlc02-nextName}}'
                            },
                            triggers: [
                                { type: 'form', key: 'amlc02', trg: 'sent', rev: false },
                                { type: 'form', key: 'amlc02', trg: 'filled', rev: true },
                            ]
                        }
                    ]
                },
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Completed by CDD High Risk'
                            },
                            triggers: [
                                { type: 'form', key: 'amlc02', trg: 'signed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    getQAStepCDD() {
        return {
            key: 'hb-chk-qa-cdd',
            name: 'QA CDD',
            triggers: [
                { type: 'value', key: 'hbrisk', value: 'risk', op: 'gte', trg: 7, rev: false },
            ],
            actions: [
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'qa01',
                    triggers: [
                        { type: 'form', key: 'qa01', trg: 'sent', rev: true },
                        { type: 'form', key: 'amlc02', trg: 'filled', rev: false },
                        { type: 'form', key: 'qa01', trg: 'saved', rev: true },
                        { type: 'branch', trg: 'master', rev: false },
                    ],
                    text: {
                        en_EN: 'Start form'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'open',
                    main: true,
                    action: 'form-start',
                    key: 'qa01',
                    triggers: [
                        { type: 'form', key: 'qa01', trg: 'sent', rev: true },
                        { type: 'form', key: 'qa01', trg: 'saved', rev: false },
                        { type: 'branch', trg: 'master', rev: false },
                    ],
                    text: {
                        en_EN: 'Amend Form'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'file-sent',
                    main: true,
                    action: 'form-view',
                    key: 'qa01',
                    triggers: [
                        { type: 'form', key: 'qa01', trg: 'filled', rev: false },
                        { type: 'branch', trg: 'master', rev: false },
                    ],
                    text: {
                        en_EN: 'View'
                    },
                    hint: {
                        en_EN: 'Open the checklist in view mode'
                    }
                },
                {
                    icon: 'file-sent',
                    main: false,
                    action: 'preview',
                    key: 'qa01',
                    triggers: [
                        { type: 'form', key: 'qa01', trg: 'filled', rev: false },
                        { type: 'branch', trg: 'master', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Open the PDF version of the form'
                    }
                }
            ],
            title: {
                en_EN: 'QA review',
            },
            hint: {
                en_EN: 'Perform a QA review of the AML process. Required for high risk.'
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Completed by QA'
                            },
                            triggers: [
                                { type: 'form', key: 'qa01', trg: 'filled', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default ukOrgChouse;