<template>
<div>

    <PropertyItem padded :header="'Language selector properties'" :icon="'locale'">

        <!-- <DesignerGroup c12>
            <DesignerLabel :text="'Placeholder'" />
            <DesignerInput :transDialog="reference.allowTranslation" v-model="input.placeholder" :placeholder="'Not set'" @before="onPropInput('text', 'lang_ph', $event)" @trans="$emit('trans', input.desc)" />
        </DesignerGroup> -->

        <DesignerGroup c6>
            <DesignerLabel :text="'List source'" />
            <DesignerDropdown v-model="input.source" :options="listOptions" @before="onPropInput('drop', 'lang_source', $event)" />
        </DesignerGroup>

        <DesignerGroup c6>
            <DesignerLabel :text="'Default language'" />
            <DesignerDropdown v-model="input.initial" :options="defaultOptions" @before="onPropInput('drop', 'lang_default', $event)" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';

export default {
    name: 'LanguageSelectorProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerDropdown,
    },
    props: {
        input: { type: Object, default: null },
        reference: { type: Object, default: null },
    },
    data() {
        return {
            listOptions: [
                { value: 'form', display: 'Form languages' },
            ],
            defaultOptions: [
                { value: 'system', display: 'System default' },
            ],
        }
    },
    created() {
        this.defaultOptions[0].display += ' (' + this.$root.config.params.defaultLanguage + ')';
    }
}
</script>