<template>
<div class="vf-country">
    
    <VFDropdown
        v-model="countryCode"
        :options="$root.countriesJson"
        :commonOptions="$root.countriesCommon" 
        :listKey="$root.locale.key"
        :displayKey="$root.locale.key"
        :outputKey="'key'"
        :triggerValidation="dropdownValidated"
        @input="handleInput"
    />

</div>
</template>
<script>
// TODO this is not actually used anywhere ???

import VFDropdown from './VFDropdown.vue';

export default {
    name: 'vf-country',
    components: {
        VFDropdown
    },
    props: {
        value: { type: String, default: '' },
        placeholder: { type: String, default: '' },
    },
    data() {
        return {
            countryCode: '',
            dropdownValidated: false
        }
    },
    methods: {
        handleInput(value) {
            if (!this.dropdownValidated) this.dropdownValidated = true;
            this.$emit('input', value);
        }
    },
    mounted() {
        this.countryCode = this.value;
    }
}
</script>
<style scoped>

</style>