<template>
<div>
    
    <FlowDesignerLayout>

        <template v-slot:editor>
            <FlowRendererV2 :flow="flow" />
        </template>

    </FlowDesignerLayout>

</div>
</template>

<script>
import v1flows from '../../v1helpers/v1flows';

import FlowDesignerLayout from './ui/FlowDesignerLayout.vue';
import FlowRendererV2 from './FlowRendererV2.vue';

export default {
    name: 'FlowDesigner',
    components: {
        FlowDesignerLayout,
        FlowRendererV2,
    },
    props: {
        initialFlow: { type: Object, default: null },
    },
    data() {
        return {
            flow: null,
        }
    },
    methods: {

        async onSave(data) {
            
        },

    },
    created() {
        console.log('initialFlow', this.initialFlow);
        this.flow = JSON.parse(JSON.stringify(this.initialFlow));

        for (const i in this.flow.steps) {

            console.log('step', this.flow.steps[i]);

            const step = v1flows.getStep(this.$root, this.flow.steps[i].key);

            this.flow.steps[i] = step;

        }

        console.log('flow', this.flow);

    }
}
</script>