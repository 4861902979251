<template>
<div class="v1dc-item" :class="[
    {'v1dc-c6': c6 },
]">

    <div v-if="title" class="v1dc-desc">
        {{ title }}
    </div>

    <div class="v1dc-value" v-if="value">
        {{ value }}
    </div>

    <div class="v1dc-empty" v-if="!value">
        {{ emptyText }}
    </div>

    <div v-if="source" class="v1dc-source" v-html="source" />

</div>
</template>
<script>

export default {
    name: 'DetailItem',
    props: {
        title: { type: String, default: '' },
        value: { type: String, default: '' },
        source: { type: String, default: '' },
        sourceName: { type: String, default: '' },
        emptyText: { type: String, default: '' },
        c6: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

    }
}
</script>
<style scoped>

.v1dc-item {
    float: left;
    width: 100%;
    margin-bottom: 8px;
}

.v1dc-c6 {
    width: 50%;
}

.v1dc-desc {
    font-size: 13px;
    color: var(--text-weak);
}

.v1dc-value {
    color: var(--text-title);
}

.v1dc-empty {
    color: var(--text-disabled);
}

.v1dc-source {
    font-size: 14px;
    color: var(--text-weak);
}

</style>