<template>
<div>
    
    <PropertyHeader :header="'Form settings'">
        <template v-slot:actions>
            
        </template>
    </PropertyHeader>

    <PropertyItem padded :header="'Naming'" :icon="'input-text'">

        <DesignerGroup c12>
            <DesignerLabel :text="'Form title'" />
            <DesignerInput v-model="reference.title[$root.locale.key]" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'form_title', $event)" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Form name'" />
            <DesignerInput v-model="reference.name[$root.locale.key]" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'form_name', $event)" />
        </DesignerGroup>

    </PropertyItem>

    <PropertyItem padded :header="'Auto save'" :icon="'save'">

        <DesignerGroup c6>
            <DesignerCheckbox v-model="reference.autoSave" :text="'Auto save'" @before="$emit('propinput', 'check', 'form_autosave', $event)" />
        </DesignerGroup>

        <DesignerGroup c6>
            <DesignerCheckbox v-model="reference.canSave" :text="'Save button'" @before="$emit('propinput', 'check', 'form_autosave', $event)" />
        </DesignerGroup>

        <DesignerGroup c6>
            <DesignerCheckbox v-model="reference.flags.autoSaveMessage" :text="'Save message'" @before="$emit('propinput', 'check', 'form_autosave', $event)" />
        </DesignerGroup>

        <DesignerGroup c6>
            <DesignerCheckbox v-model="reference.flags.autoSaveLoaded" :text="'Save loaded hint'" @before="$emit('propinput', 'check', 'form_autosave', $event)" />
        </DesignerGroup>

    </PropertyItem>

    <PropertyItem padded :header="'Static sender'" :icon="'alpha'" v-if="reference.formData.flowType == 'sign'">

        <DesignerGroup c12>
            <DesignerCheckbox v-model="reference.customSender.enabled" :text="'Use static sender'" @before="$emit('propinput', 'check', 'form_custom_sender', $event)" />
        </DesignerGroup>

        <DesignerGroup c6 v-if="reference.customSender.enabled">
            <DesignerLabel :text="'Sender first name'" />
            <DesignerInput v-model="reference.customSender.firstName" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'form_custom_sender', $event)" />
        </DesignerGroup>

        <DesignerGroup c6 v-if="reference.customSender.enabled">
            <DesignerLabel :text="'Sender last name'" />
            <DesignerInput v-model="reference.customSender.lastName" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'form_custom_sender', $event)" />
        </DesignerGroup>

        <DesignerGroup c12 v-if="reference.customSender.enabled">
            <DesignerLabel :text="'Sender email'" />
            <DesignerInput v-model="reference.customSender.email" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'form_custom_sender', $event)" />
        </DesignerGroup>

    </PropertyItem>

    <PropertyItem padded :header="'Email copy'" :icon="'alpha'" v-if="reference.formData.flowType == 'sign' || reference.formData.flowType == 'internal'">

        <DesignerGroup c12 v-if="reference.formData.flowType == 'sign'">
            <DesignerCheckbox v-model="reference.flags.ownerCopy" :text="'Send a copy of the signed document to the owner'" @before="$emit('propinput', 'check', 'form_owner_copy', $event)" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerCheckbox v-model="reference.flags.signedCopy.enabled" :text="'Send a copy to a fixed adress'" @before="$emit('propinput', 'check', 'form_signed_copy', $event)" />
        </DesignerGroup>

        <DesignerGroup c6 v-if="reference.flags.signedCopy.enabled">
            <DesignerLabel :text="'First Name'" />
            <DesignerInput v-model="reference.flags.signedCopy.firstName" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'form_signed_copy', $event)" />
        </DesignerGroup>

        <DesignerGroup c6 v-if="reference.flags.signedCopy.enabled">
            <DesignerLabel :text="'Last Name'" />
            <DesignerInput v-model="reference.flags.signedCopy.lastName" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'form_signed_copy', $event)" />
        </DesignerGroup>

        <DesignerGroup c12 v-if="reference.flags.signedCopy.enabled">
            <DesignerLabel :text="'Email'" />
            <DesignerInput v-model="reference.flags.signedCopy.email" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'form_signed_copy', $event)" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>

<script>
import PropertyHeader from './ui/PropertyHeader.vue';
import PropertyItem from './properties/PropertyItem.vue';
import DesignerButton from './ui/DesignerButton.vue';

import DesignerGroup from './ui/inputs/DesignerGroup.vue';
import DesignerLabel from './ui/inputs/DesignerLabel.vue';
import DesignerInput from './ui/inputs/DesignerInput.vue';
import DesignerCheckbox from './ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from './ui/inputs/DesignerDropdown.vue';

export default {
    name: 'FormProperties',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() {
        return {
            
        }
    },
    methods: {

    },
    created() {

    }
}
</script>
