<template>
    <div>
        <!-- Header with tabs and action buttons -->
        <div class="customer-header" v-if="!loading.main" :class="{'customer-header-notabs': !showTabs}">
            <div class="customer-header-content">
                <vap-row>
                    <vap-group auto noPadding>
                        <vap-label :text="__('common_name')" desc/>
                        <vap-label :text="companyTitleCase(customer.name)" value />
                    </vap-group>
                    <vap-group auto noPadding v-if="customer.source == 'brreg' || customer.source == 'trapets' || customer.source == 'chouse'">
                        <vap-label :text="__('company_orgnr')" desc />
                        <vap-label :text="customer.identity" value />
                    </vap-group>
                    <vap-group auto noPadding v-if="isPersonFlow">
                        <vap-label :text="__('common_dob')" desc />
                        <vap-label :text="singlePerson.info.dateOfBirth == '1800-01-01' ? __('common_unknown') : singlePerson.info.dateOfBirth" value />
                    </vap-group>
                    <vap-group auto noPadding v-if="$root.config.portal.referenceNumber">
                        <vap-label :text="__('customer_reference_short')" desc />
                        <vap-label :text="customer.ref" value />
                    </vap-group>
                    <vap-group auto noPadding v-if="isPersonFlow">
                        <vap-label :text="__('common_citizenship')" desc />
                        <vap-label :text="customer.country == 'XX' ? __('common_unknown') : customer.country" value />
                    </vap-group>
                    <vap-group auto noPadding v-if="customer.flow == 'org-no-unreg'">
                        <vap-label :text="__('company_orgnr')" desc />
                        <vap-label :text="'Legg til org.nr'" value link @clicked="showDialog('upgradeOrg')" />
                    </vap-group>
                    <vap-group auto noPadding>
                        <vap-label :text="__('company_our_rep')" desc />
                        <vap-label :text="associate.name" value />
                    </vap-group>
                    <vap-group auto noPadding v-if="$root.config.portal.departments">
                        <vap-label :text="'Siste LCSP'" desc />
                        <vap-label :text="getPartnerName(customer.partner)" value />
                    </vap-group>
                </vap-row>
            </div>
            <div class="customer-header-actions">
                <vap-icon v-if="$root.config.portal.departments" action :icon="'edit'" @clicked="departmentPopup.show = true"><template v-slot:tooltip><vap-tooltip :text="'Endre'" /></template></vap-icon>
                <vap-icon v-if="isPersonFlow && allowEdit" action :icon="'edit'" @clicked="showDialog('edit-person', singlePerson)"><template v-slot:tooltip><vap-tooltip :text="'Endre'" /></template></vap-icon>
                <vap-icon v-if="!customer.report || $root.allowedActions.deleteCustomer" action :icon="'delete'" @clicked="deletePopup = true"><template v-slot:tooltip><vap-tooltip :text="__('client_delete')" /></template></vap-icon>
            </div>
            <vap-tabs v-if="showTabs" accent v-model="flow.tabModel" :tabs="flow.tabOptions"/>
        </div>

        <!-- Page Content -->
        <div class="aml-page" v-if="!loading.main">

            <div v-if="flow.tabModel.overview" class="mt20">
                <Overview :loading="loading.data"
                    :flow="flow"
                    :events="events"
                    :archivedReports="archivedReports"
                    @receipt="showReceipt"
                    @receiptt="showReceipt2"
                    @tab="goToTab"
                    @aml="performAMLSearch"
                    @kyc="startKYC"
                    @resetkyc="resetKYC"
                    @resendkyc="resendKYC"
                    @imanage="testImanage"
                    @partner="testPartner"
                    @reset="resetCustomer"
                    @kycoptions="showKycOptionsPopup"
                    @personidc="showPersonIDCPopup"
                    @form="goToForm"
                    @migrate="showMigrationPopup"
                    @kar="showKarDialog"
                    @karopt="showKarOptDialog"
                />
            </div>

            <!-- Share Applications -->
            <div v-show="flow.tabModel.shareapp" class="mt20">
                <ShareApplications v-if="flow.tabModel.shareapp" :customer="customer" @shareapp="goToShareApplication" @receipt="showReceipt3" @reminder="onShowReminder" />
            </div>

            <!-- Selskapet -->
            <div v-show="flow.tabModel.company" class="mt20">
<!--                 <Company v-if="customer.source == 'brreg'"
                    :customer="customer"
                    :amlData="amlData"
                    :brregData="brregData"
                    :manualData="manualData"
                /> -->
                <TabCompany v-if="companyData" :company="companyData" :manualData="manualData" />
            </div>

            <!-- Ownership -->
            <div v-if="flow.tabModel.ownership" class="mt20">
                <TabOwnership v-if="companyData" :company="companyData" />
            </div>

            <vap-infocard v-if="(flow.tabModel.persons || flow.tabModel.report) && customer.report" :data="reportCreatedHelp" style="float: left; width: 100%; margin-top: 20px; margin-bottom: -20px;" />

            <!-- Personer -->
            <vap-infocard v-if="flow.tabModel.persons && !customer.aml && customer.source == 'brreg'" :data="waitForAmlHelp" style="float: left; width: 100%; margin-top: 20px; margin-bottom: -20px;" />
            <vap-infocard v-if="flow.tabModel.persons && !kycMetadata.completed && !isLookup && !kycDisabled && !$root.config.aml.person.disableChecks" :data="waitForKycHelp" style="float: left; width: 100%; margin-top: 20px; margin-bottom: -20px;" />
            <div v-show="flow.tabModel.persons" class="mt20" v-if="!loading.data">
                <Persons ref="persons"
                    :persons="personList"
                    :customer="customer"
                    :disabled="customer.report != undefined"
                    @receipt="showReceipt"
                    @reminder="onShowReminder"
                    @reload="onReloadRequested"
                    @contactset="onContactPersonSet"
                />
            </div>

            <!-- Sluttrapport -->
            <div v-show="flow.tabModel.report" class="mt20">
                <Report v-if="!loading.data"
                    :customer="customer"
                    :amlData="amlData"
                    :personList="personList"
                    :reportData="reportData"
                    :kycMetadata="kycMetadata"
                    :brregData="brregData"
                    :manualData="manualData"
                    @report="onReportCreated"
                    @receipt="showReceipt"
                    @check="validateFlowSteps"
                    @reload="onReloadRequested"
                />
            </div>

        </div>

        <ve-popup v-if="deletePopup" :header="__('client_delete') + '?'" :width="300" @close="deletePopup = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>{{ __('client_delete_prompt') }}</label><br><br>
                <label>{{ __('client_delete_hint') }}</label>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_no')" uppercase @clicked="deletePopup = false" />
                    <vap-button :text="__('common_yes')" uppercase @clicked="deleteCustomer(false)" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="reminder.show" :header="__('ov_send_reminder')" :width="600" @close="reminder.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c6>
                            <vap-label desc :text="__('common_first_name')" />
                            <vap-input v-model="reminder.firstName" :rules="'required'" @input="validateReminder" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="__('common_last_name')" />
                            <vap-input v-model="reminder.lastName" :rules="'required'" @input="validateReminder" />
                        </vap-group>
                        <vap-group c12>
                            <vap-label desc :text="__('common_email')" />
                            <vap-input v-model="reminder.email" :rules="'required|email'" @input="validateReminder" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="reminder.show = false" />
                    <vap-button :text="__('ov_send_reminder')" uppercase @clicked="sendReminder" :disabled="!reminder.valid" :working="reminder.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="forward.show" :header="'Send til teamet'" :width="500" @close="forward.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c6>
                            <vap-label desc :text="__('common_first_name')" />
                            <vap-input v-model="forward.firstName" :rules="'required'" @input="validateForward" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="__('common_last_name')" />
                            <vap-input v-model="forward.lastName" :rules="'required'" @input="validateForward" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="__('common_email')" />
                            <vap-input v-model="forward.emailName" :rules="'required'" @input="validateForward" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="' '" />
                            <vap-input :value="forward.domain" disabled noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label desc :text="'Er det noen spesielle funn?'" />
                            <vap-checkbox v-model="forward.findings" :options="yesNoOptions" inline radio noHighlight @input="validateForward" />
                        </vap-group>
                        <vap-group c12 v-if="forward.findings.yes">
                            <vap-label desc :text="'Funn/kommentar'" />
                            <vap-textarea v-model="forward.comment" @input="validateForward" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="forward.show = false" />
                    <vap-button :text="'Send'" uppercase @clicked="forwardReport" :disabled="!forward.valid" :working="forward.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="departmentPopup.show" :header="'Endre LCSP/Avdeling'" :width="500" @close="departmentPopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c12 v-if="$root.config.portal.departments">
                            <vap-label :text="'Avdeling'" desc />
                            <vap-dropdown v-model="departmentPopup.selectedDepartment"
                                :options="departments"
                                :placeholder="__('common_optional')"
                                :listKey="'display'"
                                :displayKey="'display'"
                                :outputKey="'value'"
                                @input="validateDepartmentPopup" />
                        </vap-group>
                        <vap-group c12 v-if="$root.config.portal.departments">
                            <vap-label :text="'LCSP'" desc />
                            <vap-dropdown v-model="departmentPopup.selectedPartner"
                                :options="partners"
                                :placeholder="__('common_optional')"
                                :listKey="'display'"
                                :displayKey="'display'"
                                :outputKey="'value'"
                                @input="validateDepartmentPopup" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="departmentPopup.show = false" />
                    <vap-button :text="__('common_save')" uppercase @clicked="changeDepartment" :disabled="!departmentPopup.valid" :working="departmentPopup.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="pdfload.show" :header="'Laster inn PDF'" :width="300" @close="pdfload.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div class="flw100" style="padding-left: 140px;">
                    <vap-spinner medium />
                </div>
                <vap-button-row alignCenter style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="pdfload.show = false" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="kycOptionsPopup.show" :header="__('kyc_form')" :width="500" @close="kycOptionsPopup.show = false" >
            <div style="padding: 8px 0px;">
                <vap-item fancy padding20 :fancyIcon="'file-search'"
                    :fancyText="__('show_receipt')"
                    @clicked="showReceipt(kycMetadata.envelopeId, 'kyc')">
                </vap-item>
                <vap-item fancy padding20 :fancyIcon="'reminder'" :disabled="kycMetadata.signed"
                    :fancyText="__('ov_kyc_change_recipient')"
                    @clicked="onSendKycReminderClicked">
                </vap-item>
                <vap-item fancy padding20 :fancyIcon="'file-sent'"
                    :fancyText="__('ov_kyc_resend')"
                    @clicked="resendKYC">
                </vap-item>
                <vap-item fancy padding20 :fancyIcon="'delete'"
                    :fancyText="__('ov_kyc_reset')"
                    @clicked="showKycWrongRecipient">
                </vap-item>
            </div>
        </ve-popup>

        <ve-popup v-if="kycWrongRecipientPopup.show" :header="__('ov_kyc_reset_header')" :width="400" @close="kycWrongRecipientPopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>{{ __('ov_kyc_reset_prompt') }}</label><br><br>
                <label>{{ __('ov_kyc_reset_hint') }}</label>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_no')" uppercase @clicked="kycWrongRecipientPopup.show = false" />
                    <vap-button :text="__('common_yes')" uppercase @clicked="onKycWrongRecipient" :working="kycWrongRecipientPopup.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="personIDCPopup.show" :header="'ID-kontroll'" :width="600" @close="personIDCPopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <div>
                    <vap-row>
                        <vap-group c6>
                            <vap-label desc :text="__('common_first_name')" />
                            <vap-input v-model="personIDCPopup.firstName" :rules="'required'" @input="validatePersonIDCPopup" />
                        </vap-group>
                        <vap-group c6>
                            <vap-label desc :text="__('common_last_name')" />
                            <vap-input v-model="personIDCPopup.lastName" :rules="'required'" @input="validatePersonIDCPopup" />
                        </vap-group>
                        <vap-group c12>
                            <vap-label desc :text="__('common_email')" />
                            <vap-input v-model="personIDCPopup.email" :rules="'required|email'" @input="validatePersonIDCPopup" />
                        </vap-group>
                    </vap-row>
                </div>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="personIDCPopup.show = false" />
                    <vap-button :text="'Send epost'" uppercase @clicked="onSendPersonIDC" :disabled="!personIDCPopup.valid" :working="personIDCPopup.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="migrationPopup.show" :header="'Migrering'" :width="600" @close="migrationPopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>{{ 'Kunden vil bli oppdatert for å støtte siste versjon av portalen.' }}</label><br><br>
                <label>{{ 'Etter migrering vil kunden bli flyttet fra "gamle kunder" til "kunder".' }}</label>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="migrationPopup.show = false" />
                    <vap-button :text="'Migrer kunde'" uppercase @clicked="migrateCustomer" :working="migrationPopup.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <PopupViewer v-if="previewVisible"
            :envelopeId="previewEnvelopeId"
            :blob="previewRawData"
            :filename="previewFilename"
            @close="hideReceipt"
            :showAttachment="previewAttachment"
        />
        <PreviewPopup v-if="preview2Visible"
            :envelopeId="preview2EnvelopeId"
            :blob="preview2RawData"
            :filename="preview2Filename"
            @close="hideReceipt"
            :showAttachment="preview2Attachment"
            :attachmentName="preview2AttachmentName"
            :viewOptions="preview2Options"
        />

        <IManage v-if="showIManage" @close="showIManage = false" :customer="customer" :person="singlePerson" :fileData="iManageFileData" />

        <OverviewDialogs ref="dialogs"
            :dialogs="dialogs"
            @receipt="showReceipt3"
            @form="goToForm"
        />

        <FormPopup v-if="formPopup.show" :isV0="true" :clientId="customer._id" :initialFormKey="formPopup.formKey" @close="formPopup.show = false" @reload="onReloadRequested" />

        <ve-popup v-if="karOptPopup.show" :header="__('kyc_form')" :width="500" @close="karOptPopup.show = false" >
            <div style="padding: 8px 0px;">
                <vap-item fancy padding20 :fancyIcon="'file-search'"
                    :fancyText="__('show_receipt')"
                    @clicked="showKarReceipt">
                </vap-item>
                <vap-item fancy padding20 :fancyIcon="'delete'"
                    :fancyText="'Tilbakestill'"
                    @clicked="showResetKarDialog">
                </vap-item>
            </div>
        </ve-popup>

        <ve-popup v-if="karResetPopup.show" :header="'Tilbakestill'" :width="600" @close="karResetPopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>{{ 'KAR-søket vil bli tilbakestilt og det kan gjøres et nytt søk.' }}</label><br><br>
                <label>{{ 'Kvittering på tidligere søk vil ligge i Verified-arkivet.' }}</label>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" uppercase @clicked="karResetPopup.show = false" />
                    <vap-button :text="'Tilbakestill'" uppercase @clicked="resetKar" :working="karResetPopup.working" />
                </vap-button-row>
            </div>
        </ve-popup>

    </div>
</template>
<script>
import flows from '../flows';
import backend from '../backend';
import api from '../portalapi';
import verified from '../verified';
import receipts from '../receipts';
import kychelper from '../helpers/kychelper';
import clienthelper from '../helpers/clienthelper';
import personhelper from '../helpers/personhelper';
import reporthelper from '../helpers/reporthelper';
import validations from '../validations';
import tools from '../tools';
import aml from '../aml';
import log from '../log';
import dataModel from '../dataModel';
import dataNorway from '../dataNorway';

import v1migration from '../v1helpers/v1migration';

import Overview from '../components/customer/OverviewNew';
import OverviewDialogs from '../components/customer/OverviewDialogs';
import Company from '../components/customer/Company';
import Persons from '../components/customer/Persons';
import Report from '../components/customer/Report';
import PopupViewer from '../components/documents/PopupViewer';
import PreviewPopup from '../components/documents/PreviewPopup';
import IManage from '../components/IManage';
import ShareApplications from '../custom/shareapp/ShareApplications';
import TabCompany from '../components/customer/TabCompany';
import TabOwnership from '../components/customer/TabOwnership';

import FormPopup from '../components/form/FormPopup.vue';

export default {
    name: 'Client',
    components: {
        Overview,
        OverviewDialogs,
        Company,
        Persons,
        Report,
        PopupViewer,
        PreviewPopup,
        IManage,
        ShareApplications,
        TabCompany,
        TabOwnership,
        FormPopup
    },
    data() {
        return {
            loading: { main: true, data: true },
            customer: {},
            flow: {},
            showTabs: true,
            associate: {},
            partners: [],
            events: [],
            archivedReports: [],
            brregData: {},
            manualData: {},
            amlData: {},
            amlCache: {
                useCache: false,
                aml: null,
                brreg: null
            },
            companyData: null,
            amlWarning: '',
            kycDisabled: false,
            kycMetadata: {
                completed: false, aborted: false, signed: false, expired: false,
                editor: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
                aborter: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
                nextSigner: { id: '', firstName: '', lastName: '', email: '', displayName: '' },
                signatories: [],
                signedNames: '',
                expirationDate: undefined,
                envelopeId: '', documentId: '', fileUid: ''
            },
            sources: { aml: [], kyc: [], pep: [], id: [], manual: [] },
            personList: [],
            singlePerson: {},
            singlePersonIdcIndex: 0,
            selectedPersonCount: 0,
            checkExistingResponse: undefined,
            roles: {
                nopersons: false,
                pending: false,
                completed: false,
                searchCount: 0,
                idcCount: 0,
                keywordCount: 0
            },
            counts: { vif: true, searchCount: 0, okCount: 0, pepCount: 0, sanctionCount: 0, sentCount: 0, completeCount: 0 },
            reportData: {},
            deletePopup: false,
            previewVisible: false,
            previewEnvelopeId: '',
            previewFilename: '',
            previewRawData: '',
            previewAttachment: true,

            preview2Visible: false,
            preview2EnvelopeId: '',
            preview2Filename: '',
            preview2RawData: '',
            preview2Attachment: true,
            preview2AttachmentName: '',
            preview2Options: null,

            checklist: {},

            reminder: { show: false, working: false, firstName: '', lastName: '', email: '', envelopeId: '', recipientId: '', valid: false, changed: false },
            reminderOriginal: { firstName: '', lastName: '', email: '' },
            waitForKycHelp: [{ icon: 'info', key: 'pep_kyc_info' }],
            waitForAmlHelp: [{ icon: 'info', key: 'pep_aml_info' }],
            reportCreatedHelp: [{ icon: 'locked', key: 'rep_created_info' }],
            showIManage: false,
            iManageFileData: null,
            forward: { show: false, working: false, valid: false, firstName: '', lastName: '', email: '', emailName: '', domain: '@deloitte.no', findings: { yes: false, no: false }, comment: '' },
            pdfload: { show: false },
            departments: [],
            partners: [],
            departmentPopup: { show: false, working: false, valid: false, selectedDepartment: '', selectedPartner: '' },
            yesNoOptions: {
                yes: { key: 'common_yes' },
                no: { key: 'common_no' },
            },
            kycOptionsPopup: { show: false },
            kycWrongRecipientPopup: { show: false, working: false },
            allowEdit: false,
            isPersonFlow: false,
            isLookup: false,
            shareholderBookConfirmed: true,
            shareholderBookUploaded: false,
            shareholderBookEnvelopeId: '',
            shareholderBookMultiple: false,
            externalKYCID: '',
            personIDCPopup: { show: false, working: false, valid: false, firstName: '', lastName: '', email: '' },
            dialogs: {
                reminder: { show: false, working: false, firstName: '', lastName: '', email: '', envelopeId: '', recipientId: '', valid: false, changed: false },
                pepSearch: { show: false, working: false }
            },
            suit: {
                envelopeId: ''
            },
            suitEnvelope: null,
            migrationPopup: { show: false, working: false, valid: false, params: null },
            formPopup: { show: false, formKey: '' },
            karResult: {},
            karOptPopup: { show: false },
            karResetPopup: { show: false, working: false },
        }
    },
    methods: {
        
        showMigrationPopup() {
            this.migrationPopup.show = true;
        },

        async migrateCustomer() {
            /* this.migrationPopup.working = true;
            const loadResult = await v1migration.loadV0Client(this.$root, this);
            const migrateResult = await v1migration.migrateClient(this.$root, loadResult);
            const newId = await v1migration.addToDatabase(loadResult, migrateResult);
            this.migrationPopup.working = false;
            this.migrationPopup.show = false;
            if (newId) this.$parent.navigate({ path: '/v1client', query: { id: newId } }); */
        },

        showKarDialog(extraData) {
            let existingData = {
                companyData: this.companyData,
                customer: this.customer,
                oldKycMetadata: this.kycMetadata,
                oldSinglePerson: this.singlePerson,
                isV0: true
            };

            if (extraData) existingData = { ...existingData, ...extraData };
            this.$root.formData = JSON.stringify(existingData);

            this.formPopup.formKey = 'kar99';
            this.formPopup.show = true;
        },

        showKarOptDialog() {
            this.karOptPopup.show = true;
        },

        showKarReceipt() {
            this.showReceipt2(this.karResult.envelopeId, 'kar', null);
            this.karOptPopup.show = false;
        },

        showResetKarDialog() {
            this.karResetPopup.show = true;
            this.karOptPopup.show = false;
        },

        async resetKar() {
            this.karResetPopup.working = true;

            await api.resetKar(this.customer._id);

            const response = await api.getCustomer(this.customer._id);

            this.karResetPopup.working = false;
            this.karResetPopup.show = false;
            this.onReloadRequested(response.customer);
        },

        goToForm(key, extraData) {
            let existingData = {
                companyData: this.companyData,
                customer: this.customer,
                oldKycMetadata: this.kycMetadata,
                oldSinglePerson: this.singlePerson
            };
            console.log('extra', extraData);
            if (extraData) existingData = { ...existingData, ...extraData };
            this.$root.formData = JSON.stringify(existingData);
            this.$parent.navigate({ path: '/form/dev/' + key });
            //this.$parent.navigate({ path: '/form/dev/' + 'kyc' });
        },

        showDialog(dialog, data, index, boolValue) {

            if (dialog == 'book-options') {
                this.$refs.dialogs.showShareholderBookOptionsDialog();
            }else if (dialog == 'idc-options') {
                this.$refs.dialogs.showIDCOptionsDialog(data, index, boolValue);
            }else if (dialog == 'rep-options') {
                this.$refs.dialogs.showReportOptionsDialog();
            }else if (dialog == 'upload-kyc') {
                this.$refs.dialogs.showUploadKycDialog();
            }else if (dialog == 'reference') {
                this.$refs.dialogs.showReferenceDialog();
            }else if (dialog == 'edit-person') {
                this.$refs.dialogs.showChangePersonDialog(data);
            }else {
                this.$refs.dialogs.showUpgradeOrgDialog();
            }

        },

        showShareholderBook(name) {
            if (this.kycMetadata.shareholderBookProvided) {
                if (this.kycMetadata.shareholderBooks.length > 1) {
                    this.showReceipt3(this.kycMetadata.envelopeId, 'Akseeierbok', true, name);
                }else {
                    this.showReceipt3(this.kycMetadata.envelopeId, 'Akseeierbok', true);
                }
            }else if (this.shareholderBookUploaded) {
                this.showReceipt3(this.shareholderBookEnvelopeId, 'Akseeierbok', true);
            }
        },

        goToShareApplication() {
            this.$parent.navigate({ path: '/shareapp', query: { id: this.customer._id } });
        },

        //----- Actions ---------------------------------------------------------

        showPersonIDCPopup() {

            if (this.personList.length > 0) {
                this.personIDCPopup.firstName = this.personList[0].info.firstName;
                this.personIDCPopup.lastName = this.personList[0].info.lastName;
            }

            this.personIDCPopup.show = true;
        },

        validatePersonIDCPopup() {
            const result = validations.validateEmail(this.personIDCPopup.email);
            this.personIDCPopup.valid = result.valid;
        },

        async onSendPersonIDC() {
            this.personIDCPopup.working = true;
            if (this.personList.length == 0) return;
            const index = 0;
            this.personList[index].contact.email = this.personIDCPopup.email;

            console.log(this.personList[index]);

            if (this.personList[index].uimodel.didControl) return;

            const existingData = {
                customerId: this.customer._id,
                customerName: this.customer.name,
                brreg: this.brregData,
                companyAml: this.amlData
            };
            const result = await receipts.sendIdControl(this.$root, this.personList[index], existingData, false);

            this.personIDCPopup.working = false;
            this.personIDCPopup.show = false;
            this.onReloadRequested(result.updatedCustomer);
        },

        onContactPersonSet(person) {

        },

        showKycOptionsPopup() {
            this.kycOptionsPopup.show = true;
        },

        onSendKycReminderClicked() {
            this.kycOptionsPopup.show = false;
            const reminderData = {
                envelopeId: this.customer.kyc.envelopeId,
                firstName: '', lastName: '', email: '', recipientId: ''
            }
            if (this.kycMetadata.completed) {
                reminderData.firstName = this.kycMetadata.nextSigner.firstName;
                reminderData.lastName = this.kycMetadata.nextSigner.lastName;
                reminderData.email = this.kycMetadata.nextSigner.email;
                reminderData.recipientId = this.kycMetadata.nextSigner.id;
            }else {
                reminderData.firstName = this.kycMetadata.editor.firstName;
                reminderData.lastName = this.kycMetadata.editor.lastName;
                reminderData.email = this.kycMetadata.editor.email;
                reminderData.recipientId = this.kycMetadata.editor.id;
            }
            this.onShowReminder(reminderData);
        },

        showKycWrongRecipient() {
            this.kycOptionsPopup.show = false;
            this.kycWrongRecipientPopup.show = true;
        },

        async onKycWrongRecipient() {
            this.kycOptionsPopup.show = false;
            this.kycWrongRecipientPopup.working = true;

            await api.archiveData(this.customer._id, 'kyc', 'wrongRecipient');
            const response = await api.updateCustomer(this.customer._id, { status: 'f-kyc-o', expires: -2 });

            this.kycWrongRecipientPopup.working = false;
            this.kycWrongRecipientPopup.show = false;
            this.onReloadRequested(response.customer);
        },

        showDepartmentPopup() {
            this.departmentPopup.show = true;
        },

        validateDepartmentPopup() {
            let valid = true;
            if (!this.departmentPopup.selectedDepartment) valid = false;
            if (!this.departmentPopup.selectedPartner) valid = false;
            this.departmentPopup.valid = valid;
        },

        async changeDepartment() {
            this.departmentPopup.working = true;
            const response = api.setDepartment(this.customer._id, Number(this.departmentPopup.selectedDepartment), Number(this.departmentPopup.selectedPartner));
            this.departmentPopup.working = false;
            this.departmentPopup.show = false;
            this.onReloadRequested(response.customer);
        },

        async performAMLSearch() {
            if (this.$root.config.portal.usev2receipts || this.$root.config.aml.company.ownership) {
                this.performAMLSearchV2();
                return;
            }
            const existingData = {
                customer: this.customer,
                brregData: this.brregData,
                nextStatus: this.isLookup ? 'c-aml-c' : 'f-kyc-a'
            };
            const result = await receipts.doAmlSearch(this.$root, this.$parent, existingData, false);
            this.customer = result.customer;
            this.loadAllData();
        },
        async performAMLSearchV2() {
            const existingData = {
                customer: this.customer,
                brregData: this.brregData,
                nextStatus: this.isLookup ? 'c-aml-c' : 'f-kyc-a'
            };
            const result = await receipts.doAmlSearch2(this.$root, this.$parent, existingData, false);
            this.amlCache.useCache = true;
            this.amlCache.aml = result.amlData;
            this.amlCache.brreg = this.brregData;
            this.customer = result.customer;
            await this.loadAllData();
            this.goToTab('company');
        },

        startKYC(key, eid) {
            if (this.$root.debug.testForm) {
                const existingData = {
                    companyData: this.companyData
                };
                this.$root.formData = JSON.stringify(existingData);
                this.$parent.navigate({ path: '/form/dev/kyc01' });
                return;
            }
            if (!key || key == true) {
                if (this.$root.config.kycs.length > 1) {
                    this.kycPopup = true;
                }else {
                    key = this.$root.config.kycs[0].key;
                    this.startKYC(key);
                }
            }else {
                if (eid) {
                    this.$parent.navigate({ path: '/kyc', query: { key: key, id: this.customer._id, eid: eid } });
                }else {
                    this.$parent.navigate({ path: '/kyc', query: { key: key, id: this.customer._id } });
                }
            }
        },

        async resetKYC() {
            await api.archiveData(this.customer._id, 'kyc', 'reset');
        },

        async resendKYC() {
            // Load data from KYC-envelope
            const envelope = await this.$root.getEnvelope(this.customer.kyc.envelopeId);
            let key = 'kyc1';
            if (envelope.userdata.config && envelope.userdata.config.key) key = envelope.userdata.config.key;
            this.startKYC(key, this.customer.kyc.envelopeId);
        },

        goToTab(tab) {
            for (let key in this.flow.tabModel) {
                this.flow.tabModel[key] = false;
                if (key == tab) this.flow.tabModel[key] = true;
            }
        },

        async onReportCreated() {
            this.goToTab('overview');
        },

        onShowReminder(data) {
            this.reminderOriginal.firstName = data.firstName;
            this.reminderOriginal.lastName = data.lastName;
            this.reminderOriginal.email = data.email;
            this.reminder.firstName = data.firstName;
            this.reminder.lastName = data.lastName;
            this.reminder.email = data.email;
            this.reminder.envelopeId = data.envelopeId;
            this.reminder.recipientId = data.recipientId;
            this.validateReminder();
            this.reminder.show= true;
        },

        validateReminder() {
            const result = validations.validateEmail(this.reminder.email);
            this.reminder.valid = result.valid;
            if (this.reminder.firstName != this.reminderOriginal.firstName 
            || this.reminder.lastName != this.reminderOriginal.lastName 
            || this.reminder.email != this.reminderOriginal.email) {
                this.reminder.changed = true;
            }else {
                this.reminder.changed = false;
            }
        },

        async sendReminder() {
            this.reminder.working = true;
            if (this.reminder.changed) {
                await verified.editRecipient(this.reminder.envelopeId, this.reminder.recipientId, this.reminder.firstName, this.reminder.lastName, this.reminder.email);
            }
            await verified.sendReminder(this.reminder.envelopeId, this.reminder.recipientId, 'message');
            this.reminder.working = false;
            this.reminder.show = false;
        },

        validateForward() {
            this.forward.valid = true;
            
            if (!this.forward.firstName) this.forward.valid = false;
            if (!this.forward.lastName) this.forward.valid = false;

            if (this.$root.debug.local) this.forward.domain = '@verified.eu';
            this.forward.email = this.forward.emailName + this.forward.domain;

            if (!this.forward.findings.yes && !this.forward.findings.no) this.forward.valid = false;

            if (this.forward.findings.yes && !this.forward.comment) this.forward.valid = false;

            const result = validations.validateEmail(this.forward.email);
            if (!result.valid) this.forward.valid = false;
        },

        async forwardReport() {
            this.forward.working = true;
            const data = {
                customer: this.customer,
                brregData: this.brregData,
                manualData: this.manualData,
                aml: this.amlData,
                persons: this.personList,
                reportData: this.reportData,
                kycMetadata: this.kycMetadata,
            };
            const result = await reporthelper.forwardReport(this.$root, data, this.forward);
            if (!result.success) return;
            this.onReloadRequested(result.updatedCustomer);
            this.forward.working = false;
            this.forward.show = false;
        },

        async deleteCustomer() {
            this.deletePopup = false;
            this.$parent.setLoading(true, this.__('loading_deleting_customer') + '...');
            await api.deleteCustomer(this.customer._id);
            this.$router.push({ path: '/' });
            this.$parent.setLoading(false);
        },

        onPersonsRefresh(customer) {
            if (customer) this.customer = customer;
            this.$nextTick(() => {
                this.$refs.persons.processPersons();
            });
        },

        onReloadRequested(customer) {
            log.w('Client: Reload requested with customer', customer);
            if (customer) this.customer = customer;
            this.$nextTick(() => {
                log.w('Client: Reloading with customer', this.customer);
                this.loadAllData();
            });
        },

        async resetCustomer() {
            this.$parent.setLoading(true, this.__('loading_archiving') + '...');
            const response = await api.archiveData(this.customer._id, 'all', 'newreport');
            console.log('response', response);
        
            this.customer = response.customer;
            console.log('customer', this.customer);

            this.$parent.setLoading(false);
            location.reload();
        },

        //-----------------------------------------------------------------------

        //----- Receipt Preview -------------------------------------------------

        async showReceipt(envelopeId, type, testData) {
            this.kycOptionsPopup.show = false;
            if (type == 'rep' && this.$root.config.report.merged) {
                this.showReport();
                return;
            }
            this.preview2RawData = undefined;
            this.showReceipt2(envelopeId, type, testData);
        },

        async showReceipt2(envelopeId, type, testData) {
            history.pushState(null, document.title, location.href);
            log.v('ShowReceipt2: eid=' + envelopeId + ', type=' + type);
            this.preview2Attachment = false;
            /* if (type == 'aml') this.preview2Attachment = true; */
            this.preview2EnvelopeId = envelopeId;

            this.preview2Options = null;
            if (type == 'rep' && this.$root.config.report.mergedDownload) {
                this.preview2Options = {
                    mergedDownload: true
                };
            }
            if (type == 'aml') {
                this.preview2Options = {
                    viewIndex: 1
                };
            }

            this.preview2Visible = true;
            switch (type) {
                case 'aml': this.preview2Filename = 'Firmarapport ' + this.customer.name; break;
                case 'kyc': this.preview2Filename = 'Egenerklæring ' + this.customer.name; break;
                case 'pep': this.preview2Filename = 'PEP/Sanksjonssøk ' + this.customer.name; break;
                case 'id': this.preview2Filename = 'ID-kontroll ' + this.customer.name; break;
                case 'rep': this.preview2Filename = 'Sluttrapport ' + this.customer.name; break;
                case 'kar': this.preview2Filename = 'KAR-oppslag ' + this.customer.name; break;
                default: this.preview2Filename = 'filename';
            }
        },

        async showReceipt3(envelopeId, filename, attachment, name) {
            history.pushState(null, document.title, location.href);
            log.v('ShowReceipt3: eid=' + envelopeId + ', filename=' + filename);
            this.preview2Attachment = attachment;
            this.preview2AttachmentName = name;
            this.preview2EnvelopeId = envelopeId;
            this.preview2Visible = true;
            this.preview2Filename = filename;
        },

        hideReceipt() {
            history.go(-1);
            this.previewVisible = false;
            this.preview2Visible = false;
        },

        async downloadCompleteReport() {
            const config = reporthelper.getReportConfig(this.$root);
            config.ssn = true;
            config.appendAttachments = true;
            config.externalRisk = {
                enabled: this.$root.config.report.uploadExternalRisk
            };
            config.shareholderBook = {
                enabled: this.kycMetadata.shareholderBookProvided || this.shareholderBookUploaded
            };
            config.externalKYC = {
                enabled: this.kycDisabled
            };
            const data = {
                customer: this.customer,
                brregData: this.brregData,
                manualData: this.manualData,
                aml: this.amlData,
                persons: this.personList,
                reportData: this.reportData,
                kycMetadata: this.kycMetadata,
                externalRisk: {
                    provided: true
                },
                shareholderBook: {
                    provided: this.kycMetadata.shareholderBookProvided || this.shareholderBookUploaded,
                    envelopeId: this.shareholderBookEnvelopeId || this.kycMetadata.envelopeId
                },
                externalKYC: {
                    provided: this.customer.metadata && this.customer.metadata.uploadKycData,
                    envelopeId: this.customer.metadata && this.customer.metadata.uploadKycData ? this.customer.metadata.uploadKycData.envelopeId : ''
                }
            };
            return reporthelper.downloadPDF(this.$root, data, config);
        },

        async showReport() {
            this.pdfload.show = true;
            this.preview2RawData = await this.downloadCompleteReport();
            history.pushState(null, document.title, location.href);
            this.preview2Filename = 'Sluttrapport ' + this.customer.name;
            this.preview2Visible = true;
            this.pdfload.show = false;
        },

        async testImanage() {
            this.showIManage = true;
        },

        testPartner() {
            this.forward.show = true;
        },

        //-----------------------------------------------------------------------

        validateFlowSteps() {
            this.checkControlStatus();

            for (const i in this.flow.steps) {

                if (this.flow.steps[i].key == 'company_aml') {

                    if (this.customer.aml) this.flow.steps[i].status = 5;

                    if (this.amlData && this.amlData.responseWarnings) {
                        this.flow.steps[i].status = 8;
                        this.amlWarning = this.amlData.responseWarnings[0].message + ' (' + this.amlData.responseWarnings[0].property + ')';
                    }

                    if (this.amlData && this.amlData.sanction && this.amlData.sanction.status == 'SANCTION') {
                        this.flow.steps[i].status = 9;
                    }

                }else if (this.flow.steps[i].key == 'company_se') {

                    this.flow.steps[i].status = 5;

                }else if (this.flow.steps[i].key == 'company_uk') {

                    this.flow.steps[i].status = 5;

                }else if (this.flow.steps[i].key == 'company_uk_checklist') {

                    if (this.checklist.sent) {
                        if (this.checklist.completed) {
                            this.flow.steps[i].status = 5;
                        }else {
                            this.flow.steps[i].status = 3;
                        }
                    }else {
                        this.flow.steps[i].status = 0;
                    }

                }else if (this.flow.steps[i].key == 'company_uk_audit') {

                    if (this.checklist.completed) {
                        console.log('CHK', this.checklist);
                        if (this.checklist.qaNeeded) {
                            this.flow.steps[i].status = 8;
                        }else {
                            this.flow.steps[i].status = 3;
                        }
                    }else {
                        this.flow.steps[i].status = 0;
                    }


                }else if (this.flow.steps[i].key == 'company_kyc') {

                    if (this.customer.kyc) {
                        if (this.kycMetadata.completed) {
                            if (this.kycMetadata.signed) {
                                this.flow.steps[i].status = 5;
                            }else {
                                this.flow.steps[i].status = 3;
                            }
                        }else {
                            this.flow.steps[i].status = 3;
                        }
                        if (this.kycMetadata.aborted) this.flow.steps[i].status = 9;
                        if (this.kycMetadata.expired && !this.kycMetadata.signed) this.flow.steps[i].status = 9;
                    }else {
                        this.flow.steps[i].status = 0;
                    }

                }else if (this.flow.steps[i].key == 'company_per') {

                    if (this.roles.completed) {
                        this.flow.steps[i].status = 5;
                    }else if (this.roles.pending) {
                        this.flow.steps[i].status = 3;
                    }else {
                        this.flow.steps[i].status = 0;
                    }

                }else if (this.flow.steps[i].key == 'company_rep') {

                    if (this.customer.report) this.flow.steps[i].status = 5;

                }else if (this.flow.steps[i].key == 'custom_imanage') {

                    if (this.customer.metadata && this.customer.metadata.imanage) this.flow.steps[i].status = 5;

                }else if (this.flow.steps[i].key == 'custom_forward') {

                    if (this.customer.metadata && this.customer.metadata.forwards && this.customer.metadata.forwards.length > 0) this.flow.steps[i].status = 5;

                }else if (this.flow.steps[i].key == 'company_book') {

                    this.flow.steps[i].hidden = !this.kycMetadata.editorMadeChanges && this.customer.flow == 'org-no-baml';

                    if (this.customer.metadata && this.customer.metadata.shBookData) {
                        this.flow.steps[i].status = 5;
                        this.shareholderBookConfirmed = true;
                        if (this.customer.metadata.shBookData.envelopeId) {
                            this.shareholderBookUploaded = true;
                            this.shareholderBookEnvelopeId = this.customer.metadata.shBookData.envelopeId;
                        }
                    }else {
                        this.flow.steps[i].status = 3;
                        this.shareholderBookConfirmed = false;
                    }

                }else if (this.flow.steps[i].key == 'snn_bank_kyc' || this.flow.steps[i].key == 'snn_bank_kyc2') {

                    if (this.customer.metadata && this.customer.metadata.uploadKycData) {
                        this.externalKYCID = this.customer.metadata.uploadKycData.envelopeId;
                        this.flow.steps[i].status = 5;
                    }else {
                        this.flow.steps[i].status = 0;
                    }


                }else if (this.flow.steps[i].key == 'person_idc') {

                    if (this.singlePerson.control) {
                        if (this.singlePerson.control.completed) {
                            this.flow.steps[i].status = 5;
                        }else if (this.singlePerson.control.inviteSent) {
                            this.flow.steps[i].status = 3;
                        }else {
                            this.flow.steps[i].status = 0;
                        }
                    }else {
                        this.flow.steps[i].status = 0;
                    }

                }else if (this.flow.steps[i].key == 'person_pep') {
                    
                    if (this.singlePerson.search) {
                        if (this.singlePerson.search.completed) {
                            this.flow.steps[i].status = 5;
                        }else {
                            this.flow.steps[i].status = 0;
                        }
                    }else {
                        this.flow.steps[i].status = 0;
                    }

                    

                }else if (this.flow.steps[i].key == 'person_rep') {
                    
                    if (this.customer.report) this.flow.steps[i].status = 5;

                }else if (this.flow.steps[i].key == 'nb_reference') {
                    
                    if (this.customer.status == 'b-per-x') {
                        this.flow.steps[i].status = 5;
                    }else {
                        this.flow.steps[i].status = 0;
                    }

                }else if (this.flow.steps[i].key == 'suitability') {
                    
                    if (this.suit.envelopeId) {
                        this.flow.steps[i].status = 5;
                    }else {
                        this.flow.steps[i].status = 0;
                    }

                }else if (this.flow.steps[i].key == 'company_kar') {
                    
                    if (this.customer.kar && this.customer.kar.done) {

                        this.karResult = {
                            verified: this.customer.kar.verified,
                            statusCode: this.customer.kar.statusCode,
                            message: this.customer.kar.message,
                            envelopeId: this.customer.kar.envelopeId,
                        };

                        if (this.customer.kar.verified == 'JA') {
                            this.flow.steps[i].status = 5;
                        }else {
                            this.flow.steps[i].status = 9;
                        }
                        
                    }else {
                        this.flow.steps[i].status = 0;
                    }

                }

            }
        },

        checkControlStatus() {
            this.roles.searchCount = 0;
            this.roles.idcCount = 0;
            this.roles.keywordCount = 0;

            for (const i in this.personList) {
                if (!this.personList[i].selected) continue;
                //console.log('selectedp', this.personList[i]);

                if (this.personList[i].uimodel.didSearch) {
                    this.roles.searchCount += 1;
                }

                if (this.personList[i].control.completed) {
                    this.roles.idcCount += 1;
                }

                if (this.reportData[this.personList[i].id]) {
                    if (this.reportData[this.personList[i].id].searchComplete) {
                        this.roles.keywordCount += 1;
                    }
                }

            }

            if (this.roles.searchCount > 0 || this.roles.idcCount > 0 || this.roles.keywordCount > 0 || (this.selectedPersonCount > 0 && this.kycMetadata.signed)) {
                this.roles.pending = true;
            }

            let completed = true;
            if (this.roles.searchCount < this.selectedPersonCount) completed = false;
            if (this.roles.idcCount < this.selectedPersonCount) completed = false;
            if (this.$root.config.report.keywordsEnabled && this.roles.keywordCount < this.selectedPersonCount) completed = false;
            this.roles.completed = completed;
            this.roles.nopersons = this.selectedPersonCount == 0;
            if (this.roles.nopersons) this.roles.completed = false;
        },

        //----- Loading ---------------------------------------------------------

        async loadAllData() {
            this.loading.data = true;

            const result = await clienthelper.loadClient(this.$root, this.customer, this.flow, this.amlCache);
            //console.log('result', result);

            this.brregData = result.brregData;
            this.amlData = result.amlData;
            this.manualData = result.manualData;
            this.kycMetadata = result.kycMetadata;
            this.personList = result.persons;
            this.sources = result.sources;
            this.counts = result.counts;
            this.events = result.events;
            this.reportData = result.reportData;
            this.archivedReports = result.archivedReports;
            this.selectedPersonCount = result.selectedPersonCount;
            this.shareholderBookMultiple = result.kycMetadata.shareholderBooks.length > 1;

            this.checklist = result.checklist || {};
            //console.log('CHECK', this.checklist);

            if (this.kycMetadata && this.kycMetadata.signed) {

                if (this.customer && this.customer.kyc) {

                    if (!this.customer.kyc.completedDate) {
                        log.e('KYC not updated');

                        const fixData = {
                            completedDate: new Date()
                        };
                        await api.fixKyc(this.customer._id, fixData);
                        location.reload();
                        return;

                    }else {
                        log.x('KYC signed and updated');
                    }

                }

            }

            if (this.customer.suit) {
                console.log('SUIT', this.customer.suit);
                this.suit.envelopeId = this.customer.suit.envelopeId;
                this.suit.userName = this.$root.getEmployeeName(this.customer.suit.userId);
                this.suitEnvelope = await this.$root.getEnvelope(this.suit.envelopeId);
            }

            if (this.flow.key == 'person' || this.flow.key == 'personLookup') {
                this.singlePerson = this.personList[0];
                this.singlePersonIdcIndex = this.flow.idcIndex || 0;
                this.isPersonFlow = true;
                this.allowEdit = this.flow.allowEdit;
            }
            let count = 0;
            for (const key in this.flow.tabModel) count += 1;
            if (count < 2) this.showTabs = false;
            console.log('FLOW', this.flow);

            if (!this.isPersonFlow) {
                if (this.amlData) {
                    this.companyData = dataModel.getEmptyModel();
                    if (this.amlData.keyInformation) {
                        dataNorway.processData(this.$root, this.companyData, this.amlData, this.brregData);
                    }
                }else if (this.flow.key == 'org-se-trap' || this.flow.key == 'trapets-se') {
                    this.companyData = result.companyData;
                }else if (this.flow.key == 'org-uk-chouse') {
                    this.companyData = result.companyData;
                }
                //console.log('COMPANY', this.companyData);
                //console.log(JSON.stringify(this.companyData));
            }

            // Validate steps
            this.validateFlowSteps();

            //console.log('data loaded', this.customer);
            this.loading.data = false;
        },

        //-----------------------------------------------------------------------

        onBrowserBackButton() {
            // Override back-button
            if (this.previewVisible || this.preview2Visible) {
                this.previewVisible = false;
                this.preview2Visible = false;
            }
        },

    },
    async created() {
        this.loading.main = true;
        this.$parent.setLoading(true, this.__('loading_customer') + '...');

        // Load customer from database
        const customerId = this.$route.query.id;
        const response = await api.getCustomer(customerId);
        this.customer = response.customer;
        log.s('Client: customer with ID ' + customerId + ' loaded', this.customer);

        this.$parent.setTitle(this.customer.name);
        // Add listener to handle back-button
        let self = this;
        window.addEventListener('popstate', function (event) {
            self.onBrowserBackButton(event);
        });

        if (this.$root.config.portal.departments) {
            this.partners = [];
            for (const i in this.$root.partners) {
                this.partners.push({
                    value: this.$root.partners[i].key,
                    display: this.$root.partners[i].firstName + ' ' + this.$root.partners[i].lastName
                });
            }
        }

        // Set the associate (user who created this customer)
        this.associate = this.$root.getEmployee(this.customer.userId);

        // Load customer specific flow
        if (this.customer.type == 'person') {
            if (this.customer.flow && this.customer.flow == 'personLookup') {
                this.isLookup = true;
                this.flow = flows.getFlow('personLookup', this.$root.config.flows.personLookup);
            }else {
                this.flow = flows.getFlow('personNO', this.$root.config.flows.personNorway);
            }
        }else {
            if (this.customer.source == 'brreg') {
                if (this.customer.flow && this.customer.flow == 'companyBrregLookup') {
                    this.isLookup = true;
                    this.flow = flows.getFlow('brregLookup', this.$root.config.flows.companyBrregLookup);
                }else if (this.customer.flow && this.customer.flow == 'snn-bank') {
                    this.kycDisabled = true;
                    this.flow = flows.getFlow('snn-bank', this.$root.config.flows.snnBank);
                }else if (this.customer.flow && this.customer.flow == 'snn-bank2') {
                    this.kycDisabled = true;
                    this.flow = flows.getFlow('snn-bank2', this.$root.config.flows.snnBank2);
                }else {
                    this.flow = flows.getFlow('brreg', this.$root.config.flows.companyBrreg);
                }
            }else if (this.customer.flow == 'org-se-trap') {
                this.flow = flows.getFlow('org-se-trap', this.$root.config.flows.companySweden);
            }else if (this.customer.flow == 'org-uk-chouse') {
                this.flow = flows.getFlow('org-uk-chouse', this.$root.config.flows.companyUK);
            }else {
                this.flow = flows.getFlow('manual', this.$root.config.flows.companyManual);
            }
        }

        if (this.$root.config.portal.departments) {
            this.departments = [];
            this.partners = [];
            for (const i in this.$root.departments) {
                this.departments.push({
                    value: this.$root.departments[i].key,
                    display: this.$root.departments[i].name
                });
            }
            for (const i in this.$root.partners) {
                this.partners.push({
                    value: this.$root.partners[i].key,
                    display: this.$root.partners[i].firstName + ' ' + this.$root.partners[i].lastName
                });
            }
        }

        // Inital customer loaded, show UI
        this.loading.main = false;
        this.$parent.setLoading(false);

        // Start the customer data loading process
        this.loadAllData();
    }
}
</script>
<style scoped>

.customer-header {
    position: relative;
    float: left;
    width: 100%;
    min-height: 72px;
    background-color: var(--stepper-bg);
    box-shadow: 1px 1px 2px rgba(166, 177, 198, 0.3);
    box-sizing: border-box;
}
.customer-header-content {
    float: left;
    width: 100%;
    height: 60px;
    padding-top: 12px;
    padding-left: 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px var(--text-disabled);
    box-sizing: border-box;
}
.customer-header-actions {
    position: absolute;
    display: flex;
    top: 4px;
    right: 10px;
}

.customer-header-notabs {
    min-height: 62px;
}
.customer-header-notabs .customer-header-content {
    border-bottom: none;
}

</style>