<template>
<div class="vap-fu">
    
    <div v-if="!(singleFile && files.length > 0)" class="vap-fu-dropzone" :id="dropZoneId" :class="{'vap-fu-dropzone-header':header}">
        <svg width="100%" height="100%" class="vap-fu-rect-i" style="overflow: visible;">
            <rect width="100%" height="100%" x="0" y="0" rx="5" ry="5" :fill="fillColor" 
                :stroke="strokeColorI" stroke-width="1.5" stroke-dasharray="10 3" />
        </svg>

        <div class="vap-fu-dropzone-content">
            <label v-if="header" style="font-size: 16px; font-weight: bold;">{{ header }}</label><br>
            <label>{{ helpText }}</label>
            <label :for="dropZoneId + 'i'" class="vap-input-label">
                {{ uploadText }}
                <input type="file" :id="dropZoneId + 'i'" class="vap-input" @change="onFileInputChanged">
            </label>
        </div>

<!--         <div class="vap-vu-dropzone-icon">
            <svg height="32" version="1.1" width="32" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.7);">
                <path d="M18.4 17V23H13.6V17H8.8L16.1 10L23.4 17H18.4ZM25.9 13.4C24.9 8.7 20.9 5 16.1 5C12.2 5 8.9 7.3 7.2 10.5C3.2 10.9 0 14.5 0 18.7C0 23.3 3.6 27 8 27H25.3C29 27 31.9 23.9 31.9 20.2C32.1 16.5 29.4 13.7 25.9 13.4Z"/>
            </svg>
        </div> -->
    </div>

    <div class="vap-fu-files">
        <div class="vap-fu-file" v-for="(file, i) in files" :key="'fuf' + i">
            <div v-if="!file.selectContent" class="vap-fu-file-top" @click="onFileClicked(i)" :class="{'vap-fu-file-top-expanded': file.expanded}">
                <div class="vap-fu-file-left">
                    <label>{{ file.name }}</label>
                </div>
                <div class="vap-fu-file-right">
                    <vap-icon v-if="!file.existing" action :icon="'delete'" @clicked="deleteFile(i, file)"><template v-slot:tooltip><vap-tooltip :text="'Ta bort fil'" /></template></vap-icon>
                </div>
            </div>
            <div v-if="file.selectContent" class="vap-fu-select">
                <div class="vap-fu-select-left">
                    <div class="vap-fu-select-name">
                        <label>{{ file.name }}</label>
                    </div>
                    <div class="vap-fu-select-dd">
                        <vap-dropdown v-model="file.content" :options="contentOptions" noValidation @input="setContent(file)"
                            :placeholder="__('fileupload_contents')" :listKey="$root.locale.key" :displayKey="$root.locale.key" :outputKey="'value'" />
                    </div>
                    <div class="vap-fu-select-hint">
                        <label>{{ __('fileupload_contents_hint') }}</label>
                    </div>
                </div>
                <div class="vap-fu-select-right">
                    <vap-icon action :icon="'delete'" @clicked="deleteFile(i, file)"><template v-slot:tooltip><vap-tooltip :text="'Ta bort fil'" /></template></vap-icon>
                </div>
            </div>
            <div class="vap-fu-file-preview" v-if="file.expanded">
                <Preview v-if="!file.existing" :uid="dropZoneId + 'p' + file.id" :file="file.data" />
                <Preview v-if="file.existing" :uid="dropZoneId + 'p2' + file.id" :raw="file.data" />
            </div>
        </div>
    </div>

    <div class="custom-invalid-feedback" v-if="this.wrongFormat">
        <!-- {{ __(validation.key, validation.args) }} -->
        {{ '*Invalid file format' }}
    </div>

</div>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist';
import validations from '../../validations';
import Preview from './Preview';

export default {
    name: 'vap-file-upload',
    components: {
        Preview
    },
    props: {
        header: {
            type: String,
            default: 'Upload files'
        },
        helpText: {
            type: String,
            default: 'Drag & Drop files here or'
        },
        uploadText: {
            type: String,
            default: 'browse files'
        },
        rules: {
            type: String,
            default: ''
        },
        singleFile: {
            type: Boolean,
            default: false
        },
        autoPreview: {
            type: Boolean,
            default: true
        },
        contentOptions: {
            type: Array,
            default: undefined
        },
        anyFile: {
            type: Boolean,
            default: false
        },
        allowedFiles: {
            type: String,
            default: 'pdf'
        },
        existingFiles: {
            type: Array,
            default: undefined
        },
        initialInvalid: {
            type: Boolean,
            default: false
        },
        readPDF: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            dropZoneId: 'dz',
            fillColor: '#ffffff',
            strokeColorI: '#000000',
            strokeColorV: '#000000',
            files: [],
            validation: { status: true },
            wrongFormat: false
        }
    },
    watch: {
        data: {
            deep: true,
            handler(val) {
                this.performValidation();
            }
        },
        existingFiles: {
            deep: true,
            handler(val) {
                if (val) this.addExisting();
                this.updateVmodel();
            }
        }
    },
    methods: {

        async readFile(file) {
            const self = this;
            const reader = new FileReader();
            reader.onload = async function (event) {
                try {
                    var typedarray = new Uint8Array(this.result);
                    self.showPdf = true;
                    const text = await self.getPdfText(typedarray);
                    //console.log('text', text);
                    self.$emit('pdfread', text);
                }catch (e) {
                    self.showError = true;
                }
            };
            reader.readAsArrayBuffer(file);
        },

        async getPdfText(data) {
            pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js';
            let doc = await pdfjsLib.getDocument(data).promise;
            let pageTexts = Array.from({length: doc.numPages}, async (v,i) => {
                return (await (await doc.getPage(i+1)).getTextContent()).items.map(token => token.str).join('');
            });
            return (await Promise.all(pageTexts)).join('');
        },
        
        updateVmodel() {
            let data = {
                files: this.files
            };
            this.$emit('input', data);
        },

        addExisting(val) {
            for (const i in val) {
                this.files.push({
                    data: val[i].data,
                    id: 'fileidex' + i,
                    name: val[i].name,
                    type: val[i].fileType,
                    content: '',
                    size: Number(val[i].size / 1000) + ' kB',
                    uploaded: val[i].uploaded,
                    questionId: this.questionId,
                    commentId: this.commentId,
                    expanded: val[i].autoPreview,
                    selectContent: false,
                    existing: true
                });
            }
        },

        performValidation() {
            let value = '';
            if (!this.wrongFormat) {
                if (this.files.length > 0) value = 'x';
                this.validation = {...this.validation, ...validations.validateRules(value, this.rules)};
            }else {
                this.validation = {
                    status: false,
                    message: 'wrong_file_format'
                }
            }
            this.highlightDropzone(false);
        },

        // --- Drop Zone Initialization --------------------------

        async initDropZone() {
            await new Promise(r => setTimeout(r, 200));
            let self = this;
            let holder = document.getElementById(self.dropZoneId);
            holder.ondragover = function () {
                self.highlightDropzone(true);
                return false;
            };
            holder.ondragend = function () {
                self.highlightDropzone(false);
                return false;
            };
            holder.ondragleave = function () {
                self.highlightDropzone(false);
                return false;
            };
            holder.ondrop = function (e) {
                self.highlightDropzone(false);
                e.preventDefault();
                self.readfiles(e.dataTransfer.files);
            };
        },

        highlightDropzone(value) {
            if (value) {
                this.fillColor = '#DDECFC';
                this.strokeColorI = '#568ED3';
                this.strokeColorV = '#568ED3';
            }else {
                if (this.initialInvalid) {
                    this.strokeColorI = '#b62537';
                    this.strokeColorV = '#b62537';
                    return;
                }
                if (!this.validation.status) {
                    this.fillColor = '#ffffff';
                    this.strokeColorI = '#CCCCCC';
                    this.strokeColorV = '#b62537';
                }else {
                    this.fillColor = '#ffffff';
                    this.strokeColorI = '#CCCCCC';
                    this.strokeColorV = '#568ED3';
                }
            }
        },

        getUniqueId() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },

        // -------------------------------------------------------

        // --- File adding/removing ------------------------------

        onFileInputChanged(event) {
            //console.log(event.target.files);
            this.addFile(event.target.files[0]);

/*             var reader = new FileReader();
            reader.onload = function (e) {
                var output = e.target.result;
                console.log('output', output);
            };//end onload()
            reader.readAsText(event.target.files[0]); */
            if (this.readPDF) {
                this.readFile(event.target.files[0]);
            }
        },

        readfiles(files) {
            for (var i = 0; i < files.length; i++) {
                this.addFile(files[i]);
            }
        },

        addFile(file) {
            this.wrongFormat = false;
            this.performValidation();

            if (!this.validateFileFormat(file) && !this.anyFile) { //&& !file.type.includes('image')
                this.wrongFormat = true;
                this.performValidation();
                this.$forceUpdate();
                let self = this;
                setTimeout(function() {
                    self.removeWrongFormat();
                }, 5000);
                return;
            }

            this.files.push({
                data: file,
                id: 'fileid' + this.files.length,
                name: file.name,
                type: file.type,
                content: '',
                size: Number(file.size / 1000) + ' kB',
                uploaded: false,
                questionId: this.questionId,
                commentId: this.commentId,
                expanded: this.autoPreview,
                selectContent: this.contentOptions != undefined,
                existing: false
            });
            
            this.updateVmodel();
        },

        deleteFile(index, file) {
            this.files.splice(index, 1);
            this.updateVmodel();
        },

        removeWrongFormat() {
            this.wrongFormat = false;
            this.performValidation();
            this.$forceUpdate();
        },

        validateFileFormat(file) {
            const allowed = this.allowedFiles.split('|');
            let match = false;

            //console.log('validate', file);

            if (file.type == 'application/pdf' && allowed.includes('pdf')) {
                match = true;
            }
            if (file.type == 'image/png' && allowed.includes('img')) {
                match = true;
            }
            if (file.type == 'image/jpeg' && allowed.includes('img')) {
                match = true;
            }

            return match;
        },

        // -------------------------------------------------------
        
        // --- Files ---------------------------------------------

        onFileClicked(index) {

            this.files[index].expanded = !this.files[index].expanded;
            //console.log(this.files[index].expanded)

            this.$forceUpdate();
        },

        setContent(file) {
            file.selectContent = false;
            this.updateVmodel();
        },

        // -------------------------------------------------------

    },
    async mounted() {
        this.dropZoneId = this.getUniqueId();
        this.initDropZone();
        this.highlightDropzone(false);
        if (this.existingFiles) this.addExisting(this.existingFiles);
        this.performValidation();
    }
}
</script>

<style scoped>

.vap-fu-dropzone {
    position: relative;
    height: 80px;
    margin: 0 1px;
    margin-top: 10px;
}
.vap-vu-dropzone-icon {
    position: absolute;
    top: -18px;
    left: 50%;
    background-color: white;
}
.vap-fu-dropzone-content {
    position: absolute;
    width: 100%;
    top: 0px;
    text-align: center;
    font-size: 16px;
    padding-top: 22px;
    padding-right: 15px;
}


.vap-input {
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
}
.vap-input-label {
    margin-left: 5px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    color: rgb(29, 149, 223);
}
.vap-input-label:hover {
  color: blue;
}



.vap-fu-files {
    float: left;
    width: 100%;
    margin-top: 10px;
}
.vap-fu-file {
    margin-top: 10px;
}
.vap-fu-file-top {
    display: flex;
    border-radius: 4px;
    border: solid 1px var(--input-border);
    cursor: pointer;
}
.vap-fu-file-top-expanded {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}
.vap-fu-file-top label {
    cursor: pointer;
}
.vap-fu-file-top:hover {
    background-color: var(--item-hover);
}
.vap-fu-file-left {
    flex-grow: 100;
    padding: 8px 10px;
    font-size: 17px;
    color: var(--text-strong);
}
.vap-fu-file-right {
    float: right;
}
.vap-fu-file-preview {
    min-height: 400px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px var(--input-border);
    border-top: none;
}


.vap-fu-select {
    display: flex;
    border-radius: 4px;
    border: solid 1px var(--input-border);
}
.vap-fu-select-left {
    flex-grow: 100;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 30px;
}
.vap-fu-select-right {
    flex-shrink: 0;
    padding-top: 15px;
    padding-right: 15px;
}
.vap-fu-select-name {
    padding-bottom: 3px;
}
.vap-fu-select-hint {
    float: left;
    padding: 6px 0px;
    font-size: 14px;
    color: var(--val-error);
}

.custom-invalid-feedback {
    font-size: 14px;
    color: var(--val-error);
}

</style>
