import idc00 from "./defaults/idc00";
import idc01 from "./defaults/idc01";
import kyc00 from "./defaults/kyc00";
import kyc00se from "./defaults/kyc00se";
import kyc02 from "./defaults/kyc02";
import kar99 from "./defaults/kar99";
import kycp01 from "./defaults/kycp01";
const defaultForms = {

    getForms() {
        return [
            'idc00',
            'idc01',
            'kyc00',
            'kyc00se',
            'kyc02',
            'kycp01',
            'kar99',
        ];
    },

    getForm(key) {
        switch (key) {
            case 'idc00': return idc00.getForm();
            case 'idc01': return idc01.getForm();
            case 'kyc00': return kyc00.getForm();
            case 'kyc00se': return kyc00se.getForm();
            case 'kyc02': return kyc02.getForm();
            case 'kycp01': return kycp01.getForm();
            case 'kar99': return kar99.getForm();
        }
        return null;
    },

};
export default defaultForms;