<template>
<div class="vf-dd" :class="[
    {'vf-expanded' : expanded},
    {'vf-disabled': disabled},
    {'vf-dd-dotted': dotted},
    {'vf-dd-designer': designer},
    {'vf-dd-invalid': validation.done && !validation.valid },
]" v-on-clickaway="hideDropdown" @keydown="handleKeyDown" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" @mouseup="handleOptionMouseUp($event)">
    <div class="vf-dd-value">
        <div class="vf-dd-value-drop" @click="handleValueClick" tabindex="0" @focus="onFocus" @blur="onBlur">
            <label v-if="value">{{ value.prefix }}</label>
        </div>
        <input
            :id="'phoneid' + id"
            :value="value.number"
            :placeholder="placeholder"
            :type="getType()"
            :inputmode="numeric ? 'numeric' : ''"
            :pattern="numeric ? '[0-9]*' : ''"
            @input="handleInput"
            @keydown="handleKeyDown"
            :disabled="disabled"
        />
    </div>
    <div v-if="expanded" class="vf-dd-list" id="ddScroll">

        <div v-show="!searching" class="vf-dd-item" v-for="(option, i) in commonOptions" :key="'c' + i" @click="handleCommonClick(i)" :class="{'vf-dd-selected': isSelected(i, true)}">
            <label>{{ getListDisplayCommon(i) }}</label>
        </div>

        <div v-if="searching" class="vf-dd-search">
            <input v-model="searchString" />
        </div>

        <div class="vf-dd-sep" />

        <div v-show="!searching" class="vf-dd-item" v-for="(option, i) in options" :key="i" @click="handleItemClick(i)" :class="{'vf-dd-selected': isSelected(i, false)}">
            <label>{{ getListDisplay(i) }}</label>
        </div>

        <div v-show="searching" class="vf-dd-item" v-for="(option, i) in searchOptions" :key="'s'+i" @click="handleSearchClick(i)" :class="{'vf-dd-selected': isSelected(i)}">
            <label>{{ getSearchDisplay(i) }}</label>
        </div>

    </div>

</div>
</template>
<script>
import { mixin as clickaway } from "vue-clickaway";

export default {
    name: 'vf-phone',
    mixins: [clickaway],
    props: {
        value: { type: Object, default: () => { return { prefix: '+47', number: '' } }},

/*         options: { type: Array, default: () => { return [] } },
        commonOptions: { type: Array, default: () => { return [] } }, */

        disabled: { type: Boolean, default: false },
        placeholder: { type: String, default: 'Nothing selected' },

        password: { type: Boolean, default: false },
        numeric: { type: Boolean, default: false },
        maxdigits: { type: String, default: '' },

        /* displayKey: { type: String, default: 'display' },
        outputKey: { type: String, default: 'value' },
        listKey: {  type: String, default: 'display' },
        listKey2: { type: String, default: '' }, */
        
        required: { type: Boolean, default: false },
        noValidation: { type: Boolean, default: false },
        noHighlight: { type: Boolean, default: false },

        validation: { type: Object, default: () => { return { done: false } }},
        dotted: { type: Boolean, default: false },

        id: { type: String, default: 'id' },
        designer: { type: Boolean, default: false },
        activeLogic: { type: Boolean, default: false },
    },
    data() {
        return {
            expanded: false,
            searching: false,
            searchString: '',
            focusDelay: false,
            searchOptions: [],
            selectedIndex: 0,
            options: [],
            commonOptions: [],
            displayKey: '',
            outputKey: '',
            listKey: '',
            listKey2: '',
        }
    },
    computed: {
        objectValue: function () {
            return this.getTitleFromValue(this.value);
        }
    },
    watch: {
        value: {
            deep: false,
            handler(val) {
                
            }
        },
        searchString: {
            deep: false,
            handler(val) {
                this.selectedIndex = 0;
                if (val.length > 0) {
                    this.searchOptions = [];
                    let str = val.toLowerCase();
                    for (let i in this.options) {
                        for (let key in this.options[i]) {
                            let opt = this.options[i][key].toString().toLowerCase();
                            if (opt.includes(str)) {
                                this.searchOptions.push(this.options[i]);
                                break;
                            }
                        }
                    }
                    this.searching = true;
                }else {
                    this.searching = false;
                }
            }
        }
    },
    methods: {

        updatePrefix(prefix) {
            this.$emit('input', { prefix: prefix, number: this.value.number });
        },

        validate(value) {
            if (this.noValidation) return;
            if (!value) value = this.value;
            //console.log('validating', value);
            //this.validation = {...this.validation, ...validations.validateRules(value, this.rules)};
        },

        getType() {
            if (this.password) return 'password';
            if (this.numeric) return 'numeric'
            return '';
        },

        handleInput(e) {
            let val = e.srcElement.value;

            if (this.maxdigits && val.length >= Number(this.maxdigits)) {
                val = val.substring(0, Number(this.maxdigits));
                e.srcElement.value = val;
            }

            // Emit input
            this.$emit('input', { prefix: this.value.prefix, number: val });
        },

        onFocus() {
            if (this.disabled || this.designer) return;
            this.searchString = '';
            this.selectedIndex = 0;
            this.expanded = true;
            const self = this;
            self.focusDelay = true;
            setTimeout(function() {
                self.focusDelay = false;
            }, 200);
        },

        onBlur() {
            if (this.expanded) return;
            if (this.disabled || this.designer) return;
            this.expanded = false;
        },

        handleKeyDown(event) {
            if (event.keyCode == 9) {
                this.expanded = false;
            }
            if (this.disabled || this.designer) return;
            if (event.keyCode == 38) {
                this.selectedIndex -= 1;
                this.addScroll();
            }else if (event.keyCode == 40) {
                this.selectedIndex += 1;
                this.addScroll();
            }else if (event.keyCode == 13) {
                this.selectItem();
            }else if (event.keyCode == 8) {
                if (this.searchString.length > 0) this.searchString = this.searchString.substring(0, this.searchString.length - 1);
            }else {
                if ('abcdefghijklmnopqrstuvwxyzæøåABCDEFGHIJKLMOPQRSTUVWXYZÆØÅ0123456789 '.includes(event.key)) {
                    this.searchString = this.searchString + event.key;
                }
            }
        },

        handleValueClick() {
            if (this.disabled || this.designer) return;
            this.selectedIndex = 0;
            this.searchString = '';
            if (!this.focusDelay) this.expanded = !this.expanded;
        },

        handleItemClick(index) {
            this.expanded = false;
            /* this.$emit('input', this.options[index][this.outputKey]); */
            this.updatePrefix(this.options[index][this.outputKey]);
        },

        handleSearchClick(index) {
            this.expanded = false;
            /* this.$emit('input', this.searchOptions[index][this.outputKey]); */
            this.updatePrefix(this.searchOptions[index][this.outputKey]);
        },

        handleCommonClick(index) {
            this.expanded = false;
            /* this.$emit('input', this.commonOptions[index][this.outputKey]); */
            this.updatePrefix(this.commonOptions[index][this.outputKey]);
        },

        hideDropdown() {
            this.expanded = false;
        },

        getTitleFromValue(value) {
            for (let i in this.options) {
                if (this.options[i][this.outputKey] == value) {
                    return this.options[i][this.displayKey];
                }
            }
            return '';
        },

        getListDisplay(index) {
            if (this.listKey) {
                if (this.listKey2) {
                    return this.options[index][this.listKey] + ' ' + this.options[index][this.listKey2];
                }else {
                    return this.options[index][this.listKey];
                }
            }else {
                return this.options[index].value;
            }
        },

        getSearchDisplay(index) {
            if (this.listKey) {
                if (this.listKey2) {
                    return this.searchOptions[index][this.listKey] + ' ' + this.searchOptions[index][this.listKey2];
                }else {
                    return this.searchOptions[index][this.listKey];
                }
            }else {
                return this.searchOptions[index].value;
            }
        },

        getListDisplayCommon(index) {
            if (this.listKey) {
                if (this.listKey2) {
                    return this.commonOptions[index][this.listKey] + ' ' + this.commonOptions[index][this.listKey2];
                }else {
                    return this.commonOptions[index][this.listKey];
                }
            }else {
                return this.commonOptions[index].value;
            }
        },

        isSelected(index, common) {
            if (common) {
                if (index == this.selectedIndex) {
                    return true;
                }
            }else if (this.searching) {
                if (index == this.selectedIndex) {
                    return true;
                }
            }else {
                if (index == this.selectedIndex - this.commonOptions.length) {
                    return true;
                }
            }
            return false;
        },
        
        selectItem() {
            if (this.selectedIndex < 0) return;
            if (this.searching) {
                this.handleSearchClick(this.selectedIndex);
                return;
            }
            if (this.selectedIndex < this.commonOptions.length) {
                this.handleCommonClick(this.selectedIndex);
                return;
            }
            if (this.selectedIndex >= this.commonOptions.length) {
                this.handleItemClick(this.selectedIndex - this.commonOptions.length);
            }
        },

        addScroll() {
            let elem1 = document.getElementById('ddScroll');
            let s = document.getElementsByClassName("vap-dd-selected");
            if (s.length == 0) return;
            elem1.scrollTop = s[0].offsetTop - 180;
        },


        //#region Designer / Logic --------------------------------------------------------------------------------------------

        handleMouseEnter(e) {
            this.$emit('connect-enter', this.id);
        },

        handleMouseLeave(e) {
            this.$emit('connect-leave', this.id);
        },

        handleOptionMouseUp(e) {
            if (this.activeLogic) {
                this.$emit('connect', this.id);
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    mounted() {

        this.options = this.$root.countriesJson;
        this.commonOptions = this.$root.countriesCommon;

        this.displayKey = 'prefix';
        this.outputKey = 'prefix';
        this.listKey = 'prefix';
        this.listKey2 = this.$root.locale.key;

        if (this.options.length > 0) {
            if (typeof this.options[0] == 'object') {
                this.objects = true;
            }
        }
    }
}
</script>
<style scoped>

.vf-dd {
    float: left;
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.vf-dd:focus {
    outline: none;
}
.vf-dd:focus .vap-dd-value {
    border: solid 1px var(--input-focus);
}

.vf-dd-value {
    display: flex;
}



.vf-dd-value-drop {
    position: relative;
    flex-shrink: 0;
    width: 80px;
    padding: 8px 12px;
    padding-top: 10px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: var(--card-background);

    height: 40px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: var(--input-text);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: solid 1px var(--input-border);
    z-index: 0.5;
}
.vf-dd-value-drop label {
    cursor: pointer;
}
.vf-dd-placeholder {
    color: var(--text-disabled);
}
.vf-dd-value-drop:hover {
    background-color: rgb(245, 245, 245);
    border-color: var(--input-focus);
}
.vf-dd-value-drop:focus {
    outline: none;
    border-color: var(--input-focus);
}

.vf-dd-value input {
    /* flex-grow: 100; */
    width: 100%;
    margin-left: -1px;
    padding: 6px 12px;
    padding-top: 7px;
    font-size: 15px;
    box-sizing: border-box;
    background-color: var(--card-background) !important;
    border: none;
    background: none;
    outline: none;
    height: 40px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: var(--input-text);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: solid 1px var(--input-border);
}
.vf-dd-value input:focus {
    border-color: var(--input-focus);
    z-index: 2;
}

.vf-disabled .vf-dd-value input {
    background-color: lightgray  !important;
    cursor: not-allowed;
}


.vf-expanded .vf-dd-value {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom: none;
}

.vf-dd-list {
    position: absolute;
    width: 100%;
    min-height: 20px;
    max-height: 500px;
    box-sizing: border-box;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: solid 1px lightgray;
    background-color: white;
    z-index: 100;
    overflow: auto;
}


.vf-dd-item {
    padding: 8px 14px;
    cursor: pointer;
}
.vf-dd-item label {
    cursor: pointer;
}
.vf-dd-item:hover {
    background-color: lightgray;
}
.vf-dd-selected {
    color: var(--accent-contrast);
    background-color: var(--accent);
}


.vf-dd-sep {
    height: 1px;
    background-color: lightgray;
}

.vf-dd-search {
    padding: 10px 6px;
    box-sizing: border-box;
}
.vf-dd-search input {
    width: 100%;
    font-size: 16px;
    padding: 4px 6px;
    box-sizing: border-box;
}


.vf-disabled .vf-dd-value-drop {
    background-color: lightgray;
    cursor: not-allowed;
}
.vf-disabled label {
    cursor: not-allowed;
}
.vf-disabled .vf-dd-value-drop:hover {
    border-color: var(--input-border);
}



/* --- Validation -------------------- */

.vf-dd-invalid .vf-dd-value-drop {
    border-color: var(--val-error);
}

.vf-dd-invalid .vf-dd-value input {
    border-color: var(--val-error);
}

/* ----------------------------------- */



/* --- Design Mode ------------------- */

.vf-dd-designer .vf-dd-value {
    cursor: default;
}
.vf-dd-designer .vf-dd-value label {
    cursor: default;
}

.vf-dd-dotted .vf-dd-value-drop {
    border: dotted 2px var(--input-border);
}
.vf-dd-dotted .vf-dd-value input {
    border: dotted 2px var(--input-border);
}

.logic .vf-dd:hover .vf-dd-value {
    border: solid 3px #0DCC27;
}

/* ----------------------------------- */

</style>