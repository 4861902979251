<template>
    <div class="vap-sl">
        <div class="vap-sl-icon" :class="[{'vap-sl-icon-completed':completed}, {'vap-sl-icon-aborted':aborted}, {'vap-sl-icon-warning':warning}]" />
        <div class="vap-sl-text">
            <label>{{ (completed && completedText) ? completedText : text }}</label>
            <label class="vap-sl-name">{{ name }}</label>
        </div>
    </div>
</template>

<script>

export default {
    name: 'vap-status-label',
    props: {
        completed: {
            type: Boolean,
            default: false
        },
        aborted: {
            type: Boolean,
            default: false
        },
        warning: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: 'AVVENTER part'
        },
        completedText: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: 'Test Testerson'
        }
    },
    data() {
        return {
            path1: '',
            path2: ''
        }
    },
    computed: {
        
    },
    watch: {
        
    },
    methods: {
        
    },
    async mounted() {


    }
}
</script>
<style scoped>

.vap-sl {
    width: 100%;
    display: flex;
    padding-top: 3px;
}

.vap-sl-icon {
    width: 11px;
    height: 11px;
    margin-top: 2px;
    border-radius: 50%;
    border: solid 2px var(--primary);
}
.vap-sl-icon-completed {
    background-color: var(--primary);
}
.vap-sl-icon-aborted {
    border-color: var(--error-background);
    background-color: var(--error-background);
}
.vap-sl-icon-warning {
    border-color: var(--warning-background);
    background-color: var(--warning-background);
}

.vap-sl-text {
    padding-top: 1px;
    margin-left: 10px;
    color: var(--text-weak);
}

.vap-sl-name {
    margin-left: 5px;
    color: var(--text-strong);
}

</style>