<template>
    <div>
        <BatchView :items="items" :steps="steps" />
    </div>
</template>
<script>
import api from '../../portalapi';
import BatchView from '../../components/special/BatchView';

export default {
    components: {
        BatchView
    },
    name: 'Reminders',
    data() {
        return {
            items: [],
            steps: 1,
            STEP_UPDATE: 0,
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            return item.name;
        },

        onGetInfo1(item) {
            return item.status;
        },

        onGetInfo2(item) {
            return '';
        },

        async onRemoveItem(item) {
            const response = await api.setReminder(item._id, -1);
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_UPDATE: return 'Oppdaterer kunde';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_UPDATE: return await this.updateCustomer(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async updateCustomer(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            await new Promise(r => setTimeout(r, 500));

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Loading -----------------------------------------------------------------------------------------------------

        async loadCustomers() {
            this.$parent.setLoading(true, 'Laster påminnelser...');
            
            const filter = {};
            const from = 0;
            const limit = 10;
            const sort = { updated: -1 };
            const counts = ['status', 'user', 'risk'];

            const response = await api.getCustomersFiltered(filter, from, limit, sort, counts);

            for (const i in response.customers) {
                this.items.push(response.customers[i]);
            }

            this.$parent.setLoading(false);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    async created() {
        await this.loadCustomers();
    }
}
</script>