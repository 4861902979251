<template>
<div class="arv2-base card-shadow nohl" @click="$emit('clicked')">

    <div class="arv2-icon" :style="getRiskColor(report.risk)">
        <vap-icon :icon="'check'" :color="'ext'" />
    </div>

    <div class="arv2-content">

        <div class="arv2-header">
            {{ report.name ? report.name : formatDate('format', report.dateCreated) }}
        </div>

        <div class="arv2-info">
            <div class="arv2-name">
                {{ getEmployeeName(report.userId) }}
            </div>
            <div class="arv2-risk" v-if="$root.config.risk.enabled">
                {{ __('filters_risk') + ': ' + getRiskText(report.risk) }}
            </div>
            <div class="arv2-risk" v-if="report.externalId">
                {{ $root.config.externalId.title[$root.locale.key] + ': ' + report.externalId }}
            </div>
            <div class="arv2-risk" v-if="report.name">
                {{ formatDate('format', report.dateCreated) }}
            </div>
        </div>

    </div>
</div>
</template>
<script>
export default {
    name: 'ArchivedReportItem',
    props: {
        report: { type: Object, default: null },
    },
    data() {
        return {
            texts: {
                risk: {},
            },
            colors: {},
        }
    },
    methods: {

        getRiskText(risk) {
            if (this.texts.risk[risk]) return this.texts.risk[risk][this.$root.locale.key];
            return risk;
        },

        getRiskColor(risk) {
            let color = 'disabled-background';
            if (this.colors[risk]) color = this.colors[risk];
            return `background-color: var(--${color});`;
        },

    },
    created() {
        //console.log('report', this.report);

        // Create risk options
        for (const i in this.$root.config.risk.values) {
            for (const j in this.$root.config.risk.texts) {
                if (this.$root.config.risk.texts[j].value == this.$root.config.risk.values[i]) {
                    const option = {};
                    for (const key in this.$root.config.risk.texts[j]) {
                        if (key != 'value' && key != 'color') option[key] = this.$root.config.risk.texts[j][key];
                        if (key == 'color') {
                            this.colors[this.$root.config.risk.texts[j].value] = this.$root.config.risk.texts[j][key];
                        }
                    }
                    this.texts.risk[this.$root.config.risk.texts[j].value] = option;
                }
            }
        }

        //console.log('colors', this.colors);

    }
}
</script>
<style scoped>

.arv2-base {
    float: left;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
    padding: 8px 13px;
    cursor: pointer;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
}
.arv2-base:hover {
    background-color: var(--item-hover);
}

.arv2-icon {
    width: 36px; height: 36px;
    padding-top: 1px;
    padding-left: 1px;
    border-radius: 50%;;
    background-color: var(--success-background);
    box-sizing: border-box;
    fill: var(--success-text);
}

.arv2-content {
    margin-left: 15px;
}

.arv2-header {
    font-size: 17px;
}

.arv2-info {
    display: flex;
}

.arv2-name {
    font-size: 15px;
    color: var(--text-weak);
}

.arv2-risk {
    margin-left: 10px;
    font-size: 15px;
    color: var(--text-weak);
}

</style>