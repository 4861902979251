<template>
<div>

<!--     <PropertyItem :header="'Basic properties'" :icon="'locale'" v-if="card.type != 'cftext'" :initialCollapse="initialCollapse">
        <vap-row>
            <vap-group c12 v-if="showHeaderValue(card)">
                <vap-label :text="'Header'" desc />
                <vap-input v-model="card.header[$root.locale.key]" noValidation />
            </vap-group>
        </vap-row>
    </PropertyItem>

    <PropertyItem :header="'Card properties'" :icon="'card'" :initialCollapse="initialCollapse">
        <vap-row>
            <vap-group c6 v-if="showNoPadding(card)">
                <vap-checkbox :text="'No padding'" v-model="card.noPadding" bordered borderOffset noValidation />
            </vap-group>
            <vap-group c6 v-if="showNoPadding(card)">
                <vap-checkbox :text="'No shadow'" v-model="card.noShadow" bordered borderOffset noValidation />
            </vap-group>
            <vap-group c12>
                <vap-label :text="'Visible for roles'" desc />
                <vap-checkbox v-model="card.roles" inline :options="reference.roles" noValidation />
            </vap-group>
        </vap-row>
    </PropertyItem> -->

    <PropertyItem padded :header="'Basic properties'" :icon="'locale'" v-if="card.type != 'cftext'" :initialCollapse="initialCollapse">

        <DesignerGroup c12 v-if="showHeaderValue(card)">
            <DesignerLabel :text="'Header'" />
            <DesignerInput v-model="card.header[$root.locale.key]" :placeholder="'Not set'" />
        </DesignerGroup>

    </PropertyItem>

    <PropertyItem padded :header="'Card properties'" :icon="'card'" :initialCollapse="initialCollapse" v-if="card.type != 'pdfh'">

        <DesignerGroup c6 v-if="showNoPadding(card)">
            <DesignerCheckbox v-model="card.noPadding" :text="'No padding'" />
        </DesignerGroup>

        <DesignerGroup c6 v-if="showNoPadding(card)">
            <DesignerCheckbox v-model="card.noShadow" :text="'No shadow'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Visible for roles'" />
            <DesignerCheckbox v-model="card.roles" :options="reference.roles" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';

export default {
    name: 'CardProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
    },
    props: {
        card: { type: Object, default: null },
        reference: { type: Object, default: null },
        initialCollapse: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {
        
        showHeaderValue(card) {
            if (card.type == 'logo' || card.type == 'methods' || card.type == 'checkout' || card.type == 'card-header' || card.type == 'cftext' || card.type == 'flowv' || card.type == 'popup' || card.type == 'kycc' || card.type == 'kyci' || card.type == 'kyca' || card.type == 'checku' || card.type == 'anas') return false;
            return true;
        },

        showNoPadding(card) {
            if (card.type == 'logo' || card.type == 'methods' || card.type == 'checkout' || card.type == 'card-header' || card.type == 'cftext' || card.type == 'flowv' || card.type == 'popup' || card.type == 'kycc' || card.type == 'kyci' || card.type == 'kyca' || card.type == 'checku' || card.type == 'anas') return false;
            return true;
        }

    },
    mounted() {

    }
}
</script>
<style scoped>

.frv2-opts-actions {
    display: flex;
    flex-direction: row-reverse;
    float: left;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
}

</style>
