<template>
<div>
    <FormLanding v-if="!loading" :envelope="$parent.loadedEnvelope" :recipient="$parent.loadedRecipient" />
</div>
</template>
<script>
import log from '@/log';
import locale from '@/locale';
import v2lib from '@/v2lib';

import FormLanding from '@/components/form/FormLanding';

export default {
    name: 'FormLandingView',
    components: {
        FormLanding
    },
    data() {
        return {
            loading: true,
            language: 'en_EN',
            recipient: { displayName: '' }
        }
    },
    methods: {

        setTitle(title) {
            this.$parent.headerTitle = title;
            this.$parent.setTitle(title, true);
        },

        readRecipient(recipient) {
            return {
                displayName: recipient.givenName + ' ' + recipient.familyName,
                action: recipient.role.action,
                completed: recipient.completed,
                key: recipient.role.name,
            };
        },

        setLang() {
            this.$root.locale = locale.getLocale(this.$root.config.locales, this.language);
        },

        getCheckoutLink() {
            console.log('route', this.$route);
            let signUrl = 'https://checkout.verified.eu/checkout/?u=' + this.$parent.loadedRecipient.uid + '&';
            signUrl += 'lang=' + this.language;


            let baseUrl = this.$root.baseUrl;
            if (this.$root.isAWS) baseUrl += '/web/aml-portal/#';

            const redirectUrl = baseUrl + '/lform/' + this.$parent.loadedEnvelope.id + '/' + this.$parent.loadedRecipient.id + '?lang=' + this.language;
            console.log('redirectUrl', redirectUrl);

            signUrl += '&redirect_to=' + redirectUrl;
            signUrl += '&access_token=' + v2lib.editorToken;

            console.log('signUrl', signUrl);
            return signUrl;
        },

        redirectToCheckout() {
            let signUrl = this.getCheckoutLink();
            sessionStorage.setItem(this.$parent.loadedEnvelope.id, v2lib.editorToken);
            window.location.href = signUrl;
        },
        
    },
    async created() {
        this.$parent.landing = true;
        this.$parent.setLoading(true, 'Checking envelope' + '...');

        if (this.$route.query.reload) {
            await this.$parent.reloadEnvelope();
            this.$route.query.reload = false;
            log.x('FlowPortal: Envelope reladed');
        }

        console.log('loadedEnvelope', this.$parent.loadedEnvelope);
        console.log('loadedRecipient', this.$parent.loadedRecipient);

        this.language = this.$parent.loadedRecipient.language;
        const self = this;
        setTimeout(function() {
            self.setLang();
        }, 100);

        this.recipient = this.readRecipient(this.$parent.loadedRecipient);
        log.x('FlowPortal: Recipient processed', this.recipient);

        // Use the new system for the signing flow
        if (this.$root.config.forms.newLanding && this.$parent.loadedEnvelope.content.descriptor.id == 'aml-portal-v1-sign') {
            this.loading = false;
            this.$parent.landing = false;
            this.$parent.setLoading(false);
            log.e('FlowPortal: Using new system');
            return;
        }

        if (this.recipient.action == 'edit' && !this.recipient.completed && !(this.recipient.key == 'approver' && this.$route.query.reload != null)) {
            this.$parent.navigate({ path: `/fform/${this.$route.params.envelopeId}/${this.$route.params.recipientId}`, query: this.$route.query });
            return;
        }

        if (this.recipient.action == 'sign' && !this.recipient.completed) {
            this.redirectToCheckout();
            return;
        }

/*         else if (this.recipient.action == 'sign' && this.recipient.completed) {
            this.$parent.navigate({ path: `/kyclanding/${this.$route.params.recipientId}/${this.$route.params.envelopeId}` });
            return;
        } */

        this.loading = false;
        this.$parent.landing = false;
        this.$parent.setLoading(false);
    }
}
</script>