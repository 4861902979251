<template>
<div class="vfdv2" @click="onClick" :class="[
    { 'vfdv2-clickable': clickable },
    { 'vfdv2-custom': customContent },
    { 'vfdv2-nopad': noPadding },
]">

    <div class="vfdv2-header" v-if="header">
        <div class="vfdv2-header-x" v-if="header">
            {{ header }}
        </div>
        <div class="vfdv2-header-z" v-if="header">
            
        </div>
    </div>

    <div class="vfdv2-content">
        <slot></slot>
    </div>

</div>
</template>
<script>

export default {
    name: 'DialogContent',
    props: {
        clickable: { type: Boolean, default: false },
        customContent: { type: Boolean, default: false },
        noPadding: { type: Boolean, default: false },
        header: { type: String, default: '' },
    },
    methods: {

        onClick() {
            if (!this.clickable) return;
            this.$emit('clicked');
        }

    }
}
</script>
<style scoped>

.vfdv2 {
    padding-bottom: 10px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    box-sizing: border-box;
}

.vfdv2-header {
    display: flex;
}
.vfdv2-header-x {
    padding: 3px 8px;
    border-bottom: solid 1px var(--input-border);
}
.vfdv2-header-z {
    margin-left: 11.5px;
    border-left: solid 1.5px var(--input-border);
    -webkit-transform: skew(-45deg);
    transform: skew(-45deg);
}

.vfdv2-content {
    padding: 0px 20px;
}

.vfdv2-clickable {
    cursor: pointer;
}
.vfdv2-clickable:hover {
    background-color: var(--item-hover);
}

.vfdv2-custom {
    position: relative;
    float: left;
    width: 100%;
}
.vfdv2-custom .vfdv2-content {
    padding-top: 20px;
}

.vfdv2-nopad {
    padding-bottom: 0px;
}
.vfdv2-nopad .vfdv2-content {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
}

</style>