<template>
<div>

    <PropertyItem padded :header="'Input properties'" :icon="'input-text'">

        <DesignerGroup c4>
            <DesignerLabel :text="'Input type'" />
            <DesignerDropdown v-model="input.inputType" :options="inputTypeOptions" @before="$emit('propinput', 'check', 'input_type', $event)" />
        </DesignerGroup>
        <DesignerGroup c4>
            <DesignerLabel :text="'Min length'" />
            <DesignerInput v-model="input.min" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'input_min_length', $event)" />
        </DesignerGroup>
        <DesignerGroup c4>
            <DesignerLabel :text="'Max length'" />
            <DesignerInput v-model="input.max" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'input_max_length', $event)" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Default value'" />
            <DesignerInput v-model="input.default" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'input_default', $event)"  />
        </DesignerGroup>
        
        <DesignerGroup c12>
            <DesignerLabel :text="'Placeholder'" />
            <DesignerInput v-model="input.placeholder" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'input_placeholder', $event)"  />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';

export default {
    name: 'InputProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
    },
    props: {
        input: { type: Object, default: null },
    },
    data() {
        return {
            inputTypeOptions: [
                { value: 'text', display: 'Free text' },
                { value: 'number', display: 'Number' },
                { value: 'email', display: 'Email address' },
                { value: 'percentage', display: 'Percentage' },
                { value: 'bankno', display: 'Bank account (NO)' },
            ],
        }
    },
}
</script>