const dbPersonHelper = {

    //#region Roles -------------------------------------------------------------------------------------------------------


    //#endregion ----------------------------------------------------------------------------------------------------------

    getAddressString(root, person) {
        if (person.scr.done) {
            if (person.scr.addressNational.available && person.scr.addressNational.street) {
                return this.formatAddressString(root, person.scr.addressNational);
            }
            if (person.scr.addressPostal.available && person.scr.addressPostal.street) {
                return this.formatAddressString(root, person.scr.addressPostal);
            }
        }else if (person.address.street) {
            return this.formatAddressString(root, person.address);
        }
        return '- - -';
    },

    formatAddressString(root, address) {
        return root.companyTitleCase(address.street) + ', ' + address.zip + ' ' + root.companyTitleCase(address.city) + ' - ' + root.getCountryName(address.country);
    },



    getScreeningResult(root, screening, archive) {
        if (!screening.done && !archive) return root.__t('screening-not-completed');

        if (!screening.isPep && !screening.hasSanction) return root.__('hit_none');
        if (screening.isPep && !screening.hasSanction) return root.__('hit_pep');
        if (screening.hasSanction && !screening.isPep) return root.__('hit_sanction');
        if (screening.hasSanction && screening.isPep) return 'Hit on PEP and SANCTION';

        return 'UNKNOWN STATUS';
    },

    getScreeningStatus(screening, archive) {
        if (!screening.done && !archive) return 0;
        if (!screening.isPep && !screening.hasSanction) return 1;
        if (screening.isPep && !screening.hasSanction) return 2;
        if (screening.hasSanction) return 3;
        return 0;
    },


    getIdcMatchDescription(indicator) {
        switch (indicator) {
            case 7: return 'weak match on name only';
            case 6: return 'partial match on name only';
            case 5: return 'full match on name only';
            case 4: return 'match on date of birth only';
            case 3: return 'weak match on name and date of birth';
            case 2: return 'partial match on name and date of birth';
            case 1: return 'full match on name and date of birth';
        }
        return 'no match';
    },

};
export default dbPersonHelper;