<template>
<div>

    <PropertyItem padded :header="'PDF header properties'" :icon="'pdf'" v-if="card.type == 'pdfh'">

        <DesignerGroup c12>
            <DesignerLabel :text="'Style'" />
            <DesignerDropdown v-model="card.style" :options="styleOptions" @before="$emit('propinput', 'drop', 'phone_prefix', $event)" />
        </DesignerGroup>

    </PropertyItem>

    <PropertyItem padded :header="'PDF text properties'" :icon="'pdf'" v-if="card.type == 'pdft'">

        <DesignerGroup c12>
            <TextEditorV2 designer v-model="card.header[$root.locale.key]" advanced @before="$emit('propinput', 'text', 'pdf_text', $event)" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';

import TextEditorV2 from '../../TextEditorV2.vue';

export default {
    name: 'PdfTextProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
        TextEditorV2,
    },
    props: {
        card: { type: Object, default: null },
        reference: { type: Object, default: null },
    },
    data() {
        return {
            styleOptions: [
                { value: 'default', display: 'Default style' },
            ],
        }
    },
}
</script>