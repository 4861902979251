<template>
<div>
    <div class="template-header">
        <div class="template-header-content">
            <vap-row>
                <vap-group auto>
                    <vap-label :text="__('settings')" desc/>
                    <vap-label :text="tabHeader" value />
                </vap-group>
            </vap-row>
        </div>
        <div class="template-header-actions">
            <vap-button v-if="(tabKey == 'kyc' || tabKey == 'idc') && $root.config.portal.devmodeEnabled" :text="'Default translations'" @clicked="setDefaultTranslations" style="margin-right: 10px;" />
            <vap-button :text="__('common_save')" @clicked="onSaveClicked" :working="isSaving" />
        </div>
        <vap-tabs v-if="tabVisible" v-model="tabs" :tabs="tabTitles" accent />
    </div>


    <div class="aml-page" v-on-clickaway="deselectItem">

        <ve-alert v-if="internetExplorer" :icon="'info'" :warning="true" style="float: left; width: 100%; margin-top: 20px; margin-bottom: 0px;">
            <span>{{ 'Template-designeren er ikke optimalisert for Internet Explorer. Det anbefales at du bruker en moderne nettleser f.eks. ' }}
            <b>{{ 'Google Chrome' }}</b></span>
            <span>{{ ' eller ' }}<b>{{ 'Mozilla Firefox' }}</b></span>
        </ve-alert>

        <!-- General settings -->
        <div v-show="tabs.tabGeneral" class="mt20">
            
            <vap-form-divider v-if="tabKey == 'portal'" :left="50" :right="50">
                <template v-slot:left>
                    <vap-label :text="'Logo'" header style="margin-bottom: 5px;" />
                    <vap-card>
                        <vap-row v-if="tabs.tabGeneral">
                            <vap-group c12>
                                <vap-label :text="'Header'" desc />
                                <vap-input v-model="$root.config.portal.header" noValidation />
                            </vap-group>
                            <vap-group c12>
                                <vap-label :text="'Logo URL'" desc />
                                <vap-input v-model="$root.config.portal.logoUrl" noValidation />
                            </vap-group>
                            <vap-group c4>
                                <vap-label :text="'Size adjustment'" desc />
                                <vap-slider v-model="$root.config.portal.logoWidth" :min="10" :max="500" />
                            </vap-group>
                            <vap-group c4>
                                <vap-label :text="'Top offset'" desc />
                                <vap-slider v-model="$root.config.portal.logoTop" :min="0" :max="50" />
                            </vap-group>
                            <vap-group c4>
                                <vap-label :text="'Left offset'" desc />
                                <vap-slider v-model="$root.config.portal.logoLeft" :min="0" :max="50" />
                            </vap-group>
                        </vap-row>
                    </vap-card>
                </template>
                <template v-slot:right>
                    <vap-label :text="__('settings_preview')" header style="margin-bottom: 5px;" />
                    <vap-card>
                        <div class="logo-preview">
                            <div class="logo-preview-bar" >
                                <img :src="$root.config.portal.logoUrl" 
                                    :style="'float: left; width:' + $root.config.portal.logoWidth + 'px; margin-top:' + $root.config.portal.logoTop + 'px; margin-left:' + $root.config.portal.logoLeft + 'px;'" />
                                <label style="color: var(--header-text);" class="logo-text">{{ $root.config.portal.header }}</label>
                            </div>
                        </div>
                    </vap-card>
                </template>
            </vap-form-divider>

            <vap-form-divider v-if="tabKey == 'portal'" :left="50" :right="50" style="margin-top: 20px;">
                <template v-slot:left>
                    <vap-label :text="'PDF'" header style="margin-bottom: 5px;" />
                    <vap-card>
                        <vap-row>
                            <vap-group c12>
                                <vap-label :text="'Logo URL'" desc />
                                <vap-input v-model="$root.config.pdf.logoUrl" noValidation />
                            </vap-group>
                        </vap-row>
                    </vap-card>
                </template>
                <template v-slot:right>
                    <vap-label :text="__('settings_preview')" header style="margin-bottom: 5px;" />
                    <vap-card>
                        <div class="pdf-preview">
                            <img :src="$root.config.pdf.logoUrl" 
                                :style="'float: left; width:' + $root.config.pdf.logoWidth + 'px; margin-top:' + '0' + 'px; margin-left:' + '0' + 'px;'" />
                            <label>{{ 'PDF Header preview' }}</label>
                        </div>
                    </vap-card>
                </template>
            </vap-form-divider>

            <KYCSettings v-if="tabKey == 'kyc'" @locale="showTranslationPopup" />
            <IDCSettings v-if="tabKey == 'idc'" @locale="showTranslationPopup" />

        </div>

        <!-- Questions -->
        <div v-show="tabs.tabQuestions" class="mt20">
            <FormRenderer ref="form-renderer" :data="loadedCards" :translations="translations" designMode @locale="showTranslationPopup" />
        </div>

        <!-- Translations -->
        <div v-show="tabs.tabTexts" class="mt20">

            <vap-card list noPadding>
                <div class="trans-actions">
                    <div class="trans-filters">
                        <vap-row>
                            <vap-group>
                                <vap-label :text="'Filtrer'" />
                                <vap-input :placeholder="'Filtrer'" />
                            </vap-group>
                        </vap-row>
                    </div>
                    <div class="trans-new">
                        <vap-button :text="'Ny tekst'" @clicked="showNewTextPopup" />
                    </div>
                </div>
            </vap-card>

            <vap-form-divider :left="50" :right="50">
                <template v-slot:left>
                    <vap-label :text="'Tekster'" header style="margin-bottom: 5px;" />

                    <vap-card v-for="(tran, key, i) in translations" :key="key" noPadding compactList :selected="i == selectedTranslationIndex">
                        <vap-item @clicked="handleTranslationClick(key, i)">
                            <div style="display: flex;">
                                <div style="flex-grow: 100;">
                                    <vap-label :text="key" />
                                </div>
                                <div style="float: right; padding-top: 2px;">
                                    <img :src="tran.sv_SE ? 'flags/sv_SE.png' : 'flags/grayscale/sv_SE.png'" :class="tran.sv_SE ? 'flag' : 'flag-d'" />
                                    <img :src="tran.en_EN ? 'flags/en_EN.png' : 'flags/grayscale/en_EN.png'" :class="tran.en_EN ? 'flag' : 'flag-d'" />
                                    <img :src="tran.nb_NO ? 'flags/nb_NO.png' : 'flags/grayscale/nb_NO.png'" :class="tran.nb_NO ? 'flag' : 'flag-d'" />
                                </div>
                            </div>
                            <div>
                                <vap-label :text="getDisplayTranslation(key)" />
                            </div>
                        </vap-item>
                    </vap-card>

                </template>
                <template v-slot:right>
                    <vap-label :text="'Oversettelser'" header style="margin-bottom: 5px;" />

                    <vap-card v-if="selectedTranslationIndex == -1">
                        <vap-label :text="'Velg en tekst for å endre'" />
                    </vap-card>

                    <vap-card v-if="selectedTranslationIndex != -1">
                        <div v-if="translations[selectedTranslationKey].tt">
                            <vap-label :text="'Formatert tekst'" value />
                        </div>
                        <div class="trans-desc">
                            <img src="flags/nb_NO.png" /><label>Norsk</label>
                        </div>
                        <div v-if="!translations[selectedTranslationKey].tt" class="trans-area">
                            <vap-textarea v-model="translations[selectedTranslationKey].nb_NO" />
                        </div>
                        <div v-if="translations[selectedTranslationKey].tt" class="trans-button">
                            <vap-button :text="'Åpne tekst-redigering'" @clicked="showEditTextPopup('nb_NO')" />
                        </div>
                        <div class="trans-desc">
                            <img src="flags/en_EN.png" /><label>Engelsk</label>
                        </div>
                        <div v-if="!translations[selectedTranslationKey].tt" class="trans-area">
                            <vap-textarea v-model="translations[selectedTranslationKey].en_EN" />
                        </div>
                        <div v-if="translations[selectedTranslationKey].tt" class="trans-button">
                            <vap-button :text="'Åpne tekst-redigering'" @clicked="showEditTextPopup('en_EN')" />
                        </div>
                        <div class="trans-desc">
                            <img src="flags/sv_SE.png" /><label>Svensk</label>
                        </div>
                        <div v-if="!translations[selectedTranslationKey].tt" class="trans-area">
                            <vap-textarea v-model="translations[selectedTranslationKey].sv_SE" />
                        </div>
                        <div v-if="translations[selectedTranslationKey].tt" class="trans-button">
                            <vap-button :text="'Åpne tekst-redigering'" @clicked="showEditTextPopup('sv_SE')" />
                        </div>
                    </vap-card>

                </template>
            </vap-form-divider>
        </div>

        <!-- Help texts -->
        <div v-show="tabs.tabHelp" class="mt20">
            <vap-tabs accent v-if="helpTabsVisible" v-model="helpTabs" :tabs="helpTabsTitles" style="margin-bottom: 20px;" />
            <div v-if="helpTabs.editor">
                <div class="template-help" v-for="(data, key) in $root.config.kycs[kycIndex].help.editor" :key="vueHack + 'hc' + key">
                    <div class="template-help-text">
                        <div class="template-help-head"><label>{{ getLocalTranslation(data.head) }}</label></div>
                        <div class="template-help-desc"><label>{{ getLocalTranslation(data.desc) }}</label></div>
                    </div>
                    <vap-infocard :data="data.items" :helpKey="key" designer @new="onNewHelpText" @line="showTranslationPopup" @icon="onHelpIconClicked" @delete="onDeleteHelpLine" />
                </div>
            </div>
            <div v-if="helpTabs.owner">
                <div class="template-help" v-for="(data, key) in $root.config.kycs[kycIndex].help.owner" :key="vueHack + 'hc' + key">
                    <div class="template-help-text">
                        <div class="template-help-head"><label>{{ getLocalTranslation(data.head) }}</label></div>
                        <div class="template-help-desc"><label>{{ getLocalTranslation(data.desc) }}</label></div>
                    </div>
                    <vap-infocard :data="data.items" :helpKey="key" designer @new="onNewHelpText" @line="showTranslationPopup" @icon="onHelpIconClicked" @delete="onDeleteHelpLine" />
                </div>
            </div>
        </div>

        <!-- Landing page -->
        <div v-show="tabs.tabLanding" class="mt20">
            <vap-tabs accent v-model="landingTabs" :tabs="landingTabsTitles" style="margin-bottom: 20px;" />
            <div class="slim-page">
                <vap-card>
                    <vap-row>
                        <vap-group c12>
                            <vap-label :text="'Logo URL'" desc />
                            <vap-input v-model="$root.config.kycs[kycIndex].landing.logo" noValidation />
                        </vap-group>
                        <vap-group c12>
                            <vap-label :text="'Home page URL'" desc />
                            <vap-input v-model="$root.config.kycs[kycIndex].landing.redirect" noValidation />
                        </vap-group>
                    </vap-row>
                </vap-card>
                <div class="kyc-landing-logo">
                    <img :src="$root.config.kycs[kycIndex].landing.logo" />
                </div>
                <div class="kyc-landing-header">
                    <div class="kyc-landing-header-center">
                        <div :class="landingTabs.aborted ? 'kyc-landing-icon-error' : 'kyc-landing-icon'">
                            <vap-icon :icon="landingTabs.aborted ? 'cancel' : 'check'" :color="landingTabs.aborted ? 'var(--error-text)' : 'var(--primary-contrast)'" />
                        </div>
                        <div class="kyc-landing-text">
                            <label>{{ ___(this, kycLandingKey + '_head') }}</label>
                        </div>
                    </div>
                </div>
                <vap-label :text="''" header style="margin-top: 20px; margin-bottom: 5px;">
                    <template v-slot:actions>
                        <vap-icon action :icon="'edit'" @clicked="showTranslationPopup(kycLandingKey)">
                            <template v-slot:tooltip><vap-tooltip :text="'Endre tekst'"></vap-tooltip></template>
                        </vap-icon>
                    </template>
                </vap-label>
                <vap-card>
                    <vap-text-editor :value="___(this, kycLandingKey)" />
                </vap-card>
                <div class="kyc-landing-footer">
                    <span>{{ ___(this, 'kyc_landing_footer') }}</span><span><a :href="$root.config.kycs[kycIndex].landing.redirect">{{ ___(this, 'kyc_landing_link') }}</a></span>
                </div>
            </div>
        </div>

        <!-- Colors -->
        <div v-show="tabKey == 'theme'" class="mt20" style="margin-bottom: 400px;">
            <vap-form-divider :left="65" :right="35" stickyRight>
                <template v-slot:left>
                    <vap-label :text="'Elements'" header style="margin-bottom: 5px;" />

                    <vap-card v-for="(element, i) in $root.config.colors" :key="i" noPadding compactList :selected="i == selectedColorIndex" v-show="element.show">
                        <vap-item @clicked="handleColorClick(i)">
                            <div class="stng-color">
                                <div class="stng-color-circle" :style="'background-color: var(--' + element.key + ');'" />
                                <label>{{ element.name }}</label>
                            </div>
                        </vap-item>
                    </vap-card>

                </template>
                <template v-slot:right>
                    <vap-label :text="'Color'" header style="margin-bottom: 5px;" />
                    <vap-card>
                        <div style="padding-top: 30px;">
                            <Chrome v-model="colors" @input="updateValue" style="margin: 0 auto;" />
                        </div>
                        
                    </vap-card>
                </template>
            </vap-form-divider>
        </div>

        <!-- Departments / Partners -->
        <UserList v-if="tabKey == 'users'" :tabs="tabs" />
  


        <ve-popup v-if="newTextPopup" @close="newTextPopup = false">
            <div style="float: left; width: 100%; padding: 20px; box-sizing: border-box;">
                <vap-group c12>
                    <vap-label :text="'Key'" desc />
                    <vap-input v-model="newTextInput" />
                </vap-group>
                <label v-if="newTextError">{{ newTextError }}</label>
                <vap-button :text="__('common_save')" @clicked="saveNewText" style="float: right; margin-right: 5px;" />
            </div>
        </ve-popup>

        <!-- old popup for translations tab -->
        <ve-popup v-if="editTextPopup" :width="900" :header="__('settings_text_editor')" hideCloseButton>
            <div style="padding: 10px 20px;">
                <vap-text-editor ref="texteditor" :editable="true" :value="translations[selectedTranslationKey][editTextLang]" />
                <vap-button-row alignRight style="margin-top: 10px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" outline @clicked="editTextPopup = false" />
                    <vap-button :text="__('common_save')" @clicked="saveEditText" />
                </vap-button-row>
            </div>
        </ve-popup>

        <!-- new popup for all translations -->
        <ve-popup v-if="translationPopup" :width="900" :header="__('settings_text_editor')" hideCloseButton>
            <div style="float: left; padding-top: 15px; padding-left: 20px; padding-right: 20px;">
                <vap-tabs bordered v-model="translationPopupTabModel" :tabs="translationPopupTabOptions" @input="onTranslationPopupLocale" />
            </div>
            <div style="float: left; width: 100%; padding: 10px 20px; box-sizing: border-box;">
                <vap-input v-if="!translations[translationPopupKey].tt" v-model="translationPopupInput" />
                <vap-text-editor v-if="translations[translationPopupKey].tt" ref="texteditor" :editable="true" :value="translations[translationPopupKey][translationPopupLocale]"/>
                <vap-button-row alignRight style="margin-top: 10px; margin-bottom: 20px;">
                    <!-- <vap-button :text="'Bytt til formattert tekst'" outline @clicked="switchTranslationTextType" /> -->
                    <vap-button :text="__('common_cancel')" outline @clicked="translationPopup = false" />
                    <vap-button :text="__('common_save')" @clicked="saveTranslationPopup" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="editLocalizedPopup" :width="900" :header="__('settings_text_editor')" hideCloseButton>
            <div style="padding: 10px 20px;">
                <div v-for="(locale, i) in $root.config.locales" :key="'lc' + i">
                    <vap-text-editor :ref="'te' + locale.key" :editable="true" :value="translations[editLocalizedKey][locale.key]" />
                </div>
                <vap-button-row alignRight style="margin-top: 10px; margin-bottom: 20px;">
                    <vap-button :text="__('common_cancel')" outline @clicked="editLocalizedPopup = false" />
                    <vap-button :text="__('common_save')" @clicked="saveLocalizedText" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="selectIconPopup" :width="600" @close="selectIconPopup = false" :header="'Velg ikon'">
            <div style="float: left; width: 100%; padding: 20px; box-sizing: border-box;">
                <div class="iconsel-item" v-for="(icon, i) in availableIcons" :key="'ai' + i">
                    <vap-icon :icon="icon" action @clicked="selectHelpIcon(icon)" />
                </div>
            </div>
        </ve-popup>

    </div>
</div>
</template>

<script>
import backend from '../backend';
import api from '../portalapi';
import locale from '../locale';
import uihelper from '../helpers/uihelper';
import { Chrome } from 'vue-color';
import { mixin as clickaway } from "vue-clickaway";
import FormRenderer from '../components/FormRenderer';

import KYCSettings from '../components/settings/KYCSettings';
import IDCSettings from '../components/settings/IDCSettings';
import UserList from '../components/settings/UserList';

export default {
    name: 'Template',
    mixins: [clickaway],
    components: {
        Chrome,
        FormRenderer,
        KYCSettings,
        IDCSettings,
        UserList
    },
    data() {
        return {
            vueHack: 'inital',
            pageHeader: 'AML Portal',
            logoUrl: 'bhlogo.svg',
            logoWidth: 400,
            logoTop: 5,
            logoLeft: 5,
            testModel: false,
            colors: '#194d33',
            kycIndex: 0,
            kycObject: {},
            idcIndex: 0,
            idcObject: {},
            tabKey: '',
            tabHeader: 'Settings',
            tabVisible: true,
            tabs: { tab1: true },
            tabTitles: { tab1: { label: 'Title' } },
            isSaving: false,
            translations: {},
            translationsName: '',
            translationsCompany: '',
            questionKey: '',
            selectedColorIndex: -1,
            selectedTranslationKey: undefined,
            selectedTranslationIndex: -1,
            internetExplorer: false,
            loadedCards: {},
            newTextPopup: false,
            newTextInput: '',
            newTextError: '',
            editTextPopup: false,
            editTextLang: undefined,
            editLocalizedPopup: false,
            editLocalizedKey: '',
            selectIconPopup: false,
            selectIconRole: '',
            selectIconKey: '',
            selectIconIndex: 0,
            availableIcons: [],
            translationPopup: false,
            translationPopupKey: '',
            translationPopupLocale: '',
            translationPopupTabModel: {},
            translationPopupTabOptions: {},
            translationPopupInput: '',
            helpTabsVisible: true,
            helpTabs: { editor: true, owner: false },
            helpTabsTitles: { editor: { key: 'settings_hc_editor' }, owner: { key: 'settings_hc_owner' }},
            kycLandingKey: 'kyc_landing_editor',
            landingTabs: { editor: true, signed: false, aborted: false },
            landingTabsTitles: { editor: { key: 'settings_lp_editor' }, signed: { key: 'settings_lp_signed' }, aborted: { key: 'settings_lp_aborted' }}
        }
    },
    watch: {
        landingTabs: {
            // Should be triggered when data in Customer.vue is changed
            deep: true,
            handler(val) {
                if (val.editor) this.kycLandingKey = 'kyc_landing_editor';
                if (val.signed) this.kycLandingKey = 'kyc_landing_signed';
                if (val.aborted) this.kycLandingKey = 'kyc_landing_aborted';
            }
        },
    },
    methods: {
        getDisplayTranslation(key) {
            let str = this.translations[key][this.$root.locale.key];
            str = this.removeHtmlTag(str, 'p');
            str = this.removeHtmlTag(str, 'ul');
            str = this.removeHtmlTag(str, 'li');
            str = this.removeHtmlTag(str, 'strong');
            str = str.split('&nbsp;').join(' ');
            if (str.length > 100) str = str.substring(0, 97) + '...';
            return str;
        },
        getLocalTranslation(key) {
            return this.___(this, key);
        },
        removeHtmlTag(input, text) {
            input = input.split('<' + text + '>').join('');
            return input.split('</' + text + '>').join('');
        },
        updateValue(val) {
            console.log(val);
            let selected = this.$root.config.colors[this.selectedColorIndex];
            if (this.$root.darkMode) {
                selected.darkValue = val.hex;
                document.documentElement.style.setProperty('--' + selected.key, selected.darkValue);
            }else {
                selected.value = val.hex;
                document.documentElement.style.setProperty('--' + selected.key, selected.value);
            }
        },
        handleColorClick(index) {
            this.selectedColorIndex = index;
            if (this.$root.darkMode) {
                this.colors = this.$root.config.colors[index].darkValue;
            }else {
                this.colors = this.$root.config.colors[index].value;
            }
            console.log('si = ' + index)
        },
        handleTranslationClick(key, index) {
            this.selectedTranslationKey = key;
            this.selectedTranslationIndex = index;
        },
        showNewTextPopup() {
            this.newTextPopup = true;
        },
        showEditTextPopup(lang) {
            this.editTextLang = lang;
            this.editTextPopup = true;
        },
        saveEditText() {
            this.translations[this.selectedTranslationKey][this.editTextLang] = this.$refs.texteditor.getText();
        },
        saveNewText() {
            if (this.translations[this.newTextInput]) {
                this.newTextError = 'Nøkkelen finnes allerede!';
                return;
            }
            this.$set(this.translations, this.newTextInput, { tt: false, nb_NO: '', en_EN: '' });
            this.newTextInput = '';
            this.newTextError = '';
            this.newTextPopup = false;
        },
        showTranslationPopup(key) {
            this.translationPopupTabOptions = {};
            this.translationPopupTabModel = {};
            for (let i in this.$root.config.locales) {
                if (this.$root.config.locales[i].enabled) {
                    this.$set(this.translationPopupTabOptions, this.$root.config.locales[i].key, { label: this.$root.config.locales[i].name, image: this.$root.images.flags[this.$root.config.locales[i].key] });
                    this.$set(this.translationPopupTabModel, this.$root.config.locales[i].key, this.$root.config.locales[i].key == this.$root.locale.key);
                }
            }
            this.translationPopupKey = key;
            this.translationPopupLocale = this.$root.locale.key;
            this.translationPopupInput = this.translations[this.translationPopupKey][this.translationPopupLocale];
            this.translationPopup = true;
        },
        onTranslationPopupLocale(value) {
            this.saveTranslationPopup(false);
            for (let key in value) {
                if (value[key]) this.translationPopupLocale = key;
            }
            this.translationPopupInput = this.translations[this.translationPopupKey][this.translationPopupLocale];
        },
        switchTranslationTextType() {
            this.translations[this.translationPopupKey].tt = !this.translations[this.translationPopupKey].tt;
            console.log('tt set to ' + this.translations[this.translationPopupKey].tt);
            this.$forceUpdate();
        },
        saveTranslationPopup(close) {
            if (this.translations[this.translationPopupKey].tt) {
                this.translations[this.translationPopupKey][this.translationPopupLocale] = this.$refs.texteditor.getText();
            }else {
                this.translations[this.translationPopupKey][this.translationPopupLocale] = this.translationPopupInput;
            }
            if (close) this.translationPopup = false;
        },
        saveLocalizedText() {

        },
        deselectItem(index) {
            this.selectedTranslationKey = undefined;
            this.selectedTranslationIndex = -1;
            this.selectedColorIndex = -1;
        },
        async onSaveClicked() {
            this.isSaving = true;
            console.log('saving');

            this.$parent.invalidateConfigCache();

            if (this.tabKey == 'kyc' || this.tabKey == 'idc') {
                console.log('saving translations');
                await api.setTranslations(this.translationsName, this.translations);
            }

            if (this.tabKey == 'kyc' || this.tabKey == 'idc') {
                const questions = this.$refs['form-renderer'].getSaveData();
                await api.setQuestions(this.questionKey, questions);
            }

            if (this.tabKey == 'users') {
                let response = await api.setDepartments(this.$root.departments, this.$root.partners);
                console.log('res', response);
            }


            if (this.tabKey != 'users') {
                await api.setConfig(this.$root.config);
            }

            

            this.isSaving = false;
        },
        onNewHelpText(newKey) {
            // Find a unique key for this new translation
            console.log('nk', newKey);
            const role = this.helpTabs.owner ? 'o' : 'e';
            this.selectIconRole = this.helpTabs.owner ? 'owner' : 'editor';
            let free = false;
            let count = 0;
            while (!free) {
                count += 1;
                let match = false;
                console.log('checking ' + 'help_' + role + '_' + newKey + '_' + count);
                for (let key in this.translations) {
                    if (key.includes('help_' + role + '_' + newKey + '_' + count)) match = true;
                }
                if (!match) free = true;
            }
            const freeKey = 'help_' + role + '_' + newKey + '_' + count;
            console.log(freeKey + ' is available');

            this.$set(this.translations, freeKey + '_header', {
                en_EN: 'Header',
                nb_NO: 'Overskrift'
            });
            this.$set(this.translations, freeKey + '_text', {
                tt: true,
                en_EN: 'New <b>help text</b>',
                nb_NO: 'Ny <b>hjelpetekst</b>'
            });
            this.$root.config.kycs[this.kycIndex].help[this.selectIconRole][newKey].items.push({
                icon: 'help-o',
                key: freeKey
            });
            this.vueHack = new Date().toString();
        },
        async getTranslations(name, defaults) {
            const response = await api.getTranslations(name);
            //console.log('res', response);
            if (response.texts) {
                const existing = defaults || {};
                this.translations = { ...existing, ...response.texts };
            }else {
                this.translations = defaults || {};
            }
        },
        onHelpIconClicked(args) {
            this.selectIconRole = this.helpTabs.owner ? 'owner' : 'editor';
            this.selectIconKey = args.key;
            this.selectIconIndex = args.index;
            this.selectIconPopup = true;
        },
        onDeleteHelpLine(args) {
            for (let tkey in this.translations) {
                if (args.line.key == tkey) {
                    delete this.translations[tkey];
                    break;
                }
            }
            const role = this.helpTabs.owner ? 'owner' : 'editor';
            for (let cKey in this.$root.config.kycs[this.kycIndex].help[role]) {
                if (args.key == cKey) {
                    this.$root.config.kycs[this.kycIndex].help[role][cKey].items.splice(args.index);
                }
            }
        },
        selectHelpIcon(icon) {
            this.$root.config.kycs[this.kycIndex].help[this.selectIconRole][this.selectIconKey].items[this.selectIconIndex].icon = icon;
            this.selectIconPopup = false;
        },
        async getQuestions(key) {
            const response = await api.getQuestions(key);
            //console.log('res', response);
            if (response.questions) {
                this.loadedCards = response.questions;
            }else {
                this.loadedCards = {};
            }
        },
        setDefaultTranslations() {
            if (this.tabKey == 'kyc') {
                this.translations = {...this.translations, ...locale.getDefaultKycTranslations()};
            }else if (this.tabKey == 'idc') {
                this.translations = {...this.translations, ...locale.getDefaultIdcTranslations()};
            }
        }
    },
    async created() {
        this.$parent.setLoading(true, this.__('loading') + ' ' + this.__('settings').toLowerCase() + '...');
        if (window.document.documentMode) {
            this.internetExplorer = true;
            console.error('Why are you using Internet Explorer?');
        }

        this.$parent.setTitle(this.__('settings'));

        this.availableIcons = uihelper.getAvailableIcons();

        // TODO remove this eventually
        this.translationsCompany = 'all';

        switch (this.$route.query.for) {
            case 'portal':
                this.tabKey = 'portal';
                this.tabHeader = this.__('settings_for') + ' ' + this.__('settings_show_portal_head');
                this.pageHeader = 'AML Portal';
                this.tabs = { tabGeneral: true, tabTexts: false };
                this.tabVisible = false;
                this.tabTitles = {
                    tabGeneral: { label: 'Generelt', icon: 'controls' },
                    tabTexts: { label: 'Tekster/Oversettelser', icon: 'globe' },
                };
                this.translationsName = 'portal';
                await this.getTranslations(this.translationsName);
                break;
            case 'kyc':
                this.tabKey = 'kyc';
                this.tabHeader = this.__('settings_for') + ' ' + this.__('settings_show_kyc_head');
                this.pageHeader = 'Egenerklæringsskjema Juridisk Person';
                this.tabs = { tabGeneral: true, tabQuestions: false, /* tabTexts: false, */ tabHelp: false };
                this.tabTitles = {
                    tabGeneral: { key: 'settings_general', icon: 'controls' },
                    tabQuestions: { key: 'settings_purpose', icon: 'form' },
                    /* tabTexts: { label: 'Tekster/Oversettelser', icon: 'globe' }, */
                    tabHelp: { key: 'settings_help_texts', icon: 'help-o' },
                };
                this.translationsName = 'kyc';
                await this.getTranslations(this.translationsName, locale.getDefaultKycTranslations());
                this.questionKey = this.$route.query.key || 'kyc1';
                for (let i in this.$root.config.kycs) {
                    if (this.$root.config.kycs[i].key == this.questionKey) this.kycIndex = i;
                }
                this.kycObject = this.$root.config.kycs[this.kycIndex];

                if (this.$root.config.kycs[this.kycIndex].settings.landingPage) {
                    this.$set(this.tabs, 'tabLanding', false);
                    this.$set(this.tabTitles, 'tabLanding', { key: 'settings_landing_page', icon: 'sign' });
                }
                //console.log('kyc index', this.kycIndex);
                await this.getQuestions(this.questionKey);
                break;
            case 'idcontrol':
                this.tabKey = 'idc';
                this.tabHeader = this.__('settings_for') + ' ' + this.__('settings_show_idc_head');
                this.tabVisible = true;
                //this.pageHeader = 'ID-kontroll';
                this.tabs = { tabGeneral: true};
                this.tabTitles = {
                    tabGeneral: { key: 'settings_general', icon: 'controls' },
                    /* tabQuestions: { key: 'settings_purpose', icon: 'form' }, */
                    /* tabTexts: { label: 'Tekster/Oversettelser', icon: 'globe' }, */
                };
                this.questionKey = this.$route.query.key || 'idc1';
                for (let i in this.$root.config.idcs) {
                    if (this.$root.config.idcs[i].key == this.questionKey) this.idcIndex = i;
                }
                if (this.$root.config.idcs[this.idcIndex].designer) {
                    this.$set(this.tabs, 'tabQuestions', false);
                    this.$set(this.tabTitles, 'tabQuestions', { key: 'settings_purpose', icon: 'form' });
                }
                this.translationsName = this.$root.config.idcs[this.idcIndex].key;
                await this.getTranslations(this.translationsName, locale.getDefaultIdcTranslations());
                await this.getQuestions(this.questionKey);
                this.idcObject = this.$root.config.idcs[this.idcIndex];
                console.log('idc index', this.idcIndex);
                break;
            case 'themes':
                this.tabKey = 'theme';
                this.tabHeader = this.__('settings_show_colors_head');
                this.tabVisible = false;
                break;
            case 'users':
                this.tabKey = 'users';
                this.tabHeader = 'Avdelinger / Partnere';
                this.tabs = { tabDepartments: true, tabPartners: false };
                this.tabTitles = {
                    tabDepartments: { label: 'Avdelinger', icon: 'company' },
                    tabPartners: { label: 'Partnere', icon: 'people' },
                };
                break;
        }

        this.$parent.setLoading(false);
    }
}
</script>

<style>

.template-header {
    width: 100%;
    position: relative;
    float: left;
    min-height: 72px;
    background-color: var(--stepper-bg);
    box-shadow: 1px 1px 2px rgba(166, 177, 198, 0.3);
    box-sizing: border-box;
}
.template-header-content {
    float: left;
    width: 100%;
    height: 60px;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 10px;
    border-bottom: solid 1px var(--text-disabled);
    box-sizing: border-box;
}
.template-header-actions {
    position: absolute;
    top: 11px;
    right: 20px;
}



.vc-chrome {
    box-shadow: none !important;
}

.stng-color {
    display: flex;
    padding: 6px 20px;
}
.stng-color label {
    margin-top: 6px;
    margin-left: 20px;
    font-size: 22px;
}

.stng-color-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}



.flag {
    width: 22px;
    border-radius: 3px;
    margin-left: 6px;
}
.flag-d {
    width: 22px;
    border-radius: 3px;
    margin-left: 6px;
    opacity: 0.3;
}

.trans-actions {
    width: 100%;
    display: flex;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 20px;
    box-sizing: border-box;
}
.trans-filters {
    flex-grow: 100;
}
.trans-new {
    float: right;
}


.trans-desc {
    float: left;
    width: 100%;
    margin-top: 10px;
    padding: 0px 5px;
}
.trans-desc img {
    width: 30px;
    border-radius: 2px;
}
.trans-desc label {
    margin-left: 10px;
}
.trans-area {
    padding: 0px 4px;
}



.logo-preview {

}
.logo-preview-bar {
    width: 100%;
    height: 60px;
    background-color: var(--header);
}

.logo-preview-bar img {
    
}

.logo-text {
    float: left;
    margin-top: 14px;
    margin-left: 10px;
    font-family: "Roboto Slab", serif;
    font-size: 24px;
}

.pdf-preview {
    float: left;
    width: 100%;
}
.pdf-preview label {
    float: left;
    width: 100%;
    margin-top: 20px;
    font-size: 22px;
}





.iconsel-item {
    float: left;
    width: 12.5%;
    margin-bottom: 10px;
}



.template-help {
    float: left;
    width: 100%;
    margin-top: 10px;
}
.template-help-text {
    margin-bottom: 10px;
}
.template-help-head {
    font-size: 18px;
    color: var(--text-strong);
}
.template-help-desc {
    font-size: 15px;
    color: var(--text-weak);
}



.kyc-landing-logo {
    float: left;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}
.kyc-landing-logo img {
    width: 360px;
}

.kyc-landing-header {
    float: left;
    width: 100%;
    margin-top: 40px;
}
.kyc-landing-header-center {
    display: flex;
    width: fit-content;
    margin: 0 auto;
}
.kyc-landing-icon {
    width: 45px; height: 45px;
    padding-top: 5px;
    padding-left: 5px;
    border-radius: 50%;
    background-color: var(--primary);
    box-sizing: border-box;
}
.kyc-landing-icon-error {
    width: 45px; height: 45px;
    padding-top: 4px;
    padding-left: 4px;
    border-radius: 50%;
    background-color: var(--error-background);
    box-sizing: border-box;
}
.kyc-landing-text {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 20px;
}
.kyc-landing-footer {
    float: left;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}


</style>
