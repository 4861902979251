<template>
<div class="flw100" :style="'margin-top: ' + ($root.phoneMode ? '0' : '60') + 'px;'">
    <VFFlowVisualizer :envelope="envelope" :recipient="recipient"  />
    <div class="slim-page">


        <!-- Signing options for editor (direct signing) -->
        <div v-if="lostEditor">
            <vap-card list :header="__t('landing-filled')">
                <div style="margin-bottom: 20px;">
                    {{ __t('landing-filled-hint') }}
                </div>
                <VFRow>
                    <VFGroup>
                        <VFLabel desc :text="__t('landing-awaiting-signing')" /><br>
                        <VFLabel input :text="nextSigner.name" />
                    </VFGroup>
                    <VFGroup>
                        <VFLabel desc :text="__t('landing-selected-method')" /><br>
                        <VFLabel input :text="getMethodName(nextSigner.method)" />
                    </VFGroup>
                </VFRow>
                <VFButtonRow alignLeft :phoneMode="$root.phoneMode" style="margin-top: 10px;">
                    <VFButton :icon="'sign'" :text="__t('landing-goto-signing')" @clicked="goToSigning" :working="workingDirect" />
                    <VFButton :icon="'edit'" :text="__t('landing-change-method')" outline @clicked="showMethodDialog" />
                </VFButtonRow>
            </vap-card>
        </div>


        <!-- Signing options for signers (email-link) -->
        <div v-if="signerPortal">
            <vap-card list :header="__t('landing-welcome')">
                <div style="margin-bottom: 20px;">
                    {{ __t('landing-welcome-hint') }}
                </div>
                <VFRow>
                    <VFGroup>
                        <VFLabel desc :text="__t('landing-awaiting-signing')" /><br>
                        <VFLabel input :text="nextSigner.name" />
                    </VFGroup>
                    <VFGroup>
                        <VFLabel desc :text="__t('landing-selected-method')" /><br>
                        <VFLabel input :text="getMethodName(nextSigner.method)" />
                    </VFGroup>
                </VFRow>
                <VFButtonRow alignLeft :phoneMode="$root.phoneMode" style="margin-top: 10px;">
                    <VFButton :icon="'sign'" :text="__t('landing-goto-signing')" @clicked="redirectToCheckout" />
                    <VFButton :icon="'edit'" :text="__t('landing-change-method')" outline @clicked="showMethodDialog" />
                </VFButtonRow>
                <div class="flw100" style="margin-top: 14px; font-style: italic; color: var(--text-title);">
                    {{ __t('landing-signer-hint') }}
                </div>
            </vap-card>
        </div>


        <!-- Aborted message -->
        <div v-if="abortedBySender">
            <vap-card list :header="__t('landing-aborted-head')">
                <div>
                    {{ __t('landing-aborted-message') }}
                </div>
            </vap-card>
        </div>


        <div v-if="autoRedirectForm">
            <vap-card>
                <VFButton :text="'Should auto-redirect to form'" @clicked="onAutoRedirectToForm" />
            </vap-card>
        </div>


        <div v-if="landing">
            <vap-card>
                <vap-text-editor :value="formData.flowConfig.landingParams[landingRole].message[$root.locale.key]" />
            </vap-card>
        </div>


        <!-- Contact information -->
        <div v-if="showContact" class="flv2-landing-contact">
            <span>{{ __t('landing-contact') }}</span>
            <span><b>{{ sender.name }}</b></span>
            <span>{{ __t('landing-contact-on') }}</span>
            <span><b>{{ sender.email }}</b></span><br>
            <span>{{ __t('landing-contact-please') }}</span><br>
            <span>{{ __t('landing-contact-id') + ': ' }}</span>
            <span><b>{{ envelope.id }}</b></span>
            <span>{{ ' ' + __t('landing-contact-name') + ': ' }}</span>
            <span><b>{{ documentName }}</b></span>
        </div>


        <!-- Signing methods -->
        <VFMethods v-if="showMethods" :reference="formData" :cardData="methodData" />


    </div>

    <PopupV1 v-if="methodDialog.show" :header="__t('landing-select-method')" @close="methodDialog.show = false" :dialog="methodDialog">
        <template v-slot:content>
            <div class="flv2-landing-methods">
                <div class="flv2-landing-method" v-for="(method, i) in methodDialog.methods" :key="'lm' + i" :class="[
                    {'flv2-landing-method-last': i == methodDialog.methods.length - 1 },
                    {'flv2-landing-method-disabled': methodDialog.working },
                ]" @click="onMethodSelected(method)">
                    {{ method[$root.locale.key] }}
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="methodDialog.show = false" :disabled="methodDialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v2lib from '../../v2lib';
import verified from '../../verified';
import methodHelper from './modules/signing/methodHelper';

import PopupV1 from '../../v1ui/PopupV1.vue';
import VFRow from './items/VFRow.vue';
import VFGroup from './items/VFGroup.vue';
import VFLabel from './items/VFLabel.vue';
import VFButton from './items/VFButton.vue';
import VFButtonRow from './items/VFButtonRow.vue';

import VFFlowVisualizer from './items/VFFlowVisualizer.vue';
import VFMethods from './items/VFMethods.vue';

export default {
    name: "LandingEditor",
    components: {
        PopupV1,
        VFRow,
        VFGroup,
        VFLabel,
        VFButton,
        VFButtonRow,
        VFFlowVisualizer,
        VFMethods,
    },
    props: {
        envelope: { type: Object, default: null },
        recipient: { type: Object, default: null },
        formData: { type: Object, default: null },
    },
    data() {
        return {
            methodData: { mode: 'sign' },
            nextSigner: {},
            sender: {},
            documentName: 'Unknown document',

            landing: false,
            landingRole: '',

            lostEditor: false,
            lostSigner: false,

            autoRedirectForm: false,

            signerPortal: false,

            abortedBySender: false,

            showMethods: false,
            showContact: false,

            methodDialog: { show: false, working: false, status: '', methods: [] },

            workingDirect: false,
        }
    },
    methods: {


        //#region Signing -----------------------------------------------------------------------------------------------------

        async goToSigning() {
            this.workingDirect = true;

            const result = await v2lib.getSignLinkForm(this.$root, this.envelope, this.envelope.userdata.mailConfig);

            console.log('result', result);

            sessionStorage.setItem(this.envelope.id, v2lib.editorToken);

            window.location.href = result.url;

            this.workingDirect = false;
        },

        showMethodDialog() {
            this.methodDialog.methods = methodHelper.getAvailableSignMethods(this.$root, this.formData);
            this.methodDialog.show = true;
        },

        async onMethodSelected(method) {
            if (this.methodDialog.working) return;

            this.methodDialog.working = true;
            this.methodDialog.status = 'Changing signing method';

            await verified.setSigningMethod(this.envelope.id, this.nextSigner.id, method.value);
            await this.$parent.$parent.$parent.reloadEnvelope();

            this.methodDialog.working = false;
            this.methodDialog.show = false;
            this.setNextSigner();
        },

        getMethodName(method) {
            return methodHelper.getMethodName(this.$root, method, this.$root.locale.key);
        },

        onAutoRedirectToForm() {
            this.$parent.$parent.$parent.navigate({ path: `/fform/${this.$route.params.envelopeId}/${this.$route.params.recipientId}`, query: this.$route.query });
        },

        getCheckoutLink() {
            console.log('route', this.$route);
            let signUrl = 'https://checkout.verified.eu/checkout/?u=' + this.recipient.uid + '&';
            signUrl += 'lang=' + this.$root.locale.key;


            let baseUrl = this.$root.baseUrl;
            if (this.$root.isAWS) baseUrl += '/web/aml-portal/#';

            const redirectUrl = baseUrl + '/lform/' + this.envelope.id + '/' + this.recipient.id + '?lang=' + this.$root.locale.key;
            console.log('redirectUrl', redirectUrl);

            signUrl += '&redirect_to=' + redirectUrl;
            signUrl += '&access_token=' + v2lib.editorToken;

            console.log('signUrl', signUrl);
            return signUrl;
        },

        redirectToCheckout() {
            let signUrl = this.getCheckoutLink();
            sessionStorage.setItem(this.envelope.id, v2lib.editorToken);
            window.location.href = signUrl;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        setNextSigner() {
            for (const i in this.envelope.content.recipients) {
                const recipient = this.envelope.content.recipients[i];
                //console.log('rec', recipient);
                if (recipient.role.action == 'sign' && !recipient.completed) {
                    this.nextSigner = {
                        name: recipient.givenName + ' ' + recipient.familyName,
                        method: recipient.signingMethod,
                        id: recipient.id
                    };
                    break;
                }
            }
        },

    },
    async created() {
        //console.log('formData', this.formData);

        this.setNextSigner();
        this.sender = {
            name: this.envelope.content.sender.givenName + ' ' + this.envelope.content.sender.familyName,
            email: this.envelope.content.sender.email
        };
        this.documentName = this.envelope.content.documents[0].name;

        if (this.envelope.content.aborted) {

            this.showContact = true;
            this.abortedBySender = true;
            
        }else {
            if (this.recipient.role.action == 'edit') {

                // Envelope will not have time to be updated, so if the route has the reload query treat it as completed
                if (this.recipient.completed || this.$route.query.reload) {
                    // Editor is opening the email-link AFTER filling the form

                    if (this.envelope.userdata._isForwarded) {
                        // Filling was completed and form forwarded
                        // Show landing for editor
                        this.landingRole = 'filled';
                        this.landing = true;

                    }else {
                        // Filling was completed, but editor has opened the link again
                        this.lostEditor = true;
                        this.showContact = true;
                        this.showMethods = true;

                    }

                }else {
                    // Editor is opening the email-link BEFORE filling the form
                    this.autoRedirectForm = true;
                    this.onAutoRedirectToForm();
                    
                }
            }else if (this.recipient.role.action == 'sign') {

                // Envelope will not have time to be updated, so if the route has the reload query treat it as completed
                if (this.recipient.completed || this.$route.query.reload) {
                    // Signer is redirected AFTER signing the form
                    
                    // Check if the recipient rejected
                    let wasRejected = false;
                    for (const i in this.recipient.signatures) {
                        if (this.recipient.signatures[i].status == 'rejected') wasRejected = true;
                    }

                    // Show landing text
                    this.landingRole = wasRejected ? 'aborted' : 'signed';
                    this.landing = true;
                    

                }else {
                    // Signer is opening the email-link BEFORE signing the form

                    this.signerPortal = true;
                    this.showContact = true;
                    this.showMethods = true;
                    //this.autoRedirectForm = true;
                    
                }
            }
        }

    }
}

</script>
<style scoped>

.flv2-landing-weak {
    color: var(--text-weak);
}
.flv2-landing-strong {
    color: var(--text-title);
}

.flv2-landing-contact {
    font-size: 14px;
    color: var(--text-weak);
    text-align: center;
}

.flv2-landing-methods {
    border-radius: 5px;
    border: solid 1px var(--input-border);
}
.flv2-landing-method {
    padding: 8px 15px;
    color: var(--text-title);
    border-bottom: solid 1px var(--input-border);
    cursor: pointer;
}
.flv2-landing-method:hover {
    background-color: var(--item-hover);
}
.flv2-landing-method-last {
    border-bottom: none;
}
.flv2-landing-method-disabled {
    color: var(--text-disabled);
    cursor: not-allowed;
}

</style>
