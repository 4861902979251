<template>
<div class="vf-brreg">
    
    <ve-brreg-search @company-selected="onBrregResult" />

</div>
</template>

<script>
import aml from '@/aml';

export default {
    name: 'VFBrregSearch',
    props: {
        value: [String, Number],
        placeholder: { type: String, default: '' },

        password: { type: Boolean, default: false },
        numeric: { type: Boolean, default: false },
        maxdigits: { type: String, default: '' },

        validation: { type: Object, default: () => { return { done: false } }},
        dictionary: { type: Object, default: null },
        disabled: { type: Boolean, default: false },
        dotted: { type: Boolean, default: false },

        required: { type: Boolean, default: false },
        noValidation: { type: Boolean, default: false },
        noHighlight: { type: Boolean, default: false },
        
        id: { type: String, default: 'id' },
        designer: { type: Boolean, default: false },
        activeLogic: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

        onBrregResult(company, rawData) {
            const brregResult = aml.processBrreg(rawData);
            this.$emit('match', brregResult);
        },

    },
    mounted() {

    }
}
</script>
<style scoped>



</style>
