const dataModel = {

    getEmptyModel() {
        return {
            source: '',
            version: 0,
            simplified: false,
            name: '',
            orgnr: '',
            info: {
                number: '',
                dunsNumber: '',
                country: '',
                dateRegistered: '',
                dateEstablished: '',
                purpose: '',
                language: '',
                employeesFrom: '',
                employeesTo: '',
                jurisdiction: '',
                sources: []
            },
            form: {
                formCode: '',
                formDescription: '',
                industryCode: '',
                industryDescription: '',
                sources: []
            },
            registration: {
                inVatRegistry: 'X',
                inBusinessRegistry: 'X',
                inBrreg: 'X',
                inVoluntaryRegistry: 'X',
                bankrupt: 'X',
                liquidation: 'X',
                compulsoryLiquidation: 'X',
                insolvency: 'X',
                charges: 'X',
                canFile: 'X',
                officeInDispute: 'X',
                undeliverableAddress: 'X',
                employer: 'X',
                taxAssessment: 'X',
                active: 'X',
                status: '',
                displayValues: [],
                sources: []
            },
            signature: {
                available: false,
                description: [],
                options: [],
                conclusive: false,
                remarks: [],
                sources: []
            },
            procura: {
                available: false,
                list: []
            },
            address: {
                business: {
                    street: '',
                    zip: '',
                    city: '',
                    commune: '',
                    communeNumber: '',
                    country: '',
                }
            },
            contact: {
                phone: '',
                website: ''
            },
            naceCodes: [],
            naceSources: [],
            roles: [],
            shareholders: [],
            beneficials: [],
            controlEntities: [],
            ownership: {
                keyIndicators: {},
                knowledge: {},
                selfOwnership: {},
                votingPower: {},
                naceCodes: [],
                sources: [],
                beneficialsAvailable: 'X',
                beneficialsSuspicious: 'X',
                indicatorsAvailable: false
            },
            subsidiaries: [],
            subsidiariesAvailable: false,
            previousNames: [],
            economy: {
                accountant: {
                    name: '',
                    orgnr: ''
                },
                auditor: {
                    name: '',
                    orgnr: ''
                },
                basicAvailable: false,
                yearAvailable: false,
                trendAvailable: false,
                year0: {
                    available: false
                },
                year1: {
                    available: false
                },
                year2: {
                    available: false
                },
                year3: {
                    available: false
                },
                year4: {
                    available: false
                }
            }
        };
    },

    getEmptyPersonModel() {
        return {
            source: '',
            version: 0,
            searchParams: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                ssn: '',
                countryOfSsn: '',
                parameters: [],
                mode: ''
            },
            name: '',
            firstName: '',
            lastName: '',
            ssn: '',
            countryOfSsn: '',
            dateOfBirth: '',
            birthplace: {
                available: false,
                country: '',
                city: ''
            },
            gender: '',
            citizenships: [],
            addressPostal: this.getEmptyPersonAddress(),
            addressNational: this.getEmptyPersonAddress(),
            historicAddresses: [],
            historicNames: [],
            isPep: false,
            hasSanction: false,
            pepHits: [],
            pepSummary: {
                hits: 0,
                matchIndicator: 0,
                matchDescription: '',
                title: '',
                sources: []
            },
            sanctionHits: [],
            sanctionSummary: {
                hits: 0,
                matchIndicator: 0,
                matchDescription: '',
                title: '',
                sources: []
            },
            roles: {
                available: false,
                companies: [],
                sources: []
            },
            sources: []
        };
    },

    getEmptyPersonAddress() {
        return {
            available: false,
            country: '',
            street: '',
            zip: '',
            city: '',
            registeredDate: '',
            residents: 0,
            houseType: '',
            source: ''
        };
    },

    getEmptyCitizenship() {
        return {
            country: ''
        };
    },

    getEmptyPepHit() {
        return {
            source: '',
            provider: '',
            matchIndicator: 0,
            matchDescription: '',
            nameMatchValue: 0,
            name: '',
            title: '',
            comment: ''
        };
    },

    getEmptySanctionHit() {
        return {
            source: '',
            matchIndicator: 0,
            matchDescription: '',
            nameMatchValue: 0,
            name: '',
            title: '',
            comment: ''
        };
    },

    getEmptyPersonRole() {
        return {
            name: '',
            orgnr: '',
            roleId: '',
            from: '',
            to: ''
        };
    },

    getEmptyYear() {
        return {
            available: false
        };
    },

    getEmptyEconValue() {
        return {
            available: false,
            value: 0,
            shortDisplay: '',
            fullDisplay: ''
        };
    },

    getEmptyBeneficial() {
        return {
            identified: false,
            name: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            country: '',
            street: '',
            zip: '',
            city: '',
            citizenship: '',
            ssn: '',
            shareType: '',
            shareRange: '',
            share: '',
            personId: ''
        };
    },

    getEmptySubsidiary() {
        return {
            identified: false,
            name: '',
            orgnr: '',
            dunsNumber: '',
            country: '',
        };
    },

    getEmptyControlEntity() {
        return {
            identified: false,
            type: 'X',
            name: '',
            orgnr: '',
            registeredIn: '',
            registeredCountry: '',
            registeredAuthority: '',
            formCode: '',
            formDescription: '',
            country: '',
            street: '',
            zip: '',
            city: '',
            citizenship: '',
            shareType: '',
            shareRange: '',
            share: '',
            controlTypes: []
        };
    },

    getEmptyShareholder() {
        return {
            identified: false,
            type: 'X',
            name: '',
            orgnr: '',
            ssn: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            country: '',
            street: '',
            zip: '',
            city: '',
            share: '',
            personId: ''
        };
    },

    getEmptyRole() {
        return {
            roleId: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            ssn: '',
            country: '',
            address: '',
            zip: '',
            city: '',
            personId: ''
        };
    },

    getEmptyPreviousName() {
        return {
            name: '',
            from: '',
            to: ''
        };
    },

    getRrhShare(rrh) {
        if (rrh.share) return rrh.share;
        if (rrh.shareRange) {
            if (rrh.shareRange == 0.25) {
                return '25-50';
            }else if (rrh.shareRange == 0.5) {
                return '50-75';
            }else if (rrh.shareRange == 0.75) {
                return '75-100';
            }else if (rrh.shareRange == 1) {
                return '100';
            }else {
                return '[' + rrh.shareRange + ']';
            }
        }
        return '';
    },

    getSimpleCompanyData(input) {

        if (!input) return {};

        const output = JSON.parse(JSON.stringify(input));

        output.economy.year0 = {
            available: false
        };
        output.economy.year1 = {
            available: false
        };
        output.economy.year2 = {
            available: false
        };
        output.economy.year3 = {
            available: false
        };
        output.economy.year4 = {
            available: false
        };
        output.economy.yearAvailable = false;
        output.economy.trendAvailable = false;

        output.simplified = true;
        return output;
    },

};
export default dataModel;