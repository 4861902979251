<template>
<div>

    <PropertyItem padded :header="'Fileupload properties'" :icon="'upload'">

        <DesignerGroup c6>
            <DesignerCheckbox v-model="input.singleFile" :text="'Single file'" />
        </DesignerGroup>
        <DesignerGroup c6>
            <DesignerCheckbox v-model="input.autoPreview" :text="'Auto preview'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Hint text'" />
            <DesignerInput v-model="input.hint[$root.locale.key]" :placeholder="'Not set'" />
        </DesignerGroup>

        <DesignerGroup c6>
            <DesignerLabel :text="'Browse text'" />
            <DesignerInput v-model="input.browse[$root.locale.key]" :placeholder="'Not set'" />
        </DesignerGroup>
        <DesignerGroup c6>
            <DesignerLabel :text="'File key'" />
            <DesignerInput v-model="input.fileKey" :placeholder="'Not set'" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Allowed files'" />
            <DesignerDropdown v-model="input.fileType" :options="fileTypeOptions" :placeholder="'Not set'" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerDropdown from '../ui/inputs/DesignerDropdown.vue';

export default {
    name: 'FileuploadProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerDropdown,
    },
    props: {
        input: { type: Object, default: null },
    },
    data() {
        return {
            fileTypeOptions: [
                { value: 'all', display: 'All files' },
                { value: 'pdf', display: 'PDF only' },
            ],
        }
    }
}
</script>