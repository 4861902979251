<template>
<div>

    <OwnershipMapCanvas :rounded="rounded" :preview="preview" />
    
</div>
</template>
<script>

import OwnershipMapCanvas from './OwnershipMapCanvas.vue';

export default {
    name: 'OwnershipMap',
    components: {
        OwnershipMapCanvas,
    },
    props: {
        mapData: { type: Object, default: () => { return null } },
        rounded: { type: Boolean, default: false },
        preview: { type: Boolean, default: false },
    },
    data() {
        return {
            testData: {
                cards: [
                    { display: 'Kløfta Bilservice', type: 'org', x: 0, y: 0 },

                    { display: 'Bilservice Gruppen AS', type: 'org', x: -2, y: -1 },
                    { display: 'Emil A Dahlen Kvernes', type: 'per', x: 0, y: -1 },
                    { display: 'Tore Ingar Marthinsen', type: 'per', x: 2, y: -1 },

                    { display: 'Frank Martinsen', type: 'per', x: -3, y: -2 },
                    { display: 'Katrine Normann', type: 'per', x: -1, y: -2 },
                ],
                lines: [
                    { x1: -2, y1: -1, x2: 0, y2: 0, x1p: 0, x2p: -1, share: '70 %' },
                    { x1: 0, y1: -1, x2: 0, y2: 0, x1p: 0, x2p: 0, share: '10 %' },
                    { x1: 2, y1: -1, x2: 0, y2: 0, x1p: 0, x2p: 1, share: '20 %' },

                    { x1: -3, y1: -2, x2: -2, y2: -1, x1p: 0, x2p: -1, share: '50 %' },
                    { x1: -1, y1: -2, x2: -2, y2: -1, x1p: 0, x2p: 1, share: '50 %' },
                ]
            },
            yDistance: 200,
            cardHeight: 40,
        }
    },
    methods: {

        onDrawFrame(context) {

            if (this.mapData) {
                for (const i in this.mapData.cards) {
                    this.drawCard(context, this.mapData.cards[i]);
                }
                for (const i in this.mapData.lines) {
                    this.drawLine(context, this.mapData.lines[i]);
                }
            }else {
                for (const i in this.testData.cards) {
                    this.drawCard(context, this.testData.cards[i]);
                }
                for (const i in this.testData.lines) {
                    this.drawLine(context, this.testData.lines[i]);
                }
            }

        },

        
        //#region Drawing Helpers ---------------------------------------------------------------------------------------------

        drawCard(context, card) {

            const x = card.x * 100;
            const y = card.y * (this.cardHeight + this.yDistance);

            context.fillStyle = card.type == 'org' ? '#F8EED0' : '#E9F1F6';
            context.fillRect(x, y, 170, 20);

            context.fillStyle = card.type == 'org' ? '#FCF7E9' : '#F6FAFC';
            context.fillRect(x, y + 20, 170, 20);

            context.fillStyle = "#000000";
            context.font = `12px courier`;
            context.fillText(card.display, x + 5, y + 14);
        },

        drawLine(context, line) {

            fromx, fromy, tox, toy

            let fromx = line.x1 * 100;
            let fromy = (line.y1 * (this.cardHeight + this.yDistance)) + 40;

            let tox = line.x2 * 100;
            let toy = line.y2 * (this.cardHeight + this.yDistance);

            fromx += 85;
            if (line.x1p != 0) fromx += (line.x1p * 20);

            tox += 85;
            if (line.x2p != 0) tox += (line.x2p * 20);

            

            context.beginPath();
            var headlen = 10;
            var dx = tox - fromx;
            var dy = toy - fromy;
            var angle = Math.atan2(dy, dx);
            context.moveTo(fromx, fromy);
            context.lineTo(tox, toy);
            context.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
            context.moveTo(tox, toy);
            context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
            context.stroke();

            var midX = fromx + (tox - fromx) * 0.30;
            var midY = fromy + (toy - fromy) * 0.30;

            let midW = 50;
            midX = midX - (midW / 2);

            context.fillStyle = "#FFFFFF";
            context.fillRect(midX, midY, midW, 20);
            context.strokeRect(midX, midY, midW, 20);

            context.fillStyle = "#000000";
            context.font = `12px courier`;
            context.fillText(line.share, midX + 5, midY + 14);

        },

        //#endregion ----------------------------------------------------------------------------------------------------------


    },
    created() {
        console.log('map initialized', this.mapData);

    }
}
</script>