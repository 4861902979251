<template>
<div>
    
    <PropertyHeader :header="'About'">
        <template v-slot:actions>
            
        </template>
    </PropertyHeader>

    <div class="flw100" style="margin-top: 30px;">

        <div style="width: fit-content; margin: 0 auto;">
            <div class="frv2-menu-title">
                <div class="frv2-menu-title-text">
                    <label>{{ 'form builder' }}</label>
                </div>
                <div class="frv2-menu-title-logo">
                    <label>{{ 'POWERED BY' }}</label>
                    <img :src="$root.images.vfblue" />
                </div>
            </div>
        </div>

        <div class="flw100" style="text-align: center; color: white; margin-top: 20px;">
            {{ 'version 0.11 BETA' }}
        </div>

        <div class="flw100" style="color: white; margin-top: 20px; border-bottom: solid 1px var(--fb-menu-c); padding: 4px 15px;">
            {{ 'Changelog' }}
        </div>

    </div>

    <PropertyItem :header="'v. 0.11 (23.05.2022)'" :icon="'bug'">
        <div class="frv2-about-log">
            <ul>
                <li>
                    {{ 'New layout for the form builder' }}
                </li>
            </ul>
        </div>
    </PropertyItem>

</div>
</template>
<script>
import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';

export default {
    name: 'InfoPropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() { 
        return {
            
        }
    },
    methods: {

    },
    created() {
        
    }
}
</script>
<style scoped>

.frv2-menu-title {
    padding-top: 7px;
    padding-left: 26px;
    padding-right: 20px;
}
.frv2-menu-title-text {
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    letter-spacing: 1.5px;
    color: white;
}
.frv2-menu-title-logo {
    font-size: 13px;
    font-weight: bold;
    color: white;
}
.frv2-menu-title-logo label {
    float: left;
    margin-top: 2px;
    opacity: 0.5;
}
.frv2-menu-title-logo img {
    float: left;
    width: 50px;
    margin-top: 1px;
    margin-left: 6px;
}


.frv2-about-log {
    font-size: 14px;
    color: white;
    opacity: 0.8;
}

</style>