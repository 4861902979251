import axios from 'axios';
import jwt from 'jsonwebtoken';
import log from '../../../../log';
import v2lib from '../../../../v2lib';
import api from '@/portalapi';
import flowHelper from '../../flowHelper';
import v1backoffice from '../../../../v1admin/v1backoffice';
import v1exceptions from '../../../../v1helpers/v1exceptions';
const idcModuleHelper = {

    getEmptyConfig() {
        return {
            type: 'idc',
            icon: 'card',
            methods: {
                bankidno: true,
                bankidse: true,
                nemid: true,
                passreader: true,
            },
            notifyOwner: false,
        };
    },

    async onFormCreated(root, reference, designer, module, pages, isOwner) {

        // Check if url has the id token
        if (root.$route.query.id_token) {
            log.x('IDC Module: URL has ID token:', root.$route);

            reference.showMainSpinner(true, root.__t('identity-verifying'));

            // Decode token
            const decoded = jwt.decode(root.$route.query.id_token);
            log.s('IDC Module: ID token decoded:', decoded);

            console.log('envelope', reference.envelope);
            const userdata = reference.envelope.userdata;

            let method = 'unknown';
            if (sessionStorage.getItem('vapoidc')) {
                const data = JSON.parse(sessionStorage.getItem('vapoidc'));
                method = data.method;
            }

            try {
                if (method == 'passport-reader') {
                    const exceptionData = {
                        fullPath: root.$route.fullPath,
                        id_token: root.$route.query.id_token,
                    };
                    const errorData = v1exceptions.getException(root, 'passreaderLog', 'onFormCreated', null, exceptionData);
                    v1backoffice.addError(errorData);
                }
            }catch(ex1) {
                console.log('failed to report data');
            }

            userdata.idcResult = {
                method: method,
                date: new Date()
            };
            if (method == 'bankid-se') {
                userdata.idcResult.firstName = decoded.given_name;
                userdata.idcResult.lastName = decoded.family_name;
                userdata.idcResult.dateOfBirth = decoded.ssn.substring(0, 4) + '-' + decoded.ssn.substring(4, 6) + '-' + decoded.ssn.substring(6, 8);
                userdata.idcResult.name = decoded.given_name + ' ' + decoded.family_name;
                userdata.idcResult.ssn = decoded.ssn;
            }else if (method == 'passport-reader') {
                userdata.idcResult.firstName = root.companyTitleCase(decoded.secondary_identifier);
                userdata.idcResult.lastName = root.companyTitleCase(decoded.primary_identifier);
                userdata.idcResult.dateOfBirth = decoded.birthdate;

                let dob = decoded.birthdate || '';
                if (dob.includes('.')) {
                    const parts = dob.split('.');
                    if (parts.length > 2) {
                        userdata.idcResult.dateOfBirth = parts[2] + '-' + parts[1] + '-' + parts[0];
                    }
                }

                userdata.idcResult.name = userdata.idcResult.firstName + ' ' + userdata.idcResult.lastName;
                userdata.idcResult.ssn = decoded.personal_number;
                userdata.idcResult.documentType = decoded.document_type;
                userdata.idcResult.documentNumber = decoded.document_number;
                userdata.idcResult.issuingCountry = decoded.issuing_country;
                userdata.idcResult.faceMatch = decoded.facematch_level;
                userdata.idcResult.nationality = decoded.nationality;
                userdata.idcResult.gender = decoded.gender;
                userdata.idcResult.placeOfBirth = decoded.place_of_birth;
            }else {
                userdata.idcResult.firstName = decoded.given_name;
                userdata.idcResult.lastName = decoded.family_name;
                userdata.idcResult.dateOfBirth = decoded.birthdate;
                userdata.idcResult.name = decoded.name;
                userdata.idcResult.ssn = decoded.nnin_altsub;
                userdata.idcResult.originator = decoded.originator;
            }
            userdata.idcToken = root.$route.query.id_token;
            userdata.idcDecoded = decoded;

            console.log('userdata', userdata);


            // Support for V0, remove eventually
            if (userdata.idcPerson.oldSystem) {
                await api.submitIDC(userdata.idcPerson.id, reference.envelope.id, userdata.credentials.id);
            }
            

            if (reference.getNextRole() == 'done') {

                //console.log('IDC completed, final submit');

                reference.preparePdfData(userdata);

                if (!reference.testLocally) {

                    await flowHelper.setUserdata(reference.envelope, userdata);

                    // Wait a few seconds for the envelope to update
                    await new Promise(r => setTimeout(r, 2500));

                }else {
                    console.log(JSON.stringify(userdata));
                }

                //console.log('redirect 1');
                //reference.showMainSpinner(false);
                reference.isRedirected = true;
                reference.redirectToLanding();
                //console.log('redirect 3');

            }else {
                // Go to the next role in the form

                reference.goToNextRole();

                //console.log('IDC completed, showing editor');
                //console.log('ref', reference);

                reference.showMainSpinner(false);
            }


            // Log to usage stats
            const statData = {
                type: 'idc',
                cc: userdata.credentials.cc || 'XX',
                src: userdata.idcResult.method,
                eid: reference.envelope.id,
                cid: userdata.credentials.cid,
                ckey: userdata.credentials.key,
                cname: '',
                stat: userdata.credentials.stat || 'demo',
            };
            if (userdata.mailConfig && userdata.mailConfig.values) {
                for (const i in userdata.mailConfig.values) {
                    if (userdata.mailConfig.values[i].key == 'companyName') statData.cname = userdata.mailConfig.values[i].value;
                }
            }
            v1backoffice.addStat(statData);
        }

        const existingData = JSON.parse(root.formData);
        log.x('IDC Module: On form created, existing data:', existingData);

        // Add resources
        root.$set(reference.resources, 'idcMethods', {
            type: 'list',
            key: 'idcMethods',
            name: 'ID Control Methods',
            module: 'idc',
            definition: {
                value: 'string',
                en_EN: 'string',
                nb_NO: 'string',
                sv_SE: 'string',
            },
            items: []
        });

        if (module.methods) {
            if (module.methods.bankidno && root.config.params.authMethods.bankidno) {
                reference.resources.idcMethods.items.push({ value: 'bankid-no', en_EN: 'BankID Norway', nb_NO: 'BankID Norge', sv_SE: 'BankID Norge' });
            }
            if (module.methods.bankidse && root.config.params.authMethods.bankidse) {
                reference.resources.idcMethods.items.push({ value: 'bankid-se', en_EN: 'BankID Sweden', nb_NO: 'BankID Sverige', sv_SE: 'BankID Sverige' });
            }
            /* if (module.methods.nemid) {
                reference.resources.idcMethods.items.push({ value: 'nemid', en_EN: 'NemID Denmark', nb_NO: 'NemID Danmark' , sv_SE: 'NemID Danmark' });
            } */
            if (module.methods.passreader && root.config.params.authMethods.passreader) {
                reference.resources.idcMethods.items.push({ value: 'passport-reader', en_EN: 'Passport Reader', nb_NO: 'Passleser', sv_SE: 'Passport Reader' });
            }
        }else {
            reference.resources.idcMethods.items.push({ value: 'bankid-no', en_EN: 'BankID Norway', nb_NO: 'BankID Norge', sv_SE: 'BankID Norge' });
            reference.resources.idcMethods.items.push({ value: 'bankid-se', en_EN: 'BankID Sweden', nb_NO: 'BankID Sverige', sv_SE: 'BankID Sverige' });
        }
        log.x('IDC Module: Available methods:', reference.resources.idcMethods.items);

        // Prepare ID control model
        reference.idcModel = {
            method: '',
            ssn: '',
            disabled: false
        };

        // Prefill values
        if (isOwner) {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    for (const k in pages[i].cards[j].inputs) {
                        if (pages[i].cards[j].inputs[k].logic && pages[i].cards[j].inputs[k].logic.field) {
                            pages[i].cards[j].inputs[k].model = this.getConnectedValue(root, pages[i].cards[j].inputs[k].logic.field.key, existingData);
                        }
                    }
                }
            }
        }
        
    },

    getConnectedValue(root, key, existingData) {
        if (!existingData) return '';

        const defaultPrefix = root.config.params.defaultPrefix || '+47';

        if (existingData.person) {
            switch (key) {
                case 'firstName': return root.companyTitleCase(existingData.person.info.firstName);
                case 'lastName': return root.companyTitleCase(existingData.person.info.lastName);
                case 'email': return existingData.person.email.available ? existingData.person.email.value : '';
                case 'phone': return existingData.person.phone.available ? { prefix: existingData.person.phone.prefix, number: existingData.person.phone.number } : { prefix: defaultPrefix, number: '' };
            }
        }else if (existingData.idcPerson) {
            // Support for V0, remove eventually
            switch (key) {
                case 'firstName': return root.companyTitleCase(existingData.idcPerson.info.firstName);
                case 'lastName': return root.companyTitleCase(existingData.idcPerson.info.lastName);
                case 'email': return existingData.idcPerson.contact.email;
            }
        }
        
        if (key == 'phone') return { prefix: defaultPrefix, number: '' };
        return '';
    },

    async onSetUserdata(root, reference, module, envelope, userdata, isOwner) {

        const existingData = JSON.parse(root.formData);
        log.x('IDC Module: On set userdata, existing data:', existingData);

        if (isOwner) {

            if (existingData.person) {

                userdata.idcPerson = {
                    id: existingData.person.personId,
                    name: existingData.person.displayName,
                    firstName: existingData.person.info.firstName,
                    lastName: existingData.person.info.lastName,
                    dateOfBirth: existingData.person.info.dateOfBirth,
                    roles: existingData.person.roles
                };
                userdata.idcModel = reference.idcModel;

            }else if (existingData.idcPerson) {

                // Support for V0, remove eventually
                userdata.idcPerson = {
                    oldSystem: true,
                    id: existingData.idcPerson.id,
                    name: existingData.idcPerson.displayName,
                    firstName: existingData.idcPerson.info.firstName,
                    lastName: existingData.idcPerson.info.lastName,
                    dateOfBirth: existingData.idcPerson.info.dateOfBirth,
                    roles: existingData.idcPerson.roles
                };

            }

            
            if (existingData.companyData && existingData.companyData.name) {
                userdata.company = {
                    name: existingData.companyData.name,
                    orgnr: existingData.companyData.orgnr
                };
            }else {
                userdata.company = {
                    name: '',
                    orgnr: ''
                };
            }
            
    
            userdata.customer = {
                id: existingData.customer._id,
                type: existingData.customer.type
            };

            if (existingData.idcPerson) {

                // Support for V0, remove eventually

                await api.updatePerson(existingData.idcPerson.id, {
                    idc: {
                        isForm: true,
                        userId: root.credentials.uid,
                        sentDate: new Date(),
                        completed: false,
                        envelopeId: envelope.id,
                        customerId: userdata.customer.id,
                        nextStatus: existingData.nextStatus || 'na'
                    }
                });
            }

        }
        
    },

    onSetMetadata(root, reference, module, envelope, userdata, metadata, roleKey, recipient) {

        log.x('IDC Module: On set metadata', module);

        // Send the document signed email to the owner
        if (module.notifyOwner) {
            metadata.silentParties.push({
                givenName: envelope.content.sender.givenName,
                familyName: envelope.content.sender.familyName,
                email: envelope.content.sender.email,
            });
        }

    },

    onAction(root, reference, designer, module, actionKey) {

        log.x('IDC Module: On action, key:', actionKey);

        if (actionKey == 'start') {
            this.startIDControl(root, reference);
        }
        
    },

    onLinkValue(root, reference, designer, module, link, value) {

        log.x('IDC Module: On link value (' + link.link + ')', value);

        if (link.link == 'method') {
            
            reference.idcModel[link.link] = value;

        }else if (link.link == 'ssn-no') {
            
            reference.idcModel['ssn'] = value;

        }else if (link.link == 'ssn-se') {
            
            reference.idcModel['ssn'] = value;

        }else if (link.link == 'ssn-pr') {
            
            reference.idcModel['ssn'] = value;

        }else if (link.link == 'disable') {

            reference.idcModel['disabled'] = value[link.key];

        }
        
    },

    async startIDControl(root, reference) {

        reference.showMainSpinner(true, root.__t('identity-preparing'));

        //console.log('referemce', reference.idcModel);

        const body = {
            authFlow: 'implicit',
            eid: reference.idcModel.method,
            //eid: 'bankid-no',
            redirectUrl: window.location.href,
            duration: '3d',
            sessionDuration: '30m',
            /* ssn: reference.idcModel.ssn, */
            //ssn: '18084110848',
        };
        if (reference.idcModel.method == 'bankid-se') {
            body.ssn = reference.idcModel.ssn;
        }

        log.x('IDC Module: Starting ID control with body', body);

        const data = {
            envelopeId: reference.envelope.id,
            recipientId: reference.recipient.id,
            token: v2lib.editorToken,
            method: body.eid,
            ssn: body.ssn
        };
        sessionStorage.setItem('vapoidc', JSON.stringify(data));
        log.x('IDC Module: Credentials stored in session', data);

        if (reference.testLocally) {
            await new Promise(r => setTimeout(r, 3000));
            const url = window.location.href + "?id_token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImxlZ2FjeSJ9.eyJleHAiOjE2MzYwMjIwNDMsImlhdCI6MTYyODI0NjA0MywiYXV0aF90aW1lIjoxNjI4MjQ2MDQwLCJqdGkiOiJMYXFYTDFBZ2ciLCJpc3MiOiJ2ZS1wbGF0Zm9ybS1pc3MiLCJhdWQiOiJ2ZXJpZmllZC1hbWwtYmFua2lkLWN1cnJlbnQiLCJzdWIiOiJlMWFjNmRlYy0wM2Q2LTQ4NzMtYjI5Ni03ODFjNGVjOGVjNjkiLCJ0eXAiOiJJRCIsImF6cCI6InZlcmlmaWVkLWFtbC1iYW5raWQtY3VycmVudCIsIm5vbmNlIjoiM2ZlMWRhNDgtZTE0My00NDczLTg3MmUtNDk0OTUzMjRiY2U3Iiwic2Vzc2lvbl9zdGF0ZSI6IjRkNGI4Yzc4LWRlZTctNDQyNS1hZjJlLTY4YmNlYzg3Y2M1NSIsIm5hbWUiOiJTw6VmaWUgQW5kZXJzZW4iLCJnaXZlbl9uYW1lIjoiU8OlZmllIiwiZmFtaWx5X25hbWUiOiJBbmRlcnNlbiIsImJpcnRoZGF0ZSI6IjE5NDEtMDgtMTgiLCJ1cGRhdGVkX2F0IjoxNjAwNDE5OTM0MDAwLCJhY3IiOiJ1cm46YmFua2lkOmJpZDtMT0E9NCIsIm5uaW5fYWx0c3ViIjoiMTgwODQxMTA4NDgiLCJhbXIiOiJCSUQiLCJiYW5raWRfYWx0c3ViIjoiOTU3OC02MDAwLTQtNDc3MDkzIiwib3JpZ2luYXRvciI6IkNOPUJhbmtJRCAtIFRlc3RCYW5rMSAtIEJhbmsgQ0EgMyxPVT0xMjM0NTY3ODksTz1UZXN0QmFuazEgQVMsQz1OTztPcmdpbmF0b3JJZD05OTgwO09yaWdpbmF0b3JOYW1lPUJJTkFTO09yaWdpbmF0b3JJZD05OTgwIiwiYWRkaXRpb25hbENlcnRJbmZvIjp7ImNlcnRWYWxpZEZyb20iOjE2MDA0MTk5MzQwMDAsInNlcmlhbE51bWJlciI6IjEzNzkyODIiLCJrZXlBbGdvcml0aG0iOiJSU0EiLCJrZXlTaXplIjoiMjA0OCIsInBvbGljeU9pZCI6IjIuMTYuNTc4LjEuMTYuMS4xMi4xLjEiLCJtb25ldGFyeUxpbWl0QW1vdW50IjoiMTAwMDAwIiwiY2VydFF1YWxpZmllZCI6dHJ1ZSwibW9uZXRhcnlMaW1pdEN1cnJlbmN5IjoiTk9LIiwiY2VydFZhbGlkVG8iOjE2NjM0OTE5MzQwMDAsInZlcnNpb25OdW1iZXIiOiIzIiwic3ViamVjdE5hbWUiOiJDTj1BbmRlcnNlblxcLCBTw6VmaWUsTz1UZXN0QmFuazEgQVMsQz1OTyxTRVJJQUxOVU1CRVI9OTU3OC02MDAwLTQtNDc3MDkzIn0sInRpZCI6IjY1NTQ0ZTViLWVlYzEtNDlkYi1hYTJiLWM2NjQ5OWZkMTQwOSJ9.HyyHvRXUlAx4JW14h6K8dYmUJDVT_mWBEG2HwCli-3tBXYrART6QOnfDRdr-ogtJwKX44_ef-25MSiY9o3984kN_21Q0GaHaKgJ_WlvOfUq8YnAyqhYodLIGti-QV1qK30sUX9TW5M7FQJux8Z9kfcfJderoSvwNUOsUVmNHYnDclMDzNo9NbnwCUXqpRcXLGlHs6Md5hPp8UNsSGPGuCryS7xYshyu9glNYTmelQAjuCWI8TtxLwdrCKyRoHQSQKX3Os3_Ejml98WBM1-YDfmW7EMgCLnonFb5ED1p2s5ni89nO79j2RdLwzjwNaYOJjTs3dlNc_368IXb3TmC7Eg";
            console.log('url', url);
            window.location.href = url;
            return false;
        }else {
            const url = await this.getVerifiedSecureLink(body);
            window.location.href = url;
        }
    },

    async getVerifiedSecureLink(body) {
        const axiosConfig = {
            headers: {
                'Authorization': 'JWT ' + v2lib.editorToken,
                'x-namespace': v2lib.namespace
            }
        };
        const response = await axios.post('/api/secure/oidc/initiate', body, axiosConfig);
        //console.log('response', response);
        return response.data.url;
    }

};
export default idcModuleHelper;