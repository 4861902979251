import axios from 'axios';
import log from '../log';
const v1api = {

    functionUrl: process.env.VUE_APP_API_BASE_URL + '/v1api',
    credentials: {},
    logResponse: true,
    host: 'unknown',
    baseUrl: process.env.VUE_APP_BASE_URL,
    isDEV: false,
    vToken: 'error',

    async waitForFormCreation(envelope, userdata) {
        let created = false;
        try {

            let count = 0;
            while (!created && count < 15) {
                await new Promise(r => setTimeout(r, 2000));

                const result = await this.get(`clients/${userdata.credentials.id}/formcreated/${envelope.id}`);
                //console.log('created', result.data.created);

                if (result.data.created) {
                    created = true;
                }
                count += 1;
            }

        }catch (ex) {
            console.log(ex);
            console.error('Failed to wait for form creation');
        }
        return created;
    },

    // log KAR
    async logKAR(clientId, karData)  {
        return await this.post(`clients/${clientId}/logkar`, karData);
    },


    //#region Queries -----------------------------------------------------------------------------------------------------

    async queryClients(filters, from, limit, sort, include, counts)  {
        const url = `query/clients?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.get(url + query);
    },

    async queryProcessedClients(filters, from, limit, sort, include, counts)  {
        const url = `query/processedclients?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.get(url + query);
    },

    async queryPersons(filters, from, limit, sort, include, counts)  {
        const url = `query/persons?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.get(url + query);
    },

    async queryEvents(filters, from, limit, sort, include, counts)  {
        const url = `query/events?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.get(url + query);
    },

    async queryTasks(filters, from, limit, sort, include, counts)  {
        const url = `query/tasks?`;
        const query = encodeURIComponent(`filters=${JSON.stringify(filters)}&from=${Number(from)}&limit=${Number(limit)}&sort=${JSON.stringify(sort)}&include=${JSON.stringify(include)}&counts=${JSON.stringify(counts)}`);
        return await this.get(url + query);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------
    
    async doCustomLogic(pid)  {
        const data = {
            pid: pid,
        };
        return await this.post(`clients/default/custom`, data);
    },

    async doScriptStuff(clientId, type, updateStatus, scriptData)  {
        const data = {
            type: type,
            updateStatus: updateStatus,
            scriptData: scriptData,
        };
        return await this.post(`clients/${clientId}/script`, data);
    },

    async resetScriptStatus()  {
        const data = {
            reset: true,
        };
        return await this.post(`clients/whatever/scriptreset`, data);
    },

    //#region Clients -----------------------------------------------------------------------------------------------------

    async getClient(id)  {
        return await this.get('clients/' + id);
    },

    async getClientFromForm(id)  {
        return await this.get(`clients/${id}/fromform`);
    },

    async getClientFromIdentity(identity)  {
        return await this.get(`clients/${identity}/identity`);
    },

    async getRawClient(id)  {
        return await this.get('clients/' + id + '/raw');
    },

    async importClient(client, persons, metadata)  {
        const data = {
            client: client,
            persons: persons,
            metadata: metadata
        };
        return await this.post(`clients/${client._id}/import`, data);
    },

    async migrateClient(client, persons, metadata)  {
        const data = {
            client: client,
            persons: persons,
            metadata: metadata
        };
        return await this.post(`clients/${client._id}/migrate`, data);
    },

    async resetCompany(clientId)  {
        return await this.delete(`clients/${clientId}/company`);
    },

    async refreshCompany(clientId, newData)  {
        const data = {
            newData: newData
        };
        return await this.put(`clients/${clientId}/company`, data);
    },

    async enableContinuousScreening(clientId, active, includePersons)  {
        const data = {
            active: active,
            includePersons: includePersons
        };
        return await this.post(`clients/${clientId}/cscreen`, data);
    },

    async getSimilarPersons(clientId)  {
        return await this.get(`clients/${clientId}/similarpersons`);
    },

    async getClientStatusTEST(clientId)  {
        return await this.get(`clients/${clientId}/teststatus`);
    },

    async updateClientFromFormTEST(clientId, envelope) {
        const data = {
            envelope: envelope,
            flow: 'test-flow'
        };
        return await this.post(`clients/${clientId}/forms`, data);
    },

    async createClient(clientData, companyData, personData) {
        const data = {
            clientData: clientData,
            companyData: companyData,
            personData: personData
        };
        return await this.post('clients', data);
    },

    async clientExists(clientData) {
        const data = {
            clientData: clientData,
        };
        return await this.put('clients', data);
    },

    async updateClient(clientId, clientData) {
        const data = {
            clientData: clientData
        };
        return await this.put(`clients/${clientId}`, data);
    },

    async updateClientPublic(clientId, clientData) {
        const data = {
            clientData: clientData
        };
        return await this.post(`clients/${clientId}/clientpublic`, data);
    },

    async editClient(clientId, clientData) {
        const data = {
            clientData: clientData
        };
        return await this.put(`clients/${clientId}/edit`, data);
    },

    async addAttachmentToClient(clientId, attachment) {
        const data = {
            attachment: attachment
        };
        return await this.post(`clients/${clientId}/attachments`, data);
    },

    async deleteClient(clientId)  {
        return await this.delete(`clients/${clientId}`);
    },

    async addKeyRoleToClient(clientId, personId) {
        const data = {
            personId: personId
        };
        return await this.post(`clients/${clientId}/keyroles`, data);
    },

    async removeKeyRoleFromClient(clientId, personId)  {
        return await this.delete(`clients/${clientId}/keyroles/${personId}`);
    },

    async saveReportData(clientId, items, risk) {
        const data = {
            items: items,
            risk: risk
        };
        return await this.post(`clients/${clientId}/reportdata`, data);
    },

    async saveReport(clientId, envelopeId, name) {
        const data = {
            envelopeId: envelopeId,
            name: name,
        };
        return await this.post(`clients/${clientId}/report`, data);
    },

    async resetReport(clientId)  {
        return await this.delete(`clients/${clientId}/report`);
    },

    async resetForm(clientId, formKey)  {
        return await this.delete(`clients/${clientId}/forms/${formKey}`);
    },

    async addFormReminder(clientId, formKey, envelopeId, recipient, action) {
        const data = {
            envelopeId: envelopeId,
            recipient: recipient,
            action: action,
        };
        return await this.post(`clients/${clientId}/formreminder/${formKey}`, data);
    },

    async saveFormData(clientId, formKey, formData) {
        const data = {
            formKey: formKey,
            formData: formData
        };
        return await this.post(`clients/${clientId}/formdata`, data);
    },
    async saveFormDataPublic(clientId, formKey, formData) {
        const data = {
            formKey: formKey,
            formData: formData
        };
        return await this.post(`clients/${clientId}/formdatapublic`, data);
    },

    async addCustomData(clientId, formKey, formData) {
        const data = {
            formKey: formKey,
            formData: formData
        };
        return await this.post(`clients/${clientId}/customdata`, data);
    },

    async getFormDataPublic(clientId, formKey, envelopeId)  {
        return await this.get(`clients/${clientId}/formdatapublic/${formKey}/${envelopeId}`);
    },

    async saveAdverseMediaOrg(clientId, searchResults) {
        const data = {
            searchResults: searchResults
        };
        return await this.post(`clients/${clientId}/adversemedia`, data);
    },

    async resetAdverseMediaOrg(clientId) {
        return await this.delete(`clients/${clientId}/adversemedia`);
    },

    async storeReport(clientId, storeData) {
        const data = {
            storeData: storeData
        };
        return await this.post(`clients/${clientId}/store`, data);
    },

    async forwardReport(clientId, forwardData) {
        const data = {
            forwardData: forwardData
        };
        return await this.post(`clients/${clientId}/forward`, data);
    },

    async uploadBook(clientId, files, comment)  {
        const data = {
            files: files,
            comment: comment
        };
        return await this.post(`clients/${clientId}/book`, data);
    },

    async confirmBook(clientId, comment)  {
        const data = {
            confirmed: true,
            comment: comment
        };
        return await this.put(`clients/${clientId}/book`, data);
    },

    async uploadFile(clientId, fileData)  {
        const data = {
            fileData: fileData,
        };
        return await this.post(`clients/${clientId}/files`, data);
    },

    async updateFile(clientId, formKey, fileData)  {
        const data = {
            fileData: fileData,
        };
        return await this.put(`clients/${clientId}/files/${formKey}`, data);
    },

    async deleteFile(clientId, formKey)  {
        return await this.delete(`clients/${clientId}/files/${formKey}`);
    },

    async addTags(clientId, tags) {
        const data = {
            tags: tags
        };
        return await this.post(`clients/${clientId}/tags`, data);
    },

    async removeTag(clientId, tag) {
        return await this.delete(`clients/${clientId}/tags/${tag}`);
    },

    async confirmFormSignature(clientId, formKey, envelopeId) {
        const data = {
            formKey: formKey,
            envelopeId: envelopeId,
        };
        return await this.post(`clients/${clientId}/confirmsignature`, data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Persons -----------------------------------------------------------------------------------------------------

    async getPerson(id)  {
        return await this.tryGet('persons/' + id);
    },

    async performScreening(personId, screeningData) {
        const data = {
            screeningData: screeningData
        };
        return await this.post(`persons/${personId}/screening`, data);
    },

    async resetScreening(personId) {
        return await this.delete(`persons/${personId}/screening`);
    },

    async resetIDC(personId) {
        return await this.delete(`persons/${personId}/idc`);
    },

    async addIDCReminder(personId, envelopeId, recipient, action) {
        const data = {
            envelopeId: envelopeId,
            recipient: recipient,
            action: action,
        };
        return await this.post(`persons/${personId}/idcreminders`, data);
    },

    async addManualPerson(clientId, roleData) {
        const data = {
            roleData: roleData
        };
        return await this.post(`clients/${clientId}/manualpersons`, data);
    },

    async addManualRole(clientId, personId, roleData) {
        const data = {
            roleData: roleData
        };
        return await this.post(`clients/${clientId}/manualroles/${personId}`, data);
    },

    async removeManualRole(clientId, personId, roleId) {
        const data = {
            roleId: roleId
        };
        return await this.post(`clients/${clientId}/deletemanual/${personId}`, data);
    },

    async mergePersons(personId, mergeIds) {
        const data = {
            personId: personId,
            mergeIds: mergeIds
        };
        return await this.post(`persons/${personId}/merge`, data);
    },

    async completeIDCManually(personId, manualData) {
        const data = {
            manualData: manualData
        };
        return await this.post(`persons/${personId}/manualidc`, data);
    },

    async setIdcFromFormSignature(personId, signature, envelopeId) {
        const data = {
            signature: signature,
            envelopeId: envelopeId,
        };
        return await this.post(`persons/${personId}/formidc`, data);
    },

    async saveAdverseMedia(personId, searchResults) {
        const data = {
            searchResults: searchResults
        };
        return await this.post(`persons/${personId}/adversemedia`, data);
    },

    async resetAdverseMedia(personId) {
        return await this.delete(`persons/${personId}/adversemedia`);
    },

    async enableContinuousScreeningPerson(personId, monitorIds) {
        const data = {
            monitorIds: monitorIds
        };
        return await this.post(`persons/${personId}/cscreen`, data);
    },

    async setFalsePositive(personId, falsePositive, fpParams) {
        const data = {
            falsePositive: falsePositive,
            fpParams: fpParams
        };
        return await this.post(`persons/${personId}/fpositive`, data);
    },

    async updateFromID(personId, params) {
        const data = {
            params: params
        };
        return await this.post(`persons/${personId}/identity`, data);
    },

    async updateFlags(personId) {
        const data = {
            update: true
        };
        return await this.post(`persons/${personId}/flags`, data);
    },

    async testMonitor(personId, hit) {
        const data = {
            hit: hit,
            accountId: this.getPid(),
        };
        return await this.post(`persons/${personId}/monitortest`, data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Flows -------------------------------------------------------------------------------------------------------

    async getFlows()  {
        return await this.get(`flows`);
    },

    async createFlow(flowData) {
        const data = {
            flowData: flowData
        };
        return await this.post(`flows`, data);
    },

    async deleteFlow(flowId) {
        return await this.delete(`flows/${flowId}`);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    async addWatcher(clientId, userId) {
        const data = {
            userId: userId
        };
        return await this.post(`clients/${clientId}/watchers`, data);
    },

    async removeWatcher(clientId, userId) {
        return await this.delete(`clients/${clientId}/watchers/${userId}`);
    },


    async addProject(clientId, project, defaultId) {
        const data = {
            project: project,
            defaultId: defaultId,
        };
        return await this.post(`clients/${clientId}/projects`, data);
    },

    //#region Partners / Branches -----------------------------------------------------------------------------------------

    async addPartner(partner) {
        const data = {
            partner: partner
        };
        return await this.post(`team/default/partners`, data);
    },

    async deletePartner(partnerKey) {
        return await this.delete(`team/default/partners/${partnerKey}`);
    },

    async addBranch(branch) {
        const data = {
            branch: branch
        };
        return await this.post(`team/default/branches`, data);
    },

    async deleteBranch(branchKey) {
        return await this.delete(`team/default/branches/${branchKey}`);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Report templates --------------------------------------------------------------------------------------------

    async getReportTemplates()  {
        return await this.get(`config/default/reports`);
    },

    async addReportTemplate(templateData, templateName) {
        const data = {
            templateData: templateData,
            templateName: templateName,
        };
        return await this.post(`config/default/reports`, data);
    },

    async updateReportTemplate(templateKey, templateData) {
        const data = {
            templateData: templateData
        };
        return await this.post(`config/default/reports/${templateKey}`, data);
    },

    async deleteReportTemplate(templateKey) {
        return await this.delete(`config/default/reports/${templateKey}`);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Risk --------------------------------------------------------------------------------------------------------

    async updateRiskParams(flow, params, items) {
        const data = {
            flow: flow,
            params: params,
            items: items,
        };
        return await this.post(`config/default/risk`, data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Batches -----------------------------------------------------------------------------------------------------

    async getBatch(id)  {
        return await this.get(`batch/${id}`);
    },

    async getBatches()  {
        return await this.get(`batch`);
    },

    async addBatch(batchData) {
        const data = {
            batchData: batchData,
        };
        return await this.post(`batch`, data);
    },

/*     async updateReportTemplate(templateKey, templateData) {
        const data = {
            templateData: templateData
        };
        return await this.post(`config/default/reports/${templateKey}`, data);
    },

    async deleteReportTemplate(templateKey) {
        return await this.delete(`config/default/reports/${templateKey}`);
    }, */

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Tasks -------------------------------------------------------------------------------------------------------

    async getTask(id)  {
        return await this.get(`tasks/${id}`);
    },

    async addTask(task) {
        const data = {
            task: task,
        };
        return await this.post(`tasks`, data);
    },

    async performTask(id) {
        const data = {
            perform: true,
        };
        return await this.post(`tasks/${id}/perform`, data);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Monitors ----------------------------------------------------------------------------------------------------

    async addMonitor(id, secret, name, template) {
        const data = {
            id: id,
            secret: secret,
            name: name,
            template: template,
        };
        return await this.post(`config/default/monitors`, data);
    },

    async deleteMonitor(monitorId) {
        return await this.delete(`config/default/monitors/${monitorId}`);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Request / Response ------------------------------------------------------------------------------------------

    async get(route, url) {
        const axiosConfig = this.getAxiosConfig(route);
        this.onRequest('GET', axiosConfig);
        const response = await axios.get(url || this.functionUrl, axiosConfig);
        this.onResponse(response, axiosConfig);
        response.data.result.status = response.data.result.code;
        delete response.data.result.code;
        return response.data.result;
    },

    async tryGet(route, url) {
        const axiosConfig = this.getAxiosConfig(route);
        this.onRequest('GET', axiosConfig);
        try {
            const response = await axios.get(url || this.functionUrl, axiosConfig);
            this.onResponse(response, axiosConfig);
            response.data.result.status = response.data.result.code;
            delete response.data.result.code;
            return response.data.result;
        }catch (ex) {
            return {
                error: true,
                code: ex.response.status,
            };
        }
    },

    async post(route, data, url) {
        const axiosConfig = this.getAxiosConfig(route);
        const body = JSON.stringify(data);
        this.onRequest('POST', axiosConfig, body);
        const response = await axios.post(url || this.functionUrl, body, axiosConfig);
        this.onResponse(response, axiosConfig, body);
        response.data.result.status = response.data.result.code;
        delete response.data.result.code;
        return response.data.result;
    },

    async put(route, data, url) {
        const axiosConfig = this.getAxiosConfig(route);
        const body = JSON.stringify(data);
        this.onRequest('PUT', axiosConfig, body);
        const response = await axios.put(url || this.functionUrl, body, axiosConfig);
        this.onResponse(response, axiosConfig, body);
        response.data.result.status = response.data.result.code;
        delete response.data.result.code;
        return response.data.result;
    },

    async delete(route, url) {
        const axiosConfig = this.getAxiosConfig(route);
        this.onRequest('DELETE', axiosConfig);
        const response = await axios.delete(url || this.functionUrl, axiosConfig);
        this.onResponse(response, axiosConfig);
        response.data.result.status = response.data.result.code;
        delete response.data.result.code;
        return response.data.result;
    },

    onRequest(method, config, body) {
        if (!this.logResponse) return;
        const data = {
            pid: config.headers.pid,
            body: body
        };
        const routeString = config.headers['x-route'].split('?')[0];
        if (method == 'POST' || method == 'PUT') {
            log.r('APIv1 ' + method + ' ' + routeString, data);
        }else {
            log.r('APIv1 ' + method + ' ' + routeString);
        }
    },

    onResponse(response, config, body) {
        if (!this.logResponse) return;
        const routeString = config.headers['x-route'].split('?')[0];
        log.s('APIv1 ' + response.config.method.toUpperCase() + ' ' + routeString, response);
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Axios Headers -----------------------------------------------------------------------------------------------

    // TODO remove this when all clients are on the new naming convention
    getKey(existingKey, cid) {
        let key = existingKey;
        if (!existingKey || existingKey == 'default') {
            if (cid == 'H1xq6yFU8') key = 'kvale';
            if (cid == 'ry1K-ZPPD') key = 'pangea-pp';
            if (cid == 'B1LU_pJfm') key = 'sands';
            if (cid == 'rkeUXcLuv') key = 'deloitte';
        }
        if (key == 'deloitte') key = 'default';
        return key;
    },

    getAxiosConfig(method) {
        let pid = this.getKey(this.credentials.key, this.credentials.cid);
        if (this.credentials.testinc) pid = this.getKey(this.credentials.testinc, this.credentials.cid);
        pid += '-';
        pid += this.credentials.cid;
        let config = {
            headers: {
                'x-route': method,
                'token' : this.credentials.token || this.credentials.bearerToken || '',
                'v-token' : this.vToken,
                'pid': pid,
                'portalhost': this.host,
                'isdev': this.isDEV,
                'Pragma' : 'no-cache' // IE-fix - disable caching of network calls
            }
        }
        return config;
    },

    getPid() {
        let pid = this.getKey(this.credentials.key, this.credentials.cid);
        if (this.credentials.testinc) pid = this.getKey(this.credentials.testinc, this.credentials.cid);
        pid += '-';
        pid += this.credentials.cid;
        return pid;
    }

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default v1api;