<template>
    <div>
        
        <div class="slim-page" v-if="clients.length == 0 && !batchId">
            <vap-label :text="'Masseutsendelse'" header style="margin-top: 50px; margin-bottom: 10px;" />
            <vap-card :header="'Velg fil'">
                <vap-file-upload v-model="fileupload" anyFile :autoPreview="false" @input="onFileDetected" />
            </vap-card>
        </div>

        <div class="aml-page" v-if="clients.length > 0 && !batchId">
            <vap-card>
                <div class="flw100 ms-items">
                    <div class="flw100 ms-item ms-head">
                        <div class="ms-item-x ms-item-0">
                            <label>{{ 'Selskap' }}</label>
                        </div>
                        <div class="ms-item-x ms-item-1">
                            <label>{{ 'Org.nr.' }}</label>
                        </div>
                        <div class="ms-item-x ms-item-2">
                            <label>{{ 'Kontaktperson' }}</label>
                        </div>
                        <div class="ms-item-x ms-item-3">
                            <label>{{ 'Epost' }}</label>
                        </div>
                        <div class="ms-item-x ms-item-4">
                            <label>{{ 'Telefon' }}</label>
                        </div>
                        <div class="ms-item-x ms-item-5">
                            <label>{{ 'Avdeling' }}</label>
                        </div>
                        <div class="ms-item-x ms-item-6">
                            <label>{{ 'Partner' }}</label>
                        </div>
                    </div>
                    <div class="flw100 ms-item" v-for="(client, i) in clients" :key="'c' + i">
                        <div class="ms-item-x ms-item-0">
                            <label>{{ trimText(client.name, 21) }}</label>
                        </div>
                        <div class="ms-item-x ms-item-1" :class="{'ms-item-e': !client.identityValid}">
                            <label>{{ client.identity }}</label>
                        </div>
                        <div class="ms-item-x ms-item-2">
                            <label>{{ trimText(client.firstName + ' ' + client.lastName, 28) }}</label>
                        </div>
                        <div class="ms-item-x ms-item-3" :class="{'ms-item-e': !client.emailValid}">
                            <label>{{ trimText(client.email, 28) }}</label>
                        </div>
                        <div class="ms-item-x ms-item-4">
                            <label>{{ client.phone }}</label>
                        </div>
                        <div class="ms-item-x ms-item-5">
                            <label>{{ trimText(client.department + ' - ' + client.departmentName, 25) }}</label>
                        </div>
                        <div class="ms-item-x ms-item-6">
                            <label>{{ trimText(client.partner + ' - ' + client.partnerName, 25) }}</label>
                        </div>
                    </div>
                </div>
            </vap-card>
            <vap-button-row alignCenter style="margin-top: 30px;">
                <vap-button :text="'Bekreft og gå videre'" @clicked="createBatch" :disabled="hasErrors" />
            </vap-button-row>
        </div>

        <div class="aml-page" v-if="batch.customers">

            <vap-form-divider :left="55" :right="45" stickyRight :stickyOffset="80">
                <template v-slot:left>
                    <vap-label :text="'Kunder'" header style="margin-bottom: 5px;" />

                    <vap-card>
                        <div class="flw100 bat-items">
                            <div class="flw100 bat-item" v-for="(customer, i) in batch.customers" :key="'c' + i">
                               <div class="bat-icon-c" v-if="i < currentIndex || isComplete">
                                    <vap-icon :icon="'check'" :color="'var(--success-text)'" />
                                </div>
                                <div class="bat-icon-p" v-if="i > currentIndex || (i == currentIndex && !isRunning && !isComplete)">
                                    <vap-icon :icon="'cancel'" :color="'var(--disabled-text)'" />
                                </div>
                                <div class="bat-prog" v-if="i == currentIndex && isRunning">
                                    <vap-spinner iconMode />
                                </div>
                                <div class="bat-content">
                                    <div class="bat-top">
                                        <label class="bat-strong">{{ customer.name }}</label>
                                        <label class="bat-weak">{{ customer.identity }}</label>
                                    </div>
                                    <div class="bat-bot">
                                        <label class="bat-strong">{{ customer.firstName + ' ' + customer.lastName }}</label>
                                        <label class="bat-weak">{{ customer.email }}</label>
                                        <label class="bat-weak">{{ customer.phone }}</label>
                                    </div>
                                </div>
                                <div class="bat-actions" v-if="!isRunning">
                                    <vap-icon action :icon="'edit'" @clicked="showEditPopup(customer, i)" />
                                    <vap-icon action :icon="'cancel'" @clicked="showRemovePopup(customer, i)" />
                                </div>
                            </div>
                        </div>
                    </vap-card>


                </template>
                <template v-slot:right>
                    <vap-label :text="'Status'" header style="margin-bottom: 5px;" />
                    <vap-card v-if="currentIndex < batch.customers.length">
                        
                        <div class="bat-current">
                            <label>{{ batch.customers[currentIndex].name }}</label>
                        </div>
                        <div class="bat-current-stat">
                            <label>{{ currentStatus }}</label>
                        </div>

                        <div class="bat-prog-text">
                            <label>{{ currentIndex + 1 }}</label>
                            <label>/</label>
                            <label>{{ batch.customers.length }}</label>
                        </div>

                        <div class="bat-prog-base">
                            <div class="bat-prog-prog" :style="'right: ' + progressRight + '%;'" />
                        </div>

                        <div class="bat-loop-stat" :class="{'bat-loop-stat-error': isError}">
                            <label>{{ loopStatus }}</label>
                        </div>
                        
                        <vap-button-row alignCenter style="margin-top: 20px;">
                            <vap-button :text="'Start'" @clicked="startBatch" :disabled="isRunning || isComplete || isStopping || isError" />
                            <vap-button :text="'Pause'" @clicked="pauseBatch" :disabled="!isRunning || isComplete || isStopping || isError" />
                        </vap-button-row>

                        <div class="flw100 bat-loop-error" v-if="isError">
                            <label>{{ errorMessage }}</label>
                        </div>

                    </vap-card>
                    <vap-card v-if="currentIndex >= batch.customers.length">
                        <div style="padding-top: 40px; padding-left: 20px; padding-bottom: 50px;">
                            <label>{{ 'Denne masseutsendelsen er fullført' }}</label>
                        </div>
                    </vap-card>
                </template>
            </vap-form-divider>

            
        </div>

        <ve-popup v-if="removePopup.show" :header="'Fjerne kunde?'" :width="300" @close="removePopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <label>{{ 'Er du sikker på at du vil fjerne ' + removePopup.customerName + ' fra masseutsendelsen?' }}</label><br><br>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="'Nei'" uppercase @clicked="removePopup.show = false" />
                    <vap-button :text="'Ja'" uppercase @clicked="removeFromBatch" :working="removePopup.working" />
                </vap-button-row>
            </div>
        </ve-popup>

        <ve-popup v-if="editPopup.show" :header="'Oppdater kunde?'" :width="700" @close="editPopup.show = false" hideCloseButton>
            <div style="padding: 20px;">
                <vap-row>
                    <vap-group c8>
                        <vap-label desc :text="'Navn'" />
                        <vap-input v-model="editPopup.model.name" />
                    </vap-group>
                    <vap-group c4>
                        <vap-label desc :text="'Org.nr.'" />
                        <vap-input v-model="editPopup.model.identity" />
                    </vap-group>
                    <vap-group c6>
                        <vap-label desc :text="'Fornavn'" />
                        <vap-input v-model="editPopup.model.firstName" />
                    </vap-group>
                    <vap-group c6>
                        <vap-label desc :text="'Etternavn'" />
                        <vap-input v-model="editPopup.model.lastName" />
                    </vap-group>
                    <vap-group c8>
                        <vap-label desc :text="'Epost'" />
                        <vap-input v-model="editPopup.model.email" />
                    </vap-group>
                    <vap-group c4>
                        <vap-label desc :text="'Telefon'" />
                        <vap-input v-model="editPopup.model.phone" />
                    </vap-group>
                </vap-row>
                <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                    <vap-button :text="'Avbryt'" uppercase @clicked="editPopup.show = false" />
                    <vap-button :text="'Oppdater'" uppercase @clicked="editCustomer" :working="editPopup.working" />
                </vap-button-row>
            </div>
        </ve-popup>

    </div>
</template>
<script>
import axios from 'axios';
import v2lib from '../v2lib';
import api from '../portalapi';
import receipts from '../receipts';
import kychelper from '../helpers/kychelper';
import locale from '../locale';
import verified from '../verified';
import amlTools from '../aml';
import tools from '../tools';
import log from '../log';
import validations from '../validations';

export default {
    name: 'MassSendout',
    data() {
        return {
            fileupload: {},
            clients: [],
            departments: [],
            batchId: '',
            batch: {},
            progressRight: 50,
            isRunning: false,
            isStopping: false,
            isComplete: false,
            isError: false,
            currentIndex: 0,
            currentStatus: 'Venter...',
            loopStatus: 'Klar til start...',
            errorMessage: 'unknown error',
            customerData: {},
            brregData: {},
            amlData: {},
            kycIndex: 0,
            kycTranslations: undefined,
            ud: {},
            testAml: false,
            skipSMS: false,
            limit: 0,
            skip: 0,
            hasErrors: true,
            removePopup: { show: false, working: false, index: '', customerName: '' },
            editPopup: { show: false, working: false, index: '', model: {} },
        }
    },
    methods: {
        
        // ----- File Reading -------------------------------------------------------------

        onFileDetected() {
            console.log('onFileDetected');

            const self = this;
            const reader = new FileReader();
            reader.onload = function(e) {
                const contents = e.target.result;
                self.readFile(contents);
            };
            reader.readAsText(this.fileupload.files[0].data, 'ISO-8859-4');

        },

        readFile(data) {
            console.log(data);
            const list = [];
            const lines = data.split(/\r\n|\n/);
            const def1 = lines[0].split(';');
            const def2 = lines[0].split(',');
            const comma = (def2.length > def1.length)
            const definition = comma ? def2 : def1;
            for (let i=1; i<lines.length; i++) {
                if (lines[i].length < 1) continue;
                const line = lines[i].split(comma ? ',' : ';');
                const item = {};
                for (const x in definition) item[definition[x]] = line[x];
                list.push(item);
            }
            this.processList(list);
        },

        readFile2(data) {
            const obj = {};
            const lines = data.split(/\r\n|\n/);
            for (let i=0; i<lines.length; i++) {
                if (lines[i].length < 1) continue;
                const line = lines[i].split(';');
                obj[line[0]] = line[1];
            }
            console.log('obj', obj);
            console.log(JSON.stringify(obj));
        },

        processList(list) {
            this.clients = [];
            let count = 0;
            this.hasErrors = false;
            for (const i in list) {
                if (!(this.limit != 0 && i < this.skip)) {
                    const identity = list[i].orgnr.split(' ').join('');
                    const identityValid = identity.length > 8;
                    const email = tools.replaceAll(list[i].email, ' ', '');
                    let emailValid = validations.validateEmail(email);
                    if (!validations.validateEmailName(email)) emailValid.valid = false;
                    if (!identityValid) this.hasErrors = true;
                    if (!emailValid.valid) this.hasErrors = true;
                    this.clients.push({
                        smsSent: false,
                        amlComplete: false,
                        kycSent: false,
                        customerId: '',
                        name: list[i].company,
                        identity: identity,
                        identityValid: identityValid,
                        firstName: list[i].firstname,
                        lastName: list[i].lastname,
                        email: email,
                        emailValid: emailValid.valid,
                        phone: '+' + list[i].prefix + list[i].phone,
                        department: this.getDepartmentNumber(list[i].department),
                        departmentName: this.getDepartmentName(this.getDepartmentNumber(list[i].department)),
                        partner: list[i].enumber,
                        partnerName: this.getPartnerName(list[i].enumber)
                    });
                }
                count += 1;
                if (count >= (this.limit + this.skip) && this.limit != 0) break;
            }

            console.log('clients', this.clients);
        },

        getDepartmentNumber(key) {
            for (const i in this.departments) {
                for (const j in this.departments[i].numbers) {
                    if (this.departments[i].numbers[j] == key) return this.departments[i].key;
                }
            }
            return 2001;
        },

        getDepartmentName(number) {
            for (const i in this.$root.departments) {
                if (this.$root.departments[i].key == number) return this.$root.departments[i].name;
            }
            return 'UKJENT AVDELING';
        },

        getPartnerName(number) {
            for (const i in this.$root.partners) {
                if (this.$root.partners[i].key == number) return this.$root.partners[i].firstName + ' ' + this.$root.partners[i].lastName;
            }
            return 'UKJENT PARTNER';
        },

        trimText(input, limit) {
            let output = input;
            if (input.length > limit) {
                output = input.substring(0, limit - 3) + '...';
            }

            return output;
        },

        // --------------------------------------------------------------------------------

        // ----- Create / Edit Batch ------------------------------------------------------

        async createBatch() {
            this.$parent.setLoading(true, 'Oppretter ny masseutsendelse...');

            const response = await api.createBatch(this.clients);

            this.$router.push({path: '/batch', query: { id: response.id }});
            location.reload();
        },

        showRemovePopup(customer, index) {
            this.removePopup.index = index;
            this.removePopup.customerName = customer.name;
            this.removePopup.show = true;
        },

        async removeFromBatch() {
            this.removePopup.working = true;

            await api.removeBatchCustomer(this.batch, this.removePopup.index);

            this.removePopup.working = false;
            this.removePopup.show = false;
            this.loadBatch();
        },

        showEditPopup(customer, index) {
            this.editPopup.index = index;
            this.editPopup.model = customer;
            this.editPopup.show = true;
        },

        async editCustomer() {
            this.editPopup.working = true;
            await api.updateBatchCustomer(this.batch, this.editPopup.index, this.editPopup.model);
            this.editPopup.working = false;
            this.editPopup.show = false;
            this.loadBatch();
        },

        // --------------------------------------------------------------------------------

        // ----- Batch Loop ---------------------------------------------------------------

        startBatch() {
            if (!this.isRunning && !this.isStopping && !this.isComplete) this.startBatchLoop(); 
        },

        pauseBatch() {
            this.loopStatus = 'Stopper...';
            this.isStopping = true;
        },

        setProgress() {
            const fac = 100 / this.batch.customers.length;
            const per = fac * (this.currentIndex);
            this.progressRight = 100 - per;
            if (this.isComplete) this.progressRight = 0;
        },

        async startBatchLoop() {
/*             await this.sendSMS();
            return; */

            this.isRunning = true;
            this.loopStatus = 'Kjører...';
            while (!this.isStopping && !this.isComplete) {
                console.log('processing (' + this.currentIndex + ') ' + this.batch.customers[this.currentIndex].name);
                let result;
                // Process customer


                // Step 1: Create a new customer
                this.currentStatus = 'Oppretter kunde...';
                result = await this.createCustomer();
                if (!result.ok) {
                    this.setError(result);
                    break;
                }
                
                // Step 2: Perform AML company search
                this.currentStatus = 'Henter data fra BankID AML...';
                if (this.testAml) {
                    console.log('using aml test-data');
                    this.amlData = await amlTools.doAmlCompanySearch('900800700');
                }else {
                    result = await this.performAMLSearch();
                    if (!result.ok) {
                        this.setError(result);
                        break;
                    }
                }
                


                // Step 3: Create and send out KYC form
                this.currentStatus = 'Sender ut egenerklæringsskjema...';
                result = await this.sendOutKYC();
                if (!result.ok) {
                    this.setError(result);
                    break;
                }


                // Step 4: Send an SMS notification to the contact person
                this.currentStatus = 'Sender SMS...';
                result = await this.sendSMS();
                if (!result.ok) {
                    this.setError(result);
                    break;
                }


                // Step 5: Update the batch object in the backend
                this.currentStatus = 'Fullfører kunde...';
                result = await this.updateStatus();
                if (!result.ok) {
                    this.setError(result);
                    break;
                }


                // Next customer
                this.currentStatus = 'Venter...';
                if (this.currentIndex >= this.batch.customers.length - 1) {
                    this.isComplete = true;
                }else {
                    this.currentIndex += 1;
                }
                this.setProgress();
            }
            this.isStopping = false;
            this.isRunning = false;
            if (this.isComplete) {
                this.loopStatus = 'Ferdig!';
            }else if (this.isError) {
                this.loopStatus = 'Stoppet på grunn av feil!';
            }else {
                this.loopStatus = 'Klar til start...';
            }
        },

        // --------------------------------------------------------------------------------

        setError(result) {
            this.errorMessage = result.errorMessage;
            this.isError = true;
        },

        // ----- Actions ------------------------------------------------------------------

        async createCustomer() {
            const result = { ok: false, errorMessage: 'unknown error' };
            try {

                this.brregData = await amlTools.getBrregFromNumber(this.batch.customers[this.currentIndex].identity);
                console.log('brreg', this.brregData);

                const customer = {};
                customer.type = 'org';
                customer.name = this.brregData.name;
                //customer.org_no = this.brregData.org_no;
                customer.identity = this.brregData.org_no;
                customer.country = 'NO';
                customer.source = 'brreg';

                customer.risk = 0;
                customer.batch = this.batchId;
                customer.expires = -2;
                customer.status = 'c-aml';
                customer.batchId = 'b';

                customer.department = this.batch.customers[this.currentIndex].department;
                customer.partner = Number(this.batch.customers[this.currentIndex].partner);

                const validLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
                if (validLetters.includes(customer.name.charAt(0).toUpperCase())) {
                    customer.letter = customer.name.charAt(0).toUpperCase();
                }else {
                    customer.letter = '#';
                }

                const response = await api.newCustomer(customer);
                if (response.status == 200) {
                    result.errorMessage = 'Customer already exists';
                }else if (response.status == 201) {
                    this.batch.customers[this.currentIndex].customerId = response.id;
                    this.customerData = response.customer;
                    result.ok = true;
                }else {
                    result.errorMessage = 'Error while creating customer';
                }

            }catch (ex) {
                result.errorMessage = ex.message;
            }
            return result;
        },

        async performAMLSearch() {
            const result = { ok: false, errorMessage: 'unknown error' };
            const existingData = {
                customer: this.customerData,
                brregData: this.brregData,
                nextStatus: 'f-kyc-a'
            };
            const amlResult = await receipts.doAmlSearch(this.$root, this.$parent, existingData, true);
            if (amlResult.success) {
                this.amlData = amlResult.amlData;
                result.customer = amlResult.customer;
                result.ok = true;
            }else {
                if (amlResult.exceptions.length > 0) {
                    result.errorMessage = amlResult.exceptions[0].message;
                }else {
                    result.errorMessage = 'Unknown error with call to BankID AML Organization';
                }
            }
            return result;
        },

        async sendOutKYC() {
            const result = { ok: false, errorMessage: 'unknown error' };
            try {

                this.ud = {
                    amlData: null,
                    amlTimestamp: '',
                    brregData: {},
                    manualData: {},
                    ownerQuestion: {
                        noOwners: false,
                        hasOwners: false,
                        public: false
                    },
                    entityQuestion: {
                        manual: false,
                        file: false
                    },
                    roles: [],
                    rrhs: [],
                    entities: [],
                    peps: [],
                    includeAA: true
                };
                this.ud.amlData = this.amlData;
                this.ud.brregData = this.brregData;

                // Process AML data
                this.ud.amlTimestamp = tools.dateString(new Date());
                this.ud.roles = amlTools.getKeyRoleList(this.ud.amlData);
                this.ud.rrhs = amlTools.getRRHList(this.ud.amlData);
                this.ud.entities = amlTools.getEntityList(this.ud.amlData);
                this.processAmlData(this.ud.amlData);

                this.ud.questions = {};

                 // Add receiver to userdata
                this.ud.receiver = {
                    fname: this.batch.customers[this.currentIndex].firstName,
                    lname: this.batch.customers[this.currentIndex].lastName,
                    email: this.batch.customers[this.currentIndex].email,
                    language: 'nb_NO'
                };

                // Set mail-script parameters
                const emailData = {
                    firstName: this.ud.receiver.fname,
                    lastName: this.ud.receiver.lname,
                    senderName: this.$root.user.givenName + ' ' + this.$root.user.familyName,
                    senderEmail: this.$root.user.email,
                    customerName: this.brregData.name
                };
                const lang = this.ud.receiver.language;
                this.ud.mail = kychelper.getMailData(this.$root, this.kycIndex, emailData, lang, true);

                this.ud.actions = {
                    dateSent: new Date(),
                    sender: {
                        firstName: this.$root.user.givenName,
                        lastName: this.$root.user.familyName,
                        email: this.$root.user.email,
                        company: this.$root.user.companyName
                    },
                    receiver: {
                        firstName: this.ud.receiver.fname,
                        lastName: this.ud.receiver.lname,
                        language: this.$root.locale.key,
                        signingMethod: 'email',
                        email: this.ud.receiver.email,
                    }
                };

                // Create envelope
                const envelope = await v2lib.createEnvelope('aml-portal-kyc', 'simple-public-flow-with-silent-parties', false);

                // Now that we have the envelopeId, check if this kyc should use an in-portal landing page
                if (this.$root.config.kycs[this.kycIndex].settings.landingPage) {
                    this.ud.mail.redirect = this.$root.baseUrl + '/kyclanding/' + envelope.id + '?act=' + envelope.bearerToken;
                }

                // Add KYC data to customer
                this.customerData.kyc = {
                    status: 1,
                    userId: this.$root.credentials.uid,
                    envelopeId: envelope.id,
                    sentDate: new Date()
                }

                // Get expiration day from config
                const expirationDay = kychelper.getExpirationDay(this.$root.config.kycs[this.kycIndex].settings.expirationDays);

                // Update customer in backend and get bearer token
                const params = { expires: expirationDay };
                const response = await api.sendOutKYC(this.customerData, params);
                this.ud.credentials = {
                    id: this.customerData._id,
                    cid: this.$root.credentials.cid,
                    key: this.$root.credentials.key,
                    bearerToken: response.token
                }
                if (this.$root.credentials.testinc) this.ud.credentials.testinc = this.$root.credentials.testinc;
                
                this.ud.customer = this.customerData;

                // Set KYC config
                this.ud.config = { key: this.kycKey, nextStat: 'r-rep-a' };

                // Set template userdata and name
                const documentName = 'Egenerklæring ' + this.brregData.name + '.pdf';
                this.ud.fileName = documentName;
                await v2lib.setUserData(envelope, this.ud);
                await v2lib.setNameAndTags(envelope, documentName, ['egenerklæring']);
                await v2lib.setName(envelope, documentName);

                // Set editor recipient
                const recipient = {
                    givenName: this.ud.receiver.fname,
                    familyName: this.ud.receiver.lname,
                    language: this.$root.locale.key,
                    signingMethod: 'email',
                    email: this.ud.receiver.email,
                    order: 1,
                    role: { action: "edit", label: "Fylle i skjemaet", name: "editor" }
                };
                await v2lib.addRecipient(envelope, recipient);

                // Publish the envelope
                await v2lib.publishEnvelope(envelope);

                this.batch.customers[this.currentIndex].kycSent = true;
                result.ok = true;

            }catch (ex) {
                console.log(ex);
                result.errorMessage = ex.message;
            }
            return result;
        },

        async sendSMS() {
            const result = { ok: false, errorMessage: 'unknown error' };
            try {

                if (!(this.batch.customers[this.currentIndex].phone && this.batch.customers[this.currentIndex].phone.length > 5)) {
                    log.e('no phone number, skipping SMS');
                    result.ok = true;
                    return result;
                }

                const firstName = this.batch.customers[this.currentIndex].firstName;
                const lastName = this.batch.customers[this.currentIndex].lastName;
                const companyName = this.batch.customers[this.currentIndex].name;
                let message = `Hei ${firstName}!\n\nDeloitte jobber for tiden med å oppdatere informasjon om våre kunder i forbindelse med ny hvitvaskingslov. Les mer her: https://www2.deloitte.com/no/no.html\n\nDu er registrert som vår kontaktperson i ${companyName} og du vil derfor snart motta en e-post med link til et elektronisk egenerklæringsskjema.\n\nE-posten er sendt fra noreply@verified.eu\nEmne: Egenerklæring ${companyName}\n\nMvh Deloitte`;
                message = `Hei ${firstName}!\n\nDu er registrert som vår kontaktperson i ${companyName} og du vil derfor snart motta en e-post med link til et elektronisk egenerklæringsskjema.\n\nE-posten er sendt fra noreply@verified.eu\nEmne: Egenerklæring ${companyName}\n\nMvh Deloitte`;
                message = `Hei,\nSom kontaktperson for ${companyName} mottar du en e-post fra e-postadressen noreply@verified.eu med link til et elektronisk egenerklæringsskjema. Dette er et ledd i Deloitte Norges kundekontroll etter hvitvaskingsloven. Vi er takknemlig om skjemaet returneres digitalt snarlig.\nPå forhånd takk.\nMed vennlig hilsen\nDeloitte`;

                console.log('message length = ' + message.length);
                console.log(message);

                let phone = this.batch.customers[this.currentIndex].phone;

                console.log('phone', phone);

                
                if (!this.skipSMS) {
                    console.log('sms');
                    await verified.sendSMS(phone, message, 'Deloitte');
                }
                

                result.ok = true;


            }catch (ex) {
                result.errorMessage = ex.message;
            }
            return result;
        },

        async updateStatus() {
            const result = { ok: false, errorMessage: 'unknown error' };
            try {
                this.batch.completeCount = this.currentIndex + 1;
                const response = await api.updateBatch(this.batch, this.currentIndex);
                //console.log('response', response);
                result.ok = true;
            }catch (ex) {
                result.errorMessage = ex.message;
            }
            return result;
        },

        // --------------------------------------------------------------------------------

        processAmlData(data) {
            if (this.ud.roles.length > 0) {
                for (let i in this.ud.roles) {
                    if (this.ud.roles[i].zip && this.ud.roles[i].zip.length == 3) this.ud.roles[i].zip = '0' + this.ud.roles[i].zip;
                    this.$set(this.ud.roles[i], 'phone', { prefix: '+47', number: '' });
                    this.$set(this.ud.roles[i], 'edit', false);
                    this.$set(this.ud.roles[i], 'pepQuestion', { yes: false, no: false });
                    for (let cc in this.$root.countriesJson) {
                        if (this.$root.countriesJson[cc].key == this.ud.roles[i].country) {
                            this.ud.roles[i].country = this.$root.countriesJson[cc].nb_NO;
                        }
                    }
                }
            }
            if (this.ud.rrhs.length > 0) {
                this.ud.ownerQuestion.hasOwners = true;
                for (let i in this.ud.rrhs) {
                    if (this.ud.rrhs[i].zip && this.ud.rrhs[i].zip.length == 3) this.ud.rrhs[i].zip = '0' + this.ud.rrhs[i].zip;
                    this.$set(this.ud.rrhs[i], 'phone', { prefix: '+47', number: '' });
                    this.$set(this.ud.rrhs[i], 'edit', false);
                    this.$set(this.ud.rrhs[i], 'pepQuestion', { yes: false, no: false });
                    for (let cc in this.$root.countriesJson) {
                        if (this.$root.countriesJson[cc].key == this.ud.rrhs[i].country) {
                            this.ud.rrhs[i].country = this.$root.countriesJson[cc].nb_NO;
                        }
                    }
                }
            }
            if (this.ud.entities.length > 0) {
                this.ud.entityQuestion.manual = true;
                for (let i in this.ud.entities) this.$set(this.ud.entities[i], 'edit', false);
            }
        },

        async loadBatch() {
            this.$parent.setLoading(true, 'Laster masseutsendelse...');

            this.batchId = this.$route.query.id;
            const response = await api.getBatch(this.batchId);
            this.batch = response.batch;

            this.currentIndex = this.batch.completeCount;

            console.log('batch', this.batch);

            this.setProgress();

            this.$parent.setLoading(false);
        },

    },
    async created() {
        this.$parent.setTitle('Masseutsendelse');
        if (this.$route.query.id) {
            this.loadBatch();

            // Load KYC translations and merge with main
            const defaults = locale.getDefaultKycTranslations();
            const tres = await api.getTranslations('kyc');
            this.kycTranslations = { ...defaults, ...tres.texts };
            this.$root.translations = { ...this.$root.translations, ...this.kycTranslations };

        }else {
            const response = await axios.get('deloitte_departments.json');
            const departments = [];
            departments.push({ key: 1001, name: 'Audit & Assurance', numbers: [] });
            departments.push({ key: 1002, name: 'Risk Advisory', numbers: [] });
            departments.push({ key: 1003, name: 'Financial Advisory', numbers: [] });
            departments.push({ key: 1004, name: 'Consulting', numbers: [] });
            departments.push({ key: 1005, name: 'Tax & Legal', numbers: [] });
            departments.push({ key: 1006, name: 'Deloitte', numbers: [] });
            departments.push({ key: 2001, name: 'Other', numbers: [] });
            for (const i in response.data) {
                if (response.data[i] == 'Audit & Assurance') {
                    departments[0].numbers.push(Number(i));
                }else if (response.data[i] == 'Risk Advisory') {
                    departments[1].numbers.push(Number(i));
                }else if (response.data[i] == 'Financial Advisory') {
                    departments[2].numbers.push(Number(i));
                }else if (response.data[i] == 'Consulting') {
                    departments[3].numbers.push(Number(i));
                }else if (response.data[i] == 'Tax & Legal') {
                    departments[4].numbers.push(Number(i));
                }else if (response.data[i] == 'Deloitte') {
                    departments[5].numbers.push(Number(i));
                }else {
                    departments[6].numbers.push(Number(i));
                }
            }
            //console.log('departments', departments);
            this.departments = departments;
        }
    }
}
</script>
<style scoped>

.ms-items {
    border-top: solid 1px var(--input-border);
    box-sizing: border-box;
}

.ms-item {
    border: solid 1px var(--input-border);
    border-top: none;
    border-right: none;
    box-sizing: border-box;
}
.ms-head label {
    font-weight: bold;
}
.ms-item-x  {
    float: left;
    padding: 3px 5px;
    font-family: monospace, monospace;
    letter-spacing: -0.8px;
    border-right: solid 1px var(--input-border);
    box-sizing: border-box;
}
.ms-item-e {
    color: var(--error-text);
    background-color: var(--error-background);
}
.ms-item-0 {
    width: 14%;
}
.ms-item-1 {
    width: 8%;
}
.ms-item-2 {
    width: 18%;
}
.ms-item-3 {
    width: 18%;
}
.ms-item-4 {
    width: 10%;
}
.ms-item-5 {
    width: 16%;
}
.ms-item-6 {
    width: 16%;
}



.bat-current {
    text-align: center;
    font-size: 18px;
    color: var(--text-strong);
}
.bat-current-stat {
    margin-top: 5px;
    text-align: center;
    font-size: 16px;
    color: var(--primary);
}


.bat-prog-text {
    margin-top: 20px;
    text-align: center;
    font-size: 24px;
    color: var(--text-strong);
}
.bat-prog-base {
    position: relative;
    width: 100%;
    height: 40px;
    margin: 0 auto;
    margin-top: 5px;
    text-align: center;
    border-radius: 20px;
    border: solid 2px var(--accent);
    background-color: var(--text-disabled);
    overflow: hidden;
    box-sizing: border-box;
}
.bat-prog-prog {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0%;
    background-color: var(--accent);
    opacity: 0.4;
}

.bat-loop-stat {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    color: var(--text-strong);
}
.bat-loop-stat-error {
    color: var(--val-error);
}
.bat-loop-error {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    color: var(--val-error);
}




.bat-items {

}
.bat-item {
    display: flex;
    margin-bottom: 15px;
}
.bat-icon-p {
    flex-shrink: 0;
    width: 40px; height: 40px;
    padding-top: 3px;
    padding-left: 2px;
    border-radius: 50%;
    background-color: var(--disabled-background);
    box-sizing: border-box;
}
.bat-icon-c {
    flex-shrink: 0;
    width: 40px; height: 40px;
    padding-top: 3px;
    padding-left: 3px;
    border-radius: 50%;
    background-color: var(--success-background);
    box-sizing: border-box;
}
.bat-prog {
    position: relative;
    flex-shrink: 0;
    width: 40px; height: 40px;
}
.bat-content {
    flex-grow: 100;
    margin-left: 15px;
}
.bat-top {
    
}
.bat-bot {
    
}
.bat-strong {
    margin-right: 10px;
    color: var(--text-strong);
}
.bat-weak {
    margin-right: 10px;
    color: var(--text-weak);
}
.bat-actions {
    display: flex;
}

</style>