import defaultFlows from '../v1flows/defaultFlows';
const v1filterHelper = {

    getQuery(root, filterObject) {
        const query = {
            sort: { dateUpdated: -1 },
            filters: this.getFilters(root, filterObject),
            from: (Number(filterObject.currentPage) - 1) * Number(filterObject.itemsPerPage),
            limit: filterObject.itemsPerPage,
            include: [],
            counts: filterObject.defaultCounts || ['letter'],
        };

        if (filterObject.sortModel == 'date_desc') query.sort = { date: -1 };
        if (filterObject.sortModel == 'date_created_desc') query.sort = { dateCreated: -1 };
        if (filterObject.sortModel == 'name_desc') query.sort = { name: 1 };

        for (const i in filterObject.possibleFilters) {
            if (filterObject.possibleFilters[i].formKey) {
                query.counts.push(filterObject.possibleFilters[i].formKey + '.fields.' + filterObject.possibleFilters[i].key);
            }else {
                query.counts.push(filterObject.possibleFilters[i].key);
            }
        }

        return query;
    },

    getFilters(root, filterObject) {
        const filters = [];

        if (root.config.params.hasDepartments && root.credentials.branch && !root.credentials.isMasterBranch) {
            filters.push({ field: 'department', op: 'in', values: [root.credentials.branch.toString()] });
        }
        if (filterObject.searchString) {
            filters.push({ field: 'name', op: 'search', value: filterObject.searchString });
        }
        if (filterObject.filterDateActive) {
            filters.push({ field: 'dateCreated', op: 'daterange', from: filterObject.filterDateFrom, to: filterObject.filterDateTo });
        }
        if (filterObject.letterFilter.length > 0) {
            filters.push({ field: 'letter', op: 'in', values: filterObject.letterFilter });
        }
        for (const key in filterObject.activeFilters) {
            if (filterObject.activeFilters[key].length > 0) {
                for (const i in filterObject.possibleFilters) {
                    if (filterObject.possibleFilters[i].key == key) {
                        filters.push({ field: filterObject.possibleFilters[i].processedKey, op: 'in', values: filterObject.activeFilters[key] });
                    }
                } 
            }
        }
        return filters;
    },

    updateFilters(root, filterObject, response) {
        filterObject.totalCount = response.count;
        filterObject.totalPages = Math.ceil(Number(filterObject.totalCount) / Number(filterObject.itemsPerPage));

        filterObject.filters = response.filters;


        // Sort filters
        if (filterObject.filters.status) filterObject.filters.status.sort((a, b) => (a._id > b._id) ? 1 : -1);
        if (filterObject.filters.flow) filterObject.filters.flow.sort((a, b) => (a._id > b._id) ? 1 : -1);
        if (filterObject.filters.userId) filterObject.filters.userId.sort((a, b) => (a._id > b._id) ? 1 : -1);

        filterObject.letters = [];
        if (response.filters.letter) {
            const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ#';
            for (let i=0; i < str.length; i++) {
                let match = false;
                for (let j=0; j < response.filters.letter.length; j++) {
                    if (response.filters.letter[j]._id == str.charAt(i)) { match = true; break; }
                }
                filterObject.letters.push({ letter: str.charAt(i), active: match, deselected: false });
            }
        }
    },

    filtersOnNavigate(reference, filterObject, page) {
        filterObject.currentPage = page;
        reference.filtersOnQuery();
    },

    filtersOnLetterSelected(reference, filterObject, letter) {
        filterObject.letterFilter.push(letter);
        reference.filtersOnQuery();
    },

    filtersOnSearch(reference, filterObject, str) {
        filterObject.searchString = str;
        filterObject.currentPage = 1;
        reference.filtersOnQuery();
    },

    filtersOnDateChanged(reference, filterObject) {
        if (filterObject.sortDateFrom && this.sortDateTo) {
            filterObject.filterDateFrom = new Date(filterObject.sortDateFrom);
            filterObject.filterDateTo = new Date(filterObject.sortDateTo + 'T23:59:59.000Z');
            filterObject.filterDateActive = true;
            filterObject.currentPage = 1;
            reference.filtersOnQuery();
        }
    },

    filtersOnChanged(reference, filterObject, filter, value) {
        for (const i in filterObject.activeFilters) {
            if (i == filter.key) {
                let match = false;
                for (const j in filterObject.activeFilters[i]) {
                    if (filterObject.activeFilters[i][j] == value) {
                        filterObject.activeFilters[i].splice(j, 1);
                        match = true;
                    }
                }
                if (!match) filterObject.activeFilters[i].push(value);
            }
        }
        filterObject.currentPage = 1;
        reference.filtersOnQuery();
    },

    filtersHasActive(filterObject) {
        if (filterObject.searchString) return true;
        if (filterObject.filterDateActive) return true;
        if (filterObject.letterFilter.length > 0) return true;
        for (const i in filterObject.activeFilters) {
            if (filterObject.activeFilters[i].length > 0) return true;
        }
        return false;
    },

    filtersOnRemoveAll(reference, filterObject) {
        filterObject.filterDateActive = false;
        filterObject.sortDateFrom = '';
        filterObject.sortDateTo = '';
        filterObject.searchString = '';
        filterObject.letterFilter = [];
        for (const i in filterObject.activeFilters) {
            filterObject.activeFilters[i] = [];
        }
        filterObject.currentPage = 1;
        reference.filtersOnQuery();
    },

    loadClientFilters(root, filterObject) {

        if (!filterObject.itemsPerPage) filterObject.itemsPerPage = 10;
        filterObject.currentPage = 0;
        filterObject.totalPages = 0;
        filterObject.totalCount = 0;

        filterObject.possibleFilters = [];
        filterObject.activeFilters = {};
        filterObject.texts = {};
        filterObject.completedKeys = [];

        filterObject.letters = [];
        filterObject.defaultCounts = null;

        filterObject.sortModel = 'date_updated_desc';
        filterObject.sortOptions = [
            { key: 'sort_last_updated', value: 'date_updated_desc' },
            { key: 'sort_last_created', value: 'date_created_desc' },
            { key: 'sort_name', value: 'name_desc' },
        ];

        filterObject.filters = null;
        filterObject.letterFilter = [];

        filterObject.sortDateFrom = '';
        filterObject.sortDateTo = '';
        filterObject.filterDateFrom = null;
        filterObject.filterDateTo = null;
        filterObject.filterDateActive = false;

        filterObject.texts.status = {};
        filterObject.texts.flows = {};
        filterObject.texts.departments = {};
        filterObject.texts.employees = {};
        filterObject.texts.hits = {};
        filterObject.texts.risk = {};
        filterObject.texts.branches = {};
        filterObject.texts.partners = {};
        filterObject.texts.custom = {};


        for (const i in root.config.clients.filters) {
            if (root.config.clients.filters[i].enabled) {
                const filter = {
                    enabled: root.config.clients.filters[i].enabled,
                    search: root.config.clients.filters[i].search,
                    limit: root.config.clients.filters[i].limit,
                    key: root.config.clients.filters[i].key,
                    processedKey: root.config.clients.filters[i].key,
                    textKey: root.config.clients.filters[i].textKey,
                    title: root.config.clients.filters[i].title,
                    formKey: root.config.clients.filters[i].formKey,
                };
                if (root.config.clients.filters[i].formKey) {
                    filter.processedKey = root.config.clients.filters[i].formKey + '.fields.' + root.config.clients.filters[i].key;
                }
                filterObject.possibleFilters.push(filter);
                root.$set(filterObject.activeFilters, root.config.clients.filters[i].key, []);
            }
        }

        // Load flows / statuses / custom fields
        const flows = [];
        const dflows = defaultFlows.getFlows();
        for (const i in dflows) flows.push(defaultFlows.getFlow(dflows[i]));
        for (const i in root.flowConfig.flows) flows.push(root.flowConfig.flows[i]);
        for (const i in flows) {
            const flow = flows[i];
            for (const j in flow.statuses) {
                if (!filterObject.texts.status[flow.statuses[j].key]) {
                    filterObject.texts.status[flow.statuses[j].key] = flow.statuses[j].text;
                }else {
                    for (const k in flow.statuses[j].text) {
                        filterObject.texts.status[flow.statuses[j].key][k] = flow.statuses[j].text[k];
                    }
                }
                if (flow.statuses[j].completed) filterObject.completedKeys.push(flow.statuses[j].key);
            }
            if (flow.fields) {
                for (const j in flow.fields) {
                    for (const k in flow.fields[j].values) {
                        const value = {};
                        for (const key in flow.fields[j].values[k]) {
                            if (key != 'key') value[key] = flow.fields[j].values[k][key];
                        }
                        filterObject.texts.custom[flow.fields[j].values[k].key] = value;
                    }
                }
            }
            filterObject.texts.flows[flow.key] = flow.title;
        }

        // Load departments - HB only
        if (root.config.params.hasDepartments) {
            for (const i in root.departments) {
                const text = {};
                for (const j in root.config.locales) {
                    if (root.config.locales[j].enabled) {
                        text[root.config.locales[j].key] = root.departments[i].name;
                    }
                }
                filterObject.texts.departments[root.departments[i].key.toString()] = text;
            }
        }

        // Load branches
        if (root.config.branches.enabled) {
            for (const i in root.departments) {
                const text = {};
                for (const j in root.config.locales) {
                    if (root.config.locales[j].enabled) {
                        text[root.config.locales[j].key] = root.departments[i].name;
                    }
                }
                filterObject.texts.branches[root.departments[i].key.toString()] = text;
            }
        }
        // Load partners
        if (root.config.partners.enabled) {
            for (const i in root.partners) {
                const text = {};
                for (const j in root.config.locales) {
                    if (root.config.locales[j].enabled) {
                        text[root.config.locales[j].key] = root.partners[i].firstName + ' ' + root.partners[i].lastName;
                    }
                }
                filterObject.texts.partners[root.partners[i].key.toString()] = text;
            }
        }

        // Load employees
        for (let i in root.employees) {
            const text = {};
            for (const j in root.config.locales) {
                if (root.config.locales[j].enabled) {
                    text[root.config.locales[j].key] = root.employees[i].name;
                }
            }
            filterObject.texts.employees[root.employees[i].id] = text;
        }

        // Create risk options
        for (const i in root.config.risk.values) {
            for (const j in root.config.risk.texts) {
                if (root.config.risk.texts[j].value == root.config.risk.values[i]) {
                    const option = {};
                    for (const key in root.config.risk.texts[j]) {
                        if (key != 'value' && key != 'color') option[key] = root.config.risk.texts[j][key];
                    }
                    filterObject.texts.risk[root.config.risk.texts[j].value] = option;
                }
            }
        }

        // Create hit options
        filterObject.texts.hits['0'] = root.v1translations['clients-hits-none'];
        filterObject.texts.hits['1'] = root.v1translations['clients-hits-pep'];
        filterObject.texts.hits['2'] = root.v1translations['clients-hits-sanction'];
        filterObject.texts.hits['3'] = root.v1translations['clients-hits-both'];

        filterObject.currentPage = 1;
    },

    loadPersonFilters(root, filterObject) {

        if (!filterObject.itemsPerPage) filterObject.itemsPerPage = 10;
        filterObject.currentPage = 0;
        filterObject.totalPages = 0;
        filterObject.totalCount = 0;

        filterObject.possibleFilters = [];
        filterObject.activeFilters = {};
        filterObject.texts = {};
        filterObject.completedKeys = [];

        filterObject.letters = [];
        filterObject.defaultCounts = ['userId'];

        filterObject.sortModel = 'date_updated_desc';
        filterObject.sortOptions = [
            { key: 'sort_last_updated', value: 'date_updated_desc' },
            { key: 'sort_last_created', value: 'date_created_desc' },
            { key: 'sort_name', value: 'name_desc' },
        ];

        filterObject.filters = null;
        filterObject.letterFilter = [];

        filterObject.sortDateFrom = '';
        filterObject.sortDateTo = '';
        filterObject.filterDateFrom = null;
        filterObject.filterDateTo = null;
        filterObject.filterDateActive = false;

        filterObject.texts.status = {};
        filterObject.texts.flows = {};
        filterObject.texts.employees = {};
        filterObject.texts.hits = {};
        filterObject.texts.custom = {};
        filterObject.texts.monitors = {};


        /* for (const i in root.config.roles.filters) {
            if (root.config.roles.filters[i].enabled) {
                const filter = {
                    enabled: root.config.roles.filters[i].enabled,
                    search: root.config.roles.filters[i].search,
                    limit: root.config.roles.filters[i].limit,
                    key: root.config.roles.filters[i].key,
                    processedKey: root.config.roles.filters[i].key,
                    textKey: root.config.roles.filters[i].textKey,
                    title: root.config.roles.filters[i].title,
                    formKey: root.config.roles.filters[i].formKey,
                };
                if (root.config.roles.filters[i].formKey) {
                    filter.processedKey = root.config.roles.filters[i].formKey + '.fields.' + root.config.roles.filters[i].key;
                }
                filterObject.possibleFilters.push(filter);
                root.$set(filterObject.activeFilters, root.config.roles.filters[i].key, []);
            }
        } */

        if (root.config.screening.personMonitor) {
            const filter = {
                enabled: true,
                search: false,
                limit: 0,
                key: 'monitors',
                processedKey: 'monitors',
                textKey: 'monitors',
                title: { en_EN: 'Monitors', nb_NO: 'Monitors', sv_SE: 'Monitors' },
                formKey: '',
            };
            filterObject.possibleFilters.push(filter);
            root.$set(filterObject.activeFilters, 'monitors', []);
        }

        // Load flows / statuses / custom fields
        const flows = [];
        const dflows = defaultFlows.getFlows();
        for (const i in dflows) flows.push(defaultFlows.getFlow(dflows[i]));
        for (const i in root.flowConfig.flows) flows.push(root.flowConfig.flows[i]);
        for (const i in flows) {
            const flow = flows[i];
            for (const j in flow.statuses) {
                if (!filterObject.texts.status[flow.statuses[j].key]) {
                    filterObject.texts.status[flow.statuses[j].key] = flow.statuses[j].text;
                }else {
                    for (const k in flow.statuses[j].text) {
                        filterObject.texts.status[flow.statuses[j].key][k] = flow.statuses[j].text[k];
                    }
                }
                if (flow.statuses[j].completed) filterObject.completedKeys.push(flow.statuses[j].key);
            }
            if (flow.fields) {
                for (const j in flow.fields) {
                    for (const k in flow.fields[j].values) {
                        const value = {};
                        for (const key in flow.fields[j].values[k]) {
                            if (key != 'key') value[key] = flow.fields[j].values[k][key];
                        }
                        filterObject.texts.custom[flow.fields[j].values[k].key] = value;
                    }
                }
            }
            filterObject.texts.flows[flow.key] = flow.title;
        }

        // Load employees
        for (let i in root.employees) {
            const text = {};
            for (const j in root.config.locales) {
                if (root.config.locales[j].enabled) {
                    text[root.config.locales[j].key] = root.employees[i].name;
                }
            }
            filterObject.texts.employees[root.employees[i].id] = text;
        }

        // Load monitors
        if (root.monitors && root.monitors.list) {
            for (const i in root.monitors.list) {
                const text = {};
                for (const j in root.config.locales) {
                    if (root.config.locales[j].enabled) {
                        text[root.config.locales[j].key] = root.monitors.list[i].name;
                    }
                }
                filterObject.texts.monitors[root.monitors.list[i].id] = text;
            }
        }

        filterObject.currentPage = 1;
    },

    loadEmptyFilters(root, filterObject, countKey, sort) {

        if (!filterObject.itemsPerPage) filterObject.itemsPerPage = 10;
        filterObject.currentPage = 0;
        filterObject.totalPages = 0;
        filterObject.totalCount = 0;

        filterObject.possibleFilters = [];
        filterObject.activeFilters = {};
        filterObject.texts = {};
        filterObject.completedKeys = [];

        filterObject.letters = [];
        filterObject.defaultCounts = [countKey];

        filterObject.sortModel = sort;
        filterObject.sortOptions = [];

        filterObject.filters = null;
        filterObject.letterFilter = [];

        filterObject.sortDateFrom = '';
        filterObject.sortDateTo = '';
        filterObject.filterDateFrom = null;
        filterObject.filterDateTo = null;
        filterObject.filterDateActive = false;

        filterObject.currentPage = 1;
    },

    addFilter(root, filterObject, key, title) {
        const filter = {
            enabled: true,
            search: false,
            limit: 0,
            key: key,
            processedKey: key,
            textKey: '',
            title: root.v1translations[title],
            formKey: '',
        };
        filterObject.possibleFilters.push(filter);
        root.$set(filterObject.activeFilters, key, []);
    },

};
export default v1filterHelper;