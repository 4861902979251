<template>
<div class="sbox" @keydown="handleKeyDown">
    
    <input class="sbox-i"
        v-model="str"
        :placeholder="placeholder"
    />

    <div class="sbox-icon">
        <vap-icon small absolute :action="str != ''" :icon="str ? 'cancel' : 'search'" :color="'var(--accent)'" @clicked="reset" />
    </div>

</div>
</template>
<script>

export default {
    name: 'SearchBox',
    props: {
        value: [String, Number],
        placeholder: {
            type: String,
            default: 'Søk etter kunder'
        }
    },
    data() {
        return {
            str: ''
        }
    },
    methods: {
        handleKeyDown(event) {
            if (event.keyCode == 13) {
                console.log('emit', this.str);
                this.$emit('search', this.str);
            }
        },
        reset() {
            this.str = '';
        }
    },
    async created() {
        
    }
}
</script>
<style>

.sbox {
    position: relative;
    float: left;
    width: 100%;
    height: 42px;
    box-sizing: border-box;
}

.sbox input {
    border: none;
    outline: none;
    width: 100%;
    height: 42px;
    padding: 6px 16px;
    padding-top: 7px;
    font-size: 15px;
    border-radius: 21px;
    box-sizing: border-box;
    background-color: var(--card-background);
}

.sbox-icon {
    position: absolute;
    top: 7px;
    right: 40px;
}

</style>
