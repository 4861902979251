<template>
<div>

    

</div>
</template>
<script>
import v1backoffice from '../v1admin/v1backoffice';

export default {
    name: 'BackofficeError',
    components: {
        
    },
    data() {
        return {
            loading: true,
        }
    },
    methods: {
        
        

    },
    async created() {
        

        this.loading = false;
    }
}
</script>