<template>
<div>

    <PopupV1 v-if="dialog.show" :fullscreen="$root.windowHeight < 800" :maxHeight="$root.windowHeight < 800 ? 500 : 0" :header="__t('screening-person')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div class="flw100">
                <div style="margin-bottom: 10px; color: var(--text-title);" v-if="!$root.config.screening.personMonitor">
                    {{ __t('screening-hint-documents') + ':' }}
                </div>
                <DialogContent v-if="!$root.config.screening.personMonitor">
                    <DialogContentHeader :text="__t('files-document').toUpperCase() + ':'" />
                    <DialogContentItem :text="__t('screening-person') + ' ' + params.person.displayName" :icon="'file-search'" />
                    <DialogContentHeader :text="__t('files-attachments').toUpperCase() + ':'" v-if="$root.config.screening.nordicProvider == 'baml'" />
                    <DialogContentItem :text="'BankID AML Person Report'" :icon="'attachment'" v-if="$root.config.screening.nordicProvider == 'baml'" />
                </DialogContent>
                <div style="margin-top: 20px; margin-bottom: 10px; color: var(--text-title);" v-if="!$root.config.screening.personMonitor">
                    {{ __t('screening-hint-sources') + ':' }}
                </div>
                <div style="margin-top: 0px; margin-bottom: 10px; color: var(--text-title);" v-if="$root.config.screening.personMonitor">
                    {{ __t('screening-hint-sources') + ':' }}
                </div>
                <DialogContent style="position: relative; padding-top: 10px;">
                    <DialogContentItem v-for="(source, i) in sources" :key="'sds' + i" :text="source.name" :icon="'search'" />
                    <div class="scrdv1-screen-image">
                        <div class="scrdv1-screen-image-baml" v-if="$root.config.screening.nordicProvider == 'baml'">
                            <img :src="$root.images.bamltiny" />
                        </div>
                        <div class="scrdv1-screen-image-trap" v-if="$root.config.screening.nordicProvider == 'trapets'">
                            <img :src="$root.images.trapetstiny" />
                        </div>
                    </div>
                </DialogContent>
                <div style="margin-top: 20px; margin-bottom: 10px; color: var(--text-title);">
                    {{ __t('screening-parameters') + ':' }}
                </div>
                <DialogContent style="float: left; padding-top: 10px;">
                    <VFCheckbox style="float: left;"
                        inline radio
                        v-model="paramModel"
                        :options="paramOptions"
                        @input="validateDialog"
                    />
                    <div class="flw100" style="margin-top: 10px; margin-bottom: 10px; font-size: 15px; color: var(--text-title);">
                        <span v-if="paramModel.name">{{ __t('screening-hint-name') }}</span>
                        <span v-if="paramModel.ssn">{{ __t('screening-hint-ssn') }}</span>
                    </div>
                </DialogContent>

                <MonitorSelector v-if="$root.config.screening.personMonitor"
                    :params="params"
                    @changed="onMonitorValidation"
                />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('screening-perform')" :icon="'search'" @clicked="onPerformScreening" :disabled="!dialog.valid" :working="dialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';

import MonitorSelector from '../v1monitor/MonitorSelector.vue';

export default {
    name: 'ScreeningDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox,
        MonitorSelector,
    },
    props: {
        params: { type: Object, default: null },
        progress: { type: Object, default: null }
    },
    data() {
        return {
            dialog: { show: true, valid: false, working: false, status: '' },
            sources: [
                { name: 'PEP_EDGE' },
                { name: 'EU_CONSOLIDATED' },
                { name: 'UN_CONSOLIDATED' },
            ],
            paramModel: {
                name: true,
                ssn: false
            },
            paramOptions: {
                name: { locale: { en_EN: 'Name/Date of Birth' } },
                ssn: { locale: { en_EN: 'Social Security Number' } },
            },
            monitorIds: [],
        }
    },
    watch: {
        progress: {
            deep: true,
            handler(val) {
                console.log('progress', val);
                this.dialog.working = val.working;
                this.dialog.status = val.status;
            }
        }
    },
    methods: {

        onMonitorValidation(valid, addCount, monitorIds) {
            if (addCount > 0) {
                this.monitorIds = monitorIds;
            }else {
                this.monitorIds = [];
            }
        },

        validateDialog() {
            if (this.paramModel.ssn && !this.params.person.info.ssn) {
                this.dialog.error = this.__t('screening-no-ssn');
                this.dialog.valid = false;
            }else {
                this.dialog.error = '';
                this.dialog.valid = true;
            }
        },

        onPerformScreening() {
            this.$emit('screen', this.paramModel.ssn, this.monitorIds);
        },

        onClose() {
            this.$emit('close');
        }

    },
    created() {
        for (const i in this.$root.config.locales) {
            if (this.$root.config.locales[i].enabled) {
                this.paramOptions.name.locale[this.$root.config.locales[i].key] = this.__t('screening-param-name');
                this.paramOptions.ssn.locale[this.$root.config.locales[i].key] = this.__t('screening-param-ssn');
            }
        }
        if (this.params.person.info.ssn) {
            this.paramModel.name = false;
            this.paramModel.ssn = true;
        }
        this.validateDialog();
    }
}
</script>
<style scoped>

.scrdv1-screen-image {
    position: absolute;
    top: 15px; right: 20px;
}

.scrdv1-screen-image-baml img {
    width: 140px;
}

.scrdv1-screen-image-trap img {
    width: 100px;
}

</style>