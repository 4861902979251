const uihelper = {

    getAvailableIcons() {
        return [
            'add',
            'form',
            'delete',
            'open',
            'help-o',
            'info',
            'cancel',
            'settings',
            'colors',
            'people',
            'check-c',
            'company',
            'orgchart',
            'checklist',
            'sign',
            'edit',
            'check',
            'batch',
            'pep',
            'person',
            'locked',
            'file-sent',
            'file-check',
            'file-new',
            'file-search',
            'client-search',
            'reminder',
            'search',
            'copy',
            'paste',

            'chev-l',
            'chev-r',
            'chev-u',
            'chev-d',
            'burger',
            'input-text',
            'input-number',
            'input-date',
            'input-check',
            'input-drop',
            'alpha',
            'attachment',
            'phone',
            'home',
            'logic',
            'save',
            'eye',
            'menu-h',
            'menu-v',
            'card',
            'locale',
            'link',
            'undo',
            'redo',
            'resource',
            'device',
            'monitor',
            'verified',
            'chart',

            'modules',
            'download',
            'upload',
            'flow',
            'button',

            'align-left',
            'align-center',
            'align-right',
            'increase-font',
            'decrease-font',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'list',

            'day',
            'night',
            'refresh',
            'merge',

            'pdf',
            'bug',
            'money',
            'bank',
            'update',
            'message',
            'warning',
            'cloud-search',
        ];
    },

    validateStep(form, stepKey) {
        try {
            return form.$refs[stepKey].validate();
        }catch(ex) {
            return true;
        }
    },

    enableStep(steps, key, value) {
        for (let i in steps) {
            if (steps[i].key == key) {
                steps[i].nextDisabled = !value;
            }
        }
    },

    setStepMessage(steps, key, message) {
        for (let i in steps) {
            if (steps[i].key == key) {
                steps[i].message = message;
            }
        }
    },

    numberFromInput(model) {
        try {
            const nval = Number(model.toString().split(' ').join('').replace(',', '.'));
            const valid = !isNaN(nval - parseFloat(nval));
            if (valid) return nval;
        }catch (ex) {
            return null;
        }
        return null;
    },

    setCardActionWorking(actions, actionKey, working) {
        for (const i in actions) {
            if (actions[i].action == actionKey) {
                actions[i].working = working;
            }
        }
    },

};
export default uihelper;