<template>
<div class="slim-page">

    <vap-label :text="'Flows'" header style="margin-bottom: 5px;">
        <template v-slot:actions>
            <vap-icon v-if="$root.config.flow.editor.allowNew || $root.credentials.isAdmin" action :icon="'add'" @clicked="showCreateDialog">
                <template v-slot:tooltip><vap-tooltip :text="'Add flow'"></vap-tooltip></template>
            </vap-icon>
        </template>
    </vap-label>

    <vap-card list noPadding v-for="flow in flows" :key="flow.key">
        <div class="frv2-list">
            <div class="frv2-list-title">
                <label>{{ flow.title[$root.locale.key] }}</label>
                <span>{{ flow.key }}</span>
            </div>
            <div class="frv2-list-actions">
                <vap-icon v-if="$root.config.flow.editor.allowNew || $root.credentials.isAdmin" :icon="'delete'" action @clicked="onDeleteClicked(flow)" />
                <vap-icon v-if="$root.config.flow.editor.allowNew || $root.credentials.isAdmin" :icon="'copy'" action @clicked="showCopyDialog(flow)" />
                <vap-icon :icon="'edit'" action @clicked="onEditClicked(flow)" />
            </div>
        </div>
    </vap-card>

    <ve-popup v-if="createDialog.show" :header="'Create flow'" :width="600" @close="createDialog.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <div>
                <vap-row>
                    <vap-group c12>
                        <vap-label desc :text="'Title'" required />
                        <vap-input v-model="createDialog.title" :rules="'required'" noValidation noHighlight @input="validateCreateDialog" />
                    </vap-group>
                    <vap-group c12>
                        <vap-label desc :text="'Key'" required />
                        <vap-input v-model="createDialog.key" :rules="'required'" noValidation noHighlight @input="validateCreateDialog" />
                    </vap-group>
                    <vap-group c12>
                        <vap-label desc :text="'Copy from default flow'" required />
                        <vap-dropdown noValidation noHighlight
                            v-model="createDialog.flow"
                            :options="flowOptions"
                            :displayKey="'display'"
                            :listKey="'display'"
                            :outputKey="'value'"
                            :input="validateCreateDialog"
                        />
                    </vap-group>
                    <vap-group c12>
                        <vap-label desc :text="'Copy mode'" required />
                        <vap-checkbox noValidation noHighlight inline radio
                            v-model="createDialog.copyModel"
                            :options="copyOptions"
                            :input="validateCreateDialog"
                        />
                    </vap-group>
                </vap-row>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="createDialog.show = false" />
                <vap-button :text="'Create flow'" uppercase @clicked="createFlow" :disabled="!createDialog.valid" :working="createDialog.working" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="copyDialog.show" :header="'Copy flow'" :width="600" @close="copyDialog.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <div>
                <vap-row>
                    <vap-group c12>
                        <vap-label desc :text="'Key'" required />
                        <vap-input v-model="copyDialog.key" :rules="'required'" noValidation noHighlight @input="validateCopyDialog" />
                    </vap-group>
                </vap-row>
            </div>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="copyDialog.show = false" />
                <vap-button :text="'Copy flow'" uppercase @clicked="copyFlow" :disabled="!copyDialog.valid" :working="copyDialog.working" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="deleteDialog.show" :header="'Delete flow?'" :width="400" @close="deleteDialog.show = false" hideCloseButton>
        <div style="padding: 20px;">
            <label>{{ 'Are you sure you want to delete the flow ' + deleteDialog.title + '?' }}</label><br><br>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_no')" uppercase @clicked="deleteDialog.show = false" />
                <vap-button :text="__('common_yes')" uppercase @clicked="deleteFlow" :working="deleteDialog.working" />
            </vap-button-row>
        </div>
    </ve-popup>
    
</div>
</template>
<script>
import api from '../portalapi';
import v1api from '../v1helpers/v1api';
import defaultFlows from '../v1flows/defaultFlows';
import v1flows from '../v1helpers/v1flows';

export default {
    name: 'FlowSettings',
    components: {
        
    },
    props: {
        flow: { type: Object, default: null }
    },
    data() {
        return {
            flows: [],
            createDialog: { show: false, working: false, valid: false, key: '', title: '', flow: 'no-org-baml', copyModel: { inherit: true, copy: false } },
            copyDialog: { show: false, working: false, valid: false, key: '', source: null },
            deleteDialog: { show: false, working: false, key: '', title: '' },
            flowOptions: [
                { value: 'no-org-baml', display: 'BankID AML Organization Flow' },
                { value: 'se-org-bistrap', display: 'Trapets/Bisnode Organization Flow' },
                { value: 'xx-org', display: 'Forreign Organization Flow' },
                { value: 'xx-org-creation', display: 'Under Creation Organization Flow' },
                { value: 'zz-person', display: 'Physical Person Flow' },
                { value: 'yy-person', display: 'Physical Person Flow With Self Declaration Form' },
            ],
            previewVisible: false,
            previewParams: null,
            copyOptions: { inherit: { label: 'Inherit' }, copy: { label: 'Copy' } }
        }
    },
    methods: {
        
        onEditClicked(flow) {
            //console.log('flow', flow);
            this.$parent.$parent.$parent.navigate({ path: '/v1flow', query: { key: flow.key }});
            //this.$parent.$parent.$parent.navigate({ path: '/v1flowdesigner', query: { key: flow.key }});
        },

        onDeleteClicked(form) {
            this.deleteDialog.key = form.key;
            this.deleteDialog.title = form.title[this.$root.locale.key];
            this.deleteDialog.show = true;
        },


        async loadFlows() {
            this.flows = [];
            for (const i in this.$root.flowConfig.flows) {
                this.flows.push({
                    key: this.$root.flowConfig.flows[i].key,
                    title: this.$root.flowConfig.flows[i].title
                });
            }
        },

        showCreateDialog() {
            this.createDialog.show = true;
        },

        validateCreateDialog() {
            let valid = false;
            if (this.createDialog.key && this.createDialog.title) valid = true;
            this.createDialog.valid = valid;
        },

        async createFlow() {
            this.createDialog.working = true;

            const flow = defaultFlows.getFlow(this.createDialog.flow);

            flow.key = this.createDialog.key;
            flow.name = this.createDialog.title;

            if (this.createDialog.copyModel.inherit) {
                flow.defaultStatuses = this.createDialog.flow;
                flow.defaultEvents = this.createDialog.flow;
                flow.defaultReport = this.createDialog.flow;
                flow.statuses = [];
                flow.events.items = [];
                flow.report = [];
            }

            await v1api.createFlow(flow);

            const response = await api.loadConfig();
            this.$root.flowConfig = response.flows || { flows: [], steps: [] };

            await this.loadFlows();

            this.createDialog.working = false;
            this.createDialog.show = false;
        },

        showCopyDialog(flow) {
            const source = v1flows.getFlow(this.$root, flow.key);
            this.copyDialog.source = JSON.parse(JSON.stringify(source));
            console.log('source', this.copyDialog.source);
            this.copyDialog.show = true;
        },

        validateCopyDialog() {
            let valid = false;
            if (this.copyDialog.key) valid = true;
            this.copyDialog.valid = valid;
        },

        async copyFlow() {
            this.copyDialog.working = true;

            const flow = this.copyDialog.source;

            flow.key = this.copyDialog.key;

            await v1api.createFlow(flow);

            const response = await api.loadConfig();
            this.$root.flowConfig = response.flows || { flows: [], steps: [] };

            await this.loadFlows();

            this.copyDialog.working = false;
            this.copyDialog.show = false;
        },

        async deleteFlow() {
            this.deleteDialog.working = true;

            await v1api.deleteFlow(this.deleteDialog.key);

            const response = await api.loadConfig();
            this.$root.flowConfig = response.flows || { flows: [], steps: [] };

            await this.loadFlows();

            this.deleteDialog.working = false;
            this.deleteDialog.show = false;
        }

    },
    async created() {
        await this.loadFlows();
    }
}
</script>
<style scoped>

.frv2-list {
    padding: 10px 20px;
    display: flex;
}

.frv2-list-title {
    flex-grow: 100;
    font-size: 20px;
    display: flex;
    align-items: center;
}

.frv2-list-actions {
    display: flex;
}

.frv2-list-title > span {
    margin: 0 10px;
    color: #c1c1c1;
    font-size: 13px;
}

</style>