<template>
<div class="veper">

    <div class="veper-empty" v-if="!person.idc.done && !person.idc.sent">
        <div class="veper-empty-top">
            <VeSwitch v-model="checked" :disabled="disabled" :text="__t('identity-idc')" />
        </div>
        <div class="veper-empty-icon" v-if="!checked">
            <vap-icon :icon="'card'" massive :color="'var(--text-disabled)'" />
        </div>
        <div class="veper-screen" v-if="checked">
            <div class="veper-screen-image">
                <img :src="$root.images.vfblue" />
            </div>
            <div class="veper-screen-button">
                <vap-button :text="__t('identity-idc-start')" @clicked="$emit('form', 'idc01')" v-if="!$root.config.persons.idControlPopup" />
                <vap-button :text="'Start'" @clicked="$emit('popup', 'idc00')" v-if="$root.config.persons.idControlPopup" />
                <vap-button :text="__t('identity-manual')" @clicked="$emit('manual')" style="margin-left: 10px;" />
            </div>
        </div>
    </div>


<!--     <div class="veper-empty" v-if="!person.idc.done && person.idc.sent">
        <div class="veper-empty-top">
            <VeSwitch v-model="person.idc.sent" :text="__t('identity-idc')" disabled />
        </div>
        <div class="veper-screen">
            <div class="veper-screen-image" style="padding-left: 130px;">
                <vap-status-label :text="__('person_id_awaiting')" :completedText="__('ov_id_status_complete')" :name="''" />
            </div>
            <div class="veper-screen-button">
                <vap-button :text="__('common_reset')" @clicked="resetControl" />
                <vap-button :text="__('ov_send_reminder')" @clicked="sendReminder" style="margin-left: 10px;" />
            </div>
        </div>
    </div> -->

    <div v-if="!person.idc.done && person.idc.sent" class="veper-con">
        
        <PersonInfoContainer first>
            <template v-slot:info>
                <div class="veper-info-switch">
                    <VeSwitch v-model="person.idc.sent" :text="__t('identity-idc')" disabled />
                </div>
            </template>
        </PersonInfoContainer>

        <PersonInfoContainer>
            <template v-slot:info>
                <PersonInfoItem>
                    {{ person.idc.email || __t('identity-sent') }}
                </PersonInfoItem>
            </template>
        </PersonInfoContainer>
        
        <PersonInfoContainer>
            <template v-slot:info>
                <PersonInfoItem>
                    {{ __t('identity-awaiting') }}
                </PersonInfoItem>
            </template>
        </PersonInfoContainer>

        <PersonInfoContainer>
            <template v-slot:info>
                
                <PersonInfoItem :width="50" smallText weakText>
                    {{ getDateString(person.idc.sentDate) }}
                </PersonInfoItem>

                <PersonInfoItem :width="50" border smallText weakText>
                    {{ getTimePassedString(person.idc.sentDate) }}
                </PersonInfoItem>

            </template>
            <template v-slot:actions>
                <PersonInfoButton :icon="'delete'" :disabled="disabled" @clicked="$emit('reset')" />
                <PersonInfoButton :icon="'reminder'" :disabled="disabled" @clicked="$emit('reminder')" />
            </template>
        </PersonInfoContainer>

    </div>


    <div v-if="person.idc.done" class="veper-con">
        
        <PersonInfoContainer first>
            <template v-slot:info>
                <PersonInfoItem
                    :manual="indicator == 99"
                    :success="indicator == 1 || indicator == 2"
                    :warning="indicator == 3 || indicator == 4"
                    :error="indicator == 0 || indicator == 5 || indicator == 6 || indicator == 7"
                >
                    {{ person.idc.name }}
                </PersonInfoItem>
            </template>
        </PersonInfoContainer>

        <PersonInfoContainer>
            <template v-slot:info>

                <PersonInfoItem :width="50">
                    {{ getMethod(person.idc) }}
                </PersonInfoItem>

                <PersonInfoItem :width="50" border>
                    {{ person.idc.ssn }}
                </PersonInfoItem>

            </template>
            <template v-slot:actions v-if="$root.config.persons.identityInfo">
                <PersonInfoButton :icon="'info'" @clicked="$emit('info')" />
            </template>
        </PersonInfoContainer>
        
        <PersonInfoContainer>
            <template v-slot:info>
                <PersonInfoItem>
                    {{ getMatchDescription(person) }}
                </PersonInfoItem>
            </template>
            <template v-slot:actions v-if="$root.config.persons.allowIdentityUpdate && canMerge">
                <PersonInfoButton :icon="'merge'" @clicked="$emit('merge')" />
            </template>
        </PersonInfoContainer>

        <PersonInfoContainer>
            <template v-slot:info>
                
                <PersonInfoItem :width="50" smallText weakText>
                    {{ getDateString(person.idc.date) }}
                </PersonInfoItem>

                <PersonInfoItem :width="50" border smallText weakText>
                    {{ getTimePassedString(person.idc.date) }}
                </PersonInfoItem>

            </template>
            <template v-slot:actions>
                <PersonInfoButton :icon="'delete'" :disabled="disabled" @clicked="$emit('reset')" />
                <PersonInfoButton :icon="'file-sent'" @clicked="$emit('preview')" v-if="person.idc.method != 'manual'" />
            </template>
        </PersonInfoContainer>

    </div>
    
</div>
</template>
<script>
import tools from '../tools';
import v1personHelper from '../v1helpers/v1personHelper';

import PersonInfoContainer from './PersonInfoContainer.vue';
import PersonInfoButton from './PersonInfoButton.vue';
import PersonInfoItem from './PersonInfoItem.vue';
import VeSwitch from '../components/basic/VeSwitch.vue';

export default {
    name: 'PersonIdentity',
    components: {
        PersonInfoContainer,
        PersonInfoButton,
        PersonInfoItem,
        VeSwitch
    },
    props: {
        person: { type: Object, default: null },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            checked: false,
            indicator: 0,
            canMerge: false
        }
    },
    methods: {

        getMethod(idc) {
            let str = '';
            switch (idc.method) {
                case 'bankid-no': str = this.__t('method-bankid-no'); break;
                case 'bankid-se': str = this.__t('method-bankid-se'); break;
                case 'passport-reader': str = this.__t('method-passport-reader'); break;
                case 'manual': str = this.__t('method-manual'); break;
                case 'fileupload': str = 'Passopplasting'; break;
            }
            if (idc.source == 'signature') {
                str += ' (' + this.__t('verified-signing') + ')';
            }
            return str;
        },


        //#region Helpers -----------------------------------------------------------------------------------------------------
        
        getDateString(date) {
            return tools.dateStringShort(date, this.$root.locale.key);
        },

        getTimePassedString(date) {
            return tools.timePassedString(date, this.$root);
        },

        getMatchDescription(person) {
            if (person.idc.done && person.idc.method == 'manual') {
                return person.idc.comment || this.__t('common-no-comment');
            }else if (person.idc.done && person.idc.method == 'fileupload') {
                return 'Kontroller ID i kvittering';
            }
            return v1personHelper.getIdcMatchDescription(person);
        }

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    created() {
        //console.log('PERSON', this.person);
        this.indicator = v1personHelper.getIdcMatchIndicator(this.person);
        if (this.person.idc.done && (this.person.idc.method == 'manual' || this.person.idc.method == 'fileupload')) {
            this.indicator = 99;
        }
        if (this.person.idc.method == 'passport-reader' || this.person.idc.method == 'bankid-no' || this.person.idc.method == 'bankid-se') {
            if (this.indicator != 1) this.canMerge = true;
        }
    }
}
</script>
<style scoped>

.veper {
    background-color: var(--page-background);
    border-bottom-right-radius: 6px;
}

.veper-con {
    float: left;
    width: 100%;
    box-sizing: border-box;
}

.veper-empty {
    max-height: 137px;
}
.veper-empty-top {
    display: flex;
    padding: 5px 10px;
    border-bottom: solid 1px var(--input-border);
}
.veper-empty-icon {
    margin-top: -5px;
    padding-left: 36%;
    padding-bottom: 20px;
}


.veper-screen {
    min-height: 90px;
    max-height: 105px;
    text-align: center;
    box-sizing: border-box;
}
.veper-screen-image {
    margin-top: 15px;
}
.veper-screen-image img {
    width: 80px;
}
.veper-screen-button {
    margin-top: 7px;
}



.veper-info-switch {
    height: 40px;
    padding-top: 5px;
    padding-lefT: 10px;
    box-sizing: border-box;
}

</style>