<template>
<div class="v1rsk">
    
    <!-- Uncertainty bar -->
    <div class="v1rsk-uncert">
        <div class="v1rsk-uncert-text">
            <div class="v1rsk-uncert-text-l">
                {{ 'Uncertainty' }}
            </div>
            <div class="v1rsk-uncert-text-r">
                {{ risk.uncertainty + '%' }}
            </div>
        </div>
        <div class="v1rsk-uncert-bar-c">
            <div class="v1rsk-uncert-bar">
                <div class="v1rsk-uncert-bar-m" />
                <div class="v1rsk-uncert-bar-r" />
            </div>
            <div class="v1rsk-uncert-bar-x" :style="'left: calc(' + uncertaintyBar + '% - 5px);'" />
            <div class="v1rsk-uncert-bar-l"  :style="'left: calc(' + uncertaintyBar + '% + 5px);'" />
        </div>
        <div class="v1rsk-uncert-msg">
            {{ risk.missingString }}
        </div>
    </div>

    <!-- Automatic bar -->
    <div class="v1rsk-res">
        <div class="v1rsk-res-text">
            <div class="v1rsk-res-text-l">
                {{ 'Risk classification' }}
            </div>
            <div class="v1rsk-res-text-r">
                {{ risk.riskText + ' - ' + risk.riskValue }}
            </div>
        </div>
        <div class="v1rsk-res-bar-c">
            <div class="v1rsk-res-bar" />
            <div class="v1rsk-res-bar-x1" :style="'right: calc(657px - ' + getLeft() + 'px);'" />
            <div class="v1rsk-res-bar-x2" :style="'left: calc(' + getLeft() + 'px + 60px);'" />
            <div class="v1rsk-res-bar-x" :style="'left: calc(' + getLeft() + 'px - 60px);'">
                {{ risk.riskText }}
            </div>
        </div>
    </div>


    <div class="v1rsk-values">

        <div v-for="(item, i) in risk.items" :key="'ri' + i" class="v1rsk-row" v-show="i < maxItems || expanded" :class="{'v1rsk-row-first': i == 0 }">
            <div class="v1rsk-nv">
                <div class="v1rsk-name">
                    {{ item.name }}
                </div>
                <div class="v1rsk-value">
                    {{ item.value }}
                </div>
            </div>
            <div class="v1rsk-val">
                <div class="v1rsk-ml">
                    {{ item.riskML }}
                </div>
                <div class="v1rsk-tf">
                    {{ item.riskTF }}
                </div>
            </div>
        </div>

    </div>

    <div class="vap-event-footer" v-if="overflow">
        <div class="vap-event-exptext">
            <label v-if="!expanded" @click="onExpandClicked">
                {{ '+ ' + overflowCount + ' ' + (overflowCount == 1 ? 'more value' : 'more values') }}
            </label>
        </div>
        <div class="vap-event-button" @click="onExpandClicked">
            {{ expanded ? 'Hide values' : 'Show all values' }}
        </div>
    </div>

</div>
</template>
<script>

export default {
    name: 'RiskCard',
    components: {
        
    },
    props: {
        risk: { type: Object, default: null },
        maxItems: { type: [String, Number], default: 10 },
    },
    data() {
        return {
            overflow: false,
            expanded: false,
            uncertaintyBar: 0,
            overflowCount: 0
        }
    },
    methods: {
        
        getLeft() {
            const fac = (597 - 120) / 8;
            const res = ((this.risk.riskValue - 1) * fac) + 60;
            return res;
        },

        onExpandClicked() {
            this.expanded = !this.expanded;
            this.processOverflow();
        },

        processOverflow() {
            this.overflowCount = this.risk.items.length - this.maxItems;
            this.overflow = this.overflowCount > 0;
        },

    },
    async created() {
        //console.log('risk', this.risk);
        this.uncertaintyBar = this.risk.uncertainty;
        if (this.uncertaintyBar > 99) this.uncertaintyBar = 99;
        if (this.uncertaintyBar < 1) this.uncertaintyBar = 1;
        this.processOverflow();
    }
}
</script>
<style scoped>

.v1rsk {
    float: left;
    width: 100%;
    padding: 10px 18px;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    background-color: var(--card-background);
    -webkit-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    -moz-box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.06);
    box-sizing: border-box;
}



.v1rsk-values {
    margin-top: 10px;
    float: left;
    width: 100%;
}
.v1rsk-row {
    float: left;
    width: 100%;
    display: flex;
    padding: 3px 0px;
    border-top: solid 1px var(--input-border);
}
.v1rsk-row-first {
    border-top: none;
}

.v1rsk-nv {
    flex-grow: 100;
}
.v1rsk-name {
    font-size: 12px;
}
.v1rsk-value {
    margin-top: -1px;
    font-size: 15px;
}

.v1rsk-val {
    flex-shrink: 0;
    display: flex;
}
.v1rsk-ml {
    width: 50px;
    padding-top: 5px;
    text-align: right;
    font-size: 20px;
}
.v1rsk-tf {
    width: 50px;
    padding-top: 5px;
    text-align: right;
    font-size: 20px;
}





.v1rsk-uncert {
    float: left;
    width: 100%;
}

.v1rsk-uncert-bar-c {
    position: relative;
    float: left;
    width: 100%;
}

.v1rsk-uncert-bar {
    float: left;
    width: 100%;
    height: 20px;
}
.v1rsk-uncert-bar-m {
    float: left;
    width: 80%;
    height: 20px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    background-image: linear-gradient(to right, #238CCC , #B62537);
}
.v1rsk-uncert-bar-r {
    float: left;
    width: 20%;
    height: 20px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #B62537;
}

.v1rsk-uncert-bar-x {
    position: absolute;
    top: -2px;
    height: 24px;
    width: 10px;
    border-radius: 3px;
    border: solid 2px black;
    box-sizing: border-box;
}

.v1rsk-uncert-bar-l {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 20px;
    opacity: 0.8;
    background-color: var(--card-background);
}

.v1rsk-uncert-text {
    display: flex;
    font-size: 14px;
    font-weight: 500;
}
.v1rsk-uncert-text-l {
    flex-grow: 100;
}
.v1rsk-uncert-text-r {
    flex-shrink: 0;
}

.v1rsk-uncert-msg {
    float: left;
    width: 100%;
    padding-top: 2px;
    font-size: 14px;
}



.v1rsk-res {
    margin-top: 10px;
    float: left;
    width: 100%;
}

.v1rsk-res-bar-c {
    position: relative;
    float: left;
    width: 100%;
}


.v1rsk-res-bar {
    float: left;
    width: 100%;
    height: 20px;
    border-radius: 8px;
    background-image: linear-gradient(to right, #238CCC, #FADD38, #B62537);
}


.v1rsk-res-bar-x1 {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 20px;
    opacity: 0.8;
    background-color: var(--card-background);
}

.v1rsk-res-bar-x2 {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 20px;
    opacity: 0.8;
    background-color: var(--card-background);
}

.v1rsk-res-bar-x {
    position: absolute;
    top: -2px;
    height: 24px;
    width: 120px;
    text-align: center;
    padding-top: 1px;
    font-weight: 500;
    color: black;
    border-radius: 5px;
    border: solid 2px black;
    box-sizing: border-box;
}


.v1rsk-res-text {
    display: flex;
    font-size: 14px;
    font-weight: 500;
}
.v1rsk-res-text-l {
    flex-grow: 100;
}
.v1rsk-res-text-r {
    flex-shrink: 0;
}



.vap-event-footer {
    float: left;
    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-bottom: 0px;
}

.vap-event-exptext {
    flex-grow: 100;
    padding-top: 4px;
    color: var(--link-text);
    font-weight: 500;
}
.vap-event-exptext label {
    cursor: pointer;
}
.vap-event-exptext label:hover {
    color: var(--link-active);
}

.vap-event-button {
    height: 20px;
    margin-left: 5px;
    padding: 0px 14px;
    padding-top: 4px;
    padding-bottom: 2px;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-title);
    border-radius: 3px;
    border: 1px solid var(--input-border);
    background-color: var(--page-background);
    cursor: pointer;
}
.vap-event-button:hover {
    color: var(--button-text);
    background-color: var(--button);
}

</style>