const methodHelper = {

    getAvailableSignMethods(root, formData) {
        const list = [];
        const config = root.config.params.signMethods;

        let module = null;
        for (const i in formData.modules) {
            if (formData.modules[i].type == 'sign') module = formData.modules[i];
        }

        if (module && module.methods) {
            if (module.methods.bankidno && config.bankidno) list.push({ value: 'bankid-no' });
            if (module.methods.bankidse && config.bankidse) list.push({ value: 'bankid-se' });
            if (module.methods.touchsign && config.touchsign) list.push({ value: 'touch-sign' });
        }else {
            list.push({ value: 'bankid-no' });
            list.push({ value: 'bankid-se' });
            list.push({ value: 'touch-sign' });
        }

        for (const i in formData.locales) {
            if (formData.locales[i].enabled) {
                for (const j in list) {
                    list[j][formData.locales[i].key] = this.getMethodName(root, list[j].value, formData.locales[i].key);
                }
            }
        }

        //console.log('method list', list);
        return list;
    },

    getAvailableAuthMethods(root, modules) {

    },

    getMethodName(root, value, locale) {
        switch (value) {
            case 'bankid-no': return root.translations['method_bankidno_head'][locale];
            case 'bankid-se': return root.translations['method_bankidse_head'][locale];
            case 'nets-esign-dk': return root.translations['method_nemidnets_head'][locale];
            case 'touch-sign': return root.translations['method_touchsign_head'][locale];
            case 'passport-reader': return root.translations['method_passreader_head'][locale];
        }
        return value;
    },

};
export default methodHelper;