var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"vap-button",class:[
        {'vap-button-rounded' : _vm.rounded},
        {'vap-button-outline' : _vm.outline},
        {'vap-button-action' : _vm.action},
        {'vap-button-disabled' : _vm.disabled},
        {'vap-button-dotdisabled' : _vm.dotDisabled},
        {'vap-button-working' : _vm.working}
    ],style:(_vm.fixedWidth > 0 ? 'width: ' + _vm.fixedWidth + 'px;' : ''),on:{"click":_vm.handleClick}},[_vm._v(" "+_vm._s(_vm.uppercase ? _vm.text.toUpperCase() : _vm.text)+" "),(_vm.working)?_c('div',{staticClass:"vap-button-spinner"},[_c('vap-spinner',{attrs:{"small":""}})],1):_vm._e(),_c('div',{staticClass:"vap-button-tt"},[_vm._t("tooltip")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }