<template>
<div>

    <PopupV1 :header="'Logic Designer'" @close="$emit('close')" :width="900" :dialog="dialog" flexMode>
        <template v-slot:content>
            <div class="ldv2-container">

                <div class="ldv2-group" v-for="(item, i) in reference.logic[index].items" :key="'ldi' + i" :class="[{'ldv2-group-first': i == 0}]">

                    <div class="ldv2-head">
                        <div class="ldv2-head-itm ldv2-1">
                            <span>{{ 'Inputs' }}</span>
                            <div class="ldv2-head-add" @click="onAddInputClicked(i)">
                                <vap-icon tiny :icon="'add'" absolute :top="-2" :right="3" />
                            </div>
                        </div>
                        <div class="ldv2-head-itm ldv2-2">
                            <span>{{ 'Operators' }}</span>
                            <div class="ldv2-head-add" @click="onAddOperatorClicked(i)">
                                <vap-icon tiny :icon="'add'" absolute :top="-2" :right="3" />
                            </div>
                        </div>
                        <div class="ldv2-head-itm ldv2-3">
                            {{ 'Output' }}
                        </div>
                    </div>

                    <div class="ldv2-line">
                        <div class="ldv2-line-itm ldv2-x1">
                            <div v-for="(input, j) in item.inputs" :key="'ldx' + j" class="ldv2-input">
                                <div class="ldv2-input-top">
                                    <div class="ldv2-input-listdots" v-if="input.isList">
                                        <vap-icon small :icon="'menu-h'" absolute :top="-3" :right="-2" />
                                    </div>
                                    <div class="ldv2-input-name nohl">
                                        <span>{{ input.name }}</span>
                                        <span v-if="input.isList">{{ ' (list)' }}</span>
                                    </div>
                                    <div class="ldv2-input-delete ldv2-input-button" @click="onDeleteInput(i, j)">
                                        <vap-icon tiny :icon="'delete'" />
                                    </div>
                                </div>
                                <div class="ldv2-input-bot">
                                    <div class="ldv2-input-format ldv2-input-button" @click="onChangeInputFormat(i, j)" :class="[
                                        {'ldv2-format-s': input.format == 'S' },
                                        {'ldv2-format-b': input.format == 'B' },
                                        {'ldv2-format-n': input.format == 'N' },
                                    ]">
                                        {{ input.format }}
                                    </div>
                                    <div class="ldv2-input-editor">
                                        <input v-model="input.demoValue" v-if="input.format != 'B'" :class="[
                                            {'ldv2-format-s': input.format == 'S' },
                                            {'ldv2-format-n': input.format == 'N' },
                                        ]" @input="onDemoInputChanged(input)" />
                                        <div class="ldv2-input-editor-bool ldv2-format-b nohl" v-if="input.format == 'B'" @click="onChangeBoolValue(i, j, 0)">
                                            {{ input.demoValue }}
                                        </div>
                                    </div>
                                    <div class="ldv2-input-editor-x" v-if="input.isList">
                                        <input v-model="input.demoValue1" v-if="input.format != 'B'" :class="[
                                            {'ldv2-format-s': input.format == 'S' },
                                            {'ldv2-format-n': input.format == 'N' },
                                        ]" @input="onDemoInputChanged(input)" />
                                        <div class="ldv2-input-editor-bool ldv2-format-b nohl" v-if="input.format == 'B'" @click="onChangeBoolValue(i, j, 1)">
                                            {{ input.demoValue1 }}
                                        </div>
                                    </div>
                                    <div class="ldv2-input-editor-x" v-if="input.isList">
                                        <input v-model="input.demoValue2" v-if="input.format != 'B'" :class="[
                                            {'ldv2-format-s': input.format == 'S' },
                                            {'ldv2-format-n': input.format == 'N' },
                                        ]" @input="onDemoInputChanged(input)" />
                                        <div class="ldv2-input-editor-bool ldv2-format-b nohl" v-if="input.format == 'B'" @click="onChangeBoolValue(i, j, 2)">
                                            {{ input.demoValue2 }}
                                        </div>
                                    </div>
                                    <div class="ldv2-input-move-d ldv2-input-button" @click="onMoveInputDown(i, j)">
                                        <vap-icon tiny :icon="'chev-d'" />
                                    </div>
                                    <div class="ldv2-input-move-u ldv2-input-button" @click="onMoveInputUp(i, j)">
                                        <vap-icon tiny :icon="'chev-u'" />
                                    </div>
                                </div>
                            </div>
                            <div class="ldv2-line-empty" v-if="item.inputs.length == 0">
                                {{ 'No inputs' }}
                            </div>
                        </div>
                        <div class="ldv2-line-itm ldv2-x2">
                            <LogicOperatorRenderer v-for="(operator, k) in item.ops" :key="'ldo' + k"
                                :operator="operator"
                                @delete="onDeleteOperator(i, k)"
                                @perform="performLogic"
                            />
                            <div class="ldv2-line-empty" v-if="item.ops.length == 0">
                                {{ 'No operators' }}
                            </div>
                        </div>
                    </div>

                    <div class="ldv2-group-output">
                        <div class="ldv2-group-output-delete" @click="onDeleteGroup(i)">
                            <vap-icon tiny :icon="'delete'" />
                        </div>
                        <div class="ldv2-group-output-value">
                            <span class="ldv2-group-output-d">{{ 'Group output: ' }}</span>
                            <span class="ldv2-group-output-v" :class="[
                                {'ldv2-format-s': typeof item.demoOutput == 'string' },
                                {'ldv2-format-b': typeof item.demoOutput == 'boolean' },
                                {'ldv2-format-n': typeof item.demoOutput == 'number' },
                            ]">{{ item.demoOutput }}</span>
                        </div>
                        <div class="ldv2-group-output-pf" @click="onChangePassFalse(i)">
                            <vap-icon tiny :icon="'file-sent'" />
                        </div>
                    </div>

                </div>

            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Close'" outline @clicked="$emit('close')" />
            <VFButton :text="'Add group'" :icon="'add'" @clicked="onAddGroupClicked" />
            <div class="ldv2-settings">
                <div class="ldv2-main">
                    <div class="ldv2-main-desc">
                        {{ 'Main output:' }}
                    </div>
                    <div class="ldv2-main-value" :class="[
                        {'ldv2-format-s': typeof reference.logic[index].demoOutput == 'string' },
                        {'ldv2-format-b': typeof reference.logic[index].demoOutput == 'boolean' },
                        {'ldv2-format-n': typeof reference.logic[index].demoOutput == 'number' },
                    ]">
                        {{ reference.logic[index].demoOutput }}
                    </div>
                </div>
            </div>
        </template>
    </PopupV1>

    <!-- Add input dialog -->
    <PopupV1 :header="'Add input'" v-if="inputDialog.show" @close="inputDialog.show = false" :dialog="inputDialog">
        <template v-slot:content>
            <vap-item v-for="(option, i) in inputDialog.options" :key="'am' + i" fancy padding20
                :fancyIcon="option.icon" :fancyText="option.name" :fancyHint="option.hint"
                @clicked="onAddInput(option)"
            />
            <div v-if="inputDialog.options.length == 0">
                {{ 'No available inputs!' }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="inputDialog.show = false" />
        </template>
    </PopupV1>

    <LogicOperatorSelector v-if="operatorSelectorParams.show" @close="operatorSelectorParams.show = false" @select="onAddOperator" />

    <KycModuleView v-if="kycModule.show" :params="kycModule.uiParams" @close="kycModule.show = false" @connect="onAddKycInput" />
    
</div>
</template>
<script>
import logicHelper from '../logicHelper';

import LogicOperatorSelector from './LogicOperatorSelector.vue';
import LogicOperatorRenderer from './LogicOperatorRenderer.vue';

import PopupV1 from '../../../v1ui/PopupV1.vue';
import VFInput from '../inputs/VFInput.vue';
import VFCheckbox from '../inputs/VFCheckbox.vue';
import VFDropdown from '../inputs/VFDropdown.vue';
import VFRow from '../items/VFRow.vue';
import VFGroup from '../items/VFGroup.vue';
import VFButton from '../items/VFButton.vue';

import KycModuleView from '../modules/kyc/KycModuleView.vue';
import kycModuleHelper from '../modules/kyc/kycModuleHelper';

export default {
    name: 'LogicDesignView',
    components: {
        LogicOperatorSelector,
        LogicOperatorRenderer,
        PopupV1,
        VFInput,
        VFCheckbox,
        VFDropdown,
        VFRow,
        VFGroup,
        VFButton,
        KycModuleView
    },
    props: {
        index: { type: Number, default: 0 },
        reference: { type: Object, default: () => { return {} }}
    },
    data() {
        return {
            dialog: {},
            operatorSelectorParams: { show: false, index: 0 },
            indexed: false,
            inputDialog: { show: false, index: 0, options: [] },

            kycModule: { show: false, uiParams: { type: '' } },
        }
    },
    methods: {

        //#region Inputs ------------------------------------------------------------------------------------------------------

        onAddInputClicked(index) {
            this.inputDialog.index = index;
            this.inputDialog.options = [];

            for (const i in this.reference.logic[this.index].items) {
                if (index > i) {
                    this.inputDialog.options.push({
                        icon: 'logic',
                        name: 'Output from group ' + (Number(i) + 1),
                        hint: 'The output from a group will be added as an input in this group',
                        type: 'group',
                        index: i
                    });
                }
            }

            let hasKyc = false;
            for (const i in this.reference.modules) {
                if (this.reference.modules[i].type == 'kyc') hasKyc = true;
            }

            if (hasKyc) {
                this.inputDialog.options.push({
                    icon: 'eye',
                    name: 'Data from KYC module',
                    hint: 'Input data from the KYC module',
                    type: 'kyc-select'
                });
            }

            this.inputDialog.show = true;
        },

        onAddInput(option) {

            if (option.type == 'group') {

                const input = {
                    key: 'group' + option.index,
                    type: 'group',
                    isOption: false,
                    optionKey: '',
                    format: 'X',
                    name: 'Group ' + (Number(option.index) + 1) + ' output',
                    demoValue: '',
                    isList: false,
                    groupIndex: Number(option.index)
                };
                this.reference.logic[this.index].items[this.inputDialog.index].inputs.push(input);

            }else if (option.type == 'kyc-select') {

                this.kycModule.uiParams.type = 'connect';
                this.kycModule.uiParams.output = 'logic';
                this.kycModule.show = true;

            }
            this.inputDialog.show = false;
        },

        onAddKycInput(kycKey) {
            const input = {
                key: kycKey,
                type: 'kyc',
                isOption: false,
                optionKey: '',
                format: 'S',
                name: kycModuleHelper.getConnectedItemName(kycKey),
                demoValue: 'kyc data',
                isList: false,
            };
            this.reference.logic[this.index].items[this.inputDialog.index].inputs.push(input);
        },

        onChangeInputFormat(i, j) {
            const input = this.reference.logic[this.index].items[i].inputs[j];
            if (input.type == 'group') return;
            if (input.format == 'S') {
                input.format = 'B';
            }else if (input.format == 'B') {
                input.format = 'N';
            }else {
                input.format = 'S';
            }
            if (input.format == 'S') {
                input.demoValue = 'value';
            }else if (input.format == 'B') {
                input.demoValue = false;
            }else {
                input.demoValue = 0;
            }
            if (input.isList) {
                input.demoValue1 = input.demoValue;
                input.demoValue2 = input.demoValue;
            }
            this.performLogic();
        },

        onChangeBoolValue(i, j, index) {
            if (index == 0) this.reference.logic[this.index].items[i].inputs[j].demoValue = !this.reference.logic[this.index].items[i].inputs[j].demoValue;
            if (index == 1) this.reference.logic[this.index].items[i].inputs[j].demoValue1 = !this.reference.logic[this.index].items[i].inputs[j].demoValue1;
            if (index == 2) this.reference.logic[this.index].items[i].inputs[j].demoValue2 = !this.reference.logic[this.index].items[i].inputs[j].demoValue2;
            this.performLogic(this.reference.logic[this.index].items[i].inputs[j]);
        },

        onDemoInputChanged(input) {
            this.performLogic(input);
        },

        onMoveInputDown(i, j) {
            const input = this.reference.logic[this.index].items[i].inputs[j];
            if (j == this.reference.logic[this.index].items[i].inputs.length - 1) {
                if (i == this.reference.logic[this.index].items.length - 1) {
                    this.reference.logic[this.index].items[i].inputs.splice(j, 1);
                    this.reference.logic[this.index].items[0].inputs.splice(0, 0, input);
                }else {
                    this.reference.logic[this.index].items[i].inputs.splice(j, 1);
                    this.reference.logic[this.index].items[i + 1].inputs.splice(0, 0, input);
                }
            }else {
                this.reference.logic[this.index].items[i].inputs.splice(j, 1);
                this.reference.logic[this.index].items[i].inputs.splice(j + 1, 0, input);
            }
            this.performLogic();
        },

        onMoveInputUp(i, j) {
            const input = this.reference.logic[this.index].items[i].inputs[j];
            if (j == 0) {
                if (i == 0) {
                    this.reference.logic[this.index].items[i].inputs.splice(j, 1);
                    this.reference.logic[this.index].items[this.reference.logic[this.index].items.length - 1].inputs.splice(this.reference.logic[this.index].items[this.reference.logic[this.index].items.length - 1].inputs.length - 1, 0, input);
                }else {
                    this.reference.logic[this.index].items[i].inputs.splice(j, 1);
                    this.reference.logic[this.index].items[i - 1].inputs.splice(this.reference.logic[this.index].items[i - 1].inputs.length, 0, input);
                }
            }else {
                this.reference.logic[this.index].items[i].inputs.splice(j, 1);
                this.reference.logic[this.index].items[i].inputs.splice(j - 1, 0, input);
            }
            this.performLogic();
        },

        onDeleteInput(i, j) {
            this.reference.logic[this.index].items[i].inputs.splice(j, 1);
            this.performLogic();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Operators ---------------------------------------------------------------------------------------------------

        onAddOperatorClicked(index) {
            this.operatorSelectorParams.index = index;
            this.operatorSelectorParams.show = true;
        },

        onAddOperator(operator) {
            this.reference.logic[this.index].items[this.operatorSelectorParams.index].ops.push(operator);
            console.log('ds', this.reference.logic);
            this.operatorSelectorParams.show = false;
            this.performLogic();
        },

        onDeleteOperator(i, j) {
            this.reference.logic[this.index].items[i].ops.splice(j, 1);
            this.performLogic();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Groups ------------------------------------------------------------------------------------------------------

        onAddGroupClicked() {
            this.reference.logic[this.index].items.push({
                inputs: [],
                ops: [],
                demoOutput: '',
                passFalse: true
            });
            this.performLogic();
        },

        onDeleteGroup(i) {
            this.reference.logic[this.index].items.splice(i, 1);
            this.performLogic();
        },

        onChangePassFalse(i) {
            this.reference.logic[this.index].items[i].passFalse = !this.reference.logic[this.index].items[i].passFalse;
            this.performLogic();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        performLogic(input) {
            if (input) {
                let key = input.key;
                if (input.isOption) key = input.optionKey;
                for (const i in this.reference.logic[this.index].items) {
                    for (const j in this.reference.logic[this.index].items[i].inputs) {
                        let cKey = this.reference.logic[this.index].items[i].inputs[j].key;
                        if (this.reference.logic[this.index].items[i].inputs[j].isOption) cKey = this.reference.logic[this.index].items[i].inputs[j].optionKey;
                        if (cKey == key) this.reference.logic[this.index].items[i].inputs[j].demoValue = input.demoValue;
                    }
                }
            }
            logicHelper.performLogic(this.reference, this.index, true);
        },
        
    },
    created() {
        this.performLogic();
    }
}
</script>
<style scoped>

.ldv2-container {
    max-height: 800px;
    overflow: auto;
}

.ldv2-group {
    position: relative;
    float: left;
    width: 100%;
    border-radius: 5px;
    border: solid 1px var(--input-border);
    box-sizing: border-box;

    margin-bottom: 60px;
}

.ldv2-1 {
    width: 34%;
    border-right: solid 1px var(--input-border);
}
.ldv2-2 {
    width: 33%;
    border-right: solid 1px var(--input-border);
}
.ldv2-3 {
    width: 33%;
}

.ldv2-x1 {
    width: 34%;
}
.ldv2-x2 {
    width: 66%;
}

.ldv2-head {
    float: left;
    width: 100%;
    padding: 6px 0px;
    border-bottom: solid 1px var(--input-border);
    box-sizing: border-box;
}

.ldv2-head-itm {
    float: left;
    display: flex;
    padding-left: 15px;
    color: var(--text-disabled);
    box-sizing: border-box;
}
.ldv2-head-itm span {
    flex-grow: 100;
}

.ldv2-head-add {
    position: relative;
    float: right;
    width: 30px;
    border-left: solid 1px var(--input-border);
    cursor: pointer;
    box-sizing: border-box;
}
.ldv2-head-add:hover {
    background-color: var(--item-hover);
}

.ldv2-line {
    float: left;
    width: 100%;
    display: table;
    padding: 6px 0px;
    box-sizing: border-box;
}

.ldv2-line-itm {
    display: table-cell;
    padding: 0px 15px;
    vertical-align: middle;
    box-sizing: border-box;
}

.ldv2-line-empty {
    padding: 10px 10px;
    font-size: 13px;
    color: var(--text-disabled);
}


.ldv2-group-output {
    position: absolute;
    bottom: -29px; height: 28px;
    left: calc(50% - 120px); width: 240px;
    border: solid 1px var(--input-border);
    border-top: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
}

.ldv2-group-output-delete {
    padding: 4px 3px;
    border-right: solid 1px var(--input-border);
    cursor: pointer;
}
.ldv2-group-output-delete:hover {
    background-color: var(--item-hover);
}

.ldv2-group-output-value {
    flex-grow: 100;
    padding-top: 6px;
    padding-left: 5px;
}
.ldv2-group-output-d {
    color: var(--text-disabled);
}
.ldv2-group-output-v {
    color: var(--text-title);
    font-weight: bold;
}

.ldv2-group-output-pf {
    padding: 4px 3px;
    border-left: solid 1px var(--input-border);
    cursor: pointer;
}
.ldv2-group-output-pf:hover {
    background-color: var(--item-hover);
}


.ldv2-input {
    width: 100%;
    height: 44px;
    margin: 8px 0px;
    border-radius: 4px;
    border: solid 1px var(--text-disabled);
    box-shadow: 0.5px 0.5px 1px 0px rgba(0,0,0,0.1);
}

.ldv2-input-top {
    display: flex;
    border-bottom: solid 1px var(--text-disabled);
}

.ldv2-input-listdots {
    position: relative;
    flex-shrink: 0;
    width: 28px;
}

.ldv2-input-name {
    flex-grow: 100;
    padding: 2px 5px;
    padding-top: 3px;
    font-size: 14px;
    color: var(--text-title);
}
.ldv2-input-delete {
    width: 24px;
    flex-shrink: 0;
    border-left: solid 1px var(--text-disabled);
}


.ldv2-input-bot {
    display: flex;
}

.ldv2-input-format {
    width: 26px;
    flex-shrink: 0;
    padding: 2px 7px;
    padding-top: 3px;
    font-size: 14px;
    font-weight: bold;
    border-right: solid 1px var(--text-disabled);
    box-sizing: border-box;
}
.ldv2-format-s {
    color: #b13707;
}
.ldv2-format-b {
    color: #6d07b1;
}
.ldv2-format-n {
    color: #007910;
}

.ldv2-input-editor {
    flex-grow: 100;
    padding-right: 1px;
    box-sizing: border-box;
}
.ldv2-input-editor input {
    border: none;
    width: 100%;
    padding: 2px 5px;
    padding-top: 3px;
    font-size: 14px;
    font-weight: bold;
    box-sizing: border-box;
}
.ldv2-input-editor .ldv2-format-s:focus {
    outline: solid 1px #b13707;
}
.ldv2-input-editor .ldv2-format-n:focus {
    outline: solid 1px #007910;
}

.ldv2-input-editor-bool {
    width: 100%;
    padding: 2px 5px;
    padding-top: 3px;
    font-size: 14px;
    font-weight: bold;
    box-sizing: border-box;
}

.ldv2-input-editor-x {
    flex-shrink: 0;
    width: 62px;
    padding-right: 1px;
    border-left: solid 1px var(--text-disabled);
    box-sizing: border-box;
}
.ldv2-input-editor-x input {
    border: none;
    width: 100%;
    padding: 2px 5px;
    padding-top: 3px;
    font-size: 14px;
    font-weight: bold;
    box-sizing: border-box;
}
.ldv2-input-editor-x .ldv2-format-s:focus {
    outline: solid 1px #b13707;
}
.ldv2-input-editor-x .ldv2-format-n:focus {
    outline: solid 1px #007910;
}

.ldv2-input-move-d {
    width: 24px;
    flex-shrink: 0;
    border-left: solid 1px var(--text-disabled);
}
.ldv2-input-move-u {
    width: 24px;
    flex-shrink: 0;
    border-left: solid 1px var(--text-disabled);
}

.ldv2-input-button {
    cursor: pointer;
    box-sizing: border-box;
}
.ldv2-input-button:hover {
    background-color: var(--item-hover);
}



.ldv2-settings {
    flex-grow: 100;
    height: 40px;
    display: flex;
    text-align: left;
}

.ldv2-main {
    display: flex;
    height: 40px;
    border-radius: 4px;
    border: solid 1px var(--text-disabled);
}
.ldv2-main-desc {
    margin-left: 10px;
    margin-top: 10px;
    color: var(--text-weak);
}
.ldv2-main-value {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    font-size: 22px;
    font-weight: bold;
}

</style>