<template>
    <div>
        <BatchView :items="items" :steps="steps" :skip="skip" :title="'Persons'" />
    </div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import BatchView from '../components/special/BatchView';

export default {
    components: {
        BatchView
    },
    name: 'ScriptPersonFlags',
    data() {
        return {
            items: [],
            steps: 2,
            STEP_LOAD_CLIENT: 0,
            STEP_UPDATE_STATUS: 1,
            skip: 0
        }
    },
    methods: {

        //#region BatchView Interface -----------------------------------------------------------------------------------------

        onGetName(item) {
            return item.name;
        },

        onGetInfo1(item) {
            return '';
        },

        onGetInfo2(item) {
            return '';
        },

        async onRemoveItem(item) {},

        async onStart() {
            return true;
        },

        onGetStatus(step) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return 'Laster kundedata';
                case this.STEP_UPDATE_STATUS: return 'Oppdaterer status';
            }
        },

        async onExecuteStep(step, item, data) {
            switch (step) {
                case this.STEP_LOAD_CLIENT: return await this.loadClient(item, data);
                case this.STEP_UPDATE_STATUS: return await this.updateStatus(item, data);
            }
            return { ok: false, errorMessage: 'invalid step', data: {} };
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Steps -------------------------------------------------------------------------------------------------------
        
        async loadClient(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            await new Promise(r => setTimeout(r, 500));

            console.log('item', item);

            result.ok = true;
            return result;
        },

        async updateStatus(item, data) {
            const result = { ok: false, errorMessage: 'unknown error', data: {} };

            await v1api.updateFlags(item._id);

            result.ok = true;
            return result;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        async loadPersons() {
            this.loading = true;

            const filters = [];
            const from = 1000;
            const limit = 500;
            const sort = { name: 1 };
            const include = [];
            const counts = ['letter'];

            const response = await v1api.queryPersons(filters, from, limit, sort, include, counts);

            this.items = [];
            for (const i in response.persons) {
                this.items.push(response.persons[i]);
            }

            this.loading = false;
        },

    },
    async created() {
        await this.loadPersons();
    }
}
</script>