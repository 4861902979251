<template>
<div class="vf-ta" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" @mouseup="handleOptionMouseUp($event)" :class="[
    {'vf-ta-disabled': disabled},
    {'vf-ta-dotted': dotted},
    {'vf-ta-designer': designer},
    {'vf-ta-invalid': validation.done && !validation.valid },
]">

    <textarea :style="'height: ' + getHeight() + 'px;'"
        :value="value" 
        :lines="lines" 
        :placeholder="placeholder"
        :disabled="disabled"
        @input="handleInput" 
    />

</div>
</template>

<script>

export default {
    name: 'vf-textarea',
    props: {
        value: [String, Number],
        placeholder: { type: String, default: '' },
        lines: { type: Number, default: 3 },

        validation: { type: Object, default: () => { return { done: false } }},
        disabled: { type: Boolean, default: false },
        dotted: { type: Boolean, default: false },

        required: { type: Boolean, default: false },
        noValidation: { type: Boolean, default: false },
        noHighlight: { type: Boolean, default: false },
        
        id: { type: String, default: 'id' },
        designer: { type: Boolean, default: false },
        activeLogic: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {

        getHeight() {
            return Number(this.lines) * 26;
        },

        handleInput(e) {
            this.$emit('input', e.srcElement.value);
        },

        //#region Designer / Logic --------------------------------------------------------------------------------------------

        handleMouseEnter(e) {
            this.$emit('connect-enter', this.id);
        },

        handleMouseLeave(e) {
            this.$emit('connect-leave', this.id);
        },

        handleOptionMouseUp(e) {
            if (this.activeLogic) {
                this.$emit('connect', this.id);
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    }
}
</script>

<style scoped>

textarea {
    resize: vertical;
    border: none;
    background: none;
    outline: none;
    width: 100%;
    padding: 5px 10px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    color: var(--text-strong);
    border-radius: 3px;
    border: solid 1px lightgray;
    box-sizing: border-box;
}

.vf-ta {
    position: relative;
    float: left;
    width: 100%;
}

.vf-ta textarea {
    width: 100%;
    padding: 6px 12px;
    padding-top: 10px;
    font-size: 15px;
    box-sizing: border-box;
    background-color: var(--card-background) !important;
    border: none;
    background: none;
    outline: none;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: var(--input-text);
    border-radius: 4px;
    border: solid 1px var(--input-border);
    resize: vertical;
    min-height: 40px;
    max-height: 400px;
}

.vf-ta input:focus {
    border-color: var(--input-focus);
}

.vf-ta-disabled textarea {
    background-color: lightgray !important;
}



/* --- Validation -------------------- */

.vf-ta-invalid textarea {
    border-color: var(--val-error) !important;
}

/* ----------------------------------- */



/* --- Design Mode ------------------- */

.vf-ta-designer textarea {
    cursor: default;
}

.vf-ta-dotted textarea {
    border: dotted 2px var(--input-border);
}

.logic .vf-ta:hover textarea {
    border: solid 3px #0DCC27;
}

/* ----------------------------------- */

</style>
