var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vap-inputc",class:{'vap-cb-boxonly': _vm.boxOnly}},[_c('div',{staticClass:"vap-cb-base",class:[
        {'vap-cb-nodesc': _vm.noDesc},
        {'vap-cb-bordered': _vm.bordered},
        {'vap-cb-bordero': _vm.borderOffset},
        {'vap-cb-card': _vm.fillCard},
        {'vap-cb-disabled': _vm.disabled},
        {'vf-cb-invalid': _vm.validation.done && !_vm.validation.valid },
        {'vap-cb-new': _vm.newDesign } ],on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[(!_vm.options)?_c('div',{staticClass:"vap-cb",class:{'vap-cb-news' : _vm.value && _vm.newDesign }},[_c('div',{staticClass:"vap-cb-check",on:{"click":_vm.handleClick}},[(_vm.value)?_c('div',{staticClass:"checkmark"},[_c('div',{staticClass:"checkmark_stem"}),_c('div',{staticClass:"checkmark_kick"})]):_vm._e()]),_c('div',{staticClass:"vap-cb-text",on:{"click":_vm.handleClick}},[_c('label',[_vm._v(_vm._s(_vm.text))])])]):_vm._e(),_vm._l((_vm.options),function(option,key,i){return _c('div',{key:key,staticClass:"vap-cb",class:[
                {'vap-cb-inline' : _vm.inline},
                {'vap-cb-radio' : _vm.radio},
                {'vap-cb-border' : i != 0},
                {'vap-cb-selected' : _vm.value[key]},
                {'vap-cb-news': _vm.value[key] && _vm.newDesign } ],on:{"mouseup":function($event){return _vm.handleOptionMouseUp(key, $event)}}},[_c('div',{staticClass:"vap-cb-check",on:{"click":function($event){return _vm.handleClick(key)}}},[(_vm.value[key] || (_vm.designer && option.default))?_c('div',{staticClass:"checkmark"},[_c('div',{staticClass:"checkmark_stem"}),_c('div',{staticClass:"checkmark_kick"})]):_vm._e()]),_c('div',{staticClass:"vap-cb-text",on:{"click":function($event){return _vm.handleClick(key)}}},[_c('label',[_vm._v(_vm._s(_vm.getOption(option)))])])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }