const zzPerson = {

    getFlow() {
        return {
            key: 'zz-person',
            name: 'Default Person Flow',
            type: 'person',
            countries: 'xx',
            isPerson: true,
            hideAddManual: true,
            tabs: {
                overview: { key: 'common_overview', icon: 'checklist' },
                persons: { key: 'common_persons', icon: 'people' },
                report: { key: 'company_final_report', icon: 'verified' }
            },
            title: {
                en_EN: 'Private Individual',
                nb_NO: 'Privatperson',
                sv_SE: 'Privatperson',
            },
            steps: [
                { key: 'zz-person-idc' },
                { key: 'zz-person-scr' },
                { key: 'zz-person-rep' }
            ],
            statuses: [
                {
                    key: 'b-id-a',
                    text: {
                        en_EN: 'Awaiting ID control',
                        nb_NO: 'Avventer ID kontroll',
                        sv_SE: 'Väntar på ID-kontroll',
                    },
                    triggers: []
                },
                {
                    key: 'b-rep-a',
                    text: {
                        en_EN: 'Awaiting final report',
                        nb_NO: 'Avventer sluttrapport',
                        sv_SE: 'Väntar på slurapport'
                    },
                    triggers: [
                        { type: 'person', trg: 'scr-all', rev: false },
                        { type: 'person', trg: 'idc-all', rev: false },
                    ]
                },
                {
                    key: 'x-com-a',
                    completed: true,
                    text: {
                        en_EN: 'Completed',
                        nb_NO: 'Fullført',
                        sv_SE: 'Färdiga'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ]
                },
                {
                    key: 'z-ch-c',
                    completed: false,
                    text: {
                        en_EN: '! - Changed after completion',
                        nb_NO: '! - Endret etter ferdigstillelse',
                        sv_SE: '! - Ändrad efter färdigställande'
                    },
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                        { type: 'report', trg: 'changed', rev: false },
                    ]
                }
            ],
            events: {
                items: [
                    {
                        key: 'event01',
                        text: {
                            en_EN: 'Customer created by {{createdUser}}',
                            nb_NO: 'Kunde opprettet av {{createdUser}}',
                            sv_SE: 'Kund skapad av {{createdUser}}'
                        },
                        date: { type: 'org', date: 'searchDate' },
                        triggers: [
                            { type: 'client', trg: 'created', rev: false },
                        ]
                    }
                ]
            },
            report: [
                {
                    key: 'roles',
                    icon: 'person',
                    comment: true,
                    separation: false,
                    title: {
                        en_EN: 'Individual',
                        nb_NO: 'Privatperson',
                        sv_SE: 'Privatperson',
                    },
                    requirements: [
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Individual not assessed',
                                nb_NO: 'Privatperson ikke vurdert',
                                sv_SE: 'Privatperson ej bedömd',
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                },
                {
                    key: 'assessment',
                    icon: 'check',
                    comment: true,
                    separation: true,
                    title: {
                        en_EN: 'Final assessment / risk assessment',
                        nb_NO: 'Totalvurdering',
                        sv_SE: 'Helhetsbetyg'
                    },
                    requirements: [
                        {
                            key: 'report-risk',
                            message: {
                                en_EN: 'Risk classification not set',
                                nb_NO: 'Risikoklassifisering ikke satt',
                                sv_SE: 'Riskklassificering ej fastställd'
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'risk-set', rev: false },
                            ],
                        },
                        {
                            key: 'assess',
                            message: {
                                en_EN: 'Final assessment not completed',
                                nb_NO: 'Totalvurdering ikke fullført',
                                sv_SE: 'Helhetsbedömning ej genomförd',
                            },
                            triggers: [
                                { type: 'rep-itm', trg: 'has-comment', rev: false },
                            ],
                        }
                    ]
                }
            ]
        };
    },

    //#region Steps -------------------------------------------------------------------------------------------------------
    
    

    getIdcStep() {
        return {
            key: 'zz-person-idc',
            resetMode: 'idc-individual',
            name: 'ID Control',
            actions: [
                {
                    icon: 'card',
                    main: true,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        { type: 'person', trg: 'idc-all', rev: true },
                    ],
                    text: {
                        en_EN: 'Show person',
                        nb_NO: 'Vis person',
                        sv_SE: 'Visa person'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'chev-r',
                    main: false,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        { type: 'person', trg: 'idc-all', rev: false },
                    ],
                    text: {
                        en_EN: ''
                    },
                    hint: {
                        en_EN: 'Show person',
                        nb_NO: 'Vis person',
                        sv_SE: 'Visa person'
                    }
                }
            ],
            title: {
                en_EN: 'ID-control',
                nb_NO: 'ID-kontroll',
                sv_SE: 'ID-kontroll',
            },
            hint: {
                en_EN: 'Send an email with an invitation to the ID-control. The recipient log in with BankID and is authenticated.',
                nb_NO: 'Send en epost med invitasjon til ID-kontroll. Mottaker følger linken i eposten og autentiserer seg med BankID.',
                sv_SE: 'Skicka ett mejl med en inbjudan till ID-kontrollen. Mottagaren loggar in med BankID och autentiseras.',
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'ID-control completed',
                                nb_NO: 'ID-kontroll fullført',
                                sv_SE: 'ID-kontroll genomförd',
                            },
                            triggers: [
                                { type: 'person', trg: 'idc-all', rev: false },
                            ]
                        }
                    ]
                },
            }
        };
    },

    getScreeningStep() {
        return {
            key: 'zz-person-scr',
            resetMode: 'scr-individual',
            name: 'Screening',
            actions: [
                {
                    icon: 'search',
                    main: true,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        { type: 'person', trg: 'scr-all', rev: true },
                    ],
                    text: {
                        en_EN: 'Show person',
                        nb_NO: 'Vis person',
                        sv_SE: 'Visa person'
                    },
                    hint: {
                        en_EN: ''
                    }
                },
                {
                    icon: 'chev-r',
                    main: false,
                    action: 'tab',
                    key: 'persons',
                    triggers: [
                        { type: 'person', trg: 'scr-all', rev: false },
                    ],
                    text: {
                        en_EN: ''
                    },
                    hint: {
                        en_EN: 'Show person',
                        nb_NO: 'Vis person',
                        sv_SE: 'Visa person'
                    }
                }
            ],
            title: {
                en_EN: 'Screening',
                nb_NO: 'PEP/Sanksjonssøk',
                sv_SE: 'PEP/Sanktionsansökan',
            },
            hint: {
                en_EN: 'Search against international sanction-lists and nordic PEP-lists.',
                nb_NO: 'Søker mot internasjonale sankjsonslister og nordiske PEP-lister.',
                sv_SE: 'Sök mot internationella sanktionslistor och nordiska PEP-listor.',
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Screening completed',
                                nb_NO: 'Søk utført',
                                sv_SE: 'Sökning utförd',
                            },
                            triggers: [
                                { type: 'person', trg: 'scr-all', rev: false },
                            ]
                        }
                    ]
                },
            }
        };
    },

    getReportStep() {
        return {
            key: 'zz-person-rep',
            resetMode: 'report',
            name: 'Default BankID AML Report',
            actions: [
                {
                    icon: 'pdf',
                    main: false,
                    action: 'preview',
                    key: 'report',
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ],
                    text: {
                        en_EN: 'Show receipt'
                    },
                    hint: {
                        en_EN: 'Show the final report'
                    }
                },
/*                 {
                    icon: 'delete',
                    main: false,
                    action: 'report-reset',
                    triggers: [
                        { type: 'report', trg: 'created', rev: false },
                    ],
                    text: {
                        en_EN: 'Reset report',
                    },
                    hint: {
                        en_EN: 'Reset report',
                    }
                }, */
                {
                    icon: 'open',
                    main: true,
                    action: 'tab',
                    key: 'report',
                    triggers: [
                        { type: 'report', trg: 'created', rev: true },
                    ],
                    text: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport',
                        sv_SE: 'Visa rapport',
                    },
                    hint: {
                        en_EN: 'Show report',
                        nb_NO: 'Vis rapport',
                        sv_SE: 'Visa rapport',
                    }
                },
            ],
            title: {
                en_EN: 'Final report / Risk assessment',
                nb_NO: 'Sluttrapport',
                sv_SE: 'Slutrapport',
            },
            hint: {
                en_EN: 'Look through the collected information and set a risk assessment, then generate a final report for this customer.',
                nb_NO: 'Gå gjennom innsamlet informasjon og sett en risikovurdering, så genereres det en sluttrapport for kunden.',
                sv_SE: 'Gå igenom den insamlade informationen och sätt en riskbedömning och skapa sedan en slutrapport för denna kund.',
            },
            status: {
                completed: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Final report created',
                                nb_NO: 'Sluttrapport opprettet',
                                sv_SE: 'Slutrapport skapad'
                            },
                            triggers: [
                                { type: 'report', trg: 'created', rev: false },
                                { type: 'report', trg: 'changed', rev: true },
                            ]
                        }
                    ]
                },
                error: {
                    statuses: [
                        {
                            text: {
                                en_EN: 'Underlying data has been updated since the report was created',
                                nb_NO: 'Underliggende data har blitt oppdatert siden rapporten ble opprettet',
                                sv_SE: 'Underliggande data har uppdaterats sedan rapporten skapades'
                            },
                            triggers: [
                                { type: 'report', trg: 'created', rev: false },
                                { type: 'report', trg: 'changed', rev: false },
                            ]
                        }
                    ]
                }
            }
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default zzPerson;