import clientHelper from '../helpers/clienthelper';
import kychelper from '../helpers/kychelper';
import v1api from './v1api';
import dataNorwayV1 from '../v1data/dataNorwayV1';
import dataModelV1 from '../v1data/dataModelV1';
import tools from '../tools';
import api from '../portalapi';
import v1flows from '../v1helpers/v1flows';
import personhelper from '../helpers/personhelper';
const v1migration = {

    //#region Load client -------------------------------------------------------------------------------------------------

    async loadV0Client(root, reference) {
        const result = {
            client: null,
            kycEnvelope: null
        };

        result.client = await clientHelper.loadClient(root, reference.customer, reference.flow);

        if (result.client.customer.type != 'person' && result.client.kycMetadata.envelopeId) {
            try {
                result.kycEnvelope = await root.getEnvelope(result.client.kycMetadata.envelopeId);
            }catch (ex) {

            }
        }
        

        return result;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Migrate -----------------------------------------------------------------------------------------------------

    async migrateClient(root, v0data) {
        console.log('v0data', v0data);
        const result = {
            client: null,
            persons: [],
            metadata: null,
            hasActiveControls: false,
        };

        const client = {
            _id: v0data.client.customer._id,
            name: v0data.client.customer.name,
            identity: v0data.client.customer.identity,
            letter: v0data.client.customer.letter,
            country: v0data.client.customer.country,
            type: v0data.client.customer.type,
            source: 'bankidaml',
            flow: 'no-org-baml',
            status: 'migrated',
            risk: v0data.client.customer.risk,
            envelope: {
                id: '',
                documentId: '',
                attachments: []
            },
            company: { lookup: false },
            personId: '',
            person: {},
            kyc: { done: false },
            web: { done: false },
            flags: {
                hasPep: false,
                hasSanction: false,
                hits: 0
            },
            roles: [],
            archive: [],
            report: {
                created: false,
                dateCreated: '',
                envelopeId: '',
                userId: '',
                risk: 0,
                items: []
            },
            userId: v0data.client.customer.userId,
            dateCreated: new Date(v0data.client.customer.dateCreated),
            dateUpdated: new Date(v0data.client.customer.dateUpdated),
            migrated: true
        };

        if (client.type == 'person') {
            client.source = 'manual';
            client.flow = 'zz-person';
        }

        if (v0data.client.customer.report) {
            client.report.created = true;
            client.report.dateCreated = new Date(v0data.client.customer.report.reportDate);
            client.report.envelopeId = v0data.client.customer.report.envelopeId;
            client.report.userId = v0data.client.customer.report.userId;
        }

/*         const availableFlows = v1flows.getAvailableFlows(root, client.type, client.country, client.source);
        console.log('availableFlows', availableFlows);
        client.flow = availableFlows[0].key; */
        client.flow = 'deloitte-org-baml';
        let formKey = 'kyc00';

        if (v0data.client.customer.flow == 'org-no-unreg') {
            client.flow = 'deloitte-org-creation';
            formKey = 'kyc02';
        }
        if (v0data.client.customer.flow == 'org-manual') {
            client.flow = 'deloitte-org-forreign';
            formKey = 'kyc02';
        }

        // Company data
        if (client.type != 'person' && v0data.client.customer.aml) {
            const companyData = dataModelV1.getEmptyCompany();
            dataNorwayV1.processData(root, companyData, v0data.client.amlData, v0data.client.brregData);
            client.company = companyData;

            const amlEnvelope = await root.getEnvelope(v0data.client.customer.aml.envelopeId);
            console.log('amlEnvelope', amlEnvelope);

            client.envelope.id = amlEnvelope.id;
            client.envelope.documentId = amlEnvelope.documentId;
            client.envelope.name = amlEnvelope.content.documents[0].name;
            for (const i in amlEnvelope.content.documents[0].files.attachments) {
                const atc = amlEnvelope.content.documents[0].files.attachments[i]
                //console.log('atc', atc);
                if (atc.name == 'BankID AML Firmarapport.pdf') {
                    client.envelope.attachments.push({
                        type: 'baml-org-full',
                        name: atc.name,
                        fileType: 'application/pdf',
                        size: 0
                    });
                }else if (atc.name == 'BankID AML Firmaattest.pdf') {
                    client.envelope.attachments.push({
                        type: 'baml-org-inc',
                        name: atc.name,
                        fileType: 'application/pdf',
                        size: 0
                    });
                }
            }
        }


        // KYC
        if (client.type != 'person' && v0data.kycEnvelope) {
            client[formKey] = this.getKycData(v0data.client, v0data.kycEnvelope);
            if (client[formKey].error == true) {
                result.hasActiveControls = true;
            }
            client.kyc = this.getKycResult(v0data.kycEnvelope, formKey);
        }

        // AMS
        for (const rKey in v0data.client.reportData) {
            const rdata = v0data.client.reportData[rKey];
            //console.log('RDATA', rdata);
            if (rdata.type == 'aml') {
                if (rdata.searchComplete || rdata.ok) {
                    const web = {};
                    web.done = true;
                    web.completed = true;
                    web.keywords = [];
                    web.comment = rdata.comment || '';
                    web.date = rdata.searchDate;
                    web.userId = rdata.userId;
                    for (const j in rdata.keywords) {
                        web.keywords.push({
                            key: j.toString(),
                            locale: 'en_EN',
                            done: true,
                            result: rdata.keywords[j] ? 'hits' : 'ok'
                        });
                    }
                    client.web = web;
                }
            }
        }


        // Risk
        if (v0data.client.customer.risk == 1) client.risk = 2;
        if (v0data.client.customer.risk == 3) client.risk = 4;
        if (v0data.client.customer.risk == 5) client.risk = 7;
        client.report.risk = client.risk;


        // Persons
        const pairs = [];
        for (const i in v0data.client.persons) {
            const person = this.getEmptyPerson(v0data.client.persons[i], i, client);

            const oldPerson = v0data.client.persons[i];
            console.log('old person', oldPerson);

            if (oldPerson.id) {
                console.log('id', oldPerson.id);
                pairs.push({
                    oldId: oldPerson.id,
                    newId: person._id
                });
            }

            person.idc = await this.getPersonID(root, oldPerson, person);
            person.scr = await this.getPersonScr(root, oldPerson);
            person.web = this.getPersonWeb(root, oldPerson, v0data.client.reportData);

            if (oldPerson.control.inviteSent && !oldPerson.control.completed) {
                result.hasActiveControls = true;
            }
            
            const personClient = {
                id: client._id,
                type: 'org',
                name: client.name,
                identity: client.identity,
                roles: [],
            };
            for (const j in oldPerson.roles) {
                if (!personClient.roles.includes(this.getNewRoleId(oldPerson.roles[j].id))) personClient.roles.push(this.getNewRoleId(oldPerson.roles[j].id));
            }
            if (client.type == 'person') {
                personClient.roles.push('role_individual');
                person.manualRoles.push({
                    clientId: client._id,
                    roleId: 'role_individual',
                    share: '',
                    userId: client.userId,
                });
                client.personId = person._id;
                const decoded = tools.decodePerson(v0data.client.customer.identity);
                client.person = {
                    firstName: decoded.firstName,
                    lastName: decoded.lastName,
                    dateOfBirth: decoded.dateOfBirth,
                    email: '',
                    phone: { prefix: '+47', number: '' },
                };
            }
            let isCustomerContact = false;
            for (const x in oldPerson.roles) {
                if (oldPerson.roles[x].id == 'role_customer_contact') isCustomerContact = true;
            }
            if (isCustomerContact) {
                person.manualRoles.push({
                    clientId: client._id,
                    roleId: 'role_customer_contact',
                    share: '',
                    userId: client.userId,
                });
            }

            person.clients.push(personClient);
            person.clientIds.push(client._id);
            if (oldPerson.selected) client.roles.push(person._id);

            //console.log('person', person);
            result.persons.push(person);
            /* if (i == 0) break; */
        }

        client.report.items = this.getReportItems(v0data.client.reportData, pairs);


        if (v0data.client.customer.partner) client.partner = v0data.client.customer.partner;
        if (v0data.client.customer.department) client.branch = v0data.client.customer.department;

        if (v0data.client.customer.metadata && v0data.client.customer.metadata.imanage) {
            client.report.store = [{
                type: 'imanage',
                date: v0data.client.customer.metadata.imanage.saveDate,
                userId: v0data.client.customer.metadata.imanage.userId
            }];
        }

        
        // Book
        const doCheckBook = true;
        if (doCheckBook) {
            const result = await this.checkBook(root, v0data);
            client.kyc.relevant = result.relevant;
            client.kyc.changed = result.changed;
            client.kyc.comparison = result.comparison;
            client.kyc.book = result.book;
            client.book = result.clientBook;
            if (result.files) client.files = result.files;
            client.report.items.push({
                key: 'external-risk',
                comment: '',
                check: true
            });
        }

        result.client = client;
        this.mergePersons(result);
        this.checkSignatures(v0data.kycEnvelope, result.persons);

        if (v0data.client.customer.shareApps) {
            await this.addShareApps(root, client, v0data.client.customer.shareApps);
        }

        return result;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Add to database ---------------------------------------------------------------------------------------------

    async addToDatabase(v0data, v1data) {
        console.log('v1data', v1data);

        //return;

        const migrateResult = await v1api.migrateClient(v1data.client, v1data.persons, v1data.metadata);

        const data = {
            migrated: true
        };
        await api.updateCustomer(v0data.client.customer._id, data);

        await v1api.getClientStatusTEST(migrateResult.newId);

        return migrateResult.newId;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    async checkBook(root, v0data) {
        const result = {};
        result.relevant = {
            role_ceo: true,
            role_chair: true,
            role_board_member: false,
            role_shareholder: true,
            role_rrh: true,
            role_deputy_bm: false,
            role_other: false,
        };
        result.changed = false;
        result.comparison = [];
        result.book = { provided: false, names: [] };

        result.clientBook = {};

        if (v0data.client.kycMetadata.changes) {

            for (const i in v0data.client.kycMetadata.changes.items) {
                result.changed = true;

                const change = v0data.client.kycMetadata.changes.items[i];

                console.log('change', change);

                const newChange = {
                    type: '',
                    roleId: '',
                    name: change.data.displayName,
                };

                if (change.change == 1) {
                    newChange.type = 'del';
                }else if (change.change == 2) {
                    newChange.type = 'add';
                }else if (change.change == 3) {
                    newChange.type = 'shr';
                    newChange.oShare = change.data.percentageFrom;
                    newChange.nShare = change.data.percentageTo;
                }

                if (change.type == 'rrh') {
                    newChange.roleId = 'role_rrh';
                }else if (change.type == 'sh') {
                    newChange.roleId = 'role_shareholder';
                }

                result.comparison.push(newChange);

            }

        }


        if (v0data.client.customer.metadata && v0data.client.customer.metadata.shBookData) {
            const bookData = v0data.client.customer.metadata.shBookData;

            result.clientBook.uploaded = false;
            result.clientBook.confirmed = bookData.confirmed;
            result.clientBook.comment = bookData.comment;
            result.clientBook.names = [];
            result.clientBook.userId = bookData.userId;
            result.clientBook.envelopeId = bookData.envelopeId;
            if (result.clientBook.envelopeId) {
                result.clientBook.uploaded = true;

                result.files = [];
                const file = {
                    type: 'form-manual',
                    envelopeId: bookData.envelopeId,
                    formKey: '',
                    name: '',
                    userId: bookData.userId,
                    date: v0data.client.customer.report.reportDate,
                };

                const atcEnvelope = await root.getEnvelope(bookData.envelopeId);
                console.log('atcEnvelope', atcEnvelope);

                // TODO load envelope and get attachment name ??

                file.name = atcEnvelope.content.documents[0].files.attachments[0].name;
                
                result.files.push(file);
            }

        }


        if (v0data.client.kycMetadata.shareholderBookProvided) {
            result.book.provided = true;
            for (const i in v0data.client.kycMetadata.shareholderBooks) {
                result.book.names.push(v0data.client.kycMetadata.shareholderBooks[i].name);
            }
        } 

        
        console.log('RESULT', result);
        return result;
    },

    async addShareApps(root, client, shareApps) {
        console.log('adding shareapps', shareApps);

        for (const i in shareApps) {

            const envelope = await root.getEnvelope(shareApps[i].envelopeId);
            console.log('envelope', envelope);

            const kycData = {
                userId: shareApps[i].userId,
                name: envelope.content.documents[0].name,
                dateCreated: new Date(shareApps[i].sentDate),
                envelopeId: shareApps[i].envelopeId,
                filled: shareApps[i].filled,
                signed: false,
                dateFilled: new Date(shareApps[i].fillDate),
                dateSigned: '',
                editors: [],
                signers: [],
                fields: {},
                next: null,
                hasCheckout: false,
                checkout: {}
            };

            for (const x in envelope.content.recipients) {
                const recipient = envelope.content.recipients[x];
                //console.log('rec', recipient);
    
                if (recipient.role.action == 'edit') {
    
                    kycData.editors.push({
                        name: recipient.givenName + ' ' + recipient.familyName,
                        completed: recipient.completed
                    });
    
                }else if (recipient.role.action == 'sign') {
    
                    const signer = {
                        name: recipient.givenName + ' ' + recipient.familyName,
                        completed: recipient.completed,
                    };

                    if (recipient.signatures && recipient.signatures.length > 0) {
                        signer.signature = {
                            method: recipient.signatures[0].origin,
                            firstName: recipient.signatures[0].givenName,
                            lastName: recipient.signatures[0].familyName,
                            name: recipient.signatures[0].givenName + ' ' + recipient.signatures[0].familyName,
                            dateOfBirth: recipient.signatures[0].birthDate ? recipient.signatures[0].birthDate.split('T')[0] : '',
                            ssn: recipient.signatures[0].personalNumber || '',
                            email: recipient.signatures[0].email
                        };
                        kycData.signed = true;
                        kycData.dateSigned = new Date(recipient.signatures[0].date);
                    }
    
                    kycData.signers.push(signer);
    
                }
    
            }

            console.log('kycData', kycData);

            client.shareapp = JSON.parse(JSON.stringify(kycData));
            client.shareapp.archive = [kycData];

        }

    },

    checkSignatures(envelope, persons) {
        console.log('envelope', envelope);
        if (!envelope) return;

        const formEnvelope = envelope.content;

        if (formEnvelope.documents && formEnvelope.documents.length > 0 && formEnvelope.documents[0].signatures && formEnvelope.documents[0].signatures.length > 0) {
            for (const i in formEnvelope.documents[0].signatures) {
                const signature = formEnvelope.documents[0].signatures[i];
                if (signature.origin == 'bankid-no') {
                    const sPerson = {
                        name: signature.givenName.toLowerCase() + ' ' + signature.familyName.toLowerCase(),
                        info: {
                            firstName: signature.givenName.toLowerCase(),
                            lastName: signature.familyName.toLowerCase(),
                        },
                        dateOfBirth: signature.birthDate ? signature.birthDate.split('T')[0] : ''
                    };
                    let matchPerson = null;
                    for (const j in persons) {
                        //console.log('comparing', sPerson);
                        //console.log('comparing', persons[j]);
                        if (this.comparePersons(sPerson, persons[j])) {
                            matchPerson = persons[j];
                        }
                    }
                    if (matchPerson) {

                        //console.log('match person', matchPerson);

                        if (matchPerson.idc.done) {
                            // Archive the old ID-control
                            delete matchPerson.idc.done;
                            delete matchPerson.idc.wasUpdated;
                            matchPerson.idc.type = 'idc';
                            matchPerson.archive.push(matchPerson.idc);
                        }

                        if (!matchPerson.idc.done || matchPerson.idc.source == 'signature' || matchPerson.idc.method == 'manual') {
                            matchPerson.idc = {
                                done: true,
                                date: new Date(signature.date),
                                method: signature.origin,
                                source: 'signature',
                                name: signature.givenName + ' ' + signature.familyName,
                                firstName: signature.givenName,
                                lastName: signature.familyName,
                                dateOfBirth: sPerson.dateOfBirth,
                                ssn: signature.personalNumber,
                                envelopeId: formEnvelope.id,
                                originator: signature.originator,
                                indicator: this.getIdentityMatchIndicator(matchPerson.name, matchPerson.dateOfBirth, sPerson.name, sPerson.dateOfBirth),
                                wasUpdated: false,
                                metadata: {
                                    id: signature.id,
                                    bankidAltsub: signature.bankidAltsub
                                }
                            };
                        }
                    }
                }
            }
        }

    },

    getIdentityMatchIndicator(originalName, originalDob, confirmedName, confirmedDob) {

/*         console.log('original ' + originalName);
        console.log('confirmed ' + confirmedName);
        console.log('original ' + originalDob);
        console.log('confirmed ' + confirmedDob); */

        try {
            const names1 = originalName.toLowerCase().split(' ');
            const names2 = confirmedName.toLowerCase().split(' ');

            this.processNameArray(names1);
            this.processNameArray(names2);

            let matchCount = 0;
            for (const i in names1) {
                for (const j in names2) {
                    //console.log('comparing ' + names1[i] + ' to ' + names2[j]);
                    if (names1[i] == names2[j]) {
                        matchCount += 1;
                    }
                }
            }
            //console.log('match on ' + matchCount + ' of ' + Math.max(names1.length, names2.length) + ' names');

            const matchDob = originalDob == confirmedDob;
            if (matchDob) {
                if (matchCount >= Math.max(names1.length, names2.length)) {
                    return 1;
                }else if (matchCount > 1) {
                    return 2;
                }else if (matchCount == 1) {
                    return 3;
                }else {
                    return 4;
                }
            }else {
                if (matchCount >= Math.max(names1.length, names2.length)) {
                    if (originalDob == '1800-01-01') {
                        return 8;
                    }else {
                        return 5;
                    }
                }else if (matchCount > 1) {
                    if (originalDob == '1800-01-01') {
                        return 9;
                    }else {
                        return 6;
                    }
                }else if (matchCount == 1) {
                    return 7;
                }
            }
        }catch (ex) {
            console.log('indicator match FAILED');
        }
        return 0;
    },

    //#region Final person merge ------------------------------------------------------------------------------------------

    mergePersons(result) {

        const persons = JSON.parse(JSON.stringify(result.persons));

        const newList = [];
        const idsToRemove = [];
        const idsToAdd = [];

        for (const i in persons) {
            let match = false;
            for (const j in newList) {
                if (this.comparePersons(persons[i], newList[j])) {
                    idsToRemove.push(persons[i]._id);
                    const addId = this.updateMergedPerson(persons[i], newList[j], result.client);
                    if (addId) idsToAdd.push(addId);
                    match = true;
                }
            }
            if (!match) {
                newList.push(persons[i]);
            }
        }

        /* console.log('idsToRemove', idsToRemove);
        console.log('idsToAdd', idsToAdd);
        console.log('newList', newList); */

        for (const i in idsToRemove) {
            for (const j in result.client.roles) {
                if (idsToRemove[i] == result.client.roles[i]) {
                    console.log('removed', idsToRemove[i]);
                    result.client.roles.splice(i, 1);
                }
            }
        }
        for (const i in idsToAdd) {
            result.client.roles.push(idsToAdd[i]);
        }

        result.persons = newList;

    },

    updateMergedPerson(source, target, client) {
        let addId = null;

        for (const i in client.roles) {
            if (client.roles[i] == source._id) {
                addId = target._id;
            }
        }

        for (const i in source.clients[0].roles) {
            let match = false;
            for (const j in target.clients[0].roles) {
                if (source.clients[0].roles[i] == target.clients[0].roles[j]) {
                    match = true;
                }
            }
            if (!match) {
                target.clients[0].roles.push(source.clients[0].roles[i]);
            }
        }

        if (source.idc.done && !target.idc.done) {
            target.idc = source.idc;
            console.log('merged IDC');
        }
        if (source.scr.done && !target.scr.done) {
            target.scr = source.scr;
            console.log('merged SCR');
        }
        if (source.web.done && !target.web.done) {
            target.web = source.web;
            console.log('merged WEB');
        }

        if (source.name.length > target.name.length) {
            target.name = source.name;
            target.info.firstName = source.info.firstName;
            target.info.lastName = source.info.lastName;
        }
        if (source.info.source != 'created' && source.info.source == 'created') {
            target.name = source.name;
            target.info.firstName = source.info.firstName;
            target.info.lastName = source.info.lastName;
            target.info.country = source.info.country;
            target.info.ssn = source.info.ssn;
            target.info.source = source.info.source;
            target.info.sourceDate = source.info.sourceDate;
        }

        if (source.email.value && !target.email.value) {
            target.email.value = source.email.value;
            target.email.source = source.email.source;
            target.email.sourceDate = source.email.sourceDate;
        }

        return addId;
    },

    processNameArray: function(array) {
        for (let i = array.length; i > -1; i--) {
            if (!array[i]) {
                array.splice(i, 1);
            }else {
                if (array[i].includes('é')) array[i] = array[i].split('é').join('e');
            }
        }
    },

    comparePersons(person1, person2) {

        const name1 = person1.info.firstName + ' ' + person1.info.lastName;
        const name2 = person2.info.firstName + ' ' + person2.info.lastName;

        const names1 = name1.toLowerCase().split(' ');
        const names2 = name2.toLowerCase().split(' ');

        this.processNameArray(names1);
        this.processNameArray(names2);

        //console.log(names1);
        //console.log(names2);

        const dob1 = person1.dateOfBirth;
        const dob2 = person2.dateOfBirth;
        //console.log('comparing ' + names1 + ' to ' + names2);
        let matchCount = 0;
        for (const j in names1) {
            for (const k in names2) {
                if (names1[j] == names2[k]) {
                    matchCount += 1;
                }
            }
        }
        this.logStuff('AutoMerge: ' + person1.name + ' match on ' + matchCount + ' of ' + Math.max(names1.length, names2.length) + ' names against', names2.toString());
        let mismatchDob = false;
        let mismatchSsn = false;
        if (matchCount > 1) {
            if (person2.ssn && person1.ssn && person2.ssn.length == person1.ssn.length && person2.ssn != person1.ssn) {
                mismatchSsn = true;
                this.logStuff('AutoMerge: Mismatch SSN ' + person2.ssn + ' and ' + person1.ssn);
            }
            //console.log('person1 DOB', dob1);
            //console.log('person2 DOB', dob2);
            if (dob2 && dob1 && dob2 != '1800-01-01' && dob2.length == dob1.length && dob2 != dob1) {
                mismatchDob = true;
                this.logStuff('AutoMerge: Mismatch DOB ' + dob2 + ' and ' + dob1);
            }
        }
        if (matchCount > 1 && !mismatchDob && !mismatchSsn) {
            this.logStuff('AutoMerge: ' + person1.name + ' match against', names2.toString());
            return true;
        }
        return false;
    },

    logStuff: function(message, data) {

        return;

        if (data) {
            console.log(message + ', ' + data.toString());
        }else {
            console.log(message);
        }
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------

    getReportItems(reportData, pairs) {
        const items = [];

        let comparisonComment = '';

        for (const key in reportData) {

            if (reportData[key].type == 'aml') {

                items.push({
                    key: 'org',
                    comment: reportData[key].comment,
                    check: reportData[key].ok
                });

            }else if (reportData[key].type == 'kyc') {

                items.push({
                    key: 'kyc',
                    comment: reportData[key].comment,
                    check: reportData[key].ok
                });

            }else if (reportData[key].type == 'comparison') {

                comparisonComment = reportData[key].comment;

            }else if (reportData[key].type == 'total') {

                items.push({
                    key: 'assessment',
                    comment: reportData[key].comment,
                    check: reportData[key].ok
                });

            }else if (reportData[key].type == 'per') {

                let id = 'UNKNOWN';

                for (const i in pairs) {
                    if (key == pairs[i].oldId) {
                        id = pairs[i].newId;
                    }
                }

                items.push({
                    key: 'roles-' + id,
                    comment: reportData[key].comment,
                    check: reportData[key].ok
                });

            }
        }

        for (const i in items) {
            if (items[i].key == 'kyc' && comparisonComment) {
                if (items[i].check) {
                    items[i].check = false;
                    items[i].comment = comparisonComment;
                }else {
                    items[i].comment = items[i].comment + ' ' + comparisonComment;
                }
                
            }
        }

        return items;
    },

    getKycData(client, envelope) {
        console.log('kycEnvelope', envelope);

        try {
            const kycData = {
                userId: client.customer.kyc.userId,
                name: envelope.content.documents[0].name,
                dateCreated: new Date(envelope.content.created),
                envelopeId: client.kycMetadata.envelopeId,
                filled: client.kycMetadata.completed,
                signed: client.kycMetadata.signed,
                dateFilled: new Date(client.customer.kyc.completedDate),
                dateSigned: '',
                editors: [],
                signers: [],
                files: [],
                fields: {},
                next: null,
                hasCheckout: false,
                checkout: {}
            };
    
            for (const i in envelope.content.recipients) {
                const recipient = envelope.content.recipients[i];
                //console.log('rec', recipient);
    
                if (recipient.role.action == 'edit') {
    
                    kycData.editors.push({
                        name: recipient.givenName + ' ' + recipient.familyName,
                        completed: recipient.completed
                    });
    
                }else if (recipient.role.action == 'sign') {
    
                    kycData.signers.push({
                        name: recipient.givenName + ' ' + recipient.familyName,
                        completed: recipient.completed,
                        signature: {
                            method: recipient.signatures[0].origin,
                            firstName: recipient.signatures[0].givenName,
                            lastName: recipient.signatures[0].familyName,
                            name: recipient.signatures[0].givenName + ' ' + recipient.signatures[0].familyName,
                            dateOfBirth: recipient.signatures[0].birthDate ? recipient.signatures[0].birthDate.split('T')[0] : '',
                            ssn: recipient.signatures[0].personalNumber || '',
                            email: recipient.signatures[0].email
                        }
                    });
    
                    kycData.dateSigned = new Date(recipient.signatures[0].date);
    
                }
    
            }
    
            if (envelope.content.documents[0].files.attachments) {
                for (const i in envelope.content.documents[0].files.attachments) {
                    const atc = envelope.content.documents[0].files.attachments[i];
                    console.log('atc', atc);
                    if (!atc.name.includes('pAdES')) {
                        kycData.files.push({
                            type: 'atc',
                            name: atc.name
                        });
                    }
                }
            }
    
            //console.log('kycData', kycData);
            return kycData;

        }catch (ex) {
            return { error: true };
        }
    },

    getKycResult(kycEnvelope, formKey) {
        const result = {
            done: false
        };
        if (kycEnvelope.content.completed) {
            result.done = true;
            result.name = '';
            result.orgnr = '';
            result.envelopeId = '';
            result.formKey = formKey;
            result.roles = [];
            result.beneficials = [];
            result.shareholders = [];
            result.peps = [];
            result.comparison = [];
        }else {
            return result;
        }

        const list = { kyc: [] };
        kychelper.processKYCPersons2(kycEnvelope.userdata, list);
        //console.log('kyc-list', list.kyc);

        console.log('kyc-envelope', kycEnvelope);

        result.name = kycEnvelope.userdata.brregData ? kycEnvelope.userdata.brregData.name : '';
        result.orgnr = kycEnvelope.userdata.brregData ? kycEnvelope.userdata.brregData.org_no : '';
        result.envelopeId = kycEnvelope.id;

        for (const i in kycEnvelope.userdata.roles) {
            const person = {
                roleId: this.getNewRoleId(kycEnvelope.userdata.roles[i].roleId),
                firstName: kycEnvelope.userdata.roles[i].firstName,
                lastName: kycEnvelope.userdata.roles[i].lastName,
                dateOfBirth: kycEnvelope.userdata.roles[i].dateOfBirth,
                street: kycEnvelope.userdata.roles[i].address,
                zip: kycEnvelope.userdata.roles[i].zip,
                city: kycEnvelope.userdata.roles[i].city,
                country: this.getCountryCode(kycEnvelope.userdata.roles[i].country),
                email: kycEnvelope.userdata.roles[i].email,
                phone: kycEnvelope.userdata.roles[i].phone && kycEnvelope.userdata.roles[i].phone.number ? kycEnvelope.userdata.roles[i].phone.prefix + ' ' + kycEnvelope.userdata.roles[i].phone.number : '',
                personId: '',
            };
            result.roles.push(person);
        }

        for (const i in kycEnvelope.userdata.rrhs) {
            //console.log('rrh', kycEnvelope.userdata.rrhs[i]);
            const person = {
                firstName: kycEnvelope.userdata.rrhs[i].firstName,
                lastName: kycEnvelope.userdata.rrhs[i].lastName,
                dateOfBirth: kycEnvelope.userdata.rrhs[i].dateOfBirth,
                street: kycEnvelope.userdata.rrhs[i].address,
                zip: kycEnvelope.userdata.rrhs[i].zip,
                city: kycEnvelope.userdata.rrhs[i].city,
                country: this.getCountryCode(kycEnvelope.userdata.rrhs[i].country),
                email: kycEnvelope.userdata.rrhs[i].email,
                phone: kycEnvelope.userdata.rrhs[i].phone && kycEnvelope.userdata.rrhs[i].phone.number ? kycEnvelope.userdata.rrhs[i].phone.prefix + ' ' + kycEnvelope.userdata.rrhs[i].phone.number : '',
                share: kycEnvelope.userdata.rrhs[i].share || kycEnvelope.userdata.rrhs[i].percent,
                personId: '',
            };
            if (personhelper.isValidPerson(person)) result.beneficials.push(person);
        }

        for (const i in kycEnvelope.userdata.entities) {
            const person = {
                name: kycEnvelope.userdata.entities[i].name,
                orgnr: kycEnvelope.userdata.entities[i].orgnr,
                firstName: kycEnvelope.userdata.entities[i].firstName,
                lastName: kycEnvelope.userdata.entities[i].lastName,
                dateOfBirth: kycEnvelope.userdata.entities[i].dateOfBirth,
                street: kycEnvelope.userdata.entities[i].address,
                zip: kycEnvelope.userdata.entities[i].zip,
                city: kycEnvelope.userdata.entities[i].city,
                country: this.getCountryCode(kycEnvelope.userdata.entities[i].country),
                email: kycEnvelope.userdata.entities[i].email,
                phone: kycEnvelope.userdata.entities[i].phone && kycEnvelope.userdata.entities[i].phone.number ? kycEnvelope.userdata.entities[i].phone.prefix + ' ' + kycEnvelope.userdata.entities[i].phone.number : '',
                share: kycEnvelope.userdata.entities[i].share || kycEnvelope.userdata.entities[i].percent,
                personId: '',
            };

            if (!person.firstName && person.dateOfBirth) {
                const names = person.name.split(' ');
                person.lastName = names[names.length - 1];
                person.firstName = '';
                for (const x in names) {
                    if (x == names.length - 1) break;
                    if (x > 0) person.firstName += ' ';
                    person.firstName += names[x];
                }
            }

            result.shareholders.push(person);
        }

        for (const i in kycEnvelope.userdata.peps) {
            const person = {
                firstName: kycEnvelope.userdata.peps[i].firstName,
                lastName: kycEnvelope.userdata.peps[i].lastName,
                dateOfBirth: kycEnvelope.userdata.peps[i].dateOfBirth,
                street: kycEnvelope.userdata.peps[i].address,
                zip: kycEnvelope.userdata.peps[i].zip,
                city: kycEnvelope.userdata.peps[i].city,
                country: this.getCountryCode(kycEnvelope.userdata.peps[i].country),
                email: kycEnvelope.userdata.peps[i].email,
                phone: kycEnvelope.userdata.peps[i].phone && kycEnvelope.userdata.peps[i].phone.number ? kycEnvelope.userdata.peps[i].phone.prefix + ' ' + kycEnvelope.userdata.peps[i].phone.number : '',
                title: kycEnvelope.userdata.peps[i].title,
                personId: '',
            };
            result.peps.push(person);
        }

        //console.log('result', result);
        return result;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Person helpers ----------------------------------------------------------------------------------------------

    getEmptyPerson(oldPerson, count, client) {
        return {
            _id: 'NO_ID_' + count,
            name: oldPerson.info.firstName + ' ' + oldPerson.info.lastName,
            letter: oldPerson.info.firstName.charAt(0).toUpperCase(),
            dateOfBirth: oldPerson.info.dateOfBirth,
            info: {
                country: '',
                ssn: oldPerson.info.ssn || '',
                firstName: oldPerson.info.firstName,
                lastName: oldPerson.info.lastName,
                source: 'created',
                sourceDate: new Date()
            },
            address: {
                street: '',
                zip: '',
                city: '',
                country: '',
                source: 'created',
                sourceDate: new Date()
            },
            email: {
                value: '',
                source: 'created',
                sourceDate: new Date()
            },
            phone: {
                prefix: '',
                number: '',
                source: 'created',
                sourceDate: new Date()
            },
            idc: {
                done: false
            },
            scr: {
                done: false
            },
            web: {
                done: false
            },
            flags: {
                hasPep: false,
                hasSanction: false,
                hits: 0
            },
            cscreen: false,
            clientIds: [],
            clients: [],
            manualRoles: [],
            archive: [],
            userId: client.userId,
            dateCreated: new Date(),
            dateUpdated: new Date()
        };
    },

    async getPersonID(root, oldPerson, person) {
        console.log('PPPPP', oldPerson);
        if (oldPerson.control.completed) {

            if (oldPerson.control.method == 'bankid-no') {
                const idcEnvelope = await root.getEnvelope(oldPerson.control.envelopeId);
                console.log('idcEnvelope', idcEnvelope);
                const idc = {
                    done: true,
                    date: new Date(oldPerson.control.dateCompleted),
                    method: 'bankid-no',
                    name: oldPerson.control.confirmedInfo.firstName + ' ' + oldPerson.control.confirmedInfo.lastName,
                    firstName: oldPerson.control.confirmedInfo.firstName,
                    lastName: oldPerson.control.confirmedInfo.lastName,
                    dateOfBirth: oldPerson.control.confirmedInfo.dateOfBirth,
                    ssn: oldPerson.control.confirmedInfo.ssn,
                    envelopeId: oldPerson.control.envelopeId,
                    originator: idcEnvelope.userdata.idcDecoded ? idcEnvelope.userdata.idcDecoded.originator : '',
                };

                person.email = {
                    value: oldPerson.control.recipientEmail,
                    source: 'idc',
                    sourceDate: new Date(oldPerson.control.dateCompleted)
                };

                return idc;

            }else if (oldPerson.control.method == 'fileupload') {

                const idc = {
                    done: true,
                    date: new Date(oldPerson.control.dateCompleted),
                    method: 'fileupload',
                    name: oldPerson.control.confirmedInfo.firstName + ' ' + oldPerson.control.confirmedInfo.lastName,
                    firstName: oldPerson.control.confirmedInfo.firstName,
                    lastName: oldPerson.control.confirmedInfo.lastName,
                    dateOfBirth: oldPerson.control.confirmedInfo.dateOfBirth,
                    ssn: '',
                    envelopeId: oldPerson.control.envelopeId,
                };

                person.email = {
                    value: oldPerson.control.recipientEmail,
                    source: 'idc',
                    sourceDate: new Date(oldPerson.control.dateCompleted)
                };

                return idc;

            }else if (oldPerson.control.method == 'manual') {

                const idc = {
                    done: true,
                    date: new Date(oldPerson.control.dateCompleted),
                    method: 'manual',
                    name: oldPerson.info.firstName + ' ' + oldPerson.info.lastName,
                    firstName: oldPerson.info.firstName,
                    lastName: oldPerson.info.lastName,
                    dateOfBirth: oldPerson.info.dateOfBirth,
                    ssn: oldPerson.control.manualInfo.ssn || '',
                    comment: oldPerson.control.manualInfo.comment || '',
                    userId: oldPerson.control.manualInfo.userId
                };

                return idc;

            }

        }else {
            return { done: false };
        }
    },

    async getPersonScr(root, oldPerson) {
        if (oldPerson.search.completed) {
            const scrEnvelope = await root.getEnvelope(oldPerson.search.envelopeId);
            //console.log('scrEnvelope', scrEnvelope);

            let scrData = dataModelV1.getEmptyPerson();

            if (scrEnvelope.content.descriptor.id == 'aml-portal-v1-person') {

                scrData = scrEnvelope.userdata.personData;
                scrData.done = true;
                scrData.envelopeId = scrEnvelope.id;

            }else {
                const scrParams = {
                    firstName: oldPerson.info.firstName,
                    lastName: oldPerson.info.lastName,
                    dateOfBirth: oldPerson.info.dateOfBirth,
                    ssn: oldPerson.info.ssn || '',
                    countryOfSsn: '',
                    mode: 'exact',
                    parameters: ['firstName', 'lastName', 'dateOfBirth']
                };
                dataNorwayV1.processPerson(root, scrData, { personData: scrEnvelope.userdata.aml.personData }, scrParams);
                scrData.date = new Date(scrEnvelope.content.created);
                scrData.envelopeId = scrEnvelope.id;
                scrData.userId = scrEnvelope.content.sender.uid.split('/')[4];
            }

            //console.log('scrData', scrData);
            return scrData;

        }else {
            return { done: false };
        }
    },

    getPersonWeb(root, oldPerson, reportData) {
        const web = {
            done: false
        };

        //console.log('oldPerson', oldPerson);

        for (const i in reportData) {
            //console.log('rep ' + i, reportData[i]);

            if (i == oldPerson.id) {
                //console.log('match on', oldPerson.displayName);
                //console.log('data', reportData[i]);

                const rdata = reportData[i];

                if (rdata.searchComplete || rdata.ok) {

                    web.done = true;
                    web.completed = true;
                    web.keywords = [];

                    web.comment = rdata.comment || '';
                    web.date = rdata.searchDate;
                    web.userId = rdata.userId;

                    for (const j in rdata.keywords) {
                        web.keywords.push({
                            key: j.toString(),
                            locale: 'en_EN',
                            done: true,
                            result: rdata.keywords[j] ? 'hits' : 'ok'
                        });
                    }


                }

            }

        }

        //console.log('WEB', web);
        return web;
    },

    getNewRoleId(roleId) {
        switch (roleId) {
            case 'ceo': return 'role_ceo';
            case 'chair': return 'role_chair';
            case 'screening_role_deputy_chairman': return 'role_deputy_chair';
            case 'screening_role_board_member': return 'role_board_member';
            case 'screening_role_rrh': return 'role_rrh';
            case 'screening_role_shareholder': return 'role_shareholder';
            case 'screening_role_procura': return 'role_procura';
            case 'proprietor': return 'role_proprietor';
        }
        return roleId;
    },

    getCountryCode(country) {
        const val = country ? country.toLowerCase() : '';
        if (val == 'no' || val == 'norge'|| val == 'norway') return 'NO';

        if (country) console.error('Country conversion failed');
        return country;
    }

    //#endregion ----------------------------------------------------------------------------------------------------------


    // DATA LOSS

    // idc sent date

};
export default v1migration;