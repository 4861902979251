import triggerHelper from '../v1flows/triggerHelper';
import log from '../log';
import api from '../portalapi';
import pdfTools from '../pdfTools';
import v2lib from '../v2lib';
import cache from '../cache';
import verified from '../verified';
import v1api from '../v1helpers/v1api';
import v1personHelper from './v1personHelper';
const v1reportHelper = {

    //#region Loading ------------------------------------------------------------------------------------------------------

    loadReportData(root, output, client, flow, persons, isForm) {

        // Load report items
        for (const i in flow.report) {

            // Check if this report item has any conditions
            if (!this.passesStepConditions(root, flow, flow.report[i])) continue;

            // If the report is rendered in a form, hide some steps
            if (flow.report[i].hideInForm && isForm) continue;

            if (flow.report[i].key == 'roles') {
                for (const j in persons) {
                    if (persons[j].selected) {
                        const item = JSON.parse(JSON.stringify(flow.report[i]));
                        item.dataKey = flow.report[i].key + '-' + persons[j].personId;
                        item.valid = false;
                        item.commentValid = false;
                        item.highlight = false;
                        item.person = persons[j];
                        if (j > 0) {
                            item.title = null;
                            item.separation = false;
                        }

                        //console.log('PPPPPP', persons[j])
                        if (persons[j].screening.falsePositive) {
                            item.initialData = {
                                comment: persons[j].screening.fpParams.comment,
                            };
                        }

                        output.items.push(item);
                    }
                }
            }else {
                flow.report[i].dataKey = flow.report[i].key;
                flow.report[i].valid = false;
                flow.report[i].commentValid = false;
                root.$set(flow.report[i], 'highlight', false);
                output.items.push(flow.report[i]);
            }
        }

        // Load report data
        for (const i in output.items) {
            let key = output.items[i].key;
            if (output.items[i].key == 'roles') key += '-' + output.items[i].person.personId;
            let match = false;
            for (const j in client.report.items) {
                if (client.report.items[j].key == key) {
                    match = true;
                    root.$set(output.data, key, this.getExistingReportData(root, client.report.items[j]));
                }
            }
            if (!match) {
                root.$set(output.data, key, this.getEmptyReportData(root, key, output.items[i].initialData));
            }
        }

        // Create risk options
        for (const i in root.config.risk.values) {
            if (root.config.risk.values[i] != 0) {
                for (const j in root.config.risk.texts) {
                    if (root.config.risk.texts[j].value == root.config.risk.values[i]) {
                        const option = { locale: {} };
                        for (const key in root.config.risk.texts[j]) {
                            if (key != 'value') option.locale[key] = root.config.risk.texts[j][key];
                        }
                        root.$set(output.riskOptions, root.config.risk.texts[j].value, option);
                    }
                }
            }
        }

    },

    getEmptyReportData(root, key, initialData) {
        const data = {
            key: key,
            comment: '',
            check: false
        };
/*         if (initialData && initialData.comment) {
            data.comment = initialData.comment;
        } */
        if (root.config.freport.dualComments.enabled) {
            data.comment2 = '';
            data.check2 = false;
        }
        return data;
    },

    getExistingReportData(root, data) {
        const eData = {
            key: data.key,
            comment: data.comment,
            check: data.check,
        };
        if (root.config.freport.dualComments.enabled) {
            eData.comment2 = data.comment2 || '';
            eData.check2 = data.check2 || false;
        }
        return eData;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Config ------------------------------------------------------------------------------------------------------

    getReportConfig(root, flow, triggers, texts, simplified) {
        const config = {
            items: [],
            showRisk: root.config.risk.enabled,
            commentOrg: true,
            commentPersons: true,
            showSSN: true,
            amsPersons: false,
            amsCompany: false,
            dualComments: root.config.freport.dualComments.enabled,
            comment1name: '',
            comment2name: '',
            falsePositive: false,
        };

        if (root.config.persons.allowFalsePositive) {
            config.falsePositive = true;
        }

        if (root.config.freport.dualComments.enabled) {
            config.comment1name = root.config.responsible.clientText[root.config.params.defaultLanguage];
            config.comment2name = root.config.partners.clientText[root.config.params.defaultLanguage];
        }

        if (simplified) {
            config.showSSN = false;
        }

        if (root.config.adverseMedia.enabled) config.amsPersons = true;
        if (root.config.adverseMedia.orgEnabled) config.amsCompany = true;

        for (const x in flow.report) {

            // Check if this report item has any conditions
            if (!this.passesStepConditions(root, flow, flow.report[x])) continue;

            if (flow.report[x].key != 'roles' && flow.report[x].key != 'org' && flow.report[x].key != 'assessment' && flow.report[x].key != 'form') {

                const ritem = {
                    key: flow.report[x].key,
                    title: flow.report[x].title,
                    items: [],
                    comment: flow.report[x].comment
                };

                const items = flow.report[x].items;
                console.log('items', items);

                for (const i in items) {
                    if (items[i].type != 'custom') continue;

                    let valid = true;
                    for (const k in items[i].triggers) {
                        if (items[i].triggers[k].type == 'rep-itm') {
                            
                            valid = false;

                        }else {
                            const result = triggerHelper.validateTrigger(items[i].triggers[k], triggers);
                            if (!result) valid = false;
                        }
                    }
                    if (valid) {
                        let addCustomItem = true;
                        const customItem = {
                            width: items[i].width,
                            text: items[i].text,
                            value: triggerHelper.replaceTexts(items[i].value, texts),
                        };

                        if (items[i].validTextOnly) {
                            // Item should only be added if the text was replaced
                            if (customItem.value[root.locale.key].includes('{{')) {
                                addCustomItem = false;
                            }
                        }

                        if (addCustomItem) ritem.items.push(customItem);
                    }
                }


                config.items.push(ritem);

            }else if (flow.report[x].key == 'roles') {

                if (!flow.report[x].comment) config.commentPersons = false;

            }else if (flow.report[x].key == 'org') {

                if (!flow.report[x].comment) config.commentOrg = false;

            }else if (flow.report[x].key == 'form') {

                console.log('flow.report[x]', flow.report[x]);

            }
        }

        return config;
    },

    getReportUserData(root, params, client, flow, persons, triggers, texts, simplified, extraData) {
        const reportConfig = this.getReportConfig(root, flow, triggers, texts, simplified);
        const attachments = this.getAttachments(root, flow, client, persons);

        const userdata = {
            metadata: {
                userId: root.credentials.uid,
                userName: root.getEmployeeName(root.credentials.uid),
                date: new Date(),
                title: this.getReportName(root, client),
            },
            reportConfig: reportConfig,
            flow: flow,
            client: client,
            persons: persons,
            selectedPersons: [],
            attachments: attachments,
            reportData: params.data,
            riskOptions: params.riskOptions,
            pdfLanguage: root.locale.key,
            extraData: extraData || {},
            hasExternalFiles: false,
        };
        for (const i in attachments) {
            if (attachments[i].externalFile) userdata.hasExternalFiles = true;
        }

        if (client.web && client.web.done) {
            userdata.webReport = {
                userName: root.getEmployeeName(client.web.userId),
                hitString: v1personHelper.getAmsResultText(root, client.web, root.locale.key)
            };
        }

        for (const i in persons) {
            if (persons[i].selected) userdata.selectedPersons.push(persons[i]);
            if (persons[i].web.done) {
                persons[i].webReport = {
                    userName: root.getEmployeeName(persons[i].web.userId),
                    hitString: v1personHelper.getAmsResultText(root, persons[i].web, root.locale.key)
                };
            }
        }

        return userdata;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    getReportName(root, client) {
        let filename = 'finalreport';

        if (root.config.freport.nameFormat != 'default') {

            console.log('format', root.config.freport.nameFormat);

            let str = root.config.freport.nameFormat;

            str = str.split('{{name}}').join(root.companyTitleCase(client.name));
            str = str.split('{{identity}}').join(client.identity);
            str = str.split('{{externalId}}').join(client.externalId || 'UNKNWON_ID');

            str = this.replaceTags(root, client, str);
            filename = str;
        }else {
            filename = root.__t('report-final') + ' ' + root.companyTitleCase(client.name);
        }

        return filename;
    },

    replaceTags(root, client, str) {

        const index = str.indexOf('{{tag-');

        console.log('index', index);

        if (index != -1) {

            const endIndex = str.indexOf('}}', index);

            console.log('endIndex', endIndex);

            let tagInfo = str.substring(index, endIndex + 2);
            tagInfo = tagInfo.replace('{{', '').replace('}}', '');

            console.log('tagInfo', tagInfo);

            const infoArray = tagInfo.split('-');

            console.log('infoArray', infoArray);


            str = str.substring(endIndex + 2);

            if (infoArray[1] == 'r') {
                if (client.tags && client.tags.includes(infoArray[2])) {
                    str = '[' + this.getTag(root, infoArray[2]) + '] ' + str;
                }
            }

        }

        console.log('str', str);
        return str;
    },

    getTag(root, tag) {
        for (const i in root.config.tags.list) {
            if (root.config.tags.list[i].key == tag) {
                return root.config.tags.list[i].text[root.locale.key];
            }
        }
        return tag;
    },


    passesStepConditions(root, flow, reportItem) {

        if (reportItem.stepCondition) {
            let match = false;
            for (const j in flow.steps) {
                if (flow.steps[j].key == reportItem.stepCondition) match = true;
            }

            if (!match) return false;
        }

        if (reportItem.configCondition) {
            if (!root.config.flow[reportItem.configCondition]) return false;
        }

        return true;
    },


    //#region Helpers -----------------------------------------------------------------------------------------------------

    getAttachments(root, flow, client, persons) {
        const attachments = [];

        if (client.envelope.attachments && client.envelope.attachments.length > 0) {

            for (const i in client.envelope.attachments) {
                attachments.push({
                    name: client.envelope.attachments[i].name,
                    envelopeId: client.envelope.id,
                    isAttachment: true
                });
            }

            
        }else if (!flow.isPerson && !flow.isManual) {
            attachments.push({
                name: root.__t('search-name'),
                envelopeId: client.envelope.id,
                isAttachment: false
            });
        }

        for (const i in flow.report) {

            // Check if this report item has any conditions
            if (!this.passesStepConditions(root, flow, flow.report[i])) continue;

            if (flow.report[i].includeFile && flow.report[i].includeFile.enabled) {

                let manualFile = null;
                if (client.files) {
                    for (const j in client.files) {
                        if (client.files[j].formKey == flow.report[i].includeFile.formKey) manualFile = client.files[j];
                    }
                }

                if (client[flow.report[i].includeFile.formKey]) {

                    const formData = client[flow.report[i].includeFile.formKey];
                    //console.log('FORM', formData);

                    attachments.push({
                        name: formData.name || flow.report[i].includeFile.name || 'Unkown attachment',
                        envelopeId: formData.envelopeId,
                        isAttachment: false
                    });

                    if (formData.files) {
                        for (const x in formData.files) {
                            if (!formData.files[x].name.includes('pAdES')) {
                                attachments.push({
                                    name: formData.files[x].name,
                                    envelopeId: formData.envelopeId,
                                    isAttachment: true
                                });
                            }
                        }
                    }

                }else if (manualFile) {

                    //console.log('form has uploaded file', manualFile);
                    attachments.push({
                        name: manualFile.name,
                        envelopeId: manualFile.envelopeId,
                        isAttachment: true
                    });

                }else {

                    //console.log('custom file', flow.report[i].includeFile);

                    attachments.push({
                        name: flow.report[i].includeFile.name,
                        reportKey: flow.report[i].includeFile.reportKey,
                        envelopeId: '',
                        isAttachment: false
                    });

                }

            }

        }

        if (client.book && client.book.uploaded) {
            for (const i in client.book.names) {

                attachments.push({
                    name: client.book.names[i],
                    envelopeId: client.book.envelopeId,
                    isAttachment: true
                });

            }
        }
        if (client.kyc.book && client.kyc.book.provided) {
            for (const i in client.kyc.book.names) {

                attachments.push({
                    name: client.kyc.book.names[i],
                    envelopeId: client.kyc.envelopeId,
                    isAttachment: true
                });

            }
        }

        if (client.files) {
            for (const i in client.files) {
                if (client.files[i].inludeInReport) {
                    attachments.push({
                        name: client.files[i].name,
                        envelopeId: client.files[i].envelopeId,
                        isAttachment: true,
                        externalFile: true,
                        comment: client.files[i].comment
                    });
                }
            }
        }

        return attachments;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Merged download ---------------------------------------------------------------------------------------------

    async getMergedReport(root, previewParams, simplified) {

        const result = await this.loadPreviewEnvelope(root, previewParams);
        
        let mainData;
        if (simplified) {
            const recreateData = previewParams.recreateData;

            const userdata = v1reportHelper.getReportUserData(root, recreateData.params, recreateData.client, recreateData.flow, recreateData.persons, recreateData.triggers, recreateData.texts, simplified);
            console.log('USERDATA', userdata);

            const translations = { ...root.translations, ...root.v1translations };

            const response = await pdfTools.getPDF(root, root.config, 'repv1', userdata, translations, true, []);
            console.log('response', response);

            mainData = response.data;

        }else {
            mainData = await cache.getFile(result.mainFile.envelopeId, result.mainFile.name, result.mainFile.url);
        }


        const appendFiles = [];

        for (const i in result.attachments) {
            appendFiles.push({
                type: 'na',
                url: result.attachments[i].url,
                fileType: 'application/pdf',
                description: 'na',
                info: 'na'
            });
        }

        const downloadData = await pdfTools.mergePDF(mainData, appendFiles);
        console.log(downloadData);
        return downloadData.data;
    },

    async loadPreviewEnvelope(root, previewParams) {
        console.log('previewParams', previewParams);

        const result = {
            mainFile: null,
            secondFile: null,
            attachments: [],
            signatures: [],
            notReady: false
        };

        if (previewParams.isEditor) {

            const envelope = await v2lib.getEditorEnvelope(previewParams.mainEnvelope.id);
            console.log('envelope', envelope);

            result.mainFile = {
                envelopeId: previewParams.mainEnvelope.id,
                name: previewParams.mainEnvelope.name || 'main',
                url: ''
            };

            if (envelope.content.documents[0].files.signedDocument) {
                result.mainFile.url = await verified.getEditorDownloadUrl(envelope.id, envelope.content.documents[0].id, envelope.content.documents[0].files.signedDocument.uid, v2lib.editorToken);
            }else if (envelope.content.documents[0].files.document) {
                result.mainFile.url = await verified.getEditorDownloadUrl(envelope.id, envelope.content.documents[0].id, envelope.content.documents[0].files.document.uid, v2lib.editorToken);
            }

            if (previewParams.attachmentIsMain) {

                if (envelope.content.documents[0].files.attachments) {
                    for (const i in envelope.content.documents[0].files.attachments) {
                        console.log('A', envelope.content.documents[0].files.attachments[i]);

                        const atc = await this.getEnvelopeAttachment(envelope, envelope.content.documents[0].files.attachments[i]);
                        
                        result.mainFile.url = atc.url;
                        result.mainFile.name = atc.name;

                    }
                }

            }

        }else {

            const envelope = await root.getEnvelope(previewParams.mainEnvelope.id);
            console.log('envelope', envelope);

            result.mainFile = {
                envelopeId: previewParams.mainEnvelope.id,
                name: previewParams.mainEnvelope.name || 'main',
                url: ''
            };
            if (envelope.content.documents[0].files) {
                if (envelope.content.documents[0].files.signedDocument) {
                    result.mainFile.url = await cache.getUrl(envelope.id, envelope.content.documents[0].id, envelope.content.documents[0].files.signedDocument.uid);
                }else if (envelope.content.documents[0].files.document) {
                    result.mainFile.url = await cache.getUrl(envelope.id, envelope.content.documents[0].id, envelope.content.documents[0].files.document.uid);
                }
            }else {
                console.log('NOT READY');
                result.notReady = true;
                return result;
            }

            if (!result.mainFile.url) {
                console.log('NOT READY, mainFile url empty');
                result.notReady = true;
                return result;
            }

            if (previewParams.loadAttachments) {
                previewParams.attachments = [];
                if (envelope.content.documents.length > 1) {
                    result.secondFile = {
                        envelopeId: previewParams.mainEnvelope.id,
                        name: envelope.content.documents[1].name,
                        url: ''
                    };
                    if (envelope.content.documents[1].files.signedDocument) {
                        result.secondFile.url = await cache.getUrl(envelope.id, envelope.content.documents[1].id, envelope.content.documents[1].files.signedDocument.uid);
                    }else if (envelope.content.documents[1].files.document) {
                        result.secondFile.url = await cache.getUrl(envelope.id, envelope.content.documents[1].id, envelope.content.documents[1].files.document.uid);
                    }
                }
                //console.log('atc', envelope.content.documents[0].files.attachments);
                if (envelope.content.documents[0].files.attachments) {
                    for (const i in envelope.content.documents[0].files.attachments) {
                        const attachment = envelope.content.documents[0].files.attachments[i];
                        //console.log('A', attachment);
                        if (attachment.name.includes('pAdES')) {
                            try {
                                const index = attachment.name.indexOf('pAdES');
                                const recipientId = attachment.name.substring(index + 6).replace('.pdf', '');
                                for (const x in envelope.content.recipients) {
                                    const recipient = envelope.content.recipients[x];
                                    if (recipient.id = recipientId) {
                                        if (recipient.signatures && recipient.signatures.length > 0) {
                                            const signature = {
                                                signerName: recipient.signatures[0].givenName + ' ' + recipient.signatures[0].familyName,
                                                name: attachment.name
                                            };
                                            result.signatures.push(signature);
                                        }
                                    }
                                }
                            }catch (ex) {
                                console.error(ex);
                            }
                        }else {
                            previewParams.attachments.push({
                                name: attachment.name
                            });
                        }
                    }
                }
            }
            
            for (const i in previewParams.attachments) {

                result.attachments.push(await this.getEnvelopeAttachment(envelope, previewParams.attachments[i]));

            }

        }

        return result;
    },

    async getEnvelopeAttachment(envelope, data) {
        const attachment = {
            url: '',
            envelopeId: envelope.id,
            name: data.name
        };

        for (let j in envelope.content.documents[0].files) {
            for (let k in envelope.content.documents[0].files[j]) {
                if (envelope.content.documents[0].files[j][k].name == data.name) {
                    attachment.url = await cache.getUrl(envelope.id, envelope.content.documents[0].id, envelope.content.documents[0].files[j][k].uid);
                }
            }
        }

        return attachment;
    },

    //#endregion ----------------------------------------------------------------------------------------------------------

    async createReport(root, userdata, dialog, triggers, filename) {

        // First create an envelope to make sure Verified Platform is up and running
        const envelope = await v2lib.createEnvelope('aml-portal-v1-report', 'aml-portal-no-recipients-flow');

        if (!v2lib.wasCreated(envelope)) {
            return;
        }

        let base64; let ures;

        for (const i in userdata.attachments) {
            dialog.status = root.__t('report-prog-attachment') + ' ' + userdata.attachments[i].name;

            console.log('upload atc', userdata.attachments[i]);

            if (userdata.attachments[i].envelopeId) {

                try {
                    base64 = await verified.downloadVerifiedFile(root, userdata.attachments[i].envelopeId, userdata.attachments[i].isAttachment, userdata.attachments[i].name);
                    ures = await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, userdata.attachments[i].name);
                }catch (ex) {
                    log.e('Final report: Failed to add attachment', userdata.attachments[i]);
                }

            }else if (userdata.attachments[i].reportKey) {

                let file = null;

                for (const j in root.loadedFiles) {
                    console.log('loadedFIle', root.loadedFiles[j]);

                    if (root.loadedFiles[j].reportKey == userdata.attachments[i].reportKey) {
                        file = root.loadedFiles[j];
                    }

                }

                console.log('file', file);
                if (file) {
                    await v2lib.addAttachment(envelope, file.name, file.data);
                }
                

            }
        }

        dialog.status = root.__t('report-prog-receipt');

        //console.log(JSON.stringify(userdata));
        const template = await api.renderTemplate(root, 'repv1', userdata, root.v1translations);

        dialog.status = root.__t('report-prog-final');


        const envelopeName = filename;
        const envelopeTags = [root.v1translations['report-tag'][root.config.params.defaultLanguage]];

        userdata.html = template.html;
        userdata.css = template.css;

        userdata.mailConfig = this.getMailData(root, userdata.client, root.locale.key);

        // Set metadata
        const metadata = {
            silentParties: [],
            language: root.config.params.defaultLanguage,
            skipNotifySender: true,
            senderTemplate: 'document-signed',
            senderLanguage: root.locale.key,
        };

        for (const i in root.config.freport.notifications) {
            const notification = root.config.freport.notifications[i];
            const values = [];
            let shouldNotify = true;
            for (const j in notification.triggers) {
                const valid = triggerHelper.validateTrigger(notification.triggers[j], triggers);
                values.push(valid);
            }
            if (notification.triggerOr) {
                for (const j in values) {
                    if (values[j]) shouldNotify = true;
                }
            }else {
                shouldNotify = true;
                for (const j in values) {
                    if (!values[j]) shouldNotify = false;
                }
            }
            if (shouldNotify) {
                const silentParty = {
                    givenName: notification.firstName,
                    familyName: notification.lastName,
                    email: notification.email,
                    language: notification.language || 'en_EN',
                    emailTemplate: notification.template || 'document-signed'
                };
                console.log('notifying', silentParty);
                metadata.silentParties.push(silentParty);
            }
        }

        console.log('setting metadata', metadata);
        await v2lib.setMetadata(envelope, metadata);


        // Complete the envelope
        await v2lib.setUserData(envelope, userdata);
        await v2lib.setTags(envelope, envelopeTags);
        await v2lib.setName(envelope, envelopeName);
        await v2lib.publishEnvelope(envelope);


        dialog.status = root.__t('report-prog-update');


        await v1api.saveReport(userdata.client._id, envelope.id, filename);

    },

    getMailData(root, client, language) {
        let base = '';
        if (root.isAWS) base = '/web/aml-portal/#';
        const mail = {
            logourl: root.config.branding.logoEmail,
            baseUrl: root.baseUrl,
            customRedirect: 'no',
            editRoute: base + '/lform/{envelopeId}/{recipientId}',
            signRoute: base + '/lform/{envelopeId}/{recipientId}',
            lang: language,
            defaultLang: root.config.params.defaultLanguage,
            buttonColor: root.getColor('button'),
            linkColor: root.getColor('text-accent'),
            values: [],
            texts: {}
        };

        mail.values.push({ key: 'companyName', type: 'value', value: root.user.companyName });
        mail.values.push({ key: 'senderName', type: 'value', value: root.user.givenName + ' ' + root.user.familyName });
        mail.values.push({ key: 'senderEmail', type: 'value', value: root.user.email });
        mail.values.push({ key: 'customerName', type: 'value', value: client.name });

        let roleMail = {
            subject: {
                "en_EN": "Final report created for {{customerName}}",
                "nb_NO": "Sluttrapport opprettet for {{customerName}}",
                "sv_SE": "Slutrapport skapad för {{customerName}}",
            },
            header: {
                "en_EN": "Hi",
                "nb_NO": "Hei",
                "sv_SE": "Hej",
            },
            message: {
                "en_EN": "A new final report has been created for {{customerName}}",
                "nb_NO": "En ny sluttrapport er opprettet for {{customerName}}",
                "sv_SE": "En ny slutrapport har skapats för {{customerName}}",
            },
            button: {
                "en_EN": "Open",
                "nb_NO": "Åpne",
                "sv_SE": "Öppna",
            },
            hint: {
                "en_EN": "...",
                "nb_NO": "...",
                "sv_SE": "...",
            },
            footer: {
                "en_EN": "...",
                "nb_NO": "...",
                "sv_SE": "...",
            },
        };

        const roleName = 'done';
        const translations = {};
        for (let i in root.config.locales) {
            if (!root.config.locales[i].enabled) continue;
            translations[root.config.locales[i].key] = {
                subject: this.replaceDynamicValues(root, roleMail.subject[root.config.locales[i].key], client, root.config.locales[i].key),
                header: this.replaceDynamicValues(root, roleMail.header[root.config.locales[i].key], client, root.config.locales[i].key),
                message: this.replaceDynamicValues(root, roleMail.message[root.config.locales[i].key], client, root.config.locales[i].key),
                button: this.replaceDynamicValues(root, roleMail.button[root.config.locales[i].key], client, root.config.locales[i].key),
                hint: this.replaceDynamicValues(root, roleMail.hint[root.config.locales[i].key], client, root.config.locales[i].key),
                footer: this.replaceDynamicValues(root, roleMail.footer[root.config.locales[i].key], client, root.config.locales[i].key),
            }
            mail.texts[roleName] = translations;
        }

        return mail;
    },

    replaceDynamicValues(root, input, client, language) {
        if (!input) return input;

        return input;
    },





    validateTriggers(reference) {
        let allValid = true;
        reference.statusText = null;
        for (const i in reference.reportParams.items) {
            let itemValid = true;
            let commentValid = true;
            for (const j in reference.reportParams.items[i].requirements) {
                let valid = true;
                let validComment = true;
                let checkRequirements = true;
                if (reference.reportParams.items[i].requirements[j].conditions) {
                    for (const k in reference.reportParams.items[i].requirements[j].conditions) {
                        const result = triggerHelper.validateTrigger(reference.reportParams.items[i].requirements[j].conditions[k], reference.triggers);
                        if (!result) checkRequirements = false;
                    }
                }
                if (checkRequirements) {
                    for (const k in reference.reportParams.items[i].requirements[j].triggers) {
                        if (reference.reportParams.items[i].requirements[j].triggers[k].type == 'rep-itm') {
                            const result = this.validateReportDataTrigger(reference, reference.reportParams.items[i].requirements[j].triggers[k], reference.reportParams.items[i]);
                            if (!result) {
                                if (reference.reportParams.items[i].requirements[j].triggers[k].trg == 'has-comment') {
                                    commentValid = false;
                                    validComment = false;
                                }else {
                                    valid = false;
                                }
                            }
                        }else {
                            const result = triggerHelper.validateTrigger(reference.reportParams.items[i].requirements[j].triggers[k], reference.triggers);
                            if (!result) valid = false;
                        }
                    }
                }
                if (!valid) {
                    itemValid = false;
                    if (!reference.statusText) reference.statusText = triggerHelper.replaceTexts(reference.reportParams.items[i].requirements[j].message, reference.texts);
                }else if (!validComment) {
                    if (!reference.statusText) reference.statusText = triggerHelper.replaceTexts(reference.reportParams.items[i].requirements[j].message, reference.texts);
                }
            }
            reference.reportParams.items[i].valid = itemValid;
            reference.reportParams.items[i].commentValid = commentValid;
            console.log('ITEM', reference.reportParams.items[i])
            if (!itemValid || !commentValid) allValid = false;
        }
        if (allValid) reference.statusText = reference.$root.v1translations['report-create-ready'];
        if (reference.client.report.created) reference.statusText = reference.$root.v1translations['report-already-created'];
        reference.allCompleted = allValid;
    },

    validateReportDataTrigger(reference, trigger, item) {
        let valid = false;
        if (trigger.trg == 'has-comment') {
            const rdata = reference.reportParams.data[item.dataKey];
            if (reference.isPartner) {
                valid = (rdata.check2 || this.isValidComment(rdata.comment2));
            }else {
                valid = (rdata.check || this.isValidComment(rdata.comment));
            }
        }else if (trigger.trg == 'has-file') {
            console.log('VAL FILE', item)
            if (item.includeFile && item.includeFile.reportKey) {
                for (const i in reference.$root.loadedFiles) {
                    if (reference.$root.loadedFiles[i].reportKey == item.includeFile.reportKey) return true;
                }
            }
            valid = false;
        }else if (trigger.trg == 'risk-set') {
            console.log('isDualRisk', reference.isDualRisk);
            if (reference.isDualRisk) {
                if (reference.isPartner) {
                    console.log('isPartner');
                    valid = (reference.client.report.risk && reference.client.report.risk != 0);
                }else {
                    console.log('reference.client.report', reference.client.report);
                    console.log('isNotPartner');
                    valid = (reference.client.report.riskUser && reference.client.report.riskUser != 0);
                    console.log('user valid', valid);
                }
            }else {
                valid = (reference.client.report.risk && reference.client.report.risk != 0);
            }
        }else if (trigger.trg == 'form-valid') {
            valid = (reference.$refs.reportForm && reference.$refs.reportForm.length > 0) ? reference.$refs.reportForm[0].validation.formValid : false;
        }
        return valid;
    },

    isValidComment(input) {
        if (input == '<br>') return false;
        if (input.length > 0) return true;
        return false;
    },

};
export default v1reportHelper;