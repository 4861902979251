<template>
<div>
    <canvas id="myLineChart" ></canvas>
</div>
</template>

<script>
import { Chart, ArcElement, LineElement, LineController, LinearScale, CategoryScale, PointElement } from 'chart.js';
Chart.register(
    ArcElement,
    LineElement,
    LineController,
    LinearScale,
    CategoryScale,
    PointElement
);

export default {
    name: 'vap-linechart',
    props: {
        width: {
            type: Number,
            default: 200
        },
        height: {
            type: Number,
            default: 200
        },
        data: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            chartData: undefined
        }
    },
    watch: {
        data: {
            deep: false,
            handler(val) {
                const self = this;
                setTimeout(function() {
                    self.render();
                }, 200);
            }
        }
    },
    methods: {
        
        render() {
            if (this.chartData) this.chartData.destroy();

            const labels = [];
            const vdata = [];
            const colors = [];

            //console.log('data', this.data);

            for (const i in this.data.labels) {
                labels.push(this.data.labels[i]);
                vdata.push(this.data.numbers[i]);
                //colors.push(this.data.sets[i].color);
            }

            let max = undefined, currentMax = 0, roundedMax = 0, increment = 0;
            let min = 0, currentMin = 0, roundedMin = 0;
            for (const i in vdata) {
                if (Number(vdata[i]) > Number(currentMax)) currentMax = vdata[i];
                if (Number(vdata[i]) < Number(currentMin)) currentMin = vdata[i];
            }

            if (currentMax != 0) {
                if (currentMax > 1000000000) {
                    roundedMax = Math.round(currentMax / 1000000000) * 1000000000;
                    increment = 1000000000;
                }else if (currentMax > 100000000) {
                    roundedMax = Math.round(currentMax / 100000000) * 100000000;
                    increment = 100000000;
                }else if (currentMax > 10000000) {
                    roundedMax = Math.round(currentMax / 10000000) * 10000000;
                    increment = 10000000;
                }else if (currentMax > 1000000) {
                    roundedMax = Math.round(currentMax / 1000000) * 1000000;
                    increment = 1000000;
                }else if (currentMax > 100000) {
                    roundedMax = Math.round(currentMax / 100000) * 100000;
                    increment = 100000;
                }else if (currentMax > 10000) {
                    roundedMax = Math.round(currentMax / 10000) * 10000;
                    increment = 10000;
                }else {
                    roundedMax = Math.round(currentMax / 1000) * 1000;
                    increment = 1000;
                }
            }
            roundedMax += ((increment / 10) * 2);
            if (roundedMax != 0) max = Number(roundedMax);

            

            currentMin = Math.abs(currentMin);
            if (currentMin != 0) {
                if (currentMin > 1000000000) {
                    roundedMin = Math.round(currentMin / 1000000000) * 1000000000;
                }else if (currentMin > 100000000) {
                    roundedMin = Math.round(currentMin / 100000000) * 100000000;
                }else if (currentMin > 10000000) {
                    roundedMin = Math.round(currentMin / 10000000) * 10000000;
                }else if (currentMin > 1000000) {
                    roundedMin = Math.round(currentMin / 1000000) * 1000000;
                }else {
                    roundedMin = Math.round(currentMin / 1000) * 1000;
                }
            }
            if (roundedMin != 0) min = Number(roundedMin - (roundedMin * 2));


            var ctx = document.getElementById('myLineChart');
            ctx.width = this.width;
            ctx.height = this.height;
            //console.log('ctx', ctx);
            
            const self = this;

            this.chartData = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [{
                        label: 'My First Dataset',
                        data: vdata,
                        borderColor: this.data.color,
                        backgroundColor: this.data.color,

                        cubicInterpolationMode: 'monotone',
                        tension: 0.4
                    }]
                    },
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            min: min,
                            max: max,
                            ticks: {
                                // Include a dollar sign in the ticks
                                callback: function(value, index, values) {

                                    if (value > 1000000000) {
                                        return (value / 1000000000) + 'B';
                                    }else if (value > 1000000) {
                                        return (value / 1000000) + 'M';
                                    }else if (value == 0) {
                                        return '0';
                                    }else {
                                        return (value / 1000) + 'K';
                                    }

                                    console.log(value);
                                    

                                    
                                }
                            }
                        }
                    }
                }
            });
        },

    },
    async created() {
        //console.log('created');
        const self = this;
        setTimeout(function() {
            self.render();
        }, 200);
    }
}
</script>

<style scoped>

#myLineChart {
    
}

</style>
