var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.cardData.hideName)?_c('div',{staticClass:"flv2-env",class:[
        { 'flv2-env-phone' : _vm.$root.phoneMode } ]},[_vm._v(" "+_vm._s(_vm.__t('landing-document') + ': ' + _vm.name)+" ")]):_vm._e(),_c('div',{staticClass:"flv2-lf",class:[
        { 'flv2-lf-phone' : _vm.$root.phoneMode },
        { 'flv2-lf-selected' : _vm.selected } ],on:{"click":_vm.handleClick}},[_c('div',{staticClass:"flv2-lf-steps nohl"},_vm._l((_vm.steps),function(step,i){return _c('div',{key:'ls' + i,staticClass:"flv2-lf-step",class:[
                {'flv2-lf-p': step.status == 1 },
                {'flv2-lf-c': step.status == 2 },
                {'flv2-lf-a': step.status == 8 },
                {'flv2-lf-r': step.status == 9 } ]},[_c('div',{staticClass:"flv2-lf-circle"},[_c('vap-icon',{attrs:{"icon":step.icon,"color":'ext'}})],1),_c('div',{staticClass:"flv2-lf-info"},[_c('div',{staticClass:"flv2-lf-action"},[_vm._v(" "+_vm._s(_vm.__t(step.key))+" ")]),_c('div',{staticClass:"flv2-lf-name"},[_vm._v(" "+_vm._s(step.nameKey ? _vm.__t(step.nameKey) : step.name)+" ")])]),(i > 0)?_c('div',{staticClass:"flv2-lf-line"}):_vm._e(),(step.active)?_c('div',{staticClass:"flv2-lf-dline"}):_vm._e(),(step.active)?_c('div',{attrs:{"id":"flv2-lf-scroll"}}):_vm._e()])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }