<template>
<div class="aml-page">
    
    <vap-form-divider :left="65" :right="35">
        <template v-slot:left>

            <PersonItem v-for="(person, i) in persons.filter(person => person.selected == true)" :key="'vp' + i" :disabled="client.report.created"
            :person="person" :client="client" @action="onAction($event, person)" :singlePerson="flow.isPerson" :bottomSlot="$root.config.adverseMedia.enabled">
                <template v-slot:left>
                    <SlotIdentity
                        :person="person"
                        :disabled="client.report.created"
                        @form="startForm($event, person)"
                        @popup="showFormPopup($event, person)"
                        @reset="showResetIDCDialog($event, person)"
                        @reminder="showReminderDialog(person)"
                        @preview="showIDCReceipt(person)"
                        @manual="showManualDialog(person)"
                        @info="showIdentityInfoDialog(person)"
                        @merge="showMergeIdentityDialog(person)"
                    />
                </template>
                <template v-slot:right>
                    <SlotScreening
                        :person="person"
                        :disabled="client.report.created"
                        @screen="showScreeningDialog"
                        @refresh="showScreeningDialog($event, true)"
                        @reset="showResetScreeningDialog($event, person)"
                        @preview="showScreeningReceipt(person)"
                        @info="showScreeningInfoDialog(person)"
                        @falsepositive="showFalsePositiveDialog(person)"
                        @hit-info="showMonitorHitDialog"
                    />
                </template>
                <template v-slot:bottom v-if="$root.config.adverseMedia.enabled">
                    <SlotWeb
                        :person="person"
                        :disabled="client.report.created"
                        @screen="showAdverseMediaDialog"
                        @refresh="showAdverseMediaDialog($event, true)"
                        @reset="showResetAmsDialog($event, person)"
                        @info="showAdverseMediaDialog(person)"
                    />
                </template>
            </PersonItem>

            <EmptyIcon :icon="'people'" :text="__('key_roles_description')" v-if="isSelectedListEmpty()" />

        </template>
        <template v-slot:right>

            <ActionV1 v-if="$root.config.persons.selectContact"
                :icon="'person'"
                :text="__('persons_contact')"
                :hint="__('persons_contact_hint')"
                :disabled="client.report.created"
                :warning="!hasContact"
                @clicked="selectContactDialogVisible = true"
            />

            <ActionV1 v-if="!flow.hideAddManual"
                :icon="'add'"
                :text="__t('persons-add-manual')"
                :hint="__t('persons-add-manual-hint')"
                :disabled="client.report.created"
                @clicked="showAddManualDialog('person')"
            />

            <MatchStatus v-if="!$root.config.persons.mergingDisabled" :loading="matchLoading" :result="matchResult" @dialog="showMatchDialog" @refresh="getSimilarPersons(true)" />

            <PersonRoleList :persons="persons" @addkeyrole="onAddKeyRole" @removerole="showRemoveRoleDialog" :disabled="client.report.created" @info="goToInfoPage" />

        </template>
    </vap-form-divider>

    <KeyRoleDialog v-if="keyRoleDialogVisible" :params="keyRoleDialogParams" :progress="progress" @add="onAddKeyRole" @remove="onRemoveKeyRole" @close="keyRoleDialogVisible = false" />
    <AddManualDialog v-if="addManualVisible" :params="addManualParams" :progress="progress" @person="onAddManualPerson" @role="onAddManualRole" @close="addManualVisible = false" />

    <ScreeningDialog v-if="screeningVisible" :params="screeningParams" :progress="progress" @screen="onPerformScreening" @close="screeningVisible = false" />

    <AdverseMediaDialog v-if="webVisible" :params="webParams" @saved="updateClient" @close="webVisible = false" />

    <!-- Reset screening dialog -->
    <PopupV1 v-if="resetScreeningDialog.show" :header="__t('screening-reset')" @close="resetScreeningDialog.show = false" :dialog="resetScreeningDialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                <!-- {{ __t('screening-hint-documents') + ':' }} -->
            </div>
            <DialogContent>
                <DialogContentHeader :text="__t('files-document').toUpperCase() + ':'" />
                <DialogContentItem :text="__t('screening-person')" :icon="'file-search'" />
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="resetScreeningDialog.show = false" :disabled="resetScreeningDialog.working" />
            <VFButton :text="__t('screening-reset')" :icon="'delete'" warning @clicked="resetScreening" :working="resetScreeningDialog.working" />
        </template>
    </PopupV1>

    <!-- Reset IDC dialog -->
    <ResetDialog v-if="resetIDCDialog.show" :params="resetIDCParams" isID @close="resetIDCDialog.show = false" @reload="updateClient" />

    <!-- Reset AMS dialog -->
    <ResetAmsDialog v-if="resetAmsVisible" :params="webParams" @reload="updateClient" @close="resetAmsVisible = false" />

    <!-- Remove manual role dialog -->
    <PopupV1 v-if="removeRoleDialog.show" :header="__t('persons-role-remove')" @close="removeRoleDialog.show = false" :dialog="removeRoleDialog">
        <template v-slot:content>
            <div style="margin-bottom: 10px; color: var(--text-title);">
                {{ __t('persons-role-remove-hint') + ':' }}
            </div>
            <DialogContent>
                <DialogContentHeader :text="__t('common-person').toUpperCase() + ':'" />
                <DialogContentItem :text="removeRoleDialog.person.displayName" :icon="'person'" />
                <DialogContentHeader :text="__t('common-role').toUpperCase() + ':'" />
                <DialogContentItem :text="__(removeRoleDialog.roleId)" :icon="'delete'" />
            </DialogContent>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="removeRoleDialog.show = false" :disabled="removeRoleDialog.working" />
            <VFButton :text="__t('persons-role-remove')" :icon="'delete'" warning @clicked="onRemoveManualRole" :working="removeRoleDialog.working" />
        </template>
    </PopupV1>

    <!-- Reminder dialog -->
    <ReminderDialog v-if="reminderDialogVisible" isID
        :params="reminderDialogParams"
        :client="client"
        @close="reminderDialogVisible = false"
        @reload="updateClient"
    />

    <!-- Manual complete dialog -->
    <ManuaIIdcDialog v-if="manualDialogVisible"
        :params="manualDialogParams"
        @close="manualDialogVisible = false"
        @reload="updateClient"
    />

    <!-- Match dialog -->
    <MatchDialog v-if="matchDialogVisible"
        :params="matchDialogParams"
        :clientId="client._id"
        @close="matchDialogVisible = false"
        @merge="onMergeCompleted"
    />

    <!-- Continuous Screening dialog -->
    <ContinuousScreeningDialog v-if="cscreenDialogVisible"
        :params="cscreenDialogParams"
        @close="cscreenDialogVisible = false"
        @reload="updateClient"
    />

    <!-- Screening info dialog -->
    <ScreeningInfoDialog v-if="screeningInfoDialogVisible"
        :params="screeningInfoDialogParams"
        @close="screeningInfoDialogVisible = false"
    />

    <!-- ID info dialog -->
    <IdentityInfoDialog v-if="idInfoDialogVisible"
        :params="idInfoDialogParams"
        @close="idInfoDialogVisible = false"
    />

    <!-- False positive dialog -->
    <FalsePositiveDialog v-if="falsePositiveDialogVisible"
        :params="falsePositiveDialogParams"
        @close="falsePositiveDialogVisible = false"
        @reload="updateClient"
    />

    <!-- Merge identity dialog -->
    <MergeIdentityDialog v-if="mergeIdentityDialogVisible"
        :params="mergeIdentityDialogParams"
        @close="mergeIdentityDialogVisible = false"
        @reload="updateClient"
    />

    <!-- Select contact dialog -->
    <SelectContactDialog v-if="selectContactDialogVisible"
        :client="client"
        :persons="persons"
        @close="selectContactDialogVisible = false"
        @reload="updateClient"
    />

    <!-- Monitor hit dialog -->
    <MonitorHitDialog v-if="hitDialog.show"
        :params="hitDialog"
        @close="hitDialog.show = false"
    />

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import screeningHelper from '../v1persons/screeningHelper';
import previewHelper from '../v1files/previewHelper';
import verified from '../verified';

import EmptyIcon from '../v1ui/EmptyIcon.vue';
import ActionV1 from '../v1ui/ActionV1.vue';

import PersonItem from '../v1persons/PersonItem.vue';
import PersonRoleList from '../v1persons/PersonRoleList.vue';

import SlotScreening from '../v1persons/SlotScreening.vue';
import SlotIdentity from '../v1persons/SlotIdentity.vue';
import SlotWeb from '../v1persons/SlotWeb.vue';

import KeyRoleDialog from '../v1persons/KeyRoleDialog.vue';
import AddManualDialog from '../v1persons/AddManualDialog.vue';
import ScreeningDialog from '../v1persons/ScreeningDialog.vue';
import ReminderDialog from '../v1ui/ReminderDialog.vue';
import ResetDialog from '../v1ui/ResetDialog.vue';
import ManuaIIdcDialog from '../v1persons/ManuaIIdcDialog.vue';
import AdverseMediaDialog from '../v1persons/AdverseMediaDialog.vue';
import ResetAmsDialog from '../v1persons/ResetAmsDialog.vue';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';

import MatchStatus from '../v1persons/MatchStatus.vue';
import MatchDialog from '../v1persons/MatchDialog.vue';

import ContinuousScreeningDialog from '../v1monitor/MonitorDialog.vue';
import ScreeningInfoDialog from '../v1persons/ScreeningInfoDialog.vue';
import IdentityInfoDialog from '../v1persons/IdentityInfoDialog.vue';
import FalsePositiveDialog from '../v1persons/FalsePositiveDialog.vue';
import MergeIdentityDialog from '../v1persons/MergeIdentityDialog.vue';
import SelectContactDialog from '../v1persons/SelectContactDialog.vue';
import v1personHelper from '../v1helpers/v1personHelper';

import MonitorHitDialog from '../v1monitor/MonitorHitDialog.vue';

export default {
    name: 'PersonsV1',
    components: {
        EmptyIcon,
        ActionV1,
        PersonItem,
        PersonRoleList,
        SlotScreening,
        SlotIdentity,
        SlotWeb,
        KeyRoleDialog,
        AddManualDialog,
        ScreeningDialog,
        ReminderDialog,
        ResetDialog,
        ManuaIIdcDialog,
        AdverseMediaDialog,
        ResetAmsDialog,
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        MatchStatus,
        MatchDialog,
        ContinuousScreeningDialog,
        ScreeningInfoDialog,
        IdentityInfoDialog,
        FalsePositiveDialog,
        MergeIdentityDialog,
        SelectContactDialog,
        MonitorHitDialog,
    },
    props: {
        client: { type: Object, default: null },
        flow: { type: Object, default: null },
        persons: { type: Array, default: null }
    },
    data() {
        return {
            addManualVisible: false,
            addManualParams: null,

            screeningVisible: false,
            screeningParams: null,

            webVisible: false,
            webParams: null,
            resetAmsVisible: false,

            keyRoleDialogVisible: false,
            keyRoleDialogParams: null,

            reminderDialogVisible: false,
            reminderDialogParams: {},

            manualDialogVisible: false,
            manualDialogParams: {},

            cscreenDialogVisible: false,
            cscreenDialogParams: {},

            screeningInfoDialogVisible: false,
            screeningInfoDialogParams: {},

            idInfoDialogVisible: false,
            idInfoDialogParams: {},

            falsePositiveDialogVisible: false,
            falsePositiveDialogParams: {},

            mergeIdentityDialogVisible: false,
            mergeIdentityDialogParams: {},

            selectContactDialogVisible: false,

            hitDialog: { show: false, person: null },

            progress: { working: false, status: '' },

            removeRoleDialog: { show: false, working: false, status: '', person: null, roleId: '' },

            resetScreeningDialog: { show: false, working: false, status: '', personId: '' },
            resetIDCDialog: { show: false },
            resetIDCParams: null,

            matchLoading: false,
            matchResult: null,
            matchDialogVisible: false,
            matchDialogParams: {},

            hasContact: false,
        }
    },
    methods: {
        
        isSelectedListEmpty() {
            for (const i in this.persons) {
                if (this.persons[i].selected) return false;
            }
            return true;
        },


        showCScreenDialog(person) {
            if (!person.screening.done) {
                this.showScreeningDialog(person, false);
                return;
            }
            this.cscreenDialogParams.mode = 'person';
            this.cscreenDialogParams.person = person;
            this.cscreenDialogParams.clientId = this.client._id;
            this.cscreenDialogVisible = true;
        },

        showMonitorHitDialog(person) {
            this.hitDialog.person = person;
            this.hitDialog.show = true;
        },


        //#region Screening ---------------------------------------------------------------------------------------------------

        showScreeningDialog(person, shouldReset) {
            this.screeningParams = { person, shouldReset: shouldReset || false };
            this.screeningVisible = true;
        },
        async onPerformScreening(useSSN, monitorIds) {
            this.progress.working = true;

            if (this.screeningParams.shouldReset) {
                this.progress.status = this.__t('screening-prog-resetting');
                await v1api.resetScreening(this.screeningParams.person.personId);
            }

            if (monitorIds && monitorIds.length > 0) {
                this.progress.status = this.__t('monitor-prog-adding');
                await v1api.enableContinuousScreeningPerson(this.screeningParams.person.personId, monitorIds);
            }

            await screeningHelper.performScreening(this.$root, this.client, this.screeningParams.person, useSSN, this.progress);

            await this.updateClient();
            this.progress.working = false;
            this.screeningVisible = false;
        },

        showResetScreeningDialog(event, person) {
            this.resetScreeningDialog.personId = person.personId;
            this.resetScreeningDialog.show = true;
        },
        async resetScreening() {
            this.resetScreeningDialog.working = true;

            await v1api.resetScreening(this.resetScreeningDialog.personId);

            await this.updateClient();
            this.resetScreeningDialog.working = false;
            this.resetScreeningDialog.show = false;
        },

        showScreeningReceipt(person) {
            const previewParams = previewHelper.getScreeningReceiptParams(this.$root, person);
            this.$emit('preview', previewParams);
        },

        showFalsePositiveDialog(person) {
            this.falsePositiveDialogParams.clientId = this.client._id;
            this.falsePositiveDialogParams.person = person;
            this.falsePositiveDialogVisible = true;
        },

        showScreeningInfoDialog(person) {
            this.screeningInfoDialogParams.screening = person.screening;
            this.screeningInfoDialogVisible = true;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        showIdentityInfoDialog(person) {
            this.idInfoDialogParams.person = person;
            this.idInfoDialogVisible = true;
        },

        showMergeIdentityDialog(person) {
            this.mergeIdentityDialogParams.clientId = this.client._id;
            this.mergeIdentityDialogParams.person = person;
            this.mergeIdentityDialogVisible = true;
        },


        //#region Adverse Media (Web) Search ----------------------------------------------------------------------------------

        showAdverseMediaDialog(person, shouldReset) {
            this.webParams = { name: person.displayName, web: person.web, id: person.personId, clientId: this.client._id, shouldReset: shouldReset || false };
            this.webVisible = true;
        },

        showResetAmsDialog(event, person) {
            this.webParams = { name: person.displayName, web: person.web, id: person.personId, clientId: this.client._id };
            this.resetAmsVisible = true;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Key roles ---------------------------------------------------------------------------------------------------

        async onAddKeyRole(person, perform) {
            if (!perform) {
                this.keyRoleDialogParams = { person: person, action: 'add' };
                this.keyRoleDialogVisible = true;
            }else {
                this.progress.working = true;
                await v1api.addKeyRoleToClient(this.client._id, person.personId);
                await this.updateClient();
                this.progress.working = false;
                this.keyRoleDialogVisible = false;
            }
        },

        async onRemoveKeyRole(person, perform) {
            if (!perform) {
                this.keyRoleDialogParams = { person: person, action: 'remove' };
                this.keyRoleDialogVisible = true;
            }else {
                this.progress.working = true;
                await v1api.removeKeyRoleFromClient(this.client._id, person.personId);
                await this.updateClient();
                this.progress.working = false;
                this.keyRoleDialogVisible = false;
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Manual roles ------------------------------------------------------------------------------------------------

        showAddManualDialog(action, person) {
            this.addManualParams = {
                action: action,
                person: person
            };
            this.addManualVisible = true;
        },

        async onAddManualPerson(model) {
            this.progress.working = true;
            const roleData = {
                firstName: model.firstName,
                lastName: model.lastName,
                dateOfBirth: model.dateOfBirth,
                roleId: model.role,
                share: ''
            };
            await v1api.addManualPerson(this.client._id, roleData);
            await this.updateClient();
            this.progress.working = false;
            this.addManualVisible = false;
        },

        async onAddManualRole(model, person) {
            this.progress.working = true;
            const roleData = {
                firstName: model.firstName,
                lastName: model.lastName,
                dateOfBirth: model.dateOfBirth,
                roleId: model.role,
                share: ''
            };
            await v1api.addManualRole(this.client._id, person.personId, roleData);
            await this.updateClient();
            this.progress.working = false;
            this.addManualVisible = false;
        },

        showRemoveRoleDialog(person, roleId) {
            this.removeRoleDialog.person = person;
            this.removeRoleDialog.roleId = roleId;
            this.removeRoleDialog.show = true;
        },

        async onRemoveManualRole() {
            this.removeRoleDialog.working = true;

            await v1api.removeManualRole(this.client._id, this.removeRoleDialog.person.personId, this.removeRoleDialog.roleId);

            await this.updateClient();

            this.removeRoleDialog.working = false;
            this.removeRoleDialog.show = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Actions -----------------------------------------------------------------------------------------------------

        onAction(action, person) {
            if (action.action == 'remove') {

                this.onRemoveKeyRole(person);

            }else if (action.action == 'add-role') {

                this.showAddManualDialog('role', person);

            }else if (action.action == 'info') {
                
                this.goToInfoPage(person);

            }else if (action.action == 'cscreen') {
                
                this.showCScreenDialog(person);

            }
        },

        goToInfoPage(person) {
            this.$parent.$parent.navigate({ path: '/v1person', query: { id: person.personId } });
        },

        startForm(key, person) {
            const extraData = {
                person: person
            };
            this.$emit('form', key, {}, extraData);
        },

        showFormPopup(key, person) {
            const extraData = {
                person: person
            };
            this.$emit('form', key, { inPopup: true }, extraData);
        },

        showResetIDCDialog(event, person) {
            this.resetIDCParams = { personId: person.personId, envelopeId: person.idc.envelopeId, clientId: this.client._id };
            this.resetIDCDialog.show = true;
        },

        

        showReminderDialog(person) {
            this.reminderDialogParams = {
                envelopeId: person.idc.envelopeId,
                person: person
            };
            this.reminderDialogVisible = true;
        },

        showManualDialog(person) {
            this.manualDialogParams = {
                person: person,
                clientId: this.client._id
            };
            this.manualDialogVisible = true;
        },

        showIDCReceipt(person) {
            const previewParams = previewHelper.getIDCReceiptParams(this.$root, person);
            this.$emit('preview', previewParams);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        async updateClient(response) {
            if (!response) response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);
        },

        async getSimilarPersons(force) {
            this.matchLoading = true;
            if (!this.$parent.similarResponse || force) {
                this.$parent.similarResponse = await v1api.getSimilarPersons(this.client._id);
            }
            this.matchResult = this.$parent.similarResponse.comparison;
            this.matchLoading = false;
        },

        showMatchDialog(person, index) {
            this.matchDialogParams = {
                person: person,
                index: index
            };
            this.matchDialogVisible = true;
        },

        onMergeCompleted(response) {
            this.$emit('reload', response);
        },

    },
    async created() {
        for (const i in this.persons) {
            if (v1personHelper.hasRole(this.persons[i], 'role_customer_contact')) this.hasContact = true;
        }
        if (!this.$root.config.persons.mergingDisabled) this.getSimilarPersons();
    }
}
</script>