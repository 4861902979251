<template>
<div>

    <PropertyItem :header="'Country Selector properties'" :icon="'input-text'">
        <vap-row>
            <vap-group c4>
                <vap-label :text="'Model key'" desc />
                <vap-input v-model="input.modelKey" noValidation :placeholder="'Not set'" />
            </vap-group>
            <vap-group c4>
                <vap-label :text="'Form key'" desc />
                <vap-input v-model="input.oldFormKey" noValidation :placeholder="'Not set'" />
            </vap-group>
            <vap-group c4>
                <vap-label :text="'Indexed'" desc />
                <vap-checkbox :text="'Indexed'" v-model="input.dbIndex" bordered borderOffset noValidation />
            </vap-group>
            <vap-group c12>
                <vap-label :text="'Tooltip'" desc />
                <vap-input v-model="input.tooltip" noValidation />
            </vap-group>
        </vap-row>
    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';

export default {
    name: 'CountrySelectorProperties',
    components: {
        PropertyItem
    },
    props: {
        input: { type: Object, default: null },
    },
    data() {
        return {
            inputTypeOptions: [
                { value: 'text', title: 'Free text' },
                { value: 'number', title: 'Number' },
                { value: 'email', title: 'Email address' },
                { value: 'percentage', title: 'Percentage' }
            ],
        }
    },
    methods: {
        
        

    },
    mounted() {

    }
}
</script>
<style scoped>



</style>
