<template>
<div class="aml-page">
    
    <vap-form-divider :left="25" :right="75" style="margin-top: 10px;">
        <template v-slot:left>

            <!-- Filters -->
            <vap-label :text="__('filters')" header2 style="margin-top: 10px; margin-bottom: 10px;" />

            <ActionV1
                :icon="'add'"
                :text="'New account V1.5'"
                :hint="'Show the wizard for enabling the AML portal for a new Verified account'"
                @clicked="onAddClickedV1"
            />

            <!-- Search -->
            <SearchBox ref="fltSear" @search="onSearch" :placeholder="'Search accounts'" style="margin-top: 10px; margin-bottom: 16px;" />

            <RemoveFilterButton v-if="hasActiveFilters()" @remove="removeAllFilters" />

            <div>
                <FilterControl empty :header="__('sort_last_updated')" />
                <div style="float: left; width: 50%; margin-top: 10px;">
                    <SortDatePicker v-model="sortDateFrom" noHighlight noValidation :hintKey="'datepicker_from'" :placeholder="__('datepicker_select_date')" outputISO @input="onDateChanged" />
                </div>
                <div style="float: left; width: 50%; margin-top: 10px;">
                    <SortDatePicker v-model="sortDateTo" noHighlight noValidation :hintKey="'datepicker_to'" :placeholder="__('datepicker_select_date')" right outputISO @input="onDateChanged" />
                </div>
            </div>

            <div v-if="filters">

                <div v-for="(filter, i) in possibleFilters" :key="'cpf' + i">
                    <FilterControlV1
                        :params="filter"
                        :filters="filters[filter.processedKey]"
                        :texts="texts[filter.textKey]"
                        :activeValues="activeFilters[filter.key]"
                        @filter="onFilterChanged"
                    />
                </div>

            </div>

        </template>
        <template v-slot:right>

            <div style="display: flex;">
                <vap-label :text="'Accounts'" header2 style="flex-grow: 100; margin-top: 10px; margin-bottom: 10px;" />
                <SortSelector v-model="sortModel" :options="sortOptions" style="flex-shrink: 0; margin-top: 18px;" />
            </div>

            <div v-if="!loading" class="flw100">

                <vap-card compactList noPadding v-for="(client, i) in clients" :key="'c' + i">
                    <ClientItem
                        :icon="client.type == 'person' ? 'person' : 'company'"
                        :name="client.name"
                        :info="client.key + ' - ' + client.companyId"
                        :date="formatDate('datetime', sortModel == 'date_created_desc' ? client.dateCreated : client.dateUpdated)"
                        :user="getUserName(client.userCreated)"
                        :link="'/backoffice-account?id=' + client._id"
                        :newItem="false"
                    />
                </vap-card>

            </div>

            <CenteredSpinner v-if="loading" />
            <EmptyIcon v-if="!loading && clients.length == 0" :icon="'company'" :text="__('customer_no_hits')" />

            <PageControl v-if="!loading" :total="totalPages" :current="currentPage" @page="onNavigate" />

        </template>
    </vap-form-divider>

    <BoAddAccountDialog v-if="addDialogVisible" @close="addDialogVisible = false" @reload="queryClients" />

</div>
</template>
<script>
import v1backoffice from '../v1admin/v1backoffice';

import ClientItem from '../components/items/ClientItem.vue';
import FilterControlV1 from '../v1ui/FilterControlV1.vue';
import RemoveFilterButton from '../v1ui/RemoveFilterButton.vue';
import PageControl from '../components/special/PageControl';
import SearchBox from '../components/special/SearchBox';
import SortSelector from '../components/special/SortSelector';
import SortDatePicker from '../components/special/SortDatePicker';
import EmptyIcon from '../v1ui/EmptyIcon.vue';
import CenteredSpinner from '../v1ui/CenteredSpinner.vue';
import FilterControl from '../components/special/FilterControl';

import ActionV1 from '../v1ui/ActionV1.vue';
import BoAddAccountDialog from '../v1admin/BoAddAccountDialog.vue';

export default {
    name: 'BoAccounts',
    components: {
        ClientItem,
        FilterControlV1,
        RemoveFilterButton,
        PageControl,
        SearchBox,
        SortSelector,
        SortDatePicker,
        EmptyIcon,
        CenteredSpinner,
        FilterControl,
        ActionV1,
        BoAddAccountDialog,
    },
    props: {
        admins: { type: Array, default: () => { return [] } },
    },
    data() {
        return {
            loading: true,

            itemsPerPage: 10,
            currentPage: 0,
            totalPages: 0,
            totalCount: 0,

            clients: [],

            letters: [],

            sortModel: 'date_updated_desc',
            sortOptions: [
                { key: 'sort_last_updated', value: 'date_updated_desc' },
                { key: 'sort_last_created', value: 'date_created_desc' },
                { key: 'sort_name', value: 'name_desc' },
            ],

            filters: null,
            possibleFilters: [],
            activeFilters: {},
            letterFilter: [],

            sortDateFrom: '',
            sortDateTo: '',
            filterDateFrom: null,
            filterDateTo: null,
            filterDateActive: false,

            texts: {
                status: {},
                version: {},
                userCreated: {},
            },

            addDialogVisible: false,
        }
    },
    watch: {
        sortModel: {
            deep: false,
            handler(newVal, oldVal) {
                if (this.loading) return;
                this.queryClients();
            }
        }
    },
    methods: {
        
        onAddClickedV1() {
            this.addDialogVisible = true;
        },

        getUserName(id) {
            return this.texts.userCreated[id].en_EN;
        },

        onNavigate(page) {
            this.currentPage = page;
            this.queryClients();
        },

        //#region Filters -----------------------------------------------------------------------------------------------------

        onSearch(str) {
            this.searchString = str;
            this.currentPage = 1;
            this.queryClients();
        },

        onDateChanged() {
            if (this.sortDateFrom && this.sortDateTo) {
                this.filterDateFrom = new Date(this.sortDateFrom);
                this.filterDateTo = new Date(this.sortDateTo + 'T23:59:59.000Z');
                this.filterDateActive = true;
                this.currentPage = 1;
                this.queryClients();
            }
        },

        onFilterChanged(filter, value) {
            for (const i in this.activeFilters) {
                if (i == filter.key) {
                    let match = false;
                    for (const j in this.activeFilters[i]) {
                        if (this.activeFilters[i][j] == value) {
                            this.activeFilters[i].splice(j, 1);
                            match = true;
                        }
                    }
                    if (!match) this.activeFilters[i].push(value);
                }
            }
            this.currentPage = 1;
            this.queryClients();
        },

        hasActiveFilters() {
            if (this.searchString) return true;
            if (this.filterDateActive) return true;
            if (this.letterFilter.length > 0) return true;
            for (const i in this.activeFilters) {
                if (this.activeFilters[i].length > 0) return true;
            }
            return false;
        },

        removeAllFilters() {
            this.filterDateActive = false;
            this.sortDateFrom = '';
            this.sortDateTo = '';
            this.searchString = '';
            this.letterFilter = [];
            for (const i in this.activeFilters) {
                this.activeFilters[i] = [];
            }
            this.currentPage = 1;
            this.queryClients();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        getFilters() {
            const filters = [];

            if (this.searchString) {
                filters.push({ field: 'name', op: 'search', value: this.searchString });
            }
            if (this.filterDateActive) {
                filters.push({ field: 'dateCreated', op: 'daterange', from: this.filterDateFrom, to: this.filterDateTo });
            }
            for (const key in this.activeFilters) {
                if (this.activeFilters[key].length > 0) {
                    for (const i in this.possibleFilters) {
                        if (this.possibleFilters[i].key == key) {
                            filters.push({ field: this.possibleFilters[i].processedKey, op: 'in', values: this.activeFilters[key] });
                        }
                    } 
                }
            }
            return filters;
        },

        async queryClients() {
            this.loading = true;

            let sort = { dateUpdated: -1 };
            if (this.sortModel == 'date_created_desc') sort = { dateCreated: -1 };
            if (this.sortModel == 'name_desc') sort = { name: 1 };

            const filters = this.getFilters();
            const from = (Number(this.currentPage) - 1) * Number(this.itemsPerPage);
            const limit = this.itemsPerPage;
            const include = [];
            const counts = [];
            for (const i in this.possibleFilters) {
                if (this.possibleFilters[i].formKey) {
                    counts.push(this.possibleFilters[i].formKey + '.fields.' + this.possibleFilters[i].key);
                }else {
                    counts.push(this.possibleFilters[i].key);
                }
            }

            const response = await v1backoffice.queryAccounts(filters, from, limit, sort, include, counts);

            this.totalCount = response.count;
            this.totalPages = Math.ceil(Number(this.totalCount) / Number(this.itemsPerPage));

            this.clients = response.clients;
            this.filters = response.filters;

            // Sort filters
            /* if (this.filters.status) this.filters.status.sort((a, b) => (a._id > b._id) ? 1 : -1); */
            if (this.filters.userCreated) this.filters.userCreated.sort((a, b) => (a._id > b._id) ? 1 : -1);

            this.loading = false;
        },

        addFilter(key, title) {
            const filter = {
                key: key,
                enabled: true,
                search: false,
                limit: 0,
                key: key,
                processedKey: key,
                textKey: key,
                title: { en_EN: title, nb_NO: title, sv_SE: title },
                formKey: '',
            };
            this.possibleFilters.push(filter);
            this.$set(this.activeFilters, filter.key, []);
        },

        addText(textKey, key, value) {
            const text = {};
            for (const j in this.$root.config.locales) {
                if (this.$root.config.locales[j].enabled) {
                    text[this.$root.config.locales[j].key] = value;
                }
            }
            this.texts[textKey][key] = text;
        },

    },
    created() {

        // Load texts
        for (const i in this.admins) {
            this.addText('userCreated', this.admins[i].uid, this.admins[i].firstName + ' ' + this.admins[i].lastName);
        }
        this.addText('status', 'demo', 'Demo');
        this.addText('status', 'dev', 'Development');
        this.addText('status', 'live', 'Active');
        this.addText('status', 'gone', 'Deactivated');

        this.addText('version', 'v0', '1.0');
        this.addText('version', 'v1', '1.5');

        this.addFilter('status', 'Account Status');
        this.addFilter('version', 'Portal Version');
        this.addFilter('userCreated', 'User');

        console.log('texts', this.texts);

        this.currentPage = 1;
        this.queryClients();
    }
}
</script>