<template>
    <div class="frv2-stp" id="frv2stp" v-if="currentStep"
        :style="'top: ' + getTop() + 'px; width: ' + getWidth() + '; margin-left: ' + getMargin() + ';'"
        :class="[
            { 'frv2-stp-designer': designer },
            { 'frv2-stp-designerview': designerView },
            { 'frv2-stp-scrolled': scrolled },
            { 'frv2-stp-dphone': designerPhoneMode },
        ]"
        v-on:scroll.passive="handleScroll"
    >

        <div v-if="topSlotHeight > 0" class="flw100" :style="'height: ' + topSlotHeight + 'px;'">
            <slot name="top" />
        </div>

        <div class="frv2-stp-content flw100" v-if="!$root.phoneMode" v-show="currentStep.showStepper || designer">

            <!-- Title -->
            <div class="frv2-stp-title">
                <div class="frv2-stp-title-head lbl-nowrap" v-if="currentStep">
                    <label>{{ currentStep.title[$root.locale.key] || 'Step title' }}</label>
                </div>
                <div class="frv2-stp-title-sub">
                    <label>{{ title }}</label>
                </div>
            </div>

            <!-- Steps -->
            <div class="frv2-stp-steps" v-if="!hideActions">
            
                <div class="frv2-stp-steps-center">
            
                    <div class="frv2-stp-step frv2-stp-step nohl" v-for="(step, i) in steps" :key="step.key"
                        :class="[
                            { 'frv2-step-disabled': i > currentIndex },
                            { 'frv2-step-passed': i < currentIndex },
                            { 'frv2-step-active': i == currentIndex },
                            { 'frv2-step-invalid': step.validation && step.validation.done && !step.validation.valid },
                        ]"
                    >
                        <div class="frv2-step-circle frv2-step-circle-n"
                            @click="handleStepClick(step, i)"
                            @mouseenter="handleMouseEnter($event, step)"
                            @mouseleave="handleMouseLeave($event, step)"
                            @mouseup="handleOptionMouseUp(step)">
                            <label>{{ (i + 1) }}</label>

                            <div class="frv2-step-tt">
                                <vap-tooltip :text="step.title[$root.locale.key]" />
                            </div>

                        </div>

                        <!-- Designer select handle -->
                        <!-- <div class="frv2-step-handle" v-if="designer && currentIndex == i" @click="handlePropertiesClicked(step)">         
                            <vap-icon :icon="'menu-h'" small absolute :top="-9" :right="16" :color="'ext'" />
                        </div> -->
                        <div v-if="designer" :id="'pl-' + step.key" style="position: absolute; width: 20px; height: 20px; top: 12px; left: 15px; pointer-events: none;" />

                    </div>

                    <!-- Designer add button -->
                    <div class="frv2-stp-step frv2-step-icon" v-if="designer && ($root.config.forms.editor.advanced || $root.credentials.isAdmin)">
                        <div class="frv2-step-circle frv2-step-add" @click="handleAddClick">
                            <vap-icon :icon="'add'" small absolute :top="7" :right="24" :color="'ext'" />
                        </div>
                    </div>
                    <!-- Designer paste button -->
                    <div class="frv2-stp-step frv2-step-icon" v-if="designer && ($root.config.forms.editor.advanced || $root.credentials.isAdmin) && clipboard.hasPage">
                        <div class="frv2-step-circle frv2-step-add" @click="handlePasteClick">
                            <vap-icon :icon="'paste'" small absolute :top="7" :right="24" :color="'ext'" />
                        </div>
                    </div>

                </div>

            </div>

            <!-- Buttons -->
            <div class="frv2-stp-btns nohl" v-if="!hideActions && !viewOnly">
                <div class="frv2-stp-btn btn-next" @click="handleButtonClick('next')" :class="{'btn-disabled': currentIndex == steps.length - 1 && !formValid}">
                    {{ currentIndex == steps.length - 1 ? getSubmitText() : nextText[$root.locale.key] }}
                </div>
                <div class="frv2-stp-btn btn-prev" @click="handleButtonClick('prev')">
                    {{ previousText[$root.locale.key] }}
                </div>
                <div v-if="canSave" style="padding: 10px; margin-top: 5px;">
                    <VFButton :text="__t('form-autosave-button')" :icon="'save'" :disabled="!hasChanges" :working="isSaving" @clicked="onSaveClicked">
                        <template v-slot:tooltip>
                            <vap-tooltip :text="__t('form-autosave-hint')" />
                        </template>
                    </VFButton>
                </div>
            </div>

        </div>

        <div class="frv2-pstp-content flw100" v-if="$root.phoneMode" v-show="currentStep.showStepper || designer">

            <div class="frv2-pstp-top">

                <!-- Title -->
                <div class="frv2-pstp-title">
                    <div class="frv2-sptp-title-head lbl-nowrap" v-if="currentStep">
                        <label>{{ currentStep.title[$root.locale.key] || 'Step title' }}</label>
                    </div>
                    <div class="frv2-pstp-title-sub">
                        <label>{{ title }}</label>
                    </div>
                </div>

                <!-- Buttons -->
                <div class="frv2-pstp-btns nohl" v-if="!hideActions && !viewOnly">
                    <div class="frv2-pstp-btn p-btn-next" @click="handleButtonClick('next')" :class="{'btn-disabled': currentIndex == steps.length - 1 && !formValid}">
                        <svg width="50" height="50" version="1.1" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.5); margin-top: -4px; margin-left: 0px;">
                            <path d="M 37.058594 12.980469 C 36.246094 12.949219 35.492188 13.414063 35.160156 14.15625 C 34.824219 14.898438 34.96875 15.765625 35.53125 16.359375 L 41.675781 23 L 3 23 C 2.277344 22.988281 1.609375 23.367188 1.246094 23.992188 C 0.878906 24.613281 0.878906 25.386719 1.246094 26.007813 C 1.609375 26.632813 2.277344 27.011719 3 27 L 41.675781 27 L 35.53125 33.640625 C 35.03125 34.164063 34.855469 34.914063 35.066406 35.605469 C 35.277344 36.296875 35.84375 36.820313 36.546875 36.976563 C 37.253906 37.132813 37.988281 36.894531 38.46875 36.359375 L 48.972656 25 L 38.46875 13.640625 C 38.109375 13.242188 37.597656 13 37.058594 12.980469 Z" />
                        </svg>
                    </div>
                    <div class="frv2-pstp-btn p-btn-prev" @click="handleButtonClick('prev')">
                        <svg width="50" height="50" version="1.1" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.5); margin-top: -4px; margin-left: 0px;">
                            <path d="M 13.082031 12.980469 C 12.492188 12.960938 11.925781 13.203125 11.53125 13.640625 L 1.023438 25 L 11.53125 36.359375 C 12.011719 36.894531 12.746094 37.132813 13.453125 36.976563 C 14.15625 36.820313 14.722656 36.296875 14.933594 35.605469 C 15.144531 34.914063 14.96875 34.164063 14.46875 33.640625 L 8.324219 27 L 47 27 C 47.722656 27.011719 48.390625 26.632813 48.753906 26.007813 C 49.121094 25.386719 49.121094 24.613281 48.753906 23.992188 C 48.390625 23.367188 47.722656 22.988281 47 23 L 8.324219 23 L 14.46875 16.359375 C 15.011719 15.785156 15.167969 14.949219 14.867188 14.21875 C 14.570313 13.492188 13.871094 13.003906 13.082031 12.980469 Z" />
                        </svg>
                    </div>
                </div>

            </div>

            <!-- Steps -->
            <div class="frv2-pstp-steps" v-if="!hideActions">
                <div class="frv2-pstp-step nohl" v-for="(step, i) in steps" :key="step.key" :style="'width: ' + stepWidth + '%;'"
                    :class="[
                        { 'frv2-step-first': i == 0 },
                        { 'frv2-step-last': i == steps.length - 1 },
                        { 'frv2-pstep-disabled': i > currentIndex },
                        { 'frv2-pstep-passed': i < currentIndex },
                        { 'frv2-pstep-active': i == currentIndex },
                        { 'frv2-pstep-invalid': step.validation && step.validation.done && !step.validation.valid },
                    ]"
                >
                    <div class="frv2-step-flat"
                        @click="handleStepClick(step, i)"
                        @mouseenter="handleMouseEnter($event, step)"
                        @mouseleave="handleMouseLeave($event, step)"
                        @mouseup="handleOptionMouseUp(step)">
                    </div>
                </div>
            </div>

        </div>

        <div v-if="bottomSlotHeight > 0" class="flw100" :style="'height: ' + bottomSlotHeight + 'px;'">
            <slot name="bottom" />
        </div>

<!--         <div class="frv2-stp-message nohl" v-if="currentIndex == steps.length - 1 && !formValid" :class="{'frv2-stp-message-des': designer }">
            {{ 'Please fill out all required fields' }}
        </div> -->

    </div>
</template>

<script>
import formHelper from './formHelper';

import VFButton from './items/VFButton.vue';

export default {
    name: 'FormStepperV2',
    components: {
        VFButton
    },
    props: {
        title: { type: String, default: 'Form title' },
        steps: { type: Array, default: () => { return [] }},
        stepKey: { type: String, default: '' },
        designer: { type: Boolean, default: false },
        designerView: { type: Boolean, default: false },
        designerPhoneMode: { type: Boolean, default: false },
        designerWidth: { type: Number, default: 0 },
        offset: { type: Number, default: 0 },
        topSlotHeight: { type: Number, default: 0 },
        bottomSlotHeight: { type: Number, default: 0 },
        hideActions: { type: Boolean, default: false },
        formValid: { type: Boolean, default: false },
        logicActive: { type: Boolean, default: false },
        canSave: { type: Boolean, default: false },
        hasChanges: { type: Boolean, default: false },
        isSaving: { type: Boolean, default: false },
        viewOnly: { type: Boolean, default: false },
        clipboard: { type: Object, default: () => { return {} }},
    },
    computed: {
        currentStep: function () {
            for (const i in this.steps) {
                if (this.steps[i].key == this.stepKey) return this.steps[i];
            }
            return this.steps[0];
        },
        currentIndex: function () {
            for (const i in this.steps) {
                if (this.steps[i].key == this.stepKey) return i;
            }
            return 0;
        },
        stepWidth: function () {
            return 100 / this.steps.length;
        }
    },
    data() {
        return {
            scrolled: false,
            submitText: {
                en_EN: 'Submit',
                nb_NO: 'Send inn',
                sv_SE: 'Skicka in'
            },
            nextText: {
                en_EN: 'Next',
                nb_NO: 'Neste',
                sv_SE: 'Nästa'
            },
            previousText: {
                en_EN: 'Previous',
                nb_NO: 'Forrige',
                sv_SE: 'Tillbaka'
            }
        }
    },
    methods: {

        getWidth() {
            if (this.designerPhoneMode) {
                const totalMargin = window.innerWidth - this.designerWidth - 400;
                return ((this.designer || this.designerView) ? 'calc(100% - ' + (this.designerWidth + totalMargin) + 'px)' : '100%');
            }
            return ((this.designer || this.designerView) ? 'calc(100% - ' + this.designerWidth + 'px)' : '100%');
        },

        getMargin() {
            if (this.designerPhoneMode) {
                const totalMargin = window.innerWidth - this.designerWidth - 400;
                return (totalMargin / 2) + 'px';
            }
            return '0px';
        },

        getTop() {
            if (this.designerPhoneMode) return '180';
            return (this.scrolled ? 0 : this.offset);
        },

        getSubmitText() {
            //console.log('current',this.currentStep )
            if (this.currentStep.submitText) {
                return this.currentStep.submitText[this.$root.locale.key];
            }else {
                return this.submitText[this.$root.locale.key];
            }
        },
        
        handleScroll(event) {
            let doc = document.documentElement;
            let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
            if (top > this.offset && !this.scrolled) {
                this.scrolled = true;
                this.$emit('scrolled', this.scrolled);
            }
            if (top <= this.offset && this.scrolled) {
                this.scrolled = false;
                this.$emit('scrolled', this.scrolled);
            }
        },

        handleStepClick(step, index) {
            if (this.logicActive) return;
            let direction = 'na';
            if (index < this.currentIndex) direction = 'prev';
            if (index > this.currentIndex) direction = 'next';
            const data = {
                key: step.key,
                index: Number(step.index),
                direction: direction
            }
            this.$emit('keychanged', data);
            this.hideTooltipX();
        },

        handleButtonClick(direction) {
            if (this.logicActive) return;
            let index = Number(this.currentIndex) + 1;
            if (direction == 'prev') index = Number(this.currentIndex) - 1;
            if (index < 0) return;
            if (index >= this.steps.length) {
                if (!this.formValid) return;
                this.$emit('submit');
                return;
            }
            const key = this.steps[index].key;
            const nIndex = this.steps[index].index;
            const data = {
                key: key,
                index: nIndex,
                direction: direction
            }
            this.$emit('keychanged', data);
        },

        handleAddClick() {
            if (this.logicActive) return;
            this.$emit('add');
        },

        handlePasteClick() {
            if (this.logicActive) return;
            this.$emit('paste');
        },

        handlePropertiesClicked(step) {
            if (this.logicActive) return;
            this.$emit('edit', step.key);
        },

        onSaveClicked() {
            this.$emit('save');
        },

        //#region Designer / Logic --------------------------------------------------------------------------------------------

        handleMouseEnter(e, step) {
            this.$emit('connect-enter', step);
        },

        handleMouseLeave(e, step) {
            this.$emit('connect-leave', step);
        },

        handleOptionMouseUp(step) {
            if (this.logicActive) {
                this.$emit('logic-connect', step.key);
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

    },
    async mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>
<style scoped>

.frv2-stp {
    position: absolute;
    left: 0px; right: 0px;
    box-shadow: 0 4px 2px -2px rgba(166, 177, 198, 0.3);
    z-index: 9;
}

.frv2-stp-dphone {
    
}

.frv2-stp-scrolled {
    position: fixed;
    top: 0px;
}
.frv2-stp-designer {
    position: fixed;
    top: 60px;
}
.frv2-stp-designerview {
    position: fixed;
    top: 60px;
}

.frv2-stp-content {
    height: 72px;
    display: flex;
    background-color: var(--stepper-bg);
    /* box-shadow: 1px 1px 2px rgba(166, 177, 198, 0.3); */
}

/* Phone */

.frv2-pstp-content {
    height: 72px;
    background-color: var(--stepper-bg);
    box-shadow: 1px 1px 2px rgba(166, 177, 198, 0.3);
}
.frv2-pstp-top {
    
}

.frv2-pstp-title {
    flex-grow: 100;
}
.frv2-sptp-title-head {
    margin-top: 5px;
    margin-left: 8px;
    padding-right: 120px;
    font-size: 16px;
    font-family: "Roboto Slab", serif;
    color: var(--text-title);
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}
.frv2-pstp-title-sub {
    margin-left: 8px;
    font-size: 12px;
    padding-right: 120px;
    font-family: "Roboto Slab", serif;
    color: var(--text-weak);
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}

.frv2-pstp-btns {
    position: absolute;
    top: 0px; right: 8px;
    display: flex;
    flex-direction: row-reverse;
    box-sizing: border-box;
}
.frv2-pstp-btn {
    width: 50px;
    height: 40px;
    margin-top: 5px;
    background-color: var(--button);
    box-sizing: border-box;
    cursor: pointer;
    fill: var(--button-text);
}
.p-btn-next {
    margin-left: 1px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.p-btn-prev {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.frv2-pstp-steps {
    float: left;
    width: 100%;
    margin-top: 3px;
    padding: 4px 8px;
    box-sizing: border-box;
}
.frv2-pstp-step {
    float: left;
    height: 16px;
    border: solid 1px var(--input-border);
    border-right: none;
    box-sizing: border-box;
}
.frv2-step-first {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.frv2-step-last {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: solid 1px var(--input-border);
}

.frv2-pstep-disabled.frv2-pstp-step {
    background: var(--disabled-background);
}
.frv2-pstep-passed.frv2-pstp-step {
    background-color: var(--primary);
}
.frv2-pstep-active.frv2-pstp-step {
    background-color: var(--primary);
    opacity: 0.7;
}

.frv2-pstep-invalid.frv2-pstep-active.frv2-pstp-step {
    background-color: var(--error-background);
    opacity: 0.7;
}
.frv2-pstep-invalid.frv2-pstep-passed.frv2-pstp-step {
    background: var(--error-background);
}
.frv2-pstep-invalid.frv2-pstep-disabled.frv2-pstp-step {
    color: var(--error-background);
}


/* Title */

.frv2-stp-title {
    width: 350px;
    box-sizing: border-box;
}

.frv2-stp-title-head {
    margin-top: 11px;
    margin-left: 24px;
    font-size: 22px;
    font-family: "Roboto Slab", serif;
    color: var(--text-title);
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}
.frv2-stp-title-sub {
    margin-left: 24px;
    font-size: 14px;
    font-family: "Roboto Slab", serif;
    color: var(--text-weak);
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: nowrap;
}


/* Buttons */

.frv2-stp-btns {
    width: 350px;
    display: flex;
    padding-right: 20px;
    flex-direction: row-reverse;
    box-sizing: border-box;
}

.frv2-stp-btn {
    min-width: 110px;
    height: 40px;
    margin-top: 15px;
    padding-top: 11px;
    text-align: center;
    color: var(--button-text);
    background-color: var(--button);
    box-sizing: border-box;
    cursor: pointer;
}

.btn-prev {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}
.btn-next {
    margin-left: 1px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.btn-disabled {
    color: var(--disabled-text);
    background-color: var(--disabled-background);
    cursor: not-allowed;
}

.frv2-stp-message {
    position: absolute;
    padding: 5px 10px;
    top: 64px;
    right: 30px;
    width: fit-content;
    color: var(--val-error);
    border: solid 2px var(--val-error);
    border-radius: 6px;
    background-color: var(--card-background);
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.2);
}
.frv2-stp-message:after {
    content: "";
    position: absolute;
    top: -10px;
    right: 32px;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 10px solid red;
    border-color: transparent transparent var(--val-error) var(--val-error);
    transform-origin: 0 -20;
    transform: rotate(135deg);
    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.4);
}
.frv2-stp-message-des {
    top: 124px;
}


/* Steps */

.frv2-stp-steps {
    flex-grow: 100;
    padding-top: 14px;
    text-align: center;
}

.frv2-stp-designer .frv2-stp-steps {
    /* padding-top: 6px; */
}

.frv2-stp-steps-center {
    display: inline-flex;
}

.frv2-stp-step {
    position: relative;
}

.frv2-step-circle {
    width: 42px; height: 42px;
    margin: 0px 4px;
    padding-top: 10px;
    text-align: center;
    border-radius: 50%;;
    background-color: red;
    box-sizing: border-box;
    cursor: pointer;
}

.frv2-step-circle label {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.frv2-step-icon {
    position: relative;
}

.frv2-step-disabled .frv2-step-circle {
    color: var(--disabled-text);
    background: var(--disabled-background);
}
.frv2-step-passed .frv2-step-circle {
    color: var(--primary-contrast);
    background: var(--primary);
}
.frv2-step-active .frv2-step-circle {
    padding-top: 7px;
    color: var(--primary);
    border: solid 2px var(--primary);
    background: var(--stepper-background);
}
.logic .frv2-step-circle-n:hover {
    border: solid 3px #0DCC27;
    color: #0DCC27;
}

.frv2-step-invalid.frv2-step-active .frv2-step-circle {
    color: var(--error-background);
    border: solid 2px var(--error-background);
}
.frv2-step-invalid.frv2-step-passed .frv2-step-circle {
    background: var(--error-background);
}
.frv2-step-invalid.frv2-step-disabled .frv2-step-circle {
    color: var(--error-background);
}

.frv2-step-add {
    padding-top: 7px;
    color: var(--primary);
    fill: var(--disabled-background);
    border: dotted 2px var(--disabled-background);
    background: var(--stepper-background);
}
.frv2-step-add:hover {
    border-color: #238CCC;
    fill: #238CCC;
}

.frv2-step-handle {
    position: relative;
    width: 37px;
    height: 14px;
    margin-top: 5px;
    margin-left: 7px;
    border-radius: 5px;
    border: 2px solid var(--input-border);
    box-sizing: border-box;
    cursor: pointer;
    fill: gray;
}
.frv2-step-handle:hover {
    background-color: var(--accent);
    fill: var(--accent-contrast);
}


.frv2-step-tt {
    position: absolute;
    top: 1px; right: 5px;
    bottom: 1px; left: 5px;
}

</style>
