const dataModelV1 = {

    //#region Company -----------------------------------------------------------------------------------------------------

    getEmptyCompany() {
        return {
            source: '',
            version: 0,
            lookup: false,
            simplified: false,
            name: '',
            orgnr: '',
            sanction: {
                available: false,
                hit: false,
                message: '',
                list: [],
                sources: [],
                results: [],
                summary: {
                    hits: 0,
                    matchIndicator: 0,
                    matchDescription: '',
                    title: '',
                    sources: []
                }
            },
            missingData: {
                hasMissing: false,
                list: []
            },
            info: {
                number: '',
                dunsNumber: '',
                country: '',
                dateRegistered: '',
                dateEstablished: '',
                purpose: '',
                language: '',
                employeesFrom: '',
                employeesTo: '',
                jurisdiction: ''
            },
            form: {
                formCode: '',
                formDescription: '',
                industryCode: '',
                industryDescription: ''
            },
            registration: {
                inVatRegistry: 'X',
                inBusinessRegistry: 'X',
                inBrreg: 'X',
                inVoluntaryRegistry: 'X',
                bankrupt: 'X',
                liquidation: 'X',
                compulsoryLiquidation: 'X',
                insolvency: 'X',
                charges: 'X',
                canFile: 'X',
                officeInDispute: 'X',
                undeliverableAddress: 'X',
                employer: 'X',
                taxAssessment: 'X',
                active: 'X',
                status: '',
                displayValues: []
            },
            infoSources: [],
            signature: {
                available: false,
                description: [],
                options: [],
                conclusive: false,
                remarks: [],
                sources: []
            },
            procura: {
                available: false,
                description: [],
                options: [],
                conclusive: false,
                remarks: [],
                sources: [],
                list: [] // part of old model, not used afaik
            },
            address: {
                business: {
                    street: '',
                    zip: '',
                    city: '',
                    commune: '',
                    communeNumber: '',
                    country: '',
                    full: ''
                }
            },
            contact: {
                phone: '',
                website: ''
            },
            naceCodes: [],
            naceSources: [],
            roles: [],
            shareholders: [],
            beneficials: [],
            controlEntities: [],
            ownership: {
                keyIndicators: {
                    maxShareHolderDistance: 0,
                    numberOfShareholdersDirect: 0,
                    numberOfShareholdersLegal: 0,
                    numberOfShareholdersTotal: 0
                },
                knowledge: {
                    numberOfUnidentifiedShareholders: 0,
                    sumIdentifiedShareholders: 0,
                    sumUnidentifiedShareholders: 0,
                    sumUnknownShareholders: 0
                },
                selfOwnership: {
                    companySelfOwnership: 0,
                    numberOfSelfOwnedShareholders: 0
                },
                votingPower: {
                    numberOfHighVpLowIntegrated: 0,
                    numberOfShareClasses: 0,
                    numberOfShareholdersManyShareClasses: 0,
                    shareHoldersHaveMultipleShareClasses: 'X'
                },
                naceCodes: [],
                sources: [],
                beneficialsAvailable: 'X',
                beneficialsSuspicious: 'X',
                indicatorsAvailable: false
            },
            subsidiaries: [],
            subsidiariesAvailable: false,
            previousNames: [],
            economy: {
                accountant: {
                    name: '',
                    orgnr: ''
                },
                auditor: {
                    name: '',
                    orgnr: ''
                },
                currency: '',
                basicAvailable: false,
                yearAvailable: false,
                trendAvailable: false,
                year0: this.getEmptyEconomyYear(),
                year1: this.getEmptyEconomyYear(),
                year2: this.getEmptyEconomyYear()
            }
        };
    },

    getEmptyPerson() {
        return {
            source: '',
            version: 0,
            done: false,
            date: new Date(),
            userId: '',
            envelopeId: '',
            searchParams: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                ssn: '',
                countryOfSsn: '',
                parameters: [],
                mode: ''
            },
            name: '',
            firstName: '',
            lastName: '',
            ssn: '',
            countryOfSsn: '',
            dateOfBirth: '',
            birthplace: {
                available: false,
                country: '',
                city: ''
            },
            gender: '',
            citizenships: [],
            addressPostal: this.getEmptyPersonAddress(),
            addressNational: this.getEmptyPersonAddress(),
            historicAddresses: [],
            historicNames: [],
            falsePositive: false,
            isPep: false,
            hasSanction: false,
            pepHits: [],
            pepSummary: {
                hits: 0,
                matchIndicator: 0,
                matchDescription: '',
                title: '',
                sources: []
            },
            sanctionHits: [],
            sanctionSummary: {
                hits: 0,
                matchIndicator: 0,
                matchDescription: '',
                title: '',
                sources: []
            },
            roles: {
                available: false,
                companies: [],
                sources: []
            },
            sources: []
        };
    },

    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Mapping -----------------------------------------------------------------------------------------------------


    //#endregion ----------------------------------------------------------------------------------------------------------


    //#region Helpers -----------------------------------------------------------------------------------------------------

    getEmptyPersonAddress() {
        return {
            available: false,
            country: '',
            street: '',
            zip: '',
            city: '',
            registeredDate: '',
            residents: 0,
            houseType: '',
            source: ''
        };
    },

    getEmptyCitizenship() {
        return {
            country: ''
        };
    },

    getEmptyPepHit() {
        return {
            source: '',
            provider: '',
            matchIndicator: 0,
            matchDescription: '',
            nameMatchValue: 0,
            name: '',
            title: '',
            comment: ''
        };
    },

    getEmptySanctionHit() {
        return {
            source: '',
            matchIndicator: 0,
            matchDescription: '',
            nameMatchValue: 0,
            name: '',
            title: '',
            comment: ''
        };
    },

    getEmptyPersonRole() {
        return {
            name: '',
            orgnr: '',
            roleId: '',
            from: '',
            to: ''
        };
    },

    getEmptyBeneficial() {
        return {
            identified: false,
            name: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            country: '',
            street: '',
            zip: '',
            city: '',
            citizenship: '',
            ssn: '',
            shareType: '',
            shareRange: '',
            share: '',
            personId: '',
            integratedShare: '',
            reason: '',
            reasonPhrase: ''
        };
    },

    getEmptySubsidiary() {
        return {
            identified: false,
            name: '',
            orgnr: '',
            dunsNumber: '',
            country: '',
        };
    },

    getEmptyControlEntity() {
        return {
            identified: false,
            type: 'X',
            name: '',
            orgnr: '',
            registeredIn: '',
            registeredCountry: '',
            registeredAuthority: '',
            formCode: '',
            formDescription: '',
            country: '',
            street: '',
            zip: '',
            city: '',
            citizenship: '',
            shareType: '',
            shareRange: '',
            share: '',
            controlTypes: []
        };
    },

    getEmptyShareholder() {
        return {
            identified: false,
            type: 'X',
            name: '',
            orgnr: '',
            ssn: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            country: '',
            street: '',
            zip: '',
            city: '',
            share: '',
            personId: ''
        };
    },

    getEmptyRole() {
        return {
            roleId: '',
            firstName: '',
            lastName: '',
            dateOfBirth: '',
            ssn: '',
            country: '',
            address: '',
            street: '',
            zip: '',
            city: '',
            personId: ''
        };
    },

    getEmptyPreviousName() {
        return {
            name: '',
            from: '',
            to: ''
        };
    },

    getEmptyEconomyYear() {
        return {
            available: false,
            year: 0,
            salesRevenues: -1,
            otherRevenues: -1,
            totalRevenues: -1,
            costOfGoodsSold: -1,
            inventoryChanges: -1,
            salaryCosts: -1,
            financialCosts: -1,
            depreciations: -1,
            amortizations: -1,
            otherOperatingCosts: -1,
            operatingProfits: -1,
            ordinaryProfits: -1,
            extraordinaryRevenues: -1,
            extraordinaryCosts: -1,
            minorityInterests: -1,
            taxes: -1,
            resultBeforeTax: -1,
            netProfits: -1,
            currentAssets: -1,
            fixedAssets: -1,
            sumAssets: -1,
            equity: -1,
            debt: -1,
            sumEquityAndDebt: -1,
        }
    }

    //#endregion ----------------------------------------------------------------------------------------------------------

};
export default dataModelV1;