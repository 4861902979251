import log from '../../../../log';
import formHelper from '../../formHelper';
const kycComparison = {

    compareStuff(reference, original, pages) {
        const result = {
            changed: false,
            changes: []
        };
        log.x('Comparison: Comparing data with original', original);

        const compare = {
            role_ceo: false,
            role_chair: false,
            role_board_member: false,
            role_shareholder: false,
            role_rrh: false,
            role_deputy_bm: false,
        };

        const persons = [];

        for (const i in pages) {
            for (const j in pages[i].cards) {
                if (pages[i].cards[j].isPerson) {
                    if (pages[i].cards[j].type == 'list') {

                        if (pages[i].cards[j].kycKey == 'boardMembers' || pages[i].cards[j].kycKey == 'shareholders'
                        || pages[i].cards[j].kycKey == 'beneficials' || pages[i].cards[j].kycKey == 'deputyBoardMembers') {
                            compare[this.getRoleId(pages[i].cards[j].kycKey)] = true;
                        }

                        if (formHelper.isCardVisible(reference, pages[i].cards[j], false, pages, pages[i], reference.roleKey)) {

                            for (const k in pages[i].cards[j].model) {

                                const targetPerson = { roleId: this.getRoleId(pages[i].cards[j].kycKey) };
                                for (const key in pages[i].cards[j].model[k]) {
                                    if (this.isNameKey(key)) targetPerson[key] = pages[i].cards[j].model[k][key].toLowerCase();
                                    if (key == 'share') targetPerson[key] = pages[i].cards[j].model[k][key];
                                    if (key == 'name') targetPerson[key] = pages[i].cards[j].model[k][key].toLowerCase();
                                }
                                if (this.isValidPerson(targetPerson)) {
                                    persons.push(targetPerson);
                                }else if (targetPerson.roleId == 'role_shareholder') {
                                    persons.push({
                                        firstName: targetPerson.name,
                                        lastName: 'org',
                                        dateOfBirth: 'org',
                                        share: targetPerson.share,
                                        roleId: 'role_shareholder'
                                    });
                                }
    
                            }

                        }
                    }else {
                        
                        const targetPerson = { roleId: this.getRoleId(pages[i].cards[j].kycKey) };
                        for (const k in pages[i].cards[j].inputs) {
                            if (this.isNameKey(pages[i].cards[j].inputs[k].modelKey)) {
                                targetPerson[pages[i].cards[j].inputs[k].modelKey] = pages[i].cards[j].inputs[k].model.toLowerCase();
                            }
                        }
                        if (this.isValidPerson(targetPerson)) persons.push(targetPerson);
                        compare[targetPerson.roleId] = true;

                    }
                }
            }
        }

        /* console.log('persons', persons);
        console.log('originalPersons', original.persons);
        console.log('compare', compare); */

        for (const i in original.persons) {
            if (!compare[original.persons[i].roleId]) continue;
            let match = false;
            for (const j in persons) {
                if (this.isSamePersonAndRole(original.persons[i], persons[j])) {
                    match = true;
                    if (persons[j].roleId == 'role_rrh' || persons[j].roleId == 'role_shareholder') {
                        if (persons[j].share != original.persons[i].share) {
                            result.changes.push({
                                type: 'shr',
                                roleId: original.persons[i].roleId,
                                oShare: original.persons[i].share,
                                nShare: persons[j].share,
                                name: original.persons[i].firstName + ' ' + original.persons[i].lastName
                            });
                        }
                    }
                }
            }
            if (!match) {
                result.changes.push({
                    type: 'del',
                    roleId: original.persons[i].roleId,
                    name: original.persons[i].firstName + ' ' + original.persons[i].lastName,
                    oShare: original.persons[i].share,
                });
            }
        }
        for (const j in persons) {
            if (!compare[persons[j].roleId]) continue;
            let match = false;
            for (const i in original.persons) {
                if (this.isSamePersonAndRole(original.persons[i], persons[j])) match = true;
            }
            if (!match) {
                result.changes.push({
                    type: 'add',
                    roleId: persons[j].roleId,
                    name: persons[j].firstName + ' ' + persons[j].lastName,
                    nShare: persons[j].share,
                });
            }
        }

        if (result.changes.length > 0) result.changed = true;
        log.x('Comparison: Data was compared', result);
        return result;
    },

    isNameKey(key) {
        if (key == 'firstName' || key == 'lastName' || key == 'dateOfBirth') return true;
        return false;
    },

    isValidPerson(person) {
        if (person.firstName && person.lastName && person.dateOfBirth) return true;
        return false;
    },

    isSamePersonAndRole(person1, person2) {
        if (person1.firstName == person2.firstName
        && person1.lastName == person2.lastName
        && person1.dateOfBirth == person2.dateOfBirth
        && person1.roleId == person2.roleId) {
            return true;
        }
        return false;
    },

    getRoleId(kycKey) {
        switch (kycKey) {
            case 'ceo': return 'role_ceo';
            case 'chair': return 'role_chair';
            case 'boardMembers': return 'role_board_member';
            case 'shareholders': return 'role_shareholder';
            case 'beneficials': return 'role_rrh';
            case 'deputyBoardMembers': return 'role_deputy_bm';
        }
        return 'role_other';
    },

};
export default kycComparison;