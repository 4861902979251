<template>
<div>

    <PopupV1 :header="__t('verified-reminder-send')" @close="onClose" :dialog="dialog">
        <template v-slot:content>

            <!-- <DialogContent style="float: left; width: 100%; padding-top: 20px; padding-bottom: 10px;">
                <div>
                    <div>
                        {{ 'En automatisk påminnelse vil sendes:' }}
                    </div>
                    <div>
                        <div v-for="(reminder, i) in autoReminders" :key="'ar' + i">
                            {{ formatDate('format', reminder.datePerform) }}
                        </div>
                    </div>
                </div>
            </DialogContent> -->
            <DialogContent style="float: left; width: 100%; padding-top: 20px; padding-bottom: 10px; margin-top: 10px;">
                <vap-row>
                    <vap-group c6>
                        <vap-label desc required :text="__('common_first_name')" />
                        <vap-input
                            v-model="recipient.firstName"
                            :rules="'required'"
                            noHighlight
                            @input="validateDialog"
                            :placeholder="__('common_required')"
                            :disabled="loading"
                        />
                    </vap-group>
                    <vap-group c6>
                        <vap-label desc required :text="__('common_last_name')" />
                        <vap-input
                            v-model="recipient.lastName"
                            :rules="'required'"
                            noHighlight
                            @input="validateDialog"
                            :placeholder="__('common_required')"
                            :disabled="loading"
                        />
                    </vap-group>
                    <vap-group c12>
                        <vap-label desc required :text="__('common_email')" />
                        <vap-input
                            v-model="recipient.email"
                            :rules="'required|email'"
                            noHighlight
                            @input="validateDialog"
                            :placeholder="__('common_required')"
                            :disabled="loading"
                        />
                    </vap-group>
                </vap-row>
            </DialogContent>
            <div class="flw100" style="margin-top: 10px; color: var(--text-title);">
                {{ __t('verified-reminder-hint') }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="__t('verified-reminder-send')"
                :icon="'reminder'"
                @clicked="sendReminder"
                :working="dialog.working && !loading"
                :disabled="loading || !dialog.valid"
            />
        </template>
    </PopupV1>

</div>
</template>
<script>
import verified from '../verified';
import formValidation from '../components/form/formValidation';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import DialogContent from '../v1ui/DialogContent.vue';
import DialogContentHeader from '../v1ui/DialogContentHeader.vue';
import DialogContentItem from '../v1ui/DialogContentItem.vue';
import VFCheckbox from '../components/form/inputs/VFCheckbox.vue';
import v1api from '../v1helpers/v1api';

export default {
    name: 'ReminderDialog',
    components: {
        PopupV1,
        VFButton,
        DialogContent,
        DialogContentHeader,
        DialogContentItem,
        VFCheckbox
    },
    props: {
        params: { type: Object, default: null },
        client: { type: Object, default: null },
        isID: { type: Boolean, default: false },
    },
    data() {
        return {
            loading: true,
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            recipient: {
                firstName: '',
                lastName: '',
                email: '',
            },
            orginal: {
                firstName: '',
                lastName: '',
                email: '',
            },
            envelopeId: '',
            recipientId: '',
            changed: false,
            action: '',
            autoReminders: [],
        }
    },
    methods: {

        validateDialog() {
            const result = formValidation.validateEmail(this.recipient.email);
            this.dialog.valid = result.valid && this.recipient.firstName.length > 0 && this.recipient.lastName.length > 0;

            // Check if recipient was changed
            if (this.recipient.firstName != this.orginal.firstName 
            || this.recipient.lastName != this.orginal.lastName 
            || this.recipient.email != this.orginal.email) {
                this.changed = true;
            }else {
                this.changed = false;
            }

            if (!this.dialog.valid) {
                this.dialog.error = this.__t('validation-all');
            }else {
                this.dialog.error = '';
            }
        },

        async sendReminder() {
            this.dialog.working = true;
            this.dialog.status = 'Sending reminder';

            if (this.changed) {
                await verified.editRecipient(this.envelopeId, this.recipientId, this.recipient.firstName, this.recipient.lastName, this.recipient.email);
            }

            await verified.sendReminder(this.envelopeId, this.recipientId, 'message');

            if (this.isID) {
                await v1api.addIDCReminder(this.params.person.personId, this.params.envelopeId, this.recipient, this.action);
            }else {
                await v1api.addFormReminder(this.client._id, this.params.formKey, this.params.envelopeId, this.recipient, this.action);
            }

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.dialog.show = false;
            this.onClose();
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        this.loading = true;
        this.dialog.working = true;
        this.dialog.status = this.__t('verified-reminder-loading');

        const envelope = await this.$root.getEnvelope(this.params.envelopeId);
        this.envelopeId = envelope.id;

        for (const i in envelope.content.recipients) {
            if (!envelope.content.recipients[i].completed) {
                this.recipientId = envelope.content.recipients[i].id;
                this.orginal.firstName = envelope.content.recipients[i].givenName;
                this.orginal.lastName = envelope.content.recipients[i].familyName;
                this.orginal.email = envelope.content.recipients[i].email;
                this.action = envelope.content.recipients[i].role.action;
                break;
            }
        }

        // Get automatic reminders
        const filters = [
            { field: 'info.envelopeId', op: 'is', value: this.envelopeId },
        ];
        const sort = {};
        const from = 0;
        const limit = 100;
        const include = [];
        const counts = ['userId'];

        const result = await v1api.queryTasks(filters, from, limit, sort, include, counts);

        for (const i in result.tasks) {
            const task = result.tasks[i];
            if (task.type == 'form-reminder' && task.status == 'waiting') {
                this.autoReminders.push({
                    datePerform: task.datePerform,
                });
            }
        }

        console.log('auto', this.autoReminders);

        this.recipient.firstName = this.orginal.firstName;
        this.recipient.lastName = this.orginal.lastName;
        this.recipient.email = this.orginal.email;

        this.dialog.working = false;
        this.loading = false;
        this.validateDialog();
    }
}
</script>