<template>
<div>

    <div>

        <div class="frmod-form-hint">
            {{ 'This form will try to load data from the following forms:' }}
        </div>

        <div class="frmod-form-item" v-for="(item, i) in items" :key="'fmi' + i">
            <div class="frmod-form-item-key">
                {{ item }}
            </div>
            <div class="frmod-form-item-del" @click="onDeleteKey(i)">
                <vap-icon tiny :icon="'delete'" />
            </div>
        </div>

        <div class="frmod-form-add" @click="addDialog.show = true">
            <div class="frmod-form-add-btn">
                <vap-icon tiny :icon="'add'" />
            </div>
        </div>

    </div>

    <PopupV1 designer :header="'Add form key'" v-if="addDialog.show" :width="400" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>
            <VFInput v-model="addDialog.key" :placeholder="'F.ex. kyc00'" @onreturn="onAddFormKey" />
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Cancel'" outline @clicked="addDialog.show = false" />
            <VFButton :text="'Add form'" :icon="'add'" @clicked="onAddFormKey" :disabled="addDialog.key == ''" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import DesignerGroup from '../../designer/ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../../designer/ui/inputs/DesignerLabel.vue';
import DesignerCheckbox from '../../designer/ui/inputs/DesignerCheckbox.vue';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';
import VFInput from '../../inputs/VFInput.vue';

export default {
    name: "FormModuleBox",
    components: {
        DesignerGroup,
        DesignerLabel,
        DesignerCheckbox,

        PopupV1,
        VFButton,
        VFInput,
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() {
        return {
            addDialog: { show: false, key: '' },
            items: []
        }
    },
    methods: {

        onAddFormKey() {
            if (!this.addDialog.key) return;
            this.items.push(this.addDialog.key);
            this.addDialog.show = false;
        },

        onDeleteKey(index) {
            this.items.splice(index, 1);
        },

    },
    created() {
        for (const i in this.reference.modules) {
            if (this.reference.modules[i].type == 'form') {
                this.items = this.reference.modules[i].formKeys;
            }
        }
    }
}

</script>
<style scoped>

.frmod-form-hint {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 14px;
    color: var(--fb-text-1);
    font-style: italic;
}

.frmod-form-item {
    height: 28px;
    margin-top: 5px;
    display: flex;
    background-color: var(--fb-menu-c);
    box-sizing: border-box;
}

.frmod-form-item-key {
    flex-grow: 100;
    padding: 4px 10px;
    color: var(--fb-text-1);
}

.frmod-form-item-del {
    width: 28px;
    padding-top: 3px;
    padding-left: 2px;
    border-left: solid 1px var(--fb-menu-b);
    box-sizing: border-box;
    cursor: pointer;
}
.frmod-form-item-del:hover {
    background-color: var(--fb-menu-a);
}

.frmod-form-add {
    float: right;
    margin-top: 5px;
    background-color: var(--fb-menu-c);
    box-sizing: border-box;
}
.frmod-form-add-btn {
    width: 28px; height: 24px;
    padding-top: 1px;
    padding-left: 3px;
    box-sizing: border-box;
    cursor: pointer;
}
.frmod-form-add-btn:hover {
    background-color: var(--fb-menu-a);
}

</style>