<template>
<div class="frv2-menu">

    <div class="frv2-menu-nav">
        <div class="frv2-menu-nav-name">
            {{ companyName }}
        </div>
        <div class="frv2-menu-nav-links">
            <div class="frv2-menu-nav-back">
                <div class="frv2-menu-nav-back-i">
                    <vap-icon :icon="'chev-l'" tiny :color="'ext'" />
                </div>
                <div class="frv2-menu-nav-back-t" @click="onGoBack">
                    {{ 'GO BACK' }}
                </div>
            </div>
            <div class="frv2-menu-nav-link">
                <router-link :to="basePath + 'v1events'">{{ 'OVERVIEW' }}</router-link>
                <router-link :to="basePath + 'v1clients'">{{ 'CUSTOMERS' }}</router-link>
            </div>
        </div>
    </div>

    <div class="frv2-menu-right">

        <div class="frv2-menu-title">
            <div class="frv2-menu-title-text">
                <label>{{ 'form builder' }}</label>
            </div>
            <div class="frv2-menu-title-logo">
                <label>{{ 'POWERED BY' }}</label>
                <img :src="$root.images.vfblue" />
            </div>
        </div>

        <HeaderLanguageSelector designer :locales="reference.locales" />

        <DesignerButton style="margin-right: 20px;"
            :icon="'save'"
            :tooltip="'Save form'"
            :warning="hasChanges"
            :working="saving"
            @clicked="$emit('save')"
        />

        <DesignerButton v-if="!reference.temporaryDisable"
            :icon="'input-check'"
            :tooltip="'Check for problems'"
            @clicked="$emit('test')"
        />

        <DesignerButton style="margin-right: 30px;" v-if="!reference.temporaryDisable"
            :icon="'redo'"
            :tooltip="'Redo'"
            :disabled="!canRedo"
            @clicked="$emit('redo', -2)"
            :rightList="true">
            <div class="frv2-undo-items frv2-undo-items-rev">
                <div class="frv2-undo-item" v-for="(item, i) in reference.changeLog" :key="'mul' + i" @click="$emit('redo', i - 1)" v-show="i <= reference.changeLogIndex">
                    {{ i + ' - ' + item.display }}
                </div>
            </div>
        </DesignerButton>

        <DesignerButton v-if="!reference.temporaryDisable"
            :icon="'undo'"
            :tooltip="'Undo'"
            :disabled="!canUndo"
            @clicked="$emit('undo', -2)"
            :leftList="true">
            <div class="frv2-undo-items">
                <div class="frv2-undo-item" v-for="(item, i) in reference.changeLog" :key="'mul' + i" @click="$emit('undo', i)" v-show="i > reference.changeLogIndex">
                    {{ i + ' - ' + item.display }}
                </div>
            </div>
        </DesignerButton>

        <div class="frv2-menu-role" style="margin-right: 30px;">
            <vap-dropdown noValidation noHighlight
                v-model="reference.role"
                :options="roleOptions"
                :displayKey="'display'"
                :listKey="'display'"
                :outputKey="'key'"
                :input="$emit('rolechanged', reference.role)"
            />
        </div>

        <DesignerButton style="margin-right: 30px;"
            :toggle="true"
            :toggleModel="toggleModel"
            :toggleOptions="toggleOptions"
            @toggle="$emit('toggle', $event)"
        />

<!--         <DesignerButton style="margin-right: 30px;"
            :icon="'list'"
            :tooltip="'Display'"
            :leftList="true">

            <div class="frv2-undo-item" v-for="(toggle, key, i) in toggles" :key="'mul' + i">
                <DesignerCheckbox v-model="toggles[key]" :text="key" />
            </div>

            <div class="frv2-undo-item">
                <DesignerCheckbox v-model="$root.darkMode" :text="'Dark Mode'" @input="$parent.$parent.$parent.$parent.loadColors()" />
            </div>

        </DesignerButton> -->

    </div>

</div>
</template>

<script>
import FlagIcon from '../../../v1ui/FlagIcon.vue';
import DesignerButton from './ui/DesignerButton.vue';
import DesignerCheckbox from './ui/inputs/DesignerCheckbox.vue';
import HeaderLanguageSelector from '../../../v1ui/HeaderLanguageSelector.vue';

export default {
    name: 'FormMenuV2',
    components: {
        FlagIcon,
        DesignerButton,
        DesignerCheckbox,
        HeaderLanguageSelector,
    },
    props: {
        reference: { type: Object, default: null },
        designer: { type: Boolean, default: false },
        roleOptions: { type: Array, default: null },
        saving: { type: Boolean, default: false },
        phoneMode: { type: Boolean, default: false },
        hasChanges: { type: Boolean, default: false },
        canUndo: { type: Boolean, default: false },
        canRedo: { type: Boolean, default: false },
        toggleModel: { type: Object, default: null },
    },
    data() {
        return {
            basePath: '',
            companyName: '',
            toggles: {
                toggle1: true,
                toggle2: true,
                toggle3: false,
                toggle4: true,
            },
            toggleOptions: {
                view: { icon: 'eye', tooltip: 'View mode' },
                edit: { icon: 'edit', tooltip: 'Edit mode' },
                pdf: { icon: 'pdf', tooltip: 'PDF view' },
                email: { icon: 'alpha', tooltip: 'Emails' },
                landing: { icon: 'link', tooltip: 'Landing page' },
            },
        }
    },
    methods: {

        toggleStuff(key) { // deprecated?
            this.$parent.$parent.toggle[key] = !this.$parent.$parent.toggle[key];
        },

        onGoBack() {
            history.back();
        },

    },
    async created() {
        this.basePath = this.$parent.$parent.$parent.$parent.basePath;
        this.companyName = this.$parent.$parent.$parent.$parent.user.company;
    }
}
</script>
<style>

.frv2-menu {
    position: relative;
    display: flex;
    width: 100%;
    height: 62px;
    background-color: var(--fb-header);
    box-sizing: border-box;
    z-index: 1;
}


.frv2-menu-nav {
    flex-grow: 100;
    color: white;
    padding-top: 8px;
    padding-left: 18px;
}

.frv2-menu-nav-name {
    font-size: 18px;
    padding-left: 7px;
    font-family: "Roboto Slab", serif;
    letter-spacing: 1.5px;
    color: white;
}

.frv2-menu-nav-links {
    display: flex;
}

.frv2-menu-nav-back {
    display: flex;
    fill: white;
    color: white;
    cursor: pointer;
    opacity: 0.5;
}
.frv2-menu-nav-back:hover {
    color: #238CCC;
    fill: #238CCC;
    opacity: 1;
}
.frv2-menu-nav-back-i {
    margin-top: -0.6px;
}
.frv2-menu-nav-back-t {
    margin-top: 3px;
    font-size: 13px;
    font-weight: bold;
}

.frv2-menu-nav-link a {
    margin-left: 10px;
    text-decoration: none;
    color: white;
    font-size: 13px;
    font-weight: bold;
    opacity: 0.5;
}
.frv2-menu-nav-link a:hover {
    color: #238CCC;;
    opacity: 1;
}


.frv2-menu-right {
    flex-shrink: 0;
    display: flex;
    flex-direction: row-reverse;
}


.frv2-menu-role {
    width: 200px;
    margin-top: 11px;
    margin-right: 10px;
}
.frv2-menu-role .vap-dd-value.vip {
    height: 39px;
    border-radius: 0px;
    padding-top: 9px;
    color: var(--fb-input-txt);
    border-color: var(--fb-input-bg);
    background-color: var(--fb-input-bg);
}
.frv2-menu-role .vap-dd-value.vip:hover {
    background-color: var(--fb-input-bg);
}


.frv2-menu-title {
    padding-top: 7px;
    padding-left: 26px;
    padding-right: 20px;
}
.frv2-menu-title-text {
    font-size: 20px;
    font-family: "Roboto Slab", serif;
    letter-spacing: 1.5px;
    color: white;
}
.frv2-menu-title-logo {
    font-size: 13px;
    font-weight: bold;
    color: white;
}
.frv2-menu-title-logo label {
    float: left;
    margin-top: 2px;
    opacity: 0.5;
}
.frv2-menu-title-logo img {
    float: left;
    width: 50px;
    margin-top: 1px;
    margin-left: 6px;
}



.frv2-undo-items {
    display: flex;
    flex-direction: column;
}
.frv2-undo-items-rev {
    flex-direction: column-reverse;
}

.frv2-undo-item {
    padding: 4px 10px;
    font-size: 14px;
    color: white;
    border-bottom: solid 1px var(--fb-border);
    opacity: 0.9;
    cursor: pointer;
}
.frv2-undo-item:hover {
    background-color: var(--fb-menu-b);
}

</style>
