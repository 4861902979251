<template>
<div>

    <PropertyItem padded :header="'Line properties'" :icon="'help-o'">

        <DesignerGroup c12>
            <DesignerLabel :text="'Header'" />
            <DesignerInput v-model="helpline.header[$root.locale.key]" :placeholder="'Not set'" @before="$emit('propinput', 'text', 'hline_header', $event)" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerLabel :text="'Icon'" />
            <DesignerSelector :text="helpline.icon" @clicked="$emit('icon', helpline)" :tooltip="'Select icon'" @before="$emit('propinput', 'check', 'hline_icon', $event)" />
        </DesignerGroup>

        <DesignerGroup c12>
            <TextEditorV2 designer v-model="helpline.text[$root.locale.key]" :key="helpline.key" @before="$emit('propinput', 'text', 'hline_text', $event)" />
        </DesignerGroup>

    </PropertyItem>

</div>
</template>
<script>
import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerSelector from '../ui/inputs/DesignerSelector.vue';

import TextEditorV2 from '../../TextEditorV2.vue';

export default {
    name: 'HelpLineProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerSelector,
        TextEditorV2,
    },
    props: {
        helpline: { type: Object, default: null },
    },
    data() {
        return {
            
        }
    },
    methods: {

        

    },
    created() {
        console.log('line', this.helpline);
    }
}
</script>