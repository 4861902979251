<template>
<div>

    <PropertyHeader :header="'Form languages'">
        <template v-slot:actions>
            <DesignerButton small
                :icon="'add'"
                :tooltip="'Add language'"
                @clicked="addDialog.show = true"
            />
        </template>
    </PropertyHeader>

    <PropertyItem padded :header="'Languages'" :icon="'logic'">

        <div class="frv2-ll-item" v-for="(item, i) in reference.locales" :key="'ll' + i">

            <div class="frv2-ll-item-flag">
                <FlagIcon :alpha2="item.alpha2 || 'xx'" />
            </div>
            <div class="frv2-ll-item-check" @click="onLocaleChecked(item)">
                <CheckboxUI :checked="item.enabled" />
            </div>
            <div class="frv2-ll-item-text">
                {{ item.name }}
            </div>
            <div class="frv2-ll-item-delete" @click="showDeleteDialog(item)">
                <vap-icon tiny :icon="'delete'" />
            </div>

        </div>

    </PropertyItem>

    <PopupV1 designer noPadding :maxHeight="500" v-if="addDialog.show" :header="'Add language'" @close="addDialog.show = false" :dialog="addDialog">
        <template v-slot:content>

            <div class="frv2-ll-item-con-add" v-for="(locale, i) in available" :key="'al' + i" @click="onAddLocale(locale)">
                <div class="frv2-ll-item-flag">
                    <FlagIcon :alpha2="locale.alpha2 || 'xx'" />
                </div>
                <div class="frv2-ll-item-text">
                    {{ locale.name }}
                </div>
            </div>

        </template>
        <template v-slot:buttons>
            <VFButton :text="'Cancel'" outline
                @clicked="addDialog.show = false"
            />
        </template>
    </PopupV1>

    <PopupV1 designer noPadding :maxHeight="500" v-if="deleteDialog.show" :header="'Delete language'" @close="deleteDialog.show = false" :dialog="deleteDialog">
        <template v-slot:content>
            <div style="padding: 20px; color: white; opacity: 0.8;">
                {{ 'Are you sure you want to delete this language?' }}
            </div>
            <div style="padding: 20px; padding-top: 0px; color: white; font-style: italic; opacity: 0.6;">
                {{ 'All text/translations will be removed. If you just want to temporary disable the language, you can uncheck it instead.' }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Cancel'" outline
                @clicked="deleteDialog.show = false"
            />
            <VFButton :text="'Delete'" warning
                @clicked="onDeleteClicked"
            />
        </template>
    </PopupV1>
    
</div>
</template>
<script>
import textHelper from '../../textHelper';
import editorHelper from '../../editorHelper';

import PropertyHeader from '../ui/PropertyHeader.vue';
import PropertyItem from '../properties/PropertyItem.vue';
import DesignerButton from '../ui/DesignerButton.vue';

import CheckboxUI from '../ui/CheckboxUI.vue';
import FlagIcon from '../../../../v1ui/FlagIcon.vue';
import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';

export default {
    name: 'LanguagePropBox',
    components: {
        PropertyHeader,
        PropertyItem,
        DesignerButton,
        CheckboxUI,
        FlagIcon,
        PopupV1,
        VFButton,
    },
    props: {
        params: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            addDialog: { show: false },
            deleteDialog: { show: false, locale: null },
            available: [
                { key: 'en_EN', name: 'English', alpha2: 'gb' },
                { key: 'nb_NO', name: 'Norsk', alpha2: 'no' },
                { key: 'sv_SE', name: 'Svenska', alpha2: 'se' },
                { key: 'fi_FI', name: 'Suomi', alpha2: 'fi' },
                { key: 'da_DK', name: 'Dansk', alpha2: 'dk' },
                { key: 'de_DE', name: 'Deutsch', alpha2: 'de' },
                { key: 'fr_FR', name: 'Français', alpha2: 'fr' },
            ]
        }
    },
    methods: {
        
        onLocaleChecked(locale) {
            locale.enabled = !locale.enabled;
            editorHelper.onChange(this.reference, locale.enabled ? 'locale_enabled' : 'locale_disabled', false);
        },

        showDeleteDialog(locale) {
            this.deleteDialog.locale = locale;
            this.deleteDialog.show = true;
        },

        onDeleteClicked() {
            textHelper.addRemoveFormLocale(this.reference, false, this.deleteDialog.locale.key);
            for (const i in this.reference.locales) {
                if (this.reference.locales[i].key == this.deleteDialog.locale.key) {
                    this.reference.locales.splice(i, 1);
                    break;
                }
            }
            editorHelper.onChange(this.reference, 'locale_deleted', false);
            this.deleteDialog.show = false;
        },

        onAddLocale(locale) {
            for (const i in this.reference.locales) {
                if (this.reference.locales[i].key == locale.key) return;
            }
            
            this.reference.locales.push({
                key: locale.key,
                name: locale.name,
                enabled: true,
                alpha2: locale.alpha2,
            });

            textHelper.addRemoveFormLocale(this.reference, true, locale.key);

            editorHelper.onChange(this.reference, 'locale_added', false);
            this.addDialog.show = false;
        },

        onExport() {
            const texts = textHelper.getAllTexts(this.reference);
            // TODO add export function
        },

        onImport() {
            // TODO add import function
        },

        onClose() {
            this.$emit('close');
        },

    },
    created() {
        for (const i in this.reference.locales) {
            if (!this.reference.locales[i].alpha2) {
                if (this.reference.locales[i].key == 'en_EN') this.reference.locales[i].alpha2 = 'gb';
                if (this.reference.locales[i].key == 'nb_NO') this.reference.locales[i].alpha2 = 'no';
                if (this.reference.locales[i].key == 'sv_SE') this.reference.locales[i].alpha2 = 'se';
            }
        }
        console.log('pa', this.reference.locales);
    }
}
</script>
<style scoped>

.frv2-ll-item {
    display: flex;
    height: 28px;
    margin-top: 10px;
    background-color: var(--fb-menu-c);
}

.frv2-ll-item-flag {
    margin-left: 2px;
    margin-top: -2px;
}

.frv2-ll-item-check {
    margin-top: 2px;
    margin-left: 15px;
}

.frv2-ll-item-text {
    flex-grow: 100;
    margin-top: 5px;
    margin-left: 15px;
    color: white;
    opacity: 0.8;
}

.frv2-ll-item-delete {
    width: 30px;
    padding-top: 3px;
    padding-left: 4px;
    border-left: solid 1px var(--fb-menu-b);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-ll-item-delete:hover {
    background-color: var(--fb-menu-a);
}

.frv2-ll-item-con-add {
    display: flex;
    padding: 5px 20px;
    border-bottom: solid 1px var(--fb-menu-c);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-ll-item-con-add:hover {
    background-color: var(--fb-menu-a);
}

</style>