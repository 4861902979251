<template>
<div>

    <div class="amlrb">

        <div class="amlrb-top" :style="'height: ' + topOffset + 'px'">

        </div>

        <div class="amlrb-bar">

            <div class="amlrb-item" v-for="(item, i) in items" :key="'sbi' + i" :class="[
                { 'amlrb-item-sep': item.separation },
                { 'amlrb-item-valid': item.valid && item.commentValid }
            ]" @click="$emit('goto', item)">
                <vap-icon :icon="item.icon" :color="'ext'" />
            </div>

        </div>

    </div>

    <div class="amlrb-bottom" :class="{'amlrb-bottom-ok': allCompleted}">
        {{ statusText[$root.locale.key] }}
    </div>

    <div class="amlrb-btmc" />
    <div class="amlrb-corner" />
    <div class="amlrb-cright" />

    <div class="amlrb-save" @click="$emit('save')" :class="[
        {'amlrb-save-disabled': reportCreated || isSaving },
    ]">
        <vap-icon :icon="'save'" absolute :top="25" :right="25" :color="'ext'" v-if="!isSaving" />
        <div class="amlrb-save-spinner">
            <vap-spinner small v-if="isSaving" />
        </div>
    </div>

    <div class="amlrb-buttons">

        <div class="amlrb-button" @click="onPreviewClicked" :class="{'amlrb-button-disabled': !allCompleted || reportCreated}">
            <div class="amlrb-button-icon">
                <vap-icon :icon="'eye'" absolute :top="0" :right="0" :color="'ext'" />
            </div>
            {{ __t('report-preview') }}
        </div>

        <div class="amlrb-button" @click="onCreateClicked" :class="{'amlrb-button-disabled': !allCompleted || reportCreated}">
            <div class="amlrb-button-icon">
                <vap-icon :icon="'verified'" absolute :top="0" :right="0" :color="'ext'" />
            </div>
            {{ __t('report-create') }}
        </div>
        
    </div>

    <canvas id="amlrb-canvas"></canvas>

</div>
</template>
<script>


export default {
    name: 'ReportSideBar',
    components: {
        
    },
    props: {
        flow: { type: Object, default: null },
        items: { type: Array, default: null },
        statusText: { type: Object, default: null },
        allCompleted: { type: Boolean, default: false },
        reportCreated: { type: Boolean, default: false },
        isSaving: { type: Boolean, default: true },
    },
    data() {
        return {
            topOffset: 167
        }
    },
    methods: {
        
        onPreviewClicked() {
            if (!this.allCompleted || this.reportCreated) return;
            this.$emit('preview');
        },

        onCreateClicked() {
            if (!this.allCompleted || this.reportCreated) return;
            this.$emit('create');
        },

        handleScroll(event) {
            let doc = document.documentElement;
            let top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
            this.topOffset = 167 - top;
            if (this.topOffset < 63) this.topOffset = 63;
        },

    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
}
</script>
<style scoped>

.amlrb {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 80px;
    height: 100vh;
    z-index: 20;
    pointer-events: none;
}

/* .amlrb-top {
    
} */

.amlrb-bar {
    height: 100%;
    padding-top: 30px;
    border-left: solid 1px var(--input-border);
    background-color: var(--card-background);
}

.amlrb-bottom {
    position: fixed;
    bottom: 0px; left: 0px;
    right: 180px;
    height: 60px;
    padding-top: 17px;
    padding-left: 25px;
    font-size: 20px;
    color: var(--val-error);
    border-top: solid 1px var(--input-border);
    background-color: var(--card-background);
    box-sizing: border-box;
    z-index: 99;
}
.amlrb-bottom-ok {
    color: var(--accent);
}

.amlrb-btmc {
    position: fixed;
    bottom: 0px; right: 0px;
    width: 180px;
    height: 60px;
    background-color: var(--card-background);
    z-index: 99;
}

.amlrb-corner {
    position: fixed;
    bottom: 60px; right: 30px;
    width: 100px;
    height: 100px;
    border-left: solid 1px var(--input-border);
    background-color: var(--card-background);
    z-index: 99;
    -webkit-transform: skew(-45deg);
    transform: skew(-45deg);
}

.amlrb-cright {
    position: fixed;
    bottom: 50px; right: 0px;
    width: 70px;
    height: 100px;
    background-color: var(--card-background);
    z-index: 99;
}

.amlrb-save {
    position: fixed;
    bottom: 25px; right: 25px;
    width: 90px; height: 90px;
    border-radius: 45px;
    border: solid 2px var(--button);
    box-sizing: border-box;
    z-index: 99;
    cursor: pointer;
    fill: var(--button);
}
.amlrb-save:hover {
    color: var(--button-text);
    background-color: var(--button);
    fill: var(--button-text);
}
.amlrb-save-disabled {
    border: solid 2px var(--disabled-background);
    cursor: not-allowed;
    fill: var(--disabled-background);
}
.amlrb-save-disabled:hover {
    background-color: var(--card-background);
    fill: var(--disabled-background);
}

.amlrb-save-spinner {
    position: absolute;
    top: 52px;
    left: 25px;
}


.amlrb-buttons {
    position: fixed;
    display: flex;
    bottom: 12px; right: 185px;
    z-index: 99;
}
.amlrb-button {
    position: relative;
    height: 36px;
    margin-left: 10px;
    padding-top: 7px;
    padding-left: 54px;
    padding-right: 25px;
    font-weight: 500;
    border-radius: 4px;
    border: solid 2px var(--button);
    box-sizing: border-box;
    cursor: pointer;
    fill: var(--button);
}
.amlrb-button:hover {
    color: var(--button-text);
    background-color: var(--button);
    fill: var(--button-text);
}
.amlrb-button-disabled {
    color: var(--text-weak);
    border: solid 2px var(--disabled-background);
    cursor: not-allowed;
    fill: var(--disabled-background);
}
.amlrb-button-disabled:hover {
    color: var(--text-weak);
    background-color: var(--card-background);
    fill: var(--disabled-background);
}
.amlrb-button-icon {
    position: absolute;
    top: -2px; left: 15px;
    width: 30px; height: 30px;
}


.amlrb-item {
    width: 51px; height: 51px;
    margin-bottom: 10px;
    margin-left: 13px;
    border-radius: 50%;
    padding-top: 6px;
    padding-left: 6px;
    border: solid 2px var(--error-background);
    box-sizing: border-box;
    fill: var(--error-background);
    pointer-events: all;
    cursor: pointer;
}

.amlrb-item-sep {
    margin-top: 50px;
}

.amlrb-item-valid {
    border: solid 2px var(--accent);
    background-color: var(--accent);
    fill: var(--accent-contrast);
}


#amlrb-canvas {
    position: fixed;
    top: 0px; left: 0px;
    width: 100%;
    height: 100vh;
    opacity: 0.8;
    pointer-events: none;
    /* background-color: green; */
}

</style>