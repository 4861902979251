<template>
    <div class="aml-page">

        <vap-card list :header="title">

            <vap-file-upload v-if="!loaded" v-model="fileupload" anyFile :autoPreview="false" @input="onFileDetected" />

            <div v-if="loaded">

                <DataTable :columns="loadedColumns" :rows="previewRows" ref="datatable" />

                <div class="flw100" style="margin-top: 10px; padding-left: 10px;">
                    <label>{{ '+ ' + (loadedRows.length - previewLimit) + ' til' }}</label>
                </div>

            </div>

            <vap-row v-if="loaded" style="margin-top: 30px;">
                <vap-group c2>
                    <vap-label :text="'Skip'" desc />
                    <vap-input v-model="skip" />
                </vap-group>
                <vap-group c2>
                    <vap-label :text="'Limit'" desc />
                    <vap-input v-model="limit" />
                </vap-group>
                <vap-group c2>
                    <vap-button :text="buttonText" @clicked="onEmitData" style="margin-top: 20px;" />
                </vap-group>
            </vap-row>

        </vap-card>

    </div>
</template>
<script>
import log from '../../log';
import DataTable from './DataTable.vue';

export default {
    name: 'FileReaderView',
    components: {
        DataTable
    },
    props: {
        title: {
            type: String,
            default: 'Batch'
        },
        buttonText: {
            type: String,
            default: 'Load'
        },
        initalLimit: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            fileupload: {},
            loaded: false,
            loadedColumns: [],
            loadedRows: [],
            previewRows: [],
            skip: 0,
            limit: 1,
            previewLimit: 3
        }
    },
    watch: {
        items: {
            deep: false,
            handler(val) {
                
            }
        }
    },
    methods: {

        onFileDetected() {
            const self = this;
            const reader = new FileReader();
            reader.onload = function(e) {
                const contents = e.target.result;
                self.readFile(contents);
            };
            reader.readAsText(this.fileupload.files[0].data, 'ISO-8859-4');
        },

        readFile(data) {
            //console.log(data);
            const list = [];
            const lines = data.split(/\r\n|\n/);
            const def1 = lines[0].split(';');
            const def2 = lines[0].split(',');
            const comma = (def2.length > def1.length)
            const definition = comma ? def2 : def1;
            for (let i=1; i<lines.length; i++) {
                if (lines[i].length < 1) continue;
                const line = lines[i].split(comma ? ',' : ';');
                const item = {};
                for (const x in definition) item[definition[x]] = line[x];
                list.push(item);
            }
            this.processList(list);
        },

        processList(list) {
            this.loadedColumns = this.$parent.onGetColumns();
            //console.log('list', list);

            let count = 0;
            for (const i in list) {
                this.loadedRows.push(list[i]);
                if (count < this.previewLimit) this.previewRows.push(list[i]);
                count += 1;
            }

            this.loaded = true;
        },

        onEmitData() {
            const list = [];

            for (const i in this.loadedRows) {
                if (this.skip == 0 || Number(i) >= Number(this.skip)) {
                    if (Number(list.length) < Number(this.limit)) {
                        list.push(this.loadedRows[i]);
                    }
                }
            }
            this.$parent.onFileDataLoaded(list);
        }

    },
    created() {
        this.limit = this.initalLimit;
    }
}
</script>
<style scoped>


</style>