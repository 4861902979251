<template>
<div>

    <PopupV1 :header="'Remove tag'" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div>
                {{ 'Are you sure you want to remove this tag?' }}
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-close')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton :text="'Remove tag'" warning @clicked="removeTag" :disabled="dialog.working" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import v1api from '../v1helpers/v1api';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';

export default {
    name: 'TagDialog',
    components: {
        PopupV1,
        VFButton,
    },
    props: {
        params: { type: Object, default: null },
        client: { type: Object, default: null },
    },
    data() {
        return {
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
        }
    },
    methods: {

        async removeTag() {
            this.dialog.working = true;
            this.dialog.status = 'Removing tag';

            await v1api.removeTag(this.client._id, this.params.tag);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        },

    },
}
</script>
<style scoped>

</style>