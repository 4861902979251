<template>
<button class="vap-button" :class="[
        {'vap-button-rounded' : rounded},
        {'vap-button-outline' : outline},
        {'vap-button-action' : action},
        {'vap-button-disabled' : disabled},
        {'vap-button-dotdisabled' : dotDisabled},
        {'vap-button-working' : working}
    ]" @click="handleClick" :style="fixedWidth > 0 ? 'width: ' + fixedWidth + 'px;' : ''" >

    {{ uppercase ? text.toUpperCase() : text }}

    <div v-if="working" class="vap-button-spinner">
        <vap-spinner small />
    </div>

    <div class="vap-button-tt">
        <slot name="tooltip"></slot>
    </div>

</button>
</template>

<script>

export default {
    name: 'vap-button',
    components: {
        
    },
    props: {
        text: {
            type: String,
            default: 'Button'
        },
        outline: {
            type: Boolean,
            default: false
        },
        uppercase: {
            type: Boolean,
            default: false
        },
        action: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        dotDisabled: {
            type: Boolean,
            default: false
        },
        working: {
            type: Boolean,
            default: false
        },
        rounded: {
            type: Boolean,
            default: false
        },
        fixedWidth: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        handleClick() {
            if (this.disabled || this.dotDisabled || this.working) return;
            this.$emit('clicked', true);
        }
    },
    async created() {
        
    }
}
</script>

<style>

.vap-button {
    position: relative;
    border: none;
    outline: none;
    background: none;

    min-height: 36px;

    font-family: 'Signika', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: var(--button-text);
    text-transform: uppercase;

    padding: 0px 30px;

    border-radius: 5px;
    border: solid 2px var(--button);
    background-color: var(--button);
    cursor: pointer;
}
.vap-button:hover {
    opacity: 0.8;
}

.vap-button-rounded {
    border-radius: 18px;
}

.vap-button-working {
    padding-right: 40px;
    color: var(--button);
    background-color: var(--card-background);
    cursor: default;
}

.vap-button-outline {
    color: var(--button);
    background-color: var(--card-background);
}

.vap-button-action {
    min-width: 120px;
}

.vap-button-row-right .vap-button {
    margin-left: 10px;
}
.vap-button-row-center .vap-button {
    margin-left: 5px;
    margin-right: 5px;
}

.vap-button-disabled {
    color: var(--disabled-text);
    background-color: var(--disabled-background);
    cursor: default;
    border-color: var(--disabled-background);
}
.vap-button-disabled:hover {
    opacity: 1;
}

.vap-button-dotdisabled {
    color: gray;
    border: dotted 2px var(--text-disabled);
    background: none;
    cursor: default;
}
.vap-button-dotdisabled:hover {
    opacity: 1;
}

.vap-button-spinner {
    position: relative;
}

.vap-button-tt {
    position: absolute;
    top: 0px; bottom: 0px;
    left: 0px; right: 0px;
}

</style>
