<template>
<div class="vf-pdf-text" @click.stop="handleClick" :class="[
    { 'vf-pdf-selected' : selected }
]" v-html="cardData.header[$root.locale.key]">
    
</div>
</template>

<script>
export default {
    name: 'vf-pdf-text',
    props: {
        cardData: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false }
    },
    data() {
        return {
            logoUrl: ''
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

    },
}
</script>
<style scoped>

.vf-pdf-text {
    float: left;
    width: 100%;
}



/* Designer / Selection */

.vf-pdf-selected {
    outline: solid 2px #238ccc;
}

</style>
