<template>
<div :class="['vf-gr', widthString,
        { 'vf-gr-12': c12 },
        { 'vf-gr-9': c9 },
        { 'vf-gr-8': c8 },
        { 'vf-gr-6': c6 },
        { 'vf-gr-5': c5 },
        { 'vf-gr-4': c4 },
        { 'vf-gr-3': c3 },
        { 'vf-gr-2': c2 },
        { 'vf-gr-auto': auto },
        { 'vf-gr-np': noPadding },
        { 'vf-gr-designer': designer },
        { 'vf-gr-phone': phoneMode },
        { 'vf-gr-selected': selected },
        { 'vf-gr-disabled': disabled },
        { 'vf-gr-compact': compact },
    ]" @click.stop="handleClick" :id="id" @mouseover.stop>

    <slot></slot>
    
    <div v-if="validation.done && !validation.valid" class="vf-gr-er">
        <label>{{ getValMessage(validation) }}</label>
    </div>

    <div class="vf-gr-logic" :id="'l' + id" @click="handleLogicClick" @mousedown="handleLogicClick">
        <svg height="24" version="1.1" width="24" xmlns="http://www.w3.org/2000/svg" style="transform: scale(0.7);">
            <path d="M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 9 C 3 10.093063 3.9069372 11 5 11 L 9 11 C 10.093063 11 11 10.093063 11 9 L 11 5 C 11 3.9069372 10.093063 3 9 3 L 5 3 z M 5 5 L 9 5 L 9 9 L 5 9 L 5 5 z M 5 13 L 5 15 L 7 15 L 7 13 L 5 13 z M 15 13 C 13.906937 13 13 13.906937 13 15 L 13 19 C 13 20.093063 13.906937 21 15 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 15 C 21 13.906937 20.093063 13 19 13 L 15 13 z M 15 15 L 19 15 L 19 19 L 15 19 L 15 15 z M 5 17 L 5 19 L 7 19 L 7 17 L 5 17 z M 9 17 L 9 19 L 11 19 L 11 17 L 9 17 z"/>
        </svg>
    </div>
    
</div>
</template>
<script>
export default {
    name: "vf-group",
    props: {
        width: { type: String, default: ''},
        c12: { type: Boolean, default: false },
        c9: { type: Boolean, default: false },
        c8: { type: Boolean, default: false },
        c6: { type: Boolean, default: false },
        c5: { type: Boolean, default: false },
        c4: { type: Boolean, default: false },
        c3: { type: Boolean, default: false },
        c2: { type: Boolean, default: false },
        auto: { type: Boolean, default: false },
        noPadding: { type: Boolean, default: false },
        designer: { type: Boolean, default: false },
        phoneMode: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        compact: { type: Boolean, default: false },
        id: { type: String, default: 'id' },
        validation: { type: Object, default: () => { return { done: false } }}
    },
    data() {
        return {
            widthString: ''
        }
    },
    watch: {
        width: {
            deep: false,
            handler(val) {
                this.setWidth(val);
            }
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected', true);
        },

        setWidth(val) {
            if (val == '12') {
                this.widthString = 'vf-gr-12';
            }else if (val == '9') {
                this.widthString = 'vf-gr-9';
            }else if (val == '8') {
                this.widthString = 'vf-gr-8';
            }else if (val == '6') {
                this.widthString = 'vf-gr-6';
            }else if (val == '5') {
                this.widthString = 'vf-gr-5';
            }else if (val == '4') {
                this.widthString = 'vf-gr-4';
            }else if (val == '3') {
                this.widthString = 'vf-gr-3';
            }else if (val == '2') {
                this.widthString = 'vf-gr-2';
            }
        },

        handleLogicClick() {
            this.$emit('logic', this.id);
        },

        getValMessage(validation) {
            if (validation.parts) {
                let str = '*';
                for (const i in validation.parts) {
                    if (validation.parts[i].type == 'val') {
                        str += ' ' + validation.parts[i].val;
                    }else {
                        str += ' ' + this.__(validation.parts[i].val);
                    }
                }
                return str;
            }else {
                return '* ' + this.__(validation.message);
            }
        },

    },
    mounted() {
        this.setWidth(this.width);
    }
}

</script>
<style>

.vf-gr {
    position: relative;
    /* float: left; */
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 18px;
    align-items: center;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
}

.vf-gr-np {
    padding: 0px;
}

.vf-gr-12 {
    width: 100%;
}
.vf-gr-9 {
    width: 75%;
}
.vf-gr-8 {
    width: 66.6664%;
}
.vf-gr-6 {
    width: 50%;
}
.vf-gr-5 {
    width: 41.666%;
}
.vf-gr-4 {
    width: 33.3334%;
}
.vf-gr-3 {
    width: 25%;
}
.vf-gr-2 {
    width: 16.666%;
}

.vf-gr-auto {
    float: left;
    width: auto;
    margin-right: 40px;
}

@media only screen and (max-width: 600px) {

    .vf-gr-8, .vf-gr-6, .vf-gr-5, .vf-gr-4, .vf-gr-3, .vf-gr-2 {
        width: 100%;
    }

}

.vf-gr-phone.vf-gr-8, .vf-gr-phone.vf-gr-6, .vf-gr-phone.vf-gr-5, .vf-gr-phone.vf-gr-4, .vf-gr-phone.vf-gr-3, .vf-gr-phone.vf-gr-2 {
    width: 100%;
}


.vf-gr-er {
    float: left;
    padding-top: 4px;
    padding-left: 2px;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: var(--val-error);
}


/* logic icon */

.vf-gr-logic {
    visibility: collapse;
    position: absolute;
    top: 10px; right: 20px;
    width: 26px; height: 26px;
    border-radius: 50%;
    background-color: #3190D5;
    cursor: pointer;
    padding-top: 1px;
    padding-left: 1.5px;
    box-sizing: border-box;
    z-index: 5;
}
.vf-gr-logic path {
    fill: white;
}

/* design mode */

.ac-designer:hover {
    background-color: rgb(231, 246, 255);
}
.logic .ac-designer:hover {
    background-color: transparent;
}

.vf-gr-selected {
    border-radius: 3px;
    outline: solid 2px #238ccc;
}

.vf-gr-selected .vf-gr-logic {
    visibility: visible;
}

.vf-gr-disabled {
    opacity: 0.5;
}

.vf-gr-compact {
    padding-bottom: 5px;
}

</style>
