<template>
<div>
    <PopupV1 :header="__t('files-upload-header')" @close="onClose" :dialog="dialog">
        <template v-slot:content>
            <div class="flw100" v-if="params.mode == 'new'">
                <VFFileuplaod v-model="files" :allowedFiles="'pdf'" :singleFile="true"
                    :header="__t('files-upload-header')"
                    :hintText="__t('files-upload-hint')"
                    :browseText="__t('files-upload-browse')"
                    @input="validateInputs"
                />
            </div>
            <div class="flw100" style="margin-top: 10px;" v-if="typeOptions.length > 1">
                <VFLabel desc :text="__t('files-upload-type')" />
                <VFDropdown v-model="typeModel"
                    :options="typeOptions"
                    :listKey="'display'"
                    :displayKey="'display'"
                    :outputKey="'type'"
                    @input="validateInputs"
                />
            </div>
            <div class="flw100" style="margin-top: 10px;">
                <VFLabel desc :text="__t('report-comment')" />
                <VFTextArea
                    v-model="comment"
                    :placeholder="__t('report-comment') + ' (' + __('common_optional').toLowerCase() + ')'"
                />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="onClose" :disabled="dialog.working" />
            <VFButton v-if="params.mode == 'new'"
                :text="__t('files-upload-header')"
                :icon="'upload'"
                @clicked="onUpload"
                :working="dialog.working"
                :disabled="!dialog.valid"
            />
            <VFButton v-if="params.mode == 'edit'"
                :text="__t('common-save')"
                :icon="'save'"
                @clicked="onEdit"
                :working="dialog.working"
                :disabled="!dialog.valid"
            />
        </template>
    </PopupV1>
</div>
</template>
<script>
import v1api from '../v1helpers/v1api';
import v1receipt from '../v1helpers/v1receipt';
import dataModelV1 from '../v1data/dataModelV1';
import v2lib from '../v2lib';
import tools from '../tools';

import PopupV1 from '../v1ui/PopupV1.vue';
import VFButton from '../components/form/items/VFButton.vue';
import VFLabel from '../components/form/items/VFLabel.vue';
import VFDropdown from '../components/form/inputs/VFDropdown.vue';
import VFFileuplaod from '../components/form/inputs/VFFileupload.vue';
import VFTextArea from '../components/form/inputs/VFTextArea.vue';

export default {
    name: 'UploadFileDialog',
    components: {
        PopupV1,
        VFButton,
        VFLabel,
        VFDropdown,
        VFFileuplaod,
        VFTextArea,
    },
    props: {
        client: { type: Object, default: null },
        flow: { type: Object, default: null },
        params: { type: Object, default: null },
    },
    data() {
        return {
            dialog: { show: true, valid: true, working: false, status: '', error: '' },
            typeOptions: [],
            typeModel: '',
            files: {},
            comment: '',
        }
    },
    methods: {

        validateInputs() {
            if (this.params.mode == 'edit') {
                this.dialog.valid = true;
            }else {
                if (this.files.files && this.files.files.length > 0) {
                    this.dialog.valid = true;
                }else {
                    this.dialog.valid = false;
                }
            }
        },

        async onUpload() {
            this.dialog.working = true;
            this.dialog.status = 'Fetching envelope';

            let envelope;
            if (this.client.envelope.id) {
                envelope = await this.getEnvelope(this.client.envelope.id);
                console.log('envelope', envelope);
            }else {
                this.dialog.status = 'Creating new envelope for attachments';
                const extraData = {
                    dataOnly: true,
                    dataOnlyParams: {
                        title: this.flow.title[this.$root.config.params.defaultLanguage] + ': ' + this.companyTitleCase(this.client.name),
                    },
                };
                envelope = await v1receipt.createClientEnvelope(this.$root, this.client, dataModelV1.getEmptyCompany(), this.flow, extraData);

                const updateData = {
                    envelopeId: envelope.id,
                    documentId: envelope.documentId,
                    name: this.__t('datapdf-name') + ' ' + this.companyTitleCase(this.client.name),
                    bearerToken: envelope.metadata.bearerToken
                };
                await v1api.updateClient(this.client._id, updateData);
            }

            if (!envelope) {
                this.dialog.error = 'No client envelope, can not add attachments!';
                this.dialog.working = false;
                return;
            }

            this.dialog.status = 'Uploading file';

            console.log('files', this.files);
            const name = this.files.files[0].name;
            const data = this.files.files[0].data;
            await v2lib.addAttachmentToExistingEnvelope(envelope, name, data, v2lib.personalToken);

            const fileData = {
                type: this.typeModel,
                envelopeId: envelope.id,
                formKey: tools.getUniqueId(),
                name: name,
                comment: this.comment,
                inludeInReport: true,
            };
            await v1api.uploadFile(this.client._id, fileData);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        async onEdit() {
            this.dialog.working = true;
            this.dialog.status = 'Updating file';

            const fileData = {
                type: this.typeModel,
                comment: this.comment,
            };
            await v1api.updateFile(this.client._id, this.client.files[this.params.index].formKey, fileData);

            const response = await v1api.getClient(this.client._id);
            this.$emit('reload', response);

            this.dialog.working = false;
            this.$emit('close');
        },

        onClose() {
            this.$emit('close');
        },

    },
    created() {

        for (const i in this.$root.config.features.externalFiles.types) {
            this.typeOptions.push({
                type: this.$root.config.features.externalFiles.types[i].type,
                display: this.$root.config.features.externalFiles.types[i].text[this.$root.locale.key],
            });
        }
        this.typeModel = this.$root.config.features.externalFiles.types[0].type;

        if (this.params.mode == 'edit') {
            this.comment = this.client.files[this.params.index].comment || '';
            this.typeModel = this.client.files[this.params.index].type;
            console.log('PARAMS', this.params)
        }

        this.validateInputs();
    }
}
</script>