<template>
<div>

    <div class="slim-page" v-if="showUI">

        <div class="lform-landing-logo" style="margin-top: 150px;">
            <img :src="logoUrl" />
        </div>

        <div class="lform-landing-header">
            <div class="lform-landing-header-center">
                <div :class="aborted ? 'lform-landing-icon-error' : 'lform-landing-icon'">
                    <vap-icon :icon="aborted ? 'cancel' : 'check'" :color="aborted ? 'var(--error-text)' : 'var(--primary-contrast)'" />
                </div>
                <div class="lform-landing-text" v-if="!checkForPDF">
                    <label>{{ landingParams[selectedModel].title[$root.locale.key] }}</label>
                </div>
                <div class="lform-landing-text" v-if="checkForPDF">
                    <label>{{ 'Checklist Approved' }}</label>
                </div>
            </div>
        </div>

        <vap-card list style="margin-top: 40px;" v-if="!checkForPDF">
            <vap-text-editor :value="landingParams[selectedModel].message[$root.locale.key]" />
        </vap-card>

        <vap-card list style="margin-top: 40px;" v-if="checkForPDF">
            <div>
                {{ 'Checklist was approved, please download the PDF to the customer folder.' }}
            </div>
        </vap-card>

        <vap-card v-if="checkForPDF">
            <vap-button :text="'Download PDF to customer folder'" :disabled="!pdfReady" @clicked="downloadPDF" :working="pdfDownloading" />
        </vap-card>

        <div class="lform-landing-footer">
            <span>{{ __t('form-landing-close') + ' ' }}</span><span><a :href="redirectUrl">{{ __t('form-landing-homepage') }}</a></span>
        </div>

    </div>

    <!-- Editor (if form is already filled) -->
    <SignFlowLanding :envelope="envelope" :recipient="recipient" :formData="formData" v-if="showNewUI" />

</div>
</template>
<script>
import log from '@/log';
import api from '@/portalapi';
import v2lib from '@/v2lib';
import verified from '../../verified';
import formHelper from './formHelper';

import downloadjs from 'downloadjs'

import FormRenderer from './Renderer';

import SignFlowLanding from './SignFlowLanding.vue';

export default {
    name: "FormLanding",
    components: {
        FormRenderer,
        SignFlowLanding,
    },
    props: {
        envelope: { type: Object, default: null },
        recipient: { type: Object, default: null },
    },
    data() {
        return {
            showUI: false,
            landingParams: {},
            selectedModel: '',
            aborted: false,
            checkForPDF: false,
            pdfReady: false,
            pdfDownloading: false,
            showNewUI: false,
            formData: null,
            logoUrl: '',
            redirectUrl: 'https://verified.eu'
        }
    },
    methods: {


        //#region Data processing ---------------------------------------------------------------------------------------------

        async waitForPDF() {
            this.pdfDownloading = true;

            let hasPDF = false;
            let count = 0;

            while (!hasPDF && count < 10) {

                const envelope = await v2lib.getEditorEnvelope(this.envelope.id);
                console.log('enveloep', envelope);

                if (envelope.content.documents && envelope.content.documents.length > 0 && envelope.content.documents[0].files) {
                    const files = envelope.content.documents[0].files;

                    if (files.signedDocument) {
                        hasPDF = true;
                        console.log('has SIGNED document', files.signedDocument);
                    }else if (files.document) {
                        hasPDF = true;
                        console.log('has document', files.document);
                    }

                }

                await new Promise(r => setTimeout(r, 2000));
                count += 1;
            }

            this.pdfDownloading = false;
            this.pdfReady = hasPDF;
        },

        async downloadPDF() {
            this.pdfDownloading = true;

            const envelope = await v2lib.getEditorEnvelope(this.envelope.id);

            let uid = 'error';

            if (envelope.content.documents[0].files.document) {
                uid = envelope.content.documents[0].files.document.uid;
            }

            const url = await verified.getEditorDownloadUrl(envelope.id, envelope.content.documents[0].id, uid, v2lib.editorToken);

            console.log('url', url);

            const data = await verified.downloadFile(url);

            downloadjs("data:application/pdf;base64," + data, envelope.content.documents[0].files.document.name, "application/pdf");

            this.pdfDownloading = false;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        showMainSpinner(show, text) {
            if (show) {
                this.$parent.$parent.setLoading(true, text || this.__('loading') + '...');
            }else {
                this.$parent.$parent.setLoading(false);
            }
        }

    },
    async created() {

        // Remove this when ready
        if (this.envelope.content.sender.namespace == "/companies/ByYfstVzK") {
            if (this.recipient.completed && this.recipient.role.action == "sign") {
                window.location.href= "https://www.klp.no/virksomhet/signatur-kvittering";
            }
        }

        const response = await api.getForm(this.envelope.userdata.formConfig.key);
        //console.log('response', response);
        this.landingParams = response.formData.flowConfig.landingParams;
        this.formData = response.formData;


        if (this.$root.config.forms.newLanding && this.envelope.content.descriptor.id == 'aml-portal-v1-sign') {
            this.showNewUI = true;
            log.e('FlowPortal: Using new system');
            return;
        }


        if (this.recipient.role.action == 'edit' && this.recipient.completed) {
            console.log('editor completed');
            this.selectedModel = 'filled';
        }else if (this.recipient.role.name == 'approver') {
            //console.log('this.envelope.userdata.approveModel', this.envelope.userdata.approveModel);
            let aborted = false;
            if (this.envelope.userdata.approveModel && this.envelope.userdata.approveModel.approvers) {
                for (const i in this.envelope.userdata.approveModel.approvers) {
                    if (!this.envelope.userdata.approveModel.approvers[i].approved) {
                        aborted = true;
                    }
                }
            }
            if (aborted) {
                this.selectedModel = 'aborted';
                this.aborted = true;
            }else {
                this.selectedModel = 'filled';
            }
        }

        if (this.recipient.role.action == 'sign' && this.recipient.completed) {
            console.log('signer completed');
            this.selectedModel = 'signed';
        }

        if (this.landingParams.pdfDownload) {

            if (this.envelope.userdata.chkModel && this.envelope.userdata.chkModel.finalValidation) {
                this.checkForPDF = true;
                this.waitForPDF();
            }
            
        }

        let logoUrl = '';
        if (!logoUrl && this.$root.config.branding && this.$root.config.branding.logoLanding) {
            logoUrl = this.$root.config.branding.logoLanding;
        }
        if (!logoUrl || logoUrl.includes('verified-logo-blue')) {
            logoUrl = this.$root.config.pdf.logoUrl;
        }
        this.logoUrl = logoUrl;

        if (this.$root.config.branding && this.$root.config.branding.redirectUri && this.$root.config.branding.redirectUri != 'https://verified.eu') {
            this.redirectUrl = this.$root.config.branding.redirectUri;
        }else {
            try {
                this.redirectUrl = this.$root.config.kycs[0].landing.redirect;
            }catch (ex) {
                this.redirectUrl = 'https://verified.eu';
            }
        }

        this.showUI = true;
    }
}

</script>
<style scoped>

.lform-landing-logo {
    float: left;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}
.lform-landing-logo img {
    width: 360px;
}

.lform-landing-header {
    float: left;
    width: 100%;
    margin-top: 40px;
}
.lform-landing-header-center {
    display: flex;
    width: fit-content;
    margin: 0 auto;
}
.lform-landing-icon {
    width: 45px; height: 45px;
    padding-top: 5px;
    padding-left: 5px;
    border-radius: 50%;
    background-color: var(--primary);
    box-sizing: border-box;
}
.lform-landing-icon-error {
    width: 45px; height: 45px;
    padding-top: 4px;
    padding-left: 4px;
    border-radius: 50%;
    background-color: var(--error-background);
    box-sizing: border-box;
}
.lform-landing-text {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 20px;
}
.lform-landing-footer {
    float: left;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}

</style>
