<template>
<div>

    <div class="flv2-env" v-if="!cardData.hideName" :class="[
        { 'flv2-env-phone' : $root.phoneMode },
    ]">
        {{ __t('landing-document') + ': ' + name }}
    </div>

    <div class="flv2-lf" @click="handleClick" :class="[
        { 'flv2-lf-phone' : $root.phoneMode },
        { 'flv2-lf-selected' : selected },
    ]">

        <div class="flv2-lf-steps nohl">

            <div class="flv2-lf-step" v-for="(step, i) in steps" :key="'ls' + i" :class="[
                {'flv2-lf-p': step.status == 1 },
                {'flv2-lf-c': step.status == 2 },
                {'flv2-lf-a': step.status == 8 },
                {'flv2-lf-r': step.status == 9 },
            ]">

                <div class="flv2-lf-circle">
                    <vap-icon :icon="step.icon" :color="'ext'" />
                </div>

                <div class="flv2-lf-info">
                    <div class="flv2-lf-action">
                        {{ __t(step.key) }}
                    </div>
                    <div class="flv2-lf-name">
                        {{ step.nameKey ? __t(step.nameKey) : step.name }}
                    </div>
                </div>

                <div class="flv2-lf-line" v-if="i > 0" />
                <div class="flv2-lf-dline" v-if="step.active" />

                <div id="flv2-lf-scroll" v-if="step.active" />

            </div>

        </div>
    </div>
</div>
</template>
<script>
export default {
    name: "LandingFlow",
    props: {
        envelope: { type: Object, default: null },
        recipient: { type: Object, default: null },
        cardData: { type: Object, default: () => { return {} }},
        designer: { type: Boolean, default: false },
        selected: { type: Boolean, default: false },
    },
    data() {
        return {
            name: 'Unknown envelope',
            steps: []
        }
    },
    methods: {

        handleClick() {
            if (!this.designer) return;
            this.$emit('selected');
        },

        scrollToRecipient() {
            var element = document.getElementById("flv2-lf-scroll");
            element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
        },

    },
    created() {
        if (!this.designer) {

            // Read envelope
            this.name = this.envelope.content.documents[0].name;

            const wasAborted = this.envelope.content.aborted;
            let wasRejectedMain = false;

            this.steps.push({
                icon: 'file-sent',
                status: wasAborted ? 0 : 2,
                active: false,
                key: 'landing-sent-by',
                name: this.envelope.content.sender.givenName + ' ' + this.envelope.content.sender.familyName
            });

            let hasSigner = false;
            let hasProgress = false;
            for (const i in this.envelope.content.recipients) {
                const recipient = this.envelope.content.recipients[i];
                if (recipient.role.action == 'edit') {
                    const step = {
                        icon: 'edit',
                        status: recipient.completed ? 2 : 1,
                        active: recipient.id == this.recipient.id,
                        key: recipient.completed ? 'landing-filled-by' : 'landing-filled-not',
                        name: recipient.givenName + ' ' + recipient.familyName
                    };
                    if (wasAborted) {
                        step.status = 0;
                        step.active = false;
                    }
                    this.steps.push(step);
                    if (!recipient.completed) hasProgress = true;
                }
                if (recipient.role.action == 'sign') {
                    const step = {
                        icon: 'sign',
                        status: recipient.completed ? 2 : 1,
                        active: recipient.id == this.recipient.id,
                        key: (recipient.completed && !wasAborted) ? 'landing-signed-by' : 'landing-signed-not',
                        name: recipient.givenName + ' ' + recipient.familyName
                    };
                    if (!recipient.completed && hasProgress) {
                        step.status = 0;
                        step.key = 'landing-signed-not';
                    }

                    if (recipient.completed) {
                        let wasRejected = false;
                        for (const i in recipient.signatures) {
                            if (recipient.signatures[i].status == 'rejected') {
                                wasRejected = true;
                                wasRejectedMain = true;
                            }
                        }
                        if (wasRejected) {
                            step.status = 9;
                            step.key = 'landing-signed-rejected';
                        }
                    }

                    if (wasAborted) {
                        step.status = 0;
                        step.active = false;
                    }

                    this.steps.push(step);
                    hasSigner = true;
                    if (!recipient.completed) hasProgress = true;
                }
            }

            if (!hasSigner) {
                this.steps.push({
                    icon: 'sign',
                    status: 0,
                    active: false,
                    key: 'landing-signed-not',
                    nameKey: 'landing-signed-empty'
                });
            }

            const finalStep = {
                icon: 'check',
                status: wasAborted ? 8 : 0,
                active: wasAborted,
                key: 'landing-completed-status',
                nameKey: 'landing-completed-not'
            };

            if (this.envelope.content.completed) {
                finalStep.status = 2;
                finalStep.nameKey = 'landing-completed';
            }

            if (wasAborted || wasRejectedMain) finalStep.icon = 'cancel';
            if (wasAborted) finalStep.nameKey = 'landing-aborted';
            this.steps.push(finalStep);

        }else {

            // Add something for the designer

            this.steps.push({
                icon: 'file-sent',
                status: 2,
                active: false,
                key: 'landing-sent-by',
                name: 'Form Builder'
            });
            this.steps.push({
                icon: 'edit',
                status: 1,
                active: true,
                key: 'landing-filled-by',
                name: 'Form Builder'
            });
            this.steps.push({
                icon: 'sign',
                status: 0,
                active: false,
                key: 'landing-signed-not',
                name: 'Form Builder'
            });
            this.steps.push({
                icon: 'check',
                status: 0,
                active: false,
                key: 'landing-completed-status',
                nameKey: 'landing-completed-not'
            });

        }
        if (this.$root.phoneMode) {
            this.$nextTick(() => {
                this.scrollToRecipient();
            });
        }
    }
}

</script>
<style scoped>

.flv2-lf {
    float: left;
    width: 100%;
    margin-bottom: 30px;
}

.flv2-env {
    font-size: 18px;
    text-align: center;
    color: var(--text-title);
}

.flv2-lf-steps {
    width: fit-content;
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    padding-bottom: 22px;
}

.flv2-lf-step {
    position: relative;
    width: 180px;
}

.flv2-lf-circle {
    width: 64px; height: 64px;
    margin-left: 58px;
    padding-top: 12px;
    padding-left: 12px;
    border-radius: 50%;
    border: solid 2px var(--disabled-background);
    fill: var(--disabled-text);
    background-color: var(--disabled-background);
    box-sizing: border-box;
}
.flv2-lf-p .flv2-lf-circle {
    border-color: var(--primary);
    fill: var(--primary);
    background-color: var(--primary-contrast);
}
.flv2-lf-c .flv2-lf-circle {
    border-color: var(--primary);
    fill: var(--primary-contrast);
    background-color: var(--primary);
}
.flv2-lf-a .flv2-lf-circle {
    border-color: var(--warning-background);
    fill: var(--warning-text);
    background-color: var(--warning-background);
}
.flv2-lf-r .flv2-lf-circle {
    border-color: var(--error-background);
    fill: var(--error-text);
    background-color: var(--error-background);
}

.flv2-lf-info {
    position: relative;
    margin-top: 10px;
    text-align: center;
    background-color: var(--page-background);
    padding-bottom: 6px;
    z-index: 2;
}

.flv2-lf-action {
    font-size: 14px;
    color: var(--text-weak);
}
.flv2-lf-name {
    font-weight: 500;
    color: var(--text-disabled);
}

.flv2-lf-p .flv2-lf-name {
    color: var(--text-accent);
}
.flv2-lf-c .flv2-lf-name {
    color: var(--text-title);
}
.flv2-lf-a .flv2-lf-name {
    color: var(--text-title);
}
.flv2-lf-r .flv2-lf-name {
    color: var(--text-title);
}

.flv2-lf-line {
    position: absolute;
    width: 80px; height: 5px;
    top: 30px; left: -40px;
    background-color: var(--disabled-background);
}
.flv2-lf-p .flv2-lf-line {
    background-color: var(--primary);
}
.flv2-lf-c .flv2-lf-line {
    background-color: var(--primary);
}
.flv2-lf-r .flv2-lf-line {
    background-color: var(--error-background);
}

.flv2-lf-dline {
    position: absolute;
    width: 5px; height: 66px;
    top: 90px; left: 88px;
    background-color: var(--primary);
    z-index: 1;
}
.flv2-lf-a .flv2-lf-dline {
    background-color: var(--warning-background);
}
.flv2-lf-r .flv2-lf-dline {
    background-color: var(--error-background);
}


/* Phone mode */

#flv2-lf-scroll {
    position: absolute;
    width: 10px; height: 10px;
    top: 0px; left: 278px;
    /* background-color: red; */
}

.flv2-lf-phone {
    overflow: auto;
}

.flv2-env-phone {
    padding-top: 10px;
    padding-left: 20px;
    text-align: left;
}



/* Designer / Selection */

.flv2-lf-selected {
    border-radius: 4px;
    outline: solid 2px #238ccc;
}

</style>