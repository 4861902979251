<template>
<div v-if="step" style="position: relative;">
    
    <div class="srv2x-step-x" :class="[
        { 'srv2x-step-completed srv2x-step-progress' : status == 3 },
        { 'srv2x-step-completed' : status == 5 },
        { 'srv2x-step-completed srv2x-step-warning' : status == 8 },
        { 'srv2x-step-completed srv2x-step-error' : status == 9 },
    ]" @click.stop="onStepClicked">

        <div class="srv2x-line-top" />
        <div class="srv2x-line-bot" />

        <div class="srv2x-icon">
            <div class="srv2x-icon-icon" v-if="step.icon">
                <vap-icon :icon="step.icon" />
            </div>
            <div class="srv2x-icon-number" v-if="!step.icon">
                {{ getNumberChar(archiveIndex) }}
            </div>
        </div>

        <div class="srv2x-content">

            <div class="srv2x-header">
                <!-- {{ step.title[$root.locale.key] }} -->
                {{ getFormName() }}
            </div>

            <div class="srv2x-hint" v-if="status == 0">
                {{ step.hint[$root.locale.key] }}
            </div>

            <div class="srv2x-status" v-for="(sub, i) in subs" :key="'fss' + i" :class="[
                {'srv2x-status-progress': sub.status == 3 },
                {'srv2x-status-completed': sub.status == 5 },
                {'srv2x-status-warning': sub.status == 8 },
                {'srv2x-status-error': sub.status == 9 }
            ]">
                <div class="srv2x-ball" />
                <div class="srv2x-status-text">
                    {{ sub.text[$root.locale.key] }}
                </div>
            </div>

        </div>

        <div class="srv2x-button" v-if="mainAction" @click="$emit('action', mainAction, index)">
            
            <div class="srv2x-button-c">
                {{ mainAction.text[$root.locale.key] }}
            </div>
            <div class="srv2x-button-t" />
        </div>

       <CardActions sharpCorner :actions="actions" @action="onAction" />

   </div>

</div>
</template>
<script>
import tools from '../tools';
import triggerHelper from './triggerHelper';

import CardActions from '../v1ui/CardActions.vue';

export default {
    name: 'StepListItem',
    components: {
        CardActions,
    },
    props: {
        client: { type: Object, default: null },
        item: { type: Object, default: null },
        step: { type: Object, default: null },
        index: { type: Number, default: 0 },
        archiveIndex: { type: Number, default: 0 },
    },
    data() {
        return {
            actions: [],
            mainAction: null,
            status: 0,
            text: {},
            subs: [],
            form: null
        }
    },
    methods: {

        onStepClicked() {
            this.$emit('clicked');
        },

        onAction(action) {
            this.$emit('action', action, this.archiveIndex);
        },

        getNumberChar(index) {
            return 'abcdefghijklmnopqrstuvwxyz'.charAt(index);
        },

        getFormName() {
            let name = this.item.name;
            name = name.replace('.pdf', '');
            /* name += ' (' + tools.dateString(this.item.dateCreated) + ')'; */
            return name;
        },

    },
    async created() {

        for (const i in this.step.actions) {
            if (triggerHelper.isActionRelevant(this.step.actions[i], this.$parent.$parent.$parent.triggers, this.archiveIndex)) {
                if (this.step.actions[i].main) {
                    this.mainAction = this.step.actions[i];
                }else {
                    this.actions.push(this.step.actions[i]);
                }
            }
        }

        const result = triggerHelper.getStepStatus(this.step, this.$parent.$parent.$parent.triggers, this.$parent.$parent.$parent.texts, this.archiveIndex);
        this.status = result.status;
        this.text = result.text;
        this.subs = result.subs;

    }
}
</script>
<style scoped>

.srv2x-step-x {
    float: left;
    position: relative;
    width: 100%;
    min-height: 50px;
    display: flex;
    box-sizing: border-box;
    border-bottom: solid 1px var(--input-border);
}

.srv2x-step-completed {
    /* border: solid 1px var(--input-border); */
    background-color: var(--card-background);
}

.srv2x-line-top {
    position: absolute;
    top: 0px; left: 41px;
    width: 7px; height: 14px;
    background-color: red;
}
.srv2x-line-bot {
    position: absolute;
    bottom: 0px; left: 41px;
    width: 7px; height: 44px;
    background-color: red;
}
.srv2x-step-completed .srv2x-line-top {
    background-color: var(--primary);
}
.srv2x-step-completed .srv2x-line-bot {
    background-color: var(--primary);
}
.srv2x-step-progress .srv2x-line-top {
    background-color: var(--primary-weak);
}
.srv2x-step-progress .srv2x-line-bot {
    background-color: var(--primary-weak);
}
.srv2x-step-warning .srv2x-line-top {
    background-color: var(--warning-background);
}
.srv2x-step-warning .srv2x-line-bot {
    background-color: var(--warning-background);
}
.srv2x-step-error .srv2x-line-top {
    background-color: var(--error-background);
}
.srv2x-step-error .srv2x-line-bot {
    background-color: var(--error-background);
}

.srv2x-icon {
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    margin-top: 11px;
    margin-left: 24px;
    color: gray;
    border-radius: 50%;
    border: dotted 2px gray;
    z-index: 2;
}

.srv2x-icon-icon {
    padding-top: 4px;
    padding-left: 4px;
}
.srv2x-icon-number {
    padding-top: 7px;
    padding-left: 13px;
    font-size: 22px;
    font-weight: 500;
}

.srv2x-step-completed .srv2x-icon {
    color: var(--primary-contrast);
    border: solid 2px var(--primary);
    background-color: var(--primary);
}
.srv2x-step-progress .srv2x-icon {
    color: var(--primary);
    border: solid 2px var(--primary-weak);
    background-color: var(--primary-weak);
}
.srv2x-step-warning .srv2x-icon {
    color: var(--error-text);
    border: solid 2px var(--warning-background);
    background-color: var(--warning-background);
}
.srv2x-step-error .srv2x-icon {
    color: var(--error-text);
    border: solid 2px var(--error-background);
    background-color: var(--error-background);
}




.srv2x-content {
    flex-grow: 100;
    margin-left: 22px;
    padding-right: 60px;
    padding-top: 1px;
    padding-bottom: 12px;
    color: gray;
}
.srv2x-step-completed .srv2x-content {
    color: var(--text-strong);
}

.srv2x-header {
    margin-top: 6px;
    font-size: 20px;
}
.srv2x-hint {
    margin-top: 3px;
    padding-right: 100px;
}

.srv2x-status {
    display: flex;
    margin-top: 3px;
}

.srv2x-ball {
    flex-shrink: 0;
    width: 16px; height: 16px;
    margin-top: 1px;
    border-radius: 8px;
    border: solid 2px transparent;
    box-sizing: border-box;
}
.srv2x-status-progress .srv2x-ball {
    border: solid 2px var(--success-background);
}
.srv2x-status-completed .srv2x-ball {
    border: solid 2px var(--success-background);
    background-color: var(--success-background);
}
.srv2x-status-warning .srv2x-ball {
    border: solid 2px var(--warning-background);
    background-color: var(--warning-background);
}
.srv2x-status-error .srv2x-ball {
    border: solid 2px var(--error-background);
    background-color: var(--error-background);
}

.srv2x-status-text {
    flex-grow: 100;
    margin-left: 7px;
}




/* Main Button */

.srv2x-button {
    position: absolute;
    min-width: 120px;
    max-width: 300px;
    height: 36px;
    bottom: 15px;
    right: 15px;
    background-color: var(--button);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border: solid 2px var(--button);
    box-sizing: border-box;
    cursor: pointer;
}
.srv2x-button-c {
    position: relative;
    margin-left: -22px;
    padding-top: 7px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 24;
    color: var(--button-text);
    text-align: center;
}
.srv2x-button-t {
    position: absolute;
    top: -2px;
    left: -30px;
    width: 50px;
    height: 36px;
    border: solid 2px var(--button);
    border-right: none;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 5px;
    -webkit-transform: skew(-45deg);
    transform: skew(-45deg);
    background-color: var(--button);
    box-sizing: border-box;
}
.srv2x-button:hover {
    opacity: 0.8;
}


/* Designer */

.srv2x-step-selected {
    border: solid 2px var(--accent);
}


</style>