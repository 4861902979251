<template>
<div>
    <HorizontalStepper v-if="!formLoading" :steps="steps" :stepKey="stepKey" @nav="onStepKeyChanged" @on-submit="onSubmit" :flowName="__('shareapp_title')" />
    <div class="shareapp" v-if="!formLoading">

        <!-- Upload application -->
        <vap-form v-show="stepKey == 'upload'" ref="upload">
            
            <vap-infocard :data="helpTexts.owner_upload.items" />

            <vap-card list>
                <vap-row>
                    <vap-group c12>
                        <vap-label :text="__('shareapp_input_project')" desc required />
                        <vap-input noHighlight
                            v-model="shareData.name"
                            :rules="'required'"
                            :placeholder="__('common_required')"
                            @input="validateShareForm"
                        />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('shareapp_input_price')" desc required />
                        <vap-input noHighlight
                            v-model="shareData.price"
                            :rules="'required'"
                            :placeholder="__('common_required')"
                            @input="validateShareForm"
                        />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('shareapp_input_minimum')" desc required />
                        <vap-input noHighlight
                            v-model="shareData.minimum"
                            :rules="'required'"
                            :placeholder="__('common_required')"
                            @input="validateShareForm"
                        />
                    </vap-group>
                </vap-row>
            </vap-card>

            <vap-card>
                <vap-file-upload v-model="infoFiles" singleFile readPDF
                    :header="__('fileupload_header')"
                    :helpText="__('fileupload_hint')"
                    :uploadText="__('fileupload_browse')"
                    @pdfread="onFileRead"
                />
            </vap-card>

        </vap-form>


        <!-- Recipient -->
        <vap-form v-show="stepKey == 'recipient'" ref="recipient">
            
            <vap-infocard :data="helpTexts.owner_recipient.items" />

            <vap-form-divider v-if="!editor" :left="55" :right="45">
                <template v-slot:left>
                    <vap-label :text="__('recipient')" header style="float: left; width: 100%; margin-bottom: 5px;" />
                    <RecipientSelect v-if="!checkout.initalSelect && !editor" :persons="checkout.persons" @selected="setInitialReceiver" />
                    <Recipient v-if="checkout.initalSelect" :initialData="checkout.initalReceivers" v-model="checkout.receivers" :persons="checkout.persons" selectEditor />
                </template>
                <template v-slot:right>
                    <vap-label :text="__('recipient_order')" header style="float: left; width: 100%;  margin-bottom: 5px;" />
                    <RecipientList :editors="checkout.receivers" :signers="checkout.internals" tempEditor editorIsSigner />
                </template>
            </vap-form-divider>

        </vap-form>


        <!-- Welcome (editor) -->
        <vap-form v-show="stepKey == 'welcome'" ref="welcome" >
            <vap-card>
                <vap-text-editor :value="__('shareapp_welcome')" />
            </vap-card>
        </vap-form>


        <!-- Read application -->
        <vap-form v-show="stepKey == 'preview' && editor" ref="preview">

            <vap-infocard :data="helpTexts.editor_preview.items" />

            <vap-card v-if="stepKey == 'preview' && editor">
                <Preview :raw="previewData" :filename="previewName" downloadButton />
            </vap-card>

        </vap-form>


        <!-- Form / Checkout -->
        <vap-form v-show="stepKey == 'appform' && editor" ref="appform">

            <vap-infocard :data="helpTexts.editor_form.items" />

            <vap-card list :header="__('shareapp_input_header').toUpperCase()">
                <vap-row>
                    <vap-group c6>
                        <vap-label :text="__('shareapp_input_name')" desc required />
                        <vap-input noHighlight
                            v-model="formData.name"
                            :rules="'required'"
                            :placeholder="__('common_required')"
                            @input="validateAppForm"
                        />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('shareapp_input_identity')" desc required />
                        <vap-input noHighlight
                            v-model="formData.identity"
                            :rules="'required'"
                            :placeholder="__('common_required')"
                            @input="validateAppForm"
                        />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('shareapp_input_shares1') + shareData.price + __('shareapp_input_shares2') + shareData.minimum + __('shareapp_input_shares3')" desc required />
                        <vap-input noHighlight numberFormat
                            v-model="formData.shareAmount"
                            :rules="'required|number'"
                            :placeholder="__('common_required')"
                            @input="validateNumbers"
                        />
                    </vap-group>
                    <vap-group c6>
                        <vap-label :text="__('shareapp_input_amount')" desc required />
                        <vap-input noHighlight numberFormat
                            v-model="formData.sumTotal"
                            :rules="'required|number'"
                            :placeholder="__('common_required')"
                            @input="validateNumbers"
                        />
                    </vap-group>
                </vap-row>
                <div v-if="numbersShow && !numbersValid" class="flw100" style="padding: 5px 10px; font-size: 15px; color: var(--val-error);">
                    <label>{{ numbersMessage }}</label>
                </div>
            </vap-card>

        </vap-form>


        <!-- Checkout -->
        <vap-form v-show="stepKey == 'checkout' && editor" ref="checkout">

            <vap-infocard :data="helpTexts.editor_checkout.items" />

            <vap-form-divider v-if="!editor" :left="55" :right="45">
                <template v-slot:left>
                    <vap-label :text="__('recipient')" header style="float: left; width: 100%; margin-bottom: 5px;" />
                    <RecipientSelect v-if="!checkout.initalSelect && !editor" :persons="checkout.persons" @selected="setInitialReceiver" />
                    <!-- <Recipient v-if="editor" v-model="checkout.signers" :persons="checkout.persons" /> -->
                    <Recipient v-if="!editor && checkout.initalSelect" :initialData="checkout.initalReceivers" v-model="checkout.receivers" :persons="checkout.persons" selectEditor />
                </template>
                <template v-slot:right>
                    <vap-label :text="__('recipient_order')" header style="float: left; width: 100%;  margin-bottom: 5px;" />
                    <RecipientList v-if="!editor" :editors="checkout.receivers" :signers="checkout.internals" :controls="checkout.controls" tempEditor />
                    <!-- <RecipientList v-if="editor" :signers="checkout.signers" /> -->
                </template>
            </vap-form-divider>
            <Checkout v-if="editor" v-model="checkoutData" :receiver="checkout.receiver" :persons="checkout.persons" @submit="onSubmit" />

        </vap-form>


    </div>
</div>
</template>
<script>
import log from '@/log';
import api from '@/portalapi';
import aml from '@/aml';
import tools from '@/tools';
import locale from '@/locale';
import shareapp from './shareapp';
import uihelper from '@/helpers/uihelper';
import verified from '@/verified';
import v2lib from '@/v2lib';
import customFlowHelper from '@/custom/customFlowHelper';

import SigningMethodsInfo from '@/components/kyc/SigningMethodsInfo';
import HorizontalStepper from '@/components/kyc/StepperNew';
import RecipientSelect from '@/components/kyc/RecipientSelect';
import RecipientList from '@/components/kyc/RecipientList';
import Recipient from '@/components/kyc/Recipient';
import Preview from '@/components/documents/Preview';
import Checkout from '@/components/kyc/Checkout';

export default {
    name: 'ShareApplication',
    components: {
        SigningMethodsInfo,
        HorizontalStepper,
        RecipientSelect,
        RecipientList,
        Recipient,
        Preview,
        Checkout
    },
    data() {
        return {
            formLoading: true,
            editor: false,
            steps: [],
            stepKey: '',
            helpTexts: {},
            infoFiles: {},
            previewName: '',
            previewData: undefined,
            envelope: undefined,
            shareAppData: undefined,
            checkout: {
                initalSelect: false,
                initalReceivers: [],
                receivers: [],
                signers: [],
                persons: [],
                receiver: {}
            },
            checkoutData: {
                ready: false,
                signDirect: false,
                recipients: []
            },
            customer: undefined,
            shareData: {
                name: '',
                price: '',
                minimum: '',
                valid: false,
                uploadValid: false
            },
            formData: {
                name: '',
                identity: '',
                shareAmount: '',
                sumTotal: '',
                understood: false,
                valid: false
            },
            numbersShow: false,
            numbersValid: false,
            numbersMessage: ''
        }
    },
    watch: {
        checkout: { deep: true, handler(val) { this.onCheckoutDataChanged(val) } },
        checkoutData: {
            deep: true,
            handler(val) {
                if (this.editor) {
                    if (val.ready) {
                        uihelper.enableStep(this.steps, 'checkout', true);
                    }else {
                        uihelper.enableStep(this.steps, 'checkout', false);
                    }
                }
            }
        }
    },
    methods: {

        //#region Stepper -----------------------------------------------------------------------------------------------------

        onStepKeyChanged(nav) {
            let result = uihelper.validateStep(this, this.stepKey);
            this.stepKey = nav.key;
        },

        validateShareForm() {
            let result = uihelper.validateStep(this, 'upload');
            if (result && this.shareData.uploadValid) {
                this.shareData.valid = true;
            }else {
                this.shareData.valid = false;
            }
            uihelper.enableStep(this.steps, 'upload', this.shareData.valid);
        },

        validateAppForm() {
            let result = uihelper.validateStep(this, 'appform');
            if (result && this.numbersValid) {
                this.formData.valid = true;
            }else {
                this.formData.valid = false;
            }
            uihelper.enableStep(this.steps, 'appform', this.formData.valid);
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Checkout logic ----------------------------------------------------------------------------------------------

        validateNumbers() {
            this.numbersShow = false;
            const shares = uihelper.numberFromInput(this.formData.shareAmount);
            const total = uihelper.numberFromInput(this.formData.sumTotal);
            this.numbersValid = (shares != null && total != null);
            //console.log('shares', shares);
            //console.log('total', total);
            if (!this.numbersValid || total == 0) return;
            this.numbersShow = true;

            const correctTotal = Number(this.shareData.price) * shares;
            //console.log('correct', correctTotal);

            this.numbersValid = (total == correctTotal);
            if (!this.numbersValid) {
                this.numbersMessage = 'Beløpet stemmer ikke overens med antall aksjer * aksjepris (' + this.shareData.price + 'kr)';
            }
            if (this.numbersValid) {
                if (total < Number(this.shareData.minimum)) {
                    this.numbersValid = false;
                    this.numbersMessage = 'Totalbeløpet er mindre enn minimumbeløpet (' + this.shareData.minimum + 'kr)';
                }
            }

            //console.log('show = ' + this.numbersShow + ' valid = ' + this.numbersValid);
            this.validateAppForm();
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Checkout logic ----------------------------------------------------------------------------------------------

        onCheckoutDataChanged(val) {
            if (!this.editor) {
                // Check if there is a receiver
                const validRecipient = val.receivers.length > 0 && val.receivers[0].fname && val.receivers[0].lname && val.receivers[0].email;
                uihelper.enableStep(this.steps, 'recipient', validRecipient);
            }
        },

        setInitialReceiver(value) {
            this.checkout.initalReceivers.push(value);
            this.checkout.initalSelect = true;
        },

        //#endregion ----------------------------------------------------------------------------------------------------------


        //#region Fileupload / Attachments ------------------------------------------------------------------------------------

        onFileRead(text) {
            this.shareData.uploadValid = true;
            this.validateShareForm();
        },

        onAttachmentDownloaded(attachment, data) {
            if (attachment.type == 'shareapp1') {
                this.previewName = attachment.name;
                this.previewData = data;
                uihelper.enableStep(this.steps, 'welcome', true);
            }
        },

        //#endregion ----------------------------------------------------------------------------------------------------------

        
        //#region Submit ------------------------------------------------------------------------------------------------------

        onSubmit() {
            this.editor ? this.onSubmitEditor() : this.onSubmitOwner();
        },

        async onSubmitOwner() {
            this.$parent.setLoading(true, this.__('loading_creating_form') + '...');

            // Prepare email data
            const emailData = {
                firstName: this.checkout.receivers[0].fname,
                lastName: this.checkout.receivers[0].lname,
                senderName: this.$root.user.givenName + ' ' + this.$root.user.familyName,
                senderEmail: this.$root.user.email,
                customerName: this.customer.name,
                projectName: this.shareData.name
            };

            // Create envelope
            const envelope = await v2lib.createEnvelope('aml-portal-custom', 'simple-public-flow-with-silent-parties', false);
            log.s('ShareApp: Submit owner: Envelope created', envelope);

            // Get bearer token
            const tokenResponse = await api.getBearerToken(this.customer._id, 'shareapp', envelope.id);
            const shareAppId = tools.getUniqueId();

            // Prepare email/recipient portal config
            const flowConfig = customFlowHelper.getDefaultMailScriptConfig();
            flowConfig.translationKey = 'shareapp';
            const mailConfig = customFlowHelper.getMailScriptData(this.$root, flowConfig, emailData, this.checkout.receivers[0].language);

            // Prepare flow metadata
            const flowMetadata = customFlowHelper.getInitalFlowMetadata(this.$root, this.checkout.receivers[0], this.customer);

            // Prepare envelope userdata
            const envelopeUserdata = {
                credentials: {
                    id: this.customer._id,
                    cid: this.$root.credentials.cid,
                    key: this.$root.credentials.key,
                    bearerToken: tokenResponse.token,
                    shareAppId: shareAppId
                },
                flowMetadata: flowMetadata,
                shareData: this.shareData,
                receiver: this.checkout.receivers[0],
                checkoutPersons: this.checkout.persons,
                mail: mailConfig,
                attachments: [],
                customer: this.customer
            };
            if (this.$root.credentials.testinc) envelopeUserdatacredentials.testinc = this.$root.credentials.testinc;

            // Upload part one
            for (const i in this.infoFiles.files) {
                if (!this.infoFiles.files[i].uploaded) {
                    envelopeUserdata.attachments.push(v2lib.addFileUploadAttachmentToUserdataSafe(envelopeUserdata.attachments.length, this.infoFiles.files[i], 'shareapp1'));
                    await v2lib.addAttachment(envelope, this.infoFiles.files[i].name, this.infoFiles.files[i].data);
                    log.s('ShareApp: Submit owner: Attachment uploaded', this.infoFiles.files[i]);
                }
            }

            // Set template userdata and name
            const documentName = this.__('shareapp_pdf_name') + ' ' + this.customer.name + '.pdf';
            envelopeUserdata.fileName = documentName;
            await v2lib.setUserData(envelope, envelopeUserdata);
            await v2lib.setNameAndTags(envelope, documentName, ['tegningsblankett']);
            await v2lib.setName(envelope, documentName);

            this.$parent.setLoading(true, this.__('loading_forwarding_form') + '...');

            // Set editor recipient
            await v2lib.addRecipientPerson(envelope, this.checkout.receivers[0], this.checkout.receivers[0].language);
            log.v('ShareApp: Submit editor: Recipient added', this.checkout.receivers[0]);

            // Publish the envelope
            await v2lib.publishEnvelope(envelope);
            log.s('ShareApp: Submit owner: Envelope published');

            // Update customer in database
            const newShareAppData = {
                id: shareAppId,
                name: this.shareData.name,
                userId: this.$root.credentials.uid,
                envelopeId: envelope.id,
                sentDate: new Date(),
                filled: false,
                recipientName: this.checkout.receivers[0].fname + ' ' + this.checkout.receivers[0].lname
            };
            const createResponse = await api.createShareApplication(this.customer._id, newShareAppData);
            log.s('ShareApp: Submit owner: New application added', createResponse);
            const customerData = {
                custstat: 'sa-c',
                tags: [this.shareData.name]
            };
            const updateResponse = await api.updateCustomer(this.customer._id, customerData);
            log.s('ShareApp: Submit owner: Customer updated', updateResponse.customer);

            // Navigate back to customer view
            this.$parent.navigate({ path: '/client', query: { id: this.customer._id } });
            this.$parent.setLoading(false);
        },

        async onSubmitEditor() {
            this.$parent.setLoading(true, this.__('loading_submitting_form') + '...');

            let envelope = await v2lib.startSubmitEditor(this.envelope.id);
            log.v('ShareApp: Submit editor: Envelope initialized', envelope);

            const userdata = envelope.content.documents[0].template.userData;
            console.log('userdata', userdata);

            userdata._isForwarded = true;
            userdata.finalSubmit = true;

            // Add recipients
            if (this.checkoutData.signDirect) userdata._isForwarded = false;
            for (const i in this.checkoutData.recipients) {
                let role = { action: "sign", label: "Fylle i skjemaet", name: "signer" };
                let method = this.checkoutData.recipients[i].method;
                const recipient = {
                    givenName: this.checkoutData.recipients[i].firstName,
                    familyName: this.checkoutData.recipients[i].lastName,
                    language: this.checkoutData.recipients[i].language,
                    signingMethod: method,
                    email: this.checkoutData.recipients[i].email,
                    order: Number(i) + 2,
                    role: role
                };
                await v2lib.prepareRecipient(envelope, recipient);
                customFlowHelper.onAddRecipient(userdata.flowMetadata, recipient, i);
            }

            userdata.formData = this.formData;
            userdata.pdfLanguage = this.$root.locale.key;

            console.log(JSON.stringify(userdata));

            // Get shareapp template
            const template = await api.renderTemplate(this.$root, 'share', userdata);
            userdata.html = template.html;
            userdata.css = template.css;

            await v2lib.submitEditor(envelope, userdata);
            log.s('ShareApp: Submit editor: Envelope submitted', userdata);

            this.$parent.setLoading(true, this.__('loading_sign_link') + '...');

            const updatedData = {
                filled: true,
                fillDate: new Date()
            };
            await api.updateShareApplication(this.customer._id, userdata.credentials.shareAppId, updatedData);


            if (userdata._isForwarded) {
                // Redirect to thank you page
                this.$parent.navigate({ path: '/flow/' + this.$parent.loadedEnvelope.id + '/' + this.$parent.loadedRecipient.id, query: { submit: 'yes' }});
            }else {
                // Get the sign token
                sessionStorage.setItem(this.$parent.loadedEnvelope.id, v2lib.editorToken);
                const result = await v2lib.getSignLink2(this.$root, envelope, false);
                if (result.ok) {
                    window.location.href = result.url;
                }else {
                    this.$root.exceptions.push({
                        message: 'Det skjedde en feil under innsending av skjema',
                        info: {
                            source: 'shareapp submit, error getting sign link',
                            envelopeId: envelope.id
                        }
                    });
                    return;
                }
            }
        }

        //#endregion ----------------------------------------------------------------------------------------------------------
    },
    async created() {
        this.$parent.setLoading(true, this.__('loading_form') + '...');
        log.v('ShareApp: View created with key', this.$route.params.key);

        // Set title
        this.$parent.setTitle(this.__('shareapp_title'));
        this.$parent.headerTitle = this.__('shareapp_title');

        // Load KYC translations and merge with main
        const defaults = locale.getDefaultKycTranslations();
        const tres = await api.getTranslations('kyc');
        this.kycTranslations = { ...defaults, ...tres.texts };
        this.$root.translations = { ...this.$root.translations, ...this.kycTranslations };

        // Check if this is owner or editor
        if ((this.$route.query.document_uid && this.$route.query.access_token) || this.$route.query.editor == 'yes') {
            log.w('ShareApp: Loading editor');
            this.editor = true;

            this.envelope = this.$parent.loadedEnvelope;
            this.customer = this.envelope.userdata.customer;
            this.shareData = this.envelope.userdata.shareData;
            this.checkout.receiver = this.envelope.userdata.receiver || {};
            this.checkout.persons = this.envelope.userdata.checkoutPersons || [];

            this.shareAppData = shareapp.getEnvelopeObject();
            shareapp.getEnvelopeData(this.envelope, this.shareAppData);

            shareapp.downloadAttachments(this, this.envelope);

        }else {
            log.w('ShareApp: Loading owner');

            // Load customer
            const customerId = this.$route.query.id;
            const customerResponse = await api.getCustomer(customerId);
            this.customer = customerResponse.customer;

            // Load possible recipients from AML
            if (this.customer && this.customer.aml) {
                const envelope = await this.$root.getEnvelope(this.customer.aml.envelopeId);
                this.checkout.persons = aml.getPersonList(envelope.userdata.aml);
                for (let i in this.checkout.persons) {
                    this.checkout.persons[i].firstName = aml.titleCase(this.checkout.persons[i].firstName);
                    this.checkout.persons[i].lastName = aml.titleCase(this.checkout.persons[i].lastName);
                }
            }
        }

        this.signingMethodOptions = verified.getAvailableSigningMethods(this.$root.config.kycs[0].methods, this.$root.debug.local);

        // Load steps
        this.helpTexts = shareapp.getHelpTexts();
        this.steps = shareapp.getSteps(this.editor);
        this.stepKey = this.editor ? 'preview' : 'upload';
        
        // Show UI
        this.$parent.setLoading(false);
        this.formLoading = false;
    }
}
</script>
<style scoped>

.shareapp {
    margin-top: 80px;
}

</style>