<template>
<div>
    
    <div v-if="hasLanding()">

        <div class="lform-landing-logo" style="margin-top: 150px;">
            <img :src="$root.config.branding.logoLanding" />
        </div>

        <div class="lform-landing-header">
            <div class="lform-landing-header-center">
                <div :class="aborted ? 'lform-landing-icon-error' : 'lform-landing-icon'">
                    <vap-icon :icon="aborted ? 'cancel' : 'check'" :color="aborted ? 'var(--error-text)' : 'var(--primary-contrast)'" />
                </div>
                <div class="lform-landing-text">
                    {{ reference.landingParams[selectedModel].title[$root.locale.key] }}
                </div>
            </div>
        </div>

        <vap-card style="margin-top: 40px; ">
            <div v-html="reference.landingParams[selectedModel].message[$root.locale.key]" />
        </vap-card>

        <div class="lform-landing-footer">
            <span>{{ __t('form-landing-close') + ' ' }}</span><span><a :href="$root.config.branding.redirectUri">{{ __t('form-landing-homepage') }}</a></span>
        </div>

    </div>

    <div v-if="!hasLanding()">
        {{ 'There is no landing page for this role. Select another role using the role selector.' }}

        <!-- <vap-button :text="'Add landing page'" @clicked="addLandingPage" /> -->

    </div>

</div>
</template>
<script>
import textHelper from '../../textHelper';

export default {
    name: 'LandingEditor',
    components: {
        
    },
    props: {
        reference: { type: Object, default: null },
    },
    data() { 
        return {
            showLanding: false,
            selectedModel: 'filled',
            aborted: false,
        }
    },
    methods: {

        hasLanding() {
            // TODO fix
            console.log('role', this.reference.roleOptions[this.reference.roleIndex]);

            if (this.reference.roleOptions[this.reference.roleIndex].key == 'editor') {
                this.selectedModel = 'filled';
                return true;

            }else if (this.reference.roleOptions[this.reference.roleIndex].key == 'signer') {
                this.selectedModel = 'signed';
                return true;
            }

            return false;
        },

        addLandingPage() {
            const role = this.reference.roleOptions[this.reference.roleIndex];
            console.log('role', role);

            role.hasLanding = true;

            if (!role.landingParams) {
                role.landingParams = {
                    redirect: 'default',
                };
            }

            role.landingParams.texts = [];

            const text = {
                title: textHelper.getEmptyTextObject(this.reference),
                message: textHelper.getEmptyTextObject(this.reference),
                triggers: [],
            };

            role.landingParams.texts.push(text);

        },

    },
    created() {
        
        console.log('role', this.reference.roleOptions[this.reference.roleIndex]);

    }
}
</script>
<style scoped>

.lform-landing-logo {
    float: left;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}
.lform-landing-logo img {
    width: 360px;
}

.lform-landing-header {
    float: left;
    width: 100%;
    margin-top: 40px;
}
.lform-landing-header-center {
    display: flex;
    width: fit-content;
    margin: 0 auto;
}
.lform-landing-icon {
    width: 45px; height: 45px;
    padding-top: 5px;
    padding-left: 5px;
    border-radius: 50%;
    background-color: var(--primary);
    box-sizing: border-box;
}
.lform-landing-icon-error {
    width: 45px; height: 45px;
    padding-top: 4px;
    padding-left: 4px;
    border-radius: 50%;
    background-color: var(--error-background);
    box-sizing: border-box;
}
.lform-landing-text {
    margin-top: 10px;
    margin-left: 10px;
    font-size: 20px;
}
.lform-landing-footer {
    float: left;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}

</style>