<template>
    <div v-html="html">
        
        
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'FormHelpContentV2',
    components: {
        
    },
    props: {
        file: { type: String, default: '' }
    },
    data() {
        return {
            html: ''
        }
    },
    watch: {
        file: {
            deep: false,
            handler(val) {
                this.readFile(val);
            }
        }
    },
    methods: {
        
        async readFile(file) {
            const response = await axios.get('guide/pages/' + file + '.html');
            this.html = response.data;
        }

    },
    async created() {
        this.readFile(this.file);
    }
}
</script>
<style scoped>

</style>
