<template>
<div>
    
    <ve-popup v-if="params.action == 'person'" :header="__('common_add') + ' ' + __('common_person')" :width="500" @close="onClose" hideCloseButton>
        <div style="padding: 20px;">
            <vap-row>
                <vap-group c6>
                    <vap-label :text="__('common_first_name')" desc required />
                    <vap-input v-model="model.firstName" @changed="onValidate" :placeholder="__('common_required')" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_last_name')" desc required />
                    <vap-input v-model="model.lastName" @changed="onValidate" :placeholder="__('common_required')" />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_dob')" desc required />
                    <vap-datepicker v-model="model.dateOfBirth" @changed="onValidate" outputISO />
                </vap-group>
                <vap-group c6>
                    <vap-label :text="__('common_role')" desc required />
                    <vap-dropdown v-model="model.role" @input="onValidate" :options="roleOptions" :displayKey="$root.locale.key" :listKey="$root.locale.key" :outputKey="'value'" />
                </vap-group>
                <vap-group c12 v-if="model.role == 'common_other'">
                    <vap-label :text="__('common_role')" desc required />
                    <vap-input v-model="model.roleOther" @changed="onValidate" :placeholder="'Beskriv rolle'" />
                </vap-group>
                <vap-group c12 v-if="model.role == 'screening_role_rrh' || model.role == 'screening_role_shareholder'">
                    <vap-label :text="__('common_share')" desc required />
                    <vap-input v-model="model.share" @changed="onValidate" :placeholder="__('common_share')" />
                </vap-group>
            </vap-row>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="onClose" :disabled="progress.working" />
                <vap-button :text="__('common_add')" uppercase @clicked="onAddPerson" :working="progress.working" :disabled="!valid" />
            </vap-button-row>
        </div>
    </ve-popup>

    <ve-popup v-if="params.action == 'role'" :header="__('common_add') + ' ' + __('common_role')" :width="500" @close="onClose" hideCloseButton>
        <div style="padding: 20px;">
            <vap-row>
                <vap-group c12>
                    <vap-label :text="__('common_role')" desc required />
                    <vap-dropdown v-model="model.role" @input="onValidate" :options="roleOptions" :displayKey="$root.locale.key" :listKey="$root.locale.key" :outputKey="'value'" />
                </vap-group>
                <vap-group c12 v-if="model.role == 'common_other'">
                    <vap-label :text="__('common_role')" desc required />
                    <vap-input v-model="model.roleOther" @changed="onValidate" :placeholder="'Beskriv rolle'" />
                </vap-group>
                <vap-group c12 v-if="model.role == 'screening_role_rrh' || model.role == 'screening_role_shareholder'">
                    <vap-label :text="__('common_share')" desc required />
                    <vap-input v-model="model.share" @changed="onValidate" :placeholder="__('common_share')" />
                </vap-group>
            </vap-row>
            <vap-button-row alignRight style="margin-top: 20px; margin-bottom: 20px;">
                <vap-button :text="__('common_cancel')" uppercase @clicked="onClose" :disabled="progress.working" />
                <vap-button :text="__('common_add')" uppercase @clicked="onAddRole" :working="progress.working" :disabled="!valid" />
            </vap-button-row>
        </div>
    </ve-popup>

</div>
</template>
<script>


export default {
    name: 'AddManualDialog',
    props: {
        params: { type: Object, default: null },
        progress: { type: Object, default: null }
    },
    data() {
        return {
            valid: false,
            model: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                role: '',
                roleOther: ''
            },
            roleOptions: [
                { value: 'role_proprietor' },
                { value: 'role_ceo' },
                { value: 'role_chair' },
                { value: 'role_procura' },
                { value: 'role_deputy_chair' },
                { value: 'role_rrh' },
                { value: 'role_board_member' },
                { value: 'role_deputy_bm' },
                { value: 'role_shareholder' },
                { value: 'role_accountant' },
                { value: 'role_customer_contact' },
                { value: 'role_cfo' },
                { value: 'role_commercial' },
                { value: 'role_signatory' },
                { value: 'role_poa' },
                { value: 'role_other' }
            ],
        }
    },
    methods: {
        
        onValidate() {
            if (this.params.action == 'person') {

                if (this.model.firstName.length > 0 && this.model.lastName.length > 0
                && this.model.dateOfBirth.length > 0 && this.model.role.length > 0) {
                    this.valid = true;
                }else {
                    this.valid = false;
                }

            }else {

                this.valid = true;

            }
        },

        onAddPerson() {
            this.$emit('person', this.model);
        },

        onAddRole() {
            this.$emit('role', this.model, this.params.person);
        },

        onClose() {
            this.$emit('close');
        }

    },
    async created() {
        for (const i in this.roleOptions) {
            for (const j in this.$root.config.locales) {
                this.roleOptions[i][this.$root.config.locales[j].key] = this.$root.translations[this.roleOptions[i].value][this.$root.config.locales[j].key];
            }
        }
    }
}
</script>
<style scoped>


</style>