<template>
<div>

    <PropertyItem :header="'Checkout Properties'" :icon="'sign'">
        <vap-row>
            <vap-group c12>
                <vap-label :text="'directText'" desc />
                <vap-input v-model="card.directText[$root.locale.key]" noValidation />
            </vap-group>
        </vap-row>
    </PropertyItem>

</div>
</template>
<script>
import FormMenuButton from '../FormMenuButton.vue';

import PropertyItem from './PropertyItem.vue';

export default {
    name: 'CardProperties',
    components: {
        FormMenuButton,
        PropertyItem
    },
    props: {
        card: { type: Object, default: null },
    },
    data() {
        return {
            
        }
    },
    methods: {

    },
    created() {
        console.log('heiheitest')
    }
}
</script>