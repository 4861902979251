<template>
<div>

    <!-- Input -->
    <PropertyItem padded :header="'Model properties'" :icon="'input-text'" v-if="input">

        <DesignerGroup c6 v-if="!input.isList">
            <DesignerLabel :text="'Model key'" />
            <DesignerSelector isInput
                v-model="input.modelKey"
                @clicked="showModelDialog(false, false)"
                @before="$emit('propinput', 'text', 'model_key', $event)"
            />
        </DesignerGroup>
        <DesignerGroup c6 v-if="input.isList">
            <DesignerLabel :text="'List model key'" />
            <DesignerSelector isInput
                v-model="input.listModel"
                @clicked="showModelDialog(true, false)"
                @before="$emit('propinput', 'text', 'model_list', $event)"
            />
        </DesignerGroup>

        <DesignerGroup c6>
            <DesignerLabel :text="'Form key'" />
            <DesignerInput v-model="input.oldFormKey" @before="$emit('propinput', 'text', 'form_key', $event)" />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerCheckbox v-model="input.dbIndex" :text="'Indexed'" @before="$emit('propinput', 'check', 'indexed', $event)" />
        </DesignerGroup>

    </PropertyItem>


    <!-- Card -->
    <PropertyItem padded :header="'Model properties'" :icon="'input-text'" v-if="card">

        <DesignerGroup c6>
            <DesignerLabel :text="'Model key'" />
            <DesignerSelector isInput
                v-model="card.modelKey"
                @clicked="showModelDialog(false, true)"
                @before="$emit('propinput', 'text', 'model_key', $event)"
            />
        </DesignerGroup>

        <DesignerGroup c6>
            <DesignerLabel :text="'Role ID'" />
            <DesignerSelector isInput
                v-model="card.roleId"
                @clicked="showModelDialog(false, true)"
                @before="$emit('propinput', 'text', 'role_id', $event)"
            />
        </DesignerGroup>

        <DesignerGroup c12>
            <DesignerCheckbox v-model="card.mdmodel" :text="'Add to data model'" @before="$emit('propinput', 'check', 'datamodel', $event)" />
        </DesignerGroup>

    </PropertyItem>


    <!-- Select dialog -->
    <PopupV1 v-if="dialog.show" noPadding designer :width="500" :maxHeight="500" :header="'Select model key'" @close="dialog.show = false" :dialog="dialog">
        <template v-slot:content>
            <div>
                <PropertyListItem v-for="(option, i) in options" :key="'lo' + i"
                    :icon="option.icon"
                    :text="option.display"
                    @clicked="onModelSelected(option)"
                />
            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="__t('common-cancel')" outline @clicked="dialog.show = false" />
        </template>
    </PopupV1>

</div>
</template>
<script>
import editorHelper from '../../editorHelper';

import PropertyItem from './PropertyItem.vue';
import DesignerGroup from '../ui/inputs/DesignerGroup.vue';
import DesignerLabel from '../ui/inputs/DesignerLabel.vue';
import DesignerInput from '../ui/inputs/DesignerInput.vue';
import DesignerCheckbox from '../ui/inputs/DesignerCheckbox.vue';
import DesignerSelector from '../ui/inputs/DesignerSelector.vue';

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';
import PropertyListItem from '../ui/PropertyListItem.vue';

export default {
    name: 'ModelProperties',
    components: {
        PropertyItem,
        DesignerGroup,
        DesignerLabel,
        DesignerInput,
        DesignerCheckbox,
        DesignerSelector,
        PopupV1,
        VFButton,
        PropertyListItem,
    },
    props: {
        input: { type: Object, default: null },
        card: { type: Object, default: null },
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: { show: false, isList: false, isCard: false },
            options: [
                { value: 'firstName', icon: 'person', display: 'First Name' },
                { value: 'lastName', icon: 'person', display: 'Last Name' },
                { value: 'dateOfBirth', icon: 'person', display: 'Date of Birth' },
                { value: 'street', icon: 'home', display: 'Street Adress' },
                { value: 'zip', icon: 'home', display: 'Zip Code' },
                { value: 'city', icon: 'home', display: 'City' },
                { value: 'country', icon: 'home', display: 'Country' },
                { value: 'citizenship', icon: 'locale', display: 'Citizenship' },
                { value: 'email', icon: 'alpha', display: 'Email' },
                { value: 'phone', icon: 'phone', display: 'Phone (for phone input with country prefix)' },
                { value: 'phoneNumber', icon: 'phone', display: 'Phone Number' },
                { value: 'title', icon: 'pep', display: 'Title (for PEP)' },
                { value: 'roleId', icon: 'person', display: 'Role ID (type of role f.ex. "role_ceo")' },
            ]
        }
    },
    methods: {

        showModelDialog(isList, isCard) {
            this.dialog.isList = isList;
            this.dialog.isCard = isCard;
            this.dialog.show = true;
        },

        onModelSelected(option) {
            if (this.dialog.isList) {
                this.input.listModel = option.value;
                editorHelper.onChange(this.reference, 'select_model_list', false);
            }else {
                if (this.dialog.isCard) {
                    this.card.modelKey = option.value;
                }else {
                    this.input.modelKey = option.value;
                }
                editorHelper.onChange(this.reference, 'select_model_key', false);
            }
            this.dialog.show = false;
        },

    }
}
</script>