<template>
<div>

    <PopupV1 :header="'Help text'" @close="$emit('close')" :dialog="dialog">
        <template v-slot:content>
            <div>

                <div class="fwl100" style="color: white; margin-top: 20px; margin-bottom: 6px;">
                    {{ 'Source:' }}
                </div>

                <div class="frv2-trans-selector">
                    <div class="frv2-trans-selector-flag" v-for="(role, i) in roles" :key="'tdl' + i" :class="[
                        {'frv2-trans-selector-source': role.key == 1 }
                    ]" @click="setSourceLanguage(locale.key)">
                        {{ role }}
                    </div>
                </div>

                <div class="flw100" style="margin-top: 10px;">
                    <!-- <DesignerTextArea v-model="params.text[sourceLanguage]" /> -->
                </div>


                <hr class="flw100" style="width: 60%; margin-left: 20%; margin-top: 20px; border: solid 1px var(--fb-menu-c);">


                <div class="frv2-trans-item" v-for="(role, i) in roles" :key="'txl' + i" >
                    <div class="frv2-trans-header">
                        <div class="frv2-trans-header-icon">
                            {{ 'role' }}
                        </div>
                        <div class="frv2-trans-header-button" @click="translate(role.key)">
                            {{ 'TRANSLATE' }}
                        </div>
                    </div>
                    <div>
                        <!-- <DesignerTextArea v-model="params.text[locale.key]" /> -->
                    </div>
                </div>

            </div>
        </template>
        <template v-slot:buttons>
            <VFButton :text="'Cancel'" outline
                @clicked="$emit('close')"
            />
        </template>
    </PopupV1>
    
</div>
</template>
<script>

import PopupV1 from '../../../../v1ui/PopupV1.vue';
import VFButton from '../../items/VFButton.vue';

export default {
    name: 'MergeHelplineDialog',
    components: {
        PopupV1,
        VFButton,
    },
    props: {
        helpline: { type: Object, default: () => { return {} }},
        reference: { type: Object, default: null },
    },
    data() {
        return {
            dialog: { show: true, valid: false, working: false, status: '', error: '' },
            roles: [],
        }
    },
    methods: {
        

        
    },
    created() {
        console.log('helpline', this.helpline);

        for (const i in this.reference.pages) {


            for (const j in this.reference.pages[i].info) {

                for (const k in this.reference.pages[i].info[j]) {

                    if (this.reference.pages[i].info[j][k].key == this.helpline.key) {

                        for (const x in this.reference.pages[i].info) {

                            const role = {
                                display: x,
                                lines: [],
                            };

                            for (const y in this.reference.pages[i].info[x]) {
                                role.lines.push(this.reference.pages[i].info[x][y]);
                            }

                            this.roles.push(role);

                        }

                    }

                }

            }

        }

    }
}
</script>
<style scoped>



</style>