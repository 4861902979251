<template>
<div>
    
    <div v-if="!searching && person.uimodel.doSearch && !person.uimodel.didSearch">

        <div class="vperni-row vperni-thin">
            <div class="vperni-cell vperni-single" :style="'width: 100%;'">
                {{ 'Søkekriterier' }}
            </div>
        </div>

        <div class="vperni-row vperni-thin">

            <div class="vperni-cell vperni-single nohl" :style="'width: 50%;'"
            :class="modeModel.name ? 'vperni-highlight' : 'vperni-button'" @click="selectMode('name')">
                {{ 'Navn' }}
            </div>

            <div class="vperni-cell vperni-single vperni-border nohl" :style="'width: 50%;'"
            :class="[
                { 'vperni-highlight': modeModel.ssn },
                { 'vperni-button': !modeModel.ssn && person.info.ssn }
            ]"
            @click="selectMode('ssn')">
                {{ 'Fødselsnummer' }}
            </div>

        </div>

        <div class="vperni-row vperni-tall">
            <div class="vperni-cell vperni-double" :style="'width: 100%;'">
                <span v-if="modeModel.name">{{ 'Det vil søkes på navn / fødselsdato.' }}</span>
                <span v-if="modeModel.ssn">{{ 'Det vil søkes på fullt fødselsnummer. Data fra folkeregisteret vil være tilgjengelig hvis aktivert.' }}</span>
            </div>
        </div>

        <div class="vperni-row">

            <div class="vperni-cell" :style="'width: 65%;'">
                <img :src="$root.images.bamltiny" :style="'width: 130px;'" />
            </div>

            <div class="vperni-cell vperni-border vperni-button nohl" :style="'width: 35%;'" @click="performSearch">
                {{ 'Søk' }}
            </div>

        </div>

    </div>

    <div v-if="!searching && person.uimodel.didSearch">

        <div class="vperni-row" :class="[
            { 'vperni-result-s': !person.search.isPep && !person.search.hasSanction },
            { 'vperni-result-w': person.search.isPep && !person.search.hasSanction },
            { 'vperni-result-e': person.search.hasSanction }
        ]">
            <div class="vperni-cell" :style="'width: 100%;'">
                <span v-if="!person.search.isPep && !person.search.hasSanction">{{ __('hit_none') }}</span>
                <span v-if="person.search.isPep && !person.search.hasSanction">{{ __('hit_pep') }}</span>
                <span v-if="person.search.hasSanction && !person.search.isPep">{{ __('hit_sanction') }}</span>
                <span v-if="person.search.hasSanction && person.search.isPep">{{ 'Treff på PEP og SANKSJON' }}</span>
            </div>
        </div>

        <div class="vperni-row">

            <div class="vperni-cell" :style="'width: 100%;'" v-if="person.search.isPep ">
                {{ person.search.pepTitle }}
            </div>
            <div class="vperni-cell" :style="'width: 100%;'" v-if="!person.search.isPep ">
                {{ 'Det er søkt mot følgende lister:' }}
            </div>

        </div>

        <div class="vperni-row">
            <div class="vperni-cell" :style="'width: 100%;'" v-if="person.search.isPep">
                {{ person.search.pepMatchDescription }}
            </div>
            <div class="vperni-cell vperni-date" :style="'width: 100%;'" v-if="!person.search.isPep">
                {{ person.search.listString }}
            </div>
        </div>

        <div class="vperni-row">

            <div class="vperni-cell vperni-date" :style="'width: 44%;'">
                {{ getDateString(person.search.dateCompleted) }}
            </div>

            <div class="vperni-cell vperni-date vperni-border" :style="'width: 44%;'">
                {{ getTimePassedString(person.search.dateCompleted) }}
            </div>

<!--             <div class="vperni-cell vperni-border vperni-button" :style="'width: 12%;'">
                <vap-icon small :icon="'menu-h'" absolute :top="3" :right="21" :color="'ext'" />
            </div> -->

            <div class="vperni-cell vperni-border vperni-button" :style="'width: 12%;'" @click="$emit('receipt')">
                <vap-icon small :icon="'file-sent'" absolute :top="3" :right="21" :color="'ext'" />
            </div>

        </div>


    </div>

    <div v-if="!searching && !person.uimodel.doSearch">

        <div class="vperni-empty-icon">
            <vap-icon :icon="'search'" massive :color="'var(--text-disabled)'" />
        </div>
        <div class="vperni-empty-text">
            {{ 'Ikke valgt' }}
        </div>

    </div>

    <div v-if="searching">

        <div class="flw100" style="position: relative; padding-top: 20px;">
            <div style="position: absolute; left: 50%;">
                <vap-spinner medium />
            </div>
        </div>

    </div>

</div>
</template>

<script>
import tools from '../../tools';

export default {
    name: 'PersonStatusPEP',
    components: {
        
    },
    props: {
        person: { type: Object, default: () => { return {} } },
    },
    data() {
        return {
            modeModel: {
                name: true,
                ssn: false
            },
            searching: false
        }
    },
    watch: {
        amlData: {
            deep: false,
            handler(val) {
                
            }
        },
    },
    methods: {
        
        selectMode(mode) {
            if (mode == 'ssn') {
                if (!this.person.info.ssn) return;
                this.modeModel.name = false;
                this.modeModel.ssn = true;
            }else {
                this.modeModel.name = true;
                this.modeModel.ssn = false;
            }
        },

        performSearch() {
            this.searching = true;
            this.$emit('search', this.modeModel.ssn);
        },

        getDateString(date) {
            return tools.dateStringShort(date, this.$root.locale.key);
        },

        getTimePassedString(date) {
            return tools.timePassedString(date, this.$root);
        },

    },
    created() {
        console.log('person', this.person);
    }
}
</script>

<style scoped>

.vperni-row {
    float: left;
    width: 100%;
    height: 34.5px;
    display: flex;
    border-bottom: solid 1px var(--input-border);
}

.vperni-tall {
    height: 43px;
}
.vperni-thin {
    height: 30px;
}

.vperni-cell {
    text-align: center;
    padding-top: 9px;
}

.vperni-single {
    padding-top: 6px;
}
.vperni-double {
    padding-top: 6px;
    font-size: 13px;
}

.vperni-border {
    border-left: solid 1px var(--input-border);
}

.vperni-date {
    padding-top: 10px;
    font-size: 14px;
    color: var(--text-weak);
}

.vperni-highlight {
    color: var(--accent);
    background-color: lightgray;
}

.vperni-button {
    position: relative;
    cursor: pointer;
    fill: gray;
}
.vperni-button:hover {
    color: var(--button-text);
    background-color: var(--button);
    fill: var(--button-text);
}


.vperni-result-s {
    color: var(--success-text);
    background-color: var(--success-background);
}
.vperni-result-w {
    color: var(--warning-text);
    background-color: var(--warning-background);
}
.vperni-result-e {
    color: var(--error-text);
    background-color: var(--error-background);
}


.vperni-empty-icon {
    padding-left: 125px;
}
.vperni-empty-text {
    text-align: center;
    color: var(--text-weak);
}

</style>
