<template>
    <div>
        <PropertyItem :header="'Header properties'" :icon="'locale'" :initialCollapse="initialCollapse">
            <vap-row>
                <vap-group c12>
                    <vap-label :text="'Text'" desc />
                    <vap-input v-model="card.value[$root.locale.key]" noValidation />
                </vap-group>

                <vap-group c12>
                    <vap-label :text="'Size'" desc />
                    <vap-input :numeric="true" v-model="card.size" noValidation />
                </vap-group>

                <vap-group c12>
                    <vap-label :text="'Margin'" desc />
                    <vap-input :numeric="true" v-model="card.margin" noValidation />
                </vap-group>
            </vap-row>
        </PropertyItem>
    </div>
</template>

<script>
import FormMenuButton from '../FormMenuButton.vue';

import PropertyItem from './PropertyItem.vue';

export default {
    name: 'CardProperties',
    components: {
        FormMenuButton,
        PropertyItem
    },
    props: {
        card: { type: Object, default: null },
        initialCollapse: { type: Boolean, default: false },
    },
    data() {
        return {
            
        }
    },
    methods: {
        

    },
    mounted() {

    }
}
</script>
<style scoped>

.frv2-opts-actions {
    display: flex;
    flex-direction: row-reverse;
    float: left;
    width: 100%;
    padding: 0px 15px;
    box-sizing: border-box;
}

</style>
