<template>
<div v-if="loadingComplete">
    <div class="slim-page" v-if="!isV1">


        <vap-card :header="'Sluttrapport'" style="margin-top: 60px;">

            <span class="flw100" style="margin-top: 10px;">
                {{
                    this.$parent.loadedEnvelope.content.sender.givenName + ' ' + this.$parent.loadedEnvelope.content.sender.familyName
                    + ' har sendt deg sluttrapport for ' + this.$parent.loadedEnvelope.userdata.customer.name
                }}
            </span>

            <vap-button-row alignLeft style="margin-top: 30px; margin-bottom: 20px;">
                <vap-button :text="'Vis rapport'" @clicked="showReceipt2" />
            </vap-button-row>

        </vap-card>


        <PreviewPopup v-if="preview2Visible"
            :envelopeId="preview2EnvelopeId"
            :blob="preview2RawData"
            :filename="preview2Filename"
            @close="preview2Visible = false"
            :showAttachment="preview2Attachment"
        />

    </div>
    <div class="slim-page" v-if="isV1">

        <PreviewPopupV1 v-if="previewVisible" :params="previewParams" @close="previewVisible = false" />

    </div>
</div>
</template>

<script>
import v2lib from '../v2lib';
import verified from '../verified';
import reporthelper from '../helpers/reporthelper';
import PreviewPopup from '../components/documents/PreviewPopup';

import previewHelper from '../v1files/previewHelper';
import PreviewPopupV1 from '../v1files/PreviewPopupV1.vue';

export default {
    name: 'Assessment',
    components: {
        PreviewPopup,
        PreviewPopupV1,
    },
    data() {
        return {
            loadingComplete: false,
            preview2Visible: false,
            preview2EnvelopeId: '',
            preview2Filename: '',
            preview2RawData: '',
            preview2Attachment: true,

            isV1: false,
            previewParams: null,
            previewVisible: false,
        }
    },
    methods: {

        async showReceipt2(envelopeId, type, testData) {
            // Render template locally

            console.log('envelope', this.$parent.loadedEnvelope);

            const config = reporthelper.getReportConfig(this.$root);
            config.ssn = false;
            config.appendAttachments = false;
            config.hintAttachments = false;

            for (const i in this.$parent.loadedEnvelope.content.documents[0].files.attachments) {
                const attachment = this.$parent.loadedEnvelope.content.documents[0].files.attachments[i];
                console.log('attachment', attachment);

                const type = 'aml';
                if (attachment.name == 'Egenerklæring.pdf') type = 'kyc';
 
                const url = await verified.getAttachmentDownloadUrl(this.$parent.loadedEnvelope, attachment.name);
                //console.log('url', url);
                config.appendFiles.push({
                    type: type,
                    url: url,
                    name: attachment.name,
                    fileType: 'application/pdf',
                    description: type,
                    info: type
                });

            }

            const data = {
                metadata: this.$parent.loadedEnvelope.userdata.metadata,
                customer: this.$parent.loadedEnvelope.userdata.customer,
                brregData: this.$parent.loadedEnvelope.userdata.brregData,
                manualData: this.$parent.loadedEnvelope.userdata.manualData,
                aml: this.$parent.loadedEnvelope.userdata.amlData,
                persons: this.$parent.loadedEnvelope.userdata.persons,
                reportData: this.$parent.loadedEnvelope.userdata.report,
                kycMetadata: this.$parent.loadedEnvelope.userdata.kycMetadata,
            };

            this.preview2RawData = await reporthelper.downloadPDF(this.$root, data, config);
            this.preview2Visible = true;
        },

    },
    async created() {
        this.$parent.setLoading(true, 'Laster inn' + '...');
        console.log('ud', this.$parent.loadedEnvelope.userdata);

        this.$root.editor = true;


        this.$parent.setTitle('Sluttrapport');


        const envelope = await v2lib.getEditorEnvelope(this.$parent.loadedEnvelope.id);
        //console.log('envelope', envelope);


        if (this.$parent.loadedEnvelope.userdata.forward) {
            // V0

            if (!this.$parent.loadedEnvelope.userdata.forward.completed) {
                this.$parent.loadedEnvelope.userdata.forward.completed = true;
                const response = await v2lib.setUserData(envelope, this.$parent.loadedEnvelope.userdata);
                //console.log('response', response);
            }

        }else {
            // V1

            this.isV1 = true;

            console.log('V1');

            this.previewParams = previewHelper.getPartnerPreviewParams(this.$parent.loadedEnvelope);
            console.log('preview params', this.previewParams);
            this.previewVisible = true;


        }


        
        



        this.loadingComplete = true;
        this.$parent.setLoading(false);

        //this.showReceipt2()
    }
}
</script>

<style scoped>



</style>