<template>
<div class="vap-tabs" :class="[{'vap-tabs-border': bordered}, {'vap-tabs-accent': accent}, {'vap-tabs-compact': compact}]">
    
    <div v-for="(item, id, i) in tabs" :key="id" class="vap-tabs-cell"
        :class="[
            {'vap-tabs-first' : i == 0},
            {'vap-tabs-last' : i == Object.keys(tabs).length - 1},
            {'vap-tabs-middle' : i != 0 && i != Object.keys(tabs).length - 1},
            {'vap-tabs-selected' : value[id]},
            {'vap-tabs-wimg': item.image}
        ]">

        <div class="vap-tabs-item" @click="handleClick(id)">
            <div class="vap-tabs-item-con">
                <div class="vap-tabs-item-img" v-if="item.image">
                    <img :src="item.image" />
                </div>
                <div class="vap-tabs-item-icn" v-if="item.icon">
                    <vap-icon v-if="value[id]" :icon="item.icon" :color="accent ? 'var(--accent-contrast)' : 'var(--primary)'" :small="compact" />
                    <vap-icon v-if="!value[id]" :icon="item.icon" :color="accent ? 'var(--tab)' : 'var(--primary)'" :small="compact" />
                </div>
                <div class="vap-tabs-item-lbl">
                    <label>{{ item.key ? __(item.key) : item.label }}</label>
                </div>
            </div>
        </div>

    </div>

</div>
</template>

<script>

export default {
    name: 'vap-tabs',
    props: {
		value: {
			type: Object,
			default: () => { return {} }
		},
		tabs: {
			type: Object,
			default: () => { return {} }
		},
		bordered: {
			type: Boolean,
			default: false
		},
		accent: {
			type: Boolean,
			default: false
		},
		compact: {
			type: Boolean,
			default: false
		}
	},
    data() {
        return {
            
        }
    },
    methods: {
        handleClick(id) {
            let val = {};
            for (let key in this.value) {
                val[key] = false;
                if (key == id) val[key] = true;
            }
            this.$emit('input', val);
        }
    },
    async created() {
        
    }
}
</script>

<style>

.vap-tabs {
    display: table;
    table-layout: fixed;
    float: left;
    width: 100%;
    background-color: var(--page-background);
    box-sizing: border-box;
}

.vap-tabs-border {
    border-radius: 3px;
    border: solid 1px var(--tab);
}

.vap-tabs-cell {
    display: table-cell;
    width: 100px;
    background-color: var(--card-background);
    opacity: 0.4;
    box-sizing: border-box;
}

.vap-tabs-item {
    min-height: 40px;
    padding-top: 3px;
    padding-bottom: 5px;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 16px;
    box-sizing: border-box;
    color: var(--text-strong);
}
.vap-tabs-first {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 0px;
}
.vap-tabs-border .vap-tabs-middle {
    border-left: solid 1px var(--tab);
}
.vap-tabs-last {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0px;
}
.vap-tabs-border .vap-tabs-last {
    border-left: solid 1px var(--tab);
}

.vap-tabs-item:hover {
    
}
.vap-tabs-item:hover label {
    
}

.vap-tabs-selected {
    opacity: 1;
}
.vap-tabs-selected label {
    
}

.vap-tabs-item-con {
    display: flex;
    width: fit-content;
    margin: 0 auto;
}

.vap-tabs-item-img img {
    width: 24px;
    border-radius: 2px;
    margin-top: 10px;
}

.vap-tabs-item-icn {
    margin-top: 4px;
    margin-right: 14px;
}

.vap-tabs-item-lbl {
    margin-top: 13px;
    margin-bottom: 8px;
    margin-left: 10px;
}
.vap-tabs-wimg .vap-tabs-item-lbl {
    margin-top: 9px;
    margin-left: 10px;
}

.vap-tabs-item-lbl label {
    color: var(--text-primary);
    cursor: pointer;
}

.vap-tabs-selected .vap-tabs-item-lbl label {
    color: var(--tab);
}



.vap-tabs-accent .vap-tabs-cell {
    background-color: var(--card-background);
    opacity: 1;
}
.vap-tabs-accent .vap-tabs-cell:hover {
    opacity: 0.6;
}
.vap-tabs-accent .vap-tabs-item-lbl label {
    color: var(--tab);
}

.vap-tabs-accent .vap-tabs-middle {
    border-left: solid 1px var(--text-disabled);
}
.vap-tabs-accent .vap-tabs-last {
    border-left: solid 1px var(--text-disabled);
}

.vap-tabs-accent .vap-tabs-selected .vap-tabs-item {
    background-color: var(--tab);
}
.vap-tabs-accent .vap-tabs-selected.vap-tabs-cell:hover {
    opacity: 1;
}

.vap-tabs-accent .vap-tabs-selected .vap-tabs-item-lbl label {
    color: white;
}


.vap-tabs-compact {
    max-height: 30px;
}

.vap-tabs-compact .vap-tabs-item {
    min-height: 34px;
    max-height: 34px;
    padding-top: 0px;
}

.vap-tabs-compact .vap-tabs-item-lbl {
    margin-top: 9px;
    margin-bottom: 0px;
    margin-left: 10px;
}

</style>
