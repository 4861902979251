<template>
<div>
    
    <div class="frv2-mmod" v-for="(slot, i) in slots" :key="'mm' + i">
        <div class="frv2-mmod-empty" v-if="slot.type == 'empty'" @click="$emit('add')">
            <div class="frv2-mmod-icon">
                <vap-icon :icon="'add'" :color="'var(--disabled-background)'" />
            </div>
        </div>
        <div class="frv2-mmod-mod" v-if="slot.type != 'empty'" @click="$emit('select', i, slot)">
            <div class="frv2-mmod-mod-icon">
                <vap-icon :icon="slot.icon" :color="'var(--accent)'" />
            </div>
            <div class="frv2-mmod-mod-text">
                {{ slot.name }}
            </div>
            <div class="frv2-mmod-mod-actions">
                <vap-icon :icon="'edit'" absolute action :top="-5" :right="0" @clicked="$emit('edit', i, slot)" />
            </div>
        </div>
    </div>

    <div class="frv2-fds-empty" v-if="slots.length == 0">
        {{ 'No items available' }}
    </div>

</div>
</template>

<script>

export default {
    name: 'FormDialogSelector',
    props: {
        items: { type: Array, default: () => { return [] }},
        showEmpty: { type: Boolean, default: false }
    },
    data() {
        return {
            slots: []
        }
    },
    watch: {
        items: {
            deep: false,
            handler(val) {
                this.loadSlots();
            }
        },
    },
    methods: {
        
        loadSlots() {
            this.slots = [];
            for (const i in this.items) {
                this.slots.push(this.items[i]);
            }
            if (this.showEmpty) {
                while (this.slots.length < 4) {
                    this.slots.push({ type: 'empty', icon: '', name: '' });
                }
            }
        },

    },
    created() {
        this.loadSlots();
    }
}
</script>
<style scoped>

.frv2-mmod {
    margin-bottom: 10px;
}

.frv2-mmod-empty {
    float: left;
    width: 100%;
    margin-bottom: 10px;
    border: dotted 2px lightgray;
    border-radius: 6px;
    cursor: pointer;
}
.frv2-mmod-empty:hover {
    background-color: var(--item-hover);
}
.frv2-mmod-icon {
    width: 40px;
    margin: 0 auto;
}

.frv2-mmod-mod {
    display: flex;
    min-height: 40px;
    padding-top: 7px;
    padding-left: 10px;
    border: solid 2px var(--accent);
    border-radius: 6px;
    background-color: var(--card-background);
    box-sizing: border-box;
    cursor: pointer;
}
.frv2-mmod-mod:hover {
    background-color: var(--item-hover);
}

.frv2-mmod-mod-icon {
    margin-top: -6px;
}

.frv2-mmod-mod-text {
    flex-grow: 100;
    margin-left: 10px;
    font-size: 20px;
    color: var(--accent);
}

.frv2-mmod-mod-actions {
    position: relative;
}

.frv2-fds-empty {
    padding-left: 10px;
    font-size: 15px;
    color: var(--text-weak);
}

</style>