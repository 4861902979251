<template>
<div class="cstmr-no-hits">
    <div class="cstmr-no-hits-icon">
        <vap-icon :icon="icon" massive :color="'var(--text-disabled)'" />
    </div>
    <div class="cstmr-no-hits-text">
        <label>{{ text }}</label>
    </div>
</div>
</template>
<script>

export default {
    name: 'EmptyIcon',
    props: {
        icon: { type: String, default: '' },
        text: { type: String, default: '' }
    }
}
</script>
<style scoped>

.cstmr-no-hits {
    float: left;
    width: 100%;
}
.cstmr-no-hits-icon {
    width: 100px;
    margin: 0 auto;
    margin-top: 100px;
}
.cstmr-no-hits-text {
    text-align: center;
    color: var(--text-weak);
    margin-top: 20px;
    margin-bottom: 100px;
}

</style>