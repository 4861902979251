<template>
    <div class="slim-page slim-page-mt" v-if="loadingComplete">

        <vap-card>
            <!-- <label>{{ 'Hei ' + recipient.displayName + ' role=' + recipient.action }}</label> -->
            <label>{{ 'Thank you' }}</label><br>
            <label>{{ 'This form is already completed' }}</label>
        </vap-card>

    </div>
</template>

<script>
import v2lib from '../v2lib';
import locale from '../locale';
import log from '../log';
import formHelper from '@/components/form/formHelper';

export default {
    name: 'FormRecipient',
    data() {
        return {
            loadingComplete: false,
            recipient: { displayName: '' }
        }
    },
    methods: {

        readRecipient(recipient) {
            return {
                displayName: recipient.givenName + ' ' + recipient.familyName,
                action: recipient.role.action,
                completed: recipient.completed
            };
        },

    },
    async created() {
        this.$parent.landing = true;
        this.$parent.setLoading(true, 'Checking envelope' + '...');

        log.x('FlowPortal: Envelope loaded', this.$parent.loadedEnvelope);
        log.x('FlowPortal: Recipient loaded', this.$parent.loadedRecipient);

        this.recipient = this.readRecipient(this.$parent.loadedRecipient);
        log.x('FlowPortal: Recipient processed', this.recipient);

        if (this.recipient.action == 'edit' && !this.recipient.completed) {
            this.$parent.navigate({ path: `/fform/${this.$route.params.envelopeId}/${this.$route.params.recipientId}`, query: this.$route.query });
            return;
        }else if (this.recipient.action == 'sign' && this.recipient.completed) {
            this.$parent.navigate({ path: `/kyclanding/${this.$route.params.recipientId}/${this.$route.params.envelopeId}` });
            return;
        }

        this.loadingComplete = true;
        this.$parent.landing = false;
        this.$parent.setLoading(false);
    }
}
</script>