import log from '../../log';
import formHelper from "./formHelper";
import editorHelper from "./editorHelper";
const logicHelper = {

    inputCache: {},

    getResourceValue(reference, resourceKey) {
        
        if (reference && reference.resources && reference.resources[resourceKey] && reference.resources[resourceKey].value) {
            return reference.resources[resourceKey].value;
        }

        return '- - -';
    },

    prepareItem(reference, item, type) {

        editorHelper.onChange(reference, 'logic_added', false);

        if (!item.logic && type != 'flow') item.logic = {};

        if (type == 'link' && !item.logic.link) item.logic.link = [];

        if (type == 'disabled' && !item.logic.disabled) item.logic.disabled = {
            or: true, keys: []
        };

        if (type == 'visible' && !item.logic.visible) item.logic.visible = {
            or: true, keys: []
        };

        if (type == 'required' && !item.logic.required) item.logic.required = {
            or: true, keys: []
        };

        if (type == 'ownerReq' && !item.logic.ownerReq) item.logic.ownerReq = {
            or: true, keys: []
        };

        if (type == 'dmatch' && !item.logic.dmatch) item.logic.dmatch = {
            keys: []
        };

    },


    getKeyDisplay(root, reference, key) {
        const maxLength = 40;
        if (typeof key == 'string') {

            const item = formHelper.getItemFromKey(reference.pages, key);
            if (!item) return '[INPUT]';

            let desc = '[' + item.type.toUpperCase() + '_' + 'INPUT]';
            if (item.desc) {
                if (item.desc[root.locale.key]) {
                    desc = item.desc[root.locale.key];
                }else if (desc = item.desc.en_EN) {
                    desc = item.desc.en_EN;
                }
            }else if (item.locale) {
                if (item.locale[root.locale.key]) {
                    desc = item.locale[root.locale.key];
                }else if (desc = item.locale.en_EN) {
                    desc = item.locale.en_EN;
                }
            }
            if (desc.length > maxLength) desc = desc.substring(0, maxLength - 3) + '...';
            desc += ' (' + item.key + ')';
            return desc;

        }else {
            if (key.type == 'logic') {

                for (const i in reference.logic) {
                    if (reference.logic[i].key == key.key) return 'L:' + reference.logic[i].name;
                }

                return 'logic';

            }else if (key.type == 'role') {

                return 'Role = ' + key.key;

            }else if (key.type == 'tag') {

                return 'Has tag: ' + key.key;

            }else if (key.type == 'kyc') {

                return 'KYC (' + key.key + ')';

            }else if (key.type == 'loc') {

                if (key.key == 'nordic') return 'Outside nordics';
                if (key.key == 'euro') return 'Outside EU/EFTA';

                return 'Country: ' + root.getCountryName(key.key);

            }else {
                const item = formHelper.getItemFromKey(reference.pages, key.key);
                if (!item) return 'ERROR';
                let desc = '[' + item.type.toUpperCase() + '_' + 'OPTION]';
                if (item.locale) {
                    if (item.locale[root.locale.key]) {
                        desc = item.locale[root.locale.key];
                    }else if (desc = item.locale.en_EN) {
                        desc = item.locale.en_EN;
                    }

                    const parent = formHelper.getContainer(reference.pages, item);
                    if (parent && parent.desc && parent.desc[root.locale.key]) {
                        desc += ' (' + parent.desc[root.locale.key] + ')';
                    }

                }else if (item.text) {
                    if (item.text[root.locale.key]) {
                        desc = item.text[root.locale.key];
                    }else if (desc = item.text.en_EN) {
                        desc = item.text.en_EN;
                    }

                    const parent = formHelper.getContainer(reference.pages, item);
                    if (parent && parent.desc && parent.desc[root.locale.key]) {
                        desc += ' (' + parent.desc[root.locale.key] + ')';
                    }

                }else if (item[root.locale.key]) {
                    desc = item[root.locale.key];
                }
                if (desc.length > maxLength) desc = desc.substring(0, maxLength - 3) + '...';
                desc += ' (' + item.key + ')';
                /* if (key.rev) desc = 'NOT ' + desc; */
                return desc;
            }
        }
    },



    getInputValue(reference, results, input) {
        if (input.type == 'group') {

            /* console.log('group', input);
            console.log('group', results);
            console.log('group', results[input.groupIndex]); */

            return results[input.groupIndex];

        }else if (input.isOption) {

            const item = formHelper.getItemFromKey(reference.pages, input.key);
            if (!item) {
                log.e('Logic: Missing input', input);
                return '';
            }

            return item.model[input.optionKey];

        }else {

            const item = formHelper.getItemFromKey(reference.pages, input.key);
            if (!item) {
                log.e('Logic: Missing input', input);
                return '';
            }

            //console.log('input', item);

            return item.model;

        }

    },

    performLogic(reference, index, designer) {
        const logic = reference.logic[index];
        let mainOutput = null;
        const groupResults = [];

        for (const i in logic.items) {

            const group = logic.items[i];
            let groupOutput = null;

            for (const j in group.inputs) {
                if (group.inputs[j].type == 'group') {
                    group.inputs[j].demoValue = groupResults[group.inputs[j].groupIndex];
                    if (typeof groupResults[group.inputs[j].groupIndex] == 'string') group.inputs[j].format = 'S';
                    if (typeof groupResults[group.inputs[j].groupIndex] == 'boolean') group.inputs[j].format = 'B';
                    if (typeof groupResults[group.inputs[j].groupIndex] == 'number') group.inputs[j].format = 'N';
                }
            }

            for (const j in group.ops) {
                const operator = group.ops[j];
                const result = this.getOperatorOutput(reference, groupResults, operator, designer, group.inputs);
                operator.demoOutput = result;
                if (result || group.passFalse) groupOutput = result;
            }

            group.demoOutput = groupOutput;
            groupResults.push(groupOutput);
            //console.log('outputting', groupOutput);
            if (groupOutput || group.passFalse) mainOutput = groupOutput;
        }

        logic.demoOutput = mainOutput;
        return logic.demoOutput;
    },

    getOperatorOutput(reference, results, operator, designer, inputs) {
        switch (operator.type) {
            case 'inputcomp': return this.performInputComparison(reference, results, operator, designer, inputs);
            case 'boolcomp': return this.performBoolCount(reference, results, operator, designer, inputs);
            case 'calc': return this.performCalculator(reference, results, operator, designer, inputs);
        }
        return null;
    },

    performInputComparison(reference, results, operator, designer, inputs) {
        let trueInputs = 0;

        let input1 = 0;
        let input2 = 0;

        if (designer) {
            
            if (inputs.length > 0) input1 = Number(inputs[0].demoValue);
            if (inputs.length > 1) input2 = Number(inputs[1].demoValue);

        }else {

            input1 = Number(this.getInputValue(reference, results, inputs[0]));
            input2 = Number(this.getInputValue(reference, results, inputs[1]));

        }

/*         console.log('operator', operator.operator);
        console.log('input1', input1);
        console.log('input2', input2); */

        if (operator.operator == 'gt') {
            return input1 > input2 ? operator.outputValueT : operator.outputValueF;
        }else if (operator.operator == 'lt') {
            return input1 < input2 ? operator.outputValueT : operator.outputValueF;
        }else if (operator.operator == 'lte') {
            return input1 <= input2 ? operator.outputValueT : operator.outputValueF;
        }else if (operator.operator == 'gte') {
            return input1 >= input2 ? operator.outputValueT : operator.outputValueF;
        }else {
            return input1 == input2 ? operator.outputValueT : operator.outputValueF;
        }
    },

    performBoolCount(reference, results, operator, designer, inputs) {
        let trueInputs = 0;

        if (designer) {
            for (const i in inputs) {
                if (inputs[i].demoValue) {
                    trueInputs += 1;
                }
            }
        }else {
            for (const i in inputs) {
                if (this.getInputValue(reference, results, inputs[i])) {
                    trueInputs += 1;
                }
            }
        }

        if (operator.operator == 'gt') {
            return trueInputs > operator.threshold ? operator.outputValueT : operator.outputValueF;
        }else if (operator.operator == 'lt') {
            return trueInputs < operator.threshold ? operator.outputValueT : operator.outputValueF;
        }else if (operator.operator == 'lte') {
            return trueInputs <= operator.threshold ? operator.outputValueT : operator.outputValueF;
        }else if (operator.operator == 'gte') {
            return trueInputs >= operator.threshold ? operator.outputValueT : operator.outputValueF;
        }else {
            return trueInputs == operator.threshold ? operator.outputValueT : operator.outputValueF;
        }
    },

    performCalculator(reference, results, operator, designer, inputs) {
        const values = [];

        if (designer) {
            for (const i in inputs) {
                values.push(Number(inputs[i].demoValue));
                if (inputs[i].isList) {
                    values.push(Number(inputs[i].demoValue1));
                    values.push(Number(inputs[i].demoValue2));
                }
            }
        }else {
            for (const i in inputs) {
                if (inputs[i].isList) {
                    
                }else {
                    values.push(Number(this.getInputValue(reference, results, inputs[i])));
                }
            }
        }

        let total = 0;
        if (operator.operator == 'add') {
            for (const i in values) {
                if (i == 0) {
                    total = values[i];
                }else {
                    total = total + values[i];
                }
            }
        }else if (operator.operator == 'sub') {
            for (const i in values) {
                if (i == 0) {
                    total = values[i];
                }else {
                    total = total - values[i];
                }
            }
        }else if (operator.operator == 'mul') {
            for (const i in values) {
                if (i == 0) {
                    total = values[i];
                }else {
                    total = total * values[i];
                }
            }
        }else if (operator.operator == 'div') {
            for (const i in values) {
                if (i == 0) {
                    total = values[i];
                }else {
                    total = total / values[i];
                }
            }
        }

        return Number(total);
    },

};
export default logicHelper;