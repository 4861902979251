import axios from 'axios';
import { auth, envelopes } from '@verified/libvf'

const verified = {

    async login(params) {
        let result = {
            success: false,
            token: ''
        };
        let headers = {};
        headers["authorization"] = "Basic " + params.base64;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            let response = await axios({
                url: `/api/auth`,
                method: 'POST',
                headers: headers,
                data: {},
                withCredentials: 'same-origin',
                params: {}
            });
            //console.log('response', response);
            result.token = response.data.token;
            result.success = true;
        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;
    },

    getAvailableSigningMethods(config, allowEmail) {
        const list = [];
        if (config.includes('bankid-no')) {
            list.push({
                value: 'bankid-no',
                display: 'BankID Norge'
            });
        }
        if (config.includes('bankid-se')) {
            list.push({
                value: 'bankid-se',
                display: 'BankID Sverige'
            });
        }
        if (config.includes('nets-esign-dk')) {
            list.push({
                value: 'nets-esign-dk',
                display: 'NemID (Danmark)'
            });
        }
        if (config.includes('touch-sign')) {
            list.push({
                value: 'touch-sign',
                display: 'TouchSign'
            });
        }
        if (config.includes('email') || allowEmail) {
            list.push({
                value: 'email',
                display: 'E-mail'
            });
        }
        return list;
    },

    async getSignToken(envelopeId) {
        let result = {
            success: false,
            token: ''
        };
        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            let response = await axios({
                url: `/api/envelopes/${envelopeId}/jobs/get.sign.link`,
                method: 'POST',
                headers: headers,
                data: {},
                withCredentials: 'same-origin',
                params: {}
            });
            console.log('response', response);
            result.token = response.data.signLink.split('access_token=')[1];
            result.success = true;
        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;
    },

    async sendReminder(envelopeId, recipientId, message) {
        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            let response = await axios({
                url: `/api/envelopes/${envelopeId}/jobs/send.notification`,
                method: 'POST',
                headers: headers,
                data: {
                    envelope: { 
                        greeting: message
                    },
                    recipient: { 
                        id: recipientId
                    }
                },
                withCredentials: 'same-origin',
                params: {}
            });
            
            console.log('response', response);


        }catch(err) {
            console.log(err);
        }
    },

    async editRecipient(envelopeId, recipientId, firstName, lastName, email) {
        const result = {
            success: false
        };
        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            let response = await axios({
                url: `/api/envelopes/${envelopeId}/recipients/${recipientId}`,
                method: 'PUT',
                headers: headers,
                data: {
                    email: email,
                    familyName: lastName,
                    givenName: firstName
                },
                withCredentials: 'same-origin',
                params: {}
            });
            
            console.log('response', response);
            result.success = true;

        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;
    },

    async abortEnvelope(envelopeId, documentId) {
        const result = {
            success: false
        };
        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            let response = await axios({
                url: `/api/envelopes/${envelopeId}/documents/${documentId}/status/aborted`,
                method: 'POST',
                headers: headers,
                data: {},
                withCredentials: 'same-origin',
                params: {}
            });
            
            console.log('response', response);
            result.success = true;

        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;

    },

    async setSigningMethod(envelopeId, recipientId, method) {
        const result = {
            success: false
        };
        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            let response = await axios({
                url: `/api/envelopes/${envelopeId}/recipients/${recipientId}`,
                method: 'PUT',
                headers: headers,
                data: {
                    signingMethod: method
                },
                withCredentials: 'same-origin',
                params: {}
            });
            
            console.log('response', response);
            result.success = true;


        }catch(err) {
            result.success = false;
            console.log(err);
        }
        return result;
    },

    async sendSMS(phone, message, sender) {
        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            let response = await axios({
                url: `/api/sms`,
                method: 'POST',
                headers: headers,
                data: {
                    phoneNumber: phone,
                    message: message,
                    senderId: sender,
                    recipient: {}
                },
                withCredentials: 'same-origin',
                params: {}
            });
            
            console.log('response', response);


        }catch(err) {
            console.log(err);
        }
    },

    async getEmployees(companyId) {
        let users = [];
        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        let response = await axios({
            url: `/api/companies/${companyId}`,
            method: 'GET',
            headers: headers,
            withCredentials: 'same-origin',
            params: {}
        });
        for (let i in response.data.employees) {
            let uid = '';
            if (response.data.employees[i].link && response.data.employees[i].link.uid) uid = response.data.employees[i].link.uid.replace('/users/', '');
            users.push({
                id: response.data.employees[i].id,
                uid: uid,
                department: response.data.employees[i].department,
                name: response.data.employees[i].givenName + ' ' + response.data.employees[i].familyName
            });
        }
        return users;
    },

    async getDownloadUrl(envelopeId, documentId, fileId) {
        let url = '';
        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            let res = await axios({
                url: `/api/envelopes/${envelopeId}/documents/${documentId}/files/${fileId}/url?asObject=1`,
                method: 'GET',
                headers: headers,
                withCredentials: 'same-origin',
                params: {}
            });
            url = res.data.url;
        }catch(err) {
            console.log(err);
        }
        return url;
    },

    async getEditorDownloadUrl(envelopeId, documentId, fileId, token) {
        let url = '';
        let headers = {};
        headers["authorization"] = "JWT " + token;
        /* headers["x-namespace"] = auth.namespace; */
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            let res = await axios({
                url: `/api/envelopes/${envelopeId}/documents/${documentId}/files/${fileId}/url?asObject=1`,
                method: 'GET',
                headers: headers,
                withCredentials: 'same-origin',
                params: {}
            });
            url = res.data.url;
        }catch(err) {
            console.log(err);
        }
        return url;
    },

    async downloadFile(url) {
        let base = '';
        let raw = '';
        try {
            const response = await axios.get(url, {
                responseType: 'arraybuffer'
            });
            //console.log(response);
            base = this.arrayBufferToBase64(response.data);
            raw = response.data;
            //console.log(base);
        }catch(err) {
            console.log(err);
        }
        return base;
        //return window.btoa( raw );
    },

    async getAttachmentDownloadUrl(envelope, name)  {
        //console.log('gau', envelope);
        if (!(envelope.content.documents && envelope.content.documents[0].files && envelope.content.documents[0].files.attachments)) return 'NA';
        let uid = 'NA';
        for (const i in envelope.content.documents[0].files.attachments) {
            if (envelope.content.documents[0].files.attachments[i].name == name) {
                uid = envelope.content.documents[0].files.attachments[i].uid;
            }
        }
        if (uid == 'NA') return 'NA';

        let url = '';
        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            let res = await axios({
                url: `/api/envelopes/${envelope.id}/documents/${envelope.content.documents[0].id}/files/${uid}/url?asObject=1`,
                method: 'GET',
                headers: headers,
                withCredentials: 'same-origin',
                params: {}
            });
            url = res.data.url;
        }catch(err) {
            console.log(err);
        }
        return url;
    },

    async getFirstAttachmentDownloadUrl(envelope)  {
        if (!(envelope.content.documents && envelope.content.documents[0].files && envelope.content.documents[0].files.attachments)) return 'NA';
        let uid = 'NA';
        //console.log('ATTTTTT', envelope.content.documents[0].files.attachments);
        if (envelope.content.documents[0].files.attachments.length > 0) {
            uid = envelope.content.documents[0].files.attachments[0].uid;
        }
        if (uid == 'NA') return 'NA';

        let url = '';
        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";
        try {
            let res = await axios({
                url: `/api/envelopes/${envelope.id}/documents/${envelope.content.documents[0].id}/files/${uid}/url?asObject=1`,
                method: 'GET',
                headers: headers,
                withCredentials: 'same-origin',
                params: {}
            });
            url = res.data.url;
        }catch(err) {
            console.log(err);
        }
        return url;
    },

    async downloadBankIDReport(url) {
        console.log('downloading report', url);

        let base = '';
        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;

        let attempts = 0;
        let log = '';
        let response;
        let generated = false;
        let aborted = false;
        let error;
        while (!generated && !aborted) {
            attempts += 1;
            let success = true;
            await new Promise(r => setTimeout(r, 1000));
            try {
                response = await axios.get(`/api/bankid-oidc/person/pdf?pdfUrl=${ url }`, {
                    headers: headers,
                    responseType: 'arraybuffer'
                });
                //console.log(response.data);
                //console.log(typeof response.data);
                //console.log(response.data.byteLength);
                if (response.data.byteLength < 1024) {
                    success = false;
                }
            }catch (e) {
                error = e;
                success = false;
            }
            if (!success) {
                //console.log('aml report NOT ready');
                if (attempts >= 10) {
                    //console.log('10 attempts - aborting');
                    aborted = true;
                }
                if (error && error.response && error.response.status == 500) {
                    console.log('aml report not available');
                    aborted = true;
                }
            }else {
                generated = true;
                //console.log('aml report downloaded');
            }
        }

        if (generated) {
            //console.log(response.data);
            base = this.arrayBufferToBase64(response.data);
            //console.log(base);
            return base;
        }else {
            return undefined;
        }
        
    },

    arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    },

    async uploadBase64Attachment(envelopeId, documentId, base64, name) {
        // Convert base64 to arraybuffer
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }

        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";

        // Get upload path
        let docFileRes = undefined;
        try {
            let res = await axios({
                url: `/api/envelopes/${envelopeId}/documents/${documentId}/files`,
                method: 'POST',
                headers: headers,
                data: {
                    fileType: "attachment",
                    name: name
                },
                withCredentials: 'same-origin',
                params: {}
            });
            docFileRes = res;
            console.log(res);
        }catch(err) {
            console.log(err);
        }
        // Upload data
        if (docFileRes) {
            let url = docFileRes.data.url;
            try {
                let res = await axios({
                    url: url,
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/pdf"
                    },
                    data: bytes.buffer,
                });
                return res;
            }catch(err) {
                console.log(err);
            }
        }
    },

    async uploadBinaryStringAttachment(envelopeId, documentId, binary, name) {
        // Convert base64 to arraybuffer
        var binary_string = binary;
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }

        let headers = {};
        headers["authorization"] = "JWT " + auth.token;
        headers["x-namespace"] = auth.namespace;
        headers["content-type"] = "application/json";
        headers["accept"] = "application/json";

        // Get upload path
        let docFileRes = undefined;
        try {
            let res = await axios({
                url: `/api/envelopes/${envelopeId}/documents/${documentId}/files`,
                method: 'POST',
                headers: headers,
                data: {
                    fileType: "attachment",
                    name: name
                },
                withCredentials: 'same-origin',
                params: {}
            });
            docFileRes = res;
            console.log(res);
        }catch(err) {
            console.log(err);
        }
        // Upload data
        if (docFileRes) {
            let url = docFileRes.data.url;
            try {
                let res = await axios({
                    url: url,
                    method: 'PUT',
                    headers: {
                        "Content-Type": "application/pdf"
                    },
                    data: binary,
                });
                return res;
            }catch(err) {
                console.log(err);
            }
        }
    },

    async downloadVerifiedFile(root, envelopeId, isAttachment, attachmentName) {
        //console.log('attachVfile: fetching envelope', envelopeId);

        const envelope = await root.getEnvelope(envelopeId);

        if (envelope.content.documents.length == 0) return null;

        console.log('envelope', envelope);

        let uid = 'error';

        if (envelope.content.documents[0].files.document) {
            uid = envelope.content.documents[0].files.document.uid;
        }

        if (envelope.content.documents[0].files.signedDocument) {
            uid = envelope.content.documents[0].files.signedDocument.uid;
        }

        if (isAttachment) {

            for (const i in envelope.content.documents[0].files.attachments) {
                const attachment = envelope.content.documents[0].files.attachments[i];

                if (attachment.name == attachmentName) {
                    uid = attachment.uid;
                }

            }

        }

        console.log('UID', uid);
        if (uid == 'error') return null;


        //console.log('attachVfile: fetching download url', uid);
        const url = await this.getDownloadUrl(envelope.id, envelope.documentId, uid);

        console.log('URL', url);

        //console.log('attachVfile: downloading file', url);
        const data = await this.downloadFile(url);

        return data;
    },

}

export default verified