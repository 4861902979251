const tools = {
    // Common methods used througout the app

    dateString(input) {
        let date = new Date(input);
        let day = date.getDate().toString();
        if (date.getDate() < 10) day = '0' + day;
        let mon = this.getMonthName(date.getMonth()).substring(0, 3);
        let year = date.getFullYear();
        let h = date.getHours();
        if (date.getHours() < 10) h = '0' + h;
        let m = date.getMinutes();
        if (date.getMinutes() < 10) m = '0' + m;
        return `${day}. ${mon} ${year} ${h}:${m}`;
    },

    dateStringLong(input, locale) {
        let date = new Date(input);
        let wd = this.getDayName(date.getDay(), locale);
        let day = date.getDate().toString();
        if (date.getDate() < 10) day = '0' + day;
        let mon = this.getMonthName(date.getMonth(), locale);
        let year = date.getFullYear();
        return `${wd} ${day}. ${mon} ${year}`;
    },

    dateStringShort(input, locale) {
        let date = new Date(input);
        let wd = this.getDayName(date.getDay(), locale);
        let day = date.getDate().toString();
        if (date.getDate() < 10) day = '0' + day;
        let mon = this.getMonthName(date.getMonth(), locale);
        if (mon.length > 3) mon = mon.substring(0, 3);
        let year = date.getFullYear();
        return `${day}. ${mon} ${year}`;
    },

    timePassedString(input, root, locale) {
        const date = new Date(input);
        const diff = new Date() - date;

        const day = 1000 * 60 * 60 * 24;

        if (diff < (1000 * 30)) {
            return root.__t('date-tp-now');
        }else if (diff < (1000 * 60 * 60)) {
            return Math.round(diff / (1000 * 60)) + ' ' + root.__t('date-tp-min');
        }else if (diff < (1000 * 60 * 60 * 8)) {
            return Math.round(diff / (1000 * 60 * 60)) + ' ' + root.__t('date-tp-hour');
        }else if (diff < day) {
            return root.__t('date-tp-today');
        }else if (diff < (day * 7)) {
            return root.__t('date-tp-week');
        }else if (diff < (day * 30)) {
            return root.__t('date-tp-month');
        }else if (diff < (day * 60)) {
            return root.__t('date-tp-month1');
        }else if (diff < (day * 90)) {
            return root.__t('date-tp-month2');
        }else if (diff < (day * 365)) {
            return root.__t('date-tp-month3');
        }else {
            return root.__t('date-tp-year');
        }
    },

    fixDateOfBirth(input) {
        if (input.includes('.')) {
            const ddd = input.split('.');
            return ddd[2] + '-' + ddd[1] + '-' + ddd[0];
        }else {
            return input;
        }
    },

    getMonthName(month, locale) {
        if (!locale || locale.key == 'nb_NO') {
            switch (month) {
                case 0: return 'januar';
                case 1: return 'februar';
                case 2: return 'mars';
                case 3: return 'april';
                case 4: return 'mai';
                case 5: return 'juni';
                case 6: return 'juli';
                case 7: return 'august';
                case 8: return 'september';
                case 9: return 'oktober';
                case 10: return 'november';
                case 11: return 'desember';
            }
        }else if (locale.key == 'sv_SE') {
            switch (month) {
                case 0: return 'januari';
                case 1: return 'februari';
                case 2: return 'mars';
                case 3: return 'april';
                case 4: return 'maj';
                case 5: return 'juni';
                case 6: return 'juli';
                case 7: return 'augusti';
                case 8: return 'september';
                case 9: return 'oktober';
                case 10: return 'november';
                case 11: return 'december';
            }
        }else {
            switch (month) {
                case 0: return 'January';
                case 1: return 'February';
                case 2: return 'March';
                case 3: return 'April';
                case 4: return 'May';
                case 5: return 'June';
                case 6: return 'July';
                case 7: return 'August';
                case 8: return 'September';
                case 9: return 'October';
                case 10: return 'November';
                case 11: return 'December';
            }
        }
        return 'month';
    },

    getDayName(day, locale) {
        if (!locale || locale.key == 'nb_NO') {
            switch (day) {
                case 0: return 'Søndag';
                case 1: return 'Mandag';
                case 2: return 'Tirsdag';
                case 3: return 'Onsdag';
                case 4: return 'Torsdag';
                case 5: return 'Fredag';
                case 6: return 'Lørdag';
            }
        }else if (locale.key == 'sv_SE') {
            switch (day) {
                case 0: return 'Söndag';
                case 1: return 'Måndag';
                case 2: return 'Tisdag';
                case 3: return 'Onsdag';
                case 4: return 'Torsdag';
                case 5: return 'Fredag';
                case 6: return 'Lördag';
            }
        }else {
            switch (day) {
                case 0: return 'Sunday';
                case 1: return 'Monday';
                case 2: return 'Tuesday';
                case 3: return 'Wednesday';
                case 4: return 'Thursday';
                case 5: return 'Friday';
                case 6: return 'Saturday';
            }
        }
        return 'weekday';
    },

    formatMoney(amount) {
        let value = parseFloat(amount).toFixed(0)
        var str = "";
        if (value.toString().length > 6) {
            let index1 = value.toString().length - 6;
            let index2 = value.toString().length - 3;
            str = value.toString().substring(0, index1) + " " + value.toString().substring(index1, index2) + " " + value.toString().substring(index2);
        }else if (value.toString().length > 3) {
            let index = value.toString().length - 3;
            str = value.toString().substring(0, index) + " " + value.toString().substring(index);
        }else {
            str = value.toString();
        }
        return str + ' kr';
    },

    // Properly capitalize a name
    titleCase(str) {
        try {
            return str.split(' ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
        }catch (ex) {
            return str;
        }
    },

    getNames(name) {
        const result = {
            firstName: '',
            lastName: ''
        };
        const array = name.split(' ');
        result.lastName = array[array.length - 1];
        for (const i in array) {
            if (i > 0) result.firstName += ' ';
            result.firstName += array[i];
            if (i >= array.length - 2) break;
        }
        return result;
    },

    replaceAll(input, replace, replaceWith) {
        return input.split(replace).join(replaceWith);
    },

    // Compare two persons
    isSamePerson(person1, person2) {
        // Compare persons with firstName, lastName and dateOfBirth to be sure (some companies will have i.e. father and son with the same name)
        if (person1.firstName.toLowerCase() == person2.firstName.toLowerCase() 
        && person1.lastName.toLowerCase() == person2.lastName.toLowerCase() 
        && person1.dateOfBirth == person2.dateOfBirth) {
            return true;
        }
        return false;
    },

    // Get name/dob unique id
    encodePerson(person) {
        let str = '';
        if (person.info) {
            str = this.encodeString(person.info.dateOfBirth);
            str += '-' + this.encodeString(person.info.lastName);
            str += '-' + this.encodeString(person.info.firstName);
        }else {
            str = this.encodeString(person.dateOfBirth);
            str += '-' + this.encodeString(person.lastName);
            str += '-' + this.encodeString(person.firstName);
        }
        let count = 36 - str.length;
        if (count > 5) {
            const extras = 'hQx9cC_xwYmb0pqa_tYfu38nZKq_pQ1yT62k4_jHyFdZqPa'
            let extra = '';
            for (let i=0; i<count; i++) {
                extra += extras.charAt(i);
            }
            str = extra + '-' + str;
        }
        return str;
    },

    isValidPerson(person) {
        if (!person) return false;
        if (!person.dateOfBirth) return false;
        if (!person.lastName) return false;
        if (!person.firstName) return false;
        return true;
    },

    decodePerson(str) {
        let arr = str.split('-');
        let start = 0;
        if (arr.length > 3) start = 1;
        let decoded = {
            firstName: this.decodeString(arr[start + 2]),
            lastName: this.decodeString(arr[start + 1]),
            dateOfBirth: this.decodeString(arr[start]),
        }
        return decoded;
    },

    encodeString(str) {
        str = str.toLowerCase();
        let rrr = '';
        for (let i = 0; i < str.length; i++) {
            rrr += this.encodeChar(str.charAt(i));
        }
        return rrr;
    },

    decodeString(str) {
        let rrr = '';
        for (let i = 0; i < str.length; i++) {
            rrr += this.decodeChar(str.charAt(i));
        }
        return rrr;
    },

    encodeChar(char) {
        switch (char) {
            case 'a': return '1';
            case 'b': return '2';
            case 'c': return '3';
            case 'd': return '4';
            case 'e': return '_';
            case 'f': return '6';
            case 'g': return '7';
            case 'h': return '8';
            case 'i': return '9';
            case 'j': return 'a';
            case 'k': return 'b';
            case 'l': return 'c';
            case 'm': return 'd';
            case 'n': return 'e';
            case 'o': return '5';
            case 'p': return 'g';
            case 'q': return 'h';
            case 'r': return 'i';
            case 's': return 'j';
            case 't': return 'k';
            case 'u': return 'l';
            case 'v': return 'm';
            case 'w': return 'n';
            case 'x': return 'o';
            case 'y': return 'p';
            case 'z': return 'q';
            case ' ': return '0';
            case '1': return 'r';
            case '2': return 'S';
            case '3': return 't';
            case '4': return 'U';
            case '5': return 'V';
            case '6': return 'w';
            case '7': return 'X';
            case '8': return 'Y';
            case '9': return 'z';
            case '0': return 'Q';
            case '-': return 'x';
            default: return char;
        }
    },

    decodeChar(char) {
        switch (char) {
            case '1': return 'a';
            case '2': return 'b';
            case '3': return 'c';
            case '4': return 'd';
            case '_': return 'e';
            case '6': return 'f';
            case '7': return 'g';
            case '8': return 'h';
            case '9': return 'i';
            case '9': return 'i';
            case 'a': return 'j';
            case 'b': return 'k';
            case 'c': return 'l';
            case 'd': return 'm';
            case 'e': return 'n';
            case '5': return 'o';
            case 'g': return 'p';
            case 'h': return 'q';
            case 'i': return 'r';
            case 'j': return 's';
            case 'k': return 't';
            case 'l': return 'u';
            case 'm': return 'v';
            case 'n': return 'w';
            case 'o': return 'x';
            case 'p': return 'y';
            case 'q': return 'z';
            case '0': return ' ';
            case 'r': return '1';
            case 'S': return '2';
            case 't': return '3';
            case 'U': return '4';
            case 'V': return '5';
            case 'w': return '6';
            case 'X': return '7';
            case 'Y': return '8';
            case 'z': return '9';
            case 'Q': return '0';
            case 'x': return '-';
            default: return char;
        }
    },

    getUniqueId() {
        return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },


}
export default tools