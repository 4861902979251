const kar99 = {

    getForm() {
        return {
            "key": "kar99",
            "designVersion": 1,
            "locales": [
                {
                    "key": "en_EN",
                    "name": "English",
                    "alpha2": "gb",
                    "enabled": false
                },
                {
                    "key": "nb_NO",
                    "name": "Norsk",
                    "alpha2": "no",
                    "enabled": true
                },
                {
                    "key": "sv_SE",
                    "name": "Svenska",
                    "alpha2": "se",
                    "enabled": false
                }
            ],
            "designName": "Standard KAR lookup",
            "title": {
                "en_EN": "KAR-oppslag",
                "nb_NO": "KAR Oppslag",
                "sv_SE": "KAR Lookup"
            },
            "name": {
                "en_EN": "KAR-oppslag",
                "nb_NO": "KAR Oppslag",
                "sv_SE": "KAR Lookup"
            },
            "flowType": "direct",
            "flowConfig": {
                "autoSave": false,
                "canSave": false,
                "customSender": {
                    "enabled": false,
                    "firstName": "",
                    "lastName": "",
                    "email": ""
                },
                "landingParams": {
                    "filled": {
                        "title": {
                            "en_EN": "en",
                            "nb_NO": "no",
                            "sv_SE": "se"
                        },
                        "message": {
                            "en_EN": "en",
                            "nb_NO": "no",
                            "sv_SE": "se"
                        }
                    },
                    "signed": {
                        "title": {
                            "en_EN": "en",
                            "nb_NO": "no",
                            "sv_SE": "se"
                        },
                        "message": {
                            "en_EN": "en",
                            "nb_NO": "no",
                            "sv_SE": "se"
                        }
                    },
                    "aborted": {
                        "title": {
                            "en_EN": "en",
                            "nb_NO": "no",
                            "sv_SE": "se"
                        },
                        "message": {
                            "en_EN": "en",
                            "nb_NO": "no",
                            "sv_SE": "se"
                        }
                    },
                    "redirect": "client",
                    "logoUrl": ""
                },
                "roles": [
                    {
                        "key": "owner",
                        "type": "owner",
                        "display": "Owner",
                        "next": "done",
                        "hasEmail": false,
                        "email": {},
                        "hasLanding": false,
                        "landingParams": {},
                        "redirect": "popup"
                    }
                ]
            },
            "modules": [
                {
                    "type": "kar",
                    "icon": "company"
                },
                {
                    "type": "form",
                    "icon": "form",
                    "formKeys": [
                        "kyc00",
                        "kycp01"
                    ]
                },
                {
                    "type": "kyc",
                    "icon": "eye",
                    "updateKyc": false
                }
            ],
            "counter": 1013,
            "pages": [
                {
                    "key": "p1001",
                    "type": "page",
                    "mode": "popup-page",
                    "title": {
                        "en_EN": "KAR Lookup",
                        "nb_NO": "KAR Lookup",
                        "sv_SE": "KAR Lookup"
                    },
                    "showStepper": false,
                    "hasInfo": false,
                    "info": {
                        "owner": [
                            {
                                "key": "h1002",
                                "icon": "help-o",
                                "header": {
                                    "en_EN": "",
                                    "nb_NO": "",
                                    "sv_SE": ""
                                },
                                "text": {
                                    "formatted": true,
                                    "en_EN": "",
                                    "nb_NO": "",
                                    "sv_SE": ""
                                }
                            }
                        ]
                    },
                    "roles": {
                        "owner": true
                    },
                    "cards": [
                        {
                            "key": "c1003",
                            "type": "card",
                            "header": {
                                "en_EN": "",
                                "nb_NO": "",
                                "sv_SE": ""
                            },
                            "inputs": [
                                {
                                    "key": "i1009",
                                    "type": "check",
                                    "width": "12",
                                    "desc": {
                                        "en_EN": "",
                                        "nb_NO": "",
                                        "sv_SE": ""
                                    },
                                    "required": true,
                                    "inline": true,
                                    "radio": true,
                                    "fillCard": false,
                                    "border": false,
                                    "options": {
                                        "x1010": {
                                            "type": "opt",
                                            "key": "x1010",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "Selskap",
                                                "nb_NO": "Selskap",
                                                "sv_SE": "Selskap"
                                            },
                                            "kycKey": "clientIsCompany"
                                        },
                                        "x1011": {
                                            "type": "opt",
                                            "key": "x1011",
                                            "default": false,
                                            "locale": {
                                                "en_EN": "Fysisk person",
                                                "nb_NO": "Fysisk person",
                                                "sv_SE": "Fysisk person"
                                            },
                                            "kycKey": "clientIsPerson"
                                        }
                                    },
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "ownerReq": true,
                                    "logic": {
                                        "link": [
                                            {
                                                "type": "kar",
                                                "link": "person",
                                                "key": "x1011"
                                            },
                                            {
                                                "type": "kar",
                                                "link": "company",
                                                "key": "x1010"
                                            }
                                        ]
                                    }
                                },
                                {
                                    "key": "i1012",
                                    "type": "text",
                                    "inputType": "text",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Organisasjonsnummer",
                                        "nb_NO": "Organisasjonsnummer",
                                        "sv_SE": "Organisasjonsnummer"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1009",
                                                    "key": "x1010",
                                                    "rev": false
                                                }
                                            ]
                                        },
                                        "link": [
                                            {
                                                "type": "kar",
                                                "link": "orgnr",
                                                "key": ""
                                            }
                                        ]
                                    },
                                    "min": "9",
                                    "max": "9",
                                    "ownerReq": true,
                                    "modelKey": "karOrgnr",
                                    "oldFormKey": "karOrgnr",
                                    "kycKey": "orgNumber"
                                },
                                {
                                    "key": "i1013",
                                    "type": "text",
                                    "inputType": "number",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Fødselsnummer",
                                        "nb_NO": "Fødselsnummer",
                                        "sv_SE": "Fødselsnummer"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "logic": {
                                        "visible": {
                                            "or": true,
                                            "keys": [
                                                {
                                                    "type": "check",
                                                    "pkey": "i1009",
                                                    "key": "x1011",
                                                    "rev": false
                                                }
                                            ]
                                        },
                                        "link": [
                                            {
                                                "type": "kar",
                                                "link": "ssn",
                                                "key": ""
                                            }
                                        ]
                                    },
                                    "min": "11",
                                    "max": "11",
                                    "ownerReq": true,
                                    "modelKey": "karSSN",
                                    "oldFormKey": "karSSN",
                                    "kycKey": "clientSSN"
                                },
                                {
                                    "key": "i1004",
                                    "type": "text",
                                    "inputType": "bankno",
                                    "width": "6",
                                    "desc": {
                                        "en_EN": "Kontonummer",
                                        "nb_NO": "Kontonummer",
                                        "sv_SE": "Kontonummer"
                                    },
                                    "required": true,
                                    "noPadding": false,
                                    "pdf": true,
                                    "pdfIndex": 0,
                                    "pdfLocked": false,
                                    "ownerReq": true,
                                    "modelKey": "karAccount",
                                    "oldFormKey": "karAccount",
                                    "logic": {
                                        "link": [
                                            {
                                                "type": "kar",
                                                "link": "account",
                                                "key": ""
                                            }
                                        ]
                                    }
                                }
                            ],
                            "noPadding": true,
                            "pdf": true,
                            "roles": {
                                "owner": true
                            },
                            "noShadow": true
                        },
                        {
                            "key": "q1008",
                            "type": "custom",
                            "pdf": true,
                            "pdfOnly": true,
                            "sub": "karr",
                            "roles": {
                                "owner": true
                            }
                        }
                    ],
                    "submitText": {
                        "en_EN": "Lookup",
                        "nb_NO": "Sjekk kontonummer",
                        "sv_SE": "Lookup"
                    }
                }
            ],
            "resources": {},
            "logic": {},
            "attachments": []
        };
    }

};
export default kar99;