import kycModuleHelper from "./modules/kyc/kycModuleHelper";
import idcModuleHelper from "./modules/idcontrol/idcModuleHelper";
import checklistModuleHelper from "./modules/checklist/checklistModuleHelper";
import flowHelper from "./flowHelper";
import log from "../../log";
import textHelper from './textHelper';
const formHelper = {

    processData(data) {
        //console.log('data', data);
        const result = {
            key: data.key,
            steps: [],
            pages: [],
            counter: data.counter,
            modules: [],
            resources: [],
            attachments: [],
            roles: [],
            canSave: false,
            autoSave: false,
            customSender: {
                enabled: false,
                firstName: '',
                lastName: '',
                email: ''
            },
            landingParams: {},
            title: data.title,
            name: data.name || { en_EN: 'name', nb_NO: 'name', sv_SE: 'name' },
            logic: [],
            locales: [],
            flags: {
                ownerCopy: true,
                signedCopy: {
                    enabled: false,
                    firstName: '',
                    lastName: '',
                    email: '',
                    language: ''
                },
                autoSaveMessage: false,
                autoSaveLoaded: false,
            },
            branding: {},
        };
        for (const i in data.pages) {
            result.steps.push({
                key: data.pages[i].key,
                title: data.pages[i].title
            });
            result.pages.push(data.pages[i]);
        }
        for (const i in data.modules) {
            result.modules.push(data.modules[i]); 
        }
        for (const i in data.resources) {
            result.resources.push(data.resources[i]); 
        }
        for (const i in data.attachments) {
            result.attachments.push(data.attachments[i]); 
        }
        for (const i in data.flowConfig.roles) {
            result.roles.push({
                display: data.flowConfig.roles[i].display,
                key: data.flowConfig.roles[i].key,
                type: data.flowConfig.roles[i].type,
                next: data.flowConfig.roles[i].next,
                hasEmail: data.flowConfig.roles[i].hasEmail,
                hasLanding: data.flowConfig.roles[i].hasLanding,
                landingParams: data.flowConfig.roles[i].landingParams,
                redirect: data.flowConfig.roles[i].redirect,
            });
        }
        if (data.flowConfig.canSave) result.canSave = true;
        if (data.flowConfig.autoSave) result.autoSave = true;
        if (data.flowConfig.customSender) result.customSender = data.flowConfig.customSender;
        result.landingParams = data.flowConfig.landingParams || {
            filled: { title: { en_EN: 'en', nb_NO: 'no', sv_SE: 'se' }, message: { en_EN: 'en', nb_NO: 'no', sv_SE: 'se' } },
            signed: { title: { en_EN: 'en', nb_NO: 'no', sv_SE: 'se' }, message: { en_EN: 'en', nb_NO: 'no', sv_SE: 'se' } },
            aborted: { title: { en_EN: 'en', nb_NO: 'no', sv_SE: 'se' }, message: { en_EN: 'en', nb_NO: 'no', sv_SE: 'se' } },
            redirect: '',
            logoUrl: ''
        };
        result.locales = data.locales;
        result.logic = data.logic || [];
        if (Object.prototype.toString.call(result.logic) !== '[object Array]') {
            result.logic = [];
        }
        if (data.flowConfig && data.flowConfig.flags) {
            for (const key in data.flowConfig.flags) {
                result.flags[key] = data.flowConfig.flags[key];
            }
        }
        if (data.flowConfig && data.flowConfig.branding) {
            for (const key in data.flowConfig.branding) {
                result.branding[key] = data.flowConfig.branding[key];
            }
        }
        return result;
    },

    getFormUserdata(data, pages) {
        //console.log('data', data);
        const config = {
            key: data.key,
            flowType: data.flowType,
            title: data.title,
            roles: [],
            modules: [],
            addModelToMetadata: data.flowConfig.flags ? data.flowConfig.flags.addModelToMetadata || false : false,
        };
        for (const i in data.flowConfig.roles) {
            config.roles.push({
                display: data.flowConfig.roles[i].display,
                key: data.flowConfig.roles[i].key,
                type: data.flowConfig.roles[i].type,
                next: data.flowConfig.roles[i].next
            });
        }
        for (const i in data.modules) {
            config.modules.push(data.modules[i]);
        }
        return {
            config: config,
            pages: pages
        };
    },

    processFormUserdata(config, pages) {
        //console.log('config', config);
        const data = {
            config: config,
            pages: pages,
            steps: [],
            modules: [],
            roles: []
        };
        for (const i in pages) {
            data.steps.push({
                key: pages[i].key,
                title: pages[i].title
            });
        }
        for (const i in config.roles) {
            data.roles.push(config.roles[i]);
        }
        for (const i in config.modules) {
            data.modules.push(config.modules[i]);
        }
        return data;
    },

    processRoles(data) {
        const roleConfig = {
            roles: []
        };
        for (const i in data.flowConfig.roles) {
            roleConfig.roles.push(data.flowConfig.roles[i]);
        }
        return roleConfig;
    },

    createModel(context, root, pages) {
        for (const i in pages) {
            for (const j in pages[i].cards) {
                if (pages[i].cards[j].type == 'list' || pages[i].cards[j].type == 'chk-a') {
                    context.$set(pages[i].cards[j], 'model', []);
                    context.$set(pages[i].cards[j], 'val', []);
                    if (pages[i].cards[j].type == 'chk-a') {
                        context.$set(pages[i].cards[j], 'itemModel', []);
                    }
                }else {
                    for (const k in pages[i].cards[j].inputs) {
                        const input = pages[i].cards[j].inputs[k];
                        let model = this.getInputModel(context, root, input);
                        context.$set(input, 'model', model);
                        context.$set(input, 'val', { valid: false, done: false, message: '' });
                    }
                }
            }
        }
    },

    getInputModel(context, root, input) {
        let model = null;
        if (input.type == 'check') {
            model = {};
            for (const x in input.options) {
                model[input.options[x].key] = input.options[x].default;
            }
        }else if (input.type == 'check2') {
            if (input.items.length == 1) {
                model = input.items[0].default;
            }else if (input.yesno) {
                for (const x in input.items) {
                    if (input.items[x].default && input.items[x].modelKey == 'yes') model = true;
                    if (input.items[x].default && input.items[x].modelKey == 'no') model = false;
                }
            }else {
                model = {};
                for (const x in input.items) {
                    model[input.items[x].modelKey] = input.items[x].default;
                }
            }
        }else if (input.type == 'drop') {
            model = '';
            for (const x in input.items) {
                if (input.items[x].default) model = input.items[x].key;
            }
        }else if (input.type == 'phone') {
            model = { prefix: root.config.params.defaultPrefix || '+47', number: '' };
        }else if (input.type == 'upload') {
            model = { files: [] };
        }else if (input.type == 'chk-i') {
            model = { ids: '', vs: '', qcr: '', qcc: '', comments: [] };
        }else {
            model = '';
            if (input.default) model = input.default;
        }
        return model;
    },

    addListModel(root, card, customModel) {

        if (card.type == 'list') {

            const newModel = {};
            const newVal = {};
            for (const i in card.inputs) {
                if (card.inputs[i].type == 'check') {
                    let model = {};
                    for (const x in card.inputs[i].options) {
                        model[card.inputs[i].options[x].key] = card.inputs[i].options[x].default;
                    }
                    newModel[card.inputs[i].listModel] = model;
                }else if (card.inputs[i].type == 'phone') {
                    let model = {};
                    for (const x in card.inputs[i].options) {
                        model[card.inputs[i].options[x].key] = card.inputs[i].options[x].default;
                    }
                    newModel[card.inputs[i].listModel] = { prefix: root.config.params.defaultPrefix || '+47', number: '' };
                }else {
                    newModel[card.inputs[i].listModel] = '';
                }
                newVal[card.inputs[i].listModel] = { valid: false, done: false, message: '' };
            }
            card.model.push({...newModel, ...customModel});
            card.val.push(newVal);

        }else if (card.type == 'chk-a') {

            const newModel = {};
            const newVal = {};
            for (const i in card.inputs) {
                newModel[card.inputs[i].key] = { ids: '', vs: '', qcr: '', qcc: '', comments: [] };
                newVal[card.inputs[i].key] = { valid: false, done: false, message: '' };
            }
            card.itemModel.push({
                name: '',
                id: ''
            });
            console.log('added item model', card);
            card.model.push(newModel);
            card.val.push(newVal);

        }

    },

    addValidation(card) {

        if (card.type == 'list') {

            const newVal = {};
            for (const i in card.inputs) {
                newVal[card.inputs[i].listModel] = { valid: false, done: false, message: '' };
            }
            card.val.push(newVal);

        }else if (card.type == 'chk-a') {

            const newVal = {};
            for (const i in card.inputs) {
                newVal[card.inputs[i].key] = { valid: false, done: false, message: '' };
            }
            card.val.push(newVal);

        }

    },

    getAttachments(reference, pages, currentRole) {
        const attachments = [];
        for (const i in pages) {
            if (this.isPageVisible(pages[i], currentRole)) {
                for (const j in pages[i].cards) {
                    if (this.isCardVisible(reference, pages[i].cards[j], false, pages, pages[i], currentRole)) {

                        if (pages[i].cards[j].type == 'list') {


                        }else {

                            for (const k in pages[i].cards[j].inputs) {
                                if (this.isInputVisible(reference, pages[i].cards[j].inputs[k], false, pages, pages[i])) {
                                    if (pages[i].cards[j].inputs[k].type == 'upload') {
    
                                        const files = pages[i].cards[j].inputs[k].model.files;
                                        console.log('files', files);
                                        for (const f in files) {
                                            if (files[f].existing && !files[f].isResend) continue;
                                            const attachment = {
                                                name: files[f].name,
                                                binary: files[f].data,
                                                type: pages[i].cards[j].inputs[k].fileKey || 'atc',
                                                fileType: files[f].contentType,
                                                fileSize: files[f].fileSize,
                                                existing: files[f].existing || false,
                                                isResend: files[f].isResend || false,
                                            };
                                            attachments.push(attachment);
                                        }
    
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
        return attachments;
    },
    
    getVisibleInputs(context, pages, roles, currentRole) {
        const list = [];
        for (const i in pages) {
            if (this.isPageVisible(pages[i], currentRole)) {
                for (const j in pages[i].cards) {
                    if (this.isCardVisible(context, pages[i].cards[j], false, pages, pages[i], currentRole)) {

                        if (pages[i].cards[j].type == 'list') {


                        }else {

                            for (const k in pages[i].cards[j].inputs) {
                                if (this.isInputVisible(context, pages[i].cards[j].inputs[k], false, pages, pages[i])) {
                                    
                                    list.push(pages[i].cards[j].inputs[k]);
                                    
                                }
                            }


                        }
                    }
                }
            }
        }
        return list;
    },

    getRecipient(context, pages, roles, currentRole) {

        //console.log('getR roles', roles);
        //console.log('getR currentRole', currentRole);

        let nextRecipient = '';
        for (const i in roles) {
            if (roles[i].key == currentRole) nextRecipient = roles[i].next;
        }
        log.x('FRv2: getRecipient: Default next recipient is', nextRecipient);

        const roleOptions = flowHelper.getRoleOptions();
        const recipients = {};
        for (const i in roles) {
            recipients[roles[i].key] = {
                firstName: '',
                lastName: '',
                email: '',
                lang: '',
                phone: { prefix: '+47', number: '' },
                useSms: false,
            };
            for (const j in roleOptions) {
                if (roleOptions[j].key == roles[i].key) {
                    recipients[roles[i].key].role = {
                        action: roleOptions[j].action,
                        label: roleOptions[j].label,
                        name: roleOptions[j].key,
                    };
                }
            }
        }


        for (const i in pages) {
            if (this.isPageVisible(pages[i], currentRole)) {
                for (const j in pages[i].cards) {

                    if (this.isCardVisible(context, pages[i].cards[j], false, pages, pages[i], currentRole)) {

                        if (pages[i].cards[j].type == 'list') {




                        }else {

                            for (const k in pages[i].cards[j].inputs) {
                                if (this.isInputVisible(context, pages[i].cards[j].inputs[k], false, pages, pages[i])) {
                                    const input = pages[i].cards[j].inputs[k];
                                    if (input.flow) {
                                        if (input.flow.override) {
                                            if (this.isOptionChecked(pages, input.flow.override.key)) {
                                                nextRecipient = input.flow.override.rkey;
                                                log.x('FRv2: getRecipient: Next recipient overridden to', nextRecipient);
                                            }
                                        }
                                        if (input.flow.sms) {
                                            if (this.isOptionChecked(pages, input.flow.sms.key)) {
                                                if (recipients[input.flow.sms.rkey]) {
                                                    recipients[input.flow.sms.rkey].useSms = true;
                                                    log.x('FRv2: getRecipient: Use SMS for recipient ' + input.flow.sms.rkey, recipients[input.flow.sms.rkey]);
                                                }
                                            }
                                        }
                                        if (input.flow.field) {
                                            //console.log('field', input.flow.field);
                                            if (recipients[input.flow.field.rkey]) {
                                                recipients[input.flow.field.rkey][input.flow.field.type] = input.model;
                                                //console.log(input.flow.field.rkey + '.' + input.flow.field.type + ' set to ', input.model);
                                                //console.log('input', input);
                                            }
                                        }
                                    }
                                }
                            }


                        }

                        

                    }

                }
            }
        }
        
        //console.log('recipients', recipients);
        if (nextRecipient == 'done') return null;

/*         if (!recipients[nextRecipient].firstName) {
            recipients[nextRecipient].firstName = 'Test';
            recipients[nextRecipient].lastName = 'Testerson';
            recipients[nextRecipient].email = 'magnus.ursin@verified.eu';
        } */

        return recipients[nextRecipient];
    },

    getSigners(context, pages, roles, currentRole) {
        const signers = [];
        const singleSigner = {};

        for (const i in pages) {
            if (this.isPageVisible(pages[i], currentRole)) {
                for (const j in pages[i].cards) {
                    if (this.isCardVisible(context, pages[i].cards[j], false, pages, pages[i], currentRole)) {

                        if (pages[i].cards[j].type == 'list') {

                            for (const x in pages[i].cards[j].model) {
                                let indexAdded = false;

                                for (const k in pages[i].cards[j].inputs) {
                                    if (this.isListInputVisible(context, pages[i].cards[j], pages[i].cards[j].inputs[k], x, false, pages, pages[i])) {
                                        const input = pages[i].cards[j].inputs[k];
                                        
                                        if (input.flow && input.flow.field) {
                                            //console.log('field', input.flow.field);
    
                                            const value = pages[i].cards[j].model[x][input.listModel];
                                            //console.log('value', value);

                                            if (indexAdded) {
                                                signers[x][input.flow.field.type] = value;
                                            }else {
                                                signers.push({
                                                    [input.flow.field.type]: value
                                                });
                                                indexAdded = true;
                                            }
    
                                        }
    
                                    }
                                }

                            }

                        }else {

                            for (const k in pages[i].cards[j].inputs) {
                                if (this.isInputVisible(context, pages[i].cards[j].inputs[k], false, pages, pages[i])) {
                                    const input = pages[i].cards[j].inputs[k];
                                    
                                    if (input.flow && input.flow.field) {
                                        console.log('field', input.flow.field);

                                        singleSigner[input.flow.field.type] = input.model;
                                        console.log(input.flow.field.rkey + '.' + input.flow.field.type + ' set to ', input.model);
                                        console.log('input', input);

                                    }

                                }
                            }

                        }
                    }
                }
            }
        }

        if (signers.length == 0 && singleSigner.email) {
            signers.push(singleSigner);
        }

        return signers;
    },

    isCard(item) {
        if (item.type == 'card' || item.type == 'list' || item.type == 'chk-c' || item.type == 'chk-h' || item.type == 'chk-a' || item.type == 'buttons' || item.type == 'logo' || item.type == 'methods' || item.type == 'custom' || item.type == 'card-header' || item.type == 'pepinfo' || item.type == 'popup' || item.type == 'cftext' || item.type == 'flowv' || item.type == 'kycc' || item.type == 'pdfh' || item.type == 'pdft' || item.type == 'kyci' || item.type == 'kyca' || item.type == 'checku' || item.type == 'anas') return true;
        return false;
    },

    isList(item) {
        if (item.type == 'list' || item.type == 'chk-a') return true;
        return false;
    },

    getContainer(pages, item) {
        if (this.isCard(item)) {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    if (pages[i].cards[j].key == item.key) return pages[i];
                }
            }
        }else {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    for (const k in pages[i].cards[j].inputs) {
                        if (pages[i].cards[j].inputs[k].key == item.key) {
                            return pages[i].cards[j];
                        } 
                        for (const l in pages[i].cards[j].inputs[k].options) {
                            if (item.key == pages[i].cards[j].inputs[k].options[l].key) return pages[i].cards[j].inputs[k];
                        }
                        for (const l in pages[i].cards[j].inputs[k].items) {
                            if (item.key == pages[i].cards[j].inputs[k].items[l].key) return pages[i].cards[j].inputs[k];
                        }
                    }
                }
            }
        }
        return null;
    },

    getContainerPage(page, item) {
        if (this.isCard(item)) {
            for (const j in page.cards) {
                if (page.cards[j].key == item.key) return page;
            }
        }else {
            for (const j in page.cards) {
                for (const k in page.cards[j].inputs) {
                    if (page.cards[j].inputs[k].key == item.key) return page.cards[j];
                    for (const l in page.cards[j].inputs[k].options) {
                        if (l == page.cards[j].inputs[k].options[l].key) return page.cards[j].inputs[k];
                    }
                }
            }
        }
        return null;
    },

    getItemIndex(pages, item) {
        if (this.isCard(item)) {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    if (pages[i].cards[j].key == item.key) return j;
                }
            }
        }else {
            for (const i in pages) {
                for (const j in pages[i].cards) {
                    for (const k in pages[i].cards[j].inputs) {
                        if (pages[i].cards[j].inputs[k].key == item.key) return k;
                    }
                }
            }
        }
        return null;
    },

    getItemLocation(pages, item) {
        const container = this.getContainer(pages, item);
        let list = container.inputs;
        if (this.isCard(item)) list = container.cards;
        const index = this.getItemIndex(pages, item);
        return {
            list: list,
            index: Number(index)
        };
    },

    getItemFromKey(pages, key) {
        for (const i in pages) {
            if (pages[i].key == key) return pages[i];
            for (const j in pages[i].cards) {
                if (pages[i].cards[j].key == key) return pages[i].cards[j];
                for (const k in pages[i].cards[j].inputs) {
                    if (pages[i].cards[j].inputs[k].key == key) return pages[i].cards[j].inputs[k];
                    for (const l in pages[i].cards[j].inputs[k].options) {
                        if (l == key) return pages[i].cards[j].inputs[k].options[l];
                    }
                    for (const l in pages[i].cards[j].inputs[k].items) {
                        if (pages[i].cards[j].inputs[k].items[l].key == key || pages[i].cards[j].inputs[k].items[l].modelKey == key) return pages[i].cards[j].inputs[k].items[l];
                    }
                    if (pages[i].cards[j].inputs[k].type == 'buttoni') {
                        for (const l in pages[i].cards[j].inputs[k].buttons) {
                            if (pages[i].cards[j].inputs[k].buttons[l].key == key) return pages[i].cards[j].inputs[k].buttons[l];
                        }
                    }
                }
            }
        }
        return null;
    },

    getItemFromKeyModules(reference, key) {
        
        for (const i in reference.modules) {

            if (reference.modules[i].type == 'client') {
                for (const j in reference.modules[i].tags) {
                    if (reference.modules[i].tags[j].key == key) {
                        return reference.modules[i].tags[j];
                    }
                }
            }

        }

        return null;
    },

    isOptionChecked(pages, key) {
        for (const i in pages) {
            for (const j in pages[i].cards) {
                for (const k in pages[i].cards[j].inputs) {
                    for (const l in pages[i].cards[j].inputs[k].options) {
                        if (l == key) {
                            return pages[i].cards[j].inputs[k].model[key];
                        }
                    }
                }
            }
        }
        return false;
    },

    getNewPage(reference, roleOptions) {
        const info = {};
        const roles = {};
        for (const i in roleOptions) {
            if (!this.isSignerRole(roleOptions[i].key)) {
                roles[roleOptions[i].key] = true;
                info[roleOptions[i].key] = [this.getNewHelpTextLine(reference)];
            }
        }
        return {
            page: {
                key: 'p' + (reference.counter += 1),
                type: 'page',
                mode: 'form-page',
                title: textHelper.getEmptyTextObject(reference),
                submitText: textHelper.getTranslation(reference, 'form-submit'),
                showStepper: true,
                hasInfo: true,
                info: info,
                cards: [],
                roles: roles
            }
        };
    },

    isSignerRole(roleKey) {
        return roleKey == 'sign' || roleKey == 'approve';
    },

    getNewHelpTextLine(reference) {
        return {
            key: 'h' + (reference.counter += 1),
            icon: 'help-o',
            header: textHelper.getEmptyTextObject(reference),
            text: textHelper.getEmptyTextObject(reference)
        };
    },

    getEmptyConfig(root, params) {
        const reference = {
            counter: 1001,
            locales: []
        };

        for (const key in params.locales) {
            if (key == 'en_EN' && params.locales[key] == true) {
                reference.locales.push({ key: 'en_EN', name: 'English', alpha2: 'gb', enabled: true });
            }
            if (key == 'nb_NO' && params.locales[key] == true) {
                reference.locales.push({ key: 'nb_NO', name: 'Norsk', alpha2: 'no', enabled: true });
            }
            if (key== 'sv_SE' && params.locales[key] == true) {
                reference.locales.push({ key: 'sv_SE', name: 'Svenska', alpha2: 'se', enabled: true });
            }
        }

        const config = {
            key: params.key,
            designVersion: 1,
            locales: JSON.parse(JSON.stringify(reference.locales)),
            title: {},
            name: {},
            flowType: params.flow,
            flowConfig: {
                canSave: false,
                autoSave: false,
                noValidation: false,
                ownerValidation: true,
                flags: {
                    ownerCopy: true,
                    signedCopy: {
                        enabled: false,
                        firstName: '',
                        lastName: '',
                        email: '',
                        language: ''
                    },
                    autoSaveMessage: false,
                    autoSaveLoaded: false,
                },
                roles: [
                    { key: 'owner', type: 'owner', display: 'Owner', next: 'done', hasEmail: false, email: {}, hasLanding: false, landingParams: {}, redirect: 'clients' }
                ],
                customSender: {
                    enabled: false,
                    firstName: '',
                    lastName: '',
                    email: ''
                },
                branding: {
                    logoEmail: '',
                    logoPdf: '',
                    logoLanding: ''
                }
            },
            modules: [],
            counter: 1100,
            pages: [
                {
                    key: 'p' + (reference.counter += 1),
                    type: 'page',
                    mode: 'form-page',
                    title: textHelper.getEmptyTextObject(reference),
                    submitText: textHelper.getTranslationFromRoot(root, reference, 'form-submit'),
                    showStepper: true,
                    hasInfo: true,
                    info: {
                        owner: [this.getNewHelpTextLine(reference)]
                    },
                    roles: {
                        owner: true
                    },
                    cards: [
                        {
                            key: 'c' + (reference.counter += 1),
                            type: 'card',
                            header: textHelper.getEmptyTextObject(reference),
                            inputs: [],
                            noPadding: false,
                            pdf: true
                        }
                    ]
                }
            ],
            resources: {},
            logic: []
        };

        for (const i in reference.locales) {
            config.title[reference.locales[i].key] = params.title;
            config.name[reference.locales[i].key] = params.title;
        }

        if (params.module == 'kyc') {
            config.modules.push(kycModuleHelper.getEmptyConfig());
        }else if (params.module == 'idc') {
            config.modules.push(idcModuleHelper.getEmptyConfig());
        }else if (params.module == 'chk') {
            config.modules.push(checklistModuleHelper.getEmptyConfig());
        }
        return config;
    },

    isPageVisible(page, currentRole) {
        if (currentRole == 'done') return true;
        return page.roles[currentRole];
    },

    isCardVisible(reference, card, designer, pages, page, currentRole) {
        if (card.type == 'logo' || card.type == 'custom') return true;
        if (currentRole != 'done' && !card.roles[currentRole]) return false;
        if (card.logic && card.logic.visible) {
            return this.checkVisible(reference, card, designer, pages, page);
        }
        return true;
    },

    isInputVisible(reference, input, designer, pages, page) {
        if (input.logic && input.logic.visible) {
            return this.checkVisible(reference, input, designer, pages, page);
        }else if (input.chkshow) {
            if (designer) return true;
            const item = this.getItemFromKey(pages, input.chkshow.key);
            if (item.model[input.chkshow.type] != input.chkshow.value) return false;
        }
        return true;
    },

    isListInputVisible(reference, card, input, index, designer, pages, page) {
        if (input.logic && input.logic.visible) {
            return this.checkVisibleList(reference, card, input, index, designer, pages, page);
        }
        return true;
    },

    checkCountryTrigger(reference, trigger, model, reverse) {
        const country = reference.getCountry(model);
        if (!country) return false;
        let value = false;
        if (trigger.key == 'nordic') {
            if (!(country.region == 0 && country.subregion == 0)) value = true;
        }else if (trigger.key == 'euro') {
            if (!(country.region == 0 && (country.subregion == 1 || country.subregion == 0))) value = true;
        }else {
            if (trigger.key == country.alpha2) value = true;
        }
        if (trigger.rev) value = !value;
        return value;
    },

    checkLogicTrigger(reference, input, designer, pages, type) {

        const values = [];
        if (designer) {
            
            return true;
            
        }else {
            for (const i in input.logic[type].keys) {
                if (input.logic[type].keys[i].type == 'logic') {

                    let value = false;
                    if (reference.logicResults[input.logic[type].keys[i].key]) {
                        value = reference.logicResults[input.logic[type].keys[i].key];
                    }
                    if (input.logic[type].keys[i].rev) value = !value;
                    values.push(value);

                }else if (input.logic[type].keys[i].type == 'role') {

                    const roleKey = reference.roleKey || reference.role;

                    let value = input.logic[type].keys[i].key == roleKey;
                    if (input.logic[type].keys[i].rev) value = !value;

                    values.push(value);

                }else if (input.logic[type].keys[i].type == 'kyc') {

                    let value = false;
                    if (input.logic[type].keys[i].key == 'changed') {
                        if (reference.comparison) {
                            value = reference.comparison.changed;
                        }
                    }
                    if (input.logic[type].keys[i].rev) value = !value;
                    values.push(value);

                }else if (input.logic[type].keys[i].type == 'loc') {

                    const item = this.getItemFromKey(pages, input.logic[type].keys[i].pkey);
                    values.push(this.checkCountryTrigger(reference, input.logic[type].keys[i], item.model));

                }else if (input.logic[type].keys[i].type == 'sys') {

                    let value = false;

                    if (input.logic[type].keys[i].key == 'analog') {
                        value = reference.isAnalog || false;
                    }
                    
                    if (input.logic[type].keys[i].rev) value = !value;
                    values.push(value);

                }else if (input.logic[type].keys[i].type == 'tag') {

                    let value = false;
                    if (reference.clientTags && reference.clientTags.includes(input.logic[type].keys[i].key)) value = true;
                    
                    if (input.logic[type].keys[i].rev) value = !value;
                    values.push(value);

                }else {
                    const item = this.getItemFromKey(pages, input.logic[type].keys[i].pkey);
                    //console.log('item', item);
                    if (item) {
                        if (item.type == 'drop') {
                            let value = (item.model == input.logic[type].keys[i].key);
                            if (input.logic[type].keys[i].rev) value = !value;
                            values.push(value);
                        }else if (item.type == 'check') {
                            let value = item.model[input.logic[type].keys[i].key];
                            if (input.logic[type].keys[i].rev) value = !value;
                            values.push(value);
                        }else if (item.type == 'check2') {

                            let value = false;
                            if (typeof item.model == 'boolean') {
                                value = item.model;
                            }else {
                                value = item.model[input.logic[type].keys[i].key] || false;
                            }

                            if (input.logic[type].keys[i].rev) value = !value;
                            values.push(value);
                        }
                    }else {
                        return true;
                    }
                }
            }
        }
        if (values.length == 0) {
            return false;
        }else if (input.logic[type].or) {
            let value = false;
            for (const i in values) {
                if (values[i]) value = true;
            }
            return value;
        }else if (!input.logic[type].or) {
            let value = true;
            for (const i in values) {
                if (!values[i]) value = false;
            }
            return value;
        }
    },

    checkVisible(reference, input, designer, pages, page) {

        const values = [];
        if (designer) {
            
            return true;
            
        }else {
            for (const i in input.logic.visible.keys) {
                if (input.logic.visible.keys[i].type == 'logic') {

                    let value = false;
                    if (reference.logicResults[input.logic.visible.keys[i].key]) {
                        value = reference.logicResults[input.logic.visible.keys[i].key];
                    }
                    if (input.logic.visible.keys[i].rev) value = !value;
                    values.push(value);

                }else if (input.logic.visible.keys[i].type == 'role') {

                    const roleKey = reference.roleKey || reference.role;

                    let value = input.logic.visible.keys[i].key == roleKey;
                    if (input.logic.visible.keys[i].rev) value = !value;

                    values.push(value);

                }else if (input.logic.visible.keys[i].type == 'kyc') {

                    let value = false;
                    if (input.logic.visible.keys[i].key == 'changed') {
                        if (reference.comparison) {
                            value = reference.comparison.changed;
                        }
                    }
                    if (input.logic.visible.keys[i].rev) value = !value;
                    values.push(value);

                }else if (input.logic.visible.keys[i].type == 'loc') {

                    const item = this.getItemFromKey(pages, input.logic.visible.keys[i].pkey);
                    values.push(this.checkCountryTrigger(reference, input.logic.visible.keys[i], item.model));

                }else if (input.logic.visible.keys[i].type == 'sys') {

                    let value = false;

                    if (input.logic.visible.keys[i].key == 'analog') {
                        value = reference.isAnalog || false;
                    }
                    
                    if (input.logic.visible.keys[i].rev) value = !value;
                    values.push(value);

                }else if (input.logic.visible.keys[i].type == 'tag') {

                    let value = false;
                    if (reference.clientTags && reference.clientTags.includes(input.logic.visible.keys[i].key)) value = true;
                    
                    if (input.logic.visible.keys[i].rev) value = !value;
                    values.push(value);

                }else if (input.logic.visible.keys[i].type == 'val') {

                    let value = false;

                    if (input.logic.visible.keys[i].key == 'form') {
                        value = reference.validation ? reference.validation.formValid || false : false;
                    }
                    
                    if (input.logic.visible.keys[i].rev) value = !value;
                    values.push(value);

                }else {
                    const item = this.getItemFromKey(pages, input.logic.visible.keys[i].pkey);
                    if (item) {
                        if (item.type == 'drop') {
                            let value = (item.model == input.logic.visible.keys[i].key);
                            if (input.logic.visible.keys[i].rev) value = !value;
                            values.push(value);
                        }else if (item.type == 'check') {
                            let value = item.model[input.logic.visible.keys[i].key];
                            if (input.logic.visible.keys[i].rev) value = !value;
                            values.push(value);
                        }else if (item.type == 'check2') {

                            //console.log('model', item.model);
                            let value = item.model || false;
                            if (typeof item.model == 'object' && item.model != null) {
                                value = item.model[input.logic.visible.keys[i].key];
                            }

                            if (input.logic.visible.keys[i].rev) value = !value;
                            values.push(value);
                        }
                    }else {
                        return true;
                    }
                }
            }
        }
        if (values.length == 0) {
            return false;
        }else if (input.logic.visible.or) {
            let value = false;
            for (const i in values) {
                if (values[i]) value = true;
            }
            return value;
        }else if (!input.logic.visible.or) {
            let value = true;
            for (const i in values) {
                if (!values[i]) value = false;
            }
            return value;
        }
    },

    checkVisibleSingleKey(reference, input, designer, pages, page, singleKey) {

        if (singleKey.type == 'logic') {

            let value = false;
            if (reference.logicResults[singleKey.key]) {
                value = reference.logicResults[singleKey.key];
            }
            if (singleKey.rev) value = !value;
            return value;

        }else if (singleKey.type == 'role') {

            const roleKey = reference.roleKey || reference.role;

            let value = singleKey.key == roleKey;
            if (singleKey.rev) value = !value;

            return value;

        }else if (singleKey.type == 'kyc') {

            let value = false;
            if (singleKey.key == 'changed') {
                if (reference.comparison) {
                    value = reference.comparison.changed;
                }
            }
            if (singleKey.rev) value = !value;
            return value;

        }else if (singleKey.type == 'loc') {

            const item = this.getItemFromKey(pages, singleKey.pkey);
            return this.checkCountryTrigger(reference, singleKey, item.model);

        }else if (singleKey.type == 'sys') {

            let value = false;

            if (singleKey.key == 'analog') {
                value = reference.isAnalog || false;
            }
            
            if (singleKey.rev) value = !value;
            return value;

        }else if (singleKey.type == 'tag') {

            let value = false;
            if (reference.clientTags && reference.clientTags.includes(singleKey.key)) value = true;
            
            if (singleKey.rev) value = !value;
            return value;

        }else if (singleKey.type == 'val') {

            let value = false;

            if (singleKey.key == 'form') {
                value = reference.validation ? reference.validation.formValid || false : false;
            }
            
            if (singleKey.rev) value = !value;
            return value;

        }else {
            const item = this.getItemFromKey(pages, singleKey.pkey);
            if (item) {
                if (item.type == 'drop') {
                    let value = (item.model == singleKey.key);
                    if (singleKey.rev) value = !value;
                    return value;
                }else if (item.type == 'check') {
                    let value = item.model[singleKey.key];
                    if (singleKey.rev) value = !value;
                    return value;
                }else if (item.type == 'check2') {

                    let value = item.model || false;
                    if (typeof item.model == 'object' && item.model != null) {
                        value = item.model[singleKey.key];
                    }

                    if (singleKey.rev) value = !value;
                    return value;
                }
            }else {
                return true;
            }
        }
    },

    checkVisibleList(reference, card, input, index, designer, pages, page) {
        const values = [];
        if (designer) {
            
            return true;
            
        }else {
            for (const i in input.logic.visible.keys) {
                if (input.logic.visible.keys[i].type == 'loc') {

                    const item = this.getItemFromKey(pages, input.logic.visible.keys[i].pkey);
                    values.push(this.checkCountryTrigger(reference, input.logic.visible.keys[i], card.model[index][item.listModel]));

                }else {
                    const item = this.getItemFromKey(pages, input.logic.visible.keys[i].pkey);

                    if (!item.isList) {
                        // Check logic outside the card/index
                        const value = this.checkVisibleSingleKey(reference, input, designer, pages, page, input.logic.visible.keys[i]);
                        values.push(value);
                    }else {
                        // Check logic on the same card/index
                        if (item.type == 'drop') {

                            let value = (card.model[index][item.listModel] == input.logic.visible.keys[i].key);
                            if (input.logic.visible.keys[i].rev) value = !value;
                            values.push(value);
    
                        }else if (item.type == 'check') {
                            
                            let value = card.model[index][item.listModel][input.logic.visible.keys[i].key];
                            if (input.logic.visible.keys[i].rev) value = !value;
                            values.push(value);
                            
                        }else if (item.type == 'check2') {

                            let value = card.model[index][item.listModel];
                            if (typeof card.model[index][item.listModel] == 'object') {
                                value = card.model[index][item.listModel][input.logic.visible.keys[i].key];
                            }
                            if (input.logic.visible.keys[i].rev) value = !value;
                            values.push(value);
                            
                        }
                    }
                }
            }
        }
        if (values.length == 0) {
            return true;
        }else if (input.logic.visible.or) {
            let value = false;
            for (const i in values) {
                if (values[i]) value = true;
            }
            return value;
        }else if (!input.logic.visible.or) {
            let value = true;
            for (const i in values) {
                if (!values[i]) value = false;
            }
            return value;
        }
    },

    checkLogicTriggerList(reference, card, input, index, designer, pages, page, type) {
        const values = [];
        if (designer) {
            
            return true;
            
        }else {
            for (const i in input.logic[type].keys) {
                if (input.logic[type].keys[i].type == 'loc') {

                    const item = this.getItemFromKey(pages, input.logic[type].keys[i].pkey);
                    values.push(this.checkCountryTrigger(reference, input.logic[type].keys[i], card.model[index][item.listModel]));

                }else {
                    const item = this.getItemFromKey(pages, input.logic[type].keys[i].pkey);

                    if (!item.isList) {
                        // Check logic outside the card/index
                        const value = this.checkVisibleSingleKey(reference, input, designer, pages, page, input.logic[type].keys[i]);
                        values.push(value);
                    }else {
                        // Check logic on the same card/index
                        if (item.type == 'drop') {

                            let value = (card.model[index][item.listModel] == input.logic[type].keys[i].key);
                            if (input.logic[type].keys[i].rev) value = !value;
                            values.push(value);
    
                        }else if (item.type == 'check') {
                            
                            let value = card.model[index][item.listModel][input.logic[type].keys[i].key];
                            if (input.logic[type].keys[i].rev) value = !value;
                            values.push(value);
                            
                        }else if (item.type == 'check2') {

                            let value = card.model[index][item.listModel];
                            if (typeof card.model[index][item.listModel] == 'object') {
                                value = card.model[index][item.listModel][input.logic[type].keys[i].key];
                            }
                            if (input.logic[type].keys[i].rev) value = !value;
                            values.push(value);
                            
                        }
                    }
                }
            }
        }
        if (values.length == 0) {
            return true;
        }else if (input.logic[type].or) {
            let value = false;
            for (const i in values) {
                if (values[i]) value = true;
            }
            return value;
        }else if (!input.logic[type].or) {
            let value = true;
            for (const i in values) {
                if (!values[i]) value = false;
            }
            return value;
        }
    },

    stripPages(originalPages) {
        const pages = JSON.parse(JSON.stringify(originalPages));


        for (const i in pages) {

            if (pages[i].title) delete pages[i].title;
            if (pages[i].info) delete pages[i].info;
            if (pages[i].roles) delete pages[i].roles;
            if (pages[i].validation) delete pages[i].validation;

            for (const j in pages[i].cards) {

                if (pages[i].cards[j].val) delete pages[i].cards[j].val;

                if (pages[i].cards[j].header) delete pages[i].cards[j].header;
                if (pages[i].cards[j].addtext) delete pages[i].cards[j].addtext;
                if (pages[i].cards[j].noPadding) delete pages[i].cards[j].noPadding;
                if (pages[i].cards[j].pdf) delete pages[i].cards[j].pdf;
                if (pages[i].cards[j].roles) delete pages[i].cards[j].roles;
                if (pages[i].cards[j].logic) delete pages[i].cards[j].logic;

                for (const k in pages[i].cards[j].inputs) {

                    //console.log('strip input', pages[i].cards[j].inputs[k]);

                    const input = {
                        key: pages[i].cards[j].inputs[k].key,
                        type: pages[i].cards[j].inputs[k].type,
                        model: pages[i].cards[j].inputs[k].model,
                        modelKey: pages[i].cards[j].inputs[k].modelKey,
                        dbIndex: pages[i].cards[j].inputs[k].dbIndex,
                    };
                    pages[i].cards[j].inputs[k] = input;

                }

            }

        }

        return pages;
    },

    stripAttachmentData(originalPages) {
        const pages = JSON.parse(JSON.stringify(originalPages));
        for (const i in pages) {
            for (const j in pages[i].cards) {
                for (const k in pages[i].cards[j].inputs) {
                    const input = pages[i].cards[j].inputs[k];
                    if (input.model && input.model.files) {
                        for (const x in input.model.files) {
                            input.model.files[x].data = {};
                            delete input.model.files[x].expanded;
                            delete input.model.files[x].existing;
                            delete input.model.files[x].isResend;
                        }
                    }
                }
            }
        }
        return pages;
    },

    getDataModel(reference, designer) {
        const obj = {};
        for (const i in reference.pages) {
            const page = reference.pages[i];

            for (const j in page.cards) {
                const card = page.cards[j];
                if (!card.mdmodel) continue;

                let cardName = card.key;
                if (card.modelKey) cardName = card.modelKey;
                if (card.kycKey) cardName = this.getNameFromKycKey(card.kycKey);

                if (card.type == 'card') {

                    const cardObj = {};
                    for (const k in card.inputs) {
                        let inputName = card.inputs[k].key;
                        if (card.inputs[k].modelKey) inputName = card.inputs[k].modelKey;
                        cardObj[inputName] = this.getDataModelInputValue(card.inputs[k], designer);
                    }
                    obj[cardName] = cardObj;

                }else if (card.type == 'list') {

                    if (designer) {

                        const cardObj = {};
                        for (const k in card.inputs) {
                            let inputName = card.inputs[k].key;
                            if (card.inputs[k].listModel) inputName = card.inputs[k].listModel;
                            cardObj[inputName] = this.getDataModelInputValue(card.inputs[k], designer);
                        }
                        obj[cardName] = [cardObj];

                    }else {

                        const cardObj = [];

                        for (const x in card.model) {

                            cardObj.push(card.model[x]);

                        }


                        obj[cardName] = cardObj;

                    }

                }

            }
        }
        return obj;
    },

    getDataModelInputValue(input, designer) {
        if (designer) return '';
        
        return input.model;

    },

    getDataModelListInputValue(input, designer) {
        if (designer) {
            return '';
        }
        return '';
    },

    getNameFromKycKey(kycKey) {
        return kycKey;
    },

};
export default formHelper;