import dataModelV1 from '../v1data/dataModelV1';
import dataNorwayV1 from '../v1data/dataNorwayV1';
import dataSwedenV1 from '../v1data/dataSwedenV1'
import v1receipt from '../v1helpers/v1receipt';
import v1api from '../v1helpers/v1api';
import aml from "../aml"
import axios from "axios";
import log from '../log';
import tools from '../tools';
import v2lib from '../v2lib';
import verified from '../verified';
import pdfTools from '../pdfTools';
import screeningHelper from '../v1persons/screeningHelper';

// create new swedish judicial client
const newOrgSe = async (root, statusFunc, errorFunc, orgnr, flow) => {

    const result = {
        companyData: dataModelV1.getEmptyCompany(),
        reports: [],
        rawData: null
    };

    const trapetsRes = await dataSwedenV1.getTrapetsData(orgnr);
    console.log('result', trapetsRes);

    statusFunc("Looking up company in trapets..")

    if (!trapetsRes.success) {
        errorFunc("Failed to get data on the orgnr from trapets")
        return;
    }

    // Get address
    let address = '';
    try {
        for (const i in trapetsRes.data.ResponseItems[0].Entities) {
            const entity = trapetsRes.data.ResponseItems[0].Entities[i];
            for (const j in entity.Addresses) {
                const adr = entity.Addresses[j];
                if (adr.Street) {
                    address = adr.Street + ', ' + adr.ZipCode + ' ' + adr.City;
                }
            }
        }
    }catch (ex) {
        address = '';
    }

    const selectedCompany = {
        name: trapetsRes.data.ResponseItems[0].Entities[0].Name,
        identity: trapetsRes.data.ResponseItems[0].Entities[0].CorporateId,
        address: address,
        country: 'SE',
        source: 'trapets-bisnode',
        trapetsData: trapetsRes.data
    };

    const bisnodeData = await dataSwedenV1.getBisnodeData(selectedCompany.identity);
    dataSwedenV1.processData(root, result.companyData, trapetsRes.data, bisnodeData.data);

    console.log('company', result.companyData);

    const client = {};
    client.name = selectedCompany.name;
    client.identity = selectedCompany.identity;
    client.country = selectedCompany.country;

    client.type = 'org';
    client.source = selectedCompany.source;
    client.flow = flow;
    client.letter = getLetter(client.name);

    if (root.config.params.hasDepartments) client.department = root.credentials.branch.toString();

    statusFunc("Creating client..")

    let response
     
    try {
        response = await v1api.createClient(client, result.companyData, null);
    } catch(e) {
        errorFunc("Failed to create client")
        return
    }
    
    if (response.status == 200) {
        // already exists
        return response.id;
    }else if (response.status == 201) {

        statusFunc("Creating client envelope..")

        let envelope

        try {
            envelope = await v1receipt.createClientEnvelope(root, client, result.companyData, undefined, result.rawData);
        } catch(e) {
            errorFunc("Failed to create client envelope")
            return
        }
        
        const updateData = {
            envelopeId: envelope.id,
            documentId: envelope.documentId,
            name: root.__t('search-name') + ' ' + root.companyTitleCase(client.name)
        };

        statusFunc("Updating client with envelope data")

        try {
            await v1api.updateClient(response.id, updateData);
        } catch(e) {
            errorFunc("Failed to update client with envelope data")
            return
        }

        return response.id

    }

}

// create new norwegian judicial client
const newOrgNo = async (root, statusFunc, errorFunc, orgnr, flow) => {

    const result = {
        companyData: dataModelV1.getEmptyCompany(),
        reports: [],
        rawData: null
    };

    // brreg lookup
    statusFunc("Looking up company in brreg..")
    let brregRes;
    try {
        brregRes = await axios.get("https://data.brreg.no/enhetsregisteret/api/enheter/" + encodeURIComponent(orgnr))
    } catch(e) {
        errorFunc("Failed to lookup org nr in brreg")
        console.error("brreg lookup", e)
        return
    }

    const company = {
        name: brregRes.data.navn,
        identity: String(brregRes.data.organisasjonsnummer),
        country: 'NO',
        source: 'bankidaml',
        brreg: aml.processBrreg(brregRes.data)
    }

    // BankID AML lookup
    statusFunc("Looking up company with BankID AML..")

    try {
        const bamlData = await dataNorwayV1.getBankIdAmlData(root, orgnr);
        dataNorwayV1.processData(root, result.companyData, bamlData.data, company.brreg);
        result.reports = bamlData.reports;
    } catch(e) {
        errorFunc("Failed to lookup company with BankID AML")
        console.error("bid aml lookup", e)
        return
    }

    

    const client = {};

    client.name = company.name;
    client.identity = company.identity;
    client.country = company.country;

    client.type = 'org';
    client.source = company.source;
    client.flow = flow;
    client.letter = getLetter(client.name);

    if (root.config.params.hasDepartments) client.department = root.credentials.branch.toString();

    statusFunc("Creating client..")

    let response
     
    try {
        response = await v1api.createClient(client, result.companyData, null);
    } catch(e) {
        errorFunc("Failed to create client")
        return
    }
    
    if (response.status == 200) {
        // already exists
        return response.id;
    }else if (response.status == 201) {

        statusFunc("Creating client envelope..")

        let envelope

        try {
            envelope = await v1receipt.createClientEnvelope(root, client, result.companyData, undefined, result.rawData);
        } catch(e) {
            errorFunc("Failed to create client envelope")
            return
        }
        
        const updateData = {
            envelopeId: envelope.id,
            documentId: envelope.documentId,
            name: root.__t('search-name') + ' ' + root.companyTitleCase(client.name)
        };

        statusFunc("Updating client with envelope data")

        try {
            await v1api.updateClient(response.id, updateData);
        } catch(e) {
            errorFunc("Failed to update client with envelope data")
            return
        }

        statusFunc("Adding BankID reports to client envelope as attachments")

        try {
            for (const i in result.reports) {
                await v1receipt.addBankIDReportToClientEnvelope(root, envelope, response.id, result.reports[i]);
            }
        } catch(err) {
            // ok to keep going here, no need to return, maybe add a warning in the future
            // errorFunc("Failed to add BankID reports")
        }

        return response.id

    }
    
}

// creates a new norwegian physical person
const newPersonNo = async (root, statusFunc, errorFunc, person, flow) => {

    /* person obj example {
        // required:
        firstname,
        lastname,
        
        // optional:
        dob,
        country,
        email,
        phone,
        ssn,
    }
    */
    
    if(person.ssn && !person.dob) {
        const dd = person.ssn.substring(0, 2)
        const mm = person.ssn.substring(2, 4)
        const yyyy = `19${person.ssn.substring(4, 6)}`
        person.dob = `${yyyy}-${mm}-${dd}`
    }

    const client = {};

    client.name = person.firstname + ' ' + person.lastname;
    client.identity = (person.dob || '1800-01-01') + '-' + person.firstname.toLowerCase() + '-' + person.lastname.toLowerCase();
    client.country = person.country;

    client.type = 'person';
    client.source = 'manual';
    client.flow = flow;
    client.letter = getLetter(client.name);

    const personData = {
        name: person.firstname.toLowerCase() + ' ' + person.lastname.toLowerCase(),
        firstName: person.firstname,
        lastName: person.lastname,
        dateOfBirth: person.dob || '1800-01-01',
        country: person.country,
        email: person.email,
        phone: person.phone,
        roles: ['role_individual']
    };

    statusFunc("Creating client..")

    let response;

    try {
        response = await v1api.createClient(client, null, personData);
    } catch(err) {
        console.error(e)
        errorFunc(err)
        return
    }

    if(person.ssn) {
        statusFunc("Performing manual id control to store ssn")
        try {
            await v1api.completeIDCManually(response.client.personId, {
                ssn: person.ssn,
                comment: ""
            });
        } catch(e) {
            console.error(e)
            errorFunc(err)
            return
        }
    }

    return response.id
}

const getLetter = (name) => {
    let letter = '#';
    const validLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (validLetters.includes(name.charAt(0).toUpperCase())) {
        letter = name.charAt(0).toUpperCase();
    }
    return letter;
}

const screening = async (root, client, person, useSSN) => {
    const searchParams = {
        firstName: person.info.firstName,
        lastName: person.info.lastName,
        dateOfBirth: person.info.dateOfBirth,
        ssn: person.info.ssn,
        countryOfSsn: '',
        mode: 'exact',
        parameters: []
    };

    let result = null;
    const reports = [];

    // First create an envelope to make sure Verified Platform is up and running
    const envelope = await v2lib.createEnvelope('aml-portal-v1-person', 'no-recipients-flow');

    if (!v2lib.wasCreated(envelope)) {
        // Error creating envelope, show error to the user
        return;
    }

    // Perform PEP/Sanction search with BankID AML
    if (useSSN && person.info.ssn) {
        log.w('AML: Searching with SSN');

        result = await aml.doAmlPersonSearchSSN(root, person.info.firstName, person.info.lastName, person.info.ssn);
        searchParams.parameters.push('firstName');
        searchParams.parameters.push('lastName');
        searchParams.parameters.push('ssn');
        searchParams.parameters.push('countryOfSsn');
        searchParams.countryOfSsn = 'NO';

    }else {
        log.v('AML: Searching with date of birth', person.info.dateOfBirth);

        const dateOfBirth = tools.fixDateOfBirth(person.info.dateOfBirth);
        result = await aml.doAmlPersonSearch(root, person.info.firstName, person.info.lastName, dateOfBirth);
        searchParams.parameters.push('firstName');
        searchParams.parameters.push('lastName');
        searchParams.parameters.push('dateOfBirth');

    }
    log.s('AML: Person data fetched from BankID', result);

    const personData = dataModelV1.getEmptyPerson();
    dataNorwayV1.processPerson(root, personData, result, searchParams);

    if (result.personData.links && result.personData.links.reports && result.personData.links.reports.length > 0) {

        for (const i in result.personData.links.reports) {
            reports.push({
                url: result.personData.links.reports[i].href,
                contentType: result.personData.links.reports[i].contentType,
                type: dataNorwayV1.getReportType(result.personData.links.reports[i].rel, 'person'),
                name: dataNorwayV1.getReportName(result.personData.links.reports[i].rel, 'person', searchParams.firstName + ' ' + searchParams.lastName)
            });
        }

    }

    personData.envelopeId = envelope.id;
    await v1api.performScreening(person.personId, personData);

    const attachments = [];

    for (const i in reports) {

        const attachment = {
            type: reports[i].type,
            name: reports[i].name,
            fileType: reports[i].contentType
        };
        attachments.push(attachment);

    }

    const metadata = {
        userId: root.credentials.uid,
        userName: root.getEmployeeName(root.credentials.uid),
        date: new Date()
    };

    // Get PDF configuration
    const pdfConfig = {
        logo: root.config.pdf.logoUrl,
        colors: {
            headerBack: root.getColor('header'),
            headerText: root.getColor('header-text')
        },
        language: root.locale.key || 'en_EN',
        translations: screeningHelper.getPdfTranslations(root, root.locale.key || 'en_EN')
    };

    // Prepare envelope userdata, name and tags
    const envelopeName = `PEP/Sanksjonsøk ${person.info.firstName} ${person.info.lastName} (${person.info.dateOfBirth})`;
    const envelopeTags = ['personsøk'];
    const envelopeUserdata = {
        metadata: metadata,
        pdfConfig: pdfConfig,
        personData: personData,
        attachments: attachments,
        fileName: envelopeName,
        person: {
            firstName: searchParams.firstName,
            lastName: searchParams.lastName,
            dateOfBirth: searchParams.dateOfBirth
        }
    };

    // Complete the envelope
    await v2lib.setUserData(envelope, envelopeUserdata);
    await v2lib.setTags(envelope, envelopeTags);
    await v2lib.setName(envelope, envelopeName);
    await v2lib.publishEnvelope(envelope);
    log.s('AML: Envelope published');

    // Download reports and add to envelope

    if (reports.length > 0) {
        log.v('AML: Downloading BankID reports', reports);

        for (const i in reports) {
            log.v('AML: Downloading', reports[i]);

            const base64 = await verified.downloadBankIDReport(reports[i].url);
            if (base64) {

                const attachment = {
                    type: reports[i].type,
                    name: reports[i].name,
                    fileType: reports[i].contentType,
                    size: base64.length
                };
                await verified.uploadBase64Attachment(envelope.id, envelope.documentId, base64, attachment.name);

                log.s('AML: BankID report attached', reports[i]);
            }else {
                log.e('AML: Error downloading BankID report');
            }
        }

    }else {
        log.v('AML: No reports to download');
    }
    
}

export {
    newOrgNo,
    newOrgSe,
    newPersonNo,
    screening
}